import * as React from 'react';
import clsx from 'clsx';

import { Grid, makeStyles } from '@material-ui/core';

import { PageContainerResponsive } from '@eip/next/stories/common-system-page.stories';

import EllipseFormBackground from '@ep/insight-ui/elements/input-form/ellipse-form-background';
import FormFooter from '@ep/insight-ui/elements/input-form/form-templates/footer';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
  },
  logo: {
    textAlign: 'center',
    margin: '32px 0',
  },
  content: {
    zIndex: 100,
    padding: '32px 44px',
    borderRadius: '8px',
    marginTop: '36px',
  },
  header: {
    color: '#253746',
    margin: 0,
    marginBottom: '64px',
    '& .title': {
      margin: 0,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: '16px',
    },
    '& .center': {
      textAlign: 'center',
    },
  },
  subTitle: {
    fontSize: '14px',
  },
  link: {
    color: '#23a8fb',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

interface IPropsInformationPage {
  title?: string;
  children: React.ReactNode;
}

const InformationPage = ({ title, children }: IPropsInformationPage) => {
  const classes = useStyles();

  return (
    <PageContainerResponsive>
      <EllipseFormBackground />
      <Grid container className={clsx(`${classes.container}`)}>
        <div>
          <Grid item xs={12} className={classes.logo}>
            <Icon type="logo" colorIcon={'#1A2731'} />
          </Grid>
          <Grid item xs={12} className={clsx(classes.content)}>
            <Grid item xs={12} className={clsx(classes.header)}>
              <p className={clsx(`title center`)}>{title}</p>
              {children}
            </Grid>
          </Grid>
        </div>
        <FormFooter copyright={`© ${new Date().getFullYear()} Epsilo. All Rights Reserved`} />
      </Grid>
    </PageContainerResponsive>
  );
};

export default InformationPage;
