import * as React from 'react';

interface IPinnedColumn {
  field: string;
  isLeftPin: boolean;
  isRightPin: boolean;
}

export const usePinColumn = (pinnedColumns, backbone, rowsGroup) => {
  const [columnApi, setColumnApi] = React.useState(null);
  const [hiddenPinnedColumns, setHiddenPinnedColumns] = React.useState([]);

  const pinThisColumns = React.useMemo(() => {
    if (columnApi) {
      return pinnedColumns.filter(
        (pinThisColumn) => columnApi.getColumn(pinThisColumn.field) && !pinThisColumn.isLeftPin,
      );
    }
    return [];
  }, [pinnedColumns]);

  const pinLeftColumns = React.useMemo(() => {
    if (columnApi) {
      return pinnedColumns.filter(
        (pinThisColumn) => columnApi.getColumn(pinThisColumn.field) && pinThisColumn.isLeftPin,
      );
    }
    return [];
  }, [pinnedColumns]);

  const getPinColumnActions = (column) => {
    const pinnedColumn = pinnedColumns.find(({ field }) => field === column.field);
    return [
      {
        name: pinnedColumn && !pinnedColumn.isLeftPin && !pinnedColumn.isRightPin ? 'Unpin this' : 'Pin this',
        icon: 'pinColumn',
        type: '',
        component: null,
        onSubmit: (data) => {
          const pinnedColumn = pinnedColumns.find(({ field }) => field === column.field);
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (pinnedColumn.isLeftPin || pinnedColumn.isRightPin) {
              newPinnedResult = pinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: false,
                  isRightPin: false,
                };
              });
            } else {
              newPinnedResult = pinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: false,
              isRightPin: false,
            };
            newPinnedResult = [...pinnedColumns, pinnedColumn];
            setHiddenPinnedColumns([...hiddenPinnedColumns, column.field]);
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
      {
        name: pinnedColumn && pinnedColumn.isLeftPin ? 'Unpin left' : 'Pin left',
        icon: 'pinColumn',
        type: '',
        component: null,
        onSubmit: (data) => {
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (!pinnedColumn.isLeftPin) {
              newPinnedResult = pinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: true,
                  isRightPin: false,
                };
              });
            } else {
              newPinnedResult = pinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: true,
              isRightPin: false,
            };
            newPinnedResult = [...pinnedColumns, pinnedColumn];
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
      {
        name: pinnedColumn && pinnedColumn.isRightPin ? 'Unpin Right' : 'Pin right',
        icon: 'pinColumn',
        type: '',
        component: null,
        onSubmit: (data) => {
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (!pinnedColumn.isRightPin) {
              newPinnedResult = pinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: false,
                  isRightPin: true,
                };
              });
            } else {
              newPinnedResult = pinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: false,
              isRightPin: true,
            };
            newPinnedResult = [...pinnedColumns, pinnedColumn];
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
    ];
  };

  React.useEffect(() => {
    // move left pinned columns to first
    const pinnedLeftColumns = pinLeftColumns.map(({ field }) => field);
    if (pinnedLeftColumns.length > 0) {
      columnApi.moveColumns(pinnedLeftColumns, rowsGroup.length > 0 ? 2 : 1);
    }
  }, [pinnedColumns]);

  React.useEffect(() => {
    if (columnApi) {
      const scrollPosition = columnApi.columnModel.scrollPosition;
      const newHiddenPinnedColumns = pinThisColumns
        .filter((pinThisColumn) => {
          return (
            columnApi.getColumn(pinThisColumn.field) && scrollPosition < columnApi.getColumn(pinThisColumn.field).left
          );
        })
        .map(({ field }) => field);
      setHiddenPinnedColumns(newHiddenPinnedColumns);
    }
  }, [pinnedColumns]);

  const handleBodyScroll = ({ left, direction }) => {
    if (direction === 'horizontal') {
      // Check if columns are active
      const activePinnedColumns = pinThisColumns.filter((pinnedColumn) => {
        const column = columnApi.getColumn(pinnedColumn.field);
        if (column.pinned === 'left') {
          // Left value of the right column of current pinned column is also current left value of pinned column
          const currentIndex = columnApi.getAllGridColumns().findIndex((el) => el.field === column.field);

          const columnRight = columnApi.getAllGridColumns().find((el, i) => {
            return i > currentIndex && !el.pinned && el.visible;
          });
          if (columnRight && left <= columnRight.left) {
            return false;
          }
          return true;
        }
        return left > column.left;
      });
      let newHiddenPinnedColumns;
      if (activePinnedColumns.length > 0) {
        newHiddenPinnedColumns = pinThisColumns
          .filter((pinnedColumn) => {
            return activePinnedColumns.every((el) => el.field !== pinnedColumn.field);
          })
          .map((el) => el.field);
      } else {
        newHiddenPinnedColumns = pinThisColumns.map((pinnedColumn) => pinnedColumn.field);
      }
      if (newHiddenPinnedColumns.length !== hiddenPinnedColumns.length) {
        setHiddenPinnedColumns(newHiddenPinnedColumns);
      }
    }
  };

  return {
    setColumnApi,
    handleBodyScroll,
    hiddenPinnedColumns,
    getPinColumnActions,
  };
};
