import { UaMonit } from './uamonit';
import { useLog } from '@eip/next/lib/main';
import * as apm from './transporter/apm';
import { get } from 'lodash';

const log = useLog('uamonit');
class ApiError extends Error {
  message: string;
  code: number | string;
  constructor(message, code) {
    super(message);
    this.code = code;
  }
}

const uaList = {
  canMassUpdateBiddingPrice:
    'https://www.notion.so/epsilion/Can-mass-update-bidding-price-a04a065ce2184c44bc27cc71b2d3b971',
  canUpdateBiddingPrice: 'https://www.notion.so/epsilion/Can-update-bidding-price-f7b10436f4774c04a0c92676a174e37e',
  canMassUpdateCampaignStatus:
    'https://www.notion.so/epsilion/Can-mass-update-campaign-status-15299da963384aabbec76efc329c65f5',
  canMassUpdateKeywordStatus:
    'https://www.notion.so/epsilion/Can-mass-update-keyword-status-in-ad-object-a9a912d8806245d1b19cad03489da584',
  canUpdateKeywordStatus:
    'https://www.notion.so/epsilion/Can-update-keyword-status-in-product-95909f66d1ca4ea88291ee32b1e9b750',
  canMassUpdateAdObjectStatus:
    'https://www.notion.so/epsilion/Can-mass-update-ad-object-status-in-campaign-da30e49fcd3d49b99e3a6d2b7713e3f6',
  canUpdateAdObjectStatus:
    'https://www.notion.so/epsilion/Can-update-ad-object-status-in-campaign-0bc0356a7e3f4dbf969e08612a8a9381',
  canGroupItemEtable: 'https://www.notion.so/epsilion/Can-group-items-on-eTable-6c52dfd67c604403a077870e6b8cfea6',
  canArrangeColumnEtable:
    'https://www.notion.so/epsilion/Can-arrange-column-on-eTable-1ea46e01ca6a443ea71028934b0df902',
  canSeeDataEtable: 'https://www.notion.so/epsilion/Can-arrange-column-on-eTable-1ea46e01ca6a443ea71028934b0df902',
  canFilterEtable: 'https://www.notion.so/epsilion/Can-filter-on-eTable-08cf72b87e59447990dd632a0d76b0f3',
  canSortEtable: 'https://www.notion.so/epsilion/Can-sort-on-eTable-c94f7885794649669ab12a08224fa3f1',
};
type uaType = keyof typeof uaList;

type UaMonitSessionType = {
  descriptionUrl: string;
  start: (context?) => Promise<UaMonit>;
};
type WatchlistType = Record<uaType, UaMonitSessionType>;

const sessionList: UaMonit[] = [];
class Watchlist {
  list: WatchlistType;

  constructor() {
    this.list = Object.keys(uaList).reduce((accum, key) => {
      return {
        ...accum,
        [key]: {
          descriptionUrl: uaList[key],
          start: (context?) => {
            return new Promise((resolve) => {
              window.setTimeout(() => {
                const transaction = apm.getTransaction(key, context?.title);
                const instance = new UaMonit(key, {
                  init() {
                    // apm.getTransaction(key);
                  },
                  mark(label, context) {
                    log(`${key}:${label}`, context);
                    // if (label.indexOf('fail:') === 0) {
                    //   transaction.addLabels({ uaFail: true, uaFailOn: label });
                    //   log(`${key}:${label}`, { uaFail: true, uaFailOn: label });
                    // }
                    // transaction.addLabels({
                    //   [`uaTTL:${label}`]: context.uaTTL,
                    //   [`uaTTLDelta:${label}`]: context.uaTTLDelta,
                    // });

                    // transaction.mark(label);
                  },
                  end() {
                    // transaction.end();
                  },
                });
                sessionList.push(instance);
                log('all session', sessionList.length);
                instance.addContext(context);
                instance.start('init', context?.timerMs);
                instance.requestAck('init', context?.timerMs);
                resolve(instance);
              }, 0);
            });
          },
        },
      };
    }, {} as WatchlistType);
  }
  findById(sessionId: string) {
    return sessionList.find((i) => i.id() === sessionId);
  }
  start(ua: uaType, context?) {
    return this.list[ua].start(context);
  }
  end(session: UaMonit | string) {
    let sessionId: string;
    if (typeof session === 'string') {
      sessionId = session;
    } else {
      sessionId = session.id();
    }

    const sIndex = sessionList.findIndex((i) => i.id() === sessionId);
    session = sessionList[sIndex];
    session.end();
    sessionList.splice(sIndex, 1);
    log('all session', Object.keys(sessionList));
    // delete this.session[sessionId];
  }
  inspect(type: 'apiResponse', res): Promise<any> {
    if (type === 'apiResponse')
      return new Promise<void>((resolve, reject) => {
        const isSuccess = get(res, 'serviceResponse.success');
        if (isSuccess !== undefined && isSuccess === false) {
          const error = new ApiError(JSON.stringify(res), get(res, 'serviceResponse.code', -1));
          reject(error);
        } else {
          resolve(res);
        }
      });
    else {
      return Promise.resolve(0);
    }
  }
}

export type { uaType };
export { Watchlist };
