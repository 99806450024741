/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@ep/insight-ui/icons/Icon';
import { Button, Divider, Grid, InputAdornment } from '@material-ui/core';
import SelectSearchForm from '../schedule-delivery/autocomplete';
import { OptionSelectType } from '../list-control/type';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import HeaderList from './header-list';
const useStyles = makeStyles(() => ({
  priceOption: {
    marginTop: '10px',

    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        width: '138px',
        height: '32px',
        borderRadius: '4px',
      },
    },
    '& .eip1-MuiAutocomplete-input': {
      padding: '0px 4px !important',
    },
  },
  type: {
    marginTop: '10px',
    marginLeft: '6px',
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        width: '164px',
        height: '32px',
        borderRadius: '4px',
      },
    },
    '& .eip1-MuiAutocomplete-input': {
      padding: '0px 4px !important',
    },
  },
  typeValue: {
    marginTop: '10px',
    marginLeft: '10px',
    width: '164px',
    height: '32px',
    borderRadius: '4px',
  },
  borderIcon: {
    width: '24px',
    borderBottom: 'solid 1px',
  },
  IconDown: {
    width: '24px',
  },
  arrowUp: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  arrowdown: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  arrow: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '32px',
    width: '12px',
    textAlign: 'center',
    borderLeft: 'solid 1px',
  },
  formGroup: {
    marginTop: '16px',
  },
  textTitle: {
    '& p': {
      fontSize: '16px ',
      fontWeight: 600,
    },
  },
}));
interface ComponentProps {
  onSubmit?: (data) => void;
  data: any;
  setAnchorEl: (element: any) => void;
}

const unitType: OptionSelectType[] = [
  {
    label: 'VND',
    value: 'VND',
  },
  {
    label: 'PHP',
    value: 'PHP',
  },
];

const CellComponent = ({ onSubmit, data, setAnchorEl }: ComponentProps) => {
  const rowData = data;
  const classes = useStyles();
  const endAdornment = React.useRef<HTMLDivElement>();
  const [currency, setCurrentcy] = React.useState(data.data.keyword_bidding_price.currency);
  const [price, setPrice] = React.useState<number>(rowData.data.keyword_bidding_price.value || 0);
  return (
    <>
      <HeaderList className={classes.textTitle} title="Edit Bidding price" />
      <Divider />
      <SelectSearchForm
        className={classes.type}
        options={unitType}
        value={currency}
        onChange={(e) => {
          setCurrentcy(e);
        }}
      />
      <OutlinedInput
        className={classes.typeValue}
        onChange={(e) => {
          setPrice(Number(e.target.value));
        }}
        value={price || 0}
        endAdornment={
          <InputAdornment position="end" ref={endAdornment}>
            <div className={classes.arrow}>
              <div onClick={() => setPrice(price + 1)} className={classes.borderIcon}>
                <Icon className={classes.arrowUp} type={'arrowup'} size="10px" />
              </div>
              <div onClick={() => setPrice(price - 1)} className={classes.IconDown}>
                <Icon className={classes.arrowdown} type={'arrowdowncustom'} size="10px" />
              </div>
            </div>
          </InputAdornment>
        }
      />
      <Grid container direction="row" justify="flex-end" className={classes.formGroup} spacing={3}>
        <Grid item>
          <Button
            onClick={() => {
              setAnchorEl(null);
            }}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={() => {
              onSubmit({
                index: data.rowIndex,
                currency: currency,
                price: price,
              });
              setAnchorEl(null);
            }}
            variant="contained"
            color="primary"
          >
            Apply
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
export default CellComponent;
