import { GridApi } from '@ag-grid-community/core';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles(() => {
  return {
    tooltip: {
      top: '1em',
      position: 'absolute',
      '& p': {
        border: '1px solid #eee',
        background: '#fff',
        padding: '0.5em',
      },
    },
  };
});
export function HeaderTooltip(props: { rowIndex?: number; api: GridApi }) {
  const isHeader = props.rowIndex === undefined;
  const classes = useStyles();

  if (!isHeader) return null;

  if (isHeader) {
    const selectedRow = props.api.getSelectedRows();
    return (
      <div className={classes.tooltip}>
        <p>{selectedRow.length} selected item(s)</p>
      </div>
    );
  }
}
