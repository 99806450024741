import { EIP_CONSTANT, eipRequest, aim } from '@eip/next/lib/main';
import { get, set, zipObject, uniq, cloneDeep, isString, groupBy, escape } from 'lodash';
import moment from 'moment';

import {
  cacheAsyncRequest,
  checkEpsiloTableEndpoint,
  default as dataRequest,
  epsiloTableEndpoint,
  EpsiloTableObject,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';
import { addonMiddleware } from '@ep/insight-ui/system/util/addon-middleware';
import { hasCohortProperties } from './column-enhance';
import { getTableContext, produceQueryResult } from './table-addon';
import produce from 'immer';
import { isFormulaField } from '@ep/insight-ui/system/util/excel-formula';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { universalPrefixListField } from '@ep/insight-ui/sw/util/column';
import * as swRequest from '@ep/insight-ui/sw/util/request';
import { getApiHost } from '@ep/insight-ui/eo2/global';
import { produceColumns } from '@ep/insight-ui/sw/etable/service';
import { splitComma } from '@ep/insight-ui/system/helper/functions';

const ADS_CAMPAIGN = 'ADS_CAMPAIGN';
const ADS_OBJECT = 'ADS_OBJECT';
const ADS_PLACEMENT = 'ADS_PLACEMENT';
const ADS_CALENDAR = 'ADS_CALENDAR';

export const syncValueFormat = {
  SHOPEE: {
    [ADS_CAMPAIGN]: 'App\\Models\\AdsOpsShpAdsCampaign',
    [ADS_OBJECT]: 'App\\Models\\AdsOpsShpAdsObject',
    [ADS_PLACEMENT]: 'App\\Models\\AdsOpsShpAdsPlacement',
    [ADS_CALENDAR]: 'App\\Models\\AdsOpsAdsCalendar',
  },
  LAZADA: {
    [ADS_CAMPAIGN]: 'App\\Models\\AdsOpsLzdAdsCampaign',
    [ADS_OBJECT]: 'App\\Models\\AdsOpsLzdAdsObject',
    [ADS_PLACEMENT]: 'App\\Models\\AdsOpsLzdAdsPlacement',
    [ADS_CALENDAR]: 'App\\Models\\AdsOpsAdsCalendar',
  },
};

const reversedSyncValueFormat = {
  'App\\Models\\AdsOpsShpAdsCampaign': ADS_CAMPAIGN,
  'App\\Models\\AdsOpsShpAdsObject': ADS_OBJECT,
  'App\\Models\\AdsOpsShpAdsPlacement': ADS_PLACEMENT,
  'App\\Models\\AdsOpsLzdAdsCampaign': ADS_CAMPAIGN,
  'App\\Models\\AdsOpsLzdAdsObject': ADS_OBJECT,
  'App\\Models\\AdsOpsLzdAdsPlacement': ADS_PLACEMENT,
  'App\\Models\\AdsOpsAdsCalendar': ADS_CALENDAR,
};

const cached = {
  storefrontList: null,
  storefrontListHeaders: [],
};
async function getInsightStorefrontList() {
  if (!cached.storefrontList) {
    cached.storefrontList = eipRequest
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_storefront.jsp')
      .then((result) => {
        const sfList = [].concat(result.data).reduce((carry, item) => {
          return { ...carry, [item.id]: item };
        }, {});
        cached.storefrontList = sfList;
        return sfList;
      });
  }

  return Promise.resolve(cached.storefrontList);
}

async function getOperationStorefrontList() {
  return cacheAsyncRequest('operation.storefront', () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryListing, {
        attributes: [
          'storefront.id',
          'storefront.storefront_sid',
          'storefront.name',
          'storefront.company_name',
          'storefront.country_name',
          'storefront.country_code',
          'storefront.marketplace_name',
          'storefront.marketplace_code',
        ],
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: [row['storefront.marketplace_name'], row['storefront.country_name'], row['storefront.name']].join(
                ' / ',
              ),
              value: row['storefront.id'],
              payload: {
                sid: row['storefront.storefront_sid'],
                country: {
                  code: row['storefront.country_code'],
                },
                channel: {
                  id: row['storefront.marketplace_code'],
                },
              },
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

async function getOperationCountryList() {
  return cacheAsyncRequest('operation.country', () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryListing, {
        attributes: ['storefront.country_name', 'storefront.country_code'],
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: row['storefront.country_name'],
              value: row['storefront.country_code'],
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

async function getOperationMarketplaceList() {
  return cacheAsyncRequest('operation.marketplace', () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryListing, {
        attributes: ['storefront.marketplace_name', 'storefront.marketplace_code'],
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: row['storefront.marketplace_name'],
              value: row['storefront.marketplace_code'],
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

async function getQualityStorefrontList() {
  return cacheAsyncRequest('quality.storefront', () => {
    return eipRequest.get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2_quality_storefront.jsp').then((result) => {
      const sfList = [].concat(result.data).reduce((carry, item) => {
        return { ...carry, [item.id]: item };
      }, {});
      return sfList;
    });
  });
}

export const generateFillDates = (dateFrom, dateTo, period) => {
  switch (period) {
    case 'regularly': {
      const diff10Mins = (moment(dateTo).add(86399, 'second').diff(dateFrom, 'h') + 1) * 6;
      const ranges = new Array(diff10Mins).fill(0).map((_, index) => {
        return moment(dateTo)
          .add(1, 'day')
          .subtract(10 * (index + 1), 'm');
      });

      return ranges.map((h) => h.format('YYYY-MM-DD HH:mm:ss'));
    }
    case 'hourly': {
      const diffHours = moment(dateTo).add(86399, 'second').diff(dateFrom, 'h') + 1;
      const ranges = new Array(diffHours).fill(0).map((_, index) => {
        return moment(dateTo).add(82800, 'second').subtract(index, 'h');
      });

      return ranges.map((h) => h.format('YYYY-MM-DD HH:mm:ss'));
    }
    case 'daily': {
      const diffDays = moment(dateTo).diff(dateFrom, 'd') + 1;
      const ranges = new Array(diffDays).fill(0).map((_, index) => {
        return moment(dateTo).subtract(index, 'd');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'weekly': {
      const weekStart = moment(dateFrom).startOf('W');
      const weekEnd = moment(dateTo).startOf('W');

      const diffPeriod = moment(weekEnd).diff(weekStart, 'w') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(weekEnd).subtract(index, 'w');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'monthly': {
      const monthStart = moment(dateFrom).startOf('M');
      const monthEnd = moment(dateTo).startOf('M');

      const diffPeriod = moment(monthEnd).diff(monthStart, 'M') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(monthEnd).subtract(index, 'M');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'quarterly': {
      const quarterStart = moment(dateFrom).startOf('quarter');
      const quarterEnd = moment(dateTo).startOf('quarter');

      const diffPeriod = moment(quarterEnd).diff(quarterStart, 'quarter') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(quarterEnd).subtract(index, 'quarter');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'yearly': {
      const yearStart = moment(dateFrom).startOf('year');
      const yearEnd = moment(dateTo).startOf('year');

      const diffPeriod = moment(yearEnd).diff(yearStart, 'year') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(yearEnd).subtract(index, 'year');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    default:
      return [];
  }
};

export function enhanceDataRequest2(params, originRequest, backbone) {
  const endpoint = backbone.config.endpoint.GET_TABLE_DATA;
  return addonMiddleware(
    originRequest,
    async function enhanceMopQuery2(params, originRequest, backbone, next) {
      if (checkEpsiloTableEndpoint(endpoint)) {
        return handleDataRequest({ ...params, isSummary: true }, next, backbone);
      }
      const { GET_MARKET_STATUS, GET_MARKET_STATUS_DETAIL } = get(backbone, ['config', 'endpoint'], {});
      const isGroupedBy = get(params, ['groupBy'], null);
      const isDrillDown = get(params, ['groupBy', 'drillDowns'], null);
      if ((GET_MARKET_STATUS || GET_MARKET_STATUS_DETAIL) && (!isGroupedBy || (isGroupedBy && isDrillDown))) {
        const result = await next(params, originRequest, backbone);

        const marketSyncIds = get(result, ['data', 'rows'], []).reduce((a, b) => {
          const marketplace = b['MARKETPLACE.marketplace_code'];
          const adsCampaignIds = get(a, [marketplace, ADS_CAMPAIGN], []);
          const newAdsCampaignId = b['ADS_CAMPAIGN.id'];
          const adsObjectIds = get(a, [marketplace, ADS_OBJECT], []);
          const newAdsObjectId = b['ADS_OBJECT.id'];
          const adsPlacementIds = get(a, [marketplace, ADS_PLACEMENT], []);
          const newAdsPlacementId = b['ADS_PLACEMENT.id'];
          const calendarIds = get(a, [marketplace, ADS_CALENDAR], []);
          const newCalendarCampaignId = b['ADS_CALENDAR.ADS_CAMPAIGN.id'];
          const newCalendarObjectId = b['ADS_CALENDAR.ADS_OBJECT.id'];

          if (newAdsCampaignId && !adsCampaignIds.includes(newAdsCampaignId)) {
            set(a, [marketplace, ADS_CAMPAIGN], [...adsCampaignIds, newAdsCampaignId]);
          }
          if (newAdsObjectId && !adsObjectIds.includes(newAdsObjectId)) {
            set(a, [marketplace, ADS_OBJECT], [...adsObjectIds, newAdsObjectId]);
          }
          if (newAdsPlacementId && !adsPlacementIds.includes(newAdsPlacementId)) {
            set(a, [marketplace, ADS_PLACEMENT], [...adsPlacementIds, newAdsPlacementId]);
          }
          const newCalendarIds = [];
          if (newCalendarCampaignId && !calendarIds.includes(newCalendarCampaignId)) {
            newCalendarIds.push(newCalendarCampaignId);
          }
          if (newCalendarObjectId && !calendarIds.includes(newCalendarObjectId)) {
            newCalendarIds.push(newCalendarObjectId);
          }
          set(a, [marketplace, ADS_CALENDAR], [...calendarIds, ...newCalendarIds]);
          return a;
        }, {});

        const filters = Object.keys(marketSyncIds).reduce((a, b) => {
          const subFilters = Object.keys(marketSyncIds[b]).map((el) => {
            return {
              combinator: 'and',
              filters: [
                {
                  field: 'ads_ops_buffer_ads.model',
                  operator: 'is',
                  value: get(syncValueFormat, [b, el], ''),
                  dataType: 'string',
                },
                {
                  field: 'ads_ops_buffer_ads.model_id',
                  operator: 'in',
                  value: get(marketSyncIds, [b, el], []),
                  dataType: 'string',
                },
                {
                  field: 'ads_ops_buffer_ads.data_status',
                  operator: 'is',
                  value: '2',
                  dataType: 'string',
                },
              ],
            };
          });
          return [...a, ...subFilters];
        }, []);

        if (filters.length) {
          const bufferResponses = await Promise.all(
            filters.map((filter) => {
              const payload = {
                dimensions: ['ads_ops_buffer_ads'],
                attributes: [
                  'ads_ops_buffer_ads.model_id',
                  'ads_ops_buffer_ads.model',
                  'ads_ops_buffer_ads.field_name',
                  'ads_ops_buffer_ads.marketplace_code',
                  'storefront.name',
                  'storefront.marketplace_name',
                  'ads_ops_buffer_ads.marketplace_code',
                  'ads_ops_buffer_ads.trace_id',
                  'ads_ops_buffer_ads.new_value',
                  'ads_ops_buffer_ads.old_value',
                  'ads_ops_buffer_ads.updated_at',
                  'ads_ops_buffer_ads.updated_by',
                  'ads_ops_buffer_ads.data_status',
                  'ads_ops_buffer_ads.storefront_id',
                  'ads_ops_dropdownlabel.id',
                  'ads_ops_dropdownlabel.description',
                  'user.name',
                ],
                metrics: [],
                pagination: {
                  page: 1,
                  limit: 99999,
                },
                filter,
                hiddenFilter: {
                  currency: 'USD',
                },
                currency: 'USD',
                isSummary: true,
              };

              return eipRequest.post(GET_MARKET_STATUS || GET_MARKET_STATUS_DETAIL, payload);
            }),
          );

          const formattedResult = bufferResponses.map((response) => {
            const clone = cloneDeep(response);
            const headers = get(clone, ['data', 'headers'], []);
            const formattedRows = get(clone, ['data', 'rows'], []).map((el) => {
              return headers.reduce((a, b, i) => {
                return {
                  ...a,
                  [b]: el[i],
                };
              }, {});
            });
            set(clone, ['data', 'rows'], formattedRows);
            return clone;
          });

          formattedResult.forEach((response) =>
            produceQueryResult(response, get(backbone, ['config', 'mapping'], {}), true),
          );

          const additionalObject = formattedResult.reduce((a, b) => {
            const rows = get(b, ['rows'], []);

            for (const row of rows) {
              const dimension = reversedSyncValueFormat[row['ads_ops_buffer_ads.model']];
              set(a, [dimension, row['ads_ops_buffer_ads.model_id']], {
                ...get(a, [dimension, row['ads_ops_buffer_ads.model_id']], {}),
                ...(GET_MARKET_STATUS_DETAIL
                  ? {
                      [`${dimension}.${row['ads_ops_buffer_ads.field_name']}`]: {
                        ...row,
                        status: row['ads_ops_buffer_ads.data_status'],
                        updated_at: row['ads_ops_buffer_ads.updated_at'],
                        error_message: row['ads_ops_dropdownlabel.description'],
                      },
                    }
                  : { [row['ads_ops_buffer_ads.field_name']]: row }),
              });
            }

            return a;
          }, {});

          const mappedRows = get(result, ['data', 'rows'], []).map((row) => {
            const additionalData = {
              ...get(additionalObject, [ADS_CAMPAIGN, row['ADS_CAMPAIGN.id']], {}),
              ...get(additionalObject, [ADS_OBJECT, row['ADS_OBJECT.id']], {}),
              ...get(additionalObject, [ADS_PLACEMENT, row['ADS_PLACEMENT.id']], {}),
              ...Object.keys(get(additionalObject, [ADS_CALENDAR, row['ADS_CALENDAR.ADS_CAMPAIGN.id']], {})).reduce(
                (a, b) => {
                  return {
                    ...a,
                    [b.replace(ADS_CALENDAR, `${ADS_CALENDAR}.${ADS_CAMPAIGN}`)]: get(
                      additionalObject,
                      [ADS_CALENDAR, row['ADS_CALENDAR.ADS_CAMPAIGN.id'], b],
                      {},
                    ),
                  };
                },
                {},
              ),
              ...Object.keys(get(additionalObject, [ADS_CALENDAR, row['ADS_CALENDAR.ADS_OBJECT.id']], {})).reduce(
                (a, b) => {
                  return {
                    ...a,
                    [b.replace(ADS_CALENDAR, `${ADS_CALENDAR}.${ADS_OBJECT}`)]: get(
                      additionalObject,
                      [ADS_CALENDAR, row['ADS_CALENDAR.ADS_OBJECT.id'], b],
                      {},
                    ),
                  };
                },
                {},
              ),
            };
            return {
              ...row,
              data_status: additionalData,
            };
          });

          set(result, ['data', 'rows'], mappedRows);

          return result;
        }
      }

      return next(params, originRequest, backbone);
    },
    async function handleStorefrontMetricTraction(params, originRequest, backbone, next) {
      if (!checkEpsiloTableEndpoint(endpoint)) {
        return next(params, originRequest, backbone);
      }
      const mapping = get(backbone, 'config.mapping', {});
      const columnTraction = Object.values(mapping).find((i: any) => i.cellFormat === 'storefrontMetricTraction');
      const columnTractionKey = Object.keys(mapping).find(
        (el) => mapping[el]?.cellFormat === 'storefrontMetricTraction',
      );
      const metrics = get(backbone, 'config.metric', [])
        .filter((i) => mapping[i])
        .map((i) => {
          return mapping[i].valueGetter.value;
        });
      const isMetricVisible = metrics.includes(columnTractionKey);

      params.metrics = params.metrics.filter((i) => i !== 'metric');

      const isDrillDown = get(params, 'groupBy.drillDowns', false);
      const result = await next(params, backbone);

      // Handle storefront metric traction for performance and insight table here before returning
      // Call listing API using the same params but isSummary = false
      // Map data to result

      if (!isMetricVisible || !columnTraction || !isDrillDown) {
        return result;
      }

      const queryMetrics = get(columnTraction, 'staticValue.selectedMetrics', ['cost']);

      const drillDown = get(params, 'groupBy.drillDowns[0]', {});

      const period = get(backbone, 'config.groupPeriod', 'daily');

      const selectedMetricTraction = get(backbone, 'config.selectedMetricTraction', []);

      const metricParams = selectedMetricTraction ? selectedMetricTraction : queryMetrics;
      const paramTraction = {
        attributes: [].concat(params.attributes),
        metrics: metricParams,
        filter: {
          combinator: 'and',
          filters: [
            { field: drillDown.field, operator: '=', value: drillDown.value },
            { field: 'created_datetime', operator: '>=', value: params.from },
            { field: 'created_datetime', operator: '<=', value: params.to },
          ],
        },
        groupPeriod: get(backbone, 'config.groupPeriod', 'daily'), // to be updated to have period selection on eTable
        hiddenFilter: { currency: 'USD' },
        currency: 'USD',
      };

      const metricResult = await eipRequest.post(
        checkEpsiloTableEndpoint(endpoint) ? endpoint : EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/performance.jsp',
        paramTraction,
      );

      const fillDates = generateFillDates(params.from, params.to, period);
      const storefrontRows = result.data.rows;
      const metricIndex = metricResult.data.headers.reduce((carry, i, index) => {
        return { ...carry, [i]: index };
      }, {});
      const metricRows = metricResult.data.rows;

      let joinFields: string[] = get(columnTraction, 'staticValue.joinFields', 'storefront.id');

      if (isString(joinFields)) {
        joinFields = joinFields.split(',').map((i) => String(i).trim());
      }

      const currencyField: string = get(columnTraction, 'staticValue.currencyField', null);
      const rowIndexMap = joinFields
        .concat('datetime', currencyField)
        .filter((i) => i)
        .reduce((carry, i) => {
          return { ...carry, [i]: metricResult.data.headers.findIndex((h) => i === h) };
        }, {});

      const metricDefs = metricParams.reduce((carry, metric) => {
        const metricDef = get(result, 'data.resourceMetric', []).find((el) => el.value === metric) || {
          label_raw: metric,
          prefix_value: '',
        };
        return { ...carry, [metric]: metricDef };
      }, {});

      const sfMetricRows = storefrontRows.reduce((carry, sf) => {
        const rows = [];
        for (const metric of metricParams) {
          const dtValue = fillDates.reduce((carry, dt) => {
            const foundValue = metricRows.find((r) => {
              return (
                joinFields.every((f) => rowIndexMap[f] > -1 && r[rowIndexMap[f]] === sf[f]) &&
                r[rowIndexMap['datetime']].includes(dt)
              );
            });
            const val = foundValue ? foundValue[metricIndex[metric]] : null;
            return {
              ...carry,
              [dt]: val,
              currency: get(foundValue, [rowIndexMap[currencyField]]),
            };
          }, {});
          const metricDef = metricDefs[metric];
          const metricLabel = metricDef.label_raw;
          rows.push({
            ...sf,
            metric: metricLabel,
            ...dtValue,
            currency:
              metricDef.prefix_value === '$'
                ? dtValue['currency'] || sf[currencyField] || sf['currency']
                : metricDef.prefix_value,
          });
        }
        //Add sort metric
        rows.sort((a, b) => (a.metric.toLowerCase() > b.metric.toLowerCase() ? 1 : -1));
        return carry.concat(rows);
      }, []);

      const enrichResultData = {
        headers: result.data.headers.concat('metric').concat(fillDates),
        rows: sfMetricRows,
      };

      return { ...result, data: { ...result.data, ...enrichResultData } };
    },
    async function pivotColumn(params, originRequest, backbone, next) {
      const isDrillDown = get(params, 'groupBy.drillDowns', false);
      const mapping = get(backbone, 'config.mapping', {});
      const storefrontMetricCol = Object.keys(mapping).find(
        (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
      );
      const endpoint = backbone.config.endpoint.GET_TABLE_DATA;

      const isStorefrontMetricGrouped =
        get(backbone, ['config', 'groupBy', 'columns', '0'], '') === storefrontMetricCol;
      // group by time
      const period = get(backbone, 'config.groupPeriod', 'daily');

      // group by dimension
      const isDimensionPeriod = mapping[period];

      if (isDimensionPeriod) {
        const result = await handleStorefrontMetricDimensionGroupedRequest({
          storefrontMetricCol,
          backbone,
          endpoint,
          params,
          originRequest,
          groupField: mapping[period],
        });
        return result;
      } else if (isStorefrontMetricGrouped) {
        const result = await handleStorefrontMetricGroupedRequest({
          isDrillDown,
          storefrontMetricCol,
          backbone,
          endpoint,
          params,
          originRequest,
        });
        return result;
      } else {
        return next(params, originRequest, backbone);
      }
    },
  )(params, originRequest, backbone);
}

export async function enhanceDataRequest(params, originRequest, backbone) {
  const mapping = get(backbone, 'config.mapping', {});
  const columnTraction = Object.values(mapping).find((i) => i.cellFormat === 'storefrontMetricTraction');
  const columnTractionKey = Object.keys(mapping).find((el) => mapping[el]?.cellFormat === 'storefrontMetricTraction');
  const metrics = get(backbone, 'config.metric', [])
    .filter((i) => mapping[i])
    .map((i) => {
      return mapping[i].valueGetter.value;
    });
  const isMetricVisible = metrics.includes(columnTractionKey);

  const metricTractionCols = Object.values(mapping).filter((i) => i.cellFormat === 'metricTraction');
  const metricTractionColsValue = metricTractionCols.map((el) => el.valueGetter.value);
  const metricTractionColsAvailable = metricTractionColsValue.filter((el) => metrics.includes(el));

  const endpoint = backbone.config.endpoint.GET_TABLE_DATA;

  const wrappedOriginRequest = async (params) => {
    if (checkEpsiloTableEndpoint(endpoint)) {
      return handleDataRequest({ ...params, isSummary: true }, originRequest, backbone);
    }
    return originRequest(params);
  };

  params.metrics = checkEpsiloTableEndpoint(endpoint)
    ? params.metrics.filter((i) => i !== 'metric')
    : params.metrics.filter((i) => i !== 'metric' && !metricTractionColsValue.includes(i));

  const isDrillDown = get(params, 'groupBy.drillDowns', false);

  const storefrontMetricCol = Object.keys(mapping).find(
    (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
  );

  const isStorefrontMetricGrouped = get(backbone, ['config', 'groupBy', 'columns', '0'], '') === storefrontMetricCol;
  // group by time
  const period = get(backbone, 'config.groupPeriod', 'daily');

  // group by dimension
  const isDimensionPeriod = mapping[period];

  if (isStorefrontMetricGrouped) {
    const result = await handleStorefrontMetricGroupedRequest({
      isDrillDown,
      storefrontMetricCol,
      backbone,
      endpoint,
      params,
      originRequest,
    });
    return result;
  } else if (isDimensionPeriod) {
    const result = await handleStorefrontMetricDimensionGroupedRequest({
      storefrontMetricCol,
      backbone,
      endpoint,
      params,
      originRequest,
      groupField: mapping[period],
    });
    return result;
  }

  const result = await wrappedOriginRequest(params);
  const storefrontIds = get(result, 'data.rows', [])
    .map((i) => i['STOREFRONT.id'])
    .filter((el) => el);

  if (!checkEpsiloTableEndpoint(endpoint) && metricTractionColsAvailable.length > 0) {
    const queryPerfParams: any = {
      attributes: ['COUNTRY.country_code', 'tool_code', 'storefront_id', 'tool_code'],
      metrics: metricTractionColsAvailable,
      filter: {
        combinator: 'and',
        filters: [
          { field: 'created_datetime', operator: '>=', value: params.from },
          { field: 'created_datetime', operator: '<=', value: params.to },
        ],
      },
      is_summary: true,
      hiddenFilter: { currency: 'USD' },
    };

    if (get(params, 'groupBy.dimensions', []).includes('COUNTRY.country_code') && !isDrillDown) {
      queryPerfParams.group_by = ['country_code'];
    } else if (get(params, 'groupBy.dimensions', []).includes('MARKETPLACE.marketplace_code') && !isDrillDown) {
      queryPerfParams.group_by = ['marketplace_code'];
    } else {
      queryPerfParams.filter.filters.push({ field: 'storefront_id', operator: 'in', value: storefrontIds });
    }

    const cohortConfig = get(backbone, 'config.calendarCohort', null);
    let queryCohortParams;
    if (ff.metric_cohort_column && hasCohortProperties(backbone) && cohortConfig) {
      const calStartDate = moment(params.from, 'YYYY-MM-DD');
      const calEndDate = moment(params.to, 'YYYY-MM-DD');
      const diffDays = calEndDate.diff(calStartDate, 'day');
      let calStartDateCohort, calEndDateCohort;
      switch (cohortConfig) {
        case 'previous':
          calStartDateCohort = moment(calStartDate).subtract(diffDays + 1, 'days');
          calEndDateCohort = moment(calEndDate).subtract(diffDays + 1, 'days');
          break;
        case 'last_month':
          calStartDateCohort = moment(calStartDate).subtract(1, 'months');
          calEndDateCohort = moment(calEndDate).subtract(1, 'months');
          break;
        case 'last_year':
          calStartDateCohort = moment(calStartDate).subtract(1, 'years');
          calEndDateCohort = moment(calEndDate).subtract(1, 'years');
          break;
        default:
          calStartDateCohort = moment(cohortConfig.dateFrom, 'YYYY-MM-DD');
          calEndDateCohort = moment(cohortConfig.dateTo, 'YYYY-MM-DD');
          break;
      }
      queryCohortParams = {
        ...queryPerfParams,
        filter: {
          combinator: 'and',
          filters: [
            { field: 'created_datetime', operator: '>=', value: calStartDateCohort.format('YYYY-MM-DD') },
            { field: 'created_datetime', operator: '<=', value: calEndDateCohort.format('YYYY-MM-DD') },
          ],
        },
        from: calStartDateCohort.format('YYYY-MM-DD'),
        to: calEndDateCohort.format('YYYY-MM-DD'),
      };
    }

    const results = await Promise.all([
      eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/performance.jsp', queryPerfParams),
      ...(ff.metric_cohort_column && queryCohortParams
        ? [eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/performance.jsp', queryCohortParams)]
        : [Promise.resolve()]),
    ]);

    results.forEach((el, index) => {
      if (el && el.code === 200) {
        const headers = el.data.headers;
        const storefrontMetricData = el.data.rows.map((el) => {
          return el.reduce(
            (a, b, i) => ({
              ...a,
              [headers[i]]: b,
            }),
            {},
          );
        });

        let joinField = 'storefront.id';
        if (get(queryPerfParams, 'group_by', []).includes('country_code')) {
          joinField = 'country.code';
        } else if (get(queryPerfParams, 'group_by', []).includes('marketplace_code')) {
          joinField = 'marketplace.code';
        }

        const mapFieldToPerf = {
          'storefront.id': 'STOREFRONT.id',
          'country.code': 'COUNTRY.country_code',
          'marketplace.code': 'MARKETPLACE.marketplace_code',
        };

        const storefrontMetricSumData = storefrontMetricData.reduce((a, b) => {
          const metric = index === 0 ? b.metric : `${b.metric}_cohort`;
          if (!a[b[joinField]] || !a[b[joinField]][metric]) {
            a[b[joinField]] = {
              ...a[b[joinField]],
              [metric]: b.value,
            };
          } else {
            a[b[joinField]][metric] += b.value;
          }
          return a;
        }, {});

        result.data.headers = result.data.headers.concat(
          metricTractionColsAvailable.map((el) => (index === 0 ? el : `${el}_cohort`)),
        );

        result.data.rows = result.data.rows.map((el) => ({
          ...el,
          ...storefrontMetricSumData[el[mapFieldToPerf[joinField]]],
        }));
      }
    });
  }

  // Handle storefront metric traction for performance and insight table here before returning
  // Call listing API using the same params but isSummary = false
  // Map data to result

  if (!isMetricVisible || !columnTraction || !isDrillDown) {
    return result;
  }

  const queryMetrics = get(columnTraction, 'staticValue.selectedMetrics', ['cost']);
  let joinFields: string[] = get(columnTraction, 'staticValue.joinFields', 'storefront.id');
  const currencyField: string = get(columnTraction, 'staticValue.currencyField', null);

  if (isString(joinFields)) {
    joinFields = joinFields.split(',').map((i) => String(i).trim());
  }

  const drillDown = get(params, 'groupBy.drillDowns[0]', {});

  const selectedMetricTraction = get(backbone, 'config.selectedMetricTraction', []);

  const metricParams = selectedMetricTraction ? selectedMetricTraction : queryMetrics;
  const paramTraction = {
    attributes: currencyField ? [drillDown.field].concat(currencyField) : [drillDown.field],
    metrics: metricParams,
    filter: {
      combinator: 'and',
      filters: [
        { field: drillDown.field, operator: '=', value: drillDown.value },
        { field: 'created_datetime', operator: '>=', value: params.from },
        { field: 'created_datetime', operator: '<=', value: params.to },
      ],
    },
    groupPeriod: get(backbone, 'config.groupPeriod', 'daily'), // to be updated to have period selection on eTable
    hiddenFilter: { currency: 'USD' },
    currency: 'USD',
  };

  const metricResult = await eipRequest.post(
    checkEpsiloTableEndpoint(endpoint) ? endpoint : EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/performance.jsp',
    paramTraction,
  );

  const fillDates = generateFillDates(params.from, params.to, period);
  const storefrontRows = result.data.rows;

  const metricIndex = metricResult.data.headers.reduce((carry, i, index) => {
    return { ...carry, [i]: index };
  }, {});

  const metricRows = metricResult.data.rows;
  const rowIndexMap = joinFields
    .concat('datetime', currencyField)
    .filter((i) => i)
    .reduce((carry, i) => {
      return { ...carry, [i]: metricResult.data.headers.findIndex((h) => i === h) };
    }, {});

  const sfMetricRows = storefrontRows.reduce((carry, sf) => {
    const rows = [];
    for (const metric of metricParams) {
      const dtValue = fillDates.reduce((carry, dt) => {
        const foundValue = metricRows.find((r) => {
          return (
            joinFields.every((f) => rowIndexMap[f] > -1 && r[rowIndexMap[f]] === sf[f]) &&
            r[rowIndexMap['datetime']].includes(dt)
          );
        });
        const val = foundValue ? foundValue[metricIndex[metric]] : null;
        return {
          ...carry,
          [dt]: val,
          currency: get(foundValue, [rowIndexMap[currencyField]]),
        };
      }, {});
      const metricLabel = get(result, 'data.resourceMetric', []).find((el) => el.value === metric)?.label_raw || metric;
      rows.push({
        ...sf,
        metric: metricLabel,
        ...dtValue,
        currency: dtValue['currency'] || sf[currencyField] || sf['currency'],
      });
    }
    //Add sort metric
    rows.sort((a, b) => (a.metric.toLowerCase() > b.metric.toLowerCase() ? 1 : -1));
    return carry.concat(rows);
  }, []);

  const enrichResultData = {
    headers: result.data.headers.concat('metric').concat(fillDates),
    rows: sfMetricRows,
  };

  return { ...result, data: { ...result.data, ...enrichResultData } };
}

const getNamespace = (endpoint) => {
  const endpointQueryParams = new URLSearchParams(endpoint.split('?')[1] || '');
  const params = Object.fromEntries(endpointQueryParams.entries());
  return get(params, 'namespace', '');
};

const getMetricDefinition = (endpoint) => {
  const namespace = getNamespace(endpoint);
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
    return dataRequest.getPerformanceMetrics();
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
    return dataRequest.getOperationMetrics();
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
    return dataRequest.getEpsiloMetric(EpsiloTableObject.QUALITY);
  }
  if (namespace) {
    return dataRequest.getOneMetricOptions(namespace);
  }
  return Promise.resolve([]);
};

const handleDataRequest = async (originalParams, originalRequest, backbone) => {
  const params = cloneDeep(originalParams);
  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');
  const isDisplayAllBlock = get(backbone, 'config.chartConfig.config.displayAllBlock', false);

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { dimensions: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { dimensions: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { dimensions: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  params.filter = {
    ...get(params, 'filter', { combinator: 'AND', filters: [] }),
    filters: [
      ...get(params, 'filter.filters', []),
      { field: 'created_datetime', operator: '>=', value: params.from },
      { field: 'created_datetime', operator: '<=', value: params.to },
    ],
  };

  if (params.groupBy) {
    params.groupBy.column = params.groupBy.dimensions;
  }
  if (!params.groupBy?.column && params.groupBy?.aggregations) {
    params.groupBy.column = null;
  }
  if (get(params, 'groupBy.drillDowns')) {
    params.filter.filters = params.filter.filters.concat({
      field: get(params, 'groupBy.drillDowns[0].field'),
      operator: '=',
      value: get(params, 'groupBy.drillDowns[0].value'),
    });
    params.groupBy = undefined;
  }

  if (get(backbone, 'config.chartConfig.config.isTotalQuery', false)) {
    params.groupAll = true;
  } else {
    params.groupAll = false;
  }

  const isMultiple = get(backbone, 'config.chartConfig.config.display', '') === 'multiple';

  // save table request params to config to download data
  set(backbone, 'config.tableParams', params);
  const mapping = get(backbone, 'config.mapping', {});

  let foundCurrencyField;
  for (const column of Object.values(mapping)) {
    const foundItem = Object.entries(get(column, 'valueGetter', {})).find(([k, v]) => k === 'currency');
    if (foundItem) {
      foundCurrencyField = foundItem[1] !== '' ? foundItem[1] : null;
    }
  }

  const tableType = get(backbone, 'config.tableType', '');

  const cohortConfig = get(backbone, 'config.calendarCohort', null);
  const cohortDateRangeConfig = get(backbone, 'config.cohortDateRange', null);
  const cohortDateRangeFrom = get(cohortDateRangeConfig, 'dateFrom', '');
  const cohortDateRangeTo = get(cohortDateRangeConfig, 'dateTo', '');
  let queryCohortParams;
  const diffDateCohort = cohortDateRangeConfig ? moment(params.to).diff(moment(cohortDateRangeTo), 'day') : null;
  if (ff.metric_cohort_column && hasCohortProperties(backbone) && cohortConfig) {
    queryCohortParams = {
      ...params,
      ...params.hiddenFilter,
      filter: {
        combinator: 'and',
        filters: [
          ...get(params, 'filter.filters', []).filter((f) => f.field !== 'created_datetime'),
          {
            field: 'created_datetime',
            operator: '>=',
            value: cohortDateRangeFrom,
          },
          {
            field: 'created_datetime',
            operator: '<=',
            value: cohortDateRangeTo,
          },
        ],
      },
    };
  }

  const queryCohortParamsGrouped = {
    ...queryCohortParams,
    groupPeriod: 'all',
    groupBy:
      queryCohortParams && queryCohortParams.groupBy ? { ...queryCohortParams.groupBy, column: null } : undefined,
    ...(isMultiple ? { groupAll: true } : {}),
  };

  const results = await Promise.all([
    originalRequest({ ...params, ...params.hiddenFilter }),
    ...(queryCohortParams
      ? [
          originalRequest(
            backbone.addon('datasource.getRowsParams', () => queryCohortParams)({ params: queryCohortParams }, {}),
          ),
        ]
      : [Promise.resolve()]),
    ...(tableType === 'metric' // Calculate total of original value for chart
      ? [
          originalRequest({
            ...params,
            ...params.hiddenFilter,
            groupPeriod: 'all',
            groupBy: params.groupBy ? { ...params.groupBy, column: null } : undefined,
            ...(isMultiple ? { groupAll: true } : {}),
          }),
        ]
      : [Promise.resolve()]),
    ...(tableType === 'metric' && queryCohortParams // Calculate total of cohort value for chart
      ? [
          originalRequest(
            backbone.addon('datasource.getRowsParams', () => queryCohortParamsGrouped)(
              { params: queryCohortParamsGrouped },
              {},
            ),
          ),
        ]
      : [Promise.resolve()]),
    getMetricDefinition(endpoint),
    ...(isDisplayAllBlock
      ? [originalRequest({ ...params, ...params.hiddenFilter, groupBy: undefined, groupPeriod: 'all', groupAll: true })]
      : [Promise.resolve()]),
  ]);

  const result = results.reduce((a, b, index) => {
    if (index === 1) {
      const rows = get(b, 'data.rows', []);
      const bHeaders = get(b, 'data.headers', []);

      if (rows.length > 0) {
        a.data.rows = a.data.rows.map((el) => {
          const newData = {};
          if (tableType !== 'metric') {
            let matchProps = bHeaders.filter((header) => get(params, 'attributes', []).includes(header));
            const groupByCol = get(params, ['groupBy', 'column'], []) || [];
            if (groupByCol.length > 0) {
              matchProps = groupByCol;
            }
            const data = rows.find((row) => {
              return matchProps.every((prop) => el[prop] === row[prop]);
            });
            if (data) {
              const dataCohortRow = Object.keys(data).reduce((a, b) => {
                a[b] = data[b];
                if (b.split('.')[1]) a[b.split('.')[1]] = data[b];
                return a;
              }, {});
              Object.keys(dataCohortRow).forEach((el) => {
                if (el !== 'storefront.id') {
                  newData[`${el}_cohort`] = dataCohortRow[el];
                }
              });
            }
          } else {
            const dataCohort = rows.find(
              (ele) => moment(el.datetime).subtract(diffDateCohort, 'days').format('YYYY-MM-DD') === ele.datetime,
            );

            if (dataCohort) {
              const dataCohortRow = Object.keys(dataCohort).reduce((a, b) => {
                a[b] = dataCohort[b];
                if (b.split('.')[1]) a[b.split('.')[1]] = dataCohort[b];
                return a;
              }, {});

              Object.keys(dataCohortRow).forEach((key) => (newData[`${key}_cohort`] = dataCohortRow[key]));
            }
          }
          return {
            ...el,
            ...newData,
          };
        });
        return a;
      }
      return a;
    }
    if (index === 2) {
      const rows = get(b, 'data.rows', []);
      if (rows.length > 0) {
        a.data.rows = a.data.rows.map((row) => {
          const rowTotal =
            rows.find((el) => el[get(params, ['groupBy', 'column', '0'], 'storefront.id')] === row.id) || rows[0];
          const handledRowTotal = Object.keys(rowTotal).reduce((a, b) => {
            a[b] = rowTotal[b];
            if (b.split('.')[1]) a[b.split('.')[1]] = rowTotal[b];
            return a;
          }, {});
          return {
            ...row,
            total: handledRowTotal,
          };
        });
      }
      return a;
    }
    if (index === 3) {
      const rows = get(b, 'data.rows', []);
      if (rows.length > 0) {
        a.data.rows = a.data.rows.map((row) => {
          const rowTotal = rows.find((el) => el['storefront.id'] === row.id) || rows[0];
          const handledRowTotal = Object.keys(rowTotal).reduce((a, b) => {
            a[b] = rowTotal[b];
            if (b.split('.')[1]) a[b.split('.')[1]] = rowTotal[b];
            return a;
          }, {});
          return {
            ...row,
            cohort_total: handledRowTotal,
          };
        });
      }
      return a;
    }
    if (index === 4) {
      if (b.length > 0) {
        a.data.rows = a.data.rows.map((row) => ({
          ...row,
          resourceMetric: b,
        }));
        a.data.resourceMetric = b;
      }
      return a;
    }
    if (index === 5) {
      const rows = get(b, 'data.rows', []);
      const rm = [];
      const resourceMetric = get(a, 'data.resourceMetric', []);
      if (rows.length > 0) {
        Object.keys(rows[0]).forEach((el) => {
          const value = resourceMetric.filter((ele) => ele.value === el);
          rm.push(...value);
        });
        const newValue = rows.map((el) => {
          return {
            ...el,
            'storefront.id': 0,
            'storefront.storefront_name': 'All',
            'storefront.country_code': 'All',
            'storefront.marketplace_code': 'All',
            currency: params.hiddenFilter?.currency,
            resourceMetric: rm,
          };
        });
        a.data.rows = [{ ...newValue[0] }, ...a.data.rows];
      }
      return a;
    }

    const rows = get(b, 'data.rows', []);
    const mainHeaders = get(b, 'data.headers', []);
    set(b, 'data.originalHeaders', mainHeaders);
    const masterData = get(b, 'data.masterData', {});

    const entityRelations = mainHeaders.filter((i) => i.indexOf('.id') > 0).map((i) => i.replace('.id', ''));
    const mapEntity = entityRelations.reduce((carry, entityType) => {
      const headers = masterData[entityType]?.headers.map((i) => `${entityType}.${i}`) || [];
      const rows = masterData[entityType]?.rows || [];
      return {
        ...carry,
        [entityType]: rows.reduce((carry, row, index) => {
          const obj = zipObject(headers, row);
          const id = obj[`${entityType}.id`];
          return { ...carry, [id]: obj };
        }, {}),
      };
    }, {});

    const enrichedRows = rows.map((i) => {
      let item: any = { ...i };
      entityRelations.forEach((entityType) => {
        item = { ...item, ...mapEntity[entityType][i[`${entityType}.id`]] };
      });

      return {
        ...item,
        currency: foundCurrencyField ? item[foundCurrencyField] : params.hiddenFilter?.currency,
      };
    });

    set(b, 'data.rows', enrichedRows);
    set(b, 'data.headers', enrichedRows[0] ? Object.keys(enrichedRows[0]) : mainHeaders);
    // set(b, 'data.headers', Object.keys(Object.values(storefrontList)[0]));
    if (params.groupAll) {
      set(
        b,
        'data.rows',
        enrichedRows.map((i) => {
          return {
            'storefront.id': 0,
            'storefront.storefront_name': 'All',
            'storefront.country_code': 'All',
            'storefront.marketplace_code': 'All',
            ...i,
          };
        }),
      );
      set(b, 'data.masterData', {
        storefront: {
          id: 0,
          storefront_name: 'All',
          country_code: 'All',
          marketplace_code: 'All',
        },
      });
    }

    return b;
  }, {});

  return result;
};

async function getNamespaceStorefrontList(namespace, mapping) {
  const storefrontValueGetter = get(mapping, ['storefront', 'valueGetter'], {});
  const storefrontAttributes = uniq(
    [storefrontValueGetter.id, storefrontValueGetter.value, storefrontValueGetter.label].filter((el) => !!el),
  );
  const countryValueGetter = get(mapping, ['country', 'valueGetter'], {});
  const countryAttributes = uniq(
    [countryValueGetter.id, countryValueGetter.value, countryValueGetter.label].filter((el) => !!el),
  );
  const marketplaceValueGetter = get(mapping, ['marketplace', 'valueGetter'], {});
  const marketplaceAttributes = uniq(
    [marketplaceValueGetter.id, marketplaceValueGetter.value, marketplaceValueGetter.label].filter((el) => !!el),
  );
  return cacheAsyncRequest(`namespace.storefront.${namespace}`, () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing.jsp?namespace=' + namespace, {
        attributes: [
          'storefront.id',
          ...storefrontAttributes,
          // ...[namespace === 'insight' ? 'storefront.storefront_name' : 'storefront.name'],
          ...countryAttributes,
          ...marketplaceAttributes,
        ].concat(namespace === 'insight' ? 'storefront.storefront_name' : 'storefront.name'),
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
                [stripUniversalPrefix(headers[i])]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: [
                row[marketplaceValueGetter.label || marketplaceValueGetter.value || marketplaceValueGetter.id],
                row[countryValueGetter.label || countryValueGetter.value || countryValueGetter.id],
                row[storefrontValueGetter.label || storefrontValueGetter.value || storefrontValueGetter.id] ||
                  row['storefront.storefront_name'],
              ].join(' / '),
              value: row['storefront.id'],
              payload: {
                sid: row['storefront.storefront_sid'],
                country: {
                  code: row[countryValueGetter.value || countryValueGetter.id || countryValueGetter.label],
                },
                channel: {
                  id: row[marketplaceValueGetter.value || marketplaceValueGetter.id || marketplaceValueGetter.label],
                },
              },
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

async function getNamespaceCountryList(namespace) {
  return cacheAsyncRequest(`namespace.country.${namespace}`, () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing.jsp?namespace=' + namespace, {
        attributes: ['storefront.country_name', 'storefront.country_code'],
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
                [stripUniversalPrefix(headers[i])]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: row['storefront.country_name'],
              value: row['storefront.country_code'],
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

async function getETableList({ type, field, config }) {
  const valueGetter = get(config, ['configuration', 'mapping', field, 'valueGetter'], {});
  const filterField = get(config, ['configuration', 'mapping', field, 'filterField'], '');
  const { id, value, label } = valueGetter;
  const attributes = uniq([id, value, label, filterField].filter((el) => !!el));
  return cacheAsyncRequest(`${type}.${field}.listing`, () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + `/v2_${type}_listing.jsp`, {
        attributes: attributes,
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
                [stripUniversalPrefix(headers[i])]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: row[label || value || id || filterField],
              value: row[filterField || value || id || label],
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

function stripUniversalPrefix(field) {
  return field.replace(/\.(a_|m_)/, '.');
}

const LISTING_TIMEOUT = 7900;
// const LISTING_TIMEOUT = 900;
async function getNamespaceList({
  namespace,
  field,
  config,
  getColumnFields,
  payload,
  selectedValues = [],
  forceReload = false,
}: any) {
  const valueGetter = get(config, ['configuration', 'mapping', field, 'valueGetter'], {});
  const rawFilter = get(config, ['configuration', 'mapping', field, 'filterField'], '');
  let filterField = rawFilter;
  filterField = filterField?.split(',')?.[0] || filterField;
  const { id, value, label, subtext: subTextField } = valueGetter;
  const attributes = uniq(
    [id, value, label, filterField].filter((el) => !!el && !isFormulaField(el) && !el.endsWith('.id')),
  );
  const labelField = String(label || value || id || filterField);
  const valueField = String(filterField || value || id || label);
  const filterFieldParam = String(filterField || value || id);
  const searchKeyword = get(payload, 'search', '');

  const queryParamsString =
    String(get(config, ['configuration', 'endpoint', 'GET_TABLE_DATA'], ''))?.split('?')[1] || '';

  return cacheAsyncRequest(
    `namespace.${field}.${namespace}${searchKeyword ? `.${searchKeyword.toLowerCase()}` : ''}${(
      selectedValues || []
    ).join('.')}`,
    () => {
      return eipRequest
        .post(
          EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing.jsp?' + queryParamsString,
          {
            // '@eTableConfig': config.configuration, // thinking about how to connect with the sw for enrich query return
            // 'disableFeatures': ['upstream_formula'],
            ...universalPrefixListField({
              attributes: getColumnFields('attribute').filter((el) => !!el && !isFormulaField(el)),
              metrics: getColumnFields('metric').filter(
                (el) =>
                  !!el &&
                  get(config, ['configuration', 'mapping', el, 'cellFormat']) !== 'storefrontMetricTraction' &&
                  !isFormulaField(el),
              ),
            }),
            groupBy: {
              dimensions: attributes,
            },
            pagination: {
              page: 1,
              limit: 999,
            },
            sort: [
              searchKeyword
                ? { field: `LENGTH({${filterFieldParam}})`, sort: 'ASC' }
                : { field: filterFieldParam, sort: 'ASC' },
            ],
            filter: {
              combinator: 'and',
              filters: [
                ...(searchKeyword
                  ? [
                      splitComma(rawFilter).length > 1
                        ? {
                            combinator: 'or',
                            filters: rawFilter.split(',').map((i) => {
                              return {
                                dataType: 'string',
                                field: i.trim(),
                                operator: 'contains',
                                value: String(searchKeyword).normalize('NFKD'),
                              };
                            }),
                          }
                        : {
                            dataType: 'string',
                            field: filterFieldParam,
                            operator: 'contains',
                            value: String(searchKeyword).normalize('NFKD'),
                          },
                    ]
                  : []),
                ...(selectedValues || []).map((el) => ({
                  dataType: 'string',
                  field: filterFieldParam,
                  operator: 'is_not',
                  value: el,
                })),
              ],
            },
          },
          {},
          { timeout: LISTING_TIMEOUT },
        )
        .then((result) => {
          const headers = get(result, 'data.headers', []);
          const rows = get(result, 'data.rows', []);

          const data = rows
            .map((row) => {
              return row.reduce((a, b, i) => {
                return {
                  ...a,
                  [headers[i]]: b,
                  [stripUniversalPrefix(headers[i])]: b,
                };
              }, {});
            })
            .map((row) => {
              return {
                label: isFormulaField(labelField)
                  ? toValue(labelField, { ...row, value: row[valueField] })
                  : row[labelField],
                // subtext: subTextField
                //   ? isFormulaField(subTextField)
                //     ? toValue(subTextField, { ...row })
                //     : row[subTextField]
                //   : null,
                value: row[valueField] === undefined ? row[labelField] : row[valueField],
              };
            });

          return {
            data,
            success: true,
            message: 'OK',
            lastUpdatedAt: new Date().getTime(),
            field,
          };
        })
        .catch((e) => {
          return {
            data: [],
            success: false,
            message: e.message,
            lastUpdatedAt: new Date().getTime(),
            field,
          };
        });
    },
    forceReload,
  );
}

async function getNamespaceMarketplaceList(namespace) {
  return cacheAsyncRequest(`namespace.marketplace.${namespace}`, () => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing.jsp?namespace=' + namespace, {
        attributes: ['storefront.marketplace_name', 'storefront.marketplace_code'],
      })
      .then((result) => {
        const headers = get(result, 'data.headers', []);
        const rows = get(result, 'data.rows', []);

        const data = rows
          .map((row) => {
            return row.reduce((a, b, i) => {
              return {
                ...a,
                [headers[i]]: b,
                [stripUniversalPrefix(headers[i])]: b,
              };
            }, {});
          })
          .map((row) => {
            return {
              label: row['storefront.marketplace_name'],
              value: row['storefront.marketplace_code'],
            };
          });

        return {
          data,
          success: true,
          message: 'OK',
        };
      })
      .catch((e) => {
        return {
          data: [],
          success: false,
          message: '',
        };
      });
  });
}

export {
  getInsightStorefrontList,
  handleDataRequest,
  getQualityStorefrontList,
  getOperationStorefrontList,
  getOperationCountryList,
  getOperationMarketplaceList,
  getMetricDefinition,
  getNamespaceCountryList,
  getNamespaceMarketplaceList,
  getNamespaceStorefrontList,
  getNamespaceList,
  getETableList,
};

export const exportInsightTable = async (params, backbone) => {
  const dateFrom = get(params, 'from', '');
  const dateTo = get(params, 'to', '');
  const title = backbone.getConfig('title').replace(/\-/g, '').replace(/\s+/g, '_');
  const fileName = [title, dateFrom, dateTo].filter((el) => !!el).join('_');

  const mapping = backbone.getConfig('mapping');
  const headers = [...params.attributes, ...params.metrics]
    .map((el) => {
      const col = Object.keys(mapping).find((ele) => Object.values(mapping[ele].valueGetter).includes(el));
      if (col) {
        return {
          name: mapping[col].title,
          id: el,
        };
      }
      return null;
    })
    .filter((el) => !!el);

  const extra = {
    headers,
    fileName,
  };

  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { column: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { column: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { column: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  const cohortDateRange = backbone.getConfig('cohortDateRange');

  try {
    const response = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_download.jsp', {
      ...params,
      pagination: undefined,
      extra,
      ...(cohortDateRange ? { cohort: { from: cohortDateRange.dateFrom, to: cohortDateRange.dateTo } } : {}),
    });

    if (response.code === 200) {
      window.location.href = `${EIP_CONSTANT.API_HOST.API_DATA_CENTER}/insight_download_get.jsp?id=${response.id}`;
      return {
        title: 'Data exporting is in progress',
        messages: 'Your file will be downloaded in a few moment.',
        variant: 'success',
      };
    } else {
      return {
        title: 'Sorry. Your requested data couldn’t be exported',
        messages: response.message,
        variant: 'error',
      };
    }
  } catch (e) {
    return {
      title: 'Sorry. Your requested data couldn’t be exported',
      messages: 'Please try again later',
      variant: 'error',
    };
  }
};

export const exportOperationTable = async (params, backbone) => {
  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { column: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { column: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { column: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  const dateFrom = get(params, 'from', '');
  const dateTo = get(params, 'to', '');
  const title = backbone.getConfig('title').replace(/\-/g, '').replace(/\s+/g, '_');
  const fileName = [title, dateFrom, dateTo].filter((el) => !!el).join('_');

  const mapping = backbone.getConfig('mapping');
  const headers = [...params.attributes, ...params.metrics]
    .map((el) => {
      const col = Object.keys(mapping).find((ele) => Object.values(mapping[ele].valueGetter).includes(el));
      if (col) {
        return {
          name: mapping[col].title,
          id: el,
        };
      }
      return null;
    })
    .filter((el) => !!el);

  const extra = {
    headers,
    fileName,
  };

  const cohortDateRange = backbone.getConfig('cohortDateRange');

  try {
    const response = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_download.jsp', {
      ...params,
      pagination: undefined,
      extra,
      ...(cohortDateRange ? { cohort: { from: cohortDateRange.dateFrom, to: cohortDateRange.dateTo } } : {}),
    });

    if (response.code === 200) {
      window.location.href = `${EIP_CONSTANT.API_HOST.API_DATA_CENTER}/insight_download_get.jsp?id=${response.id}`;
      return {
        title: 'Data exporting is in progress',
        messages: 'Your file will be downloaded in a few moment.',
        variant: 'success',
      };
    } else {
      return {
        title: 'Sorry. Your requested data couldn’t be exported',
        messages: response.message,
        variant: 'error',
      };
    }
  } catch (e) {
    return {
      title: 'Sorry. Your requested data couldn’t be exported',
      messages: 'Please try again later',
      variant: 'error',
    };
  }
};

export const customExportTable = async (params, backbone) => {
  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');
  const queryParams = getQueryParams(endpoint);
  const maximumExportDays = get(backbone, ['config', 'system', 'maximumExportDays'], 90);

  let exportEndpoint = 'insight_download_get.jsp';
  const matchEndpoint = Object.keys(EpsiloTableObject).find((el) => checkEpsiloTableEndpoint(endpoint, el));
  if (matchEndpoint) {
    exportEndpoint = epsiloTableEndpoint[EpsiloTableObject[matchEndpoint]].export;
  }

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { column: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { column: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { column: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  const dateFrom = get(params, 'from', '');
  const dateTo = get(params, 'to', '');

  const mFrom = moment(dateFrom, 'YYYY-MM-DD');
  const mTo = moment(dateTo, 'YYYY-MM-DD');

  if (mTo.diff(mFrom, 'days') > maximumExportDays) {
    return {
      title: 'Sorry. Your requested data couldn’t be exported',
      messages: `Our limit export date range is ${maximumExportDays} days. If you need more than ${maximumExportDays} days, please contact us.`,
      variant: 'error',
    };
  }

  const title = backbone.getConfig('title').replace(/\-/g, '').replace(/\s+/g, '_');
  const fileName = [title, dateFrom, dateTo].filter((el) => !!el).join('_');

  const columns = produceColumns(backbone.config).map((el) => el.field);
  const mapping = backbone.getConfig('mapping');
  const validFieldToExport = ['value', 'label', 'hashtag', 'subtext', 'tooltip'];
  const APIFields = [...params.attributes, ...params.metrics];

  const headers = columns.reduce((carry, columnKey) => {
    const value = get(mapping, [columnKey], {});
    const valueGetter = get(value, ['valueGetter'], null);
    if (!valueGetter) return carry;
    const validValue = Object.entries(valueGetter)
      .filter(([k, v]) => {
        return validFieldToExport.includes(k) && APIFields.includes(v);
      })
      .reduce((a, b) => {
        return {
          ...a,
          [b[0]]: b[1],
        };
      }, {});
    const mainColumn = validValue['label'] || validValue['value'];
    carry.push({
      name: value.title,
      id: mainColumn,
    });
    Object.entries(validValue)
      .filter(([k]) => !['label', 'value'].includes(k))
      .forEach(([k, v]) => {
        carry.push({
          name: value.title + ' ' + k,
          id: v,
        });
      });
    return carry;
  }, []);

  const extra = {
    headers,
    fileName,
  };

  const cohortDateRange = backbone.getConfig('cohortDateRange');

  const initUrl = queryParams.namespace ? `/v2/export.jsp?namespace=${queryParams.namespace}` : '/insight_download.jsp';

  const workspaceId = aim.getUserSettings()?.profile?.workspace_id;
  const pageId = window.location.href.match(/page\/.*?\//)?.[0]?.split('/')[1];
  try {
    swRequest.setAuthorizationHeader({
      authorization: `Bearer ${aim.getLoginToken().token}`,
      isSandbox: location.href.includes('isSandbox=1') ? '1' : '0',
      workspace: String(workspaceId),
      page: pageId,
    });
    const response = await swRequest.post(getApiHost(EIP_CONSTANT.API_HOST.API_DATA_CENTER) + initUrl, {
      ...params,
      pagination: undefined,
      extra,
      ...(cohortDateRange ? { cohort: { from: cohortDateRange.dateFrom, to: cohortDateRange.dateTo } } : {}),
    });

    const redirectUrl = queryParams.namespace
      ? `v2/export.jsp?namespace=${queryParams.namespace}&id=${response.id}`
      : `${exportEndpoint}?id=${response.id}`;

    if (response.code === 200) {
      blobFileDownload(
        `${getApiHost(EIP_CONSTANT.API_HOST.API_DATA_CENTER)}/${redirectUrl}`,
        aim.getLoginToken().token,
        backbone.getConfig('title'),
        { workspaceId },
      );
      return {
        title: 'Data exporting is in progress',
        messages: 'Your file will be downloaded in a few moment.',
        variant: 'success',
      };
    } else {
      return {
        title: 'Sorry. Your requested data couldn’t be exported',
        messages: response.message,
        variant: 'error',
      };
    }
  } catch (e) {
    return {
      title: 'Sorry. Your requested data couldn’t be exported',
      messages: 'Please try again later',
      variant: 'error',
    };
  }
};

function blobFileDownload(file, token, title, { workspaceId }) {
  const anchor = document.createElement('a');
  document.body.appendChild(anchor);

  const headers = new Headers();
  headers.append('Authorization', 'Bearer ' + token);
  if (workspaceId) {
    headers.append('X-User-Workspace', workspaceId);
  }

  fetch(file, { headers })
    .then((response) => {
      // CORS block getting content-disposition header get file name from response header
      // let contentDisposition = response.headers.get('content-disposition');
      // let fileName = contentDisposition.split('filename=')[1].replace(/"/g, '');
      // anchor.download = fileName;
      return response.blob();
    })
    .then((blobby) => {
      const objectUrl = window.URL.createObjectURL(blobby);
      anchor.download = title + '.csv';
      anchor.href = objectUrl;
      anchor.click();
      window.URL.revokeObjectURL(objectUrl);
    });
}

const handleStorefrontMetricGroupedRequest = async ({
  isDrillDown,
  storefrontMetricCol,
  backbone,
  endpoint,
  params,
  originRequest,
}) => {
  const mapping = get(backbone, 'config.mapping', {});
  const resourceMetric = await getMetricDefinition(endpoint);
  if (isDrillDown) {
    const drillDownValueLabel = get(params, ['groupBy', 'drillDowns', '0', 'value'], '');

    const drillDownValue = get(
      resourceMetric.find((el) => el.label_raw === drillDownValueLabel),
      ['value'],
      '',
    );

    const period = get(backbone, 'config.groupPeriod', 'daily');
    const storefrontDrillDownParams = {
      ...params,
      groupBy: undefined,
      isSummary: undefined,
      metrics: [drillDownValue],
      groupPeriod: period,
    };

    const result = await originRequest(storefrontDrillDownParams);

    const mainHeaders = get(result, 'data.headers', []);
    const masterData = get(result, 'data.masterData', {});

    const entityRelations = mainHeaders.filter((i) => i.indexOf('.id') > 0).map((i) => i.replace('.id', ''));
    const mapEntity = entityRelations.reduce((carry, entityType) => {
      const headers = masterData[entityType]?.headers.map((i) => `${entityType}.${i}`) || [];
      const rows = masterData[entityType]?.rows || [];
      return {
        ...carry,
        [entityType]: rows.reduce((carry, row, index) => {
          const obj = zipObject(headers, row);
          const id = obj[`${entityType}.id`];
          return { ...carry, [id]: obj };
        }, {}),
      };
    }, {});

    const fillDates = generateFillDates(params.from, params.to, period);

    const enhanceRows = Object.values(groupBy(get(result, ['data', 'rows'], []), 'storefront.id')).map((el) => {
      const fillDatesValue = fillDates.reduce((a, b) => {
        const foundValue = el.find((ele) => ele.datetime?.includes(b));

        return {
          ...a,
          [b]: foundValue ? foundValue[drillDownValue] : null,
        };
      }, {});

      let item = {};
      entityRelations.forEach((entityType) => {
        item = { ...mapEntity[entityType][el[0][`${entityType}.id`]] };
      });

      return { ...fillDatesValue, ...item };
    });

    const enhanceHeaders = Object.keys(enhanceRows[0]);

    return { ...result, data: { ...result.data, headers: enhanceHeaders, rows: enhanceRows } };
  } else {
    const storefrontMetric = mapping[storefrontMetricCol];
    const selectedMetrics = get(backbone, ['config', 'selectedMetricTraction'], []);
    const storefrontMetricField = get(storefrontMetric, ['valueGetter', 'value'], 'metric');

    return {
      success: true,
      message: 'ok',
      data: {
        headers: [storefrontMetricField],
        rows: selectedMetrics.map((el) => {
          const metricInfo = resourceMetric.find((ele) => ele.value === el);
          return {
            [storefrontMetricField]: metricInfo.label_raw,
          };
        }),
      },
    };
  }
};

const handleStorefrontMetricDimensionGroupedRequest = async ({
  storefrontMetricCol,
  backbone,
  endpoint,
  params,
  originRequest,
  groupField,
}) => {
  const mapping = get(backbone, 'config.mapping', {});
  const resourceMetric = await getMetricDefinition(endpoint);

  const storefrontMetric = mapping[storefrontMetricCol];
  const selectedMetrics = get(backbone, ['config', 'selectedMetricTraction'], []);
  const properties = get(backbone, ['config', 'metricTractionProperties'], []);
  const storefrontMetricField = get(storefrontMetric, ['valueGetter', 'value'], 'metric');
  const idField = get(groupField, ['valueGetter', 'id'], '');
  const currency = get(params, ['hiddenFilter', 'currency'], '');

  const pivot = await backbone.addon('pivot.get', async () => {
    return { values: [], queryField: null };
  })();

  if (pivot.values.length === 0) return { success: true, data: { rows: [], headers: [] } };

  const selectedOrderedMetrics = properties.filter(({ id }) => selectedMetrics.includes(id)).map(({ id }) => id);

  const filter = produce(params.filter || { combinator: 'AND', filters: [] }, (draft) => {
    set(
      draft,
      'filters',
      get(draft, 'filters', []).concat({ field: pivot.queryField, operator: 'IN', value: pivot.values }),
    );
  });

  const requestParams = {
    ...params,
    filter: filter,
    table_context: getTableContext(mapping),
    attributes: [pivot.queryField],
    groupBy: { column: [pivot.queryField] },
    isSummary: true,
    metrics: selectedOrderedMetrics,
  };

  const result = await originRequest(requestParams);

  const groupedEnrichedRows = produceQueryResult(result, mapping, true);

  const groupedRows = selectedOrderedMetrics.map((el) => {
    const metricInfo = resourceMetric.find((ele) => ele.value === el);
    // const findRow = groupedEnrichedRows.rows.find(i => i[pivot.queryField]);
    const item = groupedEnrichedRows.rows.reduce((carry, r) => {
      const groupCol = escape(`value_${r[pivot.queryField]}`);
      return { ...carry, [groupCol]: r[el] };
    }, {});

    const metricDetail = resourceMetric.find((ele) => ele.value === el) || {};

    return {
      ...item,
      [storefrontMetricField]: metricInfo.label_raw,
      staticCurrency: metricDetail.prefix_value === '$' ? currency : metricDetail.prefix_value,
    };
  });

  if (groupedRows.length > 0) {
    const headers = Object.keys(groupedRows[0]);

    return {
      success: true,
      message: 'ok',
      data: {
        headers: headers,
        rows: groupedRows,
      },
    };
  } else {
    return {
      success: true,
      message: 'ok',
      data: {
        headers: [],
        rows: [],
      },
    };
  }
};
