import * as React from 'react';
import moment from 'moment';

import { Grid, makeStyles } from '@material-ui/core';

import FormControlTemplate from './form-control-template';
import clsx from 'clsx';
import TimeRange from './time-range';
import { debounce } from 'lodash';

const weekOptions = [
  {
    label: 'S',
    value: 'Sun',
  },
  {
    label: 'M',
    value: 'Mon',
  },
  {
    label: 'T',
    value: 'Tue',
  },
  {
    label: 'W',
    value: 'Wed',
  },
  {
    label: 'T',
    value: 'Thu',
  },
  {
    label: 'F',
    value: 'Fri',
  },
  {
    label: 'S',
    value: 'Sat',
  },
];

const useStyles = makeStyles(() => ({
  weekContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  weekChild: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    background: '#f1f3f4',
    color: '#80868b',
    cursor: 'pointer',
    fontSize: '10px',
    fontWeight: 500,
    '&.active': {
      background: '#1a73e8',
      color: 'white',
    },
  },
}));

const RepeatingOn = ({ repeatingValue, value, onChange, disabled }) => {
  if (!['WEEKLY', 'CUSTOM'].includes(repeatingValue)) return null;
  const ChildComponent = React.useMemo(() => {
    if (repeatingValue == 'WEEKLY') return Week;
    if (repeatingValue == 'CUSTOM') return Custom;
  }, [repeatingValue]);
  return (
    <Grid item xs={12}>
      <ChildComponent value={value} onChange={onChange} disabled={disabled} />
    </Grid>
  );
};

const Week = ({ value, onChange, disabled }) => {
  const classes = useStyles();

  const [selectedDays, setSelectedDays] = React.useState(value || []);

  const handleSelect = (value) => {
    setSelectedDays((prevValue) => {
      if (prevValue.includes(value)) return prevValue.filter((i) => i !== value);
      return prevValue.concat(value);
    });
  };

  const handleChange = React.useCallback(debounce(onChange, 1000), []);
  React.useEffect(() => {
    handleChange(selectedDays);
  }, [selectedDays]);

  return (
    <FormControlTemplate label={'Repeating On'}>
      <div className={classes.weekContainer}>
        {weekOptions.map((i) => {
          return (
            <div
              key={i.value}
              className={clsx(classes.weekChild, [].concat(selectedDays).includes(i.value) ? 'active' : '')}
              onClick={() => handleSelect(i.value)}
              style={disabled ? { pointerEvents: 'none' } : {}}
            >
              {i.label}
            </div>
          );
        })}
      </div>
    </FormControlTemplate>
  );
};

const Custom = ({ value, onChange, disabled }) => {
  const [timeRange, setTimeRange] = React.useState(() => {
    if (value) {
      return {
        dateFrom: value.from,
        dateTo: value.to,
      };
    }

    return {
      dateFrom: moment().format('YYYY-MM-DD'),
      dateTo: '',
    };
  });

  const handleChange = React.useCallback(debounce(onChange, 100), []);
  React.useEffect(() => {
    handleChange({
      to: timeRange.dateTo,
      from: timeRange.dateFrom,
    });
  }, [timeRange]);

  return (
    <TimeRange
      label={'Repeating On'}
      value={timeRange}
      dateFormat={'YYYY-MM-DD'}
      onChange={(value) => setTimeRange(value)}
      disabled={disabled}
    />
  );
};

export default RepeatingOn;
