import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Export = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 10.0165H1C0.734784 10.0165 0.48043 10.121 0.292893 10.307C0.105357 10.493 0 10.7452 0 11.0083C0 11.2713 0.105357 11.5235 0.292893 11.7095C0.48043 11.8955 0.734784 12 1 12H9C9.26522 12 9.51957 11.8955 9.70711 11.7095C9.89464 11.5235 10 11.2713 10 11.0083C10 10.7452 9.89464 10.493 9.70711 10.307C9.51957 10.121 9.26522 10.0165 9 10.0165Z" />
    <path d="M4.42688 8.78891C4.50046 8.86358 4.58835 8.92291 4.68537 8.96341C4.7824 9.00392 4.88661 9.02479 4.99188 9.02479C5.09716 9.02479 5.20137 9.00392 5.2984 8.96341C5.39542 8.92291 5.48331 8.86358 5.55688 8.78891L7.99688 6.41866C8.07217 6.34569 8.132 6.25853 8.17284 6.16231C8.21369 6.06608 8.23473 5.96273 8.23473 5.85833C8.23473 5.75393 8.21369 5.65058 8.17284 5.55435C8.132 5.45813 8.07217 5.37097 7.99688 5.298C7.84549 5.14792 7.64093 5.0625 7.42688 5.05998H5.99688L6 0.991735C6 0.728711 5.89464 0.476459 5.70711 0.290473C5.51957 0.104486 5.26522 0 5 0C4.73478 0 4.48043 0.104486 4.29289 0.290473C4.10536 0.476459 4 0.728711 4 0.991735L3.99688 5.05998H2.59688C2.38471 5.05998 2.18123 5.14357 2.0312 5.29236C1.88117 5.44115 1.79688 5.64295 1.79688 5.85337C1.79065 5.95605 1.80512 6.05893 1.83947 6.156C1.87381 6.25306 1.92732 6.34236 1.99688 6.41866L4.42688 8.78891Z" />
  </SvgIcon>
);

export default Export;
