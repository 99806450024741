import * as React from 'react';
import * as _ from 'lodash';
import WrapperFormat from './wrapper-format';
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles({
  container: {
    // position: 'relative',
    padding: '0 17px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  label: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
});
const SimpleGroupFormat = (props: any) => {
  const classes = useStyles();
  const isGroup = _.get(props, 'node.group', false);
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const label = _.get(props, 'value.label', '');
  const value = _.get(props, 'value', null);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <div className={classes.container}>
        <div className={classes.label} style={{ paddingLeft: isGroup ? '17px' : '0px' }}>
          {label}
        </div>
      </div>
    </WrapperFormat>
  );
};
export default SimpleGroupFormat;
