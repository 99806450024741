import React from 'react';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../utils/utils';
import iconLazada from './channel-lazada.svg';
import iconShopee from './channel-shopee.svg';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    margin: '25px 0',
    fontWeight: 600,
  },
  title: {
    fontSize: 18,
    color: '#88939E',
    '& img': {
      height: 18,
    },
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  number: {
    fontSize: 24,
  },
  total: {
    color: '#FFD600',
    fontSize: 48,
    textAlign: 'center',
    lineHeight: '50px',
  },
  numberTarget: {
    marginTop: 4,
    fontSize: 14,
    fontWeight: 500,
    color: '#65758B',
  },
  totalToday: {
    color: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    marginTop: 24,
  },
  totalTitle: {
    color: '#88939E',
    fontSize: 20,
  },
  small: {
    fontSize: '.5em',
  },
  channelTotalToday: {
    fontSize: 14,
    color: '#e0e0e0',
    marginTop: 12,
    marginBottom: 6,
  },
  '@global': {
    '.odometer.odometer-auto-theme': {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
      // fontFamily: '"Courier New", Courier, monospace',
    },
  },
});

export const OverviewInfo = (props: any) => {
  const classes = useStyles();
  const {
    totalGMV,
    shopeeGMV,
    lazadaGMV,
    totalGMVTarget,
    shopeeGMVTarget,
    lazadaGMVTarget,
    currency: pCurrency,
    today,
    campaignName,
  } = props;
  const currency = '';

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="flex-end" spacing={3}>
        <Grid item xs={12}>
          <div className={classes.totalTitle}>{campaignName}</div>
          <div className={`${classes.number} ${classes.totalToday}`}>
            <span>Today&nbsp;&nbsp;</span>
            <span>{formatCurrency(today.totalGMV)}</span>
            <span>&nbsp;&nbsp;</span>
          </div>
          <div className={`${classes.number} ${classes.total}`}>
            <Odometer value={totalGMV} format={'(,ddd)'} />
            <span style={{ lineHeight: '13px', fontSize: '13px' }}>{currency}</span>
          </div>
          <div className={classes.numberTarget}>
            {totalGMV && <span>{Number((100 * totalGMV) / totalGMVTarget).toFixed(2)}% of </span>}
            {formatCurrency(totalGMVTarget)} {currency}
          </div>
        </Grid>
        <Grid item xs={6} className={classes.left}>
          <div className={classes.title}>
            <img src={iconShopee} />
            Shopee
          </div>
          <div className={`${classes.number} ${classes.channelTotalToday}`}>
            Today {today.shopeeGMV > 0 ? formatCurrency(today.shopeeGMV) : '-'} {currency}
          </div>
          <div className={classes.number}>
            {shopeeGMV > 0 ? formatCurrency(shopeeGMV) : '-'}
            <small className={classes.small}>{currency}</small>
            {/* {currency} */}
          </div>
          <div className={classes.numberTarget}>
            {!shopeeGMV && !shopeeGMVTarget ? (
              <React.Fragment>
                <span>{Number((100 * shopeeGMV) / shopeeGMVTarget).toFixed(2)}% of </span>
                {formatCurrency(shopeeGMVTarget)} {currency}
              </React.Fragment>
            ) : (
              <span>-</span>
            )}
          </div>
        </Grid>
        <Grid item xs={6} className={classes.right}>
          <div className={classes.title}>
            Lazada
            <img src={iconLazada} />
          </div>
          <div className={`${classes.number} ${classes.channelTotalToday}`}>
            Today {formatCurrency(today.lazadaGMV)} {currency}
          </div>
          <div className={classes.number}>
            {formatCurrency(lazadaGMV)}
            <small className={classes.small}>{currency}</small>
            {/* {currency} */}
          </div>
          <div className={classes.numberTarget}>
            {lazadaGMV && <span>{Number((100 * lazadaGMV) / lazadaGMVTarget).toFixed(2)}% of </span>}
            {formatCurrency(lazadaGMVTarget)} {currency}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
