import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddEdit = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.34798 11.2522C2.23678 11.3643 2.1488 11.4973 2.08909 11.6435C2.02937 11.7897 1.99911 11.9462 2.00002 12.1041V13.4C2.00002 13.5591 2.06323 13.7117 2.17574 13.8243C2.28825 13.9368 2.44084 14 2.59996 14H3.89582C4.05374 14.0009 4.21028 13.9706 4.35646 13.9109C4.50265 13.8512 4.63562 13.7632 4.74774 13.652L11.947 6.45259L9.54724 4.05279L2.34798 11.2522Z" />
    <path d="M13.6508 3.05687L12.9429 2.34892C12.7181 2.12544 12.414 2 12.097 2C11.78 2 11.4759 2.12544 11.2511 2.34892L10.3992 3.20085L12.7989 5.60066L13.6268 4.77273C13.7427 4.66259 13.8354 4.5304 13.8994 4.38394C13.9635 4.23748 13.9976 4.07969 13.9999 3.91985C14.0021 3.76 13.9724 3.60132 13.9124 3.45312C13.8525 3.30493 13.7636 3.1702 13.6508 3.05687Z" />
  </SvgIcon>
);

export default AddEdit;
