import * as React from 'react';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import Icon from '@ep/insight-ui/icons/Icon';
import { Box, makeStyles } from '@material-ui/core';
import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';
import { OptionSelectType, OptionType } from '@ep/insight-ui/elements/list-control/type';
import MenuListSingle from './list-menu-single';
import clsx from 'clsx';
import { colors, scrollbarStyles } from '@ep/insight-ui/lib/epsilo-theme';

const useStyle = makeStyles(() => ({
  inputDropdown: {
    '& input': {
      padding: '0px',
      height: '32px',
      marginLeft: '5px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .eip1-MuiInputAdornment-root': {
        position: 'absolute',
        right: 0,
        padding: '11px',
        margin: '0px',
        cursor: 'pointer',
        height: 'auto',
      },
      '& .eip1-MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '& input[type=text]:focus': {
      boxShadow: 'none',
    },
  },
  checkbox: {
    marginRight: '-13px',
    width: '100%',
    backgroundColor: 'transparent !important',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    width: (props) => (props.isConfigChart ? 'auto' : '300px'),
    overflow: 'hidden',
    maxHeight: 'calc(100% - 50%)',
    height: 'auto',
    ...scrollbarStyles,
    paddingTop: 0,
    '& ul': {
      height: 'auto',
    },
  },
  totalInput: {
    '&:hover': {
      borderColor: '#006EC6',
    },
    position: 'relative',
    width: '100%',
    borderRadius: '4px',
    border: 'solid 1px #C2C7CB',
    display: 'flex',
    backgroundColor: 'white',
    '& .dropdown': {
      width: '100%',
    },
  },
  noBorder: {
    border: 'none',
  },
  listItem: {
    listStyleType: 'none',
  },
  listItemNoChip: {
    listStyleType: 'none',
    marginTop: '6px',
    marginLeft: '14px',
    color: '#000',
    cursor: 'pointer',
  },
  icon: {
    '&.deleteIcon': {
      width: '10px',
      height: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  error: {
    borderColor: colors.border.critical,
    '&:hover': {
      borderColor: colors.border.critical,
    },
  },
  disabled: {
    borderColor: '#E4E7E9',
    background: '#F6F7F8',
    pointerEvents: 'none',
    '&:hover': {
      borderColor: '#E4E7E9',
    },
    '& $wrapIcon svg': {
      color: '#8C98A4 !important',
    },
  },
  wrapIcon: {
    height: '12px',
    width: '8px',
    marginTop: '8px',
    position: 'absolute',
    right: '9px',
  },
  disabledLabel: {
    color: '#C2C7CB',
  },
}));
const Chip = withStyles(() => ({
  root: {
    height: '24px',
    left: '0px',
    top: '0px',
    background: '#F6F7F8',
    borderRadius: '4px',
    alignItems: 'center',
    border: 0,
    marginTop: '4px',
    marginLeft: '4px',
  },
  deleteIcon: {
    '& svg': {
      color: '#253746',
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
}))(MuiChip);
type IProps = {
  value: string[];
  options: OptionSelectType[];
  multipleSelect?: boolean;
  searchAble?: boolean;
  chip?: boolean;
  onChange: (v: string[]) => void;
  maxDropdownHeight?: string;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  classNamePaper?: string;
  isConfigChart?: boolean;
  onReorder?: (v: any[]) => void;
  handleAfterClosed?: () => void;
};

const SelectMultipleForm = ({
  value = [],
  options,
  multipleSelect = false,
  searchAble = true,
  onChange,
  chip = false,
  maxDropdownHeight,
  disabled,
  error,
  className,
  classNamePaper,
  isConfigChart = false,
  onReorder,
  handleAfterClosed,
}: IProps) => {
  const myInput = React.useRef<HTMLDivElement>();
  const classes = useStyle({ isConfigChart });
  const [open, setOpen] = React.useState(false);
  const handleOnClosed = () => {
    setOpen(false);
    if (handleAfterClosed) handleAfterClosed();
  };
  const handleOpen = () => setOpen(true);
  const optionsDefault: OptionType[] = React.useMemo(() => {
    return [...options].map((item) => {
      let checked = false;
      if (value.includes(item.value)) {
        checked = true;
      }
      const result = {
        label: item.label,
        value: item.value,
        checked,
        endIcon: item.endIcon,
        onEndIconClick: item.onEndIconClick,
      };

      return result;
    });
  }, [value, options]);

  const [optionsState, setOptionsState] = React.useState(optionsDefault);

  const dataChecked: OptionType[] = React.useMemo(() => {
    if (value && value.length > 0) {
      const tempArr: OptionType[] = [];
      const arr = optionsDefault.filter((item) =>
        Array.isArray(value) ? value.includes(item.value) : value == item.value,
      );
      arr.forEach((item) => {
        const exists = tempArr.findIndex((it) => it.value == item.value);
        if (exists == -1) {
          tempArr.push(item);
        }
      });
      return tempArr;
    }
    return [];
  }, [value, optionsDefault]);
  const [limitTags, setLimitTags] = React.useState<number>(2);

  const handleOnChange = (arr: OptionType[]) => {
    onChange(arr.filter((item) => item.checked).map((item) => item.value));
  };

  const handleOnReoder = (arr: OptionType[]) => {
    if (onReorder) {
      handleOnChange(arr);
      onReorder(arr);
    }
  };

  const handleDelete = (data: OptionType) => () => {
    onChange(value.filter((item) => item !== data.value));
  };

  const RenderChip = () => {
    const lengthInput = _.get(myInput, ['current', 'offsetWidth'], 0);
    let totalLengthChip = 0;
    let lengthChipCount = 50;
    if (!chip && !multipleSelect) {
      return (
        <Box className="boxValue" display={'flex'} alignItems={'center'} position={'absolute'} zIndex={10}>
          {dataChecked.map((data, index) => {
            return (
              <li
                className={`${classes.listItemNoChip} ${disabled && classes.disabledLabel}`}
                key={index}
                onClick={handleOpen}
              >
                <span>{_.get(data, ['label'], '')}</span>
              </li>
            );
          })}
        </Box>
      );
    }
    return (
      <Box className="boxValue" display={'flex'} alignItems={'center'} position={'absolute'} zIndex={10}>
        {dataChecked.map((data, index) => {
          return index < limitTags ? (
            <li className={classes.listItem} key={index}>
              <Chip
                ref={(ref) => {
                  const length = ref ? ref.offsetWidth : 0;
                  totalLengthChip += length;
                  if (totalLengthChip > lengthInput * 0.75 - lengthChipCount) {
                    setLimitTags(index + 1);
                  }
                }}
                deleteIcon={
                  <span className={`${classes.icon} deleteIcon`}>
                    <Icon type={'deleteChip'} style={{ height: '100%', width: '100%' }} />
                  </span>
                }
                label={_.get(data, ['label'], '')}
                onDelete={handleDelete(data)}
              />
            </li>
          ) : null;
        })}
        {limitTags && dataChecked.length > limitTags && (
          <Chip
            ref={(ref) => {
              lengthChipCount = ref ? ref.offsetWidth : 0;
            }}
            variant="outlined"
            label={`+ ${dataChecked.length - limitTags}`}
          />
        )}
      </Box>
    );
  };

  return (
    <React.Fragment>
      <div ref={myInput} className="select">
        <div className={clsx(classes.totalInput, error && classes.error, disabled && classes.disabled, className)}>
          {RenderChip()}
          <Dropdown
            disabled={disabled}
            defaultOpen={open}
            handleOnClosed={handleOnClosed}
            classNamePaper={clsx(classes.paper, classNamePaper)}
            alignMenu="left"
            label=""
            sizeIcon="12px"
            className={classes.checkbox}
            maxHeight={maxDropdownHeight}
            {...(ff.improve_script_detail_mobile ? { isMobileFilter: true } : {})}
            parentRef={myInput}
          >
            <React.Fragment>
              {multipleSelect ? (
                <CheckboxList
                  allowCheckAll
                  searchAble={searchAble}
                  items={optionsDefault}
                  onChange={handleOnChange}
                  {...(onReorder ? { draggable: true, onReorder: handleOnReoder, disabledReorderDebounce: true } : {})}
                />
              ) : (
                <MenuListSingle listMenu={optionsDefault} searchAble={searchAble} onChange={handleOnChange} />
              )}
            </React.Fragment>
          </Dropdown>
          <span className={classes.wrapIcon}>
            <Icon
              type={'chevron'}
              style={{
                height: '100%',
                width: '100%',
                color: error ? '#f44336' : '#3F4F5C',
              }}
            />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SelectMultipleForm;
