import { Box, Button, makeStyles, Modal } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useModalStyles = makeStyles(() => ({
  buttonConfirm: {
    padding: '9px 8px',
    color: '#fff',
    backgroundColor: '#D4290D',
    '&:hover': {
      backgroundColor: '#e55c45',
    },
  },
  buttonCancel: {
    padding: '9px 8px',
    marginRight: '8px',
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '480px',
    // height: '164px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '16px 16px',
    outline: 'none',
    overflowY: 'auto',
    transform: 'translate(-50%, -50%)',
    borderRadius: '8px',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'start',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  modalBody: {
    position: 'relative',
    textAlign: 'left',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  modalFooter: {
    textAlign: 'end',
    padding: '10px',
    background: '#FFF',
  },
  modalTitle: {
    fontSize: '24px',
    fontWeight: 500,
    lineHeight: '32px',
    margin: 0,
    color: '#253746',
  },
}));

const useModalMobileStyles = makeStyles(() => ({
  mobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
    overflowY: 'auto',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  mobileButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
  mobileContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    padding: '10px',
  },
}));

interface ModalConfirmProp {
  title?: string;
  message?: string | JSX.Element;
  openModal?: boolean;
  onClose?: () => void;
  action?: () => void;
  btnLabel?: string;
  btnClass?: string;
}

const ModalConfirm = ({
  title,
  message,
  openModal,
  onClose,
  action,
  btnLabel = 'Delete view',
  btnClass = '',
}: ModalConfirmProp) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  const classes = useModalStyles();
  const classesMobile = useModalMobileStyles();

  const handleClose = () => {
    onClose();
  };

  const handleExecuteAction = () => {
    if (action) action();
    onClose();
  };

  return (
    <>
      {!isMobileView ? (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalContainer}>
            <div className={classes.mobileHeader}>
              <h4 className={classes.modalTitle}>{title}</h4>
            </div>
            <div className={classes.modalBody}>
              <Box>{message}</Box>
            </div>
            <div className={classes.modalFooter}>
              <Button className={classes.buttonCancel} onClick={handleClose}>
                Cancel
              </Button>
              <Button
                className={clsx(classes.buttonConfirm, btnClass)}
                color="secondary"
                variant="contained"
                onClick={handleExecuteAction}
              >
                {btnLabel}
              </Button>
            </div>
          </Box>
        </Modal>
      ) : (
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classesMobile.mobileContainer}>
            <div className={classesMobile.mobileHeader}>
              <div className={classesMobile.mobileButtonCancel}>
                <Button color="inherit" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <h4 className={classesMobile.mobileTitle}>{title}</h4>
              <div className={classesMobile.mobileButton}>
                <Button type="submit" onClick={handleExecuteAction} color="primary">
                  Delete View
                </Button>
              </div>
            </div>
            <div className={classesMobile.mobileContent}>{message}</div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default ModalConfirm;
