import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import { getActionMenu, staticFilter } from './common-config';

export const AD_OBJECT_PROGRAMMATIC_SETTING_CONFIG = {
  title: 'Ad object rules',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getScriptData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/programmatic/get-dimensions?table=ADS_OBJECT',
  },
  mainButton: {
    url: getPageUrn('/page/ec22dd25-a14f-40d8-9199-a5b0517e835e'),
    text: 'Create script',
    type: 'link',
  },
  system: {
    hiddenComponents: ['search'],
    filter: staticFilter.programmaticSettings,
  },
  primaryKeys: [
    'SCRIPT.id',
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_OBJECT.id',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'PRODUCT.id',
  ],
  mapping: {
    script: {
      title: 'Script',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT.script_name',
      valueGetter: {
        label: 'SCRIPT.script_name',
        id: 'SCRIPT.id',
        scriptObjectId: 'SCRIPT_OBJECT.id',
      },
      menu: getActionMenu('script'),
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT',
      filterGetter: {
        default: 'ADS_OBJECT.name',
      },
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        productId: 'PRODUCT.id',
        id: 'ADS_OBJECT.id',
      },
      menu: getActionMenu('ad_object'),
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      sortField: 'ADS_CAMPAIGN.name',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.id',
      },
      menu: getActionMenu('campaign'),
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
      menu: getActionMenu('storefront'),
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
      menu: getActionMenu('ad_type'),
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool_name',
        id: 'ADTOOL.ads_tool',
      },
      menu: getActionMenu('ad_tool'),
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: ff.miss_script_params ? 'MARKETPLACE.marketplace_name' : 'MARKETPLACE.marketplace_code',
        id: 'MARKETPLACE.marketplace_code',
      },
      menu: getActionMenu('marketplace'),
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: ff.miss_script_params ? 'COUNTRY.country_name' : 'COUNTRY.country_code',
        id: 'COUNTRY.country_code',
      },
      menu: getActionMenu('country'),
    },

    // ============= ATTRIBUTE =============
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    script_status: {
      title: 'Script status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'SCRIPT.script_status',
      valueGetter: {
        label: 'SCRIPT.script_status',
        status: 'SCRIPT.script_status',
      },
      menu: getActionMenu('script_status'),
    },

    // ============= METRIC =============
    condition: {
      title: 'Condition',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'condition',
      valueGetter: {
        value: 'condition',
      },
      menu: getActionMenu('_metric'),
    },
    // action: {
    //   title: 'Action',
    //   propertyType: 'metric',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'action',
    //   valueGetter: {
    //     value: 'action',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
    // action_count: {
    //   title: 'Action count',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'action_count',
    //   valueGetter: {
    //     value: 'action_count',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
    // message: {
    //   title: 'Message',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'string',
    //   filterField: 'message',
    //   valueGetter: {
    //     value: 'message',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
    // last_action_time: {
    //   title: 'Last action time',
    //   propertyType: 'metric',
    //   cellFormat: 'text',
    //   dataType: 'date',
    //   filterField: 'last_action_time',
    //   valueGetter: {
    //     value: 'last_action_time',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
};
