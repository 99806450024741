import * as React from 'react';
import { get } from 'lodash';

import { makeStyles, TextField, IconButton } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';

import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';
import { textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

interface PropsStyle {
  inputNoShadow: boolean;
  line: number;
  type: string;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  textStyle: (props: PropsStyle) => ({
    ...(textFieldStyle as CreateCSSProperties),
    width: '100%',
    '& input': {
      padding: '0 10px',
      height: '32px',
      caretColor: '#006EC6',
      width: '100%',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        ' -moz-appearance': 'textfield',
      },
    },
    '& .eip1-MuiOutlinedInput-multiline': {
      padding: '10px 10px',
      minHeight: '32px',
      caretColor: '#006EC6',
      width: '100%',
      alignItems: 'flex-start',
      '& textarea': {
        lineHeight: '20px',
        height: props.line > 0 ? `${props.line * 20}px !important` : '20px !important',
      },
    },
    '& .eip1-MuiButtonBase-root': {
      width: 'auto',
      opacity: (props: PropsStyle) => (props.type === 'password' ? 1 : 0),
    },
    '& .eip1-MuiFormHelperText-contained,  & .Mui-error.eip1-MuiFormHelperText-contained': {
      fontSize: '10px',
      margin: '4px 0 0 0',
      '& svg': {
        height: '9px',
        width: '9px',
        marginRight: '5px',
      },
    },
    '& input:-webkit-autofill,& input:-webkit-autofill:hover,& input:-webkit-autofill:focus,& input:-webkit-autofill:active':
      {
        '-webkit-text-fill-color': ' black !important',
        '-webkit-background-clip': 'text',
      },
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
  label: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  textEndAdornment: {
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#8C98A4',
    marginLeft: '10px',
    '& + .iconExpand': {
      marginLeft: '0px !important',
    },
  },
  errorMessage: {
    color: '#d4290d',
    fontSize: '12px',
  },
});
const TextInput = ({
  value,
  onChange,
  placeholder,
  initialValue,
  initialFocus,
  rowData,
  configuration,
  errors,
  fieldKey,
}: any) => {
  const classes = useStyles();

  const textEndAdornmentField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === 'text_end_adornment') || {
      value: '',
    }
  ).value;

  const isNumber = ['int', 'float'].includes(configuration?.data_type);
  const errorMessage = get(errors, String(fieldKey).split('.').concat('message'), '');

  const textEndAdornmentValue = React.useMemo(() => {
    if (!textEndAdornmentField) return '';
    if (textEndAdornmentField.startsWith('=')) return toValue(textEndAdornmentField, rowData);
    return rowData[textEndAdornmentField];
  }, [textEndAdornmentField]);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && initialFocus) {
      ref.current.focus();
    }
  }, [initialFocus]);

  React.useEffect(() => {
    setTimeout(() => {
      onChange({
        target: {
          value: initialValue,
        },
      });
    }, 0);
  }, [initialValue, onChange]);

  return (
    <div className={classes.container}>
      <TextField
        inputRef={ref}
        className={classes.textStyle}
        placeholder={placeholder}
        variant="outlined"
        onChange={onChange}
        value={value}
        InputProps={{
          endAdornment: (
            <>
              {value ? (
                <IconButton
                  tabIndex={-1}
                  onClick={() => {
                    onChange({
                      target: {
                        value: '',
                      },
                    });
                    ref.current.focus();
                  }}
                >
                  <CloseFiled />
                </IconButton>
              ) : null}
              {textEndAdornmentValue ? <span className={classes.textEndAdornment}>{textEndAdornmentValue}</span> : null}
            </>
          ),
        }}
        {...(isNumber ? { type: 'number' } : {})}
      />
      {errorMessage ? <span className={classes.errorMessage}>{errorMessage}</span> : null}
    </div>
  );
};

export default TextInput;
