import { createWorkflow, ValidationError as WorkflowValidationError } from './workflow-backbone';
import { createWorkflowLight, createWorkflowCampaignDetails } from './workflow-light';
import { WorkflowContext, WorkflowLightContext, useWorkflow, useWorkflowScreen } from './context';
import utilFunctions from './util-functions';
import { createRegister as createCampaignWorkflowRegister } from './items/campaign-detail';
import { createRegister as createRuleDetailsWorkflowRegister } from './items/programmatic-script-detail';
import { createRegister as createCamp0Detail } from './items/camp0-detail';
import { createRegister as createHyperAutomationDetail } from './items/hyper-automation-detail';
export type { ScreenInfoRegister, ScreenScenarioRegister } from './items/programmatic-script-detail';
export type { ScreenCampaignInfoRegister, ScreenSetupProductKeywords } from './items/campaign-detail';

const campaignDetailWorkflow = (tableConfig, dataSource?) =>
  createWorkflow(createCampaignWorkflowRegister(dataSource, 'campaignDetailWorkflow'), utilFunctions);
const ruleDetailWorkflow = (tableConfig, dataSource?) =>
  createWorkflow(createRuleDetailsWorkflowRegister(tableConfig, dataSource, 'ruleDetailWorkflow'), utilFunctions);
const camp0DetailWorkflow = (tableConfig, dataSource?) =>
  createWorkflow(createCamp0Detail(dataSource, 'camp0DetailWorkflow'), utilFunctions);
const hyperAutomationWorkflow = (tableConfig, dataSource?) =>
  createWorkflow(createHyperAutomationDetail(tableConfig, dataSource, 'hyperAutomationWorkflow'), utilFunctions);

export {
  WorkflowValidationError,
  WorkflowContext,
  createWorkflow,
  createWorkflowLight,
  createWorkflowCampaignDetails,
  campaignDetailWorkflow,
  ruleDetailWorkflow,
  camp0DetailWorkflow,
  hyperAutomationWorkflow,
  useWorkflow,
  useWorkflowScreen,
  WorkflowLightContext,
};

export const workflowList = {
  campaignDetailWorkflow,
  ruleDetailWorkflow,
  camp0DetailWorkflow,
  hyperAutomationWorkflow,
};
