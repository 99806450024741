import * as React from 'react';

import { Box, makeStyles } from '@material-ui/core';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import ButtonActions from '../button-actions';
import FormField from '.';

import { INPUT_TYPE_OPTIONS_KEY, INPUT_TYPE_SELECTION_OPTIONS_KEY } from '../../../utils/constant';

const useStyles = makeStyles({
  container: {
    padding: '8px 10px',
    width: '100%',
  },
  header: {
    paddingLeft: 0,
  },
  formFields: {
    marginTop: '10px',
    marginBottom: '15px',
  },
});

const TooltipInputType = ({ formFieldType }: any) => {
  const classes = useStyles();

  const configuration = {
    input_type: formFieldType,
    field_configuration: [
      {
        key: INPUT_TYPE_OPTIONS_KEY,
        value: [
          {
            label: '',
            value: '',
          },
        ],
      },
      {
        key: INPUT_TYPE_SELECTION_OPTIONS_KEY,
        value: [
          {
            key: 'pull',
            label: 'Pull',
            options: [
              {
                label: '',
                value: '',
              },
            ],
          },
          {
            key: 'push',
            label: 'Push',
            options: [
              {
                label: '',
                value: '',
              },
            ],
          },
        ],
      },
    ],
  };

  const buttonConfiguration = {
    cancel: {
      label: 'Cancel',
    },
    submit: {
      label: 'Submit',
    },
  };

  return (
    <Box className={classes.container}>
      <HeaderList title={'Inline edit'} variant="h4" className={classes.header} />
      <Box className={classes.formFields}>
        <FormField configuration={configuration} />
      </Box>
      <ButtonActions
        configuration={buttonConfiguration}
        onCancel={() => undefined}
        onSubmit={() => undefined}
        isSubmitting={false}
      />
    </Box>
  );
};

export const tooltip = (formFieldType) => {
  const TooltipComponent = () => {
    return <TooltipInputType formFieldType={formFieldType} />;
  };
  return TooltipComponent;
};

export default TooltipInputType;
