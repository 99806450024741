import * as React from 'react';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import Icon from '@ep/insight-ui/icons/Icon';
import { Box, makeStyles } from '@material-ui/core';
import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';
import * as _ from 'lodash';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';
import { OptionSelectType, OptionType } from '@ep/insight-ui/elements/list-control/type';
import MenuListSingle from './list-menu-single';
import clsx from 'clsx';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import TreeList from '@ep/insight-ui/elements/list-control/tree-list';
import { TypeTabListItem } from '@ep/insight-ui/elements/app-menu/tab-list-menu';

const useStyle = makeStyles(() => ({
  inputDropdown: {
    '& input': {
      padding: '0px',
      height: '32px',
      marginLeft: '5px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .eip1-MuiInputAdornment-root': {
        position: 'absolute',
        right: 0,
        padding: '11px',
        margin: '0px',
        cursor: 'pointer',
        height: 'auto',
      },
      '& .eip1-MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '& input[type=text]:focus': {
      boxShadow: 'none',
    },
  },
  checkbox: {
    marginRight: '-13px',
    width: '100%',
    backgroundColor: 'transparent !important',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    width: '300px',
    overflow: 'hidden',
    '& ul': {
      height: '100%',
    },
  },
  totalInput: {
    '&:hover': {
      borderColor: '#006EC6',
    },
    position: 'relative',
    width: '100%',
    borderRadius: '4px',
    border: 'solid 1px #C2C7CB',
    display: 'flex',
    backgroundColor: 'white',
    '& .dropdown': {
      width: '100%',
    },
    '& .boxValue': {
      pointerEvents: 'none',
    },
  },
  noBorder: {
    border: 'none',
  },
  listItem: {
    listStyleType: 'none',
  },
  listItemNoChip: {
    listStyleType: 'none',
    marginTop: '6px',
    marginLeft: '14px',
    color: '#000',
    cursor: 'pointer',
  },
  icon: {
    '&.deleteIcon': {
      width: '10px',
      height: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  error: {
    borderColor: colors.border.critical,
    '&:hover': {
      borderColor: colors.border.critical,
    },
  },
  wrapIcon: {
    height: '12px',
    width: '10px',
    marginTop: '10px',
    position: 'absolute',
    right: '10px',
  },
}));
const Chip = withStyles(() => ({
  root: {
    height: '24px',
    left: '0px',
    top: '0px',
    background: '#F6F7F8',
    borderRadius: '4px',
    alignItems: 'center',
    border: 0,
    marginTop: '4px',
    marginLeft: '4px',
  },
  deleteIcon: {
    '& svg': {
      color: '#253746',
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
}))(MuiChip);
type IProps = {
  value: TypeTabListItem[];
  options: TypeTabListItem[];
  multipleSelect?: boolean;
  searchAble?: boolean;
  chip?: boolean;
  onChange: (value: string[]) => void;
  maxDropdownHeight?: string;
  helperText?: string;
  disabled?: boolean;
  error?: boolean;
  className?: string;
  classNamePaper?: string;
  disableCheckbox?: boolean;
};
const checkSelectedTreeItem = (tree: TypeTabListItem[], value: string) => {
  for (let index = 0; index < tree.length; index++) {
    const element = tree[index];
    if (element.nodeId === value) {
      element.selected = true;
      return;
    }
    if (element.tabs && !element.selected) {
      checkSelectedTreeItem(element.tabs, value);

      const allSelected = element.tabs.every((item) => item.selected);
      const allNotSelected = element.tabs.every((item) => !item.selected);

      if (allSelected) {
        element.selected = true;
        element.indeterminate = false;
      } else if (allNotSelected) {
        element.selected = false;
        element.indeterminate = false;
      } else {
        element.selected = false;
        element.indeterminate = true;
      }
    }
  }
};
const SelectTree = ({
  value = [],
  options,
  multipleSelect = false,
  onChange,
  chip = false,
  maxDropdownHeight,
  disabled,
  error,
  className,
  classNamePaper,
  disableCheckbox = false,
}: IProps) => {
  const myInput = React.useRef<HTMLDivElement>();
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const handleOnClosed = () => setOpen(false);
  const handleOpen = () => setOpen(true);

  const [optionsTree, setOptionsTree] = React.useState(options);
  React.useEffect(() => {
    setOptionsTree(options);
  }, [options]);

  const [limitTags, setLimitTags] = React.useState<number>(2);
  const PopoverRef = React.useRef(null);
  const update = React.useState()[1].bind(null, {});

  React.useEffect(() => {
    if (value && value.length > 0) {
      const temp = [...optionsTree];
      value.map((item) => {
        checkSelectedTreeItem(temp, item.nodeId);
      });
      setOptionsTree(temp);
    }
  }, [value]);

  const getSelectedTreeValue = (tree: TypeTabListItem[]) => {
    const arrStr: any[] = tree
      .filter((item) => item.selected || item.tabs)
      .map((item) => {
        if (item.tabs) {
          const arr = getSelectedTreeValue(item.tabs);

          if (arr.length === 0) return [];

          if (arr.length === item.tabs.length) {
            for (let index = 0; index < item.tabs.length; index++) {
              const element = item.tabs[index];
              if (element.nodeId !== arr[index]) return arr;
            }
            return [item];
          }
          return arr;
        }
        return [item];
      });

    return _.flattenDeep(arrStr) as string[];
  };

  const handleOnChange = (arr: TypeTabListItem[]) => {
    const output: string[] = getSelectedTreeValue(arr);
    onChange(output);
    setOptionsTree(arr);
  };

  const RenderChip = () => {
    const lengthInput = _.get(myInput, ['current', 'offsetWidth'], 0);
    let totalLengthChip = 0;
    let lengthChipCount = 50;
    if (!chip && !multipleSelect) {
      return (
        <Box className="boxValue" display={'flex'} alignItems={'center'} position={'absolute'} zIndex={10}>
          {value.map((data, index) => {
            return (
              <li className={classes.listItemNoChip} key={index} onClick={handleOpen}>
                <span>{data.label}</span>
              </li>
            );
          })}
        </Box>
      );
    }
    return (
      <Box className="boxValue" display={'flex'} alignItems={'center'} position={'absolute'} zIndex={10}>
        {value.map((data, index) => {
          return index < limitTags ? (
            <li className={classes.listItem} key={index}>
              <Chip
                ref={(ref) => {
                  const length = ref ? ref.offsetWidth : 0;
                  totalLengthChip += length;
                  if (totalLengthChip > lengthInput * 0.75 - lengthChipCount) {
                    setLimitTags(index + 1);
                  }
                }}
                label={data.label}
              />
            </li>
          ) : null;
        })}
        {limitTags && value.length > limitTags && (
          <Chip
            ref={(ref) => {
              lengthChipCount = ref ? ref.offsetWidth : 0;
            }}
            variant="outlined"
            label={`+ ${value.length - limitTags}`}
          />
        )}
      </Box>
    );
  };

  const onToggle = () => {
    PopoverRef.current.updatePosition();
    update();
  };

  return (
    <React.Fragment>
      <div ref={myInput} className="select">
        <div className={clsx(classes.totalInput, error && classes.error, className)}>
          {RenderChip()}
          <Dropdown
            disabled={disabled}
            defaultOpen={open}
            handleOnClosed={handleOnClosed}
            classNamePaper={clsx(classes.paper, classNamePaper)}
            alignMenu="left"
            label=""
            sizeIcon="12px"
            className={classes.checkbox}
            maxHeight={maxDropdownHeight}
            action={PopoverRef}
          >
            <React.Fragment>
              <TreeList
                disableCheckbox={disableCheckbox}
                dataListMenu={optionsTree}
                onToggle={onToggle}
                onSelected={handleOnChange}
              />
            </React.Fragment>
          </Dropdown>
          <span className={classes.wrapIcon}>
            <Icon
              type={'chevron'}
              style={{
                height: '100%',
                width: '100%',
                color: error ? '#f44336' : 'rgba(0, 0, 0, 0.54)',
              }}
            />
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SelectTree;
