import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WorkSpace = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.89989 2.01515L13.3997 3.51511C13.5691 3.54969 13.7213 3.64173 13.8307 3.77567C13.94 3.9096 13.9997 4.0772 13.9997 4.25009V13.2498C13.9997 13.4487 13.9206 13.6395 13.78 13.7801C13.6393 13.9208 13.4486 13.9998 13.2497 13.9998H2.74998C2.55107 13.9998 2.36031 13.9208 2.21966 13.7801C2.07902 13.6395 2 13.4487 2 13.2498V7.74998C2 7.55107 2.07902 7.36031 2.21966 7.21966C2.36031 7.07902 2.55107 7 2.74998 7H4.99991V2.75013C4.99989 2.63891 5.02461 2.52907 5.07227 2.42858C5.11994 2.32808 5.18936 2.23945 5.2755 2.1691C5.36165 2.09874 5.46237 2.04843 5.57036 2.02181C5.67835 1.99518 5.79091 1.99291 5.89989 2.01515ZM3.5 8C3.22386 8 3 8.22386 3 8.5C3 8.77614 3.22386 9 3.5 9H4.5C4.77614 9 5 8.77614 5 8.5C5 8.22386 4.77614 8 4.5 8H3.5ZM11 5.5C11 5.22386 11.2239 5 11.5 5C11.7761 5 12 5.22386 12 5.5V11.5C12 11.7761 11.7761 12 11.5 12C11.2239 12 11 11.7761 11 11.5V5.5ZM9.5 5C9.22386 5 9 5.22386 9 5.5V11.5C9 11.7761 9.22386 12 9.5 12C9.77614 12 10 11.7761 10 11.5V5.5C10 5.22386 9.77614 5 9.5 5ZM7 5.5C7 5.22386 7.22386 5 7.5 5C7.77614 5 8 5.22386 8 5.5V11.5C8 11.7761 7.77614 12 7.5 12C7.22386 12 7 11.7761 7 11.5V5.5ZM3.5 10C3.22386 10 3 10.2239 3 10.5C3 10.7761 3.22386 11 3.5 11H4.5C4.77614 11 5 10.7761 5 10.5C5 10.2239 4.77614 10 4.5 10H3.5Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default WorkSpace;
