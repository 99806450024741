import * as aim from '@ep/one/aim';
import { get as _get } from 'lodash';
import defaultAxios, { AxiosError, AxiosRequestConfig } from 'axios';
import { nanoid } from 'nanoid';
import moment from 'moment';
import qs from 'qs';
import { useLog } from '@eip/next/lib/log';
import { EventEmitter2 } from 'eventemitter2';
import hash from 'object-hash';
import { CurlHelper } from './curl-helper';

const log = useLog('lib:fetch');
defaultAxios.defaults.withCredentials = true;

console.info('default set...');

export class ServiceError extends Error {
  requestId: string;
  message: string;
  code: string;

  constructor(serviceResponse) {
    super(serviceResponse.message);
    this.requestId = serviceResponse.requestId;
    this.message = serviceResponse.message;
    this.code = serviceResponse.code;
  }
}
const axios = defaultAxios.create({
  withCredentials: true,
});

export function markSandbox(toggle = true) {
  if (toggle) {
    axios.defaults.headers['x-is-sandbox'] = 1;
  }
}

const logCurls = { enabled: false, logs: [] };

// if (process.env.NODE_ENV === 'test') {
//   curlirize(axios);
//   // curlirize(axios, (result, err) => {
//   //   if (logCurls.enabled) {
//   //     const { command } = result;
//   //     logCurls.logs.push(command);
//   //   }
//   // });
// }
export { logCurls };

function urlBag(limit = 5) {
  let items: any[] = [];

  function push(item: any) {
    items.unshift(item);
    items = items.slice(0, limit);
  }

  return {
    push,
    get: () => items,
  };
}

export const lastUrls = urlBag(5);

class RequestWatcher {
  cache: any[];
  em: EventEmitter2;

  constructor() {
    this.cache = [];
    this.em = new EventEmitter2();
  }

  add(status: 'loading' | 'error' | 'success', config: AxiosRequestConfig, error?: any) {
    const url = config.url;
    let data = config.data;

    if (typeof data === 'string' && config.headers['Content-Type'] === 'application/json') {
      data = JSON.parse(data);
    }

    let requestId;
    if (String(config.method).toUpperCase() === 'GET') {
      const search = qs.parse(url.split('?')[1] || '');
      requestId = _get(search, ['serviceRequest.requestId']);
    } else {
      requestId = _get(data, 'serviceRequest.requestId');
    }
    if (!requestId) {
      requestId = hash({ url, data }, { unorderedArrays: true });
    }

    const existIndex = this.cache.findIndex((i) => i.requestId === requestId);
    const curlHelper = new CurlHelper(config);

    let finalStatus = status;
    if (status === 'success' && error.data) {
      let potentialError = error.data;
      if (config.headers['Accept'].includes('application/json') && typeof error.data === 'string') {
        potentialError = JSON.parse(error.data);
      }
      if (potentialError) {
        const isSuccess =
          _get(potentialError, 'success', true) && _get(potentialError, 'serviceResponse.success', true);
        if (isSuccess === false) {
          finalStatus = 'error';
        }
      }
    }

    if (existIndex > -1) {
      this.cache[existIndex] = {
        status: finalStatus,
        requestId,
        url,
        data,
        curlCommand: curlHelper.generateCommand(),
        error,
      };
    } else {
      this.cache.unshift({
        status: finalStatus,
        requestId,
        url,
        data,
        curlCommand: curlHelper.generateCommand(),
        error,
      });
    }

    this.cache = this.cache.slice(0, 20);
    this.em.emit('update', this.cache);
    log('request watcher: update', this.cache);
  }
  on(eventName: string, callback) {
    this.em.addListener(eventName, callback);
    return () => {
      this.em.removeListener(eventName, callback);
    };
  }
}

const rw = new RequestWatcher();

axios.interceptors.request.use(
  (config) => {
    const data = config.data;
    if (data && data.__noRequestId !== true) {
      config.headers = {
        ...config.headers,
        'X-Trace-ID': moment().format('YYYYMMDDHHmmss') + nanoid(10),
        'X-Requestor': 'eip-fe',
        'X-Env': process.env.EIP_BUILD_ENV || 'dev',
      };
    } else if (!data) {
      config.headers = {
        ...config.headers,
        'X-Trace-ID': moment().format('YYYYMMDDHHmmss') + nanoid(10),
        'X-Requestor': 'eip-fe',
        'X-Env': process.env.EIP_BUILD_ENV || 'dev',
      };
    }

    // if (config.headers['x-sandbox-mode']) {
    //   config.params = { ...config.params, ['x-sandbox-mode']: 1 };
    //   config.url = String(config.url).replace(/(eip-manager|grpc-gateway|datacenter|passport)/, 'sandbox-$1');
    //   delete config.headers['x-sandbox-mode'];
    // }
    // delete config.headers['x-sandbox-mode'];

    if (ff.auth0_next) {
      const userSettings = aim.getUserSettings();

      if (_get(userSettings, 'isAuth0', false)) {
        config.url = String(config.url).replace(
          /(eip-manager|grpc-gateway|datacenter)\.epsilo.io/,
          '$1-auth0.epsilo.io',
        );
        if (/ray.*?\.epsilo.io/.test(config.url)) {
          config.headers['Authorization'] = `Bearer ${aim.getLoginToken().token}`;
        }
        if (
          /(sandbox-manager|eip-manager|grpc-gateway|datacenter|passport|hyper-automation|hyper-integration).*?\.epsilo.io/.test(
            config.url,
          ) ||
          /(one|to)\.epsilo\.io\/api\/v1/.test(config.url) ||
          /^\/api\/v1/.test(config.url)
        ) {
          config.headers['Authorization'] = `Bearer ${aim.getLoginToken().token}`;
          let workspaceId;
          if (ff.next_left_menu) {
            const href = window.location.href;
            const currentWorkspaceDomain = href.match(/\/[a-zA-Z0-9._!@#$%^&*()]+\/page\//)?.[0];
            const workspaceDomain = _get(userSettings, ['profile', 'workspace_domain'], '');
            const userEmail = _get(userSettings, ['profile', 'userEmail'], '');
            const wsDomain = currentWorkspaceDomain ? currentWorkspaceDomain.split('/')[1] : workspaceDomain;
            const workspaces = _get(userSettings, ['workspaces'], []);
            workspaceId =
              workspaces.find((ws) => ws.workspace_domain === wsDomain)?.workspace_id ||
              _get(userSettings, ['profile', 'workspace_id'], null);
            const pageId = (window.location.href.match(/page\/.+?\//) || window.location.href.match(/page\/.+/))?.[0];
            if (pageId) {
              config.headers['X-User-Page'] = pageId.split('/')[1];
            }
            if (/eip-manager.*?\.epsilo.io/.test(config.url)) {
              config.headers['use-workspace-version'] = 1;
            }
            if (
              wsDomain === 'sandbox' ||
              (/demo.*@epsilo.io$/i.test(userEmail) && pageId?.split('/')[1] == '3edd4b9c-fd8c-40c2-8cd3-c6d997ed8426')
            ) {
              config.headers['x-is-sandbox'] = 1;
            }
          } else {
            workspaceId = _get(userSettings, ['profile', 'workspace_id'], null);
          }
          if (workspaceId && !config.url.includes('api/auth/user/workspace-set')) {
            config.headers['X-User-Workspace'] = workspaceId;
          }
        }
        if (/(integration-service|transformer).*?\.epsilo.io/.test(config.url)) {
          config.headers['Authorization'] = `Bearer ${aim.getLoginToken().token}`;
        }
      }
    }

    window.setTimeout(() => rw.add('loading', config), 0);
    return config;
  },
  (error: AxiosError) => {
    window.setTimeout(() => rw.add('error', error.request.config), 0);
    return Promise.reject(error);
  },
);

// axios.interceptors.response.use(
//   (response) => {
//     lastUrls.push(response.config.url);
//     window.setTimeout(() => rw.add('success', response.config, response), 0);
//     return response;
//   },
//   (error: AxiosError) => {
//     window.setTimeout(() => rw.add('error', error.config, error.response), 0);
//     return Promise.reject(error);
//   },
// );

export { axios };

export { rw as requestWatcher };

class ErrorRetryRequest extends Error {
  constructor(message: string) {
    super(message);
  }
}

const handleHeaders = () => {
  const headers: any = {
    'Content-Type': 'application/json',
  };
  if (aim.getLoginToken() && aim.getLoginToken().token) {
    // headers['Authorization'] = `Bearer ${aim.getLoginToken().token}`;
  }
  return { ...headers };
};

const handleError = (res: any) => {
  if (!res) {
    throw { message: 'Network error.' };
  }
  const { status, data } = res;
  switch (status) {
    case 401: {
      aim.getAuthEvent().triggerExpired();
      console.error('request token old flow');
    }
    case 500:
      throw { message: 'Internal Server Error', error: data };
    default:
      throw data;
  }
};

export const get = (url: string, body: any = null, timeout?) => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();
  const config: any = {
    method: 'get',
    url,
    headers: newHeaders,
  };

  const validBody = { 'serviceRequest.requestId': 'eip.' + nanoid() };

  if (body) {
    config[`url`] = `${url}?${qs.stringify(
      { ...validBody, ...body },
      {
        arrayFormat: 'comma',
      },
    )}`;
  }
  return (
    axios({ ...config, timeout })
      // .then((rs) => {
      //   console.info('rs.header', rs.headers, rs.data, rs.status);
      //   return rs;
      // })
      .then(refreshLoginToken)
      .then((rs) => validForRetryToken(rs, token))
      .then((rs) => rs.data)
      .catch((e) => {
        if (e instanceof ErrorRetryRequest) {
          return async () => {
            await sleep();
            return post(url, body);
          };
        } else {
          log('error', config['url']);
          return handleError(e.response);
        }
      })
  );
};

const pendingRequests: Record<string, Promise<any>> = {};

const postNext = (url: string, body: any, headers?: {}, extra?: {}) => {
  const newHeaders = { ...handleHeaders(), ...headers };
  const token = aim.getLoginToken();
  const config: any = {
    method: 'post',
    url,
    data: body,
    headers: newHeaders,
  };

  const uaSessionId = body._uaSessionId;
  delete body._uaSessionId;

  const hashedRequestId = hash({ url, body, method: 'POST' });

  log('hash pending request', { url, body, hashedRequestId });

  if (!pendingRequests[hashedRequestId]) {
    const $result = axios(config)
      .then(refreshLoginToken)
      .then((rs) => validForRetryToken(rs, token))
      .then((rs) => {
        pendingRequests[hashedRequestId] = Promise.resolve(rs.data);
        window.setTimeout(() => {
          log('deleted pending request', hashedRequestId);
          delete pendingRequests[hashedRequestId];
        }, 1.5 * 1000);
        fork(() => {
          if (uaSessionId && window.uaWatchlist) {
            const session = window.uaWatchlist.findById(uaSessionId);
            if (session) {
              window.uaWatchlist
                .inspect('apiResponse', rs.data)
                .then(() => {
                  session.success('be:response');
                  window.uaWatchlist.end(session);
                })
                .catch((e) => {
                  session.addContext({ httpErrorCode: e.code, httpErrorMessage: e.message });
                  session.fail('be:response');
                  window.uaWatchlist.end(session);
                });
            }
          }
        });
        return rs.data;
      })
      .catch((e) => {
        log(e);
        delete pendingRequests[hashedRequestId];
        if (e instanceof ErrorRetryRequest) {
          return async () => {
            await sleep();
            return postNext(url, body, headers);
          };
        } else {
          fork(() => {
            if (uaSessionId && window.uaWatchlist) {
              const session = window.uaWatchlist.findById(uaSessionId);
              if (session) {
                session.addContext({ httpErrorCode: e.code, httpErrorMessage: e.message });
                session.fail('be:response');
                window.uaWatchlist.end(session);
              }
            }
          });
          return handleError(e.response);
        }
      });
    pendingRequests[hashedRequestId] = $result;
  }

  return pendingRequests[hashedRequestId];
};

function fork(callback) {
  window.setTimeout(callback, 0);
}

export const post = postNext;

export const put = (url: string, body: any) => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();

  const uaSessionId = body._uaSessionId;
  delete body._uaSessionId;

  const config: any = {
    method: 'put',
    url,
    data: body,
    headers: newHeaders,
  };
  return axios(config)
    .then(refreshLoginToken)
    .then((rs) => validForRetryToken(rs, token))
    .then((rs) => {
      fork(() => {
        if (uaSessionId && window.uaWatchlist) {
          const session = window.uaWatchlist.findById(uaSessionId);
          if (session) {
            window.uaWatchlist
              .inspect('apiResponse', rs.data)
              .then(() => {
                session.success('be:response');
                window.uaWatchlist.end(session);
              })
              .catch((e) => {
                session.addContext({ httpErrorCode: e.code, httpErrorMessage: e.message });
                session.fail('be:response');
                window.uaWatchlist.end(session);
              });
          }
        }
      });
      return rs.data;
    })
    .catch((e) => {
      if (e instanceof ErrorRetryRequest) {
        return async () => {
          await sleep();
          return put(url, body);
        };
      } else {
        return handleError(e.response);
      }
    });
};

export const patch = (url: string, body: any) => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();

  const uaSessionId = body._uaSessionId;
  delete body._uaSessionId;

  const config: any = {
    method: 'patch',
    url,
    data: body,
    headers: newHeaders,
  };
  return axios(config)
    .then(refreshLoginToken)
    .then((rs) => validForRetryToken(rs, token))
    .then((rs) => {
      fork(() => {
        if (uaSessionId && window.uaWatchlist) {
          const session = window.uaWatchlist.findById(uaSessionId);
          if (session) {
            window.uaWatchlist
              .inspect('apiResponse', rs.data)
              .then(() => {
                session.success('be:response');
                window.uaWatchlist.end(session);
              })
              .catch((e) => {
                session.addContext({ httpErrorCode: e.code, httpErrorMessage: e.message });
                session.fail('be:response');
                window.uaWatchlist.end(session);
              });
          }
        }
      });
      return rs.data;
    })
    .catch((e) => {
      if (e instanceof ErrorRetryRequest) {
        return async () => {
          await sleep();
          return patch(url, body);
        };
      } else {
        return handleError(e.response);
      }
    });
};

export const deleteFetch = (url: string, body: any = null) => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();
  const config: any = {
    method: 'delete',
    url,
    headers: newHeaders,
    data: body,
  };
  // if (body) {
  //   config[`url`] = `${url}?${qs.stringify(body, {
  //     arrayFormat: 'comma',
  //   })}`;
  // }
  return axios(config)
    .then(refreshLoginToken)
    .then((rs) => validForRetryToken(rs, token))
    .then((rs) => rs.data)
    .catch((e) => {
      if (e instanceof ErrorRetryRequest) {
        return async () => {
          await sleep();
          return deleteFetch(url, body);
        };
      } else {
        return handleError(e.response);
      }
    });
};

export const download = (url: string, body: any = null, method = 'GET') => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();

  const config: any = {
    method: method,
    url,
    headers: { ...newHeaders, 'Content-Type': undefined },
    responseType: 'blob',
  };
  if (body && method === 'GET') {
    config[`url`] = `${url}?${qs.stringify(body, {
      arrayFormat: 'comma',
    })}`;
  } else {
    config.data = body;
  }
  return axios(config)
    .then(refreshLoginToken)
    .then((rs) => validForRetryToken(rs, token))
    .then((rs) => rs.data)
    .catch(async (e) => {
      if (e instanceof ErrorRetryRequest) {
        return async () => {
          await sleep();
          return post(url, body);
        };
      } else {
        const message = JSON.parse(await e.response.data.text()).message;
        throw { message };
      }
    });
};

export const upload = (url: string, formData: FormData) => {
  const newHeaders = { ...handleHeaders() };
  const token = aim.getLoginToken();

  const config: any = {
    method: 'POST',
    url,
    headers: { ...newHeaders, 'Content-Type': undefined },
    responseType: 'json',
  };

  return axios.post(url, formData, config);
};

function refreshLoginToken(rs: any) {
  log('refresh-token', rs.config.url);
  if (rs.headers['x-token']) {
    aim.setLoginToken(rs.headers['x-token']);
  }
  return rs;
}

function validForRetryToken(rs: any, requestToken: { token: string | null; lastUpdated: number | null }) {
  const authToken = aim.getLoginToken();
  log('refresh-token', rs.config.url);
  log('refresh-token', { authToken, requestToken });
  if (rs.status === 401 && authToken.lastUpdated && authToken.lastUpdated > requestToken.lastUpdated) {
    console.warn('valid for retry toke', authToken, rs.headers['x-token']);
    throw new ErrorRetryRequest('token change');
  } else {
    return rs;
  }
}

function sleep(ms = 50) {
  return new Promise((resolve) => {
    window.setTimeout(() => {
      resolve(ms);
    }, ms);
  });
}
