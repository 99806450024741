import * as React from 'react';

import { Button, Grid, makeStyles, Modal, Box } from '@material-ui/core';

import MultipleSelectComponent from '@ep/insight-ui/elements/multiple-select-component';

import { useMassCreate } from './hooks/use-mass-create';

const useStyles = makeStyles((theme) => ({
  container: {},
  groupCheckList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    // maxWidth: 640,
  },
  checkListItem: {
    flex: 1 / 3,
    minWidth: 210,
    maxWidth: 210,
    padding: '16px 8px',
  },
  openCheckList: {
    width: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closeCheckList: {
    width: 0,
    padding: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 16px',
  },
  button: {
    height: '100%',
  },
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  modalButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  modalButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
}));

const MassCreate = ({ handleClose, eTableEndpoint, gridApi, mapping, ...rest }: any) => {
  const classes = useStyles();

  const { options, setOptions, loading, selectedItems, setSelectedItems, handleSubmit } = useMassCreate({
    eTableEndpoint,
    gridApi,
    handleClose,
    ...rest,
  });

  return (
    <div className={classes.container}>
      <Box sx={{ maxHeight: '85vh', overflow: 'auto' }}>
        <MultipleSelectComponent
          title={rest.isDimension ? 'Dimension' : 'Metric'}
          options={options}
          setOptions={setOptions}
          selectedItems={selectedItems}
          setSelectedItems={setSelectedItems}
          hasSearch
          isSelectAllShow
          loading={loading}
        />
      </Box>
      <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
        <Grid item>
          <Button className={classes.button} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default MassCreate;
