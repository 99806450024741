import * as React from 'react';
import { get } from 'lodash';

import { aim } from '@eip/next/lib/main';

export const useButtonAction = ({ backbone }) => {
  const ref = React.useRef(null);
  const [actionSelecting, setActionSelecting] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const Component = React.useMemo(() => {
    if (actionSelecting) {
      return actionSelecting.component;
    }
    return null;
  }, [actionSelecting]);

  const propsComponent = React.useMemo(() => {
    if (actionSelecting) {
      return {
        ...actionSelecting,
        component: undefined,
        anchorEl,
        setAnchorEl,
        data: {
          node: {
            data: {
              config: backbone.config || {},
              tableRows: backbone?.getTableRows ? backbone.getTableRows() : [],
              ...get(backbone, ['config', 'actionData'], {}),
            },
          },
        },
      };
    }
    return {};
  }, [actionSelecting]);

  const handleClose = () => {
    setAnchorEl(null);
    setActionSelecting(null);
  };

  const customAction = backbone.getConfig('customAction', {});
  const label = customAction.label || 'Action Button';
  const { workspace_role_id } = aim.getUserSettings().profile || {};

  const actions = React.useMemo(() => {
    const filteredActions = (customAction.actions || [])
      .filter(
        ({ disable, role }) =>
          !disable &&
          (aim.isSuperAdmin() ||
            !workspace_role_id ||
            (role ? [].concat(role) : [1, 2, 3]).includes(Number(workspace_role_id))),
      )
      .filter(({ permission }) => (permission === 'admin' ? aim.isSuperAdmin() : true));
    const additionalData = {
      data: {
        config: backbone.config || {},
        tableRows: backbone?.getTableRows ? backbone.getTableRows() : [],
        ...get(backbone, ['config', 'actionData'], {}),
      },
    };
    const addonActions = backbone.addon('main.button.actions', () => [])(filteredActions, additionalData);
    const mappedActions = (addonActions || []).map((action) => ({
      title: action.name,
      icon: action.icon,
      customProps: action.customProps,
      onClick() {
        if (action.component) {
          setAnchorEl(ref.current);
          setActionSelecting(action);
        } else {
          action.onSubmit({ row: {} });
        }
      },
    }));

    return mappedActions;
  }, [backbone, customAction]);

  return { anchorEl, ref, handleClose, actions, label, Component, propsComponent };
};
