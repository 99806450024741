import { EIP_CONSTANT } from '@eip/next/lib/main';

const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

export const GET_TABLE_DATA = `${API_URL}/root-management/storefront-management`;
export const GET_PAGINATION_INFO = `${API_URL}/mop-query/listing/getPaginationInfo`;
export const GET_PROPERTIES = `${API_URL}/mop-query/listing/getDimensions`;
export const UPDATE_CELL_VALUE = `${API_URL}/ads-operation/mass-update-dimension`;
export const MASS_UPDATE_SCRIPT = `${API_URL}/programmatic/mass-update-dimension`;
export const MASS_UPDATE_CELL_VALUE = `${API_URL}/ads-operation/mass-upsert-dimension`;
export const MASS_DELETE_DIMENSION = `${API_URL}/ads-operation/mass-delete-dimension`;
export const GET_DIMENSION_DEFINITION = `${API_URL}/ads-operation/get-dimension-definition`;
export const FILTER_DIMENSION = `${API_URL}/ads-operation/filter-dimension`;
export const API_EXPORT_ETABLE = `${API_URL}/mop-query/action/export`;
