import * as React from 'react';
import CreatableSelect from 'react-select/creatable';
import { uniqBy } from 'lodash';

import { Avatar, Box, Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {},
  actions: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'flex-end',
    columnGap: '8px',
  },
}));

const useCustomOptionStyles = makeStyles(() => ({
  container: {
    padding: '6px 16px',
    cursor: 'pointer',
    '&:hover': {
      background: '#E4E7E9',
    },
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
  },
  name: {
    color: '#253746',
  },
  email: {
    color: '#8C98A4',
    fontSize: '10px',
  },
  avatar: {
    width: '28px',
    height: '28px',
    '& span': {
      fontSize: '14px',
    },
  },
}));

const stringToColour = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = '#';
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += ('00' + value.toString(16)).substr(-2);
  }
  return colour;
};

const CustomOption = ({ innerProps, isDisabled, children, data, ...rest }: any) => {
  const classes = useCustomOptionStyles();

  if (isDisabled) return null;

  const fullName = `${data.firstname} ${data.lastname}`;
  const shortName = fullName
    .split(' ')
    .filter((el) => !!el)
    .map((el) => el.slice(0, 1).toUpperCase())
    .join('');

  return (
    <Box {...innerProps} className={classes.container}>
      {data.email ? (
        <>
          <Box>
            {data.avatarUrl ? (
              <Avatar alt={fullName} src={data.avatarUrl} className={classes.avatar} />
            ) : (
              <Avatar
                alt={fullName}
                src={data.avatarUrl}
                className={classes.avatar}
                style={{ background: stringToColour(shortName) }}
              >
                <span>{shortName}</span>
              </Avatar>
            )}
          </Box>
          <Box className={classes.info}>
            <Box className={classes.name}>{fullName}</Box>
            <Box className={classes.email}>{data.email}</Box>
          </Box>
        </>
      ) : (
        <Box>{children}</Box>
      )}
    </Box>
  );
};

const AccessBy = ({ userOptions = [], values, setValues }: any) => {
  const classes = useStyles();

  const colorStyles = {
    multiValue: (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: '#EDF2F9',
      };
    },
    multiValueLabel: (styles, { data }) => ({
      ...styles,
      color: '#253746',
      fontSize: '14px',
      lineHeight: '1.45em',
    }),
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: '#00000042',
      cursor: 'pointer',
      ':hover': {
        backgroundColor: '#EDF2F9',
        color: '#00000066',
      },
      paddingLeft: 0,
      '& svg': {
        width: '20px',
        height: '20px',
      },
    }),
  };

  const handleAddNewUser = React.useCallback(
    (valString) => {
      const newUsers = String(valString)
        .split(/,|\n|\t|\s/)
        .filter((i) => String(i).trim() !== '')
        .map((i) => ({
          label: i,
          value: i,
        }));

      setValues((prev) => {
        return uniqBy([...prev, ...newUsers], (i) => i.value);
      });
    },
    [values],
  );

  const onChange = React.useCallback((data) => {
    setValues((prev) => {
      return data;
    });
  }, []);

  return (
    <Box className={classes.container}>
      <CreatableSelect
        isMulti
        onCreateOption={handleAddNewUser}
        onChange={onChange}
        value={values}
        options={userOptions}
        styles={colorStyles}
        components={{ Option: CustomOption }}
      />
    </Box>
  );
};

export default AccessBy;
