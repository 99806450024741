import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Tablet = (props: SvgIconProps) => (
  <SvgIcon {...props} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H12C13.1046 0 14 0.895431 14 2V14C14 15.1046 13.1046 16 12 16H2C0.89543 16 0 15.1046 0 14V2ZM2 3C2 2.44772 2.44772 2 3 2H11C11.5523 2 12 2.44772 12 3V11C12 11.5523 11.5523 12 11 12H3C2.44772 12 2 11.5523 2 11V3ZM7 15C7.55228 15 8 14.5523 8 14C8 13.4477 7.55228 13 7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15Z"
    />
  </SvgIcon>
);

export default Tablet;
