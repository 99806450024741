import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Notification = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.99929 10.9958H4.99233C4.99233 11.5481 5.44389 12 5.99581 12C6.54772 12 6.99929 11.5565 6.99929 10.9958Z"
      fill={props.color ? props.color : '#3F4F5C'}
    />
    <path
      d="M11.816 9.56485L10.662 8.41841C10.5533 8.30126 10.4864 8.15063 10.4864 7.99163L10.4864 3.92469C10.4948 3.11297 10.2272 2.33473 9.73379 1.69038C9.43275 1.30544 9.0648 0.97908 8.64668 0.728034C7.01602 -0.242678 4.98397 -0.242678 3.3533 0.728034C2.92682 0.97908 2.55888 1.30544 2.25783 1.69038C1.76445 2.32636 1.49686 3.11297 1.50522 3.92469L1.50523 8C1.50523 8.159 1.44669 8.30962 1.32962 8.42678L0.17561 9.56485C0.0585366 9.68201 0 9.83264 0 9.99163C0 10.5439 0.451568 10.9958 1.00348 10.9958C3.26132 10.9958 8.73031 10.9958 10.9965 10.9958C11.5484 10.9958 12 10.5439 12 9.99163C11.9916 9.83264 11.9331 9.68201 11.816 9.56485Z"
      fill={props.color ? props.color : '#3F4F5C'}
    />
  </SvgIcon>
);

export default Notification;
