import { EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';

import { get } from 'lodash';
import moment from 'moment';

import {
  downloadMassUploadTemplate,
  checkEpsiloTableEndpoint,
  EpsiloTableObject,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';
import { CELL_FORMAT_STATIC_KEYS } from '@ep/insight-ui/system/block/etable/cell-format-object-keys';
import {
  ADOPTION_RATE_ACTIONS_RES,
  ADOPTION_RATE_USERS_RES,
  USER_COHORT_RES,
} from '@ep/insight-ui/elements/table/mocks/getAdoptionRateData';
import AdoptionRate from '@ep/insight-ui/elements/table/format/adoption-rate-format';
import { default as dataRequest } from '@ep/insight-ui/system/backbone/data-source/common';
import { getSoSTractionData } from '@ep/insight-ui/system/backbone/data-source/common';

import {
  customExportTable,
  getMetricDefinition,
  enhanceDataRequest2,
  getInsightStorefrontList,
  getQualityStorefrontList,
  getOperationStorefrontList,
  getOperationCountryList,
  getOperationMarketplaceList,
  getNamespaceCountryList,
  getNamespaceMarketplaceList,
  getNamespaceStorefrontList,
} from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';
import { MASS_OPERATION_TABLE_DATA } from '@ep/insight-ui/system/block/etable/demo-data/mass-operation-table-data';
import { columnEnhance } from '@ep/insight-ui/system/block/etable/addons/column-enhance';
import { hasCohortProperties } from '@ep/insight-ui/system/block/etable/addons/column-enhance';
import { resetColumns } from '@ep/insight-ui/system/block/etable/addons/table-addon';
import { popuplateConfigWithCurrentView, produceValidConfig } from '@ep/insight-ui/system/block/etable/migration';
import { options, getCellFormat } from '@ep/insight-ui/system/block/etable/cell-format';
import PeriodUpdate from '@ep/insight-ui/system/block/etable/etable-config/action-components/period-update';
import CompactTable from '@ep/insight-ui/system/block/etable/etable-config/action-components/compact-table';
import InlineEdit from '@ep/insight-ui/system/block/etable/etable-config/action-components/inline-edit';
import {
  ACTION_CATEGORY_COMPACT_TABLE_VIEW,
  ACTION_CATEGORY_FILTER,
  ACTION_CATEGORY_IMPACT_TABLE,
  ACTION_CATEGORY_INLINE_FORM,
  ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
  ACTION_CATEGORY_PERIOD,
  ACTION_CATEGORY_STATUS,
  ACTION_CATEGORY_TAG,
} from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';

const isValidIndexData = (config) => {
  return checkEpsiloTableEndpoint(get(config, 'endpoint.GET_TABLE_DATA', ''), [
    EpsiloTableObject.PERFORMANCE,
    EpsiloTableObject.QUALITY,
    EpsiloTableObject.OPERATION,
  ]);
};

export const getFinalConfig = ({ customConfig, defaultConfiguration, mopFilter, lastUpdated, cellFormat }) => {
  const CELL_FORMAT_OPTIONS = options.reduce((a, b) => {
    const cellFormatData = getCellFormat(b);
    if (cellFormatData) a[b] = cellFormatData;
    return a;
  }, {});

  const CELL_FORMAT_OPTIONS_WITH_COMPONENT = Object.keys(CELL_FORMAT_OPTIONS).reduce((a, b) => {
    return { ...a, [b]: CELL_FORMAT_OPTIONS[b].component, ...cellFormat };
  }, {});

  let validConfig = produceValidConfig(customConfig);
  validConfig = popuplateConfigWithCurrentView(validConfig);

  const config: {
    apiRequest: Record<string, any>;
    configuration: Record<string, any>;
    addons?: Record<string, (...args: any[]) => any>;
    callback?: Record<string, any>;
  } = {
    apiRequest: {},
    configuration: {
      ...defaultConfiguration,
      ...validConfig,
      hiddenFilter: mopFilter,
      defaultPagination: {
        limit: isValidIndexData(validConfig) ? 1000 : 10,
        page: 1,
        total: 0,
        ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
      },
    },
    callback: {
      onBackboneReady: (backbone, config) => {
        const addons = get(config, 'addons', {});

        Object.keys(addons).forEach((key) => {
          if (key.startsWith('initial.request.')) {
            backbone
              .addon(key, () => Promise.resolve({ data: null }))()
              .then((res) => {
                backbone.addInitialDataRequest(key.replace('initial.request.', ''), res.data);
              });
          }
        });

        const enpointTable = get(backbone, 'config.endpoint.GET_TABLE_DATA');
        // Add Metric Options for button Properties
        const mappingConfig = get(backbone, 'config.mapping', {});
        const isStorefrontMetricTraction = Object.values(mappingConfig).find(
          (ele) => ele?.cellFormat === 'storefrontMetricTraction',
        );
        const metric = get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []);
        const metricTractionProperties = backbone.getConfig('metricTractionProperties', []);

        const isDiff =
          metric.length !== metricTractionProperties.length ||
          (metric.length === metricTractionProperties.length &&
            metricTractionProperties.some(({ id }) => !metric.includes(id)));

        if (isStorefrontMetricTraction && isDiff) {
          getMetricDefinition(enpointTable).then((res) => {
            if (res) {
              const metricProperties = metric.map((el) => {
                const metricDef = res.filter((mt) => mt.value === el)[0];
                return {
                  id: el,
                  name: get(metricDef, 'label_raw', el),
                  disable: false,
                };
              });
              backbone.changeConfig('metricTractionProperties', metricProperties);
            }
          });
        }
      },
    },
    ...(ff.generate_etable_config ? { cellFormat: CELL_FORMAT_OPTIONS_WITH_COMPONENT } : {}),
  };
  const endpoint = get(validConfig, 'endpoint.GET_TABLE_DATA', '');
  const queryParams = getQueryParams(endpoint);

  // Will remove when API is available
  let adoptionMockResponse;
  let showTooltip = false;
  switch (validConfig.tableType) {
    case 'adoptionRateByActions':
      adoptionMockResponse = ADOPTION_RATE_ACTIONS_RES;
      break;
    case 'adoptionRateByUsers':
      adoptionMockResponse = ADOPTION_RATE_USERS_RES;
      showTooltip = true;
      break;
    case 'userCohort':
      adoptionMockResponse = USER_COHORT_RES;
      break;
    case 'massOperationTable':
      ff.make_mocking_api_etable
        ? (adoptionMockResponse = {
            data: {
              headers: Object.keys(MASS_OPERATION_TABLE_DATA[0]),
              pagination: { limit: 100, page: 1, total: MASS_OPERATION_TABLE_DATA.length },
              primaryKeys: ['product'],
              rows: MASS_OPERATION_TABLE_DATA,
            },
            message: 'OK',
            code: 200,
          })
        : (adoptionMockResponse = undefined);
      break;
    default:
      break;
  }

  // Add additionalColumns for table
  config.addons = {
    ...config.addons,
    'table.columns.enhance': (columnDefs, columns, backbone) => {
      if (ff.metric_traction) {
        return columnEnhance(columnDefs, columns, backbone);
      } else {
        const additionalColumns = [];

        const mappings = backbone.getConfig('mapping');
        const tractionFormat = Object.values(mappings).find((mapping) => mapping.cellFormat === 'traction');

        if (tractionFormat) {
          const {
            period = 'week',
            timeRange = 8,
            formatTime = '[CW]W - YYYY',
          } = tractionFormat.staticValue || CELL_FORMAT_STATIC_KEYS.traction;

          additionalColumns.push(
            ...Array.from({ length: timeRange }, (_, index) => {
              return {
                headerName: moment().subtract(index, period).format(formatTime),
                field: `traction_${period}_${index + 1}`,
                colId: `traction_${period}_${index + 1}`,
                resizable: true,
                suppressMovable: true,
                cellStyle: {
                  borderRightColor: 'whitesmoke',
                },
                headerComponentParams: {
                  type: 'number',
                },
                cellRendererParams: {
                  showTooltip: showTooltip,
                },
                cellRendererFramework: AdoptionRate,
              };
            }),
          );
        }
        return [...columnDefs, ...additionalColumns];
      }
    },
    ...(ff.metric_cohort_column
      ? {
          'dataMenu.properties': (arr, handleUpdateVisibleWithId, backbone) => {
            const newProperties = [...arr];
            if (hasCohortProperties(backbone)) {
              newProperties.unshift({
                title: 'Cohort',
                name: 'cohort',
                icon: 'calendar',
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('calendar', true),
              });
            }
            if (checkEpsiloTableEndpoint(endpoint)) {
              newProperties.unshift({
                title: 'View by',
                name: 'periodically',
                icon: 'calendar',
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('periodically', true),
              });
            }
            return [newProperties];
          },
        }
      : {}),
    ...(!checkEpsiloTableEndpoint(endpoint)
      ? {
          'initial.request.transformStatus': async () => {
            const result = await eipRequest.get(
              EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/filter-dropdownlabel?group=Transform Status',
            );
            return result;
          },
          'filterValue.ads_campaign_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
          'filterValue.ads_object_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
          'filterValue.ads_placement_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
        }
      : {}),
  };

  if (ff.integrate_api_sos) {
    if (validConfig.tableType === 'treemapTable') {
      config.addons = {
        ...(config.addons || {}),
        'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
          const { dateFrom, dateTo } = backbone.getConfig('dateRange');
          params.hiddenFilter = {
            combinator: 'AND',
            filters: [
              {
                field: 'PRODUCT.keyword',
                operator: '=',
                dataType: 'string',
                value: backbone.getConfig('keyword'),
              },
            ],
          };
          params.from = dateFrom;
          params.to = dateTo;
          return Promise.resolve(params);
        },
        ...(ff.storefront_sos_option_list
          ? {
              'filter.optionSOS.storefront': async (keyword) => {
                const res = await dataRequest.getStorefrontSOS(keyword);
                return res;
              },
            }
          : {}),
        ...(ff.chart_line_traction
          ? {
              'get.traction.data': (keyword, productSIds, timing = 'daily') => {
                return getSoSTractionData(keyword, productSIds, timing);
              },
            }
          : {}),
      };
    }
  }

  if (ff.keep_block_refreshing) {
    const { timeToReloadData } = config.configuration;
    if (timeToReloadData) {
      config.addons = {
        ...config.addons,
        'calendar.last_updated': (rows, currentConfig, backbone) => {
          setTimeout(() => {
            const currentTime = moment().valueOf();
            if (currentTime - lastUpdated.current > timeToReloadData) {
              backbone.changeConfig('lastUpdatedAt', moment(new Date()).format('MMM/YY hh:mm'));
              backbone.reloadData('table');
              lastUpdated.current = currentTime;
            }
          }, timeToReloadData);
          return rows.map((row) => ({
            ...row,
            lastUpdatedAt: moment(new Date()).format('MMM/YY hh:mm'),
          }));
        },
      };
    }
  }

  config.addons = {
    ...config.addons,
    'externalFilter.getFields': (backbone) => {
      const specialFilterFields = ['country', 'marketplace', 'storefront', 'brand', 'category_1'];

      const externalFilters = backbone.getConfig('system.externalFilters', []);
      return backbone
        .getAvailableColumns()
        .reduce((a, b) => {
          if (externalFilters.includes(b.id)) {
            return [
              ...a,
              {
                name: b.name,
                id: b.id,
                field: b.filterField,
                dataType: b.dataType || 'string',
                propertyType: b.propertyType,
              },
            ];
          }
          return a;
        }, [])
        .sort((a, b) => {
          if (specialFilterFields.findIndex((el) => el === a.id) === -1) return 1;
          if (specialFilterFields.findIndex((el) => el === b.id) === -1) return -1;
          return (
            specialFilterFields.findIndex((el) => el === a.id) - specialFilterFields.findIndex((el) => el === b.id)
          );
        });
    },
    'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
      if (checkEpsiloTableEndpoint(endpoint)) {
        return eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, {
          ...params,
          '@eTableConfig': backbone.config,
        });
      } else {
        return enhanceDataRequest2(params, originRequest, backbone);
      }
    },
  };

  const prevAddon = get(config, ['addons', 'datasource.getRowsParams'], null);
  config.addons = {
    ...config.addons,
    'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
      const prevParams = prevAddon ? prevAddon({ params }, currentConfig) : params;
      const specialFilters = backbone.getConfig('specialFilters') || {};

      if (checkEpsiloTableEndpoint(endpoint)) {
        prevParams.hiddenFilter = {
          ...prevParams.hiddenFilter,
          currency: 'USD',
        };
        prevParams.currency = get(prevParams, 'hiddenFilter.currency', 'USD');
      }

      const formattedSpecialFilters = Object.values(specialFilters).map((filter) => ({
        dataType: filter.dataType,
        field: filter.queryField,
        operator: filter.queryType,
        value: filter.queryValue,
      }));

      if (formattedSpecialFilters.length > 0) {
        return {
          ...prevParams,
          filter: {
            ...prevParams.filter,
            combinator: prevParams.filter?.combinator || 'and',
            filters: [...(prevParams.filter?.filters || []), ...formattedSpecialFilters],
          },
        };
      }

      return prevParams;
    },
  };
  if (checkEpsiloTableEndpoint(endpoint)) {
    config.addons = {
      ...config.addons,
      'system.export': (params, backbone) => {
        return customExportTable(params, backbone);
      },
      'system.groupby.aggFunc': (key) => {
        const aggFuncList = [
          'NONE',
          'UNIQUE',
          'SUM',
          'AVG',
          'MIN',
          'MAX',
          'COUNT_ALL',
          'COUNT_VALUES',
          'COUNT_UNIQUE',
          'COUNT_EMPTY',
          'COUNT_NOT_EMPTY',
          'PERCENT_EMPTY',
          'PERCENT_NOT_EMPTY',
          'RANGE',
        ].map((el) => {
          let fieldId = el;
          if (el === 'NONE') fieldId = 'NULL';
          if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
          return {
            id: el.toLowerCase(),
            requestQuery: fieldId,
          };
        });

        const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce(
          (carry, i) => ({ ...carry, [i.id]: i }),
          {},
        );

        return aggFuncByKey[key]
          ? aggFuncByKey[key]
          : {
              id: key,
              requestQuery: key.toUpperCase(),
            };
      },
    };
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
    const mappings = get(validConfig, 'mapping', {});
    const cachedFilterOption = {};
    Object.keys(mappings)
      .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
      .forEach((colKey) => {
        let apiFilterValue;
        if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
        else if (get(mappings, [colKey, 'valueGetter', 'id'], '')) {
          apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
        } else {
          apiFilterValue = get(
            mappings, //
            [colKey, 'valueGetter', 'value'],
            colKey,
          );
        }
        const addonKey = `filterValue.${colKey}`;
        config.addons[addonKey] = async () => {
          if (cachedFilterOption[addonKey]) return cachedFilterOption[addonKey];
          try {
            const result = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_listing.jsp', {
              attributes: [apiFilterValue],
            });

            const options = get(result, 'data.rows', []);

            cachedFilterOption[addonKey] = {
              data: options
                .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
              success: true,
              message: 'OK',
            };
            return cachedFilterOption[addonKey];
          } catch (e) {
            cachedFilterOption[addonKey] = null;
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        };
      });
    config.addons = {
      ...config.addons,
      'filterValue.country': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

          return Promise.resolve({
            data: availableCountryList.map((c) => ({
              label: c,
              value: c,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableMarketplaceList = [
            ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
          ];

          return Promise.resolve({
            data: availableMarketplaceList.map((m) => ({
              label: m,
              value: m,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const data = Object.values(storefrontList).map((el: any) => {
            return {
              label: [el.marketplace_code, el.country_code, el.storefront_name].join(' / '),
              value: el.id,
              payload: {
                sid: el.storefront_sid,
                country: {
                  code: el.country_code,
                },
                channel: {
                  id: el.marketplace_code,
                },
              },
            };
          });

          return Promise.resolve({
            data,
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
    };
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
    config.addons = {
      ...config.addons,
      'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
        const result = await getOperationStorefrontList();
        return result;
      },
      'filterValue.country': async (payload?: any, forceReload?: boolean) => {
        const result = await getOperationCountryList();
        return result;
      },
      'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
        const result = await getOperationMarketplaceList();
        return result;
      },
    };
  } else if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
    config.addons = {
      ...config.addons,
      'filterValue.country': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

          const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

          return Promise.resolve({
            data: availableCountryList.map((c) => ({
              label: c,
              value: c,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

          const availableMarketplaceList = [
            ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
          ];

          return Promise.resolve({
            data: availableMarketplaceList.map((m) => ({
              label: m,
              value: m,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

          const data = Object.values(storefrontList).map((el: any) => {
            return {
              label: [el.marketplace_code, el.country_code, el.name].join(' / '),
              value: el.storefront_id,
              payload: {
                sid: el.storefront_sid,
                country: {
                  code: el.country_code,
                },
                channel: {
                  id: el.marketplace_code,
                },
              },
            };
          });

          return Promise.resolve({
            data,
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
    };
  }
  if (queryParams.namespace) {
    const namespace = queryParams.namespace;
    const mappings = get(validConfig, 'mapping', {});
    Object.keys(mappings)
      .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
      .forEach((colKey) => {
        let apiFilterValue;
        if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
        else if (get(mappings, [colKey, 'valueGetter', 'id'], '')) {
          apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
        } else {
          apiFilterValue = get(
            mappings, //
            [colKey, 'valueGetter', 'value'],
            colKey,
          );
        }
        const addonKey = `filterValue.${colKey}`;
        const cachedFilterOption = {};

        config.addons[addonKey] = async () => {
          if (cachedFilterOption[addonKey]) return cachedFilterOption[addonKey];
          try {
            const result = await eipRequest.post(
              EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing?namespace=' + namespace,
              {
                attributes: [apiFilterValue],
              },
            );

            const options = get(result, 'data.rows', []);

            cachedFilterOption[addonKey] = {
              data: options
                .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
              success: true,
              message: 'OK',
            };
            return cachedFilterOption[addonKey];
          } catch (e) {
            cachedFilterOption[addonKey] = null;
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        };
      });

    config.addons = {
      ...config.addons,
      'filterValue.storefront': async () => {
        const result = await getNamespaceStorefrontList(queryParams.namespace);
        return result;
      },
      'filterValue.country': async () => {
        const result = await getNamespaceCountryList(queryParams.namespace);
        return result;
      },
      'filterValue.marketplace': async () => {
        const result = await getNamespaceMarketplaceList(queryParams.namespace);
        return result;
      },
    };
  }

  let pivot = { values: [], queryField: null };
  config.addons = {
    ...config.addons,
    'system.reset.columns': resetColumns,
    'pivot.get': async function () {
      return pivot;
    },
    'pivot.set': async function (pivotData) {
      pivot = pivotData;
    },

    'cell.customActions': async (field, additionalData, backbone) => {
      const mappings = backbone.getConfig('mapping');

      const colDef = mappings[field];

      const customCellActions = get(colDef, ['actions'], [])
        .filter((el) => el.category !== ACTION_CATEGORY_STATUS)
        .map((el) => {
          const useInlineEdit = [ACTION_CATEGORY_TAG, ACTION_CATEGORY_INLINE_FORM];
          if (useInlineEdit.includes(el.category) || !el.category) {
            return {
              component: InlineEdit,
              payload: el.configuration.map((el) => ({
                ...el,
                cellFormat,
              })),
              customProps: {
                actionName: el.name,
                tracking: el.tracking,
                category: el.category,
                ...additionalData,
              },
              name: el.name,
              icon: el.icon,
              disable: el.disable,
              shortcut: el.shortcut,
              field,
            };
          }

          const action = {
            onSubmit({ row }) {
              if (el.category === ACTION_CATEGORY_FILTER) {
                backbone.updateTempStorage('filterModal', { field, value: '' });
                backbone.changeVisibility('filter', true);
              }
              if (el.category === ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS) {
                const link = ((el.configuration || []).find((el) => el.key === 'url') || { value: '' }).value;

                const isInternalLink = /^(http(s?)):\/\/[^.]*.?epsilo.io/.test(link);

                const url = link.replace(/\:\w+/g, (c) => {
                  const { value } = el.configuration.find((el) => el.key === c.slice(1)) || { value: '' };
                  return row[value];
                });
                isInternalLink
                  ? window.open(url, '_blank')
                  : window.open(`/redirect-external?url=${encodeURIComponent(url)}`, '_blank');
              }
            },
            payload: transformPayload(el, cellFormat),
            name: el.name,
            icon: el.icon,
            disable: el.disable,
            shortcut: el.shortcut,
            id: el.category === ACTION_CATEGORY_IMPACT_TABLE ? 'history' : undefined,
            field,
          };

          if (el.category === ACTION_CATEGORY_PERIOD) {
            action.component = PeriodUpdate;
          }
          if (el.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
            action.component = CompactTable;
          }

          return action;
        });

      return customCellActions;
    },
  };
  return config;
};

const transformPayload = (config, cellFormat) => {
  if (config.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
    return get(config, ['configuration'], []).reduce(
      (a, b) => {
        return {
          ...a,
          [b.key]: b.value,
        };
      },
      { cellFormat },
    );
  }
  if (config.category !== ACTION_CATEGORY_IMPACT_TABLE) return config.configuration;
  return {
    field: config.configuration.reduce((a, b) => {
      return {
        ...a,
        [b.key]: b.value,
      };
    }, {}),
  };
};
