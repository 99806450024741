import { get } from 'lodash';
import { EIP_CONSTANT, eipRequest as request } from '@eip/next/lib/main';

/**
 * Kratos api: https://www.ory.sh/kratos/docs/reference/api
 */
const API_AUTHENTICATION = EIP_CONSTANT.API_HOST.API_AUTHENTICATION;

const PREFIX = `/.ory/kratos/public`;

async function initRecoverPasswordFlow() {
  const data = await request
    .get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/recovery/browser?refresh=true')
    .then((res) => {
      const tokenNode = get(res, 'ui.nodes', []).find((i) => i.attributes.name === 'csrf_token');
      return { crsfToken: tokenNode.attributes.value, flowId: res.id };
    });

  return data;
}

async function initProfileSettingFlow() {
  const data = await request
    .get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/settings/browser?refresh=true')
    .then((res) => {
      const tokenNode = get(res, 'ui.nodes', []).find((i) => i.attributes.name === 'csrf_token');
      return { crsfToken: tokenNode.attributes.value, flowId: res.id };
    });

  return data;
}

function requestRecoverPassword(email, csrfToken, flowId) {
  return request.post(API_AUTHENTICATION + '/.ory/kratos/public/self-service/recovery?flow=' + flowId, {
    csrf_token: csrfToken,
    email: email,
    method: 'link',
    __noRequestId: true,
  });
}

function requestUserSettingFlows(flowId) {
  return request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/settings/flows?id=' + flowId);
}

function updateUserPassword(
  flowId,
  values: {
    csrfToken: string;
    password: string;
  },
) {
  return request.post(API_AUTHENTICATION + '/.ory/kratos/public/self-service/settings?flow=' + flowId, {
    csrf_token: values.csrfToken,
    password: values.password,
    method: 'password',
  });
}

async function initRegistrationFlow() {
  const requiredFields = [
    'traits.email',
    'traits.company.name',
    'traits.name.first',
    'traits.name.last',
    'password',
    'csrf_token',
  ];
  return request
    .get(
      API_AUTHENTICATION +
        PREFIX +
        '/self-service/registration/browser?return_to=' +
        (process.env.NODE_ENV == 'production'
          ? encodeURIComponent(window.location.protocol + '//' + window.location.host)
          : encodeURIComponent('https://to.epsilo.io')),
    )
    .then((res) => {
      const fields = get(res, 'ui.nodes', [])
        .filter((i) => requiredFields.indexOf(i.attributes.name) > -1)
        .reduce((accum, i) => {
          return { ...accum, [i.attributes.name]: i };
        }, {});
      fields['flow'] = res.id;
      return fields;
    })
    .then((fields) => {
      const csrfToken = fields['csrf_token'].attributes.value;
      return {
        flow: fields['flow'],
        csrfToken: csrfToken,
      };
    });
}

async function submitRegistration(flow, { csrfToken, password, companyName, email, firstName, lastName }) {
  return request.post(API_AUTHENTICATION + PREFIX + '/self-service/registration?flow=' + flow, {
    csrf_token: csrfToken,
    method: 'password',
    password: password,
    traits: {
      name: {
        first: firstName,
        last: lastName,
      },
      email: email,
      company: {
        name: companyName,
      },
    },
  });
}

async function logout() {
  await request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout/browser').then((token) => {
    const { logout_url: url } = token;
    const logoutUrl = new URL(url);
    const logoutToken = logoutUrl.searchParams.get('token');
    return request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout?token=' + logoutToken);
  });
}

export const authentication = {
  initRecoverPasswordFlow,
  initProfileSettingFlow,
  requestRecoverPassword,
  requestUserSettingFlows,
  updateUserPassword,
  initRegistrationFlow,
  submitRegistration,
  logout,
};
