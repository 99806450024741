import {
  EVT_CHANNEL_CLOSE,
  UPSTREAM_CALCULATE,
  getChannelUpstreamCalculate,
  renewChannel,
} from '@ep/insight-ui/sw/channel';
import * as React from 'react';
import { EEmitter } from './e-emitter';

function isUpstreamCalculating(pattern) {
  return typeof pattern === 'string' && pattern.indexOf('_upstream_func/') === 0;
}

const ee = new EEmitter();
function upstreamSubscribe(event) {
  if (event.data.type === EVT_CHANNEL_CLOSE) {
    setTimeout(() => {
      getChannelUpstreamCalculate().addEventListener('message', upstreamSubscribe);
    }, 50);
  } else {
    console.log('upstream_data_received', event.data);
    ee.emit('upstream_data_received/' + event.data.id, event);
  }
}
getChannelUpstreamCalculate().addEventListener('message', upstreamSubscribe);

export function E({ children, pattern }) {
  const [val, setVal] = React.useState(() => {
    if (isUpstreamCalculating(pattern)) {
      return <span style={{ opacity: '0.39' }}>...</span>;
    } else {
      if (String(pattern)?.includes('</span>')) {
        return children(<div dangerouslySetInnerHTML={{ __html: pattern }}></div>);
      }
      return children(pattern);
    }
  });

  React.useLayoutEffect(() => {
    const subscribe = (event) => {
      if (event.data && event.data.id === pattern) {
        setVal(() => {
          const result = event.data.result;
          if (String(result)?.includes('</span>')) {
            return children(<div dangerouslySetInnerHTML={{ __html: result }}></div>);
          }
          return children(result);
        });
      }
    };
    if (isUpstreamCalculating(pattern)) {
      // getChannelUpstreamCalculate().addEventListener('message', subscribe);
      ee.on('upstream_data_received/' + pattern, subscribe);
    }
    return () => {
      // getChannelUpstreamCalculate().removeEventListener('message', subscribe);
      ee.off('upstream_data_received/' + pattern, subscribe);
    };
  }, [pattern]);

  return val;
}
