import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Downarrow = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99406 1.00245L3.99406 8.57879L2.70538 7.28941C2.51727 7.1012 2.26214 6.99546 1.99611 6.99546C1.73008 6.99546 1.47494 7.1012 1.28683 7.28941C1.09872 7.47762 0.993042 7.73289 0.993042 7.99907C0.993042 8.26524 1.09872 8.52051 1.28683 8.70872L4.28377 11.7073C4.37664 11.801 4.48712 11.8753 4.60886 11.9261C4.73059 11.9768 4.86117 12.0029 4.99304 12.0029C5.12492 12.0029 5.25549 11.9768 5.37723 11.9261C5.49896 11.8753 5.60945 11.801 5.70232 11.7073L8.69925 8.70872C8.79239 8.61553 8.86628 8.50489 8.91669 8.38313C8.9671 8.26137 8.99304 8.13086 8.99304 7.99907C8.99304 7.86727 8.9671 7.73677 8.91669 7.615C8.86628 7.49324 8.79239 7.3826 8.69925 7.28941C8.60611 7.19622 8.49553 7.12229 8.37383 7.07185C8.25214 7.02142 8.1217 6.99546 7.98998 6.99546C7.85825 6.99546 7.72782 7.02142 7.60612 7.07185C7.48442 7.12229 7.37385 7.19622 7.2807 7.28941L5.99202 8.57879L5.99202 1.00245C5.99202 0.737358 5.88677 0.483127 5.69943 0.295682C5.51208 0.108236 5.25799 0.0029295 4.99304 0.00292951C4.7281 0.00292952 4.474 0.108236 4.28666 0.295682C4.09931 0.483127 3.99406 0.737358 3.99406 1.00245Z"
      fill="#8D979E"
    />
  </SvgIcon>
);
export default Downarrow;
