export const EO2_SYSTEM_PAGE_ID = {
  massOperation: {
    campaignManagement: '237840cd-0dac-4ca0-bfc0-9a2ccb54d6ea',
  },
  marketplaceAdvertising: {
    keywordDiscovery: '3ac202ae-40cd-1649-b299-3828194f9ao1',
  },
  productHealth: {
    adoptionRate: 'f9a0d69c-3c78-4cb8-b3b1-bf9ef315f010',
  },
};
