import * as React from 'react';
import { IValueStatusTextFormat } from '@ep/insight-ui/elements/table/format/status-text-format';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import { makeStyles } from '@material-ui/core';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import GroupButtonSubmit from '../group-button-submit';

const useStyles = makeStyles(() => ({
  input: {
    width: '100%',
  },
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
}));

const options: OptionSelectType[] = [
  {
    label: 'Michael Tan',
    value: 'Michael_Tan',
  },
  {
    label: 'Loh Xi Cheng',
    value: 'Loh_Xi_Cheng',
  },
  {
    label: 'Yao Sing Tung',
    value: 'Yao_Sing_Tung',
  },
];

type Props = {
  data: {
    value: IValueStatusTextFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const NameReceiverEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const [valueReceiver, setValueReceiver] = React.useState([]);
  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    handleClosed();
  };
  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '466px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <SelectMultipleForm
        className={`${classes.input}`}
        disabled={false}
        error={false}
        options={options}
        multipleSelect={true}
        searchAble={true}
        chip={false}
        value={valueReceiver}
        onChange={setValueReceiver}
      />
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} />
    </div>
  );
};
export default NameReceiverEditor;
