import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CommentBlank = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 2C2.44772 2 2 2.44772 2 3V10C2 10.5523 2.44772 11 3 11H10.25L13.1877 13.3501C13.515 13.612 14 13.3789 14 12.9597V10V3C14 2.44772 13.5523 2 13 2H3Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default CommentBlank;
