import * as React from 'react';
import { PageContainerResponsive } from '@eip/next/stories/common-system-page.stories';
import { Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Icon from '@ep/insight-ui/icons/Icon';
import EllipseFormBackground from '@ep/insight-ui/elements/input-form/ellipse-form-background';
import FormFooter from '@ep/insight-ui/elements/input-form/form-templates/footer';
import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';

const API_CHECK_REPORT = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/action/check-report-available';
const useStyles = makeStyles({
  container: {
    display: 'flex',
    position: 'relative',
    minHeight: '100vh',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
  },
  logo: {
    textAlign: 'center',
    margin: '32px 0',
  },
  content: {
    zIndex: '100',
    padding: '32px 44px',
    borderRadius: '8px',
    marginTop: '36px',
  },
  header: {
    color: '#253746',
    margin: 0,
    marginBottom: '64px',
    '& .title': {
      margin: 0,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '40px',
      marginBottom: '16px',
    },
    '& .center': {
      textAlign: 'center',
    },
  },
  subTitle: {
    fontSize: '14px',
  },
  link: {
    color: '#23a8fb',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
const DownloadPage = () => {
  const classes = useStyles();
  const [title, setTitle] = React.useState('');
  const [subTitle, setSubTitle] = React.useState('');
  const [statusApi, setStatusApi] = React.useState(false);
  const goToHomePage = () => {
    window.open('https://one.epsilo.io/');
  };
  const queryParams = new URLSearchParams(window.location.search);
  const reportId = queryParams.get('report');
  const requestAPI = async () => {
    try {
      const response = await request.get(API_CHECK_REPORT, {
        reportId,
      });
      if (response || !response.serviceResponse.success) {
        switch (response.serviceResponse.code) {
          case 4001:
            setTitle('Invalid URL');
            setSubTitle(response.data);
            break;
          case 4002:
            setTitle('Permission denied');
            setSubTitle(response.data);
            break;
          case 4003:
            setTitle('The link you followed has expired');
            setSubTitle(response.data);
            break;
          default:
            setTitle('Internal System');
            break;
        }
      }
      if (response.serviceResponse.success) {
        setTitle('Download in progress...');
        setSubTitle('Your download should start automatically. If not, please ');
        setStatusApi(true);
        // window.open(response.data, '_parent');
        window.location.href = response.data;
      }
    } catch (error) {
      setTitle('Internal System');
    }
  };

  React.useEffect(() => {
    requestAPI();
  }, []);

  return (
    <PageContainerResponsive>
      <EllipseFormBackground />
      <Grid container className={clsx(`${classes.container}`)}>
        <div>
          <Grid item xs={12} className={classes.logo}>
            <Icon type="logo" colorIcon={'#1A2731'} />
          </Grid>
          <Grid item xs={12} className={clsx(classes.content)}>
            <Grid item xs={12} className={clsx(classes.header)}>
              <p className={clsx(`title center`)}>{title}</p>
              <p className={clsx(`${classes.subTitle} center`)}>
                {subTitle}
                {statusApi ? (
                  <span onClick={requestAPI} className={clsx(classes.link)}>
                    click here.
                  </span>
                ) : null}
              </p>
              <p className={clsx(`${classes.subTitle} center`)}>
                Or{' '}
                <span onClick={goToHomePage} className={clsx(classes.link)}>
                  go to homepage
                </span>
              </p>
            </Grid>
          </Grid>
        </div>
        <FormFooter copyright={`© ${new Date().getFullYear()} Epsilo. All Rights Reserved`} />
      </Grid>
    </PageContainerResponsive>
  );
};
export default DownloadPage;
