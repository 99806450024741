import * as React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';
import { useSetAtom } from 'jotai';

import type { ICellRendererParams } from '@ag-grid-community/core';

import {
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  Popover,
  TextField,
  Tooltip,
  makeStyles,
  Checkbox,
} from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import { CELL_FORMAT_OPTIONS } from '@ep/insight-ui/system/block/etable/cell-format-options';
import cellFormatTooltip from '@ep/insight-ui/system/block/etable/cell-format-tooltip';
import { formulaPopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-formula';

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const useStylesCheckedBox = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '2px solid',
    borderRadius: '2px',
    borderColor: '#C2C7CB !important',
    width: '12px',
    height: '12px',
    '& .eip1-MuiSvgIcon-root': {
      width: 'auto',
    },
  },
  rootChecked: {
    backgroundColor: '#0369C7',
    borderColor: '#0369C7  !important',
    '&.icon_disabled': {
      backgroundColor: '#E4E7E9',
      borderColor: '#E4E7E9 !important',
      pointerEvents: 'none',
    },
  },
  icon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
      pointerEvents: 'none',
    },
  },
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
  },
}));

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  selectLabel: {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  removeContainer: {
    '.ag-react-container > &': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  tooltipConatiner: {
    minWidth: '200px',
    minHeight: '40px',
    background: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listItem: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
    borderRadius: '4px',
    '&.hasSelectAll': {
      paddingLeft: '20px',
    },
  },
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
    marginBottom: '8px',
  },
  inputIcon: {
    marginLeft: '12px',
  },
});

const CheckedBox = ({ icon }: { icon: string }) => {
  const classes = useStylesCheckedBox();
  return (
    <Box className={clsx(classes.root, icon !== 'default' && classes.rootChecked)}>
      {icon !== 'default' && <Icon type={icon} className={classes.icon} />}
    </Box>
  );
};

const MultiSelectFormat = (props: ICellRendererParams) => {
  const classes = useStyles();

  const displayFormula = useSetAtom(formulaPopup);

  const propsOptions = get(props, 'options', []).map((opt) => {
    return typeof opt === 'string' ? { label: CELL_FORMAT_OPTIONS[opt]?.label || opt, value: opt } : opt;
  });

  const value = (props.value ? [].concat(props.value) : []).filter((el) => el);

  const additionalOptions = props.allowCustomOption
    ? (value || [])
        .filter((el) => !!el && propsOptions.every((ele) => ele.value !== el))
        .map((el) => ({
          label: el,
          value: el,
        }))
    : [];

  const options = propsOptions.concat(additionalOptions);

  const [selectedItems, setSelectedItems] = React.useState([].concat(value));

  const selectedOptions = React.useMemo(() => {
    return selectedItems.map((el) => options.find((ele) => ele.value === el) || { label: el, value: el });
  }, [selectedItems]);

  const [inputValue, setInputValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectAll, setSelectAll] = React.useState(false);

  React.useEffect(() => {
    setSelectAll(selectedItems.length === options.length);
  }, [selectedItems]);

  const isSelectAllShow = React.useMemo(() => {
    return props.allowSelectAll && !inputValue;
  }, [inputValue, props.allowSelectAll]);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    props.node.setDataValue(
      props.field,
      props.allowCustomOption ? selectedItems : selectedItems.filter((el) => options.some((ele) => ele.value === el)),
    );
    setAnchorEl(null);
  };

  const customOption = React.useMemo(() => {
    if (!props.allowCustomOption || !inputValue) return [];
    return !options.some((option) => option.label.toLowerCase() === inputValue.toLowerCase())
      ? [
          {
            label: `Create "${inputValue}"`,
            value: inputValue,
          },
        ]
      : [];
  }, [inputValue, props.allowCustomOption, props.allowFormula, options]);

  const listItems = options
    .concat(customOption)
    .filter((option) => option.label.toLowerCase().includes(inputValue.toLowerCase()) || !props.hasSearch)
    .map((option) => {
      const CellTooltip = cellFormatTooltip[option.value];
      const checked = selectedItems.includes(option.value);
      return (
        <ConditionalWrap
          key={option.value}
          condition={CellTooltip}
          wrap={(children) => {
            return (
              <Tooltip
                title={
                  <Box className={classes.tooltipConatiner}>
                    <CellTooltip />
                  </Box>
                }
                placement="right"
              >
                {children}
              </Tooltip>
            );
          }}
        >
          <ListItem
            className={clsx(classes.listItem, isSelectAllShow && 'hasSelectAll')}
            onClick={() => {
              const newSelectedItems = checked
                ? selectedItems.filter((el) => el !== option.value)
                : [...selectedItems, option.value];
              setSelectedItems(newSelectedItems);
            }}
          >
            <Checkbox
              checked={checked}
              color="primary"
              icon={<CheckedBox icon={'default'} />}
              checkedIcon={<CheckedBox icon={'checked'} />}
            />
            <span>{option.label}</span>
          </ListItem>
        </ConditionalWrap>
      );
    });

  return (
    <Box className={classes.container}>
      <Box className={classes.selectLabel} onClick={handleClick}>
        <span>{selectedOptions.map((el) => el.label).join(',')}</span>
        <Icon type={'arrowdown'} />
      </Box>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        {props.hasSearch && (
          <TextField
            className={clsx(classes.textStyle, classes.textSearch)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <Search style={{ width: 12, height: 14 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <>
                  <Box>
                    <IconButton
                      onClick={(event) => {
                        const availVars = [];
                        props.api.forEachNode((node) => {
                          availVars.push(node.data.key);
                        });
                        event.stopPropagation();
                        displayFormula({
                          isDisplay: true,
                          content: inputValue,
                          ref: event.currentTarget,
                          backboneConfig: { mapping: props.mappingConfig },
                          availableVars: availVars,
                          submit: (content) => {
                            props.node.setDataValue(props.field, [...selectedItems, content]);
                            setAnchorEl(null);
                          },
                        });
                      }}
                      style={{ opacity: inputValue ? 1 : 0 }}
                    >
                      <Icon type={'rmx/functions-icon/blue6'} />
                    </IconButton>
                  </Box>
                  <IconButton onClick={() => setInputValue('')} style={{ opacity: inputValue ? 1 : 0 }}>
                    <Icon type={'closeCircle'} />
                  </IconButton>
                </>
              ),
              classes: { input: classes['input'] },
              fullWidth: true,
            }}
          />
        )}
        <List>
          {isSelectAllShow && (
            <ListItem
              className={classes.listItem}
              onClick={() => {
                if (selectAll) {
                  setSelectedItems([]);
                } else {
                  setSelectedItems(options.map((el) => el.value));
                }
              }}
            >
              <Checkbox
                checked={selectAll}
                color="primary"
                icon={<CheckedBox icon={'default'} />}
                checkedIcon={<CheckedBox icon={'checked'} />}
              />
              <span>All</span>
            </ListItem>
          )}
          {listItems}
        </List>
      </Popover>
    </Box>
  );
};

export default MultiSelectFormat;
