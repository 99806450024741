import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Strikethrough = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.75065 3.76261C8.35386 3.76484 8.04017 3.43832 7.93559 3.05556V3.05556C7.87487 2.83333 7.77946 2.64444 7.64934 2.48889C7.51923 2.33333 7.35443 2.21111 7.1636 2.12222C6.96409 2.03333 6.73856 1.98889 6.46967 1.98889C6.21812 1.98889 5.99259 2.02222 5.80176 2.1C5.61093 2.17778 5.4548 2.27778 5.33336 2.4C5.21192 2.53333 5.11651 2.68889 5.04711 2.85556C4.9864 3.03333 4.9517 3.22222 4.9517 3.41111C4.9517 3.81111 5.11651 4.14444 5.43745 4.42222C5.68032 4.62222 5.93187 4.82222 6.34823 5H3.34699C3.31229 4.93333 3.2776 4.85556 3.25158 4.78889C3.07809 4.38889 3.00003 3.93333 3.00003 3.4C3.00003 2.88889 3.08677 2.43333 3.26025 2.01111C3.43373 1.58889 3.66793 1.23333 3.9802 0.933333C4.29247 0.644444 4.66545 0.411111 5.09049 0.244444C5.51552 0.0888889 5.99259 0 6.51304 0C7.04216 0 7.51056 0.0888889 7.95294 0.277778C8.37797 0.455555 8.75096 0.722222 9.05455 1.05556C9.35814 1.38889 9.59235 1.78889 9.75715 2.24444C9.81966 2.41723 9.86969 2.59641 9.90772 2.7826C10.0161 3.3132 9.56263 3.75802 9.02107 3.76108L8.75065 3.76261Z"
      fill="#253746"
    />
    <path
      d="M4.24941 9.23739C4.6462 9.23516 4.95988 9.56168 5.06446 9.94444V9.94444C5.12518 10.1667 5.2206 10.3556 5.35071 10.5111C5.48082 10.6667 5.64563 10.7889 5.83646 10.8778C6.03596 10.9667 6.26149 11.0111 6.53039 11.0111C6.78194 11.0111 7.00746 10.9778 7.19829 10.9C7.38912 10.8222 7.54526 10.7222 7.66669 10.6C7.78813 10.4667 7.88355 10.3111 7.95294 10.1444C8.01366 9.96667 8.04836 9.77778 8.04836 9.58889C8.04836 9.18889 7.88355 8.85556 7.56261 8.57778C7.31973 8.37778 7.06818 8.17778 6.65182 8L1 8C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H11C11.5523 6 12 6.44772 12 7C12 7.55228 11.5523 8 11 8H9.65306C9.68776 8.06667 9.72246 8.14444 9.74848 8.21111C9.92196 8.61111 10 9.06667 10 9.6C10 10.1111 9.91329 10.5667 9.73981 10.9889C9.56632 11.4111 9.33212 11.7667 9.01985 12.0667C8.70759 12.3556 8.3346 12.5889 7.90957 12.7556C7.48454 12.9111 7.00746 13 6.48702 13C5.9579 13 5.48949 12.9111 5.04712 12.7222C4.62208 12.5444 4.2491 12.2778 3.9455 11.9444C3.64191 11.6111 3.40771 11.2111 3.2429 10.7556C3.18039 10.5828 3.13036 10.4036 3.09233 10.2174C2.98396 9.6868 3.43743 9.24198 3.97898 9.23892L4.24941 9.23739Z"
      fill="#253746"
    />
  </SvgIcon>
);
export default Strikethrough;
