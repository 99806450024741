import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import Icon from '@ep/insight-ui/icons/Icon';
import { IRowData } from '../../configs';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';

const useStyles = makeStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    '& p': {
      margin: 0,
    },
    marginBottom: '32px',
  },
  avatar: {
    margin: '0px 32px',
    marginLeft: 0,
    '& img': {
      width: '80px',
      height: '80px',
      borderRadius: '4px',
      flex: 'none',
      order: 0,
      flexGrow: 0,
    },
  },
  body: {
    flex: 1,
  },

  headTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '6px',
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    marginBottom: '20px',
    '& .contentItem': {
      display: 'inline-flex',
    },
  },
  star: {
    display: 'flex',
    alignItems: 'center',
  },
  review: {
    marginLeft: '50px',
    '& svg': {
      marginTop: '3px',
      marginRight: '5px',
    },
  },
  detail: {},
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#253746',
    '&.name': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
    },
    '&.position': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginBottom: '6px',
    },
    '&.star-number': {
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '20px',
      textAlign: 'left',
      marginRight: '6px',
      marginTop: '3px',
    },
    '&.review': {
      marginTop: '3px',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '20px',
      letterSpacing: '0px',
      textAlign: 'left',
      color: '#004AA8',
    },
    '&.detail': {
      whiteSpace: 'pre-line',
      lineHeight: 2,
    },
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 8px',
    height: '32px',
    borderRadius: '4px',
    boxShadow: 'unset !important',
  },
  hireNote: {
    flex: 1,
    marginLeft: '8px',
    marginTop: '3px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '2px 4px',
    color: '#253746',
    fontFamily: 'Roboto',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    '& .line-through': {
      textDecorationLine: 'line-through',
    },
  },
});

const getCurrency = (value: number, currency: string) => {
  if (currency == 'USD') {
    return `$${value}`;
  }
  return `${value}${currency}`;
};
const getStarIcon = (num: number) => {
  const yellow = Math.floor(num);
  let i = 0;
  const arr = [];
  while (i < 5) {
    if (i < yellow) {
      arr.push('starYellow');
    } else {
      arr.push('starGrey');
    }
    i++;
  }
  return arr.map((icon, index) => <Icon key={index} type={icon} />);
};
type IProps = {
  item: IRowData;
};
const MaketerItem = ({ item }: IProps) => {
  const classes = useStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.avatar}>
        <img src={item.avatar} alt={item.avatar} />
      </div>
      <div className={classes.body}>
        <div className={classes.headTitle}>
          <p className={`${classes.text} name`}>{item.name}</p>
          {item.isSelected ? (
            <Button variant="contained" color="default" className={classes.button}>
              Teamed
            </Button>
          ) : (
            <Button variant="contained" color="primary" className={classes.button}>
              Team up
            </Button>
          )}
        </div>
        <p className={`${classes.text} position`}>{item.position}</p>
        <div className={classes.content}>
          <div className={`contentItem ${classes.star}`}>
            <span className={`${classes.text} star-number`}>{item.star}</span>
            {getStarIcon(item.star)}
          </div>
          <div className={`contentItem ${classes.review}`}>
            <Icon type={'commentBlank'} color={'inherit'} style={{ color: '#004AA8' }} />
            <span className={`${classes.text} review`}>{formatCurrencyNumber(item.reviews)} reviews</span>
          </div>
          <div className={`contentItem ${classes.hireNote}`}>
            {item.hireNote.previousPrice > 0 ? (
              <>
                <span className="line-through">{getCurrency(item.hireNote.previousPrice, item.hireNote.currency)}</span>
                &nbsp;
              </>
            ) : null}
            {getCurrency(item.hireNote.currentPrice, item.hireNote.currency)}/{item.hireNote.perValue}
          </div>
        </div>
        <div className={classes.detail}>
          <p className={`${classes.text} detail`}>{item.about}</p>
        </div>
      </div>
    </div>
  );
};

export default MaketerItem;
