import * as React from 'react';
import { useLocation } from 'react-router-dom';

export function useFullPage() {
  const location = useLocation();
  const [isFullPage, setFullPage] = React.useState(false);

  React.useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    if (Number(searchParams.get('full')) === 1) {
      setFullPage(true);
    } else {
      setFullPage(false);
    }
  }, [location.search]);

  return isFullPage;
}
