import { EventEmitter2 } from 'eventemitter2';
/**
 * With the based library `eventemitter2`
 * a new event emitter, which support replay emitted data when new listener is added
 */
export class EEmitter {
  private emitter = new EventEmitter2({
    wildcard: true,
    delimiter: '.',
    newListener: false,
    maxListeners: 1000,
    verboseMemoryLeak: false,
    ignoreErrors: false,
  });

  private replayedEvents = new Map<string, any[]>();

  constructor(retentionTime = 60 * 1000) {
    setInterval(() => this.cleanUpReplayedData(), retentionTime);
  }

  public on(event: string, listener: (...args: any[]) => void): void {
    this.emitter.on(event, listener);

    if (this.replayedEvents.has(event)) {
      const replayedData = this.replayedEvents.get(event);

      replayedData.forEach((data) => listener(...data));
    }
  }

  public off(event: string, listener: (...args: any[]) => void): void {
    this.emitter.off(event, listener);
  }

  public emit(event: string, ...args: any[]): void {
    this.emitter.emit(event, ...args);

    if (!this.replayedEvents.has(event)) {
      this.replayedEvents.set(event, []);
    }

    const replayedData = this.replayedEvents.get(event);

    replayedData.push(args);
  }

  public removeAllListeners(event?: string): void {
    this.emitter.removeAllListeners(event);

    if (event) {
      this.replayedEvents.delete(event);
    }
  }

  private cleanUpReplayedData() {
    this.replayedEvents.forEach((data, event) => {
      if (data.length > 100) {
        this.replayedEvents.set(event, data.slice(-100));
      }
    });
  }
}
