import { CAMPAIGN_SETTING_CONFIG } from './table-config/campaign-settings';

export const CAMPAIGN_RES = {
  data: {
    headers: [
      'COUNTRY.country_code',
      'COUNTRY.country_name',
      'MARKETPLACE.marketplace_code',
      'STOREFRONT.storefront_id',
      'STOREFRONT.name',
      'AD_OBJECT.config',
      'ADTYPE.ads_type',
      'ADTOOL.ads_tool',
      'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id',
      'ADS_CAMPAIGN.name',
      'ADS_CAMPAIGN.campaign_code',
      'ADS_CAMPAIGN.status',
      'ADS_CALENDAR.timeline_to',
      'ADS_CALENDAR.timeline_from',
      'ADS_CALENDAR.ads_ops_ads_calendar_id',
      'ADS_CAMPAIGN.daily_budget',
      'ADS_CAMPAIGN.total_budget',
      'roas',
      'cost',
      'ads_gmv',
      'direct_gmv',
      'ads_item_sold',
      'direct_item_sold',
      'cpc',
      'click',
    ],
    primaryKeys: [
      'COUNTRY.country_code',
      'MARKETPLACE.marketplace_code',
      'STOREFRONT.storefront_id',
      'ADTYPE.ads_type',
      'ADTOOL.ads_tool',
      'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id',
      'ADS_CALENDAR.ads_ops_ads_calendar_id',
    ],
    rows: [
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'AD_OBJECT.config': CAMPAIGN_SETTING_CONFIG,
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000222,
        'ADS_CAMPAIGN.name': '1000000222_Campaign for old data',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE992620E1B3',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2020-12-27 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-06-28 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4579,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000223,
        'ADS_CAMPAIGN.name': '1000000223_Campaign for Products from 2020-06-02 (Old data)',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE9926443C10',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2020-12-01 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-06-02 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4580,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000224,
        'ADS_CAMPAIGN.name': '1000000224_Campaign for Products from 2020-06-07 (Old data)',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE9926483B41',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2020-12-06 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-06-07 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4581,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000225,
        'ADS_CAMPAIGN.name': '1000000225_Campaign for Products from  2020-12-02',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE992656A05C',
        'ADS_CAMPAIGN.status': 'PAUSED',
        'ADS_CALENDAR.timeline_to': '2020-12-31 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-12-02 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4582,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000226,
        'ADS_CAMPAIGN.name': '1000000226_Campaign for Products from  2020-12-07',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE99265B1941',
        'ADS_CAMPAIGN.status': 'PAUSED',
        'ADS_CALENDAR.timeline_to': '9999-12-31 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-12-07 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4583,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'VN',
        'COUNTRY.country_name': 'Vietnam',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4182,
        'STOREFRONT.name': 'AHC Vietnam Official Store',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000227,
        'ADS_CAMPAIGN.name': '1000000227_Campaign for Products from  2020-12-07 5ff2b82ccf4b4',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE99265E45FF',
        'ADS_CAMPAIGN.status': 'PAUSED',
        'ADS_CALENDAR.timeline_to': '2021-01-01 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-12-07 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4584,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'ID',
        'COUNTRY.country_name': 'Indonesia',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4165,
        'STOREFRONT.name': 'Bebelac Official Shop',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000488,
        'ADS_CAMPAIGN.name': '1000000488_Campaign for old data 5ff2b82cc1208',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE995C928A22',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2020-12-27 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-06-28 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4585,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'ID',
        'COUNTRY.country_name': 'Indonesia',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4165,
        'STOREFRONT.name': 'Bebelac Official Shop',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000489,
        'ADS_CAMPAIGN.name': '1000000489_Campaign for Products from 2020-01-05 (Old data)',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE995D473DFF',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2020-07-04 00:00:00',
        'ADS_CALENDAR.timeline_from': '2020-01-05 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4586,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'ID',
        'COUNTRY.country_name': 'Indonesia',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4165,
        'STOREFRONT.name': 'Bebelac Official Shop',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000490,
        'ADS_CAMPAIGN.name': '1000000490_Campaign for Products from 2019-04-30 (Old data) 5ff2b82cc5905',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE995DA6595E',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2019-10-29 00:00:00',
        'ADS_CALENDAR.timeline_from': '2019-04-30 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4587,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
      {
        'COUNTRY.country_code': 'ID',
        'COUNTRY.country_name': 'Indonesia',
        'MARKETPLACE.marketplace_code': 'SHOPEE',
        'STOREFRONT.storefront_id': 4165,
        'STOREFRONT.name': 'Bebelac Official Shop',
        'ADTYPE.ads_type': null,
        'ADTOOL.ads_tool': '',
        'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id': 1000000491,
        'ADS_CAMPAIGN.name': '1000000491_Campaign for Products from 2019-05-06 (Old data) 5ff2b82cc68d4',
        'ADS_CAMPAIGN.campaign_code': 'CAMPSP5FE995DB37BDF',
        'ADS_CAMPAIGN.status': 'ENDED',
        'ADS_CALENDAR.timeline_to': '2019-11-05 00:00:00',
        'ADS_CALENDAR.timeline_from': '2019-05-06 00:00:00',
        'ADS_CALENDAR.ads_ops_ads_calendar_id': 4588,
        'ADS_CAMPAIGN.daily_budget': 0,
        'ADS_CAMPAIGN.total_budget': 0,
        roas: 0,
        cost: 0,
        ads_gmv: 0,
        direct_gmv: 0,
        ads_item_sold: 0,
        direct_item_sold: 0,
        cpc: 0,
        click: 0,
      },
    ],
    pagination: { page: 1, limit: 100, total: 429 },
  },
  message: 'OK',
  code: 200,
};
