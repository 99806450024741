import * as React from 'react';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { get, groupBy, uniq } from 'lodash';

import { EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const API_MASS_CREATE_ADS_ATTRIBUTE_CALENDAR = (namespace = 'ads-operation') =>
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + `/${namespace}/mass-create-ads-attribute-calendar`;
const API_MASS_UPSERT_ADS_ATTRIBUTE_CALENDAR = (namespace = 'ads-operation') =>
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + `/${namespace}/mass-upsert-ads-attribute-calendar`;
const API_MASS_DELETE_ADS_ATTRIBUTE_CALENDAR = (namespace = 'ads-operation') =>
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + `/${namespace}/mass-delete-ads-attribute-calendar`;
const API_FILTER_ADS_ATTRIBUTE_CALENDAR = (namespace = 'ads-operation') =>
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + `/${namespace}/filter-ads-attribute-calendar`;

const getTimezoneOffsetByCountryCode = (countryCode: string) => {
  switch (countryCode) {
    case 'VN':
    case 'TH':
    case 'ID':
      return 7;
    case 'MY':
    case 'SG':
    case 'PH':
    case 'TW':
      return 8;
    default:
      return 0;
  }
};

export const useScheduleDaily = (props) => {
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [adsInfo, setAdsInfo] = React.useState({});

  const { onToast } = useToast();
  const backbone = React.useContext(TableBackboneContext);

  const namespace = props.namespace || 'ads-operation';
  const isScheduleShow = props.adsUnit || ['ADS_CAMPAIGN', 'ADS_OBJECT'].includes(props.adsType);
  const marketplace = get(props.rowData, ['MARKETPLACE.marketplace_code'], '');
  const country = get(props.rowData, ['COUNTRY.country_code'], '');
  const timezoneOffset = getTimezoneOffsetByCountryCode(country);
  const adsStatus = 'ONGOING';
  const fieldName = `${props.adsType}.name`;
  const objType = {
    SHOPEE: 'SHP',
    LAZADA: 'LZD',
    TOKOPEDIA: 'TOKO',
  };
  const type = props.adsUnit ? props.adsUnit : objType[marketplace] ? `${objType[marketplace]}_${props.adsType}` : '';
  const dateFormat = 'YYYY-MM-DD HH:00:00';

  const updatePosition = props.popoverRef.current?.updatePosition;

  const handleAdd = () => {
    const defaultValue = props.type === 'status' ? 'ONGOING' : '0';
    setItems([
      ...items,
      {
        date: moment().add(1, 'hour').format(dateFormat),
        value: defaultValue,
        fakeId: uuid(),
      },
    ]);
  };

  const handleSubmit = async () => {
    if (!type) {
      onToast({
        title: 'Failed',
        messages: 'Cannot create schedule daily for this marketplace',
        variant: 'error',
      });
      return;
    }
    if (isScheduleShow) {
      const bulkRows = uniq(
        backbone
          .getSelectedRows()
          .concat(props.rowData)
          .map((row) => get(row, [props.fieldId], '')),
      ).filter((el) => !!el);

      const massCreatePayload = bulkRows.reduce((carry, adsId) => {
        const adsItems = items
          .filter((item) => !item.id)
          .map((el) => {
            return {
              dateExecution: moment(el.date).format(dateFormat),
              fieldName: props.type,
              masterObjectId: adsId,
              masterObjectType: type,
              status: adsStatus,
              value: el.value,
            };
          });

        return [...carry, ...adsItems];
      }, []);

      const massUpsertPayload = items
        .filter((item) => item.id)
        .reduce((carry, item) => {
          const arr = item.ids.map((id, index) => {
            return {
              dateExecution: moment(item.date).format(dateFormat),
              fieldName: props.type,
              masterObjectId: get(item, ['masterObjectIds', index], null),
              masterObjectType: type,
              status: adsStatus,
              value: item.value,
              id,
            };
          });

          return carry.concat(arr);
        }, []);

      const requestDone = {
        create: massCreatePayload.length === 0,
        update: massUpsertPayload.length === 0,
      };

      if (massCreatePayload.length) {
        request
          .post(API_MASS_CREATE_ADS_ATTRIBUTE_CALENDAR(namespace), { data: massCreatePayload })
          .then((res) => {
            if (!res.success) {
              res.data.forEach((el, index) => {
                if (!el.success) {
                  const payload = get(massCreatePayload, [index], {});
                  onToast({
                    title: `Failed to create schedule for ${adsInfo[payload['masterObjectId']]} at ${
                      payload['dateExecution']
                    }`,
                    messages: Object.keys(el.error || {}).length ? el.error[Object.keys(el.error)[0]] : el.message,
                    variant: 'error',
                  });
                }
              });
            } else {
              onToast({
                title: 'Success',
                messages: 'Creating schedule successfully!',
                variant: 'success',
              });
            }
          })
          .catch((e) => {
            onToast({
              title: 'Failed',
              messages: 'Something went wrong!',
              variant: 'error',
            });
          })
          .finally(() => {
            requestDone.create = true;
            if (requestDone.create && requestDone.update) {
              props.handleClosed();
            }
          });
      }

      if (massUpsertPayload.length) {
        request
          .post(API_MASS_UPSERT_ADS_ATTRIBUTE_CALENDAR(namespace), { data: massUpsertPayload })
          .then((res) => {
            if (!res.success) {
              res.data.forEach((el, index) => {
                const payload = get(massUpsertPayload, [index], {});
                if (!el.success) {
                  onToast({
                    title: `Failed to update schedule for ${adsInfo[payload['masterObjectId']]} at ${
                      payload['dateExecution']
                    }`,
                    messages: Object.keys(el.error || {}).length ? el.error[Object.keys(el.error)[0]] : el.message,
                    variant: 'error',
                  });
                }
              });
            } else {
              onToast({
                title: 'Success',
                messages: 'Updating schedule successfully!',
                variant: 'success',
              });
            }
          })
          .catch((e) => {
            onToast({
              title: 'Failed',
              messages: 'Something went wrong!',
              variant: 'error',
            });
          })
          .finally(() => {
            requestDone.update = true;
            if (requestDone.create && requestDone.update) {
              props.handleClosed();
            }
          });
      }
    }
  };

  const handleDelete = async (item) => {
    if (item.id) {
      const idParams = item.ids.map((id) => `id=${id}`).join('&');
      eipRequest.deleteFetch(API_MASS_DELETE_ADS_ATTRIBUTE_CALENDAR(namespace) + '?' + idParams).then((res) => {
        if (res.success) {
          setItems(items.filter((i) => item.fakeId !== i.fakeId));
        }
      });
    } else {
      setItems(items.filter((i) => item.fakeId !== i.fakeId));
    }
  };

  const handleUpdate = (item, cloneDates) => {
    const existedItem = items.find(({ fakeId }) => item.fakeId === fakeId);
    if (!existedItem) return;

    if (cloneDates && cloneDates.length) {
      let [startDate, endDate] = cloneDates;
      if (!endDate) endDate = startDate;
      const diffDays = moment(endDate).diff(startDate, 'days');
      const daysNeedData = Array.from({ length: diffDays + 1 }).map((_, index) => {
        const newDate = moment(startDate).add(index, 'days').format('YYYY-MM-DD ') + item.date.split(' ')?.[1];
        return {
          ...item,
          id: newDate === item.date ? item.id : undefined,
          date: moment(startDate).add(index, 'days').format('YYYY-MM-DD ') + item.date.split(' ')?.[1],
          fakeId: uuid(),
        };
      });
      const newItems = items
        .filter(
          (i) =>
            (i.fakeId === item.fakeId && daysNeedData.every((data) => data.date !== item.date)) ||
            i.fakeId !== item.fakeId,
        )
        .concat(daysNeedData);
      setItems(newItems);
      return;
    }
    setItems(items.map((i) => (i.fakeId === item.fakeId ? item : i)));
  };

  React.useEffect(() => {
    if (!type) {
      setIsLoading(false);
      return;
    }
    if (isScheduleShow) {
      const selectedIds = uniq(backbone.getSelectedRows().concat(props.rowData))
        .map((row) => row[props.fieldId])
        .filter((row) => !!row);

      const adsInformation = uniq(backbone.getSelectedRows().concat(props.rowData)).reduce((carry, row) => {
        return {
          ...carry,
          [row[props.fieldId]]: row[fieldName],
        };
      }, {});

      setAdsInfo(adsInformation);

      const queryObj = {
        exactMatch: 'true',
        'searchBy.masterObjectType': type,
        'searchBy.fieldName': props.type,
        'searchBy.status': 'ONGOING',
        'paginate.limit': 365 * selectedIds.length,
        requestId: uuid(),
      };

      const idsQueryParams = '&' + selectedIds.map((id) => `searchBy.masterObjectId=${id}`).join('&');

      const queryStr = '?' + new URLSearchParams(queryObj).toString() + idsQueryParams;
      request
        .get(API_FILTER_ADS_ATTRIBUTE_CALENDAR(namespace) + queryStr)
        .then((res) => {
          if (res.success) {
            const groupByData = Object.values(groupBy(res.data, 'dateExecution')).reduce((carry, arr) => {
              if (
                arr.length === selectedIds.length &&
                arr.every((data) => selectedIds.some((id) => id == data['masterObjectId']))
              ) {
                const rowScheduleData = arr.find(
                  ({ masterObjectId }) => props.rowData[props.fieldId] == masterObjectId,
                );
                carry.push({
                  ...rowScheduleData,
                  masterObjectIds: selectedIds,
                  ids: arr.map((arr) => arr.id),
                });
              }

              return carry;
            }, []);
            setIsLoading(false);
            setItems(
              groupByData.map((el) => ({
                ...el,
                fakeId: uuid(),
                date:
                  props.namespace == 'campaign-management'
                    ? moment(el.dateExecution).format(dateFormat)
                    : moment(el.dateExecution).utc().add(timezoneOffset, 'hours').format(dateFormat),
              })),
            );
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  }, [props.adsId, type, isScheduleShow]);

  React.useEffect(() => {
    if (updatePosition) updatePosition();
  }, [items]);

  return {
    items,
    setItems,
    handleAdd,
    handleSubmit,
    handleDelete,
    handleUpdate,
    isLoading,
    dateFormat,
  };
};
