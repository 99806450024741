import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import MentionList from './MentionList';

const metrics = [
  {
    id: 'adsgmv',
    label: 'Ads GMV',
  },
  {
    id: 'adsitemsold',
    label: 'Ads item sold',
  },
  {
    id: 'cost',
    label: 'Cost',
  },
  {
    id: 'roas',
    label: 'ROAS',
  },
  {
    id: 'cir',
    label: 'CIR',
  },
  {
    id: 'totalgmv',
    label: 'Total GMV',
  },
  {
    id: 'totalitemsold',
    label: 'Total item sold',
  },
  {
    id: 'impression',
    label: 'Impression',
  },
  {
    id: 'click',
    label: 'Click',
  },
];

const metricData = [
  {
    metric: 'adsgmv',
    label: 'Ads GMV',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'adsgmv',
    label: 'Ads GMV',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'adsgmv',
    label: 'Ads GMV',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'adsgmv',
    label: 'Ads GMV',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
  {
    metric: 'adsitemsold',
    label: 'Ads item sold',
    value: '12.2',
    date: 'Today',
  },
  {
    metric: 'adsitemsold',
    label: 'Ads item sold',
    value: '14.3',
    date: 'Last 7 days',
  },
  {
    metric: 'adsitemsold',
    label: 'Ads item sold',
    value: '13.2',
    date: 'Last 14 days',
  },
  {
    metric: 'adsitemsold',
    label: 'Ads item sold',
    value: '16.7',
    date: 'Last 30 days',
  },
  {
    metric: 'cost',
    label: 'Cost',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'cost',
    label: 'Cost',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'cost',
    label: 'Cost',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'cost',
    label: 'Cost',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
  {
    metric: 'roas',
    label: 'ROAS',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'roas',
    label: 'ROAS',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'roas',
    label: 'ROAS',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'roas',
    label: 'ROAS',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
  {
    metric: 'cir',
    label: 'Cir',
    value: '12.2',
    date: 'Today',
  },
  {
    metric: 'cir',
    label: 'Cir',
    value: '14.3',
    date: 'Last 7 days',
  },
  {
    metric: 'cir',
    label: 'Cir',
    value: '13.2',
    date: 'Last 14 days',
  },
  {
    metric: 'cir',
    label: 'Cir',
    value: '16.7',
    date: 'Last 30 days',
  },
  {
    metric: 'totalgmv',
    label: 'Total GMV',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'totalgmv',
    label: 'Total GMV',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'totalgmv',
    label: 'Total GMV',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'totalgmv',
    label: 'Total GMV',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
  {
    metric: 'totalitemsold',
    label: 'Total item sold',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'totalitemsold',
    label: 'Total item sold',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'totalitemsold',
    label: 'Total item sold',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'totalitemsold',
    label: 'Total item sold',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
  {
    metric: 'impression',
    label: 'Impression',
    value: '12.2',
    date: 'Today',
  },
  {
    metric: 'impression',
    label: 'Impression',
    value: '14.3',
    date: 'Last 7 days',
  },
  {
    metric: 'impression',
    label: 'Impression',
    value: '13.2',
    date: 'Last 14 days',
  },
  {
    metric: 'impression',
    label: 'Impression',
    value: '16.7',
    date: 'Last 30 days',
  },
  {
    metric: 'click',
    label: 'Click',
    value: '3,438,400 IDR',
    date: 'Today',
  },
  {
    metric: 'click',
    label: 'Click',
    value: '2,405,700 IDR',
    date: 'Last 7 days',
  },
  {
    metric: 'click',
    label: 'Click',
    value: '9,634,000 IDR',
    date: 'Last 14 days',
  },
  {
    metric: 'click',
    label: 'Click',
    value: '29,134,800 IDR',
    date: 'Last 30 days',
  },
];
export default {
  items: ({ query }) => {
    return [...metricData, ...metrics].filter((item: any) => {
      if (item.metric === query.toLowerCase()) return true;
      return query === ''
        ? !!item.id
        : item.id &&
            item.id.toLowerCase() !== query.toLowerCase() &&
            item.id.toLowerCase().includes(query.toLowerCase());
    });
  },

  render: () => {
    let reactRenderer;
    let popup;

    return {
      onStart: (props) => {
        reactRenderer = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: reactRenderer.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        reactRenderer.updateProps(props);

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return reactRenderer.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        reactRenderer.destroy();
      },
    };
  },

  char: '#',
  decorationClass: 'test',
  allowSpaces: true,
};
