import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Note = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0C2.68333 0 0 2.68333 0 6C0 9.31667 2.68333 12 6 12C9.31667 12 12 9.31667 12 6C12 2.68333 9.31667 0 6 0ZM7 9C7 9.55 6.55 10 6 10C5.45 10 5 9.55 5 9V6C5 5.45 5.45 5 6 5C6.55 5 7 5.45 7 6V9ZM6 4C5.45 4 5 3.55 5 3C5 2.45 5.45 2 6 2C6.55 2 7 2.45 7 3C7 3.55 6.55 4 6 4Z" />
  </SvgIcon>
);

export default Note;
