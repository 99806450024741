import * as React from 'react';
import * as ReactDom from 'react-dom';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectProps,
  IconButton,
  TextField,
} from '@material-ui/core';
import { colors, noSelectStyled, scrollbarStyles, searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import _ from 'lodash';
import clsx from 'clsx';
import Search from '@ep/insight-ui/icons/svg/Search';
import { CreateCSSProperties } from '@material-ui/styles';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import CircleLoading from '../../loading/circle-loading';
import { Grid } from '@material-ui/core';

type PropsStyle = {
  inputWidth: string;
  inputNoShadow: boolean;
  chipColor: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: 'auto',
  },
  textSearch: {
    '& input': {
      width: '100%',
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  select: (props: PropsStyle) => ({
    width: props.inputWidth || '100%',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '20px',
    },
    '& :hover': {
      borderColor: '#006EC6',
    },
  }),
  popper: {
    ...scrollbarStyles,
    maxHeight: 'calc(100vh - 60%)',
    marginTop: '4px',
    boxSizing: 'border-box',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    '&.eres--small': {
      maxHeight: 'unset',
    },
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    position: 'absolute',
    right: '10px',
    top: '50%',
    transform: 'translateY(-50%)',
    color: colors.icon.default,
  },
  value: {
    maxWidth: '95%',
    width: 'fit-content',
    textOverflow: 'ellipsis',
    overflow: ' hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    lineHeight: '18px',
    ...noSelectStyled,
  },
  boxValueMultiple: {
    display: 'inline-block',
  },
  chip: (props: PropsStyle) => ({
    backgroundColor: props.chipColor,
    height: 'auto',
    padding: '2px 8px',
    borderRadius: '4px',
  }),
  textUnit: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: ' 14px',
    margin: '0 7px',
  },
  adornment: {
    position: 'absolute',
    right: 0,
  },
  iconButton: {
    padding: 0,
    width: '10px',
    height: '10px',
    marginLeft: '10px',
    marginTop: '2px',
    '&:active, &:focus': {
      backgroundColor: 'inherit',
      color: 'inherit',
    },
  },
  boxValue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '32px',
    position: 'absolute',
    padding: '5px 10px',
    gap: '5px',
  },
  boxValueSingle: {
    padding: '5px 0 5px 10px',
    top: 0,
    left: 0,
  },
  search: {
    // position: 'absolute',
    // top: '36px',
    // zIndex: 1400,
  },
  paper: {
    '& ul': {
      marginTop: '32px',
    },
  },
}));

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      height: '32px',
      border: `1px solid ${colors.border.default}`,
      paddingLeft: '8px',
      paddingRight: '8px',
      paddingTop: '4px',
      paddingBottom: '4px',
      boxSizing: 'border-box',
      color: colors.text.default,
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
        borderColor: colors.border.subdued,
      },
    },
  }),
)(InputBase);
export type AlignType = 'left' | 'right';
export interface SelectFormProps extends SelectProps {
  value: string | string[];
  options?: OptionSelectType[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeObject?: (value: OptionSelectType) => void;
  onChangeValueMultiple?: (value: string[]) => void;
  inputWidth?: string;
  alignMenu?: AlignType;
  placeholder?: string;
  disableIconSelect?: boolean;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  unit?: string;
  noShadow?: boolean;
  countNumber?: number;
  chip?: boolean;
  chipColor?: string;
  styledMenuItemClass?: string;
}

export const StyledMenuItem = withStyles(() => ({
  root: {
    minHeight: '32px',
    minWidth: '192px',
    borderRadius: '4px',
    padding: '8px',
    '&:hover': {
      backgroundColor: colors.action.secondaryHovered,
    },
    '&.Mui-selected': {
      backgroundColor: `#EBF6FF !important`,
      color: '#0369C7',
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    fontWeight: 400,
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
  },
}))(MenuItem);

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: ({ disabled }: { disabled: boolean }) => ({
    color: disabled ? '#C2C7CB' : '#D1D5DA',
  }),
}));

const Placeholder = ({ text, disabled = false }: { text: string; disabled: boolean }) => {
  const classes = usePlaceholderStyles({ disabled });
  return (
    <Typography variant="body2" className={classes.placeholder}>
      {text}
    </Typography>
  );
};

const SelectForm = ({
  options,
  value,
  onChange,
  onChangeObject,
  onChangeValueMultiple,
  alignMenu = 'left',
  inputWidth,
  placeholder,
  renderOption,
  disableIconSelect = false,
  disabled = false,
  unit = '',
  noShadow = false,
  countNumber = undefined,
  chip = false,
  chipColor = colors.action.primaryDisabled,
  ...rest
}: SelectFormProps) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);

  const classes = useStyles({ inputWidth, inputNoShadow: noShadow, chipColor });
  const [keyWork, setKeyWork] = React.useState<string>('');
  const [isOpen, setIsOpen] = React.useState(false);
  const results: OptionSelectType[] = React.useMemo(() => {
    return options.filter((option) =>
      keyWork.trim() ? option.label.toLocaleLowerCase().includes(keyWork.toLocaleLowerCase()) : true,
    );
  }, [options, keyWork]);

  const onClickItem = () => {
    if (refSelect?.current) {
      setIsOpen(true);
      refSelect.current.click();
    }
  };

  const renderValue = () => {
    const index = options.findIndex((option) => option.value == value);
    if (value !== '' && index !== -1) {
      return (
        <Box
          className={clsx(classes.boxValue, classes.boxValueSingle)}
          onClick={() => {
            setIsOpen(true);
          }}
          width={'100%'}
        >
          <Box display={'flex'} width={'100%'} alignItems={'center'}>
            {options[index].icon && (
              <span style={{ display: 'flex', margin: '0 20px', width: '12px', height: '14px' }}>
                <Icon type={options[index].icon} style={{ width: '100%', height: '100%' }} />
              </span>
            )}
            <span style={{ display: 'flex', width: '80%' }}>
              <div
                className={clsx(classes.value, 'select_value')}
                style={{
                  maxWidth: countNumber == undefined && 'fit-content',
                  width: countNumber == undefined && '100%',
                }}
              >
                {options[index].label}
              </div>
              <span>{countNumber > 0 ? `(${countNumber})` : ''}</span>
            </span>
          </Box>
        </Box>
      );
    }
    return placeholder ? <Placeholder text={placeholder} disabled={disabled} /> : null;
  };

  const renderValueMultiple = () => {
    if (rest.multiple && _.isArray(value)) {
      const selected = options.filter((op) => value.includes(op.value));
      const handleDelete = (index: number) => {
        selected.splice(index, 1);
        onChangeValueMultiple && onChangeValueMultiple(selected.map((opt) => opt.value));
      };
      return (
        <Box className={clsx(classes.boxValue)}>
          {selected.map((select, index) => (
            <Box
              className={clsx(classes.boxValueMultiple, chip && classes.chip)}
              height={'100%'}
              display={'flex'}
              alignItems={'center'}
              key={String(index)}
              onClick={() => onChangeObject && onChangeObject(select)}
            >
              {select.icon && (
                <span style={{ display: 'flex', margin: '0 20px', width: '12px', height: '14px' }}>
                  <Icon type={select.icon} style={{ width: '100%', height: '100%' }} />
                </span>
              )}
              <span style={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                <div
                  className={classes.value}
                  style={{
                    maxWidth: countNumber == undefined && 'fit-content',
                    width: countNumber == undefined && '100%',
                  }}
                >
                  {select.label}
                </div>
                <IconButton className={clsx(classes.iconButton)} onClick={() => handleDelete(index)}>
                  <Icon type="deleteChip" size={'10px'} />
                </IconButton>
                <span>{countNumber > 0 ? `(${countNumber})` : ''}</span>
              </span>
            </Box>
          ))}
        </Box>
      );
    }
    return null;
  };

  const handleOnChange = (e) => {
    onChange(e);
    if (onChangeObject) {
      const index = _.findIndex(options, ['value', value]);
      if (index) {
        onChangeObject(options[index]);
      }
    }
  };

  const refSelect = React.useRef<HTMLDivElement>(null);
  const [refreshLoading, setRefreshLoading] = React.useState(false);
  const reloadData = async () => {
    if (rest.requestOption) {
      setRefreshLoading(true);
      const res = await rest.requestOption(true);
      setRefreshLoading(false);
    }
  };

  return (
    <FormControl className={classes.select}>
      <Select
        ref={refSelect}
        disabled={disabled}
        autoFocus={false}
        // open={isOpen}
        onClose={() => {
          setIsOpen(false);
        }}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: alignMenu,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: alignMenu,
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: `${classes.popper} ${containerClass}`,
          },
        }}
        variant="outlined"
        value={value}
        onChange={handleOnChange}
        displayEmpty
        IconComponent={() => {
          if (!rest.isLoading && !refreshLoading) {
            if (options.length > 0 || rest.showIcon) {
              return (
                <div style={{ pointerEvents: 'none' }}>
                  {!disableIconSelect ? (
                    <Icon type="chevron" className={classes.icon} style={{ color: disabled && '#8C98A4' }} />
                  ) : unit ? (
                    <InputAdornment position="end" className={classes.adornment}>
                      <span className={classes.textUnit}>{unit}</span>
                    </InputAdornment>
                  ) : null}
                </div>
              );
            } else {
              return (
                <IconButton
                  onClick={reloadData}
                  style={{ padding: '0px', marginLeft: '6px', position: 'absolute', right: '8px' }}
                >
                  <Icon type={'reload'} colorIcon={'#253746'} />
                </IconButton>
              );
            }
          } else {
            return (
              <Grid style={{ position: 'absolute', right: '8px' }}>
                <CircleLoading size="15px" />
              </Grid>
            );
          }
        }}
        input={<BootstrapInput />}
        renderValue={renderValue}
        {...rest}
      >
        {results.map((item: OptionSelectType, index: number) => (
          <StyledMenuItem key={index} value={item.value} disabled={item.disabled} className={rest.styledMenuItemClass}>
            {item.icon && (
              <Box mx={2} display={'flex'} height={'100%'} alignItems={'center'} width={12}>
                <Icon type={item.icon} style={{ width: '100%', height: '100%' }} />
              </Box>
            )}
            {renderOption ? renderOption(item) : item.label}
          </StyledMenuItem>
        ))}
      </Select>
      {renderValueMultiple()}
    </FormControl>
  );
};

export default SelectForm;
