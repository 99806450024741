import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { Dialog, List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover } from '@material-ui/core';
import clsx from 'clsx';
import { get, isArray } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import Dropdown from '../dropdown-menu/dropdown';

const useStyles = makeStyles((theme) => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3px',
  },
  formatcell: {
    display: 'flex',
    flexDirection: 'column',
  },

  statusNone: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    textAlign: 'end',
  },
  text: {
    paddingLeft: '5px',

    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
  texttotal: {
    textAlign: 'end',
    paddingLeft: '5px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: '#253746',
  },
  textdaily: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    lineHeight: '0',
  },
  helpText: {
    fontSize: '10px',
    color: '#8C98A4',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    display: 'block',
    lineHeight: '0',
    marginTop: '-10px',
  },
  textCurrent: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
  },
  textPosition: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    display: 'flex',
    justifyContent: 'space-between',
  },
  forcell: {
    marginLeft: '-10px',
  },
  cellDropdown: {
    maxWidth: 250,
  },
  display: {
    display: 'none',
  },
  edotContainer: {
    // position: 'absolute',
    right: 0,
    background: 'transparent',
  },
  button: {
    minWidth: '24px',
    height: '24px',
  },
  menuDropdown: {
    paddingTop: '8px',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  disable: {
    pointerEvents: 'none',
    color: '#C2C7CB',
  },
}));

export const DropdownCellSubItem = (data) => {
  const classes = useStyles();
  const origin = React.useRef<HTMLDivElement>();
  const [indexRender, setIndexRender] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subActions, setSubActions] = React.useState(null);
  const onOpened = get(data, 'onOpened', () => undefined);
  const onClosed = get(data, 'onClosed', () => undefined);
  const [dropdownFuncs, setDropdownFuncs] = useState<{ open: () => void; close: () => void }>({
    open: () => undefined,
    close: () => undefined,
  });
  const handleClose = () => {
    setAnchorEl(null);
    onClosed();
  };

  let dropdownOnclose = null;
  dropdownOnclose = () => {
    setSubActions(null);
    onClosed();
  };
  const [isDeleteEditor, setIsDeleteEditor] = useState(false);

  const propsComponent = {
    onSubmit: data?.cellAction[indexRender].onSubmit,
    onLoad: data?.cellAction[indexRender].onLoad,
    title: data?.cellAction[indexRender].title,
    payload: data?.cellAction[indexRender].payload,
    customProps: data?.cellAction[indexRender]?.customProps,
    dimension: data?.cellAction[indexRender]?.dimension,
    field: data?.cellAction[indexRender]?.field,
    data: data.props,
    setAnchorEl: (val: any) => {
      setAnchorEl(val);
      if (!val) onClosed();
    },
    anchorEl: anchorEl,
    actionSubItem: data.props.actionSubItem,
  };
  const typeComponent = React.useMemo(() => data?.cellAction[indexRender]?.type, [indexRender, data]);
  const Component = React.useMemo(() => get(data, ['cellAction', indexRender, 'component'], null), [indexRender, data]);
  const handleClickMenuItem = (menu, index) => {
    if (menu.component) {
      setIndexRender(index);
      setAnchorEl(origin.current);
    } else {
      const staticValue = get(menu, ['payload', 'static']);
      const cellValue = get(data, 'props.value');
      const onSubmit = get(menu, ['onSubmit']);
      const menu_subActions = get(menu, ['subActions'], []);
      if (!(menu_subActions.length > 0)) {
        if (onSubmit) {
          onSubmit({
            value: staticValue || cellValue,
            row: get(data, 'props.node.data'),
          });
        }
        dropdownFuncs.close();
        onClosed();
      } else {
        setSubActions(menu_subActions);
      }
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');

  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const selectedRowCount = backboneContext.getSelectedRows().length;

  const mappingListItem = function renderActionListItem(menu, index) {
    return (
      <div key={index}>
        {/* {index === data.cellAction?.length - 1 && <Divider />} */}
        <ListItem
          className={clsx({
            [classes.itemDropdown]: true,
            [classes.disable]: menu.disable,
          })}
          disabled={menu.disable}
          role={'menuitem'}
          button
          onClick={() => {
            if (data.classField == 'keyword_status') {
              return uawl.start('canMassUpdateKeywordStatus', { title: titleTable, timerMs: 3000 }).then(() => {
                handleClickMenuItem(menu, index);
              });
            }
            if (data.classField == 'ad_object_status') {
              return uawl.start('canMassUpdateAdObjectStatus', { title: titleTable, timerMs: 3000 }).then(() => {
                handleClickMenuItem(menu, index);
              });
            }
            if (data.classField == 'campaign_status' || data.classField == 'ad_campaign_status') {
              return uawl.start('canMassUpdateCampaignStatus', { title: titleTable, timerMs: 3000 }).then(() => {
                handleClickMenuItem(menu, index);
              });
            }
            handleClickMenuItem(menu, index);
          }}
        >
          {menu.icon && (
            <ListItemIcon>
              <Icon
                style={menu.iconSize && { height: menu.iconSize, width: menu.iconSize }}
                type={menu.icon}
                colorIcon={menu.disable ? '#C2C7CB' : '#253746'}
              />
            </ListItemIcon>
          )}
          <ListItemText primary={menu.name} className={classes.textStyle} />
        </ListItem>
      </div>
    );
  };

  const renderActionMenu = (
    <List
      component="nav"
      aria-label="mailbox folders"
      className={`${classes.cellDropdown} eip_action_column_${data?.classField} eip_action_etable_${titleTableClass}`}
      mass={selectedRowCount > 1 ? 'true' : 'false'}
    >
      {!subActions
        ? data.cellAction && data.cellAction.map(mappingListItem)
        : isArray(subActions) && (subActions as Array<any>).map(mappingListItem)}
    </List>
  );

  const newAction = data.cellAction.map((menu, index) => {
    return {
      ...menu,
      onClick() {
        handleClickMenuItem(menu, index);
      },
      data: data.props,
    };
  });
  const editorComponent = React.useMemo(() => data?.cellAction[indexRender].editor, [indexRender, data]);
  return (
    <div ref={origin} className={classes.edotContainer}>
      {origin != null && (
        <Dropdown
          classNamePaper={classes.menuDropdown}
          className={`${classes.button} eip_cell_act_${data?.classField}`}
          alignMenu="left"
          icon={data.icon ? data.icon : 'threeDotsVertical'}
          label=""
          defaultOpen={Boolean(anchorEl)}
          onOpen={onOpened}
          handleOnClosed={dropdownOnclose}
          refFuncs={setDropdownFuncs}
          dataMenuPage={[newAction]}
          {...(ff.mobile_interaction_zone
            ? {
                isDropdownMobileShow: data.isDropdownMobileShow,
                setIsDropdownMobileShow: data.setIsDropdownMobileShow,
                title: data.classField,
              }
            : {})}
        >
          {renderActionMenu}
        </Dropdown>
      )}
      {Component && typeComponent == 'deleteEditor' ? (
        <Dialog
          open={Boolean(anchorEl)}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <Component {...propsComponent} />
        </Dialog>
      ) : (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          elevation={isDeleteEditor ? 0 : 1}
        >
          <Component {...propsComponent} />
        </Popover>
      )}
    </div>
  );
};
