import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Dashboard = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 0H6C5.44772 0 5 0.447715 5 1V2C5 2.55228 5.44772 3 6 3H9C9.55228 3 10 2.55228 10 2V1C10 0.447715 9.55228 0 9 0Z" />
    <path d="M9 4H6C5.44772 4 5 4.44772 5 5V9C5 9.55228 5.44772 10 6 10H9C9.55228 10 10 9.55228 10 9V5C10 4.44772 9.55228 4 9 4Z" />
    <path d="M3 7H1C0.447715 7 0 7.44772 0 8V9C0 9.55228 0.447715 10 1 10H3C3.55228 10 4 9.55228 4 9V8C4 7.44772 3.55228 7 3 7Z" />
    <path d="M3 0H1C0.447715 0 0 0.447715 0 1V5C0 5.55228 0.447715 6 1 6H3C3.55228 6 4 5.55228 4 5V1C4 0.447715 3.55228 0 3 0Z" />
  </SvgIcon>
);

export default Dashboard;
