import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import CalendarSelect from './calendar-select';
import ButtonFilter from '../../table-actions/button-filter';
import ButtonSearch from '../../table-actions/button-search';
import ButtonSort from '../../table-actions/button-sort';
import ButtonOption from '../../table-actions/button-option';
import ButtonSelectDate from '../../table-actions/button-select-date';
import ButtonSearchArea from '../../table-actions/button-search-area';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
/**
 * ff.ecompacttable_layout_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.ecompacttable_layout_mobile:end
 */

const HeaderActions = () => {
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const hidden = backboneContext.getConfig('system.hiddenComponents', []);
  // timeline

  let isMobileView;
  if (ff.ecompacttable_layout_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }

  if (ff.ecompacttable_layout_mobile) {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ flex: 1, flexShrink: 0 }}>{!hidden.includes('search') && <ButtonSearchArea />}</div>
        <div
          style={{
            alignItems: 'center',
            justifyContent: 'space-between',
            display: 'flex',
            marginLeft: isMobileView ? '10px' : '0',
          }}
        >
          {!hidden.includes('timeline') ? (
            <span style={isMobileView ? { margin: '0 8px' } : { margin: '0 4px' }}>
              <ButtonSelectDate />
            </span>
          ) : null}
          {!hidden.includes('settingMenu') && <ButtonOption isTableCompact />}
        </div>
      </div>
    );
  }
  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
      <div style={{ flex: 1, flexShrink: 0 }}>{!hidden.includes('search') && <ButtonSearchArea />}</div>
      <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
        {!hidden.includes('timeline') ? (
          <span style={{ margin: '0 4px' }}>
            <ButtonSelectDate />
          </span>
        ) : null}
        {!hidden.includes('settingMenu') && <ButtonOption isTableCompact />}
      </div>
    </div>
  );
};
export default HeaderActions;
