import * as React from 'react';

import { makeStyles, Popover } from '@material-ui/core';

import ChangePermission from './change-permission';
import SimpleForm from '../../simple-form';
import { cloneDeep } from 'lodash';
import { usePassport } from '../hooks/use-passport';

const useStyles = makeStyles(() => ({
  popover: {},
}));

export enum TabListType {
  CHANGE_PERMISSION = 'CHANGE_PERMISSION',
  CHANGE_NAME = 'CHANGE_NAME',
}

interface IProps {
  anchorEl?: any;
  setAnchorEl?: any;
  handleClose?: any;
  children?: any;
  type?: TabListType;
  parentNodeIds?: string[];
  nodeId?: string[];
  updateSidebar?: any;
  updateFeature?: any;
  setAppMenuList?: any;
  appMenuListRef?: any;
  label?: string;
  parentIds?: string[];
  menuType?: 'product' | 'feature';
}

const TabListPopover = ({
  anchorEl,
  handleClose,
  type,
  parentNodeIds,
  nodeId,
  updateSidebar,
  setAppMenuList,
  appMenuListRef,
  label,
  parentIds,
  menuType,
}: IProps) => {
  const classes = useStyles();

  let updateFeature, updateProduct;
  if (ff.next_left_menu) {
    const hookResponse = usePassport();
    updateFeature = hookResponse.updateFeature;
    updateProduct = hookResponse.updateProduct;
  }

  const getChildren = () => {
    switch (type) {
      case TabListType.CHANGE_PERMISSION:
        return (
          <ChangePermission
            handleClose={handleClose}
            parentNodeIds={ff.next_left_menu ? parentIds || parentNodeIds : parentNodeIds}
            nodeId={nodeId}
            updateSidebar={updateSidebar}
            appMenuListRef={appMenuListRef}
          />
        );
      case TabListType.CHANGE_NAME:
        return (
          <SimpleForm
            title={'Feature name'}
            defaultValue={label}
            handleClose={handleClose}
            handleSubmit={async (value) => {
              let res;
              if (ff.next_left_menu) {
                if (menuType === 'feature') {
                  res = await updateFeature(nodeId, { name: value });
                } else {
                  res = await updateProduct(nodeId, { name: value });
                }
              } else {
                res = {
                  success: true,
                  code: 200,
                };
              }
              if (res.success || res.code == 200) {
                const cloneAppMenuList = cloneDeep(appMenuListRef.current);
                const nodeIds = parentIds || parentNodeIds;
                const parentNode =
                  Array.isArray(nodeIds) && nodeIds.length
                    ? nodeIds.reduce((a, b, i) => {
                        if (!a) return a;
                        if (i === 0) return a.find((el) => el.nodeId === b);
                        return a.tabs.find((el) => el.nodeId === b);
                      }, cloneAppMenuList)
                    : null;

                const node = parentNode
                  ? parentNode.tabs.find((el) => el.nodeId === nodeId)
                  : cloneAppMenuList.find((el) => el.nodeId === nodeId);
                if (node) {
                  node.label = value;
                  updateSidebar(cloneAppMenuList);
                }
                return Promise.resolve(true);
              } else {
                return Promise.reject(res.message);
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  if (!type) return null;

  return (
    <Popover
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      onClose={handleClose}
      className={classes.popover}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {getChildren()}
    </Popover>
  );
};

export default TabListPopover;
