import { EIP_CONSTANT } from '@eip/next/lib/main';
import { ETable } from './etable';
import { ETable as ETableNext } from './etable-next';
import {
  ETABLE_INSIGHT_TEMPLATE,
  ETABLE_INSIGHT_TEMPLATE_COUNTRY,
  ETABLE_INSIGHT_TEMPLATE_MARKETPLACE,
  ETABLE_OPERATION_INDEX_TEMPLATE,
  ETABLE_QUALITY_INDEX_TEMPLATE,
  NEW_AD_OBJECT_OPTIMIZATION_CONFIG,
  NEW_AD_OPTIMIZATION_CONFIG,
  NEW_CAMPAIGN_SETTING_CONFIG,
  TREEMAP_SETTING_CONFIG,
  ETABLE_ONE_INDEX_TEMPLATE,
} from './table-config';

export default {
  blockType: 'richTable',
  label: 'eTable',
  // blockComponent: process.env.NODE_ENV === 'production' ? ETable : ETableNext,
  // blockComponent: ETable,
  blockComponent: ETableNext,
  systemConfig: {},
  layout: ff.generate_etable_config
    ? {
        w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
        h: 20,
      }
    : {
        w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
        h: 12,
      },
  packagedConfigs: {
    NEW_AD_OBJECT_OPTIMIZATION_CONFIG,
    NEW_AD_OPTIMIZATION_CONFIG,
    NEW_CAMPAIGN_SETTING_CONFIG,
    ETABLE_INSIGHT_TEMPLATE,
    ETABLE_INSIGHT_TEMPLATE_COUNTRY,
    ETABLE_INSIGHT_TEMPLATE_MARKETPLACE,
    ETABLE_OPERATION_INDEX_TEMPLATE,
    ETABLE_QUALITY_INDEX_TEMPLATE,
    TREEMAP_SETTING_CONFIG,
    ETABLE_ONE_INDEX_TEMPLATE,
  },
};
