import * as React from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Label, TickIcon } from './subscription-components';
import { EnumSelectedColumn, generationClass } from './subscription-plan-table';

type ICellCompare = string | number | '#' | null;
export const createDataComparePlanTable = (
  name: {
    label: string;
    icon?: IconType;
  },
  starter: ICellCompare,
  team: ICellCompare,
  enterprise: ICellCompare,
) => {
  return { name, starter, team, enterprise };
};
export interface ICompareRow {
  name: {
    label: string;
    icon?: IconType;
  };
  starter: ICellCompare;
  team: ICellCompare;
  enterprise: ICellCompare;
}

const RowCompare = ({ data, selectedColumn }: { data: ICompareRow; selectedColumn: EnumSelectedColumn }) => {
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <Box display={'flex'} alignItems={'center'}>
          <div>{data.name.label}</div>
          {(data.name.icon ?? '') && (
            <Box display={'flex'} ml={'8px'} height={'12px'} width={'11px'} alignSelf={'center'}>
              <Icon type={data.name.icon} style={{ width: '100%', height: '100%' }} />
            </Box>
          )}
        </Box>
      </TableCell>
      <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Starter)}`}>
        <span>{data.starter !== '#' ? data.starter : <TickIcon />}</span>
      </TableCell>
      <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Team)}`}>
        <span>{data.team !== '#' ? data.team : <TickIcon />}</span>
      </TableCell>
      <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Enterprise)}`}>
        <span>{data.enterprise !== '#' ? data.enterprise : <TickIcon />}</span>
      </TableCell>
    </TableRow>
  );
};

export type ICompareItem = {
  label: string;
  rows: Array<ICompareRow>;
};

type Props = {
  data: ICompareItem;
  selectedColumn: EnumSelectedColumn;
};
const CompareItem = ({ data, selectedColumn }: Props) => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell component={'th'}>
          <Label type={'subHeader'} label={data.label} />
        </TableCell>
        <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Starter)}`} />
        <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Team)}`} />
        <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Enterprise)}`} />
      </TableRow>
      {data.rows.map((row, index) => (
        <RowCompare selectedColumn={selectedColumn} key={index.toString()} data={row} />
      ))}
    </React.Fragment>
  );
};

export default CompareItem;
