import * as React from 'react';
import clsx from 'clsx';

import { Box, Divider, List, ListItem, makeStyles, Typography } from '@material-ui/core';

import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { aim } from '@eip/next/lib/main';
import { useParams, useHistory } from 'react-router';
import { useAuth } from '../../hooks/use-auth';

const useStyles = makeStyles(() => ({
  container: {
    padding: '12px',
  },
  headerContainer: {
    cursor: 'pointer',
    padding: '4px 12px',
    borderRadius: '4px',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    lineHeight: '10px',
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
  menuDropdown: {
    paddingTop: '8px',
  },
  styleButton: {
    backgroundColor: 'transparent !important',
    minWidth: '20px',
    height: '20px',
    color: '#253746 !important',
  },
  textHead: {
    '&.title': {
      color: '#253746 !important',
    },
    '&.subtitle': {
      color: '#8C98A4 !important',
      fontSize: '10px',
    },
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  serviceItem: {
    cursor: 'pointer',
    borderRadius: '4px',
    '&.active': {
      color: '#0369c7',
      background: '#EBF6FF',
    },
    '&:not(.active):hover': {
      background: '#e4e7e9',
    },
  },
}));

const services = [
  {
    label: 'X Ray Monitor',
    value: 'x-ray-monitor',
  },
  {
    label: 'Hyper integration',
    value: 'hyper-integration',
  },
];

const RayHeader = () => {
  const classes = useStyles();
  const [openMenu, setOpenMenu] = React.useState(false);

  const { userEmail } = aim.getUserSettings()?.profile || {};
  const { logout } = useAuth();

  const { serviceId } = useParams();
  const history = useHistory();

  const foundService = services.find((service) => service.value === serviceId);

  return (
    <div className={classes.container}>
      <Box
        className={classes.headerContainer}
        onClick={() => {
          if (openMenu) return;
          setOpenMenu(true);
        }}
      >
        <Box display={'flex'} alignItems={'center'}>
          <Typography variant="h4" className={`${classes.textHead} title`}>
            {foundService?.label || 'Unknown'}
          </Typography>
          <Dropdown
            classNamePaper={classes.menuDropdown}
            className={classes.styleButton}
            disabledToggleStyle
            alignMenu="right"
            disabled
            icon={'ic/charm:chevrons-up-down/#253746'}
            label=""
            sizeIcon={'12px'}
            defaultOpen={openMenu}
            handleOnClosed={() => setOpenMenu(false)}
          >
            <List>
              {services.map((service) => {
                return (
                  <ListItem
                    key={service.value}
                    className={clsx(classes.serviceItem, service.value === serviceId ? 'active' : '')}
                    onClick={() => {
                      if (service.value !== serviceId) {
                        history.push(`/ray/${service.value}`);
                        setOpenMenu(false);
                      }
                    }}
                  >
                    {service.label}
                  </ListItem>
                );
              })}
              <Divider />
              <ListItem
                className={clsx(classes.serviceItem)}
                onClick={() => {
                  logout();
                }}
              >
                Logout
              </ListItem>
            </List>
          </Dropdown>
        </Box>
        <Typography variant="span" className={`${classes.textHead} subtitle`}>
          {userEmail}
        </Typography>
      </Box>
    </div>
  );
};

export default RayHeader;
