import { CompactFactoryProvider } from '@ep/insight-ui/elements/table/compact-factory2';
import SelectBoxCompact from '@ep/insight-ui/elements/table/table-compact/select-box-compact/select-box-compact2';
import { ISelectBoxCompact } from '@ep/insight-ui/elements/table/table-compact/select-box-compact/select-box-compact';
import { IConfigTable } from '@ep/insight-ui/elements/table/table-container';
import * as React from 'react';
import FormControlTemplate from './form-control-template';
interface ICompactSelection extends ISelectBoxCompact {
  label: string;
  classNameContainer?: string;
  disabled?: boolean;
}
const CompactSelection = ({ label = '', classNameContainer = '', ...rest }: ICompactSelection) => {
  return (
    <FormControlTemplate label={label} className={`${classNameContainer}`}>
      <CompactFactoryProvider>
        <SelectBoxCompact {...rest} />
      </CompactFactoryProvider>
    </FormControlTemplate>
  );
};
export default CompactSelection;
