import { Checkbox, CheckboxProps, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { get } from 'lodash';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

/**
 * ff.tooltips_toko_shop_ads:start
 */
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
/**
 * ff.tooltips_toko_shop_ads:end
 */

const useStyles = makeStyles({
  root: {
    marginLeft: '6px',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: '12px',
    height: '12px',
    boxShadow: 'inset 0 0 0 2px #C2C7CB, inset 0 -1px 0 #C2C7CB',

    background: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '4px auto rgba(19,124,189,.6)',
      outlineOffset: 4,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
      boxShadow: 'inset 0 0 0 2px #338ED8, inset 0 -1px 0 #338ED8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      display: 'block',
      width: '12px',
      height: '12px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  indeterminate: {
    position: 'relative',
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'block',
      width: '8px',
      height: '2px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 2'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M7 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 " +
        "2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0Z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

interface ICustomCheckboxProps extends CheckboxProps {
  state: 'default' | 'checked' | 'indeterminate';
}

function StyledCheckbox({ state, ...props }: ICustomCheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={clsx(classes.icon, state === 'indeterminate' && classes.indeterminate)} />}
      {...props}
    />
  );
}
export interface IValueSelectionFormat {
  checked: boolean;
  disabled?: boolean;
}
type CheckboxState = 'default' | 'checked' | 'indeterminate';
const SelectionFormat = (props: IPropsFormat<IValueSelectionFormat>) => {
  // const { disabled = false, checked = false } = value;
  const cellAction = get(props, 'cellAction', []);
  const node = get(props, 'node', null);
  const value = get(props, 'value', null);
  const disabled = get(value, 'disabled', false);
  const checkboxState = get(props, 'checkboxState', null);
  const gridApi = get(props, 'gridApi', null);
  const setCheckboxState = get(props, 'setCheckboxState', () => null);
  const [selecting, setSelecting] = React.useState(false);

  const checked = React.useMemo(() => checkboxState === 'checked', [checkboxState]);

  const backbone = React.useContext(TableBackboneContext);

  const click = (e) => {
    setSelecting(true);
    setCheckboxState(e.target.checked ? 'checked' : 'default');
    if (gridApi) {
      gridApi.forEachNode((node) => {
        // console.log(1234567, { node });
        if (backbone.addon('checkValidRow', () => true)(node)) {
          node.setSelected(e.target.checked);
        }
      });
    }
    setTimeout(() => {
      setSelecting(false);
    }, 500);
  };

  return (
    <Grid
      container
      justifyContent="center"
      alignItems={'center'}
      style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: '1px' }}
    >
      <StyledCheckbox disabled={disabled || selecting} checked={checked} onChange={click} state={checkboxState} />
    </Grid>
  );
};
export default SelectionFormat;
