import { ViewportChangedEvent } from '@ag-grid-community/core';
import { get } from 'lodash';
import * as React from 'react';

export function useAutoExpandGroup(backbone) {
  const [isFirstExpand, setIsFirstExpand] = React.useState(true);
  const handleViewportChange = React.useCallback(() => {
    const gridApi = backbone.getGridApi();
    if (isFirstExpand && gridApi) {
      let hasGrouped = false;
      const defaultExpandedRows = get(backbone, ['config', 'system', 'defaultExpandedRows'], 0);
      const collapsed = [];
      gridApi.forEachNode((node) => {
        if (node.group) {
          hasGrouped = true;
        }
        if (node.group && node.level == 0 && collapsed.length < Number(defaultExpandedRows)) {
          collapsed.push(node);
        }
      });
      collapsed.forEach((node) => {
        if (!node.expanded) {
          gridApi.setRowNodeExpanded(node, true);
        }
      });

      if (hasGrouped) {
        setIsFirstExpand(false);
      }
    }
  }, [isFirstExpand]);

  return {
    setIsFirstExpand,
    handleViewportChange,
  };
}
