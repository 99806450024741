import * as React from 'react';
import { SystemPage, Block } from '../system-page';
import { EDashboard, ETable } from '@ep/insight-ui/system/block';
import { EO2_SYSTEM_PAGE_ID } from '../constant';

export default function MassOperationCampaignManagementSP() {
  return (
    <SystemPage
      title={'Campaign management'}
      blockId={EO2_SYSTEM_PAGE_ID.massOperation.campaignManagement}
      version={2}
      token={'tbd'}
      pageCategory={'hidden'}
    >
      <Block
        blockType={EDashboard.blockType}
        blockId={'1771cd38-4e28-4a94-8d53-73147827f25e'}
        config={EDashboard.packagedConfigs.MASS_OPERATION_DASHBOARD_CONFIG}
        layout={{ w: 36, h: 8, x: 0, y: 0 }}
      />
      <Block
        blockType={ETable.blockType}
        blockId={'64fc51d2-ff9e-40df-8c90-ff7a537d6234'}
        config={ETable.packagedConfigs.MASS_OPERATION_MANAGEMENT}
        layout={{ w: 36, h: 8, x: 0, y: 8 }}
      />
    </SystemPage>
  );
}
