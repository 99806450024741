import React from 'react';
import { DashboardBreakpoints, NodeData, NodeId, NodeLayout } from './type';

type DashboardScreenLayout = NodeLayout;

type DashboardResponsiveLayoutType = {
  [key: DashboardBreakpoints]: DashboardScreenLayout[];
};

type DashboardContextType = {
  onAddNewNode: (x: number, y: number, w: number, h: number) => NodeData;
  onRemoveNode: (id: string) => void;
  onLayoutChange: (newLayout: DashboardScreenLayout[], previousLayout: DashboardResponsiveLayoutType) => void;
  onBreakpointChange: (bp: DashboardBreakpoints, cols: number) => void;
  onNodeRequestUpdate: (nodeId: string, payload: NodeData) => void;
  onNodeSubmitUpdate: (nodeId: string, payload: any) => any;
  onNodeRequestConnection?: (nodeData: NodeData) => any;
  onUpdateNodes: (nodes: NodeData[]) => void;
  onGridChange: (layouts: DashboardResponsiveLayoutType, layoutColumns: Dashboard['layoutColumns']) => void;
  onDuplicateNode: (nodeId: NodeId) => void;
  onToggleNodeDraggable: (isEnable: boolean) => void;
  mode: 'view' | 'edit';
  blockWidthUnit: number;
};

export type FilterType = {
  channels: string[];
  tools: string[];
  countries: string[];
  shops: string[] | number[];
};

export type FilterContextType = {
  filter: FilterType;
  onUpdateFilter?: (filter: FilterType) => void;
};

export const EIPContext = React.createContext<DashboardContextType>({
  onUpdateNodes: (nodes) => {
    return nodes;
  },
  onAddNewNode: (x, y) => {
    console.info({ x, y });
    return null;
  },
  onRemoveNode: (id) => {
    console.info({ id });
  },
  onLayoutChange: (newLayout, prevLayout) => {
    console.info({ newLayout, prevLayout });
  },
  onBreakpointChange: (bp, cols) => console.info({ bp, cols }),
  onNodeRequestUpdate: (nodeId, payload) => {
    console.info({ nodeId, payload });
  },
  onNodeSubmitUpdate: (nodeId, payload) => {
    console.info({ nodeId, payload });
  },
  onNodeRequestConnection: (nodeData) => {
    console.info('node Request connection', { nodeData });
  },
  onGridChange: (breakpoint, columns) => {},
  onDuplicateNode: (nodeId) => {
    console.info('duplicate node', nodeId);
  },
  onToggleNodeDraggable: (isEnable: boolean) => {
    console.info('Toggle node draggable', isEnable);
  },
  mode: 'view',
  blockWidthUnit: 0,
});

export const DashboardItemContext = React.createContext({
  enableRootDraggable: (status: boolean) => {},
});

export const FilterContext = React.createContext<FilterContextType>({
  filter: {
    channels: [],
    tools: [],
    countries: [],
    shops: [],
  },
  onUpdateFilter: (filter: FilterType) => {
    console.info('Update global filter', filter);
  },
});
