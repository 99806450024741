import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { get } from 'lodash';

import { DropdownCell } from '../dropdown-cell';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { checkEpsiloTableEndpoint, EpsiloTableObject } from '@ep/insight-ui/system/backbone/data-source/common';

/**
 * ff.mobile_interaction_zone:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.mobile_interaction_zone:end
 */

import { useWrapperFormat } from './hooks/use-wrapper-format';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 17px',
    width: '100%',
    height: '100%',
    '&.hover': {
      backgroundColor: '#FAFCFF',
      '& .dropdown_menu': {
        background: '#FAFCFF',
      },
    },
    '&.eip1-selectable': {
      userSelect: 'text',
      MozUserSelect: 'text',
      WebkitUserSelect: 'text',
      msUserSelect: 'text',
    },
  },
  menu: {
    position: 'absolute',
    right: 8,
    ...(ff.mobile_interaction_zone
      ? {
          '.eres--small &': {
            display: 'none',
          },
        }
      : {}),
  },
  tagItem: {
    background: ' #EDF2F9',
    borderRadius: '4px',
    '& .eip1-MuiChip-deleteIcon': {
      width: '10px',
      height: '10px',
      color: '#3F4F5C',
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    textAlign: 'right',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.type': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#8C98A4',
    },
    '&.value': {
      fontSize: '14px',
      color: '#253746',
    },
  },
}));
export interface ICellAction {
  title?: string;
  name?: string;
  icon?: string;
  iconSize?: string;
  component?: React.ReactNode | unknown;
  condition?: { field: string; operator: string; values: any[] }[];
  onSubmit?: (info) => void;
  onClickItem?: () => void;
  checkDisable?: (row) => boolean;
}
export interface IPropsFormat<V> {
  value: V;
  cellAction: Array<ICellAction>;
  node: any;
}
type Props = {
  children: JSX.Element;
  cellAction: Array<ICellAction>;
  value: any;
  node: any;
  disabledMenu?: boolean;
  disabledPadding?: boolean;
  isGrouped?: boolean;
  gridApi?: any;
  typeTable?: string;
  actionSubItem?: Array<ICellAction>;
};

const WrapperFormat = ({
  children,
  cellAction,
  value,
  node,
  disabledMenu = false,
  disabledPadding = false,
  isGrouped = false,
  gridApi,
  typeTable,
  actionSubItem,
}: Props) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = React.useState(false);
  const [isPauseHover, setIsPauseHover] = React.useState(false);

  let backbone, additionalActions;
  if (ff.reload_btn_quality) {
    backbone = React.useContext(TableBackboneContext);
    additionalActions = React.useMemo(() => {
      const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
      const actions = [];
      if (backbone.getGridApi) {
        const gridApi = backbone.getGridApi();

        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
          if (isGrouped && node.group) {
            actions.push({
              name: 'Reload',
              icon: 'reload',
              onSubmit() {
                if (gridApi) {
                  gridApi.refreshServerSideStore({ route: [node.key] });
                }
              },
            });
          }
        }
      }

      return actions;
    }, [backbone, node]);
  }

  let isDropdownMobileShow, setIsDropdownMobileShow, isMobileView, handleClickingCellMobile;
  if (ff.mobile_interaction_zone) {
    [isDropdownMobileShow, setIsDropdownMobileShow] = React.useState(false);
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';

    handleClickingCellMobile = () => {
      if (isMobileView) {
        setIsDropdownMobileShow(true);
      }
    };
  }

  const handleOnMouseOver = () => {
    if (!disabledMenu && !isPauseHover) {
      setIsHovering(true);
    }
  };
  const handleOnMouseLeave = () => {
    if (!disabledMenu && !isPauseHover) {
      setIsHovering(false);
    }
  };

  const myRef = React.useRef(null);

  const { enhanceCellAction } = useWrapperFormat({ cellAction, myRef, value, node, backbone });

  const filteredActions = React.useMemo(() => {
    if (enhanceCellAction) {
      return (ff.reload_btn_quality ? enhanceCellAction.concat(additionalActions) : enhanceCellAction)
        .filter((a) => {
          if (a.condition) {
            return a.condition.every((c) => {
              switch (c.operator) {
                case 'CONTAIN':
                  const fields = get(c, ['fields'], []);
                  const fieldsOperator = get(c, ['fieldsOperator'], 'AND');
                  let fieldsCondition = null;
                  if (fieldsOperator === 'OR') {
                    fieldsCondition = fields.some((field) => c.values.includes(node.data[field]));
                  } else {
                    fieldsCondition = fields.length
                      ? fields.every((field) => c.values.includes(node.data[field]))
                      : false;
                  }
                  return c.values.includes(node.data[c.field]) || fieldsCondition;
                case 'NOT_CONTAIN':
                  return !c.values.includes(node.data[c.field]);
                default:
                  return true;
              }
            });
          }
          return true;
        })
        .map((a) => {
          if (a.checkDisable) {
            return {
              ...a,
              disable: a.checkDisable(node.data),
            };
          }
          return a;
        });
    }
    return ff.reload_btn_quality ? [].concat(additionalActions) : [];
  }, [enhanceCellAction, node, ...(ff.reload_btn_quality ? [backbone] : [])]);

  const onOpened = () => setIsPauseHover(true);
  const onClosed = () => setIsPauseHover(false);
  return (
    <div
      className={`${classes.container} cellTable eip1-selectable ${isHovering ? 'hover' : ''}`}
      role={'cell'}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      style={{
        padding: isGrouped
          ? typeTable === 'compact'
            ? '2.5px 8px 2.5px 24px'
            : '0.5px 8px 0.5px 24px'
          : typeTable === 'compact'
          ? '2.5px 8px'
          : '0.5px 8px',
      }}
      ref={myRef}
      {...(ff.mobile_interaction_zone ? { onClick: handleClickingCellMobile } : {})}
    >
      {children}
      {!disabledMenu && isHovering && (
        <MonitorContainer component={'div'} mId="cell-edot" mLabel={'Cell edot'} className={clsx(classes.menu, 'dropdown_menu')} role={'menubar'}>
          {(ff.mobile_interaction_zone ? isHovering || isMobileView : isHovering) && filteredActions.length > 0 && (
            <DropdownCell
              cellAction={filteredActions}
              props={ff.ui_ux_update_tag_item ? { value, node, gridApi, actionSubItem } : { value, node, gridApi }}
              onOpened={onOpened}
              onClosed={onClosed}
              classField={myRef.current?.parentNode?.parentNode?.getAttribute('col-id')}
              {...(ff.mobile_interaction_zone ? { isDropdownMobileShow, setIsDropdownMobileShow } : {})}
            />
          )}
        </MonitorContainer>
      )}
    </div>
  );
};
export default WrapperFormat;
