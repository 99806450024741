import * as React from 'react';
import { get } from 'lodash';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Box, Popover, Tooltip, makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import {
  CELL_FORMAT_OBJECT_KEYS,
  CELL_FORMAT_STATIC_KEYS,
} from '@ep/insight-ui/system/block/etable/cell-format-object-keys';
import { CELL_FORMAT_OPTIONS } from '@ep/insight-ui/system/block/etable/cell-format-options';
import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';
import SelectComponent from '@ep/insight-ui/elements/select-component';

import { simpleTooltip } from './helper/tooltip';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  selectLabel: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  close: {
    position: 'absolute',
    top: '50%',
    right: '15px',
    transform: 'translateY(-50%)',
  },
  removeContainer: {
    '.ag-react-container > &': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  tooltipConatiner: {
    display: 'flex',
    minWidth: '200px',
    minHeight: '40px',
    background: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listItem: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
    borderRadius: '4px',
  },
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& textarea': {
      minWidth: '300px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
    marginBottom: '8px',
  },
  inputIcon: {
    marginLeft: '12px',
  },
  icon: {
    '& img': {
      width: '24px',
    },
    '& svg': {
      width: '20px',
    },
  },
  customTooltip: {
    marginTop: 0,
    background: '#253746',
  },
});

const formatLabel = (value) => {
  const newValue = value.replace(/_/g, ' ');
  const label = newValue[0].toUpperCase() + newValue.substring(1);
  return label;
};

const SelectWindowFormat = (props: ICellRendererParams) => {
  const classes = useStyles();
  const options = get(props, 'options', []).map((opt) => {
    if (props.field === 'column' && typeof opt === 'string') {
      return {
        label: formatLabel(opt),
        value: opt,
      };
    }
    return typeof opt === 'string' ? { label: CELL_FORMAT_OPTIONS[opt]?.label || opt, value: opt } : opt;
  });

  const inputRef = React.useRef(null);

  const [selItem, setSelItem] = React.useState(options.find((opt) => opt.value === props.value));
  const [isHovering, setIsHovering] = React.useState(false);

  const isStaticFilter = get(props, ['data', 'staticValue', 'staticFilter'], 0);
  const fieldsAllowedRemove = ['filterField', 'sortField'];

  React.useEffect(() => {
    if (props.column.colId === 'column') {
      setSelItem(options.find((opt) => opt.value === props.data.columnKeys));
    } else if (props.isIcon) {
      setSelItem({
        label: props.value,
        value: props.value,
      });
    } else {
      setSelItem(
        options.find((opt) => {
          return opt.value === props.value;
        }),
      );
    }
  }, [props.value]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  React.useEffect(() => {
    setTimeout(() => {
      if (inputRef.current) {
        inputRef.current.querySelector('input')?.focus();
        inputRef.current.querySelector('textarea')?.focus();
      }
    }, 0);
  }, [anchorEl, inputRef.current]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSelectItem = (option) => {
    if (props.field === 'cellFormat') {
      if (
        props.mapping[props.node.data.columnKeys] &&
        props.mapping[props.node.data.columnKeys].cellFormat === option
      ) {
        props.node.setDataValue('valueGetter', props.mapping[props.node.data.columnKeys].valueGetter);

        if (option === 'traction') {
          if (props.mapping[props.node.data.columnKeys].staticValue) {
            props.node.setDataValue('staticValue', props.mapping[props.node.data.columnKeys].staticValue);
          } else {
            props.node.setDataValue('staticValue', CELL_FORMAT_STATIC_KEYS[option]);
          }
        }
      } else {
        if (option === 'traction') {
          props.node.setDataValue('staticValue', CELL_FORMAT_STATIC_KEYS[option]);
        }
        props.node.setDataValue('valueGetter', CELL_FORMAT_OBJECT_KEYS[option]);
      }

      if (CELL_FORMAT_OPTIONS[option]) {
        const { filterGetter } = CELL_FORMAT_OPTIONS[option].defaultConfig;
        props.node.setData({
          ...props.node.data,
          ...CELL_FORMAT_OPTIONS[option].defaultConfig,
          filterGetter: filterGetter ? filterGetter : null,
          eTableEditor: CELL_FORMAT_OPTIONS[option].eTableEditor,
        });
      } else {
        props.node.setData({
          ...props.node.data,
          menu: [],
          menuItem: [],
          filterGetter: null,
        });
      }
    }
    if (props.data.key === 'accumulativeColumn') {
      props.api.forEachNode((node) => {
        if (node.data.key === 'accumulativeField') {
          props.api.redrawRows({
            rowNodes: [node],
          });
        }
      });
    }
    if (props.field === 'defaultGroupBy') {
      // if (option == 1) {
      //   props.api.forEachNode((node) => {
      //     if (node.id !== props.node.id) {
      //       node.setDataValue(props.field, null);
      //     }
      //   });
      // }
      props.node.setDataValue(props.field, option === 1 ? option : null);
    } else {
      props.node.setDataValue(props.field, option);
    }
    setAnchorEl(null);
  };

  return (
    <Box
      className={classes.container}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ConditionalWrap
        condition={selItem && props.tooltipField && selItem[props.tooltipField]}
        wrap={(children) => {
          const CellTooltip = simpleTooltip(selItem[props.tooltipField]);

          return (
            <Tooltip
              title={
                <Box>
                  <CellTooltip />
                </Box>
              }
              classes={{
                tooltip: `${classes.customTooltip}`,
              }}
              placement="bottom-start"
            >
              {children}
            </Tooltip>
          );
        }}
      >
        <Box className={classes.selectLabel} onClick={handleClick}>
          {props.isIcon && selItem && selItem.value ? (
            <Box className={classes.icon}>
              <Icon type={selItem.value} />
            </Box>
          ) : (
            <span>{(selItem && selItem.label) || (typeof props.value === 'string' ? props.value : '')}</span>
          )}
          {isHovering &&
            !!props.value &&
            !isStaticFilter &&
            (!!fieldsAllowedRemove.includes(props.field) || props.allowedRemove) && (
              <span
                className={classes.close}
                onClick={() => {
                  props.node.setDataValue(props.field, '');
                  setIsHovering(false);
                }}
              >
                <Icon type={'closeCircle'} />
              </span>
            )}
          <Icon type={'arrowdown'} />
        </Box>
      </ConditionalWrap>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        <SelectComponent
          onSelectItem={onSelectItem}
          options={options}
          defaultInputValue={selItem?.label || props.value || ''}
          allowCustomOption={props.allowCustomOption}
        />
      </Popover>
    </Box>
  );
};

export default SelectWindowFormat;
