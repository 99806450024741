import React, { useCallback, useEffect, useState } from 'react';
import { basicSetup } from 'codemirror';
import { EditorState, Extension } from '@codemirror/state';
import { EditorView } from '@codemirror/view';
import { javascript } from '@codemirror/lang-javascript';
import { useAtomValue, useSetAtom } from 'jotai';
import { formulaPopup } from './atom/editor-formula';
import { Box, Button, makeStyles, Popover } from '@material-ui/core';
import { autocompletion } from '@codemirror/autocomplete';
import { get, sortBy, uniq } from 'lodash';
import { isFormulaField, eFDefault } from '@ep/insight-ui/sw/util/excel-formula';
import Wrapper from '@ep/insight-ui/elements/etable2/wrapper';

export default function useCodeMirror() {
  const [element, setElement] = useState<HTMLElement>();
  const { backboneConfig, availableVars } = useAtomValue(formulaPopup);
  const editorRef = React.useRef<any>({});
  const predefineFunctions = React.useMemo(() => {
    return Object.keys(eFDefault).map((i) => ({ label: i, type: 'function' }));
  }, []);

  const ref = useCallback((node: HTMLElement | null) => {
    if (!node) return;

    setElement(node);
  }, []);

  useEffect(() => {
    if (!element) return;

    const content = element.dataset['content'];
    const contextCompletion = sortBy(
      getApiMappingOptions(backboneConfig)
        .map((k: string) => {
          return {
            label: k,
            type: 'keyword',
          };
        })
        .concat(
          availableVars
            .filter((i) => i)
            .map((i) => {
              return {
                label: i,
                type: 'variable',
              };
            }),
        ),
      (a) => a.label,
    );
    const completions = predefineFunctions.concat(contextCompletion);

    function myCompletions(context) {
      const before = context.matchBefore(/\w+/);
      // If completion wasn't explicitly started and there
      // is no word before the cursor, don't open completions.
      if (!context.explicit && !before) return null;
      return {
        from: before ? before.from : context.pos,
        options: completions,
        validFor: /^\w*$/,
      };
    }

    const state = EditorState.create({
      doc: content,
      extensions: [basicSetup, javascript(), autocompletion({ override: [myCompletions] }), EditorView.lineWrapping],
    });
    const view = new EditorView({
      state,
      parent: element,
    });

    editorRef.current.getContent = () => {
      return view.state.doc.toString();
    };

    return () => view?.destroy();
  }, [element]);

  return { ref, cmRef: editorRef };
}

const getApiMappingOptions = (etableConfigContext) => {
  return uniq(
    Object.values<any>(get(etableConfigContext, 'mapping', {})).reduce((a, b) => {
      return [...a, ...(Object.values(b.valueGetter).filter((el) => !!el && !isFormulaField(el)) || [])];
    }, []),
  );
};

const useStyles = makeStyles({
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
});
export function FormulaEditor() {
  const { isDisplay, ref, content, submit } = useAtomValue(formulaPopup);
  const updateEditor = useSetAtom(formulaPopup);
  const { ref: editorRef, cmRef } = useCodeMirror();
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    updateEditor((c) => ({ ...c, isDisplay: false }));
  }, []);

  const handleSubmit = React.useCallback(() => {
    const content = cmRef.current.getContent();
    submit(content);
    updateEditor((c) => ({ ...c, content, isDisplay: false }));
  }, [submit]);

  return (
    <Popover
      anchorEl={ref}
      open={isDisplay}
      onClose={() => {
        updateEditor((c) => {
          return {
            ...c,
            isDisplay: false,
          };
        });
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      getContentAnchorEl={null}
    >
      <Wrapper>
        <div
          className="formula-editor"
          ref={editorRef}
          style={{ width: '40vw', minHeight: '20em' }}
          data-content={content}
        ></div>

        <Box className={classes.actionGroup}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </Wrapper>
    </Popover>
  );
}
