import { FilterType } from '@eip/next/src/components/dashboard/context';
import { NodeData } from '@eip/next/src/components/dashboard/type';
import {
  dataQuery,
  getChartLibFilter,
} from '@eip/next/src/util/data-query';
import { produce } from 'immer';
import moment from 'moment';

export async function requestStackedChartData(
  nodeData: NodeData,
  filters: FilterType,
  requestResolveFn,
) {
  console.log('[Stacked] requestStackedChartData', nodeData);

  const resolveFn = (dataFrame, isChainCompleted = false) => {
    return requestResolveFn({
      dataFrame: dataFrame,
      _isAllCompleted: isChainCompleted,
    });
  };

  const { channels, countries, tools, shops, cohorts, metrics } =
    getChartLibFilter(nodeData, filters);

  const params = {
    metrics: metrics.length > 0 ? metrics : ['gmv'],
    dimensions: [],
    cohort: [],
    filters: [],
  };

  // [Priority] shops > tools > countries > channels
  let mainData = null;
  if (shops.length > 0) {
    params.dimensions.push('shop_eid');
    mainData = { name: 'shop_eid', values: shops };
  }

  if (tools.length > 0) {
    params.dimensions.push('tool');
    if (mainData) {
      params.filters.push('tool', 'in', JSON.stringify(tools));
    } else {
      mainData = { name: 'tool', values: tools };
    }
  }

  if (countries.length > 0) {
    params.dimensions.push('country');
    if (mainData) {
      params.filters.push('country', 'in', JSON.stringify(countries));
    } else {
      mainData = { name: 'country', values: countries };
    }
  }

  if (channels.length > 0) {
    params.dimensions.push('channel');
    if (mainData) {
      params.filters.push([
        'channel',
        'in',
        JSON.stringify(channels),
      ]);
    } else {
      mainData = { name: 'channel', values: channels };
    }
  }

  let dataFrame = [
    {
      status: 'loading',
      data: {
        headers: ['currency', 'amount'],
        rows: [],
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['percent'],
        rows: [],
      },
    },
  ];

  // Amount
  let $chains = dataQuery({
    ...params,
    dateRange: {
      from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
  }).then((data) => {
    dataFrame[0].status = 'success';
    dataFrame[0].data = {
      headers: ['currency', 'amount', '__tool_id'],
      rows: [].concat(data.data.rows).map((r) => ['USD', ...r]),
    };
    resolveFn(dataFrame);
  });

  // Percent
  $chains = $chains.then(() => {
    return dataQuery({
      ...params,
      dateRange: {
        from: moment().subtract(60, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(31, 'day').format('YYYY-MM-DD'),
      },
    }).then((data) => {
      dataFrame = produce(dataFrame, (draft) => {
        const currentVal = dataFrame[0].data.rows[0][1];
        const previousVal = data.data.rows[0][0];

        draft[1].status = 'success';
        draft[1].data = {
          headers: ['percent'],
          rows: [100 * (currentVal / previousVal - 1)],
        };
      });
      resolveFn(dataFrame);
    });
  });

  if (mainData) {
    for (const [i, value] of mainData.values.entries()) {
      const data = await dataQuery({
        ...params,
        dateRange: {
          from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
          to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
        },
        filters: [[mainData.name, '=', value]],
      });
      dataFrame = produce(dataFrame, (draft) => {
        draft.push({
          status: 'success',
          data: {
            headers: [`${mainData.name}(${value})`],
            rows: data.data.rows.map((i) => ({
              date: i[0],
              value: i[1],
            })),
          },
        });
      });
    }
  } else {
    dataFrame = produce(dataFrame, (draft) => {
      draft.push({
        status: 'success',
        data: {
          headers: [''],
          rows: draft[0].data.rows,
        },
      });
    });
  }

  resolveFn(dataFrame, true);

  return dataFrame;
}
