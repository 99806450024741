import * as React from 'react';
import { cloneDeep, get, groupBy, merge, omit, set } from 'lodash';
import moment from 'moment';
import { useSetAtom } from 'jotai';

import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';
import { NodeEditContext, eipRequest } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { editorScript } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-script';

import { enhancedETableConfig2 } from '../../block/etable/migration';
import { getCustomCellActions } from '../../block/etable/etable-next';
import { checkEpsiloTableEndpoint } from '@ep/insight-ui/sw/etable/data/common';
import { enhanceDataRequest2 } from '../../block/etable/addons/enhance-data-request';
import { calculateValueGetter, produceColumns } from '@ep/insight-ui/sw/etable/service';

const EXCLUDE_CONFIGURATION = ['tableParams'];

export function CampaignTableFactory({
  nodeData,
  systemConfig,
  campaignInfo,
  refBackbone,
  campaignInfoRef,
  onMainTableUpdateConfig,
}: any) {
  const toolCode = get(campaignInfo, ['toolCode'], '');

  const nextSystemConfig = React.useMemo(() => {
    let config = get(nodeData, ['customAttributes', 'adsToolConfig'], []).find(({ code }) => code == toolCode);
    if (!config) {
      // Get default config
      config = get(nodeData, ['customAttributes', 'adsToolConfig'], []).find(({ code }) =>
        String(code)
          .split('\n')
          .map((i) => String(i).trim())
          .includes(toolCode),
      );
    }
    if (!config) {
      // Get default config
      config = get(nodeData, ['customAttributes', 'adsToolConfig'], []).find(({ code }) => code == 'default');
    }
    if (!config) return null;
    const configAttributes = cloneDeep(get(config, ['config'], {}));
    const viewDefaultIndex = (configAttributes.views || []).findIndex((view) => view.id === 'default');
    const { dimension, attribute, metric, columnWidth } = Object.entries(configAttributes.mapping || {}).reduce(
      (carry, [key, col]) => {
        const propertyType = col['propertyType'];
        if (carry[propertyType]) {
          carry[propertyType].push(key);
        }
        const initColumnWidth = col.initColumnWidth;
        if (initColumnWidth) {
          let width = undefined;
          if (Number(initColumnWidth)) width = initColumnWidth;
          carry.columnWidth.push({
            width,
            columnField: key,
          });
        }
        return carry;
      },
      { dimension: [], attribute: [], metric: [], columnWidth: [] },
    );
    if (!configAttributes.dimension) {
      configAttributes.dimension = dimension;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'dimension'], dimension);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'dimension'], dimension);
    }
    if (!configAttributes.attribute) {
      configAttributes.attribute = attribute;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'attribute'], attribute);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'attribute'], attribute);
    }
    if (!configAttributes.metric) {
      configAttributes.metric = metric;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'metric'], metric);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'metric'], metric);
    }
    if (!configAttributes.columnWidth) {
      configAttributes.columnWidth = columnWidth;
    }
    return {
      customAttributes: configAttributes,
    };
  }, [nodeData, systemConfig]);

  const nextNodeData = React.useMemo(() => {
    if (!nextSystemConfig) return null;
    const nodeDataAttributes = get(nodeData, ['customAttributes', 'personalization', `${toolCode}`], {});

    let views = get(nodeDataAttributes, ['views'], []);
    if (views.length == 0) {
      views = get(nextSystemConfig, ['customAttributes', 'views'], []);
    }
    const view = get(nodeDataAttributes, ['view', 'id'], null)
      ? get(nodeDataAttributes, ['view'], null)
      : get(views, [0], {});
    const mergeAttributes = {
      ...nextSystemConfig.customAttributes,
      mapping: get(nextSystemConfig.customAttributes, ['mapping'], {}),
      view,
      views,
      actionData: campaignInfoRef.current || {},
    };
    return {
      ...nodeData,
      customAttributes: mergeAttributes,
    };
  }, [nodeData, campaignInfo, nextSystemConfig]);

  if (!nextSystemConfig) return <h2>This Ad tool has not been supported yet, please contact admin to set it up.</h2>;

  return (
    <ETableBlock
      nodeData={nextNodeData}
      systemConfig={nextSystemConfig}
      campaignInfo={campaignInfo}
      refBackbone={refBackbone}
      campaignInfoRef={campaignInfoRef}
      onMainTableUpdateConfig={onMainTableUpdateConfig}
    />
  );
}

function ETableBlock({
  nodeData,
  systemConfig,
  campaignInfo,
  refBackbone,
  campaignInfoRef,
  onMainTableUpdateConfig,
}: {
  nodeData: NodeData;
  systemConfig: NodeData;
  campaignInfo: any;
  refBackbone: any;
  campaignInfoRef: any;
  onMainTableUpdateConfig: any;
}) {
  const rowDataRef = React.useRef([]);
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const { onToastMultiple } = useToast();

  const lastUpdated = React.useRef(moment().valueOf());

  const handleChangeConfig = React.useCallback(
    (config: Record<string, any>) => {
      const finConfig = omit(config, EXCLUDE_CONFIGURATION);
      EXCLUDE_CONFIGURATION.forEach((f) => {
        finConfig[f] = undefined;
      });
      window.requestAnimationFrame(() => {
        onMainTableUpdateConfig({
          [campaignInfo.toolCode]: config,
        });
      });
    },
    [nodeData],
  );

  const customConfig = get(nodeData, 'customAttributes', {});

  const { config, linkedObjects } = enhancedETableConfig2({
    customConfig,
    systemConfig,
    nodeEditContext,
    lastUpdated,
    blockEid: nodeData.blockEid,
    setOpenCodeEditor,
    getCustomCellActions: getCustomCellActions,
    onToastMultiple,
  });

  const endpoint = get(config, 'configuration.endpoint.GET_TABLE_DATA', '');

  // Enhance etable config for campaign detail
  const prevGetRowsParams = config.addons['datasource.getRowsParams'];
  config.addons['datasource.getRowsParams'] = ({ params }, currentConfig, backbone) => {
    const prevParams = prevGetRowsParams ? prevGetRowsParams({ params }, currentConfig, backbone) : params;

    const campaignFilter = {
      dataType: 'string',
      field: 'ads_campaigns.id',
      operator: 'is',
      value: campaignInfo.campaignId,
    };
    if (!prevParams.filter) {
      prevParams.filter = {
        combinator: 'AND',
        filters: [campaignFilter],
      };
    } else {
      prevParams.filter.filters.push(campaignFilter);
    }
    return prevParams;
  };

  const ADS_OBJECTS_ID = 'ads_objects.id';
  const ADS_OBJECTS_NAME = 'ads_objects.name';

  const mergeData = (raw, additionalData, groupByParams, lastSort) => {
    const fieldMap =
      groupByParams?.dimensions && !groupByParams?.drillDowns ? groupByParams?.dimensions?.[0] : ADS_OBJECTS_ID;

    let result;
    if (
      (!lastSort ||
        (!lastSort.field?.startsWith('ads_placements') && !lastSort.field?.startsWith('ads_strategy_targetings'))) &&
      additionalData.length > 0
    ) {
      result = additionalData.reduce((carry, row) => {
        const matchedAdsPlacement = raw.filter((r) => r[fieldMap] == row[fieldMap]);
        if (matchedAdsPlacement.length > 0) {
          carry.push(...matchedAdsPlacement);
        } else {
          carry.push(row);
        }
        return carry;
      }, []);
    } else {
      const groupedAdsObjetAdsPlacement = Object.keys(groupBy(raw, fieldMap));
      result = raw.concat(additionalData.filter((i) => !groupedAdsObjetAdsPlacement.includes(String(i[fieldMap]))));
    }

    return result;
  };

  const mapTotalAdsPlacement = (raw, additionalData) => {
    return raw.map((i) => {
      const foundAdsPlacement = additionalData.find((item) => item[ADS_OBJECTS_ID] == i[ADS_OBJECTS_ID]);
      return {
        ...i,
        'ads_placements.count': foundAdsPlacement ? foundAdsPlacement['ads_placements.id'] : 0,
      };
    });
  };

  const mapAdsObjectIds = (raw, adsObjectIds) => {
    return raw.map((i) => {
      return {
        ...i,
        'ads_campaigns.ads_objects_ids': adsObjectIds ? JSON.parse(adsObjectIds) : [],
      };
    });
  };

  config.addons['datasource.apiRequest.getTableData'] = async (params, originRequest, backbone) => {
    if (checkEpsiloTableEndpoint(endpoint)) {
      let result, adsObjectResult, adsPlacementCountResult, adsObjectListResult;

      const shouldIgnoreAdsPlacement =
        params.attributes.some((i) => i.startsWith('ads_placements') || i.startsWith('ads_strategy_targetings')) ||
        params.dimensions.includes('ads_placements') ||
        params.dimensions.includes('ads_strategy_targetings') ||
        params.metrics?.some((i) => String(i).startsWith('ads_placements'));

      const hasAdsObject =
        params.dimensions.includes('ads_objects') ||
        params.attributes.some((i) => String(i).startsWith('ads_objects')) ||
        params.metrics.some((i) => String(i).startsWith('ads_objects'));

      if (shouldIgnoreAdsPlacement) {
        const rawPayload = {
          ...params,
          '@eTableConfig': { ...backbone.config, tableId: nodeData.blockEid },
          filter: {
            ...params.filter,
            filters: [
              ...(params.filter?.filters || []),
              ...(params.dimensions.includes('ads_placements')
                ? [
                    {
                      dataType: 'string',
                      field: 'ads_placements.deleted_at',
                      operator: 'is_empty',
                      value: '',
                    },
                    {
                      dataType: 'string',
                      field: 'ads_placements.is_shown',
                      operator: 'is',
                      value: '1',
                    },
                  ]
                : []),
              ...(params.dimensions.includes('ads_strategy_targetings')
                ? [
                    {
                      dataType: 'string',
                      field: 'ads_strategy_targetings.deleted_at',
                      operator: 'is_empty',
                      value: '',
                    },
                    {
                      dataType: 'string',
                      field: 'ads_strategy_targetings.is_shown',
                      operator: 'is',
                      value: '1',
                    },
                  ]
                : []),
              {
                dataType: 'string',
                field: 'ads_objects.deleted_at',
                operator: 'is_empty',
                value: '',
              },
              {
                dataType: 'string',
                field: 'ads_objects.is_shown',
                operator: 'is',
                value: '1',
              },
            ],
          },
        };

        const sortWithoutAds3rd = (params.sort || []).filter(
          (i) => !i.field?.startsWith('ads_placements') && !i.field?.startsWith('ads_strategy_targetings'),
        );
        const lastSort = params.sort?.[0];
        const noAdsPlacementParams = {
          ...params,
          attributes: params.attributes.filter(
            (i) => !i.startsWith('ads_placements') && !i.startsWith('ads_strategy_targetings'),
          ),
          dimensions: params.dimensions.filter((i) => i != 'ads_placements' && i != 'ads_strategy_targetings'),
          filter: {
            ...params.filter,
            filters: [
              ...(params.filter?.filters || []),
              {
                dataType: 'string',
                field: 'ads_objects.deleted_at',
                operator: 'is_empty',
                value: '',
              },
              {
                dataType: 'string',
                field: 'ads_objects.is_shown',
                operator: 'is',
                value: '1',
              },
            ],
          },
          metrics: params.metrics.filter(
            (i) => !i.startsWith('ads_placements') && !i.startsWith('ads_strategy_targetings'),
          ),
          sort: sortWithoutAds3rd.length ? sortWithoutAds3rd : null,
        };

        const adsPlacementCountParams = {
          ...rawPayload,
          '@eTableConfig': undefined,
          groupBy: {
            dimensions: ['ads_objects.id'],
            aggregations: [
              {
                field: 'ads_objects.id',
                func: 'MAX',
              },
              {
                field: 'ads_placements.id',
                func: 'COUNT_UNIQUE',
              },
            ],
          },
          filter: {
            ...rawPayload.filter,
            filters: [
              ...(rawPayload.filter?.filters || []),
              {
                dataType: 'string',
                field: 'ads_placements.status',
                operator: 'is',
                value: 'ONGOING',
              },
            ],
          },
          sort: null,
        };

        const adsObjectsListParams = {
          ...rawPayload,
          '@eTableConfig': undefined,
          attributes: ['ads_assets.sc_identify_code'],
          metrics: [],
          dimensions: ['ads_assets'],
          groupBy: {
            dimensions: null,
            aggregations: [
              {
                field: 'ads_assets.sc_identify_code',
                func: 'LIST',
              },
            ],
          },
          groupAll: true,
          groupPeriod: 'all',
          filter: {
            combinator: 'AND',
            filters: [
              {
                dataType: 'string',
                field: 'ads_campaigns.id',
                operator: 'is',
                value: campaignInfo.campaignId,
              },
              {
                dataType: 'string',
                field: 'ads_objects.deleted_at',
                operator: 'is_empty',
                value: '',
              },
              {
                dataType: 'string',
                field: 'ads_objects.is_shown',
                operator: 'is',
                value: '1',
              },
              {
                dataType: 'string',
                field: 'ads_objects.status',
                operator: 'in',
                value: 'DRAFT,ONGOING,SUSPENDED,PAUSED',
              },
            ],
          },
          sort: null,
        };
        const noAdsPlacementPayload = {
          ...noAdsPlacementParams,
          '@eTableConfig': { ...backbone.config, tableId: nodeData.blockEid },
        };
        [result, adsObjectResult, adsPlacementCountResult, adsObjectListResult] = await Promise.all([
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, rawPayload),
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, noAdsPlacementPayload),
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, adsPlacementCountParams),
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, adsObjectsListParams),
        ]);
        const adsObjectResultDataRows = get(adsObjectResult, ['data', 'rows'], []);
        const adsObjectResultRows = get(adsObjectResult, ['rows'], []);
        const adsPlacementCountDataRows = get(adsPlacementCountResult, ['rows'], []);
        const adsPlacementCountRows = get(adsPlacementCountResult, ['rows'], []);
        const adsObjectListDataRows = get(adsObjectListResult, ['data', 'rows', 0], '[]');

        const mergedData = mergeData(result.data.rows, adsObjectResultDataRows, params.groupBy, lastSort);

        const page = get(params, ['pagination', 'page'], 1);
        rowDataRef.current = page <= 1 ? mergedData : rowDataRef.current.concat(mergedData);
        const limit = get(params, ['pagination', 'limit'], 1);
        const finalData = rowDataRef.current.slice((page - 1) * limit, page * limit);
        result.data.rows = finalData;
        result.rows = finalData;

        result.data.rows = mapAdsObjectIds(
          mapTotalAdsPlacement(result.data.rows, adsPlacementCountDataRows),
          adsObjectListDataRows,
        );
        result.rows = mapAdsObjectIds(mapTotalAdsPlacement(result.rows, adsPlacementCountRows), adsObjectListDataRows);
      } else if (!hasAdsObject) {
        const rawPayload = {
          ...params,
          '@eTableConfig': { ...backbone.config, tableId: nodeData.blockEid },
          // metrics: params.metrics.filter(
          //   (i) => !i.startsWith('ads_placements') && !i.startsWith('ads_strategy_targetings'),
          // ),
          filter: {
            ...params.filter,
            filters: [...(params.filter?.filters || [])],
          },
        };

        [result] = await Promise.all([eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, rawPayload)]);
      } else {
        const rawPayload = {
          ...params,
          '@eTableConfig': { ...backbone.config, tableId: nodeData.blockEid },
          // metrics: params.metrics.filter(
          //   (i) => !i.startsWith('ads_placements') && !i.startsWith('ads_strategy_targetings'),
          // ),
          filter: {
            ...params.filter,
            filters: [
              ...(params.filter?.filters || []),
              {
                dataType: 'string',
                field: 'ads_objects.deleted_at',
                operator: 'is_empty',
                value: '',
              },
              {
                dataType: 'string',
                field: 'ads_objects.is_shown',
                operator: 'is',
                value: '1',
              },
            ],
          },
        };
        const adsObjectsListParams = {
          ...rawPayload,
          '@eTableConfig': undefined,
          attributes: ['ads_assets.sc_identify_code'],
          metrics: [],
          dimensions: ['ads_assets'],
          groupBy: {
            dimensions: null,
            aggregations: [
              {
                field: 'ads_assets.sc_identify_code',
                func: 'LIST',
              },
            ],
          },
          groupAll: true,
          groupPeriod: 'all',
          filter: {
            ...rawPayload.filter,
          },
        };

        [result, adsObjectListResult] = await Promise.all([
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, rawPayload),
          eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, adsObjectsListParams),
        ]);

        const adsObjectListDataRows = get(adsObjectListResult, ['data', 'rows', 0], '[]');
        result.data.rows = mapAdsObjectIds(result.data.rows, adsObjectListDataRows);
        result.rows = mapAdsObjectIds(result.rows, adsObjectListDataRows);
      }

      if (result?.rows?.length == 0 && get(backbone, ['config', 'filter', 'length'], 0) == 0) {
        const rows = [campaignInfoRef.current];
        const columns = produceColumns(backbone.config);
        const enhancedRows = await calculateValueGetter({
          rows,
          columns,
          groupedFields: [],
          drillDowns: [],
          resourceMetric: [],
          formulaUpstream: null,
          config: backbone.config,
        });
        set(result, ['rows'], enhancedRows);
        set(result, ['data', 'rows'], enhancedRows);
      }

      return result;
    } else {
      return enhanceDataRequest2(params, originRequest, backbone);
    }
  };

  config.callback.onBackboneReady = (backbone) => {
    refBackbone.current = backbone;
  };

  return makeTable({
    blockEid: nodeData.blockEid,
    config,
    changeConfiguration: handleChangeConfig,
    tableBackboneHook: useTableBackbone,
    linkedObjects,
  });
}
