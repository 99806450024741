import * as React from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import { cloneDeep, get, uniq } from 'lodash';

interface DragItem {
  nodeId: string;
  parentNodeIds: string[];
}

export const useDragAndDrop = (nodeId, parentNodeIds, updateSidebar, appMenuListRef) => {
  const ref = React.useRef(null);
  const lineRef = React.useRef(null);
  const topLineRef = React.useRef(null);

  const moveNode = ({ nodeIdAfter, nodeIdBefore, parentNodeIds, desNodeId, parentDesNodeIds }) => {
    const cloneAppMenuList = cloneDeep(appMenuListRef.current);
    const parentNode = parentNodeIds.reduce((a, b, i) => {
      if (!a) return a;
      if (i === 0) return a.find((el) => el.nodeId === b);
      return a.tabs.find((el) => el.nodeId === b);
    }, cloneAppMenuList);

    const desParentNode = parentDesNodeIds.reduce((a, b, i) => {
      if (!a) return a;
      if (i === 0) return a.find((el) => el.nodeId === b);
      return a.tabs.find((el) => el.nodeId === b);
    }, cloneAppMenuList);

    if (get(parentNodeIds, [0], '') === 'favorites') {
      const favorites = cloneAppMenuList;
      const node = nodeIdAfter || nodeIdBefore;
      const nodeIndex = favorites.findIndex((fv) => fv.nodeId === node);
      const desNode = desNodeId || parentDesNodeIds[1];
      const desNodeIndex = favorites.findIndex((fv) => fv.nodeId === desNode);

      if (nodeIndex > -1 && desNodeIndex > -1) {
        favorites.splice(desNodeIndex + (nodeIdAfter ? 1 : 0), 0, favorites.splice(nodeIndex, 1)[0]);
        updateSidebar(favorites);
      }
      return;
    }

    if (parentNode && desParentNode) {
      const parentNodeTabs = Array.isArray(parentNode) ? parentNode : parentNode.tabs;
      let desParentNodeTabs = Array.isArray(desParentNode) ? desParentNode : desParentNode.tabs;
      const indexNode = parentNodeTabs.findIndex((el) => el.nodeId === (nodeIdAfter || nodeIdBefore));
      // Remove node from old destination
      const node = parentNodeTabs.splice(indexNode, 1)[0];
      const desIndexNode = desNodeId ? desParentNodeTabs.findIndex((el) => el.nodeId === desNodeId) : 0;

      // Insert node to new destination
      if (desParentNodeTabs) {
        desParentNodeTabs.splice(desIndexNode + (nodeIdAfter ? 1 : 0), 0, node);
      } else {
        desParentNodeTabs = [node];
      }

      if (ff.next_left_menu) {
        const updatedSideBar = cloneAppMenuList.filter((menu) =>
          [parentDesNodeIds[0], parentNodeIds[0]].includes(menu.nodeId),
        );
        updateSidebar(cloneAppMenuList, updatedSideBar);
      } else {
        updateSidebar(cloneAppMenuList);
      }
    }
  };

  // Handle drag menu
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MENU',
    item: {
      nodeId,
      parentNodeIds,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }));

  // Handle drop bottom line
  const [{ isOver: isLineOver }, lineDrop] = useDrop(() => ({
    accept: 'MENU',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      // ignore hover on drag menu and its submenu
      if (monitor.isOver({ shallow: true }) && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        if (ref && ref.current) {
          ref.current.style.background = null;
        }
        if (lineRef && lineRef.current) {
          lineRef.current.style.opacity = '1';
        }
      }
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      //  ignore drop on drag menu and its submenu
      if (!monitor.didDrop() && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        // Move drag menu after drop menu
        moveNode({
          parentNodeIds: item.parentNodeIds.filter((el) => el !== item.nodeId),
          nodeIdAfter: item.nodeId,
          desNodeId: nodeId,
          parentDesNodeIds: parentNodeIds.filter((el) => el !== nodeId),
          nodeIdBefore: null,
        });
      }
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  // Handle drop top line for first menu in submenu
  const [{ isOver: isTopLineOver }, topLineDrop] = useDrop(() => ({
    accept: 'MENU',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      // ignore hover on drag menu and its submenu
      if (monitor.isOver({ shallow: true }) && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        if (ref && ref.current) {
          ref.current.style.background = null;
        }
        if (topLineRef && topLineRef.current) {
          topLineRef.current.style.opacity = '1';
        }
      }
    },
    drop(item: DragItem, monitor: DropTargetMonitor) {
      //  ignore drop on drag menu and its submenu
      if (!monitor.didDrop() && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        // Move drag menu before drop menu
        moveNode({
          parentNodeIds: item.parentNodeIds.filter((el) => el !== item.nodeId),
          nodeIdAfter: null,
          desNodeId: nodeId,
          parentDesNodeIds: parentNodeIds.filter((el) => el !== nodeId),
          nodeIdBefore: item.nodeId,
        });
      }
    },
    collect: (monitor) => ({
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  // Handle drop menu
  const [{ isOver }, drop] = useDrop(() => ({
    accept: 'MENU',
    drop(item: DragItem, monitor: DropTargetMonitor) {
      //  ignore drop on drag menu and its submenu
      if (!monitor.didDrop() && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        // Move drag menu into drop menu
        moveNode({
          parentNodeIds: item.parentNodeIds.filter((el) => el !== item.nodeId),
          nodeIdAfter: null,
          desNodeId: null,
          parentDesNodeIds: uniq([...parentNodeIds, nodeId]),
          nodeIdBefore: item.nodeId,
        });
      }
    },
    hover(item: DragItem, monitor: DropTargetMonitor) {
      // ignore hover on drag menu and its submenu
      if (monitor.isOver({ shallow: true }) && item.nodeId !== nodeId && !parentNodeIds.includes(item.nodeId)) {
        ref.current.style.background = '#BFE2FF';
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  }));

  // Remove style when drag leave menu
  React.useEffect(() => {
    if (ref.current && !isOver) {
      ref.current.style.background = null;
    }
  }, [isOver]);

  // Remove style when drag leave bottom line
  React.useEffect(() => {
    if (lineRef.current && !isLineOver) {
      lineRef.current.style.opacity = '0';
    }
  }, [isLineOver]);

  // Remove style when drag leave top line
  React.useEffect(() => {
    if (topLineRef.current && !isTopLineOver) {
      topLineRef.current.style.opacity = '0';
    }
  }, [isTopLineOver]);

  // Set drag and drop for menu
  drag(drop(ref));
  // Set drop for bottom line
  lineDrop(lineRef);
  // Set drop for top line (only first menu in submenu)
  topLineDrop(topLineRef);

  return {
    ref,
    lineRef,
    topLineRef,
    isDragging,
  };
};
