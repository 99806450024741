import * as React from 'react';
import * as _ from 'lodash';
import { colors, searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { MenuItemProps, TextField, IconButton, InputAdornment } from '@material-ui/core';
import SearchInput from '../../../list-control/search-input/search-input';
import { ITextField, TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';

import { OptionType } from '../../../list-control/type';
import { useAutocomplete } from '@material-ui/lab';
import { useDropdownContext } from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import Search from '@ep/insight-ui/icons/svg/Search';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import Icon from '@ep/insight-ui/icons/Icon';
import clsx from 'clsx';

/** Style */
type PropsStyles = {
  searchAble?: boolean;
};
const useStyles = makeStyles(() => ({
  item: {
    color: '#000',
    fontSize: '14px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: '20px',
    height: '100%',
    margin: '0px',
    '&.itemText': {
      background: 'transparent',
      '&:hover': {
        background: '#E7E9EB',
      },
    },
    '&.itemTextSelected': {
      background: '#E7E9EB',
      '&:hover': {
        background: '#E7E9EB',
      },
    },
  },
  listMenu: {
    '&:hover': {
      background: colors.action.secondaryHovered,
    },
    '&:hover p': {
      color: colors.text.default,
    },
  },
  listMenuChecked: {
    background: '#EBF6FF',
    '& p': {
      color: '#0369C7',
    },
  },
  searchInput: (props: PropsStyles) => ({
    display: props.searchAble ? 'block' : 'none',
  }),
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    padding: '6px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
  },
  textSearch: {
    '& input': {
      width: '100%',
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  srollBar: {
    overflow: 'auto',
    height: 'calc(100% - 46px)',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
}));
interface itemMenu {
  index: number;
  label: string;
  select: boolean;
}
interface DropdownProps {
  listMenu: OptionType[];
  searchAble?: boolean;
  onChange?: (v: OptionType[]) => void;
}

const MenuListSingle = ({ listMenu, onChange, searchAble = true }: DropdownProps) => {
  const inputRef = React.useRef<HTMLDivElement>();
  const { open, setOpen } = useDropdownContext();
  const listMenuDefault = React.useMemo(() => listMenu, [listMenu]);
  const [option, setOption] = React.useState<OptionType[]>(listMenuDefault);
  const classes = useStyles({ searchAble });
  const [inputValue, setInputValue] = React.useState('');

  const arrOptions = React.useMemo(() => {
    if (inputValue)
      return _.filter(listMenuDefault, (item) => _.lowerCase(item.label).includes(_.lowerCase(inputValue)));
    return listMenuDefault;
  }, [inputValue, listMenuDefault]);

  const handleSelect = (data) => {
    const newOption = [...listMenuDefault];
    newOption.map((item) => {
      if (item.checked) item.checked = false;
      if (item.label === data) item.checked = true;
    });
    onChange(newOption);
    setOpen(false);
  };
  const handleFocus = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
    }
  };
  // React.useEffect(() => {
  //   if (open) {
  //     handleFocus();
  //   }
  // }, [open]);

  return (
    <>
      {searchAble && (
        <div
          className={classes.searchInput}
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 1,
            backgroundColor: '#fff',
            paddingTop: '8px',
            paddingRight: '8px',
          }}
        >
          {open ? (
            <TextField
              className={clsx(classes.textStyle, classes.textSearch)}
              placeholder={`Search For A Property`}
              onChange={(e) => setInputValue(e.target.value)}
              value={inputValue}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ width: 12, height: 14 }} />
                  </InputAdornment>
                ),
                endAdornment: inputValue.length > 0 && (
                  <IconButton
                    onClick={() => {
                      setInputValue('');
                      handleFocus();
                    }}
                  >
                    <Icon type={'closeCircle'} />
                  </IconButton>
                ),
                classes: { input: classes['input'] },
                inputRef: inputRef,
              }}
            />
          ) : (
            <div style={{ height: '32px' }}></div>
          )}
        </div>
      )}
      <div className={classes.srollBar}>
        {arrOptions.map((item: OptionType, index) => {
          return (
            <div
              className={`${classes.menuItem} ${item.checked ? classes.listMenuChecked : classes.listMenu}`}
              key={index}
              onClick={() => {
                handleSelect(item.label);
              }}
            >
              <p className={classes.item}>{item.label}</p>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MenuListSingle;
