import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { Button, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as React from 'react';
import FormControlInput from '../../form-controls/form-control-input';
import Selection from '../../form-controls/selection';

const BillingForm = () => {
  const initialValues = {
    ['payment-method']: 'Credit Card via Stripe',
    ['billing-interval']: 'annually',
    ['billing-email']: 'finance@loreal.com',
    ['VAT/GST-number']: '201811686C',
    ['address']: '80 Marine Parade Road, #01-12 Parkway Parade Singapore 449269',
  };
  return (
    <React.Fragment>
      <ExpansionForm label={'Billing setting'} defaultExpanded={true} detailContentSize={'normal'}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, { setSubmitting }) => {
            setTimeout(() => {
              alert(JSON.stringify(values, null, 2));
              setSubmitting(false);
            }, 400);
          }}
        >
          {({ values, handleChange }) => (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <FormControlInput
                    label={'Payment method'}
                    defaultValue={values['payment-method']}
                    onChangeText={handleChange('payment-method')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Selection
                    options={[{ label: 'Annually', value: 'annually' }]}
                    onChange={handleChange('billing-interval')}
                    label={'Billing interval'}
                    value={values['billing-interval']}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    label={'Billing email'}
                    type={'email'}
                    defaultValue={values['billing-email']}
                    onChangeText={handleChange('billing-email')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    label={'VAT/GST number'}
                    defaultValue={values['VAT/GST-number']}
                    onChangeText={handleChange('VAT/GST-number')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlInput
                    label={'Address'}
                    defaultValue={values['address']}
                    onChangeText={handleChange('address')}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button color={'primary'} type={'submit'} variant="contained">
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </ExpansionForm>
    </React.Fragment>
  );
};
export default BillingForm;
