import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Screen = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 9.00016H7.11111L8 10.3335V11.0002H4V10.3335L4.88889 9.00016H1.33333C0.596667 9.00016 0 8.4035 0 7.66683L0 1.66683C0 0.930163 0.596667 0.333496 1.33333 0.333496H10.6667C11.4033 0.333496 12 0.930163 12 1.66683V7.66683C12 8.4035 11.4033 9.00016 10.6667 9.00016ZM2 1.66683C1.63167 1.66683 1.33333 1.96516 1.33333 2.3335V5.66683C1.33333 6.03502 1.63181 6.3335 2 6.3335H10C10.3682 6.3335 10.6667 6.03502 10.6667 5.66683V2.3335C10.6667 2.30837 10.6653 2.28357 10.6626 2.25917C10.6281 1.94821 10.38 1.70191 10.0682 1.67027C10.0458 1.66799 10.023 1.66683 10 1.66683H2Z"
    />
  </SvgIcon>
);

export default Screen;
