import { EIP_CONSTANT } from '@eip/next/lib/main';
import moment from 'moment';
import { epsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';

export const INSIGHT_METRIC_STOREFRONT_CONFIG = {
  title: 'Summary',
  dimension: [],
  metric: [],
  attribute: [],
  dateRange: {
    dateFrom: moment().subtract(8, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().subtract(1, 'day').format(EIP_CONSTANT.DATE_FORMAT),
  },
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.PERFORMANCE.queryEpMatching,
  },
  useDataSource: false,
  primaryKeys: ['storefront.id'],
  tableType: 'metric',
  hiddenFilter: {
    currency: 'USD',
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.country_code',
      valueGetter: {
        label: 'storefront.country_code',
        id: 'storefront.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.marketplace_code',
      valueGetter: {
        label: 'storefront.marketplace_code',
        id: 'storefront.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.id',
      valueGetter: {
        label: 'storefront.storefront_name',
        id: 'storefront.id',
        sid: 'storefront.storefront_sid',
        subtext: 'storefront.id',
      },
    },
    'storefront.gmv': {
      title: 'GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'storefront.gmv',
      },
    },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
  system: {
    hiddenComponents: ['sort', 'search', 'majorButton'],
  },
  requestHiddenField: {
    dimension: [],
    attribute: [],
  },
};
