import * as React from 'react';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { Box, Button, makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles({
  title: { paddingLeft: '10px' },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.message': {
      padding: '10px',
    },
  },
});
type Props = {
  data: string;
  onClose: () => void;
};
export const DialogMessage = ({ data, onClose = () => undefined }: Props) => {
  const classes = useStyles();
  let title = null;
  let message = null;
  let noti = null;
  const handleClose = () => {
    onClose();
  };
  switch (data) {
    case 'deactivate': {
      title = 'Deactivate member';
      message = 'Are you sure to deactive the selected member from access to your company?';
      noti =
        'By this action, the selected member will temporary can’t access to any of your company related data until be activated again.';
      break;
    }
    case 'remove': {
      title = 'Remove access';
      message = 'Are you sure to remove the selected member from access to your company?';
      noti = 'By this action, the selected member will no longer can view & edit your company related data';
      break;
    }
    default:
      break;
  }
  return (
    <>
      <Typography className={`${classes.title}`} variant="h4">
        {title}
      </Typography>
      <Box className={`${classes.text} message`}>
        <Grid>{message}</Grid>
        <Grid>{noti}</Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end">
        <Button
          color="secondary"
          variant="contained"
          component="span"
          className={`${classes.button}`}
          onClick={handleClose}
        >
          <span className={`${classes.text} textButton`}>Cancel</span>
        </Button>
        <Button color="primary" variant="contained" component="span" className={`${classes.button}`}>
          <span className={`${classes.text} textButton primary`}>Remove</span>
        </Button>
      </Box>
    </>
  );
};
