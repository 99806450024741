import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PinUntilcolumn = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 12C3.38153 12 3.71315 11.7863 3.88174 11.4721C3.08909 10.9828 2.79628 9.97987 3.18189 9.14626L1.85976 8.38294C0.769752 7.75362 0.684128 6.27827 1.54965 5.46658L4 3.16863V1C4 0.447715 3.55228 0 3 0H1C0.447715 0 0 0.447715 0 1V11C0 11.5523 0.447715 12 1 12H3ZM7.2639 0.734706C6.8662 0.505094 6.35742 0.641772 6.12751 1.03999C5.8976 1.4382 6.03363 1.94715 6.43133 2.17676L6.48584 2.20824L2.23356 6.19604C1.82499 6.57921 1.88852 7.24496 2.35962 7.51695L4.54123 8.7765L4.12494 9.49753C3.89503 9.89575 4.03106 10.4047 4.42876 10.6343C4.82646 10.8639 5.33523 10.7272 5.56514 10.329L5.98143 9.608L8.16304 10.8676C8.63414 11.1395 9.24246 10.8617 9.37001 10.3163L10.6974 4.63979L10.7519 4.67126C11.1496 4.90087 11.6584 4.76419 11.8883 4.36598C12.1182 3.96777 11.9822 3.45881 11.5845 3.2292L7.2639 0.734706Z"
      fill={`${props.color ? props.color : '#253746'}`}
    />
  </SvgIcon>
);

export default PinUntilcolumn;
