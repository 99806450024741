import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { getCellFormat, options } from './cell-format';

console.info({ options });

export const CELL_FORMAT_OPTIONS = options.reduce((a, b) => {
  const cellFormatData = getCellFormat(b);
  if (cellFormatData) a[b] = cellFormatData;
  return a;
}, {});

export const CELL_FORMAT_OPTIONS_WITH_COMPONENT = Object.keys(CELL_FORMAT_OPTIONS).reduce((a, b) => {
  return { ...a, [b]: CELL_FORMAT_OPTIONS[b].component, ...cellFormat };
}, {});
