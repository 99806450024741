import Dropdown, { ChildrenDropdownProps } from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  row: {
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        width: '128px',
        height: '32px',
      },
    },
  },
  listAction: {
    padding: '8px',
  },
  showMenu: {
    // display:'inline-flex',
    opacity: 1,
  },
  hideMenu: {
    opacity: 0,
  },
}));
interface MenuAction extends ChildrenDropdownProps {
  listMenu: DropdownMenuDataType[][];
}
const MenuAction = ({ listMenu, ...rest }: MenuAction) => {
  return <MenuList listMenu={listMenu} closeMenuCallBack={rest.onClose} />;
};
export type TypeValueBudget = {
  field: string;
  operator: string;
  budget: string;
  currency?: string;
};

type BudgetRowFormProps = {
  value: TypeValueBudget;
  onChange: (value: TypeValueBudget) => void;
  options: OptionSelectType[];
  operators: OptionSelectType[];
  enableStyleMenu?: boolean;
  listMenu?: DropdownMenuDataType[][] | null;
  disabledListBox?: boolean;
  disabledModelNoLimit?: boolean;
};
const BudgetRowForm = ({
  value,
  onChange,
  operators,
  options,
  enableStyleMenu = false,
  listMenu = null,
  disabledListBox = false,
  disabledModelNoLimit = false,
}: BudgetRowFormProps) => {
  const classes = useStyles();
  const [field, setField] = React.useState<string>('');
  const [budget, setBudget] = React.useState<string>('');
  const [operator, setOperator] = React.useState<string>('');
  React.useEffect(() => {
    if (value.operator != operator) setOperator(value.operator);
    if (value.budget != budget) setBudget(value.budget);
    if (value.field != field) setField(value.field);
  }, [value]);
  React.useEffect(() => {
    onChange({ budget, field, operator });
  }, [field, budget, operator]);

  return (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={2}
      className={classes.row}
    >
      <Grid item style={{ width: '80%' }}>
        <SelectForm
          options={options}
          value={field}
          disabled={disabledListBox}
          className={classes.select}
          style={{ width: '100%' }}
          onChange={(e) => setField(e.target.value)}
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        {/* <SelectForm
          options={operators}
          value={operator}
          className={classes.select}
          style={{ width: '100%' }}
          onChange={(e) => {
            const value = e.target.value;
            setOperator(value);
            setBudget(value == 'noLimit' ? null : '0');
          }}
        /> */}
        <TextBudgetInput
          defaultValue={budget == '0' ? '' : budget}
          currency={value.currency}
          onChangeText={setBudget}
          disabledDebounce
          placeholder={disabledModelNoLimit ? '0' : 'no limit'}
          noSelectBox
        />
      </Grid>
      {/* <div style={{ width: '40%', opacity: operator == 'noLimit' ? 0 : 1 }}>
        <TextFieldNumber
          disabled={operator == 'noLimit' ? true : false}
          value={budget}
          style={{ width: '100%' }}
          onChangeNumber={(number) => setBudget(String(number))}
        />
      </div> */}

      {enableStyleMenu ? (
        <Grid item>
          <Dropdown
            disabled={listMenu == null}
            classNamePaper={classes.listAction}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            className={listMenu ? classes.showMenu : classes.hideMenu}
          >
            {listMenu && <MenuAction listMenu={listMenu} />}
          </Dropdown>
        </Grid>
      ) : null}
    </Grid>
  );
};
export default BudgetRowForm;
