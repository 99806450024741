import ButtonFilter from '@ep/insight-ui/elements/table/table-actions/button-filter';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/table-backbone';
import produce from 'immer';
import * as React from 'react';

export const EtableConfigFilter = React.forwardRef(function EtableConfigFilter(
  props: { tableConfig: any; value: any[]; node: any; column: any },
  ref,
) {
  const eTableConfig = produce(props.tableConfig, (draft) => {
    draft.filter = props.value;
  });
  const backbone = useTableBackbone(
    { apiRequest: {}, configuration: eTableConfig, addons: {}, callback: {} },
    (nuConfig) => {
      props.node.setDataValue(props.column.getId(), nuConfig.filter);
    },
  );

  React.useEffect(() => {
    backbone.init();
  }, []);

  return (
    <TableBackboneContext.Provider value={backbone}>
      <ButtonFilter></ButtonFilter>
    </TableBackboneContext.Provider>
  );
});
