import { NodeEditContext, useLog, usePage, eipRequest } from '@eip/next/lib/main';
import { useWorkflow as usePageContext, useWorkflowScreen } from '@ep/insight-ui/system/backbone/workflow-backbone';
import FormCampaignInfo, {
  IDisableArr,
} from '@ep/insight-ui/elements/input-form/form-templates/form-groups/campaign-details/campaign-setting-form';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';
import { CampaignTableFactory } from '@ep/insight-ui/system/workflow/camp0-detail/campaign-table-factory';
import { CampaignTableInit } from '@ep/insight-ui/system/workflow/camp0-detail/campaign-table-init';
import {
  createAdsObjects,
  createCampaign,
  transformCellCompactSelections,
  mappingDimensionOptions,
  getDimensionDefinition,
  updateAdObjectMethod,
  updateCampaign,
  updateCampaignTimeline,
} from '@ep/insight-ui/system/workflow/campaign-details/legacy/api-request-campaign-details';
import { getCampaignSettingInfo } from '@ep/insight-ui/system/workflow/camp0-detail/legacy/api-request-campaign-details';
import { Box, makeStyles } from '@material-ui/core';
import { debounce, get, isEqual, uniqueId, startCase, lowerCase, set } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import * as request from '../../backbone/data-source/common';
import { HelmetMetaData } from '@ep/insight-ui/elements/seo-settings/helmet-data';
import { Provider } from 'jotai';
import { PersonalizationAtom } from '../../backbone/atom/campaign-detail';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { MonitorContainer } from '../../util/monitor/container';
import { ScriptDetails } from '@ep/insight-ui/chartlib/next-script-info';

type Options = Omit<React.ComponentProps<typeof FormCampaignInfo>, 'value'>;

const log = useLog('chartlib:campaign-creator');
const mapOptionItem = (i: { value: any; label: string; payload?: any }) => {
  return {
    label: i.label,
    value: i.value,
    payload: i.payload,
  };
};

function mapOptionItemNext(i: { id: any; text: string; payload?: any }) {
  return {
    label: i.text,
    value: i.id,
    payload: i.payload,
  };
}

class HyperAutomationDetailChartlib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData) {
    const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
    const chartNodeData = Object.assign({ customAttributes: {} }, data);

    const onMainTableUpdateConfig = React.useCallback((config) => {
      const personalization = get(data, 'customAttributes.personalization', {});
      nodeEditContext.onUpdateCustomAttributes(data, { personalization: { ...personalization, ...config } });
    }, []);

    return (
      <NotificationGlobalProvider>
        <MonitorContainer component="div" mId={data.blockEid} mLabel={'Script details'}>
          <ScriptDetails />
        </MonitorContainer>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <h1>Configuration</h1>;
  }
}

export default {
  blockType: 'tempHyperAutomationCreator',
  label: 'Hyper automation detail',
  blockComponent: HyperAutomationDetailChartlib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
    h: 12,
  },
};
const CONSTANT_VALUES = {
  adObjectMethod: {
    MANUAL: 'MANUAL_OPERATION',
    Botep: 'EPSILO_OPERATION',
    MARKETPLACE: 'COMBINED_OPERATION',
  },
  objective: {
    sale: 'SALE',
    traffic: 'TRAFFIC',
  },
  marketplaceCode: {
    SHOPEE: 'SHOPEE',
    LAZADA: 'LAZADA',
    TOKOPEDIA: 'TOKOPEDIA',
  },
  status: {
    DRAFT: 'DRAFT',
    PUBLISHING: 'PUBLISHING',
    SCHEDULED: 'SCHEDULED',
    ONGOING: 'ONGOING',
    PAUSED: 'PAUSED',
    SUSPENDED: 'SUSPENDED',
    RECOVERING: 'RECOVERING',
    ENDED: 'ENDED',
  },
};
const rangeAdObjectMethod = [
  CONSTANT_VALUES.adObjectMethod.MANUAL,
  CONSTANT_VALUES.adObjectMethod.Botep,
  CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
];
const disabledAdObjectMethod = {
  [CONSTANT_VALUES.marketplaceCode.SHOPEE]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
  [CONSTANT_VALUES.marketplaceCode.LAZADA]: [CONSTANT_VALUES.adObjectMethod.Botep],
  [CONSTANT_VALUES.marketplaceCode.TOKOPEDIA]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
};

const mappingKeys = {
  dailyBudget: 'daily_budget',
  totalBudget: 'total_budget',
  objective: 'objective',
  adObjectMethod: 'automated_ads_select_product',
  firstSearchSlot: 'first_search_slot',
  'schedule.dateFrom': 'timeline_from',
  'schedule.dateTo': 'timeline_to',
};

const mappingValues = {
  COMBINED_OPERATION: '1',
  MANUAL_OPERATION: '0',
};

const HyperAutomationDetail = () => {
  return <h1>Test</h1>;
};
