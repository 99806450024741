import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core';
import ReactGridLayout from 'react-grid-layout';
import { useLog } from '../log';
import { NestedContext } from './nested';
import { v4 as uuid } from 'uuid';
import clsx from 'clsx';

const log = useLog('rgl:nested-component');

function DragLayer({ isDragging, gridId, onDragEnter, onDragLeave, onDragOver, onDrop, onClick }) {
  const classes = useStyle();
  const [{ parent, left, height, top, width }, setState] = React.useState({
    parent: null,
    left: 0,
    height: 0,
    top: 0,
    width: 0,
  });

  React.useEffect(() => {
    const p = document.getElementById(gridId).parentElement;

    setState((state) => ({ ...state, parent: p }));
  }, []);

  React.useEffect(() => {
    if (isDragging) {
      const { left, top, width, height } = (parent as HTMLDivElement).getBoundingClientRect();
      setState((state) => ({
        ...state,
        left,
        top,
        width,
        height,
      }));
    }
  }, [isDragging, parent]);

  if (parent == null) return null;

  return ReactDOM.createPortal(
    <div
      className={clsx(classes.dragLayer, isDragging && 'visible', 'root')}
      style={{ left: 0, top: 0, height, width }}
      onMouseEnter={(evt) => onDragEnter(evt)}
      onMouseMove={(evt) => onDragOver(evt)}
      onMouseLeave={(evt) => onDragLeave(evt)}
      onMouseUp={(evt) => onDrop(evt)}
      onClick={(evt) => onClick(evt)}
    ></div>,
    parent,
  );
}

function NestedMark({ gridId, isNested, gridInstance }) {
  const { register, unregister } = React.useContext(NestedContext);

  React.useEffect(() => {
    register([gridId, gridInstance]);
    return () => {
      unregister([gridId, gridInstance]);
    };
  }, []);

  return <div id={`nested-mark-${gridId}`}></div>;
}
export default class NestedGRL extends ReactGridLayout {
  dropableCandidate = null;
  gridId;

  constructor(props) {
    super(props);
    this.gridId = uuid();
  }

  validDifferentScope(blockLayout) {
    return !this.state.layout.find((i) => i.i === blockLayout.i);
  }

  nestedOnDragStart = (evt, { blockEl, blockLayout }) => {
    if (this.validDifferentScope(blockLayout)) {
      log('validDifferentScope', this.gridId, this.state.layout, blockEl, blockLayout);
      this.dropableCandidate = {
        blockEl,
        blockLayout,
      };
      this.onDragEnter(evt);
    }
  };

  nestedOnDragLeave = (e: React.MouseEvent) => {
    this.onDragLeave(e);
  };

  nestedOnDragOver = (e: React.MouseEvent) => {
    if (!this.dropableCandidate) return;
    // this.displayHolderWhenDragOver(e);
    this.onDragOver(e);
  };

  resetDropOverPlaceholder = () => {
    this.dropableCandidate = null;
    this.dragEnterCounter = 0;
    this.removeDroppingPlaceholder();
  };

  render() {
    const grid = super.render();
    const { nestedChild } = this.props;

    return (
      <React.Fragment>
        {grid}
        {!nestedChild && (
          <NestedContext.Consumer>
            {({ isDragging, onMouseMove, nestedOnDrop, setState }) => (
              <div id={this.gridId}>
                {/* {nestedChild && ( */}
                <DragLayer
                  gridId={this.gridId}
                  isDragging={isDragging}
                  onDragEnter={(evt) => {}}
                  onDragOver={onMouseMove}
                  onDragLeave={this.nestedOnDragLeave}
                  onDrop={nestedOnDrop}
                  onClick={() => setState({ dragging: false })}
                />
                {/* )} */}
              </div>
            )}
          </NestedContext.Consumer>
        )}
        <NestedMark gridId={this.gridId} gridInstance={this} isNested={nestedChild} />
      </React.Fragment>
    );
  }
}

const useStyle = makeStyles(() => ({
  dragLayer: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'none',
    // background: 'rgba(77, 175, 124, 1)',
    background: 'transparent',
    '&.visible': {
      display: 'block',
    },
  },
}));
