import { get } from 'lodash';
import moment from 'moment';
import { generateFillDates } from '../../util/calendar';
import { getStorefrontMetricTraction } from '../../util/column';
import { checkEpsiloTableEndpoint } from '../data/common';

export async function tractionTable(originData, { config, contextQuery, cellUpdate, pageRequests }, next) {
  const sfMetricTractionField = getStorefrontMetricTraction({ config });

  if (!sfMetricTractionField) {
    return await next(originData, { config, contextQuery, cellUpdate, pageRequests });
  }

  const { columns, data } = await next(originData, { config, contextQuery, cellUpdate, pageRequests });

  const tractionColumn = columns.find((c) => c.field === sfMetricTractionField);
  const mapping = config.mapping;
  const showTrend = get(config, 'showTrend', true);

  tractionColumn.actions = tractionColumn.actions.concat([
    {
      name: showTrend ? 'Hide trend' : 'Show trend',
      icon: showTrend ? 'eyeSlash' : 'eye',
      type: '',
      component: null,
      params: {
        action: 'hideTrend',
        payload: {
          showTrend,
        },
      },
    },
  ]);

  if (!showTrend) return [];

  const currentDateRange = get(config, ['dateRange']);
  const dateFrom = moment(currentDateRange.dateFrom, 'YYYY-MM-DD');
  const dateTo = moment(currentDateRange.dateTo, 'YYYY-MM-DD');
  const endpoint = get(config, 'endpoint.GET_TABLE_DATA', '');
  const isSparkline = get(mapping, 'staticValue.allowSparkline', false);
  const columnName = get(mapping, 'staticValue.sparklineColumnName', 'Trend');

  let period;
  if (checkEpsiloTableEndpoint(endpoint)) {
    period = get(config, 'groupPeriod', 'daily');
  } else {
    period = get(mapping, 'staticValue.period', 'daily');
  }

  const pivotDateTimeColumn = generateFillDates(dateFrom, dateTo, period).sort();
  // const colWidth = get(backbone, 'config.columnWidth', []).find((ele) => ele.columnField === columnField);
  const colWidth = 100;

  const sparklineColumn = {
    name: columnName,
    field: 'sparkline',
    resizable: true,
    moveable: false,
    hide: !isSparkline,
    dataType: 'float',
    width: colWidth || 100,
    actions: [],
    cell: {
      style: {
        borderRightColor: 'whitesmoke',
      },
      format: 'lineChart',
      valueGetter: {
        data: (row) => [...pivotDateTimeColumn].map((dt) => row[dt]),
        labels: () => [...pivotDateTimeColumn],
      },
      staticValue: {
        height: '32px',
        showTooltip: true,
      },
      actions: [],
      params: {
        showTooltip: true,
      },
      updateHandler: null,
    },
    lockPosition: true,
  };

  const additionalColumns = [...pivotDateTimeColumn].reverse().map((dt) => {
    return {
      name: dt,
      field: dt,
      resizable: true,
      movable: false,
      dataType: 'float',
      width: 100,
      actions: [],
      cell: {
        format: 'currencyFormatFormula',
        valueGetter: {
          value: dt,
          metricUnit: 'metricUnit',
          metric: 'metric',
          label: get(tractionColumn, 'cell.valueGetter.dateCellLabel'),
          tooltip: get(tractionColumn, 'cell.valueGetter.dateCellTooltip'),
          status: get(tractionColumn, 'cell.valueGetter.dateCellStatus'),
        },
        staticValue: {
          // isEmpty: get(config, 'groupBy.columns', []).length === 0,
          // showTooltip: true,
        },
        actions: [],
      },
      lockPosition: true,
    };
  });
  return {
    columns: columns.concat(sparklineColumn).concat(additionalColumns),
    data: data,
  };
}
