import * as React from 'react';
// inline editor

/*
 * ff.refresh_cookie_button:start
 */
import ActionNeededFormat from './format/action-needed-format';
// cell formatting
import AdditionFormat from './format/addition-format';
import AgeFormat from './format/age-format';
import AudienceFormat from './format/audience-format';
import AvatarTextFormat from './format/avatar-text-format';
import BudgetFormat from './format/budgets/budget-format';
import SelectionBudgetFormat from './format/budgets/selection-budget';
import SingleBudgetFormat, { SingleBudgetImmovableFormat } from './format/budgets/single-budget-format';
import CompactCellFormat from './format/compact-cell-format';
import ConnectionStatusFormat from './format/connection-status-format';
import CurrencyFormat from './format/currency-format';
import DateRangeFormat from './format/date-range-format';
import EnableDisableFormat from './format/enable-disable-format';
import GenderFormat from './format/gender-format';
import KeywordFormat from './format/keyword-format';
import KeywordMethodFormat from './format/keyword-method-format';
import LineChartFormat from './format/line-chart-format';
import LineEDashboardChart from './format/line-edashboard-format';
import Elasticity from './format/mixed-chart-format';
import PositionFormat from './format/position-format';
import ProgressBarFormat from './format/progress-bar-format';
import SimpleGroupFormat from './format/simple-group-format';
import StampFormat from './format/stamp-format';
import StatusTextFormat from './format/status-text-format';
import TagFormat from './format/tag-format';
import TextFormat from './format/text-format';
import TimeBasedCellFormat from './format/time-based-cell-format';
import AggregateAdObjectBudget, { AggregateKeywordBiddingPrice } from './format/aggregate-budget';
import TransformStatusFormat from './format/transform-status-format';
import DateTimeCompare from './format/datetime-compare';
import RgbStatusFormat from './format/rgb-status-format';
import EmptyFormat from './format/empty-format';
import TractionElasticityFormat from './format/traction-elasticity-format';
import DataSyncStatusFormat from './format/data-sync-status-format';

import TextFormatFormula from './format/formula-text-format';
import CurrencyFormatFormula from './format/formula-currency-format';

/**
 * ff.replace_keyword_compact:start
 */
import HeatmapFormat from './format/heatmap-format';
/**
 * ff.replace_keyword_compact:end
 */

/**
 * ff.adoption_rate_table:start
 */
import TractionFormat from './format/traction-format';
import AdoptionRateFormat from './format/adoption-rate-format';
import TextWithTooltipFormat from './format/text-with-tooltip-format';
/**
 * ff.adoption_rate_table:end
 */

/**
 * ff.mass_operation_management:start
 */
import StatusRequestFormat from './format/status-request-format';
/**
 * ff.mass_operation_management:end
 */

/**
 * ff.invite_user_workspace:end
 */
import TagWithInlineEditor from './format/tag-with-inline-editor';
/**
 * ff.ui_page_keyword_analysis:start
 */
import KeywordAnalysisFormat from './format/keyword-analysis-format';
/*
 * ff.ui_page_keyword_analysis:end
 */

/*
 * ff.add_note_column_including_inline_edit:start
 */
import NoteFormat from './format/note-format';
/*
 * ff.add_note_column_including_inline_edit:end
 */

/*
 * ff.aggregate_cell:start
 */
import AccumulationFormat from './format/accumulation-format';
/*
 * ff.aggregate_cell:end
 */

/**
 * ff.chart_line_traction:start
 */
import TwoLineChartFormat from './format/two-line-chart-format';
/**
 * ff.chart_line_traction:start
 */
import TextareaEditor from '../inline-edit/cell/textarea-editor';
import ScheduleStatusIndicator from './format/schedule-status-indicator';

function TextMatchType({ value, ...rest }: any) {
  const val = { ...value };
  if (value.value === 'EXACT_MATCH') {
    val.label = 'Exact match';
  } else if (value.value === 'BROAD_MATCH') {
    val.label = 'Broad match';
  }
  return <TextFormat value={val} {...rest} />;
}

export const cellFormat = {
  textFormatFormula: TextFormatFormula,
  currencyFormatFormula: CurrencyFormatFormula,
  pivotFormatFormula: EmptyFormat,
  text: TextFormat,
  scheduleStatusIndicator: ScheduleStatusIndicator,
  textMatchType: TextMatchType,
  status: StatusTextFormat,
  budget: BudgetFormat,
  selectionBudget: SelectionBudgetFormat,
  currency: CurrencyFormat,
  timeline: DateRangeFormat,
  position: PositionFormat,
  userInfo: AvatarTextFormat,
  tag: TagFormat,
  tagWithInlineEditor: TagWithInlineEditor,
  ...(ff.add_note_column_including_inline_edit
    ? {
        note: NoteFormat,
      }
    : {}),
  stamp: StampFormat,
  progressBar: ProgressBarFormat,
  add: AdditionFormat,
  lineChart: LineChartFormat,
  simpleGroup: SimpleGroupFormat,
  singleBudget: SingleBudgetFormat,
  // eslint-disable-next-line react/display-name
  totalBudget: (props: any) => <SingleBudgetImmovableFormat {...props} label={'total'} />,
  // eslint-disable-next-line react/display-name
  dailyBudget: (props: any) => <SingleBudgetImmovableFormat {...props} label={'daily'} />,
  compact: CompactCellFormat,
  elasticity: Elasticity,
  timeBasedCell: TimeBasedCellFormat,
  lineEDashboardChart: LineEDashboardChart,
  keywordFormat: KeywordFormat,
  age: AgeFormat,
  gender: GenderFormat,
  keywordMethod: KeywordMethodFormat,
  enableDisableFormat: EnableDisableFormat,
  audienceFormat: AudienceFormat,
  actionNeededFormat: ActionNeededFormat,
  connectionStatusFormat: ConnectionStatusFormat,
  ...(ff.adoption_rate_table
    ? {
        traction: TractionFormat,
        adoptionRate: AdoptionRateFormat,
        textWithTooltip: TextWithTooltipFormat,
      }
    : {}),
  ...(ff.ui_page_keyword_analysis
    ? {
        keywordAnalysisFormat: KeywordAnalysisFormat,
      }
    : {}),
  ...(ff.mass_operation_management
    ? {
        statusRequest: StatusRequestFormat,
      }
    : {}),
  ...(ff.aggregate_cell ? { accumulationFormat: AccumulationFormat } : {}),
  ...(ff.lowcode
    ? {
        aggregateAdObjectBudget: AggregateAdObjectBudget,
        aggregateKeywordBiddingPrice: AggregateKeywordBiddingPrice,
        placeHolder: function Placeholder(props: any) {
          return <div style={{ padding: 10 }}>Waiting Data...</div>;
        },
      }
    : {}),
  ...(ff.replace_keyword_compact ? { heatmap: HeatmapFormat } : {}),
  ...(ff.chart_line_traction ? { twoLineChart: TwoLineChartFormat } : {}),
  transformStatus: TransformStatusFormat,
  dateTimeCompare: DateTimeCompare,
  rgbStatusFormat: RgbStatusFormat,
  textareaEditor: TextareaEditor,
  emptyFormat: EmptyFormat,
  tractionElasticityFormat: TractionElasticityFormat,
  dataSyncStatusFormat: DataSyncStatusFormat,
};
