import { useLog, usePage, aim } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import AppMenu from '@ep/insight-ui/elements/app-menu/app-menu';
import { TypeTabListItem } from '@ep/insight-ui/elements/app-menu/tab-list-menu';
import GoogleForm from '@ep/insight-ui/elements/input-form/form-templates/form-groups/google-form/google-form';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { get, sortBy } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { getPageUrn } from '../global';
import { useWorkspaceMenu } from '../hooks/use-workspace-menu';
import { dataMenuFooter, dataMenuPage, dataMenuTopBar, eosMenu, hardcodeDataMenu } from './data-menu';
import { useFullPage } from './use-fullpage';
import { useVersion } from './use-version';
import { ViewAsUser } from '@ep/insight-ui/elements/app-menu/view-as-user';

const log = useLog('page:internal-page');
const { isAllow } = aim;

const dataSideBarHead: Array<TypeTabListItem> = [
  {
    label: '',
    tabs: [
      // {
      //   label: 'Enterprise feeds',
      //   icon: 'notification',
      //   disable: true,
      // },
      // {
      //   label: 'My tasks',
      //   icon: 'task',
      //   disable: true,
      // },
    ],
  },
];

function mapPageBlock2LeftMenuItem(page, pageList) {
  return {
    nodeId: page.blockEid,
    blockEid: page.blockEid,
    blockType: page.blockType,
    label: get(page, 'properties.title', 'Untitled'),
    icon: get(page, 'properties.icon'),
    locked: get(page, 'properties.locked', false),
    type: 'page',
    tabs: Object.values(pageList)
      .filter((sp: any) => sp.parentId === page.presetEid)
      .map((p) => mapPageBlock2LeftMenuItem(p, pageList)),
  };
}

export function InternalPage(props: { children: JSX.Element }) {
  const { userEmail, onLogout } = React.useContext(OnePageContext);
  const [menuList, setMenuList] = React.useState<TypeTabListItem[]>(hardcodeDataMenu as TypeTabListItem[]);
  const [publicPages, setPublicPages] = React.useState([]);
  const isFullPage = useFullPage();
  const history = useHistory();
  const {
    pageList: _pageList,
    systemPageId,
    currentPage,
    currentDirectory,
    createSystemPage,
    formulaTitle,
  } = usePage();
  const [breadcrumb, setBreadcrumb] = React.useState([]);
  const version = useVersion({ value: '2.25.53.62', dateTime: '2022-05-11T18:39:00+07:00' });
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q } = queryParams;

  React.useEffect(() => {
    if (isAllow()) {
      setMenuList((menuList) => {
        return menuList.concat(eosMenu, {
          label: 'System pages',
          nodeId: 'system_pages',
          tabs: [],
        });
      });
    }
  }, []);

  React.useEffect(() => {
    const pageList = Object.values(_pageList).filter((i) => {
      if (
        [EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.intraday, EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.marketerCommunity].indexOf(
          i.blockEid,
        ) > -1 &&
        !isAllow()
      ) {
        return false;
      }
      return true;
    });
    let publicPages = [];
    let i = menuList.findIndex((item) => item.nodeId === 'marketplace_advertising');
    let codedMenuItems;
    if (!ff.ui_page_keyword_analysis) {
      codedMenuItems = menuList[i].tabs || [];
    }

    menuList[i].tabs = sortBy(
      pageList
        .filter((i) => i.parentId === '__parent_id__' && get(i, 'properties.groupId') == 'marketplace_advertising')
        .filter((i) => {
          if (!ff.ui_page_keyword_analysis) {
            return (
              [
                EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.campaignManagement,
                EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.hyperAutomation,
                EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.intraday,
              ].indexOf(i.blockEid) > -1
            );
          } else {
            return true;
          }
        }),
      (i) => {
        switch (i.blockEid) {
          case EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.campaignManagement:
            return 1;
          case EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.hyperAutomation:
            return 2;
          case EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.intraday:
            return 3;
          default:
            return 1000;
        }
      },
    ).map((page) => mapPageBlock2LeftMenuItem(page, pageList));

    if (!ff.ui_page_keyword_analysis) {
      menuList[i].tabs = menuList[i].tabs.concat(codedMenuItems);
    }

    if (ff.lowcode) {
      const iBotep = menuList.findIndex((i) => i.nodeId === EIP_CONSTANT.EIP_SYSTEM_PAGE_GROUP_ID.botep);
      if (iBotep > -1) {
        menuList[iBotep].tabs = [].concat(
          pageList
            .filter(
              (i) =>
                i.parentId === '__parent_id__' &&
                get(i, 'properties.groupId') == EIP_CONSTANT.EIP_SYSTEM_PAGE_GROUP_ID.botep,
            )
            .map((page) => mapPageBlock2LeftMenuItem(page, pageList)),
          menuList[iBotep].tabs.filter((i) => i.type === 'btn_create_system_page'),
        );
      }
    }

    publicPages = publicPages.concat(menuList[i].tabs);

    const systemPages = pageList.filter(
      (p) => p.parentId === '__parent_id__' && !publicPages.some((p1) => p1.nodeId === p.presetEid),
    );
    i = menuList.findIndex((item) => item.nodeId === 'system_pages');
    if (i > -1) {
      menuList[i].tabs = systemPages.map((page) => mapPageBlock2LeftMenuItem(page, pageList));
    }

    setPublicPages(publicPages);
    setMenuList(menuList);
  }, [_pageList]);

  React.useEffect(() => {
    if (currentPage) {
      const title = get(currentPage, ['properties', 'title']);
      setBreadcrumb([
        {
          label:
            title.startsWith('=') && title.includes('uQuery') && !formulaTitle
              ? ''
              : formulaTitle?.replace(/<span.*?>/g, '')?.replace('</span>', '') || title,
          path: getPageUrn('/page/' + currentPage.blockEid),
        },
      ]);
    }
  }, [currentPage, currentDirectory]);

  const menuFooter = React.useMemo(() => {
    const Form = () => (
      <GoogleForm
        embed={
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSfhVpc30xCh0FWR1_hNvODLnGeY74AybAUibugUbzpgSocXGQ/viewform?embedded=true"
            width="100%"
            height="100%"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          >
            Loading…
          </iframe>
        }
      />
    );

    return dataMenuFooter(Form);
  }, []);

  const {
    /* must include */
  } = useStyle();

  const enhanceDataMenuTopBar = React.useMemo(() => {
    if (aim.canAccess('sidebar:admin')) {
      return [
        dataMenuTopBar,
        [
          {
            title: 'View as user',
            popupComponent: ViewAsUser,
            isPopupMenu: true,
            popupProps: {
              maxHeight: 'auto!important',
            },
            onClick: () => {},
          },
          {
            title: 'Reset view',
            isPopupMenu: false,
            disable: !aim.isViewAsUserMode(),
            onClick: () => {
              aim.resetAdminMode(EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);
              window.location.reload();
            },
          },
        ],
        [{ title: 'Logout', onClick: () => onLogout() }],
      ];
    } else {
      return [dataMenuTopBar, [{ title: 'Logout', onClick: () => onLogout() }]];
    }
  }, []);

  if (isFullPage) {
    return <div className={'eip-full-page'}>{props.children}</div>;
  }

  return (
    <AppMenu
      isFullPage={isFullPage}
      userEmail={userEmail}
      dataSideBarHead={dataSideBarHead}
      dataBreadcrumbs={breadcrumb}
      dataListMenu={menuList}
      dataListUser={[]}
      dataMenuTopBar={enhanceDataMenuTopBar}
      dataMenuFooter={menuFooter}
      aboutData={{ updateTime: moment(version.dateTime).fromNow(), version: version.value }}
      dataMenuPage={dataMenuPage(systemPageId)}
      onMenuItemClick={(evt: React.MouseEvent, item) => {
        evt.preventDefault();
        if (item.nodeId && item.type == 'page') {
          if (get(item, ['nodeDetails', 'isFullPage'], 0) === 1) {
            window.location.href = getPageUrn('/page/' + item.nodeId + '?full=1');
          } else {
            history.push(getPageUrn('/page/' + item.nodeId + '/default'));
          }
        } else if (item.nodeId && item.pageId && item.type === 'page_section') {
          let qValue = '';
          if (!q || !history.location.pathname.includes(item.pageId) || (!evt.ctrlKey && !evt.metaKey)) {
            qValue = item.nodeId;
          } else if (!q.split(',').includes(item.nodeId)) {
            qValue = q.split(',').concat(item.nodeId).join(',');
          } else {
            qValue = q
              .split(',')
              .filter((i) => i != item.nodeId)
              .join(',');
          }
          history.push(getPageUrn('/page/' + item.pageId + '/default' + (qValue ? `?q=${qValue}` : '')));
        } else if (item.nodeId && item.pageId && item.sectionId && item.type === 'page_section_view') {
          history.push(
            getPageUrn('/page/' + item.pageId + '/default' + `?q=${item.sectionId}&v=${item.nodeId.split('|')[1]}`),
          );
        } else if (isAllow() && item.type == 'btn_create_system_page') {
          if (ff.next_left_menu) {
            const callback = (blockId) => {
              item.nodeDetails.onClick({
                code: blockId,
                name: `Untitled (${moment().format('YYYY MMM DD')})`,
              });
            };
            createSystemPage(
              `Untitled (${moment().format('YYYY MMM DD')})`,
              EIP_CONSTANT.EIP_SYSTEM_PAGE_GROUP_ID.botep,
              callback,
            );
          } else {
            createSystemPage(
              `Untitled (${moment().format('YYYY MMM DD')})`,
              EIP_CONSTANT.EIP_SYSTEM_PAGE_GROUP_ID.botep,
            );
          }
        }
      }}
    >
      <div className={clsx(isFullPage && 'eip-full-page')}>{props.children}</div>
    </AppMenu>
  );
}

const useStyle = makeStyles({
  '@global': {
    '.eip-full-page': {
      background: '#253645',
      paddingBottom: '1px',
      minHeight: '100vh',
    },
    '.eip-full-page #eip-page-container-wrapper': {
      marginLeft: 0,
      width: '100%',
      paddingTop: 1,
    },
    '.eip-full-page .app-controls': {
      display: 'none!important',
    },
    '.os-win': {
      '& *::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        height: '7px',
      },
      '& *:hover::-webkit-scrollbar': {
        height: '9px',
      },

      '& *::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },

      '& *::-webkit-scrollbar-thumb': {
        backgroundColor: '#dfdfe3df',
        borderRadius: '16px',
        border: '0px solid #f8fafd',
      },
      '& *::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '0px solid #f4f4f4',
      },

      '& *::-webkit-scrollbar:vertical': {
        display: 'none',
      },
    },
  },
});

export const OnePageContext = React.createContext({
  userFullName: 'User Fullname',
  userEmail: 'user@email.com',
  onLogout: () => {
    console.info('logout');
  },
});
