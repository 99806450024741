import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';

export type TSize = 'small' | 'medium' | 'large';
export const breakPointSize = {
  width: {
    small: 288,
    medium: 600,
    large: 904,
  },
  height: {
    small: 216,
    medium: 216,
    large: 448,
  },
};
export const gaugeBreakPointSize = {
  width: {
    small: 288,
    medium: 600,
    large: 904,
  },
  height: {
    small: 212,
    medium: 488,
    large: 448,
  },
};

const useStyles = makeStyles({
  border: {
    border: `1px solid ${colors.border.default}`,
    borderRadius: '12px',
  },
});

const ChartSize = ({
  size,
  isGauge = false,
  children,
  isBorder = false,
}: {
  size: TSize;
  isGauge?: boolean;
  children: React.ReactNode;
  isBorder?: boolean;
}) => {
  const SIZE = {
    small: {
      width: '288px',
      height: '216px',
    },
    medium: {
      width: '600px',
      height: '216px',
    },
    large: {
      width: '904px',
      height: '448px',
    },
  };
  const GAUGE_SIZE = {
    small: {
      width: '288px',
      height: '212px',
    },
    medium: {
      width: '600px',
      height: '488px',
    },
    large: {
      width: '904px',
      height: '448px',
    },
  };

  const classes = useStyles();
  if (isGauge) {
    return (
      <Box width={'100%'} height={'100%'} className={clsx(isBorder && classes.border)}>
        {children}
      </Box>
    );
  }

  return (
    <Box width={'100%'} height={'100%'} className={clsx(isBorder && classes.border)}>
      {children}
    </Box>
  );
};

export default ChartSize;
