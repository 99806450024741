import * as React from 'react';
import { Calendar } from 'react-multi-date-picker';

import { Box, Button, Grid, List, makeStyles, withStyles } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import { CreateCSSProperties } from '@material-ui/styles';

import { ContainerResponsiveContext } from '@eip/next/lib/main';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';

import { calendarStyle } from '../helper';
import { useCalendarInput } from './hooks/use-calendar-input';
import { CalendarOption } from './hooks/use-calendar-input';
import CalendarTimeInput from './calendar-time-input';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles(() => ({
  dropdown: {
    maxWidth: 'unset',
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '3px 0px',
      fontWeight: 500,
      letterSpacing: '0.0125em',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-2px',
      paddingRight: '6px',
    },
    '& .eip1-MuiButton-iconSizeMedium': {},
  },
  dropdownPaper: {
    maxWidth: 'unset',
    padding: '16px',
  },
  calendar: (props) => ({
    ...(calendarStyle as CreateCSSProperties),
    ...(props.isWeekPicker
      ? {
          '& .rmdp-day': {
            ...calendarStyle['& .rmdp-day'],
            '&:not(.rmdp-deactive)': {},
            '& span': {
              borderRadius: '0px',
              top: '0',
              left: '0',
              right: '0',
              bottom: '0',
              '&.selected-one': {
                background: '#0074d9 !important',
                color: '#fff  !important',
                borderRadius: 4,
                '&.start': {
                  borderRadius: '4px 0 0 4px  !important',
                },
                '&.end': {
                  borderRadius: '0 4px 4px 0  !important',
                },
                '&.end.start': {
                  borderRadius: ' 4px !important',
                },
                '&:hover': {
                  background: '#338ED8 !important',
                },
              },
            },
            '&:not(.rmdp-today)': {
              '& span': {
                '&.rmdp-range-child': {
                  color: colors.text.highLight,
                  background: '#EBF6FF',
                  fontWeight: '500',
                  '&:hover': {
                    background: '#CCE3F5 !important',
                    color: `${colors.text.highLight}  !important`,
                  },
                },
              },
            },
          },
        }
      : {}),
  }),
  container: (props) => ({
    width: props.isNotDatePicker ? '500px' : '656px',
    padding: '0',
  }),
  leftCalendar: {
    borderRight: '1px solid #E5E5E5',
    '& .eip1-MuiList-root': {
      marginRight: '16px',
    },
  },
  rightCalendar: {
    marginLeft: '16px !important',
  },
  ActiveButton: {
    background: '#0369C7',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#338ED8',
    },
  },
  defaultButton: {
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
  groupButton: {
    marginTop: '16px',
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '& button + button': {
      marginLeft: '8px',
    },
  },
  calendarPeriod: {
    '& .rmdp-wrapper': {
      borderRadius: '4px',
      '& .only.rmdp-month-picker': {
        width: '350px',
        marginLeft: '15px',
      },
      '& .rmdp-day.rmdp-today': {
        '& .sd': {
          backgroundColor: '#ED5C10',
          borderRadius: '4px !important',
        },
      },
      '& .rmdp-day.rmdp-range.start': {
        borderRadius: '4px 0 0 4px',
        backgroundColor: '#0074d9',
      },
      '& .rmdp-day.rmdp-range': {
        backgroundColor: '#0074d9',
      },
      '& .rmdp-day.rmdp-today.rmdp-range': {
        '& span': {
          backgroundColor: '#0074d9 !important',
          borderRadius: '0 !important',
        },
      },
      '& .rmdp-day.rmdp-today.rmdp-range.end': {
        '& span': {
          backgroundColor: '#0074d9 !important',
          borderRadius: '0 4px 4px 0 !important',
        },
      },
      '& .rmdp-day.rmdp-range.end': {
        borderRadius: '0 4px 4px 0',
        backgroundColor: '#0074d9',
      },
      '& .rmdp-day span:hover': {
        backgroundColor: '#E4E7E9',
        color: 'inherit',
      },
      '& .rmdp-day.start span:hover': {
        backgroundColor: '#338ED8',
      },
      '& .rmdp-day.end span:hover': {
        backgroundColor: '#338ED8',
      },
    },
  },
  calendarConfig: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    paddingRight: '16px',
    borderRight: '1px solid #E5E5E5',
    marginRight: '16px',
  },
}));
const ListItem = withStyles(() => ({
  root: {
    borderRadius: '4px',
    paddingRight: '0px',
    marginBottom: 8,
    paddingLeft: 15,
  },
  button: {
    // backgroundColor: 'red',
    borderRadius: '4px',
    fontStyle: ' normal',
    fontWeight: 400,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
}))(MuiListItem);

type DateValue = {
  dateFrom: string;
  dateTo: string;
};
type Props = {
  value: DateValue;
  defaultFormatReturn?: string;
  formatDateView?: string;
  onChange: (newValue: DateValue, option?: CalendarOption) => void;
  minDate?: string;
  maxDate?: string;
  clickOnSubmit?: boolean;
  groupPeriodConfig?: string;
};
const CalendarInput = ({
  value,
  onChange,
  defaultFormatReturn = 'DD/MM/YYYY',
  formatDateView = 'MMMM DD, YYYY',
  minDate = '',
  maxDate = '',
  clickOnSubmit = false,
  groupPeriodConfig = '',
}: Props) => {
  // Check is mobile view
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const {
    calendarCohort,
    currentValue,
    currentTab,
    open,
    setOpen,
    defaultDate,
    values,
    checkMatchCalendar,
    getDateLabel,
    handleClickTab,
    mapDays,
    titleTableClass,
    handleChangeDate,
    onSubmit,
    onClose,
    handleCloseCalendarCohort,
    calendarTypePicker,
    isDateRangeSelect,
    getTabs,
    shouldShowLabel,
    calendarLabelRef,
    checkedHour,
    setCheckedHour,
    time,
    setTime,
    isDatetimePicker,
  } = useCalendarInput({ value, defaultFormatReturn, formatDateView, clickOnSubmit, onChange });

  const isMonthPicker = groupPeriodConfig === 'monthly' || calendarTypePicker === 'month';
  const isWeekPicker = calendarTypePicker === 'week';
  const isNotDatePicker = isMonthPicker || isWeekPicker;

  const classes = useStyles({ isNotDatePicker, isWeekPicker });

  return (
    <Dropdown
      alignMenu="right"
      iconStart={'calendar'}
      sizeIconStart={'12px'}
      className={classes.dropdown}
      label={shouldShowLabel && <div ref={calendarLabelRef}>{getDateLabel(!clickOnSubmit ? defaultDate : values)}</div>}
      classNamePaper={`${classes.dropdownPaper} eip_elevation_table_${titleTableClass} eip_action_set_date_range`}
      onOpen={checkMatchCalendar}
      onClick={() => setOpen(true)}
      defaultOpen={open}
      closeCalendarCohort={handleCloseCalendarCohort}
      handleOnClosed={() => setOpen(false)}
      tooltip={shouldShowLabel ? '' : getDateLabel(!clickOnSubmit ? defaultDate : values)}
    >
      <MonitorContainer
        component={Grid}
        mId={'calendar-panel'}
        mLabel={'Calendar panel'}
        container
        justifyContent={'flex-end'}
        className={classes.container}
      >
        <Grid container wrap="nowrap" xs={12}>
          {!isWeekPicker ? (
            <MonitorContainer
              mId={'preset-daterange'}
              mLabel={'Preset daterange'}
              component={Grid}
              item
              xs={3}
              className={classes.leftCalendar}
            >
              <List>
                {getTabs().map((tab, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      onClick={handleClickTab(tab.option, tab.onClick)}
                      className={`${currentTab === tab.option ? classes.ActiveButton : classes.defaultButton}`}
                    >
                      {tab.label}
                    </ListItem>
                  </div>
                ))}
              </List>
            </MonitorContainer>
          ) : null}
          <MonitorContainer
            component={Grid}
            item
            xs={isWeekPicker ? 12 : 9}
            className={isNotDatePicker ? classes.calendarPeriod : classes.rightCalendar}
            mId={'daterange-custom'}
            mLabel={'Daterange custom'}
          >
            {isWeekPicker ? (
              <Calendar
                weekPicker
                displayWeekNumbers
                rangeHover
                value={currentValue}
                onChange={handleChangeDate}
                className={classes.calendar}
                weekDays={CALENDAR_WEEKDAYS}
                weekStartDayIndex={1}
                mapDays={mapDays}
                showOtherDays
              />
            ) : isMonthPicker ? (
              <Calendar
                onlyMonthPicker
                range={isDateRangeSelect}
                value={currentValue}
                onChange={handleChangeDate}
                className={classes.calendar}
                mapDays={mapDays}
              />
            ) : (
              <Calendar
                multiple={false}
                range={isDateRangeSelect}
                disableMonthPicker
                disableYearPicker
                value={currentValue}
                onChange={handleChangeDate}
                numberOfMonths={2}
                className={classes.calendar}
                weekDays={CALENDAR_WEEKDAYS}
                weekStartDayIndex={1}
                showOtherDays
                mapDays={mapDays}
              />
            )}
            {isDatetimePicker ? (
              <Box style={{ marginTop: '8px' }}>
                <CalendarTimeInput
                  checkedHour={checkedHour}
                  setCheckedHour={setCheckedHour}
                  time={time}
                  setTime={setTime}
                  values={values}
                />
              </Box>
            ) : null}
          </MonitorContainer>
        </Grid>
        {!clickOnSubmit && (
          <Grid container justifyContent="flex-end" item xs={12} className={classes.groupButton}>
            <Button variant="text" onClick={onClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              Apply
            </Button>
          </Grid>
        )}
      </MonitorContainer>
    </Dropdown>
  );
};

export default CalendarInput;
