import * as React from 'react';
import * as _ from 'lodash';

import { Grid, makeStyles, Dialog, Box, Button } from '@material-ui/core';
import SimplifiedTreemap from '@ep/insight-ui/chartlib/treemap-chart/simplified-treemap';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import TreeMapChart from '@ep/insight-ui/chartlib/treemap-chart/new-treemap-chart';
import Icon from '@ep/insight-ui/icons/Icon';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import { useTreemap } from '@ep/insight-ui/elements/chart/hooks/use-treemap';

import WrapperFormat, { IPropsFormat } from './wrapper-format';

const useStyles = makeStyles(() => ({
  containerChart: {
    padding: '0 5%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  chart: {
    cursor: 'pointer',
  },
  buttonError: {
    display: 'flex',
    columnGap: '8px',
  },
}));

export interface IValueChartFormat {
  data: Array<number>;
}
const HeatmapFormat = (props: IPropsFormat<IValueChartFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value.value', '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');

  const backbone = React.useContext(TableBackboneContext);
  const dateRange = backbone.addon('get.parent.dateRange')();

  const { open, setOpen, stateChart, treemapData, handleReload } = useTreemap({ keyword: value, dateRange, backbone });

  const treemapRender = React.useMemo(() => {
    switch (stateChart) {
      case 'success':
        return (
          <Box className={classes.chart} onClick={() => setOpen(true)}>
            <SimplifiedTreemap data={treemapData.data} height={'37px'} />
          </Box>
        );
      case 'empty':
        return <span>No data available.</span>;
      case 'error':
        return (
          <Box display={'flex'} alignItems={'center'}>
            <Button variant="text" size="small" onClick={handleReload}>
              <span className={classes.buttonError}>
                <Icon type={'reload'} />
                <span>Reload</span>
              </span>
            </Button>
          </Box>
        );
      case 'loading':
        return (
          <Box display={'flex'} alignItems={'center'}>
            <LoadingIcon />
            <span>
              <strong>One moment please ...</strong>
            </span>
          </Box>
        );
      default:
        return <span>No data available.</span>;
    }
  }, [treemapData, stateChart]);

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems={'center'}>
        <Grid item xs={12} className={classes.containerChart}>
          {treemapRender}
        </Grid>
        <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth={false}>
          <TreeMapChart
            keyChart={'tree'}
            data={treemapData.data}
            stateChart={stateChart}
            showTable={() => undefined}
            isSimplified={true}
          />
        </Dialog>
      </Grid>
    </WrapperFormat>
  );
};

export default HeatmapFormat;
