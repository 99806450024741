import * as React from 'react';
import clsx from 'clsx';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  debounce,
  makeStyles,
} from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyle = makeStyles((theme) => ({
  container: {
    '& .jsoneditor': {
      height: '80vh',
    },
  },
  actions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  textError: {
    color: '#D4290D',
  },
}));

export default function PopupJsonEditor(props: {
  title: string;
  open: boolean;
  value: any;
  onValueChange: (value: any) => void;
  onClose: () => void;
  className?: string;
  errorMessage?: string;
  isSubmitting?: boolean;
}) {
  const classes = useStyle();
  const content = React.useRef(props.value);
  const renderedRef = React.useRef(false);

  const renderJsonEditor = React.useMemo(() => {
    if (!renderedRef.current) {
      content.current = props.value;
    }
    return debounce((ref: HTMLElement) => {
      const editor = new window._JSONEditor({
        target: ref,
        props: {
          queryLanguages: Object.values(window._JSONEditorQueryLanguages),
          content: { text: undefined, json: content.current },
          onChange: (updatedContent) => {
            if (updatedContent.json) {
              content.current = updatedContent.json;
            }
          },
        },
      });
      renderedRef.current = true;
    }, 100);
  }, [props.value]);

  React.useEffect(() => {
    if (!props.open) {
      renderedRef.current = false;
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="xl" className={clsx('ep-advance-jsoneditor', props.className)}>
      <DialogTitle disableTypography>
        {props.errorMessage ? <span className={classes.textError}>{props.errorMessage}</span> : null}
        <Typography variant="h6">{props.title}</Typography>
        <Box className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onValueChange(content.current);
            }}
            disabled={props.isSubmitting}
          >
            Submit
          </Button>
          {props.onClose && (
            <IconButton aria-label="close" onClick={props.onClose}>
              <Icon type="close"></Icon>
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <div
            className="jsoneditor"
            ref={(ref) => ref && !renderedRef.current && renderJsonEditor(ref, props.value, props.onValueChange)}
          ></div>
        </React.Suspense>
      </DialogContent>
    </Dialog>
  );
}
