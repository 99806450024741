import * as React from 'react';
import { get, debounce, cloneDeep, uniq, isEmpty, set } from 'lodash';
import moment from 'moment';

import { NodeEditContext } from '@eip/next/lib/main';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';

import {
  INPUT_TYPE_ADDITIONAL_DATA,
  INPUT_TYPE_COMPACT_OPERATOR,
  INPUT_TYPE_COMPACT_SOURCE_FIELD,
  INPUT_TYPE_COMPACT_TARGET_FIELD,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_DISPLAY_AS_COMPACT,
  INPUT_TYPE_DISPLAY_AS_SELECTION,
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_FIELD_ID,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_FILTER_FIELD,
  INPUT_TYPE_MAX_ITEM_SELECTED,
  INPUT_TYPE_SEARCH_HINT,
  INPUT_TYPE_SELECT_FROM_ETABLE,
  INPUT_TYPE_SELECT_FROM_TAG,
  INPUT_TYPE_SYSTEM_FILTERS,
  INPUT_TYPE_VIEW,
} from '../../../../utils/constant';
import { enhancedETableConfig } from '@ep/insight-ui/system/block/etable/migration';
import { useSetAtom } from 'jotai';
import { editorScript } from '../../../../atom/editor-script';
import { DEFAULT_CONFIG } from '@ep/insight-ui/system/block/etable/table-config';
import { v4 as uuid } from 'uuid';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { splitComma } from '@ep/insight-ui/system/helper/functions';
import { calculateValueGetter } from '@ep/insight-ui/sw/etable/service';

export const useETableOptions = ({ configuration, onChange, initialValue, customProps, ...rest }) => {
  const [eTableOptions, setETableOptions] = React.useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const rowData = get(rest, ['rowData'], {});

  const etableConfig = (
    get(configuration, ['field_configuration'], []).find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
  ).value;

  const displayAs = (get(configuration, 'field_configuration', []) || []).find(
    (el) => el.key === INPUT_TYPE_DISPLAY_AS,
  );
  const isSelectionFromETable = get(configuration, 'input_type', '') === INPUT_TYPE_SELECT_FROM_ETABLE && displayAs;

  const isSelectionFromTag =
    get(configuration, 'input_type', '') === INPUT_TYPE_SELECT_FROM_TAG && Boolean(etableConfig?.blockEid);

  const valueField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_FIELD_ID) || {
      value: '',
    }
  ).value;
  const labelField =
    (
      (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_FIELD_LABEL) || {
        value: '',
      }
    ).value || valueField;

  const filterField =
    (
      (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_FILTER_FIELD) || {
        value: '',
      }
    ).value || valueField;

  const viewField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_VIEW) || {
      value: '',
    }
  ).value;

  let maxItemSelected = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_MAX_ITEM_SELECTED) || {
      value: null,
    }
  ).value;

  const searchHint = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_SEARCH_HINT) || {
      value: null,
    }
  ).value;

  maxItemSelected = toValue(maxItemSelected, rowData, true);

  const systemFiltersField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_SYSTEM_FILTERS) || {
      value: [],
    }
  ).value;

  const additionalData = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_ADDITIONAL_DATA) || {
      value: [],
    }
  ).value;

  const viewInfo = get(etableConfig, 'views', []).find(({ id }) => viewField === id) || get(etableConfig, ['view'], {});
  const view = {
    ...get(viewInfo, ['combinator'], {}),
    ...get(viewInfo, ['combinator', 'properties'], {}),
  };
  const blockEid = get(etableConfig, 'blockEid', '');

  delete view.properties;

  const lastUpdated = React.useRef(moment().valueOf());

  const getCustomCellActions = get(customProps, ['getCustomCellActions'], () => undefined);

  const finalConfig = blockEid
    ? enhancedETableConfig({
        customConfig: { ...etableConfig, ...view, view: viewInfo },
        systemConfig: {},
        blockEid: get(etableConfig, 'blockEid', ''),
        lastUpdated,
        setOpenCodeEditor,
        nodeEditContext,
        getCustomCellActions,
      })
    : {
        apiRequest: {},
        configuration: DEFAULT_CONFIG,
        callback: {},
      };

  if (filterField) {
    const prevAddon = get(finalConfig, ['addons', 'datasource.getRowsParams'], null);
    const prevGetTableAddon = get(finalConfig, ['addons', 'datasource.apiRequest.getTableData'], () => null);

    finalConfig.addons = {
      ...finalConfig.addons,
      'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
        const isPageAction = get(rest, ['isPageAction'], false);
        const selectedRows = !isPageAction ? backbone.getSelectedRows() : [];
        const bulkRows = uniq(selectedRows.concat(rowData));
        const systemFilters = bulkRows.map((row) => {
          return (systemFiltersField || [])?.reduce(
            (carry, systemFilter) => {
              const field = systemFilter[INPUT_TYPE_COMPACT_SOURCE_FIELD];
              const operator = systemFilter[INPUT_TYPE_COMPACT_OPERATOR];
              const isFormula = String(field).startsWith('=');
              const value = isFormula ? toValue(field, row) : row[field];
              carry.filters.push({
                field: systemFilter[INPUT_TYPE_COMPACT_TARGET_FIELD],
                operator: operator || 'is',
                value: value,
                dataType: typeof value,
              });
              return carry;
            },
            {
              combinator: 'AND',
              filters: [],
            },
          );
        });
        const fieldOperator = backbone.getConfig('fieldOperator') || 'contains';

        try {
          const prevParams = prevAddon ? prevAddon({ params }, currentConfig, backbone) : params;

          return {
            ...prevParams,
            filter: {
              combinator: 'AND',
              ...prevParams.filter,
              filters: [
                ...(prevParams.filter?.filters || []),
                ...(filterField && !isEmpty(backbone.getConfig('search'))
                  ? [
                      {
                        combinator: fieldOperator.includes('not') ? 'and' : 'or',
                        filters: String(backbone.getConfig('search'))
                          .split('\n')
                          .reduce((carry, search) => {
                            if (splitComma(filterField).length > 1) {
                              filterField.split(',').forEach((i) => {
                                carry.push({
                                  field: i,
                                  operator: fieldOperator,
                                  value: search,
                                  dataType: 'string',
                                });
                              });
                            } else {
                              carry.push({
                                field: filterField,
                                operator: fieldOperator,
                                value: search,
                                dataType: 'string',
                              });
                            }
                            return carry;
                          }, []),
                      },
                    ]
                  : []),
                ...(systemFiltersField?.length > 0
                  ? [
                      {
                        combinator: 'OR',
                        filters: systemFilters,
                      },
                    ]
                  : []),
              ],
            },
            sort: prevParams.sort?.length
              ? params.sort
              : backbone.getConfig('search')
              ? [{ field: `LENGTH({${filterField.split(',')?.[0]}})`, sort: 'ASC' }]
              : [],
          };
        } catch (e) {
          return params;
        }
      },
      'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
        const prevResult = await prevGetTableAddon(params, originRequest, backbone);
        const fieldOperator = backbone.config['fieldOperator'] || 'contains';
        const search = backbone.config['search'] || '';
        const keyAdditionalData = (additionalData || []).find((i) => i.key == '_key' && i.value);

        let isAdded = false;
        search
          .split('\n')
          .map((i) => (i ? String(i).trim() : ''))
          .filter((i) => !!i)
          .forEach((searchInput) => {
            const isMatched = (prevResult?.rows || []).every((row) => row[keyAdditionalData?.value] != searchInput);

            if (
              (additionalData || []).length > 1 &&
              isMatched &&
              searchInput &&
              !fieldOperator?.includes('not') &&
              params?.pagination?.page == 1
            ) {
              isAdded = true;
              const headers = params.attributes.concat(params.metrics);
              const newRow = headers.reduce((carry, header) => {
                const overrideField = additionalData.find((i) => i.key == header);
                if (!overrideField) carry[header] = null;
                else carry[header] = toValue(overrideField.value, { ...rowData, _search: searchInput });
                return carry;
              }, {});
              prevResult.rows.unshift(newRow);
            }
          });

        if (isAdded) {
          const calculatedValueGetter = await calculateValueGetter({
            rows: prevResult.rows,
            columns: prevResult?.eTableContext?.columns,
            groupedFields: get(params, 'groupBy.dimensions', []),
            drillDowns: get(params, 'groupBy.drillDowns', []),
            resourceMetric: [],
            formulaUpstream: null,
            config: backbone.config,
          });
          set(prevResult, ['data', 'rows'], calculatedValueGetter);
        }

        return prevResult;
      },
    };
  }

  const selectionFinalConfig = React.useMemo(() => {
    if (isSelectionFromETable || isSelectionFromTag) {
      const clone = cloneDeep(finalConfig);
      clone.configuration.defaultPagination = {
        limit: 100,
      };
      clone.configuration.tableType = 'compact';

      return clone;
    }
    return finalConfig;
  }, [isSelectionFromETable, isSelectionFromTag]);

  const compactFinalConfig = React.useMemo(() => {
    const clone = cloneDeep(finalConfig);

    clone.configuration.tableType = 'compact';
    if (clone.configuration.system?.hiddenComponents) {
      clone.configuration.system.hiddenComponents = clone.configuration.system?.hiddenComponents.filter(
        (el) => el !== 'search',
      );
    }
    clone.configuration.searchHint = searchHint || 'Search...';
    clone.configuration.blockEid = clone.configuration.blockEid + uuid();
    clone.configuration.tableId = clone.configuration.blockEid;
    clone.configuration.rowSelection = maxItemSelected == 1 ? 'single' : 'multiple';
    clone.configuration.maxItemSelected = maxItemSelected;

    return clone;
  }, [finalConfig]);

  const backbone = isSelectionFromETable || isSelectionFromTag ? useTableBackbone(selectionFinalConfig) : null;

  const refreshData = React.useCallback(
    debounce(
      () => {
        setLoading(true);
        backbone
          .getRows()
          .then((value) => {
            const result = value.map((el) => {
              return {
                label: el[labelField],
                value: el[valueField],
              };
            });

            if (displayAs?.value === INPUT_TYPE_DISPLAY_AS_SELECTION || isSelectionFromTag) {
              setETableOptions(result);
            } else if (displayAs?.value === INPUT_TYPE_DISPLAY_AS_COMPACT) {
              const selected = value.filter((el) => {
                return [].concat(initialValue).some((ele) => el[valueField] == ele);
              });
              handleOnChange(selected);
            }
          })
          .catch((err) => {
            console.log('Get row fail', err);
          })
          .finally(() => {
            setLoading(false);
          });
      },
      50,
      { trailing: true },
    ),
    [],
  );

  React.useEffect(() => {
    if (backbone) {
      backbone.setGridApi({
        grid: {
          paginationSetPageSize: (limit) => undefined,
          refreshServerSideStore: refreshData,
          deselectAll: () => undefined,
        },
        column: {},
      });
      window.setTimeout(() => {
        window.requestAnimationFrame(() => {
          refreshData();
        });
      }, 100 /* cool down time*/);
    }
  }, []);

  const handleOnChange = (value) => {
    setSelectedRows(value);
    onChange(value.map((el) => el[valueField]));
  };

  return {
    eTableOptions,
    compactFinalConfig,
    isSelectionFromETable,
    isSelectionFromTag,
    valueField,
    labelField,
    selectedRows,
    handleOnChange,
    loading,
  };
};
