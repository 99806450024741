import * as React from 'react';
import { v4 as uuid } from 'uuid';

import { get } from 'lodash';
import { CONDITION_FORMAT, PERSONALIZATION } from '@ep/insight-ui/system/helper/constant';

export const useConditionFormat = ({ backbone, props }) => {
  const [formatItems, setFormatItems] = React.useState(
    get(backbone.getConfig(PERSONALIZATION, {}), [CONDITION_FORMAT, props.field], []),
  );
  const payload = get(props, ['payload'], []);

  const title = (payload.find(({ key }) => key === 'title') || { value: 'Formats' }).value;

  const handleAddFormat = () => {
    setFormatItems([
      ...formatItems,
      {
        fakeId: uuid(),
        filters: [],
        styles: [
          {
            key: 'label',
            value: '',
          },
          {
            key: 'cell_highlight',
            value: '',
          },
          {
            key: 'bold',
            value: false,
          },
          {
            key: 'italic',
            value: false,
          },
          {
            key: 'underline',
            value: false,
          },
        ],
        expanded: true,
        title: '',
      },
    ]);
  };

  const handleUpdateFormatItem = (item) => {
    setFormatItems(formatItems.map((formatItem) => (formatItem.fakeId === item.fakeId ? item : formatItem)));
  };

  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const handleDeleteFormatItem = async (item) => {
    setFormatItems(formatItems.filter((i) => item.fakeId !== i.fakeId));
  };

  const handleDuplicateFormatItem = (item) => {
    setFormatItems([...formatItems, { ...item, fakeId: uuid(), expanded: true }]);
  };

  const handleSubmit = () => {
    const personalization = backbone.getConfig(PERSONALIZATION, {});
    const conditionFormat = get(personalization, [CONDITION_FORMAT], {});
    backbone.changeConfig(PERSONALIZATION, {
      ...personalization,
      [CONDITION_FORMAT]: {
        ...conditionFormat,
        [props.field]: formatItems,
      },
    });
    props.setAnchorEl(null);
  };

  const rowValue = get(props, ['data', 'value'], {});
  const previewText = get(rowValue, ['label'], get(rowValue, ['value'], get(rowValue, ['id'], '')));
  const previewWidth = (
    get(backbone, ['config', 'columnWidth'], []).find((el) => el.columnField === props.field) || { width: 150 }
  ).width;

  return {
    title,
    formatItems,
    setFormatItems,
    handleAddFormat,
    handleClose,
    handleSubmit,
    handleUpdateFormatItem,
    handleDeleteFormatItem,
    handleDuplicateFormatItem,
    previewText,
    previewWidth,
  };
};
