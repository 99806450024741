import * as React from 'react';
import { useLog } from '@eip/next/lib/main';

const log = useLog('lib:workflow-backbone');

export const WorkflowContext = React.createContext<Workflow>({
  data: null,
  on: null,
  screen: () => null,
  submit: null,
  utilFunctions: null,
  _getValue: null,
  _screenBag: null,
  addScreen: null,
  init: null,
  getScreenIds: null,
  emit: null,
  removeListener: null,
  getAllData: () => null,
});

export const useWorkflow = (): Workflow => {
  return React.useContext(WorkflowContext);
};

export const useWorkflowScreen = (screenName: string): WorkflowScreen<unknown> => {
  const workflow = React.useContext(WorkflowContext);
  const screen = workflow ? workflow.screen(screenName) : null;

  log('get workflow screen', { screenName, workflow });

  return screen;
};

export const WorkflowLightContext = React.createContext(null);
