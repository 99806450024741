import moment from 'moment';
import * as _ from 'lodash';
import { noSelectStyled } from '../lib/epsilo-theme';
import { IChartRowData, IOption, IScatterChartRowData, IWaterfallChartRowData } from './type';
import { LineCase } from './line-chart/line-chart-fullsize/line-chart-intraday';
import { get } from 'lodash';

export const HIDDEN_LABEL_TEXT = '__disabled__';

export const randomInt = (min: number, max: number) => {
  return Math.round(min + Math.random() * (max - min));
};
const getDaysArray = (year: string, month: string): Array<moment.Moment> => {
  const monthDate = moment(year + '-' + month, 'YYYY-MM');
  let daysInMonth = monthDate.daysInMonth();
  const arrDays = [];
  while (daysInMonth) {
    const current = moment(monthDate).date(daysInMonth);
    arrDays.push(current);
    daysInMonth--;
  }
  return arrDays.reverse();
};
export const generalLabels = ({
  year,
  month,
  DateFormatView = 'D MMM',
}: {
  year: string;
  month: string;
  DateFormatView?: string;
}) => getDaysArray(year, month).map((item) => item.format(DateFormatView));

export const generateRandomData = ({
  year,
  month,
  DateFormatView = 'D MMM',
  minValue = 50000,
  maxValue = 100000,
}: {
  year: string;
  month: string;
  minValue: number;
  maxValue: number;
  DateFormatView?: string;
}): IChartRowData[] =>
  getDaysArray(year, month).map((item) => {
    return {
      date: item.format(DateFormatView),
      value: randomInt(minValue, maxValue),
    };
  });

export const generateRandomWaterfallData = ({
  year,
  month,
  DateFormatView = 'D MMM',
  minValue = 50000,
  maxValue = 100000,
}: {
  year: string;
  month: string;
  minValue: number;
  maxValue: number;
  DateFormatView?: string;
}): IWaterfallChartRowData[] =>
  getDaysArray(year, month).map((item) => {
    return {
      date: item.format(DateFormatView),
      value: randomInt(minValue, maxValue),
      type: randomInt(0, 1) === 0 ? 'increase' : 'decrease',
    };
  });

export const generateRandomScatterData = ({
  year,
  month,
  DateFormatView = 'D MMM',
  minValue = 50000,
  maxValue = 100000,
}: {
  year: string;
  month: string;
  minValue: number;
  maxValue: number;
  DateFormatView?: string;
}): IScatterChartRowData[] =>
  getDaysArray(year, month).map((item) => {
    return {
      date: item.format(DateFormatView),
      value: {
        x: randomInt(minValue, maxValue),
        y: randomInt(minValue, maxValue),
      },
    };
  });

const transparent = {
  '50%': '80',
  '40%': '66',
  '30%': '4d',
  '25%': '40',
  '20%': '33',
  '10%': '1a',
};

export const convertColor = (color: string) => {
  if (!color) return;

  // change opacity 100% => 30%
  if (color.length === 7) {
    return (color += transparent['30%']);
  }
  if (color.length === 9) {
    const opacity = color.slice(-2);
    const colorWithoutOpacity = color.slice(0, -2);

    // change opacity [50%, 40%,] => 10%
    if (['80', '66'].includes(opacity)) {
      return colorWithoutOpacity + transparent['10%'];
    }
    // change opacity 40% => 20%
    // if (opacity === '66') {
    //   return colorWithoutOpacity + '33';
    // }
  }
};

// stroke: 100%
// fill: 50%
// fillTrans: 50% (opacity)
// pointBorderColor: 40% (opacity)
// segment: 25%
// segmentTran: 25% (opacity)
interface IColorChart {
  [key: string]: {
    stroke: string;
    fill: string;
    fillTrans: string;
    hover: string;
    pointBorderColor: string;
    segment: string;
    segmentTrans: string;
  };
}
export const colorsChartLegend: IColorChart = {
  0: {
    stroke: '#204D77',
    fill: '#8fa6bb',
    fillTrans: '#204D7780',
    hover: '#132e47',
    pointBorderColor: '#204D7766',
    segment: '#c7d2dd',
    segmentTrans: '#204D7740',
  },
  1: {
    stroke: '#F17D40',
    fill: '#f8be9f',
    fillTrans: '#F17D4080',
    hover: '#904b26',
    pointBorderColor: '#F17D4066',
    segment: '#fbdecf',
    segmentTrans: '#F17D4040',
  },
  2: {
    stroke: '#338ED8',
    fill: '#99c6eb',
    fillTrans: '#338ED880',
    hover: '#1e5581',
    pointBorderColor: '#338ED866',
    segment: '#cce2f5',
    segmentTrans: '#338ED840',
  },
  3: {
    stroke: '#3CBB93',
    fill: '#9dddc9',
    fillTrans: '#3CBB9380',
    hover: '#247058',
    pointBorderColor: '#3CBB9366',
    segment: '#ceeee4',
    segmentTrans: '#3CBB9340',
  },
  4: {
    stroke: '#E4553E',
    fill: '#f1aa9e',
    fillTrans: '#E4553E80',
    hover: '#883325',
    pointBorderColor: '#E4553E66',
    segment: '#f8d4ce',
    segmentTrans: '#E4553E40',
  },
  5: {
    stroke: '#F0AD4E',
    fill: '#f7d6a6',
    fillTrans: '#F0AD4E80',
    hover: '#90672e',
    pointBorderColor: '#F0AD4E66',
    segment: '#fbead2',
    segmentTrans: '#F0AD4E40',
  },
  6: {
    stroke: '#5BC0DE',
    fill: '#addfee',
    fillTrans: '#5BC0DE80',
    hover: '#367385',
    pointBorderColor: '#5BC0DE66',
    segment: '#d6eff6',
    segmentTrans: '#5BC0DE40',
  },
  7: {
    stroke: '#E5EA49',
    fill: '#ECEEA2',
    fillTrans: '#E6EA65',
    hover: '#E5EA49',
    pointBorderColor: '#DEE352',
    segment: '#EAECA3',
    segmentTrans: '#E5EA49',
  },
  8: {
    stroke: '#DF5CDC',
    fill: '#F3AAF2',
    fillTrans: '#E460E2',
    hover: '#AB3FA9',
    pointBorderColor: '#E965E7',
    segment: '#F4D4F3',
    segmentTrans: '#E75CE5',
  },
  9: {
    stroke: '#1CA217',
    fill: '#A0CD9E',
    fillTrans: '#3FB73B',
    hover: '#168413',
    pointBorderColor: '#1CA217',
    segment: '#AAE6A8',
    segmentTrans: '#1CA217',
  },
};

export const colorsWaterfallChartLegend: IColorChart = {
  0: colorsChartLegend[3],
  1: colorsChartLegend[4],
};

export const PopLegend = {
  display: 'none',
  position: 'absolute',
  color: '#253746',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  fontSize: '12px',
  pointerEvents: 'none',
  transition: 'all .1s ease',
  borderRadius: '4px',
  width: '150px',
  padding: '13px 10px',
  backgroundColor: '#fff',
  margin: 0,
  transform: 'translate(-50%, 50%)',
  justifyContent: 'space-between',
  background: '#FFFFFF',
  boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
  '@media (max-width:767px)': {
    right: '5rem',
  },
  '& .contentWrapp': {
    display: 'flex',
    '& .leftContent': {
      fontWeight: '500',
      marginRight: '5px',
    },
  },
  '& .label': {
    color: '#8C98A4',
  },
  '& .content': {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'center',
    width: '100%',
    '& .label': {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '12px',
      color: '#8C98A4',
    },
    '& .valueDiv': {
      display: 'flex',
      flex: 1,
      flexShrink: 0,
      width: '100%',
      justifyContent: 'flex-end',
    },
    '& .value': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '12px',
      textAlign: 'right',
      color: '#253746',
      marginRight: 5,
    },
    '& .percent': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '10px',
      lineHeight: '12px',
      textAlign: 'right',
      color: '#253746',
    },
  },
};

export const tableTooltip = {
  padding: '6px 8px',

  backgroundColor: '#fff',
  boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
  borderRadius: '4px',
  color: '#000',
  '& .headerRow': {
    background: '#FAFCFF',
    // background: 'red',
    '& th': {
      padding: '4px 2px',
      width: '100%',
      textAlign: 'left',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      color: '#253746',
    },
  },
  '& .legendRow': {
    backgroundColor: 'inherit',
    borderWidth: 0,
    '&:last-of-type': {
      '& .legendLine': {
        marginBottom: '0px',
      },
    },
  },
  '& .legendLine': {
    minWidth: '190px',
    padding: '0px',
    borderWidth: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',
    marginBottom: '2px',

    '& .legendIcon': {
      borderWidth: '2px',
      margin: '4px 8px 4px 6px',
      height: '8px',
      width: '8px',
      display: 'inline-block',
      borderRadius: '2px',
    },
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    '& .content': {
      flex: 1,
      flexShrink: 0,
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      '& .label': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        color: '#8C98A4',
        marginRight: '10px',
      },
      '& .value': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'right',
        color: '#253746',
        flex: 1,
        flexShrink: 0,
        marginRight: 2,
      },
      '& .percent': {
        fontWeight: 400,
        fontSize: '10px',
        lineHeight: '12px',
        textAlign: 'right',
        color: '#253746',
      },
    },
  },
};

export const legendLine = {
  margin: 0,
  padding: 0,
  color: '#253746',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 400,
  fontSize: '14px',
  '@media (max-width:767px)': {
    top: 0,
  },
  '& .legendItem': {
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    padding: '4px 8px',
    margin: '5px 0',
    transition: 'all .3s ease',
    alignItems: 'center',
    '& .textContainer': {
      ...noSelectStyled,
    },
    '& p': {
      pointerEvents: 'none',
      padding: 0,
      margin: 0,
      lineHeight: '20px',
      textDecoration: 'none !important',
    },
  },
  '& .legendItem__checkbox': {
    pointerEvents: 'none',
    width: '12px',
    height: '12px',
    borderRadius: '2px',
    backgroundColor: 'transparent',
    border: '2px solid #C2C7CB',
    marginRight: '8px',
    '&.active': {
      border: 'none',
      position: 'relative',
      '& .checked': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        '&.after': {
          backgroundColor: 'white',
          borderRadius: '5px',
          width: '4px',
          top: '50%',
          left: '50%',
          height: '1.75px',
          transform: 'translate(calc(-50% - 1.25px), calc(-50% + 0.75px)) rotate(45deg)',
        },
        '&.before': {
          borderColor: 'white',
          backgroundColor: 'white',
          borderRadius: '5px',
          width: '6.5px',
          top: '50%',
          left: '50%',
          height: '1.75px',
          transform: 'translate(calc(-50% + 0.8px), calc(-50% + 0px)) rotate(-50deg)',
        },
      },
    },
  },
  '& .legendItem:hover': {
    background: '#EBF6FF',
    borderRadius: '5px',
    textAlign: 'center',
  },
  '& .legendItem.active': {
    background: '#EBF6FF',
  },
};

type IChartOptions = {
  AREA: IOption;
  STACKED_AREA: IOption;
  DOT: IOption;
  COLUMN: IOption;
  STACKED_COLUMN: IOption;
  LINE: IOption;
  PIE: IOption;
};

export const defaultOptions: IChartOptions = {
  AREA: {
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'nearest',
        position: 'nearest',
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        display: false,
      },
    },
    radius: 10,
    interaction: {
      intersect: false,
      mode: 'nearest',
    },
    scales: {
      xAxes: {
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month',
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
      },
    },
    elements: {
      point: {
        pointStyle: 'circle',
        pointRadius: 8,
        hoverBorderWidth: 16,
        radius: 100,
        pointHoverRadius: 8,
      },
    },
  },
  STACKED_AREA: {
    isCrisper: true,
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'index',
        position: 'nearest',
      },
      hover: {
        mode: 'nearest',
        intersect: true,
      },
      legend: {
        display: false,
      },
    },
    radius: 10,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      xAxes: {
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month',
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        stacked: true,

        display: true,
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
      },
    },
    elements: {
      point: {
        pointStyle: 'circle',
        pointRadius: 0,
        hoverBorderWidth: 16,
        radius: 100,
        pointHoverRadius: 8,
      },
    },
  },
  DOT: {
    isCrisper: true,
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'index',
        position: 'nearest',
      },
      legend: {
        display: false,
      },
    },
    fill: false,
    // radius: 5,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      xAxes: {
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        display: true,
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
      },
    },
    elements: {
      point: {
        pointStyle: 'circle',
        pointRadius: 8,
        borderWidth: 4,
        hoverBorderWidth: 16,
        radius: 100,
        pointHoverRadius: 8,
      },
    },
  },
  STACKED_COLUMN: {
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    barPercentage: 0.79,
    scales: {
      xAxes: {
        stacked: true,
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month',
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        stacked: true,
        display: true,
        suggestedMin: 0,
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
        grid: {
          display: false,
          drawBorder: true,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'index',
        position: 'nearest',
      },
    },
    radius: 1,
    interaction: {
      intersect: false,
      mode: 'index',
    },
  },
  COLUMN: {
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    scales: {
      xAxes: {
        display: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        scaleLabel: {
          display: true,
          labelString: 'Month',
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        display: true,
        suggestedMin: 0,
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'nearest',
        position: 'nearest',
      },
    },
    radius: 10,
  },
  LINE: {
    isCrisper: true,
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'index',
        position: 'nearest',
      },
      legend: {
        display: false,
      },
    },
    fill: false,
    radius: 10,
    interaction: {
      intersect: false,
      mode: 'index',
    },
    scales: {
      x: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
        },
      },
      yAxes: {
        display: false,
        title: {
          display: false,
        },
        ticks: {
          fontSize: 14,
          fontWeight: 400,
          color: '#253746',
          callback: (value) => undefined,
        },
        grid: {
          display: false,
          drawBorder: true,
        },
      },
    },
    // elements: {
    //   point: {
    //     pointStyle: 'circle',
    //     pointRadius: 0,
    //     hoverBorderWidth: 16,
    //     radius: 100,
    //     pointHoverRadius: 8,
    //   },
    // },
    layout: {
      padding: {},
    },
  },
  PIE: {
    responsive: true,
    maintainAspectRatio: false,
    animation: 300,
    plugins: {
      filler: {
        propagate: false,
      },
      tooltip: {
        callbacks: {},
        enabled: false,
        mode: 'index',
        position: 'nearest',
      },
      legend: {
        display: false,
      },
    },
    scales: {
      xAxes: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
      },
      yAxes: {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        title: {
          display: false,
        },
      },
    },
    layout: {
      padding: 10,
    },
  },
};

interface ITooltipParams {
  currency?: string;
  lineCase?: LineCase;
}

export const tool = {
  callbacks: {
    callback: (value) => {
      return value;
    },
  },
  lineCallbacks: {
    label:
      ({ isCohort, formatTime }) =>
      (context) => {
        const date = context.dataset.dateList[context.dataIndex];
        const label = context.dataset.label || '';
        const axisData = get(context, ['dataset', 'axisData'], []);
        const labelDate = `${label} ${moment(date).format(formatTime)}`;
        let backgroundColor = context.dataset.borderColor;
        if (Array.isArray(backgroundColor)) {
          backgroundColor = backgroundColor[context.dataIndex] || backgroundColor;
        }
        return {
          label: isCohort ? labelDate : label,
          value: context.parsed.y,
          backgroundColor,
          axisData: axisData[context.dataIndex],
          currency: context.dataset.currency,
          formatLabel: context.dataset.formatLabel,
          valueLabel: get(context, ['dataset', 'dataLabel', context.dataIndex], ''),
          config: context.dataset.config,
        };
      },
  },
  lineCallbacksV2: {
    label:
      ({ currency, lineCase }: ITooltipParams) =>
      (context) => {
        const date = context.dataset.dateList[context.dataIndex];
        const hasTime = (date || '').split(' ')[1];
        const label = lineCase !== LineCase.TODAY ? moment(date).format(hasTime ? 'YYYY-MM-DD HH:mm' : 'MMMDD') : date;
        const backgroundColor = context.dataset.borderColor || '';
        return { label, value: context.parsed.y, backgroundColor, currency, lineCase };
      },
  },
  areaCallbacks: {
    label:
      ({ currency }: ITooltipParams) =>
      (context) => {
        const label = context.dataset.label || '';
        const backgroundColor = colorsChartLegend[context.datasetIndex].stroke;
        return { label, value: context.parsed.y, backgroundColor, currency };
      },
  },
  dotCallbacks: {
    label:
      ({ currency }: ITooltipParams) =>
      (context) => {
        const label = context.dataset.label || '';
        const backgroundColor = colorsChartLegend[context.datasetIndex].stroke;
        return { label, value: context.parsed.y, backgroundColor, currency };
      },
  },
  columeCallbacks: {
    label:
      ({ chartType }) =>
      (context) => {
        const isBullet = _.get(context, ['chart', 'options', 'indexAxis'], '') === 'y';
        const value = isBullet
          ? context.parsed.x
          : chartType === 'waterfall'
          ? context.raw[1] - context.raw[0]
          : context.parsed.y;

        const label = _.get(context, ['dataset', 'label'], '');
        const type = _.get(context, ['dataset', 'type'], '');
        const axisData = get(context, ['dataset', 'axisData'], []);
        const textKey = _.get(context, ['dataset', 'textKey'], '');
        const colorTooltip = _.get(context, ['dataset', 'colorTooltip'], '');
        const raw = _.get(context, ['raw'], 0);

        // Calculation total
        const datasets = _.get(context, ['chart', 'data', 'datasets'], []).filter(({ type }) => type !== 'line');
        const totalValue = _.sum(
          datasets.map((data) => {
            return _.sum(chartType === 'waterfall' ? data.data.map((el) => el?.[1] - el?.[0]) : data.data);
          }),
        );

        return {
          label,
          textKey,
          axisData: axisData[context.dataIndex],
          value,
          totalValue,
          isBullet,
          colorTooltip,
          specialKey: raw !== 0,
          currency: context.dataset.currency,
          formatLabel: context.dataset.formatLabel,
          type,
        };
      },
  },
  pieCallbacks: {
    label:
      ({ currency }: ITooltipParams) =>
      (context) => {
        const label = _.get(context, ['label'], '');
        const backgroundColor = colorsChartLegend[context.datasetIndex].stroke;
        const data = _.get(context, ['dataset', 'data'], []);

        const totalValue = _.sum(data);
        return {
          label,
          value: context.parsed,
          backgroundColor,
          totalValue,
          currency,
          formatLabel: context.dataset.formatLabel,
        };
      },
  },
  PIE: {
    datalabels: {
      formatter: (value, ctx) => {
        let sum = 0;
        const dataArr = ctx.chart.data.datasets[0].data;
        dataArr.map((data) => {
          sum += data;
        });
        const percentage = ((value * 100) / sum).toFixed(0) + '%';
        return percentage;
      },
      font: {
        weight: '500',
        size: 18,
        lineHeight: '20px',
      },
      color: '#FFFFFF',
    },
  },
};
