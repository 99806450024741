import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent } from '@material-ui/core';
import Loading from './loading.gif';
// import { CircleSpinner } from '../CircleSpinner/CircleSpinner';

const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    padding: '0 !important',
    display: 'flex',
  },
}));

export interface Loading1UIProps {
  loading?: boolean;
}

const shown = false;
export const LoadingUI = ({ loading }: Loading1UIProps) => {
  const classes = useStyles();
  return loading ? (
    <Dialog
      open={Boolean(loading)}
      PaperProps={{
        style: {
          borderRadius: '50%',
        },
      }}
    >
      <DialogContent classes={{ root: classes.dialogContent }}>
        <img src={Loading} style={{ width: '32px', height: '32px' }} />
      </DialogContent>
    </Dialog>
  ) : null;
};
