import { Box, makeStyles, Tooltip } from '@material-ui/core';
import { first, get, isArray, last, uniqBy } from 'lodash';
import * as React from 'react';
import WrapperFormat from './wrapper-format';

import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { useAtomValue } from 'jotai';
import { isNumber, isString } from 'lodash';
import { ePerf } from '@ep/insight-ui/sw/util/dev';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import LoadingComponent from '../../loading/loading-component';

const useStyles = makeStyles({
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    textAlign: 'right',
    '&.type': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#204d77',
      fontWeight: 'normal',
    },
    '&.value': {
      fontSize: '14px',
      color: '#253746',
      fontWeight: '500',
    },
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
});
interface IValueAggregateCell {
  node: any;
  label?: string;
  value?: string;
}

function AggregateCellFormat(props: IValueAggregateCell) {
  const classes = useStyles();
  const valueGrouping = get(props, 'value', null);
  const dataType = get(props, 'dataType', '');
  const typeTable = get(props, 'typeTable', '');
  const node = get(props, 'node', null);
  const value = get(props, 'value', null);
  const loaded = get(props, ['value', 'loaded'], null);
  const column = get(props, 'column', null);
  const setColumnAggregate = get(props, 'setColumnAggregate', () => undefined);
  const aggregations = get(props, 'aggregations');
  const funcValue1 = React.useMemo(() => {
    const valueGetter = get(column, ['cell', 'valueGetter'], {});
    return get(aggregations, [valueGetter.value, 'func'], get(aggregations, [valueGetter.id, 'func'], ''));
  }, [aggregations, column]);

  const funcValue = value['aggregate.function'] ? value['aggregate.function'] : funcValue1;

  const backbone = React.useContext(TableBackboneContext);
  const mapping = backbone.getConfig('mapping');
  const isSecondaryMetric = get(mapping, [column.colId, 'staticValue', 'isSecondaryMetric'], 'no') == 'yes';
  const isSupportAggregation = backbone.getConfig('isSupportGroupAggregation', true);
  const data = get(props, ['node', 'data'], {});
  const colId = column?.colId;
  const labelFormula = get(backbone.getConfig('mapping'), [colId, 'valueGetter', 'label'], '');

  const aggByType = useAtomValue(eTableAtom.groupBy.aggregationByType);
  const aggByFunc = useAtomValue(eTableAtom.groupBy.aggregationByFunc);

  const subActions = React.useMemo(() => {
    return [].concat(aggByType[dataType] || []).map((atc) => {
      if (!atc.onSubmit) {
        return {
          ...atc,
          value: atc.id,
          name: atc.selectLabel,
          onSubmit: () => {
            setColumnAggregate(column.colId, atc.id);
          },
        };
      }
      return atc;
    });
  }, [dataType]);

  const functionType = React.useMemo(() => {
    if (!column || !aggregations || !funcValue) return 'Unique';
    return get(
      subActions.find((it) => String(it.id).toLowerCase() == String(funcValue).toLowerCase()),
      'label',
      'Unique',
    );
  }, [column, subActions, funcValue]);

  const cellAction = React.useMemo(() => {
    if (column.colId?.startsWith('_pivot')) return [];
    return get(props, 'cellAction', []).concat([
      {
        name: 'Calculate now',
        icon: 'calculate',
        subActions,
      },
    ]);
  }, [props, subActions]);

  const label = React.useMemo(() => {
    if (!valueGrouping && !funcValue) return 'COUNT_UNIQUE';

    // const val = get(valueGrouping, 'label', get(valueGrouping, 'value', null));
    const val = get(valueGrouping, 'value', null);

    if (val === null) return '';
    let valNumber;
    if (isNumber(val)) {
      valNumber = Number(val);
    }

    if (isSecondaryMetric && String(funcValue).toLowerCase() == 'none') {
      return String(labelFormula).startsWith('=')
        ? toValue(labelFormula, {
            ...data,
            ...get(data, ['eData', column.colId]),
            value: val,
            currency: '',
            'storefront.currency': '',
          }).trim()
        : `${formatCurrencyNumber(valNumber)}`;
    }

    switch (String(funcValue).toUpperCase()) {
      case aggByFunc.PERCENT_EMPTY.id:
      case aggByFunc.PERCENT_NOT_EMPTY.id: {
        return `${formatCurrencyNumber(valNumber, '%')}%`;
      }
      case aggByFunc.SUM.id:
      case aggByFunc.AVG.id:
      case aggByFunc.MEDIAN.id:
      case aggByFunc.MAX.id:
      case aggByFunc.MIN.id: {
        return String(labelFormula).startsWith('=')
          ? toValue(labelFormula, {
              ...data,
              ...get(data, ['eData', column.colId]),
              value: val,
              currency: '',
              'storefront.currency': '',
            }).trim()
          : `${formatCurrencyNumber(valNumber)}`;
      }
      case aggByFunc.RANGE.id:
      case aggByFunc.LIST.id: {
        try {
          return valNumber
            ? toValue(labelFormula, {
                ...data,
                ...get(data, ['eData', column.colId]),
                currency: '',
                'storefront.currency': '',
                value: valNumber,
              }).trim()
            : JSON.parse(val.replaceAll('\n', '\\n').replaceAll('\t', '\\t')).map((v) => {
                return String(labelFormula).startsWith('=')
                  ? toValue(labelFormula, {
                      ...data,
                      ...get(data, ['eData', column.colId]),
                      currency: '',
                      'storefront.currency': '',
                      value: v,
                    }).trim()
                  : v;
              });
        } catch (e) {
          return val;
        }
      }
      default:
        return valNumber;
    }
  }, [valueGrouping, funcValue, node]);

  const tooltip = React.useMemo(() => {
    const groupTooltip = get(valueGrouping, 'aggregate.tooltip', null);
    if (groupTooltip)
      return (
        <div>
          {String(groupTooltip)
            .split('\n')
            .map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {i}
                  <br />
                </React.Fragment>
              );
            })}
        </div>
      );
    return (
      <div>
        {funcValue === aggByFunc.LIST.id && <div style={{ marginBottom: '0.3em' }}>{label.length} item(s)</div>}
        {isArray(label)
          ? label.map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {i}
                  <br />
                </React.Fragment>
              );
            })
          : label}
      </div>
    );
  }, [valueGrouping, funcValue, label]);

  return (
    <LoadingComponent loading={isSecondaryMetric && !loaded && String(funcValue).toLowerCase() == 'none'}>
      <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value}>
        <Box className={`${classes.text} value`} width={'100%'}>
          {label !== null && label !== '' && isSupportAggregation ? (
            <Tooltip
              title={tooltip}
              classes={{ tooltip: classes.tooltip }}
              placement="right"
              PopperProps={{
                popperOptions: {
                  modifiers: {
                    flip: { enabled: false },
                  },
                },
              }}
            >
              <Box display={'flex'}>
                <Box className={`${classes.text} type`}>{functionType}</Box>
                <Box style={{ paddingLeft: '0.5em' }}>{isArray(label) ? label.join(', ') : label}</Box>
              </Box>
            </Tooltip>
          ) : (
            '-'
          )}
        </Box>
      </WrapperFormat>
    </LoadingComponent>
  );
}

export default AggregateCellFormat;
