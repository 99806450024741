import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EpsiloDisable = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.16667 8.50752C9.16667 8.75739 9.10584 8.93377 8.96898 9.05136C8.83212 9.15425 8.69525 9.21304 8.51277 9.21304H2.5517C2.5517 9.69809 2.59732 10.139 2.70377 10.5212C2.81021 10.9034 2.97749 11.2414 3.20559 11.5207C3.4489 11.8 3.75304 12.0204 4.118 12.1674C4.49817 12.3144 4.95438 12.3879 5.50182 12.3879C5.92761 12.3879 6.32299 12.3585 6.65754 12.285C6.99209 12.2115 7.28102 12.138 7.52433 12.0498C7.76764 11.9616 7.96533 11.8881 8.1326 11.8147C8.28467 11.7412 8.40632 11.7118 8.49757 11.7118C8.54319 11.7118 8.58881 11.7265 8.61922 11.7412C8.64963 11.7706 8.68005 11.8 8.71046 11.8441C8.72567 11.8881 8.74087 11.9469 8.75608 12.0351C8.77129 12.1233 8.77129 12.2115 8.77129 12.3291C8.77129 12.4173 8.77129 12.4908 8.75608 12.5496C8.75608 12.6084 8.74087 12.6672 8.72567 12.7113C8.71046 12.7554 8.69525 12.7994 8.68005 12.8435C8.64963 12.8876 8.61922 12.917 8.58881 12.9611C8.55839 12.9905 8.43674 13.0493 8.26946 13.1375C8.08698 13.2257 7.85888 13.2992 7.56995 13.3727C7.28102 13.4462 6.94647 13.5197 6.5663 13.5785C6.18613 13.6373 5.79075 13.6666 5.34975 13.6666C4.60462 13.6666 3.95073 13.5638 3.38807 13.358C2.82542 13.1522 2.35401 12.8582 1.97384 12.4614C1.59367 12.0645 1.30474 11.5648 1.10705 10.9768C0.924569 10.4183 0.833328 9.72749 0.833328 8.93378C0.833328 8.18416 0.939776 7.50803 1.13746 6.9054C1.33515 6.30277 1.62408 5.80302 2.00425 5.37677C2.38442 4.96522 2.84063 4.64185 3.37287 4.42138C3.90511 4.2009 4.51338 4.08331 5.16727 4.08331C5.88199 4.08331 6.49027 4.2009 6.97688 4.40668C7.47871 4.62715 7.88929 4.92112 8.20864 5.30328C8.52798 5.67074 8.77129 6.11169 8.92336 6.61143C9.07542 7.11118 9.15146 7.65502 9.15146 8.21355V8.50752H9.16667ZM7.49392 8.03717C7.50912 7.19937 7.32664 6.53794 6.91606 6.05289C6.50547 5.56785 5.8972 5.33267 5.10644 5.33267C4.69586 5.33267 4.3309 5.40617 4.02676 5.55315C3.72262 5.70013 3.46411 5.90591 3.25121 6.14108C3.03832 6.37626 2.88625 6.67023 2.76459 7.00829C2.64294 7.33165 2.58211 7.68441 2.56691 8.03717H7.49392Z"
      fill="#C2C7CB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.29167 0.333313C7.42954 0.333313 7.5674 0.36395 7.68995 0.409906C7.8125 0.455862 7.93505 0.532455 8.02696 0.639686C8.11887 0.731597 8.19547 0.854146 8.25674 0.976695C8.3027 1.09924 8.33334 1.23711 8.33334 1.37498C8.33334 1.51285 8.3027 1.65072 8.25674 1.77326C8.21079 1.89581 8.13419 2.01836 8.02696 2.11027C7.93505 2.20219 7.8125 2.27878 7.68995 2.34005C7.5674 2.38601 7.42954 2.41665 7.29167 2.41665C7.1538 2.41665 7.01593 2.38601 6.89338 2.34005C6.77083 2.2941 6.64828 2.2175 6.55637 2.11027C6.46446 2.01836 6.38787 1.89581 6.32659 1.77326C6.28064 1.65072 6.25 1.51285 6.25 1.37498C6.25 1.23711 6.28064 1.09924 6.32659 0.976695C6.38787 0.838828 6.46446 0.731597 6.57169 0.624367C6.6636 0.532455 6.78615 0.455862 6.9087 0.394588C7.03125 0.348632 7.1538 0.333313 7.29167 0.333313Z"
      fill="#C2C7CB"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.54167 0.333313C3.67953 0.333313 3.8174 0.36395 3.93995 0.409906C4.0625 0.455862 4.18505 0.532455 4.27696 0.639686C4.36887 0.731597 4.44547 0.854146 4.50674 0.976695C4.5527 1.09924 4.58333 1.23711 4.58333 1.37498C4.58333 1.51285 4.5527 1.65071 4.50674 1.77326C4.46078 1.89581 4.38419 2.01836 4.27696 2.11027C4.18505 2.20219 4.0625 2.27878 3.93995 2.34005C3.8174 2.38601 3.67953 2.41665 3.54167 2.41665C3.4038 2.41665 3.26593 2.38601 3.14338 2.34005C3.02083 2.2941 2.89828 2.2175 2.80637 2.11027C2.71446 2.01836 2.63787 1.89581 2.57659 1.77326C2.53064 1.65071 2.5 1.51285 2.5 1.37498C2.5 1.23711 2.53064 1.09924 2.57659 0.976695C2.62255 0.854146 2.69914 0.731597 2.80637 0.639686C2.89828 0.547774 3.02083 0.471181 3.14338 0.409906C3.26593 0.348632 3.4038 0.333313 3.54167 0.333313Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);

export default EpsiloDisable;
