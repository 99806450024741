import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Format = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7059 11.1173C11.0964 10.7268 11.0964 10.0936 10.7059 9.70312L1.70973 0.706956C1.31921 0.316431 0.686042 0.316431 0.295518 0.706956C-0.0950067 1.09748 -0.0950064 1.73065 0.295518 2.12117L4.39139 6.21704L2.6472 9.70542C2.34985 10.3001 2.7823 10.9998 3.4472 10.9998C3.78598 10.9998 4.09569 10.8084 4.2472 10.5054L5.72472 7.55038L9.29169 11.1173C9.68221 11.5079 10.3154 11.5079 10.7059 11.1173Z" />
    <path d="M7.99999 2.99985L6.99999 4.99985L5.66665 3.66651L5.99999 2.99985L4.99999 2.99985L2.99999 0.999848H9.99999C10.5523 0.999848 11 1.44756 11 1.99985C11 2.55213 10.5523 2.99985 9.99999 2.99985L7.99999 2.99985Z" />
  </SvgIcon>
);

export default Format;
