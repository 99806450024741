/**
 * @jest-environment node
 */

import * as F from '@formulajs/formulajs';
import numeral from 'numeral';
import moment from 'moment';
import { get, isNumber, max, min, uniq } from 'lodash';
import debug from 'debug';

const cached = {};
const log = debug('eip:excel-formula');
const logError = debug('eip:excel-formula:error');

const USER_SETTINGS_KEY = 'epusersettings';

export type UserSettings = {
  profile: {
    userId?: string;
    oldUserId?: string;
    userName: string;
    userEmail: string;
  };
  permissions?: {
    byShopId: {
      [shopId: string]: { [featureCode: string]: string[] };
    };
    byCode: {
      [permCode: string]: string[];
    };
  };
  lastUpdated?: number;
  isAuth0?: boolean;
};

let _cachedUserSettings = null;
export function getUserSettings(): UserSettings {
  if (_cachedUserSettings) return _cachedUserSettings;
  const str = self.localStorage.getItem(USER_SETTINGS_KEY);

  _cachedUserSettings = str ? JSON.parse(str) : null;

  return _cachedUserSettings;
}

type ExcelType = Omit<typeof F, 'TEXT'>;
type AggFunctionType =
  | 'NONE'
  | 'UNIQUE'
  | 'SUM'
  | 'AVG'
  | 'MIN'
  | 'MAX'
  | 'COUNT_ALL'
  | 'COUNT_VALUES'
  | 'COUNT_UNIQUE'
  | 'COUNT_EMPTY'
  | 'COUNT_NOT_EMPTY'
  | 'PERCENT_EMPTY'
  | 'PERCENT_NOT_EMPTY'
  | 'LIST'
  | 'RANGE';

type Formulatype = ExcelType & {
  TEXT: (value: any, format: string) => string;
  ICAL: (rows?: any[], aggFunc: AggFunctionType) => number | string;
  UCAL: (input: string, aggFunc: AggFunctionType, global?: false) => number | string;
  UCALS: (input: string, aggFunc: AggFunctionType) => number | string;
  LIST: (...input: (string | number)[]) => (string | number)[];
  MAP: (input: any[], string) => any;
  USER: (input: string) => any;
  COHORT: (apiField: string, cohort: string) => any;
  ACC_SUM: () => number;
  ACC_AVG: () => number;
  ACC_MAX: () => number;
  ACC_MIN: () => number;
  STYLE: (text: string, ...args: string[]) => string;
};

function enhanceF() {
  const ef: Formulatype = {
    ...F,
    TEXT: (value, format, complementFormat = 'YYYY-MM-DD HH:mm:ss') => {
      if (value === null || value === undefined) {
        return '-';
      }
      if (!isNaN(Number(value)) && !['local', 'relative'].includes(format)) {
        return numeral(Number(value)).format(format);
      }

      const possibleDatetime = moment.utc(value);
      if (possibleDatetime.isValid()) {
        if (format === 'relative') {
          return possibleDatetime.fromNow();
        } else if (format === 'local') {
          if (complementFormat) {
            return possibleDatetime.local().format(complementFormat);
          } else {
            return possibleDatetime.local().toISOString();
          }
        }
        return possibleDatetime.format(format);
      } else {
        return value; // invalid
      }
    },
    STYLE: (text, ...args) => {
      const mapping = {
        b: {
          'font-weight': 'bold',
        },
        u: {
          'text-decoration': 'underline',
        },
        i: {
          'font-style': 'italic',
        },
        s: {
          'text-decoration': 'line-through',
        },
      };
      const styles = args.reduce((carry, s) => {
        const styleMapped = mapping[s];
        if (/\d+px/.test(s) || /\d+em/.test(s) || /\d+rem/.test(s)) {
          carry['font-size'] = s;
        } else if (!mapping[s] && s) {
          carry['color'] = s;
        } else if (mapping[s]) {
          carry = {
            ...carry,
            ...styleMapped,
          };
        }
        return carry;
      }, {});
      const stylesTransformed = Object.entries(styles)
        .map(([key, value]) => `${key}: ${value}`)
        .join('; ');
      if (args.length) {
        return `<span style="${stylesTransformed}">${text}</span>`;
      }
      return text;
    },
    ICAL: (rows = [], func) => {
      switch (func) {
        case 'MAX':
          return max(rows);
        case 'MIN':
          return min(rows);
        case 'UNIQUE':
          return uniq(rows);
      }
    },
    UCAL: (input, func) => {
      return '';
    },
    UCALS: (input, func) => {
      return '';
    },
    LIST(...input) {
      const collection = input.reduce((carry, i) => {
        if (typeof i !== 'string') return carry.concat(i);
        try {
          const ls = JSON.parse(i as string);
          return carry.concat(ls);
        } catch (e) {
          return carry.concat(i);
        }
      }, []);
      return collection;
    },
    MAP(input, mapFun) {
      const mapFunction = new Function(
        'F',
        'x',
        `
    with(F) { 
      try {
        return ${mapFun}; 
      }
      catch(err){
        console.error('[eFormula]', err);
        return '#ERR!';
      }
    }
    `,
      );

      return input.map(mapFunction.bind(null, ef));
    },
    USER(input) {
      return get(getUserSettings(), ['profile', input]);
    },
    COHORT(apiColumn, cohortDateRange) {
      return {
        apiColumn,
        cohortDateRange,
      };
    },
    ACC_SUM: () => {
      return 1;
    },
    ACC_AVG: () => {
      return 1;
    },
    ACC_MAX: () => {
      return 1;
    },
    ACC_MIN: () => {
      return 1;
    },
    JOIN: (array, symbol = ' / ') => {
      return Array.isArray(array) ? array.join(symbol) : JSON.stringify(array);
    },
  };

  return ef;
}

const eFDefault = enhanceF();
export { eFDefault };

export function execute(eF = eFDefault, formulaStr, context: Record<string, any>) {
  const isFormula = typeof formulaStr === 'string' && formulaStr.indexOf('=') === 0;
  if (!isFormula) return formulaStr;

  const formula = formulaStr.replace('=', '');

  if (!cached[formula]) {
    try {
      cached[formula] = new Function(
        'F',
        'p',
        'r',
        `
    const origin_UCAL = F.UCAL;
    F.UCAL = function(field, calStr) { return origin_UCAL(true, field, calStr, r);};
    F.UCALS = function(field, calStr) { return origin_UCAL(false, field, calStr, r);};

    with(F) { 
      try {
        return String(${formula}); 
      }
      catch(err){
        console.error('[eFormula]', err);
        return '#ERR!';
      }
    }
   `,
      );
    } catch (e) {
      cached[formula] = () => '#SYNTAX!';
    }
  }

  return cached[formula](eF, (k: string) => context[k], context.r || (() => null));
}
export function toValue(formulaStr, context: Record<string, any>, isRaw = false) {
  const isFormula = typeof formulaStr === 'string' && formulaStr.indexOf('=') === 0;
  if (!isFormula) return formulaStr;

  const formula = formulaStr.replace('=', '');

  if (!cached[formula]) {
    try {
      cached[formula] = new Function(
        'F',
        'p',
        'r',
        `
    with(F) { 
      try {
        return ${isRaw ? formula : 'String(' + formula + ')'};
    }
    catch (err) {
      console.warn('[eformula] evaluate error', err, \`${formula}\`);
        return '#ERR!';
      }
    }
   `,
      );
    } catch (e) {
      cached[formula] = () => '#SYNTAX!';
    }
  }

  return cached[formula](eFDefault, (k: string) => get(context, k, null), context.r || (() => null));
}

export const isFormulaField = (f) => String(f).indexOf('=') === 0;

if (require.main === module) {
  console.info(toValue(`=CONCATENATE('hello ', p('hello'))`, { hello: 'world' }));
}

// =CONCATENATE("ARR $", TEXT(SUM(p("mrr1"), p("mrr2"), p("commission"))*12, '#,###'), "/ ", TEXT(SUM(p("mrr1"), p("mrr2"), p("commission"))/ SUM(UCAL("global_company.m_mrr1", "SUM"), UCAL("global_company.m_mrr2", "SUM"), UCAL("global_company.m_commission", "SUM")), "#,#%"))
