import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const User = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM6 2C6.39557 2 6.78224 2.1173 7.11114 2.33706C7.44004 2.55682 7.69639 2.86918 7.84776 3.23463C7.99914 3.60009 8.03874 4.00222 7.96157 4.39018C7.8844 4.77814 7.69392 5.13451 7.41422 5.41421C7.13451 5.69392 6.77815 5.8844 6.39018 5.96157C6.00222 6.03874 5.60009 5.99913 5.23464 5.84776C4.86918 5.69638 4.55683 5.44004 4.33706 5.11114C4.1173 4.78224 4 4.39556 4 4C4 3.46957 4.21072 2.96086 4.58579 2.58579C4.96086 2.21071 5.46957 2 6 2ZM9 8.66C8.62464 9.08563 8.163 9.42651 7.64575 9.66C7.12851 9.89349 6.5675 10.0142 6 10.0142C5.4325 10.0142 4.87149 9.89349 4.35425 9.66C3.83701 9.42651 3.37537 9.08563 3 8.66V8C3 7.73478 3.10536 7.48043 3.2929 7.29289C3.48043 7.10536 3.73479 7 4 7H8C8.26522 7 8.51957 7.10536 8.70711 7.29289C8.89465 7.48043 9 7.73478 9 8V8.66Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default User;
