import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { getActionMenu } from '../table-config/common-config';
import { DATA_TYPE, PROPERTY_TYPE, generateColumnKeys } from './common';

export const options = [
  // DIMENSION
  'companyFormat',
  'workspaceFormat',
  'countryFormat',
  'marketplaceFormat',
  'storefrontFormat',
  'adTypeFormat',
  'adToolFormat',
  'campaignFormat',
  'adObjectFormat',
  'scriptFormat',
  'staticKeywordFormat',
  'campaignTagFormat',
  'adObjectTagFormat',
  'keywordTagFormat',
  'insightDimension',
  'epsiloDimension',
  'operationDimension',
  'qualityDimension',
  'filterColumnFormat',
];

export const getCellFormat = (name) => {
  switch (name) {
    // DIMENSION
    case 'insightDimension':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Insight dimension',
      };
    case 'epsiloDimension':
      return {
        component: cellFormat?.textFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Epsilo dimension',
      };
    case 'operationDimension':
      return {
        component: cellFormat?.textFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Operation dimension',
      };
    case 'qualityDimension':
      return {
        component: cellFormat?.textFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Quality dimension',
      };
    case 'companyFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'COMPANY.name',
          valueGetter: {
            label: 'COMPANY.name',
            id: 'COMPANY.id',
          },
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          menu: getActionMenu('company'),
        },
        label: 'Company',
      };
    case 'workspaceFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'WORKSPACE.name',
          valueGetter: {
            label: 'WORKSPACE.name',
            id: 'WORKSPACE.id',
          },
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          menu: getActionMenu('workplace'),
        },
        label: 'Workplace',
      };
    case 'countryFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'COUNTRY.country_code',
          propertyType: PROPERTY_TYPE.DIMENSION,
          valueGetter: {
            label: 'COUNTRY.country_name',
            id: 'COUNTRY.country_code',
          },
          menu: getActionMenu('country'),
        },
        label: 'Country',
      };
    case 'marketplaceFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'MARKETPLACE.marketplace_code',
          propertyType: PROPERTY_TYPE.DIMENSION,
          valueGetter: {
            label: 'MARKETPLACE.marketplace_name',
            id: 'MARKETPLACE.marketplace_code',
          },
          menu: getActionMenu('marketplace'),
        },
        label: 'Marketplace',
      };
    case 'storefrontFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'STOREFRONT.id',
          propertyType: PROPERTY_TYPE.DIMENSION,
          valueGetter: {
            label: 'STOREFRONT.name',
            id: 'STOREFRONT.id',
            sid: 'STOREFRONT.storefront_sid',
            subtext: 'STOREFRONT.id',
          },
          menu: getActionMenu('marketplace'),
        },
        label: 'Storefront',
      };
    case 'campaignFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_CAMPAIGN',
          filterGetter: {
            default: 'ADS_CAMPAIGN.name',
          },
          sortField: 'ADS_CAMPAIGN.name',
          valueGetter: {
            label: 'ADS_CAMPAIGN.name',
            subtext: 'ADS_CAMPAIGN.campaign_code',
            id: 'ADS_CAMPAIGN.id',
          },
          menu: getActionMenu('campaign'),
        },
        label: 'Campaign',
      };
    case 'adObjectFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_OBJECT',
          filterGetter: {
            default: 'ADS_OBJECT.name',
          },
          sortField: 'ADS_OBJECT.name',
          valueGetter: {
            label: 'ADS_OBJECT.name',
            subtext: 'PRODUCT.product_sid',
            subtext2: 'ADS_OBJECT.sc_identify_code',
            id: 'ADS_OBJECT.id',
            ...(ff.enable_bidding_price ? { productId: 'PRODUCT.id' } : {}),
          },
          menu: getActionMenu('ad_object'),
        },
        label: 'Ad object',
      };
    case 'scriptFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'SCRIPT.script_name',
          valueGetter: {
            label: 'SCRIPT.script_name',
            id: 'SCRIPT.id',
            scriptObjectId: 'SCRIPT_OBJECT.id',
          },
          menu: getActionMenu('script'),
        },
        label: 'Script',
      };
    case 'staticKeywordFormat':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_PLACEMENT.name',
          valueGetter: {
            label: 'ADS_PLACEMENT.name',
            id: 'ADS_PLACEMENT.id',
          },
          menu: getActionMenu('keyword'),
        },
        label: 'Keyword',
      };
    case 'campaignTagFormat':
      return {
        component: cellFormat?.tagWithInlineEditor,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_CAMPAIGN.general_tag',
          valueGetter: {
            id: 'ADS_CAMPAIGN.general_tag',
            value: 'ADS_CAMPAIGN.general_tag',
          },
          ...(ff.ui_ux_update_tag_item
            ? {
                menu: getActionMenu('campaign_tag'),
                menuItem: getActionMenu('sub_item_tag'),
              }
            : {}),
        },
        label: 'Campaign tag',
      };
    case 'adObjectTagFormat':
      return {
        component: cellFormat?.tagWithInlineEditor,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_OBJECT.general_tag',
          valueGetter: {
            id: 'ADS_OBJECT.general_tag',
            value: 'ADS_OBJECT.general_tag',
          },
          ...(ff.ui_ux_update_tag_item
            ? {
                menu: getActionMenu('ad_object_tag'),
                menuItem: getActionMenu('sub_item_tag'),
              }
            : {}),
        },
        label: 'Ad object tag',
      };
    case 'keywordTagFormat':
      return {
        component: cellFormat?.tagWithInlineEditor,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_PLACEMENT.general_tag',
          valueGetter: {
            id: 'ADS_PLACEMENT.general_tag',
            value: 'ADS_PLACEMENT.general_tag',
          },
          ...(ff.ui_ux_update_tag_item
            ? {
                menu: getActionMenu('keyword_tag'),
                menuItem: getActionMenu('sub_item_tag'),
              }
            : {}),
        },
        label: 'Keyword tag',
      };
    case 'filterColumnFormat':
      return {
        component: cellFormat?.emptyFormat,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.DIMENSION,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
          },
        },
        label: 'Filter Column',
      };
    default:
      return null;
  }
};
