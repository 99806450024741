import {
  PageContainerResponsive,
  PageContext,
  pageRedux,
  EIP_CONSTANT,
  eipRequest as request,
  makeStore,
  aim,
} from '@eip/next/lib/main';
import epsiloTheme, { seedClassName } from '@ep/insight-ui/lib/epsilo-theme';
import { InternalPage, OnePageContext } from './page-ui';
import { createGenerateClassName, StylesProvider, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { Provider } from 'react-redux';
import { useAuth } from '../hooks/use-auth';
import { getAuth0 } from '../global';

import { PageEditContext } from '@ep/insight-ui/system/page/context/page-edit';
import { usePageEdit } from '@ep/insight-ui/system/page/hooks/use-page-edit';

const API_AUTHENTICATION = EIP_CONSTANT.API_HOST.API_AUTHENTICATION;

const { reducer, rootSaga } = pageRedux;
const trackError = (error) => {
  /* do nothing */
};

const generateClassName = createGenerateClassName({
  seed: seedClassName,
});
export class PageErrorBoundary extends React.Component<any, { hasError: boolean }> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    window.requestAnimationFrame(() => trackError(error));
  }

  render() {
    if (this.state.hasError) {
      return (
        <h1>
          Oops, something was wrong.{' '}
          <a href="#" onClick={() => window.location.reload()}>
            Click here
          </a>{' '}
          to refresh the page
        </h1>
      );
    }

    return this.props.children;
  }
}

function ManagedWorkspaceProvider({ children }: { children: JSX.Element }) {
  const store = React.useMemo(
    () =>
      makeStore({
        storeName: 'eip-workspace' + new Date().toISOString(),
        reducer,
        rootSaga: rootSaga,
      }),
    [],
  );

  return (
    <Provider context={PageContext} store={store}>
      {children}
    </Provider>
  );
}

export default function ContainerInternalPage({ children }: { children: JSX.Element }) {
  const { userSettings, logout } = useAuth();
  const pageEdit = usePageEdit();

  React.useEffect(() => {
    if (ff.auth0_next && aim.getUserSettings() && aim.getUserSettings().isAuth0) {
      const tid = window.setInterval(() => {
        getAuth0().then((auth0) => {
          auth0.getTokenSilently().then((token) => {
            aim.setLoginToken(token);
          });
        });
      }, 5 * 60 * 1000);

      return () => {
        window.clearInterval(tid);
      };
    } else {
      const tid = window.setInterval(() => {
        request.get(API_AUTHENTICATION + '/.ory/kratos/public/sessions/whoami');
      }, 5 * 60 * 1000);
      return () => {
        window.clearInterval(tid);
      };
    }
  });

  return (
    <ThemeProvider theme={epsiloTheme}>
      <StylesProvider generateClassName={generateClassName}>
        <ManagedWorkspaceProvider>
          <OnePageContext.Provider
            value={{
              userFullName: userSettings.profile.userName,
              userEmail: userSettings.profile.userEmail,
              onLogout: () => {
                logout();
              },
            }}
          >
            <PageContainerResponsive>
              <InternalPage>
                <PageErrorBoundary>
                  <PageEditContext.Provider value={pageEdit}>{children}</PageEditContext.Provider>
                </PageErrorBoundary>
              </InternalPage>
            </PageContainerResponsive>
          </OnePageContext.Provider>
        </ManagedWorkspaceProvider>
      </StylesProvider>
    </ThemeProvider>
  );
}
