import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { formatCurrency } from '@ep/insight-ui/lib/number';
// import { IRowEDashboard, IDateValue } from '.././';
import { dataType, IData, IOption } from '@ep/insight-ui/chartlib/type';
import ScatterChartResponsive from '@ep/insight-ui/chartlib/scatter/scatter-chart';
import { IRowEDashboard } from '../mocks/scatter';
import { funcConfigs } from '@ep/insight-ui/chartlib/scatter/hooks';
import { colorsChartLegend, defaultOptions, randomInt, tool } from '@ep/insight-ui/chartlib/helper';
import ChartState from '@ep/insight-ui/chartlib/chart-loading-state';
import { IStatusChartBB } from '../dashboard-container';
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { TSize } from '@ep/insight-ui/chartlib/chart-size';

type IStackedChart = {
  data: IRowEDashboard;
  statusChart: IStatusChartBB;
  size?: TSize;
};

const ScatterChartEDashboard = ({ data, statusChart, size = 'large' }: IStackedChart) => {
  const [dataList, setDataList] = React.useState<IData>();
  const [optionList, setOptionList] = React.useState<any>();
  const { externalTooltipHandler } = funcConfigs({});

  let thisMonthSerries: Array<{ date: string; value: number }>;
  let lastMonthSerries: Array<{ date: string; value: number }>;
  let rows;
  if (ff.revive_all_charts) {
    rows = _.get(data, 'rows', []);
    thisMonthSerries = [];
    lastMonthSerries = [];
    rows.forEach((item) => {
      thisMonthSerries = item.this_time_value_serries;
      lastMonthSerries = item.last_time_value_serries;
    });
  } else {
    thisMonthSerries = _.get(data, 'this_time_value_serries', []);
    lastMonthSerries = _.get(data, 'last_time_value_serries', []);
  }

  const stateChart = React.useMemo((): IStateChartValue => {
    switch (statusChart) {
      case 'success': {
        if (thisMonthSerries.length == 0 && lastMonthSerries.length == 0) return 'empty';
        return 'success';
      }
      case 'error':
        return 'error';
      default:
        return 'loading';
    }
  }, [statusChart, thisMonthSerries.length, lastMonthSerries.length]);

  React.useEffect(() => {
    const thisMonthData = [];
    const lastMonthData = lastMonthSerries.map((item) => {
      return { x: randomInt(0, item.value * 1000), y: randomInt(0, item.value * 100) };
    });
    const labels = thisMonthSerries.map((item) => {
      return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(item.date));
    });
    const tempDashLine = [];
    thisMonthSerries.forEach((item) => {
      if (moment(item.date).isBefore(moment().format())) {
        const value = { x: randomInt(0, item.value * 1000), y: randomInt(0, item.value * 100) };
        thisMonthData.push(value);
      } else {
        const value = { x: randomInt(0, item.value * 1000), y: randomInt(0, item.value * 100) };
        tempDashLine.push(value);
      }
    });
    setDataList({
      labels: labels,
      datasets: [
        {
          label: 'Storefront fee',
          data: thisMonthData,
          baseColor: colorsChartLegend[0].stroke,
          backgroundColor: colorsChartLegend[0].pointBorderColor,
          hoverBackgroundColor: colorsChartLegend[0].stroke,
          pointBorderColor: colorsChartLegend[0].stroke,
          pointHoverBorderColor: colorsChartLegend[0].pointBorderColor,
          active: 'active',
        },
        {
          label: 'User fee',
          data: thisMonthData.map((data, index) => data + lastMonthData[index]),
          baseColor: colorsChartLegend[1].stroke,
          backgroundColor: colorsChartLegend[1].pointBorderColor,
          hoverBackgroundColor: colorsChartLegend[1].stroke,
          pointBorderColor: colorsChartLegend[1].stroke,
          pointHoverBorderColor: colorsChartLegend[1].pointBorderColor,
          active: 'active',
        },
        {
          label: 'Take rate',
          data: lastMonthData,
          baseColor: colorsChartLegend[2].stroke,
          backgroundColor: colorsChartLegend[2].pointBorderColor,
          hoverBackgroundColor: colorsChartLegend[2].stroke,
          pointBorderColor: colorsChartLegend[2].stroke,
          pointHoverBorderColor: colorsChartLegend[2].pointBorderColor,
          active: 'active',
        },
      ],
    });

    //Display option chart
    const { DOT: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    _.set(optionList, 'plugins.tooltip.callbacks.label', tool.dotCallbacks.label({ currency: 'PHP' }));
    _.set(optionList, 'scales.x.display', size === 'large');
    _.set(optionList, 'scales.y.display', size === 'large');
    setOptionList(optionList);
  }, [data]);
  return (
    <ScatterChartResponsive
      stateChart={stateChart}
      title={'Title'}
      value={'600'}
      currency={'PHP'}
      percent={15}
      keyChart={'dot'}
      dataList={dataList}
      optionList={optionList}
    />
  );
};

export default ScatterChartEDashboard;
