import * as React from 'react';

import { Button, Typography, makeStyles } from '@material-ui/core';

import FlexBox from '@ep/insight-ui/elements/share-with-collaborators/Flexbox';
import TextFieldWithFormula from '@ep/insight-ui/elements/textField/text-field-with-formula';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { usePage } from '@eip/next/lib/main';
import { debounce } from 'lodash';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: '16px',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    '& h6': {
      lineHeight: '1.35',
    },
  },
  select: {
    '& .eip1-MuiInputBase-root': {
      padding: '6px 8px',
      '& input': {
        padding: '0px !important',
        height: '20px',
        color: colors.text.default,
      },
      '& .eip1-MuiAutocomplete-endAdornment': {
        top: '50%',
        color: colors.icon.default,
      },
      '& .eip1-MuiButtonBase-root': {
        marginRight: '-8px',
        padding: '8px',
        backgroundColor: 'transparent',
        color: colors.icon.default,
        '& .eip1-MuiIconButton-label': {
          width: '16px',
          height: '16px',
          '& .eip1-MuiSvgIcon-root': {
            fontSize: '8px !important',
          },
        },
        '& .eip1-MuiTouchRipple-root': {
          display: 'none',
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
}));

const PageConfig = () => {
  const { title, updateEditorTitle, loadFormulaTitle, pageHashtag, pageSubtext, updateHashtag, updateSubtext } =
    usePage();
  const classes = useStyles();
  const [updatedTitle, setUpdatedTitle] = React.useState(title);
  const [updatedSubtext, setUpdatedSubtext] = React.useState(pageSubtext);
  const [updatedHashtag, setUpdatedHashtag] = React.useState(pageHashtag);

  const syncPageTitle = React.useCallback(
    debounce((title, hashtag, subtext) => {
      updateEditorTitle(title);
      loadFormulaTitle(title, hashtag, subtext);
      updateHashtag(hashtag);
      updateSubtext(subtext);
    }, 500),
    [],
  );

  const handleSubmitPageConfig = () => {
    syncPageTitle(updatedTitle, updatedHashtag, updatedSubtext);
  };

  return (
    <div className={classes.container}>
      <div className={classes.group}>
        <Typography variant="h6" className={classes['mb-4']}>
          Page hashtag
        </Typography>
        <FlexBox flexDirection="column" className={classes.select}>
          <TextFieldWithFormula
            initialValue={updatedHashtag}
            supportFormula
            availVars={[]}
            onValueChange={(value) => setUpdatedHashtag(value)}
          />
        </FlexBox>
      </div>
      <div className={classes.group}>
        <Typography variant="h6" className={classes['mb-4']}>
          Page title
        </Typography>
        <FlexBox flexDirection="column" className={classes.select}>
          <TextFieldWithFormula
            initialValue={updatedTitle}
            supportFormula
            availVars={[]}
            onValueChange={(value) => setUpdatedTitle(value)}
          />
        </FlexBox>
      </div>
      <div className={classes.group}>
        <Typography variant="h6" className={classes['mb-4']}>
          Page subtext
        </Typography>
        <FlexBox flexDirection="column" className={classes.select}>
          <TextFieldWithFormula
            initialValue={updatedSubtext}
            supportFormula
            availVars={[]}
            onValueChange={(value) => setUpdatedSubtext(value)}
          />
        </FlexBox>
      </div>
      <div className={classes.actions}>
        <Button variant="contained" color="primary" onClick={handleSubmitPageConfig}>
          Submit
        </Button>
      </div>
    </div>
  );
};

export default PageConfig;
