import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { Button, Grid, makeStyles, Modal, Box } from '@material-ui/core';
import * as React from 'react';
import SelectionComponent from './selection-component';
import { OptionsModel, SelectionModal, SettingTypesItem } from './type';
import { ContainerResponsiveContext, NodeEditContext } from '@eip/next/lib/main';
import clsx from 'clsx';
import { get, isEqual, set, sortBy } from 'lodash';
import {
  LOCKED_VIEW_CONFIG_PROPERTY,
  LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE,
  DELAY_CLOSE_POPUP,
} from '@ep/insight-ui/system/helper/constant';
import { getConst } from '@ep/insight-ui/sw/constant/common';
const useStyles = makeStyles((theme) => ({
  container: {},
  groupCheckList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    maxHeight: 'calc(90vh - 50px)',
    overflowY: 'auto',
    // maxWidth: 640,
  },
  checkListItem: {
    flex: 1 / 3,
    minWidth: 210,
    maxWidth: 210,
    padding: '16px 8px',
  },
  openCheckList: {
    width: '100%',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  closeCheckList: {
    width: 0,
    padding: 0,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 16px',
  },
  button: {
    height: '100%',
  },
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  modalButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  modalButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
  lockIcon: {
    cursor: 'pointer',
  },
}));

const defaultSettingTypeData: Array<SettingTypesItem> = [
  { type: 'dimension', label: 'Dimension' },
  { type: 'attribute', label: 'Attribute' },
  { type: 'metric', label: 'Metric' },
  { type: 'action', label: 'Action' },
  { type: 'pro', label: 'Pros' },
];
type IDisabledOption = {
  [key: string]: string[];
};
interface DropDownPropertiesProps {
  handleClose: () => void;
  disabledEffectOnchange?: boolean;
  useDesktopLayoutInSmallScreen?: boolean;
  properties?: string[];
}
const DropDownProperties = ({
  handleClose,
  disabledEffectOnchange = false,
  properties,
  useDesktopLayoutInSmallScreen,
}: DropDownPropertiesProps) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = false;
  const [property, setProperty] = React.useState<{
    options: OptionsModel;
    settingTypes: Array<SettingTypesItem>;
    selectedItems: OptionsModel;
    disabledOptions: IDisabledOption;
    singleSelectColumns: { [key: string]: string[] };
  } | null>({ options: {}, selectedItems: {}, settingTypes: [], disabledOptions: {}, singleSelectColumns: {} });
  const [selectionState, setSelectionState] = React.useState({});
  const [orderState, setOrderState] = React.useState({});

  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;

  const lockedViewConfigProperty = get(backboneContext.getConfig('view'), LOCKED_VIEW_CONFIG_PROPERTY, []);
  const [lockState, setLockState] = React.useState(lockedViewConfigProperty);

  const lockStateRef = React.useRef(lockedViewConfigProperty);

  const hiddenPropertyColumns = backboneContext.getConfig('hiddenPropertyColumns', []);

  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const metricTractionProperties = backboneContext.getConfig('metricTractionProperties', []);

  const isLockedView = get(backboneContext.getConfig('view'), ['isLock'], false);
  const { isEditMode } = React.useContext(NodeEditContext);

  const fetchData = () => {
    const settingTypes: Array<SettingTypesItem> = backboneContext
      .getConfig('settingType', [])
      .filter((i) => i.allowConfig !== false);
    if (metricTractionProperties && metricTractionProperties.length > 0) {
      settingTypes.push({ type: 'traction', label: 'Traction' });
    }
    if (settingTypes.length > 0) {
      settingTypes.forEach((setting) => {
        const opts = backboneContext.getOptions(setting.type);
        const selectedOptIds = backboneContext.getConfig(setting.type);
        property.options[setting.type] = opts
          .filter((opt) => !hiddenPropertyColumns.includes(opt.id))
          .map((option) => {
            if (!isLockedView) return option;
            if (!isEditMode)
              return {
                ...option,
                disabled: lockState.includes(option.id),
              };
            return {
              ...option,
              endIcon: lockState.includes(option.id)
                ? 'rmx/lock-line-icon/#0000008a'
                : 'rmx/lock-unlock-line-icon/#0000008a',
              onEndIconClick: (_, item) => {
                if (lockStateRef.current.includes(option.id)) {
                  lockStateRef.current = lockStateRef.current.filter((el) => el != option.id);
                } else {
                  lockStateRef.current = lockStateRef.current.concat(option.id);
                }
                setLockState(lockStateRef.current);
                item.disabled = !item.disabled;
                item.endIcon = item.disabled ? 'rmx/lock-line-icon/#0000008a' : 'rmx/lock-unlock-line-icon/#0000008a';
              },
              endIconClasses: classes.lockIcon,
              disabled: lockState.includes(option.id),
            };
          });
        property.selectedItems[setting.type] = opts.filter((i) => selectedOptIds.includes(i.id));
        property.singleSelectColumns[setting.type] = get(setting, 'singleSelectColumns', []);
        if (metricTractionProperties && property.options['traction'] && property.selectedItems['traction']) {
          const selectedMetricTraction = backboneContext.getConfig('selectedMetricTraction', []);
          property.options[setting.type] = metricTractionProperties.filter(
            (metricTractionPropertie) => !hiddenPropertyColumns.includes(metricTractionPropertie.id),
          );
          property.selectedItems[setting.type] =
            selectedMetricTraction.length > 0
              ? metricTractionProperties.filter((i) => selectedMetricTraction.includes(i.id))
              : [];
        }
      });
      setProperty({
        settingTypes,
        options: property.options,
        selectedItems: property.selectedItems,
        disabledOptions: backboneContext.getConfig('disabledComponents', {}),
        // disabledOptions: {
        //   attribute: [
        //     'storefront.created_by',
        //     'storefront.created_at',
        //     'storefront.updated_by',
        //     'storefront.updated_at',
        //   ],
        // },
        singleSelectColumns: property.singleSelectColumns,
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateSelection = (type: string, selectedArr: SelectionModal) => {
    if (disabledEffectOnchange && selectedArr) {
      const val = { ...selectionState };
      set(val, type, selectedArr);
      setSelectionState(val);
      return;
    }
    if (selectedArr) backboneContext.changeConfig(type, selectedArr);
  };

  const handleReorder = (type: string, optionIds: string[]) => {
    if (disabledEffectOnchange && optionIds) {
      const val = { ...orderState };
      set(val, type, optionIds);
      setOrderState(val);
      return;
    }

    if (optionIds) backboneContext.changeOptionOrder(type, optionIds);
  };

  const handleSubmit = () => {
    handleClose();

    setTimeout(() => {
      let shouldPivotReload = false;

      // Do not allow user change order of columns in locked view
      const columnOrderProperties = {};
      const columnSelectionProperties = {};
      if (!isLockedView || isEditMode) {
        Object.keys(orderState).forEach((type) => {
          const initialCurrentOption = _.get(property, ['options', type], []).map((item) => item.id);
          if (metricTractionProperties && type == 'traction') {
            const initialCurrentTractionOption = metricTractionProperties.map((item) => item.id);
            if (!isEqual(initialCurrentTractionOption, orderState[type])) {
              const clone = [...metricTractionProperties];
              clone.sort((a, b) => orderState[type].indexOf(a.id) - orderState[type].indexOf(b.id));
              backboneContext.changeConfig('metricTractionProperties', clone);
            }
          } else if (!isEqual(initialCurrentOption, orderState[type])) {
            shouldPivotReload = true;
            backboneContext.changeOptionOrder(type, orderState[type]);
            columnOrderProperties[type] = orderState[type];
          }
        });
      }

      Object.keys(selectionState).forEach((type) => {
        const initialSelected = _.get(property, ['selectedItems', type], []).map((item) => item.id);
        const currentSelected = selectionState[type].map((item) => item.id);

        if (!isEqual(sortBy(initialSelected), sortBy(currentSelected))) {
          shouldPivotReload = false;
          if (metricTractionProperties && type == 'traction') {
            backboneContext.changeConfig('selectedMetricTraction', currentSelected);
          } else {
            backboneContext.changeConfig(type, currentSelected);
            columnSelectionProperties[type] = currentSelected;
          }
        }
      });

      if (backboneContext.getConfig('tableMode', 'default') == 'pivot' && shouldPivotReload) {
        backboneContext.reloadData('table');
      }

      // Only allow admin to update lock properties
      if (isEditMode && isLockedView) {
        const version = new Date().getTime();
        const view = backboneContext.getConfig('view');
        set(view, LOCKED_VIEW_CONFIG_PROPERTY, lockState);
        set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);

        const propertiesPath = ['combinator', 'properties'];
        const columnOrderPath = ['combinator', 'columnOrder'];
        const settingType = backboneContext.getConfig('settingType', []);
        const columnProperties = get(view, ['combinator', 'properties'], {});
        const newColumnProperties = Object.entries(columnProperties).reduce((carry, [key, value]) => {
          return {
            ...carry,
            [key]: columnSelectionProperties[key] ? columnSelectionProperties[key] : value,
          };
        }, {});
        const columnOrder = settingType.reduce((carry, { type }) => {
          const newOption = columnOrderProperties[type] ? columnOrderProperties[type] : columnProperties[type];
          return carry.concat(newOption);
        }, []);
        set(view, propertiesPath, newColumnProperties);
        set(view, columnOrderPath, columnOrder);

        const views = backboneContext.getConfig('views');
        for (const v of views) {
          if (v.id === view.id) {
            set(v, LOCKED_VIEW_CONFIG_PROPERTY, lockState);
            set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
            set(v, propertiesPath, newColumnProperties);
            set(v, columnOrderPath, columnOrder);
          }
        }

        backboneContext.updateConfig({
          view,
          views,
        });
      }
    }, getConst(DELAY_CLOSE_POPUP, 100));
  };

  const RenderOptionComponent = () => {
    return (
      <Grid
        container
        className={clsx(classes.groupCheckList, `eip_properties_parent_${titleTableClass} eip_action_properties`)}
      >
        {get(property, ['settingTypes'], [])
          .filter(
            ({ type }) =>
              (ff.expose_property_etable && (!properties || properties.includes(type))) || !ff.expose_property_etable,
          )
          .map(({ label, type, componentType, selectLimit }, index) => {
            return (
              get(property, ['options', type], []).length > 0 &&
              get(property, ['selectedItems', type], null) && (
                <SelectionComponent
                  key={`SelectionComponent_${index}`}
                  label={label}
                  type={type}
                  enableCenterStyles={index % 2 != 0 && index !== property.settingTypes.length - 1}
                  items={property.options[type]}
                  selectedItems={property.selectedItems[type]}
                  handleUpdateSelection={handleUpdateSelection}
                  onReorder={handleReorder}
                  selectLimit={selectLimit}
                  componentType={componentType}
                  disabledReorderDebounce={disabledEffectOnchange}
                  disabledOptions={get(property, ['disabledOptions', type], [])}
                  singleSelectColumns={get(property, ['singleSelectColumns', type], [])} //please fill list id option u want radio here, be like ['campaign_status', 'campaign_daily_budget', 'campaign_total_budget']
                  suppressDnD={!isEditMode && isLockedView}
                />
              )
            );
          })}
      </Grid>
    );
  };

  return (
    <div className={classes.container}>
      {(!isMobileView || useDesktopLayoutInSmallScreen) && RenderOptionComponent()}
      {(!isMobileView || useDesktopLayoutInSmallScreen) && disabledEffectOnchange ? (
        <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
          <Grid item>
            <Button className={classes.button} onClick={handleClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
              Apply
            </Button>
          </Grid>
        </Grid>
      ) : null}
      {isMobileView && !useDesktopLayoutInSmallScreen && (
        <Modal
          open={true}
          onClose={() => handleClose()}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.modalContent}>
            <div className={classes.modalHeader}>
              <div className={classes.modalButtonCancel}>
                <Button color="inherit" onClick={handleClose}>
                  Cancel
                </Button>
              </div>
              <h4 className={classes.modalTitle}>Properties</h4>
              <div className={classes.modalButton}>
                <Button color="primary" onClick={handleClose}>
                  Apply
                </Button>
              </div>
            </div>
            {RenderOptionComponent()}
          </Box>
        </Modal>
      )}
    </div>
  );
};

export default DropDownProperties;
