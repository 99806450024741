import { cloneDeep, get, first, set, zipObject, isEmpty, groupBy } from 'lodash';
import moment from 'moment';

import { addonMiddleware } from '@ep/insight-ui/system/util/addon-middleware';

import { checkEpsiloTableEndpoint, getMetricDefinition, getQueryParams, produceQueryResult } from './common';
import { hasCohortProperties, stripUniversalPrefix } from './enhancer';
import { isFormulaField, toValue } from '../../util/excel-formula';
import { getDefaultAggFunc as nextGetDefaultAggFunc } from '@ep/insight-ui/system/util/aggregation';
import { getQueryDatetimeValue } from '../../util/calendar';
import { EIP_CONSTANT } from '../../constant';
import { CHART_CONFIG, PERSONALIZATION, TIME_ORDER, X_AXIS, Y_AXIS } from '@ep/insight-ui/system/helper/constant';

const getAggFunc = (key) => {
  const aggFuncList = [
    'NONE',
    'UNIQUE',
    'SUM',
    'AVG',
    'MIN',
    'MAX',
    'COUNT_ALL',
    'COUNT_VALUES',
    'COUNT_UNIQUE',
    'COUNT_EMPTY',
    'COUNT_NOT_EMPTY',
    'PERCENT_EMPTY',
    'PERCENT_NOT_EMPTY',
    'RANGE',
  ].map((el) => {
    let fieldId = el;
    if (el === 'NONE') fieldId = 'NULL';
    if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
    return {
      id: el.toLowerCase(),
      requestQuery: fieldId,
    };
  });

  const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce((carry, i) => ({ ...carry, [i.id]: i }), {});

  return aggFuncByKey[key]
    ? aggFuncByKey[key]
    : {
        id: key,
        requestQuery: key.toUpperCase(),
      };
};

export function enhanceChartDataRequest(params, originRequest, backbone) {
  const endpoint = get(backbone, ['config', 'endpoint', 'GET_TABLE_DATA'], '');
  return addonMiddleware(
    originRequest,
    async function request(params, originalRequest, backbone, next) {
      if (checkEpsiloTableEndpoint(endpoint)) {
        return handleDataRequest(params, originalRequest, backbone);
      }
      return next(params, originalRequest, backbone);
    },
    async function handleChartParams(params, originRequest, backbone, next) {
      let newParams = { ...params };

      const chartConfig = get(backbone, ['config', 'chartConfig', 'config'], {});
      const groupPeriodConfig = get(chartConfig, 'groupPeriod', undefined);
      const displayAllBlock = get(chartConfig, 'displayAllBlock', undefined);
      const accumulativeOffline = get(chartConfig, 'accumulativeOffline', 'no') == 'yes';
      const isMultiple = get(chartConfig, 'display', '') === 'multiple';
      const chartGroupBy = get(chartConfig, 'groupBy', '');
      const useDimensionXAxis = get(chartConfig, 'useDimensionXAxis', 'no') === 'yes';
      const columnOrder = get(backbone, ['config', 'columnOrder'], []);
      const mapping = get(backbone, ['config', 'mapping'], {});
      const config = get(backbone, 'config', {});

      const groupDimension: string[] = params.groupDimension
        ? params.groupDimension
        : get(backbone, 'config.groupBy.columns', [])
            .filter((i) => !!i && i !== EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE)
            .map((c) => {
              return mapping[c].valueGetter.value || mapping[c].valueGetter.id;
            });

      const groupPeriod = get(backbone, 'config.groupPeriod', undefined);

      let groupPeriodParam = newParams.groupPeriodParam
        ? newParams.groupPeriodParam
        : displayAllBlock
        ? 'all'
        : groupPeriod || groupPeriodConfig;
      if (accumulativeOffline && groupDimension.length && ['weekly', 'monthly'].includes(groupPeriodParam)) {
        groupPeriodParam = 'daily';
      }
      const chartParams: Record<string, any> = {
        groupPeriod: groupPeriodParam,
        ...(groupPeriodParam === 'all' && (!useDimensionXAxis || (useDimensionXAxis && groupDimension.length === 0))
          ? {
              sort: params.metrics.map((metric) => ({
                field: metric,
                sort: 'DESC',
              })),
            }
          : {}),
      };

      delete newParams.groupPeriodParam;
      delete newParams.groupDimension;

      const aggregations: any[] = [];
      const groupBy = get(params, 'groupBy', { dimensions: groupDimension, aggregations: [] });
      if (
        groupDimension.length === 0 ||
        (groupDimension.length > 0 && groupDimension.every((i) => i === EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE))
        // || chartGroupBy === 'metric'
      ) {
        chartParams.groupAll = true;
      } else {
        Object.entries(backbone.config.mapping).forEach(([field, column]) => {
          const aggs = Object.entries(get(column, ['valueGetter'], {})).reduce((carry, [k, aggField]) => {
            const aggFunc =
              toValue(get(column, ['valueGetter', `${k}.aggFunc`]), {}) || get(column, 'defaultCalculated', '');
            if (!/^value\d*$/.test(String(k)) || !aggField || !aggFunc || groupBy.dimensions.includes(aggField)) {
              return carry;
            }
            return carry.concat({
              field: aggField,
              func: aggFunc,
            });
          }, []);

          aggregations.push(...aggs);
        });
        groupBy.aggregations = aggregations;
      }

      const { namespace } = getQueryParams(endpoint);

      let customGroupBy = null;
      if (namespace) {
        const defaultAggField = columnOrder.reduce((carry, cid: any) => {
          const c = mapping[cid];
          if (c && c.defaultCalculated) {
            const qFields = Object.values(c.valueGetter).filter((f) => !isFormulaField(f));
            qFields.forEach((f: string) => {
              if (!carry[f]) carry[f] = c.defaultCalculated;
            });
          }
          return carry;
        }, {});

        const configAggregations = Object.keys(mapping).reduce((carry, key) => {
          const keyValueGetter = get(mapping, [key, 'valueGetter', 'value'], '');
          const func = get(mapping, [key, 'defaultCalculated'], null);
          if (func) {
            return {
              ...carry,
              [keyValueGetter]: func,
            };
          }
          return carry;
        }, {});

        const aggregations = [].concat(
          params.attributes
            .map((i) => {
              if (isFormulaField(i)) return null;
              const defaultAggFunc = defaultAggField[i] || nextGetDefaultAggFunc('attribute').id;
              const aggFunc: AggFuncType = getAggFunc(
                configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc),
              );
              return {
                field: i,
                func: String(aggFunc.requestQuery).replace(/_f_/g, i),
              };
            })
            .filter((i) => !isEmpty(i)),
          params.metrics
            .map((i) => {
              if (isFormulaField(i)) return null;
              const defaultAggFunc = defaultAggField[i] || nextGetDefaultAggFunc('metric').id;
              const aggFunc: AggFuncType = getAggFunc(
                configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc),
              );

              return {
                field: i,
                func: String(aggFunc.requestQuery).replace(/_f_/g, i),
              };
            })
            .filter((i) => !isEmpty(i)),
        );

        customGroupBy = {
          column: null,
          aggregations,
        };
      }

      newParams = {
        ...newParams,
        ...chartParams,
        groupBy: groupBy.dimensions.length > 0 ? groupBy : customGroupBy,
      };

      return next(newParams, originRequest, backbone);
    },
    async function handleHeatmapChartParams(params, originRequest, backbone, next) {
      const newParams = { ...params };

      const mapping = get(backbone, ['config', 'mapping'], {});
      const chartConfigPath = ['config', 'chartConfig', 'config'];
      const chartType = get(backbone, chartConfigPath.concat('chartType'), '');

      if (chartType != 'heatmap') {
        return next(newParams, originRequest, backbone);
      }

      const heatmapXAxis = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG, X_AXIS], null);
      const heatmapYAxis = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG, Y_AXIS], null);

      const minPeriod = [heatmapXAxis, heatmapYAxis].reduce((time, axis) => {
        if (get(axis, ['type'], '') !== 'time') return time;
        const axisValue = axis?.value === 'date' ? 'daily' : axis?.value;
        const axisOrder = TIME_ORDER.findIndex((el) => el === axisValue);
        const currentOrder = TIME_ORDER.findIndex((el) => el === time);
        return axisOrder > -1 && axisOrder < currentOrder ? axisValue : time;
      }, 'all');
      const groupPeriodParam = minPeriod;

      const heatmapGroupDimension = [heatmapXAxis, heatmapYAxis].reduce((carry, axis) => {
        if (
          get(axis, ['type'], '') === 'time' ||
          !axis ||
          !axis?.value ||
          axis?.value === EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE
        )
          return carry;
        const value = axis.value;
        return [
          ...carry,
          get(mapping, [value, 'valueGetter', 'value'], get(mapping, [value, 'valueGetter', 'id']), ''),
        ];
      }, []);
      const groupDimension = heatmapGroupDimension;

      newParams.groupPeriodParam = groupPeriodParam;
      newParams.groupDimension = groupDimension;
      set(newParams, ['groupBy', 'dimensions'], groupDimension || []);

      return next(newParams, originRequest, backbone);
    },
  )(params, originRequest, backbone);
}

const handleDataRequest = async (originalParams, originalRequest, backbone) => {
  const params = cloneDeep(originalParams);
  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');
  const datetimeFieldRequest = get(backbone, ['config', 'system', 'datetimeField'], 'created_datetime');
  const chartConfigGroupBy = get(backbone, ['config', 'chartConfig', 'config', 'groupBy'], '');

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { dimensions: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { dimensions: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { dimensions: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  params.filter = {
    ...get(params, 'filter', { combinator: 'AND', filters: [] }),
    filters: [
      ...get(params, 'filter.filters', []),
      {
        field: datetimeFieldRequest,
        operator: '>=',
        value: getQueryDatetimeValue(datetimeFieldRequest, params.from, 'start'),
      },
      {
        field: datetimeFieldRequest,
        operator: '<=',
        value: getQueryDatetimeValue(datetimeFieldRequest, params.to, 'end'),
      },
    ],
  };

  if (params.groupBy) {
    params.groupBy.column = params.groupBy.dimensions;
    params.groupBy.aggregations = get(params, 'groupBy.aggregations', []).filter(
      (i) => String(i.func).toUpperCase() !== 'NULL',
    );
  }
  if (!params.groupBy.column && params.groupBy.aggregations) {
    params.groupBy.column = null;
  }
  if (get(params, 'groupBy.drillDowns')) {
    params.filter.filters = params.filter.filters.concat({
      field: get(params, 'groupBy.drillDowns[0].field'),
      operator: '=',
      value: get(params, 'groupBy.drillDowns[0].value'),
    });
    params.groupBy = undefined;
  }

  // save table request params to config to download data
  set(backbone, 'config.tableParams', params);

  const visualizationType = get(backbone, ['config', 'visualizationType'], 'table');
  const cohortConfig = get(backbone, 'config.calendarCohort', null);
  const cohortDateRangeConfig = get(backbone, 'config.cohortDateRange', null);
  const cohortDateRangeFrom = get(cohortDateRangeConfig, 'dateFrom', '');
  const cohortDateRangeTo = get(cohortDateRangeConfig, 'dateTo', '');

  let period = 'days';
  if (params.groupPeriod === 'regularly') period = 'minutes';
  else if (['hourly', 'weekly', 'monthly', 'yearly'].includes(params.groupPeriod))
    period = params.groupPeriod.replace('ly', 's');

  const diffDateCohort = cohortDateRangeConfig ? moment(params.to).diff(moment(cohortDateRangeTo), period) : null;
  let queryCohortParams;
  if (hasCohortProperties(backbone) && cohortConfig) {
    queryCohortParams = {
      ...params,
      ...params.hiddenFilter,
      filter: {
        combinator: 'and',
        filters: [
          ...get(params, 'filter.filters', []).filter((f) => f.field !== 'created_datetime'),
          {
            field: datetimeFieldRequest,
            operator: '>=',
            value: getQueryDatetimeValue(datetimeFieldRequest, cohortDateRangeFrom, 'start'),
          },
          {
            field: datetimeFieldRequest,
            operator: '<=',
            value: getQueryDatetimeValue(datetimeFieldRequest, cohortDateRangeTo, 'end'),
          },
        ],
      },
      _eip: {
        src: 'cohort',
      },
    };
  }

  const cohortTotalParams = {
    ...queryCohortParams,
    groupPeriod: 'all',
    ...(chartConfigGroupBy === 'metric' ? { groupAll: true } : {}),
    _eip: {
      src: 'cohort-total',
    },
  };

  const [originalRes, originalTotalRes, cohortRes, cohortTotalRes, metricDefinitionRes] = await Promise.all([
    originalRequest({ ...params, ...params.hiddenFilter }),
    originalRequest({
      ...params,
      ...params.hiddenFilter,
      groupPeriod: 'all',
      ...(chartConfigGroupBy === 'metric' ? { groupAll: true } : {}),
      _eip: {
        src: 'total',
      },
    }),
    ...(queryCohortParams
      ? [originalRequest(queryCohortParams), originalRequest(cohortTotalParams)]
      : [Promise.resolve(), Promise.resolve()]),
    getMetricDefinition(endpoint),
  ]);

  const result = handleMultiFunction(
    handleOriginalData(backbone),
    handleOriginalTotalData(originalTotalRes, { params }),
    handleCohortData(cohortRes, { visualizationType, params, diffDateCohort, period, groupPeriod: params.groupPeriod }),
    handleCohortTotalData(cohortTotalRes, { params }),
    handleMetricDefinitionData(metricDefinitionRes, { params }),
  )(originalRes);

  return result;
};

const handleOriginalData = (backbone) => {
  return (originalData) => {
    const groupPeriod = get(backbone, ['config', 'groupPeriod']);
    const chartConfig = get(backbone, ['config', 'chartConfig']);
    const mapping = get(backbone, ['config', 'mapping']);
    const metrics = get(backbone, ['config', 'metric']).map((m) => {
      return {
        apiField: mapping[m].valueGetter.value || mapping[m].valueGetter.id,
        aggFunc: mapping[m].staticValue?.aggFunc || 'SUM',
      };
    });
    const groupDimension = get(backbone, 'config.groupBy.columns', [])
      .filter((i) => !!i && i !== EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE)
      .map((c) => {
        return mapping[c].valueGetter.value || mapping[c].valueGetter.id;
      });

    const isoWeekday = get(chartConfig, ['config', 'isoWeekday'], 'Friday');
    const accumulativeOffline = get(chartConfig, ['config', 'accumulativeOffline'], 'no') == 'yes';

    if (accumulativeOffline && ['weekly', 'monthly'].includes(groupPeriod) && groupDimension.length) {
      originalData.data.rows = originalData.data.rows.map((i) => ({
        ...i,
        datetime:
          groupPeriod == 'weekly'
            ? moment(i.datetime).isoWeekday(isoWeekday).format('YYYY-MM-DD HH:mm:ss')
            : moment(i.datetime).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      }));
      Object.values(groupBy(originalData.data.rows, 'datetime')).forEach((v) => {
        Object.values(groupBy(v, (i) => groupDimension.map((field) => i[field]).join('|'))).forEach((v2) => {
          const subArr2 = v2.reduce((subCarry2, v3) => {
            metrics.forEach(({ apiField }) => {
              subCarry2[apiField] = [].concat(subCarry2[apiField]).concat(v3[apiField]);
            });
            return subCarry2;
          });
          metrics.forEach(({ aggFunc, apiField }) => {
            const apiFieldValue = [].concat(subArr2[apiField]);
            const length = apiFieldValue.length;
            if (apiFieldValue.some((i) => i != null)) {
              const sum = apiFieldValue.reduce((a, b) => a + (b || 0), 0);
              if (aggFunc == 'AVG') {
                subArr2[apiField] = sum / length;
              } else {
                subArr2[apiField] = sum;
              }
            } else {
              subArr2[apiField] = null;
            }
          });
        });
      });
    }
    return produceQueryResult(originalData, {}, true);
  };
};

const handleOriginalTotalData = (data, { params }) => {
  return (originalData) => {
    const rows = get(data, 'data.rows', []);
    if (rows.length > 0) {
      const newRows = originalData.data.rows.map((row) => {
        const groupByField = get(params, ['groupBy', 'column', '0'], 'storefront.id');
        const rowTotal = rows.find((el) => el[groupByField] === row[groupByField]) || rows[0];
        const handledRowTotal = Object.keys(rowTotal).reduce((a, b) => {
          a[b] = rowTotal[b];
          if (b.split('.')[1]) a[b.split('.')[1]] = rowTotal[b];
          return a;
        }, {});
        return {
          ...row,
          total: handledRowTotal,
        };
      });
      set(originalData, 'data.rows', newRows);
    }
    return originalData;
  };
};

const handleCohortData = (data, { visualizationType, params, diffDateCohort, period, groupPeriod }) => {
  return (originalData) => {
    const rows = get(data, 'data.rows', []);
    const bHeaders = get(data, 'data.headers', []);

    if (rows.length > 0) {
      const newRows = originalData.data.rows.map((el) => {
        const newData = {};
        if (visualizationType === 'table' || groupPeriod == 'all') {
          let matchProps = bHeaders.filter((header) => get(params, 'attributes', []).includes(header));
          const groupByCol = get(params, ['groupBy', 'column'], []) || [];
          if (groupByCol.length > 0) {
            matchProps = groupByCol;
          }
          const data = rows.find((row) => {
            return matchProps.every((prop) => el[prop] === row[prop]);
          });
          if (data) {
            const dataCohortRow = Object.keys(data).reduce((a, b) => {
              a[b] = data[b];
              if (b.split('.')[1]) a[b.split('.')[1]] = data[b];
              return a;
            }, {});
            Object.keys(dataCohortRow).forEach((el) => {
              if (el !== 'storefront.id') {
                newData[el] = dataCohortRow[el];
              }
            });
          }
        } else {
          const dataCohort = rows.find((ele) => {
            return (
              moment(el.datetime).subtract(diffDateCohort, period).format('YYYY-MM-DD HH:mm:ss') ===
              moment(ele.datetime).format('YYYY-MM-DD HH:mm:ss')
            );
          });

          if (dataCohort) {
            const dataCohortRow = Object.keys(dataCohort).reduce((a, b) => {
              a[b] = dataCohort[b];
              if (b.split('.')[1]) a[b.split('.')[1]] = dataCohort[b];
              return a;
            }, {});

            Object.keys(dataCohortRow).forEach((key) => (newData[key] = dataCohortRow[key]));
          }
        }
        return {
          ...el,
          cohort: newData,
        };
      });
      set(originalData, 'data.rows', newRows);
    }
    return originalData;
  };
};

const handleCohortTotalData = (data, { params }) => {
  return (originalData) => {
    const rows = get(data, 'data.rows', []);
    if (rows.length > 0) {
      const newRows = originalData.data.rows.map((row) => {
        const rowTotal =
          rows.find((el) => el[get(params, ['groupBy', 'column', '0'], 'storefront.id')] === row.id) || rows[0];
        const handledRowTotal = Object.keys(rowTotal).reduce((a, b) => {
          a[b] = rowTotal[b];
          if (b.split('.')[1]) a[b.split('.')[1]] = rowTotal[b];
          return a;
        }, {});
        return {
          ...row,
          total_cohort: handledRowTotal,
        };
      });
      set(originalData, 'data.rows', newRows);
    }
    return originalData;
  };
};

const handleMetricDefinitionData = (data, { params }) => {
  return (originalData) => {
    if (data.length > 0) {
      const newRows = originalData.data.rows.map((row) => {
        return {
          ...row,
          resourceMetric: data,
        };
      });
      set(originalData, 'data.resourceMetric', data);
      set(originalData, 'data.rows', newRows);
    }
    return originalData;
  };
};

const handleMultiFunction = (...funcs) => {
  return (orignialData) => {
    return funcs.reduce((currentData, func) => {
      return func(currentData);
    }, orignialData);
  };
};
