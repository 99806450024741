import { EIP_CONSTANT, ScreenInfoRegister, ScreenScenarioRegister } from '@eip/next/lib/main';
import { useLog, usePage } from '@eip/next/lib/main';
import { useWorkflow, useWorkflowScreen } from '@ep/insight-ui/system/backbone/workflow-backbone';
import * as tableConfig from '@ep/insight-ui/system/block/etable/table-config';
import ScriptInfo from '@ep/insight-ui/elements/input-form/form-templates/form-groups/next-general-info-form';
import ScriptScenarioInput from '@ep/insight-ui/elements/input-form/form-templates/form-groups/scenario-form/next-keep-form';
import { cloneDeep, drop, get, merge, set, slice, uniqBy } from 'lodash';
import moment from 'moment';
import * as React from 'react';

import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import { Box, Button, CircularProgress } from '@material-ui/core';

/**
 * ff.stick_publish_button:start
 */
import { makeStyles } from '@material-ui/core';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import clsx from 'clsx';
/**
 * ff.stick_publish_button:end
 */

import * as request from '../../system/backbone/ha-data-source/common';
import { HelmetMetaData } from '@ep/insight-ui/elements/seo-settings/helmet-data';
import { getPageUrn } from '@ep/insight-ui/eo2/global';

import EdotScript from './edot-script';
import { INPUT_SPECIAL_KEY_DISABLE } from '@ep/insight-ui/elements/textField/special-key';

const log = useLog('chartlib:form:script-info');
type IAction = any;

type IScenario = { screen: WorkflowScreen<ScreenScenarioRegister>; key: string };

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
});

export function ScriptDetails() {
  const classes = useStyles();
  const COMPACT_PROGRAMMATIC_KEYWORD_CONFIG = tableConfig.COMPACT_KEYWORD_OPTIMIZATION_CONFIG;
  const workflow = useWorkflow();
  const screenInfo: WorkflowScreen<ScreenInfoRegister> = useWorkflowScreen('screenInfo');
  const [scenarios, setScenarios] = React.useState<IScenario[]>([]);
  const allOptions = React.useRef<any>({});
  const [countryCode, setCountryCode] = React.useState<string>('');
  const [disableEdit, setDisableEdit] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState(true);

  const screenScenario0: WorkflowScreen<ScreenScenarioRegister> = useWorkflowScreen('screenScenario_0');

  const { title, updateTitle } = usePage();

  const [status, setStatus] = React.useState<any>();

  React.useEffect(() => {
    if (title && screenInfo) {
      screenInfo.set('scriptName', title);
    }
  }, [title]);

  const [screenInfoOptions, setScreenInfoOptions] = React.useState<
    Omit<React.ComponentProps<typeof ScriptInfo>, 'value' | 'onChange'>
  >({
    adType: [],
    country: [],
    existingSetting: [],
    marketplace: [],
    programmaticObject: [],
    scriptTarget: [],
    storefront: [],
    adTool: [],
    configuration: {
      programmaticObject: {
        displayField: 'ADS_PLACEMENT.name',
        idField: 'ADS_PLACEMENT.id',
        config: {
          configuration: {
            ...COMPACT_PROGRAMMATIC_KEYWORD_CONFIG,
            hiddenFilter: {
              combinator: 'AND',
              filters: [{ field: 'ADS_CAMPAIGN.status', dataType: 'string', operator: 'is_not', value: 'ENDED' }],
            },
          },
          tableType: 'compact',
        },
      },
    },
    interval: [],
    repeating: [],
  });

  const [conditionOptions, setConditionOptions] = React.useState<any>({});

  const [initialActionOptions, setInitialActionOptions] = React.useState({
    actionUnit: [],
    actionObject: [],
    actionType: [],
    currency: '',
  });
  const [actionOptions, setActionOptions] = React.useState({
    actionUnit: [],
    actionObject: [],
    actionType: [],
    valueCurrency: '',
    capCurrency: '',
  });

  const [value, setValue] = React.useState<{
    screenInfo: any;
    screenScenario_0: any;
  }>({
    screenInfo: {
      interval: 30,
      marketplace: '',
      country: '',
      storefront: '',
      adTool: '',
      scriptTarget: '',
      existingSetting: '',
    },
    screenScenario_0: {
      timeRange: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().add(7, 'day').format('YYYY-MM-DD'),
      },
      timezone: '+07:00',
      repeating: 'DAILY',
      repeatingOn: null,
      repeatingAt: null,
      action: {
        object: 'STATUS',
        type: 'EDIT',
        value: '',
        unit: 'ONGOING',
        maxValue: '',
      },
      condition: {
        filters: [
          {
            attribute: '',
            combinator: 'and',
            conditionOperator: 'EQUALS',
            targetObject: 'AdsObject',
            timeInclusive: '',
            timeQuantity: 0,
            timeQuery: 'LAST',
            timeUnit: 'DAYS',
            value: 0,
          },
        ],
      },
    },
  });

  React.useEffect(() => {
    if (screenInfo) {
      screenInfo.init().then((data) => {
        log('screeninfoinit', Object.keys(screenInfoOptions));
        const formalData: any = Object.keys(screenInfoOptions)
          .filter((k) => k !== 'configuration' && k != 'existingSetting')
          .reduce((carry, key) => {
            carry[key] = get(data, key, []).map((i) => ({ ...i, label: i.label, value: i.value }));
            return carry;
          }, {});
        log('screeninfoinit', formalData);
        setScreenInfoOptions((options) => ({ ...options, ...formalData }));
        setIsLoading(false);
        allOptions.current = formalData;
      });
    }
    if (screenScenario0) {
      screenScenario0.init().then((data) => {
        const formalData: any = Object.keys(data).reduce((carry, key) => {
          carry[key] = get(data, key, []).map((i) => ({ ...i, label: i.label, value: i.value }));
          return carry;
        }, {});
        setConditionOptions(formalData);
        setInitialActionOptions({
          actionUnit: formalData.actionUnit,
          actionObject: formalData.actionObject,
          actionType: formalData.actionType,
          currency: 'VND',
        });
      });

      const scenariosTemp = [...scenarios];
      scenariosTemp.push({ screen: screenScenario0, key: 'screenScenario_0' });
      setScenarios(scenariosTemp);
    }

    if (workflow) {
      workflow.on('ready', (data: any) => {
        let val = merge({}, value, data);
        val = set(val, 'screenScenario_0.timeRange.dateFrom', get(val, 'screenScenario_0.timeline_from'));
        val = set(val, 'screenScenario_0.timeRange.dateTo', get(val, 'screenScenario_0.timeline_to'));
        screenInfo.inputOnDemand('getCompactTableConfiguration', val.screenInfo).then((tableConfig) => {
          setScreenInfoOptions((options) => ({ ...options, configuration: tableConfig }));
        });
        if (val.screenInfo.programmaticObject && val.screenInfo.programmaticObject.length > 0) {
          screenInfo
            .inputOnDemand('getProgrammaticObject', {
              scriptTarget: val.screenInfo.scriptTarget,
              objectIds: val.screenInfo.programmaticObject,
              marketplace: val.screenInfo.marketplace,
            })
            .then((rows) => {
              screenInfo.get('scriptName').then((name) => updateTitle(name));
              const screenInfoDetail = { ...val.screenInfo, programmaticObject: rows };
              setValue((value) => {
                return { ...val, screenInfo: screenInfoDetail };
              });
              if (
                data.entityId &&
                !data.entityId.toLowerCase().startsWith('draft') &&
                get(data, ['screenInfo', 'scriptStatus'], '') !== 'DRAFT'
              ) {
                setDisableEdit(true);
              }
              onScreenInfoChange(screenInfoDetail, true);
              setTimeout(() => {
                setStatus('ready');
              }, 0);
            });
        } else {
          setValue(val);
          setTimeout(() => {
            setStatus('ready');
          }, 0);
        }

        const scriptTarget = get(val, 'screenInfo.scriptTarget');
        const storefront = get(val, 'screenInfo.storefront');
        const adTool = get(val, 'screenInfo.adTool');

        if (scriptTarget && storefront) {
          screenInfo
            .inputOnDemand('getStorefrontScripts', {
              storefrontId: storefront,
              objectTypeId: scriptTarget,
            })
            .then((ops: { id: string; text: string; payload: { scenarios: any } }[]) => {
              const opsValue = ops.map((item) => ({
                label: item.text,
                value: item.id,
                payload: item.payload,
              }));
              setScreenInfoOptions((options) => {
                const item = { ...options };
                set(item, 'existingSetting', opsValue);
                return item;
              });
            })
            .catch((err) => console.log('getStorefrontScripts in useEffectCallback error', err));
        }

        if (scriptTarget && storefront && adTool) {
          screenScenario0
            .inputOnDemand('getAttributeOptions', {
              adsUnit: scriptTarget,
              marketplace: val.screenInfo.marketplace,
              adTool: adTool,
            })
            .then((options) => {
              setConditionOptions((conditions) => ({
                ...conditions,
                attribute: uniqBy(
                  options.map((i) => ({ ...i, value: i.id, label: i.text })),
                  (i: any) => i.value,
                ),
              }));
            });
        }
      });
    }
  }, []);

  const validUpdatingScenario = React.useMemo(() => {
    const { marketplace, country, storefront, adTool, scriptTarget } = get(value, ['screenInfo'], {});

    return marketplace && country && storefront && adTool && scriptTarget;
  }, [value]);

  const onScreenInfoChange = async (valScenarioChange, isInit = false) => {
    // set scenario with existingSetting
    const existingSetting = get(valScenarioChange, 'existingSetting', '');
    if (!existingSetting) {
      setValue((parentValue) => {
        return {
          ...parentValue,
          screenInfo: { ...valScenarioChange },
        };
      });
    }
    if (!screenInfo) return {};
    const { scriptTarget, adType, adTool, marketplace, storefront, country } = valScenarioChange;
    const objectPayload = {
      adsUnit: scriptTarget,
      marketplace: marketplace,
      adTool: adTool,
    };
    if (scriptTarget != get(value, ['screenInfo', 'scriptTarget']) && !isInit) {
      valScenarioChange.programmaticObject = [];
    }
    Promise.all([
      screenScenario0.inputOnDemand('getAttributeOptions', objectPayload),
      screenScenario0.inputOnDemand('getTargetObjectOptions', {}),
      screenInfo.inputOnDemand('getAdTool', { storefront: valScenarioChange.storefront }),
    ]).then(([options, scriptTargetOptions, adToolOptions]) => {
      const opsValue = adToolOptions.data.map((item) => ({
        label: item.label,
        value: item.value,
        payload: item.payload,
      }));

      let storefrontOptions = allOptions.current.storefront.filter((option) => {
        return (
          (!country || option.payload.country.code === country) &&
          (!marketplace || option.payload.channel.id === marketplace)
        );
      });

      if ((!marketplace && !country && storefront !== get(value, ['screenInfo', 'storefront'])) || storefront) {
        // handle storefront change
        const storefrontData = storefrontOptions.find((it) => it.value == storefront);
        if (storefrontData) {
          const m = get(storefrontData, ['payload', 'channel', 'id'], '');
          const c = get(storefrontData, ['payload', 'country', 'code'], '');
          setValue((parentValue) => {
            return {
              ...parentValue,
              screenInfo: {
                ...valScenarioChange,
                marketplace: m,
                country: c,
              },
            };
          });
          storefrontOptions = storefrontOptions.filter(
            (s) => get(s, ['payload', 'channel', 'id'], '') == m && get(s, ['payload', 'country', 'code'], '') == c,
          );
        }
      }

      setScreenInfoOptions((options) => {
        return {
          ...options,
          adTool: opsValue,
          storefront: storefrontOptions,
        };
      });
      setConditionOptions((conditions) => {
        return {
          ...conditions,
          targetObject: scriptTargetOptions.data.filter((i) => i.value === scriptTarget),
          attribute: uniqBy(
            options.map((i) => ({ ...i, value: i.id, label: i.text })),
            (i: any) => i.value,
          ),
        };
      });
      if (
        (marketplace !== get(value, ['screenInfo', 'marketplace']) ||
          country !== get(value, ['screenInfo', 'country'])) &&
        !isInit
      ) {
        setValue((parentValue) => {
          return {
            ...parentValue,
            screenInfo: {
              ...valScenarioChange,
              storefront: '',
            },
          };
        });
      }

      if (existingSetting) {
        const list = get(screenInfoOptions, 'existingSetting', []);
        const item = list.find((item) => item.value == existingSetting);
        const scenario = get(item, ['scenario', 0], {});
        const condition = get(item, ['condition'], []);
        if (item && scenario.scenario_id) {
          const valScenario = cloneDeep(value);
          const mappedScriptDetail = {
            screenScenario_0: {
              repeatingAt: scenario.repeating_at,
              repeating: scenario.repeating_code,
              repeatingOn: scenario.repeating_on,
              timeRange: {
                dateFrom: scenario.timeline_from,
                dateTo: scenario.timeline_to,
              },
              action: {
                maxValue: scenario.until_reaching,
                object: scenario.action_object_code,
                type: scenario.action_selection_code,
                unit: scenario.step_value_code,
                value: scenario.step_value,
              },
              condition: {
                combinator: 'and',
                filters: condition.map((i) => {
                  return {
                    attribute: i.metric_code,
                    conditionOperator: i.operator_key,
                    timeInclusive: i.custom_timing_code,
                    timeQuantity: i.period,
                    timeQuery: i.range_code,
                    timeUnit: i.timing_code,
                    value: JSON.parse(i.data_value)?.value_compare,
                    targetObject: item.ads_unit,
                  };
                }),
              },
              timeline_from: scenario.timeline_from,
              timeline_to: scenario.timeline_to,
            },
          };
          const actionOb = get(mappedScriptDetail, ['action', 'object'], '');
          if ((actionOb as string).toUpperCase() == 'STATUS') {
            set(valScenario, ['action', 'value'], INPUT_SPECIAL_KEY_DISABLE);
            set(valScenario, ['action', 'maxValue'], INPUT_SPECIAL_KEY_DISABLE);
          }

          const existTimeRange = get(value, ['screenScenario_0', 'timeRange'], null);

          if (existTimeRange) {
            set(valScenario, ['timeRange'], existTimeRange);
          } else {
            const defaultTimeRange = {
              dateFrom: moment().format('YYYY-MM-DD'),
              dateTo: moment().add(7, 'day').format('YYYY-MM-DD'),
            };
            set(valScenario, ['timeRange'], defaultTimeRange);
          }
          setValue(valScenario);
        }
      }
    });

    screenInfo
      .inputOnDemand('getStorefrontScripts', { storefront: valScenarioChange.storefront })
      .then((existedScriptOptions) => {
        setScreenInfoOptions((options) => {
          return {
            ...options,
            existingSetting: existedScriptOptions.data,
          };
        });
      });
    return screenInfo
      .set({
        ...valScenarioChange,
        programmaticObject: (valScenarioChange.programmaticObject || []).map((i) =>
          scriptTarget == 'AdsObject'
            ? i['ads_objects.id']
            : i['ads_placements.id'] || i[screenInfoOptions.configuration.programmaticObject.idField],
        ),
      })
      .then((data) => {
        return screenInfo
          .inputOnDemand('getCompactTableConfiguration', {
            storefront: data.storefront,
            scriptTarget: data.scriptTarget,
            marketplace: data.marketplace,
          })
          .then((config) => {
            setScreenInfoOptions((options) => {
              return { ...options, configuration: config };
            });
          });
      })
      .then(() => {
        return {};
      });
  };

  const handleOnChangeScenario = (scenario: IScenario) => async (valScenarioChange) => {
    if (scenario.screen) {
      const timeline_to = moment.utc(valScenarioChange.timeRange.dateTo, 'YYYY-MM-DD').startOf('day').utcOffset(0);
      const timeline_from = moment.utc(valScenarioChange.timeRange.dateFrom, 'YYYY-MM-DD').startOf('day').utcOffset(0);
      const fval = {
        ...valScenarioChange,
        timeline_to: timeline_to.isValid() ? timeline_to.format('YYYY-MM-DD 23:59:59') : '',
        timeline_from: timeline_from.isValid() ? timeline_from.format('YYYY-MM-DD 00:00:00') : '',
      };
      // const valueTemp = { ...value };
      // set(valueTemp, [scenario.key], fval);

      if (fval.condition.filters.length > 2) {
        const conditionFilter = [];
        const operator = fval.condition.filters[1].combinator;
        fval.condition.filters.map((item, index) => {
          const result = { ...item };
          if (index > 1) {
            result.combinator = operator;
          }
          conditionFilter.push(result);
        });
        set(fval, 'condition.filters', conditionFilter);
      }

      const actionOb = get(valScenarioChange, ['action', 'object'], '');
      if ((actionOb as string).toUpperCase() == 'STATUS') {
        set(fval, ['action', 'value'], INPUT_SPECIAL_KEY_DISABLE);
        set(fval, ['action', 'maxValue'], INPUT_SPECIAL_KEY_DISABLE);
        if (!get(fval, ['action', 'type'])) set(fval, ['action', 'type'], 'EDIT');
        if (!get(fval, ['action', 'unit'])) set(fval, ['action', 'unit'], 'Ongoing');
      } else if ((actionOb as string).toUpperCase() == 'BIDDING_PRICE') {
        if (fval.action.value == INPUT_SPECIAL_KEY_DISABLE) {
          set(fval, ['action', 'value'], '');
        }

        if (fval.action.maxValue == INPUT_SPECIAL_KEY_DISABLE) {
          set(fval, ['action', 'maxValue'], '');
        }
        if (fval.condition.filters.every((condition) => condition.targetObject !== 'AdsObject')) {
          if (!get(fval, ['action', 'maxValue'])) set(fval, ['action', 'maxValue'], 0);
          if (!get(fval, ['action', 'type'])) set(fval, ['action', 'type'], 'INCREASE');
          if (!get(fval, ['action', 'unit'])) set(fval, ['action', 'unit'], 'STEP_PERCENTAGE');
        }
      }

      if (valScenarioChange?.repeating != get(value, ['screenScenario_0', 'repeating'])) {
        set(fval, 'repeatingOn', null);
        set(fval, 'repeatingAt', null);
      }
      setValue((val) => ({ ...val, [scenario.key]: fval }));
      return scenario.screen.set(fval);
    }
    return {};
  };

  const addNewScenario = () => async () => {
    const key = 'screenScenario_' + scenarios.length;
    const newScreen = await workflow.addScreen(`screenScenario_0`, key);
    const valueTemp = { ...value };
    const valScenario = {
      timeRange: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().add(7, 'day').format('YYYY-MM-DD'),
      },
      timezone: '+07:00',
      action: {
        object: 'BIDDING_PRICE',
        type: '',
        value: '',
        unit: '',
        maxValue: '',
      },
      condition: {
        filters: [],
      },
    };
    set(valueTemp, [key], valScenario);
    setValue(valueTemp);
    const tempScenarios = [...scenarios];
    tempScenarios.push({ screen: newScreen, key });
    setScenarios(tempScenarios);
  };

  log('options', { tableConfiguration: screenInfoOptions.configuration, actionOptions, conditionOptions });

  const duplicateScenario = () => () => undefined;
  const removeScenario = (index) => () => {
    if (scenarios.length > 1) {
      const tempScenarios = [...scenarios];
      tempScenarios.splice(index, 1);
      setScenarios(tempScenarios);
    }
  };

  const generateActionOptions = (value) => {
    const currency = screenInfoOptions.country.find((el) => el.value === countryCode);
    const obAction: string = get(value, ['action', 'object'], '');

    switch (obAction.toUpperCase()) {
      case 'STATUS': {
        return {
          actionUnit: initialActionOptions.actionUnit.filter((item) =>
            (item.actionObject as Array<string>).includes('STATUS'),
          ),
          actionObject: initialActionOptions.actionObject.filter((action) => {
            const conditionHasAdsObject = value.condition.filters.some((filter) => filter.targetObject === 'AdsObject');
            return !conditionHasAdsObject || (conditionHasAdsObject && action.value !== 'BIDDING_PRICE');
          }),
          actionType: drop(initialActionOptions.actionType, 2),
          valueCurrency: currency ? currency.payload.currency : '',
          capCurrency: currency ? currency.payload.currency : '',
        };
      }

      case 'BIDDING_PRICE': {
        return {
          actionUnit: initialActionOptions.actionUnit.filter((item) =>
            (item.actionObject as Array<string>).includes('BIDDING_PRICE'),
          ),
          actionObject: initialActionOptions.actionObject.filter((action) => {
            const conditionHasAdsObject = value.condition.filters.some((filter) => filter.targetObject === 'AdsObject');
            return !conditionHasAdsObject || (conditionHasAdsObject && action.value !== 'BIDDING_PRICE');
          }),
          actionType: slice(initialActionOptions.actionType, 0, 2),
          valueCurrency: get(value, ['action', 'unit'], '') == 'STEP_PERCENTAGE' ? '%' : initialActionOptions.currency,
          capCurrency: currency ? currency.payload.currency : '',
        };
      }

      default:
        return {
          actionType: [],
          actionUnit: initialActionOptions.actionUnit,
          actionObject: initialActionOptions.actionObject.filter((action) => {
            const conditionHasAdsObject = value.condition.filters.some((filter) => filter.targetObject === 'AdsObject');
            return !conditionHasAdsObject || (conditionHasAdsObject && action.value !== 'BIDDING_PRICE');
          }),
          valueCurrency: currency ? currency.payload.currency : '',
          capCurrency: currency ? currency.payload.currency : '',
        };
    }
  };

  const requestOptions = {
    getAdTool: ({ storefront }) => request.default.getAdTool({ storefront }),
    getAdType: () => request.default.getAdType(),
    getCountry: () => request.default.getCountry(),
    getMarketplace: () => request.default.getMarketplace(),
    getScriptTarget: request.default.getScriptTarget,
    getStatus: request.default.getStatus,
    getStorefront: () => request.default.getStorefront(),
    ...(ff.script_interval ? { getIntervals: request.default.getInterval } : {}),
    getRepeating: request.default.getRepeating,
  };
  return (
    <LoadingComponent
      loading={workflow && status !== 'ready'}
      error={value?.success === false}
      errorMessage={'No data available.'}
    >
      <div className={classes.container}>
        {value.entityId && (
          <EdotScript
            entityId={value.entityId}
            marketplace={get(value, 'context.marketplace', '')}
            type={get(value, 'context.type', undefined)}
          />
        )}
        {value.screenInfo.marketplace && (
          <HelmetMetaData
            title={`Script details/${value.screenInfo.marketplace}/${title}`}
            description="Epsilo is an industry-leading platform for e-commerce teams to grow revenue with automation, research, and collaboration"
          ></HelmetMetaData>
        )}
        <ScriptInfo
          onChange={onScreenInfoChange}
          value={value.screenInfo}
          adType={screenInfoOptions.adType}
          country={screenInfoOptions.country}
          existingSetting={screenInfoOptions.existingSetting}
          marketplace={screenInfoOptions.marketplace}
          programmaticObject={screenInfoOptions.programmaticObject}
          scriptTarget={screenInfoOptions.scriptTarget}
          storefront={screenInfoOptions.storefront}
          adTool={screenInfoOptions.adTool}
          configuration={screenInfoOptions.configuration}
          setCountryCode={setCountryCode}
          disableEdit={disableEdit}
          requestOptions={requestOptions}
          isLoading={isLoading}
          {...(ff.script_interval ? { interval: screenInfoOptions.interval } : {})}
        />
        {scenarios.map((item, index) => (
          <ScriptScenarioInput
            key={index}
            label={`Scenario`}
            value={value[item.key]}
            conditionOptions={conditionOptions}
            actionOptions={generateActionOptions(value[item.key])}
            onChange={handleOnChangeScenario(item)}
            repeating={screenInfoOptions.repeating}
            menuFuncs={{
              addFunc: addNewScenario(index),
              duplicateFunc: duplicateScenario(index),
              removeFunc: removeScenario(index),
            }}
            disableEdit={disableEdit}
            requestOptions={requestOptions}
            validUpdatingScenario={validUpdatingScenario}
          />
        ))}
        {/* <ScriptScenarioInput
          label="Scenario:"
          value={value.screenScenario_0}
          conditionOptions={conditionOptions}
          actionOptions={actionOptions}
          onChange={onChangeScenario}
          dataMenu={dataMenu}
        /> */}
        <SubmitControl
          hide={disableEdit}
          onSubmit={() => {
            return screenInfo.get('scriptName').then(async (name) => {
              if (name !== title) {
                await screenInfo.set('scriptName', name);
              }
            });
          }}
          value={value}
          setDisableEdit={setDisableEdit}
        />
      </div>
    </LoadingComponent>
  );
}

function SubmitControl({
  hide,
  onSubmit,
  value,
  setDisableEdit,
}: {
  hide: boolean;
  onSubmit: () => Promise<void>;
  value: {
    screenInfo: any;
    screenScenario_0: any;
  };
  setDisableEdit: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const isDraft = get(value, ['screenInfo', 'scriptStatus'], '') === 'DRAFT';
  const scriptId = get(value, ['screenInfo', 'scriptId'], '');

  let isMobile, useStyles, classes;
  if (ff.stick_publish_button) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobile = containerClass === 'eres--small';
    useStyles = makeStyles({
      buttonContain: {
        position: 'sticky',
        width: '100vw',
        bottom: '0',
        left: '0',
        justifyContent: 'space-around',
        backgroundColor: '#FFF',
        padding: '7px 10px',
        marginLeft: '-52px',
        borderTop: '1px solid #C2C7CB',
        '&.buttons': {
          height: '60px',
        },
      },
      buttonSubmit: { width: '70%', height: '100%' },
      buttonCancel: { width: '30%', backgroundColor: 'inherit', height: '100%' },
    });
    classes = useStyles();
  }
  const workflow = useWorkflow();

  const [isLoading, setIsLoading] = React.useState(false);

  if (isDraft) {
    return (
      <Box
        height={'100%'}
        display={hide ? 'none' : 'flex'}
        flexDirection={'row-reverse'}
        alignItems={'center'}
        padding={[0, 4]}
        {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonContain]: isMobile }, 'buttons') } : {})}
      >
        <Button
          {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonSubmit]: isMobile }) } : {})}
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={() => {
            if (workflow) {
              setIsLoading(true);
              onSubmit().then(() => {
                workflow
                  .submit(
                    {
                      screenInfo: {
                        scriptId: scriptId,
                        scriptStatus: 'ONGOING',
                      },
                    },
                    {
                      operation: 'UPDATE',
                    },
                  )
                  .then((res) => {
                    if (res.entityId) {
                      setDisableEdit(true);
                    }
                    setIsLoading(false);
                  });
              });
            }
          }}
        >
          Publishing
        </Button>
        <Box width={12}></Box>
        <Button
          {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonSubmit]: isMobile }) } : {})}
          variant="contained"
          color="secondary"
          disabled={isLoading}
          onClick={() => {
            if (workflow) {
              setIsLoading(true);
              onSubmit().then(() => {
                workflow
                  .submit(
                    {
                      screenInfo: {
                        scriptId: scriptId,
                        scriptStatus: 'DRAFT',
                      },
                    },
                    {
                      operation: 'UPDATE',
                    },
                  )
                  .then(() => {
                    setIsLoading(false);
                  });
              });
            }
          }}
        >
          Save draft
        </Button>
      </Box>
    );
  }

  return (
    <Box
      height={'100%'}
      display={hide ? 'none' : 'flex'}
      flexDirection={'row-reverse'}
      alignItems={'center'}
      padding={[0, 4]}
      {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonContain]: isMobile }, 'buttons') } : {})}
    >
      {isLoading && <CircularProgress color={'primary'} size={'1em'} />}
      <Button
        {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonSubmit]: isMobile }) } : {})}
        variant="contained"
        color="primary"
        disabled={isLoading}
        onClick={() => {
          if (workflow) {
            setIsLoading(true);
            onSubmit().then(() => {
              workflow.submit().then(() => {
                setIsLoading(false);
              });
            });
          }
        }}
      >
        Submit
      </Button>
      <Box width={12}></Box>
      <Button
        {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonSubmit]: isMobile }) } : {})}
        variant="contained"
        color="primary"
        disabled={isLoading}
        onClick={() => {
          if (workflow) {
            setIsLoading(true);
            onSubmit().then(() => {
              workflow
                .submit({
                  screenInfo: {
                    scriptStatus: 'DRAFT',
                  },
                })
                .then(() => {
                  setIsLoading(false);
                });
            });
          }
        }}
      >
        Save draft
      </Button>
      <Box width={12}></Box>
      <Button
        {...(ff.stick_publish_button ? { className: clsx({ [classes.buttonCancel]: isMobile }) } : {})}
        variant="contained"
        color="secondary"
        onClick={() => {
          window.location.href = getPageUrn('/page/' + EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.hyperAutomation);
        }}
      >
        Cancel
      </Button>
    </Box>
  );
}
