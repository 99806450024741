import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    justifyContent: 'space-between',
    boxShadow: colors.shadow.default,
    padding: '7px 12px 7px 8px',
  },
  paper: {
    border: `1px solid ${colors.border.subdued}`,
    borderRadius: '12px',
    backgroundColor: 'white',
  },
}));

type TopBarProps = {
  children?: React.ReactNode;
};

// eslint-disable-next-line react/display-name
const TopBar: React.FC<TopBarProps> = (props: TopBarProps) => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.container}`} alignItems="center" {...props}>
      {props.children}
    </Grid>
  );
};

export default TopBar;
