import { useLog } from '@eip/next/lib/log';
import { canAccess, getUserSettings, isSuperAdmin, isViewAsUserMode } from '@ep/one/aim';
import { request } from '@ep/one/src/utils';
import { get, mapValues } from 'lodash';
import { v4 as uuid } from 'uuid';
import { blockMapping, pageMapping } from './server-mapping';
import minimatch from 'minimatch';
import { EIP_CONSTANT } from '../../constant';

const log = useLog('hooks:preset-server');
const error = log.extend('error');

const cachedPageSections = {};

export function usePageServer(serverURL) {
  function getUserId() {
    return getUserSettings().profile.userId;
  }
  function getUserEmail() {
    return getUserSettings().profile.userEmail;
  }

  function getList() {
    return request
      .get(`${serverURL}/system_page/list`)
      .then((res) => {
        const list = mapValues(res.block, (i) => {
          return pageMapping(i);
        });

        return list;
      })
      .catch((err) => {
        error('getlist', err);
        return [];
      });
  }

  function getSingle(presetEid, isEditMode = false) {
    let userId = getUserId();
    let isAnonymous = false;
    const isViewAsUser = isViewAsUserMode();
    if (isViewAsUser) {
      isAnonymous = getUserSettings().adminConfig.isAnonymous;
      if (!isAnonymous) {
        userId = getUserSettings().adminConfig.override?.userId;
      }
    }
    if (!userId && !isAnonymous) return Promise.resolve(null);
    if (!isEditMode) {
      const queryParams = new URLSearchParams({
        id: presetEid,
        ...(isViewAsUser && !isAnonymous ? { user_id: userId } : {}),
      }).toString();
      return Promise.all([
        request.get(`${serverURL}/page/chunk?` + queryParams),
        isAnonymous ? Promise.resolve({}) : request.get(`${serverURL}/page/persona?` + queryParams),
      ])
        .then(([resPageChunk, resPersona]) => {
          if (isAnonymous) {
            const blockEid = uuid();
            resPersona = {
              [blockEid]: {
                block_eid: blockEid,
                block_id: Math.floor(Math.random() * 10000),
                block_type: 'user_persona',
                content: [presetEid],
                created_at: new Date(),
                deleted_at: null,
                entity_id: null,
                entity_type: null,
                owner_id: userId,
                parent_id: presetEid,
                parent_table: 'eip_system_block',
                payload: {
                  properties: {
                    draftData: {},
                    groupId: 'botep',
                    systemVersion: new Date().getTime(),
                    title: 'Anonymous',
                    version: new Date().getTime(),
                    blockPersona: Object.entries(resPageChunk.block).reduce((carry, [key, value]) => {
                      if (value.block_type != 'page') {
                        return {
                          ...carry,
                          [key]: {},
                        };
                      }
                      return carry;
                    }, {}),
                  },
                },
              },
            };
          }
          const pageBlocks = Object.entries(resPersona).reduce((carry, [k, v]) => {
            return {
              ...carry,
              [k]: v,
            };
          }, resPageChunk.block);

          if (pageBlocks) {
            const blocks = mapValues(pageBlocks, (block) => {
              return blockMapping(block);
            });

            const allBlocks = Object.entries(pageBlocks)
              .filter(([k, v]: [string, any]) => v.block_type === 'page')
              .map(([k, p]) => {
                const mPage = pageMapping(p);
                mPage.nodes = mPage.nodes
                  .map((node) => {
                    if (blocks[node.id]) {
                      return blocks[node.id];
                    }
                    return null;
                  })
                  .filter((i) => i !== null);

                return [k, mPage];
              })
              .reduce((carry, [k, v]) => {
                return { ...carry, [k]: v };
              }, blocks);

            return allBlocks;
          } else {
            return {};
          }
        })
        .catch((err) => {
          log('fail load chunk', err);
          return null;
        });
    }
    return request
      .post(`${serverURL}/page/chunk`, { id: presetEid, user_id: userId })
      .then((res) => {
        if (res.block) {
          const blocks = mapValues(res.block, (block) => {
            return blockMapping(block);
          });

          const allBlocks = Object.entries(res.block)
            .filter(([k, v]: [string, any]) => v.block_type === 'page')
            .map(([k, p]) => {
              const mPage = pageMapping(p);
              mPage.nodes = mPage.nodes
                .map((node) => {
                  if (blocks[node.id]) {
                    return blocks[node.id];
                  }
                  return null;
                })
                .filter((i) => i !== null);

              return [k, mPage];
            })
            .reduce((carry, [k, v]) => {
              return { ...carry, [k]: v };
            }, blocks);

          return allBlocks;
        } else {
          return {};
        }
      })
      .catch((err) => {
        log('fail load chunk', err);
        return null;
      });
  }

  function update(changeset: any[], pageId?: string): Promise<Dashboard> {
    if (isViewAsUserMode()) return Promise.resolve(null);

    return request
      .post(`${serverURL}/system_page/save_changeset`, {
        request_id: uuid(),
        user_id: getUserId(),
        user_email: getUserEmail(),
        changeset: changeset,
      })
      .then((res) => {
        if (pageId) {
          request.get('/api/v1/refresh-caches?pageId=' + pageId);
        }
        return null;
      });
  }

  function clonePage(blockEid, clonedId, groupId = 'botep') {
    if (isViewAsUserMode()) return Promise.resolve(null);
    return request
      .post(`${serverURL}/system_page/save_changeset`, {
        request_id: uuid(),
        user_id: getUserId(),
        user_email: getUserEmail(),
        changeset: [
          {
            pointer: { id: blockEid, table: 'eip_system_block' },
            op: 'clone',
            path: [],
            value: {
              blockEid: clonedId,
              properties: {
                groupId: groupId,
                workflowId: null,
                accessBy: ['*@epsilo.io'],
              },
            },
          },
        ],
      })
      .then((res) => {
        return { blockEid: clonedId };
      });
  }
  function removePage(blockEid) {
    if (isViewAsUserMode()) return Promise.resolve(null);
    return request.post(`${serverURL}/system_page/save_changeset`, {
      request_id: uuid(),
      user_id: getUserId(),
      user_email: getUserEmail(),
      changeset: [
        {
          pointer: { id: blockEid, table: 'eip_system_block' },
          op: 'remove',
          path: [],
          value: {},
        },
      ],
    });
  }

  const getPageSections = (pageId, forceReload) => {
    const queryParams = new URLSearchParams({
      id: pageId,
    }).toString();
    if (!cachedPageSections[pageId] || forceReload) {
      cachedPageSections[pageId] = request.get(`${serverURL}/page/chunk?` + queryParams).then((res) => {
        const blocks = get(res, ['block'], {});
        const layouts = _.cloneDeep(get(blocks, [pageId, 'payload', 'format', 'layouts', 'lg'], [])).sort((a, b) => {
          const xA = get(a, ['layout', 'x'], 0);
          const yA = get(a, ['layout', 'y'], 0);
          const xB = get(b, ['layout', 'x'], 0);
          const yB = get(b, ['layout', 'y'], 0);
          if (xA <= xB && yA <= yB) return -1;
          return 1;
        });
        const { userEmail, userId } = getUserSettings().profile || {};
        const sections = layouts
          .filter((layout) => {
            if (isSuperAdmin()) return true;
            let allowAccess = false;
            let legacyAllowAccess = true;

            const nextPermission = get(blocks, [layout.id, 'payload', 'properties', 'next_permission'], []);
            const permission = get(blocks, [layout.id, 'payload', 'properties', 'permission'], []);
            if (permission && permission.length > 0) {
              legacyAllowAccess = permission.includes(userEmail);
            }

            const includedAccess = nextPermission.filter((i) => !String(i).startsWith('!'));
            const excludeAccess = nextPermission.filter((i) => String(i).startsWith('!'));

            allowAccess = allowAccess || includedAccess.length === 0;
            allowAccess = allowAccess || includedAccess.some((i) => minimatch(userId, i, { matchBase: true }));
            allowAccess =
              allowAccess ||
              includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
            allowAccess =
              allowAccess &&
              (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userId, i, { matchBase: true })));

            allowAccess =
              allowAccess &&
              (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

            return legacyAllowAccess && allowAccess;
          })
          .map((layout) => {
            const section = get(
              blocks,
              [layout.id, 'payload', 'properties', 'customAttributes', 'configuration'],
              get(blocks, [layout.id, 'payload', 'properties', 'customAttributes'], {}),
            );

            return {
              title: section.title,
              label: section.title,
              nodeId: layout.id,
              icon: 'ic/ic:round-table-chart/#3F4F5C',
              tabs: [],
              type: 'page_section',
              pageId: pageId,
            };
          });
        return sections;
      });
    }

    return cachedPageSections[pageId];
  };

  const getUserList = () => {
    if (canAccess('internal')) {
      const payload = {
        dimensions: ['user'],
        attributes: ['user.email', 'user.uid', 'user.status'],
        metrics: [],
        pagination: {
          page: 1,
          limit: 9999,
        },
        from: '2024-01-01 00:00:00',
        to: '2024-12-31 23:59:59',
        sort: [
          {
            field: 'user.created_at',
            sort: 'DESC',
          },
        ],
        filter: {
          combinator: 'and',
          filters: [
            {
              field: 'user.type',
              operator: 'is',
              value: 'main',
              dataType: 'string',
            },
          ],
        },
        hiddenFilter: {
          currency: 'USD',
        },
        currency: 'USD',
        isSummary: true,
      };
      return request
        .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=passport&mainColumn=user.uid', payload)
        .then((res) => {
          const headers = get(res, ['data', 'headers'], []);
          const rows = get(res, ['data', 'rows'], []);

          const result = rows.reduce((carry, row) => {
            const emailIndex = headers.findIndex((i) => i == 'user.email');
            const statusIndex = headers.findIndex((i) => i == 'user.status');
            const createdAtIndex = headers.findIndex((i) => i == 'user.created_at');
            return {
              ...carry,
              [row[emailIndex]]: {
                createdAt: row[createdAtIndex],
                status: row[statusIndex],
              },
            };
          }, {});

          return result;
        });
    }

    return Promise.reject(null);
  };

  return {
    getList,
    getSingle,
    update,
    clone: clonePage,
    remove: removePage,
    getPageSections,
    getUserList,
  };
}
