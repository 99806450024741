import CompactFormat from '../../cell-format/compact-format';
import { CellEditorInputFormat, CellEditorViewFormat } from '../../cell-format/input-format';
import SelectFormat from '../../cell-format/select-format';
import { ACTION_CATEGORY_PERIOD } from '../constant';

export const defaultPeriodConfig = [
  { label: 'Last 7 days', value: 'last_7_days', shortcut: 'L7D' },
  { label: 'Last 15 days', value: 'last_15_days', shortcut: 'L15D' },
  { label: 'Last 30 days', value: 'last_30_days', shortcut: 'L30D' },
  { label: 'This month', value: 'this_month', shortcut: 'TM' },
  { label: 'Last month', value: 'last_month', shortcut: 'LM' },
  { label: 'This year', value: 'this_year', shortcut: 'TY' },
  { label: 'Last year', value: 'last_year', shortcut: 'LY' },
  { label: 'Year to date', value: 'year_to_date', shortcut: 'YTD' },
];

export function advanceConfigurationForm(params) {
  return {
    component: CompactFormat,
    params: {
      isConfig: true,
      isCustom: true,
      config: {
        colDefs: [
          {
            field: 'label',
            headerName: 'Label',
            width: 100,
            cellRenderer: SelectFormat,
            cellRendererParams: {
              options: [...defaultPeriodConfig],
              hasSearch: true,
              field: 'label',
              allowCustomOption: true,
              allowedRemove: true,
            },
            onCellValueChanged(params) {
              const period = defaultPeriodConfig.find(({ value }) => value === params.newValue);
              if (period) {
                params.node.setData({
                  ...params.data,
                  label: period.label,
                  value: period.value,
                  shortcut: period.shortcut,
                });
              }
            },
            editable: true,
          },
          {
            field: 'value',
            headerName: 'Value',
            width: 100,
            cellRenderer: CellEditorViewFormat,
            cellEditor: CellEditorInputFormat,
            editable: true,
          },
          {
            field: 'shortcut',
            headerName: 'Shortcut',
            width: 100,
            cellRenderer: CellEditorViewFormat,
            cellEditor: CellEditorInputFormat,
            editable: true,
          },
        ],
        getDefaultRowData() {
          return {
            label: '',
            value: '',
            shortcut: '',
          };
        },
      },
      addRowLabel: 'Add period',
    },
  };
}
