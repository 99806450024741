import * as dimension from './dimension';
import * as attribute from './attribute';
import * as metric from './metric';

export const options = [...dimension.options, ...attribute.options, ...metric.options];

export function getCellFormat(name) {
  let foundOne;
  foundOne = dimension.getCellFormat(name);
  if (foundOne === null) {
    foundOne = attribute.getCellFormat(name);
  }
  if (foundOne === null) {
    foundOne = metric.getCellFormat(name);
  }

  return foundOne;
}
