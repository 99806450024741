import { makeStyles, Popover } from '@material-ui/core';
import * as React from 'react';
import { createPortal } from 'react-dom';
import { useLog } from '@eip/next/lib/main';
import { get } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
/**
 * ff.ecompacttable_layout_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { Box, Button } from '@material-ui/core';
import { Provider } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { nanoid } from 'nanoid';
/**
 * ff.ecompacttable_layout_mobile:end
 */

const useStyles = makeStyles({
  paper: {
    '& .eip1-MuiPopover-paper': {
      maxHeight: 'unset',
    },
  },
  ...(ff.ecompacttable_layout_mobile
    ? {
        modalContent: {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: '#F6F9FF',
          boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
          padding: '20px 16px',
          outline: 'none',
          overflowY: 'auto',
        },
        modalHeader: {
          position: 'relative',
          textAlign: 'center',
          padding: '10px',
          background: '#FFF',
          borderRadius: '8px',
        },
        modalGroupItems: {
          marginTop: '16px',
          overflowY: 'auto',
          '& .rmdp-header': {
            padding: '16px 0',
          },
        },
        groupItem: {
          background: '#FFF',
          '&:first-child': {
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
          },
          '&:last-child': {
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px',
          },
          '& button': {
            justifyContent: 'flex-start',
            width: '100%',
            fontWeight: 400,
            padding: '10px',
            '&.eip1-MuiButton-text:hover': {
              backgroundColor: 'transparent',
            },
            '&.eip1-MuiButton-text:active': {
              backgroundColor: '#006EC6',
            },
          },
          '& button .eip1-MuiButton-label': {
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            padding: 0,
            width: '100%',
          },
          '& .disabled': {
            color: '#C2C7CB',
          },
        },
        groupItemTitle: {
          textAlign: 'left',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
        },
        groupItemPlaceholder: {
          color: '#8C98A4',
        },
        modalTitle: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',
          margin: 0,
          color: '#253746',
        },
        modalButton: {
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translate(0, -50%)',
        },
        modalButtonCancel: {
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translate(0, -50%)',
          color: '#8C98A4',
        },
        buttonMenuItem: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: '100%',
        },
        buttonMenuLabel: {
          display: 'flex',
          alignItems: 'center',
          columnGap: '10px',
        },
        textInput: {
          width: '100%',
          background: '#FFF',
          marginTop: '16px',
          padding: '10px',
          borderRadius: '4px',
          '& .eip1-MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
          '& .eip1-MuiOutlinedInput-input': {
            padding: 0,
          },
        },
        modalQuickActionContent: {
          height: '100vh',
          width: '100vw',
          position: 'relative',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          paddingBottom: '20px',
        },
        modalQuickActionMenu: {
          width: '95%',
        },
        modalAction: {
          backgroundColor: '#FFFFFF',
          borderRadius: '4px',
          marginTop: '16px',
        },
        mobileBtnAdd: {
          width: '100%',
          justifyContent: 'flex-start',
          background: '#fff',
          color: '#253746',
          padding: '16px',
          '&:hover': {
            backgroundColor: '#fff',
          },
        },
        mobileButton: {
          position: 'absolute',
          top: '50%',
          right: '10px',
          transform: 'translate(0, -50%)',
        },
      }
    : {}),
  compactContainer: {
    '& .ag-root.ag-unselectable.ag-layout-normal': {
      maxHeight: '100%',
    },
    height: 'calc(100% - 33px)',
    overflow: 'auto',
  },
});
const log = useLog('lib:table-backbone:compact-factory');

type actions = {
  cancelBtnLabel?: string;
  submitBtnLabel?: string;
  onSubmit?: ({ onClose }: { onClose: () => void }) => void;
};

export const TableCompactFactoryContext = React.createContext({
  display(args: {
    visible: boolean;
    tableEl: JSX.Element;
    tableConfig: any;
    anchorEl: HTMLElement;
    onClosed?: () => void;
    actions?: actions;
  }) {
    return;
  },
});

export function CompactFactoryProvider({ children }: { children: JSX.Element }) {
  const [tables, setTables] = React.useState<
    Record<
      string,
      {
        anchorEl: HTMLElement;
        display: boolean;
        el: JSX.Element;
        id: string;
        onClosed: () => void;
        actions?: actions;
      }
    >
  >({});

  const display = React.useCallback(
    (args: {
      visible: boolean;
      tableEl: JSX.Element;
      tableConfig: any;
      anchorEl: HTMLElement;
      onClosed?: () => void;
      actions?: actions;
    }) => {
      const tableId = get(
        args,
        'tableConfig.config.configuration.tableId',
        args.tableConfig.config.tableCompactId || nanoid(),
      );
      const existTable = tables[tableId];
      if (existTable) {
        setTables({
          ...tables,
          [tableId]: {
            ...existTable,
            el: args.tableEl,
            display: args.visible,
            anchorEl: args.anchorEl,
            onClosed: get(args, 'onClosed', () => undefined),
            actions: args.actions,
          },
        });
      } else {
        setTables({
          ...tables,
          [tableId]: {
            id: tableId,
            anchorEl: args.anchorEl,
            el: args.tableEl,
            display: args.visible,
            onClosed: get(args, 'onClosed', () => undefined),
            actions: args.actions,
          },
        });
      }
    },
    [tables],
  );

  const handleOnClose = (tableId, onCloseCallback) => {
    return () => {
      onCloseCallback();
      setTables((tables) => {
        tables[tableId].display = false;
        return {
          ...tables,
          [tableId]: {
            ...tables[tableId],
            display: false,
          },
        };
      });
    };
  };

  log('tables', tables);

  return (
    <DndProvider backend={HTML5Backend}>
      <TableCompactFactoryContext.Provider value={{ display }}>
        {children}
        {Object.values(tables).map((table) => {
          return (
            <Provider initialValues={[[eTableAtom.tableId, table.id]]}>
              <CompactTableInstance
                onClose={handleOnClose(table.id, table.onClosed)}
                key={table.id}
                display={table.display}
                tableId={table.id}
                anchorEl={table.anchorEl}
                actions={table.actions}
              >
                {table.el}
              </CompactTableInstance>
            </Provider>
          );
        })}
      </TableCompactFactoryContext.Provider>
    </DndProvider>
  );
}

function CompactTableInstance({
  onClose,
  anchorEl,
  display,
  children,
  tableId,
  actions,
}: {
  onClose: () => void;
  anchorEl: HTMLElement;
  display: boolean;
  children: JSX.Element;
  tableId: string;
  actions: actions;
}) {
  log('compact instance', {
    anchorEl,
    display,
    children,
    tableId,
  });
  const classes = useStyles();
  const [wrapper, setWrapper] = React.useState<HTMLElement>(null);
  const [wrapperChildren, setWrapperChildren] = React.useState<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const PopoverRef = React.useRef(null);

  React.useEffect(() => {
    let wrapper: HTMLElement = document.getElementById(tableId);
    if (!wrapper) {
      wrapper = document.createElement('div');
      wrapper.id = tableId;
      document.body.append(wrapper);
    }

    log('wrapper', wrapper);

    setWrapper(wrapper);
  }, []);
  let isMobileView;
  if (ff.ecompacttable_layout_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }

  const handleSubmit = async () => {
    setIsSubmitting(true);

    if (actions?.onSubmit) {
      try {
        await actions.onSubmit({ onClose });
      } catch {}
    }

    setIsSubmitting(false);
  };

  React.useLayoutEffect(() => {
    const ro = new ResizeObserver(() => {
      if (PopoverRef.current) {
        PopoverRef.current.updatePosition();
      }
    });
    if (!wrapperChildren) return;
    ro.observe(wrapperChildren);
    return () => {
      ro.disconnect();
    };
  }, [PopoverRef, wrapperChildren]);
  if (!wrapper) return null;
  if (ff.ecompacttable_layout_mobile) {
    return createPortal(
      <Popover
        open={display}
        anchorEl={anchorEl}
        action={PopoverRef}
        className={classes.paper}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={onClose}
      >
        {!isMobileView ? (
          <div ref={setWrapperChildren}>{children}</div>
        ) : (
          <Box className={classes.modalContent}>
            <div className={classes.modalHeader}>
              <div className={classes.modalButtonCancel}>
                <Button color="inherit" onClick={onClose}>
                  Cancel
                </Button>
              </div>
              <h4 className={classes.modalTitle}>Product</h4>
              <div className={classes.mobileButton}>
                <Button color="primary" onClick={onClose}>
                  Done
                </Button>
              </div>
            </div>
            {children}
          </Box>
        )}
      </Popover>,
      wrapper,
      tableId,
    );
  }

  return createPortal(
    <Popover
      open={display}
      anchorEl={anchorEl}
      action={PopoverRef}
      className={classes.paper}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      {...(actions?.cancelBtnLabel ? {} : { onClose })}
    >
      <Box maxHeight={'90vh'} display={'flex'} flexDirection={'column'}>
        <div ref={setWrapperChildren} className={classes.compactContainer}>
          {children}
        </div>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          pt={'0.5em'}
          pb={'0.5em'}
          sx={{ columnGap: '8px' }}
          flex={'0 0 33px'}
        >
          {actions?.cancelBtnLabel ? <Button onClick={onClose}>{actions.cancelBtnLabel}</Button> : null}
          {actions?.submitBtnLabel ? (
            <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
              {actions.submitBtnLabel}
            </Button>
          ) : null}
        </Box>
      </Box>
    </Popover>,
    wrapper,
    tableId,
  );
}
