import * as React from 'react';
import { Responsive } from 'react-grid-layout';
import NestedGRL from './nested-rgl';

function getIndentationValue<T>(param: { [key: string]: T } | T, breakpoint: string): T {
  if (param == null) return null;
  return Array.isArray(param) ? param : param[breakpoint];
}

export default class ResponsiveReactGridLayout extends Responsive {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      breakpoint,
      breakpoints,
      cols,
      layouts,
      margin,
      containerPadding,
      onBreakpointChange,
      onLayoutChange,
      onWidthChange,
      ...other
    } = this.props;
    /* eslint-enable no-unused-vars */

    return (
      <NestedGRL
        {...other}
        // $FlowIgnore should allow nullable here due to DefaultProps
        margin={getIndentationValue(margin, this.state.breakpoint)}
        containerPadding={getIndentationValue(containerPadding, this.state.breakpoint)}
        onLayoutChange={this.onLayoutChange}
        layout={this.state.layout}
        cols={this.state.cols}
      />
    );
  }
}
