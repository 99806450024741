import { Grid, GridProps, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStylesFormControl = makeStyles({
  LabelGroup: {
    marginBottom: 4,
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: ' 16px',
    color: '#8C98A4',
    textTransform: 'uppercase',
  },
});
interface FormControlProps extends GridProps {
  label: string;
  children: JSX.Element;
}
const FormControlLabel = ({ label, children, ...containerProps }: FormControlProps) => {
  const classes = useStylesFormControl();
  return (
    <Grid container {...containerProps}>
      {/* label */}
      <Grid item xs={12} className={`${classes.LabelGroup} ${containerProps.className}`}>
        <span className={classes.label} tabIndex={-1}>
          {label}
        </span>
      </Grid>
      {/* input */}
      <Grid item xs={12}>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
};
export default FormControlLabel;
