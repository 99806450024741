import { getDefaultAggFunc, useLog } from '@eip/next/lib/main';
import {
  getAvailableAggFunc as nextGetAvailableAggFunc,
  getDefaultAggFunc as nextGetDefaultAggFunc,
} from '@ep/insight-ui/system/util/aggregation';
import produce from 'immer';
import { clone, cloneDeep, get, head, isEmpty, mapValues, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { isFormulaField } from '../../util/excel-formula';
import { getAPIRequest } from './api-request';

const log = useLog('lib:table-backbone:datasource');

const BLOCK_SIZE = 10;

export { getAPIRequest };

/**
 * 1. markplace filter must only exists one in filter
 * 2. markplace filter must only in level 0
 * 3.
 */
function makeFilterCompliance(
  filters: TableBackbone.filter[],
  groupLevel = 0,
  isHiddenField = false,
): { filters: TableBackbone.filter[]; hoisting: TableBackbone.filter[] } {
  if (!filters) return { hoisting: [], filters: [] };
  let nuFilters: TableBackbone.filter[] = [];
  let hoistingFilters = [];
  if (!filters.forEach) return { hoisting: [], filters: [] };
  filters.forEach((filter) => {
    if (filter.type === 'groupFilter') {
      const nuFilter = { ...filter };
      const { hoisting, filters: compliedFilters } = makeFilterCompliance(
        filter.subFilters,
        groupLevel + 1,
        isHiddenField,
      );

      if (compliedFilters.length > 0) {
        nuFilter.subFilters = compliedFilters;
        nuFilters = nuFilters.concat(nuFilter);
      }
      hoistingFilters = hoistingFilters.concat(hoisting);
    } else {
      if (filter.field === 'marketplace' && groupLevel > 0) {
        console.assert(false, 'marketplace should be in level 0');
        hoistingFilters = hoistingFilters.concat({ ...filter, logicalOperator: 'AND' });
      } else {
        nuFilters = nuFilters.concat({ ...filter });
      }
    }
  });

  return { hoisting: hoistingFilters, filters: nuFilters };
}

export function createDatasource({
  current,
}: {
  current: {
    queryToken;
    apiRequest;
    getColumnFields;
    config;
    updateTotalResult;
    updatePaginationTraceId;
    getAvailableGroupByColumns;
    getAvailableFilterColumns;
    getAvailableSortColumns;
    addon;
    /** @deprecated */
    addons;
  };
}) {
  const getSortField = (id) => {
    const column = current.getAvailableSortColumns().find((c) => c.id === id || c.field === id);
    return column?.field;
  };

  const getFilterField = (id) => {
    const column = current.getAvailableFilterColumns().find((c) => c.id === id || c.field === id);
    return column?.field;
  };

  const getAdditionalFilterField = (field) => {
    const additionalFilter = get(current, ['config', 'system', 'additionalFilters'], []).find(
      (el) => el.id === field.field,
    );
    if (additionalFilter) {
      const validRule = get(additionalFilter, ['rules'], []).find(({ value }) => value == field.queryValue);
      return {
        combinator: validRule ? validRule.combinator : 'AND',
        filters: additionalFilter.fields.map((el) => {
          return {
            field: el,
            dataType: additionalFilter.dataType,
            operator: field.queryType,
            value: field.queryValue,
          };
        }),
      };
    }
    return null;
  };

  const getSingleColumnFields = (columnId) => {
    // FIXME: more flexible way to ignore columns eg. column_name.valueGetter.will_ignore_field
    let fields = [];
    const getterFields = Object.values(get(current.config, ['mapping', columnId, 'valueGetter'], {}));
    fields = fields.concat(Object.values(getterFields));
    return fields;
  };

  const enrichRequestParamsGroupBy = (params, request, config) => {
    const groupBy = get(config, 'groupBy', { columns: [] });

    const availableGroupByCols = current.getAvailableGroupByColumns();
    const hasGroupBy =
      groupBy.columns && groupBy.columns.length > 0 && groupBy.columns.every((c) => availableGroupByCols.includes(c));

    if (hasGroupBy) {
      const mapping = get(config, 'mapping', {});
      const gDimensions = groupBy.columns.map(
        (c) => get(mapping[c], 'groupByColumn') || get(mapping[c], 'valueGetter.id'),
      );

      const configAggregations = Object.keys(mapping).reduce((carry, key) => {
        const keyValueGetter = get(mapping, [key, 'valueGetter', 'value'], '');
        const func = get(mapping, [key, 'defaultCalculated'], null);
        if (func) {
          return {
            ...carry,
            [keyValueGetter]: func,
          };
        }
        return carry;
      }, {});

      let aggregations = [].concat(
        params.attributes
          .map((i) => {
            if (isFormulaField(i)) return null;
            const defaultAggFunc = nextGetDefaultAggFunc('attribute');
            let aggFunc: AggFuncType =
              configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc);
            if (typeof aggFunc === 'string') {
              aggFunc = current.addon('system.groupby.aggFunc', nextGetAvailableAggFunc)(aggFunc);
            } else if (!aggFunc) {
              aggFunc = defaultAggFunc;
            }

            return {
              field: i,
              func: String((aggFunc || defaultAggFunc).requestQuery).replace(/_f_/g, i),
            };
          })
          .filter((i) => !isEmpty(i)),
        params.metrics
          .map((i) => {
            if (isFormulaField(i)) return null;
            const defaultAggFunc = getDefaultAggFunc('metric');
            let aggFunc: AggFuncType =
              configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc);
            if (typeof aggFunc === 'string') {
              aggFunc = current.addon('system.groupby.aggFunc', nextGetAvailableAggFunc)(aggFunc);
            }

            return {
              field: i,
              func: String((aggFunc || defaultAggFunc).requestQuery).replace(/_f_/g, i),
            };
          })
          .filter((i) => !isEmpty(i)),
      );

      const groupByColumnFields = groupBy.columns.reduce(
        (carry, colId) => carry.concat(getSingleColumnFields(colId)),
        [],
      );

      params.attributes = uniq(groupByColumnFields.concat(params.attributes)).filter((i) => !isFormulaField(i));
      aggregations = groupByColumnFields
        .filter((i) => !isFormulaField(i))
        .map((i) => ({
          field: i,
          func: current.addon('system.groupby.aggFunc', () => ({ requestQuery: 'max' }))('max').requestQuery,
        }))
        .concat(aggregations);

      params.groupBy = {
        dimensions: gDimensions,
        aggregations: uniqBy(aggregations, 'field'),
      };
    }
    let isDrilldown = false;
    const groupKeys = get(request, 'groupKeys', []);
    if (groupKeys.length > 0) {
      const drilldown = groupKeys.map((colValue, index) => {
        const colId = get(request, ['rowGroupCols', index, 'id']).replace('_grouped', '');
        const colMap = config.mapping[colId];
        const groupByColumn = get(colMap, 'groupByColumn', '');
        const queryField = groupByColumn ? groupByColumn : get(colMap, ['valueGetter', 'id']);
        return {
          field: queryField,
          value: colValue.groupByColumn || colValue.id || colValue.value,
        };
      });
      params.groupBy = {
        ...params.groupBy,
        drillDowns: drilldown,
      };
      isDrilldown = true;
    }

    if (isDrilldown) {
      const childGroupPagination = get(config, 'system.groupByPagination', []).find(
        (i) => i.group.join(',') === groupBy.columns.join(','),
      );

      params.pagination = {
        page: 1,
        limit: childGroupPagination ? childGroupPagination.limit : params.pagination.limit,
      };
    }

    return {
      params,
      isDrilldown,
    };
  };

  function isGroupFilter(filter: TableBackbone.filter): filter is TableBackbone.groupFilter {
    return !!(filter as TableBackbone.groupFilter).subFilters;
  }

  const transformFilters = (filter: TableBackbone.filter[]) => {
    if (filter.length === 0) return undefined;
    const enabledFilter = filter.filter((el) => {
      if (el.isDisabled) return false;
      if (el.type === 'groupFilter') {
        return el.subFilters.filter((subFilter) => !subFilter.isDisabled).length > 0;
      }
      return true;
    });
    if (enabledFilter.length === 0) return undefined;
    log('transform filter', filter);

    return {
      combinator: get(enabledFilter, '[1].logicalOperator') || 'and',
      filters: enabledFilter.reduce((acc, i) => {
        if (isGroupFilter(i)) {
          return [...acc, transformFilters(i.subFilters)];
        }
        if (ff.add_note_column_including_inline_edit) {
          if (i.dataType == 'nextString') {
            i.dataType = 'string';
          }
        }
        const field = getFilterField(i.field);
        if (field && i.queryType === 'IN' && i.queryValue) {
          const operator = {
            string: 'is',
            nextString: 'is',
            integer: '=',
            float: '=',
          };
          const subFilters = String(i.queryValue)
            .split(',')
            .map((value) => {
              return {
                field,
                operator: operator[i.dataType] || 'is',
                value: String(value).trim(),
                dataType: i.dataType,
              };
            });

          return [
            ...acc,
            {
              combinator: 'OR',
              filters: subFilters,
            },
          ];
        }
        if (field) {
          return [
            ...acc,
            {
              field,
              operator: i.queryType,
              value: i.queryValue,
              dataType: i.dataType,
            },
          ];
        }
        const additionField = getAdditionalFilterField(i);
        if (additionField) {
          return [...acc, additionField];
        }
        return acc;
      }, []),
    };
  };

  const dataSource = {
    prevQueryToken: null,
    getRows: async (tableParams) => {
      const { request } = tableParams;
      log('getRows', request, tableParams);

      const pageSize = get(
        current,
        ['config', 'defaultPagination', 'limit'],
        get(current, ['config', 'pageSize'], BLOCK_SIZE),
      );

      let params: any = {
        dimensions: current.getColumnFields('dimension'),
        attributes: current.getColumnFields('attribute'),
        metrics: current.getColumnFields('metric'),
        pagination: {
          page: 1 + (request.startRow || 0) / pageSize,
          limit: pageSize,
        },
        from: get(current.config, 'dateRange.dateFrom', moment().format('YYYY-MM-DD')),
        to: get(current.config, 'dateRange.dateTo', moment().format('YYYY-MM-DD')),
        sort: null,
        filter: null,
        hiddenFilter: get(current.config, 'hiddenFilter', {}),
      };

      const enrichments = enrichRequestParamsGroupBy(params, request, current.config);
      params = enrichments.params;
      const isDrilldown = enrichments.isDrilldown;

      const sort = get(current.config, 'sort', []);
      if (sort.length > 0) {
        params.sort = sort.reduce((acc, i) => {
          const field = getSortField(i.field);
          if (field) {
            return [
              ...acc,
              {
                field,
                sort: i.sort.toUpperCase(),
              },
            ];
          }
          return acc;
        }, []);
      }

      const filter: TableBackbone.filter[] = get(current.config, 'filter', []);
      const { hoisting, filters } = makeFilterCompliance(filter);
      params.filter = transformFilters(hoisting.concat(filters));

      try {
        if (ff.integrate_api_sos) {
          params = await current.addon(['datasource.getRowsParams'], ({ params }, config) => Promise.resolve(params))(
            { params },
            current.config,
          );
        } else {
          params = await get(current.addons, ['datasource.getRowsParams'], ({ params }, config) =>
            Promise.resolve(params),
          )({ params }, current.config);
        }
      } catch (e) {
        if (ff.loading_config_search) {
          tableParams.fail();
        }
      }

      log('params', params);
      if (params.dimensions.length > 0 || params.metrics.length > 0) {
        // temporary fix invalid query params. TODO: why the invalid query appear?
        params = cloneDeep(
          produce(params, (draft) => {
            draft.dimensions = params.dimensions.filter((i) => !!i);
            draft.attributes = params.attributes.filter((i) => !!i);
            draft.metrics = params.metrics.filter((i) => !!i);
          }),
        );

        const compactSelectedRows = current.addon('compact.getSelectedRows', () => undefined)();
        const compactInitSelectedRows = current.addon('compact.getInitSelectedRows', () => undefined)();

        if (compactSelectedRows !== undefined && get(current, 'config.view.id') === '_selected_items_') {
          const columns = Object.entries(get(current, ['config', 'mapping'], {})).map(([field, column]) => ({
            ...(typeof column === 'object' ? column : {}),
            field,
          }));
          params['@sw'] = { type: 'viewSelectedItems', selectedRows: compactSelectedRows, columns, filters: filter };
        } else if (compactInitSelectedRows !== undefined && get(current, 'config.view.id') !== '_selected_items_') {
          const primaryKeys = current.addon('compact.getPrimaryKeys', () => undefined)();
          params['@sw'] = {
            type: 'markInvalid',
            selectedRows: compactInitSelectedRows,
            reason: 'Item selected',
            primaryKeys: primaryKeys,
            searchKeywords: current.config.search.split('\n').filter((i) => String(i).trim() !== ''),
          };
        }

        current
          .addon('datasource.apiRequest.getTableData', (params, originalRequest, current) => {
            return originalRequest(params, current);
          })(params, current.apiRequest.getTableData, current)
          .then(async (res) => {
            log('[Table] success:', res);
            const newRows = ff.keep_block_refreshing
              ? current.addon('calendar.last_updated', () => res.data.rows)(res.data.rows, current.config)
              : res.data.rows;

            const total = get(res, 'data.pagination.total', 0);

            const mapping = get(current.config, 'mapping', {});
            const traceId = res.traceId;

            log('mapping', mapping);
            const checkDisableRecord = get(current.config, 'checkDisableRecord');
            const mappedRows = newRows.map((r) => {
              const isDisabledRecord = checkDisableRecord ? checkDisableRecord(r) : false;
              return {
                ...r,
                _eipCustomId: uuid(),
                isDisabledRecord,
                _route: request.groupKeys,
              };
            });

            if (!isDrilldown) {
              if (!params['@sw'] || params['@sw']['type'] !== 'viewSelectedItems') {
                try {
                  if (!dataSource.prevQueryToken || dataSource.prevQueryToken !== current.queryToken) {
                    current.updatePaginationTraceId(traceId);
                    dataSource.prevQueryToken = current.queryToken;
                  }
                } catch (e) {}
              } else {
                current.updateTotalResult(total);
              }
            }

            if (isDrilldown && mappedRows.length) {
              if (ff.remove_loadmore_btn) {
                const { pagination } = await current.apiRequest.getPaginationInfo({
                  pagination: {
                    page: params.pagination.page,
                    limit: params.pagination.limit,
                  },
                  previousTraceId: traceId,
                });

                // FIXME: remove this logic from data query, scope it as related to etable presentation
                if (pagination && res.data.pagination.limit < pagination.total) {
                  const lastRow = mappedRows[mappedRows.length - 1];
                  mappedRows.push({
                    ...mapValues(clone(lastRow), (v) => String(v) + '_cloneforloadmore'),
                    isDisabledRecord: false,
                    _isLoadMore: true,
                    _lastRow: (request.startRow || 0) + mappedRows.length,
                    _groupColumn: get(current.config, ['groupBy', 'columns']),
                    _route: request.groupKeys,
                  });
                }
              } else {
                const lastRow = mappedRows[mappedRows.length - 1];
                mappedRows.push({
                  ...mapValues(clone(lastRow), (v) => String(v) + '_cloneforloadmore'),
                  isDisabledRecord: false,
                  _isLoadMore: true,
                  _lastRow: (request.startRow || 0) + mappedRows.length,
                  _groupColumn: get(current.config, ['groupBy', 'columns']),
                  _route: request.groupKeys,
                });
              }
            }

            if (ff.separate_table_data_loading) {
              tableParams.success({
                rowData: mappedRows,
                rowCount:
                  mappedRows.length < pageSize
                    ? mappedRows.length + (params.pagination.page - 1) * pageSize
                    : undefined,
              });
            } else {
              tableParams.success({ rowData: mappedRows, rowCount: isDrilldown ? mappedRows.length : total });
            }
          })
          .catch((err) => {
            log('[Table] err:', err);
            console.error(err);
            tableParams.fail(err);
          });
      } else {
        tableParams.success({ rowData: [], rowCount: 0 });
      }
    },
    ...(ff.add_filter_export_api
      ? {
          getParamFilter: (filter) => {
            const { hoisting, filters } = makeFilterCompliance(filter);
            const newFilter = transformFilters(hoisting.concat(filters));
            return newFilter;
          },
        }
      : {}),
  };
  return dataSource;
}

export function createDataQueryFromConfig({ config, addons }: { config: any; addons?: Record<string, any> }) {
  const getColumnFields = (type) => {
    const hiddenFields = get(config, ['requestHiddenField', type], []);
    const ignoreFields = get(config, ['requestIgnoreField', type], []);
    let fields = [...hiddenFields];
    let propertyIds = [];
    if (type === 'attribute') {
      propertyIds = [...get(config, 'dimension', []), ...get(config, 'attribute', [])];
      propertyIds = Object.keys(get(config, ['mapping'], {})).filter(
        (k) => ['dimension', 'attribute'].indexOf(get(config, ['mapping', k, 'propertyType'])) > -1,
      );
    } else {
      propertyIds = Object.keys(get(config, ['mapping'], {})).filter(
        (k) => get(config, ['mapping', k, 'propertyType']) === type,
      );
    }
    propertyIds
      .map((i) => i)
      .forEach((id) => {
        let getterFields = Object.values(get(config, ['mapping', id, 'valueGetter'], {}));
        if (type === 'dimension') {
          getterFields = getterFields.map((i: string) => head(i.split('.')));
        }
        fields = fields.concat(getterFields);
      });

    return uniq(fields.filter((i) => !ignoreFields.includes(i)));
  };

  const getAvailableColumns = () => {
    const mapping = get(config, 'mapping', {});
    const opt = {};
    for (const [colId, colVal] of Object.entries(mapping)) {
      const colValue: any = colVal;
      if (colValue.propertyType) {
        opt[colValue.propertyType] = (opt[colValue.propertyType] || []).concat({
          id: colId,
          name: colValue.title,
          filterField: colValue.filterField,
          sortField: colValue.sortField || colValue.filterField,
          dataType: colValue.dataType || 'string',
          disable: false,
        });
      }
    }

    const properties = ['dimension', 'attribute', 'metric'];
    return properties.reduce((acc, property) => {
      const cols = get(opt, property, []).map((c) => ({ ...c, propertyType: property }));
      return [...acc, ...cols];
    }, []);
  };

  const getAvailableFilterColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.filterField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.filterField,
            dataType: c.dataType || 'string',
          },
        ];
      }
      return acc;
    }, []);
  };

  const getAvailableSortColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.sortField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.sortField,
            dataType: c.dataType || 'string',
          },
        ];
      }
      return acc;
    }, []);
  };

  const current = {
    apiRequest: getAPIRequest(config.endpoint),
    config: config,
    getAvailableFilterColumns,
    getAvailableSortColumns,
    getColumnFields,
    updateTotalResult: () => undefined,
    updatePaginationTraceId: () => undefined,
    addons: addons,
    getAvailableColumns,
    getAvailableGroupByColumns: () => {
      return Object.keys(config.mapping).filter((k) => config.mapping[k].propertyType === 'dimension');
    },
    addon: (key, defaultFn) => get(addons, [key], defaultFn),
  };

  const ds = createDatasource({
    current: current,
  });

  return {
    query: function query({
      filter,
      dateRange,
      hiddenFilter,
      pageSize,
      groupBy,
    }: {
      filter: TableBackbone.filter[];
      dateRange?;
      hiddenFilter?;
      pageSize?;
      groupBy?;
    }): Promise<{ rowData: any[]; rowCount: number }> {
      current.config.filter = filter;
      current.config.hiddenFilter = hiddenFilter;
      current.config.dateRange = dateRange;
      current.config.pageSize = pageSize;
      current.config.groupBy = groupBy;
      return new Promise((resolve, reject) => ds.getRows({ request: { startRow: 0 }, success: resolve, fail: reject }));
    },
  };
}
