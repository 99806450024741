import { EIP_CONSTANT } from '@eip/next/lib/main';
import moment from 'moment';

export const METRIC_CONFIG = {
  title: 'Summary',
  dimension: [],
  metric: [],
  attribute: [],
  dateRange: {
    dateFrom: moment().subtract(8, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().subtract(1, 'day').format(EIP_CONSTANT.DATE_FORMAT),
  },
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/performance.jsp',
  },
  useDataSource: false,
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
  ],
  tableType: 'metric',
  hiddenFilter: {
    currency: 'USD',
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool_name',
        id: 'ADTOOL.ads_tool',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'metricTraction',
      dataType: 'float',
      valueGetter: {
        value: 'cost',
      },
      staticValue: {
        items: [
          {
            id: 'impression',
            title: 'Impression',
          },
          {
            id: 'click',
            title: 'Click',
          },
          {
            id: 'cost',
            title: 'Cost',
          },
          {
            id: 'item_sold',
            title: 'Ads item sold',
          },
          {
            id: 'direct_item_sold',
            title: 'Direct ads item sold',
          },
          {
            id: 'gmv',
            title: 'Ads GMV',
          },
          {
            id: 'direct_gmv',
            title: 'Direct ads GMV',
          },
          {
            id: 'ads_order',
            title: 'Ads order',
          },
          {
            id: 'direct_ads_order',
            title: 'Direct ads order',
          },
          {
            id: 'roas',
            title: 'ROAS',
          },
          {
            id: 'direct_roas',
            title: 'Direct ROAS',
          },
          {
            id: 'cir',
            title: 'CIR',
          },
          {
            id: 'cpc',
            title: 'CPC',
          },
          {
            id: 'ctr',
            title: 'CTR',
          },
          {
            id: 'cr',
            title: 'CR',
          },
          {
            id: 'total_gmv',
            title: 'Total GMV',
          },
          {
            id: 'total_item_sold',
            title: 'Items sold',
          },
          {
            id: 'total_order',
            title: 'Total order',
          },
          {
            id: 'percent_gmv_over_total',
            title: '%Ads GMV contribution',
          },
          {
            id: 'direct_percent_gmv_over_total',
            title: '%Direct ads GMV contribution',
          },
          {
            id: 'percent_item_sold_over_total',
            title: '%Ads item sold contribution',
          },
          {
            id: 'direct_percent_item_sold_over_total',
            title: '%Direct ads item sold contribution',
          },
          {
            id: 'ads_conversions',
            title: 'Ads conversions',
          },
          {
            id: 'direct_conversions',
            title: 'Direct conversions',
          },
          {
            id: 'cpi',
            title: 'CPI ',
          },
          {
            id: 'direct_cr',
            title: 'Direct CR',
          },
          {
            id: 'direct_cpi',
            title: 'Direct CPI',
          },
          {
            id: 'direct_cir',
            title: 'Direct CIR',
          },
        ],
      },
    },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
  system: {
    hiddenComponents: ['sort', 'search', 'majorButton'],
  },
};
