import * as React from 'react';
import { useParams } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';
import ActionConfiguration from './action-configuration';
import PageConfig from './page-config';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
  },
}));

const PageConfiguration = () => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <ActionConfiguration label={'Action Configuration'} source={'page-actions'} />
      <PageConfig />
    </div>
  );
};

export default PageConfiguration;
