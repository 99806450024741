import * as React from 'react';
import { makeStyles, Modal, Box, Grid } from '@material-ui/core';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import GroupButtonSubmit from '../group-button-submit';
import FormControlLabel from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-label';
import { TextFieldAutofill } from '@ep/insight-ui/elements/textField/textFieldAutofill';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { get } from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  header: {
    paddingLeft: '0px',
    paddingBottom: '15px',
  },
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
  form: {
    gap: '12px',
  },
});

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onLoad?: (value: any) => void;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const SCLoginCredential = ({ data, title = '', payload, onLoad, onSubmit, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const storefrontId = get(data, 'value.storefront_id', '');
  const [account, setAccount] = React.useState({ username: '', password: '' });
  const [errorMessage, setErrorMessage] = React.useState('');
  const { onToast } = useToast();

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    if (account.username && account.password) {
      setErrorMessage('');
      const response = await onSubmit({
        storefrontId,
        account,
      });
      if (response && response.success) {
        onToast({
          title: 'Success',
          messages: response.message,
          variant: 'success',
        });
        handleClosed();
      } else {
        onToast({
          title: 'Error',
          messages: response.message,
          variant: 'error',
        });
        handleClosed();
      }
    } else {
      setErrorMessage('Please input your Username or Password!');
    }
  };

  return (
    <Modal open={anchorEl}>
      <Box className={classes.popupModal}>
        <HeaderList title={title} variant="h4" className={classes.header} />
        <Grid container className={classes.form}>
          <Grid item xs={12}>
            <FormControlLabel label={'Username'}>
              <Grid container wrap={'nowrap'}>
                <Grid item xs={12}>
                  <TextFieldAutofill
                    placeholder={'Enter your username'}
                    defaultValue={account.username}
                    type={'username'}
                    onChangeText={(v) => {
                      setAccount((prevState) => ({ ...prevState, username: v }));
                    }}
                    error={false}
                    errorMessage={''}
                  />
                </Grid>
              </Grid>
            </FormControlLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel label={'Password'}>
              <Grid container wrap={'nowrap'}>
                <Grid item xs={12}>
                  <TextFieldAutofill
                    placeholder={'Enter your password'}
                    type={'password'}
                    defaultValue={account.password}
                    error={false}
                    onChangeText={(v) => {
                      setAccount((prevState) => ({ ...prevState, password: v }));
                    }}
                    errorMessage={''}
                  />
                </Grid>
              </Grid>
            </FormControlLabel>
          </Grid>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
              <Icon type={'error'} style={{ width: '9px', height: '9px' }} />
              <Typography variant="body5" style={{ color: '#D4290D' }}>
                {errorMessage}
              </Typography>
            </Box>
          </Grid>
        )}
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} submitLabel="Submit" />
      </Box>
    </Modal>
  );
};

export default SCLoginCredential;
