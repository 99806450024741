import * as React from 'react';
import { PageContainerResponsive } from '@eip/next/stories/common-system-page.stories';
import { Box, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router';

import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';

import { PageWithoutMenu } from '../menu-list';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    alignItems: 'center',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
  },
  text: {
    fontSize: '24px',
  },
});
const RedirectExternal = () => {
  const classes = useStyles();

  const { search } = useLocation();

  const redirectUrl = React.useMemo(() => {
    const queryParams = new URLSearchParams(search);
    return queryParams.get('url');
  }, [search]);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = redirectUrl;
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [redirectUrl]);

  return (
    <PageContainerResponsive>
      <Box className={classes.container}>
        <CircleLoading size={'64px'} />
        <span className={classes.text}>
          Redirecting you to external website. Or{' '}
          <a href={redirectUrl as string} rel="noopener,nofollow">
            click here
          </a>
        </span>
      </Box>
    </PageContainerResponsive>
  );
};
export default PageWithoutMenu(RedirectExternal);
