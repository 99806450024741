import { cloneDeep, get, isObject, set } from 'lodash';
import { ResponseComposition, RestContext, RestRequest } from 'msw';
import hash from 'object-hash';
import qs from 'querystring';

type tapeType = {
  (resolver?: any): (req: RestRequest, res: ResponseComposition, ctx: RestContext) => Promise<any>;
  defaults?: {
    baseUrl: string;
    getTapeEndpoint: string;
    designSystemPageEndpoint: string;
    bypass: boolean | string[] | ((url: string, body: any) => boolean);
    replay: boolean;
    record: boolean;
    maskFieldPaths?: { body: string[][]; url: string[][] };
  };
};

const SEPARATOR = ';';

export const tape: tapeType = (resolver) => {
  return async (req: RestRequest, res: ResponseComposition, ctx: RestContext) => {
    if (tape.defaults.bypass) return;

    const url = req.url;
    // console.info('body', req.body);
    let body = isObject(req.body) ? cloneDeep(req.body) : undefined;

    let search = qs.parse(url.searchParams.toString());
    // console.info('tape search', search, hash(search, { unorderedArrays: true }));

    if (body) {
      tape.defaults.maskFieldPaths.body.forEach((fieldPath) => {
        body = set(body, fieldPath, '*');
      });
    }

    tape.defaults.maskFieldPaths.url.forEach((fieldPath) => {
      if (get(search, fieldPath) !== undefined) search = set(search, fieldPath, '*');
    });

    const tokenUrl = hash(search, { unorderedArrays: true, respectType: false });
    const tokenBody = body ? hash(body, { unorderedArrays: true }) : null;
    const pathName = url.pathname.replace(tape.defaults.baseUrl, '');
    let originalData = null;

    if (tape.defaults.record && !tape.defaults.replay) {
      const upstreamRequest = (async function recordingUpstreamRequest() {
        try {
          const originalResponse = await ctx.fetch(req);
          if (originalResponse.status !== 200) throw Error(originalResponse.statusText);

          originalData = await originalResponse.json();
          if (
            get(originalData, 'serviceResponse.success') === true ||
            get(originalData, 'success') === true ||
            get(originalData, 'code') === 200
          ) {
            await ctx.fetch('/record-tape', {
              method: 'POST',
              headers: {
                'content-type': 'application/json',
              },
              body: JSON.stringify({
                pathName: pathName.replace(/\//g, SEPARATOR),
                url: pathName + (Object.keys(search).length > 0 ? (search ? '?' + qs.stringify(search) : '') : ''),
                body,
                data: set(originalData, 'serviceResponse.requestId', undefined),
                tokenBody,
                tokenUrl,
              }),
            });
            console.info('tapeId', `${pathName.replace(/\//g, SEPARATOR)}__${tokenUrl}_${tokenBody}`);
            return originalData;
          } else {
            throw originalData;
          }
        } catch (error) {
          console.error('error querying', url, body);
          console.error(error);
        }
      })();
      if (!resolver) {
        try {
          const data = await ctx
            .fetch(tape.defaults.getTapeEndpoint, {
              method: 'POST',
              headers: { 'content-type': 'application/json' },
              body: JSON.stringify({ tapeId: `${pathName.replace(/\//g, SEPARATOR)}__${tokenUrl}_${tokenBody}` }),
            })
            .then((response) => {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error(response.statusText + ': ' + response.status);
              }
            });

          return res(ctx.json(data));
        } catch (error) {
          console.info('😳 Potential missing mock file.', pathName, body);
          return upstreamRequest.then((data) => ctx.json(data)).catch((data) => ctx.json(data));
          // return res(ctx.status(500), ctx.json({ error }));
        }
      }
    } else if (tape.defaults.replay) {
      const data = await serveMock(`${pathName}__${tokenUrl}_${tokenBody}`, ctx);
      return res(ctx.json(data));
    }

    return resolver ? resolver(req, res, ctx) : undefined;
  };
};

tape.defaults = {
  baseUrl: 'http://localhost:9800/',
  getTapeEndpoint: '/replay-tape',
  bypass: false,
  replay: false,
  record: true,
  maskFieldPaths: {
    body: [
      ['serviceRequest', 'requestId'],
      ['from'], // daterange
      ['to'], // daterange
    ],
    url: [['serviceRequest.requestId']],
  },
  designSystemPageEndpoint: '/design-system-page',
};

// FIXME: pitfall, when the record update storybook will reload
async function serveMock(filename: string, ctx) {
  // return import('./fixtures/db/outstream.json').then((data: any) => {
  //   // console.info('servermock', filename, tapeId, data);
  //   if (data[tapeId]) return data[tapeId].data;
  //   console.error('missing tape', tapeId);
  //   return null;
  // });

  const tapeId = filename.replace(/\//g, SEPARATOR);
  const data = await ctx
    .fetch(tape.defaults.getTapeEndpoint, {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({ tapeId: tapeId }),
    })
    .then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error(response.statusText + ': ' + response.status);
      }
    });
  return data;
}
