import * as React from 'react';
import { Typography, Box, Button, makeStyles, Modal, Grid } from '@material-ui/core';
import ButtonAction from '../button/button-action';
import ExpansionTable from '../table/expansion-table';
import SelectMultipleForm from '../form-control/select-form/select-multiple-form';
import CompactSelection from '../input-form/form-templates/form-controls/compact-selection';
import { USER_DEMO_RES } from './mock/getUserDemo';
import { USER_CONFIG } from './mock/table-config/user-setting';

const useStyle = makeStyles({
  input: {
    padding: '10px 0',
  },
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
    width: '700px',
  },
  formControl: {
    marginBottom: 0,
    '&.select': {},
  },
  form: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectForm: {
    width: '411px',
  },
});

export const AllocateUser = ({ buttonText = 'Add component' }: { buttonText: string }) => {
  const optionStorefront = [
    {
      value: 'Chee An Vat',
      label: 'Chee An Vat',
    },
    {
      value: 'XYZ Shop',
      label: 'XYZ Shop',
    },
    {
      value: 'Epsillion’s store',
      label: 'Epsillion’s store',
    },
    {
      value: 'unilevermy',
      label: 'unilevermy',
    },
    {
      value: 'Garnier Official Store',
      label: 'Garnier Official Store',
    },
    {
      value: 'RB Home Essentials',
      label: 'RB Home Essentials',
    },
    {
      value: 'dettol_officialstore',
      label: 'dettol_officialstore',
    },
    {
      value: 'D-fair Pharma Official Store',
      label: 'D-fair Pharma Official Store',
    },
    {
      value: 'Garnier',
      label: 'Garnier',
    },
  ];

  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const [storefront, setStorefront] = React.useState([]);
  const [member, setMember] = React.useState([]);

  const getTableData = () => {
    return Promise.resolve(USER_DEMO_RES);
  };
  const config = {
    apiRequest: {
      getTableData,
    },
    isCalendarHide: true,
    configuration: {
      ...USER_CONFIG,
      system: {
        hiddenComponents: ['search', 'majorButton', 'sort', 'filter'],
      },
    },
    tableType: 'compact',
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSubmit = () => {
    return;
  };
  const programObjConfig = {
    ...config,
    callback: {
      onRowSelect: (rows) => {
        setMember(rows);
      },
    },
  };

  return (
    <>
      <ButtonAction
        icon="plus"
        label={buttonText}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={handleOpen}
      />
      <Modal open={open}>
        <Box className={classes.popupModal}>
          <Typography variant="h2">Allocate member to store</Typography>
          <ExpansionTable style={{ fontSize: '14px' }} label={'Select store and member'} defaultExpanded={true}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={12} className={classes.form}>
                  <span>Storefront</span>
                  <SelectMultipleForm
                    options={optionStorefront}
                    multipleSelect={true}
                    searchAble={false}
                    chip={true}
                    value={storefront}
                    onChange={setStorefront}
                    className={classes.selectForm}
                  />
                </Grid>
                <Grid item xs={12} className={classes.form}>
                  <CompactSelection
                    label={'Member'}
                    config={programObjConfig}
                    displayField={'email'}
                    selectedRows={member}
                    setRows={(value: any[]) => {
                      setMember(value);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </ExpansionTable>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button color="secondary" variant="contained" component="span" onClick={handleClose}>
              <span>Cancel</span>
            </Button>
            <Button color="primary" variant="contained" component="span" onClick={handleSubmit}>
              <span>Allocate</span>
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
