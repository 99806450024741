import { Chip, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import _ from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
const useStyles = makeStyles(() => ({
  tagItem: {
    background: ' #EDF2F9',
    borderRadius: '4px',
    '& .eip1-MuiChip-deleteIcon': {
      width: '10px',
      height: '10px',
      color: '#3F4F5C',
    },
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#253746',
  },
}));

export interface ITag {
  id: string;
  text: string;
}
export interface ITagFormat {
  value: Array<ITag>;
}

const TagFormat = (props: IPropsFormat<ITagFormat>) => {
  const classes = useStyles();
  // const value = _.get(props, 'value', []);
  const cellAction = _.get(props, ['cellAction'], []);
  const node = _.get(props, ['node'], null);
  const value = _.get(props, ['value'], null);
  const tagsGetter = React.useMemo(() => _.get(props, ['value', 'value'], null), [props]);
  if (value) {
    try {
      _.set(
        value,
        'value',
        tagsGetter ? (ff.safe_json_parse ? safeJsonParse(tagsGetter) : JSON.parse(tagsGetter)) : null,
      );
    } catch (err) {
      _.set(value, 'value', null);
    }
  }
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');

  const { firstTag, tags }: { firstTag: ITag; tags: Array<ITag> } = React.useMemo(() => {
    let tagsValue: Array<ITag>;
    if (ff.safe_json_parse) {
      tagsValue = safeJsonParse(tagsGetter, []);
    } else {
      try {
        tagsValue = JSON.parse(tagsGetter);
      } catch (error) {
        tagsValue = [];
      }
    }
    const first = _.get(tagsValue, 0);
    let tags = [];
    if (tagsValue && tagsValue.length > 1) {
      tags = tagsValue.slice(1);
    }
    return { firstTag: first, tags: tags };
  }, [tagsGetter]);
  const renderTags = () => {
    if (!tagsGetter)
      return (
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>{<span className={classes.text}>All</span>}</Grid>
        </Grid>
      );
    let tagsValue: Array<ITag>;
    if (ff.safe_json_parse) {
      tagsValue = safeJsonParse(tagsGetter, []);
    } else {
      try {
        tagsValue = JSON.parse(tagsGetter);
      } catch (error) {
        tagsValue = [];
      }
    }
    if (tagsGetter.length == 0)
      return (
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item>{<span className={classes.text}>All</span>}</Grid>
        </Grid>
      );
    return (
      <Grid container alignItems={'center'} spacing={1}>
        {tagsValue.length > 0 && (
          <Grid item>
            <Chip className={classes.tagItem} label={firstTag.text} />
          </Grid>
        )}
        {tagsValue.length > 1 && tags.length > 0 && (
          <Grid item>
            <Chip className={classes.tagItem} label={`+${tags.length}`} />
          </Grid>
        )}
      </Grid>
    );
  };
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      {renderTags()}
    </WrapperFormat>
  );
};
export default TagFormat;
