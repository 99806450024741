/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import WrapperFormat, { IPropsFormat } from '../wrapper-format';
import * as _ from 'lodash';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexFlow: 'wrap',
    lineHeight: '24px',
    alignItems: 'center',
    textAlign: 'right',
    width: '100%',
  },
  value: {
    lineHeight: '20px',
  },
}));
export interface IValueBudgetFormat {
  currency: string;
  totalBudget: number | null | undefined;
  dailyBudget: number | null | undefined;
  disabledModelNoLimit?: boolean;
}

const BudgetFormat = (props: IPropsFormat<IValueBudgetFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const totalBudget = _.get(props, ['value', 'totalBudget'], '');
  const dailyBudget = _.get(props, ['value', 'dailyBudget'], '');
  const currency = _.get(props, ['value', 'currency'], '');
  const typeTable = _.get(props, 'typeTable', '');

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value}>
      <Grid container alignItems={'center'} className={classes.format}>
        <Grid container className={classes.styleText}>
          {/* total */}
          <Grid item xs={12} className={classes.value}>
            {totalBudget ? `${formatCurrencyNumber(totalBudget)} ${currency} • Total` : 'no limit'}
          </Grid>
          {/* daily */}
          <Grid item xs={12} className={classes.value}>
            {dailyBudget ? `${formatCurrencyNumber(dailyBudget)} ${currency} • Daily` : null}
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default BudgetFormat;
