import { sortBy, get } from 'lodash';
const log = require('debug')('int:autolayout');

export function reArrangeLayout({
  layout,
  base = 24,
  viewport = 18,
}: {
  layout: any[];
  base: number;
  viewport: number;
}) {
  log('layout >>>', base, viewport);
  function scale(unit, min) {
    let sunit = Math.max(Math.floor((unit * viewport) / base), min);
    return sunit;
  }

  let sortedLayouts = sortBy(layout, [
    (i) => i.layout.y,
    (i) => i.layout.x,
  ]);

  let refRow = -1;
  sortedLayouts.forEach((i, index) => {
    let ilayout = i.layout;
    if (refRow === -1) {
      refRow = ilayout.y;
    } else if (refRow < ilayout.y) {
      const isPreviousRowHavingAtLeast2Items =
        get(sortedLayouts, [index - 1, 'layout', 'y'], null) ===
        get(sortedLayouts, [index - 2, 'layout', 'y'], null);

      if (isPreviousRowHavingAtLeast2Items) {
        sortedLayouts[index - 1] = shiftToRightEdge(
          sortedLayouts[index - 1],
          viewport,
        );
      } else {
        log(sortedLayouts[index - 1]);
        sortedLayouts[index - 1] = increaseWidth(
          sortedLayouts[index - 1],
          viewport,
        );
        log(sortedLayouts[index - 1]);
      }
    }
    refRow = ilayout.y;

    sortedLayouts[index] = {
      ...i,
      layout: {
        ...ilayout,
        x: scale(ilayout.x, 0),
        w: scale(ilayout.w, 1),
        // h: scale(ilayout.h, 1),
      },
    };
  });

  return sortedLayouts;
}

function increaseWidth(item, viewport) {
  let w = viewport - item.layout.x;

  return {
    ...item,
    layout: { ...item.layout, w },
  };
}

function shiftToRightEdge(item, viewport) {
  let x = viewport - item.layout.w;

  return {
    ...item,
    layout: { ...item.layout, x },
  };
}
