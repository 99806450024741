/* eslint-disable react/prop-types */
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Box, Grid, List, ListItem, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

import { enhanceCellAction } from '../table-helper';
import { getIconStatus } from './status-text-format';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { convertToLocalTime } from '@ep/insight-ui/system/helper/functions';
import { TableCompactFactoryContext } from '@ep/insight-ui/elements/table/compact-factory';
import { makeTable } from '@ep/insight-ui/elements/table/table-container';
import { useDataStatusTable } from './hooks/use-data-status-table';

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  statusCompact: {
    marginRight: '2px',
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
  },
  subtextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  errorDataStatusTooltip: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '0 16px',
  },
  errorDataStatusTooltipItem: {
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
    },
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export type IValueTextFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueTextFormat>;
};
const TextFormat = (props: IPropsFormat<IValueTextFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const label = _.get(props, ['value', 'label'], null);
  const subTabs = _.get(props, ['value', 'subTabs'], null);
  const status = _.get(props, ['value', 'status'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const displayType = _.get(props, 'value.displayType', null);
  const disabled = _.get(props, 'value.disabled', false);
  const edot = _.get(props, ['value', 'edot'], []);

  const backbone = React.useContext(TableBackboneContext);

  const {
    dataStatus,
    errorDataStatus,
    lastErrorUpdatedAt,
    valueSyncErrorMessage,
    valueSyncStatus,
    valueSyncUpdatedAt,
    enhanceRetryAction,
  } = useDataStatusTable(props, backbone);

  // Handle status for history compact table
  const isHistoryStatusField = React.useMemo(() => {
    return (
      ['newValue', 'oldValue'].includes(_.get(props, ['colDef', 'field'], '')) &&
      _.get(props, ['data', 'changelog_ads_ops_dimension.field_name'], '') === 'status'
    );
  }, [props]);

  const isCreatedAtField = React.useMemo(() => {
    return _.get(props, ['colDef', 'field'], '') === 'createdAt';
  }, [props]);

  const subtext2 = _.get(props, ['value', 'subtext2'], '');

  // Tooltips for Ad object
  const valid = _.get(props, 'value.valid', true);
  const jsonTooltips = _.get(props, 'value.jsonTooltips', '[]');
  const customTooltip = _.get(props, ['value', 'tooltip'], '');
  const subtextValue = _.get(props, ['value', 'subtext'], '');
  const tooltip = React.useMemo(() => {
    if (customTooltip) {
      const tooltipItems = customTooltip.split('\n').map((el, index) => {
        return (
          <ListItem key={index} className={classes.tooltipItem}>
            <span>{el}</span>
          </ListItem>
        );
      });
      return <List>{tooltipItems}</List>;
    }

    const tooltipItems = (ff.safe_json_parse ? safeJsonParse(jsonTooltips, []) : JSON.parse(jsonTooltips))
      .filter((tooltip) => !tooltip.is_valid)
      .map((tooltip) => {
        return (
          <ListItem key={tooltip.attribute} className={classes.tooltipItem}>
            <span>{tooltip.message}</span>
          </ListItem>
        );
      });

    return <List>{tooltipItems}</List>;
  }, [customTooltip]);

  const getLabel = () => {
    if (isHistoryStatusField) {
      return _.startCase(_.lowerCase(label));
    }
    if (isCreatedAtField && moment(label).isValid()) {
      const gmtTimezone = moment().format('Z').split(':').join('');
      return `${moment.utc(label).local().format('YYYY-MM-DD HH:mm:ss')} GMT${gmtTimezone}`;
    }
    switch (displayType) {
      case 'bidding_method':
        return `${label ? 'auto' : 'manual'}`;
      default:
        return `${label ? _.get(value, ['overrideValue', label], label) : ''}`;
    }
  };

  const subtext = React.useMemo(() => {
    if ((dataStatus && errorDataStatus.length) || valueSyncStatus == 2) {
      return (
        <Box className={classes.subtextContainer}>
          <Tooltip
            title={
              valueSyncErrorMessage ? (
                valueSyncErrorMessage
              ) : (
                <ul className={classes.errorDataStatusTooltip}>
                  {errorDataStatus.map(([key, errorData]) => {
                    return (
                      <li key={key} className={classes.errorDataStatusTooltipItem}>
                        <div>
                          <span>
                            {String(key)
                              .replace(/\w+\./g, () => '')
                              .replace(/^\w/, (c) => c.toUpperCase())
                              .split('_')
                              .join(' ')}
                            : {errorData['ads_ops_dropdownlabel.description']}
                          </span>
                          <span>Sync failed at: {convertToLocalTime(errorData['ads_ops_buffer_ads.updated_at'])}</span>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              )
            }
            classes={{ tooltip: classes.tooltip }}
            placement="bottom"
          >
            <Box className={classes.syncStatusText}>
              <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
              <span>
                Sync failed at: {convertToLocalTime(valueSyncErrorMessage ? valueSyncUpdatedAt : lastErrorUpdatedAt)}
              </span>
            </Box>
          </Tooltip>
          {subtextValue ? (
            <>
              <span>|</span>
              <span className={`${disabled && 'disabled'}`}>
                {subtextValue} {subtext2 ? ` | ${subtext2}` : ''}
              </span>
            </>
          ) : null}
        </Box>
      );
    }

    return (
      <span className={`${disabled && 'disabled'}`}>
        {subtextValue} {subtext2 ? ` | ${subtext2}` : ''}
      </span>
    );
  }, [props, dataStatus, errorDataStatus, valueSyncStatus, valueSyncErrorMessage, valueSyncUpdatedAt, subtext2]);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(enhanceRetryAction(cellAction), edot, props.field, backbone)}
      node={node}
      value={value}
      isGrouped={isGrouped}
      disabledMenu={disabled}
    >
      <ConditionalWrap
        condition={valid === 'invalid' || customTooltip}
        wrap={(children) => (
          <Tooltip
            title={tooltip}
            classes={{ tooltip: classes.tooltip }}
            placement="bottom-end"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '80px',
                  },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        <Grid
          container
          alignItems={'center'}
          wrap={'nowrap'}
          className={classes.height100}
          style={{ overflow: 'hidden' }}
        >
          {/* expand icon */}
          {!_.isEmpty(subTabs) ? (
            <Grid item style={{ marginRight: !_.isEmpty(subTabs) ? '5px' : '' }}>
              <div className={`${classes.wrapperIcon} expand`}>
                <Icon type={'arrowright'} className={`${classes.icon} arrowright`} />
              </div>
            </Grid>
          ) : null}
          {/* text */}
          <Grid item style={{ width: '100%', height: '100%' }}>
            <Grid container alignItems="center" wrap={'nowrap'} className={classes.height100}>
              {/* status icon */}
              {!_.isEmpty(status) ? (
                <Grid item style={{ marginRight: !_.isEmpty(status) ? '5px' : '' }}>
                  <span className={classes.wrapperIcon}>
                    <Icon type={status.toLowerCase()} className={`${classes.icon} status`} />
                  </span>
                </Grid>
              ) : null}
              {isHistoryStatusField && label && (
                <Grid item className={clsx(classes.status, typeTable === 'compact' && classes.statusCompact)}>
                  <span style={{ display: 'flex' }}>{getIconStatus(label).iconComponent}</span>
                </Grid>
              )}
              <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  wrap={'nowrap'}
                  className={clsx(classes.height100, classes.alignItemsStart)}
                >
                  {/*hashtag*/}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.hashtag} ${disabled && 'disabled'}`}>{hashtag}</span>
                  </Grid>
                  {/* label */}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.label} ${disabled && 'disabled'}`}>{getLabel()}</span>
                  </Grid>
                  {/* subtext */}
                  <Grid item xs={12} className={classes.height100}>
                    <Box className={classes.subtext}>{subtext ? subtext : ''}</Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrap>
    </WrapperFormat>
  );
};
export default TextFormat;
