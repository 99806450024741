import Icon from '@ep/insight-ui/icons/Icon';
import { Box, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import ButtonFilter from '../table/table-actions/button-filter';
import ButtonOption from '../table/table-actions/button-option';
import ButtonSort from '../table/table-actions/button-sort';
import { useAlignPopover } from '../table/table-actions/hooks';
import SectionDropdownView, { dropdownViewOption } from './section-dropdown-view';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '24px',
    width: '100%',
  },
  selectBox: {
    display: 'inline-flex',
    width: 'fit-content',
    '& .eip1-MuiSelect-select': {
      minWidth: '30px',
      borderRadius: '4px',
    },
  },
  selectFormControl: {},
  groupButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  followButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 8px',
    borderRadius: '4px',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
    '& span': {
      marginLeft: '6px',
      fontWeight: '500',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      color: '#253746',
    },
    '&:hover': {
      background: '#E4E7E9',
    },
  },
}));

const SectionActions = ({
  options,
  selected,
  section,
}: {
  options: dropdownViewOption[];
  selected: dropdownViewOption;
  section: string;
}) => {
  const classes = useStyles();
  const [container, setContainer] = React.useState<HTMLDivElement>(null);
  const { marginLeft } = useAlignPopover(container);
  const boxRef = React.useRef<HTMLDivElement>(null);
  const [isFollowed, setIsFollowed] = React.useState(false);

  React.useEffect(() => {
    if (!boxRef.current) return;
    setContainer(boxRef.current);
  }, [boxRef.current]);

  return (
    <div className={classes.container} ref={boxRef}>
      <SectionDropdownView options={options} selected={selected} />
      <Grid className={classes.groupButtons}>
        {section === 'chat' ? (
          <button className={classes.followButton} onClick={() => setIsFollowed(!isFollowed)}>
            <Icon type={'star'} colorIcon={`${isFollowed ? '#F7BB05' : '#253746'}`} />
            <span>{isFollowed ? 'Followed' : 'Follow'}</span>
          </button>
        ) : (
          <>
            <Box mr={2}>
              <ButtonFilter marginLeft={marginLeft} />
            </Box>
            <Box mr={2}>
              <ButtonSort marginLeft={marginLeft} />
            </Box>
          </>
        )}
        <ButtonOption />
      </Grid>
    </div>
  );
};

export default SectionActions;
