import type { ICellRendererParams } from '@ag-grid-community/core';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box } from '@material-ui/core';
import * as React from 'react';
import { ButtonLoadmore } from './pagination';

export function InlineLoadmore(props: ICellRendererParams) {
  const backbone = React.useContext(TableBackboneContext);
  const pagination = backbone.getPagination();
  const { _haveMore: haveMore } = props.data;
  const changePagination = (limit) => {
    const { _route: route, _groupColumn: groupColumn, _lastRow: lastRow } = props.data;
    backbone.changeConfig('system.groupByPagination', [{ group: groupColumn, limit: lastRow + limit }]);

    props.api.applyServerSideTransaction({
      route: route,
      remove: [props.node.data],
    });

    props.api.refreshServerSideStore({
      route: route,
    });
  };
  return (
    <>
      {haveMore !== false && (
        <Box height={'100%'} width={'100%'}>
          <ButtonLoadmore limit={pagination.limit} onClick={() => changePagination(pagination.limit)}></ButtonLoadmore>
        </Box>
      )}
    </>
  );
}
