import { EIP_CONSTANT } from '@eip/next/lib/main';
import { epsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';

export const ETABLE_INSIGHT_TEMPLATE = {
  title: 'Etable starter - Insight',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.PERFORMANCE.queryEpMatching,
  },
  primaryKeys: ['storefront.id'],
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  hiddenColumns: [],
  requestHiddenField: {},
  requestIgnoreField: {
    metric: [],
  },
  hiddenFilter: {
    currency: 'USD',
  },
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'countryFormat',
      dataType: 'string',
      filterField: 'storefront.country_code',
      valueGetter: {
        label: 'storefront.country_code',
        id: 'storefront.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'marketplaceFormat',
      dataType: 'string',
      filterField: 'storefront.marketplace_code',
      valueGetter: {
        label: 'storefront.marketplace_code',
        id: 'storefront.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'storefrontFormat',
      dataType: 'string',
      filterField: 'storefront.id',
      valueGetter: {
        label: 'storefront.storefront_name',
        complementId: 'storefront.id',
        subtext: 'storefront.storefront_sid',
        id: 'storefront.id',
      },
    },
    'storefront.gmv': {
      title: 'GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'storefront.gmv',
      },
    },
  },
};
