import { EIP_CONSTANT } from '@eip/next/lib/main';

export const SCRIPT_EXECUTION_LOG = {
  title: 'Script execution log',
  dimension: [],
  metric: [],
  attribute: [],
  tableType: 'compact',
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=automation',
  },
  searchHint: 'Looking for history',
  system: {
    hiddenComponents: ['timeline', 'majorButton'],
  },
  requestIgnoreField: {
    dimension: ['ADTYPE'],
    attribute: [],
  },
  sort: [
    {
      field: 'script_execution_log.created_at',
      sort: 'desc',
    },
  ],
  columnOrder: ['status', 'expected_value', 'createdAt'],
  columnWidth: [
    {
      columnField: 'checkbox',
      width: 40,
    },
    {
      columnField: 'expected_value',
      width: 150,
    },
    {
      columnField: 'status',
      width: 100,
    },
    {
      columnField: 'createdAt',
      width: 230,
    },
  ],
  mapping: {
    createdAt: {
      cellFormat: 'text',
      dataType: 'datetime',
      filterField: '',
      propertyType: 'dimension',
      title: 'Created At',
      valueGetter: {
        value: 'script_execution_log.created_at',
        label: 'script_execution_log.created_at',
        id: 'script_execution_log.created_at',
      },
      menu: [],
      menuItem: [],
      useMock: {},
      staticValue: {},
      actions: [],
    },
    expected_value: {
      menu: [],
      title: 'Expected value',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      cellFormat: 'text',
      filterField: 'script_execution_log.expected_value',
      staticValue: {
        overrideValue: {
          SUSPENDED: 'PAUSED',
        },
      },
      valueGetter: {
        id: 'script_execution_log.expected_value',
        label: 'script_execution_log.expected_value',
        value: 'script_execution_log.expected_value',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    status: {
      menu: [],
      title: 'Status',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      cellFormat: 'scheduleStatusIndicator',
      filterField: 'script_execution_log.status',
      staticValue: {},
      valueGetter: {
        id: 'script_execution_log.status',
        label: 'script_execution_log.status',
        value: 'script_execution_log.status',
        message: 'script_execution_log.message',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
  },
};
