import * as React from 'react';
import FormControlTemplate from '../form-control-template';
import TextBudgetInput, { TextBudgetInputProps } from './text-budget-input';

interface TextInputProps extends TextBudgetInputProps {
  label: string;
  classNameContainer?: string;
}
const TextInput = ({ label, classNameContainer, ...rest }: TextInputProps) => {
  return (
    <FormControlTemplate label={label} className={classNameContainer}>
      <TextBudgetInput {...rest} />
    </FormControlTemplate>
  );
};
export default TextInput;
