import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';
import { cloneDeep, debounce, first, get, isEqual, merge, set } from 'lodash';
import React from 'react';

import ETableConfig from './etable-config';
// Will remove when API is available
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { nanoid } from 'nanoid';

import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import produce from 'immer';
import ButtonEtableConfig from './etable-config/button-etable-config';

import EpsiloTemplateConfig from '../epsilo-template-config';
import { ChartConfigForm } from './chart-config';
import { viewByTimelineOptions } from './etable-config/utils/constant';
import { DEFAULT_CONFIG } from './table-config';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import Icon from '@ep/insight-ui/icons/Icon';
import clsx from 'clsx';
import { DATETIME_PICKER } from '@ep/insight-ui/sw/etable/constant';
import {
  calendarDateLabel,
  CalendarOption,
} from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';
import { WIDTH_TO_SHOW_EXTERNAL, WIDTH_TO_SHOW_SHORTCUT } from '../../helper/constant';

const defaultConfiguration = DEFAULT_CONFIG;

const calendarOptions = Object.values(CalendarOption)
  .filter((value) => value !== CalendarOption.CUSTOM)
  .map((value) => ({
    value,
    label: calendarDateLabel[value],
  }));

const useStyles = makeStyles({
  configurationForm: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    '& .eip1-MuiTextField-root': {
      width: '100%',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: '8px',
    },
  },
  acLabel: {
    whiteSpace: 'nowrap',
  },
  dropdown: {
    background: '#EDF2F9',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});
export const RichConfigForm = ({
  data,
  onSubmit,
  nodeId,
  ignoreTemplate = false,
}: {
  data: NodeData['customAttributes'];
  onSubmit: (...args: any[]) => void;
  nodeId: string;
  ignoreTemplate?: boolean;
}) => {
  const classes = useStyles();
  const [openConfig, setOpenConfig] = React.useState(false);
  const [openButtonConfig, setOpenButtonConfig] = React.useState(false);
  const [sConfig, setStateConfig] = React.useState(data);
  const [openJsonEditor, setOpenJsonEditor] = React.useState(false);

  React.useEffect(() => {
    setStateConfig(data);
  }, [data]);

  const onSubmit1 = React.useCallback((config) => {
    console.info('onsubmit1', config);
    const fConfig = { ...config, _editorId: 'table_' + nanoid() };

    const existsMetricTraction = Object.keys(config.mapping).some(
      (k) => config.mapping[k].cellFormat === 'storefrontMetricTraction',
    );
    if (!existsMetricTraction) {
      delete fConfig['metricTractionProperties'];
    }
    const fConfig1 = produce(fConfig, (draft) => {
      // const columnOrder = config.columnOrder;
      const defaultViewIndex = fConfig.views.findIndex((i) => i.id === 'default');
      // ['dimension', 'attribute', 'metric'].forEach((k) => {
      //   const kFields = columnOrder.filter((f) => config.mapping[f] && config.mapping[f].propertyType === k);
      //   set(draft, ['views', defaultViewIndex, 'combinator', 'properties', k], kFields);
      //   if (fConfig.view.id === 'default') {
      //     draft[k] = kFields;
      //     set(draft, ['view', 'combinator', 'properties', k], kFields);
      //   }
      // });

      if (fConfig.visualizationType === 'table') {
        (fConfig.settingType || []).forEach((item, index) => {
          draft.settingType[index].allowConfig = true;
        });
      }

      Object.entries(fConfig.mapping).forEach(([k, c]: [any, any]) => {
        if (c.defaultCalculated) {
          set(draft, ['groupBy', 'aggregations', k, 'func'], c.defaultCalculated);
          set(
            draft,
            ['views', defaultViewIndex, 'combinator', 'groupBy', 'aggregations', k, 'func'],
            c.defaultCalculated,
          );
          if (fConfig.view.id === 'default') {
            set(draft, ['view', 'combinator', 'groupBy', 'aggregations', k, 'func'], c.defaultCalculated);
          }
        }
      });
    });

    try {
      onSubmit(cloneDeep(fConfig1));
      setStateConfig(cloneDeep(fConfig1));
      setOpenConfig(false);
      setOpenButtonConfig(false);
    } catch (error) {
      window.alert('Error, please resubmit or copy the configuration and contact support');
    }
  }, []);

  const handleClickConfiguration = () => {
    // dispatch(actions.setEditorMode({ mode: 'advance_json_edit' }));
    setOpenConfig(true);
  };

  const handleClose = () => {
    // dispatch(actions.setEditorMode({ mode: 'select' }));
    setOpenConfig(false);
  };

  const config = merge({}, defaultConfiguration, data);

  React.useEffect(() => {
    if (!isEqual(config.columnOrder, sConfig.columnOrder)) {
      setStateConfig((sConfig) => {
        return {
          ...sConfig,
          columnOrder: config.columnOrder,
        };
      });
    }
  }, [config]);

  const [valueExternalFilters, setValueExternalFilters] = React.useState(
    get(config, ['system', 'externalFilters'], []),
  );

  const [externalFiltersOptions, setExternalFiltersOptions] = React.useState(() => {
    const defaultOptions = Object.keys(sConfig.mapping)
      .filter((key) => {
        return sConfig.mapping[key].propertyType === 'dimension';
      })
      .map((key) => ({
        value: key,
        label: sConfig.mapping[key].title,
        isMultiple: true,
      }));

    const configOptions = get(config, ['system', 'externalFiltersOptions'], defaultOptions);
    const additionalOptions = defaultOptions.filter((op) => {
      return !configOptions.some(({ value }) => value == op.value);
    });
    const removedOptions = configOptions.filter((op) => {
      return !defaultOptions.some(({ value }) => value == op.value);
    });

    return configOptions
      .concat(additionalOptions)
      .filter(({ value }) => !removedOptions.some((removedOption) => removedOption.value == value));
  });

  const enhancedExternalFiltersOptions = React.useMemo(() => {
    return externalFiltersOptions.map((el) => ({
      ...el,
      endIcon:
        el.isMultiple == null || el.isMultiple
          ? 'ic/material-symbols:check-box/#253746'
          : 'ic/ic:baseline-radio-button-checked/#253746',
      onEndIconClick(event, item) {
        setExternalFiltersOptions((prev) => {
          return prev.map((el) => ({
            ...el,
            isMultiple: el.value === item.value ? !el.isMultiple : el.isMultiple,
          }));
        });
      },
    }));
  }, [externalFiltersOptions]);

  const [visualizationType, setVisualizationType] = React.useState(get(config, ['visualizationType'], 'table'));
  const [calendarTypePicker, setCalendarTypePicker] = React.useState(
    get(config, ['calendarConfig', 'typePicker'], 'default'),
  );
  const [dateRangeSelect, setDateRangeSelect] = React.useState(
    get(config, ['calendarConfig', 'dateRangeSelect'], 'yes'),
  );
  const [changeCurrency, setChangeCurrency] = React.useState(get(config, ['system', 'changeCurrency'], 'no'));
  const [hiddenCalendarOptions, setHiddenCalendarOptions] = React.useState(
    get(config, ['calendarConfig', 'hiddenCalendarOptions'], []),
  );
  const [maxDaysSelected, setMaxDaysSelected] = React.useState(get(config, ['calendarConfig', 'maxDaysSelected'], 999));
  const [viewByTimelineConfig, setViewByTimelineConfig] = React.useState(get(config, ['viewByConfig', 'timeline'], []));
  const [allowedViewByTimelineType, setAllowedViewByTimelineType] = React.useState(
    get(
      config,
      ['viewByConfig', 'allowedViewByTimelineType'],
      visualizationType === 'chart' ? ['timeline', 'dimension1', 'dimension2'] : ['timeline'],
    ),
  );
  const [viewByDimensionConfig, setViewByDimensionConfig] = React.useState(
    get(config, ['viewByConfig', 'dimension'], []),
  );

  const [linkToGlobalFilter, setLinkToGlobalFilter] = React.useState(get(config, ['linkToGlobalFilter'], 'no'));
  const [watchMeLink, setWatchMeLink] = React.useState(get(config, ['system', 'watchMeLink'], ''));
  const [allowAccumulative, setAllowAccumulative] = React.useState(get(config, ['system', 'allowAccumulative'], 'no'));
  const [allowExport, setAllowExport] = React.useState(get(config, ['system', 'allowExport'], 'yes'));
  const [defaultExpandedRows, setDefaultExpandedRows] = React.useState(
    get(config, ['system', 'defaultExpandedRows'], 0),
  );
  const [defaultLimit, setDefaultLimit] = React.useState(get(config, ['system', 'defaultLimit'], ''));
  const [numberOfPivot, setNumberOfPivot] = React.useState(get(config, ['system', 'numberOfPivot'], 0));
  const [maximumChartsPerRow, setMaximumChartsPerRow] = React.useState(
    get(config, ['system', 'maximumChartsPerRow'], '5,5,3,2,2'),
  );
  const [monitorConfig, setMonitorConfig] = React.useState(get(config, ['system', 'monitorConfig'], ''));
  const [maximumExportDays, setMaximumExportDays] = React.useState(get(config, ['system', 'maximumExportDays'], 90));
  const [widthToShowShortcut, setWidthToShowShortcut] = React.useState(
    get(config, ['system', 'widthToShowShortcut'], WIDTH_TO_SHOW_SHORTCUT),
  );
  const [widthToShowExternal, setWidthToShowExternal] = React.useState(
    get(config, ['system', 'widthToShowExternal'], WIDTH_TO_SHOW_EXTERNAL),
  );
  // const [allowPivot, setAllowPivot] = React.useState(get(config, ['system', 'allowPivot'], 'no'));
  const [allowPivotMetric, setAllowPivotMetric] = React.useState(get(config, ['system', 'allowPivotMetric'], 'no'));

  const [lockColumnWidth, setLockColumnWidth] = React.useState(get(config, ['system', 'lockColumnWidth'], 'no'));
  const [hideUpdatedAtLabel, setHideUpdatedAtLabel] = React.useState(
    get(config, ['system', 'hideUpdatedAtLabel'], 'no'),
  );

  const viewByDimensionOptions = React.useMemo(() => {
    const mapping = get(config, 'mapping', {});
    return Object.keys(mapping)
      .filter(
        (el) => mapping[el]?.['propertyType'] == 'dimension' && mapping[el]?.['cellFormat'] != 'filterColumnFormat',
      )
      .map((el) => {
        return {
          label: mapping[el].title,
          value: el,
          type: 'dimension',
        };
      })
      .concat({
        label: 'None',
        value: EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE,
        type: 'dimension',
      });
  }, [config]);

  const externalPropertiesOptions = [
    {
      label: 'Dimensions',
      value: 'dimension',
    },
    {
      label: 'Attributes',
      value: 'attribute',
    },
    {
      label: 'Metrics',
      value: 'metric',
    },
    {
      label: 'Pivot',
      value: 'pivot_metric',
    },
  ];

  const [valueExternalProperties, setValueExternalProperties] = React.useState(
    get(config, ['system', 'externalProperties'], []),
  );

  const hiddenComponentsOptions = [
    {
      label: 'Calendar',
      value: 'timeline',
    },
    {
      label: 'Major Button',
      value: 'majorButton',
    },
    {
      label: 'View',
      value: 'view',
    },
    {
      label: 'Reload button',
      value: 'reload_button',
    },
    {
      label: 'Filter',
      value: 'filter',
    },
    {
      label: 'Sort',
      value: 'sort',
    },
    {
      label: 'External Filter',
      value: 'special_button',
    },
    {
      label: 'Metric Filter',
      value: 'special_metric_button',
    },
    {
      label: 'Threedots',
      value: 'settingMenu',
    },
  ];

  const [valueHiddenComponents, setValueHiddenComponents] = React.useState(
    get(config, ['system', 'hiddenComponents'], []),
  );

  const handleSubmit = () => {
    const specialFilters = { ...sConfig.specialFilters };
    Object.keys(specialFilters).forEach((key) => {
      if (!valueExternalFilters.includes(key)) delete specialFilters[key];
    });
    onSubmit1(
      cloneDeep(
        produce(sConfig, (draft) => {
          set(draft, ['system', 'externalFilters'], valueExternalFilters);
          set(draft, ['system', 'externalFiltersOptions'], externalFiltersOptions);
          set(draft, ['system', 'externalProperties'], valueExternalProperties);
          set(draft, ['system', 'hiddenComponents'], ['search', ...valueHiddenComponents]);
          set(draft, 'specialFilters', specialFilters);
          set(draft, 'visualizationType', visualizationType);
          set(draft, ['calendarConfig', 'typePicker'], calendarTypePicker);
          set(draft, ['calendarConfig', 'dateRangeSelect'], dateRangeSelect);
          set(draft, ['system', 'changeCurrency'], changeCurrency);
          set(draft, ['calendarConfig', 'hiddenCalendarOptions'], hiddenCalendarOptions);
          set(draft, ['calendarConfig', 'maxDaysSelected'], maxDaysSelected);
          set(draft, ['linkToGlobalFilter'], linkToGlobalFilter);
          set(draft, ['system', 'watchMeLink'], watchMeLink);
          set(draft, ['system', 'allowAccumulative'], allowAccumulative);
          set(draft, ['system', 'allowExport'], allowExport);
          set(draft, ['system', 'defaultExpandedRows'], defaultExpandedRows);
          set(draft, ['system', 'defaultLimit'], defaultLimit);
          set(draft, ['system', 'numberOfPivot'], numberOfPivot);
          set(draft, ['system', 'maximumChartsPerRow'], maximumChartsPerRow);
          set(draft, ['system', 'monitorConfig'], monitorConfig);
          set(draft, ['system', 'maximumExportDays'], maximumExportDays);
          set(draft, ['system', 'widthToShowShortcut'], widthToShowShortcut);
          set(draft, ['system', 'widthToShowExternal'], widthToShowExternal);
          // set(draft, ['system', 'allowPivot'], allowPivot);
          set(draft, ['system', 'allowPivotMetric'], allowPivotMetric);
          set(draft, ['system', 'lockColumnWidth'], lockColumnWidth);
          set(draft, ['system', 'hideUpdatedAtLabel'], hideUpdatedAtLabel);
          set(draft, ['viewByConfig', 'timeline'], viewByTimelineConfig);
          set(draft, ['viewByConfig', 'allowedViewByTimelineType'], allowedViewByTimelineType);
          set(draft, ['viewByConfig', 'dimension'], viewByDimensionConfig);
        }),
      ),
    );
  };

  const handleChartSubmit = (config) => {
    const updatedConfig = produce(config, (draft) => {
      draft.visualizationType = visualizationType;
      set(draft, ['system', 'externalFilters'], get(config, 'chartConfig.config.externalFilters', []));
      set(draft, ['system', 'externalFiltersOptions'], get(config, 'chartConfig.config.externalFiltersOptions', []));
      set(draft, ['system', 'externalProperties'], valueExternalProperties);
      set(draft, ['calendarConfig', 'typePicker'], calendarTypePicker);
      set(draft, ['calendarConfig', 'dateRangeSelect'], dateRangeSelect);
      set(draft, ['system', 'changeCurrency'], changeCurrency);
      set(draft, ['calendarConfig', 'hiddenCalendarOptions'], hiddenCalendarOptions);
      set(draft, ['calendarConfig', 'maxDaysSelected'], maxDaysSelected);
      set(draft, ['linkToGlobalFilter'], linkToGlobalFilter);
      set(draft, ['system', 'watchMeLink'], watchMeLink);
      set(draft, ['system', 'allowAccumulative'], allowAccumulative);
      set(draft, ['system', 'maximumExportDays'], maximumExportDays);
      set(draft, ['system', 'widthToShowExternal'], widthToShowExternal);
      set(draft, ['system', 'widthToShowShortcut'], widthToShowShortcut);
      set(draft, ['system', 'allowExport'], allowExport);
      set(draft, ['system', 'hideUpdatedAtLabel'], hideUpdatedAtLabel);
      set(draft, ['system', 'hiddenComponents'], ['search', ...valueHiddenComponents]);
      set(draft, ['viewByConfig', 'timeline'], viewByTimelineConfig);
      set(draft, ['viewByConfig', 'allowedViewByTimelineType'], allowedViewByTimelineType);
      set(draft, ['viewByConfig', 'dimension'], viewByDimensionConfig);
    });
    onSubmit1(cloneDeep(updatedConfig));
  };

  const actions = [
    [
      {
        title: 'Edit Main button',
        onClick() {
          setOpenButtonConfig(true);
        },
      },
      {
        title: 'Geek Mode',
        onClick() {
          setOpenJsonEditor(true);
        },
      },
    ],
  ];

  return (
    <React.Fragment>
      <div className={classes.configurationForm}>
        <div>
          {!ignoreTemplate ? <EpsiloTemplateConfig nodeId={nodeId} config={sConfig} chartId={'richTable'} /> : null}
          <ButtonGroup variant="contained" color="secondary">
            <Button variant="contained" color="secondary" onClick={handleClickConfiguration} id="edit-config-btn">
              <span className={classes.acLabel}>Advanced configuration</span>
            </Button>
            <Dropdown
              alignMenu="right"
              disabledToggleStyle
              icon={'chevron'}
              sizeIcon={'12px'}
              className={classes.dropdown}
            >
              <MenuList closeAfterClick listMenu={actions} />
            </Dropdown>
          </ButtonGroup>
          <ETableConfig nodeId={nodeId} open={openConfig} onClose={handleClose} config={sConfig} onSubmit={onSubmit1} />

          <ButtonEtableConfig
            open={openButtonConfig}
            onClose={() => setOpenButtonConfig(false)}
            config={sConfig}
            onSubmit={onSubmit1}
          />
        </div>
        <div>
          <Typography variant="h6">Display as</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                { value: 'table', label: 'Table' },
                { value: 'chart', label: 'Chart' },
                { value: 'category', label: 'Gallery' },
              ]}
              value={visualizationType}
              onChange={(v) => setVisualizationType(first(v))}
              multipleSelect={false}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Watch me link</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={watchMeLink}
              onChange={(e) => setWatchMeLink(e.target.value)}
              type={'text'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Allow Change Currency</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={changeCurrency}
              onChange={([value]) => setChangeCurrency(value)}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Calendar Type picker</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Month',
                  value: 'month',
                },
                {
                  label: 'Week',
                  value: 'week',
                },
                {
                  label: 'Date',
                  value: 'default',
                },
                {
                  label: 'Datetime',
                  value: DATETIME_PICKER,
                },
              ]}
              value={calendarTypePicker}
              onChange={([value]) => setCalendarTypePicker(value)}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Allow DateRange Select</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={dateRangeSelect}
              onChange={([value]) => setDateRangeSelect(value)}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Hidden calendar options</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={calendarOptions}
              value={hiddenCalendarOptions}
              onChange={setHiddenCalendarOptions}
              multipleSelect
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Maximum Days Selected</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={maxDaysSelected}
              onChange={(e) => setMaxDaysSelected(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Allowed ViewBy Type</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={
                visualizationType === 'chart'
                  ? [
                      {
                        label: 'Timeline',
                        value: 'timeline',
                      },
                      {
                        label: 'Dimension 1',
                        value: 'dimension1',
                      },
                      {
                        label: 'Dimension 2',
                        value: 'dimension2',
                      },
                    ]
                  : [
                      { label: 'Timeline', value: 'timeline' },
                      { label: 'Dimension', value: 'dimension1' },
                    ]
              }
              value={allowedViewByTimelineType}
              onChange={setAllowedViewByTimelineType}
              multipleSelect
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">ViewBy Timeline</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={viewByTimelineOptions}
              value={viewByTimelineConfig}
              onChange={setViewByTimelineConfig}
              multipleSelect
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">ViewBy Dimension</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={viewByDimensionOptions}
              value={viewByDimensionConfig}
              onChange={setViewByDimensionConfig}
              multipleSelect
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Link to page filter</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={linkToGlobalFilter}
              onChange={([value]) => setLinkToGlobalFilter(value)}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Default limit</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={defaultLimit}
              onChange={(e) => setDefaultLimit(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Allow accumulative</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={allowAccumulative}
              onChange={([value]) => {
                setAllowAccumulative(value);
              }}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Allow export</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={allowExport}
              onChange={([value]) => {
                setAllowExport(value);
              }}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Maximum export days</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={maximumExportDays}
              onChange={(e) => setMaximumExportDays(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">External Metric</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={externalPropertiesOptions}
              value={valueExternalProperties}
              onChange={setValueExternalProperties}
              multipleSelect={true}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Hidden Components</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={hiddenComponentsOptions}
              value={valueHiddenComponents}
              onChange={setValueHiddenComponents}
              multipleSelect={true}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Hide updated at label</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={[
                {
                  label: 'Yes',
                  value: 'yes',
                },
                {
                  label: 'No',
                  value: 'no',
                },
              ]}
              value={hideUpdatedAtLabel}
              onChange={([value]) => setHideUpdatedAtLabel(value)}
              multipleSelect={false}
              searchAble={false}
              isConfigChart
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Width to show shortcut</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={widthToShowShortcut}
              onChange={(e) => setWidthToShowShortcut(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Width to show external filters</Typography>
          <div style={{ width: '100%' }}>
            <TextField
              variant="outlined"
              value={widthToShowExternal}
              onChange={(e) => setWidthToShowExternal(e.target.value)}
              type={'number'}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6" className={classes['mb-4']}>
            Monitor config
          </Typography>
          <div style={{ width: '100%' }}>
            <TextField
              placeholder="Placehoder"
              onChange={(e) => setMonitorConfig(e.target.value)}
              variant="outlined"
              value={monitorConfig}
            />
          </div>
          <span>E.g: 20(seconds delay),24(hours look back).</span>
        </div>
        {visualizationType === 'table' && (
          <React.Fragment>
            <div>
              <Typography variant="h6">Default expanded rows</Typography>
              <div style={{ width: '100%' }}>
                <TextField
                  variant="outlined"
                  value={defaultExpandedRows}
                  onChange={(e) => setDefaultExpandedRows(e.target.value)}
                  type={'number'}
                />
              </div>
            </div>
            <div>
              <Typography variant="h6">External Filters</Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={enhancedExternalFiltersOptions}
                  value={valueExternalFilters}
                  onChange={setValueExternalFilters}
                  onReorder={setExternalFiltersOptions}
                  multipleSelect={true}
                  searchAble={false}
                  isConfigChart={true}
                />
              </div>
            </div>
            <div>
              <Typography variant="h6">Lock column width</Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  value={lockColumnWidth}
                  onChange={([value]) => setLockColumnWidth(value)}
                  multipleSelect={false}
                  searchAble={false}
                  isConfigChart
                />
              </div>
            </div>
            {/* <div>
              <Typography variant="h6">Allow Pivot</Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  value={allowPivot}
                  onChange={([value]) => setAllowPivot(value)}
                  multipleSelect={false}
                  searchAble={false}
                  isConfigChart
                />
              </div>
            </div> */}
            {/* {allowPivot === 'yes' ? (
              <div>
                <Typography variant="h6">Number of pivot columns</Typography>
                <div style={{ width: '100%' }}>
                  <TextField
                    variant="outlined"
                    value={numberOfPivot}
                    onChange={(e) => setNumberOfPivot(e.target.value)}
                    type={'number'}
                  />
                </div>
              </div>
            ) : null} */}
            <div>
              <Typography variant="h6">Allow Pivot Metric</Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  value={allowPivotMetric}
                  onChange={([value]) => setAllowPivotMetric(value)}
                  multipleSelect={false}
                  searchAble={false}
                  isConfigChart
                />
              </div>
            </div>
          </React.Fragment>
        )}
        {visualizationType !== 'chart' ? (
          <>
            <>
              <div>
                <Typography variant="h6" className={classes['mb-4']}>
                  Maximum Chart Per Row
                </Typography>
                <div style={{ width: '100%' }}>
                  <TextField
                    placeholder="Placehoder"
                    onChange={(e) => setMaximumChartsPerRow(e.target.value)}
                    variant="outlined"
                    value={maximumChartsPerRow}
                  />
                </div>
              </div>
            </>
            <div>
              <Button
                variant="contained"
                color="primary"
                style={{ marginTop: '15px', width: '100%' }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </>
        ) : null}

        {visualizationType === 'chart' && (
          <>
            <ChartConfigForm data={sConfig} onSubmit={handleChartSubmit} />
          </>
        )}
        <PopupJsonEditor
          title="Config"
          open={openJsonEditor}
          value={sConfig}
          onValueChange={onSubmit1}
          onClose={() => setOpenJsonEditor(false)}
        />
      </div>
    </React.Fragment>
  );
};

const useStyle = makeStyles((theme) => ({
  container: {
    '& .jsoneditor': {
      height: '80vh',
    },
  },
  actions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

function PopupJsonEditor(props: {
  title: string;
  open: boolean;
  value: any;
  onValueChange: (value: any) => void;
  onClose: () => void;
  className?: string;
}) {
  const classes = useStyle();
  const content = React.useRef(props.value);
  const renderedRef = React.useRef(false);
  const renderJsonEditor = React.useCallback(
    debounce((ref: HTMLElement, value: any, onValueChange: (value: any) => void) => {
      const editor = new window._JSONEditor({
        target: ref,
        props: {
          queryLanguages: Object.values(window._JSONEditorQueryLanguages),
          content: { text: undefined, json: content.current },
          onChange: (updatedContent) => {
            if (updatedContent.json) {
              content.current = updatedContent.json;
            }
          },
        },
      });
      renderedRef.current = true;
    }, 100),
    [],
  );

  React.useEffect(() => {
    if (!props.open) {
      renderedRef.current = false;
    }
  }, [props.open]);

  return (
    <Dialog open={props.open} fullWidth={true} maxWidth="xl" className={clsx('ep-advance-jsoneditor', props.className)}>
      <DialogTitle disableTypography>
        <Typography variant="h6">{props.title}</Typography>
        <Box className={classes.actions}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              props.onValueChange(content.current);
            }}
          >
            Submit
          </Button>
          {props.onClose && (
            <IconButton aria-label="close" onClick={props.onClose}>
              <Icon type="close"></Icon>
            </IconButton>
          )}
        </Box>
      </DialogTitle>
      <DialogContent className={classes.container}>
        <React.Suspense fallback={<div>Loading...</div>}>
          <div
            className="jsoneditor"
            ref={(ref) => ref && !renderedRef.current && renderJsonEditor(ref, props.value, props.onValueChange)}
          ></div>
        </React.Suspense>
      </DialogContent>
    </Dialog>
  );
}
