import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { List, ListItem, ListItemIcon, ListItemText, makeStyles, Popover, Box } from '@material-ui/core';
import clsx from 'clsx';
import { get, isArray } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import Dropdown from '../dropdown-menu/dropdown';
/**
 * ff.ui_ux_update_tag_item:start
 */
import { Dialog } from '@material-ui/core';
/**
 * ff.ui_ux_update_tag_item:end
 */

import { NodeEditContext } from '@eip/next/lib/main';
import Wrapper from '../etable2/wrapper';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';

const useStyles = makeStyles((theme) => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3px',
  },
  formatcell: {
    display: 'flex',
    flexDirection: 'column',
  },

  statusNone: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    textAlign: 'end',
  },
  text: {
    paddingLeft: '5px',

    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
  texttotal: {
    textAlign: 'end',
    paddingLeft: '5px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: '#253746',
  },
  textdaily: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    lineHeight: '0',
  },
  helpText: {
    fontSize: '10px',
    color: '#8C98A4',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    display: 'block',
    lineHeight: '0',
    marginTop: '-10px',
  },
  textCurrent: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
  },
  textPosition: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    display: 'flex',
    justifyContent: 'space-between',
  },
  forcell: {
    marginLeft: '-10px',
  },
  cellDropdown: {
    maxWidth: 250,
  },
  display: {
    display: 'none',
  },
  edotContainer: {
    // position: 'absolute',
    right: 0,
    background: 'transparent',
  },
  button: {
    minWidth: '24px',
    height: '24px',
  },
  menuDropdown: {
    paddingTop: '8px',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  disable: {
    pointerEvents: 'none',
    color: '#C2C7CB',
  },
  itemDropdownContainer: {
    position: 'relative',
    '&:hover': {
      '& .editAction': {
        display: 'block',
      },
    },
    '& .editAction': {
      position: 'absolute',
      background: 'transparent',
      top: '50%',
      right: '4px',
      transform: 'translateY(-50%)',
      padding: '0 4px',
      display: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
      '&:hover': {
        background: '#E4E7E9',
      },
    },
  },
  iconContainer: {
    '& svg': {
      maxWidth: '16px',
    },
  },
}));

export const DropdownCell = (data) => {
  const classes = useStyles();
  const origin = React.useRef<HTMLDivElement>();
  const [indexRender, setIndexRender] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subActions, setSubActions] = React.useState(null);
  const onOpened = get(data, 'onOpened', () => undefined);
  const onClosed = get(data, 'onClosed', () => undefined);
  const [dropdownFuncs, setDropdownFuncs] = useState<{ open: () => void; close: () => void }>({
    open: () => undefined,
    close: () => undefined,
  });
  const handleClose = () => {
    setAnchorEl(null);
    onClosed();
  };

  let dropdownOnclose = null;
  dropdownOnclose = () => {
    setSubActions(null);
    onClosed();
  };

  const popoverRef = React.useRef(null);

  const { isEditMode } = React.useContext(NodeEditContext);

  const propsComponent = {
    onSubmit: data?.cellAction[indexRender].onSubmit,
    onLoad: data?.cellAction[indexRender].onLoad,
    title: data?.cellAction[indexRender].title,
    payload: data?.cellAction[indexRender].payload,
    customProps: data?.cellAction[indexRender]?.customProps,
    dimension: data?.cellAction[indexRender]?.dimension,
    field: data?.cellAction[indexRender]?.field,
    data: data.props,
    setAnchorEl: (val: any) => {
      setAnchorEl(val);
      if (!val) onClosed();
    },
    anchorEl: anchorEl,
    ...(ff.ui_ux_update_tag_item
      ? {
          actionSubItem: data.props.actionSubItem,
        }
      : {}),
    popoverRef: popoverRef,
  };
  let typeComponent;
  if (ff.ui_ux_update_tag_item) {
    typeComponent = React.useMemo(() => data?.cellAction[indexRender]?.type, [indexRender, data]);
  }
  const Component = React.useMemo(() => get(data, ['cellAction', indexRender, 'component'], null), [indexRender, data]);
  const editAction = (e, menu) => {
    e.stopPropagation();
    try {
      document.querySelector('#edit-config-btn').click();
      setTimeout(() => {
        document.querySelector(`#compact-${menu.shortcut.key}-actions`).click();
        setTimeout(() => {
          document.querySelectorAll('.advanced-configuration-btn').forEach((node, i) => {
            if (i === menu.shortcut.index) {
              node.click();
            }
          });
        }, 100);
      }, 100);
      onClosed();
      return;
    } catch (e) {
      // Do nothing
    }
  };
  const handleClickMenuItem = (menu, index) => {
    if (menu.component) {
      setIndexRender(index);
      setAnchorEl(origin.current);
    } else {
      const staticValue = get(menu, ['payload', 'static']);
      const cellValue = get(data, 'props.value');
      const onSubmit = get(menu, ['onSubmit']);
      const menu_subActions = get(menu, ['subActions'], []);
      if (!(menu_subActions.length > 0)) {
        if (onSubmit) {
          onSubmit({
            value: staticValue || cellValue,
            row: get(data, 'props.node.data'),
            target: origin.current,
          });
        }
        dropdownFuncs.close();
        onClosed();
      } else {
        setSubActions(menu_subActions);
      }
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');

  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const selectedRowCount = backboneContext.getSelectedRows().length;

  let renderActionMenu = null;
  const mappingListItem = function renderActionListItem(menu, index) {
    return (
      <MonitorContainer component={'div'} mId={'edot-action-menu'} mLabel={'Edot action menu'} key={index} className={classes.itemDropdownContainer}>
        {/* {index === data.cellAction?.length - 1 && <Divider />} */}
        <ListItem
          className={clsx({
            [classes.itemDropdown]: true,
            [classes.disable]: menu.disable,
          })}
          disabled={menu.disable}
          role={'menuitem'}
          aria-label={menu.name}
          button
          onClick={() => {
            handleClickMenuItem(menu, index);
          }}
        >
          {menu.icon && (
            <ListItemIcon className={classes.iconContainer}>
              <Icon
                style={menu.iconSize && { height: menu.iconSize, width: menu.iconSize }}
                type={menu.icon}
                colorIcon={menu.disable ? '#C2C7CB' : '#253746'}
              />
            </ListItemIcon>
          )}
          <ListItemText primary={menu.name} className={classes.textStyle} />
        </ListItem>
        {isEditMode && menu.shortcut ? (
          <Box className={'editAction'} onClick={(e) => editAction(e, menu)}>
            <Icon type={'edit'} />
          </Box>
        ) : null}
      </MonitorContainer>
    );
  };

  if (ff.ui_ux_update_tag_item) {
    renderActionMenu =
      !Boolean(anchorEl) || typeComponent == 'deleteEditor' ? (
        <List
          component="nav"
          aria-label="mailbox folders"
          className={`${classes.cellDropdown} eip_action_column_${data?.classField} eip_action_etable_${titleTableClass}`}
          mass={selectedRowCount > 1 ? 'true' : 'false'}
        >
          {!subActions
            ? data.cellAction && data.cellAction.map(mappingListItem)
            : isArray(subActions) && (subActions as Array<any>).map(mappingListItem)}
        </List>
      ) : null;
  } else {
    renderActionMenu = !Boolean(anchorEl) ? (
      <List
        component="nav"
        aria-label="mailbox folders"
        className={`${classes.cellDropdown} eip_action_column_${data?.classField} eip_action_etable_${titleTableClass}`}
        mass={selectedRowCount > 1 ? 'true' : 'false'}
      >
        {!subActions
          ? data.cellAction && data.cellAction.map(mappingListItem)
          : isArray(subActions) && (subActions as Array<any>).map(mappingListItem)}
      </List>
    ) : null;
  }

  const newAction = data.cellAction.map((menu, index) => {
    return {
      ...menu,
      onClick() {
        handleClickMenuItem(menu, index);
      },
      data: data.props,
    };
  });
  return (
    <MonitorContainer component='div' mId='edot' mLabel={'edot'} ref={origin} className={classes.edotContainer}>
      <Dropdown
        classNamePaper={classes.menuDropdown}
        className={`${classes.button} eip_cell_act_${data?.classField}`}
        alignMenu="right"
        icon={data.icon ? data.icon : 'threeDotsVertical'}
        label=""
        defaultOpen={Boolean(anchorEl)}
        onOpen={onOpened}
        handleOnClosed={dropdownOnclose}
        refFuncs={setDropdownFuncs}
        dataMenuPage={[newAction]}
        {...(ff.mobile_interaction_zone
          ? {
              isDropdownMobileShow: data.isDropdownMobileShow,
              setIsDropdownMobileShow: data.setIsDropdownMobileShow,
              title: data.classField,
            }
          : {})}
      >
        {renderActionMenu}
      </Dropdown>
      {Component &&
        (ff.ui_ux_update_tag_item && typeComponent == 'deleteEditor' ? (
          <Dialog
            open={Boolean(anchorEl)}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <MonitorContainer mId={'inline-form/' + propsComponent.title} mLabel={`Inline form: ${propsComponent.title}`} component={Component} {...propsComponent} />
          </Dialog>
        ) : (
          <MonitorContainer component={Popover} mId={'inline-form/' + propsComponent.title} mLabel={`Inline form: ${propsComponent.title}`}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            action={popoverRef}
          >
            <Wrapper>
              <Component {...propsComponent} />
            </Wrapper>
          </MonitorContainer>
        ))}
    </MonitorContainer>
  );
};
