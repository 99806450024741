import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Refresh = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 1.00492L12 3.99193C12 4.55251 11.5571 4.99596 10.9972 4.99596L8.01392 4.99596C7.11977 4.99596 6.66852 3.91662 7.30362 3.28073L8.03564 2.54779C7.43954 2.19604 6.74238 1.99223 5.99998 1.99223C4.98884 1.99223 4.06126 2.37711 3.35931 2.99626C2.71585 3.56522 2.25624 4.32661 2.08075 5.20514C1.98048 5.66532 1.57936 6 1.10304 6C0.476297 6 -2.64154e-05 5.42268 0.133679 4.81189C0.259027 4.15927 0.501367 3.54848 0.818916 2.99626C1.85513 1.20573 3.78549 0.000891326 5.99998 0.000891423C7.29349 0.00089148 8.49545 0.413387 9.47465 1.107L10.2869 0.293733C10.922 -0.333789 12 0.109659 12 1.00492Z"
      {...(ff.mobile_interaction_zone ? {} : { fill: `${props.color ? props.color : '#3F4F5C'}` })}
    />
    <path
      d="M4.39267e-08 10.9951L1.74493e-07 8.00807C1.98997e-07 7.44748 0.451254 7.00404 1.00279 7.00404L3.98608 7.00404C4.88023 7.00404 5.33148 8.08337 4.69638 8.71926L3.96724 9.44931C4.5627 9.80463 5.25879 10.0078 5.99997 10.0078C7.01111 10.0078 7.93869 9.62289 8.64064 9.00374C9.28409 8.43479 9.7437 7.66503 9.91919 6.79486C10.0195 6.33468 10.4206 6 10.8969 6C11.5236 6 12 6.56895 11.8663 7.18811C11.7409 7.84073 11.4986 8.45152 11.181 9.00374C10.1448 10.7943 8.21445 11.9991 5.99997 11.9991C4.70834 11.9991 3.50799 11.5878 2.52958 10.8888L1.71309 11.7063C1.078 12.3338 4.79339e-09 11.8903 4.39267e-08 10.9951Z"
      {...(ff.mobile_interaction_zone ? {} : { fill: `${props.color ? props.color : '#3F4F5C'}` })}
    />
  </SvgIcon>
);

export default Refresh;
