import { actionNative } from '@ep/one/src/utils/redux';
import { reArrangeLayout } from '@eip/next/int/auto-layout';
import { produce } from 'immer';
import { cloneDeep, mapValues, omit, set } from 'lodash';
import { nanoid } from 'nanoid';
import { SCREEN_COLUMNS } from '../../constant';
import { DashboardState, NodeData } from './type';

function isContainer(nodeData: NodeData) {
  return nodeData.chartLibId === 'container';
}

const actions = {
  set: actionNative({
    actname: 'SET',
    fun: ({ layouts, nodes, layoutColumns }) => ({
      layouts,
      nodes,
      layoutColumns,
    }),
  }),
  select: actionNative({
    actname: 'SELECT',
    fun: ({ nodeId }) => ({ nodeId, mode: 'select' }),
  }),
  selectMultiple: actionNative({
    actname: 'SELECT_MULTIPLE',
    fun: ({ nodeIds }) => ({ nodeIds }),
  }),
  edit: actionNative({
    actname: 'EDIT',
    fun: ({ nodeId }) => ({ nodeId, mode: 'edit' }),
  }),
  connect: actionNative({
    actname: 'CONNECT',
    fun: ({ nodeId, eventId, drilldownPointId }) => ({
      nodeId,
      mode: 'connect',
      eventId,
      drilldownPointId,
    }),
  }),
  connectCancel: actionNative({
    actname: 'CONNECT_CANCEL',
    fun: () => {},
  }),
  editText: actionNative({
    actname: 'EDIT_TEXT',
    fun: ({ nodeId }) => ({ nodeId, mode: 'edit_text' }),
  }),
  addNewNode: actionNative({
    actname: 'ADD_NEW_NODE',
    fun: ({ node }) => {
      return { node };
    },
  }),
  removeNode: actionNative({
    actname: 'REMOVE_NODE',
    fun: ({ nodeId }) => {
      return { nodeId };
    },
  }),
  updateNode: actionNative({
    actname: 'UPDATE_NODE',
    fun: ({ nodeId, data }) => ({ nodeId, data }),
  }),
  updateSingleBlockLayout: actionNative({
    actname: 'UPDATE_SINGLE_BLOCK_LAYOUT',
    fun: ({ nodeId, layout }) => ({ nodeId, layout }),
  }),
  groupNodes: actionNative({
    actname: 'GROUP_NODES',
    fun: ({ nodeIds }) => ({ nodeIds }),
  }),
  unGroupNodes: actionNative({
    actname: 'UNGROUP_NODES',
    fun: ({ nodeId }) => ({ nodeId }),
  }),
  submitDrilldownConnect: actionNative({
    actname: 'SUBMIT_DRILLDOWN_CONNECT',
    fun: ({ nodeSrc, nodeDest, eventId, added }) => {
      return { nodeSrc, nodeDest, eventId, added };
    },
  }),
  updateBreakpoint: actionNative({
    actname: 'UPDATE_BREAKPOINT',
    fun: ({ breakpoint }) => ({ breakpoint }),
  }),
  updateLayout: actionNative({
    actname: 'UPDATE_LAYOUT',
    fun: ({ layout }) => ({ layout }),
  }),
  updateBlockLayout: actionNative({
    actname: 'UPDATE_LAYOUT_BLOCK',
    fun: ({ blockId, layout }) => ({ blockId, layout }),
  }),
  updateLayoutColumns: actionNative({
    actname: 'UPDATE_LAYOUT_COLUMNS',
    fun: ({ breakpoint, columns }) => ({
      breakpoint,
      columns,
    }),
  }),
  duplicateNode: actionNative({
    actname: 'DUPLICATE_NODE',
    fun: ({ nodeId }) => ({ nodeId }),
  }),
  setEditorMode: actionNative({
    actname: 'SET_EDITOR_MODE',
    fun: ({ mode }: { mode: modeGeneral['type'] }) => ({ mode }),
  }),
};

export { actions };

export const initState = {
  layouts: { md: [] },
  nodes: [],
  mode: { type: null, activeNode: null },
  breakpoint: 'lg',
  baseBreakpoint: 'lg',
  layoutColumns: {
    lg: SCREEN_COLUMNS.lg.default,
    md: SCREEN_COLUMNS.md.default,
    sm: SCREEN_COLUMNS.sm.default,
    xs: SCREEN_COLUMNS.xs.default,
    xxs: SCREEN_COLUMNS.xxs.default,
  },
};

export function reducer(
  state: DashboardState = {
    blockEid: null,
    blockType: null,
    parentId: null,
    content: null,
    layouts: { md: [] },
    nodes: [],
    mode: { type: null, activeNode: null },
    breakpoint: 'lg',
    baseBreakpoint: 'lg',
    layoutColumns: {
      lg: SCREEN_COLUMNS.lg.default,
      md: SCREEN_COLUMNS.md.default,
      sm: SCREEN_COLUMNS.sm.default,
      xs: SCREEN_COLUMNS.xs.default,
      xxs: SCREEN_COLUMNS.xxs.default,
    },
    // mode: { type: 'connect', activeNode: 'OvpbiH8VikHYavSU1TQI2' },
  },
  action: { type: string; payload: any },
) {
  const payload = action.payload;

  switch (action.type) {
    case actions.edit.type():
    case actions.editText.type():
    case actions.select.type(): {
      state = produce(state, (draft) => {
        draft.mode.type = payload.mode;
        draft.mode.activeNode = payload.nodeId;
        if (payload.nodeId === null) {
          draft.mode.type = null;
        }
      });
      break;
    }
    case actions.connect.type(): {
      state = produce(state, (draft) => {
        draft.mode.type = 'connect';
        draft.mode.activeNode = payload.nodeId;
        draft.mode.eventId = payload.eventId;
        draft.mode.drilldownPointId = payload.drilldownPointId;
        draft.mode.activeNodeData = state.nodes.find((i) => i.id === payload.nodeId);
      });
      break;
    }
    case actions.set.type(): {
      state = produce(state, (draft) => {
        draft.layouts = payload.layouts;
        draft.nodes = payload.nodes;
      });
      break;
    }
    case actions.addNewNode.type(): {
      state = produce(state, (draft) => {
        const { node } = payload;
        if (!node.parentId) {
          draft.layouts = mapValues(state.layouts, (layout) => {
            return layout.concat(omit(node, ['chartLibId']));
          });
        } else {
          const parentIndex = state.nodes.findIndex((n) => n.id == node.parentId);
          const parentNode = state.nodes[parentIndex];
          parentNode.content = parentNode.content.concat(node.id);
          parentNode.format.layout = [].concat(parentNode.format.layout).concat({ i: node.id, ...node });
          draft.nodes[parentIndex] = parentNode;
        }

        draft.nodes = state.nodes.concat({
          id: node.id,
          chartLibId: node.chartLibId,
          parentId: node.parentId,
          containerId: node.parentId,
        });
      });
      break;
    }
    case actions.removeNode.type(): {
      const id = payload.nodeId;
      state = produce(state, (draft) => {
        const nuLayouts = mapValues(state.layouts, (layout) => {
          return (layout || []).filter((i) => i.id !== id);
        });

        const nuNodes = state.nodes.filter((n) => {
          return n.id !== id && n.containerId !== id;
        });

        draft.layouts = nuLayouts;
        draft.nodes = nuNodes;
      });
      break;
    }
    case actions.updateNode.type(): {
      const { nodeId, data } = payload;
      state = produce(state, (draft) => {
        const index = state.nodes.findIndex((n) => n.id === nodeId);
        if (index > -1) {
          draft.nodes[index] = data;
        }
      });
      break;
    }
    case actions.updateSingleBlockLayout.type(): {
      const { nodeId, layout } = payload;

      state = produce(state, (draft) => {
        const bp = state.breakpoint;
        const blockLayout = draft.layouts[bp].find((i) => i.id == nodeId);
        if (blockLayout) blockLayout.layout = { ...blockLayout.layout, ...layout };
      });

      break;
    }
    case actions.groupNodes.type(): {
      const { nodeIds } = payload;
      state = produce(state, (draft) => {
        const containerId = nanoid();
        const layout = state.layouts[state.breakpoint];
        const nodeLayouts = layout.filter((n) => nodeIds.indexOf(n.id) > -1);
        const rect = nodeLayouts.reduce(
          (acc, n) => {
            return {
              x: acc.x === null ? n.layout.x : Math.min(acc.x, n.layout.x),
              y: acc.y === null ? n.layout.y : Math.min(acc.y, n.layout.y),
              x1: acc.y1 === null ? n.layout.x + n.layout.w : Math.max(acc.x1, n.layout.x + n.layout.w),
              y1: acc.x === null ? n.layout.y + n.layout.h : Math.max(acc.y1, n.layout.y + n.layout.h),
            };
          },
          { x: null, y: null, x1: null, y1: null },
        );

        const cLayout = {
          x: rect.x,
          y: rect.y,
          w: rect.x1 - rect.x,
          h: rect.y1 - rect.y,
        };

        for (const nid of nodeIds) {
          const li = layout.findIndex((l) => l.id === nid);
          const ni = state.nodes.findIndex((n) => n.id === nid);
          draft.layouts[state.breakpoint][li].containerId = containerId;
          draft.nodes[ni].containerId = containerId;
        }

        draft.nodes.push({
          id: containerId,
          chartLibId: 'container',
          mainAttributes: null,
          customAttributes: {
            nodeIds: nodeIds,
            nodeLayouts: nodeLayouts.map((i) => ({
              ...i,
              layout: {
                ...i.layout,
                x: i.layout.x - cLayout.x,
                y: i.layout.y - cLayout.y,
              },
            })),
            w: cLayout.w,
            h: cLayout.h,
          },
        });
        draft.layouts[state.breakpoint].push({
          id: containerId,
          layout: cLayout,
        });
      });
      break;
    }
    case actions.unGroupNodes.type(): {
      const { nodeId } = payload;
      state = produce(state, (draft) => {
        const breakpoint = state.breakpoint;
        const containerId = nodeId;
        let layout = state.layouts[breakpoint];
        const container = state.nodes.find((i) => i.id === containerId);
        const containerLayout = layout.find((i) => i.id === containerId);

        const childLayout = container.customAttributes.nodeLayouts;

        const nodes = state.nodes.reduce((ls, node) => {
          if (node.id === containerId) {
            return ls;
          }
          if (node.containerId === containerId) {
            return [...ls, omit(node, 'containerId')];
          } else {
            return ls.concat(node);
          }
        }, []);

        layout = layout
          .concat(
            childLayout.map((i) => ({
              ...i,
              layout: {
                ...i.layout,
                x: i.layout.x + containerLayout.layout.x,
                y: i.layout.y + containerLayout.layout.y,
              },
            })),
          )
          .filter((i) => i.id !== containerId);
        draft.layouts[breakpoint] = layout;
        draft.nodes = nodes;
      });
      break;
    }
    case actions.submitDrilldownConnect.type(): {
      const { nodeSrc: nodeSrcId, nodeDest: nodeDestId, eventId, added } = payload;
      state = produce(state, (draft) => {
        const nodes = state.nodes;
        const nodeSrcIndex = nodes.findIndex((n) => n.id === nodeSrcId);
        const nodeDestIndex = nodes.findIndex((n) => n.id === nodeDestId);

        console.info({ nodeSrcIndex, nodeDestIndex });

        const nodeSrc = nodes[nodeSrcIndex];
        const nodeDest = nodes[nodeDestIndex];

        const childList = nodeSrc.childList || [];
        const parentList = nodeDest.parentList || [];

        nodeSrc.childList = childList
          .filter((i) => i.eventId !== eventId || i.nodeId !== nodeDestId)
          .concat(
            added
              ? {
                  nodeId: nodeDestId,
                  eventId: eventId,
                }
              : [],
          );
        nodeDest.parentList = parentList
          .filter((i) => i.eventId !== eventId || i.nodeId !== nodeSrcId)
          .concat(
            added
              ? {
                  nodeId: nodeSrcId,
                  eventId: eventId,
                }
              : [],
          );
        nodes[nodeSrcIndex] = nodeSrc;
        nodes[nodeDestIndex] = nodeDest;

        draft.nodes = [...nodes];

        draft.mode.type = 'select';
      });
      break;
    }
    case actions.connectCancel.type(): {
      state = produce(state, (draft) => {
        draft.mode.type = 'select';
      });
      break;
    }

    case actions.updateBreakpoint.type(): {
      const { breakpoint } = payload;
      state = produce(state, (draft) => {
        draft.breakpoint = breakpoint;
        if (breakpoint !== state.baseBreakpoint) {
          const layout: any[] = state.layouts[state.baseBreakpoint];
          const baseCols = state.layoutColumns[state.baseBreakpoint];
          const bpCols = state.layoutColumns[breakpoint];

          const sortedLayouts = reArrangeLayout({
            layout,
            base: baseCols,
            viewport: bpCols,
          });

          console.info({ sortedLayouts });

          draft.layouts[breakpoint] = sortedLayouts;
        }
      });
      break;
    }
    case actions.updateLayout.type(): {
      state = produce(state, (draft) => {
        draft.layouts[state.breakpoint] = payload.layout;
        draft.layouts.lg = payload.layout;
      });
      break;
    }
    case actions.updateBlockLayout.type(): {
      const { blockId, layout } = payload;
      state = produce(state, (draft) => {
        const blockIndex = state.nodes.findIndex((n) => n.id === blockId);
        let block = state.nodes[blockIndex];
        block = set(block, 'format.layout', layout);

        draft.nodes[blockIndex] = block;
      });
      break;
    }
    case actions.selectMultiple.type(): {
      state = produce(state, (draft) => {
        draft.mode.type = 'select_multiple';
        draft.mode.activeNodes = payload.nodeIds;
      });
      break;
    }
    case actions.updateLayoutColumns.type(): {
      state = produce(state, (draft) => {
        const { breakpoint, columns } = payload;

        const scaleRatio = columns / state.layoutColumns[breakpoint];
        const newLayouts = state.layouts[breakpoint].map((item) => {
          const newWidth = Math.round(item.layout.w * scaleRatio);
          const newHeight = Math.round(item.layout.h * scaleRatio);
          const newX = Math.round(item.layout.x * scaleRatio);
          const newY = Math.round(item.layout.y * scaleRatio);
          return {
            ...item,
            layout: {
              ...item.layout,
              w: newWidth,
              h: newHeight,
              x: newX,
              y: newY,
            },
          };
        });

        draft.layouts = {
          ...state.layouts,
          [breakpoint]: newLayouts,
        };
        draft.layoutColumns = {
          ...state.layoutColumns,
          [breakpoint]: columns,
        };
      });
      break;
    }
    case actions.duplicateNode.type(): {
      const { nodeId } = payload;
      const bp = state.breakpoint;

      state = produce(state, (draft) => {
        const layout = state.layouts[bp];

        const nLayout = layout.find((i) => i.id === nodeId);
        const dupNode = cloneDeep(state.nodes.find((n) => n.id === nodeId));

        dupNode.id = nanoid();

        const dupLayout = {
          ...nLayout,
          layout: { ...nLayout.layout },
        };

        dupLayout.id = dupNode.id;

        const maxGridWidth = 36;
        // If has enough space to duplicate block horizontally
        const isMaxWidth = maxGridWidth - nLayout.layout.w < nLayout.layout.w + nLayout.layout.x;

        const blocksAfterRaw = layout
          .filter(
            (el) =>
              (el.layout.x - (nLayout.layout.x + nLayout.layout.w) > nLayout.layout.w ||
                maxGridWidth - (el.layout.x + el.layout.w) >= 0) &&
              el.layout.y <= nLayout.layout.y &&
              el.layout.y + el.layout.h > nLayout.layout.y,
          )
          .sort((a, b) => a.layout.x - b.layout.x);

        const fakeLastBlock = {
          layout: {
            x: maxGridWidth,
            y: dupLayout.layout.y,
          },
        };
        const blocks = [].concat(dupLayout, blocksAfterRaw, fakeLastBlock);
        const matchedIndex = blocks.findIndex((el, index, arr) => {
          if (index === 0) return false;
          const previousBlock = arr[index - 1];
          return el.layout.x - (previousBlock.layout.x + previousBlock.layout.w) >= dupLayout.layout.w;
        });

        if (!isMaxWidth && (blocksAfterRaw.length === 0 || matchedIndex !== -1)) {
          const newX =
            blocksAfterRaw.length === 0
              ? nLayout.layout.x + nLayout.layout.w
              : blocks[matchedIndex - 1].layout.x + blocks[matchedIndex - 1].layout.w;
          dupLayout.layout.x = newX;
        } else {
          // find place in the next row
          const nextY = nLayout.layout.y + nLayout.layout.h;
          const blocksWithNextY = layout
            .filter((el) => el.layout.y <= nextY && el.layout.y + el.layout.h > nextY)
            .sort((a, b) => a.layout.x - b.layout.x);

          const fakeFirstBlock = {
            layout: {
              x: 0,
              y: nextY,
              w: 0,
            },
          };
          const fakeLastBlock = {
            layout: {
              x: maxGridWidth,
              y: nextY,
            },
          };

          const blocks = [].concat(fakeFirstBlock, blocksWithNextY, fakeLastBlock);

          const matchedIndex = blocks.findIndex((el, index, arr) => {
            if (index === 0) return false;
            const previousBlock = arr[index - 1];
            return el.layout.x - (previousBlock.layout.x + previousBlock.layout.w) >= dupLayout.layout.w;
          });

          dupLayout.layout.y = nLayout.layout.y + nLayout.layout.h;

          if (blocksWithNextY.length === 0 || matchedIndex == -1) {
            dupLayout.layout.x = 0;
          } else {
            dupLayout.layout.x = blocks[matchedIndex - 1].layout.x + blocks[matchedIndex - 1].layout.w;
          }
        }

        (function pushOtherDown() {
          draft.layouts[bp] = layout.map((i) => {
            if (i.layout.y >= dupLayout.layout.y) {
              console.info('push layout down, item', i);
              return {
                ...i,
                layout: { ...i.layout, y: i.layout.y + dupLayout.layout.h },
              };
            }
            return i;
          });
        })();

        if (dupNode.chartLibId === 'container') {
          const childNodeIds = dupNode.customAttributes.nodeLayouts.map((i) => i.id);

          const dupChildNodes = state.nodes
            .filter((i) => childNodeIds.indexOf(i.id) > -1)
            .map((i) => ({ ...i, id: nanoid(), originId: i.id }));

          dupNode.customAttributes.nodeLayouts = dupNode.customAttributes.nodeLayouts.map((i) => ({
            ...i,
            id: dupChildNodes.find((i1) => i1.originId === i.id).id,
          }));
          draft.nodes.push(...dupChildNodes);
        }

        draft.layouts[bp].push(dupLayout);
        draft.nodes.push(dupNode);
      });
    }
    case actions.setEditorMode.type(): {
      state = produce(state, (draft) => {
        draft.mode.type = action.payload.mode;
      });
    }
  }

  // console.info('updated state >>', { state, action });

  return state;
}
