import { makeStyles, useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { isMobile } from 'react-device-detect';
import { CompositeProgress } from '../composite-progress';

const useStyles = makeStyles({
  header: {
    fontSize: 24,
    fontWeight: 600,
  },
  title: {
    borderBottom: '1px solid #E5E7EA',
    padding: '20px 0 10px',
    marginBottom: 42,
    fontSize: 12,
    color: '#B5BEC9',
  },
  normalMode: {
    border: '1px gray solid',
    background: '#E5E7EA',
  },
  selectedMode: {
    background: '#000',
    color: '#fff',
    '&:hover': {
      color: 'rgba(0, 0, 0, 0.87)',
    },
  },
  groupContainer: {
    '&.even': {
      paddingRight: '25px',
    },
    '&.odd': {
      paddingLeft: '25px',
    },
  },
});

export const ProgressGroupUI = (props: any) => {
  const classes = useStyles();
  const [chanelVisibility, setChanelVisibility] = useState(props.channelVisibility);

  const matches = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  return (
    <>
      <Grid container justify="space-between">
        <Grid item>
          <div className={classes.header}>{props.title}</div>
        </Grid>
        {props.toggleButton && <Grid item>{props.toggleButton}</Grid>}
      </Grid>

      {!isMobile && (
        <Grid container className={classes.title} spacing={3}>
          <Grid item xs={6}>
            <Box display="flex">
              <Box flexGrow={1}>{props.groupType}</Box>
              <Box>Target</Box>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex">
              <Box flexGrow={1}>{props.groupType}</Box>
              <Box>Target</Box>
            </Box>
          </Grid>
        </Grid>
      )}
      {isMobile && (
        <Grid container className={classes.title}>
          <Grid item xs={12}>
            <Box display="flex">
              <Box flexGrow={1}>{props.groupType}</Box>
              <Box>Target</Box>
            </Box>
          </Grid>
        </Grid>
      )}

      <Grid container spacing={3}>
        {props.processes.map((item: any, index: number) => (
          <Grid
            item
            md={6}
            xs={12}
            key={index}
            className={[classes.groupContainer, matches && index % 2 === 1 ? 'odd' : 'even'].join(' ')}
          >
            <CompositeProgress key={index} data={item} showChannels={true}></CompositeProgress>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
