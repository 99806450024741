import { cloneDeep, get, groupBy } from 'lodash';
import { ETableDataUpdate } from '../push-cell-update';
import { getQueryDatetimeValue } from '../../util/calendar';

export const calculateSecondaryMetricGroupBy = async (
  originData,
  { config, contextQuery, cellUpdate, pageRequests },
  next,
) => {
  const mapping = get(config, ['mapping'], {});
  const secondaryMetrics = get(config, ['metric'], []).filter((i) => {
    const metricValue = get(mapping, [i, 'valueGetter', 'value'], '');
    const metricAggFunc = get(contextQuery, ['groupBy', 'aggregations'], []).find((i) => i.field == metricValue);
    return (
      get(mapping, [i, 'staticValue', 'isSecondaryMetric'], 'no') == 'yes' &&
      String(metricAggFunc?.func).toLowerCase() == 'none'
    );
  });
  const secondaryMetricsAPIFields = secondaryMetrics.map((i) => get(mapping, [i, 'valueGetter', 'value'], ''));
  const isChart = get(config, ['visualizationType']) == 'chart';
  const isNotGroupByRequest =
    !get(contextQuery, ['groupBy', 'dimensions']) ||
    get(contextQuery, ['groupBy', 'drillDowns', 'length']) == get(contextQuery, ['groupBy', 'dimensions', 'length']);

  if (!isChart && !isNotGroupByRequest && secondaryMetrics.length > 0) {
    const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
    const primaryKeys = get(contextQuery, ['groupBy', 'dimensions'], []);
    const rows = get(originData, ['rows'], []);
    const datetimeFieldRequest = get(config, ['system', 'datetimeField'], 'created_datetime');

    // Request for traction data
    const requestTractionData = async function () {
      const isRequesting = Object.entries(pageRequests || {})
        .filter(([key]) => key != config.tableId)
        .some(([_, value]) => {
          return new Date().getTime() - value.createdAt < 30000 && value.status != 'DONE';
        });
      if (isRequesting) {
        setTimeout(() => {
          requestTractionData();
        }, 100);
      } else {
        const params = {
          ...contextQuery,
          metrics: contextQuery.metrics.concat(secondaryMetricsAPIFields),
          isSummary: true,
        };

        const additionalFilters = primaryKeys.map((item, index) => {
          if (index == primaryKeys.length - 1) {
            return {
              field: item,
              operator: 'IN',
              value: rows.map((el) => el[item]),
            };
          }

          return {
            field: item,
            operator: '=',
            value: get(rows, [0, item]),
          };
        });

        params.filter = {
          ...get(params, 'filter', { combinator: 'AND', filters: [] }),
          filters: [
            ...get(params, 'filter.filters', []),
            ...(datetimeFieldRequest
              ? [
                  {
                    field: datetimeFieldRequest,
                    operator: '>=',
                    value: getQueryDatetimeValue(datetimeFieldRequest, params.from, 'start'),
                  },
                  {
                    field: datetimeFieldRequest,
                    operator: '<=',
                    value: getQueryDatetimeValue(datetimeFieldRequest, params.to, 'end'),
                  },
                ]
              : []),
            ...additionalFilters,
          ],
        };

        const process = async (
          context: { columnTractionElasticityKey; elasticityMetrics; groupPeriod; fillSlot; extra },
          el,
        ) => {
          const { rows } = el.data;

          const separator = '|';

          const groupedRows = groupBy(rows, (r) => {
            return primaryKeys.map((f) => r[f]).join(separator);
          });

          const updateData = Object.keys(groupedRows).reduce((carry, rowKey) => {
            const ids = String(rowKey).split(separator);
            const keys = primaryKeys.reduce((carry1, f, index) => {
              return { ...carry1, [f]: ids[index] };
            }, {});
            secondaryMetrics.forEach((metric, index) => {
              const updateCell = {
                keyId: rowKey,
                keys,
                updatePath: [metric],
                data: {
                  value: get(groupedRows, [rowKey, 0, get(secondaryMetricsAPIFields, [index], '')]),
                  loaded: true,
                },
              };

              carry.push(updateCell);
            });

            return carry;
          }, []);
          return updateData as ETableDataUpdate[];
        };

        cellUpdate.addQuery({
          endpoint: endpoint.includes('&isAutoAggregation=true') ? endpoint : endpoint + '&isAutoAggregation=true',
          params: params,
          columnEffects: secondaryMetrics,
          process: process.bind(null, {
            secondaryMetrics: secondaryMetrics,
          }),
        });

        cellUpdate.complete();
      }
    };

    requestTractionData();
  }
  return await next(originData, { config, contextQuery, cellUpdate, pageRequests });
};
