import * as React from 'react';

import { Box, Grid, Typography } from '@material-ui/core';

import { PageEditContext } from '@ep/insight-ui/system/page/context/page-edit';
import ExpansionPanel from '@ep/insight-ui/elements/expansion-panels/expansion-panel';
import ModalConfirm from '@ep/insight-ui/elements/table/dropdown-views/modal-confirm';
import Icon from '@ep/insight-ui/icons/Icon';

import Table from '../images/template/table.svg';
import Textbase from '../images/template/textbase.svg';
import SuperBigMetric from '../images/template/super-big-metric.svg';

const images = {
  richTable: Table,
  textPlain: Textbase,
  eChartv2: SuperBigMetric,
};

const TemplateItem = ({ template, classes, onDragItemStart, onDragItemEnd, pageEdit }: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <Grid item xs={6} key={template.nodeId}>
      <Box
        className={classes.card}
        draggable={true}
        onDragStart={() =>
          onDragItemStart({
            chartId: template.chartId,
            layout: {
              w: 5,
              h: 5,
            },
            advancedProperties: template.config,
          })
        }
        onDragEnd={onDragItemEnd}
      >
        {template.chartId && <img className={classes.image} src={images[template.chartId]}></img>}
        <Typography className="chart-name" variant="body4">
          {template.label}
        </Typography>
        <Box
          position={'absolute'}
          top={'4px'}
          right={'4px'}
          style={{ cursor: 'pointer' }}
          onClick={() => setOpen(true)}
        >
          <Icon type={'close'} width={'8px'} height={'8px'} />
        </Box>
        <ModalConfirm
          title="Deleting a template"
          message={
            <div>
              Are you sure to delete <strong>{template.label}</strong> template?
            </div>
          }
          btnLabel="Delete template"
          openModal={open}
          onClose={() => setOpen(false)}
          action={() => pageEdit.deleteTemplate(template.nodeId)}
        />
      </Box>
    </Grid>
  );
};

const EpsiloTemplates = ({ classes, onDragItemStart, onDragItemEnd }: any) => {
  const pageEdit = React.useContext(PageEditContext);

  const epsiliTemplatesRender = pageEdit.templates.map((template) => (
    <TemplateItem
      template={template}
      classes={classes}
      key={template.nodeId}
      onDragItemStart={onDragItemStart}
      onDragItemEnd={onDragItemEnd}
      pageEdit={pageEdit}
    />
  ));

  return (
    <ExpansionPanel
      label="Custom templates"
      className={classes.expansionPanel}
      classes={{
        contentDetailsRoot: classes.expansionPanel,
      }}
    >
      <Grid container spacing={3}>
        {epsiliTemplatesRender}
      </Grid>
    </ExpansionPanel>
  );
};

export default EpsiloTemplates;
