import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import Selection from '../../form-controls/selection';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as React from 'react';
import FormControlLabel from '../../form-controls/form-control-label';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  container: {
    background: '#fff',
    padding: '5% 4% 3% 4%',
  },
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  selectList: {
    '& .eip1-MuiGrid-grid-xs-8': {
      maxWidth: '100%',
      flexBasis: '100%',
    },
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.textInfo': {
      paddingLeft: '10px',
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  section: {
    marginTop: '24px',
  },
  expanded: {
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      marginBottom: '10px',
    },
  },
  error: {
    '& .eip1-MuiFormHelperText-contained p, & .eip1-MuiFormHelperText-contained svg': {
      color: '#D4290D',
    },
  },
  information: {
    backgroundColor: '#EBF6FF',
    borderRadius: '4px',
    margin: '20px 0 14px 0',
  },
  paddingInfo: {
    padding: '8px 10px',
  },
});

const ContactInformationForm = ({ initialValues, onClose, companySizeList, questionsList }: any) => {
  const classes = useStyles();

  return (
    <Grid container className={`${classes.container}`}>
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>Get in touch with sales</span>
      </Grid>
      <Grid item xs={12} className={`${classes.information}`}>
        <Box display={'flex'} className={classes.paddingInfo}>
          <span>
            <Icon type={'addinformation'} />
          </span>
          <span className={`${classes.text} textInfo`}>
            We’ll work with you on your setup and help you land on the best plan
          </span>
        </Box>
      </Grid>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid item xs={12}>
              <ExpansionForm
                label={'Contact information'}
                level={'2'}
                detailContentSize={'full'}
                defaultExpanded={true}
                className={classes.expanded}
              >
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControlLabel label={'your name'}>
                        <Grid container wrap={'nowrap'}>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              placeholder={'Enter your name'}
                              defaultValue={values.name}
                              onChangeText={(value) => setFieldValue('name', value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel label={'Your work email'}>
                        <Grid container wrap={'nowrap'}>
                          <Grid item xs={12}>
                            <TextFieldCustom
                              placeholder={'Enter your work email'}
                              defaultValue={values.email}
                              onChangeText={(value) => setFieldValue('email', value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12} className={classes.selectList}>
                      <FormControlLabel label={'Company size'}>
                        <Selection
                          options={companySizeList}
                          placeholder={'Select size'}
                          classNameContainer={classes.selectList}
                          onChange={(event) => setFieldValue('company_size', event.target.value)}
                          value={values.company_size}
                        />
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12} className={classes.selectList}>
                      <FormControlLabel label={'Your question'}>
                        <Grid container wrap={'nowrap'}>
                          <Grid item xs={12}>
                            <Selection
                              options={questionsList}
                              placeholder={'Select question'}
                              classNameContainer={classes.selectList}
                              onChange={(event) => setFieldValue('questions', event.target.value)}
                              value={values.questions}
                            />
                          </Grid>
                        </Grid>
                      </FormControlLabel>
                    </Grid>
                  </Grid>
                </Box>
              </ExpansionForm>
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" alignItems="center" justifyContent="flex-end">
                <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  className={`${classes.button} cancel`}
                  onClick={onClose}
                >
                  <span className={`${classes.text} textButton`}>Cancel</span>
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  type="submit"
                  disabled={!values.name || !values.email || !values.company_size || !values.questions}
                >
                  Send
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};
export default ContactInformationForm;
