import { get, escape, snakeCase, groupBy, set, cloneDeep, orderBy, omit, uniq } from 'lodash';
import { uQuery } from '../origin-request';
import type { UqueryParams } from '../origin-request';
import produce from 'immer';
import { isFormulaField } from '../../util/excel-formula';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { getPivotColKey, getPivotValueGetter } from '@ep/insight-ui/system/helper/functions';
import { calculateValueGetter } from '../util';

function groupName(row, groupBy) {
  return groupBy.map((field) => get(row, field)).join(', ');
}

function columnKey(name) {
  return `pv__${snakeCase(escape(name))}`;
}

async function enhancedPivotTableNext(
  originData,
  { tableQuery, headers, config, contextQuery, cellUpdate, pageRequests },
  next,
) {
  const mapping = get(config, 'mapping', {});
  const columnValueDef = get(config, 'pivot.columnValue', []);
  const selectedDimension = columnValueDef[0];
  const selectedDimensionValue = mapping[selectedDimension].valueGetter.value;
  const columnMetricValueDef = get(config, 'pivot.metricColumnValue', []);
  const selectedMetric = columnMetricValueDef[0];
  const selectedMetricValue = mapping[selectedMetric].valueGetter.value;
  const ignoredAttributes = Object.values(mapping[selectedDimension].valueGetter);
  const dimensions = Object.keys(mapping).filter((k) => mapping[k].propertyType === 'dimension');

  const groupedWithoutSelected = dimensions
    .filter((el) => el != selectedDimension)
    .map((c) => mapping[c].valueGetter.value);

  const originQueryParams: UqueryParams = produce<UqueryParams>(contextQuery, (draft) => {
    draft.attributes = contextQuery.attributes.filter((el) => !ignoredAttributes.includes(el));
    draft.groupPeriod = 'all';
    draft.pagination.limit = getConst('pivotLimit', 100);
    draft._eip = { src: 'pivot' };
  });

  // const originResult = await uQuery(config.endpoint.GET_TABLE_DATA, originQueryParams as UqueryParams);
  const configFirstHalf = omit(config, ['pivot', 'tableMode']);
  configFirstHalf.primaryKeys = config.primaryKeys.filter((k) => k !== selectedDimensionValue);
  const originResult = await tableQuery(null, {
    eTableConfig: configFirstHalf,
    url: config.endpoint.GET_TABLE_DATA,
    method: 'POST',
    headers: headers,
    body: originQueryParams,
  });

  const pivotFilters = get(originResult, ['data', 'rows'], []).map((row) => {
    return {
      combinator: 'and',
      filters: groupedWithoutSelected.map((el) => ({
        dataType: 'string',
        field: el,
        operator: 'is',
        value: row[el],
      })),
    };
  });

  const pivotQueryParams: UqueryParams = produce<UqueryParams>(contextQuery, (draft) => {
    draft.groupBy = undefined;
    draft.groupPeriod = 'all';
    draft.pagination.limit = getConst('pivotLimit', 9999);
    draft._eip = { src: 'pivot' };
    const originFilter = contextQuery.filter || {
      combinator: 'and',
      filters: [],
    };
    draft.filter = {
      ...originFilter,
      filters: [
        ...originFilter.filters,
        {
          combinator: 'or',
          filters: pivotFilters,
        },
      ],
    };
    draft.metrics = columnMetricValueDef.map((c) => mapping[c].valueGetter.value);
  });

  const configSecondHalf: any = produce(config, (draft) => {
    draft.primaryKeys = uniq(config.primaryKeys.concat(selectedDimensionValue));
  });
  const nextPivotResult = await next(originResult, {
    config: configSecondHalf,
    contextQuery: pivotQueryParams,
    cellUpdate,
    pageRequests,
  });
  const columns = nextPivotResult.columns.filter((el) => el.field !== selectedDimension);
  const selectedColumn = nextPivotResult.columns.find((el) => el.field === selectedDimension);

  const calculatedRows = await calculateValueGetter({
    rows: nextPivotResult.data.rows,
    columns: [].concat(selectedColumn),
    drillDowns: [],
    groupedFields: [],
    resourceMetric: [],
    formulaUpstream: null,
  });

  const groupPivot = groupBy(calculatedRows, selectedDimensionValue);

  let pivotColumns = Object.keys(groupPivot).map((el) => {
    const originMetricLabel = get(mapping, [selectedMetric, 'valueGetter', 'label'], '');

    const columnData = get(groupPivot, [el, 0], {});
    const dimensionLabel = get(
      columnData,
      `eData.${selectedDimension}.label`,
      get(columnData, `eData.${selectedDimension}.label`),
    );

    const columnName = dimensionLabel;

    const defaultColConfig = {
      actions: [],
      aggFunc: undefined,
      cell: {
        format: 'oneMetric',
        valueGetter: {
          value: getPivotValueGetter(el),
          label: originMetricLabel.replace(/p\((\'|\")value(\'|\")\)/g, `p('${getPivotValueGetter(el)}')`),
        },
        staticValue: {},
        actions: [],
        updateHandler: null,
      },
      dataType: 'float',
      field: getPivotColKey(el),
      isGrouped: false,
      name: columnName,
      propertyType: 'metric',
      sortable: false,
      width: 200,
    };

    let colConfig = cloneDeep(columns.find((col) => col.field === selectedMetric));

    if (colConfig) {
      const pivotValueGetterField = getPivotValueGetter(el);
      set(colConfig, ['field'], getPivotColKey(el));
      set(colConfig, ['name'], columnName);
      set(colConfig, ['cell', 'valueGetter', 'value'], pivotValueGetterField);
      set(
        colConfig,
        ['cell', 'valueGetter', 'label'],
        originMetricLabel.replace(/p\((\'|\")value(\'|\")\)/g, `p('${pivotValueGetterField}')`),
      );
      set(colConfig, ['cell', 'valueGetter', `_cell_address`], `${pivotValueGetterField}_cell_address`);
    } else {
      colConfig = defaultColConfig;
    }

    return colConfig;
  });
  pivotColumns = orderBy(pivotColumns, ['name'], ['asc']);

  const columnOrder = get(config, 'columnOrder', []);

  const sortedColumns = [...columns, ...pivotColumns].sort((a, b) => {
    if (columnOrder.indexOf(a.field) === -1) return 1;
    return columnOrder.indexOf(a.field) - columnOrder.indexOf(b.field);
  });

  const finalPivotResult = get(nextPivotResult, ['data', 'rows'], []).reduce((carry, row) => {
    const key = groupedWithoutSelected.map((el) => row[el]).join('+');
    const value = row[selectedDimensionValue];

    return {
      ...carry,
      [key]: {
        ...carry[key],
        ...row,
        [getPivotValueGetter(value)]: row[selectedMetricValue],
        [`${getPivotValueGetter(value)}_cell_address`]: configSecondHalf.primaryKeys.reduce(
          (acc, el) => ({ ...acc, [el]: String(row[el]) }),
          { _column: selectedMetric },
        ),
        primaryKey: configFirstHalf.primaryKeys.reduce((acc, el) => ({ ...acc, [el]: String(row[el]) }), {}),
      },
    };
  }, {});

  const finalDataRows = get(originResult, ['data', 'rows'], []).map((row) => {
    const key = groupedWithoutSelected.map((el) => row[el]).join('+');
    const foundPivotRow = finalPivotResult[key];
    return {
      ...foundPivotRow,
      ...row,
    };
  });

  set(originResult, ['data', 'rows'], finalDataRows);

  return {
    columns: sortedColumns,
    data: originResult,
  };
}

export function pivotTableNext(tableQuery, { headers }) {
  return async (originData, { config, contextQuery, cellUpdate, pageRequests }, next) => {
    const tableMode = get(config, 'tableMode', 'default');
    if (tableMode !== 'pivot') return next(originData, { config, contextQuery, cellUpdate, pageRequests });

    const mapping = get(config, 'mapping', {});
    const columnValueDef = get(config, 'pivot.columnValue', []);
    const columnMetricValueDef = get(config, 'pivot.metricColumnValue', []);
    // const selectedColumn = get(config, ['pivot','selectedValue', columnValue.join('|')], []);

    if (columnValueDef.length === 1 && columnMetricValueDef.length === 1) {
      return enhancedPivotTableNext(
        originData,
        { tableQuery, headers, config, contextQuery, cellUpdate, pageRequests },
        next,
      );
    }

    const queryParams: UqueryParams = produce<UqueryParams>(contextQuery, (draft) => {
      draft.groupBy = { column: columnValueDef.map((c) => mapping[c].valueGetter.value) };
      draft.groupPeriod = 'all';
      draft.pagination.limit = getConst('pivotLimit', 11);
      draft._eip = { src: 'pivot' };
    });
    const result = await uQuery(config.endpoint.GET_TABLE_DATA, queryParams as UqueryParams);

    const columnValue = result.data.rows
      .map((r) =>
        groupName(
          r,
          columnValueDef.map((c) => mapping[c].valueGetter.value),
        ),
      )
      .map((c) => {
        return {
          field: columnKey(c),
          isPivotColumnValue: true,
          pivotKeys: c,
          name: c,
          dataType: 'string',
          action: [],
          cell: {
            format: 'pivotCell',
            columnValueDef,
            valueGetter: {
              pivot: 'pivot',
              key: 'key',
            },
            staticValue: {},
            action: [],
          },
        };
      });

    const { columns: nextColumns, data } = await next(originData, { config, contextQuery, cellUpdate, pageRequests });
    const finalColumns = columnValue.concat(nextColumns);

    return {
      columns: finalColumns,
      data,
    };
  };
}

export async function pivotBuild({ config, context, data }) {
  const tableMode = get(config, 'tableMode', 'default');
  const columnValueDef = get(config, 'pivot.columnValue', []);
  const columnMetricValueDef = get(config, 'pivot.metricColumnValue', []);

  if (tableMode !== 'pivot') {
    return { context, data };
  }

  if (columnValueDef.length === 1 && columnMetricValueDef.length === 1) {
    context.tableMode = 'pivotNext';
    return { context, data };
  }

  const { columns } = context;
  const {
    data: { rows: originRows },
  } = data;
  const columnRows = [];
  const columnValueFields = columnValueDef.reduce((acc, c) => {
    return acc.concat(Object.values(get(config, ['mapping', c, 'valueGetter'])).filter((v) => !isFormulaField(v)));
  }, []);

  const groupedRows = groupBy(originRows, (r) => {
    return columnKey(
      groupName(
        r.eData,
        columnValueDef.map((c) => [c, 'value']),
      ),
    );
  });

  const columnPivotValueLabel = {};

  columns
    .filter((c) => !c.isPivotColumnValue)
    .forEach((c) => {
      const pRow = { pivotLabel: { label: c.name } };
      const staticValue = get(config, ['mapping', c.field, 'staticValue'], {});
      Object.entries(groupedRows).forEach(([k, r]: [string, any]) => {
        const firstRow = r[0];
        const key = columnValueFields.reduce((acc, f) => {
          return { ...acc, [f]: get(firstRow, f) };
        }, {});
        set(pRow, [k, 'key'], key);
        set(
          pRow,
          [k, 'pivot', 'data'],
          r.map((i) => {
            return { ...i.eData[c.field], ...staticValue };
          }),
        );
        set(pRow, [k, 'pivot', 'sourceColumn'], c.field);
        set(pRow, [k, 'pivot', 'cellFormat'], c.cell.format);
        set(pRow, [k, 'pivot', 'rowData'], firstRow);
        set(
          pRow,
          [k, 'sourcePrimaryKeys'],
          get(config, 'primaryKeys', []).reduce((acc, pk) => {
            return {
              ...acc,
              [pk]: firstRow[pk],
            };
          }, {}),
        );
        set(pRow, 'sourceColumn', c.field);
        if (!columnPivotValueLabel[k]) {
          columnPivotValueLabel[k] = columnValueDef
            .map((cv) => {
              return get(firstRow.eData, [cv, 'label'], null);
            })
            .join(', ');
        }
      });
      columnRows.push({ eData: pRow });
    });

  const columnPivotLabel = {
    field: 'pivotLabel',
    pivotColumnValue: true,
    name: '',
    dataType: 'string',
    action: [],
    cell: {
      format: 'textFormatFormula',
      columnValueDef,
      valueGetter: {
        label: 'label',
      },
      staticValue: {},
      action: [],
    },
  };

  context.columns = [
    columnPivotLabel,
    ...columns
      .filter((c) => c.isPivotColumnValue)
      .map((i) => {
        return {
          ...i,
          name: columnPivotValueLabel[i.field] || '',
        };
      }),
  ];
  context.tableMode = 'pivot';
  context.originColumns = columns;
  context.pinnedColumn = (context.pinnedColumn || []).concat({ field: columnPivotLabel.field, isLeftPin: true });
  data.data.rows = columnRows;

  return { context, data: { ...data, rows: columnRows } };
}
