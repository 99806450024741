import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import AdvertiserCommunity from '@ep/insight-ui/elements/advertiser-community';

class PageMarketerCommunity implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <div>
        <AdvertiserCommunity />
      </div>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <PageMarketerSettingForm data={data} onSubmit={handleSubmit} />;
  }
}

function PageMarketerSettingForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'pageMarketerCommunity',
  label: 'Market Community',
  blockComponent: PageMarketerCommunity,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 80,
  },
};
