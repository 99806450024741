import * as React from 'react';
import { cloneDeep, get, merge, omit, set } from 'lodash';
import moment from 'moment';
import { useSetAtom } from 'jotai';

import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';
import { NodeEditContext } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { editorScript } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-script';

import { enhancedETableConfig2 } from '../../block/etable/migration';
import { getCustomCellActions } from '../../block/etable/etable-next';
import { calculateValueGetter, produceColumns } from '@ep/insight-ui/sw/etable/service';

const EXCLUDE_CONFIGURATION = ['tableParams'];

export function CampaignTableInit({ nodeData, systemConfig, campaignInfo, initialRows }: any) {
  const toolCode = get(campaignInfo, ['toolCode'], '');

  const nextSystemConfig = React.useMemo(() => {
    const config = get(nodeData, ['customAttributes', 'adsToolConfig'], []).find(({ code }) => code == 'init');
    if (!config) return null;
    const configAttributes = cloneDeep(get(config, ['config'], {}));
    const viewDefaultIndex = (configAttributes.views || []).findIndex((view) => view.id === 'default');
    const { dimension, attribute, metric, columnWidth } = Object.entries(configAttributes.mapping || {}).reduce(
      (carry, [key, col]) => {
        const propertyType = col['propertyType'];
        if (carry[propertyType]) {
          carry[propertyType].push(key);
        }
        const initColumnWidth = col.initColumnWidth;
        if (initColumnWidth) {
          let width = undefined;
          if (Number(initColumnWidth)) width = initColumnWidth;
          carry.columnWidth.push({
            width,
            columnField: key,
          });
        }
        return carry;
      },
      { dimension: [], attribute: [], metric: [], columnWidth: [] },
    );
    if (!configAttributes.dimension) {
      configAttributes.dimension = dimension;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'dimension'], dimension);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'dimension'], dimension);
    }
    if (!configAttributes.attribute) {
      configAttributes.attribute = attribute;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'attribute'], attribute);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'attribute'], attribute);
    }
    if (!configAttributes.metric) {
      configAttributes.metric = metric;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'metric'], metric);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'metric'], metric);
    }
    if (!configAttributes.columnWidth) {
      configAttributes.columnWidth = columnWidth;
    }
    return {
      customAttributes: configAttributes,
    };
  }, [nodeData, systemConfig]);

  const nextNodeData = React.useMemo(() => {
    if (!nextSystemConfig) return null;
    const marketplaceCode = get(campaignInfo, ['marketplaceIds', 0], '');
    const storefrontId = get(campaignInfo, ['storefrontIds', 0], '');
    const nodeDataAttributes = get(
      nodeData,
      ['customAttributes', 'personalization', `${marketplaceCode}:${storefrontId}:${toolCode}`],
      {},
    );

    const mergeAttributes = merge(nextSystemConfig.customAttributes, nodeDataAttributes);
    return {
      ...nodeData,
      customAttributes: mergeAttributes,
    };
  }, [nodeData, campaignInfo, nextSystemConfig]);

  if (!nextSystemConfig) return <h2>This Ad tool has not been supported yet, please contact admin to set it up.</h2>;

  return (
    <ETableBlock
      nodeData={nextNodeData}
      systemConfig={nextSystemConfig}
      campaignInfo={campaignInfo}
      initialRows={initialRows}
    />
  );
}

function ETableBlock({
  nodeData,
  systemConfig,
  campaignInfo,
  initialRows,
}: {
  nodeData: NodeData;
  systemConfig: NodeData;
  campaignInfo: any;
  initialRows: any[];
}) {
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const { onToastMultiple } = useToast();

  const lastUpdated = React.useRef(moment().valueOf());
  const refBackbone = React.useRef(null);
  const initialRowsRef = React.useRef(initialRows || []);

  const handleChangeConfig = React.useCallback(
    (config: Record<string, any>) => {
      const finConfig = omit(config, EXCLUDE_CONFIGURATION);
      EXCLUDE_CONFIGURATION.forEach((f) => {
        finConfig[f] = undefined;
      });
      window.requestAnimationFrame(() => {
        nodeEditContext.onUpdateCustomAttributes(nodeData, finConfig);
      });
    },
    [nodeData],
  );

  const customConfig = get(nodeData, 'customAttributes', {});

  const { config, linkedObjects } = enhancedETableConfig2({
    customConfig,
    systemConfig,
    nodeEditContext,
    lastUpdated,
    blockEid: nodeData.blockEid,
    setOpenCodeEditor,
    getCustomCellActions: getCustomCellActions,
    onToastMultiple,
  });

  config.addons['datasource.apiRequest.getTableData'] = async (params, originRequest, backbone) => {
    const columns = produceColumns(backbone.config);
    const enhancedRows = await calculateValueGetter({
      rows: initialRowsRef.current || [],
      columns,
      groupedFields: [],
      drillDowns: [],
      resourceMetric: [],
      formulaUpstream: null,
      config: backbone.config,
    });
    const result = {
      data: {
        summary: {},
        rows: enhancedRows,
      },
      eTableContext: {
        columns: columns,
        groupBy: {
          aggregations: {},
        },
        pinnedColumn: [],
      },
      rows: enhancedRows,
    };

    return result;
  };

  config.callback.onBackboneReady = (backbone) => {
    refBackbone.current = backbone;
  };

  React.useEffect(() => {
    initialRowsRef.current = initialRows;
    if (refBackbone.current) {
      refBackbone.current.reloadData('table');
    }
  }, [initialRows]);

  return makeTable({
    blockEid: nodeData.blockEid,
    config,
    changeConfiguration: handleChangeConfig,
    tableBackboneHook: useTableBackbone,
    linkedObjects,
  });
}
