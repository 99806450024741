import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddGift = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4.23307 3.07418C4.63206 2.6923 5.27895 2.6923 5.67794 3.07417L8.69866 5.96528C9.09765 6.34715 9.09765 6.96628 8.69866 7.34815C8.29967 7.73002 7.65278 7.73002 7.25379 7.34815L4.23307 4.45705C3.83408 4.07518 3.83408 3.45605 4.23307 3.07418Z"
      stroke={'#253746'}
      strokeWidth="2"
    />
    <path
      d="M7.30201 7.34804C6.90302 6.96617 6.90302 6.34703 7.30201 5.96516L10.3227 3.07406C10.7217 2.69219 11.3686 2.69219 11.7676 3.07406C12.1666 3.45593 12.1666 4.07506 11.7676 4.45693L8.74689 7.34804C8.3479 7.72991 7.701 7.72991 7.30201 7.34804Z"
      stroke={'#253746'}
      strokeWidth="2"
    />
    <path d="M2 6H14V8H2L2 6Z" fill="white" stroke={'#253746'} strokeWidth="2" />
    <path
      d="M3 8.16667C3 8.0827 3.02662 8.02684 3.04624 8H12.9538C12.9734 8.02684 13 8.0827 13 8.16667V12.8333C13 12.9173 12.9734 12.9732 12.9538 13H3.04624C3.02662 12.9732 3 12.9173 3 12.8333V8.16667Z"
      fill="white"
      stroke={'#253746'}
      strokeWidth="2"
    />
    <rect x="13" y="6" width="1" height="10" rx="0.5" transform="rotate(90 13 6)" fill="white" />
    <rect x="7" y="5" width="2" height="9" fill={'#253746'} />
  </SvgIcon>
);

export default AddGift;
