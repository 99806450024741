import * as React from 'react';

export const AuthContext = React.createContext<{
  canAccess: (codes: string[]) => boolean;
  canInternalAccess: () => boolean;
  workspaces?: any;
  currentWorkspaceDetail?: any;
  setCurrentWorkspace?: React.Dispatch<any>;
  loadedWorkspaces?: boolean;
}>({
  canAccess: (codes: string[]) => true,
  canInternalAccess: () => false,
  workspaces: [],
  currentWorkspaceDetail: null,
  setCurrentWorkspace: () => undefined,
  loadedWorkspaces: false,
});
