import * as React from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import AnalogTimePicker from 'react-multi-date-picker/plugins/analog_time_picker';
import moment from 'moment';
import { get } from 'lodash';
import clsx from 'clsx';

import { Box, Grid, IconButton, Popover, OutlinedInput, makeStyles, Button } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import { debounce } from 'lodash';
import { calendarStyle } from '@ep/insight-ui/elements/form-control/calendar/helper';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import ModalConfirm from '@ep/insight-ui/elements/table/dropdown-views/modal-confirm';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import ButtonAction from '@ep/insight-ui/elements/button/button-action';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';

/* Type */
type CheckboxRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
  items?: any[];
  setItems?: any;
  classes?: any;
};

const SelectBoxRender = ({ draggable, index, children, items, classes, setItems }: CheckboxRenderProps) => {
  const moveCard = (dragIndex, hoverIndex) => {
    const tmpItems = [...items];
    const dragCard = tmpItems[dragIndex];
    tmpItems.splice(dragIndex, 1);
    tmpItems.splice(hoverIndex, 0, dragCard);
    setItems(tmpItems);
  };

  if (draggable) {
    return (
      <div className={classes.box}>
        <DragCard key={index} index={index} moveCard={moveCard}>
          {children}
        </DragCard>
      </div>
    );
  }

  return <>{children}</>;
};

const scrollbarStyles = {
  '& ul': {
    width: '100% !important',
  },
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '16px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#babac038',
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: '#babac038',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: '5px solid #F8FAFD',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#a0a0a5',
    border: '4px solid #f4f4f4',
  },
  '&::-webkit-scrollbar-button ': { display: 'none' },
};

const useStyles = makeStyles(() => ({
  rangeCalendar: {
    minWidth: '272px',
    // '& .rmdp-day': {
    //   height: '32px',
    //   width: '100%',
    //   '& span': {
    //     display: 'flex',
    //     flexDirection: 'column',
    //     justifyContent: 'center',
    //   },
    // },
    // '& .rmdp-day:not(.rmdp-deactive).rmdp-today span': {
    //   color: '#fff',
    //   background: '#ED5C10',
    //   borderRadious: '50%',
    // },
    // '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
    //   color: '#000',
    //   borderRadius: '4px',
    //   backgroundColor: '#E4E7E9',
    // },
    padding: '10px 16px',
    ...(calendarStyle as CreateCSSProperties),
    '& .rmdp-day.rmdp-range': {
      backgroundColor: '#0074d9',
      boxShadow: '0 0 3px #8798ad',
      '&:not(.rmdp-deactive).rmdp-today': {
        '& span': {
          backgroundColor: 'transparent',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#338ED8',
          },
        },
      },
      '&:not(.rmdp-disabled):not(.rmdp-day-hidden)': {
        '& span:hover': {
          color: '#fff',
          backgroundColor: '#338ED8',
          borderRadius: 0,
        },
        '&.start span:hover, &.end span:hover': {
          borderRadius: '50%',
        },
      },
    },
    '& .rmdp-day': {
      ...calendarStyle['& .rmdp-day'],
      '&:not(.rmdp-deactive).rmdp-today': {
        '& span': {
          backgroundColor: '#ED5C10',
          color: '#fff',
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: '#F17D40',
          },
        },
      },
      '&.rmdp-today:not(.rmdp-range):not(.rmdp-disabled):not(.rmdp-day-hidden)': {
        '& span:hover': {
          backgroundColor: '#F17D40',
          color: '#fff',
          borderRadius: '50%',
        },
      },
    },
  },
  calendar: (props) => ({
    ...(calendarStyle as CreateCSSProperties),
    minWidth: '500px',
    padding: '8px',
  }),
  paper: {
    ...scrollbarStyles,
    paddingTop: '8px',
  },
  buttonAddBottomWrap: {
    textAlign: 'right',
    paddingRight: '16px',
    paddingBottom: '10px',
  },
  buttonAddBottom: {
    display: 'flex',
    alignItems: ' center',
    padding: '2px 8px',
    marginLeft: '-5px',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '14px',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-3px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
}));

const DailyItem = ({
  items,
  setItems,
  item,
  index,
  classes,
  disabledModelNoLimit = false,
  handleDelete,
  handleUpdate,
  currency,
  nodeData,
  dateFormat,
  type,
}: any) => {
  const itemClasses = useStyles();
  const [data, setData] = React.useState(item);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isCloneCalendar, setIsCloneCalendar] = React.useState<boolean>(null);
  const [cloneDates, setCloneDates] = React.useState([]);

  const calendarRef = React.useRef<HTMLElement>(null);
  const dropdownRef = React.useRef<HTMLElement>(null);
  const object = {
    daily_budget: 'budget',
    status: 'status',
  }[type];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);

    setTimeout(() => {
      if (calendarRef.current) {
        const timePickerDivs = calendarRef.current.querySelectorAll('.rmdp-time-picker div');

        if (timePickerDivs) {
          timePickerDivs.forEach((node, index) => {
            if (index > 0) {
              node.classList.add('disabled');
            }
          });
        }

        const countryName = get(nodeData, ['COUNTRY.country_name'], null);
        if (countryName) {
          const timePickerContainer = calendarRef.current.querySelector('.right');
          const div = document.createElement('div');
          const span = document.createElement('span');
          span.style.color = '#8C98A4';
          const text = document.createTextNode(`Your storefront time: ${countryName}`);
          span.appendChild(text);
          div.appendChild(span);
          timePickerContainer.appendChild(div);
        }
      }
    }, 0);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setTimeout(() => {
      setIsCloneCalendar(false);
    }, 500);
  };

  const handleDataChange = React.useCallback(debounce(handleUpdate, 300), [items]);

  React.useEffect(() => {
    handleDataChange(data);
  }, [data]);

  const open = Boolean(anchorEl);

  const mapDays = ({ date }: { date: DateObject }): React.HTMLAttributes<HTMLDivElement> => {
    const props: React.HTMLAttributes<HTMLDivElement> = {};
    if (date.format('YYYY-MM-DD') == moment(data.date).format('YYYY-MM-DD') && !isCloneCalendar) {
      props.className = ' selected-one end start';
    } else {
      props.className = '';
    }

    return props;
  };

  const actionMenus = [
    [
      {
        title: 'Clone this schedule',
        name: 'clone',
        icon: 'edit',
        colorStartIcon: '#253746',
        onClick: (...args) => {
          setIsCloneCalendar(true);
          setAnchorEl(dropdownRef.current);
        },
      },
      {
        title: 'Delete',
        name: 'delete',
        icon: 'delete',
        colorStartIcon: '#253746',
        onClick: () => {
          if (item.id) {
            setOpenConfirm(true);
          } else {
            handleDelete(item);
          }
        },
      },
    ],
  ];

  const handleCloneSchedule = () => {
    handleDataChange(
      data,
      cloneDates.map((date) => new DateObject(date).format('YYYY-MM-DD')),
    );
    handleClose();
  };

  return (
    <SelectBoxRender draggable={true} index={index} classes={classes} items={items} setItems={setItems}>
      <Grid
        container
        direction="row"
        alignItems="center"
        wrap="nowrap"
        justifyContent="space-between"
        style={{ display: 'flex' }}
        className={classes.formGroup}
      >
        <Grid item className={classes.groupRule}>
          <Icon type={'draggable'} style={{ width: '9px' }} />
          <Grid className={classes.groupItem}>
            <OutlinedInput
              onChange={(e) => setData({ ...data, date: e.target.value })}
              onBlur={(e) => {
                const minDate = moment().add(1, 'hour').format(dateFormat);
                const newDate = moment(e.target.value).format(dateFormat);
                setData({ ...data, date: newDate < minDate ? minDate : newDate });
              }}
              value={data.date}
              endAdornment={
                <Box component={'div'} height={'16px'} className={classes.adornment} onClick={handleClick}>
                  <Icon size="16px" type="rmx/calendar-event-fill-icon/black" />
                </Box>
              }
            />
          </Grid>
          <Grid className={classes.groupItem}>
            {type === 'daily_budget' ? (
              <TextBudgetInput
                defaultValue={data.value == '0' ? '' : data.value}
                currency={currency}
                onChangeText={(value) => setData({ ...data, value })}
                disabledDebounce
                placeholder={disabledModelNoLimit ? '0' : 'no limit'}
                noSelectBox
              />
            ) : type === 'status' ? (
              <SelectMultipleForm
                options={[
                  {
                    label: 'ONGOING',
                    value: 'ONGOING',
                  },
                  {
                    label: 'PAUSED',
                    value: 'SUSPENDED',
                  },
                ]}
                value={data.value}
                onChange={([value]) => setData({ ...data, value })}
                multipleSelect={false}
                searchAble={false}
                isConfigChart
              />
            ) : null}
          </Grid>
        </Grid>
        <div item style={{ marginLeft: '10px' }} ref={dropdownRef}>
          <Dropdown
            disabledClickContentClose={false}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            classNamePaper={`${classes.paper}`}
            sizeButton={`32px`}
            sizeIcon={'14px'}
            dataMenuPage={actionMenus}
          >
            {!Boolean(anchorEl) && <MenuList listMenu={actionMenus} />}
          </Dropdown>
        </div>
      </Grid>
      <Popover
        classes={{ paper: classes.popover }}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {isCloneCalendar ? (
          <div>
            <Calendar
              ref={calendarRef}
              range={isCloneCalendar}
              onChange={setCloneDates}
              format={'YYYY-MM-DD HH:mm:ss'}
              minDate={moment().add(1, 'hour').format(dateFormat)}
              weekDays={CALENDAR_WEEKDAYS}
              weekStartDayIndex={1}
              showOtherDays
              className={itemClasses.rangeCalendar}
              mapDays={mapDays}
            />
            <Grid item className={itemClasses.buttonAddBottomWrap}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                onClick={handleCloneSchedule}
                disabled={cloneDates.length === 0}
              >
                Apply
              </Button>
            </Grid>
          </div>
        ) : (
          <Calendar
            ref={calendarRef}
            onChange={(e) => {
              const minDate = moment().add(1, 'hour').format(dateFormat);
              const newDate = new DateObject(e).format(dateFormat);
              setData({ ...data, date: newDate < minDate ? minDate : newDate });
            }}
            value={moment(data.date).format(dateFormat)}
            format={'YYYY-MM-DD HH:mm:ss'}
            minDate={moment().add(1, 'hour').format(dateFormat)}
            plugins={[<AnalogTimePicker key={new Date().getTime().toString()} />]}
            weekDays={CALENDAR_WEEKDAYS}
            weekStartDayIndex={1}
            showOtherDays
            className={classes.calendar}
            mapDays={mapDays}
          />
        )}
      </Popover>
      <ModalConfirm
        title={`Deleting a schedule daily ${object}`}
        message={`Are you sure you want to delete this schedule daily ${object}?`}
        openModal={openConfirm}
        onClose={() => setOpenConfirm(false)}
        action={() => handleDelete(item)}
      />
    </SelectBoxRender>
  );
};

export default DailyItem;
