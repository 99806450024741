import * as React from 'react';

import { Box, makeStyles, TextField } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { ICellEditorParams, IDetailCellRendererParams } from '@ag-grid-community/core';
import { get, isString } from 'lodash';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    width: '100%',
    height: '100%',
    position: 'relative',
    '& svg': {
      cursor: 'pointer',
    },
  },
  value: {
    flex: '1',
  },
  input: {
    '& .eip1-MuiInputBase-input': {
      backgroundColor: '#fff',
      padding: '8px',
    },
    width: '100%',
  },
  icon: {
    position: 'absolute',
    top: '50%',
    right: '4px',
    transform: 'translateY(-50%)',
    zIndex: 9999,
    background: '#ffffff79',
  },
});

const InputFormat = (props: IDetailCellRendererParams) => {
  const classes = useStyles();

  const [input, setInput] = React.useState('');
  const [isEditing, setIsEditing] = React.useState(false);
  const [isHovering, setIsHovering] = React.useState(false);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isEditing) {
      setIsHovering(false);
      inputRef.current.focus();
    }
  }, [isEditing]);

  React.useEffect(() => {
    setInput(props.value);
  }, [props.value]);

  if (!isEditing) {
    return (
      <Box
        className={classes.container}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onDoubleClick={() => setIsEditing(true)}
      >
        <span className={classes.value}>{props.value}</span>
        {isHovering && (
          <Box className={classes.icon}>
            <Icon type={'edit'} onClick={() => setIsEditing(true)} />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <TextField
      value={input}
      inputRef={inputRef}
      variant="outlined"
      onBlur={() => {
        setIsEditing(false);
        props.setValue(input);
      }}
      className={classes.input}
      onChange={(e) => {
        setInput(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsEditing(false);
          props.setValue(input);
        } else if (e.key === 'Escape') {
          setIsEditing(false);
          e.stopPropagation();
        }
      }}
    />
  );
};

export default InputFormat;

export const CellEditorViewFormat = (props: IDetailCellRendererParams) => {
  const classes = useStyles();

  const [isHovering, setIsHovering] = React.useState(false);

  return (
    <Box
      className={classes.container}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onDoubleClick={() =>
        props.api.startEditingCell({
          colKey: props.colDef.field,
          rowIndex: props.rowIndex,
        })
      }
    >
      <span className={classes.value}>{props.value || props.defaultValue}</span>
      {isHovering && (
        <Box className={classes.icon}>
          <Icon
            type={'edit'}
            onClick={() => props.api.startEditingCell({ colKey: props.colDef.field, rowIndex: props.rowIndex })}
          />
        </Box>
      )}
    </Box>
  );
};
const CellEditorInputFormat = React.forwardRef((props: ICellEditorParams, ref) => {
  const classes = useStyles();

  const defaultValue = get(props, 'params.defaultValue', props.defaultValue || '');

  const [input, setInput] = React.useState(props.value && props.value != '' ? props.value : defaultValue);
  const [isEditing, setIsEditing] = React.useState(props.cellStartedEdit);
  const [isHovering, setIsHovering] = React.useState(false);

  React.useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (isString(input) && input == '') return defaultValue;
        return input;
      },
    };
  });

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isEditing) {
      setIsHovering(false);
      inputRef.current.focus();
    }
  }, [isEditing]);

  React.useEffect(() => {
    if (!props.value || (isString(props.value) && props.value == '')) {
      setInput(defaultValue);
    } else setInput(props.value);
  }, [props.value]);

  React.useEffect(() => {
    return () => {
      if (props.onBlur) {
        props.onBlur(props);
      }
    };
  }, []);

  if (!isEditing) {
    return (
      <Box
        className={classes.container}
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onDoubleClick={() => setIsEditing(true)}
      >
        <span className={classes.value}>{input}</span>
        {isHovering && (
          <Box className={classes.icon}>
            <Icon type={'edit'} onClick={() => setIsEditing(true)} />
          </Box>
        )}
      </Box>
    );
  }

  return (
    <TextField
      value={input}
      inputRef={inputRef}
      defaultValue={defaultValue}
      variant="outlined"
      onBlur={() => {
        setIsEditing(false);
        props.stopEditing();
      }}
      className={classes.input}
      onChange={(e) => {
        setInput(e.target.value);
      }}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          setIsEditing(false);
          props.stopEditing();
        } else if (e.key === 'Escape') {
          setIsEditing(false);
          props.stopEditing();
          e.stopPropagation();
        }
      }}
    />
  );
});

export { CellEditorInputFormat };
