import AdvertisingCostEditor from '@ep/insight-ui/elements/inline-edit/cell/advertising-cost';
import AgeEditor from '@ep/insight-ui/elements/inline-edit/cell/age';
import BudgetEditor from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-editor';
import BudgetMultipleEditor from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-multiple-editor';
import BudgetSelectionEditor from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-selection-editor';
import BudgetSingleEditor from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-single-editor';
import GenderEditor from '@ep/insight-ui/elements/inline-edit/cell/gender-editor';
import LandingPageEditor from '@ep/insight-ui/elements/inline-edit/cell/landing-page';
import LocationEditor from '@ep/insight-ui/elements/inline-edit/cell/location';
import PremiumRateEditor from '@ep/insight-ui/elements/inline-edit/cell/rate';
import StatusEditor from '@ep/insight-ui/elements/inline-edit/cell/status-editor';
import TaglineEditor from '@ep/insight-ui/elements/inline-edit/cell/tag-line';
import { nodeDefault } from '@ep/insight-ui/elements/inline-edit/mockup';
import AdditionFormat from '@ep/insight-ui/elements/table/format/addition-format';
import AgeFormat, { IValueAgeFormat } from '@ep/insight-ui/elements/table/format/age-format';
import AvatarTextFormat, { IAvatarTextFormat } from '@ep/insight-ui/elements/table/format/avatar-text-format';
import BudgetFormat, { IValueBudgetFormat } from '@ep/insight-ui/elements/table/format/budgets/budget-format';
import MultiBudgetFormat, {
  IValueMultipleBudgetFormat,
} from '@ep/insight-ui/elements/table/format/budgets/multi-budget-format';
import SelectionBudgetFormat from '@ep/insight-ui/elements/table/format/budgets/selection-budget';
import SingleBudgetFormat, {
  IValueSingleBudgetFormat,
  SingleBudgetImmovableFormat,
} from '@ep/insight-ui/elements/table/format/budgets/single-budget-format';
import CurrencyFormat, { IValueCurrencyFormat } from '@ep/insight-ui/elements/table/format/currency-format';
import DateRangeFormat, { IValueDateRange } from '@ep/insight-ui/elements/table/format/date-range-format';
import GenderFormat, { IValueGenderFormat } from '@ep/insight-ui/elements/table/format/gender-format';
import LineChartFormat, { IValueChartFormat } from '@ep/insight-ui/elements/table/format/line-chart-format';
import Elasticity from '@ep/insight-ui/elements/table/format/mixed-chart-format';
import NoteFormat from '@ep/insight-ui/elements/table/format/note-format';
import PositionFormat, { IPositionFormatValue } from '@ep/insight-ui/elements/table/format/position-format';
import ProgressBarFormat, { IProgressBarFormat } from '@ep/insight-ui/elements/table/format/progress-bar-format';
import SimpleGroupFormat from '@ep/insight-ui/elements/table/format/simple-group-format';
import StampFormat, { IStampFormat } from '@ep/insight-ui/elements/table/format/stamp-format';
import StatusTextFormat, { IValueStatusTextFormat } from '@ep/insight-ui/elements/table/format/status-text-format';
import TagFormat, { ITagFormat } from '@ep/insight-ui/elements/table/format/tag-format';
import TagWithInlineEditor from '@ep/insight-ui/elements/table/format/tag-with-inline-editor';
import TextFormat, { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import { getRandomInt } from '@ep/insight-ui/elements/table/mockup';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';

/** Style */
const useStyles = makeStyles(() => ({
  table: { height: '100%', margin: '10px auto', background: '#E5E5E5', padding: 10 },
  row: {
    display: 'flex',
    alignItems: ' center',
    width: '100%',
    padding: ' 20px',
    background: '#fff',
    margin: '5px 0',
  },
  timebasedCellContainer: {
    display: 'flex',
    height: '40px',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'center',
    fontSize: '12px',
    fontWeight: 400,
    padding: '0 8px',
    '& .timebase-label': {
      fontSize: 14,
      color: '#181d1f',
    },
    '& .timebase-subtext': {
      fontSize: 10,
      color: '#8C98A4',
    },
  },
}));
const CampaignBudgetCell = () => {
  const [state, setState] = React.useState<IValueMultipleBudgetFormat>({
    budgets: [
      {
        value: 200,
        currency: 'PNG',
        label: 'total',
      },
      {
        value: getRandomInt(500, 1500),
        currency: 'PHP',
        label: 'daily',
      },
    ],
  });

  const CellActions = [
    {
      title: 'Ads object budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetMultipleEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);
        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <MultiBudgetFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const KeywordCell = () => {
  const [state, setState] = React.useState<IValueStatusTextFormat>({
    label: 'ONGOING',
    status: 'ONGOING',
    // hashtag: '#hashtag',
    // subtext: 'subtext',
  });
  const CellActions = [
    {
      title: 'Status',
      name: 'Change status',
      icon: 'editStatus',
      component: StatusEditor,
      onSubmit: ({ value, row }) => {
        console.log('value StatusEditor', value);
        setState(value);
      },
    },
    {
      title: '',
      name: 'Edit name',
      icon: 'pencil',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: '',
      name: 'Export',
      icon: 'export',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];
  return <StatusTextFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const AdsObjectBudgetCell = () => {
  const [state, setState] = React.useState<IValueSingleBudgetFormat>({
    value: 200,
    currency: 'VNG',
    label: 'total',
  });

  const CellActions = [
    {
      title: 'Ads object budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetSingleEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);

        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <SingleBudgetFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const TotalBudgetCell = () => {
  const [state, setState] = React.useState<IValueSingleBudgetFormat>({
    value: 200.24,
    currency: 'PNG',
  });

  const CellActions = [
    {
      title: 'Total Budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetSingleEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);

        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <SingleBudgetImmovableFormat label={'total'} value={state} cellAction={CellActions} node={nodeDefault} />;
};
const DailyBudgetCell = () => {
  const [state, setState] = React.useState<IValueSingleBudgetFormat>({
    value: 200.1,
    currency: 'PNG',
  });

  const CellActions = [
    {
      title: 'Daily Budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetSingleEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);

        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: '',
      name: 'Change currency',
      icon: 'changeCurrency',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <SingleBudgetImmovableFormat label={'daily'} value={state} cellAction={CellActions} node={nodeDefault} />;
};

const BudgetFormatCell = () => {
  const [state, setState] = React.useState<IValueBudgetFormat>({
    currency: 'PNG',
    dailyBudget: 971,
    totalBudget: 1200,
  });

  const CellActions = [
    {
      title: 'Ads object budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);
        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <BudgetFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const SelectionBudgetFormatCell = () => {
  const [state, setState] = React.useState<IValueBudgetFormat>({
    currency: 'PNG',
    dailyBudget: 200,
    totalBudget: 0,
  });

  const CellActions = [
    {
      title: 'Ads object budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetSelectionEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);
        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <SelectionBudgetFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const AvatarTextCell = () => {
  const [value, setValue] = React.useState<IAvatarTextFormat>({
    name: 'Name',
    hashtag: '#hashtag',
    subtext: 'Subtext',
    image: '',
  });
  const CellActions = [
    {
      title: 'Creative image',
      name: 'Edit',
      icon: 'group',
      component: null,
      onSubmit: ({ value, row }) => {
        setValue(value);
        console.log('value', value);
      },
    },
  ];
  return <AvatarTextFormat value={value} cellAction={CellActions} node={nodeDefault} />;
};

const TagCell = () => {
  const [value, setValue] = React.useState<ITagFormat>({
    value: JSON.stringify([
      { label: 'Tag#1' },
      { label: 'Tag#2' },
      { label: 'Tag#3' },
      { label: 'Tag#4' },
      { label: 'Tag#5' },
    ]),
  });
  const CellActions = [
    {
      title: '',
      name: 'Edit',
      icon: 'group',
      component: null,
      onSubmit: ({ value, row }) => undefined,
    },
  ];
  return <TagFormat value={value} cellAction={CellActions} node={nodeDefault} />;
};

const StampCell = () => {
  const [value, setValue] = React.useState<IStampFormat>({ stamp: 1 });
  const CellActions = [
    {
      title: '',
      name: 'Edit',
      icon: 'group',
      component: null,
      onSubmit: ({ value, row }) => undefined,
    },
  ];
  return <StampFormat value={value} cellAction={CellActions} node={nodeDefault} />;
};

const ProgressBarCell = () => {
  const [value, setValue] = React.useState<IProgressBarFormat>({ value: 60 });
  const CellActions = [
    {
      title: '',
      name: 'Edit',
      icon: 'group',
      component: null,
      onSubmit: ({ value, row }) => undefined,
    },
  ];
  return <ProgressBarFormat value={value} cellAction={CellActions} node={nodeDefault} />;
};

const TextCell = ({ expand = false }: { expand?: boolean }) => {
  const [state, setState] = React.useState<IValueTextFormat>({
    label: 'Text',
    value: 'option-value',
    hashtag: '#hashtag',
    subtext: 'Subtext',
    status: 'paused',
    subTabs: expand ? [{ label: '', value: '' }] : [],
  });
  const CellActions = [
    {
      title: 'Match type',
      name: 'Edit Match Type',
      icon: 'group',
      component: null,
      onSubmit: ({ value, row }) => {
        console.log('value', value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: 'Age',
      name: 'Edit age',
      icon: '',
      component: AgeEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
    {
      title: 'Location',
      name: 'Edit location',
      icon: '',
      component: LocationEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
    {
      title: 'Creative landing page',
      name: 'Edit landing page',
      icon: '',
      component: LandingPageEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
    {
      title: 'Creative tagline',
      name: 'Edit tagline',
      icon: '',
      component: TaglineEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
  ];
  return <TextFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const NumberCell = ({ value }: { value: IValueCurrencyFormat }) => {
  const [state, setState] = React.useState<IValueCurrencyFormat>(value);

  const CellActions = [
    {
      title: 'Edit Bidding price',
      name: 'Edit RTB',
      icon: 'group',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: 'Campaign advertising cost',
      name: 'Toko - Product ads',
      icon: '',
      component: AdvertisingCostEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
    {
      title: 'Campaign advertising cost',
      name: 'Toko - Shop ads',
      icon: '',
      component: AdvertisingCostEditor,
      customProps: {
        limit: 1000,
      },
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
    {
      title: 'Premium rate',
      name: 'Premium Rate Editor',
      icon: '',
      component: PremiumRateEditor,
      onSubmit: (info) => {
        console.log('PremiumRateEditor info', info);
        setState(info.value);
      },
    },
  ];
  return <CurrencyFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const AdditionCell = () => {
  const [state, setState] = React.useState<any>({});

  const CellActions = [
    {
      title: 'Edit Bidding price',
      name: 'Edit RTB',
      icon: 'group',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <AdditionFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const LineChartCell = () => {
  const [state, setState] = React.useState<IValueChartFormat>({
    data: [
      2, 6, 9, 10, 100, 25, 29, 30, 10, 20, 60, 30, 20, 10, 100, 2, 6, 9, 10, 100, 25, 29, 30, 10, 20, 60, 30, 20, 10,
      1002, 6, 9, 10, 100, 25, 29, 30, 10, 20, 60, 30, 20, 10, 100,
    ],
  });

  const CellActions = [
    {
      title: 'Edit Bidding price',
      name: 'Edit RTB',
      icon: 'group',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];
  return <LineChartFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const SimpleBudgetFormatCell = () => {
  const [state, setState] = React.useState<IValueSingleBudgetFormat>({
    value: 200,
    currency: 'PNG',
    label: 'total',
  });

  const CellActions = [
    {
      title: 'Ads object budget',
      name: 'Edit Budget',
      icon: 'pencil',
      component: BudgetSingleEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);

        setState(value);
      },
    },
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <SingleBudgetFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};
const TimelineCell = () => {
  const [state, setState] = React.useState<IValueDateRange>({
    date_from: '01/07/2021',
    date_to: '30/12/9999',
    defaultFormatReturn: 'DD/MM/YYYY',
    formatDateView: 'MMMM, YYYY',
  });

  const CellActions = [
    {
      title: '',
      name: 'Set script',
      icon: 'setscript',
      component: null,
      onSubmit: (info) => {
        console.log('info', info);
      },
    },
  ];

  return <DateRangeFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const AgeCellFormat = () => {
  const [state, setState] = React.useState<IValueAgeFormat>({
    value: JSON.stringify([]),
    hashtag: '#hashtag',
    subtext: 'Subtext',
    status: 'paused',
  });
  const CellActions = [
    {
      title: 'Age',
      name: 'Edit age',
      icon: '',
      component: AgeEditor,
      onSubmit: (info) => {
        console.log('info', info);
        setState(info.value);
      },
    },
  ];
  return <AgeFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};
//
const GenderFormatCell = () => {
  const [state, setState] = React.useState<IValueGenderFormat>({
    value: null,
    hashtag: '#hashtag',
    subtext: 'Subtext',
    status: 'paused',
  });
  const CellActions = [
    {
      title: 'Gender',
      name: 'Change gender',
      icon: '',
      iconSize: '14px',
      component: GenderEditor,
      onSubmit: ({ value, row }) => {
        console.log('value', value);
        setState(value);
      },
    },
  ];
  return <GenderFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const PositionCellFormat = ({
  value = 0,
  displayNumber = true,
  updateDay = '',
}: {
  value: number;
  displayNumber?: boolean;
  updateDay?: string;
}) => {
  const [state, setState] = React.useState<IPositionFormatValue>({
    position: value,
    updateDay,
    displayNumber,
  });
  const CellActions = [];
  return <PositionFormat value={state} cellAction={CellActions} node={nodeDefault} />;
};

const statusDataDemo = [
  {
    label: 'ONGOING',
    status: 'ONGOING',
  },
  {
    label: 'PAUSED',
    status: 'PAUSED',
  },
  {
    label: 'SUSPENDED',
    status: 'SUSPENDED',
  },
  {
    label: 'RECOVERING',
    status: 'RECOVERING',
  },
];

const StatusCellFormat = () => {
  const classes = useStyles();

  const statusDataDemoRender = statusDataDemo.map((data, index) => {
    return (
      <CellStyle key={index}>
        <StatusTextFormat value={data} cellAction={[]} node={null} />
      </CellStyle>
    );
  });

  return (
    <Grid container direction={`column`} alignItems="center" wrap={'nowrap'} className={classes.statusContainer}>
      <CellStyle>
        <StatusTextFormat value={statusDataDemo[1]} cellAction={[]} node={null} />
      </CellStyle>
    </Grid>
  );
};

const useStylesCell = makeStyles({
  container: {
    background: '#fff',
    height: '52px',
    width: '100%',
  },
});
const CellStyle = ({ children }: { children: JSX.Element }) => {
  const classes = useStylesCell();
  return <div className={classes.container}>{children}</div>;
};

const TextMatchCell = () => {
  const val = {
    label: 'EXACT_MATCH',
    value: 'option-value',
    hashtag: '#hashtag',
    subtext: 'Subtext',
    status: 'paused',
    subTabs: '',
  };
  return <TextFormat value={val} />;
};

const SimpleGroupCell = () => {
  return <SimpleGroupFormat value={{ label: 'Text' }} />;
};

const ElasticityCell = () => {
  return <Elasticity />;
};

const TimeBasedCell = () => {
  const classes = useStyles();
  return (
    <div className={classes.timebasedCellContainer}>
      <span className="timebase-label">7.20x</span>
      <span className="timebase-subtext">ROAS</span>
    </div>
  );
};

const CountryFormatCell = () => {
  const data = {
    label: 'Vietnam',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const MarketplaceFormatCell = () => {
  const data = {
    label: 'Shopee',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const StorefrontFormatCell = () => {
  const data = {
    label: 'XYZ Shop',
    subtext: '3921',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const AdToolFormatCell = () => {
  const data = {
    label: 'Product search ads',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const CampaignFormatCell = () => {
  const data = {
    label: 'S_Search_Care_Epsilo',
    subtext: 'CAMPL6050C838EE63F',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const AdObjectFormatCell = () => {
  const data = {
    label: 'Mysterious Purple Pin Love',
    subtext: '7840525149',
    subtext2: '3008755',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const ScriptFormatCell = () => {
  const data = {
    label: 'Script t1',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const StaticKeywordFormatCell = () => {
  const data = {
    label: 'lego friends',
    id: 1,
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const CamapaignTagFormatCell = () => {
  const data = {
    value: 'super_brand_day',
    id: 'super_brand_day',
  };
  const column = {
    colDef: {
      field: 'campaign_tag',
    },
  };
  return (
    <CellStyle>
      <TagWithInlineEditor value={data} column={column} />
    </CellStyle>
  );
};

const AdObjectTagFormatCell = () => {
  const data = {
    value: 'priority',
    id: 'priority',
  };
  const column = {
    colDef: {
      field: 'ad_object_tag',
    },
  };
  return (
    <CellStyle>
      <TagWithInlineEditor value={data} column={column} />
    </CellStyle>
  );
};

const KeywordTagFormatCell = () => {
  const data = {
    value: 'category',
    id: 'category',
  };
  const column = {
    colDef: {
      field: 'keyword_tag',
    },
  };
  return (
    <CellStyle>
      <TagWithInlineEditor value={data} column={column} />
    </CellStyle>
  );
};

const CampaignStatusFormatCell = () => {
  const data = {
    label: 'ONGOING',
    status: 'ONGOING',
  };
  return (
    <CellStyle>
      <StatusTextFormat value={data} />
    </CellStyle>
  );
};

const CampaignTimelineFormatCell = () => {
  const data = {
    date_from: '21/04/2022',
    date_to: '27/05/2022',
    defaultFormatReturn: 'DD/MM/YYYY',
    formatDateView: 'MMMM DD, YYYY',
  };
  return (
    <CellStyle>
      <DateRangeFormat value={data} />
    </CellStyle>
  );
};

const CampaignDailyBudgetFormatCell = () => {
  const data = {
    value: 100000,
    currency: 'VND',
  };
  return (
    <CellStyle>
      <SingleBudgetImmovableFormat label={'daily'} value={data} />
    </CellStyle>
  );
};

const CampaignTotalBudgetFormatCell = () => {
  const data = {
    value: 120000,
    currency: 'VND',
  };
  return (
    <CellStyle>
      <SingleBudgetImmovableFormat label={'total'} value={data} />
    </CellStyle>
  );
};

const AdObjectStatusFormatCell = () => {
  const data = {
    label: 'PAUSED',
    status: 'PAUSED',
  };
  return (
    <CellStyle>
      <StatusTextFormat value={data} />
    </CellStyle>
  );
};

const AdObjectBudgetFormatCell = () => {
  const data = {
    currency: 'VND',
    dailyBudget: 0,
    totalBudget: 100000,
  };
  return (
    <CellStyle>
      <SelectionBudgetFormat value={data} />
    </CellStyle>
  );
};

const AdObjectTimelineFormatCell = () => {
  const data = {
    date_from: '21/04/2022',
    date_to: '27/05/2022',
    defaultFormatReturn: 'DD/MM/YYYY',
    formatDateView: 'MMMM DD, YYYY',
  };
  return (
    <CellStyle>
      <DateRangeFormat value={data} />
    </CellStyle>
  );
};

const AdObjectBiddingPriceFormatCell = () => {
  const data = {
    value: 10000,
    currency: 'VND',
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} />
    </CellStyle>
  );
};

const ScriptStatusFormatCell = () => {
  const data = {
    label: 'SUSPENDED',
    status: 'SUSPENDED',
  };
  return (
    <CellStyle>
      <StatusTextFormat value={data} />
    </CellStyle>
  );
};

const KeywordStatusFormatCell = () => {
  const data = {
    label: 'DRAFT',
    status: 'DRAFT',
  };
  return (
    <CellStyle>
      <StatusTextFormat value={data} />
    </CellStyle>
  );
};

const KeywordBiddingPriceFormatCell = () => {
  const data = {
    value: 5000,
    currency: 'VND',
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} />
    </CellStyle>
  );
};

const KeywordMatchTypeFormatCell = () => {
  const data = {
    value: 'EXACT_MATCH',
    label: 'Exact match',
  };
  return (
    <CellStyle>
      <TextFormat value={data} />
    </CellStyle>
  );
};

const CampaignNoteFormatCell = () => {
  const data = {
    value: 'test1 sprint',
    label: 'test1 sprint',
  };
  return (
    <CellStyle>
      <NoteFormat value={data} />
    </CellStyle>
  );
};

const AdObjectNoteFormatCell = () => {
  const data = {
    value: 'test12 sprint',
    label: 'test12 sprint',
  };
  return (
    <CellStyle>
      <NoteFormat value={data} />
    </CellStyle>
  );
};

const AdNoteFormatCell = () => {
  const data = {
    value: 'test123 sprint',
    label: 'test123 sprint',
  };
  return (
    <CellStyle>
      <NoteFormat value={data} />
    </CellStyle>
  );
};

const MetricFloatFormatCell = () => {
  const data = {
    value: 0,
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} dataType={'float'} />
    </CellStyle>
  );
};

const MetricFloatWithCurrencyFormatCell = () => {
  const data = {
    value: 0,
    currency: 'VND',
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} dataType={'float'} />
    </CellStyle>
  );
};

const MetricIntegerFormatCell = () => {
  const data = {
    value: 0,
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} />
    </CellStyle>
  );
};

const KeywordPositionFormatCell = () => {
  const data = {
    value: 0,
    subtext: '2022-05-23 10:46:36',
  };
  return (
    <CellStyle>
      <CurrencyFormat value={data} />
    </CellStyle>
  );
};

export default {
  text: TextCell,
  textMatchType: TextMatchCell,
  status: StatusCellFormat,
  budget: BudgetFormatCell,
  selectionBudget: SelectionBudgetFormatCell,
  currency: NumberCell,
  timeline: TimelineCell,
  position: PositionCellFormat,
  userInfo: AvatarTextCell,
  tag: TagCell,
  stamp: StampCell,
  progressBar: ProgressBarCell,
  add: AdditionCell,
  lineChart: LineChartCell,
  simpleGroup: SimpleGroupCell,
  singleBudget: SimpleBudgetFormatCell,
  totalBudget: TotalBudgetCell,
  dailyBudget: DailyBudgetCell,
  // compact: CompactCellFormat,
  elasticity: ElasticityCell,
  timeBasedCell: TimeBasedCell,
  // lineEDashboardChart: LineEDashboardChart,
  // keywordFormat: KeywordFormat,
  age: AgeCellFormat,
  gender: GenderFormatCell,
  // keywordMethod: KeywordMethodFormat,
  // enableDisableFormat: EnableDisableFormat,
  // audienceFormat: AudienceFormat,
  // actionNeededFormat: ActionNeededFormat,
  // connectionStatusFormat: ConnectionStatusFormat,
  countryFormat: CountryFormatCell,
  marketplaceFormat: MarketplaceFormatCell,
  storefrontFormat: StorefrontFormatCell,
  adToolFormat: AdToolFormatCell,
  campaignFormat: CampaignFormatCell,
  adObjectFormat: AdObjectFormatCell,
  scriptFormat: ScriptFormatCell,
  staticKeywordFormat: StaticKeywordFormatCell,
  campaignTagFormat: CamapaignTagFormatCell,
  adObjectTagFormat: AdObjectTagFormatCell,
  keywordTagFormat: KeywordTagFormatCell,
  campaignStatusFormat: CampaignStatusFormatCell,
  campaignTimelineFormat: CampaignTimelineFormatCell,
  campaignDailyBudgetFormat: CampaignDailyBudgetFormatCell,
  campaignTotalBudgetFormat: CampaignTotalBudgetFormatCell,
  adObjectStatusFormat: AdObjectStatusFormatCell,
  adObjectBudgetFormat: AdObjectBudgetFormatCell,
  adObjectTimelineFormat: AdObjectTimelineFormatCell,
  adObjectBiddingPriceFormat: AdObjectBiddingPriceFormatCell,
  scriptStatusFormat: ScriptStatusFormatCell,
  keywordStatusFormat: KeywordStatusFormatCell,
  keywordBiddingPriceFormat: KeywordBiddingPriceFormatCell,
  keywordMatchTypeFormat: KeywordMatchTypeFormatCell,
  campaignNoteFormat: CampaignNoteFormatCell,
  adObjectNoteFormat: AdObjectNoteFormatCell,
  adNoteFormat: AdNoteFormatCell,
  impressionFormat: MetricFloatFormatCell,
  clickFormat: MetricIntegerFormatCell,
  costFormat: MetricFloatWithCurrencyFormatCell,
  adsItemSoldFormat: MetricIntegerFormatCell,
  directItemSoldFormat: MetricIntegerFormatCell,
  adsGmvFormat: MetricFloatWithCurrencyFormatCell,
  directGmvFormat: MetricFloatWithCurrencyFormatCell,
  adsOrderFormat: MetricIntegerFormatCell,
  directAdsOrderFormat: MetricIntegerFormatCell,
  roasFormat: MetricFloatFormatCell,
  cirFormat: MetricFloatFormatCell,
  cpcFormat: MetricFloatWithCurrencyFormatCell,
  ctrFormat: MetricFloatFormatCell,
  crFormat: MetricFloatFormatCell,
  stockFormat: MetricIntegerFormatCell,
  discountFormat: MetricFloatFormatCell,
  conditionFormat: MetricIntegerFormatCell,
  keywordPositionFormat: KeywordPositionFormatCell,
  directRoasFormat: MetricFloatFormatCell,
  cpiFormat: MetricFloatFormatCell,
  directCrFormat: MetricFloatFormatCell,
  directCpiFormat: MetricFloatFormatCell,
  directCirFormat: MetricFloatFormatCell,
};
