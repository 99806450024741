import { get } from 'lodash';

import { aim } from '@eip/next/lib/main';
import { cellFormat } from '@ep/insight-ui/elements/etable2/table-helper';
import InlineEdit from '@ep/insight-ui/system/block/etable/etable-config/action-components/inline-edit';
import {
  ACTION_CATEGORY_COMPACT_TABLE_VIEW,
  ACTION_CATEGORY_CONDITON_FORMAT,
  ACTION_CATEGORY_COPY_PASTE,
  ACTION_CATEGORY_ETABLE_FORM,
  ACTION_CATEGORY_FILTER,
  ACTION_CATEGORY_IMPACT_TABLE,
  ACTION_CATEGORY_INLINE_FORM,
  ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
  ACTION_CATEGORY_PERIOD,
  ACTION_CATEGORY_SCRIPT_ACTION,
  ACTION_CATEGORY_SEPARATOR,
  ACTION_CATEGORY_STATUS,
  ACTION_CATEGORY_TAG,
} from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from '@ep/insight-ui/system/block/etable/cell-format-options';
import PeriodUpdate from '@ep/insight-ui/system/block/etable/etable-config/action-components/period-update';
import CompactTable from '@ep/insight-ui/system/block/etable/etable-config/action-components/compact-table';
import ConditionFormat from '@ep/insight-ui/system/block/etable/etable-config/action-components/condition-format';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { eipRequest as request } from '@eip/next/lib/main';
import CompactTableForm from '../block/etable/etable-config/action-components/compact-table-form';

const nextCellFormat = {
  ...CELL_FORMAT_OPTIONS_WITH_COMPONENT,
  ...cellFormat,
  ...['qualityMetric', 'oneMetric', 'operationMetric'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.currencyFormatFormula };
  }, {}),
  ...['oneDimension'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.textFormatFormula };
  }, {}),
};

export const getCustomCellActions = (
  { actions = [], backbone, field = '', isPageAction = false, additionalData = {} },
  dotMenu = [],
  onToastMultiple,
) => {
  const userProfile = aim.getUserSettings().profile || { workspace_role_id: '', userEmail: '' };
  const { userEmail, workspace_role_id } = userProfile;

  const callbackScript = (script) => {
    return (...vars) => {
      const providedUserProfile = {
        ...userProfile,
        token: String(userProfile?.userEmail).endsWith('@epsilo.io') ? aim.getLoginToken().token : '',
      };
      try {
        const variables = [
          ['bb', backbone],
          ['notify', onToastMultiple],
          ['request', request],
          ['user', providedUserProfile],
          ...vars,
        ];
        const v1 = variables.map((v) => v[0]);
        const v2 = variables.map((v) => v[1]);

        if (
          !script.includes('user.token') ||
          confirm(
            getConst(
              'CONFIRM_TOKEN_SCRIPT_MESSAGE',
              'This script will use your token to do something, are you sure to continue executing this script?',
            ),
          )
        ) {
          return Function(...v1, script)(...v2);
        }
      } catch (e) {
        onToastMultiple({
          title: 'Error',
          messages: [e.message],
          variant: 'error',
        });
      }

      return;
    };
  };
  return actions
    .filter((el) => {
      const isStatusCategory = el.category === ACTION_CATEGORY_STATUS;
      const isCopyPasteCategory = el.category === ACTION_CATEGORY_COPY_PASTE;

      const role = el.role;

      const isRoleMatched =
        aim.isSuperAdmin() ||
        !workspace_role_id ||
        (role ? [].concat(role) : [1, 2, 3]).includes(Number(workspace_role_id));

      // TODO: Update more logics to show the action here
      const permission = el.permission ? toValue(el.permission, { ...additionalData?.data }) : 'allow';
      const isVisible =
        permission === 'admin' ? aim.isSuperAdmin() : permission == (additionalData?.isOne ? 'one' : 'allow');

      return !isStatusCategory && !isCopyPasteCategory && isVisible && isRoleMatched;
    })
    .filter((el) => {
      if (el.category !== ACTION_CATEGORY_SCRIPT_ACTION) return true;
      const script = get(el, ['configuration', 0, 'value'], '');
      return !script.includes('user.token') || String(userEmail).endsWith('@epsilo.io');
    })
    .map((el) => {
      const callback = callbackScript(get(el, ['callback'], ''));

      let disabled =
        typeof el.disable == 'boolean' ? el.disable : toValue(String(el.disable), { ...additionalData?.data }) == '1';

      const demoUser = /demo.*@epsilo.io$/i.test(userEmail);

      if (
        ![
          ACTION_CATEGORY_FILTER,
          ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
          ACTION_CATEGORY_PERIOD,
          ACTION_CATEGORY_COMPACT_TABLE_VIEW,
          ACTION_CATEGORY_COPY_PASTE,
          ACTION_CATEGORY_SEPARATOR,
        ].includes(el.category) &&
        demoUser
      ) {
        disabled = true;
      }

      const useInlineEdit = [ACTION_CATEGORY_TAG, ACTION_CATEGORY_INLINE_FORM];
      if (useInlineEdit.includes(el.category) || !el.category) {
        return {
          component: InlineEdit,
          payload: (el.configuration || []).map((el) => ({
            ...el,
            cellFormat,
          })),
          customProps: {
            actionName: el.name,
            tracking: el.tracking,
            category: el.category,
            ...additionalData,
            getCustomCellActions,
            callback,
          },
          name: el.name,
          icon: el.icon,
          disable: disabled,
          shortcut: el.shortcut,
          field,
          isPageAction,
        };
      }

      const action = {
        onSubmit({ row }) {
          if (el.category === ACTION_CATEGORY_FILTER) {
            backbone.updateTempStorage('filterModal', { field, value: '' });
            backbone.changeVisibility('filter', true);
          }
          if (el.category === ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS) {
            const link = ((el.configuration || []).find((el) => el.key === 'url') || { value: '' }).value;

            const isInternalLink = /^(http(s?)):\/\/[^.]*.?epsilo.io/.test(link);

            const url = link.replace(/\:\w+/g, (c) => {
              const { value } = (el.configuration || []).find((el) => el.key === c.slice(1)) || { value: '' };
              return row[value];
            });
            isInternalLink
              ? window.open(url, '_blank')
              : window.open(`/redirect-external?url=${encodeURIComponent(url)}`, '_blank');
          }
          if (!disabled) {
            callback();
          }
          return;
        },
        payload: transformPayload(el, isPageAction),
        name: el.name,
        icon: el.icon,
        disable: disabled,
        shortcut: el.shortcut,
        id: el.category === ACTION_CATEGORY_IMPACT_TABLE ? 'history' : undefined,
        field,
        isPageAction,
      };

      if (el.category === ACTION_CATEGORY_PERIOD) {
        action.component = PeriodUpdate;
      }
      if (el.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
        action.type = 'compact';
        action.customProps = { getCustomCellActions, callback };
        action.component = CompactTable;
      }
      if (el.category === ACTION_CATEGORY_ETABLE_FORM) {
        action.type = 'compact';
        action.customProps = { getCustomCellActions, callback };
        action.component = CompactTableForm;
      }
      if (el.category === ACTION_CATEGORY_CONDITON_FORMAT) {
        action.component = ConditionFormat;
      }
      if (el.category === ACTION_CATEGORY_SEPARATOR) {
        action.customProps = {
          category: el.category,
        };
      }

      return action;
    })
    .concat(dotMenu);
};

const transformPayload = (config, isPageAction) => {
  if (config.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
    return get(config, ['configuration'], []).reduce(
      (a, b) => {
        return {
          ...a,
          [b.key]: b.value,
        };
      },
      { cellFormat: nextCellFormat, isPageAction },
    );
  }
  if (config.category !== ACTION_CATEGORY_IMPACT_TABLE) return config.configuration;
  return {
    field: config.configuration.reduce(
      (a, b) => {
        return {
          ...a,
          [b.key]: b.value,
        };
      },
      { isPageAction },
    ),
  };
};
