import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import ItemTypes from './ItemTypes';
import { ConnectDragSource, ConnectDropTarget } from 'react-dnd';

/* Interface */
interface PropRef {
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  children: React.ReactNode;
}
interface CardProps {
  id: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => number;
}

// eslint-disable-next-line react/display-name
const Card = React.forwardRef(({ isDragging, connectDragSource, connectDropTarget, children }: PropRef, ref) => {
  const elementRef = React.useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0 : 1;
  React.useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  return (
    <div ref={elementRef} styles={opacity}>
      {children}
    </div>
  );
});
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props: CardProps, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        if (hoverIndex - dragIndex > 1) {
          const timeout = props.moveCard(dragIndex, hoverIndex - 1);
          if (!timeout) {
            monitor.getItem().index = hoverIndex - 1;
          }
        }
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        if (dragIndex - hoverIndex > 1) {
          const timeout = props.moveCard(dragIndex, hoverIndex + 1);
          if (!timeout) {
            monitor.getItem().index = hoverIndex + 1;
          }
        }
        return;
      }
      const timeout = props.moveCard(dragIndex, hoverIndex);
      if (!timeout) {
        monitor.getItem().index = hoverIndex;
      }
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props: CardProps) => ({
        id: props.id,
        index: props.index,
      }),
      ...(ff.next_left_menu
        ? {
            endDrag: (props) => {
              if (props.onDrop) props.onDrop();
            },
          }
        : {}),
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }),
  )(Card),
);
