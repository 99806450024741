import { getUserSettings } from '../../aim';
import { ONE_EP } from '../../endpoint';
import * as request from './fetch';

export function oneGetShops(payload: {
  params: any;
  permIdIncludes?: string[];
}) {
  let { params, permIdIncludes } = payload;
  let settings = getUserSettings();
  let shopPerms = settings?.permissions?.byShopId!;

  console.info({ shopPerms });

  return request.get(ONE_EP.GET_SHOPS(), params).then((rs) => {
    let data = rs.data.filter((i: any) => {
      const shopId = i.shop_eid as string;
      const features = i.features as any[];
      const perms = shopPerms[shopId];

      return features.some((i) => {
        let fcode = i.feature_code;
        return (
          perms[fcode] &&
          perms[fcode].length > 0 &&
          (permIdIncludes || []).every(
            (id) => perms[fcode].indexOf(id) > -1,
          )
        );
      });
    });

    console.info({ filteredData: data });

    return { ...rs, data };
  });
}
