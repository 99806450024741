import * as React from 'react';

export const useItemAction = ({ actions, data }) => {
  const ref = React.useRef(null);
  const [actionSelecting, setActionSelecting] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const Component = React.useMemo(() => {
    if (actionSelecting) {
      return actionSelecting.component;
    }
    return null;
  }, [actionSelecting]);

  const propsComponent = React.useMemo(() => {
    if (actionSelecting) {
      return {
        ...actionSelecting,
        data: {
          node: {
            data,
          },
        },
        component: undefined,
        anchorEl,
        setAnchorEl,
      };
    }
    return {};
  }, [actionSelecting]);

  const handleClose = () => {
    setAnchorEl(null);
    setActionSelecting(null);
  };

  const mappedActions = actions.map((action) => ({
    title: action.name,
    icon: action.icon,
    customProps: action.customProps,
    onClick() {
      if (action.component) {
        setAnchorEl(ref.current);
        setActionSelecting(action);
      } else {
        action.onSubmit({ row: {} });
      }
    },
    disable: action.disable,
  }));

  return { ref, anchorEl, handleClose, mappedActions, Component, propsComponent };
};
