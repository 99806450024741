import { useLog } from '@eip/next/lib/log';
import { getBuiltinEntities } from '@eip/next/src/util/data-request';
import { API_EIP_MANAGER_URL } from '@ep/one/global';
import { actionAsync, actionNative } from '@ep/one/src/utils/redux';
import * as jsonpatch from 'fast-json-patch';
import { produce, current } from 'immer';
import { Drafted } from 'immer/dist/internal';
// import { usePresetServer } from '../preset-server';
import { assign, cloneDeep, get, keyBy, merge, omit, pick } from 'lodash';
import { channel } from 'redux-saga';
import * as eff from 'redux-saga/effects';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import type { TypePatches } from './page-changeset';
import { buildNodeAsBlockDiff, buildPageBlockChangeset, buildPageChangeset } from './page-changeset';
import { usePageServer } from './server-system-page';
import { useUserPageServer } from './server-user-page';

import { spawn, Thread, Worker } from 'threads';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const pageServer = usePageServer(API_EIP_MANAGER_URL);
const userPageServer = useUserPageServer(API_EIP_MANAGER_URL);
const log = useLog('page:page-reducer');

log('import.meta.url', import.meta.url);

let _cachedWorker;
async function startWorker() {
  if (_cachedWorker) {
    return _cachedWorker;
  }

  const worker = new Worker(
    /* webpackChunkName: "changeset-worker" */ new URL('./worker/changeset.worker.ts', import.meta.url),
  );
  const changeset = await spawn(worker);
  _cachedWorker = changeset;

  return changeset;
}

export const channelPersist = { in: channel(), out: channel() };

type PersistRepoRequest = {
  request: ({ type, payload }: { type: string; payload: any }) => Promise<any>;
};
class PersistRepo {
  repo: PersistRepoRequest = {
    request: async ({ type, payload }) => {
      return null;
    },
  };

  registerRepo(repo: PersistRepoRequest) {
    this.repo = repo;
  }

  request({ type, payload }: { type; payload }) {
    return this.repo.request({ type, payload });
  }
}

export const persistRepo = new PersistRepo();
export const uQueryRegex = new RegExp(`uQuery\\(([\\'\\"]).*?\\1\\s*\\,\\s*([\\'\\"]).*?\\2\\s*\\)`, 'g');

type immuPageUpdater = (draft: Drafted<Dashboard>) => void;
const actions = {
  getList: actionAsync({
    actname: 'GET_LIST',
    fun: function* getList() {
      const ls = yield eff.call(() => {
        return pageServer.getList();
      });

      return ls;
    },
  }),
  getUserList: actionAsync({
    actname: 'GET_USER_LIST',
    fun: function* getUserList() {
      const ls = yield eff.call(() => {
        return pageServer.getUserList();
      });

      return ls;
    },
  }),
  getMenuList: actionAsync({
    actname: 'GET_USER_MENU',
    fun: function* getUserMenu({ pageIds }: { pageIds: string[] }) {
      const records = yield eff.call(() => {
        return userPageServer.syncMenuByParents(pageIds);
      });

      return records;
    },
  }),
  updatePersonalize: actionAsync({
    actname: 'UPDATE_PAGE_PERSONALIZE',
    fun: function* updatePage({
      page,
      callback,
    }: {
      page: Dashboard | immuPageUpdater;
      callback: (any) => void;
      updateExtend?: ({ originPageUpdate, changeset, previousPage }) => Promise<any>;
    }) {
      const changesetWorker: any = yield eff.call(async () => {
        const w = await startWorker();
        return w;
      });

      const diff0: {
        currentPage;
        nextPage;
        nextPageOrigin;
        currentPageBlockViews;
      } = yield eff.select((state: PageReduxStateType) => {
        const currentPageOrigin = state.currentPageBlock;
        let currentPage: Partial<Dashboard> = currentPageOrigin || ({} as Dashboard);
        currentPage = pick(currentPage, ['nodes', 'id', 'blockEid', 'blockType', 'table', 'content', 'properties']);

        currentPage = produce(currentPage, (draft) => {
          draft.nodes.forEach((n, index) => {
            draft.nodes[index] = omit(currentPage.nodes[index], ['properties']) as NodeData;
            draft.nodes[index].customAttributes = pick(n.customAttributes, EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS);
          });
        });

        const nextPageOrigin: Dashboard = typeof page === 'function' ? produce(currentPageOrigin, page) : page;
        let nextPage: Partial<Dashboard> = pick(nextPageOrigin, [
          'nodes',
          'id',
          'blockEid',
          'blockType',
          'table',
          'content',
          'properties',
        ]);

        nextPage = produce(nextPage, (draft) => {
          draft.nodes.forEach((n, index) => {
            draft.nodes[index] = omit(nextPage.nodes[index], ['properties']) as NodeData;
            draft.nodes[index].customAttributes = pick(n.customAttributes, EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS);
          });
        });

        log('currentPage vs nextPage', currentPage, nextPage);
        const currentPageBlockViews = state.currentPageBlockViews;

        return { currentPage, nextPage, nextPageOrigin, currentPageBlockViews };
      });

      log('diff0...', diff0);

      const diff: { currentPage; patches; nodeDiff; nextPage } = yield eff.call(async () => {
        let pageDiff, nodeDiff;
        try {
          const result = await changesetWorker.diff(diff0.currentPage, diff0.nextPage);
          pageDiff = result.patches;
          nodeDiff = result.nodeDiff;
        } catch (error) {
          console.error('error processing diff...');
        }

        return {
          currentPage: diff0.currentPage,
          nextPage: diff0.nextPage,
          patches: pageDiff,
          nodeDiff,
        };
      });

      log('current page vs. page', diff);
      log('patches', diff.patches);

      const nextPage = diff.nextPage;
      if (
        diff.patches.length == 0 &&
        Object.keys(diff.nodeDiff.addNew).length === 0 &&
        Object.keys(diff.nodeDiff.updated).length === 0 &&
        Object.keys(diff.nodeDiff.deleted).length === 0
      ) {
        yield eff.cancel();
        callback({ page: nextPage, diff: null });
        return { page: nextPage, updated: false };
      } else {
        let newViews = {
          isUpdated: false,
          value: null,
        };
        const changeset = yield eff.call(async () => {
          let changeset = [];
          try {
            changeset = await changesetWorker.buildPageChangeset(nextPage, diff.patches);
            const blockChangeset = await changesetWorker.buildPageBlockChangeset(nextPage, diff.nodeDiff);
            changeset = changeset.concat(blockChangeset);
            newViews = await changesetWorker.buildPageViewsChange(nextPage, diff0.currentPageBlockViews);
          } catch (error) {
            changeset = [];
            console.error(error);
          }

          return changeset;
        });

        log('server post changeset', changeset);

        if (changeset.length > 0) {
          yield eff.call(() => persistRepo.request({ type: 'update', payload: { changeset } }));
        } else {
          yield eff.cancel();
          return { page: diff0.nextPageOrigin, updated: false };
        }
        callback({ page: diff0.nextPageOrigin, diff, changeset });
        return { page: diff0.nextPageOrigin, updated: true, newViews };
      }
    },
  }),
  update: actionAsync({
    actname: 'UPDATE_PAGE',
    fun: function* updatePage({
      page,
      callback,
    }: {
      page: Dashboard | immuPageUpdater;
      callback: (any) => void;
      updateExtend?: ({ originPageUpdate, changeset, previousPage }) => Promise<any>;
    }) {
      const diff: {
        currentPage;
        patches: TypePatches[];
        nodeDiff: {
          updated: { [key: string]: TypePatches[] };
          addNew: { [key: string]: NodeData };
          deleted: { [key: string]: any };
        };
        nextPage;
        nextPageTitle;
        nextPageSubtext;
        nextPageHashtag;
      } = yield eff.select((state: PageReduxStateType) => {
        const currentPage: Dashboard = state.currentPageBlock || ({} as Dashboard);
        const nextPage: Dashboard = typeof page === 'function' ? produce(currentPage, page) : page;
        const pageDiff = jsonpatch.compare(omit(currentPage, 'nodes'), omit(nextPage, 'nodes'));
        const nodeDiff = buildNodeAsBlockDiff(keyBy(currentPage.nodes, 'id'), keyBy(nextPage.nodes, 'id'));
        let nextPageTitle = '';
        if (state.nextPageTitle && state.nextPageTitle != state.pageTitle) {
          nextPageTitle = state.nextPageTitle;
        }
        let nextPageSubtext;
        if (state.nextPageSubtext && state.nextPageSubtext != state.pageSubtext) {
          nextPageSubtext = state.nextPageSubtext;
        }
        let nextPageHashtag;
        if (state.nextPageHashtag && state.nextPageHashtag != state.pageHashtag) {
          nextPageHashtag = state.nextPageHashtag;
        }

        return { currentPage, patches: pageDiff, nodeDiff, nextPage, nextPageTitle, nextPageHashtag, nextPageSubtext };
      });
      log('current page vs. page', diff);
      log('patches', diff.patches);

      const nextPage = diff.nextPage;
      if (
        diff.patches.length == 0 &&
        Object.keys(diff.nodeDiff.addNew).length === 0 &&
        Object.keys(diff.nodeDiff.updated).length === 0 &&
        Object.keys(diff.nodeDiff.deleted).length === 0 &&
        !diff.nextPageTitle &&
        diff.nextPageHashtag != undefined &&
        diff.nextPageSubtext != undefined
      ) {
        callback({ page: nextPage, diff: null });
        yield eff.cancel();
        return { page: nextPage, updated: false };
      } else {
        const nextVersion = Date.now();
        diff.patches.push({
          op: 'replace',
          path: '/properties/version',
          value: nextVersion,
        });
        const nextPageWithVersion = produce<Dashboard>(nextPage, (draft) => {
          draft.properties.version = nextVersion;
        });
        let changeset = buildPageChangeset(nextPageWithVersion, diff.patches);
        const blockChangeset = buildPageBlockChangeset(nextPageWithVersion, diff.nodeDiff);
        changeset = changeset.concat(blockChangeset);

        log('server post changeset', changeset);
        let resultUpdate = {
          isSent: false,
          variant: '',
          message: '',
        };
        if (changeset.length > 0) {
          try {
            const { payload: rs } = yield eff.call(() =>
              persistRepo.request({ type: 'update', payload: { changeset, pageId: nextPage.blockEid } }),
            );
          } catch (e) {
            resultUpdate = {
              isSent: true,
              variant: 'error',
              message: e.error?.message || e.message,
            };
          }
        } else {
          yield eff.cancel();
          return { page: nextPageWithVersion, updated: false };
        }
        callback({ page: nextPageWithVersion, diff, changeset, resultUpdate });
        return { page: nextPageWithVersion, updated: true };
      }
    },
  }),

  updateTitle: actionNative({
    actname: 'UPDATE_PAGE_TITLE',
    fun: function ({ title }) {
      return { title };
    },
  }),

  updateHashtag: actionNative({
    actname: 'UPDATE_PAGE_HASHTAG',
    fun: function ({ hashtag }) {
      return { hashtag };
    },
  }),

  updateSubtext: actionNative({
    actname: 'UPDATE_PAGE_SUBTEXT',
    fun: function ({ subtext }) {
      return { subtext };
    },
  }),

  updateEditorTitle: actionNative({
    actname: 'UPDATE_PAGE_EDITOR_TITLE',
    fun: function ({ title }) {
      return { title };
    },
  }),

  createPage: actionAsync({
    actname: 'CREATE_SINGLE_PAGE',
    fun: function* createSinglePage() {
      return;
    },
  }),

  addStaticPage: actionNative({
    actname: 'ADD_SINGLE_PAGE',
    fun: function addStaticPage({ pageId, pageContent }: { pageId: string; pageContent?: any }) {
      return { pageId, pageContent };
    },
  }),

  loadSinglePage: actionAsync({
    actname: 'LOAD_SINGLE_PAGE',
    fun: function* getSinglePage({
      pageId,
      context,
      callback,
    }: {
      pageId: string;
      context?: any;
      callback?: ({ pageId, result }) => void;
    }) {
      log('action loadsinglepage', pageId, callback);

      const { payload: chunks } = yield eff.call(() =>
        persistRepo.request({ type: 'getSinglePage', payload: { pageId: pageId, context } }),
      );

      const focusPage = chunks[pageId];

      log('action loadsinglepage - result', focusPage);
      const result = chunks;

      return { pageId, result };
    },
  }),

  loadPageBlockViews: actionNative({
    actname: 'LOAD_PAGE_BLOCK_VIEWS',
    fun: function getPageBlockViews({ result, pageId }: { result: { payload: any }; pageId: string }) {
      return { pageId, result };
    },
  }),

  loadFormulaTitle: actionAsync({
    actname: 'LOAD_FORMULA_TITLE',
    fun: function* getFormulaTitle({
      title,
      pageHashtag,
      pageSubtext,
      callback,
    }: {
      title: string;
      pageHashtag: string;
      pageSubtext: string;
      callback?: ({ pageId, result }) => void;
    }) {
      return { title, pageHashtag, pageSubtext };
    },
  }),

  loadSingleSystemPage: actionAsync({
    actname: 'LOAD_SINGLE_SYSTEM_PAGE',
    fun: function* getSinglePage({ pageId, context }: { pageId: string; context?: any }) {
      log('action loadsinglepage', pageId);

      const { payload: chunks } = yield eff.call(() =>
        persistRepo.request({ type: 'getSinglePage', payload: { pageId: pageId, context, isEditMode: true } }),
      );

      const focusPage = chunks[pageId];

      log('action loadsinglesystempage - result', focusPage);
      const result = chunks;

      return { pageId, result };
    },
  }),

  loadBuiltinEntities: actionAsync({
    actname: 'LOAD_BUILTIN_ENTITIES',
    fun: function* loadBuiltinEntities() {
      const rs = yield eff.call(() => {
        return getBuiltinEntities();
      });
    },
  }),

  setCurrentPage: actionNative({
    actname: 'SET_ACTIVE',
    fun: function (pageId) {
      return { pageId };
    },
  }),

  loadPageNavigation: actionAsync({
    actname: 'LOAD_PAGE_NAVIGATION',
    fun: function* loadPageNavigation({ pageId }) {
      const {
        payload: { blocks },
      } = yield eff.call(() => persistRepo.request({ type: 'getPageNavigation', payload: { pageId: pageId } }));

      return blocks;
    },
  }),
  updatePageNavigation: actionAsync({
    actname: 'UPDATE_PAGE_NAVGATION',
    fun: function* updatePageNavigation({ pageId, parentId, nextParentId, listBeforeId, listAfterId }) {
      console.info('update page navigation', pageId);
      return { pageId, parentId, nextParentId, listBeforeId, listAfterId };
    },
  }),
  togglePageTitleEditable: actionNative({
    actname: 'TOGGLE_PAGE_EDITABLE',
    fun: ({ editable }) => ({ editable }),
  }),
  updatePageSocket: actionNative({
    actname: 'UPDATE_PAGE_SOCKET',
    fun: function (updatedEditingPage) {
      return updatedEditingPage;
    },
  }),
  refreshCaches: actionAsync({
    actname: 'REFRESH_CACHES',
    fun: function* refreshCaches({ pageId }) {
      yield eff.delay(1000);

      yield eff.call(() => persistRepo.request({ type: 'refreshCaches', payload: { pageId: pageId } }));
    },
  }),
};

export { actions };

export function* rootSaga() {
  yield eff.all([
    actions.getList.saga(),
    actions.update.saga(),
    actions.updatePersonalize.saga(),
    actions.loadSinglePage.saga(),
    actions.loadSingleSystemPage.saga(),
    actions.loadFormulaTitle.saga(),
    actions.getMenuList.saga(),
    actions.loadPageNavigation.saga(),
    actions.updatePageNavigation.saga(),
    actions.refreshCaches.saga(),
    actions.getUserList.saga(),
  ]);
}

class PageNotFoundError extends Error {}

export type PageReduxStateType = {
  isInit: boolean;
  pageTitle: string;
  systemPageList: Record<Dashboard['blockEid'], Dashboard>;
  currentPageId: Dashboard['blockEid'];
  currentPageBlock: Dashboard;
  isCurrentPageLoaded: boolean;
  currentDirectory: Dashboard['blockEid'][];
  navigation: Record<
    Dashboard['blockEid'],
    {
      blocks: Record<Dashboard['blockEid'], Dashboard>;
      ordinal: Dashboard[];
      loading: boolean;
    }
  >;
  loading: {
    [key: string]: {
      status: boolean;
      error: any;
    };
  };
  titleEditable: boolean;
  pageDescription?: string;
  nextPageTitle?: string;
  pageSocket?: {
    view?: {
      members: {
        [key: string]: {
          email: string;
          createdAt: number;
          name: string;
        };
      };
    };
    edit?: {
      members: {
        [key: string]: {
          email: string;
          createdAt: number;
          name: string;
        };
      };
    };
    lastUpdated?: {
      at: number;
      by: string;
      id: string;
    };
  };
  formulaTitle?: string;
  pageHashtag?: string;
  formulaHashtag?: string;
  pageSubtext?: string;
  formulaSubtext?: string;
  nextPageHashtag?: string;
  nextPageSubtext?: string;
  currentPageBlockViews: {
    [key: string]: {
      name: string;
      id: string;
    }[];
  };
  userList?: {
    [key: string]: {
      createdAt: string;
      status: string;
    };
  };
};

export function reducer(
  state: PageReduxStateType = {
    pageTitle: '',
    isInit: false,
    systemPageList: {},
    currentPageId: null,
    currentPageBlock: null,
    isCurrentPageLoaded: false,
    navigation: {},
    loading: {},
    currentDirectory: [],
    titleEditable: true,
    pageDescription: '',
    nextPageTitle: '',
    pageSocket: {
      view: {},
      edit: {},
      lastUpdated: {},
    },
    formulaTitle: '',
    pageHashtag: '',
    formulaHashtag: '',
    pageSubtext: '',
    formulaSubtext: '',
    nextPageHashtag: '',
    nextPageSubtext: '',
    currentPageBlockViews: {},
    userList: {},
  },
  action: { type: string; payload: any },
): PageReduxStateType {
  switch (action.type) {
    case 'REQUEST_ACTION_LOADING': {
      state = produce(state, (draft) => {
        draft.loading[action.payload.loading.section] = {
          status: action.payload.loading.status,
          error: action.payload.loading.error,
        };
      });
      break;
    }
    case actions.setCurrentPage.type():
      state = produce(state, (draft) => {
        draft.currentPageId = action.payload.pageId;
      });
      break;
    case actions.updatePersonalize.fetchType():
    case actions.update.fetchType():
      const { page: pageBlock, updated, newViews } = action.payload;
      if (updated) {
        state = produce(state, (draft) => {
          const updatedBlock = pageBlock;
          if (pageBlock.blockEid === state.currentPageBlock.blockEid) {
            draft.currentPageBlock = updatedBlock;
          }
          if (state.nextPageTitle && state.nextPageTitle != state.pageTitle) {
            draft.pageTitle = state.nextPageTitle;
            draft.pageHashtag = state.pageHashtag;
            draft.pageSubtext = state.pageSubtext;
          }
        });
      }
      if (newViews && newViews?.isUpdated) {
        state = produce(state, (draft) => {
          draft.currentPageBlockViews = newViews.value;
        });
      }
      break;
    case actions.getList.fetchType():
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.isInit = true;
        draft.systemPageList = { ...state.systemPageList, ...payload };
      });
      break;
    case actions.getUserList.fetchType(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.userList = payload;
      });
      break;
    }
    case actions.getMenuList.fetchType(): {
      const { payload } = action;
      break;
    }
    case actions.updateTitle.type(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.pageTitle = payload.title;
        draft.currentPageBlock.properties.title = payload.title;
      });
      break;
    }
    case actions.updateHashtag.type(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.nextPageHashtag = payload.hashtag;
        draft.currentPageBlock.properties.pageHashtag = payload.hashtag;
      });
      break;
    }
    case actions.updateSubtext.type(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.nextPageSubtext = payload.subtext;
        draft.currentPageBlock.properties.pageSubtext = payload.subtext;
      });
      break;
    }
    case actions.updateEditorTitle.type(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.nextPageTitle = payload.title;
        draft.currentPageBlock.properties.title = payload.title;
      });
      break;
    }
    case actions.loadSinglePage.type(): {
      state = produce(state, (draft) => {
        draft.pageTitle = '';
        draft.pageHashtag = '';
        draft.pageSubtext = '';
      });
      break;
    }
    case actions.loadSinglePage.fetchType(): {
      const { pageId, result: payload } = action.payload;

      state = produce(state, (draft) => {
        const currentPageBlock: Dashboard = payload[pageId];
        // if (!currentPageBlock) {
        //   draft.currentPageId = pageId;
        //   draft.currentPageBlock = new PageNotFoundError('page not found');
        //   return;
        // }
        const personaBlocks: [string, Dashboard][] = Object.entries<Block>(payload)
          .filter(([k, p]: [string, Block]) => {
            return p.blockType === EIP_CONSTANT.BLOCK_TYPE.userPersona;
          })
          .map((entry) => {
            const p = entry[1] as Dashboard;
            const systemPage: Dashboard = cloneDeep(payload[p.parentId]);
            p.nodes = cloneDeep(systemPage.nodes);
            p.layouts = systemPage.layouts;
            p.updatedBy = systemPage.updatedBy;
            p.layoutColumns = systemPage.layoutColumns;
            p.properties = assign({}, systemPage.properties, p.properties);
            p.properties.systemVersion = get(systemPage, 'properties.version', null);
            p.properties.draftData = {};
            p.title = get(systemPage, 'properties.title');
            p.description = get(systemPage, 'properties.description');
            p.properties.title = get(systemPage, 'properties.title');
            if (p.properties.workflowId === null || p.properties.workflowId === undefined) {
              draft.pageTitle = p.title;
              draft.pageDescription = p.description;
              draft.pageHashtag = get(systemPage, 'properties.pageHashtag');
              draft.pageSubtext = get(systemPage, 'properties.pageSubtext');
            }
            p.parentId = systemPage.parentId;
            p.parentTable = systemPage.parentTable;

            let pVersion, sVersion;
            if (ff.lowcode) {
              pVersion = get(p, 'properties.version', 0);
              sVersion = get(systemPage, 'properties.version', 0);
            }

            const blockPersona = get(p, 'properties.blockPersona', {});

            Object.keys(blockPersona).forEach((customNodeId) => {
              const propPersona = blockPersona[customNodeId];
              const blockIndex = systemPage.nodes.findIndex((i) => i.blockEid === customNodeId);
              if (blockIndex === -1) return;
              if (pVersion >= sVersion) {
                p.nodes[blockIndex].customAttributes = assign(
                  {},
                  systemPage.nodes[blockIndex].customAttributes,
                  propPersona.customAttributes,
                );
                p.nodes[blockIndex].mainAttributes = assign(
                  {},
                  systemPage.nodes[blockIndex].mainAttributes,
                  propPersona.mainAttributes,
                );
                p.nodes[blockIndex]._system = cloneDeep(systemPage.nodes[blockIndex].properties);
              } else {
                const excludedUserCustomization = get(
                  systemPage.nodes[blockIndex].customAttributes,
                  'excludedUserCustomization',
                  [],
                );
                p.nodes[blockIndex].customAttributes = assign(
                  {},
                  systemPage.nodes[blockIndex].customAttributes,
                  pick(
                    propPersona.customAttributes,
                    EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS.filter((i) => !excludedUserCustomization.includes(i)),
                  ),
                );
                p.nodes[blockIndex]._system = cloneDeep(systemPage.nodes[blockIndex].properties);
              }
              draft.currentPageBlockViews[pageId] = {
                ...draft.currentPageBlockViews[pageId],
                [customNodeId]: get(propPersona, ['customAttributes', 'views'], [])
                  .filter((v) => !v.isHidden)
                  .map((v) => ({
                    id: v.id,
                    name: v.name,
                    isLocked: v.isLock,
                  })),
              };
            });

            return [entry[0], p];
          });

        if (currentPageBlock.blockType === 'page') {
          const personaBlock = personaBlocks.find(([k, p]) => p.content[0] === currentPageBlock.blockEid);
          draft.currentPageBlock = personaBlock[1];
          draft.currentPageId = personaBlock[0];
        } else if (currentPageBlock.blockType === 'user_system_link') {
          const personaBlock = personaBlocks.find(([k, p]) => p.content[0] === currentPageBlock.content[0]);
          draft.currentPageBlock = { ...personaBlock[1], ...currentPageBlock };
          draft.currentPageId = currentPageBlock.blockEid;
        } else {
          draft.currentPageBlock = currentPageBlock;
          draft.currentPageId = currentPageBlock.blockEid;
        }

        draft.currentPageBlock.lastUpdated = Date.now();
      });
      break;
    }
    case actions.loadPageBlockViews.type(): {
      const { pageId, result } = action.payload;

      state = produce(state, (draft) => {
        draft.currentPageBlockViews[pageId] = result.payload;
      });
      break;
    }
    case actions.loadSingleSystemPage.fetchType(): {
      const { pageId, result: payload } = action.payload;
      state = produce(state, (draft) => {
        const currentPageBlock: Dashboard = payload[pageId];
        draft.currentPageBlock = currentPageBlock;
        draft.pageTitle = currentPageBlock.properties.title;
        draft.pageHashtag = currentPageBlock.properties.pageHashtag;
        draft.pageSubtext = currentPageBlock.properties.pageSubtext;
        draft.pageDescription = currentPageBlock.properties.description;
        draft.currentPageBlock.lastUpdated = Date.now();
        draft.currentPageId = currentPageBlock.blockEid;
      });
      break;
    }
    case actions.loadFormulaTitle.fetchType(): {
      const { title, pageHashtag, pageSubtext } = action.payload;

      state = produce(state, (draft) => {
        draft.formulaTitle = title;
        draft.formulaHashtag = pageHashtag;
        draft.formulaSubtext = pageSubtext;
      });
      break;
    }
    case actions.loadPageNavigation.type(): {
      const { pageId } = action.payload[0];
      state = produce(state, (draft) => {
        if (state.navigation[pageId]) {
          draft.navigation[pageId].loading = true;
        } else {
          draft.navigation[pageId] = { blocks: {}, ordinal: [], loading: true };
        }
      });
      break;
    }
    case actions.loadPageNavigation.fetchType(): {
      const blocks = action.payload;
      state = produce(state, (draft) => {
        for (const [id, blockMenu] of Object.entries<any>(blocks)) {
          draft.navigation[id] = { blocks: blockMenu.blocks, ordinal: blockMenu.navigation, loading: false };
        }
      });
      break;
    }
    case actions.updatePageNavigation.type(): {
      console.info(action.payload);
      const { pageId, parentId, nextParentId, listAfterId, listBeforeId } = action.payload[0];
      const parent = state.navigation[parentId];
      const pageBlock = parent.blocks[pageId];
      state = produce(state, (draft) => {
        // remove page from current parent
        delete draft.navigation[parentId].blocks[pageId];
        const orderIndex = state.navigation[parentId].ordinal.findIndex((i) => i === pageId);
        delete draft.navigation[parentId].ordinal[orderIndex];
      });
      state = produce(state, (draft) => {
        // add page to new parent
        const nextParent = state.navigation[nextParentId];
        const draftParent = draft.navigation[nextParentId];
        draftParent.blocks[pageId] = pageBlock;
        if (listAfterId !== null) {
          const posIndex = nextParent.ordinal.findIndex((i) => i === listAfterId);
          draftParent.ordinal.splice(posIndex + 1, 0, pageId);
        } else if (listBeforeId !== null) {
          const posIndex = nextParent.ordinal.findIndex((i) => i === listBeforeId);
          draftParent.ordinal.splice(Math.max(posIndex - 1, 0), 0, pageId);
        }
      });
      break;
    }
    case actions.togglePageTitleEditable.type(): {
      const { payload } = action;
      state = produce(state, (draft) => {
        draft.titleEditable = payload.editable;
      });
      break;
    }
    case actions.addStaticPage.type(): {
      const { pageId, pageContent } = action.payload;
      state = produce(state, (draft) => {
        const page = {
          layouts: { md: [] },
          parentId: '__static__',
          content: null,
          nodes: null,
          blockEid: pageId,
          presetEid: pageId,
          blockType: 'page',
          properties: {
            title: pageContent.title,
          },
        };
      });
      break;
    }
    case actions.updatePageSocket.type(): {
      state = produce(state, (draft) => {
        Object.keys(action.payload).forEach((key) => {
          draft.pageSocket[key] = action.payload[key];
        });
      });
      break;
    }
  }

  state = produce(state, (draft) => {
    if (state.currentPageBlock) {
      const currentPageBlock = state.currentPageBlock;
      draft.currentDirectory = [currentPageBlock.parentId, currentPageBlock.blockEid];
    }
  });

  return state;
}
