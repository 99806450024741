import * as React from 'react';
import { cloneDeep, get, isEmpty, set, uniq } from 'lodash';
import moment from 'moment';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { TableCompactFactoryContext } from '@ep/insight-ui/elements/etable2/compact-factory';
import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { getDateRangeFromOption } from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';
import { NodeEditContext } from '@eip/next/lib/main';
import { eipRequest } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

import {
  INPUT_TYPE_COMPACT_SOURCE_FIELD,
  INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER,
  INPUT_TYPE_COMPACT_TARGET_FIELD,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_FILTER_FIELD,
  INPUT_TYPE_SYSTEM_FILTERS,
  INPUT_TYPE_VIEW,
  INPUT_TYPE_MULTIPLE_SELECT,
  SYSTEM_FIELD_DEFINED,
  INPUT_TYPE_HIDDEN_FIELD,
  INIT_VALUE_TYPE_FROM_ETABLE,
  INIT_VALUE_TYPE_STATIC,
  INPUT_TYPE_COMPACT_SPLIT_DATA,
  INIT_VALUE_TYPE_FROM_COMPACT_ETABLE,
  INPUT_TYPE_FORMAT_INPUT,
  INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
  INPUT_TYPE_COMPACT_OPERATOR,
  CALENDAR_COMPONENT_NAME,
  INPUT_TYPE_SUBMIT_METHOD,
  INPUT_TYPE_MAX_ITEM_SELECTED,
  INPUT_TYPE_SEARCH_HINT,
  INPUT_TYPE_ADDITIONAL_DATA,
  INPUT_TYPE_LINK_GROUPBY_FILTER,
  INPUT_TYPE_CUSTOM_FILTERS,
} from '../../utils/constant';
import { enhancedETableConfig2 } from '../../../migration';
import { useSetAtom } from 'jotai';
import { editorScript } from '../../atom/editor-script';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { splitComma } from '@ep/insight-ui/system/helper/functions';
import { calculateValueGetter } from '@ep/insight-ui/sw/etable/service';

const CompactTableForm = (props) => {
  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const backboneContext = React.useContext(TableBackboneContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const lastUpdated = React.useRef(moment().valueOf());
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const callbackRef = React.useRef<any[]>([]);
  const rowsTemp = React.useRef([]);

  const tracking = get(props, ['customProps', 'tracking'], '');
  const callback = get(props, ['customProps', 'callback'], () => undefined);

  const gridApi = backboneContext.getGridApi ? backboneContext.getGridApi() : null;
  const groupBy = backboneContext.getConfig('groupBy');

  const { onToastMultiple } = useToast();

  const onRowSelectedModal = (rows: { node: RowNode; isSelected: boolean; isPresent: boolean }[], backbone) => {
    if (backbone.getGridApi) {
      const selectedRows = backbone.getGridApi()?.getSelectedRows();
      rowsTemp.current = selectedRows;
    }
  };

  const handleReloadTable = () => {
    if (gridApi) {
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.length > 0) {
        const expanded = [];
        gridApi.forEachNode((node) => {
          if (node.expanded) {
            expanded.push(node);
          }
        });
        expanded.forEach((el) => {
          gridApi.refreshServerSideStore({ route: [el.key] });
        });
      } else {
        gridApi.refreshServerSideStore();
      }
      gridApi.deselectAll();
    }
  };

  const handleClosed = () => {
    props.setAnchorEl(null);
  };

  const convertValue = (field, value, rowData = {}) => {
    const dataType = get(field, ['data_type'], '');
    const inputType = get(field, ['input_type'], '');

    if (!dataType || inputType == CALENDAR_COMPONENT_NAME) return value;

    if (dataType == 'int') {
      return parseInt(value) || 0;
    }
    if (dataType == 'float') {
      return parseFloat(value) || 0;
    }
    if (dataType == 'boolean') {
      let parsedValue = value;
      try {
        parsedValue = JSON.parse(value);
      } catch {}
      return Boolean(parsedValue);
    }
    if (dataType == 'string') {
      return String(value);
    }
    if (dataType == 'date') {
      return moment(value).format('YYYY-MM-DD');
    }
    if (dataType == 'datetime') {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
    if (dataType == 'json') {
      return safeJsonParse(value, value);
    }
    if (dataType == 'encode') {
      return JSON.stringify(value);
    }
    if (String(dataType).startsWith('=')) {
      return toValue(dataType, { ...rowData, value }, true);
    }

    return value;
  };

  React.useEffect(() => {
    if (props.anchorEl) {
      let payloadConfig = get(props, ['payload'], []).find(
        (pl) => pl.field_key == 'etableConfig' && pl.input_type == SYSTEM_FIELD_DEFINED,
      );
      let formConfig = get(props, ['payload'], []).find(
        (pl) => pl.field_key == 'form' && pl.input_type == SYSTEM_FIELD_DEFINED,
      );

      if (!payloadConfig || !formConfig) return;
      payloadConfig = payloadConfig.field_configuration.reduce((carry, item) => {
        return {
          ...carry,
          [item.key]: item.value,
        };
      }, {});
      formConfig = formConfig.field_configuration.reduce((carry, item) => {
        return {
          ...carry,
          [item.key]: item.value,
        };
      }, {});

      const rowData = get(props, ['data', 'node', 'data'], {});

      let maxItemSelected = payloadConfig[INPUT_TYPE_MAX_ITEM_SELECTED];

      if (maxItemSelected) {
        maxItemSelected = toValue(maxItemSelected, rowData, true);
      }
      if (!Number(maxItemSelected)) {
        maxItemSelected = null;
      }
      const payloadTransform = get(formConfig, 'payload_transform', '');

      const handleSubmit = async ({ onClose }) => {
        const endpoint = get(formConfig, ['submit_endpoint'], '');
        const method = get(formConfig, [INPUT_TYPE_SUBMIT_METHOD], 'post');

        let isSuccess = false;
        if (endpoint) {
          const result = {};
          const isSplitData = get(payloadConfig, [INPUT_TYPE_COMPACT_SPLIT_DATA], false);
          const basedTableFields = get(props, ['payload'], []).filter(
            (pl) => pl.input_type === INPUT_TYPE_HIDDEN_FIELD && pl.init_value_type === INIT_VALUE_TYPE_FROM_ETABLE,
          );
          const selectedCompactRows = rowsTemp.current;
          let bulkRows = [];
          if (backboneContext.getGridApi) {
            bulkRows = uniq(backboneContext.getGridApi().getSelectedRows().concat(rowData));
          }

          const payloadFromBasedTable = basedTableFields.reduce((payload, input) => {
            const basedRowKey = input.init_value_value;
            let payloadValue = get(rowData, basedRowKey, null);
            if (String(basedRowKey).startsWith('=')) {
              payloadValue = toValue(basedRowKey, rowData, true);
            }
            const payloadKey = input.field_key;

            if (bulkRows.length === 1) {
              if (isSplitData) {
                selectedCompactRows.forEach((_, rIndex) => {
                  set(
                    payload,
                    (payloadKey.endsWith('$') ? payloadKey.slice(0, -1) : payloadKey).replace('.0.', `.${rIndex}.`),
                    convertValue(input, payloadValue, rowData),
                  );
                });
              } else {
                set(
                  payload,
                  payloadKey.endsWith('$') ? payloadKey.slice(0, -1) : payloadKey,
                  convertValue(input, payloadValue, rowData),
                );
              }
            } else if (bulkRows.length > 1) {
              // example key: data.0.ad_object_id etc...
              if (/[^\.]+\.0\.[^\.]+/.test(payloadKey)) {
                bulkRows.forEach((row, index) => {
                  let payloadValue = get(row, basedRowKey, null);
                  if (String(basedRowKey).startsWith('=')) {
                    payloadValue = toValue(basedRowKey, row, true);
                  }
                  if (isSplitData) {
                    selectedCompactRows.forEach((_, rIndex) => {
                      set(
                        payload,
                        payloadKey.replace('.0.', `.${index * selectedCompactRows.length + rIndex}.`),
                        convertValue(input, payloadValue, rowData),
                      );
                    });
                  } else {
                    set(payload, payloadKey.replace('.0.', `.${index}.`), convertValue(input, payloadValue, rowData));
                  }
                });
              } else if (payloadKey.endsWith('$')) {
                // Prevent transform this field to array
                set(payload, payloadKey.slice(0, -1), convertValue(input, payloadValue, rowData));
              } else {
                set(
                  payload,
                  payloadKey,
                  uniq(
                    bulkRows.map((row) => {
                      let payloadValue = get(row, basedRowKey, null);
                      if (String(basedRowKey).startsWith('=')) {
                        payloadValue = toValue(basedRowKey, row, true);
                      }
                      return convertValue(input, payloadValue, rowData);
                    }),
                  ),
                );
              }
            } else if (payloadValue && payloadKey) {
              set(payload, payloadKey, convertValue(input, payloadValue, rowData));
            }

            return payload;
          }, cloneDeep(result));

          let apiPayload = payloadFromBasedTable;

          const staticFields = get(props, ['payload'], []).filter(
            (pl) => pl.input_type === INPUT_TYPE_HIDDEN_FIELD && pl.init_value_type === INIT_VALUE_TYPE_STATIC,
          );

          staticFields.forEach((el) => {
            bulkRows.forEach((row, index) => {
              if (isSplitData) {
                selectedCompactRows.forEach((_, rIndex) => {
                  set(
                    apiPayload,
                    el.field_key.replace('.0.', `.${index * selectedCompactRows.length + rIndex}.`),
                    convertValue(el, el.init_value_value, rowData),
                  );
                });
              } else {
                set(
                  apiPayload,
                  el.field_key.replace('.0.', `.${index}.`),
                  convertValue(el, el.init_value_value, rowData),
                );
              }
            });
          });

          const compactFields = get(props, ['payload'], []).filter(
            (pl) =>
              pl.input_type === INPUT_TYPE_HIDDEN_FIELD && pl.init_value_type === INIT_VALUE_TYPE_FROM_COMPACT_ETABLE,
          );

          compactFields.forEach((el) => {
            bulkRows.forEach((_, index) => {
              if (isSplitData) {
                selectedCompactRows.forEach((row, rIndex) => {
                  const initValue = el.init_value_value;
                  let value = get(row, [el.init_value_value]);
                  if (String(initValue).startsWith('=')) {
                    value = toValue(initValue, row, true);
                  }
                  set(
                    apiPayload,
                    el.field_key.replace('.0.', `.${index * selectedCompactRows.length + rIndex}.`),
                    convertValue(el, value, rowData),
                  );
                });
              } else {
                const formatInput = get(el, ['field_configuration'], []).find(
                  (config) => config['key'] === INPUT_TYPE_FORMAT_INPUT,
                )?.value;
                const value = selectedCompactRows.map((row) => {
                  const initValue = el.init_value_value;
                  let value = get(row, [el.init_value_value]);
                  if (String(initValue).startsWith('=')) {
                    value = toValue(initValue, row, true);
                  }
                  return value;
                });
                set(
                  apiPayload,
                  el.field_key.replace('.0.', `.${index}.`),
                  convertValue(
                    el,
                    String(formatInput).startsWith('=') ? toValue(formatInput, { value }) : value,
                    rowData,
                  ),
                );
              }
            });
          });

          if (String(payloadTransform).startsWith('=')) {
            apiPayload = toValue(payloadTransform, { bulkRows, rowData, payload: apiPayload }, true);
          }

          try {
            let request = eipRequest.post;
            if (method === 'GET') {
              request = eipRequest.get;
            }
            if (method === 'PUT') {
              request = eipRequest.put;
            }
            if (method === 'PATCH') {
              request = eipRequest.patch;
            }
            if (method === 'DELETE') {
              request = eipRequest.deleteFetch;
            }

            const response = await request(endpoint, apiPayload);

            if (response.success || response.code == 200) {
              isSuccess = true;

              handleReloadTable();
              // TODO: query api for all row effected by the updates and reload the node.data
              handleClosed();
              if (onClose) {
                onClose();
              }
            } else {
              let errorMessage;
              if (Array.isArray(response.data)) {
                const errorMessageObj = response.data.find((i) => i.success == false);
                errorMessage = Object.values(errorMessageObj?.error || {})?.[0];
              }
              if (!errorMessage) {
                errorMessage = response.message;
              }
              onToastMultiple({
                messages: [],
                title: errorMessage || 'Something went wrong!',
                variant: 'error',
              });
            }

            callback(
              ['response', response],
              ['payload', apiPayload],
              ['endpoint', endpoint],
              ['handleClosed', handleClosed],
              ['row', rowData],
            );

            return response;
          } catch (e) {
            onToastMultiple({
              title: e.message,
              variant: 'error',
              messages: [],
            });
            callback(
              [
                'response',
                {
                  ...e,
                  message: e.message,
                  response: e.response,
                },
              ],
              ['payload', apiPayload],
              ['endpoint', endpoint],
              ['handleClosed', handleClosed],
            );
          }

          if (tracking === 'yes') {
            try {
              let bulkRows = [];
              if (backboneContext.getGridApi) {
                bulkRows = uniq(backboneContext.getGridApi().getSelectedRows().concat(rowData));
              }
              freshpaint.track(`cell_action_submit`, {
                section: backboneContext.getConfig('title'),
                column: get(props, ['field'], ''),
                action_name: get(props, ['customProps', 'actionName'], ''),
                count_bulk_item: bulkRows.length,
                result: isSuccess,
              });
            } catch {}
          }
        } else {
          onToastMultiple({
            title: 'Please enter the endpoint.',
            variant: 'error',
            messages: [],
          });
        }
      };
      const etableConfig = get(payloadConfig, [INPUT_TYPE_ETABLE_CONFIG], {});
      const sourceFields = get(payloadConfig, [INPUT_TYPE_COMPACT_SOURCE_FIELD], []);
      const targetFields = get(payloadConfig, [INPUT_TYPE_COMPACT_TARGET_FIELD], []);
      const filterField = get(payloadConfig, [INPUT_TYPE_FILTER_FIELD], {});
      const searchHint = get(payloadConfig, [INPUT_TYPE_SEARCH_HINT], '');
      const additionalData = get(payloadConfig, [INPUT_TYPE_ADDITIONAL_DATA], []);
      const linkGroupByFilter = get(payloadConfig, [INPUT_TYPE_LINK_GROUPBY_FILTER], false) == true;
      const ignoreCalendar = get(payloadConfig, [INPUT_TYPE_COMPACT_IGNORE_CALENDAR], false);
      const displayAs = get(payloadConfig, [INPUT_TYPE_DISPLAY_AS], 'etable');
      const view = get(payloadConfig, [INPUT_TYPE_VIEW], '');
      const isPageAction = get(payloadConfig, ['isPageAction'], false);
      const multiple = get(payloadConfig, [INPUT_TYPE_MULTIPLE_SELECT], true);
      const getCustomCellActions = get(props, ['customProps', 'getCustomCellActions'], () => undefined);

      const inheritSourceFilter = get(payloadConfig, [INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER], 'no');

      const dateRange = isPageAction ? get(etableConfig, ['dateRange'], {}) : backboneContext.getConfig('dateRange');
      const calendarOption = isPageAction
        ? get(etableConfig, ['calendarOption'], '')
        : backboneContext.getConfig('calendarOption');

      const viewInfo = get(etableConfig, 'views', []).find(({ id }) => view === id) || get(etableConfig, ['view'], {});
      const linkedBlockEid = get(etableConfig, 'blockEid', '');
      const customConfig = { ...etableConfig, view: viewInfo };

      const { config, linkedObjects } = enhancedETableConfig2({
        customConfig: customConfig,
        systemConfig: {},
        nodeEditContext,
        blockEid: linkedBlockEid,
        setOpenCodeEditor,
        lastUpdated,
        getCustomCellActions,
        onToastMultiple,
      });

      const clone = cloneDeep(config);

      clone.configuration.dateRange = isPageAction ? getDateRangeFromOption(calendarOption, dateRange) : dateRange;
      clone.configuration.calendarOption = calendarOption;
      clone.configuration.tableType = 'compact';
      if (clone.configuration.system?.hiddenComponents) {
        clone.configuration.system.hiddenComponents = clone.configuration.system?.hiddenComponents.filter(
          (el) => el !== 'search',
        );
      }
      clone.configuration.searchHint = searchHint || 'Search...';
      clone.configuration.rowSelection = multiple ? 'multiple' : 'single';
      clone.configuration.maxItemSelected = maxItemSelected;

      // const mappings = get(etableConfig, ['mapping'], {});
      // const dimensionField = uniq(
      //   Object.keys(mappings)
      //     .filter((key) => mappings[key]?.propertyType === 'dimension')
      //     .map((key) => {
      //       const valueGetter = get(mappings, [key, 'valueGetter'], {});
      //       return valueGetter.id || valueGetter.value;
      //     })
      //     .filter((el) => !!el),
      // );
      const dimensionFieldValue = typeof sourceFields === 'string' ? sourceFields.split(',') : sourceFields;
      const dimensionFieldKey = typeof targetFields === 'string' ? targetFields.split(',') : targetFields;

      const customFiltersConfig = get(props, ['payload', INPUT_TYPE_CUSTOM_FILTERS], null);

      let customFilters = customFiltersConfig
        ? String(customFiltersConfig).startsWith('=')
          ? toValue(customFiltersConfig, rowData)
          : rowData[customFiltersConfig]
        : null;

      if (typeof customFilters == 'string' && customFilters) {
        customFilters = safeJsonParse(customFilters, []).map((i) => {
          return {
            ...i,
            static: true,
            isHidden: true,
            isLocked: true,
          };
        });
      }
      if (customFilters && Array.isArray(customFilters) && customFilters.length) {
        clone.configuration.filter = [].concat(clone.configuration.filter || []).concat(customFilters);
      }
      const prevAddon = get(clone, ['addons', 'datasource.getRowsParams'], null);
      const prevGetTableAddon = get(clone, ['addons', 'datasource.apiRequest.getTableData'], () => null);
      clone.addons = {
        ...clone.addons,
        'datasource.getRowsParams': async ({ params }, currentConfig, backbone) => {
          try {
            const prevParams = prevAddon ? prevAddon({ params }, currentConfig, backbone) : params;
            const selectedRows = !isPageAction ? backboneContext.getSelectedRows() : [];
            const rowData = get(props, ['data', 'node', 'data'], {});
            const bulkRows = uniq(selectedRows.concat(rowData));
            let inheritFilters = [];
            if (inheritSourceFilter === 'yes' && !isPageAction) {
              const specialFilters = backboneContext.getConfig('specialFilters') || {};
              const mapping = backbone.getConfig('mapping');
              inheritFilters = Object.entries(specialFilters)
                .map(([k, filter]) => {
                  const tableField = mapping[k];
                  if (!tableField) return null;
                  return {
                    dataType: filter.dataType,
                    field: tableField.filterField,
                    operator: filter.queryType,
                    value: filter.queryValue,
                  };
                })
                .filter((el) => el);
            }
            const systemFiltersConfig = get(payloadConfig, [INPUT_TYPE_SYSTEM_FILTERS], []);

            const fieldOperator = backbone.getConfig('fieldOperator') || 'contains';

            const systemFilters = bulkRows.map((row) => {
              return systemFiltersConfig.reduce(
                (carry, systemFilter) => {
                  const field = systemFilter[INPUT_TYPE_COMPACT_SOURCE_FIELD];
                  const operator = systemFilter[INPUT_TYPE_COMPACT_OPERATOR];
                  const isFormula = String(field).startsWith('=');
                  const value = isFormula ? toValue(field, row, true) : row[field];
                  if (value !== undefined && value !== null) {
                    carry.filters.push({
                      field: systemFilter[INPUT_TYPE_COMPACT_TARGET_FIELD],
                      operator: operator || 'is',
                      value: value,
                      dataType: typeof value,
                    });
                  }
                  return carry;
                },
                {
                  combinator: 'AND',
                  filters: [],
                },
              );
            });

            const cloneParams = cloneDeep({
              ...prevParams,
              filter: {
                combinator: 'AND',
                ...prevParams.filter,
                filters: [
                  ...(inheritFilters || []),
                  ...(prevParams.filter?.filters || []),
                  ...(filterField && !isEmpty(backbone.getConfig('search'))
                    ? [
                        {
                          combinator: fieldOperator.includes('not') ? 'and' : 'or',
                          filters: String(backbone.getConfig('search'))
                            .split('\n')
                            .reduce((carry, search) => {
                              if (splitComma(filterField).length > 1) {
                                filterField.split(',').forEach((i) => {
                                  carry.push({
                                    field: i,
                                    operator: fieldOperator,
                                    value: search,
                                    dataType: 'string',
                                  });
                                });
                              } else {
                                carry.push({
                                  field: filterField,
                                  operator: fieldOperator,
                                  value: search,
                                  dataType: 'string',
                                });
                              }
                              return carry;
                            }, []),
                        },
                      ]
                    : []),
                  ...dimensionFieldValue.reduce((accum, field, index) => {
                    const rowData = get(props, ['data', 'node', 'data', field], null);
                    const bulkRows = uniq(selectedRows.map((el) => el[field]).concat(rowData)).filter(
                      (i) => i !== null,
                    );
                    if (!bulkRows.length) return accum;
                    return accum.concat({
                      field: dimensionFieldKey[index],
                      operator: 'IN',
                      value: bulkRows,
                      dataType: typeof bulkRows[0],
                    });
                  }, []),
                  ...(systemFiltersConfig.length > 0
                    ? [
                        {
                          combinator: 'OR',
                          filters: systemFilters,
                        },
                      ]
                    : []),
                  ...(linkGroupByFilter
                    ? get(backboneContext, ['config', 'groupBy', 'columns'], [])
                        .map((i) => {
                          const valueGetter = get(backboneContext, ['config', 'mapping', i, 'valueGetter'], {});
                          const field = valueGetter?.id || valueGetter?.value;
                          return {
                            field: field,
                            operator: 'is',
                            value: get(rowData, [field], null),
                            dataType: 'string',
                          };
                        })
                        .filter((i) => i.value)
                    : []),
                ],
              },
              sort: prevParams.sort?.length
                ? params.sort
                : backbone.getConfig('search')
                ? [{ field: `LENGTH({${filterField.split(',')?.[0]}})`, sort: 'ASC' }]
                : [],
            });

            if (ignoreCalendar) {
              delete cloneParams.from;
              delete cloneParams.to;
            }

            return cloneParams;
          } catch (e) {
            return params;
          }
        },
        'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
          const prevResult = await prevGetTableAddon(params, originRequest, backbone);
          const fieldOperator = backbone.config['fieldOperator'] || 'contains';
          const search = backbone.config['search'] || '';
          const keyAdditionalData = (additionalData || []).find((i) => i.key == '_key' && i.value);

          let isAdded = false;
          search
            .split('\n')
            .map((i) => (i ? String(i).trim() : ''))
            .filter((i) => !!i)
            .forEach((searchInput) => {
              const isMatched = (prevResult?.rows || []).every((row) => row[keyAdditionalData?.value] != searchInput);

              if (
                (additionalData || []).length > 1 &&
                isMatched &&
                searchInput &&
                !fieldOperator?.includes('not') &&
                params?.pagination?.page == 1
              ) {
                isAdded = true;
                const headers = params.attributes.concat(params.metrics);
                const newRow = headers.reduce((carry, header) => {
                  const overrideField = additionalData.find((i) => i.key == header);
                  if (!overrideField) carry[header] = null;
                  else carry[header] = toValue(overrideField.value, { ...rowData, _search: searchInput });
                  return carry;
                }, {});
                prevResult.rows.unshift(newRow);
              }
            });

          if (isAdded) {
            const calculatedValueGetter = await calculateValueGetter({
              rows: prevResult.rows,
              columns: prevResult?.eTableContext?.columns,
              groupedFields: get(params, 'groupBy.dimensions', []),
              drillDowns: get(params, 'groupBy.drillDowns', []),
              resourceMetric: [],
              formulaUpstream: null,
              config: backbone.config,
            });
            set(prevResult, ['data', 'rows'], calculatedValueGetter);
          }

          return prevResult;
        },
      };
      clone.configuration.visualizationType = displayAs;
      clone.configuration.tableId = clone.configuration.blockEid;
      clone.callback.onRowSelect = onRowSelectedModal;

      const hiddenComponents = get(clone, ['configuration', 'system', 'hiddenComponents'], []);
      if (displayAs === 'chart') {
        set(clone, ['configuration', 'system', 'hiddenComponents'], uniq([...hiddenComponents, 'search', 'expansion']));
      }

      if (ignoreCalendar) {
        set(clone, ['configuration', 'system', 'hiddenComponents'], uniq([...hiddenComponents, 'timeline']));
      }

      const tableRender = makeTable({ blockEid: linkedBlockEid, config: clone, linkedObjects });
      displayTable({
        anchorEl: props.anchorEl,
        tableConfig: { config: clone },
        tableEl: tableRender,
        visible: true,
        onClosed() {
          props.setAnchorEl(null);
        },
        actions: {
          cancelBtnLabel: formConfig['cancel_label'],
          submitBtnLabel: formConfig['submit_label'],
          onSubmit: handleSubmit,
        },
      });
    }
  }, [props.setAnchorEl]);
  return null;
};

export default CompactTableForm;
