import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import CheckboxList from '../../list-control/checkbox-list';
import HeaderList from '../../list-control/header-list/header-list';
import RadioList from '../../list-control/radio-list';
import { OptionType } from '../../list-control/type';
import { SelectionModal } from './type';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useStyles = makeStyles((theme) => ({
  checkListItem: {
    minWidth: 210,
    // maxWidth: 210,
    // margin: '0px 4px',
    padding: '8px 8px',
  },
  borderCenter: {
    border: '1px solid #E4E7E9',
    borderTopWidth: 0,
    borderBottomWidth: 0,
    marginTop: '8px',
    paddingTop: '0px',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  borderCenterMobile: {
    border: 'none',
    width: '100%',
    padding: '0 8px',
    marginBottom: '20px',
  },
}));

type SelectionComponentProps = {
  label: string;
  type: string;
  items: SelectionModal;
  disabled?: boolean;
  selectedItems: SelectionModal;
  handleUpdateSelection: (type: string, selectedArr: SelectionModal) => void;
  enableCenterStyles?: boolean;
  componentType?: string;
  onReorder: (type: string, optionOrder: string[]) => void;
  disabledReorderDebounce?: boolean;
  disabledOptions?: string[];
  selectLimit?: number;
  singleSelectColumns?: string[];
  suppressDnD?: boolean;
};
type statusSelections = 'loading' | 'error' | 'success' | '';
const SelectionComponent = ({
  label,
  type,
  items,
  selectedItems,
  handleUpdateSelection,
  enableCenterStyles = false,
  componentType = '',
  onReorder,
  disabledReorderDebounce = false,
  disabledOptions = [],
  selectLimit,
  singleSelectColumns = [],
  disabled,
  suppressDnD = false,
}: SelectionComponentProps) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = false;

  const selections = React.useMemo(() => {
    return items.map((tmp, index) => {
      const idx = selectedItems.findIndex((selected) => selected.id == tmp.id);
      const checked = componentType == 'radio' ? index === 0 : idx !== -1;
      return {
        ...tmp,
        label: tmp.name,
        value: tmp.id.toString(),
        checked,
        disabled: disabled || disabledOptions.includes(tmp.id.toString()) || tmp.disabled,
      };
    });
  }, [selectedItems, items, disabled]);

  const backbone = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const statusSelection: statusSelections = backbone.getStatus(type);

  const handleReloadData = () => {
    backbone.reloadData(type);
  };

  const handleOnChange = (selectedArr: OptionType[]) => {
    const newSelectedReturn: SelectionModal = selectedArr
      .filter((item) => item.checked)
      .map((m) => ({ id: m.value, name: m.label }));
    handleUpdateSelection(type, newSelectedReturn);
  };

  const handleReorder = (options: OptionType[]) => {
    const optionIds = options.map((i) => i.value);
    onReorder(type, optionIds);
  };

  const RenderCheckBoxListWithStatus = (status: statusSelections = 'success') => {
    switch (status) {
      case 'loading':
        return (
          <Box>
            <HeaderList title={label} addOn={false} />
            Loading ....
          </Box>
        );
      case 'error':
        return (
          <Box>
            <HeaderList title={label} addOn={false} />
            <button onClick={handleReloadData}>Reload</button>
          </Box>
        );
      default:
        return componentType !== 'radio' ? (
          <Box>
            <CheckboxList
              allowCheckAll
              searchAble={true}
              disabledAddOnIcon
              draggable={!!onReorder && !suppressDnD}
              label={label}
              items={selections}
              onChange={handleOnChange}
              onReorder={handleReorder}
              disabledReorderDebounce={disabledReorderDebounce}
              selectLimit={selectLimit}
              singleSelectElements={singleSelectColumns}
            />
          </Box>
        ) : (
          <Box>
            <RadioList
              searchAble={true}
              draggable={!!onReorder}
              // label={label}
              items={selections}
              onChange={handleOnChange}
              onReorder={handleReorder}
            />
          </Box>
        );
    }
  };
  return (
    <Grid
      className={clsx(classes.checkListItem, `eip_properties_type_${type}`, {
        [classes.borderCenter]: enableCenterStyles,
        [classes.borderCenterMobile]: isMobileView,
      })}
    >
      {RenderCheckBoxListWithStatus(statusSelection)}
    </Grid>
  );
};
export default SelectionComponent;
