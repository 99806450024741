import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Box, Grid } from '@material-ui/core';
const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: 'transparent !important',
    backgroundColor: 'transparent !important',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: '12px 0 0 0',
    '&$expanded': {
      margin: '12px 0 0 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    display: 'block',
  },
}))(MuiAccordionDetails);

/** Style */
const useStyles = makeStyles(() => ({
  iconToggle: {
    width: '10px',
    height: '100%',
    marginRight: '10px',
  },
  label: {
    fontStyle: ' normal',
    fontWeight: 500,
    fontSize: ' 24px',
    lineHeight: '20px',
  },
  headerRight: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spacer: {
    marginBottom: '10px',
    '&.non-spacer': {
      marginBottom: 0,
    },
  },
  wapperRight: {
    justifyContent: 'flex-end',
  },
  expandTitle: {
    color: '#253746',
  },
}));

type StyleProps = {
  expand?: boolean;
};

interface AccordionCustomProps extends AccordionProps {
  label?: string;
  headerRight?: React.ReactNode;
  headerAction?: React.ReactNode;
  contentMarginTop?: string | number;
  noSpacer?: boolean;
}

const ExpansionPanel: React.FC<AccordionCustomProps> = ({
  label = '',
  headerRight = null,
  headerAction = null,
  children,
  contentMarginTop = 0,
  noSpacer = false,
  ...rest
}: AccordionCustomProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  React.useEffect(() => {
    setExpanded(rest.defaultExpanded);
  }, [rest.defaultExpanded]);

  const classes = useStyles();
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion {...rest} square expanded={expanded}>
      <AccordionSummary>
        <Grid container>
          <Grid
            item
            xs={12}
            container
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ marginRight: '10px' }}
          >
            <Grid item xs={6} className={classes.spacer}>
              <Box display={'inline-flex'} onClick={handleToggle} className={classes.expandTitle}>
                <span className={classes.iconToggle}>
                  <Icon
                    type={expanded ? 'arrowdown' : 'arrowright'}
                    style={{ width: '100%', height: expanded ? '10px' : '13px' }}
                  />
                </span>
                <span className={classes.label} data-dbf-text-editable={'etable-title'} data-dbf-text-default={label}>
                  {label}
                </span>
              </Box>
            </Grid>
            <Grid item xs={6} className={`${classes.spacer} ${noSpacer ? 'non-spacer' : ''} ${classes.wapperRight}`}>
              <Grid className={classes.headerRight}>{headerRight}</Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container style={{ marginLeft: '17px', marginRight: '6px' }}>
            {headerAction}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: noSpacer ? '2px 8px 8px' : '4px 8px 8px' }}>
        <div style={{ marginLeft: '17px', marginRight: '17px', marginTop: contentMarginTop }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionPanel;
