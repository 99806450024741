import { formatCurrency } from '@ep/insight-ui/lib/number';
import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { colorsChartLegend, defaultOptions, HIDDEN_LABEL_TEXT, tool } from '../helper';
import { IDataChart } from '../type';
import ColumnChartResponsive from './column-chart';
import { funcConfigs } from './hooks';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';

type Props = {
  data: IDataChart;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
};
const ColumnChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
}: Props) => {
  const [dataChart, setDataChart] = React.useState<{ dataList: any; optionList: any }>({
    dataList: null,
    optionList: null,
  });
  const { externalTooltipHandler } = funcConfigs({});
  React.useEffect(() => {
    const datasets = [];
    const now = moment();
    data.rows.forEach((row, index) => {
      const thisSeries = [];
      const futureSeries = [];
      const rowDataEmpty = [];
      row.data.forEach((item) => {
        if (moment(item.date).isAfter(now)) {
          futureSeries.push(item.value);
        } else {
          thisSeries.push(item.value);
        }
        rowDataEmpty.push(0);
      });

      const dataSetCurrent = {
        label: row.label,
        backgroundColor: colorsChartLegend[index].stroke,
        borderColor: colorsChartLegend[index].stroke,
        pointBackgroundColor: colorsChartLegend[index].stroke,
        hoverBackgroundColor: colorsChartLegend[index].hover,
        data: thisSeries,
        textKey: row.label,
        active: 'active',
        stack: String(index),
        borderRadius: {
          topLeft: 4,
          topRight: 4,
        },
      };
      datasets.push(dataSetCurrent);
      if (futureSeries.length > 0) {
        const arr = thisSeries.map((item, index) => 0).concat(futureSeries);
        const dataSet = {
          label: HIDDEN_LABEL_TEXT + index,
          colorTooltip: colorsChartLegend[index].stroke,
          textKey: row.label,
          backgroundColor: colorsChartLegend[index].segment,
          borderColor: colorsChartLegend[index].segment,
          pointBackgroundColor: colorsChartLegend[index].stroke,
          hoverBackgroundColor: colorsChartLegend[index].hover,
          data: arr,
          active: 'active',
          stack: String(index),
          borderRadius: {
            topLeft: 4,
            topRight: 4,
          },
        };
        datasets.push(dataSet);
      }
    });

    const dataList = {
      labels: data.headers,
      datasets,
    };
    //Display option chart
    const { COLUMN: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    _.set(optionList, 'plugins.tooltip.callbacks.label', tool.columeCallbacks.label({ currency }));
    _.set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    if (maxValues) {
      _.set(optionList, 'scales.y.suggestedMax', maxValues);
    }
    setDataChart({ dataList, optionList });
  }, [data]);

  return (
    <ColumnChartResponsive
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
    />
  );
};

export default ColumnChart;
