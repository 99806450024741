/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import * as _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';
// import
const useStyles = makeStyles(() => ({
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  text: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.time': {
      fontSize: '13px',
      fontWeight: 'normal',
    },
  },
}));
export interface IPositionFormatValue {
  position: number;
  updateDay: string;
  defaultFormat?: string;
  displayNumber?: boolean;
}

const PositionFormat = (props: IPropsFormat<IPositionFormatValue>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const position = _.get(props, ['value', 'position'], 0);
  const updateDay = _.get(props, ['value', 'updateDay']);
  const defaultFormat = _.get(props, ['value', 'defaultFormat'], '');
  const displayNumber = _.get(props, ['value', 'displayNumber'], true);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const getLabel = (val: number | undefined) => {
    if (!val || (val && val > 20))
      return (
        <a href="" style={{ color: '#CE364D' }}>
          {displayNumber ? `>20` : 'Lose'}
        </a>
      );

    return (
      <a href="" style={{ color: '#3BA76D' }}>
        {displayNumber ? val : 'Win'}
      </a>
    );
  };

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid
        container
        alignItems={'center'}
        direction={'column'}
        wrap={'nowrap'}
        className={clsx(classes.height100, classes.alignItemsStart)}
      >
        {/*hashtag*/}
        <Grid item xs={12} className={classes.height100}>
          <span></span>
        </Grid>
        {/* label */}
        <Grid item xs={12} className={classes.height100}>
          <span className={classes.text}>{getLabel(position)}</span>
        </Grid>
        {/* subtext */}
        <Grid item xs={12} className={classes.height100}>
          <span className={`${classes.text} time`}>
            {updateDay ? moment(updateDay, defaultFormat).format('hh:mm DD/MM/YYYY') : null}
          </span>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default PositionFormat;
