import * as React from 'react';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';

export interface IPropsLoadingComponent {
  loading?: boolean;
  error?: boolean;
  errorMessage?: string;
  children?: any;
}

const LoadingComponent = ({
  loading,
  error,
  errorMessage = 'No data available.',
  children,
}: IPropsLoadingComponent) => {
  if (loading) return <LoadingIcon color={'#253746'} />;
  if (error) return <span>{errorMessage}</span>;
  return <>{children}</>;
};

export default LoadingComponent;
