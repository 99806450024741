import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PausedRounded = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2C6.81331 2 5.65328 2.35189 4.66658 3.01118C3.67989 3.67047 2.91085 4.60754 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2ZM7 10C7 10.2652 6.89465 10.5196 6.70711 10.7071C6.51957 10.8946 6.26522 11 6 11C5.73479 11 5.48043 10.8946 5.2929 10.7071C5.10536 10.5196 5 10.2652 5 10V6C5 5.73478 5.10536 5.48043 5.2929 5.29289C5.48043 5.10536 5.73479 5 6 5C6.26522 5 6.51957 5.10536 6.70711 5.29289C6.89465 5.48043 7 5.73478 7 6V10ZM11 10C11 10.2652 10.8946 10.5196 10.7071 10.7071C10.5196 10.8946 10.2652 11 10 11C9.73479 11 9.48043 10.8946 9.2929 10.7071C9.10536 10.5196 9 10.2652 9 10V6C9 5.73478 9.10536 5.48043 9.2929 5.29289C9.48043 5.10536 9.73479 5 10 5C10.2652 5 10.5196 5.10536 10.7071 5.29289C10.8946 5.48043 11 5.73478 11 6V10Z"
      fill="#253746"
    />
  </SvgIcon>
);

export default PausedRounded;
