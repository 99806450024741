import { variantMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import MuiTypography from '@material-ui/core/Typography';
import clsx from 'clsx';
import * as React from 'react';

const useStyles = makeStyles(() => ({
  body3: variantMapping.body3.styles,
  body4: variantMapping.body4.styles,
  body5: variantMapping.body5.styles,
}));

type MuiTypographyProp = React.ComponentProps<typeof MuiTypography>;

export type ETypographyProp = Omit<MuiTypographyProp, 'variant'> & {
  variant: 'body3' | 'body4' | 'body5' | MuiTypographyProp['variant'];
};

const Typography: React.FC<ETypographyProp> = ({ variant, ...rest }: ETypographyProp) => {
  const classes = useStyles();
  const eVariant = variant;
  let muiVariant: MuiTypographyProp['variant'] = variant;

  if (['body3', 'body4', 'body5'].indexOf(variant) > -1) {
    muiVariant = 'body2';
  }

  return (
    <MuiTypography {...rest} variant={muiVariant} className={clsx(classes[eVariant], rest.className)}></MuiTypography>
  );
};

export default Typography;
