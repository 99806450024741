/* eslint-disable react/jsx-key */
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import '@ep/insight-ui/elements/table/styles.css';

import { ColumnMovedEvent, GridApi, IServerSideGetRowsParams } from '@ag-grid-community/core';
import {
  ModuleRegistry,
  RowSelectedEvent,
  ServerSideStoreType,
  ValueGetterParams,
  ViewportChangedEvent,
} from '@ag-grid-community/core';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { ContainerResponsiveContext, retryTillSuccess, useLog, eipRequest, NodeEditContext } from '@eip/next/lib/main';
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
import { IconType } from '@ep/insight-ui/icons/Icon';
/**
 * ff.ecompacttable_layout_mobile:end
 */

import moment from 'moment';
import { Box, makeStyles } from '@material-ui/core';
import {
  cloneDeep,
  debounce,
  first,
  flatten,
  get,
  groupBy,
  mapValues,
  merge,
  set,
  snakeCase,
  escape,
  isEqual,
} from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import HeaderGroup from '@ep/insight-ui/elements/table/format/header-group';
import LoadingCellFormat from '@ep/insight-ui/elements/table/format/loading-cell-format';
import EmptyRows from '@ep/insight-ui/elements/table/format/no-rows-data-format';
import SelectionFormat from './format/selection-format';
import { aggregatedCell } from './aggregate-wrapper';
import HeaderCell from './header-cell';
import { HeaderTooltip } from './header-tooltip';
import TableCompactError from '@ep/insight-ui/elements/table/table-compact-error';
import { InlineLoadmore } from '@ep/insight-ui/elements/etable2/table-group-load-more';
import { groupContracted, groupExpandedIcon } from './table-helper';

import { usePinColumn } from './hooks/pin-column';

import CalculateFormat, { PINNED_ROW_HEIGHT } from '@ep/insight-ui/elements/table/format/calculate-format';
import { useAtomValue, useSetAtom } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { getChannelCellUpdate } from '@ep/insight-ui/sw/channel';
import produce from 'immer';
import { useAutoExpandGroup } from './hooks/use-auto-expand-group';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { getPivotColKey, getPivotValueGetter } from '@ep/insight-ui/system/helper/functions';
import CellSelectionFormat from './format/cell-selection-format';
import EmptyFormat from '../table/format/empty-format';
import NextPagination, { ROW_HEIGHT as PAGINATION_ROW_HEIGHT } from '../table/next-pagination';
import { produceColumns } from '@ep/insight-ui/sw/etable/service';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { CALCULATE_TRACTION_WIDTH } from '@ep/insight-ui/system/helper/constant';

LicenseManager.setLicenseKey(process.env.AGGRID_LICENSE || process.env.STORYBOOK_AGGRID_LICENSE);
ModuleRegistry.registerModules([ServerSideRowModelModule, RowGroupingModule]);

const log = useLog('insight-ui:tablev27');

const useStyles = makeStyles({
  container: {
    '& .ag-has-focus .ag-cell-value.ag-cell-focus': {
      background: '#ebf6ff',
    },
    '& .ag-has-focus .cell-checkbox.ag-cell-focus': {
      background: '#ebf6ff',
    },
    '& .cell-checkbox:hover': {
      background: '#FAFCFF',
    },
    '& .ag-header-active': {
      background: '#ebf6ff',
    },
    '&.ag-theme-alpine .ag-keyboard-focus .ag-header-cell:focus::after': {
      content: 'none',
    },
  },
});

export type OptionSelectType = {
  width?: number;
  name: string;
  field: string;
  sortable?: boolean;
  filterable?: boolean;
  cell: {
    format: any;
    action?: {
      name: string;
      icon?: IconType;
      component?: React.ReactNode;
      onSubmit: (info) => void;
    };
  };
  align?: string;
  action?: {
    name: string;
    icon?: IconType;
    component?: React.ReactNode;
    onSubmit: (info) => void;
    type?: string;
  };
};

export type SortType = {
  field: string;
  sort: 'asc' | 'desc';
};

export interface IColumnWidth {
  columnField: string;
  width: number;
}

export interface IPagination {
  page: number;
  limit: number;
}

interface IPinnedColumn {
  field: string;
  isLeftPin: boolean;
  isRightPin: boolean;
  left?: number;
}

export const PAGE_OPTIONS = [
  {
    label: '10 items',
    value: '10',
    checked: true,
  },
  {
    label: '20 items',
    value: '20',
    checked: false,
  },
  {
    label: '50 items',
    value: '50',
    checked: false,
  },
  {
    label: '100 items',
    value: '100',
    checked: false,
  },
];

interface IPropTable {
  backbone: any;
  openSortModal?: (params: { field: string; menuType: { name: string; type: 'desc' | 'asc' | '' } }) => void;
  filterText?: string;
  columns: OptionSelectType[];
  columnsActions?: [{ name: 'Add filter'; onClick: () => void }];
  columnOrder?: string[];
  hiddenColumns?: string[];
  pinnedColumns?: IPinnedColumn[];
  rowsGroup?: string[];
  filter?: [{ field: string; operator: string; value: string }];
  sort?: SortType[];
  allowSelect?: boolean;
  selectedRows?: any[];
  pageOptions?: Array<OptionType>;
  pagination: { page: number; limit: number; total: number };
  onChangePagination: ({ page, limit }: IPagination) => void;
  onColumnResize?: (columnWidth: Array<IColumnWidth>) => void;
  onRowSelect?: () => void;
  onColumnReorder?: (columnOrder: string[]) => void;
  typeTable?: string;
  aggregations?: { [key: string]: any };
  cellFormatRegistry: Record<string, any>;
  cellActionRegistry: Record<string, any>;
  headerActionRegistry: Record<string, any>;
}

function batchCall(batchableFn, ms) {
  const queue = [];
  let tid;
  const batchedFn = (arg) => {
    window.clearTimeout(tid);
    if (arg !== undefined) {
      queue.push(arg);
    }
    tid = window.setTimeout(() => {
      batchableFn(queue.length > 0 ? queue : undefined);
      queue.length = 0;
    }, ms);
  };

  return batchedFn;
}

const createValueGetter = (column: any) => {
  const valueMapper = column.cell?.valueGetter;
  const staticValue = column.cell?.staticValue;
  return (params: ValueGetterParams) => {
    if (valueMapper) {
      const getter: Record<string, any> = cloneDeep(get(params, ['data', 'eData', column.field], {}));
      if (getter.label === undefined) {
        getter.label = getter.value;
      }
      return merge(getter, staticValue);
    }
    return null;
  };
};

const Table = ({
  pagination,
  hiddenColumns = [],
  rowsGroup = [],
  onChangePagination,
  onColumnResize,
  sort = [],
  selectedRows = [],
  onColumnReorder,
  backbone,
  typeTable = '',
  cellFormatRegistry,
  cellActionRegistry,
  headerActionRegistry,
}: IPropTable) => {
  const classes = useStyles();
  const headerHeight = 32;
  const isMinimalTable = typeTable == 'minimal';
  const rowHeight = React.useMemo(() => (isMinimalTable ? 16 : typeTable === 'compact' ? 56 : 56), [typeTable]);
  const [gridApi, setGridApi] = React.useState<GridApi>(null);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [checkboxState, setCheckboxState] = React.useState<'default' | 'checked' | 'indeterminate'>('default');
  const setLastUpdatedAt = useSetAtom(eTableAtom.lastUpdatedAt);
  const lastUpdatedAt = useAtomValue(eTableAtom.lastUpdatedAt);
  const { handleViewportChange: autoExpandGroup, setIsFirstExpand } = useAutoExpandGroup(backbone);
  const { isEditMode } = React.useContext(NodeEditContext);
  const tableModeRef = React.useRef('default');
  const pinnedCols = useAtomValue(eTableAtom.view.pinnedColumn);
  const lockColumnWidthConfig = backbone.getConfig('system.lockColumnWidth', 'no') == 'yes';
  const {
    setColumnApi: setColumnApiNext,
    handleBodyScroll: handleBodyScrollNext,
    getPinColumnActions: getPinColumnActionsNext,
  } = usePinColumn({ pinnedColumns: pinnedCols, backbone, rowsGroup });
  const isLockedView = get(backbone.getConfig('view', {}), 'isLock', false);
  const maxItemSelected = backbone.getConfig('max_item_selected', '') || null;
  const getCellActions = (actions: any[], column: any) => {
    return actions.map((act) => {
      if (act.icon === 'filter') {
        act.disable = false;
        act.onSubmit = (nodeValue) => {
          const filterField = get(column, 'filterField', '');
          const value = get(nodeValue, ['row', filterField], '');
          const data = { field: column.field, value };
          backbone.updateTempStorage('filterModal', data);
          backbone.changeVisibility('filter', true);
        };
      }
      return act;
    });
  };

  const [columnDefs, setColumnDefs] = React.useState(() => {
    const { columnDefs } = buildColumnFromService({
      eTableContext: {
        columns: produceColumns(backbone.config),
        groupBy: backbone.config?.groupBy,
        pinnedColumn: backbone.config?.pinnedColumn,
        tableMode: tableModeRef.current,
      },
    });
    return columnDefs;
  });
  const groupedCols = backbone.getGroupedColumns();

  const tableId = useAtomValue(eTableAtom.tableId);
  React.useEffect(() => {
    setColumnDefs(() => {
      const { columnDefs } = buildColumnFromService({
        eTableContext: {
          columns: produceColumns(backbone.config),
          groupBy: groupedCols,
          pinnedColumn: pinnedCols,
          tableMode: tableModeRef.current,
        },
      });

      return columnDefs;
    });
  }, [groupedCols]);

  const totalGroupedColumns = React.useMemo(() => {
    return columnDefs.filter((i) => i.cellRenderer == 'agGroupCellRenderer').length;
  }, [columnDefs]);

  const tableDOM = React.useRef(null);

  log('usePinColumn', { pinnedCols, backbone, rowsGroup });

  const multiple = backbone.getConfig('rowSelection', 'multiple');
  const ignoreFooterRow = backbone.getConfig('system.ignoreFooterRow') || false;
  const [totalRowInTable, settotalRowInTable] = React.useState(0);
  const [leftNotEmpty, setLeftNotEmpty] = React.useState(false);

  React.useEffect(() => {
    const mappedColumnDefs = (columnDef) => {
      if (columnDef.colId === 'checkbox' || columnDef.enableRowGroup) return columnDef;

      const pinnedColumn = pinnedCols.find(({ field }) => field === columnDef.field);
      const menuAction = get(columnDef, 'headerComponentParams.menuAction', [])
        .filter((m) => {
          return ['pinThis', 'pinLeft', 'pinRight'].indexOf(m.actionId) === -1;
        })
        .concat(getPinColumnActionsNext(columnDef, pinnedCols));

      return {
        ...columnDef,
        pinned:
          pinnedColumn && pinnedColumn.isLeftPin ? 'left' : pinnedColumn && pinnedColumn.isRightPin ? 'right' : false,
        headerComponentParams: {
          ...columnDef.headerComponentParams,
          menuAction,
        },
        ...(columnDef.children ? { children: columnDef.children.map(mappedColumnDefs) } : {}),
      };
    };
    setColumnDefs((columnDefs) => {
      return columnDefs.map(mappedColumnDefs);
    });
  }, [pinnedCols]);

  const pageOptions = React.useMemo(() => {
    return cloneDeep(PAGE_OPTIONS);
  }, []);

  const [rowSelected, setRowSelected] = React.useState([]);
  const [maxSelectedError, setMaxSelectedError] = React.useState(false);
  const isShowVerticalLines = useAtomValue(eTableAtom.view.toggleVerticalLines) == 'yes';

  const isPromotedObjectTable = backbone.getConfig('tableId') === 'promoted_object';

  function buildColumnFromService(result) {
    const { eTableContext } = result;
    const { columns: rawColumns, groupBy, pinnedColumn: swPinnedColumns, tableMode } = eTableContext;
    const acceptedTableMode = ['pivot', 'pivotNext'];
    if (acceptedTableMode.includes(tableMode)) {
      tableModeRef.current = tableMode;
    } else {
      tableModeRef.current = 'default';
    }

    const mapping = backbone.getConfig('mapping');
    const columnWidth = backbone.getConfig('columnWidth', []);
    const numberOfPivot = backbone.getConfig('system.numberOfPivot', 0);
    const columns = [];
    for (const column of rawColumns) {
      columns.push(column);
    }
    const systemColumnWidth = backbone
      .addon('get.system.config.detail', () => [])('columnWidth', [])
      .reduce((carry, i) => {
        return {
          ...carry,
          [i.columnField]: i.width,
        };
      }, {});

    function buildCols(columns) {
      const newCols = columns
        .slice(0, tableMode === 'pivot' && numberOfPivot > 0 ? +numberOfPivot + 1 : columns.length)
        .map((column: any, index: number) => {
          const isPivotColumn = column.field.startsWith('_pivot') && column.rawMetric;
          const isLockedColumnWidth =
            lockColumnWidthConfig ||
            toValue(get(mapping, [column.field, 'systemSettings', 'properties.lockWidth'], ''), backbone.config) ==
              'yes' ||
            get(mapping, [column.field, 'lockColumnWidth'], 0) == 1;
          const isGrouped = !!column.isGrouped;
          const colField = column.field;
          const pinnedColumn = swPinnedColumns.find(({ field }) => field === colField);
          const pinColumnActions = isLockedView && !isEditMode ? [] : getPinColumnActionsNext(column, swPinnedColumns);
          const headerActions = (headerActionRegistry[column.propertyType] || headerActionRegistry['default']).filter(
            (i) => {
              return !isLockedColumnWidth || i.icon != 'resize';
            },
          );

          const propType = column.propertyType;
          const menuAction = isPivotColumn
            ? pinColumnActions
            : headerActions.concat(pinColumnActions).concat(
                isLockedView && !isEditMode
                  ? []
                  : {
                      name: 'Hide this',
                      icon: 'hideDetail',
                      type: '',
                      component: null,
                      onSubmit: () => {
                        const currentSelected = backbone.getConfig(propType);
                        const filteredSelected = currentSelected.filter((ele) => ele !== colField);
                        backbone.changeConfig(propType, filteredSelected);
                      },
                    },
              );

          let width = column.width;
          // pivot table column width
          if (tableMode === 'pivot') {
            width = columnWidth.find(({ columnField }) => columnField === colField)?.width || column.width;
          }

          // check init default column width
          let field;
          if (isPivotColumn) {
            field = column.rawMetric;
          } else {
            field = column.field;
          }
          const initColumnWidth = mapping[field]?.initColumnWidth;
          const pivotColumnWidth = mapping[field]?.pivotColumnWidth || column?.pivotColumnWidth;
          const initGroupColumnWidth = mapping[field]?.initGroupColumnWidth;
          if (isLockedView || isEditMode || isLockedColumnWidth || isPivotColumn) {
            if (initColumnWidth && Number(initColumnWidth)) width = initColumnWidth;
            else if (initColumnWidth === EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT) width = undefined;
            else if (systemColumnWidth[field]) width = systemColumnWidth[field];
          }
          const firstGroupedColumn = groupedColumns.filter((i) => i.isGrouped).map((i) => i.field)?.[0];
          if (isLockedColumnWidth && initGroupColumnWidth && firstGroupedColumn && field != firstGroupedColumn) {
            width =
              initGroupColumnWidth === EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT ? undefined : initGroupColumnWidth;
          }
          if (pivotColumnWidth && isPivotColumn) {
            if (pivotColumnWidth && Number(pivotColumnWidth)) width = pivotColumnWidth;
            else if (pivotColumnWidth === EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT) width = undefined;
          }
          const cellActions = getCellActions(get(column, 'cell.actions', []), column);

          const updateHandler = get(column, ['cell', 'updateHandler']);
          const cellFormat = get(column, 'cell.format', null);
          const children = get(column, ['children'], null);
          const marryChildren = get(column, ['marryChildren'], null);
          const suppressResizable = get(column, ['suppressResizable'], false);
          const additionalHeaderClass = get(column, ['additionalHeaderClass'], '');

          if (isMinimalTable) {
            width = '0';
          }
          const col: AgGridColumnProps = {
            headerName: column.name,
            field: colField,
            colId: colField,
            resizable: !suppressResizable && ((!isLockedColumnWidth && !isLockedView) || isEditMode),
            width: width,
            rowGroup: column.isGrouped,
            headerClass: [
              `align-${column.align || 'left'}`,
              `${typeTable === 'treemapTable' && colField === 'keyword' ? 'keyword-header' : ''}`,
              `eip_col_${colField}`,
              additionalHeaderClass,
            ],
            cellClass: `eip_col_${colField}`,
            hide: isGrouped || (hiddenColumns || []).includes(colField),
            lockVisible: true,
            lockPosition: column.lockPosition ?? false,
            pinned:
              pinnedColumn && pinnedColumn.isLeftPin
                ? 'left'
                : pinnedColumn && pinnedColumn.isRightPin
                ? 'right'
                : false,
            headerComponentParams: {
              menuAction,
              field: colField,
              sort,
              dataType: column.dataType,
            },
            cellStyle: {
              padding: '0',
            },
            cellRendererParams: {
              cellAction: cellActions,
              updateHandler: updateHandler,
              field: colField,
              columnName: column.name,
              isGrouped: isGrouped,
              typeTable,
              dataType: column.dataType,
              column,
              aggregations,
              setColumnAggregate: (colId, calculatedSelectedValue) => {
                backbone.addon('group.setColumnAggregate')(colId, calculatedSelectedValue, backbone);
              },
            },
            valueGetter: createValueGetter(column),
            minWidth: 39,
            rowGroupIndex: groupedColumns.filter((i) => i.isGrouped).findIndex((i) => i.field == colField),
            ...(children ? { children: buildCols(children) } : {}),
            ...(marryChildren ? { marryChildren } : {}),
          };

          if (cellFormat === 'pivotCell') {
            col.cellRendererSelector = (params) => {
              const cellFormat = get(params, 'value.pivot.cellFormat', 'textFormatFormula');
              const value = first(get(params, 'value.pivot.data', [{}]));
              return {
                component: aggregatedCell(cellFormatRegistry[cellFormat]),
                params: {
                  value: value,
                },
              };
            };
          } else {
            col.cellRenderer = aggregatedCell(cellFormatRegistry[cellFormat]);
            col.cellRendererSelector = (params) => {
              if (params.node.isRowPinned() && !isMinimalTable) {
                return {
                  component: String(params.colDef.colId).startsWith('_pivot') ? EmptyFormat : CalculateFormat,
                  params: {
                    tableDOM,
                  },
                };
              }

              return undefined;
            };
          }

          col.aggFunc = column.aggFunc ?? 'unique';
          if (isGrouped) {
            col.keyCreator = (params) => {
              const countryObject = params.value;
              return countryObject.value;
            };
          }

          return col;
        });

      return newCols;
    }

    const groupedColumns = get(groupBy, 'columns', [])
      .map((c) => columns.find((c1) => c1.field === c))
      .filter((c) => !!c);
    const aggregations = get(groupBy, 'aggregations', {});
    const groupedCol = groupedColumns
      .filter((i) => i.isGrouped)
      .map((column, index) => {
        const isLockedColumnWidth =
          lockColumnWidthConfig ||
          toValue(get(mapping, [column.field, 'systemSettings', 'properties.lockWidth'], ''), backbone.config) ==
            'yes' ||
          get(mapping, [column.field, 'lockColumnWidth'], 0) == 1;
        const pinColumnActions = isLockedView && !isEditMode ? [] : getPinColumnActionsNext(column, swPinnedColumns);
        const headerActions = headerActionRegistry['groupBy'].filter((i) => {
          return !isLockedColumnWidth || i.icon != 'resize';
        });
        const menuAction = headerActions.concat(pinColumnActions);
        const cellActions = getCellActions(get(column, 'cell.actions', []), column);
        const cellFormat = get(column, 'cell.format', null);

        let width = column.width;
        // pivot table column width
        if (tableMode === 'pivot') {
          width = columnWidth.find(({ columnField }) => columnField === column.field)?.width || column.width;
        }

        // check init default column width
        const initColumnWidth = mapping[column.field]?.initColumnWidth;
        const initGroupColumnWidth = mapping[column.field]?.initGroupColumnWidth;
        if (isLockedView || isEditMode || isLockedColumnWidth) {
          if (initColumnWidth && Number(initColumnWidth)) width = initColumnWidth;
          else if (initColumnWidth === EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT) width = undefined;
          else if (systemColumnWidth[column.field]) width = systemColumnWidth[column.field];
        }
        if (isLockedColumnWidth && initGroupColumnWidth && index > 0) {
          width =
            initGroupColumnWidth === EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT ? undefined : initGroupColumnWidth;
        }
        if (isMinimalTable) {
          width = '0';
        }
        const col: AgGridColumnProps = {
          colId: column.field + '_grouped',
          field: column.field,
          headerName: column.name,
          showRowGroup: column.field,
          cellRenderer: 'agGroupCellRenderer',
          headerComponentParams: {
            menuAction: menuAction,
            field: column.field,
            sort,
          },
          pinned: 'left',
          lockPinned: true,
          lockVisible: true,
          lockPosition: true,
          resizable: (!isLockedColumnWidth && !isLockedView) || isEditMode,
          headerComponent: HeaderGroup,
          valueGetter: createValueGetter(column),
          cellRendererParams: {
            suppressCount: true,
            innerRenderer: cellFormatRegistry[get(column, 'cell.format', 'text')],
            columnName: column.name,
            isGrouped: true,
            typeTable,
            cellAction: cellActions,
            updateHandler: column.cell.updateHandler,
            dataType: column.dataType,
            column,
            field: column.field,
            aggregations,
            setColumnAggregate: (colId, calculatedSelectedValue) => {
              backbone.addon('group.setColumnAggregate')(
                colId.replace(/_grouped$/, ''),
                calculatedSelectedValue,
                backbone,
              );
            },
          },
          width: width,
          enableRowGroup: true,
          enablePivot: true,
        };

        col.cellRendererSelector = (params) => {
          if (params.node.level < index) {
            return {
              component: aggregatedCell(cellFormatRegistry[cellFormat]),
            };
          }
          if (params.node.isRowPinned()) {
            return {
              component: EmptyFormat,
              params: {
                tableDOM,
              },
            };
          }

          return undefined;
        };
        return col;
      });

    const newCols = buildCols(
      columns.slice(0, tableMode === 'pivot' && numberOfPivot > 0 ? +numberOfPivot + 1 : columns.length),
    );

    const checkboxSelect: AgGridColumnProps = {
      headerName: '',
      field: 'checkbox',
      colId: 'checkbox',
      resizable: false,
      pinned: 'left',
      lockPinned: true,
      lockVisible: true,
      lockPosition: true,
      headerTooltip: `Rows selected`,
      tooltipComponent: HeaderTooltip,
      cellStyle: {
        borderRightColor: '#e5e5e5',
      },
      width: 40,
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: false,
      cellClass: 'cell-checkbox',
      headerComponent: maxItemSelected == null ? SelectionFormat : EmptyFormat,
      cellRenderer: CellSelectionFormat,
      cellRendererParams: {
        maxItemSelected,
      },
    };

    return {
      columnDefs: []
        .concat(!isMinimalTable ? checkboxSelect : [])
        .concat(groupedCol)
        .concat(newCols),
      pinnedCols: swPinnedColumns,
    };
  }

  const ssDatasource = React.useMemo(() => {
    const datasource = backbone.getDatasource();

    return {
      ...datasource,
      getRows: (params: IServerSideGetRowsParams) => {
        const { success, fail, request } = params;
        return datasource
          .getRows({ ...params, success: () => {}, fail: () => {} })
          .then(async (result) => {
            if (result.queryParams?.pagination?.page == 1 && !result.queryParams?.groupBy?.drillDowns) {
              setIsFirstExpand(true);
            }
            const { columnDefs } = buildColumnFromService(result);
            setColumnDefs(columnDefs);
            if (get(result, 'isError', false)) {
              fail();
            } else {
              // Auto Fit Content initColumnWidth
              const columnApi = backbone.getColumnApi();
              const columnNeedsToFit = columnDefs.filter((el) => !el.width).map((el) => el.colId);
              if ((isLockedView || isEditMode) && columnApi && columnNeedsToFit.length) {
                setTimeout(() => {
                  columnApi.autoSizeColumns(columnNeedsToFit);
                }, 0);
              }
              setTimeout(() => {
                autoExpandGroup();
              }, 0);
              if (isMinimalTable) {
                setTimeout(() => {
                  columnApi.autoSizeAllColumns();
                }, 0);
              }
              success({
                rowData: result.rowData,
                rowCount:
                  result.rowData.length < pagination.limit
                    ? result.rowData.length + Number(get(request, ['startRow'], 0))
                    : undefined,
              });
            }
          })
          .catch((err) => {
            console.error(err);
            fail();
          });
      },
    };
  }, []);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setMaxSelectedError(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [maxSelectedError]);

  const setHeaderRetry = useSetAtom(eTableAtom.updateHeaderError);
  const clearHeaderUpdates = useSetAtom(eTableAtom.clearHeaderUpdates);
  React.useEffect(() => {
    if (gridApi) {
      const updateCell = (event) => {
        const cellUpdateByRowId = event.data.update;
        const { error } = event.data;
        retryTillSuccess(() => {
          // because sometime api return to fast, faster than eTable render...
          return new Promise((resolve) => {
            if (error) {
              const { extra } = event.data;
              const columnEffects = get(extra, 'columnEffects', []) || [];
              const rowEffects = get(extra, 'rowEffects', []) || [];
              const token = get(extra, 'token', '') || '';
              setHeaderRetry({
                columnIds: get(extra, 'columnEffects', []),
                retryToken: extra.token,
                tableId,
              });
              if (rowEffects.length) {
                const [from, to] = [].concat(rowEffects);
                setTimeout(() => {
                  gridApi.forEachNode((node, index) => {
                    if (index >= from && index <= to) {
                      const nodeData = node.data;
                      columnEffects.forEach((i) => {
                        set(nodeData, ['eData', i], {
                          loaded: true,
                          success: false,
                          token: token,
                        });
                      });
                      node.setData(nodeData);
                    }
                  });
                }, 100);
              }
            } else {
              const { extra } = event.data;
              const isPivot = tableModeRef.current === 'pivot';
              const isPivotNext = tableModeRef.current === 'pivotNext';

              if (isPivotNext && Object.keys(cellUpdateByRowId).length > 0) {
                const columnMetricValueDef = get(backbone.getConfig('pivot'), ['metricColumnValue', 0], null);

                const updateByRows = groupBy(flatten(Object.values(cellUpdateByRowId)), 'updatePath.0');

                if (updateByRows[columnMetricValueDef]) {
                  gridApi.forEachNode((node) => {
                    const nodeData = node.data;
                    let updatesArr: any[] = Object.values(cellUpdateByRowId).filter((ls: any) => {
                      const i: any = first(ls);
                      const rowPrimaryKv = get(nodeData, ['primaryKey'], {});

                      return Object.keys(rowPrimaryKv).every((k) => {
                        if (i.keys[k] === undefined) {
                          return true;
                        }
                        return rowPrimaryKv[k] == i.keys[k];
                      });
                    });

                    updatesArr = flatten(updatesArr);

                    if (updatesArr.length) {
                      const updateData = nodeData;
                      updatesArr.forEach((update) => {
                        const eData = updateData.eData;
                        Object.keys(eData).forEach((col) => {
                          const keys = { ...update.keys, _column: first(update.updatePath) };
                          if (isEqual(eData[col]._cell_address, keys)) {
                            Object.keys(update.data).forEach((k) => {
                              set(updateData, ['eData'].concat(col).concat(k), update.data[k]);
                            });
                          } else if (
                            !eData[col]._cell_address &&
                            isEqual(updateData.primaryKey, update.keys) &&
                            col === first(update.updatePath)
                          ) {
                            Object.keys(update.data).forEach((k) => {
                              set(updateData, ['eData'].concat(col).concat(k), update.data[k]);
                            });
                          }
                        });
                      });

                      node.setData(updateData);
                    }
                  });
                }
              } else if (isPivot && Object.keys(cellUpdateByRowId).length > 0) {
                const updateByRows = groupBy(flatten(Object.values(cellUpdateByRowId)), 'updatePath.0');
                gridApi.forEachNode((node, index) => {
                  const nodeData = node.data;
                  const sourceColumn = get(nodeData, 'eData.sourceColumn');
                  if (nodeData) {
                  }
                  let updatedData = nodeData.eData;
                  let havingUpdates = false;
                  if (updateByRows[sourceColumn]) {
                    updateByRows[sourceColumn].forEach(
                      (update: { data: any; keys: Record<string, any>; updatePath: string[] }) => {
                        const updateKeys = update.keys;
                        updatedData = mapValues(updatedData, (value) => {
                          const valuePrimaryKeys = get(value, 'sourcePrimaryKeys', {});
                          const isMatched = Object.entries(updateKeys).every(([k, v]) => {
                            return v == valuePrimaryKeys[k];
                          });
                          if (isMatched) {
                            havingUpdates = true;
                            Object.keys(update.data).forEach((k) => {
                              set(
                                value,
                                ['pivot', 'data', 0, 'eData'].concat(update.updatePath.slice(1).concat(k)),
                                update.data[k],
                              );
                            });
                          }
                          return value;
                        });
                      },
                    );
                  }

                  if (havingUpdates) {
                    node.setData({ ...nodeData, eData: updatedData });
                  }
                });
              } else if (Object.keys(cellUpdateByRowId).length > 0) {
                gridApi.forEachNode((node, index) => {
                  const nodeData = node.data;
                  const updates: any[] = Object.values(cellUpdateByRowId).find((ls: any) => {
                    const i: any = first(ls);
                    return Object.keys(i.keys).every((k) => nodeData[k] === undefined || i.keys[k] == nodeData[k]);
                  });

                  if (updates) {
                    const updateData = nodeData;
                    updates.forEach((update) => {
                      Object.keys(update.data).forEach((k) => {
                        set(updateData, ['eData'].concat(update.updatePath).concat(k), update.data[k]);
                      });
                    });

                    node.setData(updateData);
                  }
                });
                clearHeaderUpdates(get(extra, 'columnEffects', []));
                window.dispatchEvent(
                  new CustomEvent(CALCULATE_TRACTION_WIDTH, {
                    detail: {
                      data: {
                        tableId,
                      },
                    },
                  }),
                );
              }
            }
            resolve(true);
          });
        }, 100);
      };
      getChannelCellUpdate(tableId).addEventListener('message', updateCell);

      return () => {
        getChannelCellUpdate(tableId).removeEventListener('message', updateCell);
      };
    }
  }, [gridApi, lastUpdatedAt]);

  const getCheckboxState = () => {
    let allSelected = true;
    let allNotSelected = true;
    gridApi.forEachNode((node) => {
      if (!node.isSelected() && backbone.addon('checkValidRow', () => true)(node)) {
        allSelected = false;
      }
      if (node.isSelected()) {
        allNotSelected = false;
      }
    });

    return allSelected ? 'checked' : allNotSelected ? 'default' : 'indeterminate';
  };

  React.useEffect(() => {
    if (selectedRows.length > 0 && checkboxState !== 'indeterminate') {
      setCheckboxState('indeterminate');
    }
  }, [selectedRows]);

  // React.useLayoutEffect(() => {
  //   if (!tableDOM.current) return;
  //   window.requestAnimationFrame(() => {
  //     if (!tableDOM.current) return;
  //     const horizontalScroll = tableDOM.current.querySelector('.ag-body-horizontal-scroll-viewport') as HTMLDivElement;
  //     horizontalScroll.classList.remove('ag-hidden');
  //   });
  // }, [tableDOM.current]);
  // useEffect(() => {
  //   log('table first render');
  // }, []);

  const updatePaginationHeight = React.useState()[1].bind(null, {});

  const checkingStateSelectAll = (e: ViewportChangedEvent) => {
    if (checkboxState == 'checked') {
      e.api.forEachNode((node) => {
        if (backbone.addon('checkValidRow', () => true)(node)) {
          if (!node.id?.includes('cloneforloadmore')) {
            node.setSelected(true);
          }
        }
      });
    } else if (checkboxState == 'default') {
      e.api.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };

  const checkingNoRowData = (e: ViewportChangedEvent) => {
    const count = e.api.getDisplayedRowCount();
    if (count == 0) {
      if (isEmpty !== true) setIsEmpty(true);
      return;
    }
    if (isEmpty !== false) setIsEmpty(false);
  };

  const checkingStateGroupChecked = (e: ViewportChangedEvent) => {
    e.api.forEachNode((node) => {
      if (node.parent.rowIndex !== null && node.parent.isSelected() && !node.id?.includes('cloneforloadmore')) {
        node.setSelected(true);
      }
    });
  };

  const bbSelectedRows = React.useCallback(
    batchCall((selectedRows: { node: any; isSelected: boolean }[]) => {
      const onRowSelect = backbone.getCallback('onRowSelect');
      const rows = backbone.getSelectedRows(
        selectedRows && selectedRows.map((i) => ({ data: i.node.data, isSelected: i.isSelected })),
      );

      onRowSelect(rows, backbone);
      // remove additional selected rows
      const filteredSelectedRows = (rows || []).filter((row) => Object.keys(row).length > 1);
      setRowSelected(filteredSelectedRows);

      if (isPromotedObjectTable && filteredSelectedRows.length > 10) {
        selectedRows.forEach((r) => {
          r.node.setSelected(false);
        });
        setMaxSelectedError(true);
      }
      backbone.addon(
        'rowSelect.changeLog.add',
        (i) => i,
      )((selectedRows || []).map((i) => ({ data: i.node.data, isSelected: i.isSelected })));
    }, 100),
    [],
  );

  const handleRowSelect = (rowEvent: RowSelectedEvent) => {
    const state = getCheckboxState();
    if (state !== checkboxState) setCheckboxState(state);
    const onRowSelect = backbone.getCallback('onRowSelect');
    if (onRowSelect) {
      bbSelectedRows({ node: rowEvent.node, isSelected: rowEvent.node.isSelected() });
    }
  };

  const updateCountTotalRowsInTable = React.useCallback(() => {
    if (!gridApi) return;
    let total = 0;
    gridApi
      .getModel()
      .getRootStore()
      .getBlocksInOrder()
      .forEach((block) => {
        total += block.rowNodes.length;
      });
    settotalRowInTable(total);
  }, [gridApi]);

  return (
    <>
      {isPromotedObjectTable && <TableCompactError rowSelected={rowSelected} maxSelectedError={maxSelectedError} />}
      <Box
        className={`etable ag-theme-alpine body-container ${classes.container} ${leftNotEmpty ? 'left-not-zero' : ''} ${
          totalGroupedColumns ? 'total-group-' + totalGroupedColumns : ''
        } ${isShowVerticalLines ? '' : 'hide-vertical-lines'} ${isMinimalTable ? 'minimal-container' : ''}`}
        style={{
          height: '100%',
          flex: 1,
          flexShrink: 0,
          marginTop: isMinimalTable ? 0 : typeTable === 'treemapTable' ? '8px' : '24px',
        }}
        ref={tableDOM}
      >
        <AgGridReact
          rowSelection={multiple}
          tooltipShowDelay={500}
          gridOptions={{
            rowClassRules: {
              'ag-row-disabled': function (params) {
                return get(params, 'node.data.isDisabledRecord', false);
              },
            },
            groupHideOpenParents: false,
            groupSuppressBlankHeader: true,
            groupDisplayType: 'custom',
            // groupSuppressAutoColumn: true,
            frameworkComponents: {
              LoadingCellRenderer: LoadingCellFormat,
              agColumnHeader: isMinimalTable ? EmptyFormat : HeaderCell,
            },
            icons: {
              // use some strings from group
              groupExpanded: groupExpandedIcon,
              groupContracted: groupContracted,
            },
          }}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          getRowHeight={(params) => {
            if (params.node.isFullWidthCell()) {
              return PAGINATION_ROW_HEIGHT;
            }
            if (ff.calculate_on_footer2) {
              if (params.node.isRowPinned()) {
                return PINNED_ROW_HEIGHT;
              }
            }
            return rowHeight;
          }}
          pagination={true}
          suppressPaginationPanel={true}
          suppressScrollOnNewData={true}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          suppressEnterpriseResetOnNewColumns={true}
          suppressColumnVirtualisation={true}
          suppressRowVirtualisation={false}
          suppressMovableColumns={isLockedView && !isEditMode}
          groupSuppressBlankHeader={true}
          animateRows={false}
          showOpenedGroup={true}
          columnDefs={columnDefs}
          onColumnMoved={debounce((columnMove: ColumnMovedEvent) => {
            // FIXME: optimize only when drag stopped to enabled update table backbone
            if (columnMove.source === 'uiColumnDragged') {
              const columnsShow = columnMove.columnApi.getAllDisplayedColumns();
              const arr = [];
              columnsShow.forEach((item: any) => {
                if (item.colId !== 'checkbox') {
                  arr.push(item.colId);
                }
              });
              // when pinnedCols update, columnDefs will get updated,
              // but when changing column order, we haven't updated order for columnDefs yet
              // Check React.useEffect on line 281
              setColumnDefs((columnDefs) => {
                const clonedColumnDefs = cloneDeep(columnDefs);
                return clonedColumnDefs.sort((a, b) => {
                  if (
                    !arr.includes(b.colId) ||
                    b.colId?.startsWith('_pivot_header') ||
                    a.colId?.startsWith('_pivot_header')
                  )
                    return 1;
                  return arr.findIndex((el) => el == a.colId) - arr.findIndex((el) => el == b.colId);
                });
              });
              onColumnReorder(arr);
            }
          }, 1000)}
          onColumnResized={(params) => {
            if (params.finished && ['uiColumnDragged', 'autosizeColumns'].indexOf(params.source) > -1) {
              const columnWidth = params.columnApi.getAllColumns().map((i) => {
                return {
                  columnField: i.getColId(),
                  width: i.getActualWidth(),
                };
              });
              if (params.column) {
                const colId = params.column.getColId();
                if (ff.adoption_rate_table && params.type === 'columnResized' && colId.startsWith('traction_')) {
                  const columnWidths = params.columnApi
                    .getAllColumns()
                    .filter((column) => column.getColId().startsWith('traction_'))
                    .map((column) => ({
                      key: column,
                      newWidth: params.column.getActualWidth(),
                    }));
                  params.columnApi.setColumnWidths(columnWidths);
                  const newColWidths = columnWidths.map((col) => {
                    return {
                      columnField: col.key.getColId(),
                      width: col.newWidth,
                    };
                  });
                  onColumnResize(newColWidths);
                } else {
                  const realColId = String(colId).replace('_grouped', '');
                  const newColWidth = columnWidth.filter((i) => i.columnField !== realColId);
                  newColWidth.push({ columnField: realColId, width: params.column.getActualWidth() });
                  setColumnDefs((cols) => {
                    cols.forEach((col) => {
                      if (col.field === realColId) {
                        col.width = params.column.getActualWidth();
                      }
                    });

                    onColumnResize(newColWidth);
                    return cols;
                  });
                }
              } else if (params.columns) {
                const newColWidths = columnWidth.filter((i) =>
                  params.columns.every((el) => el.getColId() !== i.columnField),
                );
                setColumnDefs((cols) => {
                  params.columns.forEach((el) => {
                    const colId = el.getColId().replace('_grouped', '');
                    const colDefIndex = cols.findIndex((i) => i.field === colId);
                    if (colDefIndex > -1) {
                      set(cols, [colDefIndex, 'width'], el.getActualWidth());
                    }
                    newColWidths.push({ columnField: el.getColId(), width: el.getActualWidth() });
                  });
                  onColumnResize(newColWidths);
                  return cols;
                });
              }
            }
          }}
          cacheBlockSize={pagination.limit}
          paginationPageSize={pagination.limit}
          loadingCellRenderer={'LoadingCellRenderer'}
          loadingCellRendererParams={{
            loadingMessage: isMinimalTable ? '' : 'One moment please ...',
            tableDOM,
            typeTable,
          }}
          rowModelType={'serverSide'}
          serverSideStoreType={'partial' as ServerSideStoreType}
          serverSideDatasource={ssDatasource}
          onGridReady={(params) => {
            backbone.setGridApi({ grid: params.api, column: params.columnApi });
            setColumnApiNext(params.columnApi);
            setGridApi(params.api);
          }}
          onDisplayedColumnsChanged={(params) => {
            // Set min-width for scrollbar;
            if (tableDOM && tableDOM.current.querySelector('.ag-body-horizontal-scroll-container')) {
              const tableWidth = params.columnApi.getAllGridColumns().reduce((a, b) => {
                return b.isVisible() ? a + b.getActualWidth() : a;
              }, 0);
              tableDOM.current.querySelector('.ag-body-horizontal-scroll-container').style.minWidth = `${
                tableWidth + 8
              }px`;
            }
          }}
          onViewportChanged={(e) => {
            checkingStateSelectAll(e);
            checkingStateGroupChecked(e);
            checkingNoRowData(e);
          }}
          getRowNodeId={backbone.getRowId}
          onRowSelected={handleRowSelect}
          onBodyScroll={(e) => {
            // handle scrollbar when scrolling
            // FIXME: remove uneccessary creating handler
            // Only show right scroll bar when scroll direction is vertical
            if (e.direction === 'vertical') {
              if (tableDOM && tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal')) {
                const el = tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal');
                el.classList.add('scroll');
                setTimeout(function () {
                  el.classList.remove('scroll');
                }, 1000);
              }
            } else {
              setLeftNotEmpty(e.left != 0);
            }
            window.dispatchEvent(
              new CustomEvent(CALCULATE_TRACTION_WIDTH, {
                detail: {
                  data: {
                    tableId,
                  },
                },
              }),
            );
            handleBodyScrollNext(e);
          }}
          blockLoadDebounceMillis={60}
          onModelUpdated={() => {
            setLastUpdatedAt({ value: moment() });
            updatePaginationHeight();
            updateCountTotalRowsInTable();
          }}
          getServerSideStoreParams={getServerSideStoreParams}
          isFullWidthCell={(rowNode) => {
            return rowNode.data && rowNode.data._isLoadMore == true;
          }}
          fullWidthCellRenderer={InlineLoadmore}
          {...(ff.calculate_on_footer2 && !ignoreFooterRow ? { pinnedBottomRowData: [{}] } : {})}
        />
      </Box>
      {!isMinimalTable ? (
        <>
          <Box className="footer-container">
            <NextPagination
              tableDOM={tableDOM}
              pageOptions={pageOptions}
              pagination={pagination}
              onChangePagination={onChangePagination}
              totalRowInTable={totalRowInTable}
              isPromotedObjectTable={isPromotedObjectTable}
              rowSelected={rowSelected}
            />
          </Box>
          {isEmpty && tableDOM.current && (
            <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={rowHeight} />
          )}
        </>
      ) : null}
    </>
  );
};
export default Table;

function getServerSideStoreParams(params) {
  const noGroupingActive = params.rowGroupColumns.length == 0;
  let res;

  if (noGroupingActive) {
    res = {
      storeType: 'partial',
      cacheBlockSize: params.api.paginationGetPageSize(),
    };
  } else {
    const topLevelRows = params.level === 0;
    res = {
      storeType: topLevelRows ? 'partial' : 'full',
      cacheBlockSize: params.api.paginationGetPageSize(),
      maxBlocksInCache: -1,
    };
  }
  console.log('getServerSideStoreParams, level = ' + params.level + ', result = ' + JSON.stringify(res));
  return res;
}
