import { aggFuncByKey, getDefaultAggFunc } from '@ep/insight-ui/system/util/aggregation';
import { produce } from 'immer';
import { get, set } from 'lodash';

const isFormulaField = (field) => String(field).indexOf('=') === 0;

function groupSetColumnAggregate(columnId: string, aggFunc: string, backbone: TableBackbone.TableBackboneContextType) {
  const groupBy = backbone.getConfig('groupBy', {});
  const nextGroupBy = produce(groupBy, (draft) => {
    const column = backbone.getCellConfig(columnId);
    const valueGetter = column.valueGetter;
    for (const [valKey, queryField] of Object.entries(valueGetter)) {
      if (isFormulaField(queryField)) continue;

      if (valKey === 'id' || valKey === 'value') {
        set(draft, ['aggregations', queryField as string, 'func'], aggFunc);
      } else {
        // set(draft, ['aggregations', queryField as string, 'func'], aggFuncByKey.none.id);
        set(draft, ['aggregations', queryField as string, 'func'], aggFunc);
      }
    }
    set(draft, ['aggregations', columnId, 'func'], aggFunc);
  });
  backbone.changeConfig('groupBy', nextGroupBy);
}

async function configGroupByDefaultAggregation(
  { value, previousValue },
  config,
  backbone: TableBackbone.TableBackboneContextType,
) {
  const prevColumns = get(previousValue, 'columns', []);
  const columns = get(value, 'columns', []);
  if (prevColumns[0] !== columns[0] && columns[0]) {
    // set default aggregation.
    const mappingList: Record<string, any> = config.mapping;
    const aggregations = {};
    for (const [columnId, mapping] of Object.entries(mappingList)) {
      if (columnId == columns[0]) continue;
      for (const [valKey, queryField] of Object.entries<any>(mapping.valueGetter)) {
        if (isFormulaField(queryField)) continue;
        if (valKey === 'id' || valKey === 'value') {
          const defaultCalculated = get(mapping, ['defaultCalculated'], '');
          aggregations[queryField] = { func: defaultCalculated || getDefaultAggFunc(mapping.propertyType).id };
        } else {
          aggregations[queryField] = { func: aggFuncByKey.none.id };
        }
      }
    }
    return {
      value: {
        columns: columns,
        aggregations: {
          ...aggregations,
          ...config.groupBy.aggregations,
        },
      },
    };
  } else {
    return { value };
  }
}

export const aggregationAddon = {
  'group.setColumnAggregate': groupSetColumnAggregate,
  'config.groupBy': configGroupByDefaultAggregation,
};
