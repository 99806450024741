import * as React from 'react';
import moment from 'moment';
import { get, uniq } from 'lodash';

import { Box, makeStyles, Button } from '@material-ui/core';

import { SwitchIcon } from '@ep/insight-ui/elements/modal/switch';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    paddingLeft: '2px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    paddingLeft: '8px',
  },
  hyphen: {
    border: '1px solid #000000de',
    width: '4px',
    margin: '0 8px',
  },
  selectionContainer: {
    display: 'flex',
    alignItems: 'center',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '8px 16px',
      minWidth: '70px',
    },
    '& .eip1-MuiOutlinedInput-root': {
      borderRadius: '16px',
      background: '#eee',
      '&:hover .eip1-MuiOutlinedInput-notchedOutline': {
        borderColor: '#eee',
      },
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#eee',
    },
  },
  timePickerContainer: {
    boxShadow: 'none',
    '& .rmdp-time-picker': {
      minWidth: '0px !important',
      '& .rmdp-down i': {
        marginTop: '7px',
      },
      '& .rmdp-arrow-container': {
        width: 32,
        height: 16,
        margin: 0,
        alignItems: 'center',
        '& .rmdp-arrow': {
          border: 'solid black',
          borderWidth: ' 0 3px 3px 0',
          borderRadius: '1px',
          height: '6px',
          width: '6px',
          marginTop: -2,
        },
        '&.rmdp-left, &.rmdp-right': {
          top: '50%',
        },
      },
      '& .rmdp-arrow-container:hover': {
        background: 'transparent',
        boxShadow: 'none',
      },
      '& div:nth-child(n+2)': {
        pointerEvents: 'none',
        '& .rmdp-arrow': {
          borderColor: '#E4E7E9',
        },
        '& input': {
          background: '#F6F7F8',
          color: '#C2C7CB',
        },
      },
      '& .dvdr': {
        marginTop: '-2px',
        fontWeight: 500,
      },
      '& div input': {
        marginBottom: '1px',
        fontWeight: 500,
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        outline: 'none',
      },
    },
  },
  dropdown: {
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '3px 0px',
      fontWeight: 500,
      letterSpacing: '0.0125em',
    },
  },
  dropdownPaper: {},
  hourContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '384px',
  },
  hourItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '64px',
    height: '32px',
    flex: '0 0 64px',
    '&:hover': {
      background: '#e4e7e9',
      '&:not(.selected)': {
        borderRadius: '4px',
      },
    },
    cursor: 'pointer',
    '&.range-child': {
      background: '#ebf6ff',
      '& span': {
        color: '#006ec6',
      },
    },
    '&.range-start': {
      background: '#0074d9',
      borderTopLeftRadius: '4px',
      borderBottomLeftRadius: '4px',
      '& span': {
        color: '#ffffff',
      },
    },
    '&.range-end': {
      background: '#0074d9',
      borderTopRightRadius: '4px',
      borderBottomRightRadius: '4px',
      '& span': {
        color: '#ffffff',
      },
    },
  },
  groupButton: {
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '& button + button': {
      marginLeft: '8px',
    },
    textAlign: 'right',
  },
  hourFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '16px',
  },
  timezone: {
    color: '#8c98a4',
    paddingLeft: '14px',
  },
}));

type time = {
  timeStart: string;
  timeEnd: string;
};

interface IPropsCalendarTimeInput {
  checkedHour: boolean;
  setCheckedHour: React.Dispatch<React.SetStateAction<boolean>>;
  time: time;
  setTime: React.Dispatch<React.SetStateAction<time>>;
  values?: any;
}

interface IPropsCalendarHourPicker extends IPropsCalendarTimeInput {
  dates: any;
  open: boolean;
  classes: any;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const CALENDAR_FORMAT = 'MMMM DD, YYYY HH:mm';

const CalendarHourPicker = ({ classes, time, setTime, dates, open, setOpen }: IPropsCalendarHourPicker) => {
  const defaultValue = React.useMemo(() => {
    const { timeStart, timeEnd } = time || {};
    const start = Number((timeStart || '').split(':')[0] || 0);
    const end = Number((timeEnd || '').split(':')[0] || 23);
    return uniq([start, end]);
  }, [time]);
  const [tmpTime, setTmpTime] = React.useState(defaultValue);

  const handleClose = () => {
    setTmpTime(defaultValue);
    setOpen(false);
  };

  const dateFormat = 'YYYY-MM-DD';

  const dateLabel = React.useMemo(() => {
    try {
      let [dateFrom, dateEnd] = dates;
      dateEnd = (dateEnd || dateFrom).format(dateFormat).concat(` ${time.timeEnd || '23:00'}`);
      dateFrom = dateFrom.format(dateFormat).concat(` ${time.timeStart || '00:00'}`);
      const isSameDay = moment(dateFrom).isSame(moment(dateEnd), 'day');
      const isSameDatetime = isSameDay && moment(dateFrom).isSame(moment(dateEnd), 'hour');
      const start = moment(dateFrom).format(CALENDAR_FORMAT);
      const end = moment(dateEnd).format(isSameDay ? 'HH:mm' : CALENDAR_FORMAT);
      return start === end || isSameDatetime ? `${start}` : `${start} - ${end}`;
    } catch (e) {
      return 'Invalid date';
    }
  }, [dates, time]);

  const handleSubmit = () => {
    setTime({
      timeStart: `0${get(tmpTime, [0], 0)}`.slice(-2).concat(':00'),
      timeEnd: `0${get(tmpTime, [1], get(tmpTime, [0], 0))}`.slice(-2).concat(':00'),
    });
    setOpen(false);
  };

  const hourSelectionRender = Array.from({ length: 24 }).map((_, hour) => {
    const timeStart = tmpTime[0];
    const timeEnd = tmpTime[1];
    const isRangeStart = timeStart == hour;
    const isRangeEnd = (!timeEnd && hour == timeStart) || timeEnd == hour;
    const isRangeChild = timeEnd && hour > timeStart && hour < timeEnd;
    const isSelected = isRangeStart || isRangeEnd || isRangeChild;
    return (
      <Box
        key={hour}
        className={`${classes.hourItem} ${isRangeStart ? 'range-start' : ''} ${isRangeEnd ? 'range-end' : ''} ${
          isRangeChild ? 'range-child' : ''
        } ${isSelected ? 'selected' : ''}`}
        onClick={() =>
          setTmpTime((tmpValue) => {
            return tmpValue.length === 2 ? [hour] : tmpValue.concat(hour).sort((a, b) => a - b);
          })
        }
      >
        <span>{`0${hour}`.slice(-2)}:00</span>
      </Box>
    );
  });

  return (
    <Dropdown
      alignMenu="left"
      iconStart={'calendar'}
      sizeIconStart={'12px'}
      className={classes.dropdown}
      label={dateLabel}
      onOpen={() => setOpen(true)}
      onClick={() => undefined}
      defaultOpen={open}
      closeCalendarCohort={() => undefined}
      handleOnClosed={handleClose}
    >
      <Box>
        <Box className={classes.hourContainer}>{hourSelectionRender}</Box>
        <Box className={classes.hourFooter}>
          <Box className={classes.timezone}>Your timezone: {new Date().toString().match(/([A-Z]+[\+-][0-9]+)/)[1]}</Box>
          <Box className={classes.groupButton}>
            <Button variant="text" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Apply
            </Button>
          </Box>
        </Box>
      </Box>
    </Dropdown>
  );
};

const CalendarTimeInput = ({ checkedHour, setCheckedHour, time, setTime, values }: IPropsCalendarTimeInput) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <Box className={classes.container}>
      <Box className={classes.labelContainer}>
        <span>Set Hours:</span>
        <SwitchIcon
          checked={checkedHour}
          onChange={(event) => {
            setCheckedHour(event.target.checked);
            if (event.target.checked) {
              setOpen(true);
            }
          }}
          isCustom={true}
          toggle={checkedHour ? 'on' : 'off'}
        />
      </Box>
      {checkedHour ? (
        <CalendarHourPicker
          classes={classes}
          time={time}
          setTime={setTime}
          dates={values}
          open={open}
          setOpen={setOpen}
        />
      ) : null}
    </Box>
  );
};

export default CalendarTimeInput;
