import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, Typography } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { ETypographyProp } from '../text-style/Typography';

/* Type */
type HeaderListProps = {
  title: string;
  addOn?: React.ReactNode;
  onClickAddOn?: () => void;
  disabled?: boolean;
  variant?: ETypographyProp['variant'];
  className?: string;
};

/** Style */
const useStyles = makeStyles(() => ({
  addOnContainer: {
    textAlign: 'right',
    flexGrow: 0.3,
  },
  title: {
    paddingLeft: '7px',
  },
  header: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    marginBottom: '6px',
    paddingLeft: '8px',
  },
  addOnBtn: {
    padding: '5px',
    '&:focus': {
      backgroundColor: 'transparent',
      color: colors.icon.default,
    },
  },
}));

const HeaderList: React.FC<HeaderListProps> = ({
  title,
  variant = 'h6',
  addOn,
  onClickAddOn,
  disabled,
  className,
}: HeaderListProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={`${classes.header} ${className}`}
    >
      <Grid item xs>
        <Typography variant={variant}>{title}</Typography>
      </Grid>
      {addOn && (
        <Grid item xs className={classes.addOnContainer}>
          {addOn && (
            <Button
              disabled={disabled}
              variant="outlined"
              className={classes.addOnBtn}
              onClick={() => onClickAddOn && onClickAddOn()}
            >
              <Icon type="plus" size="12px" />
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderList;
