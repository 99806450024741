import hash from 'object-hash';
import { useLog } from '../../lib/log';

const log = useLog('dbf:cache');

export function buildCacheKey(obj) {
  let cachedKey = hash(obj, {
    unorderedArrays: true,
    unorderedSet: true,
    unorderedObject: true,
  });

  log('cachekey', cachedKey, obj);

  return cachedKey;
}

export function makeCache() {
  const caches = {};

  return function cacheit(key: string, cacheFun) {
    if (caches[key] !== undefined) {
      log('serve cached data', key, caches[key]);
      return caches[key];
    }
    return cacheFun((val) => {
      caches[key] = val;
    });
  };
}
