import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import './MentionList.css';

const MentionList = forwardRef((props: any, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      if (item.id) {
        props.editor
          .chain()
          .deleteRange({
            from: props.range.from + 1,
            to: props.range.to,
          })
          .insertContent(item.id)
          .run();
      } else {
        props.command({
          id: `${item.label} ${item.date}: ${item.value}`,
        });
      }
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className="items">
      {props.items.map((item, index) => (
        <button
          className={`item ${index === selectedIndex ? 'is-selected' : ''}`}
          key={index}
          onClick={() => selectItem(index)}
        >
          <div>
            <div>
              {item.id ? (
                <span>{item.label}</span>
              ) : (
                <>
                  <span>{item.date}</span>
                  <span> - {item.value}</span>
                </>
              )}
            </div>
          </div>
        </button>
      ))}
    </div>
  );
});

MentionList.displayName = 'MentionList';

export default MentionList;
