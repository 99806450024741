import * as React from 'react';
import { get } from 'lodash';

import { Box, makeStyles, Button, Popover, ButtonGroup } from '@material-ui/core';

import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { useItemAction } from './hook/use-item-action';

const useStyles = makeStyles((theme) => ({
  buttonAction: {
    '& .eip1-MuiTypography-body2': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    height: '24px',
  },
  singleButton: {
    height: '24px',
    whiteSpace: 'nowrap',
  },
}));

const ItemAction = ({ actions, data }) => {
  const classes = useStyles();

  const { ref, anchorEl, handleClose, mappedActions, Component, propsComponent } = useItemAction({ actions, data });

  if (!mappedActions) return null;

  return (
    <Box mr={2} ref={ref}>
      <ButtonGroup variant="contained" color="primary">
        <Button
          variant="contained"
          color="primary"
          onClick={get(mappedActions, ['0', 'onClick'], () => undefined)}
          className={classes.singleButton}
          disabled={get(mappedActions, ['0', 'disable'], false)}
        >
          {get(mappedActions, ['0', 'title'], '')}
        </Button>
        {mappedActions.length > 1 ? (
          <Dropdown
            alignMenu="right"
            disabledToggleStyle
            icon={'chevron'}
            sizeIcon={'10px'}
            className={classes.buttonAction}
            buttonProps={{ color: 'primary', variant: 'contained' }}
            disabledClickContentClose={false}
          >
            <MenuList closeAfterClick listMenu={[mappedActions.slice(1)]} />
          </Dropdown>
        ) : null}
      </ButtonGroup>
      {Component ? (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Component {...propsComponent} />
        </Popover>
      ) : null}
    </Box>
  );
};

export default ItemAction;
