import { RowNode } from '@ag-grid-community/core';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, Button, makeStyles } from '@material-ui/core';
import MuiChip from '@material-ui/core/Chip';
import { withStyles } from '@material-ui/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { TableCompactFactoryContext } from '../../compact-factory2';
import { IConfigTable, makeTable } from '../../table-container2';

const useStyle = makeStyles(() => ({
  inputDropdown: {
    '& input': {
      padding: '0px',
      height: '32px',
      marginLeft: '5px',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      '& .eip1-MuiInputAdornment-root': {
        position: 'absolute',
        right: 0,
        padding: '11px',
        margin: '0px',
        cursor: 'pointer',
        height: 'auto',
      },
      '& .eip1-MuiInputBase-input': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
    },
    '& input[type=text]:focus': {
      boxShadow: 'none',
    },
  },
  checkbox: {
    marginRight: '-13px',
    width: '100%',
    justifyContent: 'flex-end',
    backgroundColor: 'transparent !important',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  paper: {
    left: '95px !important',
    width: 'fix-content',
  },
  totalInput: {
    '&:hover': {
      borderColor: '#006EC6',
    },
    position: 'relative',
    width: '100%',
    height: '32px',
    borderRadius: '4px',
    border: 'solid 1px #C2C7CB',
    display: 'flex',
    backgroundColor: 'white',
    '& .dropdown': {
      width: '100%',
    },
  },
  disabled: {
    color: '#C2C7CB',
    background: '#F6F7F8',
    borderColor: '#E4E7E9',
    '&:hover': {
      borderColor: '#E4E7E9',
    },
    '& p': {
      color: '#C2C7CB',
    },
  },
  noBorder: {
    border: 'none',
  },
  listItem: {
    // listStyleType: 'none',
    display: 'flex',
  },
  icon: {
    '&.popupIcon': {
      width: '8px',
      height: '8px',
      display: 'flex',
      alignItems: 'center',
      color: '#3F4F5C',
    },
    '&.deleteIcon': {
      width: '10px',
      height: '10px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  position: {
    '&.iconClose': {
      right: '17px',
      marginTop: '2px',
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
    },
    '&.popupIcon': {
      position: 'absolute',
      right: '10px',
      transform: 'translateY(-50%)',
      top: '50%',
      pointerEvents: 'none',
    },
  },
  placeHolder: {
    position: 'absolute',
    padding: '6px',
    color: colors.text.subdued,
  },
}));
const Chip = withStyles(() => ({
  root: {
    height: '24px',
    left: '0px',
    top: '0px',
    background: '#F6F7F8',
    borderRadius: '4px',
    alignItems: 'center',
    border: 0,
    marginRight: '3px',
    marginLeft: '1px',
    justifyContent: 'flex-start',
    whiteSpace: 'unset',
  },
  deleteIcon: {
    '& svg': {
      color: '#253746',
    },
  },
  label: {
    // display: 'inline-grid',
  },
}))(MuiChip);

export interface ISelectBoxCompact {
  displayField: string;
  selectedRows: any[];
  setRows: (value: any[]) => void;
  config: IConfigTable | any;
  placeHolder?: string;
  useSubmitOnClose?: boolean;
  disabled?: boolean;
}
const SelectBoxCompact = ({
  displayField = '',
  setRows = () => undefined,
  selectedRows = [],
  config = {},
  placeHolder = '',
  useSubmitOnClose = false,
  disabled = false,
}: ISelectBoxCompact) => {
  const { display: displayTable } = React.useContext(TableCompactFactoryContext);

  const myInput = React.useRef<HTMLDivElement>();
  const classes = useStyle();
  const rowsTemp = React.useRef(selectedRows || []);
  const isSubmitOnClosed = React.useMemo(() => {
    return config.submitRowSelectionOnClosed || useSubmitOnClose;
  }, [config]);
  const handleDelete = (chipToDelete) => () => {
    const rowUpdate = selectedRows.filter((item) => !_.isEqual(chipToDelete, item));
    rowsTemp.current = [].concat(rowUpdate);
    setRows(rowUpdate);
    // setSelected(selected.filter((item) => !_.isEqual(chipToDelete, item)));
  };
  const [limit, setLimit] = React.useState<number>(1);
  const callbackRef = React.useRef<any[]>([]);

  const onRowSelectedModal = (rows: { node: RowNode; isSelected: boolean; isPresent: boolean }[], backbone) => {
    const selectedRows = backbone.getSelectedRows(
      rows.map((row) => ({ data: row.node.data, isSelected: row.isSelected })),
    );
    backbone.updateSelectedRows(selectedRows);
    rowsTemp.current = selectedRows;
    backbone.addon('addRowChangeListeners', (selectedRows) => {
      rowsTemp.current = selectedRows;
    })(selectedRows);
    callbackRef.current.forEach((listener) => {
      listener(selectedRows);
    });
  };

  const handleOnClosed = () => {
    rowsTemp.current = selectedRows;
  };

  const handleOnSubmit = () => {
    setRows(rowsTemp.current);
  };

  const configurationHandle = React.useMemo(() => {
    if (isSubmitOnClosed) {
      return {
        ...config,
        callback: {
          ...config.callback,
          onRowSelect: null,
          onRowSelectNext: onRowSelectedModal,
        },
        addons: {
          ...config.addons,
          'compact.getSelectedRows': () => rowsTemp.current,
          'compact.addSelectedChangeListener': (callback) => {
            callback(rowsTemp.current);
            callbackRef.current.push(callback);
          },
          getRowId: (row, primaryKeys, ignoreKeys = []) => {
            return []
              .concat(primaryKeys)
              .filter((i) => ignoreKeys.indexOf(i) === -1)
              .map((i) => {
                return row[i];
              })
              .join('|');
          },
        },
      };
    }
    return {
      ...config,
      addons: {
        ...config.addons,
        'compact.getSelectedRows': () => rowsTemp.current,
        onRowSelectNext: onRowSelectedModal,
        getRowId: (row, primaryKeys, ignoreKeys) => {
          return []
            .concat(primaryKeys)
            .filter((i) => ignoreKeys.indexOf(i) === -1)
            .map((i) => {
              return row[i];
            })
            .join('|');
        },
      },
    };
  }, [config]);
  const configTable = {
    config: configurationHandle,
    selectedRows: selectedRows,
  };
  const tableRender = <>{makeTable(configTable)}</>;
  const displayTableCompact = (visible: boolean) => {
    displayTable({
      anchorEl: myInput.current,
      tableConfig: { config },
      tableEl: tableRender,
      visible: visible,
      onClosed: handleOnClosed,
      onSubmit: handleOnSubmit,
    });
  };

  const RenderChip = () => {
    return (
      <React.Fragment>
        {selectedRows.map((data, index) => {
          return index < limit ? (
            <Chip
              style={{ width: '100%' }}
              key={data.key}
              deleteIcon={
                <div
                  className={`${classes.icon} deleteIcon`}
                  onClick={handleDelete(data)}
                  style={{ height: '10px', width: '10px' }}
                >
                  <Icon type={'deleteChip'} style={{ height: '100%', width: '100%' }} />
                </div>
              }
              label={
                <div
                  style={{
                    width: '100%',
                    fontStyle: 'normal',
                    fontWeight: 'normal',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#253746',
                    overflow: ' hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  {_.get(data, [displayField], '')}
                </div>
              }
              onDelete={handleDelete(data)}
              disabled={disabled}
            />
          ) : null;
        })}
        {limit && selectedRows.length > limit && (
          <Chip variant="outlined" disabled={disabled} label={`+ ${selectedRows.length - limit}`} />
        )}
      </React.Fragment>
    );
  };

  const handleDisplayChip = () => {
    const lengthInput = _.get(myInput, ['current', 'offsetWidth'], 0) - 120;
    const children = myInput.current.children[0].children[0].children || [];

    const max = children.length > 0 ? _.maxBy(children, 'offsetWidth').offsetWidth : lengthInput;
    const newLimit = Math.floor(lengthInput / max) || 1;

    if (limit === newLimit) return;
    setLimit(Math.floor(lengthInput / max));
  };

  const PlaceHolder = () => {
    return (
      <Typography variant="body2" className={clsx(classes.placeHolder)}>
        {placeHolder}
      </Typography>
    );
  };

  React.useEffect(() => {
    handleDisplayChip();
  }, [selectedRows]);

  const handler = _.debounce(handleDisplayChip, 1000);

  React.useLayoutEffect(() => {
    if (!myInput.current) return;
    const re = new ResizeObserver(handler);

    re.observe(myInput.current);

    return () => re.disconnect();
  }, [myInput]);

  return (
    <div ref={myInput}>
      <div className={`${classes.totalInput} ${disabled && classes.disabled}`}>
        <Box display={'flex'} alignItems={'center'} maxWidth={'80%'} flexDirection={'row'}>
          {selectedRows.length > 0 && RenderChip()}
        </Box>
        {placeHolder && selectedRows.length === 0 && PlaceHolder()}
        <Button
          size="small"
          onClick={() => displayTableCompact(true)}
          className={classes.checkbox}
          disabled={disabled}
        ></Button>
        <span className={`${classes.icon} popupIcon ${classes.position} popupIcon`}>
          <Icon
            type={'chevron'}
            colorIcon={disabled ? '#8c98a4' : '#3F4F5C'}
            style={{ height: '100%', width: '100%' }}
          />
        </span>
      </div>
    </div>
  );
};
export default SelectBoxCompact;
