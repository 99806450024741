import Icon from '@ep/insight-ui/icons/Icon';
import { makeStyles, Radio, RadioProps } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:hover ~ &': {
      backgroundColor: '#ebf1f5',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#006EC6',
    boxShadow: 'inset 0 0 0 1px #006EC6, inset 0 -1px 0 #006EC6',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#006EC6',
    },
  },
  radioIcon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
      pointerEvents: 'none',
    },
  },
});
type IRadioComponent = {
  icon: 'radio' | 'radioBlank';
  disabled?: boolean;
};
export const RadioComponent = ({ icon = 'radioBlank', disabled = false }: IRadioComponent) => {
  const classes = useStyles();
  return <Icon type={icon} className={`${classes.radioIcon} ${disabled ? 'icon_disabled' : null}`} />;
};

function StyledRadio(props: RadioProps) {
  const classes = useStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

export default StyledRadio;
