import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import { Box, makeStyles, Modal } from '@material-ui/core';
import { get, uniq } from 'lodash';
import React from 'react';
import ButtonAction from '../button/button-action';
import AccountInformationForm from './account-information-form';
import SelectMarketplaceForm from './select-marketplace-form';
import StoreConnected from './store-connected';
import StorefrontIntegrateForm from './storefront-integrate-form';
import VerifyAccountForm from './verify-account-form';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import clsx from 'clsx';
import { Dialog, Button, DialogActions } from '@material-ui/core';

const API_LINKSHOP = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/link-store';
const API_GET_CHANNEL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-channel';
const API_GET_COUNTRY = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-country';
const API_CALLBACK_STORE = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/check-callback-link-store';

const useStyles = makeStyles({
  tableWrapper: {
    padding: 8,
    width: '100%',
    border: '1px solid #d0d0d0',
  },
  container: {
    padding: '0 48px 48px',
  },
  treemap: {
    padding: '6px',
    borderRadius: '16px',
    border: '0.5px solid #C2C7CB',
    marginBottom: '24px',
  },
  toggle: {},
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
  popupModalLast: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    borderRadius: '8px',
    width: '547px',
    padding: '32px 32px 24px 32px',
    textAlign: 'center',
  },
  popupModalMobile: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100vh',
    background: ff.integrate_storefront_ui_v2 ? '#FFF' : '#F6F9FF',
    padding: ff.integrate_storefront_ui_v2 ? '0' : '20px 16px 16px 16px',
  },
  popupModalLastMobile: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: ff.integrate_storefront_ui_v2 ? '#FFF' : '#F6F9FF',
    width: '100%',
    height: '100vh',
    padding: '20px 16px 16px 16px',
    textAlign: 'center',
  },
  ipadContent: {
    width: '90%',
  },
  dialog: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& .eip1-MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
  },
  iframe: {
    width: '700px',
    height: '1000px',
  },
  buttonDialog: {
    position: 'absolute',
    top: '5px',
    right: '10px',
  },
});

const DEFAULT_FLOW_VALUES = {
  marketplace: '',
  country: '',
  username: '',
  password: '',
  otp: '',
  storeLists: [],
};

export const StorefrontIntegration = ({ buttonText = 'Connect your stores' }: { buttonText: string }) => {
  const classes = useStyles();
  const [flowValues, setFlowValues] = React.useState(DEFAULT_FLOW_VALUES);
  const [optionList, setOptionList] = React.useState({
    marketplaces: [],
    countries: [],
  });
  // open / close popup
  const [open, setOpen] = React.useState(false);

  const steps = {
    chooseMarketplace: 1,
    fillMarketplaceCredential: 2,
    fillAccountOtp: 3,
    chooseStores: 4,
    end: 5,
  };
  const errors = {
    ERROR_NEED_OTP: 401,
    ERROR_INVALID_OTP: 402,
    ERROR_INCORRECT_ACCOUNT: 403,
    ERROR_NETWORK: 404,
  };

  const [step, setStep] = React.useState(1);
  const [stepHistory, setStepHistory] = React.useState([1]);
  const [loading, setLoading] = React.useState(false);
  const [messageError, setMessageError] = React.useState<string>('');
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;
  const isIPad = containerClass === 'eres--medium';

  const [redirectLink, setRedirectLink] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [timeCallback, setTimeCallBack] = React.useState(0);
  const [paramCallback, setParamCallback] = React.useState({});
  const [messageErrorCallback, setMessageErrorCallback] = React.useState('');
  const timeCallbackTimeout = 90;

  React.useEffect(() => {
    let result;
    const timeout = setTimeout(() => {
      if (timeCallback > 0 && openDialog) {
        const callbackCheck = async () => {
          result = await request.get(API_CALLBACK_STORE, {
            sid: paramCallback.sids[0],
          });
          if (result.serviceResponse) {
            if (result.serviceResponse.code === 0 || result.serviceResponse.success) {
              moveNextStep(steps.end);
              setOpenDialog(false);
              setTimeCallBack(0);
            }
            if (result.serviceResponse.code === 1000) {
              setMessageErrorCallback(result.serviceResponse.message);
              setOpenDialog(false);
              setTimeCallBack(0);
            }
          }
        };
        setTimeCallBack(timeCallback - 3);
        callbackCheck();
      } else {
        setOpenDialog(false);
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeCallback]);
  React.useEffect(() => {
    Promise.all([request.get(API_GET_CHANNEL, {}), request.get(API_GET_COUNTRY, {})])
      .then(([maketplaceRes, countryRes]) => {
        const checkingOb = {};
        get(maketplaceRes, 'data', [])
          .map((channel) => {
            let countries = [];
            channel.sites.map((side) => {
              countries = countries.concat(side.countries);
            });
            return { id: channel.code, text: channel.name, countries: countries.map((item) => item.code) };
          })
          .map((item) => {
            if (checkingOb[item.id]) {
              checkingOb[item.id].payload.countries = uniq(
                checkingOb[item.id].payload.countries.concat(item.countries),
              );
            } else {
              checkingOb[item.id] = {
                value: item.id,
                label: item.text,
                payload: {
                  countries: item.countries,
                },
              };
            }
          });
        const marketplaces = Object.values(checkingOb);

        const countries = countryRes.data.map((i) => ({
          value: i.code,
          label: i.name,
        }));
        setOptionList({
          marketplaces,
          countries,
        });
      })
      .catch((err) => {
        console.log('Get options fail', err);
      });
  }, []);

  const handleOpen = () => {
    setStep(1);
    setStepHistory([1]);
    setFlowValues(DEFAULT_FLOW_VALUES);
    setOpen(true);
    setMessageError('');
  };
  const handleClose = () => setOpen(false);

  const submitStep = async (step, values) => {
    let result = null;
    const reqParam = {
      channel: {
        code: flowValues.marketplace,
        sites: [
          {
            code: 'SELLER_CENTER',
            countries: [{ code: flowValues.country }],
          },
        ],
      },
      userName: flowValues.username,
      password: flowValues.password,
    };
    switch (step) {
      case steps.fillMarketplaceCredential: {
        result = await request.post(API_LINKSHOP, {
          ...reqParam,
          userName: values.username,
          password: values.password,
        });
        if (!result.serviceResponse.success) throw result;

        setFlowValues(() => ({
          ...flowValues,
          username: values.username,
          password: values.password,
          storeLists: result.listStore,
        }));
        moveNextStep(steps.chooseStores);
        break;
      }
      case steps.fillAccountOtp: {
        result = await request.post(API_LINKSHOP, {
          ...reqParam,
          otp: values.otp,
        });
        if (!result.serviceResponse.success) throw result;

        setFlowValues(() => ({
          ...flowValues,
          otp: values.otp,
          storeLists: result.listStore,
        }));
        moveNextStep(steps.chooseStores);
        break;
      }
      case steps.chooseStores: {
        result = await request.post(API_LINKSHOP, {
          ...reqParam,
          otp: flowValues.otp,
          sids: values.storefronts.map((i) => i.value),
        });
        if (result.redirectUrl) {
          setOpenDialog(true);
          setRedirectLink(result.redirectUrl);
          setParamCallback(() => ({
            ...reqParam,
            otp: flowValues.otp,
            sids: values.storefronts.map((i) => i.value),
          }));
          setTimeCallBack(timeCallbackTimeout);
        }
        if (!result.serviceResponse.success) throw result;

        moveNextStep(steps.end);
        break;
      }
      default: {
        setStep(step + 1);
        setStepHistory([...stepHistory, step + 1]);
      }
    }
    return result;
  };

  const moveNextStep = (nextStep) => {
    setStep(nextStep);
    if (!stepHistory.includes(nextStep)) {
      setStepHistory([...stepHistory, nextStep]);
    }
  };

  const handleNextStep = async (step, values) => {
    try {
      setLoading(true);
      await submitStep(step, values);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.message) {
        setMessageError('Something went wrong! Please try again.');
      }
      const errorCode = error.serviceResponse.code;
      switch (errorCode) {
        case errors.ERROR_NEED_OTP: {
          moveNextStep(steps.fillAccountOtp);
          break;
        }
        case errors.ERROR_INVALID_OTP: {
          // TODO: show error message;
          moveNextStep(steps.fillAccountOtp);
          setMessageError(error.serviceResponse.message);
          break;
        }
        case errors.ERROR_INCORRECT_ACCOUNT: {
          // TODO: show error message
          moveNextStep(steps.fillMarketplaceCredential);
          setMessageError(error.serviceResponse.message);
          break;
        }
        case errors.ERROR_NETWORK:
        default: {
          // TODO: show error message
          setMessageError(error.serviceResponse.message);
          break;
        }
      }
    }
  };

  const handleBackStep = () => {
    const newStepHistory = stepHistory.slice(0, stepHistory.length - 1);
    setStepHistory(newStepHistory);
    setStep(newStepHistory[newStepHistory.length - 1]);
    setMessageError('');
  };

  const handleResendOTP = async () => {
    await request.post(API_LINKSHOP, {
      channel: {
        code: flowValues.marketplace,
        sites: [
          {
            code: 'SELLER_CENTER',
            countries: [{ code: flowValues.country }],
          },
        ],
      },
      userName: flowValues.username,
      password: flowValues.password,
    });
  };

  return (
    <div>
      <ButtonAction
        icon="plus"
        label={buttonText}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={handleOpen}
      />
      <Modal open={open}>
        <>
          <Box
            className={
              step === 5
                ? clsx({
                    [classes.popupModalLastMobile]: isMobile,
                    [classes.popupModalLast]: !isMobile,
                  })
                : clsx({
                    [classes.popupModalMobile]: isMobile,
                    [classes.popupModal]: !isMobile,
                    [classes.ipadContent]: isIPad,
                  })
            }
          >
            {step === 1 && (
              <SelectMarketplaceForm
                loading={loading}
                initialValues={flowValues}
                optionList={optionList}
                setInitialValues={setFlowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.chooseMarketplace, values);
                }}
              />
            )}
            {step === 2 && (
              <AccountInformationForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.fillMarketplaceCredential, values);
                }}
                onBackStep={handleBackStep}
                messageError={messageError}
              />
            )}
            {step === 3 && (
              <VerifyAccountForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.fillAccountOtp, values);
                }}
                onResendOTP={handleResendOTP}
                onBackStep={handleBackStep}
                messageError={messageError}
              />
            )}
            {step === 4 && (
              <StorefrontIntegrateForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => handleNextStep(steps.chooseStores, values)}
                onBackStep={handleBackStep}
                messageError={messageError}
                messageErrorCallback={messageErrorCallback}
              />
            )}
            {step === 5 && <StoreConnected onClose={handleClose} />}
          </Box>
          <Dialog className={classes.dialog} open={openDialog} onClose={() => setOpenDialog(false)}>
            <DialogActions>
              <Button className={classes.buttonDialog} onClick={() => setOpenDialog(false)}>
                X
              </Button>
            </DialogActions>
            <iframe className={classes.iframe} src={redirectLink} frameBorder="0"></iframe>
          </Dialog>
        </>
      </Modal>
    </div>
  );
};
