import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Heading3 = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="2 2 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="3" width="2" height="10" rx="1" fill={props.color} />
    <rect x="6" y="3" width="2" height="10" rx="1" fill={props.color} />
    <rect x="12" y="3" width="2" height="6" rx="1" fill={props.color} />
    <rect x="12" y="7" width="2" height="6" rx="1" fill={props.color} />
    <rect x="2" y="9" width="2" height="6" rx="1" transform="rotate(-90 2 9)" fill={props.color} />
    <path
      d="M10 5C9.44772 5 9 4.55228 9 4C9 3.44772 9.44772 3 10 3L13 3C13.5523 3 14 3.44772 14 4C14 4.55228 13.5523 5 13 5L10 5Z"
      fill={props.color}
    />
    <path
      d="M10 9C9.44772 9 9 8.55228 9 8C9 7.44772 9.44772 7 10 7L13 7C13.5523 7 14 7.44772 14 8C14 8.55228 13.5523 9 13 9L10 9Z"
      fill={props.color}
    />
    <path
      d="M10 13C9.44772 13 9 12.5523 9 12C9 11.4477 9.44772 11 10 11L13 11C13.5523 11 14 11.4477 14 12C14 12.5523 13.5523 13 13 13L10 13Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Heading3;
