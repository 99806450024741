import * as React from 'react';
import { useSetAtom } from 'jotai';
import clsx from 'clsx';

import { TextField, IconButton, makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { FormulaEditor } from '@ep/insight-ui/system/block/etable/etable-config/formula-editor';
import { formulaPopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-formula';
import { debounce } from 'lodash';

const useStyles = makeStyles(() => ({
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& textarea': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiOutlinedInput-multiline': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
    marginBottom: '8px',
  },
  inputIcon: {
    marginLeft: '12px',
  },
}));

interface IPropsTextFieldWithFormula {
  supportFormula?: boolean;
  initialValue?: string;
  availVars?: string[];
  onValueChange?: (value: string) => void;
  mapping?: any;
  autoFocus?: boolean;
}

const TextFieldWithFormula = ({
  supportFormula = false,
  initialValue = '',
  availVars = [],
  onValueChange = () => undefined,
  mapping = {},
  autoFocus,
}: IPropsTextFieldWithFormula) => {
  const classes = useStyles();

  const inputRef = React.useRef(null);
  const [inputValue, setInputValue] = React.useState<string>(initialValue);

  const displayFormula = useSetAtom(formulaPopup);

  const handleSetText = React.useCallback(debounce(onValueChange, 100), []);
  React.useEffect(() => {
    handleSetText(inputValue);
  }, [inputValue]);

  return (
    <div>
      <TextField
        inputRef={inputRef}
        multiline={supportFormula}
        minRows={1}
        maxRows={10}
        className={clsx(classes.textStyle, classes.textSearch)}
        value={inputValue}
        onChange={(e) => {
          setInputValue(e.target.value);
        }}
        variant="outlined"
        autoFocus={autoFocus}
        InputProps={{
          endAdornment: (
            <React.Fragment>
              <IconButton
                onClick={() => {
                  setInputValue('');
                  inputRef.current.focus();
                }}
                style={{ opacity: inputValue ? 1 : 0 }}
              >
                <Icon type={'closeCircle'} />
              </IconButton>
              {supportFormula ? (
                <IconButton
                  onClick={(e) => {
                    displayFormula({
                      isDisplay: true,
                      content: inputValue,
                      ref: e.target,
                      backboneConfig: { mapping },
                      availableVars: availVars,
                      submit: (content) => {
                        setInputValue(content);
                        setTimeout(() => {
                          inputRef.current.focus();
                        }, 0);
                      },
                    });
                  }}
                >
                  <Icon type={'rmx/functions-icon/blue6'} />
                </IconButton>
              ) : null}
            </React.Fragment>
          ),
          classes: { input: classes['input'] },
          fullWidth: true,
        }}
      />
      <FormulaEditor />
    </div>
  );
};

export default TextFieldWithFormula;
