import * as React from 'react';
import { debounce, get, last } from 'lodash';
import { DateObject } from 'react-multi-date-picker';
import moment from 'moment';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { defaultPeriodConfig } from '@ep/insight-ui/system/block/etable/etable-config/utils/action-advance-configs/period';
import { DATETIME_PICKER, DATETIME_REGEX } from '@ep/insight-ui/sw/etable/constant';

export const usePeriodUpdate = (props) => {
  const backbone = React.useContext(TableBackboneContext);
  const fieldTarget = props.field;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const mapping = backbone.getConfig('mapping');
  const calendarConfig = backbone.getConfig('calendarConfig', {});
  const calendarTypePicker = get(calendarConfig, 'typePicker', 'default');

  const tractionPeriod = get(backbone.getConfig('personalization'), ['tractionPeriod'], {});
  const customValue = Object.values(get(tractionPeriod, [props.field, 'customValue'], {})).sort();
  const yesterday = moment().subtract(1, 'day').format('YYYY-MM-DD');
  const [currentValue, setCurrentValue] = React.useState(customValue.length ? customValue : [yesterday, yesterday]);

  const [calendarValue, setCalendarValue] = React.useState(() => {
    return currentValue.map((val) => new DateObject(moment(val).format('YYYY-MM-DD')));
  });

  const isDatetimePicker = calendarTypePicker === DATETIME_PICKER;

  const [checkedHour, setCheckedHour] = React.useState(() => {
    return isDatetimePicker && DATETIME_REGEX.test(currentValue[0]);
  });
  const [time, setTime] = React.useState(() => {
    const [dateFrom, dateTo] = currentValue;
    const timeStart = dateFrom?.match(/\d{2}:\d{2}/)?.[0] || '00:00';
    const timeEnd = dateTo?.match(/\d{2}:\d{2}/)?.[0] || '23:00';

    return {
      timeStart,
      timeEnd,
    };
  });

  const datetimeChecked = React.useMemo(() => {
    return isDatetimePicker && checkedHour;
  }, [isDatetimePicker, checkedHour]);

  React.useEffect(() => {
    if (datetimeChecked) {
      const newValues = getNewDatesWithTime(calendarValue, time);

      handleChangeDateDebounce(newValues);
    }
  }, [time, datetimeChecked]);

  const handleChangeDate = (selectedDates: DateObject[]) => {
    const newValues = datetimeChecked ? getNewDatesWithTime(selectedDates, time) : selectedDates;
    setCalendarValue(newValues);
  };

  const getNewDatesWithTime = (dates, time) => {
    let [datetimeStart, datetimeEnd]: string | DateObject[] = dates;
    if (!datetimeEnd) {
      datetimeEnd = datetimeStart;
    }
    const format = 'YYYY-MM-DD';
    datetimeStart = datetimeStart.format(format).concat(' ' + time.timeStart);
    datetimeEnd = datetimeEnd.format(format).concat(' ' + time.timeEnd);

    const newValues = [datetimeStart, datetimeEnd].map(
      (time) => new DateObject({ date: time, format: 'YYYY-MM-DD HH:mm' }),
    );
    return newValues;
  };

  const handleChangeDateDebounce = React.useCallback(debounce(handleChangeDate, 100), [time]);

  const options = get(props, 'payload', []).map((option) => ({
    label: defaultPeriodConfig.find((period) => period.value === option.value)?.label || option.label,
    onClick(event) {
      if (option.value === 'custom') {
        setAnchorEl(event.target);
      } else {
        const fieldTractionPeriod = Object.keys(backbone.getConfig('mapping')).reduce((carry, field) => {
          if (mapping[field].cellFormat !== mapping[fieldTarget].cellFormat) return carry;

          return {
            ...carry,
            [field]: {
              ...option,
              value: option.value.toLowerCase(),
            },
          };
        }, {});
        const personalization = backbone.getConfig('personalization');
        const currentTractionPeriod = get(personalization, ['tractionPeriod'], {});
        backbone.changeConfig('personalization', {
          ...personalization,
          tractionPeriod: { ...currentTractionPeriod, ...fieldTractionPeriod },
        });
        // packages/insight-ui/system/backbone/table-backbone/next-table-backbone.ts -> line 547 prevent auto reload table
        backbone.reloadData('table');
        props.setAnchorEl(null);
      }
    },
    value: option.value.toLowerCase(),
  }));

  const handleCloseCalendar = () => {
    setCurrentValue(calendarValue);
    setAnchorEl(null);
  };

  const handleSubmitCalendar = () => {
    const personalization = backbone.getConfig('personalization');
    const currentTractionPeriod = get(personalization, ['tractionPeriod'], {});
    const fieldTractionPeriod = Object.keys(backbone.getConfig('mapping')).reduce((carry, field) => {
      if (mapping[field].cellFormat !== mapping[fieldTarget].cellFormat) return carry;

      const format = datetimeChecked ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
      const start =
        get(calendarValue, [0], moment().subtract(1, 'day')).format(format) + (datetimeChecked ? ':00.000' : '');
      const end =
        get(calendarValue, [1], get(calendarValue, [0], moment().subtract(1, 'day'))).format(format) +
        (datetimeChecked ? ':00.000' : '');

      return {
        ...carry,
        [field]: {
          ...get(props, 'payload', []).find(({ value }) => value === 'custom'),
          customValue: {
            dateFrom: start,
            dateTo: end,
          },
        },
      };
    }, {});
    backbone.changeConfig('personalization', {
      ...personalization,
      tractionPeriod: { ...currentTractionPeriod, ...fieldTractionPeriod },
    });
    // packages/insight-ui/system/backbone/table-backbone/next-table-backbone.ts -> line 547 prevent auto reload table
    backbone.reloadData('table');

    if (!datetimeChecked) {
      setTime({
        timeStart: '00:00',
        timeEnd: '23:00',
      });
    }
    handleCloseCalendar();
    props.setAnchorEl(null);
  };

  const mapDays = (props) => {
    const start = get(calendarValue, [0], moment().subtract(1, 'day'));

    const isSameStart = props.isSameDate(props.date, start);
    const isSameEnd = props.isSameDate(props.date, last(calendarValue));
    if (calendarValue.length > 1) {
      props.className = `${isSameStart && 'selected-one start'} ${isSameEnd && 'selected-one end'}`;

      const startDate = moment(start.format());
      const endDate = moment(last(calendarValue).format());
      const isBetween = moment(props.date.format()).isBetween(startDate, endDate);
      if (isBetween && !isSameEnd) {
        props.className = ' rmdp-range-child';
      }
    } else {
      if (isSameStart) {
        props.className = ' selected-one';
      } else {
        props.className = '';
      }
    }

    if (moment(props.date.format()).isSameOrAfter(moment())) {
      props.disabled = true;
    }

    return props;
  };

  const currentOption = null;

  return {
    options,
    currentOption,
    anchorEl,
    open,
    handleCloseCalendar,
    handleSubmitCalendar,
    mapDays,
    calendarValue,
    isDatetimePicker,
    checkedHour,
    setCheckedHour,
    time,
    setTime,
    handleChangeDate,
    currentValue,
  };
};
