import { ITextField, TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { makeStyles, IconButton, Box } from '@material-ui/core';
import * as React from 'react';
import FormControlTemplate from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-template';
import Icon from '@ep/insight-ui/icons/Icon';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { eipRequest } from '@eip/next/lib/main';
import { get } from 'lodash';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';

const useStyles = makeStyles({
  inputGroup: {
    flexGrow: 1,
    width: '100%',
    '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
      width: '100%',
    },
    '& input': {
      width: '100%',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  input: {},
  checkWinRate: {
    height: '100%',
    padding: 10,
    opacity: 0.5 + '!important',
    '&:hover': {
      opacity: 1 + '!important',
    },
  },
});

interface FormControlInputProps extends ITextField {
  label: string;
  classNameContainer?: string;
}

const LazadaInputFirstSearchSlot = ({
  storefrontId,
  campaignSid,
  label,
  classNameContainer = '',
  ...props
}: { storefrontId: string; campaignSid: string } & FormControlInputProps) => {
  const classes = useStyles();
  const [winRate, setWinRate] = React.useState('');
  const [inputRate, setInputRate] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const value = React.useRef(0);

  React.useEffect(() => {
    if (inputRate > 0) {
      !isLoading && setIsLoading(true);
      eipRequest
        .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/hook/api/run', {
          template: 'lazada_first_search_ratio',
          vars: {
            storefrontId: parseInt(storefrontId),
            campaignSid: campaignSid || '-1',
            overBidRate: inputRate,
          },
        })
        .then((res) => {
          const returnRate = Number(get(res, 'result.sellerInputOverbidWinRate', 0) * 100);
          if (returnRate <= 0) {
            setWinRate('N/A');
          } else {
            setWinRate(Number(get(res, 'result.sellerInputOverbidWinRate', 0) * 100).toFixed(0));
          }

          setIsLoading(false);
          props.onChangeText(String(inputRate));
        });
    } else {
      setWinRate('');
    }
  }, [inputRate]);

  React.useEffect(() => {
    setInputRate(parseInt(props.defaultValue));
  }, [props.defaultValue]);

  return (
    <FormControlTemplate label={label} className={`${classNameContainer}`}>
      <Box display={'flex'}>
        <TextFieldCustom
          className={classes.inputGroup}
          classNamePaperInput={classes.input}
          {...props}
          onChangeText={(val) => {
            value.current = parseInt(val);
          }}
          suffix={
            <React.Fragment>
              <span style={{ color: '#8C98A4' }}>%</span>
            </React.Fragment>
          }
          helperText={
            <React.Fragment>
              {isLoading ? <LoadingIcon /> : ''}
              {winRate ? (
                <span>
                  <strong>{winRate}%</strong> chance to win
                </span>
              ) : (
                ''
              )}
            </React.Fragment>
          }
        />
        <IconButton
          title="Check your win rate"
          className={classes.checkWinRate}
          onClick={() => {
            setInputRate(value.current);
          }}
          disabled={isLoading}
        >
          <Icon size="12px" type="checked" />
        </IconButton>
      </Box>
    </FormControlTemplate>
  );
};

export default LazadaInputFirstSearchSlot;
