import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Italic = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="3 3 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 12C11.5523 12 12 12.4477 12 13C12 13.5523 11.5523 14 11 14H5C4.44772 14 4 13.5523 4 13C4 12.4477 4.44772 12 5 12H11Z" />
    <path d="M8 11C10.2091 11 12 9.20914 12 7L12 3C12 2.44772 11.5523 2 11 2C10.4477 2 10 2.44772 10 3L10 7C10 8.10457 9.10457 9 8 9C6.89543 9 6 8.10457 6 7L6 3C6 2.44772 5.55229 2 5 2C4.44772 2 4 2.44772 4 3V7C4 9.20914 5.79086 11 8 11Z" />
  </SvgIcon>
);

export default Italic;
