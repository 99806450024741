import { ICellRendererParams, RowNode } from '@ag-grid-community/core';
import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { produce } from 'immer';
import * as _ from 'lodash';
import { get } from 'lodash';
import * as React from 'react';
// import { TableCompactFactoryContext } from '../compact-factory';
import { TableCompactFactoryContext } from '../compact-factory2';
import { IConfigTable, makeTable } from '../table-container2';
import WrapperFormat, { ICellAction } from './wrapper-format';
import { useAtomValue } from 'jotai';
import { PersonalizationAtom, P13nUpdateAtom } from '@ep/insight-ui/system/backbone/atom/campaign-detail';
type StyleProps = {
  isAuto: boolean;
};
const useStyles = makeStyles(() => ({
  container: {
    // position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  stamp: {
    background: ' #EDF2F9',
    alignItems: 'center',
    justifyContent: 'center',
    '& .eip1-MuiChip-label': {
      //   padding: '',
      fontStyle: ' normal',
      fontWeight: 500,
      fontSize: ' 14px',
      lineHeight: ' 20px',
    },
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    flexDirection: 'column',
    height: '100%',
  },
  alignItem: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  value: ({ isAuto }: StyleProps) => ({
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    paddingTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
  }),
  subtext: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-start',
    color: isAuto ? '#C2C7CB' : '#8C98A4',
  }),
  autoSubtext: ({ isAuto }: StyleProps) => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
  }),
  hashtag: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#006EC6',
  }),
}));

const CompactCellFormat = (props: ICellRendererParams) => {
  const selectedItems = _.get(props, 'value.data.selectedItems', []);
  const currentFocusItem = _.get(props, 'value.data.currentFocusItem', {});
  const displayField = _.get(props, 'value.data.displayField', '');
  const defaultDisplay = _.get(props, 'value.defaultDisplay', '');
  const columnName = _.get(props, 'columnName', '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  // const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const isAuto = _.get(props, ['value', 'isAuto'], false);
  const subtext = _.get(props, ['value', 'subtext'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const isAutomatedAds = _.get(props, ['value', 'isAutomatedAds'], '');
  const isAutomatedCampaign = _.get(props, ['value', 'isAutomatedCampaign'], '');
  const isAutomatedAdsSelectProduct = _.get(props, ['value', 'isAutomatedAdsSelectProduct'], '');
  const marketplace = _.get(props, ['value', 'marketplace'], '');
  const marketplaceCode = _.get(props, ['value', 'marketplaceCode'], '');
  const p13nUpdate = useAtomValue(P13nUpdateAtom);

  let disabledCompact;
  if (ff.compact_dependency_data) {
    disabledCompact = Object(props.value).hasOwnProperty('dependencyData')
      ? ![].concat(props.value['dependencyData']).every((el) => _.get(props, ['data', el], null))
      : false;
  }

  const classes = useStyles({ isAuto });
  // const [rowsTemp, setRowsTemp] = React.useState<Array<any>>([]);
  const rowsTemp = React.useRef([]);
  let compactRef;
  compactRef = React.useRef();
  const refConfig = React.useRef({ compactTableConfig: null });
  const currentValue = _.get(currentFocusItem, [displayField], '');

  const subtext2 = _.get(props, ['value', 'subtext2']) || '';

  const hiddenFilter = _.get(props, 'value.data.hiddenFilter', {});
  const filter = _.get(props, 'value.data.filter', []);

  const backbone = React.useContext(TableBackboneContext);

  const tableConfig = get(backbone.getCellConfig(props.colDef.field), 'tableConfiguration');

  const valueHeader = _.get(props, 'colDef.field', '');

  const callbackRef = React.useRef<any[]>([]);

  const onRowSelectedModal = (rows: { node: RowNode; isSelected: boolean; isPresent: boolean }[], backbone) => {
    const selectedRows = backbone.getSelectedRows(
      rows.map((row) => ({ data: row.node.data, isSelected: row.isSelected })),
    );
    backbone.updateSelectedRows(selectedRows);
    rowsTemp.current = selectedRows;
    backbone.addon('addRowChangeListeners', (selectedRows) => {
      rowsTemp.current = selectedRows;
    })(selectedRows);
    callbackRef.current.forEach((listener) => {
      listener(selectedRows);
    });
  };

  React.useLayoutEffect(() => {
    if (!tableConfig) return;
    const config = {
      config: produce(tableConfig, (draft) => {
        if (!tableConfig.callback) draft.callback = {};

        const p13nConfig = p13nUpdate.get(tableConfig.configuration.tableId);
        const finConfig = { ...tableConfig.configuration, ...p13nConfig };
        console.info(tableConfig.configuration);
        draft.configuration = finConfig;

        draft.configuration.filter = filter;
        draft.configuration.hiddenFilter = hiddenFilter;
        draft.configuration.tableType = 'compact';
        draft.addons = {
          ...tableConfig.addons,
          'compact.getSelectedRows': () => rowsTemp.current,
          'compact.getInitSelectedRows': () =>
            get(tableConfig, ['addons', 'compactTable.initSelectedItems'], selectedItems),
          'compact.addSelectedChangeListener': (callback) => {
            callback(rowsTemp.current);
            callbackRef.current.push(callback);
          },
          getRowId: (row, primaryKeys, ignoreKeys = []) => {
            return []
              .concat(primaryKeys)
              .filter((i) => ignoreKeys.indexOf(i) === -1)
              .map((i) => {
                return row[i];
              })
              .join('|');
          },
        };
        draft.callback.onBackboneReady =
          tableConfig.callback && tableConfig.callback.onBackboneReady
            ? (compactBackbone) => {
                tableConfig.callback.onBackboneReady(compactBackbone);
                compactRef.current = compactBackbone;
              }
            : (compactBackbone) => (compactRef.current = compactBackbone);
        draft.callback.onRowSelect = (row) => {
          // if (isSubmitOnClosed) {
          //   rowsTemp.current = [].concat(row);
          // } else {
          //   onRowSelect(row, props.node.data, backbone);
          // }
        };
        draft.callback.onRowSelectNext = onRowSelectedModal;
        draft.tableType = 'compact';
        draft.configuration.system = { ...(tableConfig.configuration.system || {}), context: props.node.data };
      }),
      selectedRows: selectedItems,
      changeConfiguration: (config) => {
        p13nUpdate.update(config);
      },
    };

    refConfig.current.compactTableConfig = config;
    // setCompactTableConfig(config);
  }, [tableConfig]);

  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const ref = React.useRef<HTMLDivElement>();
  const onRowSelect = get(tableConfig, 'callback.onRowSelect', () => undefined);

  const handleOnClosed = () => {
    onRowSelect(rowsTemp.current, props.node.data, backbone);
  };
  const displayTableCompact = (visible: boolean) => {
    rowsTemp.current = [];
    const tableRender = <>{makeTable(refConfig.current.compactTableConfig)}</>;
    displayTable({
      anchorEl: ref.current,
      tableConfig: { config: refConfig.current.compactTableConfig.config },
      tableEl: tableRender,
      visible: visible,
      onClosed: () => {},
      onSubmit: handleOnClosed,
    });
  };

  const cellAction: ICellAction[] = React.useMemo(
    () => [
      {
        title: '',
        name: `Add ${columnName}`,
        icon: 'add',
        iconSize: '13px',
        onSubmit: () => {
          backbone
            .addon(
              `compactTable.${props.colDef.field}.config`,
              async ({ compactTableConfig }, rowTarget, backbone) => compactTableConfig,
            )({ compactTableConfig: refConfig.current.compactTableConfig }, props.data, backbone)
            .then((enhancedConfig) => {
              const { config: compactTableConfig } = enhancedConfig;
              const p13nConfig = p13nUpdate.get(compactTableConfig.configuration.tableId);
              const finConfig = { ...compactTableConfig.configuration, ...p13nConfig };
              console.info(tableConfig.configuration);

              if (props.colDef.field === 'ad_object') {
                refConfig.current.compactTableConfig = produce(enhancedConfig, (draft) => {
                  draft.config.addons = enhancedConfig.config.addons
                    ? {
                        ...enhancedConfig.config.addons,
                        'compact.getInitSelectedRows': () => enhancedConfig.selectedRows,
                        'compact.getPrimaryKeys': () => {
                          return ['PRODUCT.id'];
                        },
                      }
                    : {
                        'compact.getInitSelectedRows': () => enhancedConfig.selectedRows,
                        'compact.getPrimaryKeys': () => {
                          return ['PRODUCT.id'];
                        },
                      };
                  draft.selectedRows = [];
                  draft.config.configuration = finConfig;
                });
              } else {
                refConfig.current.compactTableConfig = enhancedConfig;
                refConfig.current.compactTableConfig = produce(enhancedConfig, (draft) => {
                  draft.config.configuration = finConfig;
                });
              }
              window.setTimeout(() => displayTableCompact(true), 50);
            });
        },
      },
      {
        name: `Remove ${columnName}`,
        icon: 'remove',
        iconSize: '12px',
        onSubmit: () => {
          backbone.getCallback('onRowRemove')(props.colDef?.field, currentFocusItem, props.node.data, backbone);
        },
      },
    ],
    [],
  );

  let displayContent;
  if (!tableConfig) {
    displayContent = (
      <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
        <Grid item xs={12} className={classes.alignItem}>
          <span className={classes.hashtag}></span>
        </Grid>
        <Grid item xs={12} className={classes.alignItem}>
          <div className={classes.value}>{defaultDisplay}</div>
        </Grid>
        <Grid item xs={12} className={classes.alignItem}>
          <span className={classes.subtext}></span>
        </Grid>
      </Grid>
    );
  } else {
    if ((isAutomatedAds || (isAutomatedCampaign && isAutomatedAdsSelectProduct)) && marketplaceCode === 'LAZADA') {
      displayContent = (
        <React.Fragment>
          <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
            <Grid item xs={12} className={classes.alignItem}>
              <span className={classes.hashtag}>{hashtag}</span>
            </Grid>
            <Grid item xs={12} className={classes.alignItem}>
              <div className={`${classes.value} ${classes.autoSubtext}`}>{'Auto selected'}</div>
            </Grid>
            <Grid item xs={12} className={classes.alignItem}>
              <span className={`${classes.subtext} ${classes.autoSubtext}`}>
                {isAutomatedCampaign ? `by ${marketplace}` : subtext}
              </span>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else if (currentValue) {
      displayContent = (
        <React.Fragment>
          <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
            <Grid item xs={12} className={classes.alignItem}>
              <span className={classes.hashtag}>{hashtag}</span>
            </Grid>
            <Grid item xs={12} className={classes.alignItem}>
              <div className={`${classes.value} ${isAutomatedAds && classes.autoSubtext}`}>{currentValue}</div>
            </Grid>
            <Grid item xs={12} className={classes.alignItem}>
              <span className={`${classes.subtext} ${isAutomatedAds && classes.autoSubtext}`}>
                {isAutomatedAds ? `by ${marketplace}` : subtext2 ? `${subtext} | ${subtext2}` : subtext}
              </span>
            </Grid>
          </Grid>
        </React.Fragment>
      );
    } else {
      ff.etable_identifier_add_buttons
        ? (displayContent = ((ff.compact_dependency_data && !disabledCompact) || !ff.compact_dependency_data) && (
            <div className={classes.menu}>
              <Button
                size="small"
                className={`eip_btn_plus_${valueHeader}`}
                onClick={() => cellAction[0].onSubmit(undefined)}
                style={{ padding: 8 }}
              >
                {selectedItems.length > 0 ? <span>{selectedItems.length}</span> : <Icon type="plus" />}
              </Button>
            </div>
          ))
        : (displayContent = ((ff.compact_dependency_data && !disabledCompact) || !ff.compact_dependency_data) && (
            <div className={classes.menu}>
              <Button size="small" onClick={() => cellAction[0].onSubmit(undefined)} style={{ padding: 8 }}>
                {selectedItems.length > 0 ? <span>{selectedItems.length}</span> : <Icon type="plus" />}
              </Button>
            </div>
          ));
    }
  }

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={isAutomatedAds ? [] : cellAction}
      node={node}
      value={value}
      disabledMenu={!Boolean(currentValue) || (Boolean(currentValue) && isAuto)}
      isGrouped={isGrouped}
    >
      <div className={classes.container} ref={ref}>
        {displayContent}
      </div>
    </WrapperFormat>
  );
};
export default CompactCellFormat;
