import * as React from 'react';
import { get, set, uniq, isEqual, uniqWith, cloneDeep, uniqBy } from 'lodash';
import { useForm } from 'react-hook-form';
import * as hb from 'handlebars';
import moment from 'moment';

import { eipRequest } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

import {
  SYSTEM_FIELD_DEFINED,
  INIT_VALUE_TYPE_FROM_ETABLE,
  BIDDING_EDITOR_COMPONENT_NAME,
  BUDGET_EDITOR_COMPONENT_NAME,
  INIT_VALUE_TYPE_STATIC,
  CUSTOM_TAG,
  TEXT_INPUT_COMPONENT_NAME,
  TEXTAREA_COMPONENT_NAME,
  CALENDAR_COMPONENT_NAME,
  INPUT_TYPE_SUBMIT_AFTER_CLICK,
  INPUT_TYPE_SUBMIT_METHOD,
  INPUT_TYPE_RELOAD_AFTER_SUBMIT,
  INPUT_TYPE_USE_QUERY_PAYLOAD,
} from '../../../utils/constant';
import { USE_FORMULA } from '@ep/insight-ui/system/helper/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const INPUT_TYPE_HIDDEN_FIELD = 'hidden';

export const useInlineEdit = (props) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    control,
    getValues,
    setError,
  } = useForm();
  const { onToast, onToastMultiple } = useToast();

  const keyPresses = React.useRef([]);

  React.useEffect(() => {
    const handleKeyUp = (e) => {
      if (isEqual(keyPresses.current, ['Meta', 'Enter']) || isEqual(keyPresses.current, ['Control', 'Enter'])) {
        handleSubmit(onSubmit)();
      }
      keyPresses.current = [];
    };

    const handleKeyDown = (e) => {
      if (!keyPresses.current.includes(e.key)) {
        keyPresses.current.push(e.key);
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const backbone = React.useContext(TableBackboneContext);
  const groupBy = backbone.getConfig('groupBy');
  const gridApi = backbone.getGridApi ? backbone.getGridApi() : null;

  const tracking = get(props, ['customProps', 'tracking'], '');
  const callback = get(props, ['customProps', 'callback'], () => undefined);

  React.useEffect(() => {
    if (tracking === 'yes') {
      const rowData = get(props, ['data', 'node', 'data'], {});
      if (backbone.getGridApi) {
        const bulkRows = uniq(backbone.getGridApi().getSelectedRows().concat(rowData));
        try {
          freshpaint.track(`cell_action_click`, {
            section: backbone.getConfig('title'),
            column: get(props, ['field'], ''),
            action_name: get(props, ['customProps', 'actionName'], ''),
            count_bulk_item: bulkRows.length,
          });
        } catch {}
      }
    }
  }, []);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [errorMessage, setErrorMessage] = React.useState<string>('');
  const [subtext, setSubtext] = React.useState<string>('');

  const [value, setValue] = React.useState('');

  const handleClosed = () => {
    props.setAnchorEl(null);
  };

  const payload = get(props, ['payload'], []);

  const configuration = React.useMemo(() => {
    return payload.reduce(
      (carry, f) => {
        if (f.input_type === SYSTEM_FIELD_DEFINED) {
          carry.defaults = { ...carry.defaults, [f.field_key]: f };
        } else {
          carry.fields = carry.fields.concat(f);
        }
        return carry;
      },
      { fields: [], defaults: {} },
    );
  }, [payload]);

  const isEmbeded = get(configuration, 'defaults.form.field_configuration', []).find(
    (el) => el.key === 'embedded' && el.value === 'yes',
  );

  const payloadTransform = (
    get(configuration, 'defaults.form.field_configuration', []).find((el) => el.key === 'payload_transform') || {
      value: '',
    }
  )?.value;

  const submitAfterClick = React.useMemo(() => {
    const value = (
      get(configuration, ['defaults', 'form', 'field_configuration'], []).find(
        (el) => el.key === INPUT_TYPE_SUBMIT_AFTER_CLICK,
      ) || {
        value: false,
      }
    ).value;

    return safeJsonParse(value, value);
  }, [configuration]);

  const reloadAfterSubmit = React.useMemo(() => {
    const value = (
      get(configuration, ['defaults', 'form', 'field_configuration'], []).find(
        (el) => el.key === INPUT_TYPE_RELOAD_AFTER_SUBMIT,
      ) || {
        value: 'true',
      }
    ).value;

    return value == 'true';
  }, [configuration]);

  const initialFocusIndex = configuration.fields.findIndex((field) =>
    [TEXT_INPUT_COMPONENT_NAME, TEXTAREA_COMPONENT_NAME].includes(field.input_type),
  );

  const getFinalValue = (value, data) => {
    if (String(value).startsWith(USE_FORMULA)) {
      return toValue(value.replace(USE_FORMULA, ''), {
        ...data,
        value: get(data, ['eData', props.field, 'value'], null),
      });
    }

    return value;
  };

  const convertValue = (field, value, rowData = {}) => {
    const dataType = get(field, ['data_type'], '');
    const inputType = get(field, ['input_type'], '');

    if (!dataType || inputType == CALENDAR_COMPONENT_NAME) return value;

    if (dataType == 'int') {
      return parseInt(value) || 0;
    }
    if (dataType == 'float') {
      return parseFloat(value) || 0;
    }
    if (dataType == 'boolean') {
      let parsedValue = value;
      try {
        parsedValue = JSON.parse(value);
      } catch {}
      return Boolean(parsedValue);
    }
    if (dataType == 'string') {
      return String(value);
    }
    if (dataType == 'date') {
      return moment(value).format('YYYY-MM-DD');
    }
    if (dataType == 'datetime') {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    }
    if (dataType == 'json') {
      return safeJsonParse(value, value);
    }
    if (dataType == 'encode') {
      return JSON.stringify(value);
    }
    if (String(dataType).startsWith('=')) {
      return toValue(dataType, { ...rowData, value }, true);
    }

    return value;
  };

  const getPayload = (data, rowData) => {
    let bulkRows = [];
    if (backbone.getGridApi) {
      bulkRows = uniq(backbone.getGridApi().getSelectedRows().concat(rowData));
    }

    const basedTableFields = configuration.fields.filter(
      (input) => input.input_type === INPUT_TYPE_HIDDEN_FIELD && input.init_value_type === INIT_VALUE_TYPE_FROM_ETABLE,
    );

    const narrativeTemplateFields = configuration.fields.filter(
      (input) => input.input_type === 'narrative_template' && input.init_value_type === INIT_VALUE_TYPE_FROM_ETABLE,
    );

    const basedTableFieldKeys = basedTableFields.map((input) => input.field_key);

    const payloadFromBasedTable = basedTableFields.reduce((payload, input) => {
      const basedRowKey = input.init_value_value;
      let payloadValue = get(rowData, basedRowKey, null);
      if (String(basedRowKey).startsWith('=')) {
        payloadValue = toValue(basedRowKey, rowData, true);
      }
      const payloadKey = input.field_key;

      if (bulkRows.length === 1) {
        const convertedValue = convertValue(input, payloadValue, rowData);
        const key = payloadKey.endsWith('$') ? payloadKey.slice(0, -1) : payloadKey;
        if (typeof convertedValue == 'object' && convertedValue) {
          Object.entries(convertedValue).forEach(([key, value]) => {
            set(payload, key.concat(`.${key}`), value);
          });
        } else {
          set(payload, key, convertedValue);
        }
      } else if (bulkRows.length > 1) {
        // example key: data.0.ad_object_id etc...
        if (/[^\.]+\.0\.[^\.]+/.test(payloadKey)) {
          bulkRows.forEach((row, index) => {
            let payloadValue = get(row, basedRowKey, null);
            if (String(basedRowKey).startsWith('=')) {
              payloadValue = toValue(basedRowKey, row, true);
            }
            const convertedValue = convertValue(input, payloadValue, rowData);
            const key = payloadKey.replace('.0.', `.${index}.`);
            if (typeof convertedValue == 'object' && convertedValue) {
              Object.entries(convertedValue).forEach(([key, value]) => {
                set(payload, key.concat(`.${key}`), value);
              });
            } else {
              set(payload, key, convertValue(input, convertedValue, rowData));
            }
          });
        } else if (payloadKey.endsWith('$')) {
          // Prevent transform this field to array
          const convertedValue = convertValue(input, payloadValue, rowData);
          const key = payloadKey.slice(0, -1);
          if (typeof convertedValue == 'object' && convertedValue) {
            Object.entries(convertedValue).forEach(([key, value]) => {
              set(payload, key.concat(`.${key}`), value);
            });
          } else {
            set(payload, key, convertValue(input, convertedValue, rowData));
          }
        } else {
          set(
            payload,
            payloadKey,
            uniq(
              bulkRows.map((row) => {
                let payloadValue = get(row, basedRowKey, null);
                if (String(basedRowKey).startsWith('=')) {
                  payloadValue = toValue(basedRowKey, row, true);
                }
                return convertValue(input, payloadValue, rowData);
              }),
            ),
          );
        }
      } else if (payloadValue && payloadKey) {
        set(payload, payloadKey, convertValue(input, payloadValue, rowData));
      }

      return payload;
    }, cloneDeep(data));

    let apiPayload = payloadFromBasedTable;
    narrativeTemplateFields.forEach((input) => {
      const basedRowKey = input.init_value_value;
      const payloadValue = get(rowData, basedRowKey, null);
      const payloadKey = input.field_key;
      const tmplFn = hb.compile(payloadValue, { noEscape: true });
      const narrativeContent = tmplFn({ rows: bulkRows.map((i: any) => i.eData) });
      set(apiPayload, payloadKey, narrativeContent);
    });

    apiPayload = configuration.fields
      .filter((input) => input.input_type !== SYSTEM_FIELD_DEFINED && !basedTableFieldKeys.includes(input.field_key))
      .reduce((payload, input) => {
        // example key: data.0.bidding_price etc...
        const payloadKey = input.field_key;
        if (/[^\.]+\.0\.[^\.]+/.test(payloadKey) && bulkRows.length > 1) {
          bulkRows.forEach((row, index) => {
            const value = getFinalValue(get(data, payloadKey, null), row);
            const convertedValue = convertValue(input, value, rowData);
            if (typeof convertedValue == 'object' && convertedValue) {
              Object.entries(convertedValue).forEach(([key, value]) => {
                set(payload, payloadKey.replace('.0.', `.${index}.`).concat(`.${key}`), value);
              });
            } else {
              set(payload, payloadKey.replace('.0.', `.${index}.`), convertValue(input, convertedValue, rowData));
            }
          });
        } else if (bulkRows.length == 1) {
          const value = getFinalValue(get(data, payloadKey, null), bulkRows[0]);
          const convertedValue = convertValue(input, value, rowData);
          if (typeof convertedValue == 'object' && convertedValue) {
            Object.entries(convertedValue).forEach(([key, value]) => {
              set(payload, payloadKey.concat(`.${key}`), value);
            });
          } else {
            set(payload, payloadKey, convertedValue);
          }
        }
        return payload;
      }, apiPayload);

    /**
     * optimized here for deduplication on array object
     * example. data.0.campaign_id, data.0.bidding_price
     */
    basedTableFieldKeys
      .reduce((carry, k) => {
        const matches = String(k).match(/(.*?)\.0\./);
        if (matches) return carry.concat(matches[1]);
        return carry;
      }, [])
      .forEach((prefix) => {
        const arrayObj = get(apiPayload, prefix);
        set(apiPayload, prefix, uniqWith(arrayObj, isEqual));
      });

    // Hanle hidden fields with INIT_VALUE_TYPE_STATIC
    configuration.fields
      .filter(
        (input) => input.input_type === INPUT_TYPE_HIDDEN_FIELD && input.init_value_type === INIT_VALUE_TYPE_STATIC,
      )
      .forEach((el) => {
        set(apiPayload, el.field_key, convertValue(el, el.init_value_value, rowData));
      });

    if (String(payloadTransform).startsWith('=')) {
      apiPayload = toValue(payloadTransform, { bulkRows, rowData, payload: apiPayload }, true);
    }

    return apiPayload;
  };

  const defaultExpandedRows = get(backbone, ['config', 'system', 'defaultExpandedRows'], 0);
  const visualizationType = backbone.getConfig('visualizationType');
  const handleReloadTable = () => {
    if (gridApi) {
      gridApi.deselectAll();
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.filter((i) => !!i).length > 0 && backbone.getConfig('visualizationType') === 'table') {
        const expanded = [];
        const collapsed = [];
        gridApi.forEachNode((node) => {
          if (node.group && collapsed.length < Number(defaultExpandedRows)) {
            collapsed.push(node);
            expanded.push(node);
          } else if (node.expanded) {
            expanded.push(node);
          }
        });
        collapsed.forEach((node) => {
          if (!node.expanded) {
            gridApi.setRowNodeExpanded(node, true);
          }
        });
        if (expanded.length) {
          const lowestLevelExpanded = Math.max(...expanded.map((i) => i.level));
          expanded
            .filter((i) => i.level == lowestLevelExpanded)
            .forEach((el) => {
              const route = [el.key];
              let currentNode = el;
              while (currentNode.level > 0) {
                route.push(currentNode.parent.key);
                currentNode = currentNode.parent;
              }

              gridApi.refreshServerSideStore({ route: route.reverse() });
            });
        } else {
          gridApi.refreshServerSideStore();
        }
      } else {
        if (visualizationType == 'category') {
          backbone.changePagination({ page: 1 });
        }
        gridApi.refreshServerSideStore();
      }
    }
  };

  const onSubmit = async (data) => {
    setErrorMessage(null);
    const endpointObj = (get(configuration, ['defaults', 'form', 'field_configuration'], []) || []).find(
      (el) => el.key === 'submit_endpoint',
    );
    const methodObj = (get(configuration, ['defaults', 'form', 'field_configuration'], []) || []).find(
      (el) => el.key === INPUT_TYPE_SUBMIT_METHOD,
    );
    const useQueryPayloadObj = (get(configuration, ['defaults', 'form', 'field_configuration'], []) || []).find(
      (el) => el.key === INPUT_TYPE_USE_QUERY_PAYLOAD,
    );
    const endpoint = endpointObj ? endpointObj.value : '';
    const method = methodObj ? methodObj.value : 'post';
    const useQueryPayload = useQueryPayloadObj ? useQueryPayloadObj.value : false;

    let isSuccess = false;

    const err = {};
    const rowData = get(props, ['data', 'node', 'data'], {});

    props.payload.forEach(({ regex, field_key }) => {
      if (regex) {
        const value = get(data, field_key, '');
        const regexValue = toValue(regex, { ...rowData, value }, true);
        err[field_key] =
          regexValue == true
            ? null
            : {
                type: 'regex',
                message: regexValue,
              };
      }
    });

    Object.entries(err).forEach(([k, v]) => {
      setError(k, v);
      if (!v) delete err[k];
    });
    if (Object.keys(err).length > 0) return;

    if (endpoint) {
      setIsSubmitting(true);

      const rowData = get(props, ['data', 'node', 'data'], {});
      let payload = getPayload(data, rowData);

      try {
        let finalEndpoint = endpoint;
        let request = eipRequest.post;
        if (method === 'GET') {
          request = eipRequest.get;
        }
        if (method === 'PUT') {
          request = eipRequest.put;
        }
        if (method === 'PATCH') {
          request = eipRequest.patch;
        }
        if (method === 'DELETE') {
          request = eipRequest.deleteFetch;
        }
        if (useQueryPayload == true) {
          const queryPayload = Object.entries(payload).reduce((carry, [k, v]) => {
            if (Array.isArray(v)) carry.push(...v.map((i) => `${k}=${String(i)}`));
            else carry.push(`${k}=${String(v)}`);
            return carry;
          }, []);
          finalEndpoint += queryPayload.join('&');
          payload = {};
        }
        const response = await request(finalEndpoint, payload);

        setIsSubmitting(false);

        callback(
          ['response', response],
          ['payload', payload],
          ['endpoint', endpoint],
          ['handleClosed', handleClosed],
          ['setErrorMessage', setErrorMessage],
          ['setSubtext', setSubtext],
          ['handleReloadTable', handleReloadTable],
          ['row', rowData],
        );

        if (response.success || response.code == 200) {
          isSuccess = true;

          if (reloadAfterSubmit) {
            handleReloadTable();
          }
          // TODO: query api for all row effected by the updates and reload the node.data
          handleClosed();
        } else {
          let errorMessage;
          if (Array.isArray(response.data)) {
            const errorMessageObj = response.data.find((i) => i.success == false);
            errorMessage = Object.values(errorMessageObj?.error || {})?.[0];
          }
          if (!errorMessage) {
            errorMessage = response.message;
          }
          setErrorMessage(errorMessage || 'Something went wrong!');
          if (submitAfterClick) {
            onToastMultiple({
              messages: [],
              title: errorMessage || 'Something went wrong!',
              variant: 'error',
            });
          }
        }
      } catch (e) {
        setErrorMessage(e.message);
        if (submitAfterClick) {
          onToastMultiple({
            messages: [],
            title: e.message,
            variant: 'error',
          });
        }
        setIsSubmitting(false);
        callback(
          [
            'response',
            {
              ...e,
              message: e.message,
              response: e.response,
            },
          ],
          ['payload', payload],
          ['endpoint', endpoint],
          ['handleClosed', handleClosed],
          ['setErrorMessage', setErrorMessage],
          ['handleReloadTable', handleReloadTable],
          ['setSubtext', setSubtext],
        );
      }

      if (tracking === 'yes') {
        try {
          let bulkRows = [];
          if (backbone.getGridApi) {
            bulkRows = uniq(backbone.getGridApi().getSelectedRows().concat(rowData));
          }
          freshpaint.track(`cell_action_submit`, {
            section: backbone.getConfig('title'),
            column: get(props, ['field'], ''),
            action_name: get(props, ['customProps', 'actionName'], ''),
            count_bulk_item: bulkRows.length,
            result: isSuccess,
          });
        } catch {}
      }
    } else {
      setErrorMessage('Please enter the endpoint.');
    }
  };

  const headerTitle = React.useMemo(() => {
    const formObj = configuration.defaults?.form || {
      field_configuration: [],
    };

    const formTitleObj = (formObj.field_configuration || []).find((el) => el.key === 'form_title') || { value: '' };
    return formTitleObj.value;
  }, [configuration]);

  const isModal = false;

  const pivotColumn = get(props, ['customProps', 'pivotColumn'], '');
  const pivotColumnRowData = get(props, ['data', 'node', 'data', 'eData', pivotColumn, 'pivot', 'rowData'], null);
  const rowData = pivotColumnRowData || get(props, ['data', 'node', 'data'], {});

  React.useEffect(() => {
    if (submitAfterClick) {
      handleSubmit(onSubmit)();
      handleClosed();
    }
  }, [submitAfterClick]);

  return {
    register,
    watch,
    getValues,
    errors,
    control,
    isSubmitting,
    setIsSubmitting,
    handleClosed,
    handleSubmit: handleSubmit(onSubmit),
    configuration,
    isModal,
    value,
    setValue,
    errorMessage,
    headerTitle,
    rowData,
    initialFocusIndex,
    isEmbeded,
    subtext,
  };
};
