import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  ellipseFirstSignIn: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        position: 'absolute',
        width: '737px',
        height: '739px',
        left: '-571px',
        top: '-417px',
        border: '4px solid #0369C7',
        boxSizing: 'border-box',
        content: '""',
        borderRadius: '100%',
        zIndex: 2,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '976.01px',
        height: '977.56px',
        right: '-579.85px',
        bottom: '-689.17px',
        border: '4px solid #0369C7',
        boxSizing: 'border-box',
        borderRadius: '100%',
        zIndex: 2,
      },
    },
  },
  ellipseFirstSignInMobile: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '289.54px',
        height: '290.33px',
        left: '-196px',
        top: '-168.03px',
        border: '4px solid #0369C7',
        boxSizing: 'border-box',
        borderRadius: '100%',
        zIndex: 2,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '408.65px',
        height: '409.3px',
        right: '-272.68px',
        bottom: '-321px',
        border: '4px solid #0369C7',
        boxSizing: 'border-box',
        borderRadius: '100%',
        zIndex: 2,
      },
    },
  },
  ellipseSecondSignIn: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '976.01px',
        height: '977.56px',
        left: '-700.82px',
        top: '-712.18px',
        background: '#253746',
        borderRadius: '100%',
        zIndex: 1,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '976.01px',
        height: '977.56px',
        right: '-623.19px',
        bottom: '-652.37px',
        background: '#253746',
        borderRadius: '100%',
        zIndex: 1,
      },
    },
  },
  ellipseSecondSignInMobile: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '383.44px',
        height: '384.05px',
        left: '-247px',
        top: '-284px',
        background: '#253746',
        borderRadius: '100%',
        zIndex: 1,
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '408.65px',
        height: '409.3px',
        right: '-290.83px',
        bottom: '-305.59px',
        background: '#253746',
        borderRadius: '100%',
        zIndex: 1,
      },
    },
  },
  ellipseThirdSignIn: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '976.01px',
        height: '977.56px',
        left: '-691px',
        top: '-644px',
        background: '#EBF6FF',
        borderRadius: '100%',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '976.01px',
        height: '977.56px',
        right: '-561.01px',
        bottom: '-632.56px',
        background: '#EBF6FF',
        borderRadius: '100%',
      },
    },
  },
  ellipseThirdSignInMobile: {
    '&.ellipseItem': {
      position: 'unset',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '383.44px',
        height: '384.05px',
        left: '-243.14px',
        top: '-257.21px',
        background: '#EBF6FF',
        borderRadius: '100%',
      },
      '&::before': {
        content: '""',
        position: 'absolute',
        width: '408.65px',
        height: '409.3px',
        right: '-264.79px',
        bottom: '-297.3px',
        background: '#EBF6FF',
        borderRadius: '100%',
      },
    },
  },

  ellipseFirstRecovery: {
    '&::after': {
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      left: '-484.09px',
      top: '210.01px',
      border: '4px solid #0369C7',
      boxSizing: 'border-box',
      transform: 'rotate(-90deg)',
      content: '""',
      borderRadius: '100%',
      zIndex: 2,
    },
    '&::before': {
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      right: '-480.2px',
      top: '207.7px',
      border: '4px solid #0369C7',
      boxSizing: 'border-box',
      transform: 'rotate(-90deg)',
      content: '""',
      borderRadius: '100%',
      zIndex: 2,
    },
  },
  ellipseFirstRecoveryMobile: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  ellipseSecondRecovery: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      left: '-506px',
      top: '184.22px',
      background: '#253746',
      transform: 'rotate(-90deg)',
      borderRadius: '100%',
      zIndex: 1,
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      right: '-506px',
      top: '185.79px',
      background: '#253746',
      transform: 'rotate(-90deg)',
      borderRadius: '100%',
      zIndex: 1,
    },
  },
  ellipseSecondRecoveryMobile: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
  ellipseThirdRecovery: {
    '&::after': {
      content: '""',
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      left: '-452.99px',
      top: '174.41px',
      background: '#EBF6FF',
      transform: 'rotate(-90deg)',
      borderRadius: '100%',
    },
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '580.99px',
      height: '581.91px',
      right: '-468.99px',
      top: '174px',
      background: '#EBF6FF',
      transform: 'rotate(-90deg)',
      borderRadius: '100%',
    },
  },
  ellipseThirdRecoveryMobile: {
    '&::after': {
      display: 'none',
    },
    '&::before': {
      display: 'none',
    },
  },
}));

const EllipseFormBackground = ({ type }: any) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  const classes = useStyles();

  return (
    <Grid style={{ height: '100vh', position: 'fixed', width: '100vw' }}>
      {type === 'sign-in' || type === 'Login' ? (
        <div>
          <div
            className={clsx(`${classes.ellipseFirstSignIn} ellipseItem`, {
              [classes.ellipseFirstSignInMobile]: isMobileView,
            })}
          ></div>
          <div
            className={clsx(`${classes.ellipseSecondSignIn} ellipseItem`, {
              [classes.ellipseSecondSignInMobile]: isMobileView,
            })}
          ></div>
          <div
            className={clsx(`${classes.ellipseThirdSignIn} ellipseItem`, {
              [classes.ellipseThirdSignInMobile]: isMobileView,
            })}
          ></div>
        </div>
      ) : (
        <Grid>
          <Grid
            className={clsx({
              [classes.ellipseFirstRecovery]: !isMobileView,
            })}
          />
          <Grid
            className={clsx({
              [classes.ellipseSecondRecovery]: !isMobileView,
            })}
          />
          <Grid
            className={clsx({
              [classes.ellipseThirdRecovery]: !isMobileView,
            })}
          />
        </Grid>
      )}
    </Grid>
  );
};
export default EllipseFormBackground;
