import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RemixItalic = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M15 20H7v-2h2.927l2.116-12H9V4h8v2h-2.927l-2.116 12H15z" />
  </SvgIcon>
);

export default RemixItalic;
