import * as React from 'react';
import * as _ from 'lodash';
import { Box, Button, Tooltip, IconButton, withStyles, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import Arrow from '@ep/insight-ui/icons/svg/Arrow';
import { CreateCSSProperties } from '@material-ui/styles';
import { contentStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency, handlePercentAbs } from '@ep/insight-ui/lib/number';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { dataMenu } from '@ep/insight-ui/elements/dropdown-menu/data-demo';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { InformationMetric } from '../information-metric';
import { formatCurrencyResponsive } from '@ep/insight-ui/lib/number';

import KeywordDropdown from '@ep/insight-ui/elements/dropdown-menu/keyword-dropdown';

interface IProps {
  size: 'small' | 'medium';
  isSpacingNumber: boolean;
}

const titleResponsive = {
  small: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  medium: {
    fontSize: '18px',
    lineHeight: '20px',
  },
};

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: '#253746',
    boxShadow: 'rgb(140 152 164 / 25%) 0px 6px 12px',
    borderRadius: '12px',

    width: '288px',
    height: '192px',

    padding: '12px',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    '& .eip1-chart-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '18px',
      marginTop: '2px',
    },
    '& .eip1-chart-header .title': {
      cursor: 'pointer',
    },
    '.eres--small &': {
      flexDirection: 'row',
    },
  },

  title: {
    color: '#253746',
    fontWeight: 500,
    fontSize: (props: IProps) => titleResponsive[props.size].fontSize,
    lineHeight: (props: IProps) => titleResponsive[props.size].lineHeight,
  },

  number: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .trending': {
      marginTop: '5px',
      '&.trendingSmall': {
        fontSize: '14px',
      },
    },
    '.eres--medium &, .eres--large &': {
      '& .parameter + .trending': {
        marginLeft: (props: IProps) => (props.isSpacingNumber ? 'auto' : '8px'),
      },
    },
    '& .parameterChartSmall': {
      fontSize: '20px',
    },
    '&.between': {
      justifyContent: 'space-between',
    },
    '.eres--small &': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  percent: {
    ...(contentStyle.percent as CreateCSSProperties),
    '.eres--small &': {
      '& .parameter + .trending': {
        marginLeft: '0',
      },
      display: 'flex',
      alignItems: 'center',
    },
  },

  state: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .eip1-MuiTypography-root': {
      fontWeight: 400,
    },
  },

  stateAble: {
    display: 'flex',
    alignItems: 'center',
  },
  stateAbleIcon: {
    marginTop: '-2px',
    marginRight: '5px',
  },

  stateErrorText: {
    color: colors.text.critical,
  },

  '@keyframes rightTransform': {
    '0%': {
      transform: 'translateX(0)',
    },
    '25%': {
      transform: 'translateX(11px)',
    },
    '50%': {
      transform: 'translateX(11px)',
    },
    '75%': {
      transform: 'translateX(11px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes leftTransform': {
    '0%': {
      transform: 'translateX(0)',
    },
    '25%': {
      transform: 'translateX(-11px)',
    },
    '50%': {
      transform: 'translateX(-11px)',
    },
    '75%': {
      transform: 'translateX(-11px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  stateLoading: {
    display: 'flex',
    alignItems: 'center',
    '& .right': {
      // backgroundColor: 'blue',
      // transform: 'translateX(11px)',
      animation: `$rightTransform 1000ms ${theme.transitions.easing.easeIn} infinite`,
    },
    '& .left': {
      // backgroundColor: 'yellow',
      // transform: 'translateX(-11px)',
      animation: `$leftTransform 1000ms ${theme.transitions.easing.easeIn} infinite`,
    },
  },

  stateEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },

  wrapperResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '220px',
  },

  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
    marginBottom: '8px',
  },
  inputIcon: {
    marginLeft: '12px',
  },
  keywordItem: {
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      background: '#E4E7E9',
    },
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  noOptions: {
    textAlign: 'center',
    color: '#8D979E',
  },
}));
const BackgroundTooltip = withStyles({
  tooltip: {
    backgroundColor: '#253746',
  },
})(Tooltip);
export type IStateChartValue = 'success' | 'loading' | 'error' | 'empty' | 'no-data-config' | 'hide';
interface IChartState {
  stateChart?: IStateChartValue;
  children?: React.ReactNode;
  title?: string;
  value?: string;
  currency?: string;
  percent?: number;
  isSpacingNumber?: boolean;
  forwardMeaning?: boolean;
  headerType?: string;
  parentRef?: any;
  keepHeadContent?: boolean;
  titleBetween?: boolean;
  isSimplified?: boolean;
}
const ChartState = ({
  stateChart,
  title = '',
  value = '',
  currency = '',
  percent = 0,
  isSpacingNumber = false,
  children = null,
  forwardMeaning = true,
  headerType = '',
  parentRef = { current: undefined },
  keepHeadContent = false,
  titleBetween = false,
  isSimplified = false,
}: IChartState) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState<'small' | 'medium'>('medium');
  const [arrow, setArrow] = React.useState<string>('up');
  const [isHovering, setIsHovering] = React.useState(false);
  const classes = useStyle({ size, isSpacingNumber });
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';
  const [isHoverTitle, setIsHoverTitle] = React.useState(false);
  const getDefinition = () => {
    const definition = InformationMetric.find((val) => val.title === title);
    if (definition === undefined) return '';
    return definition.definition;
  };
  const chartWithState = () => {
    switch (stateChart) {
      case 'success': {
        return <>{children}</>;
      }
      case 'empty': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateAble)}>
              <Icon className={clsx(classes.stateAbleIcon)} type="note" size="14px" />
              <Typography variant="body1">No data available.</Typography>
            </Box>
          </Box>
        );
      }
      case 'no-data-config': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateEmpty)}>
              <Icon type="tool" colorIcon={colors.icon.default} size="24px" />
              <Typography variant="body1">No data added to be monitored</Typography>
              <Button variant="contained" color="secondary">
                Add data
              </Button>
            </Box>
          </Box>
        );
      }
      case 'error': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateAble)}>
              <Icon className={clsx(classes.stateAbleIcon)} type="error" colorIcon={colors.icon.critical} size="14px" />
              <Typography variant="body1">
                <span className={clsx(classes.stateErrorText)}>Could not load data.</span> Retry
              </Typography>
            </Box>
          </Box>
        );
      }
      case 'hide': {
        return null;
      }
      default: {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateLoading)}>
              <Box
                className={clsx('left')}
                width="10px"
                height="10px"
                borderRadius="100%"
                bgcolor={colors.icon.warning}
              />
              <Box
                className={clsx('right')}
                width="10px"
                height="10px"
                borderRadius="100%"
                bgcolor={colors.icon.warning}
              />
            </Box>
          </Box>
        );
      }
    }
  };

  const onResize = () => {
    const width = _.get(ref.current, 'offsetWidth');

    if (width < 768) {
      setSize('small');
      return;
    }
    setSize('medium');
  };

  React.useEffect(() => {
    const re = new ResizeObserver(onResize);
    re.observe(ref.current);
  }, []);
  const [isNumberChartSmall, setIsNumberChartSmall] = React.useState(false);
  const refNumber = React.useRef<HTMLDivElement>(null);
  const restyleNumberChart = (numberChartDOM) => {
    if (numberChartDOM.current.offsetHeight < 30) {
      if (numberChartDOM.current.classList.contains('numberGroupChartSmall')) {
        setIsNumberChartSmall(false);
      } else {
        return;
      }
    }
    if (numberChartDOM.current.offsetHeight > 30) {
      if (numberChartDOM.current.classList.contains('numberGroupChartSmall')) {
        return;
      } else {
        setIsNumberChartSmall(true);
      }
    }
  };
  React.useEffect(() => {
    if (refNumber.current) {
      const numberMutation = new ResizeObserver(() => {
        restyleNumberChart(refNumber);
      });
      numberMutation.observe(refNumber.current);
      return () => {
        numberMutation.disconnect();
      };
    }
  }, []);

  React.useEffect(() => {
    percent >= 0 ? setArrow('up') : setArrow('down');
  }, [percent]);

  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyMetricNumber = () => {
    const cb = navigator.clipboard;
    cb.writeText(value).then(() => setIsCopied(true));
  };

  return (
    <div
      className={clsx(classes.wrapper, 'chartState')}
      ref={ref}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {headerType !== 'analysis'
        ? title &&
          (keepHeadContent || stateChart === 'success') &&
          value && (
            <Box mb={size === 'small' ? '8px' : '16px'} style={{ flex: '4', width: isMobile ? '25%' : 'auto' }}>
              <Grid container direction="row">
                {title && (
                  <Grid
                    item
                    xs={12}
                    onMouseOver={() => setIsHoverTitle(true)}
                    onMouseLeave={() => setIsHoverTitle(false)}
                    style={{ display: 'flex' }}
                  >
                    <Typography variant="h5" color="textSecondary" className={clsx(classes.title, 'title')}>
                      {title}
                    </Typography>
                    {isHoverTitle && (
                      <BackgroundTooltip title={getDefinition()} placement="top">
                        <IconButton style={{ padding: '0px', marginLeft: '6px' }}>
                          <Icon type={'addinformation'} colorIcon={'#253746'} />
                        </IconButton>
                      </BackgroundTooltip>
                    )}
                  </Grid>
                )}
              </Grid>
              {(keepHeadContent || stateChart === 'success') && value && (
                <div
                  ref={refNumber}
                  className={clsx('numberGroupChart', isNumberChartSmall ? 'numberGroupChartSmall' : '')}
                >
                  <Box
                    className={clsx(
                      classes.number,
                      'number',
                      size === 'small' && titleBetween ? 'between' : '',
                      isMobile ? classes.wrapperResponsive : null,
                    )}
                  >
                    <Box display={'flex'}>
                      {isNumberChartSmall ? (
                        <Typography
                          variant={size === 'small' ? 'h2' : 'h1'}
                          className={clsx('parameter', isNumberChartSmall ? 'parameterChartSmall' : '')}
                        >
                          {formatCurrencyResponsive(value, currency)} {currency && currency}
                        </Typography>
                      ) : (
                        <Typography variant={size === 'small' ? 'h2' : 'h1'} className={'parameter'}>
                          {formatCurrency(value, currency)} {currency && currency}
                        </Typography>
                      )}
                      {isHovering && (
                        <BackgroundTooltip
                          title={isCopied ? 'Number copied' : formatCurrencyNumber(value) + ` ${currency}`}
                          onClick={handleCopyMetricNumber}
                        >
                          <IconButton
                            style={{ padding: '4px', marginLeft: '4px' }}
                            onMouseLeave={() => setIsCopied(false)}
                          >
                            <Icon type={'copy'} colorIcon={'#253746'} onClick={() => undefined} />
                          </IconButton>
                        </BackgroundTooltip>
                      )}
                    </Box>
                    <Typography
                      variant="h4"
                      className={clsx(
                        classes.percent,
                        arrow === 'down' && 'down',
                        arrow == 'down' ? 'warning' : 'normal',
                        'trending',
                        !forwardMeaning && 'forwardMeaning',
                        isNumberChartSmall ? 'trendingSmall' : '',
                      )}
                    >
                      {+percent !== 0 && <Arrow className={clsx(arrow === 'down' && 'down')} />}
                      {handlePercentAbs(percent) || 0}%
                    </Typography>
                  </Box>
                </div>
              )}
            </Box>
          )
        : (!ff.replace_keyword_compact || !isSimplified) && (
            <Box
              mb={title && stateChart === 'success' && value && '16px'}
              className={`eip1-chart-header`}
              style={{ width: isMobile ? '25%' : 'auto' }}
            >
              <Dropdown alignMenu="right" icon="threeDotsVertical" label="">
                <MenuList listMenu={dataMenu} />
              </Dropdown>
            </Box>
          )}

      {chartWithState()}
    </div>
  );
};

export default ChartState;
