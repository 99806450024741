import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import NewStorefront from '@ep/insight-ui/elements/new-storefront/new-storefront';

class PageNewStorefront implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <div>
        <NewStorefront />
      </div>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <PageNewStorefrontSettingForm data={data} onSubmit={handleSubmit} />;
  }
}

function PageNewStorefrontSettingForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'pageNewStorefront',
  label: 'New Storefront',
  blockComponent: PageNewStorefront,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 17,
  },
};
