import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { TextEditorStories as TextEditor } from '@ep/insight-ui/elements/text-editor/text-editor.stories';
import ExpansionSection from '@ep/insight-ui/elements/intraday/expansion-section';
import HeaderRight from '@ep/insight-ui/elements/table/table-actions/header-right';
import CollaborationRoom from '@ep/insight-ui/elements/intraday/collaboration-room';
import SectionActions from '@ep/insight-ui/elements/intraday/section-actions';
import { dropdownViewOption } from '@ep/insight-ui/elements/intraday/section-dropdown-view';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';

const roomOptions: dropdownViewOption[] = [{ id: 'beauty-group', name: 'Beauty Group' }];
const roomSelected: dropdownViewOption = { id: 'beauty-group', name: 'Beauty Group' };

class EChatSystem implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <NotificationGlobalProvider>
        <div style={{ padding: '12px' }}>
          <ExpansionSection defaultExpanded={true} label={'Collaboration room'} noSpacer headerRight={<HeaderRight />}>
            <SectionActions options={roomOptions} selected={roomSelected} section={`chat`} />
            <CollaborationRoom />
          </ExpansionSection>
        </div>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'eChatSystem',
  label: 'Message channel',
  blockComponent: EChatSystem,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 14,
  },
};
