import { request } from '@ep/one/src/utils';
import hash from 'object-hash';
import { EIP_EP } from '../enpoint';

const log = require('debug')('dbf:data-request');

const memCached = {};

export function dataRequest(
  queryParams: DataQueryParams,
  forceReload = false,
) {
  const filters = [].concat(queryParams.filters).concat([
    ['date', '>=', queryParams.dateRange.from],
    ['date', '<=', queryParams.dateRange.to],
  ]);
  const cachedKey = buildRequestKey(queryParams);

  if (memCached[cachedKey] && !forceReload) {
    log('cache hit', cachedKey);
    return Promise.resolve(memCached[cachedKey]);
  } else {
    log('cache missed', { cachedKey, forceReload });
    memCached[cachedKey] = request
      .post(EIP_EP.DATA_QUERY(), {
        dimensions: queryParams.dimensions,
        metrics: queryParams.metrics,
        filters: filters,
        orders: queryParams.orders,
        pagination: queryParams.pagination,
      })
      .then((data) => {
        memCached[cachedKey] = data;
        log('cached updated', cachedKey, data);
        return data;
      });
  }

  return Promise.resolve(memCached[cachedKey]);
}

export function getBuiltinEntities() {
  if (!memCached['builtin_entity']) {
    memCached['builtin_entity'] = request
      .get(EIP_EP.BUILTIN_ENTITY())
      .then((data) => {
        memCached['builtin_entity'] = data;
        return data;
      });
  }
  return Promise.resolve(memCached['builtin_entity']);
}

function buildRequestKey(queryParams: DataQueryParams) {
  const dimensionSet = new Set(queryParams.dimensions);
  const metricSet = new Set(queryParams.metrics);
  const filters = [].concat(queryParams.filters).concat([
    ['date', '>=', queryParams.dateRange.from],
    ['date', '<=', queryParams.dateRange.to],
  ]);
  const filterSet = new Set(filters.map((i) => i.join('')));

  const cachedKey = hash(
    [dimensionSet, metricSet, filterSet, queryParams.pagination],
    {
      unorderedArrays: true,
    },
  );

  return cachedKey;
}
