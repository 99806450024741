import { RowNode } from '@ag-grid-community/core';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueCurrencyFormat } from '@ep/insight-ui/elements/table/format/currency-format';
import TextFieldNumber from '@ep/insight-ui/elements/textField/textField-number';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { get, max, min, set, uniq, uniqBy } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/**
 * ff.mobile_interaction_zone:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.mobile_interaction_zone:end
 */

const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
  },
  select: {
    width: '100%',
  },
  formGroup: {},
  formControl: {
    width: '100%',
    '&.sm': {
      width: '70%',
    },
  },
}));

export interface OptionRTBType extends Omit<OptionSelectType, 'dataType'> {
  dataType: string;
}

export interface OperatorTypeRTB extends OptionSelectType {
  unit?: string;
  disabledActionButton?: boolean;
}
export const CONST_OPTION = {
  INCREASE: 'increase',
  DECREASE: 'decrease',
  SET_BID_TO: 'set-bid-to',
};
export type operatorRTBType = {
  [key: string]: OperatorTypeRTB[];
};
export const options: OptionRTBType[] = [
  {
    label: 'Increase',
    value: CONST_OPTION.INCREASE,
    // data type == key operator object
    dataType: 'increaseDecreaseOperator',
  },
  {
    label: 'Decrease',
    value: CONST_OPTION.DECREASE,
    dataType: 'increaseDecreaseOperator',
  },
  {
    label: 'Set bid to',
    value: CONST_OPTION.SET_BID_TO,
    dataType: 'setBidToOperator',
  },
];

export const CONST_OPERATOR = {
  BY_PERCENTAGE: 'By-percentage',
  BY_AMOUNT: 'by-amount',
  EXACT_VALUE: 'exact-value',
  SUGGEST_BID_PRICE: 'suggest-bid-price',
};

export const increaseDecreaseOperator: OperatorTypeRTB[] = [
  {
    label: 'By percentage',
    value: CONST_OPERATOR.BY_PERCENTAGE,
    unit: '%',
    disabledActionButton: false,
  },
  {
    label: 'By amount',
    value: CONST_OPERATOR.BY_AMOUNT,
    unit: '',
    disabledActionButton: false,
  },
];

export const setBidToOperator: OperatorTypeRTB[] = [
  {
    label: 'Exact value',
    value: CONST_OPERATOR.EXACT_VALUE,
    unit: '',
    disabledActionButton: false,
  },
  {
    label: 'Suggest bid price',
    value: CONST_OPERATOR.SUGGEST_BID_PRICE,
    unit: '',
    disabledActionButton: false,
  },
];
export const defaultOperators: operatorRTBType = {
  increaseDecreaseOperator,
  setBidToOperator,
};
const onLoadSingleActionTemp = (param: string[]): Promise<{ success: boolean; data: number[] }> => {
  return new Promise((resolve, reject) => {
    resolve({ success: true, data: [200] });
  });
};
const onLoadMassActionTemp = (param: string[]): Promise<{ success: boolean; data: number[] }> => {
  return new Promise((resolve, reject) => {
    resolve({ success: true, data: [200, 600, 800, 100, 200, 300, 1000, 1200, 1600, 100, 200] });
  });
};

type Props = {
  data: {
    value: IValueCurrencyFormat;
    node: RowNode;
    gridApi?: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  /**
   * params: array keyword name : string[]
   * response array number
   *  */
  onLoad?: (param: any) => Promise<{ success: boolean; data: number[] }>;
};
const RTBEditor = ({ data, title = '', payload, onLoad, onSubmit, setAnchorEl, anchorEl }: Props) => {
  const classes = useStyles();
  const inputRef = React.useRef();
  const [operators, setOperators] = React.useState<operatorRTBType>(defaultOperators);
  const [value, setValue] = React.useState<string>('0');
  const [option, setOption] = React.useState<OptionRTBType>({ label: '', value: '', dataType: '' });
  const [operator, setOperator] = React.useState<OperatorTypeRTB>({ label: '', value: '' });
  const [suggestedValues, setSuggestedValues] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  let isMobileView;
  if (ff.mobile_interaction_zone) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }

  React.useEffect(() => {
    if (Boolean(anchorEl) || (ff.mobile_interaction_zone && isMobileView)) {
      const dataValue = get(data, 'value.value', 0);
      if (dataValue !== value) setValue(String(dataValue || 0));

      const currency = get(data, 'value.currency', '');
      const defaultCurrency = get(data, 'value.defaultCurrency', '');
      const excludeOperator = get(payload, 'excludeOperator', []);
      const rowData = get(data, ['node', 'data'], {});
      const newOperators = Object.keys(operators).reduce((acc, value) => {
        const typeOperators = operators[value]
          .filter((o) => {
            const exclude = excludeOperator.find((i) => i.operator === o.value);
            if (exclude) {
              return !exclude.values.includes(rowData[exclude.field]);
            }
            return true;
          })
          .map((o) => {
            let unit = o.unit;
            if (o.unit != '%') {
              unit = currency !== '%' ? currency : defaultCurrency;
            }
            return { ...o, unit };
          });
        return { ...acc, [value]: typeOperators };
      }, {});

      // set by default
      setOption(options.find((item) => item.value == CONST_OPTION.SET_BID_TO));
      setOperator(setBidToOperator.find((item) => item.value == CONST_OPERATOR.EXACT_VALUE));
      setOperators(newOperators);
    }
  }, [data, anchorEl]);

  const initSuggestData = async () => {
    if (Boolean(onLoad)) {
      setLoading(true);
      const rowPicker = get(payload, ['rowPicker'], {});
      const primaryKey = get(payload, ['static', 'primaryKey']);
      const selectedRows = data.gridApi ? [...data.gridApi.getSelectedRows(), data.node.data] : [data.node.data];
      const res = await onLoad({
        marketplaceCode: get(data.node, ['data', rowPicker.marketplaceCode]),
        toolCodeIds: uniq(selectedRows.map((r) => r[rowPicker.toolCode])),
        storefrontIds: uniq(selectedRows.map((r) => r[rowPicker.storefrontId])),
        masterObjectIds: uniq(selectedRows.map((r) => r[rowPicker.masterObjectId])),
        keywords: uniq(selectedRows.map((r) => r[rowPicker.name])),
      });
      if (res.success) {
        // TODO: set initial suggest values
        const keywordPrices = res.data.map((i) => {
          const row = selectedRows.find((r) => r[rowPicker.name] === i.name);
          return {
            name: i.name,
            id: row ? row[primaryKey] : null,
            value: parseFloat(i.biddingPrice.toFixed(2)),
          };
        });
        setSuggestedValues(keywordPrices);
      }
      setLoading(false);
    }
  };

  const getOperatorByOptionType = (dataType) => {
    // setValue('');
    if (operators[dataType]) {
      return operators[dataType];
    }
    return [];
  };

  const handleOnchangeValue = (e) => {
    const { name } = e.target;
    setLoading(false);
    if (name == 'option') {
      const item = options.find((i) => i.value == e.target.value);
      if (item) {
        setOption(item);
        setOperator(operators[item.dataType][0]);
      }
    } else if (name == 'operator') {
      const item = operators[option.dataType].find((i) => i.value == e.target.value);
      if (item) {
        setOperator(item);
        if (item.value === 'suggest-bid-price') {
          initSuggestData();
        }
      }
    }
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const outputValue = { ...data.value };
    const field = get(payload, ['field', 'value']);
    const valueField = payload.field.value;
    const primaryKey = get(payload, ['static', 'primaryKey']);
    const selectedRows = data.gridApi ? [...data.gridApi.getSelectedRows(), data.node.data] : [data.node.data];

    let valueRequest: { id: string; value: number }[] = [];
    switch (option.value) {
      case CONST_OPTION.DECREASE: {
        switch (operator.value) {
          case CONST_OPERATOR.BY_PERCENTAGE: {
            valueRequest = selectedRows.map((row) => {
              const valueCell = row[valueField];
              return {
                id: row[primaryKey],
                value: valueCell - valueCell * (+value / 100),
              };
            });
            break;
          }
          case CONST_OPERATOR.BY_AMOUNT: {
            valueRequest = selectedRows.map((row) => {
              const valueCell = row[valueField];
              return {
                id: row[primaryKey],
                value: valueCell - +value,
              };
            });
            break;
          }
        }
        break;
      }
      case CONST_OPTION.INCREASE: {
        switch (operator.value) {
          case CONST_OPERATOR.BY_PERCENTAGE: {
            valueRequest = selectedRows.map((row) => {
              const valueCell = row[valueField];
              return {
                id: row[primaryKey],
                value: valueCell + valueCell * (+value / 100),
              };
            });
            break;
          }
          case CONST_OPERATOR.BY_AMOUNT: {
            valueRequest = selectedRows.map((row) => {
              const valueCell = row[valueField];
              return {
                id: row[primaryKey],
                value: valueCell + +value,
              };
            });
            break;
          }
        }
        break;
      }
      case CONST_OPTION.SET_BID_TO: {
        switch (operator.value) {
          case CONST_OPERATOR.EXACT_VALUE: {
            valueRequest = selectedRows.map((row) => ({
              id: row[primaryKey],
              value: +value,
            }));
            break;
          }
          case CONST_OPERATOR.SUGGEST_BID_PRICE: {
            valueRequest = suggestedValues;
            break;
          }
        }
        break;
      }
    }
    set(outputValue, ['value'], {
      isMultiValue: true,
      field,
      data: uniqBy(valueRequest, 'id'),
    });
    setIsSubmitting(true);
    const response: any = await onSubmit({ value: outputValue, row: data.node.data });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  const handleValue = () => {
    if (operator.value !== CONST_OPERATOR.SUGGEST_BID_PRICE) return value;
    const suggestPrices = suggestedValues.map((i) => i.value);
    if (suggestPrices.length == 1) {
      return get(suggestPrices, [0], '');
    }

    if (suggestPrices.length > 1) {
      const suggestedMin = min(suggestPrices);
      const suggestedMax = max(suggestPrices);

      return `${suggestedMin} ➞ ${suggestedMax}`;
    }
    return '';
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0 10px', minWidth: ff.mobile_interaction_zone && isMobileView ? null : '504px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_change_${titleActionClass}`}
    >
      <Box sx={{ display: 'flex' }}>
        <Box>
          <HeaderList
            title={title}
            variant="h4"
            className={classes.header}
            iconHead={
              loading ? (
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
      <div className={classes.dialog}>
        <Grid container wrap={'nowrap'} justifyContent="space-between" spacing={2} className={classes.formGroup}>
          <Grid item className={`${classes.formControl} sm`}>
            <SelectForm
              options={options}
              name={'option'}
              value={option.value}
              onChange={handleOnchangeValue}
              className={classes.select}
            />
          </Grid>
          <Grid item className={`${classes.formControl} sm`}>
            <SelectForm
              options={getOperatorByOptionType(option.dataType)}
              value={operator.value}
              name={'operator'}
              onChange={handleOnchangeValue}
              className={classes.select}
            />
          </Grid>
          <Grid item className={classes.formControl}>
            <TextFieldNumber
              inputRef={inputRef}
              disabled={operator.value == CONST_OPERATOR.SUGGEST_BID_PRICE}
              value={handleValue()}
              style={{ width: '100%', marginLeft: 0 }}
              placeholder={'Your value'}
              onChangeNumber={(number) => setValue(String(number))}
              unit={operator.unit}
              timeDebounce={0}
            />
          </Grid>
        </Grid>
      </div>
      <GroupButtonSubmit
        onCancel={handleClosed}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        title={title}
        optionValue={operator.value}
      />
    </div>
  );
};

export default RTBEditor;
