import { Box, Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import * as _ from 'lodash';
import clsx from 'clsx';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
const useStyles = makeStyles((theme) => ({
  '@keyframes rightTransform': {
    '0%': {
      transform: 'translateX(5px)',
    },
    '50%': {
      transform: 'translateX(-10px)',
    },
    '100%': {
      transform: 'translateX(5px)',
    },
  },
  '@keyframes leftTransform': {
    '0%': {
      transform: 'translateX(-5px)',
    },
    '50%': {
      transform: 'translateX(10px)',
    },
    '100%': {
      transform: 'translateX(-5px)',
    },
  },
  '@keyframes changeLeftZIndex': {
    '0%, 49.9%': {
      zIndex: 1,
    },
    '50%, 100%': {
      zIndex: 2,
    },
  },
  '@keyframes changeRightZIndex': {
    '0%, 49.9%': {
      zIndex: 2,
    },
    '50%, 100%': {
      zIndex: 1,
    },
  },
  stateLoading: {
    display: 'flex',
    alignItems: 'center',
    '& .right': {
      animation: `$rightTransform 1000ms ${theme.transitions.easing.easeIn} infinite, $changeRightZIndex 1000ms infinite`,
      position: 'relative',
    },
    '& .left': {
      animation: `$leftTransform 1000ms ${theme.transitions.easing.easeIn} infinite, $changeLeftZIndex 1000ms infinite`,
      position: 'relative',
    },
  },
  state: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .eip1-MuiTypography-root': {
      fontWeight: 400,
    },
  },
}));
const LoadingIcon = ({
  color = '#253746',
  size = '6px',
  secondaryColor = colors.icon.warning,
}: {
  color?: string;
  size?: string;
  secondaryColor?: string;
}) => {
  const classes = useStyles();

  return (
    <Box className={clsx(classes.state)} width={'28px'} mx={'10px'}>
      <Box className={clsx(classes.stateLoading)}>
        <Box className={clsx('left')} width={size} height={size} borderRadius="100%" bgcolor={color} />
        <Box
          className={clsx('right')}
          width={size}
          height={size}
          borderRadius="100%"
          bgcolor={['#fff', '#ffffff'].includes(String(color).toLowerCase()) ? color : secondaryColor}
        />
      </Box>
    </Box>
  );
};

export default LoadingIcon;
