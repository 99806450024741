import * as React from 'react';

import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import TextareaWithPlugins from '@ep/insight-ui/elements/textField/textarea-with-plugins';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    '& .eip1-MuiCheckbox-root': {
      marginRight: 0,
    },
    '& .eip1-MuiFormControlLabel-root': {
      marginRight: 0,
    },
    '& .eip1-MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    '& .eip1-MuiMenu-paper': {
      paddingTop: '8px',
    },
  },
  formGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  label: {
    flex: '0 0 80px',
  },
  field: {
    width: '150px',
    '& .boxValue li': {
      marginLeft: '8px',
    },
    '.vs-dark & .eip1-MuiTouchRipple-root': {
      background: '#1e1e1e',
    },
    '.vs-dark & span': {
      color: '#fff',
    },
  },
  numberInput: {
    '& input': {
      textAlign: 'right',
    },
  },
  fieldSelect: {
    width: '150px',
  },
  fieldJSON: {
    width: '100%',
    '& textarea': {
      borderColor: '#c2c7cb',
      borderRadius: '4px',
      resize: 'vertical',
    },
  },
  selection: {
    paddingTop: '8px',
    marginTop: '8px',
  },
}));

const MODE_TRACE = '1';
const MODE_SPAN = '2';
const MODE_TRACE_SUMMARY = '3';
const MODE_SCRIPT = '4';

const modeOptions = [
  {
    label: 'Trace',
    value: MODE_TRACE,
  },
  {
    label: 'Span',
    value: MODE_SPAN,
  },
  {
    label: 'Trace summary',
    value: MODE_TRACE_SUMMARY,
  },
  {
    label: 'Script',
    value: MODE_SCRIPT,
  },
];

const RayConfig = ({ detail, setDetail }: any) => {
  const classes = useStyles();
  const { serviceId } = useParams();

  const [periodChecked, setPeriodChecked] = React.useState(detail?.updatePeriod != 0);

  const handleUpdateField = (key, value) => {
    return setDetail((prevValue) => ({
      ...prevValue,
      [key]: value,
    }));
  };

  return (
    <div className={classes.container}>
      {serviceId === 'hyper-integration' ? (
        <div className={classes.formGroup}>
          <div className={classes.label}>Type</div>
          <div className={classes.field}>
            <TextFieldCustom
              onChangeText={(value) => {
                handleUpdateField('type', value);
              }}
              defaultValue={detail?.type}
              suffix={' '}
            />
          </div>
        </div>
      ) : null}
      <div className={classes.formGroup}>
        <div className={classes.label}>Interval</div>
        <div className={classes.field}>
          <TextFieldCustom
            onChangeText={(value) => {
              handleUpdateField('updateInterval', value);
            }}
            defaultValue={detail?.updateInterval}
            suffix={' '}
            className={classes.numberInput}
          />
        </div>
        <span>minute(s)</span>
        <FormControlLabel
          label={''}
          control={
            <Checkbox
              checked={periodChecked}
              onChange={(event) => {
                const checked = event.target.checked;
                setPeriodChecked(event.target.checked);
                if (!checked) {
                  handleUpdateField('updatePeriod', 0);
                }
              }}
              color={'primary'}
            />
          }
        />
        {periodChecked ? (
          <div className={classes.formGroup}>
            <div>Period</div>
            <div className={classes.field}>
              <TextFieldCustom
                onChangeText={(value) => {
                  handleUpdateField('updatePeriod', value);
                }}
                defaultValue={detail?.updatePeriod || 0}
                suffix={' '}
                className={classes.numberInput}
              />
            </div>
            <span>minute(s)</span>
          </div>
        ) : null}
      </div>
      <div className={classes.formGroup}>
        <div className={classes.label}>Mode</div>
        <div className={classes.field}>
          <SelectMultipleForm
            options={modeOptions}
            value={String(detail?.mode)}
            onChange={([v]) => handleUpdateField('mode', v)}
            multipleSelect={false}
            searchAble={false}
            isConfigChart={true}
            classNamePaper={classes.selection}
          />
        </div>
      </div>
      <div className={classes.formGroup}>
        <div className={classes.label}>Import</div>
        <div style={{ width: '100%' }}>
          <TextFieldCustom
            onChangeText={(value) => {
              handleUpdateField('ref', value);
            }}
            defaultValue={detail?.ref || ''}
            suffix={' '}
          />
        </div>
      </div>
      {detail?.mode != MODE_SCRIPT ? (
        <div className={classes.formGroup}>
          <div className={classes.label}>Filter</div>
          <div className={classes.fieldJSON}>
            <TextareaWithPlugins
              onChange={(value) => {
                handleUpdateField('filter', value);
              }}
              value={detail?.filter || '{}'}
              isSupportJSON
              minRows={7}
              maxRows={7}
            />
          </div>
        </div>
      ) : null}
      {['1', '3'].includes(String(detail?.mode)) ? (
        <div className={classes.formGroup}>
          <div className={classes.label}>Filter root</div>
          <div className={classes.fieldJSON}>
            <TextareaWithPlugins
              onChange={(value) => {
                handleUpdateField('filterRoot', value);
              }}
              value={detail?.filterRoot || '{}'}
              isSupportJSON
              minRows={7}
              maxRows={7}
            />
          </div>
        </div>
      ) : null}
      {['1'].includes(String(detail?.mode)) ? (
        <div className={classes.formGroup}>
          <div className={classes.label}>Filter detail</div>
          <div className={classes.fieldJSON}>
            <TextareaWithPlugins
              onChange={(value) => {
                handleUpdateField('filterDetail', value);
              }}
              value={detail?.filterDetail || '{}'}
              isSupportJSON
              minRows={7}
              maxRows={7}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default RayConfig;
