import { get, escape, set } from 'lodash';
import { isFormulaField, toValue } from '@ep/insight-ui/sw/util/excel-formula';
import * as request from '@ep/insight-ui/sw/util/request';
import { getMetricDefinition, produceQueryResult } from '../data/common';
import produce from 'immer';
import { universalPrefixListField } from '../../util/column';
import { getQueryDatetimeValue } from '../../util/calendar';

export async function pivotTable(originData, { config, contextQuery, cellUpdate, pageRequests }, next) {
  const mapping = get(config, 'mapping', {});
  const groupPeriod = get(config, 'groupPeriod', '');
  const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
  const sfMetricColKey = Object.keys(mapping).find(
    (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
  );
  const dateRange = get(config, 'dateRange');
  const sfMetricCol = get(config, ['mapping', sfMetricColKey]);
  const datetimeFieldRequest = get(config, ['system', 'datetimeField'], 'created_datetime');

  if (!sfMetricColKey || !groupPeriod || !mapping[groupPeriod])
    return next(originData, { config, contextQuery, cellUpdate, pageRequests });

  const specialFilters = Object.values(get(config, 'specialFilters', {})).map((el) => {
    return {
      field: el.queryField,
      operator: el.queryType,
      value: el.queryValue,
    };
  });

  const pivotFields = Object.values(get(mapping, [groupPeriod, 'valueGetter'], {})).filter((f) => !isFormulaField(f));
  const pivotGroupByField = get(
    mapping,
    [groupPeriod, 'valueGetter', 'value'],
    get(mapping, [groupPeriod, 'valueGetter', 'id']),
  );

  const params = {
    dimensions: [],
    ...universalPrefixListField({
      attributes: [].concat(pivotFields),
      metrics: [],
    }),
    pagination: {
      page: 1,
      limit: 20,
    },
    groupBy: {
      column: [].concat(pivotGroupByField),
      aggregations: pivotFields
        .filter((f) => f !== pivotGroupByField)
        .map((f) => {
          return { field: f, func: 'MAX' };
        }),
    },
    sort: null,
    filter: {
      combinator: 'AND',
      filters: [
        {
          field: datetimeFieldRequest,
          operator: '>=',
          value: getQueryDatetimeValue(dateRange.dateFrom, 'start'),
        },
        {
          field: datetimeFieldRequest,
          operator: '<=',
          value: getQueryDatetimeValue(dateRange.dateEnd, 'end'),
        },
        ...specialFilters,
      ],
    },
    hiddenFilter: {
      currency: 'USD',
    },
    currency: 'USD',
    isSummary: true,
    groupPeriod: 'all',
  };

  const { columns, pivotValues } = await request
    .post(endpoint, params)
    .then(async (res) => {
      const result = produceQueryResult(res, mapping);

      const idField = pivotGroupByField;
      const labelField = get(mapping, [groupPeriod, 'valueGetter', 'label']);

      let getLabel = (context) => context['label'];
      if (isFormulaField(labelField)) {
        getLabel = (context) => toValue(labelField, context);
      }

      const groupData = result.rows;

      const colHeaders = groupData.map((el) => {
        const context = Object.entries(get(mapping, [groupPeriod, 'valueGetter'])).reduce((carry, [k, f]) => {
          return { ...carry, [k]: el[f] };
        }, {});
        return {
          field: `value_${escape(String(el[idField]))}`,
          name: String(getLabel(context)),
          dataType: 'string',
          action: [],
          cell: {
            format: 'currencyFormatFormula',
            valueGetter: {
              value: escape(`value_${el[idField]}`),
              label: escape(`value_${el[idField]}`),
              currency: 'currency',
            },
            staticValue: {},
            action: [],
          },
        };
      });

      colHeaders.unshift({
        field: sfMetricColKey,
        name: sfMetricCol.title,
        dataType: 'string',
        action: [],
        cell: {
          format: 'textFormatFormula',
          valueGetter: {
            value: 'metric',
            label: 'metric',
          },
          staticValue: {},
          action: [],
        },
      });
      return { columns: colHeaders, pivotValues: groupData };
    })
    .catch((e) => {
      return { columns: [], pivotValues: [] };
    });

  const data = await queryData(
    { config, contextQuery },
    {
      queryField: pivotGroupByField,
      values: pivotValues.map((el) => el[pivotGroupByField]),
    },
    sfMetricColKey,
  );

  return { columns, data };
}

async function queryData({ config, contextQuery: params }, pivot: { values; queryField }, storefrontMetricCol) {
  const mapping = get(config, 'mapping', {});
  const endpoint = get(config, 'endpoint.GET_TABLE_DATA');
  const resourceMetric = await getMetricDefinition(endpoint);

  const storefrontMetric = storefrontMetricCol;
  const selectedMetrics = get(config, ['selectedMetricTraction'], []);
  const properties = get(config, ['metricTractionProperties'], []);
  const storefrontMetricField = get(storefrontMetric, ['valueGetter', 'value'], 'metric');
  const currency = get(params, ['hiddenFilter', 'currency'], '');

  const selectedOrderedMetrics = properties.filter(({ id }) => selectedMetrics.includes(id)).map(({ id }) => id);

  const filter = produce(params.filter || { combinator: 'AND', filters: [] }, (draft) => {
    set(
      draft,
      'filters',
      get(draft, 'filters', []).concat({ field: pivot.queryField, operator: 'IN', value: pivot.values }),
    );
  });

  const requestParams = {
    ...params,
    filter: filter,
    // table_context: getTableContext(mapping),
    attributes: [pivot.queryField],
    groupBy: { column: [pivot.queryField] },
    isSummary: true,
    metrics: selectedOrderedMetrics,
  };

  const result = await request.post(endpoint, requestParams);

  const groupedEnrichedRows = produceQueryResult(result, mapping);

  const groupedRows = selectedOrderedMetrics.map((el) => {
    const metricInfo = resourceMetric.find((ele) => ele.value === el);
    // const findRow = groupedEnrichedRows.rows.find(i => i[pivot.queryField]);
    const item = groupedEnrichedRows.rows.reduce((carry, r) => {
      const groupCol = escape(`value_${r[pivot.queryField]}`);
      return { ...carry, [groupCol]: r[el] };
    }, {});

    return {
      ...item,
      [storefrontMetricField]: metricInfo.label_raw,
      currency: metricInfo.prefix_value === '$' ? currency : metricInfo.prefix_value,
    };
  });

  if (groupedRows.length > 0) {
    const headers = Object.keys(groupedRows[0]);

    return {
      success: true,
      message: 'ok',
      data: {
        headers: headers,
        rows: groupedRows,
      },
    };
  } else {
    return {
      success: true,
      message: 'ok',
      data: {
        headers: [],
        rows: [],
      },
    };
  }
}
