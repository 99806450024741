import { first, get, uniq } from 'lodash';
import * as React from 'react';
import CreatableSelect from 'react-select/creatable';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { LicenseManager } from '@ag-grid-enterprise/core';

import { Box, Button, Dialog, DialogContent, makeStyles, Popover, TextField } from '@material-ui/core';

import ExpansionTable from '@ep/insight-ui/elements/table/expansion-table';
import EmptyRows from '@ep/insight-ui/elements/table/format/no-rows-data-format';
import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { CELL_FORMAT_STATIC_KEYS } from '@ep/insight-ui/system/block/etable/cell-format-object-keys';
import { CELL_FORMAT_OPTIONS } from '@ep/insight-ui/system/block/etable/cell-format-options';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

import CalculateCellFormat from './cell-format/calculate-cell-format';
import CompactFormat from './cell-format/compact-format';
import SelectFormat from './cell-format/select-format';
import { EtableConfigContext } from './context';
import { FormulaEditor } from './formula-editor';
import { useEtableConfig } from './hooks/use-etable-config';
import Wrapper from '@ep/insight-ui/elements/etable2/wrapper';
import { getActionColDef } from './utils/action-col-def';
import { getAdvancedFilterColDef } from './utils/advanced-filter-col-def';
import { CellEditorInputFormat, CellEditorViewFormat } from './cell-format/input-format';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import TextareaWithPlugins from '@ep/insight-ui/elements/textField/textarea-with-plugins';
import clsx from 'clsx';
import { withWrapperFormat } from './cell-format/wrapper-format';
import Icon from '@ep/insight-ui/icons/Icon';
import { atom, Provider, useAtomValue, useSetAtom } from 'jotai';

LicenseManager.setLicenseKey(process.env.AGGRID_LICENSE || process.env.STORYBOOK_AGGRID_LICENSE);
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const useStyles = makeStyles({
  toggle: {
    marginLeft: 0,
    '&.Mui-expanded': {
      marginLeft: '0!important',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    rowGap: '16px',
    '& .ag-cell-value': {
      alignItems: 'center',
      padding: '0 8px',
    },
    '& .ag-cell-value .ag-react-container': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ag-cell-value.ag-cell-focus': {
      background: '#ebf6ff',
    },
    '&.ag-theme-alpine .ag-ltr .ag-row-drag': {
      marginRight: 0,
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '1px solid #f8fafd',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '& .cell-checkbox .ag-cell-value': {
      padding: 0,
    },
    '& .header-checkbox .ag-header-select-all': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::before': {
      backgroundColor: '#FFF',
    },
    '& .ag-row': {
      cursor: 'grabbing',
    },
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  headerGroup: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '8px',
    },
  },
  headerGroupTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  headerInput: {
    flex: '0 0 100%',
    '& > div': {
      width: '100%',
    },
  },
  closeButton: {},
  settingExpansion: {
    width: '100%',
  },
  recommendEndpoint: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  listItem: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
    borderRadius: '4px',
  },
  textarea: {
    '& textarea': {
      borderColor: '#0000003b',
      borderRadius: '4px',
      resize: 'vertical',
      padding: '4px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
});

const useControlStyles = makeStyles({
  input: {
    width: '100%',
    border: 0,
    '&:focus': {
      border: 0,
      outline: 0,
    },
    '&:focus-visible': {
      outline: 0,
    },
  },
});

const useHeaderStyles = makeStyles({
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  action: {
    cursor: 'pointer',
  },
});

const pinnedColumns = atom([]);

const formatColumnKey = (value) => {
  const newKey = value.toLowerCase().replace(/\s/g, '_');
  return newKey;
};

const PopoverComponent = ({ ChildComponent, title, eTableEndpoint, gridApi, mapping, ...rest }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        {title}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Wrapper>
          <ChildComponent
            handleClose={handleClose}
            eTableEndpoint={eTableEndpoint}
            gridApi={gridApi}
            mapping={mapping}
            {...rest}
          />
        </Wrapper>
      </Popover>
    </>
  );
};

const SelectInput = (props: any) => {
  const classes = useControlStyles();
  const [input, setInput] = React.useState(props?.selectProps?.value?.value || '');

  React.useEffect(() => {
    setInput(props?.selectProps?.value?.value);
  }, [props?.selectProps?.value?.value]);

  return (
    <input
      {...props}
      onChange={(e) => {
        props.onChange(e);
        setInput(e.target.value);
      }}
      onBlur={(e) => {
        props.setValue({
          label: e.target.value,
          value: e.target.value,
        });
      }}
      value={input}
      className={classes.input}
    />
  );
};

const SingleValue = () => <></>;

const HeaderCell = (props) => {
  const pinThisColumns1 = useAtomValue(pinnedColumns);
  const setPinThisColumns = useSetAtom(pinnedColumns);

  const classes = useHeaderStyles();
  const displayName = props.displayName;
  const colId = props.column.colId;
  const handlePin = () => {
    setPinThisColumns(
      pinThisColumns1.includes(colId) ? pinThisColumns1.filter((i) => i != colId) : pinThisColumns1.concat(colId),
    );
  };
  if (!displayName) return null;

  return (
    <div className={classes.container}>
      <span className={classes.label}>{displayName}</span>
      <div className={classes.action} onClick={handlePin}>
        <Icon
          type={
            !pinThisColumns1.includes(colId)
              ? 'ic/material-symbols:push-pin-outline/charcoal/16px'
              : 'ic/material-symbols:push-pin/charcoal/16px'
          }
        />
      </div>
    </div>
  );
};

const ETableConfig = ({
  nodeId,
  config,
  open,
  onClose,
  onSubmit,
  isCompactConfig = false,
  isDashboardConfig = false,
}: {
  nodeId: string;
  config: any;
  open: boolean;
  onClose: any;
  onSubmit: any;
  isCompactConfig?: boolean;
  isDashboardConfig?: boolean;
}) => {
  const classes = useStyles();
  const {
    gridApi,
    setGridApi,
    eTableEndpoint,
    setETableEndpoint,
    eTableTitle,
    setEtableTitle,
    defaultPrimaryKeys,
    primaryKeysOptions,
    handlePrimaryKeysChange,
    tableDOM,
    defaultRowData,
    actionButtons,
    view,
    views,
    handleSubmit,
    isEmpty,
    handleRowChange,
    columnApi,
    setColumnApi,
    recommendEndpoint,
    chartEndpoint,
    setChartEndpoint,
    metricEndpoint,
    setMetricEndpoint,
    etableConfigContext,
    endpointOptions,
    handleChangeEndpoint,
    etableList,
    setDatetimeField,
    defaultDatetimeField,
    roles,
    eTableDescription,
    setETableDescription,
  } = useEtableConfig({ config, onSubmit, isDashboardConfig, open });

  const pinThisColumns1 = useAtomValue(pinnedColumns);

  const handleBodyScroll = ({ left, direction }) => {
    if (direction === 'horizontal' && pinThisColumns1.length > 0) {
      // Check if columns are active
      const activePinnedColumns = pinThisColumns1.filter((pinnedColumn) => {
        const column = columnApi.getColumn(pinnedColumn);
        if (!column) return false;
        if (column.isPinnedLeft() && column.isVisible()) {
          // Left value of the right column of current pinned column is also current left value of pinned column
          const currentIndex = columnApi.getAllGridColumns().findIndex((el) => el.getColId() === column.getColId());

          const columnRight = columnApi.getAllGridColumns().find((el, i) => {
            return i > currentIndex && !el.isPinnedLeft() && el.isVisible();
          });
          if (columnRight && left <= columnRight.getLeft()) {
            return false;
          }
          return true;
        }
        return left > column.getLeft();
      });
      activePinnedColumns.forEach((pCol) => {
        const col = columnApi.getColumn(pCol);
        if (!col.isPinnedLeft()) {
          columnApi.setColumnPinned(pCol, 'left');
        }
      });
      pinThisColumns1.forEach((i) => {
        if (!activePinnedColumns.some((i1) => i1 === i)) {
          columnApi.setColumnPinned(i, null);
        }
      });
    }
  };

  const rowData = React.useMemo(() => {
    return JSON.parse(defaultRowData).map((r) => {
      const eTableEditor = CELL_FORMAT_OPTIONS[r.cellFormat]?.eTableEditor;
      if (eTableEditor) {
        return {
          ...r,
          eTableEditor,
        };
      }
      return r;
    });
  }, [open]);

  // Auto fill staticValue if table has traction format cell and does not have staticValue for it
  React.useEffect(() => {
    Object.keys(config.mapping).forEach((field) => {
      if (config.mapping[field].cellFormat === 'traction') {
        const nodeData = rowData.find(({ columnKeys }) => columnKeys === field);
        if (nodeData) {
          const { staticValue } = nodeData;
          if (!staticValue || (staticValue && !staticValue.timeRange && !staticValue.period)) {
            nodeData.staticValue = CELL_FORMAT_STATIC_KEYS.traction;
          }
        }
      }
    });
  }, []);

  const columnDefs: AgGridColumnProps[] = React.useMemo(() => {
    const propertyTypeOptions = ['dimension', 'attribute', 'metric', 'requestHiddenField'];
    const cellFormatOptions = Object.keys({ ...CELL_FORMAT_OPTIONS, ...cellFormat });
    const dataTypeOptions = ['string', 'integer', 'float', 'datetime', 'date'];
    const availAggregation = [
      'NONE',
      'UNIQUE',
      'SUM',
      'AVG',
      'MIN',
      'MAX',
      'COUNT_ALL',
      'COUNT_VALUES',
      'COUNT_UNIQUE',
      'COUNT_EMPTY',
      'COUNT_NOT_EMPTY',
      'PERCENT_EMPTY',
      'PERCENT_NOT_EMPTY',
      'LIST',
      'RANGE',
    ];
    const mapping = get(config, 'mapping', {});
    const sortFilterFields = uniq(
      Object.values(mapping as Record<string, any>).reduce((a, b) => {
        return [...a, ...Object.values(get(b, ['valueGetter'], {}))];
      }, []),
    ).map((el) => ({
      label: el,
      value: el,
    }));
    const yesNoOptions = [
      { label: 'yes', value: 1 },
      { label: 'no', value: 0 },
    ];
    return [
      {
        field: 'checkbox',
        pinned: 'left',
        maxWidth: 40,
        resizable: false,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellClass: 'cell-checkbox',
        headerClass: 'header-checkbox',
        headerName: '',
      },
      {
        field: 'cellFormat',
        editable: true,
        headerName: 'Column',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: cellFormatOptions,
          field: 'cellFormat',
          mapping: config.mapping,
          hasSearch: true,
          // Add this property to use custom option in select format
          // allowCustomOption: true,
          labelFormula: `=${JSON.stringify(CELL_FORMAT_OPTIONS)}[p('value')]?.label || p('value')`,
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'title',
        editable: true,
        cellEditor: CellEditorInputFormat,
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return { component: CellEditorViewFormat };
        },
        minWidth: 150,
      },
      {
        field: 'columnKeys',
        headerName: 'Column key',
        editable: true,
        cellEditor: CellEditorInputFormat,
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return { component: CellEditorViewFormat };
        },
        minWidth: 150,
      },
      {
        field: 'columnDescription',
        headerName: 'Column Description',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: [],
          field: 'columnDescription',
          mapping: config.mapping,
          hasSearch: true,
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'valueGetter',
        headerName: 'Data point mapping',
        editable: true,
        cellEditor: withWrapperFormat(CompactFormat),
        cellRenderer: withWrapperFormat(CompactFormat),
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      { ...getActionColDef(etableConfigContext, etableList, roles) },
      {
        field: 'filterField',
        headerName: 'Filter field',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: sortFilterFields,
          hasSearch: true,
          field: 'filterField',
          allowCustomOption: true,
        },
        editable: true,
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      { ...getAdvancedFilterColDef(etableConfigContext, etableList, cellFormatOptions) },
      {
        field: 'selectionFilter',
        headerName: 'Selection filter',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: yesNoOptions,
          field: 'selectionFilter',
          labelFormula: "=IF(p('value') == 1, 'yes', IF(p('value') == 0, 'no', ''))",
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'sortField', // default if blank will pick filterField
        headerName: 'Sort field',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: sortFilterFields,
          hasSearch: true,
          field: 'sortField',
          allowCustomOption: true,
        },
        editable: true,
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'staticValue',
        headerName: 'Column settings',
        editable: true,
        cellRenderer: withWrapperFormat(CompactFormat),
        cellRendererParams: {
          etableConfigContext,
          etableList,
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'systemSettings',
        headerName: 'System settings',
        editable: true,
        cellRenderer: withWrapperFormat(CompactFormat),
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'initColumnWidth',
        headerName: 'Init Column Width',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: [
            {
              label: 'Auto fit content',
              value: EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT,
            },
          ],
          hasSearch: true,
          field: 'initColumnWidth',
          allowCustomOption: true,
          onBlur(props) {
            if (Number.isNaN(Number(props.data?.initColumnWidth))) {
              props.node?.setDataValue('initColumnWidth', EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT);
            }
          },
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'lockColumnWidth',
        headerName: 'Width lock',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: yesNoOptions,
          field: 'lockColumnWidth',
          labelFormula: "=IF(p('value') == 1, 'yes', IF(p('value') === 0, 'no', ''))",
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'initGroupColumnWidth',
        headerName: 'Init Group Column Width',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: [
            {
              label: 'Auto fit content',
              value: EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT,
            },
          ],
          hasSearch: true,
          field: 'initGroupColumnWidth',
          allowCustomOption: true,
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'pivotColumnWidth',
        headerName: 'Pivot Column Width',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: [
            {
              label: 'Auto fit content',
              value: EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT,
            },
          ],
          hasSearch: true,
          field: 'pivotColumnWidth',
          allowCustomOption: true,
          onBlur(props) {
            if (Number.isNaN(Number(props.data?.pivotColumnWidth))) {
              props.node?.setDataValue('pivotColumnWidth', EIP_CONSTANT.ETABLE.COLUMN_AUTO_FIT_CONTENT);
            }
          },
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'defaultGroupBy',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: yesNoOptions,
          field: 'defaultGroupBy',
          labelFormula: "=IF(p('value') == 1, 'yes', IF(p('value') == 0, 'no', ''))",
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'defaultCalculated',
        headerName: 'Default calculated',
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: availAggregation,
          field: 'defaultCalculated',
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'propertyType',
        headerName: 'Property type',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: propertyTypeOptions,
          field: 'propertyType',
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
      {
        field: 'dataType',
        headerName: 'Data type',
        editable: true,
        cellRenderer: withWrapperFormat(SelectFormat),
        cellRendererParams: {
          options: dataTypeOptions,
          field: 'dataType',
        },
        cellRendererSelector(params) {
          if (params.node.rowPinned) {
            return {
              frameworkComponent: CalculateCellFormat,
            };
          }
          return undefined;
        },
        minWidth: 150,
      },
    ];
  }, [etableConfigContext, etableList]);

  const defaultColDef = React.useMemo(() => {
    return {
      flex: 1,
      editable: true,
      resizable: true,
      minWidth: 150,
    };
  }, []);

  const actionButtonsRender = actionButtons.map((btn) => {
    if (btn.popoverComponent) {
      return (
        <PopoverComponent
          ChildComponent={btn.popoverComponent}
          title={btn.title}
          eTableEndpoint={eTableEndpoint}
          gridApi={gridApi}
          {...btn.params}
        />
      );
    }
    return (
      <Button key={btn.id} variant="contained" color="secondary" onClick={btn.onClick}>
        {btn.title}
      </Button>
    );
  });

  return (
    <EtableConfigContext.Provider value={etableConfigContext}>
      <Dialog open={open} fullWidth={true} maxWidth={isCompactConfig ? 'lg' : 'xl'}>
        <DialogContent className={classes.container}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Box className={`ag-theme-alpine body-container ${classes.container}`} ref={tableDOM}>
              <ExpansionTable
                defaultExpanded={false}
                className={`${classes.toggle} ${classes.settingExpansion}`}
                label={'Settings'}
                noSpacer
                headerRight={null}
              >
                <Box className={classes.headerSection}>
                  <Box className={classes.headerGroup}>
                    <Box>
                      <span className={classes.headerGroupTitle}>Title:</span>
                    </Box>
                    <Box className={classes.headerInput}>
                      <TextField
                        variant="outlined"
                        value={eTableTitle}
                        onChange={(e) => setEtableTitle(e.target.value)}
                      />
                    </Box>
                  </Box>

                  {isDashboardConfig ? (
                    <>
                      <Box className={classes.headerGroup}>
                        <Box>
                          <span className={classes.headerGroupTitle}>Metric Endpoint:</span>
                        </Box>
                        <Box className={classes.headerInput}>
                          <TextField
                            variant="outlined"
                            value={metricEndpoint}
                            onChange={(e) => setMetricEndpoint(e.target.value)}
                          />
                        </Box>
                      </Box>
                      <Box className={classes.headerGroup}>
                        <Box>
                          <span className={classes.headerGroupTitle}>Chart Endpoint:</span>
                        </Box>
                        <Box className={classes.headerInput}>
                          <TextField
                            variant="outlined"
                            value={chartEndpoint}
                            onChange={(e) => setChartEndpoint(e.target.value)}
                          />
                        </Box>
                      </Box>
                    </>
                  ) : (
                    <Box className={classes.headerGroup}>
                      <Box>
                        <span className={classes.headerGroupTitle}>Endpoint:</span>
                      </Box>
                      <Box className={classes.headerInput}>
                        <CreatableSelect
                          onChange={handleChangeEndpoint}
                          options={endpointOptions}
                          value={eTableEndpoint}
                          components={{ Input: SelectInput, SingleValue }}
                        />
                      </Box>
                      {recommendEndpoint && (
                        <span>
                          <span>Click to use suggest endpoint:</span>
                          <span> </span>
                          <span
                            className={classes.recommendEndpoint}
                            onClick={() => setETableEndpoint({ label: recommendEndpoint, value: recommendEndpoint })}
                          >
                            {recommendEndpoint}
                          </span>
                        </span>
                      )}
                    </Box>
                  )}
                  <Box className={classes.headerGroup}>
                    <Box>
                      <span className={classes.headerGroupTitle}>Primary keys:</span>
                    </Box>
                    <Box className={classes.headerInput}>
                      <CreatableSelect
                        isMulti
                        onChange={handlePrimaryKeysChange}
                        options={primaryKeysOptions}
                        defaultValue={defaultPrimaryKeys}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.headerGroup}>
                    <Box>
                      <span className={classes.headerGroupTitle}>Datetime Query Field:</span>
                    </Box>
                    <Box className={classes.headerInput}>
                      <CreatableSelect
                        onChange={(e) => setDatetimeField(e.value)}
                        options={primaryKeysOptions}
                        defaultValue={defaultDatetimeField}
                      />
                    </Box>
                  </Box>
                  <Box className={classes.headerGroup}>
                    <Box>
                      <span className={classes.headerGroupTitle}>Description:</span>
                    </Box>
                    <Box className={clsx(classes.headerInput, classes.textarea)}>
                      <TextareaWithPlugins
                        onChange={(value) => {
                          setETableDescription(value);
                        }}
                        value={eTableDescription}
                        minRows={7}
                        maxRows={7}
                      />
                    </Box>
                  </Box>
                </Box>
              </ExpansionTable>
              <Box className={'etable-config'}>
                <AgGridReact
                  modules={[ClientSideRowModelModule, ClipboardModule]}
                  columnDefs={columnDefs}
                  rowData={rowData}
                  defaultColDef={defaultColDef}
                  onGridReady={(params) => {
                    setGridApi(params.api);
                    handleRowChange(params.api);
                    setColumnApi(params.columnApi);
                  }}
                  onRowDataUpdated={(params) => {
                    handleRowChange(params.api);
                  }}
                  gridOptions={{
                    frameworkComponents: {
                      agColumnHeader: HeaderCell,
                    },
                  }}
                  processCellForClipboard={(params) => {
                    const colId = params.column.getColId();
                    if (colId === 'checkbox') {
                      return JSON.stringify({
                        sourceId: nodeId,
                        data: {
                          sourceType: 'table-editor',
                        },
                      });
                    }
                    return JSON.stringify({
                      sourceId: nodeId,
                      data: {
                        [colId]: params.value,
                      },
                    });
                  }}
                  processCellFromClipboard={(params) => {
                    try {
                      const value = JSON.parse(params.value);
                      const data = value.data;
                      const column = params.column.getColId();
                      if (data[column] === undefined) throw new Error('invalid data');
                      return data[column];
                    } catch (error) {
                      return params.node?.data[params.column.getColId()];
                    }
                  }}
                  processDataFromClipboard={(params) => {
                    const selectedNodes = []; // params.api.getSelectedNodes();
                    let isValidPasteData = false;
                    let parsedRows: any[] = [];
                    try {
                      parsedRows = params.data.map((r) => r.map((c) => JSON.parse(c)));
                      const firstCell = first(parsedRows[0]);
                      isValidPasteData = firstCell.data.sourceType === 'table-editor';
                    } catch (error) {
                      // expect JSON parse error
                    }
                    if (!isValidPasteData) {
                      return params.data;
                    }
                    const transactions = {
                      update: [],
                      add: [],
                    };
                    if (selectedNodes.length > 0) {
                      selectedNodes.forEach((node, index) => {
                        let updateRow = parsedRows[index].reduce((acc, cell) => {
                          return { ...acc, ...cell.data };
                        }, {});
                        updateRow = { ...node.data, ...updateRow, columnKeys: node.data.columnKeys };
                        transactions.update.push(updateRow);
                      });
                    }
                    const newRows = parsedRows.slice(transactions.update.length).map((r) =>
                      r.reduce((acc, cell) => {
                        return { ...acc, ...cell.data };
                      }, {}),
                    );
                    transactions.add = transactions.add.concat(newRows);
                    params.api.applyTransaction(transactions);
                  }}
                  rowDragManaged={true}
                  rowDragEntireRow={true}
                  rowSelection={'multiple'}
                  enableRangeSelection={false}
                  suppressMoveWhenRowDragging={true}
                  suppressRowClickSelection={true}
                  suppressNoRowsOverlay={true}
                  onBodyScroll={(e) => {
                    if (tableDOM && tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal')) {
                      const el = tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal');
                      el.classList.add('scroll');
                      setTimeout(function () {
                        el.classList.remove('scroll');
                      }, 1000);
                    }
                    handleBodyScroll(e);
                  }}
                  onCellValueChanged={(params) => {
                    if (params.node?.data?.cellFormat === 'traction' && params.column.getColId() === 'staticValue') {
                      const { period = 'week', timeRange = 8 } = safeJsonParse(params.newValue, {});
                      params.node.setDataValue('title', `Last ${timeRange} ${period}s`);
                    }
                  }}
                />
              </Box>
              {isEmpty && tableDOM.current && (
                <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={40} />
              )}
              <Box className={classes.actions}>
                <Box className={classes.actionGroup}>{actionButtonsRender}</Box>
                <Box className={classes.actionGroup}>
                  <Button variant="contained" color="secondary" onClick={onClose}>
                    Close
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </React.Suspense>
        </DialogContent>
      </Dialog>
      <FormulaEditor />
    </EtableConfigContext.Provider>
  );
};

const ETableConfigHOC = (props) => {
  return (
    <Provider initialValues={[[pinnedColumns, []]]}>
      <ETableConfig {...props} />
    </Provider>
  );
};

export default ETableConfigHOC;
