import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const History = (props: SvgIconProps) => (
  <SvgIcon {...props} width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.17268 4.99996C2.63984 2.23389 5.0107 0.0828718 7.87263 0.00238969C11.8303 -0.108742 14.9466 3.67307 13.728 7.82587C13.1775 9.70257 11.7101 11.179 9.84817 11.7322C7.95221 12.2954 6.13667 11.9336 4.73895 10.991C4.22082 10.6418 4.15449 9.89977 4.5947 9.45608C4.92714 9.12185 5.44859 9.06252 5.83822 9.32573C6.46911 9.75187 7.22683 10 8.04258 10C10.2859 10 12.0948 8.12417 12.0086 5.84389C11.9307 3.77083 10.2544 2.0813 8.1976 2.00276C6.28078 1.92913 4.65199 3.22668 4.19914 4.99996H4.86493C5.02493 4.99913 5.1783 5.06263 5.29105 5.17794C5.52234 5.41273 5.52069 5.79208 5.28773 6.02521L3.50037 7.82587C3.26742 8.059 2.89105 8.05732 2.65975 7.82253L0.875711 6.02437C0.762135 5.91157 0.69913 5.75699 0.699959 5.59572C0.701617 5.26484 0.969389 4.99829 1.29768 4.99996H2.17268Z" />
    <path d="M7.11443 6.35477C7.07294 6.24454 7.05023 6.12501 7.05023 6.00015V3.99978C7.05023 3.44747 7.49458 2.9996 8.04256 2.9996C8.59054 2.9996 9.0349 3.44747 9.0349 3.99978V5.5857L10.0281 6.58672C10.4152 6.97693 10.4152 7.6103 10.0281 8.00135C9.64091 8.39156 9.01251 8.39156 8.62453 8.00135L7.34122 6.70788C7.23835 6.60419 7.16275 6.48347 7.11443 6.35477Z" />
  </SvgIcon>
);

export default History;
