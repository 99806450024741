import * as React from 'react';
import { useLocation } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { EIP_CONSTANT, aim, eipRequest as request, AuthContext } from '@eip/next/lib/main';
import { CompactFactoryProvider } from '@ep/insight-ui/elements/etable2/compact-factory';
import { DropdownCell } from '@ep/insight-ui/elements/etable2/dropdown-cell';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';
import { getCustomCellActions } from '@ep/insight-ui/system/helper/function';
import { useToast } from '../notifications/hook';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles(() => ({
  button: {
    width: '32px',
    height: '32px',
  },
}));

const defaultPageMenu = (pageId) => [
  {
    name: 'Edit this page',
    icon: 'pencil',
    onSubmit: function onClick() {
      return window.open('/page/' + pageId + '/edit');
    },
    disable: !aim.canAccess('page:edit'),
  },
];

const DynamicActionDot = () => {
  const classes = useStyles();
  const location = useLocation();
  const [actions, setActions] = React.useState([]);

  const { onToastMultiple } = useToast();

  const pageId = (location.pathname.match(/page\/.*?\//)?.[0] || '').split('/')[1]?.replace('/', '');
  const dotMenu = React.useMemo(() => defaultPageMenu(pageId), [pageId]);

  const { currentWorkspaceDetail } = React.useContext(AuthContext);

  const { workspace_role_id } = aim.getUserSettings().profile || {};

  React.useEffect(() => {
    if (currentWorkspaceDetail) {
      const timeout = setTimeout(() => {
        request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/page-actions').then((res) => {
          const newActions = (res.data?.v || []).filter(
            ({ role }) =>
              aim.isSuperAdmin() ||
              !workspace_role_id ||
              (role ? [].concat(role) : [1, 2, 3]).includes(Number(workspace_role_id)),
          );
          setActions(newActions);
        });
      }, DELAY_REQUEST_ADDITIONAL_DATA);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentWorkspaceDetail]);

  return (
    <NotificationGlobalProvider>
      <CompactFactoryProvider>
        <DropdownCell
          cellAction={getCustomCellActions(
            { actions, field: '', backbone: null, isPageAction: true },
            dotMenu,
            onToastMultiple,
          )}
          props={{ value: { pageId }, node: { data: { pageId } } }}
          onOpened={() => undefined}
          onClosed={() => undefined}
          showThreeDots={true}
          setShowThreeDots={() => undefined}
          classes={classes.button}
          sizeIcon={'14px'}
        />
      </CompactFactoryProvider>
    </NotificationGlobalProvider>
  );
};

export default DynamicActionDot;
