import { Box, Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import * as _ from 'lodash';
import LoadingIcon from '../../list-control/spinners/icon-spinner';
import { ICellRendererParams } from '@ag-grid-community/core';

/**
 * ff.loading_config_search:start
 */
import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
/**
 * ff.loading_config_search:end
 */

const useStyles = makeStyles((theme) => ({
  text: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.label': {
      // fontSize: '18px',
      fontWeight: 500,
    },
  },
  ...(ff.loading_config_search
    ? {
        buttonError: {
          display: 'flex',
          columnGap: '8px',
        },
        error: {
          marginLeft: '40px',
        },
      }
    : {}),
}));
const LoadingCellFormat = (props: ICellRendererParams) => {
  const classes = useStyles();
  const loadingMessage = _.get(props, 'loadingMessage', '');
  const errorMessage = _.get(props, 'errorMessage', 'Error');
  const typeTable = _.get(props, ['typeTable'], '');
  const isFailed = _.get(props, ['node', 'failedLoad']) ? true : false;

  let backbone, tableType;
  if (ff.loading_config_search) {
    backbone = React.useContext(TableBackboneContext);
    tableType = backbone.getConfig('tableType');
  }
  return (
    <Box
      height={ff.loading_config_search && tableType === 'compact' ? '40px' : '56px'}
      display={'flex'}
      alignItems={'center'}
      flexWrap={'nowrap'}
      style={{ justifyContent: 'flex-start' }}
    >
      {isFailed ? (
        ff.loading_config_search ? (
          <div className={`${classes.text} label ${classes.error}`}>
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.api.retryServerSideLoads();
              }}
            >
              <span className={classes.buttonError}>
                <Icon type={'reload'} />
                <span>Reload</span>
              </span>
            </Button>
          </div>
        ) : (
          <span className={`${classes.text} label`}>
            {errorMessage}{' '}
            <Button
              variant="text"
              size="small"
              onClick={() => {
                props.api.retryServerSideLoads();
              }}
            >
              Retry
            </Button>
          </span>
        )
      ) : (
        <React.Fragment>
          <LoadingIcon {...(typeTable == 'minimal' ? { color: '#fff' } : {})} />{' '}
          <span className={`${classes.text} label`}>{loadingMessage}</span>
        </React.Fragment>
      )}
    </Box>
  );
};

export default LoadingCellFormat;
