import * as React from 'react';

export const TableBackboneContext = React.createContext<TableBackbone.TableBackboneContextType>({
  visibility: undefined,
  status: undefined,
  options: undefined,
  config: undefined,
  reloadData: (name: string) => undefined,
  init: () => undefined,
  getConfig: (name: string) => [],
  getStatus: (name: string) => '',
  getHeaders: () => [],
  getRows: () => [],
  getPagination: () => ({ limit: 10, page: 1, total: 0 }),
  getCellConfig: (columnName: string) => null,
  getOptions: (name: string) => [],
  useOptions: (name: string) => undefined,
  getSubmitHandler: (type: string) => undefined,
  getVisibility: (name: string) => false,
  changeVisibility: (name: string, isVisible: boolean) => undefined,
  changeStatus: (name: string, value: TableBackbone.StatusType) => undefined,
  changeConfig: (name: string, value: any) => undefined,
  changePagination: (value: { page: number; limit: number }) => undefined,
  getAvailableColumns: () => [],
  getAvailableFilterColumns: () => [],
  getMainButtonComponent: (name: string) => null,
  registerMainButtonComponent: (component: any) => undefined,
  registerHeaderAction: (action: any) => undefined,
  registerCellAction: (action: any) => undefined,
  registerCellFormat: (format: any) => undefined,
  getCallback: (name) => () => undefined,
});
