import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import * as React from 'react';
import ButtonFormSearch from '../../form-control/search-button-form';

const ButtonSearch = (props) => {
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const searchText = backboneContext.getConfig('search');

  const handleOnChangeText = (text: string) => backboneContext.changeConfig('search', text);

  return <ButtonFormSearch onChangeText={handleOnChangeText} defaultText={searchText} />;
};

export default ButtonSearch;
