import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const HideDetail = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.00174 14.0029L12.9983 14.0029C13.5513 14.0029 14 13.5545 14 13.0017C14 12.4489 13.5513 12.0004 12.9983 12.0004L3.00174 12.0004C2.44865 12.0004 2 12.4489 2 13.0017C2 13.5545 2.44865 14.0029 3.00174 14.0029Z"
      fill={props.color}
    />
    <rect x="6" y="7" width="8" height="2" rx="1" fill={props.color} />
    <rect x="7" y="2" width="7" height="2" rx="1" fill={props.color} />
    <rect x="8" y="10" width="6" height="1" rx="0.5" fill={props.color} />
    <rect x="8" y="5" width="6" height="1" rx="0.5" fill={props.color} />
    <path
      d="M1.00001 2.75327L1.00001 7.24673C0.99909 7.39549 1.04545 7.54113 1.13319 7.66509C1.22093 7.78904 1.34607 7.88571 1.49266 7.94275C1.63924 7.99979 1.80063 8.01463 1.95625 7.98537C2.11186 7.95611 2.25466 7.88408 2.36642 7.77846L4.76364 5.53173C4.83853 5.46211 4.89798 5.37928 4.93855 5.28801C4.97911 5.19675 5 5.09886 5 5C5 4.90113 4.97911 4.80325 4.93855 4.71199C4.89798 4.62072 4.83853 4.53789 4.76364 4.46827L2.36642 2.22154C2.25466 2.11592 2.11186 2.04389 1.95625 2.01463C1.80063 1.98537 1.63924 2.00021 1.49266 2.05725C1.34607 2.11429 1.22093 2.21096 1.13319 2.33492C1.04545 2.45887 0.99909 2.60451 1.00001 2.75327Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default HideDetail;
