import * as React from 'react';
import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import CalendarInput from '@ep/insight-ui/elements/form-control/calendar/calendar-input';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Theme,
  Grid,
  withStyles,
  Box,
  Button,
  TableFooter,
} from '@material-ui/core';
import {
  CellCurrency,
  AntSwitch,
  ISubscriptionRow,
  Label,
  Pagination,
  textStyles,
  TickIcon,
  IObjectButtons,
  createCellButton,
  createDataObjectButtons,
  createDataObjectInfoSubscription,
  IObjectInfoSubscription,
} from './subscription-components';
import CompareItem, { ICompareItem } from './compare-item';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
export enum EnumSelectedColumn {
  Starter = 'starter',
  Team = 'team',
  Enterprise = 'enterprise',
  Empty = '',
}
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: textStyles as CreateCSSProperties,
  cell: {
    '&.p-0': {
      padding: 0,
    },
  },
  clearBorder: {
    borderBottomWidth: 0,
  },
  popularDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 28px',
    width: '100%',
    height: '32px',
    background: '#EBF6FF',
  },
  selectedCell: {
    position: 'relative',
    '&.active': {
      borderStyle: 'solid',
      borderLeftWidth: '3px',
      borderRightWidth: '3px',
      borderColor: '#0364BE',
      '& $selectedDiv': {
        opacity: 1,
      },
      '&.thead': {
        borderTop: '3px solid #0364BE',
        borderBottom: '1px solid transparent',
      },
      '&.tbody': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
        '&.button': {
          borderBottom: '1px solid transparent',
        },
      },
      '&.tfoot': {
        borderTop: '1px solid rgba(224, 224, 224, 1)',
        borderBottom: '3px solid #0364BE',
      },
    },
  },
  selectedDiv: {
    opacity: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    width: '30px',
    height: '30px',
    '& .box': {
      borderLeft: '30px solid transparent',
      width: '0',
      height: '0',
      borderRight: '0 solid transparent',
      borderTop: '30px solid #0364BE',
    },
    '& .tick': {
      position: 'absolute',
      top: 0,
      right: 0,
      height: '10px',
    },
  },
});

const HeaderCell = ({ isPopular = false }: { isPopular?: boolean }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      {isPopular ? (
        <div className={classes.popularDiv}>
          <Label type={'subHeader'} label={'Most popular'} />
        </div>
      ) : null}
      <div className={`${classes.selectedDiv}`}>
        <div className={'box'} />
        <Box width={'100%'} height={'100%'} position={'relative'}>
          <span className={'tick'}>
            <TickIcon color={'#fff'} />
          </span>
        </Box>
      </div>
    </React.Fragment>
  );
};

export const generationClass = (
  type: 'head' | 'body' | 'foot',
  current: EnumSelectedColumn,
  selectTypeCell: EnumSelectedColumn,
) => {
  const classes = useStyles();
  switch (type) {
    case 'head': {
      return `${classes.cell} p-0 ${classes.selectedCell} thead ${selectTypeCell} ${
        selectTypeCell == current ? 'active' : ''
      } ${classes.clearBorder} `;
    }
    case 'body': {
      return `${classes.selectedCell} tbody ${selectTypeCell} ${selectTypeCell == current ? 'active' : ''}`;
    }
    case 'foot': {
      return `${classes.clearBorder}  ${classes.selectedCell} tfoot ${selectTypeCell} ${
        selectTypeCell == current ? 'active' : ''
      }`;
    }
    default:
      return '';
  }
};
const defaultOptions = [
  {
    label: '10',
    value: '10',
    checked: true,
  },
  {
    label: '20',
    value: '20',
    checked: false,
  },
  {
    label: '50',
    value: '50',
    checked: false,
  },
  {
    label: '100',
    value: '100',
    checked: false,
  },
];

const defaultInfoSubscriptionObject: IObjectInfoSubscription = createDataObjectInfoSubscription(
  'Start to explore ecommerce potential',
  'For teams who want to work together',
  'Stellar your e-commerce organization',
);

const defaultButtonsObject: IObjectButtons = createDataObjectButtons(
  createCellButton('Your plan', () => undefined, 'secondary'),
  createCellButton('Upgrade', () => undefined),
  createCellButton('Get in touch', () => undefined),
);

type DateType = { dateFrom: string; dateTo: string };
type Props = {
  label: string;
  dataHead: Array<ISubscriptionRow>;
  dataBody: Array<ICompareItem>;
  popularColumn?: EnumSelectedColumn;
  selectedColumn?: EnumSelectedColumn;
  dateProps: {
    value: DateType;
    defaultFormatReturn?: string;
    formatDateView?: string;
    onChange: (value: DateType) => void;
  };
  payToggleProps: {
    checked: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  };
  paginationProps: {
    pageLength: number;
    onChange: (value: number) => void;
    optionsPagi?: Array<OptionType>;
  };
  buttons?: IObjectButtons;
  infos?: IObjectInfoSubscription;
};

const SubscriptionPlanTable = ({
  label = '',
  dataHead,
  dataBody,
  popularColumn = EnumSelectedColumn.Empty,
  selectedColumn = EnumSelectedColumn.Empty,
  dateProps,
  payToggleProps,
  paginationProps,
  buttons = defaultButtonsObject,
  infos = defaultInfoSubscriptionObject,
}: Props) => {
  const classes = useStyles();

  return (
    <ExpansionForm
      label={label}
      labelSize={24}
      defaultExpanded={true}
      detailContentSize={'full'}
      disabledMarginX
      rightElement={
        <React.Fragment>
          <CalendarInput {...dateProps} />
        </React.Fragment>
      }
    >
      <TableContainer>
        <Table className={`${classes.table} ${selectedColumn}`}>
          <TableHead>
            {/* most popular */}
            <TableRow>
              <TableCell className={`${classes.cell} p-0 ${classes.clearBorder} `} />
              <TableCell className={`${generationClass('head', selectedColumn, EnumSelectedColumn.Starter)}`}>
                <HeaderCell isPopular={popularColumn === EnumSelectedColumn.Starter} />
              </TableCell>
              <TableCell className={`${generationClass('head', selectedColumn, EnumSelectedColumn.Team)}`}>
                <HeaderCell isPopular={popularColumn === EnumSelectedColumn.Team} />
              </TableCell>
              <TableCell className={`${generationClass('head', selectedColumn, EnumSelectedColumn.Enterprise)}`}>
                <HeaderCell isPopular={popularColumn === EnumSelectedColumn.Enterprise} />
              </TableCell>
            </TableRow>
            {/* header */}
            <TableRow>
              <TableCell>
                <Box display={'flex'} alignItems={'center'} minWidth={'200px'}>
                  <AntSwitch checked={payToggleProps.checked} onChange={payToggleProps.onChange} />
                  <span className={`${classes.text} header ml`}> Pay annually</span>
                </Box>
              </TableCell>
              <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Starter)}`}>
                <Label type={'header'} label={'Starter'} />
              </TableCell>
              <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Team)}`}>
                <Label type={'header'} label={'Team'} />
              </TableCell>
              <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Enterprise)}`}>
                <Label type={'header'} label={'Enterprise'} />
              </TableCell>
            </TableRow>
            {dataHead.map((row, index) => (
              <TableRow key={index.toString()}>
                <TableCell component="th" scope="row">
                  <Label label={row.name} />
                </TableCell>
                <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Starter)}`}>
                  <CellCurrency cell={row.starter} />
                </TableCell>
                <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Team)}`}>
                  <CellCurrency cell={row.team} />
                </TableCell>
                <TableCell className={`${generationClass('body', selectedColumn, EnumSelectedColumn.Enterprise)}`}>
                  <CellCurrency cell={row.enterprise} />
                </TableCell>
              </TableRow>
            ))}
            {/* info subscription */}
            <TableRow>
              <TableCell component="th" scope="row" className={classes.clearBorder} />
              {Object.keys(infos).map((key, index) => (
                <TableCell
                  key={index.toString()}
                  className={`${classes.clearBorder} ${generationClass(
                    'body',
                    selectedColumn,
                    EnumSelectedColumn[key],
                  )} button`}
                >
                  <Label label={infos[key]} />
                </TableCell>
              ))}
            </TableRow>
            {/* buttons subscription */}
            <TableRow>
              <TableCell className={classes.clearBorder} component="th" scope="row" />
              {Object.keys(buttons).map((key, index) => (
                <TableCell
                  key={index.toString()}
                  className={`${classes.clearBorder} ${generationClass(
                    'body',
                    selectedColumn,
                    EnumSelectedColumn[key],
                  )} button`}
                >
                  <Button
                    color={buttons[key].color ?? 'primary'}
                    variant="contained"
                    component="span"
                    disabled={buttons[key].disabled ?? false}
                    onClick={buttons[key].onClick}
                  >
                    {buttons[key].label}
                  </Button>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {/* list compare item */}
            {dataBody.map((item, index) => (
              <CompareItem selectedColumn={selectedColumn} key={index.toString()} data={item} />
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell className={`${classes.clearBorder} `}>
                <Pagination
                  value={paginationProps.pageLength}
                  onChange={paginationProps.onChange}
                  options={paginationProps.optionsPagi ?? defaultOptions}
                />
              </TableCell>
              <TableCell className={`${generationClass('foot', selectedColumn, EnumSelectedColumn.Starter)}`} />
              <TableCell className={`${generationClass('foot', selectedColumn, EnumSelectedColumn.Team)}`} />
              <TableCell className={`${generationClass('foot', selectedColumn, EnumSelectedColumn.Enterprise)}`} />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </ExpansionForm>
  );
};

export default SubscriptionPlanTable;
