import * as React from 'react';
import { get } from 'lodash';

import { EIP_CONSTANT, eipRequest as request, aim } from '@eip/next/lib/main';

export const useActionConfiguration = ({ source = 'page-actions', handleSubmit = (actions: any[]) => undefined }) => {
  const [showConfig, setShowConfig] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [submitting, setSubmitting] = React.useState(false);

  const [config, setConfig] = React.useState(null);

  const onSubmit = (data) => {
    const now = Date.now();
    const v = [...get(data, ['customAction', 'actions'], [])].map((el) => ({
      ...el,
      updatedAt: now,
      updatedById: aim.getUserSettings().profile.userId,
      updatedByEmail: aim.getUserSettings().profile.userEmail,
    }));
    setSubmitting(true);
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv', {
        k: source,
        v,
        version: now,
      })
      .then(() => {
        setConfig(data);
        setShowConfig(false);
        if (handleSubmit) {
          handleSubmit(v);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const mapping = {
    pageId: {
      valueGetter: {
        id: 'pageId',
      },
    },
  };

  React.useEffect(() => {
    request
      .get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/' + source)
      .then((res) => {
        const customActions = get(res, 'data.v', []) || [];
        setConfig({
          mapping,
          customAction: {
            actions: customActions,
          },
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    showConfig,
    setShowConfig,
    config,
    onSubmit,
    loading,
    submitting,
  };
};
