import { aggFuncByKey } from '@ep/insight-ui/system/util/aggregation';
import { get, debounce } from 'lodash';
import * as React from 'react';
import { AllocateUser } from '../company-management/allocate-user';
import { InviteUser } from '../company-management/invite-user';
// inline editor
import { FilterOperatorType } from '../inline-dialog/filter/filter-group-dialog';
import AdvertisingCostEditor from '../inline-edit/cell/advertising-cost';
import AgeEditor from '../inline-edit/cell/age';
import AudiencesEditor from '../inline-edit/cell/audiences';
import BudgetEditor from '../inline-edit/cell/budget/budget-editor';
import SelectionBudgetEditor from '../inline-edit/cell/budget/budget-selection-editor';
import BudgetSingleEditor from '../inline-edit/cell/budget/budget-single-editor';
import ScheduleDailyEditor from '../inline-edit/cell/budget/schedule-daily-editor';
import DropdownEditor from '../inline-edit/cell/dropdown-editor';
import GenderEditor from '../inline-edit/cell/gender-editor';
import InterestCategoryEditor from '../inline-edit/cell/interest-category-editor';
import LandingPageEditor from '../inline-edit/cell/landing-page';
import LinkTo from '../inline-edit/cell/link-to';
import LocationEditor from '../inline-edit/cell/location';
import MatchTypeEditor from '../inline-edit/cell/match-type-editor';
import NameEditor from '../inline-edit/cell/name-editor';
import NameReceiverEditor from '../inline-edit/cell/name-receiver/name-receiver';
import PhotoEditor from '../inline-edit/cell/photo';
import PremiumRateEditor from '../inline-edit/cell/rate';
import TransformStatusEditor from '../inline-edit/cell/transform-status-editor';
/*
 * ff.refresh_cookie_button:end
 */
/**
 * ff.invite_user_workspace:start
 */
import { RemoveUser } from '../inline-edit/cell/remove-user/remove-user';
import RTBEditor from '../inline-edit/cell/rtb-editor';
import StatusEditor from '../inline-edit/cell/status-editor';
import TaglineEditor from '../inline-edit/cell/tag-line';
import TimeLineEditor from '../inline-edit/cell/timeline-editor';
import { UserPermission } from '../inline-edit/cell/user-status-editor/user-permission';
import { UserStatusEditor } from '../inline-edit/cell/user-status-editor/user-status-editor';
import { StorefrontIntegration } from '../new-storefront/storefront-integration';
/*
 * ff.refresh_cookie_button:start
 */
import ActionNeededFormat from './format/action-needed-format';
// cell formatting
import AdditionFormat from './format/addition-format';
import AgeFormat from './format/age-format';
import AudienceFormat from './format/audience-format';
import AvatarTextFormat from './format/avatar-text-format';
import BudgetFormat from './format/budgets/budget-format';
import SelectionBudgetFormat from './format/budgets/selection-budget';
import SingleBudgetFormat, { SingleBudgetImmovableFormat } from './format/budgets/single-budget-format';
import CompactCellFormat from './format/compact-cell-format';
import ConnectionStatusFormat from './format/connection-status-format';
import CurrencyFormat from './format/currency-format';
import DateRangeFormat from './format/date-range-format';
import EnableDisableFormat from './format/enable-disable-format';
import GenderFormat from './format/gender-format';
import KeywordFormat from './format/keyword-format';
import KeywordMethodFormat from './format/keyword-method-format';
import LineChartFormat from './format/line-chart-format';
import LineEDashboardChart from './format/line-edashboard-format';
import Elasticity from './format/mixed-chart-format';
import PositionFormat from './format/position-format';
import ProgressBarFormat from './format/progress-bar-format';
import SimpleGroupFormat from './format/simple-group-format';
import StampFormat from './format/stamp-format';
import StatusTextFormat from './format/status-text-format';
import TagFormat from './format/tag-format';
import TextFormat from './format/text-format';
import TimeBasedCellFormat from './format/time-based-cell-format';
import AggregateAdObjectBudget, { AggregateKeywordBiddingPrice } from './format/aggregate-budget';
import TransformStatusFormat from './format/transform-status-format';
import DateTimeCompare from './format/datetime-compare';
import RgbStatusFormat from './format/rgb-status-format';
import EmptyFormat from './format/empty-format';
import TractionElasticityFormat from './format/traction-elasticity-format';
import DataSyncStatusFormat from './format/data-sync-status-format';

import TextFormatFormula from './format/formula-text-format';
import CurrencyFormatFormula from './format/formula-currency-format';

/**
 * ff.replace_keyword_compact:start
 */
import HeatmapFormat from './format/heatmap-format';
/**
 * ff.replace_keyword_compact:end
 */

/**
 * ff.adoption_rate_table:start
 */
import TractionFormat from './format/traction-format';
import AdoptionRateFormat from './format/adoption-rate-format';
import TextWithTooltipFormat from './format/text-with-tooltip-format';
/**
 * ff.adoption_rate_table:end
 */

/**
 * ff.mass_operation_management:start
 */
import StatusRequestFormat from './format/status-request-format';
import ImportMassOperationBtn from '../button/button-import-mass-operation';
/**
 * ff.mass_operation_management:end
 */

/**
 * ff.mass_operation_management:start
 */
import ReloadTableBtn from '../button/reload-table';
/**
 * ff.mass_operation_management:end
 */

/**
 * ff.invite_user_workspace:end
 */
import TagEditor from '../inline-edit/cell/tag-editor';
import TagWithInlineEditor from './format/tag-with-inline-editor';
/**
 * ff.ui_page_keyword_analysis:start
 */
import KeywordAnalysisFormat from './format/keyword-analysis-format';
/*
 * ff.ui_page_keyword_analysis:end
 */
/*
 * ff.ui_ux_update_tag_item:start
 */
import CreateTagEditor from '../inline-edit/cell/createtag-editor';
import DeleteEditor from '../inline-edit/cell/delete-editor';
/*
 * ff.ui_ux_update_tag_item:end
 */

/*
 * ff.add_note_column_including_inline_edit:start
 */
import NoteFormat from './format/note-format';
import CreateNoteEditor from '../inline-edit/cell/createnote-editor';
/*
 * ff.add_note_column_including_inline_edit:end
 */

/*
 * ff.aggregate_cell:start
 */
import AccumulationFormat from './format/accumulation-format';
/*
 * ff.aggregate_cell:end
 */

/**
 * ff.chart_line_traction:start
 */
import TwoLineChartFormat from './format/two-line-chart-format';
/**
 * ff.chart_line_traction:start
 */
import TextareaEditor from '../inline-edit/cell/textarea-editor';
import AutoRenew from '../inline-edit/cell/auto-renew-editor';
import SCLoginCredential from '../inline-edit/cell/sc-login-credential';
import { updateCookie, updateComment, autoRenewal } from '@ep/insight-ui/system/backbone/data-source/common';
import { ACTION_CATEGORY_STATUS } from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import ScheduleStatusIndicator from './format/schedule-status-indicator';
import { handleUpdateVisible } from '@ep/insight-ui/system/helper/functions';
import { checkEpsiloTableEndpoint } from '@ep/insight-ui/sw/etable/data/common';

export const filterOperators: FilterOperatorType = {
  string: [
    [
      { label: 'Is', value: 'is' },
      { label: 'Is not', value: 'is_not' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },
      { label: 'Contain', value: 'contains' },
      { label: 'Does not contain', value: 'does_not_contain' },
      { label: 'Starts with', value: 'begins_with' },
      { label: 'Ends with', value: 'ends_with' },
      { label: 'Does not start with', value: 'does_not_start_with' },
      { label: 'Does not end with', value: 'does_not_end_with' },
      { label: 'Any of', value: 'IN' },

      // { label: 'In', value: 'in' },
      // { label: 'Not in', value: 'not_in' },
    ],
  ],
  nextString: [
    [
      { label: 'Is', value: 'is' },
      { label: 'Is not', value: 'is_not' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },
      { label: 'Is not match', value: 'is_not_match' },
      { label: 'Match', value: 'match' },
      { label: 'Any of', value: 'IN' },
    ],
  ],
  integer: [
    [
      { label: '=', value: '=' },
      { label: '≠', value: '≠' },
      { label: '>', value: '>' },
      { label: '≥', value: '≥' },
      { label: '<', value: '<' },
      { label: '≤', value: '≤' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },
      { label: 'Any of', value: 'IN' },

      // { label: 'In', value: 'in' },
      // { label: 'Not in', value: 'not_in' },
      // { label: 'Between', value: 'between' },
      // { label: 'Not between', value: 'not_between' },
    ],
  ],
  float: [
    [
      { label: '=', value: '=' },
      { label: '≠', value: '≠' },
      { label: '>', value: '>' },
      { label: '≥', value: '≥' },
      { label: '<', value: '<' },
      { label: '≤', value: '≤' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },
      { label: 'Any of', value: 'IN' },

      // { label: 'In', value: 'in' },
      // { label: 'Not in', value: 'not_in' },
      // { label: 'Between', value: 'between' },
      // { label: 'Not between', value: 'not_between' },
    ],
  ],
  datetime: [
    [
      { label: 'Is', value: 'is' },
      { label: 'Is before', value: 'is_before' },
      { label: 'Is after', value: 'is_after' },
      { label: 'Is on or before', value: 'is_on_or_before' },
      { label: 'Is on or after', value: 'is_on_or_after' },
      { label: 'Is within', value: 'is_within' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },

      // { label: 'Is not', value: 'not_equal' },
      // { label: 'Not in', value: 'not_in' },
      // { label: 'Is not within', value: 'is_not_within' },
    ],
  ],
  date: [
    [
      { label: 'Is', value: 'is' },
      { label: 'Is before', value: 'is_before' },
      { label: 'Is after', value: 'is_after' },
      { label: 'Is on or before', value: 'is_on_or_before' },
      { label: 'Is on or after', value: 'is_on_or_after' },
      { label: 'Is within', value: 'is_within' },
      { label: 'Is empty', value: 'is_empty' },
      { label: 'Is not empty', value: 'is_not_empty' },

      // { label: 'Is not', value: 'not_equal' },
      // { label: 'Not in', value: 'not_in' },
      // { label: 'Is not within', value: 'is_not_within' },
    ],
  ],
};

export const getSelectedOperators = (columnName: string) => {
  const allowedSelectColumns = [
    'country',
    'marketplace',
    'storefront',
    'ad_type',
    'ad_tool',
    'campaign_status',
    'ad_object_status',
    'keyword_status',
    'script_status',
    'keyword_match_type',
    'ad_object_tag',
    'campaign_tag',
    'keyword_tag',
    'channel',
    'category_1',
    'category_2',
    'platform',
    'brand',
    'ads_campaign_transform_status',
    'ads_object_transform_status',
    'ads_placement_transform_status',
  ];
  if (allowedSelectColumns.includes(columnName)) {
    return ['is', 'is_not', 'contains', 'does_not_contain'];
  }
  return [];
};

function TextMatchType({ value, ...rest }: any) {
  const val = { ...value };
  if (value.value === 'EXACT_MATCH') {
    val.label = 'Exact match';
  } else if (value.value === 'BROAD_MATCH') {
    val.label = 'Broad match';
  }
  return <TextFormat value={val} {...rest} />;
}

export const cellFormat = {
  textFormatFormula: TextFormatFormula,
  currencyFormatFormula: CurrencyFormatFormula,
  pivotFormatFormula: EmptyFormat,
  text: TextFormat,
  scheduleStatusIndicator: ScheduleStatusIndicator,
  textMatchType: TextMatchType,
  status: StatusTextFormat,
  budget: BudgetFormat,
  selectionBudget: SelectionBudgetFormat,
  currency: CurrencyFormat,
  timeline: DateRangeFormat,
  position: PositionFormat,
  userInfo: AvatarTextFormat,
  tag: TagFormat,
  tagWithInlineEditor: TagWithInlineEditor,
  ...(ff.add_note_column_including_inline_edit
    ? {
        note: NoteFormat,
      }
    : {}),
  stamp: StampFormat,
  progressBar: ProgressBarFormat,
  add: AdditionFormat,
  lineChart: LineChartFormat,
  simpleGroup: SimpleGroupFormat,
  singleBudget: SingleBudgetFormat,
  // eslint-disable-next-line react/display-name
  totalBudget: (props: any) => <SingleBudgetImmovableFormat {...props} label={'total'} />,
  // eslint-disable-next-line react/display-name
  dailyBudget: (props: any) => <SingleBudgetImmovableFormat {...props} label={'daily'} />,
  compact: CompactCellFormat,
  elasticity: Elasticity,
  timeBasedCell: TimeBasedCellFormat,
  lineEDashboardChart: LineEDashboardChart,
  keywordFormat: KeywordFormat,
  age: AgeFormat,
  gender: GenderFormat,
  keywordMethod: KeywordMethodFormat,
  enableDisableFormat: EnableDisableFormat,
  audienceFormat: AudienceFormat,
  actionNeededFormat: ActionNeededFormat,
  connectionStatusFormat: ConnectionStatusFormat,
  ...(ff.adoption_rate_table
    ? {
        traction: TractionFormat,
        adoptionRate: AdoptionRateFormat,
        textWithTooltip: TextWithTooltipFormat,
      }
    : {}),
  ...(ff.ui_page_keyword_analysis
    ? {
        keywordAnalysisFormat: KeywordAnalysisFormat,
      }
    : {}),
  ...(ff.mass_operation_management
    ? {
        statusRequest: StatusRequestFormat,
      }
    : {}),
  ...(ff.aggregate_cell ? { accumulationFormat: AccumulationFormat } : {}),
  ...(ff.lowcode
    ? {
        aggregateAdObjectBudget: AggregateAdObjectBudget,
        aggregateKeywordBiddingPrice: AggregateKeywordBiddingPrice,
        placeHolder: function Placeholder(props: any) {
          return <div style={{ padding: 10 }}>Waiting Data...</div>;
        },
      }
    : {}),
  ...(ff.replace_keyword_compact ? { heatmap: HeatmapFormat } : {}),
  ...(ff.chart_line_traction ? { twoLineChart: TwoLineChartFormat } : {}),
  transformStatus: TransformStatusFormat,
  dateTimeCompare: DateTimeCompare,
  rgbStatusFormat: RgbStatusFormat,
  textareaEditor: TextareaEditor,
  emptyFormat: EmptyFormat,
  tractionElasticityFormat: TractionElasticityFormat,
  dataSyncStatusFormat: DataSyncStatusFormat,
};

export const cellEditor = {
  NameEditor,
  StatusEditor,
  BudgetEditor,
  SingleBudgetEditor: BudgetSingleEditor,
  ScheduleDailyEditor,
  TimeLineEditor,
  MatchTypeEditor,
  BiddingPriceEditor: RTBEditor,
  SelectionBudgetEditor,
  LinkTo,
  PhotoEditor,
  LandingPageEditor,
  TaglineEditor,
  GenderEditor,
  AgeEditor,
  LocationEditor,
  InterestCategoryEditor,
  UserStatusEditor,
  DropdownEditor,
  AudiencesEditor,
  PremiumRateEditor,
  NameReceiverEditor,
  AdvertisingCostEditor,
  UserPermission,
  RemoveUser,
  TagEditor,
  ...(ff.ui_ux_update_tag_item
    ? {
        CreateTagEditor,
        DeleteEditor,
      }
    : {}),
  ...(ff.add_note_column_including_inline_edit
    ? {
        CreateNoteEditor,
      }
    : {}),
  TransformStatusEditor,
};

export const mainButtonComponent = {
  StorefrontIntegration,
  InviteUser,
  AllocateUser,
  ...(ff.mass_operation_management ? { ImportMassOperationBtn } : {}),
  ...(ff.reload_btn_quality ? { ReloadTableBtn } : {}),
};

export const initHeaderAction = (
  backbone: any,
  handleChangePin: (field: string) => void,
  isEditMode?: boolean,
  blockEid?: string,
) => {
  const isLockedView = get(backbone.getConfig('view', {}), 'isLock', false);

  const handlePinColumn = (data) => {
    const pinnedColumns = backbone.getConfig('pinnedColumn');
    if (!(pinnedColumns as Array<string>).includes(data.field)) {
      pinnedColumns.push(data.field);
      backbone.changeConfig('pinnedColumn', pinnedColumns);
    } else {
      const index = pinnedColumns.findIndex((item) => item == data.field);
      if (index !== -1) {
        pinnedColumns.splice(index, 1);
      }
      backbone.changeConfig('pinnedColumn', pinnedColumns);
    }
  };

  const handlePinUntilColumn = (data) => {
    const headers = backbone.getHeaders();
    console.log('headers', headers);

    handleChangePin(data.field);
  };

  const handleSortAsc = (data) => {
    const sortList = backbone.getConfig('sort', []).filter((item) => item.field !== data.field);
    sortList.push({ field: data.field, sort: 'asc' });
    backbone.changeConfig('sort', sortList);
  };

  const handleSortDesc = (data) => {
    console.log('Do sort desc', data);
    const sortList = backbone.getConfig('sort', []).filter((item) => item.field !== data.field);
    sortList.push({ field: data.field, sort: 'desc' });
    backbone.changeConfig('sort', sortList);
  };

  const handleAddFilter = (data) => {
    const field = get(data, 'field', '');
    backbone.updateTempStorage('filterModal', { field, value: '' });
    if (blockEid) {
      handleUpdateVisible(blockEid, 'filter', true);
    } else {
      backbone.changeVisibility('filter', true);
    }
  };

  const handleAutoResize = (data) => {
    const field = get(data, 'field', '');
    const columnData = get(backbone.getColumnApi(), 'columnModel.columnDefs', []);
    const colId = columnData.find((el) => el.field === field).colId;
    if (colId.includes('_grouped')) {
      backbone.autoResizeColumn(field + '_grouped');
    } else {
      backbone.autoResizeColumn(field);
    }
  };

  const handleResetColumn = (data) => {
    const field = get(data, 'field', '');
    const columnData = get(backbone.getColumnApi(), 'columnModel.columnDefs', []);
    const fieldData = columnData.find((el) => el.field === field);
    const colId = fieldData?.colId;
    if (colId.includes('_grouped')) {
      backbone.getColumnApi().setColumnWidth(field + '_grouped', fieldData?.minWidth);
    } else {
      backbone.getColumnApi().setColumnWidth(field, fieldData?.minWidth);
    }
  };

  const hiddenComponents = backbone.getConfig('system.hiddenComponents', []) || [];

  const handleResizeColumn = (data) => {};

  return {
    dimension: [
      ...(hiddenComponents.includes('sort')
        ? []
        : [
            {
              name: 'Sort Ascending',
              icon: 'ascending',
              type: 'asc',
              component: null,
              onSubmit: handleSortAsc,
            },
            {
              name: 'Sort Descending',
              icon: 'descensing',
              type: 'desc',
              component: null,
              onSubmit: handleSortDesc,
            },
          ]),
      ...(hiddenComponents.includes('filter')
        ? []
        : [
            {
              name: 'Add Filter',
              icon: 'addfilter',
              type: '',
              component: null,
              onSubmit: handleAddFilter,
            },
          ]),

      {
        name: 'Group by',
        icon: 'groupBy',
        type: '',
        component: null,
        onSubmit: (data) => {
          console.log('Group by', data);
          const endpoint = backbone.getConfig('endpoint.GET_TABLE_DATA');
          if (checkEpsiloTableEndpoint(endpoint)) {
            const groupBy = backbone.getConfig('groupBy', {}) || {};
            const groupedColumns = (backbone.getConfig('groupBy.columns', []) || []).filter((i) => !!i);
            backbone.changeConfig('groupBy', { ...groupBy, columns: groupedColumns.concat(data.field) });
          } else {
            backbone.changeConfig('groupBy', { columns: [data.field] });
          }
        },
      },
      ...(isLockedView && !isEditMode
        ? []
        : [
            {
              name: 'Autosize this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleAutoResize,
            },
            {
              name: 'Reset this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleResetColumn,
            },
          ]),
      ...(ff.ag_grid_27
        ? []
        : [
            {
              name: 'Pin this column',
              icon: 'pinColumn',
              type: '',
              component: null,
              onSubmit: handlePinColumn,
            },
          ]),
    ],
    default: [
      ...(hiddenComponents.includes('sort')
        ? []
        : [
            {
              name: 'Sort Ascending',
              icon: 'ascending',
              type: 'asc',
              component: null,
              onSubmit: handleSortAsc,
            },
            {
              name: 'Sort Descending',
              icon: 'descensing',
              type: 'desc',
              component: null,
              onSubmit: handleSortDesc,
            },
          ]),
      ...(hiddenComponents.includes('filter')
        ? []
        : [
            {
              name: 'Add Filter',
              icon: 'addfilter',
              type: '',
              component: null,
              onSubmit: handleAddFilter,
            },
          ]),
      ...(isLockedView && !isEditMode
        ? []
        : [
            {
              name: 'Autosize this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleAutoResize,
            },
            {
              name: 'Reset this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleResetColumn,
            },
          ]),
      ...(ff.ag_grid_27
        ? []
        : [
            {
              name: 'Pin this column',
              icon: 'pinColumn',
              type: '',
              component: null,
              onSubmit: handlePinColumn,
            },
          ]),
    ],
    groupBy: [
      {
        name: 'Sort Ascending',
        icon: 'ascending',
        type: 'asc',
        component: null,
        onSubmit: handleSortAsc,
      },
      {
        name: 'Sort Descending',
        icon: 'descensing',
        type: 'desc',
        component: null,
        onSubmit: handleSortDesc,
      },
      {
        name: 'Add Filter',
        icon: 'addfilter',
        type: '',
        component: null,
        onSubmit: handleAddFilter,
      },
      {
        name: 'Show detail',
        icon: 'showDetail',
        type: '',
        component: null,
        onSubmit: (data) => {
          console.log('Show detail', data);
        },
      },
      {
        name: 'Hide detail',
        icon: 'hideDetail',
        type: '',
        component: null,
        onSubmit: (data) => {
          console.log('Hide detail', data);
        },
      },
      {
        name: 'Ungroup by',
        icon: 'unGroup',
        type: '',
        component: null,
        onSubmit: (data) => {
          console.log('Ungroup by', data);
          backbone.changeConfig('groupBy', { columns: [] });
          const endpoint = backbone.getConfig('endpoint.GET_TABLE_DATA');
          if (checkEpsiloTableEndpoint(endpoint)) {
            const groupBy = backbone.getConfig('groupBy', {}) || {};
            const groupedColumns = backbone.getConfig('groupBy.columns', {}) || {};
            const filteredGroupedColumns = groupedColumns.filter((col) => !!col && col != data.field);
            if (filteredGroupedColumns.length) {
              backbone.changeConfig('groupBy', {
                ...groupBy,
                columns: filteredGroupedColumns,
              });
            } else {
              backbone.changeConfig('groupBy', { columns: [] });
            }
          } else {
            backbone.changeConfig('groupBy', { columns: [] });
          }
        },
      },
      ...(isLockedView && !isEditMode
        ? []
        : [
            {
              name: 'Autosize this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleAutoResize,
            },
            {
              name: 'Reset this column',
              icon: 'resize',
              type: '',
              component: null,
              onSubmit: handleResetColumn,
            },
          ]),
      ...(ff.ag_grid_27
        ? []
        : [
            {
              name: 'Pin this column',
              icon: 'pinColumn',
              type: '',
              component: null,
              onSubmit: handlePinColumn,
            },
          ]),
    ],
  };
};

export const groupExpandedIcon = `<svg width="9" height="9" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M7.98978 1.68972e-05H1.9985C1.80016 -0.00113686 1.60597 0.0568177 1.44069 0.166491C1.27541 0.276165 1.14653 0.432588 1.07047 0.615819C0.994418 0.79905 0.974636 1.00079 1.01365 1.19531C1.05266 1.38983 1.1487 1.56832 1.28953 1.70803L4.28517 4.70455C4.378 4.79816 4.48844 4.87247 4.61012 4.92318C4.7318 4.97389 4.86232 5 4.99414 5C5.12596 5 5.25648 4.97389 5.37816 4.92318C5.49984 4.87247 5.61028 4.79816 5.70311 4.70455L8.69875 1.70803C8.83958 1.56832 8.93562 1.38983 8.97463 1.19531C9.01365 1.00079 8.99386 0.79905 8.91781 0.615819C8.84175 0.432588 8.71287 0.276165 8.54759 0.166491C8.38231 0.0568177 8.18812 -0.00113686 7.98978 1.68972e-05Z"
  fill='#3F4F5C'
/>
</svg>
`;
export const groupContracted = `<svg width="9" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
  d="M1.65914e-05 1.00436L1.68533e-05 6.99564C-0.00113689 7.19398 0.0568177 7.38817 0.166491 7.55345C0.276165 7.71873 0.432588 7.84761 0.615819 7.92367C0.79905 7.99972 1.00079 8.0195 1.19531 7.98049C1.38983 7.94148 1.56832 7.84544 1.70803 7.70461L4.70455 4.70897C4.79816 4.61614 4.87247 4.5057 4.92318 4.38402C4.97389 4.26234 5 4.13182 5 4C5 3.86818 4.97389 3.73766 4.92318 3.61598C4.87247 3.4943 4.79816 3.38386 4.70455 3.29103L1.70803 0.295387C1.56832 0.154562 1.38983 0.0585212 1.19531 0.0195083C1.00079 -0.0195046 0.79905 0.000277961 0.615819 0.0763344C0.432588 0.15239 0.276164 0.281275 0.166491 0.446554C0.0568174 0.611834 -0.00113717 0.80602 1.65914e-05 1.00436Z"
  fill='#3F4F5C'
/>
</svg>
`;

const isValidAggregateFun = (fieldName, aggkeyKeyId) => {
  if (
    aggkeyKeyId == aggFuncByKey.sum.id ||
    aggkeyKeyId == aggFuncByKey.avg.id ||
    aggkeyKeyId == aggFuncByKey.median.id ||
    aggkeyKeyId == aggFuncByKey.min.id ||
    aggkeyKeyId == aggFuncByKey.max.id ||
    aggkeyKeyId == aggFuncByKey.range.id
  ) {
    return (
      fieldName != 'cir' &&
      fieldName != 'cpc' &&
      fieldName != 'roas' &&
      fieldName != 'ctr' &&
      fieldName != 'cr' &&
      fieldName != 'cpi' &&
      fieldName != 'direct_cir' &&
      fieldName != 'direct_roas' &&
      fieldName != 'direct_cr' &&
      fieldName != 'direct_cpi' &&
      fieldName != 'direct_gmv' &&
      fieldName != 'cost' &&
      fieldName != 'ads_gmv' &&
      fieldName != 'campaign_total_budget' &&
      fieldName != 'campaign_daily_budget'
    );
  }
  return false;
};

export const getAggregateCellActions = (typeCell: 'string' | 'float' | 'integer' | 'date', columnField: string) => {
  if (!typeCell) return [];

  switch (typeCell.toLocaleLowerCase()) {
    case 'checkbox':
      return [
        {
          label: '',
          name: 'None',
          value: aggFuncByKey.none.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'All',
          name: 'Count all',
          value: 'count_all',
          component: null,
          onSubmit: null,
        },
        {
          label: 'Checked',
          name: 'Checked',
          value: 'checked',
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unchecked',
          name: 'Unchecked',
          value: 'unchecked',
          component: null,
          onSubmit: null,
        },
        {
          label: 'Checked',
          name: 'Percent checked',
          value: 'percent_checked',
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unchecked',
          name: 'Percent unchecked',
          value: 'percent_unchecked',
          component: null,
          onSubmit: null,
        },
      ];
    case 'float':
    case 'integer':
      return [
        {
          label: '',
          name: 'None',
          value: aggFuncByKey.none.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'All',
          name: 'Count all',
          value: aggFuncByKey.count.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Values',
          name: 'Count values',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unique',
          name: 'Count unique values',
          value: aggFuncByKey.unique.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Count empty',
          value: aggFuncByKey.count_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Count not empty',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Percent empty',
          value: aggFuncByKey.percent_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Percent not empty',
          value: aggFuncByKey.percent_not_empty.id,
          component: null,
          onSubmit: null,
        },
        ...(isValidAggregateFun(columnField, aggFuncByKey.sum.id)
          ? [
              {
                label: 'Sum',
                name: 'Sum',
                value: aggFuncByKey.sum.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
        ...(isValidAggregateFun(columnField, aggFuncByKey.avg.id)
          ? [
              {
                label: 'Average',
                name: 'Average',
                value: aggFuncByKey.avg.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
        ...(isValidAggregateFun(columnField, aggFuncByKey.median.id)
          ? [
              {
                label: 'Median',
                name: 'Median',
                value: aggFuncByKey.median.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
        ...(isValidAggregateFun(columnField, aggFuncByKey.min.id)
          ? [
              {
                label: 'Min',
                name: 'Min',
                value: aggFuncByKey.min.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
        ...(isValidAggregateFun(columnField, aggFuncByKey.max.id)
          ? [
              {
                label: 'Max',
                name: 'Max',
                value: aggFuncByKey.max.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
        ...(isValidAggregateFun(columnField, aggFuncByKey.range.id)
          ? [
              {
                label: 'Range',
                name: 'Range',
                value: aggFuncByKey.range.id,
                component: null,
                onSubmit: null,
              },
            ]
          : []),
      ];
    case 'string':
      return [
        {
          label: '',
          name: 'None',
          value: aggFuncByKey.none.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'All',
          name: 'Count all',
          value: aggFuncByKey.count.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Values',
          name: 'Count values',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unique',
          name: 'Count unique values',
          value: aggFuncByKey.unique.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Count empty',
          value: aggFuncByKey.count_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Count not empty',
          value: aggFuncByKey['count_not_empty'].id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Percent empty',
          value: aggFuncByKey['percent_empty'].id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Percent not empty',
          value: aggFuncByKey['percent_not_empty'].id,
          component: null,
          onSubmit: null,
        },
      ];
    case 'date':
      return [
        {
          label: '',
          name: 'None',
          value: aggFuncByKey.none.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'All',
          name: 'Count all',
          value: aggFuncByKey.count.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Values',
          name: 'Count values',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unique',
          name: 'Count unique values',
          value: aggFuncByKey.unique.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Count empty',
          value: aggFuncByKey.count_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Count not empty',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Empty',
          name: 'Percent empty',
          value: aggFuncByKey.percent_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Not empty',
          name: 'Percent not empty',
          value: aggFuncByKey.percent_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Earliest',
          name: 'Earliest date',
          value: aggFuncByKey.earliest_date.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Latest',
          name: 'Latest date',
          value: aggFuncByKey.latest_date.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Range',
          name: 'Date range',
          value: aggFuncByKey.range.id,
          component: null,
          onSubmit: null,
        },
      ];
    default: {
      return [
        {
          label: 'All',
          name: 'Count all',
          value: aggFuncByKey.count.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Values',
          name: 'Count values',
          value: aggFuncByKey.count_not_empty.id,
          component: null,
          onSubmit: null,
        },
        {
          label: 'Unique',
          name: 'Count unique values',
          value: aggFuncByKey.unique.id,
          component: null,
          onSubmit: null,
        },
      ];
    }
  }
};

const getStatusDefaultActions = (statusField, menu) => {
  return [
    {
      name: 'Publish now',
      icon: 'upload',
      condition: [
        {
          field: statusField,
          operator: 'CONTAIN',
          values: ['DRAFT'],
        },
      ],
      payload: {
        field: {
          status: statusField,
        },
        static: {
          status: 'PUBLISHING',
        },
      },
      category: ACTION_CATEGORY_STATUS,
      shortcut: menu.shortcut,
      disable: menu.disable,
    },
    {
      name: 'Pause it',
      icon: 'pausedRounded',
      condition: [
        {
          field: statusField,
          operator: 'CONTAIN',
          values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
        },
      ],
      payload: {
        field: {
          status: statusField,
        },
        static: {
          status: 'SUSPENDED',
        },
      },
      category: ACTION_CATEGORY_STATUS,
      shortcut: menu.shortcut,
      disable: menu.disable,
    },
    {
      name: 'Resume it',
      icon: 'refreshRounded',
      condition: [
        {
          field: statusField,
          operator: 'CONTAIN',
          values: ['SUSPENDED'],
        },
      ],
      payload: {
        field: {
          status: statusField,
        },
        static: {
          status: 'RECOVERING',
        },
      },
      category: ACTION_CATEGORY_STATUS,
      shortcut: menu.shortcut,
      disable: menu.disable,
    },
  ];
};

export const enhanceCellAction = (cellAction, edot, field, backbone) => {
  const edotActions = edot
    .map((el) => {
      switch (el) {
        case 'update_cookie':
          return {
            component: TextareaEditor,
            name: 'Update cookie',
            icon: 'pencil',
            title: 'Update cookie',
            async onSubmit({ value, row, backbone }) {
              const mapping = backbone.getConfig('mapping');

              const columnHasStorefrontId = Object.keys(mapping).find((key) => {
                return Object.keys(mapping[key].valueGetter).includes('storefront_id');
              });
              const storefrontIdField = get(mapping, [columnHasStorefrontId, 'valueGetter', 'storefront_id'], '');

              const columnHasUsername = Object.keys(mapping).find((key) => {
                return Object.keys(mapping[key].valueGetter).includes('username');
              });
              const usernameField = get(mapping, [columnHasUsername, 'valueGetter', 'username'], '');

              const payload = {
                cookie: value,
                storefront_id: storefrontIdField ? row[storefrontIdField] : null,
                username: usernameField ? row[usernameField] : null,
              };
              const response = await updateCookie(payload);
              if (response.success || response.code == 200) {
                return {
                  success: true,
                  message: 'Success.',
                  code: 200,
                };
              }
              return {
                success: false,
                message: response.message,
                code: response.code,
              };
            },
            isTextarea: true,
          };
        case 'update_comment':
          return {
            component: TextareaEditor,
            name: 'Update comment',
            icon: 'pencil',
            title: 'Update comment',
            async onSubmit({ value, row, backbone, rowNode }) {
              const mapping = backbone.getConfig('mapping');

              const columnHasStorefrontId = Object.keys(mapping).find((key) => {
                return Object.keys(mapping[key].valueGetter).includes('storefront_id');
              });
              const storefrontIdField = get(mapping, [columnHasStorefrontId, 'valueGetter', 'storefront_id'], '');
              const commentField = get(mapping, [field, 'valueGetter', 'label'], '');

              const payload = {
                table: 'storefront',
                keys: {
                  id: row[storefrontIdField],
                },
                values: {
                  comment: value,
                },
              };
              const response = await updateComment(payload);
              if (response.success || response.code == 200) {
                rowNode.setData({
                  ...row,
                  [commentField]: value,
                });
                return {
                  success: true,
                  message: 'Success.',
                  code: 200,
                };
              }
              return {
                success: false,
                message: response.message,
                code: response.code,
              };
            },
            isTextarea: true,
          };
        case 'display_history':
          return {
            name: 'History',
            icon: 'history',
            id: 'history',
            payload: {
              field: {
                dimension_type: 'ADS_PLACEMENT',
              },
            },
          };
        case 'auto_renew':
          return {
            component: AutoRenew,
            name: 'On/off auto renew',
            icon: 'pencil',
            title: 'On/off auto renew',
            field: el,
            onSubmit: async ({ storefrontId, valueRenew, row, rowNode, backbone }) => {
              const mapping = backbone.getConfig('mapping');
              const labelField = get(mapping, [field, 'valueGetter', 'label'], '');

              const payload = {
                dimension: 'storefront.allowRenewCookie',
                id: [storefrontId],
                data: {
                  [storefrontId]: valueRenew,
                },
              };
              const response = await autoRenewal(payload);
              if (response.serviceResponse && response.serviceResponse.success) {
                rowNode.setData({
                  ...row,
                  [labelField]: valueRenew,
                });
                return {
                  success: true,
                  message: 'Success!',
                  code: 200,
                };
              } else {
                return {
                  success: false,
                  message: 'Something went wrong! Please try again.',
                };
              }
            },
          };
        case 'auto_pull_push':
          return {
            component: AutoRenew,
            name: 'On/off pull push',
            icon: 'pencil',
            title: 'On/off pull push',
            field: el,
            onSubmit: async ({ storefrontId, valueRenew, row, rowNode, backbone }) => {
              const mapping = backbone.getConfig('mapping');
              const response = [];
              const handleResponse = (res, key) => {
                if (res.serviceResponse && res.serviceResponse.success) {
                  const labelField = get(mapping, [field, 'valueGetter', `storefront_${key}_value`], '');
                  rowNode.setData({
                    ...row,
                    [labelField]: valueRenew[key],
                  });
                  response.push({
                    success: true,
                    message: `Update ${key} Success!`,
                    code: 200,
                  });
                } else {
                  response.push({
                    success: false,
                    message: res.message ? res.message : `Update ${key} Failed!`,
                  });
                }
              };

              const payloadPull = {
                dimension: 'storefront.updateAllowPull',
                id: [storefrontId],
                data: {
                  [storefrontId]: valueRenew?.pull,
                },
              };
              const payloadPush = {
                dimension: 'storefront.updateAllowPush',
                id: [storefrontId],
                data: {
                  [storefrontId]: valueRenew?.push,
                },
              };
              const res = await Promise.all([autoRenewal(payloadPull), autoRenewal(payloadPush)]);
              res.forEach((rs, id) => {
                let key;
                switch (id) {
                  case 0:
                    key = 'pull';
                    break;
                  case 1:
                    key = 'push';
                    break;
                  default:
                    break;
                }
                handleResponse(rs, key);
              });
              return response;
            },
          };
        case 'login_credential':
          return {
            component: SCLoginCredential,
            name: 'Change SC login credential',
            icon: 'pencil',
            title: 'Change SC login credential',
            onSubmit: async ({ storefrontId, account }) => {
              const payload = {
                id: [storefrontId],
                dimension: 'storefront.updateAccount',
                data: {
                  [storefrontId]: account,
                },
              };
              const response = await autoRenewal(payload);
              if (response.serviceResponse && response.serviceResponse.success) {
                return {
                  success: true,
                  message: 'Success!',
                  code: 200,
                };
              } else {
                return {
                  success: false,
                  message: 'Something went wrong! Please try again.',
                };
              }
            },
          };
        default:
          return null;
      }
    })
    .filter((el) => !!el);

  let customCellActions = [];
  if (backbone) {
    const mappings = backbone.getConfig('mapping');

    const colDef = mappings[field];

    const statusActions = get(colDef, ['actions'], []).filter((el) => el.category === ACTION_CATEGORY_STATUS);

    if (statusActions.length > 0) {
      customCellActions = customCellActions.concat(
        statusActions.reduce((a, b) => {
          const statusField = (b.configuration || []).find((el) => el.key === 'status_field') || { value: '' };

          if (statusField?.value) return [...a, ...getStatusDefaultActions(statusField.value, b)];
          return a;
        }, []),
      );
    }
  }

  return cellAction.concat(edotActions).concat(customCellActions);
};
