import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Arrowup = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.842202 3.82667C0.729681 3.71196 0.666626 3.55753 0.666626 3.39667C0.666626 3.2358 0.729681 3.08138 0.842202 2.96667L3.5759 0.18C3.62995 0.123121 3.69496 0.0778374 3.76699 0.046896C3.83902 0.0159546 3.91657 0 3.99494 0C4.07331 0 4.15086 0.0159546 4.22289 0.046896C4.29492 0.0778374 4.35993 0.123121 4.41397 0.18L7.14768 2.96667C7.20641 3.02383 7.2531 3.09223 7.28498 3.16781C7.31686 3.24339 7.33329 3.32461 7.33329 3.40667C7.33329 3.48872 7.31686 3.56995 7.28498 3.64552C7.2531 3.7211 7.20641 3.7895 7.14768 3.84667C7.03174 3.95102 6.87942 4.00535 6.72377 3.99786C6.56812 3.99037 6.42169 3.92167 6.31626 3.80667L3.99494 1.46667L1.68692 3.82667C1.5739 3.93702 1.42235 3.99878 1.26456 3.99878C1.10677 3.99878 0.955224 3.93702 0.842202 3.82667Z"
      fill="#8D979E"
    />
  </SvgIcon>
);

export default Arrowup;
