import { IconType } from '@ep/insight-ui/icons/Icon';

export type ResponsiveScreenType = {
  title: string;
  sizeInfo: string;
  description: string;
  icon: IconType;
  sizeIcon: string;
  dimensions: {
    width: number;
    height: number;
  };
};
export const responsiveScreenList: ResponsiveScreenType[] = [
  {
    title: 'Large screen',
    sizeInfo: '1920px and up',
    description: "Desktop styles apply at 1920px and up, unless they're edited at a larger or smaller screens.",
    icon: 'bigScreen',
    sizeIcon: '20px',
    dimensions: {
      width: 1920,
      height: 1080,
    },
  },
  {
    title: 'Medium screen',
    sizeInfo: '1440px and up',
    description: "Desktop styles apply at 1440px and up, unless they're edited at a larger or smaller screens.",
    icon: 'mediumScreen',
    sizeIcon: '18px',
    dimensions: {
      width: 1440,
      height: 1080,
    },
  },
  {
    title: 'Desktop',
    sizeInfo: 'Base breakpoints -1280px',
    description: "Desktop styles apply at all breakpoints, unless they're edited at a larger or smaller screens.",
    icon: 'desktop',
    sizeIcon: '20px',
    dimensions: {
      width: 1280,
      height: 960,
    },
  },
  {
    title: 'Tablet',
    sizeInfo: '959 px and down',
    description: "Desktop styles apply at all breakpoints, unless they're edited at a larger or smaller breakpoints.",
    icon: 'tablet',
    sizeIcon: '14px',
    dimensions: {
      width: 959,
      height: 768,
    },
  },
  {
    title: 'Mobile',
    sizeInfo: '767px and down',
    description: "Desktop styles apply at all breakpoints, unless they're edited at a larger or smaller breakpoints.",
    icon: 'mobile',
    sizeIcon: '10px',
    dimensions: {
      width: 767,
      height: 1024,
    },
  },
];
