import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CloseCircle = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 0C4.81331 0 3.65328 0.351894 2.66658 1.01118C1.67989 1.67047 0.910851 2.60754 0.456726 3.7039C0.00259972 4.80026 -0.11622 6.00666 0.115291 7.17054C0.346802 8.33443 0.918247 9.40353 1.75736 10.2426C2.59648 11.0818 3.66558 11.6532 4.82946 11.8847C5.99335 12.1162 7.19975 11.9974 8.2961 11.5433C9.39246 11.0892 10.3295 10.3201 10.9888 9.33342C11.6481 8.34673 12 7.18669 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0ZM8.79 7.41C8.97831 7.5983 9.08409 7.8537 9.08409 8.12C9.08409 8.3863 8.97831 8.6417 8.79 8.83C8.6017 9.0183 8.3463 9.12409 8.08 9.12409C7.8137 9.12409 7.55831 9.0183 7.37 8.83L6 7.41L4.54 8.83C4.35264 9.01625 4.09919 9.12079 3.835 9.12079C3.57082 9.12079 3.31737 9.01625 3.13 8.83C3.03627 8.73704 2.96188 8.62644 2.91111 8.50458C2.86034 8.38272 2.8342 8.25201 2.8342 8.12C2.8342 7.98799 2.86034 7.85728 2.91111 7.73542C2.96188 7.61356 3.03627 7.50296 3.13 7.41L4.54 6L3.13 4.59C3.03627 4.49704 2.96188 4.38644 2.91111 4.26458C2.86034 4.14272 2.8342 4.01201 2.8342 3.88C2.8342 3.74799 2.86034 3.61728 2.91111 3.49542C2.96188 3.37356 3.03627 3.26296 3.13 3.17C3.31737 2.98375 3.57082 2.87921 3.835 2.87921C4.09919 2.87921 4.35264 2.98375 4.54 3.17L6 4.59L7.37 3.17C7.55831 2.9817 7.8137 2.87591 8.08 2.87591C8.3463 2.87591 8.6017 2.9817 8.79 3.17C8.97831 3.3583 9.08409 3.6137 9.08409 3.88C9.08409 4.1463 8.97831 4.4017 8.79 4.59L7.37 6L8.79 7.41Z" />
  </SvgIcon>
);

export default CloseCircle;
