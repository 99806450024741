import * as React from 'react';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Box, FormControl, InputAdornment, MenuItem, Select, SelectProps } from '@material-ui/core';
import { colors, scrollbarStyles } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import _ from 'lodash';
type PropsStyle = {
  inputWidth: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  formControl: {
    '& input': {
      height: '100%',
    },
  },
  select: (props: PropsStyle) => ({
    width: props.inputWidth || '100%',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '20px',
    },
    display: 'flex',
    alignItems: ' center',
  }),
  popper: {
    ...scrollbarStyles,
    maxHeight: 'calc(100vh - 60%)',
    marginTop: '4px',
    boxSizing: 'border-box',
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    position: 'absolute',
    right: '10px',
    '&.icon_select': {
      color: '#000',
    },
  },
  value: {
    maxWidth: '100%',
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: ' hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  textUnit: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: ' 14px',
    margin: '0 7px',
  },
  adornment: {
    position: 'absolute',
    right: 0,
  },
  inputAdornment: {},
}));

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      height: '100%',
      paddingLeft: '8px',
      '&:focus': {
        backgroundColor: 'transparent',
        borderRadius: '4px',
      },
    },
  }),
)(InputBase);
export type AlignType = 'left' | 'right';
export interface SelectFormProps extends SelectProps {
  options?: OptionSelectType[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onChangeObject?: (value: OptionSelectType) => void;
  inputWidth?: string;
  alignMenu?: AlignType;
  placeholder?: string;
  disableIconSelect?: boolean;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  classNameText?: string;
  unit?: string;
}

export const StyledMenuItem = withStyles(() => ({
  root: {
    minHeight: '32px',
    minWidth: '192px',
    borderRadius: '4px',
    padding: '8px',
    '&:hover': {
      backgroundColor: colors.action.subtleHovered,
      fontWeight: 500,
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    fontWeight: 400,
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
  },
}))(MenuItem);

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: colors.text.subdued,
  },
}));

const Placeholder = ({ text }: { text: string }) => {
  const classes = usePlaceholderStyles();
  return (
    <Typography variant="body2" className={classes.placeholder}>
      {text}
    </Typography>
  );
};

const TextSelectForm: React.FC<SelectFormProps> = ({
  options,
  value,
  onChange,
  onChangeObject,
  alignMenu = 'left',
  inputWidth,
  placeholder,
  renderOption,
  disableIconSelect = false,
  disabled = false,
  classNameText = '',
  unit = '',
  ...rest
}: SelectFormProps) => {
  const classes = useStyles({ inputWidth });
  const renderValue = () => {
    const index = _.findIndex(options, ['value', value]);
    if (value !== '' && index !== -1) {
      return (
        <Box height={'100%'} display={'flex'} alignItems={'center'}>
          {options[index].icon && (
            <span style={{ display: 'flex', margin: '0 20px', width: '12px', height: '14px' }}>
              <Icon type={options[index].icon} style={{ width: '100%', height: '100%' }} />
            </span>
          )}
          <div className={`${classes.value} ${classNameText}`}>{options[index].label}</div>
        </Box>
      );
    }
    return placeholder ? <Placeholder text={placeholder} /> : null;
  };

  const handleOnChange = (e) => {
    onChange(e);
    if (onChangeObject) {
      const index = _.findIndex(options, ['value', value]);
      if (index) {
        onChangeObject(options[index]);
      }
    }
  };
  return (
    <FormControl className={classes.formControl}>
      <Select
        disabled={disabled}
        autoFocus={false}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: alignMenu,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: alignMenu,
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.popper,
          },
        }}
        value={value}
        onChange={handleOnChange}
        IconComponent={() => (
          <InputAdornment position="end" className={classes.adornment}>
            {!disableIconSelect ? (
              <Icon type="chevron" className={`${classes.icon}  icon_select`} />
            ) : unit ? (
              <span className={classes.textUnit}>{unit}</span>
            ) : null}
          </InputAdornment>
        )}
        renderValue={renderValue}
        input={<BootstrapInput />}
        {...rest}
      >
        {options.map((item: OptionSelectType, index: number) => (
          <StyledMenuItem key={index} value={item.value}>
            {item.icon && (
              <Box mx={2} display={'flex'} height={'100%'} alignItems={'center'} width={12}>
                <Icon type={item.icon} style={{ width: '100%', height: '100%' }} color={'inherit'} />
              </Box>
            )}
            {renderOption ? renderOption(item) : item.label}
          </StyledMenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TextSelectForm;
