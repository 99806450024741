import * as React from 'react';
import AggregateCellFormat from './format/aggregate-cell-format';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';

const cachedCellComponents = [];
function getDisplayName(WrappedComponent) {
  return WrappedComponent ? WrappedComponent.displayName || WrappedComponent.name || 'Component' : 'UnknownComponent';
}
/**
 * Make sure we return the same instance of React component
 * @param CellFormat
 * @returns
 */
export const groupableCell = (CellFormat: any) => {
  const foundComponent = cachedCellComponents.find((i) => i[1] === CellFormat);
  let CachedComponent = null;
  // console.info('cached', getDisplayName(CellFormat), CellFormat, CachedComponent);
  if (!foundComponent) {
    CachedComponent = function GroupableCell({ node, ...rest }) {
      if (node.group) {
        return (
          <MonitorContainer
            mId={`column.${rest.field}`}
            mLabel={`column.${rest.field}`}
            component={AggregateCellFormat}
            node={node}
            {...rest}
          />
        );
      } else {
        return (
          <MonitorContainer
            mId={`column.${rest.field}`}
            mLabel={`column.${rest.field}`}
            component={CellFormat}
            node={node}
            {...rest}
          />
        );
      }
    };
    CachedComponent.displayName = getDisplayName(CellFormat);
    cachedCellComponents.push([CachedComponent, CellFormat]);
  } else {
    CachedComponent = foundComponent[0];
  }

  return CachedComponent;
};
