import * as React from 'react';
import { get, max, min, uniq } from 'lodash';

import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';

import {
  operatorRTBType,
  defaultOperators,
  OptionRTBType,
  OperatorTypeRTB,
  CONST_OPERATOR,
  options,
  setBidToOperator,
  CONST_OPTION,
} from '@ep/insight-ui/elements/inline-edit/cell/rtb-editor/rtb-editor';
import { getAPIRequest } from '@ep/insight-ui/system/backbone/table-backbone';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import TextFieldNumber from '@ep/insight-ui/elements/textField/textField-number';
import { USE_FORMULA } from '@ep/insight-ui/system/helper/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const useStyles = makeStyles(() => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    // marginTop: '10px',
  },
  select: {
    width: '100%',
  },
  formGroup: {},
  formControl: {
    width: '100%',
    '&.sm': {
      width: '70%',
    },
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

const BiddingEditor = ({ data, title = '', payload, onLoad, configuration, rowData, onChange, field }: any) => {
  const classes = useStyles();
  const inputRef = React.useRef();
  const [operators, setOperators] = React.useState<operatorRTBType>(defaultOperators);
  const [value, setValue] = React.useState<string>('0');
  const [option, setOption] = React.useState<OptionRTBType>({ label: '', value: '', dataType: '' });
  const [operator, setOperator] = React.useState<OperatorTypeRTB>({ label: '', value: '' });
  const [suggestedValues, setSuggestedValues] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const currency = get(rowData, ['eData', field, 'currency'], '');

  const excludeOperators = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const excludeOperatorsObject = fieldConfiguration.find((el) => el.key === 'exclude_operators') || {
      value: '',
    };
    return excludeOperatorsObject.value;
  }, [configuration]);

  const primaryKeyField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const primaryKeyFieldObject = fieldConfiguration.find((el) => el.key === 'primary_key') || { value: '' };
    return primaryKeyFieldObject.value;
  }, [configuration]);

  const marketplaceCodeField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const marketplaceCodeFieldObject = fieldConfiguration.find((el) => el.key === 'marketplace_code') || { value: '' };
    return marketplaceCodeFieldObject.value;
  }, [configuration]);

  const toolCodeField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const toolCodeFieldObject = fieldConfiguration.find((el) => el.key === 'tool_code') || { value: '' };
    return toolCodeFieldObject.value;
  }, [configuration]);

  const storefrontIdField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const storefrontIdFieldObject = fieldConfiguration.find((el) => el.key === 'storefront_id') || { value: '' };
    return storefrontIdFieldObject.value;
  }, [configuration]);

  const masterObjectIdField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const masterObjectIdFieldObject = fieldConfiguration.find((el) => el.key === 'master_object_id') || { value: '' };
    return masterObjectIdFieldObject.value;
  }, [configuration]);

  const nameField = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const nameFieldObject = fieldConfiguration.find((el) => el.key === 'name') || { value: '' };
    return nameFieldObject.value;
  }, [configuration]);

  React.useEffect(() => {
    // if (Boolean(anchorEl) || (ff.mobile_interaction_zone && isMobileView)) {
    const dataValue = get(data, ['value', 'value'], 0);
    if (dataValue !== value) setValue(String(dataValue || 0));

    const selectedRows = data
      ? data.gridApi
        ? uniq([...data.gridApi.getSelectedRows(), data.node.data])
        : [data.node.data]
      : [];
    const exclOperators = toValue(excludeOperators, { ...rowData, selectedRows }, true) || [];

    const newOperators = Object.keys(operators).reduce((acc, value) => {
      const typeOperators = operators[value]
        .filter((o) => {
          try {
            const exclude = exclOperators.find((i) => i === o.value);

            return !exclude;
          } catch (e) {
            return true;
          }
        })
        .map((o) => {
          return { ...o, unit: o.unit != '%' ? currency : o.unit };
        });
      return { ...acc, [value]: typeOperators };
    }, {});

    // set by default
    setOption(options.find((item) => item.value == CONST_OPTION.SET_BID_TO));
    setOperator(setBidToOperator.find((item) => item.value == CONST_OPERATOR.EXACT_VALUE));
    setOperators(newOperators);
    // }
  }, [rowData]);

  React.useEffect(() => {
    const timeoutOnChange = setTimeout(() => {
      let valueRequest: string | number = '';
      switch (option.value) {
        case CONST_OPTION.DECREASE: {
          switch (operator.value) {
            case CONST_OPERATOR.BY_PERCENTAGE: {
              valueRequest = `${USE_FORMULA}=p('value') * ${1 - +value / 100}`;
              break;
            }
            case CONST_OPERATOR.BY_AMOUNT: {
              valueRequest = `${USE_FORMULA}=p('value') - ${+value}`;
              break;
            }
          }
          break;
        }
        case CONST_OPTION.INCREASE: {
          switch (operator.value) {
            case CONST_OPERATOR.BY_PERCENTAGE: {
              valueRequest = `${USE_FORMULA}=p('value') * ${1 + +value / 100}`;
              break;
            }
            case CONST_OPERATOR.BY_AMOUNT: {
              valueRequest = `${USE_FORMULA}=p('value') + ${+value}`;
              break;
            }
          }
          break;
        }
        case CONST_OPTION.SET_BID_TO: {
          switch (operator.value) {
            case CONST_OPERATOR.EXACT_VALUE: {
              valueRequest = +value;
              break;
            }
            case CONST_OPERATOR.SUGGEST_BID_PRICE: {
              if (suggestedValues.length > 0) {
                const suggestedValuesObj = suggestedValues.reduce((carry, { id, value }) => {
                  return {
                    ...carry,
                    [id]: value,
                  };
                }, {});
                valueRequest = `${USE_FORMULA}=${JSON.stringify(suggestedValuesObj)}[p('${primaryKeyField}')] || 0`;
              }
              break;
            }
          }
          break;
        }
      }

      onChange({
        target: {
          value: valueRequest,
        },
      });
    }, 100);

    return () => {
      clearTimeout(timeoutOnChange);
    };
  }, [value, option, operator, suggestedValues]);

  const initSuggestData = async () => {
    if (Boolean(getAPIRequest)) {
      setLoading(true);
      const selectedRows = data.gridApi ? [...data.gridApi.getSelectedRows(), data.node.data] : [data.node.data];
      const res = await getAPIRequest('').getSuggestBiddingPrice({
        marketplaceCode: get(data.node, ['data', marketplaceCodeField]),
        toolCodeIds: uniq(selectedRows.map((r) => r[toolCodeField])),
        storefrontIds: uniq(selectedRows.map((r) => r[storefrontIdField])),
        masterObjectIds: uniq(selectedRows.map((r) => r[masterObjectIdField])),
        keywords: uniq(selectedRows.map((r) => r[nameField])),
      });
      if (res.success) {
        // TODO: set initial suggest values
        const keywordPrices = res.data.map((i) => {
          const matchedData = selectedRows.find((row) => row[nameField] == i.name);
          return {
            name: i.name,
            id: matchedData ? matchedData[primaryKeyField] : null,
            value: parseFloat(i.biddingPrice.toFixed(2)),
          };
        });
        setSuggestedValues(keywordPrices);
      } else {
        setErrorMessage(res.msessage);
      }
      setLoading(false);
    }
  };

  const getOperatorByOptionType = (dataType) => {
    // setValue('');
    if (operators[dataType]) {
      return operators[dataType];
    }
    return [];
  };

  const handleOnchangeValue = (e) => {
    setErrorMessage('');
    if (operator.value === CONST_OPERATOR.SUGGEST_BID_PRICE) {
      setValue(suggestedValues[0]?.value || '0');
    }
    const { name } = e.target;
    setLoading(false);
    if (name == 'option') {
      const item = options.find((i) => i.value == e.target.value);
      if (item) {
        setOption(item);
        setOperator(operators[item.dataType][0]);
      }
    } else if (name == 'operator') {
      const item = operators[option.dataType].find((i) => i.value == e.target.value);
      if (item) {
        setOperator(item);
        if (item.value === 'suggest-bid-price') {
          initSuggestData();
        }
      }
    }
  };

  const handleValue = () => {
    if (operator.value !== CONST_OPERATOR.SUGGEST_BID_PRICE) return value;
    const suggestPrices = suggestedValues.map((i) => i.value);
    if (suggestPrices.length == 1) {
      return get(suggestPrices, [0], '');
    }

    if (suggestPrices.length > 1) {
      const suggestedMin = min(suggestPrices);
      const suggestedMax = max(suggestPrices);

      return `${suggestedMin} ➞ ${suggestedMax}`;
    }
    return '';
  };

  return (
    <div style={{ padding: '0', minWidth: '504px' }}>
      {loading ? (
        <Box sx={{ display: 'flex', marginBottom: '10px' }}>
          <Box>
            <HeaderList
              title={title}
              variant="h4"
              className={classes.header}
              iconHead={
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              }
            />
          </Box>
        </Box>
      ) : null}
      <div className={classes.dialog}>
        <Grid container wrap={'nowrap'} justifyContent="space-between" spacing={2} className={classes.formGroup}>
          <Grid item className={`${classes.formControl} sm`}>
            <SelectForm
              options={options}
              name={'option'}
              value={option.value}
              onChange={handleOnchangeValue}
              className={classes.select}
            />
          </Grid>
          <Grid item className={`${classes.formControl} sm`}>
            <SelectForm
              options={getOperatorByOptionType(option.dataType)}
              value={operator.value}
              name={'operator'}
              onChange={handleOnchangeValue}
              className={classes.select}
            />
          </Grid>
          <Grid item className={classes.formControl}>
            <TextFieldNumber
              inputRef={inputRef}
              disabled={operator.value == CONST_OPERATOR.SUGGEST_BID_PRICE}
              value={handleValue()}
              style={{ width: '100%', marginLeft: 0 }}
              placeholder={'Your value'}
              onChangeNumber={(number) => setValue(String(number))}
              unit={rowData?.[currency] || operator.unit || currency}
              timeDebounce={0}
            />
          </Grid>
        </Grid>
        {errorMessage ? <span className={classes.textError}>{errorMessage}</span> : null}
      </div>
    </div>
  );
};

export default BiddingEditor;
