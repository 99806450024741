import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import * as _ from 'lodash';
import moment from 'moment';
import numeral from 'numeral';
import { colorsChartLegend, convertColor, legendLine, PopLegend, tableTooltip } from '../helper';
import { get } from 'lodash';
export const HIDDEN_LABEL_TEXT = '__disabled__';
const useStyles = makeStyles((theme) => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: theme.zIndex.drawer + 10,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
  btnRight: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    background: '#fff',
    zIndex: 100,
    cursor: 'pointer',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  btnLeft: {
    display: 'none',
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    zIndex: 100,
    cursor: 'pointer',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}
interface ILabel {
  borderRadius?: number;
  datasetIndex?: number;
  fillStyle?: string;
  fontColor?: string;
  lineCap?: string;
  hidden?: boolean;
  lineWidth?: number;
  strokeStyle?: string;
  text?: string;
}
const hashIndex = {
  0: 0,
  2: 1,
  4: 5,
  6: 3,
  8: 4,
  10: 5,
  12: 6,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();
  let labels: ILabel[];
  let labelsWidthouDisabled: ILabel[];
  let isFuture: boolean;

  const getOrCreateTooltipAndCrisper = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('.tooltip') as HTMLDivElement;
    let crisperEl = chart.canvas.parentNode.querySelector('.crisper') as HTMLSpanElement;

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = `${classes.tooltip} tooltip`;

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!crisperEl) {
      crisperEl = document.createElement('span') as HTMLSpanElement;
      crisperEl.className = `crisper`;
      crisperEl.style.position = 'absolute';

      chart.canvas.parentNode.appendChild(crisperEl);
    }

    return { tooltipEl, crisperEl };
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const { tooltipEl, crisperEl } = getOrCreateTooltipAndCrisper(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      crisperEl.style.opacity = '0';
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      const total = _.sumBy(bodyLines, '[0].value');

      const tableHead = document.createElement('thead');
      const firstLine = get(bodyLines, '0.0', {});

      titleLines.forEach((title) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        let date = moment(title);
        if (firstLine.axisData) {
          date = moment(firstLine.axisData);
        }
        let text = document.createTextNode(title);
        if (date.isValid()) {
          const options = chart.options;
          if (options.period === 'hourly') {
            text = document.createTextNode(date.format('ddd, DD MMM YYYY HH:mm'));
          } else {
            text = document.createTextNode(date.format('ddd, DD MMM YYYY'));
          }
        }

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        let percent = '';
        const type: string = _.get(body, [0, 'type'], '');
        if (type === 'line') return;
        const specialKey: string = _.get(body, [0, 'specialKey'], false);
        const label: string = _.get(body, [0, 'label'], '');
        const value: number = _.get(body, [0, 'value'], 0);
        const totalValue: number = _.get(body, [0, 'totalValue'], 0);
        const isBullet: boolean = _.get(body, [0, 'isBullet'], false);
        const currency: string = _.get(body, [0, 'currency'], '') || '';
        const formatLabel: string = _.get(body, [0, 'formatLabel'], '');
        let valueText = formatCurrencyNumber(value, currency);
        if (formatLabel.startsWith('=')) {
          valueText = toValue(formatLabel, _.get(body, [0], {}));
        }

        if (isBullet) {
          percent = `(${numeral((value * 100) / totalValue).format('0.00')}%)`;
        } else {
          percent = `(${numeral((value / total) * 100).format('0.00')}%)`;
        }

        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.className = 'legendRow';
        const colors = tooltip.labelColors[i];
        const tdHTML = `
            <td class="legendLine">
              <span class="legendIcon" style="background: ${colors.backgroundColor}; border-color: ${
          colors.borderColor
        };"></span>
              <div class="content">
              <div class="label">${label}</div>
              <div class="value">${`${valueText} ${formatLabel.includes('currency') ? '' : currency}`}</div>
              </div>
            </td>
          `;
        tr.innerHTML = tdHTML;
        if (!(String(label) || '').includes(HIDDEN_LABEL_TEXT)) {
          tableBody.appendChild(tr);
        } else if (specialKey) {
          const textKey: string = _.get(body, [0, 'textKey'], '');
          const colorTooltip: string = _.get(body, [0, 'colorTooltip'], '');
          tr.querySelector('.label').textContent = textKey;
          const leng: HTMLSpanElement = tr.querySelector('.legendIcon');
          leng.style.background = colorTooltip;
          tableBody.appendChild(tr);
        }
      });

      const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.className = `${classes.tableTooltip}`;
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    const PADDING = 20;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + PADDING + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = '0';
    tooltipEl.style.transform = 'translateX(0)';

    if (tooltip.caretX > chart.chartArea.width - tooltipEl.offsetWidth) {
      tooltipEl.style.transform = 'translateX(-100%)';
      tooltipEl.style.left = positionX + tooltip.caretX - PADDING + 'px';
    }

    const { isCrisper } = chart.options;
    if (!isCrisper) return;

    // crisperEl
    crisperEl.style.opacity = '1';

    crisperEl.style.height = `${chart.chartArea.height}px`;
    crisperEl.style.width = '2px';
    crisperEl.style.backgroundColor = '#E4E7E9';
    crisperEl.style.pointerEvents = 'none';

    crisperEl.style.left = positionX + tooltip.caretX + 'px';
    crisperEl.style.top = '10px';
  };

  const getOrCreateLegendList = (id: string) => {
    const legendContainer = document.getElementById(id);
    if (legendContainer) {
      const listContainer = legendContainer.querySelector(`ul`);
      const popLegend: HTMLElement = legendContainer.querySelector(`.${classes.pop}`);
      const totalValue = legendContainer.querySelector(`.${classes.totalValueLine}`);

      //Create legend custom
      if (!listContainer) {
        // const div = document.createElement('div');
        const listContainer = document.createElement('ul');
        listContainer.className = classes.legendLine;
        // div.appendChild(listContainer);
        legendContainer.appendChild(listContainer);
      }

      //Create pop-up when hover legend
      if (!popLegend) {
        const popLegend = document.createElement('p');
        popLegend.className = classes.pop;
        legendContainer.appendChild(popLegend);
      }

      const buttonRight = legendContainer.querySelector(`#btnRight-${id}`);
      if (!buttonRight) {
        const btnRight = document.createElement('span');
        btnRight.className = classes.btnRight;
        btnRight.id = `btnRight-${id}`;
        btnRight.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM358.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L290.8 256L201.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C364.9 239.6 368 247.8 368 256S364.9 272.4 358.6 278.6z"/></svg>';
        btnRight.onclick = (e) => {
          const legendNode = legendContainer.querySelector('ul');
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (legendNode) {
            const { scrollLeft, scrollWidth, clientWidth } = legendNode;
            if (scrollLeft < scrollWidth - clientWidth) {
              legendNode.scrollTo({
                left: scrollLeft + 200,
                behavior: 'smooth',
              });
              if (scrollLeft + 200 >= scrollWidth - clientWidth) {
                btnRight.style.display = 'none';
              }
            }

            btnLeft.style.display = 'inline-block';
          }
        };
        btnRight.onmouseenter = () => {
          btnRight.style.display = 'inline-block';
          const legendNode = legendContainer.querySelector('ul');
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (legendNode) {
            setTimeout(() => {
              const { scrollLeft, scrollWidth, clientWidth } = legendNode;
              if (scrollLeft >= scrollWidth - clientWidth) {
                btnRight.style.display = 'none';
              }
            }, 200);
            const { scrollLeft } = legendNode;
            if (scrollLeft > 0) {
              btnLeft.style.display = 'inline-block';
            }
          }
        };
        btnRight.onmouseout = () => {
          btnRight.style.display = 'none';
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (btnLeft) {
            btnLeft.style.display = 'none';
          }
        };
        legendContainer.appendChild(btnRight);
      }

      const buttonLeft = legendContainer.querySelector(`#btnLeft-${id}`);
      if (!buttonLeft) {
        const btnLeft = document.createElement('span');
        btnLeft.className = classes.btnLeft;
        btnLeft.id = `btnLeft-${id}`;
        btnLeft.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM310.6 345.4c12.5 12.5 12.5 32.75 0 45.25s-32.75 12.5-45.25 0l-112-112C147.1 272.4 144 264.2 144 256s3.125-16.38 9.375-22.62l112-112c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L221.3 256L310.6 345.4z"/></svg>';
        btnLeft.onclick = () => {
          const legendNode = legendContainer.querySelector('ul');
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (legendNode) {
            const { scrollLeft } = legendNode;
            if (scrollLeft > 0) {
              legendNode.scrollTo({
                left: scrollLeft - 200,
                behavior: 'smooth',
              });
              if (scrollLeft - 200 <= 0) {
                btnLeft.style.display = 'none';
              }
            }
            btnRight.style.display = 'inline-block';
          }
        };
        btnLeft.onmouseenter = () => {
          btnLeft.style.display = 'inline-block';
          const legendNode = legendContainer.querySelector('ul');
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (legendNode) {
            setTimeout(() => {
              const { scrollLeft } = legendNode;
              if (scrollLeft <= 0) {
                btnLeft.style.display = 'none';
              }
            }, 200);
            const { scrollLeft, scrollWidth, clientWidth } = legendNode;
            if (scrollLeft < scrollWidth - clientWidth) {
              btnRight.style.display = 'inline-block';
            }
          }
        };
        btnLeft.onmouseout = () => {
          btnLeft.style.display = 'none';
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (btnRight) {
            btnRight.style.display = 'none';
          }
        };
        legendContainer.appendChild(btnLeft);
      }

      //Create total value chart
      if (!totalValue) {
        const totalValue = document.createElement('p');
        totalValue.className = classes.totalValueLine;
        legendContainer.appendChild(totalValue);
      }

      return { listContainer, popLegend, totalValue };
    }
    return {};
  };

  let currentLegendItemHovering: number | null = null;
  let cacheUL: HTMLUListElement;
  //Custom legend
  const htmlLegendPlugin = {
    afterUpdate(chart, { mode }) {
      chart.canvas.parentNode.className = `${classes.wrapp} ${classes.canvas}`;
      labels = chart.options.plugins.legend.labels.generateLabels(chart).filter((el) => !el.lineDash);

      labelsWidthouDisabled = labels.filter((label) => !(String(label.text) || '').includes(HIDDEN_LABEL_TEXT));
      isFuture = labels.some((label) => (String(label.text) || '').includes(HIDDEN_LABEL_TEXT));
      const id = props.legendDivID;
      let ul = getOrCreateLegendList(id).listContainer;
      const checkEl = document.getElementById(id);
      // If another chart
      if (!checkEl) return;

      // If no Legend yet
      if (!ul) return;

      // If had create Legend
      if (cacheUL && mode !== 'reOrder') {
        ul = cacheUL;
        return;
      }

      // If don't have create Legend
      // Create custom Legend
      const legendItems = createLegendItems(chart, id);

      ul.innerHTML = '';
      ul.classList.add('legendDiv');
      legendItems.forEach((legendItem) => ul.appendChild(legendItem));

      cacheUL = ul;
    },
  };

  const createCheckBox = (index: number, color: string) => {
    const allActive = labelsWidthouDisabled.every((label) => !label.hidden);

    const hidden = labelsWidthouDisabled[index] && labelsWidthouDisabled[index].hidden;
    const cIndex =
      index >= Object.values(colorsChartLegend).length ? Object.values(colorsChartLegend).length - 1 : index;

    //Create checkbox
    const checkbox = document.createElement('span');
    checkbox.className = `legendItem__checkbox ${!hidden ? 'active' : ''}`;
    // console.info(colorsChartLegend[cIndex], cIndex, index);
    checkbox.style.background = `${
      !hidden
        ? Array.isArray(color) && color.length
          ? `linear-gradient(to right, ${color
              .map(
                (cl, index) =>
                  `${cl} ${index * (1 / color.length) * 100}%, ${cl} ${(index + 1) * (1 / color.length) * 100}%`,
              )
              .join(', ')})`
          : color || colorsChartLegend[cIndex].stroke
        : 'transparent'
    }`;
    //Create checked
    if (!allActive) {
      const after = document.createElement('span');
      const before = document.createElement('span');
      after.className = 'checked after';
      before.className = 'checked before';
      checkbox.appendChild(after);
      checkbox.appendChild(before);
    }

    return checkbox;
  };

  const createLegendItem = (label: ILabel, data, colors) => {
    const li = document.createElement('li');

    const checkbox = createCheckBox(
      isFuture ? hashIndex[label.datasetIndex] : label.datasetIndex,
      get(colors, [`${data?.key}_${data?.order}`, 'color'], data?.borderColor),
    );

    //Binding data text
    const textContainer = document.createElement('p');
    textContainer.style.color = label.fontColor;
    textContainer.style.textDecoration = label.hidden ? 'line-through' : '';
    textContainer.className = 'textContainer';
    const text = document.createTextNode(String(label.text));
    textContainer.appendChild(text);

    li.className = `legendItem`;
    li.appendChild(checkbox);
    li.appendChild(textContainer);

    return li;
  };

  const createLegendItems = (chart: any, id: string) => {
    const datas: Array<any> = chart.data.datasets.filter((el) => el.type !== 'line');

    const colors = datas.reduce((carry, item) => {
      return {
        ...carry,
        [`${item.key}_${item.order}`]: {
          color: item.borderColor,
          hoverBorderColor: item.pointHoverBorderColor,
        },
      };
    }, {});

    const onToggle = (legendIndex: number) => {
      const allActive = labels.every((label) => !label.hidden);

      if (!labels[legendIndex].hidden) {
        if (allActive) {
          labels.forEach((label) => (label.hidden = true));
          labels[legendIndex].hidden = false;
          if (isFuture) {
            labels[legendIndex + 1].hidden = false;
          }

          labels.forEach((label, index) => {
            const realIndex = datas.findIndex((data) => data.order == index);
            if (!label.hidden) return chart.setDatasetVisibility(realIndex, true);
            return chart.setDatasetVisibility(realIndex, false);
          });

          onMouseover(legendIndex);
          return;
        }

        if (!allActive) {
          labels[legendIndex].hidden = true;
          if (isFuture) {
            labels[legendIndex + 1].hidden = true;
          }

          const allNotActive = labels.every((label) => label.hidden);
          if (allNotActive) {
            labels.forEach((label) => (label.hidden = false));
          }

          labels.forEach((label, index) => {
            const realIndex = datas.findIndex((data) => data.order == index);
            if (!label.hidden) return chart.setDatasetVisibility(realIndex, true);
            return chart.setDatasetVisibility(realIndex, false);
          });
          onMouseout();
          return;
        }
      }

      labels[legendIndex].hidden = false;
      if (isFuture) {
        labels[legendIndex + 1].hidden = false;
      }
      labels.forEach((label, index) => {
        const realIndex = datas.findIndex((data) => data.order == index);
        if (!label.hidden) return chart.setDatasetVisibility(realIndex, true);
        return chart.setDatasetVisibility(realIndex, false);
      });
      onMouseover(legendIndex);
    };

    const onMouseover = (legendIndex: number) => {
      const divLegend = document.querySelector(`#${id}`);
      if (divLegend) {
        const menu = divLegend.querySelector('ul');
        if (menu) {
          const btnLeft = divLegend.querySelector(`#btnLeft-${id}`);
          const btnRight = divLegend.querySelector(`#btnRight-${id}`);
          if (menu.scrollLeft > 0 && btnLeft) {
            btnLeft.style.display = 'inline-block';
          }
          if (menu.scrollLeft < menu.scrollWidth - menu.clientWidth - 1) {
            btnRight.style.display = 'inline-block';
          }
        }
      }

      datas.forEach((data, index) => {
        if (Array.isArray(data.backgroundColor)) {
          data.backgroundColor = data.pointHoverBorderColor;
        } else {
          data.backgroundColor = get(colors, [`${data.key}_${data.order}`, 'hoverBorderColor']);
        }
      });

      const realLegendIndex = datas.findIndex((data) => data.order == legendIndex);
      if (Array.isArray(datas[realLegendIndex].backgroundColor)) {
        datas[realLegendIndex].backgroundColor = datas[realLegendIndex].pointHoverBackgroundColor;
      } else {
        const color = get(colors, [`${datas[realLegendIndex].key}_${datas[realLegendIndex].order}`, 'color']);
        datas[realLegendIndex].backgroundColor = color;
        if (isFuture) {
          datas[realLegendIndex + 1].backgroundColor = color;
        }
      }

      currentLegendItemHovering = legendIndex;
      chart.data.datasets = datas.concat(chart.data.datasets.filter((el) => el.type === 'line'));
    };

    const onMouseout = () => {
      const divLegend = document.querySelector(`#${id}`);
      if (divLegend) {
        const btnLeft = divLegend.querySelector(`#btnLeft-${id}`);
        const btnRight = divLegend.querySelector(`#btnRight-${id}`);
        if (btnLeft) {
          btnLeft.style.display = 'none';
        }
        if (btnRight) {
          btnRight.style.display = 'none';
        }
      }

      currentLegendItemHovering = null;
      datas.forEach((data) => {
        if (Array.isArray(data.backgroundColor)) {
          data.backgroundColor = data.pointHoverBackgroundColor;
        } else {
          data.backgroundColor = get(colors, [`${data.key}_${data.order}`, 'color']);
        }
      });

      chart.data.datasets = datas.concat(chart.data.datasets.filter((el) => el.type === 'line'));
    };

    const onUpdateLegendItem = () => {
      const id = props.legendDivID;
      const ul = getOrCreateLegendList(id).listContainer;

      const legendItems = ul.querySelectorAll('.legendItem') as NodeListOf<HTMLLIElement>;
      legendItems.forEach((liEl, index) => {
        const oldCheckbox = liEl.querySelector('.legendItem__checkbox') as HTMLSpanElement;
        const realIndex = datas.findIndex((data) => data.order == index);
        const isHidden = labels[index].hidden;
        const newCheckbox = createCheckBox(
          index,
          get(colors, [`${datas[realIndex]?.key}_${datas[realIndex]?.order}`, isHidden ? 'hoverBorderColor' : 'color']),
        );

        oldCheckbox.outerHTML = newCheckbox.outerHTML;
      });
    };

    return labels
      .map((label, legendIndex) => {
        if (!String(label.text as string).includes(HIDDEN_LABEL_TEXT)) {
          const realLegendIndex = datas.findIndex((data) => legendIndex == data.order);
          const li: HTMLLIElement = createLegendItem(label, datas[realLegendIndex], colors);

          //Click legend show pop-up
          li.onclick = () => {
            onToggle(legendIndex);
            onUpdateLegendItem();
            chart.update();
          };
          li.onmouseover = () => {
            if (currentLegendItemHovering === legendIndex) return;
            if (labels[legendIndex].hidden) return;

            onMouseover(legendIndex);
            chart.update();
          };
          li.onmouseout = () => {
            onMouseout();
            chart.update();
          };
          return li;
        }
      })
      .filter((li) => li);
  };

  const cacheChartColors = (() => {
    let colorsBackground: string[] = [];
    let colorsBackgroundHover: string[] = [];
    return {
      cacheColorsBackground: (chartColor: string[]) => {
        if (colorsBackground.length === 0) {
          colorsBackground = chartColor;
        }
        return colorsBackground;
      },
      cacheColorsBackgroundHover: (chartColor: string[]) => {
        if (colorsBackgroundHover.length === 0) {
          colorsBackgroundHover = chartColor;
        }
        return colorsBackgroundHover;
      },
    };
  })();

  return {
    externalTooltipHandler: externalTooltipHandler,
    htmlLegendPlugin: htmlLegendPlugin,
  };
};
