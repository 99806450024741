import * as React from 'react';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    pointerEvents: 'none',
    width: '100%',
    height: '100%',
  },
});

const EmptyFormat = () => {
  const classes = useStyles();

  return <Box className={classes.container}></Box>;
};

export default EmptyFormat;
