import * as React from 'react';

import DragCard from '@ep/insight-ui/elements/drag-n-drop';

/* Type */
type CheckboxRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
  items?: any[];
  setItems?: any;
  classes?: any;
};

const SelectBoxRender = ({ draggable, index, children, items, classes, setItems }: CheckboxRenderProps) => {
  const moveCard = (dragIndex, hoverIndex) => {
    const tmpItems = [...items];
    const dragCard = tmpItems[dragIndex];
    tmpItems.splice(dragIndex, 1);
    tmpItems.splice(hoverIndex, 0, dragCard);
    setItems(tmpItems);
  };

  if (draggable) {
    return (
      <div className={classes.box}>
        <DragCard key={index} index={index} moveCard={moveCard}>
          {children}
        </DragCard>
      </div>
    );
  }

  return <>{children}</>;
};

export default SelectBoxRender;
