export const MASS_OPERATION_DASHBOARD_DATA = [
  {
    metric: 'processing_request',
    title: 'PROCESSING REQUEST',
    value: 52,
    forwardMeaning: true,
    this_time_value: 0,
    last_time_value: 0,
    this_time_value_serries: [
      {
        date: '2022-04-20',
        value: 20,
      },
      {
        date: '2022-04-21',
        value: 60,
      },
      {
        date: '2022-04-22',
        value: 40,
      },
      {
        date: '2022-04-23',
        value: 20,
      },
      {
        date: '2022-04-24',
        value: 40,
      },
      {
        date: '2022-04-25',
        value: 55,
      },
      {
        date: '2022-04-26',
        value: 25,
      },
      {
        date: '2022-04-27',
        value: 20,
      },
    ],
    last_time_value_serries: [
      {
        date: '2022-04-13',
        value: 0,
      },
      {
        date: '2022-04-14',
        value: 40,
      },
      {
        date: '2022-04-15',
        value: 20,
      },
      {
        date: '2022-04-16',
        value: 0,
      },
      {
        date: '2022-04-17',
        value: 20,
      },
      {
        date: '2022-04-18',
        value: 35,
      },
      {
        date: '2022-04-19',
        value: 5,
      },
      {
        date: '2022-04-20',
        value: 0,
      },
    ],
  },
  {
    metric: 'processing_item',
    title: 'PROCESSING ITEM',
    value: 86399,
    forwardMeaning: false,
    this_time_value: 0,
    last_time_value: 0,
    this_time_value_serries: [
      {
        date: '2022-04-20',
        value: 20,
      },
      {
        date: '2022-04-21',
        value: 60,
      },
      {
        date: '2022-04-22',
        value: 40,
      },
      {
        date: '2022-04-23',
        value: 20,
      },
      {
        date: '2022-04-24',
        value: 40,
      },
      {
        date: '2022-04-25',
        value: 55,
      },
      {
        date: '2022-04-26',
        value: 25,
      },
      {
        date: '2022-04-27',
        value: 20,
      },
    ],
    last_time_value_serries: [
      {
        date: '2022-04-13',
        value: 0,
      },
      {
        date: '2022-04-14',
        value: 40,
      },
      {
        date: '2022-04-15',
        value: 20,
      },
      {
        date: '2022-04-16',
        value: 0,
      },
      {
        date: '2022-04-17',
        value: 20,
      },
      {
        date: '2022-04-18',
        value: 35,
      },
      {
        date: '2022-04-19',
        value: 5,
      },
      {
        date: '2022-04-20',
        value: 0,
      },
    ],
  },
  {
    metric: 'failed_request',
    title: 'FAILED REQUEST',
    value: 12,
    forwardMeaning: true,
    this_time_value: 0,
    last_time_value: 0,
    this_time_value_serries: [
      {
        date: '2022-04-20',
        value: 20,
      },
      {
        date: '2022-04-21',
        value: 60,
      },
      {
        date: '2022-04-22',
        value: 40,
      },
      {
        date: '2022-04-23',
        value: 20,
      },
      {
        date: '2022-04-24',
        value: 40,
      },
      {
        date: '2022-04-25',
        value: 55,
      },
      {
        date: '2022-04-26',
        value: 25,
      },
      {
        date: '2022-04-27',
        value: 20,
      },
    ],
    last_time_value_serries: [
      {
        date: '2022-04-13',
        value: 0,
      },
      {
        date: '2022-04-14',
        value: 40,
      },
      {
        date: '2022-04-15',
        value: 20,
      },
      {
        date: '2022-04-16',
        value: 0,
      },
      {
        date: '2022-04-17',
        value: 20,
      },
      {
        date: '2022-04-18',
        value: 35,
      },
      {
        date: '2022-04-19',
        value: 5,
      },
      {
        date: '2022-04-20',
        value: 0,
      },
    ],
  },
  {
    metric: 'failed_item',
    title: 'FAILED ITEM',
    value: 300,
    forwardMeaning: true,
    this_time_value: 0,
    last_time_value: 0,
    this_time_value_serries: [
      {
        date: '2022-04-20',
        value: 20,
      },
      {
        date: '2022-04-21',
        value: 60,
      },
      {
        date: '2022-04-22',
        value: 40,
      },
      {
        date: '2022-04-23',
        value: 20,
      },
      {
        date: '2022-04-24',
        value: 40,
      },
      {
        date: '2022-04-25',
        value: 55,
      },
      {
        date: '2022-04-26',
        value: 25,
      },
      {
        date: '2022-04-27',
        value: 20,
      },
    ],
    last_time_value_serries: [
      {
        date: '2022-04-13',
        value: 0,
      },
      {
        date: '2022-04-14',
        value: 40,
      },
      {
        date: '2022-04-15',
        value: 20,
      },
      {
        date: '2022-04-16',
        value: 0,
      },
      {
        date: '2022-04-17',
        value: 20,
      },
      {
        date: '2022-04-18',
        value: 35,
      },
      {
        date: '2022-04-19',
        value: 5,
      },
      {
        date: '2022-04-20',
        value: 0,
      },
    ],
  },
];
