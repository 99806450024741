import { useLog } from '../log';

const log = useLog('lib:main:util');

export function coolDownInterval(asyncFun, startDuration) {
  const logErr = log.extend('error');

  let multiplier = 1;
  let tid = 0;
  function repeatIt(duration) {
    asyncFun()
      .then(() => {
        multiplier = 1; // reset mutliplier
        tid = window.setTimeout(() => repeatIt(duration), duration);
      })
      .catch((error) => {
        logErr('coolDownInternval', error);
        multiplier = Math.min(multiplier * 2, 1024);
        tid = window.setTimeout(() => repeatIt(duration), multiplier * duration);
      });
  }

  repeatIt(startDuration);

  return () => {
    window.clearTimeout(tid);
  };
}

export function retryTillSuccess(asyncFun, startDuration, logError?: (error) => void): [Promise<any>, () => void] {
  const logErr = log.extend('error');

  let multiplier = 1;
  let tid = null;
  function repeatIt(duration) {
    return asyncFun()
      .then((result) => {
        multiplier = 1; // reset mutliplier
        return result;
      })
      .catch((error) => {
        logErr('retryInterval', multiplier * duration, error);
        if (typeof logError === 'function') logError(error);
        return new Promise((resolve) => {
          tid = window.setTimeout(() => resolve(repeatIt(duration)), multiplier * duration);
          multiplier = Math.min(multiplier * 2, 1024);
        });
      });
  }

  return [repeatIt(startDuration), () => clearTimeout(tid)];
}

const MOP_DATA_TYPE_SHORTCUT = {
  s: 'string',
  i: 'integer',
  f: 'float',
  d: 'date',
  dt: 'datetime',
};

export function mopQueryParamsToFilter(conditionKV: Record<string, any>) {
  return Object.keys(conditionKV).map((fieldName) => {
    const [dataType, operator, value] = String(conditionKV[fieldName]).split(';');
    return {
      field: fieldName,
      dataType: MOP_DATA_TYPE_SHORTCUT[dataType],
      operator,
      value,
    };
  });
}

export function mopFilterToQueryParams(filters: MopFilterType[]) {
  const queryParams = filters.reduce((carry, filter) => {
    const op = Object.entries(MOP_DATA_TYPE_SHORTCUT).find((i) => i[1] === filter.dataType)[0];
    return {
      ...carry,
      [filter.field]: [op, filter.operator, filter.value].join(';'),
    };
  }, {});

  return queryParams;
}

const availableAggFunc: Record<string, AggFuncType> = {
  max: {
    id: 'max',
    text: 'Max',
    requestQuery: 'max',
    requestQueryPointer: null,
  },

  avg: {
    id: 'avg',
    text: 'Average',
    requestQuery: 'avg',
    requestQueryPointer: null,
  },

  unique: {
    id: 'unique',
    text: 'Unique',
    requestQuery: 'count(distinct(${__fieldname__}))',
    requestQueryPointer: null,
  },

  sum: {
    id: 'sum',
    text: 'Sum',
    requestQuery: 'sum',
    requestQueryPointer: null,
  },

  count: {
    id: 'count',
    text: 'Count',
    requestQuery: 'count',
    requestQueryPointer: null,
  },
};

export function getAvailableAggFunc(funcId) {
  return availableAggFunc[funcId];
}

export function getDefaultAggFunc(type: 'groupTarget' | 'dimension' | 'attribute' | 'metric'): AggFuncType {
  switch (type) {
    case 'groupTarget':
      return getAvailableAggFunc('max');

    case 'dimension':
      return getAvailableAggFunc('unique');

    case 'attribute':
      return getAvailableAggFunc('count');

    case 'metric':
      return getAvailableAggFunc('avg');
  }
}
