import { Checkbox, CheckboxProps, Grid, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { debounce, get } from 'lodash';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

/**
 * ff.tooltips_toko_shop_ads:start
 */
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { GridApi } from '@ag-grid-community/core';
import ConditionalWrap from '../../util/conditional-wrap';
import StyledTooltip from '../../tooltip/styled';
/**
 * ff.tooltips_toko_shop_ads:end
 */

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: '12px',
    height: '12px',
    boxShadow: 'inset 0 0 0 2px #C2C7CB, inset 0 -1px 0 #C2C7CB',

    background: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '4px auto rgba(19,124,189,.6)',
      outlineOffset: 4,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
      boxShadow: 'inset 0 0 0 2px #338ED8, inset 0 -1px 0 #338ED8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      display: 'block',
      width: '12px',
      height: '12px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  indeterminate: {
    position: 'relative',
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'block',
      width: '8px',
      height: '2px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 2'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M7 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 " +
        "2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0Z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
});

interface ICustomCheckboxProps extends CheckboxProps {
  state: 'default' | 'checked' | 'indeterminate';
}

function StyledCheckbox({ state, ...props }: ICustomCheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={clsx(classes.icon, state === 'indeterminate' && classes.indeterminate)} />}
      {...props}
    />
  );
}
export interface IValueCellSelectionFormat {
  checked: boolean;
  disabled?: boolean;
}
type CheckboxState = 'default' | 'checked' | 'indeterminate';
const CellSelectionFormat = (props: IPropsFormat<IValueCellSelectionFormat>) => {
  const maxItemSelected = props.maxItemSelected;
  // const { disabled = false, checked = false } = value;
  if (props.node.isRowPinned()) return null;
  const backbone = React.useContext(TableBackboneContext);
  const value = get(props, 'value', null);
  const disabled = get(value, 'disabled', false);
  // const checkboxState = get(props, 'checkboxState', null);
  const gridApi: GridApi = get(props, 'griApi', get(props, 'api', null));
  // const setCheckboxState = get(props, 'setCheckboxState', () => null);
  const [checkboxState, setCheckboxState] = React.useState<CheckboxState>(() => {
    const id = get(props, ['data', '_eipCustomId']);
    const selectedRows = backbone.getSelectedRows();
    const isCompactETable = backbone.getConfig('tableType', '') == 'compact';
    const primaryKeys = backbone.getConfig('primaryKeys', []);
    if (selectedRows.length === 0) return 'default';
    if (!props.node.group || !props.node.expanded) {
      const isSelected = selectedRows.some((row) => {
        if (!isCompactETable) return get(row, ['_eipCustomId'], '') == id;
        return primaryKeys.every((key) => get(row, [key]) == get(props, ['data', key]));
      });
      return isSelected ? 'checked' : 'default';
    }

    let childrenCount = 0;
    let childrenSelected = 0;
    gridApi.forEachNode((node) => {
      if (node.parent?.id == props.node.id && !node.id?.includes('cloneforloadmore')) {
        childrenCount++;
        if (node.selected) {
          childrenSelected++;
        }
      }
    });
    if (childrenCount > 0) {
      return childrenCount == childrenSelected ? 'checked' : childrenSelected > 0 ? 'indeterminate' : 'default';
    }
    return 'default';
  });

  const loopSelect = (node, isChecked) => {
    gridApi.forEachNode((node2) => {
      if (node2.group && node2.expanded && node2.parent?.id == node.id) {
        loopSelect(node2, isChecked);
      } else if (node2.parent?.id == node.id && !node2.id?.includes('cloneforloadmore')) {
        node2.setSelected(isChecked);
      }
    });
  };

  const click = (e) => {
    const isChecked = e.target.checked;
    if (maxItemSelected && backbone.getSelectedRows().length >= maxItemSelected && isChecked) return;
    const parentNode = props.node.parent;
    if (props.node.group && props.node.expanded) {
      loopSelect(props.node, isChecked);
      return;
    }

    setCheckboxState(isChecked ? 'checked' : 'default');
    props.node.setSelected(isChecked);

    if (parentNode?.group && parentNode?.expanded) {
      let childrenCount = 0;
      let childrenSelected = 0;
      gridApi.forEachNode((node) => {
        if (node.parent?.id == parentNode.id && !node.id?.includes('cloneforloadmore')) {
          childrenCount++;
          if (node.selected) {
            childrenSelected++;
          }
        }
      });
      if (childrenCount > 0) {
        parentNode.setSelected(childrenCount == childrenSelected);
      }
    }

    return;
  };

  React.useEffect(() => {
    const handleChangeSelection = debounce((...args) => {
      if (props.node.id?.includes('cloneforloadmore')) return;
      if (!props.node.group || (props.node.group && !props.node.expanded)) {
        setCheckboxState(props.node.selected ? 'checked' : 'default');
      } else if (props.node.group && props.node.expanded) {
        let childrenCount = 0;
        let childrenSelected = 0;
        gridApi.forEachNode((node) => {
          if (node.parent?.id == props.node.id && !node.id?.includes('cloneforloadmore')) {
            childrenCount++;
            if (node.selected) {
              childrenSelected++;
            }
          }
        });
        if (childrenCount > 0) {
          setCheckboxState(
            childrenCount == childrenSelected ? 'checked' : childrenSelected > 0 ? 'indeterminate' : 'default',
          );
          props.node.setSelected(childrenCount == childrenSelected);
        }
      }
    }, 70);

    if (gridApi) {
      gridApi.addEventListener('selectionChanged', handleChangeSelection);

      return () => {
        gridApi.removeEventListener('selectionChanged', handleChangeSelection);
      };
    }
  }, [gridApi]);

  return (
    <Grid
      container
      justifyContent="center"
      alignItems={'center'}
      style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: '6px' }}
    >
      <ConditionalWrap
        condition={maxItemSelected != null}
        wrap={(children) => {
          return <StyledTooltip title={`Maximum ${maxItemSelected} selected row(s)`}>{children}</StyledTooltip>;
        }}
      >
        <div>
          <StyledCheckbox
            disabled={disabled}
            checked={checkboxState === 'checked'}
            onChange={click}
            state={checkboxState}
          />
        </div>
      </ConditionalWrap>
    </Grid>
  );
};
export default CellSelectionFormat;
