import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getActionMenu } from './common';

export const CAMPAIGN_DETAIL_INIT_CONFIG = {
  title: 'Campaign detail',
  tableId: 'campaign_detail_init',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
  },
  primaryKeys: ['MARKETPLACE.marketplace_code', 'STOREFRONT.id', 'ADTYPE.ads_type', 'ADTOOL.ads_tool'],
  viewSetting: [
    {
      id: 'all',
      name: 'All',
      excludeProperty: {
        dimension: ['storefront_ad_object'],
        metric: [],
        attribute: [],
      },
    },
  ],
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  mapping: {
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
      },
      tableConfiguration: null,
    },
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.position_updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
  },
};
