import { cloneDeep, get, uniq } from 'lodash';
import * as React from 'react';

import {
  checkEpsiloTableEndpoint,
  default as dataRequest,
  EpsiloTableObject,
} from '@ep/insight-ui/system/backbone/data-source/common';
import { CellFormatDataType, CellFormatType } from '@ep/insight-ui/system/block/etable/cell-format/cell-format-type';
import { EtableConfigContext } from '../context';

enum SpecialCellFormat {
  INSIGHT_DIMENSION = 'INSIGHT_DIMENSION',
  INSIGHT_METRIC = 'INSIGHT_METRIC',
  INSIGHT_BOTH = 'INSIGHT_BOTH',
  OPERATION_DIMENSION = 'OPERATION_DIMENSION',
  OPERATION_METRIC = 'OPERATION_METRIC',
  OPERATION_BOTH = 'OPERATION_BOTH',
  QUALITY_DIMENSION = 'QUALITY_DIMENSION',
  QUALITY_METRIC = 'QUALITY_METRIC',
  QUALITY_BOTH = 'QUALITY_BOTH',
  ONE_DIMENSION = 'ONE_DIMENSION',
  ONE_METRIC = 'ONE_METRIC',
  ONE_BOTH = 'ONE_BOTH',
  ELASTICITY = 'ELASTICITY',
}

const specialCompactOptionsDefault = {
  valueGetter: {},
  staticValue: {
    storefrontMetricTraction: {
      selectedMetrics: null,
    },
  },
};

export const useCompactEtableConfig = ({ onSubmit, props, mappingConfig }) => {
  const [gridApi, setGridApi] = React.useState(null);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [specialCompactOptions, setSpecialCompactOptions] = React.useState(specialCompactOptionsDefault);

  const etableConfig = React.useContext(EtableConfigContext);
  const [columnsOption, setColumnsOptions] = React.useState([]);

  const [specialCellFormatOptions, setSpecialCellFormatOptions] = React.useState<
    Array<{ label: string; value: string; label_raw?: string; propertyType?: string }>
  >([]);
  const endpoint = get(etableConfig, 'endpoint.GET_TABLE_DATA', '');

  const namespace = React.useMemo(() => {
    const endpointQueryParams = new URLSearchParams(endpoint.split('?')[1] || '');
    const params = Object.fromEntries(endpointQueryParams.entries());
    return get(params, 'namespace', '');
  }, [endpoint]);

  const specialCellFormat: SpecialCellFormat = React.useMemo(() => {
    const cellFormat = get(props, 'data.cellFormat', '');

    if (cellFormat === 'filterColumnFormat') {
      const propertyType = get(props, 'data.propertyType', '');
      const isDimension = ['dimension', 'attribute'].includes(propertyType);
      const isMetric = propertyType === 'metric';

      if (isDimension) {
        if (namespace) return SpecialCellFormat.ONE_DIMENSION;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION))
          return SpecialCellFormat.OPERATION_DIMENSION;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE))
          return SpecialCellFormat.INSIGHT_DIMENSION;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) return SpecialCellFormat.QUALITY_DIMENSION;
      }
      if (isMetric) {
        if (namespace) return SpecialCellFormat.ONE_METRIC;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) return SpecialCellFormat.OPERATION_METRIC;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) return SpecialCellFormat.INSIGHT_METRIC;
        if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) return SpecialCellFormat.QUALITY_METRIC;
      }
    }

    const namespaceMetricFormat = ['oneDimension', 'tractionElasticity', 'oneMetric'];
    if (namespace && namespaceMetricFormat.includes(cellFormat)) {
      return SpecialCellFormat.ONE_METRIC;
    }

    const insightBothCellFormat = ['dateTimeMetric', 'rgbStatusFormat'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE) &&
      insightBothCellFormat.includes(cellFormat)
    ) {
      return SpecialCellFormat.INSIGHT_BOTH;
    }

    const performanceBothCellFormat = ['dateTimeMetric', 'rgbStatusFormat'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION) &&
      performanceBothCellFormat.includes(cellFormat)
    ) {
      return SpecialCellFormat.OPERATION_BOTH;
    }

    const qualityBothCellFormat = ['dateTimeMetric', 'rgbStatusFormat'];
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY) && qualityBothCellFormat.includes(cellFormat)) {
      return SpecialCellFormat.QUALITY_BOTH;
    }

    const insightDimensionCellFormat = ['insightDimension'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE) &&
      insightDimensionCellFormat.includes(cellFormat)
    )
      return SpecialCellFormat.INSIGHT_DIMENSION;

    const insightMetricCellFormat = ['metricTraction'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE) &&
      insightMetricCellFormat.includes(cellFormat)
    )
      return SpecialCellFormat.INSIGHT_METRIC;

    const performanceDimensionCellFormat = ['operationDimension'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION) &&
      performanceDimensionCellFormat.includes(cellFormat)
    )
      return SpecialCellFormat.OPERATION_DIMENSION;

    const performanceMetricCellFormat = ['operationMetric'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION) &&
      performanceMetricCellFormat.includes(cellFormat)
    )
      return SpecialCellFormat.OPERATION_METRIC;

    const qualityDimensionCellFormat = ['qualityDimension'];
    if (
      checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY) &&
      qualityDimensionCellFormat.includes(cellFormat)
    )
      return SpecialCellFormat.QUALITY_DIMENSION;

    const qualityMetricCellFormat = ['qualityMetric'];
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY) && qualityMetricCellFormat.includes(cellFormat))
      return SpecialCellFormat.QUALITY_METRIC;

    return null;
  }, [props, etableConfig]);

  const getMetricDefinition = (endpoint) => {
    if (namespace) {
      return dataRequest.getOneMetricOptions(namespace);
    }
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
      return dataRequest.getPerformanceMetrics();
    }
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
      return dataRequest.getOperationMetrics();
    }
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
      return dataRequest.getEpsiloMetric(EpsiloTableObject.QUALITY);
    }
    return Promise.resolve(null);
  };

  React.useEffect(() => {
    const endpoint = get(etableConfig, 'endpoint.GET_TABLE_DATA', '');
    const cellFormat = get(props, 'data.cellFormat', '');

    if (cellFormat === 'storefrontMetricTraction') {
      getMetricDefinition(endpoint).then((res) => {
        setSpecialCompactOptions({
          ...specialCompactOptions,
          staticValue: {
            ...specialCompactOptions.staticValue,
            storefrontMetricTraction: {
              ...specialCompactOptions.staticValue.storefrontMetricTraction,
              selectedMetrics: res,
            },
          },
        });
      });
    }
  }, [props, etableConfig]);

  React.useEffect(() => {
    switch (specialCellFormat) {
      case SpecialCellFormat.ONE_METRIC:
      case SpecialCellFormat.ONE_DIMENSION:
        Promise.all([dataRequest.getOneDimensionOptions(namespace), dataRequest.getOneMetricOptions(namespace)]).then(
          ([attributes, metrics]) => {
            setSpecialCellFormatOptions([].concat(attributes, metrics));
          },
        );
        break;

      case SpecialCellFormat.INSIGHT_BOTH:
        Promise.all([dataRequest.getPerformanceAttributes(), dataRequest.getPerformanceMetrics()]).then(
          ([attributes, metrics]) => {
            setSpecialCellFormatOptions(
              [].concat(
                attributes.map((el) => ({
                  ...el,
                  propertyType: 'dimension',
                })),
                metrics.map((el) => ({
                  ...el,
                  propertyType: 'metric',
                })),
              ),
            );
          },
        );
        break;
      case SpecialCellFormat.OPERATION_BOTH:
        Promise.all([dataRequest.getOperationAttributes(), dataRequest.getOperationMetrics()]).then(
          ([attributes, metrics]) => {
            setSpecialCellFormatOptions(
              [].concat(
                attributes.map((el) => ({
                  ...el,
                  propertyType: 'dimension',
                })),
                metrics.map((el) => ({
                  ...el,
                  propertyType: 'metric',
                })),
              ),
            );
          },
        );
        break;
      case SpecialCellFormat.QUALITY_BOTH:
        Promise.all([
          dataRequest.getEpsiloAttributes(EpsiloTableObject.QUALITY),
          dataRequest.getEpsiloMetric(EpsiloTableObject.QUALITY),
        ]).then(([attributes, metrics]) => {
          setSpecialCellFormatOptions(
            [].concat(
              attributes.map((el) => ({
                ...el,
                propertyType: 'dimension',
              })),
              metrics.map((el) => ({
                ...el,
                propertyType: 'metric',
              })),
            ),
          );
        });
        break;
      case SpecialCellFormat.INSIGHT_DIMENSION:
        dataRequest.getPerformanceAttributes().then((res) => setSpecialCellFormatOptions(res));
        break;
      case SpecialCellFormat.INSIGHT_METRIC:
        dataRequest.getPerformanceMetrics().then((res) => setSpecialCellFormatOptions(res));
        break;
      case SpecialCellFormat.OPERATION_DIMENSION:
        dataRequest.getOperationAttributes().then((res) => setSpecialCellFormatOptions(res));
        break;
      case SpecialCellFormat.OPERATION_METRIC:
        dataRequest.getOperationMetrics().then((res) => setSpecialCellFormatOptions(res));
        break;
      case SpecialCellFormat.QUALITY_DIMENSION:
        dataRequest.getEpsiloAttributes(EpsiloTableObject.QUALITY).then((res) => setSpecialCellFormatOptions(res));
        break;
      case SpecialCellFormat.QUALITY_METRIC:
        dataRequest.getEpsiloMetric(EpsiloTableObject.QUALITY).then((res) => setSpecialCellFormatOptions(res));
        break;
      default:
        setSpecialCellFormatOptions([]);
    }
  }, [specialCellFormat, endpoint]);

  const compactConfig = get(props, 'config', {});
  const isSelectType = compactConfig.type === CellFormatType.SELECT;

  React.useEffect(() => {
    if (isSelectType && gridApi) {
      gridApi.forEachNode((node) => {
        if (props.value.includes(node.data.value)) {
          node.setSelected(true);
        }
      });
    }
  }, [gridApi]);

  React.useEffect(() => {
    const metricLists = [];
    uniq(
      Object.values(mappingConfig).forEach((el) => {
        const b = get(el, 'valueGetter', {});
        Object.values(b).forEach((ele) => metricLists.push(ele));
      }),
    );
    const mt = metricLists.map((el) => {
      return {
        value: el,
        label: el,
      };
    });
    setColumnsOptions(mt);
  }, [mappingConfig]);

  const tableDOM = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const handleRowChange = (api) => {
    setIsEmpty(api.getDisplayedRowCount() === 0);
  };

  const actionButtons = [
    {
      id: 'delete',
      title: 'Delete selected rows',
      onClick() {
        gridApi.applyTransaction({
          remove: gridApi.getSelectedRows(),
        });
      },
    },
    {
      id: 'add_row',
      title: props.addRowLabel ? props.addRowLabel : 'Add property',
      onClick() {
        gridApi.applyTransaction({
          add: compactConfig.getDefaultRowData
            ? [compactConfig.getDefaultRowData()]
            : [
                {
                  key: '',
                  value: '',
                },
              ],
          addIndex: compactConfig.addNewFromIndex ? compactConfig.addNewFromIndex : 0,
        });
      },
    },
  ];

  const enhanceActionButtons = React.useMemo(() => {
    const actions = [...(compactConfig.hideDefaultActions ? [] : actionButtons)].concat(
      (props.buttons || []).map((el) => ({
        ...el,
        onClick: (event) => el.onClick({ gridApi, event }),
      })),
    );
    return actions;
  }, [actionButtons, props]);

  const handleSubmit = () => {
    if (isSelectType) {
      let data = gridApi.getSelectedRows();
      if (compactConfig.dataType === CellFormatDataType.ARRAY) {
        data = data.map((el) => el.value);
      }
      onSubmit(data);
    } else if (props.isCustom) {
      const data = [];
      gridApi.forEachNode((node) => {
        data.push(node.data);
      });
      onSubmit(data);
    } else {
      const data = {};
      gridApi.forEachNode((node) => {
        data[node.data.key] = node.data.value;
      });
      onSubmit(data);
      if (get(props, 'colDef.field', '') === 'valueGetter') {
        if (specialCellFormat) {
          const cellFormatOption = specialCellFormatOptions.reduce((a, b) => {
            if (b.value === data['label']) a['label'] = b.label_raw;
            if (b.value === data['value']) a['value'] = b.label_raw;
            if (b.value === data['id']) a['id'] = b.label_raw;
            return a;
          }, {});
          const title = cellFormatOption['label'] || cellFormatOption['value'] || cellFormatOption['id'];
          const currentTitle = get(props, ['data', 'title'], '');
          const specialTitle = ['Storefront', 'Country', 'Marketplace']; // Do not modify this title
          if (!specialTitle.includes(currentTitle) && title) {
            // onSubmit(title, 'title');
          }
          if (['dateTimeMetric', 'rgbStatusFormat'].includes(get(props, 'data.cellFormat', ''))) {
            const matchData = specialCellFormatOptions.find((el) => el.value === data.value);
            if (matchData) {
              onSubmit(matchData.propertyType, 'propertyType');
            }
          }
        }
        // auto fill filter field and sort field according to valueGetter->value
        const mainField = data['value'] || data['id'] || data['label'];
        if (!get(props, ['data', 'filterField'], null)) {
          onSubmit(mainField, 'filterField');
        }
        if (!get(props, ['data', 'sortField'], null)) {
          onSubmit(mainField, 'sortField');
        }
      }
    }
    handleClose();
  };

  return {
    isEmpty,
    tableDOM,
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleRowChange,
    actionButtons: enhanceActionButtons,
    handleSubmit,
    gridApi,
    setGridApi,
    isSelectType,
    compactConfig,
    specialCellFormat,
    specialCellFormatOptions,
    specialCompactOptions,
    columnsOption,
  };
};
