import * as React from 'react';
import { cloneDeep, debounce, get, merge, set } from 'lodash';

import { useTableBackbone } from '../../backbone/table-backbone/next-table-backbone';
import { getFinalConfig } from '@ep/insight-ui/system/block/etable/etable-config/action-components/inline-edit/form-field/utils/config';
import {
  datetimeSelectionAdjustment,
  lockedViewAdjustment,
  migrateRemovedColumns,
  migrateSingleSpecialFilters,
  migrateSpecialFilters,
  popuplateConfigWithCurrentView,
  produceValidConfig,
} from '../../block/etable/migration';
import { DEFAULT_CONFIG } from '../../block/etable/table-config';
import {
  checkEpsiloTableEndpoint,
  EpsiloTableObject,
  getMetricDefinition,
  getQueryParams,
} from '@ep/insight-ui/sw/etable/data/common';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from '../../block/etable/cell-format-options';
import { cellFormat } from '@ep/insight-ui/elements/etable2/table-helper';
import { EIP_CONSTANT, eipRequest, NodeEditContext, useLog } from '@eip/next/lib/main';
import { enhanceDataRequest2 } from '@ep/insight-ui/sw/etable/data/enhancer';
import { splitComma } from '../../helper/functions';

const defaultConfiguration = DEFAULT_CONFIG;

const isValidIndexData = (config) => {
  return checkEpsiloTableEndpoint(get(config, 'endpoint.GET_TABLE_DATA', ''), [
    EpsiloTableObject.PERFORMANCE,
    EpsiloTableObject.QUALITY,
    EpsiloTableObject.OPERATION,
  ]);
};

const cached = {};

const nextCellFormat = {
  ...CELL_FORMAT_OPTIONS_WITH_COMPONENT,
  ...cellFormat,
  ...['qualityMetric', 'oneMetric', 'operationMetric'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.currencyFormatFormula };
  }, {}),
  ...['oneDimension'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.textFormatFormula };
  }, {}),
};

enum SELECTED_FILTER_TYPE {
  IN = 'IN',
  IS_NOT = 'is_not',
}

export const useGetTableData = ({ etableConfig, customConfig = {} }) => {
  let validConfig = etableConfig;
  if (validConfig && validConfig.mapping) {
    validConfig = produceValidConfig(etableConfig);
    validConfig = lockedViewAdjustment(validConfig, {});
    validConfig = migrateSpecialFilters(validConfig);
    validConfig = datetimeSelectionAdjustment(validConfig);
    validConfig = popuplateConfigWithCurrentView(validConfig);
    validConfig = migrateSingleSpecialFilters(validConfig, {});
    validConfig = migrateRemovedColumns(validConfig);
    validConfig = merge(validConfig, customConfig);
  }

  const searchInputRef = React.useRef('');
  const searchFieldIdRef = React.useRef('');
  const selectedItemsRef = React.useRef([]);
  const selectedFilterType = React.useRef<SELECTED_FILTER_TYPE>(SELECTED_FILTER_TYPE.IS_NOT);

  const convertValueToIsNotFilters = (value, field) => {
    return {
      dataType: 'string',
      field,
      operator: SELECTED_FILTER_TYPE.IS_NOT,
      value,
    };
  };

  const config: {
    apiRequest: Record<string, any>;
    configuration: Record<string, any>;
    addons?: Record<string, (...args: any[]) => any>;
    callback?: Record<string, any>;
    cellFormat?: Record<string, any>;
  } = {
    apiRequest: {},
    configuration: {
      ...defaultConfiguration,
      ...validConfig,
      defaultPagination: {
        limit: 10,
        page: 1,
        total: 0,
        ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
      },
    },
    callback: {
      onBackboneReady: (backbone, config) => {
        const addons = get(config, 'addons', {});

        Object.keys(addons).forEach((key) => {
          if (key.startsWith('initial.request.')) {
            backbone
              .addon(key, () => Promise.resolve({ data: null }))()
              .then((res) => {
                backbone.addInitialDataRequest(key.replace('initial.request.', ''), res.data);
              });
          }
        });

        const endpointTable = get(backbone, 'config.endpoint.GET_TABLE_DATA');
        // Add Metric Options for button Properties
        const mappingConfig = get(backbone, 'config.mapping', {});
        const isStorefrontMetricTraction = Object.values(mappingConfig).find(
          (ele) => ele?.cellFormat === 'storefrontMetricTraction',
        );
        const metric = get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []);
        const metricTractionProperties = backbone.getConfig('metricTractionProperties', []);

        const isDiff =
          metric.length !== metricTractionProperties.length ||
          (metric.length === metricTractionProperties.length &&
            metricTractionProperties.some(({ id }) => !metric.includes(id)));

        if (isStorefrontMetricTraction && isDiff) {
          getMetricDefinition(endpointTable).then((res) => {
            if (res) {
              const metricProperties = metric.map((el) => {
                const metricDef = res.filter((mt) => mt.value === el)[0];
                return {
                  id: el,
                  name: get(metricDef, 'label_raw', el),
                  disable: false,
                };
              });
              backbone.changeConfig('metricTractionProperties', metricProperties);
            }
          });
        }
      },
    },
    cellFormat: nextCellFormat,
  };

  config.addons = {
    'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
      if (!params) {
        return Promise.reject({
          success: false,
          message: 'Params not found',
        });
      }
      const endpoint = get(backbone, ['config', 'endpoint', 'GET_TABLE_DATA'], '');
      if (checkEpsiloTableEndpoint(endpoint)) {
        return eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, {
          ...params,
          '@eTableConfig': { ...backbone.config, tableId: etableConfig.blockEid },
        });
      } else {
        return enhanceDataRequest2(params, originRequest, backbone);
      }
    },
    'datasource.getRowsParams': ({ params }, _, backbone) => {
      if (!get(backbone, ['config', 'blockEid'], '')) {
        return null;
      }
      const prevParams = cloneDeep(params);
      const specialFilters = backbone.getConfig('specialFilters') || {};
      const mapping = backbone.getConfig('mapping', {});
      const searchInput = searchInputRef.current;
      const searchField = get(backbone, ['config', 'searchField'], '');
      const searchValueField = get(backbone, ['config', 'searchValueField'], '');
      const paginationLimit = get(backbone, ['config', 'pagination', 'limit'], 100);

      set(prevParams, ['pagination', 'limit'], Number(paginationLimit));

      const endpoint = get(backbone, ['config', 'endpoint', 'GET_TABLE_DATA'], '');

      if (checkEpsiloTableEndpoint(endpoint)) {
        prevParams.hiddenFilter = {
          ...prevParams.hiddenFilter,
          currency: 'USD',
        };
        prevParams.currency = get(prevParams, 'hiddenFilter.currency', 'USD');
      }

      if (searchField && searchInput && selectedFilterType.current === SELECTED_FILTER_TYPE.IS_NOT) {
        prevParams.filter = {
          ...prevParams.filter,
          combinator: prevParams.filter?.combinator || 'and',
          filters: [
            ...(prevParams.filter?.filters || []),
            ...(splitComma(searchField).length > 1
              ? [
                  {
                    combinator: 'or',
                    filters: searchField.split(',').map((i) => {
                      return {
                        dataType: 'string',
                        field: i.trim(),
                        operator: 'contains',
                        value: String(searchInput).normalize('NFKD'),
                      };
                    }),
                  },
                ]
              : [
                  {
                    dataType: 'string',
                    field: searchField,
                    operator: 'contains',
                    value: String(searchInput).normalize('NFKD'),
                  },
                ]),
          ],
        };
      }

      if (searchField) {
        const relevantSort = searchInput ? [{ field: `LENGTH({${searchField.split(',')[0]}})`, sort: 'ASC' }] : [];

        prevParams.sort = Array.isArray(prevParams.sort) ? [...prevParams.sort, ...relevantSort] : relevantSort;
      }

      if (Array.isArray(selectedItemsRef.current) && selectedItemsRef.current.length) {
        let additionalFilters = [];
        if (selectedFilterType.current === SELECTED_FILTER_TYPE.IN) {
          additionalFilters = [
            {
              dataType: 'string',
              field: searchValueField,
              operator: SELECTED_FILTER_TYPE.IN,
              value: selectedItemsRef.current,
            },
          ];
        } else {
          additionalFilters = selectedItemsRef.current.map((value) =>
            convertValueToIsNotFilters(value, searchValueField),
          );
        }

        prevParams.filter = {
          ...prevParams.filter,
          combinator: prevParams.filter?.combinator || 'and',
          filters: [...(prevParams.filter?.filters || []), ...additionalFilters],
        };
      }

      const formattedSpecialFilters = Object.entries(specialFilters)
        .filter(([field]) => get(backbone, ['config', 'system', 'externalFilters'], []).includes(field))
        .map(([k, filter]) => {
          const tableField = mapping[k];
          if (!tableField) return null;
          return {
            dataType: filter.dataType,
            field: tableField.filterField,
            operator: filter.queryType,
            value: filter.queryValue,
          };
        })
        .filter((el) => el);

      if (formattedSpecialFilters.length > 0) {
        return {
          ...prevParams,
          filter: {
            ...prevParams.filter,
            combinator: prevParams.filter?.combinator || 'and',
            filters: [...(prevParams.filter?.filters || []), ...formattedSpecialFilters],
          },
        };
      }

      return { ...prevParams };
    },
    'system.groupby.aggFunc': (key) => {
      const aggFuncList = [
        'NONE',
        'UNIQUE',
        'SUM',
        'AVG',
        'MIN',
        'MAX',
        'COUNT_ALL',
        'COUNT_VALUES',
        'COUNT_UNIQUE',
        'COUNT_EMPTY',
        'COUNT_NOT_EMPTY',
        'PERCENT_EMPTY',
        'PERCENT_NOT_EMPTY',
        'RANGE',
      ].map((el) => {
        let fieldId = el;
        if (el === 'NONE') fieldId = 'NULL';
        if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
        return {
          id: el.toLowerCase(),
          requestQuery: fieldId,
        };
      });

      const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce((carry, i) => ({ ...carry, [i.id]: i }), {});

      return aggFuncByKey[key]
        ? aggFuncByKey[key]
        : {
            id: key,
            requestQuery: key.toUpperCase(),
          };
    },
    'system.get.sort.available.column': (availableSortColumns, backbone) => {
      const availableSortColumnKeys = get(backbone, ['config', 'availableSortColumnKeys'], []) || [];
      if (!availableSortColumnKeys.length) return availableSortColumns;
      return availableSortColumns.filter((column) => {
        return availableSortColumnKeys.includes(column.id);
      });
    },
  };

  const backbone = useTableBackbone(config);

  const blockEid = etableConfig.blockEid;
  const bbBlockEid = get(backbone, ['config', 'blockEid'], '');

  React.useEffect(() => {
    if (blockEid && bbBlockEid === blockEid) {
      backbone.init();
    }
  }, [blockEid, bbBlockEid]);

  const getTableData = async (forceReload = false) => {
    const searchInput = searchInputRef.current;
    const searchField = searchFieldIdRef.current;
    const sort = (get(backbone, ['config', 'sort'], []) || []).map((el) => `${el.field}+${el.sort}`).join('.');
    const viewId = get(backbone, ['config', 'view', 'id'], '');
    const isSingle = !Array.isArray(selectedItemsRef.current);
    const selectedValues = isSingle ? '' : get(selectedItemsRef, ['current'], []).join('+');
    const cacheKey = [blockEid, viewId, searchField, searchInput, selectedValues, sort].join('_');

    let selectedDataResult = [];
    if (forceReload && !isSingle && selectedItemsRef.current.length) {
      selectedFilterType.current = SELECTED_FILTER_TYPE.IN;
      try {
        selectedDataResult = await backbone.getRows();
      } catch {}
    }

    selectedFilterType.current = SELECTED_FILTER_TYPE.IS_NOT;

    if (!cached[cacheKey] || forceReload) {
      try {
        const response = await backbone.getRows();
        cached[cacheKey] = {
          data: response,
          searchInput,
          success: true,
          lastUpdated: new Date().getTime(),
          searchField,
        };
      } catch (e) {
        if (cached[cacheKey])
          return {
            ...cached[cacheKey],
            data: selectedDataResult.concat(cached[cacheKey]?.data || []),
            isCached: true,
          };
        return {
          data: selectedDataResult,
          searchInput,
          message: e?.message,
          success: false,
          lastUpdated: new Date().getTime(),
          searchField,
        };
      }
    }

    return {
      ...cached[cacheKey],
      data: selectedDataResult.concat(cached[cacheKey]?.data || []),
    };
  };

  return {
    getTableData,
    backbone,
    searchInputRef,
    selectedItemsRef,
    searchFieldIdRef,
  };
};
