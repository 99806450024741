import ChartEditor from '@ep/insight-ui/elements/chart-editor';
import {
  columnStacked,
  optionsCohorts,
  optionsColor,
  optionsColumnColor,
  optionsDisplayAllBlock,
  optionsGroupBy,
  optionsHeading,
  optionsHiddenButton,
  optionsType,
  sectionDisplay,
  sortOptions,
} from '@ep/insight-ui/elements/chart-type/data-demo';
import { produce } from 'immer';
import { uniq } from 'lodash';
import * as React from 'react';

const optionsChart = [
  {
    label: 'Column',
    labelType: 'STACKING',
    value: 'col',
    icon: 'columnChart',
  },
  {
    label: 'Pie',
    labelType: 'Pie Types',
    value: 'pie',
    icon: 'pieChart',
  },
  {
    label: 'Lines',
    labelType: 'Lines Types',
    value: 'lines',
    icon: 'linesChart',
  },
  {
    label: 'Treemap',
    labelType: 'Treemap Types',
    value: 'treemap',
    icon: 'dashboard',
  },
  {
    label: 'Mixed',
    labelType: 'Mixed Types',
    value: 'mixed',
    icon: 'dashboard',
  },
  {
    label: 'Waterfall',
    labelType: 'Waterfall Types',
    value: 'waterfall',
    icon: 'ic/mdi:chart-waterfall/charcoal',
  },
  {
    label: 'Heatmap',
    labelType: 'Heatmap Types',
    value: 'heatmap',
    icon: 'dashboard',
  },
];

export function ChartConfigForm({ data, onSubmit }) {
  // const [config, setConfig] = React.useState(data);
  const config = data;

  const handleChartConfig = (cConfig) => {
    const finConfig = produce(config, (draft) => {
      draft.chartConfig = { config: cConfig };
    });

    // setConfig(finConfig);
    onSubmit(finConfig);
  };

  const defaultOptionsHiddenButton = [];

  const dimensionOptions = React.useMemo(() => {
    const column = Object.entries(config.mapping)
      .filter(([k, i]) => {
        return i.propertyType !== 'metric';
      })
      .map(([k, v]) => {
        return { label: k, value: k };
      });

    return column;
    // const dimensions: any[] = Object.values(config.mapping).filter((i) => i.propertyType === 'dimension');
    // return uniq(
    //   dimensions.reduce((carry, dim) => {
    //     return carry.concat(Object.values(dim.valueGetter));
    //   }, []),
    // ).map((i) => {
    //   return {
    //     label: i,
    //     value: i,
    //   };
    // });
  }, [config]);

  return (
    <ChartEditor
      optionsGroupPeriod={[]}
      optionsChart={optionsChart}
      optionsType={optionsType}
      optionsHeading={optionsHeading}
      optionsColor={optionsColor}
      optionsColumnColor={optionsColumnColor}
      optionsCohorts={optionsCohorts}
      optionsGroupBy={optionsGroupBy}
      optionsDimension={dimensionOptions}
      sectionDisplay={sectionDisplay}
      optionsColumnStacked={columnStacked}
      optionsHiddenButton={optionsHiddenButton}
      sortOptions={sortOptions}
      optionsDisplayAllBlock={optionsDisplayAllBlock}
      defaultOptionsHiddenButton={defaultOptionsHiddenButton}
      onSubmit={handleChartConfig}
      nodeData={config}
    />
  );
}
