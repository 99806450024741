import * as React from 'react';
import { Typography, Box, Button, makeStyles, Modal } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { useNotificationGlobal } from '../notifications/hook';
import ButtonAction from '../button/button-action';
/**
 * ff.invite_user_workspace:start
 */
import { usePutUser } from './hook/use-put-user';
/**
 * ff.invite_user_workspace:end
 */

const useStyle = makeStyles({
  input: {
    padding: '10px 0',
  },
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
});

export const InviteUser = ({ buttonText = 'Add component' }: { buttonText: string }) => {
  const globalToast = useNotificationGlobal();
  const classes = useStyle();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [dataInput, setDataInput] = React.useState('');
  const handleClose = () => {
    setOpen(false);
  };
  const { putUser } = usePutUser({ dataInput: dataInput, setDataInput: setDataInput, setOpen: setOpen });
  const handleSubmit = () => {
    //Success
    globalToast.onToastCustomize({
      title: 'Invitation link has been sent to each user email ',
      content: (
        <div>
          <div>The invivation link will valid for 24 hours</div>
        </div>
      ),
      variant: 'success',
      disableIcon: false,
    });
    //Fail
    globalToast.onToastCustomize({
      title: 'Invite new users failed',
      content: (
        <div>
          <div>Exceed limit 100 user per workspace. Please review and try again</div>
        </div>
      ),
      variant: 'error',
      disableIcon: false,
    });

    globalToast.onToastCustomize({
      title: 'Invite new users failed',
      content: (
        <div>
          <div>Invalid email format. Please review the list of email address and try again</div>
        </div>
      ),
      variant: 'error',
      disableIcon: false,
    });
    // Close input
    handleClose();
  };
  return (
    <>
      <ButtonAction
        icon="plus"
        label={buttonText}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={handleOpen}
      />
      <Modal open={open}>
        <Box className={classes.popupModal}>
          <Typography variant="h4">Input emails to invite</Typography>
          <TextField
            className={`${classes.input}`}
            size="small"
            variant="outlined"
            style={{ width: 360 }}
            multiline
            placeholder="Placeholder"
            value={dataInput}
            onChange={(e) => setDataInput(e.target.value)}
          />
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button color="secondary" variant="contained" component="span" onClick={handleClose}>
              <span>Cancel</span>
            </Button>
            {ff.invite_user_workspace ? (
              <Button color="primary" variant="contained" component="span" onClick={putUser}>
                <span>Invite</span>
              </Button>
            ) : (
              <Button color="primary" variant="contained" component="span" onClick={handleSubmit}>
                <span>Invite</span>
              </Button>
            )}
          </Box>
        </Box>
      </Modal>
    </>
  );
};
