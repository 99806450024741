import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { useLog } from '../../lib/log';
import RichPrompt from '@ep/insight-ui/elements/rich-prompt';

const log = useLog('dbf:title-editable');
const useStyles = makeStyles(() => {
  return {
    slackInput: {
      minHeight: '1em!important',
      border: 0,
      outline: 'none',
      '&:empty:before': {
        color: '#8C98A4',
        content: 'attr(placeholder)',
      },
      display: 'inline-block',
    },
  };
});

export const TitleEditable = ({
  value,
  placeholder,
  onChange,
  readOnly = false,
  isEditor = false,
}: {
  value: { text: string; ts?: number };
  placeholder: string;
  readOnly: boolean;
  onChange: ({ text: string }) => void;
  isEditor?: boolean;
}) => {
  const timeRef = React.useRef<number>(Date.now());
  const valueRef = React.useRef<string>('');
  const classes = useStyles();
  const [titleText, setTitleText] = React.useState<string>(value.text);

  React.useLayoutEffect(() => {
    if (valueRef.current !== value.text) {
      valueRef.current = value.text;
      setTitleText(value.text);
    }
  }, [value.text]);

  return (
    <div
      className={classes.slackInput}
      contentEditable={!readOnly}
      onInput={(evt) => {
        valueRef.current = evt.currentTarget.textContent;
        if (isEditor) {
          onChange({ text: evt.currentTarget.textContent, ts: timeRef.current });
        }
      }}
      {...(!isEditor
        ? {
            onBlur: () => {
              onChange({ text: valueRef.current });
            },
          }
        : {})}
      placeholder={placeholder}
    >
      {titleText?.startsWith('=') && !isEditor ? <RichPrompt text={titleText} /> : titleText}
    </div>
  );
};
