import * as React from 'react';
import clsx from 'clsx';

import {
  Box,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  Typography,
  Popover,
  ListItemIcon,
  Button,
  OutlinedInput,
} from '@material-ui/core';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import HeaderList from '@ep/insight-ui/elements/table/header-list';
import FilterForm from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog/filter-form-group';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import Icon from '@ep/insight-ui/icons/Icon';
import SelectBoxRender from '@ep/insight-ui/elements/select-box-render';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { optionsColumnColor, optionsColumnColor2 } from '@ep/insight-ui/elements/chart-type/data-demo';

import { useFormatItem } from './hooks/use-format-item';

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    // maxWidth: 'unset',
    maxWidth: 'calc(100vw - 60%)',
    // width: 'fit-content',
    minWidth: '432px',
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      '&:hover': {
        '& input': {
          borderColor: '#006EC6',
        },
        '& .eip1-MuiOutlinedInput-notchedOutline': {
          borderColor: '#006EC6',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: '0px 8px',
      height: 32,
    },
    '& .eip1-MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
    '& .eip1-MuiAccordionSummary-content': {
      margin: 0,
      cursor: 'auto',
      minWidth: '624.3px',
    },
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      minHeight: '24px',
    },
    '& .eip1-MuiAccordionSummary-root': {
      minHeight: '24px',
      position: 'relative',
    },
    '& .eip1-MuiCollapse-hidden': {
      opacity: 0,
    },
    '& .eip1-MuiAccordionSummary-root.Mui-focused, & .eip1-MuiAccordionSummary-root.Mui-focusVisible': {
      backgroundColor: 'transparent',
    },
    border: '1px solid #C2C7CB',
    borderRadius: '4px',
    marginBottom: '20px',
  },
  buttonAddBottomWrap: {
    marginTop: '8px',
  },
  buttonAddBottom: {
    display: 'flex',
    alignItems: ' center',
    padding: '2px 8px',
    marginLeft: '-5px',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '14px',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-3px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  groupRule: {
    display: 'flex',
    flexWrap: 'nowrap',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
    columnGap: '8px',
    alignItems: 'center',
  },
  groupItem: {
    width: '200px',
  },
  closeBtn: {
    height: '24px',
    width: '24px',
  },
  formGroup: {
    marginTop: '8px',
    '& .eip1-MuiButton-text': {
      padding: '6px 8px',
    },
  },
  header: {
    paddingLeft: 0,
  },
  endAdornment: {
    cursor: 'pointer',
  },
  startAdornment: {
    border: '1px solid #dadce0',
    width: '21px',
    height: '16px',
    borderRadius: '4px',
  },
  box12: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  buttonColor: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: 'transparent',
  },
  buttonColorActive: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: '#E4E7E9',
  },
  label: {
    fontStyle: ' normal',
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '28px',
    '.eres--small &': {
      fontSize: '18px',
      lineHeight: '20px',
    },
    margin: '0 8px',
  },
  icon: {
    height: '16px',
  },
  headerFormat: {
    '& .edit-icon': {
      display: 'none',
    },
    '&:hover .edit-icon': {
      display: 'block',
    },
    position: 'relative',
    cursor: 'pointer',
  },
  input: {
    '& .eip1-MuiInputBase-input': {
      backgroundColor: '#fff',
      padding: '0 8px',
    },
    width: '500px',
  },
  inputControl: {
    marginLeft: '8px',
  },
  threeDotsIcon: {
    position: 'absolute',
    top: '50%',
    right: '8px',
    transform: 'translateY(-50%)',
    height: '32px',
    cursor: 'pointer',
    '& svg': {
      width: '3px',
    },
  },
  labelStyles: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  labelStylesItem: {
    background: 'none',
    outline: 'none',
    border: 'none',
    cursor: 'pointer',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    width: '32px',
    marginRight: '1px',
    borderRadius: '2px',
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&:hover': {
      background: 'rgba(var(--sk_foreground_low,29,28,29),.13)',
    },
    '&.is-active': {
      color: 'rgba(var(--sk_primary_foreground,29,28,29),1)',
      background: 'rgba(var(--sk_primary_foreground,29,28,29),.2)',
    },
  },
  colorPickerContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  popover: {
    minWidth: 'unset',
  },
  cellPreviewContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '8px',
  },
  cellPreview: {
    height: '55px',
    fontSize: '14px',
    padding: '0.5px 8px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #e5e5e5',
    marginLeft: '8px',
  },
  cellData: {
    padding: '0 6px',
    borderRadius: '4px',
  },
  removeIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
}));

interface IPropsFormatItem {
  handleUpdateFormatItem?: (item) => void;
  handleDeleteFormatItem?: (item) => void;
  handleDuplicateFormatItem?: (item) => void;
  item: {
    fakeId: string;
    filters: any[];
    styles: any[];
  };
  index: number;
  items: any[];
  setItems: any;
  previewText?: string;
  previewWidth?: number;
}

const FormatItem = (props: IPropsFormatItem) => {
  const classes = useStyles();

  const backbone = React.useContext(TableBackboneContext);
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;

  const {
    expanded,
    setExpanded,
    hideDetail,
    titleRef,
    title,
    setIsEditing,
    isEditing,
    styleItems,
    filterOptions,
    filterOperators,
    setFilterModel,
    getOptionFilterAvailable,
    inputRef,
    actions,
    labelStyles,
    handleClickLabelStyle,
    anchorEl,
    handleClosePopover,
    handleOpenPopover,
    colorPickerStyles,
    colorValue,
    setColorValue,
    styles,
  } = useFormatItem({
    backbone,
    props,
  });

  return (
    <SelectBoxRender
      draggable={true}
      index={props.index}
      classes={classes}
      items={props.items}
      setItems={props.setItems}
    >
      <Box className={classes.container}>
        <Accordion expanded={expanded}>
          <AccordionSummary>
            <Box display={'inline-flex'} alignItems={'center'} className={classes.headerFormat}>
              <Box
                display={'inline-flex'}
                alignItems={'center'}
                onClick={() => {
                  if (!isEditing) {
                    setExpanded(!expanded);
                  }
                }}
              >
                <span className={classes.icon}>
                  <Icon
                    colorIcon={isDarkMode ? '#FFFFFF' : '#000000'}
                    type={expanded ? 'arrowdown' : 'arrowright'}
                    style={{ width: '12px', height: '15px' }}
                  />
                </span>
                {isEditing ? (
                  <Box className={classes.inputControl}>
                    <TextField
                      defaultValue={titleRef.current}
                      inputRef={inputRef}
                      variant="outlined"
                      onBlur={() => {
                        setIsEditing(false);
                      }}
                      className={classes.input}
                      onChange={(e) => {
                        titleRef.current = e.target.value;
                      }}
                      onKeyUp={(e) => {
                        if (e.key === 'Enter') {
                          setIsEditing(false);
                        }
                      }}
                    />
                  </Box>
                ) : (
                  <span
                    style={{ color: isDarkMode ? '#FFFFFF' : '#000000' }}
                    className={classes.label}
                    data-dbf-text-editable={'etable-title'}
                  >
                    {title || 'Untitled'}
                  </span>
                )}
              </Box>
              {!isEditing ? (
                <span className={clsx(classes.icon, 'edit-icon')} onClick={() => setIsEditing(true)}>
                  <Icon
                    colorIcon={isDarkMode ? '#FFFFFF' : '#000000'}
                    type={'edit'}
                    style={{ width: '12px', height: '15px' }}
                  />
                </span>
              ) : null}
            </Box>
            <Box className={classes.threeDotsIcon}>
              <Dropdown alignMenu="right" icon="threeDotsVertical" label="" disabledClickContentClose={false}>
                <MenuList listMenu={actions} />
              </Dropdown>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {!hideDetail ? (
              <Box display={'flex'} flexDirection={'column'} width={'100%'}>
                <FilterForm
                  addFilterButtonLabel="Add condition"
                  headerText={'Condition'}
                  helperText={<Typography variant="body2">Add your first condition</Typography>}
                  options={filterOptions}
                  operators={filterOperators}
                  filterModel={props.item.filters}
                  setFilterModel={setFilterModel}
                  enabledSearchOptions={false}
                  enableFillerGroup
                  disabledIconNoFilter
                  getOptionAvailable={getOptionFilterAvailable}
                  hideButtons
                />
                <Box>
                  <HeaderList title="Style" variant="h4" className={classes.header} />
                  <Box className={classes.labelStyles}>
                    {colorPickerStyles.map(({ key, icon }) => {
                      return (
                        <Box
                          key={key}
                          className={clsx(classes.labelStylesItem)}
                          onClick={(e) => handleOpenPopover(e, key)}
                        >
                          <Icon type={icon} />
                        </Box>
                      );
                    })}
                    {labelStyles.map(({ icon, key, value }) => {
                      return (
                        <Box
                          key={key}
                          className={clsx(
                            classes.labelStylesItem,
                            (styleItems.find(({ key: styleKey }) => styleKey === key) || { value: false }).value
                              ? 'is-active'
                              : '',
                          )}
                          onClick={() => handleClickLabelStyle({ key, value })}
                        >
                          <Icon type={icon} />
                        </Box>
                      );
                    })}
                  </Box>
                  <Box className={classes.cellPreviewContainer}>
                    <span>Cell preview</span>
                    <Box className={classes.cellPreview} sx={{ minWidth: `${props.previewWidth}px` }}>
                      <Box className={classes.cellData} sx={styles}>
                        <span>{props.previewText || 'Text preview'}</span>
                      </Box>
                    </Box>
                  </Box>
                  <Popover
                    classes={{ paper: clsx(classes.container, classes.popover) }}
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={() => handleClosePopover()}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <Box className={classes.colorPickerContainer}>
                      <OutlinedInput
                        onChange={(e) => setColorValue(e.target.value)}
                        value={colorValue}
                        endAdornment={
                          colorValue ? (
                            <Box className={classes.removeIcon} onClick={() => setColorValue('')}>
                              <Icon type={'closeCircle'} size={12} />
                            </Box>
                          ) : null
                        }
                      />
                      <ListItemIcon style={{ gap: '8px', flexWrap: 'wrap', width: '200px' }}>
                        {optionsColumnColor.map((color) => (
                          <Button
                            key={color}
                            variant="contained"
                            color="secondary"
                            className={colorValue === color ? classes.buttonColorActive : classes.buttonColor}
                            onClick={() => {
                              setColorValue(color);
                              handleClosePopover(color);
                            }}
                          >
                            <Box bgcolor={color} className={classes.box12}></Box>
                          </Button>
                        ))}
                      </ListItemIcon>
                      <ListItemIcon style={{ gap: '8px', flexWrap: 'wrap', width: '200px' }}>
                        {(optionsColumnColor2).map((color) => (
                          <Button
                            key={color}
                            variant="contained"
                            color="secondary"
                            className={colorValue === color ? classes.buttonColorActive : classes.buttonColor}
                            onClick={() => {
                              setColorValue(color);
                              handleClosePopover(color);
                            }}
                          >
                            <Box bgcolor={color} className={classes.box12}></Box>
                          </Button>
                        ))}
                      </ListItemIcon>
                    </Box>
                  </Popover>
                </Box>
              </Box>
            ) : null}
          </AccordionDetails>
        </Accordion>
      </Box>
    </SelectBoxRender>
  );
};

export default FormatItem;
