import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const BigScreen = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 0H2C0.895 0 0 0.895 0 2V12C0 13.105 0.895 14 2 14H8L6 17V18H14V17L12 14H18C19.105 14 20 13.105 20 12V2C20 0.895 19.105 0 18 0ZM18 9C18 9.5525 17.5525 10 17 10H3C2.4475 10 2 9.5525 2 9V3C2 2.4475 2.4475 2 3 2H17C17.5525 2 18 2.4475 18 3V9Z" />
  </SvgIcon>
);

export default BigScreen;
