import { noSelectStyled, TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import moment from 'moment';
import * as _ from 'lodash';
import { colorsChartLegend, PopLegend, tableTooltip, legendLine, convertColor } from '../helper';
import numeral from 'numeral';
export const HIDDEN_LABEL_TEXT = '__disabled__';
const useStyles = makeStyles((theme) => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: theme.zIndex.drawer + 10,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}
interface ILabel {
  borderRadius?: number;
  datasetIndex?: number;
  fillStyle?: string;
  fontColor?: string;
  lineCap?: string;
  hidden?: boolean;
  lineWidth?: number;
  strokeStyle?: string;
  text?: string;
}
const hashIndex = {
  0: 0,
  2: 1,
  4: 5,
  6: 3,
  8: 4,
  10: 5,
  12: 6,
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();
  let labels: ILabel[];
  let labelsWidthouDisabled: ILabel[];
  let isFuture: boolean;

  const getOrCreateTooltipAndCrisper = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('.tooltip');
    let crisperEl = chart.canvas.parentNode.querySelector('.crisper') as HTMLSpanElement;

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = `${classes.tooltip} tooltip`;

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    if (!crisperEl) {
      crisperEl = document.createElement('span') as HTMLSpanElement;
      crisperEl.className = `crisper`;
      crisperEl.style.position = 'absolute';

      chart.canvas.parentNode.appendChild(crisperEl);
    }

    return { tooltipEl, crisperEl };
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;
    const { tooltipEl, crisperEl } = getOrCreateTooltipAndCrisper(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      crisperEl.style.opacity = '0';
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      let total = 0;
      bodyLines.forEach((arr) => {
        total += _.get(arr, [0, 'value'], 0);
      });
      const tableHead = document.createElement('thead');

      titleLines.forEach((title) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(moment(title).format('ddd, DD MMM YYYY'));

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.className = 'legendRow';
        const label: string = _.get(body, [0, 'label'], '');
        const value: number = _.get(body, [0, 'value'], 0);
        const backgroundColor: string = _.get(body, [0, 'backgroundColor'], '');
        const currency: string = _.get(body, [0, 'currency'], '');

        const tdHTML = `
        <td class="legendLine">
          <span class="legendIcon" style="background: ${backgroundColor}; border-color: ${backgroundColor};"></span>
          <div class="content">
          <div class="label">${_.startCase(_.lowerCase(label))}</div>
          <div class="value">${`${formatCurrency(value, currency)} ${currency}`}</div>
          <div class="percent">(${numeral((value / total) * 100).format('0.00')}%)</div>
          </div>
        </td>
        `;
        tr.innerHTML = tdHTML;
        tableBody.appendChild(tr);
      });

      const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.className = `${classes.tableTooltip}`;
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    const PADDING = 20;
    tooltipEl.style.opacity = '1';
    tooltipEl.style.left = positionX + tooltip.caretX + PADDING + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = '0';
    tooltipEl.style.transform = 'translateX(0)';

    if (tooltip.caretX > chart.chartArea.width / 2) {
      tooltipEl.style.transform = 'translateX(-100%)';
      tooltipEl.style.left = positionX + tooltip.caretX - PADDING + 'px';
    }

    const { isCrisper } = chart.options;
    if (!isCrisper) return;

    // crisperEl
    crisperEl.style.opacity = '1';

    crisperEl.style.height = `${chart.chartArea.height}px`;
    crisperEl.style.width = '2px';
    crisperEl.style.backgroundColor = '#E4E7E9';
    crisperEl.style.pointerEvents = 'none';

    crisperEl.style.left = positionX + tooltip.caretX + 'px';
    crisperEl.style.top = '10px';
  };

  const getOrCreateLegendList = (id) => {
    const legendContainer = document.getElementById(id);
    if (legendContainer) {
      const listContainer = legendContainer.querySelector(`ul`);
      const popLegend: HTMLElement = legendContainer.querySelector(`.${classes.pop}`);
      const totalValue = legendContainer.querySelector(`.${classes.totalValueLine}`);

      //Create legend custom
      if (!listContainer) {
        // const div = document.createElement('div');
        const listContainer = document.createElement('ul');
        listContainer.className = classes.legendLine;
        // div.appendChild(listContainer);
        legendContainer.appendChild(listContainer);
      }

      //Create pop-up when hover legend
      if (!popLegend) {
        const popLegend = document.createElement('p');
        popLegend.className = classes.pop;
        legendContainer.appendChild(popLegend);
      }

      //Create total value chart
      if (!totalValue) {
        const totalValue = document.createElement('p');
        totalValue.className = classes.totalValueLine;
        legendContainer.appendChild(totalValue);
      }

      return { listContainer, popLegend, totalValue };
    }
    return {};
  };

  let currentLegendItemHovering: number | null = null;
  let cacheUL: HTMLUListElement;
  //Custom legend
  const htmlLegendPlugin = {
    afterUpdate(chart) {
      chart.canvas.parentNode.className = `${classes.wrapp} ${classes.canvas}`;
      labels = chart.options.plugins.legend.labels.generateLabels(chart);
      labelsWidthouDisabled = labels.filter((label) => !label.text.includes(HIDDEN_LABEL_TEXT));
      isFuture = labels.some((label) => label.text.includes(HIDDEN_LABEL_TEXT));
      const id = props.legendDivID;
      let ul = getOrCreateLegendList(id).listContainer;
      const checkEl = document.getElementById(id);

      // If another chart
      if (!checkEl) return;

      // If no Legend yet
      if (!ul) return;

      // If had create Legend
      if (cacheUL) {
        ul = cacheUL;
        return;
      }

      // If don't have create Legend
      // Create custom Legend
      const legendItems = createLegendItems(chart);

      ul.innerHTML = '';
      ul.classList.add('legendDiv');
      legendItems.forEach((legendItem) => ul.appendChild(legendItem));

      cacheUL = ul;
    },
  };

  const createCheckBox = (index: number) => {
    const allActive = labelsWidthouDisabled.every((label) => !label.hidden);

    //Create checkbox
    const checkbox = document.createElement('span');
    checkbox.className = `legendItem__checkbox ${!labelsWidthouDisabled[index].hidden ? 'active' : ''}`;
    checkbox.style.backgroundColor = `${
      !labelsWidthouDisabled[index].hidden ? colorsChartLegend[index].stroke : 'transparent'
    }`;
    //Create checked
    if (!allActive) {
      const after = document.createElement('span');
      const before = document.createElement('span');
      after.className = 'checked after';
      before.className = 'checked before';
      checkbox.appendChild(after);
      checkbox.appendChild(before);
    }

    return checkbox;
  };

  const createLegendItem = (label: ILabel) => {
    const li = document.createElement('li');

    const checkbox = createCheckBox(isFuture ? hashIndex[label.datasetIndex] : label.datasetIndex);

    //Binding data text
    const textContainer = document.createElement('p');
    textContainer.style.color = label.fontColor;
    textContainer.style.textDecoration = label.hidden ? 'line-through' : '';
    textContainer.className = 'textContainer';
    const text = document.createTextNode(label.text);
    textContainer.appendChild(text);

    li.className = `legendItem`;
    li.appendChild(checkbox);
    li.appendChild(textContainer);

    return li;
  };

  const createLegendItems = (chart: any) => {
    const datas: Array<any> = chart.data.datasets;

    const { cacheColorsPoint, cacheColorsPointHover, cacheColorsPointBorder, cacheColorsPointBorderHover } =
      cacheChartColors;
    const chartColorsPoint = cacheColorsPoint(datas.map((data) => data.pointBackgroundColor));
    const chartColorsPointBorder = cacheColorsPointBorder(datas.map((data) => data.pointBorderColor));
    const chartColorsPointHover = cacheColorsPointHover(chartColorsPoint.map((color) => convertColor(color)));
    const chartColorsPointBorderHover = cacheColorsPointBorderHover(
      chartColorsPointBorder.map((color) => convertColor(color)),
    );

    const onToggle = (legendIndex: number) => {
      const allActive = labels.every((label) => !label.hidden);

      if (!labels[legendIndex].hidden) {
        if (allActive) {
          labels.forEach((label) => (label.hidden = true));
          labels[legendIndex].hidden = false;
          if (isFuture) {
            labels[legendIndex + 1].hidden = false;
          }

          labels.forEach((label, index) => {
            if (!label.hidden) return chart.setDatasetVisibility(index, true);
            return chart.setDatasetVisibility(index, false);
          });

          onMouseover(legendIndex);
          return;
        }

        if (!allActive) {
          labels[legendIndex].hidden = true;
          if (isFuture) {
            labels[legendIndex + 1].hidden = true;
          }
          const allNotActive = labels.every((label) => label.hidden);
          if (allNotActive) {
            labels.forEach((label) => (label.hidden = false));
          }

          labels.forEach((label, index) => {
            if (!label.hidden) return chart.setDatasetVisibility(index, true);
            return chart.setDatasetVisibility(index, false);
          });
          onMouseout();
          return;
        }
      }

      labels[legendIndex].hidden = false;
      if (isFuture) {
        labels[legendIndex + 1].hidden = false;
      }
      labels.forEach((label, index) => {
        if (!label.hidden) return chart.setDatasetVisibility(index, true);
        return chart.setDatasetVisibility(index, false);
      });
      onMouseover(legendIndex);
    };

    const onMouseover = (legendIndex: number) => {
      datas.forEach((data, index) => {
        data.pointBackgroundColor = chartColorsPointHover[index];
        data.pointBorderColor = chartColorsPointBorderHover[index];
      });

      datas[legendIndex].pointBackgroundColor = chartColorsPoint[legendIndex];
      datas[legendIndex].pointBorderColor = chartColorsPointBorder[legendIndex];
      if (isFuture) {
        datas[legendIndex + 1].pointBackgroundColor = chartColorsPoint[legendIndex];
        datas[legendIndex + 1].pointBorderColor = chartColorsPointBorder[legendIndex];
      }

      currentLegendItemHovering = legendIndex;
      chart.data.datasets = datas;
    };

    const onMouseout = () => {
      currentLegendItemHovering = null;
      datas.forEach((data, index) => {
        data.pointBackgroundColor = chartColorsPoint[index];
        data.pointBorderColor = chartColorsPointBorder[index];
      });

      chart.data.datasets = datas;
    };

    const onUpdateLegendItem = () => {
      const id = props.legendDivID;
      const ul = getOrCreateLegendList(id).listContainer;

      const legendItems = ul.querySelectorAll('.legendItem') as NodeListOf<HTMLLIElement>;
      legendItems.forEach((liEl, index) => {
        const oldCheckbox = liEl.querySelector('.legendItem__checkbox') as HTMLSpanElement;
        const newCheckbox = createCheckBox(index);

        oldCheckbox.outerHTML = newCheckbox.outerHTML;
      });
    };

    return labels
      .map((label, legendIndex) => {
        if (!(label.text as string).includes(HIDDEN_LABEL_TEXT)) {
          const li: HTMLLIElement = createLegendItem(label);

          //Click legend show pop-up
          li.onclick = () => {
            onToggle(legendIndex);
            onUpdateLegendItem();
            chart.update();
          };
          li.onmouseover = () => {
            if (currentLegendItemHovering === legendIndex) return;
            if (labels[legendIndex].hidden) return;

            onMouseover(legendIndex);
            chart.update();
          };
          li.onmouseout = () => {
            onMouseout();
            chart.update();
          };
          return li;
        }
      })
      .filter((li) => li);
  };

  const cacheChartColors = (() => {
    let colorsPoint: string[] = [];
    let colorsPointBorder: string[] = [];
    let colorsPointHover: string[] = [];
    let colorsPointBorderHover: string[] = [];
    return {
      cacheColorsPoint: (chartColor: string[]) => {
        if (colorsPoint.length === 0) {
          colorsPoint = chartColor;
        }
        return colorsPoint;
      },
      cacheColorsPointHover: (chartColor: string[]) => {
        if (colorsPointHover.length === 0) {
          colorsPointHover = chartColor;
        }
        return colorsPointHover;
      },
      cacheColorsPointBorder: (chartColor: string[]) => {
        if (colorsPointBorder.length === 0) {
          colorsPointBorder = chartColor;
        }
        return colorsPointBorder;
      },
      cacheColorsPointBorderHover: (chartColor: string[]) => {
        if (colorsPointBorderHover.length === 0) {
          colorsPointBorderHover = chartColor;
        }
        return colorsPointBorderHover;
      },
    };
  })();

  return {
    externalTooltipHandler: externalTooltipHandler,
    htmlLegendPlugin: htmlLegendPlugin,
  };
};
