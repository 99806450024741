import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import moment from 'moment';
import * as _ from 'lodash';
import { PopLegend, tableTooltip, legendLine, convertColor } from '../helper';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
export const HIDDEN_LABEL_TEXT = '__disabled__';
const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: 999,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
  btnRight: {
    display: 'none',
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    background: '#fff',
    zIndex: 100,
    cursor: 'pointer',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
  btnLeft: {
    display: 'none',
    position: 'absolute',
    background: '#fff',
    top: '50%',
    left: 0,
    transform: 'translateY(-50%)',
    zIndex: 100,
    cursor: 'pointer',
    '& svg': {
      width: '16px',
      height: '16px',
    },
  },
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}
interface ILabel {
  fillStyle?: string;
  hidden?: boolean;
  index?: number;
  lineWidth?: number;
  strokeStyle?: string;
  text?: string;
  fontColor?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();

  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = classes.tooltip;

      const table = document.createElement('table');
      table.style.margin = '0px';

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }

    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Tooltip Element
    const { chart, tooltip } = context;

    const tooltipEl = getOrCreateTooltip(chart);

    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set Text
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      const tableHead = document.createElement('thead');

      titleLines.forEach((title) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(moment(title).format('ddd, DD MMM YYYY'));

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.className = 'legendRow';
        const label: string = _.get(body, [0, 'label'], '');
        const backgroundColor: string = _.get(body, [0, 'backgroundColor'], '');
        const value: number = _.get(body, [0, 'value'], 0);
        const totalValue: number = _.get(body, [0, 'totalValue'], 0);
        const colors = tooltip.labelColors[i];
        const currency: string = _.get(body, [0, 'currency'], '') || '';
        const formatLabel: string = _.get(body, [0, 'formatLabel'], '');
        let valueText = formatCurrencyNumber(value, currency);
        if (formatLabel.startsWith('=')) {
          valueText = toValue(formatLabel, _.get(body, [0], {}));
        }

        if (!label) return;
        if (!value) return;

        const tdHTML = `
        <td class="legendLine">
          <span class="legendIcon" style="background: ${colors.backgroundColor}; border-color: ${
          colors.borderColor
        };"></span>
          <div class="content">
            <div class="label">${label}</div>
            <div class="value">${`${valueText} ${formatLabel.includes('currency') ? '' : currency}`}</div>
          </div>
        </td>
        `;
        tr.innerHTML = tdHTML;
        tableBody.appendChild(tr);
      });

      const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.className = `${classes.tableTooltip}`;
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    // Display, position, and set styles for font
    tooltipEl.style.opacity = '1';
    if (tooltip.caretX > 832) {
      tooltipEl.style.transform = 'translateX(-100%)';
    } else if (tooltip.caretX > 63) {
      tooltipEl.style.transform = 'translateX(0)';
    }
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = '0';
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    if (legendContainer) {
      const listContainer = legendContainer.querySelector(`ul`);
      const popLegend: HTMLElement = legendContainer.querySelector(`.${classes.pop}`);
      const totalValue = legendContainer.querySelector(`.${classes.totalValueLine}`);

      //Create legend custom
      if (!listContainer) {
        // const div = document.createElement('div');
        const listContainer = document.createElement('ul');
        listContainer.className = classes.legendLine;
        // div.appendChild(listContainer);
        legendContainer.appendChild(listContainer);
      }

      //Create pop-up when hover legend
      if (!popLegend) {
        const popLegend = document.createElement('p');
        popLegend.className = classes.pop;
        legendContainer.appendChild(popLegend);
      }

      const buttonRight = legendContainer.querySelector(`#btnRight-${id}`);
      if (!buttonRight) {
        const btnRight = document.createElement('span');
        btnRight.className = classes.btnRight;
        btnRight.id = `btnRight-${id}`;
        btnRight.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM358.6 278.6l-112 112c-12.5 12.5-32.75 12.5-45.25 0s-12.5-32.75 0-45.25L290.8 256L201.4 166.6c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l112 112C364.9 239.6 368 247.8 368 256S364.9 272.4 358.6 278.6z"/></svg>';
        btnRight.onclick = (e) => {
          const legendNode = legendContainer.querySelector('ul');
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (legendNode) {
            const { scrollLeft, scrollWidth, clientWidth } = legendNode;
            if (scrollLeft < scrollWidth - clientWidth) {
              legendNode.scrollTo({
                left: scrollLeft + 200,
                behavior: 'smooth',
              });
              if (scrollLeft + 200 >= scrollWidth - clientWidth) {
                btnRight.style.display = 'none';
              }
            }

            btnLeft.style.display = 'inline-block';
          }
        };
        btnRight.onmouseenter = () => {
          btnRight.style.display = 'inline-block';
          const legendNode = legendContainer.querySelector('ul');
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (legendNode) {
            setTimeout(() => {
              const { scrollLeft, scrollWidth, clientWidth } = legendNode;
              if (scrollLeft >= scrollWidth - clientWidth) {
                btnRight.style.display = 'none';
              }
            }, 200);
            const { scrollLeft } = legendNode;
            if (scrollLeft > 0) {
              btnLeft.style.display = 'inline-block';
            }
          }
        };
        btnRight.onmouseout = () => {
          btnRight.style.display = 'none';
          const btnLeft = legendContainer.querySelector(`#btnLeft-${id}`);
          if (btnLeft) {
            btnLeft.style.display = 'none';
          }
        };
        legendContainer.appendChild(btnRight);
      }

      const buttonLeft = legendContainer.querySelector(`#btnLeft-${id}`);
      if (!buttonLeft) {
        const btnLeft = document.createElement('span');
        btnLeft.className = classes.btnLeft;
        btnLeft.id = `btnLeft-${id}`;
        btnLeft.innerHTML =
          '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M256 0C114.6 0 0 114.6 0 256c0 141.4 114.6 256 256 256s256-114.6 256-256C512 114.6 397.4 0 256 0zM310.6 345.4c12.5 12.5 12.5 32.75 0 45.25s-32.75 12.5-45.25 0l-112-112C147.1 272.4 144 264.2 144 256s3.125-16.38 9.375-22.62l112-112c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L221.3 256L310.6 345.4z"/></svg>';
        btnLeft.onclick = () => {
          const legendNode = legendContainer.querySelector('ul');
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (legendNode) {
            const { scrollLeft } = legendNode;
            if (scrollLeft > 0) {
              legendNode.scrollTo({
                left: scrollLeft - 200,
                behavior: 'smooth',
              });
              if (scrollLeft - 200 <= 0) {
                btnLeft.style.display = 'none';
              }
            }
            btnRight.style.display = 'inline-block';
          }
        };
        btnLeft.onmouseenter = () => {
          btnLeft.style.display = 'inline-block';
          const legendNode = legendContainer.querySelector('ul');
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (legendNode) {
            setTimeout(() => {
              const { scrollLeft } = legendNode;
              if (scrollLeft <= 0) {
                btnLeft.style.display = 'none';
              }
            }, 200);
            const { scrollLeft, scrollWidth, clientWidth } = legendNode;
            if (scrollLeft < scrollWidth - clientWidth) {
              btnRight.style.display = 'inline-block';
            }
          }
        };
        btnLeft.onmouseout = () => {
          btnLeft.style.display = 'none';
          const btnRight = legendContainer.querySelector(`#btnRight-${id}`);
          if (btnRight) {
            btnRight.style.display = 'none';
          }
        };
        legendContainer.appendChild(btnLeft);
      }

      //Create total value chart
      if (!totalValue) {
        const totalValue = document.createElement('p');
        totalValue.className = classes.totalValueLine;
        legendContainer.appendChild(totalValue);
      }

      return { listContainer, popLegend, totalValue };
    }
    return {};
  };

  let currentLegendItemHovering: number | null = null;
  let cacheUL: HTMLUListElement;
  //Custom legend
  const htmlLegendPlugin = {
    afterUpdate(chart) {
      chart.canvas.parentNode.className = `${classes.wrapp} ${classes.canvas}`;
      const id = props.legendDivID;
      let ul = getOrCreateLegendList(chart, id).listContainer;
      const checkEl = document.getElementById(id);

      // If another chart
      if (!checkEl) return;

      // If no Legend yet
      if (!ul) return;

      // If had create Legend
      if (cacheUL) {
        ul = cacheUL;
        return;
      }

      // If don't have create Legend
      // Create custom Legend
      const legendItems = createLegendItems(chart, id);

      ul.innerHTML = '';
      ul.classList.add('legendDiv');
      legendItems.forEach((legendItem) => ul.appendChild(legendItem));

      cacheUL = ul;
    },
  };

  const createLegendItems = (chart: any, id: string) => {
    const labels: ILabel[] = chart.options.plugins.legend.labels.generateLabels(chart).filter((item) => item.text);

    const data = _.get(chart, ['data', 'datasets', '0'], null);

    const { cacheColorsBackground, cacheColorsBackgroundHover } = cacheChartColors;
    const chartColorsBackground = cacheColorsBackground(_.get(data, ['backgroundColor'], []));
    const chartColorsBackgroundHover = cacheColorsBackgroundHover(
      chartColorsBackground.map((color) => convertColor(color)),
    );

    const onToggle = (legendIndex: number) => {
      const allActive = data.actives.every((data) => data);
      if (data.actives[legendIndex]) {
        if (allActive) {
          data.actives.fill(false);
          data.actives.forEach((data, index) => {
            chart._hiddenIndices[index] = !data;
          });

          data.actives[legendIndex] = !data.actives[legendIndex];
          chart.toggleDataVisibility(legendIndex);
          onMouseover(legendIndex);
          return;
        }

        if (!allActive) {
          data.actives[legendIndex] = !data.actives[legendIndex];
          const allNotActive = data.actives.every((data) => !data);
          if (allNotActive) {
            data.actives.fill(true);
            data.actives.forEach((data, index) => {
              chart._hiddenIndices[index] = !data;
            });
            return;
          }

          chart.toggleDataVisibility(legendIndex);
          onMouseout();
          return;
        }
      }
      data.actives[legendIndex] = !data.actives[legendIndex];
      chart.toggleDataVisibility(legendIndex);
      onMouseover(legendIndex);
    };

    const onMouseover = (legendIndex: number) => {
      const divLegend = document.querySelector(`#${id}`);
      if (divLegend) {
        const menu = divLegend.querySelector('ul');
        if (menu) {
          const btnLeft = divLegend.querySelector(`#btnLeft-${id}`);
          const btnRight = divLegend.querySelector(`#btnRight-${id}`);
          if (menu.scrollLeft > 0 && btnLeft) {
            btnLeft.style.display = 'inline-block';
          }
          if (menu.scrollLeft < menu.scrollWidth - menu.clientWidth) {
            btnRight.style.display = 'inline-block';
          }
        }
      }
      data.backgroundColor = [...chartColorsBackgroundHover];
      data.backgroundColor.forEach((color, index, colors) => {
        colors[index] = legendIndex === index ? chartColorsBackground[index] : chartColorsBackgroundHover[index];
      });

      // Override to update data
      chart.data.datasets[0] = data;
      currentLegendItemHovering = legendIndex;
    };

    const onMouseout = () => {
      const divLegend = document.querySelector(`#${id}`);
      if (divLegend) {
        const btnLeft = divLegend.querySelector(`#btnLeft-${id}`);
        const btnRight = divLegend.querySelector(`#btnRight-${id}`);
        if (btnLeft) {
          btnLeft.style.display = 'none';
        }
        if (btnRight) {
          btnRight.style.display = 'none';
        }
      }
      currentLegendItemHovering = null;
      data.backgroundColor = [...chartColorsBackground];

      // Override to update data
      chart.data.datasets[0] = data;
    };

    const onUpdateLegendItem = () => {
      const id = props.legendDivID;
      const ul = getOrCreateLegendList(chart, id).listContainer;

      const legendItems = ul.querySelectorAll('.legendItem') as NodeListOf<HTMLLIElement>;
      legendItems.forEach((liEl, index) => {
        const oldCheckbox = liEl.querySelector('.legendItem__checkbox') as HTMLSpanElement;
        const newCheckbox = createCheckBox(index);

        oldCheckbox.outerHTML = newCheckbox.outerHTML;
      });
    };

    const createCheckBox = (index: number) => {
      const actives = _.get(data, ['actives'], []);
      const allActive = actives.every((data) => data);

      //Create checkbox
      const checkbox = document.createElement('span');
      checkbox.className = `legendItem__checkbox ${actives[index] ? 'active' : ''}`;
      checkbox.style.backgroundColor = `${actives[index] ? chartColorsBackground[index] : 'transparent'}`;
      //Create checked
      if (!allActive) {
        const after = document.createElement('span');
        const before = document.createElement('span');
        after.className = 'checked after';
        before.className = 'checked before';
        checkbox.appendChild(after);
        checkbox.appendChild(before);
      }

      return checkbox;
    };

    const createLegendItem = (label: ILabel) => {
      const li = document.createElement('li');

      const checkbox = createCheckBox(label.index);

      //Binding data text
      const textContainer = document.createElement('p');
      textContainer.style.color = label.fontColor;
      textContainer.style.textDecoration = label.hidden ? 'line-through' : '';
      textContainer.className = 'textContainer';
      const text = document.createTextNode(label.text);
      textContainer.appendChild(text);

      li.className = `legendItem`;
      li.appendChild(checkbox);
      li.appendChild(textContainer);

      return li;
    };

    // //Get value and perCent
    return labels.map((label, legendIndex) => {
      if (label.text !== HIDDEN_LABEL_TEXT) {
        const li = createLegendItem(label);
        //Click legend show pop-up
        li.onclick = () => {
          onToggle(legendIndex);
          onUpdateLegendItem();
          chart.update();
        };
        li.onmouseover = () => {
          if (currentLegendItemHovering === legendIndex) return;
          if (!data.actives[legendIndex]) return;

          onMouseover(legendIndex);
          chart.update();
        };
        li.onmouseout = () => {
          onMouseout();
          chart.update();
        };
        return li;
      }
    });
  };

  const cacheChartColors = (() => {
    let colorsBackground: string[] = [];
    let colorsBackgroundHover: string[] = [];
    return {
      cacheColorsBackground: (chartColor: string[]) => {
        if (colorsBackground.length === 0) {
          colorsBackground = chartColor;
        }
        return colorsBackground;
      },
      cacheColorsBackgroundHover: (chartColor: string[]) => {
        if (colorsBackgroundHover.length === 0) {
          colorsBackgroundHover = chartColor;
        }
        return colorsBackgroundHover;
      },
    };
  })();

  return {
    externalTooltipHandler: externalTooltipHandler,
    htmlLegendPlugin: htmlLegendPlugin,
  };
};
