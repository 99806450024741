import * as React from 'react';
import loadjs from 'loadjs';
declare global {
  interface Window {
    require: any;
    eppy: any;
    loadPyodide: any;
    monaco: any;
    MonacoVim: any;
    MonacoEnvironment: any;
  }
}

const CURRENT_HOST = window.location.host;
const CURRENT_PROTOCOL = window.location.protocol;

let ideLoadingStatus = false;
const allCallbacks = [];
function loadMonaco(callback) {
  if (ideLoadingStatus) {
    callback();
    return;
  }

  allCallbacks.push(callback);
  if (allCallbacks.length > 1) {
    return;
  }

  loadjs(
    [
      'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.44.0/min/vs/loader.min.js',
      // 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/pyodide.js',
    ],
    'monaco',
    {
      success: () => {
        window.require.config({
          paths: {
            vs: 'https://cdnjs.cloudflare.com/ajax/libs/monaco-editor/0.44.0/min/vs',
            'monaco-vim': 'https://unpkg.com/monaco-vim/dist/monaco-vim',
            // 'monaco-yaml': '/monaco-yaml/monaco-yaml',
          },
        });
        window.require(['vs/editor/editor.main', 'monaco-vim'], async function (a, MonacoVim) {
          window.MonacoEnvironment = {
            getWorker(moduleId, label) {
              switch (label) {
                // Handle other cases
                case 'yaml':
                  return new Worker('http://localhost:9800/monaco-yaml/monaco-yaml/yaml.worker.js');
                default:
                  throw new Error(`Unknown label ${label}`);
              }
            },
          };

          ideLoadingStatus = true;
          const monaco = window.monaco;
          // window.eppy = await window.loadPyodide({ indexURL: 'https://cdn.jsdelivr.net/pyodide/v0.24.1/full/' });
          window.MonacoVim = MonacoVim;
          // MonacoYaml.configureMonacoYaml(monaco);
          // await installBlack(window.eppy);
          monaco.languages.registerDocumentFormattingEditProvider('python', {
            provideDocumentFormattingEdits(model, options, token) {
              // Format the document here
              const formatted = formatCode(model.getValue());
              // Return the edits as an array
              return [
                {
                  range: model.getFullModelRange(),
                  text: formatted,
                },
              ];
            },
          });
          allCallbacks.forEach((callback) => callback());
        });
      },
    },
  );
}

function formatCode(codeRaw) {
  //   const code = window.btoa(codeRaw);
  //   const formatted = window.eppy.runPython(`
  // code = b64decode("${code}").decode("utf-8")
  // try:
  //   code = black.format_str(code, mode=black.Mode())
  // except Exception as e:
  //   code
  // code
  //   `);
  //   return formatted;
}

async function installBlack(eppy) {
  //   await eppy.loadPackage('micropip');
  //   eppy.runPythonAsync(`
  // import micropip
  // await micropip.install("https://files.pythonhosted.org/packages/28/c7/150de595f9e5ee1efffeb398acfac3e37d218171100049c77e494326dc4b/black-23.9.1-py3-none-any.whl")
  // import black
  // from base64 import b64decode
  // 		`);
}

const formatPythonIndent = (value, indent = 4) => {
  const regex = new RegExp(' '.repeat(indent), 'g');
  return value.replace(regex, '\t');
};

export const MonacoEditor = React.forwardRef(function MonacoEditor(props, ref) {
  const { value, language, ...rest } = props;
  const targetRef = React.useRef(null);
  const [editor, setEditor] = React.useState(null);
  const editorRef = React.useRef({ vimMode: null });

  React.useEffect(() => {
    loadMonaco(() => {
      if (rest.suggestions) {
        window.monaco.languages.registerCompletionItemProvider(language, {
          provideCompletionItems: (model, position) => {
            const word = model.getWordUntilPosition(position);
            const range = {
              startLineNumber: position.lineNumber,
              endLineNumber: position.lineNumber,
              startColumn: word.startColumn,
              endColumn: word.endColumn,
            };

            return {
              suggestions: rest.suggestions.map((suggestion) => {
                return {
                  ...suggestion,
                  kind: window.monaco.languages.CompletionItemKind[suggestion.kind || 'Function'],
                  range,
                  ...(suggestion.insertTextRules
                    ? {
                        insertTextRules:
                          window.monaco.languages.CompletionItemInsertTextRule[suggestion.insertTextRules],
                      }
                    : {}),
                };
              }),
            };
          },
        });

        if (language === 'python') {
          window.monaco.languages.registerDefinitionProvider('python', {
            provideDefinition: (model, position, token) => {
              const { word } = model.getWordAtPosition(position);
              let matches = model.findMatches(new RegExp(`${word}\\s*=`), true, true, true, null, true);
              if (matches?.length == 0) {
                matches = model.findMatches(new RegExp(`def ${word}\\s*\\(`, 'gi'), true, true, true, null, true);
              }

              if (
                rest.suggestions?.some((el) => el.label?.label === word && el.label?.detail === ' - self') &&
                matches?.length > 0
              ) {
                return {
                  uri: model.uri,
                  range: matches[0].range,
                };
              }
            },
          });
        }
      }
      const editor = window.monaco.editor.create(targetRef.current, {
        value,
        language,
        ...rest,
      });
      setEditor(editor);

      // add action "Toggle vim" to the editor
      editor.addAction({
        id: 'toggleVim',
        label: 'Toggle Vim',
        run: function (ed) {
          if (editorRef.current.vimMode) {
            editorRef.current.vimMode.dispose();
            editorRef.current.vimMode = null;
          } else {
            editorRef.current.vimMode = window.MonacoVim.initVimMode(editor);
          }
        },
      });

      if (language == 'python') {
        editor.addAction({
          id: 'formatIndent',
          label: 'Format Indent',
          contextMenuGroupId: 'control',
          run(IEditor) {
            const model = IEditor.getModel();
            if (model) {
              const formatted = formatPythonIndent(model.getValue(), model.getOptions().indentSize);
              model.setValue(formatted);
            }
          },
        });
      }

      // provide language suggestion
      // monaco.languages.registerCompletionItemProvider(language, {
      //   provideCompletionItems: () => {
      //     return {
      //       suggestions: [
      //         {
      //           label: 'Hello',
      //           kind: monaco.languages.CompletionItemKind.Keyword,
      //           insertText: 'Hello',
      //         },
      //         {
      //           label: 'World',
      //           kind: monaco.languages.CompletionItemKind.Function,
      //           insertText: 'World',
      //         },
      //       ],
      //     };
      //   },
      // });

      let previousValue = editor.getValue();
      const listener = editor.onDidChangeModelContent(() => {
        const currentValue = editor.getValue();
        if (currentValue !== previousValue) {
          props.onCodeChange(currentValue);
          previousValue = currentValue;
        }
      });

      return () => {
        listener.dispose();
        editor.dispose();
      };
    });
  }, []);

  React.useEffect(() => {
    if (rest.theme && editor) {
      editor._themeService.setTheme(rest.theme);
    }
  }, [editor, rest.theme]);

  React.useImperativeHandle(ref, () => ({
    ...editor,
    getValue: () => editor.getValue(),
    setValue: (value) => editor.setValue(value),
    formatCode: () => {
      //   const encodedCode = window.btoa(editor.getValue());
      //   const code = window.eppy.runPython(`
      // 	code = b64decode("${encodedCode}").decode("utf-8")
      // 	try:
      // 		code = black.format_str(code, mode=black.Mode())
      // 	except Exception as e:
      // 		code = str(e)
      // 	code
      // `);
      //   editor.setValue(formatCode(code));
    },
    replaceSpaces: () => {
      const value = editor.getValue();
      console.log('12345', { value });
    },
    getAction: (...args) => editor.getAction(...args),
  }));

  React.useEffect(() => {
    if (editor) {
      editor.setValue(value || '');
    }
  }, [value, editor]);

  return <div ref={targetRef} style={{ width: '100%', height: '100%' }} />;
});
