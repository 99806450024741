import produce from 'immer';
import { createDataQueryFromConfig } from '../../backbone/table-backbone';
import { pageShowNotify, pageDismissNotify } from '../../util/browser-page';

export async function getSelectedAdObjects({
  currentWorkflow: workflow,
  marketplace = 'LAZADA',
  hiddenFilter = {},
  compactTableConfig,
  originalRows: rows,
  productIdField,
  adObjectNameField,
}) {
  if (rows.length === 0) return [];
  // const productFilters = rows
  //   .map((i) => ({
  //     field: productIdField,
  //     dataType: 'integer',
  //     operator: '=',
  //     value: i[productIdField],
  //   }))
  //   .filter((i) => i.value);
  // if (productFilters.length === 0) return [];

  let complementProducts = rows;

  const selectedAdObjects: any[] = await workflow.request('filterSelectedItems', {
    rows: rows.map((r) => {
      const fr = complementProducts.find((i) => i[productIdField] === r[productIdField]);
      return {
        ...fr,
        ...r,
      };
    }),
    idField: productIdField,
    keyTuple: [productIdField, adObjectNameField, 'ADS_OBJECT.id'],
  });

  return selectedAdObjects;
}

export async function getSelectedAdObjectKeywords({
  input,
  selectedRows,
  masterObjectField,
  workflow,
  marketplace = 'LAZADA',
  hiddenFilters = null,
}) {
  if (selectedRows.length === 0) return [];

  const selectedProductId = selectedRows.map((i) => i[masterObjectField]);

  const datasource = await workflow.request('dataQueryFromMainTable', {
    campaignId: input.campaignId,
    schedule: input.schedule,
  });

  let selectedAdObjectsKeywords = await datasource.query({
    filter: [
      {
        type: 'filter',
        logicalOperator: 'and',
        field: 'MARKETPLACE.marketplace_code',
        dataType: 'string',
        queryValue: marketplace,
        queryType: 'is',
      },
    ],
    dateRange: {
      dateFrom: input.schedule.dateFrom,
      dateTo: input.schedule.dateTo,
    },
    pageSize: 9999,
    hiddenFilter: {
      combinator: 'AND',
      filters: [
        { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: input.campaignId },
        {
          field: masterObjectField,
          operator: 'in',
          dataType: 'integer',
          value: selectedProductId.filter((el) => el !== null),
        },
      ].concat(hiddenFilters ?? []),
    },
  });

  const adObjectNameField = 'ADS_OBJECT.name';
  const keywordIdField = 'SUGGESTED_KEYWORD.id';
  const keywordNameField = 'SUGGESTED_KEYWORD.name';
  const suggestedKeywordAdObjectName = 'SUGGESTED_KEYWORD.product_name';
  const suggestedKeywordAdObjectId = 'SUGGESTED_KEYWORD.master_object_id';
  const productIdField = 'PRODUCT.id';
  const complementAdObjKeywords = selectedAdObjectsKeywords.rowData.map((i) => {
    return {
      ...i,
      [suggestedKeywordAdObjectName]: i[adObjectNameField],
      [suggestedKeywordAdObjectId]: i[productIdField],
      [keywordIdField]: i['ADS_PLACEMENT.id'],
      [keywordNameField]: i['ADS_PLACEMENT.name'],
    };
  });

  return complementAdObjKeywords;
}

export async function compactTableKeywordConfig(
  { compactTableConfig },
  rowTarget,
  backbone,
  context: { input; workflow; marketplace?, masterObjectField?, hiddenFilters? },
) {
  let { input, workflow, marketplace } = context;
  let selectedRows = backbone.getSelectedRows();
  if (selectedRows.length === 0) {
    selectedRows = [rowTarget];
  }

  const masterObjectField = context.masterObjectField ?? 'PRODUCT.id';
  const selectedProductId = selectedRows.map((i) => i[masterObjectField]);

  // pageShowNotify('Loading related keywords, please wait for a moment...');

  // const complementAdObjKeywords = await getSelectedAdObjectKeywords({
  //   input,
  //   selectedRows,
  //   masterObjectField,
  //   workflow,
  //   marketplace,
  //   hiddenFilters: context.hiddenFilters,
  // });
  const complementAdObjKeywords = []

  // pageDismissNotify();

  return produce(compactTableConfig, (draft) => {
    draft.config.addons = compactTableConfig.config.addons
      ? {
          ...compactTableConfig.config.addons,
          'compact.getInitSelectedRows': () => complementAdObjKeywords,
          'compact.getPrimaryKeys': () => {
            return ['SUGGESTED_KEYWORD.name', 'SUGGESTED_KEYWORD.master_object_id'];
          },
        }
      : {
          'compact.getInitSelectedRows': () => complementAdObjKeywords,
          'compact.getPrimaryKeys': () => {
            return ['SUGGESTED_KEYWORD.name', 'SUGGESTED_KEYWORD.master_object_id'];
          },
        };
    draft.selectedRows = [];
    draft.config.id = 'compact_table_keyword_' + selectedProductId.join('_');
    draft.config.configuration.hiddenFilter = {
      combinator: 'AND',
      filters: [
        {
          field: masterObjectField,
          operator: 'in',
          dataType: 'integer',
          value: ff.can_not_add_keywork ? selectedProductId.filter((el) => el !== null) : selectedProductId,
        },
      ],
    };
    draft.config.configuration.requestHiddenField = {
      attribute: [masterObjectField],
    };
  });
}
