import * as React from 'react';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
}));

export const withWrapperFormat = (Component) => {
  return (props) => {
    const classes = useStyles();
    const value = get(props, ['value'], null);

    const [isHovering, setIsHovering] = React.useState(false);
    const [isClicked, setIsClicked] = React.useState(false);

    const onComponentClose = () => {
      setTimeout(() => {
        setIsClicked(false);
      }, 100);
    };

    const label = React.useMemo(() => {
      let defaultLabel = value;
      if (!value) defaultLabel = '';
      else if (Array.isArray(value)) {
        const length = value.length;
        defaultLabel = length === 0 ? '' : `${length} propert${length > 1 ? 'ies' : 'y'}`;
      } else if (typeof value === 'object') {
        const length = Object.keys(value).length;
        defaultLabel = length === 0 ? '' : `${length} propert${length > 1 ? 'ies' : 'y'}`;
      }
      if (!props.labelFormula) return defaultLabel;
      return toValue(props.labelFormula, props);
    }, [value]);

    return (
      <div
        className={classes.container}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => setIsClicked(true)}
      >
        {isHovering || isClicked ? <Component {...props} onClose={onComponentClose} /> : <span>{label}</span>}
      </div>
    );
  };
};
