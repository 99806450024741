// Swiper lib
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import * as React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Keyboard } from 'swiper';
import { Avatar, Box, Popover, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  avatar: {
    transition: 'transform .2s',
    width: '30px',
    height: '30px',
    fontSize: '10px',
    border: '1px solid #CCE3F5',
    background: '#EBF6FF',
    color: '#000',
    marginRight: '8px',
    borderRadius: '4px',
    cursor: 'pointer',
    zIndex: 999999,
    // '&:hover': {
    //   border: 'none',
    //   transform: 'scale(10)',
    // },
  },
  swiper: {
    '--swiper-theme-color': '#006EC6',
    width: '100%',
    height: '100%',
    '& .swiper-wrapper': {
      width: '400px',
      minHeight: '200px',
      '& .swiper-slide': {
        textAlign: 'center',
        fontSize: '14px',
        background: '#fff',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '& img': {
          display: 'block',
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        },
      },
    },
    '& .swiper-pagination': {
      position: 'static',
    },
    '& .swiper-button-next, & .swiper-button-prev': {
      marginTop: '-40px',
    },
  },
  imageUrl: {
    textAlign: 'left',
    width: '100%',
    marginTop: '4px',
    '& a': {
      color: '#8C98A4',
    },
  },
}));

export function FormatAvatar({ avatar: avatarStr }: { avatar: string }) {
  const classes = useStyles();
  const imageUrls = avatarStr.split(',');

  const [sliderAnchorEl, setSliderAnchorEl] = React.useState(null);

  const handleOpenSlider = (e) => {
    setSliderAnchorEl(e.target);
  };

  const handleCloseSlider = () => {
    setSliderAnchorEl(null);
  };

  const imagesRender = React.useMemo(() => {
    return imageUrls.map((url, index) => {
      return (
        <SwiperSlide key={url + index}>
          <img src={url} loading="lazy" />
          <Box className={classes.imageUrl}>
            <a href={url} target={'_blank'} rel="noreferrer">
              {url}
            </a>
          </Box>
        </SwiperSlide>
      );
    });
  }, [avatarStr]);

  if (!avatarStr) return null;

  return (
    <>
      <Avatar src={imageUrls[0]} className={classes.avatar} onClick={handleOpenSlider} />
      <Popover
        onClose={handleCloseSlider}
        open={Boolean(sliderAnchorEl)}
        anchorEl={sliderAnchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Swiper
          navigation={true}
          loop={true}
          pagination={{
            clickable: true,
          }}
          keyboard={{
            enabled: true,
          }}
          modules={[Navigation, Pagination, Keyboard]}
          className={classes.swiper}
        >
          {imagesRender}
        </Swiper>
      </Popover>
    </>
  );
}
