import { EventEmitter } from 'events';
import React from 'react';
import { DiscreteChart } from '../../../chartlib/discrete-line-chart/discrete-line-chart.stories';

class InsightChart implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <DiscreteChart />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <BlockConfigForm data={data} onSubmit={handleSubmit} />;
  }
}

function BlockConfigForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Block configuration goes here.</h2>;
}

export default {
  blockType: 'insight-chart',
  label: 'Insight Chart',
  blockComponent: InsightChart,
  systemConfig: {},
  layout: {
    w: 120,
    h: 20,
  },
};
