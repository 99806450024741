import { aim, eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import React, { useEffect } from 'react';
import { apm, retryTillSuccess } from '@eip/next/lib/main';
import { once } from 'lodash';
import { AUTH0_LOGOUT_URI, getAuth0 } from '../global';
import { useHistory } from 'react-router';

const { API_AUTHENTICATION } = EIP_CONSTANT.API_HOST;

declare global {
  interface Window {
    FS: any;
    ChurnZero: any;
    fcWidget: any;
    Canny: any;
  }
}

// FIXME: temporary fix on circular dependencies on library
async function eo2Logout() {
  // await request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout/browser').then((token) => {
  //   const { logout_url: url } = token;
  //   const logoutUrl = new URL(url);
  //   const logoutToken = logoutUrl.searchParams.get('token');
  //   return request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout?token=' + logoutToken);
  // });

  return await request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout/browser').then((token) => {
    const { logout_url: url } = token;
    const logoutUrl = new URL(url);
    const logoutToken = logoutUrl.searchParams.get('token');
    // return request.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/logout?token=' + logoutToken);
    return url;
  });
}

let auth0Logout;
if (ff.auth0_next) {
  auth0Logout = async function auth0Logout() {
    return getAuth0().then(async (auth0) => {
      const result = await auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
      aim.clearUserSettings();
      aim.setLoginToken(null);
    });
  };
}

const request3rdTracking = once(function request3rdTracking(data: aim.UserSettings) {
  let unregisters = [];

  // const [, fs] = retryTillSuccess(async () => {
  //   if (!window.FS) {
  //     console.info('not init FS yet...');
  //     throw new Error('not init FS yet...');
  //   }
  //   window.FS.identify(data.profile.userId, {
  //     email: data.profile.userEmail,
  //     displayName: data.profile.userName,
  //   });
  //   return data;
  // }, 100);

  const [, fc] = retryTillSuccess(async () => {
    if (!window.fcWidget) {
      throw new Error('not init fcWidget yet...');
    }
    window.fcWidget.init({
      token: 'c28233de-14f0-484a-8a48-614d36de61a5',
      host: 'https://wchat.freshchat.com',
      externalId: data.profile.userId,
    });

    window.fcWidget.user.setProperties({
      email: data.profile.userEmail,
      firstName: data.profile.userName,
    });
    return data;
  }, 110);

  function notuse() {
    const [, churnzero] = retryTillSuccess(async () => {
      if (!window.ChurnZero) {
        throw new Error('not init ChurnZero yet...');
      }
      window.ChurnZero.push(['setAppKey', '1!I83pMDcrNxkmZgz7Ck2ablanFBEKyj38eFtIn6KAw08tD84']);
      window.ChurnZero.push(['setContact', data.profile.userId, data.profile.userEmail]);
      return data;
    }, 120);
  }

  const [, mixpanel] = retryTillSuccess(async () => {
    if (!window.mixpanel) {
      throw new Error('not init mixpanel yet...');
    }
    const mx: any = window.mixpanel;
    mx.identify(data.profile.userId);
    mx.people.set({
      email: data.profile.userEmail,
      company: data.profile.userCompanyName,
      fullname: data.profile.userName,
      Name: data.profile.userName,
    });
    return data;
  }, 120);

  const [, _1flow] = retryTillSuccess(async () => {
    if (!window._1flow) {
      throw new Error('not init 1flow yet...');
    }
    const _1f = window._1flow;
    _1f('identify', data.profile.userId, {
      name: data.profile.userName,
      company: data.profile.userCompanyName,
      email: data.profile.userEmail,
    });

    return data;
  }, 120);

  const [, freshpaint] = retryTillSuccess(async () => {
    if (!window.freshpaint) {
      throw new Error('not init freshpaint yet...');
    }
    const fp: any = window.freshpaint;
    fp.identify(data.profile.userId, {
      name: data.profile.userName,
      company: data.profile.userCompanyName,
      email: data.profile.userEmail,
    });
    return data;
  }, 120);

  unregisters = unregisters.concat(fc, mixpanel, _1flow, freshpaint);

  return unregisters;
});

export function useAuth() {
  const [token, setToken] = React.useState<string | null>(aim.getLoginToken().token);
  const history = useHistory();

  const [userSettings, setUserSettings] = React.useState<aim.UserSettings>({
    profile: { userEmail: '-', userName: '-' },
  });

  useEffect(() => {
    setToken(aim.getLoginToken().token);
    aim.getAuthEvent().onExpired(() => {
      console.info('expired....');
      setToken(null);
      if (!history) {
        window.location.href = history.createHref({
          pathname: '/login',
          search: `?redirect_url=${encodeURIComponent(window.location.href)}`,
        });
      }
    });

    if (aim.getLoginToken().token) {
      retrieveUserProfile().then((data: aim.UserSettings) => {
        // FIXME: potential race condition, double firing api request
        if (data) {
          setUserSettings(data);
          apm.setUserContext({
            id: data.profile.userId,
            email: data.profile.userEmail,
            username: data.profile.userName,
          });
          request3rdTracking(data);
        } else {
          aim.getAuthEvent().triggerExpired();
        }
      });
    }
  }, []);

  const logout = () => {
    if (ff.auth0_next && aim.getUserSettings() && aim.getUserSettings().isAuth0) {
      auth0Logout();
    } else {
      eo2Logout()
        .then((logoutUrl: string) => {
          aim.clearUserSettings();
          aim.setLoginToken(null);
          window.location.href = logoutUrl;
        })
        .catch(() => {
          alert('Opps, please retry..');
          // aim.clearUserSettings();
          // aim.setLoginToken(null);
          // window.location.href = '/';
        });
    }
  };

  React.useEffect(() => {
    const handleReceiveToken = (event) => {
      setToken(event.detail.token);
    };

    document.addEventListener('RECEIVED_TOKEN', handleReceiveToken);

    return () => {
      document.removeEventListener('RECEIVED_TOKEN', handleReceiveToken);
    };
  }, []);

  return { token, userSettings, logout };
}

async function retrieveUserProfile() {
  const userSettings = aim.getUserSettings();

  return Promise.resolve(userSettings);
}
