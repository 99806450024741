import { eipRequest as request, EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { createDataQueryFromConfig, getAPIRequest } from '@ep/insight-ui/system/backbone/table-backbone';
import { createWorkflowCampaignDetails } from '@ep/insight-ui/system/backbone/workflow-backbone';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import produce from 'immer';
import {
  cloneDeep,
  difference,
  differenceWith,
  flatten,
  get,
  isEqual,
  merge,
  omit,
  uniq,
  uniqBy,
  uniqueId,
} from 'lodash';
import qs from 'qs';
import { getCampaignSettingInfo, publishDimensions } from '../legacy/api-request-campaign-details';
import {
  CAMPAIGN_DETAIL_CONFIG,
  COMPACT_AD_TOOL_CONFIG,
  COMPACT_KEYWORD_CONFIG,
  COMPACT_PROMOTED_OBJECT_CONFIG,
} from './table-config';
import { compactTableKeywordConfig } from '../util';

const COMPACT_TABLE_CONFIG = {
  ad_tool: { ...COMPACT_AD_TOOL_CONFIG, tableId: 'ad_tool_compact' },
  keyword: { ...COMPACT_KEYWORD_CONFIG, tableId: 'keyword_compact' },
  promoted_object: { ...COMPACT_PROMOTED_OBJECT_CONFIG, tableId: 'promoted_object' },
};

const CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY = {
  ...CAMPAIGN_DETAIL_CONFIG,
  primaryKeys: [...CAMPAIGN_DETAIL_CONFIG.primaryKeys, 'eipCustomRowId'],
};

const log = useLog('chartlib:table-campaign-detail');
const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

const endpoint = {
  GET_CAMPAIGN_SETTING: API_URL + '/mop-query/listing/getCampaignData',
  GET_CAMPAIGN_INSIDE_DATA: API_URL + '/mop-query/listing/getCampaignInsideData',
  CREATE_DIMENSION: API_URL + '/ads-operation/create-dimension',
  UPDATE_DIMENSION: API_URL + '/ads-operation/update-dimension',
  MASS_CREATE_DIMENSION: API_URL + '/ads-operation/mass-create-dimension',
  MASS_UPDATE_DIMENSION: API_URL + '/ads-operation/mass-update-dimension',
  MASS_DELETE_DIMENSION: API_URL + '/ads-operation/mass-delete-dimension',
  REQUEST_CUSTOM_KEYWORD: API_URL + '/ads-operation/filter-dimension',
  MASS_UPSERT_DIMENSION: API_URL + '/ads-operation/mass-upsert-dimension',
};

const DEFAULT_VALUE = {
  MATCH_TYPE: 'EXACT_MATCH',
  BIDDING_PRICE: 450,
};

interface autoAdsParams {
  adsObjectId: number;
  marketplaceCode: string;
}

interface autoBiddingParams {
  id: number;
  bidding_price: number | null;
  marketplaceCode: string;
}

export function getWorkflow() {
  let lastKeyword = '';
  const wf = createWorkflowCampaignDetails(
    {},
    {
      async full_tableConfig(input: Record<string, any>, workflow) {
        const ref: any = {};
        const tableConfig: Record<string, any> = merge(cloneDeep(CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY), {
          mapping: {
            ad_tool: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_tool' }),
            },
            keyword: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', {
                column: 'keyword',
                campaignId: input.campaignId,
              }),
            },
            promoted_object: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', {
                column: 'promoted_object',
              }),
            },
          },
        });

        const excludeColumns = ['storefront_ad_object', 'landing_page'];
        tableConfig.mapping = omit(tableConfig.mapping, excludeColumns);

        const config = {
          apiRequest: await workflow.request('eTableApiRequest', {
            campaignId: input.campaignId,
            schedule: input.schedule,
          }),
          id: uniqueId('ep_campaign_detail_'),
          configuration: tableConfig,
          callback: {
            onBackboneReady: (backbone) => {
              ref.backbone = backbone;
              workflow.set('mainTableBackbone', backbone);
              backbone.changeConfig('dateRange', input.schedule);
            },
            onRowRemove: async (type, rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              let selectedRows = [];
              let reloadTable = false;
              switch (type) {
                case 'keyword':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['SUGGESTED_KEYWORD.id'] !== rows['SUGGESTED_KEYWORD.id']);

                  res = await workflow.applyUpdate('syncRemoveProductKeywords', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                  } else {
                    error = res.error;
                  }
                  reloadTable = res.reloadTable;
                  break;
                case 'status':
                  res = await workflow.request('deleteStatusRow', {
                    id: rowTarget['ADS_PLACEMENT.id'] || rowTarget['ADS_OBJECT.id'],
                    dimension: rowTarget['ADS_PLACEMENT.id'] ? 'ADS_PLACEMENT' : 'ADS_OBJECT',
                    marketplaceCode: rowTarget['MARKETPLACE.marketplace_code'],
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                case 'promoted_object':
                default:
                  updatedRows = rows;
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, item.message], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (reloadTable) {
                // stateDispatch.current({ type: type, payload: { selectedItems: updatedRows, rowTarget } });
                // onChange(updatedRows, type);
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
            onAddAutoAds: async ({ adsObjectId, marketplaceCode }: autoAdsParams, backbone) => {
              const params = {
                data: [
                  {
                    automated_ads: 1,
                    adsObjectId,
                    status: 'DRAFT',
                    type: 'SEARCH_RESULT_PLACEMENT',
                  },
                ],
                marketplaceCode,
              };
              const response: any = await workflow.request('createAdsPlacements', params);
              if (response && response.success) {
                backbone.reloadData('table');
              } else {
                const errors = response.data.reduce((a, b) => (b.success ? a : [...a, ...Object.values(b.error)]), []);
                if (errors.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errors),
                  });
                }
              }
            },
          },
          addons: {
            'compactTable.keyword.config': async ({ compactTableConfig }, rowTarget, backbone) => {
              let selectedRows = backbone.getSelectedRows();
              if (selectedRows.length === 0) {
                selectedRows = [rowTarget];
              }

              const masterObjectField = 'STOREFRONT.id';
              const selectedProductId = selectedRows.map((i) => i[masterObjectField]);
              // return produce(compactTableConfig, (draft) => {
              //   const selectedProductId = selectedRows.map((i) => i[masterObjectField]);
              //   draft.config.id = 'compact_table_keyword_' + selectedProductId.join('_');
              //   draft.config.configuration.hiddenFilter = {
              //     combinator: 'AND',
              //     filters: [
              //       {
              //         field: masterObjectField,
              //         operator: 'in',
              //         dataType: 'integer',
              //         value: selectedProductId,
              //       },
              //     ],
              //   };
              //   draft.config.configuration.requestHiddenField = {
              //     attribute: [masterObjectField],
              //   };
              // });

              const hiddenFilters = ([]).concat([
                {
                  field: masterObjectField,
                  operator: 'in',
                  dataType: 'integer',
                  value: selectedProductId,
                },
              ]);

              const config = compactTableKeywordConfig({ compactTableConfig }, rowTarget, backbone, {
                input,
                workflow,
                marketplace: 'TOKOPEDIA',
                masterObjectField,
                hiddenFilters,
              });


              return config;
            },
            'datasource.getRowsParams': async ({ params }, config) => {
              const ignoreFields = ['keyword', 'ad_object', 'ad_tool', 'context', 'promoted_object'];
              params.attributes = params.attributes.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              params.dimensions = params.dimensions.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              const aggregations = get(params, 'groupBy.aggregations', []);
              if (aggregations.length > 0) {
                params.groupBy.aggregations = aggregations.filter((i) => {
                  return ignoreFields.indexOf(i.field) === -1;
                });
              }
              return params;
            },
          },
        };
        return config;
      },
      async compact_tableConfig({ column: type, campaignId }, workflow) {
        const tableConfig = COMPACT_TABLE_CONFIG[type];
        const finalConfig: RecordValue = {
          tableCompactId: tableConfig.tableId,
          configuration: tableConfig,
          // apiRequest: {
          //   getTableData: () => Promise.resolve(COMPACT_TABLE_RES[type]),
          // },
          submitRowSelectionOnClosed: true,
          callback: {
            onRowSelect: async (rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              switch (type) {
                case 'keyword': {
                  updatedRows = rows;
                  res = await workflow.applyUpdate('syncUpdateCampaignKeywords', {
                    updateType: 'default',
                    value: rows,
                    target: [rowTarget],
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                case 'promoted_object': {
                  res = await workflow.applyUpdate('syncUpdatePromotedObject', {
                    updateType: 'default',
                    target: rowTarget,
                    value: rows,
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                default:
                  updatedRows = rows;
              }

              if (updatedRows) {
                workflow.emit('onChange', { updatedRows, type });
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, ...Object.values(item.error)], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (updatedRows.length > 0) {
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
        };
        log('Compact table config', { type, finalConfig });

        let searchField = '';
        switch (type) {
          case 'keyword':
            searchField = 'SUGGESTED_KEYWORD.name';
            break;
          case 'promoted_object':
            searchField = 'PRODUCT';
            break;
        }

        finalConfig.addons = {
          ...(COMPACT_TABLE_CONFIG[type]?.addons || {}),
          'datasource.getRowsParams': async ({ params }, config) => {
            if (type === 'keyword') {
              const searchValue = config.search;
              if (lastKeyword !== searchValue) {
                lastKeyword = searchValue;
                const context = get(config, 'system.context');
                await workflow.request('queryCustomKeyword', {
                  marketcode: get(context, ['MARKETPLACE.marketplace_code']),
                  keywords:
                    !searchValue || String(searchValue).trim() === ''
                      ? ['']
                      : String(searchValue)
                        .split('\n')
                        .filter((i) => String(i).trim() !== ''),
                  productId: get(context, ['PRODUCT.id']),
                  storefrontId: get(context, ['STOREFRONT.id']),
                  toolCode: get(context, ['ADTOOL.ads_tool']),
                  masterObjectList: get(context, ['ADS_OBJECT.promoted_objects']),
                });

                if (lastKeyword !== searchValue) {
                  return null;
                }
              }
            }

            const context = get(config, 'system.context');
            const campaignInfo = await getCampaignSettingInfo({ campaignId, marketplaceCode: 'TOKOPEDIA' });
            const getStorefrontId = get(context, 'STOREFRONT.id');
            const storefrontIdFilter = {
              field: 'STOREFRONT.id',
              dataType: 'string',
              operator: 'is',
              value: getStorefrontId,
            };
            const tokoFilter = {
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              operator: 'is',
              value: 'TOKOPEDIA',
            };
            if (!params.filter) {
              type === 'ad_tool'
                ? (params.filter = {
                  combinator: 'AND',
                  filters: [tokoFilter, storefrontIdFilter],
                })
                : (params.filter = {
                  combinator: 'AND',
                  filters: [tokoFilter],
                });
            } else if (
              !params.filter.filters.some((el) => Object.keys(tokoFilter).every((ele) => el[ele] === tokoFilter[ele]))
            ) {
              type === 'ad_tool'
                ? (params.filter = {
                  combinator: 'AND',
                  filters: [tokoFilter, params.filter, storefrontIdFilter],
                })
                : (params.filter = {
                  combinator: 'AND',
                  filters: [tokoFilter, params.filter],
                });
            }
            if (type == 'keyword')
              params.hiddenFilter = produce(params.hiddenFilter, (draft) => {
                draft.filters = [
                  {
                    field: 'STOREFRONT.id',
                    operator: 'in',
                    value: [campaignInfo['STOREFRONT.id']],
                    dataType: 'integer',
                  },
                  {
                    field: 'PRODUCT.id',
                    operator: 'in',
                    value: get(context, ['ADS_OBJECT.promoted_objects'])
                      ? ff.safe_json_parse
                        ? safeJsonParse(get(context, ['ADS_OBJECT.promoted_objects']), [])
                        : JSON.parse(get(context, ['ADS_OBJECT.promoted_objects']))
                      : [],
                    dataType: 'integer',
                  },
                  {
                    field: 'ADTOOL.ads_tool',
                    operator: 'is',
                    value: get(context, ['ADTOOL.ads_tool']),
                    dataType: 'string',
                  },
                ];
              });
            if (config.search) {
              return produce(params, (draft) => {
                const keywords = config.search
                  .split('\n')
                  .filter((i) => String(i).trim() !== '');
                const searchFilter = {
                  combinator: 'OR',
                  filters: keywords
                    .map((i) => {
                      return {
                        field: searchField,
                        dataType: 'string',
                        operator: 'contains',
                        value: String(i).trim(),
                      };
                    }),
                };

                if (!params.filter) {
                  draft.filter = [tokoFilter, searchFilter];
                } else {
                  draft.filter.filters = [...draft.filter.filters, searchFilter];
                }
              });
            }

            return params;
          },
        };

        if (type === 'keyword') {
          finalConfig.addons['config.search'] = async ({ value }, config) => {
            const context = get(config, 'system.context');
            const result: any = await workflow.request('queryCustomKeyword', {
              marketcode: get(context, ['MARKETPLACE.marketplace_code']),
              keywords: String(value)
                .split('\n')
                .filter((i) => String(i).trim() !== ''),
              productId: get(context, ['PRODUCT.id']),
              storefrontId: get(context, ['STOREFRONT.id']),
              toolCode: get(context, ['ADTOOL.ads_tool']),
              masterObjectList: get(context, ['ADS_OBJECT.promoted_objects']),
            });

            if (result.success) {
              return Promise.resolve({ value });
            } else {
              return Promise.reject({ value });
            }
          };
        }

        if (type == 'promoted_object') {
        }

        return finalConfig;
      },
    },
    {
      eTableApiRequest: async ({ campaignId, schedule }, workflow) => {
        let apiRequest;
        const endpoint = CAMPAIGN_DETAIL_CONFIG.endpoint;
        apiRequest = getAPIRequest(endpoint);
        const originGetTableData = apiRequest.getTableData;
        apiRequest = {
          ...apiRequest,
          getTableData: (params) => {
            let hiddenFilter = params.hiddenFilter;
            if (!hiddenFilter) {
              hiddenFilter = {
                combinator: 'AND',
                filters: [],
              };
            }

            hiddenFilter = produce(hiddenFilter, (draft) => {
              draft.combinator = 'AND';
              draft.filters = (hiddenFilter.filters || []).concat({
                field: 'ADS_CAMPAIGN.id',
                dataType: 'integer',
                operator: '=',
                value: campaignId,
              });
            });

            params.sort = params.sort
              ? [].concat(params.sort)
              : [
                { field: 'ADS_PLACEMENT.created_at', sort: 'DESC' },
                { field: 'ADS_OBJECT.created_at', sort: 'DESC' },
                { field: 'ADS_PLACEMENT.updated_at', sort: 'DESC' },
                { field: 'ADS_OBJECT.updated_at', sort: 'DESC' },
              ];

            const tokoFilter = {
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              operator: 'is',
              value: 'TOKOPEDIA',
            };
            if (!params.filter) {
              params.filter = {
                combinator: 'AND',
                filters: [tokoFilter],
              };
            } else if (
              !params.filter.filters.some((el) => Object.keys(tokoFilter).every((ele) => el[ele] === tokoFilter[ele]))
            ) {
              params.filter.filters.push(tokoFilter);
            }

            return originGetTableData({ ...params, hiddenFilter }).then(async (res) => {
              const rows = res.data.rows;
              let result = null;
              if (rows.length === 0) {
                const campaignInfo = await getCampaignSettingInfo({ campaignId, marketplaceCode: 'TOKOPEDIA' });
                const addAdsObjectParam = {
                  marketplaceCode: 'TOKOPEDIA',
                  data: [
                    {
                      masterObjectId: campaignInfo['STOREFRONT.id'],
                      masterObjectType: undefined,
                      adsCampaignId: campaignId,
                      totalBudget: 0,
                      dailyBudget: 0,
                      adsOpsAdsCalendars: [],
                    },
                  ],
                };

                const createAdObjectRes = await workflow.request('createAdsObjects', addAdsObjectParam);
                if (createAdObjectRes.success) {
                  // // after create ad-object, fetch data main-table
                  const retryLoadData = async (resolve, reject) => {
                    const res = await originGetTableData({ ...params, hiddenFilter });
                    if (res.data.rows.length === 0) {
                      setTimeout(() => {
                        retryLoadData(resolve, reject);
                      }, 1000);
                    } else {
                      const rows = res.data.rows;
                      resolve({
                        ...res,
                        data: {
                          ...res.data,
                          rows: await workflow.request('transformCellCompactSelections', {
                            rows,
                            campaignDateFrom: schedule.dateFrom,
                            campaignDateTo: schedule.dateTo,
                          }),
                        },
                      });
                    }
                  };

                  const loadData = () => {
                    return new Promise((resolve, reject) => {
                      return retryLoadData(resolve, reject);
                    });
                  };
                  await loadData().then((res) => {
                    result = res;
                  });
                }
              } else {
                result = {
                  ...res,
                  data: {
                    ...res.data,
                    rows: await workflow.request('transformCellCompactSelections', {
                      rows,
                      campaignDateFrom: schedule.dateFrom,
                      campaignDateTo: schedule.dateTo,
                    }),
                  },
                };
              }
              return result;
            });
          },
        };
        return apiRequest;
      },
      transformCellCompactSelections: async (
        { rows, campaignDateFrom: campaignFrom, campaignDateTo: campaignTo },
        workflow,
      ) => {
        const toolIdField = 'ADTOOL.ads_tool';
        const toolNameField = 'ADTOOL.ads_tool_name';
        const productNameField = 'PRODUCT.name';
        const keywordIdField = 'SUGGESTED_KEYWORD.id';
        const keywordNameField = 'SUGGESTED_KEYWORD.name';
        const productIdField = 'STOREFRONT.id';
        const promotedObjectsField = 'ADS_OBJECT.promoted_objects';

        rows.forEach((r) => {
          r[keywordIdField] = r['ADS_PLACEMENT.id'];
          r[keywordNameField] = r['ADS_PLACEMENT.name'];
        });

        const selectedAdTools: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: toolIdField,
          keyTuple: [toolIdField, toolNameField],
        });
        const selectedAdObjects: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: productIdField,
          keyTuple: [productIdField, productNameField, 'ADS_OBJECT.id'],
        });
        // const selectedKeywords = filterSelectedItems(rows, keywordIdField, keywordNameField);

        const groupedKeywordsByProduct = selectedAdObjects.reduce((acc, adObject) => {
          const productId = adObject[productIdField];
          const keywords = rows
            .filter((r) => r[keywordIdField] && r[productIdField] === productId)
            .map((r) => ({
              master_object_id: r[productIdField],
              [keywordIdField]: r[keywordIdField],
              [keywordNameField]: r[keywordNameField],
              [promotedObjectsField]: r[promotedObjectsField],
            }));
          return {
            ...acc,
            [productId]: keywords,
          };
        }, {});

        rows.forEach((r) => {
          const productId = r[productIdField];
          const selectedKeywords = groupedKeywordsByProduct[productId] || [];
          r.eipCustomRowId = uniqueId('eip_row_');
          r.ad_tool = {
            selectedItems: selectedAdTools,
            currentFocusItem: selectedAdTools.find((i) => i[toolIdField] === r[toolIdField]),
            displayField: toolNameField,
            singleSelection: true,
          };
          r.keyword = {
            selectedItems: selectedKeywords,
            currentFocusItem: selectedKeywords.find((i) => i[keywordIdField] === r[keywordIdField]),
            displayField: keywordNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: productIdField,
                  operator: '=',
                  dataType: 'integer',
                  value: productId,
                },
              ],
            },
          };
          const promoted_objects = r[promotedObjectsField];
          r.promoted_object = {
            selectedItems: promoted_objects
              ? (ff.safe_json_parse ? safeJsonParse(promoted_objects, []) : JSON.parse(promoted_objects)).map((id) => ({
                'PRODUCT.id': id,
              }))
              : [],
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: productIdField,
                  operator: '=',
                  dataType: 'integer',
                  value: productId,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom,
                },
              ],
            },
          };
        });

        return rows;
      },
      async filterSelectedItems(
        { rows, idField, keyTuple }: { rows: any[]; idField: string; keyTuple: any[] },
        workflow,
      ) {
        return rows.reduce((acc, r) => {
          const exist = acc.find((i) => i[idField] === r[idField]);
          if (!exist && r[idField]) {
            return [
              ...acc,
              {
                ...keyTuple.reduce((carry, k) => {
                  return { ...carry, [k]: r[k] };
                }, {}),
              },
            ];
          }
          return acc;
        }, []);
      },

      createAdsObjects: (param: {
        data: {
          masterObjectId: string;
          adsCampaignId: string;
          dailyBudget: number;
          totalBudget: number;
        }[];
        marketplaceCode: string;
      }) => {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            adsOpsAdsCalendars: [],
            status: 'DRAFT',
          })),
          dimension: 'ADS_OBJECT',
        };
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      upsertAdsObjects: (param: {
        data: {
          masterObjectId: string;
          adsCampaignId: string;
          dailyBudget: number;
          totalBudget: number;
        }[];
        marketplaceCode: string;
      }) => {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
          })),
          dimension: 'ADS_OBJECT',
        };
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.post(endpoint.MASS_UPSERT_DIMENSION, reqParam);
      },
      deleteAdsObjects(param: { id: string[]; marketplaceCode: string }) {
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
          {
            ...param,
            dimension: 'ADS_OBJECT',
          },
          {
            arrayFormat: 'comma',
          },
        )}`;
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.deleteFetch(url);
      },
      async queryCustomKeyword({
        marketcode,
        toolCode,
        keywords,
        storefrontId,
        productId,
        masterObjectList,
      }: {
        toolCode: string;
        marketcode: string;
        keywords: string[];
        storefrontId: string | number;
        productId: string | number;
        masterObjectList: string;
      }) {
        return request.post(endpoint.REQUEST_CUSTOM_KEYWORD, {
          dimension: 'SUGGEST_KEYWORD',
          marketplace_code: marketcode,
          search_by: {
            tool_code: [toolCode],
            storefront_id: [storefrontId],
            master_object_id: masterObjectList
              ? ff.safe_json_parse
                ? safeJsonParse(masterObjectList, [])
                : JSON.parse(masterObjectList)
              : [],
            name: keywords,
          },
        });
      },
      createAdsPlacements(param: {
        data: {
          adsObjectId: string;
          name: string;
          matchType: string;
          biddingPrice: number;
        }[];
        marketplaceCode: string;
      }) {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            type: 'SEARCH_RESULT_PLACEMENT',
            status: 'DRAFT',
          })),
          dimension: 'ADS_PLACEMENT',
        };
        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsPlacements(param: { id: string[]; marketplacecode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_PLACEMENT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      deleteStatusRow(param: { id: string[]; marketplacecode: string; dimension: string }) {
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
          {
            ...param,
            dimension: param.dimension,
          },
          {
            arrayFormat: 'comma',
          },
        )}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      dataQueryFromMainTable: async ({ campaignId, schedule, excludedMetric = true }, workflow) => {
        const tableConfig = await workflow.getTableConfig('full', 'tableConfig', { campaignId, schedule });
        const config = tableConfig.configuration;
        if (excludedMetric) {
          config.mapping = Object.keys(config.mapping).reduce((carry, k) => {
            {
              if (config.mapping[k].propertyType !== 'metric') {
                return { ...carry, [k]: config.mapping[k] };
              }
              return carry;
            }
          }, {});
        }
        const ds = createDataQueryFromConfig({ config: config, addons: tableConfig.addons });
        return ds;
      },
      campaignStatus: async ({ campaignId, schedule }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });

        return {
          haveDraft: true,
          draftItems: [],
        };
      },
      publishCampaign: async ({ campaignId, schedule, marketplace }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });
        const results = [];

        const draftProduct = await ds.query({
          filter: [
            {
              type: 'filter',
              logicalOperator: 'and',
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              queryValue: 'TOKOPEDIA',
              queryType: 'is',
            },
          ],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_OBJECT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdObjectIds = draftProduct.rowData.map((i) => i['ADS_OBJECT.id']);
        if (draftAdObjectIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_OBJECT',
              id: draftAdObjectIds,
              marketplaceCode: marketplace,
            }),
          );
        }

        const draftKeywords = await ds.query({
          filter: [
            {
              type: 'filter',
              logicalOperator: 'and',
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              queryValue: 'TOKOPEDIA',
              queryType: 'is',
            },
          ],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_PLACEMENT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdPlacementIds = draftKeywords.rowData.map((i) => i['ADS_PLACEMENT.id']);
        if (draftAdPlacementIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_PLACEMENT',
              id: draftAdPlacementIds,
              marketplaceCode: marketplace,
            }),
          );
        }
        return results;
      },
    },
    {
      async syncRemoveProduct({ target: rowTarget, updateType, value: products }, workflow) {
        const adObjectIdField = 'ADS_OBJECT.id';
        const marketplaceCode = get(rowTarget, ['MARKETPLACE.marketplace_code'], null);
        const selectingItemIds = products.map((i) => i[adObjectIdField]);

        const removeProductIds = selectingItemIds;

        let removePromise = null;
        if (removeProductIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            id: selectingItemIds,
          };
          removePromise = workflow.request('deleteAdsObjects', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = result.data;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncRemoveProductKeywords({ target: rowTarget, value: keywords }, workflow) {
        const keywordIdField = 'SUGGESTED_KEYWORD.id';
        const keywordNameField = 'SUGGESTED_KEYWORD.name';
        const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);
        const selectingItemIds = keywords.map((i) => ({
          masterObjectId: i[keywordIdField],
          name: i[keywordNameField],
        }));

        // Determine which keywords to add/remove
        const removedKeywordIds = selectingItemIds;

        let removePromise = null;
        if (removedKeywordIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            ...(ff.select_all_add_keyword
              ? {
                id: removedKeywordIds.map((i) => i.masterObjectId).filter((el) => el !== null),
              }
              : {
                id: removedKeywordIds.map((i) => i.masterObjectId),
              }),
          };
          removePromise = workflow.request('deleteAdsPlacements', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = removedKeywordIds;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncUpdateCampaignProducts({ target, updateType, value }, workflow) {
        const productIdField = 'PRODUCT.id';
        const adObjectIdField = 'ADS_OBJECT.id';
        const campaignId = get(target, 'ADS_CAMPAIGN.id', null);
        const marketplaceCode = get(target, ['MARKETPLACE.marketplace_code'], null);
        const storefrontId = get(target, ['STOREFRONT.id'], null);
        const selectedItems = get(target, ['ad_object', 'selectedItems'], []);
        const countryCode = get(target, ['COUNTRY.country_code'], null);
        const toolCode = get(target, ['ADTOOL.ads_tool'], null);
        const adType = get(target, ['ADTYPE.ads_type'], null);

        const selectedItemIds = selectedItems.map((i) => i[productIdField]);
        const selectingItemIds = value.map((i) => i[productIdField]);

        // Determine which products to add/remove
        const removeProductIds = difference(selectedItemIds, selectingItemIds);
        const addProductIds = difference(selectingItemIds, selectedItemIds);

        let removePromise = null;
        let addPromise = null;
        removePromise = Promise.resolve(null);

        if (addProductIds.length > 0) {
          const addAdsObjectParam = {
            marketplaceCode,
            data: addProductIds.map((id) => ({
              masterObjectId: id, // FIXME: incorrect
              masterObjectType: undefined, // no need according to @Nam.Huynh https://epsilo.slack.com/archives/D01TB0HSUPN/p1635135520001600
              countryCode,
              storefrontId,
              toolCode,
              type: adType,
              adsCampaignId: campaignId,
              totalBudget: 0,
              dailyBudget: 0,
              adsOpsAdsCalendars: [],
            })),
          };
          addPromise = workflow.request('createAdsObjects', addAdsObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];
            if (values[0]) {
              if (values[0].success) {
                rows = selectedItems.filter((i) => !removeProductIds.includes(i[productIdField]));
                success = true;
              } else {
                error = error.concat(values[0].data);
                console.log(values[0].message);
              }
            }

            if (values[1]) {
              if (values[1].success) {
                const addedItems = value
                  .filter((i) => addProductIds.includes(i[productIdField]))
                  .map((i) => {
                    const adObject = values[1].data.find((v) => v.data.masterObjectId == i[productIdField]);
                    return {
                      ...i,
                      status: get(adObject, ['data', 'status'], ''),
                      adsObjectId: parseInt(get(adObject, ['data', 'adsObjectId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.log(values[1].message);
                console.error(values[1]);
              }
            }

            return {
              success,
              data: rows,
              error: error,
            };
          })
          .catch((err) => {
            console.log('Error', err);
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      syncUpdateCampaignKeywords: (
        { value: keywords, target: rowTargets }: { value: any[]; target: any[] },
        workflow,
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          const productIdField = 'SUGGESTED_KEYWORD.master_object_id';
          const keywordNameField = 'SUGGESTED_KEYWORD.name';

          const adObjectIds = uniq(rowTargets.map((rowTarget) => get(rowTarget, ['ADS_OBJECT.id'], null)));

          const marketplaceCode = get(rowTargets[0], 'MARKETPLACE.marketplace_code', null);
          const selectedItems: any[] = flatten(
            rowTargets.map((rowTarget) => get(rowTarget, ['keyword', 'selectedItems'], [])),
          );

          // const adObjectId = get(rowTarget, ['ad_object', 'currentFocusItem', 'ADS_OBJECT.id'], null);
          // const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);

          // const selectedItems = get(rowTarget, ['keyword', 'selectedItems'], []);

          // const selectedItemIds = uniqBy(
          //   selectedItems.map((i) => ({
          //     masterObjectId: i[productIdField],
          //     name: i[keywordNameField],
          //   })),
          //   (i) => [i.masterObjectId, i.name].join(','),
          // );
          const selectedItemIds = [];

          const selectingItemIds = uniqBy(
            keywords.map((i) => ({
              masterObjectId: i[productIdField],
              name: i[keywordNameField],
              biddingPrice: i['SUGGESTED_KEYWORD.bidding_price'],
            })),
            (i) => [i.masterObjectId, i.name].join(','),
          );

          // Determine which keywords to add/remove
          // const removeKeywordIds = differenceWith(selectedItemIds, selectingItemIds, isEqual);
          /**
           * FIXME: temporary commented the removed keywords, the logic here is not correct with provided context
           * when there are multiple ad object selected and suggested selected list does not return the existing keyword
           */
          const addKeywordIds = differenceWith(selectingItemIds, selectedItemIds, isEqual);

          const removePromise = Promise.resolve('todo...');
          let addPromise = null;

          if (addKeywordIds.length > 0) {
            const addAdsObjectParam = {
              marketplaceCode,
              data: flatten(
                addKeywordIds.map((i) => {
                  return adObjectIds.map((adObjectId) => {
                    return {
                      adsObjectId: adObjectId,
                      name: i.name,
                      matchType: DEFAULT_VALUE.MATCH_TYPE,
                      biddingPrice:
                        keywords.find((kw) => kw[keywordNameField] == i.name)['SUGGESTED_KEYWORD.bidding_price'] ||
                        DEFAULT_VALUE.BIDDING_PRICE,
                    };
                  });
                }),
              ),
            };
            addPromise = workflow.request('createAdsPlacements', addAdsObjectParam);
          } else {
            addPromise = Promise.resolve(null);
          }

          Promise.all([removePromise, addPromise])
            .then((values) => {
              let rows = [];
              let success = false;
              let error = [];
              if (values[1]) {
                if (values[1].success) {
                  const addedItems = keywords
                    .filter((item) => {
                      const exist = addKeywordIds.some(
                        (i) => i.masterObjectId === item[productIdField] && i.name === item[keywordNameField],
                      );
                      return exist;
                    })
                    .map((i) => {
                      const adPlacement = values[1].data.find((v) => v.data.name === i[keywordNameField]);
                      return {
                        ...i,
                        status: get(adPlacement, ['data', 'status'], ''),
                        adsPlacementId: parseInt(get(adPlacement, ['data', 'adsPlacementId'], 0)),
                      };
                    });
                  rows = [...rows, ...addedItems];
                  success = true;
                } else {
                  error = error.concat(values[1].data);
                  console.error(JSON.stringify(values[1]));
                  console.log(values[1].message);
                }
              }

              resolve({
                success,
                data: rows,
                error,
              });
            })
            .catch((err) => {
              resolve({
                success: false,
                data: [],
                error: err.data,
              });
            });
        });
      },
      async syncUpdatePromotedObject({ target, updateType, value }, workflow) {
        const productIdField = 'PRODUCT.id';
        const adObjectIdField = 'ADS_OBJECT.id';
        const adObjectId = get(target, [adObjectIdField], null);
        const marketplaceCode = get(target, ['MARKETPLACE.marketplace_code'], null);

        const invalidRows = value
          .map((row) => row['PRODUCT.category_id'])
          .filter((el, _, arr) => arr.indexOf(el) === arr.lastIndexOf(el));

        const addProductIds = value
          .filter(
            (el) =>
              (!invalidRows || invalidRows.length === 0 || !invalidRows.includes(el['PRODUCT.category_id'])) &&
              el['PRODUCT.category_id'],
          )
          .map((i) => i[productIdField]);

        let addPromise = null;

        if (addProductIds.length > 0) {
          const addAdsObjectParam = {
            marketplaceCode,
            data: [
              {
                id: adObjectId,
                promotedObjects: JSON.stringify(addProductIds),
              },
            ],
          };

          addPromise = workflow.request('upsertAdsObjects', addAdsObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        return addPromise
          .then((value) => {
            let rows = [];
            let success = false;
            let error = [];

            if (value) {
              if (value.success) {
                const addedItems = value
                  .filter((i) => addProductIds.includes(i[productIdField]))
                  .map((i) => {
                    const adObject = value.data.find((v) => v.data.masterObjectId == i[productIdField]);
                    return {
                      ...i,
                      status: get(adObject, ['data', 'status'], ''),
                      adsObjectId: parseInt(get(adObject, ['data', 'adsObjectId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(value.data);
                console.log(value.message);
                console.error(value);
              }
            }

            return {
              success,
              data: rows,
              error: error,
            };
          })
          .catch((err) => {
            console.log('Error', err);
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
    },
  );
  return wf;
}
