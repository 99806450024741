import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Calendar2 = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 0C3.55228 0 4 0.447715 4 1H8C8 0.447715 8.44772 0 9 0C9.55229 0 10 0.447715 10 1H11C11.5523 1 12 1.44772 12 2V11C12 11.5523 11.5523 12 11 12H1C0.447715 12 0 11.5523 0 11V2C0 1.44772 0.447715 1 1 1H2C2 0.447715 2.44772 0 3 0ZM2.5 5C2.22386 5 2 5.22386 2 5.5V9.5C2 9.77614 2.22386 10 2.5 10H9.5C9.77614 10 10 9.77614 10 9.5V5.5C10 5.22386 9.77614 5 9.5 5H2.5Z"
      fill="#253746"
    />
    <path
      d="M6 6.5C6 6.22386 6.22386 6 6.5 6H8.5C8.77614 6 9 6.22386 9 6.5V8.5C9 8.77614 8.77614 9 8.5 9H6.5C6.22386 9 6 8.77614 6 8.5V6.5Z"
      fill="#253746"
    />
  </SvgIcon>
);

export default Calendar2;
