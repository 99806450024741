/* eslint-disable react/prop-types */
import Icon from '@ep/insight-ui/icons/Icon';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Box, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import moment from 'moment';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

import { enhanceCellAction } from '../table-helper';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    paddingTop: '3px',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  status: {
    display: 'flex',
    height: '10px',
    alignItems: 'center',
  },
  statusCompact: {
    height: '22px',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  statusContainer: {
    width: 'unset',
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
    marginLeft: '-10px',
  },
  subtextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

export const getIconStatus = (status: string) => {
  let iconComponent = null;
  let iconType = null;
  if (status) {
    switch (status.toUpperCase()) {
      case 'ACTIVE':
      case 'CONNECT':
      case 'NOT AVAILABLE':
      case 'GOOD':
      case 'DIS-CONNECT':
      case 'INVALID':
      case 'ONGOING': {
        iconComponent = <Icon type={'goingStatus'} />;
        iconType = 'goingStatus';
        break;
      }
      case 'PAUSED': {
        iconComponent = <Icon type={'pauseStatus'} />;
        iconType = 'pauseStatus';
        break;
      }
      case 'INACTIVE':
      case 'ERROR': {
        iconComponent = <Icon type={'errorStatus'} />;
        iconType = 'errorStatus';
        break;
      }
      case 'WAITING FOR RESPONSE':
      case 'SCHEDULED':
      case 'PROCESSING': {
        iconComponent = <Icon type={'processStatus'} />;
        iconType = 'processStatus';
        break;
      }
      default: {
        iconComponent = <Icon type={'draftStatus'} />;
        iconType = 'draftStatus';
        break;
      }
    }
  }
  return { iconComponent, iconType };
};

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export interface IValueStatusTextFormat {
  label: string;
  status: string;
  hashtag?: string;
  subtext?: string;
}
const StatusTextFormat = (props: IPropsFormat<IValueStatusTextFormat>) => {
  const classes = useStyles();
  // const { hashtag = '', subtext = '' } = props.value;
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const label = _.get(props, ['value', 'label'], '');
  const status = _.get(props, ['value', 'status'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const edot = _.get(props, ['value', 'edot'], []);
  let subtextValue = _.get(props, ['value', 'subtext'], '');

  const valueTool = _.get(props, ['value', 'tooltips'], '');
  const valueTooltips = valueTool ? (ff.safe_json_parse ? safeJsonParse(valueTool) : JSON.parse(valueTool)) : null;
  const tooltipValueItems = valueTooltips && valueTooltips.status ? valueTooltips.status : [];

  const backbone = React.useContext(TableBackboneContext);

  const statusField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'status'], '');
  const valueSyncStatus = _.get(props, ['data', 'data_status', statusField, 'status'], null);
  const valueSyncUpdatedAt = _.get(props, ['data', 'data_status', statusField, 'updated_at'], null);
  const valueSyncErrorMessage = _.get(props, ['data', 'data_status', statusField, 'error_message'], '');

  const getLabel = (val: string) => {
    if (!val) return '';
    /**
     * DRAFT ==> Draft
       PUBLISHING ==> Publishing
       SCHEDULED ==> Scheduled
       ONGOING ==> Ongoing
       ENDED ==> Ended
       RECOVERING ==> Resuming
       SUSPENDED ==> Pause
       PAUSED ==> Pause by Botep
     *
     */
    const text = val.toUpperCase();

    if (text == 'RECOVERING') return 'Resuming';
    if (text == 'SUSPENDED') return 'Paused';
    if (text == 'PAUSED') {
      subtextValue = 'by Botep';
      return 'Paused';
    }

    return _.startCase(_.lowerCase(text));
  };

  if (label && label.toUpperCase() === 'PAUSED' && statusField !== 'SCRIPT.script_status') {
    subtextValue = 'by Botep';
  }

  const subtext = React.useMemo(() => {
    if (valueSyncStatus == 2) {
      return (
        <Box className={classes.subtextContainer}>
          <Tooltip title={valueSyncErrorMessage} classes={{ tooltip: classes.tooltip }} placement="bottom">
            <Box className={classes.syncStatusText}>
              <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
              <span>
                Sync failed at:{' '}
                {moment(valueSyncUpdatedAt).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Box>
          </Tooltip>
          {subtextValue ? (
            <>
              <span>|</span>
              <span>{subtextValue}</span>
            </>
          ) : null}
        </Box>
      );
    }

    return <span>{subtextValue}</span>;
  }, [props, valueSyncStatus, valueSyncUpdatedAt]);

  const PausedByBotepTooltip = React.useMemo(() => {
    const tooltipItems = [
      {
        icon: 'goingStatus',
        label: 'Account balance',
      },
      {
        icon: 'goingStatus',
        label: 'Campaign status',
      },
      {
        icon: 'goingStatus',
        label: 'Campaign budget',
      },
      {
        icon: 'goingStatus',
        label: 'Ad object status',
      },
      {
        icon: 'errorStatus',
        label: 'Ad object status',
      },
      {
        icon: 'errorStatus',
        label: 'Product availabilty',
      },
      {
        icon: 'errorStatus',
        label: 'Product stock',
      },
    ];

    const tooltipItemsRender = tooltipValueItems.map((item, index) => {
      return (
        <ListItem key={index}>
          <Box className={'tooltip-icon'}>
            <Icon type={item.is_valid == 0 ? 'errorStatus' : 'goingStatus'} />
          </Box>
          <span>{item.message}</span>
        </ListItem>
      );
    });

    return <List>{tooltipItemsRender}</List>;
  }, []);

  // const backbone = React.useContext(TableBackboneContext);

  // const statusCellAction = React.useMemo(() => {
  //   return status && status.toUpperCase() === 'DRAFT'
  //     ? [
  //         ...cellAction,
  //         {
  //           name: 'Delete it',
  //           icon: 'delete',
  //           iconSize: '13px',
  //           onSubmit: async () => {
  //             backbone.getCallback('onRowRemove')('status', [], props.node.data, backbone);
  //           },
  //         },
  //       ]
  //     : cellAction;
  // }, [status]);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(cellAction, edot, props.field, backbone)}
      node={node}
      value={value}
      isGrouped={isGrouped}
    >
      <ConditionalWrap
        condition={status && status.toUpperCase() === 'PAUSED' && valueTool}
        wrap={(children) => (
          <Tooltip
            title={PausedByBotepTooltip}
            classes={{ tooltip: classes.tooltip }}
            placement="right-start"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '30px',
                  },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        <Grid
          container
          alignItems="center"
          wrap={'nowrap'}
          className={`${classes.statusContainer} ${classes.height100}`}
        >
          {/* status icon */}
          {!_.isEmpty(status) ? (
            <Grid
              item
              className={clsx(classes.status, typeTable === 'compact' && subtext && classes.statusCompact)}
              style={{ marginRight: !_.isEmpty(status) ? '2px' : '' }}
            >
              <span style={{ display: 'flex' }}>{!_.isEmpty(status) && getIconStatus(status).iconComponent}</span>
            </Grid>
          ) : null}
          <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
            <Grid
              container
              alignItems={'center'}
              direction={'column'}
              wrap={'nowrap'}
              className={clsx(classes.height100, classes.alignItemsStart)}
            >
              {/*hashtag*/}
              <Grid item xs={12} className={classes.height100}>
                <span className={classes.hashtag}>{hashtag}</span>
              </Grid>
              {/* label */}
              <Grid item xs={12} className={classes.height100}>
                <span className={classes.label}>{getLabel(label)} </span>
              </Grid>
              {/* subtext */}
              <Grid item xs={12} className={classes.height100}>
                <Box className={classes.subtext}>{subtext ? subtext : ''}</Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrap>
    </WrapperFormat>
  );
};
export default StatusTextFormat;
