import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CustomError = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.275 4.475L5.525 0.725C5.225 0.425 4.775 0.425 4.475 0.725L0.725 4.475C0.425 4.775 0.425 5.225 0.725 5.525L4.475 9.275C4.775 9.575 5.225 9.575 5.525 9.275L9.275 5.525C9.575 5.225 9.575 4.775 9.275 4.475ZM5 8C4.55 8 4.25 7.7 4.25 7.25C4.25 6.8 4.55 6.5 5 6.5C5.45 6.5 5.75 6.8 5.75 7.25C5.75 7.7 5.45 8 5 8ZM5.75 5C5.75 5.45 5.45 5.75 5 5.75C4.55 5.75 4.25 5.45 4.25 5V2.75C4.25 2.3 4.55 2 5 2C5.45 2 5.75 2.3 5.75 2.75V5Z" />
  </SvgIcon>
);

export default CustomError;
