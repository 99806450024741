import { SYSTEM_FIELD_DEFINED } from '../../../utils/constant';

export const defaultInlineEditData = [
  {
    field_key: 'form',
    label: 'Form Configuration',
    input_type: SYSTEM_FIELD_DEFINED,
    field_configuration: [
      {
        key: 'form_title',
        value: 'Untitled',
      },
      {
        key: 'submit_label',
        value: 'Submit',
      },
      {
        key: 'cancel_label',
        value: 'Cancel',
      },
      {
        key: 'submit_endpoint',
        value: '',
      },
    ],
  },
];
