import extendedBlocks from '@ep/insight-ui/system/block';
import ContainerChartLib from './container';
import FormCampaignInfoChartLib from './form/campaign-info';
import FormScriptInfoChartLib from './form/script-info';
import FormUserBillingChartLib from './form/user-billing';
import SubscriptionPlanChartLib from './subscription-plan';
import ToggleContainer from './toggle-container';

export const CHART_TYPE = {
  NA: 'not_available',
  TOGGLE_CONTAINER: 'toggleContainer',
  FORM_CAMPAIGN_INFO: 'formCampaignInfo',
  FORM_SCRIPT: 'formScriptInfo',
  FORM_USER_PROFILE: 'formUserProfile',
  FORM_USER_BILLING: 'formUserBilling',
  FORM_SUBMIT_CONTROL: 'fromSubmitControl',
  SUBSCRIPTION_PLAN: 'subscriptionPlan',
  CAMPAIGN_CREATOR: 'tempCampaignCreator',
  CAMPAIGN_ZERO_DETAIL: 'tempCamp0Creator',
  HYPER_AUTOMATION_DETAIL: 'tempHyperAutomationCreator',
};

export const chartPrePackagedConfig = {};

export const hasCustomMenu = (chartType) => {
  const customMenuCharts = ['empty'];
  return customMenuCharts.includes(chartType);
};

const chartLib = (() => {
  let libs: { [key: string]: ChartLib } = {
    container: {
      chartLibId: 'container',
      label: 'Container',
      component: new ContainerChartLib(),
      scale: 0,
    },
    [CHART_TYPE.TOGGLE_CONTAINER]: {
      chartLibId: CHART_TYPE.TOGGLE_CONTAINER,
      label: 'Toggle container',
      component: new ToggleContainer(),
      scale: 0,
    },
    [CHART_TYPE.FORM_CAMPAIGN_INFO]: {
      chartLibId: CHART_TYPE.FORM_CAMPAIGN_INFO,
      label: 'Campaign setting',
      component: new FormCampaignInfoChartLib(),
      scale: 0,
    },
    [CHART_TYPE.FORM_SCRIPT]: {
      chartLibId: CHART_TYPE.FORM_SCRIPT,
      label: 'Script setting',
      component: new FormScriptInfoChartLib(),
      scale: 0,
    },
    // [CHART_TYPE.FORM_USER_PROFILE]: {
    //   chartLibId: CHART_TYPE.FORM_USER_PROFILE,
    //   label: 'User setting',
    //   component: new FormUserProfileChartLib(),
    //   scale: 0,
    // },
    [CHART_TYPE.FORM_USER_BILLING]: {
      chartLibId: CHART_TYPE.FORM_USER_BILLING,
      label: 'User billing',
      component: new FormUserBillingChartLib(),
      scale: 0,
    },
    [CHART_TYPE.SUBSCRIPTION_PLAN]: {
      chartLibId: CHART_TYPE.SUBSCRIPTION_PLAN,
      label: 'Subscription plan',
      component: new SubscriptionPlanChartLib(),
      scale: 0,
    },
    // [CHART_TYPE.E_DASHBOARD]: {
    //   chartLibId: CHART_TYPE.E_DASHBOARD,
    //   label: 'Subscription plan',
    //   component: new EDashboard(),
    //   scale: 0,
    //   layout: {
    //     w: SCREEN_COLUMNS.lg.default,
    //     h: 12,
    //   },
    // },
    // [CHART_TYPE.CAMPAIGN_CREATOR]: {
    //   chartLibId: CHART_TYPE.CAMPAIGN_CREATOR,
    //   label: 'Campaign Creator',
    //   component: new CampaignCreator(),
    //   scale: 0,
    //   layout: {
    //     w: SCREEN_COLUMNS.lg.default,
    //     h: 12,
    //   },
    // },
  };

  Object.entries(extendedBlocks).forEach(([type, block]) => {
    libs[type] = {
      chartLibId: block.blockType,
      component: new block.blockComponent(),
      label: block.label,
      scale: 0,
    };
  });

  if (process.env.NODE_ENV === 'production') {
    delete libs.embed;
  }

  libs = {
    ...libs,
    notAvailable: {
      chartLibId: CHART_TYPE.NA,
      label: '',
      component: {
        render: (dom, nodeData) => {
          console.error('missing chart for', nodeData);
          return null;
        },
        renderConfigurationForm: (dom, nodeData) => {
          console.error('missing chart for form', nodeData);
          return null;
        },
      },
      scale: 0,
    },
  };

  libs = Object.keys(libs).reduce((carry, i) => {
    return { ...carry, [i]: { ...libs[i], blockType: libs[i].chartLibId, blockComponent: libs[i].component } };
  }, {});

  return {
    find: (chartId) => {
      return libs[chartId] ? libs[chartId] : libs['notAvailable'];
    },
    asList: () => {
      return Object.values(libs).filter((i) => i.chartLibId !== 'not_available');
    },
  };
})();

export default chartLib;
