import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { responsiveChart } from '@ep/insight-ui/lib/epsilo-theme';
import { getComponent } from '@ep/insight-ui/system/backbone/atom/app';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import * as React from 'react';
import { useState } from 'react';
import { get, set, uniq } from 'lodash';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize, breakPointSize } from '../chart-size';
import { IData, IOption } from '../type';
import { Bared } from './bar-template';
import { funcConfigs } from './hooks';
import { colorsChartLegend, convertColor } from '../helper';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import ChartDataLabels from 'chartjs-plugin-datalabels';
const svg = `<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.99312 11.9966L5.99312 4.42024L7.2818 5.70961C7.46991 5.89783 7.72505 6.00356 7.99107 6.00356C8.2571 6.00356 8.51224 5.89783 8.70035 5.70961C8.88846 5.5214 8.99414 5.26613 8.99414 4.99996C8.99414 4.73378 8.88846 4.47851 8.70035 4.2903L5.70341 1.29175C5.61055 1.19807 5.50006 1.12371 5.37832 1.07296C5.25659 1.02222 5.12602 0.996094 4.99414 0.996094C4.86226 0.996094 4.73169 1.02222 4.60996 1.07296C4.48822 1.12371 4.37773 1.19807 4.28487 1.29175L1.28793 4.2903C1.19479 4.38349 1.1209 4.49413 1.07049 4.61589C1.02008 4.73766 0.99414 4.86816 0.99414 4.99996C0.99414 5.13175 1.02008 5.26226 1.07049 5.38402C1.1209 5.50578 1.19479 5.61642 1.28793 5.70961C1.38107 5.80281 1.49165 5.87673 1.61335 5.92717C1.73505 5.9776 1.86548 6.00356 1.99721 6.00356C2.12893 6.00356 2.25936 5.9776 2.38106 5.92717C2.50276 5.87673 2.61334 5.80281 2.70648 5.70961L3.99516 4.42024L3.99516 11.9966C3.99516 12.2617 4.10041 12.5159 4.28776 12.7033C4.4751 12.8908 4.72919 12.9961 4.99414 12.9961C5.25909 12.9961 5.51318 12.8908 5.70052 12.7033C5.88787 12.5159 5.99312 12.2617 5.99312 11.9966Z" fill="#0BA373"/>
</svg>`;
const useStyles = makeStyles({
  ratio_wrap: responsiveChart.ratio_wrap as CreateCSSProperties,
  ratio_item: responsiveChart.ratio_item as CreateCSSProperties,
  wrapper: {
    width: '100%',
    height: '100%',
  },
  chart: {
    width: '100%',
    height: '100%',
    flex: '1 0 0',
  },
  wrapperRes: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  wrapperLegendLineSingle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '& ul.legendDiv': {
      display: 'flex',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      width: 'auto',
      height: '30px',
      '& li.legendItem': {
        margin: 0,
        marginRight: 0,
        fontSize: '12px',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
    position: 'relative',
    width: '100%',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  crisper: {
    position: 'absolute',
  },
  darkmode: {
    height: 25,
  },
});
type Props = {
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: IData;
  optionList: IOption;
  stateChart: IStateChartValue;
  isHideTotal?: boolean;
  isHidePercent?: boolean;
  isColumnStacked?: boolean;
  isSingle?: boolean;
  setIsDarkModeParent?: any;
  colorOption?: string[];
  valueDisplay?: string;
  chartType?: 'column' | 'waterfall';
  colKey?: string;
  showLabelOnTop?: boolean;
  subTitle?: string;
};
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const StackedChartResponsive = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  stateChart,
  isHideTotal = false,
  isHidePercent = false,
  isColumnStacked = false,
  isSingle = false,
  colorOption = [],
  valueDisplay,
  chartType = 'column',
  colKey,
  showLabelOnTop = false,
  subTitle,
}: Props) => {
  const classes = useStyles({ isSingle });
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const darkmode = React.useContext(DarkModeContext);
  const [width, setWidth] = useState(window.innerWidth);
  const [chartSize, setchartSize] = React.useState<TSize>('large');
  const divRef = React.useRef<HTMLDivElement>();
  const idLegend = React.useMemo(() => `StackedChartLegend_${keyChart}`, []);
  const ref = React.useRef(null);
  const { htmlLegendPlugin } = funcConfigs({
    percent: '15%',
    width,
    legendDivID: idLegend,
  });

  const metric = backbone.getConfig('metric');
  const columnOrder = backbone.getConfig('columnOrder');
  const mapping = backbone.getConfig('mapping');
  const yAxisConfig = backbone.getConfig('chartConfig.config.yAxisConfig', '');
  const zAxisConfig = backbone.getConfig('chartConfig.config.zAxisConfig', '');

  React.useEffect(() => {
    if (ref.current) {
      const metricOrder = columnOrder.filter((el) => metric.includes(el));
      const datasets = get(ref.current, ['data', 'datasets'], []);
      const isEqualOrder = datasets.every((data) => data.order === metricOrder.indexOf(data.key));
      if (metricOrder.length === datasets.length && !isEqualOrder && valueDisplay !== 'single') {
        let colorIndex = 0;
        const colors = [...ref.current.data.datasets]
          .sort((a, b) => metricOrder.indexOf(a.key) - metricOrder.indexOf(b.key))
          .reduce((carry, item, index) => {
            const chartColorConfig = get(item, ['chartColorConfig'], null);
            let color, hoverBorderColor;

            if (chartColorConfig) {
              color = chartColorConfig;
              hoverBorderColor = convertColor(chartColorConfig);
            } else if (colorOption?.[colorIndex]) {
              color = colorOption[colorIndex];
              hoverBorderColor = convertColor(colorOption[colorIndex]);
              colorIndex++;
            } else {
              color = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].stroke;
              hoverBorderColor = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].pointBorderColor;
              colorIndex++;
            }

            return {
              ...carry,
              [`${item.key}_${index}`]: {
                color,
                hoverBorderColor,
              },
            };
          }, {});

        // Update datasets for formula colors
        const formulaValues = ref.current.data.datasets.reduce((a, b) => {
          return {
            ...a,
            [b.key]: b.data,
          };
        }, {});

        ref.current.data.datasets = ref.current.data.datasets.map((el) => {
          if (el.type === 'line') return el;
          const order = Math.max(metricOrder.indexOf(el.key), 0);
          const keyColor = `${el.key}_${order}`;
          const chartColor = get(colors, [keyColor, 'color'], '');
          const hoverBorderColor = get(colors, [keyColor, 'hoverBorderColor'], '');

          const formulaColor = get(mapping, [el.key, 'valueGetter', 'chartColor'], '');
          let backgroundColor, hoverBackgroundColor;
          if (formulaColor && formulaColor.startsWith('=')) {
            backgroundColor = el.data.map((dt, i) => {
              const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                return {
                  ...carry,
                  [key]: value[i],
                };
              }, {});

              return (
                toValue(formulaColor, {
                  value: dt,
                  legend: el.label,
                  ...formulaValue,
                }) || colors[keyColor].color
              );
            });
            hoverBackgroundColor = el.data.map((dt, i) => {
              const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                return {
                  ...carry,
                  [key]: value[i],
                };
              }, {});
              return (
                convertColor(
                  toValue(formulaColor, {
                    value: dt,
                    legend: el.label,
                    ...formulaValue,
                  }),
                ) || colors[keyColor].hoverBorderColor
              );
            });
          }

          return {
            ...el,
            backgroundColor: backgroundColor || chartColor,
            borderColor: backgroundColor ? uniq(backgroundColor) : chartColor,
            pointBackgroundColor: backgroundColor || chartColor,
            hoverBackgroundColor: hoverBackgroundColor || hoverBorderColor,
            order,
          };
        });
        ref.current.update('reOrder');
      }
    }
  }, [metric, columnOrder, ref.current]);

  const isDarkMode = darkmode.isDarkMode;

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (optionList) {
        set(optionList, `scales.yAxes.display`, false);
        const scaleList = Object.keys(get(optionList, ['scales'], {}));
        const [y, yFormula, grid] = yAxisConfig.split('\n').map((el) => (el ? el.trim() : ''));
        const [z, zFormula] = zAxisConfig.split('\n').map((el) => (el ? el.trim() : ''));
        const matchedY = scaleList.find(
          (i) => toValue(y, { value: i }, true) == true || toValue(y, { value: i }, true) == i,
        );
        if (matchedY) {
          set(optionList, `scales.${matchedY}.display`, true);
          set(optionList, `scales.${matchedY}.position`, 'left');
          set(optionList, `scales.${matchedY}.grid.display`, grid == 'true');
          const dt = []
            .concat(dataList?.datasets)
            .find((el) => el?.yAxisID == matchedY || (matchedY == 'yAxes' && !el?.yAxisID));
          if (dt?.formatLabel || yFormula) {
            set(optionList, `scales.${matchedY}.ticks.callback`, (value) => {
              return toValue(yFormula || dt?.formatLabel, { value, currency: dt?.currency });
            });
          }
        }
        const matchedZ = scaleList.find(
          (i) => toValue(z, { value: i }, true) == true || toValue(z, { value: i }, true) == i,
        );
        if (matchedZ) {
          set(optionList, `scales.${matchedZ}.display`, true);
          set(optionList, `scales.${matchedZ}.position`, 'right');
          const dt = []
            .concat(dataList?.datasets)
            .find((el) => el?.yAxisID == matchedZ || (matchedZ == 'yAxes' && !el?.yAxisID));
          if (dt?.formatLabel || zFormula) {
            set(optionList, `scales.${matchedZ}.ticks.callback`, (value) => {
              return toValue(zFormula || dt?.formatLabel, { value, currency: dt?.currency });
            });
          }
        }
        if (
          divRef.current.offsetWidth < breakPointSize.width.medium ||
          divRef.current.offsetHeight < breakPointSize.height.medium
        )
          return setchartSize('small');
        if (
          divRef.current.offsetWidth < breakPointSize.width.large ||
          divRef.current.offsetHeight < breakPointSize.height.large
        )
          return setchartSize('medium');

        optionList.scales.xAxes.display = true;
        setchartSize('large');
      }
    });
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [ref]);

  const ChartStateDerived = useAtomValue(React.useRef(getComponent(ChartState)).current);

  return (
    <div
      ref={divRef}
      className={classes.wrapper}
      style={{ display: 'flex', flexDirection: 'column', backgroundColor: isDarkMode ? '#253645' : 'inherit' }}
    >
      <ChartSize size={chartSize}>
        <ChartStateDerived
          stateChart={stateChart}
          title={title}
          value={value}
          currency={currency}
          percent={percent}
          isHideTotal={isHideTotal}
          isHidePercent={isHidePercent}
          colKey={colKey}
          subTitle={subTitle}
        >
          <div className={classes.wrapperRes}>
            <div className={clsx(classes.chart, 'chart')}>
              {dataList && (
                <Bared
                  data={dataList}
                  options={optionList}
                  plugins={[
                    htmlLegendPlugin,
                    ...(chartType === 'waterfall' || showLabelOnTop ? [ChartDataLabels] : []),
                  ]}
                  refElement={ref}
                />
              )}
            </div>
            <div
              className={classes.wrapperLegendLineSingle}
              style={{
                display: chartSize === 'large' ? 'flex' : 'block',
              }}
            >
              <div className={classes.legendDiv} id={idLegend}></div>
            </div>
          </div>
        </ChartStateDerived>
      </ChartSize>
    </div>
  );
};
export default StackedChartResponsive;
