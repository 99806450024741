import { NodeEditContext } from '@eip/next/lib/main';
import { get } from 'lodash';
import * as React from 'react';

export function useEditorEnhancement(config, backbone) {
  const { isEditMode } = React.useContext(NodeEditContext);

  if (isEditMode) {
    // violate rules of hooks but thats ok for this case. Because editMode is never co-exists with none editor mode,
    // switching environment is refreshed the whole app
    React.useEffect(() => {
      if (backbone.isTableReady()) {
        backbone.updateConfig({ title: config.configuration.title });
      }
    }, [config.configuration.title]);

    // React.useEffect(() => {
    //   const mapping = config.configuration.mapping;
    //   const columnOrder = config.configuration.columnOrder;

    //   const dimension = get(config.configuration, 'dimension', []).filter((i) => !!mapping[i]).sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));
    //   const attribute = get(config.configuration, 'attribute', []).filter((i) => !!mapping[i]).sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));
    //   const metric = get(config.configuration, 'metric', []).filter((i) => !!mapping[i]).sort((a, b) => columnOrder.indexOf(a) - columnOrder.indexOf(b));

    //   if (backbone.isTableReady()) {
    //     backbone.updateConfig({
    //       mapping: config.configuration.mapping,
    //       dimension:
    //         dimension.length > 0
    //           ? dimension
    //           : columnOrder.filter((c) => mapping[c] && mapping[c].propertyType === 'dimension'),
    //       attribute:
    //         attribute.length > 0
    //           ? attribute
    //           : attribute.filter((c) => mapping[c] && mapping[c].propertyType === 'attribute'),
    //       metric: metric.length > 0 ? metric : metric.filter((c) => mapping[c] && mapping[c].propertyType === 'metric'),
    //     });
    //   }
    // }, [config.configuration.mapping]);
  }
}
