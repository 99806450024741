import * as React from 'react';
import * as _ from 'lodash';
import clsx from 'clsx';

import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';

import WrapperFormat, { IPropsFormat } from './wrapper-format';

const useStyles = makeStyles(() => ({
  container: {
    '&:hover .label': {
      textDecoration: 'underline',
    },
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export type IValueTextFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueTextFormat>;
};

const TextWithTooltipFormat = (props: IPropsFormat<IValueTextFormat>) => {
  const classes = useStyles();

  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const disabled = _.get(props, 'value.disabled', false);
  const subTabs = _.get(props, ['value', 'subTabs'], null);
  const subtext = _.get(props, ['value', 'subtext'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const label = _.get(props, ['value', 'label'], '');
  const tooltip = _.get(props, ['value', 'tooltip'], '');

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={cellAction}
      node={node}
      value={value}
      isGrouped={isGrouped}
      disabledMenu={disabled}
    >
      <ConditionalWrap
        condition={tooltip}
        wrap={(children) => (
          <Tooltip
            title={tooltip}
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '0, -20px',
                  },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        <Grid
          container
          alignItems={'center'}
          wrap={'nowrap'}
          className={clsx(classes.height100, classes.container)}
          style={{ overflow: 'hidden' }}
        >
          {/* expand icon */}
          {!_.isEmpty(subTabs) ? (
            <Grid item style={{ marginRight: !_.isEmpty(subTabs) ? '5px' : '' }}>
              <div className={`${classes.wrapperIcon} expand`}>
                <Icon type={'arrowright'} className={`${classes.icon} arrowright`} />
              </div>
            </Grid>
          ) : null}
          {/* text */}
          <Grid item style={{ width: '100%', height: '100%' }}>
            <Grid container alignItems="center" wrap={'nowrap'} className={classes.height100}>
              {/* status icon */}
              {!_.isEmpty(status) ? (
                <Grid item style={{ marginRight: !_.isEmpty(status) ? '5px' : '' }}>
                  <span className={classes.wrapperIcon}>
                    <Icon type={status.toLowerCase()} className={`${classes.icon} status`} />
                  </span>
                </Grid>
              ) : null}
              <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  wrap={'nowrap'}
                  className={clsx(classes.height100, classes.alignItemsStart)}
                >
                  {/*hashtag*/}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.hashtag} ${disabled && 'disabled'}`}>{hashtag}</span>
                  </Grid>
                  {/* label */}
                  <Grid item xs={12} className={tooltip ? clsx(classes.height100, 'label') : classes.height100}>
                    <span className={`${classes.label} ${disabled && 'disabled'}`}>{label}</span>
                  </Grid>
                  {/* subtext */}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.subtext} ${disabled && 'disabled'}`}>{subtext ? subtext : ''}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrap>
    </WrapperFormat>
  );
};

export default TextWithTooltipFormat;
