import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Setscript = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.29473 6.29155C8.90265 6.68426 8.90265 7.3193 9.29473 7.71201C9.6868 8.10473 10.3208 8.10473 10.7129 7.71201L11.0007 7.42374L11.2885 7.71201C11.6805 8.10473 12.3145 8.10473 12.7066 7.71201C13.0987 7.3193 13.0987 6.68426 12.7066 6.29155L12.4188 6.00328L12.7066 5.715C13.0987 5.32229 13.0987 4.68725 12.7066 4.29454C12.3145 3.90182 11.6805 3.90182 11.2885 4.29454L11.0007 4.58281L10.7129 4.29454C10.3208 3.90182 9.6868 3.90182 9.29473 4.29454C8.90265 4.68725 8.90265 5.32229 9.29473 5.715L9.58253 6.00328L9.29473 6.29155Z"
      fill={props.color}
    />
    <path
      d="M11.7057 12.703L11.7024 12.7063C11.3108 13.0979 10.6776 13.0979 10.2859 12.7063L9.29439 11.7149C8.90277 11.3233 8.90277 10.6901 9.29439 10.2985C9.68601 9.90692 10.3193 9.90692 10.7109 10.2985L10.9942 10.5818L12.2857 9.29038C12.6774 8.8988 13.3106 8.8988 13.7022 9.29038C14.0939 9.68197 14.0939 10.3152 13.7022 10.7067L11.7108 12.698L11.7057 12.703Z"
      fill={props.color}
    />
    <path
      d="M2 11.0042C2 10.4527 2.45125 10.0015 3.00279 10.0015H6.99721C7.54875 10.0015 8 10.4527 8 11.0042C8 11.5556 7.54875 12.0068 6.99721 12.0068H3.00279C2.45125 12.0068 2 11.5556 2 11.0042Z"
      fill={props.color}
    />
    <path
      d="M3.00279 5.00477C2.45125 5.00477 2 5.45598 2 6.00745C2 6.55893 2.45125 7.01013 3.00279 7.01013H6.99721C7.54875 7.01013 8 6.55893 8 6.00745C8 5.45598 7.54875 5.00477 6.99721 5.00477H3.00279Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Setscript;
