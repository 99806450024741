function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export const getMockDataRows = (total = 100) => {
  return [...Array(total)].map((value, i) => {
    return {
      rowId: i + 1,
      country: {
        label: 'PH',
      },
      store_front: {
        status: 'active',
        label: 'Official Store ' + (i + 1),
      },
      campaign: {
        label: 'Flash sale ' + (i + 1),
      },
      ads_object: {
        label: 'Joy Lemon Concentrate Dishwashing Liquid Bottle (790mL) Set of 2',
        status: 'active',
      },
      keyword: {
        label: 'downy sunrise fresh ' + (i + 1),
        status: 'active',
      },
      campaign_budget: [
        {
          value: getRandomInt(500, 1500),
          currency: 'PHP',
          label: 'total',
        },
        {
          value: getRandomInt(500, 1500),
          currency: 'PHP',
          label: 'daily',
        },
      ],
      campaign_bidding_limit: {
        value: getRandomInt(1, 100),
        currency: 'PHP',
      },
      campaign_timeline: {
        date_from: '01/07/2021',
        date_to: '19/12/2021',
      },
      ads_object_budget: [
        {
          value: getRandomInt(500, 1500),
          currency: 'PHP',
          label: 'total',
        },
      ],
      ads_object_timeline: {
        date_from: '03/07/2021',
        date_to: '21/07/2021',
      },
      match_type: {
        label: 'Broad match',
        value: 'broad_match',
      },
      keyword_bidding_price: {
        value: getRandomInt(1, 100),
        currency: 'PHP',
      },
      roas: {
        value: getRandomInt(50000, 1000000),
        currency: 'VND',
      },
      cpc: {
        value: getRandomInt(50000, 1000000),
        currency: 'VND',
      },
      cost: {
        value: getRandomInt(50000, 1000000),
        currency: 'VND',
      },
      gmv: {
        value: getRandomInt(50000, 1000000),
        currency: 'PHP',
      },
      direct_ads_gmv: {
        value: getRandomInt(50000, 1000000),
        currency: 'PHP',
      },
      ads_gmv: {
        value: getRandomInt(50000, 1000000),
        currency: 'PHP',
      },
      item_sold: {
        value: getRandomInt(50000, 1000000),
        currency: 'VND',
      },
      direct_item_sold: {
        value: getRandomInt(50000, 1000000),
        currency: 'VND',
      },
      current_position: {
        position: getRandomInt(1, 100),
        hour: '16:32',
        date: '03/06/2021',
      },
    };
  });
};

export const DEFAULT_OPTIONS = {
  view: [
    { id: 'all', name: 'All' },
    // { id: 'active_campaign', name: 'All active campaigns' },
    // { id: 'ad_object_script', name: 'Ad object script' },
  ],
  dimension: [],
  metric: [],
  attribute: [],
};
