import { NodeEditContext } from '@eip/next/lib/main';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import { filterValueAddon } from '@ep/insight-ui/elements/table/table-addons/filter-value';
import { useFullPage } from '@ep/insight-ui/eo2/container/use-fullpage';
import { ResourceMetricInterface } from '@ep/insight-ui/system/backbone/data-source/common';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { produce } from 'immer';
import { cloneDeep, get, groupBy, uniq } from 'lodash';
import moment from 'moment';
import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import { useToast } from '../notifications/hook';
import ExpansionTable from '../table/expansion-table';
import HeaderRight from '../table/table-actions/header-right';
import TableActions from '../table/table-actions/table-actions';
import ChartETable from './edashboard-charts/chart-etable';
import LineChartEDashboard from './edashboard-charts/line-chart';

export type IStatusChartBB = 'loading' | 'error' | 'success' | undefined;
export enum TypeEDashBoard {
  INTRADAY = 'intraday',
  METRIC = 'metric',
  BILLING = 'billing',
  AREA = 'area',
  LINE = 'line',
  DOT = 'dot',
  SCATTER = 'scatter',
  WATERFALL = 'waterfall',
  TREEMAP = 'treemap',
  GAUGE = 'gauge',
}
export interface IConfigTable {
  apiRequest: any;
  configuration: any;
  callback?: any;
}

type IDarkModeContext = {
  isDarkMode: boolean;
};
interface DashboardContainerProps {
  config: IConfigTable;
  changeConfiguration: (value: any) => void;
  tableBackboneHook: any;
  tableContext: any;
  selectedRows?: any[];
  chartConfig?: any;
}
const useStyles = makeStyles(() => ({
  toggle: {},
  headerMobile: {
    '& .dropdown': {
      display: 'none',
    },
  },
}));
export const DarkModeContext = React.createContext<IDarkModeContext>({
  isDarkMode: false,
});
const DashboardContainer: React.FunctionComponent<DashboardContainerProps> = ({
  config,
  changeConfiguration,
  tableBackboneHook,
  tableContext,
  selectedRows,
  chartConfig,
}: DashboardContainerProps) => {
  // const isMobile = containerClass === 'eres--small';
  const isMobile = false;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const enhanceConfig = React.useMemo(() => {
    return produce(config, (draft) => {
      draft.addons = {
        ...filterValueAddon,
        ...draft.addons,
      };
    });
  }, []);
  const chartRef = React.useRef({ isInit: false });
  const backbone = tableBackboneHook(enhanceConfig, changeConfiguration);
  const currencyReq = get(config, 'requestFilter.currency', '');
  const [loadingChart, setLoadingChart] = React.useState(false);
  const [resourceMetric, setResourceMetric] = React.useState<ResourceMetricInterface[]>([]);
  const { onToast } = useToast();
  const groupPeriod = get(chartConfig, 'config.groupPeriod', '');
  const isFullPage = useFullPage();

  const [rows, setRows] = React.useState([]);
  const rowData = React.useMemo(() => {
    const cloneRows = cloneDeep(rows);
    cloneRows.sort((a, b) => (a.datetime < b.datetime ? -1 : 1));
    return cloneRows;
    // return _.get(rows, [0, 'rows'], []);
  }, [rows]);
  React.useEffect(() => {
    backbone.registerNotification({
      success: (msg: string) => enqueueSnackbar(msg, { variant: 'success' }),
      error: (msg: string) => enqueueSnackbar(msg, { variant: 'error' }),
    });
    backbone.setGridApi({
      grid: {
        paginationSetPageSize: (limit) => undefined,
        refreshServerSideStore: refreshData,
        deselectAll: () => undefined,
      },
      column: {},
    });
    backbone.init();
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        chartRef.current.isInit = true;
        refreshData();
      });
    }, 100 /* cool down time*/);
  }, []);

  React.useEffect(() => {
    backbone.getCallback('onBackboneReady')(backbone);
  }, []);

  const refreshData = () => {
    setLoadingChart(true);
    backbone
      .getRows()
      .then((value) => {
        backbone.changeConfig('lastUpdated', moment());
        setRows(value);
        setResourceMetric(get(value, '[0].resourceMetric', []));
        setLoadingChart(false);
      })
      .catch((err) => {
        console.error('Get row fail', err);
        setLoadingChart(false);
      });
  };

  React.useEffect(() => {
    if (chartRef.current.isInit) {
      refreshData();
    }
    let tid = 0;
    if (get(config, 'refreshInterval', null)) {
      console.info('auto refresh');
      tid = window.setInterval(() => {
        refreshData();
      }, get(config, 'refreshInterval'));
    }
    return () => {
      window.clearInterval(tid);
    };
  }, [config]);

  const handleExport = async () => {
    const response = await backbone.exportEtable();
    onToast({ title: response.title, messages: response.messages, variant: response.variant });
  };

  const titleTable = backbone.getConfig('title');
  const tableType = backbone.getConfig('tableType');

  const statusChart = backbone.getStatus('table');

  const generateTable = (typeTable: TypeEDashBoard | undefined) => {
    switch (typeTable) {
      default: {
        const isShowCohort = get(chartConfig, 'config.timelineCohorts', false);
        const availableMetric = backbone.getConfig('metric');
        const orderMetric = backbone.getOptions('metric').filter((el) => availableMetric.includes(el.id));
        const valueGroupBy = get(chartConfig, 'config.groupBy', '');
        const valueDimension = get(chartConfig, 'config.valueDimension', '');
        const dimensionName = valueDimension ? uniq(rowData.map((i) => i[valueDimension])) : [];
        const valueDisplay = get(chartConfig, 'config.display', '');
        const isColumnStacked = get(chartConfig, 'config.isColumnStacked', false);

        const charts = [];
        // if (isShowCohort) {
        //   const data = rowData.map((el) => el.rowsCohort || el.rowsCurrent);
        // }
        switch (valueGroupBy) {
          case 'dimension':
            if (valueDisplay === 'single' && orderMetric.length >= 2) {
              dimensionName.forEach((i) => {
                if (Object.entries(groupBy(rowData, 'metric')).length === 0) {
                  charts.push({
                    data: { rows: [], headers: [] },
                    total: 0,
                    metric: i,
                    currency: '',
                    isSettingYAxis: false,
                    isColumnStacked,
                  });
                } else {
                  for (const [k, v] of Object.entries(groupBy(rowData, 'metric'))) {
                    const chartMapping = [];
                    chartMapping.push({
                      label: k,
                      key: k,
                      data: v.filter((el) => el[valueDimension] === i),
                    });

                    const currency = uniq(v.map((i) => i.unit));
                    const total = v.reduce((a, b) => {
                      if (b[valueDimension] === i) {
                        a += b.value;
                      }
                      return a;
                    }, 0);

                    charts.push({
                      data: { rows: chartMapping, headers: uniq(rowData.map((i) => i.datetime)).sort() },
                      total,
                      metric: i,
                      currency: currency[0],
                      isSettingYAxis: false,
                      isColumnStacked,
                    });
                  }
                }
              });
            } else {
              dimensionName.forEach((i) => {
                const chartMapping = [];
                const dimensionRows = rowData.filter((value) => value[valueDimension] === i);
                const metric = uniq(dimensionRows.map((i) => i.metric));

                if (Object.entries(groupBy(dimensionRows, 'metric')).length === 0) {
                  const metric = uniq(dimensionRows.map((i) => i.metric));
                  charts.push({
                    pie: [],
                    data: { rows: [], headers: [] },
                    total: 0,
                    metric: i,
                    currency: '',
                    isSettingYAxis: true,
                    hideShowTotal: metric.length > 1,
                    isColumnStacked,
                  });
                } else {
                  // if (chartConfig.config.chartType === 'pie') {
                  //   for (const [k, v] of Object.entries(groupBy(dimensionRows, 'datetime'))) {
                  //     chartMapping.push({
                  //       label: v.map((el) => el.metric),
                  //       datasets: [
                  //         {
                  //           label: k,
                  //           data: v.map((el) => el.value),
                  //           actives: v.map((el) => true),
                  //           backgroundColor: v.map((el, index) => colorsChartLegend[index].stroke),
                  //           borderWidth: 0,
                  //           hoverOffset: 4,
                  //         },
                  //       ],
                  //     });
                  //   }
                  // } else {
                  for (const [k, v] of Object.entries(groupBy(dimensionRows, 'metric'))) {
                    const label = orderMetric.find((el) => el.id == k);
                    const currency = get(v, '[0].currency', '');
                    chartMapping.push({
                      label: label ? label.name : k,
                      key: k,
                      data: v,
                      currency,
                    });
                  }
                  // }

                  const total = rowData.reduce((a, b) => {
                    if (b[valueDimension] === i) {
                      a += b.value;
                    }
                    return a;
                  }, 0);

                  charts.push({
                    pie: chartConfig.config.chartType === 'pie' ? chartMapping : [],
                    data: { rows: chartMapping, headers: uniq(rowData.map((i) => i.datetime)).sort() },
                    total,
                    metric: i,
                    currency: currencyReq,
                    isSettingYAxis: true,
                    hideShowTotal: metric.length > 1,
                    isColumnStacked,
                  });
                }
              });
            }
            break;

          case 'metric':
            if (valueDisplay === 'single' && orderMetric.length >= 2) {
              orderMetric.forEach((headerItem) => {
                const metric = headerItem.name;
                const metricRows = rowData.filter((i) => i.metric === headerItem.id);
                if (Object.entries(groupBy(metricRows, valueDimension)).length === 0) {
                  charts.push({
                    data: { rows: [], headers: [] },
                    total: 0,
                    cohortTotal: 0,
                    metric,
                    currency: '',
                    isSettingYAxis: false,
                    isColumnStacked,
                  });
                } else {
                  for (const [k, v] of Object.entries(groupBy(metricRows, valueDimension))) {
                    const isCohort = v.every((el) => el.value_cohort);
                    const currency = get(v, '[0].currency', '');
                    const chartMapping = [];
                    if (isCohort) {
                      const cohortData = v.map((el) => ({
                        ...el,
                        value: el.value_cohort,
                        datetime: el.datetime_cohort,
                      }));
                      chartMapping.push(
                        {
                          label: 'Current',
                          key: k,
                          data: v,
                          currency: currency,
                        },
                        {
                          label: `Previous`,
                          key: k,
                          data: cohortData,
                          isCohort: true,
                          currency: currency,
                        },
                      );
                    } else {
                      chartMapping.push({
                        label: valueDimension === '_all' || !valueDimension ? 'All' : k,
                        key: k,
                        data: v,
                        currency: currency,
                      });
                    }

                    const total = get(v, '[0].total', 0);
                    const cohortTotal = get(v, '[0].cohort_total', null);

                    charts.push({
                      data: { rows: chartMapping, headers: uniq(rowData.map((i) => i.datetime)).sort() },
                      total,
                      cohortTotal,
                      metric,
                      currency: currency,
                      isSettingYAxis: false,
                      isColumnStacked,
                    });
                  }
                }
              });
            } else {
              orderMetric.forEach((headerItem) => {
                const chartMapping = [];
                const metric = headerItem.name;
                const metricRows = rowData.filter((i) => i.metric === headerItem.id);

                if (Object.entries(groupBy(metricRows, valueDimension)).length === 0) {
                  charts.push({
                    data: { rows: [], headers: [] },
                    total: 0,
                    cohortTotal: 0,
                    metric,
                    isSettingYAxis: true,
                    currency: '',
                    isColumnStacked,
                  });
                } else {
                  const dates = uniq(rowData.map((i) => i.datetime)).sort();
                  for (const [k, v] of Object.entries(groupBy(metricRows, valueDimension))) {
                    const currency = get(v, '[0].currency', '');
                    chartMapping.push({
                      label: valueDimension === '_all' || !valueDimension ? 'All' : k,
                      key: k,
                      data: dates.map((d) => {
                        const exits = v.find((i) => i.datetime === d);
                        return exits ?? { value: null };
                      }),
                      currency,
                    });
                  }
                  const total = get(chartMapping, '[0].data.[0].total', 0);
                  const cohortTotal = get(chartMapping, '[0].data.[0].cohort_total', null);

                  charts.push({
                    data: { rows: chartMapping, headers: dates },
                    total,
                    cohortTotal,
                    metric,
                    isSettingYAxis: true,
                    currency: '',
                    isColumnStacked,
                  });
                }
              });
            }
            break;

          default:
            orderMetric.forEach((headerItem) => {
              const chartMapping = [];
              const metric = headerItem.name;
              const metricRows = rowData.filter((i) => i.metric === headerItem.id);

              if (Object.entries(groupBy(metricRows, valueDimension)).length === 0) {
                charts.push({
                  data: { rows: [], headers: [] },
                  total: 0,
                  metric,
                  currency: '',
                  isSettingYAxis: false,
                  isColumnStacked,
                });
              } else {
                for (const [k, v] of Object.entries(groupBy(metricRows, valueDimension))) {
                  chartMapping.push({
                    label: k,
                    key: k,
                    data: v,
                  });
                }

                const currency = uniq(metricRows.map((i) => i.unit));
                const total = rowData.reduce((a, b) => {
                  if (b.metric === headerItem.id) {
                    a += b.value;
                  }
                  return a;
                }, 0);

                charts.push({
                  data: { rows: chartMapping, headers: uniq(rowData.map((i) => i.datetime)).sort() },
                  total,
                  metric,
                  currency: currency[0],
                  isSettingYAxis: false,
                  isColumnStacked,
                });
              }
            });
            break;
        }
        const MenuOptions: DropdownMenuDataType[] = [
          ...(ff.time_periodically
            ? [
                {
                  title: 'View by',
                  name: 'periodically ',
                  icon: 'calendar',
                  colorStartIcon: '#253746',
                  onClick: () => backbone.changeVisibility('periodically', true),
                },
              ]
            : []),
          {
            title: 'Properties',
            name: 'properties',
            icon: 'properties',
            colorStartIcon: '#253746',
            onClick: () => backbone.changeVisibility('property', true),
          },
          {
            title: 'Change chart type',
            name: 'change-chart-type',
            icon: 'task',
            // iconSize: '14px',
            colorStartIcon: '#253746',
            disable: true,
            onClick: () => undefined,
          },
          // {
          //   title: 'Change currency',
          //   name: 'currencySwitch',
          //   icon: 'changeCurrency',
          //   // iconSize: '18px',
          //   colorStartIcon: '#253746',
          //   onClick: () => backbone.changeVisibility('currencySwitch', true),
          // },
          {
            title: 'Export',
            name: 'export',
            icon: 'export',
            // iconSize: '18px',
            colorStartIcon: '#253746',
            onClick: () => handleExport(),
            disable: false,
          },
        ];
        if (isShowCohort) {
          MenuOptions.unshift({
            title: 'Cohort',
            name: 'cohort',
            icon: 'calendar',
            colorStartIcon: '#253746',
            onClick: () => backbone.changeVisibility('calendar', true),
          });
        }

        return (
          <DarkModeContext.Provider value={{ isDarkMode: isFullPage }}>
            <ExpansionTable
              label={titleTable}
              defaultExpanded={true}
              className={`${classes.toggle} ${isMobile ? classes.headerMobile : null}`}
              headerRight={<HeaderRight />}
            >
              <>
                <TableActions excludedViewBy={['hourly', 'regularly']} dataMenu={MenuOptions} />

                <Grid container spacing={3} style={{ height: '100%', marginTop: '16px', marginBottom: '16px' }}>
                  {loadingChart ? (
                    <Box>
                      <LoadingIcon color={'#253746'} />
                    </Box>
                  ) : chartConfig ? (
                    <ChartETable
                      chartConfig={{
                        ...chartConfig,
                        chart: charts,
                      }}
                      backbone={backbone}
                    ></ChartETable>
                  ) : (
                    <LineChartEDashboard data={{ rows: rows }} statusChart={'success'} />
                  )}
                </Grid>
              </>
            </ExpansionTable>
          </DarkModeContext.Provider>
        );
      }
    }
  };
  return <tableContext.Provider value={backbone}>{generateTable(tableType)}</tableContext.Provider>;
};

export const makeDashboard = ({
  config = {
    apiRequest: {},
    configuration: {},
    callback: {},
  },
  changeConfiguration = (config: any) => undefined,
  tableBackboneHook = useTableBackbone,
  tableContext = TableBackboneContext,
  selectedRows = [],
  chartConfig,
}: {
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
  chartConfig?: any;
}) => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <EChartContainer
        config={config}
        changeConfiguration={changeConfiguration}
        tableBackboneHook={tableBackboneHook}
        tableContext={tableContext}
        selectedRows={selectedRows}
        chartConfig={chartConfig}
      />
    </SnackbarProvider>
  );
};

function EChartContainer({
  config = {
    apiRequest: {},
    configuration: {},
    callback: {},
  },
  changeConfiguration = (config: any) => undefined,
  tableBackboneHook = useTableBackbone,
  tableContext = TableBackboneContext,
  selectedRows = [],
  chartConfig,
}: {
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
  chartConfig?: any;
}) {
  const { isEditMode } = React.useContext(NodeEditContext);

  if (isEditMode) {
    return (
      <DashboardContainer
        key={config.configuration._editorId}
        config={config}
        changeConfiguration={changeConfiguration}
        tableBackboneHook={tableBackboneHook}
        tableContext={tableContext}
        selectedRows={selectedRows}
        chartConfig={chartConfig}
      />
    );
  } else {
    return (
      <DashboardContainer
        config={config}
        changeConfiguration={changeConfiguration}
        tableBackboneHook={tableBackboneHook}
        tableContext={tableContext}
        selectedRows={selectedRows}
        chartConfig={chartConfig}
      />
    );
  }
}
