import * as React from 'react';
import LoadingIcon from '../../list-control/spinners/icon-spinner';
import { Button } from '@material-ui/core';

const TimeBasedCellFormat = (props: any) => {
  const { backbone, colDef, rowIndex, metricId } = props;

  const [cellData, setCellData] = React.useState(null);

  const fetchData = async () => {
    setCellData(null);
    try {
      const data = await backbone.addon('getTimeValue')(colDef.field, metricId, rowIndex);
      setCellData(data);
    } catch (e) {
      setCellData(e);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  // Loading
  if (!cellData) {
    return (
      <div className="eip1-additional-loading">
        <LoadingIcon />
      </div>
    );
  }

  // Error
  if (cellData.error)
    return (
      <div className="eip1-additional-error">
        <Button onClick={fetchData}>Retry</Button>
      </div>
    );

  // Success
  return (
    <div className="eip1-additional-col">
      <span className="eip1-additional-label">{`${cellData.value}${cellData.unit}`}</span>
      <span className="eip1-additional-subtext">{cellData.label}</span>
    </div>
  );
};

export default TimeBasedCellFormat;
