import * as React from 'react';
import { Tooltip, makeStyles, TooltipProps } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltipView: {
    background: '#253746',
    fontSize: '11px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '0 16px',
  },
  tooltipListItem: {
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
    },
  },
}));

const StyledTooltip = ({ children, ...rest }: TooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip classes={{ tooltip: classes.tooltipView }} {...rest}>
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
