import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Gartner = (props: SvgIconProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1.2" viewBox="0 0 1391 1550" width="14" height="15" {...props}>
    <title>gartner-svg</title>
    <path
      id="Layer"
      className="s0"
      d="m1390.6 1400c-184.6 99-369.2 155.6-612.9 148.5-451.5-13.5-777.6-294.5-777.6-767.7 0-471.3 329.3-772.9 778.2-779.9 227.7-3.3 430.9 54 590.4 194.8l-192.3 193.5c-98.4-84.2-243.1-133.1-416.7-125.4-282.4 12.9-452.8 218.6-456.7 500.3-3.8 305.4 184 516.3 477.2 524 138.3 3.2 236.7-14.8 322.2-53.4v-311.8h-304.8l20.6-256.6h571.7z"
    />
  </svg>
);

export default Gartner;
