import * as React from 'react';
import { EditorView as CodeMirrorEditorView } from '@codemirror/view';
import { Remirror, ThemeProvider, useHelpers, useRemirror, MarkdownToolbar } from '@remirror/react';
import { AllStyledComponent } from '@remirror/styles/emotion';
import { basicSetup } from 'codemirror';
import { ExtensionPriority } from 'remirror';
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TrailingNodeExtension,
  UnderlineExtension,
  MentionAtomExtension,
  CodeBlockExtension,
} from 'remirror/extensions';
import { UserSuggestor } from './mention';

import { languages } from '@codemirror/language-data';
import { CodeMirrorExtension } from '@remirror/extension-codemirror6';

let cachedExtensions;

const extensions = () => {
  // if (cachedExtensions) return cachedExtensions;

  cachedExtensions = [
    new LinkExtension({ autoLink: true }),
    new BoldExtension({}),
    new UnderlineExtension(),
    new StrikeExtension(),
    new ItalicExtension(),
    new HeadingExtension({}),
    new LinkExtension({}),
    new BlockquoteExtension(),
    new BulletListExtension({ enableSpine: true }),
    new OrderedListExtension(),
    new ListItemExtension({
      priority: ExtensionPriority.High,
      enableCollapsible: false,
    }),
    new CodeExtension(),
    new CodeBlockExtension({}),
    new TrailingNodeExtension(),
    new MarkdownExtension({ copyAsMarkdown: false }),
    new CodeMirrorExtension({
      languages,
      extensions: [basicSetup, CodeMirrorEditorView.baseTheme({})],
    }),
    new MentionAtomExtension({
      extraAttributes: { type: 'user' },
      matchers: [{ name: 'at', char: '@', appendText: ' ', matchOffset: 0 }],
    }),
  ];

  return cachedExtensions;
};

// write forwardRef component to get the ref of the editor
const MarkdownEditor = React.forwardRef((props, ref) => {
  const { manager, state } = useRemirror({
    extensions,
    content: props.defaultContent || `Enter your narrative in _markdown_ format`,
    selection: 'start',
    stringHandler: 'markdown',
  });

  React.useImperativeHandle(ref, () => ({
    getValue: () => {
      const content = manager.store.helpers.getMarkdown();
      return content;
    },
  }));

  return (
    <AllStyledComponent>
      <ThemeProvider>
        <Remirror manager={manager} initialContent={state} autoFocus autoRender="end">
          <MarkdownToolbar />
        </Remirror>
      </ThemeProvider>
    </AllStyledComponent>
  );
});

export default MarkdownEditor;
