import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import { produce } from 'immer';
import { cloneDeep, debounce, get, isEqual, mapValues, set } from 'lodash';
import React from 'react';
import { Prompt } from 'react-router';
import styled from 'styled-components';
import { v4 as uuid } from 'uuid';
import { useLog } from '../lib/log';
import { AppHeader } from '@ep/insight-ui/system/app-header';
import { DashboardFrame } from './components/dashboard';
import { EIPContext as DashboardFrameContext } from './components/dashboard/context';
import { usePage } from '@eip/next/lib/main';
import { NodeData } from './components/dashboard/type';
import { makeWorkflowProvider } from './components/dashboard/workflow-provider';
import { TitleEditable } from './components/title-editable';
import { SCREEN_COLUMNS } from './constant';
// import { ReactComponent as PrintLogo } from './images/epsilo-logo-light.svg';
import { PrintControl } from './print-control';
import { PageTitle as PageTitleEditor } from './components/editor-title-editable';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import RichPrompt from '@ep/insight-ui/elements/rich-prompt';
import Icon from '@ep/insight-ui/icons/Icon';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));
const log = useLog('dbf:dashboard-app');

function PageTitle() {
  const classes = useStyles();
  const { title, updateTitle, titleEditable, description, formulaTitle, formulaPageHashtag, formulaPageSubtext } =
    usePage();
  const [updatedTitle, setUpdatedTitle] = React.useState<{ text: string }>({
    text: formulaTitle,
  });

  // FIXME: the title updating is cumbersome
  const handleChangeTitle = React.useCallback(
    (value) => {
      if (updatedTitle.text !== value.text) {
        setUpdatedTitle(value);
        updateTitle(value.text);
      }
    },
    [updatedTitle.text],
  );

  React.useEffect(() => {
    // if (title.text !== updatedTitle.text && title.ts > updatedTitle.ts) {
    //   setUpdatedTitle({ text: title.text, ts: title.ts });
    // }
    setUpdatedTitle({
      text:
        String(title).startsWith('=') && String(title).includes('uQuery') && !formulaTitle ? '' : formulaTitle || title,
    });
  }, [formulaTitle, title]);

  return (
    <div className={classes.container}>
      <RichPrompt text={formulaPageHashtag} />
      <Typography variant="h1" className={'page-title'} placeholder="Untitled">
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <TitleEditable
            placeholder={'Untitled'}
            value={updatedTitle}
            onChange={handleChangeTitle}
            readOnly={!titleEditable}
          />
          {description ? (
            <StyledTooltip
              title={<div style={{ whiteSpace: 'pre-line', padding: '2px 4px' }}>{description}</div>}
              placement={'right'}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Icon type={'ic/mi:circle-information/#737373'} size={'20px'} width={'20px'} />
              </div>
            </StyledTooltip>
          ) : null}
        </div>
      </Typography>
      <RichPrompt text={formulaPageSubtext} />
    </div>
  );
}

export function DashboardSinglePreset({ presetId, mode = 'view' }) {
  const { currentPage, getLoadingStatus, actions, systemPageId } = usePage();

  const isLoading =
    getLoadingStatus(actions.loadSinglePage.type()).status &&
    getLoadingStatus(actions.loadSingleSystemPage.type()).status;
  const [isEditMode] = React.useState(mode === 'edit');

  const handleToggleEditMode = (enable) => {
    if (enable) {
      window.location.href = `/page/${systemPageId}/edit`;
    } else {
      window.location.href = `/page/${currentPage.blockEid}`;
    }
  };

  const pageTitle = React.useMemo(() => (ff.lowcode && isEditMode ? <PageTitleEditor /> : <PageTitle />), []);

  log('loading', isLoading, currentPage);
  if (isLoading || !currentPage) {
    return <div>Loading...</div>;
  }

  return (
    <DashboardAppContainer className={clsx('dashboard-container')} isEditMode={isEditMode}>
      <div className="app-controls">{pageTitle}</div>
      {!isLoading && (
        <DashboardPage key={presetId} isEditMode={isEditMode} onExitEditMode={() => handleToggleEditMode(false)} />
      )}
    </DashboardAppContainer>
  );
}

function DashboardAppContainer({ className, isEditMode = false, children }) {
  const { currentPage, getPageServer, setPageServer } = usePage();

  const workflowId = get(currentPage, 'properties.workflowId', null);

  const WorkflowProvider = React.useMemo(() => {
    log('makeworkflowprovider');
    return makeWorkflowProvider(!isEditMode ? workflowId : null);
  }, [isEditMode, workflowId]);

  if (isEditMode) {
    setPageServer(getPageServer().systemPageServer);
  }

  return (
    <WorkflowProvider>
      <StyledDashboardApp className={className}>{children}</StyledDashboardApp>
    </WorkflowProvider>
  );
}

type actionType = 'SET' | 'SET_LAYOUT' | 'REMOVE_NODE' | 'UPDATE_NODE' | 'CHANGE_BREAKPOINT' | 'CHANGE_LAYOUT_COLUMNS';
function dashboardReducer(
  state: Dashboard & { breakpoint: string } = {
    content: [],
    blockEid: null,
    blockType: null,
    parentId: null,
    layouts: null,
    nodes: null,
    breakpoint: null,
    layoutColumns: null,
  },
  action: { type: actionType; payload: any },
) {
  log('state update ===> ', { state, action });

  const { payload } = action;
  switch (action.type) {
    case 'SET': {
      state = produce(state, (draft) => {
        if (payload.layouts !== undefined) {
          draft.layouts = payload.layouts;
        }
        if (payload.nodes !== undefined) {
          draft.nodes = payload.nodes;
        }
      });
      break;
    }
    case 'SET_LAYOUT': {
      state = produce(state, (draft) => {
        draft.layouts[state.breakpoint] = payload.layouts;
        draft.layouts.lg = payload.layouts;
      });
      break;
    }
    case 'CHANGE_BREAKPOINT': {
      if (state.breakpoint === payload.breakpoint) break;
      state = produce(state, (draft) => {
        draft.breakpoint = payload.breakpoint;
      });
      break;
    }
    case 'REMOVE_NODE': {
      state = produce(state, (draft) => {
        const id = payload.nodeId;
        const nuLayouts = mapValues(state.layouts, (layout) => {
          return (layout || []).filter((i) => i.id !== id);
        });

        const nuNodes = state.nodes.filter((n) => {
          return n.id !== id;
        });

        draft.layouts = nuLayouts;
        draft.nodes = nuNodes;
      });
      break;
    }
    case 'UPDATE_NODE': {
      state = produce(state, (draft) => {
        const id = payload.nodeId;
        const index = state.nodes.findIndex((i) => i.id === id);
        draft.nodes[index] = payload.data;
      });
      break;
    }
    case 'CHANGE_LAYOUT_COLUMNS': {
      state = produce(state, (draft) => {
        draft.layoutColumns = payload.layoutColumns;
      });
      break;
    }
  }
  log('state update ===> ', { state, action });
  return state;
}

function layoutMigration(payload) {
  if (payload.layouts['lg']) {
    const grid = payload.layouts['lg'].map((i) => ({
      ...i,
      layout: { ...i.layout, w: i.layout.w },
    }));

    const breakpoints = Object.keys(payload.layouts).concat(['lg', 'md', 'sm', 'xs', 'xxs']);

    return {
      ...payload,
      layouts: breakpoints.reduce((carry, bp) => {
        return { ...carry, [bp]: grid };
      }, {}),
    };
  }
  return payload;
}

export function DashboardPage({ isEditMode, onExitEditMode }) {
  const { currentPage, updatePage } = usePage();
  const [state, dispatch] = React.useReducer(
    dashboardReducer,
    {
      layouts: currentPage.layouts,
      nodes: currentPage.nodes,
      breakpoint: currentPage.breakpoint,
      blockEid: currentPage.blockEid,
      blockType: currentPage.blockType,
      parentId: currentPage.parentId,
      content: currentPage.content,
      layoutColumns:
        currentPage.layoutColumns && currentPage.layoutColumns['lg']
          ? currentPage.layoutColumns
          : {
              lg: SCREEN_COLUMNS.lg.default,
              md: SCREEN_COLUMNS.md.default,
              sm: SCREEN_COLUMNS.sm.default,
              xs: SCREEN_COLUMNS.xs.default,
              xxs: SCREEN_COLUMNS.xxs.default,
            },
    },
    layoutMigration,
  );
  const { onToastMultiple } = useToast();

  // const { current, push, undo, redo } = useDashboardHistory();
  const [historyId, setHistoryId] = React.useState('init');
  const updateRef = React.useRef({
    isInitial: true,
    isJustAddedNewNode: false,
    isUsingHistory: false,
  });
  const { layouts, nodes, layoutColumns } = state;
  log('current=>', layouts, nodes, layoutColumns);

  // useHotkeys('ctrl+z,command+z', () => {
  //   log('undo');
  //   updateRef.current.isUsingHistory = true;
  //   undo();
  // });
  // useHotkeys('ctrl+shift+z,command+shift+z', () => {
  //   log('redo');
  //   updateRef.current.isUsingHistory = true;
  //   redo();
  // });

  React.useEffect(() => {
    if (!isEditMode) {
      if (updateRef.current.isInitial) {
        updateRef.current.isInitial = false;
        return;
      }
      const payload = {
        ...currentPage,
        nodes: state.nodes,
        layouts: state.layouts,
        layoutColumns: state.layoutColumns,
      };

      // if (isEditMode) {
      //   payload = produce(payload, (draft) => {
      //     set(draft, 'properties.version', Date.now());
      //   });
      //   log('update page', payload.properties);
      // }
      // log('update page', JSON.stringify(payload));

      updatePage(payload, onToastMultiple);

      // if (!updateRef.current.isUsingHistory) {
      //   push(payload);
      // }
    }
  }, [state]);

  // React.useEffect(() => {
  //   if (current && updateRef.current.isUsingHistory) {
  //     log('history trigger', current);
  //     const { dashboard, historyId } = JSON.parse(current);
  //     const record = dashboard;
  //     dispatch({
  //       type: 'SET',
  //       payload: {
  //         nodes: record.nodes,
  //         layouts: record.layouts,
  //         reason: 'history',
  //       },
  //     });
  //     log('history id', historyId);
  //     setHistoryId(historyId);
  //   }
  // }, [current]);

  const handleLayoutChange = React.useCallback(
    debounce((newLayout, previousLayout) => {
      log('layout change', newLayout, updateRef.current);
      if (!updateRef.current.isJustAddedNewNode && !updateRef.current.isUsingHistory) {
        dispatch({
          type: 'SET_LAYOUT',
          payload: { layouts: newLayout },
        });
      } else {
        updateRef.current.isJustAddedNewNode = false;
        updateRef.current.isUsingHistory = false;
      }
    }, 700),
    [],
  );

  if (!layouts || !nodes) return <div>Loading...</div>;

  const handleAddNewNode = (x: number, y: number, w: number, h: number) => {
    const node = {
      id: uuid(),
      layout: {
        x,
        y,
        w,
        h,
      },
    };
    updateRef.current.isJustAddedNewNode = true;
    dispatch({
      type: 'SET',
      payload: {
        layouts: mapValues(layouts, (layout) => {
          return layout.concat(node);
        }),
        nodes: nodes.concat({
          id: node.id,
          chartLibId: undefined,
        }),
      },
    });
    return node;
  };

  const handleBreakpointChange = React.useCallback(
    (newBp, newCols) => {
      log('breakpoint update', newBp, state.breakpoint);
      if (newBp !== state.breakpoint) {
        dispatch({
          type: 'CHANGE_BREAKPOINT',
          payload: { breakpoint: newBp },
        });
      }
    },
    [state],
  );

  const handleRemoveNode = (id: string) => {
    dispatch({ type: 'REMOVE_NODE', payload: { nodeId: id } });
  };

  const handleNodeSubmit = (nodeId: string, payload: NodeData) => {
    dispatch({
      type: 'UPDATE_NODE',
      payload: {
        nodeId,
        data: payload,
      },
    });
  };

  const handleUpdateNodes = (nodes) => {
    dispatch({
      type: 'SET',
      payload: {
        nodes: nodes,
      },
    });
  };

  const handleGridChange = React.useCallback(
    (layouts, layoutColumns) => {
      if (!isEqual(layoutColumns, state.layoutColumns)) {
        dispatch({
          type: 'CHANGE_LAYOUT_COLUMNS',
          payload: {
            layoutColumns: layoutColumns,
          },
        });
      }
    },
    [state],
  );

  const handlePublish = async () => {
    const payload = {
      ...currentPage,
      nodes: state.nodes,
      layouts: state.layouts,
      layoutColumns: state.layoutColumns,
    };

    return updatePage(payload, onToastMultiple);
  };

  return (
    <DashboardFrameContext.Provider
      value={{
        onAddNewNode: handleAddNewNode,
        onBreakpointChange: handleBreakpointChange,
        onLayoutChange: handleLayoutChange,
        onRemoveNode: handleRemoveNode,
        onNodeSubmitUpdate: handleNodeSubmit,
        onUpdateNodes: handleUpdateNodes,
        onNodeRequestUpdate: () => {},
        onGridChange: handleGridChange,
        onDuplicateNode: () => {},
        onToggleNodeDraggable: () => {},
        mode: isEditMode ? 'edit' : 'view',
      }}
    >
      <React.Fragment>
        {isEditMode && (
          <Box py={2}>
            <AppHeader onPublish={handlePublish}></AppHeader>
          </Box>
        )}
        {/* <Box className={'onlyprint'} marginBottom={2}>
          <PrintLogo />
        </Box> */}
        <DashboardFrame
          layouts={layouts}
          nodes={nodes}
          layoutColumns={layoutColumns}
          historyId={historyId}
          isEditMode={isEditMode}
        />
        <PrintControl />
      </React.Fragment>
    </DashboardFrameContext.Provider>
  );
}

const StyledDashboardApp = styled('div')`
  margin-bottom: 5em;
  &.edit-mode {
    margin-right: 210px;
  }
  & .app-controls {
    z-index: 1200;
    display: flex;
    flex-direction: row;
    margin-bottom: 2em;
    justify-content: space-between;
  }
  & .page-title {
    min-width: 20em;
    &:focus-visible {
      outline: none;
    }
    &:empty:before {
      content: attr(placeholder);
      color: #eee;
    }
  }
`;
