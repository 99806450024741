import * as React from 'react';
import clsx from 'clsx';

import { Button, makeStyles, Typography } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles(() => ({
  ray: {
    position: 'relative',
    padding: '6px 0 6px 10px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    width: '100%',
    justifyContent: 'flex-start',
  },
}));

const ButtonWithIcon = ({ title, onClick, icon }) => {
  const classes = useStyles();

  return (
    <Button className={clsx(classes.ray)} onClick={onClick}>
      <div style={{ display: 'flex', alignItems: 'center', columnGap: '12px' }}>
        <div style={{ width: '14px', display: 'flex', alignItems: 'center' }}>
          <Icon type={icon} color={'#0000008a'} />
        </div>
        <Typography variant="subtitle1" style={{ fontSize: '14px' }}>
          {title}
        </Typography>
      </div>
    </Button>
  );
};

export default ButtonWithIcon;
