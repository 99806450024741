import * as React from 'react';
import ColumnChartResposive from '../../../chartlib/column-chart/index-column';
import { generalLabels, generateRandomData } from '../../../chartlib/helper';
import { IDataChart } from '../../../chartlib/type';
import { IConfigTable, makeTable } from '@ep/insight-ui/elements/table/table-container';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { CHART_CONFIG, FORMAT_CHART } from '@ep/insight-ui/chartlib/area-chart/chart-config';
import moment from 'moment';

const data: IDataChart = {
  headers: generalLabels({ month: '10', year: '2021', DateFormatView: 'DD/MM/YYYY' }),
  rows: [
    {
      label: 'Vietnam',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 12000,
        minValue: 5000,
      }),
    },
  ],
};

const { period, formatTime } = FORMAT_CHART.traction;

const valueDate = data.rows.reduce((a, b) => {
  b.data.forEach((el) => {
    const [time, value] = Object.values(el);
    if (!a[b.label])
      a[b.label] = {
        [moment(time).format(formatTime)]: value,
      };
    else a[b.label][moment(time).format(formatTime)] = value;
  });
  return a;
}, {});

export const ColumnChart = () => {
  const config = {
    apiRequest: {},
    configuration: {
      ...CHART_CONFIG,
      title: 'Column Chart',
    },
  };

  const changeConfiguration = (config: IConfigTable) => {
    console.log('Update configuration', config);
  };
  const headers = ['country', ...data.headers.map((el) => `${el}`)];
  const RESPONSE_CHART = {
    data: {
      headers,
      pagination: { limit: 100, page: 1, total: -1 },
      primaryKeys: [],
      rows: data.rows.map((row) => ({
        country: row.label,
        ...valueDate[row.label],
      })),
    },
    message: 'OK',
    code: 200,
  };

  const tableContainer = React.useMemo(
    () =>
      makeTable({
        config: {
          ...config,
          addons: {
            'table.columns.enhance': (columnDefs, columns, backbone) => {
              const additionalColumns = data.headers.map((el) => {
                return {
                  headerName: moment(el).format(formatTime),
                  field: moment(el).format(formatTime),
                  colId: moment(el).format(formatTime),
                  resizable: false,
                  suppressMovable: true,
                  cellStyle: {
                    borderRightColor: 'whitesmoke',
                  },
                  headerComponentParams: {
                    type: 'number',
                  },
                  width: 200,
                  cellRendererParams: {},
                  cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: '10px',
                  },
                };
              });
              return [...columnDefs, ...additionalColumns];
            },
            'datasource.apiRequest.getTableData': async (params, originRequest) => {
              return Promise.resolve(RESPONSE_CHART);
            },
          },
        },
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <>
      <div style={{ height: '100vh', width: '100%', marginBottom: '100px' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <ColumnChartResposive
            keyChart={'column_demo'}
            title="Title"
            value="600"
            currency="PHP"
            percent={15}
            data={data}
            stateChart="success"
          />
          {tableContainer}
        </div>
      </div>
    </>
  );
};
export default ColumnChart;
