import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import Selection from '@ep/insight-ui/elements/input-form/form-templates/form-controls/selection';
import GroupButtonSubmit from '../group-button-submit';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { get, isArray } from 'lodash';

const useStyles = makeStyles({
  container: {
    padding: '0 10px',
    minWidth: '250px',
  },
  header: {
    paddingLeft: '0px',
    paddingBottom: '15px',
  },
  select: {
    width: '100%',
  },
});

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onLoad?: (value: any) => void;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  field?: string;
};

type OptionSelectType = {
  value: string;
  label: string;
};

const options: OptionSelectType[] = [
  {
    value: '1',
    label: 'On',
  },
  {
    value: '0',
    label: 'Off',
  },
];

const AutoRenew = ({ data, title = '', payload, onLoad, onSubmit, anchorEl, setAnchorEl, field = '' }: Props) => {
  const classes = useStyles();
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const storefrontId = get(data, 'value.storefront_id', '');
  const [valueSelected, setValueSelected] = React.useState(String(get(data, 'value.label', '')));
  const [valuePullPush, setValuePullPush] = React.useState({
    pull: String(get(data, 'value.storefront_pull_value', '0')),
    push: String(get(data, 'value.storefront_push_value', '0')),
  });
  const { onToast } = useToast();

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handlePopup = (res) => {
    if (res && res.success) {
      onToast({
        title: 'Success',
        messages: res.message,
        variant: 'success',
      });
      handleClosed();
    } else {
      onToast({
        title: 'Error',
        messages: res.message,
        variant: 'error',
      });
    }
  };
  const handleSubmit = async () => {
    let valueRenew;
    switch (field) {
      case 'auto_renew':
        valueRenew = valueSelected;
        break;
      case 'auto_pull_push':
        valueRenew = valuePullPush;
        break;
      default:
        valueRenew = '';
        break;
    }
    const response = await onSubmit({
      storefrontId,
      valueRenew,
      row: data.node.data,
      rowNode: data.node,
      backbone,
    });
    if (isArray(response)) {
      response.forEach((res) => {
        handlePopup(res);
      });
    } else {
      handlePopup(response);
    }
  };

  const renderForm = () => {
    switch (field) {
      case 'auto_renew':
        return (
          <>
            <SelectForm
              options={options}
              value={valueSelected}
              onChange={(e) => setValueSelected(e.target.value)}
              className={classes.select}
            />
          </>
        );
      case 'auto_pull_push':
        return (
          <div style={{ lineHeight: '40px' }}>
            <Selection
              options={options}
              onChange={(e) => setValuePullPush((preState) => ({ ...preState, pull: e.target.value }))}
              label={'Pull:'}
              value={valuePullPush.pull}
            />
            <Selection
              options={options}
              onChange={(e) => setValuePullPush((preState) => ({ ...preState, push: e.target.value }))}
              label={'Push:'}
              value={valuePullPush.push}
            />
          </div>
        );
      default:
        return <></>;
    }
  };

  return (
    <div className={classes.container}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      {renderForm()}
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} />
    </div>
  );
};

export default AutoRenew;
