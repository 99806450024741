/* eslint-disable react/prop-types */
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { createDataQueryFromConfig, TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep, debounce, get, set } from 'lodash';
import * as React from 'react';
import { filterOperators } from '../table-helper';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import hash from 'object-hash';
import produce from 'immer';

/**
 * ff.accumulation_compact_table:start
 */
import { Button } from '@material-ui/core';
import { makeTable } from '../table-container';
import { TableCompactFactoryContext } from '../compact-factory';
/**
 * ff.accumulation_compact_table:end
 */

type StyleProps = {
  isAuto: boolean;
};

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    height: '100%',
  },
  display: {
    display: 'none',
  },
  styleText: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
    ...(ff.accumulation_compact_table ? { width: '100%' } : {}),
  }),
  subtext: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#8C98A4',
  }),
  autoSubtext: ({ isAuto }: StyleProps) => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
    textAlign: 'left',
  }),
  hashtag: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#006EC6',
  }),
  numberAlign: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  rowGroup: {
    height: '100%',
    width: '100%',
  },
}));

export interface IValueCurrencyFormat {
  value: number | string;
  currency: string;
  defaultCurrency: string;
  typeDisplay?: 'currency' | 'datetime' | 'text-number';
  formatDateDisplay?: string;
  hashtag?: string;
  subtext?: string;
  field?: string;
}

const requestQueue: Record<string, { query: any; carry: any }> = {};

function getDefer() {
  const defer: { promise?: Promise<any>; resolve?: any; reject?: any } = {};
  defer.promise = new Promise((resolve, reject) => {
    defer.resolve = resolve;
    defer.reject = reject;
  });
  return defer;
}

function debouncePromise(aysncFun, ms) {
  const queueDefer = [];
  const debounceFun = debounce(
    (...args) =>
      aysncFun(...args)
        .then((...result) => queueDefer.forEach((defer) => defer.resolve(...result)))
        .catch((err) => queueDefer.forEach((defer) => defer.reject(err))),
    ms,
  );

  return (...args) => {
    const defer = getDefer();
    queueDefer.push(defer);
    debounceFun(...args);
    return defer.promise;
  };
}

class MaskFilter {}

function createQueryFilterValueCombination({ config, accumulativeFilterBuilder }) {
  const dataQuery = createDataQueryFromConfig({
    config,
    addons: {
      'datasource.apiRequest.getTableData': (params, originalRequest) => {
        const maskedParams = accumulativeFilterBuilder(new MaskFilter(), params);
        const cachedKey = hash({
          endpoint: config.endpoint,
          params: maskedParams,
        });
        if (!requestQueue[cachedKey]) {
          requestQueue[cachedKey] = { query: debouncePromise(originalRequest, 500), carry: {} };
        }
        requestQueue[cachedKey].carry = accumulativeFilterBuilder(requestQueue[cachedKey].carry, params);
        return requestQueue[cachedKey].query(requestQueue[cachedKey].carry);
      },
    },
  });

  return dataQuery;
}

const AccumulationFormat = (props: IPropsFormat<IValueCurrencyFormat>) => {
  const backbone = React.useContext(TableBackboneContext);

  const node = get(props, ['node'], []);
  const value = get(props, ['value'], []);
  const typeDisplay = 'currency';
  const currency = get(props, ['value', 'currency'], '');
  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const dataType = get(props, 'dataType', '');
  const classes = useStyles({ isAuto: false });
  const row = get(props, 'data', {});

  const [aggValue, setAggValue] = React.useState(null);
  const [isWaitingData, setIsWaitingData] = React.useState(false);

  const getter = get(backbone.getCellConfig(props.field), 'staticValue');
  const baseColumns = get(getter, ['baseColumns'], '');
  const accumulativeField = get(getter, ['accumulativeField'], '');
  const accumulativeFunction = get(getter, ['accumulativeFunction'], '');
  const accumlativeFilter = get(getter, ['filter'], []);

  let accuCompactConfig, compactConfig, ref, handleOnClosed, displayTable, displayTableCompact, defaultFilter;
  if (ff.accumulation_compact_table) {
    defaultFilter = React.useMemo(() => {
      return []
        .concat(baseColumns)
        .map((column) => {
          const colConfig = backbone.getCellConfig(column);
          const field = get(colConfig, 'valueGetter.id', '');
          const dataType = get(colConfig, 'dataType');
          return {
            type: 'filter',
            logicalOperator: 'AND',
            field: column,
            queryType: get(filterOperators, [dataType, 0, 0, 'value'], ''),
            queryValue: row[field],
            dataType,
          };
        })
        .concat({
          type: 'filter',
          logicalOperator: 'AND',
          field: 'marketplace',
          queryType: 'is',
          queryValue: row['MARKETPLACE.marketplace_code'],
          dataType: 'string',
        });
    }, [baseColumns]);

    accuCompactConfig = get(getter, ['compactConfig'], null);
    compactConfig = {
      config: {
        configuration: {
          ...accuCompactConfig,
          tableType: 'compact',
          filter: [
            ...accumlativeFilter,
            ...defaultFilter,
            ...backbone.getConfig('filter').filter((i) => i.field !== 'marketplace'),
          ],
          dateRange: backbone.getConfig('dateRange'),
        },
      },
    };
    ref = React.useRef<HTMLDivElement>();

    handleOnClosed = () => {
      console.log('close');
    };

    displayTable = React.useContext(TableCompactFactoryContext).display;

    displayTableCompact = (visible: boolean) => {
      const tableRender = <>{makeTable(compactConfig)}</>;
      displayTable({
        anchorEl: ref.current,
        tableConfig: compactConfig,
        tableEl: tableRender,
        visible: visible,
        onClosed: handleOnClosed,
      });
    };
  }

  React.useEffect(() => {
    setIsWaitingData(true);

    const colConfig = backbone.getCellConfig([].concat(baseColumns)[0]);
    const connectedIdField = get(colConfig, 'valueGetter.id', '');
    const connectedIdValue = row[connectedIdField];

    const filter = []
      .concat(baseColumns)
      .map((column) => {
        const colConfig = backbone.getCellConfig(column);
        const field = get(colConfig, 'valueGetter.id', '');
        const dataType = get(colConfig, 'dataType');
        return {
          type: 'filter',
          logicalOperator: 'AND',
          field: column,
          queryType: get(filterOperators, [dataType, 0, 0, 'value'], ''),
          queryValue: row[field],
          dataType,
        };
      })
      .concat({
        type: 'filter',
        logicalOperator: 'AND',
        field: 'marketplace',
        queryType: 'is',
        queryValue: row['MARKETPLACE.marketplace_code'],
        dataType: 'string',
      });

    const config = cloneDeep(get(getter, 'compactConfig'));
    set(config, 'mapping.campaign.filterField', 'ADS_CAMPAIGN.id');
    set(config, 'mapping.ad_object.filterField', 'ADS_OBJECT.id');
    set(config, 'requestIgnoreField', { metric: ['STOREFRONT.currency'] });

    // const dataQuery = createDataQueryFromConfig({
    //   config: config,
    // });

    const dataQuery = createQueryFilterValueCombination({
      config,
      accumulativeFilterBuilder: (carry, params) => {
        if (carry instanceof MaskFilter) {
          return produce(params, (draft) => {
            const connectedFilterIndex = params.filter.filters.findIndex((i) => i.field === connectedIdField);
            if (connectedFilterIndex > -1) draft.filter.filters.splice(connectedFilterIndex, 1);
          });
        } else {
          return produce(params, (draft) => {
            const connectedFilterIndex = params.filter.filters.findIndex((i) => i.field === connectedIdField);
            const previousConnectedFilter = get(carry, 'filter.filters', []).find((i) => i.field === connectedIdField);
            if (previousConnectedFilter) {
              const foundFilter = params.filter.filters[connectedFilterIndex];
              const accumValue = [].concat(previousConnectedFilter.value, foundFilter.value);
              draft.filter.filters[connectedFilterIndex] = {
                ...previousConnectedFilter,
                operator: 'in',
                value: accumValue,
              };
            }
          });
        }
      },
    });

    dataQuery
      .query({
        filter: [
          ...accumlativeFilter,
          ...filter,
          ...backbone.getConfig('filter').filter((i) => i.field !== 'marketplace'),
        ],
        dateRange: backbone.getConfig('dateRange'),
        groupBy: {
          columns: [].concat(baseColumns),
          aggregations: { [accumulativeField]: { func: accumulativeFunction } },
        },
      })
      .then((res) => {
        const frow = get(res, ['rowData'], []).find((i) => i[connectedIdField] == connectedIdValue);
        setAggValue(get(frow, [accumulativeField]));
        setIsWaitingData(false);
      })
      .catch((e) => {
        setIsWaitingData(false);
      });
  }, []);

  const getCurrency = (number: number, currency = '', dataType = '') => {
    if (!currency) return number || 0;
    return formatCurrency(number, currency);
  };
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={[]}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={true}
    >
      <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
        <Grid item xs={12} className={`${classes.numberAlign}`}>
          {!isWaitingData ? (
            <div className={classes.styleText} {...(ff.accumulation_compact_table ? { ref } : {})}>
              {ff.accumulation_compact_table ? (
                <Button onClick={() => displayTableCompact(true)}>
                  {typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`}
                </Button>
              ) : (
                typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`
              )}
            </div>
          ) : (
            <div style={{ padding: 10 }}>Waiting Data...</div>
          )}
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default AccumulationFormat;
