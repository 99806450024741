import { aim } from '@eip/next/lib/main';
import createAuth0Client, { Auth0Client } from 'auth0-spa-js';

let pagePrefix = '/page';
export const getPagePrefix = () => pagePrefix;
export const getPagePrefix1 = () => '/page';
export const setPagePrefix = (nuPrefix) => {
  pagePrefix = nuPrefix;
};
export const isSandboxMode = () => getPagePrefix().indexOf('/sandbox') === 0;

export const getPageUrn = (pageUrn, disabledSandbox = false) => {
  let finalPageUrn = pageUrn;
  if (!/\/[a-zA-Z0-9._!@#$%^&*()]+(?=\/page)/.test(finalPageUrn) && ff.next_left_menu) {
    finalPageUrn =
      (window ? window.location.href.match(/\/[a-zA-Z0-9._!@#$%^&*()]+(?=\/page)/)?.[0] : '') + `${pageUrn}`;
  }
  const [urn, search] = finalPageUrn.split('?');
  const searchParams = new URLSearchParams(search);

  if (isSandboxMode()) {
    if (!disabledSandbox) {
      searchParams.set('isSandbox', '1');
    } else {
      searchParams.delete('isSandbox');
    }
    return urn + '?' + searchParams.toString();
  }
  return finalPageUrn;
};

let webAuth: Auth0Client;
export function getAuth0() {
  if (webAuth) return Promise.resolve(webAuth);
  return createAuth0Client({
    // client_id: process.env.AUTH0_CLIENT_ID || '5Q1SDN07UntgTDyjJWddO14SQIZ2PIz0',
    // domain: process.env.AUTH0_DOMAIN || 'staging-auth.epsilo.io',
    // audience: process.env.AUTH0_AUDIENCE || 'https://quickstarts/api',

    client_id: process.env.AUTH0_CLIENT_ID || 'QHSWZsLWlHZKbeEFvSKeLBjXFKq1iJfq',
    domain: process.env.AUTH0_DOMAIN || 'auth.epsilo.io',
    audience: process.env.AUTH0_AUDIENCE || 'https://epsilo.us.auth0.com/api/v2/',

    redirect_uri: process.env.AUTH0_REDIRECT_URL || window.location.href,
  }).then((auth0) => {
    webAuth = auth0;
    return auth0;
  });
}

export const AUTH0_LOGOUT_URI =
  process.env.AUTH0_LOGOUT_URI || [window.location.protocol, '//', window.location.hostname].join('');

export function getApiHost(domain) {
  if (aim.getUserSettings().isAuth0 && !/auth0\.epsilo.io/.test(domain)) {
    return domain.replace(/\.epsilo\.io/, '-auth0.epsilo.io');
  }
  return domain;
}
