import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { TimerUI } from '../timer';

import imageLogo from '../../../images/epsilo-logo.png';
// import rbLogo from './rb.svg';
import rbLogo from './abbott.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#6A5B76',
      color: '#fff',
      top: 0,
      position: 'sticky',
      '.eip-full-page &': {
        padding: '0 24px',
        position: 'fixed',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      height: 56,
      minHeight: 'auto',
    },
    logo: {
      height: 32,
    },
    brandxEp: {
      display: 'flex',
      fontSize: 26,
      fontWeight: 500,
      color: '#fff',
      alignItems: 'end',
      '& span': {
        margin: '0 10px',
        color: 'rgba(255, 255, 255, 0.5)',
      },
    },
  }),
);

export const HeaderUI = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Grid container justify="space-between" alignItems="center">
            <Grid item className={classes.brandxEp}>
              <img src={rbLogo} className={classes.logo} />
              <span style={{ marginLeft: 12, paddingBottom: 3 }}>x</span>
              <img src={imageLogo} className={classes.logo} />
            </Grid>
            <Grid item>
              <TimerUI></TimerUI>
            </Grid>
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
