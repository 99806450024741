import { EventEmitter } from 'events';
import * as React from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardItem } from '@eip/next/src/components/dashboard';
import { GridAdapterContext } from '@eip/next/src/components/dashboard/grid-adapter';
import { Box } from '@material-ui/core';
import { enhanceNested } from '@eip/next/lib/react-grid-layout/nested';
import NestedRGL from '@eip/next/lib/react-grid-layout/nested-rgl';
import { useLog } from '@eip/next/lib/log';
import { actions } from '@eip/next/src/components/dashboard/dashboard-redux';
import { v4 as uuid } from 'uuid';

const log = useLog('chartlib:toggle-container');
export default class ToggleContainerChartlib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <ToggleContainerComponent data={data as BlockData} />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return null;
  }
}

let ContainerGridLayout = enhanceNested(WidthProvider(NestedRGL));

function ToggleContainerComponent({ data }: { data: BlockData }) {
  const childNodes = useSelector((state: DashboardState) => {
    let nodeIds = data.content || [];
    return {
      nodes: state.nodes.filter((n) => nodeIds.indexOf(n.id) > -1),
    };
  });
  const context = React.useContext(GridAdapterContext);
  const dispatch = useDispatch();

  return (
    <div style={{ height: '100%' }}>
      <Box height={context.squareWidth}>Toggle container</Box>
      <ContainerGridLayout
        onLayoutChange={(layout) => {
          log('onlayoutchange', layout);
          // dispatch(actions.updateBlockLayout({ blockId: data.id, layout }));
        }}
        className={'container-grid-layout'}
        cols={24}
        rowHeight={context.squareWidth}
        margin={[context.margin, context.margin]}
        layout={data.format?.layout}
        containerPadding={[0, 0]}
        measureBeforeMount={true}
        isDroppable={true}
        draggableHandle={'.drag-enabled'}
        onDropDragOver={(e) => {
          return {
            w: 4,
            h: 2,
          };
        }}
        onDrop={(layout, item, evt) => {
          // evt.stopPropagation();
          const chartLibId = evt.nativeEvent.dataTransfer.getData('plain/text');
          log('onDrop', layout, item);
          const newNodeId = uuid();
          dispatch(
            actions.addNewNode({
              node: {
                id: newNodeId,
                chartLibId,
                parentId: data.id,
                containerId: data.id,
                x: item.x,
                y: item.y,
                h: item.h,
                w: item.w,
              },
            }),
          );
        }}
      >
        {childNodes.nodes.map((i) => (
          <div key={i.id} className={'ep-dashboard-item'} data-node-id={i.id}>
            <DashboardItem key={i.id} nodeData={i}></DashboardItem>
          </div>
        ))}
      </ContainerGridLayout>
    </div>
  );
}
