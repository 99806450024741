import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Separate = (props: SvgIconProps) => (
  <SvgIcon {...props} width="3" height="3" viewBox="0 0 3 3" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.989258 1.42578V1.22559C0.989258 0.945638 1.07715 0.714518 1.25293 0.532227C1.43197 0.349935 1.67448 0.258789 1.98047 0.258789C2.28971 0.258789 2.53385 0.349935 2.71289 0.532227C2.89193 0.714518 2.98145 0.945638 2.98145 1.22559V1.42578C2.98145 1.70247 2.89193 1.93197 2.71289 2.11426C2.53711 2.29329 2.2946 2.38281 1.98535 2.38281C1.67936 2.38281 1.43685 2.29329 1.25781 2.11426C1.07878 1.93197 0.989258 1.70247 0.989258 1.42578Z"
      fill="#8C98A4"
    />
  </SvgIcon>
);

export default Separate;
