import * as React from 'react';
import { get } from 'lodash';
import * as uuid from 'uuid';
import moment from 'moment';

import {
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_FIELD_ID,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_VIEW,
} from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { DEFAULT_CONFIG } from '@ep/insight-ui/system/block/etable/table-config';
import { getFinalConfig } from '@ep/insight-ui/system/block/etable/etable-config/action-components/inline-edit/form-field/utils/config';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';
import { cloneDeep, debounce } from 'lodash';

const defaultConfiguration = DEFAULT_CONFIG;

export const useCustomTag = ({ rowData, onChange, handleSubmit, initialValue, payload }: any) => {
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [items, setItems] = React.useState([]);
  const [selectedItem, setSelectedItem] = React.useState({});
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const isItemExisted = React.useMemo(() => {
    return items.some((item) => item.value === searchValue);
  }, [searchValue, items]);

  const itemsRef = React.useRef({});
  const inputRef = React.useRef(null);

  let lastUpdated;
  if (ff.keep_block_refreshing) {
    lastUpdated = React.useRef(moment().valueOf());
  }

  const configuration = (payload || []).find((el) => el.field_key === 'tagConfig');

  const etableConfig = (
    get(configuration, ['field_configuration'], []).find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
  ).value;

  const valueField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_FIELD_ID) || {
      value: '',
    }
  ).value;

  const labelField =
    (
      (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_FIELD_LABEL) || {
        value: '',
      }
    ).value || valueField;

  const viewField = (
    (get(configuration, ['field_configuration'], []) || []).find((el) => el.key === INPUT_TYPE_VIEW) || {
      value: '',
    }
  ).value;

  const viewInfo = get(etableConfig, 'views', []).find(({ id }) => viewField === id) || get(etableConfig, ['view'], {});
  const view = {
    ...get(viewInfo, ['combinator'], {}),
    ...get(viewInfo, ['combinator', 'properties'], {}),
  };
  delete view.properties;

  const finalConfig = getFinalConfig({
    customConfig: { ...etableConfig, ...view, view: viewInfo },
    defaultConfiguration,
    mopFilter: {},
    lastUpdated,
    cellFormat: {},
  });

  const selectionFinalConfig = React.useMemo(() => {
    const clone = cloneDeep(finalConfig);
    clone.configuration.defaultPagination = {
      limit: 100,
    };

    return clone;
  }, []);

  const newBackbone = useTableBackbone(selectionFinalConfig);

  const refreshData = React.useCallback(
    debounce(
      () => {
        newBackbone
          .getRows()
          .then((response) => {
            setIsLoading(false);
            setItems(
              response
                .filter((el) => !!el[valueField])
                .map((el) => ({
                  label: String(el[labelField]),
                  value: el[valueField],
                })),
            );
          })
          .catch((err) => {
            setIsLoading(false);
            console.log('Get row fail', err);
          });
      },
      50,
      { trailing: true },
    ),
    [],
  );

  React.useEffect(() => {
    if (newBackbone) {
      newBackbone.setGridApi({
        grid: {
          paginationSetPageSize: (limit) => undefined,
          refreshServerSideStore: refreshData,
          deselectAll: () => undefined,
        },
        column: {},
      });
      newBackbone.init();
      window.setTimeout(() => {
        window.requestAnimationFrame(() => {
          refreshData();
        });
      }, 100 /* cool down time*/);
    }
  }, []);

  const allowDrag = false;

  const addNewItem = (value?: string) => {
    if (value) {
      onChange({
        target: {
          value: value,
        },
      });
      handleSubmit();
    } else {
      setItems([...items, { id: uuid.v4(), label: value, value, isNew: true, color: '#fff', allowConfig: true }]);
    }
  };

  const handleDuplicateItem = (item) => {
    setItems([...items, { ...item, id: uuid.v4() }]);
  };

  const selectItem = (item) => {
    onChange({
      target: {
        value: item.value,
      },
    });
    handleSubmit();
  };

  const deleteItem = (deletedItem) => {
    setItems(items.filter((item) => item.value !== deletedItem.value));
    if (selectedItem.value === deletedItem.value) {
      setSelectedItem(items[0]);
    }
  };

  const updateItem = (newItem) => {
    setItems(
      items.map((item) => {
        if (item.id && item.id === newItem.id) return newItem;
        return item;
      }),
    );
    if (selectedItem.value === newItem.value) {
      setSelectedItem(newItem);
    }
  };

  const moveCard = (dragIndex, hoverIndex) => {
    // const itemsClone = cloneDeep(items);
    // [itemsClone[dragIndex], itemsClone[hoverIndex]] = [itemsClone[hoverIndex], itemsClone[dragIndex]];
    // setItems(itemsClone);
  };

  React.useEffect(() => {
    // Get initial items
    if (initialValue) {
      setSelectedItem({
        value: initialValue,
        label: initialValue,
      });
    }
  }, [initialValue]);

  React.useEffect(() => {
    itemsRef.current = items;
  }, [items]);

  return {
    searchValue,
    setSearchValue,
    selectedItem,
    items,
    inputRef,
    addNewItem,
    handleDuplicateItem,
    selectItem,
    deleteItem,
    updateItem,
    moveCard,
    allowDrag,
    isLoading,
    isItemExisted,
  };
};
