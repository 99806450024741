import { useWorkflowScreen } from '@eip/next/lib/main';
import FormCampaignInfo from '@ep/insight-ui/elements/input-form/form-templates/form-groups/campaign-details/campaign-setting-form';
import { EventEmitter } from 'events';
import * as React from 'react';

type Options = Omit<React.ComponentProps<typeof FormCampaignInfo>, 'value'>;
export default class FormCampaignInfoChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <CampaignInfoFormChartlib />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return null;
}

function mapOptionItem(i: { id: number | string; text: string; payload?: any }) {
  return {
    label: i.text,
    value: i.id,
    payload: i.payload,
  };
}

function CampaignInfoFormChartlib() {
  const screen = useWorkflowScreen('campaign_info');

  const [options, setOptions] = React.useState<Options>({
    marketplace: [],
    adType: [],
    country: [],
    storefront: [],
    status: [],
  });

  React.useEffect(() => {
    if (screen) {
      screen.init().then(({ marketplace, adType, country, storefront }) => {
        setOptions({
          marketplace: marketplace.map(mapOptionItem),
          adType: adType.map(mapOptionItem),
          country: country.map(mapOptionItem),
          storefront: storefront.map(mapOptionItem),
          status: storefront.map(mapOptionItem),
        });
      });
    } else {
      console.info('no workflow screen found');
    }
  }, []);

  return (
    <FormCampaignInfo
      value={{ schedule: { dateFrom: null, dateTo: null }, totalBudget: 0, dailyBudget: 0, bidLimit: 0 }}
      {...options}
    />
  );
}
