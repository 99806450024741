import * as React from 'react';
import { Box, makeStyles, Button, Grid } from '@material-ui/core';
import { Calendar, DateObject } from 'react-multi-date-picker';
import Icon from '../../../../icons/Icon';
import moment from 'moment';
import { get, last } from 'lodash';
import { noLimitDate, isLimitDate } from '../helper';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';

const useStylesModal = makeStyles(() => ({
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
  },
  modalHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  modalButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  modalButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  dateRange: {
    width: '100%',
    height: '35px',
    marginTop: '15px',
    borderBottom: '1px solid #8C98A4',
    borderTop: '1px solid #8C98A4',
    borderLeft: '1px solid #8C98A4',
    borderRight: '1px solid #8C98A4',
    backgroundColor: 'white',
    display: 'flex',
    borderRadius: '5px',
  },
  dateFrom: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
    fontSize: '12px',
    borderRight: '1px solid #8C98A4',
    width: '47%',
  },
  dateTo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '48%',
    paddingLeft: '10px',
    fontSize: '12px',
  },
  calendar: {
    boxShadow: 'none',
    width: '100%',
    marginTop: '15px',
    '& .rmdp-calendar': {
      width: '100%',
      '& .rmdp-day-picker': {
        justifyContent: 'center',
        '& .rmdp-day.rmdp-today span': {
          backgroundColor: '#ED5C10',
        },
        '& .rmdp-day': {
          height: '44px !important',
          width: '44px !important',
          boxShadow: 'none',
        },
        '& .rmdp-day span': {
          height: 'auto',
          left: '0',
          right: '0',
          bottom: '0',
          top: '0',
        },
        '& .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
          color: 'white',
          backgroundColor: '#0074d9',
        },
        '& .rmdp-range.start': {
          borderTopLeftRadius: '5%',
          borderBottomLeftRadius: '5%',
          backgroundColor: '#0074d9',
          color: 'white',
        },
        '& .rmdp-range.end': {
          borderTopRightRadius: '5%',
          borderBottomRightRadius: '5%',
          backgroundColor: '#0074d9',
          color: 'white',
        },
        '& .rmdp-range': {
          backgroundColor: '#EBF6FF',
          color: '#0369C7',
        },
        '& .rmdp-week': {
          '& .rmdp-week-day': {
            width: '44px',
            height: '44px',
          },
        },
      },
      '& .rmdp-header-values': {
        fontWeight: 'bold',
      },
    },
  },
}));
const formatInput = 'DD/MM/YYYY';
const formatSystem = 'YYYY/MM/DD';
export const PopupCalendar = ({
  handleClose,
  handleSetValues,
  onChange,
  value,
  defaultFormatReturn = 'DD/MM/YYYY',
  formatDateView = 'MMMM DD, YYYY',
}: any) => {
  const classes = useStylesModal();
  const generateDateObject = (val: string, format = formatSystem): DateObject => {
    const dateString = moment(val, format).format();
    return new DateObject(dateString);
  };
  const [isButtonClose, setIsButtonClose] = React.useState(true);
  const fetchValueDate = () => {
    const tempValues = [];
    let start = new DateObject();
    let end = generateDateObject(noLimitDate, formatInput);
    const dateFrom = get(value, 'dateFrom', '');
    if (dateFrom) {
      start = generateDateObject(dateFrom, defaultFormatReturn);
    }
    const dateTo = get(value, 'dateTo', '');
    if (!isLimitDate(dateTo, moment(dateTo, defaultFormatReturn))) {
      end = generateDateObject(dateTo, defaultFormatReturn);
    }
    tempValues.unshift(start);
    tempValues.push(end);
    return [...tempValues];
  };

  const [rangeDate, setRangeDate] = React.useState(fetchValueDate());
  const handleChangeDate = (selectedDates: DateObject[]) => {
    if (selectedDates.length === 1) {
      const temp = [];
      temp.push(selectedDates);
      temp.push(generateDateObject(noLimitDate, defaultFormatReturn));
      setRangeDate(temp);
    } else {
      setRangeDate(selectedDates);
    }
    setIsButtonClose(false);
    setRangeDate(selectedDates);
    handleSetValues(selectedDates[0], last(selectedDates));
  };
  const handleSubmit = () => {
    if (rangeDate.length > 0) {
      const start = rangeDate[0].format('YYYY-MM-DD');
      const endValue = last(rangeDate);
      const end = last(rangeDate).format('YYYY-MM-DD');
      if (!value || start != value.dateFrom || end != value.dateTo) {
        onChange({ dateFrom: start, dateTo: endValue.format('YYYY-MM-DD') == noLimitDate ? '' : end });
      }
    }
    handleClose();
  };

  const handleClearEndDate = () => {
    const tempValue = [...rangeDate];
    tempValue.pop();
    tempValue.push(generateDateObject(noLimitDate, defaultFormatReturn));
    setRangeDate(tempValue);
    setIsButtonClose(true);
  };
  return (
    <Box className={classes.modalContent}>
      <Grid className={classes.modalHeader}>
        <Grid className={classes.modalButtonCancel}>
          <Button style={{ color: '#8C98A4' }} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <h4 className={classes.modalTitle}>Date</h4>
        <Grid className={classes.modalButton}>
          <Button color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.dateRange}>
        <Box className={classes.dateFrom}>
          <Icon type={'calendar'} size="16px" colorIcon="#333" style={{ marginTop: '-3px', paddingRight: '5px' }} />
          {rangeDate[0].format(formatDateView)}
        </Box>
        <Box className={classes.dateTo}>
          <span>{isButtonClose ? 'No limit' : last(rangeDate).format(formatDateView)}</span>
          <Icon
            type={'closeCircle'}
            size="16px"
            colorIcon="#333"
            style={{ marginTop: '-3px', paddingLeft: '5px', display: isButtonClose ? 'none' : 'block' }}
            onClick={handleClearEndDate}
          />
        </Box>
      </Grid>
      <Grid>
        <Calendar
          className={classes.calendar}
          multiple={false}
          value={rangeDate}
          range={true}
          disableMonthPicker
          disableYearPicker
          onChange={handleChangeDate}
          numberOfMonths={1}
          weekDays={CALENDAR_WEEKDAYS}
          weekStartDayIndex={1}
          showOtherDays
        />
      </Grid>
    </Box>
  );
};
