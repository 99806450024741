import { get, uniq } from 'lodash';

import { requestEtableConfig } from '@ep/insight-ui/system/backbone/data-source/common';

import {
  INPUT_TYPE_ETABLE_SELECTED,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_FILTER_FIELD,
  INPUT_TYPE_ETABLE_CONFIG,
  DEFAULT_CONFIG_ETABLE_SELECTION,
  INPUT_TYPE_VIEW,
  INPUT_TYPE_COMPACT_SOURCE_FIELD,
  INPUT_TYPE_COMPACT_TARGET_FIELD,
  INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER as INPUT_TYPE_COMPACT_TARGET_INHERIT_FILTER,
  INPUT_TYPE_SYSTEM_FILTERS,
  INPUT_TYPE_COMPACT_OPERATOR,
  INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
  INPUT_TYPE_CUSTOM_TAG,
  INPUT_TYPE_SEARCH_HINT,
  INPUT_TYPE_ADDITIONAL_DATA,
  INPUT_TYPE_LINK_GROUPBY_FILTER,
} from './constant';

import SelectFormat from '../cell-format/select-format';
import CompactFormat from '../cell-format/compact-format';
import { CellEditorInputFormat } from '../cell-format/input-format';
import MultiSelectFormat from '../cell-format/multi-select-format';
import ResyncConfigFormat from '../cell-format/resync-config-format';
import { isFormulaField } from '@ep/insight-ui/sw/util/excel-formula';

const operators = [
  { label: 'Is', value: 'is' },
  { label: 'Is not', value: 'is_not' },
  { label: 'Is empty', value: 'is_empty' },
  { label: 'Is not empty', value: 'is_not_empty' },
  { label: 'Contain', value: 'contains' },
  { label: 'Does not contain', value: 'does_not_contain' },
  { label: 'Starts with', value: 'begins_with' },
  { label: 'Ends with', value: 'ends_with' },
  { label: 'Does not start with', value: 'does_not_start_with' },
  { label: 'Does not end with', value: 'does_not_end_with' },
  { label: 'Is before', value: 'is_before' },
  { label: 'Is after', value: 'is_after' },
  { label: 'Is on or before', value: 'is_on_or_before' },
  { label: 'Is on or after', value: 'is_on_or_after' },
  { label: 'Is within', value: 'is_within' },
  { label: 'Any of', value: 'IN' },
  { label: 'Not in', value: 'NOT_IN' },
  { label: 'Match', value: 'match' },
  { label: '=', value: '=' },
  { label: '≠', value: '≠' },
  { label: '>', value: '>' },
  { label: '≥', value: '≥' },
  { label: '<', value: '<' },
  { label: '≤', value: '≤' },
];

const getApiMappingOptions = (etableConfigContext) => {
  return uniq(
    Object.values<any>(get(etableConfigContext, 'mapping', {})).reduce(
      (a, b) => {
        return [...a, ...(Object.values(b.valueGetter || {}).filter((el) => !!el && !isFormulaField(el)) || [])];
      },
      ['currency'],
    ),
  );
};

export const getTooltipConfig = ({ etableConfigContext, etableList }) => {
  return {
    isConfig: true,
    isCustom: true,
    config: {
      colDefs: [
        {
          field: 'key',
          headerName: 'Key',
          width: 100,
          cellRenderer: SelectFormat,
          cellRendererParams: {
            options: [
              {
                label: 'Header',
                value: 'header',
              },
              {
                label: 'Span',
                value: 'span',
              },
              {
                label: 'Table',
                value: 'table',
              },
              {
                label: 'Block',
                value: 'block',
              },
              {
                label: 'Status',
                value: 'status',
              },
            ],
            field: 'key',
          },
          onCellValueChanged(params) {
            params.api.redrawRows({
              rowNodes: [params.node],
            });
          },
          editable: true,
        },
        {
          field: 'value',
          headerName: 'Value',
          width: 100,
          cellRendererSelector(valueParams2) {
            if (valueParams2.data?.key == 'table') {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Config item',
                        width: 200,
                        cellRenderer: SelectFormat,
                        cellRendererParams: {
                          options: [
                            {
                              label: 'eTable select',
                              value: INPUT_TYPE_ETABLE_SELECTED,
                            },
                            {
                              label: 'eTable config',
                              value: INPUT_TYPE_ETABLE_CONFIG,
                            },
                            {
                              label: 'Display as',
                              value: INPUT_TYPE_DISPLAY_AS,
                            },
                            {
                              label: 'View',
                              value: INPUT_TYPE_VIEW,
                            },
                            {
                              label: 'System filters',
                              value: INPUT_TYPE_SYSTEM_FILTERS,
                            },
                            {
                              label: 'Ignore calendar',
                              value: INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                            },
                            {
                              label: 'Source fields',
                              value: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                            },
                            {
                              label: 'Target fields',
                              value: INPUT_TYPE_COMPACT_TARGET_FIELD,
                            },
                            {
                              label: 'Inherit filter from source',
                              value: INPUT_TYPE_COMPACT_TARGET_INHERIT_FILTER,
                              description:
                                'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                            },
                            {
                              label: 'Filter field',
                              value: INPUT_TYPE_FILTER_FIELD,
                              description:
                                'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                            },
                            {
                              label: 'Custom Tag',
                              value: INPUT_TYPE_CUSTOM_TAG,
                            },
                            {
                              label: 'Search hint',
                              value: INPUT_TYPE_SEARCH_HINT,
                            },
                            {
                              label: 'Additional data',
                              value: INPUT_TYPE_ADDITIONAL_DATA,
                            },
                            {
                              label: 'Link group by filter',
                              value: INPUT_TYPE_LINK_GROUPBY_FILTER,
                            },
                          ],
                          hasSearch: true,
                          field: 'key',
                          allowCustomOption: true,
                          allowedRemove: true,
                        },
                        editable: true,
                        onCellValueChanged(params) {
                          params.api.redrawRows({
                            rowNodes: [params.node],
                          });
                        },
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(valueParams) {
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: etableList,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                            return {
                              component: ResyncConfigFormat,
                            };
                          }
                          const etableConfig = (
                            valueParams.node.parent.allLeafChildren.find(
                              (el) => el.data?.key === INPUT_TYPE_ETABLE_CONFIG,
                            ) || {
                              data: { value: {} },
                            }
                          ).data?.value;

                          if (valueParams.data.key === INPUT_TYPE_VIEW) {
                            const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }));

                            return {
                              component: SelectFormat,
                              params: {
                                options: viewsOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_DISPLAY_AS) {
                            const chartConfig = get(etableConfig, ['chartConfig', 'config'], null);
                            const isGalleryVisualization = get(etableConfig, ['visualizationType'], null) == 'category';
                            const viewsOptions = [
                              {
                                label: 'Etable',
                                value: 'etable',
                              },
                              ...(!!chartConfig
                                ? [
                                    {
                                      label: 'Chart',
                                      value: 'chart',
                                    },
                                  ]
                                : []),
                              ...(isGalleryVisualization ? [{ label: 'Gallery', value: 'category' }] : []),
                            ];

                            return {
                              component: SelectFormat,
                              params: {
                                options: viewsOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map((i) => ({
                            value: i,
                            label: i,
                          }));
                          if (valueParams.data.key === INPUT_TYPE_COMPACT_SOURCE_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: sourceFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const targetFieldViewOptions = getApiMappingOptions(etableConfig).map((i) => ({
                            value: i,
                            label: i,
                          }));
                          if (valueParams.data.key === INPUT_TYPE_COMPACT_TARGET_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: targetFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_SYSTEM_FILTERS) {
                            return {
                              component: CompactFormat,
                              params: {
                                // isConfig: true,
                                isCustom: true,
                                config: {
                                  colDefs: [
                                    {
                                      field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                      headerName: 'Target field',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: targetFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                      headerName: 'Source field',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: sourceFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: INPUT_TYPE_COMPACT_OPERATOR,
                                      headerName: 'Operator',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: operators,
                                        hasSearch: true,
                                        allowCustomOption: false,
                                        field: INPUT_TYPE_COMPACT_OPERATOR,
                                      },
                                      editable: true,
                                    },
                                  ],
                                  getDefaultData() {
                                    return [];
                                  },
                                  getDefaultRowData() {
                                    return {
                                      [INPUT_TYPE_COMPACT_TARGET_FIELD]: '',
                                      [INPUT_TYPE_COMPACT_SOURCE_FIELD]: '',
                                      [INPUT_TYPE_COMPACT_OPERATOR]: 'is',
                                    };
                                  },
                                  addNewFromIndex: 9999999,
                                },
                                allowDragRow: true,
                                addRowLabel: 'Add input',
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_ADDITIONAL_DATA) {
                            return {
                              component: CompactFormat,
                              params: {
                                // isConfig: true,
                                isCustom: true,
                                config: {
                                  colDefs: [
                                    {
                                      field: 'key',
                                      headerName: 'Key',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: targetFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: 'key',
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: 'value',
                                      headerName: 'Value',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: sourceFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: 'value',
                                      },
                                      editable: true,
                                    },
                                  ],
                                  getDefaultData() {
                                    return [];
                                  },
                                  getDefaultRowData() {
                                    return {
                                      key: '',
                                      value: '',
                                    };
                                  },
                                  addNewFromIndex: 9999999,
                                },
                                allowDragRow: true,
                                addRowLabel: 'Add input',
                              },
                            };
                          }

                          const yesNoOptionsKeys = [INPUT_TYPE_LINK_GROUPBY_FILTER, INPUT_TYPE_COMPACT_IGNORE_CALENDAR];
                          if (yesNoOptionsKeys.includes(valueParams.data.key)) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: [
                                  {
                                    label: 'Yes',
                                    value: true,
                                  },
                                  {
                                    label: 'No',
                                    value: false,
                                  },
                                ],
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce((a, b) => {
                            const valueGetter = Object.values(get(b, 'valueGetter', {}));
                            return [...a, ...valueGetter];
                          }, []);
                          mapping.sort();
                          const uniqMapping = uniq(mapping);
                          const filteredMapping = uniqMapping.filter((el) => !!el);
                          return {
                            component: SelectFormat,
                            params: {
                              options: filteredMapping,
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                            },
                          };
                        },
                        onCellValueChanged(params) {
                          if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            const blockEid = params.data.value;
                            requestEtableConfig(blockEid).then((res) => {
                              const eTableConfigNode = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                              );
                              if (eTableConfigNode) {
                                if (res.success) {
                                  eTableConfigNode.setDataValue('value', res.data);
                                } else if (!eTableConfigNode.data?.blockEid) {
                                  eTableConfigNode.setDataValue('value', {
                                    ...DEFAULT_CONFIG_ETABLE_SELECTION,
                                    blockEid,
                                  });
                                }
                              }
                              const eTableConfigView = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_VIEW,
                              );
                              if (eTableConfigView) {
                                if (res.success) {
                                  eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                                }
                              }
                            });
                          }
                          const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                            return (
                              params.data.key === INPUT_TYPE_ETABLE_SELECTED || data?.key != INPUT_TYPE_ETABLE_CONFIG
                            );
                          });
                          params.api.redrawRows({
                            rowNodes: redrawRows,
                          });
                        },
                        editable: true,
                      },
                    ],
                    getDefaultData() {
                      return [
                        {
                          key: INPUT_TYPE_ETABLE_SELECTED,
                          value: '',
                        },
                        {
                          key: INPUT_TYPE_ETABLE_CONFIG,
                          value: '',
                        },
                        {
                          key: INPUT_TYPE_DISPLAY_AS,
                          value: 'etable',
                        },
                        {
                          key: INPUT_TYPE_SYSTEM_FILTERS,
                          value: [],
                        },
                        {
                          key: INPUT_TYPE_VIEW,
                          value: '',
                        },
                      ];
                    },
                    getDefaultRowData() {
                      return {
                        label: '',
                        value: '',
                        shortcut: '',
                      };
                    },
                  },
                  addRowLabel: 'Add config',
                },
              };
            }
            return {
              component: SelectFormat,
              params: {
                options: [],
                hasSearch: true,
                field: 'value',
                allowCustomOption: true,
                allowedRemove: true,
              },
            };
          },
          cellEditor: CellEditorInputFormat,
          editable: true,
        },
      ],
      getDefaultData() {
        return [
          {
            key: 'status',
            value: 'Enabled',
          },
          {
            key: 'span',
            value: '',
          },
        ];
      },
      getDefaultRowData() {
        return {
          key: 'span',
          value: '',
        };
      },
      addNewFromIndex: 9999999,
    },
    allowDragRow: true,
  };
};
