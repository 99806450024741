import { aim, eipRequest as _request, EIP_CONSTANT } from '@eip/next/lib/main';
import { get, lowerCase, startCase, uniqBy, uniq } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import hash from 'object-hash';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import LRU from 'lru-cache';

/**
 * ff.replace_keyword_compact:start
 */
import { TreemapDataResponseInterface } from './type';
/**
 * ff.replace_keyword_compact:end
 */

// swagger, api docs: https://staging-grpc-gateway-swagger.epsilo.io/swagger/root-management

const API = {
  GET_CHANNEL: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-channel',
  GET_COUNTRY: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-country',
  GET_STOREFRONT: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-user-storefront',
  GET_TOOL: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-tool',
  GET_DIMENSION_DEFINITION: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/get-dimension-definition',
  UPDATE_USER_MENU: () => EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/user/save_changeset',
  SAVE_DRAFT: () => EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/user/save_changeset',
  ...(ff.storefront_sos_option_list
    ? {
        GET_STOREFRONT_LIST_SOS: () =>
          EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/definition/get-storefront-list-sos',
      }
    : {}),
  LOAD_INTERVALS: () => EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/programmatic/get-intervals',
};

export const request = {
  memo: {},
  get: (url, body?: any, forceReload = false, timeout?) => {
    const cacheKey = hash(
      { url, body },
      {
        unorderedArrays: true,
      },
    );
    if (request.memo[cacheKey] && !forceReload) {
      return Promise.resolve(request.memo[cacheKey]);
    } else {
      request.memo[cacheKey] = _request.get(url, body, timeout).then((res) => {
        request.memo[cacheKey] = res;
        return res;
      });

      return request.memo[cacheKey];
    }
  },
  post: _request.post,
};

const getMarketplace = async (
  params: { countryIds?; storefrontIds?; adTypeIds?; scriptTargetIds?; forceReload } = {
    forceReload: false,
  },
) => {
  return new Promise(async (resolve, reject) => {
    try {
      // debugger;
      const res = await request.get(
        API.GET_CHANNEL(),
        {
          countryCode: get(params, 'countryIds.0', undefined),
        },
        params.forceReload,
      );
      let data = [];
      data = uniqBy(
        res.data.map((i) => {
          return {
            value: i.code,
            label: i.name,
          };
        }),
        (i: any) => i.value,
      );
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getCountry = async (
  params: {
    markplaceIds?;
    storefrontIds?;
    adTypeIds?;
    scriptTargetIds?;
    countryIds?;
    forceReload;
  } = { forceReload: false },
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(
        API.GET_COUNTRY(),
        {
          countryCode: get(params, 'countryIds.0', undefined),
        },
        params.forceReload,
      );
      let data = [];
      data = res.data.map((i) => {
        return {
          value: i.code,
          label: i.name,
          payload: {
            currency: i.currency,
            timezone: i.timezone,
          },
        };
      });
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getStorefront = async (
  params: { marketplaceIds?; countryIds?; adTypeIds?; scriptTargetIds?; forceReload } = {
    forceReload: false,
  },
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const paramStr = qs.stringify(
        {
          channelCode: get(params, 'marketplaceIds.0', 'NONE'),
          siteCode: 'PUBLIC',
          pagination: {
            limit: -1,
          },
        },
        { allowDots: true },
      );
      const res = await request.get(`${API.GET_STOREFRONT()}?${paramStr}`, undefined, params.forceReload);
      let data = [];
      data = uniqBy(
        res.data[0].listStorefront.map((w) => {
          const i = w.storefront;
          const country = get(i, 'channel.sites.0.countries.0', {});
          return {
            value: i.storefrontId,
            label: [i.channel.name, country.name, i.name].join(' / '),
            payload: {
              sid: i.sid,
              wallet: i.wallet,
              channel: {
                id: i.channel.code,
                name: i.channel.name,
              },
              country: country,
            },
            id: i.storefrontId,
          };
        }),
        'value',
      );
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getStatus = async (params: {
  dimension: string;
  marketplaceCode: string;
  toolCode?: string;
  countryCode?: string;
}) => {
  return new Promise(async (resolve, reject) => {
    let status = [];
    if (params.dimension === 'SCRIPT') {
      status = [
        { value: 'DRAFT', label: 'Draft' },
        { value: 'SCHEDULED', label: 'Scheduled' },
        { value: 'ONGOING', label: 'Ongoing' },
        { value: 'PAUSED', label: 'Paused' },
        { value: 'ENDED', label: 'Ended' },
      ];
      resolve({
        data: status,
        success: true,
        message: null,
      });
    } else {
      try {
        const res = await request.get(API.GET_DIMENSION_DEFINITION(), params, false);
        if (res.success) {
          const values = get(res.data, ['data', 'value', 'status']);
          if (values) {
            status = (values.split(':')[1] || '').split(',').map((i) => {
              // FIXME: this is troublesome, user text derived from the system status.
              let label;
              switch (i) {
                case 'PAUSED': {
                  label = 'Paused by Botep';
                  break;
                }
                case 'SUSPENDED': {
                  label = 'Paused';
                  break;
                }
                case 'RECOVERING': {
                  label = 'Resuming';
                  break;
                }
                default:
                  label = startCase(lowerCase(i));
              }
              return {
                value: i,
                label: label,
              };
            });
          }
        }
        resolve({
          data: status,
          success: res.success,
          message: res.message,
        });
      } catch (error) {
        resolve({
          data: [],
          success: false,
          message: error.message,
        });
      }
    }
  });
};

const getStorefrontSOS = async (param) => {
  return new Promise(async (resolve, reject) => {
    try {
      const paramStr = qs.stringify(
        {
          search: param,
          pagination: {
            limit: 10,
            page: 1,
          },
        },
        { allowDots: true },
      );
      const res = await request.get(`${API.GET_STOREFRONT_LIST_SOS()}?${paramStr}`);
      let data = [];
      data = res.data.map((el) => {
        return {
          value: `${el.id}`,
          label: [el.marketplaceName, el.countryName, el.category1].join(' / '),
          payload: {
            sid: el.storefrontSid,
            wallet: 0,
            channel: {
              id: el.marketplaceCode,
              name: el.marketplaceCode,
            },
            country: {},
          },
        };
      });

      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getInterval = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(API.LOAD_INTERVALS());
      resolve({
        data: get(res, ['intervals'], []).map((interval) => ({
          label: interval.label,
          value: interval.id || interval.value,
        })),
        success: true,
        message: '',
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error?.message || '',
      });
    }
  });
};

const getAdType = async (
  params: { marketplaceIds?; countryIds?; adTypeIds?; scriptTargetIds?; toolIds?; forceReload } = {
    forceReload: false,
  },
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(
        API.GET_TOOL(),
        {
          toolCode: get(params, 'toolIds.0', 'TOOL_NONE'),
          channelCode: get(params, 'marketplaceIds.0', 'NONE'),
          type: get(params, 'adTypeIds.0', 'TYPE_NONE'),
        },
        params.forceReload,
      );
      let data = [];
      data = uniqBy(
        res.data.map((i) => ({ value: String(i.type).toUpperCase(), label: i.typeName })),
        (i: any) => i.value,
      );
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const scriptGetAdTool = async (params?: { marketplaceIds; countryIds; storefrontIds; adTypeIds }) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(API.GET_TOOL(), {
        toolCode: get(params, 'toolIds.0', 'TOOL_NONE').toUpperCase(),
        channelCode: get(params, 'marketplaceIds.0', 'NONE').toUpperCase(),
        type: get(params, 'adTypeIds.0', 'TYPE_NONE').toUpperCase(),
      });
      let data = [];
      data = uniqBy(
        res.data.map((i) => {
          return {
            value: String(i.toolCode).toUpperCase(),
            label: i.toolName,
            payload: {
              adTypeId: i.type,
              adTypeName: i.typeName,
              ...i,
            },
          };
        }),
        (i: any) => i.value,
      );
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getAdTool = async (
  params: { marketplaceIds?; countryIds?; storefrontIds?; adTypeIds?; forceReload } = {
    forceReload: false,
  },
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(
        API.GET_TOOL(),
        {
          toolCode: get(params, 'toolIds.0', 'TOOL_NONE'),
          channelCode: get(params, 'marketplaceIds.0', 'NONE'),
          type: get(params, 'adTypeIds.0', 'TYPE_NONE'),
        },
        params.forceReload,
      );
      let data = [];
      data = res.data.map((i) => {
        return {
          value: i.toolCode,
          label: i.toolName,
          payload: {
            marketplaceId: i.channelCode,
            adTypeId: i.type,
            adTypeName: i.typeName,
          },
        };
      });
      resolve({
        data,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
      });
    } catch (error) {
      resolve({
        data: [],
        success: false,
        message: error.message,
      });
    }
  });
};

const getScriptTarget = async (params?: { marketplaceIds; countryIds; storefrontIds; adTypeIds }) => {
  return [
    {
      value: 'ad_object_keyword',
      label: 'Ad object - Keyword',
    },
  ];
};

export enum EpsiloTableObject {
  PERFORMANCE = 'PERFORMANCE',
  OPERATION = 'OPERATION',
  QUALITY = 'QUALITY',
}

type epsiloTableObjectType = {
  prefixFE: string;
  queryEpMatching: string;
  queryAlias?: string;
  queryAttributes?: string;
  queryMetrics?: string;
  queryListing?: string;
  queryStorefrontListing?: string;
  export?: string;
};

export const epsiloTableEndpoint: { [key in EpsiloTableObject]: epsiloTableObjectType } = {
  [EpsiloTableObject.PERFORMANCE]: {
    prefixFE: 'performance',
    queryEpMatching: 'v2_performance.jsp',
    queryAlias: 'insight.jsp',
    queryAttributes: 'v2_performance_attributes.jsp',
    queryMetrics: 'v2_performance_metrics.jsp',
    queryListing: 'v2_performance_listing.jsp',
    queryStorefrontListing: 'v2_performance_storefront.jsp',
    export: 'v2_performance_download_get.jsp',
  },
  [EpsiloTableObject.OPERATION]: {
    prefixFE: 'operation',
    queryEpMatching: 'v2_operational.jsp',
    queryAttributes: 'v2_operational_attributes.jsp',
    queryMetrics: 'v2_operational_metrics.jsp',
    queryListing: 'v2_operational_listing.jsp',
    export: 'v2_operational_download_get.jsp',
  },
  [EpsiloTableObject.QUALITY]: {
    prefixFE: 'quality',
    queryEpMatching: 'v2_quality.jsp',
    queryAlias: 'v2_quality.jsp',
    queryAttributes: 'v2_quality_attributes.jsp',
    queryMetrics: 'v2_quality_metrics.jsp',
    queryStorefrontListing: 'v2_quality_storefront.jsp',
    export: 'v2_quality_download_get.jsp',
  },
};

export const checkEpsiloTableEndpoint = (
  endpoint: string,
  tableObject?: EpsiloTableObject | EpsiloTableObject[],
): boolean => {
  const queryParams = getQueryParams(endpoint);

  if (!tableObject && queryParams.namespace) {
    return true;
  }

  let compareTables = [].concat(tableObject);
  if (!tableObject) {
    compareTables = Object.keys(epsiloTableEndpoint);
  }
  return [].concat(compareTables).some((el) => {
    return (
      String(endpoint).includes(epsiloTableEndpoint[el].queryEpMatching) ||
      String(endpoint).includes(epsiloTableEndpoint[el].queryAlias)
    );
  });
};

export const getQueryParams = (endpoint: string) => {
  const queryParams = new Proxy(new URLSearchParams(String(endpoint).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop) => searchParams.get(prop),
  });

  return queryParams;
};

const getPerformanceAttributes = (): Promise<Array<{ label: string; value: string }>> => {
  return new Promise(async (resolve) => {
    request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.PERFORMANCE.queryAttributes)
      .then((res) => {
        resolve(
          res.map((el) => ({
            label: el.name + ` (insight.${el.code})`,
            label_raw: el.name,
            value: el.code,
          })),
        );
      })
      .catch((e) => resolve([]));
  });
};

export interface ResourceMetricInterface {
  label: string;
  value: string;
  prefix_value: string;
  variable_type: string;
}

let cachedInsightMetrics = null;
const getPerformanceMetrics = (): Promise<Array<ResourceMetricInterface>> => {
  if (cachedInsightMetrics) return Promise.resolve(cachedInsightMetrics);
  cachedInsightMetrics = new Promise(async (resolve) => {
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.PERFORMANCE.queryMetrics)
      .then((res) => {
        cachedInsightMetrics = res.map((el) => ({
          label: el.name + ` (insight.${el.code})`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
        resolve(cachedInsightMetrics);
      })
      .catch((e) => {
        cachedInsightMetrics = null;
        console.info(e);
        resolve([]);
      });
  });
  return cachedInsightMetrics;
};

let cachedOperationMetrics = null;
const getOperationMetrics = (): Promise<Array<ResourceMetricInterface>> => {
  if (cachedOperationMetrics) return Promise.resolve(cachedOperationMetrics);
  return new Promise(async (resolve) => {
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryMetrics)
      .then((res) => {
        cachedOperationMetrics = res.map((el) => ({
          label: el.name + ` - [${epsiloTableEndpoint.OPERATION.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
        resolve(cachedOperationMetrics);
      })
      .catch((e) => {
        console.info(e);
        resolve([]);
      });
  });
};

const options = {
  max: 500,

  // how long to live in ms
  ttl: 1000 * 60 * 15,
};

const cachedAsyncResult = new LRU(options);
export function cacheAsyncRequest(name, asyncRequest, forceReload = false) {
  // return (): Promise<any> => {
  const cached = cachedAsyncResult.get(name);
  if (cached && !forceReload) return Promise.resolve(cached);

  cachedAsyncResult.set(
    name,
    new Promise(async (resolve, reject) => {
      let result;
      try {
        result = await asyncRequest();
      } catch (e) {
        cachedAsyncResult.delete(name);
      }
      resolve(result);
    }),
  );

  return cachedAsyncResult.get(name);
  // };
}

const getEpsiloMetric = (tableObj: EpsiloTableObject) => {
  return cacheAsyncRequest('metric_' + tableObj, () => {
    const metricSet = epsiloTableEndpoint[tableObj];
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + metricSet.queryMetrics)
      .then((res) => {
        return res.map((el) => ({
          label: el.name + ` - [${metricSet.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
      })
      .catch((e) => {
        console.info(e);
        return [];
      });
  });
};

const getEpsiloAttributes = (tableObj: EpsiloTableObject) => {
  return cacheAsyncRequest('attribute_' + tableObj, () => {
    const metricSet = epsiloTableEndpoint[tableObj];
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + metricSet.queryAttributes)
      .then((res) => {
        return res.map((el) => ({
          label: el.name + ` - [${metricSet.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
        }));
      })
      .catch((e) => {
        console.info(e);
        return [];
      });
  });
};

let cachedPerformanceAttributes = null;
const getOperationAttributes = (): Promise<Array<ResourceMetricInterface>> => {
  if (cachedPerformanceAttributes) return Promise.resolve(cachedPerformanceAttributes);
  return new Promise(async (resolve) => {
    request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryAttributes)
      .then((res) => {
        cachedPerformanceAttributes = res.map((el) => ({
          label: el.name + ` - [${epsiloTableEndpoint.OPERATION.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
        }));
        resolve(cachedPerformanceAttributes);
      })
      .catch((e) => {
        console.info(e);
        resolve([]);
      });
  });
};

const getEnpointNamespaces = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/namespaces.jsp');
      let data = [];
      data = res.map((el) => ({
        ...el,
        value: el.namespace,
        label: el.namespace,
      }));
      resolve(data);
    } catch (error) {
      resolve([]);
    }
  });
};

const cachedOneDimensionOptions = {};
const getOneDimensionOptions = async (namespace) => {
  if (cachedOneDimensionOptions[namespace]) return Promise.resolve(cachedOneDimensionOptions[namespace]);
  return new Promise((resolve) => {
    request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/attributes.jsp?namespace=' + namespace)
      .then((res) => {
        cachedOneDimensionOptions[namespace] = Object.values(res).reduce((carry, el) => {
          const conventionCode = el.code.replace(/\.([^\.]+)$/, '.a_$1');
          return carry.concat(
            {
              label: el.name + ` (${el.code})`,
              label_raw: el.name,
              value: el.code,
              type: 'attribute',
            },
            {
              label: el.name + ` (${conventionCode})`,
              label_raw: el.name,
              value: conventionCode,
              type: 'attribute',
            },
          );
        }, []);
        resolve(cachedOneDimensionOptions[namespace]);
      })
      .catch((e) => {
        resolve([]);
      });
  });
};

const cachedOneMetricOptions = {};
const getOneMetricOptions = async (namespace) => {
  if (cachedOneMetricOptions[namespace]) return Promise.resolve(cachedOneMetricOptions[namespace]);
  return new Promise((resolve) => {
    request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/metrics.jsp?namespace=' + namespace)
      .then((res) => {
        cachedOneMetricOptions[namespace] = Object.values(res).reduce((carry: any[], el) => {
          const conventionCode = el.code.replace(/\.([^\.]+)$/, '.m_$1');
          return carry.concat(
            {
              label: el.name + ` (${el.code})`,
              label_raw: el.name,
              value: el.code,
              type: 'metric',
            },
            {
              label: el.name + ` (${conventionCode})`,
              label_raw: el.name,
              value: conventionCode,
              type: 'metric',
            },
          );
        }, []);
        resolve(cachedOneMetricOptions[namespace]);
      })
      .catch((e) => {
        resolve([]);
      });
  });
};

export default {
  getStatus,
  getAdType,
  getCountry,
  getMarketplace,
  getScriptTarget,
  getStorefront,
  getInterval,
  getAdTool,
  scriptGetAdTool,
  ...(ff.storefront_sos_option_list ? { getStorefrontSOS } : {}),
  getPerformanceAttributes: getPerformanceAttributes,
  getPerformanceMetrics: getPerformanceMetrics,
  getOperationMetrics: getOperationMetrics,
  getOperationAttributes: getOperationAttributes,
  getEpsiloMetric: getEpsiloMetric,
  getEpsiloAttributes: getEpsiloAttributes,
  getEnpointNamespaces,
  getOneDimensionOptions,
  getOneMetricOptions,
};

export async function updateUserMenu(payload: {
  parentId: string;
  blockId: string;
  listAfterBlockId?: string;
  listBeforeBlockId?: string;
}) {
  const userId = aim.getUserSettings().profile.userId;

  const changset = {
    request_id: uuid(),
    changeset: [
      {
        pointer: { id: payload.blockId, table: 'eip_block' },
        op: 'updateMenu',
        path: [],
        value: {
          listAfter: payload.listAfterBlockId,
          listBefore: payload.listBeforeBlockId,
          parent_id: payload.parentId,
          parent_table: 'eip_system_block',
          owner_id: userId,
          updated_by: userId,
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    ],
  };

  return request.post(API.UPDATE_USER_MENU(), changset);
}

export async function getDraft({ parentId, entityId, templatePageId, workflowId }) {
  let draftId = entityId;
  if (draftId) {
    // load draft 1) all correct 2) incorrect blockId
    return { entityId, blockId: templatePageId };
  } else {
    draftId = 'draft' + nanoid();
    const blockId = uuid();
    // create new draft
    return createUserDraft({ parentId, entityId: draftId, blockId, templatePageId, workflowId }).then(async () => {
      await updateUserMenu({
        parentId: parentId,
        blockId: blockId,
      });
      return {
        entityId: draftId,
        blockId: blockId,
      };
    });
  }
}

export async function createUserDraft({
  parentId,
  entityId,
  blockId,
  templatePageId,
  parentTable,
  workflowId,
}: {
  parentId: string;
  parentTable?: string;
  entityId: string;
  blockId: string;
  templatePageId: string;
  workflowId: string;
}) {
  const userId = aim.getUserSettings().profile.userId;
  const pointer = { id: blockId, table: 'user_block' };

  return request.post(API.SAVE_DRAFT(), {
    request_id: nanoid(),
    changeset: [
      {
        pointer: pointer,
        op: 'add',
        path: [],
        value: {
          content: [templatePageId],
          type: 'user_system_link',
          id: blockId,
          version: 1,
          parent_id: parentId,
          parent_table: parentTable || 'eip_system_block',
          owner_id: userId,
          updated_by: userId,
          created_at: moment().format(EIP_CONSTANT.DATETIME_FORMAT),
          updated_at: moment().format(EIP_CONSTANT.DATETIME_FORMAT),
          table: 'eip_block',
          payload_schema_version: 1,
          workspace_id: 'epsilo',
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['properties'],
        value: {
          title: '',
          workflowId: workflowId,
          systemTemplatePageId: templatePageId,
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['entity_id'],
        value: {
          entity_id: entityId,
        },
      },
    ],
  });
}

/**
 * ff.integrate_api_sos:start
 */
const API_KEYWORD_LIST_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/definition/get-keyword-list';
const API_TREEMAP_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchData';
const API_SOS_MAIN = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchTopShareMyShareData';
const API_SOS_EXTRA =
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchTopShareMyShareExtraData';
const API_SOS_TRACTION =
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchTopShareMyShareDataTraction';

export const getSoSTractionData = (keyword, productSIds, timing = 'daily') => {
  try {
    return request
      .post(EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchDataTraction', {
        from: '2022-03-01',
        to: '2022-06-10',
        timing: timing,
        attributes: [
          'PRODUCT.id',
          'PRODUCT.product_sid',
          'PRODUCT.timing',
          'PRODUCT.created_datetime',
          'PRODUCT.keyword',
        ],
        dimensions: ['PRODUCT'],
        metrics: ['PRODUCT.item_sold'],
        filter: {},
        hiddenFilter: {
          combinator: 'AND',
          filters: [
            {
              field: 'PRODUCT.timing',
              operator: '=',
              dataType: 'string',
              value: timing,
            },
            {
              field: 'PRODUCT.keyword',
              operator: '=',
              dataType: 'string',
              value: keyword,
            },
            {
              field: 'PRODUCT.product_sid',
              operator: 'in',
              dataType: 'integer',
              value: productSIds,
            },
          ],
        },
        sort: [
          {
            field: 'PRODUCT.product_sid',
            sort: 'ASC',
          },
          {
            field: 'PRODUCT.created_datetime',
            sort: 'ASC',
          },
        ],
      })
      .then((res) => {
        const itemSoldIndex = res.data.headers.findIndex((header) => header === 'PRODUCT.item_sold');
        const data = res.data.rows.map((row) => row[itemSoldIndex]);
        return {
          data,
          message: '',
          success: true,
        };
      })
      .catch((e) => {
        return {
          data: [],
          message: e.message,
          success: false,
        };
      });
  } catch (e) {
    return Promise.resolve({
      data: [],
      message: 'Something went wrong.',
      success: false,
    });
  }
};

export const getKeywordList = (keyword) => {
  try {
    return request.get(API_KEYWORD_LIST_URL + `?search=${keyword.toLowerCase()}&pagination.limit=10`);
  } catch (e) {
    return Promise.resolve({
      data: [],
    });
  }
};

export const getKeywordListData = (keyword, dateRange) => {
  return new Promise(async (resolve) => {
    try {
      const { data: dataMain, serviceResponse } = await request.post(API_SOS_MAIN, {
        from: dateRange.dateFrom,
        to: dateRange.dateTo,
        attributes: [
          'STOREFRONT.id',
          'STOREFRONT.keyword',
          'KEYWORD_TYPE.keyword_type',
          'KEYWORD_TYPE.search_volume',
          'STOREFRONT.updated_at',
        ],
        dimensions: ['STOREFRONT', 'KEYWORD_TYPE'],
        metrics: [],
        pagination: {
          limit: 10,
        },
        filter: {
          combinator: 'AND',
          filters: [
            {
              field: 'STOREFRONT.keyword',
              operator: 'contains',
              dataType: 'string',
              value: keyword,
            },
          ],
        },
        hiddenFilter: {},
      });

      if (serviceResponse.success) {
        const keywordIndex = dataMain.headers.findIndex((el) => el === 'STOREFRONT.keyword');
        const keywords = dataMain.rows.map((el) => el[keywordIndex]);

        if (keywords.length > 0) {
          try {
            const extraPayload = {
              from: dateRange.dateFrom,
              to: dateRange.dateTo,
              attributes: [
                'STOREFRONT.id',
                'STOREFRONT.keyword',
                'STOREFRONT.storefront_sid',
                'STOREFRONT.storefront_name',
                'STOREFRONT.storefront_type',
              ],
              dimensions: ['STOREFRONT', 'KEYWORD_TYPE'],
              metrics: ['sos'],
              filter: {},
              hiddenFilter: {
                combinator: 'AND',
                filters: [
                  {
                    field: 'STOREFRONT.keyword',
                    operator: 'in',
                    dataType: 'string',
                    value: keywords,
                  },
                ],
              },
            };
            const tractionPayload = {
              from: dateRange.dateFrom,
              to: dateRange.dateTo,
              timing: 'daily',
              attributes: [
                'STOREFRONT.id',
                'STOREFRONT.storefront_sid',
                'STOREFRONT.keyword',
                'STOREFRONT.created_datetime',
              ],
              dimensions: ['STOREFRONT'],
              metrics: ['sos'],
              filter: {},
              hiddenFilter: {
                combinator: 'AND',
                filters: [
                  {
                    field: 'STOREFRONT.keyword',
                    operator: 'in',
                    dataType: 'string',
                    value: keywords,
                  },
                ],
              },
              sort: [
                {
                  field: 'STOREFRONT.keyword',
                  sort: 'ASC',
                },
                {
                  field: 'STOREFRONT.created_datetime',
                  sort: 'ASC',
                },
              ],
            };
            const [{ data: dataExtra }, { data: dataTraction }] = await Promise.all([
              request.post(API_SOS_EXTRA, extraPayload),
              request.post(API_SOS_TRACTION, tractionPayload),
            ]);

            const headers = uniq([...dataMain.headers, ...dataExtra.headers]);
            const rows = dataMain.rows.map((el) => {
              return headers.reduce((a, b) => {
                let elData;
                const indexMain = dataMain.headers.findIndex((ele) => ele === b);
                if (indexMain != -1) {
                  elData = el[indexMain];
                } else {
                  const indexExtra = dataExtra.headers.findIndex((ele) => ele === b);
                  const indexKeywordExtra = dataExtra.headers.findIndex((ele) => ele === 'STOREFRONT.keyword');
                  const indexKeywordMain = dataMain.headers.findIndex((ele) => ele === 'STOREFRONT.keyword');
                  const extraData = dataExtra.rows.find((ele) => ele[indexKeywordExtra] === el[indexKeywordMain]);
                  elData = extraData ? extraData[indexExtra] : null;
                }
                return {
                  ...a,
                  [b]: elData,
                };
              }, {});
            });
            console.log(12345, rows);
            resolve({
              data: {
                headers,
                primaryKeys: [],
                rows,
              },
              success: true,
              erorr: 'OK',
            });
          } catch (e) {
            console.log(123456, e);
            resolve({
              data: {},
              success: false,
              erorr: e.message,
            });
          }
        } else {
          resolve({
            data: {
              rows: [],
            },
            success: true,
            erorr: '',
          });
        }
      } else {
        resolve({
          data: {},
          success: false,
          erorr: serviceResponse.message,
        });
      }
    } catch (e) {
      resolve({
        data: {},
        success: false,
        erorr: e.message,
      });
    }
  });
};

export const getTreemapData = (keyword, dateRange): Promise<TreemapDataResponseInterface> => {
  try {
    if (ff.replace_keyword_compact) {
      return request
        .post(API_TREEMAP_URL, {
          from: dateRange.dateFrom,
          to: dateRange.dateTo,
          attributes: ['STOREFRONT.id', 'STOREFRONT.name', 'STOREFRONT.storefront_sid', 'STOREFRONT.keyword'],
          dimensions: ['STOREFRONT'],
          metrics: ['sos'],
          pagination: {
            limit: ff.heatmap_change_limit ? -1 : 10,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              {
                field: 'STOREFRONT.keyword',
                operator: '=',
                dataType: 'string',
                value: keyword,
              },
              {
                field: 'STOREFRONT.name',
                operator: 'is_not_null',
                dataType: 'string',
                value: '',
              },
            ],
          },
          sort: [{ field: 'sos', sort: 'DESC' }],
        })
        .then((res) => {
          const indexStorefrontName = res.data.headers.findIndex((header) => header === 'STOREFRONT.name');
          const indexSoS = res.data.headers.findIndex((header) => header === 'sos');
          const data = res.data.rows.reduce((a, b) => {
            if (!a[b[indexStorefrontName]]) {
              a[b[indexStorefrontName]] = {
                name: b[indexStorefrontName],
                children: [
                  {
                    name: `${b[indexStorefrontName]}\n${b[indexSoS].toFixed(2)}`,
                    value: b[indexSoS],
                    showLabel: true,
                  },
                ],
              };
            } else {
              a[b[indexStorefrontName]].children.push({
                name: `${b[indexStorefrontName]}\n${b[indexSoS].toFixed(2)}`,
                value: b[indexSoS],
                showLabel: true,
              });
            }
            return a;
          }, {});
          return {
            data: Object.values(data),
            success: true,
            message: '',
          };
        })
        .catch((e) => {
          return Promise.resolve({
            data: [],
            success: false,
            message: e.message,
          });
        });
    }
    return request.post(API_TREEMAP_URL, {
      from: dateRange.dateFrom,
      to: dateRange.dateTo,
      attributes: ['STOREFRONT.id', 'STOREFRONT.name', 'STOREFRONT.storefront_sid', 'STOREFRONT.keyword'],
      dimensions: ['STOREFRONT'],
      metrics: ['sos'],
      pagination: {
        limit: 10,
      },
      hiddenFilter: {
        combinator: 'AND',
        filters: [
          {
            field: 'STOREFRONT.keyword',
            operator: '=',
            dataType: 'string',
            value: keyword,
          },
          {
            field: 'STOREFRONT.name',
            operator: 'is_not_null',
            dataType: 'string',
            value: '',
          },
        ],
      },
    });
  } catch (e) {
    if (ff.replace_keyword_compact) {
      return Promise.resolve({
        data: [],
        success: false,
        message: 'Something went wrong, please try again.',
      });
    }
    return Promise.resolve({
      data: {
        rows: [],
      },
    });
  }
};
/**
 * ff.integrate_api_sos:end
 */

/**
 * ff.mass_operation_management:start
 */
const API_IMPORT_MASS_UPLOAD = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-upload-import-file';
const API_EXPORT_MASS_UPLOAD = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-upload-export-file';
const API_DOWNLOAD_MASS_UPLOAD_TEMPLATE =
  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-upload-download-template';

export const importMassUpload = (file) => {
  return new Promise((resolve) => {
    try {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const payload = {
          toolCode: 'SHP_PA',
          fileData: get(reader.result.split(','), 1, ''),
          fileName: get(file.name.split('.'), 0, ''),
        };
        try {
          resolve(request.post(API_IMPORT_MASS_UPLOAD, payload));
        } catch (e) {
          resolve({
            data: [],
            success: false,
            message: 'Something went wrong, please try again.',
          });
        }
      };
      reader.onerror = (e) => console.log(123456, e);
    } catch (e) {
      resolve({
        data: [],
        success: false,
        message: 'Something went wrong, please try again.',
      });
    }
  });
};

export const exportMassUpload = (id) => {
  try {
    return request.get(API_EXPORT_MASS_UPLOAD + `?requestInfoId=${id}`);
  } catch (e) {
    return Promise.resolve({
      data: {},
      success: false,
      message: 'Something went wrong, please try again.',
    });
  }
};

export const downloadMassUploadTemplate = () => {
  try {
    return request.get(API_DOWNLOAD_MASS_UPLOAD_TEMPLATE);
  } catch (e) {
    return Promise.resolve({
      data: {},
      success: false,
      message: 'Something went wrong, please try again.',
    });
  }
};
/**
 * ff.mass_operation_management:end
 */

export const updateCookie = (payload) => {
  try {
    return request.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/update_cookie.jsp', payload);
  } catch (e) {
    return Promise.resolve({
      data: {},
      success: false,
      message: e.message,
    });
  }
};

export const updateComment = (payload) => {
  try {
    return request.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/update_quality_attributes.jsp', payload);
  } catch (e) {
    return Promise.resolve({
      data: {},
      success: false,
      message: e.message,
    });
  }
};

export const autoRenewal = (payload) => {
  try {
    return _request.put(EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/update-dimension', payload);
  } catch (e) {
    return Promise.resolve({
      data: {},
      success: false,
      message: e.message,
    });
  }
};

export const requestEtableConfig = (blockEid) => {
  return new Promise((resolve) => {
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/system/block/single', {
        id: blockEid,
      })
      .then((res) => {
        if (res.block[blockEid]) {
          const etableConfig = get(res.block[blockEid], ['payload', 'properties', 'customAttributes'], {});

          resolve({
            data: {
              ...etableConfig,
              blockEid,
              _actionInfo: {
                parentId: get(res, ['block', blockEid, 'parent_id'], ''),
                lastSyncedAt: moment.utc().format(),
                lastUpdatedAt: get(res, ['block', blockEid, 'updated_at'], ''),
              },
            },
            success: true,
            message: 'ok',
          });
        }
        resolve({
          data: { blockEid },
          success: false,
          message: 'BlockEid is not found.',
        });
      })
      .catch((e) => {
        resolve({
          data: { blockEid },
          success: false,
          message: e.message,
        });
      });
  });
};
