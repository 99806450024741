/* eslint-disable react/prop-types */
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { produce } from 'immer';
import WrapperFormat, { IPropsFormat, ICellAction } from './wrapper-format';
import { makeTable } from '../table-container';
import { TableCompactFactoryContext } from '../compact-factory';

const useStyles = makeStyles(() => ({
  container: {
    // position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    padding: '4.5px 8px',
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  keywordColor: {
    color: '#004AA8',
  },
  value: ({ isAuto }: StyleProps) => ({
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    paddingTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
  }),
  alignItem: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  autoSubtext: ({ isAuto }: StyleProps) => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
  }),
}));

export type IValueTextFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueTextFormat>;
};
const KeywordMethodFormat = (props: IPropsFormat<IValueTextFormat>) => {
  const classes = useStyles();
  const node = _.get(props, 'node', null);
  const value = _.get(props, ['value', 'value'], null);
  const label = _.get(props, ['value', 'label'], null);
  const subTabs = _.get(props, ['value', 'subTabs'], null);
  const status = _.get(props, ['value', 'status'], '');
  const subtext = _.get(props, ['value', 'subtext'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const marketplace = _.get(props, ['value', 'marketplace'], '');
  const refConfig = React.useRef({ compactTableConfig: null });
  const hiddenFilter = _.get(props, 'value.data.hiddenFilter', {});
  const isAutomatedCampaign = _.get(props, ['value', 'isAutomatedCampaign'], '');
  const marketplaceCode = _.get(props, ['value', 'marketplaceCode'], '');

  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const backbone = React.useContext(TableBackboneContext);

  const tableConfig = _.get(backbone.getCellConfig('keyword'), 'tableConfiguration');

  React.useLayoutEffect(() => {
    if (!tableConfig) return;
    const config = {
      config: produce(tableConfig, (draft) => {
        if (!tableConfig.callback) draft.callback = {};

        draft.configuration.hiddenFilter = hiddenFilter;
        draft.configuration.tableType = 'compact';
        draft.callback.onRowSelect = (row) => {
          if (isSubmitOnClosed) {
            rowsTemp.current = [].concat(row);
          } else {
            onRowSelect(row, props.node.data, backbone);
          }
        };
        draft.tableType = 'compact';
        draft.configuration.system = { ...(tableConfig.configuration.system || {}), context: props.node.data };
      }),
      selectedRows: [],
    };

    refConfig.current.compactTableConfig = config;
    // setCompactTableConfig(config);
  }, [tableConfig]);

  const isSubmitOnClosed = _.get(tableConfig, 'submitRowSelectionOnClosed', false);

  const ref = React.useRef<HTMLDivElement>();
  const onRowSelect = _.get(tableConfig, 'callback.onRowSelect', () => undefined);
  const rowsTemp = React.useRef([]);

  const handleOnClosed = () => {
    if (isSubmitOnClosed) {
      onRowSelect(rowsTemp.current, props.node.data, backbone);
    }
  };

  const displayTableCompact = (visible: boolean) => {
    const tableRender = <>{makeTable(refConfig.current.compactTableConfig)}</>;
    displayTable({
      anchorEl: ref.current,
      tableConfig: refConfig.current.compactTableConfig,
      tableEl: tableRender,
      visible: visible,
      onClosed: handleOnClosed,
    });
  };

  const cellAction: ICellAction[] = backbone.addon('keyword.method.cell.action', () => cellAction)(
    props,
    backbone,
    refConfig,
    displayTableCompact,
  );

  if (isAutomatedCampaign && marketplaceCode === 'LAZADA') {
    return (
      <Grid className={classes.container}>
        <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
          <Grid item xs={12} className={classes.alignItem}>
            <span className={classes.hashtag}>{hashtag}</span>
          </Grid>
          <Grid item xs={12} className={classes.alignItem}>
            <div className={`${classes.value} ${classes.autoSubtext}`}>{'Automated'}</div>
          </Grid>
          <Grid item xs={12} className={classes.alignItem}>
            <span className={`${classes.subtext} ${classes.autoSubtext}`}>
              {isAutomatedCampaign ? `by ${marketplace}` : subtext}
            </span>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid
        container
        alignItems={'center'}
        wrap={'nowrap'}
        className={classes.height100}
        style={{ overflow: 'hidden' }}
        ref={ref}
      >
        {/* expand icon */}
        {!_.isEmpty(subTabs) ? (
          <Grid item style={{ marginRight: !_.isEmpty(subTabs) ? '5px' : '' }}>
            <div className={`${classes.wrapperIcon} expand`}>
              <Icon type={'arrowright'} className={`${classes.icon} arrowright`} />
            </div>
          </Grid>
        ) : null}
        {/* text */}
        <Grid item style={{ width: '100%', height: '100%' }}>
          <Grid container alignItems="center" wrap={'nowrap'} className={classes.height100}>
            {/* status icon */}
            {!_.isEmpty(status) ? (
              <Grid item style={{ marginRight: !_.isEmpty(status) ? '5px' : '' }}>
                <span className={classes.wrapperIcon}>
                  <Icon type={status.toLowerCase()} className={`${classes.icon} status`} />
                </span>
              </Grid>
            ) : null}
            <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
              <Grid
                container
                alignItems={'center'}
                direction={'column'}
                wrap={'nowrap'}
                className={clsx(classes.height100, classes.alignItemsStart)}
              >
                {/*hashtag*/}
                <Grid item xs={12} className={classes.height100}>
                  <span className={classes.hashtag}>{hashtag}</span>
                </Grid>
                {/* label */}
                <Grid item xs={12} className={`${classes.height100}`}>
                  <span className={classes.label}>{`${value ? 'Automated' : 'Manual'}`} </span>
                </Grid>
                {/* subtext */}
                <Grid item xs={12} className={classes.height100}>
                  <span className={classes.subtext}>{value ? `by ${marketplace}` : ''}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default KeywordMethodFormat;
