import * as React from 'react';
import moment from 'moment';
import { debounce, get } from 'lodash';
import { useAtomValue, useSetAtom } from 'jotai';

import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';

function useEtableGridApi(
  backbone,
  callback = {
    setStatus,
    setLoadingChart,
    getRows: { success: async (result: { rowData: any[]; rowCount: number }) => {}, fail: async (error: Error) => {} },
  },
  getSelectedRows,
) {
  const api = React.useMemo(() => {
    const ds = backbone.getDatasource();
    const { limit } = backbone.getPagination();
    function refreshServerSideStore(page = 1) {
      if (page == 1) {
        callback.setLoadingChart(true);
      } else {
        callback.setStatus('loading');
      }
      ds.getRows({
        request: { startRow: (page - 1) * limit, limit: -1, groupKeys: [], rowGroupCols: {} },
        success: callback.getRows.success,
        fail: callback.getRows.fail,
      });
    }

    return {
      deselectAll: () => {},
      refreshServerSideStore: refreshServerSideStore,
      paginationSetPageSize: (...args) => {
        console.info('called with', args);
      },
      getSelectedRows: () => [],
    };
  }, []);

  return api;
}

export const useCategoryVisualization = ({ backbone, config }) => {
  const chartRef = React.useRef({ isInit: false });

  const [rowData, setRowData] = React.useState([]);
  const [loadingChart, setLoadingChart] = React.useState(false);
  const [isRequestFailed, setIsRequestFailed] = React.useState(false);
  const [status, setStatus] = React.useState<'success' | 'error' | 'loading' | 'total'>('success');

  const setLastUpdatedAt = useSetAtom(eTableAtom.lastUpdatedAt);

  const pagination = backbone.getPagination();

  const requestSuccess = React.useCallback(
    async ({ rowData, rowCount, summary, queryParams }) => {
      setLastUpdatedAt({ value: moment() });
      if (queryParams.pagination?.page == 1) {
        setRowData(rowData);
      } else {
        setRowData((prevValue) => prevValue.concat(rowData));
      }
      setLoadingChart(false);
      setIsRequestFailed(false);
      if (rowData.length < queryParams.pagination?.limit) {
        setStatus('total');
      } else {
        setStatus('success');
      }
    },
    [pagination.page],
  );

  const requestFail = React.useCallback(async () => {
    setLoadingChart(false);
    setIsRequestFailed(true);
  }, []);

  const gridApi = useEtableGridApi(backbone, {
    setStatus,
    setLoadingChart,
    getRows: { success: requestSuccess, fail: requestFail },
    getSelectedRows: () => [],
  });

  const reload = () => {
    setIsRequestFailed(false);
    gridApi.refreshServerSideStore();
  };

  React.useEffect(() => {
    backbone.setGridApi({
      grid: gridApi,
      column: {},
    });
    backbone.init();
    window.setTimeout(() => {
      window.requestAnimationFrame(() => {
        chartRef.current.isInit = true;
        gridApi.refreshServerSideStore();
      });
    }, 100 /* cool down time*/);
  }, []);

  React.useEffect(() => {
    backbone.getCallback('onBackboneReady')(backbone);
  }, []);

  React.useEffect(() => {
    if (pagination.page == 1) return;
    gridApi.refreshServerSideStore(pagination.page);
  }, [pagination.page]);

  React.useEffect(() => {
    let tid = 0;
    if (get(config, 'refreshInterval', null)) {
      console.info('auto refresh');
      tid = window.setInterval(() => {
        gridApi.refreshServerSideStore();
      }, get(config, 'refreshInterval'));
    }
    return () => {
      window.clearInterval(tid);
    };
  }, [config]);

  const onLoadMore = () => {
    backbone.changePagination({ page: pagination.page + 1, limit: pagination.limit });
  };

  return {
    loadingChart,
    isRequestFailed,
    reload,
    rowData,
    limit: pagination.limit,
    onLoadMore,
    status,
  };
};
