import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Column = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <rect x="6" y="2" width="4" height="12" rx="1" fill={props.color} />
    <rect x="2" y="2" width="3" height="12" rx="1" fill={props.color} />
    <rect x="11" y="2" width="3" height="12" rx="1" fill={props.color} />
  </SvgIcon>
);

export default Column;
