import * as React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles(() => ({
  errorMessage: {
    padding: '8px',
    background: '#FCEEEC',
    borderRadius: '4px',
    color: '#253746',
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    transform: 'translateY(20px)',
    '& svg': {
      width: 12,
      height: 12,
    },
  },
}));

const promotedObjectRules = (rowSelected, maxSelectedError) => {
  if (maxSelectedError) {
    return {
      error: true,
      message: 'The maximum selected promoted object is 10',
    };
  }
  const categoryHasOneProduct =
    rowSelected.map((row) => row['PRODUCT.category_id']).filter((el, _, arr) => arr.indexOf(el) === arr.lastIndexOf(el))
      .length > 0;

  if (categoryHasOneProduct) {
    return {
      error: true,
      message: 'At least 2 products per category',
    };
  }
  return {
    error: false,
    message: '',
  };
};

const TableCompactError = ({ rowSelected, maxSelectedError }: any) => {
  const classes = useStyles();

  const { error, message } = promotedObjectRules(rowSelected, maxSelectedError);

  if (!error) return null;

  return (
    <div className={classes.errorMessage}>
      <span>
        <Icon type={'error'} />
      </span>
      <span>{message}</span>
    </div>
  );
};

export default TableCompactError;
