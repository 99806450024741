import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ColumnChart = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="3 3 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 3C7 2.44772 7.44772 2 8 2C8.55228 2 9 2.44772 9 3V13C9 13.5523 8.55228 14 8 14C7.44772 14 7 13.5523 7 13V3Z" />
    <path d="M3 6C3 5.44772 3.44772 5 4 5C4.55228 5 5 5.44772 5 6V13C5 13.5523 4.55228 14 4 14C3.44772 14 3 13.5523 3 13V6Z" />
    <path d="M11 10C11 9.44772 11.4477 9 12 9C12.5523 9 13 9.44772 13 10V13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13V10Z" />
  </SvgIcon>
);

export default ColumnChart;
