import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { get, set } from 'lodash';

// aim means access identity management
const TOKEN_KEY = 'eptoken';
export function setLoginToken(token: string | null) {
  if (token === null) {
    window.localStorage.removeItem(TOKEN_KEY);
  } else {
    console.debug('update token', getLoginToken().token !== token);
    window.localStorage.setItem(TOKEN_KEY, JSON.stringify({ token, lastUpdated: Date.now() }));
  }
  return token;
}

export function getLoginToken(): {
  token: string | null;
  lastUpdated: number | null;
} {
  const token = window.localStorage.getItem(TOKEN_KEY);
  let parsedToken;
  try {
    parsedToken = token ? JSON.parse(token) : { token: null, lastUpdated: null };
  } catch (error) {
    parsedToken = { token: null, lastUpdated: null };
  }
  return parsedToken;
}

export type UserSettings = {
  profile: {
    userId?: string;
    oldUserId?: string;
    userName: string;
    userEmail: string;
    workspace_id: number;
    workspace_homepage?: string;
    workspace_domain?: string;
    workspace_role_id?: string;
    workspace_role_name?: string;
    favorite?: string | null;
  };
  permissions?: {
    byShopId: {
      [shopId: string]: { [featureCode: string]: string[] };
    };
    byCode: {
      [permCode: string]: string[];
    };
  };
  lastUpdated?: number;
  isAuth0?: boolean;
  adminMode?: string[];
  adminConfig?: {
    origin?: Partial<Record<keyof UserSettings['profile'], string>>;
    override?: Partial<Record<keyof UserSettings['profile'], string>>;
    userEmail?: string;
    viewAsUserId?: string;
    isAnonymous?: boolean;
  };
  workspaces?: any[];
};

const USER_SETTINGS_KEY = 'epusersettings';

let _cachedUserSettings = null;

export function setUserSettings(settings: UserSettings | null) {
  if (settings === null) {
    clearUserSettings();
  }
  window.localStorage.setItem(USER_SETTINGS_KEY, JSON.stringify({ ...settings, lastUpdated: Date.now() }));
  _cachedUserSettings = { ...settings, lastUpdated: Date.now() };
}

export function getUserSettings(): UserSettings {
  if (_cachedUserSettings) return _cachedUserSettings;
  const str = window.localStorage.getItem(USER_SETTINGS_KEY);

  _cachedUserSettings = str ? JSON.parse(str) : null;

  return _cachedUserSettings;
}

export function clearUserSettings() {
  _cachedUserSettings = null;
  window.localStorage.removeItem(USER_SETTINGS_KEY);
}

let authEvt: EventTarget;
export function getAuthEvent() {
  if (!authEvt) {
    authEvt = new EventTarget();
    authEvt.addEventListener('onExpired', () => setLoginToken(null));
  }

  return {
    onExpired: function (fn: EventListener) {
      authEvt.addEventListener('onExpired', fn);
    },
    triggerExpired: function () {
      authEvt.dispatchEvent(new Event('onExpired'));
    },
  };
}

export function isAllow() {
  const userSettings = getUserSettings();
  if (!userSettings || !userSettings.profile) {
    return false;
  }
  if (process.env.EIP_BUILD_ENV === 'staging') {
    return true;
  }

  return (
    ['phuc.phan@epsilo.io', 'quang@epsilo.io', 'nishant.didawat@epsilo.io', 'hau.ho@epsilo.io'].indexOf(
      userSettings.profile.userEmail,
    ) > -1
  );
}

export function isSuperAdmin(userEmail?: string) {
  const userSettings = getUserSettings();
  if (!userSettings || !userSettings.profile) {
    return false;
  }
  if (process.env.EIP_BUILD_ENV === 'staging') {
    return true;
  }
  return (
    ['phuc.phan@epsilo.io', 'quang@epsilo.io', 'huyen.ng@epsilo.io', 'leo.huynh@epsilo.io', 'zoe.ng@epsilo.io'].indexOf(
      userEmail || userSettings.profile.userEmail,
    ) > -1
  );
}

export function canAccess(resourceTag = null) {
  try {
    const userSettings = getUserSettings();
    const isEpsilo = userSettings.profile.userEmail.includes('@epsilo.io');
    const userEmail = userSettings.profile.userEmail;

    if (resourceTag === 'safeMode:toggle') {
      return getConst('safeMode:toggle', [
        'hau.ho@epsilo.io',
        'tung.le@epsilo.io',
        'quang@epsilo.io',
        'phuc.phan@epsilo.io',
      ]).includes(userEmail);
    }

    if (isSuperAdmin()) return true;

    if (['section_botep', 'tracing', 'page:syncTo', 'page:remove'].includes(resourceTag) && isEpsilo) {
      return true;
    } else if (
      resourceTag == 'page:edit' &&
      getConst(['perm', 'page:edit'], ['hau.ho@epsilo.io', 'irene.vo@epsilo.io']).includes(userEmail)
    ) {
      return true;
    } else if ('internal' == resourceTag) {
      return isEpsilo;
    } else if ('sidebar:admin' === resourceTag) {
      const originEmail = get(userSettings, 'adminConfig.origin.userEmail', userEmail);
      let allow = isSuperAdmin(originEmail);

      const revTeam = [
        'alex.vo@epsilo.io',
        'son@epsilo.io',
        'hung.mai@epsilo.io',
        'nishant.didawat@epsilo.io',
        'duc.luu@epsilo.io',
      ];
      allow = allow || getConst(['perm', 'sidebar:admin'], revTeam).includes(originEmail);

      return allow;
    } else if (resourceTag === 'ray') {
      const defaultRayAccess = [
        'hau.ho@epsilo.io',
        'irene.vo@epsilo.io',
        'khanh.ho@epsilo.io',
        'tung.le@epsilo.io',
        'huy.truong@epsilo.io',
        'thien.chau@epsilo.io',
      ];
      return getConst(['perm', 'ray'], defaultRayAccess).includes(userEmail);
    } else {
      return false;
    }
  } catch (err) {
    return false;
  }
}

export function isAllowBasedOnList(allowance: string[]) {}

export function isViewAsUserMode() {
  const settings = getUserSettings();
  if (!settings) return false;
  return settings.adminMode?.includes(EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);
}

export function resetAdminMode(mode) {
  const userSettings = getUserSettings();
  switch (mode) {
    case EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER:
      const adminMode = userSettings.adminMode.filter((i) => i !== EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);
      const origin = get(userSettings, 'adminConfig.origin');
      set(userSettings, 'adminConfig.viewAsUserId', undefined);
      set(userSettings, 'adminConfig.userEmail', undefined);
      set(userSettings, 'adminConfig.origin', undefined);
      set(userSettings, 'adminConfig.override', undefined);
      set(userSettings, 'adminConfig.isAnonymous', undefined);
      set(userSettings, 'adminMode', adminMode);
      for (const key in origin) {
        set(userSettings, `profile.${key}`, origin[key]);
      }
      setUserSettings(userSettings);
      break;
  }
}
