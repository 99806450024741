import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Sandbox = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.5 3C3.5 2.44772 3.94772 2 4.5 2H6.5C7.05228 2 7.5 2.44772 7.5 3C7.5 3.55228 7.05228 4 6.5 4H5.5V7C5.5 7.55228 5.05228 8 4.5 8C5.05228 8 5.5 8.44772 5.5 9V12H6.5C7.05228 12 7.5 12.4477 7.5 13C7.5 13.5523 7.05228 14 6.5 14H4.5C3.94772 14 3.5 13.5523 3.5 13V10C2.94772 10 2.5 9.55228 2.5 9V7C2.5 6.44772 2.94772 6 3.5 6V3Z"
      fill={`${props.color}`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 3C12.5 2.44772 12.0523 2 11.5 2H9.5C8.94772 2 8.5 2.44772 8.5 3C8.5 3.55228 8.94772 4 9.5 4H10.5V7C10.5 7.55228 10.9477 8 11.5 8C10.9477 8 10.5 8.44772 10.5 9V12H9.5C8.94772 12 8.5 12.4477 8.5 13C8.5 13.5523 8.94772 14 9.5 14H11.5C12.0523 14 12.5 13.5523 12.5 13V10C13.0523 10 13.5 9.55228 13.5 9V7C13.5 6.44772 13.0523 6 12.5 6V3Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default Sandbox;
