export enum PROPERTY_TYPE {
  DIMENSION = 'dimension',
  ATTRIBUTE = 'attribute',
  METRIC = 'metric',
  REQUEST_HIDDEN_FIELD = 'requestHiddenField',
}

export enum DATA_TYPE {
  STRING = 'string',
  INTEGER = 'integer',
  FLOAT = 'float',
  DATETIME = 'datetime',
  DATE = 'date',
  NEXTSTRING = 'nextString',
}

export const generateColumnKeys = (name) => {
  return name.replace(/[A-Z]/g, (c) => `_${c.toLowerCase()}`).replace(/\_format$/, '');
};
