import * as React from 'react';
import { get, uniq, zipObject, set } from 'lodash';

import { Button, makeStyles, Box, Popover } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { SCRIPT_EXECUTION_LOG } from '@ep/insight-ui/system/block/etable/table-config/script-execution-log';
import { TableCompactFactoryContext } from '@ep/insight-ui/elements/table/compact-factory';
import { TableCompactFactoryContext as TableCompactFactoryContext2 } from '@ep/insight-ui/elements/etable2/compact-factory';
import { makeTable } from '@ep/insight-ui/elements/table/table-container';
import produce from 'immer';
import { stripUniversalPrefix } from '@ep/insight-ui/sw/util/column';

const useStyles = makeStyles(() => ({
  button: {
    padding: '0 8px',
    height: '32px',
    marginBottom: '6px',
  },
}));

const ViewHistory = (props) => {
  const classes = useStyles();
  const ref = React.useRef(null);

  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const { display: displayTable2 } = React.useContext(TableCompactFactoryContext2);

  const marketplace = get(props, ['rowData', 'MARKETPLACE.marketplace_code'], '');
  const objType = {
    SHOPEE: 'SHP',
    LAZADA: 'LZD',
    TOKOPEDIA: 'TOKO',
  };
  const type = props.adsUnit ? props.adsUnit : objType[marketplace] ? `${objType[marketplace]}_${props.adsType}` : '';

  const getHistoryCompactConfig = () => {
    const cloneConfig = produce(SCRIPT_EXECUTION_LOG, (draft) => {
      if (props.type === 'daily_budget') {
        draft.mapping.expected_value.dataType = 'integer';
        draft.mapping.expected_value.cellFormat = 'currency';
        draft.mapping.expected_value.staticValue.currency = props.currency;
      }
    });

    return {
      config: {
        configuration: cloneConfig,
        addons: {
          'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
            const mapping = get(backbone, ['config', 'mapping'], {});

            const logFields = uniq(
              Object.values(mapping).reduce((a, b) => {
                const valueGetter = Object.values(get(b, 'valueGetter', {})).filter((el: string) =>
                  el.startsWith('changelog_ads_ops_dimension'),
                );
                return [...a, ...valueGetter];
              }, []),
            );

            const finalParams = produce(params, (draft) => {
              draft.log = logFields;
              draft.currency = 'USD';
              draft.hiddenFilter.currency = 'USD';
              draft.pagination.limit = 1000;
              draft.filter = {
                combinator: 'and',
                filters: [
                  ...(params.filter?.filters || []).map((filter) => {
                    if (filter.dataType === 'integer') filter.value = Number(filter.value);
                    return filter;
                  }),
                  {
                    dataType: 'string',
                    field: 'script_execution_log.master_object_id',
                    operator: 'is',
                    value: props.adsId,
                  },
                  {
                    dataType: 'string',
                    field: 'script_execution_log.master_object_type',
                    operator: 'is',
                    value: type,
                  },
                  {
                    dataType: 'string',
                    field: 'script_execution_log.trigger_type',
                    operator: 'is',
                    value: 'AttributeCalendar',
                  },
                  {
                    dataType: 'string',
                    field: 'script_execution_log.field_name',
                    operator: 'is',
                    value: props.type,
                  },
                ],
              };
              draft.sort = [
                {
                  field: 'script_execution_log.created_at',
                  sort: 'desc',
                },
              ];
              draft.isSummary = true;
              delete draft.metric;
              delete draft.from;
              delete draft.to;
            });

            const result = await originRequest(finalParams);

            const mainHeaders = get(result, 'data.headers', {});
            const rows = get(result, 'data.rows', []);

            const masterData = get(result, 'data.masterData', {});
            const masterDataPrimaryKey = get(result, 'data.masterDataPrimaryKey', {});

            const mapEntity = Object.entries(masterDataPrimaryKey).reduce((carry, [entityType, fields]) => {
              const rawHeaders = get(masterData, [entityType, 'headers'], []);
              const headers = rawHeaders
                .map((i) => `${entityType}.${i}`)
                .concat(rawHeaders.map((i) => stripUniversalPrefix(`${entityType}.${i}`)));
              const rows = get(masterData, [entityType, 'rows'], []);

              return {
                ...carry,
                [entityType]: rows.reduce((carry, row) => {
                  const obj = zipObject(headers, row.concat(row));
                  const id = fields.map((field) => obj[`${entityType}.${field}`]).join('|');
                  return { ...carry, [id]: obj };
                }, {}),
              };
            }, {});

            const enrichedRows = rows.map((i, index) => {
              let item: any = Object.entries(i).reduce((carry, [key, value]) => {
                return {
                  ...carry,
                  [key]: value,
                  [stripUniversalPrefix(key)]: value,
                };
              }, {});
              Object.entries(masterDataPrimaryKey).forEach(([entityType, fields]) => {
                const id = fields.map((field) => get(item, `${entityType}.${field}`)).join('|');
                item = { ...item, ...mapEntity[entityType][id] };
              });

              return {
                ...item,
                currency: params.hiddenFilter?.currency,
              };
            });

            set(result, 'data.rows', enrichedRows);
            set(result, 'data.headers', enrichedRows[0] ? Object.keys(enrichedRows[0]) : mainHeaders);

            return result;
          },
          ...(props.type === 'status'
            ? {
                'filterValue.expected_value': async () => {
                  return Promise.resolve({
                    data: [
                      {
                        label: 'ONGOING',
                        value: 'ONGOING',
                      },
                      {
                        label: 'PAUSED',
                        value: 'SUSPENDED',
                      },
                    ],
                    success: true,
                    message: 'ok',
                  });
                },
              }
            : {}),
          'selection.filter': (colId) => {
            if ((props.type === 'status' && colId === 'expected_value') || colId === 'status')
              return ['is', 'is_not', 'contains', 'does_not_contain'];
            return [];
          },
          'filterValue.status': async () => {
            return Promise.resolve({
              data: [
                {
                  label: 'Succeeded',
                  value: 'COMPLETED',
                },
                {
                  label: 'Failed',
                  value: 'SKIPPED',
                },
              ],
              success: true,
              message: 'ok',
            });
          },
        },
      },
    };
  };

  const displayHistoryTableCompact = () => {
    const tableRender = makeTable(getHistoryCompactConfig());
    displayTable({
      anchorEl: ref.current,
      tableConfig: getHistoryCompactConfig(),
      tableEl: tableRender,
      visible: true,
      onClosed: () => undefined,
    });
    displayTable2({
      anchorEl: ref.current,
      tableConfig: getHistoryCompactConfig(),
      tableEl: tableRender,
      visible: true,
      onClosed: () => undefined,
    });
    if (props.onViewHistoryOpen) {
      props.onViewHistoryOpen();
    }
  };

  return (
    <Box>
      <Button ref={ref} className={classes.button} onClick={displayHistoryTableCompact}>
        <Icon type={'history'} />
        <Box style={{ whiteSpace: 'nowrap', marginLeft: '8px' }}>View history</Box>
      </Button>
    </Box>
  );
};

export default ViewHistory;
