import * as React from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { makeStyles } from '@material-ui/core';

import { calendarSingleStyle, calendarStyle } from '../helper';
import { CreateCSSProperties } from '@material-ui/styles';
import moment from 'moment';
import clsx from 'clsx';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';
const useStyles = makeStyles(() => ({
  calendar: calendarStyle as CreateCSSProperties,
  calendarSingleStyle: calendarSingleStyle as CreateCSSProperties,
}));
type Props = {
  value: string;
  defaultFormatReturn?: string;
  onChange: (newValue: string) => void;
  minDate?: string;
  maxDate?: string;
  onCloseDialog?: () => void;
};
const formatSystem = 'YYYY/MM/DD';

const generateDateObject = (val: string, format = formatSystem): DateObject => {
  const dateString = moment(val, format).format();
  return new DateObject(dateString);
};
const SingleCalendar = ({
  value,
  onChange,
  defaultFormatReturn = 'DD/MM/YYYY',
  minDate = '',
  maxDate = '',
  onCloseDialog = () => undefined,
}: Props) => {
  const classes = useStyles();
  const convertValue = (text: string) => {
    if (!text) return null;
    return generateDateObject(text, defaultFormatReturn);
  };
  const [date, setDate] = React.useState<DateObject>(convertValue(value));
  const getSystemFormat = (val: string) => {
    if (!val) return null;
    return moment(val, defaultFormatReturn).format(formatSystem);
  };

  //   handle active button
  React.useEffect(() => {
    if (!date) return;
    const dateValue = date.format(defaultFormatReturn);
    if (dateValue !== value) {
      onChange(dateValue);
    }
  }, [date]);
  const handleChange = (data: any) => {
    setDate(data);
    onCloseDialog();
  };
  return (
    <Calendar
      disableMonthPicker
      disableYearPicker
      value={date}
      onChange={handleChange}
      className={clsx(classes.calendar, classes.calendarSingleStyle)}
      weekDays={CALENDAR_WEEKDAYS}
      weekStartDayIndex={1}
      minDate={getSystemFormat(minDate)}
      maxDate={getSystemFormat(maxDate)}
      showOtherDays
    />
  );
};

export default SingleCalendar;
