import React from 'react';
import { Bar } from 'react-chartjs-2';

const TractionFormat = () => {
  const chartLength = Array.from({ length: 15 });
  const generateLineData = () => chartLength.map(() => Math.round(Math.random() * 9) + 1);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
        max: 15,
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
    },
    hover: {
      mode: null,
    },
  };

  const initialData = React.useMemo(() => {
    return {
      labels: chartLength.map(() => ''),
      datasets: [
        {
          type: 'line',
          label: 'Dataset 1',
          borderColor: '#004AA8',
          borderWidth: 2,
          fill: false,
          data: generateLineData(),
          lineTension: 0.4,
          pointRadius: 0,
        },
      ],
    };
  }, []);

  return (
    <>
      <Bar data={initialData} options={options} height={1} width={3} />
    </>
  );
};

export default TractionFormat;
