import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import FlexBox from '../../share-with-collaborators/Flexbox';
import Typography from '../../text-style/Typography';

type PropsStyle = {
  inputWidth: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  container: {
    '& .eip1-MuiAutocomplete-listbox': {
      padding: '0px !important',
    },
    width: (props: PropsStyle) => props.inputWidth || 'auto',
    '& .eip1-MuiAutocomplete-popupIndicator': {
      marginRight: '5px',
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        color: colors.icon.default,
      },
    },
    '& .eip1-MuiInputBase-root.eip1-Mui-disabled': {
      backgroundColor: colors.surface.disable,
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
  },
  paper: {
    boxShadow: colors.shadow.hover,
  },
  classNameIcon: {
    minWidth: 'unset',
    marginRight: '6px',
  },
  'mb-5': {
    marginBottom: '3px',
  },
  wrapIcon: {
    padding: '2px',
    lineHeight: '0',
  },
  wrapEndIcon: {
    padding: '11px',
    boxSizing: 'border-box',
    position: 'absolute',
    right: 0,
    display: 'flex',
    cursor: 'pointer',
  },
  endIcon: {
    width: '10px',
    height: '10px',
  },
  wrapOptionIcon: {
    padding: '10px 10px 10px 0px',
    lineHeight: '0',
  },
  renderOption: {
    margin: '-6px',
    '& .optionLabel': {
      color: colors.text.default,
    },
    '& .eip1-MuiListItemIcon-root': {
      minWidth: 'unset',
      '& svg': {
        marginBottom: '1.5px',
      },
    },
  },
  option: {
    backgroundColor: 'transparent',
    '&[aria-selected="true"]': {
      backgroundColor: '#EBF6FF',
      color: '#0369C7',
      '& .optionLabel': {
        color: '#0369C7',
      },
      '& .eip1-MuiListItemIcon-root': {
        '& svg': {
          color: '#0369C7',
        },
      },
    },
    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
  },
}));

interface SelectFormProps {
  options: OptionSelectType[];
  value: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  inputWidth?: string;
  placeholder?: string;
  className?: string;
  icon?: boolean;
  colorIcon?: string;
}

const SelectSearchFormIcon = ({
  options,
  value,
  onChange,
  disabled,
  inputWidth,
  placeholder,
  className,
  icon = false,
  colorIcon = colors.icon.default,
}: SelectFormProps) => {
  const classes = useStyles({ inputWidth });
  return (
    <FormControl>
      <Autocomplete
        multiple={false}
        disableClearable
        disabled={disabled}
        classes={{
          paper: classes.paper,
          option: classes.option,
        }}
        options={options}
        autoHighlight
        value={options.find((item) => item.value === value)}
        onChange={(event: any, newValue: OptionSelectType | null) => {
          onChange(newValue.value);
        }}
        className={`${classes.container} ${className}`}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <FlexBox alignItems="center" className={icon && classes.renderOption}>
            {icon && (
              <ListItemIcon>
                <div className={icon && classes.wrapOptionIcon}>
                  <Icon type={option.icon} size="12px" colorIcon={colorIcon}></Icon>
                </div>
              </ListItemIcon>
            )}
            <Typography variant="body2" className="optionLabel">
              {option.label}
            </Typography>
          </FlexBox>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
              startAdornment: icon && (
                <ListItemIcon>
                  <div className={classes.wrapIcon}>
                    <Icon
                      type={options.find((item) => item.value === value)?.icon || ''}
                      size="12px"
                      colorIcon={colorIcon}
                    ></Icon>
                  </div>
                </ListItemIcon>
              ),
              // endAdornment: (
              //   <div className={classes.wrapEndIcon}>
              //     <Icon type="chevron" size="10px" className={classes.endIcon}></Icon>
              //   </div>
              // ),
            }}
          />
        )}
        popupIcon={<Icon type="chevronMedium" size="10px" />}
      />
    </FormControl>
  );
};

export default SelectSearchFormIcon;
