import * as React from 'react';
import { useSetAtom } from 'jotai';
import { get } from 'lodash';

import { Button } from '@material-ui/core';

import { scriptEditor } from '@ep/insight-ui/system/atom/script-editor';
import ScriptEditor from '@ep/insight-ui/elements/script-editor';
import { aim, eipRequest as request } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

const ScriptEditorFormat = (props) => {
  const ref = React.useRef(null);
  const setOpenCodeEditor = useSetAtom(scriptEditor);
  const userProfile = aim.getUserSettings().profile || {};
  const { onToastMultiple } = useToast();

  const handleEditScript = () => {
    setOpenCodeEditor({
      isDisplay: true,
      ref,
      content: String(get(props, ['value'], '')),
      submit: (content) => {
        try {
          if (props.setValue) {
            props.setValue(content);
            setOpenCodeEditor((config) => {
              return {
                ...config,
                isDisplay: false,
              };
            });
          }
        } catch (e) {
          alert(e.message);
        }
      },
      test: (content) => {
        const providedUserProfile = {
          ...userProfile,
          token: String(userProfile?.userEmail).endsWith('@epsilo.io') ? aim.getLoginToken().token : '',
        };

        const variables = [
          ['notify', onToastMultiple],
          ['request', request],
          ['user', providedUserProfile],
        ];
        const v1 = variables.map((v) => v[0]);
        const v2 = variables.map((v) => v[1]);

        return Function(...v1, content)(...v2);
      },
      backboneConfig: null,
      availableVars: [],
      defaultWidth: 1,
      language: 'javascript',
    });
  };

  return (
    <div ref={ref}>
      <Button variant="contained" color="secondary" onClick={handleEditScript}>
        Edit script
      </Button>
      <ScriptEditor />
    </div>
  );
};

export default ScriptEditorFormat;
