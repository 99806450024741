import { cloneDeep, get, groupBy, isEqual, update } from 'lodash';
import { buffer, concatMap, map, mergeMap, Observable, share, Subject } from 'rxjs';
import { uQuery } from './origin-request';

type QueryItem = {
  endpoint: string;
  params: ETableQuery;
  columnEffects: string[];
  rowEffect?: number[];
  process: (result: ETableResponseType) => Promise<ETableDataUpdate[]>;
};

export type ETableDataUpdate = {
  keyId: string;
  keys: Record<string, number | string>;
  updatePath: string[];
  data: any;
};

export class PushCellUpdate {
  queryRequest: Subject<QueryItem>;
  requestList: QueryItem[] = [];
  params: Record<string, any>;
  requestPipe: Observable<any>;
  addDone$: Subject<boolean>;
  callback: (error: any, arg: ETableDataUpdate[], retryQuery?: QueryItem[]) => void;

  constructor(callback) {
    this.queryRequest = new Subject();
    this.addDone$ = new Subject();
    this.callback = callback;

    this.requestPipe = this.queryRequest.pipe(
      map((query) => {
        return query;
      }),
      buffer(this.addDone$), // frequency, rate limit control
      mergeMap(async (requestList) => {
        // reduce the duplication on the endpoint, merge metrics into query
        const queryResults = await Promise.all(requestList.map((request) => uQuery(request.endpoint, request.params)));
        const results = await Promise.all(requestList.map((request, index) => request.process(queryResults[index])));

        return results.flat();
      }),
      map((updateList) => {
        const groupedByCell = groupBy(updateList, (i) => i.keyId);
        return groupedByCell;
      }),
      share(),
    );

    this.requestPipe.subscribe({
      next: (results) => {
        this.callback(null, results, this.requestList);
      },
      error: (err) => {
        const error = get(err, 'error', {});
        this.callback(error, null, this.requestList);
      },
      complete: () => {
        // todo
      },
    });
  }

  addQuery(item: QueryItem) {
    this.requestList.push(item);
    this.queryRequest.next(item);
  }

  complete() {
    this.addDone$.next(true);
  }
}
