import * as React from 'react';

import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { Box, BoxProps, Button, Grid, makeStyles } from '@material-ui/core';
import FormControlInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-input';
import Selection from '@ep/insight-ui/elements/input-form/form-templates/form-controls/selection';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';

import { useScreenInfo, useStorefrontForm } from './hooks/use-new-storefront';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  size: {
    '&.w-100': {
      width: '100%',
    },
    '&.h-100': {
      height: '100%',
    },
    '&.mr-2': {
      marginRight: '12px',
    },
    '&.mt': {
      marginTop: '30px',
    },
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '32px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textOPT': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#D4290D',
    },
    '&.no-wrap': {
      whiteSpace: 'nowrap',
    },
    '&.currency': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#253746',
    },
    '&.subText': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#8C98A4',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.text-sm': {
      fontSize: '12px',
      lineHeight: '12px',
    },
    '& .link': {
      color: '#006EC6',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  formPassword: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  textfieldPass: {
    width: '66.7%',
    '& input': {
      width: '100%',
    },
  },
  lablePassword: {
    marginTop: '5px',
  },
  linkWord: {
    color: '#0369C7',
    cursor: 'pointer',
  },
  credentialDescription: {
    color: '#8C98A4',
    marginBottom: '26px',
  },
  selectStorefrontLabel: {
    color: '#8C98A4',
    marginBottom: '8px',
  },
  section: {
    maxWidth: '768px',
    marginTop: '38px',
  },
});
const boxContent: BoxProps = {
  width: '90%',
  marginLeft: '17px',
  // margin: 'auto',
};

const NewStorefrontForm = () => {
  const classes = useStyles();

  const { marketplaceOptions, countryOptions } = useScreenInfo();
  const {
    marketplace,
    setMarketplace,
    country,
    setCountry,
    username,
    setUsername,
    password,
    setPassword,
    otp,
    setOtp,
    isCredentialFormBtnDisabled,
    onValidateCredential,
    isPasswordValid,
    onValidateOtp,
    isOTPValid,
    items,
    setItems,
    canIntegrate,
    onIntegrateSubmit,
    isCredentialFormSuccess,
    isOTPFormSuccess,
    isOTPFormBtnDisabled,
  } = useStorefrontForm();

  return (
    <Grid container direction={'column'}>
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>Integrate new storefront</span>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <ExpansionForm label={'Credential information'} level={'1'} detailContentSize={'full'} defaultExpanded={true}>
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Selection
                  options={marketplaceOptions}
                  placeholder={'Choose marketplace'}
                  classNameContainer={`${classes.formControl} select`}
                  onChange={(event) => setMarketplace(event.target.value)}
                  label={'Marketplace'}
                  value={marketplace}
                />
              </Grid>
              <Grid item xs={12}>
                <Selection
                  options={countryOptions}
                  placeholder={'Choose country'}
                  classNameContainer={`${classes.formControl} select`}
                  onChange={(event) => setCountry(event.target.value)}
                  label={'Country'}
                  value={country}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlInput
                  label={'Username'}
                  classNameContainer={`${classes.formControl} input`}
                  placeholder={'Your username to sign in to the Seller Center'}
                  defaultValue={username}
                  onChangeText={(value) => {
                    setUsername(value);
                  }}
                />
              </Grid>
              <Grid className={classes.formPassword} item xs={12}>
                <FormControlInput
                  label={'Password'}
                  placeholder={'Your password'}
                  type={'password'}
                  defaultValue={password}
                  onChangeText={(value) => {
                    setPassword(value);
                  }}
                  helperText={'8+ mix of characters, numbers and special characters'}
                  error={!isPasswordValid}
                />
              </Grid>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Grid>
                    <div className={classes.credentialDescription}>
                      <p>
                        Your account will be encrypted and used for operating your storefront. No one can access this
                        information. Visit <span className={classes.linkWord}>Privacy Policy</span> for more information
                        on how we protect your data.
                      </p>
                    </div>
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      className={classes.button}
                      onClick={onValidateCredential}
                      disabled={isCredentialFormBtnDisabled}
                    >
                      Validate your credential
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ExpansionForm>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <ExpansionForm
          label={'Update OTP information'}
          level={'1'}
          detailContentSize={'full'}
          defaultExpanded={isCredentialFormSuccess}
          disabled={!isCredentialFormSuccess}
        >
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlInput
                  label={'OTP code'}
                  classNameContainer={`${classes.formControl} input`}
                  placeholder={'Please enter your OTP code'}
                  defaultValue={otp}
                  onChangeText={(value) => {
                    setOtp(value);
                  }}
                  helperText={!isOTPValid && 'Invalid OTP code . Please check and try again'}
                  error={!isOTPValid}
                />
              </Grid>
              <Grid container alignItems={'center'} justifyContent={'space-between'}>
                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                  <Grid>
                    <div className={classes.credentialDescription}>
                      <p>
                        This OTP will be used to access your seller center account. No one can access this information.
                      </p>
                      <p>
                        By clicking “Verify and access” button below, you agree Epsilo to use the email
                        “SHP.Chee.anvat@gmail.com@epsilo.io” to receive OTP in the future. This email is manage by
                        Epsilo and no one can access it.
                      </p>
                      <p>
                        Visit <span className={classes.linkWord}>Privacy Policy</span> for more information on how we
                        protect your data.
                      </p>
                    </div>
                    <Button
                      color="primary"
                      variant="contained"
                      component="span"
                      className={classes.button}
                      disabled={isOTPFormBtnDisabled}
                      onClick={onValidateOtp}
                    >
                      Validate OTP
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ExpansionForm>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <ExpansionForm
          label={'Select storefront '}
          level={'1'}
          detailContentSize={'full'}
          defaultExpanded={isOTPFormSuccess && isCredentialFormSuccess}
          disabled={!isOTPFormSuccess || !isCredentialFormSuccess}
        >
          <Box>
            <div className={classes.selectStorefrontLabel}>
              Select one or more store to integrate with Epsilo. Stores that already integrate with Epsilo will be
              choosed & can’t integrate one more time.
            </div>
            <CheckboxList items={items} onChange={setItems} />
          </Box>
        </ExpansionForm>
      </Grid>
      <Grid item xs={12} className={`${classes.size} mt`}>
        <Box {...boxContent} display="flex" alignItems="center" justifyContent="flex-end">
          <Button color="secondary" variant="contained" component="span" className={`${classes.button} cancel`}>
            <span className={`${classes.text} textButton`}>Cancel</span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            component="span"
            className={`${classes.button}`}
            disabled={!canIntegrate}
            onClick={onIntegrateSubmit}
          >
            Integrate
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
export default NewStorefrontForm;
