/**
 * ff.custom_filter_value_addon:start
 */
import { EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { compare } from 'fast-json-patch';
import produce from 'immer';
import { cloneDeep, flatten, get, head, isEqual, last, set, uniq, isArray } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
/**
 * ff.function_crud_etable_view:start
 */
import { v4 as uuid } from 'uuid';
import { viewByTimelineOptions } from '../../block/etable/etable-config/utils/constant';
import { UaWatchlist } from '../../util/uamonit/uamonit-hooks';
import dataRequest from '../data-source/common';
/**
 * ff.custom_filter_value_addon:end
 */
import { getAPIRequest } from './api-request';
import { callbackGenerate } from './callback-generator';
import { DEFAULT_OPTIONS } from './mockup';
import { createDatasource } from './table-datasource';
import { StatusType } from './workflow-type';
/**
 * ff.function_crud_etable_view:end
 */

const log = useLog('lib:table-backbone');
/**
 * ff.export_etable:end
 */

/**
 * Table backbone controls:
 * System function:
 *  # columns mapping
 *    # edot
 *    # edot actions
 *  # api request: filtering, sorting, grouping, aggregation, searching
 *  # pagination
 *  # inline edit
 *    # muliple single edit
 *    # mass editing
 *    # predefined option and validation
 *  # filter
 *  # sort
 *  # group by
 *    # aggregation
 *  # configurable components
 *  # view selection
 *  # default time range: period, custom date range
 *  # user personalization regions
 *  # localization
 *
 * Personalization:
 *  # hide/show columns
 *  # filter
 *  # sort
 *  # column orderings
 *  # group by
 *    # aggregation
 *  # view managing
 *  # time range
 *
 * Experience:
 *  # Pinthis
 *
 * ### TODO
 * * Let start creating test for:
 * * grouping and aggregation
 * * Edot actions for open new link with URL component from row
 * * Thinking about mocking the backbone
 */

const DEFAULT_PAGINATION = {
  limit: 100,
  page: 1,
  total: 0,
  ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
};

const DEFAULT_CONFIG = {
  title: 'Title',
  ...(ff.function_crud_etable_view
    ? {
        view: {
          id: 'default',
          name: 'Default',
          combinator: {
            filter: [],
            sort: [],
            properties: {
              dimension: [],
              attribute: [],
              metric: [],
            },
            groupBy: {},
            pinnedColumn: [],
          },
          systemView: true,
          initializedView: false,
        },
        views: [
          {
            id: 'default',
            name: 'Default',
            combinator: {
              filter: [],
              sort: [],
              properties: {
                dimension: [],
                attribute: [],
                metric: [],
              },
              groupBy: {},
              pinnedColumn: [],
            },
            systemView: true,
            initializedView: false,
          },
        ],
        allowCustomView: true,
      }
    : {
        view: { id: 'all', name: 'All' },
        views: [
          {
            id: 'all',
            name: 'All',
          },
        ],
      }),
  tableType: '',
  dateRange: {
    dateFrom: moment().subtract(7, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().format(EIP_CONSTANT.DATE_FORMAT),
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: true },
    { type: 'attribute', label: 'Attribute', allowConfig: true },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  currency: 'USD',
  dimension: [],
  metric: [],
  attribute: [],
  pinnedColumn: [],
  columnWidth: [],
  columnOrder: [],
  sort: [],
  filter: [],
  search: '',
  endpoint: {},
  mapping: {},
  groupBy: {},
  system: {},
  hiddenFilter: {},
  hiddenColumns: ['ad_type'],
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  disabledComponents: {},
  defaultPagination: DEFAULT_PAGINATION,
};

/**
 * ff.expose_filter_etable:start
 */
export const SPECIAL_FILTERS = 'specialFilters';
/**
 * ff.expose_filter_etable:end
 */

export const EMPTY_FUNCTION = () => undefined;

export const useTableBackbone = (
  bbConfig = {
    apiRequest: {},
    configuration: {} as Record<string, any>,
    callback: {},
    addons: {},
  },
  changeConfiguration = (config: Record<string, any>) => undefined,
) => {
  const [config, setConfig] = React.useState(() => ({ ...DEFAULT_CONFIG, ...bbConfig.configuration }));
  const [options, setOptions] = React.useState(DEFAULT_OPTIONS);
  const [status, setStatus] = React.useState({
    table: '',
    ...(ff.separate_table_data_loading ? { pagination: '' } : {}),
    dimension: '',
    metric: '',
    attribute: '',
  });
  const [visibility, setVisibility] = React.useState({
    tableSetting: false,
    filter: false,
    sort: false,
  });
  const [headers, setHeaders] = React.useState([]);
  const [pagination, setPagination] = React.useState(() =>
    cloneDeep(bbConfig.configuration.defaultPagination || DEFAULT_PAGINATION),
  );
  const [initialDataRequest, setInitialDataRequest] = React.useState({});
  const initialDataRequestRef = React.useRef({});

  const getInitialDataRequest = (key) => {
    if (key) return initialDataRequestRef.current[key];
    return initialDataRequestRef.current;
  };

  const addInitialDataRequest = (key, value) => {
    initialDataRequestRef.current[key] = value;
    setInitialDataRequest({
      ...initialDataRequestRef.current,
    });
  };

  const ref = React.useRef({
    mainButton: {},
    headerAction: {},
    cellFormat: {},
    cellEditor: {},
    notification: null,
    tempStorage: {},
    backboneInstance: null,
  });

  const apiRequest = React.useMemo(() => {
    const defaultRequest = getAPIRequest(config.endpoint);
    return { ...defaultRequest, ...bbConfig.apiRequest };
  }, []);

  const uawl = React.useContext(UaWatchlist);

  const fastRef = React.useRef<Record<string, any>>({ config });

  const tableRef = React.useRef<{
    getColumnFields;
    apiRequest;
    config;
    callback: {
      clickMainButton?: (...args: any[]) => void;
      onRowUpdate?: (...args: any) => void;
    };
    gridApi;
    columnApi;
    pagination;
    updateTotalResult;
    updatePaginationTraceId;
    getAvailableGroupByColumns;
    getAvailableFilterColumns;
    getAvailableSortColumns;
    datasource;
    selectedRows: any[];
    addons: Record<string, any>;
    addon: (string: any, defaultFun: (...args: any[]) => any) => typeof defaultFun;
    queryToken: string;
  }>({
    queryToken: nanoid(),
    getColumnFields: null,
    apiRequest: null,
    config: { ...DEFAULT_CONFIG, ...bbConfig.configuration },
    callback: { ...bbConfig.callback, ...callbackGenerate(config) },
    gridApi: null,
    columnApi: null,
    pagination: cloneDeep(bbConfig.configuration.defaultPagination || DEFAULT_PAGINATION),
    updateTotalResult: null,
    updatePaginationTraceId: null,
    getAvailableGroupByColumns: null,
    getAvailableFilterColumns: null,
    getAvailableSortColumns: null,
    datasource: null,
    selectedRows: [],
    addons: bbConfig.addons,
    addon: null,
  });

  const {
    updateCellValue,
    getFilterValueData,
    getDimensionOptions,
    getSuggestBiddingPrice,
    getLandingPageOptions,
    getGenders,
    getAges,
    getAudienceLocations,
    getInterestCategories,
    deleteDimension,
  } = apiRequest;

  const init = () => {
    return new Promise((resolve) => {
      initPropertyOptions();
      resetColumns();
      if (ff.function_crud_etable_view) {
        initViews();
      }
      resolve(true);
    });
  };

  const initPropertyOptions = (autoSelectAll = false) => {
    const columnOrder = get(bbConfig.configuration, 'columnOrder', []);
    const mapping = get(bbConfig.configuration, 'mapping', {});
    const hiddenColumns: any[] = get(config, 'hiddenColumns', []);
    const opt = {};
    const selectedViewId = get(config, ['view', 'id']);
    const view = config.views.find((i) => i.id === selectedViewId) || config.views[0];
    const excludeColumns = flatten(Object.values(get(view, 'excludeProperty', {}))).concat(hiddenColumns);

    for (const [colId, colVal] of Object.entries(mapping)) {
      const colValue: any = colVal;
      if (colValue.propertyType && !excludeColumns.includes(colId as any)) {
        opt[colValue.propertyType] = (opt[colValue.propertyType] || []).concat({
          id: colId,
          name: colValue.title,
          filterField: colValue.filterField,
          sortField: colValue.sortField || colValue.filterField,
          dataType: colValue.dataType || 'string',
          disable: false,
          pinned: colValue.pinned,
        });
      }
    }

    for (const [key, val] of Object.entries(opt)) {
      opt[key] = sortItemsByField(val, columnOrder, 'id');
    }

    if (ff.function_crud_etable_view) {
      const listViews = config.views;
      const index = listViews.findIndex((item) => item.id === 'default' || item.id === 'all');

      const selectedColumns = config.settingType.reduce((acc, val) => {
        const restSingleColumns = get(val, 'singleSelectColumns', []).slice(1);
        const cols = get(opt, val.type, [])
          .map((c) => c.id)
          .filter((c) => !restSingleColumns.includes(c));
        return {
          ...acc,
          [val.type]: cols,
        };
      }, {});

      const defaultCombinator = {
        dimension: selectedColumns['dimension'],
        attribute: selectedColumns['attribute'],
        metric: selectedColumns['metric'],
      };

      if (index !== -1 && get(listViews[index], ['initializedView'], false)) {
        // T & T
        updateConfig({
          view: view,
          filter: get(view, ['combinator', 'filter'], config.filter),
          sort: get(view, ['combinator', 'sort'], config.sort),
          dimension: get(view, ['combinator', 'properties', 'dimension']),
          attribute: get(view, ['combinator', 'properties', 'attribute'], []),
          metric: get(view, ['combinator', 'properties', 'metric'], []),
          groupBy: get(view, ['combinator', 'groupBy'], config.groupBy),
          pinnedColumn: get(view, ['combinator', 'pinnedColumn'], config.pinnedColumn),
        });
      } else if (index === -1 && !get(listViews[index], ['initializedView'], false)) {
        // F & F
        const selectedColumns = config.settingType.reduce((acc, val) => {
          const restSingleColumns = get(val, 'singleSelectColumns', []).slice(1);
          const cols = get(opt, val.type, [])
            .map((c) => c.id)
            .filter((c) => !restSingleColumns.includes(c));
          return {
            ...acc,
            [val.type]: cols,
          };
        }, {});

        const combinator = {
          dimension: opt['dimension']
            ? get(view, ['combinator', 'properties', 'dimension'], selectedColumns['dimension'] || [])
            : [],
          attribute: opt['attribute']
            ? get(view, ['combinator', 'properties', 'attribute'], selectedColumns['attribute'] || [])
            : [],
          metric: opt['metric']
            ? get(view, ['combinator', 'properties', 'metric'], selectedColumns['metric'] || [])
            : [],
        };

        updateConfig({
          view,
          dimension: combinator.dimension,
          attribute: combinator.attribute,
          metric: combinator.metric,
        });
      } else {
        // F & T || T & F
        if (config.dimension.length === 0) {
          tableRef.current.config = {
            ...tableRef.current.config,
            dimension: defaultCombinator.dimension,
            attribute: defaultCombinator.attribute,
            metric: defaultCombinator.metric,
          };
          updateConfig({
            view,
            dimension: defaultCombinator.dimension,
            attribute: defaultCombinator.attribute,
            metric: defaultCombinator.metric,
          });
        } else {
          updateConfig({ view });
        }
      }
    } else {
      updateConfig({ view });
    }

    addon('config.view', (view) => undefined)(view);

    setOptions((options) => ({
      ...options,
      ...opt,
    }));
  };

  const initViews = () => {
    const listViews = config.views;
    if (listViews.length === 0) {
      const views = [
        {
          id: 'default',
          name: 'Default',
          combinator: {
            filter: get(config, ['filter'], []),
            sort: get(config, ['sort'], []),
            properties: {
              dimension: get(config, ['dimension'], []),
              attribute: get(config, ['attribute'], []),
              metric: get(config, ['metric'], []),
            },
            groupBy: get(config, ['groupBy'], {}),
            pinnedColumn: get(config, ['pinnedColumn'], []),
          },
          systemView: true,
          initializedView: true,
        },
      ];
      updateConfig({ views: views, view: views[0] });
    } else {
      const index = listViews.findIndex((item) => item.id === 'default' || item.id === 'all');

      if (index !== -1 && !get(listViews[index], ['initializedView'], false)) {
        const defaultCombinator = {
          dimension:
            get(config, ['dimension'], []).length === 0
              ? get(tableRef, 'current.config.dimension', [])
              : get(config, ['dimension']),
          attribute:
            get(config, ['attribute'], []).length === 0
              ? get(tableRef, 'current.config.attribute', [])
              : get(config, ['attribute']),
          metric:
            get(config, ['metric'], []).length === 0
              ? get(tableRef, 'current.config.metric', [])
              : get(config, ['metric']),
        };
        const newListViews = [
          ...listViews.slice(0, index),
          {
            id: 'default',
            name: 'Default',
            combinator: {
              filter: get(config, ['filter'], []),
              sort: get(config, ['sort'], []),
              properties: {
                dimension: defaultCombinator.dimension,
                attribute: defaultCombinator.attribute,
                metric: defaultCombinator.metric,
              },
              groupBy: get(config, ['groupBy'], {}),
              pinnedColumn: get(config, ['pinnedColumn'], []),
            },
            systemView: true,
            initializedView: true,
          },
          ...listViews.slice(index + 1),
        ];

        updateConfig({ views: newListViews, view: newListViews[index] });
      }
    }
  };

  const getSortFilterFieldsByType = (type: string) => {
    const filterFields = getFilterFields(config.filter);
    const sortFields = config.sort.map((i) => i.field);
    const addFields = [...filterFields, ...sortFields];
    return Object.entries(config.mapping).reduce((acc, [k, v]) => {
      if (addFields.includes(k) && v['propertyType'] === type) {
        return [...acc, k];
      }
      return acc;
    }, []);
  };

  const getColumnFields = (type) => {
    const hiddenFields = get(config, ['requestHiddenField', type], []);
    const ignoreFields = get(config, ['requestIgnoreField', type], []);
    let fields = [...hiddenFields];
    let propertyIds = [];

    switch (type) {
      case 'dimension': {
        propertyIds = get(config, 'dimension', []);
        break;
      }
      case 'attribute': {
        fields = [...fields, ...get(config, 'primaryKeys', [])];
        propertyIds = [
          ...get(config, 'dimension', []),
          ...get(config, 'attribute', []),
          ...getSortFilterFieldsByType('attribute'),
        ];
        break;
      }
      case 'metric': {
        propertyIds = [...get(config, 'metric', []), ...getSortFilterFieldsByType('metric')];
        break;
      }
    }

    propertyIds
      // FIXME: more flexible way to ignore columns eg. column_name.valueGetter.will_ignore_field
      .forEach((id) => {
        let getterFields = Object.values(get(config, ['mapping', id, 'valueGetter'], {}));
        getterFields = getterFields.filter((f) => String(f).indexOf('=') === -1);
        if (type === 'dimension') {
          getterFields = getterFields.map((i: string) => head(i.split('.')));
        }
        fields = fields.concat(getterFields);
      });
    return uniq(fields.filter((i) => !ignoreFields.includes(i)));
  };

  const loadTableData = (route = []) => {
    if (tableRef.current.gridApi) {
      tableRef.current.gridApi.refreshServerSideStore({ route });
    }
  };

  let loadPagination;
  if (ff.separate_table_data_loading) {
    loadPagination = async (traceId) => {
      const params = {
        previousTraceId: traceId,
        pagination: {
          page: tableRef.current.pagination.page,
          limit: tableRef.current.pagination.limit,
        },
      };

      return new Promise(async (resolve, reject) => {
        try {
          const res = await apiRequest.getPaginationInfo(params);
          if (res.success) {
            const total = get(res, 'pagination.total', 0);
            tableRef.current.updateTotalResult(total);
          }

          resolve({
            success: true,
            data: res,
          });
        } catch (e) {
          // Handle request when losing connection
          if (ff.loading_config_search) {
            resolve({
              success: false,
              data: {},
            });
          }
        }
      });
    };
  }

  const editorDataLoading = {
    StatusEditor: getDimensionOptions,
    BiddingPriceEditor: getSuggestBiddingPrice,
    LandingPageEditor: getLandingPageOptions,
    GenderEditor: getGenders,
    AgeEditor: getAges,
    LocationEditor: getAudienceLocations,
    InterestCategoryEditor: getInterestCategories,
  };

  const [reloadHandler, setReloadHandler] = React.useState({
    table: loadTableData,
    ...(ff.separate_table_data_loading ? { pagination: loadPagination } : {}),
  });

  const handleChangePagination = (value: { page: number; limit: number }) => {
    const newPagination = {
      ...pagination,
      ...value,
    };
    log('handlechangepagination', newPagination);
    if (pagination.limit !== newPagination.limit) {
      const newPage = Math.floor((pagination.page * pagination.limit) / newPagination.limit);
      newPagination.page = newPage;
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.limit));
    } else if (pagination.page != newPagination.page) {
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.page * newPagination.limit));
    }
    setPagination(newPagination);
  };

  const handleChangeVisibility = (name: string, isVisible: boolean) => {
    log('Change visibility', { name, isVisible });
    setVisibility({
      ...visibility,
      [name]: isVisible,
    });
  };

  const handleChangeStatus = (name: string, value: StatusType) => {
    if (ff.separate_table_data_loading) {
      setStatus((status) => ({
        ...status,
        [name]: value,
      }));
    } else {
      setStatus({
        ...status,
        [name]: value,
      });
    }
  };

  const updateConfig = (value: any) => {
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries(value)) {
          draft[key] = val;
        }
      });
      const isSyncPersonalization = Object.keys(value).some(
        (k) => EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS.indexOf(k) > -1,
      );

      if (isSyncPersonalization) {
        changeConfiguration(newConfig);
      }

      // FIXME: tableref.current.config is potentially out of date, some config will trigger query data. some would not
      // tableRef.current.config = newConfig;
      fastRef.current.config = newConfig;
      // console.log('UpdateConfig 1', newConfig);
      return newConfig;
    });
  };

  const updateSystemConfig = (value: any) => {
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries(value)) {
          set(draft, ['system', key], val);
        }
      });
      changeConfiguration(newConfig);
      tableRef.current.config = newConfig;
      // console.log('UpdateConfig 2', newConfig);
      return newConfig;
    });
  };

  const handleChangeOptionOrder = (name: string, ids: Array<number | string>) => {
    const newArr = sortItemsByField(get(options, name, []), ids, 'id');
    const newOptions = {
      ...options,
      [name]: newArr,
    };
    setOptions(newOptions);

    const colOrders = config.settingType.reduce((acc, val) => {
      const cols = get(newOptions, val.type, []).map((c) => c.id);
      return acc.concat(cols);
    }, []);
    updateConfig({ columnOrder: colOrders });

    if (tableRef.current.columnApi.moveColumns) {
      tableRef.current.columnApi.moveColumns(colOrders, 0);
    }
  };

  const handleChangeColumnOrder = (columnOrders: string[]) => {
    const newOptions = produce(options, (draft) => {
      config.settingType.forEach((i) => {
        draft[i.type] = sortItemsByField(get(options, [i.type], []), columnOrders, 'id');
      });
    });
    setOptions(newOptions);
    updateConfig({ columnOrder: columnOrders });
  };

  React.useEffect(() => {
    const newViewId = get(config, ['view', 'id']);
    const oldViewId = get(tableRef.current.config, ['view', 'id']);
    if (newViewId !== oldViewId) {
      initPropertyOptions();
      resetColumns();
    }
  }, [config.view]);

  React.useEffect(() => {
    resetColumns();
    if (tableRef.current.config.tableType === '' && tableRef.current.gridApi) {
      // tableRef.current.gridApi.getSelectedNodes().forEach(n => n.setSelected(false));
      tableRef.current.gridApi.deselectAll();
      tableRef.current.selectedRows = [];
    }
  }, [
    config.dimension,
    config.metric,
    config.attribute,
    config.columnOrder,
    options.dimension,
    options.metric,
    options.attribute,
    config.groupBy,
    config.groupPeriod,
    config.tractionPeriod,
    ...(ff.expose_filter_etable ? [config[SPECIAL_FILTERS]] : []),
  ]);

  React.useEffect(() => {
    if (tableRef.current.config.tableType === '' && tableRef.current.gridApi) {
      // tableRef.current.gridApi.getSelectedNodes().forEach(n => n.setSelected(false));
      tableRef.current.gridApi.deselectAll();
      tableRef.current.selectedRows = [];
    }
  }, [config.sort, config.filter, config.dateRange.dateFrom, config.dateRange.dateTo, config.groupBy, config.view]);

  React.useEffect(() => {
    if (tableRef.current.gridApi && !isEqual(tableRef.current.config, config)) {
      if (process.env.NODE_ENV === 'development') {
        log('diff', compare(tableRef.current.config, config));
      }
      log('table reload');
      tableRef.current.config = cloneDeep(config);
      tableRef.current.queryToken = nanoid();
      tableRef.current.gridApi.paginationSetPageSize(tableRef.current.pagination.limit);
      tableRef.current.gridApi.refreshServerSideStore({ purge: true });
    }
  }, [
    config.sort,
    config.filter,
    config.currency,
    config.search,
    config.dateRange,
    config.metric,
    config.groupBy,
    ...(ff.function_crud_etable_view ? [config.view.id] : []),
    ...(ff.integrate_api_sos ? [config.keyword] : []),
    ...(ff.integrate_api_keyword_analysis ? [config.hiddenFilter] : []),
    ...(ff.calendar_timeline_cohort ? [config.cohortDateRange] : []),
    ...(ff.expose_filter_etable ? [config[SPECIAL_FILTERS]] : []),
    ...(ff.time_periodically ? [config.groupPeriod] : []),
    config.tractionPeriod,
    config.selectedMetricTraction,
    config.metricTractionProperties,
  ]);

  const getFilterFields = (filter: TableBackbone.filter[]) => {
    if (filter.length === 0) return [];
    return filter.reduce((acc, i) => {
      if (i.subFilters) {
        return [...acc, ...getFilterFields(i.subFilters)];
      }
      return [...acc, i.field];
    }, []);
  };

  const getAvailableColumns = () => {
    const properties = ['dimension', 'attribute', 'metric'];
    return properties.reduce((acc, property) => {
      const cols = get(options, property, []).map((c) => ({ ...c, propertyType: property }));
      return [...acc, ...cols];
    }, []);
  };

  const getAvailableGroupByColumns = () => {
    return get(config, 'dimension', []);
  };

  const getAvailableFilterColumns = () => {
    return getAvailableColumns()
      .reduce((acc, c) => {
        if (c.filterField) {
          return [
            ...acc,
            {
              name: c.name,
              id: c.id,
              field: c.filterField,
              dataType: c.dataType || 'string',
              propertyType: c.propertyType,
            },
          ];
        }
        return acc;
      }, [])
      .concat(get(config, ['system', 'additionalFilters'], []));
  };

  const getAvailableSortColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.sortField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.sortField,
            dataType: c.dataType || 'string',
            propertyType: c.propertyType,
          },
        ];
      }
      return acc;
    }, []);
  };

  const getTimePeriodicallyOptions = () => {
    return viewByTimelineOptions.map((el) => ({
      ...el,
      checked: false,
    }));
  };

  const sortItemsByField = (items, orderedValues, field) => {
    const newItems = [...items];
    newItems.sort((a, b) => {
      const indexA = orderedValues.indexOf(a[field]);
      const indexB = orderedValues.indexOf(b[field]);
      if (indexA < indexB) {
        return -1;
      }
      if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
    return newItems;
  };

  const resetColumns = () => {
    const groupByColumns = get(config, 'groupBy.columns', []);
    const columns = config.settingType.reduce((acc, val) => {
      if (options[val.type]) {
        const selectedCols = get(config, [val.type], []);
        const cols = options[val.type]
          .filter((i) => selectedCols.includes(i.id))
          .map((c) => ({
            name: c.name,
            field: c.id,
            dataType: c.dataType,
            sortable: false,
            filterField: get(config, ['mapping', c.id, 'filterField'], ''),
            filterGetter: get(config, ['mapping', c.id, 'filterGetter']),
            cell: {
              format: getCellFormat(c.id),
              valueGetter: get(config, ['mapping', c.id, 'valueGetter']),
              staticValue: get(config, ['mapping', c.id, 'staticValue']),
              action: getCellActions(c.id),
              updateHandler: getCellUpdateHandler(c.id),
              ...(ff.ui_ux_update_tag_item
                ? {
                    actionSubItem: getCellActionsSubItem(c.id),
                  }
                : {}),
            },
            action: groupByColumns.includes(c.id) ? getHeaderActions('groupBy') : getHeaderActions(c.id, val.type),
            aggFunc: get(config, ['groupBy', 'aggregations', c.id, 'func']),
            pinned: c.pinned || null,
          }));
        return acc.concat(cols);
      }
      return acc;
    }, []);
    log('reset columns', { groupByColumns, config, columns });
    addon('system.reset.columns', () => setHeaders(columns))(columns, setHeaders);
  };

  const getHeaderActions = (columnField: string, columnType?: string) => {
    if (ref.current.headerAction[columnField]) {
      return ref.current.headerAction[columnField];
    } else {
      if (ref.current.headerAction[columnType]) {
        return ref.current.headerAction[columnType];
      } else {
        return ref.current.headerAction['default'];
      }
    }
  };

  const handleUpdateCell = async (params: any, idField: string, _route = [], columnField?: string) => {
    let columnInfo;
    columnInfo = tableRef.current.columnApi.getColumn(columnField);
    try {
      const session = uawl.currentSession();
      if (session) {
        session.addContext({ uaQueryParams: JSON.stringify(params), uaContext: JSON.stringify({ idField }) });
        session.ack('be:request');
        params._uaSessionId = session.id();
      }
      window.dispatchEvent(new CustomEvent('updateCellValue', { detail: { params, idField, columnField } }));

      const res: any = await updateCellValue(params);

      const getUpdatedValues = (dimensionId) => {
        const newRowData = {};
        const updateFields = Object.keys(params.data[0]).filter((i) => i !== 'id');
        const useResponseValue = updateFields.includes('status');
        let data;
        if (useResponseValue) {
          const exist = res.data.find((i) => i.data.id == dimensionId);
          if (exist) {
            data = exist.data;
          }
        } else {
          data = params.data.find((i) => i.id == dimensionId);
        }
        if (data) {
          const dimensionName = idField.substring(0, idField.lastIndexOf('.'));
          updateFields.forEach((field) => {
            if (data[field] !== undefined) {
              newRowData[`${dimensionName}.${field}`] = data[field];
            }
          });
        }
        return newRowData;
      };

      const successIds = [];
      const errorItems = [];
      res.data.forEach((item, index) => {
        if (item.success) {
          successIds.push(item.data.id);
        } else {
          errorItems.push({
            id: params.data[index].id,
            messages: Object.values(item.error),
          });
        }
      });

      if (params.dimension === 'SCRIPT') {
        if (res.code === 200) {
          notice(`Update script successfully!`, [], true);
          tableRef.current.gridApi.refreshServerSideStore({ route: _route });
        } else {
          notice(`Update script failed with the following errors`, res.message, false);
        }

        return res;
      }

      if (successIds.length > 0) {
        if (
          (['ADS_CAMPAIGN', 'ADS_OBJECT'].includes(params.dimension) &&
            Object.keys(params.data[0]).includes('status')) ||
          Object.keys(params.data[0]).includes('general_tag') ||
          (['ADS_CALENDAR'].includes(params.dimension) && Object.keys(params.data[0]).includes('timeline_to'))
        ) {
          if (ff.cached_selected_rows) {
            tableRef.current.gridApi.deselectAll();
            tableRef.current.selectedRows = [];
          }
          tableRef.current.gridApi.refreshServerSideStore({ route: _route });
        } else {
          tableRef.current.gridApi.forEachNode((rowNode) => {
            if (rowNode.data) {
              const dimensionId = (rowNode.data[idField] || '').toString();
              if (successIds.includes(dimensionId)) {
                rowNode.setData({ ...rowNode.data, ...getUpdatedValues(dimensionId) });
              }
            }
          });
        }
        if (Object.keys(params.data[0]).includes('general_tag') && columnInfo) {
          notice(`Update ${columnInfo.name} successfully!`, [], true);
        } else {
          notice(`Update ids ${successIds.join(', ')} successfully!`, [], true);
        }
      }

      if (errorItems.length > 0) {
        errorItems.forEach((i) => {
          notice(`Update id "${i.id}" failed with the following errors`, i.messages, false);
        });
      }

      return res;
    } catch (err) {
      console.log('[UpdateCell] System error', err);
      return {
        success: false,
        message: err.message,
      };
    }
  };

  const updateSingleValue = async (
    { value, row }: { value: any; row: any },
    updateFieldMapping: any,
    columnField?: string,
  ) => {
    const dataForUpdate = {};
    let dimension = '';
    for (const [key, field] of Object.entries<string>(updateFieldMapping)) {
      const newValue = value[key];
      const parts = field.split('.');
      const attributeField: string = last(parts);
      dataForUpdate[attributeField] = newValue;
      dimension = head(parts);
    }
    const _route = get(row, '_route', []);

    if (dimension) {
      const primaryKeys = get(config, 'primaryKeys', []);
      let idField = '';
      if (dimension === 'ADS_CALENDAR') {
        const field = updateFieldMapping['date_from'].substring(0, updateFieldMapping['date_from'].lastIndexOf('.'));
        idField = primaryKeys.find((k) => k.includes(field));
      } else {
        idField = primaryKeys.find((k) => k.includes(dimension));
      }

      const selectedRows = tableRef.current.gridApi.getSelectedRows();
      const ids = uniq(
        ff.failed_message_is_displayed_add_tag
          ? selectedRows
              .concat(row)
              .map((r) => r[idField])
              .filter((el) => el != null)
          : selectedRows.concat(row).map((r) => r[idField]),
      );
      if (ff.failed_message_is_displayed_add_tag ? idField && ids.length > 0 : idField) {
        if (dimension === 'ADS_CALENDAR') {
          const prevTimelineFrom = moment(row[updateFieldMapping['date_from']], EIP_CONSTANT.DATE_FORMAT).format(
            EIP_CONSTANT.DATE_FORMAT,
          );
          if (prevTimelineFrom === dataForUpdate['timeline_from']) dataForUpdate['timeline_from'] = undefined;
          if (dataForUpdate['timeline_to']) {
            dataForUpdate['timeline_to'] = moment(dataForUpdate['timeline_to'], EIP_CONSTANT.DATE_FORMAT)
              .set({ hour: 23, minute: 59, second: 59 })
              .format(EIP_CONSTANT.DATETIME_FORMAT);
          }
        }
        const param = {
          data: ids.map((id) => ({ id, ...dataForUpdate })),
          dimension,
          marketplaceCode: row['MARKETPLACE.marketplace_code'],
        };
        log('onsubmit single value', { value, dataForUpdate, row, idField });
        let response;
        if (ff.notification_tag) {
          response = await handleUpdateCell(param, idField, _route, columnField);
        } else {
          response = await handleUpdateCell(param, idField, _route);
        }
        return response;
      }
    }
  };

  const updateMultiValue = async ({ value, row }: { value: any; row: any }) => {
    const parts = get(value, ['value', 'field'], '').split('.');
    const dimension = head(parts);
    const updateField = last(parts);
    const primaryKeys = get(config, 'primaryKeys', []);
    const idField = primaryKeys.find((k) => k.includes(dimension));
    const data = get(value, ['value', 'data'], []).map((i) => ({
      id: i.id,
      [updateField as string]: i.value,
    }));
    const param = {
      data,
      dimension,
      marketplaceCode: row['MARKETPLACE.marketplace_code'],
    };
    log('onsubmit multi value', { value, param, row, idField });
    const response = await handleUpdateCell(param, idField);
    return response;
  };

  const handleSubmit = async (data: { value: any; row: any }, updateFieldMapping: any, columnField?: string) => {
    // Detect updated field and new value
    const isUpdateMultiValue = get(data, ['value', 'value', 'isMultiValue'], false);
    if (isUpdateMultiValue) {
      const response = await updateMultiValue(data);
      return response;
    } else {
      let response;
      if (ff.notification_tag) {
        response = await updateSingleValue(data, updateFieldMapping, columnField);
      } else {
        response = await updateSingleValue(data, updateFieldMapping);
      }
      return response;
    }
  };

  const handleLocalUpdate = ({ value, row }: { value: any; row: any }, valueGetter: any) => {
    const primaryKeys = get(config, 'primaryKeys', []);
    const selectedRows = tableRef.current.gridApi.getSelectedRows().concat(row);

    const dataForUpdate = {};
    for (const [key, field] of Object.entries<string>(valueGetter)) {
      const oldValue = row[field];
      const newValue = value[key];
      if (oldValue !== newValue) {
        dataForUpdate[field] = newValue;
      }
    }

    tableRef.current.gridApi.forEachNode((rowNode) => {
      if (rowNode.data) {
        const exist = selectedRows.find((r) => {
          return primaryKeys.every((key) => r[key] === rowNode.data[key]);
        });
        if (exist) {
          rowNode.setData({ ...rowNode.data, ...dataForUpdate });
        }
      }
    });

    if (tableRef.current.callback.onRowUpdate) {
      const rowData = [];
      tableRef.current.gridApi.forEachNode((rowNode) => rowData.push(rowNode.data));
      tableRef.current.callback.onRowUpdate(rowData);
    }
  };

  const notice = (title = '', messages = [], isSuccess: boolean) => {
    if (ref.current.notification) {
      if (isSuccess) {
        ref.current.notification.success(title, messages);
      } else {
        ref.current.notification.error(title, messages);
      }
    }
  };

  const handleDeleteDimension = async ({ row }: { value: any; row: any }, dimension: string) => {
    const primaryKeys = get(config, 'primaryKeys', []);
    const idField = primaryKeys.find((k) => k.includes(dimension));
    if (idField) {
      const selectedRows = tableRef.current.gridApi.getSelectedRows();
      const ids: string[] = uniq(selectedRows.concat(row).map((r) => r[idField]));
      const params = {
        id: ids,
        dimension,
        marketplaceCode: row['MARKETPLACE.marketplace_code'],
      };

      try {
        const res: any = await deleteDimension(params);
        const successIds = [];
        const errorItems = [];
        res.data.forEach((item, index) => {
          if (item.success) {
            successIds.push(ids[index]);
          } else {
            errorItems.push({
              id: ids[index],
              messages: [item.message],
            });
          }
        });

        if (successIds.length > 0) {
          tableRef.current.gridApi.refreshServerSideStore();
          notice(`Update ids ${successIds.join(', ')} successfully!`, [], true);
        }

        if (errorItems.length > 0) {
          errorItems.forEach((i) => {
            notice(`Update id "${i.id}" failed with the following errors`, i.messages, false);
          });
        }
      } catch (err) {
        console.log('[UpdateCell] System error', err);
      }
    }
  };

  const getCellActions = (columnField: string) => {
    const cellMenu = get(config, ['mapping', columnField, 'menu'], []);
    return cellMenu.map((m) => {
      let submitHandler = null;
      if (m.disabledSubmit) {
        submitHandler = (data) => {
          alert('submit disabled. Reason: ' + m.disabledSubmitReason);
        };
      } else {
        if (m['onClickItem']) {
          submitHandler = m['onClickItem'];
        } else {
          const actionType = get(m, 'actionType', 'edit');
          if (actionType === 'delete') {
            const dimension = get(m, ['payload', 'static', 'dimension'], '');
            submitHandler = (data) => handleDeleteDimension(data, dimension);
          } else {
            const updateFieldMapping = get(m, ['payload', 'field'], {});
            if (m.localUpdate) {
              submitHandler = (data) => handleLocalUpdate(data, updateFieldMapping);
            } else {
              if (ff.add_note_column_including_inline_edit) {
                if (actionType === 'removeEmpty') {
                  const valueEmpty = { value: { value: '' } };
                  submitHandler = (data) => handleSubmit({ ...data, ...valueEmpty }, updateFieldMapping);
                } else {
                  submitHandler = (data) => handleSubmit(data, updateFieldMapping);
                }
              } else {
                submitHandler = (data) => handleSubmit(data, updateFieldMapping);
              }
            }
          }
        }
      }
      return {
        ...m,
        component: get(ref.current.cellEditor, m.editor, null),
        onSubmit: submitHandler,
        ...(ff.ui_ux_update_tag_item
          ? {
              actionSubItem: getCellActionsSubItem(columnField),
            }
          : {}),
        onLoad: editorDataLoading[m.editor],
      };
    });
  };
  let getCellActionsSubItem = null;
  if (ff.ui_ux_update_tag_item) {
    getCellActionsSubItem = (columnField: string) => {
      const cellMenu = get(config, ['mapping', columnField, 'menuItem'], []);
      return cellMenu.map((m) => {
        return {
          ...m,
          component: get(ref.current.cellEditor, m.editor, null),
          onLoad: editorDataLoading[m.editor],
        };
      });
    };
  }

  const getCellUpdateHandler = (columnId: string) => {
    const editField = get(config, ['mapping', columnId, 'valueGetter']);
    if (editField) {
      if (ff.notification_tag) {
        return (data) => {
          return handleSubmit(data, editField, columnId);
        };
      } else {
        return (data) => {
          return handleSubmit(data, editField);
        };
      }
    }
    return null;
  };

  const getCellFormat = (columnField: string) => {
    const formatType = get(config, ['mapping', columnField, 'cellFormat'], 'text');
    return ref.current.cellFormat[formatType];
  };

  const handleGetSelectedRows = (nextRows?: { data: any; isSelected: boolean }[]) => {
    const selectedRows = tableRef.current.selectedRows;

    if (!nextRows) return selectedRows;

    const kvSelectedIndex = selectedRows.reduce((carry, i, index) => {
      return { ...carry, [getRowId(i)]: index };
    }, {});

    const nextSelectedRows = produce(selectedRows, (draft) => {
      nextRows.forEach(({ data, isSelected }) => {
        const rowId = getRowId(data);
        if (isSelected && kvSelectedIndex[rowId] === undefined) {
          draft.push(data);
        } else if (!isSelected && kvSelectedIndex[rowId] !== undefined) {
          draft[kvSelectedIndex[rowId]] = null;
        }
      });
    }).filter((i) => i !== null);

    tableRef.current.selectedRows = nextSelectedRows;
    return addon('getSelectedRows', (nextSelectedRows) => nextSelectedRows)(nextSelectedRows);
  };

  const handleUpdateSelectedRows = (rows: any[]) => {
    const primaryKeys = get(config, `primaryKeys`, []);
    tableRef.current.selectedRows = cloneDeep(rows);
    if (tableRef.current.gridApi && primaryKeys.length > 0) {
      restoreSelectedRows({ api: tableRef.current.gridApi });
    }
  };

  const getDataRows = () => {
    if (!tableRef.current.datasource) {
      tableRef.current.datasource = createDatasource(tableRef);
    }

    setStatus((status) => ({
      ...status,
      table: 'loading',
    }));

    return new Promise((resolve, reject) => {
      tableRef.current.datasource.getRows({
        request: { startRow: 0 },
        success: ({ rowData }) => {
          log('resolve getrows', rowData);
          setStatus((status) => ({
            ...status,
            table: 'success',
          }));
          resolve(rowData);
        },
        fail: (error) => {
          setStatus((status) => ({
            ...status,
            table: 'error',
          }));
          reject(error);
        },
      });
    });
  };

  const registerMainButtonComponent = (btnComponent: any) => {
    ref.current.mainButton = btnComponent;
  };

  const registerHeaderAction = (action: any) => {
    ref.current.headerAction = action;
  };

  const registerCellFormat = (format: any) => {
    ref.current.cellFormat = format;
  };

  const registerCellEditor = (editor: any) => {
    ref.current.cellEditor = editor;
  };

  const registerNotification = (notification: {
    success: (title: string, messages: string[]) => void;
    error: (title: string, messages: string[]) => void;
  }) => {
    ref.current.notification = notification;
  };

  const addon = React.useCallback((path, defaults = null) => {
    const fun = get(tableRef.current.addons, [path], defaults);
    if (!fun) return null;
    return (...args) => fun(...args, ref.current.backboneInstance);
  }, []);

  const registerAddon = React.useCallback((path, handler) => {
    tableRef.current.addons = set(tableRef.current.addons || {}, [path], handler);
  }, []);

  const trackingUpdateView = (name: string, value: any) => {
    if (
      ['filter', 'sort', 'dimension', 'metric', 'attribute', 'groupBy', 'pinnedColumn', SPECIAL_FILTERS].includes(name)
    ) {
      const listViews = tableRef.current.config.views;
      const selectedViews = tableRef.current.config.view;
      const index = listViews.findIndex((item) => item.id === selectedViews.id);
      if (index !== -1) {
        const newSelected = {
          ...selectedViews,
          combinator: {
            filter: name === 'filter' ? value : tableRef.current.config.filter,
            sort: name === 'sort' ? value : tableRef.current.config.sort,
            properties: ['dimension', 'metric', 'attribute'].includes(name)
              ? {
                  dimension: tableRef.current.config.dimension,
                  attribute: tableRef.current.config.attribute,
                  metric: tableRef.current.config.metric,
                  [name]: value,
                }
              : {
                  ...get(selectedViews, 'combinator.properties', {
                    dimension: tableRef.current.config.dimension,
                    attribute: tableRef.current.config.attribute,
                    metric: tableRef.current.config.metric,
                    [name]: value,
                  }),
                },
            groupBy: name === 'groupBy' ? value : tableRef.current.config.groupBy,
            pinnedColumn: name === 'pinnedColumn' ? value : tableRef.current.config.pinnedColumn,
            [SPECIAL_FILTERS]: name === SPECIAL_FILTERS ? value : tableRef.current.config[SPECIAL_FILTERS],
          },
        };
        const newListViews = [...listViews.slice(0, index), newSelected, ...listViews.slice(index + 1)];
        updateConfig({ views: newListViews, view: newSelected });
      }
    }
  };

  const changeConfig = React.useCallback((name: string, value: any) => {
    addon(`config.${name}`, () => Promise.resolve({ value }))(
      { value, previousValue: get(tableRef.current.config, name) },
      tableRef.current.config,
    ).then(({ value }) => {
      updateConfig({ [name]: value });
      if (name.indexOf('system.') === 0) {
        const varName = name.replace('system.', '');
        updateSystemConfig({ [varName]: value });
      }
      trackingUpdateView(name, value);
    });
  }, []);

  const createUserView = (name: string, id) => {
    console.info('userviewid', id);
    if ('allowCustomView' in config && config.allowCustomView) {
      const newSelect = {
        id: !id ? uuid() : id,
        name: name.trim(),
        combinator: {
          filter: tableRef.current.config.filter,
          sort: tableRef.current.config.sort,
          properties: {
            dimension: tableRef.current.config.dimension,
            attribute: tableRef.current.config.attribute,
            metric: tableRef.current.config.metric,
          },
          groupBy: tableRef.current.config.groupBy,
          pinnedColumn: tableRef.current.config.pinnedColumn,
        },
        systemView: false,
      };
      const views = [...config.views, newSelect];
      if (!ff.optimize_dropdown_view) {
        updateConfig({ views: views, view: newSelect });

        return true;
      }
      // updateConfig({ views: views });
      return newSelect;
    }
    return false;
  };

  const changeConfigMultiple = React.useCallback((config: Record<string, any>) => {
    const arr = [];
    for (const [name, value] of Object.entries(config)) {
      const temp = new Promise((resolve) => {
        addon(`config.${name}`, () => Promise.resolve({ value }))(
          { value, previousValue: get(tableRef.current.config, name) },
          tableRef.current.config,
        ).then(({ value }) => {
          resolve({ [name]: value });
        });
      });
      arr.push(temp);
    }
    return Promise.all(arr).then((arr) => {
      const fConfig = arr.reduce((carry, obj) => {
        return { ...carry, ...obj };
      }, {});
      updateConfig(fConfig);
    });
  }, []);

  const selectView = async (id: string) => {
    const listViews = config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1) {
      const newViewSelect: any = listViews[index];
      if ('combinator' in newViewSelect && 'allowCustomView' in config && config.allowCustomView) {
        // update config switch custom view
        await changeConfigMultiple({
          filter: newViewSelect.combinator.filter,
          sort: newViewSelect.combinator.sort,
          groupBy: newViewSelect.combinator.groupBy,
          pinnedColumn: newViewSelect.combinator.pinnedColumn,
          //
          attribute: newViewSelect.combinator.properties.attribute,
          metric: newViewSelect.combinator.properties.metric,
          dimension: newViewSelect.combinator.properties.dimension,
        });
        // changeConfig('view', newViewSelect);
      }
      changeConfig('view', newViewSelect);
    }
  };

  const updateUserView = (
    id: string,
    name: string,
    filter: any[] = null,
    sort: any[] = null,
    properties: any = null,
    groupBy: any = null,
    pinnedColumn: any = null,
  ) => {
    const listViews: any = tableRef.current.config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1 && 'allowCustomView' in config && config.allowCustomView) {
      const newListViews = [
        ...listViews.slice(0, index),
        {
          ...listViews[index],
          name: !listViews[index].systemView ? name : listViews[index].name,
          combinator: {
            filter: filter ? filter : tableRef.current.config.filter,
            sort: sort ? sort : tableRef.current.config.sort,
            properties: properties
              ? {
                  ...listViews[index].combinator.properties,
                  ...properties,
                }
              : {
                  dimension: tableRef.current.config.dimension,
                  attribute: tableRef.current.config.attribute,
                  metric: tableRef.current.config.metric,
                },
            groupBy: groupBy ? groupBy : tableRef.current.config.groupBy,
            pinnedColumn: pinnedColumn ? pinnedColumn : tableRef.current.config.pinnedColumn,
          },
        },
        ...listViews.slice(index + 1),
      ];
      updateConfig({ views: newListViews });
      tableRef.current.config.views = newListViews;
      if (tableRef.current.config.view.id === id) {
        updateConfig({ view: newListViews[index] });
        tableRef.current.config.view = newListViews[index];
      }
      return true;
    }
    return false;
  };

  const deleteUserView = (id: string) => {
    const listViews: any = config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1 && !listViews[index].systemView && 'allowCustomView' in config && config.allowCustomView) {
      const newListViews = listViews.filter((v) => v.id !== id);
      updateConfig({ views: newListViews });
      tableRef.current.config.views = newListViews;
      if (tableRef.current.config.view.id === id) {
        const newViewSelect = newListViews[index === newListViews.length ? newListViews.length - 1 : index];
        selectView(newViewSelect.id);
      }
      return true;
    }
    return false;
  };

  tableRef.current.apiRequest = apiRequest;
  tableRef.current.getColumnFields = getColumnFields;
  tableRef.current.updateTotalResult = (total) => {
    setPagination((pagination) => ({ ...pagination, total: total }));
  };

  if (ff.separate_table_data_loading) {
    tableRef.current.updatePaginationTraceId = (traceId) => {
      setPagination((pagination) => {
        console.info('setPagination', traceId);
        return { ...pagination, traceId };
      });
      tableRef.current.pagination.traceId = traceId;
    };
  }

  tableRef.current.getAvailableGroupByColumns = getAvailableGroupByColumns;
  tableRef.current.getAvailableFilterColumns = getAvailableFilterColumns;
  tableRef.current.getAvailableSortColumns = getAvailableSortColumns;
  tableRef.current.addon = addon;
  tableRef.current.datasource = React.useMemo(() => createDatasource(tableRef), []);

  function restoreSelectedRows({ api }) {
    if (tableRef.current.config.tableType !== 'compact') return;
    const primaryKeys = get(config, 'primaryKeys', []);
    api.forEachNode((rowNode) => {
      if (rowNode.data) {
        const exist = tableRef.current.selectedRows.find((r) => {
          return primaryKeys.every((key) => r[key] == rowNode.data[key]);
        });
        rowNode.setSelected(!!exist);
      }
    });
  }

  function getMainButtonComponent(name: string) {
    return get(ref.current.mainButton, name, null);
  }

  function getRowId(row, ignoreKeys = []) {
    if (!row) return null;
    const primaryKeys = get(config, 'primaryKeys', []);

    const addonGetRowId = addon('getRowId', undefined);
    if (typeof addonGetRowId === 'function') {
      return addonGetRowId(row, primaryKeys, ignoreKeys);
    }

    return ['_eipCustomId']
      .concat(primaryKeys)
      .filter((i) => ignoreKeys.indexOf(i) === -1)
      .map((i) => {
        return row[i];
      })
      .join('|');
  }

  let getFilterOptionValue;
  if (ff.custom_filter_value_addon) {
    getFilterOptionValue = (columnName: string, payload?: any, forceReload: boolean) => {
      const filterValueReq = get(tableRef.current.addons, `filterValue.${columnName}`);
      if (filterValueReq) {
        return filterValueReq(payload, forceReload, getInitialDataRequest, tableRef.current.getColumnFields);
      }
      switch (columnName) {
        case 'country':
          return dataRequest.getCountry({ forceReload });
        case 'marketplace':
          return dataRequest.getMarketplace({ forceReload });
        case 'storefront':
          return dataRequest.getStorefront();
        case 'ad_tool':
          return dataRequest.getAdTool();
        case 'ad_type':
          return dataRequest.getAdType({ forceReload });
        case 'campaign_status':
          return dataRequest.getStatus({ dimension: 'ADS_CAMPAIGN', marketplaceCode: 'SHOPEE' });
        case 'ad_object_status':
          return dataRequest.getStatus({ dimension: 'ADS_OBJECT', marketplaceCode: 'SHOPEE' });
        case 'keyword_status':
          return dataRequest.getStatus({ dimension: 'ADS_PLACEMENT', marketplaceCode: 'SHOPEE' });
        case 'script_status':
          return dataRequest.getStatus({ dimension: 'SCRIPT', marketplaceCode: 'SHOPEE' });
        case 'keyword_match_type':
          const matchTypes = [
            { value: 'BROAD_MATCH', label: 'Broad match' },
            { value: 'EXACT_MATCH', label: 'Exact match' },
          ];
          return Promise.resolve({ data: matchTypes, message: null, success: true });
        case 'ad_object_tag':
          const matchTypesAdObjectTag = {
            data: [
              { label: 'High-performing', value: 'high_performing' },
              { label: 'Mid-performing', value: 'mid_performing' },
              { label: 'Low-performing', value: 'low_performing' },
              { label: 'Priority', value: 'priority' },
              { label: 'Non-priority', value: 'non_priority' },
              { label: 'New', value: 'new' },
              { label: 'Other', value: 'other' },
            ],
            message: '',
            success: true,
          };
          return Promise.resolve(matchTypesAdObjectTag);
        case 'campaign_tag':
          const matchTypesAdCampaignTag = {
            data: [
              { label: 'Normal day', value: 'normal_day' },
              { label: 'Brand day', value: 'brand_day' },
              { label: 'Super brand day', value: 'super_brand_day' },
              { label: 'Other', value: 'other' },
            ],
            message: '',
            success: true,
          };
          return Promise.resolve(matchTypesAdCampaignTag);
        case 'keyword_tag':
          const matchTypesKeywordTag = {
            data: [
              { label: 'Generic', value: 'generic' },
              { label: 'Brand', value: 'brand' },
              { label: 'Competitor', value: 'competitor' },
              { label: 'Category', value: 'category' },
              { label: 'Relevant', value: 'relevant' },
              { label: 'Must win', value: 'must_win' },
              { label: 'New', value: 'new' },
              { label: 'Other', value: 'other' },
            ],
            message: '',
            success: true,
          };
          return Promise.resolve(matchTypesKeywordTag);
        default:
          return Promise.resolve([]);
      }
    };
  }

  const handleExportEtable = async () => {
    if (hasAddon('system.export')) {
      return addon('system.export')(tableRef.current.config.tableParams);
    }
    let getFilter, filter;
    if (ff.add_filter_export_api) {
      getFilter = get(tableRef.current, ['config', 'filter']);
      filter = tableRef.current.datasource.getParamFilter(getFilter);
    }
    const titleFail = 'Time range is too long';
    const messagesFail = 'For now, you can only export data for 31 days. Try adjusting your time range.';
    const dateFrom = config.dateRange.dateFrom;
    const dateTo = config.dateRange.dateTo;
    const durationDate = moment.duration(moment(dateTo).diff(moment(dateFrom))).asDays();
    const endpointApi = get(config.endpoint, 'GET_TABLE_DATA', '').match(/\/mop(.*)*/);
    const params = {
      dimensions: getColumnFields('dimension'),
      attributes: getColumnFields('attribute'),
      metrics: getColumnFields('metric'),
      from: dateFrom,
      to: dateTo,
      sort: null,
      ...(ff.add_filter_export_api ? { filter: { ...filter } } : { filter: null }),
      hiddenFilter: get(config, 'hiddenFilter', {}),
      api: endpointApi[0],
    };

    if (durationDate + 1 > 31) {
      return { title: titleFail, messages: messagesFail, variant: 'warning' };
    }

    return new Promise(async (resolve, reject) => {
      const response = await apiRequest.requestExportEtable(params);
      resolve({
        title: response.title,
        messages: response.messages,
        variant: response.variant,
      });
    });
  };

  const hasAddon = (addon) => {
    return get(tableRef.current.addons, addon, null);
  };

  ref.current.backboneInstance = {
    visibility,
    status,
    options,
    setConfig,
    updateConfig,
    config,
    getRowId: getRowId,
    setGridApi: ({ grid, column }) => {
      tableRef.current.gridApi = grid;
      tableRef.current.columnApi = column;
      if (grid.addEventListener) {
        grid.addEventListener('paginationChanged', (event) => {
          restoreSelectedRows(event);
        });
      }
      // tableRef.current.gridApi.refreshServerSideStore({ purge: true });
    },
    getGridApi: () => tableRef.current.gridApi,
    getColumnApi: () => tableRef.current.columnApi,
    reloadData: (name: string, route) => {
      log('reloadDATA', name);
      reloadHandler[name] && reloadHandler[name](route);
    },
    init: init,
    isTableReady: () => !!tableRef.current.gridApi,
    getCellConfig: (columnField: string) => {
      return get(config, ['mapping', columnField]);
    },
    getConfig: (name: string, defaultValue: any = null) =>
      cloneDeep(get(fastRef.current.config, `${name}`, defaultValue)),
    // cloneDeep(get(config, `${name}`, defaultValue)),
    getCallback: (name: string) => get(tableRef.current.callback, `${name}`, EMPTY_FUNCTION),
    EMPTY_FUNCTION: EMPTY_FUNCTION,
    // getConfig: useConfig,
    // updateConfig: updateConfig,
    createUserView: createUserView,
    updateUserView: updateUserView,
    deleteUserView: deleteUserView,
    selectView: selectView,
    getStatus: (name: string) => get(status, `${name}`, ''),
    getHeaders: () => headers,
    getRows: getDataRows,
    getDatasource: () => tableRef.current.datasource,
    getPagination: () => {
      log('getPagination', tableRef.current.pagination, pagination);
      return pagination;
    },
    getOptions: (name: string) => get(options, name, []),
    getFilterOptionValue: ff.custom_filter_value_addon ? getFilterOptionValue : getFilterValueData,
    getTempStorage: (name: string) => get(ref.current.tempStorage, name),
    // getOptions: useOption,
    getSubmitHandler: (type: string) => apiRequest[type],
    getVisibility: (name: string) => get(visibility, `${name}`, false),
    getSelectedRows: handleGetSelectedRows,
    getContextSelectedRows: () => tableRef.current.gridApi.getSelectedRows(),
    updateSelectedRows: handleUpdateSelectedRows,
    updateTempStorage: (name: string, value: any) => {
      ref.current.tempStorage[name] = value;
    },
    changeVisibility: handleChangeVisibility,
    changeStatus: handleChangeStatus,
    changeConfig: changeConfig,
    changeOptionOrder: handleChangeOptionOrder,
    changePagination: handleChangePagination,
    changeColumnOrder: handleChangeColumnOrder,
    getMainButtonComponent,
    getAvailableColumns,
    getAvailableFilterColumns,
    getAvailableSortColumns,
    registerMainButtonComponent,
    registerHeaderAction,
    registerCellFormat,
    registerCellEditor,
    registerNotification,
    registerAddon: registerAddon,
    addon: addon,
    ...(ff.storefront_sos_option_list ? { hasAddon } : {}),
    ...(ff.separate_table_data_loading ? { loadPagination } : {}),
    exportEtable: handleExportEtable,
    ...(ff.time_periodically ? { getTimePeriodicallyOptions } : {}),
    addInitialDataRequest,
    getInitialDataRequest,
    autoResizeColumn: (field) => {
      tableRef.current.columnApi.autoSizeColumn(field, false);
    },
    handleCellSubmit: handleSubmit,
  };

  return ref.current.backboneInstance;
};
