import * as React from 'react';

import { Button, Popover } from '@material-ui/core';

import Wrapper from '@ep/insight-ui/elements/etable2/wrapper';

const PopoverComponent = ({ ChildComponent, title, eTableEndpoint, gridApi, params }: any) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Button variant="contained" color="secondary" onClick={handleClick}>
        {title}
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Wrapper>
          <ChildComponent handleClose={handleClose} eTableEndpoint={eTableEndpoint} gridApi={gridApi} params={params} />
        </Wrapper>
      </Popover>
    </>
  );
};

export default PopoverComponent;
