import { aim } from '@eip/next/lib/main';
import React from 'react';
import ReactDOM from 'react-dom';
import { Workbox } from 'workbox-window';
import OneApp from './app';
import './appnew.css';

let root;

if (typeof global.process === 'undefined') {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('/sw.js');
    const notyf = new window.Notyf({ duration: 10000, position: { x: 'center', y: 'top' } });
    window._eBanner = notyf;
    window._eGetUser = () => {
      let settings = aim.getUserSettings() || {};
      settings = { ...settings, auth: aim.getLoginToken() };
      return settings;
    };

    // let loadingNotice;
    // if (!root) {
    //   loadingNotice = notyf.success({
    //     message: 'Loading...',
    //     icon: false,
    //   });
    // }

    function boot() {
      if (!root) {
        // notyf.dismiss(loadingNotice);
        root = document.getElementById('root');
        ReactDOM.render(<OneApp />, root);
      }
    }

    const showSkipWaitingPrompt = async (event) => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.
      wb.addEventListener('controlling', () => {
        // At this point, reloading will ensure that the current
        // tab is loaded under the control of the new service worker.
        // Depending on your web app, you may want to auto-save or
        // persist transient state before triggering the reload.
        // window.location.reload();
        if (root) {
          try {
            const noti = notyf.success('New version updated. Click here to refresh the app.');
            try {
              fetch([self.location.protocol, '//', self.location.host, '/api/v1/reload-cache?q=vendors'].join(''));
            } catch {}
            noti.on('click', () => {
              window.location.reload();
            });
          } catch (err) {
            console.error('fail to notify user');
          }
        }
      });

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      // This code assumes your app has a promptForUpdate() method,
      // which returns true if the user wants to update.
      // Implementing this is app-specific; some examples are:
      // https://open-ui.org/components/alert.research or
      // https://open-ui.org/components/toast.research
      // const updateAccepted = window.confirm("Please reload for new version.");

      // if (updateAccepted) {
      wb.messageSkipWaiting();
      // }
    };

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.

    wb.addEventListener('activated', () => {
      if (!root) {
        window.location.reload();
      }
    });

    wb.addEventListener('waiting', (event) => {
      if (!root) {
        boot();
      }
      showSkipWaitingPrompt(event);
    });

    wb.addEventListener('message', (event) => {
      try {
        if (event.data && event.data.type === 'NEW_PAGE_UPDATE') {
          const noti = notyf.success('New version updated. Click here to refresh the page.');
          noti.on('click', () => {
            window.location.reload();
          });
        }
      } catch {
        console.error('fail to notify user');
      }
    });

    wb.register().then((reg) => {
      if (navigator.serviceWorker.controller === null) {
        navigator.serviceWorker.ready.then(() => {
          console.info('service worker ready');
          reg.active.postMessage('claimMe');
        });
      }

      reg.addEventListener('updatefound', (evt) => {
        const newWorker = reg.installing;
        console.info('updatefound', newWorker.state);

        newWorker.addEventListener('statechange', () => {
          console.info('statechange', newWorker.state);
        });
      });

      if (!root && reg.active) {
        boot();
      }

      window.setInterval(() => {
        reg.update();
      }, 10 * 60 * 1000 /* check every 10min for the update */);
    });
  }
}
