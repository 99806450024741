import { eipRequest as request, EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { createDataQueryFromConfig, getAPIRequest } from '@ep/insight-ui/system/backbone/table-backbone';
import { createWorkflowCampaignDetails } from '@ep/insight-ui/system/backbone/workflow-backbone';
import { enhanceDataRequest2 } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';
import produce from 'immer';
import {
  cloneDeep,
  difference,
  differenceWith,
  flatten,
  get,
  isEqual,
  merge,
  omit,
  set,
  uniq,
  uniqBy,
  uniqueId,
} from 'lodash';
import qs from 'qs';
import { getCampaignSettingInfo, publishDimensions } from '../legacy/api-request-campaign-details';
import { COMPACT_AD_OBJECT_CONFIG, COMPACT_AD_TOOL_CONFIG, COMPACT_KEYWORD_CONFIG } from '../table-config';
import { CAMPAIGN_DETAIL_CONFIG } from './table-config';
import { compactTableKeywordConfig, getSelectedAdObjects } from '../util';

const COMPACT_TABLE_CONFIG = {
  ad_tool: { ...COMPACT_AD_TOOL_CONFIG, tableId: 'ad_tool_compact' },
  ad_object: { ...COMPACT_AD_OBJECT_CONFIG, tableId: 'ad_object_compact' },
  keyword: { ...COMPACT_KEYWORD_CONFIG, tableId: 'keyword_compact' },
};

const CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY = {
  ...CAMPAIGN_DETAIL_CONFIG,
  primaryKeys: [...CAMPAIGN_DETAIL_CONFIG.primaryKeys, 'eipCustomRowId'],
};

const log = useLog('chartlib:table-campaign-detail');
const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

const endpoint = {
  GET_CAMPAIGN_SETTING: API_URL + '/mop-query/listing/getCampaignData',
  GET_CAMPAIGN_INSIDE_DATA: API_URL + '/mop-query/listing/getCampaignInsideData',
  CREATE_DIMENSION: API_URL + '/ads-operation/create-dimension',
  UPDATE_DIMENSION: API_URL + '/ads-operation/update-dimension',
  MASS_CREATE_DIMENSION: API_URL + '/ads-operation/mass-create-dimension',
  MASS_UPDATE_DIMENSION: API_URL + '/ads-operation/mass-update-dimension',
  MASS_DELETE_DIMENSION: API_URL + '/ads-operation/mass-delete-dimension',
  REQUEST_CUSTOM_KEYWORD: API_URL + '/ads-operation/filter-dimension',
  MASS_UPSERT_DIMENSION: API_URL + '/ads-operation/mass-upsert-dimension',
};

const DEFAULT_VALUE = {
  MATCH_TYPE: 'EXACT_MATCH',
  BIDDING_PRICE: 450,
};

interface autoAdsParams {
  adsObjectId: number;
  marketplaceCode: string;
}

interface autoBiddingParams {
  id: number;
  bidding_price: number | null;
  marketplaceCode: string;
}

export function getWorkflow() {
  let lastKeyword;
  if (ff.loading_config_search) {
    lastKeyword = '';
  }
  const wf = createWorkflowCampaignDetails(
    {},
    {
      async full_tableConfig(input: Record<string, any>, workflow) {
        const ref: any = {};
        const tableConfig: Record<string, any> = merge(cloneDeep(CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY), {
          mapping: {
            ad_tool: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_tool' }),
            },
            ad_object: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_object' }),
            },
            keyword: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'keyword' }),
            },
          },
        });

        const excludeColumns = ['storefront_ad_object', 'tagline', 'landing_page'];
        tableConfig.mapping = omit(tableConfig.mapping, excludeColumns);

        const config = {
          apiRequest: await workflow.request('eTableApiRequest', {
            campaignId: input.campaignId,
            schedule: input.schedule,
          }),
          id: uniqueId('ep_campaign_detail_'),
          configuration: tableConfig,
          callback: {
            onBackboneReady: (backbone) => {
              ref.backbone = backbone;
              workflow.set('mainTableBackbone', backbone);
              backbone.changeConfig('dateRange', input.schedule);
            },
            onRowRemove: async (type, rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              let selectedRows = [];
              let reloadTable = false;
              switch (type) {
                case 'ad_object':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['ADS_OBJECT.id'] !== rows['ADS_OBJECT.id']);

                  res = await workflow.applyUpdate('syncRemoveProduct', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                  } else {
                    error = res.error;
                  }
                  reloadTable = res.reloadTable;
                  break;
                case 'keyword':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['SUGGESTED_KEYWORD.id'] !== rows['SUGGESTED_KEYWORD.id']);

                  res = await workflow.applyUpdate('syncRemoveProductKeywords', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                  } else {
                    error = res.error;
                  }
                  reloadTable = res.reloadTable;
                  break;
                case 'keyword_method':
                  selectedRows = backbone.getSelectedRows();
                  const ids = uniq(
                    [...selectedRows.map((row) => row['ADS_PLACEMENT.id']), rowTarget['ADS_PLACEMENT.id']].filter(
                      (el) => !!el,
                    ),
                  );
                  res = await workflow.request('deleteAdsPlacements', {
                    id: ids,
                    marketplaceCode: rowTarget['MARKETPLACE.marketplace_code'],
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                case 'status':
                  res = await workflow.request('deleteStatusRow', {
                    id: rowTarget['ADS_PLACEMENT.id'] || rowTarget['ADS_OBJECT.id'],
                    dimension: rowTarget['ADS_PLACEMENT.id'] ? 'ADS_PLACEMENT' : 'ADS_OBJECT',
                    marketplaceCode: rowTarget['MARKETPLACE.marketplace_code'],
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                default:
                  updatedRows = rows;
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, item.message], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (reloadTable) {
                // stateDispatch.current({ type: type, payload: { selectedItems: updatedRows, rowTarget } });
                // onChange(updatedRows, type);
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
            onAddAutoAds: async ({ adsObjectId, marketplaceCode }: autoAdsParams, backbone) => {
              const selectedRows = backbone.getSelectedRows();
              const ids = uniq([...selectedRows.map((row) => row['ADS_OBJECT.id']), adsObjectId]);
              const params = {
                data: ids.map((id) => ({
                  automated_ads: 1,
                  adsObjectId: id,
                  status: 'DRAFT',
                  type: 'SEARCH_RESULT_PLACEMENT',
                })),
                marketplaceCode,
              };
              const response: any = await workflow.request('createAdsPlacements', params);
              if (response && response.success) {
                backbone.getSelectedRows(selectedRows.map((r) => ({ data: r, isSelected: false })));
                backbone.getGridApi().deselectAll();
                backbone.reloadData('table');
              } else {
                const errors = response.data.reduce((a, b) => (b.success ? a : [...a, ...Object.values(b.error)]), []);
                if (errors.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errors),
                  });
                }
              }
            },
          },
          addons: {
            'compactTable.keyword.config': async ({ compactTableConfig }, rowTarget, backbone) => {
              return compactTableKeywordConfig({ compactTableConfig }, rowTarget, backbone, {
                input,
                workflow,
                marketplace: 'SHOPEE'
              });
            },
            'datasource.getRowsParams': async ({ params }, config) => {
              const ignoreFields = ['keyword', 'ad_object', 'ad_tool', 'context'];
              params.attributes = params.attributes.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              params.dimensions = params.dimensions.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              const aggregations = get(params, 'groupBy.aggregations', []);
              if (aggregations.length > 0) {
                params.groupBy.aggregations = aggregations.filter((i) => {
                  return ignoreFields.indexOf(i.field) === -1;
                });
              }
              return params;
            },
            'keyword.method.cell.action': (props, backbone, refConfig, displayTableCompact) => {
              const value = get(props, ['value', 'value'], null);
              const adsObjectId = get(props, ['value', 'adsObjectId'], '');
              const marketplaceCode = get(props, ['value', 'marketplaceCode'], '');

              return [
                {
                  name: value ? `Add manual keyword` : `Add Shopee automation`,
                  icon: 'plus',
                  iconSize: '13px',
                  onSubmit: async () => {
                    if (value) {
                      backbone
                        .addon(
                          `compactTable.keyword.config`,
                          async ({ compactTableConfig }, rowTarget, backbone) => compactTableConfig,
                        )({ compactTableConfig: refConfig.current.compactTableConfig }, props.data, backbone)
                        .then((enhancedConfig) => {
                          // setCompactTableConfig(enhancedConfig);
                          refConfig.current.compactTableConfig = enhancedConfig;
                          window.setTimeout(() => displayTableCompact(true), 50);
                        });
                    } else {
                      const params = {
                        adsObjectId,
                        marketplaceCode,
                      };
                      backbone.getCallback('onAddAutoAds')(params, backbone);
                    }
                  },
                },
                {
                  name: value ? `Remove Shopee automation` : `Remove all manual keyword`,
                  icon: 'remove',
                  iconSize: '12px',
                  onSubmit: async () => {
                    if (value) {
                      backbone.getCallback('onRowRemove')(props.colDef?.field, [], props.node.data, backbone);
                    }
                  },
                  disable: !value,
                },
                {
                  name: `Add Botep automation`,
                  icon: 'epsiloDisable',
                  iconSize: '14px',
                  onSubmit: () => undefined,
                  disable: true,
                },
              ];
            },
            'selection.filter': (field, originSelectionFilter) => {
              const additionalFilterField = get(tableConfig, ['system', 'additionalFilters'], []).find(
                ({ id }) => id === field,
              );

              const additionalOperators = get(additionalFilterField, ['operators'], []).map((el) => el.value);

              return additionalOperators.length ? additionalOperators : originSelectionFilter;
            },
            ...get(tableConfig, ['system', 'additionalFilters'], []).reduce((a, b) => {
              return {
                ...a,
                [`filterValue.${b.id}`]: () => {
                  return {
                    data: b.options,
                    success: true,
                    message: 'OK',
                  };
                },
              };
            }, {}),
            'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
              return enhanceDataRequest2(params, originRequest, backbone);
            },
          },
        };
        return config;
      },
      async compact_tableConfig({ column: type }, workflow) {
        const tableConfig = COMPACT_TABLE_CONFIG[type];
        const finalConfig: RecordValue = {
          tableCompactId: tableConfig.tableId,
          configuration: tableConfig,
          // apiRequest: {
          //   getTableData: () => Promise.resolve(COMPACT_TABLE_RES[type]),
          // },
          submitRowSelectionOnClosed: true,
          callback: {
            onRowSelect: async (rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              switch (type) {
                case 'ad_object': {
                  res = await workflow.applyUpdate('syncUpdateCampaignProducts', {
                    updateType: 'default',
                    target: rowTarget,
                    value: rows,
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                case 'keyword': {
                  const contextSelectedRows = backbone.getSelectedRows();
                  res = await workflow.applyUpdate('syncUpdateCampaignKeywords', {
                    updateType: 'default',
                    value: rows,
                    target: contextSelectedRows.length > 0 ? contextSelectedRows : [rowTarget],
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                default:
                  updatedRows = rows;
              }

              if (updatedRows) {
                workflow.emit('onChange', { updatedRows, type });
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, ...Object.values(item.error)], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (updatedRows.length > 0) {
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
        };
        log('Compact table config', { type, finalConfig });

        let searchField = '';
        switch (type) {
          case 'ad_object':
            searchField = 'PRODUCT';
            break;
          case 'keyword':
            searchField = 'SUGGESTED_KEYWORD.name';
            break;
        }

        finalConfig.addons = {
          ...(COMPACT_TABLE_CONFIG[type]?.addons || {}),
          'datasource.getRowsParams': async ({ params }, config) => {
            if (ff.loading_config_search) {
              if (type === 'keyword') {
                const searchValue = config.search;
                if (lastKeyword !== searchValue) {
                  lastKeyword = searchValue;
                  const context = get(config, 'system.context');
                  let masterObjectIds;
                  const { mainTableBackbone: backbone } = await workflow.get('mainTableBackbone');
                  masterObjectIds = uniq([
                    ...backbone.getSelectedRows().map((i) => i['PRODUCT.id']),
                    get(context, ['PRODUCT.id']),
                  ]);
                  await workflow.request('queryCustomKeyword', {
                    marketcode: get(context, ['MARKETPLACE.marketplace_code']),
                    keywords:
                      !searchValue || String(searchValue).trim() === ''
                        ? ['']
                        : String(searchValue)
                            .split('\n')
                            .filter((i) => String(i).trim() !== ''),
                    masterObjectIds,
                    storefrontId: get(context, ['STOREFRONT.id']),
                    toolCode: get(context, ['ADTOOL.ads_tool']),
                  });

                  if (lastKeyword !== searchValue) {
                    return null;
                  }
                }
              }
            }

            const context = get(config, 'system.context');
            const getStorefrontId = get(context, 'STOREFRONT.id');
            const storefrontIdFilter = {
              field: 'STOREFRONT.id',
              dataType: 'string',
              operator: 'is',
              value: getStorefrontId,
            };
            const shopeeFilter = {
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              operator: 'is',
              value: 'SHOPEE',
            };

            if (!params.filter) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter],
                  });
            } else if (
              !params.filter.filters.some((el) =>
                Object.keys(shopeeFilter).every((ele) => el[ele] === shopeeFilter[ele]),
              )
            ) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter],
                  });
            }

            if (config.search) {
              return produce(params, (draft) => {
                const searchFilter = {
                  combinator: 'OR',
                  filters: config.search
                    .split('\n')
                    .filter((i) => String(i).trim() !== '')
                    .map((i) => {
                      return {
                        field: searchField,
                        dataType: 'string',
                        operator: 'contains',
                        value: String(i).trim(),
                      };
                    }),
                };

                if (!params.filter) {
                  draft.filter = searchFilter;
                } else {
                  draft.filter.filters = [...draft.filter.filters, searchFilter];
                }
              });
            }

            return params;
          },
        };

        if (!ff.loading_config_search) {
          if (type === 'keyword') {
            finalConfig.addons['config.search'] = async ({ value }, config) => {
              const context = get(config, 'system.context');
              const result: any = await workflow.request('queryCustomKeyword', {
                marketcode: get(context, ['MARKETPLACE.marketplace_code']),
                keywords:
                  !value || String(value).trim() === ''
                    ? ['']
                    : String(value)
                        .split('\n')
                        .filter((i) => String(i).trim() !== ''),
                productId: get(context, ['PRODUCT.id']),
                storefrontId: get(context, ['STOREFRONT.id']),
                toolCode: get(context, ['ADTOOL.ads_tool']),
              });

              if (result.success) {
                return Promise.resolve({ value });
              } else {
                return Promise.reject({ value });
              }
            };
          }
        }

        return finalConfig;
      },
    },
    {
      eTableApiRequest: async ({ campaignId, schedule }, workflow) => {
        let apiRequest;
        const endpoint = CAMPAIGN_DETAIL_CONFIG.endpoint;
        apiRequest = getAPIRequest(endpoint);
        const originGetTableData = apiRequest.getTableData;
        apiRequest = {
          ...apiRequest,
          getTableData: (params) => {
            let hiddenFilter = params.hiddenFilter;
            if (!hiddenFilter) {
              hiddenFilter = {
                combinator: 'AND',
                filters: [],
              };
            }

            hiddenFilter = produce(hiddenFilter, (draft) => {
              draft.combinator = 'AND';
              draft.filters = (hiddenFilter.filters || []).concat({
                field: 'ADS_CAMPAIGN.id',
                dataType: 'integer',
                operator: '=',
                value: campaignId,
              });
            });

            params.sort = params.sort
              ? [].concat(params.sort)
              : [
                  { field: 'ADS_PLACEMENT.created_at', sort: 'DESC' },
                  { field: 'ADS_OBJECT.created_at', sort: 'DESC' },
                  { field: 'ADS_PLACEMENT.updated_at', sort: 'DESC' },
                  { field: 'ADS_OBJECT.updated_at', sort: 'DESC' },
                ];

            return originGetTableData({ ...params, hiddenFilter }).then(async (res) => {
              const rows = res.data.rows;
              let result = null;

              if (rows.length === 0) {
                // rows = stateSelector.current;
                result = await workflow.request('initFirstRowForEmptyCampaign', {
                  campaignId,
                  marketplace: 'SHOPEE',
                  campaignDateFrom: schedule.dateFrom,
                  campaignDateTo: schedule.dateTo,
                  originResponse: res,
                });
              } else {
                result = {
                  ...res,
                  data: {
                    ...res.data,
                    rows: await workflow.request('transformCellCompactSelections', {
                      rows,
                      campaignDateFrom: schedule.dateFrom,
                      campaignDateTo: schedule.dateTo,
                    }),
                  },
                };
              }

              return result;
            });
          },
        };
        return apiRequest;
      },
      initFirstRowForEmptyCampaign: async (
        { marketplace, campaignId, campaignDateFrom, campaignDateTo, originResponse },
        workflow,
      ) => {
        const campaignInfo = await getCampaignSettingInfo({ campaignId, marketplaceCode: marketplace });
        const rows = await workflow.request('transformCellCompactSelections', {
          rows: [].concat(campaignInfo),
          campaignDateFrom,
          campaignDateTo,
        });

        const result = produce(originResponse, (draft) => {
          set(draft, 'data.pagination.total', 1);
          set(draft, 'data.rows', rows);
        });

        return result;
      },
      transformCellCompactSelections: async (
        { rows, campaignDateFrom: campaignFrom, campaignDateTo: campaignTo },
        workflow,
      ) => {
        const toolIdField = 'ADTOOL.ads_tool';
        const toolNameField = 'ADTOOL.ads_tool_name';
        const adObjectNameField = 'ADS_OBJECT.name';
        const keywordIdField = 'SUGGESTED_KEYWORD.id';
        const keywordNameField = 'SUGGESTED_KEYWORD.name';
        const productIdField = 'PRODUCT.id';

        rows.forEach((r) => {
          r[keywordIdField] = r['ADS_PLACEMENT.id'];
          r[keywordNameField] = r['ADS_PLACEMENT.name'];
        });

        const selectedAdTools: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: toolIdField,
          keyTuple: [toolIdField, toolNameField],
        });

        let selectedAdObjects: any[] = rows;
        if (rows.length > 0) {
          const r = rows[0];
          selectedAdObjects = await getSelectedAdObjects({
            currentWorkflow: workflow,
            marketplace: 'SHOPEE',
            compactTableConfig: COMPACT_TABLE_CONFIG.ad_object,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom, // FIXME: asked querying calendar on api
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
            originalRows: rows,
            productIdField,
            adObjectNameField,
          });
        }

        // const selectedKeywords = filterSelectedItems(rows, keywordIdField, keywordNameField);
        let getSelectedKeywords: any[];
        if (ff.groupby_keyword_column) {
          getSelectedKeywords = await workflow.request('filterSelectedItems', {
            rows,
            idField: keywordIdField,
            keyTuple: [productIdField, keywordNameField, keywordIdField],
          });
        }

        const groupedKeywordsByProduct = selectedAdObjects.reduce((acc, adObject) => {
          const productId = adObject[productIdField];
          const keywords = rows
            .filter((r) => r[keywordIdField] && r[productIdField] === productId)
            .map((r) => ({
              master_object_id: r[productIdField],
              [keywordIdField]: r[keywordIdField],
              [keywordNameField]: r[keywordNameField],
            }));
          return {
            ...acc,
            [productId]: keywords,
          };
        }, {});

        rows.forEach((r) => {
          const productId = r[productIdField];
          const selectedKeywords = groupedKeywordsByProduct[productId] || [];
          r.eipCustomRowId = uniqueId('eip_row_');
          r.ad_tool = {
            selectedItems: selectedAdTools,
            currentFocusItem: selectedAdTools.find((i) => i[toolIdField] === r[toolIdField]),
            displayField: toolNameField,
            singleSelection: true,
          };
          r.ad_object = {
            selectedItems: selectedAdObjects,
            currentFocusItem: selectedAdObjects.find((i) => i[productIdField] === r[productIdField]),
            displayField: adObjectNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom,
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
          };
          r.keyword = {
            selectedItems: selectedKeywords,
            currentFocusItem: selectedKeywords.find((i) => i[keywordIdField] === r[keywordIdField]),
            displayField: keywordNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: productIdField,
                  operator: '=',
                  dataType: 'integer',
                  value: productId,
                },
              ],
            },
          };
        });

        return rows;
      },
      async filterSelectedItems(
        { rows, idField, keyTuple }: { rows: any[]; idField: string; keyTuple: any[] },
        workflow,
      ) {
        return rows.reduce((acc, r) => {
          const exist = acc.find((i) => i[idField] === r[idField]);
          if (!exist && r[idField]) {
            return [
              ...acc,
              {
                ...keyTuple.reduce((carry, k) => {
                  return { ...r, ...carry, [k]: r[k] };
                }, {}),
              },
            ];
          }
          return acc;
        }, []);
      },

      createAdsObjects: (param: {
        data: {
          masterObjectId: string;
          adsCampaignId: string;
          dailyBudget: number;
          totalBudget: number;
        }[];
        marketplaceCode: string;
      }) => {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            adsOpsAdsCalendars: [],
            status: 'DRAFT',
          })),
          dimension: 'ADS_OBJECT',
        };
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsObjects(param: { id: string[]; marketplaceCode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_OBJECT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.deleteFetch(url);
      },
      async queryCustomKeyword({
        marketcode,
        toolCode,
        keywords,
        storefrontId,
        productId, // will be removed when remove ff "mass_create_custom_keyword"
        masterObjectIds,
      }: {
        toolCode: string;
        marketcode: string;
        keywords: string[];
        storefrontId: string | number;
        productId: string | number; // will be removed when remove ff "mass_create_custom_keyword"
        masterObjectIds: string[] | number[];
      }) {
        return request.post(endpoint.REQUEST_CUSTOM_KEYWORD, {
          dimension: 'SUGGEST_KEYWORD',
          marketplace_code: marketcode,
          search_by: {
            tool_code: [toolCode],
            storefront_id: [storefrontId],
            master_object_id: masterObjectIds,
            name: keywords,
          },
        });
      },
      createAdsPlacements(param: {
        data: {
          adsObjectId: string;
          name: string;
          matchType: string;
          biddingPrice: number;
        }[];
        marketplaceCode: string;
      }) {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            type: 'SEARCH_RESULT_PLACEMENT',
            status: 'DRAFT',
          })),
          dimension: 'ADS_PLACEMENT',
        };
        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsPlacements(param: { id: string[]; marketplacecode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_PLACEMENT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      deleteStatusRow(param: { id: string[]; marketplacecode: string; dimension: string }) {
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
          {
            ...param,
            dimension: param.dimension,
          },
          {
            arrayFormat: 'comma',
          },
        )}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      dataQueryFromMainTable: async ({ campaignId, schedule, excludedMetric = true }, workflow) => {
        const tableConfig = await workflow.getTableConfig('full', 'tableConfig', { campaignId, schedule });
        const config = tableConfig.configuration;
        if (excludedMetric) {
          config.mapping = Object.keys(config.mapping).reduce((carry, k) => {
            {
              if (config.mapping[k].propertyType !== 'metric') {
                return { ...carry, [k]: config.mapping[k] };
              }
              return carry;
            }
          }, {});
        }
        const ds = createDataQueryFromConfig({ config: config, addons: tableConfig.addons });
        return ds;
      },
      campaignStatus: async ({ campaignId, schedule }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });

        return {
          haveDraft: true,
          draftItems: [],
        };
      },
      publishCampaign: async ({ campaignId, schedule, marketplace }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });
        const results = [];

        const draftProduct = await ds.query({
          filter: [],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_OBJECT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdObjectIds = draftProduct.rowData.map((i) => i['ADS_OBJECT.id']);
        if (draftAdObjectIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_OBJECT',
              id: draftAdObjectIds,
              marketplaceCode: marketplace,
            }),
          );
        }

        const draftKeywords = await ds.query({
          filter: [],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_PLACEMENT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdPlacementIds = draftKeywords.rowData.map((i) => i['ADS_PLACEMENT.id']);
        if (draftAdPlacementIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_PLACEMENT',
              id: draftAdPlacementIds,
              marketplaceCode: marketplace,
            }),
          );
        }
        return results;
      },
    },
    {
      async syncRemoveProduct({ target: rowTarget, updateType, value: products }, workflow) {
        const adObjectIdField = 'ADS_OBJECT.id';
        const marketplaceCode = get(rowTarget, ['MARKETPLACE.marketplace_code'], null);
        const selectingItemIds = products.map((i) => i[adObjectIdField]);

        const removeProductIds = selectingItemIds;

        let removePromise = null;
        if (removeProductIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            id: selectingItemIds,
          };
          removePromise = workflow.request('deleteAdsObjects', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = result.data;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncRemoveProductKeywords({ target: rowTarget, value: keywords }, workflow) {
        const keywordIdField = 'SUGGESTED_KEYWORD.id';
        const keywordNameField = 'SUGGESTED_KEYWORD.name';
        const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);
        const selectingItemIds = keywords.map((i) => ({
          masterObjectId: i[keywordIdField],
          name: i[keywordNameField],
        }));

        // Determine which keywords to add/remove
        const removedKeywordIds = selectingItemIds;

        let removePromise = null;
        if (removedKeywordIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            ...(ff.select_all_add_keyword
              ? {
                  id: removedKeywordIds.map((i) => i.masterObjectId).filter((el) => el !== null),
                }
              : {
                  id: removedKeywordIds.map((i) => i.masterObjectId),
                }),
          };
          removePromise = workflow.request('deleteAdsPlacements', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = removedKeywordIds;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncUpdateCampaignProducts({ target, updateType, value }, workflow) {
        const productIdField = 'PRODUCT.id';
        const adObjectIdField = 'ADS_OBJECT.id';
        const campaignId = get(target, 'ADS_CAMPAIGN.id', null);
        const marketplaceCode = get(target, ['MARKETPLACE.marketplace_code'], null);
        const storefrontId = get(target, ['STOREFRONT.id'], null);
        const selectedItems = get(target, ['ad_object', 'selectedItems'], []);
        const countryCode = get(target, ['COUNTRY.country_code'], null);
        const toolCode = get(target, ['ADTOOL.ads_tool'], null);
        const adType = get(target, ['ADTYPE.ads_type'], null);

        const selectedItemIds = selectedItems.map((i) => i[productIdField]);
        const selectingItemIds = value.map((i) => i[productIdField]);

        // Determine which products to add/remove
        const removeProductIds = difference(selectedItemIds, selectingItemIds);
        const addProductIds = difference(selectingItemIds, selectedItemIds);

        let removePromise = null;
        let addPromise = null;
        removePromise = Promise.resolve(null);

        if (addProductIds.length > 0) {
          const addAdsObjectParam = {
            marketplaceCode,
            data: addProductIds.map((id) => ({
              masterObjectId: id, // FIXME: incorrect
              masterObjectType: undefined, // no need according to @Nam.Huynh https://epsilo.slack.com/archives/D01TB0HSUPN/p1635135520001600
              countryCode,
              storefrontId,
              toolCode,
              type: adType,
              adsCampaignId: campaignId,
              totalBudget: 0,
              dailyBudget: 0,
              adsOpsAdsCalendars: [],
            })),
          };
          addPromise = workflow.request('createAdsObjects', addAdsObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];
            if (values[0]) {
              if (values[0].success) {
                rows = selectedItems.filter((i) => !removeProductIds.includes(i[productIdField]));
                success = true;
              } else {
                error = error.concat(values[0].data);
                console.log(values[0].message);
              }
            }

            if (values[1]) {
              if (values[1].success) {
                const addedItems = value
                  .filter((i) => addProductIds.includes(i[productIdField]))
                  .map((i) => {
                    const adObject = values[1].data.find((v) => v.data.masterObjectId == i[productIdField]);
                    return {
                      ...i,
                      status: get(adObject, ['data', 'status'], ''),
                      adsObjectId: parseInt(get(adObject, ['data', 'adsObjectId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.log(values[1].message);
                console.error(values[1]);
              }
            }

            return {
              success,
              data: rows,
              error: error,
            };
          })
          .catch((err) => {
            console.log('Error', err);
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      syncUpdateCampaignKeywords: (
        { value: keywords, target: rowTargets }: { value: any[]; target: any[] },
        workflow,
      ): Promise<any> => {
        return new Promise((resolve, reject) => {
          const productIdField = 'SUGGESTED_KEYWORD.master_object_id';
          const keywordNameField = 'SUGGESTED_KEYWORD.name';

          const adObjectIds = uniq(
            rowTargets.map((rowTarget) => get(rowTarget, ['ad_object', 'currentFocusItem', 'ADS_OBJECT.id'], null)),
          );

          const adObjects = uniq(
            rowTargets.map((rowTarget) => get(rowTarget, ['ad_object', 'currentFocusItem'], null)),
          );

          const marketplaceCode = get(rowTargets[0], 'MARKETPLACE.marketplace_code', null);
          const selectedItems: any[] = flatten(
            rowTargets.map((rowTarget) => get(rowTarget, ['keyword', 'selectedItems'], [])),
          );

          // const adObjectId = get(rowTarget, ['ad_object', 'currentFocusItem', 'ADS_OBJECT.id'], null);
          // const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);

          // const selectedItems = get(rowTarget, ['keyword', 'selectedItems'], []);

          // const selectedItemIds = uniqBy(
          //   selectedItems.map((i) => ({
          //     masterObjectId: i[productIdField],
          //     name: i[keywordNameField],
          //   })),
          //   (i) => [i.masterObjectId, i.name].join(','),
          // );
          const selectedItemIds = [];

          const selectingItemIds = uniqBy(
            keywords.map((i) => ({
              masterObjectId: i[productIdField],
              name: i[keywordNameField],
              biddingPrice: i['SUGGESTED_KEYWORD.bidding_price'],
            })),
            (i) => [i.masterObjectId, i.name].join(','),
          ).filter((i) => i.masterObjectId);

          // Determine which keywords to add/remove
          // const removeKeywordIds = differenceWith(selectedItemIds, selectingItemIds, isEqual);
          /**
           * FIXME: temporary commented the removed keywords, the logic here is not correct with provided context
           * when there are multiple ad object selected and suggested selected list does not return the existing keyword
           */
          const addKeywordIds = differenceWith(selectingItemIds, selectedItemIds, isEqual);

          const removePromise = Promise.resolve('todo...');
          let addPromise = null;

          if (addKeywordIds.length > 0) {
            const addAdsObjectParam = {
              marketplaceCode,
              data: flatten(
                addKeywordIds.map((i) => {
                  return adObjects
                    .filter((adObject) => String(i.masterObjectId).includes(adObject['PRODUCT.id']))
                    .map((adObject) => {
                      return {
                        adsObjectId: adObject['ADS_OBJECT.id'],
                        name: i.name,
                        matchType: DEFAULT_VALUE.MATCH_TYPE,
                        biddingPrice:
                          keywords.find((kw) => kw[keywordNameField] == i.name)['SUGGESTED_KEYWORD.bidding_price'] ||
                          DEFAULT_VALUE.BIDDING_PRICE,
                      };
                    });
                }),
              ).reduce((a, b) => {
                // Remove duplicate element
                if (!a.find((el) => Object.keys(el).every((ele) => el[ele] === b[ele]))) {
                  return [...a, b];
                }
                return a;
              }, []),
            };
            addPromise = workflow.request('createAdsPlacements', addAdsObjectParam);
          } else {
            addPromise = Promise.resolve(null);
          }

          Promise.all([removePromise, addPromise])
            .then((values) => {
              let rows = [];
              let success = false;
              let error = [];
              if (values[1]) {
                if (values[1].success) {
                  const addedItems = keywords
                    .filter((item) => {
                      const exist = addKeywordIds.some(
                        (i) => i.masterObjectId === item[productIdField] && i.name === item[keywordNameField],
                      );
                      return exist;
                    })
                    .map((i) => {
                      const adPlacement = values[1].data.find((v) => v.data.name === i[keywordNameField]);
                      return {
                        ...i,
                        status: get(adPlacement, ['data', 'status'], ''),
                        adsPlacementId: parseInt(get(adPlacement, ['data', 'adsPlacementId'], 0)),
                      };
                    });
                  rows = [...rows, ...addedItems];
                  success = true;
                } else {
                  error = error.concat(values[1].data);
                  console.error(JSON.stringify(values[1]));
                  console.log(values[1].message);
                }
              }

              resolve({
                success,
                data: rows,
                error,
              });
            })
            .catch((err) => {
              resolve({
                success: false,
                data: [],
                error: err.data,
              });
            });
        });
      },
    },
  );
  return wf;
}
