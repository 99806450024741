import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, FormControl } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
type PropsStyle = {
  inputWidth: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  container: {
    '& .MuiAutocomplete-listbox': {
      padding: '0px !important',
    },
    width: (props: PropsStyle) => props.inputWidth || 'auto',
    '& .MuiAutocomplete-popupIndicator': {
      marginRight: '5px',
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        color: colors.icon.default,
      },
    },
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.surface.disable,
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
    },
    '& .MuiInputBase-root': {
      height: '32px',
      padding: '0px !important',
      paddingLeft: '5px !important',
    },
    marginLeft: '5px',
  },
  paper: {
    boxShadow: colors.shadow.hover,
  },
  icon: {
    paddingTop: '4px',
  },
  option: {
    backgroundColor: 'transparent',
    '&[aria-selected="true"]': {
      backgroundColor: '#EBF6FF',
      color: '#0369C7',
    },
    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
    '& .divider': {
      position: 'absolute',
      width: 'calc(100% + 35px)',
      backgroundColor: colors.border.default,
      height: '1px',
      bottom: '-7px',
      left: '-20px',
    },
  },
}));

interface SelectFormProps {
  options: OptionSelectType[];
  value: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  inputWidth?: string;
  placeholder?: string;
  className?: string;
}

const SelectSearchForm = ({
  options,
  value,
  onChange,
  disabled,
  inputWidth,
  placeholder,
  className,
}: SelectFormProps): JSX.Element => {
  const classes = useStyles({ inputWidth });
  return (
    <FormControl>
      <Autocomplete
        size="small"
        fullWidth
        multiple={false}
        disableClearable
        disableCloseOnSelect
        disabled={disabled}
        classes={{
          paper: classes.paper,
          option: classes.option,
        }}
        options={options}
        autoHighlight
        value={options.find((item) => item.value === value)}
        onChange={(event: unknown, newValue: OptionSelectType | null) => {
          onChange(newValue.value);
        }}
        className={`${classes.container} ${className}`}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => (
          <div style={{ position: 'relative', width: '100%' }}>
            {option.divider && <div className="divider"></div>}
            {option.label}
          </div>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        popupIcon={<Icon className={classes.icon} type="chevronMedium" size="10px" />}
      />
    </FormControl>
  );
};

export default SelectSearchForm;
