import { EIP_CONSTANT } from '@eip/next/lib/main';

export const COMPACT_AUDIENCE_CONFIG = {
  tableType: 'compact',
  title: 'Display location',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getSuggestedAudienceData',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  searchHint: 'Looking for audience',
  system: {
    hiddenComponents: ['timeline'],
  },
  primaryKeys: ['SUGGESTED_AUDIENCE.type'],
  mapping: {
    audience: {
      title: 'Audience',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SUGGESTED_AUDIENCE.name',
      valueGetter: {
        label: 'SUGGESTED_AUDIENCE.name',
        id: 'SUGGESTED_AUDIENCE.id',
        type: 'SUGGESTED_AUDIENCE.type',
      },
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'PRODUCT.name',
      valueGetter: {
        label: 'PRODUCT.name',
        subtext: 'PRODUCT.id',
        id: 'PRODUCT.name',
      },
    },

    // ============= ATTRIBUTE =============

    // ============= METRIC =============
  },
};
