import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TableView = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0003 2H3.00033C2.26699 2 1.66699 2.6 1.66699 3.33333V12.6667C1.66699 13.4 2.26699 14 3.00033 14H13.0003C13.7337 14 14.3337 13.4 14.3337 12.6667V3.33333C14.3337 2.6 13.7337 2 13.0003 2ZM13.0003 3.33333V5.33333H3.00033V3.33333H13.0003ZM9.66699 12.6667H6.33366V6.66667H9.66699V12.6667ZM3.00033 6.66667H5.00033V12.6667H3.00033V6.66667ZM11.0003 12.6667V6.66667H13.0003V12.6667H11.0003Z"
      fill="#2B3245"
    />
  </SvgIcon>
);

export default TableView;
