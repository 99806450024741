import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { IValueCurrencyFormat } from '@ep/insight-ui/elements/table/format/currency-format';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import { produce } from 'immer';
import { get } from 'lodash';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: colors.text.default,
    '& + &': {
      marginTop: '8px',
    },
    '&.audiences input::placeholder': {
      color: colors.text.subdued,
    },
  },
  form: {
    width: '100%',
  },
}));

type Props = {
  data: {
    value: IValueCurrencyFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const PremiumRateEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, anchorEl, setAnchorEl } = props;
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('');

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    const valueNumber = get(data, 'value.value', '');
    if (value != valueNumber) {
      setValue(String(valueNumber));
    }
  }, [data.value, anchorEl]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const valueNumber = get(data, 'value.value', '');
    if (value !== valueNumber) {
      setIsSubmitting(true);
      const response: any = await onSubmit({
        value: produce(data.value, (draft) => {
          draft.value = value;
        }),
        row: data.node.data,
      });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    } else {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px', width: '299px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <div className={clsx(classes.block, 'audiences')}>
          <Typography variant="body2">Increase bidding price by</Typography>
          <TextBudgetInput
            style={{ width: '110px' }}
            defaultValue={value}
            currency={'%'}
            onChangeText={(v) => setValue(v)}
            disabledDebounce
            placeholder={'Your value'}
            noSelectBox
          />
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default PremiumRateEditor;
