import * as React from 'react';

import { Grid, Button, makeStyles } from '@material-ui/core';

import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';

const useStyles = makeStyles({
  button: {
    height: '100%',
  },
  loadingIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ButtonActions = ({ isSubmitting, onCancel, onSubmit, configuration }: any) => {
  const classes = useStyles();

  const cancelLabel = (
    (configuration.form?.field_configuration || []).find((el) => el.key === 'cancel_label') || { value: '' }
  ).value;

  const submitLabel = (
    (configuration.form?.field_configuration || []).find((el) => el.key === 'submit_label') || { value: '' }
  ).value;

  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{ marginTop: '15px' }}>
      {isSubmitting && (
        <Grid item className={classes.loadingIcon}>
          <CircleLoading size={'18px'} />
        </Grid>
      )}
      <Grid item>
        {cancelLabel && (
          <Button className={classes.button} onClick={onCancel}>
            {cancelLabel}
          </Button>
        )}
      </Grid>
      <Grid item>
        <Button
          disabled={isSubmitting}
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSubmit}
        >
          {submitLabel || 'Submit'}
        </Button>
      </Grid>
    </Grid>
  );
};

export default ButtonActions;
