import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import { SnackbarOrigin, SnackbarProvider } from 'notistack';
import { useToast, IGlobalToast, NotificationContext } from './hook';

const useStyles = makeStyles((theme) => ({
  root: {
    pointerEvents: 'all',
  },
  container: {
    position: 'absolute',
  },
  rootGlobalSnackbar: {
    pointerEvents: 'all',
    marginTop: 16,
    marginBottom: 16,
  },
  providerContainer: {
    overflowY: 'auto',
  },
}));

type INotificationContainer = {
  children: React.ReactNode;
};
/* the parent wrapper of NotificationContainer must be have position:'relative' */
export const NotificationContainer = ({ children }: INotificationContainer) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      classes={{
        root: classes.root,
        containerRoot: classes.container,
      }}
    >
      <>{children}</>
    </SnackbarProvider>
  );
};
type INotificationGlobalProviderProps = {
  children: React.ReactNode;
  maxSnack?: number;
  anchorOrigin?: SnackbarOrigin;
};
export const NotificationGlobalProvider = ({
  children,
  maxSnack = 12,
  anchorOrigin = { horizontal: 'right', vertical: 'top' },
}: INotificationGlobalProviderProps) => {
  const [refSnackbar, setRefSnackbar] = React.useState<SnackbarProvider>();
  const classes = useStyles();
  const getGlobalToast = () => {
    let toast: IGlobalToast = { enqueueSnackbar: () => undefined, closeSnackbar: () => undefined };
    if (refSnackbar) {
      toast = { enqueueSnackbar: refSnackbar.enqueueSnackbar, closeSnackbar: refSnackbar.closeSnackbar };
    }
    return useToast(toast);
  };
  return (
    <NotificationContext.Provider value={getGlobalToast()}>
      <SnackbarProvider
        ref={(ref) => setRefSnackbar(ref)}
        classes={{
          root: classes.rootGlobalSnackbar,
          containerRoot: classes.providerContainer,
        }}
        maxSnack={maxSnack}
        anchorOrigin={anchorOrigin}
      >
        {children}
      </SnackbarProvider>
    </NotificationContext.Provider>
  );
};
