import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import EpsiloTheme from '@ep/insight-ui/lib/epsilo-theme';
import { Box, Grid, makeStyles, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { AREA_CONFIG } from './dashboard-config/area';
import { BILLING_CONFIG } from './dashboard-config/billing';
import { INTRADAY_CONFIG } from './dashboard-config/intraday';
import { LINE_CONFIG } from './dashboard-config/line';
import { DOT_CONFIG } from './dashboard-config/dot';
import { SCATTER_CONFIG } from './dashboard-config/scatter';
import { SUMMARY_CONFIG } from './dashboard-config/summary';
import { WATERFALL_CONFIG } from './dashboard-config/waterfall';
import { TREEMAP_CONFIG } from './dashboard-config/treemap';
import { GAUGE_CONFIG } from './dashboard-config/gauge';
import { makeDashboard, TypeEDashBoard } from './dashboard-container';
import { AREA_RES } from './mocks/area';
import { BILLING_RES } from './mocks/billing';
import { INTRADAY_RES } from './mocks/intraday';
import { LINE_RES } from './mocks/line';
import { DOT_RES } from './mocks/dot';
import { SCATTER_RES } from './mocks/scatter';
import { SUMMARY_RES } from './mocks/summary';
import { WATERFALL_RES } from './mocks/waterfall';
import { TREEMAP_RES } from './mocks/treemap';
import { GAUGE_RES } from './mocks/gauge';
import { METRIC_DATA_MOCK } from './mocks/metricDemoData';
import LineChartEDashboard from './edashboard-charts/line-chart';

export default {
  title: 'Elements / eDashboard',
};

const useStyles = makeStyles({
  tableWrapper: {
    width: '100%',
    // border: '1px solid #d0d0d0',
  },
});
export const DashboardSummary = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(SUMMARY_RES);
      },
    },
    configuration: SUMMARY_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

const mapping = {
  time_to_response: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'Time to response',
    valueGetter: {},
  },
  delivery_time: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'Delivery time',
    valueGetter: {},
  },
  time_to_win_search: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'Time to win search',
    valueGetter: {},
  },
  out_of_stock_sku: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'Out-of-stock SKU',
    valueGetter: {},
  },
  ad_wallet_runrate: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'Ad wallet runrate',
    valueGetter: {},
  },
  programmactic_script: {
    cellFormat: 'text',
    propertyType: 'operating_metric',
    title: 'CPC',
    valueGetter: {},
  },
  advertised_product: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Advertised product',
    valueGetter: {},
  },
  advertised_keyword: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Advertised keyword',
    valueGetter: {},
  },
  ad_selling_sku: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Ad selling SKU',
    valueGetter: {},
  },
  share_of_search: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Share of search',
    valueGetter: {},
  },
  time_saving: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Time saving',
    valueGetter: {},
  },
  cost_saving: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Cost saving',
    valueGetter: {},
  },
  brand_equity: {
    cellFormat: 'text',
    propertyType: 'leading_metric',
    title: 'Brand equity',
    valueGetter: {},
  },
  gmv: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'GMV',
    valueGetter: {},
  },
  roas: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'ROAS',
    valueGetter: {},
  },
  ad_gmv: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Ad GMV',
    valueGetter: {},
  },
  ad_spend: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Ad spend',
    valueGetter: {},
  },
  add_to_cart: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Add To Cart',
    valueGetter: {},
  },
  followers: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: ff.intraday_bug_may ? 'Followers' : 'followers',
    valueGetter: {},
  },
  cancellation_rate: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Cancellation rate',
    valueGetter: {},
  },
  average_product_rating: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Average product rating',
    valueGetter: {},
  },
  average_order_value: {
    cellFormat: 'text',
    propertyType: 'lagging_metric',
    title: 'Average order value',
    valueGetter: {},
  },
};

export const DashboardIntraday = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(INTRADAY_RES);
      },
    },
    tableType: TypeEDashBoard.INTRADAY,
    configuration: {
      ...INTRADAY_CONFIG,
      title: 'Summary',
      system: {
        hiddenComponents: ['majorButton', 'search', ...(ff.intraday_bug_may ? ['sort', 'export'] : [])],
      },
      settingType: [
        { type: 'operating_metric', label: 'OPERATING METRIC', allowConfig: true },
        { type: 'leading_metric', label: 'LEADING METRIC', allowConfig: true },
        { type: 'lagging_metric', label: 'LAGGING METRIC', allowConfig: true },
      ],
      dimension: [''],
      operating_metric: ['out_of_stock_sku', 'programmactic_script'],
      lagging_metric: [],
      leading_metric: [
        'advertised_product',
        'advertised_keyword',
        'ad_selling_sku',
        'share_of_search',
        'time_saving',
        'cost_saving',
      ],
      mapping: {
        ...INTRADAY_CONFIG.mapping,
        ...mapping,
      },
    },
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardBilling = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(BILLING_RES);
      },
    },
    configuration: BILLING_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardArea = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(AREA_RES);
      },
    },
    configuration: AREA_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardLine = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(LINE_RES);
      },
    },
    configuration: LINE_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardDot = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(DOT_RES);
      },
    },
    tableType: TypeEDashBoard.DOT,
    requestFilter: {
      currency: 'USD',
    },
    configuration: DOT_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardScatter = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(SCATTER_RES);
      },
    },
    tableType: TypeEDashBoard.SCATTER,
    requestFilter: {
      currency: 'USD',
    },
    configuration: SCATTER_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardWaterFall = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(WATERFALL_RES);
      },
    },
    tableType: TypeEDashBoard.WATERFALL,
    requestFilter: {
      currency: 'USD',
    },
    configuration: WATERFALL_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardTreeMap = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(TREEMAP_RES);
      },
    },
    tableType: TypeEDashBoard.TREEMAP,
    requestFilter: {
      currency: 'USD',
    },
    configuration: TREEMAP_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const DashboardGauge = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(GAUGE_RES);
      },
    },
    tableType: TypeEDashBoard.GAUGE,
    requestFilter: {
      currency: 'USD',
    },
    configuration: GAUGE_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeDashboard({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

export const MetricCharts = () => {
  console.log('INTRADAY_CONFIG', METRIC_DATA_MOCK);

  return (
    <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
      <LineChartEDashboard data={{ rows: METRIC_DATA_MOCK }} statusChart={'success'} />
    </Grid>
  );
};
