import * as React from 'react';
import { get, set, uniq } from 'lodash';

import { Button, TextField, makeStyles } from '@material-ui/core';

import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import { eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { SwitchIcon } from '@ep/insight-ui/elements/modal/switch';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 10px',
    width: '350px',
    '& h4': {
      margin: 0,
      marginBottom: '10px',
      marginTop: '8px',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input': {
      padding: '0 10px 0 14px',
      height: '32px',
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
    '& .eip1-MuiFormControl-root': {
      width: '100%',
    },
    '& .eip1-MuiSwitch-root .Mui-disabled+.eip1-MuiSwitch-track': {
      backgroundColor: '#006EC6aa',
    },
  },
  buttonGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: '8px',
    marginTop: '16px',
  },
  textEndAdornment: {
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#8C98A4',
    marginLeft: '10px',
    '& + .iconExpand': {
      marginLeft: '0px !important',
    },
  },
  label: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    '.horizontal &': {
      width: '30%',
    },
  },
  flexBetween: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    marginTop: '4px',
  },
  errorMessage: {
    color: '#d4290d',
    fontSize: '12px',
    marginTop: '4px',
  },
}));

const DISCOVERY_RESULT_PLACEMENT = 'DISCOVERY_RESULT_PLACEMENT';
const DAILY_DISCOVER = 'Daily Discover';
const YOU_MAY_ALSO_LIKE = 'You May Also Like';

const SwitchPlacementType = (props) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(true);
  const [requesting, setRequesting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [range, setRange] = React.useState({
    min: 0,
    max: Infinity,
  });
  const [existedKeywords, setExistedKeywords] = React.useState(null);

  const rowData = get(props, ['data', 'node', 'data'], {});
  const textEndAdornmentValue = get(rowData, ['storefront.currency'], '');
  const { onToastMultiple } = useToast();

  const backbone = React.useContext(TableBackboneContext);

  const gridApi = backbone.getGridApi ? backbone.getGridApi() : null;
  const groupBy = backbone.getConfig('groupBy');

  const [dd, setDd] = React.useState({
    id: null,
    bidding_price: 0,
    checked: true,
    name: DAILY_DISCOVER,
    status: 'DRAFT',
  });
  const [ymal, setYaml] = React.useState({
    id: null,
    bidding_price: 0,
    checked: true,
    name: YOU_MAY_ALSO_LIKE,
    status: 'DRAFT',
  });

  const getField = (field) => {
    const fieldValue = get(props, ['payload'], []).find((i) => i.key === field);
    if (!fieldValue) return null;
    return toValue(fieldValue.value, rowData);
  };

  const getSelectedField = (field) => {
    const fieldValue = get(props, ['payload'], []).find((i) => i.key === field);
    if (!fieldValue) return [];
    return uniq(
      backbone
        .getSelectedRows()
        .concat(rowData)
        .map((i) => toValue(fieldValue.value, i)),
    );
  };

  const handleClose = () => {
    if (props.setAnchorEl) props.setAnchorEl(null);
  };

  const composeCreatePayload2 = (formData, keywordData) => {
    return {
      ads_unit_type: 'AdsPlacement',
      attributes: ['storefront_id', 'tool_code', 'marketplace_code', 'country_code']
        .map((i) => ({
          field_name: i,
          value: getField(i),
        }))
        .concat(
          {
            field_name: 'parent_id',
            value: String(keywordData['ads_objects.id']),
          },
          {
            field_name: 'status',
            value: keywordData['ads_objects.status'] == 'DRAFT' ? 'DRAFT' : formData.checked ? 'ONGOING' : 'SUSPENDED',
          },
          {
            field_name: 'bidding_price',
            value: String(formData.bidding_price),
          },
          {
            field_name: 'name',
            value: formData.name,
          },
          {
            field_name: 'type',
            value: DISCOVERY_RESULT_PLACEMENT,
          },
        ),
    };
  };

  const composeUpdatePayload2 = (formData, keywordData) => {
    return {
      ads_unit_type: 'AdsPlacement',
      attributes: [
        {
          field_name: 'status',
          value: keywordData['ads_objects.status'] == 'DRAFT' ? 'DRAFT' : formData.checked ? 'ONGOING' : 'SUSPENDED',
        },
        {
          field_name: 'bidding_price',
          value: String(formData.bidding_price),
        },
      ],
      id: keywordData['ads_placements.id'],
    };
  };

  const loadExistedKeywords = async () => {
    const parentId = getSelectedField('parent_id');
    const queryDiscoveryKeysParams = {
      dimensions: ['ads_placements', 'ads_objects'],
      attributes: [
        'ads_placements.id',
        'ads_placements.name',
        'ads_placements.bidding_price',
        'ads_placements.status',
        'ads_objects.id',
        'ads_objects.status',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: 100,
      },
      from: '2024-03-09',
      to: '2024-03-24',
      isSummary: true,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'ads_objects.id',
            operator: 'IN',
            value: parentId,
          },
          {
            field: 'ads_placements.type',
            operator: 'is',
            value: 'DISCOVERY_RESULT_PLACEMENT',
          },
        ],
      },
    };

    return new Promise(async (resolve) => {
      try {
        const response = await eipRequest.post(
          EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=automation',
          queryDiscoveryKeysParams,
        );
        resolve(response);
      } catch (e) {
        resolve({
          success: false,
          data: {
            headers: [],
            rows: [],
          },
          code: 400,
        });
      }
    });
  };

  const getFilterDefinition = async () => {
    return new Promise(async (resolve) => {
      try {
        const queryParam = {
          exactMatch: 'false',
          'searchBy.definitionMethod': 'ATTRIBUTE_VALIDATION',
          'searchBy.attributeName': 'bidding_price',
          'searchBy.countryCode': getField('country_code'),
          'searchBy.marketplaceCode': getField('marketplace_code'),
          'searchBy.toolCode': getField('tool_code'),
          'searchBy.enable': 'true',
          'searchBy.key': DISCOVERY_RESULT_PLACEMENT,
        };
        const response = await eipRequest.get(
          EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY +
            '/campaign-management/filter-definition?' +
            Object.entries(queryParam)
              .map(([k, v]) => `${k}=${v}`)
              .join('&'),
        );
        resolve(response);
      } catch (e) {
        resolve({
          success: false,
          data: [],
          code: 400,
        });
      }
    });
  };

  const disabledSubmit =
    requesting ||
    dd.bidding_price < range.min ||
    dd.bidding_price > range.max ||
    ymal.bidding_price < range.min ||
    ymal.bidding_price > range.max ||
    range.min == 0;

  const handleSubmit = async () => {
    setRequesting(true);
    setErrorMessage('');
    const type = getField('type');

    const createPayload = [];
    const updatePayload = [];

    uniq(backbone.getSelectedRows().concat(rowData)).forEach((row) => {
      const objectId = row['ads_objects.id'];
      const dailyDiscover = get(existedKeywords, [objectId, DAILY_DISCOVER], null);
      if (dailyDiscover) {
        updatePayload.push(composeUpdatePayload2(dd, dailyDiscover));
      } else {
        createPayload.push(composeCreatePayload2(dd, row));
      }
      const ymalData = get(existedKeywords, [objectId, YOU_MAY_ALSO_LIKE], null);
      if (ymalData) {
        updatePayload.push(composeUpdatePayload2(ymal, ymalData));
      } else {
        createPayload.push(composeCreatePayload2(ymal, row));
      }
    });

    const adsPlacementRequest = [];
    if (createPayload.length) {
      adsPlacementRequest.push(
        eipRequest.post(EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/campaign-management/mass-create-ads-unit', {
          data: createPayload,
        }),
      );
    }
    if (updatePayload.length) {
      adsPlacementRequest.push(
        eipRequest.post(EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/campaign-management/mass-upsert-ads-unit', {
          data: updatePayload,
        }),
      );
    }

    try {
      const [response, response3] = await Promise.all(adsPlacementRequest);
      if (
        response.success ||
        Number(response?.successfulElement) > 0 ||
        response3?.success ||
        Number(response3?.successfulElement || '0') > 0
      ) {
        const response2 = await eipRequest.post(
          EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/campaign-management/mass-upsert-ads-unit',
          {
            data: uniq(
              get(response, ['data'], [])
                .concat(get(response3, ['data'], []))
                .filter((i) => i.success && get(i, ['data', 'parentId']))
                .map((i) => {
                  return {
                    ads_unit_type: 'AdsObject',
                    attributes: [
                      {
                        field_name: 'placement_type',
                        value: type,
                      },
                    ],
                    id: get(i, ['data', 'parentId'], ''),
                  };
                }),
            ),
          },
        );
        if (response2.success == false) {
          const errData = get(response2, ['data'], []).find((i) => i.success == false);
          if (errData) {
            const errMsg = Object.values(errData.error).find((i) => !!i);
            setErrorMessage(errMsg);
          }
        } else {
          onToastMultiple({
            title: 'Update placement type successfully',
            messages: [],
            variant: 'success',
          });
          handleReloadTable();
          handleClose();
        }
      } else {
        const errData = get(response, ['data'], [])
          .concat(get(response3, ['data'], []))
          .find((i) => i.success == false);
        if (errData) {
          const errMsg = Object.values(errData.error).find((i) => !!i) || errData?.message || 'Something went wrong!';
          setErrorMessage(errMsg);
        }
      }
    } catch (e) {}

    setRequesting(false);
  };

  const handleReloadTable = () => {
    if (gridApi) {
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.length > 0) {
        const expanded = [];
        gridApi.forEachNode((node) => {
          if (node.expanded) {
            expanded.push(node);
          }
        });
        expanded.forEach((el) => {
          gridApi.refreshServerSideStore({ route: [el.key] });
        });
      } else {
        gridApi.refreshServerSideStore();
      }
      gridApi.deselectAll();
    }
  };

  React.useEffect(() => {
    Promise.all([loadExistedKeywords(), getFilterDefinition()])
      .then(([keywordResponse, definitionResponse]) => {
        let min, max;
        if (get(definitionResponse, ['data', 'length'], 0) > 0) {
          const newRange = get(definitionResponse, ['data', '0', 'value'], '').split(',').slice(2, 4);
          (min = Number(newRange[0]) || range.min),
            (max = Number(newRange[1]) || range.max),
            setRange({
              min,
              max,
            });
        }
        set(keywordResponse, ['data', 'masterData'], get(keywordResponse, ['masterData'], {}));
        set(keywordResponse, ['data', 'masterDataPrimaryKey'], get(keywordResponse, ['masterDataPrimaryKey'], {}));
        produceQueryResult(keywordResponse, {});

        const selectedKeywordResponse = get(keywordResponse, ['rows'], []).filter(
          (i) => i['ads_objects.id'] == getField('parent_id'),
        );
        const selectedKeywordResponseObj = get(keywordResponse, ['rows'], []).reduce((carry, v) => {
          if (!carry[v['ads_objects.id']]) {
            carry[v['ads_objects.id']] = {
              [v['ads_placements.name']]: v,
            };
          } else {
            carry[v['ads_objects.id']][v['ads_placements.name']] = v;
          }
          return carry;
        }, {});
        setExistedKeywords(selectedKeywordResponseObj);
        if (selectedKeywordResponse.length > 0) {
          const rows = selectedKeywordResponse.reduce((carry, row) => {
            const rowObj = Object.entries(row).reduce((subCarry, [k, v]) => {
              if (!k.startsWith('ads_placements')) return subCarry;
              return {
                ...subCarry,
                [k.split('.')[1]]: v,
              };
            }, {});
            return {
              ...carry,
              [rowObj['name']]: rowObj,
            };
          }, {});

          if (rows[DAILY_DISCOVER]) {
            setDd((prevValue) => ({
              ...prevValue,
              ...rows[DAILY_DISCOVER],
              checked: rows[DAILY_DISCOVER]['status'] != 'SUSPENDED',
            }));
          }

          if (rows[YOU_MAY_ALSO_LIKE]) {
            setYaml((prevValue) => ({
              ...prevValue,
              ...rows[YOU_MAY_ALSO_LIKE],
              checked: rows[YOU_MAY_ALSO_LIKE]['status'] != 'SUSPENDED',
            }));
          }
        } else {
          setDd((prevValue) => ({
            ...prevValue,
            bidding_price: min,
          }));

          setYaml((prevValue) => ({
            ...prevValue,
            bidding_price: min,
          }));
        }
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className={classes.container}>
      <h4>Manage Discovery Locations</h4>
      <LoadingComponent loading={loading}>
        <div className={classes.flexColumn}>
          <div className={classes.flexColumn}>
            <div className={classes.flexBetween}>
              <span className={classes.label}>{dd.name}</span>
              <SwitchIcon
                checked={dd.checked}
                onChange={(e) => {
                  e.persist();
                  setDd((prevValue) => ({
                    ...prevValue,
                    checked: e.target?.checked,
                  }));
                }}
                disabled={!ymal.checked}
                isCustom
              />
            </div>
            <div>
              <TextField
                type={'number'}
                variant={'outlined'}
                value={dd.bidding_price}
                placeholder={'Enter bidding price'}
                onChange={(e) => {
                  e.persist();
                  setDd((prevValue) => ({
                    ...prevValue,
                    bidding_price: e.target.value,
                  }));
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {textEndAdornmentValue ? (
                        <span className={classes.textEndAdornment}>{textEndAdornmentValue}</span>
                      ) : null}
                    </>
                  ),
                }}
              />
              <div className={classes.subtext}>
                The bidding price must be between {range.min} and {range.max}.
              </div>
            </div>
          </div>
          <div className={classes.flexColumn}>
            <div className={classes.flexBetween}>
              <span className={classes.label}>{ymal.name}</span>
              <SwitchIcon
                checked={ymal.checked}
                onChange={(e) => {
                  e.persist();
                  setYaml((prevValue) => ({
                    ...prevValue,
                    checked: e.target?.checked,
                  }));
                }}
                disabled={!dd.checked}
                isCustom
              />
            </div>
            <div>
              <TextField
                type={'number'}
                variant={'outlined'}
                value={ymal.bidding_price}
                placeholder={'Enter bidding price'}
                onChange={(e) => {
                  e.persist();
                  setYaml((prevValue) => ({
                    ...prevValue,
                    bidding_price: e.target.value,
                  }));
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      {textEndAdornmentValue ? (
                        <span className={classes.textEndAdornment}>{textEndAdornmentValue}</span>
                      ) : null}
                    </>
                  ),
                }}
              />
              <div className={classes.subtext}>
                The bidding price must be between {range.min} and {range.max}.
              </div>
            </div>
          </div>
        </div>
      </LoadingComponent>
      {errorMessage ? <div className={classes.errorMessage}>{errorMessage}</div> : null}
      <div className={classes.buttonGroup}>
        <Button variant={'text'} onClick={handleClose}>
          Cancel
        </Button>
        <Button variant={'contained'} color={'primary'} onClick={handleSubmit} disabled={disabledSubmit}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

export default SwitchPlacementType;
