import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import * as uuid from 'uuid';
import { IconType } from '@ep/insight-ui/icons/Icon';
import { IOnClickItem, SimpleTab, TypeSimpleTab } from './simple-tab';
import { StyleTitleListItem } from './list-item-style';
import TreeView from '@material-ui/lab/TreeView';
import { Box, Tooltip } from '@material-ui/core';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { useHistory, useLocation } from 'react-router-dom';
/**
 * ff.clone_page:start
 */
import { aim, EIP_CONSTANT, usePage } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
/**
 * ff.clone_page:end
 */

/**
 * ff.dnd_sidebar_menu:start
 */
import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';

import DraggableMenu from './draggable-menu';
/**
 * ff.dnd_sidebar_menu:end
 */

const useStyles = makeStyles((theme) => ({
  label: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#8C98A4',
    ...noSelectStyled,
  },
  icon: {
    width: 12,
    height: 12,
    margin: 5,
    marginLeft: 0,
    marginRight: 12,
  },
  iconExpand: {
    width: 8,
    height: 8,
    marginLeft: 1,
  },
  treeView: {
    width: '100%',
    paddingLeft: '2px',
  },
  titleListItem: {
    position: 'relative',
    '& .dropdown-action': {
      position: 'absolute',
      right: 0,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 10,
      display: 'none',
    },
    '&:hover .dropdown-action': {
      display: 'block',
    },
  },
}));

/**
 * ff.dnd_sidebar_menu:start
 */

import TabListPopover, { TabListType } from '@ep/insight-ui/elements/app-menu/tab-list-popover';
import { PageAtoms } from '@ep/insight-ui/system/backbone/atom/page';
import { useAtomValue } from 'jotai';
import { usePassport } from './hooks/use-passport';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { get } from 'lodash';

const useLabelInputStyles = makeStyles((theme) => ({
  labelInput: {
    background: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    textTransform: 'uppercase',
    color: '#8C98A4',
    fontFamily: 'Roboto',
    width: '100%',
    cursor: 'pointer',
    '&:focus-visible': {
      outline: 'none',
      cursor: 'text',
    },
  },
}));

/**
 * ff.dnd_sidebar_menu:end
 */

export type TypeTabListItem = {
  label: string;
  nodeId?: string;
  icon?: IconType;
  iconRight?: IconType;
  disable?: boolean;
  tabs?: TypeSimpleTab[];
  type?: string;
  selected?: boolean;
  indeterminate?: boolean;
};

export const LIST_EXPANDED_DEFAULT = 'listExpandedDefault';
const LIST_COLLAPSED_DEFAULT = 'listCollapsedDefault';
const defaultSelectedLeftMenu = '34c31cc3-0570-4aa9-bf69-d799a1e7f065';
type PropsTabList = {
  data: Array<TypeTabListItem>;
  onItemClick: IOnClickItem;
  useDnd?: boolean;
  updateSidebar?: any;
  appMenuListRef?: any;
  addNewSidebarSection?: any;
  deleteSidebarSection?: any;
  customDataMenu?: {
    title: string;
    onClick: () => void;
    icon: string;
    disable: boolean;
  }[][];
  setAppMenuList: (appMenuList: any) => void;
  cloneFeature: (blockEid: string, nodeId: string) => Promise<any>;
  homepageId?: string;
  setHomepageId?: React.Dispatch<React.SetStateAction<string>>;
  favorites?: { nodeId: string }[];
  updateFavorites?: (favorites: { nodeId: string }[]) => void;
  additionalBlockInfo?: any;
  customActions?: any[];
};

const SectionComponent = ({
  useDnd,
  classes,
  idx,
  item,
  sectionActions,
  appMenuListRef,
  updateSidebar,
  listCollapsed,
  setListCollapsed,
  setAppMenuList,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverType, setPopoverType] = React.useState<TabListType>(null);
  const dropdownRef = React.useRef(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const enhanceSectionActions = React.useMemo(() => {
    if (aim.isSuperAdmin()) {
      return [
        [
          ...sectionActions[0],
          {
            title: 'Rename',
            onClick(blockEid: string) {
              setPopoverType(TabListType.CHANGE_NAME);
              setAnchorEl(dropdownRef.current);
            },
            icon: 'edit',
            disable: false,
          },
          {
            title: 'Change permission',
            onClick(blockEid: string) {
              setPopoverType(TabListType.CHANGE_PERMISSION);
              setAnchorEl(dropdownRef.current);
            },
            icon: 'user',
            disable: false,
          },
        ],
      ];
    } else {
      return sectionActions;
    }
  }, [sectionActions]);

  const onSectionClick = () => {
    const listTemp = [...listCollapsed];
    const index = listTemp.findIndex((nodeId) => nodeId == item.nodeId);

    if (index !== -1) {
      listTemp.splice(index, 1);
    } else {
      listTemp.push(item.nodeId);
    }
    localStorage.setItem(LIST_COLLAPSED_DEFAULT, JSON.stringify(listTemp));
    setListCollapsed(listTemp);
  };

  const productLabelColor = getConst('PRODUCT_LABEL_COLOR', '#000');

  return (
    <StyleTitleListItem className={classes.titleListItem}>
      <div ref={dropdownRef} style={{ width: '100%' }}>
        {useDnd ? (
          <>
            <Tooltip
              title={`Click to ${listCollapsed.includes(item.nodeId) ? 'show' : 'hide'} section`}
              placement="top-start"
            >
              <span
                onClick={onSectionClick}
                style={{ cursor: 'pointer', color: productLabelColor }}
                className={`${classes.label}`}
              >
                {item.label}
              </span>
            </Tooltip>
            {get(enhanceSectionActions, [0], []).length ? (
              <div className={'dropdown-action'}>
                <Dropdown
                  alignMenu="right"
                  icon="threeDotsVertical"
                  disabledToggleStyle
                  label=""
                  sizeIcon={'12px'}
                  dataMenuPage={enhanceSectionActions}
                >
                  <MenuList listMenu={enhanceSectionActions} customArg={{ index: idx }} closeAfterClick />
                </Dropdown>
              </div>
            ) : null}
          </>
        ) : (
          <Tooltip
            title={`Click to ${listCollapsed.includes(item.nodeId) ? 'show' : 'hide'} section`}
            placement="top-start"
          >
            <span
              className={`${classes.label}`}
              onClick={onSectionClick}
              style={{ cursor: 'pointer', color: productLabelColor }}
            >
              {item.label}
            </span>
          </Tooltip>
        )}
      </div>
      <TabListPopover
        anchorEl={anchorEl}
        handleClose={handleClose}
        type={popoverType}
        parentNodeIds={null}
        nodeId={item.nodeId}
        updateSidebar={updateSidebar}
        appMenuListRef={appMenuListRef}
        label={item.label}
        setAppMenuList={setAppMenuList}
        menuType={'product'}
      />
    </StyleTitleListItem>
  );
};

export const TabList = ({
  data,
  onItemClick,
  useDnd = false,
  updateSidebar,
  appMenuListRef,
  addNewSidebarSection,
  deleteSidebarSection,
  customDataMenu,
  setAppMenuList,
  cloneFeature,
  homepageId,
  setHomepageId,
  favorites,
  updateFavorites,
  additionalBlockInfo,
  customActions,
}: PropsTabList) => {
  const classes = useStyles();

  const listExpandedDefault = localStorage.getItem(LIST_EXPANDED_DEFAULT);
  const listCollapsedDefault = localStorage.getItem(LIST_COLLAPSED_DEFAULT);
  const pageFn = useAtomValue(PageAtoms.fn);

  const history = useHistory();
  const location = history.location;
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q, v } = queryParams;

  const selectedTab = React.useMemo(() => {
    const matched = location.pathname.match(/page\/.+/)?.[0];
    const detailPageIds = ['afdd53e6-49f9-4170-9212-bdeada713c0d', 'ec22dd25-a14f-40d8-9199-a5b0517e835e'];
    const selectedPageId = matched ? matched.split('/')[1] : defaultSelectedLeftMenu;
    const entityPage = matched ? matched.split('/')[2] : 'default';
    if (q) {
      return q.split(',').concat(q && v ? q + '|' + v : []);
    }
    if (!detailPageIds.includes(selectedPageId) || entityPage === 'default') return [selectedPageId];
    return matched.split('/').slice(1).join('/') + location.search;
  }, [location, q]);

  const [listExpanded, setListExpanded] = React.useState<string[]>(
    listExpandedDefault ? JSON.parse(listExpandedDefault) : [],
  );
  const [sectionsExpanded, setSectionsExpanded] = React.useState([]);
  const [listCollapsed, setListCollapsed] = React.useState<string[]>(
    listCollapsedDefault ? JSON.parse(listCollapsedDefault) : [],
  );

  const { clonePage, removePage, systemPageId, loadPageList } = usePage();

  const NEW_BOTEP_ID = getConst('NEW_BOTEP_ID', 'botep');

  let updateFeature;
  if (ff.next_left_menu) {
    const hookResponse = usePassport();
    updateFeature = hookResponse.updateFeature;
  }

  const dataMenu = React.useMemo(() => {
    if (customDataMenu) {
      return customDataMenu;
    }

    const menu = [];

    const menu1 = [
      {
        title: 'Copy link',
        onClick(blockEid: string) {
          const cb = navigator.clipboard;
          cb.writeText(window.location.origin + getPageUrn('/page/' + blockEid));
        },
        icon: 'copyLinkView',
      },
      // {
      //   title: 'Move to',
      //   onClick() {
      //     return undefined;
      //   },
      //   icon: 'moveto',
      //   disable: true,
      // },
      {
        title: 'Open new tab',
        onClick(blockEid: string) {
          window.open(getPageUrn('/page/' + blockEid), '_blank');
        },
        icon: 'addlink',
        disable: false,
      },
      ...(aim.isSuperAdmin()
        ? [
            {
              title: 'Duplicate to Botep',
              onClick(blockEid: string) {
                const cloneId = uuid.v4();
                clonePage(blockEid, cloneId, NEW_BOTEP_ID).then(async () => {
                  try {
                    await cloneFeature(blockEid, cloneId);
                  } catch {}
                  window.window.location.href = getPageUrn('/page/' + cloneId);
                });
                return undefined;
              },
              icon: 'addduplicate',
              disable: false,
            },
          ]
        : []),
    ];

    if (aim.canAccess('page:syncTo')) {
      menu1.push({
        title: 'Sync to ' + (process.env.EIP_BUILD_ENV === 'staging' ? 'production' : 'staging'),
        onClick(blockEid: string) {
          const aggree = window.confirm('Are you sure?');
          // get the current url domain
          const target = process.env.EIP_BUILD_ENV === 'staging' ? 'production' : 'staging';

          if (aggree) {
            pageFn.syncPage(target, blockEid).then(() => {
              if (target === 'production') {
                window.open('https://one.epsilo.io' + getPageUrn('/page/' + blockEid), '_blank', 'noopener,noreferrer');
              } else {
                window.open('https://to.epsilo.io' + getPageUrn('/page/' + blockEid), '_blank', 'noopener,noreferrer');
              }
            });
          }
        },
        icon: 'upload',
      });
    }
    if (aim.canAccess('page:remove')) {
      menu1.push({
        title: 'Remove',
        onClick(blockEid: string) {
          const aggree = window.confirm('Are you sure?');
          if (aggree) {
            if (ff.next_left_menu) {
              updateFeature(blockEid, { status: 'inactive' });
            }
            removePage(blockEid)
              .then(() => {
                if (systemPageId === blockEid) {
                  window.location.href = getPageUrn('/page');
                } else {
                  window.location.reload();
                }
              })
              .then(() => loadPageList());
          }
        },
        icon: 'close',
        disable: ff.clone_page ? !aim.isSuperAdmin() : true,
      });
    }

    menu.push(menu1);
    return menu;
  }, [customDataMenu]);

  const sectionActions =
    ff.next_left_menu || !aim.isSuperAdmin()
      ? [[]]
      : [
          [
            {
              title: 'Add new section',
              onClick({ index }) {
                addNewSidebarSection(index);
              },
              icon: 'add',
            },
            {
              title: 'Remove this section',
              onClick({ index }) {
                if (window.confirm('Are you sure?')) deleteSidebarSection(index);
              },
              icon: 'delete',
            },
          ],
        ];

  return (
    <TreeView className={classes.treeView} expanded={listExpanded.concat(sectionsExpanded)} selected={selectedTab}>
      {data.map((item, idx) => (
        <React.Fragment key={`TabListItem_${item.nodeId}`}>
          <ConditionalWrap
            condition={useDnd && (!item.label || !ff.next_left_menu)}
            wrap={(children) => (
              <DraggableMenu
                nodeId={item.nodeId || item.label}
                parentNodeIds={item.nodeId ? [item.nodeId] : []}
                showTopLine={true}
                listExpanded={listExpanded}
                appMenuListRef={appMenuListRef}
                updateSidebar={updateSidebar}
              >
                {children}
              </DraggableMenu>
            )}
          >
            {item.label && (
              <SectionComponent
                useDnd={useDnd}
                classes={classes}
                idx={idx}
                item={item}
                sectionActions={sectionActions}
                appMenuListRef={appMenuListRef}
                updateSidebar={updateSidebar}
                listCollapsed={listCollapsed}
                setListCollapsed={setListCollapsed}
                setAppMenuList={setAppMenuList}
              />
            )}
            {!listCollapsed.includes(item.nodeId)
              ? item.tabs.map((tab, index) => (
                  <ConditionalWrap
                    key={tab.nodeId ? tab.nodeId : `GroupTabs_SimpleTab_${index}`}
                    condition={useDnd}
                    wrap={(children) => (
                      <DraggableMenu
                        nodeId={tab.nodeId || tab.label}
                        parentNodeIds={item.nodeId ? [item.nodeId] : []}
                        showTopLine={index === 0}
                        listExpanded={listExpanded}
                        appMenuListRef={appMenuListRef}
                        updateSidebar={updateSidebar}
                      >
                        {children}
                      </DraggableMenu>
                    )}
                  >
                    <SimpleTab
                      listExpanded={listExpanded}
                      setListExpanded={setListExpanded}
                      nodeId={`${item.nodeId ?? idx}_${tab.nodeId ?? `${tab.label}_${index}`}`}
                      {...tab}
                      nodeDetails={tab}
                      onItemClick={onItemClick}
                      dataMenu={
                        tab.nodeId === EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.campaignManagement
                          ? [
                              [
                                {
                                  title: 'Create new campaign',
                                  onClick: () => window.open(`/page/afdd53e6-49f9-4170-9212-bdeada713c0d`),
                                  icon: 'plus',
                                  disable: false,
                                },
                              ].concat(dataMenu[0]),
                            ]
                          : dataMenu
                      }
                      useDnd={useDnd}
                      index={index}
                      parentNodeIds={item.nodeId ? [item.nodeId] : []}
                      updateSidebar={updateSidebar}
                      appMenuListRef={appMenuListRef}
                      level={1}
                      setAppMenuList={setAppMenuList}
                      homepageId={homepageId}
                      setHomepageId={setHomepageId}
                      favorites={favorites}
                      updateFavorites={updateFavorites}
                      additionalBlockInfo={additionalBlockInfo}
                      customActions={customActions}
                      setSectionsExpanded={setSectionsExpanded}
                      sectionsExpanded={sectionsExpanded}
                    />
                  </ConditionalWrap>
                ))
              : null}
          </ConditionalWrap>
          <Box mb={item.tabs && item.tabs.length > 0 ? '8px' : '0px'} />
        </React.Fragment>
      ))}
    </TreeView>
  );
};
