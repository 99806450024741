import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import SelectForm from '../form-control/select-form/select-from-drag';
const useStyles = makeStyles((theme) => ({
  selectBox: {
    display: 'inline-flex',
    width: 'fit-content',
    fontWeight: 'bold',
    height: '32px',
    '& .eip1-MuiSelect-select': {
      minWidth: '24px',
      borderRadius: '4px',
      padding: '0px 25px 0px 5px',
      height: '100%',
      backgroundColor: `#F6F7F8`,
      color: '#253746',
      '& .eip1-MuiBox-root': {
        alignItems: 'center',
      },
    },
    // '& .eip1-MuiSelect-select[aria-expanded="true"]': {
    //   backgroundColor: `#006EC6`,
    //   color: 'white',
    // },
    '&:hover': {
      '& .eip1-MuiSelect-select': {
        backgroundColor: `${colors.action.subtleHovered}`,
      },
    },
  },
  selectFormControl: {},
  groupButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

export interface dropdownViewOption {
  id: string;
  name: string;
}

const SectionDropdownView = ({
  options,
  selected,
}: {
  options: dropdownViewOption[];
  selected: dropdownViewOption;
}) => {
  const classes = useStyles();
  const optionSelectView = React.useMemo(() => {
    if (options.length > 0) return options.map((item) => ({ label: item.name, value: item.id }));
    return [];
  }, [options]);

  const handleChangeSelectView = (e) => {
    if (e.target.value) {
      const find = options.find((item) => item.id === e.target.value);
    }
  };

  return (
    <React.Fragment>
      <SelectForm
        draggable={true}
        classNameForm={classes.selectFormControl}
        options={optionSelectView}
        value={selected.id}
        className={classes.selectBox}
        onChange={handleChangeSelectView}
      />
    </React.Fragment>
  );
};
export default SectionDropdownView;
