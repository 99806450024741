import * as React from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ModuleRegistry } from '@ag-grid-community/core';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { EtableConfigContext } from '../context';

import { Box, Button, Dialog, DialogContent, makeStyles, TextField, Popover, List, ListItem } from '@material-ui/core';

import EmptyRows from '@ep/insight-ui/elements/table/format/no-rows-data-format';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

import { useButtonEtableConfig } from '../hooks/use-button-etable-config';
import PopoverComponent from '../popover-components';
import { FormulaEditor } from '../formula-editor';

LicenseManager.setLicenseKey(process.env.AGGRID_LICENSE || process.env.STORYBOOK_AGGRID_LICENSE);
ModuleRegistry.registerModules([ClientSideRowModelModule]);

const useStyles = makeStyles({
  toggle: {
    marginLeft: 0,
    '&.Mui-expanded': {
      marginLeft: '0!important',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    rowGap: '16px',
    '& .ag-cell-value': {
      alignItems: 'center',
      padding: '0 8px',
    },
    '& .ag-cell-value .ag-react-container': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '& .ag-cell-value.ag-cell-focus': {
      background: '#ebf6ff',
    },
    '&.ag-theme-alpine .ag-ltr .ag-row-drag': {
      marginRight: 0,
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '1px solid #f8fafd',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '& .cell-checkbox .ag-cell-value': {
      padding: 0,
    },
    '& .header-checkbox .ag-header-select-all': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::before': {
      backgroundColor: '#FFF',
    },
    '& .ag-row': {
      cursor: 'grabbing',
    },
  },
  headerSection: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  headerGroup: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '8px',
    },
  },
  headerGroupTitle: {
    fontSize: 14,
    fontWeight: 500,
  },
  headerInput: {
    flex: '0 0 100%',
    '& > div': {
      width: '100%',
    },
  },
  closeButton: {},
  settingExpansion: {
    width: '100%',
  },
  recommendEndpoint: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  listItem: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
    borderRadius: '4px',
  },
});

interface IPropsButtonEtableConfig {
  open: boolean;
  config: any;
  onClose: any;
  onSubmit: any;
  submitting?: boolean;
}

const ButtonEtableConfig = ({ open, config, onClose, onSubmit, submitting }: IPropsButtonEtableConfig) => {
  const classes = useStyles();

  const {
    tableDOM,
    setGridApi,
    gridApi,
    handleRowChange,
    isEmpty,
    defaultColDef,
    columnDefs,
    rowData,
    handleSubmit,
    actionButtons,
    etableConfigContext,
  } = useButtonEtableConfig({
    config,
    onSubmit,
    open,
  });

  const actionButtonsRender = actionButtons.map((btn) => {
    if (btn.popoverComponent) {
      return (
        <PopoverComponent
          ChildComponent={btn.popoverComponent}
          title={btn.title}
          eTableEndpoint={null}
          gridApi={gridApi}
          params={btn.params}
        />
      );
    }
    return (
      <Button
        key={btn.id}
        variant="contained"
        color="secondary"
        onClick={(evt) => btn.onClick({ event: evt, gridApi: gridApi })}
      >
        {btn.title}
      </Button>
    );
  });

  return (
    <EtableConfigContext.Provider value={etableConfigContext}>
      <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
        <DialogContent className={classes.container}>
          <Box className={`ag-theme-alpine body-container ${classes.container}`} ref={tableDOM}>
            <AgGridReact
              suppressCellFocus={true}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              suppressNoRowsOverlay={true}
              onGridReady={(params) => {
                setGridApi(params.api);
                handleRowChange(params.api);
              }}
              onRowDataUpdated={(params) => {
                handleRowChange(params.api);
              }}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              rowDragManaged
              rowDragEntireRow
              suppressMoveWhenRowDragging
            />
          </Box>
          {isEmpty && tableDOM.current && (
            <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={40} />
          )}
          <Box className={classes.actions}>
            <Box className={classes.actionGroup}>{actionButtonsRender}</Box>
            <Box className={classes.actionGroup}>
              <Button variant="contained" color="secondary" onClick={onClose}>
                Close
              </Button>
              <Button variant="contained" color="primary" onClick={handleSubmit} disabled={submitting}>
                Submit
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      <FormulaEditor />
    </EtableConfigContext.Provider>
  );
};

export default ButtonEtableConfig;
