import { get, isEqual } from 'lodash';
import * as React from 'react';

import {
  Box,
  Button, List, makeStyles, Popover, Typography
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import Icon from '@ep/insight-ui/icons/Icon';
import { searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import MenuItem from './menu-item';

const useStyles = makeStyles(() => ({
  container: {},
  popover: {
    minWidth: '247px',
  },
  dropdownBtn: (props) => ({
    background: props.isDarkMode ? '#253645' : '#F6F7F8',
    borderRadius: '4px',
    height: '32px',
    paddingRight: '6px',
    '& .eip1-MuiButton-label': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      columnGap: '6px',
    },
    ...(props.isDarkMode
      ? {
          '& .iconDropDown': {
            color: '#FFFFFF',
          },
          '& .viewLabel': {
            color: '#FFFFFF',
          },
          '&:hover': {
            '& .iconDropDown': {
              color: '#253746',
            },
            '& .viewLabel': {
              color: '#253746',
            },
          },
        }
      : {}),
  }),
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
    '& input::placeholder': {
      textTransform: 'unset',
    },
  },
  textSearch: {
    '& input': {
      height: 30,
      width: '100%',
      paddingRight: '22px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    color: '#8C98A4',
    padding: '8px 0',
  },
  divider: {
    margin: '8px 0',
  },
  newViewBtn: {
    width: '100%',
    height: '32px',
    '& .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      fontWeight: 'normal',
      justifyContent: 'flex-start',
    },
  },
  listViews: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    maxHeight: '232px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  tooltip: {
    maxWidth: '245px',
    marginLeft: '15px',
    padding: '0px',
    '& p': {
      fontSize: '11px',
      marginBottom: '0px',
      lineHeight: '15px',
      padding: '8px',
    },
  },
  threeDots: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchField: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
  },
  viewLabel: {
    whiteSpace: 'nowrap',
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const emptySystemConfig = {};

const DropdownView = () => {
  const isEditMode = false;
  const backboneContext = React.useContext(TableBackboneContext);
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const classes = useStyles({ isDarkMode });

  const selectedRows = backboneContext.getSelectedRows();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedCount, setSelectedCount] = React.useState(() => backboneContext.getSelectedRows().length);

  React.useEffect(() => {
    setSelectedCount(selectedRows.length);
  }, [selectedRows])

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update backbone config
  React.useEffect(() => {
    if (
      !anchorEl &&
      (!isEqual(views, backboneContext.getConfig('views')) || !isEqual(selectedView, backboneContext.getConfig('view')))
    ) {
      backboneContext.updateConfig({
        views,
        view: selectedView,
      });
    }
  }, [anchorEl]);

  React.useEffect(() => {
    const onSelectedRowsChange = (selectedRows) => {
      setSelectedCount(selectedRows.length);
    };
    backboneContext.addon('compact.addSelectedChangeListener', () => {})(onSelectedRowsChange);
  }, []);

  const systemConfig = backboneContext.addon('get.system.config', () => {
    return emptySystemConfig;
  })();

  const systemViews = React.useMemo(() => get(systemConfig, ['views'], []), [systemConfig]);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const inputRef = React.useRef(null);

  const [views, setViews] = React.useState(backboneContext.getConfig('views'));
  const [selectedView, setSelectedView] = React.useState(backboneContext.getConfig('view'));

  React.useEffect(() => {
    const bbViews = backboneContext.getConfig('views').map((el) => {
      return {
        ...el,
        name: el.id === selectedView.id ? selectedView.name : el.name,
        isHidden: el.id === selectedView.id ? selectedView.isHidden : !!el.isHidden,
      };
    });
    setViews(bbViews);
  }, [selectedView]);

  const handleDuplicateView = (view) => {
    const newView = backboneContext.createUserView('');
    if (newView) {
      setViews([...views, { ...view, name: '', id: newView.id, isNew: true, systemView: isEditMode ? true : false }]);
    }
  };

  const selectView = (view) => {
    setSelectedView(view);
    handleClose();
  };

  const deleteView = (deletedView) => {
    setViews(views.filter((view) => view.id !== deletedView.id));
    if (selectedView.id === deletedView.id) {
      setSelectedView(views[0]);
    }
  };

  const updateView = (newView) => {
    setViews(
      views.map((view) => {
        if (view.id === newView.id) return newView;
        return view;
      }),
    );
    if (selectedView.id === newView.id) {
      setSelectedView(newView);
    }
  };

  const resetView = (view) => {
    backboneContext.addon('system.reset.view', () => undefined)(view);
    handleClose();
  };

  const viewsRender = React.useMemo(() => {
    if (views.length === 1 && !views.find((v) => v.id === '_selected_items_')) {
      const newView = backboneContext.createUserView('Selected items', '_selected_items_');
      if (newView) {
        setViews([
          ...views,
          { ...views[0], name: 'Selected items', id: newView.id, isNew: false, systemView: isEditMode },
        ]);
      }
    }
    return (
      views
        .filter(
          (view) =>
            (isEditMode || !view.isHidden) &&
            (!inputRef || view.name.toLowerCase().includes(searchValue.toLowerCase())),
        )
        // .concat({
        //   name: 'Selected items',
        //   id: '_selected_items_',
        // })
        .map((view, index) => {
          return (
            // Do not allow user to move item when searchValue is not empty
            <ConditionalWrap key={view.id} condition={!searchValue} wrap={(children) => children}>
              <MenuItem
                view={view}
                allowCustomView={false}
                selectView={selectView}
                selectedView={selectedView}
                handleDuplicateView={handleDuplicateView}
                updateView={updateView}
                deleteView={deleteView}
                resetView={resetView}
                systemViews={systemViews}
                isEditMode={isEditMode}
              />
            </ConditionalWrap>
          );
        })
    );
  }, [views]);

  return (
    <Box className={classes.container}>
      <Button className={classes.dropdownBtn} onClick={handleClick}>
        <Icon className={isDarkMode ? 'iconDropDown' : ''} type={'addproperties'} />
        <span className={`${classes.viewLabel} ${isDarkMode ? 'viewLabel' : ''}`}>
          {selectedView.name || 'Untitled'}
        </span>
        <Icon className={isDarkMode ? 'iconDropDown' : ''} type={'chevron'} />
      </Button>
      <Typography
        component={'span'}
        variant={'caption'}
        style={{ cursor: 'pointer' }}
        onClick={() => {
          selectView(views[1]);
          backboneContext.updateConfig({
            views,
            view: views[1],
          });
        }}
      >
        {' '}
        {selectedCount} selected items
      </Typography>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popover}>
          {viewsRender.length > 0 ? (
            <List className={classes.listViews}>{viewsRender}</List>
          ) : (
            <Box className={classes.noResults}>
              <span>No results</span>
            </Box>
          )}
        </Box>
      </Popover>
    </Box>
  );
};

export default DropdownView;
