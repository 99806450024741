import * as React from 'react';
import { FormControlLabel, Radio } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
interface radioItemProps {
  label: string;
  radioValue: string;
  value: string;
  children?: React.ReactNode;
  className?: string;
}

const useStyle = makeStyles(() => ({
  root: {
    marginBottom: '12px',
  },
  control: {
    marginBottom: '4px',
    marginLeft: '10px',
    marginRight: '0px',
    padding: '2px 0',
    '& .eip1-MuiButtonBase-root:active, & .eip1-MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
    '& .eip1-MuiTypography-root': {
      color: colors.text.default,
    },
  },
  icon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    border: `2px solid ${colors.border.default}`,
    backgroundColor: 'transparent',
    '&:hover': {
      borderColor: colors.border.highlight,
    },
  },
  checkedIcon: {
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    backgroundColor: colors.action.primaryDefault,
    borderColor: colors.action.primaryDefault,
    '&:before': {
      display: 'block',
      width: '8px',
      height: '8px',
      backgroundColor: 'white',
      content: '""',
      transform: 'translate(50%, 50%)',
      borderRadius: '50%',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
}));

const RadioItem: React.FC<radioItemProps> = ({ children, label, value, radioValue, className }: radioItemProps) => {
  const classes = useStyle();
  return (
    <div className={`${classes.root} ${className}`}>
      <FormControlLabel
        checked={radioValue === value}
        className={classes.control}
        label={label}
        value={radioValue}
        control={
          <Radio
            icon={<span className={classes.icon} />}
            color="default"
            checkedIcon={<span className={classes.checkedIcon}></span>}
          />
        }
      />
      {radioValue === value && children}
    </div>
  );
};

export default RadioItem;
