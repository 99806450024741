import { cloneDeep, get, set } from 'lodash';
import moment from 'moment';

const handleFilter = ({ filter, row, columns, callback }) => {
  const { queryType, field, subFilters, queryValue } = filter;
  if (filter.subFilters) return callback({ filters: subFilters, row, columns });

  const column = columns.find((col) => col.field === field);
  const filterValue =
    get(row, get(column, ['filterField'], ''), '') ||
    get(row, ['eData', field, 'value'], '') ||
    get(row, ['eData', field, 'id'], '') ||
    get(row, ['eData', field, 'label'], '');

  if (['is', '='].includes(queryType)) {
    return filterValue == queryValue;
  }
  if (['is_not', '≠'].includes(queryType)) {
    return filterValue != queryValue;
  }
  if (['is_empty'].includes(queryType)) {
    return filterValue == null;
  }
  if (['is_not_empty'].includes(queryType)) {
    return filterValue != null;
  }
  if (['contains', 'match'].includes(queryType)) {
    return String(filterValue).toLowerCase().includes(String(queryValue).toLowerCase());
  }
  if (['does_not_contain', 'is_not_match'].includes(queryType)) {
    return !String(filterValue).toLowerCase().includes(String(queryValue).toLowerCase());
  }
  if (['begins_with'].includes(queryType)) {
    return String(filterValue).toLowerCase().startsWith(String(queryValue).toLowerCase());
  }
  if (['ends_with'].includes(queryType)) {
    return String(filterValue).toLowerCase().endsWith(String(queryValue).toLowerCase());
  }
  if (['does_not_start_with'].includes(queryType)) {
    return !String(filterValue).toLowerCase().startsWith(String(queryValue).toLowerCase());
  }
  if (['does_not_end_with'].includes(queryType)) {
    return !String(filterValue).toLowerCase().endsWith(String(queryValue).toLowerCase());
  }
  if (['IN'].includes(queryType)) {
    return queryValue.some((value) => String(value).toLowerCase().includes(String(filterValue).toLowerCase()));
  }
  if (['>'].includes(queryType)) {
    return Number(filterValue || 0) > Number(queryValue);
  }
  if (['≥'].includes(queryType)) {
    return Number(filterValue || 0) >= Number(queryValue);
  }
  if (['<'].includes(queryType)) {
    return Number(filterValue || 0) < Number(queryValue);
  }
  if (['≤'].includes(queryType)) {
    return Number(filterValue || 0) <= Number(queryValue);
  }
  if (['is_before'].includes(queryType)) {
    return moment(filterValue).diff(moment(queryValue), 'day') < 0;
  }
  if (['is_after'].includes(queryType)) {
    return moment(filterValue).diff(moment(queryValue), 'day') > 0;
  }
  if (['is_on_or_before'].includes(queryType)) {
    return moment(filterValue).diff(moment(queryValue), 'day') <= 0;
  }
  if (['is_on_or_after'].includes(queryType)) {
    return moment(filterValue).diff(moment(queryValue), 'day') >= 0;
  }
  if (['is_within'].includes(queryType)) {
    return (
      moment(filterValue).format('YYYY-MM-DD HH:mm:ss') >= moment(queryValue).format('YYYY-MM-DD 00:00:00') &&
      moment(filterValue).format('YYYY-MM-DD HH:mm:ss') <= moment(queryValue).format('YYYY-MM-DD 23:59:59')
    );
  }

  return filterValue === queryValue;
};

export const handleOperation = ({ filters, row, columns }) => {
  if (filters.length == 0) return true;
  if (filters.length > 1 && filters[1].logicalOperator.toLowerCase() == 'and') {
    return filters.every((filter) => handleFilter({ filter, row, columns, callback: handleOperation }));
  }
  return filters.some((filter) => handleFilter({ filter, row, columns, callback: handleOperation }));
};

export function calculateConditionFormat({ rows, conditionFormat, columns }) {
  return rows.map((row) => {
    const cloneRow = cloneDeep(row);
    Object.entries(conditionFormat).forEach(([colKey, conditions]: [string, any[]]) => {
      const validConditions = (conditions || []).filter((con) =>
        handleOperation({ filters: con.filters, row, columns }),
      );

      if (validConditions.length === 0) return;

      const appliedStyles = {
        label: { value: null, propMapping: 'style.color' },
        cell_highlight: { value: null, propMapping: 'style.background' },
        bold: { value: null, propMapping: 'style.fontWeight' },
        italic: { value: null, propMapping: 'style.fontStyle' },
        underline: { value: null, propMapping: 'style.textDecoration' },
      };

      // combine the styles, first one wins
      validConditions.forEach((validCondition) => {
        Object.keys(appliedStyles).forEach((styleKey) => {
          const style = get(validCondition, ['styles'], []).find((el) => el.key === styleKey);
          if (style) {
            appliedStyles[styleKey].value = style.value;
          }
        });
      });

      Object.keys(appliedStyles).forEach((styleKey) => {
        if (appliedStyles[styleKey].value !== null) {
          set(cloneRow, ['eData', colKey, appliedStyles[styleKey].propMapping], appliedStyles[styleKey].value);
        }
      });

      return;
    });

    return cloneRow;
  });
}
