import React from 'react';
import { usePage } from '@eip/next/lib/main';
import { FeedbackFeatureRequest } from '@ep/insight-ui/elements/app-menu/form-feature-request';
import { Typography } from '@material-ui/core';

export default function FeatureRequest() {
  const { isInit, addStaticPage, setCurrentPage, loadPageList, title, updateTitle } = usePage();
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (isInit === false) {
      loadPageList();
      addStaticPage('feature-request', { title: 'Feature request' });
    }
  }, [isInit]);

  React.useEffect(() => {
    setCurrentPage('feature-request');
    setInit(true);
  }, []);
  return (
    <div style={{ paddingLeft: 48, paddingRight: 48, background: 'white' }}>
      <Typography variant="h1" className={'page-title'} placeholder="Untitled" style={{ marginBottom: 20 }}>
        {title.text}
      </Typography>
      <FeedbackFeatureRequest />
    </div>
  );
}
