import * as React from 'react';
import ButtonSelectDate from './button-select-date';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
const HeaderRight = () => {
  return (
    <MonitorContainer component={'div'} mId="section-calendar" mLabel="section-calendar">
      <ButtonSelectDate />
    </MonitorContainer>
  );
};
export default HeaderRight;
