import { IFilterItem } from '../inline-dialog/filter/filter-group-dialog/filter-form-group';
import CellComponent from './cell-component';
import { PropertiesModal } from './dropdown-properties/dropdown-properties';
import MultiBudgetFormat from './format/budgets/multi-budget-format';
import CurrencyFormat from './format/currency-format';
import DateRangeFormat from './format/date-range-format';
import PositionFormat from './format/position-format';
import StatusTextFormat from './format/status-text-format';
import TextFormat from './format/text-format';
import { IDataTable } from './interface';
import { SortType } from './table';
import { IOptionModal } from './table-actions/button-option';

export function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}

export const dataExample: IDataTable = {
  country: {
    label: 'PH',
  },
  store_front: {
    status: 'active',
    label: 'P&G Home Care Official Store',
  },
  campaign: {
    label: 'Flash sale 15.7',
  },
  ads_object: {
    label: 'Joy Lemon Concentrate Dishwashing Liquid Bottle (790mL) Set of 2',
    status: 'active',
  },
  keyword: {
    label: 'downy sunrise fresh',
    status: 'active',
  },
  campaign_budget: [
    {
      value: getRandomInt(500, 1500),
      currency: 'PHP',
      label: 'Total',
    },
    {
      value: getRandomInt(500, 1500),
      currency: 'PHP',
      label: 'Daily',
    },
  ],
  campaign_bidding_limit: {
    value: getRandomInt(1, 100),
    currency: 'PHP',
  },
  campaign_timeline: {
    date_from: '01/07/2021',
    date_to: -1,
  },
  ads_object_budget: [
    {
      value: getRandomInt(500, 1500),
      currency: 'PHP',
      label: 'Total',
    },
  ],
  ads_object_timeline: {
    date_from: '03/07/2021',
    date_to: '21/07/2021',
  },
  match_type: {
    label: 'Broad match',
  },
  keyword_bidding_price: {
    value: getRandomInt(1, 100),
    currency: 'PHP',
  },
  roas: {
    value: getRandomInt(50000, 1000000),
    currency: 'VND',
  },
  cost: {
    value: getRandomInt(50000, 1000000),
    currency: 'VND',
  },
  direct_ads_gmv: {
    value: getRandomInt(50000, 1000000),
    currency: 'PHP',
  },
  ads_gmv: {
    value: getRandomInt(50000, 1000000),
    currency: 'PHP',
  },

  item_sold: {
    value: getRandomInt(50000, 1000000),
    currency: 'VND',
  },
  direct_item_sold: {
    value: getRandomInt(50000, 1000000),
    currency: 'VND',
  },
  current_position: {
    position: getRandomInt(1, 100),
    hour: '16:32',
    date: '03/06/2021',
  },
};

export const cellAction = [
  {
    name: 'Edit Component',
    icon: 'pencil',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Copy name',
    icon: 'copy',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Copy setting',
    icon: 'setting',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Set script',
    icon: 'setscript',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Tag',
    icon: 'tag',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'View history',
    icon: 'history',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Comment',
    icon: 'comment',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Search by "Keyword name"',
    icon: 'filter',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Move to',
    icon: 'move',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Export',
    icon: 'export',
    component: null,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
];

export const kwBiddingPriceActions = [
  {
    name: 'Edit RTB',
    icon: 'pencil',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
];

export const cellActionInlineEdit = [
  {
    name: 'Edit RTB',
    icon: 'pencil',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },

  {
    name: 'Copy setting',
    icon: 'setting',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Set script',
    icon: 'setscript',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
  {
    name: 'Tag',
    icon: 'tag',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },

  {
    name: 'Comment',
    icon: 'comment',
    component: CellComponent,
    onSubmit: (info) => {
      console.log('info', info);
    },
  },
];

export const headerAction = [
  {
    name: 'Sort Ascending',
    icon: 'ascending',
    type: 'asc',
    component: null,
  },
  {
    name: 'Sort Descending',
    icon: 'descensing',
    type: 'desc',
    component: null,
  },
  {
    name: 'Add Filter',
    icon: 'addfilter',
    type: '',
    component: null,
  },
];

export const dataMenuTable = {
  columns: [
    {
      name: 'Country',
      width: 115,
      field: 'country',
      cell: {
        format: TextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Storefront',
      width: 115,
      field: 'store_front',
      cell: {
        format: StatusTextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Campaign',
      width: 188,
      field: 'campaign',
      cell: {
        format: StatusTextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Ads Object',
      width: 168,
      field: 'ads_object',
      cell: {
        format: StatusTextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Keyword',
      width: 155,
      field: 'keyword',
      cell: {
        format: StatusTextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Campaign Budget',
      width: 148,
      field: 'campaign_budget',
      cell: {
        format: MultiBudgetFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Campaign Bidding Limit',
      width: 148,
      field: 'campaign_bidding_limit',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Campaign Timeline',
      width: 185,
      field: 'campaign_timeline',
      cell: {
        format: DateRangeFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Ads Object Budget',
      width: 148,
      field: 'ads_object_budget',
      cell: {
        format: MultiBudgetFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Ads object timeline',
      width: 185,
      field: 'ads_object_timeline',
      cell: {
        format: DateRangeFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Match Type',
      width: 164,
      field: 'match_type',
      cell: {
        format: TextFormat,
        action: cellAction,
      },
      action: headerAction,
    },
    {
      name: 'Keyword Bidding Price',
      width: 164,
      field: 'keyword_bidding_price',
      cell: {
        format: CurrencyFormat,
        action: cellActionInlineEdit,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'ROAS',
      width: 124,
      field: 'roas',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'COST',
      width: 148,
      field: 'cost',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Direct Ads GMV',
      width: 152,
      field: 'direct_ads_gmv',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Ads GMV',
      width: 152,
      field: 'ads_gmv',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Item Sold',
      width: 124,
      field: 'item_sold',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Direct Item Sold',
      width: 124,
      field: 'direct_item_sold',
      cell: {
        format: CurrencyFormat,
        action: cellAction,
      },
      action: headerAction,
      align: 'right',
    },
    {
      name: 'Current Position',
      width: 151,
      field: 'current_position',
      cell: {
        format: PositionFormat,
        action: cellAction,
      },
      action: headerAction,
    },
  ],

  //rows: datarows,
  hiddenColumns: [],
  pinnedColumns: [],
  pagination: { page: 1, limit: 10, total: 100 },
  sort: [],
};

export const pageOption = [
  {
    label: '10 pages',
    value: '10',
    checked: true,
  },
  {
    label: '20 pages',
    value: '20',
    checked: false,
  },
  {
    label: '50 pages',
    value: '50',
    checked: false,
  },
  {
    label: '100 pages',
    value: '100',
    checked: false,
  },
];

//filter data
export const filterOperators = [
  [
    {
      label: 'Contains',
      value: 'contains',
    },
    {
      label: 'Does not contains',
      value: 'noContains',
    },
  ],
  [
    {
      label: 'Exactly with',
      value: 'exactlyWith',
    },
    {
      label: 'Starts with',
      value: 'startsWith',
    },
    {
      label: 'Ends with',
      value: 'endsWith',
    },
  ],
];
export const dataDemoFilter: IFilterItem[] = [
  {
    type: 'filter',
    logicalOperator: '',
    field: { label: 'Country', value: 'country' },
    queryType: { label: 'Contains', value: 'contains' },
    queryValue: { value: 'ecd' },
  },
  {
    type: 'filter',
    logicalOperator: 'or',
    field: { label: 'Shop', value: 'shop' },
    queryType: { label: 'Does not contains', value: 'noContains' },
    queryValue: { value: 'abc' },
  },
  {
    type: 'groupFilter',
    logicalOperator: 'and',
    subFilters: [
      {
        type: 'filter',
        logicalOperator: '',
        field: { label: 'Country', value: 'country' },
        queryType: { label: 'Contains', value: 'contains' },
        queryValue: { value: 'PH' },
      },
    ],
  },
];

//sort data
export const dataDemoSort: SortType[] = [
  { field: 'country', sort: 'asc' },
  { field: 'shop', sort: 'asc' },
  { field: 'campaign', sort: 'asc' },
  { field: 'ads_object', sort: 'desc' },
];

// properties data
export const dataDemoPropertiesModal: PropertiesModal = [
  {
    option: {
      label: 'Marketplace',
      value: 'marketplace',
      checked: true,
    },
    atributes: [
      { label: 'Marketplace, Atribute1', value: 'marketplace-atribute1', checked: false },
      { label: 'Marketplace, Atribute2', value: 'marketplace_atribute2', checked: false },
    ],
    metrics: [
      { label: 'Marketplace, Metric1', value: 'marketplace-metric1', checked: false },
      { label: 'Marketplace, Metric2', value: 'marketplace-metric2', checked: false },
    ],
  },
  {
    option: {
      label: 'Tool',
      value: 'tool',
      checked: true,
    },
    atributes: [
      { label: 'Tool, Atribute1', value: 'tool-atribute1', checked: false },
      { label: 'Tool, Atribute2', value: 'tool-atribute2', checked: false },
    ],
    metrics: [
      { label: 'Tool, Metric1', value: 'tool-metric1', checked: false },
      { label: 'Tool, Metric2', value: 'tool-metric2', checked: false },
    ],
  },
  {
    option: {
      label: 'Ad Type',
      value: 'adType',
      checked: true,
    },
    atributes: [
      { label: 'Ad Type, Atribute1', value: 'adType-atribute1', checked: false },
      { label: 'Ad Type, Atribute2', value: 'adType-atribute2', checked: false },
    ],
    metrics: [
      { label: 'Ad Type, Metric1', value: 'adType-metric1', checked: false },
      { label: 'Ad Type, Metric2', value: 'adType-metric2', checked: false },
    ],
  },
  {
    option: {
      label: 'Country',
      value: 'country',
      checked: true,
    },
    atributes: [
      { label: 'Country, Atribute1', value: 'country-atribute1', checked: false },
      { label: 'Country, Atribute2', value: 'country-atribute2', checked: false },
    ],
    metrics: [{ label: 'Country, Metric1', value: 'country-metric1', checked: false }],
  },
  {
    option: {
      label: 'Storefront',
      value: 'storefront',
      checked: true,
    },
    atributes: [{ label: 'Storefront, Atribute1', value: 'storefront-atribute1', checked: false }],
    metrics: [{ label: 'Storefront, Metric1', value: 'storefront-metric1', checked: false }],
  },
  {
    option: {
      label: 'Campaign',
      value: 'campaign',
      checked: true,
    },
    atributes: [
      { label: 'Campaign, Atribute1', value: 'campaign-atribute1', checked: false },
      { label: 'Campaign, Atribute2', value: 'campaign-atribute2', checked: false },
    ],
    metrics: [
      { label: 'Campaign, Metric1', value: 'campaign-metric1', checked: false },
      { label: 'Campaign, Metric2', value: 'campaign-metric2', checked: false },
      { label: 'Campaign, Metric3', value: 'campaign-metric3', checked: false },
    ],
  },
  {
    option: {
      label: 'ad object',
      value: 'adObject',
      checked: true,
    },
    atributes: [
      { label: 'ad object, Atribute1', value: 'adObject-atribute1', checked: false },
      { label: 'ad object, Atribute2', value: 'adObject-atribute2', checked: false },
    ],
    metrics: [
      { label: 'ad object, Metric1', value: 'adObject-metric1', checked: false },
      { label: 'ad object, Metric2', value: 'adObject-metric2', checked: false },
      { label: 'ad object, Metric3', value: 'adObject-metric3', checked: false },
    ],
  },
  {
    option: {
      label: 'Keyword',
      value: 'keyword',
      checked: true,
    },
    atributes: [{ label: 'Keyword, Atribute1', value: 'keyword-atribute1', checked: false }],
    metrics: [
      { label: 'Keyword, Metric1', value: 'keyword-metric1', checked: false },
      { label: 'Keyword, Metric3', value: 'keyword-metric3', checked: false },
    ],
  },
];

export const dataOptionsDemo: IOptionModal = {
  properies: dataDemoPropertiesModal,
};

export const DEMO_CONFIG = {
  title: 'Ad object optimization',
  view: { id: 'all', name: 'All' },
  dateRange: { dateFrom: '3/8/2021', dateTo: '12/8/2021' },

  settingType: [
    { type: 'dimension', label: 'Dimension' },
    { type: 'metric', label: 'Metric' },
    { type: 'attribute', label: 'Attribute' },
  ],
  dimension: [
    { id: 'country', name: 'Country', dataType: 'string' },
    { id: 'store_front', name: 'Storefront', dataType: 'string' },
    { id: 'campaign', name: 'Campaign', dataType: 'string' },
    { id: 'ads_object', name: 'Ads Object', dataType: 'string' },
    { id: 'keyword', name: 'Keyword', dataType: 'string' },
  ],
  metric: [
    { id: 'gmv', name: 'GMV', dataType: 'number' },
    { id: 'roas', name: 'ROAS', dataType: 'number' },
    { id: 'cpc', name: 'CPC', dataType: 'number' },
    { id: 'cost', name: 'Cost', dataType: 'number' },
    { id: 'ads_gmv', name: 'Ads GMV', dataType: 'number' },
    { id: 'item_sold', name: 'Item Sold', dataType: 'number' },
    { id: 'direct_item_sold', name: 'Direct Item Sold', dataType: 'number' },
  ],
  attribute: [
    { id: 'campaign_timeline', name: 'Campaign Timeline', dataType: 'date' },
    { id: 'campaign_budget', name: 'Campaign Budget', dataType: 'number' },
    { id: 'campaign_bidding_limit', name: 'Campaign Bidding Limit', dataType: 'number' },
    { id: 'ads_object_budget', name: 'Ads Object Budget', dataType: 'number' },
    { id: 'ads_object_timeline', name: 'Ads object timeline', dataType: 'date' },
    { id: 'match_type', name: 'Match Type', dataType: 'string' },
    { id: 'keyword_bidding_price', name: 'Keyword Bidding Price', dataType: 'number' },
    { id: 'current_position', name: 'Current Position', dataType: 'number' },
  ],
  pinnedColumn: ['country', 'store_front', 'campaign', 'ads_object', 'keyword'],
  columnWidth: [{ columnField: 'country', width: 120 }],
  sort: [],
  filter: [],
  search: '',
  endpoint: {},
};

export const DEMO_STOREFRONT_CONFIG = {
  title: 'Storefront management',
  dimension: [
    {
      id: 'storefront.name',
      name: 'Storefront',
      dataType: 'string',
    },
    {
      id: 'company.name',
      name: 'Company',
      dataType: 'string',
    },
    {
      id: 'country.name',
      name: 'Country',
      dataType: 'string',
    },
    {
      id: 'channel.name',
      name: 'Channel',
      dataType: 'string',
    },
    {
      id: 'workspace.name',
      name: 'Channel',
      dataType: 'string',
    },
  ],
  metric: [],
  attribute: [
    {
      id: 'storefront.created_by',
      name: 'Created By',
      dataType: 'string',
    },
    {
      id: 'storefront.created_at',
      name: 'Create At',
      dataType: 'string',
    },
    {
      id: 'storefront.updated_by',
      name: 'Update By',
      dataType: 'string',
    },
    {
      id: 'storefront.updated_at',
      name: 'Update At',
      dataType: 'string',
    },
  ],
  endpoint: {},
  mapping: {
    'storefront.name': {
      cellFormat: 'text',
      valueGetter: {
        label: 'storefront.name',
      },
    },
    'storefront.created_by': {
      cellFormat: 'text',
      valueGetter: {
        label: 'storefront.created_by',
      },
    },
    'storefront.created_at': {
      cellFormat: 'text',
      valueGetter: {
        label: 'storefront.created_at',
      },
    },
    'storefront.updated_by': {
      cellFormat: 'text',
      valueGetter: {
        label: 'storefront.updated_by',
      },
    },
    'storefront.updated_at': {
      cellFormat: 'text',
      valueGetter: {
        label: 'storefront.updated_at',
      },
    },
    'company.name': {
      cellFormat: 'text',
      valueGetter: {
        label: 'company.name',
      },
    },
    'channel.name': {
      cellFormat: 'text',
      valueGetter: {
        label: 'channel.name',
      },
    },
    'country.name': {
      cellFormat: 'text',
      valueGetter: {
        label: 'country.name',
      },
    },
    'workspace.name': {
      cellFormat: 'text',
      valueGetter: {
        label: 'workspace.name',
      },
    },
  },
};
