import * as React from 'react';
import PieChartComponent from '../../../chartlib/pie-chart-v2';
import { colorsChartLegend } from '../../../chartlib/helper';

const listData = {
  labels: ['Label 1', 'Label 2', 'Label 3', 'Label 4'],
  datasets: [
    {
      label: 'Labels',
      data: [25, 12, 7, 60],
      actives: [true, true, true, true],
      backgroundColor: [
        colorsChartLegend[0].stroke,
        colorsChartLegend[1].stroke,
        colorsChartLegend[2].stroke,
        colorsChartLegend[3].stroke,
      ],
      borderWidth: 0,
      hoverOffset: 4,
    },
  ],
};
const PieChart = () => {
  return (
    <div style={{ marginBottom: '200px', height: '100vh' }}>
      <PieChartComponent
        keyChart="pie_demo"
        data={listData}
        stateChart="success"
        title={'Hihi'}
        value={'600'}
        currency={'IDR'}
        percent={15}
      />
    </div>
  );
};

export default PieChart;
