import * as React from 'react';

import { Box, ButtonGroup, Button, makeStyles } from '@material-ui/core';

import { useActionConfiguration } from './hooks/use-action-configuration';
import ButtonEtableConfig from '../../block/etable/etable-config/button-etable-config';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';

const useStyles = makeStyles(() => ({
  container: {
    '& .eip1-MuiButton-root': {
      height: '32px',
    },
  },
}));

const ActionConfiguration = ({
  label = 'Action Configuration',
  source = 'page-actions',
  handleSubmit = (actions) => undefined,
  variant = 'contained',
  color = 'secondary',
}: {
  label: any;
  source: string;
  handleSubmit?: (actions: any[]) => void;
  variant: 'contained' | 'outlined' | 'text';
  color: string;
}) => {
  const classes = useStyles();

  const { showConfig, setShowConfig, config, onSubmit, loading, submitting } = useActionConfiguration({
    source,
    handleSubmit,
  });

  return (
    <Box className={classes.container}>
      <LoadingComponent loading={loading}>
        <ButtonGroup variant={variant} color={color} fullWidth>
          <Button variant={variant} color={color} fullWidth onClick={() => setShowConfig(true)}>
            {label}
          </Button>
        </ButtonGroup>
      </LoadingComponent>
      <ButtonEtableConfig
        open={showConfig}
        onClose={() => setShowConfig(false)}
        config={config}
        onSubmit={onSubmit}
        submitting={submitting}
      />
    </Box>
  );
};

export default ActionConfiguration;
