import { getUserSettings } from '@ep/one/aim';
import { request } from '@ep/one/src/utils';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { Avatar, Box, Button, Drawer, makeStyles, Tab, Tabs, TextareaAutosize, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get, uniq, uniqBy } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { EIPContext } from '../context';
import { actions } from '../dashboard-redux';
import ChartLibs from '../node/chartlibs';
import { DashboardState, NodePanelProps } from '../type';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import CreatableSelect from 'react-select/creatable';

const colorStyles = {
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: '#EDF2F9',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: '#253746',
    fontSize: '14px',
    lineHeight: '1.45em',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: '#00000042',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: '#EDF2F9',
      color: '#00000066',
    },
    paddingLeft: 0,
    '& svg': {
      width: '20px',
      height: '20px',
    },
  }),
};

export function NodePanelContainer(props: Omit<NodePanelProps, 'nodeData' | 'activeTab'>) {
  const dispatch = useDispatch();
  const chartData = useSelector((state: DashboardState) => {
    const node = state.nodes.find((n) => n.id === props.nodeId);
    let parentList = [];
    let childList = [];
    if (node) {
      parentList = state.nodes.filter((n) => (node.parentList || []).some((n1) => n1.nodeId === n.id));
      childList = state.nodes.filter((n) => (node.childList || []).some((n1) => n1.nodeId === n.id));
    }
    return { node, parentList, childList };
  });

  return (
    <NodePanelUI
      nodeId={props.nodeId}
      nodeData={chartData.node}
      parentList={chartData.parentList}
      childList={chartData.childList}
      isExpanded={props.isExpanded}
      componentFilter={props.componentFilter}
      onCancel={props.onCancel}
      onAddConnection={(nodeId, eventId) => {
        dispatch(actions.connect({ nodeId, eventId }));
      }}
    />
  );
}
export { NodePanelContainer as NodePanel };

const ADMIN_EMAILS = ['phuc.phan@epsilo.io', 'vu.nguyen@epsilo.io'];

function NodePanelUI({ nodeId, nodeData: chartData, isExpanded = true }: NodePanelProps) {
  const classes = useStyle();
  const context = React.useContext(EIPContext);
  const [activeTab, setActiveTab] = React.useState('settings');
  const [emails, setEmails] = React.useState(null);
  const [nextEmails, setNextEmails] = React.useState(null);

  const [rolesOption, setRolesOption] = React.useState([]);
  const [updated, setUpdated] = React.useState(false);
  const [roles, setRoles] = React.useState([1, 2, 3]);

  const chartLibId = get(chartData, 'chartLibId', null);

  const chartLibs = React.useMemo(() => {
    return ChartLibs.asList().map((i) => ({
      ...i,
      value: i.chartLibId,
      label: i.label,
    }));
  }, []);
  const chartLib = chartLibs.find((i) => i.value === chartLibId);

  const ref = React.useRef();

  const masterEmails = React.useMemo(() => {
    const userEmail = getUserSettings().profile.userEmail;
    return uniq([...ADMIN_EMAILS, userEmail]);
  }, []);

  // React.useEffect(() => {
  //   if (isExpanded) {
  //     document.body.classList.add(classes.bodyExpanded);
  //   } else {
  //     document.body.classList.remove(classes.bodyExpanded);
  //   }
  // }, [isExpanded]);

  React.useEffect(() => {
    if (chartData) {
      const userEmails = get(chartData, 'permission', []).map((i) => {
        return {
          label: i,
          value: i,
        };
      });
      setEmails(userEmails);
      const nextUserEmails = get(chartData, 'next_permission', []).map((i) => {
        return {
          label: i,
          value: i,
        };
      });
      setNextEmails(nextUserEmails);
      if (!updated) {
        const roles = get(chartData, 'roles', [1, 2, 3]);
        setRoles(roles);
      }
      setUpdated(true);
    }
  }, [chartData]);
  // React.useEffect(() => {
  //   if (ref.current && nodeId && chartData && chartLibId) {
  //     const chartLib = ChartLibs.find(chartLibId);

  //     // FIXME: more fine tunes on reuse react component
  //     // ReactDOM.unmountComponentAtNode(ref.current);
  //     // window.requestAnimationFrame(() => {
  //     //   chartLib.component.renderConfigurationForm(ref.current, chartData.customAttributes, (settings) => {
  //     //     context.onNodeSubmitUpdate(chartData.id, {
  //     //       ...chartData,
  //     //       customAttributes: settings,
  //     //     });
  //     //     return Promise.resolve(true);
  //     //   });
  //     // });
  //   }
  // }, [nodeId, chartData, chartLibId]);

  React.useEffect(() => {
    request.get(EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/common/collect-workspace-role').then((res) => {
      if (res.success) {
        setRolesOption(
          res.data.map((el) => ({
            label: el.name || el.label,
            value: el.id || el.value,
          })),
        );
      }
    });
  }, []);

  const handleAddOption = React.useCallback(
    (valString) => {
      const newUsers = String(valString)
        .split(/,|\n|\t|\s/)
        .filter((i) => String(i).trim() !== '')
        .map((i) => ({
          label: i,
          value: i,
        }));

      setEmails((prev) => {
        return uniqBy([...(prev || []), ...newUsers], (i) => i?.value);
      });
    },
    [emails],
  );

  const handleAddNextOption = React.useCallback(
    (valString) => {
      const newUsers = String(valString)
        .split(/,|\n|\t|\s/)
        .filter((i) => String(i).trim() !== '')
        .map((i) => ({
          label: i,
          value: i,
        }));

      setNextEmails((prev) => {
        return uniqBy([...(prev || []), ...newUsers], (i) => i?.value);
      });
    },
    [nextEmails],
  );

  return (
    <Drawer
      open={isExpanded}
      anchor={'right'}
      variant={'persistent'}
      classes={{ paper: classes.panelPaper }}
      id="ep-editor-panel"
      className={clsx(classes.editorPanel, 'ep-editor-panel')}
    >
      <div className={clsx('card card-lg sidebar-card sidebar-footer-fixed')}>
        <div className="card-body sidebar-body sidebar-scrollbar">
          <Tabs value={activeTab}>
            <Tab
              label={'Settings'}
              value="settings"
              classes={{
                root: classes.tabRoot,
                wrapped: classes.tabText,
              }}
            ></Tab>
          </Tabs>
          {/** start data configuration */}
          <div className={clsx('mt-3', activeTab !== 'data' && 'd-none')}>
            <div className="configuration-form">
              {chartLib &&
                chartLib.component.renderConfigurationForm(
                  null,
                  get(chartData, 'customAttributes', null),
                  (data) => {
                    return context.onNodeSubmitUpdate(chartData.id, {
                      ...chartData,
                      customAttributes: data,
                    });
                  },
                  chartData.id,
                )}
              {chartLib && chartData.id && (
                <>
                  <div style={{ marginTop: '16px' }}>
                    <Typography variant="h6">Access with role</Typography>
                    <div style={{ width: '100%' }}>
                      <SelectMultipleForm
                        options={rolesOption}
                        value={roles}
                        onChange={(values) => {
                          setRoles(values);
                        }}
                        multipleSelect={true}
                        searchAble={false}
                        isConfigChart
                        handleAfterClosed={() => {
                          context.onNodeSubmitUpdate(chartData.id, {
                            ...chartData,
                            roles,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <h4>Access with emails (legacy):</h4>
                    <CreatableSelect
                      isMulti
                      onCreateOption={handleAddOption}
                      onChange={(data) => {
                        setEmails(data);
                      }}
                      value={emails || []}
                      options={[]}
                      defaultValue={[]}
                      styles={colorStyles}
                    />
                    <h4>Access with emails:</h4>
                    <CreatableSelect
                      isMulti
                      onCreateOption={handleAddNextOption}
                      onChange={(data) => {
                        setNextEmails(data);
                      }}
                      value={nextEmails || []}
                      options={[]}
                      defaultValue={[]}
                      styles={colorStyles}
                    />
                    <Button
                      onClick={() => {
                        context.onNodeSubmitUpdate(chartData.id, {
                          ...chartData,
                          permission: (emails || []).filter((i) => !!i?.value).map((i) => i.value),
                          next_permission: (nextEmails || []).filter((i) => !!i?.value).map((i) => i.value),
                        });
                      }}
                    >
                      Update
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

const useStyle = makeStyles(() => {
  const panelWidth = 240;
  return {
    bodyExpanded: {
      width: `calc(100vw + ${panelWidth}px)`,
      marginRight: `${panelWidth}px !important`,
    },
    panelPaper: {
      fontSize: `12px`,
      width: `${panelWidth}px`,
      marginTop: 58,
    },
    panelModal: {
      zIndex: 21,
    },
    editorPanel: {
      '& .card-lg > .card-body': {
        padding: '0 12px 12px 12px !important',
      },
      '& .configuration-form': {
        marginTop: '1rem',
      },
    },
    tabRoot: {
      minWidth: 'auto',
      padding: '6px 12px !important',
    },
    tabText: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '12px !important',
      textTransform: 'capitalize',
      color: '#8C98A4',
    },
  };
});
