import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getActionMenu } from '../../table-config/common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign detail',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
    GET_MARKET_STATUS_DETAIL: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'ADS_PLACEMENT.id',
    'PRODUCT.id',
    'ADS_CALENDAR.ADS_CAMPAIGN.id',
    'ADS_CALENDAR.ADS_OBJECT.id',
  ],
  viewSetting: [
    {
      id: 'all',
      name: 'All',
      excludeProperty: {
        dimension: ['storefront_ad_object'],
        metric: [],
        attribute: [],
      },
    },
  ],
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
    additionalFilters: [
      {
        name: 'Data status',
        id: 'data_status',
        dataType: 'string',
        propertyType: 'dimension',
        fields: ['ADS_CAMPAIGN.data_status', 'ADS_OBJECT.data_status'],
        options: [
          {
            label: 'Succeeded',
            value: '1',
          },
          {
            label: 'Failed',
            value: '2',
          },
        ],
        rules: [
          {
            value: '1',
            combinator: 'AND',
          },
          {
            value: '2',
            combinator: 'OR',
          },
        ],
        operators: [
          {
            label: 'Is',
            value: 'is',
          },
        ],
        isAdditionalFilter: true,
      },
    ],
  },
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'dataSyncStatus',
          'marketplace',
          'country',
          'storefront',
          'ad_type',
          'ad_tool',
          'ad_object',
          'campaign_tag',
          'ad_object_tag',
          'keyword_tag',
          'ad_campaign_status',
          'campaign_timelime',
          'ad_object_budget',
          'ad_object_status',
          'ad_object_creative',
          'ad_object_audience',
          'ad_object_bidding_price',
          'ad_object_timelime',
        ],
      }
    : {}),
  mapping: {
    dataSyncStatus: {
      title: 'Sync status',
      propertyType: 'dimension',
      cellFormat: 'dataSyncStatusFormat',
      dataType: 'string',
      filterField: '',
      sortField: '',
      valueGetter: {
        data_status: 'ADS_CAMPAIGN.data_status',
      },
      pinned: 'left',
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        campaignName: 'ADS_CAMPAIGN.name',
        campaignCode: 'ADS_CAMPAIGN.campaign_code',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
        campaignDataStatus: 'ADS_CAMPAIGN.data_status',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
        ...(ff.groupby_display_location
          ? {
              id: 'ADTOOL.ads_tool',
              value: 'ADTOOL.ads_tool_name',
            }
          : {}),
      },
      tableConfiguration: null,
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT',
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        productIds: 'PRODUCT.product_sid',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        data_status: 'ADS_OBJECT.data_status',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },

    // ============= ATTRIBUTE =============
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.general_tag',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    ...(ff.delete_ad_campaign_object
      ? {}
      : {
          ad_campaign_object: {
            title: 'Ad campaign object',
            propertyType: 'attribute',
            cellFormat: 'text',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.objective',
            valueGetter: {
              label: 'ADS_CAMPAIGN.objective',
              id: 'ADS_CAMPAIGN.objective',
              value: 'ADS_CAMPAIGN.objective',
            },
            menu: getActionMenu('ad_campaign_object'),
          },
        }),
    ad_campaign_status: {
      title: ff.delete_ad_campaign_object ? 'Campaign status' : 'Ad campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    ...(ff.remove_campaign_budget
      ? {}
      : {
          ad_campaign_daily_budget: {
            title: 'Ad campaign daily budget',
            propertyType: 'attribute',
            cellFormat: 'dailyBudget',
            dataType: 'float',
            filterField: 'ADS_CAMPAIGN.daily_budget',
            valueGetter: {
              value: 'ADS_CAMPAIGN.daily_budget',
              currency: 'STOREFRONT.currency',
            },
            menu: getActionMenu('campaign_daily_budget'),
          },
          ad_campaign_total_budget: {
            title: 'Ad campaign total budget',
            propertyType: 'attribute',
            cellFormat: 'totalBudget',
            dataType: 'float',
            filterField: 'ADS_CAMPAIGN.total_budget',
            valueGetter: {
              value: 'ADS_CAMPAIGN.total_budget',
              currency: 'STOREFRONT.currency',
            },
            menu: getActionMenu('campaign_total_budget'),
          },
        }),
    campaign_timelime: {
      title: 'Campaign timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
        id: 'ADS_CALENDAR.ADS_CAMPAIGN.id',
      },
      menu: getActionMenu('campaign_timeline'),
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    ad_object_creative: {
      title: 'Ad object creative',
      propertyType: 'attribute',
      cellFormat: 'enableDisableFormat',
      dataType: 'string',
      filterField: 'ADS_OBJECT.automated_creatives',
      valueGetter: {
        label: 'ADS_OBJECT.automated_creatives',
        status: 'ADS_OBJECT.automated_creatives',
        value: 'ADS_OBJECT.automated_creatives',
      },
      menu: getActionMenu('ad_object_creative'),
    },
    ad_object_audience: {
      title: 'Ad object audience',
      propertyType: 'attribute',
      cellFormat: 'audienceFormat',
      dataType: 'string',
      filterField: 'ADS_OBJECT.audience_store_visitors_bidding_premium',
      valueGetter: {
        audience_store_visitors: 'ADS_OBJECT.audience_store_visitors_bidding_premium',
        audience_similar_products: 'ADS_OBJECT.audience_similar_products_bidding_premium',
      },
      menu: [
        {
          title: 'Ad object audience',
          name: 'Change audience',
          icon: 'editStatus',
          editor: 'AudiencesEditor',
          payload: {
            field: {
              audienceStoreVisitorsBiddingPremium: 'ADS_OBJECT.audience_store_visitors_bidding_premium',
              audienceSimilarProductsBiddingPremium: 'ADS_OBJECT.audience_similar_products_bidding_premium',
            },
          },
        },
      ],
    },
    ad_object_bidding_price: {
      title: 'Ad object bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_OBJECT.base_bidding_price',
      valueGetter: {
        value: 'ADS_OBJECT.base_bidding_price',
        currency: 'STOREFRONT.currency',
        marketplace: 'MARKETPLACE.marketplace_name',
      },
      menu: [
        {
          title: 'Ad object bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.base_bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_OBJECT.name',
            },
            static: {
              primaryKey: 'ADS_OBJECT.id',
            },
            ...(ff.hidden_bidding_price_lazada
              ? {
                  excludeOperator: [
                    {
                      operator: 'suggest-bid-price',
                      field: 'ADTOOL.ads_tool',
                      values: 'LZD_SP',
                    },
                  ],
                }
              : {}),
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'base_bidding_price',
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.base_bidding_price.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'base_bidding_price',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ],
    },
    ad_object_timelime: {
      title: 'Ad object timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
        id: 'ADS_CALENDAR.ADS_OBJECT.id',
      },
      menu: getActionMenu('ad_object_timeline'),
    },

    // ============= METRIC =============
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
    },
    direct_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    items_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
    },
    stock: {
      title: 'Stock',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'stock',
      valueGetter: {
        value: 'stock',
      },
    },
    discount: {
      title: 'Discount',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'discount',
      valueGetter: {
        value: 'discount',
      },
    },
  },
};
