import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const ChangeCurrency = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 4.625C8.34518 4.625 8.625 4.90482 8.625 5.25V5.375H9.25C9.59518 5.375 9.875 5.65482 9.875 6C9.875 6.34518 9.59518 6.625 9.25 6.625H8H7.5C7.29289 6.625 7.125 6.79289 7.125 7C7.125 7.20711 7.29289 7.375 7.5 7.375H8.5C9.39746 7.375 10.125 8.10254 10.125 9C10.125 9.8554 9.46406 10.5564 8.625 10.6203V10.75C8.625 11.0952 8.34518 11.375 8 11.375C7.65482 11.375 7.375 11.0952 7.375 10.75V10.625H6.75C6.40482 10.625 6.125 10.3452 6.125 10C6.125 9.65482 6.40482 9.375 6.75 9.375H8H8.5C8.70711 9.375 8.875 9.20711 8.875 9C8.875 8.79289 8.70711 8.625 8.5 8.625H7.5C6.60254 8.625 5.875 7.89746 5.875 7C5.875 6.1446 6.53594 5.44356 7.375 5.37974V5.25C7.375 4.90482 7.65482 4.625 8 4.625Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8928 9.83258C13.0975 10.0162 13.3778 10.0519 13.6138 9.92424C13.8499 9.79661 14 9.52831 14 9.23398V7.76602C14 7.71716 13.9959 7.66937 13.9881 7.62305C13.9258 6.63277 13.6185 5.66896 13.0883 4.82048C12.4168 3.74594 11.4224 2.91154 10.2476 2.43689C9.07282 1.96224 7.7779 1.87169 6.54846 2.17822C5.31902 2.48475 4.21819 3.17263 3.40372 4.14327C3.04872 4.56634 3.10391 5.1971 3.52698 5.5521C3.95006 5.9071 4.58081 5.85192 4.93581 5.42884C5.47879 4.78175 6.21268 4.32317 7.0323 4.11881C7.85193 3.91445 8.71521 3.97482 9.49842 4.29126C10.2816 4.60769 10.9446 5.16396 11.3922 5.88032C11.61 6.22896 11.7715 6.60681 11.873 7H11.6818C11.3922 7 11.1341 7.20564 11.0384 7.5128C10.9426 7.81995 11.0301 8.16155 11.2564 8.36462L12.8928 9.83258Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 7.97566V6.76603C2 6.47169 2.1501 6.20339 2.38617 6.07577C2.62224 5.94814 2.90252 5.98377 3.10724 6.16742L4.74358 7.63538C4.96995 7.83845 5.05739 8.18005 4.96163 8.48721C4.86587 8.79436 4.60782 9 4.31815 9H4.12697C4.22849 9.39319 4.38991 9.77104 4.60776 10.1197C5.05539 10.836 5.71832 11.3923 6.50153 11.7087C7.28474 12.0252 8.14802 12.0855 8.96764 11.8812C9.78727 11.6768 10.5212 11.2182 11.0641 10.5711C11.4191 10.1481 12.0499 10.0929 12.473 10.4479C12.896 10.8029 12.9512 11.4336 12.5962 11.8567C11.7818 12.8274 10.6809 13.5152 9.45149 13.8218C8.22205 14.1283 6.92713 14.0378 5.75232 13.5631C4.5775 13.0884 3.58311 12.2541 2.91167 11.1795C2.38139 10.3309 2.07403 9.3669 2.01177 8.37644C2.00404 8.33028 2 8.28266 2 8.23398V8.02448C1.99993 8.00821 1.99993 7.99194 2 7.97566Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default ChangeCurrency;
