import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddExtension = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 3C6 1.89543 6.89543 1 8 1C9.10457 1 10 1.89543 10 3L12 3C12.5523 3 13 3.44772 13 4V6C14.1046 6 15 6.89543 15 8C15 9.10457 14.1046 10 13 10V13C13 13.5523 12.5523 14 12 14H8.91465C8.96992 13.8436 9 13.6753 9 13.5V12.5C9 11.6716 8.32843 11 7.5 11C6.67157 11 6 11.6716 6 12.5V13.5C6 13.6753 6.03008 13.8436 6.08535 14H3C2.44771 14 2 13.5523 2 13V10H3.5C4.32843 10 5 9.32843 5 8.5C5 7.67157 4.32843 7 3.5 7H2V4C2 3.44772 2.44772 3 3 3L6 3Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);

export default AddExtension;
