import * as React from 'react';
import { get, set, isEqual } from 'lodash';

import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import {
  ACTION_CATEGORY_COPY_PASTE,
  INIT_VALUE_TYPE_CLIPBOARD,
  INIT_VALUE_TYPE_FROM_ETABLE,
  INIT_VALUE_TYPE_STATIC,
  SYSTEM_FIELD_DEFINED,
} from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';

export const useKeyboardInteraction = ({ eGridCell, backbone, gridApi, node }: any) => {
  const { onToast } = useToast();

  const [showThreeDots, setShowThreeDots] = React.useState(false);

  const keyPresses = React.useRef([]);

  const groupBy = backbone.getConfig('groupBy');

  React.useEffect(() => {
    if (eGridCell) {
      const handleFocus = () => {
        setShowThreeDots(true);
      };
      const handleBlur = () => {
        setTimeout(() => {
          setShowThreeDots(false);
        }, 0);
      };
      eGridCell.addEventListener('focus', handleFocus);
      eGridCell.addEventListener('blur', handleBlur);

      return () => {
        eGridCell.removeEventListener('focus', handleFocus);
        eGridCell.removeEventListener('blur', handleBlur);
      };
    }
  }, [eGridCell]);

  const handleReloadTable = () => {
    if (gridApi) {
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.length > 0) {
        const expanded = [];
        gridApi.forEachNode((node) => {
          if (node.expanded) {
            expanded.push(node);
          }
        });
        expanded.forEach((el) => {
          gridApi.refreshServerSideStore({ route: [el.key] });
        });
      } else {
        gridApi.refreshServerSideStore();
      }
      gridApi.deselectAll();
    }
  };

  React.useEffect(() => {
    if (eGridCell) {
      const handleKeyUp = async () => {
        const shouldClick3Dots = [['Meta', 'Enter'], ['Control', 'Enter'], ['Enter']];
        if (shouldClick3Dots.some((el) => isEqual(keyPresses.current, el))) {
          eGridCell.querySelector('.dropdown button')?.click();
        }
        if (isEqual(keyPresses.current, ['Enter'])) {
          window.dispatchEvent(new CustomEvent('executeFunction'));
        }

        const focusedCell = gridApi.getFocusedCell();
        const column = get(focusedCell, ['column', 'field'], '');

        const copyKeys = [
          ['Control', 'c'],
          ['Meta', 'c'],
        ];
        if (copyKeys.some((el) => isEqual(keyPresses.current, el)) && !document.getSelection().toString()) {
          navigator.clipboard.writeText(
            JSON.stringify({
              value: get(node, 'data', {}),
              column,
            }),
          );
        }

        const pasteKeys = [
          ['Control', 'v'],
          ['Meta', 'v'],
        ];
        if (pasteKeys.some((el) => isEqual(keyPresses.current, el))) {
          let parseClipboard = {};
          try {
            const clipboardText = await navigator.clipboard.readText();
            parseClipboard = JSON.parse(clipboardText);
          } catch {}
          const clipBoardCol = get(parseClipboard, ['column'], '');

          if (clipBoardCol && clipBoardCol === column) {
            const mapping = backbone.getConfig('mapping');
            const copyPasteAction = get(mapping, [column, 'actions'], []).find(
              ({ category }) => category === ACTION_CATEGORY_COPY_PASTE,
            );

            if (copyPasteAction) {
              const formConfig = get(copyPasteAction, 'configuration', []).find(
                ({ init_value_type }) => init_value_type === SYSTEM_FIELD_DEFINED,
              );
              const submitUrl = (
                get(formConfig, 'field_configuration', []).find(({ key }) => key === 'submit_endpoint') || { value: '' }
              ).value;

              const formFields = get(copyPasteAction, 'configuration', []).filter(
                ({ init_value_type }) => init_value_type !== SYSTEM_FIELD_DEFINED,
              );
              if (submitUrl && formFields.length) {
                const clipboardValue = get(parseClipboard, ['value'], '');

                const payload = formFields.reduce((a, b) => {
                  const payloadKey = b.field_key;

                  if (b.init_value_type === INIT_VALUE_TYPE_FROM_ETABLE) {
                    set(
                      a,
                      payloadKey.endsWith('$') ? payloadKey.slice(0, -1) : payloadKey,
                      get(node, ['data', b.init_value_value], null),
                    );
                  }
                  if (b.init_value_type === INIT_VALUE_TYPE_STATIC) {
                    set(a, payloadKey, b.init_value_value);
                  }
                  if (b.init_value_type === INIT_VALUE_TYPE_CLIPBOARD) {
                    set(a, payloadKey, clipboardValue[b.init_value_value]);
                  }
                  return a;
                }, {});

                try {
                  const response = await request.post(submitUrl, payload);

                  if (response.success || response.code == 200) {
                    handleReloadTable();

                    onToast({
                      title: 'Success',
                      messages: response.message,
                      variant: 'success',
                    });
                  } else {
                    onToast({
                      title: 'Failed',
                      messages: response.message,
                      variant: 'error',
                    });
                  }
                } catch {
                  onToast({
                    title: 'Failed',
                    messages: 'Something went wrong!',
                    variant: 'error',
                  });
                }
              }
            }
          }
        }
        keyPresses.current = [];
      };

      const handleKeyDown = (e) => {
        if (!keyPresses.current.includes(e.key)) {
          keyPresses.current.push(e.key);
        }
      };

      if (showThreeDots) {
        eGridCell.addEventListener('keyup', handleKeyUp);
        eGridCell.addEventListener('keydown', handleKeyDown);
      }

      return () => {
        eGridCell.removeEventListener('keyup', handleKeyUp);
        eGridCell.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [showThreeDots, eGridCell]);

  return {
    showThreeDots,
    setShowThreeDots,
  };
};
