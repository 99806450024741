import * as React from 'react';
import { get, uniq } from 'lodash';

import { makeStyles, Popover, Box, withStyles, Grid, Button } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';

import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { checkEpsiloTableEndpoint, EpsiloTableObject } from '@ep/insight-ui/system/backbone/data-source/common';
import HeatmapPeriodically from '../heatmap-periodically';
import HeaderList from '../../list-control/header-list/header-list';
import { handleUpdateVisible } from '@ep/insight-ui/system/helper/functions';

const useStyles = makeStyles(() => ({
  period: {
    padding: '12px 16px 8px 16px',
    maxWidth: 'calc(100vw - 60%)',
    minWidth: '250px',
    maxHeight: '90vh',
  },
  viewByHeader: {
    padding: '8px 7px',
    marginBottom: '8px',
  },
  activeButton: {
    background: '#0369C7',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#338ED8',
    },
  },
  defaultButton: {
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
}));

const ListItem = withStyles(() => ({
  root: {
    borderRadius: '4px',
    marginBottom: 8,
    paddingLeft: 15,
  },
  button: {
    // backgroundColor: 'red',
    borderRadius: '4px',
    fontStyle: ' normal',
    fontWeight: 400,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
}))(MuiListItem);

interface IPropsPeriodically {
  excludedViewBy?: any[];
  hasDimensionViewBy?: boolean;
  setDropdown?: React.Dispatch<React.SetStateAction<any>>;
  setAnchorEl?: React.Dispatch<React.SetStateAction<any>>;
  tableId?: string;
}

const Periodically = ({
  excludedViewBy = [],
  hasDimensionViewBy = false,
  setDropdown,
  setAnchorEl,
  tableId,
}: IPropsPeriodically) => {
  const classes = useStyles();

  const backboneContext = React.useContext(TableBackboneContext);

  const isPeriodically = backboneContext.getVisibility('periodically');
  const visualizationType = backboneContext.getConfig('visualizationType');
  const chartType = get(backboneContext, ['config', 'chartConfig', 'config', 'chartType'], '');
  const useDimensionXAxis =
    get(backboneContext, ['config', 'chartConfig', 'config', 'useDimensionXAxis'], 'no') === 'yes';
  const isChart = visualizationType === 'chart';

  const endpoint = get(backboneContext.getConfig('endpoint'), 'GET_TABLE_DATA', '');
  const valuePeriod = backboneContext.getConfig('groupPeriod', '');
  const mapping = backboneContext.getConfig('mapping');
  const valueGroupDimension = backboneContext.getConfig('groupBy', { columns: [] });
  const viewByTimelineConfig = get(backboneContext.getConfig('viewByConfig'), 'timeline', []);
  const viewByDimensionConfig = get(backboneContext.getConfig('viewByConfig'), 'dimension', []);
  const allowedViewByTimelineType = backboneContext.getConfig('viewByConfig.allowedViewByTimelineType', [
    'timeline',
    'dimension1',
    'dimension2',
  ]);
  const [values, setValues] = React.useState(() => {
    const groupPeriod = backboneContext.getConfig('groupPeriod');
    let dimension1;
    if (isChart) {
      dimension1 = get(backboneContext.getConfig('groupBy'), ['columns', 0], null);
    } else {
      dimension1 = backboneContext.getConfig('groupDimension', null);
      if (dimension1) {
        dimension1 = [].concat(dimension1)[0];
      }
    }
    const dimension2 = get(backboneContext.getConfig('groupBy'), ['columns', 1], null);

    return {
      groupPeriod,
      dimension1,
      dimension2,
    };
  });
  const periodicallyOptions = (backboneContext.getTimePeriodicallyOptions() || []).filter((el) => {
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
      return !excludedViewBy.includes(el.value);
    }
    return viewByTimelineConfig.includes(el.value);
  });
  const enhancePeriodicallyOptions = React.useMemo(() => {
    const mapping = backboneContext.getConfig('mapping');
    const storefrontMetricCol = Object.keys(mapping).find(
      (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
    );

    const isAllowGroupBy = get(mapping, [storefrontMetricCol, 'staticValue', 'allowGroupby'], 0);

    const additionalPeriodicallyOptions =
      hasDimensionViewBy || (storefrontMetricCol && isAllowGroupBy)
        ? Object.keys(mapping)
            .filter(
              (el) =>
                mapping[el]?.['propertyType'] == 'dimension' &&
                mapping[el]?.['cellFormat'] != 'filterColumnFormat' &&
                viewByDimensionConfig.includes(el),
            )
            .map((el) => {
              return {
                label: mapping[el].title,
                value: el,
                type: 'dimension',
              };
            })
            .concat({
              label: 'None',
              value: EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE,
              type: 'dimension',
            })
        : [];
    return [...periodicallyOptions, ...additionalPeriodicallyOptions];
  }, [periodicallyOptions, endpoint, backboneContext]);

  const handleClose = () => {
    if (isPeriodically) {
      backboneContext.changeVisibility('periodically', false);
    }
    handleUpdateVisible(tableId, 'periodically', false);
    if (setDropdown) {
      setDropdown({ open: false, optionComponent: null });
    }
    if (setAnchorEl) {
      setAnchorEl(null);
    }
  };

  const onSubmit = () => {
    if (isChart) {
      if (useDimensionXAxis) {
        if (values.groupPeriod) {
          backboneContext.changeConfig('groupPeriod', values.groupPeriod);
        } else {
          backboneContext.changeConfig('groupBy', { columns: [values.dimension1] });
          backboneContext.changeConfig('groupPeriod', 'all');
        }
      } else {
        backboneContext.changeConfig('groupPeriod', values.groupPeriod);
        backboneContext.changeConfig('groupBy', { columns: [values.dimension1, values.dimension2] });
      }
    } else {
      if (values.groupPeriod) {
        backboneContext.changeConfig('groupPeriod', values.groupPeriod);
        backboneContext.changeConfig(`groupDimension`, null);
      } else {
        backboneContext.changeConfig('groupPeriod', 'all');
        backboneContext.changeConfig(`groupDimension`, [values.dimension1]);
      }
    }
    handleClose();
  };

  const dimensionOption = enhancePeriodicallyOptions.filter((i) => i.type === 'dimension');

  if (chartType === 'heatmap' && isChart) {
    return (
      <HeatmapPeriodically
        enhancePeriodicallyOptions={enhancePeriodicallyOptions}
        handleClose={handleClose}
        valuePeriod={valuePeriod}
        classes={classes}
      />
    );
  }
  return (
    <div className={classes.period}>
      <Grid container style={{ columnGap: '16px' }}>
        {allowedViewByTimelineType.includes('timeline') ? (
          <Grid
            item
            style={{
              minWidth: '120px',
              width:
                dimensionOption.length === 0 ||
                (!allowedViewByTimelineType.includes('dimension1') && !allowedViewByTimelineType.includes('dimension2'))
                  ? '100%'
                  : null,
            }}
          >
            <Box className={classes.viewByHeader}>
              <HeaderList title={'Time'} />
            </Box>
            {enhancePeriodicallyOptions
              .filter((i) => i.type === undefined)
              .map((el, index) => (
                <div key={index}>
                  <ListItem
                    button
                    className={values.groupPeriod === el.value ? classes.activeButton : classes.defaultButton}
                    onClick={() => {
                      if (isChart) {
                        if (useDimensionXAxis) {
                          setValues((prev) => {
                            return {
                              ...prev,
                              dimension1: null,
                              dimension2: null,
                            };
                          });
                        } else {
                          setValues((prev) => {
                            return {
                              ...prev,
                              groupPeriod: el.value,
                            };
                          });
                        }
                      } else {
                        setValues((prev) => {
                          return {
                            groupPeriod: el.value,
                            dimension1: null,
                            dimension2: null,
                          };
                        });
                      }
                    }}
                  >
                    {el.label}
                  </ListItem>
                </div>
              ))}
          </Grid>
        ) : null}
        {dimensionOption.length > 0 && (
          <React.Fragment>
            {allowedViewByTimelineType.includes('dimension1') ? (
              <Grid
                item
                style={{
                  minWidth: '120px',
                  width:
                    !allowedViewByTimelineType.includes('timeline') && !allowedViewByTimelineType.includes('dimension2')
                      ? '100%'
                      : null,
                }}
              >
                <Box className={classes.viewByHeader}>
                  <HeaderList title={useDimensionXAxis || !isChart ? 'Dimension' : 'Dimension 1'} />
                </Box>
                {dimensionOption.map((el, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      className={values.dimension1 == el.value ? classes.activeButton : classes.defaultButton}
                      onClick={() => {
                        if (isChart) {
                          if (useDimensionXAxis) {
                            setValues((prev) => {
                              return {
                                ...prev,
                                groupPeriod: 'all',
                                dimension1: el.value,
                              };
                            });
                          } else {
                            setValues((prev) => {
                              return {
                                ...prev,
                                dimension1: el.value,
                              };
                            });
                          }
                        } else {
                          setValues((prev) => {
                            return {
                              groupPeriod: null,
                              dimension1: el.value,
                              dimension2: null,
                            };
                          });
                        }
                      }}
                    >
                      {el.label}
                    </ListItem>
                  </div>
                ))}
              </Grid>
            ) : null}
            {!useDimensionXAxis && allowedViewByTimelineType.includes('dimension2') ? (
              <Grid
                item
                style={{
                  minWidth: '120px',
                  width:
                    !allowedViewByTimelineType.includes('timeline') && !allowedViewByTimelineType.includes('dimension1')
                      ? '100%'
                      : null,
                }}
              >
                <Box className={classes.viewByHeader}>
                  <HeaderList title={'Dimension 2'} />
                </Box>
                {dimensionOption.map((el, index) => (
                  <div key={index}>
                    <ListItem
                      button
                      className={
                        (!isChart && valuePeriod === el.value) || (isChart && values.dimension2 == el.value)
                          ? classes.activeButton
                          : classes.defaultButton
                      }
                      onClick={() => {
                        if (isChart) {
                          setValues((prev) => {
                            return {
                              ...prev,
                              dimension2: el.value,
                            };
                          });
                        } else {
                          setValues((prev) => {
                            return {
                              groupPeriod: el.value,
                              dimension1: null,
                              dimension2: null,
                            };
                          });
                        }
                      }}
                    >
                      {el.label}
                    </ListItem>
                  </div>
                ))}
              </Grid>
            ) : null}
          </React.Fragment>
        )}
      </Grid>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} sx={{ margin: '8px 0' }}>
        <Box display={'flex'} alignItems={'center'} sx={{ columnGap: '12px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant={'contained'} color={'primary'} onClick={onSubmit}>
            Apply
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Periodically;
