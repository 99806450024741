import React from 'react';
import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import { BulletChart } from '@carbon/charts-react';
import { ChartTabularData, BulletChartOptions } from '@carbon/charts/interfaces';
import '@carbon/charts/styles.css';
import './style.css';
import { Box } from '@material-ui/core';

interface ChartProps {
  data: ChartTabularData;
  options: BulletChartOptions;
  refElement: any;
}

const useStyles = makeStyles(() => ({
  loot: TooltipMapping.loot as CreateCSSProperties,
  wrapperChart: {
    // width: '436px',
    // height: '436px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Bullet = ({ data, options, refElement }: ChartProps) => {
  const classes = useStyles();
  React.useEffect(() => {
    if (!refElement) return;
    refElement.current.chartRef.id = 'BulletChart';
  }, [refElement]);
  return (
    <Box className={classes.wrapperChart}>
      <BulletChart ref={refElement} data={data} options={options} />
    </Box>
  );
};
