import { EIP_CONSTANT, aim } from '@eip/next/lib/main';
import { getPageUrn } from '../global';
import { EO2_SYSTEM_PAGE_ID } from '../system-page/constant';

const { isAllow } = aim;

export const hardcodeDataMenu = [
  // {
  //   label: 'Favorite',
  //   nodeId: 'favorite',
  //   tabs: [{ label: 'Campaign management', nodeId: 'campaign-management' }],
  // },
  {
    label: 'Report and communication',
    nodeId: 'report-and-communication',
    tabs: [
      { label: 'Advertising report', disable: true },
      { label: 'Report center', disable: true },
    ],
  },
  {
    label: 'Marketplace advertising',
    nodeId: 'marketplace_advertising',
    tabs: [
      {
        label: 'Keyword discovery',
        nodeId: EO2_SYSTEM_PAGE_ID.marketplaceAdvertising.keywordDiscovery,
        type: 'page',
        locked: !ff.ui_page_keyword_analysis,
      },
      {
        label: 'Competitors tracking',
        locked: true,
      },
    ],
  },
  {
    label: 'Multichannel selling',
    nodeId: 'multichannel-selling',
    tabs: [
      {
        label: 'Catalog management',
        disable: true,
      },
      {
        label: 'Inventory management',
        disable: true,
      },
      {
        label: 'Promotion',
        disable: true,
      },
      {
        label: 'Pricing',
        disable: true,
      },
      {
        label: 'Warehouse management',
        disable: true,
      },
    ],
  },
  ...(ff.mass_operation_management
    ? [
        {
          label: 'Bulk operation',
          nodeId: 'mass-operation',
          tabs: [
            {
              label: 'Campaign management',
              nodeId: EO2_SYSTEM_PAGE_ID.massOperation.campaignManagement,
              type: 'page',
            },
          ],
        },
      ]
    : []),
  // ...(ff.adoption_rate_table
  //   ? [
  //       {
  //         label: 'Product health',
  //         nodeId: 'product-health',
  //         tabs: [
  //           {
  //             label: 'Adoption rate',
  //             nodeId: EO2_SYSTEM_PAGE_ID.productHealth.adoptionRate,
  //             type: 'page',
  //           },
  //         ],
  //       },
  //     ]
  //   : []),
  // {
  //   label: 'Storefront & users',
  //   nodeId: 'storefront_users',
  //   tabs: [
  //     {
  //       label: 'Profile setting',
  //       icon: 'user',
  //     },
  //     {
  //       label: 'Workspace management',
  //       icon: 'workSpace',
  //     },
  //   ],
  // },
];

if (isAllow()) {
  hardcodeDataMenu.push({
    label: 'Community',
    nodeId: 'community',
    tabs: [
      {
        label: 'Marketer Community',
        nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.marketerCommunity,
        type: 'page',
      },
      {
        label: 'Developer network',
        disable: true,
      },
    ],
  });
  if (ff.lowcode)
    hardcodeDataMenu.push({
      label: 'Botep',
      nodeId: 'botep',
      tabs: [
        {
          label: 'Adoption rate',
          nodeId: EO2_SYSTEM_PAGE_ID.productHealth.adoptionRate,
          type: 'page',
        },
        {
          label: 'Add',
          nodeId: 'botep_addnew',
          type: 'btn_create_system_page',
        },
      ],
    });
}

export const eosMenu = {
  label: 'EOS',
  nodeId: 'eos',
  tabs: [
    {
      label: 'GTM, Revenue',
      nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.gtmRevenue,
      type: 'page',
    },
    {
      label: 'GTM, Sale',
      nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.gtmSale,
      type: 'page',
    },
    {
      label: 'GTM, Solution',
      nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.gtmSolution,
      type: 'page',
    },
    {
      label: 'GTM, Managed Service',
      nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.gtmManagedService,
      type: 'page',
    },
    {
      label: 'Investor MIS',
      nodeId: EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.gtmInvestorMIS,
      type: 'page',
    },
  ],
};

export const dataMenuTopBar = [
  {
    title: 'Company management',
    icon: 'workSpace',
    onClick: () => {
      window.location.href = getPageUrn('/page/' + EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.companyManagement);
    },
  },
  ...(ff.next_left_menu
    ? []
    : [
        {
          title: 'My profile',
          icon: 'user',
          disable: true,
          onClick: () => {
            window.location.href = getPageUrn('/page/' + EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.myProfile, true);
          },
        },
        {
          title: 'Subscription',
          icon: 'lightBulbStar',
          disable: true,
          onClick: () => undefined,
        },
      ]),
];

export const dataMenuFooter = (Form) => [
  [
    {
      title: 'What’s new?',
      icon: 'addgift',
      disable: false,
      onClick: () => {
        window.open('https://www.notion.so/What-s-new-Epsilo-One-208187736903412d81285c8547b3faab?pvs=4', '_blank');
      },
    },
    {
      title: 'Feature request',
      icon: 'addrequest',
      onClick: () => {
        window.location.href = '/feature-request';
      },
      isPopupMenu: false,
    },
    {
      title: 'Submit a support ticket',
      icon: 'ic/ep:tickets',
      onClick: () => window.open('https://support.epsilo.io/support/tickets/new', '_blank'),
    },
    {
      title: 'Rate us on G2',
      icon: 'ic/simple-icons:g2',
      onClick: () => window.open('https://www.g2.com/products/epsilo/reviews', '_blank'),
    },
    {
      title: 'Review us on Gartner',
      icon: 'gartner',
      onClick: () => window.open('https://gtnr.io/J2OtHy5I7', '_blank'),
    },
    {
      title: 'FAQ',
      disable: false,
      icon: 'importcontacts',
      onClick: () => window.open('https://support.epsilo.io', '_blank'),
    },
    {
      title: 'Get iOS & Android app',
      icon: 'addos',
      disable: true,
      onClick: () => undefined,
    },
    {
      title: 'Get browser plugins',
      icon: 'addextension',
      disable: true,
      onClick: () => undefined,
    },
  ],
  [
    {
      title: 'Visit our website',
      onClick: () => {
        window.open('https://epsilo.io/');
      },
    },
    {
      title: 'Join us',
      onClick: () => undefined,
      disable: true,
    },
  ],
];

export const dataMenuPage: (pageId) => any[] = (pageId) => {
  return [
    [
      {
        title: 'Edit this page',
        icon: 'pencil',
        onClick: () => window.open(`/page/${pageId}/edit`),
        disable: !aim.canAccess('page:edit'),
      },
      {
        title: 'Add to Favorite',
        icon: 'star',
        onClick: () => undefined,
        disable: true,
      },
      {
        title: 'Copy link',
        icon: 'duplicate',
        disable: true,
        onClick: () => undefined,
      },
      {
        title: 'Page history',
        icon: 'history',
        onClick: () => undefined,
        disable: true,
      },
      {
        title: 'Delete',
        icon: 'delete',
        onClick: () => undefined,
        disable: true,
      },
      {
        title: 'Export',
        icon: 'export',
        onClick: () => undefined,
        disable: true,
      },
    ],
  ];
};
