export const USER_DEMO_RES = {
  data: {
    header: ['first_name', 'last_name', 'email', 'company', 'permission', 'assigned_date'],
    primaryKeys: ['id'],
    rows: [
      {
        id: '1',
        first_name: 'Josphine',
        last_name: 'Villanueva',
        email: 'Gregory.Jon@outlook.com',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '01/11/2021',
      },
      {
        id: '2',
        first_name: 'Beckie',
        last_name: 'Silvestrini',
        email: 'Maria.Rodney@gmail.com',
        company: 'L’Oreal',
        permission: 'Full access',
        assigned_date: '01/11/2021',
      },
      {
        id: '3',
        first_name: 'An',
        last_name: 'Fritz',
        email: 'Bradley.Kelsey@hotmail.com',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '01/04/2021',
      },
      {
        id: '4',
        first_name: 'Albina',
        last_name: 'Glick',
        email: 'Dana.Rafael@gmail.com',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '01/06/2021',
      },
      {
        id: '5',
        first_name: 'Zona',
        last_name: 'Colla',
        email: 'Trevor.Micah@gmail.com',
        company: 'L’Oreal',
        permission: 'Full access',
        assigned_date: '03/11/2021',
      },
      {
        id: '6',
        first_name: 'Lili',
        last_name: 'Paskin',
        email: 'Jorge.Nicolas@gmail.com',
        company: 'L’Oreal',
        permission: 'Editor',
        assigned_date: '21/06/2021',
      },
      {
        id: '7',
        first_name: 'Kayleigh',
        last_name: 'Lace',
        email: 'Brian.Sabrina@epsilo.io',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '04/11/2021',
      },
      {
        id: '8',
        first_name: 'Golda',
        last_name: 'Kaniecki',
        email: 'Henry.Francis@outlook.com',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '28/10/2021',
      },
      {
        id: '9',
        first_name: 'Beckie',
        last_name: 'Mirafuentes',
        email: 'George.Ann@gmail.com',
        company: 'L’Oreal',
        permission: 'Viewer',
        assigned_date: '21/06/2021',
      },
      {
        id: '10',
        first_name: 'Justine',
        last_name: 'Mugnolo',
        email: 'Jerry.Hillary@hotmail.com',
        company: 'L’Oreal',
        permission: 'Editor',
        assigned_date: '21/06/2021',
      },
    ],
    pagination: { page: 1, limit: 100, total: 429 },
  },
  message: 'OK',
  code: 200,
};
