import React from 'react';

import { makeStyles } from '@material-ui/core';

import HeaderRight from '@ep/insight-ui/elements/table/table-actions/header-right';
import ExpansionSection from '@ep/insight-ui/elements/intraday/expansion-section';
import SectionActions from '@ep/insight-ui/elements/intraday/section-actions';
import TreemapChart from '@ep/insight-ui/chartlib/treemap-chart';
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { dropdownViewOption } from '@ep/insight-ui/elements/intraday/section-dropdown-view';
import { ITreeMapDataChart } from '@ep/insight-ui/chartlib/type';

const useStyles = makeStyles({
  tableWrapper: {
    padding: 8,
    width: '100%',
    border: '1px solid #d0d0d0',
  },
  container: {
    padding: '48px',
  },
  treemap: {
    padding: '6px',
    borderRadius: '16px',
    border: '0.5px solid #C2C7CB',
    marginBottom: '24px',
  },
  toggle: {},
});

export const treemapData: ITreeMapDataChart[] = [
  {
    name: "L'Oreal",
    children: [
      {
        name: 'Vichy',
        value: 5_000,
        showLabel: true,
      },
      {
        name: 'La Roche Posay',
        value: 15_000,
        showLabel: true,
      },
      {
        name: "L'Oreal Paris",
        value: 25_000,
        showLabel: true,
      },
    ],
  },
  {
    name: 'Armore Pacific',
    children: [
      {
        name: 'Innisfree',
        value: 6500,
        showLabel: true,
      },
      {
        name: 'Laneige',
        value: 5500,
        showLabel: true,
      },
      {
        name: 'Sulwashoo',
        value: 2000,
        showLabel: true,
      },
      {
        name: 'qwe',
        value: 500,
        showLabel: true,
      },
      {
        name: 'tyu',
        value: 500,
        showLabel: true,
      },
    ],
  },
  {
    name: 'Shiseido',
    children: [
      {
        name: 'ZA',
        value: 5000,
        showLabel: true,
      },
      {
        name: 'Baum',
        value: 4500,
        showLabel: true,
      },
      {
        name: 'Anessa',
        value: 4000,
        showLabel: true,
      },
    ],
  },
  {
    name: 'Beiersdorf',
    children: [
      {
        name: 'Nivea',
        value: 7500,
        showLabel: true,
      },
      {
        name: 'Eucerin',
        value: 6500,
        showLabel: true,
      },
    ],
  },
  {
    name: 'Estee Lauder',
    children: [
      {
        name: 'Clinique',
        value: 3500,
        showLabel: true,
      },
      {
        name: 'MAC',
        value: 2000,
        showLabel: true,
      },
      {
        name: 'ABC',
        value: 500,
        showLabel: true,
      },
      {
        name: 'ABCD',
        value: 500,
        showLabel: true,
      },
      {
        name: 'ABCE',
        value: 500,
        showLabel: true,
      },
      {
        name: 'ABCF',
        value: 250,
        showLabel: true,
      },
      {
        name: 'ABCG',
        value: 250,
        showLabel: true,
      },
    ],
  },
  {
    name: 'Others',
    children: [
      {
        name: 'AHC',
        value: 1500,
        showLabel: true,
      },
      {
        name: 'AHCD',
        value: 1500,
        showLabel: true,
      },
      {
        name: 'AHCE',
        value: 250,
        showLabel: true,
      },
      {
        name: 'AHCF',
        value: 250,
        showLabel: true,
      },
    ],
  },
];

const analysisOptions: dropdownViewOption[] = [{ id: 'share-of-search', name: 'Share of Search' }];
const analysisSelected: dropdownViewOption = { id: 'share-of-search', name: 'Share of Search' };

const ETreemapChart = () => {
  const classes = useStyles();

  const stateChart: IStateChartValue = 'success';

  return (
    <ExpansionSection
      defaultExpanded={true}
      label={'Share of Shelf analysis'}
      className={classes.toggle}
      noSpacer
      headerRight={<HeaderRight />}
    >
      <SectionActions options={analysisOptions} selected={analysisSelected} section={`hashtag`} />
      <div className={classes.treemap}>
        <TreemapChart keyChart={'tree'} data={treemapData} stateChart={stateChart} title={'#SKINCARE'} />
      </div>
    </ExpansionSection>
  );
};

export default ETreemapChart;
