import * as React from 'react';
import clsx from 'clsx';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  makeStyles,
  Box,
  Button,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  List,
  Divider,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import { searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';

import MenuItem from './menu-item';
import { useCustomTag } from './hooks/use-custom-tag';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';

const useStyles = makeStyles(() => ({
  container: {},
  popover: {
    minWidth: '247px',
  },
  dropdownBtn: (props) => ({
    background: props.isDarkMode ? '#253645' : '#F6F7F8',
    borderRadius: '4px',
    height: '32px',
    paddingRight: '6px',
    '& .eip1-MuiButton-label': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      columnGap: '6px',
    },
    ...(props.isDarkMode
      ? {
          '& .iconDropDown': {
            color: '#FFFFFF',
          },
          '& .itemLabel': {
            color: '#FFFFFF',
          },
          '&:hover': {
            '& .iconDropDown': {
              color: '#253746',
            },
            '& .itemLabel': {
              color: '#253746',
            },
          },
        }
      : {}),
  }),
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
    '& input::placeholder': {
      textTransform: 'unset',
    },
  },
  textSearch: {
    '& input': {
      height: 30,
      width: '100%',
      paddingRight: '22px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  items: {
    display: 'flex',
    padding: '8px 0',
    '& ul': {
      width: '100%',
    },
  },
  divider: {
    margin: '8px 0',
  },
  newItemBtn: {
    width: '100%',
    height: '32px',
    '& .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      fontWeight: 'normal',
      justifyContent: 'flex-start',
    },
  },
  listItems: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    maxHeight: '232px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  tooltip: {
    maxWidth: '245px',
    marginLeft: '15px',
    padding: '0px',
    '& p': {
      fontSize: '11px',
      marginBottom: '0px',
      lineHeight: '15px',
      padding: '8px',
    },
  },
  threeDots: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchField: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
  },
  itemLabel: {
    whiteSpace: 'nowrap',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));

const CustomTag = (props) => {
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const classes = useStyles({ isDarkMode });

  const {
    items,
    inputRef,
    searchValue,
    setSearchValue,
    selectItem,
    addNewItem,
    handleDuplicateItem,
    updateItem,
    deleteItem,
    moveCard,
    allowDrag,
    isLoading,
    isItemExisted,
  } = useCustomTag(props);

  const itemsRender = React.useMemo(() => {
    return items
      .filter((item) => !inputRef || item.label.toLowerCase().includes(searchValue.toLowerCase()))
      .map((item, index) => {
        return (
          // Do not allow user to move item when searchValue is not empty
          <ConditionalWrap
            key={item.value}
            condition={!searchValue && allowDrag}
            wrap={(children) => (
              <DragCard index={index} moveCard={moveCard}>
                {children}
              </DragCard>
            )}
          >
            <MenuItem
              item={item}
              selectItem={selectItem}
              handleDuplicateItem={handleDuplicateItem}
              updateItem={updateItem}
              deleteItem={deleteItem}
              allowDrag={allowDrag}
            />
          </ConditionalWrap>
        );
      });
  }, [items, searchValue]);

  return (
    <Box className={classes.popover}>
      <FormControl fullWidth className={classes.searchField}>
        <TextField
          style={{
            position: 'sticky',
            top: '0px',
            zIndex: 1,
            backgroundColor: '#fff',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
          className={`${clsx(classes.textSearch, classes.textStyle)}`}
          placeholder={'Find a item'}
          onChange={(e) => {
            setSearchValue(e.target.value);
          }}
          value={searchValue}
          variant={'outlined'}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search style={{ width: 12, height: 14 }} />
              </InputAdornment>
            ),
            endAdornment: searchValue && (
              <IconButton onClick={() => setSearchValue('')} className={classes.closeBtn}>
                <Icon type={'closeCircle'} />
              </IconButton>
            ),
            classes: { input: classes['input'] },
            fullWidth: true,
          }}
          inputRef={inputRef}
        />
      </FormControl>
      <Box className={classes.items} sx={{ justifyContent: itemsRender.length > 0 ? 'flex-start' : 'center' }}>
        <LoadingComponent loading={isLoading}>
          {itemsRender.length > 0 ? (
            <DndProvider context={window} backend={HTML5Backend}>
              <List className={classes.listItems}>{itemsRender}</List>
            </DndProvider>
          ) : (
            <span style={{ color: '#8C98A4' }}>No results</span>
          )}
        </LoadingComponent>
      </Box>
      <Divider className={classes.divider} />
      <Button className={classes.newItemBtn} onClick={() => addNewItem(!isItemExisted ? searchValue : '')}>
        <span>
          <Icon type="plus" style={{ width: '9px', height: '9px' }} />
        </span>
        <span>New {!isItemExisted && searchValue ? searchValue : 'item'}</span>
      </Button>
      {props.errorMessage && <span className={classes.textError}>{props.errorMessage}</span>}
    </Box>
  );
};

export default CustomTag;
