import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import DotChartResponsive from './dot-chart';
import { funcConfigs } from './hooks';
import { colorsChartLegend, defaultOptions, tool } from '../helper';
import { IDataChart } from '../type';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { TSize } from '../chart-size';
type Props = {
  data: IDataChart;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
};
const DotChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
}: Props) => {
  const [dataChart, setDataChart] = React.useState<{ dataList: any; optionList: any }>({
    dataList: null,
    optionList: null,
  });
  const { externalTooltipHandler } = funcConfigs();
  React.useEffect(() => {
    if (!data) return;
    const now = moment();
    const datasets = data.rows.map((item, index) => ({
      label: item.label,
      data: item.data.map((it) => it.value),
      baseColor: colorsChartLegend[index].stroke,
      pointBorderColor: colorsChartLegend[index].stroke,
      pointHoverBorderColor: colorsChartLegend[index].pointBorderColor,
      pointBackgroundColor: colorsChartLegend[index].pointBorderColor,
      pointHoverBackgroundColor: colorsChartLegend[index].stroke,
      showLine: false,
    }));
    const dataList = {
      labels: data.headers,
      datasets,
    };
    //Display option chart
    const { DOT: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    _.set(optionList, 'plugins.tooltip.callbacks.label', tool.dotCallbacks.label({ currency }));
    _.set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    if (maxValues) {
      _.set(optionList, 'scales.y.suggestedMax', maxValues);
    }
    setDataChart({ dataList, optionList });
  }, [data]);

  return (
    <DotChartResponsive
      stateChart={stateChart}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
      keyChart={keyChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
    />
  );
};

export default DotChart;
