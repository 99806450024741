let apiPassportUrl;
let apiUrl;
let apiInsightUrl;
let apiEipManagerUrl;
let apiGrpcGateway;
let apiAuthenticationUrl;
let apiDataCenter;
let apmServer;
let authWS;
let rayUrl;
let apiCdn;
let apiHA;

if (process.env.API_URL) {
  apiUrl = process.env.API_URL;
} else if (process.env.STORYBOOK_API_URL) {
  apiUrl = process.env.STORYBOOK_API_URL;
} else {
  apiUrl = '/staging-onex-api.epsilo.io';
}

if (process.env.API_PASSPORT_URL) {
  apiPassportUrl = process.env.API_PASSPORT_URL;
} else if (process.env.STORYBOOK_PASSPORT_URL) {
  apiPassportUrl = process.env.STORYBOOK_PASSPORT_URL;
} else {
  apiPassportUrl = '/staging-passport.epsilo.io';
}

if (process.env.API_INSIGHT_URL) {
  apiInsightUrl = process.env.API_INSIGHT_URL;
} else {
  apiInsightUrl = '/staging-insight-api.epsilo.io';
}

if (process.env.API_EIP_MANAGER_URL) {
  apiEipManagerUrl = process.env.API_EIP_MANAGER_URL;
} else {
  apiEipManagerUrl = '/staging-eip-manager-auth0.epsilo.io';
}

if (process.env.API_GRPC_GATEWAY) {
  apiGrpcGateway = process.env.API_GRPC_GATEWAY;
} else {
  apiGrpcGateway = '/staging-grpc-gateway.epsilo.io';
}

if (process.env.API_AUTHENTICATION) {
  apiAuthenticationUrl = process.env.API_AUTHENTICATION;
} else {
  apiAuthenticationUrl = '/staging-sso.epsilo.io';
}

if (process.env.API_DATA_CENTER) {
  apiDataCenter = process.env.API_DATA_CENTER;
} else {
  apiDataCenter = '/staging-datacenter.epsilo.io';
}

if (process.env.APM_SERVER) {
  apmServer = process.env.APM_SERVER;
} else {
  apmServer = '/staging-apm.epsilo.io';
}

if (process.env.AUTH_WS) {
  authWS = process.env.AUTH_WS;
} else {
  authWS = '/staging-auth-ws.epsilo.io';
}

if (process.env.RAY_URL) {
  rayUrl = process.env.RAY_URL;
} else {
  rayUrl = '/ray.epsilo.io';
}

if (process.env.API_CDN) {
  apiCdn = process.env.API_CDN;
} else {
  apiCdn = 'https://cdn.epsilo.io';
}

if (process.env.API_HYPER_AUTOMATION) {
  apiHA = process.env.API_HYPER_AUTOMATION;
} else {
  apiHA = '/staging-hyper-automation.epsilo.io';
}

export const API_URL = apiUrl;
export const API_PASSPORT_URL = apiPassportUrl;
export const API_INSIGHT_URL = apiInsightUrl;
export const API_EIP_MANAGER_URL = apiEipManagerUrl;
export const API_GRPC_GATEWAY = apiGrpcGateway;
export const API_AUTHENTICATION = apiAuthenticationUrl;
export const API_DATA_CENTER = apiDataCenter;
export const APM_SERVER = apmServer;
export const AUTH_WS = authWS;
export const RAY_URL = rayUrl;
export const API_CDN = apiCdn;
export const API_HA = apiHA;
