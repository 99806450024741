/* eslint-disable react/prop-types */
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Grid, Box, List, ListItem, Tooltip, Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { isEmpty, get } from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import { enhanceCellAction } from '../table-helper';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '10px',
    border: '1px solid #CCE3F5',
    background: '#EBF6FF',
    color: '#000',
    marginRight: '4px',
    borderRadius: '4px',
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export type IValueTextFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueTextFormat>;
};
const TextFormat = (props: IPropsFormat<IValueTextFormat>) => {
  const classes = useStyles();
  const cellAction = get(props, 'cellAction', []);
  const node = get(props, 'node', null);
  const value = get(props, 'value', null);

  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const edot = get(props, ['value', 'edot'], []);

  const label = toValue(get(props, ['value', 'label'], null), props.value);
  const status = toValue(get(props, ['value', 'status'], ''), props.value);
  const subtext = toValue(get(props, ['value', 'subtext'], ''), props.value);
  const hashtag = toValue(get(props, ['value', 'hashtag'], ''), props.value);
  const disabled = toValue(get(props, 'value.disabled', false), props.value);
  const avatar = toValue(get(props, ['value', 'avatar'], ''), props.value);
  const customTooltip = toValue(get(props, ['value', 'tooltip'], ''), props.value);

  const customStyle = React.useMemo(() => {
    const styles = Object.keys(value || {})
      .filter((el) => el.startsWith('style.'))
      .reduce((a, b) => {
        const styleName = b.replace('style.', '').replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
        let styleValue = value && value[b] ? toValue(value[b], value) : null;

        if (styleName === 'background' || styleName.includes('color')) {
          if (styleValue === 'auto') {
            styleValue = typeof label == 'string' ? stringToTagColour(label) : null;
          }
        }
        if (styleValue) {
          return {
            ...a,
            [styleName]: styleValue,
          };
        }
        return a;
      }, {});

    return styles;
  }, [value, label]);

  const backbone = React.useContext(TableBackboneContext);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(cellAction, edot, props.field, backbone)}
      node={node}
      value={value}
      isGrouped={isGrouped}
      disabledMenu={disabled}
    >
      <ConditionalWrap
        condition={customTooltip}
        wrap={(children) => (
          <Tooltip
            title={String(customTooltip)
              .split('\n')
              .map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    {i}
                    <br />
                  </React.Fragment>
                );
              })}
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        <Grid
          container
          alignItems={'center'}
          wrap={'nowrap'}
          className={classes.height100}
          style={{ overflow: 'hidden' }}
        >
          {/* text */}
          <Grid item style={{ width: '100%', height: '100%' }}>
            <Grid container alignItems="center" wrap={'nowrap'} className={classes.height100}>
              {/* status icon */}
              {!isEmpty(status) ? (
                <Grid
                  item
                  style={{ marginRight: !isEmpty(status) ? '5px' : '', flexShrink: 0, flexGrow: 0, flexBasis: '16px' }}
                >
                  <span className={classes.wrapperIcon}>
                    <Icon type={String(status) as IconType} size={16} className={`${classes.icon} status`} />
                  </span>
                </Grid>
              ) : null}
              {avatar ? <Avatar src={avatar} className={classes.avatar} /> : null}
              <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
                <Grid
                  container
                  alignItems={'center'}
                  direction={'column'}
                  wrap={'nowrap'}
                  className={clsx(classes.height100, classes.alignItemsStart)}
                >
                  {/*hashtag*/}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.hashtag} ${disabled && 'disabled'}`}>{hashtag}</span>
                  </Grid>
                  {/* label */}
                  <Grid item xs={12} className={classes.height100}>
                    {Object.keys(customStyle).length > 0 ? (
                      <Box
                        className={`${classes.label} ${disabled && 'disabled'}`}
                        style={{ padding: '0 6px', borderRadius: '4px', ...customStyle }}
                      >
                        <span>{label}</span>
                      </Box>
                    ) : (
                      <span className={`${classes.label} ${disabled && 'disabled'}`}>{label}</span>
                    )}
                  </Grid>
                  {/* subtext */}
                  <Grid item xs={12} className={classes.height100}>
                    <span className={`${classes.subtext} ${disabled && 'disabled'}`}>{subtext ? subtext : ''}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrap>
    </WrapperFormat>
  );
};
export default TextFormat;
