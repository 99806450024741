import { isFunction } from 'lodash';
import { isFormulaField, toValue } from '../util/excel-formula';
import { stripUniversalPrefix } from '../util/column';
import { FormulaUpstream } from './formula-upstream';

export async function calculateValueGetter({
  rows,
  columns,
  groupedFields = [],
  drillDowns = [],
  resourceMetric = [],
  formulaUpstream,
}: {
  rows: any[];
  columns: any[];
  groupedFields: any[];
  drillDowns: any[];
  resourceMetric: any[];
  formulaUpstream: FormulaUpstream;
}) {
  const fieldColCalculate = columns.reduce((carry, col) => {
    const valueGetter = col.cell.valueGetter;
    const mapOrder = [];
    for (const [key, field] of Object.entries<string>(valueGetter)) {
      if (isFunction(field)) {
        mapOrder.push([key, field]);
      } else if (isFormulaField(field) && /UCAL/.test(field)) {
        mapOrder.push([
          key,
          (row) => {
            formulaUpstream &&
              formulaUpstream.addFormula(field, {
                _eTable: {
                  field: col.field,
                  valueGetterField: key,
                  rowIds: Object.keys(row)
                    .filter((k) => k.indexOf('.id') > -1)
                    .reduce((carry, k) => {
                      return { ...carry, [k]: row[k] };
                    }, {}),
                  row,
                },
              });
            return '...';
          },
        ]);
      } else if (isFormulaField(field)) {
        mapOrder.push([key, (row) => toValue(field, row)]);
      } else if (groupedFields.length > 0 && drillDowns.length === 0 && ['LIST', 'RANGE'].indexOf(col.aggFunc) > -1) {
        mapOrder.unshift([
          key,
          (row) => {
            try {
              return typeof row[field] === 'string' ? JSON.parse(row[field]) : null;
            } catch (e) {
              return row[field];
            }
          },
        ]);
      } else {
        mapOrder.unshift([
          key,
          (row) => {
            return row[stripUniversalPrefix(field)];
          },
        ]);
      }
    }

    const foundMetricDef = resourceMetric.find(
      (m) =>
        valueGetter.value &&
        !isFunction(valueGetter.value) &&
        [valueGetter.value, valueGetter.value.replace(/\.(m|a)_/, '.')].indexOf(m.value) > -1,
    );

    if (foundMetricDef && !valueGetter.currency) {
      mapOrder.unshift([
        'currency',
        (row) => {
          return foundMetricDef.prefix_value === '$' ? row['currency'] : foundMetricDef.prefix_value;
        },
      ]);
    }
    mapOrder.unshift(['aggregate.function', () => col.aggFunc]);

    return {
      ...carry,
      [col.field]: mapOrder,
    };
  }, {});

  return rows.map((row) => {
    const data = {};
    for (const [key, mapOrder] of Object.entries(fieldColCalculate)) {
      const cData = (mapOrder as any[]).reduce((carry, [vKey, mapFun]) => {
        return {
          ...carry,
          [vKey]: mapFun({ ...row, ...carry }),
        };
      }, {});
      data[key] = cData;
    }

    return { ...row, eData: data };
  });
}
