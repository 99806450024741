import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Remove = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 12 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    {ff.invite_user_workspace ? (
      <rect width="7" height="1" rx="1" fill={props.color} />
    ) : (
      <rect width="12" height="2" rx="1" fill={props.color} />
    )}
  </SvgIcon>
);

export default Remove;
