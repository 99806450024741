import * as React from 'react';
import { get } from 'lodash';
import { IDateValue, IRowEDashboard, IRowMetric } from '../mocks/intraday';
import { IStatusChartBB } from '../dashboard-container';
import { Grid, makeStyles } from '@material-ui/core';
import LineChartIntraday from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize/line-chart-intraday';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/**
 * Line Chart
 */
type ILineChartProps = {
  data: IRowEDashboard;
  statusChart: IStatusChartBB;
};
const useStyle = makeStyles({
  containerChart: {
    '&.eip1-MuiGrid-item': {
      padding: '0',
    },
    '& .eip1-MuiTypography-h2': {
      fontSize: '18px',
    },
    '& .chartState': {
      paddingTop: '10px',
    },
  },
  chartlibMobile: {
    gap: '10px',
    margin: '0',
  },
});
const LineChartEDashboard = ({ data, statusChart }: ILineChartProps) => {
  const classes = useStyle();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';
  const divRef = React.useRef<HTMLDivElement>(null);
  const [itemPerRow, setItemPerRow] = React.useState(4);

  const backboneContext = React.useContext(TableBackboneContext);
  const filterData = backboneContext.getConfig('filter');
  const dateRangeData = backboneContext.getConfig('dateRange');

  // hide chart when filter has marketplace condition which has LAZADA or TOKOPEDIA value and dateRange is selected single day
  const isChartHide = React.useMemo(() => {
    return (
      filterData?.some(
        (filter) => filter.field === 'marketplace' && ['LAZADA', 'TOKOPEDIA'].includes(filter.queryValue),
      ) && dateRangeData?.dateFrom === dateRangeData?.dateTo
    );
  }, [filterData, dateRangeData]);

  // if (_.isEmpty(data)) return null;

  const onUpdateSize = () => {
    if (!divRef || !divRef.current) return;
    if (!divRef.current.offsetWidth) return;
    if (divRef.current.offsetWidth < 620) {
      setItemPerRow(1);
      return;
    }
    if (divRef.current.offsetWidth < 768) {
      setItemPerRow(2);
      return;
    }
    if (divRef.current.offsetWidth < 1100) {
      setItemPerRow(3);
      return;
    }
    setItemPerRow(4);
  };
  React.useLayoutEffect(() => {
    const ro = new ResizeObserver(onUpdateSize);
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [divRef.current]);
  const [selected, setSelected] = React.useState(null);
  const handleClick = (idx) => {
    isMobile ? setSelected(idx) : setSelected(null);
  };
  const handleClose = () => {
    setSelected(null);
  };

  if (get(data, 'rows', []).length === 0) return null;

  return (
    <div ref={divRef} style={{ width: '100%', height: '100%' }}>
      <Grid container spacing={3} className={isMobile ? classes.chartlibMobile : null}>
        {data.rows.length !== 0
          ? data.rows.map((lineChart, index) => {
              return (
                <Grid
                  key={lineChart?.metric || lineChart?.title || index.toString()}
                  item
                  xs={12 / itemPerRow}
                  style={{ height: isMobile ? 'auto' : isChartHide ? '100%' : '200px' }}
                  onClick={() => handleClick(index)}
                  className={isMobile ? classes.containerChart : null}
                >
                  <LineChartIntraday
                    index={index}
                    metricData={lineChart}
                    selected={selected}
                    onClose={handleClose}
                    isChartHide={isChartHide}
                  />
                </Grid>
              );
            })
          : null}
      </Grid>
    </div>
  );
};
export default LineChartEDashboard;
