import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import ExpansionTable from '../../table/expansion-table';
import TableAction from './table-action';
import TopAdvertiserItem from './advertiser-items/top-advertiser-item';
import { IRowData } from '../configs';
import MaketerItem from './advertiser-items/maketer-item';
export enum ITypeTable {
  TOP_ADVERTISERS = 'TOP_ADVERTISERS',
  ALL_ADVERTISER = 'ALL_ADVERTISER',
}
export interface IConfigTable {
  apiRequest: any;
  configuration: any;
  callback?: any;
}
interface AdvertiserContainerProps {
  config: IConfigTable;
  changeConfiguration: (value: any) => void;
  tableBackboneHook: any;
  tableContext: any;
  selectedRows?: any[];
}
const useStyles = makeStyles(() => ({
  toggle: {},
  containerHorizontal: {
    margin: '0 -16px',
    display: 'flex',
    flexWrap: 'nowrap',
    overflowY: 'auto',
    flexDirection: 'row',
    paddingBottom: '10px',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      height: '8px',
    },
    '&:hover::-webkit-scrollbar': {
      height: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      margin: '0 15px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '1px solid #f8fafd',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
  },
  horizontalItem: { minWidth: '25%', display: 'inline-flex' },
}));
const AdvertiserContainer: React.FunctionComponent<AdvertiserContainerProps> = ({
  config,
  changeConfiguration,
  tableBackboneHook,
  tableContext,
  selectedRows,
}: AdvertiserContainerProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const backbone = tableBackboneHook(config, changeConfiguration);

  const [rows, setRows] = React.useState<IRowData[]>([]);
  React.useEffect(() => {
    backbone.registerNotification({
      success: (msg: string) => enqueueSnackbar(msg, { variant: 'success' }),
      error: (msg: string) => enqueueSnackbar(msg, { variant: 'error' }),
    });
    backbone.setGridApi({
      grid: {
        paginationSetPageSize: (limit) => undefined,
        refreshServerSideStore: refreshData,
      },
      column: {},
    });
    backbone.init();
    refreshData();
  }, []);

  const refreshData = () => {
    backbone
      .getRows()
      .then((value) => {
        console.log('value', value);

        setRows(value);
      })
      .catch((err) => {
        console.log('Get row fail', err);
      });
  };
  const titleTable = backbone.getConfig('title');
  const tableType = backbone.getConfig('tableType');

  const generateTable = (typeTable: ITypeTable | undefined) => {
    switch (typeTable) {
      case ITypeTable.TOP_ADVERTISERS:
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<TableAction />}
          >
            <div className={classes.containerHorizontal}>
              {rows.map((item, index) => {
                return (
                  <div key={index} className={classes.horizontalItem}>
                    <TopAdvertiserItem item={item} />
                  </div>
                );
              })}
            </div>
          </ExpansionTable>
        );
      default: {
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<TableAction />}
          >
            <React.Fragment>
              {rows.map((item, index) => {
                return <MaketerItem key={index} item={item} />;
              })}
            </React.Fragment>
          </ExpansionTable>
        );
      }
    }
  };
  return <tableContext.Provider value={backbone}>{generateTable(tableType)}</tableContext.Provider>;
};

export const makeAdvertise = ({
  config = {
    apiRequest: {},
    configuration: {},
    callback: {},
  },
  changeConfiguration = (config: any) => undefined,
  tableBackboneHook = useTableBackbone,
  tableContext = TableBackboneContext,
  selectedRows = [],
}: {
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
}) => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <AdvertiserContainer
        config={config}
        changeConfiguration={changeConfiguration}
        tableBackboneHook={tableBackboneHook}
        tableContext={tableContext}
        selectedRows={selectedRows}
      />
    </SnackbarProvider>
  );
};
