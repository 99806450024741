import { SvgIconProps } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Add from './svg/Add';
import AddDuplicate from './svg/AddDuplicate';
import AddEdit from './svg/AddEdit';
import AddExtension from './svg/AddExtension';
import AddFavorite from './svg/AddFavorite';
import Addfilter from './svg/Addfilter';
import AddGift from './svg/AddGift';
import AddGroup from './svg/AddGroup';
import AddLink from './svg/AddLink';
import AddOS from './svg/AddOS';
import AddProperties from './svg/AddProperties';
import AddRequest from './svg/AddRequest';
import AlignCenter from './svg/AlignCenter';
import AlignLeft from './svg/AlignLeft';
import AlignRight from './svg/AlignRight';
import Arrowdown from './svg/Arrowdown';
import Arrowdowncustom from './svg/Arrowdowncustom';
import Arrowleft from './svg/Arrowleft';
import Arrowleftcustom from './svg/Arrowleftcustom';
import Arrowright from './svg/Arrowright';
import Arrowup from './svg/Arrowup';
import Ascending from './svg/Ascending';
import Avatar from './svg/Avatar';
import Avatar1 from './svg/Avatar1';
import Avatar2 from './svg/Avatar2';
import Avatar3 from './svg/Avatar3';
import Avatar4 from './svg/Avatar4';
import Avatar5 from './svg/Avatar5';
import AvatarProfile from './svg/AvatarProfile';
import BasicArrowRight from './svg/BasicArrowRight';
import Bill from './svg/Bill';
import Bold from './svg/Bold';
import Calculate from './svg/Calculate';
import Calendar from './svg/Calendar';
import Calendar2 from './svg/Calendar2';
import ChangeCurrency from './svg/ChangeCurrency';
import Checked from './svg/Checked';
import Chevron from './svg/Chevron';
import ChevronMedium from './svg/ChevronMedium';
import Chevronmediumright from './svg/Chevronmediumright';
import Chevronmediumrightcustom from './svg/Chevronmediumrightcustom';
import ChevronMediumUp from './svg/ChevronMediumUp';
import Clock from './svg/Clock';
import Close from './svg/Close';
import CloseCircle from './svg/CloseCircle';
import CloseRounded from './svg/CloseRounded';
import Code from './svg/Code';
import ColorText from './svg/ColorText';
import Column from './svg/Column';
import ColumnChart from './svg/ColumnChart';
import Comment from './svg/Comment';
import CommentBlank from './svg/CommentBlank';
import Copy from './svg/Copy';
import CopyLink from './svg/CopyLink';
import Dashboard from './svg/Dashboard';
import Delete from './svg/Delete';
import DeleteChip from './svg/DeleteChip';
import Descensing from './svg/Descensing';
import BigScreen from './svg/Device/BigScreen';
import Desktop from './svg/Device/Desktop';
import MediumScreen from './svg/Device/MediumScreen';
import Mobile from './svg/Device/Mobile';
import Tablet from './svg/Device/Tablet';
import Done from './svg/Done';
import Downarrow from './svg/Downarrow';
import DraftStatus from './svg/DraftStatus';
import Draggable from './svg/Draggable';
import Duplicate from './svg/Duplicate';
import DuplicatePlus from './svg/DuplicatePlus';
import Edit from './svg/Edit';
import EditStatus from './svg/EditStatus';
import Error from './svg/Error';
import ErrorStatus from './svg/ErrorStatus';
import Export from './svg/Export';
import Eyes from './svg/Eyes';
import Filter from './svg/Filter';
import Format from './svg/Format';
import Gartner from './svg/Gartner';
import GoingStatus from './svg/GoingStatus';
import Group from './svg/Group';
import GroupBy from './svg/GroupBy';
import Hashtag from './svg/Hashtag';
import Heading1 from './svg/Heading1';
import Heading2 from './svg/Heading2';
import Heading3 from './svg/Heading3';
import HideDetail from './svg/HideDetail';
import History from './svg/History';
import Import from './svg/Import';
import Indeterminate from './svg/Indeterminate';
import Italic from './svg/Italic';
import LightBulbStar from './svg/LightBulbStar';
import LinesChart from './svg/LinesChart';
import Lock from './svg/Lock';
import Logo from './svg/Logo';
import Logout from './svg/Logout';
import LorealLogo from './svg/LorealLogo';
import Medal_1st from './svg/Medal_1st';
import Medal_2nd from './svg/Medal_2nd';
import Move from './svg/Move';
import MoveTo from './svg/MoveTo';
import NoResult from './svg/NoResult';
import Not from './svg/Not';
import Note from './svg/Note';
import Notification from './svg/Notification';
import OpenPdp from './svg/Openpdp';
import PausedCircle from './svg/PausedCircle';
import PausedRounded from './svg/PauseRounded';
import PauseStatus from './svg/PauseStatus';
import Pencil from './svg/Pencil';
import PieChart from './svg/PieChart';
import PinColumn from './svg/Pincolumn';
import PinUntilColumn from './svg/PinUntilColumn';
import PlainText from './svg/PlainText';
import Plus from './svg/Plus';
import Preset from './svg/Preset';
import ProcessStatus from './svg/ProcessStatus';
import Properties from './svg/Properties';
import RadioChecked from './svg/RadioChecked';
import Refresh from './svg/Refresh';
import RefreshRounded from './svg/RefreshRounded';
import ReLoad from './svg/Reload';
import Remove from './svg/Remove';
import Resize from './svg/Resize';
import Screen from './svg/Screen';
import Search from './svg/Search';
import SearchIcon from './svg/SearchIcon';
import Setscript from './svg/Setscript';
import Setting from './svg/Setting';
import Share from './svg/Share';
import ShowDetail from './svg/ShowDetail';
import Sort from './svg/Sort';
import Star from './svg/Star';
import StarGrey from './svg/StarGrey';
import StarRounded from './svg/StarRounded';
import StarWhite from './svg/StarWhite';
import StarYellow from './svg/StarYellow';
import Active from './svg/StatusActive';
import Draft from './svg/StatusDraft';
import Paused from './svg/StatusPaused';
import Scheduled from './svg/StatusScheduled';
import Store from './svg/Store';
import Strikethrough from './svg/Strikethrough';
import Success from './svg/Success';
import Tag from './svg/Tag';
import Task from './svg/Task';
import Icondot from './svg/ThreeDots';
import ThreeDotsVertical from './svg/ThreeDotsVertical';
import Tool from './svg/Tool';
import TriangularArrowDown from './svg/TriangularArrowDown';
import TriangularArrowRight from './svg/TriangularArrowRight';
import TurnIntoGroup from './svg/TurnIntoGroup';
import Underline from './svg/Underline';
import UnGroup from './svg/UnGroup';
import Upload from './svg/Upload';
import User from './svg/User';
import WorkSpace from './svg/WorkSpace';
import HidePassword from './svg/HidePassword';
import EpsiloDisable from './svg/EpsiloDisable';
import SuccessConnectStore from './svg/SuccessConnectStore';
import AddInformation from './svg/AddInformation';
import PlayButton from './svg/PlayButton';
import ProgressIndicatorDot from './svg/ProgressIndicatorDot';
import Radio from './svg/Radio';
import RadioBlank from './svg/RadioBlank';
import CustomError from './svg/CustomError';
import CustomSuccess from './svg/CustomSuccess';
import BugReport from './svg/BugReport';
import ImportContacts from './svg/ImportContacts';
import ColorPicker from './svg/ColorPicker';
/**
 * ff.mass_operation_management:start
 */
import CircleStatus from './svg/CircleStatus';
import Excel from './svg/Excel';
import Separate from './svg/Separate';
/**
 * ff.mass_operation_management:end
 * ff.maintenance_page:start
 */
import Maintenance from './svg/Maintenance';
/**
 * ff.maintenance_page:end
 */

/**
 * ff.function_crud_etable_view:start
 */
import Rename from './svg/Rename';
import CopyLinkView from './svg/CopyLinkView';
import DeleteView from './svg/DeleteView';
import TableView from './svg/TableView';
/**
 * ff.function_crud_etable_view:end
 */

/**
 * ff.add_text_block:start
 */
import RemixIcons from './svg/RemixIcon';
/**
 * ff.add_text_block:end
 */
import WatchMe from './svg/WatchMe';
import Sandbox from './svg/SandBox';
import Eye from './svg/Eye';
import EyeSlash from './svg/EyeSlash';
import { optionsColumnColor } from '../elements/chart-type/data-demo';
import { DeferReactIcon, RemixLegacy } from './defer-react-icons';

const remixIcons = {};
// const reactIcons = {};
// export const listReactIcons = () => {
//   const contextReactIcons = require.context('react-icons/', true, /index.js$/);
//   contextReactIcons.keys().forEach((key: any) => {
//     Object.keys(contextReactIcons(key)).forEach((icon) => {
//       reactIcons[icon] = contextReactIcons(key)[icon];
//     });
//   });
//   return { reactIcons };
// };

// listReactIcons();

export const colorName = {
  '#204d77': 'blue5',
  '#f17d40': 'orange',
  '#338ed8': 'blue2',
  '#0BA373': 'mint',
  '#e4553e': 'carmine',
  '#f0ad4e': 'yellow',
  '#5bc0de': 'seaSerpent',
  '#d4dded': 'azureishWhite',
  '#FB2300': 'red',
  '#303F52': 'charcoal',
  '#344E73': 'blue6',
  '#2C6BC4': 'blue3',
  '#77AEFB': 'blue1',
  '#7E81C6': 'purple',
  '#103E86': 'darkCerulean',
  '#A91708': 'rufous',
  '#E17C00': 'fulvous',
  '#7A8577': 'xanadu',
  '#0074AF': 'blue4',
};

export const colorCodes = {
  blue5: '#204d77',
  orange: '#f17d40',
  blue2: '#338ed8',
  mint: '#0BA373',
  green: '#0BA373',
  carmine: '#e4553e',
  yellow: '#f0ad4e',
  seaSerpent: '#5bc0de',
  azureishWhite: '#d4dded',
  red: '#FB2300',
  charcoal: '#303F52',
  blue6: '#344E73',
  blue3: '#2C6BC4',
  blue39: '#2C6BC4',
  blue1: '#77AEFB',
  purple: '#7E81C6',
  darkCerulean: '#103E86',
  rufous: '#A91708',
  fulvous: '#E17C00',
  xanadu: '#7A8577',
  blue4: '#0074AF',
};

const sortedOptionsColumnColor = [...optionsColumnColor].sort((a, b) => {
  return colorName[b] < colorName[a] ? 1 : -1;
});

const icons = {
  comment: Comment,
  tag: Tag,
  setscript: Setscript,
  copy: Copy,
  edit: Edit,
  addfilter: Addfilter,
  descensing: Descensing,
  ascending: Ascending,
  icondot: Icondot,
  draft: Draft,
  scheduled: Scheduled,
  paused: Paused,
  error: Error,
  downarrow: Downarrow,
  active: Active,
  chevronmediumrightcustom: Chevronmediumrightcustom,
  arrowleftcustom: Arrowleftcustom,
  chevronmediumright: Chevronmediumright,
  arrowleft: Arrowleft,
  done: Done,
  not: Not,
  clock: Clock,
  add: Add,
  arrowdowncustom: Arrowdowncustom,
  arrowup: Arrowup,
  calendar: Calendar,
  arrowdown: Arrowdown,
  arrowright: Arrowright,
  bold: Bold,
  chevron: Chevron,
  code: Code,
  delete: Delete,
  deleteChip: DeleteChip,
  export: Export,
  history: History,
  import: Import,
  italic: Italic,
  pencil: Pencil,
  plus: Plus,
  threeDotsVertical: ThreeDotsVertical,
  duplicate: Duplicate,
  duplicatePlus: DuplicatePlus,
  format: Format,
  filter: Filter,
  properties: Properties,
  setting: Setting,
  sort: Sort,
  search: Search,
  reload: ReLoad,
  screen: Screen,
  resize: Resize,
  preset: Preset,
  share: Share,
  move: Move,
  dashboard: Dashboard,
  note: Note,
  closeCircle: CloseCircle,
  bigScreen: BigScreen,
  mediumScreen: MediumScreen,
  desktop: Desktop,
  mobile: Mobile,
  tablet: Tablet,
  radioChecked: RadioChecked,
  close: Close,
  draggable: Draggable,
  chevronMedium: ChevronMedium,
  chevronMediumUp: ChevronMediumUp,
  logo: Logo,
  noResult: NoResult,
  underline: Underline,
  alignLeft: AlignLeft,
  alignCenter: AlignCenter,
  alignRight: AlignRight,
  columnChart: ColumnChart,
  pieChart: PieChart,
  linesChart: LinesChart,
  h1: Heading1,
  h2: Heading2,
  h3: Heading3,
  colorText: ColorText,
  avatar: Avatar,
  plainText: PlainText,
  triangularArrowDown: TriangularArrowDown,
  triangularArrowRight: TriangularArrowRight,
  lorealLogo: LorealLogo,
  notification: Notification,
  task: Task,
  lock: Lock,
  avatar1: Avatar1,
  avatar2: Avatar2,
  avatar3: Avatar3,
  avatar4: Avatar4,
  avatar5: Avatar5,
  addGroup: AddGroup,
  column: Column,
  starRounded: StarRounded,
  hashtag: Hashtag,
  group: Group,
  pausedCircle: PausedCircle,
  editStatus: EditStatus,
  basicArrowRight: BasicArrowRight,
  avatarProfile: AvatarProfile,
  star: Star,
  bill: Bill,
  openPdp: OpenPdp,
  pinUntilColumn: PinUntilColumn,
  pinColumn: PinColumn,
  copyLink: CopyLink,
  refresh: Refresh,
  lightBulbStar: LightBulbStar,
  user: User,
  workSpace: WorkSpace,
  groupBy: GroupBy,
  showDetail: ShowDetail,
  hideDetail: HideDetail,
  unGroup: UnGroup,
  goingStatus: GoingStatus,
  gartner: Gartner,
  pauseStatus: PauseStatus,
  errorStatus: ErrorStatus,
  draftStatus: DraftStatus,
  processStatus: ProcessStatus,
  success: Success,
  remove: Remove,
  turnIntoGroup: TurnIntoGroup,
  tool: Tool,
  eyes: Eyes,
  checked: Checked,
  indeterminate: Indeterminate,
  calendar2: Calendar2,
  store: Store,
  pausedRounded: PausedRounded,
  refreshRounded: RefreshRounded,
  closeRounded: CloseRounded,
  upload: Upload,
  searchIcon: SearchIcon,
  logout: Logout,
  addduplicate: AddDuplicate,
  addedit: AddEdit,
  moveto: MoveTo,
  addlink: AddLink,
  addfavorite: AddFavorite,
  addproperties: AddProperties,
  strikethrough: Strikethrough,
  changeCurrency: ChangeCurrency,
  addgift: AddGift,
  addrequest: AddRequest,
  addextension: AddExtension,
  addos: AddOS,
  calculate: Calculate,
  commentBlank: CommentBlank,
  starWhite: StarWhite,
  medal_1st: Medal_1st,
  medal_2nd: Medal_2nd,
  starYellow: StarYellow,
  starGrey: StarGrey,
  hidePassword: HidePassword,
  epsiloDisable: EpsiloDisable,
  successConnectStore: SuccessConnectStore,
  addinformation: AddInformation,
  playbutton: PlayButton,
  progressIndicatorDot: ProgressIndicatorDot,
  radio: Radio,
  radioBlank: RadioBlank,
  customError: CustomError,
  customSuccess: CustomSuccess,
  bugreport: BugReport,
  importcontacts: ImportContacts,
  circleStatus: CircleStatus,
  excel: Excel,
  separate: Separate,
  rename: Rename,
  copyLinkView: CopyLinkView,
  deleteView: DeleteView,
  tableView: TableView,
  maintenance: Maintenance,
  ...(ff.add_text_block ? RemixIcons : {}),
  watchme: WatchMe,
  sandbox: Sandbox,
  eye: Eye,
  eyeSlash: EyeSlash,
  colorPicker: ColorPicker,
  ...sortedOptionsColumnColor.reduce((a, b) => {
    return {
      ...a,
      [`circleFill_${colorName[b]}`]: (props) => <CircleStatus {...props} color={b} />,
    };
  }, {}),
};
export { icons as allIcons };

export type IconType = keyof typeof icons;

type PropsStyle = {
  size: string;
  colorIcon: string;
};

/** Style */
const useStyles = makeStyles(() => ({
  icon: {
    fontSize: (props: PropsStyle) => `${props.size} !important`,
    color: (props: PropsStyle) => props.colorIcon,
    width: (props: PropsStyle) => (props.size ? props.size : 'auto'),
    height: (props: PropsStyle) => (props.size ? props.size : 'auto'),
  },
  riIcon: {
    '& path': {
      stroke: 'currentColor',
    },
  },
}));
interface IconProps extends SvgIconProps {
  type: IconType;
  size?: string;
  colorIcon?: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ type, size, colorIcon, className, ...rest }: IconProps) => {
  const [typeIcon, icon, color, customSize] = type.split('/');

  let hexColor = colorIcon;
  const classes = useStyles({ size: customSize || size, colorIcon: hexColor ? hexColor : colorIcon });

  if (color) {
    const customColor = Object.keys(colorName).find((el) => colorName[el] === color);
    hexColor = customColor ? customColor : color;
  }

  if (icon && (typeIcon == 'ri' || typeIcon == 'ic')) {
    return (
      <DeferReactIcon
        isIconify={typeIcon === 'ic'}
        nameIcon={icon}
        className={className}
        size={customSize || size}
        {...rest}
        color={hexColor || rest?.color || '#000'}
      />
    );
    // return <ReactIconComponent color={hexColor || '#000'} className={className} size={size} />;
  }

  if (icon && typeIcon == 'rmx') {
    return (
      <RemixLegacy
        name={icon.replace(/-icon$/, '')}
        className={className}
        size={customSize || size}
        {...rest}
        color={hexColor || rest?.color || '#000'}
      />
    );
  }

  const RenderIcon = icons[icon ? icon : type];
  if (RenderIcon) {
    return <RenderIcon data-mid={type} data-mlabel={type} {...rest} className={`${classes.icon} ${className}`} />;
  }
  return null;
};

export default Icon;
