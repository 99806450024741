import * as React from 'react';
import clsx from 'clsx';
import { useHistory, useParams } from 'react-router';

import { Button, createGenerateClassName, Drawer, makeStyles, StylesProvider, ThemeProvider } from '@material-ui/core';

import { aim } from '@eip/next/lib/main';
import epsiloTheme, { seedClassName } from '@ep/insight-ui/lib/epsilo-theme';

import RayHeader from './header';
import RayList from './list';
import Icon from '@ep/insight-ui/icons/Icon';
import RayFooter from './footer';
import RayMain from './main';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

type PropsStyle = {
  drawerWidth: number;
};

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100vh',
  },
  sidebarContainer: {
    position: 'fixed',
    display: 'inline-block',
    minHeight: '67px',
    // top: '40px',
    // bottom: 0,
    zIndex: theme.zIndex.drawer,
    '&.isOpen': {
      '& $abButton': {
        display: 'none',
      },
      '&:hover': {
        '& $abButton': {
          display: 'flex',
        },
      },
    },
  },
  drawer: ({ drawerWidth }: PropsStyle) => ({
    width: '13px',
    flexShrink: 0,
    whiteSpace: 'nowrap',
  }),
  drawerOpen: ({ drawerWidth }: PropsStyle) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100%',
    '.eres--small &': {
      width: '80vw',
      height: '100vh',
    },
    background: '#F8FAFD',
  }),
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '100%',
    overflowX: 'hidden',
    width: '13px',
    [theme.breakpoints.up('sm')]: {
      width: '13px',
    },
  },
  borderIconButton: {
    padding: '5px',
    display: 'flex',
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  abButton: {
    right: 0,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonExpand: {
    borderRadius: '50%',
    border: 'none',
    height: 24,
    width: 24,
    backgroundColor: '#fff !important',
  },
  iconExp: {
    width: '6px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  drawerContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

const drawerWidth_const = 250;
const OPEN_SIDEBAR = 'opensidebar';
const RAY_FAVORITES = 'ray_favorites';

const generateClassName = createGenerateClassName({
  seed: seedClassName,
});

const Ray = ({}) => {
  const classes = useStyles({ drawerWidth: drawerWidth_const });

  const history = useHistory();
  const { serviceId, id } = useParams();

  const openSideBarLocal = localStorage.getItem(OPEN_SIDEBAR);
  const [openSideBar, setOpenSideBar] = React.useState(openSideBarLocal ? JSON.parse(openSideBarLocal) : true);
  const [favorites, setFavorites] = React.useState(safeJsonParse(localStorage.getItem(RAY_FAVORITES) || '[]'));

  const handleToggleFavorite = (id) => {
    setFavorites((prevValue) => {
      return prevValue.includes(id) ? prevValue.filter((fv) => fv != id) : prevValue.concat(id);
    });
  };

  const checkIsFavorite = (id) => {
    return favorites.includes(id);
  };

  const favoriteFunctions = {
    handleToggleFavorite,
    checkIsFavorite,
  };

  React.useEffect(() => {
    if (!aim.canAccess('ray')) return history.push('/');
  }, []);

  React.useEffect(() => {
    localStorage.setItem(RAY_FAVORITES, JSON.stringify(favorites));
  }, [favorites]);

  React.useEffect(() => {
    let title = 'Epsilo Ray';
    if (id && id != 0) {
      title += ` | ${id}`;
    }
    document.title = title;
  }, [id]);

  return (
    <ThemeProvider theme={epsiloTheme}>
      <StylesProvider generateClassName={generateClassName}>
        <div className={classes.container}>
          <div className={`${classes.sidebarContainer} ${openSideBar ? ' isOpen' : ''}`}>
            <Drawer
              anchor="left"
              variant="permanent"
              className={clsx(classes.drawer, {
                [classes.drawerOpen]: openSideBar,
                [classes.drawerClose]: !openSideBar,
              })}
              classes={{
                paper: clsx({
                  [classes.drawerOpen]: openSideBar,
                  [classes.drawerClose]: !openSideBar,
                }),
              }}
            >
              <div className={classes.drawerContainer} style={{ display: !openSideBar && 'none' }}>
                <RayHeader />
                <RayList favoriteFunctions={favoriteFunctions} favorites={favorites} />
                <RayFooter />
              </div>
            </Drawer>
            <div className={`${classes.borderIconButton} ${classes.abButton}`}>
              <Button
                onClick={() => {
                  setOpenSideBar(!openSideBar);
                  localStorage.setItem(OPEN_SIDEBAR, JSON.stringify(!openSideBar));
                }}
                variant="outlined"
                className={classes.buttonExpand}
              >
                <div className={classes.iconExp}>
                  <Icon
                    type={openSideBar ? 'arrowleftcustom' : 'chevronmediumright'}
                    style={{ height: '100%', width: '100%', marginLeft: openSideBar ? 0 : 1 }}
                  />
                </div>
              </Button>
            </div>
          </div>
          <main>
            <RayMain openSideBar={openSideBar} favoriteFunctions={favoriteFunctions} />
          </main>
        </div>
      </StylesProvider>
    </ThemeProvider>
  );
};

export default Ray;
