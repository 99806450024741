import { mopQueryParamsToFilter, useLog, NodeEditContext, EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';
import { createDataQueryFromConfig, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';

// import { actions } from '@eip/next/src/components/dashboard/dashboard-redux';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';
import { makeTable } from '@ep/insight-ui/elements/table/table-container';
import { Button, Typography, makeStyles } from '@material-ui/core';
import { EventEmitter } from 'events';
import { cloneDeep, debounce, get, isEmpty, merge, set } from 'lodash';
import qs from 'qs';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { UaMonitProvider, UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { popuplateConfigWithCurrentView, produceValidConfig } from './migration';
import { DEFAULT_CONFIG } from './table-config';

import { columnEnhance } from './addons/column-enhance';
import { customExportTable, getETableList, getMetricDefinition, getNamespaceList } from './addons/enhance-data-request';

/**
 * ff.adoption_rate_table:start
 */
import {
  ADOPTION_RATE_ACTIONS_RES,
  ADOPTION_RATE_USERS_RES,
  USER_COHORT_RES,
} from '@ep/insight-ui/elements/table/mocks/getAdoptionRateData';
import moment from 'moment';
import AdoptionRate from '@ep/insight-ui/elements/table/format/adoption-rate-format';
import { CELL_FORMAT_STATIC_KEYS } from '@ep/insight-ui/system/block/etable/cell-format-object-keys';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from './cell-format-options';
/**
 * ff.adoption_rate_table:end
 */

/**
 * ff.ui_page_keyword_analysis:start
 */
import { TOP_KEYWORD_ANALYSIS_CONFIG_RES } from '@ep/insight-ui/elements/keyword-analysis/mocks';
/**
 * ff.ui_page_keyword_analysis:end
 */

/**
 * ff.generate_etable_config:start
 */
import ETableConfig from './etable-config';
/**
 * ff.generate_etable_config:end
 */

const log = useLog('block:etable');

/**
 * ff.mass_operation_management:start
 */
// Will remove when API is available
import { MASS_OPERATION_TABLE_DATA } from './demo-data/mass-operation-table-data';
import { nanoid } from 'nanoid';
import {
  downloadMassUploadTemplate,
  EpsiloTableObject,
  checkEpsiloTableEndpoint,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';
/**
 * ff.mass_operation_management:end
 */

/**
 * ff.storefront_sos_option_list:start
 */
import { default as dataRequest } from '@ep/insight-ui/system/backbone/data-source/common';
/**
 * ff.storefront_sos_option_list:end
 */
/**
 * ff.chart_line_traction:start
 */
import { getSoSTractionData } from '@ep/insight-ui/system/backbone/data-source/common';
/**
 * ff.chart_line_traction:end
 */

import {
  enhanceDataRequest2,
  getInsightStorefrontList,
  getQualityStorefrontList,
  getOperationStorefrontList,
  getOperationCountryList,
  getOperationMarketplaceList,
  getNamespaceCountryList,
  getNamespaceMarketplaceList,
  getNamespaceStorefrontList,
} from './addons/enhance-data-request';

import { hasCohortProperties } from './addons/column-enhance';

import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import produce from 'immer';

import EpsiloTemplateConfig from '../epsilo-template-config';
import { resetColumns } from './addons/table-addon';

const useStyles = makeStyles({
  configurationForm: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
});

export class ETable implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    const chartNodeData = Object.assign({ customAttributes: {} }, data);
    return (
      <UaMonitProvider>
        <ChartShape nodeData={chartNodeData}></ChartShape>
      </UaMonitProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <RichConfigForm data={data} onSubmit={handleSubmit} key={nodeId} nodeId={nodeId}></RichConfigForm>;
  }
}

const defaultConfiguration = DEFAULT_CONFIG;

export const RichConfigForm = ({
  data,
  onSubmit,
  nodeId,
}: {
  data: NodeData;
  onSubmit: (...args: any[]) => void;
  nodeId: string;
}) => {
  const classes = useStyles();
  const [openConfig, setOpenConfig] = React.useState(false);
  const [sConfig, setStateConfig] = React.useState(data);

  const onSubmit1 = React.useCallback((config) => {
    console.info('onsubmit1', config);
    const fConfig = { ...config, _editorId: 'table_' + nanoid() };
    onSubmit(fConfig);
    setStateConfig(fConfig);
    setOpenConfig(false);
  }, []);

  const dispatch = useDispatch();
  const handleClickConfiguration = () => {
    // dispatch(actions.setEditorMode({ mode: 'advance_json_edit' }));
    setOpenConfig(true);
  };

  const handleClose = () => {
    // dispatch(actions.setEditorMode({ mode: 'select' }));
    setOpenConfig(false);
  };

  const config = merge({}, defaultConfiguration, data);

  const externalFiltersOptions = React.useMemo(() => {
    return Object.keys(sConfig.mapping)
      .filter((key) => {
        return sConfig.mapping[key].propertyType === 'dimension';
      })
      .map((key) => ({
        value: key,
        label: sConfig.mapping[key].title,
      }));
  }, [sConfig]);

  const [valueExternalFilters, setValueExternalFilters] = React.useState(
    get(config, ['system', 'externalFilters'], []),
  );

  const externalPropertiesOptions = [
    {
      label: 'Metrics',
      value: 'metric',
    },
  ];

  const [valueExternalProperties, setValueExternalProperties] = React.useState(
    get(config, ['system', 'externalProperties'], []),
  );

  const hiddenComponentsOptions = [
    {
      label: 'Calendar',
      value: 'timeline',
    },
    {
      label: 'Major Button',
      value: 'majorButton',
    },
  ];

  const [valueHiddenComponents, setValueHiddenComponents] = React.useState(
    get(config, ['system', 'hiddenComponents'], []),
  );

  const handleSubmit = () => {
    const specialFilters = { ...sConfig.specialFilters };
    Object.keys(specialFilters).forEach((key) => {
      if (!valueExternalFilters.includes(key)) delete specialFilters[key];
    });
    onSubmit1(
      cloneDeep(
        produce(sConfig, (draft) => {
          set(draft, ['system', 'externalFilters'], valueExternalFilters);
          set(draft, ['system', 'externalProperties'], valueExternalProperties);
          set(draft, ['system', 'hiddenComponents'], ['search', ...valueHiddenComponents]);
          set(draft, 'specialFilters', specialFilters);
        }),
      ),
    );
  };

  return (
    <React.Fragment>
      <div className={classes.configurationForm}>
        <div>
          <EpsiloTemplateConfig nodeId={nodeId} config={sConfig} chartId={'richTable'} />
          <Button variant="contained" color="secondary" onClick={handleClickConfiguration} id="edit-config-btn">
            Advanced configuration
          </Button>
          {ff.generate_etable_config ? (
            <ETableConfig open={openConfig} onClose={handleClose} config={sConfig} onSubmit={onSubmit1} />
          ) : (
            <PopupJsonEditor
              onValueChange={onValueChange}
              open={openConfig}
              title={'Edit etable setting'}
              value={config}
              onClose={handleClose}
            />
          )}
        </div>
        <div>
          <Typography variant="h6">External Filters</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={externalFiltersOptions}
              value={valueExternalFilters}
              onChange={setValueExternalFilters}
              multipleSelect={true}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">External Metric</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={externalPropertiesOptions}
              value={valueExternalProperties}
              onChange={setValueExternalProperties}
              multipleSelect={true}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Typography variant="h6">Hidden Components</Typography>
          <div style={{ width: '100%' }}>
            <SelectMultipleForm
              options={hiddenComponentsOptions}
              value={valueHiddenComponents}
              onChange={setValueHiddenComponents}
              multipleSelect={true}
              searchAble={false}
              isConfigChart={true}
            />
          </div>
        </div>
        <div>
          <Button variant="contained" color="primary" style={{ marginTop: '15px' }} onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </React.Fragment>
  );
};

const isValidIndexData = (config) => {
  return checkEpsiloTableEndpoint(get(config, 'endpoint.GET_TABLE_DATA', ''), [
    EpsiloTableObject.PERFORMANCE,
    EpsiloTableObject.QUALITY,
    EpsiloTableObject.OPERATION,
  ]);
};

export const ChartShape = ({ nodeData }: { nodeData: NodeData }) => {
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const uawl = React.useContext(UaWatchlist);
  const location = useLocation();
  const [mopFilter] = React.useState(() => {
    const searchParams = qs.parse(location.search.replace('?', ''));
    const mopQuery = searchParams.mopq;
    if (mopQuery) {
      return { combinator: 'AND', filters: mopQueryParamsToFilter(mopQuery as Record<string, any>) };
    }
    return {};
  });
  let lastUpdated;
  if (ff.keep_block_refreshing) {
    lastUpdated = React.useRef(moment().valueOf());
  }

  const handleChangeConfig = React.useCallback(
    (config: Record<string, any>) => {
      log('handlechangeconfig', config);
      window.requestAnimationFrame(() => {
        nodeEditContext.onUpdateCustomAttributes(nodeData, config);
      });
    },
    [nodeData],
  );

  const customConfig = get(nodeData, 'customAttributes', {});
  let validConfig = produceValidConfig(customConfig);
  validConfig = popuplateConfigWithCurrentView(validConfig);

  const config: {
    apiRequest: Record<string, any>;
    configuration: Record<string, any>;
    addons?: Record<string, (...args: any[]) => any>;
    callback?: Record<string, any>;
  } = {
    apiRequest: {},
    configuration: {
      ...defaultConfiguration,
      ...validConfig,
      hiddenFilter: mopFilter,
      defaultPagination: {
        limit: isValidIndexData(validConfig) ? 1000 : 100,
        page: 1,
        total: 0,
        ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
      },
    },
    callback: {
      onBackboneReady: (backbone, config) => {
        const addons = get(config, 'addons', {});

        Object.keys(addons).forEach((key) => {
          if (key.startsWith('initial.request.')) {
            backbone
              .addon(key, () => Promise.resolve({ data: null }))()
              .then((res) => {
                backbone.addInitialDataRequest(key.replace('initial.request.', ''), res.data);
              });
          }
        });

        const enpointTable = get(backbone, 'config.endpoint.GET_TABLE_DATA');
        // Add Metric Options for button Properties
        const mappingConfig = get(backbone, 'config.mapping', {});
        const isStorefrontMetricTraction = Object.values(mappingConfig).find(
          (ele) => ele?.cellFormat === 'storefrontMetricTraction',
        );
        const metric = get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []);
        const metricTractionProperties = backbone.getConfig('metricTractionProperties', []);

        const isDiff =
          metric.length !== metricTractionProperties.length ||
          (metric.length === metricTractionProperties.length &&
            metricTractionProperties.some(({ id }) => !metric.includes(id)));

        if (isStorefrontMetricTraction && isDiff) {
          getMetricDefinition(enpointTable).then((res) => {
            if (res) {
              const metricProperties = metric.map((el) => {
                const metricDef = res.filter((mt) => mt.value === el)[0];
                return {
                  id: el,
                  name: get(metricDef, 'label_raw', el),
                  disable: false,
                };
              });
              backbone.changeConfig('metricTractionProperties', metricProperties);
            }
          });
        }
      },
    },
    // Will remove when API is available
    ...(ff.mass_operation_management && validConfig.tableType === 'massOperationTable'
      ? {
          addons: {
            ...(ff.make_mocking_api_etable
              ? {}
              : {
                  'datasource.apiRequest.getTableData': async (params, originRequest) => {
                    const response = {
                      data: {
                        headers: Object.keys(MASS_OPERATION_TABLE_DATA[0]),
                        pagination: { limit: 100, page: 1, total: MASS_OPERATION_TABLE_DATA.length },
                        primaryKeys: ['product'],
                        rows: MASS_OPERATION_TABLE_DATA,
                      },
                      message: 'OK',
                      code: 200,
                    };
                    return Promise.resolve(response);
                  },
                }),
            'dataMenu.properties': (backbone) => {
              return [
                [
                  {
                    title: 'Properties',
                    name: 'properties',
                    icon: 'properties',
                    colorStartIcon: '#253746',
                    onClick: () => backbone.changeVisibility('property', true),
                  },
                  {
                    title: 'Download template',
                    name: 'download_template',
                    icon: 'export',
                    iconSize: '14px',
                    colorStartIcon: '#253746',
                    onClick: () => {
                      downloadMassUploadTemplate().then((res) => {
                        if (res.success) {
                          window.location.href = res.data.url;
                        }
                      });
                    },
                  },
                ],
              ];
            },
          },
        }
      : {}),
    ...(ff.generate_etable_config ? { cellFormat: CELL_FORMAT_OPTIONS_WITH_COMPONENT } : {}),
  };
  const endpoint = get(validConfig, 'endpoint.GET_TABLE_DATA', '');
  const queryParams = getQueryParams(endpoint);

  // Will remove when API is available
  let adoptionMockResponse;
  let showTooltip = false;
  switch (validConfig.tableType) {
    case 'adoptionRateByActions':
      adoptionMockResponse = ADOPTION_RATE_ACTIONS_RES;
      break;
    case 'adoptionRateByUsers':
      adoptionMockResponse = ADOPTION_RATE_USERS_RES;
      showTooltip = true;
      break;
    case 'userCohort':
      adoptionMockResponse = USER_COHORT_RES;
      break;
    case 'massOperationTable':
      ff.make_mocking_api_etable
        ? (adoptionMockResponse = {
            data: {
              headers: Object.keys(MASS_OPERATION_TABLE_DATA[0]),
              pagination: { limit: 100, page: 1, total: MASS_OPERATION_TABLE_DATA.length },
              primaryKeys: ['product'],
              rows: MASS_OPERATION_TABLE_DATA,
            },
            message: 'OK',
            code: 200,
          })
        : (adoptionMockResponse = undefined);
      break;
    default:
      break;
  }

  // Add additionalColumns for table
  config.addons = {
    ...config.addons,
    'table.columns.enhance': (columnDefs, columns, backbone) => {
      if (ff.metric_traction) {
        return columnEnhance(columnDefs, columns, backbone);
      } else {
        const additionalColumns = [];

        const mappings = backbone.getConfig('mapping');
        const tractionFormat = Object.values(mappings).find((mapping) => mapping.cellFormat === 'traction');

        if (tractionFormat) {
          const {
            period = 'week',
            timeRange = 8,
            formatTime = '[CW]W - YYYY',
          } = tractionFormat.staticValue || CELL_FORMAT_STATIC_KEYS.traction;

          additionalColumns.push(
            ...Array.from({ length: timeRange }, (_, index) => {
              return {
                headerName: moment().subtract(index, period).format(formatTime),
                field: `traction_${period}_${index + 1}`,
                colId: `traction_${period}_${index + 1}`,
                resizable: true,
                suppressMovable: true,
                cellStyle: {
                  borderRightColor: 'whitesmoke',
                },
                headerComponentParams: {
                  type: 'number',
                },
                cellRendererParams: {
                  showTooltip: showTooltip,
                },
                cellRendererFramework: AdoptionRate,
              };
            }),
          );
        }
        return [...columnDefs, ...additionalColumns];
      }
    },
    ...(ff.metric_cohort_column
      ? {
          'dataMenu.properties': (arr, handleUpdateVisibleWithId, backbone) => {
            const newProperties = [...arr];
            if (hasCohortProperties(backbone)) {
              newProperties.unshift({
                title: 'Cohort',
                name: 'cohort',
                icon: 'calendar',
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('calendar', true),
              });
            }
            if (checkEpsiloTableEndpoint(endpoint)) {
              newProperties.unshift({
                title: 'View by',
                name: 'periodically',
                icon: 'calendar',
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('periodically', true),
              });
            }
            return [newProperties];
          },
        }
      : {}),
    ...(!checkEpsiloTableEndpoint(endpoint)
      ? {
          'initial.request.transformStatus': async () => {
            const result = await eipRequest.get(
              EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/filter-dropdownlabel?group=Transform Status',
            );
            return result;
          },
          'filterValue.ads_campaign_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
          'filterValue.ads_object_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
          'filterValue.ads_placement_transform_status': (
            payload?: any,
            forceReload?: boolean,
            getInitialDataRequest?: any,
          ) => {
            return new Promise((resolve) => {
              if (getInitialDataRequest) {
                resolve({
                  data: getInitialDataRequest('transformStatus').map((el) => ({
                    value: el.id,
                    label: el.label,
                  })),
                  success: true,
                  message: 'OK',
                });
              }
              return resolve({
                data: [],
                success: false,
                message: '',
              });
            });
          },
        }
      : {}),
  };

  if (ff.integrate_api_sos) {
    if (validConfig.tableType === 'treemapTable') {
      config.addons = {
        ...(config.addons || {}),
        'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
          const { dateFrom, dateTo } = backbone.getConfig('dateRange');
          params.hiddenFilter = {
            combinator: 'AND',
            filters: [
              {
                field: 'PRODUCT.keyword',
                operator: '=',
                dataType: 'string',
                value: backbone.getConfig('keyword'),
              },
            ],
          };
          params.from = dateFrom;
          params.to = dateTo;
          return Promise.resolve(params);
        },
        ...(ff.storefront_sos_option_list
          ? {
              'filter.optionSOS.storefront': async (keyword) => {
                const res = await dataRequest.getStorefrontSOS(keyword);
                return res;
              },
            }
          : {}),
        ...(ff.chart_line_traction
          ? {
              'get.traction.data': (keyword, productSIds, timing = 'daily') => {
                return getSoSTractionData(keyword, productSIds, timing);
              },
            }
          : {}),
      };
    }
  }

  if (ff.keep_block_refreshing) {
    const { timeToReloadData } = config.configuration;
    if (timeToReloadData) {
      config.addons = {
        ...config.addons,
        'calendar.last_updated': (rows, currentConfig, backbone) => {
          setTimeout(() => {
            const currentTime = moment().valueOf();
            if (currentTime - lastUpdated.current > timeToReloadData) {
              backbone.changeConfig('lastUpdatedAt', moment(new Date()).format('MMM/YY hh:mm'));
              backbone.reloadData('table');
              lastUpdated.current = currentTime;
            }
          }, timeToReloadData);
          return rows.map((row) => ({
            ...row,
            lastUpdatedAt: moment(new Date()).format('MMM/YY hh:mm'),
          }));
        },
      };
    }
  }

  if (ff.metric_traction) {
    const prevAddon = get(config, ['addons', 'datasource.apiRequest.getTableData'], null);
    config.addons = {
      ...config.addons,
      'externalFilter.getFields': (backbone) => {
        const specialFilterFields = ['country', 'marketplace', 'storefront', 'brand', 'category_1'];

        const externalFilters = backbone.getConfig('system.externalFilters', []);
        return backbone
          .getAvailableColumns()
          .reduce((a, b) => {
            if (externalFilters.includes(b.id)) {
              return [
                ...a,
                {
                  name: b.name,
                  id: b.id,
                  field: b.filterField,
                  dataType: b.dataType || 'string',
                  propertyType: b.propertyType,
                },
              ];
            }
            return a;
          }, [])
          .sort((a, b) => {
            if (specialFilterFields.findIndex((el) => el === a.id) === -1) return 1;
            if (specialFilterFields.findIndex((el) => el === b.id) === -1) return -1;
            return (
              specialFilterFields.findIndex((el) => el === a.id) - specialFilterFields.findIndex((el) => el === b.id)
            );
          });
      },
      'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
        return enhanceDataRequest2(params, originRequest, backbone);
      },
    };
  }
  const mappings = get(validConfig, 'mapping', {});

  if (ff.expose_filter_etable) {
    const prevAddon = get(config, ['addons', 'datasource.getRowsParams'], null);
    config.addons = {
      ...config.addons,
      'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
        const prevParams = prevAddon ? prevAddon({ params }, currentConfig) : params;
        const specialFilters = backbone.getConfig('specialFilters') || {};

        if (checkEpsiloTableEndpoint(endpoint)) {
          prevParams.hiddenFilter = {
            ...prevParams.hiddenFilter,
            currency: 'USD',
          };
          prevParams.currency = get(prevParams, 'hiddenFilter.currency', 'USD');
        }

        const formattedSpecialFilters = Object.values(specialFilters).map((filter) => ({
          dataType: filter.dataType,
          field: filter.queryField,
          operator: filter.queryType,
          value: filter.queryValue,
        }));

        if (formattedSpecialFilters.length > 0) {
          return {
            ...prevParams,
            filter: {
              ...prevParams.filter,
              combinator: prevParams.filter?.combinator || 'and',
              filters: [...(prevParams.filter?.filters || []), ...formattedSpecialFilters],
            },
          };
        }

        return prevParams;
      },
      'selection.filter': (field, originSelectionFilter) => {
        const additionalFilterField = get(validConfig, ['system', 'additionalFilters'], []).find(
          ({ id }) => id === field,
        );

        const additionalOperators = get(additionalFilterField, ['operators'], []).map((el) => el.value);

        return additionalOperators.length ? additionalOperators : originSelectionFilter;
      },
      ...get(validConfig, ['system', 'additionalFilters'], []).reduce((a, b) => {
        return {
          ...a,
          [`filterValue.${b.id}`]: () => {
            return {
              data: b.options,
              success: true,
              message: 'OK',
            };
          },
        };
      }, {}),
    };
    if (checkEpsiloTableEndpoint(endpoint)) {
      config.addons = {
        ...config.addons,
        'system.export': (params, backbone) => {
          return customExportTable(params, backbone);
        },
        'system.groupby.aggFunc': (key) => {
          const aggFuncList = [
            'NONE',
            'UNIQUE',
            'SUM',
            'AVG',
            'MIN',
            'MAX',
            'COUNT_ALL',
            'COUNT_VALUES',
            'COUNT_UNIQUE',
            'COUNT_EMPTY',
            'COUNT_NOT_EMPTY',
            'PERCENT_EMPTY',
            'PERCENT_NOT_EMPTY',
            'RANGE',
          ].map((el) => {
            let fieldId = el;
            if (el === 'NONE') fieldId = 'NULL';
            if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
            return {
              id: el.toLowerCase(),
              requestQuery: fieldId,
            };
          });

          const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce(
            (carry, i) => ({ ...carry, [i.id]: i }),
            {},
          );

          return aggFuncByKey[key]
            ? aggFuncByKey[key]
            : {
                id: key,
                requestQuery: key.toUpperCase(),
              };
        },
      };
    }
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
      const mappings = get(validConfig, 'mapping', {});
      const cachedFilterOption = {};
      Object.keys(mappings)
        .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
        .forEach((colKey) => {
          let apiFilterValue;
          if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
          else if (get(mappings, [colKey, 'valueGetter', 'id'], '')) {
            apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
          } else {
            apiFilterValue = get(
              mappings, //
              [colKey, 'valueGetter', 'value'],
              colKey,
            );
          }
          const addonKey = `filterValue.${colKey}`;
          config.addons[addonKey] = async () => {
            if (cachedFilterOption[addonKey]) return cachedFilterOption[addonKey];
            try {
              const result = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_listing.jsp', {
                attributes: [apiFilterValue],
              });

              const options = get(result, 'data.rows', []);

              cachedFilterOption[addonKey] = {
                data: options
                  .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                  .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
                success: true,
                message: 'OK',
              };
              return cachedFilterOption[addonKey];
            } catch (e) {
              cachedFilterOption[addonKey] = null;
              return Promise.resolve({
                data: [],
                success: false,
                message: '',
              });
            }
          };
        });
      config.addons = {
        ...config.addons,
        'filterValue.country': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

            const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

            return Promise.resolve({
              data: availableCountryList.map((c) => ({
                label: c,
                value: c,
              })),
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
        'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

            const availableMarketplaceList = [
              ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
            ];

            return Promise.resolve({
              data: availableMarketplaceList.map((m) => ({
                label: m,
                value: m,
              })),
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
        'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

            const data = Object.values(storefrontList).map((el: any) => {
              return {
                label: [el.marketplace_code, el.country_code, el.storefront_name].join(' / '),
                value: el.id,
                payload: {
                  sid: el.storefront_sid,
                  country: {
                    code: el.country_code,
                  },
                  channel: {
                    id: el.marketplace_code,
                  },
                },
              };
            });

            return Promise.resolve({
              data,
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
      };
    }
    if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
      config.addons = {
        ...config.addons,
        'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
          const result = await getOperationStorefrontList();
          return result;
        },
        'filterValue.country': async (payload?: any, forceReload?: boolean) => {
          const result = await getOperationCountryList();
          return result;
        },
        'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
          const result = await getOperationMarketplaceList();
          return result;
        },
      };
    } else if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
      Object.keys(mappings)
        .filter(
          (colKey) =>
            get(mappings, [colKey, 'propertyType'], '') === 'dimension' ||
            get(mappings, [colKey, 'selectionFilter'], false),
        )
        .forEach((colKey) => {
          const addonKey = `filterValue.${colKey}`;

          config.addons[addonKey] = async () => {
            const result = await getETableList({
              type: 'operational',
              field: colKey,
              config,
            });
            return result;
          };
        });
      config.addons = {
        ...config.addons,
        'selection.filter': (field, originSelectionFilter) => {
          if (originSelectionFilter.length === 0 && get(mappings, [field, 'selectionFilter'], false)) {
            return ['is', 'is_not', 'contains', 'does_not_contain'];
          }
          return originSelectionFilter;
        },
        'filterValue.country': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

            const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

            return Promise.resolve({
              data: availableCountryList.map((c) => ({
                label: c,
                value: c,
              })),
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
        'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

            const availableMarketplaceList = [
              ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
            ];

            return Promise.resolve({
              data: availableMarketplaceList.map((m) => ({
                label: m,
                value: m,
              })),
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
        'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
          try {
            const [storefrontList] = await Promise.all([getQualityStorefrontList()]);

            const data = Object.values(storefrontList).map((el: any) => {
              return {
                label: [el.marketplace_code, el.country_code, el.name].join(' / '),
                value: el.storefront_id,
                payload: {
                  sid: el.storefront_sid,
                  country: {
                    code: el.country_code,
                  },
                  channel: {
                    id: el.marketplace_code,
                  },
                },
              };
            });

            return Promise.resolve({
              data,
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        },
      };
    }
    if (queryParams.namespace) {
      Object.keys(mappings)
        .filter(
          (colKey) =>
            get(mappings, [colKey, 'propertyType'], '') === 'dimension' ||
            get(mappings, [colKey, 'selectionFilter'], false),
        )
        .forEach((colKey) => {
          const addonKey = `filterValue.${colKey}`;

          config.addons[addonKey] = async (_, __, ___, getColumnFields) => {
            const result = await getNamespaceList({
              namespace: queryParams.namespace,
              field: colKey,
              config,
              getColumnFields,
            });
            return result;
          };
        });

      config.addons = {
        ...config.addons,
        'selection.filter': (field, originSelectionFilter) => {
          if (originSelectionFilter.length === 0 && get(mappings, [field, 'selectionFilter'], false)) {
            return ['is', 'is_not', 'contains', 'does_not_contain'];
          }
          return originSelectionFilter;
        },
        'filterValue.storefront': async () => {
          const mapping = get(config, ['configuration', 'mapping'], {});
          const result = await getNamespaceStorefrontList(queryParams.namespace, mapping);
          return result;
        },
      };
    }

    let pivot = { values: [], queryField: null };
    config.addons = {
      ...config.addons,
      'system.reset.columns': resetColumns,
      'pivot.get': async function () {
        return pivot;
      },
      'pivot.set': async function (pivotData) {
        pivot = pivotData;
      },
    };
  }

  React.useEffect(() => {
    uawl.start('canSeeDataEtable', { title: config.configuration.title, timerMs: 10000 });
  }, []);

  log('final config', config);

  return makeTable({
    config,
    changeConfiguration: handleChangeConfig,
    tableBackboneHook: useTableBackbone,
  });
};
