import * as React from 'react';
import { useParams } from 'react-router';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core';

import RayMainCode from './code';
import RayMainLog from './log';

const useStyles = makeStyles(() => ({
  container: {
    marginLeft: ({ openSideBar }) => (openSideBar ? '250px' : '13px'),
    display: 'flex',
    '& .eip1-MuiFormHelperText-root': {
      display: 'none',
    },
    '&.vs-dark': {
      color: '#fff',
      background: '#1e1e1e',
      '& input, & textarea': {
        color: '#fff',
        background: '#1e1e1e',
      },
      '& .eip1-MuiCheckbox-root:not(.Mui-checked)': {
        color: '#fff',
      },
    },
  },
}));

type RayMainProps = {
  openSideBar: boolean;
  favoriteFunctions: any;
};

export const defaultAlerts = {
  emailMessages: [],
  slackMessages: [],
};

const RAY_THEME = 'ray_theme';

const RayMain = ({ openSideBar, favoriteFunctions }: RayMainProps) => {
  const classes = useStyles({ openSideBar });
  const { serviceId, id } = useParams();
  const [alerts, setAlerts] = React.useState<{ emailMessages: string[]; slackMessages: string[] }>(defaultAlerts);
  const [log, setLog] = React.useState<string>('');
  const [toggleLog, setToggleLog] = React.useState<boolean>(true);
  const [theme, setTheme] = React.useState(localStorage.getItem(RAY_THEME) || 'vs');
  const [detail, setDetail] = React.useState(null);

  React.useEffect(() => {
    setAlerts(defaultAlerts);
    setLog('');
  }, [id]);

  React.useEffect(() => {
    localStorage.setItem(RAY_THEME, theme);
  }, [theme]);

  if (!serviceId || !id) return null;
  return (
    <div className={clsx(classes.container, theme)}>
      <RayMainCode
        setAlerts={setAlerts}
        setLog={setLog}
        setToggleLog={setToggleLog}
        toggleLog={toggleLog}
        defaultAlerts={defaultAlerts}
        favoriteFunctions={favoriteFunctions}
        theme={theme}
        setTheme={setTheme}
        detail={detail}
        setDetail={setDetail}
      />
      {toggleLog && detail ? <RayMainLog alerts={alerts} log={log} theme={theme} /> : null}
    </div>
  );
};

export default RayMain;
