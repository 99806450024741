import * as React from 'react';
import ButtonOption from './button-option-2';
import ButtonSelectDate from './button-select-date';
import ButtonSearchArea from './button-search-area';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import ButtonAction from './button-action';

const HeaderActions = () => {
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const hidden = backboneContext.getConfig('system.hiddenComponents', []);
  // timeline

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between' }}>
      <div style={{ flex: 1, flexShrink: 0 }}>{!hidden.includes('search') && <ButtonSearchArea />}</div>
      <div style={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
        {!hidden.includes('timeline') ? (
          <span style={{ margin: '0 4px' }}>
            <ButtonSelectDate />
          </span>
        ) : null}
        {!hidden.includes('actionButton') && (
          <MonitorContainer component="div" mId="main-button-legacy" mLabel="main-button-legacy">
            <ButtonAction backbone={backboneContext} />
          </MonitorContainer>
        )}
        {!hidden.includes('settingMenu') && <ButtonOption isTableCompact />}
      </div>
    </div>
  );
};
export default HeaderActions;
