import * as React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import { useDragAndDrop } from './hooks/use-drag-and-drop';

const useStyles = makeStyles((theme) => ({
  dragContainer: {
    position: 'relative',
  },
  lineDrop: {
    position: 'absolute',
    left: '0',
    bottom: '0',
    height: '4px',
    width: '100%',
    opacity: '0',
    background: '#BFE2FF',
    zIndex: 10,
  },
  lineDropTop: {
    top: '0',
  },
}));

interface IPropsDraggableMenu {
  nodeId: string;
  parentNodeIds: string[];
  showTopLine: boolean;
  listExpanded: string[];
  setAppMenuList?: any;
  updateSidebar?: any;
  appMenuListRef?: any;
  children: any;
}

const DraggableMenu = ({
  nodeId,
  parentNodeIds = [],
  showTopLine,
  listExpanded = [],
  updateSidebar,
  appMenuListRef,
  children,
}: IPropsDraggableMenu) => {
  const classes = useStyles();

  const { ref, lineRef, topLineRef } = useDragAndDrop(nodeId, parentNodeIds, updateSidebar, appMenuListRef);

  return (
    <div ref={ref} className={classes.dragContainer}>
      {children}
      {showTopLine && <div className={clsx(classes.lineDrop, classes.lineDropTop)} ref={topLineRef} />}
      {!listExpanded.includes(nodeId) && <div className={classes.lineDrop} ref={lineRef} />}
    </div>
  );
};

export default DraggableMenu;
