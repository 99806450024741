export const PERSONALIZATION = 'personalization';
export const SHORTCUT_ACTIONS = 'shortcutActions';
export const CONDITION_FORMAT = 'conditionFormat';
export const ACTION_INTERACTION = 'action_interaction';
export const CONDITION_FORMAT_PATH = [PERSONALIZATION, CONDITION_FORMAT];
const LOCKED_VIEW_CONFIG = 'lockedConfig';
export const LOCKED_VIEW_CONFIG_PROPERTY = [LOCKED_VIEW_CONFIG, 'property'];
export const LOCKED_VIEW_CONFIG_SORT = [LOCKED_VIEW_CONFIG, 'sort'];
export const LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE = [LOCKED_VIEW_CONFIG, 'lastSystemUpdate'];

export const POST_RELOAD_TABLE_EVENT = 'post_reload_table';

export const ACCUMULATIVE_CONFIG = 'accumulativeConfig';

export const CALENDAR_WEEKDAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];
export const EPSILO_BLUE_COLOR = '#204d77';

export const DEFAULT_GRADIENT_MID_POINT = 10;
export const X_AXIS = 'xAxis';
export const Y_AXIS = 'yAxis';
export const TIME_ORDER = ['regularly', 'hourly', 'daily', 'weekly', 'monthly', 'quarterly', 'yearly', 'all'];
export const DEFAULT_DATETIME_FORMAT_FORMULA = `=IF(p('period') == 'hourly', 'HH:mm', IF(p('period') == 'weekly', '[CW]W', 'ddd'))`;
export const CHART_CONFIG = 'chartConfig';

export const WIDTH_TO_SHOW_SHORTCUT = 550;
export const WIDTH_TO_SHOW_EXTERNAL = 650;
export const USE_FORMULA = 'USE_FORMULA';
export const DELAY_CLOSE_POPUP = 100;

export const DELAY_REQUEST_ADDITIONAL_DATA = 5000;
export const CALCULATE_TRACTION_WIDTH = 'CALCULATE_TRACTION_WIDTH';
