import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

export const HelmetMetaData = ({ title }) => {
  const titleTag = title !== undefined ? title : 'Epsilo | Execution hub for e-commerce teams';
  return (
    <Helmet>
      <title>{titleTag}</title>
    </Helmet>
  );
};

HelmetMetaData.propTypes = {
  description: PropTypes.string,
  quote: PropTypes.string,
  hashtag: PropTypes.string,
  title: PropTypes.string.isRequired,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
};
