import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddRequest = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 3C2 2.44771 2.44772 2 3 2H13C13.5523 2 14 2.44772 14 3V12C14 12.5523 13.5523 13 13 13H10.2361C10.0808 13 9.92771 13.0361 9.78885 13.1056L8.1118 13.9441C8.04142 13.9793 7.95858 13.9793 7.8882 13.9441L6.21115 13.1056C6.07229 13.0361 5.91918 13 5.76393 13H3C2.44772 13 2 12.5523 2 12V3Z"
      fill="#253746"
    />
    <mask id="path-2-inside-1_791_39232" fill="white">
      <ellipse cx="7.92383" cy="11.0119" rx="1" ry="0.987919" />
    </mask>
    <ellipse cx="7.92383" cy="11.0119" rx="1" ry="0.987919" fill="#C4C4C4" />
    <path
      d="M7.92383 11.0119C7.92383 11.0115 7.92388 11.0102 7.9243 11.0082C7.9247 11.0062 7.92529 11.0044 7.92595 11.0029C7.92724 10.9999 7.92833 10.9989 7.92814 10.9991C7.92794 10.9993 7.92764 10.9995 7.92752 10.9995C7.92751 10.9995 7.9275 10.9995 7.92748 10.9995C7.92745 10.9995 7.92737 10.9996 7.92721 10.9996C7.927 10.9996 7.92597 10.9998 7.92383 10.9998V12.9998C9.0169 12.9998 9.92383 12.1212 9.92383 11.0119H7.92383ZM7.92383 10.9998C7.92168 10.9998 7.92066 10.9996 7.92044 10.9996C7.92028 10.9996 7.9202 10.9995 7.92018 10.9995C7.92015 10.9995 7.92014 10.9995 7.92013 10.9995C7.92002 10.9995 7.91971 10.9993 7.91952 10.9991C7.91933 10.9989 7.92042 10.9999 7.9217 11.0029C7.92237 11.0044 7.92296 11.0062 7.92336 11.0082C7.92377 11.0102 7.92383 11.0115 7.92383 11.0119H5.92383C5.92383 12.1212 6.83075 12.9998 7.92383 12.9998V10.9998ZM7.92383 11.0119C7.92383 11.0123 7.92377 11.0136 7.92336 11.0156C7.92296 11.0176 7.92237 11.0194 7.9217 11.0209C7.92042 11.0239 7.91933 11.0249 7.91952 11.0247C7.91971 11.0245 7.92002 11.0244 7.92013 11.0243C7.92014 11.0243 7.92015 11.0243 7.92018 11.0243C7.9202 11.0243 7.92028 11.0243 7.92044 11.0242C7.92066 11.0242 7.92168 11.024 7.92383 11.024V9.02399C6.83075 9.02399 5.92383 9.90258 5.92383 11.0119H7.92383ZM7.92383 11.024C7.92597 11.024 7.927 11.0242 7.92721 11.0242C7.92737 11.0243 7.92745 11.0243 7.92748 11.0243C7.9275 11.0243 7.92751 11.0243 7.92752 11.0243C7.92764 11.0244 7.92794 11.0245 7.92814 11.0247C7.92833 11.0249 7.92724 11.0239 7.92595 11.0209C7.92529 11.0194 7.9247 11.0176 7.9243 11.0156C7.92388 11.0136 7.92383 11.0123 7.92383 11.0119H9.92383C9.92383 9.90258 9.0169 9.02399 7.92383 9.02399V11.024Z"
      fill="white"
      mask="url(#path-2-inside-1_791_39232)"
    />
    <path
      d="M9.43499 5.41745C9.43499 5.23131 9.39788 5.04699 9.32578 4.87502C9.25367 4.70304 9.14799 4.54678 9.01476 4.41516C8.88152 4.28354 8.72336 4.17913 8.54928 4.1079C8.3752 4.03666 8.18863 4 8.00021 4C7.81179 4 7.62522 4.03666 7.45114 4.1079C7.27707 4.17913 7.1189 4.28354 6.98567 4.41516C6.85244 4.54678 6.74675 4.70304 6.67465 4.87502C6.60254 5.04699 6.56543 5.23131 6.56543 5.41745"
      stroke="white"
      strokeWidth="1.505"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.43478 5.41724C9.43478 7.18905 8 6.83469 8 8.6065"
      stroke="white"
      strokeWidth="1.505"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);

export default AddRequest;
