import { isArray } from 'lodash';

export class PLACE_HOLDER {}

export function addonMiddleware(...originHandlers: any[]) {
  const handlers = [].concat(originHandlers).reverse();

  return (...initArgs) => {
    const argLength = initArgs.length;

    function recur(...handlers: any[]) {
      const [first, ...rest] = handlers;
      return (...args) => {
        let correctArgs = args;
        if (args.length < argLength) {
          correctArgs = correctArgs.concat(initArgs.slice(args.length, initArgs.length));
        }
        if (rest.length > 0) {
          if (isArray(first)) {
            return first[0](...[].concat(first[1]).concat(recur(...rest)));
          }
          return first(...correctArgs.concat(recur(...rest)));
        } else {
          if (isArray(first)) {
            return first[0](...[].concat(first[1]));
          }
          return first(...correctArgs);
        }
      };
    }

    return recur(...handlers)(...initArgs);
  };
}
