import { EIP_CONSTANT } from '@eip/next/lib/main';
import { epsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';

export const ETABLE_QUALITY_INDEX_TEMPLATE = {
  title: 'Quality Index',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.QUALITY.queryEpMatching,
  },
  primaryKeys: ['storefront.id'],
  system: {
    hiddenComponents: ff.reload_btn_quality ? ['search'] : ['search', 'majorButton'],
  },
  hiddenColumns: [],
  requestHiddenField: {},
  requestIgnoreField: {
    metric: [],
  },
  hiddenFilter: {
    currency: 'USD',
  },
  ...(ff.reload_btn_quality
    ? {
        mainButton: {
          text: 'Reload',
          type: 'component',
          componentName: 'ReloadTableBtn',
        },
      }
    : {}),
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'qualityDimension',
      dataType: 'string',
      filterField: 'storefront.country_code',
      valueGetter: {
        label: 'storefront.name',
        id: 'storefront.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'qualityDimension',
      dataType: 'string',
      filterField: 'storefront.marketplace_code',
      valueGetter: {
        label: 'storefront.marketplace_name',
        id: 'storefront.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'qualityDimension',
      dataType: 'string',
      filterField: 'storefront.id',
      valueGetter: {
        label: 'storefront.name',
        complementId: 'storefront.id',
        subtext: 'storefront.storefront_sid',
        id: 'storefront.id',
      },
    },
    gmv: {
      title: 'GMV',
      propertyType: 'metric',
      cellFormat: 'qualityMetric',
      dataType: 'float',
      valueGetter: {
        value: 'storefront.gmv',
      },
    },
  },
};
