import * as React from 'react';
import LineChartEDashboard from '@ep/insight-ui/elements/eDashboard/edashboard-charts/line-chart';
import { Line } from 'react-chartjs-2';

const data = {
  labels: [
    '2021-08-25 00:00:00',
    '2021-08-25 01:00:00',
    '2021-08-25 02:00:00',
    '2021-08-25 03:00:00',
    '2021-08-25 04:00:00',
    '2021-08-25 05:00:00',
    '2021-08-25 06:00:00',
    '2021-08-25 07:00:00',
    '2021-08-25 08:00:00',
    '2021-08-25 09:00:00',
    '2021-08-25 10:00:00',
    '2021-08-25 11:00:00',
    '2021-08-25 12:00:00',
    '2021-08-25 13:00:00',
    '2021-08-25 14:00:00',
    '2021-08-25 15:00:00',
    '2021-08-25 16:00:00',
    '2021-08-25 17:00:00',
    '2021-08-25 18:00:00',
    '2021-08-25 19:00:00',
    '2021-08-25 20:00:00',
    '2021-08-25 21:00:00',
    '2021-08-25 22:00:00',
    '2021-08-25 23:00:00',
  ],
  datasets: [
    {
      label: 'Current',
      data: [
        3749, 1355, 818, 537, 552, 908, 1013, 3663, 1942, 1751, 1468, 1254, 1388, 1554, 2428, 1424, 802, 778, 752, 747,
        1053, 0, 0, 0,
      ],
      dateList: [
        '2021-08-25 00:00:00',
        '2021-08-25 01:00:00',
        '2021-08-25 02:00:00',
        '2021-08-25 03:00:00',
        '2021-08-25 04:00:00',
        '2021-08-25 05:00:00',
        '2021-08-25 06:00:00',
        '2021-08-25 07:00:00',
        '2021-08-25 08:00:00',
        '2021-08-25 09:00:00',
        '2021-08-25 10:00:00',
        '2021-08-25 11:00:00',
        '2021-08-25 12:00:00',
        '2021-08-25 13:00:00',
        '2021-08-25 14:00:00',
        '2021-08-25 15:00:00',
        '2021-08-25 16:00:00',
        '2021-08-25 17:00:00',
        '2021-08-25 18:00:00',
        '2021-08-25 19:00:00',
        '2021-08-25 20:00:00',
        '2021-08-25 21:00:00',
        '2021-08-25 22:00:00',
        '2021-08-25 23:00:00',
      ],
      backgroundColor: '#204D77',
      borderColor: '#204D77',
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 3,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#204D77',
      pointHoverBorderColor: '#204D77',
      pointStyle: 'circle',
      lineTension: 0.4,
      pointHoverBorderWidth: 3,
    },
    {
      label: '__disabled__',
      data: [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
      ],
      dateList: [
        '2021-08-25 00:00:00',
        '2021-08-25 01:00:00',
        '2021-08-25 02:00:00',
        '2021-08-25 03:00:00',
        '2021-08-25 04:00:00',
        '2021-08-25 05:00:00',
        '2021-08-25 06:00:00',
        '2021-08-25 07:00:00',
        '2021-08-25 08:00:00',
        '2021-08-25 09:00:00',
        '2021-08-25 10:00:00',
        '2021-08-25 11:00:00',
        '2021-08-25 12:00:00',
        '2021-08-25 13:00:00',
        '2021-08-25 14:00:00',
        '2021-08-25 15:00:00',
        '2021-08-25 16:00:00',
        '2021-08-25 17:00:00',
        '2021-08-25 18:00:00',
        '2021-08-25 19:00:00',
        '2021-08-25 20:00:00',
        '2021-08-25 21:00:00',
        '2021-08-25 22:00:00',
        '2021-08-25 23:00:00',
      ],
      backgroundColor: '#204D77',
      borderColor: '#204D77',
      borderDash: [5, 5],
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 3,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#204D77',
      pointHoverBorderColor: '#204D77',
      pointStyle: 'circle',
      lineTension: 0.4,
      pointHoverBorderWidth: 3,
    },
    {
      label: 'Previous',
      data: [
        317, 178, 99, 50, 84, 172, 324, 334, 400, 486, 528, 564, 551, 528, 523, 455, 426, 459, 505, 538, 0, 0, 1, 0,
      ],
      dateList: [
        '2021-08-24 00:00:00',
        '2021-08-24 01:00:00',
        '2021-08-24 02:00:00',
        '2021-08-24 03:00:00',
        '2021-08-24 04:00:00',
        '2021-08-24 05:00:00',
        '2021-08-24 06:00:00',
        '2021-08-24 07:00:00',
        '2021-08-24 08:00:00',
        '2021-08-24 09:00:00',
        '2021-08-24 10:00:00',
        '2021-08-24 11:00:00',
        '2021-08-24 12:00:00',
        '2021-08-24 13:00:00',
        '2021-08-24 14:00:00',
        '2021-08-24 15:00:00',
        '2021-08-24 16:00:00',
        '2021-08-24 17:00:00',
        '2021-08-24 18:00:00',
        '2021-08-24 19:00:00',
        '2021-08-24 20:00:00',
        '2021-08-24 21:00:00',
        '2021-08-24 22:00:00',
        '2021-08-24 23:00:00',
      ],
      backgroundColor: '#D4DDED',
      borderColor: '#D4DDED',
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 3,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#D4DDED',
      pointHoverBorderColor: '#D4DDED',
      pointStyle: 'circle',
      lineTension: 0.4,
      pointHoverBorderWidth: 3,
    },
  ],
};

const options = {
  isCrisper: false,
  responsive: true,
  maintainAspectRatio: false,
  animation: 300,
  plugins: {
    filler: { propagate: false },
    tooltip: { callbacks: {}, enabled: false, mode: 'index', position: 'nearest' },
    legend: { display: false },
  },
  fill: false,
  radius: 10,
  interaction: { intersect: false, mode: 'index' },
  scales: {
    xAxes: {
      axis: 'x',
      display: false,
      grid: {
        display: false,
        drawBorder: false,
        lineWidth: 1,
        drawOnChartArea: true,
        drawTicks: true,
        tickLength: 8,
        offset: false,
        borderDash: [],
        borderDashOffset: 0,
        borderWidth: 1,
        color: 'rgba(0,0,0,0.1)',
        borderColor: 'rgba(0,0,0,0.1)',
      },
      title: { display: false, text: '', padding: { top: 4, bottom: 4 }, color: '#666' },
      ticks: {
        fontSize: 14,
        fontWeight: 400,
        color: '#253746',
        minRotation: 0,
        maxRotation: 50,
        mirror: false,
        textStrokeWidth: 0,
        textStrokeColor: '',
        padding: 3,
        display: true,
        autoSkip: true,
        autoSkipPadding: 3,
        labelOffset: 0,
        minor: {},
        major: {},
        align: 'center',
        crossAlign: 'near',
        showLabelBackdrop: false,
        backdropColor: 'rgba(255, 255, 255, 0.75)',
        backdropPadding: 2,
      },
      type: 'category',
      offset: false,
      reverse: false,
      beginAtZero: false,
      bounds: 'ticks',
      grace: 0,
      id: 'xAxes',
      position: 'bottom',
    },
    yAxes: {
      axis: 'y',
      display: false,
      title: { display: false, text: '', padding: { top: 4, bottom: 4 }, color: '#666' },
      ticks: {
        fontSize: 14,
        fontWeight: 400,
        color: '#253746',
        minRotation: 0,
        maxRotation: 50,
        mirror: false,
        textStrokeWidth: 0,
        textStrokeColor: '',
        padding: 3,
        display: true,
        autoSkip: true,
        autoSkipPadding: 3,
        labelOffset: 0,
        minor: {},
        major: {},
        align: 'center',
        crossAlign: 'near',
        showLabelBackdrop: false,
        backdropColor: 'rgba(255, 255, 255, 0.75)',
        backdropPadding: 2,
      },
      type: 'linear',
      offset: false,
      reverse: false,
      beginAtZero: false,
      bounds: 'ticks',
      grace: 0,
      grid: {
        display: true,
        lineWidth: 1,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        tickLength: 8,
        offset: false,
        borderDash: [],
        borderDashOffset: 0,
        borderWidth: 1,
        color: 'rgba(0,0,0,0.1)',
        borderColor: 'rgba(0,0,0,0.1)',
      },
      id: 'yAxes',
      position: 'left',
    },
  },
  layout: { padding: {} },
};

const LineEDashboardChart = () => {
  return <Line data={data} options={options} plugins={[]} />;
};

export default LineEDashboardChart;
