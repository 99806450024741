import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Upload = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 11.9832H4C3.73478 11.9832 3.48043 12.0894 3.29289 12.2785C3.10536 12.4677 3 12.7242 3 12.9916C3 13.259 3.10536 13.5155 3.29289 13.7046C3.48043 13.8938 3.73478 14 4 14H12C12.2652 14 12.5196 13.8938 12.7071 13.7046C12.8946 13.5155 13 13.259 13 12.9916C13 12.7242 12.8946 12.4677 12.7071 12.2785C12.5196 12.0894 12.2652 11.9832 12 11.9832Z" />
    <path d="M8.60643 2.23984C8.53285 2.16392 8.44497 2.10359 8.34794 2.0624C8.25091 2.02122 8.1467 2 8.04143 2C7.93616 2 7.83194 2.02122 7.73492 2.0624C7.63789 2.10359 7.55001 2.16392 7.47643 2.23984L5.03643 4.64993C4.96114 4.72412 4.90131 4.81275 4.86047 4.91059C4.81962 5.00843 4.79858 5.11352 4.79858 5.21968C4.79858 5.32583 4.81962 5.43092 4.86047 5.52876C4.90131 5.6266 4.96114 5.71523 5.03643 5.78942C5.18782 5.94203 5.39239 6.02888 5.60643 6.03144L7.03643 6.03144L7.03643 9.96422C7.03643 10.2317 7.14179 10.4882 7.32932 10.6773C7.51686 10.8664 7.77121 10.9726 8.03643 10.9726C8.30165 10.9726 8.556 10.8664 8.74354 10.6773C8.93107 10.4882 9.03643 10.2317 9.03643 9.96422L9.03643 6.03144L10.4364 6.03144C10.6486 6.03144 10.8521 5.94645 11.0021 5.79516C11.1521 5.64387 11.2364 5.43867 11.2364 5.22472C11.2427 5.12031 11.2282 5.0157 11.1938 4.91701C11.1595 4.81831 11.106 4.72751 11.0364 4.64993L8.60643 2.23984Z" />
  </SvgIcon>
);

export default Upload;
