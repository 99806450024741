import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import FormControlInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-input';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as React from 'react';
import CircleLoading from '../loading/circle-loading';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textOPT': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#D4290D',
    },
    '&.no-wrap': {
      whiteSpace: 'nowrap',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.textOtp': {
      fontSize: '10px',
      lineHeight: '12px',
      color: '#253746',
    },
    '&.resendOtp': {
      color: '#006EC6',
      textDecoration: 'underline',
      fontWeight: 500,
      cursor: 'pointer',
    },
    '&.waitResendOtp': {
      color: '#8C98A4',
      textDecoration: 'underline',
      fontWeight: 500,
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  linkWord: {
    color: '#0369C7',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  credentialDescription: {
    color: '#8C98A4',
    paddingBottom: '8px',
    '& > p:first-child': {
      marginTop: '8px',
    },
  },
  section: {
    marginTop: '24px',
  },
  expanded: {
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      marginBottom: '10px',
    },
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    ...(ff.integrate_storefront_ui_v2
      ? {
          borderBottom: '1px solid #C2C7CB',
        }
      : {
          borderRadius: '8px',
        }),
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  buttonCancel: {
    color: '#8C98A4',
  },
  mobileContent: {
    padding: ff.integrate_storefront_ui_v2 ? '20px 25px 0 25px' : '15px 0 0 0',
    height: '100vh',
  },
  buttonValidate: {
    width: '100%',
    height: '40px',
    marginTop: '30px',
  },
  buttonVerify: {
    width: '95%',
    height: '40px',
    marginTop: ff.integrate_storefront_ui_v2 ? '15px' : '30px',
    marginLeft: '12px',
  },
  buttonBack: {
    width: '100%',
    height: '40px',
    marginTop: '15px',
    background: '#F6F7F8',
  },
  descriptionMobile: {
    color: '#8C98A4',
    marginTop: '32px',
    lineHeight: '22px',
  },
});

const VerifyAccountForm = ({
  loading = false,
  initialValues,
  onClose,
  onNextStep,
  onBackStep,
  onResendOTP,
  messageError,
}: any) => {
  const classes = useStyles();
  const [waitingOTPSeconds, setWaitingOTPSeconds] = React.useState(0);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;

  React.useEffect(() => {
    const interval = setInterval(() => {
      if (waitingOTPSeconds > 0) {
        setWaitingOTPSeconds(waitingOTPSeconds - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [waitingOTPSeconds]);

  const handleResendOTP = () => {
    setWaitingOTPSeconds(60);
    if (onResendOTP) {
      onResendOTP();
    }
  };

  return (
    <Grid container direction={'column'}>
      {!isMobile ? (
        <Grid item xs={12}>
          <span className={`${classes.text} title`}>Connect your store</span>
        </Grid>
      ) : (
        <div className={classes.mobileHeader}>
          <h4 className={classes.mobileTitle}>Verify your account</h4>
          <div className={classes.mobileButton}>
            <Button className={classes.buttonCancel} color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          if (!loading) {
            setWaitingOTPSeconds(0);
            onNextStep(values);
          }
        }}
      >
        {({ values, setFieldValue }) =>
          !isMobile ? (
            <Form>
              <Grid item xs={12} className={classes.section}>
                <ExpansionForm
                  label={'Verify your account'}
                  level={'2'}
                  detailContentSize={'full'}
                  defaultExpanded={true}
                  className={classes.expanded}
                >
                  <Box>
                    <Grid item xs={12}>
                      <FormControlInput
                        label={'OTP'}
                        classNameContainer={`${classes.formControl} input`}
                        placeholder={'Enter your OTP'}
                        defaultValue={values.otp}
                        helperText={messageError}
                        error={!!messageError}
                        onChangeText={(value) => setFieldValue('otp', value)}
                      />
                      <Grid container alignItems={'center'} justifyContent={'space-between'}>
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                          <div className={`${classes.text} textOtp`}>
                            Didn’t receive a code?
                            {waitingOTPSeconds === 0 && (
                              <span className={`${classes.text} resendOtp`} onClick={handleResendOTP}>
                                {' '}
                                Resend OTP
                              </span>
                            )}
                            {waitingOTPSeconds > 0 && (
                              <span className={`${classes.text} waitResendOtp`}>
                                {' '}
                                Resend OTP ({waitingOTPSeconds}s)
                              </span>
                            )}
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid>
                        <div className={classes.credentialDescription}>
                          <p>
                            Please enter the OTP that send to your mobile phone/ email address to complete verify your
                            account. The OTP code will be expired within <strong>15 minutes</strong>, so make sure to
                            enter it soon.
                          </p>
                          <p>
                            This OTP will be used to access your seller center account. No one can access this
                            information.
                          </p>
                          <p style={{ margin: 0 }}>
                            Visit{' '}
                            <a
                              target="_blank"
                              href="https://epsilo.io/privacy-policy"
                              className={classes.linkWord}
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>{' '}
                            for more information on how we protect your data.
                          </p>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </ExpansionForm>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      className={`${classes.button} back`}
                      onClick={onBackStep}
                    >
                      <span className={`${classes.text} textButton`}>Back</span>
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    {loading ? <CircleLoading /> : ''}
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      className={`${classes.button} cancel`}
                      onClick={onClose}
                    >
                      <span className={`${classes.text} textButton`}>Cancel</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      disabled={loading || !values.otp}
                      type="submit"
                    >
                      Verify
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Form>
          ) : (
            <Form>
              <Box className={classes.mobileContent}>
                <Grid item xs={12}>
                  <FormControlInput
                    label={'OTP'}
                    classNameContainer={`${classes.formControl} input`}
                    placeholder={'Enter your OTP'}
                    defaultValue={values.otp}
                    helperText={messageError}
                    error={!!messageError}
                    onChangeText={(value) => setFieldValue('otp', value)}
                  />
                  <Grid container alignItems={'center'} justifyContent={'space-between'}>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={12}>
                      <div className={`${classes.text} textOtp`}>
                        Didn’t receive a code?
                        {waitingOTPSeconds === 0 && (
                          <span className={`${classes.text} resendOtp`} onClick={handleResendOTP}>
                            {' '}
                            Resend OTP
                          </span>
                        )}
                        {waitingOTPSeconds > 0 && (
                          <span className={`${classes.text} waitResendOtp`}> Resend OTP ({waitingOTPSeconds}s)</span>
                        )}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {loading ? <CircleLoading /> : ''}
                </Grid>
                <Grid item xs={12}>
                  <Grid>
                    <div className={classes.descriptionMobile}>
                      <p>
                        Please enter the OTP that send to your mobile phone/ email address to complete verify your
                        account. The OTP code will be expired within <strong>15 minutes</strong>, so make sure to enter
                        it soon.
                      </p>
                      <p>
                        This OTP will be used to access your seller center account. No one can access this information.
                      </p>
                      <p style={{ margin: 0 }}>
                        Visit{' '}
                        <a
                          target="_blank"
                          href="https://epsilo.io/privacy-policy"
                          className={classes.linkWord}
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>{' '}
                        for more information on how we protect your data.
                      </p>
                    </div>
                  </Grid>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={3}>
                    <Button className={classes.buttonBack} onClick={onBackStep}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonVerify}
                      disabled={loading || !values.otp}
                      type="submit"
                    >
                      Verify
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )
        }
      </Formik>
    </Grid>
  );
};
export default VerifyAccountForm;
