import * as React from 'react';
import * as _ from 'lodash';
import clsx from 'clsx';
import { get } from 'lodash';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Tooltip } from '@material-ui/core';

import { IconType } from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
    padding: '0 8px',
    '&:hover .adoption-rate': {
      textDecoration: 'underline',
    },
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
}));

export type IValueAdoptionRateFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueAdoptionRateFormat>;
};

interface IPropsAdoptionRateFormat {
  data: string;
  week: string;
}

const tooltips = ["of CW's active users", "of CW's Campaign detail users", "of CW's Script detail users"];

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const AdoptionRateFormat = (props: IPropsAdoptionRateFormat) => {
  const classes = useStyles();

  const showTooltip = get(props, ['showTooltip'], true);
  const actionId = get(props, ['data', 'ACTION.id'], null);
  const isActiveRow = actionId === 1;

  const exact = Math.floor(Math.random() * 101);
  const percent = Math.floor(Math.random() * 101);
  const tooltip = tooltips[Math.floor(Math.random() * 3)];

  const type = 'action';

  return (
    <ConditionalWrap
      condition={!isActiveRow && showTooltip && tooltip}
      wrap={(children) => (
        <Tooltip
          title={tooltip}
          classes={{ tooltip: classes.tooltip }}
          placement="right"
          PopperProps={{
            popperOptions: {
              modifiers: {
                flip: { enabled: false },
                offset: {
                  enabled: true,
                  offset: '0, -100px',
                },
              },
            },
          }}
        >
          {children}
        </Tooltip>
      )}
    >
      <Grid
        container
        alignItems={'center'}
        wrap={'nowrap'}
        className={classes.container}
        style={{ overflow: 'hidden' }}
      >
        {/* text */}
        <Grid item style={{ width: '100%', height: '100%' }}>
          <Grid container alignItems="center" wrap={'nowrap'} className={classes.height100}>
            <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
              <Grid
                container
                alignItems={'center'}
                direction={'column'}
                wrap={'nowrap'}
                className={clsx(classes.height100, classes.alignItemsStart)}
              >
                {/*hashtag*/}
                <Grid item xs={12} className={classes.height100}>
                  <span className={`${classes.hashtag}`}></span>
                </Grid>
                {/* label */}
                <Grid
                  item
                  xs={12}
                  className={isActiveRow || !showTooltip ? classes.height100 : clsx(classes.height100, 'adoption-rate')}
                >
                  <span className={`${classes.label}`}>{isActiveRow ? `${exact} users` : `${percent}%`}</span>
                </Grid>
                {/* subtext */}
                <Grid item xs={12} className={classes.height100}>
                  <span className={`${classes.subtext}`}>
                    {!isActiveRow && `${exact} ${type}${exact > 1 ? 's' : ''}`}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ConditionalWrap>
  );
};
export default AdoptionRateFormat;
