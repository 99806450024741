/* eslint-disable react/prop-types */
import Icon from '@ep/insight-ui/icons/Icon';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Box, Grid, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    paddingTop: '3px',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  status: {
    display: 'flex',
    height: '10px',
    alignItems: 'flex-start',
  },
  statusCompact: {
    height: '22px',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  statusContainer: {
    width: 'unset',
  },
}));

export const getIconStatus = (status: string) => {
  let iconComponent = null;
  let iconType = null;
  if (status) {
    switch (status) {
      case 'Validation in progress': {
        iconComponent = <Icon type={'circleStatus'} color={'#2B3245'} />;
        iconType = 'circleStatus';
        break;
      }
      case 'Execution in progress': {
        iconComponent = <Icon type={'circleStatus'} color={'#F7BB05'} />;
        iconType = 'circleStatus';
        break;
      }
      case 'Success': {
        iconComponent = <Icon type={'circleStatus'} color={'#0BA373'} />;
        iconType = 'circleStatus';
        break;
      }
      case 'Failed': {
        iconComponent = <Icon type={'errorStatus'} />;
        iconType = 'errorStatus';
        break;
      }
      default: {
        iconComponent = <Icon type={'draftStatus'} />;
        iconType = 'draftStatus';
        break;
      }
    }
  }
  return { iconComponent, iconType };
};

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export interface IValueStatusRequestFormat {
  label: string;
  status: string;
  hashtag?: string;
  subtext?: string;
}
const StatusRequestFormat = (props: IPropsFormat<IValueStatusRequestFormat>) => {
  const classes = useStyles();
  // const { hashtag = '', subtext = '' } = props.value;
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const subtext = _.get(props, ['value', 'subtext'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const label = _.get(props, ['value', 'label'], '');
  const status = _.get(props, ['value', 'status'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');

  const valueTool = _.get(props, ['value', 'tooltips'], '');
  const valueTooltips = valueTool ? (ff.safe_json_parse ? safeJsonParse(valueTool) : JSON.parse(valueTool)) : null;
  const tooltipValueItems = valueTooltips && valueTooltips.status ? valueTooltips.status : [];

  const PausedByBotepTooltip = React.useMemo(() => {
    const tooltipItems = [
      {
        icon: 'goingStatus',
        label: 'Account balance',
      },
      {
        icon: 'goingStatus',
        label: 'Campaign status',
      },
      {
        icon: 'goingStatus',
        label: 'Campaign budget',
      },
      {
        icon: 'goingStatus',
        label: 'Ad object status',
      },
      {
        icon: 'errorStatus',
        label: 'Ad object status',
      },
      {
        icon: 'errorStatus',
        label: 'Product availabilty',
      },
      {
        icon: 'errorStatus',
        label: 'Product stock',
      },
    ];

    const tooltipItemsRender = tooltipValueItems.map((item, index) => {
      return (
        <ListItem key={index}>
          <Box className={'tooltip-icon'}>
            <Icon type={item.is_valid == 0 ? 'errorStatus' : 'goingStatus'} />
          </Box>
          <span>{item.message}</span>
        </ListItem>
      );
    });

    return <List>{tooltipItemsRender}</List>;
  }, []);

  // const backbone = React.useContext(TableBackboneContext);

  // const statusCellAction = React.useMemo(() => {
  //   return status && status.toUpperCase() === 'DRAFT'
  //     ? [
  //         ...cellAction,
  //         {
  //           name: 'Delete it',
  //           icon: 'delete',
  //           iconSize: '13px',
  //           onSubmit: async () => {
  //             backbone.getCallback('onRowRemove')('status', [], props.node.data, backbone);
  //           },
  //         },
  //       ]
  //     : cellAction;
  // }, [status]);

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <ConditionalWrap
        condition={status && status.toUpperCase() === 'PAUSED' && valueTool}
        wrap={(children) => (
          <Tooltip
            title={PausedByBotepTooltip}
            classes={{ tooltip: classes.tooltip }}
            placement="right-start"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                  offset: {
                    enabled: true,
                    offset: '30px',
                  },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        <Grid
          container
          alignItems="center"
          wrap={'nowrap'}
          className={`${classes.statusContainer} ${classes.height100}`}
        >
          {/* status icon */}
          {!_.isEmpty(status) ? (
            <Grid
              item
              className={clsx(classes.status, typeTable === 'compact' && subtext && classes.statusCompact)}
              style={{ marginRight: !_.isEmpty(status) ? '2px' : '' }}
            >
              <span style={{ display: 'flex' }}>{!_.isEmpty(status) && getIconStatus(status).iconComponent}</span>
            </Grid>
          ) : null}
          <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
            <Grid
              container
              alignItems={'center'}
              direction={'column'}
              wrap={'nowrap'}
              className={clsx(classes.height100, classes.alignItemsStart)}
            >
              {/*hashtag*/}
              <Grid item xs={12} className={classes.height100}>
                <span className={classes.hashtag}>{hashtag}</span>
              </Grid>
              {/* label */}
              <Grid item xs={12} className={classes.height100}>
                <span className={classes.label}>{label} </span>
              </Grid>
              {/* subtext */}
              <Grid item xs={12} className={classes.height100}>
                <span className={classes.subtext}>{subtext ? subtext : ''}</span>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ConditionalWrap>
    </WrapperFormat>
  );
};
export default StatusRequestFormat;
