import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Tool = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M23.8759 5.41321C23.8493 5.30404 23.7952 5.20346 23.7187 5.12102C23.6422 5.03859 23.5459 4.97704 23.4388 4.94224C23.3318 4.90743 23.2175 4.90053 23.1071 4.92218C22.9966 4.94382 22.8935 4.99331 22.8075 5.06592L20.1907 7.62871C19.9392 7.87113 19.6042 8.00825 19.2544 8.01193H16.6857C16.5969 8.01356 16.5088 7.99732 16.4265 7.96419C16.3442 7.93107 16.2695 7.88173 16.2067 7.81913C16.144 7.75653 16.0945 7.68195 16.0613 7.59985C16.0281 7.51775 16.0118 7.42981 16.0135 7.3413V4.83838C16.01 4.66475 16.0424 4.49226 16.1084 4.33159C16.1745 4.17092 16.2729 4.02548 16.3976 3.90428L18.9784 1.24568C19.0677 1.16343 19.1321 1.05775 19.1642 0.940735C19.1962 0.823715 19.1946 0.700064 19.1595 0.583908C19.1245 0.467752 19.0574 0.363774 18.9659 0.283853C18.8745 0.203931 18.7623 0.151288 18.6423 0.131937C17.4687 -0.105115 16.2527 -0.0216421 15.1227 0.373542C13.9928 0.768727 12.9907 1.46096 12.2223 2.37717C11.454 3.29337 10.9478 4.39955 10.7573 5.57893C10.5668 6.75831 10.699 7.96713 11.14 9.07777L0.780775 19.4607C0.533244 19.7076 0.336887 20.0007 0.20292 20.3233C0.0689526 20.6459 0 20.9917 0 21.3409C0 21.6901 0.0689526 22.0358 0.20292 22.3584C0.336887 22.681 0.533244 22.9742 0.780775 23.221C1.02824 23.468 1.32204 23.6639 1.6454 23.7976C1.96876 23.9312 2.31534 24 2.66535 24C3.01537 24 3.36195 23.9312 3.68531 23.7976C4.00867 23.6639 4.30247 23.468 4.54993 23.221L14.9091 12.898C16.0223 13.3295 17.2311 13.455 18.4095 13.2616C19.588 13.0681 20.6927 12.5628 21.6087 11.7982C22.5246 11.0337 23.2181 10.0379 23.6169 8.91484C24.0157 7.79177 24.1051 6.58251 23.8759 5.41321ZM2.66535 22.6702C2.31198 22.6702 1.97307 22.5301 1.7232 22.2808C1.47332 22.0315 1.33294 21.6934 1.33294 21.3409C1.33294 20.9883 1.47332 20.6502 1.7232 20.4009C1.97307 20.1516 2.31198 20.0116 2.66535 20.0116C3.01873 20.0116 3.35763 20.1516 3.60751 20.4009C3.85738 20.6502 3.99776 20.9883 3.99776 21.3409C3.99776 21.6934 3.85738 22.0315 3.60751 22.2808C3.35763 22.5301 3.01873 22.6702 2.66535 22.6702Z"
      fill="#8C98A4"
    />
  </SvgIcon>
);

export default Tool;
