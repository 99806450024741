import { EIP_CONSTANT } from '@eip/next/lib/main';

export const COMPACT_KEYWORD_OPTIMIZATION_CONFIG = {
  tableType: 'compact',
  title: 'Ad optimization',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getAdsPlacementData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_PLACEMENT',
  },
  columnOrder: [
    'keyword',
    'ad_object',
    'campaign',
    'country',
    'campaign',
    'storefront',
    'marketplace',
    'ad_tool',
    'keyword_id',
    'campaign_status',
    'campaign_budget',
    'campaign_daily_budget',
  ],
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADS_CAMPAIGN.id',
    'ADS_PLACEMENT.id',
    'ADS_OBJECT.id',
    'ADS_CALENDAR.id',
    'PRODUCT.id',
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool',
      },
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN',
      sortField: 'ADS_CAMPAIGN.name',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.id',
      },
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT',
      filterGetter: {
        default: 'ADS_OBJECT.name',
      },
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        subtext: 'PRODUCT.product_sid',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
      },
    },
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.name',
      valueGetter: {
        label: 'ADS_PLACEMENT.name',
        id: 'ADS_PLACEMENT.id',
        ...(ff.add_script_compact_table_properties ? { scriptId: 'SCRIPT.id' } : {}),
      },
    },
    keyword_id: {
      title: 'Keyword id',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.id',
      valueGetter: {
        value: 'ADS_PLACEMENT.id',
        label: 'ADS_PLACEMENT.id',
        id: 'ADS_PLACEMENT.id',
      },
    },
    // negative_keyword: {
    //   title: 'Negative keyword',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    // audience: {
    //   title: 'Audience',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    // placement: {
    //   title: 'Campaign',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },

    // ============= ATTRIBUTE =============
    // campaign_brief: {
    //   title: 'Campaign brief',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.campaign_brief',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.campaign_brief',
    //   },
    // },
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
      },
    },
    // disabled due to lack of api support
    // campaign_timeline: {
    //   title: 'Campaign timeline',
    //   propertyType: 'attribute',
    //   cellFormat: 'timeline',
    //   dataType: 'date',
    //   filterField: 'ADS_CAMPAIGN.timeline_from',
    //   valueGetter: {
    //     date_from: 'ADS_CAMPAIGN.timeline_from',
    //     date_to: 'ADS_CAMPAIGN.timeline_to',
    //   },
    // },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'dailyBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.daily_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        currency: 'STOREFRONT.currency',
      },
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'totalBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.total_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        currency: 'STOREFRONT.currency',
      },
    },
    // ad_objective: {
    //   title: 'Ad objective',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.ad_objective',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.ad_objective',
    //   },
    // },

    // disabled due to lack of api support
    // ad_object_budget: {
    //   title: 'Ad object budget',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'float',
    //   filterField: 'ADS_OBJECT.total_budget',
    //   valueGetter: {
    //     budgets: 'ADS_OBJECT.total_budget',
    //   },
    // },
    // disable due to lack of api support
    // ad_object_bidding_price: {
    //   title: 'Ad object bidding price',
    //   propertyType: 'attribute',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ADS_OBJECT.bidding_price',
    //   valueGetter: {
    //     value: 'ADS_OBJECT.bidding_price',
    //   },
    // },
    // ad_object_creative: {
    //   title: 'Ad object creative',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative',
    //   },
    // },
    keyword_status: {
      title: 'Keyword status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.status',
      valueGetter: {
        label: 'ADS_PLACEMENT.status',
        status: 'ADS_PLACEMENT.status',
        id: 'ADS_PLACEMENT.id',
        tooltips: 'ADS_PLACEMENT.tooltips',
      },
    },
    keyword_bidding_price: {
      title: 'Keyword bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_PLACEMENT.bidding_price',
      valueGetter: {
        value: 'ADS_PLACEMENT.bidding_price',
        currency: 'STOREFRONT.currency',
        id: 'ADS_PLACEMENT.id',
      },
    },
    keyword_match_type: {
      title: 'Keyword match type',
      propertyType: 'attribute',
      cellFormat: 'textMatchType',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.match_type',
      valueGetter: {
        value: 'ADS_PLACEMENT.match_type',
        id: 'ADS_PLACEMENT.id',
      },
    },
    // premium_rate: {
    //   title: 'Premium rate',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.premium_rate',
    //   valueGetter: {
    //     label: 'AUDIENCE.premium_rate',
    //   },
    // },
    // age: {
    //   title: 'Age',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.age',
    //   valueGetter: {
    //     label: 'AUDIENCE.age',
    //   },
    // },
    // gender: {
    //   title: 'Gender',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.gender',
    //   valueGetter: {
    //     label: 'AUDIENCE.gender',
    //   },
    // },
    // location: {
    //   title: 'Location',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.location',
    //   valueGetter: {
    //     label: 'AUDIENCE.location',
    //   },
    // },
    // interest_category: {
    //   title: 'Interest category',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.interest_category',
    //   valueGetter: {
    //     label: 'AUDIENCE.interest_category',
    //   },
    // },

    // ============= METRIC =============
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    direct_ads_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    items_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
    },
    ...(ff.add_script_compact_table_properties
      ? {
          script_amount: {
            title: 'Script amount',
            propertyType: 'metric',
            cellFormat: 'currency',
            dataType: 'integer',
            filterField: 'script_amount',
            valueGetter: {
              value: 'script_amount',
            },
          },
        }
      : {}),
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
};
