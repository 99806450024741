import * as React from 'react';

import { Box, makeStyles, Button } from '@material-ui/core';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    padding: '6px 12px',
    columnGap: '8px',
  },
}));

const FooterButtons = () => {
  const classes = useStyles();

  const backbone = React.useContext(TableBackboneContext);
  const buttons = backbone.addon('footer.buttons', () => [])();

  if (!buttons.length) return null;

  return (
    <Box className={classes.container}>
      {buttons.map((button) => {
        return (
          <Button
            key={button.id}
            variant={button.variant || 'contained'}
            color={button.color || 'primary'}
            onClick={button.onClick}
          >
            {button.title}
          </Button>
        );
      })}
    </Box>
  );
};

export default FooterButtons;
