import { useLog } from '@eip/next/lib/main';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeTable } from '@ep/insight-ui/elements/table/table-container2';
import { cloneDeep, merge, uniqueId } from 'lodash';
import React from 'react';
import { CAMPAIGN_DETAIL_INIT_CONFIG, COMPACT_AD_TOOL_CONFIG } from './table-config';

const CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY = CAMPAIGN_DETAIL_INIT_CONFIG;
const COMPACT_TABLE_CONFIG = {
  ad_tool: { ...COMPACT_AD_TOOL_CONFIG, tableId: 'ad_tool_compact' },
};

const log = useLog('chartlib:table-campaign-detail');

export const TableCampaignDetail = ({
  initialRows,
  onChange = (items) => console.log('onChange', items),
}: {
  initialRows: any[];
  onChange: (items: any[], type: string) => void;
}) => {
  const refBackbone = React.useRef<ReturnType<typeof useTableBackbone>>();
  const stateDispatch = React.useRef<any>();
  const stateSelector = React.useRef<any>();

  React.useLayoutEffect(() => {
    stateSelector.current = initialRows;
  }, [initialRows]);

  const changeConfiguration = (config: any) => {
    log('Update configuration', config);
  };

  const getCompactTableConfig = (type) => {
    const tableConfig = COMPACT_TABLE_CONFIG[type];
    const finalConfig: any = {
      tableCompactId: tableConfig.tableId,
      configuration: tableConfig,
      submitRowSelectionOnClosed: true,
      callback: {
        onRowSelect: async (rows) => {
          const updatedRows = rows;
          if (updatedRows && updatedRows.length > 0) {
            onChange(updatedRows, type);
          }
        },
      },
      addons: {
        'datasource.transformFilter': async (filter, originTransform) => {
          return filter;
        },
      },
    };
    log('Compact table config', { type, finalConfig });

    return finalConfig;
  };

  const tableContainer = React.useMemo(() => {
    let apiRequest = null;
    apiRequest = {
      getTableData: () => {
        return new Promise((resolve) => {
          window.setTimeout(() => {
            const rows = stateSelector.current;
            log('apirequest.tabledata', rows);
            return resolve({
              data: {
                rows: rows,
                pagination: { page: 1, limit: 100, total: rows.length },
              },
            });
          }, 50);
        });
      },
    };

    const tableConfig = merge(cloneDeep(CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY), {
      mapping: {
        ad_tool: {
          tableConfiguration: getCompactTableConfig('ad_tool'),
        },
      },
    });

    console.log('table config', tableConfig);

    const config = {
      apiRequest: apiRequest,
      id: uniqueId('ep_campaign_detail_'),
      configuration: tableConfig,
      callback: {
        onBackboneReady: (backbone) => {
          refBackbone.current = backbone;
        },
      },
      addons: {},
    };

    log('change makeTable', config);
    return makeTable({
      config,
      changeConfiguration,
      tableBackboneHook: useTableBackbone,
      tableContext: TableBackboneContext,
    });
  }, [initialRows]);

  React.useEffect(() => {
    if (refBackbone.current)
      window.setTimeout(() => {
        if (refBackbone.current) refBackbone.current.reloadData('table');
      }, 300);
  }, [initialRows]);

  return tableContainer;
};
