import * as React from 'react';
import WrapperFormat from './wrapper-format';
import { Grid, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import moment from 'moment';

const DateTimeCompare = (props: any) => {
  const value = get(props, ['value', 'value'], null);
  const typeTable = get(props, 'typeTable', '');
  const isGrouped = get(props, 'isGrouped', false);
  const node = get(props, 'node', null);

  const dateTimeConfig = get(props, 'value.dateTime', '');
  const updatedAt = value;

  const renderDateTime = () => {
    let convertDate;
    if (dateTimeConfig && updatedAt) {
      switch (dateTimeConfig) {
        case 'absolute':
          convertDate = moment.utc(updatedAt).local().format('YYYY-MM-DD HH:mm:ss [GMT]ZZ');
          break;
        case 'relative':
          const time = moment.utc(updatedAt).local();
          convertDate = moment(time).fromNow();
          break;
        default:
          convertDate = updatedAt;
          break;
      }
      return <span>{convertDate}</span>;
    }
    return null;
  };

  return (
    <WrapperFormat typeTable={typeTable} cellAction={[]} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems="center" wrap={'nowrap'}>
        {renderDateTime()}
      </Grid>
    </WrapperFormat>
  );
};

export default DateTimeCompare;
