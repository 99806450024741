import { EIP_CONSTANT } from '@eip/next/lib/main';
import { METRIC_CONFIG as METRIC_CAMPAIGN_CONFIG } from './chart-config/metric-campaign-config';
import { METRIC_CONFIG as METRIC_STOREFRONT_CONFIG } from './chart-config/metric-storefront-config';
import { INSIGHT_METRIC_STOREFRONT_CONFIG } from './chart-config/insight-metric-storefront';
import { OPERATION_INDEX_DASHBOARD_CONFIG } from './chart-config/operation-index-dashboard';
import { EDashboardChartLib } from './epsilo-chart';

/**
 * ff.mass_operation_management:start
 */
import { MASS_OPERATION_DASHBOARD_CONFIG } from './chart-config/mass-operation-dashboard';
/**
 * ff.mass_operation_management:end
 */

export default {
  blockType: 'eChartv2',
  label: 'eVisualization',
  blockComponent: EDashboardChartLib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 12,
  },
  packagedConfigs: {
    default: METRIC_STOREFRONT_CONFIG,
    INSIGHT_METRIC_STOREFRONT_CONFIG,
    OPERATION_INDEX_DASHBOARD_CONFIG,
    METRIC_STOREFRONT_CONFIG,
    METRIC_CAMPAIGN_CONFIG,
    ...(ff.mass_operation_management ? { MASS_OPERATION_DASHBOARD_CONFIG } : {}),
    ...(ff.revive_all_charts
      ? {
          packagedConfigs: {
            METRIC_CAMPAIGN_CONFIG,
          },
        }
      : {}),
  },
};
