import * as React from 'react';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { Box, Button, makeStyles, Grid, Dialog } from '@material-ui/core';
import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import { useNotificationGlobal } from '@ep/insight-ui/elements/notifications/hook';

const useStyles = makeStyles({
  title: { paddingLeft: '10px' },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.message': {
      padding: '10px',
    },
  },
});

const API_PUT_USER = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/modify-workspace-user';

export const RemoveUser = ({ data }: any) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
  };
  const globalToast = useNotificationGlobal();

  const handleRemove = async () => {
    const response = await request.put(API_PUT_USER, {
      action: 'REMOVE', // ADD or REMOVE
      arrayUserEmail: [data.node.data.email],
      workspaceCode: 'WP1001',
    });
    if (response.serviceResponse) {
      if (response.serviceResponse.code === 1000) {
        globalToast.onToastCustomize({
          title: 'Remove user',
          content: (
            <div>
              <div>{response.serviceResponse.message}</div>
            </div>
          ),
          variant: 'error',
          disableIcon: false,
        });
      }
      if (response.serviceResponse.code === 0) {
        globalToast.onToastCustomize({
          title: 'Remove user',
          content: (
            <div>
              <div>{response.serviceResponse.message}</div>
            </div>
          ),
          variant: 'success',
          disableIcon: false,
        });
      }
    }
    setOpen(false);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Typography className={`${classes.title}`} variant="h2">
          Remove access
        </Typography>
        <Box className={`${classes.text} message`}>
          <p>Are you sure to remove the selected member from access to your company?</p>
          <p>By this action, the selected member will no longer can view & edit your company related data</p>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <Button
            color="secondary"
            variant="contained"
            component="span"
            className={`${classes.button}`}
            onClick={handleClose}
          >
            <span className={`${classes.text} textButton`}>Cancel</span>
          </Button>
          <Button
            color="primary"
            variant="contained"
            component="span"
            className={`${classes.button}`}
            onClick={handleRemove}
          >
            <span className={`${classes.text} textButton primary`}>Remove</span>
          </Button>
        </Box>
      </Dialog>
    </>
  );
};
