import * as React from 'react';
import * as uuid from 'uuid';
import { cloneDeep, uniqBy, get, uniq } from 'lodash';
import { useLocation } from 'react-router-dom';
import moment from 'moment';

import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import { EIP_CONSTANT, aim, AuthContext } from '@eip/next/lib/main';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import minimatch from 'minimatch';
import { usePassport } from './use-passport';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';

const RELOAD_SANDBOX_INFORMATION_EVENT = 'reload_sandbox_information_event';

export const useAppMenu = (dataListMenu) => {
  const userProfile = aim.getUserSettings() ? aim.getUserSettings().profile : ({} as aim.UserSettings['profile']);
  const userEmail = String(userProfile.userEmail).toLowerCase();

  const [appMenuList, setAppMenuList] = React.useState([]);
  const [userSessions, setUserSessions] = React.useState({});
  const [userInfo, setUserInfo] = React.useState({});
  const [sandboxPageInformation, setSandboxPageInformation] = React.useState(null);

  const timeoutLabel = React.useRef(null);

  const [customActions, setCustomActions] = React.useState([]);
  const appMenuListRef = React.useRef([]);
  const [blocks, setBlocks] = React.useState([]);
  const { currentWorkspaceDetail } = React.useContext(AuthContext);

  const convertUTCToLocal = (utc, format = 'YYYY-MM-DD HH:mm:ss') => {
    return moment.utc(utc).local().format(format);
  };

  const additionalBlockInfo = React.useMemo(() => {
    const blockInfo = {};
    if (blocks.length > 0) {
      blocks.forEach((block) => {
        const blockEid = block.block_eid;
        const updatedAt = convertUTCToLocal(block.updated_at.replace('.000Z', '').replace('T', ' '));
        const viewAt = userSessions[blockEid] ? convertUTCToLocal(userSessions[blockEid]) : null;
        const uInfo = userInfo[block.updated_by];
        const updatedBy = uInfo ? uInfo : block.updated_by;
        const isViewed = viewAt && updatedAt ? updatedAt <= viewAt : true;
        const messages = [];
        const sandboxStatus = get(sandboxPageInformation, [blockEid, 'status'], '');
        if (!isViewed) {
          if (updatedAt) {
            messages.push(`Edited ${moment(updatedAt).fromNow()} by ${updatedBy || 'unknown'}`);
          }
          if (viewAt) {
            messages.push(`Last viewed ${moment(viewAt).fromNow()}`);
          }
        }
        if (['init', 'processing'].includes(sandboxStatus)) {
          messages.push(`Generating sandbox data (${get(sandboxPageInformation, [blockEid, 'process'])}%)`);
        }
        blockInfo[blockEid] = messages;
      });
    }
    return blockInfo;
  }, [blocks, userSessions, userInfo, sandboxPageInformation]);

  const location = useLocation();

  const EXPERIMENTATION_CODE = getConst('EXPERIMENTATION_CODE', 13);
  const NEW_BOTEP_ID = getConst('NEW_BOTEP_ID', 'botep');

  let getLeftMenu, updateLeftMenu, createNewFeature;

  const flatAppMenuList = (appMenu, parentIds = []) => {
    return appMenu.reduce((carry, menu) => {
      const subTabs = flatAppMenuList(menu.tabs || [], parentIds.concat(menu.nodeId));
      return carry.concat(subTabs, { ...menu, parentIds: (menu.parentIds || []).concat(parentIds) });
    }, []);
  };

  const hookResponse = usePassport();
  const updateUserFavorites = hookResponse.updateUserFavorites;
  const [favorites, setFavorites] = React.useState(() => {
    const favorite = aim.getUserSettings().profile?.favorite;
    return favorite ? JSON.parse(favorite) : [];
  });
  const favoritesRef = React.useRef(favorites);
  const favoritesMenuList = React.useMemo(() => {
    if (appMenuList.length === 0) return [];
    const flatAppMenu = flatAppMenuList(appMenuList);
    return [
      {
        accessBy: [],
        label: 'Favorites',
        nodeId: 'favorites',
        product_id: 'favorites',
        product_sequence: 0,
        tabs: favorites
          .filter((fv) => Boolean(fv))
          .map((fv) => {
            if (fv.isDetailPage) {
              return {
                ...fv,
                blockEid: fv.nodeId,
                type: 'page',
                tabs: [],
                sequence: 99999,
                parentIds: ['favorites'],
                id: fv.nodeId,
                blockType: 'page',
                accessBy: [],
              };
            }
            const pageDetail = flatAppMenu.find((el) => el.nodeId == fv.nodeId);
            return pageDetail;
          })
          .filter((fv) => Boolean(fv?.nodeId) || Boolean(fv?.isDetailPage)),
      },
    ];
  }, [favorites, appMenuList]);
  const updateFavorites = (newFavorites) => {
    updateUserFavorites(newFavorites);
    const userSettings = aim.getUserSettings();
    const newFavorite = JSON.stringify(newFavorites);
    const newWorkspaces = (userSettings.workspaces || []).map((ws) => {
      return {
        ...ws,
        favorite: ws.workspace_id === userSettings.profile.workspace_id ? newFavorite : ws.favorite,
      };
    });
    aim.setUserSettings({
      ...userSettings,
      profile: {
        ...userSettings.profile,
        favorite: newFavorite,
      },
      workspaces: newWorkspaces,
    });
    favoritesRef.current = newFavorites;
    setFavorites(newFavorites);
  };

  if (ff.next_left_menu) {
    getLeftMenu = hookResponse.getLeftMenu;
    updateLeftMenu = hookResponse.updateLeftMenu;
    createNewFeature = hookResponse.createNewFeature;
  }

  const isFullPage = React.useMemo(() => {
    const page = blocks.find(
      (block) =>
        block.block_eid ===
        get(location.pathname.match(/[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}/i), '[0]', ''),
    );
    return get(page, 'payload.properties.displayFullPage', false);
  }, [location.pathname, blocks]);

  React.useEffect(() => {
    appMenuListRef.current = appMenuList;
  }, [appMenuList]);

  const addNewSidebarSection = (index) => {
    const cloneAppMenuList = cloneDeep(appMenuListRef.current);

    cloneAppMenuList.splice(index + 1, 0, {
      label: 'Untitled',
      nodeId: uuid.v4(),
      tabs: [],
      accessBy: ['*@epsilo.io'],
    });
    updateSidebar(cloneAppMenuList);
  };

  const deleteSidebarSection = (index) => {
    const cloneAppMenuList = cloneDeep(appMenuListRef.current);

    cloneAppMenuList.splice(index, 1);
    updateSidebar(cloneAppMenuList);
  };

  const renameSidebarSection = (index, value) => {
    if (value) {
      clearTimeout(timeoutLabel.current);

      timeoutLabel.current = setTimeout(() => {
        const cloneAppMenuList = cloneDeep(appMenuListRef.current);

        cloneAppMenuList[index].label = value;
        updateSidebar(cloneAppMenuList);
      }, 500);
    }
  };

  const removeBotepFromSidebar = (sidebar) => {
    return sidebar
      .filter((menu) => menu.nodeId !== 'botep_addnew')
      .map((menu) => {
        if (!menu.tabs) return menu;
        return {
          ...menu,
          tabs: removeBotepFromSidebar(menu.tabs),
        };
      });
  };

  const cloneFeature = (nodeId, cloneId) => {
    const flattedAppMenuList = flatAppMenuList(appMenuListRef.current || []);
    const nodeDetail = flattedAppMenuList.find((menu) => menu.nodeId === nodeId);
    if (nodeDetail) {
      return createNewFeature({
        name: `${nodeDetail.label} clone`,
        code: cloneId,
        productId: EXPERIMENTATION_CODE,
      });
    } else {
      alert('Page not found');
    }
  };

  const updateSidebar = (listMenu, updatedSideBar?) => {
    setAppMenuList(listMenu);
    if (ff.next_left_menu && updatedSideBar) {
      const sidebarWithoutBotep = removeBotepFromSidebar(updatedSideBar);
      updateLeftMenu(sidebarWithoutBotep)
        .then((res) => {
          console.info({ res });
        })
        .catch((e) => {
          // Do something here!!!
        });
    }
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv', {
        k: 'sidebar',
        v: listMenu,
        version: Date.now(),
      })
      .then((res) => {
        console.info({ res });
      })
      .catch((e) => {
        // Do something here!!!
      });
  };

  const getSidebarData = async () => {
    let result = [];
    const inusedPageIds = [];
    function filterMenuPermission(menuList, parent?) {
      const ml = menuList.filter((m) => {
        if (parent !== NEW_BOTEP_ID) {
          inusedPageIds.push(m.nodeId);
        }
        if (m.accessBy && m.accessBy.length > 0) {
          let allowAccess = false;

          if (aim.isSuperAdmin()) return true;

          const includedAccess = m.accessBy.filter((i) => !String(i).startsWith('!'));
          const excludeAccess = m.accessBy.filter((i) => String(i).startsWith('!'));

          allowAccess = allowAccess || includedAccess.length === 0;
          allowAccess =
            allowAccess || includedAccess.some((i) => minimatch(userProfile.userId, i, { matchBase: true }));
          allowAccess =
            allowAccess ||
            includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
          allowAccess =
            allowAccess &&
            (excludeAccess.length === 0 ||
              excludeAccess.every((i) => minimatch(userProfile.userId, i, { matchBase: true })));

          allowAccess =
            allowAccess &&
            (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

          // if (m.accessBy.includes('*@epsilo.io')) {
          //   allowAccess = allowAccess || userEmail.includes('@epsilo.io');
          // }
          return allowAccess;
        }
        return true;
      });

      ml.forEach((m) => {
        m.tabs = filterMenuPermission(m.tabs || [], m.nodeId);
      });

      return ml;
    }

    let newBlocks = [];
    let listResponse;
    try {
      listResponse = await request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/system_page/list');
      newBlocks = Object.values(listResponse.block);
      setBlocks(newBlocks);
    } catch (e) {}

    if (ff.next_left_menu) {
      try {
        const sidebarResponse = await getLeftMenu();
        result = sidebarResponse.data;
        const experimentationIndex = result.findIndex((el) => el.nodeId === EXPERIMENTATION_CODE);
        if (experimentationIndex > -1 && aim.isAllow()) {
          result[experimentationIndex].tabs.push({
            label: 'Add',
            nodeId: 'botep_addnew',
            type: 'btn_create_system_page',
            onClick: (params) => createNewFeature({ ...params, productId: EXPERIMENTATION_CODE }),
          });
        }
      } catch (e) {
        result = e.data || [];
      }
      result = currentWorkspaceDetail?.workspace_domain == 'sandbox' ? result : filterMenuPermission(result);
    } else {
      try {
        const sidebarResponse = await request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/sidebar');

        let botepPages = [];
        if (listResponse) {
          botepPages = Object.values(listResponse.block)
            .filter((el) => get(el, 'payload.properties.groupId', '') === NEW_BOTEP_ID)
            .map((el) => ({
              nodeId: el.block_eid,
              blockEid: el.block_eid,
              label: get(el, 'payload.properties.title', 'Untitled'),
              blockType: el.block_type,
              locked: false,
              tabs: [],
              type: el.block_type,
            }));
        }
        if (sidebarResponse.success && sidebarResponse.data.v.length > 0) {
          const menuList = filterMenuPermission(sidebarResponse.data.v);
          let existBotep = dataListMenu.find((i) => i.nodeId === NEW_BOTEP_ID);
          if (!existBotep) {
            existBotep = { tabs: [] };
          }
          const finalMenuList = menuList.map((el) => {
            if (el.nodeId !== NEW_BOTEP_ID) return el;
            return {
              ...el,
              tabs: uniqBy(
                botepPages
                  .concat(existBotep.tabs)
                  .map((ele) => {
                    const nodeDetail = el.tabs.find((elem) => elem.nodeId === ele.nodeId);
                    if (nodeDetail) {
                      return {
                        ...ele,
                        ...nodeDetail,
                      };
                    }
                    return ele;
                  })
                  .filter((i) => i.nodeId === 'botep_addnew' || !inusedPageIds.includes(i.nodeId)),
                (i) => i.nodeId,
              ),
            };
          });
          result = finalMenuList;
        } else {
          result = dataListMenu;
        }
      } catch (e) {
        result = dataListMenu;
      }
    }

    return result;
  };

  const reloadSandboxInformation = (sidebarData) => {
    if (String(userEmail).endsWith('@epsilo.io')) {
      try {
        const payload = {
          action: 'check_state_all',
        };
        request.post(EIP_CONSTANT.API_HOST.API_SANDBOX_MANAGER + '/admin/apis.jsp', payload).then((res) => {
          const newSandboxPageInformation = get(res, ['data'], []).reduce((carry, feature) => {
            return {
              ...carry,
              [feature.processId]: feature,
            };
          }, {});
          setSandboxPageInformation(newSandboxPageInformation);
        });
      } catch {}
    }
  };

  React.useEffect(
    () => {
      if (currentWorkspaceDetail || !ff.next_left_menu) {
        getSidebarData().then((sidebarData) => {
          setAppMenuList(sidebarData);
          setTimeout(() => {
            reloadSandboxInformation(sidebarData);
          }, DELAY_REQUEST_ADDITIONAL_DATA);
        });
        const handleEvent = (e) => {
          reloadSandboxInformation(appMenuListRef.current);
        };
        request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/menu-page-actions').then((res) => {
          if (res.success) {
            setCustomActions(get(res, ['data', 'v'], []));
          }
        });
        if (String(userEmail).endsWith('@epsilo.io')) {
          window.addEventListener(RELOAD_SANDBOX_INFORMATION_EVENT, handleEvent);

          return () => {
            window.removeEventListener(RELOAD_SANDBOX_INFORMATION_EVENT, handleEvent);
          };
        }
      }
    },
    ff.next_left_menu ? [currentWorkspaceDetail] : [],
  );

  React.useEffect(() => {
    if (sandboxPageInformation) {
      const isProcessing = Object.values(sandboxPageInformation).some(
        (value) => value && ['processing', 'init'].includes(value.status),
      );
      if (isProcessing) {
        setTimeout(() => {
          reloadSandboxInformation(appMenuListRef.current);
        }, 10000);
      }
    }
  }, [sandboxPageInformation]);

  React.useEffect(() => {
    if (String(userEmail).endsWith('@epsilo.io') && blocks.length > 0) {
      const updatedBy = uniq(Object.values(blocks).map((el) => el.updated_by)).filter(
        (el) => !!el && !String(el).endsWith('@epsilo.io'),
      );
      if (updatedBy.length) {
        setTimeout(() => {
          request
            .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=passport', {
              dimensions: ['user'],
              attributes: ['user.email', 'user.uid'],
              metrics: [],
              pagination: {
                page: 1,
                limit: 999,
              },
              from: '2023-01-01',
              to: '2023-12-31',
              sort: [],
              filter: {
                combinator: 'and',
                filters: [
                  {
                    field: 'user.uid',
                    operator: 'IN',
                    value: updatedBy.join(','),
                    dataType: 'string',
                  },
                ],
              },
              isSummary: true,
            })
            .then((res) => {
              const uInfo = {};
              const headers = get(res, ['data', 'headers'], []);
              const userUidIndex = headers.findIndex((header) => header === 'user.uid');
              const userEmailIndex = headers.findIndex((header) => header === 'user.email');
              const rows = get(res, ['data', 'rows'], []);
              rows.forEach((row) => {
                uInfo[row[userUidIndex]] = row[userEmailIndex];
              });
              setUserInfo(uInfo);
            });
        }, 5000);
      }
      setTimeout(() => {
        request
          .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=quality', {
            dimensions: ['trace_frontend'],
            attributes: ['trace_frontend.page_id', 'trace_frontend.started_at'],
            metrics: [],
            pagination: {
              page: 1,
              limit: 999,
            },
            from: '2023-01-01',
            to: '2023-12-31',
            sort: [
              {
                field: 'trace_frontend.started_at',
                sort: 'DESC',
              },
            ],
            filter: {
              combinator: 'and',
              filters: [
                {
                  field: 'trace_frontend.action_type',
                  operator: 'IN',
                  value: 'DocumentLoad,Navigation',
                  dataType: 'string',
                },
                {
                  field: 'trace_frontend.user_email',
                  operator: 'is',
                  value: userEmail,
                  dataType: 'string',
                },
                {
                  field: 'trace_frontend.page_name',
                  operator: 'is_not_empty',
                  value: '',
                },
              ],
            },
            groupBy: {
              dimensions: ['trace_frontend.page_id'],
              aggregations: [
                {
                  field: 'trace_frontend.page_id',
                  func: 'MAX',
                },
                {
                  field: 'trace_frontend.started_at',
                  func: 'MAX',
                },
              ],
              column: ['trace_frontend.page_id'],
            },
            isSummary: true,
          })
          .then((res) => {
            const uSessions = {};
            const headers = get(res, ['data', 'headers'], []);
            const pageIdIndex = headers.findIndex((header) => header === 'trace_frontend.page_id');
            const startedAtIndex = headers.findIndex((header) => header === 'trace_frontend.started_at');
            const rows = get(res, ['data', 'rows'], []);
            rows.forEach((row) => {
              uSessions[row[pageIdIndex]] = row[startedAtIndex];
            });
            setUserSessions(uSessions);
          });
      }, DELAY_REQUEST_ADDITIONAL_DATA);
    }
  }, [userEmail, blocks]);

  return {
    appMenuList,
    updateSidebar,
    appMenuListRef,
    addNewSidebarSection,
    deleteSidebarSection,
    renameSidebarSection,
    isFullPage,
    setAppMenuList,
    cloneFeature,
    flatAppMenuList,
    favorites,
    updateFavorites,
    favoritesMenuList,
    favoritesRef,
    additionalBlockInfo,
    sandboxPageInformation,
    customActions,
  };
};
