import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Task = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.29473 2.93815C6.90265 3.33087 6.90265 3.9659 7.29473 4.35862C7.6868 4.75134 8.3208 4.75134 8.71287 4.35862L9.00067 4.07035L9.28847 4.35862C9.68055 4.75134 10.3145 4.75134 10.7066 4.35862C11.0987 3.9659 11.0987 3.33087 10.7066 2.93815L10.4188 2.64988L10.7066 2.36161C11.0987 1.96889 11.0987 1.33386 10.7066 0.941144C10.3145 0.548427 9.68055 0.548427 9.28847 0.941144L9.00067 1.22942L8.71287 0.941144C8.3208 0.548427 7.6868 0.548427 7.29473 0.941144C6.90265 1.33386 6.90265 1.96889 7.29473 2.36161L7.58253 2.64988L7.29473 2.93815Z"
      fill={props.color}
    />
    <path
      d="M9.70575 9.34961L9.70245 9.35292C9.31083 9.7445 8.67756 9.7445 8.28594 9.35292L7.29439 8.36147C6.90277 7.96989 6.90277 7.33669 7.29439 6.94511C7.68601 6.55352 8.31927 6.55352 8.7109 6.94511L8.99421 7.22839L10.2857 5.93699C10.6774 5.54541 11.3106 5.54541 11.7022 5.93699C12.0939 6.32857 12.0939 6.96177 11.7022 7.35335L9.7108 9.34459L9.70575 9.34961Z"
      fill={props.color}
    />
    <path
      d="M0 7.65076C0 7.09928 0.451253 6.64808 1.00279 6.64808H4.99721C5.54875 6.64808 6 7.09928 6 7.65076C6 8.20223 5.54875 8.65344 4.99721 8.65344H1.00279C0.451253 8.65344 0 8.20223 0 7.65076Z"
      fill={props.color}
    />
    <path
      d="M1.00279 1.65138C0.451253 1.65138 0 2.10258 0 2.65406C0 3.20553 0.451253 3.65674 1.00279 3.65674H4.99721C5.54875 3.65674 6 3.20553 6 2.65406C6 2.10258 5.54875 1.65138 4.99721 1.65138H1.00279Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Task;
