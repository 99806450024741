import { NodeEditContext, useLog } from '@eip/next/lib/main';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { assign, cloneDeep, debounce, get, mapValues, set } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { GRID_MARGIN } from '../../constant';
import { BlockFactory } from './block-factory-viewonly';
import { BlockFactory as BlockFactoryNext } from './block-factory-viewonly-next';
import { EIPContext, FilterContext, FilterType } from './context';
import { actions } from './dashboard-redux';
import { DashboardBreakpoints, NodeData, NodeLayout } from './type';
import { useLocation } from 'react-router';

const log = useLog('dbf:grid:viewonly');

const emptyFun: (...args: any[]) => any = () => undefined;

type DashboardScreenLayout = NodeLayout;

type DashboardResponsiveLayoutType = {
  [key in DashboardBreakpoints]: DashboardScreenLayout[];
};
type DashboardProps = {
  layouts: DashboardResponsiveLayoutType;
  nodes: NodeData[];
  layoutColumns: Dashboard['layoutColumns'];
  filterComponent?: React.Component;
};

/**
 *  FIXME: replace the react-grid with the css grid
 */
export function DashboardFrameViewMode({ layouts, nodes }: DashboardProps) {
  const isEditMode = false;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q } = queryParams;

  const classes = useStyle();
  const context = React.useContext(EIPContext);

  // FIXME: GlobalFilter will be updated from chartlib "GLobal Filter"
  const [globalFilter, setGlobalFilter] = React.useState<FilterType>({
    channels: [],
    tools: [],
    countries: [],
    shops: [],
  });
  const [blockWidthUnit, setBlockWidthUnit] = React.useState<number>(0);

  const containerRef = React.useRef();

  const rLayouts = React.useMemo(() => {
    const visibleNodeIds = nodes.map((i) => i.id).filter((i) => (q ? q.split(',').includes(i) : true));
    const sortedLayouts = mapValues(layouts, (layout: any[]) => {
      let yOffset = 0;
      return layout
        .map((i: any) => ({ i: i.id, ...i.layout }))
        .sort((a, b) => (a.y + 1) * (a.x + 1) - (b.y + 1) * (a.x + 1))
        .reduce((acc, block, index, arr) => {
          const blocksSameRow = arr.filter((item) => item.i !== block.i && item.y === block.y);
          let newBlock = null;
          if (visibleNodeIds.includes(block.i)) {
            newBlock = {
              ...block,
              y: block.y - yOffset,
            };
          } else if (blocksSameRow.length === 0) {
            yOffset += block.h;
          }
          return newBlock ? [...acc, newBlock] : acc;
        }, []);
    });

    log({ sortedLayouts });

    return sortedLayouts;
  }, [layouts, q]);

  // React.useEffect(() => {
  //   log('effects.... update nodes', nodes);
  //   context.onUpdateNodes(nodes);
  // }, [nodes]);

  const handleTextChange = React.useCallback(
    debounce((nodeData: NodeData, contentId, contentValue) => {
      const data = set(nodeData, `customAttributes.customText.${contentId}`, contentValue);
      handleNodeSubmit(data.id, data);
    }, 1000),
    [],
  );
  const handleUpdateCustomAttributes = React.useCallback((nodeData, attributes) => {
    const customAttributes = assign(cloneDeep(get(nodeData, 'customAttributes', {})), attributes);
    handleNodeSubmit(nodeData.id, {
      ...nodeData,
      customAttributes,
    });
  }, []);

  const handleNodeRequestUpdate = (nodeId, payload) => {
    log('editmode', { nodeId, payload });
  };

  const handleNodeSubmit = (nodeId, payload) => {
    log('handleNodeSubmit', { nodeId, payload });
    dispatch(actions.updateNode({ nodeId, data: payload }));
    context.onNodeSubmitUpdate(nodeId, payload);
  };

  const handleUpdateGlobalFilter = (filter: FilterType) => {
    setGlobalFilter(filter);
  };

  return (
    <div className={clsx(nodes.length === 0 && classes.dashboardBlank, classes.gridWrapper)} ref={containerRef}>
      <FilterContext.Provider
        value={{
          filter: globalFilter,
          onUpdateFilter: handleUpdateGlobalFilter,
        }}
      >
        <EIPContext.Provider
          value={{
            mode: 'view',
            blockWidthUnit: blockWidthUnit,
            onAddNewNode: emptyFun,
            onBreakpointChange: emptyFun,
            onGridChange: emptyFun,
            onUpdateNodes: context.onUpdateNodes,
            onNodeRequestConnection: emptyFun,
            onLayoutChange: emptyFun,
            onRemoveNode: emptyFun,
            onNodeSubmitUpdate: handleNodeSubmit,
            onNodeRequestUpdate: handleNodeRequestUpdate,
            onDuplicateNode: emptyFun,
            onToggleNodeDraggable: emptyFun,
          }}
        >
          <NodeEditContext.Provider
            value={{
              isEditMode,
              onTextChange: handleTextChange,
              onUpdateCustomAttributes: handleUpdateCustomAttributes,
            }}
          >
            {rLayouts.lg.map((i) => {
              return (
                <div
                  key={i.i}
                  className={'ep-dashboard-item'}
                  data-node-id={i.i}
                  style={{
                    gridColumnStart: i.x + 1,
                    gridColumnEnd: i.x + i.w + 1,
                    gridRowStart: i.y + 1,
                    gridRowEnd: i.y + i.h + 1,
                  }}
                >
                  <div className={'dashboard-item-card'} id={'block-' + i.i}></div>
                </div>
              );
            })}
            <BlockFactoryNext blocks={rLayouts.lg.map((i) => nodes.find((n) => n.id === i.i))} q={q} />
          </NodeEditContext.Provider>
        </EIPContext.Provider>
      </FilterContext.Provider>
    </div>
  );
}

const useStyle = makeStyles(() => {
  return {
    dashboardBlank: {
      position: 'relative',
      minHeight: '100vh',
      '& > div': {
        position: 'relative',
        zIndex: 1,
      },
      '&:after': {
        top: 0,
        left: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        content: '""',
        fontSize: '5em',
        color: '#eee',
      },
    },
    gridWrapper: (props) => ({
      display: 'grid',
      gridTemplateColumns: `repeat(36, 1fr)`,
      columnGap: GRID_MARGIN,
      rowGap: GRID_MARGIN,
    }),
    banner: {
      position: 'fixed',
      top: 56,
      zIndex: 1300,
    },
  };
});
