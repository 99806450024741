/* eslint-disable react/prop-types */
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import { TableBackboneContext, createDataQueryFromConfig } from '@ep/insight-ui/system/backbone/table-backbone';
import { AD_OBJECT_OPTIMIZATION_CONFIG } from '@ep/insight-ui/system/block/etable/table-config/ad-object-optimization';
import { AD_OPTIMIZATION_CONFIG } from '@ep/insight-ui/system/block/etable/table-config/ad-optimization';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import numeral from 'numeral';
import { cloneDeep, set } from 'lodash';
type StyleProps = {
  isAuto: boolean;
};

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    ...(ff.new_aggregate_for_campaign_cell_format
      ? {}
      : {
          flexDirection: 'column',
        }),
    height: '100%',
  },
  display: {
    display: 'none',
  },
  styleText: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
  }),
  subtext: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#8C98A4',
  }),
  autoSubtext: ({ isAuto }: StyleProps) => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
    textAlign: 'left',
  }),
  hashtag: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#006EC6',
  }),
  numberAlign: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  rowGroup: {
    height: '100%',
    width: '100%',
  },
}));

export interface IValueCurrencyFormat {
  value: number | string;
  currency: string;
  defaultCurrency: string;
  typeDisplay?: 'currency' | 'datetime' | 'text-number';
  formatDateDisplay?: string;
  hashtag?: string;
  subtext?: string;
}

const AggregateAdObjectBudget = (props: IPropsFormat<IValueCurrencyFormat>) => {
  const backbone = React.useContext(TableBackboneContext);

  const node = _.get(props, ['node'], []);
  const value = _.get(props, ['value'], []);
  const typeDisplay = 'currency';
  const currency = _.get(props, ['value', 'currency'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const dataType = _.get(props, 'dataType', '');
  const campaignId = _.get(props, ['value', 'campaignId']);
  const classes = useStyles({ isAuto: false });

  const [aggValue, setAggValue] = React.useState(null);
  let isWaitingData, setIsWaitingData;
  if (ff.new_aggregate_for_campaign_cell_format) {
    [isWaitingData, setIsWaitingData] = React.useState(false);
  }
  React.useEffect(() => {
    if (ff.new_aggregate_for_campaign_cell_format) {
      setIsWaitingData(true);
    }
    const config = cloneDeep(AD_OBJECT_OPTIMIZATION_CONFIG);
    set(config, 'mapping.campaign.filterField', 'ADS_CAMPAIGN.id');
    set(config, 'requestIgnoreField', { metric: ['STOREFRONT.currency'] });

    const dataQuery = createDataQueryFromConfig({
      config: config,
    });
    dataQuery
      .query({
        filter: [
          {
            type: 'filter',
            logicalOperator: 'AND',
            field: 'campaign',
            queryType: '=',
            queryValue: campaignId,
            dataType: 'integer',
          },
          ...backbone.getConfig('filter'),
        ],
        dateRange: backbone.getConfig('dateRange'),
        groupBy: {
          columns: ['campaign'],
          aggregations: { 'ADS_OBJECT.total_budget': { func: 'sum' }, cost: { func: 'sum' } },
        },
      })
      .then((res) => {
        setAggValue(res.rowData[0]['ADS_OBJECT.total_budget']);
        if (ff.new_aggregate_for_campaign_cell_format) {
          setIsWaitingData(false);
        }
      })
      .catch((e) => {
        setIsWaitingData(false);
      });
  }, []);

  const getCurrency = (number: number, currency = '', dataType = '') => {
    return formatCurrency(number, currency);
  };
  if (ff.new_aggregate_for_campaign_cell_format) {
    return (
      <WrapperFormat
        typeTable={typeTable}
        cellAction={[]}
        node={node}
        value={value}
        isGrouped={isGrouped}
        gridApi={props.api}
        disabledMenu={true}
      >
        <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
          <Grid item xs={12} className={`${classes.numberAlign}`}>
            {!isWaitingData ? (
              <div className={classes.styleText}>
                {typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`}
              </div>
            ) : (
              <div style={{ padding: 10 }}>Waiting Data...</div>
            )}
          </Grid>
        </Grid>
      </WrapperFormat>
    );
  }
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={[]}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={true}
    >
      <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
        <Grid item xs={12} className={`${classes.numberAlign}`}>
          {
            <div className={classes.styleText}>
              {typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`}
            </div>
          }
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default AggregateAdObjectBudget;

const AggregateKeywordBiddingPrice = (props: IPropsFormat<IValueCurrencyFormat>) => {
  const backbone = React.useContext(TableBackboneContext);

  const node = _.get(props, ['node'], []);
  const value = _.get(props, ['value'], []);
  const typeDisplay = 'currency';
  const currency = _.get(props, ['value', 'currency'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const dataType = _.get(props, 'dataType', '');
  const campaignId = _.get(props, ['value', 'campaignId']);
  const classes = useStyles({ isAuto: false });

  const [aggValue, setAggValue] = React.useState(null);
  let isWaitingData, setIsWaitingData;
  if (ff.new_aggregate_for_campaign_cell_format) {
    [isWaitingData, setIsWaitingData] = React.useState(false);
  }
  React.useEffect(() => {
    if (ff.new_aggregate_for_campaign_cell_format) {
      setIsWaitingData(true);
    }
    const config = cloneDeep(AD_OPTIMIZATION_CONFIG);
    set(config, 'mapping.campaign.filterField', 'ADS_CAMPAIGN.id');
    set(config, 'requestIgnoreField', { metric: ['STOREFRONT.currency'] });

    const dataQuery = createDataQueryFromConfig({
      config: config,
    });
    dataQuery
      .query({
        filter: [
          {
            type: 'filter',
            logicalOperator: 'AND',
            field: 'campaign',
            queryType: '=',
            queryValue: campaignId,
            dataType: 'integer',
          },
          ...backbone.getConfig('filter'),
        ],
        dateRange: backbone.getConfig('dateRange'),
        groupBy: {
          columns: ['campaign'],
          aggregations: { 'ADS_PLACEMENT.bidding_price': { func: 'sum' }, cost: { func: 'sum' } },
        },
      })
      .then((res) => {
        setAggValue(res.rowData[0]['ADS_PLACEMENT.bidding_price']);
        if (ff.new_aggregate_for_campaign_cell_format) {
          setIsWaitingData(false);
        }
      })
      .catch((e) => {
        setIsWaitingData(false);
      });
  }, []);

  const getCurrency = (number: number, currency = '', dataType = '') => {
    return formatCurrency(number, currency);
  };
  if (ff.new_aggregate_for_campaign_cell_format) {
    return (
      <WrapperFormat
        typeTable={typeTable}
        cellAction={[]}
        node={node}
        value={value}
        isGrouped={isGrouped}
        gridApi={props.api}
        disabledMenu={true}
      >
        <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
          <Grid item xs={12} className={`${classes.numberAlign}`}>
            {!isWaitingData ? (
              <div className={classes.styleText}>
                {typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`}
              </div>
            ) : (
              <div style={{ padding: 10 }}>Waiting Data...</div>
            )}
          </Grid>
        </Grid>
      </WrapperFormat>
    );
  }
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={[]}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={true}
    >
      <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
        <Grid item xs={12} className={`${classes.numberAlign}`}>
          {
            <div className={classes.styleText}>
              {typeDisplay == 'currency' && `${getCurrency(aggValue, currency, dataType)} ${currency}`}
            </div>
          }
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};

export { AggregateKeywordBiddingPrice };
