import { get } from 'lodash';

export const hasCohortProperties = (backbone) => {
  const mappings = get(backbone, 'config.mapping', null) || backbone.getConfig('mapping');
  return Object.values(mappings).some((mapping) => {
    return get(mapping, 'staticValue.allowCohort', false);
  });
};

export const getStorefrontMetricTraction = (backbone) => {
  const mappings = get(backbone, 'config.mapping', null);
  const columnTractionKey = Object.keys(mappings).find((el) => mappings[el]?.cellFormat === 'storefrontMetricTraction');
  const isMetricVisible = get(backbone, 'config.metric', []).includes(columnTractionKey);

  return isMetricVisible ? columnTractionKey : null;
};

export function stripUniversalPrefix(field) {
  return field?.replace(/\.(a_|m_)/, '.');
}

export function universalPrefixListField(fields: { attributes: string[]; metrics: string[] }) {
  const metrics = [];
  const attributes = [];

  fields.attributes.forEach((f) => {
    if (/\.m_.*$/.test(f)) {
      metrics.push(f);
    } else {
      attributes.push(f);
    }
  });

  fields.metrics.forEach((f) => {
    if (/\.a_.*$/.test(f)) {
      attributes.push(f);
    } else {
      metrics.push(f);
    }
  });

  return {
    attributes,
    metrics,
  };
}
