import moment from 'moment';
import { DATETIME_REGEX } from '../etable/constant';

export const generateFillDates = (dateFrom, dateTo, period) => {
  switch (period) {
    case 'regularly': {
      const diff10Mins = (moment(dateTo).add(86399, 'second').diff(dateFrom, 'h') + 1) * 6;
      const ranges = new Array(diff10Mins).fill(0).map((_, index) => {
        return moment(dateTo)
          .add(1, 'day')
          .subtract(10 * (index + 1), 'm');
      });

      return ranges.map((h) => h.format('YYYY-MM-DD HH:mm:ss'));
    }
    case 'hourly': {
      const diffHours = moment(dateTo).add(86399, 'second').diff(dateFrom, 'h') + 1;
      const ranges = new Array(diffHours).fill(0).map((_, index) => {
        return moment(dateTo).add(82800, 'second').subtract(index, 'h');
      });

      return ranges.map((h) => h.format('YYYY-MM-DD HH:mm:ss'));
    }
    case 'daily': {
      const diffDays = moment(dateTo).diff(dateFrom, 'd') + 1;
      const ranges = new Array(diffDays).fill(0).map((_, index) => {
        return moment(dateTo).subtract(index, 'd');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'weekly': {
      const weekStart = moment(dateFrom).startOf('W');
      const weekEnd = moment(dateTo).startOf('W');

      const diffPeriod = moment(weekEnd).diff(weekStart, 'w') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(weekEnd).subtract(index, 'w');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'monthly': {
      const monthStart = moment(dateFrom).startOf('M');
      const monthEnd = moment(dateTo).startOf('M');

      const diffPeriod = moment(monthEnd).diff(monthStart, 'M') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(monthEnd).subtract(index, 'M');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'quarterly': {
      const quarterStart = moment(dateFrom).startOf('quarter');
      const quarterEnd = moment(dateTo).startOf('quarter');

      const diffPeriod = moment(quarterEnd).diff(quarterStart, 'quarter') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(quarterEnd).subtract(index, 'quarter');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    case 'yearly': {
      const yearStart = moment(dateFrom).startOf('year');
      const yearEnd = moment(dateTo).startOf('year');

      const diffPeriod = moment(yearEnd).diff(yearStart, 'year') + 1;
      const ranges = new Array(diffPeriod).fill(0).map((_, index) => {
        return moment(yearEnd).subtract(index, 'year');
      });

      return ranges.map((d) => d.format('YYYY-MM-DD'));
    }
    default:
      return [];
  }
};

const DEFAULT_PERFORMANCE_METRIC_DATETIME_FIELD = 'created_datetime';
export function getQueryDatetimeValue(
  datetimeFieldRequest = DEFAULT_PERFORMANCE_METRIC_DATETIME_FIELD,
  datetimeStr,
  startOrEnd = 'start',
) {
  let datetime = moment(datetimeStr);

  if (startOrEnd === 'start') {
    datetime = DATETIME_REGEX.test(datetimeStr) ? datetime : datetime.startOf('day');
  } else if (startOrEnd === 'end') {
    datetime = DATETIME_REGEX.test(datetimeStr) ? datetime : datetime.endOf('day');
  }

  if (datetimeFieldRequest === DEFAULT_PERFORMANCE_METRIC_DATETIME_FIELD && datetime.isValid()) {
    return datetime.format('YYYY-MM-DD HH:mm:ss.SSS');
  } else if (datetime.isValid()) {
    return datetime.toISOString();
  } else {
    return datetimeStr;
  }
}

export function isUTCBased(queryField) {
  return queryField === DEFAULT_PERFORMANCE_METRIC_DATETIME_FIELD;
}