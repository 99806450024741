import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { getActionMenu } from '../table-config/common-config';
import { DATA_TYPE, PROPERTY_TYPE, generateColumnKeys } from './common';

export const options = [
  'insightDimension',
  'epsiloDimension',
  'operationDimension',
  'rgbStatusFormat',
  'oneDimension',
];

export const getCellFormat = (name) => {
  switch (name) {
    case 'campaignStatusFormat':
      return {
        component: cellFormat?.status,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_CAMPAIGN.status',
          valueGetter: {
            label: 'ADS_CAMPAIGN.status',
            status: 'ADS_CAMPAIGN.status',
            tooltips: 'ADS_CAMPAIGN.tooltips',
          },
          menu: getActionMenu('campaign_status'),
        },
        label: 'Campaign status',
      };
    case 'campaignTimelineFormat':
      return {
        component: cellFormat?.timeline,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.DATE,
          filterField: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
          valueGetter: {
            date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
            date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
          },
          menu: getActionMenu('campaign_timeline'),
        },
        label: 'Campaign timeline',
      };
    case 'campaignDailyBudgetFormat':
      return {
        component: cellFormat?.dailyBudget,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.FLOAT,
          filterField: 'ADS_CAMPAIGN.daily_budget',
          valueGetter: {
            value: 'ADS_CAMPAIGN.daily_budget',
            currency: 'STOREFRONT.currency',
          },
          menu: getActionMenu('campaign_daily_budget'),
        },
        label: 'Campaign daily budget',
      };
    case 'campaignTotalBudgetFormat':
      return {
        component: cellFormat?.totalBudget,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.FLOAT,
          filterField: 'ADS_CAMPAIGN.total_budget',
          valueGetter: {
            value: 'ADS_CAMPAIGN.total_budget',
            currency: 'STOREFRONT.currency',
          },
          menu: getActionMenu('campaign_total_budget'),
        },
        label: 'Campaign total budget',
      };
    case 'adObjectStatusFormat':
      return {
        component: cellFormat?.status,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_OBJECT.status',
          valueGetter: {
            label: 'ADS_OBJECT.status',
            status: 'ADS_OBJECT.status',
            tooltips: 'ADS_OBJECT.tooltips',
          },
          menu: getActionMenu('ad_object_status'),
        },
        label: 'Ad object status',
      };
    case 'adObjectBudgetFormat':
      return {
        component: cellFormat?.selectionBudget,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.FLOAT,
          filterField: 'ADS_OBJECT.total_budget',
          valueGetter: {
            totalBudget: 'ADS_OBJECT.total_budget',
            dailyBudget: 'ADS_OBJECT.daily_budget',
            currency: 'STOREFRONT.currency',
          },
          menu: getActionMenu('ad_object_budget'),
        },
        label: 'Ad object budget',
      };
    case 'adObjectTimelineFormat':
      return {
        component: cellFormat?.timeline,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.DATE,
          filterField: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
          valueGetter: {
            date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
            date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
          },
          menu: getActionMenu('ad_object_timeline'),
        },
        label: 'Ad object timeline',
      };
    case 'adObjectBiddingPriceFormat':
      return {
        component: cellFormat?.currency,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.FLOAT,
          filterField: 'ADS_OBJECT.base_bidding_price',
          valueGetter: {
            value: 'ADS_OBJECT.base_bidding_price',
            currency: 'STOREFRONT.currency',
          },
          menu: getActionMenu('ad_object_bidding_price'),
        },
        label: 'Ad object bidding price',
      };
    case 'scriptStatusFormat':
      return {
        component: cellFormat?.status,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'SCRIPT.script_status',
          valueGetter: {
            label: 'SCRIPT.script_status',
            status: 'SCRIPT.script_status',
          },
          menu: getActionMenu('script_status'),
        },
        label: 'Script status',
      };
    case 'keywordStatusFormat':
      return {
        component: cellFormat?.status,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_PLACEMENT.status',
          valueGetter: {
            label: 'ADS_PLACEMENT.status',
            status: 'ADS_PLACEMENT.status',
            id: 'ADS_PLACEMENT.id',
            tooltips: 'ADS_PLACEMENT.tooltips',
          },
          menu: getActionMenu('keyword_status'),
        },
        label: 'Keyword status',
      };
    case 'keywordBiddingPriceFormat':
      return {
        component: cellFormat?.currency,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_PLACEMENT.bidding_price',
          valueGetter: {
            value: 'ADS_PLACEMENT.bidding_price',
            currency: 'STOREFRONT.currency',
          },
          menu: [
            {
              title: 'Keyword bidding price',
              name: 'Change bidding price',
              icon: 'pencil',
              editor: 'BiddingPriceEditor',
              payload: {
                field: {
                  value: 'ADS_PLACEMENT.bidding_price',
                },
                rowPicker: {
                  marketplaceCode: 'MARKETPLACE.marketplace_code',
                  toolCode: 'ADTOOL.ads_tool',
                  storefrontId: 'STOREFRONT.id',
                  masterObjectId: 'PRODUCT.id',
                  name: 'ADS_PLACEMENT.name',
                },
                static: {
                  primaryKey: 'ADS_PLACEMENT.id',
                },
                excludeOperator: [
                  {
                    operator: 'suggest-bid-price',
                    field: 'ADTOOL.ads_tool',
                    values: ff.enable_bidding_price ? [] : ['SHP_DA'],
                  },
                ],
              },
              // disabledSubmit: true,
              // disabledSubmitReason: 'not supported yet (code: mop)',
            },
            {
              name: 'Set script',
              icon: 'setscript',
              disable: true,
            },
            {
              name: 'Filter by',
              icon: 'filter',
            },
          ],
        },
        label: 'Keyword bidding price',
      };
    case 'keywordMatchTypeFormat':
      return {
        component: cellFormat?.textMatchType,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: 'ADS_PLACEMENT.match_type',
          valueGetter: {
            value: 'ADS_PLACEMENT.match_type',
            id: 'ADS_PLACEMENT.id',
          },
          menu: getActionMenu('keyword_match_type'),
        },
        label: 'Keyword match type',
      };
    case 'campaignNoteFormat':
      return {
        component: cellFormat?.note,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.NEXTSTRING,
          filterField: 'ADS_CAMPAIGN.note',
          valueGetter: {
            id: 'ADS_CAMPAIGN.note',
            value: 'ADS_CAMPAIGN.note',
          },
          menu: getActionMenu('campaign_note'),
        },
        label: 'Campaign note',
      };
    case 'adObjectNoteFormat':
      return {
        component: cellFormat?.note,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.NEXTSTRING,
          filterField: 'ADS_OBJECT.note',
          valueGetter: {
            id: 'ADS_OBJECT.note',
            value: 'ADS_OBJECT.note',
          },
          menu: getActionMenu('ad_object_note'),
        },
        label: 'Ad object note',
      };
    case 'adNoteFormat':
      return {
        component: cellFormat?.note,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.NEXTSTRING,
          filterField: 'ADS_PLACEMENT.note',
          valueGetter: {
            id: 'ADS_PLACEMENT.note',
            value: 'ADS_PLACEMENT.note',
          },
          menu: getActionMenu('ad_note'),
        },
        label: 'Keyword / Ad placement note',
      };
    case 'insightDimension':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Insight dimension',
      };
    case 'epsiloDimension':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Epsilo dimension',
      };
    case 'operationDimension':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'Operation dimension',
      };
    case 'rgbStatusFormat':
      return {
        component: cellFormat?.rgbStatusFormat,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            value: '',
          },
        },
        label: 'RGB status',
      };
    case 'oneDimension':
      return {
        component: cellFormat?.textFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.ATTRIBUTE,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          valueGetter: {
            id: '',
            label: '',
            value: '',
          },
        },
        label: 'One dimension',
      };
    default:
      return null;
  }
};
