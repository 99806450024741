import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import { Box, makeStyles, Modal } from '@material-ui/core';
import { get, set, uniq } from 'lodash';
import React from 'react';
import ButtonAction from '../button/button-action';
import AccountInformationForm from './account-information-form';
import SelectMarketplaceForm from './select-marketplace-form';
import StoreConnected from './store-connected';
import StorefrontIntegrateForm from './next-storefront-integrate-form';
import VerifyAccountForm from './verify-account-form';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import clsx from 'clsx';
import { Dialog, Button, DialogActions } from '@material-ui/core';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { INPUT_TYPE_ETABLE_CONFIG } from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { STOREFRONT_INTEGRATION_CONFIG } from './table-config';
import produce from 'immer';

const API_LINKSHOP = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/link-store';
const API_GET_CHANNEL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-channel';
const API_GET_COUNTRY = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/get-country';
const API_CALLBACK_STORE = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/check-callback-link-store';
const API_GET_MARKETPLACE_COUNTRY = EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=universal';
const API_INTEGRATE_STOREFRONT = EIP_CONSTANT.API_HOST.API_INTEGRATION + '/integration_new_store';

const useStyles = makeStyles({
  tableWrapper: {
    padding: 8,
    width: '100%',
    border: '1px solid #d0d0d0',
  },
  container: {
    padding: '0 48px 48px',
  },
  treemap: {
    padding: '6px',
    borderRadius: '16px',
    border: '0.5px solid #C2C7CB',
    marginBottom: '24px',
  },
  toggle: {},
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '65%',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
  popupModalLast: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: '#FFFFFF',
    borderRadius: '8px',
    width: '547px',
    padding: '32px 32px 24px 32px',
    textAlign: 'center',
  },
  popupModalMobile: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    height: '100vh',
    background: ff.integrate_storefront_ui_v2 ? '#FFF' : '#F6F9FF',
    padding: ff.integrate_storefront_ui_v2 ? '0' : '20px 16px 16px 16px',
  },
  popupModalLastMobile: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: ff.integrate_storefront_ui_v2 ? '#FFF' : '#F6F9FF',
    width: '100%',
    height: '100vh',
    padding: '20px 16px 16px 16px',
    textAlign: 'center',
  },
  ipadContent: {
    width: '90%',
  },
  dialog: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    '& .eip1-MuiDialog-paperWidthSm': {
      maxWidth: '100%',
    },
  },
  iframe: {
    width: '700px',
    height: '1000px',
  },
  buttonDialog: {
    position: 'absolute',
    top: '5px',
    right: '10px',
  },
});

const DEFAULT_FLOW_VALUES = {
  marketplace: '',
  country: '',
  username: '',
  password: '',
  siteCode: 'SELLER_CENTER',
  otp: '',
  storeLists: [],
  sessionId: '',
  account_type: 'main_account',
  method: '0',
  reg_code: '',
};

const IN_QUEUE = 'IN_QUEUE';
const PROCESSING = 'PROCESSING';
const SUCCESS = 'SUCCESSFUL';
const ERROR = 'ERROR';
const RETRYING = 'RETRYING';
const VERIFYING = 'VERIFYING';

const StorefrontIntegration = (props) => {
  const data = get(props, ['data', 'node', 'data'], {});
  const payloadObj = get(props, ['payload'], []).reduce((carry, { key, value }) => {
    return {
      ...carry,
      [key]: value,
    };
  }, {});
  const marketplaceField = get(payloadObj, ['marketplace'], '');
  const countryField = get(payloadObj, ['country'], '');
  const endpointField = get(payloadObj, ['endpoint'], '') || API_INTEGRATE_STOREFRONT;
  const storefrontIntegrationConfig = produce(
    get(payloadObj, [INPUT_TYPE_ETABLE_CONFIG], '') || STOREFRONT_INTEGRATION_CONFIG,
    (draft) => {
      set(draft, ['tableType'], 'compact');
      set(draft, ['system', 'hiddenComponents'], ['majorButton', 'search', 'settingMenu', 'actionButton', 'timeline']);
      set(draft, ['system', 'ignoreFooterRow'], true);
      set(draft, ['max_item_selected'], 1);
    },
  );

  const classes = useStyles();
  const [flowValues, setFlowValues] = React.useState({
    ...DEFAULT_FLOW_VALUES,
    marketplace: marketplaceField ? data[marketplaceField] || toValue(marketplaceField, data) : '',
    country: countryField ? data[countryField] || toValue(countryField, data) : '',
  });
  const optionList = React.useMemo(() => {
    const countries = [
      {
        label: get(data, ['country.name'], ''),
        value: get(data, ['country.code'], ''),
      },
    ].filter((i) => i['value']);
    return {
      marketplaces: [
        {
          label: get(data, ['marketplace.name'], ''),
          value: get(data, ['marketplace.code'], ''),
          payload: {
            countries: countries.map((i) => i['value']),
          },
        },
      ].filter((i) => i['value']),
      countries: countries,
    };
  }, []);
  // open / close popup
  const [open, setOpen] = React.useState(true);

  const steps = {
    chooseMarketplace: 1,
    fillMarketplaceCredential: 2,
    fillAccountOtp: 3,
    chooseStores: 4,
    end: 5,
  };
  const errors = {
    ERROR_NEED_OTP: 401,
    ERROR_INVALID_OTP: 402,
    ERROR_INCORRECT_ACCOUNT: 403,
    ERROR_NETWORK: 404,
  };

  const [step, setStep] = React.useState(1);
  const [stepHistory, setStepHistory] = React.useState([1]);
  const [loading, setLoading] = React.useState(false);
  const [messageError, setMessageError] = React.useState<string>('');
  const [subtext, setSubtext] = React.useState<string>('');
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;
  const isIPad = containerClass === 'eres--medium';

  const [otpProcess, setOtpProcess] = React.useState(false);
  const [extensionCode, setExtensionCode] = React.useState('');
  const [redirectLink, setRedirectLink] = React.useState('');
  const [openDialog, setOpenDialog] = React.useState(false);
  const [timeCallback, setTimeCallBack] = React.useState(0);
  const [paramCallback, setParamCallback] = React.useState({});
  const [messageErrorCallback, setMessageErrorCallback] = React.useState('');
  const timeout = React.useRef(null);
  const timeCallbackTimeout = 90;

  React.useEffect(() => {
    let result;
    const timeout = setTimeout(() => {
      if (timeCallback > 0 && openDialog) {
        const callbackCheck = async () => {
          result = await request.get(API_CALLBACK_STORE, {
            sid: paramCallback.sids[0],
          });
          if (result.serviceResponse) {
            if (result.serviceResponse.code === 0 || result.serviceResponse.success) {
              moveNextStep(steps.end);
              setOpenDialog(false);
              setTimeCallBack(0);
            }
            if (result.serviceResponse.code === 1000) {
              setMessageErrorCallback(result.serviceResponse.message);
              setOpenDialog(false);
              setTimeCallBack(0);
            }
          }
        };
        setTimeCallBack(timeCallback - 3);
        callbackCheck();
      } else {
        setOpenDialog(false);
      }
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [timeCallback]);

  const fetchStorefrontInformation = async (sessionId) => {
    if (sessionId) {
      return new Promise(async (resolve) => {
        const response = await request.post(endpointField, {
          action: 'get_store_info',
          payload: {
            session_id: sessionId,
          },
        });
        if (response?.success) {
          clearTimeout(timeout.current);
          return resolve(response.data?.stores);
        } else {
          timeout.current = setTimeout(() => fetchStorefrontInformation(sessionId), 5000);
        }
      });
    }
  };

  const fetchAccountInformation = async (sessionId) => {
    setLoading(true);
    if (sessionId) {
      return new Promise(async (resolve) => {
        try {
          const response = await request.post(endpointField, {
            action: 'get_account_check_list',
            payload: {
              session_id: sessionId,
            },
          });
          const currentStepStatusName = get(response, ['data', 'current_step', 'status_name'], '');
          const currentStepState = get(response, ['data', 'current_step', 'state'], '');
          const currentStepMessage = get(response, ['data', 'current_step', 'message'], '');
          if (currentStepStatusName == 'list_store' && currentStepState == SUCCESS) {
            clearTimeout(timeout.current);
            moveNextStep(steps.chooseStores);
            setLoading(false);
            setOtpProcess(false);
            setExtensionCode('');
            return resolve(true);
          } else if (!response.success) {
            clearTimeout(timeout.current);
            setMessageError(response.message || 'Something went wrong!');
            setLoading(false);
            return resolve(true);
          } else if (currentStepState == ERROR) {
            clearTimeout(timeout.current);
            setMessageError(currentStepMessage);
            setLoading(false);
            return resolve(true);
          } else if (currentStepState == VERIFYING && currentStepStatusName == 'otp_input') {
            clearTimeout(timeout.current);
            setOtpProcess(true);
            setLoading(false);
            return resolve(true);
          }
          if (currentStepState == VERIFYING && currentStepStatusName == 'email_verify') {
            setSubtext(currentStepMessage);
          } else {
            setSubtext('');
          }
          timeout.current = setTimeout(() => fetchAccountInformation(sessionId), 5000);
        } catch (e) {
          setLoading(false);
          clearTimeout(timeout.current);
        }
      });
    }
  };

  const handleClose = () => {
    setOpen(false);
    if (props.setAnchorEl) {
      props.setAnchorEl(null);
    }
  };

  const submitStep = async (step, values) => {
    let result = null;
    const reqParam = {
      channel: {
        code: flowValues.marketplace,
        sites: [
          {
            code: 'SELLER_CENTER',
            countries: [{ code: flowValues.country }],
          },
        ],
      },
      userName: flowValues.username,
      password: flowValues.password,
    };
    switch (step) {
      case steps.fillMarketplaceCredential: {
        try {
          if (otpProcess) {
            result = await request.post(endpointField, {
              action: 'input_otp',
              payload: {
                session_id: flowValues.sessionId,
                input_value: values.otp,
              },
            });
          } else {
            setExtensionCode('');

            result = await request.post(endpointField, {
              action: 'send_validate_store',
              payload: {
                username: values.username,
                password: values.password,
                marketplace_code: flowValues.marketplace,
                country_code: flowValues.country,
                site_code: flowValues.siteCode,
                account_type: values.account_type,
                method: Number(values.method),
                session_id: '',
                reg_code: values.reg_code,
              },
            });
          }

          if (result.success) {
            const sessionId = result.data?.session_id || result.data;
            const extension_code = result.data?.extension_code || '';
            if (sessionId) {
              setFlowValues((prevValue) => {
                return {
                  ...prevValue,
                  sessionId,
                };
              });
            }
            setExtensionCode(extension_code);
            if (flowValues.sessionId || sessionId) {
              await fetchAccountInformation(sessionId || flowValues.sessionId);
            }
          } else {
            setMessageError(result.message);
          }
        } catch (e) {
          setLoading(false);
          setMessageError('Something went wrong! Please try again.');
          clearTimeout(timeout.current);
        }

        break;
      }
      case steps.fillAccountOtp: {
        result = await request.post(API_LINKSHOP, {
          ...reqParam,
          otp: values.otp,
        });
        if (!result.serviceResponse.success) throw result;

        setFlowValues(() => ({
          ...flowValues,
          otp: values.otp,
          storeLists: result.listStore,
        }));
        moveNextStep(steps.chooseStores);
        break;
      }
      case steps.chooseStores: {
        result = await request.post(endpointField, {
          action: 'integration_new_store',
          sessionId: flowValues.sessionId,
        });
        if (result?.success) {
          moveNextStep(steps.end);
        }

        break;
      }
      default: {
        setStep(step + 1);
        setStepHistory([...stepHistory, step + 1]);
      }
    }
    return result;
  };

  const moveNextStep = (nextStep) => {
    setStep(nextStep);
    if (!stepHistory.includes(nextStep)) {
      setStepHistory([...stepHistory, nextStep]);
    }
  };

  const handleNextStep = async (step, values) => {
    setMessageError('');
    setSubtext('');
    try {
      setLoading(true);
      await submitStep(step, values);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      if (error.message) {
        setMessageError('Something went wrong! Please try again.');
      }
      const errorCode = error.serviceResponse.code;
      switch (errorCode) {
        case errors.ERROR_NEED_OTP: {
          moveNextStep(steps.fillAccountOtp);
          break;
        }
        case errors.ERROR_INVALID_OTP: {
          // TODO: show error message;
          moveNextStep(steps.fillAccountOtp);
          setMessageError(error.serviceResponse.message);
          break;
        }
        case errors.ERROR_INCORRECT_ACCOUNT: {
          // TODO: show error message
          moveNextStep(steps.fillMarketplaceCredential);
          setMessageError(error.serviceResponse.message);
          break;
        }
        case errors.ERROR_NETWORK:
        default: {
          // TODO: show error message
          setMessageError(error.serviceResponse.message);
          break;
        }
      }
    }
  };

  const handleBackStep = () => {
    if (otpProcess) {
      setMessageError('');
      setSubtext('');
      setOtpProcess(false);
      setFlowValues((prevValue) => {
        return {
          ...prevValue,
          otp: '',
        };
      });
    } else {
      const newStepHistory = stepHistory.slice(0, stepHistory.length - 1);
      setStepHistory(newStepHistory);
      setStep(newStepHistory[newStepHistory.length - 1]);
      setMessageError('');
    }
    setExtensionCode('');
    clearTimeout(timeout.current);
  };

  const handleIntegrateStorefront = async (storefrontId) => {
    setMessageError('');
    setSubtext('It might take a little longer to succeed, please wait a moment.');
    setLoading(true);
    try {
      const response = await request.post(endpointField, {
        action: 'integration_new_store',
        payload: {
          session_id: flowValues.sessionId,
          shop_id: storefrontId,
        },
      });

      if (response.success) {
      } else {
        setMessageError(response.message);
      }
      setLoading(false);
      setSubtext('');
      return response;
    } catch (e) {
      setMessageError(e.message);
      setSubtext('');
      setLoading(false);
      return {
        success: false,
        message: e.message,
      };
    }
  };

  const handleResendOTP = async () => {
    await request.post(API_LINKSHOP, {
      channel: {
        code: flowValues.marketplace,
        sites: [
          {
            code: 'SELLER_CENTER',
            countries: [{ code: flowValues.country }],
          },
        ],
      },
      userName: flowValues.username,
      password: flowValues.password,
    });
  };

  return (
    <div>
      <Modal open={open}>
        <>
          <Box
            className={
              step === 5
                ? clsx({
                    [classes.popupModalLastMobile]: isMobile,
                    [classes.popupModalLast]: !isMobile,
                  })
                : clsx({
                    [classes.popupModalMobile]: isMobile,
                    [classes.popupModal]: !isMobile,
                    [classes.ipadContent]: isIPad,
                  })
            }
          >
            {step === 1 && (
              <SelectMarketplaceForm
                loading={loading}
                initialValues={flowValues}
                optionList={optionList}
                setInitialValues={setFlowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.chooseMarketplace, values);
                }}
                enableSiteCode
              />
            )}
            {step === 2 && (
              <AccountInformationForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.fillMarketplaceCredential, values);
                  setMessageError('');
                }}
                onBackStep={handleBackStep}
                messageError={messageError}
                subtext={subtext}
                otpProcess={otpProcess}
                extensionCode={extensionCode}
              />
            )}
            {step === 3 && (
              <VerifyAccountForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => {
                  setFlowValues(values);
                  handleNextStep(steps.fillAccountOtp, values);
                }}
                onResendOTP={handleResendOTP}
                onBackStep={handleBackStep}
                messageError={messageError}
              />
            )}
            {step === 4 && (
              <StorefrontIntegrateForm
                loading={loading}
                initialValues={flowValues}
                onClose={handleClose}
                onNextStep={(values) => handleNextStep(steps.chooseStores, values)}
                onBackStep={handleBackStep}
                messageError={messageError}
                messageErrorCallback={messageErrorCallback}
                fetchStorefrontInformation={fetchStorefrontInformation}
                storefrontIntegrationConfig={storefrontIntegrationConfig}
                handleIntegrateStorefront={handleIntegrateStorefront}
                subtext={subtext}
              />
            )}
            {step === 5 && <StoreConnected onClose={handleClose} />}
          </Box>
        </>
      </Modal>
    </div>
  );
};

export default StorefrontIntegration;
