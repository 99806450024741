import { floor } from 'lodash';

const { ZoneContextManager } = require('@opentelemetry/context-zone');

const { BatchSpanProcessor } = require('@opentelemetry/sdk-trace-base');
const { WebTracerProvider } = require('@opentelemetry/sdk-trace-web');
const { ZipkinExporter } = require('@opentelemetry/exporter-zipkin');
const { Resource } = require('@opentelemetry/resources');
const { SemanticResourceAttributes } = require('@opentelemetry/semantic-conventions');
const { registerInstrumentations } = require('@opentelemetry/instrumentation');
const { getWebAutoInstrumentations } = require('@opentelemetry/auto-instrumentations-web');
import opentelemetry, { SpanStatusCode, Tracer } from '@opentelemetry/api';
import { aim, retryTillSuccess } from './epsilo-lib';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { useLog } from '@ep/insight-ui/sw/log';

const console = useLog('tracing');
declare global {
  interface Window {
    epGetTracer: () => Tracer;
    freshpaint: any;
  }
}
const TRACING_URL = process.env.TRACING_URL
  ? process.env.TRACING_URL
  : '';
const JAEGER_URL = process.env.JAEGER_URL ? process.env.JAEGER_URL : '';
const TRACING_DEBUG = !!process.env.TRACING_DEBUG;

let isInit = false;
function setAll(span, info) {
  for (const key in info) {
    if (!key.startsWith('_')) {
      span.setAttribute(key, info[key]);
    }
  }
}
function getTime(spanTime) {
  return spanTime[0] * 1000 + spanTime[1] / 1000000;
}
function durationToString(duration: number) {
  let s: string = '';
  let n: number = floor(duration % 1000);
  if (n > 0) s = n + 'ms' + s;
  duration = floor(duration / 1000);
  if (duration == 0) return s;
  n = duration % 60;
  if (n > 0) s = n + 's' + (s === '' ? s : ' ' + s);
  duration = floor(duration / 60);
  if (duration == 0) return s;
  n = duration % 60;
  if (n > 0) s = n + 'm' + (s === '' ? s : ' ' + s);
  duration = floor(duration / 60);
  if (duration == 0) return s;
  n = duration % 24;
  if (n > 0) s = n + 'h' + (s === '' ? s : ' ' + s);
  duration = floor(duration / 24);
  if (duration > 0) s = duration + 'd' + (s === '' ? s : ' ' + s);
  return s;
}
const CalcInterval = class {
  public readonly start: number;
  public readonly end: number;
  constructor(start: number, end: number) {
    this.start = start ? start : 0;
    this.end = end ? end : 0;
  }
  duration() {
    return this.end - this.start;
  }
};
const CalcIntervals = class {
  public intervals = [];
  add(start, end) {
    this.intervals.push(new CalcInterval(start, end));
  }
  sum() {
    let n = 0;
    this.intervals.forEach((interval) => {
      n += interval.duration();
    });
    return n;
  }
  size() {
    return this.intervals.length;
  }
  get(idx) {
    return this.intervals[idx];
  }
  public merge() {
    if (this.size() == 0) return this;
    if (this.size() == 1) return this;
    this.intervals.sort((a, b) => {
      return a.start - b.start;
    });

    let first = this.get(0);
    let start: number = first.start;
    let end: number = first.end;

    let result = new CalcIntervals();
    for (let i = 1; i < this.size(); i++) {
      let current = this.get(i);
      if (current.start <= end) {
        end = Math.max(current.end, end);
      } else {
        result.add(start, end);
        start = current.start;
        end = current.end;
      }
    }
    result.add(start, end);
    return result;
  }
};
function getActionName(span) {
  let name = '';
  if ('documentLoad' == span.name) {
  } else if ('click' == span.name) {
    name = span.attributes['text'];
  }
  if (name.length > 32) name = name.substring(0, 32) + '...';
  if (name.length > 0) name += ' ';
  return name;
}
function getContentFromClassName(className) {
  let listObjs = document.getElementsByClassName(className);
  if (listObjs.length > 0) {
    return listObjs[0].innerText;
  }
  return '';
}
function getStacktrace(): string {
  return new Error().stack;
}

function getValidListSpan(listSpan: Record<string, any>, lookBackMs = 5_000) {
  const spanEntries = Object.entries(listSpan).filter(([k, v]) => {
    return v.name.startsWith('click_section') && Date.now() - 1000 * v.endTime[0] <= lookBackMs;
  });
  return spanEntries;
}

export function init() {
  // if (TRACING_URL == '') return;
  if (isInit) return;
  isInit = true;

  console.log('tracing init...');
  const userProfile = aim.getUserSettings();

  const tracer = opentelemetry.trace.getTracer('eip-frontend');

  window.epGetTracer = () => tracer;

  retryTillSuccess(() => {
    return new Promise((resolve) => {
      window.freshpaint.ready(function () {
        console.log('freshpaint ready');

        if (window.freshpaint._processEvent && !window.freshpaint._processEventOriginal) {
          window.freshpaint._processEventOriginal = window.freshpaint._processEvent;
          window.freshpaint._processEvent = function (a, b, c, d, f, g) {
            window.freshpaint._processEventOriginal.apply(this, arguments);
            b.target.events = window.freshpaint._find_matching_event_defs(a, b, d);
          };
        }

        resolve(true);
      });
    });
  }, 100);

  let spanBuffer = {
    lastRootSpan: null,
    firstStartInFrameSpan: null,
    serverTime: null,
    errorCount: 0,
    httpParents: {},
    preLoadSpans: [],
    clickSpans: [],
    httpEventParentTrail: {},
    applyParent: function (span, parent, args) {
      if (span === parent) {
        args[1] = span.spanContext();
        return;
      }
      span.__parentSpan = parent;
      span._spanContext.traceId = span.__parentSpan._spanContext.traceId;
      span.parentSpanId = span.__parentSpan._spanContext.spanId;
      args[1] = span.spanContext();
    },
  };
  let intervals = new CalcIntervals();
  if (!BatchSpanProcessor.prototype.onEndOriginal) {
    BatchSpanProcessor.prototype.onStartOriginal = BatchSpanProcessor.prototype.onStart;
    BatchSpanProcessor.prototype.onEndOriginal = BatchSpanProcessor.prototype.onEnd;

    BatchSpanProcessor.prototype.onStart = function () {
      let span = arguments[0];
      const type = span.name;

      span.attributes['time.start'] = Date.now();
      if (type == 'documentLoad') {
        fetch('https://time.epsilo.io/_now')
          .then((res) => {
            spanBuffer.serverTime = { server: res.headers.get('x-now'), client: Date.now() };
          })
          .catch((e) => {});

        spanBuffer.lastRootSpan = span;
        span.attributes['http.uri'] = location.pathname;
        span.attributes['httpUrl'] = window.location.href;
        span.attributes['httpUserAgent'] = window.navigator.userAgent;
        span.attributes['user.email'] = userProfile?.profile?.userEmail;
        span.attributes['user.id'] = userProfile?.profile?.userId;

        for (let i = 0; i < spanBuffer.preLoadSpans.length; i++) {
          let childSpanContextPack = spanBuffer.preLoadSpans[i];
          let childSpanContext = childSpanContextPack[0];
          let childSpanArgs = childSpanContextPack[1];
          let childSpan = childSpanContextPack[2];

          childSpan.setAttribute('preload', true);
          spanBuffer.applyParent(childSpan, spanBuffer.lastRootSpan, childSpanArgs);
        }
        spanBuffer.preLoadSpans = [];
      } else if (type.startsWith('click')) {
        spanBuffer.lastRootSpan = span;
        intervals = new CalcIntervals();
        spanBuffer.clickSpans[span._spanContext.traceId + '-' + span._spanContext.spanId] = span;
      } else if (type.startsWith('HTTP ') || type.startsWith('error ')) {
        const spanEntries = getValidListSpan(spanBuffer.clickSpans);
        if (spanBuffer.lastRootSpan != null) {
          spanBuffer.applyParent(span, spanBuffer.lastRootSpan, arguments);
          spanEntries.forEach(([k, v]) => {
            spanBuffer.applyParent(v, spanBuffer.lastRootSpan, arguments);
          });
        } else {
          spanBuffer.preLoadSpans.push([this, arguments, span]);
        }
        if (span.parentSpanId) {
          spanEntries.forEach(([k, v]) => {
            // potential undefined
            spanBuffer.applyParent(v, span.__parentSpan, arguments);
          });
          console.info('%cspanEntries >>>', 'background:yellow', spanEntries);

          spanBuffer.httpParents[span._spanContext.traceId + '-' + span.parentSpanId] = span;
          spanBuffer.httpEventParentTrail[span._spanContext.traceId + '-' + span._spanContext.spanId] = spanEntries;
        }
      }
      BatchSpanProcessor.prototype.onStartOriginal.apply(this, arguments);
    };

    BatchSpanProcessor.prototype.onEnd = function () {
      const self = this;
      console.info('%conEnd', 'background:yellow', arguments);
      let span = arguments[0];
      const type = span.name;

      if (spanBuffer.serverTime != null) {
        span.attributes['time.server'] = JSON.stringify(spanBuffer.serverTime);
      }
      span.attributes['time.end'] = Date.now();
      span.attributes['page.name'] = getContentFromClassName('t_page_name');
      span.attributes['user.email'] = userProfile?.profile?.userEmail;
      span.attributes['user.id'] = userProfile?.profile?.userId;

      let willApply = true;
      if (type === 'click') {
        willApply = false;
        let key = span._spanContext.traceId + '-' + span._spanContext.spanId;
        if (key in spanBuffer.httpParents) {
          willApply = true;
          span.applied = true;
          spanBuffer.clickSpans = [];
          delete spanBuffer.httpParents[key];
        }
      } else if (type.startsWith('click_section')) {
        console.info('%constart >>>', 'background:tomato', span, span.attributes, span.attributes.eventTs);
        const storedKey = span._spanContext.traceId + '-' + span._spanContext.spanId;
        if (
          !spanBuffer.firstStartInFrameSpan ||
          spanBuffer.firstStartInFrameSpan.startTime[0] + getConst('tracing.clickSection.periodSecond', 10) < 
            span.startTime[0]
        ) {
          console.info('%constart >>> set parent span in 10s', 'background:tomato', span);
          spanBuffer.firstStartInFrameSpan = span;
        }

        /**
         * preventing duplicate click_section spans because of event "click" buble up
         */
        Object.keys(spanBuffer.clickSpans).forEach((k) => {
          const storedSpan = spanBuffer.clickSpans[k];
          if (!storedSpan) {
            console.error('storedSpan is undefined', k);
            return;
          }

          if (!storedSpan.name.startsWith('click_section') || storedSpan.attributes.eventTs != span.attributes.eventTs)
            return;

          console.info(
            '%constart >>> compare',
            'background:tomato',
            spanBuffer.clickSpans[k],
            k,
            spanBuffer.clickSpans,
          );
          if (Number(storedSpan.attributes.targetDepth) < Number(span.attributes.targetDepth)) {
            storedSpan.isRedundant = true;
            storedSpan.applied = true;
            delete spanBuffer.clickSpans[k];
          } else if (Number(storedSpan.attributes.targetDepth) > Number(span.attributes.targetDepth)) {
            span.isRedundant = true;
            span.applied = true;
            delete spanBuffer.clickSpans[storedKey];
          } else {
          }
        });
      } else if (type.startsWith('HTTP ')) {
        if (span.__parentSpan) {
          console.info('%cspan >>>', 'background:yellow', span, span.__parentSpan, span.__parentSpan.applied, span.__parentSpan._ended);
          let parentSpan = span.__parentSpan;
          if (!parentSpan.applied) {
            if (parentSpan._ended) {
              parentSpan.applied = true;
              BatchSpanProcessor.prototype.onEndOriginal.apply(this, [parentSpan]);
            } else {
              if (!parentSpan.__initSpan) {
                setTimeout(function () {
                  let initClickSpan = tracer.startSpan('click init', parentSpan._spanContext);
                  initClickSpan.parentSpanId = parentSpan._spanContext.spanId;
                  initClickSpan.attributes = parentSpan.attributes;
                  initClickSpan._clock = parentSpan._clock;
                  initClickSpan.startTime = parentSpan.startTime;
                  initClickSpan.end();
                  parentSpan.__initSpan = initClickSpan;
                  span.parentSpanId = parentSpan.__initSpan._spanContext.spanId;
                }, 0);
              }
            }
          }
          const spanEntries =
            spanBuffer.httpEventParentTrail[span._spanContext.traceId + '-' + span._spanContext.spanId] || [];
          console.info('sending %cspanEntries >>>', 'background:yellow', spanEntries);
          spanEntries.forEach(([k, span]) => {
            if (span.__parentSpan === parentSpan && !span.applied) {
              span.applied = true;
              BatchSpanProcessor.prototype.onEndOriginal.apply(this, [span]);
            }
          });
          if (parentSpan.__initSpan) span.parentSpanId = parentSpan.__initSpan._spanContext.spanId;
        }
      }

      if (willApply && spanBuffer.lastRootSpan != null) {
        if (span._spanContext.traceId == spanBuffer.lastRootSpan._spanContext.traceId) {
          let active = false;
          if (span.name.endsWith('Fetch') || span.name == 'documentLoad') {
            active = true;
          } else if (span.name.startsWith('HTTP ')) {
            active = true;
            let url = span.attributes['http.url'];
            if (url.endsWith('/getPaginationInfo') || url.endsWith('/save_changeset')) {
              active = false;
            }
          }

          if (active) {
            intervals.add(getTime(span.startTime), getTime(span.endTime));
            intervals = intervals.merge();
            let n = intervals.sum();
            let durationHTML = '<p style="color:#8C98A4;">TTL ' + durationToString(n) + '</p>';
            if (aim.canAccess('tracing') && JAEGER_URL != '') {
              durationHTML =
                '<a href="' +
                JAEGER_URL +
                '/trace/' +
                span._spanContext.traceId +
                '" style="text-decoration: none" target="_blank">' +
                durationHTML +
                '</a>';
            }
            showPageObservability(durationHTML);
          }
        }
      }

      console.log('%conEnd >>> apply', 'background:violet', {
        willApply: willApply,
        isRedundant: span.isRedundant,
        type: type,
        id: span._spanContext.spanId,
        parentSpanId: span.parentSpanId,
        traceId: span._spanContext.traceId,
        span: span,
      });

      if (willApply && !span.isRedundant && !span.applied) {
        let isDefer = span.name.startsWith('click_section');
        const deferTime = getConst('tracing.clickSection.deferMs', 5_000);
        isDefer = isDefer && Date.now() - 1000 * span.endTime[0] <= deferTime;
        if (isDefer) {
          const args = [].concat(arguments);
          console.info('%cdefer', 'background:yellow', span, deferTime);
          spanBuffer.applyParent(span, spanBuffer.firstStartInFrameSpan, arguments);
          setTimeout(() => {
            console.info('%cexecute again', 'background:yellow', span, deferTime);
            if (span.__parentSpan && !span.__parentSpan.applied && span.__parentSpan._ended) {
              span.__parentSpan.applied = true;
              BatchSpanProcessor.prototype.onEndOriginal.apply(self, [span.__parentSpan]);
            }
            self.onEnd.apply(self, [span].concat(args.slice(1)));
          }, deferTime);

          if (spanBuffer.firstStartInFrameSpan && span !== spanBuffer.firstStartInFrameSpan) {
            spanBuffer.applyParent(span, spanBuffer.firstStartInFrameSpan, arguments);
            span.__parentSpan = spanBuffer.firstStartInFrameSpan;
          }

          return;
        }

        span.applied = true;
        // persistent to jeager
        BatchSpanProcessor.prototype.onEndOriginal.apply(this, arguments);
      }
    };
  }
  if (!XMLHttpRequest.prototype.sendOriginal) {
    XMLHttpRequest.prototype.sendOriginal = XMLHttpRequest.prototype.send;
    XMLHttpRequest.prototype.send = function (body) {
      if (typeof body === 'string') {
        this.requestBody = body;
      }
      XMLHttpRequest.prototype.sendOriginal.apply(this, arguments);
    };
  }
  let assertOriginal = console.assert;
  console.assert = function (value, message) {
    if (!value) {
      spanBuffer.errorCount++;
      if (spanBuffer.errorCount < 1000) {
        let span = tracer.startSpan('error assert');
        span.setAttribute('error.message', message);
        span.setAttribute('error.stack', getStacktrace());
        span.end();
      }
    }
    assertOriginal.apply(this, arguments);
  };
  window.addEventListener('error', (event) => {
    spanBuffer.errorCount++;
    if (spanBuffer.errorCount < 1000) {
      let span = tracer.startSpan('error runtime');
      span.setAttribute('error.message', event.message);
      span.setAttribute('error.type', event.type);
      span.setAttribute('error.stack', getStacktrace());
      span.end();
    }
  });

  const ignoreUrls = [
    /\/[\w-]*apm\.epsilo\.io\//g,
    /\/[\w-]*sso\.epsilo\.io\//g,
    /\/api-js\.mixpanel\.com\//g,
    /\/api\.perfalytics\.com\//g,
    /\/runtime-error/g,
    /\/cdn-cgi\//g,
  ];
  const propagateTraceHeaderCorsUrls = [
    /\/[\w-]*eip-manager[\w-]*\.epsilo\.io\//g,
    /\/[\w-]*datacenter[\w-]*\.epsilo\.io\//g,
    /\/[\w-]*grpc-gateway[\w-]*\.epsilo\.io\//g,
    /\/[\w-]*hyper-automation[\w-]*\.epsilo\.io\//g,
    /\/[\w-]*passport[\w-]*\.epsilo\.io\//g,
    /\/[\w-]*hyper-integration[\w-]*\.epsilo\.io\//g,
  ];

  const provider = new WebTracerProvider({
    resource: new Resource({
      [SemanticResourceAttributes.SERVICE_NAME]: 'eip-frontend',
    }),
  });

  provider.addSpanProcessor(
    new BatchSpanProcessor(
      new ZipkinExporter({
        serviceName: 'eip-frontend',
        url: TRACING_URL,
        headers: {},
      }),
    ),
  );

  provider.register({
    contextManager: new ZoneContextManager(),
  });

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        '@opentelemetry/instrumentation-document-load': {},
        '@opentelemetry/instrumentation-user-interaction': {
          shouldPreventSpanCreation: (eventType, element, span) => {
            span.target = element;
            span.setAttribute('text', element.innerText);
            span.setAttribute('user.email', userProfile.profile.userEmail);
            if (element.events && element.events.length > 0) {
              for (let i = 0; i < element.events.length; i++) {
                let event = element.events[i];
                if (i === 0) {
                  span.setAttribute('id', event.id);
                  span.setAttribute('name', event.name);
                } else {
                  span.setAttribute('id' + i, event.id);
                  span.setAttribute('name' + i, event.name);
                }
              }
            }
          },
        },
        '@opentelemetry/instrumentation-xml-http-request': {
          ignoreUrls: ignoreUrls,
          propagateTraceHeaderCorsUrls: propagateTraceHeaderCorsUrls,
          clearTimingResources: true,
          applyCustomAttributesOnSpan: (span, xhr) => {
            span.setAttribute('user.email', userProfile.profile.userEmail);
            if (xhr.status > 399 || xhr.status == 0) {
              span.setStatus({
                code: SpanStatusCode.ERROR,
                message: 'Error',
              });
            }
            if (xhr.requestBody) {
              span.setAttribute('http.request.body', xhr.requestBody);
            }
          },
        },
        '@opentelemetry/instrumentation-fetch': {
          ignoreUrls: ignoreUrls,
          propagateTraceHeaderCorsUrls: propagateTraceHeaderCorsUrls,
          clearTimingResources: true,
          applyCustomAttributesOnSpan: (span, request, result) => {
            span.setAttribute('user.email', userProfile.profile.userEmail);
          },
        },
      }),
    ],
  });

  console.log('tracing init complete');
}

function showPageObservability(html) {
  const el = document.querySelector('.page-observability');
  if (el) {
    el.innerHTML = html;
  }
}

function run() {
  try {
    init();
  } catch (err) {
    console.error(err);
  }
}

run();
