import loadable from '@loadable/component';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, Paper } from '@material-ui/core';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import Draggable from 'react-draggable';
import { narrativePopup } from '../atom/editor-narrative';

const LazyEditor = loadable(() => import('./markdown-editor'));

const useStyles = makeStyles({
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
});
export function NarrativeEditor() {
  const { isDisplay, content, submit } = useAtomValue(narrativePopup);
  const updateEditor = useSetAtom(narrativePopup);
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    updateEditor((c) => ({ ...c, isDisplay: false }));
  }, []);

  const editor = React.useRef(null);

  const handleSubmit = React.useCallback(() => {
    const content = editor.current.getValue();
    submit(content);
    updateEditor((c) => ({ ...c, content, isDisplay: false }));
  }, [submit]);

  return (
    <Dialog hideBackdrop={true} open={isDisplay} PaperComponent={PaperComponent} maxWidth={'xl'}>
      <DialogTitle>Narrative Editor</DialogTitle>
      <DialogContent>
        <Box width={'40vw'} height={'80vh'}>
          <ErrorBoundary>
            <LazyEditor ref={editor} defaultContent={content} />
          </ErrorBoundary>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box className={classes.actionGroup}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

function PaperComponent(props) {
  return <Paper {...props} style={{ position: 'absolute', right: 0, bottom: 0 }} />;
}

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
