import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { Resizable } from 'react-resizable';
import { editorScript } from '../atom/editor-script';
import { MonacoEditor } from './monaco-editor';
import { eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { debounce, get } from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';
import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import ExpansionPanel from '@ep/insight-ui/elements/table/expansion-table';
import InlineEdit from '../action-components/inline-edit';
import { INIT_VALUE_TYPE_FROM_ETABLE } from '../utils/constant';
import { getConst } from '@ep/insight-ui/sw/constant/common';

const useStyles = makeStyles({
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  handleLeft: {
    position: 'absolute',
    top: '0',
    left: '-5px',
    width: '10px',
    height: '100%',
    cursor: 'ew-resize',
    zIndex: 1,
    backgroundColor: '#f0f0f0',
  },
  buttonAction: {
    '& .eip1-MuiTypography-body2': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
});

type ScriptPayload = {
  id: number;
  name: string;
  updateInterval: number;
  updatePeriod: number;
  filter: string;
  filterRoot: string;
  filterDetail: string;
  etlScript: string;
  enabled: boolean;
  mode: number;
};

function PaperComponent(props: PaperProps) {
  const classes = useStyles();
  return (
    <Resizable
      width={props.width}
      height={props.height}
      handleClasses={{ left: classes.handleLeft }}
      handle={<span className={classes.handleLeft} />}
      onResize={(event, { size, ...rest }) => {
        props.onResize(size.width, size.height);
      }}
      resizeHandles={['w']}
    >
      <Paper {...props} />
    </Resizable>
  );
}

export function ScriptEditor({ language = 'python' }) {
  const { isDisplay, submit, ...rest } = useAtomValue(editorScript);
  const [size, setSize] = React.useState({
    width: 0.4 * window.visualViewport.width,
    height: window.visualViewport.height,
  });
  const [scriptContent, setScriptContent] = React.useState('');
  const [consoleLog, setConsoleLog] = React.useState('');
  const [displayLog, setDisplayLog] = React.useState(false);
  const [scriptDetail, setScriptDetail] = React.useState<ScriptPayload>({} as ScriptPayload);
  const [status, setStatus] = React.useState('');
  const updateEditor = useSetAtom(editorScript);
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    updateEditor((c) => ({ ...c, isDisplay: false }));
  }, []);

  const idRef = React.useRef(null);
  const editor = React.useRef(null);
  const logEditor = React.useRef(null);
  const properties = React.useRef(null);

  const handleSubmit = React.useCallback(() => {
    const content = editor.current.getValue();
    setStatus('loading');

    const details = properties.current.getValues();

    eipRequest
      .post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb_api.jsp', {
        action: 'save',
        ...details,
        id: Number(details.scriptId || details.id || scriptDetail.id),
        etl: content,
      })
      .then((res) => {
        setStatus('loaded');
      })
      .catch(() => {
        console.error('get script content error');
        setStatus('error_loading');
      });

    updateEditor((c) => ({ ...c, content, isDisplay: true }));
  }, [submit, scriptDetail]);

  const handleTest = React.useCallback(() => {
    const content = editor.current.getValue();
    setStatus('loading');
    eipRequest
      .post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb_api.jsp', {
        action: 'test',
        id: scriptDetail.id,
        name: scriptDetail.name,
        interval: scriptDetail.updateInterval / 60_000,
        updatePeriod: scriptDetail.updatePeriod / 60_000,
        mode: scriptDetail.mode,
        filter: scriptDetail.filter,
        filterRoot: scriptDetail.filterRoot,
        filterDetail: scriptDetail.filterDetail,
        etl: content,
        realAlertTest: false,
        enabled: true,
      })
      .then((res) => {
        setStatus('loaded');
        setConsoleLog(res.output);
        setDisplayLog(true);
      })
      .catch(() => {
        console.error('get script content error');
        setStatus('error_loading');
      });
  }, [scriptDetail]);

  React.useEffect(() => {
    const fields = get(rest, 'payload', []);
    if (fields.length == 0 && !isDisplay) return;

    const scriptIdField = fields.find((field) => field.field_key === 'scriptId');
    let scriptId = scriptIdField.init_value_value;
    if (INIT_VALUE_TYPE_FROM_ETABLE == scriptIdField.init_value_type) {
      const rowData = get(rest, 'inlineFormPayload.rowData', {});
      scriptId = rowData[scriptIdField.init_value_value];
    }

    setStatus('loading');
    eipRequest
      .post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb_api.jsp', {
        action: 'get',
        id: scriptId,
      })
      .then((res) => {
        setStatus('loaded');
        idRef.current = scriptId;
        setScriptDetail(res.data);
        setScriptContent(res.data.etlScript);
      })
      .catch(() => {
        console.error('get script content error');
        setStatus('error_loading');
      });
  }, [rest.payload, isDisplay]);

  const autoSave = React.useCallback(
    debounce((content, id) => {
      if (!id) return;
      window.localStorage.setItem('scriptContent/' + id, content);
    }, getConst(['script', 'autoSavePeriod'], 500)),
    [],
  );

  return (
    <Dialog
      disableEnforceFocus={true}
      hideBackdrop={true}
      open={isDisplay}
      PaperComponent={PaperComponent}
      maxWidth={'xl'}
      disableScrollLock={true}
      PaperProps={{
        style: { position: 'absolute', right: 0, bottom: 0, width: size.width, height: size.height },
        width: size.width,
        height: size.height,
        onResize: (w, h) => {
          setSize({ width: w, height: h });
        },
      }}
    >
      <DialogTitle disableTypography>
        <ExpansionPanel
          label={'Script'}
          headerRight={
            <Box display="flex" width={'100%'} alignItems={'center'}>
              <Box ml={1}>
                <StatusIndicator status={status} />
              </Box>
              <Box ml={1} color={'#7A8577'}>
                {scriptDetail.name}
              </Box>
            </Box>
          }
        >
          <PropertiesEditForm ref={properties} payload={rest.inlineFormPayload} scriptDetails={scriptDetail} />
        </ExpansionPanel>
      </DialogTitle>
      <DialogContent>
        <ErrorBoundary>
          <Box display="flex" flexDirection={'column'} height={'100%'}>
            <Box flexGrow={1} minHeight={'70%'}>
              <MonacoEditor
                ref={editor}
                language={language}
                onCodeChange={(content) => {
                  autoSave(content, idRef.current);
                }}
                value={scriptContent}
                automaticLayout={true}
              />
            </Box>
            {displayLog && (
              <Box height={'30%'} borderTop={'1px solid #626266'}>
                <MonacoEditor
                  ref={logEditor}
                  language={'plaintext'}
                  value={consoleLog}
                  readOnly={true}
                  automaticLayout={true}
                />
              </Box>
            )}
          </Box>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <ButtonGroup variant="contained" color="secondary" size="small">
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              handleTest();
            }}
          >
            Test
          </Button>
          <Dropdown
            direction="top"
            alignMenu="right"
            disabledToggleStyle
            icon={'chevron'}
            sizeIcon={'12px'}
            className={classes.buttonAction}
            buttonProps={{ color: 'secondary', variant: 'contained' }}
          >
            <MenuList
              closeAfterClick
              listMenu={[
                [
                  {
                    title: 'Toggle log',
                    onClick: () => {
                      setDisplayLog(!displayLog);
                    },
                  },
                  {
                    title: 'Format code',
                    onClick: () => {
                      editor.current.formatCode();
                    },
                  },
                  {
                    title: 'Revert cached code',
                    onClick: () => {
                      const cachedContent = window.localStorage.getItem('scriptContent/' + idRef.current);
                      editor.current.setValue(cachedContent);
                    },
                  },
                ],
              ]}
            />
          </Dropdown>
        </ButtonGroup>
        <Box flexGrow={1} />
        <Box className={classes.actionGroup}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleSubmit();
            }}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}

function StatusIndicator({ status }) {
  switch (status) {
    case 'loading':
      return <CircleLoading size={'12px'} />;
    case 'error_loading':
      return <Icon type="error" />;
    default:
      return null;
  }
}

const PropertiesEditForm = React.forwardRef(({ payload, scriptDetails }, ref) => {
  const data = React.useMemo(() => {
    const node = {
      data: {
        ...(payload.rowData || {}),
        ...scriptDetails,
        scriptId: scriptDetails.id,
        interval: scriptDetails.updateInterval / 60_000,
        updatePeriod: scriptDetails.updatePeriod / 60_000,
      },
    };
    return { node };
  }, [payload, scriptDetails]);

  return <InlineEdit ref={ref} {...payload} horizontal={true} data={data} />;
});

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}
