// import { apm, Transaction } from '@elastic/apm-rum';
import { EIP_CONSTANT } from '@eip/next/lib/main';

// document https://www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html
const { APM_SERVER } = EIP_CONSTANT.API_HOST.APM_SERVER;

let isInit = false;
export function init() {
  // if (!isInit) {
  //   apm.init({
  //     serviceName: 'eip-frontend',
  //     serverUrl: APM_SERVER,
  //   });
  //   isInit = true;
  // }
}

export function setUserContext({ id, username, email }) {
  // apm.setUserContext({
  //   id,
  //   username,
  //   email,
  // });
}

export function getTransaction(type, name = 'default') {
  // return apm.startTransaction(name, type);
}

export function getCurrentTransaction() {
  // return apm.getCurrentTransaction();
}

/**
 *
 * @param labels pair key:value; *searchable* in elastic apm
 */
export function addLabels(labels: { [k: string]: any }) {
  // apm.addLabels(labels);
}

export function error(error: Error | string) {
  // apm.captureError(error);
}

/**
 *
 * @param labels pair key:value; *none* searchable in elastic apm
 */
export function addCustomData(labels: { [k: string]: any }) {
  // apm.setCustomContext(labels);
}
