import * as React from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import ButtonFilter from '@ep/insight-ui/elements/table/table-actions/button-filter';
import ButtonOption from '@ep/insight-ui/elements/table/table-actions/button-option';
import ButtonSort from '@ep/insight-ui/elements/table/table-actions/button-sort';
import { useAlignPopover } from '@ep/insight-ui/elements/table/table-actions/hooks';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    marginRight: '6px',
  },
  selectBox: {
    display: 'inline-flex',
    width: 'fit-content',
    '& .eip1-MuiSelect-select': {
      minWidth: '30px',
      borderRadius: '4px',
    },
  },
  selectFormControl: {},
  groupButtons: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

const TableAction = () => {
  const classes = useStyles();
  const [container, setContainer] = React.useState<HTMLDivElement>(null);
  const { marginLeft } = useAlignPopover(container);
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const hidden = backboneContext.getConfig('system.hiddenComponents', []);

  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!boxRef.current) return;
    setContainer(boxRef.current);
  }, [boxRef.current]);

  return (
    <div className={classes.container} ref={boxRef}>
      <Grid className={classes.groupButtons}>
        {!hidden.includes('filter') && (
          <Box mr={2}>
            <ButtonFilter marginLeft={marginLeft} />
          </Box>
        )}
        {!hidden.includes('sort') && (
          <Box mr={2}>
            <ButtonSort marginLeft={marginLeft} />
          </Box>
        )}
        <ButtonOption />
      </Grid>
    </div>
  );
};

export default TableAction;
