/* eslint-disable react/jsx-key */
import '@ag-grid-community/core/dist/styles/ag-grid.css';
import '@ag-grid-community/core/dist/styles/ag-theme-alpine.css';
import type { ColumnMovedEvent, ModelUpdatedEvent } from '@ag-grid-community/core';
import {
  ModuleRegistry,
  RowSelectedEvent,
  ServerSideStoreType,
  ValueGetterParams,
  ViewportChangedEvent,
} from '@ag-grid-community/core';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';
import { LicenseManager } from '@ag-grid-enterprise/core';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import moment from 'moment';
import { ContainerResponsiveContext, useLog } from '@eip/next/lib/main';
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
import { IconType } from '@ep/insight-ui/icons/Icon';
/**
 * ff.ecompacttable_layout_mobile:end
 */

import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { Box, makeStyles } from '@material-ui/core';
import { cloneDeep, debounce, get, merge } from 'lodash';
import * as React from 'react';
import { useEffect } from 'react';
import HeaderGroup from './format/header-group';
import LoadingCellFormat from './format/loading-cell-format';
import EmptyRows from './format/no-rows-data-format';
import SelectionFormat from './format/selection-format';
import { groupableCell } from './function-helper';
import HeaderCell from './header-cell';
import { useEnhanceColDefs } from './hooks/use-enhance-col-defs';
import Pagination, { ROW_HEIGHT as PAGINATION_ROW_HEIGHT } from './pagination';
import './styles.css';
import TableCompactError from './table-compact-error';
import { InlineLoadmore } from './table-group-load-more';
import { groupContracted, groupExpandedIcon } from './table-helper';
import { HeaderTooltip } from './header-tooltip';

import { usePinColumn } from './hooks/pin-column';

/**
 * ff.calculate_on_footer:start
 */
import CalculateFormat, { PINNED_ROW_HEIGHT } from './format/calculate-format';
import { useAtom, useSetAtom } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import FooterButtons from './footer-buttons';
/**
 * ff.calculate_on_footer:end
 */

LicenseManager.setLicenseKey(process.env.AGGRID_LICENSE || process.env.STORYBOOK_AGGRID_LICENSE);
ModuleRegistry.registerModules([ServerSideRowModelModule, RowGroupingModule]);

const log = useLog('insight-ui:table');

let useStyles;
if (ff.ecompacttable_layout_mobile) {
  useStyles = makeStyles({
    mobileCompactHeader: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      columnGap: '5px',
      fontSize: '12px',
      color: '#8C98A4',
      marginTop: '16px',
      padding: '5px',
      justifyContent: 'space-between',
    },
  });
}

export type OptionSelectType = {
  width?: number;
  name: string;
  field: string;
  sortable?: boolean;
  filterable?: boolean;
  cell: {
    format: any;
    action?: {
      name: string;
      icon?: IconType;
      component?: React.ReactNode;
      onSubmit: (info) => void;
    };
  };
  align?: string;
  action?: {
    name: string;
    icon?: IconType;
    component?: React.ReactNode;
    onSubmit: (info) => void;
    type?: string;
  };
};

export type SortType = {
  field: string;
  sort: 'asc' | 'desc';
};

export interface IColumnWidth {
  columnField: string;
  width: number;
}

export interface IPagination {
  page: number;
  limit: number;
}

interface IPinnedColumn {
  field: string;
  isLeftPin: boolean;
  isRightPin: boolean;
  left?: number;
}

export const PAGE_OPTIONS = [
  {
    label: '10 items',
    value: '10',
    checked: true,
  },
  {
    label: '20 items',
    value: '20',
    checked: false,
  },
  {
    label: '50 items',
    value: '50',
    checked: false,
  },
  {
    label: '100 items',
    value: '100',
    checked: false,
  },
];

interface IPropTable {
  backbone: any;
  openSortModal?: (params: { field: string; menuType: { name: string; type: 'desc' | 'asc' | '' } }) => void;
  filterText?: string;
  columns: OptionSelectType[];
  columnsActions?: [{ name: 'Add filter'; onClick: () => void }];
  columnOrder?: string[];
  columnWidth?: Array<IColumnWidth>;
  hiddenColumns?: string[];
  pinnedColumns?: IPinnedColumn[];
  rowsGroup?: string[];
  filter?: [{ field: string; operator: string; value: string }];
  sort?: SortType[];
  allowSelect?: boolean;
  selectedRows?: any[];
  pageOptions?: Array<OptionType>;
  pagination: { page: number; limit: number; total: number };
  onChangePagination: ({ page, limit }: IPagination) => void;
  onColumnResize?: (columnWidth: Array<IColumnWidth>) => void;
  onRowSelect?: () => void;
  onColumnReorder?: (columnOrder: string[]) => void;
  typeTable?: string;
  aggregations?: { [key: string]: any };
}

function batchCall(batchableFn, ms) {
  const queue = [];
  let tid;
  const batchedFn = (arg) => {
    window.clearTimeout(tid);
    if (arg !== undefined) {
      queue.push(arg);
    }
    tid = window.setTimeout(() => {
      batchableFn(queue.length > 0 ? queue : undefined);
      queue.length = 0;
    }, ms);
  };

  return batchedFn;
}

const Table = ({
  filterText,
  openSortModal = () => undefined,
  columns,
  pagination,
  hiddenColumns = [],
  pinnedColumns = [],
  rowsGroup = [],
  onChangePagination,
  onColumnResize,
  columnWidth = [],
  sort = [],
  allowSelect = false,
  selectedRows = [],
  onRowSelect,
  onColumnReorder,
  backbone,
  typeTable = '',
  aggregations = {},
}: IPropTable) => {
  const headerHeight = 32;
  const rowHeight = React.useMemo(() => (typeTable === 'compact' ? 40 : 56), [typeTable]);
  const [gridApi, setGridApi] = React.useState(null);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [checkboxState, setCheckboxState] = React.useState<'default' | 'checked' | 'indeterminate'>('default');
  const setLastUpdatedAt = useSetAtom(eTableAtom.lastUpdatedAt);
  const [leftNotEmpty, setLeftNotEmpty] = React.useState(false);

  const tableDOM = React.useRef(null);
  const {
    setColumnApi: setColumnApiNext,
    handleBodyScroll: handleBodyScrollNext,
    hiddenPinnedColumns: hiddenPinnedColumnsNext,
    getPinColumnActions: getPinColumnActionsNext,
  } = usePinColumn(pinnedColumns, backbone, rowsGroup);

  const multiple = backbone.getConfig('rowSelection', 'multiple');
  const [totalRowInTable, settotalRowInTable] = React.useState(0);
  React.useEffect(() => {
    columns.map((item) => {
      if (item.action) {
        item.action.onSubmit = openSortModal;
      }
    });
  }, [columns]);

  const pageOptions = React.useMemo(() => {
    return PAGE_OPTIONS.map((i) => {
      const limit = (pagination.limit * Number(i.value)) / 10;
      return {
        label: `${limit} items`,
        value: String(limit),
        checked: i.checked,
      };
    });
  }, [pagination.limit]);

  const [rowSelected, setRowSelected] = React.useState([]);
  const [maxSelectedError, setMaxSelectedError] = React.useState(false);
  const isPromotedObjectTable = backbone.getConfig('tableId') === 'promoted_object';
  const countRowSelected = backbone.getSelectedRows();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setMaxSelectedError(false);
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, [maxSelectedError]);

  const getCheckboxState = () => {
    let allSelected = true;
    let allNotSelected = true;
    gridApi.forEachNode((node) => {
      if (!node.isSelected() && backbone.addon('checkValidRow', () => true)(node)) {
        allSelected = false;
      }
      if (node.isSelected()) {
        allNotSelected = false;
      }
    });

    return allSelected ? 'checked' : allNotSelected ? 'default' : 'indeterminate';
  };

  const createValueGetter = (column: any) => {
    const valueMapper = column.cell?.valueGetter;
    const staticValue = column.cell?.staticValue;
    return (params: ValueGetterParams) => {
      if (valueMapper) {
        const getter: Record<string, any> = {};
        for (const [key, value] of Object.entries(valueMapper)) {
          if (String(value).indexOf('=') == 0) {
            getter[key] = value;
          } else {
            getter[key] = params.data[value as string];
          }
        }
        if (getter.label === undefined) {
          getter.label = getter.value;
        }
        return merge(getter, staticValue);
      }
      return null;
    };
  };
  const getCellActions = (actions: any[], column: any) => {
    return actions.map((act) => {
      if (act.icon === 'filter') {
        act.disable = false;
        act.onSubmit = (nodeValue) => {
          const filterField = get(column, 'filterField', '');
          const value = get(nodeValue, ['row', filterField], '');
          const data = { field: column.field, value };
          backbone.updateTempStorage('filterModal', data);
          backbone.changeVisibility('filter', true);
        };
      }
      return act;
    });
  };
  let columnDefs = React.useMemo(() => {
    const colWidth = columnWidth.reduce(
      (acc, col) => ({
        ...acc,
        [col.columnField]: col.width,
      }),
      {},
    );

    const newColumns = columns.map((column: any, index: number) => {
      const isGrouped = rowsGroup.includes(column.field);
      const pinnedColumn = pinnedColumns.find(({ field }) => field === column.field);
      const pinColumnActions = getPinColumnActionsNext(column, index);
      const menuAction = column.action.concat(pinColumnActions);
      const cellActions = getCellActions(get(column, 'cell.action', []), column);

      const updateHandler = get(column, ['cell', 'updateHandler']);

      let actionSubItem;
      if (ff.ui_ux_update_tag_item) {
        actionSubItem = getCellActions(get(column, 'cell.actionSubItem', []), column);
      }

      const col: AgGridColumnProps = {
        headerName: column.name,
        field: column.field,
        colId: column.field,
        resizable: true,
        width: column.field === 'dataSyncStatus' ? 80 : colWidth[column.field] || 100,
        headerClass: [
          `align-${column.align || 'left'}`,
          `${typeTable === 'treemapTable' && column.field === 'keyword' ? 'keyword-header' : ''}`,
          `eip_col_${column.field}`,
        ],
        cellClass: `eip_col_${column.field}`,
        hide: isGrouped || (hiddenColumns || []).includes(column.field),
        pinned: column.pinned
          ? column.pinned
          : pinnedColumns.length === 0
          ? index === 0
          : pinnedColumn && pinnedColumn.isLeftPin
          ? 'left'
          : pinnedColumn && pinnedColumn.isRightPin
          ? 'right'
          : pinnedColumns.some(
              (pinnedColumn) =>
                pinnedColumn.field === column.field && hiddenPinnedColumnsNext.every((el) => el !== pinnedColumn.field),
            ) ?? false,
        rowGroup: isGrouped,
        headerComponentParams: {
          menuAction,
          field: column.field,
          sort,
          dataType: column.dataType,
        },
        cellStyle: {
          padding: '0',
        },
        cellRendererParams: {
          cellAction: cellActions,
          updateHandler: updateHandler,
          ...(ff.ui_ux_update_tag_item
            ? {
                actionSubItem: actionSubItem,
              }
            : {}),
          field: column.field,
          columnName: column.name,
          isGrouped: isGrouped,
          typeTable,
          dataType: column.dataType,
          column,
          aggregations,
          setColumnAggregate: (colId, calculatedSelectedValue) => {
            backbone.addon('group.setColumnAggregate')(colId, calculatedSelectedValue, backbone);
          },
        },
        cellRenderer: groupableCell(column.cell?.format),
        valueGetter: createValueGetter(column),
        minWidth: column.field === 'dataSyncStatus' ? 80 : 104,
      };

      col.aggFunc = column.aggFunc ?? 'unique';
      if (isGrouped) {
        col.keyCreator = (params) => {
          const countryObject = params.value;
          return countryObject.value;
        };
      } else {
        col.cellRenderer = groupableCell(column.cell?.format);
        if (ff.calculate_on_footer) {
          col.cellRendererSelector = (params) => {
            if (params.node.isRowPinned()) {
              return {
                frameworkComponent: CalculateFormat,
                params: {
                  tableDOM,
                },
              };
            }

            return undefined;
          };
        }
      }

      return col;
    });

    if (rowsGroup.length) {
      rowsGroup.forEach((group) => {
        const index = columns.findIndex((column) => column.field == group);
        if (index === -1) return;
        const pinnedColumn = pinnedColumns.find(({ field }) => field === columns[index].field);
        const menuAction = get(columns, [index, 'action'], [])
          .filter(({ icon }) => icon !== 'pinColumn')
          .map((act) => {
            if (act.icon === 'unGroup') {
              return {
                ...act,
                onSubmit: (data) => {
                  let newPinnedResult: IPinnedColumn[];
                  if (pinnedColumn) {
                    newPinnedResult = pinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
                  } else {
                    newPinnedResult = [...pinnedColumns];
                  }
                  backbone.changeConfig('pinnedColumn', newPinnedResult);
                  backbone.changeConfig('groupBy', { columns: [] });
                },
              };
            }
            return act;
          });
        const column = columns[index];
        const cellActions = getCellActions(get(column, 'cell.action', []), column);
        const updateHandlerGroup = get(column, ['cell', 'updateHandler']);
        let actionSubItem;
        if (ff.ui_ux_update_tag_item) {
          actionSubItem = getCellActions(get(column, 'cell.actionSubItem', []), column);
        }
        const groupColumn: AgGridColumnProps = {
          colId: columns[index].field + '_grouped',
          field: columns[index].field,
          headerName: get(columns, [index, 'name'], ''),
          showRowGroup: group,
          cellRenderer: 'agGroupCellRenderer',
          headerComponentParams: {
            menuAction,
            field: get(columns, [index, 'field'], group),
            sort,
          },
          pinned: 'left',
          resizable: true,
          headerComponent: HeaderGroup,
          valueGetter: createValueGetter(get(columns, [index, 'cell', 'valueGetter'])),
          cellRendererParams: {
            suppressCount: true,
            // innerRendererFramework: SimpleGroupFormat,
            innerRendererFramework: get(columns, [index, 'cell', 'format']),
            columnName: get(columns, [index, 'name']),
            isGrouped: true,
            typeTable,
            cellAction: cellActions,
            updateHandler: updateHandlerGroup,
            ...(ff.ui_ux_update_tag_item
              ? {
                  actionSubItem: actionSubItem,
                }
              : {}),
          },
          width: colWidth[column.field + '_grouped'] || 100,
          enableRowGroup: true,
          enablePivot: true,
        };
        newColumns.unshift(groupColumn);
      });
    }

    if (allowSelect) {
      const checkboxSelect: AgGridColumnProps = {
        headerName: '',
        field: 'checkbox',
        colId: 'checkbox',
        resizable: false,
        pinned: 'left',
        headerTooltip: `Rows selected`,
        tooltipComponent: HeaderTooltip,
        cellStyle: {
          borderRightColor: '#e5e5e5',
        },
        width: 40,
        headerCheckboxSelection: false,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        cellClass: 'cell-checkbox',
      };
      if (multiple == 'multiple') {
        checkboxSelect.headerComponent = SelectionFormat;
        checkboxSelect.headerComponentParams = {
          gridApi,
          checkboxState,
          setCheckboxState,
        };
      }
      newColumns.unshift(checkboxSelect);
    }

    return newColumns;
  }, [
    columns,
    pinnedColumns,
    rowsGroup.length,
    checkboxState,
    aggregations,
    hiddenPinnedColumnsNext,
    countRowSelected,
  ]); // FIXME: value comparison is not efficiency

  columnDefs = useEnhanceColDefs(columnDefs, columns, tableDOM);

  log('newColumns', columnDefs);

  React.useEffect(() => {
    if (selectedRows.length > 0 && checkboxState !== 'indeterminate') {
      setCheckboxState('indeterminate');
    }
  }, [selectedRows]);

  React.useLayoutEffect(() => {
    if (!tableDOM.current) return;
    window.requestAnimationFrame(() => {
      if (!tableDOM.current) return;
      const horizontalScroll = tableDOM.current.querySelector('.ag-body-horizontal-scroll-viewport') as HTMLDivElement;
      if (pagination.total == 0) return horizontalScroll.classList.add('ag-hidden');
      horizontalScroll.classList.remove('ag-hidden');
    });
  }, [pagination.total, tableDOM.current]);
  useEffect(() => {
    log('table first render');
  }, []);

  const updatePaginationHeight = React.useState()[1].bind(null, {});

  const checkingStateSelectAll = (e: ModelUpdatedEvent) => {
    if (checkboxState == 'checked') {
      e.api.forEachNode((node) => {
        if (backbone.addon('checkValidRow', () => true)(node)) {
          node.setSelected(true);
        }
      });
    } else if (checkboxState == 'default') {
      e.api.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };

  const checkingNoRowData = (e: ViewportChangedEvent) => {
    const count = e.api.getDisplayedRowCount();
    if (count == 0) {
      if (isEmpty !== true) setIsEmpty(true);
      return;
    }
    if (isEmpty !== false) setIsEmpty(false);
  };

  const checkingStateGroupChecked = (e: ViewportChangedEvent) => {
    e.api.forEachNode((node) => {
      if (node.parent.rowIndex !== null && node.parent.isSelected() && !node.id.includes('cloneforloadmore')) {
        node.setSelected(true);
      }
    });
  };

  const bbSelectedRows = React.useCallback(
    batchCall((selectedRows: { node: any; isSelected: boolean; isPresent: boolean }[]) => {
      const onRowSelectNext = backbone.getCallback('onRowSelectNext');
      if (onRowSelectNext !== backbone.EMPTY_FUNCTION) {
        onRowSelectNext(selectedRows, backbone);

        const rows = backbone.getSelectedRows(
          selectedRows && selectedRows.map((i) => ({ data: i.node.data, isSelected: i.isSelected })),
        );
        const filteredSelectedRows = (rows || []).filter((row) => Object.keys(row).length > 1);
        setRowSelected(filteredSelectedRows);

        if (isPromotedObjectTable && filteredSelectedRows.length > 10) {
          selectedRows.forEach((r) => {
            r.node.setSelected(false);
          });
          setMaxSelectedError(true);
        }

        return;
      }

      const onRowSelect = backbone.getCallback('onRowSelect');
      const rows = backbone.getSelectedRows(
        selectedRows && selectedRows.map((i) => ({ data: i.node.data, isSelected: i.isSelected })),
      );

      onRowSelect(rows, backbone);
      // remove additional selected rows
      const filteredSelectedRows = (rows || []).filter((row) => Object.keys(row).length > 1);
      setRowSelected(filteredSelectedRows);

      if (isPromotedObjectTable && filteredSelectedRows.length > 10) {
        selectedRows.forEach((r) => {
          r.node.setSelected(false);
        });
        setMaxSelectedError(true);
      }
      backbone.addon(
        'rowSelect.changeLog.add',
        (i) => i,
      )((selectedRows || []).map((i) => ({ data: i.node.data, isSelected: i.isSelected })));
    }, 100),
    [],
  );

  const handleRowSelect = (rowEvent: RowSelectedEvent) => {
    const state = getCheckboxState();
    if (state !== checkboxState) setCheckboxState(state);
    const onRowSelect = backbone.getCallback('onRowSelect');
    const onRowSelectNext = backbone.getCallback('onRowSelectNext');
    if (onRowSelect || onRowSelectNext) {
      let isPresent = false;
      rowEvent.api.forEachNode((node) => {
        if (!isPresent) {
          isPresent = node.id == rowEvent.node.id;
        }
      });
      bbSelectedRows({ node: rowEvent.node, isSelected: rowEvent.node.isSelected(), isPresent });
    }

    if (rowEvent.node.group) {
      rowEvent.api.forEachNode((node) => {
        if (node.parent.rowIndex === rowEvent.node.rowIndex && !node.id.includes('cloneforloadmore')) {
          node.setSelected(node.parent.isSelected());
        }
      });
    }
  };

  const updateCountTotalRowsInTable = React.useCallback(() => {
    if (!gridApi) return;
    let total = 0;
    gridApi
      .getModel()
      .getRootStore()
      .getBlocksInOrder()
      .forEach((block) => {
        total += block.rowNodes.length;
      });
    settotalRowInTable(total);
  }, [gridApi]);

  return (
    <>
      {isPromotedObjectTable && <TableCompactError rowSelected={rowSelected} maxSelectedError={maxSelectedError} />}
      <Box
        className={`ag-theme-alpine body-container ${leftNotEmpty ? 'left-not-zero' : ''}`}
        style={{ height: '100%', flex: 1, flexShrink: 0, marginTop: typeTable === 'treemapTable' ? '8px' : '24px' }}
        ref={tableDOM}
      >
        <AgGridReact
          rowSelection={multiple}
          tooltipShowDelay={500}
          gridOptions={{
            rowClassRules: {
              'ag-row-disabled': function (params) {
                return get(params, 'node.data.isDisabledRecord', false);
              },
            },
            groupHideOpenParents: false,
            groupSuppressBlankHeader: true,
            groupDisplayType: 'custom',
            // groupSuppressAutoColumn: true,
            frameworkComponents: {
              LoadingCellRenderer: LoadingCellFormat,
              agColumnHeader: HeaderCell,
            },
            icons: {
              // use some strings from group
              groupExpanded: groupExpandedIcon,
              groupContracted: groupContracted,
            },
          }}
          headerHeight={headerHeight}
          rowHeight={rowHeight}
          getRowHeight={(params) => {
            if (params.node.isFullWidthCell()) {
              return PAGINATION_ROW_HEIGHT;
            }
            if (ff.calculate_on_footer) {
              if (params.node.isRowPinned()) {
                return PINNED_ROW_HEIGHT;
              }
            }
            return rowHeight;
          }}
          pagination={true}
          suppressPaginationPanel={true}
          suppressScrollOnNewData={true}
          suppressRowClickSelection={true}
          suppressAggFuncInHeader={true}
          groupSuppressBlankHeader={true}
          showOpenedGroup={true}
          columnDefs={columnDefs}
          onColumnMoved={debounce((columnMove: ColumnMovedEvent) => {
            // FIXME: optimize only when drag stopped to enabled update table backbone
            if (columnMove.source === 'uiColumnDragged') {
              const columnsShow = columnMove.columnApi.getAllDisplayedColumns();
              const arr = [];
              columnsShow.forEach((item: any) => {
                if (item.colId !== 'checkbox') {
                  arr.push(item.colId);
                }
              });
              onColumnReorder(arr);
            }
          }, 1000)}
          onColumnResized={(params) => {
            if (params.finished) {
              if (params.column) {
                const colId = params.column.getColId();
                if (ff.adoption_rate_table && params.type === 'columnResized' && colId.startsWith('traction_')) {
                  const columnWidths = params.columnApi
                    .getAllColumns()
                    .filter((column) => column.getColId().startsWith('traction_'))
                    .map((column) => ({
                      key: column,
                      newWidth: params.column.getActualWidth(),
                    }));
                  params.columnApi.setColumnWidths(columnWidths);
                  const newColWidths = columnWidths.map((col) => {
                    return {
                      columnField: col.key.getColId(),
                      width: col.newWidth,
                    };
                  });
                  onColumnResize(newColWidths);
                } else {
                  const newColWidth = columnWidth.filter((i) => i.columnField !== colId);
                  newColWidth.push({ columnField: colId, width: params.column.getActualWidth() });
                  onColumnResize(newColWidth);
                }
              } else if (params.columns) {
                const newColWidths = columnWidth.filter((i) =>
                  params.columns.every((el) => el.getColId() !== i.columnField),
                );
                params.columns.forEach((el) => {
                  newColWidths.push({ columnField: el.getColId(), width: el.getActualWidth() });
                });
                onColumnResize(newColWidths);
              }
            }
          }}
          cacheBlockSize={pagination.limit}
          paginationPageSize={pagination.limit}
          loadingCellRenderer={'LoadingCellRenderer'}
          loadingCellRendererParams={{
            loadingMessage: 'One moment please ...',
            tableDOM,
          }}
          rowModelType={'serverSide'}
          serverSideStoreType={'partial' as ServerSideStoreType}
          serverSideDatasource={backbone.getDatasource()}
          onGridReady={(params) => {
            backbone.setGridApi({ grid: params.api, column: params.columnApi });
            setColumnApiNext(params.columnApi);
            setGridApi(params.api);
          }}
          onDisplayedColumnsChanged={(params) => {
            // Set min-width for scrollbar;
            if (tableDOM && tableDOM.current.querySelector('.ag-body-horizontal-scroll-container')) {
              const tableWidth = params.columnApi.getAllGridColumns().reduce((a, b) => {
                return b.isVisible() ? a + b.getActualWidth() : a;
              }, 0);
              tableDOM.current.querySelector('.ag-body-horizontal-scroll-container').style.minWidth = `${
                tableWidth + 8
              }px`;
            }
          }}
          onViewportChanged={(e) => {
            checkingNoRowData(e);
          }}
          getRowNodeId={backbone.getRowId}
          onRowSelected={handleRowSelect}
          onBodyScroll={(e) => {
            // handle scrollbar when scrolling
            // FIXME: remove uneccessary creating handler
            // Only show right scroll bar when scroll direction is vertical
            if (e.direction === 'vertical') {
              if (tableDOM && tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal')) {
                const el = tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal');
                el.classList.add('scroll');
                setTimeout(function () {
                  el.classList.remove('scroll');
                }, 1000);
              }
            } else {
              setLeftNotEmpty(e.left != 0);
            }
            handleBodyScrollNext(e);
          }}
          blockLoadDebounceMillis={60}
          onModelUpdated={(e) => {
            checkingStateSelectAll(e);
            checkingStateGroupChecked(e);
            setLastUpdatedAt({ value: moment() });
            updatePaginationHeight();
            updateCountTotalRowsInTable();
          }}
          getServerSideStoreParams={getServerSideStoreParams}
          isFullWidthCell={(rowNode) => {
            return rowNode.data && rowNode.data._isLoadMore == true;
          }}
          fullWidthCellRendererFramework={InlineLoadmore}
          {...(ff.calculate_on_footer ? { pinnedBottomRowData: [{}] } : {})}
        />
      </Box>
      <Box className="footer-container">
        <Pagination
          tableDOM={tableDOM}
          pageOptions={pageOptions}
          pagination={pagination}
          onChangePagination={onChangePagination}
          totalRowInTable={totalRowInTable}
          isPromotedObjectTable={isPromotedObjectTable}
          rowSelected={rowSelected}
        />
        <FooterButtons />
      </Box>
      {isEmpty && tableDOM.current && (
        <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={rowHeight} />
      )}
    </>
  );
};
export default Table;

function getServerSideStoreParams(params) {
  const noGroupingActive = params.rowGroupColumns.length == 0;
  let res;

  if (noGroupingActive) {
    res = {
      storeType: 'partial',
      cacheBlockSize: params.api.paginationGetPageSize(),
    };
  } else {
    const topLevelRows = params.level === 0;
    res = {
      storeType: topLevelRows ? 'partial' : 'full',
      cacheBlockSize: params.api.paginationGetPageSize(),
      maxBlocksInCache: -1,
    };
  }
  console.log('getServerSideStoreParams, level = ' + params.level + ', result = ' + JSON.stringify(res));
  return res;
}
