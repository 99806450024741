import * as React from 'react';
import { get } from 'lodash';
import clsx from 'clsx';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Box, IconButton, InputAdornment, List, ListItem, Popover, TextField, makeStyles } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import {
  CELL_FORMAT_OBJECT_KEYS,
  CELL_FORMAT_STATIC_KEYS,
} from '@ep/insight-ui/system/block/etable/cell-format-object-keys';
import { CELL_FORMAT_OPTIONS } from '@ep/insight-ui/system/block/etable/cell-format-options';
import cellFormatTooltip from '@ep/insight-ui/system/block/etable/cell-format-tooltip';

import { tooltip } from '../action-components/inline-edit/form-field/tooltip-input-type';
import { fields as FormFieldsComponent } from '../action-components/inline-edit/form-field';
import { simpleTooltip } from './helper/tooltip';
import { formulaPopup } from '../atom/editor-formula';
import { useSetAtom } from 'jotai';
import { isFormulaField } from '@ep/insight-ui/sw/util/excel-formula';

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#fff',
    color: 'rgba(0, 0, 0, 0.87)',
    minWidth: 200,
    maxWidth: 1000,
    boxShadow:
      '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    border: '1px solid #dadde9',
  },
}));

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  selectLabel: {
    display: 'flex',
    position: 'relative',
    width: '100%',
    height: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    '& > span': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  close: {
    position: 'absolute',
    top: '50%',
    right: '15px',
    transform: 'translateY(-50%)',
  },
  removeContainer: {
    '.ag-react-container > &': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
    },
  },
  tooltipConatiner: {
    display: 'flex',
    minWidth: '200px',
    minHeight: '40px',
    background: '#FFF',
    color: 'rgba(0, 0, 0, 0.87)',
  },
  listItem: {
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
    },
    borderRadius: '4px',
  },
  nagative: {
    backgroundColor: '#0000000a',
  },
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& textarea': {
      minWidth: '300px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
    marginBottom: '8px',
  },
  inputIcon: {
    marginLeft: '12px',
  },
  icon: {
    '& img': {
      width: '24px',
    },
    '& svg': {
      width: '20px',
    },
  },
  customTooltip: {
    marginTop: 0,
    background: '#253746',
  },
});

const formatLabel = (value) => {
  const newValue = value.replace(/_/g, ' ');
  const label = newValue[0].toUpperCase() + newValue.substring(1);
  return label;
};

const SelectFormat = (props: ICellRendererParams) => {
  const classes = useStyles();
  const displayFormula = useSetAtom(formulaPopup);
  const options = get(props, 'options', []).map((opt) => {
    if (props.field === 'column' && typeof opt === 'string') {
      return {
        label: formatLabel(opt),
        value: opt,
      };
    }
    return typeof opt === 'string' ? { label: CELL_FORMAT_OPTIONS[opt]?.label || opt, value: opt } : opt;
  });

  const inputRef = React.useRef(null);

  const [selItem, setSelItem] = React.useState(options.find((opt) => opt.value === props.value));
  const [isHovering, setIsHovering] = React.useState(false);

  const isStaticFilter = get(props, ['data', 'staticValue', 'staticFilter'], 0);
  const fieldsAllowedRemove = ['filterField', 'sortField'];
  const [indexItem, setIndexItem] = React.useState(null);
  const [navigateLabel, setNavigateLabel] = React.useState('');
  const [isNavigate, setIsNavigate] = React.useState(false);

  React.useEffect(() => {
    if (['sortField', 'filterField'].includes(props.field)) {
      setInputValue(props.value);
    }
    if (props.column.colId === 'column') {
      setSelItem(options.find((opt) => opt.value === props.data.columnKeys));
    } else {
      setSelItem(
        options.find((opt) => {
          return opt.value === props.value;
        }),
      );
    }
  }, [props.value]);

  const [inputValue, setInputValue] = React.useState(() => {
    const defaultInputValue = options.find((option) => option.value === props.value);
    if (defaultInputValue) return defaultInputValue.label;
    return props.value ? String(props.value) : '';
  });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    if (props.onClose) {
      props.onClose();
    }
  };

  const customOption = React.useMemo(() => {
    if (!props.allowCustomOption || !inputValue) return [];
    return !options.some((option) => option.label?.toLowerCase() === inputValue?.toLowerCase())
      ? [
          {
            label: `Create "${inputValue}"`,
            value: inputValue,
          },
        ]
      : [];
  }, [inputValue, props.allowCustomOption, props.allowFormula, options]);

  const listItems = options
    .concat({
      label: 'empty',
      value: '',
    })
    .concat(customOption)
    .filter(
      (option) =>
        option.label?.toLowerCase().includes(String(inputValue)?.toLowerCase()) ||
        (!props.hasSearch && !props.allowCustomOption),
    )
    .map((option, index) => {
      let CellTooltip = cellFormatTooltip[option.value];

      if (props.useTooltipIcon && FormFieldsComponent[option.value]) {
        CellTooltip = tooltip(option.value);
      } else if (props.tooltipField) {
        CellTooltip = option[props.tooltipField] ? simpleTooltip(option[props.tooltipField]) : null;
      }

      return (
        <ConditionalWrap
          key={option.value + index}
          condition={CellTooltip && !props.hideTooltip}
          wrap={(children) => {
            if (props.tooltipField) {
              return (
                <Tooltip
                  title={
                    <Box>
                      <CellTooltip />
                    </Box>
                  }
                  classes={{
                    tooltip: `${classes.customTooltip}`,
                  }}
                  placement="bottom-start"
                >
                  {children}
                </Tooltip>
              );
            }

            return (
              <HtmlTooltip title={<CellTooltip />} placement="right">
                {children}
              </HtmlTooltip>
            );
          }}
        >
          <ListItem
            className={clsx(classes.listItem, indexItem === index && classes.nagative)}
            onClick={() => {
              if (props.field === 'cellFormat') {
                if (
                  props.mapping[props.node.data.columnKeys] &&
                  props.mapping[props.node.data.columnKeys].cellFormat === option.value
                ) {
                  props.node.setDataValue('valueGetter', props.mapping[props.node.data.columnKeys].valueGetter);

                  // if (option.value === 'traction') {
                  //   if (props.mapping[props.node.data.columnKeys].staticValue) {
                  //     props.node.setDataValue('staticValue', props.mapping[props.node.data.columnKeys].staticValue);
                  //   } else {
                  //     props.node.setDataValue('staticValue', CELL_FORMAT_STATIC_KEYS[option.value]);
                  //   }
                  // }
                } else {
                  if (option.value === 'traction') {
                    props.node.setDataValue('staticValue', CELL_FORMAT_STATIC_KEYS[option.value]);
                  }
                }

                if (get(props, ['mapping', props.node.data.columnKeys, 'valueGetter'], null) == null) {
                  const { filterGetter } = CELL_FORMAT_OPTIONS[option.value].defaultConfig;
                  props.node.setData({
                    ...get(CELL_FORMAT_OPTIONS, [option.value, 'defaultConfig'], {}),
                    filterGetter: filterGetter ? filterGetter : null,
                    eTableEditor: CELL_FORMAT_OPTIONS[option.value].eTableEditor,
                  });
                }
              }

              if (props.data.key === 'accumulativeColumn') {
                props.api.forEachNode((node) => {
                  if (node.data.key === 'accumulativeField') {
                    props.api.redrawRows({
                      rowNodes: [node],
                    });
                  }
                });
              }

              if (props.field === 'defaultGroupBy') {
                // if (option.value == 1) {
                //   props.api.forEachNode((node) => {
                //     if (node.id !== props.node.id) {
                //       node.setDataValue(props.field, null);
                //     }
                //   });
                // }
                props.node.setDataValue(props.field, option.value === 1 ? option.value : null);
              } else {
                props.node.setDataValue(props.field, option.value);
              }
              setAnchorEl(null);
            }}
          >
            {props.isIcon ? (
              <Box className={classes.icon}>
                <Icon type={option.value} />
              </Box>
            ) : (
              option.label
            )}
          </ListItem>
        </ConditionalWrap>
      );
    });

  const handleUserKeyPress = React.useCallback(
    (event) => {
      //UP 38 , DOWN 40, ENTER 13
      const { keyCode } = event;
      if (keyCode == 38) {
        setIsNavigate(true);
        indexItem <= 0 ? setIndexItem(listItems.length - 1) : setIndexItem((prev) => prev - 1);
      }

      if (keyCode == 40) {
        setIsNavigate(true);
        indexItem >= listItems.length - 1 ? setIndexItem(0) : setIndexItem((prev) => (prev == null ? 0 : prev + 1));
      }

      if (keyCode == 13 && indexItem != null) {
        const listOptions = options
          .concat(customOption)
          .filter(
            (option) =>
              option.label?.toLowerCase().includes(String(inputValue)?.toLowerCase()) ||
              (!props.hasSearch && !props.allowCustomOption),
          );

        if (anchorEl) {
          const option = listOptions[indexItem];
          if (props.field === 'cellFormat') {
            if (
              props.mapping[props.node.data.columnKeys] &&
              props.mapping[props.node.data.columnKeys].cellFormat === option.value
            ) {
              props.node.setDataValue('valueGetter', props.mapping[props.node.data.columnKeys].valueGetter);
            } else {
              if (option.value === 'traction') {
                props.node.setDataValue('staticValue', CELL_FORMAT_STATIC_KEYS[option.value]);
              }
            }

            if (get(props, ['mapping', props.node.data.columnKeys, 'valueGetter'], null) == null) {
              const { filterGetter } = CELL_FORMAT_OPTIONS[option.value].defaultConfig;
              props.node.setData({
                ...get(CELL_FORMAT_OPTIONS, [option.value, 'defaultConfig'], {}),
                filterGetter: filterGetter ? filterGetter : null,
                eTableEditor: CELL_FORMAT_OPTIONS[option.value].eTableEditor,
              });
            }
          }
          if (props.data.key === 'accumulativeColumn') {
            props.api.forEachNode((node) => {
              if (node.data.key === 'accumulativeField') {
                props.api.redrawRows({
                  rowNodes: [node],
                });
              }
            });
          }
          if (props.field === 'defaultGroupBy') {
            props.node.setDataValue(props.field, option.value === 1 ? option.value : null);
          } else {
            props.node.setDataValue(props.field, option.value);
          }
          setAnchorEl(null);
        }
      }
    },
    [indexItem, options, customOption, anchorEl],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  // React.useEffect(() => {
  //   const listOptions = options
  //     .concat(customOption)
  //     .filter(
  //       (option) =>
  //         option.label.toLowerCase().includes(String(inputValue).toLowerCase()) ||
  //         (!props.hasSearch && !props.allowCustomOption),
  //     );

  //   const option = listOptions[indexItem];
  //   if (option && anchorEl) {
  //     setNavigateLabel(option.label);
  //   }
  // }, [indexItem, options, customOption, anchorEl]);

  return (
    <Box
      className={classes.container}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <ConditionalWrap
        condition={selItem && props.tooltipField && selItem[props.tooltipField]}
        wrap={(children) => {
          const CellTooltip = simpleTooltip(selItem[props.tooltipField]);

          return (
            <Tooltip
              title={
                <Box>
                  <CellTooltip />
                </Box>
              }
              classes={{
                tooltip: `${classes.customTooltip}`,
              }}
              placement="bottom-start"
            >
              {children}
            </Tooltip>
          );
        }}
      >
        <Box className={classes.selectLabel} onClick={handleClick}>
          {props.isIcon && selItem && selItem.value ? (
            <Box className={classes.icon}>
              <Icon type={selItem.value} />
            </Box>
          ) : (
            <span>{(selItem && selItem.label) || (typeof props.value === 'string' ? props.value : '')}</span>
          )}
          {isHovering &&
            !!props.value &&
            !isStaticFilter &&
            (!!fieldsAllowedRemove.includes(props.field) || props.allowedRemove) && (
              <span
                className={classes.close}
                onClick={() => {
                  props.node.setDataValue(props.field, '');
                  setIsHovering(false);
                }}
              >
                <Icon type={'closeCircle'} />
              </span>
            )}
          <Box>
            {isFormulaField(props.value) && (
              <IconButton
                onClick={(event) => {
                  const availVars = [];
                  props.api.forEachNode((node) => {
                    availVars.push(node.data.key);
                  });
                  event.stopPropagation();
                  displayFormula({
                    isDisplay: true,
                    content: inputValue,
                    ref: event.currentTarget,
                    backboneConfig: { mapping: props.mappingConfig },
                    availableVars: availVars,
                    submit: (content) => {
                      setInputValue(content);
                      props.node.setDataValue(props.field, content);
                      handleClose();
                    },
                  });
                }}
                style={{ opacity: inputValue ? 1 : 0 }}
              >
                <Icon type={'rmx/functions-icon/blue6'} />
              </IconButton>
            )}
            <Icon type={'arrowdown'} />
          </Box>
        </Box>
      </ConditionalWrap>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        getContentAnchorEl={null}
      >
        {(props.hasSearch || props.allowCustomOption) && (
          <TextField
            ref={inputRef}
            multiline={props.allowFormula}
            minRows={1}
            maxRows={10}
            className={clsx(classes.textStyle, classes.textSearch)}
            defaultValue={inputValue}
            value={isNavigate && navigateLabel ? navigateLabel : inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              setIsNavigate(false);
            }}
            variant="outlined"
            autoFocus={true}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <Search style={{ width: 12, height: 14 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <React.Fragment>
                  <IconButton
                    onClick={() => {
                      const availVars = [];
                      props.api.forEachNode((node) => {
                        availVars.push(node.data.key);
                      });
                      displayFormula({
                        isDisplay: true,
                        content: inputValue,
                        ref: anchorEl,
                        backboneConfig: { mapping: props.mappingConfig },
                        availableVars: availVars,
                        submit: (content) => {
                          setInputValue(content);
                          props.node.setDataValue(props.field, content);
                          handleClose();
                        },
                      });
                    }}
                    style={{ opacity: inputValue ? 1 : 0 }}
                  >
                    <Icon type={'rmx/functions-icon/blue6'} />
                  </IconButton>
                  <IconButton onClick={() => setInputValue('')} style={{ opacity: inputValue ? 1 : 0 }}>
                    <Icon type={'closeCircle'} />
                  </IconButton>
                </React.Fragment>
              ),
              classes: { input: classes['input'] },
              fullWidth: true,
            }}
          />
        )}
        <List>{listItems}</List>
      </Popover>
    </Box>
  );
};

export default SelectFormat;
