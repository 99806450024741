import {
  BaseTextFieldProps,
  Box,
  debounce,
  IconButton,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';
import Icon from '@ep/insight-ui/icons/Icon';
import FormControlTemplate from '../form-control-template';
import clsx from 'clsx';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { colors, getCustomPlaceHolder, noSelectStyled, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import numeral from 'numeral';
import { formatNumber } from '@ep/insight-ui/lib/number';
import MaskedInput from 'react-text-mask';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: '',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 3, // how many digits allowed after the decimal
  integerLimit: 20, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

const CurrencyInput = ({ maskOptions, ...inputProps }: any) => {
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  return <MaskedInput mask={currencyMask} {...inputProps} />;
};
interface IProps {
  noSelectBox: boolean;
  currency: boolean;
  isValue: boolean;
}
const useStyles = makeStyles(() => ({
  textStyle: {
    ...(textFieldStyle as CreateCSSProperties),
    '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
      width: '100%',

      '& .eip1-MuiOutlinedInput-notchedOutline': {
        borderColor: colors.border.subdued,
        color: colors.icon.disabled,
      },
    },
    '& .eip1-MuiInputBase-root.Mui-disabled': {
      pointerEvents: 'none',
    },
    '&.eip1-MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
    width: '100%',
  },
  isCorrect: textFieldStyle.isCorrect as CreateCSSProperties,
  textFormat: {
    width: '100%',
    maxHeight: '32px',
    '& .eip1-MuiOutlinedInput-root': {
      maxHeight: '32px',
      paddingRight: 0,
    },
    '& button svg': {
      width: '12px',
      height: '12px',
    },
    '& input': {
      width: '100%',
      ...getCustomPlaceHolder('#000', {
        fontWeight: 400,
      }),
      paddingLeft: '8px',
      paddingRight: (props: IProps) => (props.isValue ? '32px' : '8px'),
      borderRight: (props: IProps) => (props.noSelectBox ? 'none' : 'solid 1px #C2C7CB'),
      borderRadius: 0,
    },
    '&:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
    '&:hover input,&:focus input , & input:focus': {
      borderRightColor: '#006EC6',
    },
    '& input.Mui-disabled': {
      borderRightColor: `${colors.border.subdued}`,
    },
  },
  textEndAdornment: {
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    color: '#8C98A4',
    ...noSelectStyled,
    marginLeft: (props: IProps) => (props.noSelectBox ? '0' : '10px'),
    marginRight: '10px',
    '& + .iconExpand': {
      marginLeft: '0px !important',
    },
  },
  box: {
    display: 'flex',
    opacity: 1,
    position: 'relative',
    maxHeight: '32px',
    height: '32px',
    justifyContent: 'center',
    cursor: (props: IProps) => (props.noSelectBox ? 'default' : 'pointer'),
    '& .eip1-MuiSvgIcon-root': {
      cursor: 'pointer',
      width: '12px',
      height: '12px',
      // marginTop: '10px',
      // marginLeft: '-12px',
    },
  },
  adornment: {
    display: 'flex',
    alignItems: 'center',
    '&.iconClose': {
      position: 'absolute',
      left: 0,
      padding: '0 10px',
      transform: 'translateX(-32px)',
      cursor: 'pointer',
    },
    '&.iconExpand': {
      marginRight: '10px',
      marginLeft: '10px',
      '& svg': {
        padding: '0',
        width: '8px',
        height: '8px',
      },
    },
  },
  disabled: {
    background: '#F6F7F8',
    pointerEvents: 'none',
    color: '#C2C7CB',
    '&.eip1-MuiOutlinedInput-root.Mui-disabled .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#E4E7E9',
    },
    '& input': {
      ...getCustomPlaceHolder('#C2C7CB', {
        fontWeight: 400,
      }),
    },
    '& $textEndAdornment': {
      color: '#C2C7CB',
    },
  },
}));

export interface TextBudgetInputProps extends OutlinedInputProps {
  onChangeText?: (v: string) => void;
  defaultValue?: string;
  currency?: string;
  disableHideIconSelect?: boolean;
  noSelectBox?: boolean;
  budgetType?: 'daily' | 'total' | 'limit';
  disabledDebounce?: boolean;
  limit?: number;
}
const TextBudgetInput = ({
  onChangeText,
  defaultValue = '',
  currency = '',
  disableHideIconSelect = false,
  noSelectBox = false,
  budgetType,
  disabledDebounce = false,
  limit = -1,
  ...rest
}: TextBudgetInputProps) => {
  const [value, setValue] = React.useState<string>(defaultValue);
  const [focus, setFocus] = React.useState(false);
  const classes = useStyles({ noSelectBox, isValue: !!value });
  React.useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  const handleSetText = React.useCallback(debounce(onChangeText, 500), []);
  React.useEffect(() => {
    const output = value.replace(/,/g, '');
    if (value == defaultValue) return;
    if (!disabledDebounce) {
      handleSetText(value.replace(/,/g, ''));
    } else {
      onChangeText(value.replace(/,/g, ''));
    }
  }, [value]);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const onClear = () => {
    return setValue('');
  };

  const checkLimit = () => limit > 0 && Number(value.replace(/,/g, '')) > limit;

  const outLineInputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (!outLineInputRef.current) return;

    const input = outLineInputRef.current.querySelector('input') as HTMLInputElement;
    const len = input.value ? input.value.length : value.length + 1;

    input.setSelectionRange(len, len);
  }, [focus]);
  const handleFocus = React.useCallback(debounce(setFocus, 100), []);

  return (
    <OutlinedInput
      {...rest}
      className={clsx(classes.textStyle, classes.textFormat, rest.disabled && classes.disabled, rest.className, {
        ['Mui-error']: checkLimit(),
      })}
      ref={outLineInputRef}
      onChange={onChange}
      onFocus={() => handleFocus(true)}
      onBlur={() => handleFocus(false)}
      value={value}
      inputComponent={CurrencyInput as any}
      endAdornment={
        <Box className={classes.box} aria-label="toggle password visibility">
          {focus && (
            <Box component={'div'} height={'100%'} className={`${classes.adornment} iconClose`} onClick={onClear}>
              {value && <CloseFiled />}
            </Box>
          )}

          <Box display={`flex`}>
            {currency && <p className={classes.textEndAdornment}>{currency}</p>}
            {!noSelectBox && !disableHideIconSelect && (
              <Box component={'div'} height={'100%'} className={`${classes.adornment} iconExpand`}>
                <Icon size="8px" type="chevron" />
              </Box>
            )}
          </Box>
        </Box>
      }
    />
  );
};
export default TextBudgetInput;
