import { EIP_CONSTANT } from '@eip/next/lib/main';
import { CAMPAIGN_DETAIL_COMMON_MAPPING, getActionMenu } from '../../table-config/common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign detail',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
    GET_MARKET_STATUS_DETAIL: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'ADS_PLACEMENT.id',
    'PRODUCT.id',
    'ADS_CALENDAR.ADS_CAMPAIGN.id',
    'ADS_CALENDAR.ADS_OBJECT.id',
    // 'SUGGESTED_KEYWORD.id',
  ],
  viewSetting: [
    {
      id: 'all',
      name: 'All',
      excludeProperty: {
        dimension: ['storefront_ad_object'],
        metric: [],
        attribute: [],
      },
    },
  ],
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
    additionalFilters: [
      {
        name: 'Data status',
        id: 'data_status',
        dataType: 'string',
        propertyType: 'dimension',
        fields: ['ADS_CAMPAIGN.data_status', 'ADS_OBJECT.data_status', 'ADS_PLACEMENT.data_status'],
        options: [
          {
            label: 'Succeeded',
            value: '1',
          },
          {
            label: 'Failed',
            value: '2',
          },
        ],
        rules: [
          {
            value: '1',
            combinator: 'AND',
          },
          {
            value: '2',
            combinator: 'OR',
          },
        ],
        operators: [
          {
            label: 'Is',
            value: 'is',
          },
        ],
        isAdditionalFilter: true,
      },
    ],
  },
  disabledComponents: {
    dimension: ['marketplace', 'country', 'storefront', 'ad_tool', 'storefront_ad_object', 'ad_object'],
  },
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'dataSyncStatus',
          'marketplace',
          'country',
          'storefront',
          'ad_tool',
          'ad_object',
          'keyword',
          'campaign_tag',
          'ad_object_tag',
          'keyword_tag',
          'keyword_method',
          'keyword_bidding_price',
          'ad_campaign_status',
          'campaign_timelime',
          'ad_object_status',
          'ad_object_budget',
          'ad_object_creative',
          'ad_object_timelime',
          'keyword_status',
        ],
      }
    : {}),
  mapping: {
    dataSyncStatus: {
      title: 'Sync status',
      propertyType: 'dimension',
      cellFormat: 'dataSyncStatusFormat',
      dataType: 'string',
      filterField: '',
      sortField: '',
      valueGetter: {
        data_status: 'ADS_CAMPAIGN.data_status',
      },
      pinned: 'left',
    },
    // ---------------------------- Dimension ----------------------------------//
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        campaignName: 'ADS_CAMPAIGN.name',
        campaignCode: 'ADS_CAMPAIGN.campaign_code',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
        campaignDataStatus: 'ADS_CAMPAIGN.data_status',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
        ...(ff.groupby_display_location
          ? {
              id: 'ADTOOL.ads_tool',
              value: 'ADTOOL.ads_tool_name',
            }
          : {}),
      },
      tableConfiguration: null,
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT',
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        productIds: 'PRODUCT.product_sid',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        isAutomatedCampaign: 'ADS_CAMPAIGN.automated_ads',
        isAutomatedAdsSelectProduct: 'ADS_CAMPAIGN.automated_ads_select_product',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        marketplace: 'MARKETPLACE.marketplace_name',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        data_status: 'ADS_OBJECT.data_status',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_PLACEMENT.name',
      valueGetter: {
        data: 'keyword',
        adPlacementId: 'ADS_PLACEMENT.id',
        label: 'ADS_PLACEMENT.name',
        createdAt: 'ADS_PLACEMENT.created_at',
        updatedAt: 'ADS_PLACEMENT.updated_at',
        isAutomatedAds: 'ADS_OBJECT.automated_ads',
        marketplace: 'MARKETPLACE.marketplace_name',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        isAutomatedCampaign: 'ADS_CAMPAIGN.automated_ads',
        data_status: 'ADS_PLACEMENT.data_status',
        ...(ff.groupby_display_location
          ? {
              id: 'ADS_PLACEMENT.name',
              productId: 'PRODUCT.id',
            }
          : {}),
      },
      ...(ff.compact_dependency_data
        ? {
            staticValue: {
              dependencyData: ['PRODUCT.id'],
            },
          }
        : {}),
      tableConfiguration: null,
    },
    // ---------------------------- Attribute ----------------------------------//
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.general_tag',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    keyword_method: {
      title: 'Keyword method',
      propertyType: 'attribute',
      cellFormat: 'keywordMethod',
      valueGetter: {
        value: 'ADS_OBJECT.automated_ads',
        marketplace: 'MARKETPLACE.marketplace_name',
        adsObjectId: 'ADS_OBJECT.id',
        adsPlacementId: 'ADS_PLACEMENT.id',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        label: 'ADS_OBJECT.automated_ads',
        createdAt: 'ADS_PLACEMENT.created_at',
        updatedAt: 'ADS_PLACEMENT.updated_at',
        isAutomatedCampaign: 'ADS_CAMPAIGN.automated_ads',
      },
      tableConfiguration: null,
      menu: getActionMenu('keyword_method'),
    },
    keyword_bidding_price: {
      title: 'Keyword bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_PLACEMENT.bidding_price',
      valueGetter: {
        value: 'ADS_PLACEMENT.bidding_price',
        id: 'ADS_PLACEMENT.id',
        currency: 'STOREFRONT.currency',
        isAutomatedAds: 'ADS_OBJECT.automated_ads',
        marketplace: 'MARKETPLACE.marketplace_name',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        isAutomatedCampaign: 'ADS_CAMPAIGN.automated_ads',
      },
      menu: getActionMenu('keyword_bidding_price'),
    },
    ...(ff.delete_ad_campaign_object
      ? {}
      : {
          ad_campaign_object: {
            title: 'Ad campaign object',
            propertyType: 'attribute',
            cellFormat: 'text',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.objective',
            valueGetter: {
              label: 'ADS_CAMPAIGN.objective',
              id: 'ADS_CAMPAIGN.objective',
              value: 'ADS_CAMPAIGN.objective',
            },
            menu: getActionMenu('ad_campaign_object'),
          },
        }),
    ad_campaign_status: {
      title: ff.delete_ad_campaign_object ? 'Campaign status' : 'Ad campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    ...(ff.remove_campaign_budget
      ? {}
      : {
          ad_campaign_daily_budget: {
            title: 'Ad campaign daily budget',
            propertyType: 'attribute',
            cellFormat: 'dailyBudget',
            dataType: 'float',
            filterField: 'ADS_CAMPAIGN.daily_budget',
            valueGetter: {
              value: 'ADS_CAMPAIGN.daily_budget',
              currency: 'STOREFRONT.currency',
            },
            menu: getActionMenu('campaign_daily_budget'),
          },
          ad_campaign_total_budget: {
            title: 'Ad campaign total budget',
            propertyType: 'attribute',
            cellFormat: 'totalBudget',
            dataType: 'float',
            filterField: 'ADS_CAMPAIGN.total_budget',
            valueGetter: {
              value: 'ADS_CAMPAIGN.total_budget',
              currency: 'STOREFRONT.currency',
            },
            menu: getActionMenu('campaign_total_budget'),
          },
        }),
    campaign_timelime: {
      title: 'Campaign timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
        id: 'ADS_CALENDAR.ADS_CAMPAIGN.id',
      },
      menu: getActionMenu('campaign_timeline'),
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    ad_object_creative: {
      title: 'Ad object creative',
      propertyType: 'attribute',
      cellFormat: 'enableDisableFormat',
      dataType: 'string',
      filterField: 'ADS_OBJECT.automated_creatives',
      valueGetter: {
        label: 'ADS_OBJECT.automated_creatives',
        status: 'ADS_OBJECT.automated_creatives',
        value: 'ADS_OBJECT.automated_creatives',
      },
      menu: getActionMenu('ad_object_creative'),
    },
    ad_object_timelime: {
      title: 'Ad object timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
        id: 'ADS_CALENDAR.ADS_OBJECT.id',
      },
      menu: getActionMenu('ad_object_timeline'),
    },
    keyword_status: {
      title: 'Keyword status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.status',
      valueGetter: {
        label: 'ADS_PLACEMENT.status',
        status: 'ADS_PLACEMENT.status',
        id: 'ADS_PLACEMENT.id',
        tooltips: 'ADS_PLACEMENT.tooltips',
      },
      menu: getActionMenu('keyword_status'),
    },
    // ---------------------------- metric ----------------------------------//
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.position_updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
    ...CAMPAIGN_DETAIL_COMMON_MAPPING,
  },
};
