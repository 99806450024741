import { request } from '@ep/one/src/utils';
import { get as _get, get, mapValues, merge, omit } from 'lodash';
import { useLog } from '@eip/next/lib/log';
import { v4 as uuid } from 'uuid';
import { pageMapping } from './server-mapping';
import { usePageServer } from './server-system-page';
import { canAccess, getUserSettings, isSuperAdmin, isViewAsUserMode } from '@ep/one/aim';
import minimatch from 'minimatch';
import { EIP_CONSTANT } from '../../constant';

const log = useLog('hooks:server-user-page');
const logErr = log.extend('error');

const cachedPersona = {};
const cachedPageSections = {};

export function useUserPageServer(serverURL) {
  function getUserId() {
    return getUserSettings().profile.userId;
  }

  const systemPageServer = usePageServer(serverURL);
  function syncMenuByParents(pageIds: string[]) {
    return request
      .post(`${serverURL}/user/sync_records`, {
        request_id: uuid(),
        user_id: getUserId(),
        requests: pageIds.map((parentId) => {
          return {
            pointer: { id: parentId, table: 'eip_system_block' },
            pointer_type: 'parent',
            block_type: 'user_menu',
            owner_id: getUserId(),
          };
        }),
      })
      .then((res) => {
        const list = mapValues(res.records, (records) => mapValues(records, pageMapping));
        log('user menu', list);
        return list;
      })
      .catch((err) => {
        logErr('syncMenuViaSystemPage', err);
        return {};
      });
  }

  function update(changeset: any[]): Promise<any> {
    if (isViewAsUserMode()) {
      console.info('view as user mode, skip update');
      return Promise.resolve(null);
    }

    // return Promise.resolve({});
    return request
      .post(`${serverURL}/user/save_changeset`, {
        request_id: uuid(),
        changeset: changeset,
        user_id: getUserId(),
      })
      .then((res) => {
        return null;
      });
  }

  async function getPageNavigation(pageId) {
    return request
      .post(`${serverURL}/user/get_page_navigation`, {
        request_id: uuid(),
        user_id: getUserId(),
        request: [
          {
            id: pageId,
          },
        ],
      })
      .then((res) => res);
  }

  const refreshCaches = (pageId) => {
    request.get('/api/v1/refresh-caches?pageId=' + pageId);
  };

  const getPageBlockViews = (pageId, forceReload) => {
    let userId = getUserId();
    const isViewAsUser = isViewAsUserMode();
    if (isViewAsUser) {
      userId = getUserSettings().adminConfig.override.userId;
    }
    const queryParams = new URLSearchParams({
      id: pageId,
      ...(isViewAsUser ? { user_id: userId } : {}),
    }).toString();
    if (!cachedPersona[pageId] || forceReload) {
      cachedPersona[pageId] = request.get(`${serverURL}/page/persona?` + queryParams).then((res) => {
        return Object.entries(get(Object.values(res), [0, 'payload', 'properties', 'blockPersona'], {})).reduce(
          (carry, [k, v]) => {
            return {
              ...carry,
              [k]: get(v, ['customAttributes', 'views'], [])
                .filter((view) => !view.isHidden)
                .map((view) => ({
                  id: view.id,
                  name: view.name,
                  isLocked: view.isLock,
                })),
            };
          },
          {},
        );
      });
    }

    return cachedPersona[pageId];
  };

  const getPageSections = (pageId, forceReload) => {
    const queryParams = new URLSearchParams({
      id: pageId,
    }).toString();
    if (!cachedPageSections[pageId] || forceReload) {
      cachedPageSections[pageId] = request.get(`${serverURL}/page/chunk?` + queryParams).then((res) => {
        const blocks = get(res, ['block'], {});
        const layouts = _.cloneDeep(get(blocks, [pageId, 'payload', 'format', 'layouts', 'lg'], [])).sort((a, b) => {
          const xA = get(a, ['layout', 'x'], 0);
          const yA = get(a, ['layout', 'y'], 0);
          const xB = get(b, ['layout', 'x'], 0);
          const yB = get(b, ['layout', 'y'], 0);
          if (xA <= xB && yA <= yB) return -1;
          return 1;
        });
        const { userEmail, userId } = getUserSettings().profile || {};
        const sections = layouts
          .filter((layout) => {
            if (isSuperAdmin()) return true;
            let allowAccess = false;
            let legacyAllowAccess = true;

            const nextPermission = get(blocks, [layout.id, 'payload', 'properties', 'next_permission'], []);
            const permission = get(blocks, [layout.id, 'payload', 'properties', 'permission'], []);
            if (permission && permission.length > 0) {
              legacyAllowAccess = permission.includes(userEmail);
            }

            const includedAccess = nextPermission.filter((i) => !String(i).startsWith('!'));
            const excludeAccess = nextPermission.filter((i) => String(i).startsWith('!'));

            allowAccess = allowAccess || includedAccess.length === 0;
            allowAccess = allowAccess || includedAccess.some((i) => minimatch(userId, i, { matchBase: true }));
            allowAccess =
              allowAccess ||
              includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
            allowAccess =
              allowAccess &&
              (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userId, i, { matchBase: true })));

            allowAccess =
              allowAccess &&
              (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

            return legacyAllowAccess && allowAccess;
          })
          .map((layout) => {
            const section = get(
              blocks,
              [layout.id, 'payload', 'properties', 'customAttributes', 'configuration'],
              get(blocks, [layout.id, 'payload', 'properties', 'customAttributes'], {}),
            );

            return {
              title: section.title,
              label: section.title,
              nodeId: layout.id,
              icon: 'ic/ic:round-table-chart/#3F4F5C',
              tabs: [],
              type: 'page_section',
              pageId: pageId,
            };
          });
        return sections;
      });
    }

    return cachedPageSections[pageId];
  };

  const getUserList = () => {
    if (canAccess('internal')) {
      const payload = {
        dimensions: ['user'],
        attributes: ['user.email', 'user.uid', 'user.status'],
        metrics: [],
        pagination: {
          page: 1,
          limit: 9999,
        },
        from: '2024-01-01 00:00:00',
        to: '2024-12-31 23:59:59',
        sort: [
          {
            field: 'user.created_at',
            sort: 'DESC',
          },
        ],
        filter: {
          combinator: 'and',
          filters: [
            {
              field: 'user.type',
              operator: 'is',
              value: 'main',
              dataType: 'string',
            },
          ],
        },
        hiddenFilter: {
          currency: 'USD',
        },
        currency: 'USD',
        isSummary: true,
      };
      return request
        .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=passport&mainColumn=user.uid', payload)
        .then((res) => {
          const headers = get(res, ['data', 'headers'], []);
          const rows = get(res, ['data', 'rows'], []);

          const result = rows.reduce((carry, row) => {
            const emailIndex = headers.findIndex((i) => i == 'user.email');
            const statusIndex = headers.findIndex((i) => i == 'user.status');
            const createdAtIndex = headers.findIndex((i) => i == 'user.created_at');
            return {
              ...carry,
              [row[emailIndex]]: {
                createdAt: row[createdAtIndex],
                status: row[statusIndex],
              },
            };
          }, {});

          return result;
        });
    }

    return Promise.reject(null);
  };

  return {
    syncMenuByParents,
    update,
    getSingle: systemPageServer.getSingle,
    getPageNavigation,
    refreshCaches,
    getPageBlockViews,
    getPageSections,
    getUserList,
  };
}
