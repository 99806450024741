import * as React from 'react';
import { debounce } from 'lodash';

import { Grid, TextField, makeStyles } from '@material-ui/core';

import FormControlTemplate from './form-control-template';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '5px 10px',
      height: '22px',
      width: '50px',
    },
  },
}));

const RepeatingAt = ({ repeatingValue, value, onChange, disabled }) => {
  if (!['WEEKLY', 'CUSTOM', 'DAILY_AT'].includes(repeatingValue)) return null;
  const classes = useStyles();

  const [from, setFrom] = React.useState(value?.from || '');
  const [to, setTo] = React.useState(value?.to || '');

  const formatTime = (time) => {
    return `0${time}`.slice(-2);
  };

  const handleOnBlurFrom = () => {
    let numbers = from.match(/\d+/g).join('');
    if (numbers.length === 1) numbers = '0' + numbers + '00';
    else if (numbers.length < 3) numbers = numbers + '0'.repeat(4 - numbers.length);
    else if (numbers.length === 3) numbers = '0' + numbers;
    let [h, m] = [numbers.slice(0, 2), numbers.slice(2, 4)];
    if (to) {
      const [toH, toM] = to.split(':');
      if (Number(toH) < Number(h)) h = toH;
      if (Number(h) == Number(toH) && Number(m) > Number(toM)) m = toM;
    }
    const newFrom = `${formatTime(Math.min(Number(h), 23))}:${formatTime(Math.min(Number(m), 59))}`;
    setFrom(newFrom);
    onChange({
      from: newFrom,
      to,
    });
  };

  const handleOnBlurTo = () => {
    let numbers = to.match(/\d+/g).join('');
    if (numbers.length === 1) numbers = '0' + numbers + '00';
    else if (numbers.length < 3) numbers = numbers + '0'.repeat(4 - numbers.length);
    else if (numbers.length === 3) numbers = '0' + numbers;
    let [h, m] = [numbers.slice(0, 2), numbers.slice(2, 4)];
    if (from) {
      const [fromH, fromM] = from.split(':');
      if (Number(fromH) > Number(h)) h = fromH;
      if (Number(h) == Number(fromH) && Number(m) < Number(fromM)) m = fromM;
    }
    const newTo = `${formatTime(Math.min(Number(h), 23))}:${formatTime(Math.min(Number(m), 59))}`;
    setTo(newTo);
    onChange({
      from,
      to: newTo,
    });
  };

  return (
    <Grid item xs={12}>
      <FormControlTemplate label={'Repeating At'}>
        <div className={classes.container}>
          <TextField
            variant={'outlined'}
            placeholder={'HH:MM'}
            value={from}
            onChange={(e) => setFrom(e.target.value)}
            onBlur={handleOnBlurFrom}
            disabled={disabled}
          />
          <span> to </span>
          <TextField
            variant={'outlined'}
            placeholder={'HH:MM'}
            value={to}
            onChange={(e) => setTo(e.target.value)}
            onBlur={handleOnBlurTo}
            disabled={disabled}
          />
        </div>
      </FormControlTemplate>
    </Grid>
  );
};

export default RepeatingAt;
