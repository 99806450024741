import { Grid, makeStyles, Box, Tooltip } from '@material-ui/core';
import * as React from 'react';
import { get } from 'lodash';

import Icon from '@ep/insight-ui/icons/Icon';

import WrapperFormat, { IPropsFormat } from './wrapper-format';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 2,
    width: '12px',
    height: '12px',
    boxShadow: 'inset 0 0 0 2px #C2C7CB, inset 0 -1px 0 #C2C7CB',

    background: '#fff',
    '$root.Mui-focusVisible &': {
      outline: '4px auto rgba(19,124,189,.6)',
      outlineOffset: 4,
    },
    'input:hover ~ &': {
      backgroundColor: '#fff',
      boxShadow: 'inset 0 0 0 2px #338ED8, inset 0 -1px 0 #338ED8',
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      display: 'block',
      width: '12px',
      height: '12px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  indeterminate: {
    position: 'relative',
    backgroundColor: '#0369C7',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    boxShadow: 'inset 0 0 0 1px #0369C7, inset 0 -1px 0 #0369C7',
    '&:before': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'block',
      width: '8px',
      height: '2px',
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 2'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M7 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 " +
        "2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0Z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
    '& svg': {
      width: '21px',
      height: '21px',
    },
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  errorDataStatusTooltip: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '0 16px',
  },
  errorDataStatusTooltipItem: {
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
    },
  },
});

export interface IValueSelectionFormat {
  checked: boolean;
  disabled?: boolean;
}

const StatusIndicator = (props: IPropsFormat<IValueSelectionFormat>) => {
  const value = get(props, ['value', 'value'], null);
  const classes = useStyles();
  const typeTable = get(props, 'typeTable', '');
  const cellAction = get(props, 'cellAction', []);
  const isGrouped = get(props, 'isGrouped', false);
  const node = get(props, 'node', null);
  const disabled = get(props, 'value.disabled', false);
  const dataStatus = get(props, ['value', 'value'], null);
  const errorDataStatus = Object.entries(safeJsonParse(get(props, ['value', 'message'], '')) || {});

  const isError = React.useMemo(() => {
    return dataStatus === 'SKIPPED';
  }, [dataStatus]);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={cellAction}
      node={node}
      value={value}
      isGrouped={isGrouped}
      disabledMenu={disabled}
    >
      <Grid
        container
        justifyContent="center"
        alignItems={'center'}
        style={{ display: 'flex', alignItems: 'center', height: '100%', paddingLeft: '1px', position: 'relative' }}
      >
        {isError ? (
          <Tooltip
            title={
              <ul className={classes.errorDataStatusTooltip}>
                {errorDataStatus.map(([key, errorMessage]) => {
                  return (
                    <li key={key} className={classes.errorDataStatusTooltipItem}>
                      <div>
                        <span>
                          {String(key)
                            .replace(/\w+\./g, () => '')
                            .replace(/^\w/, (c) => c.toUpperCase())
                            .split('_')
                            .join(' ')}
                          : {errorMessage}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            }
            classes={{ tooltip: classes.tooltip }}
            placement="bottom"
          >
            <Box className={classes.syncStatusText}>
              <Icon type={'ri/MdErrorOutline/#D4290D'} size={'24px'} />
            </Box>
          </Tooltip>
        ) : (
          <Box className={classes.syncStatusText}>
            <Icon type={'ri/GrStatusGood/mint'} size={'24px'} />
          </Box>
        )}
      </Grid>
    </WrapperFormat>
  );
};
export default StatusIndicator;
