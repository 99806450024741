import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList, { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import Icon from '@ep/insight-ui/icons/Icon';
import clsx from 'clsx';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { EIPContext, DashboardItemContext } from './context';
import { actions } from './dashboard-redux';
import { hasCustomMenu } from './node/chartlibs';
import { NodeFactory } from './node/factory';
import { DashboardState, NodeData } from './type';
import { Button } from '@material-ui/core';
import { get } from 'lodash';

const StyledCard = styled('div')`
  position: relative;
  display: flex;
  background: rgba(255, 255, 255, 0.79);
  overflow: hidden;

  .drag-holder {
    position: absolute;
    z-index: 10;
    top: 0;
    left: calc(50% - 25px);
    width: 50px;
    padding: 2px;
    background: rgba(237, 92, 16, 1);
    border: 1px solid rgba(255, 255, 255, 0.8);
    cursor: move;
    display: flex;
    justify-content: center;
  }

  &.selected {
    outline: 1px solid rgba(237, 92, 16, 1) !important;
    z-index: 10;
  }

  &.editable {
    outline: 1px solid rgba(37, 55, 70, 0.39);
    & .controls {
      visibility: visible;
    }
  }

  .dashboard-item-card {
    padding: 0;
    width: 100%;
    height: 100%;
    flex: 1 1 auto;
  }
  .dashboard-item-card.center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .controls {
    display: flex;
    align-items: center;
    visibility: hidden;
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
  }
  .controls-dropdown {
    z-index: 1000;
  }

  .btn-control {
    color: var(--secondary-21);
    &:hover {
      color: var(--primary);
    }
  }
  .btn-add-chart {
    color: var(--secondary-21);
    cursor: pointer;
    &:hover {
      color: var(--secondary);
    }
  }
  .card-body.center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .connection {
  }
`;

const DashboardItem = React.forwardRef(
  ({ nodeData, onToggleFocus = Function.prototype }: { nodeData: NodeData; onToggleFocus?: () => void }, ref) => {
    const [menuOpen, setMenuOpen] = React.useState(false);
    const [isDraggable, setDraggable] = React.useState(true);

    const { isSelect, isConnectSource, editorMode } = useSelector((state: DashboardState) => {
      const nodeId = nodeData.id;
      const mode: DashboardState['mode'] = state.mode || {
        type: null,
        activeNode: null,
      };
      return {
        isSelect: mode.type === 'select' && mode.activeNode === nodeId,
        isConnectSource: mode.type === 'connect' && mode.activeNode === nodeId,
        editorMode: mode,
      };
    });
    const isFocus = isSelect;

    const showDefaultMenu = !hasCustomMenu(nodeData.chartLibId);

    const dbContext = React.useContext(EIPContext);
    const isEditMode = dbContext.mode === 'edit';

    const [shouldRenderComponent, setShouldRenderComponent] = React.useState(!isEditMode);
    const itemRef = React.useRef();

    const menuItems: DropdownMenuDataType[][] = [
      [
        {
          title: 'Remove',
          icon: 'delete',
          onClick: () => {
            dbContext.onRemoveNode(nodeData.id);
          },
        },
        {
          title: 'Duplicate',
          icon: 'duplicate',
          onClick: () => {
            dbContext.onDuplicateNode(nodeData.id);
          },
        },
        ...(shouldRenderComponent
          ? [
              {
                title: 'Hide component',
                icon: 'rmx/eye-off-fill-icon/characoal',
                onClick: () => {
                  document.querySelector(`#${'block-' + nodeData.id} > div`).style.display = 'none';
                  setShouldRenderComponent(false);
                },
              },
            ]
          : [
              {
                title: 'Show component',
                icon: 'rmx/eye-fill-icon/characoal',
                onClick: () => {
                  setShouldRenderComponent(true);
                  setTimeout(() => {
                    if (document.querySelector(`#${'block-' + nodeData.id} > div`)) {
                      document.querySelector(`#${'block-' + nodeData.id} > div`).style.display = 'block';
                    }
                  }, 100);
                },
              },
            ]),
        {
          title: 'Copy sectionId',
          icon: 'copy',
          onClick: () => {
            const cb = navigator.clipboard;
            cb.writeText(String(nodeData.blockEid));
          },
        },
      ],
    ];

    return (
      <DashboardItemContext.Provider
        value={{
          enableRootDraggable: (status) => {
            setDraggable(status);
          },
        }}
      >
        <StyledCard
          style={{
            height: '100%',
            width: '100%',
          }}
          ref={itemRef}
          className={clsx(isEditMode && 'editable', isFocus && 'selected')}
        >
          <div className={'drag-holder drag-enabled'}>
            <Icon className="icon" type="draggable" colorIcon={'#fff'} />
          </div>
          <div
            ref={(dom) => {
              if (dom && ref) ref.current = dom.parentElement;
            }}
          ></div>
          {showDefaultMenu && (
            <div className="controls">
              <Dropdown alignMenu="right" icon="threeDotsVertical" label="">
                <MenuList listMenu={menuItems} />
              </Dropdown>
            </div>
          )}
          {shouldRenderComponent ? (
            <div
              className={clsx(!nodeData.chartLibId && 'center', 'dashboard-item-card')}
              id={'block-' + nodeData.id}
            ></div>
          ) : (
            <div>
              <strong>{get(nodeData, ['customAttributes', 'title'], '')}</strong>
            </div>
          )}
        </StyledCard>
      </DashboardItemContext.Provider>
    );
  },
);

export default DashboardItem;
