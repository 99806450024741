import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Openpdp = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.93305 1.65L3.2967 5.29C3.11063 5.47736 3.0062 5.73081 3.0062 5.995C3.0062 6.25919 3.11063 6.51264 3.2967 6.7C3.38957 6.79373 3.50006 6.86812 3.6218 6.91889C3.74353 6.96966 3.87411 6.9958 4.00599 6.9958C4.13787 6.9958 4.26844 6.96966 4.39018 6.91889C4.51192 6.86812 4.62241 6.79373 4.71528 6.7L8.34164 3.07L9.14084 3.86C9.21127 3.9291 9.30055 3.97579 9.39744 3.99418C9.49433 4.01257 9.59449 4.00184 9.6853 3.96335C9.77611 3.92485 9.8535 3.8603 9.90773 3.77785C9.96195 3.69539 9.99057 3.59871 9.98999 3.5V1C9.98999 0.734784 9.88474 0.48043 9.69739 0.292893C9.51004 0.105357 9.25594 0 8.99099 0H6.4935C6.39914 0.0107769 6.30967 0.0477393 6.23518 0.106716C6.16069 0.165692 6.10416 0.244335 6.07195 0.333768C6.03975 0.423201 6.03315 0.519865 6.05292 0.612849C6.07268 0.705834 6.11802 0.791438 6.18381 0.86L6.93305 1.65Z"
      fill={`${props.color ? props.color : '#3F4F5C'}`}
    />
    <path
      d="M8.99099 6C8.72604 6 8.47194 6.10536 8.28459 6.29289C8.09724 6.48043 7.99199 6.73478 7.99199 7V8H1.998V2H2.997C3.26195 2 3.51605 1.89464 3.7034 1.70711C3.89075 1.51957 3.996 1.26522 3.996 1C3.996 0.734784 3.89075 0.48043 3.7034 0.292893C3.51605 0.105357 3.26195 0 2.997 0H0.998999C0.734048 0 0.479949 0.105357 0.2926 0.292893C0.105251 0.48043 0 0.734784 0 1V9C0.0005487 9.13231 0.0273213 9.26319 0.0787664 9.38507C0.130211 9.50694 0.205306 9.61738 0.2997 9.71C0.393046 9.80268 0.503751 9.87601 0.625467 9.92577C0.747182 9.97554 0.877514 10.0008 1.00899 10H9.00098C9.13246 10.0008 9.26279 9.97554 9.38451 9.92577C9.50622 9.87601 9.61693 9.80268 9.71027 9.71C9.80286 9.61656 9.87611 9.50574 9.92583 9.38391C9.97554 9.26207 10.0007 9.13161 9.99998 9V7C9.99999 6.86783 9.97382 6.73698 9.92299 6.61499C9.87217 6.49301 9.79769 6.38232 9.70386 6.28933C9.61003 6.19634 9.49872 6.12289 9.37636 6.07323C9.254 6.02357 9.12302 5.99868 8.99099 6Z"
      fill={`${props.color ? props.color : '#3F4F5C'}`}
    />
  </SvgIcon>
);

export default Openpdp;
