/* eslint-disable react/prop-types */
import { Box, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { DateObject } from 'react-multi-date-picker';
import { isLimitDate } from '../../form-control/calendar/helper';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import Icon from '@ep/insight-ui/icons/Icon';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-bettween',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  height100: {
    height: '100%',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
  },
  subtextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));
const generateDateObject = (val: string, format = 'DD/MM/YYYY'): DateObject => {
  return new DateObject({ date: val, format: format });
};
export interface IValueDateRange {
  date_from: string;
  date_to: string;
  defaultFormatReturn?: string;
  formatDateView?: string;
  disabledChooseStartDate?: boolean;
  minDate?: string;
  maxDate?: string;
}
const DateRangeFormat = (props: IPropsFormat<IValueDateRange>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const date_from = _.get(props, ['value', 'date_from'], '');
  const defaultFormatReturn = _.get(props, ['value', 'defaultFormatReturn'], 'YYYY-MM-DD');
  const formatDateView = _.get(props, ['value', 'formatDateView'], 'MMM DD, YYYY');
  const date_to = _.get(props, ['value', 'date_to'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const subtextValue = _.get(props, ['value', 'subtext'], '');
  const getDate = (date: string) => {
    if (date || date !== '1970-01-01 00:00:00') {
      return moment(date, defaultFormatReturn).format(formatDateView);
    }
    return '';
  };
  const dateToMoment = moment(date_to, defaultFormatReturn);

  const backbone = React.useContext(TableBackboneContext);
  const dateFromField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'date_from'], '');
  const dateToField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'date_to'], '');
  const dateSyncStatus = _.get(
    props,
    ['data', 'data_status', dateFromField, 'status'],
    _.get(props, ['data', 'data_status', dateToField, 'status'], null),
  );
  const dateSyncUpdatedAt = _.get(
    props,
    ['data', 'data_status', dateFromField, 'updated_at'],
    _.get(props, ['data', 'data_status', dateToField, 'updated_at'], null),
  );
  const dateSyncErrorMessage = _.get(
    props,
    ['data', 'data_status', dateFromField, 'error_message'],
    _.get(props, ['data', 'data_status', dateToField, 'error_message'], ''),
  );

  const subtext = React.useMemo(() => {
    if (dateSyncStatus == 2) {
      return (
        <Box className={classes.subtextContainer}>
          <Tooltip title={dateSyncErrorMessage} classes={{ tooltip: classes.tooltip }} placement="bottom">
            <Box className={classes.syncStatusText}>
              <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
              <span>
                Sync failed at:{' '}
                {moment(dateSyncUpdatedAt).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Box>
          </Tooltip>
          {subtextValue ? (
            <>
              <span>|</span>
              <span>{subtextValue}</span>
            </>
          ) : null}
        </Box>
      );
    }
    return <span>{subtextValue}</span>;
  }, [props, dateSyncStatus, dateSyncUpdatedAt]);

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems={'center'} className={clsx(classes.format, classes.height100)}>
        <Grid
          container
          alignItems={'flex-start'}
          direction={'column'}
          wrap={'nowrap'}
          className={clsx(classes.styleText, classes.height100)}
        >
          <Grid item xs={12} className={classes.height100}>
            <span className={classes.hashtag}>{hashtag}</span>
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            {`${date_from ? getDate(date_from) : ''} - ${
              isLimitDate(date_to, dateToMoment) ? 'No limit' : getDate(date_to)
            }`}
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            <Box className={classes.subtext}>{subtext}</Box>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default DateRangeFormat;
