import { colors, noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import moment from 'moment';

export const calendarStyle = {
  boxShadow: 'none !important',
  width: '100% !important',
  '& .rmdp-day, & .rmdp-week-day': {
    height: '32px',
    width: '34px',
  },
  ...(ff.mobile_interaction_zone
    ? {
        '&.mobile .rmdp-day, &.mobile .rmdp-week-day': {
          height: 'calc((100vw - 48px)/7)',
          width: 'calc((100vw - 48px)/7)',
        },
      }
    : {}),
  '& .rmdp-calendar': {
    margin: 0,
    padding: 0,
    width: '100%',
  },
  '& .rmdp-header': {
    marginTop: 0,
    height: 'auto',
    '& > div': {
      height: '32px',
    },
  },
  '& .rmdp-header-values': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
  '& .rmdp-day-picker': {
    padding: 0,
    gap: 16,
    '& div': {
      width: '100%',
      margin: `0px !important`,
    },
    '& .rmdp-week:nth-of-type(1)': {
      padding: '8px 0',
    },
    // '& .rmdp-range:nth-child(1)':{
    //   color: '#fff',
    //   background: '#006EC6',
    //   borderRadius: 4,
    // }
  },
  '& .rmdp-week-day': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: ' 16px',
    color: '#8C98A4',
    textTransform: 'uppercase',
  },
  '& .rmdp-arrow-container': {
    width: 32,
    height: 32,
    margin: 0,
    alignItems: 'center',
    '& .rmdp-arrow': {
      border: 'solid black',
      borderWidth: ' 0 3px 3px 0',
      borderRadius: '1px',
      height: '6px',
      width: '6px',
      marginTop: -2,
    },
    '&.rmdp-left, &.rmdp-right': {
      top: '50%',
    },
  },
  '& .rmdp-arrow-container:hover': {
    background: 'transparent',
    boxShadow: 'none',
  },
  '& .rmdp-day.rmdp-deactive': {
    pointerEvents: 'none',
    color: colors.text.disabled,
    '& span': {
      backgroundColor: 'transparent',
      color: colors.text.disabled,
      '&.rmdp-range-child, &.selected-one.start, &.selected-one.end': {
        backgroundColor: '#FAFCFF',
        color: '#7AB5E5',
      },
    },
  },
  '& .rmdp-day': {
    '& span': {
      ...noSelectStyled,
    },
    // default
    backgroundColor: 'transparent',
    boxShadow: 'none',
    '&:not(.rmdp-disabled):not(.rmdp-day-hidden)': {
      '& span:hover': {
        backgroundColor: '#E4E7E9',
        color: '#000',
        borderRadius: '4px',
      },
    },
    '&.rmdp-selected span:not(.highlight)': {
      boxShadow: 'none',
    },
    '&:not(.rmdp-deactive)': {
      '& span': {
        borderRadius: '0px',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        '&.selected-one': {
          background: '#0074d9 !important',
          color: '#fff  !important',
          borderRadius: 4,
          '&.start': {
            borderRadius: '4px 0 0 4px  !important',
          },
          '&.end': {
            borderRadius: '0 4px 4px 0  !important',
          },
          '&.end.start': {
            borderRadius: ' 4px !important',
          },
          '&:hover': {
            background: '#338ED8 !important',
          },
        },
      },
      '&:not(.rmdp-today)': {
        '& span': {
          '&.rmdp-range-child': {
            color: colors.text.highLight,
            background: '#EBF6FF',
            fontWeight: '500',
            '&:hover': {
              background: '#CCE3F5 !important',
              color: `${colors.text.highLight}  !important`,
            },
          },
        },
      },
    },

    '&:not(.rmdp-deactive).rmdp-today': {
      '& span': {
        background: '#ED5C10 !important',
        color: '#fff !important',
        borderRadius: '50% !important',
        '&:hover': {
          background: '#F17D40 !important',
        },
      },
      '&.rmdp-range': {
        '&:hover': {
          '&.start, &.end': {
            background: '#0074d9 !important',
            color: '#fff  !important',
          },
          '&.start': {
            borderRadius: '4px 0 0 4px  !important',
          },
          '&.end': {
            borderRadius: '0 4px 4px 0  !important',
          },
        },
        background: '#EBF6FF',
        '& span': {
          '&.rmdp-range-child': {
            background: '#ED5C10',
            color: '#fff',
            borderRadius: '50%',
            '&:hover': {
              background: '#ED5C10 !important',
              color: '#fff !important',
            },
          },
        },
      },
      '&.start,&.end': {
        '&:hover span': {
          background: 'transparent !important',
        },
        '& span': {
          color: '#fff',
          background: 'transparent',
        },
      },
    },
  },
};
export const calendarSingleStyle = {
  '& .rmdp-day': {
    '&': {
      width: '34px !important',
      height: '32px!important',
      color: colors.text.default,
    },
    '&:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
      backgroundColor: '#E4E7E9',
      color: colors.text.default,
    },
    '&:not(.rmdp-today) span': {
      borderRadius: '4px !important',
    },
    '&:not(.rmdp-deactive).rmdp-today': {
      '& span': {
        backgroundColor: ' #ED5C10 !important',
        '&:hover': {
          backgroundColor: ' #F17D40 !important',
        },
      },
      '&.rmdp-selected span': {
        backgroundColor: ' #0074d9 !important',
        // boxShadow: '0 0 3px #8798ad',
        borderRadius: '4px !important',
        color: ' white',
        '&:hover': {
          backgroundColor: ' #338ED8 !important',
        },
      },
    },
    '&.rmdp-selected span': {
      backgroundColor: ' #0074d9 !important',
      color: ' white !important',
      '&:hover': {
        backgroundColor: ' #338ED8 !important',
      },
    },
  },
};

export const noLimitDate = '31/12/2222';
export const isLimitDate = (dateString, date: moment.Moment) => {
  // if date null or empty string is no limit
  if (!dateString) return true;
  const pointDateCheckLimit = moment(noLimitDate, 'DD/MM/YYYY');
  return date.isAfter(pointDateCheckLimit);
};
