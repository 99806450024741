import * as React from 'react';
import { debounce } from 'lodash';
import clsx from 'clsx';
import Error from '@ep/insight-ui/icons/svg/Error';
import { makeStyles } from '@material-ui/core/styles';
import { InputAdornment, InputProps, OutlinedInput, Typography, FormHelperText } from '@material-ui/core';
import { textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { CreateCSSProperties } from '@material-ui/styles';

interface PropsStyle {
  inputNoShadow: boolean;
  fullWidthInput: boolean;
  line: number;
}

/** Style */
const useStyles = makeStyles(() => ({
  title: {
    marginBottom: '6px',
  },
  textStyle: (props: PropsStyle) => ({
    ...(textFieldStyle as CreateCSSProperties),
    width: '100%',
    '&:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
    '&.Mui-disabled': {
      pointerEvents: 'none',
      '&.Mui-disabled .eip1-MuiOutlinedInput-notchedOutline': {
        borderColor: '#C2C7CB',
      },
      '& input': {
        backgroundColor: '#FBFBFC',
      },
    },
    '& input': {
      padding: '0 10px',
      height: '32px',
      caretColor: '#006EC6',
      width: props.fullWidthInput ? '100%' : '208px',
    },
    '&.eip1-MuiOutlinedInput-multiline': {
      padding: '10px 10px',
      minHeight: '32px',
      caretColor: '#006EC6',
      width: props.fullWidthInput ? '100%' : '208px',
      alignItems: 'flex-start',
      '& textarea': {
        lineHeight: '20px',
        height: props.line > 0 ? `${props.line * 20}px !important` : '20px !important',
      },
    },
  }),
  formHelper: {
    marginRight: 0,
    marginLeft: 0,
    fontSize: '10px',
    color: '#D4290D',
    '& svg': {
      width: '9px',
      height: '9px',
      marginRight: '5px',
    },
  },
}));

type PositionUnitType = 'start' | 'end';

export interface TextFormProps extends InputProps {
  label?: string;
  fullWidth?: boolean;
  setValue: (v: string) => void;
  unit?: string;
  positionUnit?: PositionUnitType;
  height?: string;
  dissable?: boolean;
  error?: boolean;
  helperText?: string;
  noShadow?: boolean;
  className?: string;
  fullWidthInput?: boolean;
  line?: number;
  tmpFilterModel?: any;
}

const TextForm = ({
  label,
  value,
  fullWidth = true,
  unit,
  positionUnit = 'end',
  setValue,
  dissable = false,
  error,
  helperText,
  noShadow = false,
  className = '',
  fullWidthInput = false,
  line = -1,
  ...rest
}: TextFormProps) => {
  const classes = useStyles({ inputNoShadow: noShadow, fullWidthInput, line });
  const ref = React.useRef(null);

  React.useEffect(() => {
    ref.current.value = value;
  }, [value]);

  const handleChange = React.useCallback(
    debounce((value) => {
      setValue(value);
    }, 150),
    [rest.tmpFilterModel],
  );

  //Handle error and correct text
  return (
    <React.Fragment>
      {label && (
        <Typography className={classes.title} variant="h6">
          {label}
        </Typography>
      )}
      <OutlinedInput
        inputRef={ref}
        error={error}
        disabled={dissable}
        className={clsx(className, classes.textStyle)}
        fullWidth={fullWidth}
        onChange={(e) => {
          handleChange(e.target.value);
        }}
        startAdornment={unit && positionUnit === 'start' && <InputAdornment position="end">{unit}</InputAdornment>}
        endAdornment={unit && positionUnit === 'end' && <InputAdornment position="end">{unit}</InputAdornment>}
        {...rest}
      />
      {error && (
        <FormHelperText className={`${classes.formHelper}`}>
          <Error />
          {helperText}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

export default TextForm;
