import { isFormulaField, toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { get, escape, snakeCase, groupBy, set, mapValues, omit, cloneDeep, pick, omitBy } from 'lodash';
import moment from 'moment';
import { getChannelCellUpdate } from '../../channel';

type fetchFun = (request: RequestInfo) => Promise<Response>;

type eTableRequest = {
  eTableConfig: Record<string, any>;
  url: string;
  method: string;
  headers: Headers;
  body: Record<string, any>;
};

export function cohortColumn(handleRaw: (fetch: fetchFun, request: eTableRequest) => Promise<Response>, { headers }) {
  return async (originData, { config, contextQuery, cellUpdate, pageRequests }, next) => {
    const mapping = get(config, 'mapping', {});
    const availableColumns = get(config, 'dimension', [])
      .concat(get(config, 'attribute', []))
      .concat(get(config, 'metric', []));

    const cohortColumns: [string, any][] = Object.entries(mapping)
      .filter(([k, v]) => {
        const valueApiField = get(v, 'valueGetter.value');
        return availableColumns.includes(k) && /^=\s*COHORT\(/.test(valueApiField);
      })
      .map(([k, v]) => {
        return [k, cloneDeep(v)] as [string, any];
      });
    // precondition
    if (cohortColumns.length == 0) return await next(originData, { config, contextQuery, cellUpdate, pageRequests });

    const tableId = config.tableId;
    const datetimeFieldRequest = get(config, ['system', 'datetimeField'], 'created_datetime');
    const eTablePrimaryKeys = get(config, 'primaryKeys', []);

    for (const [k, v] of cohortColumns) {
      mapping[k].valueGetter = mapValues(v.valueGetter, (k) => {
        return '="..."';
      });
      set(mapping, [k, 'staticValue', 'isSkipElasticity'], true);
    }
    config.mapping = mapping;

    const { columns: nextColumns, data } = await next(originData, { config, contextQuery, cellUpdate, pageRequests });

    const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
    let params = set(contextQuery, '_eip.src0', 'cohort');

    const shiftAmount = -1;
    const shiftUnit = 'days';
    let currentFilters = get(params, 'filter.filters', []);
    const gap = moment(params.to).diff(moment(params.from), shiftUnit);
    const dateTo = moment(params.from).add(shiftAmount, shiftUnit);
    const dateFrom = moment(dateTo).subtract(gap, shiftUnit);

    currentFilters = currentFilters
      .filter((f) => f.field !== datetimeFieldRequest)
      .concat([
        { field: datetimeFieldRequest, operator: '>=', value: dateFrom.format('YYYY-MM-DD 00:00:00') },
        { field: datetimeFieldRequest, operator: '<=', value: dateTo.format('YYYY-MM-DD 23:59:59.999') },
      ]);
    params = set(params, 'filter.filters', currentFilters);
    delete params.from;
    delete params.to;

    const cohortConfig = cloneDeep(config);
    cohortConfig.mapping = mapValues(cohortConfig.mapping, (v, k) => {
      // if the k is not in the cohortColum, remove all the property of valueGetter which is a formula
      const columnCohort = cohortColumns.find(([k1, v]) => k1 == k);
      if (!columnCohort) {
        v.valueGetter = omitBy(v.valueGetter, (val) => isFormulaField(val));
        v.cellFormat = v.propertyType === 'dimension' ? 'oneDimension' : 'oneMetric';
        v.staticValue = {};
        return v;
      } else {
        const [k1, v1] = columnCohort;
        const { apiColumn } = toValue(v1.valueGetter.value, {}, true);
        v1.valueGetter.value = apiColumn;
        const foundIndex = get(params, 'groupBy.aggregations', []).findIndex((f) => f.field === apiColumn);
        if (foundIndex > -1) {
          params = set(
            params,
            `groupBy.aggregations.${foundIndex}.func`,
            get(cohortConfig, ['groupBy', 'aggregations', k1, 'func'], null),
          );
        }
        return v1;
      }
    });
    cohortConfig.dateRange = {
      dateFrom: dateFrom.format('YYYY-MM-DD 00:00:00'),
      dateTo: dateTo.format('YYYY-MM-DD 23:59:59.999'),
    };

    const separator = '|';

    setTimeout(
      () =>
        handleRaw(null, {
          eTableConfig: cohortConfig,
          url: endpoint,
          method: 'POST',
          headers: headers,
          body: params,
        }).then((result) => {
          const rows = get(result, 'data.rows', []);

          const rowGroupByKey = rows.reduce((carry, row) => {
            const keyStr = eTablePrimaryKeys.map((f) => row[f]).join(separator);
            const keys = eTablePrimaryKeys.reduce((accum, f) => {
              return { ...accum, [f]: row[f] };
            }, {});

            return {
              ...carry,
              [keyStr]: cohortColumns.map(([k, v]) => {
                return {
                  keys,
                  keyId: keyStr,
                  updatePath: [k],
                  data: get(row, ['eData', k], {}),
                };
              }),
            };
          }, {});

          const channel = getChannelCellUpdate(tableId);
          console.info('cohort row group', rowGroupByKey);
          channel.postMessage({
            update: rowGroupByKey,
            error: null,
          });
        }),
      0,
    );

    return {
      columns: nextColumns,
      data,
    };
  };
}

async function process(context: {}, el: { data: any }) {}
