import * as React from 'react';
import { get } from 'lodash';
import { Control, Controller } from 'react-hook-form';

import { makeStyles, Box } from '@material-ui/core';

import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';

import Textarea from './textarea';
import TextInput from './text-input';
import Select from './select';
import LabelSelect from './label-select';
import TagEditor from './tag-editor';
import Compact from './compact';
import {
  INPUT_TYPE_DATA_OBJECT,
  INPUT_TYPE_PLACEHOLDER,
  SELECT_COMPONENT_NAME,
  LABEL_SELECT_COMPONENT_NAME,
  TAG_EDITOR_COMPONENT_NAME,
  TEXT_INPUT_COMPONENT_NAME,
  TEXTAREA_COMPONENT_NAME,
  INPUT_TYPE_SELECT_FROM_ETABLE,
  INPUT_TYPE_HELPER_TEXT,
  CALENDAR_COMPONENT_NAME,
  BUDGET_EDITOR_COMPONENT_NAME,
  BIDDING_EDITOR_COMPONENT_NAME,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_DISPLAY_AS_COMPACT,
  INIT_VALUE_TYPE_FROM_ETABLE,
  MULTIPLE_SELECT_COMPONENT_NAME,
  INPUT_TYPE_SELECT_FROM_TAG,
  INPUT_TYPE_REGEX,
  TOGGLE_COMPONENT_NAME,
  INPUT_TYPE_TOOLTIP,
  FILTER_COMPONENT_NAME,
} from '../../../utils/constant';
import { HiddenInput } from './hidden';
import Calendar from './calendar';
import BudgetEditor from './budget-editor';
import BiddingEditor from './bidding-editor';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import MultipleSelect from './multiple-select';
import TagSelection from './tag-selection';
import Toggle from './toggle';
import FilterInput from './filter';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import Icon from '@ep/insight-ui/icons/Icon';

const Empty = () => {
  return <></>;
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    '.horizontal &': {
      flexDirection: 'row',
    },
  },
  label: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    '.horizontal &': {
      width: '30%',
    },
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  content: {
    '.horizontal &': {
      width: '70%',
    },
  },
  helperText: {
    fontSize: '10px',
    color: '#0000008a',
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
  },
  tooltip: {
    '& p': {
      margin: 0,
      padding: '2px 4px',
    },
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
});

export const fields = {
  [TEXTAREA_COMPONENT_NAME]: Textarea,
  [TEXT_INPUT_COMPONENT_NAME]: TextInput,
  [SELECT_COMPONENT_NAME]: Select,
  [MULTIPLE_SELECT_COMPONENT_NAME]: MultipleSelect,
  [INPUT_TYPE_SELECT_FROM_ETABLE]: Select,
  [INPUT_TYPE_SELECT_FROM_TAG]: TagSelection,
  // [LABEL_SELECT_COMPONENT_NAME]: LabelSelect,
  hidden: HiddenInput,
  [TAG_EDITOR_COMPONENT_NAME]: TagEditor,
  [INPUT_TYPE_DATA_OBJECT]: Empty,
  [CALENDAR_COMPONENT_NAME]: Calendar,
  [BUDGET_EDITOR_COMPONENT_NAME]: BudgetEditor,
  [BIDDING_EDITOR_COMPONENT_NAME]: BiddingEditor,
  [TOGGLE_COMPONENT_NAME]: Toggle,
  [FILTER_COMPONENT_NAME]: FilterInput,
};

const FormField = ({
  configuration,
  control,
  rowData,
  ...rest
}: {
  configuration: Record<string, any>;
  control?: Control;
  rowData: Record<string, any>;
}) => {
  const classes = useStyles();

  const Component = React.useMemo(() => {
    const type = configuration?.input_type;

    // Special Component of selectFromETable
    const isSelectFromETable = type === INPUT_TYPE_SELECT_FROM_ETABLE;
    const isCompact =
      (get(configuration, ['field_configuration'], []).find((el) => el.key === INPUT_TYPE_DISPLAY_AS) || { value: '' })
        .value === INPUT_TYPE_DISPLAY_AS_COMPACT;
    if (isSelectFromETable && isCompact) {
      return Compact;
    }

    if (type && fields[type]) return fields[type];
    return Empty;
  }, [configuration]);

  if (!control) {
    return <Component configuration={configuration}></Component>;
  }

  const tooltip = React.useMemo(() => {
    return get(configuration, [INPUT_TYPE_TOOLTIP], '');
  }, [configuration]);

  const placeholder = React.useMemo(() => {
    return get(configuration, [INPUT_TYPE_PLACEHOLDER], '');
  }, [configuration]);

  const helperText = React.useMemo(() => {
    return get(configuration, [INPUT_TYPE_HELPER_TEXT], '');
  }, [configuration]);

  const regex = React.useMemo(() => {
    return get(configuration, [INPUT_TYPE_REGEX], '');
  }, [configuration]);

  const fieldKey = React.useMemo(() => {
    return get(configuration, ['field_key'], '');
  }, [configuration]);

  const handleStaticInitValue = (value) => {
    const regex = [/^\d+$/, /^(true|false)$/, /^\[.*\]$/, /^\{.*\}$/];
    if (regex.some((el) => el.test(value))) {
      return safeJsonParse(value, value);
    }
    return value;
  };

  const initialValue = React.useMemo(() => {
    if (configuration?.init_value_type != INIT_VALUE_TYPE_FROM_ETABLE)
      return handleStaticInitValue(configuration?.init_value_value);
    if (String(configuration?.init_value_value).startsWith('='))
      return toValue(configuration.init_value_value, rowData, true);
    if ([undefined, null].indexOf(rowData[configuration?.init_value_value]) > -1) return '';
    return rowData[configuration?.init_value_value];
  }, [configuration, rowData]);

  return (
    <Controller
      name={configuration.field_key}
      control={control}
      render={({ field }) => {
        const hideHelperFields = ['hidden', INPUT_TYPE_DATA_OBJECT];
        return (
          <ConditionalWrap
            condition={!hideHelperFields.includes(configuration.input_type)}
            wrap={(children) => (
              <Box className={classes.container}>
                {configuration.label && (
                  <div className={classes.label}>
                    <span>{configuration.label}</span>
                    {tooltip ? (
                      <>
                        <StyledTooltip
                          title={
                            <div className={classes.tooltip}>
                              {tooltip ? <p className={classes.tooltipContent}>{tooltip}</p> : null}
                            </div>
                          }
                          placement={'right'}
                        >
                          <Icon type={'ic/mi:circle-information/#737373'} />
                        </StyledTooltip>
                      </>
                    ) : null}
                  </div>
                )}
                <div className={classes.content}>
                  {children}
                  <div className={classes.helperText}>{helperText}</div>
                </div>
              </Box>
            )}
          >
            <Component
              {...field}
              {...rest}
              configuration={configuration}
              placeholder={placeholder}
              helperText={helperText}
              rowData={rowData}
              initialValue={initialValue}
              fieldName={field.name}
              regex={regex}
              fieldKey={fieldKey}
              tooltip={tooltip}
            ></Component>
          </ConditionalWrap>
        );
      }}
    ></Controller>
  );
};

export default FormField;
