import Icon from '@ep/insight-ui/icons/Icon';
import { Box, Button, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
      marginTop: '24px',
      marginBottom: 0,
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      marginTop: ' 8px',
      marginBottom: '24px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    marginBottom: '8px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  img: {
    '&.banner': {
      width: '100%',
      height: '100%',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      minWidth: '480px',
      minHeight: '230px',
    },
  },
  content: {},
  buttonMobile: {
    width: '100%',
    height: '40px',
    marginTop: '30px',
  },
});

const StoreConnected = ({ onClose }: any) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;

  return (
    <Box alignItems="center" justifyContent="center" className={classes.content}>
      <Box my={'8px'}>
        <Icon type="successConnectStore" />
      </Box>
      <p className={`${classes.text} title`}>Your store is connected</p>
      <p className={`${classes.text} text`}>
        We need a little time to load and analyse your data from the Seller Center.
      </p>
      {!isMobile ? (
        <Button variant="contained" color="primary" className={classes.button} onClick={onClose}>
          Start boost your business
        </Button>
      ) : (
        <Button variant="contained" color="primary" className={classes.buttonMobile} onClick={onClose}>
          Start boost your business
        </Button>
      )}
    </Box>
  );
};
export default StoreConnected;
