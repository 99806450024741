import * as React from 'react';
import { get } from 'lodash';

import { eipRequest as request, EIP_CONSTANT, aim } from '@eip/next/lib/main';
import { AUTH0_LOGOUT_URI, getAuth0 } from '../global';
import { usePassport } from '@ep/insight-ui/elements/app-menu/hooks/use-passport';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';

const USER_WORKSPACE_ACCESSIBLE_ENDPOINT =
  EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/workspace-accessible';

export const useWorkspace = (token) => {
  const userSettings = aim.getUserSettings();
  const [workspaces, setWorkspaces] = React.useState([]);
  const [currentWorkspace, setCurrentWorkspace] = React.useState(null);
  const currentWorkspaceDetail = workspaces.find((ws) =>
    ff.next_left_menu
      ? ws.workspace_domain == currentWorkspace
      : userSettings?.profile?.workspace_id === ws.workspace_id,
  );
  const [loadedWorkspaces, setLoadedWorkspaces] = React.useState(false);
  const retryTimesRef = React.useRef(0);

  const { setUserWorkspace } = usePassport();

  const setLocalWorkspace = (workspace) => {
    const userSettings = aim.getUserSettings();
    aim.setUserSettings({
      ...userSettings,
      profile: {
        ...userSettings?.profile,
        workspace_id: workspace.workspace_id,
        workspace_domain: workspace.workspace_domain,
        favorite: workspace.favorite,
      },
    });
  };

  React.useEffect(() => {
    const workspaceChannel = new BroadcastChannel('workspace');

    const fetchWorkspaces = () => {
      request
        .get(USER_WORKSPACE_ACCESSIBLE_ENDPOINT)
        .then((res) => {
          if (res.success) {
            const workspacesResponse = res.data;
            setWorkspaces(workspacesResponse);
            setLoadedWorkspaces(true);
            const userSettings = aim.getUserSettings();
            const workspace = window.location.href.match(/[a-zA-Z0-9._!@#$%^&*()]+(?=\/page)/)?.[0];
            const currentWorkspace = get(userSettings, ['profile', 'workspace_domain'], '');
            const currentWsDetail = res.data.find((el) => el.workspace_id == userSettings.profile.workspace_id);
            aim.setUserSettings({
              ...userSettings,
              profile: {
                ...userSettings.profile,
                workspace_homepage: currentWsDetail?.homepage,
                favorite: currentWsDetail?.favorite,
              },
              workspaces: workspacesResponse,
            });
            if (workspace !== currentWorkspace) {
              const workspaceDetail = workspacesResponse.find((ws) => ws.workspace_domain == workspace);
              if (workspaceDetail) {
                setUserWorkspace(workspaceDetail.workspace_id);
                setLocalWorkspace(workspaceDetail);
                workspaceChannel.postMessage(workspaceDetail);
              }
            }
          } else {
            if (retryTimesRef.current > 3) {
              getAuth0().then((auth0) => {
                auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
              });
              return;
            }
            setTimeout(fetchWorkspaces, 3000);
            retryTimesRef.current++;
          }
        })
        .catch(() => {
          if (retryTimesRef.current > 3) {
            getAuth0().then((auth0) => {
              auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
            });
            return;
          }
          setTimeout(fetchWorkspaces, 3000);
          retryTimesRef.current++;
        })
        .finally(() => {
          workspaceChannel.close();
          setTimeout(() => {
            request.get('/api/v1/reload-cache?q=workspace-accessible').then(() => {
              setTimeout(() => {
                request.get(USER_WORKSPACE_ACCESSIBLE_ENDPOINT);
              }, 1000);
            });
          }, DELAY_REQUEST_ADDITIONAL_DATA);
        });
    };

    if (token) {
      fetchWorkspaces();
    }
  }, [token]);

  if (ff.next_left_menu) {
    React.useEffect(() => {
      // sync workspace between tab
      const workspaceChannel = new BroadcastChannel('workspace');

      workspaceChannel.onmessage = (event) => {
        setLocalWorkspace(event.data);
      };

      const handleTabChange = (event) => {
        if (event.target.hidden === true) return;
        const workspace = window.location.href.match(/[a-zA-Z0-9._!@#$%^&*()]+(?=\/page)/)?.[0];

        const userSettings = aim.getUserSettings();
        const workspaceDetail = get(userSettings, ['workspaces'], []).find((ws) => ws.workspace_domain == workspace);
        const currentWorkspace = get(userSettings, ['profile', 'workspace_domain'], '');
        workspaceChannel.postMessage(workspaceDetail);
        if (currentWorkspace !== workspace && workspaceDetail) {
          setLocalWorkspace(workspaceDetail);
          setUserWorkspace(workspaceDetail.workspace_id);
        }
      };

      document.addEventListener('visibilitychange', handleTabChange);

      return () => {
        document.removeEventListener('visibilitychange', handleTabChange);
        workspaceChannel.close();
      };
    }, []);
  }

  return {
    workspaces,
    setCurrentWorkspace,
    currentWorkspaceDetail,
    loadedWorkspaces,
  };
};
