import * as React from 'react';
import { get, groupBy, set } from 'lodash';

import { Box, Button, Grid, makeStyles } from '@material-ui/core';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import MenuPropertiesSelection from '../menu-properties-selection';
import { ACCUMULATIVE_CONFIG } from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles(() => ({
  containers: {
    padding: '8px',
  },
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 8px',
  },
  button: {
    height: '100%',
  },
}));

const AccumulativeDialog = ({ handleClose }: any) => {
  const classes = useStyles();

  const [searchTerm, setSearchTerm] = React.useState('');

  const backbone = React.useContext(TableBackboneContext);
  const mappings = backbone.getConfig('mapping', {});
  const view = backbone.getConfig('view', {});

  const metrics = backbone.getConfig('metric');
  const chartConfig = backbone.getConfig('chartConfig', {});
  const chartType = get(chartConfig, ['config', 'chartType'], '');

  const selections = [
    ...(chartType === 'heatmap'
      ? []
      : [
          {
            label: 'None',
            value: '',
          },
        ]),
    {
      label: 'Sum',
      value: 'sum',
    },
    {
      label: 'Average',
      value: 'avg',
    },
  ];

  const [menu, setMenu] = React.useState(() => {
    return metrics.map((metric) => {
      const customSelections = Object.keys(get(mappings, [metric, 'valueGetter'], {})).reduce(
        (carry, key) => {
          if (/^accumulative\[\'.*\'\]$/.test(key)) {
            carry.push({
              label: key.replace("accumulative['", '').replace("']", ''),
              value: key,
            });
          }
          return carry;
        },
        [
          {
            label: 'None',
            value: '',
          },
        ],
      );
      return {
        label: get(mappings, [metric, 'title'], ''),
        value: get(view, [ACCUMULATIVE_CONFIG, metric], ''),
        id: metric,
        selections: customSelections.length > 1 ? customSelections : selections,
      };
    });
  });

  const filteredMenu = React.useMemo(() => {
    return menu.filter((item) => item.label.toLowerCase().includes(searchTerm.toLowerCase()));
  }, [menu, searchTerm]);

  const handleSubmit = () => {
    const updatedAccumulateConfig = groupBy(menu, 'id');
    Object.entries(updatedAccumulateConfig).forEach(([key, value]) => {
      updatedAccumulateConfig[key] = get(value, [0, 'value']);
    });

    const view = backbone.getConfig('view');
    const newAccumulateConfig = {
      ...get(view, [ACCUMULATIVE_CONFIG], {}),
      ...updatedAccumulateConfig,
    };
    set(view, ACCUMULATIVE_CONFIG, newAccumulateConfig);

    const views = backbone.getConfig('views');
    for (const v of views) {
      if (v.id === view.id) {
        set(v, ACCUMULATIVE_CONFIG, newAccumulateConfig);
      }
    }

    backbone.updateConfig({
      view,
      views,
    });

    backbone.reloadData('table');

    handleClose();
  };

  return (
    <Box className={classes.containers}>
      <MenuPropertiesSelection
        title={'metric'}
        menu={filteredMenu}
        onInputChange={setSearchTerm}
        onMenuChange={setMenu}
      />
      <Grid Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
        <Grid item>
          <Button className={classes.button} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AccumulativeDialog;
