import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Ascending = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99318 11.9964L5.99318 4.42005L7.28186 5.70943C7.46997 5.89764 7.72511 6.00338 7.99114 6.00338C8.25717 6.00338 8.5123 5.89764 8.70041 5.70943C8.88852 5.52122 8.9942 5.26595 8.9942 4.99977C8.9942 4.7336 8.88852 4.47833 8.70041 4.29012L5.70348 1.29157C5.61061 1.19788 5.50012 1.12352 5.37839 1.07278C5.25665 1.02204 5.12608 0.99591 4.9942 0.99591C4.86232 0.99591 4.73175 1.02204 4.61002 1.07278C4.48828 1.12352 4.37779 1.19788 4.28493 1.29157L1.28799 4.29012C1.19485 4.38331 1.12096 4.49395 1.07055 4.61571C1.02015 4.73747 0.994201 4.86798 0.994201 4.99977C0.994201 5.13157 1.02015 5.26207 1.07055 5.38384C1.12096 5.5056 1.19485 5.61624 1.28799 5.70943C1.38114 5.80262 1.49171 5.87655 1.61341 5.92699C1.73511 5.97742 1.86554 6.00338 1.99727 6.00338C2.12899 6.00338 2.25943 5.97742 2.38112 5.92699C2.50282 5.87655 2.6134 5.80262 2.70654 5.70943L3.99522 4.42005L3.99522 11.9964C3.99522 12.2615 4.10047 12.5157 4.28782 12.7032C4.47516 12.8906 4.72926 12.9959 4.9942 12.9959C5.25915 12.9959 5.51324 12.8906 5.70058 12.7032C5.88793 12.5157 5.99318 12.2615 5.99318 11.9964Z"
      fill={props.color ? props.color : '#3F4F5C'}
    />
  </SvgIcon>
);
export default Ascending;
