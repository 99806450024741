import * as React from 'react';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';
import { cloneDeep, isEqual, omit } from 'lodash';

import { Box, makeStyles, Tooltip } from '@material-ui/core';

import { NodeEditContext } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { SHORTCUT_ACTIONS } from '@ep/insight-ui/system/helper/constant';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';

import ItemTypes from '../../drag-n-drop/ItemTypes';
import ConditionalWrap from '../../util/conditional-wrap';
import Dropdown from '../../dropdown-menu/dropdown';
import Icon from '@ep/insight-ui/icons/Icon';
import { boolean, number, string } from 'yup';
import ButtonDropdown from '../../button/button-dropdown';
import Card from '@ep/insight-ui/elements/drag-n-drop/drag-drop-horizontal';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { useAtomValue } from 'jotai';
import Wrapper from '../../etable2/wrapper';

const useStyles = makeStyles(() => ({
  shortcutButtonContainer: {
    position: 'relative',
    '&:hover .close': {
      opacity: 0.2,
    },
    '& .close': {
      display: 'flex',
      position: 'absolute',
      cursor: 'pointer',
      top: 0,
      right: 0,
      opacity: 0,
      transition: 'all 0.2s',
      '&:hover': {
        opacity: 1,
      },
    },
    '&:hover .drag': {
      opacity: 0.2,
    },
    '& .drag': {
      display: 'flex',
      position: 'absolute',
      cursor: 'default',
      top: 0,
      left: 0,
      opacity: 0,
      transition: 'all 0.2s',
      '&:hover': {
        opacity: 1,
      },
    },
  },
  containerButton: {
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      whiteSpace: 'nowrap',
    },
  },
  popover: {
    '& .eip1-MuiPopover-paper': {
      background: ' #FFFFFF',
      boxShadow: ' 0px 6px 12px rgb(140 152 164 / 25%)',
      padding: 0,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#babac038',
        marginLeft: 20,
      },
      '&::-webkit-scrollbar-track:hover': {
        backgroundColor: '#babac038',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '5px solid #F8FAFD',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4',
      },
      '&::-webkit-scrollbar-button ': { display: 'none' },
    },
  },
  active: {
    color: `${colors.text.highLight}`,
    '&:hover': {
      color: `${colors.text.highLight}`,
      backgroundColor: `#CCE3F5`,
    },
    '&.eip1-MuiButton-active': {
      color: `white`,
    },
  },
  tooltip: {
    background: '#253746',
    fontSize: '11px',
    lineHeight: '14px',
    maxWidth: '350px',
  },
}));

type TypeShortcutAction = {
  name: string;
  order?: number;
  shortcut?: boolean;
  hideLabel?: boolean;
};

const convertShortcutActions = (shortcutActions) => {
  return Object.values(shortcutActions)
    .filter((act) => act.shortcut)
    .sort((a, b) => a.order - b.order);
};

const ButtonOptionShortcut = ({ RenderOptionComponent, getFilterData, sortModelDataConvert, menu }: any) => {
  const classes = useStyles();

  const backboneContext = React.useContext(TableBackboneContext);
  const { isEditMode } = React.useContext(NodeEditContext);
  const tableId = useAtomValue(eTableAtom.tableId);

  const shortcutActions = backboneContext.getConfig(SHORTCUT_ACTIONS, {});

  const [actions, setActions] = React.useState<TypeShortcutAction[]>(() => {
    return convertShortcutActions(shortcutActions);
  });

  React.useEffect(() => {
    const converted = convertShortcutActions(shortcutActions);
    const isBackboneUpdated = actions.some((action) => {
      return Object.keys(action).some((key) => {
        return key != 'order' && action[key] != shortcutActions[action.name]?.[key];
      });
    });
    if (converted.length != actions.length || isBackboneUpdated) {
      setActions(converted);
    }
  }, [shortcutActions]);

  const handleUpdateBackbone = (newActions) => {
    const updateShortcutActions = Object.entries(shortcutActions).reduce((carry, [key, act]) => {
      const updatedAct = newActions.find(({ name }) => name === key);
      const updatedActIndex = newActions.findIndex(({ name }) => name === key);
      if (updatedAct) {
        act = cloneDeep(updatedAct);
        act.order = updatedActIndex > -1 ? updatedActIndex : null;
      } else {
        act.order = null;
        act.shortcut = null;
      }
      return {
        ...carry,
        [key]: act,
      };
    }, {});

    backboneContext.changeConfig(SHORTCUT_ACTIONS, updateShortcutActions);
  };

  return (
    <DndProvider context={window} backend={HTML5Backend}>
      <Box display={'flex'} alignItems={'center'} sx={{ marginRight: '8px' }}>
        {actions.map((act) => {
          const item = (menu || []).find((i) => i.name === act.name);
          if (!item) return null;
          return (
            <ButtonOptionShortcutItem
              item={item}
              isEditMode={isEditMode}
              RenderOptionComponent={RenderOptionComponent}
              getFilterData={getFilterData}
              sortModelDataConvert={sortModelDataConvert}
              setActions={setActions}
              handleUpdateBackbone={handleUpdateBackbone}
              actions={actions}
              classes={classes}
              act={act}
              backbone={backboneContext}
              key={act.name}
              tableId={tableId}
            />
          );
        })}
      </Box>
    </DndProvider>
  );
};

const ButtonOptionShortcutItem = ({
  item,
  isEditMode,
  RenderOptionComponent,
  getFilterData,
  sortModelDataConvert,
  setActions,
  handleUpdateBackbone,
  actions,
  classes,
  act,
  backbone,
  tableId,
}: any) => {
  const [openPopup, setOpenPopup] = React.useState(false);
  const renderComponent = RenderOptionComponent(item.name);
  const visibility = item.name;
  let isButtonActive = false;
  if (item.name === 'filter') {
    const filterData = (getFilterData() || []).filter((data) => (!data.isHidden && !isEditMode) || isEditMode);
    isButtonActive = filterData.length > 0;
  }
  if (item.name === 'sort') {
    isButtonActive = sortModelDataConvert.length > 0;
  }
  const icon = String(item.icon)
    .split('/')
    .map((i, index) => (index != 2 || !openPopup ? i : 'white'))
    .join('/');
  React.useEffect(() => {
    const handleEvent = (e) => {
      setOpenPopup(e?.detail.value);
    };

    window.addEventListener(`${tableId}_visible_${visibility}`, handleEvent);

    return () => {
      window.removeEventListener(`${tableId}_visible_${visibility}`, handleEvent);
    };
  }, []);
  return (
    <ConditionalWrap
      condition={isEditMode}
      key={item.name}
      wrap={(children) => {
        return (
          <Card
            id={item.name}
            findIndex={(id) => actions.findIndex((act) => act.name === id)}
            moveCard={(order, originalOrder) => {
              const clonedActions = cloneDeep(actions);
              clonedActions.splice(order, 0, clonedActions.splice(originalOrder, 1)[0]);
              setActions(clonedActions);
            }}
            onDrop={() => handleUpdateBackbone(actions)}
          >
            {children}
          </Card>
        );
      }}
    >
      <Box className={classes.shortcutButtonContainer}>
        <MonitorContainer
          component={'div'}
          mId={`advanced-${item.name}`}
          mLabel={`advanced-${item.name}`}
          className={classes.containerButton}
        >
          {renderComponent ? (
            <Dropdown
              alignMenu={'right'}
              defaultOpen={openPopup}
              handleOnClosed={() => setOpenPopup(false)}
              label={act.hideLabel ? '' : item.title}
              iconStart={icon}
              disabledClickContentClose
              classNamePopover={classes.popover}
              sizeIconStart={'14px'}
              onClick={() => {
                setOpenPopup(true);
              }}
              className={isButtonActive ? classes.active : ''}
              tooltip={item.tooltip ? item.tooltip : act.hideLabel ? item.title : ''}
            >
              <Wrapper level={2}>{renderComponent}</Wrapper>
            </Dropdown>
          ) : (
            <ConditionalWrap
              condition={item.tooltip || act.hideLabel}
              wrap={(children) => {
                return (
                  <Tooltip title={item.tooltip ? item.tooltip : item.title} classes={{ tooltip: classes.tooltip }}>
                    {children}
                  </Tooltip>
                );
              }}
            >
              <ButtonDropdown
                label={act.hideLabel ? '' : item.title}
                onClick={() => item.onClick()}
                startIcon={<Icon type={icon} size={'14px'} />}
              />
            </ConditionalWrap>
          )}
        </MonitorContainer>
        {isEditMode ? (
          <React.Fragment>
            <Box
              className={'close'}
              onClick={() => {
                const newActions = actions.filter((act) => act.name !== item.name);
                handleUpdateBackbone(newActions);
              }}
            >
              <Icon type={'ic/material-symbols:cancel/xanadu'} size={'12px'} />
            </Box>
            <Box className={'drag'}>
              <Icon type={'ic/material-symbols:drag-pan-rounded/xanadu'} size={'12px'} />
            </Box>
          </React.Fragment>
        ) : null}
      </Box>
    </ConditionalWrap>
  );
};

export default ButtonOptionShortcut;
