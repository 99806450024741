import * as React from 'react';
import * as _ from 'lodash';
// import { funcConfigs } from './hooks';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { ITreeMapDataChart, IOption } from '../type';
import { Bullet } from './bullet-template';
import { makeStyles } from '@material-ui/core';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../chart-size';
import { breakPointSize } from '../chart-size';
import { ChartTabularData, BulletChartOptions } from '@carbon/charts/interfaces';
const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '90%',
  },
  titleChart: {
    color: '#8C98A4',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.25,
    letterSpacing: '0.00735em',
    marginBottom: '10px',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
  wrapperRes: {
    height: '100%',
  },
  totalValueLine: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '1rem',
    color: '#253746',
    margin: 0,
    '& span': {
      fontSize: '1rem',
      marginLeft: '15px',
      color: '#0BA373',
      fontWeight: 500,
    },
    '& .currency': {
      fontSize: '1rem',
      color: '#253746',
      marginLeft: '1px',
      marginRight: '5px',
    },
    '& svg': {
      marginRight: 4,
    },
    '@media (max-width:767px)': {
      marginTop: 0,
      left: 0,
    },
    '& .percent': {
      display: 'block',
      '& span': {
        margin: 0,
      },
    },
  },
  legendLine: {
    display: 'flex',
    flexDirection: 'row',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    lineHeight: 2.2,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    '@media (max-width:767px)': {
      top: 0,
    },
    '& .legendItem': {
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      padding: '5px',
      transition: 'all .3s ease',
      '& span': {
        width: '12px',
        height: '12px',
        marginRight: '8px',
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
    '& .legendItem:hover': {
      background: '#F8FAFD',
      borderRadius: '5px',
      textAlign: 'center',
    },
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
});

type IBulletChart = {
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: ChartTabularData;
  optionList: BulletChartOptions;
  percentList?: { [key: string]: string };
  keyChart?: string;
  stateChart: IStateChartValue;
};
const BulletChart = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  percentList,
  stateChart,
}: IBulletChart) => {
  const classes = useStyles();
  const divRef = React.useRef<HTMLDivElement>();
  const legendRef = React.useRef<HTMLDivElement>(null);
  const [chartSize, setchartSize] = React.useState<TSize>('large');
  const ref = React.createRef();

  React.useEffect(() => {
    //
  }, [dataList]);

  return (
    <div ref={divRef} style={{ height: '100%' }}>
      <ChartSize size={chartSize}>
        <ChartState stateChart={stateChart} title={title} value={value} currency={currency} percent={percent}>
          <Bullet data={dataList} options={optionList} refElement={ref} />
        </ChartState>
      </ChartSize>
    </div>
  );
};

export default BulletChart;
