import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../text-style/Typography';
import { Button, ButtonProps } from '@material-ui/core';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';

/** Style */
const useStyles = makeStyles(() => ({
  button: {
    padding: '9px 18px',
    borderStyle: 'dashed',
    '&:active': {
      color: '#FFFFFF',
      backgroundColor: colors.action.primaryDefault,
    },
  },
  typography: {
    lineHeight: '0',
  },
}));

interface ButtonActionProps extends ButtonProps {
  label: string;
  icon?: IconType;
}

const ButtonAction: React.FC<ButtonActionProps> = ({ label, icon, ...rest }: ButtonActionProps) => {
  const classes = useStyles();
  return (
    <Button
      className={classes.button}
      variant="outlined"
      startIcon={icon && <Icon type={icon} size="12px" />}
      {...rest}
    >
      <Typography className={classes.typography} variant="h5">
        {label}
      </Typography>
    </Button>
  );
};

export default ButtonAction;
