import * as React from 'react';
import { get, uniq, zipObject, set, uniqBy } from 'lodash';
import produce from 'immer';

import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import { TableCompactFactoryContext } from '@ep/insight-ui/elements/table/compact-factory';
import { TableCompactFactoryContext as TableCompactFactoryContext2 } from '@ep/insight-ui/elements/table/compact-factory2';
import { makeTable } from '@ep/insight-ui/elements/table/table-container';
import { CAMPAIGN_HISTORY } from '@ep/insight-ui/system/block/etable/table-config/history-compact-table';
import { ACTION_CATEGORY_STATUS } from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { syncValueFormat } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';

const getHistoryCompactConfig = (options, rowData) => {
  const { field_name, dimension_type } = options;
  const adsTool = rowData && rowData[`ADTOOL.ads_tool`] ? rowData[`ADTOOL.ads_tool`].split('_')[0] : 'SHP';
  const master_object_type = `${adsTool}_${dimension_type}`;

  const configuration = produce(CAMPAIGN_HISTORY, (draft) => {
    if (dimension_type === 'ADS_CAMPAIGN') {
      delete draft.mapping.adObject;
      delete draft.mapping.keyword;
    } else if (dimension_type === 'ADS_OBJECT') {
      delete draft.mapping.keyword;
    }
  });

  return {
    config: {
      configuration,
      addons: {
        'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
          const mapping = get(backbone, ['config', 'mapping'], {});
          const { dateFrom, dateTo } = get(backbone, ['config', 'dateRange'], {});

          const logFields = uniq(
            Object.values(mapping).reduce((a, b) => {
              const valueGetter = Object.values(get(b, 'valueGetter', {})).filter((el: string) =>
                el.startsWith('changelog_ads_ops_dimension'),
              );
              return [...a, ...valueGetter];
            }, []),
          );

          const finalParams = produce(params, (draft) => {
            draft.log = logFields;
            draft.currency = 'USD';
            draft.hiddenFilter.currency = 'USD';
            draft.pagination.limit = 1000;
            draft.filter = {
              combinator: 'and',
              filters: [
                {
                  field: 'changelog_ads_ops_dimension.simulation_status',
                  operator: '=',
                  value: 'SYNCED',
                },
                {
                  field: 'changelog_ads_ops_dimension.master_object_type',
                  operator: '=',
                  value: master_object_type,
                },
                ...(rowData && rowData.hasOwnProperty([`${dimension_type}.id`])
                  ? [
                      {
                        field: 'changelog_ads_ops_dimension.master_object_id',
                        operator: '=',
                        value: rowData[`${dimension_type}.id`],
                      },
                    ]
                  : []),
                ...(field_name && field_name.length > 0
                  ? [
                      {
                        field: 'changelog_ads_ops_dimension.field_name',
                        operator: Array.isArray(field_name) ? 'in' : '=',
                        value: field_name,
                      },
                    ]
                  : []),
              ],
            };
            delete draft.metric;
            delete draft.from;
            delete draft.to;
          });

          const result = await originRequest(finalParams);

          const masterData = get(result, 'data.masterData', {});
          const mainHeaders = get(result, 'data.headers', {});
          const rows = get(result, 'data.rows', []);

          const entityRelations = mainHeaders.filter((i) => i.indexOf('.id') > 0).map((i) => i.replace('.id', ''));
          const mapEntity = entityRelations.reduce((carry, entityType) => {
            const headers = masterData[entityType]?.headers.map((i) => `${entityType}.${i}`) || [];
            const rows = masterData[entityType]?.rows || [];
            return {
              ...carry,
              [entityType]: rows.reduce((carry, row, index) => {
                const obj = zipObject(headers, row);
                const id = obj[`${entityType}.id`];
                return { ...carry, [id]: obj };
              }, {}),
            };
          }, {});

          const enrichedRows = rows.map((i) => {
            let item: any = { ...i };
            entityRelations.forEach((entityType) => {
              item = { ...item, ...mapEntity[entityType][i[`${entityType}.id`]] };
            });

            return {
              ...rowData,
              ...item,
              currency: params.hiddenFilter?.currency,
            };
          });

          set(result, 'data.rows', enrichedRows);
          set(result, 'data.headers', enrichedRows[0] ? Object.keys(enrichedRows[0]) : mainHeaders);

          return result;
        },
      },
    },
  };
};

export const useWrapperFormat = ({ cellAction, myRef, value, node, backbone }: any) => {
  const { onToast } = useToast();

  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const { display: displayTable2 } = React.useContext(TableCompactFactoryContext2);

  const displayHistoryTableCompact = (options) => {
    const tableRender = makeTable(getHistoryCompactConfig(options, node.data));
    displayTable({
      anchorEl: myRef.current,
      tableConfig: getHistoryCompactConfig(options),
      tableEl: tableRender,
      visible: true,
      onClosed: () => undefined,
    });
    displayTable2({
      anchorEl: myRef.current,
      tableConfig: getHistoryCompactConfig(options),
      tableEl: tableRender,
      visible: true,
      onClosed: () => undefined,
    });
  };

  const enhanceCellAction = React.useMemo(() => {
    let enhancedActions = cellAction.map((el) => {
      if (el.id === 'history') {
        return {
          ...el,
          onSubmit() {
            displayHistoryTableCompact(el.payload.field);
          },
        };
      }
      if (el.id === 'retry_sync_value') {
        const {
          field_name: fieldName,
          dimension,
          id,
          conditional_field_name: conditionalFieldName,
        } = get(el, ['payload', 'field'], {});

        return {
          ...el,
          async onSubmit() {
            if ((fieldName || conditionalFieldName) && dimension && id) {
              const selectedRows = uniq([
                ...backbone.getSelectedRows().map((el) => el[id]),
                get(node, ['data', id], null),
              ]).filter((el) => !!el);
              const marketplaceCode = get(node, ['data', 'MARKETPLACE.marketplace_code'], '');
              const model = get(syncValueFormat, [marketplaceCode, dimension], '');

              const data = selectedRows.reduce((carry, modelId) => {
                if (conditionalFieldName && conditionalFieldName.length) {
                  const validData = conditionalFieldName
                    .filter((el) => {
                      if (!el.condition) return true;

                      return el.condition.every((c) => {
                        switch (c.operator) {
                          case 'CONTAIN':
                            return c.values.includes(node.data[c.field]);
                          case 'NOT_CONTAIN':
                            return !c.values.includes(node.data[c.field]);
                          default:
                            return true;
                        }
                      });
                    })
                    .map((el) => {
                      return {
                        fieldName: el.field_name,
                        marketplaceCode,
                        model,
                        modelId,
                      };
                    });

                  return [...carry, ...validData];
                }

                return [
                  ...carry,
                  {
                    fieldName,
                    marketplaceCode,
                    model,
                    modelId,
                  },
                ];
              }, []);
              const payload = {
                data,
                requestId: 'string',
                transactionPackaging: true,
                validateOnly: true,
              };

              try {
                const res = await request.post(
                  EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-retry-ads-attribute',
                  payload,
                );

                if (!res.success || +res.failedElement > 0) {
                  get(res, ['data'], []).forEach((el) => {
                    if (!el.success) {
                      onToast({
                        title: 'Failed',
                        messages: el.message,
                        variant: 'error',
                      });
                    }
                  });
                }

                if (res.success || +res.successfulElement > 0) {
                  onToast({
                    title: `Update ids ${data.map((el) => el.modelId).join(', ')} successfully!`,
                    variant: 'success',
                  });
                  backbone.reloadData('table');
                }
              } catch (e) {
                onToast({
                  title: 'Failed',
                  messages: e.message,
                  variant: 'error',
                });
              }
            }
          },
        };
      }
      if (el.category === ACTION_CATEGORY_STATUS && !el.onSubmit) {
        const updateFieldMapping = get(el, ['payload', 'field'], {});

        return {
          ...el,
          onSubmit: (data) => backbone.handleCellSubmit(data, updateFieldMapping),
        };
      }
      return el;
    });

    // deduplicate actions
    enhancedActions = uniqBy(enhancedActions, 'name');
    return enhancedActions;
  }, [cellAction, myRef.current]);

  return {
    enhanceCellAction,
  };
};
