import * as React from 'react';
import { NodeFactory } from './node/factory';
import { BlockFactoryContext, useLog, NodeEditContext } from '@eip/next/lib/main';
import { isEqual } from 'lodash';
import { ScriptEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/script-editor';

const log = useLog('dbf:editor:block-factory');

function BlockComponent({ blockData, chartLib }: { blockData: NodeData; chartLib: ChartLibComponent }) {
  return chartLib.render(null, blockData, null);
}

const BlockComponentPure = React.memo(BlockComponent, (prevProps, nextProps) => {
  return isEqual(prevProps.blockData, nextProps.blockData);
});

const ContextWrappedBlock = function ContextWrappedBlock({ blockData, chartLib }) {
  const context = React.useContext(NodeEditContext);

  const onTextChange = React.useCallback((...args) => {
    console.info('onTextChage', args);
  }, []);
  const onUpdateCustomAttributes = React.useCallback((...args) => {
    context.onUpdateCustomAttributes(...args);
  }, []);
  const initContext = React.useMemo(
    () => ({
      isEditMode: true,
      onTextChange: onTextChange,
      onUpdateCustomAttributes: onUpdateCustomAttributes,
    }),
    [],
  );
  return (
    <NodeEditContext.Provider value={initContext}>
      <BlockComponentPure blockData={blockData} chartLib={chartLib} />
    </NodeEditContext.Provider>
  );
};

// const BlockMemo = React.memo(ContextWrappedBlock, (prevProps, nextProps) => {
//   return true;
// });
const BlockMemo = ContextWrappedBlock;

export function BlockFactory({ blocks }: { blocks: NodeData[] }) {
  const factory = React.useContext(BlockFactoryContext);
  const getChartLib = React.useCallback((chartLibId) => {
    const foundChart = factory.find(chartLibId);
    if (foundChart) return foundChart.blockComponent || foundChart.component;
    else {
      console.info('not found chart', chartLibId);
      return null;
    }
  }, []);

  return (
    <React.Fragment>
      {blocks.map((blockData) => (
        <NodeFactory key={blockData.id} data={blockData}>
          <BlockMemo blockData={blockData} chartLib={getChartLib(blockData.chartLibId)} />
        </NodeFactory>
      ))}
      <ScriptEditor />
    </React.Fragment>
  );
}
