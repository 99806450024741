import { EIP_CONSTANT } from '@eip/next/lib/main';

export const COMPACT_AD_OBJECT_CONFIG = {
  title: 'Ad object',
  tableType: 'compact',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getProductData',
  },
  primaryKeys: ['PRODUCT.id'],
  searchHint: 'Looking for products',
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  sort: [{ field: 'PRODUCT.valid', sort: 'DESC' }],
  checkDisableRecord: (r) => r['PRODUCT.valid'] === 'invalid' || r['_sw.valid'] === 'invalid',
  mapping: {
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'PRODUCT',
      sortField: 'PRODUCT.name',
      valueGetter: {
        label: 'PRODUCT.name',
        subtext: 'PRODUCT.product_sid',
        valid: 'PRODUCT.valid',
        productId: 'PRODUCT.id',
        storefrontId: 'STOREFRONT.id',
        storefrontCurrency: 'STOREFRONT.currency',
        jsonTooltips: 'PRODUCT.valid_tooltips',
        ad_tool: 'ADTOOL.ads_tool',
        id: 'PRODUCT.name',
      },
    },

    // ============= ATTRIBUTE =============

    // ============= METRIC =============
    impression: {
      title: 'Impression',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'impression',
      valueGetter: {
        value: 'impression',
      },
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
    },
    ads_item_sold: {
      title: 'Ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    direct_gmv: {
      title: 'Direct ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    ads_order: {
      title: 'Ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_order',
      valueGetter: {
        value: 'ads_order',
      },
    },
    direct_ads_order: {
      title: 'Direct ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_order',
      valueGetter: {
        value: 'direct_order',
      },
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
    },
    cir: {
      title: 'CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cir',
      valueGetter: {
        value: 'cir',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
    },
    stock: {
      title: 'Stock',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'stock',
      valueGetter: {
        value: 'stock',
      },
    },
    discount: {
      title: 'Discount',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'discount',
      valueGetter: {
        value: 'discount',
      },
    },
    direct_roas: {
      title: 'Direct ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'direct_roas',
            valueGetter: {
              value: 'direct_roas',
            },
          }
        : {
            filterField: 'direct_roas',
            valueGetter: {
              // value: 'direct_roas',
            },
          }),
    },
    cpi: {
      title: 'CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'cpi',
            valueGetter: {
              value: 'cpi',
            },
          }
        : {
            filterField: 'cpi',
            valueGetter: {
              // value: 'cpi',
            },
          }),
    },
    direct_cr: {
      title: 'Direct CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'direct_cr',
            valueGetter: {
              value: 'direct_cr',
            },
          }
        : {
            filterField: 'direct_cr',
            valueGetter: {
              // value: 'direct_cr',
            },
          }),
    },
    direct_cpi: {
      title: 'Direct CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'direct_cpi',
            valueGetter: {
              value: 'direct_cpi',
            },
          }
        : {
            filterField: 'direct_cpi',
            valueGetter: {
              // value: 'direct_cpi',
            },
          }),
    },
    direct_cir: {
      title: 'Direct CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'direct_cir',
            valueGetter: {
              value: 'direct_cir',
            },
          }
        : {
            filterField: 'direct_cir',
            valueGetter: {
              // value: 'direct_cir',
            },
          }),
    },
    selling_price: {
      title: 'Selling price',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      ...(ff.add_two_fields_conversion
        ? {
            filterField: 'PRODUCT.price_postsub',
            valueGetter: {
              value: 'PRODUCT.price_postsub',
            },
          }
        : {
            filterField: 'selling_price',
            valueGetter: {
              // value: 'selling_price',
            },
          }),
    },
  },
  addons: {
    checkValidRow: (node) => !node.data['PRODUCT.valid'] || node.data['PRODUCT.valid'] !== 'invalid',
  },
};
