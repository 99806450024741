import { EIP_CONSTANT } from '@eip/next/lib/main';
import { METRIC_DASHBOARD_CONFIG } from './dashboard-config/metric-dashboard';
import { INTRADAY_DASHBOARD_CONFIG } from './dashboard-config/intraday-dashboard';
import { EDashboardChartLib } from './metric-dashboard';
/**
 * ff.revive_all_charts:start
 */
import { WATERFALL_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/waterfall';
import { TREEMAP_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/treemap';
import { SCATTER_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/scatter';
import { LINE_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/line';
import { GAUGE_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/gauge';
import { DOT_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/dot';
import { BILLING_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/billing';
import { AREA_CONFIG } from '@ep/insight-ui/elements/eDashboard/dashboard-config/area';
/**
 * ff.revive_all_charts:end
 */

/**
 * ff.mass_operation_management:start
 */
import { MASS_OPERATION_DASHBOARD_CONFIG } from './dashboard-config/mass-operation-dashboard';
/**
 * ff.mass_operation_management:end
 */

export default {
  blockType: 'eDashboard',
  label: 'Metric dashboard',
  blockComponent: EDashboardChartLib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 12,
  },
  packagedConfigs: {
    INTRADAY_DASHBOARD_CONFIG,
    METRIC_DASHBOARD_CONFIG,
    ...(ff.mass_operation_management ? { MASS_OPERATION_DASHBOARD_CONFIG } : {}),
    ...(ff.revive_all_charts
      ? {
          packagedConfigs: {
            INTRADAY_DASHBOARD_CONFIG,
            METRIC_DASHBOARD_CONFIG,
            WATERFALL_CONFIG,
            TREEMAP_CONFIG,
            SCATTER_CONFIG,
            LINE_CONFIG,
            GAUGE_CONFIG,
            DOT_CONFIG,
            BILLING_CONFIG,
            AREA_CONFIG,
          },
        }
      : {}),
  },
};
