import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Heading1 = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="2 2 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="3" width="2" height="10" rx="1" fill={props.color} />
    <rect x="6" y="3" width="2" height="10" rx="1" fill={props.color} />
    <rect x="12" y="3" width="2" height="10" rx="1" fill={props.color} />
    <rect x="2" y="9" width="2" height="6" rx="1" transform="rotate(-90 2 9)" fill={props.color} />
    <path
      d="M11 5C10.4477 5 10 4.55228 10 4C10 3.44772 10.4477 3 11 3L13 3C13.5523 3 14 3.44772 14 4C14 4.55228 13.5523 5 13 5L11 5Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Heading1;
