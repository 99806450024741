import { API_AUTHENTICATION, API_DATA_CENTER, API_EIP_MANAGER_URL, API_GRPC_GATEWAY } from '@ep/one/global';
import { random, range, uniqueId } from 'lodash';
import { rest } from 'msw';
import { v4 as uuid } from 'uuid';
import { tape } from './tape';

const API = {
  GET_CHANNEL: () => API_GRPC_GATEWAY + '/root-management/get-channel',
  GET_COUNTRY: () => API_GRPC_GATEWAY + '/root-management/get-country',
  GET_STOREFRONT: () => API_GRPC_GATEWAY + '/root-management/get-user-storefront',
  GET_TOOL: () => API_GRPC_GATEWAY + '/root-management/get-tool',
};
let handlers = [];

handlers.push(rest.get(API.GET_CHANNEL(), tape()));
handlers.push(rest.get(API.GET_COUNTRY(), tape()));
handlers.push(rest.get(API.GET_TOOL(), tape()));
handlers.push(rest.get(API.GET_STOREFRONT(), tape()));

handlers.push(rest.post(API_DATA_CENTER + '/metrics.jsp', tape()));
handlers.push(rest.post(API_DATA_CENTER + '/charts.jsp', tape()));

handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getAdsPlacementData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getAdsObjectData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getCampaignData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/ads-operation/create-dimension', tape()));

handlers.push(
  rest.get(
    API_GRPC_GATEWAY + '/programmatic/get-list-metric',
    tape((req, res, ctx) => {
      const params: any = req.url.searchParams;
      switch (params.get('objectType')) {
        case 'ADS_OBJECT':
          return import('./fixtures/list-metric-shp-ads-object.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'ADS_PLACEMENT': {
          return import('./fixtures/list-metric-shp-ads-placement.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        }
      }
    }),
  ),
);

handlers.push(
  rest.post(
    API_GRPC_GATEWAY + '/programmatic/create-script',
    tape(() => {
      return;
    }),
  ),
);
handlers.push(rest.post(API_GRPC_GATEWAY + '/programmatic/get-list-script-ads-object', tape()));
handlers.push(rest.get(API_GRPC_GATEWAY + '/programmatic/get-list-script', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getAdToolData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getProductData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getSuggestedAdsPlacementData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getCampaignData', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData', tape()));
handlers.push(rest.get(API_GRPC_GATEWAY + '/ads-operation/get-dimension-definition', tape()));
handlers.push(rest.post(API_GRPC_GATEWAY + '/mop-query/listing/getScriptData', tape()));
handlers.push(
  rest.post('https://staging-grpc-gateway.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-grpc-gateway.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);
handlers.push(
  rest.post('https://grpc-gateway.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-grpc-gateway.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);

handlers.push(
  rest.post('https://staging-datacenter.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-datacenter.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
  rest.get('https://staging-datacenter.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-datacenter.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);

handlers.push(
  rest.post('https://datacenter.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-datacenter.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
  rest.get('https://datacenter.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-datacenter.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);

handlers.push(
  rest.post('https://staging-eip-manager.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-eip-manager.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
  rest.get('https://staging-eip-manager.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-eip-manager.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);

handlers.push(
  rest.post('https://eip-manager.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-eip-manager.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify(req.body),
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
  rest.get('https://eip-manager.epsilo.io/*', (req, res, ctx) => {
    return ctx
      .fetch(`/staging-eip-manager.epsilo.io${req.url.pathname}${req.url.search}`, {
        method: 'GET',
        headers: {
          'content-type': 'application/json',
        },
      })
      .then((result) => result.json())
      .then((result) => res(ctx.json(result)));
  }),
);

tape.defaults.bypass = true;
// tape.defaults.replay = true;

// EIP_MANAGER_URL
// handlers = [];
if (false) {
  handlers = handlers.concat(
    // ep
    rest.post(API_EIP_MANAGER_URL + '/system_page/save_changeset', (req, res, ctx) => {
      // return;
      return res(ctx.json({}));
    }),
    rest.post(API_EIP_MANAGER_URL + '/user/save_changeset', (req, res, ctx) => {
      // return;
      return res(ctx.json({}));
    }),

    rest.post(API_EIP_MANAGER_URL + '/page/chunk', (req, res, ctx) => {
      const rbody: any = req.body;
      switch (rbody.id) {
        case 'ec22dd25-a14f-40d8-9199-a5b0517e835e':
          return import('./fixtures/pages/script-details.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'persona-ec22dd25-a14f-40d8-9199-a5b0517e835e':
          return import('./fixtures/pages/script-details.json').then(({ default: data }) => {
            const blockEid = rbody.id.replace('persona-', '');
            data.block[rbody.id] = {
              block_eid: rbody.id,
              block_type: 'user_persona',
              content: [blockEid],
              parent_id: data.block[blockEid].block_eid,
              parent_talble: data.block[blockEid].block_table,
              properties: {},
            };
            return res(ctx.json(data));
          });
        case '34c31cc3-0570-4aa9-bf69-d799a1e7f065':
          return import('./fixtures/pages/campaign-management.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'persona-34c31cc3-0570-4aa9-bf69-d799a1e7f065':
          return import('./fixtures/pages/campaign-management.json').then(({ default: data }) => {
            const blockEid = rbody.id.replace('persona-', '');
            data.block[rbody.id] = {
              block_eid: rbody.id,
              block_type: 'user_persona',
              content: [blockEid],
              parent_id: data.block[blockEid].block_eid,
              parent_talble: data.block[blockEid].block_table,
              properties: {},
            };
            return res(ctx.json(data));
          });
        case '6d8bb941-b290-4bd7-b597-e91704f1228e':
          return import('./fixtures/pages/campaign-management-issue.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'd7070db9-b225-4e1c-a317-fb050f1682ee':
          return import('./fixtures/pages/campaign-management-issue-saving-config.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'd237ba9c-9ce1-4a73-b888-c4106abd48db':
          return import('./fixtures/pages/company-management.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case '0f9aa561-6cc7-402d-a67d-162b472ff33d':
          return import('./fixtures/pages/campaign-management-issue-sunny.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'e0672112-e4ae-4e10-a811-e4b368f56243':
          return import('./fixtures/pages/campaign-management-issue-dec24.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'df9c3e94-597d-45f7-b9f2-9cadea4d9f10':
          return import('./fixtures/pages/campaign-management-issue-jan28.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'issue-with-saving-custom-view':
          return import('./fixtures/pages/campaign-management-issue-saving-view.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });

        case 'afdd53e6-49f9-4170-9212-bdeada713c0d':
        case 'persona-afdd53e6-49f9-4170-9212-bdeada713c0d':
          return import('./fixtures/pages/create-campaign.json').then(({ default: data }) => {
            const blockEid = rbody.id.replace('persona-', '');
            data.block[rbody.id] = {
              block_eid: rbody.id,
              block_type: 'user_persona',
              content: [blockEid],
              parent_id: data.block[blockEid].block_eid,
              parent_talble: data.block[blockEid].block_table,
              properties: {},
            };
            return res(ctx.json(data));
          });
        case '3f38d2fa-4eb5-47a7-b937-146872685fb5':
          return import('./fixtures/pages/script-logs.json').then(({ default: data }) => {
            return res(ctx.json(data));
          });
        case 'persona-3f38d2fa-4eb5-47a7-b937-146872685fb5':
          return import('./fixtures/pages/script-logs.json').then(({ default: data }) => {
            const blockEid = rbody.id.replace('persona-', '');
            data.block[rbody.id] = {
              block_eid: rbody.id,
              block_type: 'user_persona',
              content: [blockEid],
              parent_id: data.block[blockEid].block_eid,
              parent_talble: data.block[blockEid].block_table,
              properties: {},
            };
            return res(ctx.json(data));
          });

        default: {
          let pageId = rbody.id;
          const personaPage = /^persona-(.*)/.exec(pageId);
          if (personaPage && personaPage[1]) {
            pageId = personaPage[1];
          }

          if (pageId.indexOf('syspage-') !== 0) return;

          return ctx
            .fetch(`${tape.defaults.designSystemPageEndpoint}?page=${encodeURIComponent(pageId)}`, {
              method: 'GET',
              headers: {
                'content-type': 'application/json',
              },
            })
            .then((response) => response.json())
            .then((response) => {
              const block = response.block;
              block['persona-' + pageId] = {
                ...block[pageId],
                block_eid: 'persona-' + pageId,
                content: [pageId],
                parent_id: pageId,
                parent_table: 'eip_system_block',
                block_type: 'user_persona',
                table: 'eip_block',
              };
              return res(ctx.json({ block: block }));
            });
        }
      }
    }),
    rest.post('/design-system-page', async (req, res, ctx) => {
      const response = await ctx
        .fetch(tape.defaults.designSystemPageEndpoint || '/design-system-page', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(req.body),
        })
        .then((response) => response.json());

      return res(ctx.json(response));
    }),

    rest.post(API_EIP_MANAGER_URL + '/system_page/list', (req, res, ctx) => {
      return;
      return import('./fixtures/eip/system-page-list.json').then(({ default: data }) => {
        return res(ctx.json(data));
      });
    }),
    rest.post(API_EIP_MANAGER_URL + '/user/sync_records', (req, res, ctx) => {
      return;
      const rbody: any = req.body;
      if (rbody.requests.length === 0) {
        return res(ctx.json({ records: {} }));
      } else {
        return import('./fixtures/eip/user-menu.json').then(({ default: data }) => {
          return res(ctx.json(data));
        });
      }
    }),
  );

  const cachedPagination = {};
  handlers.push(
    rest.post(API_EIP_MANAGER_URL + '/user/get_page_navigation', (req, res, ctx) => {
      return import('./fixtures/navigation.json').then((cachedPagination) => {
        const rbody = req.body as { request: any[] };
        return res(
          ctx.delay(random(200, 3000)),
          ctx.json({
            blocks: rbody.request.reduce((carry, pointer) => {
              return {
                ...carry,
                [pointer.id]: cachedPagination[pointer.id]
                  ? cachedPagination[pointer.id]
                  : { blocks: {}, navigation: [] },
              };
            }, {}),
          }),
        );
      });
    }),
    rest.post(API_EIP_MANAGER_URL + '/user/get_page_navigation_dynamic', (req, res, ctx) => {
      console.info(cachedPagination);
      const rbody = req.body as { request: any[] };

      rbody.request.forEach((pointer) => {
        const blockId = pointer.id;
        if (!cachedPagination[blockId]) {
          const menu = [];
          const childBlocks = range(random(0, 1_000, false)).reduce((carry, i) => {
            const blockEid = uuid();
            menu.push(blockEid);
            const title = `Page ${uniqueId('title_')}`;
            return {
              ...carry,
              [blockEid]: {
                blockEid: blockEid,
                blockType: 'user_system_link',
                parentId: blockId,
                parentTable: 'eip_block',
                properties: { title },
              },
            };
          }, {});
          cachedPagination[blockId] = {
            blocks: childBlocks,
            navigation: menu,
          };
        }
      });

      return res(
        ctx.delay(random(200, 3000)),
        ctx.json({
          blocks: rbody.request.reduce((carry, pointer) => {
            return { ...carry, [pointer.id]: cachedPagination[pointer.id] };
          }, {}),
        }),
      );
    }),
  );
}

handlers.push(
  rest.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/recovery/browser', (req, res, ctx) => {
    return import('./fixtures/ory/ory-recover-request-flow.json').then((data) => {
      return res(ctx.json(data));
    });
  }),
  rest.post(API_AUTHENTICATION + '/.ory/kratos/public/self-service/recovery', (req, res, ctx) => {
    return import('./fixtures/ory/ory-recover-send-email.json').then((data) => {
      return res(ctx.json(data));
    });
  }),
  rest.get(API_AUTHENTICATION + '/.ory/kratos/public/self-service/settings/flows', (request, response, ctx) => {
    const url = request.url;
    const id = url.searchParams.get('id');

    switch (id) {
      case '4b5e25c8-0bb1-4099-892c-44ebd34e9869': {
        return import('./fixtures/ory/ory-recover-successfully.json').then((data) => {
          return response(ctx.json(data));
        });
      }
      case 'invalid-recovery-flow-id': {
        return import('./fixtures/ory/ory-recover-fail.json').then((data) => {
          return response(ctx.json(data));
        });
      }
    }
  }),
  rest.post(API_AUTHENTICATION + '/.ory/kratos/public/self-service/settings', (request, response, ctx) => {
    const data: any = request.body;
    switch (data.password) {
      case 'abc123': {
        return import('./fixtures/ory/ory-update-password-fail.json').then((data) => {
          return response(ctx.json(data));
        });
      }
      default: {
        return import('./fixtures/ory/ory-update-password-success.json').then((data) => {
          return response(ctx.json(data));
        });
      }
    }
  }),
);

// handlers = [];
export default handlers;
