import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Box, Grid } from '@material-ui/core';
import Dropdown from '../../dropdown-menu/dropdown';
import { scrollbarStyles } from '@ep/insight-ui/lib/epsilo-theme';
import MenuList from '../../list-control/menu-list';
import { DropdownMenuDataType } from '../../list-control/menu-list/menu-list';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
const Accordion = withStyles({
  root: {
    marginBottom: 32,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:last-child': {
      marginBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      marginTop: 0,
      marginBottom: 24,
    },
    '&.Mui-disabled': {
      background: 'none',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: 'transparent !important',
    backgroundColor: 'transparent !important',
    minHeight: 20,
    marginBottom: 0,
    '& .eip1-MuiAccordionSummary-content.Mui-expanded': {
      margin: 0,
    },
    '& .eip1-MuiAccordionSummary-content': {
      margin: 0,
    },
    '&$expanded': {
      minHeight: 20,
      marginBottom: 16,
    },
    '.eres--small &$expanded': {
      marginBottom: 8,
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    display: 'block',
  },
}))(MuiAccordionDetails);

interface IProps {
  level: string;
}
/** Style */
const useStyles = makeStyles(() => ({
  iconToggle: {
    width: (props: IProps) => (props.level === '1' ? '12px' : '10px'),
    height: '100%',
    marginRight: '10px',
  },
  label: {
    fontStyle: ' normal',
    fontWeight: 500,
    fontSize: ' 18px',
    lineHeight: '20px',
  },
  paper: {
    ...scrollbarStyles,
  },
  details: {
    width: '100%',
    '&.full': {
      width: '100%',
    },
    '&.normal': {
      width: '75%',
    },
    '&.half': {
      width: '50%',
      minWidth: '600px',
    },
  },
  spacer: {
    marginBottom: '0px',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  headerRight: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  arrowdown: {
    height: '13px',
  },
  arrowright: {
    height: '15px',
  },
}));

interface AccordionCustomProps extends AccordionProps {
  label: string;
  labelSize?: number;
  dataMenu?: DropdownMenuDataType[][];
  defaultExpanded?: boolean;
  rightElement?: JSX.Element;
  detailContentSize?: 'full' | 'half' | 'normal';
  disabledMarginX?: boolean;
  level?: string;
}

const levelSize = {
  '1': {
    fontSize: '24px',
    lineHeight: '28px',
  },
  '2': {
    fontSize: '18px',
    lineHeight: '20px',
  },
};

const ExpansionForm: React.FC<AccordionCustomProps> = ({
  label = '',
  labelSize = 18,
  dataMenu,
  children,
  rightElement = null,
  defaultExpanded = false,
  detailContentSize = 'half',
  disabledMarginX = false,
  level = '2',
  ...rest
}: AccordionCustomProps) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';

  const classes = useStyles({ level });
  const [expanded, setExpanded] = React.useState<boolean>(defaultExpanded);
  React.useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const handleToggle = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion {...rest} square expanded={expanded}>
      <AccordionSummary>
        <Grid container>
          <Grid item xs={12} container justifyContent={'space-between'} wrap={'nowrap'} alignItems={'center'}>
            <Grid item className={classes.spacer} style={{ width: '100%' }}>
              <Box display={'inline-flex'} alignItems="center" onClick={handleToggle}>
                <span className={classes.iconToggle}>
                  <Icon
                    type={expanded ? 'arrowdown' : 'arrowright'}
                    className={classes[expanded ? 'arrowdown' : 'arrowright']}
                    style={{ width: '100%' }}
                  />
                </span>
                <span
                  className={classes.label}
                  style={{ fontSize: levelSize[level].fontSize, lineHeight: levelSize[level].lineHeight }}
                >
                  {label}
                </span>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={`${classes.details} ${isMobile ? 'full' : detailContentSize}`}>
        <div
          style={{
            marginRight: !disabledMarginX && !isMobile && '32px',
            marginLeft: !disabledMarginX && !isMobile && '17px',
            paddingLeft: isMobile && '22px',
            ...(ff.ui_my_profile_mobile
              ? {
                  paddingRight: isMobile && '22px',
                }
              : {}),
          }}
        >
          {children}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionForm;
