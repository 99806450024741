import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import * as request from '@ep/insight-ui/sw/util/request';
import { set, get, zipObject } from 'lodash';
import { stripUniversalPrefix } from '../../util/column';

type epsiloTableObjectType = {
  prefixFE: string;
  queryEpMatching: string;
  queryAlias?: string;
  queryAttributes?: string;
  queryMetrics?: string;
  queryListing?: string;
  queryStorefrontListing?: string;
  export?: string;
};

export enum EpsiloTableObject {
  PERFORMANCE = 'PERFORMANCE',
  OPERATION = 'OPERATION',
  QUALITY = 'QUALITY',
}

export const epsiloTableEndpoint: { [key in EpsiloTableObject]: epsiloTableObjectType } = {
  [EpsiloTableObject.PERFORMANCE]: {
    prefixFE: 'performance',
    queryEpMatching: 'v2_performance.jsp',
    queryAlias: 'insight.jsp',
    queryAttributes: 'v2_performance_attributes.jsp',
    queryMetrics: 'v2_performance_metrics.jsp',
    queryListing: 'v2_performance_listing.jsp',
    queryStorefrontListing: 'v2_performance_storefront.jsp',
    export: 'v2_performance_download_get.jsp',
  },
  [EpsiloTableObject.OPERATION]: {
    prefixFE: 'operation',
    queryEpMatching: 'v2_operational.jsp',
    queryAttributes: 'v2_operational_attributes.jsp',
    queryMetrics: 'v2_operational_metrics.jsp',
    queryListing: 'v2_operational_listing.jsp',
    export: 'v2_operational_download_get.jsp',
  },
  [EpsiloTableObject.QUALITY]: {
    prefixFE: 'quality',
    queryEpMatching: 'v2_quality.jsp',
    queryAlias: 'v2_quality.jsp',
    queryAttributes: 'v2_quality_attributes.jsp',
    queryMetrics: 'v2_quality_metrics.jsp',
    queryStorefrontListing: 'v2_quality_storefront.jsp',
    export: 'v2_quality_download_get.jsp',
  },
};
export const checkEpsiloTableEndpoint = (
  endpoint: string,
  tableObject?: EpsiloTableObject | EpsiloTableObject[],
): boolean => {
  const queryParams = getQueryParams(endpoint);

  if (!tableObject && queryParams.namespace) {
    return true;
  }

  let compareTables = [].concat(tableObject);
  if (!tableObject) {
    compareTables = Object.keys(epsiloTableEndpoint);
  }
  return [].concat(compareTables).some((el) => {
    return (
      String(endpoint).includes(epsiloTableEndpoint[el].queryEpMatching) ||
      String(endpoint).includes(epsiloTableEndpoint[el].queryAlias)
    );
  });
};

export interface ResourceMetricInterface {
  label: string;
  value: string;
  prefix_value: string;
  variable_type: string;
}
let cachedInsightMetrics = null;
const getPerformanceMetrics = (queryStr): Promise<Array<ResourceMetricInterface>> => {
  if (cachedInsightMetrics) return Promise.resolve(cachedInsightMetrics);
  cachedInsightMetrics = new Promise(async (resolve) => {
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.PERFORMANCE.queryMetrics + '?' + queryStr)
      .then((res) => {
        cachedInsightMetrics = res.map((el) => ({
          label: el.name + ` (insight.${el.code})`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
        resolve(cachedInsightMetrics);
      })
      .catch((e) => {
        cachedInsightMetrics = null;
        console.info(e);
        resolve([]);
      });
  });
  return cachedInsightMetrics;
};

export const getQueryParams = (endpoint: string) => {
  const queryParams = new Proxy(new URLSearchParams(String(endpoint).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });

  return queryParams;
};

let cachedOperationMetrics = null;
const getOperationMetrics = (queryStr): Promise<Array<ResourceMetricInterface>> => {
  if (cachedOperationMetrics) return Promise.resolve(cachedOperationMetrics);
  return new Promise(async (resolve) => {
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + epsiloTableEndpoint.OPERATION.queryMetrics + '?' + queryStr)
      .then((res) => {
        cachedOperationMetrics = res.map((el) => ({
          label: el.name + ` - [${epsiloTableEndpoint.OPERATION.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
        resolve(cachedOperationMetrics);
      })
      .catch((e) => {
        console.info(e);
        resolve([]);
      });
  });
};

const cachedAsyncResult = {};
export function cacheAsyncRequest(name, asyncRequest) {
  // return (): Promise<any> => {
  if (cachedAsyncResult[name]) return Promise.resolve(cachedAsyncResult[name]);

  cachedAsyncResult[name] = new Promise(async (resolve) => {
    const result = await asyncRequest();
    cachedAsyncResult[name] = result;
    resolve(result);
  });

  return cachedAsyncResult[name];
  // };
}

const getEpsiloMetric = (tableObj: EpsiloTableObject, queryStr = '') => {
  return cacheAsyncRequest('metric_' + tableObj, () => {
    const metricSet = epsiloTableEndpoint[tableObj];
    return request
      .get(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/' + metricSet.queryMetrics + '?' + queryStr)
      .then((res) => {
        return res.map((el) => ({
          label: el.name + ` - [${metricSet.prefixFE}] ${el.code}`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
      })
      .catch((e) => {
        console.info(e);
        return [];
      });
  });
};

export function produceQueryResult(rawResult, mapping = {}, skipRowMapping = false) {
  let foundCurrencyField;
  for (const column of Object.values(mapping)) {
    const foundItem = Object.entries(get(column, 'valueGetter', {})).find(([k, v]) => k === 'currency');
    if (foundItem) {
      foundCurrencyField = foundItem[1] !== '' ? foundItem[1] : null;
    }
  }

  let rows = get(rawResult, 'data.rows', []);
  if (!skipRowMapping) {
    rows = rows.map((row) => zipObject(get(rawResult, 'data.headers', []), row));
  }
  const mainHeaders = get(rawResult, 'data.headers', []);
  set(rawResult, 'data.originalHeaders', mainHeaders);
  const masterData = get(rawResult, 'data.masterData', {});
  const masterDataPrimaryKey = get(rawResult, 'data.masterDataPrimaryKey', {});


  const mapEntity = Object.entries(masterDataPrimaryKey).reduce((carry, [entityType, fields]) => {
    const rawHeaders = get(masterData, [entityType, 'headers'], []);
    const headers = rawHeaders
      .map((i) => `${entityType}.${i}`)
      .concat(rawHeaders.map((i) => stripUniversalPrefix(`${entityType}.${i}`)));
    const rows = get(masterData, [entityType, 'rows'], []);

    return {
      ...carry,
      [entityType]: rows.reduce((carry, row) => {
        const obj = zipObject(headers, row.concat(row));
        const id = fields.map((field) => obj[`${entityType}.${field}`]).join('|');
        return { ...carry, [id]: obj };
      }, {}),
    };
  }, {});

  const enrichedRows = rows.map((i, index) => {
    let item: any = Object.entries(i).reduce((carry, [key, value]) => {
      return {
        ...carry,
        [key]: value,
        [stripUniversalPrefix(key)]: value,
      }
    }, {});
    Object.entries(masterDataPrimaryKey).forEach(([entityType, fields]) => {
      const id = fields.map((field) => get(item, `${entityType}.${field}`)).join('|');
      item = { ...item, ...mapEntity[entityType][id] };
    });

    return {
      ...item,
      currency: get(item, foundCurrencyField, 'USD'),
    };
  });

  set(rawResult, 'rows', enrichedRows);
  set(rawResult, 'headers', enrichedRows[0] ? Object.keys(enrichedRows[0]) : mainHeaders);

  return rawResult;
}

const getNamespace = (endpoint) => {
  const endpointQueryParams = new URLSearchParams(endpoint.split('?')[1] || '');
  const params = Object.fromEntries(endpointQueryParams.entries());
  return get(params, 'namespace', '');
};

const getMetricDefinition = (endpoint) => {
  const namespace = getNamespace(endpoint);
  const queryStr = endpoint.split('?')[1];
  const queryStrParams = new URLSearchParams(queryStr || '');
  queryStrParams.delete('namespace');

  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
    return getPerformanceMetrics(queryStr);
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
    return getOperationMetrics(queryStr);
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
    return getEpsiloMetric(EpsiloTableObject.QUALITY);
  }

  if (namespace) {
    return getOneMetricOptions(namespace, queryStr);
  }
  return Promise.resolve([]);
};

const cachedOneMetricOptions = {};
const getOneMetricOptions = async (namespace, queryStr = '') => {
  if (cachedOneMetricOptions[namespace]) return Promise.resolve(cachedOneMetricOptions[namespace]);
  return new Promise((resolve) => {
    request
      .get(
        EIP_CONSTANT.API_HOST.API_DATA_CENTER +
          '/v2/metrics.jsp?namespace=' +
          namespace +
          (queryStr ? '&' + queryStr : ''),
      )
      .then((res) => {
        cachedOneMetricOptions[namespace] = Object.values(res).map((el) => ({
          label: el.name + ` (${el.code})`,
          label_raw: el.name,
          value: el.code,
          prefix_value: el.prefix_value,
          variable_type: el.variable_type,
        }));
        resolve(cachedOneMetricOptions[namespace]);
      })
      .catch((e) => {
        resolve([]);
      });
  });
};

export { getPerformanceMetrics, getOperationMetrics, getEpsiloMetric, getMetricDefinition, getOneMetricOptions };
