import { useLog } from '@eip/next/lib/log';
import { buildCacheKey, makeCache } from '@eip/next/src/util/cache';
import { get } from 'lodash';
import React from 'react';
import { FilterContext, FilterType } from '../context';
import { CHART_TYPE } from '../node/chartlibs';
import type { demoMetricRangeType, NodeData } from '../type';
import { requestLineChartData } from './data-query/line-chart-query';
import { requestDetailMetricData, requestOverviewMetricData } from './data-query/metric-chart-query';
import { requestStackedChartData } from './data-query/stacked-chart-query';
import { requestTableData } from './data-query/table-query';

const log = useLog('dbf:hooks:useDataQuery');

export const QUERY_STATUS = {
  SUCCESS: 'success',
  FAIL: 'fail',
  LOADING: 'loading',
};

export const CHART_DATA_TYPE = {
  NUMBER: 'number',
  PERCENT: 'percent',
  CURRENCY: 'currency',
  FLOAT: 'float',
};

export const DataQueryContext = React.createContext<{
  demoMetricRange: demoMetricRangeType[];
}>({
  demoMetricRange: [],
});

function getCacheKey(nodeData: NodeData, filters: FilterType) {
  const cohort = get(nodeData, 'mainAttributes.cohort', null);
  const dimensions = get(nodeData, 'mainAttributes.dimensions', null);
  const metrics = get(nodeData, 'mainAttributes.metrics', null);
  const channels = get(filters, 'channels', null);
  const countries = get(filters, 'countries', null);
  const shops = get(filters, 'shops', null);
  const tools = get(filters, 'tools', null);

  const chartLibId = nodeData.chartLibId;
  let morePayload = null;

  if (chartLibId === 'table') {
    const dimensionId = get(nodeData.customAttributes, 'dimensionId');
    const relatedDimensions = get(nodeData.customAttributes, `selection[${dimensionId}].relatedDimensions`, []);
    const tableDimensions = [dimensionId, ...relatedDimensions];
    const metrics = get(nodeData.customAttributes, `selection[${dimensionId}].metrics`, []);
    const pagination = get(nodeData.customAttributes, 'pagination', {
      page: 1,
      limit: 10,
    });

    const orders = get(nodeData.customAttributes, 'sorting', []).map((i) => [i.field, i.sort]);

    morePayload = {
      metrics,
      dimensions: dimensions ? dimensions.concat(tableDimensions) : tableDimensions,
      orders,
      pagination,
    };

    log('morepayload', morePayload);
  }

  return buildCacheKey({
    cohort,
    dimensions,
    metrics,
    channels,
    countries,
    shops,
    tools,
    morePayload,
  });
}

const cacheit = makeCache();

export const useDataQuery = (nodeData: NodeData) => {
  const filterContext = React.useContext(FilterContext);
  const { availFilters } = {};

  const [queries, setQueriesData] = React.useState(null);
  const [filters, setFilters] = React.useState<FilterType>(null);

  React.useEffect(() => {
    setFilters(filterContext.filter);
    reloadChartData();
  }, [filterContext]);

  React.useEffect(() => {
    if (availFilters.metrics && availFilters.metrics.length > 0) {
      reloadChartData();
    }
  }, [availFilters.metrics, getAsyncData]);

  const reloadChartData = () => {
    getAsyncData(nodeData, filters).then((data) => {
      setQueriesData(data);
    });
  };

  return {
    queries,
    reloadData: reloadChartData,
  };
};

function withCacheGetAsync(asyncGet) {
  return (nodeData, filters) =>
    cacheit(getCacheKey(nodeData, filters), (setCache) => ({
      then: (resolve) =>
        asyncGet(nodeData, filters).then((data) => {
          if (data._isAllCompleted) {
            setCache(Promise.resolve(data.dataFrame));
            return resolve(data.dataFrame);
          } else if (data.dataFrame !== undefined) {
            return resolve(data.dataFrame);
          } else {
            return resolve(data);
          }
        }),
    }));
}

const getAsyncData = withCacheGetAsync(function (nodeData: NodeData, filters): PromiseLike<any> {
  log('getAsyncData', nodeData);

  let initialQueries = Promise.resolve(null);
  switch (nodeData.chartLibId) {
    case CHART_TYPE.METRIC:
      return {
        then: (resolve): PromiseLike<any> => {
          return Promise.resolve(requestOverviewMetricData(nodeData, filters, resolve));
        },
      };
    case CHART_TYPE.METRIC_DETAIL:
    case CHART_TYPE.METRIC_TOTAL:
      return {
        then: (resolve): PromiseLike<any> => {
          return Promise.resolve(requestDetailMetricData(nodeData, filters, resolve));
        },
      };
    case CHART_TYPE.STACKED_CHART:
      return {
        then: (resolve): PromiseLike<any> => {
          return Promise.resolve(requestStackedChartData(nodeData, filters, resolve));
        },
      };
    case CHART_TYPE.LINE_CHART:
      return {
        then: (resolve): PromiseLike<any> => {
          return Promise.resolve(requestLineChartData(nodeData, filters, resolve));
        },
      };
    case CHART_TYPE.TABLE:
      return {
        then: (resolve): PromiseLike<any> => {
          return Promise.resolve(requestTableData(nodeData, filters, resolve));
        },
      };
      break;
    default:
  }

  return initialQueries;
});
