import { EIP_CONSTANT } from '@eip/next/lib/main';

export const DATA_STATUS_TABLE = {
  title: 'Data status compact table',
  dimension: [
    'marketplace',
    'storefront_name',
    'field_name',
    'old_value',
    'new_value',
    'updated_at',
    'updated_by',
    'error_message',
  ],
  metric: [],
  attribute: [],
  tableType: 'compact',
  system: {
    hiddenComponents: ['timeline', 'majorButton'],
  },
  searchHint: 'Looking for field name...',
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  requestIgnoreField: {},
  sort: [],
  columnOrder: [
    'marketplace',
    'storefront_name',
    'field_name',
    'old_value',
    'new_value',
    'updated_at',
    'updated_by',
    'error_message',
    'model',
    'model_id',
    'trace_id',
    'error_code',
    'data_status',
    'storefront_id',
  ],
  mapping: {
    field_name: {
      menu: [],
      title: 'Field Name',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.field_name',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.field_name',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.field_name',
        label: 'ads_ops_buffer_ads.field_name',
        value: 'ads_ops_buffer_ads.field_name',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    new_value: {
      menu: [],
      title: 'New Value',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.new_value',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.new_value',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.new_value',
        label: 'ads_ops_buffer_ads.new_value',
        value: 'ads_ops_buffer_ads.new_value',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    old_value: {
      menu: [],
      title: 'Old Value',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.old_value',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.old_value',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.old_value',
        label: 'ads_ops_buffer_ads.old_value',
        value: 'ads_ops_buffer_ads.old_value',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    updated_at: {
      menu: [],
      title: 'Sync Failed At',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.updated_at',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.updated_at',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.updated_at',
        label: 'ads_ops_buffer_ads.updated_at',
        value: 'ads_ops_buffer_ads.updated_at',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    error_message: {
      menu: [],
      title: 'Error Message',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_dropdownlabel.description',
      cellFormat: 'text',
      filterField: 'ads_ops_dropdownlabel.description',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_dropdownlabel.description',
        label: 'ads_ops_dropdownlabel.description',
        value: 'ads_ops_dropdownlabel.description',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    model: {
      menu: [],
      title: 'Model',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.model',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.model',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.model',
        label: 'ads_ops_buffer_ads.model',
        value: 'ads_ops_buffer_ads.model',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    model_id: {
      menu: [],
      title: 'Model ID',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.model_id',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.model_id',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.model_id',
        label: 'ads_ops_buffer_ads.model_id',
        value: 'ads_ops_buffer_ads.model_id',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    trace_id: {
      menu: [],
      title: 'Trace ID',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.trace_id',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.trace_id',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.trace_id',
        label: 'ads_ops_buffer_ads.trace_id',
        value: 'ads_ops_buffer_ads.trace_id',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    error_code: {
      menu: [],
      title: 'Error Code',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_dropdownlabel.id',
      cellFormat: 'text',
      filterField: 'ads_ops_dropdownlabel.id',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_dropdownlabel.id',
        label: 'ads_ops_dropdownlabel.id',
        value: 'ads_ops_dropdownlabel.id',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    updated_by: {
      menu: [],
      title: 'Updated By',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'user.name',
      cellFormat: 'text',
      filterField: 'user.name',
      staticValue: {},
      valueGetter: {
        id: 'user.name',
        label: 'user.name',
        value: 'user.name',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    data_status: {
      menu: [],
      title: 'Data Status',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.data_status',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.data_status',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.data_status',
        label: 'ads_ops_buffer_ads.data_status',
        value: 'ads_ops_buffer_ads.data_status',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    storefront_id: {
      menu: [],
      title: 'Storefront ID',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_ops_buffer_ads.storefront_id',
      cellFormat: 'text',
      filterField: 'ads_ops_buffer_ads.storefront_id',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.storefront_id',
        label: 'ads_ops_buffer_ads.storefront_id',
        value: 'ads_ops_buffer_ads.storefront_id',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    storefront_name: {
      menu: [],
      title: 'Storefront Name',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'storefront.name',
      cellFormat: 'text',
      filterField: 'storefront.name',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.storefront_id',
        label: 'storefront.name',
        value: 'ads_ops_buffer_ads.storefront_id',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
    marketplace: {
      menu: [],
      title: 'Marketplace',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'storefront.marketplace_name',
      cellFormat: 'text',
      filterField: 'storefront.marketplace_name',
      staticValue: {},
      valueGetter: {
        id: 'ads_ops_buffer_ads.marketplace_code',
        label: 'storefront.marketplace_name',
        value: 'ads_ops_buffer_ads.marketplace_code',
      },
      propertyType: 'dimension',
      defaultGroupBy: null,
      selectionFilter: null,
      defaultCalculated: null,
    },
  },
};
