import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PieChart = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="2 2 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.917 9C13.441 11.8377 10.973 14 8 14C4.68629 14 2 11.3137 2 8C2 5.027 4.16229 2.55904 7 2.08296V8C7 8.55228 7.44772 9 8 9H13.917Z" />
    <path d="M8 2V7C8 7.55228 8.44772 8 9 8H14C14 4.68629 11.3137 2 8 2Z" />
  </SvgIcon>
);

export default PieChart;
