import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Bill = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 0C0.447715 0 0 0.450608 0 1.00646V11.3342C0 11.6903 0.357263 11.9337 0.685696 11.8015L2.12861 11.2206C2.36702 11.1246 2.63298 11.1246 2.87139 11.2206L4.62861 11.928C4.86702 12.024 5.13298 12.024 5.37139 11.928L7.12861 11.2206C7.36702 11.1246 7.63298 11.1246 7.87139 11.2206L9.31431 11.8015C9.64274 11.9337 10 11.6903 10 11.3342V1.00646C10 0.450608 9.55229 0 9 0H1ZM2.5 2.01292C2.22386 2.01292 2 2.23823 2 2.51615V3.52261C2 3.80054 2.22386 4.02584 2.5 4.02584H7.5C7.77614 4.02584 8 3.80054 8 3.52261V2.51615C8 2.23823 7.77614 2.01292 7.5 2.01292H2.5ZM2.5 7.04523C2.22386 7.04523 2 7.27053 2 7.54846C2 7.82638 2.22386 8.05169 2.5 8.05169H7.5C7.77614 8.05169 8 7.82638 8 7.54846C8 7.27053 7.77614 7.04523 7.5 7.04523H2.5ZM2 5.53553C2 5.25761 2.22386 5.0323 2.5 5.0323H7.5C7.77614 5.0323 8 5.25761 8 5.53553C8 5.81346 7.77614 6.03877 7.5 6.03877H2.5C2.22386 6.03877 2 5.81346 2 5.53553Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default Bill;
