import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ImportContacts = (props: SvgIconProps) => (
  <SvgIcon {...props} height="16px" viewBox="0 2 22 22" width="16px" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.5 4.5c-1.95 0-4.05.4-5.5 1.5-1.45-1.1-3.55-1.5-5.5-1.5S2.45 4.9 1 6v14.65c0 .65.73.45.75.45C3.1 20.45 5.05 20 6.5 20c1.95 0 4.05.4 5.5 1.5 1.35-.85 3.8-1.5 5.5-1.5 1.65 0 3.35.3 4.75 1.05.41.21.75-.19.75-.45V6c-1.49-1.12-3.63-1.5-5.5-1.5zm3.5 14c-1.1-.35-2.3-.5-3.5-.5-1.7 0-4.15.65-5.5 1.5V8c1.35-.85 3.8-1.5 5.5-1.5 1.2 0 2.4.15 3.5.5v11.5z"></path>
  </SvgIcon>
);

export default ImportContacts;
