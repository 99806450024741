import * as React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import { makeStyles } from '@material-ui/core';

import { eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

import MiniChart from '../mini-chart';

export const uQueryRegex = new RegExp(`uQuery\\(([\\'\\"]).*?\\1\\s*\\,\\s*([\\'\\"]).*?\\2\\s*\\)`, 'g');
export const chartRegex = new RegExp(`chartQuery\\(([\\'\\"]).*?\\1\\s*\\,\\s*([\\'\\"]).*?\\2\\s*\\)`, 'g');

const f = (field, operator, value) => {
  return {
    field,
    operator,
    value,
  };
};

const convertToFilters = (combinator = 'and') => {
  return (...args) => {
    return {
      combinator: combinator,
      filters: args,
    };
  };
};

const getChartData = ({ period, data, dateRangeChart, metric, size }) => {
  const { formatTime, periodUnit }: { formatTime: string; periodUnit: any } = (() => {
    switch (period) {
      case 'hourly':
        return { formatTime: 'MMM DD HH:mm', periodUnit: 'hour' };
      case 'daily':
        return { formatTime: 'YYYY-MM-DD', periodUnit: 'day' };
      case 'weekly':
        return { formatTime: '[W]W, MMM DD YYYY', periodUnit: 'week' };
      case 'monthly':
        return { formatTime: 'MMM YYYY', periodUnit: 'month' };
      case 'quarterly':
        return { formatTime: '[Q]Q YYYY', periodUnit: 'quarter' };
      case 'yearly':
        return { formatTime: '[Y]YYYY', periodUnit: 'year' };
      default:
        return { formatTime: 'YYYY-MM-DD', periodUnit: 'day' };
    }
  })();

  const datetimeIndex = data?.headers.findIndex((i) => i == 'datetime');
  const labels = data?.rows.map((i) => i[datetimeIndex]);
  let dataValues = data?.rows.reduce((carry, row) => {
    const key = row[datetimeIndex];
    const value = Object.keys(metric).reduce((carry, m) => {
      const metricIndex = data?.headers.findIndex((i) => i == m);
      return {
        ...carry,
        [m]: row[metricIndex],
      };
    }, {});
    return {
      ...carry,
      [key]: value,
    };
  }, {});

  let formatLabels = labels.map((label) =>
    period === 'weekly' ? moment(label).isoWeekday('Friday').format(formatTime) : moment(label).format(formatTime),
  );

  let filledLabels = labels;

  const hourlyFormat = 'YYYY-MM-DD HH:00:00';
  const strtoMoment = periodUnit === 'hour' ? hourlyFormat : 'YYYY-MM-DD';
  const startMoment = moment(dateRangeChart.dateFrom);
  const endMoment = moment(dateRangeChart.dateTo);

  const filledData = {};
  const kvData = Object.entries(dataValues);
  filledLabels = new Array(endMoment.diff(startMoment, periodUnit) + 1).fill(1).map((_, index) => {
    const l = moment(startMoment)
      .add(index, periodUnit)
      .format(periodUnit === 'hour' ? hourlyFormat : 'YYYY-MM-DD');
    filledData[l] = null;
    return l;
  });

  kvData.forEach(([k, v]) => {
    const kUTC = moment(k).format(strtoMoment);
    filledData[kUTC] = v;
  });

  dataValues = filledData;
  formatLabels = filledLabels.map((l) => moment(l).format(formatTime));

  return {
    data: {
      labels: formatLabels,
      datasets: Object.values(metric)
        .sort((a, b) => a.index - b.index)
        .map((m, index) => {
          const metric = m.apiField;
          const chartStyle = get(m, ['chartStyle'], 'line');
          const chartMinConfig = get(m, ['tractionRangeMin'], '');
          const chartMaxConfig = get(m, ['tractionRangeMax'], '');
          const yAxisID = get(m, ['yAxisID'], metric);
          const formulaLabel = get(m, ['label'], '=p("value")');
          const name = get(m, ['name'], '');
          const borderDash = get(m, ['borderDash'], '');
          const lineTension = get(m, ['lineTension'], 0.39);
          const color = get(m, ['color'], '#204D77');

          const seriesValues = filledLabels
            .map((label) => {
              return get(dataValues, [label, metric], null);
            })
            .filter((value) => value != undefined && value != null);

          const minValue = seriesValues.length ? Math.min(...seriesValues) : null;
          const maxValue = seriesValues.length ? Math.max(...seriesValues) : null;
          const avgValue = seriesValues.length ? seriesValues.reduce((a, b) => a + b, 0) / seriesValues.length : null;

          const rangeValue = {
            min: minValue,
            max: maxValue,
            avg: avgValue,
          };

          let defaultMinValue = minValue;
          if (minValue != null && minValue >= 0) defaultMinValue = 0;
          const min = String(chartMinConfig).startsWith('=') ? toValue(chartMinConfig, rangeValue) : defaultMinValue;

          const defaultMaxValue = maxValue ? maxValue * 1.05 : null;
          const max = String(chartMaxConfig).startsWith('=') ? toValue(chartMaxConfig, rangeValue) : defaultMaxValue;

          const seriesData = filledLabels.map((label, index) => {
            const yValue = get(dataValues, [label, metric], null);

            return {
              x: formatLabels[index],
              y: yValue,
              tooltipLabel: toValue(formulaLabel, {
                value: yValue,
              }),
              metric: metric,
              chartStyle,
            };
          });

          return {
            label: metric,
            key: metric,
            data: seriesData,
            min,
            max,
            maxRaw: max,
            order: index,
            // data: filledLabels.map((label) => dataValues[label]),
            backgroundColor: color,
            borderColor: color,
            borderDash: borderDash ? String(borderDash).split(',') : undefined,
            borderWidth: chartStyle === 'bar' ? 0 : 2,
            borderRadius: 3,
            fill: false,
            pointRadius: 0,
            pointHoverRadius: 3,
            pointHitRadius: 15,
            pointBorderWidth: 2,
            pointStyle: 'rectRounded',
            lineTension: lineTension,
            maxBarThickness: 20,
            barPercentage: 1,
            categoryPercentage: 0.8,
            spanGaps: false,
            parsing: {
              xAxisKey: 'x',
              yAxisKey: 'y',
            },
            type: chartStyle === 'area' ? 'line' : chartStyle,
            chartStyle,
            yAxisID: yAxisID,
            customMetric: name,
          };
        }),
    },
    chartPeriod: period,
    size,
  };
};

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    columnGap: '4px',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'flex-end',
  },
}));

const renderDangerouslySetInnerHTML = (str, classes) => {
  return str.includes('</span>') ? (
    <div className={classes.flexEnd} dangerouslySetInnerHTML={{ __html: `<span>${str}</span>` }}></div>
  ) : (
    <div className={classes.flexEnd}>
      <span>{str}</span>
    </div>
  );
};

const RichPrompt = ({ text }: { text: string }) => {
  const classes = useStyles();
  const [prompt, setPrompt] = React.useState(null);

  React.useEffect(() => {
    const textUQuery = String(text).startsWith('=') ? String(text).match(uQueryRegex) : [];
    const chartQueryList = String(text).startsWith('=') ? String(text).match(chartRegex) : [];

    const uQuery = (namespace, field, aggFunc, filterFormula = '') => {
      let filter = null;
      if (String(filterFormula).startsWith('AND') || String(filterFormula).startsWith('OR')) {
        try {
          filter = Function(
            'AND',
            'OR',
            'and',
            'or',
            'f',
            `return ${filterFormula}`,
          )(convertToFilters('and'), convertToFilters('or'), convertToFilters('and'), convertToFilters('or'), f);
        } catch (e) {
          filter = null;
        }
      }
      const payload = {
        dimensions: [field.split('.')[0]],
        attributes: [field],
        metrics: [],
        groupBy: {
          columns: null,
          aggregations: [
            {
              field,
              func: aggFunc,
            },
          ],
        },
        groupAll: true,
        groupPeriod: 'all',
        hiddenFilter: {
          currency: 'USD',
        },
        pagination: {
          page: 1,
          limit: 1,
        },
        sort: [],
        filter,
      };

      return eipRequest
        .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=' + namespace, payload)
        .then((res) => get(res, ['data', 'rows', 0, 0]))
        .catch(() => null);
    };

    const chartQuery = (namespace, period, timeFormula, filterFormula = '', keys, sizeFormula, ...metrics) => {
      const attribute = new Function('keys', `return ${keys}`)((...attributes) => {
        return attributes;
      });
      const dimension = [...new Set(attribute.map((i) => i.split('.')[0]))];
      const metric = metrics.reduce((carry, m, index) => {
        const mt = new Function('metric', `return ${m}`)((metric) => ({ ...metric, index }));

        return {
          ...carry,
          [mt.apiField]: {
            ...mt,
          },
        };
      }, {});
      const time = new Function('time', `return ${timeFormula}`)((field, timeText) => {
        let dateFrom, dateTo, type, qty, period;
        if (/\w+\_\w+\_/.test(String(timeText))) {
          if (timeText.endsWith('_to_date')) {
            [period] = timeText.toLowerCase().split('_');
            dateFrom = moment().startOf(period);
            dateTo = moment();
          } else {
            [type, qty, period] = timeText.toLowerCase().split('_');

            if (!period || type === 'this') {
              period = qty;
              qty = 0;
            }

            if (type === 'last') {
              dateFrom = moment()
                .subtract(+qty || 1, period)
                .startOf(period);
              dateTo = moment().subtract(1, period).endOf(period);
            }

            if (type === 'this') {
              dateFrom = moment().startOf(period);
              dateTo = moment().endOf(period);
            }
          }
          dateFrom = dateFrom.format('YYYY-MM-DD 00:00:00');
          dateTo = dateTo.format('YYYY-MM-DD 23:59:59');
        } else {
          [dateFrom, dateTo] = timeText.split(',').map((i) => i.trim());
        }
        return {
          dateFrom,
          dateTo,
          field,
        };
      });

      const size = new Function('size', `return ${sizeFormula}`)((height = '50px', width = '100px') => ({
        height,
        width,
      }));

      let filter = {
        combinator: 'AND',
        filters: [
          {
            field: time.field,
            operator: '>=',
            value: time.dateFrom,
          },
          {
            field: time.field,
            operator: '<=',
            value: time.dateTo,
          },
        ],
      };
      if (String(filterFormula).startsWith('AND') || String(filterFormula).startsWith('OR')) {
        try {
          const subFilters = Function(
            'AND',
            'OR',
            'and',
            'or',
            'f',
            `return ${filterFormula}`,
          )(convertToFilters('and'), convertToFilters('or'), convertToFilters('and'), convertToFilters('or'), f);
          filter.filters.push(subFilters);
        } catch (e) {
          filter = null;
        }
      }

      const payload = {
        dimensions: dimension,
        attributes: attribute,
        metrics: Object.keys(metric),
        groupBy: null,
        groupPeriod: period,
        hiddenFilter: {
          currency: 'USD',
        },
        pagination: {
          page: 1,
          limit: 9999,
        },
        sort: [],
        filter,
      };

      return eipRequest
        .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=' + namespace, payload)
        .then((res) => getChartData({ period, data: res?.data || {}, dateRangeChart: time, metric, size }))
        .catch(() => null);
    };

    const fetchedData = async () => {
      const queryObj = {};
      if (splitUQuery.length) {
        const response = await Promise.all(splitUQuery.map((i) => new Function('uQuery', `return ${i}`)(uQuery)));
        response.forEach((res, index) => {
          queryObj[splitUQuery[index]] = res;
        });
      }
      if (splitChartQuery.length) {
        try {
          const response = await Promise.all(
            splitChartQuery.map((i) => new Function('chartQuery', `return ${i}`)(chartQuery)),
          );
          response.forEach((res, index) => {
            const base64 = btoa(splitChartQuery[index]);
            queryObj[splitChartQuery[index]] = base64;
            queryObj[base64] = res;
          });
        } catch {}
      }
      return queryObj;
    };
    const splitUQuery = [].concat(textUQuery).filter((el) => el);
    const splitChartQuery = [].concat(chartQueryList).filter((el) => el);
    if (splitUQuery.length || splitChartQuery.length) {
      fetchedData()
        .then((res) => {
          const replacedText = (text || '').replace(chartRegex, (c) => `"${get(res, [c], '')}"`);
          const newPrompt = toValue(
            replacedText.replace(uQueryRegex, (c) => get(res, [c], '')),
            {},
          ).replace('<script', "Don't try to add script here");

          const chartQueryIndexs = Object.keys(res).reduce((carry, k) => {
            if (newPrompt.indexOf(k) > -1) {
              carry[k] = newPrompt.indexOf(k);
            }
            return carry;
          }, {});
          const dividedNewPrompt = Object.entries(chartQueryIndexs)
            .sort((a, b) => a[1] - b[1])
            .reduce((carry, [k, v], index) => {
              const nextIndex = Number(v);
              if (index == 0)
                return [
                  [0, nextIndex],
                  [nextIndex, nextIndex + k.length],
                ];
              else carry.push([carry[carry.length - 1][1], nextIndex], [nextIndex, nextIndex + k.length]);
              return carry;
            }, []);

          const promptRender = dividedNewPrompt.length
            ? dividedNewPrompt.map(([start, end]) => {
                const str = newPrompt.slice(start, end);
                if (res[str]) {
                  return (
                    <div
                      key={start}
                      style={{
                        width: get(res, [str, 'size', 'width'], {}),
                      }}
                      className={classes.flexCenter}
                    >
                      <MiniChart
                        data={get(res, [str, 'data'], {})}
                        chartPeriod={get(res, [str, 'chartPeriod'], {})}
                        currency={''}
                        metricDetail={''}
                        height={get(res, [str, 'size', 'height'], {})}
                      />
                    </div>
                  );
                }
                return renderDangerouslySetInnerHTML(str, classes);
              })
            : renderDangerouslySetInnerHTML(newPrompt, classes);

          setPrompt(promptRender);
        })
        .catch(() => {});
    } else {
      const newPrompt = renderDangerouslySetInnerHTML(
        toValue(text || '', {}).replace('<script', "Don't try to add script here"),
        classes,
      );
      setPrompt(newPrompt);
    }
  }, [text]);

  if (!prompt) return null;

  return <div className={classes.container}>{prompt}</div>;
};

export default RichPrompt;
