import * as React from 'react';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { SelectProps } from '@material-ui/core';

interface TimeZoneInputProps extends SelectProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const timezones = [
  {
    value: '+07:00',
    label: 'GMT+07:00',
  },
  {
    value: '+08:00',
    label: 'GMT+08:00',
  },
];
const TimeZoneInput = ({ value, onChange, ...rest }: TimeZoneInputProps) => {
  const options: OptionSelectType[] = React.useMemo(() => {
    if (timezones.length > 0) {
      return timezones.map((time) => ({
        label: time.label,
        value: time.value,
        note: time.label,
      }));
    }
    return [];
  }, [timezones]);
  return (
    <SelectForm
      disableIconSelect
      options={options}
      value={rest.disabled ? '' : value}
      renderOption={(option) => `${option.note}`}
      onChange={onChange}
      {...rest}
    />
  );
};

export default TimeZoneInput;
