import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import TextFieldNumber from '@ep/insight-ui/elements/textField/textField-number';
import Dropdown, { ChildrenDropdownProps } from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { responsiveTextField } from '@ep/insight-ui/lib/epsilo-theme';
import { SelectItem } from '@ep/insight-ui/elements/table/dropdown-properties/type';
import { useFormikContext } from 'formik';
import { get, set } from 'lodash';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import TextForm from '@ep/insight-ui/elements/form-control/text-form';
import Typography from '@ep/insight-ui/elements/text-style/Typography';

/**
 * ff.improve_script_detail_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.improve_script_detail_mobile:end
 */

const useStyles = makeStyles({
  ...responsiveTextField,
  container: {
    marginBottom: 5,
    ...(ff.improve_script_detail_mobile
      ? {
          '&.eres--small .eip1-MuiGrid-item': {
            width: '100%',
            '& .text-field-mobile': {
              marginLeft: 0,
            },
          },
        }
      : {}),
  },
  dropdownMenu: {
    display: 'flex',
    alignItems: ' center',

    '& p': {
      marginRight: '8px',
      marginLeft: '4px',
    },
  },
  firstCell: {
    minWidth: '70px',
    textAlign: 'right',
    flexGrow: 0,
    color: '#253746',
    '& .operator': {
      textAlign: 'right',
    },
  },
  operator: {
    textAlign: 'right',
  },
  text: {
    height: '32px',
    padding: '6px 8px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      width: '100%',
    },
  },
});
const groupOperator: OptionSelectType[] = [
  {
    label: 'And',
    value: 'and',
    note: 'All filter must match.',
  },
  {
    label: 'Or',
    value: 'or',
    note: 'At least one filter must match.',
  },
];

type ConditionRowValueType = {
  targetObject: string;
  attribute: string;
  timeQuery: string;
  timeUnit?: string;
  timeQuantity?: number;
  conditionOperator: string;
  value: number;
};

type Props = {
  options?: {
    targetObject: OptionSelectType[];
    attribute: OptionSelectType[];
    timeQuery: OptionSelectType[];
    timeUnit: OptionSelectType[];
    conditionOperator: OptionSelectType[];
    timeInclusive: OptionSelectType[];
  };
  disabledOperatorCell?: boolean;
  name: string;
  index: number;
  handleDelete: (index: number) => void;
  handleDuplicate: (index: number) => void;
  handleChangeLogicalOperator?: (val: string) => void;
  disabled?: boolean;
};

interface MenuActionProps extends ChildrenDropdownProps {
  index: number;
}

const ConditionRow = ({
  name,
  disabledOperatorCell = false,
  handleDelete = () => undefined,
  handleDuplicate = () => undefined,
  handleChangeLogicalOperator = () => undefined,
  options = {
    targetObject: [{ label: 'Ad object', value: 'ad-object' }],
    attribute: [{ label: 'Ads GMV', value: 'ads-GMV' }],
    timeQuery: [{ label: 'Last', value: 'last' }],
    timeUnit: [{ label: 'Days', value: 'days' }],
    conditionOperator: [{ label: '>=', value: '>=' }],
    timeInclusive: [
      {
        label: 'Include Today',
        value: 'INCLUDE_TODAY',
      },
      {
        label: 'Exclude today',
        value: 'EXCLUDE_TODAY',
      },
      {
        label: 'Exclude custom day',
        value: 'EXCLUDE_CUSTOM',
      },
    ],
  },
  index,
  disabled = false,
}: Props) => {
  const classes = useStyles();

  /**
   * ff.improve_script_detail_mobile:start
   */
  let containerClass, isMobile;
  if (ff.improve_script_detail_mobile) {
    containerClass = React.useContext(ContainerResponsiveContext).containerClass;
    isMobile = containerClass === 'eres--small';
  }

  const listMenu = [
    [
      {
        title: 'Remove',
        icon: 'remove',
        onClick: () => handleDelete(index),
      },
      {
        title: 'Duplicate',
        icon: 'addduplicate',
        iconSize: '16px',
        onClick: () => handleDuplicate(index),
      },
      {
        title: 'Turn into group',
        icon: 'turnIntoGroup',
        disable: true,
        onClick: () => undefined,
      },
    ],
  ];
  /**
   * ff.improve_script_detail_mobile:end
   */

  const dataInclude: OptionSelectType[] = [
    {
      label: 'Include Today',
      value: 'INCLUDE_TODAY',
    },
    {
      label: 'Exclude today',
      value: 'EXCLUDE_TODAY',
    },
    {
      label: 'Exclude custom day',
      value: 'EXCLUDE_CUSTOM',
    },
  ];
  const { handleChange, values, setValues } = useFormikContext();

  // React.useEffect(() => {
  //   const rowValue = get(values, name);
  //   if (!rowValue)
  //     setValues(
  //       set(values as any, name, {
  //         targetObject: get(options, 'targetObject[0].value', ''),
  //         attribute: get(options, 'attribute[0].value', ''),
  //         timeQuery: options.timeQuery[0].value,
  //         timeUnit: options.timeUnit[0].value,
  //         timeInclusive: get(options, 'timeInclusive[0].value', ''),
  //         conditionOperator: options.conditionOperator[0].value,
  //       }),
  //       true,
  //     );
  // }, []);
  const timeQuery = React.useMemo(
    () => get(values, `${name}.timeQuery`, get(options, 'timeQuery[0].value', '')),
    [values, name, options],
  );

  const MenuAction: React.FC<MenuActionProps> = ({ index, ...rest }: MenuActionProps) => {
    return (
      <MenuList
        listMenu={[
          [
            {
              title: 'Remove',
              icon: 'remove',
              onClick: () => handleDelete(index),
            },
            {
              title: 'Duplicate',
              icon: 'addduplicate',
              iconSize: '16px',
              onClick: () => handleDuplicate(index),
            },
            {
              title: 'Turn into group',
              icon: 'turnIntoGroup',
              disable: true,
              onClick: () => undefined,
            },
          ],
        ]}
        closeMenuCallBack={rest.onClose}
      />
    );
  };

  return (
    <Grid
      container
      item
      xs={12}
      spacing={3}
      wrap={'nowrap'}
      alignItems={ff.improve_script_detail_mobile ? (isMobile ? 'flex-start' : 'center') : 'center'}
      className={ff.improve_script_detail_mobile ? `${containerClass} ${classes.container}` : classes.container}
      {...(ff.improve_script_detail_mobile ? (isMobile ? { direction: 'column' } : {}) : {})}
    >
      <Grid item>
        <div className={ff.improve_script_detail_mobile ? (isMobile ? '' : classes.firstCell) : classes.firstCell}>
          {disabledOperatorCell ? (
            <span>Where</span>
          ) : index == 1 ? (
            <SelectForm
              options={groupOperator}
              value={get(values, `${name}.combinator`, get(options, 'combinator', 'and'))}
              onChange={handleChange(`${name}.combinator`)}
              disabled={disabled}
            />
          ) : (
            <Typography variant="body2" align={'right'} style={{ textTransform: 'capitalize', fontWeight: 400 }}>
              {get(values, `${name}.combinator`, get(options, 'combinator', 'and'))}
            </Typography>
          )}
        </div>
      </Grid>
      <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 2) : 2}>
        <SelectForm
          className={`${classes.inputSelect} md`}
          options={options.targetObject || []}
          value={get(values, `${name}.targetObject`, get(options, 'targetObject[0].value', ''))}
          onChange={handleChange(`${name}.targetObject`)}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 2) : 2}>
        {/* <SelectForm
          className={`${classes.inputSelect} md`}
          options={options.attribute || []}
          value={get(values, `${name}.attribute`, get(options, 'attribute[0].value', ''))}
          onChange={handleChange(`${name}.attribute`)}
        /> */}
        <SelectMultipleForm
          maxDropdownHeight={'30vh'}
          onChange={(val) => handleChange(`${name}.attribute`)(get(val, [0], ''))}
          value={[get(values, `${name}.attribute`, get(options, 'attribute[0].value', ''))]}
          options={options.attribute || []}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 1) : 1}>
        <SelectForm
          className={`${classes.inputSelect} xs2`}
          options={options.timeQuery || []}
          value={get(values, `${name}.timeQuery`, get(options, 'timeQuery[0].value', ''))}
          onChange={handleChange(`${name}.timeQuery`)}
          disabled={disabled}
        />
      </Grid>
      {timeQuery === 'LAST' && (
        <React.Fragment>
          <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 1) : 1}>
            <TextForm
              value={get(values, `${name}.timeQuantity`, '0')}
              placeholder={''}
              setValue={handleChange(`${name}.timeQuantity`)}
              className={`${classes.text}`}
              disabled={disabled}
            />
            {/* <TextFieldNumber
              value={get(values, `${name}.timeQuantity`, '0')}
              style={{ width: '100%' }}
              placeholder={''}
              onChangeNumber={handleChange(`${name}.timeQuantity`)}
              className={`${classes.inputNumber} xs`}
            /> */}
          </Grid>
          <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 1) : 1}>
            <SelectForm
              className={`${classes.inputSelect} xs2`}
              value={get(values, `${name}.timeUnit`, get(options, 'timeUnit[0].value'))}
              onChange={handleChange(`${name}.timeUnit`)}
              options={options.timeUnit || []}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 2) : 2}>
            <SelectForm
              className={`${classes.inputSelect} xs`}
              options={options.timeInclusive || []}
              onChange={handleChange(`${name}.timeInclusive`)}
              value={get(values, `${name}.timeInclusive`, get(dataInclude, ['0', 'value']))}
              disabled={disabled}
            />
          </Grid>
        </React.Fragment>
      )}
      <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 1) : 1}>
        <SelectForm
          className={`${classes.inputSelect}`}
          options={options.conditionOperator || []}
          onChange={handleChange(`${name}.conditionOperator`)}
          value={get(values, `${name}.conditionOperator`, get(options, 'conditionOperator[0].value'))}
          disabled={disabled}
        />
      </Grid>
      <Grid
        item
        xs={
          ff.improve_script_detail_mobile
            ? isMobile
              ? 12
              : timeQuery === 'LAST'
              ? 2
              : 6
            : timeQuery === 'LAST'
            ? 2
            : 6
        }
      >
        {/* <TextForm
          value={get(values, `${name}.value`, '')}
          placeholder={'Your value'}
          setValue={handleChange(`${name}.value`)}
          className={`${classes.text}`}
        /> */}

        <TextFieldNumber
          value={get(values, `${name}.value`, 0)}
          style={{ width: '100%' }}
          placeholder={'Your value'}
          onChangeNumber={handleChange(`${name}.value`)}
          className={
            ff.improve_script_detail_mobile
              ? isMobile
                ? `${classes.inputNumber} md text-field-mobile`
                : `${classes.inputNumber} md`
              : `${classes.inputNumber} md`
          }
          disabledIncreaseDecrease
          disabled={disabled}
        />
      </Grid>
      <Grid item>
        <Dropdown
          disabledClickContentClose={false}
          label={''}
          alignMenu={'left'}
          className={classes.dropdownMenu}
          icon={'threeDotsVertical'}
          disabled={disabled}
          {...(ff.improve_script_detail_mobile ? (isMobile ? { dataMenuPage: listMenu } : {}) : {})}
        >
          <MenuAction index={index} />
        </Dropdown>
      </Grid>
    </Grid>
  );
};

export default ConditionRow;
