import * as React from 'react';
import GroupColumnChartResposive from '../../../chartlib/column-chart/index-group-column';
import { generalLabels, generateRandomData } from '../../../chartlib/helper';
import { IDataChart } from '../../../chartlib/type';
import { IConfigTable, makeTable } from '@ep/insight-ui/elements/table/table-container';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { CHART_CONFIG } from '@ep/insight-ui/chartlib/area-chart/chart-config';

const group_data: IDataChart = {
  headers: generalLabels({ month: '10', year: '2021', DateFormatView: 'DD/MM/YYYY' }),
  rows: [
    {
      label: 'GMV',
      data: generateRandomData({
        month: '6',
        year: '2022',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 12000,
        minValue: 5000,
      }),
    },
    {
      label: 'GMV Target',
      data: generateRandomData({
        month: '6',
        year: '2022',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 4000,
      }),
    },
  ],
};

const valueDate = group_data.rows.reduce((a, b) => {
  b.data.forEach((el) => {
    const [time, value] = Object.values(el);
    if (!a[b.label])
      a[b.label] = {
        [time]: value,
      };
    else a[b.label][time] = value;
  });
  return a;
}, {});

export const GroupColumnChart = () => {
  const config = {
    apiRequest: {},
    configuration: {
      ...CHART_CONFIG,
      title: 'Group Column Chart',
    },
  };

  const changeConfiguration = (config: IConfigTable) => {
    console.log('Update configuration', config);
  };
  const headers = ['country', ...group_data.headers.map((el) => `${el}`)];
  const RESPONSE_CHART = {
    data: {
      headers,
      pagination: { limit: 100, page: 1, total: -1 },
      primaryKeys: [],
      rows: group_data.rows.map((row) => ({
        country: row.label,
        ...valueDate[row.label],
      })),
    },
    message: 'OK',
    code: 200,
  };

  // const tableContainer = React.useMemo(
  //   () =>
  //     makeTable({
  //       config: {
  //         ...config,
  //         addons: {
  //           'table.columns.enhance': (columnDefs, columns, backbone) => {
  //             const additionalColumns = group_data.headers.map((el) => {
  //               return {
  //                 headerName: el,
  //                 field: el,
  //                 colId: el,
  //                 resizable: false,
  //                 suppressMovable: true,
  //                 cellStyle: {
  //                   borderRightColor: 'whitesmoke',
  //                 },
  //                 headerComponentParams: {
  //                   type: 'number',
  //                 },
  //                 width: 200,
  //                 cellRendererParams: {},
  //                 cellStyle: {
  //                   display: 'flex',
  //                   alignItems: 'center',
  //                   justifyContent: 'flex-end',
  //                   paddingRight: '10px',
  //                 },
  //               };
  //             });
  //             return [...columnDefs, ...additionalColumns];
  //           },
  //           'datasource.apiRequest.getTableData': async (params, originRequest) => {
  //             return Promise.resolve(RESPONSE_CHART);
  //           },
  //         },
  //       },
  //       changeConfiguration,
  //       tableBackboneHook: useTableBackbone,
  //       tableContext: TableBackboneContext,
  //     }),
  //   [],
  // );

  return (
    <>
      <div style={{ height: '100vh', width: '100%', marginBottom: '100px' }}>
        <div style={{ height: '100%', width: '100%' }}>
          <GroupColumnChartResposive
            keyChart={'column_group_demo'}
            title=""
            value=""
            currency=""
            percent={15}
            data={group_data}
            stateChart="success"
          />
        </div>
      </div>
    </>
  );
};

export default GroupColumnChart;
