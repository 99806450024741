import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const ProcessStatus = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4 3.01992e-07C4.79112 2.6741e-07 5.56448 0.234596 6.22228 0.674122C6.88008 1.11365 7.39277 1.73836 7.69552 2.46927C7.99827 3.20017 8.07748 4.00444 7.92314 4.78036C7.7688 5.55629 7.38784 6.26902 6.82843 6.82843C6.26902 7.38784 5.55629 7.7688 4.78036 7.92314C4.00444 8.07748 3.20017 7.99827 2.46927 7.69552C1.73836 7.39277 1.11365 6.88008 0.674121 6.22228C0.234596 5.56448 -1.49698e-07 4.79113 -1.74846e-07 4L4 4L4 3.01992e-07Z"
      fill={'#0369C7'}
    />
  </SvgIcon>
);
export default ProcessStatus;
