import { useLog } from '@eip/next/lib/log';
import * as jsonpatch from 'fast-json-patch';
import { produce } from 'immer';
import { get, set, uniqBy } from 'lodash';

const log = useLog('page:page-changeset');

export type TypePatches = { op: string; path: string; value: any };

const changePathMapping = {
  '/entityId': 'entity_id',
  '/entityType': 'entity_type',
};

export function buildPageChangeset(page: Dashboard, patches: TypePatches[]) {
  let changeset: Changeset = [];

  const updated = {
    properties: 0,
    format: 0,
  };

  const reducedPatches = patches.reduce((carry, patch) => {
    if (patch.path.indexOf('/title') == 0 && !updated.properties) {
      updated.properties = 1;
      return carry.concat(patch);
    } else if (patch.path.indexOf('/properties') == 0 && !updated.properties) {
      updated.properties = 1;
      return carry.concat(patch);
    } else if (patch.path.indexOf('/layouts') == 0 && !updated.format) {
      updated.format = 1;
      return carry.concat(patch);
    } else if (patch.path.indexOf('/layoutColumns') == 0 && !updated.format) {
      updated.format = 1;
      return carry.concat(patch);
    }
    return carry.concat(patch);
  }, []);

  for (const patch of reducedPatches) {
    const change: ChangeItem = { op: null, pointer: null, path: null, value: null };
    if (['add', 'replace', 'remove'].indexOf(patch.op) > -1) {
      change.op = 'add';
      change.pointer = {
        id: page.blockEid,
        table: page.table,
      };
    }
    if (patch.path.indexOf('/properties') === 0) {
      change.path = ['properties'];
      change.value = {
        ...(page.properties || {}),
      };
    } else if (patch.path === '/title') {
      change.path = ['properties'];
      change.value = {
        ...(page.properties || {}),
        title: patch.value,
      };
    } else if (patch.path.indexOf('/layoutColumns') === 0 || patch.path.indexOf('/layouts') === 0) {
      change.path = ['format'];
      change.value = {
        layouts: page.layouts,
        layoutColumns: patch.value,
      };
    } else if (changePathMapping[patch.path]) {
      change.path = [changePathMapping[patch.path]];
      change.value = set({}, change.path, patch.value);
    } else if (patch.path.indexOf('/version') === 0 && patch.value === 1) {
      change.path = [];
      change.value = {
        id: page.blockEid,
        type: page.blockType,
        parent_id: '__parent_id__',
        parent_table: 'space',
        owner_id: 918,
        version: 1,
        payload: {
          properties: { title: page.title, version: 1 },
          format: {
            layouts: page.layouts,
            layoutColumns: page.layoutColumns,
          },
        },
      };
    }
    if (change.path !== null) changeset = changeset.concat(change);
  }

  return pageChangesetFilter(page, changeset);
}

export function buildNodeAsBlockDiff(prevNodes: { [key: string]: NodeData }, nodes: { [key: string]: NodeData }) {
  const newBlocks = {};
  let deletedBlocks = {};
  const updatedBlocks = {};

  for (const nodeId of Object.keys(nodes)) {
    if (prevNodes[nodeId]) {
      const runtimeProps = Object.keys(get(nodes[nodeId], 'customAttributes._runtime', {}));
      const initDiff = jsonpatch.compare(prevNodes[nodeId], nodes[nodeId]);
      const diff = initDiff.filter((patch) => {
        const path = patch.path;
        if (path.indexOf('/customAttributes/_runtime') === 0) return false;
        const shouldIgnore = runtimeProps.some((p) => path.indexOf('/customAttributes/' + p) === 0);
        return shouldIgnore ? false : true;
      });

      if (diff.length > 0) {
        updatedBlocks[nodeId] = diff;
      }
    } else {
      newBlocks[nodeId] = nodes[nodeId];
    }
  }

  deletedBlocks = Object.keys(prevNodes)
    .filter((nodeId) => !nodes[nodeId])
    .reduce((carry, nodeId) => {
      return { ...carry, [nodeId]: { id: nodeId } };
    }, {});

  return {
    addNew: newBlocks,
    updated: updatedBlocks,
    deleted: deletedBlocks,
  };
}

function buildBlockChangeSet(page: Dashboard, node: NodeData, patches: TypePatches[]) {
  let changeset: Changeset = [];

  for (const patch of patches) {
    const change: ChangeItem = { op: null, pointer: null, path: null, value: null };
    if (['add', 'replace'].indexOf(patch.op) > -1 && patch.path == '' && patch.value.version === 1) {
      change.op = 'add';
      change.pointer = {
        id: node.id,
        table: 'eip_system_block',
      };
      change.value = {
        id: node.id,
        type: node.chartLibId,
        parent_id: page.blockEid,
        parent_table: 'eip_system_block',
        version: 1,
        payload: {
          properties: {
            mainAttributes: node.mainAttributes,
            customAttributes: node.customAttributes,
          },
        },
      };
      change.path = [];
      changeset = changeset.concat(change);

      changeset.push({
        op: 'add',
        pointer: change.pointer,
        path: ['properties'],
        value: {
          mainAttributes: node.mainAttributes,
          customAttributes: node.customAttributes,
        },
      });
    } else if (
      ['add', 'replace', 'remove'].indexOf(patch.op) > -1 &&
      (patch.path.indexOf('/mainAttributes') === 0 ||
        patch.path.indexOf('/customAttributes') === 0 ||
        patch.path.indexOf('/permission') === 0 ||
        patch.path.indexOf('/roles') === 0 ||
        patch.path.indexOf('/next_permission') === 0)
    ) {
      change.op = 'add';
      change.pointer = {
        id: node.id,
        table: 'eip_system_block',
      };
      change.parent_pointer = {
        id: page.blockEid,
        table: 'eip_system_block',
      };
      change.path = ['properties'];
      change.value = {
        mainAttributes: node.mainAttributes,
        customAttributes: node.customAttributes,
        permission: node.permission,
        roles: node.roles,
        next_permission: node.next_permission,
      };
      changeset = changeset.concat(change);
    } else if (patch.op === 'remove') {
      change.op = 'remove';
      change.pointer = {
        id: node.id,
        table: 'eip_system_block',
      };
    }
  }

  // compress changeset
  changeset = uniqBy(changeset, (i) => [].concat(i['op']).concat(i['path']).join(''));

  return changeset;
}

export function buildPageBlockChangeset(
  page: Dashboard,
  blockPatches: {
    updated: { [key: string]: TypePatches[] };
    addNew: { [key: string]: NodeData };
    deleted: { [key: string]: any };
  },
) {
  let changeset: Changeset = [];

  for (const nodeId of Object.keys(blockPatches.updated)) {
    changeset = changeset.concat(
      buildBlockChangeSet(
        page,
        page.nodes.find((n) => n.id === nodeId),
        blockPatches.updated[nodeId],
      ),
    );
  }

  for (const nodeId of Object.keys(blockPatches.addNew)) {
    changeset = changeset.concat({
      op: 'add',
      path: ['content'],
      value: page.nodes.map((i) => i.id),
      pointer: {
        id: page.blockEid,
        table: 'eip_system_block',
      },
    });
    changeset = changeset.concat(
      buildBlockChangeSet(
        page,
        page.nodes.find((n) => n.id === nodeId),
        [
          {
            op: 'add',
            path: '',
            value: { version: 1 },
          },
        ],
      ),
    );
  }

  for (const nodeId of Object.keys(blockPatches.deleted)) {
    changeset = changeset.concat({
      op: 'add',
      path: ['content'],
      value: page.nodes.map((i) => i.id),
      pointer: {
        id: page.blockEid,
        table: 'eip_system_block',
      },
    });
    changeset = changeset.concat(
      buildBlockChangeSet(page, blockPatches.deleted[nodeId], [
        {
          op: 'remove',
          path: '',
          value: { version: -1 },
        },
      ]),
    );
  }

  return pageBlockChangesetTransform(page, changeset);
}

function pageChangesetFilter(page: Dashboard, changeset: Changeset): Changeset {
  if (page.blockType === 'user_persona') {
    return changeset.filter((change) => !['layout', 'layoutColumns', 'format'].some((k) => change.path.includes(k)));
  }
  return changeset;
}

function pageBlockChangesetTransform(page: Dashboard, blockChangeset: Changeset): Changeset {
  if (page.blockType !== 'user_persona') return blockChangeset;

  const blockChanges = blockChangeset.reduce((carry, change) => {
    return { ...carry, [change.pointer.id]: filterPersonaProperties(change.value, change.pointer.id) };
  }, {});

  if (Object.keys(blockChanges).length === 0) return [];

  const blockPersona = produce(page.properties.blockPersona || {}, (draft) => {
    page.nodes.map((n) => {
      draft[n.id] = n;
    });
    Object.keys(blockChanges).forEach((k) => {
      draft[k] = blockChanges[k];
    });
  });

  return [
    {
      op: 'add',
      pointer: {
        id: page.blockEid,
        table: page.table,
      },
      path: ['properties'],
      value: {
        ...page.properties,
        blockPersona: blockPersona,
      },
    },
  ];
}

function filterPersonaProperties(blockProps, blockEid) {
  log('filtering properties', blockEid, blockProps);
  if (!blockProps) return undefined;

  let properties = { ...blockProps };
  properties = produce(properties, (draft) => {
    set(draft, 'customAttributes.mapping', undefined);
    set(draft, 'system', undefined);
  });

  log('after update', properties);

  return properties;
}
