import * as React from 'react';
import clsx from 'clsx';
import { FixedSizeList } from 'react-window';
import { cloneDeep, debounce } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  makeStyles,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  TextField,
  Checkbox,
  Tooltip,
} from '@material-ui/core';

import Search from '@ep/insight-ui/icons/svg/Search';
import Icon from '@ep/insight-ui/icons/Icon';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import ConditionalWrap from '../conditional-wrap';
import LightTooltip from '@ep/insight-ui/elements/tooltip/light-tooltip';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import ButtonSort from '@ep/insight-ui/elements/table/table-actions/button-sort';
import { LoadingObjType } from '@ep/insight-ui/elements/table/table-actions/hooks/use-filter-selection';

const useStylesCheckedBox = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '2px solid',
    borderRadius: '2px',
    borderColor: '#C2C7CB !important',
    width: '12px',
    height: '12px',
    '& .eip1-MuiSvgIcon-root': {
      width: 'auto',
    },
  },
  rootChecked: {
    backgroundColor: '#0369C7',
    borderColor: '#0369C7  !important',
    '.Mui-disabled &': {
      backgroundColor: '#E4E7E9',
      borderColor: '#E4E7E9 !important',
      pointerEvents: 'none',
    },
  },
  icon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
      pointerEvents: 'none',
    },
  },
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
  },
}));

const CheckedBox = ({ icon, disabled, isSingle = false }: { icon: string; disabled: boolean; isSingle: boolean }) => {
  const classes = useStylesCheckedBox();
  if (isSingle) {
    return <Icon type={icon} className={clsx(classes.icon, disabled ? 'icon_disabled' : null)} />;
  }
  return (
    <Box className={clsx(classes.root, icon !== 'default' && classes.rootChecked)}>
      {icon !== 'default' && <Icon type={icon} className={clsx(classes.icon, disabled ? 'icon_disabled' : null)} />}
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  container: {
    padding: '16px',
    paddingBottom: 0,
  },
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
  },
  inputIcon: {
    marginLeft: '12px',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
      '& .additional-container > div': {
        backgroundColor: 'transparent',
      },
    },
    borderRadius: '4px',
    '&.hasSelectAll': {
      paddingLeft: '20px',
    },
    position: 'relative',
  },
  title: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '8px',
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  dragIcon: {
    cursor: 'grab',
    marginRight: '10px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  nagative: {
    backgroundColor: '#0000000a',
    '& .additional-container > div': {
      backgroundColor: 'transparent',
    },
  },
  cellFormatContainer: {
    '& > div:hover': {
      backgroundColor: 'transparent',
    },
    background: '#fff',
    height: '36px',
    marginLeft: '8px',
  },
  additionalContainer: {
    position: 'absolute',
    height: '36px',
    top: 0,
    right: 0,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    '& .cellTable': {
      // Do not want to override current cell style
      padding: '0 !important',
      whiteSpace: 'nowrap',
      '& .cell-format-main': {
        '& > div': {
          minHeight: '12px',
        },
        '& .label span': {
          lineHeight: '12px',
        },
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  icon: {
    cursor: 'pointer',
    display: 'flex',
  },
  updatedAtContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    columnGap: '4px',
    marginLeft: '8px',
    textTransform: 'none',
    fontWeight: 400,
    color: '#626266',
  },
}));

const Row = ({ data, index, style }: any) => {
  const classes = useStyles();

  const listItem = data.options[index];
  const checked = data.selectedItems.includes(listItem.value);
  const isDisabled = data.disabledOptions?.includes(listItem.value);
  const singleId = listItem?.singleId;

  return (
    <DragCard index={index} moveCard={data.moveCard}>
      <div style={style}>
        <ListItem
          className={clsx(
            classes.listItem,
            data.isSelectAllShow && data.isMultiSelect && 'hasSelectAll',
            data.indexItem === index && classes.nagative,
          )}
          onClick={() => {
            if (!isDisabled) {
              const newSelectedItems =
                checked && data.isMultiSelect
                  ? data.selectedItems.filter((el) => el !== listItem.value)
                  : !data.isMultiSelect
                  ? [listItem.value]
                  : singleId
                  ? data.selectedItems
                      .filter((el) => {
                        const selectedItemDetail = data.options.find((opt) => opt.value == el);
                        return !selectedItemDetail?.singleId && selectedItemDetail?.singleId != singleId;
                      })
                      .concat(listItem.value)
                  : [...data.selectedItems, listItem.value];
              data.setSelectedItems(newSelectedItems);
              data.setIndexItem(index);
            }
          }}
        >
          <Box className={classes.dragIcon}>
            <Icon type={'draggable'} />
          </Box>
          <Checkbox
            checked={checked}
            color="primary"
            icon={<CheckedBox icon={singleId ? 'radioBlank' : 'default'} isSingle={singleId} />}
            checkedIcon={<CheckedBox icon={singleId ? 'radio' : 'checked'} isSingle={singleId} />}
            disabled={isDisabled}
          />
          <span className={classes.label}>{listItem?.label}</span>
        </ListItem>
      </div>
    </DragCard>
  );
};

interface IMultipleSelectComponent {
  selectedItems: Array<string | number>;
  setSelectedItems: React.Dispatch<React.SetStateAction<Array<string | number>>>;
  options: any[];
  setOptions?: React.Dispatch<React.SetStateAction<any[]>>;
  title?: string;
  hasSearch?: boolean;
  isSelectAllShow?: boolean;
  loadingObj?: LoadingObjType;
  loading: boolean;
  onInputChange?: any;
  useParentFilter?: boolean;
  placeholder?: string;
  error?: JSX.Element;
  isMultiSelect?: boolean;
  showSelectedIndicator?: boolean;
  compactBackbone?: any;
  handleChangeCompactSort?: (model?: SortType[]) => void;
  hasSortButton?: boolean;
  handleRetry?: () => void;
  lastUpdatedLabel?: string;
  disabledOptions?: string[];
}

const MultipleSelectComponent = ({
  title = '',
  hasSearch = true,
  selectedItems,
  setSelectedItems,
  options,
  setOptions,
  isSelectAllShow = true,
  loadingObj,
  loading = false,
  onInputChange = () => undefined,
  useParentFilter = false,
  placeholder = '',
  error = null,
  isMultiSelect = true,
  showSelectedIndicator = false,
  compactBackbone,
  handleChangeCompactSort,
  hasSortButton = false,
  handleRetry,
  lastUpdatedLabel,
  disabledOptions = [],
}: IMultipleSelectComponent) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState('');
  const [selectAll, setSelectAll] = React.useState(false);
  const [indexItem, setIndexItem] = React.useState(null);

  const handleSetText = React.useCallback(debounce(onInputChange, 300), []);

  React.useEffect(() => {
    handleSetText(inputValue);
  }, [inputValue]);

  const filteredOptions = React.useMemo(() => {
    if (useParentFilter) return options;
    return options.filter(
      (option) => (option.label && String(option.label).toLowerCase().includes(inputValue.toLowerCase())) || !hasSearch,
    );
  }, [options, hasSearch, inputValue]);

  const filteredOptionsLength = filteredOptions.length;

  const groupedOptions = React.useMemo(() => {
    return options.reduce((carry, opt) => {
      const singleId = opt.singleId;
      if (!carry[singleId]) {
        carry[singleId] = [opt.value];
      } else {
        carry[singleId].push(opt.value);
      }
      return carry;
    }, {});
  }, [options]);

  React.useEffect(() => {
    const totalAvailableOptions = options.reduce(
      (carry, opt) => {
        const singleId = opt.singleId;
        if (!singleId || !carry[singleId]) {
          carry['count']++;
          carry[singleId] = true;
        }
        return carry;
      },
      {
        count: 0,
      },
    );
    setSelectAll(selectedItems.length >= totalAvailableOptions.count);
  }, [selectedItems, options]);

  const windowHeight = window.innerHeight;
  const listHeightPercent = 0.7;

  const moveCard = (dragIndex, hoverIndex) => {
    const viewsClone = cloneDeep(options);
    [viewsClone[dragIndex], viewsClone[hoverIndex]] = [viewsClone[hoverIndex], viewsClone[dragIndex]];
    setOptions(viewsClone);
  };

  const widthList = React.useMemo(() => {
    let hasAdditionalWidth = false;
    const mapped = options
      .filter((el) => el.label)
      .map((el) => {
        const additionalWidth = el.additionalInfomrations
          ? el.additionalInfomrations.reduce((a, b) => a + parseInt(b.maxColumnWidth) || 100, 0)
          : 0;
        if (additionalWidth > 0) hasAdditionalWidth = true;
        return el.label.length * 10 + 40 + additionalWidth;
      });
    return Math.min(hasAdditionalWidth ? 1024 : 450, Math.max(...mapped, 250));
  }, [options]);

  const checkAllIcon = React.useMemo(() => {
    return selectedItems.length > 0 ? 'indeterminate' : 'default';
  }, [selectedItems]);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  const handleUserKeyPress = React.useCallback(
    (event) => {
      //UP 38 , DOWN 40, ENTER 13
      const { keyCode } = event;
      if (keyCode == 38) {
        indexItem <= 0 ? setIndexItem(filteredOptionsLength - 1) : setIndexItem((prev) => prev - 1);
      }

      if (keyCode == 40) {
        indexItem >= filteredOptionsLength - 1
          ? setIndexItem(0)
          : setIndexItem((prev) => (prev == null ? 0 : prev + 1));
      }

      if (keyCode == 13 && indexItem !== null) {
        const listItem = options[indexItem];
        const checked = selectedItems.includes(listItem.value);
        const singleId = listItem?.singleId;
        const newSelectedItems =
          checked && isMultiSelect
            ? selectedItems.filter((el) => el !== listItem.value)
            : !isMultiSelect
            ? [listItem.value]
            : singleId
            ? selectedItems
                .filter((el) => {
                  const selectedItemDetail = options.find((opt) => opt.value == el);
                  return !selectedItemDetail?.singleId && selectedItemDetail?.singleId != singleId;
                })
                .concat(listItem.value)
            : [...selectedItems, listItem.value];
        setSelectedItems(newSelectedItems);
      }
    },
    [indexItem, selectedItems],
  );

  React.useEffect(() => {
    window.addEventListener('keydown', handleUserKeyPress);
    return () => {
      window.removeEventListener('keydown', handleUserKeyPress);
    };
  }, [handleUserKeyPress]);

  return (
    <Box className={classes.container}>
      {title && (
        <Box className={classes.title}>
          <span>{title}</span>
          {lastUpdatedLabel ? (
            <React.Fragment>
              <Box className={classes.updatedAtContainer}>
                <span className={classes.icon} onClick={handleRetry}>
                  <Icon type={'reload'} colorIcon={'#626266'} />
                </span>
                <span>Updated {lastUpdatedLabel}</span>
              </Box>
              {loadingObj && loadingObj.cached ? (
                <Tooltip title={'Request failed. Using cache instead.'}>
                  <Box display={'flex'} alignItems={'center'} sx={{ marginLeft: '4px' }}>
                    <Icon type={'ic/material-symbols:warning/yellow'} size={'14px'} />
                  </Box>
                </Tooltip>
              ) : null}
            </React.Fragment>
          ) : null}
        </Box>
      )}
      <Box className={classes.header}>
        {hasSearch && (
          <TextField
            className={clsx(classes.textStyle, classes.textSearch)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <Search style={{ width: 12, height: 14 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={() => setInputValue('')} style={{ opacity: inputValue ? 1 : 0 }}>
                  <Icon type={'closeCircle'} />
                </IconButton>
              ),
              classes: { input: classes['input'] },
              fullWidth: true,
            }}
            inputRef={inputRef}
            placeholder={placeholder || 'Search'}
          />
        )}
        {hasSortButton ? (
          <TableBackboneContext.Provider value={compactBackbone}>
            <ButtonSort callback={handleChangeCompactSort} />
          </TableBackboneContext.Provider>
        ) : null}
      </Box>
      {error && <Box className={classes.error}>{error}</Box>}
      {(loadingObj && ['loading', 'reloading'].includes(loadingObj.status)) || loading ? (
        <Box className={classes.loading}>
          <LoadingIcon color={'#253746'} />
        </Box>
      ) : null}
      {(loadingObj && loadingObj.status !== 'loading') || !loading ? (
        <React.Fragment>
          {!error && options.length === 0 ? (
            <span>0 results</span>
          ) : (
            <List>
              {isSelectAllShow && isMultiSelect && (
                <ConditionalWrap
                  condition={showSelectedIndicator}
                  wrap={(children) => {
                    return (
                      <LightTooltip title={`${selectedItems.length} selected item(s)`} placement="bottom-start">
                        {children}
                      </LightTooltip>
                    );
                  }}
                >
                  <ListItem
                    className={classes.listItem}
                    onClick={() => {
                      if (selectAll) {
                        setSelectedItems((prevValue) => {
                          return prevValue.filter((el) => disabledOptions?.includes(el));
                        });
                      } else {
                        setSelectedItems((prevValue) => {
                          const previousWithDisabledOptions = prevValue.filter((el) => disabledOptions?.includes(el));
                          const singleGroupHasSelectedItem = Object.entries(groupedOptions)
                            .filter(([k, v]) => {
                              return k && (v || []).some((i) => prevValue.includes(i));
                            })
                            .reduce((carry, [k, v]) => {
                              return {
                                ...carry,
                                [k]: v,
                              };
                            }, {});
                          const nextWithoutDisabledOptions = options
                            .filter((opt) => {
                              const value = opt.value;
                              const singleId = opt.singleId;
                              return (
                                !disabledOptions?.includes(value) &&
                                (!singleId ||
                                  (!singleGroupHasSelectedItem[singleId] && groupedOptions[singleId]?.[0] == value) ||
                                  (singleGroupHasSelectedItem[singleId] && prevValue.includes(value)))
                              );
                            })
                            .map((i) => i.value);
                          return previousWithDisabledOptions.concat(nextWithoutDisabledOptions);
                        });
                      }
                    }}
                  >
                    <Checkbox
                      checked={selectAll}
                      color="primary"
                      icon={<CheckedBox icon={checkAllIcon} />}
                      checkedIcon={<CheckedBox icon={'checked'} />}
                    />
                    <span style={{ fontWeight: 500 }}>All</span>
                  </ListItem>
                </ConditionalWrap>
              )}
              <DndProvider context={window} backend={HTML5Backend}>
                <FixedSizeList
                  height={
                    filteredOptionsLength * 35 + 20 > windowHeight * listHeightPercent
                      ? windowHeight * listHeightPercent
                      : filteredOptionsLength * 35 + 20
                  }
                  itemData={{
                    options: filteredOptions,
                    selectedItems,
                    isSelectAllShow,
                    setSelectedItems,
                    moveCard,
                    indexItem,
                    setIndexItem,
                    isMultiSelect,
                    disabledOptions,
                  }}
                  itemCount={filteredOptionsLength}
                  itemSize={35}
                  width={widthList}
                >
                  {Row}
                </FixedSizeList>
              </DndProvider>
            </List>
          )}
        </React.Fragment>
      ) : null}
    </Box>
  );
};

export default MultipleSelectComponent;
