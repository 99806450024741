import * as React from 'react';
import moment from 'moment';
import { get } from 'lodash';

import { makeStyles, Tooltip } from '@material-ui/core';

import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import Icon from '@ep/insight-ui/icons/Icon';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import ConditionalWrap from '../../util/conditional-wrap';

const useStyles = makeStyles({
  container: {
    marginLeft: '8px',
    overflow: 'hidden',
  },
  label: {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center',
    color: '#626266',
  },
  icon: {
    cursor: 'pointer',
  },
  updatedText: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const UpdatedAtSection = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const [needTooltip, setNeedTooltip] = React.useState(false);

  const updatedTextRef = React.useRef(null);

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const backbone = React.useContext(TableBackboneContext);
  const groupBy = backbone.getConfig('groupBy');
  const gridApi = backbone.getGridApi();
  const hideUpdatedAtLabel = get(backbone.getConfig('system'), ['hideUpdatedAtLabel'], 'no') === 'yes';
  const visualizationType = backbone.getConfig('visualizationType');

  const lastUpdatedAt = useAtomValue(eTableAtom.lastUpdatedAt);

  const [lastUpdatedLabel, setLastUpdatedLabel] = React.useState(
    lastUpdatedAt ? moment(lastUpdatedAt).fromNow() : null,
  );

  const defaultExpandedRows = get(backbone, ['config', 'system', 'defaultExpandedRows'], 0);

  const handleReloadTable = () => {
    if (gridApi) {
      gridApi.deselectAll();
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.filter((i) => !!i).length > 0 && backbone.getConfig('visualizationType') === 'table') {
        const expanded = [];
        const collapsed = [];
        gridApi.forEachNode((node) => {
          if (node.group && collapsed.length < Number(defaultExpandedRows)) {
            collapsed.push(node);
            expanded.push(node);
          } else if (node.expanded) {
            expanded.push(node);
          }
        });
        collapsed.forEach((node) => {
          if (!node.expanded) {
            gridApi.setRowNodeExpanded(node, true);
          }
        });
        if (expanded.length) {
          const lowestLevelExpanded = Math.max(...expanded.map((i) => i.level));
          expanded
            .filter((i) => i.level == lowestLevelExpanded)
            .forEach((el) => {
              const route = [el.key];
              let currentNode = el;
              while (currentNode.level > 0) {
                route.push(currentNode.parent.key);
                currentNode = currentNode.parent;
              }

              gridApi.refreshServerSideStore({ route: route.reverse() });
            });
        } else {
          gridApi.refreshServerSideStore();
        }
      } else {
        if (visualizationType == 'category') {
          backbone.changePagination({ page: 1 });
        }
        gridApi.refreshServerSideStore();
      }
    }
  };

  React.useEffect(() => {
    if (lastUpdatedAt) {
      setLastUpdatedLabel(moment(lastUpdatedAt).fromNow());
      const interval = setInterval(() => {
        setLastUpdatedLabel(lastUpdatedAt.fromNow());
      }, 1000 * 60);

      return () => {
        clearInterval(interval);
      };
    }
  }, [lastUpdatedAt]);

  React.useEffect(() => {
    if (updatedTextRef.current) {
      setNeedTooltip(updatedTextRef.current.offsetWidth < updatedTextRef.current.scrollWidth);
    }
  }, [updatedTextRef.current, lastUpdatedAt]);

  if (!lastUpdatedAt) return null;

  return (
    <React.Fragment>
      <div ref={ref} className={classes.container}>
        <div className={classes.label}>
          <ConditionalWrap
            condition={hideUpdatedAtLabel}
            wrap={(children) => {
              return <Tooltip title={`Updated ${lastUpdatedLabel}`}>{children}</Tooltip>;
            }}
          >
            <span className={classes.icon} onClick={handleReloadTable}>
              <Icon type={'reload'} colorIcon={'#626266'} />
            </span>
          </ConditionalWrap>
          {!hideUpdatedAtLabel ? (
            <ConditionalWrap
              condition={needTooltip}
              wrap={(children) => {
                return <Tooltip title={`Updated ${lastUpdatedLabel}`}>{children}</Tooltip>;
              }}
            >
              <span ref={updatedTextRef} className={classes.updatedText}>
                Updated {lastUpdatedLabel}
              </span>
            </ConditionalWrap>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
});
export default UpdatedAtSection;
