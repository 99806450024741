import * as React from 'react';
import { useAtomValue, useSetAtom } from 'jotai';
import { Resizable } from 'react-resizable';
import { debounce, get } from 'lodash';
import moment from 'moment';

import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  makeStyles,
  Paper,
  PaperProps,
} from '@material-ui/core';

import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';
import Icon from '@ep/insight-ui/icons/Icon';
import { scriptEditor } from '@ep/insight-ui/system/atom/script-editor';
import { MonacoEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/monaco-editor';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { aim } from '@eip/next/lib/main';

const useStyles = makeStyles({
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  handleLeft: {
    position: 'absolute',
    top: '0',
    left: '-5px',
    width: '10px',
    height: '100%',
    cursor: 'ew-resize',
    zIndex: 1,
    backgroundColor: '#f0f0f0',
  },
  buttonAction: {
    '& .eip1-MuiTypography-body2': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  paperComponent: {
    '& .eip1-MuiDialogContent-root': {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
});

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

function StatusIndicator({ status }) {
  switch (status) {
    case 'loading':
      return <CircleLoading size={'12px'} />;
    case 'error_loading':
      return <Icon type="error" />;
    default:
      return null;
  }
}

function PaperComponent(props: PaperProps) {
  const classes = useStyles();
  return (
    <Resizable
      width={props.width}
      height={props.height}
      handleClasses={{ left: classes.handleLeft }}
      handle={<span className={classes.handleLeft} />}
      onResize={(event, { size, ...rest }) => {
        props.onResize(size.width, size.height);
      }}
      resizeHandles={['w']}
      className={classes.paperComponent}
    >
      <Paper {...props} />
    </Resizable>
  );
}

const ScriptEditor = ({ language = 'javascript', defaultWidth = 1 }: any) => {
  const { isDisplay, submit, content, test } = useAtomValue(scriptEditor);
  const [size, setSize] = React.useState({
    width: defaultWidth * window.visualViewport.width - 64,
    height: window.visualViewport.height,
  });
  const [consoleLog, setConsoleLog] = React.useState([]);
  const [displayLog, setDisplayLog] = React.useState(false);
  const [theme, setTheme] = React.useState('vs');
  const [status, setStatus] = React.useState('');
  const updateEditor = useSetAtom(scriptEditor);
  const classes = useStyles();

  const handleClose = React.useCallback(() => {
    updateEditor((c) => ({ ...c, isDisplay: false }));
  }, []);

  const idRef = React.useRef(null);
  const editor = React.useRef(null);
  const logEditor = React.useRef(null);
  const userProfile = aim.getUserSettings().profile || {};
  const providedUserProfile = {
    ...userProfile,
    token: String(userProfile?.userEmail).endsWith('@epsilo.io') ? aim.getLoginToken().token : '',
  };

  const suggestions = Object.keys(providedUserProfile).map((key) => {
    return {
      label: `user.${key}`,
      insertText: `user.${key}`,
    };
  });

  const properties = React.useRef(null);

  const autoSave = React.useCallback(
    debounce((content, id) => {
      if (!id) return;
      window.localStorage.setItem('scriptContent/' + id, content);
    }, getConst(['script', 'autoSavePeriod'], 500)),
    [],
  );

  const convertedConsoleLog = React.useMemo(() => {
    return consoleLog.map((log) => {
      return `${log.time}: ${log.message}`;
    });
  }, [consoleLog]);

  const addLog = (log) => {
    setConsoleLog((logs) => {
      return logs.concat({
        time: moment().format('YYYY-MM-DD HH:mm:ss'),
        message: log,
      });
    });
  };

  return (
    <Dialog
      disableEnforceFocus={true}
      hideBackdrop={true}
      open={isDisplay}
      PaperComponent={PaperComponent}
      maxWidth={'xl'}
      disableScrollLock={true}
      PaperProps={{
        style: {
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: size.width,
          height: size.height,
        },
        width: size.width,
        height: size.height,
        onResize: (w, h) => {
          setSize({ width: w, height: h });
        },
      }}
    >
      <DialogContent>
        <ErrorBoundary>
          <Box display="flex" flexDirection={'column'} height={'100%'}>
            <Box flexGrow={1} minHeight={'70%'}>
              <MonacoEditor
                ref={editor}
                language={language}
                onCodeChange={(content) => {
                  // autoSave(content, idRef.current);
                }}
                value={content}
                automaticLayout={true}
                suggestions={suggestions}
                theme={theme}
              />
            </Box>
            {displayLog && (
              <Box height={'30%'} borderTop={'1px solid #626266'} style={{ position: 'relative' }}>
                <Box
                  style={{ position: 'absolute', top: 0, right: -12, cursor: 'pointer' }}
                  onClick={() => setDisplayLog(false)}
                >
                  <Icon type={'close'} />
                </Box>
                <MonacoEditor
                  ref={logEditor}
                  language={'plaintext'}
                  value={convertedConsoleLog.join('\n')}
                  readOnly={true}
                  automaticLayout={true}
                />
              </Box>
            )}
          </Box>
        </ErrorBoundary>
      </DialogContent>
      <DialogActions>
        <Box display={'flex'} alignItems={'center'} style={{ columnGap: '8px' }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (test) {
                const content = editor.current.getValue();
                try {
                  test(content);
                } catch (e) {
                  setConsoleLog((logs) => {
                    return logs.concat({
                      time: moment().format('YYYY-MM-DD HH:mm:ss'),
                      message: e.message,
                    });
                  });
                  setDisplayLog(true);
                }
              }
            }}
          >
            Test
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              setDisplayLog(!displayLog);
            }}
          >
            Toggle log
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              editor.current.getAction('editor.action.formatDocument').run();
            }}
          >
            Format code
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              editor.current._themeService.setTheme(theme === 'vs' ? 'vs-dark' : 'vs');
              setTheme(theme === 'vs' ? 'vs-dark' : 'vs');
            }}
          >
            {theme === 'vs' ? 'Dark theme' : 'Light theme'}
          </Button>
        </Box>
        <Box flexGrow={1} />
        <Box className={classes.actionGroup}>
          <Button variant="contained" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              if (submit) {
                const content = editor.current.getValue();
                submit(content);
              }
            }}
          >
            Submit
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default ScriptEditor;
