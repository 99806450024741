import { Avatar, Grid, makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
const useStyles = makeStyles(() => ({
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '7px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    paddingTop: '3px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  avatar: {
    width: '24px',
    height: '24px',
    fontSize: '10px',
    border: '1px solid #CCE3F5',
    background: '#EBF6FF',
    color: '#000',
  },
}));
export type IAvatarTextFormat = {
  name: string;
  image?: string;
  hashtag?: string;
  subtext?: string;
};
const AvatarTextFormat = (props: IPropsFormat<IAvatarTextFormat>) => {
  const classes = useStyles();
  // const { hashtag = '', subtext = '', avatar = '' } = value;
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const subtext = _.get(props, ['value', 'subtext'], '');
  const avatar = _.get(props, ['value', 'image'], '');
  const name = _.get(props, ['value', 'name'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={props.cellAction}
      node={props.node}
      value={props.value}
      isGrouped={isGrouped}
    >
      <Grid container alignItems={'center'} wrap={'nowrap'} spacing={1}>
        {/* avatar */}
        <Grid item>
          <Avatar src={avatar} className={`${classes.avatar}`}>
            {name.length > 0 && ff.mass_operation_management
              ? name
                  .split(' ')
                  .map((el) => el[0])
                  .join('')
              : name[0]}
          </Avatar>
        </Grid>
        {/* info */}
        <Grid item style={{ width: '100%' }}>
          <Grid container alignItems={'center'}>
            {/* hashtag */}
            <Grid item xs={12}>
              <span className={classes.hashtag}>{hashtag}</span>
            </Grid>
            {/* name */}
            <Grid item xs={12}>
              <span className={classes.label}>{`${name}`} </span>
            </Grid>
            {/* subtext */}
            <Grid item xs={12}>
              <span className={classes.subtext}>{subtext}</span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};

export default AvatarTextFormat;
