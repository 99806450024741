import React from 'react';
import { get } from 'lodash';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import LinearProgress from '@material-ui/core/LinearProgress';
import { Tooltip } from '@material-ui/core';
import StarIcon from '@material-ui/icons/Star';

import { formatCurrency } from '../../../utils/utils';

const MainProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 64,
  },
  colorPrimary: {
    backgroundColor: 'rgba(217, 221, 227, 0.32);',
  },
  bar: {
    borderRadius: 64,
    background: '#FFD600',
  },
  dashedColorPrimary: {
    background: ' rgba(217, 221, 227, 0.32)',
  },
  dashed: {
    animation: 'none',
  },
  bar1Buffer: {
    // background: 'linear-gradient(90deg, #7D16AD 0%, #DA4F95 100%);',
    background: 'linear-gradient(90deg, #7D16AD 0%, #DA4F95 100%);',
  },
}))(LinearProgress);

const MainSuccessProgress = withStyles((theme) => ({
  root: {
    height: 8,
    borderRadius: 64,
  },
  colorPrimary: {
    backgroundColor: '#f5f5f5',
  },
  bar: {
    borderRadius: 64,
    backgroundColor: '#0BAA78',
  },
}))(LinearProgress);

const SubProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 64,
  },
  colorPrimary: {
    // backgroundColor: 'f5f5f5',
    backgroundColor: 'rgba(217, 221, 227, 0.24)',
  },
  bar: {
    borderRadius: 64,
    background: '#FFD600',
  },
  bar1Buffer: {
    // background: 'linear-gradient(90deg, #7D16AD 0%, #DA4F95 100%);',
    background: 'linear-gradient(90deg, #7D16AD 0%, #DA4F95 100%);',
  },
  dashedColorPrimary: {
    background: ' rgba(217, 221, 227, 0.32)',
  },
  dashed: {
    animation: 'none',
  },
}))(LinearProgress);

const SubSuccessProgress = withStyles((theme) => ({
  root: {
    height: 4,
    borderRadius: 64,
  },
  colorPrimary: {
    backgroundColor: 'f5f5f5',
  },
  bar: {
    borderRadius: 64,
    backgroundColor: '#0BAA78',
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    position: 'relative',
    padding: '32px 0',
    marginBottom: '10px',
    fontSize: 14,
    fontWeight: 600,
  },
  star: {
    fontSize: 16,
    color: '#F16145',
    marginRight: 2,
  },
  child: {
    marginLeft: 67,
    padding: '24px 0',
    '& span': {
      fontSize: 12,
    },
    '& span:first-child': {
      left: 0,
    },
  },
  order: {
    position: 'absolute',
    top: 0,
    left: 0,
    background: '#000',
    color: '#fff',
    width: 20,
    height: 20,
    textAlign: 'center',
    borderRadius: 4,
  },
  name: {
    fontSize: 20,
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 10,
    },
  },
  target: {
    position: 'absolute',
    top: '10%',
    right: 0,
    color: '#65758B',
  },
  targetPercent: {
    position: 'absolute',
    bottom: '10%',
    right: 0,
    color: '#65758B',
  },
  achieved: {
    position: 'absolute',
    zIndex: 1,
    top: '10%',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '5px',
    paddingLeft: '5px',
  },
  achievedPercent: {
    position: 'absolute',
    bottom: '10%',
    right: 0,
    color: '#fff',
  },
  icon: {
    width: '32px',
    height: '32px',
    '$child &': {
      width: '24px',
      height: '24px',
    },
  },
});

interface ProgressUIProps {
  order?: number;
  name: string;
  icon?: string;
  achieved: number;
  target: number;
  currency: string;
  today: {
    achieved: number;
  };
}

export const ProgressUI = (props: ProgressUIProps) => {
  const classes = useStyles();
  const refName = React.useRef();
  const refTarget = React.useRef();

  const { order, name, achieved, target, currency, icon, today } = props;

  const [percent, setPercent] = React.useState(Number(((achieved / target) * 100).toFixed(2)));
  const calPercent = Number(((achieved / target) * 100).toFixed(2));
  let todayPercent = Number(((get(today, 'achieved', 0) / target) * 100).toFixed(2));

  // if (get(today, 'achieved') === achieved) {
  //   todayPercent = 0;
  // }
  todayPercent = 0;

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      if (refName.current && refTarget.current) {
        const p = refName.current.parentElement;
        const barWidth = p.clientWidth;

        const nameWidth = refName.current.clientWidth;
        const targetWidth = refTarget.current.clientWidth;

        const minPercent = Number(((100 * nameWidth) / barWidth).toFixed(2));
        const maxPercent = Number(((100 * (barWidth - targetWidth)) / barWidth).toFixed(2));

        if (calPercent > 100) {
          setPercent(100);
        } else if (calPercent < minPercent) {
          setPercent(minPercent);
        } else if (calPercent > maxPercent) {
          setPercent(maxPercent);
        } else {
          setPercent(calPercent);
        }

        // console.info(refName.current, refTarget.current);
      }
    });
  }, [achieved, target]);

  function test() {
    const calPercent = 107;
    const todayPercent = 10;
    const percent = 100;
  }

  const isMain = !!order;

  let lineProgress;
  if (isMain) {
    lineProgress =
      percent >= 100 ? (
        <MainSuccessProgress variant="determinate" value={100} />
      ) : (
        <MainProgress variant="buffer" value={calPercent} valueBuffer={calPercent} />
      );
  } else {
    lineProgress =
      percent >= 100 ? (
        <SubSuccessProgress variant="determinate" value={100} />
      ) : (
        <SubProgress variant="buffer" value={calPercent} valueBuffer={calPercent} />
      );
  }

  function render100() {
    return (
      <React.Fragment>
        <span
          className={classes.achieved}
          style={{
            right: 0,
          }}
        >
          {isMain && percent >= 100 && <StarIcon className={classes.star} />}
          {formatCurrency(achieved)}
        </span>
        <span className={classes.achievedPercent} style={{ right: 0 }}>{`${calPercent}%`}</span>
      </React.Fragment>
    );
  }

  function renderLess100() {
    return (
      <React.Fragment>
        {percent > 0 && (
          <span
            className={classes.achieved}
            style={{
              right: percent > 50 ? `${100 - percent}%` : undefined,
              left: percent <= 50 ? `${percent}%` : undefined,
            }}
          >
            {isMain && percent >= 100 && <StarIcon className={classes.star} />}
            {formatCurrency(achieved)}
          </span>
        )}
        {calPercent > 0 && (
          <span
            className={classes.achievedPercent}
            style={{
              left: calPercent < 50 ? calPercent + '%' : undefined,
              right: percent > 50 ? `${100 - percent}%` : undefined,
            }}
          >{`${calPercent}%`}</span>
        )}
        {calPercent < 100 && (
          <span className={classes.target} ref={refTarget}>
            {formatCurrency(target)}
          </span>
        )}
        {calPercent < 100 && <span className={classes.targetPercent}>100%</span>}
      </React.Fragment>
    );
  }

  return (
    <TooltipProgress {...props}>
      <div className={`${classes.root} ${order ? '' : classes.child}`}>
        {/* {isMain && <span className={classes.order}>{order}</span>} */}
        <span className={classes.name} ref={refName}>
          {icon ? <img className={classes.icon} src={icon} alt={name} /> : null} <span>{name}</span>
        </span>

        {calPercent >= 100 && render100()}
        {calPercent < 100 && renderLess100()}

        {lineProgress}
      </div>
    </TooltipProgress>
  );
};

ProgressUI.defaultProps = {
  order: null,
  name: '',
  achieved: 0,
  target: 0,
};

const once = true;
function TooltipProgress(props: any) {
  const { order, name, achieved, target, currency, icon, today } = props;

  const classes = useTooltipStyle();

  const tooltipContent = (
    <div>
      <div className={classes.title}>{name}</div>
      <div className={classes.item}>
        <div className={[classes.itemTitle, 'today'].join(' ')}>GMV today</div>
        <div className={'row'}>{formatCurrency(today.achieved)} USD</div>
        <div className={'row'}>{Number((today.achieved / target) * 100).toFixed(2)} %</div>
      </div>
      {/* <div className={classes.item}>
        <div className={[classes.itemTitle, 'mtd'].join(' ')}>GMV MTD</div>
        <div className={'row'}>{formatCurrency(achieved)} USD</div>
        <div className={'row'}>{Number((achieved / target) * 100).toFixed(2)} %</div>
      </div> */}
    </div>
  );

  return (
    <HtmlTooltip
      title={tooltipContent}
      PopperProps={{
        modifiers: {
          offset: { offset: '0,-150px' },
          preventOverflow: {
            enabled: true,
            boundariesElement: window,
          },
        },
      }}
    >
      {props.children}
    </HtmlTooltip>
  );
}

const useTooltipStyle = makeStyles((theme) => ({
  root: {
    background: '#fff',
    color: '#253746',
    padding: 8,
  },
  title: {
    fontSize: '16px',
    // marginBottom: '16px',
  },
  item: {
    marginLeft: 28,
    '& .row': {
      fontSize: '14px',
      fontWeight: 500,
      marginBottom: 8,
    },
  },
  itemTitle: {
    marginTop: '16px',
    fontSize: '11px',
    fontWeight: 700,
    marginBottom: '8px',
    color: '#596772',
    position: 'relative',
    '&.mtd::before': {
      content: '" "',
      position: 'absolute',
      left: -18,
      background: '#D94F96',
      height: 12,
      width: 12,
      borderRadius: 6,
    },
    '&.today::before': {
      content: '" "',
      position: 'absolute',
      left: -18,
      background: '#FFD600',
      height: 12,
      width: 12,
      borderRadius: 6,
    },
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    background: '#fff',
    color: '#253746',
    width: 175,
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);
