import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PausedCircle = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="8" cy="8" r="6" fill="#8D979E" />
    <circle cx="8" cy="8" r="3" fill="white" />
  </SvgIcon>
);

export default PausedCircle;
