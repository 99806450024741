import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Import = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9 9.98319H1C0.734784 9.98319 0.48043 10.0894 0.292893 10.2785C0.105357 10.4677 0 10.7242 0 10.9916C0 11.259 0.105357 11.5155 0.292893 11.7046C0.48043 11.8938 0.734784 12 1 12H9C9.26522 12 9.51957 11.8938 9.70711 11.7046C9.89464 11.5155 10 11.259 10 10.9916C10 10.7242 9.89464 10.4677 9.70711 10.2785C9.51957 10.0894 9.26522 9.98319 9 9.98319Z" />
    <path d="M5.60643 0.239844C5.53285 0.163921 5.44497 0.103593 5.34794 0.0624049C5.25091 0.021217 5.1467 9.28059e-09 5.04143 0C4.93616 -9.2806e-09 4.83194 0.021217 4.73492 0.0624049C4.63789 0.103593 4.55001 0.163921 4.47643 0.239844L2.03643 2.64993C1.96114 2.72412 1.90131 2.81275 1.86047 2.91059C1.81962 3.00843 1.79858 3.11352 1.79858 3.21968C1.79858 3.32583 1.81962 3.43092 1.86047 3.52876C1.90131 3.6266 1.96114 3.71523 2.03643 3.78942C2.18782 3.94203 2.39239 4.02888 2.60643 4.03144H4.03643L4.03643 7.96422C4.03643 8.23166 4.14179 8.48815 4.32932 8.67727C4.51686 8.86638 4.77121 8.97262 5.03643 8.97262C5.30165 8.97262 5.556 8.86638 5.74354 8.67727C5.93107 8.48815 6.03643 8.23166 6.03643 7.96422L6.03643 4.03144L7.43643 4.03144C7.6486 4.03144 7.85208 3.94645 8.00211 3.79516C8.15214 3.64387 8.23643 3.43867 8.23643 3.22472C8.24266 3.12031 8.22819 3.0157 8.19385 2.91701C8.15951 2.81831 8.10599 2.72751 8.03643 2.64993L5.60643 0.239844Z" />
  </SvgIcon>
);

export default Import;
