import * as React from 'react';
import { set, get, uniq, isEmpty as _isEmpty } from 'lodash';
import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';

import { getQueryParams, request } from '@ep/insight-ui/system/backbone/data-source/common';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import {
  checkEpsiloTableEndpoint,
  EpsiloTableObject,
  default as dataRequest,
} from '@ep/insight-ui/system/backbone/data-source/common';

import MassCreate from '../popover-components/mass-create';

import { ETABLE_CONFIG_LIST } from './mock/etable-config';
import produce from 'immer';

type INameSpace = {
  namespace: string;
  value: string;
  label: string;
};

export const useEtableConfig = ({ config, onSubmit, isDashboardConfig, open }) => {
  const [gridApi, setGridApi] = React.useState(null);
  const { onToastMultiple } = useToast();

  const [columnApi, setColumnApi] = React.useState(null);

  // Handle endpoint
  const defaultEndpoint = {
    label: config.endpoint.GET_TABLE_DATA,
    value: config.endpoint.GET_TABLE_DATA,
  };
  const [eTableEndpoint, setETableEndpoint] = React.useState(defaultEndpoint);
  const [endpointOptions, setEndpointOptions] = React.useState([]);

  const handleChangeEndpoint = (value) => {
    setETableEndpoint(value);
  };

  React.useEffect(() => {
    setETableEndpoint(defaultEndpoint);
  }, [open]);

  const [eTableTitle, setEtableTitle] = React.useState(config.title);
  const [eTableDescription, setETableDescription] = React.useState(config.description);
  const [chartEndpoint, setChartEndpoint] = React.useState(config.endpoint.GET_CHART_DATA);
  const [metricEndpoint, setMetricEndpoint] = React.useState(config.endpoint.GET_METRIC_DATA);
  const [listNameSpace, setListNameSpace] = React.useState<INameSpace[]>([]);
  const datetimeFieldDefaultValue = get(config, ['system', 'datetimeField'], 'created_datetime');
  const [datetimeField, setDatetimeField] = React.useState(datetimeFieldDefaultValue);

  const defaultDatetimeField = {
    label: datetimeFieldDefaultValue,
    value: datetimeFieldDefaultValue,
  };

  const etableConfigContext = React.useMemo(() => {
    return {
      mapping: config.mapping,
      endpoint: {
        GET_TABLE_DATA: eTableEndpoint.value,
      },
    };
  }, [eTableEndpoint]);

  // Handle recommend endpoint
  const [recommendEndpoint, setRecommendEndpoint] = React.useState('');

  // const handleRecommendEndpoint = (api) => {
  //   const dimensions = ['STOREFRONT', 'ADS_CAMPAIGN', 'ADS_OBJECT', 'KEYWORD', 'SCRIPT'];
  //   const endpoints = [
  //     `${EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY}/mop-query/listing/getStorefrontData`,
  //     `${EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY}/mop-query/listing/getCampaignData`,
  //     `${EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY}/mop-query/listing/getAdsObjectData`,
  //     `${EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY}/mop-query/listing/getAdsPlacementData`,
  //     `${EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY}/mop-query/listing/getScriptData`,
  //   ];
  //   let index = null;
  //   api.forEachNode((node) => {
  //     if (node?.data?.propertyType === 'dimension') {
  //       const newIndex = dimensions.findIndex((el) => {
  //         return Object.values(node.data.valueGetter)
  //           .map((ele) => ele.split('.')[0])
  //           .includes(el);
  //       });
  //       index = Math.max(index, newIndex);
  //       // if ((index === null || newIndex < index) && newIndex != -1) index = newIndex;
  //     }
  //   });

  //   setRecommendEndpoint(endpoints[index]);
  // };

  // Handle primary keys
  const [primaryKeys, setPrimaryKeys] = React.useState(config.primaryKeys);

  const defaultPrimaryKeys = (config.primaryKeys || []).map((key) => ({
    value: key,
    label: key,
  }));

  const primaryKeysOptions = Object.values(config.mapping)
    .reduce((a: string[], b: any) => {
      if (['dimension', 'attribute'].includes(b.propertyType)) {
        return uniq([...a, ...Object.values(b.valueGetter || {})]);
      }
      return a;
    }, [])
    .map((key) => ({
      label: key,
      value: key,
    }));

  const handlePrimaryKeysChange = (options) => {
    setPrimaryKeys(options.map((option) => option.value));
  };

  // Handle views
  const view = config.view || {
    id: 'all',
    name: 'All',
  };
  const views = config.views || [
    {
      id: 'all',
      name: 'All',
      combinator: {
        filter: [],
        sort: [],
        properties: {
          dimension: ['country', 'marketplace', 'storefront', 'ad_tool', 'campaign'],
          attribute: ['campaign_status', 'campaign_timeline', 'campaign_daily_budget', 'campaign_total_budget'],
          metric: [
            'impression',
            'click',
            'cost',
            'items_sold',
            'direct_item_sold',
            'ads_order',
            'direct_ads_order',
            'ads_gmv',
            'direct_gmv',
            'roas',
            'cir',
            'cpc',
            'ctr',
            'cr',
            'direct_roas',
            'cpi',
            'direct_cr',
            'direct_cpi',
            'direct_cir',
          ],
        },
        groupBy: {},
      },
    },
  ];

  const tableDOM = React.useRef(null);

  React.useEffect(() => {
    if (tableDOM.current) {
      tableDOM.current.querySelector('.etable-config').style.height = 'calc(80vh - 150px)';
    }
  }, [tableDOM.current]);

  const defaultRowData = React.useMemo(() => {
    const defaultView = get(config, ['views'], []).find(
      ({ id, initializedView }) => id === 'default' || (id === 'all' && initializedView),
    );

    const defaultColumnOrder = defaultView ? get(defaultView, ['combinator', 'columnOrder'], []) : [];
    const settingType = config.settingType || [{ type: 'dimension' }, { type: 'attribute' }, { type: 'metric' }];

    const opt = {};

    Object.keys(config.mapping).forEach((col) => {
      const propertyType = config.mapping[col]?.propertyType;
      if (propertyType) {
        opt[config.mapping[col]?.propertyType] = (opt[config.mapping[col]?.propertyType] || []).concat(col);
      }
    });

    for (const [key, val] of Object.entries(opt)) {
      opt[key] = val.sort((a, b) => defaultColumnOrder.indexOf(a) - defaultColumnOrder.indexOf(b));
    }

    const columns = settingType.reduce((a, b) => {
      if (opt[b.type]) {
        return a.concat(opt[b.type]);
      }
      return a;
    }, []);

    return JSON.stringify(
      columns
        .filter((f) => !!config.mapping[f])
        .map((field) => {
          const {
            title,
            propertyType,
            cellFormat,
            dataType,
            filterField,
            valueGetter,
            defaultAggregation,
            lockColumnWidth,
            defaultCalculated,
            menu,
            menuItem,
            sortField,
            filterGetter,
            actions = [],
            selectionFilter,
            initColumnWidth,
            initGroupColumnWidth,
            advancedFilter,
            columnDescription,
            systemSettings,
            pivotColumnWidth,
          } = config.mapping[field];

          const staticValue = config.mapping[field].staticValue;
          const useMock = config.mapping[field].useMock || {};

          let migratedActions;
          if (ff.migrate_action_config) {
            migratedActions = actions.map((action) => {
              return {
                ...action,
                configuration: action.configuration.map((el) => {
                  return {
                    ...el,
                    field_configuration: (el.field_configuration || []).reduce((a, b) => {
                      if (b.key === 'input_type.options') {
                        return [...a, ...b.value];
                      }
                      return [...a, b];
                    }, []),
                  };
                }),
              };
            });
          }

          return {
            columnKeys: field,
            title,
            propertyType,
            cellFormat,
            columnDescription,
            dataType,
            filterField,
            valueGetter,
            defaultGroupBy: config.groupBy?.columns?.includes(field) ? 1 : null,
            lockColumnWidth,
            defaultAggregation: defaultAggregation || null,
            defaultCalculated: defaultCalculated || null,
            staticValue,
            menu: menu ? menu : [],
            sortField: sortField,
            filterGetter: filterGetter,
            menuItem: menuItem ? menuItem : [],
            useMock,
            actions: ff.migrate_action_config ? migratedActions : actions,
            selectionFilter: selectionFilter || null,
            initGroupColumnWidth,
            initColumnWidth,
            advancedFilter,
            systemSettings,
            pivotColumnWidth,
          };
        }),
    );
  }, [config]);

  React.useEffect(() => {
    dataRequest.getEnpointNamespaces().then((res) => {
      const response = res.map((el) => {
        return {
          label: el.label,
          value: `${EIP_CONSTANT.API_HOST.API_DATA_CENTER}/v2/query.jsp?namespace=${el.value}`,
        };
      });

      setEndpointOptions(response);
    });
  }, []);

  const namespace = React.useMemo(() => {
    const queryParams = getQueryParams(eTableEndpoint.value);
    return queryParams?.namespace;
  }, [eTableEndpoint.value]);

  const actionButtons = React.useMemo(
    () => [
      ...(namespace
        ? [
            {
              id: 'mass_create_dimension_columns',
              title: 'Mass create dimension columns',
              popoverComponent: MassCreate,
              params: {
                isDimension: true,
              },
            },
            {
              id: 'mass_create_metric_columns',
              title: 'Mass create metric columns',
              popoverComponent: MassCreate,
            },
          ]
        : []),
      ...(checkEpsiloTableEndpoint(eTableEndpoint.value, [EpsiloTableObject.OPERATION, EpsiloTableObject.PERFORMANCE])
        ? [
            {
              id: 'mass_create_metric_columns',
              title: 'Mass create metric columns',
              popoverComponent: MassCreate,
            },
          ]
        : []),
      {
        id: 'delete',
        title: 'Delete selected columns',
        onClick() {
          gridApi.applyTransaction({
            remove: gridApi.getSelectedRows(),
          });
        },
      },
      {
        id: 'duplicate',
        title: 'Duplicate selected columns',
        onClick() {
          gridApi.applyTransaction({
            add: gridApi.getSelectedRows().map((row) => ({
              ...row,
              columnKeys: `${(row.columnKeys || '').split('_clone_')[0]}_clone_${nanoid()}`,
            })),
          });
        },
      },
      {
        id: 'add_row',
        title: 'Add columns',
        onClick() {
          gridApi.applyTransaction({
            add: [
              {
                columnKeys: 'hashtag',
                title: '#Hashtag',
                propertyType: 'attribute',
                cellFormat: 'placeHolder',
                dataType: 'string',
                valueGetter: '{}',
              },
            ],
            addIndex: 0,
          });
        },
      },
      {
        id: 'reset',
        title: 'Reset',
        onClick() {
          gridApi.setRowData(JSON.parse(defaultRowData));
          setETableEndpoint({
            label: config.endpoint.GET_TABLE_DATA,
            value: config.endpoint.GET_TABLE_DATA,
          });
        },
      },
      {
        id: 'fit_column',
        title: 'Fit columns',
        onClick() {
          columnApi.autoSizeAllColumns();
        },
      },
    ],
    [eTableEndpoint.value, gridApi, columnApi, namespace],
  );

  // Etable List
  const [etableList, setEtableList] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/system/block', {
        groupId: 'botep',
        type: 'richTable',
        page: 0,
        limit: 1000,
        attributes: ['title', 'parent_title', 'block_eid'],
      })
      .then((res) => {
        const filteredList = Object.values(res.block).map((el) => {
          const title = get(el, ['title'], '');
          const parentTitle = get(el, ['parent_title'], '');
          const id = get(el, ['block_eid'], '');
          return {
            label: `${parentTitle} / ${title}`,
            value: id,
          };
        });
        setEtableList(filteredList);
      });
    request.get(EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/common/collect-workspace-role').then((res) => {
      if (res.success) {
        setRoles(
          res.data.map((el) => ({
            label: el.name || el.label,
            value: el.id || el.value,
          })),
        );
      }
    });
  }, []);

  const handleSubmit = () => {
    const rowData = [];
    const groupBy: any = {};
    gridApi.forEachNode((node) => rowData.push(node.data));
    const columnOrder = rowData.map(({ columnKeys }) => columnKeys);
    const checkDuplicateKey = (value) => value.filter((item, index) => value.indexOf(item) != index);
    const duplicateKey = checkDuplicateKey(columnOrder);
    if (duplicateKey.length > 0) {
      onToastMultiple({
        title: 'List key is duplicate',
        messages: duplicateKey,
        variant: 'error',
      });
      return;
    }
    const newMapping = rowData.reduce((a, b) => {
      const keyValueGetter = get(b, 'valueGetter.value', '');
      if (!b.columnKeys) return a;
      const currentMappingField = config.mapping[b.columnKeys] || {};
      a[b.columnKeys] = {
        ...(currentMappingField ? currentMappingField : {}),
        cellFormat: b.cellFormat || '',
        dataType: b.dataType || '',
        columnDescription: b.columnDescription || '',
        filterField: b.filterField || '',
        propertyType: b.propertyType || '',
        title: currentMappingField.title && !b.title ? currentMappingField.title : b.title || '',
        valueGetter: b.valueGetter || {},
        defaultCalculated: b.defaultCalculated || '',
        defaultGroupBy: b.defaultGroupBy,
        lockColumnWidth: b.lockColumnWidth || '',
        menu: b.menu ? b.menu : [],
        menuItem: b.menuItem ? b.menuItem : [],
        useMock: { ...b.useMock },
        staticValue: b.staticValue || {},
        actions:
          (b.actions || []).map((action, index) => ({
            ...action,
            shortcut: {
              key: b.columnKeys,
              index,
            },
            id: action.id || uuid(),
          })) || [],
        selectionFilter: b.selectionFilter,
        initColumnWidth: b.initColumnWidth || '',
        initGroupColumnWidth: b.initGroupColumnWidth || '',
        pivotColumnWidth: b.pivotColumnWidth || '',
        advancedFilter: b.advancedFilter,
        systemSettings: b.systemSettings || {},
      };

      if (b.sortField) {
        a[b.columnKeys].sortField = b.sortField;
      }
      if (!b.sortField && a[b.columnKeys].sortField) {
        delete a[b.columnKeys].sortField;
      }
      if (b.filterGetter) {
        a[b.columnKeys].filterGetter = b.filterGetter;
      }
      if (b.defaultGroupBy) {
        if (groupBy.columns) {
          set(groupBy, ['columns'], [...groupBy.columns, b.columnKeys]);
        } else {
          set(groupBy, ['columns'], [b.columnKeys]);
        }
      }
      if (b.defaultCalculated) {
        set(groupBy, ['aggregations', keyValueGetter ? keyValueGetter : b.columnKeys, 'func'], b.defaultCalculated);
      }
      return a;
    }, {});
    const hiddenPropertyColumns = Object.keys(newMapping).filter(
      (key) => newMapping[key].cellFormat === 'filterColumnFormat',
    );
    const newConfig = produce(config, (draft) => {
      draft.endpoint = {
        ...config.endpoint,
        ...(isDashboardConfig
          ? { GET_CHART_DATA: chartEndpoint, GET_METRIC_DATA: metricEndpoint }
          : { GET_TABLE_DATA: eTableEndpoint.value }),
      };
      draft.system = {
        ...config.system,
        hiddenComponents: !config.system?.hiddenComponents
          ? ['majorButton', 'search']
          : config.system?.hiddenComponents,
        datetimeField,
      };
      draft.hiddenPropertyColumns = hiddenPropertyColumns;
      draft.title = eTableTitle;
      draft.description = eTableDescription;
      draft.mapping = newMapping;
      draft.primaryKeys = primaryKeys;
      draft.columnOrder = columnOrder;
      draft.groupBy = groupBy;
      if (config.view?.id === 'default' && config.view?.initializedView) {
        draft.view.combinator.columnOrder = columnOrder;
      }
      config.views.forEach((view, index) => {
        if (view.id === 'default' && view.initializedView) {
          draft.views[index].combinator.columnOrder = columnOrder;
        }
      });
    });
    onSubmit(newConfig);
  };

  // Handle row count
  const [isEmpty, setIsEmpty] = React.useState(false);

  const handleRowChange = (api) => {
    if (api.getDisplayedRowCount() > 0) {
      api.setPinnedBottomRowData([
        {
          columnKeys: api.getDisplayedRowCount(),
        },
      ]);
    }
    setIsEmpty(api.getDisplayedRowCount() === 0);
  };

  return {
    gridApi,
    setGridApi,
    eTableEndpoint,
    setETableEndpoint,
    eTableTitle,
    setEtableTitle,
    defaultPrimaryKeys,
    primaryKeysOptions,
    handlePrimaryKeysChange,
    tableDOM,
    defaultRowData,
    actionButtons,
    view,
    views,
    handleSubmit,
    isEmpty,
    handleRowChange,
    columnApi,
    setColumnApi,
    recommendEndpoint,
    chartEndpoint,
    setChartEndpoint,
    metricEndpoint,
    setMetricEndpoint,
    etableConfigContext,
    listNameSpace,
    endpointOptions,
    handleChangeEndpoint,
    etableList,
    setDatetimeField,
    defaultDatetimeField,
    roles,
    eTableDescription,
    setETableDescription,
  };
};
