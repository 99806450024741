import ButtonAction from '@ep/insight-ui/elements/button/button-action';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import SelectFormSearchInside from '@ep/insight-ui/elements/form-control/select-form/select-form-search-inside';
import { SortType } from '@ep/insight-ui/elements/table/table';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, Button, Divider, Grid, IconButton, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { GridSortDirection } from '@material-ui/data-grid';
import * as React from 'react';
import * as _ from 'lodash';
import { SortDialogProps } from '.';
import Dropdown, { ChildrenDropdownProps } from '../../../dropdown-menu/dropdown';
import { DropdownMenuDataType } from '../../../list-control/menu-list/menu-list';
import SelectForm from '../../../form-control/select-form';
import HeaderList from '../../../list-control/header-list/header-list';
import { OptionSelectType } from '../../../list-control/type';
import Typography from '../../../text-style/Typography';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { ContainerResponsiveContext, NodeEditContext } from '@eip/next/lib/main';
/**
 * ff.sort_mobile_menu:start
 */
import { MobileSortMenu } from '@ep/insight-ui/elements/app-menu/app-menu-mobile';
/**
 * ff.sort_mobile_menu:end
 */
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/* Type */
type CheckboxRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
};
interface SortFormProps extends SortDialogProps, ChildrenDropdownProps {}

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    minWidth: '352px',
    paddingBottom: '6px',
    '& .eip1-MuiMenu-paper': {
      // backgroundColor: 'red',
    },
    width: '100%',
  },
  groupRule: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  formGroup: {
    marginTop: '8px',
    '& .eip1-MuiButton-text': {
      padding: '6px 8px',
    },
  },
  closeBtn: {
    height: '24px',
    width: '24px',
  },
  header: {
    paddingLeft: '0px',
  },
  selectField: {
    '& .eip1-MuiAutocomplete-inputRoot': {
      '& .eip1-MuiAutocomplete-input': {
        paddingLeft: '0px !important',
      },
      padding: '5px 24px 5px 8px',
    },
    '&.queryField .eip1-MuiSelect-outlined': {
      paddingRight: '15%',
    },
    width: '240px',
  },
  noSort: {
    textAlign: 'right',
    color: colors.icon.subdued,
    '& h4': {
      marginTop: '2px',
    },
    '& p': {
      textAlign: 'left',
    },
  },
  buttonAdd: {
    padding: '6px 8px 6px 10px',

    marginTop: '16px',

    marginBottom: '14px',
  },
  iconNoSort: {
    marginTop: '24px',
  },
  buttonAddBottomWrap: {
    marginTop: '8px',
  },
  buttonAddBottom: {
    display: 'flex',
    alignItems: ' center',
    padding: '2px 8px',
    marginLeft: '-5px',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '14px',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-3px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  paper: {
    padding: '8px',
  },
  iconDrag: {
    width: '13px',
    height: '100%',
    marginRight: '8px',
    marginTop: '7px',
  },
  box: {
    // marginBottom: '6px',
  },
  selectSearchPaper: {
    '& .eip1-MuiFormControl-root': {
      width: '100%',
      '& .eip1-MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  mobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  mobileContent: {
    marginTop: '20px',
    padding: '15px',
    height: '100vh',
    backgroundColor: '#FFFFFF',
  },
  mobileCloseBtn: {
    height: '24px',
    width: '24px',
  },
  mobileGroupRule: {
    display: 'flex',
    width: '92%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
  },
}));

export const sortDirection: OptionSelectType[] = [
  {
    label: 'Ascending',
    value: 'asc',
  },
  {
    label: 'Descending',
    value: 'desc',
  },
];
export interface ISortItem {
  field: OptionSelectType;
  sort: GridSortDirection;
  locked?: boolean;
}

const defaultSortModel: ISortItem = {
  field: { label: '', value: '' },
  sort: 'asc' as GridSortDirection,
};

const SortForm = ({ options, sortModel, setSortModel, disabledIconNoFilter = false, ...rest }: SortFormProps) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = false;

  const [tmpSortModel, setTmpSortModel] = React.useState<Array<ISortItem>>(sortModel);

  const { isEditMode } = React.useContext(NodeEditContext);
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const isLockedView = _.get(backboneContext.getConfig('view'), ['isLock'], false);
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');
  // React.useEffect(() => {
  //   setTmpSortModel(sortModel);
  // }, [sortModel]);
  const currentOptions: OptionSelectType[] = React.useMemo(() => {
    const tmpOptions = options.filter((item) =>
      tmpSortModel.findIndex((element) => element.field.value == item.value) == -1 ? true : false,
    );
    return tmpOptions;
  }, [tmpSortModel]);

  const canAddMoreSortModel = _.get(options, ['length'], 0) > _.get(tmpSortModel, ['length'], 0);

  const handleAddRule = () => {
    if (canAddMoreSortModel) {
      const tmpModel = JSON.parse(JSON.stringify(tmpSortModel));
      defaultSortModel.field = currentOptions[0];
      tmpModel.push({ ...defaultSortModel, locked: false });
      setTmpSortModel(tmpModel);
    }
  };
  const handleDeleteRule = (index: number) => {
    const tmpModel = JSON.parse(JSON.stringify(tmpSortModel));
    tmpModel.splice(index, 1);
    setTmpSortModel(tmpModel);
  };
  const handleChangeRule = (
    field: 'field' | 'sort' | 'locked',
    value: string | OptionSelectType | boolean,
    index: number,
  ) => {
    const tmpModel = JSON.parse(JSON.stringify(tmpSortModel));
    tmpModel[index][field] = value;

    setTmpSortModel(tmpModel);
  };

  const getOptions = (currentField: string) => {
    // const tmpOptions = options.filter(
    //   (item) => !tmpSortModel.some((element) => element.field === item.value && element.field !== currentField),
    // );
    const tmpOptions = options.filter((item) =>
      tmpSortModel.findIndex((element) => element.field?.value == item.value && element.field.value !== currentField) ==
      -1
        ? true
        : false,
    );
    return tmpOptions;
  };

  const handleCloseMenu = () => {
    if (rest && rest.onClose) rest.onClose();
  };

  const handleSubmit = () => {
    const tmpModel: Array<ISortItem> = JSON.parse(JSON.stringify(tmpSortModel));
    // setTmpSortModel([]);
    const sortValue: Array<SortType> = tmpModel.map((item) => ({
      field: item.field.value,
      sort: item.sort,
      locked: item.locked,
    }));
    setSortModel(sortValue);

    if (rest && rest.onClose) rest.onClose();
    if (isMobileView) rest.setIsDialogSort(false);
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const tmpItems: ISortItem[] = [...tmpSortModel];
    const dragCard = tmpItems[dragIndex];
    tmpItems.splice(dragIndex, 1);
    tmpItems.splice(hoverIndex, 0, dragCard);
    setTmpSortModel(tmpItems);
    // handler(valueUpdate);
  };
  const SelectBoxRender = ({ draggable, index, children }: CheckboxRenderProps) =>
    draggable && (
      <div className={classes.box}>
        <DragCard key={index} index={index} moveCard={moveCard}>
          {children}
        </DragCard>
      </div>
    );

  const dataMenu: Array<Array<DropdownMenuDataType>> = [
    [
      {
        title: 'Add a sort',
        icon: 'plus',
        onClick: handleAddRule,
      },
    ],
  ];

  React.useEffect(() => {
    if (sortModel.length > 0 && tmpSortModel.length === 0) {
      setSortModel([]);
    }
  }, [tmpSortModel.length]);

  return (
    <>
      {!isMobileView ? (
        <div className={classes.container}>
          <HeaderList title="Sort" variant="h4" onClickAddOn={handleAddRule} className={classes.header} />
          {tmpSortModel.map((rule: ISortItem, index: number) => {
            const lockedSort = isLockedView && !isEditMode && rule.locked;
            return (
              <SelectBoxRender key={index.toString()} draggable={true} index={index}>
                <Grid
                  container
                  direction="row"
                  alignItems="center"
                  wrap="nowrap"
                  justifyContent="space-between"
                  style={{ display: 'flex' }}
                  className={classes.formGroup}
                >
                  <Grid item className={classes.groupRule}>
                    <div className={`${classes.iconDrag} draggable`}>
                      <Icon type={'draggable'} style={{ width: '100%', height: '100%' }} />
                    </div>
                    <SelectFormSearchInside
                      noShadow={true}
                      options={getOptions(_.get(rule, ['field', 'value'], ''))}
                      style={{
                        backgroundColor: '#fff',
                        width: '100%',
                        flexShrink: 1,
                      }}
                      inputWidth={'100%'}
                      value={rule.field}
                      placeholder={'Add name'}
                      placeholderInput={'Search your value'}
                      onChangeValue={(val) => handleChangeRule('field', val, index)}
                      className={`${classes.selectField} queryField`}
                      classNamePaper={`${classes.selectSearchPaper}`}
                      disabled={lockedSort}
                    />
                    <SelectForm
                      noShadow={true}
                      options={sortDirection}
                      style={{ width: '100%', flexShrink: 0.5 }}
                      inputWidth="60%"
                      value={rule.sort}
                      onChange={(e) => handleChangeRule('sort', e.target.value, index)}
                      className={classes.selectField}
                    />
                  </Grid>
                  {isLockedView && isEditMode ? (
                    <Grid item style={{ marginLeft: '10px' }}>
                      <IconButton
                        className={classes.closeBtn}
                        onClick={() => handleChangeRule('locked', !rule.locked, index)}
                      >
                        <Icon
                          type={rule.locked ? 'rmx/lock-line-icon/#0000008a' : 'rmx/lock-unlock-line-icon/#0000008a'}
                          size="12px"
                          colorIcon={colors.icon.default}
                        />
                      </IconButton>
                    </Grid>
                  ) : null}
                  {!lockedSort ? (
                    <Grid item style={{ marginLeft: '10px' }}>
                      <IconButton className={classes.closeBtn} onClick={() => handleDeleteRule(index)}>
                        <Icon type="close" size="10px" colorIcon={colors.icon.default} />
                      </IconButton>
                    </Grid>
                  ) : null}
                </Grid>
              </SelectBoxRender>
            );
          })}
          {tmpSortModel.length === 0 && (
            <Box className={classes.noSort}>
              {!disabledIconNoFilter && (
                <div className={classes.iconNoSort}>
                  <Icon type="noResult" />
                </div>
              )}
              <Typography variant="body2">Use a sort to:</Typography>
              <Typography variant="body2">Order ads by high or low performance conditions</Typography>
              <Typography variant="body2">Order ads by name of storefront, campaign or other dimension</Typography>
              <Typography variant="body2">Show recently lived campaign</Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon type="plus" size="12px" />}
                className={classes.buttonAdd}
                onClick={handleAddRule}
              >
                Add a sort
              </Button>
            </Box>
          )}

          {tmpSortModel.length > 0 && canAddMoreSortModel && (
            <Grid item className={classes.buttonAddBottomWrap}>
              <ButtonAction
                icon="plus"
                label="Add more sorts"
                size={'medium'}
                className={classes.buttonAddBottom}
                variant={'text'}
                onClick={handleAddRule}
              />
            </Grid>
          )}
          {tmpSortModel.length !== 0 && (
            <Grid container direction="row" justifyContent="flex-end" className={classes.formGroup} spacing={3}>
              <Grid item>
                <Button onClick={handleCloseMenu}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    return uawl.start('canSortEtable', { title: titleTable, timerMs: 3000 }).then(() => handleSubmit());
                  }}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          )}
        </div>
      ) : ff.sort_mobile_menu ? (
        <MobileSortMenu
          sortData={tmpSortModel}
          handleAddRule={handleAddRule}
          disabledIconNoFilter={disabledIconNoFilter}
          handleSubmit={handleSubmit}
          handleDeleteRule={handleDeleteRule}
          getOptions={getOptions}
          handleChangeRule={handleChangeRule}
          {...rest}
        />
      ) : (
        <Modal
          open={rest.isDialogSort}
          onClose={() => rest.setIsDialogSort(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box className={classes.mobileContainer}>
            <div className={classes.mobileHeader}>
              <h4 className={classes.mobileTitle}>Sort</h4>
              <div className={classes.mobileButton}>
                <Button
                  color="primary"
                  onClick={() => {
                    rest.setIsDialogSort(false);
                    rest.onClose();
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
            <div className={classes.mobileContent}>
              {tmpSortModel.map((rule: ISortItem, index: number) => (
                <SelectBoxRender key={index.toString()} draggable={true} index={index}>
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                    justifyContent="space-between"
                    style={{ display: 'flex' }}
                    className={classes.formGroup}
                  >
                    <Grid item className={classes.mobileGroupRule}>
                      <div className={`${classes.iconDrag} draggable`}>
                        <Icon type={'draggable'} style={{ width: '100%', height: '100%' }} />
                      </div>
                      <SelectFormSearchInside
                        noShadow={true}
                        options={getOptions(_.get(rule, ['field', 'value'], ''))}
                        style={{
                          backgroundColor: '#fff',
                          width: '100%',
                          flexShrink: 1,
                        }}
                        inputWidth={'100%'}
                        value={rule.field}
                        placeholder={'Add name'}
                        placeholderInput={'Search your value'}
                        onChangeValue={(val) => handleChangeRule('field', val, index)}
                        className={`${classes.selectField} queryField`}
                        classNamePaper={`${classes.selectSearchPaper}`}
                      />
                      <SelectForm
                        noShadow={true}
                        options={sortDirection}
                        style={{ width: '100%', flexShrink: 0.5 }}
                        inputWidth="60%"
                        value={rule.sort}
                        onChange={(e) => handleChangeRule('sort', e.target.value, index)}
                        className={classes.selectField}
                      />
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                      <IconButton className={classes.mobileCloseBtn} onClick={() => handleDeleteRule(index)}>
                        <Icon type="close" size="10px" colorIcon={colors.icon.default} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </SelectBoxRender>
              ))}
              {tmpSortModel.length === 0 && (
                <Box className={classes.noSort}>
                  {!disabledIconNoFilter && (
                    <div className={classes.iconNoSort}>
                      <Icon type="noResult" />
                    </div>
                  )}
                  <Typography variant="body2">Use a sort to:</Typography>
                  <Typography variant="body2">Order ads by high or low performance conditions</Typography>
                  <Typography variant="body2">Order ads by name of storefront, campaign or other dimension</Typography>
                  <Typography variant="body2">Show recently lived campaign</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Icon type="plus" size="12px" />}
                    className={classes.buttonAdd}
                    onClick={handleAddRule}
                  >
                    Add a sort
                  </Button>
                </Box>
              )}
              {tmpSortModel.length > 0 && canAddMoreSortModel && (
                <Grid item className={classes.buttonAddBottomWrap}>
                  <ButtonAction
                    icon="plus"
                    label="Add more sorts"
                    size={'medium'}
                    className={classes.buttonAddBottom}
                    variant={'text'}
                    onClick={handleAddRule}
                  />
                </Grid>
              )}
              {tmpSortModel.length !== 0 && (
                <Grid container direction="row" justifyContent="flex-end" className={classes.formGroup} spacing={3}>
                  <Grid item>
                    <Button onClick={handleCloseMenu}>Cancel</Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        return uawl
                          .start('canSortEtable', { title: titleTable, timerMs: 3000 })
                          .then(() => handleSubmit());
                      }}
                    >
                      Apply
                    </Button>
                  </Grid>
                </Grid>
              )}
            </div>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SortForm;
