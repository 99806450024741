import { Box, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import Typography from '@ep/insight-ui/elements/text-style/Typography';

const useStyles = makeStyles(() => ({
  container: {
    marginBottom: '48px',
    rowGap: '4px',
    zIndex: 1,
    '.eres--small &': {
      marginBottom: '24px',
    },
  },
  label: {
    margin: 0,
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',

    '& .link': {
      color: '#253746',
      cursor: 'pointer',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    '&.center': {
      textAlign: 'center',
    },
  },
}));

interface IFormFooterProps {
  copyright: string;
}

const FormFooter = ({ copyright }: IFormFooterProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.container} container justifyContent={'center'} alignItems={'center'}>
      <Grid item container justifyContent={'center'}>
        <Box display={'flex'} gridGap={'24px'}>
          <Box>
            <p className={classes.label}>
              <a href={'https://epsilo.io/terms-conditions'} tabIndex={-1} className={'link'}>
                Privacy Policy
              </a>
            </p>
          </Box>
          <Box>
            <p className={classes.label}>
              <a href={'https://epsilo.io/privacy-policy'} tabIndex={-1} className={'link'}>
                Terms & conditions
              </a>
            </p>
          </Box>
        </Box>
      </Grid>
      <Grid item>
        <Typography variant="body2" style={{ color: '#8C98A4' }} tabIndex={-1}>
          {copyright}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default FormFooter;
