import { useLog } from '@eip/next/lib/log';
import { apm, ContainerResponsiveContext, EIP_CONSTANT, NodeEditContext, retryTillSuccess } from '@eip/next/lib/main';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get, pick } from 'lodash';
import React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import { NodeData } from '../type';
// FIXME: inline editor only available on editmode
import { BlockErrorDisplay } from './error';

const log = useLog('factory');
const errorLog = log.extend('error');

const bpWidth = [176, 224, 270, 416, Number.POSITIVE_INFINITY];
const bpHeight = [80, 128, 176, 272, Number.POSITIVE_INFINITY];
const GRID_MARGIN = EIP_CONSTANT.GRID_MARGIN;
const bpClassName = EIP_CONSTANT.CONTAINER_RESPONSIVE.breakpoints;

const NodeContainer = styled('div')`
  height: 100%;
  width: 100%;
  font-size: 16px;
  & [data-dbf-event-drilldown] {
    position: relative;
    &:hover .dot-connector {
      visibility: visible;
    }

    .connect-source & .dot-connector {
      visibility: visible;
    }
    & .dot-connector {
      cursor: pointer;
      position: absolute;
      width: 1em;
      height: 1em;
      background: rgba(237, 92, 16, 0.79);
      border-radius: 1em;
      left: calc(50% - 0.5em);
      top: calc(50% - 0.5em);
      visibility: hidden;
      z-index: 1;
    }
  }
`;

export function NodeFactory(props: { data: NodeData; children: JSX.Element; idPrefix?: string; refreshTime?: number }) {
  const chartLibId = props.data.chartLibId;

  // const context = React.useContext(NodeEditContext);
  // const rootNodeContext = React.useContext(DashboardItemContext);
  const memoData = React.useMemo(() => props.data, []);
  const containerRef = React.useRef<HTMLDivElement>();
  const [parentContainer, setParentContainer] = React.useState<HTMLElement>();
  const [containerBreakpointClass, setContainerBreakpointClass] = React.useState({
    width: null,
    height: null,
    className: null,
  });

  React.useLayoutEffect(() => {
    const [result, clear] = retryTillSuccess(() => {
      const placeholder = document.getElementById('block-' + props.data.id);
      return placeholder ? Promise.resolve(placeholder) : Promise.reject(null);
    }, 100);
    result.then((placeholder) => {
      setParentContainer(placeholder);
    });
    return clear;
  }, []);

  // React.useEffect(() => {
  //   let mountedEls;
  //   const tid = window.setInterval(() => {
  //     if (containerRef.current) {
  //       window.clearInterval(tid);
  //       window.requestAnimationFrame(() => {
  //         mountedEls = setupInlineEditor({
  //           containerRef,
  //           chartLibId,
  //           rootNodeContext,
  //           context,
  //           nodeData: props.data,
  //           readOnly: !context.isEditMode,
  //         });
  //       });
  //     }
  //   }, 500);

  //   return () => {
  //     window.clearInterval(tid);
  //   };
  // }, [chartLibId]);

  React.useEffect(() => {
    if (containerRef.current) {
      const animationFrameIds = [];
      const ro = new ResizeObserver((entries) => {
        log(entries, props.data.chartLibId);
        for (const entry of entries) {
          log(entry, props.data.chartLibId);
          const cr = entry.contentRect;
          const width = cr.width;
          const height = cr.height;
          const bpWidthIndex = bpWidth.findIndex((i) => width < i);
          const bpKlass = bpClassName.find((c) => c[1] > width)[0];
          const bpHeightIndex = bpHeight.findIndex((i) => height < i);
          setContainerBreakpointClass({
            width: 'ep-sw-' + bpWidthIndex,
            height: 'ep-sh-' + bpHeightIndex,
            className: bpKlass,
          });
        }
      });

      // Observe one or multiple elements
      ro.observe(containerRef.current);
      if (containerRef.current.querySelector('.block-content-wrapper')) {
        ro.observe(containerRef.current.querySelector('.block-content-wrapper'));
      }

      return () => {
        ro.disconnect();
        animationFrameIds.forEach((id) => window.cancelAnimationFrame(id));
      };
    }
  }, [parentContainer]);

  if (!parentContainer) return null;

  return ReactDOM.createPortal(<ErrorBoundary blockData={memoData}>{props.children}</ErrorBoundary>, parentContainer);

  // return ReactDOM.createPortal(
  //   <React.Fragment>
  //     <NodeContainer
  //       id={[props.idPrefix || '', 'chart-container-', props.data.id].join('')}
  //       // style={{ fontSize: `${fontSize}px` }}
  //       style={{ fontSize: `14px`, height: '100%' }}
  //       className={clsx(
  //         'single-chart-container',
  //         `chart-type-${props.data.chartLibId}`,
  //         !!containerBreakpointClass.width && containerBreakpointClass.width,
  //         !!containerBreakpointClass.height && containerBreakpointClass.height,
  //         containerBreakpointClass.className,
  //       )}
  //       ref={containerRef}
  //     >
  //       <ContainerResponsiveContext.Provider value={{ containerClass: containerBreakpointClass.className }}>
  //         <ErrorBoundary blockData={memoData}>
  //           <div className={'block-content-wrapper'}>{props.children}</div>
  //         </ErrorBoundary>
  //       </ContainerResponsiveContext.Provider>
  //     </NodeContainer>
  //   </React.Fragment>,
  //   parentContainer,
  // );
}

class ErrorBoundary extends React.Component<{ blockData: NodeData }> {
  state = { error: false };

  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    apm.addLabels({ haveCustomData: true });
    apm.addCustomData({
      ...errorInfo,
      settings: pick(this.props.blockData.customAttributes, EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS),
    });
    apm.error(error);
    errorLog(error, errorInfo);
  }

  resetSetting = () => {
    this.props.blockData;
  };

  render() {
    const { children, blockData } = this.props;

    if (blockData.chartLibId === 'richTable' && this.state.error) {
      return (
        <Box>
          Error. Click to <ButtonResetSetting blockData={blockData}></ButtonResetSetting> your settings if you still
          encounter this error persistently.
        </Box>
      );
    }

    if (this.state.error) {
      return <BlockErrorDisplay blockData={blockData} />;
    }
    return children;
  }
}

function ButtonResetSetting({ blockData }) {
  const context = React.useContext(NodeEditContext);
  const [resetClicked, setResetClicked] = React.useState(false);

  return (
    <Typography
      variant="button"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        context.onUpdateCustomAttributes(
          blockData,
          EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS.reduce((carry, i) => {
            return { ...carry, [i]: get(blockData, ['customAttributes', 'system', i], []) };
          }, {}),
        );
        setResetClicked(true);
        window.setTimeout(() => {
          window.location.reload();
        }, 1000);
      }}
    >
      {!resetClicked ? 'reset' : 'resetting...'}
    </Typography>
  );
}
