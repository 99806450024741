import React from 'react';

import { ProgressUI } from '../progress';

export const CompositeProgress = (props: any) => {
  let channels = '';
  const aTarget = props.data.target;

  if (props.showChannels) {
    console.info('channels >>>', props.data.channels);
    channels = props.data.channels.map((item, index) => {
      let target = item.target;
      if (target == 0) {
        target = item.achieved;
      }
      return (
        <ProgressUI
          key={index}
          name={item.name}
          icon={item.icon}
          currency={item.currency}
          target={target}
          achieved={item.achieved}
          today={item.today}
        ></ProgressUI>
      );
    });
  }

  return (
    <div>
      <ProgressUI
        order={props.data.order}
        name={props.data.name}
        icon={props.data.icon}
        currency={props.data.currency}
        target={aTarget == 0 ? props.data.achieved : aTarget}
        achieved={props.data.achieved}
        today={props.data.today}
      ></ProgressUI>
      {channels}
    </div>
  );
};
