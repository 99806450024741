import { Box, createStyles, Grid, LinearProgressProps, makeStyles, Theme, withStyles } from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import * as React from 'react';
import { DropdownCell } from '../dropdown-cell';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import * as _ from 'lodash';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
const useStyles = makeStyles(() => ({
  wrapper: {
    padding: '0 5%',
  },
  label: {
    color: '#0369C7',
    fontStyle: ' normal',
    fontWeight: 'bold',
    fontSize: '11px',
    lineHeight: '16px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
}));
const LinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 4,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#0369C7',
    },
  }),
)(MuiLinearProgress);

export interface IProgressBarFormat {
  value: number;
}

const ProgressBarFormat = (props: IPropsFormat<IProgressBarFormat>) => {
  const classes = useStyles();
  const value = _.get(props, 'value', null);
  const node = _.get(props, 'node', null);
  const cellAction = _.get(props, 'cellAction', []);
  const isGrouped = _.get(props, 'isGrouped', false);
  const valueProgress = _.get(value, 'value', 0);
  const typeTable = _.get(props, 'typeTable', '');
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid
        container
        direction={'column'}
        alignItems={'flex-start'}
        wrap={'nowrap'}
        justifyContent={'center'}
        className={classes.wrapper}
      >
        <Grid item style={{ width: '100%', height: '12px' }}></Grid>
        <Grid container alignItems={'center'} item style={{ width: '100%', height: '6px' }}>
          <LinearProgress style={{ width: '100%' }} variant="determinate" value={valueProgress} />
        </Grid>
        <Grid item>
          <Typography variant="body5">{valueProgress}% updated...</Typography>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default ProgressBarFormat;
