import * as React from 'react';
import { Grid, Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { Redirect } from 'react-router';
import Icon from '@ep/insight-ui/icons/Icon';
import FormFooter from '@ep/insight-ui/elements/input-form/form-templates/footer';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { getPageUrn } from '../global';

const useStyles = makeStyles({
  container: {
    zIndex: 1000,
    display: 'flex',
    minHeight: '100vh',
    minWidth: '100vw',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: 0,
    position: 'fixed',
    top: 0,
    background: '#FFF',
    paddingBottom: '3em',
  },
  logo: {
    textAlign: 'center',
    margin: '32px 0',
  },
  content: {
    borderRadius: '8px',
    marginTop: '36px',
  },
  header: {
    color: '#253746',
    margin: 0,
    marginBottom: '64px',
    '& .title': {
      margin: 0,
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '28px',
      lineHeight: '40px',
      marginBottom: '16px',
      paddingTop: '10px',
    },
    '& .center': {
      textAlign: 'center',
    },
  },
  subTitle: {
    fontSize: '14px',
    fontWeight: 400,
  },
  image: {
    textAlign: 'center',
    '& .eip1-MuiSvgIcon-root': {
      width: '283px !important',
      height: '155px !important',
    },
    '& .MuiSvgIcon-root': {
      width: '283px !important',
      height: '155px !important',
    },
  },
});
const MaintenanceMobileBlock = () => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';

  if (!isMobile) return <Redirect to={getPageUrn('/page')} />;

  return (
    <Grid container className={clsx(`${classes.container}`)}>
      <div>
        <Grid item xs={12} className={classes.logo}>
          <Icon type="logo" colorIcon={'#1A2731'} />
        </Grid>
        <Grid item xs={12} className={clsx(classes.content)}>
          <Box className={classes.image}>
            <Icon type="maintenance" />
          </Box>
          <Grid item xs={12} className={clsx(classes.header)}>
            <p className={clsx(`title center`)}>Device not supported</p>
            <p className={clsx(`${classes.subTitle} center`)}>The mobile view is in development.</p>
            <p className={clsx(`${classes.subTitle} center`)} style={{ marginTop: '-10px' }}>
              Give it a try on your desktop.
            </p>
          </Grid>
        </Grid>
      </div>
      <FormFooter copyright={`© ${new Date().getFullYear()} Epsilo. All Rights Reserved`} />
    </Grid>
  );
};
export default MaintenanceMobileBlock;
