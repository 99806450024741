import * as React from 'react';

import { Button, Grid, makeStyles, Typography } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { getConst } from '@ep/insight-ui/sw/constant/common';

import { StartPage } from './start-page';
import { getAuth0, AUTH0_LOGOUT_URI } from '../global';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 9999,
  },
  logo: {
    textAlign: 'center',
    margin: '32px 0',
  },
  content: {
    color: '#253746',
    padding: '32px 0',
    marginTop: '36px',
    textAlign: 'center',
  },
  link: {
    color: '#0369C7',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

const Waitlist = () => {
  const classes = useStyles();

  const redirectToLogin = () => {
    getAuth0().then((auth0) => {
      auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
    });
  };

  return (
    <StartPage type={'sign-in'}>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.logo}>
          <Icon type="logo" colorIcon={'#1A2731'} />
        </Grid>
        <Grid item xs={12} className={classes.content}>
          <Typography variant={'h4'} style={{ marginBottom: '4px' }}>
            {getConst(
              'WAITLIST_CONTENT',
              'Please contact your administrator to get access to your enterprise workspace',
            )}
          </Typography>
          <Typography variant={'h4'} style={{ marginBottom: '4px' }}>
            or contact our support at support@epsilo.io.
          </Typography>
          <Button
            variant={'contained'}
            color={'primary'}
            onClick={redirectToLogin}
            style={{ marginTop: '20px', padding: '16px' }}
          >
            Login with another account
          </Button>
        </Grid>
      </Grid>
    </StartPage>
  );
};

export default Waitlist;
