import React, { useState, useEffect } from 'react';
import moment from 'moment';

import { Grid, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  timerWrapper: {
    fontWeight: 600,
    fontSize: 13,
    color: '#fff',
  },
  time: {
    padding: '2px 0',
    width: 90,
    textAlign: 'center',
    marginRight: '5px',
  },
});

export const TimerUI = () => {
  const classes = useStyles();
  const query = new window.URLSearchParams(window.location.search);

  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('00  :  00  :  00');
  const [currentTime, setCurrentTime] = useState('00  :  00  :  00');

  useEffect(() => {
    if (query.get('stat-time')) {
      setDate(moment(query.get('stat-time'), 'YYYY-MM-DD').format('MM / DD / YYYY'));
    } else {
      const interval = setInterval(() => {
        const m = moment().utcOffset(8);
        setDate(m.format('MM / DD / YYYY'));
        setCurrentTime(m.format('HH : mm : ss'));
      }, 1000);
      return () => clearInterval(interval);
    }
  }, []);

  return (
    <Box display="flex" alignItems="center" className={classes.timerWrapper}>
      <Box className={classes.time}>{date}</Box>
      <Box className={classes.time}>{currentTime}</Box>
      <Box>GMT+8</Box>
    </Box>
  );
};
