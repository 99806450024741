import * as React from 'react';
import { get } from 'lodash';

import { TableBackboneContext, SPECIAL_FILTERS } from '@ep/insight-ui/system/backbone/table-backbone';
import { getQueryParams } from '@ep/insight-ui/system/backbone/data-source/common';

enum ExternalFilterType {
  UPDATE = 'UPDATE',
  REMOVE = 'REMOVE',
}

const SYNC_EXTERNAL_FILTER_EVENT = 'sync_external_filter';

export const useSpecialButtonFilter = () => {
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;

  const availableColumns = backbone.getAvailableColumns();
  const specialFilterFields = React.useMemo(
    () => backbone.addon('externalFilter.getFields', () => [])(),
    [availableColumns],
  );

  const endpoint = get(backbone.getConfig('endpoint'), ['GET_TABLE_DATA'], '');
  const queryParams = getQueryParams(endpoint);
  const namespace = queryParams.namespace;

  // This linkToGlobalFilterOldChart variable is only for old chart config
  const linkToGlobalFilterOldChart = get(backbone.getConfig('chartConfig'), ['config', 'linkToGlobalFilter'], null);

  const isLinkedToGlobalFilter = (linkToGlobalFilterOldChart || backbone.getConfig('linkToGlobalFilter')) === 'yes';

  React.useEffect(() => {
    const handleEvent = (e) => {
      const hasField = specialFilterFields.some(({ id }) => id === get(e, ['detail', 'data', '0'], ''));
      if (isLinkedToGlobalFilter && hasField) {
        if (e.detail.type === ExternalFilterType.UPDATE) {
          updateSpecialConfig(...e.detail.data, false);
        } else if (e.detail.type === ExternalFilterType.REMOVE) {
          removeSpecialConfig(...e.detail.data, false);
        }
      }
    };
    window.addEventListener(SYNC_EXTERNAL_FILTER_EVENT, handleEvent);

    return () => {
      window.removeEventListener(SYNC_EXTERNAL_FILTER_EVENT, handleEvent);
    };
  }, [specialFilterFields]);

  // const specialFilterFields = React.useMemo(() => backbone.addon('externalFilter.getFields', () => []), []);
  const defaultValues = backbone.getConfig(SPECIAL_FILTERS) || {};
  // const defaultValues = backbone.addon('externalFilter.getConfig');

  const updateSpecialConfig = (field, value, sendToGlobal = true) => {
    if (sendToGlobal) {
      window.dispatchEvent(
        new CustomEvent(SYNC_EXTERNAL_FILTER_EVENT, {
          detail: {
            data: [field, value],
            type: ExternalFilterType.UPDATE,
          },
        }),
      );
    } else {
      if (specialFilterFields.some((el) => el.id === field)) {
        const newConfig = {
          ...defaultValues,
          [field]: {
            ...defaultValues[field],
            ...value,
          },
        };

        backbone.changeConfig(SPECIAL_FILTERS, newConfig);
      }
    }
  };

  const removeSpecialConfig = (field, sendToGlobal = true) => {
    if (sendToGlobal) {
      window.dispatchEvent(
        new CustomEvent(SYNC_EXTERNAL_FILTER_EVENT, {
          detail: {
            data: [field],
            type: ExternalFilterType.REMOVE,
          },
        }),
      );
    } else {
      if (specialFilterFields.some((el) => el.id === field)) {
        const newConfig = { ...defaultValues };

        delete newConfig[field];

        backbone.changeConfig(SPECIAL_FILTERS, newConfig);
      }
    }
  };

  const formatValue = (value, filter) => {
    return {
      dataType: filter.dataType,
      field: filter.id,
      logicalOperator: 'and',
      queryType: 'IN',
      queryField: filter.field,
      queryValue: [].concat(value.value),
      queryValueLabel: value.label,
      additionalInfomrations: value.additionalInfomrations,
      type: 'filter',
    };
  };

  const onSubmit = ({ filter, payload }) => {
    if (payload.length === 0) {
      removeSpecialConfig(filter.id, isLinkedToGlobalFilter);
    } else {
      const length = filter.isMultiple || filter.isMultiple == undefined ? payload.length : 1;
      updateSpecialConfig(
        filter.id,
        formatValue(
          {
            value: payload.slice(0, length).map((option) => option.value),
            label: payload
              .slice(0, length)
              .map((option) => option.label)
              .join(','),
            additionalInfomrations: payload.slice(0, length).map((option) => option.additionalInfomrations),
          },
          filter,
        ),
        isLinkedToGlobalFilter,
      );
    }
  };

  return {
    backbone,
    specialFilterFields,
    defaultValues,
    onSubmit,
  };
};
