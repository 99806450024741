import * as React from 'react';

import { Box, Grid, makeStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import ButtonAction from '@ep/insight-ui/elements/button/button-action';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import { calendarStyle } from '@ep/insight-ui/elements/form-control/calendar/helper';

import BudgetDailyItem from './item';

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    minWidth: '352px',
    marginTop: '8px',
    '& .eip1-MuiMenu-paper': {
      // backgroundColor: 'red',
    },
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      '&:hover': {
        '& input': {
          borderColor: '#006EC6',
        },
        '& .eip1-MuiOutlinedInput-notchedOutline': {
          borderColor: '#006EC6',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: '0px 8px',
      height: 32,
    },
    width: '100%',
  },
  groupRule: {
    display: 'flex',
    width: '100%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
    columnGap: '8px',
    alignItems: 'center',
  },
  groupItem: {
    width: '200px',
  },
  formGroup: {
    marginTop: '8px',
    '& .eip1-MuiButton-text': {
      padding: '6px 8px',
    },
  },
  closeBtn: {
    height: '24px',
    width: '24px',
  },
  header: {
    paddingLeft: '0px',
  },
  selectField: {
    '& .eip1-MuiAutocomplete-inputRoot': {
      '& .eip1-MuiAutocomplete-input': {
        paddingLeft: '0px !important',
      },
      padding: '5px 24px 5px 8px',
    },
    '&.queryField .eip1-MuiSelect-outlined': {
      paddingRight: '15%',
    },
    width: '240px',
  },
  noSort: {
    textAlign: 'right',
    color: colors.icon.subdued,
    '& h4': {
      marginTop: '2px',
    },
    '& p': {
      textAlign: 'left',
    },
  },
  buttonAdd: {
    padding: '6px 8px 6px 10px',

    marginTop: '16px',

    marginBottom: '14px',
  },
  iconNoSort: {
    marginTop: '24px',
  },
  buttonAddBottomWrap: {
    marginTop: '8px',
  },
  buttonAddBottom: {
    display: 'flex',
    alignItems: ' center',
    padding: '2px 8px',
    marginLeft: '-5px',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '14px',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-3px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  paper: {
    padding: '8px',
  },
  box: {
    // marginBottom: '6px',
  },
  selectSearchPaper: {
    '& .eip1-MuiFormControl-root': {
      width: '100%',
      '& .eip1-MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  mobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  mobileContent: {
    marginTop: '20px',
    padding: '15px',
    height: '100vh',
    backgroundColor: '#FFFFFF',
  },
  mobileCloseBtn: {
    height: '24px',
    width: '24px',
  },
  mobileGroupRule: {
    display: 'flex',
    width: '92%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  adornment: {
    cursor: 'pointer',
  },
  popover: {
    '&.eip1-MuiPaper-rounded': {
      padding: 0,
    },
    '& .rmdp-top-class ': {
      '& .right': {
        justifyItems: 'center',
        padding: '0 8px',
        '& > div:nth-child(2)': {
          width: '100%',
        },
        width: '100%',
      },
    },
    '& .rmdp-analog-clock': {
      width: '140px',
      height: '140px',
      backgroundColor: '#fff',
      borderColor: '#79838e',
    },
    '& .rmdp-analog-clock .rmdp-hour': {
      backgroundColor: '#0074d9',
    },
    '& .rmdp-analog-clock .dial-lines': {
      background: '#C2C7CB',
    },
    '& .rmdp-analog-clock .dial-lines:nth-of-type(5n)': {
      backgroundColor: '#8C98A4',
    },
    '& .rmdp-time-picker': {
      '& .rmdp-down i': {
        marginTop: '7px',
      },
      '& div.disabled': {
        pointerEvents: 'none',
        '& .rmdp-arrow': {
          borderColor: '#E4E7E9',
        },
        '& input': {
          background: '#F6F7F8',
          color: '#C2C7CB',
        },
      },
    },
  },
  calendar: {
    ...(calendarStyle as CreateCSSProperties),
    minWidth: '500px',
    padding: '8px',
  },
}));

const ScheduleDaily = ({
  items,
  setItems,
  handleAdd,
  handleDelete,
  handleUpdate,
  isLoading,
  currency,
  nodeData,
  dateFormat,
  type,
}: any) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <LoadingComponent loading={isLoading}>
        {items.map((item, index) => (
          <BudgetDailyItem
            items={items}
            setItems={setItems}
            key={item.fakeId}
            classes={classes}
            handleDelete={handleDelete}
            handleUpdate={handleUpdate}
            index={index}
            item={item}
            currency={currency}
            nodeData={nodeData}
            dateFormat={dateFormat}
            type={type}
          />
        ))}
      </LoadingComponent>
      <Grid item className={classes.buttonAddBottomWrap}>
        <ButtonAction
          icon="plus"
          label="Add more schedule"
          size={'medium'}
          className={classes.buttonAddBottom}
          variant={'text'}
          onClick={handleAdd}
        />
      </Grid>
    </Box>
  );
};

export default ScheduleDaily;
