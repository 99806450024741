import * as React from 'react';
import clsx from 'clsx';

import { Box, ListItem, Divider, makeStyles, FormControl, TextField } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { ContainerResponsiveContext } from '@eip/next/lib/main';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import Icon from '@ep/insight-ui/icons/Icon';
import { searchStyle } from '@ep/insight-ui/lib/epsilo-theme';

import ModalConfirm from '@ep/insight-ui/elements/table/dropdown-views/modal-confirm';

const useStyles = makeStyles(() => ({
  viewItem: {
    position: 'relative',
    padding: 0,
  },
  viewItemInteract: {
    cursor: 'pointer',
  },
  untitledText: {
    color: '#8C98A4',
  },
  viewLabel: {
    padding: '8px 32px 8px 10px',
    height: '32px',
    borderRadius: '4px',
    flex: '0 0 100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  unSelectedView: {
    '&:hover': {
      background: '#E4E7E9',
    },
  },
  selectedView: {
    background: '#EBF6FF',
    color: '#0369C7',
    '&:hover': {
      background: '#EBF6FF',
    },
  },
  threeDots: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
    '& input::placeholder': {
      textTransform: 'unset',
    },
    '& input:focus': {
      outline: 'none',
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  textSearch: {
    '& input': {
      height: 'unset',
      width: '100%',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  hiddenTag: {
    background: '#ccc',
    color: '#fff',
    fontSize: '10px',
    borderRadius: '4px',
    padding: '0 4px',
  },
}));

interface ViewInterface {
  id?: string;
  name?: string;
  combinator?: any;
  systemView?: boolean;
  initializedView?: boolean;
  isNew?: boolean;
  isHidden?: boolean;
  isLock?: boolean;
}

interface IPropsMenuItem {
  view: ViewInterface;
  allowCustomView: boolean;
  isEditMode: boolean;
  selectView: (view: ViewInterface) => void;
  selectedView: ViewInterface;
  handleDuplicateView: (view: ViewInterface) => void;
  updateView: (view: ViewInterface) => void;
  deleteView: (view: ViewInterface) => void;
  resetView: (view: ViewInterface) => void;
  systemViews: ViewInterface[];
}

const MenuItem = ({
  view,
  allowCustomView,
  selectView,
  selectedView,
  handleDuplicateView,
  updateView,
  deleteView,
  resetView,
  systemViews,
  isEditMode = false,
}: IPropsMenuItem) => {
  const classes = useStyles();

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const [name, setName] = React.useState(view.name);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (view.isNew) {
      setIsEditing(true);
      const newView = { ...view };
      delete view.isNew;
      updateView(newView);
    }
  }, [view]);

  React.useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.querySelector('input').focus();
    }
  }, [isEditing]);
  React.useEffect(() => {
    updateView({ ...view, name });
  }, [name]);

  const handleSelectView = (view) => {
    if (!isEditing) {
      selectView({ ...view, name });
    }
  };

  const isInSystemViews = systemViews.some(({ id }) => id === view.id);

  return (
    <ListItem className={clsx(classes.viewItem, !isEditing && classes.viewItemInteract)}>
      <Box
        className={clsx(
          !view.name && classes.untitledText,
          classes.viewLabel,
          !isEditing && classes.unSelectedView,
          !isEditing && selectedView.id === view.id && classes.selectedView,
        )}
        onClick={() => handleSelectView(view)}
      >
        <Icon type={'draggable'} />
        {isEditing ? (
          <FormControl fullWidth>
            <TextField
              style={{
                justifyContent: 'center',
                height: '32px',
                zIndex: 1,
                backgroundColor: '#fff',
              }}
              className={`${clsx(classes.textSearch, classes.textStyle)}`}
              placeholder={'Untitled'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') setIsEditing(false);
              }}
              onBlur={() => setIsEditing(false)}
              value={name}
              variant={'outlined'}
              ref={inputRef}
            />
          </FormControl>
        ) : (
          <span>{view.name || 'Untitled'}</span>
        )}
        {view.isHidden ? <Box className={classes.hiddenTag}>hidden</Box> : null}
        {view.isLock ? <Icon type={'rmx/lock-line-icon/#0000008a'} /> : null}
      </Box>
      {allowCustomView && (
        <React.Fragment>
          <Dropdown
            className={classes.threeDots}
            alignMenu="right"
            icon="threeDotsVertical"
            disabledToggleStyle
            label=""
            sizeIcon={'12px'}
            {...(isMobileView
              ? {
                  dataMenuPage: [
                    [
                      {
                        title: 'Rename',
                        icon: 'rename',
                        iconSize: '16px',
                        colorStartIcon: '#2B3245',
                        className: 'view_action',
                        disable: isEditMode ? false : view.systemView,
                        onClick: () => console.log(123456),
                      },
                      {
                        title: 'Duplicate view',
                        icon: 'duplicatePlus',
                        iconSize: '16px',
                        colorStartIcon: '#2B3245',
                        className: 'view_action',
                        onClick: () => console.log(123456),
                      },
                      {
                        title: 'Delete view',
                        icon: 'delete',
                        iconSize: '16px',
                        colorTitle: '#E4553E',
                        colorStartIcon: '#E4553E',
                        className: 'view_action',
                        disable: isEditMode ? false : view.systemView,
                        onClick: () => console.log(123456),
                      },
                    ],
                  ],
                }
              : {})}
          >
            {(isEditMode || !view.systemView) && (
              <MenuList
                listMenu={[
                  [
                    {
                      title: 'Rename',
                      icon: 'pencil',
                      iconSize: '16px',
                      colorStartIcon: '#2B3245',
                      className: 'view_action',
                      onClick: () => setIsEditing(true),
                    },
                  ],
                ]}
                closeAfterClick
              />
            )}
            {isEditMode && (
              <MenuList
                listMenu={[
                  [
                    {
                      title: view.isHidden ? 'Mark as public' : 'Mark as hidden',
                      icon: view.isHidden ? 'rmx/eye-fill-icon/black' : 'rmx/eye-off-fill-icon/black',
                      iconSize: '16px',
                      colorStartIcon: '#2B3245',
                      className: 'view_action',
                      onClick: () =>
                        updateView({
                          ...view,
                          isHidden: !view.isHidden,
                        }),
                    },
                    {
                      title: view.isLock ? 'Unlock view' : 'Lock view',
                      icon: view.isLock ? 'rmx/lock-unlock-line-icon/#0000008a' : 'rmx/lock-line-icon/#0000008a',
                      iconSize: '16px',
                      colorStartIcon: '#2B3245',
                      className: 'view_action',
                      onClick: () =>
                        updateView({
                          ...view,
                          isLock: !view.isLock,
                        }),
                    },
                  ],
                ]}
                closeAfterClick
              />
            )}
            {(isEditMode || !view.systemView) && <Divider />}
            <MenuList
              listMenu={[
                [
                  {
                    title: 'Duplicate view',
                    icon: 'duplicatePlus',
                    iconSize: '16px',
                    colorStartIcon: '#2B3245',
                    className: 'view_action',
                    onClick: () => handleDuplicateView(view),
                  },
                  ...(isInSystemViews
                    ? [
                        {
                          title: 'Reset view',
                          icon: 'reload',
                          iconSize: '16px',
                          colorStartIcon: '#2B3245',
                          className: 'view_action',
                          onClick: () => resetView(view),
                        },
                      ]
                    : []),
                  {
                    title: 'Delete view',
                    icon: 'deleteView',
                    iconSize: '16px',
                    colorTitle: '#E4553E',
                    colorStartIcon: '#E4553E',
                    className: 'view_action',
                    disable: isEditMode ? false : view.systemView || view.id == selectedView.id,
                    onClick: () => setOpenConfirm(true),
                  },
                ],
              ]}
              closeAfterClick
            />
          </Dropdown>
          <ModalConfirm
            title="Deleting a view"
            message="Are you sure you want to delete this view?"
            openModal={openConfirm}
            onClose={() => setOpenConfirm(false)}
            action={() => deleteView(view)}
          />
        </React.Fragment>
      )}
    </ListItem>
  );
};

export default MenuItem;
