import * as React from 'react';
import { EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import { set } from 'lodash';

const fetchUrl = EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=user_action';
const upsertUrl = EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/update.jsp';
export const useGuideMe = () => {
  const fetchGuidelineSection = (tableId) => {
    const payload = {
      dimensions: ['guideline_section'],
      attributes: [
        'guideline_section.id',
        'guideline_section.icon',
        'guideline_section.level',
        'guideline_section.demo_base64',
        'guideline_section.demo_link',
        'guideline_section.parent_id',
        'guideline_section.created_at',
        'guideline_section.created_by',
        'guideline_section.section_id',
        'guideline_section.updated_at',
        'guideline_section.updated_by',
        'guideline_section.display_order',
        'guideline_section.use_case_name',
        'guideline_section.use_case_description',
        'guideline_section.disabled',
        'guideline_section.access_by',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: 100,
      },
      from: '2024-06-28',
      to: '2024-07-28',
      sort: [
        { field: 'guideline_section.level', sort: 'ASC' },
        { field: 'guideline_section.created_at', sort: 'ASC' },
      ],
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'guideline_section.section_id',
            operator: 'is',
            value: tableId,
          },
          {
            field: 'created_datetime',
            operator: '>=',
            value: '2024-06-28 00:00:00.000',
          },
          {
            field: 'created_datetime',
            operator: '<=',
            value: '2024-07-28 23:59:59.999',
          },
        ],
      },
    };
    return eipRequest.post(fetchUrl, payload).then((res) => {
      let rows = [];
      try {
        set(res, 'data.masterData', res.masterData);
        set(res, 'data.masterDataPrimaryKey', res.masterDataPrimaryKey);
        const processedResult = produceQueryResult(res);
        rows = processedResult.rows.map((row) => {
          Object.keys(row).forEach((key) => {
            if (key.startsWith('guideline_section.')) {
              row[key.split('.')[1]] = row[key];
            }
          });
          return row;
        });
      } catch (error) {
        console.error(error);
      }
      return rows || [];
    });
  };

  const upsertGuideline = (body, id?) => {
    const payload = {
      dimension: 'guideline_section',
      namespace: 'user_action',
      newValues: body,
    };
    if (id) {
      payload.data = [{ id: id }];
    } else {
      payload.operation = 'add';
    }
    return eipRequest.post(upsertUrl, payload).then((res) => {
      return res;
    });
  };

  return {
    fetchGuidelineSection,
    upsertGuideline,
  };
};
