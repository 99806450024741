import moment from 'moment';
import { DATETIME_REGEX } from '../etable/constant';

type DateValue = {
  dateFrom: string;
  dateTo: string;
};

export const getDateRangeFromOption = (
  option: string,
  currentDateRange: DateValue,
  dateTimeFormat = 'YYYY-MM-DD',
): DateValue => {
  let dateFrom, dateTo;
  let type, qty, period;
  const startedDate = moment(currentDateRange.dateFrom);
  const endedDate = moment(currentDateRange.dateTo);
  if (!option.startsWith('=')) {
    if (option.toLowerCase() === 'the_previous' || option.toLowerCase() === 'last_1_days') {
      let sDate, eDate, interval, unit;
      if (DATETIME_REGEX.test(currentDateRange.dateFrom)) {
        sDate = moment(currentDateRange.dateFrom);
        eDate = moment(currentDateRange.dateTo);
        interval = moment.duration(eDate.diff(sDate)).asHours() + 1;
        unit = 'hours';
      } else {
        sDate = moment(currentDateRange.dateFrom).startOf('day');
        eDate = moment(currentDateRange.dateTo).startOf('day');
        interval = moment.duration(eDate.diff(sDate)).asDays() + 1;
        unit = 'days';
      }
      dateFrom = moment(startedDate).subtract(interval, unit);
      dateTo = moment(endedDate).subtract(interval, unit);
    } else if (option.toLowerCase().endsWith('_to_date')) {
      [period] = option.toLowerCase().split('_');
      dateFrom = moment(startedDate).startOf(period);
      dateTo = moment();
    } else {
      [type, qty, period] = option.toLowerCase().split('_');

      if (!period || type === 'this') {
        period = qty;
        qty = 0;
      }

      if (type === 'last') {
        dateFrom = moment(startedDate).subtract(+qty || 1, period);
        dateTo = moment(endedDate).subtract(+qty || 1, period);
      }

      if (type === 'this') {
        dateFrom = moment(startedDate).startOf(period);
        dateTo = moment(endedDate).startOf(period);
      }
    }
  } else {
    const [from, to] = option.replace(/(^=|\s)/g, '').split(',');
    if (moment(from).isValid()) {
      dateFrom = moment(from);
    } else if (from.startsWith('-')) {
      const qty: any = (from.match(/\d+/) || [])[0] || 0;
      const period: any = ((from.match(/[a-z]+/i) || [])[0] || '').toLowerCase();
      dateFrom = moment().subtract(+qty + 1, period);
    }
    if (!to) {
      dateTo = moment();
    } else if (moment(to).isValid()) {
      dateTo = moment(to);
    } else if (to.startsWith('-')) {
      const qty: any = (to.match(/\d+/) || [])[0] || 0;
      const period: any = ((to.match(/[a-z]+/i) || [])[0] || '').toLowerCase();
      dateTo = moment().subtract(+qty + 1, period);
    }
  }

  if (!!dateFrom && !!dateTo) {
    return {
      dateFrom: dateFrom.format(dateTimeFormat),
      dateTo: dateTo.format(dateTimeFormat),
    };
  }
  return currentDateRange;
};
