import * as React from 'react';
import { noSelectStyled, TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import moment from 'moment';
import * as _ from 'lodash';
import { colorsChartLegend, PopLegend, tableTooltip, legendLine } from '../helper';
import numeral from 'numeral';
import { HeatmapChartOptions } from '@carbon/charts/interfaces';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: 999,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();

  const backbone = React.useContext(TableBackboneContext);

  const isTimeSelect = backbone.getConfig('groupBy.columns', []).filter((el) => el).length <= 1;
  const { dateFrom, dateTo } = backbone.getConfig('dateRange', { dateFrom: '', dateTo: '' });

  const customHTML =
    (formatLabel) =>
    (data, ...args) => {
      const tableRoot = document.createElement('table');
      const tableHead = document.createElement('thead');
      const tableBody = document.createElement('tbody');

      const dataObject = (data || []).reduce((carry, v) => {
        return {
          ...carry,
          [v.label]: v,
        };
      }, {});

      const total = dataObject['Total'];
      const left = dataObject['y-value'];
      const bottom = dataObject['x-value'];
      const max = dataObject['Max'];
      const min = dataObject['Min'];

      const isFormula = String(formatLabel).startsWith('=');
      if (isFormula) {
        if (total?.value != undefined) total.value = toValue(formatLabel, { value: total.value });
        if (max?.value != undefined) max.value = toValue(formatLabel, { value: max.value });
        if (min?.value != undefined) min.value = toValue(formatLabel, { value: min.value });
      }

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(isTimeSelect && total ? data[0].value : `${dateFrom} - ${dateTo}`);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      })();

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        const tdHTML = total
          ? `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${total?.color};"></span>
        <div class="content">
          <div class="label">${isTimeSelect ? left?.value : `${left?.value} - ${bottom?.value}`}</div>
          <div class="value">${total?.value}</div>
        </div>
      </td>
      `
          : `
      <td class="legendLine" style="display: flex;flex-direction: column;">
      ${[min, max]
        .map(
          (el) => `<div class="content" style="display: flex; align-items: center; justify-content: space-between">
      <div class="label">${(left || bottom).value} - ${el.label}</div>
      <div class="value">${el.value}</div>
    </div>`,
        )
        .join('')}
      </td>
      `;
        tr.innerHTML = tdHTML;
        tableBody.appendChild(tr);
      })();

      tableRoot.className = `${classes.tableTooltip}`;
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      return tableRoot.outerHTML;
    };

  const defaultOptions: HeatmapChartOptions = {
    title: 'Heatmap',
    axes: {
      left: {
        title: 'Dimensions',
        mapsTo: 'label',
        scaleType: 'labels',
      },
      bottom: {
        title: 'Datetime',
        mapsTo: 'datetime',
        scaleType: 'labels',
      },
    },
    heatmap: {
      colorLegend: {
        title: 'Legend title',
      },
    },
    experimental: true,
    height: '1600px',
    tooltip: {
      customHTML: customHTML({}),
    },
  };

  return {
    customHTML,
    defaultOptions,
  };
};

export const useTableVisible = (ref) => {
  const [isTableShow, setIsTableShow] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains('eip1-MuiTypography-root') &&
        !event.target.classList.contains('eip1-MuiPaper-root') &&
        !event.target.classList.contains('eip1-MuiMenu-paper') &&
        !event.target.classList.contains('eip1-MuiButton-endIcon') &&
        !event.target.classList.contains('eip1-MuiButtonBase-root') &&
        !event.target.classList.contains('eip1-MuiSvgIcon-root') &&
        (!event.target.hasAttribute('aria-hidden') ||
          (event.target.hasAttribute('aria-hidden') && event.target.getAttribute('aria-hidden') === true))
      ) {
        setIsTableShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return {
    ref,
    isTableShow,
    setIsTableShow,
  };
};
