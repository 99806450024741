import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, createStyles, InputBase, makeStyles, Popover, Select, SelectProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import React from 'react';
import Calendar from './calendar';
type PropsStyle = {
  inputWidth: string;
  inputHeight: string;
  inputNoShadow: boolean;
};
const useStyles = makeStyles(() => ({
  select: (props: PropsStyle) => ({
    overflow: 'unset',
    width: props.inputWidth || '100%',
    maxWidth: props.inputWidth || '100%',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '20%',
    },
    '& .eip1-MuiOutlinedInput-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    // height: props.inputHeight || '17px',
  }),
  selectInput: {
    cursor: 'pointer',
    color: colors.text.default,
    '&.placeholder': {
      color: '#8C98A4',
    },
  },
  popper: {
    marginTop: '4px',
    boxSizing: 'border-box',
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    position: 'absolute',
    right: '10px',
    color: colors.icon.default,
  },
  container: {
    '& .eip1-MuiAutocomplete-listbox': {
      padding: '0px !important',
    },
    width: (props: PropsStyle) => props.inputWidth || 'auto',
    '& .eip1-MuiAutocomplete-popupIndicator': {
      marginRight: '5px',
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        color: colors.icon.default,
      },
    },
    '& .eip1-MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.surface.disable,
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
    },
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      fontSize: 13,
    },
    width: '100%',
  },
  paper: {
    // border: '1px solid #d3d4d5',
    padding: 16,
  },
}));
const BootstrapInput = withStyles(() =>
  createStyles({
    root: {
      width: '100%',
    },
    input: {
      height: '17px',
      border: `1px solid ${colors.border.default}`,
      paddingLeft: '8px',
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&:hover': {
        borderColor: colors.border.highlight,
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
      },
    },
  }),
)(InputBase);
interface SelectFormProp extends SelectProps {
  value: string;
  onChangeValue?: (value: string) => void;
  inputWidth?: string;
  inputHeight?: string;
  placeholder?: string;
  placeholderInput?: string;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  className?: string;
  classNamePaper?: string;
  style?: React.CSSProperties;
  noShadow?: boolean;
  defaultFormatReturn?: string;
  formatDateView?: string;
  minDate?: string;
  maxDate?: string;
}
const CalendarSingle = ({
  value = '',
  disabled,
  inputWidth,
  inputHeight,
  onChangeValue,
  placeholder,
  placeholderInput,
  renderOption,
  className,
  classNamePaper,
  style,
  noShadow = false,
  defaultFormatReturn = 'YYYY-MM-DD',
  formatDateView = 'MMMM DD, YYYY',
  minDate = null,
  maxDate = null,
  ...rest
}: SelectFormProp) => {
  const classes = useStyles({ inputWidth, inputHeight, inputNoShadow: noShadow });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    if (!disabled) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const getFormat = (val) => {
    if (!val) return null;
    return moment(val, defaultFormatReturn).format(formatDateView);
  };

  return (
    <React.Fragment>
      <Select
        readOnly
        color={'primary'}
        onClick={handleClick}
        style={{ ...style, color: value ? '#000' : '#8C98A4' }}
        value={value ? value : placeholder}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          transformOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.popper,
          },
        }}
        inputProps={{
          className: `${classes.selectInput} ${value ? '' : 'placeholder'}`,
          style: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            overflowX: 'hidden',
          },
        }}
        variant="outlined"
        IconComponent={() => <Icon type="chevron" className={classes.icon} />}
        disabled={disabled}
        input={<BootstrapInput />}
        {...rest}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        <option value={value}>{getFormat(value)}</option>
      </Select>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handleClose}
        classes={{
          paper: classes.paper,
        }}
      >
        <Box width={'240px'}>
          <Calendar
            value={value}
            defaultFormatReturn={defaultFormatReturn}
            onCloseDialog={handleClose}
            onChange={onChangeValue}
            minDate={minDate}
            maxDate={maxDate}
          />
        </Box>
      </Popover>
    </React.Fragment>
  );
};
export default CalendarSingle;
