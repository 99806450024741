import * as React from 'react';
import { usePage } from '@eip/next/lib/main';
import { cloneDeep, get } from 'lodash';
import { Avatar, Tooltip, makeStyles } from '@material-ui/core';
import { optionsColumnColor } from '@ep/insight-ui/elements/chart-type/data-demo';
import moment from 'moment';
import clsx from 'clsx';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    background: '#fff',
  },
  row: {
    verticalAlign: 'top',
  },
  buttonContainer: {
    '& button:not(:first-child)': {
      marginLeft: '12px',
    },
    marginRight: `${240 - 10}px`,
  },
  buttonItem: {
    marginLeft: '12px',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    fontSize: '12px',
  },
  tooltipContainer: {
    background: '#253746',
    maxWidth: 'none',
  },
  editingContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    marginLeft: '16px',
  },
  othersContainer: {
    color: '#8c98a4',
    marginLeft: '8px',
    cursor: 'default',
  },
  hoverContent: {
    '& > div': {
      width: '16px !important',
      height: '16px !important',
      fontSize: '10px !important',
    },
  },
  gridContainer: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto',
    '& .grid-item': {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '2px',
    },
    maxHeight: '90vh',
    overflow: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
}));

function stringToColor(str: string) {
  if (typeof str !== 'string') return null;
  const sumOfCharCode = [...str].reduce((a, b) => a + b.charCodeAt(), 0);
  const index = sumOfCharCode % optionsColumnColor.slice(1).length;
  return optionsColumnColor.slice(1)[index];
}
function stringAvatar(name: string) {
  return {
    style: {
      backgroundColor: String(name).toLowerCase() == 'quang ' ? '#204d77' : stringToColor(name),
      height: '24px',
      width: '24px',
      fontSize: '12px',
      outline: 'rgba(255, 255, 255, 0.055) solid 1px',
    },
    children: `${name.split(' ')[0][0]?.toUpperCase()}`,
  };
}

const getColorIcon = (userList, email) => {
  const daysAfterCreated = moment().diff(get(userList, [email, 'createdAt'], moment()), 'days');
  const status = get(userList, [email, 'status']);

  if (status != 'active') return 'lightgray';
  if (daysAfterCreated < 30) return 'red';
  if (daysAfterCreated < 90) return 'yellow';
  return 'mint';
};

const TooltipRender = (props) => {
  const iconColor = getColorIcon(props.userList, props.email);
  return (
    <div className={props.classes.tooltip}>
      <span style={{ display: 'flex', alignItems: 'center', columnGap: '4px' }}>
        <Icon type={'ic/ic:baseline-circle/' + iconColor} /> {props.name}
      </span>
      <span>{props.email}</span>
      {Object.keys(props.availableIds).length ? null : <span>Last viewed {moment(props.lastViewedAt).fromNow()}</span>}
    </div>
  );
};

const TooltipRender2 = (props) => {
  return (
    <div className={props.classes.tooltip}>
      <div>LAST VIEWED BY</div>
      <div className={props.classes.gridContainer}>
        {props.data.map((i) => {
          const iconColor = getColorIcon(props.userList, i.email);
          return (
            <>
              <div className={clsx(props.classes.hoverContent, 'grid-item')}>
                <Icon type={'ic/ic:baseline-circle/' + iconColor} />
              </div>
              <div className={clsx(props.classes.hoverContent, 'grid-item')} style={{ marginLeft: '4px' }}>
                <Avatar {...stringAvatar(i.name)} sx={{ width: 12 }} />
              </div>
              <div className={'grid-item'} style={{ padding: '0 8px' }}>
                {i.email}
              </div>
              <div className={'grid-item'} style={{ justifySelf: 'flex-end' }}>
                {Object.keys(i.availableIds).length ? 'online' : moment(i.lastViewedAt).fromNow()}
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
};

const PageView = () => {
  const classes = useStyles();
  const { pageSocket, userList } = usePage();

  const pageSocketData = React.useMemo(() => {
    const members = cloneDeep(pageSocket?.view?.members || {});
    const onlineUsers = Object.values(members)
      .filter((member) => Object.keys(member.availableIds).length)
      .sort((a, b) => b.lastViewedAt - a.lastViewedAt);
    const offlineUsers = Object.values(members)
      .filter((member) => Object.keys(member.availableIds).length == 0)
      .sort((a, b) => b.lastViewedAt - a.lastViewedAt);

    return onlineUsers.concat(offlineUsers);
  }, [pageSocket?.view]);

  const firstFiveRender = React.useMemo(() => {
    return pageSocketData.slice(0, 5).map((i, index, arr) => {
      return (
        <Tooltip
          key={i.email + i.createdAt}
          title={<TooltipRender {...i} classes={classes} userList={userList} />}
          classes={{ tooltip: classes.tooltipContainer }}
        >
          <div
            style={{
              marginLeft: `-6px`,
              zIndex: arr.length - index,
              opacity: Object.keys(i.availableIds).length ? 1 : 0.3,
              outline: 'rgba(255, 255, 255, 0.055) solid 1px',
              fontSize: 12,
            }}
          >
            <Avatar {...stringAvatar(i.name)} />
          </div>
        </Tooltip>
      );
    });
  }, [pageSocketData, userList]);

  const othersRender = React.useMemo(() => {
    const others = pageSocketData.slice(5);
    if (others.length == 0) return null;
    return (
      <Tooltip
        title={<TooltipRender2 data={others} classes={classes} userList={userList} />}
        classes={{ tooltip: classes.tooltipContainer }}
        placement={'bottom-end'}
        interactive
      >
        <div className={classes.othersContainer}>+{others.length}</div>
      </Tooltip>
    );
  }, [pageSocketData, userList]);
  return (
    <div className={classes.editingContainer}>
      {firstFiveRender}
      {othersRender}
    </div>
  );
};

export default PageView;
