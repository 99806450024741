import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Sort = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.01107 9H0.988931C0.0856107 9 -0.315865 10.1 0.286349 10.7L3.29742 13.7C3.69889 14.1 4.30111 14.1 4.70258 13.7L7.71365 10.7C8.31586 10.1 7.91439 9 7.01107 9Z" />
    <path d="M0.988931 5H7.01107C7.91439 5 8.31586 3.9 7.71365 3.3L4.70258 0.3C4.30111 -0.1 3.69889 -0.1 3.29742 0.3L0.286349 3.3C-0.315865 3.9 0.0856107 5 0.988931 5Z" />
  </SvgIcon>
);

export default Sort;
