import { EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import { Button, Typography } from '@material-ui/core';
import { EventEmitter } from 'events';
import { first, get } from 'lodash';
import React from 'react';
import LiveDashboard from './app-dark';

/**
 * ff.reuse_our_chart: start
 */
/**
 * ff.reuse_our_chart: end
 */
/**
 * ff.keep_block_refreshing: end
 */
/**
 * ff.generate_etable_config:end
 */

const log = useLog('block:live-dashboard');
export class EpsiloLiveDashboard implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    const chartNodeData = Object.assign({ customAttributes: {} }, data);
    return <BlockComponent nodeData={chartNodeData} container={dom}></BlockComponent>;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <RichConfigForm data={data} onSubmit={handleSubmit} key={nodeId}></RichConfigForm>;
  }
}

const RichConfigForm = ({ data, onSubmit }: { data: NodeData; onSubmit: (...args: any[]) => void }) => {
  const [isDisableTarget, setDisableTarget] = React.useState(get(data, ['disableTarget'], 'no'));
  const handleSubmit = React.useCallback(() => {
    onSubmit({ disableTarget: isDisableTarget });
  }, [isDisableTarget]);
  return (
    <div>
      <Typography variant="h6">Disable target</Typography>
      <div style={{ width: '100%' }}>
        <SelectMultipleForm
          options={[
            { value: 'no', label: 'No' },
            { value: 'yes', label: 'Yes' },
          ]}
          value={isDisableTarget}
          onChange={(v) => setDisableTarget(first(v))}
          multipleSelect={false}
          searchAble={false}
          isConfigChart={false}
        />
      </div>
      <div>
        <Button variant="contained" color="primary" style={{ marginTop: '15px', width: '100%' }} onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </div>
  );
};

const BlockComponent = ({ nodeData, container }: { nodeData: NodeData; container: any }) => {
  return (
    <div className="epsilo-live-dashboard">
      <LiveDashboard isDisableTarget={get(nodeData, 'customAttributes.disableTarget', 'no') == 'yes'} />
    </div>
  );
};
