import * as React from 'react';
import { Box, Button, makeStyles, Grid, Dialog } from '@material-ui/core';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';

const useStyles = makeStyles({
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '&.buttonCancel': {
      backgroundColor: 'unset',
      boxShadow: 'none',
    },
    '&.buttonDelete': {
      backgroundColor: '#D4290D',
      marginLeft: '10px',
    },
  },
  header: {
    paddingLeft: '0px',
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.message': {
      padding: '10px 0',
    },
  },
});
type Props = {
  data: any;
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  onClose: () => void;
};

const DeleteEditor = (props: Props) => {
  const { setAnchorEl } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div style={{ padding: '0 10px', minWidth: '320px' }}>
          <HeaderList title={'Delete tag'} variant="h1" className={classes.header} />
          <Box className={`${classes.text} message`}>
            <p>Thi action will delete all items that have this tag.</p>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <Button
              color="inherit"
              variant="contained"
              component="span"
              className={`${classes.button} buttonCancel`}
              onClick={handleClose}
            >
              <span className={`${classes.text} textButton`}>Cancel</span>
            </Button>
            <Button variant="contained" component="span" className={`${classes.button} buttonDelete`}>
              <span className={`${classes.text} textButton primary`}>Delete</span>
            </Button>
          </Box>
        </div>
      </Dialog>
    </>
  );
};
export default DeleteEditor;
