import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import Typography from '../text-style/Typography';
import Icon from '@ep/insight-ui/icons/Icon';
import { Grid, IconButton } from '@material-ui/core';
import { responsiveScreenList } from './responsive-description';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Tooltip from '../tooltip';

export const StyledIconButton = withStyles(() => ({
  root: {
    width: '100%',
    height: '100%',
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
}))(IconButton);

/** Style */
const useStyles = makeStyles(() => ({
  currentSize: {
    minWidth: '105px',
  },
  container: {
    width: '316px',
    alignItems: 'center',
  },
  button: {
    width: '50px',
  },
}));

type OnDeviceClickType = (screenType: string, dimensions: { width: number; height: number }) => void;

const handleOnClick: OnDeviceClickType = (screenType, dimensions) => {
  alert(`${screenType} ${dimensions.width}px - ${dimensions.height}px`);
};

function ResponsiveBar({ onDeviceClick = handleOnClick }: { onDeviceClick: OnDeviceClickType }): JSX.Element {
  const classes = useStyles();
  return (
    <Grid container className={classes.container}>
      {responsiveScreenList.map((device, index) => (
        <Grid item xs key={index}>
          <Tooltip
            mode="title"
            title={
              <React.Fragment>
                {device.title} <small style={{ fontWeight: 500 }}>(upcoming)</small>
              </React.Fragment>
            }
            subTitle={device.sizeInfo}
            content={device.description}
          >
            <StyledIconButton onClick={() => onDeviceClick(device.title, device.dimensions)}>
              <Icon type={device.icon} size={device.sizeIcon} aria-label="delete" colorIcon={colors.icon.default} />
            </StyledIconButton>
          </Tooltip>
        </Grid>
      ))}
      <Grid item xs className={classes.currentSize}>
        <Typography variant="body1">896 px - 66.67%</Typography>
      </Grid>
    </Grid>
  );
}

export default ResponsiveBar;
