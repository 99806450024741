import { useLog } from '@eip/next/lib/main';
import { EventEmitter2 as EventEmitter } from 'eventemitter2';
import produce from 'immer';
import { assign, clone, get as _get, has as _has, merge, omit, set as _set } from 'lodash';

const log = useLog('lib:workflow-backbone');
const logError = log.extend('error');

export class ValidationError extends Error {
  code: number = null;
  constructor(message: string) {
    super(message);
    this.code = 400;
  }
}

export class WorkflowError extends Error {
  code: number = null;
  constructor(message: string) {
    super(message);
    this.code = 500;
  }
}

class WorkflowScreenImpl<R, T extends WorkflowRegisterScreen<R>> implements WorkflowScreen<T> {
  _screen: T;
  screenId: string;
  _workflow: Workflow;
  validate: (fieldId: string, value: WorkflowInputValue) => Promise<Record<string, globalThis.ValidationError>>;

  constructor(screen: T, workflow: Workflow) {
    this._screen = screen;
    this.screenId = screen.screenId;
    this._workflow = workflow;

    const validationInput = screen.validation;
    const validations = Object.keys(validationInput).reduce((carry, key) => {
      return { ...carry, [key]: () => undefined };
    }, {});
    log('validations', validations);

    this.validate = async (fieldId, value) => {
      if (validations[fieldId]) {
        try {
          const result = await validations[fieldId].call(
            null,
            Function.prototype,
            async (funName, args) => {
              const result = await workflow.utilFunctions[funName](...args);
              return result;
            },
            async (entity, attr) => {
              if (entity === '#_' && attr === fieldId) {
                return value;
              } else if (entity === '#_') {
                const val = await workflow._getValue([this.screenId, attr]);
                return val;
              } else {
                throw Error('Workflow syntax validation error.');
              }
            },
          );
          log('validation %s - result %s', fieldId, result);
        } catch (error) {
          if (error instanceof ValidationError) {
            return {
              [fieldId]: error,
            };
          }
        }
      }
      return null;
    };
    return this;
  }
  init(): Promise<any> {
    // @ts-ignore
    return this._screen.inputOnDemand.__init__.call(null, this._workflow);
  }
  inputOnDemand(entryKey: keyof R, inputValue: string | number | RecordValue[]): Promise<any> {
    if (this._screen.inputOnDemand[entryKey]) {
      return Promise.resolve(this._screen.inputOnDemand[entryKey](inputValue, this._workflow));
    } else {
      log('missing input on demand function', entryKey);
      return Promise.resolve(null);
    }
  }

  async _setSingle(fieldId: string, data) {
    const error = await this.validate(fieldId, data);
    if (error === null) {
      this._workflow.data.set([this.screenId, fieldId], data);
      this._workflow.emit(`field_change:${this.screenId}`, { [this.screenId]: { [fieldId]: data } });
      return { [fieldId]: data };
    } else {
      throw error;
    }
  }

  set = async (fieldId, data) => {
    if (typeof fieldId === 'string') {
      return this._setSingle(fieldId, data);
    } else {
      for (const [key, value] of Object.entries(fieldId)) {
        await this._setSingle(key, value);
      }
      return fieldId;
    }
  };
  get = async (fieldId) => {
    if (typeof fieldId === 'string') {
      return this._workflow.data.get([this.screenId, fieldId]);
    } else if (fieldId) {
      const value = {};
      for (const id of fieldId) {
        value[id] = this._workflow.data.get([this.screenId, id]);
      }
      return value;
    } else {
      log('screen get data', this._workflow.data.get(this.screenId));
      return this._workflow.data.get(this.screenId);
    }
  };
  save = async (data) => {
    this._workflow.emit(`field_change:${this.screenId}`, data);
    this._workflow.data.set(this._screen.screenId, merge(this._workflow.data.get(this._screen.screenId), data));
    return this._workflow.data.get(this._screen.screenId);
  };

  on = (key, callback) => {
    const eventId = `${key}:${this.screenId}`;
    this._workflow.on(eventId, callback);
    return () => {
      this._workflow.removeListener(eventId, callback);
    };
  };
}

function createWorkflowScreen<T>(screen: WorkflowRegisterScreen<T>, workflow: Workflow): WorkflowScreen<typeof screen> {
  const wfScreen = new WorkflowScreenImpl(screen, workflow);
  return wfScreen;
}

export function createWorkflow(register: WorkflowRegister, utilFuntions: Workflow['utilFunctions']): Workflow {
  const input = register.input;
  let isInit = false;
  const em = new EventEmitter({
    wildcard: true,
    delimiter: ':',
  });
  let dataBag = {
    ...register.input,
  };

  const wf: Workflow = {
    screen: (screenId) => screenBag[screenId],
    getScreenIds: () => Object.keys(screenBag),
    init: ({ context, ..._data } = {}) => {
      if (_data) {
        const data = clone(_data);
        dataBag = merge(dataBag, omit(data, ['_draftData']));
        dataBag = merge(clone(data._draftData), dataBag);
        dataBag.context = context;
      }
      return Promise.resolve(register.init(dataBag)).then((initData) => {
        dataBag = assign(clone(dataBag), initData);
        isInit = true;
        em.emit('ready', dataBag);
        return dataBag;
      });
    },
    on: (eventId, callback: (...args: any) => void) => {
      em.on(eventId, callback);
      if (eventId === 'ready' && isInit) {
        callback(dataBag);
      }
      return () => em.removeListener(eventId, callback);
    },
    emit: (eventId, ...args) => {
      em.emit(eventId, ...args);
    },
    removeListener: (eventId, callback) => {
      em.removeListener(eventId, callback);
    },
    data: {
      set(path, value) {
        // because of this: log('setdata', Object.isSealed(dataBag), Object.isFrozen(dataBag));
        // then _set(dataBag, path, value)
        // FIXME: but WHY? probably because of the immer ??? https://immerjs.github.io/immer/freezing/
        dataBag = produce(dataBag, (draft) => _set(draft, path, value));
        em.emit(`data_change:${path}`, value);
        return value;
      },
      get(path, defaultValue) {
        return _get(dataBag, path, defaultValue);
      },
    },
    getAllData: () => {
      log('getAllData', dataBag);
      return Promise.resolve(dataBag);
    },
    utilFunctions: utilFuntions,
    submit: async (data = {}, options = {}) => {
      let allData = {};
      allData = merge({}, dataBag, data);
      try {
        const result = await register.onSubmit(allData, options);
        em.emit('submit', { result, data: allData }, null);
        return result;
      } catch (error) {
        logError(error, allData);
        em.emit('submit', { result: null, data: allData }, error);
        return error;
      }
    },
    _screenBag: null,
    _getValue: (attr) => {
      if (_has(dataBag, attr)) {
        return _get(dataBag, attr);
      }
    },
    addScreen: async (fromScreenId, screenId) => {
      const nuScreen = createWorkflowScreen({ ...register.screens[fromScreenId], screenId: screenId }, wf);
      screenBag[screenId] = nuScreen;
      return nuScreen;
    },
  };
  const screenBag = Object.values(register.screens).reduce((carry, step) => {
    return {
      ...carry,
      [step.screenId]: createWorkflowScreen(step, wf),
    };
  }, {});

  wf._screenBag = screenBag;

  return wf;
}
