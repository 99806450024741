import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getActionMenu } from './common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign detail',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'ADS_PLACEMENT.id',
    'PRODUCT.id',
    // 'SUGGESTED_KEYWORD.id',
  ],
  viewSetting: [
    {
      id: 'all',
      name: 'All',
      excludeProperty: {
        dimension: ['storefront_ad_object'],
        metric: [],
        attribute: [],
      },
    },
  ],
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  disabledComponents: {
    dimension: ['marketplace', 'country', 'storefront', 'ad_tool', 'storefront_ad_object', 'ad_object'],
  },
  mapping: {
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
      },
      tableConfiguration: null,
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        subtext: 'PRODUCT.product_sid',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.position_updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.general_tag',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
  },
};
