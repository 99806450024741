import {
  BaseTextFieldProps,
  Box,
  debounce,
  IconButton,
  makeStyles,
  OutlinedInput,
  OutlinedInputProps,
  Select,
  TextField,
} from '@material-ui/core';
import * as React from 'react';
import * as _ from 'lodash';
import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';
import Icon from '@ep/insight-ui/icons/Icon';
import FormControlTemplate from '../form-control-template';
import clsx from 'clsx';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { colors, getCustomPlaceHolder, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import SelectSearchForm from '@ep/insight-ui/elements/form-control/select-form/select-search-form';
import numeral from 'numeral';

interface IProps {
  currencyBoxWidth: number;
}

const useStyles = makeStyles(() => ({
  textStyle: {
    ...(textFieldStyle as CreateCSSProperties),
    '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
      width: '100%',

      '& .eip1-MuiOutlinedInput-notchedOutline': {
        borderColor: colors.border.subdued,
        color: colors.icon.disabled,
      },
    },
    '& .eip1-MuiInputBase-root.Mui-disabled': {
      pointerEvents: 'none',
    },
    width: '100%',
  },
  isCorrect: textFieldStyle.isCorrect as CreateCSSProperties,
  textFormat: {
    width: '100%',
    maxHeight: '32px',
    '& .eip1-MuiOutlinedInput-root': {
      maxHeight: '32px',
      paddingRight: 0,
      '& fieldset.eip1-MuiOutlinedInput-notchedOutline': {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
      },
    },
    '& button svg': {
      width: '12px',
      height: '12px',
    },
    '& input': {
      width: '100%',
      ...getCustomPlaceHolder('#000', {
        fontWeight: 400,
      }),
      paddingLeft: '14px',
      paddingRight: '32px',
      borderRadius: 0,
    },
  },
  box: {
    display: 'flex',
    opacity: 1,
    position: 'relative',
    maxHeight: '32px',
    height: '32px',
    justifyContent: 'center',
    cursor: 'pointer',
    '& .eip1-MuiSvgIcon-root': {
      cursor: 'pointer',
      width: '12px',
      height: '12px',
      // marginTop: '10px',
      // marginLeft: '-12px',
    },
  },
  adornment: {
    display: 'flex',
    alignItems: 'center',
    '&.iconClose': {
      position: 'absolute',
      left: 0,
      padding: '0 10px',
      transform: 'translateX(-32px)',
      cursor: 'pointer',
    },
  },
  currencyBox: {
    cursor: 'pointer',
    width: (props: IProps) => (props.currencyBoxWidth === 0 ? 'auto' : `${props.currencyBoxWidth}px`),
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
    '& > .eip1-MuiBox-root': {
      height: '100%',
      pointerEvents: 'none',
      '& > li': {
        marginTop: 0,
      },
    },
    '& > .dropdown': {
      position: 'relative',
      '& > .eip1-MuiButtonBase-root': {
        margin: 0,
        height: '30px',
      },
    },
    '& > .dropdown + span': {
      pointerEvents: 'none',
      height: '14px',
    },
  },
  currencyBoxPaper: {
    padding: '8px',
    height: 'auto',
  },
  disabled: {
    pointerEvents: 'none',
    color: '#C2C7CB',
    background: '#F6F7F8',
    '& .eip1-MuiSelect-outlined.eip1-MuiSelect-outlined': {
      background: '#F6F7F8',
      borderColor: '#E4E7E9',
    },
    '& input': {
      width: '100%',
      ...getCustomPlaceHolder('#C2C7CB', {
        fontWeight: 400,
      }),
    },
  },
}));

const defaultOptions = [
  {
    label: 'VND',
    value: 'vnd',
  },
  {
    label: 'PHP',
    value: 'php',
  },
  {
    label: 'IDR',
    value: 'idr',
  },
];

export interface TextBudgetInputWithSelectProps extends BaseTextFieldProps {
  onChangeText?: (v: string) => void;
  onChangeCurrency?: (v: string) => void;
  defaultValue?: string;
  currency?: string;
  disableHideIconSelect?: boolean;
  options?: OptionSelectType[];
  budgetType?: 'daily' | 'total' | 'limit';
}
const TextBudgetInputWithSelect = ({
  onChangeText,
  onChangeCurrency,
  defaultValue = '',
  currency = '',
  options = defaultOptions,
  budgetType,
  ...rest
}: TextBudgetInputWithSelectProps) => {
  const [value, setValue] = React.useState<string>(defaultValue);
  const [currencyBoxWidth, setCurrencyBoxWidth] = React.useState<number>(0);
  const ref = React.useRef<HTMLDivElement>(null);
  const classes = useStyles({ currencyBoxWidth });
  React.useEffect(() => {
    if (defaultValue !== value) {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  const handleSetText = React.useCallback(debounce(onChangeText, 500), []);
  React.useEffect(() => {
    handleSetText(value);
  }, [value]);

  React.useEffect(() => {
    const boxValue = ref.current.querySelector('.select .boxValue') as HTMLDivElement;
    const BUTTON_WIDTH = 32;

    const totalLength = boxValue.offsetWidth + BUTTON_WIDTH;
    setCurrencyBoxWidth(totalLength);
  }, [currency]);

  const onChangeValue = (e) => {
    if (e.target.value === '' && budgetType === 'limit') return setValue('0');
    if (e.target.value === '' && budgetType !== 'limit') return setValue('');

    const valueFormat = numeral(e.target.value.replaceAll('.', ',')).format('0,0');
    setValue(valueFormat === '0' && budgetType !== 'limit' ? '' : valueFormat.replaceAll(',', '.'));
  };

  const onClear = () => {
    if (budgetType === 'limit') return setValue('0');
    return setValue('');
  };

  const onChangeCur = (v: string[]) => {
    onChangeCurrency(v[0]);
  };
  return (
    <div style={{ display: 'flex' }} ref={ref}>
      <TextField
        className={clsx(classes.textStyle, classes.textFormat, rest.className, rest.disabled && classes.disabled)}
        variant="outlined"
        onChange={onChangeValue}
        value={value}
        InputProps={{
          endAdornment: (
            <Box className={classes.box} aria-label="toggle password visibility">
              {value && (
                <Box component={'div'} height={'100%'} className={`${classes.adornment} iconClose`} onClick={onClear}>
                  <CloseFiled />
                </Box>
              )}
            </Box>
          ),
        }}
        {...rest}
      />
      <SelectMultipleForm
        options={options}
        value={[currency]}
        searchAble={false}
        onChange={onChangeCur}
        className={classes.currencyBox}
        classNamePaper={classes.currencyBoxPaper}
        disabled={rest.disabled}
      />
    </div>
  );
};
export default TextBudgetInputWithSelect;
