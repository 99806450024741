import React from 'react';
import { sortBy, sumBy, get } from 'lodash';
import flagID from './icons/Indonesia-24px.svg';
import flagSG from './icons/Singapore-24px.svg';
import flagTH from './icons/Thailand-24px.svg';
import flagMY from './icons/Malaysia-24px.svg';
import flagVN from './icons/Vietnam-24px.svg';
import flagPH from './icons/Philippines-24px.svg';
import iconShopee from './icons/channel-shopee.svg';
import iconLazada from './icons/channel-lazada.svg';
import { EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';
import { getConst } from '@ep/insight-ui/sw/constant/common';

const ANALYTIC_URL = EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/live-dashboard/analytic';
// const ANALYTIC_URL = '/localhost:30000' + '/live-dashboard/analytic';

const countryLabel = {
  SG: { name: 'Singapore', icon: flagSG },
  TH: { name: 'Thailand', icon: flagTH },
  MY: { name: 'Malaysia', icon: flagMY },
  PH: { name: 'Philippines', icon: flagPH },
  VN: { name: 'Vietnam', icon: flagVN },
  ID: { name: 'Indonesia', icon: flagID },
};

// let brandLabel = {
//   enfagrow: { name: 'Enfagrow' },
//   dettol: { name: 'Dettol' },
//   enfamil: { name: 'Enfamil' },
//   enfalac: { name: 'Enfalac' },
// };

const channelLabel = {
  shopee: { name: 'Shopee', icon: iconShopee },
  lazada: { name: 'Lazada', icon: iconLazada },
};

export function useRealtimeData(isNoTarget = false) {
  const [data, setData] = React.useState({
    gmv: {
      totalGMV: 0,
      totalGMVTarget: 0,
      shopeeGMV: 0,
      shopeeGMVTarget: 0,
      lazadaGMV: 0,
      lazadaGMVTarget: 0,
      currency: 'USD',
      today: {},
    },
    countries: [],
    brands: [],
  });

  React.useEffect(() => {
    queryData().then((result) => {
      if (result) {
        setData({
          gmv: !isNoTarget ? result.gmv : { ...result.gmv, totalGMVTarget: 0, shopeeGMVTarget: 0, lazadaGMVTarget: 0 },
          countries: !isNoTarget
            ? result.regions
            : result.regions.map((r) => {
                const channels = r.channels;
                return {
                  ...r,
                  target: 0,
                  channels: channels.map((i) => {
                    console.info('target >>> ', i);
                    return { ...i, target: 0 };
                  }),
                };
              }),
          brands: !isNoTarget
            ? result.brands
            : result.brands.map((r) => {
                const channels = r.channels;
                return {
                  ...r,
                  target: 0,
                  channels: channels.map((i) => {
                    console.info('target >>> ', i);
                    return { ...i, target: 0 };
                  }),
                };
              }),
        });
      }
    });

    const tid = window.setInterval(() => {
      queryData().then((result) => {
        if (result) {
          setData({
            gmv: !isNoTarget
              ? result.gmv
              : { ...result.gmv, totalGMVTarget: 0, shopeeGMVTarget: 0, lazadaGMVTarget: 0 },
            countries: !isNoTarget
              ? result.regions
              : result.regions.map((r) => {
                  const channels = r.channels;
                  return {
                    ...r,
                    target: 0,
                    channels: channels.map((i) => {
                      console.info('target >>> ', i);
                      return { ...i, target: 0 };
                    }),
                  };
                }),
            brands: !isNoTarget
              ? result.brands
              : result.brands.map((r) => {
                  const channels = r.channels;
                  return {
                    ...r,
                    target: 0,
                    channels: channels.map((i) => {
                      return { ...i, target: 0 };
                    }),
                  };
                }),
          });
        }
      });
    }, 2 * 60 * 1000);

    return () => {
      window.clearInterval(tid);
    };
  }, []);

  return data;
}

async function queryData() {
  const query = new window.URLSearchParams(window.location.search);
  query.set('client', 'abbott');
  const apiUrl = ANALYTIC_URL + '?' + query.toString();

  console.info('apiurl', apiUrl);

  const { ok, data, history_data: historyData } = await eipRequest.get(apiUrl);

  if (!ok) {
    return false;
  }

  const gmv = {
    totalGMV: 0,
    shopeeGMV: 0,
    lazadaGMV: 0,
    totalGMVTarget: 0,
    shopeeGMVTarget: 0,
    lazadaGMVTarget: 0,
    currency: 'USD',
    today: {},
  };

  const regionsByChannel = data
    .filter((i) => i.analytic_type === 'region')
    .map((i) => {
      return i.gmv_json.map((i1) => ({ ...i1, channel: i.channel }));
    })
    .flat();

  // regions
  const regions = Object.keys(countryLabel).reduce((acc, code) => {
    const channels = regionsByChannel
      .filter((i) => i.regionCode === code)
      .map((i) => {
        return {
          channel: i.channel,
          icon: channelLabel[i.channel].icon,
          name: channelLabel[i.channel].name,
          target: 0,
          achieved: i.sales,
          currency: 'USD',
        };
      });

    const mtdByChannel = ['shopee', 'lazada'].reduce(
      (acc, k) => {
        const record = get(historyData, ['countries', k], []).find((i) => i.country_code === code);

        return {
          ...acc,
          [k]: record,
          // _total: acc._total + get(record, 'gmv_mtd', 0),

          _total: acc._total,
        };
      },
      { _total: 0 },
    );

    const todaySale = channels.reduce((acc, i) => {
      return acc + i.achieved;
    }, 0);
    const sumSale = mtdByChannel._total + todaySale;

    return acc.concat({
      currency: 'USD',
      name: countryLabel[code].name,
      icon: countryLabel[code].icon,
      // target: fakeTarget(sumSale),
      target: getTargetByCountry(code),
      achieved: sumSale,
      today: {
        achieved: todaySale,
      },
      channels: ['shopee', 'lazada'].map((c) => {
        const gmvMTD = get(mtdByChannel, [c, 'gmv_mtd'], 0);
        const todayChannel = channels.find((i) => i.channel === c);

        if (!todayChannel) {
          return {
            channel: c,
            icon: channelLabel[c].icon,
            name: channelLabel[c].name,
            // target: fakeTarget(gmvMTD),
            target: getTargetByCountry(code, c),
            achieved: gmvMTD,
            currency: 'USD',
            today: {
              achieved: 0,
            },
          };
        } else {
          return {
            channel: c,
            icon: channelLabel[c].icon,
            name: channelLabel[c].name,
            target: getTargetByCountry(code, c),
            achieved: gmvMTD + todayChannel.achieved,
            currency: 'USD',
            today: {
              achieved: todayChannel.achieved,
            },
          };
        }
      }),
    });
  }, []);

  const availBrands = getBrandsByTarget();

  const brandsByChannel = data
    .filter((i) => i.analytic_type === 'brand')
    .map((i) => {
      return i.gmv_json.map((i1) => ({ ...i1, channel: i.channel }));
    })
    .flat()
    .filter((i) => availBrands.indexOf(i.brand) > -1);

  const brandLabel = brandsByChannel.reduce((lb, i) => {
    return { ...lb, [i.brand]: { name: i.brandName } };
  }, {});

  // brands items
  const brands = Object.keys(brandLabel).reduce((acc, code) => {
    const channels = brandsByChannel
      .filter((i) => i.brand === code)
      .map((i) => {
        return {
          channel: i.channel,
          name: channelLabel[i.channel].name,
          icon: channelLabel[i.channel].icon,
          target: 0,
          achieved: i.sales,
          currency: 'USD',
        };
      });

    const mtdByChannel = ['shopee', 'lazada'].reduce(
      (acc, k) => {
        const record = historyData.brands[k].find((i) => i.brand === code);
        if (!record) {
          return acc;
        }

        return {
          ...acc,
          [k]: record,
          _total: acc._total + record.gmv_mtd,
        };
      },
      { _total: 0 },
    );

    const todaySale = channels.reduce((acc, i) => {
      return acc + i.achieved;
    }, 0);
    const sumSale = mtdByChannel._total + todaySale;

    return acc.concat({
      currency: 'USD',
      name: brandLabel[code].name,
      // target: fakeTarget(sumSale),
      target: getTargetByBrand(code),
      achieved: sumSale,
      today: {
        achieved: todaySale,
      },
      channels: ['shopee', 'lazada'].map((c) => {
        const gmvMTD = get(mtdByChannel, [c, 'gmv_mtd'], 0);
        const todayChannel = channels.find((i) => i.channel === c);

        if (!todayChannel) {
          return {
            channel: c,
            icon: channelLabel[c].icon,
            name: channelLabel[c].name,
            // target: fakeTarget(gmvMTD),
            target: getTargetByBrand(code, c),
            achieved: gmvMTD,
            currency: 'USD',
            today: {
              achieved: 0,
            },
          };
        } else {
          return {
            channel: c,
            icon: channelLabel[c].icon,
            name: channelLabel[c].name,
            // target: fakeTarget(gmvMTD + todayChannel.achieved),
            target: getTargetByBrand(code, c),
            achieved: gmvMTD + todayChannel.achieved,
            currency: 'USD',
            today: {
              achieved: todayChannel.achieved,
            },
          };
        }
      }),
    });
  }, []);

  const lazadaGMV = sumBy(regions, (i) =>
    get(
      i.channels.find((i1) => i1 && i1.channel === 'lazada'),
      'today.achieved',
      0,
    ),
  );
  const shopeeGMV = sumBy(regions, (i) =>
    get(
      i.channels.find((i1) => i1 && i1.channel === 'shopee'),
      'today.achieved',
      0,
    ),
  );

  const totalGMVMTDShopee = get(historyData, 'countries.shopee', []).reduce((sum, i) => {
    return sum + i.gmv_mtd;
  }, 0);

  const totalGMVMTDLazada = get(historyData, 'countries.lazada', []).reduce((sum, i) => {
    return sum + i.gmv_mtd;
  }, 0);

  // console.info({
  //   totalGMVMTDLazada,
  //   totalGMVMTDShopee,
  //   shopeeGMV,
  //   lazadaGMV,
  // });

  gmv.shopeeGMV = totalGMVMTDShopee + shopeeGMV;
  gmv.lazadaGMV = totalGMVMTDLazada + lazadaGMV;
  gmv.totalGMV = gmv.shopeeGMV + gmv.lazadaGMV;

  gmv.shopeeGMVTarget = Object.keys(countryLabel)
    .map((code) => getTargetByCountry(code, 'shopee'))
    .reduce((sum, v) => sum + v, 0);
  gmv.lazadaGMVTarget = Object.keys(countryLabel)
    .map((code) => getTargetByCountry(code, 'lazada'))
    .reduce((sum, v) => sum + v, 0);
  gmv.totalGMVTarget = gmv.shopeeGMVTarget + gmv.lazadaGMVTarget;

  gmv.today = {
    shopeeGMV,
    lazadaGMV,
    totalGMV: shopeeGMV + lazadaGMV,
  };

  const fin = {
    gmv,
    regions: sortBy(regions, (i) => i.name).map((i, k) => ({
      order: k + 1,
      ...i,
    })),
    brands: sortBy(brands, (i) => ['nutrition', 'health', 'hygiene'].indexOf(i.name.toLowerCase()))
      .map((i, k) => ({
        order: k + 1,
        ...i,
      }))
      .slice(0, 10),
  };

  const brandTotal = {
    mtd: sumBy(fin.brands, (i) => i.achieved),
    today: sumBy(fin.brands, (i) => get(i, 'today.achieved', 0)),
    shopeeMtd: sumBy(fin.brands, (i) => get(i, 'channels.0.achieved', 0)),
    shopeeToday: sumBy(fin.brands, (i) => get(i, 'channels.0.today.achieved', 0)),
    lazadaMtd: sumBy(fin.brands, (i) => get(i, 'channels.1.achieved', 0)),
    lazadaToday: sumBy(fin.brands, (i) => get(i, 'channels.1.today.achieved', 0)),
  };

  // fin.brands.push({
  //   order: fin.brands.length + 1,
  //   currency: 'USD',
  //   name: 'Others',
  //   // target: fakeTarget(sumSale),
  //   target: 1 * Math.max(gmv.totalGMV - brandTotal.mtd, 0),
  //   achieved: Math.max(gmv.totalGMV - brandTotal.mtd, 0),
  //   today: {
  //     achieved: Math.max(
  //       get(gmv, 'today.totalGMV', 0) - brandTotal.today,
  //       0,
  //     ),
  //   },
  //   channels: [
  //     {
  //       channel: 'shopee',
  //       icon: channelLabel['shopee'].icon,
  //       name: channelLabel['shopee'].name,
  //       target: 1 * Math.max(gmv.shopeeGMV - brandTotal.shopeeMtd, 0),
  //       achieved: Math.max(gmv.shopeeGMV - brandTotal.shopeeMtd, 0),
  //       currency: 'USD',
  //       today: {
  //         achieved: Math.max(
  //           get(gmv, 'today.shopeeGMV', 0) - brandTotal.shopeeToday,
  //           0,
  //         ),
  //       },
  //     },
  //     {
  //       channel: 'lazada',
  //       icon: channelLabel['lazada'].icon,
  //       name: channelLabel['lazada'].name,
  //       target: 1 * Math.max(gmv.lazadaGMV - brandTotal.lazadaMtd, 0),
  //       achieved: Math.max(gmv.lazadaGMV - brandTotal.lazadaMtd, 0),
  //       currency: 'USD',
  //       today: {
  //         achieved: Math.max(
  //           get(gmv, 'today.lazadaGMV', 0) - brandTotal.lazadaToday,
  //           0,
  //         ),
  //       },
  //     },
  //   ],
  // });

  return fin;
}

function fakeTarget(sale) {
  return (1 + (Math.random() * 90 + 10) / 100) * sale;
  // return sale;
}

function getTargetByBrand(brandCode, channel = null) {
  const rows = getRawTarget().filter((i) => i.brand === brandCode);
  if (channel === 'shopee') {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_shopee);
    }, 0);
  } else if (channel === 'lazada') {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_lazada);
    }, 0);
  } else {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_lazada) + Number(i.target_shopee);
    }, 0);
  }
}

function getRawCountry() {
  return getConst('LIVE_DASHBOARD_RAW_COUNTRY', [
    {
      country: 'VN',
      target_shopee: '1,457,809',
      target_lazada: '1,817,320',
    },
    {
      country: 'MY',
      target_shopee: '1,154,734',
      target_lazada: '1,270,208',
    },
    {
      country: 'PH',
      target_shopee: '832,354',
      target_lazada: '665,306',
    },
    {
      country: 'TH',
      target_shopee: '455,000',
      target_lazada: '630,000',
    },
    {
      country: 'ID',
      target_shopee: '118,421',
      target_lazada: '164,653',
    },
    {
      country: 'SG',
      target_shopee: '265,942',
      target_lazada: '205,634',
    },
  ]).map((i) => ({
    ...i,
    target_shopee: i.target_shopee.replace(/,/g, ''),
    target_lazada: i.target_lazada.replace(/,/g, ''),
  }));
}

function getTargetByCountry(countryCode, channel = null) {
  const rows = getRawCountry().filter((i) => i.country === countryCode);

  if (channel === 'shopee') {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_shopee);
    }, 0);
  } else if (channel === 'lazada') {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_lazada);
    }, 0);
  } else {
    return rows.reduce((sum, i) => {
      return sum + Number(i.target_lazada) + Number(i.target_shopee);
    }, 0);
  }
}

function getBrandsByTarget() {
  const set = new Set(getRawTarget().map((i) => i.brand));
  // return ['nutrition', 'health', 'hygiene'];
  return Array.from(set);
}

function getRawBuTarget() {
  const rawBuNumbers = [
    {
      country: 'ID',
      brand: 'nutrition',
      target_shopee: '180,000',
      target_lazada: '252,000',
    },
    {
      country: 'MY',
      brand: 'nutrition',
      target_shopee: '407,000',
      target_lazada: '240,000',
    },
    {
      country: 'PH',
      brand: 'nutrition',
      target_shopee: '476,180',
      target_lazada: '342,352',
    },
    {
      country: 'SG',
      brand: 'nutrition',
      target_shopee: '120,000',
      target_lazada: '71,000',
    },
    {
      country: 'TH',
      brand: 'nutrition',
      target_shopee: '312,500',
      target_lazada: '781,250',
    },
    {
      country: 'VN',
      brand: 'nutrition',
      target_shopee: '240,000',
      target_lazada: '205,000',
    },
  ]
    .concat([
      {
        country: 'ID',
        brand: 'health',
        target_shopee: '90,140',
        target_lazada: '18,000',
      },
      {
        country: 'MY',
        brand: 'health',
        target_shopee: '101,500',
        target_lazada: '68,250',
      },
      {
        country: 'PH',
        brand: 'health',
        target_shopee: '16,852',
        target_lazada: '8,184',
      },
      {
        country: 'SG',
        brand: 'health',
        target_shopee: '55,000',
        target_lazada: '66,000',
      },
      {
        country: 'TH',
        brand: 'health',
        target_shopee: '162,500',
        target_lazada: '234,375',
      },
      {
        country: 'VN',
        brand: 'health',
        target_shopee: '90,000',
        target_lazada: '45,000',
      },
    ])
    .concat([
      {
        country: 'ID',
        brand: 'hygiene',
        target_shopee: '8,873',
        target_lazada: '1,786',
      },
      {
        country: 'MY',
        brand: 'hygiene',
        target_shopee: '40,000',
        target_lazada: '40,000',
      },
      {
        country: 'PH',
        brand: 'hygiene',
        target_shopee: '94,777',
        target_lazada: '102,843',
      },
      {
        country: 'SG',
        brand: 'hygiene',
        target_shopee: '18,000',
        target_lazada: '26,000',
      },
      {
        country: 'TH',
        brand: 'hygiene',
        target_shopee: '20,000',
        target_lazada: '30,000',
      },
      {
        country: 'VN',
        brand: 'hygiene',
        target_shopee: '0',
        target_lazada: '0',
      },
    ]);

  return rawBuNumbers.map((i) => ({
    ...i,
    target_lazada: String(i.target_lazada).replace(',', ''),
    target_shopee: String(i.target_shopee).replace(',', ''),
  }));
}

function getRawTarget() {
  return [
    {
      country: 'MY',
      brand: 'ensure',
      target_shopee: '301905',
      target_lazada: '171429',
    },
    {
      country: 'MY',
      brand: 'pediasure',
      target_shopee: '23810',
      target_lazada: '3810',
    },
    {
      country: 'MY',
      brand: 'similac',
      target_shopee: '102381',
      target_lazada: '71429',
    },
    {
      country: 'MY',
      brand: 'glucerna',
      target_shopee: '25714',
      target_lazada: '7143',
    },
    {
      country: 'MY',
      brand: 'abbott',
      target_shopee: '25714',
      target_lazada: '7143',
    },
    {
      country: 'ID',
      brand: 'ensure',
      target_shopee: '285740',
      target_lazada: '674000',
    },
    {
      country: 'ID',
      brand: 'pediasure',
      target_shopee: '',
      target_lazada: '',
    },
    {
      country: 'ID',
      brand: 'similac',
      target_shopee: '26410',
      target_lazada: '28530',
    },
    {
      country: 'ID',
      brand: 'glucerna',
      target_shopee: '85750',
      target_lazada: '7480',
    },
    {
      country: 'ID',
      brand: 'abbott',
      target_shopee: '85750',
      target_lazada: '7480',
    },
    {
      country: 'PH',
      brand: 'ensure',
      target_shopee: '173002',
      target_lazada: '200541',
    },
    {
      country: 'PH',
      brand: 'pediasure',
      target_shopee: '86494',
      target_lazada: '219727',
    },
    {
      country: 'PH',
      brand: 'similac',
      target_shopee: '28615',
      target_lazada: '33056',
    },
    {
      country: 'PH',
      brand: 'glucerna',
      target_shopee: '14597',
      target_lazada: '12968',
    },
    {
      country: 'PH',
      brand: 'abbott',
      target_shopee: '14597',
      target_lazada: '12968',
    },
    {
      country: 'SG',
      brand: 'ensure',
      target_shopee: '193880',
      target_lazada: '195804',
    },
    {
      country: 'SG',
      brand: 'pediasure',
      target_shopee: '47064',
      target_lazada: '103600',
    },
    {
      country: 'SG',
      brand: 'similac',
      target_shopee: '39664',
      target_lazada: '42180',
    },
    {
      country: 'SG',
      brand: 'glucerna',
      target_shopee: '5994',
      target_lazada: '12950',
    },
    {
      country: 'SG',
      brand: 'abbott',
      target_shopee: '5994',
      target_lazada: '12950',
    },
    {
      country: 'TH',
      brand: 'ensure',
      target_shopee: '129909',
      target_lazada: '686764',
    },
    {
      country: 'TH',
      brand: 'pediasure',
      target_shopee: '39515',
      target_lazada: '84759',
    },
    {
      country: 'TH',
      brand: 'similac',
      target_shopee: '24781',
      target_lazada: '65846',
    },
    {
      country: 'VN',
      brand: 'ensure',
      target_shopee: '295000', // 243000
      target_lazada: '450000',
    },
    {
      country: 'VN',
      brand: 'pediasure',
      target_shopee: '120000', // 127000
      target_lazada: '25000',
    },
  ];
}
