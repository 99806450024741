import * as React from 'react';

import { Box } from '@material-ui/core';

const Wrapper = (props) => {
  const ref = React.useRef();

  React.useEffect(() => {
    const re = new ResizeObserver(([element]) => {
      const { top } = element.target.getBoundingClientRect();
      const height = element.target?.offsetHeight;
      if (16 + height + top > window.innerHeight) {
        element.target.style.top = `${top - (16 + height + top - window.innerHeight)}px`;
      }
    });
    let parentNode = ref.current.parentNode;
    for (let i = 0; i < (props.level || 0); i++) {
      try {
        parentNode = parentNode.parentNode;
      } catch {}
    }

    if (parentNode) {
      re.observe(parentNode);

      return () => {
        re.disconnect();
      };
    }
  }, []);

  return <Box ref={ref}>{props.children}</Box>;
};

export default Wrapper;
