import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Chevron = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 2.58211L1.71331 0.295422C1.62049 0.201829 1.51005 0.127543 1.38836 0.0768476C1.26668 0.0261526 1.13616 5.19888e-05 1.00434 5.19872e-05C0.872522 5.19857e-05 0.742005 0.0261526 0.620322 0.0768476C0.49864 0.127543 0.388199 0.201829 0.29537 0.295422C0.201777 0.38825 0.127491 0.498692 0.0767959 0.620374C0.0261007 0.742057 4.92715e-08 0.872573 4.76995e-08 1.00439C4.61276e-08 1.13622 0.0261007 1.26673 0.0767959 1.38841C0.127491 1.5101 0.201777 1.62054 0.29537 1.71337L3.29103 4.70902C3.38386 4.80262 3.4943 4.8769 3.61598 4.9276C3.73766 4.97829 3.86818 5.00439 4 5.00439C4.13182 5.00439 4.26234 4.97829 4.38402 4.9276C4.5057 4.8769 4.61614 4.80262 4.70897 4.70902L7.70463 1.71337C7.79822 1.62054 7.87251 1.5101 7.9232 1.38841C7.9739 1.26673 8 1.13622 8 1.00439C8 0.872573 7.9739 0.742057 7.9232 0.620374C7.87251 0.498692 7.79822 0.38825 7.70463 0.295422C7.6118 0.20183 7.50136 0.127543 7.37968 0.0768476C7.25799 0.0261527 7.12748 5.20602e-05 6.99566 5.20587e-05C6.86384 5.20571e-05 6.73332 0.0261527 6.61164 0.0768476C6.48995 0.127543 6.37951 0.20183 6.28669 0.295422L4 2.58211Z" />
  </SvgIcon>
);

export default Chevron;
