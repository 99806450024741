import { eipRequest } from '@eip/next/lib/main';
import { REQUEST_TIMEOUT } from '@ep/insight-ui/sw/constant';
import { atom } from 'jotai';
import { first, get as _get, omit } from 'lodash';

type HeaderUpdatesType = Record<
  string,
  { token?: string; type: 'error' | 'loading'; code: number; process?: (...args: any[]) => any }
>;

const HeaderUpdates = atom<{
  updates: HeaderUpdatesType;
}>({ updates: {} });
export const headerUpdates = atom(
  (get) => {
    return get(HeaderUpdates).updates;
  },
  (get, set, value: { updates: any }) => {
    set(HeaderUpdates, value);
  },
);

export const clearHeaderUpdates = atom(null, (get, set, value: { columnIds: any[] }) => {
  const { updates } = get(HeaderUpdates);
  if (updates) {
    set(HeaderUpdates, {
      updates: omit(updates, value.columnIds),
    });
  }
});

export const updateHeaderError = atom(
  null,
  (get, set, value: { columnIds: any[]; retryToken: string; tableId: string }) => {
    const { updates } = get(HeaderUpdates);

    const errorUpdates = Object.values(value.columnIds).reduce((updates, id) => {
      return {
        ...updates,
        [id]: {
          type: 'error',
          code: 500,
          token: value.retryToken,
          process: (type) => {
            if (type == 'retry') {
              eipRequest.post('/_eip_retry/etable', {
                token: value.retryToken,
                tableId: value.tableId,
              });
            }
          },
        },
      };
    }, {});

    set(HeaderUpdates, {
      updates: {
        ...updates,
        ...errorUpdates,
      },
    });
  },
);

export const updateHeaderRetry = atom(null, (get, set, value: { token: string }) => {
  const { updates } = get(HeaderUpdates);
  const columnIds = Object.keys(updates).filter((i) => updates[i].token === value.token);

  if (columnIds.length > 0) {
    updates[first(columnIds)].process('retry');
  }

  const loadUpdates = Object.values(columnIds).reduce((updates, id) => {
    return {
      ...updates,
      [id]: {
        ...(updates[id] || {}),
        type: 'loading',
        code: 100,
      },
    };
  }, updates);

  set(HeaderUpdates, {
    updates: loadUpdates as HeaderUpdatesType,
  });

  setTimeout(() => {
    const headerUpdates = get(HeaderUpdates);
    const stillHaveErrors = Object.values(columnIds).some((id) => {
      return _get(headerUpdates, ['updates', id, 'type']) === 'loading';
    });

    if (stillHaveErrors) {
      const errorUpdates = Object.values(columnIds).reduce((updates, id) => {
        return {
          ...updates,
          [id]: {
            ...(updates[id] || {}),
            type: 'error',
            code: 500,
          },
        };
      }, headerUpdates.updates);

      set(HeaderUpdates, {
        updates: errorUpdates as HeaderUpdatesType,
      });
    }
  }, Math.ceil(1.1 * REQUEST_TIMEOUT * 1000));
});
