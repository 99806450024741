import { ContainerResponsiveContext, NodeEditContext, useLog } from '@eip/next/lib/main';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { useTableBackboneClientSide } from '@ep/insight-ui/system/backbone/table-backbone';

import { getDefaultAggFunc } from '@ep/insight-ui/system/util/aggregation';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { produce } from 'immer';
import { get } from 'lodash';
import * as React from 'react';
import { useToast } from '../notifications/hook';
import { NotificationGlobalProvider } from '../notifications/notifications';
import { CompactFactoryProvider } from './compact-factory';
import ExpansionTable from './expansion-table';
import HeaderRight from './table-actions/header-right';
import TableActions from './table-actions/table-actions';
import { aggregationAddon } from './table-addons/aggregation';
import { filterValueAddon } from './table-addons/filter-value';
import HeaderActions from './table-compact/header-actions';
import { cellEditor, cellFormat, initHeaderAction, mainButtonComponent } from './table-helper';
import Table from './tablev27';
import TableClientSide from './table-clientside';

import { useEditorEnhancement } from './hooks/use-editor-enhancement';

/**
 * ff.make_mocking_api_etable:start
 */
import { getColumnData } from '@ep/insight-ui/system/block/etable/demo-data/mocking-data';
import { Provider } from 'jotai';
/**
 * ff.make_mocking_api_etable:end
 */

const log = useLog('lib:table-backbone:table-container');
export interface IConfigTable {
  id?: string;
  configuration: any;
  apiRequest?: any;
  tableType?: string;
  submitRowSelectionOnClosed?: boolean;
  addons?: Record<string, any>;
  callback?: any;
  isCalendarHide?: boolean;
  cellFormat: Record<string, React.Component>;
}
interface TableContainerProps {
  config: IConfigTable;
  changeConfiguration: (value: any) => void;
  selectedRows?: any[];
}
const useStyles = makeStyles(() => ({
  toggle: {},
  wrapperTable: {
    display: 'flex',
    flexDirection: 'column',
    ...(ff.ecompacttable_layout_mobile
      ? {
          '&.wrapperTableMobile': {
            marginTop: '16px',
          },
        }
      : {}),
  },
  ...(ff.integrate_api_sos
    ? {
        tableBlock: {
          border: '1px solid #C2C7CB',
          padding: '6px',
          borderRadius: '16px',
          marginTop: '16px',
        },
        treemapTable: {
          padding: '10px',
        },
      }
    : {}),
}));
const TableContainer: React.FunctionComponent<TableContainerProps> = ({
  config,
  changeConfiguration,
  selectedRows,
}: TableContainerProps) => {
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const { onToastMultiple } = useToast();

  let isMobileView;
  if (ff.ecompacttable_layout_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }
  const { isEditMode } = React.useContext(NodeEditContext);

  const uawl = React.useContext(UaWatchlist);
  const enhanceConfig = React.useMemo(() => {
    return produce(config, (draft) => {
      draft.addons = {
        ...filterValueAddon,
        ...draft.addons,
        ...aggregationAddon,
        ...(!config.addons || !config.addons['datasource.apiRequest.getTableData']
          ? {
              'datasource.apiRequest.getTableData': (params, originalRequest, backboneInstance) => {
                const session = uawl.currentSession();
                session.addContext({ queryParams: params });
                if (backboneInstance) {
                  // const currentFilter = backboneInstance.getConfig('filter');
                  session.addContext({ currentFilter: backboneInstance.config.filter });
                }
                session.ack('be:request');
                params._uaSessionId = session.id();
                if (ff.make_mocking_api_etable) {
                  return originalRequest(params).then((resp) => {
                    try {
                      if (resp.success) {
                        // Mock data
                        let tempData = {};
                        const mapping = config.configuration.mapping;
                        Object.keys(mapping).forEach((key) => {
                          if (get(mapping, [key, 'useMock', 'enabled'], false)) {
                            const valueGetter = mapping[key].valueGetter;
                            Object.keys(valueGetter).forEach((k) => {
                              tempData = {
                                ...tempData,
                                ...getColumnData(valueGetter[k], mapping[key].dataType),
                              };
                            });
                          }
                        });
                        const newRowsData = [];
                        resp.data.rows.forEach((row) => {
                          newRowsData.push({ ...row, ...tempData });
                        });

                        const newResponse = {
                          ...resp,
                          data: {
                            ...resp.data,
                            rows: newRowsData,
                          },
                        };
                        return Promise.resolve(newResponse);
                      } else {
                        return Promise.resolve(resp);
                      }
                    } catch (error) {
                      return Promise.resolve(resp);
                    }
                  });
                } else {
                  return originalRequest(params);
                }
              },
            }
          : {}),
      };
      if (config.tableType === 'compact') {
        let changeLog = [];
        let initSelectedRow = [];
        draft.addons['rowSelect.init'] = (selectedRows) => {
          initSelectedRow = selectedRows;
        };
        draft.addons['rowSelect.changeLog.add'] = (item: { data; isSelected }[], backbone) => {
          changeLog = changeLog.concat([].concat(item));
        };

        draft.addons['onGetSelectedRow'] = (nativeSelectedRow, backbone) => {
          changeLog;
          nativeSelectedRow;
          initSelectedRow;
          backbone;
          const currentSelectedIds = initSelectedRow.map((r) =>
            backbone.getRowId(r, ['_eipCustomId', 'eipCustomRowId']),
          );
          const currentSelectedRows = [].concat(initSelectedRow);
          changeLog.forEach((l) => {
            const lId = backbone.getRowId(l.data, ['_eipCustomId', 'eipCustomRowId']);
            const isSelected = l.isSelected;
            if (isSelected && currentSelectedIds.indexOf(lId) === -1) {
              currentSelectedRows.push(l.data);
              currentSelectedIds.push(lId);
            } else if (!isSelected && currentSelectedIds.indexOf(lId) > -1) {
              const i = currentSelectedIds.indexOf(lId);
              currentSelectedRows.splice(i, 1);
              currentSelectedIds.splice(i, 1);
            }
          });

          return currentSelectedRows;
        };

        draft.addons['datasource.apiRequest.getTableData'] = (params, originalRequest) => {
          return originalRequest(params).then((res) => {
            if (params.pagination.page === 1) {
              changeLog = [];
            }
            return res;
          });
        };
      }
    });
  }, []);

  const backbone = useTableBackbone(enhanceConfig, changeConfiguration);
  const handleChangePin = (field) => {
    // const headers = backbone.getHeaders();
  };

  React.useEffect(() => {
    const headerAction = initHeaderAction(backbone, handleChangePin, isEditMode);
    backbone.registerMainButtonComponent(mainButtonComponent);
    backbone.registerHeaderAction(headerAction);
    backbone.registerCellFormat(ff.generate_etable_config ? get(config, 'cellFormat', cellFormat) : cellFormat);
    backbone.registerCellEditor(cellEditor);
    backbone.registerNotification({
      // success: (msg: string) => enqueueSnackbar(msg, { variant: 'success' }),
      success: (title: string, msg: string[]) => onToastMultiple({ title, messages: msg, variant: 'success' }),
      error: (title: string, msg: string[]) => onToastMultiple({ title, messages: msg, variant: 'error' }),
    });
    backbone.init();
  }, []);

  const headers = backbone.getHeaders();
  const pagination = backbone.getPagination();
  const sort = backbone.getConfig('sort');
  const pinnedColumns = backbone.getConfig('pinnedColumn');
  const columnWidth = backbone.getConfig('columnWidth');
  const titleTable = backbone.getConfig('title');

  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const rowsGroup = backbone.getConfig('groupBy.columns', []);

  React.useEffect(() => {
    backbone.updateSelectedRows(selectedRows);
    backbone.addon('rowSelect.init', (i) => i)(selectedRows);
  }, [selectedRows.length]);

  React.useEffect(() => {
    backbone.getCallback('onBackboneReady')(backbone, config);
    if (config.addons) {
      Object.keys(config.addons).forEach((k) => {
        backbone.registerAddon(k, config.addons[k]);
      });
    }
  }, []);

  useEditorEnhancement(config, backbone);

  log('table headers >>>>', headers);

  const generateTable = (typeTable: string) => {
    const aggregations = () => {
      const configAggregation = backbone.getConfig('groupBy.aggregations', {});
      const obj = {};
      headers.forEach((col) => {
        const propertyType = get(backbone.getCellConfig(col.field), 'propertyType', '');
        const func = _.get(getDefaultAggFunc(propertyType), 'id', '');
        obj[col.field] = { func };
      });
      return { ...obj, ...configAggregation };
    };
    switch (typeTable) {
      case 'compact':
        return (
          <Box width={'904px'} height={'100%'} component={'div'} className={classes.wrapperTable}>
            <HeaderActions />
            <Table
              typeTable={'compact'}
              backbone={backbone}
              columns={headers}
              sort={sort}
              pinnedColumns={pinnedColumns}
              columnWidth={columnWidth}
              rowsGroup={rowsGroup}
              pagination={pagination}
              allowSelect={true}
              selectedRows={selectedRows}
              onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
              onColumnReorder={backbone.changeColumnOrder}
              onChangePagination={backbone.changePagination}
              aggregations={aggregations()}
            />
          </Box>
        );
      case 'clientSide': {
        return (
          <CompactFactoryProvider>
            <ExpansionTable
              defaultExpanded={true}
              label={titleTable}
              className={classes.toggle}
              noSpacer
              headerRight={config.isCalendarHide ? null : <HeaderRight />}
            >
              <Box pb={1} maxHeight={720} className={classes.wrapperTable}>
                <TableActions />
                <TableClientSide
                  backbone={backbone}
                  columns={headers}
                  sort={sort}
                  pinnedColumns={pinnedColumns}
                  columnWidth={columnWidth}
                  rowsGroup={rowsGroup}
                  pagination={pagination}
                  allowSelect={true}
                  selectedRows={selectedRows}
                  onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                  onColumnReorder={backbone.changeColumnOrder}
                  onChangePagination={backbone.changePagination}
                  hiddenColumns={backbone.getConfig('hiddenColumn')}
                  aggregations={aggregations()}
                />
              </Box>
            </ExpansionTable>
          </CompactFactoryProvider>
        );
      }
      default:
        return (
          <CompactFactoryProvider>
            <ExpansionTable
              defaultExpanded={true}
              label={titleTable}
              className={classes.toggle}
              noSpacer
              headerRight={config.isCalendarHide ? null : <HeaderRight />}
            >
              <Box pb={1} maxHeight={720} className={classes.wrapperTable}>
                <TableActions />
                <Table
                  backbone={backbone}
                  columns={headers}
                  sort={sort}
                  pinnedColumns={pinnedColumns}
                  columnWidth={columnWidth}
                  rowsGroup={rowsGroup}
                  pagination={pagination}
                  allowSelect={true}
                  selectedRows={selectedRows}
                  onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                  onColumnReorder={backbone.changeColumnOrder}
                  onChangePagination={backbone.changePagination}
                  hiddenColumns={backbone.getConfig('hiddenColumn')}
                  aggregations={aggregations()}
                />
              </Box>
            </ExpansionTable>
          </CompactFactoryProvider>
        );
    }
  };

  return (
    <Provider>
      <TableBackboneContext.Provider value={backbone}>
        {generateTable(backbone.getConfig('tableType', ''))}
      </TableBackboneContext.Provider>
    </Provider>
  );
};

export const makeTable = ({
  config = {
    apiRequest: {},
    configuration: {},
    tableType: '',
    callback: {},
    addons: {},
  },
  changeConfiguration = (config: any) => undefined,
  tableBackboneHook = useTableBackbone,
  selectedRows = [],
}: {
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
}) => {
  if (config.tableType === 'clientSide') {
    return (
      <NotificationGlobalProvider>
        <TableContainer
          key={config.id}
          config={config}
          changeConfiguration={changeConfiguration}
          tableBackboneHook={useTableBackboneClientSide}
          selectedRows={selectedRows}
        />
      </NotificationGlobalProvider>
    );
  }
  return (
    <NotificationGlobalProvider>
      <EditableTableContainer
        key={config.id}
        config={config}
        changeConfiguration={changeConfiguration}
        selectedRows={selectedRows}
      />
    </NotificationGlobalProvider>
  );
};

function EditableTableContainer({ config, changeConfiguration: changeConfig, selectedRows }) {
  const { isEditMode } = React.useContext(NodeEditContext);

  if (isEditMode) {
    return (
      <TableContainer
        key={config.configuration._editorId}
        config={config}
        changeConfiguration={changeConfig}
        selectedRows={selectedRows}
      />
    );
  } else {
    return (
      <TableContainer key={config.id} config={config} changeConfiguration={changeConfig} selectedRows={selectedRows} />
    );
  }
}
