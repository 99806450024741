import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Mobile = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 2C0 0.895431 0.895431 0 2 0H8C9.10457 0 10 0.895431 10 2V14C10 15.1046 9.10457 16 8 16H2C0.89543 16 0 15.1046 0 14V2ZM3 2V3C3 3.55228 3.44772 4 4 4H6C6.55228 4 7 3.55228 7 3V2C7.55228 2 8 2.44772 8 3V13C8 13.5523 7.55228 14 7 14H3C2.44772 14 2 13.5523 2 13V3C2 2.44772 2.44772 2 3 2Z"
    />
  </SvgIcon>
);

export default Mobile;
