import * as React from 'react';
import { useSetAtom } from 'jotai';
import { get } from 'lodash';

import { TextareaAutosize, IconButton, Box, makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { formulaPopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-formula';
import { narrativePopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-narrative';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';

import {
  INPUT_TYPE_MAX_LINES,
  INPUT_TYPE_SUPPORT_FORMULA,
  INPUT_TYPE_SUPPORT_JSON,
  INPUT_TYPE_SUPPORT_NARRATIVE,
} from '../../../utils/constant';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
  },
  iconsContainer: {
    padding: 0,
    position: 'absolute',
    top: 8,
    right: 8,
    display: 'flex',
    rowGap: '8px',
    alignItems: 'center',
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
  },
  errorMessage: {
    color: '#d4290d',
    fontSize: '12px',
  },
});

const Textarea = ({
  value,
  error,
  onChange,
  placeholder,
  initialValue,
  configuration,
  initialFocus,
  errors,
  fieldKey,
  rowData,
}: any) => {
  const classes = useStyles();
  const [showEditor, setShowEditor] = React.useState(false);

  const displayFormula = useSetAtom(formulaPopup);
  const displayNarrative = useSetAtom(narrativePopup);

  const errorMessage = get(errors, String(fieldKey).split('.').concat('message'), '');

  const fieldConfiguration = get(configuration, ['field_configuration'], []);
  const isSupportFormula = (
    fieldConfiguration.find((field) => field.key === INPUT_TYPE_SUPPORT_FORMULA) || { value: '' }
  ).value;
  const isSupportJSON = (fieldConfiguration.find((field) => field.key === INPUT_TYPE_SUPPORT_JSON) || { value: '' })
    .value;

  const isSupportNarrative = (
    fieldConfiguration.find((field) => field.key === INPUT_TYPE_SUPPORT_NARRATIVE) || { value: '' }
  ).value;

  let maxLines = (fieldConfiguration.find((field) => field.key === INPUT_TYPE_MAX_LINES) || { value: '' }).value;

  maxLines = toValue(maxLines, rowData, true);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && initialFocus) {
      ref.current.focus();
    }
  }, [initialFocus]);

  React.useEffect(() => {
    setTimeout(() => {
      onChange({
        target: {
          value: initialValue,
        },
      });
    }, 0);
  }, [initialValue, onChange]);

  const handleChange = (e) => {
    if (maxLines && e.target.value.split('\n').length > maxLines) return;
    onChange(e);
  };

  return (
    <Box className={classes.container}>
      <TextareaAutosize
        ref={ref}
        style={{ width: '100%', borderColor: error?.visible && '#D4290D', borderRadius: error?.visible && '2px' }}
        minRows={5}
        maxRows={5}
        value={typeof value === 'object' ? JSON.stringify(value) : value}
        onChange={handleChange}
        placeholder={placeholder}
      />
      <Box className={classes.iconsContainer}>
        {isSupportNarrative && (
          <IconButton
            onClick={(event) => {
              const availVars = [];
              event.stopPropagation();
              displayNarrative({
                isDisplay: true,
                content: value,
                ref: event.currentTarget,
                backboneConfig: { mapping: {} },
                availableVars: availVars,
                submit: (content) => {
                  onChange({
                    target: {
                      value: content,
                    },
                  });
                },
              });
            }}
          >
            <Icon type={'ri/MdEditNote/blue5'} />
          </IconButton>
        )}
        {isSupportFormula && (
          <IconButton
            onClick={(event) => {
              const availVars = [];
              event.stopPropagation();
              displayFormula({
                isDisplay: true,
                content: value,
                ref: event.currentTarget,
                backboneConfig: { mapping: {} },
                availableVars: availVars,
                submit: (content) => {
                  onChange({
                    target: {
                      value: content,
                    },
                  });
                },
              });
            }}
          >
            <Icon type={'rmx/functions-icon/blue6'} />
          </IconButton>
        )}
        {isSupportJSON && (
          <>
            <IconButton
              onClick={(event) => {
                setShowEditor(true);
              }}
            >
              <Icon type={'ic/bi:filetype-json/blue5'} />
            </IconButton>
            {showEditor && (
              <PopupJsonEditor
                title={'Editor'}
                open={showEditor}
                onClose={() => setShowEditor(false)}
                value={value ? safeJsonParse(value, value) : {}}
                onValueChange={(result) => {
                  onChange({
                    target: {
                      value: JSON.stringify(result),
                    },
                  });
                  setShowEditor(false);
                }}
              />
            )}
          </>
        )}
      </Box>
      {errorMessage ? <span className={classes.errorMessage}>{errorMessage}</span> : null}
    </Box>
  );
};

export default Textarea;
