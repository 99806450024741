import { NodeEditContext, useLog, aim } from '@eip/next/lib/main';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';

// import { actions } from '@eip/next/src/components/dashboard/dashboard-redux';
import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { EventEmitter } from 'events';
import { get, omit } from 'lodash';
import React from 'react';
import { enhancedETableConfig2 } from './migration';

import moment from 'moment';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from './cell-format-options';
const log = useLog('block:etable');

import { checkEpsiloTableEndpoint, EpsiloTableObject } from '@ep/insight-ui/system/backbone/data-source/common';

import { cellFormat } from '@ep/insight-ui/elements/etable2/table-helper';
import { ChartShape as ETableBlockLegacy } from './etable-legacy';
import CompactTable from './etable-config/action-components/compact-table';
import ConditionFormat from './etable-config/action-components/condition-format';
import InlineEdit from './etable-config/action-components/inline-edit';
import PeriodUpdate from './etable-config/action-components/period-update';
import JsonEditor from './etable-config/action-components/json-editor';
import {
  ACTION_CATEGORY_CODE_EXECUTION,
  ACTION_CATEGORY_CODE_IDE,
  ACTION_CATEGORY_COMPACT_TABLE_VIEW,
  ACTION_CATEGORY_CONDITON_FORMAT,
  ACTION_CATEGORY_COPY_PASTE,
  ACTION_CATEGORY_ETABLE_FORM,
  ACTION_CATEGORY_FILTER,
  ACTION_CATEGORY_IMPACT_TABLE,
  ACTION_CATEGORY_INLINE_FORM,
  ACTION_CATEGORY_INTEGRATE_STOREFRONT,
  ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
  ACTION_CATEGORY_JSON_FORM,
  ACTION_CATEGORY_PERIOD,
  ACTION_CATEGORY_SCHEDULE_DAILY,
  ACTION_CATEGORY_SCRIPT_ACTION,
  ACTION_CATEGORY_SEPARATOR,
  ACTION_CATEGORY_STATUS,
  ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE,
  ACTION_CATEGORY_TAG,
} from './etable-config/utils/constant';
import { RichConfigForm } from './etable-next-configuration-form';
import { MonitorContainer } from '../../util/monitor/container';
import { useSetAtom } from 'jotai';
import { editorScript } from './etable-config/atom/editor-script';
import { ScriptExecution } from './etable-config/script-execution/script-execution';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { eipRequest as request } from '@eip/next/lib/main';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import CompactTableForm from './etable-config/action-components/compact-table-form';
import { handleUpdateVisible } from '../../helper/functions';
import ScheduleDaily from './etable-config/action-components/schedule-daily';
import SwitchPlacementType from './etable-config/action-components/switch-placement-type';
import StorefrontIntegration from '@ep/insight-ui/elements/new-storefront/next-storefront-integration';
import { useHistory, useLocation } from 'react-router';

export class ETable implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    const chartNodeData = Object.assign({ customAttributes: {} }, data);
    return (
      <MonitorContainer component="div" mId={data.blockEid} mLabel={get(data, 'customAttributes.title', 'unknown')}>
        <ChartShape nodeData={chartNodeData} systemConfig={data._system}></ChartShape>
      </MonitorContainer>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <RichConfigForm data={data} onSubmit={handleSubmit} key={nodeId} nodeId={nodeId}></RichConfigForm>;
  }
}

const ChartShape = ({ nodeData, systemConfig }: { nodeData: NodeData; systemConfig: NodeData }) => {
  const customConfig = get(nodeData, 'customAttributes', {});

  // const endpoint = React.useMemo(() => get(customConfig, 'endpoint.GET_TABLE_DATA', ''), []);
  const MemoETable = React.useMemo(() => {
    const endpoint = get(customConfig, 'endpoint.GET_TABLE_DATA', '');
    if (endpoint.indexOf('grpc') > -1 || checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE))
      return ETableBlockLegacy;
    else return ETableBlock;
  }, []);

  return <MemoETable nodeData={nodeData} systemConfig={systemConfig} />;
};

const EXCLUDE_CONFIGURATION = ['tableParams'];
const nextCellFormat = {
  ...CELL_FORMAT_OPTIONS_WITH_COMPONENT,
  ...cellFormat,
  ...['qualityMetric', 'oneMetric', 'operationMetric'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.currencyFormatFormula };
  }, {}),
  ...['oneDimension'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.textFormatFormula };
  }, {}),
  ...['onePivot'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.pivotFormatFormula };
  }, {}),
};
export function ETableBlock({
  nodeData,
  systemConfig,
  addons = {},
  backboneRef = null,
}: {
  nodeData: NodeData;
  systemConfig: NodeData;
  addons: any;
  backboneRef?: any;
}) {
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { v } = queryParams;

  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const { onToastMultiple } = useToast();

  const lastUpdated = React.useRef(moment().valueOf());

  const handleChangeConfig = React.useCallback(
    (config: Record<string, any>) => {
      log('handlechangeconfig', config);
      const finConfig = omit(config, EXCLUDE_CONFIGURATION);
      EXCLUDE_CONFIGURATION.forEach((f) => {
        finConfig[f] = undefined;
      });
      window.requestAnimationFrame(() => {
        nodeEditContext.onUpdateCustomAttributes(nodeData, finConfig);
      });
    },
    [nodeData],
  );

  const customConfig = get(nodeData, 'customAttributes', {});
  const { config, linkedObjects } = enhancedETableConfig2({
    customConfig,
    systemConfig,
    nodeEditContext,
    lastUpdated,
    blockEid: nodeData.blockEid,
    setOpenCodeEditor,
    getCustomCellActions,
    onToastMultiple,
    addons,
    queryView: v,
  });

  return makeTable({
    blockEid: nodeData.blockEid,
    config,
    changeConfiguration: handleChangeConfig,
    tableBackboneHook: useTableBackbone,
    linkedObjects,
    backboneRef,
  });
}

// ETableBlock.whyDidYouRender = true;

export const getCustomCellActions = ({
  actions,
  backbone,
  field = '',
  additionalData = {},
  setOpenCodeEditor,
  onToastMultiple,
  blockEid,
}) => {
  const userProfile = aim.getUserSettings().profile || { workspace_role_id: '', userEmail: '' };
  const { workspace_role_id, userEmail } = userProfile;

  const callbackScript = (script) => {
    return (...vars) => {
      const providedUserProfile = {
        ...userProfile,
        token: String(userProfile?.userEmail).endsWith('@epsilo.io') ? aim.getLoginToken().token : '',
      };
      try {
        const variables = [
          ['bb', backbone],
          ['notify', onToastMultiple],
          ['request', request],
          ['user', providedUserProfile],
          ['column', field],
          ...vars,
        ];
        const v1 = variables.map((v) => v[0]);
        const v2 = variables.map((v) => v[1]);

        if (
          !script.includes('user.token') ||
          confirm(
            getConst(
              'CONFIRM_TOKEN_SCRIPT_MESSAGE',
              'This script will use your token to do something, are you sure to continue executing this script?',
            ),
          )
        ) {
          return Function(...v1, script)(...v2);
        }
      } catch (e) {
        onToastMultiple({
          title: 'Error',
          messages: [e.message],
          variant: 'error',
        });
      }

      return;
    };
  };

  return actions
    .filter((el) => el.category !== ACTION_CATEGORY_STATUS)
    .filter((el) => el.category !== ACTION_CATEGORY_COPY_PASTE)
    .filter(
      ({ role }) =>
        aim.isSuperAdmin() ||
        !workspace_role_id ||
        (role ? [].concat(role) : [1, 2, 3]).includes(Number(workspace_role_id)),
    )
    .filter((el) => {
      const permission = el.permission ? toValue(el.permission, { ...additionalData?.data }) : 'allow';
      const isVisible =
        permission === 'admin' ? aim.isSuperAdmin() : permission == (additionalData?.isOne ? 'one' : 'allow');

      return isVisible;
    })
    .filter((el) => {
      if (el.category !== ACTION_CATEGORY_SCRIPT_ACTION) return true;
      const script = get(el, ['configuration', 0, 'value'], '');
      return !script.includes('user.token') || String(userEmail).endsWith('@epsilo.io');
    })
    .map((el) => {
      const callback = callbackScript(get(el, ['callback'], ''));

      const useInlineEdit = [ACTION_CATEGORY_TAG, ACTION_CATEGORY_INLINE_FORM];
      let disabled =
        typeof el.disable == 'boolean' ? el.disable : toValue(String(el.disable), { ...additionalData?.data }) == '1';

      const demoUser = /demo.*@epsilo.io$/i.test(userEmail);

      if (
        ![
          ACTION_CATEGORY_FILTER,
          ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
          ACTION_CATEGORY_PERIOD,
          ACTION_CATEGORY_COMPACT_TABLE_VIEW,
          ACTION_CATEGORY_COPY_PASTE,
          ACTION_CATEGORY_SEPARATOR,
        ].includes(el.category) &&
        demoUser
      ) {
        disabled = true;
      }

      const actionInfo = omit(el, ['configuration']);

      if (useInlineEdit.includes(el.category) || !el.category) {
        return {
          component: InlineEdit,
          payload: el.configuration.map((el) => ({
            ...el,
            cellFormat,
          })),
          customProps: {
            ...actionInfo,
            actionName: el.name,
            tracking: el.tracking,
            category: el.category,
            ...additionalData,
            getCustomCellActions,
            callback,
          },
          name: el.name,
          icon: el.icon,
          disable: disabled,
          shortcut: el.shortcut,
          field,
        };
      }

      const action = {
        onSubmit({ row }) {
          const finRow = get(
            row,
            ['eData', (additionalData as { pivotColumn: string })?.pivotColumn, 'pivot', 'rowData'],
            row,
          );
          if (el.category === ACTION_CATEGORY_FILTER) {
            const filterField = get(backbone.getConfig('mapping'), [field, 'filterField'], '');
            backbone.updateTempStorage('filterModal', { field, value: row[filterField] || '' });
            if (blockEid) {
              handleUpdateVisible(blockEid, 'filter', true);
            } else {
              backbone.changeVisibility('filter', true);
            }
          }
          if (el.category === ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS) {
            const link = ((el.configuration || []).find((el) => el.key === 'url') || { value: '' }).value;

            const isInternalLink = /^(http(s?)):\/\/[^.]*.?epsilo.io/.test(link);

            const url = link.replace(/\:\w+/g, (c) => {
              const { value } = el.configuration.find((el) => el.key === c.slice(1)) || { value: '' };
              return finRow[value];
            });
            isInternalLink
              ? window.open(url, '_blank')
              : window.open(`/redirect-external?url=${encodeURIComponent(url)}`, '_blank');
          }
          if (!el.disable) {
            callback(['row', row]);
          }
        },
        payload: transformPayload(el),
        name: el.name,
        icon: el.icon,
        disable: disabled,
        shortcut: el.shortcut,
        id: el.category === ACTION_CATEGORY_IMPACT_TABLE ? 'history' : undefined,
        field,
        customProps: actionInfo,
      };

      if (el.category === ACTION_CATEGORY_JSON_FORM) {
        action.component = JsonEditor;
      }

      if (el.category === ACTION_CATEGORY_PERIOD) {
        action.component = PeriodUpdate;
      }
      if (el.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
        action.type = 'compact';
        action.customProps = { ...actionInfo, getCustomCellActions, callback };
        action.component = CompactTable;
      }
      if (el.category === ACTION_CATEGORY_ETABLE_FORM) {
        action.type = 'compact';
        action.customProps = { ...actionInfo, getCustomCellActions, callback };
        action.component = CompactTableForm;
      }
      if (el.category === ACTION_CATEGORY_CONDITON_FORMAT) {
        action.component = ConditionFormat;
      }
      if (el.category === ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE) {
        action.component = SwitchPlacementType;
      }
      if (el.category === ACTION_CATEGORY_SEPARATOR) {
        action.customProps = {
          ...actionInfo,
          category: el.category,
        };
      }
      if (el.category === ACTION_CATEGORY_INTEGRATE_STOREFRONT) {
        action.component = StorefrontIntegration;
      }

      if (el.category === ACTION_CATEGORY_CODE_IDE) {
        action.onSubmit = (payload) => {
          setOpenCodeEditor({
            isDisplay: true,
            payload: action.payload,
            inlineFormPayload: {
              rowData: payload.row,
              payload: el.configuration.map((el) => ({
                ...el,
                cellFormat,
              })),
              customProps: {
                ...actionInfo,
                actionName: el.name,
                tracking: el.tracking,
                category: el.category,
                ...additionalData,
              },
              name: el.name,
              icon: el.icon,
              disable: disabled,
              shortcut: el.shortcut,
              field,
            },
          });
        };
      }

      if (el.category === ACTION_CATEGORY_CODE_EXECUTION) {
        return {
          component: ScriptExecution,
          payload: el.configuration.map((el) => ({
            ...el,
            cellFormat,
          })),
          customProps: {
            ...actionInfo,
            actionName: el.name,
            tracking: el.tracking,
            category: el.category,
            ...additionalData,
          },
          name: el.name,
          icon: el.icon,
          disable: disabled,
          shortcut: el.shortcut,
          field,
        };
      }

      if (el.category === ACTION_CATEGORY_SCHEDULE_DAILY) {
        return {
          component: ScheduleDaily,
          payload: el.configuration.map((el) => ({
            ...el,
            cellFormat,
          })),
          customProps: {
            ...actionInfo,
            actionName: el.name,
            tracking: el.tracking,
            category: el.category,
            ...additionalData,
          },
          name: el.name,
          icon: el.icon,
          disable: disabled,
          shortcut: el.shortcut,
          field,
        };
      }

      return action;
    });
};

const transformPayload = (config) => {
  if (config.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
    return get(config, ['configuration'], []).reduce(
      (a, b) => {
        return {
          ...a,
          [b.key]: b.value,
        };
      },
      { cellFormat: nextCellFormat },
    );
  }
  if (config.category !== ACTION_CATEGORY_IMPACT_TABLE) return config.configuration;
  return {
    field: config.configuration.reduce((a, b) => {
      return {
        ...a,
        [b.key]: b.value,
      };
    }, {}),
  };
};
