import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getActionMenu, staticFilter } from './common-config';

export const AD_OBJECT_OPTIMIZATION_CONFIG = {
  title: 'Ad object optimization',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getAdsObjectData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_OBJECT',
    GET_MARKET_STATUS: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_OBJECT.id',
    'ADS_CALENDAR.id',
    'ADS_CALENDAR.ADS_OBJECT.id',
    'PRODUCT.id',
  ],
  disabledComponents: {
    dimension: ['country', 'marketplace', 'storefront', 'ad_type', 'ad_tool', 'campaign', 'ad_object'],
  },
  system: {
    hiddenComponents: ['majorButton', 'search'],
    filter: staticFilter.adObjectOptimization,
    additionalFilters: [
      {
        name: 'Data status',
        id: 'data_status',
        dataType: 'string',
        propertyType: 'dimension',
        fields: ['ADS_OBJECT.data_status'],
        options: [
          {
            label: 'Succeeded',
            value: '1',
          },
          {
            label: 'Failed',
            value: '2',
          },
        ],
        rules: [
          {
            value: '1',
            combinator: 'AND',
          },
          {
            value: '2',
            combinator: 'OR',
          },
        ],
        operators: [
          {
            label: 'Is',
            value: 'is',
          },
        ],
        isAdditionalFilter: true,
      },
    ],
  },
  hiddenPropertyColumns: ['ad_object_daily_budget'],
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'dataSyncStatus',
          'country',
          'marketplace',
          'storefront',
          'ad_type',
          'ad_tool',
          'campaign',
          'ad_object',
          'ad_object_tag',
          'campaign_status',
          'campaign_daily_budget',
          'campaign_total_budget',
          'ad_object_status',
          'ad_object_budget',
          'ad_object_timeline',
          'ad_object_bidding_price',
        ],
      }
    : {}),
  mapping: {
    dataSyncStatus: {
      title: 'Sync status',
      propertyType: 'dimension',
      cellFormat: 'dataSyncStatusFormat',
      dataType: 'string',
      filterField: '',
      sortField: '',
      valueGetter: {
        value: 'ADS_OBJECT.data_status',
        data_status: 'ADS_OBJECT.data_status',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
      menu: getActionMenu('country'),
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
      menu: getActionMenu('marketplace'),
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
      menu: getActionMenu('storefront'),
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
      menu: getActionMenu('ad_type'),
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool_name',
        id: 'ADTOOL.ads_tool',
      },
      menu: getActionMenu('ad_tool'),
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      sortField: 'ADS_CAMPAIGN.name',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.id',
        value: 'ADS_CAMPAIGN.name',
      },
      menu: getActionMenu('campaign'),
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT',
      filterGetter: {
        default: 'ADS_OBJECT.name',
      },
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        id: 'ADS_OBJECT.id',
        ...(ff.enable_bidding_price ? { productId: 'PRODUCT.id' } : {}),
        data_status: 'ADS_OBJECT.data_status',
      },
      menu: getActionMenu('ad_object'),
    },

    // ============= ATTRIBUTE =============
    // ads_campaign_transform_status: {
    //   title: 'Campaign sync status',
    //   propertyType: 'dimension',
    //   cellFormat: 'transformStatus',
    //   filterField: 'ADS_CAMPAIGN.transform_status',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.transform_status',
    //     id: 'ADS_CAMPAIGN.transform_status',
    //     value: 'ADS_CAMPAIGN.transform_status',
    //     updatedAt: 'ADS_CAMPAIGN.transform_status_updated_at',
    //   },
    //   menu: [
    //     {
    //       title: 'Data sync',
    //       name: 'Sync data',
    //       icon: 'pencil',
    //       editor: 'TransformStatusEditor',
    //       payload: {
    //         field: {
    //           value: 'ADS_CAMPAIGN.transform_status',
    //         },
    //       },
    //     },
    //   ],
    // },
    // ads_object_transform_status: {
    //   title: 'Ad object sync status',
    //   propertyType: 'dimension',
    //   cellFormat: 'transformStatus',
    //   filterField: 'ADS_OBJECT.transform_status',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.transform_status',
    //     id: 'ADS_OBJECT.transform_status',
    //     value: 'ADS_OBJECT.transform_status',
    //     updatedAt: 'ADS_OBJECT.transform_status_updated_at',
    //   },
    //   menu: [
    //     {
    //       title: 'Data sync',
    //       name: 'Sync data',
    //       icon: 'pencil',
    //       editor: 'TransformStatusEditor',
    //       payload: {
    //         field: {
    //           value: 'ADS_OBJECT.transform_status',
    //         },
    //       },
    //     },
    //   ],
    // },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'dailyBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.daily_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_daily_budget'),
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'totalBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.total_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_total_budget'),
    },
    // ad_objective: {
    //   title: 'Ad objective',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.ad_objective',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.ad_objective',
    //   },
    // },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    ad_object_daily_budget: {
      title: 'Ad object daily budget',
      propertyType: 'attribute',
      cellFormat: 'filterColumnFormat',
      dataType: 'float',
      filterField: 'ADS_OBJECT.daily_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    ad_object_timeline: {
      title: 'Ad object timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
        id: 'ADS_CALENDAR.ADS_OBJECT.id',
      },
      menu: getActionMenu('ad_object_timeline'),
    },
    ad_object_bidding_price: {
      title: 'Ad object bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_OBJECT.base_bidding_price',
      valueGetter: {
        value: 'ADS_OBJECT.base_bidding_price',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_bidding_price'),
    },

    // ad_object_creative: {
    //   title: 'Ad object creative',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.ad_object_creative',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.ad_object_creative',
    //   },
    // },
    // creative_tagline: {
    //   title: 'Creative tagline',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_tagline',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_tagline',
    //   },
    // },
    // creative_image: {
    //   title: 'Creative image',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_image',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_image',
    //   },
    // },
    // creative_landing_page: {
    //   title: 'Creative landing page',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_landing_page',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_landing_page',
    //   },
    // },
    wallet: {
      title: 'Wallet',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'STOREFRONT.wallet',
      valueGetter: {
        value: 'STOREFRONT.wallet',
        currency: 'STOREFRONT.currency',
        subtext: 'STOREFRONT.wallet_updated_at',
      },
      menu: [],
    },
    first_search_slot: {
      title: 'First search slot',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.first_search_slot',
      valueGetter: {
        value: 'ADS_CAMPAIGN.first_search_slot',
      },
      menu: getActionMenu('first_search_slot'),
    },
    max_bidding_price: {
      title: 'Max bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.max_bidding_price',
      valueGetter: {
        value: 'ADS_CAMPAIGN.max_bidding_price',
      },
      menu: getActionMenu('max_bidding_price'),
    },

    // ============= METRIC =============
    impression: {
      title: 'Impression',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'impression',
      valueGetter: {
        value: 'impression',
      },
      menu: getActionMenu('_metric'),
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
      menu: getActionMenu('_metric'),
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ads_item_sold: {
      title: 'Ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    direct_item_sold: {
      title: 'Direct ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    atc: {
      title: 'ATC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'atc',
      valueGetter: {
        value: 'atc',
      },
      menu: getActionMenu('_metric'),
    },
    atc_direct: {
      title: 'ATC direct',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_atc',
      valueGetter: {
        value: 'direct_atc',
      },
      menu: getActionMenu('_metric'),
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    direct_gmv: {
      title: 'Direct ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ads_order: {
      title: 'Ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_order',
      valueGetter: {
        value: 'ads_order',
      },
      menu: getActionMenu('_metric'),
    },
    direct_ads_order: {
      title: 'Direct ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_order',
      valueGetter: {
        value: 'direct_order',
      },
      menu: getActionMenu('_metric'),
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
      menu: getActionMenu('_metric'),
    },
    cir: {
      title: 'CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cir',
      valueGetter: {
        value: 'cir',
      },
      menu: getActionMenu('_metric'),
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
      menu: getActionMenu('_metric'),
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
      menu: getActionMenu('_metric'),
    },
    stock: {
      title: 'Stock',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'stock',
      valueGetter: {
        value: 'stock',
      },
      menu: getActionMenu('_metric'),
    },
    discount: {
      title: 'Discount',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'discount',
      valueGetter: {
        value: 'discount',
      },
      menu: getActionMenu('_metric'),
    },
    direct_roas: {
      title: 'Direct ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_roas',
      valueGetter: {
        value: 'direct_roas',
      },
      menu: getActionMenu('_metric'),
    },
    cpi: {
      title: 'CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpi',
      valueGetter: {
        value: 'cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cr: {
      title: 'Direct CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cr',
      valueGetter: {
        value: 'direct_cr',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cpi: {
      title: 'Direct CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cpi',
      valueGetter: {
        value: 'direct_cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cir: {
      title: 'Direct CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cir',
      valueGetter: {
        value: 'direct_cir',
      },
      menu: getActionMenu('_metric'),
    },
    selling_price: {
      title: 'Selling price',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'PRODUCT.price_postsub',
      valueGetter: {
        value: 'PRODUCT.price_postsub',
      },
      menu: getActionMenu('_metric'),
    },
  },
};
