import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeStyles } from '@material-ui/core';
import { get, set } from 'lodash';
import * as React from 'react';
import { FieldSelectedValue } from '../../inline-dialog/filter/filter-group-dialog';
import SortDialog from '../../inline-dialog/sort/sort-form-table';
import { ISortItem } from '../../inline-dialog/sort/sort-form-table/sort-form';
import { SortType } from '../table';
import { NodeEditContext } from '@eip/next/lib/main';
import { LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE } from '@ep/insight-ui/system/helper/constant';

type IProps = {
  marginLeft?: number;
};

const useStyles = makeStyles((theme) => ({
  containerButton: {
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
  },
  sortDialog: {
    // maxWidth: 'unset',
    maxWidth: 'calc(100vw - 60%)',
    // width: 'fit-content',
    minWidth: '432px',
    maxHeight: 'calc(100vh - 39%)',
    '& ul': {
      width: '100% !important',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: '#babac038',
      marginLeft: 20,
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: '#babac038',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '5px solid #F8FAFD',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '4px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  active: {
    color: `${colors.text.highLight}`,
    '&:hover': {
      color: colors.text.default,
    },
    '&.eip1-MuiButton-active': {
      color: `white`,
    },
  },
}));

const ButtonSort = ({ marginLeft = 0, callback }: { marginLeft?: number; callback?: (model) => void }) => {
  const classes = useStyles({ marginLeft });

  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const isVisibleSort = backboneContext.getVisibility('sort');
  const sortModel: Array<SortType> = backboneContext.getConfig('sort');
  const isLockedView = get(backboneContext.getConfig('view'), ['isLock'], false);
  const { isEditMode } = React.useContext(NodeEditContext);

  const options: Array<FieldSelectedValue> = backboneContext.getAvailableSortColumns
    ? backboneContext.getAvailableSortColumns().map((item, index) => {
        return {
          label: item.name,
          value: item.id,
          dataType: item.dataType ?? 'string',
        };
      })
    : [];
  const handleClose = () => {
    // onClosed();
    backboneContext.changeVisibility('sort', false);
  };

  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const sortModelDataConvert: ISortItem[] = React.useMemo(() => {
    return sortModel.reduce((acc, item) => {
      const opt = options.find((op) => op.value == item.field);
      if (opt) {
        return [
          ...acc,
          {
            field: opt,
            sort: item.sort,
            locked: isLockedView ? item.locked : false,
          },
        ];
      }
      return acc;
    }, []);
  }, [sortModel]);

  const handleChangeSortModal = (model: SortType[]) => {
    const sortData = model.filter(({ locked }) => locked).concat(model.filter(({ locked }) => !locked));
    if (isEditMode && isLockedView) {
      const version = new Date().getTime();
      const view = backboneContext.getConfig('view');
      set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
      set(view, ['combinator', 'sort'], sortData);

      const views = backboneContext.getConfig('views');
      for (const v of views) {
        if (v.id === view.id) {
          set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
          set(v, ['combinator', 'sort'], sortData);
        }
      }

      backboneContext.updateConfig({
        sort: sortData,
        view,
        views,
      });
    } else {
      backboneContext.changeConfig('sort', sortData);
    }
    if (callback) {
      callback(sortData);
    }
  };

  return (
    <div className={classes.containerButton}>
      {/* button Filter */}
      <SortDialog
        defaultOpen={isVisibleSort}
        onClosed={handleClose}
        className={`${classes.sortDialog} eip_elevation_table_${titleTableClass} eip_action_sort`}
        options={options}
        classNameButton={`${sortModelDataConvert.length > 0 ? classes.active : ''}`}
        sortModel={sortModelDataConvert}
        setSortModel={handleChangeSortModal}
        disabledClickContentClose
        disabledIconNoFilter
        alignMenu={'right'}
      />
    </div>
  );
};

export default ButtonSort;
