import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Pencil = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M0.347984 9.2522C0.236778 9.36432 0.148797 9.49729 0.0890854 9.64348C0.029374 9.78968 -0.000892922 9.94622 2.00551e-05 10.1041V11.4C2.00551e-05 11.5591 0.0632278 11.7117 0.175738 11.8243C0.288248 11.9368 0.440845 12 0.599958 12H1.89582C2.05374 12.0009 2.21028 11.9706 2.35647 11.9109C2.50265 11.8512 2.63562 11.7632 2.74774 11.652L9.94699 4.45259L7.54724 2.05279L0.347984 9.2522Z" />
    <path d="M11.6508 1.05687L10.9429 0.348923C10.7181 0.12544 10.414 0 10.097 0C9.77999 0 9.47588 0.12544 9.25107 0.348923L8.39915 1.20085L10.7989 3.60066L11.6268 2.77273C11.7427 2.66259 11.8354 2.5304 11.8994 2.38394C11.9635 2.23748 11.9976 2.07969 11.9999 1.91985C12.0021 1.76 11.9724 1.60132 11.9124 1.45312C11.8525 1.30493 11.7636 1.1702 11.6508 1.05687Z" />
  </SvgIcon>
);

export default Pencil;
