import { useLog } from '@eip/next/lib/log';
import { AuthContext, usePage } from '@eip/next/lib/main';
import * as React from 'react';

const log = useLog('page:page-control');

export function usePageControl() {
  const { isInit, loadPageList } = usePage();
  const { currentWorkspaceDetail } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (isInit === false && currentWorkspaceDetail) {
      loadPageList();
    }
  }, [isInit, currentWorkspaceDetail]);
}
