import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueGenderFormat } from '@ep/insight-ui/elements/table/format/gender-format';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { produce } from 'immer';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        height: '32px',
      },
    },
    width: '100%',
  },
}));
const OPTION_ALL_CONST = 'all';
const optionAll = {
  label: 'All',
  value: OPTION_ALL_CONST,
};
const defaultOptions: OptionSelectType[] = [
  {
    label: 'All',
    value: OPTION_ALL_CONST,
  },
  {
    label: 'Male',
    value: 1,
  },
  {
    label: 'Female',
    value: 2,
  },
];

export type IGenderForm = {
  title?: string;
  options?: OptionSelectType[];
  value?: string;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: (value: string) => Promise<any> | void;
  submitLabel?: string;
};
export const GenderForm = ({
  title = 'Gender',
  options = defaultOptions,
  value = '',
  loading = false,
  onClose = () => undefined,
  onSubmit = () => undefined,
  submitLabel = 'Apply now',
}: IGenderForm) => {
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = React.useState<string>(value);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (value != currentStatus) setCurrentStatus(value);
  }, [value]);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentStatus(e.target.value);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit(currentStatus);
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      onClose();
    }
  };
  return (
    <div style={{ padding: '0 10px', minWidth: '320px' }}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <HeaderList
            title={title}
            variant="h4"
            className={classes.header}
            iconHead={
              loading ? (
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
      <div className={classes.dialog}>
        <SelectForm options={options} value={currentStatus} className={classes.select} onChange={handleChangeStatus} />
        <GroupButtonSubmit
          onCancel={onClose}
          onSubmit={handleSubmit}
          submitLabel={submitLabel}
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  );
};

type Props = {
  data: {
    value: IValueGenderFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  onLoad: (param: { toolCodeIds: string[]; storefrontIds: string[] }) => Promise<void>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const GenderEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, onLoad, anchorEl, setAnchorEl } = props;
  const [loading, setLoading] = React.useState(false);
  const defaultValue = React.useMemo(() => {
    const va = get(data, 'value.value', null);
    if (va) return String(va.id);
    return OPTION_ALL_CONST;
  }, [data.value, anchorEl]);

  const [options, setOptions] = React.useState<OptionSelectType[]>([]);

  const initOptions = async () => {
    if (onLoad) {
      setLoading(true);
      const toolCode = get(payload, ['rowPicker', 'toolCode']);
      const storefrontId = get(payload, ['rowPicker', 'storefrontId']);
      const res: any = await onLoad({
        toolCodeIds: [].concat(get(data.node, ['data', toolCode])),
        storefrontIds: [].concat(get(data.node, ['data', storefrontId])),
      });
      if (res.success) {
        // TODO: set initial options
        const arr: OptionSelectType[] = res.data.map((item) => ({
          label: item.text,
          value: item.id,
        }));
        setOptions(arr);
      } else {
        setOptions([optionAll]);
      }
      setLoading(false);
    } else {
      setOptions([optionAll]);
    }
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async (gender) => {
    const response = await onSubmit({
      value: produce(data.value, (draft) => {
        const item: OptionSelectType = options.find((m) => m.value == gender);
        (draft as any).value =
          item && item.value !== OPTION_ALL_CONST ? JSON.stringify({ text: item.label, id: item.value }) : null;
      }),
      row: data.node.data,
    });
    return response;
  };

  return (
    <GenderForm
      title={title}
      loading={loading}
      options={options}
      value={defaultValue}
      onClose={handleClosed}
      onSubmit={handleSubmit}
    />
  );
};

export default GenderEditor;
