import * as React from 'react';
import * as ReactDOM from 'react-dom';
import ReactGridLayout, { ReactGridLayoutProps } from 'react-grid-layout';
import { useLog } from '../log';

const log = useLog('rgl:nested');

export const NestedContext = React.createContext({
  grids: [],
  init: false,
  isDragging: false,
  setState: null,
  onMouseMove: null,
  nestedOnDrop: null,
  register: (grid) => undefined,
  unregister: (grid) => undefined,
});

export function enhanceNested(Component: ReactGridLayout) {
  return function NestedEnhancement(props: ReactGridLayoutProps) {
    const { grids, init } = React.useContext(NestedContext);

    if (!init) {
      return (
        <NestedRoot>
          <Component {...props} />
        </NestedRoot>
      );
    } else {
      return (
        <NestedChild>
          <Component {...props} />
        </NestedChild>
      );
    }
  };
}

function calculateGridBoundaries(gridIds) {
  const boundaries = [];
  for (const gridId of gridIds) {
    const markEl = document.getElementById('nested-mark-' + gridId);
    const gridEl = markEl.parentElement.querySelector('.react-grid-layout');
    boundaries.push(gridEl.getBoundingClientRect());
  }

  log('grid boundaries', boundaries);
  return boundaries;
}

function NestedRoot({ children }) {
  const boundaries = React.useRef<DOMRect[]>([]);
  const nestedGrid = React.useRef([]);
  const gridFocus = React.useRef(null);
  const blockFocus = React.useRef({ blockEl: null, blockLayout: null });
  const [{ dragging }, setDragging] = React.useState({
    dragging: false,
  });

  const setState = React.useCallback(({ dragging, blockEl, blockLayout }) => {
    setDragging({ dragging });
    blockFocus.current = { blockEl, blockLayout };
  }, []);

  React.useEffect(() => {
    if (dragging === true) {
      boundaries.current = calculateGridBoundaries(nestedGrid.current.map((i) => i[0]));
    } else {
      nestedGrid.current.forEach((i) => {
        if (i[1]) i[1].resetDropOverPlaceholder();
      });
    }
  }, [dragging]);

  const onDragStart: ReactGridLayoutProps['onDragStart'] = (layout, oldItem, newItem, placeholder, event, el) => {
    if (oldItem.i === '__dropping-elem__') return;
    let isReallyDragging = true;
    const checkEventMouseUp = () => {
      isReallyDragging = false;
    };
    el.addEventListener('mouseup', checkEventMouseUp);
    window.setTimeout(() => {
      if (isReallyDragging) {
        log('ondragstart', nestedGrid.current, newItem);
        setState({ dragging: true, blockEl: el, blockLayout: newItem });
        el.removeEventListener('mouseup', checkEventMouseUp);
      }
    }, 100);
  };

  const onDragStop: ReactGridLayoutProps['onDragStop'] = (layout, oldItem, newItem, placeholder, event, el) => {
    window.setTimeout(() => {
      // HACK: set timeout so that onDrop will start
      setState((state) => {
        return { dragging: false, blockEl: null, blockLayout: null };
      });
    }, 100);
  };

  const onMouseMove = React.useCallback((evt: React.MouseEvent) => {
    for (let i = boundaries.current.length - 1; i >= 0; i--) {
      let rect: DOMRect = boundaries.current[i];
      if (
        evt.clientX >= rect.x &&
        evt.clientX <= rect.x + rect.width &&
        evt.clientY >= rect.y &&
        evt.clientY <= rect.y + rect.height
      ) {
        if (gridFocus.current !== nestedGrid.current[i]) {
          let [, gridInstance] = nestedGrid.current[i];
          if (gridFocus.current) {
            let [, prevGridInstance] = gridFocus.current;
            log('nestedOnDragLeave');
            prevGridInstance.nestedOnDragLeave(evt);
          }
          log('nestedOnDragStart');
          gridInstance.nestedOnDragStart(evt, blockFocus.current);
          gridFocus.current = nestedGrid.current[i];
        }
        break;
      }
    }

    if (gridFocus.current) {
      gridFocus.current[1].nestedOnDragOver(evt);
    }
  }, []);

  const onNestedDrop = (evt: React.MouseEvent) => {
    log('nested on drop > current grid focus', gridFocus.current);
    log('nested on drop > current block layout', blockFocus.current);

    if (gridFocus.current && blockFocus.current) {
      let [gridId, instance] = gridFocus.current;
      const validDroppable = instance.validDifferentScope(blockFocus.current.blockLayout);

      log('valid droppable', validDroppable);
      if (validDroppable) {
        log('valid droppable', gridId, instance, blockFocus);
        const datatransfer = new DataTransfer();
        datatransfer.setData('text/plain', JSON.stringify(blockFocus.current.blockLayout));
        const ddevent = new DragEvent('drop', { dataTransfer: datatransfer });
        evt.nativeEvent = ddevent;
        instance.onDrop(evt);
      }
    }

    gridFocus.current = null;
    blockFocus.current = null;
    setDragging({ dragging: false });
  };

  return (
    <NestedContext.Provider
      value={{
        setState: setState,
        grids: nestedGrid.current,
        init: true,
        isDragging: dragging,
        onMouseMove: onMouseMove,
        nestedOnDrop: onNestedDrop,
        register: (grid) => {
          nestedGrid.current.push(grid);
        },
        unregister: (grid) => {
          nestedGrid.current = nestedGrid.current.filter((i) => i[0] !== grid[0]);
        },
      }}
    >
      {React.cloneElement(children, {
        onDragStart: (...args) => {
          onDragStart(...args);
          if (children.props.onDragStart) children.props.onDragStart(...args);
        },
        onDragStop: (...args) => {
          onDragStop();
          if (children.props.onDragStop) children.props.onDragStop(...args);
        },
      })}
    </NestedContext.Provider>
  );
}

function NestedChild({ children }) {
  const { setState } = React.useContext(NestedContext);

  const onDragStart: ReactGridLayoutProps['onDragStart'] = (layout, oldItem, newItem, placeholder, event, el) => {
    if (oldItem.i === '__dropping-elem__') return;
    let isReallyDragging = true;
    const checkEventMouseUp = () => {
      isReallyDragging = false;
    };
    el.addEventListener('mouseup', checkEventMouseUp);
    window.setTimeout(() => {
      if (isReallyDragging) {
        setState({ dragging: true, blockEl: el, blockLayout: newItem });
        el.removeEventListener('mouseup', checkEventMouseUp);
      }
    }, 100);
  };

  const onDragStop: ReactGridLayoutProps['onDragStop'] = (layout, oldItem, newItem, placeholder, event, el) => {
    window.setTimeout(() => {
      // HACK: set timeout so that onDrop will start
      setState((state) => {
        return { dragging: false, blockEl: null, blockLayout: null };
      });
    }, 100);
  };

  return React.cloneElement(children, {
    innerRef: (dom) => {
      if (children.props.innerRef) children.props.innerRef.current = dom;
    },
    onDragStart: (...args) => {
      onDragStart(...args);
      if (children.props.onDragStart) children.props.onDragStart(...args);
    },
    onDragStop: (...args) => {
      onDragStop();
      if (children.props.onDragStop) children.props.onDragStop(...args);
    },
    nestedChild: true,
  });
}
