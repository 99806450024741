import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RadioBlank = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="4" y="4" width="12" height="12" rx="6" fill="white" />
    <rect x="4" y="4" width="12" height="12" rx="6" stroke="#C2C7CB" strokeWidth="2" />
  </SvgIcon>
);

export default RadioBlank;
