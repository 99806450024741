import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLog } from '../../../lib/log';
import { actions } from './dashboard-redux';

const log = useLog('dbf:node-focus');

export function NodeFocus() {
  const panelMenuRef = React.useRef<HTMLDivElement>();
  const dispatch = useDispatch();
  const { mode, currentNodeId } = useSelector((state: DashboardState) => {
    return {
      mode: state.mode.type,
      currentNodeId: state.mode.activeNode,
    };
  });

  const markFocus = React.useCallback(
    (evt) => {
      if (panelMenuRef.current.contains(evt.target)) return;

      const el: HTMLDivElement = evt.target as HTMLDivElement;
      const blockEl = el.closest('div.ep-dashboard-item');
      if (blockEl) {
        const nodeId = blockEl.getAttribute('data-node-id');
        if (nodeId !== currentNodeId) {
          dispatch(actions.select({ nodeId }));
        }
      }
    },
    [currentNodeId],
  );

  React.useEffect(() => {
    panelMenuRef.current = document.querySelector('#ep-editor-panel');
    if (mode === 'advance_json_edit') {
      document.removeEventListener('click', markFocus);
    } else {
      document.addEventListener('click', markFocus);
    }
    return () => {
      document.removeEventListener('click', markFocus);
    };
  }, [mode, markFocus]);

  return <React.Fragment></React.Fragment>;
}
