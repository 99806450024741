const transparent = {
  '50%': '80',
  '40%': '66',
  '30%': '4d',
  '25%': '40',
  '20%': '33',
  '10%': '1a',
};

const checkIfHexCode = (color: string) => {
  return /^#[0-9A-F]{6}$/i.test(color);
};

export const convertColor = (color: string) => {
  if (!checkIfHexCode(color)) return color;

  // Add transparent for color
  return color + transparent['30%'];
};

export function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}
