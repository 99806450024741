import shawnOngAvatar from '../../../icons/svg/img/advertiser-community/shawn-ong-avatar.png';
import rachelBeiserAvatar from '../../../icons/svg/img/advertiser-community/rachel-beiser-avatar.png';
import joannLauAvatar from '../../../icons/svg/img/advertiser-community/joanne-lau-avatar.png';
import sumQianYuAvatar from '../../../icons/svg/img/advertiser-community/sum-qian-yu-avatar.png';

import odeyinkaGaniuAvatar from '../../../icons/svg/img/advertiser-community/odeyinka-ganiu-avatar.png';
import stefanieFleischAvatar from '../../../icons/svg/img/advertiser-community/stefanie-fleisch-avatar.png';
import rajeevBharshettyAvatar from '../../../icons/svg/img/advertiser-community/rajeev-bharshetty-avatar.png';
import lynetteNgAvatar from '../../../icons/svg/img/advertiser-community/lynette-ng-avatar.png';
import camilaEmidioAvatar from '../../../icons/svg/img/advertiser-community/camila-emidio-avatar.png';
import brunoRechtAvatar from '../../../icons/svg/img/advertiser-community/bruno-recht-avatar.png';
import busarakhamKandaAvatar from '../../../icons/svg/img/advertiser-community/busarakham-kanda-avatar.png';
import samuelPohAvatar from '../../../icons/svg/img/advertiser-community/samuel-poh-avatar.png';

import muktaSoniAvatar from '../../../icons/svg/img/advertiser-community/mukta-soni-avatar.png';
import moupiyaUkilAvatar from '../../../icons/svg/img/advertiser-community/moupiya-ukil-avatar.png';
import alishaSharmaAvatar from '../../../icons/svg/img/advertiser-community/alisha-sharma-avatar.png';
import peteHotchkissAvatar from '../../../icons/svg/img/advertiser-community/pete-hotchkiss-avatar.png';
import kateMasAvatar from '../../../icons/svg/img/advertiser-community/kate-mas-avatar.png';

import { IRowData } from '../configs';

export type TypeMock = {
  data: { rows: IRowData[] };
  message: string;
  code: number;
};

export const TOP_ADVERTISERS_RES: TypeMock = {
  data: {
    rows: [
      {
        reviews: 123,
        star: 4.7,
        name: 'Shawn Ong',
        location: 'GMT +6 to GMT +8',
        rank: 2,
        position: 'Self-promoted',
        workAddress: 'Tokopedia, Shopee, Lazada',
        avatar: shawnOngAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 46,
          currentPrice: 20,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 32,
        star: 4.9,
        name: 'Rachel Beiser',
        location: 'GMT +2 to GMT +4',
        rank: 2,
        position: 'Self-promoted',
        workAddress: 'Myntra, Noon, Tiktok',
        avatar: rachelBeiserAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 50,
          currentPrice: 25,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 17,
        star: 4.15,
        name: 'Joanne Lau',
        location: 'GMT -6 to GMT -10',
        rank: 2,
        position: 'Self-promoted',
        workAddress: 'Amazon, Walmart',
        avatar: joannLauAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 60,
          currentPrice: 33,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 12,
        star: 4.57,
        name: 'Sum Qian Yu',
        location: 'GMT +6 to GMT +8',
        rank: 2,
        position: 'Self-promoted',
        workAddress: 'Tokopedia, Shopee, Lazada',
        avatar: sumQianYuAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 55,
          currentPrice: 29,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
    ],
  },
  message: 'OK',
  code: 200,
};

export const TOP_ADVERTISERS_RES_2: TypeMock = {
  data: {
    rows: [
      {
        reviews: 42121,
        star: 4.8,
        name: 'Odeyinka Ganiu',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: { timework: '14 months', company: 'Epsilo' },
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: odeyinkaGaniuAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 20,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 25645,
        star: 4.9,
        name: 'Stefanie Fleisch',
        location: 'GMT -2 to GMT -5',
        rank: 1,
        position: { timework: '6 months', company: 'Epsilo' },
        workAddress: 'Amazon, Mercado Libre, Shopee',
        avatar: stefanieFleischAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 50,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 16135,
        star: 4.5,
        name: 'Rajeev Bharshetty',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: { timework: '15 months', company: 'Epsilo' },
        workAddress: 'Tokopedia, Shopee, Lazada',
        avatar: rajeevBharshettyAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 49,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 12698,
        star: 4.95,
        name: 'Lynette Ng',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: { timework: '10 months', company: 'Epsilo' },
        workAddress: 'Tokopedia, Shopee, Lazada',
        avatar: lynetteNgAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 52,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 7145,
        star: 4.67,
        name: 'Camila Emidio',
        location: 'GMT +3 to GMT +9',
        rank: 1,
        position: { timework: '15 months', company: 'Epsilo' },
        workAddress: 'Apple Store, Google Store',
        avatar: camilaEmidioAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 40,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 6241,
        star: 4.5,
        name: 'Bruno Recht',
        location: 'GMT -6 to GMT -10',
        rank: 2,
        position: { timework: '11 months', company: 'Epsilo' },
        workAddress: 'Amazon, Walmart, Etsy, Ebay',
        avatar: brunoRechtAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 39,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 3135,
        star: 4.73,
        name: 'Busarakham Kanda',
        location: 'GMT +2 to GMT +4',
        rank: 2,
        position: { timework: '24 months', company: 'Epsilo' },
        workAddress: 'Myntra, Noon, Sephora, Tiktok',
        avatar: busarakhamKandaAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 45,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
      {
        reviews: 1456,
        star: 4.6,
        name: 'Samuel Poh',
        location: 'GMT -6 to GMT -10',
        rank: 1,
        position: { timework: '17 months', company: 'Epsilo' },
        workAddress: 'Amazon',
        avatar: samuelPohAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 46,
          perValue: 'store/week',
        },
        about: '',
        checklist: [],
      },
    ],
  },
  message: 'OK',
  code: 200,
};

export const MAKETER_RES: TypeMock = {
  data: {
    rows: [
      {
        reviews: 136,
        star: 4.5,
        name: 'Mukta Soni',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: 'Performance Manager, mastering in using Epsilo',
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: muktaSoniAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 21,
          perValue: 'store/week',
        },
        about: `Hello!! Are You Struggling With E-commerce Onsite Ads? Finding Someone Who Can Create Highly Targeted Tokopedia, Shopee, Lazada Ads Campaign? I'll help your business to grow with Facebook Ads & Instagram Ads and help you to get targeted customers and optimize ads. \nI am a certified professional and experienced Shopify store Designer & digital marketer. I am shopify facebook ads expert and have in-depth knowledge about shopify video ads. I have grown hundreds of dropshipping, E-Commerce stores & various businesses by deploying my advertising strategy and techniques to improve revenues while maintaining positive ROI.`,
        checklist: [],
      },
      {
        reviews: 156,
        star: 3.8,
        name: 'Moupiya Ukil',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: 'Performance Manager',
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: moupiyaUkilAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 31,
          perValue: 'store/week',
        },
        about: `Cost-effective services at reasonable prices: My name is Moupiya Ukil, I have great in-hand experience in multiple skills. I am ready to take care of your projects to complete them on the given deadline. My areas of expertise are : 1. Onsite Marketing (Paid and Organic) -> Amazon Marketing -> Flipkart...`,
        checklist: [],
        isSelected: true,
      },
      {
        reviews: 99,
        star: 4.1,
        name: 'Alisha Sharma',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: 'E-commerce Specialist',
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: alishaSharmaAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 26,
          perValue: 'store/week',
        },
        about: `This is Alisha Sharma, I am an experienced Digital Marketing professional. My experience involves connecting your product/service and taking that directly to the heart and pulse of people.\nAnalyzing and reporting social media presence, engagement, and effectiveness across all platforms to boost overall impact and performance.\nI can confidently say that my marketing strategy is different to many others. I believe in creating value for prospects/cold audience. There is no putting your offer out to cold audience. That’s where most the agencies and Facebook experts waste most of advertising spent`,
        checklist: [],
      },
      {
        reviews: 352,
        star: 4.8,
        name: 'Pete Hotchkiss',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: 'Performance Specialist',
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: peteHotchkissAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 49,
          perValue: 'store/week',
        },
        about: `I specialize in helping startups, small businesses, and entrepreneurs reach their marketing goals by implementing proven marketing tactics and data-driven strategies. I am well-versed with a variety of marketing practices and platforms. I have successful experience with the following:\nMy Services \n✔ Competitor analysis\n✔ Keyword Research\n✔ Media Planning\n✔ Content writing\n✔ Weekly report\n`,
        checklist: [],
        isSelected: true,
      },
      {
        reviews: 123,
        star: 4.0,
        name: 'Kate Mas',
        location: 'GMT +6 to GMT +8',
        rank: 1,
        position: 'Performance Operator',
        workAddress: 'Shopee, Jumia, Flipkart, Nykaa',
        avatar: kateMasAvatar,
        hireNote: {
          currency: 'USD',
          previousPrice: 0,
          currentPrice: 39,
          perValue: 'store/week',
        },
        about: `Hi...!\nIf you are looking for someone who can set up Shopee ads manager and run, manage and optimize your Shopee campaign.\nThen You are in the right place!\nI am a Shopee advertising expert. I know how to reach a highly targeted audience through the Shopee ads campaign. I will bring the best profit by applying various marketing plans and strategies for your Drop Shipping, F-commerce, e-commerce, and any type of business.`,
        checklist: [],
        isSelected: true,
      },
    ],
  },
  message: 'OK',
  code: 200,
};
