const tagColors = [
  // {
  //   hex_code: '#2B3245',
  //   shade: 100,
  // },
  // {
  //   hex_code: '#F2F3F4',
  //   shade: 20,
  // },
  {
    hex_code: '#EBF6FF',
    shade: 20,
  },
  {
    hex_code: '#FCEEEC',
    shade: 20,
  },
  {
    hex_code: '#ECF8F4',
    shade: 20,
  },
  {
    hex_code: '#FEF2EC',
    shade: 20,
  },
  {
    hex_code: '#FDF1CD',
    shade: 20,
  },
  {
    hex_code: '#E7F7F7',
    shade: 20,
  },
  {
    hex_code: '#FAEFFF',
    shade: 20,
  },
  {
    hex_code: '#FFEFFC',
    shade: 20,
  },
];

export const tagsData = {
  campaign_tag: [
    { label: 'Normal day', value: 'normal_day', color: '#EBF6FF' },
    { label: 'Brand day', value: 'brand_day', color: '#FDF1CD' },
    { label: 'Super brand day', value: 'super_brand_day', color: '#ECF8F4' },
    { label: 'Other', value: 'other', color: '#FEF2EC' },
  ],
  ad_object_tag: [
    { label: 'High-performing', value: 'high_performing', color: '#ECF8F4' },
    { label: 'Mid-performing', value: 'mid_performing', color: '#FDF1CD' },
    { label: 'Low-performing', value: 'low_performing', color: '#FCEEEC' },
    { label: 'Priority', value: 'priority', color: '#EBF6FF' },
    { label: 'Non-priority', value: 'non_priority', color: '#FFEFFC' },
    { label: 'New', value: 'new', color: '#E7F7F7' },
    { label: 'Other', value: 'other', color: '#FEF2EC' },
  ],
  keyword_tag: [
    { label: 'Generic', value: 'generic', color: '#EBF6FF' },
    { label: 'Brand', value: 'brand', color: '#ECF8F4' },
    { label: 'Competitor', value: 'competitor', color: '#FCEEEC' },
    { label: 'Category', value: 'category', color: '#FAEFFF' },
    { label: 'Relevant', value: 'relevant', color: '#FDF1CD' },
    { label: 'Must win', value: 'must_win', color: '#FFEFFC' },
    { label: 'New', value: 'new', color: '#E7F7F7' },
    { label: 'Other', value: 'other', color: '#FEF2EC' },
  ],
};
