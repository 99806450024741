import * as React from 'react';
import { Route } from 'react-router';
import { getPagePrefix1 } from '../global';
import { EO2_SYSTEM_PAGE_ID } from './constant';
import DemoSystemPage from './demo-system-page';
/**
 * ff.ui_page_keyword_analysis:start
 */
import KeywordAnalysisPage from './keyword-analysis-page';

/*
 * ff.ui_page_keyword_analysis:end
 */
import MassOperationCampaignManagementSP from './mass-operation/campaign-management';

/**
 * ff.adoption_rate_table:start
 */
import AdoptionRatePage from './product-health/adoption-rate-page';
/**
 * ff.adoption_rate_table:end
 */

export function withSystemPage(LegacyPages) {
  return function NewSystemPage() {
    return (
      <SystemPagesRoutes>
        <LegacyPages />
      </SystemPagesRoutes>
    );
  };
}

function SystemPagesRoutes({ children }: { children: JSX.Element | JSX.Element[] }) {
  return (
    <React.Fragment>
      <SystemPageList />
      {children}
    </React.Fragment>
  );
}

function SystemPageList() {
  return (
    <>
      <Route
        path={`${getPagePrefix1()}/${EO2_SYSTEM_PAGE_ID.massOperation.campaignManagement}`}
        exact={true}
        component={MassOperationCampaignManagementSP}
      ></Route>
      {ff.adoption_rate_table && (
        <Route
          path={`${getPagePrefix1()}/${EO2_SYSTEM_PAGE_ID.productHealth.adoptionRate}`}
          exact={true}
          component={AdoptionRatePage}
        ></Route>
      )}
      {ff.ui_page_keyword_analysis && (
        <Route
          path={`${getPagePrefix1()}/${EO2_SYSTEM_PAGE_ID.marketplaceAdvertising.keywordDiscovery}`}
          component={KeywordAnalysisPage}
          exact={true}
        ></Route>
      )}
    </>
  );
}
