import * as React from 'react';
import Icon from '@ep/insight-ui/icons/Icon';
import { Box, createStyles, makeStyles, Switch, Theme, withStyles } from '@material-ui/core';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import RadioList from '@ep/insight-ui/elements/list-control/radio-list';
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
import { CreateCSSProperties, CSSProperties } from '@material-ui/core/styles/withStyles';
export const textStyles: CSSProperties = {
  fontStyle: 'normal',
  '&.header': {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '20px',
    color: '#253746',
  },
  '&.text': {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#000000',
  },
  '&.currency': {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '28px',
    color: '#253746',
  },
  '&.label-secondary': {
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#8C98A4',
  },
  '&.secondary': {
    color: '#8C98A4',
  },
  '&.text2': {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
  '&.text3': {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#8C98A4',
  },
  '&.p': {
    margin: 0,
  },
  '&.ml': {
    marginLeft: 14,
  },
  '&.subHeader': {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#253746',
  },
};
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  text: textStyles as CreateCSSProperties,
  cell: {
    '&.p-0': {
      padding: 0,
    },
  },
  clearBorder: {
    border: 0,
  },
  popularDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '6px 28px',
    width: '100%',
    height: '32px',
    background: '#EBF6FF',
  },
});

export const TickIcon = ({ color = '' }: { color?: string }) => {
  return (
    <Box display={'flex'} ml={'8px'} height={'20px'} width={'14px'} alignSelf={'center'}>
      <Icon type={'done'} style={{ width: '100%', height: '100%', color: color }} />
    </Box>
  );
};

type ICellSubscription = {
  value: string | number;
  type?: 'currency' | 'percent';
  currency?: string;
  per?: Array<string>;
} | null;
export interface ISubscriptionRow {
  name: string;
  starter: ICellSubscription;
  team: ICellSubscription;
  enterprise: ICellSubscription;
}
export const createDataSubscriptionTable = (
  name: string,
  starter: ICellSubscription,
  team: ICellSubscription,
  enterprise: ICellSubscription,
) => {
  return { name, starter, team, enterprise };
};

export const CellCurrency = ({ cell }: { cell: ICellSubscription }) => {
  const classes = useStyles();
  const perValues = React.useMemo(() => {
    if (cell && cell.per) return cell.per.slice(0, 2);
    return [];
  }, [cell]);
  return (
    <Box display={'flex'} alignItems={'flex-end'}>
      {cell ? (
        cell.value !== 'Contact sale for pricing' ? (
          <React.Fragment>
            <span className={`${classes.text} currency`}>
              {cell.type == 'currency' && (cell.currency ?? '$') + (cell.value ?? '')}
              {cell.type == 'percent' && (cell.value ?? '') + '%'}
            </span>
            <div style={{ marginBottom: 3, display: 'inline-flex' }}>
              {perValues.map((item, index) => (
                <div key={index} className={`${classes.text} label-secondary`}>
                  / {item}
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : (
          <span className={`${classes.text} text3`}>Contact sale for pricing</span>
        )
      ) : (
        <span className={`${classes.text} text2`} style={{ marginRight: 10 }}>
          Not Available
        </span>
      )}
    </Box>
  );
};

export const Label = ({ label, type = 'text' }: { label: string; type?: 'header' | 'text' | 'subHeader' }) => {
  const classes = useStyles();
  return (
    <Box display={'flex'} alignItems={'center'}>
      <span className={`${classes.text} ${type}`}>{label}</span>
    </Box>
  );
};

export const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      background: 'transparent',
      borderRadius: ' 100%',
      '&$checked': {
        transform: 'translateX(12px)',
        // color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: theme.palette.primary.main,
          borderColor: theme.palette.primary.main,
        },
      },
    },
    thumb: {
      width: 12,
      height: 12,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.common.white}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#C2C7CB',
    },
    checked: {},
  }),
)(Switch);
type PaginationProps = {
  value: number;
  onChange: (newValue: number) => void;
  options: OptionType[];
};
export const Pagination = ({ value, onChange, options }: PaginationProps) => {
  const classes = useStyles();
  const defaultOptions: OptionType[] = React.useMemo(() => {
    if (options.length > 0)
      return options.map((op) => {
        if (op.value == String(value)) {
          op.checked = true;
        } else {
          op.checked = false;
        }
        return op;
      });
    return [];
  }, [options, value]);
  const [items, setItems] = React.useState<OptionType[]>(defaultOptions);
  const handleOnChange = (optionsValue: OptionType[]) => {
    setItems(optionsValue);
    const optionSelected = optionsValue.find((item) => item.checked);
    if (optionSelected) {
      onChange(+optionSelected.value);
    }
  };
  return (
    <React.Fragment>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-start'}>
        <Box width={'8px'} mr={'5px'} height={'100%'} component={'div'} display={'flex'}>
          <Icon type={'descensing'} style={{ width: '100%', height: '100%', color: '#8C98A4' }} />
        </Box>
        <div className={`${classes.text} text secondary`}>
          Show <span className={`${classes.text} text`}>{value} more</span>
        </div>
        <Dropdown alignMenu="right" icon="threeDotsVertical" label="">
          <RadioList items={items} onChange={handleOnChange} draggable={false} />
        </Dropdown>
      </Box>
    </React.Fragment>
  );
};

export type ICellButton = {
  label: string;
  onClick: () => void;
  disabled?: boolean;
  color?: 'primary' | 'secondary';
};
export const createCellButton = (
  label: string,
  onClick: () => void,
  color?: 'primary' | 'secondary',
  disabled?: boolean,
) => ({ label, onClick, disabled: disabled ?? false, color: color ?? 'primary' });
export type IObjectButtons = {
  Starter: ICellButton;
  Team: ICellButton;
  Enterprise: ICellButton;
};
export const createDataObjectButtons = (
  Starter: ICellButton,
  Team: ICellButton,
  Enterprise: ICellButton,
): IObjectButtons => {
  return { Starter, Team, Enterprise };
};

export type IObjectInfoSubscription = {
  Starter: string;
  Team: string;
  Enterprise: string;
};
export const createDataObjectInfoSubscription = (
  Starter: string,
  Team: string,
  Enterprise: string,
): IObjectInfoSubscription => {
  return { Starter, Team, Enterprise };
};
