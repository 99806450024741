import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import Typography, { ETypographyProp } from '../../text-style/Typography';

/**


 * ff.welcome_page_questionnaire:start


 */

import clsx from 'clsx';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

/**


   * ff.welcome_page_questionnaire:end


   */

/* Type */
type HeaderListProps = {
  title: string;
  addOn?: React.ReactNode;
  onClickAddOn?: () => void;
  disabled?: boolean;
  variant?: ETypographyProp['variant'];
  className?: string;
  iconHead?: React.ReactNode;
};

/** Style */
const useStyles = makeStyles(() => ({
  addOnContainer: {
    textAlign: 'right',
    flexGrow: 0.3,
  },
  title: {
    paddingLeft: '7px',
  },
  header: {
    borderRadius: '4px',
    paddingTop: '8px',
    paddingLeft: '8px',
  },
  headerMobile: {
    marginBottom: '4px',
  },
  addOnBtn: {
    padding: '5px',
    '&:focus': {
      backgroundColor: 'transparent',
      color: colors.icon.default,
    },
  },
}));

const HeaderList: React.FC<HeaderListProps> = ({
  title,
  variant = 'h6',
  addOn,
  onClickAddOn,
  disabled,
  className,
  iconHead = null,
}: HeaderListProps) => {
  const classes = useStyles();

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      className={clsx(`${classes.header} ${className}`, {
        [classes.headerMobile]: isMobileView,
      })}
    >
      <Grid item xs style={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant={variant} style={{ display: 'inline-flex' }}>
          {title}
        </Typography>
        <span style={{ display: 'inline-flex' }}>{iconHead}</span>
      </Grid>
      {addOn && (
        <Grid item xs className={classes.addOnContainer}>
          {addOn && (
            <Button
              disabled={disabled}
              variant="outlined"
              className={classes.addOnBtn}
              onClick={() => onClickAddOn && onClickAddOn()}
            >
              <Icon type="plus" size="12px" />
            </Button>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default HeaderList;
