import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const TurnIntoGroup = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.707 4.28626L11.5719 2.14788C11.2564 1.83189 10.7172 2.05592 10.7172 2.50233V3.98448H4.0057C2.8981 3.98448 2 4.88397 2 5.99412V7.00312C2 7.55569 2.44738 8.00376 2.99909 8.00376C3.5508 8.00376 3.99818 7.55569 3.99818 7.00312V5.99579H10.7172V7.48881C10.7172 7.93605 11.2564 8.15925 11.5719 7.84326L13.707 5.70488C14.0976 5.31281 14.0976 4.67832 13.707 4.28626Z"
      fill={props.color}
    />
    <path
      d="M11.9943 12.0155C13.1019 12.0155 14 11.116 14 10.0067V8.99771C14 8.44514 13.5526 7.99707 13.0009 7.99707C12.4492 7.99707 12.0018 8.44514 12.0018 8.99771V10.0042H5.28273V8.51202C5.28273 8.06479 4.74354 7.84159 4.42804 8.15758L2.29297 10.2951C1.90234 10.6872 1.90234 11.3225 2.29297 11.7137L4.42804 13.8521C4.74354 14.1681 5.28273 13.9441 5.28273 13.4977V12.0155H11.9943Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default TurnIntoGroup;
