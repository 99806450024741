import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const SearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.6584 12.2444L10.2435 8.8295C10.8306 7.89485 11.0529 6.77669 10.8679 5.68854C10.6829 4.60039 10.1036 3.6185 9.24065 2.93037C8.37765 2.24224 7.29143 1.89608 6.18941 1.958C5.08739 2.01992 4.04678 2.48558 3.2663 3.26606C2.48583 4.04654 2.02017 5.08714 1.95825 6.18916C1.89633 7.29119 2.24248 8.37741 2.93062 9.2404C3.61875 10.1034 4.60064 10.6827 5.68879 10.8677C6.77693 11.0527 7.8951 10.8304 8.82975 10.2432L12.2446 13.6581C12.4321 13.8456 12.6864 13.9509 12.9515 13.9509C13.2166 13.9509 13.4709 13.8456 13.6584 13.6581C13.8459 13.4707 13.9512 13.2164 13.9512 12.9513C13.9512 12.6861 13.8459 12.4319 13.6584 12.2444ZM6.46023 8.96888C5.96402 8.96888 5.47895 8.82174 5.06636 8.54606C4.65378 8.27038 4.33221 7.87854 4.14231 7.4201C3.95242 6.96166 3.90274 6.45721 3.99954 5.97053C4.09635 5.48385 4.3353 5.03681 4.68617 4.68593C5.03705 4.33506 5.48409 4.09611 5.97077 3.9993C6.45745 3.90249 6.9619 3.95218 7.42035 4.14207C7.87879 4.33196 8.27062 4.65353 8.5463 5.06612C8.82198 5.47871 8.96913 5.96378 8.96913 6.45999C8.97045 6.78983 8.90645 7.11667 8.78083 7.42166C8.65522 7.72664 8.47046 8.00375 8.23723 8.23698C8.00399 8.47022 7.72689 8.65497 7.4219 8.78059C7.11691 8.90621 6.79007 8.9702 6.46023 8.96888Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default SearchIcon;
