import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import Selection from '@ep/insight-ui/elements/input-form/form-templates/form-controls/selection';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { get } from 'lodash';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  formPassword: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkWord: {
    color: '#0369C7',
    cursor: 'pointer',
  },
  credentialDescription: {
    color: '#8C98A4',
    paddingBottom: '16px',
  },
  selectStorefrontLabel: {
    color: '#8C98A4',
    marginBottom: '8px',
  },
  section: {
    marginTop: '24px',
  },
  expanded: {
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      marginBottom: '10px',
    },
  },
  error: {
    '& .eip1-MuiFormHelperText-contained p, & .eip1-MuiFormHelperText-contained svg': {
      color: '#D4290D',
    },
  },
  helper: {
    '& > p.eip1-MuiFormHelperText-contained': {
      display: 'none',
    },
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    ...(ff.integrate_storefront_ui_v2
      ? {
          borderBottom: '1px solid #C2C7CB',
        }
      : {
          borderRadius: '8px',
        }),
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  buttonCancel: {
    color: '#8C98A4',
  },
  mobileContent: {
    padding: ff.integrate_storefront_ui_v2 ? '20px 25px 0 25px' : '15px 0 0 0',
    height: '100vh',
  },
  option: {
    gap: '10px',
  },
  buttonNext: {
    width: '100%',
    height: '40px',
    marginTop: '30px',
  },
});

const siteCodeList = [
  {
    label: 'Public',
    value: 'PUBLIC',
    disabled: true,
  },
  {
    label: 'Seller center',
    value: 'SELLER_CENTER',
  },
  {
    label: 'Open API',
    value: 'OPEN_API',
    disabled: true,
  },
];

const SelectMarketplaceForm = ({
  initialValues,
  optionList = { marketplaces: [], countries: [] },
  onClose,
  onNextStep,
  isSelectBtnDisabled,
  enableSiteCode,
}: any) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;
  const getCountriesByMarketplace = (currentMarketplaceId: string) => {
    if (!currentMarketplaceId) return [];

    const marketplaces: Array<{ value: string; label: string; payload: { countries: Array<string> } }> = get(
      optionList,
      'marketplaces',
      [],
    );
    const currentMarketplace = marketplaces.find((item) => item.value == currentMarketplaceId);
    if (!currentMarketplace) return [];

    const countries: Array<{ value: string; label: string }> = get(optionList, 'countries', []);

    return countries.filter((item) => get(currentMarketplace, 'payload.countries', []).includes(item.value));
  };
  return (
    <Grid container direction={'column'}>
      {!isMobile ? (
        <Grid item xs={12}>
          <span className={`${classes.text} title`}>Connect your store</span>
        </Grid>
      ) : (
        <div className={classes.mobileHeader}>
          <h4 className={classes.mobileTitle}>Select marketplace</h4>
          <div className={classes.mobileButton}>
            <Button className={classes.buttonCancel} color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          onNextStep(values);
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, setFieldValue }) =>
          !isMobile ? (
            <Form>
              <Grid item xs={12} className={classes.section}>
                <ExpansionForm
                  label={'Select marketplace'}
                  level={'2'}
                  detailContentSize={'full'}
                  defaultExpanded={true}
                  className={classes.expanded}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Selection
                          options={optionList.marketplaces}
                          placeholder={'Choose marketplace'}
                          classNameContainer={`${classes.formControl} select`}
                          onChange={(event) => {
                            setFieldValue('marketplace', event.target.value);
                            const listCountriesSupport = getCountriesByMarketplace(event.target.value);
                            const isExistCountry = listCountriesSupport.find((item) => item.value == values.country);
                            if (!isExistCountry) setFieldValue('country', '');
                          }}
                          label={'Marketplace'}
                          value={values.marketplace}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Selection
                          options={getCountriesByMarketplace(values.marketplace)}
                          placeholder={'Choose country'}
                          classNameContainer={`${classes.formControl} select`}
                          onChange={(event) => setFieldValue('country', event.target.value)}
                          label={'Country'}
                          value={values.country}
                          disabled
                        />
                      </Grid>
                      {enableSiteCode ? (
                        <Grid item xs={12}>
                          <Selection
                            options={siteCodeList}
                            placeholder={'Choose site code'}
                            classNameContainer={`${classes.formControl} select`}
                            onChange={(event) => setFieldValue('siteCode', event.target.value)}
                            label={'Site code'}
                            value={values.siteCode}
                          />
                        </Grid>
                      ) : null}
                    </Grid>
                  </Box>
                </ExpansionForm>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" mt={4} alignItems="center" justifyContent="flex-end">
                  <Button
                    color="secondary"
                    variant="contained"
                    component="span"
                    className={`${classes.button} cancel`}
                    onClick={onClose}
                  >
                    <span className={`${classes.text} textButton`}>Cancel</span>
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.button}
                    disabled={!values.marketplace || !values.country || !optionList?.marketplaces?.length}
                    type="submit"
                  >
                    Next
                  </Button>
                </Box>
              </Grid>
            </Form>
          ) : (
            <Form>
              <Box className={classes.mobileContent}>
                <Grid container spacing={2} className={classes.option}>
                  <Grid item xs={12}>
                    <Selection
                      options={optionList.marketplaces}
                      placeholder={'Choose marketplace'}
                      classNameContainer={`${classes.formControl} select`}
                      onChange={(event) => {
                        setFieldValue('marketplace', event.target.value);
                        const listCountriesSupport = getCountriesByMarketplace(event.target.value);
                        const isExistCountry = listCountriesSupport.find((item) => item.value == values.country);
                        if (!isExistCountry) setFieldValue('country', '');
                      }}
                      label={'MARKETPLACE'}
                      value={values.marketplace}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Selection
                      options={getCountriesByMarketplace(values.marketplace)}
                      placeholder={'Choose country'}
                      classNameContainer={`${classes.formControl} select`}
                      onChange={(event) => setFieldValue('country', event.target.value)}
                      label={'COUNTRY'}
                      value={values.country}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonNext}
                    disabled={!values.marketplace || !values.country || !optionList?.marketplaces?.length}
                    type="submit"
                  >
                    Next
                  </Button>
                </Grid>
              </Box>
            </Form>
          )
        }
      </Formik>
    </Grid>
  );
};
export default SelectMarketplaceForm;
