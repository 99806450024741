import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Pincolumn = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.33476 0.129435C5.85908 -0.137018 5.25083 0.0211068 4.97619 0.482616C4.70155 0.944125 4.86454 1.53426 5.34022 1.80071L10.508 4.69544C10.9837 4.96189 11.592 4.80377 11.8666 4.34226C12.1412 3.88075 11.9782 3.29062 11.5026 3.02416L6.33476 0.129435Z"
      fill={`${props.color ? props.color : '#253746'}`}
    />
    <path
      d="M8.85902 11.2389C8.70688 11.8712 7.9797 12.1929 7.41623 11.8773L4.80696 10.4157L4.30967 11.2514C4.03504 11.7129 3.42678 11.871 2.9511 11.6045C2.47542 11.3381 2.31243 10.748 2.58707 10.2864L3.08436 9.45078L0.474832 7.98906C-0.0886418 7.67343 -0.165052 6.90157 0.323227 6.45763L5.405 1.83723L10.4424 4.65892L8.85902 11.2389Z"
      fill={`${props.color ? props.color : '#253746'}`}
    />
  </SvgIcon>
);

export default Pincolumn;
