import moment from 'moment';
import { escape, snakeCase } from 'lodash';
import minimatch from 'minimatch';

export const convertToLocalTime = (time, format = 'YYYY-MM-DD HH:mm:ss') => {
  return moment(time).add(moment().utcOffset(), 'minutes').format(format);
};

export const getPivotValueGetter = (key: string) => {
  return `pivot_${key}`;
};

export const getPivotColKey = (key: string) => {
  return `pv__${snakeCase(escape(key))}`;
};

export const handleUpdateVisible = (tableId, key, value) => {
  window.dispatchEvent(
    new CustomEvent(`${tableId}_visible_${key}`, {
      detail: {
        value,
      },
    }),
  );
};

export const splitComma = (filter) => {
  return filter?.split(',')?.filter((i) => String(i).trim()) || [];
};

export const validateAccessBy = (accessBy, userEmail, userId?) => {
  try {
    let allowAccess = false;
    const includedAccess = accessBy.filter((i) => !String(i).startsWith('!'));
    const excludeAccess = accessBy.filter((i) => String(i).startsWith('!'));

    allowAccess = allowAccess || includedAccess.length === 0;
    allowAccess = allowAccess || includedAccess.some((i) => minimatch(userId, i, { matchBase: true }));
    allowAccess =
      allowAccess || includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
    allowAccess =
      allowAccess &&
      (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userId, i, { matchBase: true })));

    allowAccess =
      allowAccess &&
      (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

    return allowAccess;
  } catch (e) {
    return false;
  }
};
