import * as React from 'react';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Select, SelectProps, Divider, Box, MenuList, MenuProps, Menu } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';

type PropsStyle = {
  inputWidth: string;
  inputNoShadow: boolean;
  fullWidth?: boolean;
};
/** Style */
const useStyles = makeStyles(() => ({
  select: (props: PropsStyle) => ({
    overflow: 'unset',
    width: props.fullWidth ? '100%' : 'auto',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '0px',
    },
  }),
  popper: {
    marginTop: '4px',
    boxSizing: 'border-box',
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    position: 'absolute',
    right: '10px',
    color: colors.icon.default,
  },
}));

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      color: colors.text.default,
      height: '17px',
      border: `1px solid ${colors.border.default}`,
      paddingLeft: '8px',
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&:hover': {
        borderColor: colors.border.highlight,
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
        borderColor: 'unset',
      },
    },
  }),
)(InputBase);
export type AlignType = 'left' | 'right';
interface SelectFormProps extends SelectProps {
  options?: OptionSelectType[][];
  value: OptionSelectType;
  onChangeValue?: (value: OptionSelectType) => void;
  inputWidth?: string;
  alignMenu?: AlignType;
  placeholder?: string;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  noShadow?: boolean;
  fullWidth?: boolean;
}
const StyledMenu = withStyles({
  paper: {
    boxShadow: '0px 6px 12px rgb(140 152 164 / 25%)',
    '&::-webkit-scrollbar': {
      marginLeft: '-8px',
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
      border: '1px solid transparent',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
})((props: MenuProps) => (
  <Menu
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    style={{ padding: 0 }}
    {...props}
  />
));
export const StyledMenuItem = withStyles(() => ({
  root: {
    minHeight: '32px',
    minWidth: '192px',
    borderRadius: '4px',
    padding: '8px',
    fontWeight: 400,
    '&:hover': {
      backgroundColor: colors.action.secondaryHovered,
    },
    '&.Mui-selected': {
      backgroundColor: `#EBF6FF !important`,
      color: '#0369C7',
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
  },
}))(MenuItem);

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: colors.text.subdued,
  },
}));

const Placeholder = ({ text }: { text: string }) => {
  const classes = usePlaceholderStyles();
  return (
    <Typography variant="body2" className={classes.placeholder}>
      {text}
    </Typography>
  );
};

const SelectForm: React.FC<SelectFormProps> = ({
  options,
  value,
  onChangeValue,
  alignMenu = 'left',
  inputWidth,
  placeholder,
  renderOption,
  disabled = false,
  noShadow = false,
  fullWidth = false,
  ...rest
}: SelectFormProps) => {
  const classes = useStyles({ inputWidth, inputNoShadow: noShadow, fullWidth });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelect =
    ({ item, groupIndex, currentIndex }: { groupIndex: number; currentIndex: number; item: OptionSelectType }) =>
    () => {
      onChangeValue(item);
      handleClose();
    };
  const renderValue = (tex) => {
    if (value) {
      return value.label;
    }
    return placeholder && <Placeholder text={placeholder} />;
  };
  return (
    <FormControl className={classes.select}>
      <Select
        readOnly
        disabled={disabled}
        autoFocus={false}
        onClick={handleClick}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: alignMenu,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: alignMenu,
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.popper,
          },
        }}
        variant="outlined"
        value={value}
        displayEmpty
        IconComponent={() => <Icon type="chevron" className={classes.icon} />}
        renderValue={renderValue}
        input={<BootstrapInput />}
        {...rest}
      />
      <StyledMenu anchorEl={anchorEl} keepMounted open={!disabled && Boolean(anchorEl)} onClose={handleClose}>
        {options.map((op: OptionSelectType[], idx: number) => (
          <Box key={`opGroup_${idx}`}>
            {op.map((item, index) => (
              <StyledMenuItem
                key={`opGroup_${idx}_${index}`}
                onClick={handleSelect({ groupIndex: idx, currentIndex: index, item })}
                style={{
                  color: item?.value === value?.value && '#0369C7',
                  backgroundColor: item?.value === value?.value && '#EBF6FF',
                }}
              >
                {renderOption ? renderOption(item) : item.label}
              </StyledMenuItem>
            ))}

            <Box my={2}>{idx !== options.length - 1 && <Divider />}</Box>
          </Box>
        ))}
      </StyledMenu>
    </FormControl>
  );
};

export default SelectForm;
