import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
    color: colors.text.default,
    '& + &': {
      marginTop: '8px',
    },
    '&.audiences input::placeholder': {
      color: colors.text.subdued,
    },
  },
  form: {
    width: '100%',
  },
}));

interface IAudience {
  audience_store_visitors: number;
  audience_similar_products: number;
}

type Props = {
  data: {
    value: IAudience;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const AudiencesEditor = (props: Props) => {
  const { data, title = '', onSubmit, setAnchorEl } = props;
  const classes = useStyles();

  const defaultValue = React.useMemo(() => {
    return {
      audience_store_visitors: data.value.audience_store_visitors,
      audience_similar_products: data.value.audience_similar_products,
    };
  }, [data]);

  const [value, setValue] = React.useState<IAudience>(defaultValue);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit({
      value: {
        audienceStoreVisitorsBiddingPremium: value.audience_store_visitors,
        audienceSimilarProductsBiddingPremium: value.audience_similar_products,
      },
      row: data.node.data,
    });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  const rows = [
    {
      startLabel: 'Increase bid',
      value: String(value.audience_store_visitors || 0),
      endLabel: 'On store visitors in the past 15 days',
      field: 'audience_store_visitors',
    },
    {
      startLabel: 'Increase bid',
      value: String(value.audience_similar_products || 0),
      endLabel: 'On in-market audience for similar product',
      field: 'audience_similar_products',
    },
  ];

  const handleChange = (v: string, field: string) => {
    setValue({
      ...value,
      [field]: v,
    });
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0px 8px 8px 8px', width: '486px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        {rows.map((row, index) => (
          <div key={String(index)} className={clsx(classes.block, 'audiences')}>
            <Typography variant="body2">{row.startLabel}</Typography>
            <TextBudgetInput
              style={{ width: '110px' }}
              defaultValue={row.value}
              currency={'%'}
              onChangeText={(v) => handleChange(v, row.field)}
              disabledDebounce
              placeholder={'Your value'}
              noSelectBox
            />
            <Typography variant="body2">{row.endLabel}</Typography>
          </div>
        ))}
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default AudiencesEditor;
