import { isString, omit } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import qs from 'qs';
import { REQUEST_TIMEOUT } from '../constant';
import { mapDomain, replaceUrl } from './domain';
const JSONbigString = require('json-bigint')({ storeAsString: true });

let authorizationHeader = null;
let userWorkspaceHeader = null;
let userPageHeader = null;
let isSandboxHeader = null;
export const setAuthorizationHeader = ({
  authorization,
  workspace,
  page,
  isSandbox,
}: {
  authorization: string;
  workspace: string;
  page: string;
  isSandbox: string;
}) => {
  if (!authorization) {
    console.error('setAuthorization.....', authorization);
  }
  if (!workspace) {
    console.error('setUserWorkspace.....', workspace);
  }
  if (!page) {
    console.error('setPage.....', page);
  }
  if (!isSandbox) {
    console.error('setIsSandbox.....', isSandbox);
  }

  authorizationHeader = authorization;
  userWorkspaceHeader = workspace;
  userPageHeader = page;
  isSandboxHeader = isSandbox;

  return {
    authorizationHeader,
    userWorkspaceHeader,
    userPageHeader,
    isSandboxHeader,
  };
};

export const getAuthorizationHeader = () => {
  return authorizationHeader;
};

function commonHeaders() {
  const headers = {
    'X-Trace-ID': moment().format('YYYYMMDDHHmmss') + nanoid(10),
    'X-Requestor': 'eip-fe',
    'X-Env': process.env.EIP_BUILD_ENV || 'dev',
  };

  if (authorizationHeader) {
    headers['Authorization'] = authorizationHeader;
  }

  if (userWorkspaceHeader) {
    headers['x-user-workspace'] = userWorkspaceHeader;
  }

  if (userPageHeader) {
    headers['x-user-page'] = userPageHeader;
  }

  if (isSandboxHeader == '1') {
    headers['x-is-sandbox'] = isSandboxHeader;
  }

  return headers;
}

const map = mapDomain;
// const TIMEOUT_SEC = 5;
const TIMEOUT_SEC = REQUEST_TIMEOUT;

function processUrl(url) {
  let finUrl = url;
  if (authorizationHeader && /\.epsilo\.io/.test(url) && !/auth0\.epsilo\.io/.test(url)) {
    finUrl = finUrl.replace(/([^.]+)?\.epsilo\.io/, '$1-auth0.epsilo.io');
  }
  return replaceUrl(finUrl);
}

const Timeout = (time: number) => {
  const controller = new AbortController();
  setTimeout(() => controller.abort('Timeout with ' + TIMEOUT_SEC + 's'), time * 1000);
  return controller;
};

export function post(url, body) {
  const [path, query] = url.split('?');
  const searchParams = new URLSearchParams(query);
  const infoParams = new URLSearchParams(query);
  infoParams.delete('_eiptraceparent');
  const qsString = infoParams.toString();
  return fetch(processUrl(path + (qsString !== '' ? '?' + qsString : '')), {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...commonHeaders(),
      traceparent: searchParams.get('_eiptraceparent'),
    },
    credentials: 'include',
    body: isString(body) ? body : JSON.stringify(body),
    // signal: Timeout(39).signal,
    signal: Timeout(TIMEOUT_SEC).signal,
  })
    .then((res) => res.text())
    .then((res) => {
      try {
        return JSONbigString.parse(res);
      } catch(e) {
        return JSON.parse(res)
      }
    });
}

export function get(url, body?) {
  let finUrl = processUrl(url);
  const [path, query] = url.split('?');

  const infoParams = qs.parse(query);

  let queryParams = omit(infoParams, '_eiptraceparent');
  if (body) {
    queryParams = { ...body, ...queryParams };
  }
  finUrl = `${finUrl}?${qs.stringify(queryParams, { arrayFormat: 'comma' })}`;

  return fetch(finUrl, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      ...commonHeaders(),
      traceparent: infoParams._eiptraceparent as string,
    },
    credentials: 'include',
    body: isString(body) ? body : JSON.stringify(body),
    // signal: Timeout(39).signal,
    signal: Timeout(TIMEOUT_SEC).signal,
  }).then((res) => res.json());
}
