import { ContainerResponsiveContext, aim, NodeEditContext } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeStyles, Popover, Box, withStyles, Grid, Button, Tooltip } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';
import { get, set } from 'lodash';
import * as React from 'react';
import Dropdown from '../../dropdown-menu/dropdown';
import { FieldSelectedValue } from '../../inline-dialog/filter/filter-group-dialog';
import FilterForm, { IFilterItem } from '../../inline-dialog/filter/filter-group-dialog/filter-form-group';
import SortForm, { ISortItem } from '../../inline-dialog/sort/sort-form-table/sort-form';
import { CurrencyForm } from '../../inline-edit/cell/currency/currency-editor';
import MenuList from '../../list-control/menu-list';
import { DropdownMenuDataType } from '../../list-control/menu-list/menu-list';
import { useToast } from '../../notifications/hook';
import PropertiesMenu from '../dropdown-properties';
import { SortType } from '../table';
import { filterOperators, getSelectedOperators } from '../table-helper';
import { checkEpsiloTableEndpoint, EpsiloTableObject } from '@ep/insight-ui/system/backbone/data-source/common';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { PERSONALIZATION, SHORTCUT_ACTIONS } from '@ep/insight-ui/system/helper/constant';
import { ButtonOptionPivotDialog } from './button-option-pivot';
import { LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE } from '@ep/insight-ui/system/helper/constant';
import AccumulativeDialog from './accumulative-dialog';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import HeatmapPeriodically from '../../inline-dialog/heatmap-periodically';
import Periodically from '../../inline-dialog/periodically';
import ButtonDropdown from '../../button/button-dropdown';
import Icon from '@ep/insight-ui/icons/Icon';
import ConditionalWrap from '../../util/conditional-wrap';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider, DropTargetMonitor, useDrag, useDrop } from 'react-dnd';
import ItemTypes from '../../drag-n-drop/ItemTypes';
import ButtonOptionShortcut from './button-option-shortcut';
import { getConst } from '@ep/insight-ui/sw/constant/common';
const API_EXPORT = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/action/export';

const scrollbarStyles = {
  '& ul': {
    width: '100% !important',
  },
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '16px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: '#babac038',
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: '#babac038',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
    borderRadius: '16px',
    border: '5px solid #F8FAFD',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#a0a0a5',
    border: '4px solid #f4f4f4',
  },
  '&::-webkit-scrollbar-button ': { display: 'none' },
};

const ListItem = withStyles(() => ({
  root: {
    borderRadius: '4px',
    paddingRight: '0px',
    marginBottom: 8,
    paddingLeft: 15,
  },
  button: {
    // backgroundColor: 'red',
    borderRadius: '4px',
    fontStyle: ' normal',
    fontWeight: 400,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
}))(MuiListItem);

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '9px 18px',
    borderStyle: 'dashed',
    '&:active': {
      color: '#FFFFFF',
      backgroundColor: colors.action.subtlePressed,
    },
  },
  paper: {
    ...scrollbarStyles,
    paddingTop: '8px',
  },
  paper_properties: {
    width: 'fit-content',
    maxWidth: 'unset',
    padding: '2px 0px',
    /**
     * when property dropdown open
     *    width of component properties will be different from component menu-list-option
     *    so if you don't set
     *       left: unset
     *    then left-position will stay only initial value of component menu-list-option
     *    thus will be lose the contents of  component properties
     */
    // left: 'unset !important',
    // right: 0,
    maxHeight: 'calc(100vh - 30%)',
  },
  popover: {
    '& .eip1-MuiPopover-paper': {
      background: ' #FFFFFF',
      boxShadow: ' 0px 6px 12px rgb(140 152 164 / 25%)',
      padding: 0,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#babac038',
        marginLeft: 20,
      },
      '&::-webkit-scrollbar-track:hover': {
        backgroundColor: '#babac038',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '5px solid #F8FAFD',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4',
      },
      '&::-webkit-scrollbar-button ': { display: 'none' },
    },
  },
  sortDialog: {
    // maxWidth: 'unset',
    padding: '12px 16px 6px 16px',
    maxWidth: 'calc(100vw - 60%)',
    // width: 'fit-content',
    minWidth: '432px',
    maxHeight: '60vh',
  },
  currencyDialog: {
    padding: '12px 16px 8px 16px',
    maxWidth: 'calc(100vw - 60%)',
    minWidth: '333px',
    maxHeight: '60vh',
  },
  period: {
    padding: '12px 16px 8px 16px',
    maxWidth: 'calc(100vw - 60%)',
    minWidth: '250px',
    maxHeight: '60vh',
  },
  activeButton: {
    background: '#0369C7',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#338ED8',
    },
  },
  defaultButton: {
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
  viewByHeader: {
    padding: '8px 7px',
    marginBottom: '8px',
  },
  containerButton: {
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      whiteSpace: 'nowrap',
    },
  },
  active: {
    color: `${colors.text.highLight}`,
    '&:hover': {
      color: `${colors.text.highLight}`,
      backgroundColor: `#CCE3F5`,
    },
    '&.eip1-MuiButton-active': {
      color: `white`,
    },
  },
}));

type TypeOptionComponent =
  | 'properties'
  | 'sort'
  | 'filter'
  | 'currencySwitch'
  | 'periodically'
  | 'pivot'
  | 'accumulative';
const ButtonOption = ({
  dataMenu,
  isTableCompact = false,
  excludedViewBy = [],
  hasDimensionViewBy = false,
  isShortcut = false,
}: {
  isTableCompact?: boolean;
  dataMenu?: DropdownMenuDataType[];
  excludedViewBy?: any[];
  hasDimensionViewBy?: boolean;
  isShortcut?: boolean;
}) => {
  const classes = useStyles();
  const origin = React.useRef<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [dropdown, setDropdown] = React.useState<{ open: boolean; optionComponent: TypeOptionComponent }>({
    open: false,
    optionComponent: null,
  });
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const isVisibleProperty = backboneContext.getVisibility('property');
  const isVisibleSort = backboneContext.getVisibility('sort');
  const isVisibleFilter = backboneContext.getVisibility('filter');
  const isVisibleChangeCurrency = backboneContext.getVisibility('currencySwitch');
  const isLockedView = get(backboneContext.getConfig('view', {}), 'isLock', false);
  const allowExport = backboneContext.getConfig('system.allowExport', 'yes');
  const shortcutActions = backboneContext.getConfig(SHORTCUT_ACTIONS, null);
  const endpoint = backboneContext.getConfig('endpoint.GET_TABLE_DATA', '');
  const { isEditMode } = React.useContext(NodeEditContext);
  const exportTooltipDes = getConst(
    'EXPORT_TOOLTIP_DES',
    'Download all data in this table, up to 10,000 rows. If you need to download more data, please contact us via support@epsilo.io',
  );

  let isPeriodically;
  if (ff.time_periodically) {
    isPeriodically = backboneContext.getVisibility('periodically');
  }
  const isPivotDisplay = backboneContext.getVisibility('pivot');
  const isAccumulativeDisplay = backboneContext.getVisibility('accumulative');

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  const [isDialogSort, setIsDialogSort] = React.useState(false);
  const [isDialogFilter, setIsDialogFilter] = React.useState(false);

  const hidden = backboneContext.getConfig('system.hiddenComponents', []);
  React.useEffect(() => {
    if (
      !shortcutActions ||
      isTableCompact ||
      isMobileView ||
      (shortcutActions && !shortcutActions['properties']?.shortcut)
    ) {
      if (isVisibleProperty) {
        setDropdown({ open: true, optionComponent: 'properties' });
        setAnchorEl(origin.current);
      }
    }
    if (isVisibleProperty && isShortcut) {
      setDropdown({ open: true, optionComponent: 'properties' });
    }
  }, [isVisibleProperty]);

  React.useEffect(() => {
    if (isTableCompact || isMobileView || (shortcutActions && !shortcutActions['sort']?.shortcut)) {
      if (isVisibleSort) {
        setDropdown({ open: true, optionComponent: 'sort' });
        setAnchorEl(origin.current);
      }
    }
  }, [isVisibleSort]);

  React.useEffect(() => {
    if (isTableCompact || isMobileView || (shortcutActions && !shortcutActions['filter']?.shortcut)) {
      if (isVisibleFilter) {
        setDropdown({ open: true, optionComponent: 'filter' });
        setAnchorEl(origin.current);
        if (ff.mobile_interaction_zone) {
          setIsDialogFilter(isVisibleFilter);
        }
      }
    }
  }, [isVisibleFilter]);

  React.useEffect(() => {
    if (isVisibleChangeCurrency) {
      setDropdown({ open: true, optionComponent: 'currencySwitch' });
      setAnchorEl(origin.current);
    }
  }, [isVisibleChangeCurrency]);

  React.useEffect(() => {
    if (isTableCompact || isMobileView || (shortcutActions && !shortcutActions['periodically']?.shortcut)) {
      if (isPeriodically) {
        setDropdown({ open: true, optionComponent: 'periodically' });
        setAnchorEl(origin.current);
      }
    }
  }, [isPeriodically]);

  React.useEffect(() => {
    if (isTableCompact || isMobileView || (shortcutActions && !shortcutActions['pivot']?.shortcut)) {
      if (isPivotDisplay) {
        setDropdown({ open: true, optionComponent: 'pivot' });
        setAnchorEl(origin.current);
      }
    }
  }, [isPivotDisplay]);

  React.useEffect(() => {
    if (isTableCompact || isMobileView || (shortcutActions && !shortcutActions['accumulative']?.shortcut)) {
      if (isAccumulativeDisplay) {
        setDropdown({ open: true, optionComponent: 'accumulative' });
        setAnchorEl(origin.current);
      }
    }
  }, [isAccumulativeDisplay]);

  const handleClose = () => {
    if (isVisibleProperty) {
      backboneContext.changeVisibility('property', false);
    }
    if (ff.can_not_click_properties) {
      if (isVisibleSort) {
        backboneContext.changeVisibility('sort', false);
      }
      if (isVisibleFilter) {
        backboneContext.changeVisibility('filter', false);
      }
    } else {
      if (isVisibleSort || isTableCompact) {
        backboneContext.changeVisibility('sort', false);
      }
      if (isVisibleFilter || isTableCompact) {
        backboneContext.changeVisibility('filter', false);
      }
    }
    if (ff.time_periodically) {
      if (isPeriodically) {
        backboneContext.changeVisibility('periodically', false);
      }
    }
    if (isPivotDisplay) {
      backboneContext.changeVisibility('pivot', false);
    }
    if (isAccumulativeDisplay) {
      backboneContext.changeVisibility('accumulative', false);
    }
    setDropdown({ open: false, optionComponent: null });
    setAnchorEl(null);
  };

  const getRules = (length) => {
    return length === 0 ? '' : `${length} rule${length > 1 ? 's' : ''}`;
  };

  const { onToast } = useToast();
  const handleExport = async () => {
    const response = await backboneContext.exportEtable();
    onToast({ title: response.title, messages: response.messages, variant: response.variant });
  };

  const getDataMenu = (): DropdownMenuDataType[][] => {
    if (dataMenu && dataMenu.length > 0) {
      /**
       *  this just handle open change currency dialog because backbone not support changeVisibility for currencySwitch-config
       * */
      const idxConfigChangeCurrency = dataMenu.findIndex((item) => item.name == 'currencySwitch');
      if (idxConfigChangeCurrency !== -1) {
        const dataMenuTemp = [...dataMenu];
        dataMenuTemp[idxConfigChangeCurrency].onClick = () => {
          // click open currency
          setDropdown({ open: true, optionComponent: 'currencySwitch' });
          setAnchorEl(origin.current);
        };
        return [dataMenuTemp];
      }
      return [dataMenu];
    }

    const arr: DropdownMenuDataType[] = [
      // {
      //   title: 'Auto-all size column',
      //   icon: 'column',
      //   colorStartIcon: '#253746',
      //   onClick: () => undefined,
      // },
      ...(isLockedView && !isEditMode
        ? []
        : [
            {
              title: 'Resize visible columns',
              name: 'resize_all_columns',
              icon: getConst('SHORTCUT_RESIZE_VISIBLE_COLUMNS_ICON', 'edit'),
              colorStartIcon: '#253746',
              onClick: () => backboneContext.getColumnApi()?.autoSizeAllColumns(),
            },
          ]),
      {
        title: 'Properties',
        name: 'properties',
        icon: getConst('SHORTCUT_PROPERTIES_ICON', 'properties'),
        colorStartIcon: '#253746',
        onClick: () => backboneContext.changeVisibility('property', true),
      },
      {
        title: 'Sort',
        name: 'sort',
        icon: getConst('SHORTCUT_SORT_ICON', 'sort'),
        iconSize: '14px',
        colorStartIcon: '#253746',
        onClick: () => {
          isMobileView ? setIsDialogSort(true) : setIsDialogSort(false);
          backboneContext.changeVisibility('sort', true);
        },
        subText: getRules(backboneContext.getConfig('sort').length),
      },
      {
        title: 'Filter',
        name: 'filter',
        icon: getConst('SHORTCUT_FILTER_ICON', 'filter'),
        iconSize: '18px',
        colorStartIcon: '#253746',
        onClick: () => {
          isMobileView ? setIsDialogFilter(true) : setIsDialogFilter(false);
          backboneContext.changeVisibility('filter', true);
        },
        subText: getRules(backboneContext.getConfig('filter').length),
      },
      ...(aim.canAccess()
        ? [
            {
              title: 'Import',
              name: 'import',
              icon: getConst('SHORTCUT_IMPORT_ICON', 'import'),
              colorStartIcon: '#253746',
              onClick: () => {},
              disable: isLockedView && !isEditMode,
            },
          ]
        : []),
      {
        title: 'Export',
        name: 'export',
        icon: getConst('SHORTCUT_EXPORT_ICON', 'export'),
        colorStartIcon: '#253746',
        onClick: () => {
          isMobileView ? setIsDialogFilter(true) : setIsDialogFilter(false);
          handleExport();
        },
        disable: allowExport === 'no',
        ...(checkEpsiloTableEndpoint(endpoint) && allowExport === 'yes' && exportTooltipDes
          ? {
              tooltip: exportTooltipDes,
            }
          : {}),
      },
    ];
    hidden.forEach((hiddenItem) => {
      const idx = arr.findIndex((item) => get(item, 'name', '') == hiddenItem.toLowerCase());
      if (idx !== -1) {
        arr.splice(idx, 1);
      }
    });

    return backboneContext.addon('dataMenu.properties', () => [arr])(arr, () => undefined);
  };
  // const dataMenu: DropdownMenuDataType[] = ;
  // options
  const filterOptions: Array<FieldSelectedValue> = backboneContext.getAvailableFilterColumns().map((item) => {
    return {
      label: item.name,
      value: item.id,
      dataType: item.dataType ?? 'string',
      selectedOperators: backboneContext.addon('selection.filter', () => getSelectedOperators(item.id))(
        item.id,
        getSelectedOperators(item.id),
      ),
      isAdditionalFilter: item.isAdditionalFilter,
    };
  });

  const sortOptions = backboneContext.getAvailableSortColumns
    ? backboneContext.getAvailableSortColumns().map((item, index) => {
        return {
          label: item.name,
          value: item.id,
          dataType: item.dataType ?? 'string',
        };
      })
    : [];

  // model config
  const sortModel: Array<SortType> = backboneContext.getConfig('sort');
  const filterModel: IFilterItem[] = backboneContext.getConfig('filter');
  const currencyValue: string = backboneContext.getConfig('currency', '');

  const sortModelDataConvert: ISortItem[] = React.useMemo(() => {
    return sortModel.reduce((acc, item) => {
      const opt = sortOptions.find((op) => op.value == item.field);
      if (opt) {
        return [
          ...acc,
          {
            field: opt,
            sort: item.sort,
            locked: isLockedView ? item.locked : false,
          },
        ];
      }
      return acc;
    }, []);
  }, [sortModel]);

  const setSortModel = (model: SortType[]) => {
    const sortData = model.filter(({ locked }) => locked).concat(model.filter(({ locked }) => !locked));
    if (isEditMode && isLockedView) {
      const version = new Date().getTime();
      const view = backboneContext.getConfig('view');
      set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
      set(view, ['combinator', 'sort'], sortData);

      const views = backboneContext.getConfig('views');
      for (const v of views) {
        if (v.id === view.id) {
          set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
          set(v, ['combinator', 'sort'], sortData);
        }
      }

      backboneContext.updateConfig({
        sort: sortData,
        view,
        views,
      });
    } else {
      backboneContext.changeConfig('sort', sortData);
    }
  };
  const setFilterModel = (model: IFilterItem[]) => {
    if (isEditMode && isLockedView) {
      const version = new Date().getTime();
      const view = backboneContext.getConfig('view');
      set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
      set(view, ['combinator', 'filter'], model);

      const views = backboneContext.getConfig('views');
      for (const v of views) {
        if (v.id === view.id) {
          set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
          set(v, ['combinator', 'filter'], model);
        }
      }

      backboneContext.updateConfig({
        filter: model,
        view,
        views,
      });
    } else {
      backboneContext.changeConfig('filter', model);
    }
  };

  const getOptionFilterAvailable = async (field: string, filterData?: IFilterItem[]) => {
    return backboneContext.getFilterOptionValue(field, filterData);
  };

  const getFilterData = () => {
    const filterTemp: { field: string; value: string } = get(
      backboneContext,
      'getTempStorage',
      () => null,
    )('filterModal');

    if (filterTemp) {
      const defaultFilterItem: IFilterItem = {
        type: 'filter',
        logicalOperator: 'and',
        field: {
          label: '',
          value: filterTemp.field,
          dataType: 'string',
        },
        queryType: {
          label: '',
          value: '',
          dataType: 'string',
        },
        queryValue: {
          value: filterTemp.value,
        },
      };
      const field = filterOptions.find((item) => item.value == filterTemp.field);
      if (field) defaultFilterItem.field = field;
      const queryType = get(filterOperators, [defaultFilterItem.field.dataType, 0, 0], null);
      if (queryType) defaultFilterItem.queryType = queryType;
      filterModel.push(defaultFilterItem);

      (backboneContext as any).updateTempStorage('filterModal', null);
    }
    return filterModel;
  };

  const RenderOptionConponent = (type: TypeOptionComponent) => {
    if (type) {
      switch (type) {
        case 'properties': {
          return (
            <PropertiesMenu
              handleClose={handleClose}
              disabledEffectOnchange
              isShow={dropdown.open && dropdown.optionComponent == 'properties'}
            />
          );
        }
        case 'sort': {
          return (
            <div className={classes.sortDialog}>
              <SortForm
                options={sortOptions}
                sortModel={sortModelDataConvert}
                setSortModel={setSortModel}
                disabledIconNoFilter={true}
                onClose={handleClose}
                isDialogSort={isDialogSort}
                setIsDialogSort={setIsDialogSort}
              />
            </div>
          );
        }
        case 'filter': {
          return (
            <div className={classes.sortDialog}>
              <FilterForm
                options={filterOptions}
                operators={filterOperators}
                filterModel={getFilterData()}
                setFilterModel={setFilterModel}
                enabledSearchOptions={false}
                enableFillerGroup
                disabledIconNoFilter
                getOptionAvailable={getOptionFilterAvailable}
                onClose={handleClose}
                isDialogFilter={isDialogFilter}
                setIsDialogFilter={setIsDialogFilter}
              />
            </div>
          );
        }
        case 'currencySwitch': {
          return (
            <div className={classes.currencyDialog}>
              <CurrencyForm
                title={'Currency'}
                value={currencyValue}
                onClose={handleClose}
                submitLabel={'Apply'}
                onSubmit={(newCurrency) => {
                  backboneContext.changeConfig('currency', newCurrency);
                }}
              />
            </div>
          );
        }
        case 'periodically': {
          return (
            <Periodically
              hasDimensionViewBy={hasDimensionViewBy}
              excludedViewBy={excludedViewBy}
              setDropdown={setDropdown}
              setAnchorEl={setAnchorEl}
            />
          );
        }
        case 'pivot': {
          return <ButtonOptionPivotDialog handleClose={handleClose} />;
        }
        case 'accumulative': {
          return <AccumulativeDialog handleClose={handleClose} />;
        }
        default:
          return null;
      }
    }
  };

  if (isShortcut) {
    const menu = getDataMenu()[0];
    return (
      <ButtonOptionShortcut
        RenderOptionConponent={RenderOptionConponent}
        getFilterData={getFilterData}
        sortModelDataConvert={sortModelDataConvert}
        menu={menu}
        handleClose={handleClose}
      />
    );
  }

  return (
    <div ref={origin} {...(ff.logic_remove_badge ? { className: 'threedots' } : {})}>
      {/* button Filter */}
      <Dropdown
        disabledClickContentClose={false}
        alignMenu="right"
        icon="threeDotsVertical"
        label=""
        classNamePaper={`${classes.paper} ${dropdown.optionComponent && classes[`paper_${dropdown.optionComponent}`]}`}
        sizeButton={`32px`}
        sizeIcon={'14px'}
        dataMenuPage={getDataMenu()}
      >
        {!Boolean(anchorEl) && <MenuList listMenu={getDataMenu()} />}
      </Dropdown>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MonitorContainer component={'div'} mId={'popup-panel'} mLabel={dropdown.optionComponent}>
          {RenderOptionConponent(dropdown.optionComponent)}
        </MonitorContainer>
      </Popover>
    </div>
  );
};

export default ButtonOption;
