import {
  CssBaseline,
  Grid,
  makeStyles,
  StylesProvider,
  ThemeProvider,
  createGenerateClassName,
} from '@material-ui/core';
import React from 'react';
import EpsiloTheme from '@ep/insight-ui/lib/epsilo-theme';
import { seedClassName } from '@ep/insight-ui/lib/epsilo-theme';
import FormFooter from '@ep/insight-ui/elements/input-form/form-templates/footer';

import { ContainerResponsiveContext } from '@eip/next/lib/main';
import ExploreEpsilo from '@ep/insight-ui/elements/input-form/form-templates/form-groups/sign-up-form/explore-epsilo';

import EllipseFormBackground from '@ep/insight-ui/elements/input-form/ellipse-form-background';
import { useHistory } from 'react-router';

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const generateClassName = createGenerateClassName({
  seed: seedClassName,
});

export function StartPage({ children, ...rest }: any) {
  const classes = useStyles();

  const containerClass = React.useContext(ContainerResponsiveContext).containerClass;
  const isMobileView = containerClass === 'eres--small';

  return (
    <StylesProvider generateClassName={generateClassName}>
      <ThemeProvider theme={EpsiloTheme}>
        <CssBaseline />
        <ConditionalWrap
          condition={rest.isRegisterPage && !isMobileView}
          wrap={(children) => {
            return (
              <Grid container>
                <Grid item xs={6}>
                  <ExploreEpsilo />
                </Grid>
                <Grid item xs={6}>
                  {children}
                </Grid>
              </Grid>
            );
          }}
        >
          <div
            className={`${classes.root} ${containerClass}`}
            style={{ background: rest.isRegisterPage && '#FFF' }}
            {...rest}
          >
            {!rest.isRegisterPage && <EllipseFormBackground type={rest.type} />}
            {children}
            <FormFooter copyright={`© ${new Date().getFullYear()} Epsilo. All Rights Reserved`} />
            {/* <div className={classes.footer}>
            <div>
              <Grid container justify="space-between" spacing={3} alignItems="center">
                <Grid item md={6} xs={6}>
                  © 2020 Epsilo. All Rights Reserved
                </Grid>
                <Grid item md={6} xs={6}>
                  <Grid container spacing={4} justify={'flex-end'}>
                    <Grid item>FAQ</Grid>
                    <Grid item>Privacy Policy</Grid>
                    <Grid item>Terms and condition</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div> */}
          </div>
        </ConditionalWrap>
      </ThemeProvider>
    </StylesProvider>
  );
}

const useStyles = makeStyles(() => ({
  root: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '100vh',
    backgroundColor: '#fff',
    color: '#fff',
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: 60,
    width: '100%',
    padding: 16,
  },
}));
