import { get as _get, merge, omit } from 'lodash';

export function pageMapping(serverPage) {
  const page: any = {};
  const payload = typeof serverPage.payload === 'string' ? JSON.parse(serverPage.payload) : serverPage.payload;

  page.layouts = _get(payload, 'format.layouts', {});
  page.layoutColumns = _get(payload, 'format.layoutColumns', null);
  page.nodes = (serverPage.content || []).map((i) => ({
    id: i,
  }));
  page.demoMetricRange = [];
  page.updatedAt = serverPage.updated_at;
  page.updatedBy = serverPage.updated_by;
  page.table = serverPage.table;
  page.dbVersion = serverPage.payload_schema_version;
  page.presetEid = serverPage.block_eid;
  page.blockEid = serverPage.block_eid;
  page.blockType = serverPage.block_type;
  page.parentId = serverPage.parent_id;
  page.entityId = serverPage.entity_id;
  page.entityType = serverPage.entity_type;
  page.content = serverPage.content;
  page.properties = serverPage.properties;
  page.properties = omit(
    merge(
      {
        groupId: 'marketplace_operating',
        icon: undefined,
      },
      payload.properties,
    ),
  );
  page.format = payload.format;
  return page;
}

export function blockMapping(sb): NodeData {
  return {
    id: sb.block_eid,
    blockEid: sb.block_eid,
    blockType: sb.block_type,
    table: sb.table,
    parentId: sb.parent_id,
    parentTable: sb.parent_table,
    chartLibId: sb.block_type,
    entityId: sb.entity_id,
    entityType: sb.entity_type,
    properties: _get(sb, 'payload.properties'),
    format: _get(sb, 'payload.format'),
    customAttributes: _get(sb, 'payload.properties.customAttributes'),
    mainAttributes: _get(sb, 'payload.properties.mainAttributes'),
    permission: _get(sb, 'payload.properties.permission'),
    roles: _get(sb, 'payload.properties.roles'),
    next_permission: _get(sb, 'payload.properties.next_permission'),
    content: _get(sb, 'content', []),
    updatedAt: _get(sb, ['updated_at'], ''),
    updatedBy: _get(sb, ['updated_by'], ''),
  };
}
