import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import GroupButtonSubmit from '../group-button-submit';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { IValueStatusTextFormat } from '@ep/insight-ui/elements/table/format/status-text-format';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  inputText: {
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      height: '32px',
    },
    width: '100%',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  TextField: {
    width: '100%',
  },
  InputPaper: {
    '& input': {
      width: '100%',
    },
  },
}));

type Props = {
  data: {
    value: IValueStatusTextFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const CreateNoteEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>('');
  const [error, setError] = React.useState<{ visibled: boolean; textError: string }>({
    visibled: false,
    textError: '',
  });

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const isValidation = () => {
    // validate

    return true;
  };
  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    if (isValidation()) {
      setError({
        textError: '',
        visibled: false,
      });

      const valueTmp: IValueStatusTextFormat = JSON.parse(JSON.stringify(value));
      setIsSubmitting(true);
      const response: any = await onSubmit({ value: { value: valueTmp }, row: data.node.data });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    } else {
      setError({
        textError: 'error',
        visibled: true,
      });
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();
  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <TextFieldCustom
          defaultValue={value}
          onChangeText={setValue}
          error={error.visibled}
          helperText={error.textError}
          className={classes.TextField}
          classNamePaperInput={classes.InputPaper}
        />
      </div>
      <GroupButtonSubmit
        onCancel={handleClosed}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        submitLabel={'Create'}
      />
    </div>
  );
};

export default CreateNoteEditor;
