import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import MultipleSelect from '../popover-components/multiple-select';
import { EIP_CONSTANT, aim } from '@eip/next/lib/main';
import * as uuid from 'uuid';
import { cloneDeep } from 'lodash';

const getActions = () => {
  return request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/action-template', {}, true, 10 * 1000);
};

const updateActions = (actions) => {
  return request.post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv', {
    k: 'action-template',
    v: actions,
    version: Date.now(),
  });
};

export const actionButtons = [
  {
    title: 'Select action(s)',
    id: 'select_action',
    popoverComponent: MultipleSelect,
    params: {
      requestOptions() {
        return new Promise((resolve) => {
          getActions()
            .then((res) => {
              if (res.success) {
                const result = Object.keys(res.data.v).map((el) => {
                  return {
                    label: res.data.v[el].name,
                    value: el,
                  };
                });
                resolve(result);
              }
              resolve([]);
            })
            .catch(() => resolve([]));
        });
      },
      handleSubmit(selectedItems, gridApi, callback) {
        getActions().then((res) => {
          if (res.success) {
            const actions = { ...res.data.v };
            const rowData = [];
            selectedItems.forEach((el) => {
              rowData.push(actions[el]);
            });
            gridApi.applyTransaction({
              add: rowData,
            });
          }
          if (callback) {
            callback();
          }
        });
      },
      handleDelete(selectedItems, _, setOptions) {
        if (aim.isSuperAdmin()) {
          getActions().then((res) => {
            if (res.success) {
              const actions = { ...res.data.v };
              selectedItems.forEach((el) => {
                delete actions[el];
              });
              updateActions(actions).then(() => {
                const newOptions = Object.keys(actions).map((el) => {
                  return {
                    label: actions[el].name,
                    value: el,
                  };
                });
                setOptions(newOptions);
              });
            }
          });
        } else {
          alert('You do not have a permission to perform this action.');
        }
      },
    },
  },
  {
    title: 'Save action(s)',
    id: 'save_action',
    async onClick({ gridApi, event }) {
      if (aim.isSuperAdmin()) {
        const btnElement = event.currentTarget;
        if (!btnElement.hasAttribute('disabled')) {
          btnElement.setAttribute('disabled', '');

          const response = await getActions();

          if (response.success) {
            const actions = response.data.v || {};
            const selectedRows = gridApi.getSelectedRows();
            selectedRows.forEach((el) => {
              actions[uuid.v4()] = el;
            });

            await updateActions(actions);
          }
          btnElement.removeAttribute('disabled');
        }
      }
    },
  },
  {
    title: 'Clone action(s)',
    id: 'clone_action',
    onClick({ gridApi, event }) {
      gridApi.applyTransaction({
        add: gridApi.getSelectedRows().map((row) => cloneDeep(row)),
      });
    },
  },
];
