import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT, NodeEditContext } from '@eip/next/lib/main';

import { debounce, Popover, List, ListItem, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TextEditor from '@ep/insight-ui/elements/text-editor';

import EpsiloTemplateConfig from '../epsilo-template-config';

const useStyles = makeStyles({
  textContainer: {
    padding: '0px 8px',
    '& *:not(hr)': {
      margin: 0,
      minHeight: '1.43em',
    },
    '& code': {
      background: '#87837826',
      color: '#EB5757',
      borderRadius: '3px',
      fontSize: '85%',
      padding: '0.2em 0.4em',
    },
    '& pre': {
      background: '#0D0D0D',
      color: '#FFF',
      padding: '0.75rem 1rem',
      borderRadius: '0.5rem',
      '& code': {
        color: 'inherit',
        padding: 0,
        background: 'none',
        fontSize: '0.8rem',
      },
    },
    '& blockquote': {
      borderLeft: '2px solid #0d0d0d1a',
      paddingLeft: '1rem',
    },
    '& ul[data-type=taskList] li': {
      display: 'flex',
      alignItems: 'center',
      '&::mark': {
        display: 'none',
      },
    },
    '& hr': {
      borderBottom: 'none',
    },
  },
  fontSizeSection: {
    display: 'flex',
    marginTop: '16px',
    alignItems: 'center',
    columnGap: '8px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '8px',
    },
  },
});

const defaultText = '<p>Click to edit this text</p>';

class TextChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    if (ff.add_text_block) {
      return <TextBlock data={data} />;
    }

    return (
      <div style={{ padding: '12px' }}>
        <div>Double click to edit your text.</div>
      </div>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId, ...args) {
    return <BlockConfigForm data={data} onSubmit={handleSubmit} nodeId={nodeId} />;
  }
}

function BlockConfigForm({ data, onSubmit, nodeId }: { data: any; onSubmit: any; nodeId: string }) {
  return (
    <>
      <EpsiloTemplateConfig nodeId={nodeId} config={data} chartId={'textPlain'} />
    </>
  );
}

function Editor({ data, onSubmit }: { data: any; onSubmit: any }) {
  const onChange = (value) => {
    onSubmit({
      text: value,
    });
  };

  const handleOnChange = React.useCallback(debounce(onChange, 500), []);

  return (
    <div>
      <TextEditor defaultText={data?.text || defaultText} onChange={handleOnChange} hideBtn={true} />
    </div>
  );
}

function TextBlock({ data }: { data: NodeData }) {
  const classes = useStyles();
  const { isEditMode, onUpdateCustomAttributes } = React.useContext(NodeEditContext);

  if (isEditMode) {
    return <Editor data={data.customAttributes} onSubmit={(config) => onUpdateCustomAttributes(data, config)} />;
  }

  return (
    <div
      className={classes.textContainer}
      dangerouslySetInnerHTML={{ __html: data.customAttributes?.text || defaultText }}
    />
  );
}

export default {
  blockType: 'textPlain',
  label: 'Text',
  blockComponent: TextChartLib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 4,
  },
};
