import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RadioChecked = (props: SvgIconProps) => (
  <SvgIcon {...props} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" rx="7" fill="#006EC6" />
    <rect x="4" y="4" width="6" height="6" rx="3" fill="white" />
  </SvgIcon>
);

export default RadioChecked;
