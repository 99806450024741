import Dropdown, { DropdownMenuDataType } from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import ResponsiveBar from '@ep/insight-ui/elements/reponsive-bar/responsive-bar';
import TopBar from '@ep/insight-ui/elements/top-bar';
import Icon from '@ep/insight-ui/icons/Icon';
import { Avatar, Box, Grid, IconButton, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import RedoIcon from '@material-ui/icons/Redo';
import UndoIcon from '@material-ui/icons/Undo';
import React from 'react';
import { aim, socket, usePage } from '@eip/next/lib/main';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { optionsColumnColor } from '@ep/insight-ui/elements/chart-type/data-demo';

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    background: '#fff',
  },
  row: {
    verticalAlign: 'top',
  },
  buttonContainer: {
    '& button:not(:first-child)': {
      marginLeft: '12px',
    },
    marginRight: `${240 - 10}px`,
  },
  buttonItem: {
    marginLeft: '12px',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    fontSize: '12px',
  },
  tooltipContainer: {
    background: '#253746',
  },
  editingContainer: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const TooltipRender = (props) => {
  return (
    <div className={props.classes.tooltip}>
      <span>{props.name}</span>
      <span>{props.email}</span>
      <span>Joined {moment(props.createdAt).fromNow()}</span>
    </div>
  );
};

export const AppHeader = ({ onPublish }) => {
  const classes = useStyles();
  const [openPageSettings, setOpenPageSettings] = React.useState(false);

  const [socketId, setSocketId] = React.useState(null);
  const [joinedAt, setJoinedAt] = React.useState(new Date().getTime());
  const { currentPage, updatePage, pageSocket } = usePage();

  const { id, at, by } = pageSocket?.lastUpdated || {};

  const wasUpdated = React.useMemo(() => {
    return at > joinedAt && id != socketId;
  }, [socketId, pageSocket, joinedAt]);

  React.useEffect(() => {
    setSocketId(socket.id);
  }, [socket.id]);

  React.useEffect(() => {
    if (id == socket.id) {
      setJoinedAt(at);
    }
  }, [id, at, socketId]);

  const [dataMenu, setDataMenu] = React.useState([
    [
      {
        title: 'Duplicate',
        icon: 'duplicate',
        onClick: () => {},
      },
      {
        title: 'Remove',
        icon: 'delete',
        onClick: () => {},
      },
      {
        title: 'Page setting',
        icon: 'edit',
        onClick: () => {
          setOpenPageSettings(true);
        },
      },
    ],
  ]);

  function stringToColor(str: string) {
    if (typeof str !== 'string') return null;
    const sumOfCharCode = [...str].reduce((a, b) => a + b.charCodeAt(), 0);
    const index = sumOfCharCode % optionsColumnColor.slice(1).length;
    return optionsColumnColor.slice(1)[index];
  }
  function stringAvatar(name: string) {
    return {
      style: {
        backgroundColor: String(name).toLowerCase() == 'quang ' ? '#204d77' : stringToColor(name),
        height: '24px',
        width: '24px',
        fontSize: '12px',
        outline: 'rgba(255, 255, 255, 0.055) solid 1px',
      },
      children: `${name.split(' ')[0][0]?.toUpperCase()}`,
    };
  }

  const pageSocketRender = React.useMemo(() => {
    const members = pageSocket?.edit?.members || {};
    const mergedMembers = Object.values(members).reduce((carry, item) => {
      if (!carry[item.email]) {
        carry[item.email] = cloneDeep(item);
      } else if (carry[item.email].createdAt < item.createdAt) {
        carry[item.email].createdAt = item.createdAt;
      }

      return carry;
    }, {});

    return Object.values(mergedMembers)
      .sort((a, b) => b.createdAt - a.createdAt)
      .map((i, index, arr) => {
        return (
          <Tooltip
            key={i.email + i.createdAt}
            title={<TooltipRender {...i} classes={classes} />}
            classes={{ tooltip: classes.tooltipContainer }}
          >
            <div style={{ marginLeft: `-6px`, zIndex: arr.length - index }}>
              <Avatar {...stringAvatar(i.name)} />
            </div>
          </Tooltip>
        );
      });
  }, [pageSocket?.edit]);

  const handlePublish = () => {
    if (
      !wasUpdated ||
      (wasUpdated && confirm(`This update will override ${by}'s settings. Are you sure you want to proceed?`))
    ) {
      onPublish().then(() => {
        socket.emit('publishEditRoom', {
          pageId: currentPage.blockEid,
          email: aim.getUserSettings().profile?.userEmail,
        });
      });
    }
  };

  return (
    <div className={classes.container}>
      <TopBar>
        <Grid item>
          <Grid container alignItems="center">
            <Icon type="logo" />
          </Grid>
        </Grid>
        <Grid item>
          <ResponsiveBar
            onDeviceClick={(screenType, dimensions) => {
              console.info(screenType, dimensions);
            }}
          />
        </Grid>
        <Grid item className={classes.buttonContainer}>
          <Grid container>
            <div className={classes.editingContainer}>{pageSocketRender}</div>
            <IconButton aria-label="setting" size="small">
              <UndoIcon fontSize="small" />
            </IconButton>
            <IconButton aria-label="setting" size="small" style={{ marginLeft: 0 }}>
              <RedoIcon fontSize="small" />
            </IconButton>
            <Button size="small" variant="contained" color="primary" onClick={handlePublish}>
              Publish
            </Button>
            {wasUpdated ? (
              <Tooltip
                title={
                  <div className={classes.tooltip}>
                    Page was updated by {by} {moment(at).fromNow()}
                  </div>
                }
                placement={'bottom'}
                classes={{ tooltip: classes.tooltipContainer }}
              >
                <Box display={'flex'} alignItems={'center'} marginLeft={'4px'}>
                  <Icon type={'ic/mi:circle-information/#737373'} />{' '}
                </Box>
              </Tooltip>
            ) : null}
            {aim.isSuperAdmin() && (
              <Dropdown alignMenu="right" icon="threeDotsVertical" label="" className={classes.buttonItem}>
                <MenuList listMenu={dataMenu} />
              </Dropdown>
            )}
          </Grid>
        </Grid>
      </TopBar>
      <PopupJsonEditor
        onValueChange={(properties) => {
          updatePage({ ...currentPage, properties: properties });
          socket.emit('publishEditRoom', {
            pageId: currentPage.blockEid,
            email: aim.getUserSettings().profile?.userEmail,
          });
          setJoinedAt(new Date().getTime());
        }}
        open={openPageSettings}
        title={'Edit page setting'}
        value={currentPage.properties}
        onClose={() => setOpenPageSettings(false)}
      />
    </div>
  );
};
