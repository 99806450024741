import { aim, EIP_CONSTANT } from '@eip/next/lib/main';
import { request } from '../data-source/common';
import { get } from 'lodash';

const API = {
  GET_MARKETPLACE_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-marketplace-allocation',
  GET_COUNTRY_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-country-allocation',
  GET_STOREFRONT_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-storefront-allocation',
};

const mappingFields = {
  code: 'value',
  name: 'label',
  storefront_name: 'label',
  storefront_id: 'value',
};

const transformData = (data) => {
  return data.map((el) => {
    const mappedData = Object.entries(mappingFields).reduce((carry, [key, mappedKey]) => {
      return {
        ...carry,
        [mappedKey]: el[key],
      };
    }, {});

    return {
      ...el,
      ...mappedData,
    };
  });
};

const getMarketplace = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.get(API.GET_MARKETPLACE_URL);
      resolve({
        data: transformData(response.data || []),
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getCountry = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.get(API.GET_COUNTRY_URL);
      resolve({
        data: transformData(response.data || []),
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getStorefront = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_STOREFRONT_URL, { newValues: {} });
      const transformedData = transformData(response.data || []).map((dt) => {
        const payload = {
          country: get(dt, ['country'], {}),
          channel: {
            ...get(dt, ['channel'], {}),
            id: get(dt, ['channel', 'code'], {}),
          },
        };
        return {
          ...dt,
          value: String(dt.value),
          payload,
        };
      });
      resolve({
        data: transformedData,
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getAdType = async () => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

const getAdTool = async () => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

const getStatus = async (arg) => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

export default {
  getMarketplace,
  getCountry,
  getStorefront,
  getAdType,
  getAdTool,
  getStatus,
};
