import { useLog } from '@eip/next/lib/log';
import { enhanceNested } from '@eip/next/lib/react-grid-layout/nested';
import ResponsiveLayoutGrid from '@eip/next/lib/react-grid-layout/responsive';
import { makeStyles } from '@material-ui/core';
import { debounce, get, mapValues, omit } from 'lodash';
import { set } from 'lodash/fp';
import React, { useState } from 'react';
// import { Responsive as ResponsiveLayoutGrid } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import { useDispatch, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { GRID_MARGIN, SCREEN_COLUMNS, SCREEN_SIZE } from '../../constant';
import { EIPContext, FilterContext, FilterType } from './context';
import DashboardItem from './dashboard-item';
import { actions } from './dashboard-redux';
import GridProvider from './grid-adapter';
import { NodeFocus } from './node-focus';
import { NodeEditContext } from '@eip/next/lib/main';
import { NodePanel } from './panel/block-panel';

import { DashboardBreakpoints, DashboardState, NodeData, NodeLayout } from './type';
import { chartPrePackagedConfig, CHART_TYPE } from './node/chartlibs';

import { ChartTemplatePanel } from '@ep/insight-ui/system/panel/chart-template';
import { BlockFactory } from './block-factory';

const log = useLog('dbf:grid:editor');

const emptyFn = (...args) => console.info('called empty fn', ...args);
const ReactGridLayout = GridProvider(enhanceNested(ResponsiveLayoutGrid));
const DragField = ReactGridLayout;

type DashboardScreenLayout = NodeLayout;

type DashboardResponsiveLayoutType = {
  [key in DashboardBreakpoints]: DashboardScreenLayout[];
};

type DashboardProps = {
  layouts: DashboardResponsiveLayoutType;
  nodes: NodeData[];
  layoutColumns: Dashboard['layoutColumns'];
  filterComponent?: React.Component;
};

export function DashboardFrameEditMode({ layouts, nodes, filterComponent }: DashboardProps) {
  const [gridMargin, setGridMargin] = useState(GRID_MARGIN);
  const [editMode, setEditMode] = useState<{
    isExpanded: boolean;
    nodeId: string | null;
    nodeData: NodeData | null;
  }>({ isExpanded: false, nodeId: null, nodeData: null });

  const {
    mode: dbMode,
    gridLayouts,
    layoutColumns,
    breakpoint,
  } = useSelector((state: DashboardState) => {
    return {
      mode: state.mode,
      nodes: state.nodes,
      gridLayouts: state.layouts,
      layoutColumns: state.layoutColumns,
      breakpoint: state.breakpoint,
    };
  });
  const dispatch = useDispatch();

  const statusRef = React.useRef({
    isChangeBreakpoint: false,
  });

  const classes = useStyle();
  const context = React.useContext(EIPContext);

  // FIXME: GlobalFilter will be updated from chartlib "GLobal Filter"
  const [globalFilter, setGlobalFilter] = React.useState<FilterType>({
    channels: [],
    tools: [],
    countries: [],
    shops: [],
  });

  const [droppingTemplate, setDroppingTemplate] = React.useState(null);

  const containerRef = React.useRef();

  const rLayouts = React.useMemo(
    () => mapValues(layouts, (layout: any[]) => layout.map((i: any) => ({ i: i.id, ...i.layout }))),
    [layouts],
  );

  React.useEffect(() => {
    const gridColumns = layoutColumns[breakpoint];
    const newMargin = calcGridMargin(gridColumns);
    if (gridMargin !== newMargin) {
      setGridMargin(newMargin);
    }
    context.onGridChange(gridLayouts, layoutColumns);
  }, [layoutColumns]);

  // React.useEffect(() => {
  //   setIsDraggable(dbMode.type !== 'connect');
  // }, [dbMode.type]);

  React.useEffect(() => {
    log('effects.... update nodes', nodes);
    context.onUpdateNodes(nodes);
  }, [nodes]);

  const handleAddNewNode = ({ x, y, w, h }: { x: number; y: number; w: number; h: number }) => {
    const newNode = context.onAddNewNode(x, y, w, h);
    if (newNode) {
      dispatch(actions.addNewNode({ node: newNode }));
    }
  };

  const handleRemoveNode = (id) => {
    dispatch(actions.removeNode({ nodeId: id }));
    context.onRemoveNode(id);
  };

  const onBreakpointChange = (bp, cols) => {
    statusRef.current.isChangeBreakpoint = true;
    dispatch(actions.updateBreakpoint({ breakpoint: bp }));
    context.onBreakpointChange(bp, cols);
  };

  const handleLayoutChange = debounce((newLayout: any, previousLayout: any) => {
    const nuLayouts = newLayout.map((i: any) => ({
      id: i.i,
      layout: omit(i, ['i']),
    }));
    log('onlayoutchange', nuLayouts);
    dispatch(actions.updateLayout({ layout: nuLayouts }));
    context.onLayoutChange(
      nuLayouts,
      mapValues(previousLayout, (layout) =>
        layout.map((i: any) => ({
          id: i.i,
          layout: omit(i, ['i']),
        })),
      ),
    );
  }, 200);

  const handleNodeRequestUpdate = (nodeId, payload) => {
    log('editmode', { nodeId, payload });
    if (get(payload, 'formControl.isExpanded', null) === false) {
      setEditMode((state) => ({ ...state, isExpanded: false }));
    } else {
      setEditMode({
        nodeData: payload,
        isExpanded: true,
        nodeId: nodeId,
      });
      // context.onNodeSubmitUpdate(nodeId, payload);
    }
  };

  const handleNodeSubmit = (nodeId, payload) => {
    log('handleNodeSubmit', { nodeId, payload });
    dispatch(actions.updateNode({ nodeId, data: payload }));
    context.onNodeSubmitUpdate(nodeId, payload);
  };

  const calcGridMargin = (value) => {
    return Math.max(
      Math.round((GRID_MARGIN * (SCREEN_COLUMNS[breakpoint].max - value)) / SCREEN_COLUMNS[breakpoint].max),
      4,
    );
  };

  const handleDuplicateNode = (nodeId) => {
    dispatch(actions.duplicateNode({ nodeId }));
  };

  const handleUpdateGlobalFilter = (filter: FilterType) => {
    setGlobalFilter(filter);
  };

  const handleDrop = (layout, layoutItem, event: React.DragEvent) => {
    const newNode = context.onAddNewNode(layoutItem.x, layoutItem.y, layoutItem.w, layoutItem.h);
    if (newNode) {
      dispatch(actions.addNewNode({ node: newNode }));
      handleNodeSubmit(newNode.id, {
        id: newNode.id,
        chartLibId: droppingTemplate.chartId,
        customAttributes: droppingTemplate.advancedProperties,
      });
    }
  };

  return (
    <div className={nodes.length === 0 ? classes.dashboardBlank : classes.dashboard} ref={containerRef}>
      <EIPContext.Provider
        value={{
          ...context,
          onRemoveNode: handleRemoveNode,
          onNodeSubmitUpdate: handleNodeSubmit,
          onNodeRequestUpdate: handleNodeRequestUpdate,
          onDuplicateNode: handleDuplicateNode,
          onToggleNodeDraggable: emptyFn,
        }}
      >
        <NodeEditContext.Provider
          value={{
            isEditMode: true,
            onTextChange: debounce((nodeData: NodeData, contentId, contentValue) => {
              const data = set(`customAttributes.customText.${contentId}`, contentValue)(nodeData);
              handleNodeSubmit(data.id, data);
            }, 1000),
            onUpdateCustomAttributes: (nodeData, attributes) => {
              const customAttributes = Object.assign({}, get(nodeData, 'customAttributes', {}), attributes);
              handleNodeSubmit(nodeData.id, {
                ...nodeData,
                customAttributes,
              });
            },
          }}
        >
          <DragField
            isDraggable={true}
            isResizable={true}
            margin={gridMargin}
            containerPadding={[0, 0]}
            onAddNewNode={handleAddNewNode}
            onLayoutChange={handleLayoutChange}
            onBreakpointChange={onBreakpointChange}
            breakpoints={{
              lg: SCREEN_SIZE.lg,
              md: SCREEN_SIZE.md,
              sm: SCREEN_SIZE.sm,
              xs: SCREEN_SIZE.xs,
              xxs: SCREEN_SIZE.xxs,
            }}
            cols={layoutColumns}
            isDroppable={true}
            // droppingItem={droppingItem}
            onDrop={handleDrop}
            autoSize={true}
            isBounded={false}
            isDragging={true}
            layouts={rLayouts}
            verticalCompact={true}
            draggableHandle={'.drag-enabled'}
            className={'dashboard-grid-layout'}
            onDropDragOver={(e: React.DragEvent) => {
              if (droppingTemplate && droppingTemplate.layout) {
                return droppingTemplate.layout;
              }
              return {
                w: 4,
                h: 2,
              };
            }}
          >
            {nodes
              .filter((i) => !i.containerId)
              .map((i: NodeData) => (
                <div key={i.id} className={'ep-dashboard-item'} data-node-id={i.id} style={{ zIndex: 1 }}>
                  <DashboardItem key={i.id} nodeData={i}></DashboardItem>
                </div>
              ))}
          </DragField>
          <BlockFactory blocks={nodes.filter((i) => !i.containerId)} />
          <NodePanel
            componentFilter={filterComponent}
            isExpanded={true}
            nodeId={dbMode.activeNode}
            onCancel={() =>
              setEditMode((state) => ({
                ...state,
                isExpanded: false,
              }))
            }
          />
          <ChartTemplatePanel
            isExpanded={true}
            onDragItemStart={({ chartId, layout, advancedProperties }) => {
              dispatch(
                actions.setEditorMode({
                  mode: 'dragging_add_node',
                }),
              );
              setDroppingTemplate({ chartId, layout, advancedProperties });
            }}
            onDragItemEnd={() => {
              setDroppingTemplate(null);
              actions.setEditorMode({ mode: null });
            }}
            chartSingleConfig={CHART_TYPE}
            chartGroupConfig={(chartTypeId) => chartPrePackagedConfig[chartTypeId]}
          />
        </NodeEditContext.Provider>
        <NodeFocus />
      </EIPContext.Provider>
    </div>
  );
}

const useStyle = makeStyles(() => {
  return {
    dashboardBlank: {
      position: 'relative',
      minHeight: '100vh',
      '& > div': {
        position: 'relative',
        zIndex: 1,
      },
      '&:after': {
        top: 0,
        left: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        content: '""',
        fontSize: '5em',
        color: '#eee',
      },
    },
    dashboard: {
      width: 'calc(100% - 240px)',
    },
    banner: {
      position: 'fixed',
      top: 56,
      zIndex: 1300,
    },
  };
});
