import * as React from 'react';
import { useLocation } from 'react-router';

import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import { EIP_CONSTANT, aim } from '@eip/next/lib/main';

type Template = {
  nodeId: string;
  label: string;
};

export const usePageEdit = () => {
  const [templates, setTemplates] = React.useState<Template[]>([]);
  const { pathname } = useLocation();

  const updateTemplates = (newTemplates) => {
    if (aim.isSuperAdmin()) {
      request
        .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv', {
          k: 'epsilo-template',
          v: newTemplates,
          version: Date.now(),
        })
        .then((res) => {
          if (res.success) {
            setTemplates(res.data.v);
          }
        });
    }
  };

  const getTemplate = (nodeId) => {
    return templates.find((template) => template.nodeId === nodeId);
  };

  const deleteTemplate = (nodeId: string) => {
    const newTemplates = templates.filter((template) => template.nodeId !== nodeId);

    updateTemplates(newTemplates);
  };

  const saveTemplate = (template: Template) => {
    if (template.label === '') {
      deleteTemplate(template.nodeId);
    } else {
      const isTemplateExisted = templates.some(({ nodeId }) => nodeId === template.nodeId);
      const newTemplates = !!isTemplateExisted
        ? templates.map((el) => {
            if (el.nodeId === template.nodeId) return template;
            return el;
          })
        : templates.concat(template);
      updateTemplates(newTemplates);
    }
  };

  React.useEffect(() => {
    if (/edit$/.test(pathname)) {
      console.info('aaaa', pathname);
      request
        .get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/epsilo-template')
        .then((res) => setTemplates(res.data.v));
    }
  }, [pathname]);

  return {
    templates,
    getTemplate,
    deleteTemplate,
    saveTemplate,
  };
};
