import { formatCurrency } from '@ep/insight-ui/lib/number';
import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import AreaChartResponsive from './area-chart';
import { funcConfigs } from './hooks';
import { colorsChartLegend, defaultOptions, HIDDEN_LABEL_TEXT, tool } from '../helper';
import { IDataChart, IOption } from '../type';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { TSize } from '../chart-size';
type Props = {
  data: IDataChart;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
};
const AreaChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
}: Props) => {
  const [dataChart, setDataChart] = React.useState<{
    dataList: any;
    optionList: IOption;
  }>({ dataList: null, optionList: null });
  const { externalTooltipHandler } = funcConfigs();
  React.useEffect(() => {
    const now = moment();
    const datasets = data.rows.map((item, index) => {
      const result = {
        label: item.label,
        data: item.data.map((it) => it.value),
        backgroundColor: colorsChartLegend[index].fill,
        borderColor: colorsChartLegend[index].stroke,
        pointBackgroundColor: colorsChartLegend[index].stroke,
        pointBorderColor: colorsChartLegend[index].pointBorderColor,
        fill: true,
        lineTension: 0.4,
        // segment: {
        //   borderColor: (ctx) => {
        //     const date = _.get(item.data, [_.get(ctx, 'p0.$context.dataIndex'), 'date']);
        //     if (date && moment(date).isAfter(now)) {
        //       return colorsChartLegend[index].fill;
        //     }
        //     return colorsChartLegend[index].stroke;
        //   },
        //   backgroundColor: (ctx) => {
        //     const date = _.get(item.data, [_.get(ctx, 'p0.$context.dataIndex'), 'date']);
        //     if (date && moment(date).isAfter(now)) {
        //       return colorsChartLegend[index].segment;
        //     }
        //     return colorsChartLegend[index].fill;
        //   },
        // },
      };
      return result;
    });
    const dataList = {
      labels: data.headers,
      datasets,
    };
    //Display option chart
    const { STACKED_AREA: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    _.set(optionList, 'plugins.tooltip.callbacks.label', tool.areaCallbacks.label({ currency }));
    _.set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);

    if (maxValues) {
      _.set(optionList, 'scales.yAxes.suggestedMax', maxValues);
    }
    setDataChart({ dataList, optionList: { ...optionList } });
  }, [data]);

  return (
    <AreaChartResponsive
      stateChart={stateChart}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
      keyChart={keyChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
    />
  );
};

export default AreaChart;
