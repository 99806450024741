import * as React from 'react';
import ExpansionTable from '../../elements/table/expansion-table';
import { Bar } from 'react-chartjs-2';
import Typography from '@ep/insight-ui/elements/text-style/Typography';

export default {
  title: 'Chartlib / Discrete chart',
};

const data = {
  labels: [0, 1000, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000],
  datasets: [
    {
      type: 'line',
      label: 'Budget',
      data: [
        { x: 9000, y: 0 },
        { x: 9000, y: 90000 },
      ],
      fill: false,
      borderColor: '#D4290D',
      backgroundColor: '#D4290D',
      borderDash: [5, 5],
    },
    {
      type: 'line',
      label: 'Current timeline',
      data: [0, 2000, 3000, 4000, 7000, 11000, 13000],
      fill: false,
      borderColor: '#204D77',
      backgroundColor: '#204D77',
    },
    {
      type: 'line',
      label: 'Previous timeline',
      data: [0, 500, 1200, 2400, 5000, 7200, 7000, 11000, 30000, 50000, 70000, 72000, 76600],
      fill: false,
      borderColor: '#8FA6BB',
      backgroundColor: '#8FA6BB',
    },
    {
      type: 'line',
      label: '0-1 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => index * (20000 / 16)),
      fill: true,
      borderColor: 'rgba(241, 170, 159, 1)',
      backgroundColor: 'rgba(241, 170, 159, 1)',
      borderWidth: 0,
    },
    {
      type: 'line',
      label: '1-3 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => index * (50000 / 16)),
      fill: true,
      borderColor: 'rgba(248, 190, 159, 1)',
      backgroundColor: 'rgba(248, 190, 159, 1)',
      borderWidth: 0,
    },
    {
      type: 'line',
      label: '3-5 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => index * (80000 / 16)),
      fill: true,
      borderColor: 'rgba(247, 214, 167, 1)',
      backgroundColor: 'rgba(247, 214, 167, 1)',
      borderWidth: 0,
    },
    {
      type: 'line',
      label: '5-10 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => (index < 9 ? index * (90000 / 9) : 90000)),
      fill: true,
      borderColor: 'rgba(255, 228, 85, 0.5)',
      backgroundColor: 'rgba(255, 228, 85, 0.5)',
      borderWidth: 0,
    },
    {
      type: 'line',
      label: '10-20 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => (index < 4 ? index * (90000 / 4) : 90000)),
      fill: true,
      borderColor: 'rgba(182, 215, 50, 0.5)',
      backgroundColor: 'rgba(182, 215, 50, 0.5)',
      borderWidth: 0,
    },
    {
      type: 'line',
      label: '>20 ROAS',
      data: Array.from({ length: 16 }).map((_, index) => 90000),
      fill: true,
      borderColor: 'rgba(157, 221, 201, 1)',
      backgroundColor: 'rgba(157, 221, 201, 1)',
      borderWidth: 0,
    },
  ],
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
      text: 'About 52%',
      font: {
        size: 30,
      },
      position: 'top',
    },
    tooltip: { enabled: false },
  },
};
export const DiscreteChart = () => {
  return (
    <>
      <ExpansionTable label={'Market insight'} defaultExpanded={true}>
        <Typography variant="h2">Heatmap of generated revenue</Typography>
        <div style={{ height: '500px' }}>
          <Bar data={data} options={options} />
        </div>
      </ExpansionTable>
    </>
  );
};
