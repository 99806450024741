import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { IconType } from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        height: '32px',
      },
    },
    width: '100%',
  },
}));
const defaultOptions: OptionSelectType[] = [
  {
    label: 'Enable',
    value: 1,
  },
  {
    label: 'Disable',
    value: 0,
  },
];
type IValueDropdownFormat = {
  value: { id: string | number; text: string } | null;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
};
export type IDropdownForm = {
  title?: string;
  options?: OptionSelectType[];
  value?: string;
  loading?: boolean;
  onClose?: () => void;
  onSubmit?: (value: string) => Promise<any> | void;
  submitLabel?: string;
};
export const DropdownForm = ({
  title = '',
  options = defaultOptions,
  value = '',
  loading = false,
  onClose = () => undefined,
  onSubmit = () => undefined,
  submitLabel = 'Apply now',
}: IDropdownForm) => {
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = React.useState<string>(value);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (value != currentStatus) setCurrentStatus(value);
  }, [value]);

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentStatus(e.target.value);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit(currentStatus);
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      onClose();
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_${titleActionClass}`}
    >
      <Box sx={{ display: 'flex' }}>
        <Box>
          <HeaderList
            title={title}
            variant="h4"
            className={classes.header}
            iconHead={
              loading ? (
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
      <div className={classes.dialog}>
        <SelectForm options={options} value={currentStatus} className={classes.select} onChange={handleChangeStatus} />
        <GroupButtonSubmit
          onCancel={onClose}
          onSubmit={handleSubmit}
          submitLabel={submitLabel}
          isSubmitting={isSubmitting}
        />
      </div>
    </div>
  );
};

type Props = {
  data: {
    value: IValueDropdownFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  onLoad: (param: { toolCodeIds: string[]; storefrontIds: string[] }) => Promise<void>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  dimension?: string;
  field?: string;
};
const DropdownEditor = (props: Props) => {
  const { data, title = '', onSubmit, setAnchorEl, dimension, field } = props;
  const [loading, setLoading] = React.useState(false);
  const backbone = React.useContext(TableBackboneContext);

  const defaultValue = React.useMemo(() => {
    const va = get(data, 'value.value', null);
    return va;
  }, [data, dimension, field]);

  const [options, setOptions] = React.useState<OptionSelectType[]>([]);

  React.useEffect(() => {
    backbone
      .addon('dimension.definition')(data, dimension, field, setLoading)
      .then((response) => {
        setOptions(response || defaultOptions);
      });
  }, []);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async (value) => {
    const response = await onSubmit({
      value: {
        label: undefined,
        status: value,
      },
      row: data.node.data,
    });
    return response;
  };

  return (
    <DropdownForm
      title={title}
      loading={loading}
      options={options}
      value={defaultValue}
      onClose={handleClosed}
      onSubmit={handleSubmit}
    />
  );
};

export default DropdownEditor;
