/* eslint-disable react/prop-types */
import { formatCurrency, formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import numeral from 'numeral';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { ResourceMetricInterface } from '@ep/insight-ui/system/backbone/data-source/common';

import { enhanceCellAction } from '../table-helper';
import Icon from '@ep/insight-ui/icons/Icon';

type StyleProps = {
  isAuto: boolean;
};
const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    flexDirection: 'column',
    height: '100%',
  },
  display: {
    display: 'none',
  },
  styleText: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
  }),
  subtext: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#8C98A4',
  }),
  autoSubtext: ({ isAuto }: StyleProps) => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
    textAlign: 'left',
  }),
  hashtag: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#006EC6',
  }),
  numberAlign: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  rowGroup: {
    height: '100%',
    width: '100%',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
  },
}));

export interface IValueCurrencyFormat {
  value: number | string;
  suggestedValue?: number;
  suggestedMax?: number;
  suggestedMin?: number;
  currency: string;
  defaultCurrency: string;
  typeDisplay?: 'currency' | 'datetime' | 'text-number';
  formatDateDisplay?: string;
  hashtag?: string;
  subtext?: string;
}

const CurrencyFormat = (props: IPropsFormat<IValueCurrencyFormat>) => {
  const cellAction = _.get(props, ['cellAction'], []);
  const node = _.get(props, ['node'], []);
  const value = _.get(props, ['value'], []);
  const valueNumber = _.get(props, ['value', 'value'], '');
  const typeDisplay = _.get(props, ['value', 'typeDisplay'], 'currency');
  const currency = _.get(props, ['value', 'currency'], '');
  const formatDateDisplay = _.get(props, ['value', 'formatDateDisplay'], 'MMM DD YYYY - HH:MM A');
  const subtext = _.get(props, ['value', 'subtext'], '');
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const isAuto = _.get(props, ['value', 'isAuto'], false);
  const suggestedValue = _.get(props, ['value', 'suggestedValue'], null);
  const suggestedMax = _.get(props, ['value', 'suggestedMax'], null);
  const suggestedMin = _.get(props, ['value', 'suggestedMin'], null);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const dataType = _.get(props, 'dataType', '');
  const classes = useStyles({ isAuto });
  const isAutomatedAds = _.get(props, ['value', 'isAutomatedAds'], '');
  const isAutomatedCampaign = _.get(props, ['value', 'isAutomatedCampaign'], '');
  const marketplace = _.get(props, ['value', 'marketplace'], '');
  const marketplaceCode = _.get(props, ['value', 'marketplaceCode'], '');
  const isEmpty = _.get(props, ['value', 'isEmpty'], false);
  const staticCurrency = _.get(props, ['data', 'staticCurrency'], '');

  const edot = _.get(props, ['value', 'edot'], []);

  const resourceMetric: ResourceMetricInterface[] = _.get(props, ['data', 'resourceMetric'], []);

  const isAutoBid = (isAutomatedCampaign || isAutomatedAds) && (valueNumber === null || marketplaceCode === 'LAZADA');

  const backbone = React.useContext(TableBackboneContext);

  const isSubtextUpdatedAt = React.useMemo(() => {
    const mappings = backbone.getConfig('mapping');
    const colId = _.get(props, 'colDef.colId', '');
    const subtextGetter = _.get(mappings, `['${colId.replace(/\_cohort$/, '')}'].valueGetter.subtext`, '');

    return subtextGetter.includes('updated_at');
  }, [backbone]);

  const valueField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'value'], '');
  const valueSyncStatus = _.get(props, ['data', 'data_status', valueField, 'status'], null);
  const valueSyncUpdatedAt = _.get(props, ['data', 'data_status', valueField, 'updated_at'], null);
  const valueSyncErrorMessage = _.get(props, ['data', 'data_status', valueField, 'error_message'], '');

  const finalSubtext = React.useMemo(() => {
    if (valueSyncStatus == 2) {
      return (
        <Tooltip title={valueSyncErrorMessage} classes={{ tooltip: classes.tooltip }} placement="bottom">
          <Box className={classes.syncStatusText}>
            <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
            <span>
              Sync failed at:{' '}
              {moment(valueSyncUpdatedAt).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')}
            </span>
          </Box>
        </Tooltip>
      );
    }
    if (!isSubtextUpdatedAt) return subtext;
    return subtext ? subtext : '-';
  }, [subtext, isSubtextUpdatedAt]);

  const finalCurrency = React.useMemo(() => {
    if (currency) return currency;
    if (staticCurrency) return staticCurrency;
    const mappings = backbone.getConfig('mapping');
    const colId = _.get(props, 'colDef.colId', '');
    const valueGetter = _.get(mappings, `['${colId.replace(/\_cohort$/, '')}'].valueGetter.value`, '');
    const metric = resourceMetric.find((el) => el.value === valueGetter);
    const metricCurrency = _.get(metric, 'prefix_value', '');
    return metricCurrency === '$' ? _.get(props, 'data.currency', '') : metricCurrency;
  }, []);

  const getSuggestedValue = () => {
    if (_.isArray(valueNumber) && valueNumber.length > 0) {
      if (valueNumber.length == 1) {
        return formatCurrencyNumber(_.get(valueNumber, [0], 0));
      }
      return `${_.min(valueNumber)} ➞ ${_.max(valueNumber)}`;
    }

    return '';
  };

  const getCurrency = (number: number, currency = '', dataType = '') => {
    return formatCurrencyNumber(number, currency);
  };
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={isAutoBid ? [] : enhanceCellAction(cellAction, edot, props.field, backbone)}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={isAuto}
    >
      {!isEmpty && (
        <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
          <Grid item xs={12} className={classes.numberAlign}>
            <span className={classes.hashtag}>{hashtag}</span>
          </Grid>
          <Grid item xs={12} className={`${classes.numberAlign} ${isAutoBid && classes.autoSubtext}`}>
            {isAutoBid ? (
              <div>Auto bid</div>
            ) : suggestedValue == null || suggestedMax == null || suggestedMin == null ? (
              <div className={classes.styleText}>
                {typeDisplay == 'currency' && `${getCurrency(valueNumber, finalCurrency, dataType)} ${finalCurrency}`}
                {typeDisplay == 'datetime' && `${moment(valueNumber).format(formatDateDisplay)}`}
                {typeDisplay == 'text-number' && valueNumber}
                {typeDisplay == 'delta' && (
                  <span style={{ color: valueNumber < 0 ? '#D4290D' : '#006EC6' }}>{valueNumber.toFixed(2)}%</span>
                )}
              </div>
            ) : (
              <div className={classes.styleText}>
                {!_.isArray(valueNumber) ? valueNumber : getSuggestedValue()} {finalCurrency}
              </div>
            )}
          </Grid>
          <Grid item xs={12} className={classes.numberAlign}>
            <span className={`${classes.subtext} ${isAutoBid && classes.autoSubtext}`}>
              {isAutoBid ? `by ${marketplace}` : finalSubtext}
            </span>
          </Grid>
        </Grid>
      )}
    </WrapperFormat>
  );
};
export default CurrencyFormat;
