import React from 'react';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { formatCurrency } from '../../../utils/utils';
import iconLazada from './channel-lazada.svg';
import iconShopee from './channel-shopee.svg';
import moment from 'moment';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    textAlign: 'center',
    margin: '25px 0',
    fontWeight: 600,
  },
  title: {
    fontSize: 18,
    color: '#B5BEC9',
    display: 'flex',
    alignItems: 'center',
    '& img': {
      marginRight: 7,
    },
    '&.right': {
      justifyContent: 'flex-end',
      '& img': {
        marginRight: 0,
        marginLeft: 7,
      },
    },
  },
  left: {
    textAlign: 'left',
  },
  right: {
    textAlign: 'right',
  },
  number: {
    fontSize: 32,
  },
  numberTarget: {
    marginTop: 8,
    fontSize: 20,
    fontWeight: 500,
    color: '#65758B',
  },
  total: {
    color: '#FFD600',
    fontSize: 64,
    textAlign: 'center',
    lineHeight: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  totalToday: {
    marginTop: '.7em',
    marginBottom: '.3em',
    color: '#e0e0e0',
    display: 'flex',
    justifyContent: 'center',
  },
  channelTotalToday: {
    fontSize: 18,
    color: '#e0e0e0',
    marginTop: 16,
    marginBottom: 8,
  },
  totalTitle: {
    color: '#88939E',
    fontSize: 24,
  },

  '@global': {
    '.odometer.odometer-auto-theme': {
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;",
      // fontFamily: '"Courier New", Courier, monospace',
    },
  },
});

export const OverviewInfo = (props: any) => {
  const classes = useStyles();

  const {
    totalGMV,
    shopeeGMV,
    lazadaGMV,
    currency: pCurrency,
    shopeeGMVTarget,
    lazadaGMVTarget,
    totalGMVTarget,
    today,
    campaignName,
  } = props;

  const currency = '';

  const currentMonth = moment().utcOffset(8).format('M');

  return (
    <div className={classes.root}>
      <Grid container direction="row" justify="center" alignItems="center" spacing={3}>
        <Grid item xs={3} className={classes.left}>
          <div className={classes.title}>
            <img src={iconShopee} />
            Shopee
          </div>
          <div className={`${classes.number} ${classes.channelTotalToday}`}>
            Today {today.shopeeGMV > 0 ? formatCurrency(today.shopeeGMV) : '-'} {currency}
          </div>
          <div className={classes.number}>
            {shopeeGMV > 0 ? formatCurrency(shopeeGMV) : '-'} {currency}
          </div>
          {shopeeGMVTarget > 0 ? (
            <div className={classes.numberTarget}>
              <span>{Number((100 * shopeeGMV) / shopeeGMVTarget).toFixed(2)}% of </span>
              {formatCurrency(shopeeGMVTarget)} {currency}
            </div>
          ) : (
            <div className={classes.numberTarget}>-</div>
          )}
        </Grid>
        <Grid item xs={6}>
          <div className={classes.totalTitle}>{campaignName}</div>
          <div className={`${classes.number} ${classes.totalToday}`}>
            <span>Today&nbsp;&nbsp;</span>
            <span>{formatCurrency(today.totalGMV)}</span>
            <span>&nbsp;&nbsp;</span>
          </div>
          <div className={`${classes.number} ${classes.total}`}>
            <Odometer value={totalGMV} format={'(,ddd)'} />
            <div style={{ marginLeft: '3px' }}>{currency}</div>
          </div>
          {totalGMVTarget > 0 ? (
            <div className={classes.numberTarget}>
              <span>{Number((100 * totalGMV) / totalGMVTarget).toFixed(2)}% of </span>
              {formatCurrency(totalGMVTarget)} {currency}
            </div>
          ) : (
            <div className={classes.numberTarget}>-</div>
          )}
        </Grid>
        <Grid item xs={3} className={classes.right}>
          <div className={[classes.title, 'right'].join(' ')}>
            Lazada
            <img src={iconLazada} />
          </div>
          <div className={`${classes.number} ${classes.channelTotalToday}`}>
            Today {formatCurrency(today.lazadaGMV)} {currency}
          </div>
          <div className={classes.number}>
            {formatCurrency(lazadaGMV)} {currency}
          </div>
          {lazadaGMVTarget > 0 ? (
            <div className={classes.numberTarget}>
              <span>{Number((100 * lazadaGMV) / lazadaGMVTarget).toFixed(2)}% of </span>
              {formatCurrency(lazadaGMVTarget)} {currency}
            </div>
          ) : (
            <div className={classes.numberTarget}>-</div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
