import { formatCurrency } from '@ep/insight-ui/lib/number';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Stacked } from '../../../chartlib/stacked-chart/stacked-chart';
import { IData, IOption } from '../../../chartlib/type';
import { funcConfigs } from '../../../chartlib/stacked-chart/legend/legend';
import { colorsChartLegend } from '../../../chartlib/helper';

const svg = `<svg width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.99312 11.9966L5.99312 4.42024L7.2818 5.70961C7.46991 5.89783 7.72505 6.00356 7.99107 6.00356C8.2571 6.00356 8.51224 5.89783 8.70035 5.70961C8.88846 5.5214 8.99414 5.26613 8.99414 4.99996C8.99414 4.73378 8.88846 4.47851 8.70035 4.2903L5.70341 1.29175C5.61055 1.19807 5.50006 1.12371 5.37832 1.07296C5.25659 1.02222 5.12602 0.996094 4.99414 0.996094C4.86226 0.996094 4.73169 1.02222 4.60996 1.07296C4.48822 1.12371 4.37773 1.19807 4.28487 1.29175L1.28793 4.2903C1.19479 4.38349 1.1209 4.49413 1.07049 4.61589C1.02008 4.73766 0.99414 4.86816 0.99414 4.99996C0.99414 5.13175 1.02008 5.26226 1.07049 5.38402C1.1209 5.50578 1.19479 5.61642 1.28793 5.70961C1.38107 5.80281 1.49165 5.87673 1.61335 5.92717C1.73505 5.9776 1.86548 6.00356 1.99721 6.00356C2.12893 6.00356 2.25936 5.9776 2.38106 5.92717C2.50276 5.87673 2.61334 5.80281 2.70648 5.70961L3.99516 4.42024L3.99516 11.9966C3.99516 12.2617 4.10041 12.5159 4.28776 12.7033C4.4751 12.8908 4.72919 12.9961 4.99414 12.9961C5.25909 12.9961 5.51318 12.8908 5.70052 12.7033C5.88787 12.5159 5.99312 12.2617 5.99312 11.9966Z" fill="#0BA373"/>
</svg>`;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const LegendChart = () => {
  const [dataList, setDataList] = useState<IData>();
  const [optionList, setOptionList] = useState<IOption>();
  const [width, setWidth] = useState(window.innerWidth);
  const ref = React.createRef();
  const { handleDate, handleRandomdata, externalTooltipHandler, htmlLegendPlugin } = funcConfigs({
    percent: '15%',
    icon: svg,
    width,
  });

  //Update width
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(window.innerWidth);
    });

    // Set data legend chart
    setDataList({
      labels: handleDate(),
      datasets: [
        {
          label: 'Vietnam',
          backgroundColor: colorsChartLegend[0].stroke,
          hoverBackgroundColor: colorsChartLegend[0].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'Thailand',
          backgroundColor: colorsChartLegend[1].stroke,
          hoverBackgroundColor: colorsChartLegend[1].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'Philippines',
          backgroundColor: colorsChartLegend[2].stroke,
          hoverBackgroundColor: colorsChartLegend[2].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'Indonesia',
          backgroundColor: colorsChartLegend[3].stroke,
          hoverBackgroundColor: colorsChartLegend[3].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'Singapore',
          backgroundColor: colorsChartLegend[4].stroke,
          hoverBackgroundColor: colorsChartLegend[4].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'Malaysia',
          backgroundColor: colorsChartLegend[5].stroke,
          hoverBackgroundColor: colorsChartLegend[5].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
        },
        {
          label: 'USA',
          backgroundColor: colorsChartLegend[6].stroke,
          hoverBackgroundColor: colorsChartLegend[6].hover,
          data: handleRandomdata(),
          stack: '1',
          active: 'active',
          borderRadius: {
            topLeft: 3,
            topRight: 3,
          },
        },
      ],
    });

    //Display option chart
    setOptionList({
      responsive: true,
      maintainAspectRatio: false,
      datasets: {
        bar: {
          barThickness: 14,
        },
      },
      scales: {
        yAxes: {
          suggestedMin: 0,
          suggestedMax: 5000,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
            callback: (value) => {
              return value;
            },
          },
        },
        xAxes: {
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
            callback: function (value, index) {
              if (window.innerWidth <= 767) return this.getLabelForValue(value);
              return index % 2 === 0 ? this.getLabelForValue(value) : '';
            },
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          mode: 'nearest',
          callbacks: {
            //Custom show currency chart
            label: (context) => {
              let label = context.dataset.label || '';

              if (context.parsed.y !== null) {
                label += ` đ ${formatCurrency(context.parsed.y, 'VND')}` + ` (${100 / 10}%)`;
              }
              return label;
            },
          },
          external: externalTooltipHandler,
        },
      },
    });
  }, [width]);

  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      for (const entry of entries) {
        window.requestAnimationFrame(() => {
          setWidth(entry.contentRect.width);
        });
      }
    });
    if (ref.current) {
      ro.observe(ref.current?.canvas);
    }

    return () => {
      ro.disconnect();
    };
  }, [ref]);

  return (
    <>{dataList && <Stacked data={dataList} options={optionList} plugins={[htmlLegendPlugin]} refElement={ref} />}</>
  );
};

export default LegendChart;
