import { EIP_CONSTANT } from '@eip/next/lib/main';

export const TREEMAP_SETTING_CONFIG = {
  title: 'Share of Shelf analysis',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getShareOfSearchData',
  },
  tableType: 'treemapTable',
  disabledComponents: {
    dimension: ['keyword', 'product', 'storefront'],
  },
  system: {
    hiddenComponents: ['majorButton', 'search'],
  },
  isTreemapShow: true,
  keyword: 'skincare',
  views: [
    {
      id: 'share_of_search',
      name: 'Share of Search',
    },
  ],
  view: {
    id: 'share_of_search',
    name: 'Share of Search',
  },
  ...(ff.chart_line_traction
    ? {
        requestIgnoreField: {
          metric: ['PRODUCT.product_sid', 'PRODUCT.keyword'],
        },
      }
    : {}),
  requestHiddenField: {},
  mapping: {
    keyword: {
      cellFormat: 'keywordFormat',
      propertyType: 'dimension',
      dataType: 'string',
      title: 'Keyword',
      valueGetter: {
        value: 'PRODUCT.keyword',
      },
    },
    product: {
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      title: 'Product',
      filterField: 'PRODUCT.product_name',
      valueGetter: {
        value: 'PRODUCT.product_name',
        product_id: 'PRODUCT.id',
        id: 'PRODUCT.product_sid',
      },
    },
    brand: {
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      title: 'Brand',
      filterField: 'PRODUCT.brand_name',
      valueGetter: {
        value: 'PRODUCT.brand_name',
        id: 'PRODUCT.brand_name',
      },
    },
    storefront: {
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      title: 'Storefront',
      filterField: 'STOREFRONT.name',
      valueGetter: {
        value: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
      },
    },
    shippingMethod: {
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      title: 'Shipping method',
      filterField: 'PRODUCT.shipping_method',
      valueGetter: {
        value: 'PRODUCT.shipping_method',
        id: 'PRODUCT.shipping_method',
      },
    },
    sellingPrice: {
      cellFormat: 'currency',
      propertyType: 'attribute',
      dataType: 'float',
      title: 'Selling price',
      filterField: 'PRODUCT.selling_price',
      valueGetter: {
        value: 'PRODUCT.selling_price',
      },
    },
    discount: {
      cellFormat: 'currency',
      propertyType: 'attribute',
      dataType: 'integer',
      title: 'Discount',
      filterField: 'PRODUCT.discount',
      valueGetter: {
        value: 'PRODUCT.discount',
      },
    },
    promotionType: {
      cellFormat: 'text',
      propertyType: 'attribute',
      dataType: 'string',
      title: 'Promotion type',
      filterField: 'PRODUCT.promotion_type',
      valueGetter: {
        value: 'PRODUCT.promotion_type',
      },
    },
    productRating: {
      cellFormat: 'currency',
      dataType: 'float',
      propertyType: 'attribute',
      title: 'Product rating',
      filterField: 'PRODUCT.rating_star',
      valueGetter: {
        value: 'PRODUCT.rating_star',
      },
    },
    productReview: {
      cellFormat: 'currency',
      dataType: 'integer',
      propertyType: 'attribute',
      title: 'Product review',
      filterField: 'PRODUCT.product_review',
      valueGetter: {
        value: 'PRODUCT.product_review',
      },
    },
    lastUpdatedAt: {
      cellFormat: 'text',
      dataType: 'datetime',
      propertyType: 'attribute',
      title: 'Last updated at',
      valueGetter: {
        value: 'PRODUCT.last_updated_at',
      },
    },
    averagePosition: {
      cellFormat: 'currency',
      propertyType: 'metric',
      dataType: 'float',
      title: 'Average position',
      filterField: 'avg_position',
      valueGetter: {
        value: 'avg_position',
      },
    },
    itemSold: {
      cellFormat: 'currency',
      propertyType: 'metric',
      dataType: 'integer',
      title: 'Item sold',
      filterField: 'item_sold',
      valueGetter: {
        value: 'item_sold',
      },
    },
    ...(ff.chart_line_traction
      ? {
          itemSoldL2W: {
            cellFormat: 'twoLineChart',
            propertyType: 'metric',
            dataType: 'integer',
            title: 'Item sold L2W',
            valueGetter: {
              productSId: 'PRODUCT.product_sid',
              keyword: 'PRODUCT.keyword',
            },
          },
        }
      : {}),
  },
};
