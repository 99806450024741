import { ValidationError } from './workflow-backbone';

export default {
  ev_required: async () => {
    return true;
  },
  ev_shop_budget_min: async (shopEid, budget, message) => {
    if (shopEid == 1308) {
      throw new ValidationError(message.replace('{min_budget}', 500));
    }
    return true;
  },
  ev_budget_is_no_limit: async (budgetValue) => budgetValue === null,
  ev_no_duplicate_campaign_name: async (campaignName, shopEid, error) => {
    return true;
  },
};
