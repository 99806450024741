import * as React from 'react';
import { eipRequest, EIP_CONSTANT } from '@eip/next/lib/main';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import { get } from 'lodash';
import { getConst } from '@ep/insight-ui/sw/constant/common';

export const usePriceBook = () => {
  const [costValue, setCostValue] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [priceBooks, setPriceBooks] = React.useState(null);
  const plans = getConst('EPSILO_PLANS', ['starter', 'individual', 'team', 'professional', 'enterprise']);

  const marks = [
    {
      value: 0,
      scaledValue: 0,
      label: '',
    },
    {
      value: 1,
      scaledValue: 100,
      label: '100',
    },
    {
      value: 2,
      scaledValue: 500,
      label: '500',
    },
    {
      value: 3,
      scaledValue: 2000,
      label: '2k',
    },
    {
      value: 4,
      scaledValue: 5000,
      label: '5k',
    },
    {
      value: 5,
      scaledValue: 10000,
      label: '10k',
    },
    {
      value: 6,
      scaledValue: 100000,
      label: '100k',
    },
    {
      value: 7,
      scaledValue: 500000,
      label: '500k',
    },
    {
      value: 8,
      scaledValue: 1000000,
      label: '',
    },
  ];

  const fetchPriceBook = () => {
    setLoading(true);
    const payload = {
      dimensions: ['notion_price_book'],
      attributes: [
        'notion_price_book.availability',
        'notion_price_book.enterprise',
        'notion_price_book.feature_description',
        'notion_price_book.feature_group',
        'notion_price_book.feature_name',
        'notion_price_book.framework',
        'notion_price_book.id',
        'notion_price_book.individual',
        'notion_price_book.nps',
        'notion_price_book.page',
        'notion_price_book.product',
        'notion_price_book.professional',
        'notion_price_book.section',
        'notion_price_book.starter',
        'notion_price_book.sub_item',
        'notion_price_book.subscription',
        'notion_price_book.team',
        'notion_price_book.type',
        'notion_price_book.unique_id',
        'notion_price_book.url',
        'notion_price_book.version',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: '9999',
      },
      from: '2024-07-19',
      to: '2024-08-18',
      sort: [
        {
          field: 'notion_price_book.type',
          sort: 'ASC',
        },
      ],
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'created_datetime',
            operator: '>=',
            value: '2024-07-19 00:00:00.000',
          },
          {
            field: 'created_datetime',
            operator: '<=',
            value: '2024-08-18 23:59:59.999',
          },
          {
            field: 'notion_price_book.feature_name',
            operator: 'does_not_contain',
            value: '(Hidden)',
          },
          {
            field: 'notion_price_book.feature_name',
            operator: 'is_not_empty',
            value: '',
          },
          {
            field: 'notion_price_book.feature_group',
            operator: 'is_not',
            value: 'ICP',
          },
        ],
      },
    };
    const payload2 = {
      dimensions: ['notion_price_book_parent_item'],
      attributes: [
        'notion_price_book_parent_item.notion_price_book_id',
        'notion_price_book_parent_item.notion_parent_item_id',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: '9999',
      },
      from: '2024-07-19',
      to: '2024-08-18',
      sort: [],
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'created_datetime',
            operator: '>=',
            value: '2024-07-19 00:00:00.000',
          },
          {
            field: 'created_datetime',
            operator: '<=',
            value: '2024-08-18 23:59:59.999',
          },
        ],
      },
    };
    Promise.all([
      eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=user_action', payload),
      eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=user_action', payload2),
    ])
      .then(([res1, res2]) => {
        const result1 = produceQueryResult({
          ...res1,
          data: {
            ...res1.data,
            masterData: res1.masterData,
            masterDataPrimaryKey: res1.masterDataPrimaryKey,
          },
        });
        const result2 = produceQueryResult({
          ...res2,
          data: {
            ...res2.data,
            masterData: res2.masterData,
            masterDataPrimaryKey: res2.masterDataPrimaryKey,
          },
        });
        try {
          const rows1 = get(result1, ['rows'], []).map((i) => {
            Object.entries(i).forEach(([k, v]) => {
              i[k.split('.')[1]] = v;
              delete i[k];
            });
            return i;
          });
          const rows2 = get(result2, ['rows'], []);
          let priceId = '';
          const groupByData = rows1.reduce((carry, row) => {
            const rowId = row['id'];
            const rowName = row['feature_name'];

            if (String(rowName).toLowerCase() == 'pricing') {
              priceId = rowId;
            }

            const parent = rows2.find((i) => i['notion_price_book_parent_item.notion_price_book_id'] == rowId);

            if (parent) {
              const parentId = parent['notion_price_book_parent_item.notion_parent_item_id'];
              if (carry[parentId]) {
                carry[parentId].children = (carry[parentId].children || []).concat(row);
              } else {
                carry[parentId] = {
                  children: [row],
                };
              }
            } else {
              carry[rowId] = {
                ...carry[rowId],
                ...row,
              };
            }

            return carry;
          }, {});
          Object.keys(groupByData).forEach((key) => {
            if (!groupByData[key]['id'] || !groupByData[key]['children']) delete groupByData[key];
          });
          const header = groupByData[priceId].children.find(
            (i) => String(i['feature_name']).toLowerCase() == 'base price',
          );
          const highlights = groupByData[priceId].children.filter(
            (i) => String(i['feature_name']).toLowerCase() != 'base price',
          );
          delete groupByData[priceId];
          setPriceBooks({
            header,
            highlights,
            others: Object.values(groupByData),
          });
        } catch (e) {}
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    fetchPriceBook();
  }, []);

  return {
    loading,
    priceBooks,
    plans,
    costValue,
    setCostValue,
    marks,
  };
};
