import React, { useState } from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import Container from '@material-ui/core/Container';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { isMobile } from 'react-device-detect';
import { HeaderUI as HeaderUIDesktop } from '../../dark-tbr/header';
import { OverviewInfo as OverviewInfoDesktop } from '../../dark-tbr/overview-info';
import { ProgressGroupUI } from '../../dark-tbr/progress-group';
import { HeaderUIMobile } from '../../mobile/header';
import { OverviewInfo as OverviewInfoMobile } from '../../mobile/overview-info';
import { useCountUserView } from './use-count-user-view';
import { useRealtimeData } from './use-realtime-data';
import moment from 'moment';

const HeaderUI = isMobile ? HeaderUIMobile : HeaderUIDesktop;
const OverviewInfo = isMobile ? OverviewInfoMobile : OverviewInfoDesktop;

const useStyles = makeStyles((theme) => ({
  root: {
    '.eip-full-page &': {
      paddingTop: 0,
      marginTop: 56,
    },
    background: theme.palette.background.default,
    marginTop: 40,
    paddingTop: 10,
    paddingLeft: 0,
    paddingRight: 0,
    paddingBottom: 200,
    '&.mobile': {
      paddingBottom: '50px',
    },
  },
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 9,
  },
  switchView: {
    background: 'rgba(217, 221, 227, 0.24)',
    textTransform: 'none',
  },
}));

const Dashboard = (props: { isDisableTarget: boolean }) => {
  const classes = useStyles();

  const PAGE_SIZE = 100;

  const [page, setPage] = useState(1);

  const { brands, countries, gmv } = useRealtimeData(props.isDisableTarget);
  const GMVInfo = gmv;

  const [pageCount, setPageCount] = useState(Math.ceil(Math.max(countries.length, brands.length) / PAGE_SIZE));
  const [leftItems, setLeftItems] = useState(countries.slice(0, PAGE_SIZE));
  const [rightItems, setRightItems] = useState(brands.slice(0, PAGE_SIZE));
  const [view, setView] = useState('countries');

  const [visibleScrollTop, setVisibleScrollTop] = useState(false);
  React.useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY;
      if (scrollPos > 50) {
        window.requestAnimationFrame(() => {
          setVisibleScrollTop(true);
        });
      } else {
        window.requestAnimationFrame(() => {
          setVisibleScrollTop(false);
        });
      }
    });
  }, []);

  React.useEffect(() => {
    const startIndex = (page - 1) * PAGE_SIZE;
    setPageCount(Math.ceil(Math.max(countries.length, brands.length) / PAGE_SIZE));

    setLeftItems(countries.slice(startIndex, startIndex + PAGE_SIZE));
    setRightItems(brands.slice(startIndex, startIndex + PAGE_SIZE));
  }, [countries, brands]);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    const startIndex = (value - 1) * PAGE_SIZE;
    // setLeftItems(countries.slice(startIndex, startIndex + PAGE_SIZE));
    setRightItems(brands.slice(startIndex, startIndex + PAGE_SIZE));
    setPage(value);
  };

  let content = null;
  if (isMobile) {
    content = (
      <>
        <Grid container>
          <Grid item xs={12} style={{ textAlign: 'right' }}>
            <OnlineUserViewer />
          </Grid>
          <Grid item xs={12}>
            <Typography component={'div'} align="right" variant="caption" style={{ marginBottom: 7 }}>
              * All numbers are in USD.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <ProgressGroupUI
              title=""
              groupType="Country"
              processes={leftItems}
              channelVisibility={false}
            ></ProgressGroupUI>
          </Grid>
          {/* <Grid item xs={12}>
            <ProgressGroupUI
              title=""
              groupType="Brand"
              processes={rightItems}
              channelVisibility={true}
            ></ProgressGroupUI>
          </Grid> */}
        </Grid>
      </>
    );
  } else {
    content = (
      <Grid container spacing={5}>
        {view === 'countries' && (
          <Grid item xs={12}>
            <ProgressGroupUI
              title="GMV by Countries"
              groupType="Country"
              processes={leftItems}
              channelVisibility={false}
              toggleButton={null}
            ></ProgressGroupUI>
          </Grid>
        )}
      </Grid>
    );
    // content = (
    //   <Grid container spacing={5}>
    //     {view === 'countries' && (
    //       <Grid item xs={12}>
    //         <ProgressGroupUI
    //           title="GMV by Countries"
    //           groupType="Country"
    //           processes={leftItems}
    //           channelVisibility={false}
    //           toggleButton={
    //             <Button className={classes.switchView} onClick={() => setView('brands')}>
    //               View GMV by brands
    //             </Button>
    //           }
    //         ></ProgressGroupUI>
    //       </Grid>
    //     )}
    //     {view === 'brands' && (
    //       <Grid item xs={12}>
    //         <ProgressGroupUI
    //           title="GMV by brands"
    //           groupType="Brand"
    //           processes={rightItems}
    //           channelVisibility={true}
    //           toggleButton={
    //             <Button className={classes.switchView} onClick={() => setView('countries')}>
    //               View GMV by Countries
    //             </Button>
    //           }
    //         ></ProgressGroupUI>
    //       </Grid>
    //     )}
    //   </Grid>
    // );
  }

  const current = moment().utcOffset(8);
  const campaignNameDefault = (
    <React.Fragment>
      {current.format('D')}.{current.format('M')} Live Dashboard
    </React.Fragment>
  );
  // FIXME: error with build missing stylesheet
  return (
    <Container className={[classes.root, isMobile ? 'mobile' : ''].join(' ')}>
      <HeaderUI></HeaderUI>
      <Box marginLeft={6} marginRight={6}>
        {!isMobile && (
          <Grid container style={{ marginTop: '1em' }}>
            <Grid item xs={6}>
              <OnlineUserViewer />
            </Grid>
            <Grid item xs={6}>
              <Typography component={'div'} align="right" variant="caption">
                * All numbers are in USD.
              </Typography>
              {props.isDisableTarget && (
                <Typography component={'div'} align="right" variant="caption">
                  * No target.
                </Typography>
              )}
            </Grid>
          </Grid>
        )}
        <OverviewInfo {...GMVInfo} campaignName={campaignNameDefault}></OverviewInfo>
        {content}
        {isMobile && visibleScrollTop && (
          <Fab
            aria-label={'go to top'}
            className={classes.fab}
            color={'inherit'}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' });
            }}
          >
            <ExpandLessIcon />
          </Fab>
        )}
      </Box>
    </Container>
  );
};

function OnlineUserViewer() {
  const { desktop, mobile } = useCountUserView();
  if (mobile + desktop === 0) {
    return null;
  }
  return (
    <Typography component={'div'} variant="caption" style={{ color: '#B5BEC9' }}>
      * Online {Number(mobile) + Number(desktop)} users
      {mobile > 0 && <div style={{ display: 'inline-block' }}>{`(${mobile} mobile)`}</div>}
    </Typography>
  );
}

export default Dashboard;
