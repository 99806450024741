/* eslint-disable react/prop-types */
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors, noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import { last, get, set } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { Calendar, DateObject } from 'react-multi-date-picker';
import { calendarStyle, isLimitDate, noLimitDate } from '../helper';
import TextMaskCustom from '../textField-masked';

/**
 * ff.mobile_interaction_zone:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';
/**
 * ff.mobile_interaction_zone:end
 */

const useStyles = makeStyles(() => ({
  header: {
    paddingLeft: '0px',
  },
  container: {
    marginTop: '15px',
    marginBottom: '8px',
  },
  calendar: calendarStyle as CreateCSSProperties,
  label: {
    marginBottom: 5,
  },
  MyInput: {},
  InputPaper: {
    width: '100% !important',
    '& input': {
      width: '100% !important',
    },
  },
  input: {
    '&.startDate fieldset': {
      borderTopRightRadius: '0px !important',
      borderBottomRightRadius: '0px !important',
      borderRightWidth: '0.5px  !important',
    },
    '&.endDate fieldset': {
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      borderLeftWidth: '0.5px !important',
    },
  },
  groupButton: {
    marginTop: '16px',
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '& button + button': {
      marginLeft: '8px',
    },
  },
}));

export type DateValue = {
  dateFrom: string;
  dateTo: string;
};
export interface CalendarFormProps {
  value: DateValue;
  onChange: (value: DateValue) => void;
  defaultFormatReturn?: string;
  disabledChooseStartDate?: boolean;
  minDate?: string;
  maxDate?: string;
  clickOnSubmit?: boolean;
  onClose?: () => void;
  maxDaysSelected?: string | number;
}
const formatInput = 'DD/MM/YYYY';
const formatSystem = 'YYYY/MM/DD';
const generateDateObject = (val: string, format = formatSystem): DateObject => {
  const dateString = moment(val, format).format();
  return new DateObject(dateString);
};
const CalendarRange = ({
  value,
  onChange,
  defaultFormatReturn = 'DD/MM/YYYY',
  disabledChooseStartDate = false,
  minDate = '',
  maxDate = '',
  clickOnSubmit = false,
  onClose = () => undefined,
  maxDaysSelected,
}: CalendarFormProps) => {
  const classes = useStyles();
  const fetchValue = () => {
    const tempValues = [];
    let start = new DateObject();
    let end = generateDateObject(noLimitDate, formatInput);
    const dateFrom = get(value, 'dateFrom', '');
    if (dateFrom) {
      start = generateDateObject(dateFrom, defaultFormatReturn);
    }
    const dateTo = get(value, 'dateTo', '');
    if (!isLimitDate(dateTo, moment(dateTo, defaultFormatReturn))) {
      end = generateDateObject(dateTo, defaultFormatReturn);
    }
    tempValues.unshift(start);
    tempValues.push(end);
    // setRange(tempValues);
    return [...tempValues];
  };

  let isMobileView;
  if (ff.mobile_interaction_zone) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }

  const [currentValue, setCurrentValue] = React.useState<any>();

  const [range, setRange] = React.useState(fetchValue());

  /**
   * handle input value
   */

  const getRangeMoment = () => {
    const start = range[0];
    const startMoment = moment(start.format());

    const end = last(range);
    const endMoment = moment(end.format());
    return { startMoment, endMoment };
  };

  /**
   * handle output value
   */
  React.useEffect(() => {
    if (range.length > 0 && clickOnSubmit) {
      const start = range[0].format(defaultFormatReturn);
      const endValue = last(range);
      const end = last(range).format(defaultFormatReturn);
      if (!value || start != value.dateFrom || end != value.dateTo) {
        onChange({ dateFrom: start, dateTo: endValue.format(formatInput) == noLimitDate ? '' : end });
      }
    }
  }, [range]);

  /**
   * handle value view start date input
   */
  const startDateVal: string = React.useMemo(() => {
    if (range.length > 0) {
      const start = range[0];
      return start.format(formatInput);
    }
    const now = moment().format(formatInput);
    return now;
  }, [range]);

  /**
   * handle value view end date input
   */
  const endDateVal: string = React.useMemo(() => {
    if (range.length > 0) {
      const end = last(range);
      if (end.format(formatInput) !== noLimitDate) {
        return end.format(formatInput);
      }
    }
    return '';
  }, [range]);

  /**
   *
   * handle Change date range when inputs change
   */

  const handleSetStartDate = (val: string) => {
    const valueTemp = [...range];

    if (val == '') {
      const now = moment();
      const value = generateDateObject(now.format(formatInput), formatInput);

      if (!disabledChooseStartDate) {
        setCurrentValue(value);
      }

      valueTemp.unshift(value);
      setRange(valueTemp);
      return;
    }
    if (range.length > 0) {
      const valueMoment = moment(val, formatInput);
      const { startMoment, endMoment } = getRangeMoment();
      if (valueMoment.format(formatInput) !== startMoment.format(formatInput)) {
        const value = new DateObject(valueMoment.format());
        valueTemp.shift();
        if (valueMoment.isBefore(endMoment)) {
          valueTemp.unshift(value);
        } else {
          valueTemp.pop();
          const value = new DateObject(valueMoment.format());
          valueTemp.unshift(value);
          valueTemp.push(value);
        }

        if (!disabledChooseStartDate) {
          setCurrentValue(value);
        }

        setRange(valueTemp);
      }
    }
  };

  const handleSetEndDate = (val: string) => {
    const valueTemp = [...range];
    if (val == '') {
      valueTemp.push(generateDateObject(noLimitDate, formatInput));
      setRange(valueTemp);
      return;
    }
    if (range.length > 0) {
      const valueMoment = moment(val, formatInput);
      const { startMoment, endMoment } = getRangeMoment();
      if (valueMoment.format(formatInput) !== endMoment.format(formatInput) && valueMoment.isAfter(startMoment)) {
        valueTemp.pop();
        const value = new DateObject(valueMoment.format());

        if (disabledChooseStartDate) {
          setCurrentValue(value);
        }

        valueTemp.push(value);
        setRange(valueTemp);
      }
    }
  };
  const handleSetRange = (rangeValue: any) => {
    if (!disabledChooseStartDate) {
      setRange(rangeValue);
      return;
    }
    const rangeTemp = [...range];
    rangeTemp.shift();
    rangeTemp.pop();
    const startDate = range[0] || moment();
    const lastVal = rangeValue;
    const startValue = generateDateObject(startDate.format(formatInput), formatInput);
    rangeTemp.unshift(startValue);
    rangeTemp.push(lastVal);
    setRange(rangeTemp);
  };
  const getDateValue = (date: string, defaultValue?: any) => {
    if (date) {
      return moment(date, defaultFormatReturn);
    }
    return defaultValue;
  };

  /**
   *
   * map days calendar with custom case
   */
  const mapDays = ({ date }: { date: DateObject }): React.HTMLAttributes<HTMLDivElement> => {
    const isSameDate = (arg1: DateObject, arg2: DateObject) => {
      return arg1.format() == arg2.format();
    };

    const props: React.HTMLAttributes<HTMLDivElement> = {};
    const start = range[0];
    if (range.length == 1 && maxDaysSelected) {
      const selectedDate = new DateObject(range[0]).format(defaultFormatReturn);
      const maximumDate = moment(selectedDate)
        .add(maxDaysSelected - 1, 'days')
        .format(defaultFormatReturn);
      const minimumDate = moment(selectedDate)
        .subtract(maxDaysSelected - 1, 'days')
        .format(defaultFormatReturn);
      const dateFormatted = moment(date.format(defaultFormatReturn));
      if (dateFormatted.isBefore(minimumDate) || dateFormatted.isAfter(maximumDate)) {
        props.disabled = true;
      }
    }
    if (range.length > 1) {
      const end = last(range);

      props.className = `${isSameDate(date, start) && 'selected-one start'} ${
        isSameDate(date, end) && 'selected-one end'
      }`;

      const startDate: moment.Moment = moment(range[0].format(formatSystem), formatSystem);
      const endDate: moment.Moment = moment(last(range).format(formatSystem), formatSystem);
      const isBetween = moment(date.format()).isBetween(startDate, endDate);

      if (isBetween) {
        props.className = ' rmdp-range-child';
      }
    } else {
      if (isSameDate(date, start)) {
        props.className = ' selected-one';
      } else {
        props.className = '';
      }
    }

    const min = minDate ? moment(minDate, defaultFormatReturn) : moment();

    if (moment(date.format()).isBefore(min.subtract(1, 'days'))) {
      (props as any).disabled = true;
    }
    const max = getDateValue(maxDate);
    if (max && moment(date.format()).isAfter(max)) {
      (props as any).disabled = true;
    }

    return props;
  };

  const onSubmit = () => {
    if (range.length > 0) {
      const start = range[0].format(defaultFormatReturn);
      const endValue = last(range);
      const end = last(range).format(defaultFormatReturn);
      if (!value || start != value.dateFrom || end != value.dateTo) {
        onChange({ dateFrom: start, dateTo: endValue.format(formatInput) == noLimitDate ? '' : end });
      }
    }
    onClose();
  };

  return (
    <div>
      <Grid className={classes.container} container spacing={0}>
        <Grid item xs={6} style={{ marginRight: '-0.5px' }}>
          <Typography variant="h6" className={classes.label}>
            start date
          </Typography>
          <TextMaskCustom
            startAdornment={
              <div style={{ width: '14px', height: '14px', marginLeft: '10px' }}>
                <Icon type={'calendar2'} style={{ width: '100%', height: '100%' }} />
              </div>
            }
            disabled={disabledChooseStartDate || maxDaysSelected}
            disabledIconClear
            placeholder={startDateVal}
            defaultValue={startDateVal}
            onChangeText={handleSetStartDate}
            className={`${classes.input} startDate`}
          />
        </Grid>
        <Grid item xs={6} style={{ marginLeft: '-0.5px' }}>
          <Typography variant="h6" className={classes.label}>
            end date
          </Typography>
          <TextMaskCustom
            defaultValue={endDateVal}
            onChangeText={handleSetEndDate}
            className={`${classes.input} endDate`}
            disabled={maxDaysSelected}
          />
        </Grid>
      </Grid>
      <Calendar
        multiple={false}
        range={!disabledChooseStartDate}
        numberOfMonths={ff.mobile_interaction_zone && isMobileView ? 1 : 2}
        className={
          ff.mobile_interaction_zone ? `${classes.calendar} ${isMobileView ? 'mobile' : ''}` : classes.calendar
        }
        value={currentValue}
        onChange={handleSetRange}
        weekDays={CALENDAR_WEEKDAYS}
        weekStartDayIndex={1}
        showOtherDays
        mapDays={mapDays}
      />
      {!clickOnSubmit && (
        <Grid container justifyContent="flex-end" item xs={12} className={classes.groupButton}>
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={onSubmit}>
            Apply
          </Button>
        </Grid>
      )}
    </div>
  );
};

export default CalendarRange;
