import * as React from 'react';
import { debounce, IconButton, InputAdornment, makeStyles, TextField } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { searchStyle, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import clsx from 'clsx';
const textFontStyle: React.CSSProperties = {
  fontSize: '14px',
  fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  fontWeight: 500,
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
};
type StyleProps = {
  active: boolean;
  blank: boolean;
};
const useStyles = makeStyles((theme) => ({
  textStyle: { ...(searchStyle as CreateCSSProperties), ...textFontStyle },
  textSearch: ({ active, blank }: StyleProps) => ({
    '& input': {
      height: 30,
      padding: 0,
      fontSize: '0.875rem',
      border: 'none',
      '&::placeholder': {
        /* Firefox */
        ...textFontStyle,
        opacity: 1,
        color: !active ? '#253746' : '#8C98A4',
        fontWeight: !active ? 500 : 400,
      },

      '&:-ms-input-placeholder': {
        /* Internet Explorer 10-11 */
        ...textFontStyle,
        color: !active ? '#253746' : '#8C98A4',
        fontWeight: !active ? 500 : 400,
      },

      '&::-ms-input-placeholder': {
        /* Microsoft Edge */
        ...textFontStyle,
        color: !active ? '#253746' : '#8C98A4',
        fontWeight: !active ? 500 : 400,
      },
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: blank ? '#006ec6' : 'transparent',
    },

    '&.no-outline .eip1-MuiOutlinedInput-root.Mui-focused .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006ec6',
    },
  }),
  inputOpen: {
    width: 200,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  inputClose: {
    width: 84,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

type ButtonSearchProps = {
  defaultText: string;
  onChangeText: (text: string) => void;
  disabledOutline?: boolean;
};
const searchButtonForm = ({ defaultText, onChangeText, disabledOutline = true }: ButtonSearchProps) => {
  const [keywork, setkeywork] = React.useState<string>(defaultText);
  React.useEffect(() => {
    if (keywork !== defaultText) {
      setkeywork(defaultText);
    }
  }, [defaultText]);
  const [active, setActive] = React.useState<boolean>(false);
  const classes = useStyles({ active, blank: Boolean(keywork) });
  const handleClick = () => setActive(true);
  const handleBlur = () => {
    if (!keywork.trimStart()) setActive(false);
  };
  const handler = React.useCallback(debounce(onChangeText, 200), []);
  React.useEffect(() => {
    handler(keywork);
  }, [keywork]);
  return (
    <React.Fragment>
      <TextField
        className={clsx(classes.textStyle, classes.textSearch, {
          [classes.inputOpen]: active,
          [classes.inputClose]: !active,
          ['no-outline']: disabledOutline,
        })}
        size={'small'}
        onClick={handleClick}
        placeholder={'Search'}
        onChange={(e) => setkeywork(e.target.value.trimStart())}
        onBlur={handleBlur}
        defaultValue={'Search'}
        value={keywork}
        color={'primary'}
        variant={'outlined'}
        InputProps={{
          startAdornment: (
            <InputAdornment position={'start'}>
              <Search style={{ width: 12, height: 14, color: '#253746' }} />
            </InputAdornment>
          ),
          endAdornment: keywork && (
            <IconButton
              onClick={() => {
                setkeywork('');
                setTimeout(() => {
                  setActive(false);
                }, 0);
              }}
            >
              <Icon type={'closeCircle'} />
            </IconButton>
          ),
        }}
      />
    </React.Fragment>
  );
};

export default searchButtonForm;
