import Icon from '@ep/insight-ui/icons/Icon';
import {
  Divider,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  TableCellProps,
  MenuItem,
} from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { useState } from 'react';
import Dropdown from '../dropdown-menu/dropdown';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { useAtomValue, useSetAtom } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { IHeaderParams } from '@ag-grid-community/core';
import { get } from 'lodash';
import { useKeyboardInteraction } from './hooks/use-keyboard-interaction';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import StyledTooltip from '../tooltip/styled';

const useStyle = makeStyles({
  header: {
    fontWeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    ...(ff.ag_grid_27 ? { width: '100%' } : {}),
  },
  label: {
    // width: '100%',
    //
    // textAlign: 'right',
    '&.align-right': {
      textAlign: 'right',
    },
    '&.align-left': {
      textAlign: 'left',
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  sortType: {
    '&.d-show': {
      opacity: 1,
    },
    '&.d-none': {
      opacity: 0,
    },
    marginRight: '8px',
  },
  button: {
    minWidth: '24px',
    height: '24px',
    backgroundColor: 'white',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  itemDropdownContainer: {
    position: 'relative',
    '&:hover': {
      '& .editAction': {
        display: 'block',
      },
    },
    '& .editAction': {
      position: 'absolute',
      background: 'transparent',
      top: '50%',
      right: '4px',
      transform: 'translateY(-50%)',
      padding: '0 4px',
      display: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
      '&:hover': {
        background: '#E4E7E9',
      },
    },
    '&.eip1-MuiListItem-gutters': {
      padding: 0,
      borderRadius: '4px',
    },
  },
  tooltip: {
    '& p': {
      margin: 0,
      padding: '2px 4px',
    },
  },
  tooltipHeader: {
    fontWeight: 500,
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  justifyContentStart: {
    justifyContent: 'flex-start',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  descriptionIcon: {
    width: '16px',
    maxWidth: '16px',
  },
});

export const HeaderCell = (props: IHeaderParams) => {
  const classes = useStyle();

  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const mapping = backboneContext.getConfig('mapping');

  const { showThreeDots } = useKeyboardInteraction({
    backbone: backboneContext,
    gridApi: props.api,
    node: {},
    eGridCell: props.eGridHeader,
  });
  const columnDescription = get(mapping, [props.field, 'columnDescription'], '');

  const { menuAction = [], field, sort, dataType = '' } = props;
  const sortType = props.sort?.filter((s) => s.field === field)[0]?.sort;
  const newAction = menuAction.map((el) => {
    return {
      ...el,
      onClick() {
        const checkExist = sort.filter((object) => object.field === field);
        if (checkExist.length === 0) {
          sort.push({ field, sort: el.type });
        } else {
          checkExist[0].sort = el.type;
        }
        el.onSubmit({ field, menuType: { name: el.name, type: el.type } });
      },
    };
  });
  const [isHovering, setIsHovering] = useState(false);

  const updates = useAtomValue(eTableAtom.headerUpdates);
  const setHeaderRetry = useSetAtom(eTableAtom.updateHeaderRetry);

  const [notifyUpdates, setNotifyUpdates] = React.useState<(typeof updates)['string']>(null);
  const align = ['float', 'integer'].includes(dataType) ? 'right' : 'left';
  const alignStyle = {
    left: 'justifyContentStart',
    right: 'justifyContentEnd',
  };
  const alignClass = classes[get(alignStyle, [align], 'justifyContentEnd')];

  const handleMouseOverCell = () => {
    setIsHovering(true);
  };
  const handleMouseOutCell = (event) => {
    const e = event.toElement || event.relatedTarget;
    if (e.parentNode == this || e == this) {
      return;
    }

    setIsHovering(false);
  };

  React.useEffect(() => {
    const update = get(updates, [props.column.getId()], null);
    if (update) {
      setNotifyUpdates(update);
    } else {
      setNotifyUpdates(null);
    }
  }, [updates]);

  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');

  return (
    <MonitorContainer
      component={Grid}
      mId={`header.${props.field}`}
      mLabel={props?.displayName}
      className={clsx(props?.displayName + 'header', classes.header)}
      onMouseOver={handleMouseOverCell}
      onMouseLeave={handleMouseOutCell}
      role="cell"
    >
      <StyledTooltip
        title={
          <div className={classes.tooltip}>
            <p className={classes.tooltipHeader}>{props?.displayName}</p>
            {columnDescription ? <p className={classes.tooltipContent}>{columnDescription}</p> : null}
          </div>
        }
        placement={'right'}
      >
        <Grid container alignItems={'center'} wrap={'nowrap'} spacing={3} className={alignClass}>
          {notifyUpdates && notifyUpdates.type == 'error' && (
            <Grid className={'align-left'} style={{ maxWidth: 8, flexBasis: 8, flexGrow: 0, flexShrink: 0 }}>
              <Icon type="circleFill_azureishWhite" size={8}></Icon>
            </Grid>
          )}
          {notifyUpdates && notifyUpdates.type == 'loading' && (
            <Grid className={'align-left'} style={{ maxWidth: 8, flexBasis: 8, flexGrow: 0, flexShrink: 0 }}>
              <Icon type="active" size={8}></Icon>
            </Grid>
          )}
          <Grid item className={`${classes.label} ${props?.displayName}`}>
            {props?.displayName}
          </Grid>
          {columnDescription && (
            <Grid xs={2} className={classes.descriptionIcon} container alignItems={'center'}>
              <Icon type={'ic/mi:circle-information/#737373'} />
            </Grid>
          )}
        </Grid>
      </StyledTooltip>

      <div className={`${classes.menu}`} role="menubar">
        {(isHovering || showThreeDots) && menuAction?.length > 0 && (
          <Dropdown
            className={`${classes.button} eip_header_act_${props.field}`}
            disabledClickContentClose={false}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            dataMenuPage={[newAction]}
            keyboardNavigation
          >
            {notifyUpdates && notifyUpdates.type === 'error' && (
              <MenuItem className={classes.itemDropdownContainer}>
                <ListItem button role="menuitem" className={classes.itemDropdown}>
                  <ListItemIcon>
                    <Icon type={'refresh'} colorIcon={'#253746'} width="16px" height="16px" />
                  </ListItemIcon>
                  <ListItemText
                    className={classes.textStyle}
                    onClick={() => {
                      setHeaderRetry({ token: notifyUpdates.token });
                    }}
                  >
                    Refresh this column
                  </ListItemText>
                </ListItem>
              </MenuItem>
            )}
            {menuAction.map((menu, index) => (
              <MenuItem
                key={index}
                className={classes.itemDropdownContainer}
                onClick={() => {
                  if (menu.name == 'Group by') {
                    uawl.start('canGroupItemEtable', { title: titleTable, timerMs: 3000 });
                  }
                  const checkExist = sort.filter((object) => object.field === field);
                  if (checkExist.length === 0) {
                    sort.push({ field, sort: menu.type });
                  } else {
                    checkExist[0].sort = menu.type;
                  }
                  menu.onSubmit({ field, menuType: { name: menu.name, type: menu.type } });
                }}
              >
                {/* {index === menuAction?.length - 1 && <Divider />} */}
                <ListItem button role="menuitem" className={classes.itemDropdown}>
                  <ListItemIcon>
                    <Icon type={menu.icon} colorIcon={'#253746'} width="16px" height="16px" />
                  </ListItemIcon>
                  <ListItemText className={classes.textStyle} primary={menu.name} />
                </ListItem>
              </MenuItem>
            ))}
          </Dropdown>
        )}
      </div>
    </MonitorContainer>
  );
};
export default HeaderCell;
