import * as React from 'react';
import moment from 'moment';
import { cloneDeep, get, set } from 'lodash';
import { useAtomValue } from 'jotai';
import { Bubble } from 'react-chartjs-2';
import 'chartjs-adapter-moment';

import { Popover, makeStyles } from '@material-ui/core';

import { eipRequest } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import Icon from '@ep/insight-ui/icons/Icon';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import StyledTooltip from '../tooltip/styled';
import ConditionalWrap from '../util/conditional-wrap';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import { funcConfigs } from '@ep/insight-ui/chartlib/bubble-chart/hooks';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import Wrapper from '../etable2/wrapper';

const MAX_Y_AXIS = getConst(['monitorConfig.maxYAxis'], 60);
const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '8px',
  },
  tooltip: {
    margin: 0,
    padding: '2px 4px',
    whiteSpace: 'pre-line',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
}));

const options = {
  plugins: {
    legend: {
      display: false,
    },
    filler: {
      propagate: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  scales: {
    x: {
      type: 'time',
      time: {
        unit: 'minute',
        tooltipFormat: 'YYYY-MM-DD HH:mm',
        displayFormats: {
          minute: 'HH:mm',
        },
        stepSize: 30,
      },
      grid: {
        display: false,
      },
    },
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        callback: function (value) {
          return value + 's';
        },
      },
    },
  },
  onHover: (event, chartElement) => {
    event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
  },
};

const BubbleChart = ({ tableId, hourMonitor, redFlag }) => {
  const [loading, setLoading] = React.useState(true);
  const [data, setData] = React.useState({ datasets: [] });

  const { externalTooltipHandler } = funcConfigs({});

  const min = moment().subtract(hourMonitor, 'hours');
  const max = moment();
  React.useEffect(() => {
    const payload = {
      dimensions: ['trace_metric_section_ttl'],
      attributes: [
        'trace_metric_section_ttl.traceID',
        'trace_metric_section_ttl.spanID',
        'trace_metric_section_ttl.tag',
        'trace_metric_section_ttl.page_name',
        'trace_metric_section_ttl.section_id',
        'trace_metric_section_ttl.section_name',
        'trace_metric_section_ttl.workspace_id',
        'trace_metric_section_ttl.duration',
        'trace_metric_section_ttl.note',
        'trace_metric_section_ttl.user_email',
        'trace_metric_section_ttl.created_datetime',
        'trace_metric_section_ttl.error_message',
        'global_company.name',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: 9999,
      },
      from: '2024-04-12',
      to: '2024-04-12',
      sort: [],
      filter: {
        combinator: 'and',
        filters: [
          {
            dataType: 'string',
            field: 'trace_metric_section_ttl.tag',
            operator: 'IN',
            value: ['render'],
          },
          {
            dataType: 'string',
            field: 'trace_metric_section_ttl.section_id',
            operator: 'is',
            value: tableId,
          },
          {
            field: 'trace_metric_section_ttl.created_datetime',
            operator: '>=',
            value: moment().subtract(hourMonitor, 'hours'),
          },
          {
            field: 'trace_metric_section_ttl.created_datetime',
            operator: '<=',
            value: moment(),
          },
        ],
      },
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
    };

    eipRequest
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=quality', payload)
      .then((res) => {
        const result = produceQueryResult({
          ...res,
          data: {
            ...res.data,
            masterData: res.masterData,
            masterDataPrimaryKey: res.masterDataPrimaryKey,
          },
        });
        const newData = {
          datasets: [
            {
              label: 'Error',
              data: [],
              backgroundColor: '#d4290d',
            },
            {
              label: 'Error',
              data: [],
              backgroundColor: '#f0ad4e',
            },
            {
              label: 'Normal',
              data: [],
              backgroundColor: '#344e73',
            },
            {
              label: 'Normal',
              data: [],
              backgroundColor: '#0ba373',
            },
            {
              label: 'Normal',
              data: [
                min.subtract(1, 'hours').local().format('YYYY-MM-DD HH:mm:ss'),
                max.add(1, 'hours').local().format('YYYY-MM-DD HH:mm:ss'),
              ].map((i) => ({
                x: i,
                value: 1,
                y: redFlag,
              })),
              fill: false,
              backgroundColor: 'transparent',
              type: 'line',
              borderColor: '#f0ad4e',
              borderDash: [5, 5],
              borderWidth: 1,
            },
          ],
        };
        result.rows.forEach((row) => {
          const error = row['trace_metric_section_ttl.error_message'];
          const x = moment.utc(row['trace_metric_section_ttl.created_datetime']).local().format('YYYY-MM-DD HH:mm:ss');
          const y = Number((row['trace_metric_section_ttl.duration'] / Math.pow(10, 6)).toFixed(2));
          const queryTime = String(row['trace_metric_section_ttl.note']).split(' - ')[1];
          const userEmail = String(row['trace_metric_section_ttl.user_email'] || '');
          const dataset = {
            x,
            y: Math.min(MAX_Y_AXIS, y),
            r: 10,
            formatLabel: '=CONCATENATE(p("value"), "s")',
            traceId: row['trace_metric_section_ttl.traceID'],
            spanId: row['trace_metric_section_ttl.spanID'],
            label: `${userEmail} - ${row['global_company.name']}`,
            value: y,
            title: x,
            additionalTooltip: [
              {
                label: 'uQuery',
                value: String(queryTime).split(': ')?.[1],
              },
            ],
          };
          if (error && !userEmail.endsWith('@epsilo.io')) newData['datasets'][0]['data'].push(dataset);
          else if (error && userEmail.endsWith('@epsilo.io')) newData['datasets'][1]['data'].push(dataset);
          else if (userEmail.endsWith('@epsilo.io')) newData['datasets'][3]['data'].push(dataset);
          else newData['datasets'][2]['data'].push(dataset);
        });
        setData(newData);
      })
      .finally(() => setLoading(false));
  }, []);

  const modifiedOptions = cloneDeep(options);
  const minMinute = moment(min).format('mm');
  const maxMinute = moment(max).format('mm');
  set(
    modifiedOptions,
    ['scales', 'x', 'min'],
    moment(min).format(`YYYY-MM-DD HH:${Number(minMinute) > 30 ? '30' : '00'}:ss`),
  );
  set(
    modifiedOptions,
    ['scales', 'x', 'max'],
    moment(max).format(`YYYY-MM-DD HH:${Number(maxMinute) > 30 ? '59' : '30'}:ss`),
  );

  set(modifiedOptions, 'plugins.tooltip.external', externalTooltipHandler);
  set(modifiedOptions, 'onClick', (_, i) => {
    const datasetIndex = get(i, [0, 'datasetIndex']);
    const index = get(i, [0, 'index']);
    const element = get(data, ['datasets', datasetIndex, 'data', index]);
    if (element) {
      const { traceId, spanId } = element;
      window.open(`https://conan.epsilo.io/trace/${traceId}${spanId ? '?uiFind=' + spanId : ''}`);
    }
  });

  return (
    <div style={{ width: '50vw' }}>
      {loading ? <LoadingIcon color={'#253746'} /> : <Bubble options={modifiedOptions} data={data} />}
    </div>
  );
};

const MonitorSection = () => {
  const classes = useStyles();

  const [countDelay, setCountDelay] = React.useState(null);
  const [countError, setCountError] = React.useState(null);
  const backboneContext = React.useContext(TableBackboneContext);
  const status = backboneContext.getStatus('table');
  const tableId = useAtomValue(eTableAtom.tableId);
  const configMonitorBackbone = Array.isArray(backboneContext.getConfig('system.monitorConfig'))
    ? backboneContext.getConfig('system.monitorConfig')[0]
    : backboneContext.getConfig('system.monitorConfig');
  const monitorConfig = (configMonitorBackbone || getConst(['monitorConfig'], '20,24'))
    .split(',')
    .map((el) => String(el).trim());
  const redFlag = monitorConfig[0] || 20;
  const hourMonitor = monitorConfig[1] || 24;
  const isRed = countError > 0;
  const isYellow = countDelay > 0;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const monitorRef = React.useRef(null);

  React.useEffect(() => {
    if (status == 'success') {
      const delayPayload = {
        dimensions: ['trace_metric_section_ttl'],
        attributes: ['trace_metric_section_ttl.spanID'],
        metrics: [],
        pagination: {
          page: 1,
          limit: 100,
        },
        from: '2024-04-12',
        to: '2024-04-12',
        sort: [],
        filter: {
          combinator: 'and',
          filters: [
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.tag',
              operator: 'IN',
              value: ['render'],
            },
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.section_id',
              operator: 'is',
              value: tableId,
            },
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.duration',
              operator: '>',
              value: redFlag * Math.pow(10, 6),
            },
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.error_message',
              operator: 'IS_EMPTY',
              value: '',
            },
            {
              field: 'trace_metric_section_ttl.created_datetime',
              operator: '>=',
              value: moment().subtract(hourMonitor, 'hours'),
            },
            {
              field: 'trace_metric_section_ttl.created_datetime',
              operator: '<=',
              value: moment(),
            },
          ],
        },
        hiddenFilter: {
          currency: 'USD',
        },
        currency: 'USD',
        groupAll: true,
        groupPeriod: 'all',
        groupBy: {
          columns: null,
          aggregations: [
            {
              field: 'trace_metric_section_ttl.spanID',
              func: 'COUNT_UNIQUE',
            },
          ],
        },
      };
      const errorPayload = {
        dimensions: ['trace_metric_section_ttl'],
        attributes: ['trace_metric_section_ttl.error_message'],
        metrics: [],
        pagination: {
          page: 1,
          limit: 100,
        },
        from: '2024-04-12',
        to: '2024-04-12',
        sort: [],
        filter: {
          combinator: 'and',
          filters: [
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.tag',
              operator: 'IN',
              value: ['render'],
            },
            {
              dataType: 'string',
              field: 'trace_metric_section_ttl.section_id',
              operator: 'is',
              value: tableId,
            },
            {
              field: 'trace_metric_section_ttl.created_datetime',
              operator: '>=',
              value: moment().subtract(hourMonitor, 'hours'),
            },
            {
              field: 'trace_metric_section_ttl.created_datetime',
              operator: '<=',
              value: moment(),
            },
            {
              field: 'trace_metric_section_ttl.error_message',
              operator: 'IS_NOT_EMPTY',
              value: '',
            },
          ],
        },
        hiddenFilter: {
          currency: 'USD',
        },
        currency: 'USD',
        groupAll: true,
        groupPeriod: 'all',
        groupBy: {
          columns: null,
          aggregations: [
            {
              field: 'trace_metric_section_ttl.error_message',
              func: 'COUNT_ALL',
            },
          ],
        },
      };
      Promise.all([
        eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=quality', delayPayload),
        eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=quality', errorPayload),
      ]).then(([delayResponse, errorResponse]) => {
        setCountDelay(get(delayResponse, ['data', 'rows', 0], 0));
        setCountError(get(errorResponse, ['data', 'rows', 0], 0));
      });
    }
  }, [status]);

  const tooltipText = [countDelay, countError].reduce((carry, count, index) => {
    if (index == 0 && count > 0) return `${count} delays(>${redFlag}s)`;
    if (index == 1 && count > 0) {
      return carry ? `${carry} and ${count} errors` : `${count} errors`;
    }
    return carry;
  }, '');

  if (countDelay == null && countError == null) return null;

  return (
    <div className={classes.container} ref={monitorRef}>
      <ConditionalWrap
        condition={isRed || isYellow}
        wrap={(children) => {
          return (
            <StyledTooltip
              title={
                <div className={classes.tooltip}>
                  {tooltipText} in {hourMonitor} hours
                </div>
              }
              placement={'right'}
            >
              {children}
            </StyledTooltip>
          );
        }}
      >
        <span onClick={() => setAnchorEl(monitorRef.current)}>
          <Icon
            type={'ic/ic:baseline-circle/' + (isRed ? '#d4290d' : isYellow ? '#f0ad4e' : '#0ba373')}
            size={10}
            width={10}
          />
        </span>
      </ConditionalWrap>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <Wrapper>
          <BubbleChart tableId={tableId} hourMonitor={hourMonitor} redFlag={redFlag} />
        </Wrapper>
      </Popover>
    </div>
  );
};

export default MonitorSection;
