import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { select } from 'redux-saga/effects';

const DataTypes = {
  float: 'float',
  integer: 'integer',
  datetime: 'datetime',
  string: 'string',
};

const initAggregationList = {
  MAX: {
    id: 'MAX',
    label: 'Max',
    selectLabel: 'Max',
    requestQuery: 'MAX',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime],
  },
  MIN: {
    id: 'MIN',
    label: 'Min',
    selectLabel: 'Min',
    requestQuery: 'MIN',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime],
  },
  AVG: {
    id: 'AVG',
    label: 'Average',
    selectLabel: 'Average',
    requestQuery: 'AVG',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime],
  },
  UNIQUE: {
    id: 'UNIQUE',
    label: 'Unique',
    selectLabel: 'Count unique',
    requestQuery: 'UNIQUE',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  SUM: {
    id: 'SUM',
    label: 'Sum',
    selectLabel: 'Sum',
    requestQuery: 'SUM',
    dataTypes: [DataTypes.float, DataTypes.integer],
  },
  COUNT_ALL: {
    id: 'COUNT_ALL',
    label: 'All',
    selectLabel: 'Count all',
    requestQuery: 'COUNT_ALL',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  COUNT_VALUES: {
    id: 'COUNT_VALUES',
    label: 'Values',
    selectLabel: 'Count values',
    requestQuery: 'COUNT_VALUES',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  COUNT_UNIQUE: {
    id: 'COUNT_UNIQUE',
    label: 'Uniques',
    selectLabel: 'Count uniques',
    requestQuery: 'COUNT_UNIQUE',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  MEDIAN: {
    id: 'MEDIAN',
    label: 'Median',
    selectLabel: 'Median',
    requestQuery: 'MEDIAN',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime],
  },
  PERCENT_NOT_EMPTY: {
    id: 'PERCENT_NOT_EMPTY',
    label: 'Not empty',
    selectLabel: 'Percent not empty',
    requestQuery: 'PERCENT_NOT_EMPTY',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  PERCENT_EMPTY: {
    id: 'PERCENT_EMPTY',
    label: 'Empty',
    selectLabel: 'Percent empty',
    requestQuery: 'PERCENT_EMPTY',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  COUNT_NOT_EMPTY: {
    id: 'COUNT_NOT_EMPTY',
    label: 'Not empty',
    selectLabel: 'Count not empty',
    requestQuery: 'COUNT_NOT_EMPTY',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  COUNT_EMPTY: {
    id: 'COUNT_EMPTY',
    label: 'Empty',
    requestQuery: 'COUNT_EMPTY',
    selectLabel: 'Count empty',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  RANGE: {
    id: 'RANGE',
    label: 'Range',
    selectLabel: 'Range',
    requestQuery: 'RANGE',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime],
  },
  NONE: {
    id: 'NONE',
    label: 'None',
    selectLabel: 'None',
    requestQuery: 'NULL',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
  EARLIEST_DATE: {
    id: 'EARLIEST_DATE',
    label: 'Earliest date',
    selectLabel: 'Ealiest date',
    requestQuery: 'EARLIEST_DATE',
    dataTypes: [DataTypes.datetime],
  },
  LATEST_DATE: {
    id: 'LATEST_DATE',
    label: 'Latest date',
    selectLabel: 'Latest date',
    requestQuery: 'LATEST_DATE',
    dataTypes: [DataTypes.datetime],
  },
  LIST: {
    id: 'LIST',
    label: 'List',
    selectLabel: 'List',
    requestQuery: 'LIST',
    dataTypes: [DataTypes.float, DataTypes.integer, DataTypes.datetime, DataTypes.string],
  },
};

type AggregationType = {
  id: string;
  label: string;
  selectLabel: string;
  requestQuery: string;
  currency?: string;
  dataTypes?: string[];
};

export const aggregationList = atom<AggregationType[]>(Object.values(initAggregationList));

const aggregationOrder = {
  [DataTypes.string]: [
    'NONE',
    'COUNT_ALL',
    'COUNT_VALUES',
    'COUNT_UNIQUE',
    'COUNT_EMPTY',
    'COUNT_NOT_EMPTY',
    'PERCENT_EMPTY',
    'PERCENT_NOT_EMPTY',
    'LIST',
  ],
};

aggregationOrder[DataTypes.float] = aggregationOrder[DataTypes.string].concat([
  'SUM',
  'AVG',
  'MEDIAN',
  'MIN',
  'MAX',
  'RANGE',
]);
aggregationOrder[DataTypes.integer] = aggregationOrder[DataTypes.float];
aggregationOrder[DataTypes.datetime] = aggregationOrder[DataTypes.string].concat([
  'EARLIEST_DATE',
  'LATEST_DATE',
  'RANGE',
]);

export const aggregationByType = selectAtom(aggregationList, (ls) => {
  return Object.values(DataTypes).reduce((carry, type) => {
    const aggList = aggregationOrder[type].map((id) => ls.find((agg) => agg.id === id));
    return {
      ...carry,
      [type]: aggList,
    };
  }, {});
});

export const aggregationByFunc = selectAtom<
  AggregationType[],
  Record<keyof typeof initAggregationList, AggregationType>
>(aggregationList, (ls) => {
  return ls.reduce((carry, agg) => {
    return { ...carry, [agg.id]: agg };
  }, {} as Record<keyof typeof initAggregationList, AggregationType>);
});
