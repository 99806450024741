import { useLog } from '@eip/next/lib/main';
import dataSource, { getDraft } from '../../data-source/common';

const validation = {
  total_budget: `ev_budget_is_no_limit(#_.total_budget) 
  OR ev_shop_budget_min(#_.shop_eid, #_.total_budget, "Budget should above {min_budget}");`,
  daily_budget: `ev_budget_is_no_limit(#_.daily_budget) 
  OR ev_shop_budget_min(#_.shop_eid, #_.daily_budget, "Budget should above {min_budget}");`,
  campaign_name: `ev_required(#_.campaign_name, "Campaign name is required.") 
  AND ev_no_duplicate_campaign_name(#_.campaign_name, #_.shop_eid, "Campaign name is duplicated.");`,
  schedule_start_date: `ev_cond(#_.schedule_start_date > time("now"), "Start date is invalid");`,
};

const log = useLog('lib:workflow-backbone:campaign-details');

const defaultDataRepo = dataSource;

function screenCampaignInfo(dataRepo: typeof dataSource) {
  const screen = {
    screenId: 'campaign_info',
    inputOnDemand: {
      __init__: (workflow: Workflow) => {
        const adsCampaignId = workflow.data.get('entityId', null);
        let { marketplace: marketplaceCode } = workflow.data.get('context', { marketplace: 'SHOPEE' });
        if (marketplaceCode === undefined) marketplaceCode = 'SHOPEE';

        return Promise.all([
          dataRepo.getMarketplace(),
          dataRepo.getCountry(),
          dataRepo.getStorefront(),
          dataRepo.getAdType(),
          dataRepo.getAdTool(),
          dataRepo.getStatus({ dimension: 'ADS_CAMPAIGN', marketplaceCode: marketplaceCode }),
        ])
          .then(([marketplace, country, storefront, adType, adTool, status]) => {
            return {
              marketplace: marketplace.data,
              country: country.data,
              storefront: storefront.data,
              adType: adType.data,
              adTool: adTool.data,
              adsCampaignId,
              marketplaceCode: marketplaceCode,
              status: status.data,
            };
          })
          .catch((err) => console.error(err));
      },

      country: async () => {
        return dataRepo.getCountry();
      },
    },
    validation: validation,
  };

  return screen as WorkflowRegisterScreen<typeof screen['inputOnDemand']>;
}
export type ScreenCampaignInfoRegister = ReturnType<typeof screenCampaignInfo>;

function screenSetupProductKeywords(dataRepo: typeof dataSource) {
  const screen = {
    screenId: 'setup_product_keywords',
    inputOnDemand: {
      __init__: async () => {
        return;
      },
      getTableBackboneConfiguration: async (data, workflow: Workflow) => {
        return {};
      },
    },
    validation: validation,
  };
  return screen as WorkflowRegisterScreen<typeof screen['inputOnDemand']>;
}
export type ScreenSetupProductKeywords = ReturnType<typeof screenSetupProductKeywords>;

export function createRegister(dataRepo = defaultDataRepo, workflowId = ''): WorkflowRegister {
  const campaignInfo = screenCampaignInfo(dataRepo);
  const setupProductKeywords = screenSetupProductKeywords(dataRepo);

  const CampaignCreatingWorkflowRegister: WorkflowRegister = {
    input: {},
    init: async (input: { entityId?: string; parentId?: string; pageId?: string; context: Record<string, any> }) => {
      if (input.entityId && input.entityId.indexOf('default') !== 0) {
        return input;
      } else {
        // return getDraft({
        //   parentId: input.parentId,
        //   entityId: input.entityId,
        //   templatePageId: input.pageId,
        //   workflowId: workflowId,
        // }).then(({ entityId, blockId }) => {
        //   log('draft input', input);
        //   return { entityId, blockId };
        // });
        return { entityId: 'default' };
      }
    },
    onSubmit: async (data) => {
      throw new Error('not implemented yet');
    },
    screens: { [campaignInfo.screenId]: campaignInfo, [setupProductKeywords.screenId]: setupProductKeywords },
  };

  return CampaignCreatingWorkflowRegister;
}
