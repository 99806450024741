import * as React from 'react';

export const useScreenInfo = () => {
  const marketplaceOptions = [
    { id: 'LAZADA', text: 'Lazada', label: 'Lazada', value: 'LAZADA' },
    { id: 'AMAZON', text: 'Amazon', label: 'Amazon', value: 'AMAZON' },
  ];
  const countryOptions = [
    {
      id: 'VN',
      text: 'Vietnam',
      payload: { currency: 'VND', timezone: 'Asia/Ho_Chi_Minh' },
      label: 'Vietnam',
      value: 'VN',
    },
    {
      id: 'PH',
      text: 'Philippines',
      payload: { currency: 'PHP', timezone: 'Asia/Manila' },
      label: 'Philippines',
      value: 'PH',
    },
    {
      id: 'MY',
      text: 'Malaysia',
      payload: { currency: 'MYR', timezone: 'Asia/Kuala_Lumpur' },
      label: 'Malaysia',
      value: 'MY',
    },
    {
      id: 'SG',
      text: 'Singapore',
      payload: { currency: 'SGD', timezone: 'Asia/Singapore' },
      label: 'Singapore',
      value: 'SG',
    },
    {
      id: 'ID',
      text: 'Indonesia',
      payload: { currency: 'IDR', timezone: 'Asia/Jakarta' },
      label: 'Indonesia',
      value: 'ID',
    },
    {
      id: 'TH',
      text: 'Thailand',
      payload: { currency: 'THB', timezone: 'Asia/Bangkok' },
      label: 'Thailand',
      value: 'TH',
    },
  ];

  return {
    marketplaceOptions,
    countryOptions,
  };
};

const demoData = JSON.stringify([
  {
    value: '1',
    label: 'Garnier Men Indonesia Official Shop',
    checked: true,
    disabled: true,
  },
  {
    value: '2',
    label: 'Garnier  Indonesia Official Shop',
    checked: true,
  },
  {
    value: '3',
    label: 'Maybelline  Indonesia Official Shop',
    checked: false,
  },
  {
    value: '4',
    label: 'L’Oreal Paris Indonesia Official Shop',
    checked: false,
  },
]);

export const useStorefrontForm = () => {
  // Credential Form
  const [marketplace, setMarketplace] = React.useState('');
  const [country, setCountry] = React.useState('');
  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [isPasswordValid, setIsPasswordValid] = React.useState(true);
  const [isCredentialFormSuccess, setIsCredentialFormSuccess] = React.useState(false);

  const isCredentialFormBtnDisabled =
    [marketplace, country, username, password].some((el) => el === '') ||
    password.length < 8 ||
    !isPasswordValid ||
    isCredentialFormSuccess;

  const onValidateCredential = () => {
    if (!isCredentialFormBtnDisabled) {
      if (/(?=.*[a-z])(?=.*[0-9])(?=.*[$&+,:;=?@#|'<>.^*()%!-])/i.test(password)) {
        setIsCredentialFormSuccess(true);
      } else {
        setIsPasswordValid(false);
      }
    }
  };

  React.useEffect(() => {
    setIsPasswordValid(true);
  }, [password]);

  React.useEffect(() => {
    setIsCredentialFormSuccess(false);
  }, [marketplace, country, username, password]);

  // OTP Form
  const [otp, setOtp] = React.useState('');
  const [isOTPValid, setIsOTPValid] = React.useState(true);
  const [isOTPFormSuccess, setIsOTPFormSuccess] = React.useState(false);

  const isOTPFormBtnDisabled = otp === '' || !isOTPValid || isOTPFormSuccess;

  const onValidateOtp = () => {
    // 50% invalid
    const randomNumber = Math.round(Math.random() * 1);
    setIsOTPValid(randomNumber === 1);
    if (randomNumber === 1) {
      setIsOTPFormSuccess(true);
    }
  };

  React.useEffect(() => {
    setIsOTPValid(true);
    setIsOTPFormSuccess(false);
  }, [otp]);

  // Select storefront Form
  const [items, setItems] = React.useState(JSON.parse(demoData));
  const isStorefrontFormSuccess = items.some((item) => item.checked);

  React.useEffect(() => {
    if (!isCredentialFormSuccess) {
      setOtp('');
      setItems(JSON.parse(demoData));
    }
  }, [isCredentialFormSuccess]);

  // Integrate Form
  const canIntegrate = isCredentialFormSuccess && isOTPFormSuccess && isStorefrontFormSuccess;
  const onIntegrateSubmit = () => {
    console.log({
      marketplace,
      country,
      username,
      password,
      otp,
      items,
    });
    alert('Processing...');
  };

  return {
    marketplace,
    setMarketplace,
    country,
    setCountry,
    username,
    setUsername,
    password,
    setPassword,
    otp,
    setOtp,
    isCredentialFormBtnDisabled,
    onValidateCredential,
    isPasswordValid,
    onValidateOtp,
    isOTPValid,
    items,
    setItems,
    canIntegrate,
    onIntegrateSubmit,
    isCredentialFormSuccess,
    isOTPFormSuccess,
    isOTPFormBtnDisabled,
  };
};
