import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Comment = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.9584 11.9692H5.00895L3.26387 13.7143C2.81454 14.1636 2.04688 13.8453 2.04688 13.2094V4.03388C2.04688 3.48424 2.49206 3.03906 3.04169 3.03906H12.9592C13.5088 3.03906 13.954 3.48424 13.954 4.03388V10.9744C13.9532 11.5232 13.508 11.9692 12.9584 11.9692ZM4.52725 6.01523H11.4727C11.7471 6.01523 11.9693 5.79305 11.9685 5.51948C11.9685 5.2459 11.7471 5.02373 11.4727 5.02373H4.52725C4.25367 5.02373 4.03149 5.24507 4.03149 5.51948C4.03149 5.79305 4.25284 6.01523 4.52725 6.01523ZM8.49574 7.99989H4.52725C4.25284 7.99989 4.03149 7.77771 4.03149 7.50414C4.03149 7.22974 4.25367 7.00839 4.52725 7.00839H8.49657C8.77098 7.00839 8.99232 7.23056 8.99232 7.50414C8.99232 7.77771 8.77015 7.99989 8.49574 7.99989ZM4.52725 9.98456H10.4804C10.7548 9.98456 10.977 9.76238 10.9762 9.48881C10.9762 9.21523 10.7548 8.99305 10.4804 8.99305H4.52725C4.25367 8.99305 4.03149 9.2144 4.03149 9.48881C4.03149 9.76238 4.25284 9.98456 4.52725 9.98456Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Comment;
