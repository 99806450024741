import { NodeEditContext, useLog, usePage, eipRequest, aim } from '@eip/next/lib/main';
import { useWorkflow as usePageContext, useWorkflowScreen } from '@ep/insight-ui/system/backbone/workflow-backbone';
import FormCampaignInfo, {
  IDisableArr,
} from '@ep/insight-ui/elements/input-form/form-templates/form-groups/campaign-details/campaign-setting-form';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';
import { CampaignTableFactory } from '@ep/insight-ui/system/workflow/camp0-detail/campaign-table-factory';
import { CampaignTableInit } from '@ep/insight-ui/system/workflow/camp0-detail/campaign-table-init';
import {
  createAdsObjects,
  createCampaign,
  transformCellCompactSelections,
  mappingDimensionOptions,
  getDimensionDefinition,
  updateAdObjectMethod,
  updateCampaign,
  updateCampaignTimeline,
} from '@ep/insight-ui/system/workflow/campaign-details/legacy/api-request-campaign-details';
import { getCampaignSettingInfo } from '@ep/insight-ui/system/workflow/camp0-detail/legacy/api-request-campaign-details';
import { Box, makeStyles } from '@material-ui/core';
import { debounce, get, isEqual, uniqueId, startCase, lowerCase, set, cloneDeep } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import * as request from '../../backbone/data-source/common';
import { HelmetMetaData } from '@ep/insight-ui/elements/seo-settings/helmet-data';
import { Provider } from 'jotai';
import { PersonalizationAtom } from '../../backbone/atom/campaign-detail';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { MonitorContainer } from '../../util/monitor/container';
import Camp0DetailConfiguration from './configuration';
import EditorView from './editor-view';
import CampaignActions from '../../workflow/camp0-detail/campaign-actions';
import produce from 'immer';

type Options = Omit<React.ComponentProps<typeof FormCampaignInfo>, 'value'>;

const log = useLog('chartlib:campaign-creator');
const mapOptionItem = (i: { value: any; label: string; payload?: any }) => {
  return {
    label: i.label,
    value: i.value,
    payload: i.payload,
  };
};

function mapOptionItemNext(i: { id: any; text: string; payload?: any }) {
  return {
    label: i.text,
    value: i.id,
    payload: i.payload,
  };
}

class Camp0CreatorChartlib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData) {
    const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
    const chartNodeData = Object.assign({ customAttributes: {} }, data);

    const onMainTableUpdateConfig = React.useCallback((config) => {
      const personalization = get(data, ['customAttributes', EIP_CONSTANT.PERSONALIZATION], {});
      nodeEditContext.onUpdateCustomAttributes(data, { personalization: { ...personalization, ...config } });
    }, []);

    return (
      <NotificationGlobalProvider>
        <MonitorContainer component="div" mId={data.blockEid} mLabel={'Campaign details'}>
          <Provider
            initialValues={[[PersonalizationAtom, get(data, `customAttributes.${EIP_CONSTANT.PERSONALIZATION}`, {})]]}
          >
            <CampaignCreator
              onMainTableUpdateConfig={onMainTableUpdateConfig}
              nodeData={chartNodeData}
              systemConfig={data._system}
            ></CampaignCreator>
          </Provider>
        </MonitorContainer>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <Camp0DetailConfiguration data={data} onSubmit={handleSubmit} key={nodeId} nodeId={nodeId} />;
  }
}

export default {
  blockType: 'tempCamp0Creator',
  label: 'Camp0 Creator',
  blockComponent: Camp0CreatorChartlib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
    h: 12,
  },
};
const CONSTANT_VALUES = {
  adObjectMethod: {
    MANUAL: 'MANUAL_OPERATION',
    Botep: 'EPSILO_OPERATION',
    MARKETPLACE: 'COMBINED_OPERATION',
  },
  objective: {
    sale: 'SALE',
    traffic: 'TRAFFIC',
  },
  marketplaceCode: {
    SHOPEE: 'SHOPEE',
    LAZADA: 'LAZADA',
    TOKOPEDIA: 'TOKOPEDIA',
  },
  status: {
    DRAFT: 'DRAFT',
    PUBLISHING: 'PUBLISHING',
    SCHEDULED: 'SCHEDULED',
    ONGOING: 'ONGOING',
    PAUSED: 'PAUSED',
    SUSPENDED: 'SUSPENDED',
    RECOVERING: 'RECOVERING',
    ENDED: 'ENDED',
  },
};
const rangeAdObjectMethod = [
  CONSTANT_VALUES.adObjectMethod.MANUAL,
  CONSTANT_VALUES.adObjectMethod.Botep,
  CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
];
const disabledAdObjectMethod = {
  [CONSTANT_VALUES.marketplaceCode.SHOPEE]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
  AD_TOOL: {
    SHP_PRODUCT_ADS: [CONSTANT_VALUES.adObjectMethod.Botep],
  },
  [CONSTANT_VALUES.marketplaceCode.LAZADA]: [CONSTANT_VALUES.adObjectMethod.Botep],
  [CONSTANT_VALUES.marketplaceCode.TOKOPEDIA]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
};

const mappingKeys = {
  dailyBudget: 'daily_budget',
  totalBudget: 'total_budget',
  objective: 'objective',
  adObjectMethod: 'automated_ads_select_product',
  firstSearchSlot: 'first_search_slot',
  'schedule.dateFrom': 'timeline_from',
  'schedule.dateTo': 'timeline_to',
  maxBiddingPrice: 'max_bidding_price',
};

const newMappingKeys = {
  allowScriptEndTimeline: 'allow_script_end_timeline',
  allowScriptPauseBudget: 'allow_script_pause_budget',
  numberOfAdsObjects: 'conf_number_ads_object',
};
const mappingValues = {
  COMBINED_OPERATION: '1',
  MANUAL_OPERATION: '0',
};

const CampaignCreator = ({ onMainTableUpdateConfig, nodeData, data, systemConfig }) => {
  const [initialRows, setInitialRows] = React.useState([]);
  const [adsCampaignId, setAdsCampaignId] = React.useState<string | number>(null);
  const isEpsilo = aim.canAccess('internal');
  const [campaignInfo, setCampaignInfo] = React.useState({
    storefrontIds: [],
    campaignId: 0,
    marketplaceIds: [],
    schedule: { dateFrom: null, dateTo: null },
    totalBudget: 0,
    dailyBudget: 0,
    bidLimit: 0,
    objective: 'SALE',
    toolCode: null,
    status: 'DRAFT',
    adObjectMethod: CONSTANT_VALUES.adObjectMethod.MANUAL,
    campaignName: '',
    ...(isEpsilo ? { allowScriptEndTimeline: '1', allowScriptPauseBudget: '1', numberOfAdsObjects: '40' } : {}),
  });
  const campaignInfoRef = React.useRef({});
  const [initialSetting, setInitialSetting] = React.useState(null);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(-1);
  const [currentStatus, setCurrentStatus] = React.useState('DRAFT');
  const [firstLoaded, setFirstLoaded] = React.useState(false);
  const refBackbone = React.useRef(null);

  const publishCampaign = (id) => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_HA + '/api/auth/ads-campaign/publish', {
        newValues: {
          id,
        },
      })
      .then((res) => {
        if (res.success || res.code == 200) {
          refBackbone.current.reloadData('table');
          onToastMultiple({
            messages: [],
            title: 'Update campaign successfully',
            variant: 'success',
          });
        } else {
          onToastMultiple({
            messages: [res.message],
            title: 'Update campaign failed',
            variant: 'error',
          });
        }
        return res;
      })
      .catch((e) => {
        onToastMultiple({
          messages: typeof e.message == 'string' ? [e.message] : [],
          title: 'Update campaign failed',
          variant: 'error',
        });
        return {
          success: false,
          message: e.message,
        };
      });
  };
  const updateCampaign = (payload) => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/campaign-management/mass-upsert-ads-unit', payload)
      .then((res) => {
        if (res.success) {
          refBackbone.current.reloadData('table');
          onToastMultiple({
            messages: [],
            title: 'Update campaign successfully',
            variant: 'success',
          });
        } else {
          let errorMessage = [];
          if (Array.isArray(res.data)) {
            const errorMessageObj = res.data.filter((i) => i.success == false);
            errorMessage = errorMessageObj.reduce((carry, i) => {
              Object.values(i.error || {}).forEach((msg) => carry.push(msg));
              return carry;
            }, []);
          }
          if (errorMessage.length == 0) {
            errorMessage = [res.message];
          }
          if (errorMessage.length == 0) {
            errorMessage = get(res, ['data'], []).map((el) => el.message);
          }
          onToastMultiple({
            messages: errorMessage,
            title: 'Update campaign failed',
            variant: 'error',
          });
        }
        return res;
      })
      .catch((e) => {
        onToastMultiple({
          messages: [],
          title: 'Update campaign failed',
          variant: 'error',
        });
        return {
          success: false,
          message: e.message,
        };
      });
  };

  const updateNewCampaign = (payload) => {
    return eipRequest
      .post(EIP_CONSTANT.API_HOST.API_HA + '/api/auth/ads-unit/modify-attribute', payload)
      .then((res) => {
        if (res.success) {
          refBackbone.current.reloadData('table');
          onToastMultiple({
            messages: [],
            title: 'Update campaign successfully',
            variant: 'success',
          });
        } else {
          let errorMessage = Object.values(get(res, ['data', 'result'], {}))
            .map((i) => i?.message)
            .filter((i) => !!i);

          if (errorMessage.length == 0) {
            errorMessage = [res.message];
          }
          if (errorMessage.length == 0) {
            errorMessage = get(res, ['data'], []).map((el) => el.message);
          }
          onToastMultiple({
            messages: errorMessage,
            title: 'Update campaign failed',
            variant: 'error',
          });
        }
        return res;
      })
      .catch((e) => {
        onToastMultiple({
          messages: [],
          title: 'Update campaign failed',
          variant: 'error',
        });
        return {
          success: false,
          message: e.message,
        };
      });
  };

  const [loadingStatus, setLoadingStatus] = React.useState<{ loading: boolean; error: boolean }>({
    loading: true,
    error: false,
  });

  const { isEditMode } = React.useContext<NodeEditContext>(NodeEditContext);

  const requestOptions = {
    getCountry: (forceReload) => request.default.getCountry({ forceReload }),
    getMarketplace: (forceReload) => request.default.getMarketplace({ forceReload }),
    getStorefront: (forceReload) => request.default.getStorefront({ forceReload }),
  };
  // Disable to edit campaign name in Campaign detail page for campaign status is not Draft
  React.useEffect(() => {
    if (
      campaignInfo &&
      campaignInfo.marketplaceIds.includes('SHOPEE') &&
      campaignInfo.toolCode === 'SHP_SA' &&
      campaignInfo.status !== 'DRAFT'
    ) {
      document.querySelector('.app-controls').style.pointerEvents = 'none';
    } else {
      document.querySelector('.app-controls').style.pointerEvents = null;
    }
  }, [campaignInfo]);

  const { title, updateTitle } = usePage();
  const screen = useWorkflowScreen('campaign_info');
  const pageContext = usePageContext();
  const { onToastMultiple } = useToast();

  const fastRef = React.useRef({
    marketplaceCode: null,
    isScreenReady: false,
    campaignInfo: campaignInfo,
    title: null,
    campaignIdWithEmptyData: false,
    adsCalendarId: null,
    previousCampaignInfo: campaignInfo,
  });

  // log('currentPage', currentPage);

  const [options, setOptions] = React.useState<Options>({
    marketplace: [],
    adType: [],
    country: [],
    storefront: [],
    status: [],
    objective: [],
    adObjectMethod: [],
    disabledArr: [],
  });

  const debounceUpdateCampaign = React.useRef(debounce((payload) => updateCampaign(payload), 1000));

  React.useEffect(() => {
    fastRef.current.title = title;
    setInitialRows((rows) => {
      return rows.map((row) => {
        return {
          ...row,
          ['ads_campaigns.name']: title,
        };
      });
    });
    if (
      campaignInfo.campaignId &&
      campaignInfo.campaignId != 0 &&
      title &&
      title !== campaignInfoRef.current[`ads_campaigns.name`]
    ) {
      const payload = {
        data: [
          {
            ads_unit_type: 'AdsCampaign',
            id: campaignInfo.campaignId,
            attributes: [
              {
                field_name: 'name',
                value: title,
              },
            ],
          },
        ],
      };
      set(campaignInfoRef.current, [`ads_campaigns.name`], title);
      debounceUpdateCampaign.current(payload);
    }
  }, [title]);

  const onPublish = () => {
    if (String(campaignInfo?.toolCode).startsWith('SHP') && isEpsilo) {
      return publishCampaign(campaignInfo.campaignId).then((res) => {
        if (res.success) {
          set(campaignInfoRef.current, [`ads_campaigns.status`], 'ONGOING');
          setCampaignInfo((prevValue) => {
            return {
              ...prevValue,
              status: 'ONGOING',
            };
          });
          setOptions((prev) => {
            return {
              ...prev,
              disabledArr: [].concat(prev.disabledArr || []).concat('ad-object-method'),
            };
          });
        }
      });
    }
    const payload = {
      data: [
        {
          ads_unit_type: 'AdsCampaign',
          id: campaignInfo.campaignId,
          attributes: [
            {
              field_name: 'status',
              value: 'PUBLISHING',
            },
          ],
        },
      ],
    };

    return updateCampaign(payload).then((res) => {
      if (res.success) {
        set(campaignInfoRef.current, [`ads_campaigns.status`], 'ONGOING');
        setCampaignInfo((prevValue) => {
          return {
            ...prevValue,
            status: 'ONGOING',
          };
        });
        setOptions((prev) => {
          return {
            ...prev,
            disabledArr: [].concat(prev.disabledArr || []).concat('ad-object-method'),
          };
        });
      }
    });
  };

  React.useEffect(() => {
    if (screen) {
      screen
        .init()
        .then(({ marketplace, adType, country, storefront, adsCampaignId, status, marketplaceCode }) => {
          setOptions({
            marketplace: marketplace.map(mapOptionItem),
            adType: adType.map(mapOptionItem),
            country: country.map(mapOptionItem),
            storefront: storefront.map(mapOptionItem),
            status: status.map(mapOptionItem),
            adObjectMethod: [],
          });
          fastRef.current.marketplaceCode = marketplaceCode;

          if (!adsCampaignId || adsCampaignId.indexOf('default') === 0) {
            setInitialSetting(campaignInfo);
            fastRef.current.isScreenReady = true;
          } else {
            setAdsCampaignId(adsCampaignId);
          }
          setLoadingStatus({
            ...loadingStatus,
            loading: false,
          });
        })
        .catch(() => {
          setLoadingStatus({ ...loadingStatus, loading: false, error: true });
        });
    } else {
      console.info('no workflow screen found');
      setLoadingStatus({ ...loadingStatus, loading: false });
    }
  }, []);

  React.useEffect(() => {
    const haveOptions = Object.values(options).some((v) => v.length > 0);
    if (haveOptions && adsCampaignId) {
      loadCampaignDetail(adsCampaignId, fastRef.current.marketplaceCode);
    }
  }, [options, adsCampaignId]);

  // FIXME: separating the campaign details submit
  React.useEffect(() => {
    if (!adsCampaignId) return;
  }, [lastUpdatedAt, adsCampaignId]);

  const loadCampaignDetail = async (campaignId, marketplaceCode = 'SHOPEE') => {
    const setting: any = await getCampaignSettingInfo({ campaignId, marketplaceCode });
    const totalBudget = get(setting, 'ads_campaigns.total_budget', '') || '';
    const dailyBudget = get(setting, 'ads_campaigns.daily_budget', '') || '';
    if (setting) {
      const info = {
        ...setting,
        campaignId,
        marketplaceIds: [].concat(get(setting, 'marketplace.code', [])),
        countryIds: [].concat(get(setting, 'country.code', [])),
        storefrontIds: [].concat(get(setting, 'storefront.id', 0).toString()),
        adTypeIds: [].concat(get(setting, 'ADTYPE.ads_type', '').toLowerCase()),
        adObjectMethod:
          get(setting, 'ads_campaigns.automated_ads_select_product', 0) == 1
            ? CONSTANT_VALUES.adObjectMethod.MARKETPLACE
            : CONSTANT_VALUES.adObjectMethod.MANUAL,
        objective: get(setting, 'ads_campaigns.objective', ''),
        schedule: {
          dateFrom: get(setting, 'ads_campaigns.timeline_from', moment().format('YYYY-MM-DD')),
          dateTo: get(setting, 'ads_campaigns.timeline_to', ''),
        },
        totalBudget: String(totalBudget),
        dailyBudget: String(dailyBudget),
        status: get(setting, 'ads_campaigns.status', ''),
        bidLimit: 0,
        toolCode: get(setting, 'ads_tool.code', null),
        campaignName: get(setting, 'ads_campaigns.name', ''),
        firstSearchSlot: get(setting, 'ads_campaigns.first_search_slot'),
        maxBiddingPrice: get(setting, 'ads_campaigns.max_bidding_price', 0),
        storefrontSid: get(setting, 'storefront.storefront_sid', 0),
        ...(isEpsilo
          ? {
              allowScriptPauseBudget: get(setting, 'ads_campaigns.allow_script_pause_budget', '1'),
              allowScriptEndTimeline: get(setting, 'ads_campaigns.allow_script_end_timeline', '1'),
              numberOfAdsObjects: get(setting, 'ads_campaigns.conf_number_ads_object', '40'),
            }
          : {}),
      };
      campaignInfoRef.current = setting;
      const pageTitle = get(setting, 'ads_campaigns.name', '');
      updateTitle(pageTitle);
      setCampaignInfo(info);
      setInitialSetting(info);
      setCurrentStatus(info.status);
      fastRef.current.isScreenReady = true;
      fastRef.current.campaignInfo = info;
      fastRef.current.previousCampaignInfo = info;
      fastRef.current.adsCalendarId = get(setting, 'ADS_CALENDAR.id', '');
      const row = { ...setting, campaignId: campaignId };
      const transformedRows = transformCellCompactSelections([row], info.schedule.dateFrom, info.schedule.dateTo);
      setInitialRows(transformedRows);
    } else {
      // Add more error case
      setLoadingStatus({
        loading: false,
        error: true,
      });
    }
  };

  const checkToRecreateCampaign = (obj1: any, obj2: any) => {
    const mainFields = ['marketplaceIds', 'countryIds', 'storefrontIds' /* 'adTypeIds' */];
    const mainObj1 = {};
    const mainObj2 = {};
    mainFields.forEach((field) => {
      mainObj1[field] = obj1[field];
      mainObj2[field] = obj2[field];
    });
    return !isEqual(mainObj1, mainObj2);
  };

  const transformValue = (key, value) => {
    if (key === 'timeline_from' && value) return moment(value).format('YYYY-MM-DD 00:00:00');
    if (key === 'timeline_to' && value) return moment(value).format('YYYY-MM-DD 23:59:59');
    return value;
  };

  const handleChangeCampaignProperties = React.useCallback(
    debounce(async (setting: any) => {
      // FIXME: not work, campaignid would be 0
      if (!firstLoaded) setFirstLoaded(true);
      log('Change setting', setting);
      const adToolCode = get(initialRows, [0, 'ad_tool', 'currentFocusItem', 'ADTOOL.ads_tool']);
      const newSetting = {
        ...campaignInfo,
        ...setting,
        campaignId: campaignInfo.campaignId,
        toolCode: campaignInfo.toolCode,
      };

      if (!isEqual(newSetting.storefrontIds, fastRef.current.campaignInfo.storefrontIds)) {
        const storefront = options.storefront.find((i) => i.value === newSetting.storefrontIds[0]);
        newSetting.marketplaceIds = [storefront.payload.channel.id];
        newSetting.countryIds = [storefront.payload.country.code];
      }

      const marketplaceCode = get(newSetting, ['marketplaceIds', 0], '');
      fastRef.current.marketplaceCode = marketplaceCode;

      if (newSetting && !isEqual(newSetting.marketplaceIds, initialSetting ? initialSetting.marketplaceIds : [])) {
        const dimension = 'ADS_CAMPAIGN';

        let allValues = {};
        try {
          const res_Dimension = await getDimensionDefinition({ dimension, marketplaceCode });
          if (res_Dimension.success) {
            allValues = get(res_Dimension, 'data.data.value', {});
          }
        } catch (error) {
          allValues = {};
        }

        // here handle default data with marketplaceCode
        let disabledArr: IDisableArr[] = [];
        newSetting.adObjectMethod =
          get(setting, 'ads_campaigns.automated_ads_select_product', 0) == 1
            ? CONSTANT_VALUES.adObjectMethod.MARKETPLACE
            : CONSTANT_VALUES.adObjectMethod.MANUAL;
        //edit schedule
        if (
          ff.user_cannot_extend_campaign_timeline
            ? [CONSTANT_VALUES.status.PUBLISHING, CONSTANT_VALUES.status.RECOVERING].includes(campaignInfo.status)
            : [
                CONSTANT_VALUES.status.ENDED,
                CONSTANT_VALUES.status.PUBLISHING,
                CONSTANT_VALUES.status.RECOVERING,
              ].includes(campaignInfo.status)
        ) {
          disabledArr = ['schedule'];
        }

        // edit budget
        if (campaignInfo.status == CONSTANT_VALUES.status.ENDED) {
          disabledArr.push('total-budget');
          disabledArr.push('daily-budget');
          disabledArr.push('allow-script-end');
          disabledArr.push('allow-script-pause');
        }

        //edit ad-object-method
        if (campaignInfo.status !== 'DRAFT') {
          disabledArr.push('ad-object-method');
          disabledArr.push('number-of-ads-objects');
        }

        if (marketplaceCode != CONSTANT_VALUES.marketplaceCode.LAZADA) {
          disabledArr.push('objective');
        }

        // here handle fetch data options from server
        const adObjectMethods = mappingDimensionOptions({
          values: allValues['operation_method'],
          keyPropertyDimension: 'operation_method',
        });
        const objectives = mappingDimensionOptions({
          values: allValues['objective'],
          keyPropertyDimension: 'objective',
        });

        const adObjectOps = [];
        rangeAdObjectMethod.map((id) => {
          const el = adObjectMethods.find((i) => i.id == id);
          if (el) {
            adObjectOps.push({
              label: el.text.includes('MARKETPLACE')
                ? el.text.replace('MARKETPLACE', startCase(lowerCase(marketplaceCode)))
                : el.text,
              value: el.id,
              disabled:
                get(disabledAdObjectMethod, [marketplaceCode], []).includes(el.id) &&
                (get(disabledAdObjectMethod, ['AD_TOOL', campaignInfo.toolCode], null)
                  ? get(disabledAdObjectMethod, ['AD_TOOL', campaignInfo.toolCode], []).includes(el.id)
                  : true),
            });
          }
        });

        setOptions({
          ...options,
          objective: objectives.map(mapOptionItemNext),
          adObjectMethod: adObjectOps,
          disabledArr,
        });
      }
      setInitialSetting(newSetting);
      setCampaignInfo(newSetting);
      const prevSetting = fastRef.current.previousCampaignInfo;
      const isChange = !isEqual(prevSetting, newSetting);
      const ignoredFields = ['rawValue'];
      const diffs = Object.entries(prevSetting).reduce((carry, [key, value]) => {
        if (ignoredFields.includes(key)) return carry;
        const newSettingValue = newSetting[key];
        if (newSettingValue && typeof newSettingValue === 'object') {
          Object.entries(newSettingValue).forEach(([k, v]) => {
            const finalKey = [key, k].join('.');
            const finalValue = mappingValues[v] ? mappingValues[v] : v;
            if (!isEqual(value, newSettingValue)) {
              carry[finalKey] = finalValue;
            }
          });
        } else {
          const finalValue = mappingValues[newSettingValue] ? mappingValues[newSettingValue] : newSettingValue;
          if (value != newSettingValue) {
            set(carry, [key], finalValue);
          }
        }
        set(fastRef.current, ['previousCampaignInfo', key], newSettingValue);

        return carry;
      }, {});

      if (isChange) {
        fastRef.current.campaignInfo = newSetting;
      }
      const row = getBasisRow(newSetting);
      if (Object.keys(diffs).length > 0) {
        const attributes = Object.entries(diffs).reduce((carry, [key, value]) => {
          const mappedKey = mappingKeys[key];
          if (mappedKey) {
            const finalValue = transformValue(mappedKey, value);
            campaignInfoRef.current = produce(campaignInfoRef.current, (draft) => {
              set(draft, [`ads_campaigns.${mappedKey}`], value);
            });
            carry.push({
              field_name: mappedKey,
              value: finalValue,
            });
          }
          return carry;
        }, []);
        if (attributes.length) {
          const payload = {
            data: [
              {
                ads_unit_type: 'AdsCampaign',
                id: campaignInfo.campaignId,
                attributes,
              },
            ],
          };
          if (campaignInfo?.campaignId && campaignInfo?.campaignId != 0) {
            updateCampaign(payload);
          }
        }
        if (aim.canAccess('internal')) {
          const newAttributes = Object.entries(diffs).reduce((carry, [key, value]) => {
            const mappedKey = newMappingKeys[key];
            if (mappedKey) {
              const finalValue = transformValue(mappedKey, value);
              campaignInfoRef.current = produce(campaignInfoRef.current, (draft) => {
                set(draft, [`ads_campaigns.${mappedKey}`], value);
              });
              carry.push({
                dimension: 'AdsCampaign',
                id: campaignInfo.campaignId,
                attribute: mappedKey,
                value: finalValue,
              });
            }
            return carry;
          }, []);
          if (newAttributes.length) {
            const payload = {
              newValues: {
                data: newAttributes,
              },
            };
            if (campaignInfo?.campaignId && campaignInfo?.campaignId != 0) {
              updateNewCampaign(payload);
            }
          }
        }
      }
      setInitialRows([row]);
    }, 300),
    [campaignInfo, options],
  );

  const getBasisRow = (setting) => {
    const marketplaceCode = get(setting, ['marketplaceIds', 0], null);
    const countryCode = get(setting, ['countryIds', 0], null);
    const storefrontId = get(setting, ['storefrontIds', 0], null);
    const adTypeId = get(setting, ['adTypeIds', 0], null);
    const marketplace = options.marketplace.find((i) => i.value === marketplaceCode);
    const country = options.country.find((i) => i.value === countryCode);
    const storefront = options.storefront.find((i) => i.value === storefrontId);
    const adType = options.adType.find((i) => i.value === adTypeId);
    const row = {
      'marketplace.code': marketplaceCode,
      'marketplace.name': marketplace ? marketplace.label : '',
      'country.code': countryCode,
      'country.name': country ? country.label : '',
      'storefront.id': storefrontId,
      'storefront.name': storefront ? storefront.label : '',
      'ads_campaigns.timeline_to': get(setting, ['schedule', 'dateTo'], null),
      'ads_campaigns.timeline_from': get(setting, ['schedule', 'dateFrom'], ''),
      'ads_campaigns.daily_budget': String(get(setting, 'dailyBudget') || 0),
      'ads_campaigns.total_budget': String(get(setting, 'totalBudget') || 0),
      'ads_campaigns.name': fastRef.current.title,
      'ads_campaigns.objective': get(setting, 'objective'),
      'ads_campaigns.automated_ads': String(
        get(setting, ['adObjectMethod'], 'MANUAL_OPERATION') === 'MANUAL_OPERATION' ? 0 : 1,
      ),
      ...(isEpsilo
        ? {
            'ads_campaigns.allow_script_end_timeline': String(get(setting, ['allowScriptEndTimeline'], '1')),
            'ads_campaigns.allow_script_pause_budget': String(get(setting, ['allowScriptPauseBudget'], '1')),
            'ads_campaigns.conf_number_ads_object': String(get(setting, ['numberOfAdsObjects'], '40')),
          }
        : {}),
      adsOpsShpAdsCampaignId: 0,
      eipCustomRowId: uniqueId('eip_custom_row_'),
    };
    return row;
  };

  const createDraftCampaign = async (setting: any, rows: any[]) => {
    log('createdraftcampaign', setting);
    const toolCode = get(setting, 'toolCode', null);
    let timelineTo = get(setting, ['schedule', 'dateTo'], null);
    if (timelineTo) {
      timelineTo = moment(timelineTo, EIP_CONSTANT.DATE_FORMAT)
        .set({ hour: 23, minute: 59, second: 59 })
        .format(EIP_CONSTANT.DATETIME_FORMAT);
    }
    const param: any = {
      data: {
        name: fastRef.current.title,
        storefrontId: get(setting, ['storefrontIds', 0], null),
        toolCode: toolCode,
        totalBudget: get(setting, 'totalBudget') || 0,
        dailyBudget: get(setting, 'dailyBudget') || 0,
        countryCode: get(setting, ['countryIds', 0]),
        adsCalendars: [
          {
            timelineFrom: get(setting, ['schedule', 'dateFrom'], moment().format('YYYY/MM/DD')),
            timelineTo,
          },
        ],
      },
      marketplaceCode: get(setting, ['marketplaceIds', 0], null),
    };

    if (get(setting, ['marketplaceIds', 0], null) === 'LAZADA') {
      param.data.objective = get(setting, ['objective'], 'SALE');
      // param.data.automated_ads = get(setting, ['adObjectMethod'], 'MANUAL_OPERATION') === 'MANUAL_OPERATION' ? 0 : 1;
      param.data.automated_ads_select_product =
        get(setting, ['adObjectMethod'], 'MANUAL_OPERATION') === 'MANUAL_OPERATION' ? 0 : 1;
      param.data.firstSearchSlot = get(setting, ['firstSearchSlot']) ? Number(get(setting, ['firstSearchSlot'])) : null;
    }

    try {
      const response = await createCampaign(param);
      if (response.success) {
        const campaignId = get(response, ['data', 'id'], 0);
        const mkplace = get(setting, ['marketplaceIds', 0], null);
        pageContext.data.set('entityQuery', { marketplace: mkplace });
        pageContext.data.set('entityId', campaignId);
        setAdsCampaignId(campaignId);
        if (toolCode === 'SHP_SA') {
          await createShopAdObject(campaignId, setting);
        }
        await loadCampaignDetail(campaignId, mkplace);
      } else {
        const message = Object.values(response.error).join('\n');
        throw Error('Create draft campaign failed!\n' + message);
      }
    } catch (error) {
      log('[Error] createCampaign', error);
      alert(error.message);
      setInitialRows((rows) => {
        const rollbackRows = rows.map((i) => ({ ...i, ad_tool: { ...i.ad_tool, selectedItems: [] } }));
        return rollbackRows;
      });
    }
  };

  const createShopAdObject = async (campaignId: string, setting: any) => {
    const addAdsObjectParam = {
      marketplaceCode: get(setting, ['marketplaceIds', 0], null),
      data: [
        {
          masterObjectId: get(setting, ['storefrontIds', 0], null),
          masterObjectType: 'STOREFRONT',
          countryCode: get(setting, ['countryIds', 0]),
          storefrontId: get(setting, ['storefrontIds', 0], null),
          toolCode: get(setting, ['toolCode', 0], null),
          type: get(setting, 'ADTYPE.ads_type', ''),
          tagline: 'Shop',
          adsCampaignId: campaignId,
          totalBudget: 0,
          dailyBudget: 0,
          adsOpsAdsCalendars: [],
        },
      ],
    };

    const res = await createAdsObjects(addAdsObjectParam);
    if (!res.success) {
      onToastMultiple({
        title: 'Error',
        variant: 'error',
        messages: Object.values(res.data[0].error),
      });
    }
  };

  const handleChangeCampaignTable = (items, type) => {
    console.trace();
    log('handleChange', items);

    const adToolCode = get(items, [0, 'ADTOOL.ads_tool']);
    if (type === 'ad_tool' && adToolCode !== fastRef.current.campaignInfo.toolCode && adToolCode) {
      createDraftCampaign({ ...fastRef.current.campaignInfo, toolCode: adToolCode }, items);
    } else {
      setLastUpdatedAt(Date.now());
    }
  };

  if (isEditMode) {
    return <EditorView nodeData={nodeData} />;
  }

  return (
    <LoadingComponent
      loading={(loadingStatus.loading || !initialSetting) && !loadingStatus.error}
      error={loadingStatus.error}
    >
      <Box>
        {campaignInfo.marketplaceIds[0] && (
          <HelmetMetaData
            title={`Campaign details/${campaignInfo.marketplaceIds[0]}/${title}`}
            description="Epsilo is an industry-leading platform for e-commerce teams to grow revenue with automation, research, and collaboration"
          ></HelmetMetaData>
        )}
        <FormCampaignInfo
          value={initialSetting}
          onChange={handleChangeCampaignProperties}
          disabledChooseStartDate={
            ff.user_cannot_extend_campaign_timeline
              ? [
                  CONSTANT_VALUES.status.ONGOING,
                  CONSTANT_VALUES.status.PAUSED,
                  CONSTANT_VALUES.status.SUSPENDED,
                  CONSTANT_VALUES.status.ENDED,
                ].includes(currentStatus)
              : [
                  CONSTANT_VALUES.status.ONGOING,
                  CONSTANT_VALUES.status.PAUSED,
                  CONSTANT_VALUES.status.SUSPENDED,
                ].includes(currentStatus)
          }
          requestOptions={requestOptions}
          {...options}
        />
        {!adsCampaignId && (
          <CampaignTableInit
            nodeData={nodeData}
            systemConfig={systemConfig}
            campaignInfo={campaignInfo}
            onMainTableUpdateConfig={onMainTableUpdateConfig}
            initialRows={initialRows}
            campaignInfoRef={campaignInfoRef}
          />
        )}
        {adsCampaignId && (
          <>
            <CampaignTableFactory
              nodeData={nodeData}
              systemConfig={systemConfig}
              campaignInfo={campaignInfo}
              onMainTableUpdateConfig={onMainTableUpdateConfig}
              refBackbone={refBackbone}
              campaignInfoRef={campaignInfoRef}
            />
            <CampaignActions campaignInfo={campaignInfo} onPublish={onPublish} />
          </>
        )}
      </Box>
    </LoadingComponent>
  );
};
