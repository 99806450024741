import * as React from 'react';
import Gradient from 'javascript-color-gradient';

import {
  Grid,
  Divider,
  TextField,
  ListItemIcon,
  Button,
  RadioGroup,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { OptionSelectType } from '../list-control/type';
import Typography from '../text-style/Typography';
import SelectSearchFormIcon from '../form-control/select-form/select-search-form-icon';
import Dropdown from '../dropdown-menu/dropdown';
import MenuList, { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import Icon from '@ep/insight-ui/icons/Icon';

import RadioItem from './control-group';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';

import FlexBox, { FlexBoxItem } from '../share-with-collaborators/Flexbox';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { get } from 'lodash';
import SelectMultipleForm from '../form-control/select-form/select-multiple-form';
import { SortValue } from './data-demo';
import moment from 'moment';
import TextFieldWithFormula from '../textField/text-field-with-formula';
import {
  DEFAULT_DATETIME_FORMAT_FORMULA,
  DEFAULT_GRADIENT_MID_POINT,
  EPSILO_BLUE_COLOR,
} from '@ep/insight-ui/system/helper/constant';

const useStyle = makeStyles(() => ({
  root: {
    ...(ff.reuse_our_chart ? {} : { width: '240px' }),
    padding: '5px',
    boxSizing: 'border-box',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    padding: '12px',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    '& h6': {
      lineHeight: '1.35',
    },
  },
  titltFormat: {
    marginBottom: '10px',
  },
  'mb-4': {
    marginBottom: '4px',
  },
  'mb-16': {
    marginBottom: '16px',
  },
  'mb-14': {
    marginBottom: '14px',
  },
  'mb-6': {
    marginBottom: '6px',
  },
  'mb-0': {
    marginBottom: '0px !important',
  },
  'mr-2': {
    marginRight: '2px',
  },
  'ml-2': {
    marginLeft: '2px',
  },
  'mx-2': {
    marginLeft: '2px',
    marginRight: '2px',
  },
  button: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 'auto',
    padding: '2px 4px',
    '& span.eip1-MuiButton-endIcon': {
      marginLeft: 'auto',
    },
    '& span.eip1-MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 12,
    border: '2px solid #C2C7CB',
    backgroundColor: 'white',
  },
  checkedIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: '#006EC6',
    borderColor: '#006EC6',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      backgroundColor: 'white',
      content: '""',
      transform: 'translate(50%, 50%)',
      borderRadius: '50%',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  select: {
    '& .eip1-MuiInputBase-root': {
      padding: '6px 8px',
      '& input': {
        padding: '0px !important',
        height: '20px',
        color: colors.text.default,
      },
      '& .eip1-MuiAutocomplete-endAdornment': {
        top: '50%',
        color: colors.icon.default,
      },
      '& .eip1-MuiButtonBase-root': {
        marginRight: '-8px',
        padding: '8px',
        backgroundColor: 'transparent',
        color: colors.icon.default,
        '& .eip1-MuiIconButton-label': {
          width: '16px',
          height: '16px',
          '& .eip1-MuiSvgIcon-root': {
            fontSize: '8px !important',
          },
        },
        '& .eip1-MuiTouchRipple-root': {
          display: 'none',
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
  },
  selectWithIcon: {
    '& .eip1-MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: '4px',
      marginBottom: '0px',
      marginLeft: '0px',
    },
  },
  classNameIcon: {
    borderRadius: '4px',
  },
  buttonFormat: {
    minWidth: 'unset',
    padding: '6px',
    backgroundColor: 'transparent',
  },
  buttonColor: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: 'transparent',
  },
  buttonColorActive: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: '#E4E7E9',
  },
  box12: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  buttonBgIconWhite: {
    '&.eip1-MuiButton-active .eip1-MuiButton-startIcon': {
      borderRadius: '4px',
      backgroundColor: 'white',
    },
    '&:active .eip1-MuiButton-startIcon': {
      borderRadius: '4px',
      backgroundColor: 'white',
    },
  },
  switchCompare: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .eip1-MuiIconButton-root': {
      padding: '8px',
    },
  },
  rangeMonth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  colorSelectedContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: '36px',
    columnGap: '12px',
    rowGap: '12px',
    marginBottom: '8px',
    '& > div': {
      cursor: 'pointer',
    },
  },
  heatmapGradient: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    paddingLeft: '36px',
    marginBottom: '8px',
    '& > div': {
      borderRadius: '0',
    },
  },
}));

type chartType = 'col' | 'mixed' | 'line' | 'treemap' | 'heatmap';

export type OptionSelectChart = OptionSelectType & { labelType: string };
export const defaultPieLabel = `IF(p('percent') >= 1/15, TEXT(p('percent'), '0%'), '')`;

interface ChartTypeProps {
  optionsChart: OptionSelectChart[];
  optionsType: OptionSelectType[];
  optionsHeading: DropdownMenuDataType[][];
  optionsColor: DropdownMenuDataType[][];
  optionsColumnColor: string[];
  optionsGroupPeriod: OptionSelectType[];
  optionsCohorts: any;
  optionsGroupBy: any;
  optionsDimension: any;
  sectionDisplay: any;
  optionsColumnStacked: any;
  optionsHiddenButton: any;
  sortOptions: OptionSelectType[];
  optionsDisplayAllBlock: any;
  defaultOptionsHiddenButton?: any;
  onSubmit?: any;
  nodeData?: any;
}

const ChartEditor: React.FC<ChartTypeProps> = ({
  optionsChart,
  optionsType,
  optionsGroupPeriod,
  optionsHeading,
  optionsColor,
  optionsColumnColor,
  optionsCohorts,
  optionsGroupBy,
  optionsDimension,
  sectionDisplay,
  optionsColumnStacked,
  optionsHiddenButton,
  sortOptions,
  optionsDisplayAllBlock,
  defaultOptionsHiddenButton = [],
  onSubmit,
  nodeData,
}: ChartTypeProps) => {
  const chartConfig = get(nodeData, 'chartConfig.config', {});
  const classes = useStyle();
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const [valueChartType, setValueChartType] = React.useState<chartType>('col');
  const [valueStacking, setValueStacking] = React.useState('def');
  const [isTotalQuery, setIsTotalQuery] = React.useState(false);
  const [valueTitle, setValueTitle] = React.useState(chartConfig.title || '');
  const [valueTitle2, setValueTitle2] = React.useState(chartConfig.title2 || '');
  const [valueTitle3, setValueTitle3] = React.useState(chartConfig.title3 || '');
  const [valueColor, setValueColor] = React.useState([]);
  const [valueTimelineCohorts, setValueTimelineCohorts] = React.useState(false);
  const [valueGroupBy, setValueGroupBy] = React.useState('metric');
  const [height, setHeight] = React.useState(400);
  const [maximumChartsPerRow, setMaximumChartsPerRow] = React.useState(4);
  const [datetimeFormat, setDatetimeFormat] = React.useState(
    chartConfig.datetimeFormat || DEFAULT_DATETIME_FORMAT_FORMULA,
  );
  const [labelDisplayCondition, setLabelDisplayCondition] = React.useState(chartConfig.labelDisplayCondition || '1');
  const [labelColor, setLabelColor] = React.useState(chartConfig.labelColor || '#000000de');
  const [valueDisplay, setValueDiplay] = React.useState('single');
  const [showLegend, setShowLegend] = React.useState('no');
  const [showXAxis, setShowXAxis] = React.useState('no');
  const [valueColumnStacked, setValueColumnStacked] = React.useState(false);
  const [valueColumnSort, setValueColumnSort] = React.useState(SortValue.ALPHABET);
  const [valueGroupPeriod, setValueGroupPeriod] = React.useState('daily');
  const [valueColorFormat, setValueColorFormat] = React.useState('order');
  const [useDimensionXAxis, setUseDimensionXAxis] = React.useState(chartConfig.useDimensionXAxis || 'no');
  const [showLabelOnTop, setShowLabelOnTop] = React.useState(chartConfig.showLabelOnTop || 'no');
  const [pieLabel, setPieLabel] = React.useState(chartConfig.pieLabel || defaultPieLabel);
  const [valueHiddenButtons, setValueHiddenButtons] = React.useState(defaultOptionsHiddenButton);
  const [lineTension, setLineTension] = React.useState(0);
  const [gradientMidpoint, setGradientMidpoint] = React.useState(
    chartConfig.gradientMidpoint || DEFAULT_GRADIENT_MID_POINT,
  );

  const gradientColors = new Gradient()
    .setColorGradient('#ffffff', ...(valueColor.length ? valueColor : [EPSILO_BLUE_COLOR]))
    .setMidpoint(Math.max(0, Number(gradientMidpoint)))
    .getColors();

  const submitChartConfig = () => {
    const chartConfig = {
      isTotalQuery,
      chartType: valueChartType,
      title: valueTitle,
      title2: valueTitle2 || '',
      title3: valueTitle3 || '',
      color: valueColor,
      timelineCohorts: valueTimelineCohorts,
      groupBy: valueGroupBy,
      groupPeriod: valueGroupPeriod,
      display: valueDisplay,
      showLegend: showLegend,
      showXAxis: showXAxis,
      isColumnStacked: valueColumnStacked,
      sortBy: valueColumnSort,
      hiddenButton: valueHiddenButtons,
      externalFilters: valueExternalFilters,
      yAxisConfig: yAxisConfig,
      zAxisConfig: zAxisConfig,
      externalFiltersOptions: externalFiltersOptions,
      ...(ff.expose_property_etable ? { externalProperties: valueExternalProperties } : {}),
      height,
      maximumChartsPerRow,
      datetimeFormat,
      labelDisplayCondition,
      labelColor,
      lineTension,
      pieLabel,
      gradientMidpoint,
      useDimensionXAxis,
      showLabelOnTop,
      // comparasionMetrics: [comparasionMetricOne, comparasionMetricTwo]
      //   .filter((el) => !!el)
      //   .map((el) => metricOptions.find((ele) => ele.value === el)),
    };
    backboneContext.changeConfig('chartEditors', chartConfig);
    onSubmit(chartConfig);
  };

  const handleSelectColor = (color) => {
    if (valueColor.includes(color)) {
      setValueColor(valueColor.filter((el) => el != color));
    } else {
      setValueColor([...valueColor, color]);
    }
  };

  const [valueExternalFilters, setValueExternalFilters] = React.useState(
    get(nodeData, ['system', 'externalFilters'], []),
  );

  const [yAxisConfig, setYAxisConfig] = React.useState(get(chartConfig, ['yAxisConfig'], ''));
  const [zAxisConfig, setZAxisConfig] = React.useState(get(chartConfig, ['zAxisConfig'], ''));

  const [externalFiltersOptions, setExternalFiltersOptions] = React.useState(() => {
    const defaultOptions = Object.keys(nodeData.mapping)
      .filter((key) => {
        return nodeData.mapping[key].propertyType === 'dimension';
      })
      .map((key) => ({
        value: key,
        label: nodeData.mapping[key].title,
        isMultiple: true,
      }));

    const configOptions = get(nodeData, ['system', 'externalFiltersOptions'], defaultOptions);
    const additionalOptions = defaultOptions.filter((op) => {
      return !configOptions.some(({ value }) => value == op.value);
    });
    const removedOptions = configOptions.filter((op) => {
      return !defaultOptions.some(({ value }) => value == op.value);
    });

    return configOptions
      .concat(additionalOptions)
      .filter(({ value }) => !removedOptions.some((removedOption) => removedOption.value == value));
  });

  const enhancedExternalFiltersOptions = React.useMemo(() => {
    return externalFiltersOptions.map((el) => ({
      ...el,
      endIcon:
        el.isMultiple == null || el.isMultiple
          ? 'ic/material-symbols:check-box/#253746'
          : 'ic/ic:baseline-radio-button-checked/#253746',
      onEndIconClick(_, item) {
        setExternalFiltersOptions((prev) => {
          return prev.map((el) => ({
            ...el,
            isMultiple: el.value === item.value ? !el.isMultiple : el.isMultiple,
          }));
        });
      },
    }));
  }, [externalFiltersOptions]);

  let externalPropertiesOptions, valueExternalProperties, setValueExternalProperties;
  if (ff.expose_property_etable) {
    externalPropertiesOptions = [
      {
        label: 'Metrics',
        value: 'metric',
      },
    ];

    [valueExternalProperties, setValueExternalProperties] = React.useState(
      get(nodeData, ['system', 'externalProperties'], []),
    );
  }

  const heatmapLabelAvailVars = Object.entries(get(backboneContext, ['config', 'mapping'], {})).reduce(
    (carry, [key, column]) => {
      if (['metric', 'dimension'].includes(column.propertyType)) carry.push(key);
      return carry;
    },
    ['xAxis', 'yAxis', 'value', 'metric', 'hourly', 'daily', 'date', 'weekly', 'monthly', 'colorIndex'],
  );

  React.useEffect(() => {
    if (chartConfig.chartType) {
      setValueChartType(chartConfig.chartType);
      setValueTitle(chartConfig.title);
      setValueTitle2(chartConfig.title2);
      setValueTitle3(chartConfig.title3);
      setValueColor(chartConfig.color);
      setValueTimelineCohorts(chartConfig.timelineCohorts);
      setValueGroupBy(chartConfig.groupBy);
      setValueDiplay(chartConfig.display);
      setShowLegend(chartConfig.showLegend || 'no');
      setShowXAxis(chartConfig.showXAxis || 'no');
      setIsTotalQuery(chartConfig.isTotalQuery);
      setHeight(chartConfig.height);
      setMaximumChartsPerRow(chartConfig.maximumChartsPerRow);
      setDatetimeFormat(chartConfig.datetimeFormat);
      setLabelDisplayCondition(chartConfig.labelDisplayCondition || '1');
      setLabelColor(chartConfig.labelColor || '#000000de');
      setValueColumnStacked(chartConfig.isColumnStacked);
      setUseDimensionXAxis(chartConfig.useDimensionXAxis || 'no');
      setShowLabelOnTop(chartConfig.showLabelOnTop || 'no');
      setValueGroupPeriod(chartConfig.groupPeriod || 'daily');
      setValueHiddenButtons(chartConfig.hiddenButton);
      setPieLabel(chartConfig.pieLabel || defaultPieLabel);
      setGradientMidpoint(chartConfig.gradientMidpoint || DEFAULT_GRADIENT_MID_POINT);
      if (chartConfig.sortBy) {
        setValueColumnSort(chartConfig.sortBy);
      }
      setLineTension(chartConfig.lineTension || 0);
    }
  }, []);

  return (
    <Grid container>
      {ff.reuse_our_chart ? <></> : <Grid item xs={4}></Grid>}
      <Grid item {...(ff.reuse_our_chart ? {} : { xs: 4 })} style={{ width: '100%' }}>
        <FlexBox flexDirection="column" className={classes.root}>
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              External filters
            </Typography>
            <div style={{ width: '100%' }}>
              <SelectMultipleForm
                options={enhancedExternalFiltersOptions}
                value={valueExternalFilters}
                onChange={setValueExternalFilters}
                onReorder={setExternalFiltersOptions}
                multipleSelect={true}
                searchAble={false}
                isConfigChart={true}
              />
            </div>
          </div>
          {ff.expose_property_etable && (
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                External metric
              </Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={externalPropertiesOptions}
                  value={valueExternalProperties}
                  onChange={setValueExternalProperties}
                  multipleSelect={true}
                  searchAble={false}
                  isConfigChart={true}
                />
              </div>
            </div>
          )}
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              Hide top buttons
            </Typography>
            <div style={{ width: '100%' }}>
              <SelectMultipleForm
                options={optionsHiddenButton}
                value={valueHiddenButtons}
                onChange={setValueHiddenButtons}
                multipleSelect={true}
                searchAble={false}
                isConfigChart={true}
              />
            </div>
          </div>
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              Chart types
            </Typography>
            <SelectSearchFormIcon
              className={`${classes.selectWithIcon} ${classes.select}`}
              icon={true}
              options={optionsChart}
              inputWidth="100%"
              value={valueChartType}
              placeholder="Placeholder"
              onChange={(e) => setValueChartType(e)}
            />
          </div>
          <>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Period
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                icon={false}
                options={optionsGroupPeriod}
                inputWidth="100%"
                value={valueGroupPeriod}
                placeholder="Placeholder"
                onChange={(e) => setValueGroupPeriod(e)}
              />
            </div>
            {valueChartType === 'col' || valueChartType === 'mixed' ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Stacked
                  </Typography>
                  <SelectSearchFormIcon
                    className={`${classes.selectWithIcon} ${classes.select}`}
                    options={optionsColumnStacked}
                    inputWidth="100%"
                    value={valueColumnStacked}
                    placeholder="Placeholder"
                    onChange={(e) => setValueColumnStacked(e)}
                  />
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Sort by
                  </Typography>
                  <SelectSearchFormIcon
                    className={`${classes.selectWithIcon} ${classes.select}`}
                    options={sortOptions}
                    inputWidth="100%"
                    value={valueColumnSort}
                    placeholder="Placeholder"
                    onChange={(e) => setValueColumnSort(e)}
                  />
                </div>
              </>
            ) : null}
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Timeline cohorts
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={optionsCohorts}
                inputWidth="100%"
                value={valueTimelineCohorts}
                placeholder="Placeholder"
                onChange={(e) => setValueTimelineCohorts(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Group by
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={optionsGroupBy}
                inputWidth="100%"
                value={valueGroupBy}
                placeholder="Placeholder"
                onChange={(e) => setValueGroupBy(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Section display as
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                // options={valueChartType === 'col' ? sectionDisplay.filter(i => i.value !== 'single') : sectionDisplay}
                options={sectionDisplay}
                inputWidth="100%"
                value={valueDisplay}
                placeholder="Placeholder"
                onChange={(e) => setValueDiplay(e)}
              />
              {valueGroupBy === 'dimension' && <span>For group by Dimension, please don't chose the single.</span>}
            </div>
            {valueDisplay === 'single' ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Maximum Chart Per Row
                  </Typography>
                  <FlexBox flexDirection="row" className={classes.select}>
                    <TextField
                      placeholder="Placehoder"
                      onChange={(e) => setMaximumChartsPerRow(e.target.value)}
                      variant="outlined"
                      value={maximumChartsPerRow}
                      type={'number'}
                    />
                  </FlexBox>
                </div>
              </>
            ) : null}
            {['lines', 'mixed'].includes(valueChartType) ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Show legend
                  </Typography>
                  <div style={{ width: '100%' }}>
                    <SelectMultipleForm
                      options={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                      value={showLegend}
                      onChange={([value]) => setShowLegend(value)}
                      multipleSelect={false}
                      searchAble={false}
                      isConfigChart
                    />
                  </div>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Show x-axis
                  </Typography>
                  <div style={{ width: '100%' }}>
                    <SelectMultipleForm
                      options={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                      value={showXAxis}
                      onChange={([value]) => setShowXAxis(value)}
                      multipleSelect={false}
                      searchAble={false}
                      isConfigChart
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Height
              </Typography>
              <FlexBox flexDirection="row" className={classes.select}>
                <TextField
                  placeholder="Placehoder"
                  onChange={(e) => setHeight(e.target.value)}
                  variant="outlined"
                  value={height}
                  type={'number'}
                />
              </FlexBox>
            </div>
            {valueChartType === 'heatmap' ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Datetime format
                  </Typography>
                  <FlexBox flexDirection="column" className={classes.select}>
                    <TextFieldWithFormula
                      initialValue={datetimeFormat}
                      supportFormula
                      availVars={['hourly', 'daily', 'date', 'weekly', 'monthly', 'period']}
                      onValueChange={(value) => setDatetimeFormat(value)}
                    />
                  </FlexBox>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Gradient midpoint
                  </Typography>
                  <FlexBox flexDirection="row" className={classes.select}>
                    <TextField
                      placeholder="Placehoder"
                      onChange={(e) => setGradientMidpoint(e.target.value)}
                      variant="outlined"
                      value={gradientMidpoint}
                      type={'number'}
                    />
                  </FlexBox>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Label Display Condition
                  </Typography>
                  <FlexBox flexDirection="column" className={classes.select}>
                    <TextFieldWithFormula
                      initialValue={labelDisplayCondition}
                      supportFormula
                      availVars={heatmapLabelAvailVars}
                      onValueChange={(value) => setLabelDisplayCondition(value)}
                    />
                  </FlexBox>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Label Color
                  </Typography>
                  <FlexBox flexDirection="column" className={classes.select}>
                    <TextFieldWithFormula
                      initialValue={labelColor}
                      supportFormula
                      availVars={heatmapLabelAvailVars}
                      onValueChange={(value) => setLabelColor(value)}
                    />
                  </FlexBox>
                </div>
              </>
            ) : null}
            {['lines', 'mixed'].includes(valueChartType) ? (
              <div className={classes.group}>
                <Typography variant="h6" className={classes['mb-4']}>
                  Line tension
                </Typography>
                <FlexBox flexDirection="row" className={classes.select}>
                  <TextField
                    placeholder="Placehoder"
                    onChange={(e) => setLineTension(e.target.value)}
                    variant="outlined"
                    value={lineTension}
                    type={'number'}
                  />
                </FlexBox>
              </div>
            ) : null}
            {valueChartType === 'pie' ? (
              <div className={classes.group}>
                <Typography variant="h6" className={classes['mb-4']}>
                  Pie label
                </Typography>
                <TextFieldWithFormula
                  initialValue={pieLabel}
                  supportFormula
                  availVars={['sum', 'value', 'percent']}
                  onValueChange={(value) => setPieLabel(value)}
                />
              </div>
            ) : null}
            <div className={classes.group}>
              <Typography variant="h6">Use Dimension for X-Axis</Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={[
                    {
                      label: 'Yes',
                      value: 'yes',
                    },
                    {
                      label: 'No',
                      value: 'no',
                    },
                  ]}
                  value={useDimensionXAxis}
                  onChange={([value]) => setUseDimensionXAxis(value)}
                  multipleSelect={false}
                  searchAble={false}
                  isConfigChart
                />
              </div>
            </div>
            {['col', 'lines', 'mixed'].includes(valueChartType) ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6">Show label on top</Typography>
                  <div style={{ width: '100%' }}>
                    <SelectMultipleForm
                      options={[
                        {
                          label: 'Yes',
                          value: 'yes',
                        },
                        {
                          label: 'No',
                          value: 'no',
                        },
                      ]}
                      value={showLabelOnTop}
                      onChange={([value]) => setShowLabelOnTop(value)}
                      multipleSelect={false}
                      searchAble={false}
                      isConfigChart
                    />
                  </div>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    YAxis config
                  </Typography>
                  <FlexBox flexDirection="column" className={classes.select}>
                    <TextFieldWithFormula
                      initialValue={yAxisConfig}
                      supportFormula
                      availVars={[]}
                      onValueChange={(value) => setYAxisConfig(value)}
                    />
                  </FlexBox>
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    ZAxis config
                  </Typography>
                  <FlexBox flexDirection="column" className={classes.select}>
                    <TextFieldWithFormula
                      initialValue={zAxisConfig}
                      supportFormula
                      availVars={[]}
                      onValueChange={(value) => setZAxisConfig(value)}
                    />
                  </FlexBox>
                </div>
              </>
            ) : null}
            <Divider style={{ marginBottom: '12px' }} />
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Title 1
              </Typography>
              <FlexBox flexDirection="column" className={classes.select}>
                <TextFieldWithFormula
                  initialValue={valueTitle}
                  supportFormula
                  availVars={[]}
                  onValueChange={(value) => setValueTitle(value)}
                />
              </FlexBox>
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Title 2
              </Typography>
              <FlexBox flexDirection="column" className={classes.select}>
                <TextFieldWithFormula
                  initialValue={valueTitle2}
                  supportFormula
                  availVars={[]}
                  onValueChange={(value) => setValueTitle2(value)}
                />
              </FlexBox>
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Title 3
              </Typography>
              <FlexBox flexDirection="column" className={classes.select}>
                <TextFieldWithFormula
                  initialValue={valueTitle3}
                  supportFormula
                  availVars={[]}
                  onValueChange={(value) => setValueTitle3(value)}
                />
              </FlexBox>
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Title format
              </Typography>
              <FlexBox flexDirection="column">
                <FlexBox justifyContent="space-between" className={classes['mb-16']}>
                  {/* Heading */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <Dropdown
                      alignMenu="right"
                      icon="chevron"
                      label="Heading 1"
                      className={`${classes.button} ${classes['mr-2']}`}
                    >
                      <MenuList listMenu={optionsHeading} />
                    </Dropdown>
                  </FlexBoxItem>
                  {/* Color */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['ml-2']}`}>
                    <Dropdown
                      sizeIconStart="15px"
                      alignMenu="right"
                      icon="chevron"
                      label="Color"
                      className={`${classes.button} ${classes.buttonBgIconWhite} ${classes['ml-2']}`}
                      iconStart="colorText"
                      colorStartIcon={colors.icon.default}
                    >
                      <MenuList listMenu={optionsColor} title="Color" iconSize="18px" />
                    </Dropdown>
                  </FlexBoxItem>
                </FlexBox>
                <FlexBox justifyContent="space-between" className={classes['mb-16']}>
                  {/* Style */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="bold" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="italic" size="12px"></Icon>
                      </Button>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="underline" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                  {/* Format */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['ml-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="format" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="code" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
                <FlexBox justifyContent="flex-start">
                  {/* Align */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="alignLeft" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="alignCenter" size="12px"></Icon>
                      </Button>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="alignRight" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
              </FlexBox>
            </div>
            <Divider style={{ marginBottom: '12px' }} />
            <div className={`${classes.group} ${classes['mb-0']}`}>
              <Typography variant="h6" className={classes['mb-6']}>
                Color format
              </Typography>
              <RadioGroup onChange={(e) => setValueColorFormat(e.target.value)}>
                <RadioItem label="Column order" value={valueColorFormat} radioValue="order">
                  <ListItemIcon style={{ paddingLeft: '30px', ...(ff.reuse_our_chart ? { flexWrap: 'wrap' } : {}) }}>
                    {optionsColumnColor.map((color) => (
                      <Button
                        key={color}
                        variant="contained"
                        color="secondary"
                        className={valueColor.includes(color) ? classes.buttonColorActive : classes.buttonColor}
                        onClick={() => handleSelectColor(color)}
                      >
                        <Box bgcolor={color} className={classes.box12}></Box>
                      </Button>
                    ))}
                  </ListItemIcon>
                </RadioItem>
                {valueColor?.length && valueChartType !== 'heatmap' ? (
                  <div className={classes.colorSelectedContainer}>
                    {valueColor.map((color) => {
                      return (
                        <Box
                          key={color}
                          bgcolor={color}
                          className={classes.box12}
                          onClick={() => handleSelectColor(color)}
                        ></Box>
                      );
                    })}
                  </div>
                ) : null}
                {valueChartType === 'heatmap' ? (
                  <div className={classes.heatmapGradient}>
                    {gradientColors.map((color) => {
                      return <Box key={color} bgcolor={color} className={classes.box12}></Box>;
                    })}
                  </div>
                ) : null}
                <RadioItem
                  label="Dimension value color"
                  value={valueColorFormat}
                  radioValue="dimension"
                  className={classes['mb-0']}
                >
                  <Typography variant="body5" style={{ marginLeft: '38px' }}>
                    Manage dimension value color
                  </Typography>
                </RadioItem>
              </RadioGroup>
            </div>
          </>
          {ff.reuse_our_chart ? (
            <Button variant="contained" color="primary" style={{ marginTop: '15px' }} onClick={submitChartConfig}>
              Submit
            </Button>
          ) : (
            <></>
          )}
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default ChartEditor;
