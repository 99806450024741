import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { ListItem, ListItemProps } from '@material-ui/core';
/** Style */
const useStyles = makeStyles((theme) => ({
  hover: {
    '&:hover': {
      // cursor: 'pointer',
      // backgroundColor: `#EBF4FB`,
    },
  },
}));

export const StyleTitleListItem = withStyles(() => ({
  root: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    marginBottom: 0,
    paddingLeft: 15,
    // padding: 0,
  },
}))((props: ListItemProps) => <ListItem {...props} />);

export const StyleListItem = withStyles(() => ({
  root: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    paddingRight: '10px',
    position: 'relative',
    // marginBottom: 10,
    justifyContent: 'space-between',

    '& .dotInfo': {
      opacity: 0,
    },
    '&:hover > .dotInfo': {
      opacity: 1,
    },
  },
}))((props: ListItemProps) => {
  const classes = useStyles();
  return <ListItem {...props} className={`${props.className} ${!props.disabled ? classes.hover : ''}`} />;
});
