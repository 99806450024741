import * as React from 'react';
import * as PropTypes from 'prop-types';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '../text-style/Typography';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { ButtonProps } from '@material-ui/core/Button';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { DarkModeContext } from '../epsilo-chart/chart-container';
/** Style */
const useStyles = makeStyles((theme) => ({
  root: (props: ButtonDropdownProps) => ({
    minWidth: props.sizeButton ? props.sizeButton : '32px',
    height: props.sizeButton ? props.sizeButton : '32px',
    padding: '0px',
  }),
  disabledToogleStyle: {
    '&:active': {
      color: colors.text.default,
    },
  },
  onlyIcon: {
    margin: '0px',
  },
  active: (props: ButtonDropdownProps) => ({
    ...theme.overrides.MuiButton?.text['&:active'],
    backgroundColor: props.dataColorActive,
    '&:hover': {
      color: colors.text.default,
    },
    '& svg': {
      color: 'white #important',
    },
  }),
  passive: {
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
  },
  label: () => ({
    paddingLeft: '8px',
    paddingRight: '8px',
  }),
  icon: (props: ButtonDropdownProps) => ({
    width: props.sizeIcon || '16px',
    height: props.sizeIcon || '16px',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0px',
    paddingLeft: props.startIcon && props.label ? '0px' : '4px',
    paddingRight: props.endIcon && props.label ? '0px' : '4px',
  }),
  darkMode: {
    backgroundColor: '#253645',
    color: '#FFFFFF',
    '&:active': {
      backgroundColor: '#006EC6',
    },
    '&:hover': {
      '& .eip1-MuiButton-startIcon': {
        color: '#253645',
      },
      '& .eip1-MuiTypography-body2': {
        color: '#253645',
      },
    },
  },
}));

interface ButtonDropdownProps extends ButtonProps {
  label: string;
  open: boolean;
  sizeIcon?: string;
  disabledToggleStyle?: boolean;
  startIcon?: string;
  endIcon?: string;
  dataColorActive?: string;
  sizeButton?: string;
}

// eslint-disable-next-line react/display-name
const ButtonDropdown = React.forwardRef<ButtonDropdownProps, any>((props, ref) => {
  const classes = useStyles(props);
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const { disabledToggleStyle, sizeIcon, ...propsButton } = props;
  return (
    <Button
      classes={{
        root: clsx(
          classes.root,
          props.label && classes.label,
          props.open && !disabledToggleStyle && classes.active + ' eip1-MuiButton-active',
          disabledToggleStyle && classes.disabledToogleStyle,
          {
            [classes.darkMode]:
              typeof isDarkMode == 'boolean' && isDarkMode && !disabledToggleStyle && !props.isButtonActive,
            [props.className]: props.className,
          },
        ),
        endIcon: `${!props.label && classes.onlyIcon} ${classes.icon}`,
        startIcon: `${!props.label && classes.onlyIcon} ${classes.icon}`,
      }}
      ref={ref}
      {...propsButton}
    >
      {props.label && <Typography variant="body2">{props.label}</Typography>}
    </Button>
  );
});

ButtonDropdown.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  label: PropTypes.string.isRequired,
  disabledToggleStyle: PropTypes.bool,
  sizeIcon: PropTypes.string,
  dataColorActive: PropTypes.string,
  sizeButton: PropTypes.string,
};

export default ButtonDropdown;
