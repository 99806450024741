import { useLog } from '@eip/next/lib/main';
import { Box, makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import * as React from 'react';
import { getAggregateCellActions } from '../table-helper';
import WrapperFormat from './wrapper-format';

import { aggFuncByKey, supportedFieldKey } from '@ep/insight-ui/system/util/aggregation';
import { formatCurrencyNumber, formatPercentNumber } from '@ep/insight-ui/lib/number';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { isNumber, isString } from 'lodash';

const log = useLog('insight-ui:table:aggregate-cell');

const useStyles = makeStyles({
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    textAlign: 'right',
    '&.type': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#8C98A4',
    },
    '&.value': {
      fontSize: '14px',
      color: '#253746',
    },
  },
});
interface IValueAggregateCell {
  node: any;
  label?: string;
  value?: string;
}

function AggregateCellFormat(props: IValueAggregateCell) {
  const classes = useStyles();
  const valueGrouping = _.get(props, 'value', null);
  const dataType = _.get(props, 'dataType', '');
  const typeTable = _.get(props, 'typeTable', '');
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const column = _.get(props, 'column', null);
  const setColumnAggregate = _.get(props, 'setColumnAggregate', () => undefined);
  const aggregations = _.get(props, 'aggregations');
  const funcValue = _.get(aggregations, [column.colId, 'func'], '');

  const backbone = React.useContext(TableBackboneContext);
  const isSupportAggregation = backbone.getConfig('isSupportGroupAggregation', true);

  const subActions = React.useMemo(() => {
    return [].concat(getAggregateCellActions(dataType, column.field)).map((atc) => {
      if (!atc.onSubmit)
        atc.onSubmit = (args) => {
          setColumnAggregate(column.colId, atc.value);
        };
      return atc;
    });
  }, [dataType]);

  const functionType = React.useMemo(() => {
    if (!column || !aggregations) return '...';
    const funcValue = _.get(aggregations, [column.colId, 'func'], 'COUNT_UNIQUE');
    return _.get(
      subActions.find((it) => String(it.value).toLowerCase() == String(funcValue).toLowerCase()),
      'label',
      '',
    );
  }, [aggregations, column, subActions, funcValue]);

  const cellAction = React.useMemo(() => {
    return _.get(props, 'cellAction', []).concat([
      {
        name: 'Calculate now',
        icon: 'calculate',
        subActions,
      },
    ]);
  }, [props, subActions]);

  const getNumberFormat = (val: number) => (isNumber(val) ? Number(val).toFixed(4) : '');

  const label = React.useMemo(() => {
    if (!valueGrouping && !funcValue) return '';
    const val = _.get(
      Object.entries(valueGrouping).filter(([key, item]) => supportedFieldKey),
      [0, 1],
      null,
    );
    const currency = _.get(node, ['data', 'currency'], '') || '';

    if (val === null) return '';

    if (dataType === 'string' && isString(val)) {
      return val;
    }

    const valNumber = getNumberFormat(parseFloat(val)).replace(/\.0000$/, '');

    switch (funcValue) {
      case aggFuncByKey.percent_not_empty.id:
      case aggFuncByKey.percent_empty.id: {
        return `${formatPercentNumber(valNumber)}%`;
      }
      case aggFuncByKey.sum.id:
      case aggFuncByKey.avg.id:
      case aggFuncByKey.median.id:
      case aggFuncByKey.min.id:
      case aggFuncByKey.max.id: {
        return `${formatCurrencyNumber(valNumber)}`;
      }
      case aggFuncByKey.range.id: {
        const range = ff.safe_json_parse ? safeJsonParse(val, []) : JSON.parse(val) || [];
        return `${formatCurrencyNumber(getNumberFormat(_.get(range, [0], '')))} - ${formatCurrencyNumber(
          getNumberFormat(_.last(range)),
        )}`;
      }
      default:
        return valNumber;
    }
  }, [valueGrouping, funcValue, node]);

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value}>
      <Box className={`${classes.text} value`} width={'100%'}>
        {label !== null && label !== '' && isSupportAggregation ? (
          <Box display={'flex'}>
            {/* <Box className={`${classes.text} type`}>{aggFunc}</Box> */}
            <Box className={`${classes.text} type`}>{functionType}</Box>
            <Box style={{ paddingLeft: '0.5em' }}>{String(label)}</Box>
          </Box>
        ) : (
          '-'
        )}
      </Box>
    </WrapperFormat>
  );
}

export default AggregateCellFormat;
