import * as React from 'react';
import loadjs from 'loadjs';
declare global {
  interface Window {
    require: any;
    eppy: any;
    loadPyodide: any;
    monaco: any;
  }
}

function formatCode(codeRaw) {
  const code = window.btoa(codeRaw);
  const formatted = window.eppy.runPython(`
code = b64decode("${code}").decode("utf-8")
try:
  code = black.format_str(code, mode=black.Mode())
except Exception as e:
  code

code
  `);
  return formatted;
}

export const MonacoCompareEditor = React.forwardRef(function MonacoCompareEditor(props, ref) {
  const { value, language, suggestions, options, compareValue, onCodeChange, ...rest } = props;
  const targetRef = React.useRef(null);
  const [editor, setEditor] = React.useState(null);

  React.useEffect(() => {
    if (suggestions) {
      window.monaco.languages.registerCompletionItemProvider(language, {
        provideCompletionItems: (model, position) => {
          const word = model.getWordUntilPosition(position);
          const range = {
            startLineNumber: position.lineNumber,
            endLineNumber: position.lineNumber,
            startColumn: word.startColumn,
            endColumn: word.endColumn,
          };

          return {
            suggestions: suggestions.map((suggestion) => {
              return {
                ...suggestion,
                kind: window.monaco.languages.CompletionItemKind[suggestion.kind || 'Function'],
                range,
                ...(suggestion.insertTextRules
                  ? {
                      insertTextRules: window.monaco.languages.CompletionItemInsertTextRule[suggestion.insertTextRules],
                    }
                  : {}),
              };
            }),
          };
        },
      });

      if (language === 'python') {
        window.monaco.languages.registerDefinitionProvider('python', {
          provideDefinition: (model, position, token) => {
            const { word } = model.getWordAtPosition(position);
            let matches = model.findMatches(new RegExp(`${word}\\s*=`), true, true, true, null, true);
            if (matches?.length == 0) {
              matches = model.findMatches(new RegExp(`def ${word}\\s*\\(`, 'gi'), true, true, true, null, true);
            }

            if (
              suggestions?.some((el) => el.label?.label === word && el.label?.detail === ' - self') &&
              matches?.length > 0
            ) {
              return {
                uri: model.uri,
                range: matches[0].range,
              };
            }
          },
        });
      }
    }
    const editor = window.monaco.editor.createDiffEditor(targetRef.current, options);
    editor.setModel({
      original: window.monaco.editor.createModel(compareValue, language),
      modified: window.monaco.editor.createModel(value, language),
    });

    const modifiedEditor = editor.getModifiedEditor();

    let previousValue = modifiedEditor.getValue();
    const listener = modifiedEditor.onDidChangeModelContent(() => {
      const currentValue = modifiedEditor.getValue();
      if (currentValue !== previousValue) {
        onCodeChange(currentValue);
        previousValue = currentValue;
      }
    });

    setEditor(editor);

    return () => {
      listener.dispose();
      editor.dispose();
    };
  }, []);

  React.useImperativeHandle(ref, () => ({
    ...editor,
  }));

  return <div ref={targetRef} style={{ width: '100%', height: '100%' }} />;
});
