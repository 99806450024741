import * as React from 'react';
import moment from 'moment';
import { makeStyles, Avatar, Button } from '@material-ui/core';

import { TextEditorStories as TextEditor } from '@ep/insight-ui/elements/text-editor/text-editor.stories';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyle = makeStyles((theme) => ({
  container: {
    display: 'flex',
    width: '100%',
    color: '#000',
    '& ul': {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
  },
  mainContainer: {
    display: 'flex',
    flex: 7,
    flexDirection: 'column',
    paddingRight: '8px',
  },
  messages: {},
  threadContainer: {
    display: 'flex',
    flex: 3,
    flexDirection: 'column',
    borderLeft: '1px solid #E5E5E5',
  },
  profileAndTime: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
  },
  name: {
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
  },
  time: {
    fontSize: '10px',
    lineHeight: '10px',
    color: '#8C98A4',
    marginLeft: '16px',
  },
  content: {
    paddingLeft: '32px',
    '& li': {
      marginBottom: '6px',
      display: 'flex',
      justifyContent: 'space-between',
    },
  },
  formField: {
    display: 'flex',
    '& textarea': {
      outline: 'none',
      border: 'none',
      width: '100%',
      fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
      resize: 'none',
      fontSize: '14px',
    },
    '& textarea:hover': {
      outline: 'none',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '32px',
  },
  tools: {
    display: 'flex',
    '& li': {
      marginRight: '24px',
      cursor: 'pointer',
    },
  },
  avatar: {
    marginRight: '8px',
    width: '24px',
    height: '24px',
    fontSize: '10px',
    color: '#253746',
    background: '#EBF6FF',
    border: '1px solid #CCE3F5',
  },
  breakLine: {
    width: '100%',
    padding: '0 0 8px',
    height: '1px',
    background: '#fff',
    boxShadow: 'inset 0px -1px 0px #E5E5E5',
  },
  form: {
    marginTop: '8px',
  },
  formButton: {
    height: '32px',
  },
  replies: {
    padding: '16px',
  },
  threadMainContent: {
    padding: '0 8px',
  },
  messageContent: {
    marginBottom: '16px',
  },
  mentionContent: {
    color: '#004AA8',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  noteContent: {
    fontWeight: 'bold',
  },
  replied: {
    color: '#8C98A4',
  },
  repliedSection: {
    display: 'flex',
  },
  repliedGroup: {
    display: 'flex',
    '& .replied-icon': {
      marginLeft: '-13px',
    },
  },
}));

const messageData = [
  {
    avatar: 'EC',
    name: 'Angeline G',
    time: new Date(),
    messages: [
      {
        id: 1,
        content: "The first 1 hour performed well, make sure we don't run out of budget",
        replies: ['AG', 'HM', 'SN'],
      },
      {
        id: 2,
        content:
          'our top and killer deals must be on the top search result and need to maintain the 60% #share-of-search',
      },
      {
        id: 3,
        content: 'for our branded keyword and top 2 functional keywords. @Maria make all budget decision on-air please',
      },
    ],
  },
  {
    avatar: 'SN',
    name: 'Scarlet',
    time: moment(new Date()).add('m', 3).toDate(),
    messages: [
      {
        id: 4,
        content: 'Up-to-dated hero list already submit to you for your approval',
      },
      {
        id: 5,
        content: '@Maria please update your setup accordingly? Thx',
      },
      {
        id: 6,
        content: 'Pls double check inputs  from ID team',
        replies: ['AD', 'MG', 'MO'],
      },
    ],
  },
  {
    avatar: 'HM',
    name: 'Hung',
    time: moment(new Date()).add('m', 5).toDate(),
    messages: [
      {
        id: 7,
        content: 'Updating, will be done before 5PM',
      },
    ],
  },
];

const threadData = {
  id: 8,
  avatar: 'EC',
  name: 'Angeline G',
  time: new Date(),
  content: 'Make sure 70% Nov budget flow into this campaign',
  replies: [
    {
      avatar: 'AG',
      name: 'Angeline G',
      time: moment(new Date()).add('m', 3).toDate(),
      messages: [
        {
          id: 9,
          content: 'request for >10M IDR will be forward to you @Emir',
        },
        {
          id: 10,
          content: '@Hung please check the budget timeline following above notes',
        },
      ],
    },
    {
      avatar: 'MR',
      name: 'Maria',
      time: moment(new Date()).add('m', 5).toDate(),
      messages: [
        {
          id: 11,
          content: 'Any specific objective for the rest',
        },
      ],
    },
  ],
};

const tools = ['underline', 'italic', 'bold', 'strikethrough', 'properties', 'code'];

const generateMessage = (classes, messages) => {
  return messages.map((el) => {
    const content = el.content
      .split(' ')
      .map((ele) => {
        if (ele.startsWith('@')) return <span className={classes.mentionContent}>{ele}</span>;
        if (ele.startsWith('#')) return <span className={classes.noteContent}>{ele}</span>;
        return ele;
      })
      .reduce((a, b, index, arr) => {
        if (index === 0) return [b];
        if (typeof a[a.length - 1] === 'string' && typeof b === 'string')
          return [a.slice(0, a.length - 1), `${a[a.length - 1]} ${b}`];

        if (index < arr.length) {
          return [...a, ' ', b, ' '];
        }

        return [...a, ' ', b];
      }, []);
    const repliedSection = el.replies ? (
      <div className={classes.repliedSection}>
        <div className={classes.repliedGroup}>
          {el.replies.map((ele, index, arr) => (
            <Avatar className={`${classes.avatar} replied-icon`} key={el} style={{ zIndex: arr.length - index }}>
              <span>{ele}</span>
            </Avatar>
          ))}
        </div>
        <span className={classes.replied}>replied on this</span>
      </div>
    ) : null;
    return (
      <li key={el.id}>
        <span>{content}</span>
        {repliedSection}
      </li>
    );
  });
};

const CollaborationRoomConversation = () => {
  const classes = useStyle();

  const messages = messageData.map((message) => {
    const messageContent = generateMessage(classes, message.messages);
    return (
      <li key={message.time.getTime()} className={classes.messageContent}>
        <div className={classes.profileAndTime}>
          <div className={classes.profile}>
            <Avatar className={classes.avatar}>{message.avatar}</Avatar>
            <div className={classes.name}>
              <span>{message.name}</span>
            </div>
          </div>
          <div className={classes.time}>
            <span>Today at {moment(message.time).format('HH:mm A')}</span>
          </div>
        </div>
        <div className={classes.content}>
          <ul>{messageContent}</ul>
        </div>
      </li>
    );
  });

  const toolsRender = tools.map((tool) => {
    return (
      <li key={tool}>
        <Icon type={tool} colorIcon={'#253746'} />
      </li>
    );
  });

  return (
    <div className={classes.mainContainer}>
      <ul className={classes.messages}>{messages}</ul>
      <div className={classes.breakLine} />
      <div className={classes.form}>
        <form onSubmit={(e) => e.preventDefault()} className={classes.formField}>
          <Avatar className={classes.avatar}>
            <span>AG</span>
          </Avatar>
          <TextEditor placeholder={'Add a comment...'} label={'Send'} btnAction={(value) => console.log(value)} />
        </form>
      </div>
    </div>
  );
};

const CollaborationRoomThread = () => {
  const classes = useStyle();

  const replies = threadData.replies.map((message) => {
    const messageContent = generateMessage(classes, message.messages);
    return (
      <li key={message.time.getTime()} className={classes.messageContent}>
        <div className={classes.profileAndTime}>
          <div className={classes.profile}>
            <Avatar className={classes.avatar}>{message.avatar}</Avatar>
            <div className={classes.name}>
              <span>{message.name}</span>
            </div>
          </div>
          <div className={classes.time}>
            <span>Today at {moment(message.time).format('HH:mm A')}</span>
          </div>
        </div>
        <div className={classes.content}>
          <ul>{messageContent}</ul>
        </div>
      </li>
    );
  });

  const toolsRender = tools.map((tool) => {
    return (
      <li key={tool}>
        <Icon type={tool} colorIcon={'#253746'} />
      </li>
    );
  });

  return (
    <div className={classes.threadContainer}>
      <div className={classes.threadMainContent}>
        <div className={classes.profileAndTime}>
          <div className={classes.profile}>
            <Avatar className={classes.avatar}>{threadData.avatar}</Avatar>
            <div className={classes.name}>
              <span>{threadData.name}</span>
            </div>
          </div>
          <div className={classes.time}>
            <span>Today at {moment(threadData.time).format('HH:mm A')}</span>
          </div>
        </div>
        <div className={classes.content}>
          <span>{threadData.content}</span>
        </div>
      </div>
      <div className={classes.breakLine} />
      <div className={classes.replies}>
        <ul>{replies}</ul>
        <div className={classes.breakLine} />
        <div className={classes.form}>
          <form onSubmit={(e) => e.preventDefault()} className={classes.formField}>
            <Avatar className={classes.avatar}>
              <span>AG</span>
            </Avatar>
            <TextEditor placeholder={'Reply...'} label={'Reply'} btnAction={(value) => console.log(value)} />
          </form>
        </div>
      </div>
    </div>
  );
};

const CollaborationRoom = () => {
  const classes = useStyle();
  return (
    <div className={classes.container}>
      <CollaborationRoomConversation />
      <CollaborationRoomThread />
    </div>
  );
};

export default CollaborationRoom;
