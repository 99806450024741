import { io } from 'socket.io-client';

import * as aim from '@ep/one/aim';

import { API_EIP_MANAGER_URL } from '../../global';

export const socket = io(API_EIP_MANAGER_URL, {
  autoConnect: false,
  timeout: 3000,
  extraHeaders: {
    Authorization: `Bearer ${aim.getLoginToken().token}`,
  },
  transports: ['websocket'],
});
