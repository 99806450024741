import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import * as _ from 'lodash';
import React from 'react';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { ITreeMapDataChart } from '../type';
import { funcConfigs } from './hooks';
import TreeMapChartResponse from './treemap-chart';
type Props = {
  data: ITreeMapDataChart[];
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  colKey?: string;
  subTitle?: string;
};
function TreeMapChart({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
  colKey = '',
  subTitle = '',
}: Props) {
  const { defaultOptions, customHTML } = funcConfigs();

  const [dataChart, setDataChart] = React.useState<{ dataList: ITreeMapDataChart[]; optionList: any }>({
    dataList: [],
    optionList: JSON.parse(JSON.stringify(defaultOptions)),
  });

  const sortedData = React.useMemo(() => {
    return [...data].sort((a, b) => b.totalValue - a.totalValue);
  }, [data]);

  const TOTAL_VALUE = React.useMemo(
    () => _.sum(sortedData.map((tree) => _.sumBy(tree.children, 'value'))),
    [sortedData],
  );

  const PERCENT_LIST = React.useMemo(() => {
    const result = {};
    sortedData.forEach((tree) => {
      tree.children.forEach((leaf) => {
        result[leaf.name] = ((leaf.value * 100) / TOTAL_VALUE).toFixed(2);
      });
    });
    return result;
  }, [sortedData, TOTAL_VALUE]);

  const LABEL_LIST = React.useMemo(() => {
    const result = {};
    sortedData.forEach((tree) => {
      tree.children.forEach((leaf) => {
        result[leaf.value] = leaf.label || leaf.value;
      });
    });
    return result;
  }, [sortedData]);

  React.useEffect(() => {
    const hashColors = {};
    sortedData.forEach((item, index) => {
      hashColors[item.name] = colors.charts[index];
    });

    //Display option chart
    // _.set(dataChart.optionList, 'title', 'TreeMap Chart');
    _.set(dataChart.optionList, 'getFillColor', (group, label, data, defaultFillColor) => {
      return hashColors[group];
    });
    _.set(
      dataChart.optionList,
      'tooltip.customHTML',
      customHTML({
        percent: PERCENT_LIST,
        currency,
        labelList: LABEL_LIST,
        headers: sortedData.map((i) => ({
          label: i.name,
          valueLabel: i.totalLabel,
          value: i.totalValue,
        })),
      }),
    );
    _.set(dataChart.optionList, 'tooltip.valueFormatter', (value, label) => {
      return {
        label,
        value,
      };
    });
    setDataChart({ dataList: sortedData, optionList: dataChart.optionList });
  }, []);
  return (
    <TreeMapChartResponse
      percentList={PERCENT_LIST}
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
      colKey={colKey}
      subTitle={subTitle}
    />
  );
}

export default TreeMapChart;
