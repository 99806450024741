import { downloadFile } from '@ep/one/src/utils/common';
import { get } from 'lodash';
import moment from 'moment';
import { FilterType } from '../components/dashboard/context';
import { NodeData } from '../components/dashboard/type';
import { getAllMetrics, getAllShops } from './async-resources';
import { dataRequest } from './data-request';
import { toCSV } from './export-utils';

const log = require('debug')('data-query');

const defaultCohorts = [
  { value: 'mtd', label: 'MTD' },
  { value: '7-days', label: 'Last 7 days' },
  { value: '30-days', label: 'Last 30 days' },
  { value: '3-months', label: 'Last 3 months' },
  { value: '6-months', label: 'Last 6 months' },
  { value: '9-months', label: 'Last 9 months' },
];

export function translateCohort(cohort) {
  const dateRange = { from: null, to: null };
  const prevRange = { from: null, to: null };
  const yesterday = moment().subtract(1, 'day');
  switch (cohort) {
    case 'mtd': {
      dateRange.from = moment().startOf('month').format('YYYY-MM-DD');
      dateRange.to = yesterday.format('YYYY-MM-DD');
      prevRange.from = moment()
        .subtract(1, 'month')
        .startOf('month')
        .format('YYYY-MM-DD');
      prevRange.to = moment()
        .startOf('month')
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      break;
    }
    default: {
      const [number, period] = String(cohort).split('-');
      dateRange.from = moment()
        .subtract(number, period)
        .format('YYYY-MM-DD');
      dateRange.to = yesterday.format('YYYY-MM-DD');
      prevRange.from = moment()
        .subtract(number * 2, period)
        .format('YYYY-MM-DD');
      prevRange.to = moment(yesterday)
        .subtract(number, period)
        .format('YYYY-MM-DD');
    }
  }

  return { current: dateRange, previous: prevRange };
}

async function getShopIds(dimensions) {
  if (dimensions.shops && dimensions.shops.length > 0) {
    return dimensions.shops;
  } else {
    const allShops = await getAllShops();
    return filterShopIdsBy(allShops, dimensions).map(
      (s) => s.shop_eid,
    );
  }
}

function filterShopIdsBy(allShops, { channels, countries, tools }) {
  let fShops = allShops;
  if (channels && channels.length > 0) {
    fShops = fShops.filter(
      (s) => channels.indexOf(s.channel_code) >= 0,
    );
  } else if (countries && countries.length > 0) {
    fShops = fShops.filter(
      (s) => countries.indexOf(s.country_code) >= 0,
    );
  } else if (tools && tools > 0) {
    fShops = fShops.filter((s) =>
      tools.some((t) => s.features.find((f) => f.feature_code === t)),
    );
  }
  return fShops;
}

async function dataQuery({
  metrics,
  dateRange,
  dimensions,
  filters = [],
  orders = [],
  pagination,
}: {
  metrics: string[];
  dateRange: { from: string; to: string };
  dimensions: string[];
  filters?: [string, string, string][];
  orders?: [string, 'asc' | 'desc'][];
  pagination?: { page: number; limit: number };
}) {
  log('do query', {
    metrics,
    dateRange,
    dimensions,
    filters,
    orders,
  });

  const shopIds = await getShopIds(dimensions);

  const data = await dataRequest({
    dimensions,
    metrics,
    filters: [].concat(filters),
    orders: [].concat(orders),
    dateRange,
    pagination: Object.assign(
      {
        page: 0,
        limit: 100,
      },
      pagination,
    ),
  });

  const metricLabels = await getAllMetrics(shopIds);

  const result = {
    data: data.data,
    pagination: data.pagination,
    metricLabels,
  };

  return result;
}

export { dataQuery };

export const exportToCSV = (
  data: any,
  headers?: any,
  fileName?: any,
) => {
  const fileData = toCSV(data, headers);
  const fileType = 'text/csv;charset=UTF-8';
  console.log('Export CSV data', fileData);
  downloadFile(fileData, fileName || 'table-data', fileType);
};

export const getChartLibFilter = (
  nodeData: NodeData,
  filters: FilterType,
) => {
  const defaultChannels = get(filters, 'channels', []);
  const defaultCountries = get(filters, 'countries', []);
  const defaultTools = get(filters, 'tools', []);
  const defaultShops = get(filters, 'shops', []);

  const channels = get(
    nodeData,
    'mainAttributes.dimensions.channels',
    defaultChannels,
  );
  const countries = get(
    nodeData,
    'mainAttributes.dimensions.countries',
    defaultCountries,
  );
  const tools = get(
    nodeData,
    'mainAttributes.dimensions.tools',
    defaultTools,
  );
  const shops = get(
    nodeData,
    'mainAttributes.dimensions.shops',
    defaultShops,
  );
  const metrics = get(nodeData, 'mainAttributes.metrics', []).map(
    (i) => String(i).toLowerCase(),
  );
  const cohorts = get(nodeData, 'mainAttributes.cohort', []);

  return {
    channels,
    countries,
    tools,
    shops,
    cohorts,
    metrics,
  };
};

export const buildRequestParams = (
  nodeData: NodeData,
  filters: FilterType,
) => {
  const { channels, tools, countries, shops, metrics } =
    getChartLibFilter(nodeData, filters);
  const params = {
    dimensions: [],
    filters: [],
    metrics,
  };

  if (shops.length > 0) {
    params.dimensions.push('shop_eid');
    params.filters.push(['shop_eid', 'in', JSON.stringify(shops)]);
  }

  if (tools.length > 0) {
    params.dimensions.push('features');
    params.filters.push(['features', 'in', JSON.stringify(tools)]);
  }

  if (countries.length > 0) {
    params.dimensions.push('country');
    params.filters.push(['country', 'in', JSON.stringify(countries)]);
  }

  if (channels.length > 0) {
    params.dimensions.push('channel');
    params.filters.push(['channel', 'in', JSON.stringify(channels)]);
  }

  return params;
};
