import * as React from 'react';
import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';

import { useToast } from '@ep/insight-ui/elements/notifications/hook';

const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

const endpoint = {
  REQUEST_OTP: API_URL + '/root-management/request-otp',
  RENEW_COOKIE_BY_OTP: API_URL + '/root-management/renew-cookie-by-otp',
};

const defaultTimeToResend = 60;

export const useRequestOtp = ({ storefrontId }: any) => {
  const [open, setOpen] = React.useState<boolean>(false);
  const [needOtp, setNeedOtp] = React.useState<boolean>(false);
  const { onToastMultiple } = useToast();

  const requestOtp = async () => {
    const response = await request.get(`${endpoint.REQUEST_OTP}?storefront_id=${storefrontId}`);
    if (response.serviceResponse.success) {
      setNeedOtp(response.serviceResponse.code !== 0);
      setOpen(true);
    } else {
      const messages = [
        'An error occurred when connecting to Seller Center.',
        'Please retry Re-connecting again',
        'Or directly contact us via support@epsilo.io if issue persist',
      ];
      onToastMultiple({
        title: 'Failed to connect with the Seller Center',
        variant: 'error',
        messages,
      });
    }
    return response;
  };

  return {
    requestOtp,
    open,
    setOpen,
    needOtp,
  };
};

export const useVerifyOtp = ({ storefrontId, onClose = () => undefined, step, setStep, backbone }: any) => {
  const [otp, setOtp] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const [requesting, setRequesting] = React.useState(false);
  const [timeToResend, setTimeToResend] = React.useState(0);

  React.useEffect(() => {
    setErrorMessage('');
  }, [otp]);

  React.useEffect(() => {
    if (timeToResend > 0) {
      const timeout = setTimeout(() => {
        setTimeToResend(timeToResend - 1);
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [timeToResend]);

  const requestOtp = async () => {
    setRequesting(true);
    const response = await request.get(`${endpoint.REQUEST_OTP}?storefront_id=${storefrontId}`);
    if (!response.serviceResponse.success) {
      setErrorMessage('Failed to request OTP!');
    }
    setRequesting(false);
    return response;
  };

  const requestCookie = async (otp) => {
    setRequesting(true);
    const response = await request.get(`${endpoint.RENEW_COOKIE_BY_OTP}?storefront_id=${storefrontId}&otp=${otp}`);

    if (!response.serviceResponse.success) {
      const errorMsg = response.serviceResponse.message.split(' - ')[1];
      setErrorMessage(errorMsg ? errorMsg : 'Failed to re-connect to Seller Center!');
    }
    setRequesting(false);

    return response;
  };

  const handleClose = () => {
    onClose();
    if (step === 2) {
      backbone.reloadData('table');
    } else {
      setStep(1);
    }
  };

  const handleResendOtp = async () => {
    setTimeToResend(defaultTimeToResend);

    await requestOtp();
  };

  const handleRequestCookie = async (otp) => {
    const response = await requestCookie(otp);

    if (response.serviceResponse.success) {
      setStep(2);
    }
  };

  return {
    otp,
    setOtp,
    requestOtp,
    requestCookie,
    requesting,
    errorMessage,
    setErrorMessage,
    handleClose,
    timeToResend,
    setTimeToResend,
    handleResendOtp,
    handleRequestCookie,
    defaultTimeToResend,
  };
};
