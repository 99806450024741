import React from 'react';
import clsx from 'clsx';
import numeral from 'numeral';

import { Button, makeStyles, Slider, Tooltip, withStyles } from '@material-ui/core';

import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';

import { usePriceBook } from './hooks/use-price-book';
import ConditionalWrap from '../util/conditional-wrap';

const iOSBoxShadow = '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)';

const IOSSlider = withStyles({
  root: {
    color: '#3880ff',
    height: 2,
    padding: '15px 0',
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    boxShadow: iOSBoxShadow,
    marginTop: -14,
    marginLeft: -14,
    '&:focus, &:hover, &$active': {
      boxShadow: '0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        boxShadow: iOSBoxShadow,
      },
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 4,
  },
  rail: {
    height: 4,
    opacity: 1,
    backgroundColor: '#bfbfbf',
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 4,
    marginTop: 3,
    '&[data-index="8"]': {
      opacity: 0,
    },
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
    '&[data-index="0"]': {
      opacity: 0,
    },
  },
  markLabel: {
    fontWeight: 500,
  },
})(Slider);

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    width: '100%',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    position: 'relative',
    marginBottom: '20px',
    '& .eip1-MuiSlider-mark:last-child': {
      opacity: 0,
    },
    maxWidth: '1280px',
  },
  otherContainer: {
    marginTop: '22px',
    display: 'flex',
    flexDirection: 'column',
    gap: '42px',
    position: 'relative',
    marginBottom: '20px',
  },
  subContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    position: 'relative',
  },
  layoutCol: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
    position: 'relative',
  },
  fullWidth: {
    alignSelf: 'stretch',
  },
  planText: {
    fontSize: '2rem',
    lineHeight: '1.25',
    fontWeight: 500,
    margin: 0,
  },
  horizontalLine: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    pointerEvents: 'none',
    width: '100%',
    height: '0px',
    flex: '0 0 auto',
  },
  separator: {
    width: '100%',
    height: '1px',
    visibility: 'visible',
    borderBottom: '1px solid #e3e2e080',
  },
  stickyHeader: {
    position: 'sticky',
    top: '35px',
    paddingTop: '20px',
    marginTop: '-30px',
    background: '#fff',
    zIndex: 999,
    '&.sticking': {
      boxShadow: 'rgba(22, 29, 27, 0.02) 0px 3px 4px 0px',
      paddingBottom: '16px',
      borderBottom: '1px solid rgb(227, 226, 224)',
    },
  },
  layoutRow: {
    display: 'flex',
    flexDirection: 'row',
    gap: '12px',
    borderRadius: '2px',
  },
  planName: {
    fontSize: '22px',
    fontWeight: 500,
    lineHeight: '26px',
  },
  planPrice: {
    color: '#787774',
    fontSize: '10px',
    lineHeight: '13px',
  },
  firstColumn: {
    width: '100%',
    maxWidth: '250px',
    minWidth: '250px',
    fontSize: '14px',
    padding: '12px',
    paddingRight: 0,
    fontWeight: 500,
  },
  nextColumn: {
    width: '100%',
    minWidth: '150px',
    fontSize: '14px',
    padding: '12px 0',
    '&:last-child': {
      paddingRight: '12px',
    },
  },
  headerTitle: {
    display: 'flex',
    flexFlow: 'wrap',
    gap: '6px',
    width: '100%',
    alignItems: 'center',
  },
  popular: {
    padding: '2px 4px',
    background: '#2383e212',
    color: '#2383e2',
    borderRadius: '4px',
    fontSize: '11px',
    fontWeight: 500,
    lineHeight: 1.3,
    textWrap: 'no-wrap',
  },
  button: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '6px 8px',
    height: '24px',
    borderRadius: '4px',
    boxShadow: 'unset !important',
    width: 'fit-content',
  },
  highlightsList: {
    paddingInlineStart: '0px',
    marginBlock: '0px',
    listStyleType: 'none',
  },
  secondaryBackground: {
    background: '#f9f9f8',
  },
  featureName: {
    fontSize: '14px',
    fontWeight: 500,
    paddingLeft: '12px',
    color: '#787774',
  },
  featureHeight: {
    height: '64px',
  },
  featureDescription: {
    cursor: 'help',
  },
  tooltipContainer: {
    background: '#253746',
    fontSize: '12px',
  },
  semiHeader: {
    fontSize: '16px',
    lineHeight: '22px',
    fontWeight: 500,
  },
  link: {
    color: '#787774',
    fontSize: '14px',
    lineHeight: '18px',
    '& a': {
      textDecoration: 'underline',
      color: '#787774',
    },
  },
  outlinedButton: {
    '&:hover': {
      background: '#37352f0f',
      borderColor: '#0000003b',
      '&.eip1-MuiButton-outlined span': {
        color: '#8D979E',
      },
    },
  },
  sliderContainer: {
    padding: '38px 54px',
    borderRadius: '8px',
    border: '1px solid #d7d5d2',
    '& h3': {
      fontSize: '26px',
      fontWeight: 600,
      lineHeight: '38px',
      marginTop: 0,
      marginBottom: '12px',
    },
  },
  scaleLabel: {
    display: 'inline-flex',
    backgroundColor: '#f0f1fa',
    color: '#2d2e2e',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '3px 8px',
    borderRadius: '30px',
  },
  tagline: {
    alignItems: 'center',
    '& h1': {
      fontWeight: 600,
    },
  },
}));

const PriceBook = () => {
  const classes = useStyles();
  const { loading, priceBooks, plans, costValue, setCostValue, marks } = usePriceBook();
  const { header, highlights, others } = priceBooks || {};

  const stickyHeaderRef = React.useRef(null);

  React.useEffect(() => {
    const handleScrollEvent = () => {
      const stickyElement = stickyHeaderRef.current;
      if (stickyElement) {
        const stickyOffsetTop = stickyElement.offsetTop + 40;

        if (window.pageYOffset >= stickyOffsetTop) {
          stickyElement.classList.add('sticking');
        } else {
          stickyElement.classList.remove('sticking');
        }
      }
    };

    window.addEventListener('scroll', handleScrollEvent);

    return () => {
      window.removeEventListener('scroll', handleScrollEvent);
    };
  }, [stickyHeaderRef.current]);

  const scale = (value) => {
    return marks[value]?.scaledValue || value;
  };

  function numFormatter(num) {
    return numeral(num).format('0.0a');
  }

  if (loading) return <LoadingIcon />;
  if (!priceBooks || !header || !highlights || !others) return <span>No data available.</span>;
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <div className={clsx(classes.fullWidth, classes.subContainer, classes.tagline)}>
          <h1 className={classes.planText}>A closed-loop operating hub for retail media team of all sizes</h1>
        </div>
        <div className={classes.sliderContainer}>
          <div style={{ height: '52px' }}>
            {!costValue ? (
              <h3>How much do you need to spend on ads per month?</h3>
            ) : (
              <h3>
                I need to spend <span className={classes.scaleLabel}>{numeral(scale(costValue)).format('#,###')}$</span>{' '}
                per month
              </h3>
            )}
          </div>
          <IOSSlider
            value={costValue}
            min={0}
            step={1}
            max={marks.length - 1}
            valueLabelFormat={numFormatter}
            marks={marks}
            scale={scale}
            onChange={(_, value) => setCostValue(value)}
            aria-labelledby="non-linear-slider"
          />
        </div>
        <div className={clsx(classes.fullWidth, classes.subContainer)}>
          <h1 className={classes.planText}>All plans</h1>
          <div className={classes.horizontalLine} style={{ zIndex: 9999 }}>
            <div className={classes.separator}></div>
          </div>
        </div>
        <div className={clsx(classes.layoutRow, classes.stickyHeader)} ref={stickyHeaderRef}>
          <div className={classes.firstColumn}></div>
          {plans.map((plan) => {
            return (
              <div key={plan} className={clsx(classes.layoutCol, classes.nextColumn)}>
                <div className={classes.headerTitle}>
                  <span className={classes.planName}>
                    {String(plan)
                      .split('_')
                      .map((i) => String(i)[0]?.toUpperCase() + String(i).slice(1))
                      .join(' ')}
                  </span>
                  {String(plan).toLowerCase() == 'individual' ? <div className={classes.popular}>Popular</div> : null}
                </div>
                <span className={classes.planPrice}>{header[plan]}</span>
                {String(plan).toLowerCase() == 'individual' ? (
                  <Button variant="contained" color="primary" className={classes.button}>
                    Upgrade
                  </Button>
                ) : String(plan).toLowerCase() != 'starter' ? (
                  <Button variant={'outlined'} className={clsx(classes.button, classes.outlinedButton)}>
                    Upgrade
                  </Button>
                ) : null}
              </div>
            );
          })}
        </div>
        <div className={clsx(classes.layoutRow, classes.secondaryBackground)}>
          <div className={classes.firstColumn}>Highlights</div>
          {plans.map((plan) => {
            return (
              <div key={plan} className={clsx(classes.layoutCol, classes.nextColumn)}>
                <ul className={classes.highlightsList}>
                  {highlights
                    .filter((hl) => !!hl[plan])
                    .map((hl) => {
                      return (
                        <li key={hl[plan]}>
                          <span>•</span>
                          <span> {hl[plan]}</span>
                        </li>
                      );
                    })}
                </ul>
              </div>
            );
          })}
        </div>
        <div className={classes.otherContainer}>
          {others.map((other) => {
            return (
              <div key={other.id}>
                <div className={clsx(classes.fullWidth, classes.subContainer)}>
                  <span className={classes.featureName}>{other.feature_name}</span>
                  <div className={classes.horizontalLine}>
                    <div className={classes.separator}></div>
                  </div>
                </div>
                {other.children.map((child, index) => {
                  return (
                    <div
                      key={child.id}
                      className={clsx(
                        classes.layoutRow,
                        index % 2 == 1 ? classes.secondaryBackground : '',
                        classes.featureHeight,
                      )}
                    >
                      <ConditionalWrap
                        condition={child.feature_description}
                        wrap={(children) => {
                          return (
                            <Tooltip
                              title={child.feature_description}
                              placement={'right'}
                              classes={{ tooltip: classes.tooltipContainer }}
                            >
                              {children}
                            </Tooltip>
                          );
                        }}
                      >
                        <div
                          className={clsx(
                            classes.firstColumn,
                            child.feature_description ? classes.featureDescription : '',
                          )}
                        >
                          {child.feature_name}
                        </div>
                      </ConditionalWrap>
                      {plans.map((plan) => {
                        return (
                          <div key={plan} className={clsx(classes.layoutCol, classes.nextColumn)}>
                            <div>
                              <span>{child[plan] ? child[plan] : '－'}</span>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={classes.layoutCol}>
          <div className={clsx(classes.fullWidth)}>
            <div className={classes.semiHeader}>FAQ</div>
            <div className={classes.link}>
              <a href={'https://www.epsilo.ai/pricing'} target={'_blank'} rel="noreferrer">
                Plans, Billing & Payment
              </a>
            </div>
          </div>
          <div className={clsx(classes.fullWidth)}>
            <Button variant={'outlined'} className={clsx(classes.button, classes.outlinedButton)}>
              Message support
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PriceBook;
