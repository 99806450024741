export default [
  {
    metric_code: 'impression',
    metric_name: 'Impressions',
    prefix_value: null,
  },
  {
    metric_code: 'click',
    metric_name: 'Clicks',
    prefix_value: null,
  },
  {
    metric_code: 'item_sold',
    metric_name: 'Ads Items Sold',
    prefix_value: null,
  },
  {
    metric_code: 'gmv',
    metric_name: 'Ads GMV',
    prefix_value: '$',
  },
  {
    metric_code: 'cost',
    metric_name: 'Cost',
    prefix_value: '$',
  },
  {
    metric_code: 'total_sku',
    metric_name: '#SKU',
    prefix_value: null,
  },
  {
    metric_code: 'total_keyword',
    metric_name: '#KW',
    prefix_value: null,
  },
  {
    metric_code: 'total_gmv',
    metric_name: 'Total GMV',
    prefix_value: '$',
  },
  {
    metric_code: 'total_item_sold',
    metric_name: 'Total Items Sold',
    prefix_value: null,
  },
  {
    metric_code: 'wallet',
    metric_name: 'Wallet',
    prefix_value: '$',
  },
  {
    metric_code: 'ctr',
    metric_name: 'CTR',
    prefix_value: '%',
  },
  {
    metric_code: 'cr',
    metric_name: 'CR',
    prefix_value: '%',
  },
  {
    metric_code: 'cpc',
    metric_name: 'CPC',
    prefix_value: '$',
  },
  {
    metric_code: 'cpi',
    metric_name: 'CPI',
    prefix_value: '$',
  },
  {
    metric_code: 'cir',
    metric_name: 'CIR',
    prefix_value: '%',
  },
  {
    metric_code: 'percent_gmv_over_total',
    metric_name: '% Ads GMV Contribution',
    prefix_value: '%',
  },
  {
    metric_code: 'percent_item_sold_over_total',
    metric_name: '% Ads Items Sold Contribution',
    prefix_value: '%',
  },
  {
    metric_code: 'roas',
    metric_name: 'ROAS',
    prefix_value: null,
  },
  {
    metric_code: 'direct_gmv',
    metric_name: 'Direct Ads GMV',
    prefix_value: '$',
  },
  {
    metric_code: 'direct_item_sold',
    metric_name: 'Direct Ads Items Sold',
    prefix_value: null,
  },
  {
    metric_code: 'total_order',
    metric_name: 'Total Order',
    prefix_value: null,
  },
  {
    metric_code: 'ads_order',
    metric_name: 'Ads Order',
    prefix_value: null,
  },
  {
    metric_code: 'direct_ads_order',
    metric_name: 'Direct Ads Order',
    prefix_value: null,
  },
  {
    metric_code: 'direct_cr',
    metric_name: 'Direct CR',
    prefix_value: '%',
  },
  {
    metric_code: 'direct_cpi',
    metric_name: 'Direct CPI',
    prefix_value: '$',
  },
  {
    metric_code: 'direct_cir',
    metric_name: 'Direct CIR',
    prefix_value: '%',
  },
  {
    metric_code: 'direct_percent_gmv_over_total',
    metric_name: '% Direct Ads GMV Contribution',
    prefix_value: '%',
  },
  {
    metric_code: 'direct_percent_item_sold_over_total',
    metric_name: '% Direct Ads Items Sold Contribution',
    prefix_value: '%',
  },
  {
    metric_code: 'direct_roas',
    metric_name: 'Direct ROAS',
    prefix_value: null,
  },
  {
    metric_code: 'conversion',
    metric_name: 'Ads conversions',
    prefix_value: null,
  },
  {
    metric_code: 'direct_conversion',
    metric_name: 'Direct ads conversions',
    prefix_value: null,
  },
  {
    metric_code: 'cost_saving',
    metric_name: 'Cost Saving',
    prefix_value: '$',
  },
  // insight metrics
  {
    metric_code: 'user_analysis.metric_value',
    metric_name: 'Metric Value (insight.user_analysis.metric_value)',
    prefix_value: '',
  },
  { metric_code: 'off_platform.ads_gmv', metric_name: 'Ads GMV (insight.off_platform.ads_gmv)', prefix_value: '$' },
  { metric_code: 'off_platform.atc', metric_name: 'ATC (insight.off_platform.atc)', prefix_value: '' },
  {
    metric_code: 'off_platform.conversion_rate',
    metric_name: 'Conversion Rate (insight.off_platform.conversion_rate)',
    prefix_value: '',
  },
  {
    metric_code: 'off_platform.gross_order',
    metric_name: 'Gross Order (insight.off_platform.gross_order)',
    prefix_value: '',
  },
  {
    metric_code: 'off_platform.gross_unique_buyer',
    metric_name: 'Gross Unique Buyer (insight.off_platform.gross_unique_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'off_platform.gross_unit_sold',
    metric_name: 'Gross Unit Sold (insight.off_platform.gross_unit_sold)',
    prefix_value: '',
  },
  {
    metric_code: 'off_platform.new_buyer',
    metric_name: 'New Buyer (insight.off_platform.new_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'off_platform.unique_visitor',
    metric_name: 'Unique Visitor (insight.off_platform.unique_visitor)',
    prefix_value: '',
  },
  { metric_code: 'off_platform.visitor', metric_name: 'Visitor (insight.off_platform.visitor)', prefix_value: '' },
  { metric_code: 'product.atc', metric_name: 'ATC (insight.product.atc)', prefix_value: '' },
  { metric_code: 'product.atc_visitor', metric_name: 'ATC Visitor (insight.product.atc_visitor)', prefix_value: '' },
  {
    metric_code: 'product.conversion_rate',
    metric_name: 'Conversion Rate (insight.product.conversion_rate)',
    prefix_value: '%',
  },
  {
    metric_code: 'product.current_stock',
    metric_name: 'Current Stock (insight.product.current_stock)',
    prefix_value: '',
  },
  { metric_code: 'product.gmv', metric_name: 'GMV (insight.product.gmv)', prefix_value: '$' },
  {
    metric_code: 'product.gross_average_basket_size',
    metric_name: 'Gross Average Basket Size (insight.product.gross_average_basket_size)',
    prefix_value: '$',
  },
  {
    metric_code: 'product.gross_average_selling_price',
    metric_name: 'Gross Average Selling Price (insight.product.gross_average_selling_price)',
    prefix_value: '$',
  },
  { metric_code: 'product.gross_order', metric_name: 'Gross Order (insight.product.gross_order)', prefix_value: '' },
  {
    metric_code: 'product.gross_unique_buyer',
    metric_name: 'Gross Unique Buyer (insight.product.gross_unique_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'product.gross_unit_sold',
    metric_name: 'Gross Unit Sold (insight.product.gross_unit_sold)',
    prefix_value: '',
  },
  { metric_code: 'product.net_order', metric_name: 'Net Order (insight.product.net_order)', prefix_value: '' },
  {
    metric_code: 'product.net_unique_buyer',
    metric_name: 'Net Unique Buyer (insight.product.net_unique_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'product.net_unit_sold',
    metric_name: 'Net Unit Sold (insight.product.net_unit_sold)',
    prefix_value: '',
  },
  { metric_code: 'product.nmv', metric_name: 'NMV (insight.product.nmv)', prefix_value: '$' },
  {
    metric_code: 'product.product_rating',
    metric_name: 'Product Rating (insight.product.product_rating)',
    prefix_value: '',
  },
  {
    metric_code: 'product.product_view',
    metric_name: 'Product View (insight.product.product_view)',
    prefix_value: '',
  },
  {
    metric_code: 'product.product_visitor',
    metric_name: 'Product Visitor (insight.product.product_visitor)',
    prefix_value: '',
  },
  {
    metric_code: 'product.revenue_per_buyer',
    metric_name: 'Revenue Per Buyer (insight.product.revenue_per_buyer)',
    prefix_value: '$',
  },
  {
    metric_code: 'product.visitor_value',
    metric_name: 'Visitor Value (insight.product.visitor_value)',
    prefix_value: '$',
  },
  { metric_code: 'product.wishlist', metric_name: 'Wishlist (insight.product.wishlist)', prefix_value: '' },
  {
    metric_code: 'product.wishlist_visitor',
    metric_name: 'Wishlist Visitor (insight.product.wishlist_visitor)',
    prefix_value: '',
  },
  { metric_code: 'on_platform.ads_gmv', metric_name: 'Ads GMV (insight.on_platform.ads_gmv)', prefix_value: '$' },
  { metric_code: 'on_platform.cir', metric_name: 'CIR (insight.on_platform.cir)', prefix_value: '' },
  { metric_code: 'on_platform.click', metric_name: 'Click (insight.on_platform.click)', prefix_value: '' },
  {
    metric_code: 'on_platform.conversion_rate',
    metric_name: 'Conversion Rate (insight.on_platform.conversion_rate)',
    prefix_value: '',
  },
  { metric_code: 'on_platform.cost', metric_name: 'Cost (insight.on_platform.cost)', prefix_value: '' },
  { metric_code: 'on_platform.cpc', metric_name: 'CPC (insight.on_platform.cpc)', prefix_value: '$' },
  { metric_code: 'on_platform.ctr', metric_name: 'CTR (insight.on_platform.ctr)', prefix_value: '' },
  {
    metric_code: 'on_platform.gross_average_basket_size',
    metric_name: 'Gross Average Basket Size (insight.on_platform.gross_average_basket_size)',
    prefix_value: '$',
  },
  {
    metric_code: 'on_platform.gross_order',
    metric_name: 'Gross Order (insight.on_platform.gross_order)',
    prefix_value: '',
  },
  {
    metric_code: 'on_platform.gross_unit_sold',
    metric_name: 'Gross Unit Sold (insight.on_platform.gross_unit_sold)',
    prefix_value: '',
  },
  {
    metric_code: 'on_platform.impression',
    metric_name: 'Impression (insight.on_platform.impression)',
    prefix_value: '',
  },
  { metric_code: 'on_platform.roi', metric_name: 'ROI (insight.on_platform.roi)', prefix_value: '' },
  { metric_code: 'storefront.atc', metric_name: 'ATC (insight.storefront.atc)', prefix_value: '' },
  {
    metric_code: 'storefront.atc_visitor',
    metric_name: 'ATC Visitor (insight.storefront.atc_visitor)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.conversion_rate',
    metric_name: 'Conversion Rate (insight.storefront.conversion_rate)',
    prefix_value: '%',
  },
  {
    metric_code: 'storefront.current_stock',
    metric_name: 'Current Stock (insight.storefront.current_stock)',
    prefix_value: '',
  },
  { metric_code: 'storefront.gmv', metric_name: 'GMV (insight.storefront.gmv)', prefix_value: '$' },
  {
    metric_code: 'storefront.gmv_target',
    metric_name: 'GMV Target (insight.storefront.gmv_target)',
    prefix_value: '$',
  },
  {
    metric_code: 'storefront.gross_average_basket_size',
    metric_name: 'Gross Average Basket Size (insight.storefront.gross_average_basket_size)',
    prefix_value: '$',
  },
  {
    metric_code: 'storefront.gross_average_selling_price',
    metric_name: 'Gross Average Selling Price (insight.storefront.gross_average_selling_price)',
    prefix_value: '$',
  },
  {
    metric_code: 'storefront.gross_order',
    metric_name: 'Gross Order (insight.storefront.gross_order)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.gross_unique_buyer',
    metric_name: 'Gross Unique Buyer (insight.storefront.gross_unique_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.gross_unit_sold',
    metric_name: 'Gross Unit Sold (insight.storefront.gross_unit_sold)',
    prefix_value: '',
  },
  { metric_code: 'storefront.net_order', metric_name: 'Net Order (insight.storefront.net_order)', prefix_value: '' },
  {
    metric_code: 'storefront.net_unique_buyer',
    metric_name: 'Net Unique Buyer (insight.storefront.net_unique_buyer)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.net_unit_sold',
    metric_name: 'Net Unit Sold (insight.storefront.net_unit_sold)',
    prefix_value: '',
  },
  { metric_code: 'storefront.nmv', metric_name: 'NMV (insight.storefront.nmv)', prefix_value: '$' },
  {
    metric_code: 'storefront.percent_in_stock',
    metric_name: 'Percent In Stock (insight.storefront.percent_in_stock)',
    prefix_value: '%',
  },
  {
    metric_code: 'storefront.percent_in_stock_weighted',
    metric_name: 'Percent In Stock (Weighted) (insight.storefront.percent_in_stock_weighted)',
    prefix_value: '%',
  },
  {
    metric_code: 'storefront.percent_selling_sku',
    metric_name: 'Percent Selling SKU (insight.storefront.percent_selling_sku)',
    prefix_value: '%',
  },
  {
    metric_code: 'storefront.product_rating',
    metric_name: 'Product Rating (insight.storefront.product_rating)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.product_view',
    metric_name: 'Product View (insight.storefront.product_view)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.product_visitor',
    metric_name: 'Product Visitor (insight.storefront.product_visitor)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.revenue_per_buyer',
    metric_name: 'Revenue Per Buyer (insight.storefront.revenue_per_buyer)',
    prefix_value: '$',
  },
  {
    metric_code: 'storefront.shop_page_view',
    metric_name: 'Shop Page View (insight.storefront.shop_page_view)',
    prefix_value: '',
  },
  {
    metric_code: 'storefront.visitor_value',
    metric_name: 'Visitor Value (insight.storefront.visitor_value)',
    prefix_value: '$',
  },
  { metric_code: 'storefront.wishlist', metric_name: 'Wishlist (insight.storefront.wishlist)', prefix_value: '' },
  {
    metric_code: 'storefront.wishlist_visitor',
    metric_name: 'Wishlist Visitor (insight.storefront.wishlist_visitor)',
    prefix_value: '',
  },
];
