import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RemixUnderline = (props: SvgIconProps) => (
  <SvgIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path fill="none" d="M0 0h24v24H0z" />
    <path d="M8 3v9a4 4 0 1 0 8 0V3h2v9a6 6 0 1 1-12 0V3h2zM4 20h16v2H4v-2z" />
  </SvgIcon>
);

export default RemixUnderline;
