import * as React from 'react';
import { FixedSizeList } from 'react-window';
import clsx from 'clsx';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { Avatar, Box, Divider, List, ListItem, makeStyles } from '@material-ui/core';
import { useWorkspaceMenu } from '@ep/insight-ui/eo2/hooks/use-workspace-menu';
import Icon from '@ep/insight-ui/icons/Icon';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import ConditionalWrap from '../util/conditional-wrap';
import TooltipDark from '../tooltip/tooltip-dark';
import { stringToColor } from '@ep/insight-ui/sw/util/helper';

const HEIGHT = 40;

const useStyles = makeStyles(() => ({
  container: {
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '12px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      border: '4px solid #fff',
      borderRadius: '16px',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a0a0a5',
      border: '4px solid #fff',
    },
    overflowX: 'hidden !important',
  },
  listItem: {
    padding: 0,
    height: '100%',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    width: '100%',
    height: '100%',
    borderRadius: '4px',
    padding: '0 12px',
    height: '40px',
    '&.active': {
      background: '#EBF6FF',
      color: '#0369c7',
    },
    '&:not(.active):hover': {
      background: '#e4e7e9',
    },
  },
  itemIcon: {
    width: '14px',
    flex: '0 0 14px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'grab',
  },
  itemLabel: {
    cursor: 'pointer',
    height: '100%',
    width: (props) => (props.isScrollShow ? `calc(100% - 12px)` : '100%'),
    display: 'flex',
    alignItems: 'center',
    '& div': {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    columnGap: '10px',
  },
  wsLogoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '32px',
    height: '32px',
    position: 'relative',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& .eip1-MuiAvatar-root': {
      width: '32px',
      height: '32px',
      fontSize: '1rem',
    },
  },
  wsLogo: {
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '32px',
    height: '32px',
  },
}));
const Row = ({ data, index, style }: any) => {
  const label = data.options[index].title;
  const id = data.options[index].id;
  const active = data.options[index].active;
  const logo = data.options[index].logo;
  const { count, topShownWorkspace, setOpenMenu } = data;
  const onClick = (...args) => {
    data.options[index].onClick(...args);
    if (setOpenMenu) {
      setOpenMenu(false);
    }
  };
  const shortLabel = label
    .trim()
    .split(' ')
    .filter((i) => !!i)
    .map((i) => i[0].toUpperCase())
    .slice(0, 2)
    .join('');

  const classes = useStyles({ isScrollShow: count > topShownWorkspace });

  const [needTooltip, setNeedTooltip] = React.useState(false);

  const updatedTextRef = React.useRef(null);

  React.useEffect(() => {
    if (updatedTextRef.current) {
      setNeedTooltip(updatedTextRef.current.offsetWidth < updatedTextRef.current.scrollWidth);
    }
  }, [updatedTextRef.current]);

  return (
    <DragCard index={index} moveCard={data.moveCard} onDrop={data.onDrop}>
      <div style={style}>
        <ListItem className={classes.listItem}>
          <Box className={clsx(classes.item, active ? 'active' : '')}>
            <Box className={classes.itemIcon}>
              <Icon type={'draggable'} />
            </Box>
            <ConditionalWrap
              condition={needTooltip}
              wrap={(children) => {
                return (
                  <TooltipDark title={label} fontSize={'12px'}>
                    {children}
                  </TooltipDark>
                );
              }}
            >
              <Box onClick={onClick} className={classes.itemLabel}>
                <Box className={classes.wsLogoContainer}>
                  <Avatar style={{ backgroundColor: stringToColor(shortLabel) }}>{shortLabel}</Avatar>
                  <div
                    className={classes.wsLogo}
                    style={{
                      backgroundImage: `url('${logo}')`,
                    }}
                  />
                </Box>
                <MonitorContainer mId={'workspace'} mLabel={'Workspace'} data-mid={id}>
                  <div ref={updatedTextRef}>{label}</div>
                </MonitorContainer>
              </Box>
            </ConditionalWrap>
          </Box>
        </ListItem>
      </div>
    </DragCard>
  );
};

const WorkspaceSection = (props: { setOpenMenu?: (value: boolean) => void }) => {
  const classes = useStyles();

  const { workspacesTopBar, moveCard, onDrop } = useWorkspaceMenu();

  const topShownWorkspace = getConst('TOP_SHOWN_WORKSPACES', 10);

  return (
    <Box>
      <List>
        <DndProvider context={window} backend={HTML5Backend}>
          <FixedSizeList
            height={Math.min(topShownWorkspace * HEIGHT, workspacesTopBar.length * HEIGHT)}
            itemData={{
              options: workspacesTopBar,
              moveCard,
              onDrop,
              count: workspacesTopBar.length,
              topShownWorkspace,
              setOpenMenu: props?.setOpenMenu,
            }}
            itemCount={workspacesTopBar.length}
            itemSize={HEIGHT}
            width={getConst('WORKSPACE_WIDTH', 280)}
            className={classes.container}
          >
            {Row}
          </FixedSizeList>
        </DndProvider>
      </List>
      <Divider style={{ margin: '4px -8px 4px -8px' }} />
    </Box>
  );
};

export default WorkspaceSection;
