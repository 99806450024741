import { cloneDeep, get } from 'lodash';
import * as React from 'react';

import { AgGridColumnProps } from '@ag-grid-community/react';

import { EIP_CONSTANT } from '@eip/next/lib/main';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';

import { getActionColDef } from '../utils/action-col-def';
import { ACTION_CATEGORY_INLINE_FORM } from '../utils/constant';
import { defaultInlineEditData } from '../action-components/inline-edit/data/default-data';
import { actionButtons as commonActionButtons } from '../utils/form-setup';
import { GridApi } from '@ag-grid-community/core';

export const useButtonEtableConfig = ({ config, onSubmit, open }) => {
  const tableDOM = React.useRef(null);

  const [gridApi, setGridApi] = React.useState<GridApi>(null);
  const [isEmpty, setIsEmpty] = React.useState(false);
  const [etableList, setEtableList] = React.useState([]);
  const [roles, setRoles] = React.useState([]);

  React.useEffect(() => {
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/system/block', {
        groupId: 'botep',
        type: 'richTable',
        page: 0,
        limit: 1000,
        attributes: ['title', 'parent_title', 'block_eid'],
      })
      .then((res) => {
        const filteredList = Object.values(res.block).map((el) => {
          const title = get(el, ['title'], '');
          const parentTitle = get(el, ['parent_title'], '');
          const id = get(el, ['block_eid'], '');
          return {
            label: `${parentTitle} / ${title}`,
            value: id,
          };
        });
        setEtableList(filteredList);
      });
    request.get(EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/common/collect-workspace-role').then((res) => {
      if (res.success) {
        setRoles(
          res.data.map((el) => ({
            label: el.name || el.label,
            value: el.id || el.value,
          })),
        );
      }
    });
  }, []);

  const defaultColDef = React.useMemo(() => {
    return {
      flex: 1,
      editable: true,
      resizable: true,
      minWidth: 100,
      suppressKeyboardEvent: () => {
        return true;
      },
    };
  }, []);

  const etableConfigContext = React.useMemo(() => {
    return {
      mapping: get(config, ['mapping'], {}),
      endpoint: {
        GET_TABLE_DATA: get(config, ['endpoint', 'GET_TABLE_DATA'], ''),
      },
    };
  }, [config]);

  const columnDefs: AgGridColumnProps[] = React.useMemo(() => {
    const defaultColDefs = [
      {
        field: 'checkbox',
        pinned: 'left',
        maxWidth: 40,
        resizable: false,
        editable: false,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellClass: 'cell-checkbox',
        headerClass: 'header-checkbox',
        headerName: '',
      },
    ];

    const colDefs = get(
      getActionColDef(etableConfigContext, etableList, roles),
      ['cellRendererParams', 'config', 'colDefs'],
      [],
    );

    return defaultColDefs.concat(colDefs);
  }, [etableConfigContext, etableList, roles]);

  const rowData = React.useMemo(() => {
    return cloneDeep(
      get(config, ['customAction', 'actions'], []).map((el) => {
        return {
          ...el,
          role: get(el, ['role'], [1, 2, 3]),
        };
      }),
    );
  }, [open]);

  const handleRowChange = (api) => {
    setIsEmpty(api.getDisplayedRowCount() === 0);
  };

  const handleSubmit = () => {
    const actions = [];
    gridApi.forEachNode((node) => actions.push(node.data));

    const newConfig = {
      ...config,
      customAction: {
        actions,
      },
    };
    onSubmit(newConfig);
  };

  const actionButtons = React.useMemo(() => {
    return [
      {
        id: 'duplicate',
        title: 'Duplicate selected items',
        onClick() {
          gridApi.applyTransaction({
            add: gridApi.getSelectedRows().map((r) => cloneDeep(r)),
          });
        },
      },
      {
        id: 'add_row',
        title: 'Add items',
        onClick() {
          gridApi.applyTransaction({
            add: [
              {
                category: ACTION_CATEGORY_INLINE_FORM,
                name: '',
                icon: '',
                disable: false,
                configuration: defaultInlineEditData,
                tracking: false,
                role: [1, 2, 3],
              },
            ],
            addIndex: 0,
          });
        },
      },
      {
        id: 'delete',
        title: 'Delete selected items',
        onClick() {
          gridApi.applyTransaction({
            remove: gridApi.getSelectedRows(),
          });
        },
      },
    ].concat(
      commonActionButtons.map((i) => {
        return {
          ...i,
          id: `mainbutton_${i.id}`,
        };
      }),
    );
  }, [gridApi]);

  return {
    tableDOM,
    setGridApi,
    gridApi,
    handleRowChange,
    isEmpty,
    defaultColDef,
    columnDefs,
    rowData,
    handleSubmit,
    actionButtons,
    etableConfigContext,
    roles,
  };
};
