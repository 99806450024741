import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';
import Eyes from '@ep/insight-ui/icons/svg/Eyes';
import Error from '@ep/insight-ui/icons/svg/Error';
import Correct from '@ep/insight-ui/icons/svg/Correct';
import SearchIcon from '@ep/insight-ui/icons/svg/SearchIcon';

import { colors, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, debounce, IconButton, InputAdornment, TextareaAutosize } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import * as React from 'react';
import * as _ from 'lodash';
import { CreateCSSProperties } from '@material-ui/styles';
import Icon from '@ep/insight-ui/icons/Icon';
import Typography from '../text-style/Typography';
/**
 * ff.ecompacttable_layout_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.ecompacttable_layout_mobile:end
 */
interface PropsStyle {
  noBorder: boolean;
  inputNoShadow: boolean;
  line: number;
  closeWidth: number;
  type: string;
}

const useStyles = makeStyles(() => ({
  textStyle: (props: PropsStyle) => ({
    ...(textFieldStyle as CreateCSSProperties),

    width: '100%',
    '& input': {
      padding: '0 10px',
      height: '32px',
      caretColor: '#006EC6',
      width: '100%',
      paddingRight: `${props.closeWidth && props.closeWidth + 22}px`,
      paddingLeft: '20px',
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: props.noBorder ? 'transparent' : '#C2C7CB',
    },
    '& .eip1-MuiOutlinedInput-multiline': {
      padding: '5px 10px',
      paddingRight: '3px',
      paddingLeft: '20px',
      caretColor: '#006EC6',
      width: '100%',
      maxHeight: '104px',
      minHeight: '32px',
      display: 'flex',
      flexBasis: 'content',
      alignItems: 'center',
      '& textarea': {
        paddingRight: `${props.closeWidth && props.closeWidth + 22}px`,
        lineHeight: '20px',
        maxHeight: '92px',
        overflow: 'auto !important',
        '&::-webkit-scrollbar': {
          backgroundColor: 'transparent',
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
        '&::-webkit-scrollbar-track:hover': {
          backgroundColor: 'transparent',
        },
        '&:hover::-webkit-scrollbar-thumb': {
          backgroundColor: '#babac0',
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: '#a0a0a5',
          borderRadius: '16px',
          //   border: '5px solid #F8FAFD',
        },
        '&::-webkit-scrollbar-thumb:hover': {
          backgroundColor: '#a0a0a5',
          //   border: '4px solid #f4f4f4',
        },
        '&::-webkit-scrollbar-button ': { display: 'none' },
      },
    },
    '& .eip1-MuiButtonBase-root': {
      width: 'auto',
      opacity: (props: PropsStyle) => (props.type === 'password' ? 1 : 0),
    },
    ...(ff.ecompacttable_layout_mobile
      ? {
          '&.textStyleMobile': {
            '& .eip1-MuiOutlinedInput-multiline': {
              background: '#fff',
              minHeight: '40px',
            },
            '& .eip1-MuiOutlinedInput-notchedOutline': {
              border: 'unset',
            },
          },
        }
      : {}),
  }),

  iconDelete: {
    padding: 0,
    borderRadius: '100%',
    '& svg': {
      width: '12px',
      color: '#000',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  countKW: {
    color: colors.text.subdued,
  },
}));

export interface ITextArea extends BaseTextFieldProps {
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  correct?: boolean;
  helperText?: string;
  className?: string;
  classNamePaperInput?: string;
  onChangeText?: (v: string) => void;
  onClear?: () => void;
  onTogglePassword?: () => void;
  line?: number;
  noShadow?: boolean;
  limit?: number;
  searchIcon?: boolean;
  multiline?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const TextArea = ({
  placeholder,
  defaultValue = '',
  disabled,
  error,
  correct,
  helperText,
  className,
  classNamePaperInput = '',
  onChangeText = () => undefined,
  onClear = () => undefined,
  onTogglePassword = () => undefined,
  noShadow = false,
  line = -1,
  limit = 100,
  multiline = true,
  searchIcon = true,
  ...res
}: ITextArea) => {
  const [value, setValue] = React.useState(defaultValue);
  const [count, setCount] = React.useState(0);
  const closeRef = React.useRef<HTMLDivElement>(null);
  const closeWidth = React.useMemo(() => closeRef?.current?.offsetWidth, [closeRef?.current?.offsetWidth]);
  let isMobileView;
  if (ff.ecompacttable_layout_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }
  const classes = useStyles({
    inputNoShadow: noShadow,
    line,
    type: res.type,
    closeWidth: closeWidth,
  });

  const handleChangeText = (value: string) => {
    onChangeText && onChangeText(value);
    if (multiline) return setCount(value === '' ? 0 : value.split('\n').length);
    setCount(value.length);
  };

  React.useEffect(() => {
    handleChange(value);
  }, [value]);

  const handleChange = _.debounce(handleChangeText, 100);

  return (
    <Box component={'div'} position="relative">
      <TextField
        className={clsx(classes.textStyle)}
        variant="outlined"
        multiline={multiline}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onKeyDown={(evt) => {
          res.onKeyDown && res.onKeyDown(evt);
          if (count === limit + 1) {
            evt.preventDefault();
          }
        }}
        InputProps={{
          className: clsx(classNamePaperInput, {
            ['Mui-error']: count > limit,
          }),
          startAdornment: searchIcon ? (
            <InputAdornment position={'start'}>
              <Box position={'absolute'} top={'6px'} left={'6px'}>
                <SearchIcon />
              </Box>
            </InputAdornment>
          ) : null,
        }}
        placeholder={placeholder}
      />
      <Box ref={closeRef} position={'absolute'} right={'14px'} top={'6px'} display={'flex'} gridGap={'12px'}>
        <IconButton
          className={classes.iconDelete}
          style={{}}
          onClick={() => {
            setValue('');
            onClear && onClear();
          }}
          disableTouchRipple
          disableFocusRipple
          disableRipple
        >
          <CloseFiled />
        </IconButton>
        <Box className={clsx(classes.countKW)}>
          <Typography variant="body2">
            {count}/{limit}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default TextArea;
