import * as React from 'react';
import clsx from 'clsx';

import { Box, ListItem, Divider, makeStyles, FormControl, TextField } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { ContainerResponsiveContext } from '@eip/next/lib/main';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import Icon from '@ep/insight-ui/icons/Icon';
import { searchStyle } from '@ep/insight-ui/lib/epsilo-theme';

import ModalConfirm from '@ep/insight-ui/elements/table/dropdown-views/modal-confirm';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';

const useStyles = makeStyles(() => ({
  itemItem: {
    position: 'relative',
    padding: 0,
  },
  itemItemInteract: {
    cursor: 'pointer',
  },
  untitledText: {
    color: '#8C98A4',
  },
  itemLabel: {
    padding: '0 6px',
    height: '32px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    marginLeft: '8px',
  },
  unSelectedItem: {
    '&:hover': {
      background: '#E4E7E9',
    },
  },
  selectedItem: {
    background: '#EBF6FF',
    color: '#0369C7',
    '&:hover': {
      background: '#EBF6FF',
    },
  },
  threeDots: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
    '& input::placeholder': {
      textTransform: 'unset',
    },
    '& input:focus': {
      outline: 'none',
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  textSearch: {
    '& input': {
      height: 'unset',
      width: '100%',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
}));

interface itemInterface {
  value?: string;
  label?: string;
  combinator?: any;
  isNew?: boolean;
}

interface IPropsMenuItem {
  item: itemInterface;
  selectItem: (item: itemInterface) => void;
  handleDuplicateItem: (item: itemInterface) => void;
  updateItem: (item: itemInterface) => void;
  deleteItem: (item: itemInterface) => void;
  allowDrag?: boolean;
}

const MenuItem = ({ item, selectItem, handleDuplicateItem, updateItem, deleteItem, allowDrag }: IPropsMenuItem) => {
  const classes = useStyles();

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const [name, setName] = React.useState(item.label);
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const [openConfirm, setOpenConfirm] = React.useState<boolean>(false);
  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (item.isNew) {
      setIsEditing(true);
      const newView = { ...item };
      delete item.isNew;
      updateItem(newView);
    }
  }, [item]);

  React.useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.querySelector('input').focus();
    }
  }, [isEditing]);
  React.useEffect(() => {
    if (!isEditing) {
      updateItem({ ...item, value: name, label: name });
    }
  }, [name, isEditing]);

  const handleSelectItem = (item) => {
    if (!isEditing) {
      selectItem({ ...item, label: name });
    }
  };

  return (
    <ListItem className={clsx(classes.itemItem)}>
      <Icon type={'draggable'} />
      <Box
        className={clsx(
          !item.label && classes.untitledText,
          classes.itemLabel,
          !isEditing && classes.unSelectedItem,
          !isEditing && classes.itemItemInteract,
        )}
        style={{ background: !isEditing && item.value && stringToTagColour(item.value) }}
        onClick={() => handleSelectItem(item)}
      >
        {isEditing ? (
          <FormControl fullWidth>
            <TextField
              style={{
                justifyContent: 'center',
                height: '32px',
                zIndex: 1,
                backgroundColor: '#fff',
              }}
              className={`${clsx(classes.textSearch, classes.textStyle)}`}
              placeholder={'Untitled'}
              onChange={(e) => {
                setName(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') setIsEditing(false);
              }}
              onBlur={() => setIsEditing(false)}
              value={name}
              variant={'outlined'}
              ref={inputRef}
            />
          </FormControl>
        ) : (
          <span>{item.label || 'Untitled'}</span>
        )}
      </Box>
      {item.allowConfig && (
        <Dropdown
          className={classes.threeDots}
          alignMenu="right"
          icon="threeDotsVertical"
          disabledToggleStyle
          label=""
          sizeIcon={'12px'}
          {...(isMobileView
            ? {
                dataMenuPage: [
                  [
                    {
                      title: 'Rename',
                      icon: 'rename',
                      iconSize: '16px',
                      colorStartIcon: '#2B3245',
                      className: 'item_action',
                      onClick: () => console.log(123456),
                    },
                    {
                      title: 'Duplicate item',
                      icon: 'duplicatePlus',
                      iconSize: '16px',
                      colorStartIcon: '#2B3245',
                      className: 'item_action',
                      onClick: () => console.log(123456),
                    },
                    {
                      title: 'Delete item',
                      icon: 'delete',
                      iconSize: '16px',
                      colorTitle: '#E4553E',
                      colorStartIcon: '#E4553E',
                      className: 'item_action',
                      onClick: () => console.log(123456),
                    },
                  ],
                ],
              }
            : {})}
        >
          <MenuList
            listMenu={[
              [
                {
                  title: 'Rename',
                  icon: 'pencil',
                  iconSize: '16px',
                  colorStartIcon: '#2B3245',
                  className: 'item_action',
                  onClick: () => setIsEditing(true),
                },
              ],
            ]}
            closeAfterClick
          />
          {/* <Divider />
        <MenuList
          listMenu={[
            [
              {
                title: 'Duplicate item',
                icon: 'duplicatePlus',
                iconSize: '16px',
                colorStartIcon: '#2B3245',
                className: 'item_action',
                onClick: () => handleDuplicateItem(item),
              },
              {
                title: 'Delete item',
                icon: 'deleteItem',
                iconSize: '16px',
                colorTitle: '#E4553E',
                colorStartIcon: '#E4553E',
                className: 'item_action',
                onClick: () => setOpenConfirm(true),
              },
            ],
          ]}
          closeAfterClick
        /> */}
        </Dropdown>
      )}
      <ModalConfirm
        title="Deleting a item"
        message="Are you sure you want to delete this item?"
        openModal={openConfirm}
        onClose={() => setOpenConfirm(false)}
        action={() => deleteItem(item)}
      />
    </ListItem>
  );
};

export default MenuItem;
