import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, createStore } from 'redux';

export function makeStore({
  storeName,
  reducer,
  rootSaga,
  initState,
}: {
  storeName: string;
  reducer: (state: any, action: any) => any;
  rootSaga: any;
  initState?: any;
}) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = composeWithDevTools({
    name: storeName,
  });

  const store = createStore(
    reducer,
    initState,
    composeEnhancers(applyMiddleware(sagaMiddleware)),
  );

  sagaMiddleware.run(rootSaga as any);

  return store;
}

export { makeStore as makeOneStore };
