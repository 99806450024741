import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const UnGroup = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2" y="2" width="3" height="3" fill={props.color} />
    <rect x="9" y="2" width="2" height="2" fill={props.color} />
    <rect x="5" y="11" width="3" height="3" fill={props.color} />
    <rect x="5" y="5" width="3" height="3" fill={props.color} />
    <rect x="2" y="9" width="2" height="2" fill={props.color} />
    <rect x="3" y="3" width="1" height="6" fill={props.color} />
    <rect x="9" y="3" width="1" height="2" fill={props.color} />
    <rect x="10" y="3" width="1" height="7" transform="rotate(90 10 3)" fill={props.color} />
    <rect x="5" y="9" width="1" height="2" transform="rotate(90 5 9)" fill={props.color} />
    <rect x="6" y="6" width="1" height="6" fill={props.color} />
    <rect x="12" y="6" width="1" height="7" fill={props.color} />
    <rect x="13" y="6" width="1" height="7" transform="rotate(90 13 6)" fill={props.color} />
    <rect x="13" y="12" width="1" height="7" transform="rotate(90 13 12)" fill={props.color} />
    <rect x="11" y="5" width="3" height="3" fill={props.color} />
    <rect x="11" y="11" width="3" height="3" fill={props.color} />
  </SvgIcon>
);

export default UnGroup;
