import { generateFillDates, getQueryDatetimeValue } from '@ep/insight-ui/sw/util/calendar';
import * as eipRequest from '@ep/insight-ui/sw/util/request';
import { addonMiddleware } from '@ep/insight-ui/system/util/addon-middleware';
import { cloneDeep, get, groupBy, isString, set, zipObject } from 'lodash';
import { SPECIAL_COLUMNS } from '../../constant';
import { universalPrefixListField } from '../../util/column';
import { isFormulaField } from '../../util/excel-formula';
import { checkEpsiloTableEndpoint, getMetricDefinition, produceQueryResult } from './common';

export function stripUniversalPrefix(field) {
  return field.replace(/\.(a_|m_)/, '.');
}

export function enhanceDataRequest2(params, originRequest, backbone) {
  const endpoint = backbone.config.endpoint.GET_TABLE_DATA;
  return addonMiddleware(
    originRequest,
    async function enhanceMopQuery2(params, originRequest, backbone, next) {
      if (checkEpsiloTableEndpoint(endpoint)) {
        return handleDataRequest({ ...params, isSummary: true }, next, backbone);
      }
      return next(params, originRequest, backbone);
    },
    // async function handleStorefrontMetricTraction(params, originRequest, backbone, next) {
    //   if (!checkEpsiloTableEndpoint(endpoint)) {
    //     return next(params, originRequest, backbone);
    //   }
    //   const mapping = get(backbone, 'config.mapping', {});
    //   const columnTraction = Object.values(mapping).find((i: any) => i.cellFormat === 'storefrontMetricTraction');
    //   const columnTractionKey = Object.keys(mapping).find(
    //     (el) => mapping[el]?.cellFormat === 'storefrontMetricTraction',
    //   );
    //   const metrics = get(backbone, 'config.metric', [])
    //     .filter((i) => mapping[i])
    //     .map((i) => {
    //       return mapping[i].valueGetter.value;
    //     });
    //   const isMetricVisible = metrics.includes(columnTractionKey);

    //   params.metrics = params.metrics.filter((i) => i !== 'metric');

    //   const isDrillDown = get(params, 'groupBy.drillDowns', false);
    //   const result = await next(params, backbone);

    //   // Handle storefront metric traction for performance and insight table here before returning
    //   // Call listing API using the same params but isSummary = false
    //   // Map data to result

    //   if (!isMetricVisible || !columnTraction || !isDrillDown) {
    //     return result;
    //   }

    //   const queryMetrics = get(columnTraction, 'staticValue.selectedMetrics', ['cost']);

    //   const drillDown = get(params, 'groupBy.drillDowns[0]', {});

    //   const period = get(backbone, 'config.groupPeriod', 'daily');
    //   const datetimeFieldRequest = get(backbone, ['config', 'system', 'datetimeField'], 'created_datetime');

    //   const selectedMetricTraction = get(backbone, 'config.selectedMetricTraction', []);

    //   const metricParams = selectedMetricTraction ? selectedMetricTraction : queryMetrics;
    //   const paramTraction = {
    //     ...universalPrefixListField({
    //       attributes: [].concat(params.attributes),
    //       metrics: metricParams,
    //     }),
    //     filter: {
    //       combinator: 'and',
    //       filters: [
    //         { field: drillDown.field, operator: '=', value: drillDown.value },
    //         {
    //           field: datetimeFieldRequest,
    //           operator: '>=',
    //           value: getQueryDatetimeValue(datetimeFieldRequest, params.from, 'start'),
    //         },
    //         {
    //           field: datetimeFieldRequest,
    //           operator: '<=',
    //           value: getQueryDatetimeValue(datetimeFieldRequest, params.to, 'end'),
    //         },
    //       ],
    //     },
    //     groupPeriod: get(backbone, 'config.groupPeriod', 'daily'), // to be updated to have period selection on eTable
    //     hiddenFilter: { currency: 'USD' },
    //     currency: 'USD',
    //   };

    //   if (params.filter) {
    //     paramTraction.filter.filters.push(params.filter);
    //   }

    //   const metricResult = await eipRequest.post(endpoint, paramTraction);

    //   const fillDates = generateFillDates(params.from, params.to, period);
    //   const storefrontRows = result.data.rows;
    //   const metricIndex = metricResult.data.headers.reduce((carry, i, index) => {
    //     return { ...carry, [i]: index, [stripUniversalPrefix(i)]: index };
    //   }, {});
    //   const metricRows = metricResult.data.rows;
    //   const masterDataPrimaryKey = get(metricResult, ['masterDataPrimaryKey'], {});

    //   const joinFieldsFromRequest = Object.keys(masterDataPrimaryKey).reduce((a, b) => {
    //     const primaryKeys = masterDataPrimaryKey[b].map((el) => `${b}.${el}`);
    //     return [...a, ...primaryKeys];
    //   }, []);

    //   let joinFields: string[] = get(
    //     columnTraction,
    //     'staticValue.joinFields',
    //     joinFieldsFromRequest.length ? joinFieldsFromRequest : 'storefront.id',
    //   );

    //   if (isString(joinFields)) {
    //     joinFields = joinFields.split(',').map((i) => String(i).trim());
    //   }

    //   const currencyField: string = get(columnTraction, 'staticValue.currencyField', null);
    //   const rowIndexMap = joinFields
    //     .concat('datetime', currencyField)
    //     .filter((i) => i)
    //     .reduce((carry, i) => {
    //       return { ...carry, [i]: metricResult.data.headers.findIndex((h) => i === stripUniversalPrefix(h)) };
    //     }, {});

    //   const metricDefs = metricParams.reduce((carry, metric) => {
    //     const metricDef = get(result, 'data.resourceMetric', []).find((el) => el.value === metric) || {
    //       label_raw: metric,
    //       prefix_value: '',
    //     };
    //     return { ...carry, [metric]: metricDef };
    //   }, {});

    //   const sfMetricRows = storefrontRows.reduce((carry, sf) => {
    //     const rows = [];
    //     for (const metric of metricParams) {
    //       const dtValue = fillDates.reduce((carry, dt) => {
    //         const foundValue = metricRows.find((r) => {
    //           return (
    //             joinFields.every((f) => rowIndexMap[f] > -1 && r[rowIndexMap[f]] === sf[f]) &&
    //             String(r[rowIndexMap['datetime']]).includes(dt)
    //           );
    //         });
    //         const val = foundValue ? foundValue[metricIndex[metric]] : null;
    //         return {
    //           ...carry,
    //           [dt]: val,
    //           currency: get(foundValue, [rowIndexMap[currencyField]]),
    //         };
    //       }, {});
    //       const metricDef = metricDefs[metric];
    //       const metricLabel = metricDef.label_raw;
    //       rows.push({
    //         ...sf,
    //         metric: metricLabel,
    //         metricUnit: metricDef.prefix_value,
    //         ...dtValue,
    //         currency:
    //           metricDef.prefix_value === '$'
    //             ? dtValue['currency'] || sf[currencyField] || sf['currency']
    //             : metricDef.prefix_value,
    //       });
    //     }
    //     //Add sort metric
    //     rows.sort((a, b) => (a.metric.toLowerCase() > b.metric.toLowerCase() ? 1 : -1));
    //     return carry.concat(rows);
    //   }, []);

    //   const enrichResultData = {
    //     headers: result.data.headers.concat('metric').concat(fillDates),
    //     rows: sfMetricRows,
    //   };

    //   return { ...result, data: { ...result.data, ...enrichResultData } };
    // },

    // async function pivotColumn(params, originRequest, backbone, next) {
    //   const isDrillDown = get(params, 'groupBy.drillDowns', false);
    //   const mapping = get(backbone, 'config.mapping', {});
    //   const storefrontMetricCol = Object.keys(mapping).find(
    //     (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
    //   );
    //   const endpoint = backbone.config.endpoint.GET_TABLE_DATA;

    //   const isStorefrontMetricGrouped =
    //     get(backbone, ['config', 'groupBy', 'columns', '0'], '') === storefrontMetricCol;
    //   // group by time
    //   const period = get(backbone, 'config.groupPeriod', 'daily');

    //   // group by dimension
    //   const isDimensionPeriod = mapping[period];

    //   if (isStorefrontMetricGrouped && !isDimensionPeriod) {
    //     const result = await handleStorefrontMetricGroupedRequest({
    //       isDrillDown,
    //       storefrontMetricCol,
    //       backbone,
    //       endpoint,
    //       params,
    //       originRequest,
    //     });
    //     return result;
    //   } else {
    //     return next(params, originRequest, backbone);
    //   }
    // },

    async function excludeFilterFieldInColumnQuery(params, originRequest, backbone, next) {
      const mapping = get(backbone, 'config.mapping', {});

      let validQueryFields = [];
      Object.entries(mapping).forEach(([k, i]: [string, any]) => {
        if (i.cellFormat !== SPECIAL_COLUMNS.filterColumn) {
          validQueryFields = validQueryFields.concat(Object.values(i.valueGetter));
        }
      });

      params.attributes = params.attributes.filter((i) => validQueryFields.includes(i));
      params.metrics = params.metrics.filter((i) => validQueryFields.includes(i));

      return next(
        {
          ...params,
          attributes: params.attributes.filter((i) => validQueryFields.includes(i)),
          metrics: params.metrics.filter((i) => validQueryFields.includes(i)),
        },
        originRequest,
        backbone,
      );
    },
  )(params, originRequest, backbone);
}

export const handleDataRequest = async (originalParams, originalRequest, backbone) => {
  const params = cloneDeep(originalParams);
  const endpoint = get(backbone, 'config.endpoint.GET_TABLE_DATA', '');
  const datetimeFieldRequest = get(backbone, ['config', 'system', 'datetimeField'], 'created_datetime');

  if (endpoint.includes('?_eipGroup=country')) {
    params.groupBy = { dimensions: ['storefront.country_code'] };
  } else if (endpoint.includes('?_eipGroup=marketplace')) {
    params.groupBy = { dimensions: ['storefront.marketplace_code'] };
  } else if (endpoint.includes('?_eipGroup=')) {
    const sp = new URLSearchParams('?' + endpoint.split('?')[1]);
    const groupBy = String(sp.get('_eipGroup'))
      .split(',')
      .map((i) => i.trim());
    params.groupBy = { dimensions: groupBy.concat(get(params, 'groupBy.dimensions', [])) };
  }

  params.filter = {
    ...get(params, 'filter', { combinator: 'AND', filters: [] }),
    filters: [
      ...get(params, 'filter.filters', []),
      ...(datetimeFieldRequest
        ? [
            {
              field: datetimeFieldRequest,
              operator: '>=',
              value: getQueryDatetimeValue(datetimeFieldRequest, params.from, 'start'),
            },
            {
              field: datetimeFieldRequest,
              operator: '<=',
              value: getQueryDatetimeValue(datetimeFieldRequest, params.to, 'end'),
            },
          ]
        : []),
    ],
  };

  if (params.groupBy) {
    params.groupBy.column = params.groupBy.dimensions;
    params.groupBy.aggregations = get(params, 'groupBy.aggregations', []).filter(
      (i) => String(i.func).toUpperCase() !== 'NULL',
    );
  }
  const drillDowns = get(params, 'groupBy.drillDowns');
  if (drillDowns) {
    params.filter.filters = params.filter.filters.concat(
      drillDowns.map((drilldown) => {
        return {
          ...drilldown,
          operator: '=',
        };
      }),
    );
    const groupedColumns = get(backbone, ['config', 'groupBy', 'columns'], []) || [];
    if (drillDowns.length < groupedColumns.length) {
      params.groupBy = {
        ...params.groupBy,
        drillDowns: undefined,
        dimensions: params.groupBy.dimensions.slice(0, drillDowns.length + 1),
        column: params.groupBy.column.slice(0, drillDowns.length + 1),
      };
    } else {
      params.groupBy = undefined;
    }
  }

  const isAutomationNamespace = String(endpoint).includes('v2/query.jsp?namespace=automation');
  const primaryKeys = [].concat(get(backbone, ['config', 'primaryKeys'], [])).filter((i) => !!i);
  const attributes = [].concat(get(params, ['attributes'], []));

  if (isAutomationNamespace) {
    const validSortPrimaryKeys = primaryKeys.filter((i) => attributes.includes(i));
    if (validSortPrimaryKeys.length) {
      params.sort = (params.sort || []).concat(
        validSortPrimaryKeys.map((i) => {
          return {
            field: i,
            sort: 'ASC',
          };
        }),
      );
    }
  }
  // save table request params to config to download data
  set(backbone, 'config.tableParams', params);
  const mapping = get(backbone, 'config.mapping', {});

  let foundCurrencyField;
  for (const column of Object.values(mapping)) {
    const foundItem = Object.entries(get(column, 'valueGetter', {})).find(([k, v]) => k === 'currency');
    if (foundItem) {
      foundCurrencyField = foundItem[1] !== '' ? foundItem[1] : null;
    }
  }

  const results = await Promise.all([
    originalRequest({ ...params, ...params.hiddenFilter }),
    getMetricDefinition(endpoint),
  ]);

  const result = results.reduce((a, b, index) => {
    if (index === 1) {
      if (b.length > 0) {
        a.data.rows = a.data.rows.map((row) => ({
          ...row,
        }));
        a.data.resourceMetric = b;
      }
      return a;
    }

    const b1 = produceQueryResult(b, mapping, true);

    set(b1, 'data.rows', b1.rows);
    set(b1, 'data.headers', b1.headers);

    return b1;
  }, {});

  return {
    data: result,
    params,
  };
};

export const hasCohortProperties = (backbone) => {
  const mappings = get(backbone, 'config.mapping', null) || backbone.getConfig('mapping');
  const visualizationType = get(backbone, ['config', 'visualizationType'], 'table');
  const chartType = get(backbone, ['config', 'chartConfig', 'config', 'chartType'], '');
  const allowCohort = get(backbone, ['config', 'chartConfig', 'config', 'timelineCohorts'], '');
  if (visualizationType === 'chart' && ['lines', 'treemap'].includes(chartType) && allowCohort) {
    return true;
  }
  return Object.values(mappings).some((mapping) => {
    return get(mapping, 'staticValue.allowCohort', false);
  });
};

const handleStorefrontMetricGroupedRequest = async ({
  isDrillDown,
  storefrontMetricCol,
  backbone,
  endpoint,
  params,
  originRequest,
}) => {
  const mapping = get(backbone, 'config.mapping', {});
  const resourceMetric = await getMetricDefinition(endpoint);
  if (isDrillDown) {
    const drillDownValueLabel = get(params, ['groupBy', 'drillDowns', '0', 'value'], '');

    const drillDownValue = get(
      resourceMetric.find((el) => el.label_raw === drillDownValueLabel),
      ['value'],
      '',
    );

    const period = get(backbone, 'config.groupPeriod', 'daily');
    const storefrontDrillDownParams = {
      ...params,
      groupBy: undefined,
      isSummary: undefined,
      metrics: [drillDownValue],
      groupPeriod: period,
    };

    const result = await originRequest(storefrontDrillDownParams);

    const mainHeaders = get(result, 'data.headers', []);
    const masterData = get(result, 'data.masterData', {});

    const entityRelations = mainHeaders
      .filter((i) => stripUniversalPrefix(i).indexOf('.id') > 0)
      .map((i) => stripUniversalPrefix(i).replace('.id', ''));
    const mapEntity = entityRelations.reduce((carry, entityType) => {
      const headers = masterData[entityType]?.headers.map((i) => `${entityType}.${i}`) || [];
      const rows = masterData[entityType]?.rows || [];
      return {
        ...carry,
        [entityType]: rows.reduce((carry, row, index) => {
          const obj = zipObject(headers.concat(headers.map((h) => stripUniversalPrefix(h))), row.concat(row));
          const id = obj[`${entityType}.id`] as string;
          return { ...carry, [id]: obj };
        }, {}),
      };
    }, {});

    const fillDates = generateFillDates(params.from, params.to, period);

    const enhanceRows = Object.values(groupBy(get(result, ['data', 'rows'], []), 'storefront.id')).map((el) => {
      const fillDatesValue = fillDates.reduce((a, b) => {
        const foundValue = el.find((ele) => ele.datetime?.includes(b));

        return {
          ...a,
          [b]: foundValue ? foundValue[drillDownValue] : null,
        };
      }, {});

      let item = {};
      entityRelations.forEach((entityType) => {
        item = { ...mapEntity[entityType][el[0][`${entityType}.id`]] };
      });

      return { ...fillDatesValue, ...item };
    });

    const enhanceHeaders = Object.keys(enhanceRows[0]);

    return { ...result, data: { ...result.data, headers: enhanceHeaders, rows: enhanceRows } };
  } else {
    const storefrontMetric = mapping[storefrontMetricCol];
    const selectedMetrics = get(backbone, ['config', 'selectedMetricTraction'], []);
    const storefrontMetricField = get(storefrontMetric, ['valueGetter', 'value'], 'metric');

    return {
      success: true,
      message: 'ok',
      data: {
        headers: [storefrontMetricField],
        rows: selectedMetrics.map((el) => {
          const metricInfo = resourceMetric.find((ele) => ele.value === el);
          return {
            [storefrontMetricField]: metricInfo.label_raw,
          };
        }),
      },
    };
  }
};

export function getTableContext(mapping) {
  const tableContext = { attributes: [], metrics: [] };
  Object.values(mapping).forEach((f: any) => {
    if (f.propertyType == 'dimension' || f.propertyType === 'attribute') {
      tableContext.attributes = tableContext.attributes.concat(
        Object.values(f.valueGetter).filter((i) => !isFormulaField(i)),
      );
    } else if (f.propertyType === 'metric') {
      tableContext.metrics = tableContext.metrics.concat(
        Object.values(f.valueGetter).filter((i) => !isFormulaField(i)),
      );
    }
  });
  return tableContext;
}
