import React from 'react';
import { BrowserRouter, HashRouter, useLocation } from 'react-router-dom';
import { setPagePrefix } from './global';
import { eipRequest as request } from '@eip/next/lib/main';

const Router = process.env.NODE_ENV === 'production' ? BrowserRouter : HashRouter;
// const Router = HashRouter;

export default function OneApp1({ children }) {
  return (
    <Router>
      <PagePrefix>{children}</PagePrefix>
    </Router>
  );
}

function PagePrefix({ children }) {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const isSandbox = searchParams.get('isSandbox')

  if(isSandbox) {
    setPagePrefix('/sandbox/page');
    if (request.markSandbox) request.markSandbox(true);
  } else {
    setPagePrefix('/page');
    if (request.markSandbox) request.markSandbox(false);
  }
  // if (location.pathname.indexOf('/sandbox') === 0) {
  //   setPagePrefix('/sandbox/page');
  //   if (request.markSandbox) request.markSandbox(true);
  // } else {
  //   setPagePrefix('/page');
  //   if (request.markSandbox) request.markSandbox(false);
  // }

  return children;
}
