import { INPUT_SPECIAL_KEY_DISABLE } from '@ep/insight-ui/elements/textField/special-key';
import TextFieldNumber, { TextFormProps } from '@ep/insight-ui/elements/textField/textField-number';
import { responsiveTextField } from '@ep/insight-ui/lib/epsilo-theme';
import { Grid, InputProps, makeStyles } from '@material-ui/core';
import * as React from 'react';
import FormControlTemplate from './form-control-template';
const useStyles = makeStyles({
  ...responsiveTextField,
  inputNumberValue: {
    margin: 0,
  },
});
interface Props extends TextFormProps {
  label: string;
  value: string;
  currency?: string;
  onChange: (e: any) => void;
}
const NumberFormControl = ({ value, onChange, currency = '', label, ...rest }: Props) => {
  const classes = useStyles();
  return (
    <div>
      {value !== INPUT_SPECIAL_KEY_DISABLE ? (
        <FormControlTemplate label={label}>
          <Grid container>
            <Grid item xs={12}>
              <TextFieldNumber
                {...rest}
                value={value}
                style={{ width: '100%' }}
                placeholder={'Your value'}
                onChangeNumber={onChange}
                className={`${classes.inputNumber} md ${classes.inputNumberValue}`}
                unit={currency}
                disabledIncreaseDecrease
              />
            </Grid>
          </Grid>
        </FormControlTemplate>
      ) : null}
    </div>
  );
};

export default NumberFormControl;
