import SelectFormSearchInside from '@ep/insight-ui/elements/form-control/select-form/select-form-search-inside';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import TextArea from '@ep/insight-ui/elements/textField/text-area';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
}));

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  onClose: () => void;
};

const TaglineEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, anchorEl, setAnchorEl, onClose } = props;
  const classes = useStyles();

  const defaultAgeValue = React.useMemo(() => {
    if (data.value.label) return data.value.label;
    return '';
  }, [data.value, anchorEl]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const [value, setValue] = React.useState(defaultAgeValue);
  const handleClosed = () => {
    if (onClose) {
      onClose();
    }
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const valueTmp = JSON.parse(JSON.stringify(data.value));

    valueTmp.label = value;
    setIsSubmitting(true);
    const response: any = await onSubmit({ value: valueTmp, row: data.node.data });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '304px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <div className={classes.block}>
          <div className={clsx(classes.form)}>
            <TextArea defaultValue={value} onChangeText={setValue} limit={50} searchIcon={false} multiline={false} />
          </div>
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default TaglineEditor;
