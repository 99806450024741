import { aim, ContainerResponsiveContext } from '@eip/next/lib/main';
import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import MultipleSelection from '../../form-controls/multiple-selection';
import Selection from '../../form-controls/selection';
import TextBudget from '../../form-controls/text-budget';
import TextBudgetWithSelect from '../../form-controls/text-budget-select';
import TimeRange from '../../form-controls/time-range';
import TimeZone from '../../form-controls/time-zone';
import LazadaInputFirstSearchSlot from './lazada-input-first-search-slot';

const defaultStatus: SelectItem[] = [{ label: 'Draft', value: 'DRAFT', payload: null }];
const defaultValue = {
  adObjectMethod: 'manual',
  adTypeIds: [],
  bidLimit: 0,
  bidLimitCurrency: '',
  campaignId: '',
  countryIds: [],
  dailyBudget: 0,
  dailyBudgetCurrency: '',
  marketplaceIds: [],
  objective: '',
  schedule: { dateFrom: '', dateTo: '' },
  status: 'DRAFT',
  storefrontIds: [],
  timezone: '',
  totalBudget: 0,
  totalBudgetCurrency: '',
  isChangeCurrency: false,
};

type SelectItem = {
  label: string;
  value: string;
  disabled?: boolean;
  payload?: any;
};
export type IDisableArr =
  | 'marketplace'
  | 'country'
  | 'storefront'
  | 'schedule'
  | 'total-budget'
  | 'daily-budget'
  | 'objective'
  | 'ad-object-method'
  | 'status'
  | 'allow-script-end'
  | 'allow-script-pause'
  | 'number-of-ads-objects';
type CampaignSettingsFormProps = {
  value?: {
    campaignId: string;
    marketplaceIds: string[];
    countryIds: string[];
    storefrontIds: string[];
    adTypeIds: string[];
    schedule: {
      dateFrom: string; // YYYY-MM-DD
      dateTo: string; // YYYY-MM-DD
    };
    totalBudget: number;
    dailyBudget: number;
    bidLimit: number;
    timezone: string;
    objective: string;
    adObjectMethod: string;
    status: string;
    totalBudgetCurrency: string;
    dailyBudgetCurrency: string;
    bidLimitCurrency: string;
    isChangeCurrency?: boolean;
    firstSearchSlot?: string;
  };

  marketplace?: SelectItem[];
  country?: SelectItem[];
  storefront?: SelectItem[];
  adType?: SelectItem[];
  status?: SelectItem[];
  objective?: SelectItem[];
  adObjectMethod?: SelectItem[];
  onChange?: (data: any) => void;
  disabledArr?: IDisableArr[];
  disabledChooseStartDate?: boolean;
  requestOptions?: any;
};

const enableDisableOptions = [
  {
    label: 'Enable',
    value: '1',
  },
  {
    label: 'Disable',
    value: '0',
  },
];

const numberOfAdsObjectsOptions = [
  {
    label: 'Auto',
    value: '1',
  },
  {
    label: '20',
    value: '20',
  },
  {
    label: '40',
    value: '40',
  },
  {
    label: '60',
    value: '60',
  },
  {
    label: '80',
    value: '80',
  },
  {
    label: '100',
    value: '100',
  },
];

const CampaignSettingFormMultiple = ({
  adType = [],
  country = [],
  marketplace = [],
  status = defaultStatus,
  storefront = [],
  objective = [],
  value = defaultValue,
  adObjectMethod = [],
  disabledArr = [],
  onChange = () => undefined,
  disabledChooseStartDate = false,
  requestOptions,
}: CampaignSettingsFormProps) => {
  const isEditMode = Boolean(value.campaignId);
  const isChangeCurrency = value.isChangeCurrency || false;

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';

  const getCurrency = (values) => {
    const val = get(values, ['storefrontIds', 0], '');
    const currentStorefront = storefront.find((item) => item.value == val);
    return get(currentStorefront, 'payload.country.currency', '');
  };

  return (
    <ExpansionForm
      level={isMobile ? '2' : '1'}
      defaultExpanded={true}
      label={'Campaign setting'}
      detailContentSize={'half'}
      dataMenu={[[]]}
    >
      <Formik
        initialValues={value}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
        validateOnChange={true}
        validate={(data) => {
          if (onChange) {
            onChange(data);
          }
        }}
        enableReinitialize={true}
      >
        {({ values, handleChange, setFieldValue, setValues }) => {
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <MultipleSelection
                    label={'Marketplace'}
                    placeholder={'Choose marketplace'}
                    disabled={isEditMode || disabledArr.includes('marketplace')}
                    defaultValue={values.marketplaceIds}
                    onChangeValue={(value) => {
                      setValues({ ...values, marketplaceIds: value, storefrontIds: [] });
                    }}
                    limitTags={3}
                    options={marketplace}
                    requestOption={requestOptions.getMarketplace}
                    single
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultipleSelection
                    label={'Country'}
                    placeholder={'Choose country'}
                    defaultValue={values.countryIds}
                    disabled={isEditMode || disabledArr.includes('country')}
                    onChangeValue={(value) => {
                      // setFieldValue('countryIds', value);
                      // setFieldValue('storefrontIds', []);
                      setValues({ ...values, countryIds: value, storefrontIds: [] });
                    }}
                    options={country}
                    requestOption={requestOptions.getCountry}
                    single
                  />
                </Grid>
                <Grid item xs={12}>
                  <MultipleSelection
                    label={'Storefront'}
                    placeholder={'Choose storefront'}
                    defaultValue={values.storefrontIds}
                    disabled={isEditMode || disabledArr.includes('storefront')}
                    onChangeValue={(value) => setFieldValue('storefrontIds', value)}
                    options={storefront.filter((option) => {
                      const { countryIds, marketplaceIds } = values;
                      return (
                        (!countryIds ||
                          countryIds.length === 0 ||
                          countryIds.some((countryId) => option.payload.country.code === countryId)) &&
                        (!marketplaceIds ||
                          marketplaceIds.length === 0 ||
                          marketplaceIds.some((marketplaceId) => option.payload.channel.id === marketplaceId))
                      );
                    })}
                    requestOption={requestOptions.getStorefront}
                    single
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimeRange
                    label={'Schedule'}
                    dateFormat={'YYYY-MM-DD'}
                    disabled={disabledArr.includes('schedule')}
                    value={values.schedule}
                    disabledChooseStartDate={disabledChooseStartDate}
                    onChange={(value) => {
                      console.log('output value :  ', value);

                      setFieldValue('schedule', value);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextBudget
                    label={'Max bidding price'}
                    defaultValue={values.maxBiddingPrice === 0 ? '' : String(values.maxBiddingPrice)}
                    currency={getCurrency(values)}
                    onChangeText={handleChange('maxBiddingPrice')}
                    placeholder={'no limit'}
                    noSelectBox
                    budgetType="daily"
                    disabled={disabledArr.includes('max-bidding-price')}
                  />
                </Grid>
                <Grid item xs={12}>
                  {isChangeCurrency ? (
                    <TextBudgetWithSelect
                      label={'Total budget'}
                      defaultValue={values.totalBudget === 0 ? '' : String(values.totalBudget)}
                      currency={values.totalBudgetCurrency}
                      onChangeText={handleChange('totalBudget')}
                      onChangeCurrency={handleChange('totalBudgetCurrency')}
                      placeholder={'no limit'}
                      budgetType="total"
                      disabled={disabledArr.includes('total-budget')}
                    />
                  ) : (
                    <TextBudget
                      label={'Total budget'}
                      defaultValue={values.totalBudget === 0 ? '' : String(values.totalBudget)}
                      currency={getCurrency(values)}
                      onChangeText={handleChange('totalBudget')}
                      placeholder={'no limit'}
                      noSelectBox
                      budgetType="total"
                      disabled={disabledArr.includes('total-budget')}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {isChangeCurrency ? (
                    <TextBudgetWithSelect
                      label={'Daily budget'}
                      defaultValue={values.dailyBudget === 0 ? '' : String(values.dailyBudget)}
                      currency={values.dailyBudgetCurrency}
                      onChangeText={handleChange('dailyBudget')}
                      onChangeCurrency={handleChange('dailyBudgetCurrency')}
                      placeholder={'no limit'}
                      budgetType="daily"
                      disabled={disabledArr.includes('daily-budget')}
                    />
                  ) : (
                    <TextBudget
                      label={'Daily budget'}
                      defaultValue={values.dailyBudget === 0 ? '' : String(values.dailyBudget)}
                      currency={getCurrency(values)}
                      onChangeText={handleChange('dailyBudget')}
                      placeholder={'no limit'}
                      noSelectBox
                      budgetType="daily"
                      disabled={disabledArr.includes('daily-budget')}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Selection
                    options={objective}
                    onChange={handleChange('objective')}
                    label={'Objective'}
                    value={values.objective}
                    disabled={disabledArr.includes('objective')}
                    placeholder="Not available"
                    showIcon={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Selection
                    options={adObjectMethod}
                    onChange={handleChange('adObjectMethod')}
                    label={'Ad object method'}
                    value={values.adObjectMethod}
                    disabled={disabledArr.includes('ad-object-method')}
                    placeholder="Choose ad object method"
                    showIcon={true}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimeZone
                    disableIconSelect={false}
                    disabled
                    placeholder={'Storefront timezone'}
                    label={'Time zone'}
                    value={values.timezone}
                    onChange={handleChange('timeZone')}
                  />
                </Grid>
                {get(values, ['marketplaceIds', 0], '') === 'LAZADA' ? (
                  <Grid item xs={12}>
                    <LazadaInputFirstSearchSlot
                      storefrontId={get(values, ['storefrontIds', 0], null)}
                      campaignSid={get(values, ['campaignId'], null)}
                      label={'First search slot'}
                      defaultValue={values.firstSearchSlot}
                      onChangeText={handleChange('firstSearchSlot')}
                      placeholder={'Enter first search slot'}
                      type={'number'}
                    />
                  </Grid>
                ) : null}
                {aim.canAccess('internal') ? (
                  <>
                    <Grid item xs={12}>
                      <Selection
                        options={numberOfAdsObjectsOptions}
                        onChange={handleChange('numberOfAdsObjects')}
                        label={'Number of ads objects'}
                        value={values.numberOfAdsObjects}
                        disabled={disabledArr.includes('number-of-ads-objects')}
                        placeholder="Number Of Ads Objects"
                        showIcon={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Selection
                        options={enableDisableOptions}
                        onChange={handleChange('allowScriptEndTimeline')}
                        label={'Allow script end (by timeline)'}
                        value={values.allowScriptEndTimeline}
                        disabled={disabledArr.includes('allow-script-end')}
                        placeholder="Allow Script End (by Timeline)"
                        showIcon={true}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Selection
                        options={enableDisableOptions}
                        onChange={handleChange('allowScriptPauseBudget')}
                        label={'Allow script pause (by budget)'}
                        value={values.allowScriptPauseBudget}
                        disabled={false}
                        placeholder="Allow Script Pause (by Budget)"
                        showIcon={true}
                      />
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ExpansionForm>
  );
};
export default CampaignSettingFormMultiple;
