import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider, Button } from '@material-ui/core';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { TypeValueBudget } from '../budget-row-form/budget-row-form';
import BudgetRowForm from '../budget-row-form';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import GroupButtonSubmit from '../../group-button-submit';
import {
  IValueBudget,
  IValueMultipleBudgetFormat,
} from '@ep/insight-ui/elements/table/format/budgets/multi-budget-format';
import * as _ from 'lodash';
/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  arrowUp: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  arrowDown: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  buttonAdd: {
    marginTop: 10,
  },
}));
export const options: OptionSelectType[] = [
  {
    label: 'Total budget',
    value: 'total',
  },
  {
    label: 'Daily budget',
    value: 'daily',
  },
];

const operators: OptionSelectType[] = [
  {
    label: 'Exact value',
    value: 'exactValue',
  },
  {
    label: 'No limit',
    value: 'noLimit',
  },
];
type Props = {
  data: {
    value: IValueMultipleBudgetFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const BudgetMultipleEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();
  const disabledModelNoLimit = _.get(data, ['value', 'disabledModelNoLimit'], false);

  const defaultBudgets = React.useMemo(() => {
    const items: Array<IValueBudget> = _.get(data.value, 'budgets', []);
    const arr: Array<TypeValueBudget> = items.map((item) => ({
      field: item.label,
      operator: item.value ? operators[0].value : operators[1].value,
      budget: String(item.value),
      currency: item.currency,
    }));
    return arr;
  }, [data.value, anchorEl]);
  const [budgets, setBudgets] = React.useState<Array<TypeValueBudget>>(defaultBudgets);
  React.useEffect(() => {
    setBudgets(defaultBudgets);
  }, [anchorEl]);

  const handleAddBudget = () => {
    const temp: Array<TypeValueBudget> = JSON.parse(JSON.stringify(budgets));
    const defaultObjectBudget: TypeValueBudget = {
      field: '',
      budget: '',
      operator: '',
    };
    if (options[0]) defaultObjectBudget.field = options[0].value;
    if (operators[0]) defaultObjectBudget.operator = operators[0].value;
    temp.push(defaultObjectBudget);
    setBudgets(temp);
  };

  const handleRemove = (index: number) => {
    const temp: Array<TypeValueBudget> = JSON.parse(JSON.stringify(budgets));
    if (temp[index]) {
      temp.splice(index, 1);
      setBudgets(temp);
    }
  };

  const handleOnChangeBudgetItem = (value: TypeValueBudget, index: number) => {
    if (budgets[index]) {
      budgets[index].budget = value.budget;
      budgets[index].field = value.field;
      budgets[index].operator = value.operator;
      setBudgets(budgets);
    }
  };
  const getDataMenu = (index: number): DropdownMenuDataType[][] => {
    return [[{ title: 'Remove', onClick: () => handleRemove(index) }]];
  };

  const handleClosed = () => setAnchorEl(null);
  const handleSubmit = () => {
    const valueSubmit: IValueMultipleBudgetFormat = {
      budgets: budgets.map((item) => ({
        label: item.field,
        currency: item.currency ?? '',
        value: item.operator !== 'noLimit' ? +item.budget : null,
      })),
    };
    onSubmit({ value: valueSubmit, row: data.node.data });
    handleClosed();
  };

  return (
    <div style={{ padding: '0 10px', minWidth: '400px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      {/* <Divider /> */}
      {budgets.map((budget, index) => (
        <BudgetRowForm
          key={index}
          options={options}
          operators={operators}
          value={budget}
          enableStyleMenu={budgets.length > 1 ? true : false}
          listMenu={index == 1 ? getDataMenu(index) : null}
          onChange={(value) => handleOnChangeBudgetItem(value, index)}
          disabledModelNoLimit={disabledModelNoLimit}
        />
      ))}
      {budgets.length <= 1 ? (
        <Button className={classes.buttonAdd} onClick={handleAddBudget}>
          <Icon className={classes.arrowDown} type={'add'} size="10px" /> Add more budget
        </Button>
      ) : null}

      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} />
    </div>
  );
};

export default BudgetMultipleEditor;
