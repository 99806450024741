import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import { IRowData } from '../../configs';
import Icon from '@ep/insight-ui/icons/Icon';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { get } from 'lodash';
const useStyles = makeStyles({
  wrapper: {
    height: '332px',
    width: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    display: 'inline-block',
    margin: '0 16px',
    '& p': {
      margin: 0,
    },
    borderRadius: '4px',
  },
  container: {
    backgroundImage: 'linear-gradient(0deg, #253746 0%, rgba(37, 55, 70, 0) 62.63%)',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    padding: '16px',
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
  },
  headContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  boxIcon: {
    background: 'rgba(37, 55, 70, 0.5)',
    borderRadius: '2px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    padding: '2px 4px',
  },
  bodyContent: {
    flex: 1,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  nameRank: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '8px',
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '12px',
    letterSpacing: '0px',
    textAlign: 'left',
    '&.name': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#FFFFFF',
    },
    '&.text-icon-box': {
      fontWeight: 500,
      fontSize: '12px',
      lineHeight: '16px',
      color: '#FFFFFF',
      marginLeft: '2px',
    },
    '&.text-location': {
      fontWeight: 'normal',
      lineHeight: '12px',
      color: '#FFFFFF',
      marginBottom: '2px',
    },
    '&.position': {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#8C98A4',
    },
    '&.timework': {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#FFFFFF',
    },
    '&.workAddress': {
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '16px',
      color: '#FFFFFF',
      marginBottom: '8px',
    },
  },
  action: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  button: {
    height: '24px',
    borderRadius: '4px',
    boxShadow: 'unset !important',
    // padding: "2px, 8px, 2px, 8px"
  },
  hireNote: {
    marginLeft: '8px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '2px 4px',
    background: 'rgba(37, 55, 70, 0.5)',
    borderRadius: '2px',
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: '16px',
    color: '#FFFFFF',
    '& .line-through': {
      textDecorationLine: 'line-through',
    },
  },
});

type IProps = {
  item: IRowData;
};
const getRankIcon = (num: number) => {
  if (num == 1) return <Icon type={'medal_1st'} />;
  if (num == 2) return <Icon type={'medal_2nd'} />;
  return null;
};
const getCurrency = (value: number, currency: string) => {
  if (currency == 'USD') {
    return `$${value}`;
  }
  return `${value}${currency}`;
};
const getPositionLabel = (
  position:
    | string
    | {
        timework: string;
        company: string;
      },
) => {
  const classes = useStyles();

  const timework = get(position, 'timework');
  const company = get(position, 'company');
  if (timework && company) {
    return (
      <React.Fragment>
        <span className={`${classes.text} timework`}>{timework}</span> on {company}
      </React.Fragment>
    );
  }
  return <React.Fragment>{position}</React.Fragment>;
};
const TopAdvertiserItem = ({ item }: IProps) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper} style={{ backgroundImage: `url(${item.avatar})` }}>
      <div className={classes.container}>
        <div className={classes.headContent}>
          <div className={classes.boxIcon}>
            <Icon type={'commentBlank'} color={'inherit'} style={{ color: '#fff' }} />
            <span className={`${classes.text} text-icon-box`}>{formatCurrencyNumber(item.reviews)}</span>
          </div>
          <div className={classes.boxIcon}>
            <Icon type={'starWhite'} />
            <span className={`${classes.text} text-icon-box`}>{item.star}</span>
          </div>
        </div>
        <div className={classes.bodyContent}>
          <p className={`${classes.text} text-location`}>{item.location}</p>
          <div className={classes.nameRank}>
            <span className={`${classes.text} name`}>{item.name}</span>
            {getRankIcon(item.rank)}
          </div>
          <p className={`${classes.text} position`}>{getPositionLabel(item.position)}</p>
          <p className={`${classes.text} workAddress`}>{item.workAddress}</p>
          <div className={classes.action}>
            <Button variant="contained" color="primary" className={classes.button}>
              Team up
            </Button>
            <span className={classes.hireNote}>
              {item.hireNote.previousPrice > 0 ? (
                <>
                  <span className="line-through">
                    {getCurrency(item.hireNote.previousPrice, item.hireNote.currency)}
                  </span>
                  &nbsp;
                </>
              ) : null}
              {getCurrency(item.hireNote.currentPrice, item.hireNote.currency)}/{item.hireNote.perValue}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopAdvertiserItem;
