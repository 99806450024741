import * as React from 'react';

import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';

import { useJsonEditor } from './hooks/use-json-editor';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';

const JsonEditor = (props) => {
  const { showEditor, setShowEditor, errMsg, handleSubmit, isSubmitting } = useJsonEditor(props);

  return (
    <NotificationGlobalProvider>
      <PopupJsonEditor
        title={'Editor'}
        open={showEditor}
        onClose={() => setShowEditor(false)}
        value={{}}
        onValueChange={handleSubmit}
        errorMessage={errMsg}
        isSubmitting={isSubmitting}
      />
    </NotificationGlobalProvider>
  );
};

export default JsonEditor;
