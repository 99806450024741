import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const StarRounded = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 0C2.7 0 0 2.7 0 6C0 9.3 2.7 12 6 12C9.3 12 12 9.3 12 6C12 2.7 9.3 0 6 0ZM9.36 5.88L8.52 6.72C8.4 6.84 8.28 7.14 8.28 7.38L8.52 8.58C8.64 9.24 7.92 9.78 7.38 9.42L6.36 8.82C6.12 8.7 5.94 8.7 5.7 8.82L4.68 9.42C4.08 9.72 3.36 9.18 3.54 8.58L3.78 7.38C3.84 7.14 3.72 6.9 3.54 6.72L2.58 5.88C2.22 5.4 2.4 4.62 3.06 4.56L4.2 4.38C4.44 4.38 4.62 4.14 4.8 3.96L5.28 2.88C5.58 2.22 6.42 2.22 6.72 2.88L7.2 3.96C7.26 4.2 7.5 4.32 7.8 4.38L8.94 4.5C9.6 4.62 9.78 5.4 9.36 5.88Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default StarRounded;
