/**
 * @deprecated should use @eip/lib/main/constant
 */
import { EIP_CONSTANT } from '../lib/main/constant';
export const RELOAD_TIME = EIP_CONSTANT.RELOAD_TIME;

export const SCREEN_SIZE = EIP_CONSTANT.SCREEN_SIZE;
export const SCREEN_COLUMNS = EIP_CONSTANT.SCREEN_COLUMNS;
export const GRID_MARGIN = EIP_CONSTANT.GRID_MARGIN;
export const GRID_BORDER_RADIUS = EIP_CONSTANT.GRID_BORDER_RADIUS;
export const ETABLE_PERSONALIZED_FIELDS = EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS;
export const EIP_SYSTEM_PAGE_ID = EIP_CONSTANT.EIP_SYSTEM_PAGE_ID;
