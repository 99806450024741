import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { isEqual } from 'lodash';
import * as React from 'react';

export function useEnhanceColDefs(columnDefs, columns, tableDOM) {
  const backbone = React.useContext(TableBackboneContext);
  const prevRef = React.useRef();

  const nextColDefs = backbone.addon('table.columns.enhance', (columnDefs, columns, backbone) => columnDefs)(
    columnDefs,
    columns,
    backbone,
    tableDOM,
  );
  const isChange = !isEqual(prevRef.current, nextColDefs);

  React.useLayoutEffect(() => {
    if (isChange) {
      prevRef.current = nextColDefs;
    }
  });

  return isChange ? nextColDefs : prevRef.current;
}
