import * as React from 'react';

import { makeStyles, Button } from '@material-ui/core';

import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from '@tiptap/extension-mention';
import Highlight from '@tiptap/extension-highlight';
import TaskList from '@tiptap/extension-task-list';
import TaskItem from '@tiptap/extension-task-item';
import suggestion from './suggestion';
import Link from '@tiptap/extension-link';

import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  textEditor: {
    '& *:not(hr)': {
      margin: 0,
      minHeight: '1.43em',
    },
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '0.75rem',
    display: 'flex',
    flexDirection: 'column',
    '& p': {
      margin: 0,
    },
    '& .ProseMirror': {
      outline: 'none',
      padding: '3px 8px',
    },
    '& .ProseMirror p.is-editor-empty:first-child::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '& .ProseMirror p:first-child': {
      marginTop: 0,
    },
    '& .ProseMirror ul': {
      listStyle: 'disc',
      paddingInlineStart: '40px',
    },
    '& code': {
      background: '#87837826',
      color: '#EB5757',
      borderRadius: '3px',
      fontSize: '85%',
      padding: '0.2em 0.4em',
    },
    '& pre': {
      background: '#0D0D0D',
      color: '#FFF',
      padding: '0.75rem 1rem',
      borderRadius: '0.5rem',
      '& code': {
        color: 'inherit',
        padding: 0,
        background: 'none',
        fontSize: '0.8rem',
      },
    },
    '& blockquote': {
      borderLeft: '2px solid #0d0d0d1a',
      paddingLeft: '1rem',
    },
    '& ul[data-type=taskList] li': {
      display: 'flex',
      alignItems: 'center',
      '&::mark': {
        display: 'none',
      },
    },
  },
  textAction: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      background: 'none',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '32px',
      width: '32px',
      marginRight: '1px',
      borderRadius: '2px',
      '& svg': {
        width: '20px',
        height: '20px',
      },
    },
    '& button:hover': {
      background: 'rgba(var(--sk_foreground_low,29,28,29),.13)',
    },
    '& button.is-active': {
      color: 'rgba(var(--sk_primary_foreground,29,28,29),1)',
      background: 'rgba(var(--sk_primary_foreground,29,28,29),.2)',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    rowGap: '8px',
    padding: '4px',
  },
  formButton: {
    height: '32px',
  },
  divider: {
    backgroundColor: '#0000001a',
    height: '1.25rem',
    marginLeft: '0.5rem',
    marginRight: '0.75rem',
    width: '2px',
  },
});

interface IPropTextEditor {
  placeholder?: string;
  label?: string;
  onSubmit?: any;
  onChange?: any;
  defaultText?: any;
  hideBtn?: boolean;
}

const MenuBar = ({ editor }: { editor: Editor }) => {
  if (!editor) {
    return null;
  }
  const classes = useStyles();

  const setLink = React.useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  return (
    <div className={classes.textAction}>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Icon type={'remixBold'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Icon type={'remixItalic'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <Icon type={'remixUnderline'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <Icon type={'remixStrokeThrough'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHighlight().run()}
        className={editor.isActive('highlight') ? 'is-active' : ''}
      >
        <Icon type={'remixHighlight'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        <Icon type={'remixCode'} />
      </button>
      <div className={classes.divider}></div>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={editor.isActive('heading', { level: 1 }) ? 'is-active' : ''}
      >
        <Icon type={'remixHeadOne'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={editor.isActive('heading', { level: 2 }) ? 'is-active' : ''}
      >
        <Icon type={'remixHeadTwo'} />
      </button>
      <button
        onClick={() => editor.chain().focus().setParagraph().run()}
        className={editor.isActive('paragraph') ? 'is-active' : ''}
      >
        <Icon type={'remixParagraph'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <Icon type={'remixBulletList'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Icon type={'remixOrderedList'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleTaskList().run()}
        className={editor.isActive('taskList') ? 'is-active' : ''}
      >
        <Icon type={'remixTaskList'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCodeBlock().run()}
        className={editor.isActive('codeBlock') ? 'is-active' : ''}
      >
        <Icon type={'remixCodeBlock'} />
      </button>
      <button onClick={setLink} className={editor.isActive('link') ? 'is-active' : ''}>
        <Icon type={'remixLink'} />
      </button>
      <div className={classes.divider}></div>
      <button
        onClick={() => editor.chain().focus().toggleBlockquote().run()}
        className={editor.isActive('blockquote') ? 'is-active' : ''}
      >
        <Icon type={'remixBlockQuote'} />
      </button>
      <button onClick={() => editor.chain().focus().setHorizontalRule().run()}>
        <Icon type={'remixSeparator'} />
      </button>
      <div className={classes.divider}></div>
      <button onClick={() => editor.chain().focus().setHardBreak().run()}>
        <Icon type={'remixTextWrap'} />
      </button>
      <button
        onClick={() => {
          editor.chain().focus().unsetAllMarks().run();
          editor.chain().focus().clearNodes().run();
        }}
      >
        <Icon type={'remixFormatClear'} />
      </button>
      <div className={classes.divider}></div>
      <button
        onClick={() => editor.chain().focus().undo().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Icon type={'remixUndo'} />
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        className={editor.isActive('orderedList') ? 'is-active' : ''}
      >
        <Icon type={'remixRedo'} />
      </button>
    </div>
  );
};

const TextEditor = ({
  placeholder = 'Write something...',
  label = 'Submit',
  onSubmit,
  onChange,
  defaultText,
  hideBtn = false,
}: IPropTextEditor) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
        renderLabel({ node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
      }),
      Link.configure({
        openOnClick: false,
        autolink: true,
      }),
    ],
    content: defaultText || '',
    onUpdate({ editor }) {
      if (onChange) {
        onChange(editor.getHTML());
      }
    },
  });
  const classes = useStyles();

  return (
    <div className={classes.textEditor}>
      <div className={classes.actions}>
        <MenuBar editor={editor} />
        {!hideBtn && (
          <Button
            variant="contained"
            color="primary"
            className={classes.formButton}
            onClick={() => {
              onSubmit(editor.getHTML());
            }}
          >
            {label}
          </Button>
        )}
      </div>
      <EditorContent editor={editor} />
    </div>
  );
};

export default TextEditor;
