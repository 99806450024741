import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import MuiToolTip, { TooltipProps } from '@material-ui/core/Tooltip';
import Typography from '../text-style/Typography';
import { Box } from '@material-ui/core';

/** Style */

const getMaxWidthTooltip = (mode: TypeToolTip) => {
  if (mode === 'short') return '76px';
  if (mode === 'long') return '183px';
  return '178px';
};

const useStyles = makeStyles(() => ({
  customTooltip: (props: TooltipCustomProps) => ({
    marginTop: '0px',
    marginLeft: '-5px',
    maxWidth: getMaxWidthTooltip(props.mode),
    width: props.width || 'auto',
    backgroundColor: colors.surface.darkDefault,
    '& p:first-child': {
      marginBottom: props.mode === 'long' ? '2px' : '0px',
    },
    '& p': {
      color: colors.surface.disable,
    },

    '& p:nth-child(2)': {
      marginBottom: '16px',
    },
  }),
}));

type TypeToolTip = 'short' | 'long' | 'title';

const TitleComponent = (mode: TypeToolTip, title?: string, subTitle?: string, content?: string) => {
  if (mode === 'short' || mode === 'long') return <Typography variant="body4">{content}</Typography>;
  return (
    <React.Fragment>
      <Typography variant="body1">{title}</Typography>
      <Typography variant="body4">{subTitle}</Typography>
      <Typography variant="body5">{content}</Typography>
    </React.Fragment>
  );
};

type TooltipCustomProps = Omit<TooltipProps, 'title'> & {
  mode: TypeToolTip;
  width?: string;
  content?: string;
  subTitle?: string;
  title?: string | JSX.Element;
};

const Tooltip: React.FC<TooltipCustomProps> = ({
  mode,
  title,
  subTitle,
  width,
  content,
  children,
  placement = 'bottom',
  className,
  ...rest
}: TooltipCustomProps) => {
  const classes = useStyles({ mode, width, children, ...rest });
  const [open, setOpen] = React.useState(false);
  return (
    <MuiToolTip
      {...rest}
      open={open}
      title={TitleComponent(mode, title, subTitle, content)}
      classes={{
        tooltip: `${classes.customTooltip} ${className}`,
      }}
      placement={placement}
    >
      <Box display={'inline-block'} onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
        {children}
      </Box>
    </MuiToolTip>
  );
};

export default Tooltip;
