import { getUserSettings } from '@ep/one/aim';
import { API_EIP_MANAGER_URL, API_GRPC_GATEWAY } from '@ep/one/global';
import * as _request from '@ep/one/src/utils/fetch';
import { get, lowerCase, startCase, uniqBy } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import hash from 'object-hash';
import qs from 'qs';
import { v4 as uuid } from 'uuid';
import { EIP_CONSTANT } from '../constant';

// swagger, api docs: https://staging-grpc-gateway-swagger.epsilo.io/swagger/root-management

const API = {
  GET_CHANNEL: () => API_GRPC_GATEWAY + '/root-management/get-channel',
  GET_COUNTRY: () => API_GRPC_GATEWAY + '/root-management/get-country',
  GET_STOREFRONT: () => API_GRPC_GATEWAY + '/root-management/get-user-storefront',
  GET_TOOL: () => API_GRPC_GATEWAY + '/root-management/get-tool',
  GET_DIMENSION_DEFINITION: () => API_GRPC_GATEWAY + '/ads-operation/get-dimension-definition',
  UPDATE_USER_MENU: () => API_EIP_MANAGER_URL + '/user/save_changeset',
  SAVE_DRAFT: () => API_EIP_MANAGER_URL + '/user/save_changeset',
};

const request = {
  memo: {},
  get: (url, body?: any) => {
    const cacheKey = hash(
      { url, body },
      {
        unorderedArrays: true,
      },
    );
    if (request.memo[cacheKey]) {
      return Promise.resolve(request.memo[cacheKey]);
    } else {
      request.memo[cacheKey] = _request.get(url, body).then((res) => {
        request.memo[cacheKey] = res;
        return res;
      });

      return request.memo[cacheKey];
    }
  },
  post: _request.post,
};

const getMarketplace = async (params?: { countryIds; storefrontIds; adTypeIds; scriptTargetIds }) => {
  const res = await request.get(API.GET_CHANNEL(), {
    countryCode: get(params, 'countryIds.0', undefined),
  });

  return uniqBy(
    res.data.map((i) => {
      return {
        id: i.code,
        text: i.name,
      };
    }),
    (i: any) => i.id,
  );
};

const getCountry = async (params?: { markplaceIds; storefrontIds; adTypeIds; scriptTargetIds; countryIds }) => {
  const res = await request.get(API.GET_COUNTRY(), {
    countryCode: get(params, 'countryIds.0', undefined),
  });

  return res.data.map((i) => {
    return {
      id: i.code,
      text: i.name,
      payload: {
        currency: i.currency,
        timezone: i.timezone,
      },
    };
  });
};
const getStorefront = async (params?: { marketplaceIds; countryIds; adTypeIds; scriptTargetIds }) => {
  const paramStr = qs.stringify(
    {
      channelCode: get(params, 'marketplaceIds.0', 'NONE'),
      siteCode: 'PUBLIC',
      pagination: {
        limit: -1,
      },
    },
    { allowDots: true },
  );
  const res = await request.get(`${API.GET_STOREFRONT()}?${paramStr}`);

  return res.data[0].listStorefront.map((w) => {
    const i = w.storefront;
    const country = get(i, 'channel.sites.0.countries.0', {});
    return {
      id: i.storefrontId,
      text: [i.channel.name, country.name, i.name].join(' / '),
      payload: {
        sid: i.sid,
        wallet: i.wallet,
        channel: {
          id: i.channel.code,
          name: i.channel.name,
        },
        country: country,
      },
    };
  });
};

const getStatus = async (params: {
  dimension: string;
  marketplaceCode: string;
  toolCode?: string;
  countryCode?: string;
}) => {
  let status = [];
  if (params.dimension === 'SCRIPT') {
    status = [
      { id: 'SCHEDULED', text: 'Scheduled' },
      { id: 'ONGOING', text: 'Ongoing' },
      { id: 'PAUSED', text: 'Paused' },
      { id: 'ENDED', text: 'Ended' },
    ];
  } else {
    const res = await request.get(API.GET_DIMENSION_DEFINITION(), params);
    if (res.success) {
      const values = get(res.data, ['data', 'value', 'status']);
      if (values) {
        status = (values.split(':')[1] || '').split(',').map((i) => {
          // FIXME: this is troublesome, user text derived from the system status.
          let label;
          switch (i) {
            case 'PAUSED': {
              label = 'Paused by Botep';
              break;
            }
            case 'SUSPENDED': {
              label = 'Paused';
              break;
            }
            case 'RECOVERING': {
              label = 'Resuming';
              break;
            }
            default:
              label = startCase(lowerCase(i));
          }
          return {
            id: i,
            text: label,
          };
        });
      }
    }
  }
  return status;
};

const getAdType = async (params?: { marketplaceIds; countryIds; adTypeIds; scriptTargetIds; toolIds }) => {
  const res = await request.get(API.GET_TOOL(), {
    toolCode: get(params, 'toolIds.0', 'TOOL_NONE'),
    channelCode: get(params, 'marketplaceIds.0', 'NONE'),
    type: get(params, 'adTypeIds.0', 'TYPE_NONE'),
  });

  const adTypes = uniqBy(res.data, (i: any) => {
    return i.type;
  }).map((i) => ({ id: String(i.type).toUpperCase(), text: i.typeName }));

  return adTypes;
};

const scriptGetAdTool = async (params?: { marketplaceIds; countryIds; storefrontIds; adTypeIds }) => {
  const res = await request.get(API.GET_TOOL(), {
    toolCode: get(params, 'toolIds.0', 'TOOL_NONE').toUpperCase(),
    channelCode: get(params, 'marketplaceIds.0', 'NONE').toUpperCase(),
    type: get(params, 'adTypeIds.0', 'TYPE_NONE').toUpperCase(),
  });

  return uniqBy(
    res.data.map((i) => {
      return {
        id: String(i.toolCode).toUpperCase(),
        text: i.toolName,
        payload: {
          adTypeId: i.type,
          adTypeName: i.typeName,
          ...i,
        },
      };
    }),
    (i: any) => i.id,
  );
};

const getAdTool = async (params?: { marketplaceIds; countryIds; storefrontIds; adTypeIds }) => {
  const res = await request.get(API.GET_TOOL(), {
    toolCode: get(params, 'toolIds.0', 'TOOL_NONE'),
    channelCode: get(params, 'marketplaceIds.0', 'NONE'),
    type: get(params, 'adTypeIds.0', 'TYPE_NONE'),
  });

  return res.data.map((i) => {
    return {
      id: i.toolCode,
      text: i.toolName,
      payload: {
        marketplaceId: i.channelCode,
        adTypeId: i.type,
        adTypeName: i.typeName,
      },
    };
  });
};

const getScriptTarget = async (params?: { marketplaceIds; countryIds; storefrontIds; adTypeIds }) => {
  return [
    {
      id: 'ad_object_keyword',
      text: 'Ad object - Keyword',
    },
  ];
};

export default {
  getStatus,
  getAdType,
  getCountry,
  getMarketplace,
  getScriptTarget,
  getStorefront,
  getAdTool,
  scriptGetAdTool,
};

export async function updateUserMenu(payload: {
  parentId: string;
  blockId: string;
  listAfterBlockId?: string;
  listBeforeBlockId?: string;
}) {
  const userId = getUserSettings().profile.userId;

  const changset = {
    request_id: uuid(),
    changeset: [
      {
        pointer: { id: payload.blockId, table: 'eip_block' },
        op: 'updateMenu',
        path: [],
        value: {
          listAfter: payload.listAfterBlockId,
          listBefore: payload.listBeforeBlockId,
          parent_id: payload.parentId,
          parent_table: 'eip_system_block',
          owner_id: userId,
          updated_by: userId,
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
        },
      },
    ],
  };

  return request.post(API.UPDATE_USER_MENU(), changset);
}

export async function getDraft({ parentId, entityId, templatePageId, workflowId }) {
  let draftId = entityId;
  if (draftId) {
    // load draft 1) all correct 2) incorrect blockId
    return { entityId, blockId: templatePageId };
  } else {
    draftId = 'draft' + nanoid();
    const blockId = uuid();
    // create new draft
    return createUserDraft({ parentId, entityId: draftId, blockId, templatePageId, workflowId }).then(async () => {
      await updateUserMenu({
        parentId: parentId,
        blockId: blockId,
      });
      return {
        entityId: draftId,
        blockId: blockId,
      };
    });
  }
}

export async function createUserDraft({
  parentId,
  entityId,
  blockId,
  templatePageId,
  parentTable,
  workflowId,
}: {
  parentId: string;
  parentTable?: string;
  entityId: string;
  blockId: string;
  templatePageId: string;
  workflowId: string;
}) {
  const userId = getUserSettings().profile.userId;
  const pointer = { id: blockId, table: 'user_block' };

  return request.post(API.SAVE_DRAFT(), {
    request_id: nanoid(),
    changeset: [
      {
        pointer: pointer,
        op: 'add',
        path: [],
        value: {
          content: [templatePageId],
          type: 'user_system_link',
          id: blockId,
          version: 1,
          parent_id: parentId,
          parent_table: parentTable || 'eip_system_block',
          owner_id: userId,
          updated_by: userId,
          created_at: moment().format(EIP_CONSTANT.DATETIME_FORMAT),
          updated_at: moment().format(EIP_CONSTANT.DATETIME_FORMAT),
          table: 'eip_block',
          payload_schema_version: 1,
          workspace_id: 'epsilo',
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['properties'],
        value: {
          title: '',
          workflowId: workflowId,
          systemTemplatePageId: templatePageId,
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['entity_id'],
        value: {
          entity_id: entityId,
        },
      },
    ],
  });
}
