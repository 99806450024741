import {
  apm,
  BlockFactoryContext,
  ContainerResponsiveContext,
  EIP_CONSTANT,
  NodeEditContext,
  retryTillSuccess,
  useLog,
} from '@eip/next/lib/main';
import { Box, styled, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { compare } from 'fast-json-patch';
import { debounce, get, isEqual, pick } from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BlockErrorDisplay } from './node/error';
import { ScriptEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/script-editor';

const log = useLog('dbf:block-factory');
const errorLog = log.extend('error');
const emptyFun = (...args) => log('emptyFun', ...args);

function BlockComponent({ blockData, chartLib }: { blockData: NodeData; chartLib: ChartLibComponent }) {
  return chartLib.render(null, blockData, null);
}

export function BlockFactory({ blocks, q }: { blocks: NodeData[]; q?: string }) {
  const factory = React.useContext(BlockFactoryContext);
  const { onUpdateCustomAttributes } = React.useContext(NodeEditContext);
  const getChartLib = React.useCallback(
    (chartLibId) => {
      const foundChart = factory.find(chartLibId);
      if (foundChart) return foundChart.blockComponent || foundChart.component;
      else {
        console.info('not found chart', chartLibId);
        return null;
      }
    },
    [q],
  );
  const mBlocks = React.useMemo(() => blocks, [q]);

  return (
    <React.Fragment>
      {mBlocks.map((blockData) => (
        <PureBlockRender
          key={blockData.id}
          data={blockData}
          chartLib={getChartLib(blockData.chartLibId)}
          onUpdateCustomAttributes={onUpdateCustomAttributes}
        />
      ))}
      <ScriptEditor />
    </React.Fragment>
  );
}

function UnControlledNodeFactory({
  data: blockData,
  chartLib,
  onUpdateCustomAttributes,
}: {
  data: NodeData;
  chartLib: any;
  onUpdateCustomAttributes: any;
}) {
  console.info('uncontrolled render...');
  return (
    <NodeEditContext.Provider
      value={{ isEditMode: false, onTextChange: emptyFun, onUpdateCustomAttributes: onUpdateCustomAttributes }}
    >
      <NodeFactory key={blockData.id} data={blockData}>
        <BlockComponent blockData={blockData} chartLib={chartLib} />
      </NodeFactory>
    </NodeEditContext.Provider>
  );
}

const PureBlockRender = React.memo(UnControlledNodeFactory, () => {
  return true;
});

const NodeContainer = styled('div')({
  height: '100%',
  width: '100%',
  fontSize: '16px',
});

const bpWidth = [176, 224, 270, 416, Number.POSITIVE_INFINITY];
const bpHeight = [80, 128, 176, 272, Number.POSITIVE_INFINITY];
const bpClassName = EIP_CONSTANT.CONTAINER_RESPONSIVE.breakpoints;

export function NodeFactory(props: { data: NodeData; children: JSX.Element; idPrefix?: string; refreshTime?: number }) {
  const containerRef = React.useRef<HTMLDivElement>();
  const [parentContainer, setParentContainer] = React.useState<HTMLElement>();
  const [containerBreakpointClass, setContainerBreakpointClass] = React.useState({
    width: null,
    height: null,
    className: null,
    rawWidth: null,
  });

  React.useLayoutEffect(() => {
    const [result, clear] = retryTillSuccess(() => {
      const placeholder = document.getElementById('block-' + props.data.id);
      return placeholder ? Promise.resolve(placeholder) : Promise.reject(null);
    }, 100);
    result.then((placeholder) => {
      setParentContainer(placeholder);
    });
    return clear;
  }, []);

  React.useEffect(() => {
    const updateContainerBp = debounce((bpWidthIndex, bpHeightIndex, bpKlass, width) => {
      setContainerBreakpointClass((state) => {
        const nuState = {
          width: 'ep-sw-' + bpWidthIndex,
          height: 'ep-sh-' + bpHeightIndex,
          className: bpKlass,
          rawWidth: width,
        };
        if (state.width !== nuState.width || state.height !== nuState.height || state.className !== nuState.className)
          return nuState;
        return state;
      });
    }, 50);
    if (containerRef.current) {
      const animationFrameIds = [];
      const ro = new ResizeObserver((entries) => {
        for (const entry of entries) {
          log(entry, props.data.chartLibId);
          const cr = entry.contentRect;
          const width = cr.width;
          const height = cr.height;
          const bpWidthIndex = bpWidth.findIndex((i) => width < i);
          const bpKlass = bpClassName.find((c) => c[1] > width)[0];
          const bpHeightIndex = bpHeight.findIndex((i) => height < i);
          window.requestAnimationFrame(() => updateContainerBp(bpWidthIndex, bpHeightIndex, bpKlass, width));
        }
      });

      // Observe one or multiple elements
      ro.observe(containerRef.current);
      if (containerRef.current.querySelector('.block-content-wrapper')) {
        ro.observe(containerRef.current.querySelector('.block-content-wrapper'));
      }

      return () => {
        ro.disconnect();
        animationFrameIds.forEach((id) => window.cancelAnimationFrame(id));
      };
    }
  }, [parentContainer]);

  if (!parentContainer) return null;

  return ReactDOM.createPortal(
    <React.Fragment>
      <NodeContainer
        id={[props.idPrefix || '', 'chart-container-', props.data.id].join('')}
        // style={{ fontSize: `${fontSize}px` }}
        style={{ fontSize: `14px`, height: '100%' }}
        className={clsx('single-chart-container', `chart-type-${props.data.chartLibId}`)}
        ref={containerRef}
      >
        <ContainerResponsiveContext.Provider
          value={{
            containerClass: containerBreakpointClass.className,
            containerWidth: containerBreakpointClass.rawWidth,
          }}
        >
          <ErrorBoundary blockData={props.data}>
            <div className={'block-content-wrapper'}>{props.children}</div>
          </ErrorBoundary>
        </ContainerResponsiveContext.Provider>
      </NodeContainer>
    </React.Fragment>,
    parentContainer,
  );
}

class ErrorBoundary extends React.Component<{ blockData: NodeData }> {
  state = { error: false };

  constructor(props) {
    super(props);
    this.state = { error: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { error: true };
  }

  componentDidCatch(error, errorInfo) {
    if (window.epGetTracer) {
      const span = window.epGetTracer().startSpan('error');
      span.addEvent('error', { error, errorInfo });
      span.setAttributes({
        sectionId: this.props.blockData.blockEid,
        sectionName: get(this.props.blockData, 'customAttributes.title'),
      });
      span.end();
    }
    errorLog(error, errorInfo);
  }

  resetSetting = () => {
    this.props.blockData;
  };

  render() {
    const { children, blockData } = this.props;

    if (blockData.chartLibId === 'richTable' && this.state.error) {
      return (
        <Box>
          Error. Click to <ButtonResetSetting blockData={blockData}></ButtonResetSetting> your settings if you still
          encounter this error persistently.
        </Box>
      );
    }

    if (this.state.error) {
      return <BlockErrorDisplay blockData={blockData} />;
    }
    return children;
  }
}

function ButtonResetSetting({ blockData }) {
  const context = React.useContext(NodeEditContext);
  const [resetClicked, setResetClicked] = React.useState(false);

  return (
    <Typography
      variant="button"
      style={{ cursor: 'pointer' }}
      onClick={() => {
        const systemPersonalizedField = EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS.reduce((carry, i) => {
          return { ...carry, [i]: get(blockData, ['_system', 'customAttributes', i], []) };
        }, {});
        context.onUpdateCustomAttributes(blockData, systemPersonalizedField);
        setResetClicked(true);
        window.setTimeout(() => {
          window.location.reload();
        }, 5000);
      }}
    >
      {!resetClicked ? 'reset' : 'resetting...'}
    </Typography>
  );
}
