import * as React from 'react';
import { get, set, last } from 'lodash';
import moment from 'moment';

import { makeStyles, Typography, Modal, Box, Button, InputAdornment, TextField } from '@material-ui/core';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { ISortItem } from '@ep/insight-ui/elements/inline-dialog/sort/sort-form-table/sort-form';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { IFilterItem } from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog/filter-form-group';
import Calendar from '@ep/insight-ui/elements/form-control/calendar/calendar-single/calendar';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const useStyles = makeStyles(() => ({
  buttonAdd: {
    padding: '6px 8px 6px 10px',
    marginTop: '16px',
    marginBottom: '14px',
  },
  mobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
    overflowY: 'auto',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
    marginBottom: '16px',
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  mobileButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
  mobileContent: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
  },
  mobileCloseBtn: {
    height: '24px',
    width: '24px',
  },
  mobileGroupRule: {
    display: 'flex',
    width: '92%',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  mobileBtnAdd: {
    width: '100%',
    justifyContent: 'flex-start',
    background: '#fff',
    color: '#253746',
    padding: '16px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  mobileSortItemHeader: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '13px',
      height: '13px',
    },
    columnGap: '5px',
    fontSize: '12px',
    color: '#8C98A4',
    marginTop: '16px',
    padding: '5px',
    justifyContent: 'space-between',
  },
  mobileIconDrag: {
    width: '6px',
  },
  formGroupMobile: {
    display: 'flex',
    flexDirection: 'column',
  },
  noSort: {
    textAlign: 'right',
    color: colors.icon.subdued,
    padding: '10px',
    '& h4': {
      marginTop: '2px',
    },
    '& p': {
      textAlign: 'left',
    },
  },
  iconNoSort: {
    marginTop: '24px',
  },
  breakline: {
    background: '#E4E7E9',
    width: '95%',
    height: '1px',
    margin: 'auto',
  },
  formAction: {
    marginTop: '16px',
  },
  textInput: {
    width: '100%',
    background: '#FFF',
    padding: '10px',
    borderRadius: '4px',
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
    },
    '& .eip1-MuiOutlinedInput-adornedEnd': {
      paddingRight: '0',
    },
  },
  groupLabelOperator: {
    color: '#8C98A4',
    fontSize: '12px',
    lineHeight: '16px',
  },
}));

const useStylesModal = makeStyles(() => ({
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
    overflowY: 'auto',
  },
  modalHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  modalGroupItems: {
    marginTop: '16px',
    overflowY: 'auto',
    '& .rmdp-header': {
      padding: '16px 0',
    },
  },
  groupItem: {
    background: '#FFF',
    '&:first-child': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '& button': {
      justifyContent: 'flex-start',
      width: '100%',
      fontWeight: 400,
      padding: '10px',
      '&.eip1-MuiButton-text:hover': {
        backgroundColor: 'transparent',
      },
      '&.eip1-MuiButton-text:active': {
        backgroundColor: '#006EC6',
      },
    },
    '& button .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: 0,
      width: '100%',
    },
    '& .disabled': {
      color: '#C2C7CB',
    },
  },
  groupItemTitle: {
    textAlign: 'left',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
  },
  groupItemPlaceholder: {
    color: '#8C98A4',
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  modalButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  modalButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
  buttonMenuItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  buttonMenuLabel: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
  },
  textInput: {
    width: '100%',
    background: '#FFF',
    marginTop: '16px',
    padding: '10px',
    borderRadius: '4px',
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
    },
  },
  modalQuickActionContent: {
    height: '100vh',
    width: '100vw',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    paddingBottom: '20px',
  },
  modalQuickActionMenu: {
    width: '95%',
  },
  modalAction: {
    backgroundColor: '#FFFFFF',
    borderRadius: '4px',
    marginTop: '16px',
  },
  mobileBtnAdd: {
    width: '100%',
    justifyContent: 'flex-start',
    background: '#fff',
    color: '#253746',
    padding: '16px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  subText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8C98A4',
    fontWeight: 400,
  },
  buttonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '12px',
  },
}));

interface IPropsMenuItemComponent {
  disable?: boolean;
  icon?: IconType;
  secondaryIcon?: IconType;
  title?: string;
  onClick?: any;
  threeDots?: boolean;
  name?: string;
  label?: string;
  subText?: string;
  itemLabelColor?: string;
}

export const MenuItemComponent = ({
  disable,
  icon,
  secondaryIcon,
  title,
  onClick,
  threeDots = false,
  ...rest
}: IPropsMenuItemComponent) => {
  const classes = useStylesModal();
  const btnRef = React.useRef(null);

  React.useEffect(() => {
    if (btnRef.current && threeDots) {
      btnRef.current.querySelector('span').style.width = `${btnRef.current.offsetWidth}px`;
    }
  }, [btnRef.current, threeDots]);

  return (
    <div className={classes.groupItem}>
      <Button disabled={disable} onClick={onClick} className={disable ? 'disabled' : ''}>
        <div className={classes.buttonMenuItem} ref={btnRef}>
          <div className={classes.buttonMenuLabel}>
            {icon && (
              <div className={classes.buttonIcon}>
                <Icon type={icon} width={'12px'} height={'12px'} />
              </div>
            )}
            <span
              className={`${classes.groupItemTitle} ${rest.name ? classes.groupItemPlaceholder : ''} ${
                threeDots && classes.ellipsis
              }`}
              style={{
                color: rest.itemLabelColor ? rest.itemLabelColor : null,
              }}
            >
              {title === '' || title === undefined ? rest.name || rest.label : title}
            </span>
          </div>
          <div>
            {rest.subText ? <span className={classes.subText}>{rest.subText}</span> : null}
            {secondaryIcon && <Icon type={secondaryIcon} width={'12px'} height={'12px'} />}
          </div>
        </div>
      </Button>
    </div>
  );
};

const sortObject = {
  asc: 'Ascending',
  desc: 'Descending',
};

interface IPropsMobilePropertiesMenu {
  dataMenuPage: any[];
  setPropertiesModalOpen: any;
  hasSearch?: boolean;
  handleChangeRule?: any;
  index?: any;
  type?: 'VALUE' | 'FIELD' | 'DEFAULT';
  itemLabelColor?: string;
}

export const MobilePropertiesMenu = ({
  dataMenuPage,
  setPropertiesModalOpen,
  hasSearch = false,
  handleChangeRule,
  index,
  type = 'DEFAULT',
  itemLabelColor,
}: IPropsMobilePropertiesMenu) => {
  const classes = useStylesModal();

  const [searchValue, setSearchValue] = React.useState('');

  const handleClose = () => {
    setPropertiesModalOpen(null);
  };

  const MenuItems = dataMenuPage
    .filter(
      (el) =>
        !searchValue ||
        el.label.toLowerCase().includes(searchValue.toLowerCase()) ||
        el.value.toLowerCase().includes(searchValue.toLowerCase()),
    )
    .map((el, idx) => {
      return (
        <MenuItemComponent
          key={idx}
          {...{
            ...el,
            onClick() {
              if (type === 'VALUE') {
                handleChangeRule('queryValue', el, index);
              } else if (type === 'FIELD') {
                handleChangeRule('field', el, index);
              } else {
                el.onClick();
              }
              setPropertiesModalOpen(false);
            },
          }}
          itemLabelColor={itemLabelColor}
        />
      );
    });

  return (
    <Box className={classes.modalContent}>
      <div className={classes.modalHeader}>
        <div className={classes.modalButtonCancel}>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <h4 className={classes.modalTitle}>Actions</h4>
      </div>
      {hasSearch && (
        <TextField
          variant="outlined"
          className={classes.textInput}
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position={'start'}>
                <Icon type={'searchIcon'} colorIcon={'#8C98A4'} />
              </InputAdornment>
            ),
          }}
          placeholder={'Search for properties...'}
        />
      )}

      <div className={classes.modalGroupItems}>{MenuItems}</div>
    </Box>
  );
};

interface IPropsMobileQuickActionMenu {
  dataMenuPage: any[];
  setPropertiesModalOpen: any;
  handleChangeRule: any;
  index: number;
  type?: 'SORT' | 'FILTER' | 'OPERATOR';
}

const MobileQuickActionMenu = ({
  dataMenuPage,
  setPropertiesModalOpen,
  handleChangeRule,
  index,
  type = 'SORT',
}: IPropsMobileQuickActionMenu) => {
  const classes = useStylesModal();

  const handleClose = () => {
    setPropertiesModalOpen(null);
  };

  const MenuItems = dataMenuPage.map((el, idx) => {
    return (
      <MenuItemComponent
        key={idx}
        {...{
          ...el,
          onClick() {
            if (type === 'SORT') {
              handleChangeRule('sort', el.value, index);
            } else if (type === 'FILTER') {
              handleChangeRule('queryType', el, index);
            } else if (type === 'OPERATOR') {
              handleChangeRule(el.value);
            }
            setPropertiesModalOpen(null);
          },
        }}
      />
    );
  });

  return (
    <Box className={classes.modalQuickActionContent} onClick={handleClose}>
      <Box className={classes.modalQuickActionMenu}>
        <div className={classes.modalGroupItems}>{MenuItems}</div>
      </Box>
    </Box>
  );
};

interface IPropsMobileCalendarMenu {
  handleChangeRule: any;
  index: number;
  setPropertiesModalOpen: any;
  queryValue: string;
}

const MobileCalendarMenu = ({
  handleChangeRule,
  index,
  setPropertiesModalOpen,
  queryValue,
}: IPropsMobileCalendarMenu) => {
  const classes = useStylesModal();

  const handleClose = () => {
    setPropertiesModalOpen(null);
  };

  return (
    <Box className={classes.modalContent}>
      <div className={classes.modalHeader}>
        <div className={classes.modalButtonCancel}>
          <Button color="inherit" onClick={handleClose}>
            Cancel
          </Button>
        </div>
        <h4 className={classes.modalTitle}>Date</h4>
      </div>

      <div className={classes.modalGroupItems}>
        <Calendar
          value={queryValue}
          onChange={(val) => {
            handleChangeRule('queryValue', val, index);
            handleClose();
          }}
        />
      </div>
    </Box>
  );
};

interface IPropsMobileSortMenu {
  isDialogSort: boolean;
  onClose: any;
  setIsDialogSort: any;
  sortData: ISortItem[];
  disabledIconNoFilter: boolean;
  handleAddRule: any;
  handleSubmit: any;
  handleDeleteRule: any;
  getOptions: any;
  handleChangeRule: any;
}

export const MobileSortMenu = ({
  isDialogSort,
  onClose,
  setIsDialogSort,
  sortData,
  disabledIconNoFilter,
  handleAddRule,
  handleSubmit,
  handleDeleteRule,
  getOptions,
  handleChangeRule,
}: IPropsMobileSortMenu) => {
  const classes = useStyles();
  const [propertiesModalOpen, setPropertiesModalOpen] = React.useState(null);
  const [quickActionModalOpen, setQuickActionModalOpen] = React.useState(null);

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');
  return (
    <Modal
      open={isDialogSort}
      onClose={() => setIsDialogSort(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.mobileContainer}>
        <div className={classes.mobileHeader}>
          <div className={classes.mobileButtonCancel}>
            <Button
              color="inherit"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
          <h4 className={classes.mobileTitle}>Sort</h4>
          <div className={classes.mobileButton}>
            <Button
              color="primary"
              onClick={() => {
                if (sortData.length === 0) {
                  setIsDialogSort(false);
                  onClose();
                } else {
                  return uawl.start('canSortEtable', { title: titleTable, timerMs: 3000 }).then(() => handleSubmit());
                }
              }}
            >
              {sortData.length > 0 ? 'Apply' : 'Done'}
            </Button>
          </div>
        </div>
        {sortData.map((rule: ISortItem, index: number) => (
          <div key={index.toString()}>
            <div className={classes.mobileSortItemHeader}>
              <span>Sort {index + 1}</span>
            </div>
            <div className={classes.mobileContent}>
              <MenuItemComponent
                title={rule.field?.label}
                secondaryIcon={'chevronmediumrightcustom'}
                onClick={() => setPropertiesModalOpen(index)}
              />
              <Modal
                open={propertiesModalOpen === index}
                onClose={() => setPropertiesModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MobilePropertiesMenu
                  dataMenuPage={getOptions(get(rule, ['field', 'value'], ''))}
                  setPropertiesModalOpen={setPropertiesModalOpen}
                  handleChangeRule={handleChangeRule}
                  hasSearch={true}
                  index={index}
                  type={'FIELD'}
                />
              </Modal>
              <MenuItemComponent
                title={sortObject[rule.sort]}
                secondaryIcon={'chevronmediumrightcustom'}
                onClick={() => setQuickActionModalOpen(index)}
              />
              <Modal
                open={quickActionModalOpen === index}
                onClose={() => setQuickActionModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MobileQuickActionMenu
                  dataMenuPage={Object.keys(sortObject).map((el) => ({
                    label: sortObject[el],
                    value: el,
                  }))}
                  setPropertiesModalOpen={setQuickActionModalOpen}
                  handleChangeRule={handleChangeRule}
                  index={index}
                />
              </Modal>
              <div className={classes.breakline} />
              <MenuItemComponent title="Delete" icon={'delete'} onClick={() => handleDeleteRule(index)} />
            </div>
          </div>
        ))}
        {sortData.length === 0 && (
          <div className={classes.mobileContent}>
            <Box className={classes.noSort}>
              {!disabledIconNoFilter && (
                <div className={classes.iconNoSort}>
                  <Icon type="noResult" />
                </div>
              )}
              <Typography variant="body2">Use a sort to:</Typography>
              <Typography variant="body2">Order ads by high or low performance conditions</Typography>
              <Typography variant="body2">Order ads by name of storefront, campaign or other dimension</Typography>
              <Typography variant="body2">Show recently lived campaign</Typography>
            </Box>
          </div>
        )}
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon type="plus" size="12px" />}
          className={`${classes.buttonAdd} ${classes.mobileBtnAdd}`}
          onClick={handleAddRule}
        >
          Add a sort
        </Button>
      </Box>
    </Modal>
  );
};

interface IPropsMobileFilterMenu {
  isDialogFilter: boolean;
  onClose: any;
  setIsDialogFilter: any;
  filterData: IFilterItem[];
  disabledIconNoFilter: boolean;
  handleAddRule: any;
  handleSubmit: any;
  handleDeleteRule: any;
  getOptions: any;
  handleChangeRule: any;
  handleAddGroup: any;
  children: any;
}

export const MobileFilterMenu = ({
  isDialogFilter,
  onClose,
  setIsDialogFilter,
  filterData,
  disabledIconNoFilter,
  handleAddRule,
  handleSubmit,
  handleAddGroup,
  children,
}: IPropsMobileFilterMenu) => {
  const classes = useStyles();
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');
  return (
    <Modal
      open={isDialogFilter}
      onClose={() => setIsDialogFilter(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={classes.mobileContainer}>
        <div className={classes.mobileHeader}>
          <div className={classes.mobileButtonCancel}>
            <Button
              color="inherit"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </Button>
          </div>
          <h4 className={classes.mobileTitle}>Filter</h4>
          <div className={classes.mobileButton}>
            <Button
              color="primary"
              onClick={() => {
                if (filterData.length === 0) {
                  setIsDialogFilter(false);
                  onClose();
                } else {
                  return uawl.start('canFilterEtable', { title: titleTable, timerMs: 3000 }).then(() => handleSubmit());
                }
              }}
            >
              {filterData.length > 0 ? 'Apply' : 'Done'}
            </Button>
          </div>
        </div>
        {children}
        {filterData.length === 0 && (
          <div className={classes.mobileContent}>
            <Box className={classes.noSort}>
              {!disabledIconNoFilter && (
                <div className={classes.iconNoSort}>
                  <Icon type="noResult" />
                </div>
              )}
              <Typography variant="body2">Use a sort to:</Typography>
              <Typography variant="body2">Order ads by high or low performance conditions</Typography>
              <Typography variant="body2">Order ads by name of storefront, campaign or other dimension</Typography>
              <Typography variant="body2">Show recently lived campaign</Typography>
            </Box>
          </div>
        )}
        <div className={`${classes.mobileContent} ${classes.formAction}`}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon type="plus" size="12px" />}
            className={classes.mobileBtnAdd}
            onClick={handleAddRule}
          >
            Add a filter
          </Button>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon type="addGroup" size="12px" />}
            className={classes.mobileBtnAdd}
            onClick={handleAddGroup}
          >
            Add a filter group
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

const groupOperator: OptionSelectType[] = [
  {
    label: 'And',
    value: 'and',
    note: 'All filter must match.',
  },
  {
    label: 'Or',
    value: 'or',
    note: 'At least one filter must match.',
  },
];

export const MobileFilterGroupModal = (props: any) => {
  const classes = useStylesModal();

  const handleClose = () => {
    props.setPropertiesModalOpen(null);
  };

  const getFilterItem = (arr: IFilterItem[]) => {
    if (arr.length > 0) {
      const simpleFilter = arr.filter((item) => item.type == 'filter' && !item.static);
      if (simpleFilter.length > 0) {
        const lastItem = last(simpleFilter);
        return { ...lastItem };
      }
    }
    return props.getDefaultRule();
  };

  const handleAddRule = () => {
    const tmpModel: Array<IFilterItem> = JSON.parse(JSON.stringify(props.filterData));
    const defaultFilterItem = getFilterItem(tmpModel);
    if (tmpModel.length > 1) {
      defaultFilterItem.logicalOperator = tmpModel[1].logicalOperator;
    }
    tmpModel.push({ ...defaultFilterItem, static: false });
    props.setFilterData(tmpModel);
  };

  const handleAddGroup = () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(props.filterData));
    const defaultFilterItem = getFilterItem(tmpModel);
    let logicalOperator = 'and';
    if (tmpModel.length > 1) {
      logicalOperator = tmpModel[1].logicalOperator;
    }
    const groupDefault: IFilterItem = {
      type: 'groupFilter',
      logicalOperator: logicalOperator,
      subFilters: [defaultFilterItem],
    };
    tmpModel.push(groupDefault);
    props.setFilterData(tmpModel);
  };

  return (
    <Box className={classes.modalContent}>
      <div className={classes.modalHeader}>
        <div className={classes.modalButtonCancel}>
          <Button color="primary" onClick={handleClose}>
            Back
          </Button>
        </div>
        <h4 className={classes.modalTitle}>Filter group {props.index + 1}</h4>
      </div>

      <div className={classes.modalGroupItems}>
        <MobileFilterGroup {...props} />
      </div>

      <div className={classes.modalAction}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon type="plus" size="12px" />}
          className={classes.mobileBtnAdd}
          onClick={handleAddRule}
        >
          Add a filter
        </Button>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Icon type="addGroup" size="12px" />}
          className={classes.mobileBtnAdd}
          onClick={handleAddGroup}
        >
          Add a filter group
        </Button>
      </div>
    </Box>
  );
};

export const MobileFilterGroup = ({
  filterData,
  setFilterData,
  options,
  getFieldOperators,
  getOptionAvailable,
  getDefaultRule,
}: any) => {
  const classes = useStyles();
  const [propertiesModalOpen, setPropertiesModalOpen] = React.useState(null);
  const [quickActionModalOpen, setQuickActionModalOpen] = React.useState(null);
  const [filterGroupModalOpen, setFilterGroupModalOpen] = React.useState(null);
  const [operatorModalOpen, setOperatorModalOpen] = React.useState(null);
  const [valueModalOpen, setValueModalOpen] = React.useState(null);
  const [valueOptions, setValueOptions] = React.useState([]);
  const previousFilterGroupLength = React.useRef({
    shouldOpenFilterGroupModal: false,
    length: 0,
  });

  // Open filter group modal when clicking add filter group
  React.useEffect(() => {
    if (
      filterData.length > previousFilterGroupLength.current.length &&
      filterData[filterData.length - 1].type === 'groupFilter' &&
      previousFilterGroupLength.current.shouldOpenFilterGroupModal
    ) {
      setFilterGroupModalOpen(filterData.length - 1);
    }
    previousFilterGroupLength.current.length = filterData.length;
  }, [filterData.length]);

  // Do not open filter group modal when open filter menu
  React.useEffect(() => {
    previousFilterGroupLength.current.shouldOpenFilterGroupModal = true;
  }, []);

  const handleChangeRule = async (
    field: 'field' | 'queryType' | 'queryValue',
    value: string | OptionSelectType,
    index: number,
  ) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));

    if (index !== -1) {
      if (field === 'queryValue' && typeof value == 'string') {
        tmpModel[index][field].value = value;
      } else if (field === 'field') {
        tmpModel[index][field] = value;
        const queryType = get(getFieldOperators((value as OptionSelectType).dataType), [0, 0], null);
        if (queryType) {
          tmpModel[index]['queryType'] = queryType;
        }

        if (typeof value !== 'string' && value.value === 'campaign_timeline') {
          tmpModel[index]['queryValue'].value = moment(new Date()).format('YYYY/MM/DD');
        } else {
          tmpModel[index]['queryValue'].value = null;
        }
      } else {
        tmpModel[index][field] = value;
      }
      setFilterData(tmpModel);
    }
  };
  const handleChangeLogicalOperator = (value: string) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));
    const logicalOperatorValue = value.toLocaleLowerCase() == 'and' ? 'and' : 'or';
    tmpModel.map((item, index) => {
      if (index !== 0) {
        item.logicalOperator = logicalOperatorValue;
      }
    });
    setFilterData(tmpModel);
  };
  const handleDeleteRule = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));
    if (tmpModel[index]) {
      tmpModel.splice(index, 1);
    }
    setFilterData(tmpModel);
  };

  const handleDuplicateRule = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));
    if (tmpModel[index]) {
      tmpModel.splice(index, 0, tmpModel[index]);
    }
    setFilterData(tmpModel);
  };

  const getValueLabel = (rule: IFilterItem) => {
    const dataType = get(rule, 'field.dataType', '');
    const queryValue = get(rule, 'queryValue.value', '');

    if (dataType == 'date' || dataType == 'datetime') {
      return queryValue ? moment(queryValue, 'DD/MM/YYYY').format('MMMM DD, YYYY') : '';
    }

    const useSelected = (get(rule, ['field', 'selectedOperators'], []) as Array<string>).includes(
      get(rule, ['queryType', 'value'], ''),
    );

    if (useSelected) {
      return rule.queryValue?.label;
    }

    return queryValue;
  };

  const handleSetGroup = (index: number, childFilters: IFilterItem[]) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));
    if (tmpModel[index] && tmpModel[index].type == 'groupFilter') {
      if (childFilters.length > 0) {
        (tmpModel[index] as IFilterItem).subFilters = childFilters;
      } else {
        tmpModel.splice(index, 1);
      }
      setFilterData(tmpModel);
    }
  };

  const handleTurnIntoGroup = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(filterData));
    if (tmpModel[index]) {
      let logicalOperator = '';
      if (tmpModel.length > 1) {
        logicalOperator = tmpModel[1].logicalOperator;
      }
      const itemCloned: IFilterItem = tmpModel[index];
      const groupDefault: IFilterItem = {
        type: 'groupFilter',
        logicalOperator: logicalOperator,
        subFilters: [itemCloned],
      };
      tmpModel.splice(index, 1);
      tmpModel.splice(index, 0, groupDefault);
      setFilterData(tmpModel);
    }
  };

  const getValueContentWithRule = (rule: IFilterItem, index: number) => {
    const queryValue = get(rule, 'queryValue.value', '');
    const operatorValue = get(rule, 'queryType.value', '');
    const isEmptyOperator = operatorValue == 'is_empty' || operatorValue == 'is_not_empty';

    const fieldValue = get(rule, 'field.value', '');
    const dataType = get(rule, 'field.dataType', '');

    if (dataType == 'date' || dataType == 'datetime')
      return (
        <>
          <MenuItemComponent
            disable={isEmptyOperator}
            title={getValueLabel(rule)}
            secondaryIcon={'calendar'}
            onClick={() => {
              setValueModalOpen(index);
              getOptionAvailable(get(rule, 'field.value', '')).then((res) => {
                setValueOptions(res);
              });
            }}
            name={!queryValue && 'Select a date'}
          />
          <Modal
            open={valueModalOpen === index}
            onClose={() => setValueModalOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MobileCalendarMenu
              setPropertiesModalOpen={setValueModalOpen}
              handleChangeRule={handleChangeRule}
              queryValue={queryValue}
              index={index}
            />
          </Modal>
        </>
      );

    const useSelected = (get(rule, ['field', 'selectedOperators'], []) as Array<string>).includes(
      get(rule, ['queryType', 'value'], ''),
    );

    if (useSelected) {
      return (
        <>
          <MenuItemComponent
            isEmptyOperator={isEmptyOperator}
            title={getValueLabel(rule)}
            secondaryIcon={'chevronmediumrightcustom'}
            onClick={() => {
              setValueModalOpen(index);
              getOptionAvailable(get(rule, 'field.value', '')).then((res) => {
                setValueOptions(res.data);
              });
            }}
            name={!queryValue && 'Select an option'}
          />
          <Modal
            open={valueModalOpen === index}
            onClose={() => setValueModalOpen(null)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MobilePropertiesMenu
              dataMenuPage={valueOptions}
              setPropertiesModalOpen={setValueModalOpen}
              handleChangeRule={handleChangeRule}
              hasSearch={true}
              index={index}
              type={'VALUE'}
            />
          </Modal>
        </>
      );
    }

    const queryUnit = get(rule, 'queryValue.unit', '');

    return (
      <TextField
        variant="outlined"
        className={classes.textInput}
        value={queryValue}
        onChange={(e) => handleChangeRule('queryValue', e.target.value, index)}
        InputProps={{
          endAdornment: queryValue ? (
            <Icon
              type={'closeCircle'}
              colorIcon={'#8C98A4'}
              onClick={() => handleChangeRule('queryValue', '', index)}
            />
          ) : (
            <Icon type={'edit'} colorIcon={'#8C98A4'} />
          ),
        }}
        placeholder={'Value'}
        disabled={isEmptyOperator}
      />
    );
  };

  const getGroupLabel = (rule: IFilterItem) => {
    const operator = rule.subFilters[1]?.logicalOperator;
    return rule.subFilters.map((subFilter, index) => {
      const label = `${subFilter.field.label} ${subFilter.queryType.label.toLowerCase()} ${getValueLabel(subFilter)}`;
      if (index > 0) {
        return (
          <span key={index}>
            <span className={classes.groupLabelOperator}> {operator} </span>
            {subFilter.type === 'groupFilter' ? <span>({getGroupLabel(subFilter)})</span> : <span>{label}</span>}
          </span>
        );
      }
      if (subFilter.type === 'groupFilter') return <span>({getGroupLabel(subFilter)})</span>;
      return <span key={index}>{label}</span>;
    });
  };

  return filterData.map((rule: IFilterItem, index: number) => {
    const isStatic = get(rule, 'static', false);
    const isGroup = get(rule, 'type', 'filter') === 'groupFilter';
    return (
      <div key={index.toString()}>
        {index == 1 && !isStatic && (
          <div className={`${classes.mobileContent} ${classes.formAction}`}>
            <MenuItemComponent
              title={groupOperator.find((operator) => operator.value === rule.logicalOperator).label}
              secondaryIcon={'chevronmediumrightcustom'}
              onClick={() => setOperatorModalOpen(index)}
            />
            <Modal
              open={operatorModalOpen === index}
              onClose={() => setOperatorModalOpen(null)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <MobileQuickActionMenu
                dataMenuPage={groupOperator}
                setPropertiesModalOpen={setOperatorModalOpen}
                handleChangeRule={handleChangeLogicalOperator}
                index={index}
                type={'OPERATOR'}
              />
            </Modal>
          </div>
        )}

        <div className={classes.mobileSortItemHeader}>
          <span>
            {isGroup ? 'Filter group' : 'Filter'} {index + 1}
          </span>
          {isGroup && (
            <span>
              {rule.subFilters.length} rule{rule.subFilters.length > 1 ? 's' : ''}
            </span>
          )}
        </div>
        <div className={classes.mobileContent}>
          {isGroup ? (
            <>
              <MenuItemComponent
                title={getGroupLabel(rule)}
                secondaryIcon={'chevronmediumrightcustom'}
                onClick={() => setFilterGroupModalOpen(index)}
                threeDots={true}
              />
              <Modal
                open={filterGroupModalOpen === index}
                onClose={() => setFilterGroupModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MobileFilterGroupModal
                  filterData={rule.subFilters}
                  setFilterData={(val: IFilterItem[]) => {
                    handleSetGroup(index, val);
                  }}
                  options={options}
                  handleChangeRule={handleChangeRule}
                  getFieldOperators={getFieldOperators}
                  getOptionAvailable={getOptionAvailable}
                  handleTurnIntoGroup={handleTurnIntoGroup}
                  setPropertiesModalOpen={setFilterGroupModalOpen}
                  getDefaultRule={getDefaultRule}
                  index={index}
                />
              </Modal>
            </>
          ) : (
            <>
              <MenuItemComponent
                title={rule.field?.label}
                secondaryIcon={'chevronmediumrightcustom'}
                onClick={() => setPropertiesModalOpen(index)}
              />
              <Modal
                open={propertiesModalOpen === index}
                onClose={() => setPropertiesModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MobilePropertiesMenu
                  dataMenuPage={options}
                  setPropertiesModalOpen={setPropertiesModalOpen}
                  handleChangeRule={handleChangeRule}
                  hasSearch={true}
                  index={index}
                  type={'FIELD'}
                />
              </Modal>
              <MenuItemComponent
                title={rule.queryType?.label}
                secondaryIcon={'chevronmediumrightcustom'}
                onClick={() => setQuickActionModalOpen(index)}
              />
              <Modal
                open={quickActionModalOpen === index}
                onClose={() => setQuickActionModalOpen(null)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <MobileQuickActionMenu
                  dataMenuPage={
                    getFieldOperators(rule.field?.dataType) ? getFieldOperators(rule.field?.dataType)[0] : []
                  }
                  setPropertiesModalOpen={setQuickActionModalOpen}
                  handleChangeRule={handleChangeRule}
                  index={index}
                  type={'FILTER'}
                />
              </Modal>
              {getValueContentWithRule(rule, index)}
            </>
          )}
          {!isStatic && (
            <>
              <div className={classes.breakline} />
              <MenuItemComponent title="Remove" icon={'remove'} onClick={handleDeleteRule(index)} />
              <MenuItemComponent title="Duplicate" icon={'addduplicate'} onClick={handleDuplicateRule(index)} />
              {!isGroup && (
                <MenuItemComponent
                  title="Turn into group"
                  icon={'turnIntoGroup'}
                  onClick={handleTurnIntoGroup(index)}
                />
              )}
            </>
          )}
        </div>
      </div>
    );
  });
};
