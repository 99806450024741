import { atom } from 'jotai';

const scriptEditor = atom({
  isDisplay: false,
  content: '',
  submit: (content: string) => {},
  test: (content: string) => {},
  ref: null,
  backboneConfig: null,
  availableVars: [],
  language: 'javascript',
  defaultWidth: 1,
});

export { scriptEditor as scriptEditor };
