import { EIP_CONSTANT } from '@eip/next/lib/main';

export const SCRIPT_LOG_CONFIG = {
  title: 'Log',
  dimension: [],
  metric: [],
  attribute: [],
  hiddenColumns: [],
  requestHiddenField: {},
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getScriptLogData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/programmatic/get-dimensions?table=SCRIPT_LOG',
  },
  primaryKeys: [
    'SCRIPT_LOG.script_id',
    'SCRIPT_LOG.log_executed_id',
    // 'ADS_PLACEMENT.id',
    // 'ADS_CALENDAR.id',
  ],
  mapping: {
    // keyword: {
    //   title: 'Keyword',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   valueGetter: {
    //     label: 'ADS_PLACEMENT.name',
    //     id: 'ADS_PLACEMENT.id',
    //   },
    // },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.object_name',
      valueGetter: {
        ...(ff.log_table_groupby ? { id: 'SCRIPT_LOG.object_id' } : {}),
        label: 'SCRIPT_LOG.object_name',
        subtext: 'SCRIPT_LOG.object_sid',
        scriptId: 'SCRIPT_LOG.script_id',
        logExecuteId: 'SCRIPT_LOG.log_executed_id',
      },
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.campaign_name',
      valueGetter: {
        ...(ff.log_table_groupby ? { id: 'SCRIPT_LOG.campaign_id' } : {}),
        label: 'SCRIPT_LOG.campaign_name',
        subtext: 'SCRIPT_LOG.campaign_code',
      },
    },
    ...(ff.log_table_groupby
      ? {
          keyword: {
            title: 'Keyword',
            propertyType: 'dimension',
            cellFormat: 'text',
            dataType: 'string',
            filterField: 'SCRIPT_LOG.placement_name',
            valueGetter: {
              id: 'SCRIPT_LOG.placement_id',
              label: 'SCRIPT_LOG.placement_name',
            },
          },
        }
      : {}),
    // marketplace: {
    //   title: 'Marketplace',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   valueGetter: {
    //     label: 'MARKETPLACE.marketplace_name',
    //     id: 'MARKETPLACE.marketplace_code',
    //   },
    // },
    // country: {
    //   title: 'Country',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   valueGetter: {
    //     label: 'COUNTRY.country_code',
    //     id: 'COUNTRY.country_code',
    //   },
    // },

    // ============= ATTRIBUTE =============
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.campaign_status',
      valueGetter: {
        label: 'SCRIPT_LOG.campaign_status',
        status: 'SCRIPT_LOG.campaign_status',
      },
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.object_status',
      valueGetter: {
        label: 'SCRIPT_LOG.object_status',
        status: 'SCRIPT_LOG.object_status',
      },
    },
    // keyword_status: {
    //   title: 'Keyword status',
    //   propertyType: 'attribute',
    //   cellFormat: 'status',
    //   valueGetter: {
    //     label: 'ADS_PLACEMENT.status',
    //     status: 'ADS_PLACEMENT.status',
    //   },
    //   menu: [
    //     {
    //       name: 'Publish now',
    //       icon: 'upload',
    //       condition: [
    //         {
    //           field: 'ADS_PLACEMENT.status',
    //           operator: 'CONTAIN',
    //           values: ['DRAFT'],
    //         },
    //       ],
    //       payload: {
    //         field: {
    //           status: 'ADS_PLACEMENT.status',
    //         },
    //         static: {
    //           status: 'PUBLISHING',
    //         },
    //       },
    //     },
    //     {
    //       name: 'Pause it',
    //       icon: 'pausedRounded',
    //       condition: [
    //         {
    //           field: 'ADS_PLACEMENT.status',
    //           operator: 'CONTAIN',
    //           values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
    //         },
    //       ],
    //       payload: {
    //         field: {
    //           status: 'ADS_PLACEMENT.status',
    //         },
    //         static: {
    //           status: 'SUSPENDED',
    //         },
    //       },
    //     },
    //     {
    //       name: 'Resume it',
    //       icon: 'refreshRounded',
    //       condition: [
    //         {
    //           field: 'ADS_PLACEMENT.status',
    //           operator: 'CONTAIN',
    //           values: ['SUSPENDED'],
    //         },
    //       ],
    //       payload: {
    //         field: {
    //           status: 'ADS_PLACEMENT.status',
    //         },
    //         static: {
    //           status: 'RECOVERING',
    //         },
    //       },
    //     },
    //     // {
    //     //   name: 'End it',
    //     //   icon: 'closeRounded',
    //     //   condition: [
    //     //     {
    //     //       field: 'ADS_PLACEMENT.status',
    //     //       operator: 'CONTAIN',
    //     //       values: ['SCHEDULED', 'ONGOING', 'PAUSED', 'SUSPENDED'],
    //     //     },
    //     //   ],
    //     //   payload: {
    //     //     field: {
    //     //       status: 'ADS_PLACEMENT.status',
    //     //     },
    //     //     static: {
    //     //       status: 'ENDED',
    //     //     },
    //     //   },
    //     // },
    //   ],
    // },
    // script_status: {
    //   title: 'Script status',
    //   propertyType: 'attribute',
    //   cellFormat: 'status',
    //   valueGetter: {
    //     label: 'SCRIPT.script_status',
    //     status: 'SCRIPT.script_status',
    //   },
    //   menu: getActionMenu('script_status'),
    // },

    // ============= METRIC =============
    // condition: {
    //   title: 'Condition',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   valueGetter: {
    //     value: 'condition',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
    action: {
      title: 'Action',
      propertyType: 'metric',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.action_name',
      valueGetter: {
        value: 'SCRIPT_LOG.action_name',
      },
    },
    last_action_time: {
      title: 'Last action time',
      propertyType: 'metric',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.created_at',
      valueGetter: {
        value: 'SCRIPT_LOG.created_at',
      },
    },
    // action_count: {
    //   title: 'Action count',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   valueGetter: {
    //     value: 'action_count',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
    message: {
      title: 'Message',
      propertyType: 'metric',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SCRIPT_LOG.message',
      valueGetter: {
        value: 'SCRIPT_LOG.message',
      },
    },
    // actual_condition_value: {
    //   title: 'Actual condition value',
    //   propertyType: 'metric',
    //   cellFormat: 'text',
    //   valueGetter: {
    //     value: 'actual_condition_value',
    //   },
    //   menu: getActionMenu('_metric'),
    // },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
};
