export const demoData = {
  headers: [
    'ADTYPE.ads_type',
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_OBJECT.id',
    'ADS_CALENDAR.id',
    'ADS_CALENDAR.ADS_OBJECT.id',
    'PRODUCT.id',
    'COUNTRY.country_name',
    'MARKETPLACE.marketplace_name',
    'STOREFRONT.name',
    'STOREFRONT.storefront_sid',
    'ADTOOL.ads_tool_name',
    'ADS_CAMPAIGN.name',
    'ADS_CAMPAIGN.campaign_code',
    'ADS_OBJECT.name',
    'PRODUCT.product_sid',
    'ADS_OBJECT.sc_identify_code',
    'ADS_OBJECT.general_tag',
    'ADS_OBJECT.note',
    'ADS_CAMPAIGN.status',
    'ADS_CAMPAIGN.tooltips',
    'ADS_CAMPAIGN.daily_budget',
    'STOREFRONT.currency',
    'ADS_CAMPAIGN.total_budget',
    'ADS_OBJECT.status',
    'ADS_OBJECT.tooltips',
    'ADS_OBJECT.total_budget',
    'ADS_OBJECT.daily_budget',
    'ADS_CALENDAR.ADS_OBJECT.timeline_from',
    'ADS_CALENDAR.ADS_OBJECT.timeline_to',
    'ADS_OBJECT.base_bidding_price',
    'impression',
    'click',
    'cost',
    'ads_item_sold',
    'direct_item_sold',
    'ads_gmv',
    'direct_gmv',
    'ads_order',
    'direct_order',
    'roas',
    'cir',
    'cpc',
    'ctr',
    'cr',
    'stock',
    'discount',
    'direct_roas',
    'cpi',
    'direct_cr',
    'direct_cpi',
    'direct_cir',
    'PRODUCT.price_postsub',
  ],
  primaryKeys: [
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'MARKETPLACE.marketplace_code',
    'COUNTRY.country_code',
    'STOREFRONT.id',
    'ADS_CAMPAIGN.id',
    'ADS_OBJECT.id',
    'PRODUCT.id',
  ],
  rows: [
    [
      'SEARCH_ADS',
      'PH',
      'LAZADA',
      4290,
      'SHP_PA',
      null,
      1,
      null,
      48611,
      4261491152574,
      'Philippines',
      'Lazada',
      'LEGO® Official Store',
      153532993,
      'Product search ads',
      null,
      null,
      'LEGO® Friends 41677 Forest Waterfall (93 Pieces) Building Toys Toys For Kids Girls Toy Learning',
      10908046946,
      7970596,
      'priority',
      'increase budget note',
      null,
      null,
      null,
      'PHP',
      null,
      'ENDED',
      null,
      0,
      0,
      '1970-01-01 00:00:00',
      '2021-07-25 23:59:59',
      null,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      0,
      63,
      0,
      0,
      0,
      0,
      0,
      0,
      599,
    ],
  ],
};
