import * as React from 'react';
import Icon from '@ep/insight-ui/icons/Icon';
import { Box, Typography, Dialog } from '@material-ui/core';
import { DialogMessage } from './dialog-message';

export const UserStatusEditor = () => {
  const [open, setOpen] = React.useState(false);
  const [action, setAction] = React.useState('');
  const handleClose = () => {
    setOpen(false);
  };
  const statusData = [
    {
      type: 'active',
      label: 'Activate',
      cursor: 'default',
      click: () => null,
    },
    {
      type: 'errorStatus',
      label: 'Deactivate',
      cursor: 'pointer',
      click: () => {
        setOpen(true), setAction('deactivate');
      },
    },
    {
      type: 'remove',
      label: 'Remove access',
      cursor: 'pointer',
      click: () => {
        setOpen(true), setAction('remove');
      },
    },
  ];
  return (
    <>
      <Box style={{ width: '200px' }}>
        {statusData.map((val) => {
          return (
            <Box
              key={val.type}
              mb={5}
              display="flex"
              alignItems="center"
              style={{ marginTop: '8px', cursor: val.cursor }}
              onClick={val.click}
            >
              <Icon type={val.type} size="8px" colorIcon="#333" style={{ height: '8px' }} />
              <Typography variant="body1" style={{ marginLeft: '0.5em' }}>
                {val.label}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogMessage data={action} onClose={handleClose} />
      </Dialog>
    </>
  );
};
