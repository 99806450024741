import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../list-control/type';
import { OptionSelectChart } from './chart-type';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';

export const optionsColumnColor = [
  '#204d77',
  '#f17d40',
  '#338ed8',
  '#3cbb93',
  '#e4553e',
  '#f0ad4e',
  '#5bc0de',
  '#d4dded',
  '#FB2300',
  '#303F52',
  '#344E73',
  '#2C6BC4',
  '#77AEFB',
  '#7E81C6',
  '#103E86',
  '#A91708',
  '#E17C00',
  '#7A8577',
  '#0074AF',
];
export const optionsColumnColor2 = [

    '#D7E6F4', '#FCDFCF',
    '#D4E7F7', '#D8F3EA',
    '#F9D8D2', '#FBE9D0',
    '#D5EFF6', '#DBE3F0',
    '#FFD3CC', '#DFE5EC',
    '#DCE4EF', '#D5E3F6',
    '#CDE1FE', '#DCDCEF',
    '#D1E1FA', '#FDD3CE',
    '#FFE8CC', '#E5E7E4',
    '#CCEEFF'
];

export const optionsChart: OptionSelectChart[] = [
  {
    label: 'Column',
    labelType: 'STACKING',
    value: 'col',
    icon: 'columnChart',
  },
  {
    label: 'Pie',
    labelType: 'Pie Types',
    value: 'pie',
    icon: 'pieChart',
  },
  {
    label: 'Lines',
    labelType: 'Lines Types',
    value: 'lines',
    icon: 'linesChart',
  },
  {
    label: 'Comparasion',
    labelType: 'Comparasion Types',
    value: 'comparasion',
    icon: 'turnIntoGroup',
  },
];
export const optionsType: OptionSelectType[] = [
  {
    label: 'Default',
    value: 'def',
  },
  {
    label: '100%',
    value: '100',
  },
];
export const optionsHeading: Array<Array<DropdownMenuDataType>> = [
  [
    {
      title: 'Plain text',
      icon: 'plainText',
      colorTitle: colors.text.default,
      onClick: () => undefined,
    },
    {
      title: 'Heading 1',
      icon: 'h1',
      colorTitle: colors.text.default,
      onClick: () => undefined,
    },
    {
      title: 'Heading 2',
      icon: 'h2',
      colorTitle: colors.text.default,
      onClick: () => undefined,
    },
    {
      title: 'Heading 3',
      icon: 'h3',
      colorTitle: colors.text.default,
      onClick: () => undefined,
    },
  ],
];
export const optionsColor: Array<Array<DropdownMenuDataType>> = [
  [
    {
      title: 'Default',
      icon: 'colorText',
      onClick: () => undefined,
      colorTitle: colors.text.default,
      colorStartIcon: colors.text.default,
    },
    {
      title: 'Grey',
      icon: 'colorText',
      onClick: () => undefined,
      colorTitle: colors.text.default,
      colorStartIcon: colors.text.subdued,
    },
    {
      title: 'Red',
      icon: 'colorText',
      onClick: () => undefined,
      colorTitle: colors.text.default,
      colorStartIcon: colors.text.critical,
    },
    {
      title: 'item',
      icon: 'colorText',
      onClick: () => undefined,
      colorTitle: colors.text.default,
      colorStartIcon: colors.text.warning,
    },
  ],
];

export const optionsCohorts = [
  {
    label: 'Disable',
    value: false,
  },
  {
    label: 'Enable',
    value: true,
  },
];

export const optionsGroupBy = [
  {
    label: 'Metric',
    value: 'metric',
  },
  {
    label: 'Dimension',
    value: 'dimension',
  },
];

export const optionsDimension = [
  {
    label: 'Country',
    value: 'country_name',
  },
  {
    label: 'Marketplace',
    value: 'marketplace_name',
  },
  {
    label: 'Storefront',
    value: 'name',
  },
];

export const sectionDisplay = [
  {
    label: 'Single',
    value: 'single',
  },
  {
    label: 'Multiple',
    value: 'multiple',
  },
];

export const columnStacked = [
  {
    label: 'True',
    value: true,
  },
  {
    label: 'False',
    value: false,
  },
];

export const optionsHiddenButton = [
  {
    label: 'View',
    value: 'view',
  },
  {
    label: 'Filter',
    value: 'filter',
  },
  {
    label: 'Sort',
    value: 'sort',
  },
  {
    label: 'Special button',
    value: 'special_button',
  },
  {
    label: 'Edot',
    value: 'settingMenu',
  },
  {
    label: 'Calendar',
    value: 'timeline',
  },
  {
    label: 'Major Button',
    value: 'majorButton',
  },
];

export enum SortValue {
  ALPHABET = 'ALPHABET',
  DEFAULT = 'DEFAULT',
}

export const sortOptions = [
  {
    label: 'Default',
    value: SortValue.DEFAULT,
  },
  {
    label: 'Alphabet',
    value: SortValue.ALPHABET,
  },
];

export const optionsDisplayAllBlock = [
  {
    label: 'Enable',
    value: true,
  },
  {
    label: 'Disable',
    value: false,
  },
];
