import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import clsx from 'clsx';
import ButtonSearch from './button-search';
import ButtonCreateCampaign from './button-createCampaign';
import ButtonFilter from './button-filter';
import ButtonOption from './button-option-2';
import ButtonSort from './button-sort';
import DropdownView from './dropdown-view';
import { useAlignPopover } from './hooks';
import { DropdownMenuDataType } from '../../list-control/menu-list/menu-list';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

/**
 * ff.optimize_dropdown_view:start
 */
import NewDropdownView from './new-dropdown-view';
/**
 * ff.optimize_dropdown_view:end
 */

import SpecialButtonFilters from './special-button-filters';

/**
 * ff.expose_property_etable:start
 */
import SpecialButtonProperties from './special-button-properties';
/**
 * ff.expose_property_etable:end
 */

import UpdatedAtSection from './updated-at-section';
import ButtonAction from './button-action';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import {
  SHORTCUT_ACTIONS,
  WIDTH_TO_SHOW_EXTERNAL,
  WIDTH_TO_SHOW_SHORTCUT,
} from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    ...(ff.logic_remove_badge
      ? {
          '& .dropdown': {
            position: 'relative',
          },
        }
      : {}),
  },
  selectBox: {
    display: 'inline-flex',
    width: 'fit-content',
    '& .eip1-MuiSelect-select': {
      minWidth: '30px',
      borderRadius: '4px',
    },
  },
  selectFormControl: {},
  groupButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    overflowX: 'hidden',
  },
  specialGroupButtons: {
    margin: '0 8px',
    display: 'flex',
    flexWrap: 'nowrap',
    gap: '8px',
    overflowX: 'auto',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
    maxWidth: 'calc(100% - 32px)',
  },
}));

type Props = {
  dataMenu?: DropdownMenuDataType[];
  excludedViewBy?: any[];
  hasDimensionViewBy?: boolean;
};

const TableAction = ({ dataMenu, excludedViewBy = [], hasDimensionViewBy = false }: Props) => {
  const classes = useStyles();
  const [container, setContainer] = React.useState<HTMLDivElement>(null);
  const { marginLeft } = useAlignPopover(container);
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const hidden = backboneContext.getConfig('system.hiddenComponents', []);
  const shortcutActions = backboneContext.getConfig(SHORTCUT_ACTIONS, null);
  const widthToShowShortcut = backboneContext.getConfig('system.widthToShowShortcut', WIDTH_TO_SHOW_SHORTCUT);
  const widthToShowExternal = backboneContext.getConfig('system.widthToShowExternal', WIDTH_TO_SHOW_EXTERNAL);

  const { containerClass, containerWidth } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const boxRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!boxRef.current) return;
    setContainer(boxRef.current);
  }, [boxRef.current]);

  return (
    <div className={classes.container} ref={boxRef}>
      <Grid className={classes.leftSide}>
        {!hidden.includes('view') && <NewDropdownView />}
        {!hidden.includes('reload_button') ? (
          <MonitorContainer component={UpdatedAtSection} mId="refresh-btn" mLabel="refresh-btn" />
        ) : null}
      </Grid>
      <Grid className={classes.groupButtons}>
        <MonitorContainer
          component={Box}
          mr={2}
          className={clsx(containerClass, classes.specialGroupButtons)}
          mId="filter"
          mLabel={'filter'}
        >
          <React.Fragment>
            <SpecialButtonFilters
              containerClass={containerClass}
              isHidden={(containerWidth && containerWidth <= widthToShowExternal) || hidden.includes('special_button')}
            />
            {(containerWidth > widthToShowExternal || !containerWidth) && !hidden.includes('special_metric_button') && (
              <SpecialButtonProperties containerClass={containerClass} />
            )}
          </React.Fragment>
        </MonitorContainer>
        {!hidden.includes('filter') &&
          (containerWidth > widthToShowShortcut || !containerWidth) &&
          shortcutActions == null && (
            <MonitorContainer component={Box} mr={2} mId="advanced-filter" mLabel="advanced-filter">
              <ButtonFilter marginLeft={marginLeft} />
            </MonitorContainer>
          )}
        {!hidden.includes('sort') &&
          (containerWidth > widthToShowShortcut || !containerWidth) &&
          shortcutActions == null && (
            <MonitorContainer component={Box} mr={2} mId="advanced-sort" mLabel="advanced-sort">
              <ButtonSort marginLeft={marginLeft} />
            </MonitorContainer>
          )}
        {shortcutActions && (containerWidth > widthToShowShortcut || !containerWidth) ? (
          <ButtonOption
            dataMenu={dataMenu}
            excludedViewBy={excludedViewBy}
            hasDimensionViewBy={hasDimensionViewBy}
            isShortcut
          />
        ) : null}
        {!hidden.includes('search') && !isMobileView && (
          <MonitorContainer component={Box} mr={2} mId="search" mLabel="search">
            <ButtonSearch />
          </MonitorContainer>
        )}
        {!hidden.includes('majorButton') && (
          <MonitorContainer component={Box} mId="main-button-legacy" mLabel="main-button-legacy" mr={2}>
            <ButtonCreateCampaign />
          </MonitorContainer>
        )}
        {!hidden.includes('actionButton') && (
          <MonitorContainer component="div" mId="main-button-legacy" mLabel="main-button-legacy">
            <ButtonAction backbone={backboneContext} />
          </MonitorContainer>
        )}
        {!hidden.includes('settingMenu') && (
          <MonitorContainer component="div" mId="section-edot" mLabel="Section edot">
            <ButtonOption dataMenu={dataMenu} excludedViewBy={excludedViewBy} hasDimensionViewBy={hasDimensionViewBy} />
          </MonitorContainer>
        )}
      </Grid>
    </div>
  );
};

export default TableAction;
