import * as React from 'react';
import { get, uniq } from 'lodash';
import moment from 'moment';

import { TableCompactFactoryContext } from '@ep/insight-ui/elements/table/compact-factory';
import { makeTable } from '@ep/insight-ui/elements/table/table-container';
import { DATA_STATUS_TABLE } from '@ep/insight-ui/system/block/etable/table-config/data-status-compact-table';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';

export const useDataStatusTable = (props, backboneContext) => {
  // For campaign management
  const dataStatus = get(props, ['value', 'data_status'], null);
  const errorDataStatus = Object.entries(get(props, ['data', 'data_status'], {}));
  const errorDataStatusValue = Object.values(get(props, ['data', 'data_status'], {}));
  const lastErrorUpdatedAt = errorDataStatusValue.reduce((a, b) => {
    return a < b['ads_ops_buffer_ads.updated_at'] ? b['ads_ops_buffer_ads.updated_at'] : a;
  }, moment(0).format('YYYY-MM-DD HH:mm:ss'));

  // For campaign detail
  const valueField = get(backboneContext, ['config', 'mapping', props.field, 'valueGetter', 'value'], '');
  const valueSyncStatus = get(props, ['data', 'data_status', valueField, 'status'], null);
  const valueSyncUpdatedAt = get(props, ['data', 'data_status', valueField, 'updated_at'], null);
  const valueSyncErrorMessage = get(props, ['data', 'data_status', valueField, 'error_message'], '');

  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const { onToast } = useToast();

  const getDataStatusTableConfig = (etableData) => {
    const selectedRows = backboneContext.getSelectedRows();
    const uniqData = uniq(
      [...etableData].concat(...selectedRows.map((el) => Object.values(get(el, ['data_status'], {})))),
    );

    const config = {
      configuration: DATA_STATUS_TABLE,
      addons: {
        'datasource.apiRequest.getTableData': async (_, __, backbone) => {
          const searchTerm = get(backbone, ['config', 'search'], '');
          return {
            code: 200,
            data: {
              headers: Object.keys(uniqData[0]),
              rows: uniqData.filter((el) =>
                el['ads_ops_buffer_ads.field_name'].toLowerCase().includes(searchTerm.toLowerCase()),
              ),
              masterData: {},
            },
            message: 'OK',
            success: true,
          };
        },
        'footer.buttons': (backbone) => {
          return [
            {
              id: 'retry',
              variant: 'contained',
              color: 'primary',
              async onClick() {
                const selectedRows = backbone.getSelectedRows();

                let data = [];
                if (selectedRows.length) {
                  data = [...selectedRows];
                } else {
                  const gridApi = backbone.getGridApi();
                  gridApi.forEachNode((node) => {
                    data = data.concat(node.data);
                  });
                }

                if (data.length) {
                  const payload = {
                    data: data.map((el) => ({
                      marketplaceCode: el['ads_ops_buffer_ads.marketplace_code'],
                      model: el['ads_ops_buffer_ads.model'],
                      modelId: el['ads_ops_buffer_ads.model_id'],
                      fieldName: el['ads_ops_buffer_ads.field_name'],
                    })),
                    requestId: 'string',
                    transactionPackaging: true,
                    validateOnly: true,
                  };

                  try {
                    const res = await request.post(
                      EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-retry-ads-attribute',
                      payload,
                    );

                    const failedElement = get(res, ['data'], []).filter((el) => !el.success);

                    if (failedElement.length) {
                      failedElement.forEach((el) => {
                        if (!el.success) {
                          onToast({
                            title: 'Failed',
                            messages: el.message,
                            variant: 'error',
                          });
                        }
                      });
                    }

                    const successElement = get(res, ['data'], []).filter((el) => el.success);

                    if (successElement.length) {
                      onToast({
                        title: `Update ids ${successElement.map((el) => el?.data.modelId).join(', ')} successfully!`,
                        variant: 'success',
                      });
                      // Close the compact table
                      displayTable({
                        tableConfig: getDataStatusTableConfig(errorDataStatusValue),
                        visible: false,
                      });
                      backboneContext.updateSelectedRows([]);
                      backboneContext.reloadData('table');
                    }
                  } catch (e) {
                    onToast({
                      title: 'Failed',
                      messages: e.message,
                      variant: 'error',
                    });
                  }
                }
              },
              title: 'Retry',
            },
          ];
        },
      },
    };
    return { config };
  };

  const displayDataStatusTableCompact = (anchorEl) => {
    const tableRender = makeTable(getDataStatusTableConfig(errorDataStatusValue));
    displayTable({
      anchorEl,
      tableConfig: getDataStatusTableConfig(errorDataStatusValue),
      tableEl: tableRender,
      visible: true,
      onClosed: () => undefined,
    });
  };

  const enhanceRetryAction = (cellAction) => {
    if (dataStatus == 2) {
      return [
        ...cellAction,
        {
          name: 'Retry sync',
          icon: 'reload',
          id: 'retry_fail_sync',
          onSubmit({ target }) {
            if (target) {
              displayDataStatusTableCompact(target);
            }
          },
        },
      ];
    }

    return cellAction;
  };

  return {
    dataStatus,
    errorDataStatus,
    lastErrorUpdatedAt,
    valueSyncStatus,
    valueSyncUpdatedAt,
    valueSyncErrorMessage,
    enhanceRetryAction,
  };
};
