import { IData, IOption } from '@ep/insight-ui/chartlib/type';
import { Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import * as _ from 'lodash';
import { Line } from 'react-chartjs-2';
import { MetricCard } from '../../metric-card/metric-card';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import moment from 'moment';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { tableTooltip } from '@ep/insight-ui/chartlib/helper';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  containerChart: {
    padding: '0 5%',
  },
  tableTooltip: tableTooltip,
}));

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector('div');

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.style.background = '#fff';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = '#204d77';
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.boxShadow = '0px 6px 12px rgb(140 152 164 / 25%)';
    tooltipEl.style.padding = '6px 8px';

    const table = document.createElement('table');
    table.style.margin = '0px';

    tooltipEl.appendChild(table);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, options) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const { period, classes } = options;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const tableHead = document.createElement('thead');
    tableHead.style.textAlign = 'left';
    tableHead.style.marginBottom = '12px';
    tableHead.style.fontSize = '14px';

    titleLines.forEach((title) => {
      const tr = document.createElement('tr');
      tr.className = 'headerRow';
      tr.style.borderWidth = 0;

      const th = document.createElement('th');
      th.style.borderWidth = 0;
      const text =
        period === 'hourly'
          ? document.createTextNode(moment(title).format('ddd, DD MMM YYYY HH:mm'))
          : document.createTextNode(moment(title).format('ddd, DD MMM YYYY'));

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const tr: HTMLTableRowElement = document.createElement('tr');
      tr.className = 'legendRow';
      const label: string = get(body, [0, 'metric'], '');
      const value: number = get(body, [0, 'value'], 0);
      const backgroundColor: string = get(tooltip, ['labelColors', i, 'backgroundColor'], '');
      const currency: string = get(body, [0, 'currency'], '') || '';

      const tdHTML = `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${backgroundColor}; border-color: ${backgroundColor};"></span>
        <div class="content">
        <div class="label">${label}</div>
        <div class="value">${`${formatCurrencyNumber(value, currency)} ${currency}`}</div>
        </div>
      </td>
      `;
      tr.innerHTML = tdHTML;
      tableBody.appendChild(tr);
    });

    const tableRoot = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    tableRoot.className = `${classes.tableTooltip}`;
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const currentColumnLeft = chart.canvas?.parentNode?.parentNode?.parentNode?.parentNode?.offsetLeft || 0;

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = currentColumnLeft + positionX + tooltip.caretX + 'px';
  tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = 0;
};
export interface IValueChartFormat {
  data: Array<number>;
}
const LineChartFormat = (props: IPropsFormat<IValueChartFormat>) => {
  const classes = useStyles();

  const ref = React.useRef(null);

  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const dataValue = _.get(value, 'data', []);
  const labels = _.get(value, 'labels', []);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const height = _.get(props, 'value.height', '');

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const periodBackbone = backboneContext.getConfig('groupPeriod', '');

  const data = React.useMemo(
    () => ({
      labels: labels,
      datasets: [
        {
          label: '',
          data: dataValue,
          backgroundColor: '#204D77',
          borderColor: '#204D77',
          fill: false,
          pointRadius: 0,
          pointHoverRadius: 3,
          pointHitRadius: 15,
          pointBorderWidth: 2,
          pointStyle: 'rectRounded',
          lineTension: 0.4,
          borderWidth: 2,
        },
      ],
    }),
    [value.data],
  );

  const options = React.useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      scales: {
        yAxes: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 14,
            color: '#253746',
          },
        },
        xAxes: {
          display: false,
          grid: {
            display: true,
            drawBorder: false,
          },
          ticks: {
            align: 'start',
            fontSize: 14,
            color: '#253746',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) => externalTooltipHandler(context, { period: periodBackbone, classes }),
          callbacks: {
            label(data) {
              const metricDetail =
                get(props, 'data.resourceMetric', []).find(
                  (el) => el.label_raw === props.data.metric || el.value === props.data.metric,
                ) || {};
              const currency = metricDetail.prefix_value === '$' ? props.data.currency : metricDetail.prefix_value;
              return {
                metric: props.data.metric,
                value: data.raw,
                currency: currency || '',
              };
            },
          },
        },
      },
    }),
    [periodBackbone],
  );

  // Handle overlap
  React.useEffect(() => {
    if (ref.current) {
      const onMouseEnter = () => {
        ref.current.parentNode.parentNode.parentNode.style.zIndex = 9999;
      };
      const onMouseLeave = () => {
        ref.current.parentNode.parentNode.parentNode.style.zIndex = null;
      };
      ref.current.parentNode.parentNode.addEventListener('mouseenter', onMouseEnter);
      ref.current.parentNode.parentNode.addEventListener('mouseleave', onMouseLeave);

      return () => {
        ref.current.parentNode.parentNode.removeEventListener('mouseenter', onMouseEnter);
        ref.current.parentNode.parentNode.removeEventListener('mouseleave', onMouseLeave);
      };
    }
  }, [ref]);
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems={'center'} ref={ref}>
        <Grid item xs={12} className={classes.containerChart}>
          <Line height={height ? height : '100%'} data={data} options={options} />
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};

export default LineChartFormat;
