/**
 * ff.custom_filter_value_addon:start
 */
import { EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { compare } from 'fast-json-patch';
import produce from 'immer';
import { cloneDeep, flatten, get, head, isEqual, last, set, uniq } from 'lodash';
import moment from 'moment';
import React from 'react';
import { UaWatchlist } from '../../util/uamonit/uamonit-hooks';
import dataRequest from '../data-source/common';
/**
 * ff.custom_filter_value_addon:end
 */
import { getAPIRequest } from './api-request';
import { callbackGenerate } from './callback-generator';
import { DEFAULT_OPTIONS } from './mockup';
import { createDatasource } from './table-datasource';
import { StatusType } from './workflow-type';

const log = useLog('lib:table-backbone');

/**
 * Table backbone controls:
 * System function:
 *  # columns mapping
 *    # edot
 *    # edot actions
 *  # api request: filtering, sorting, grouping, aggregation, searching
 *  # pagination
 *  # inline edit
 *    # muliple single edit
 *    # mass editing
 *    # predefined option and validation
 *  # filter
 *  # sort
 *  # group by
 *    # aggregation
 *  # configurable components
 *  # view selection
 *  # default time range: period, custom date range
 *  # user personalization regions
 *  # localization
 *
 * Personalization:
 *  # hide/show columns
 *  # filter
 *  # sort
 *  # column orderings
 *  # group by
 *    # aggregation
 *  # view managing
 *  # time range
 *
 * Experience:
 *  # Pinthis
 *
 * ### TODO
 * * Let start creating test for:
 * * grouping and aggregation
 * * Edot actions for open new link with URL component from row
 * * Thinking about mocking the backbone
 */

const DEFAULT_CONFIG = {
  title: 'Title',
  view: { id: 'all', name: 'All' },
  views: [
    {
      id: 'all',
      name: 'All',
    },
  ],
  tableType: '',
  dateRange: {
    dateFrom: moment().subtract(7, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().format(EIP_CONSTANT.DATE_FORMAT),
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: true },
    { type: 'attribute', label: 'Attribute', allowConfig: true },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  currency: 'USD',
  dimension: [],
  metric: [],
  attribute: [],
  pinnedColumn: [],
  columnWidth: [],
  columnOrder: [],
  sort: [],
  filter: [],
  search: '',
  endpoint: {},
  mapping: {},
  groupBy: {},
  system: {},
  hiddenFilter: {},
  hiddenColumns: ['ad_type'],
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  disabledComponents: {},
};

const DEFAULT_PAGINATION = {
  limit: 10,
  page: 1,
  total: 0,
  ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
};

export const useTableBackbone = (
  bbConfig = {
    apiRequest: {},
    configuration: {},
    callback: {},
    addons: {},
  },
  changeConfiguration = (config: Record<string, any>) => undefined,
) => {
  const [config, setConfig] = React.useState({ ...DEFAULT_CONFIG, ...bbConfig.configuration });
  const [options, setOptions] = React.useState(DEFAULT_OPTIONS);
  const [status, setStatus] = React.useState({
    table: '',
    ...(ff.separate_table_data_loading ? { pagination: '' } : {}),
    dimension: '',
    metric: '',
    attribute: '',
  });
  const [visibility, setVisibility] = React.useState({
    tableSetting: false,
    filter: false,
    sort: false,
  });
  const [headers, setHeaders] = React.useState([]);
  const [pagination, setPagination] = React.useState(DEFAULT_PAGINATION);

  const ref = React.useRef({
    mainButton: {},
    headerAction: {},
    cellFormat: {},
    cellEditor: {},
    notification: null,
    tempStorage: {},
    backboneInstance: null,
  });

  const apiRequest = React.useMemo(() => {
    const defaultRequest = getAPIRequest(config.endpoint);
    return { ...defaultRequest, ...bbConfig.apiRequest };
  }, []);

  const uawl = React.useContext(UaWatchlist);

  const fastRef = React.useRef<Record<string, any>>({ config });

  const tableRef = React.useRef<{
    getColumnFields;
    apiRequest;
    config;
    callback: {
      clickMainButton?: (...args: any[]) => void;
      onRowUpdate?: (...args: any) => void;
    };
    gridApi;
    columnApi;
    pagination;
    updateTotalResult;
    updatePaginationTraceId;
    getAvailableFilterColumns;
    getAvailableSortColumns;
    datasource;
    selectedRows: any[];
    addons: Record<string, any>;
    addon: (string: any, defaultFun: (...args: any[]) => any) => typeof defaultFun;
  }>({
    getColumnFields: null,
    apiRequest: null,
    config: { ...DEFAULT_CONFIG, ...bbConfig.configuration },
    callback: { ...bbConfig.callback, ...callbackGenerate(config) },
    gridApi: null,
    columnApi: null,
    pagination: DEFAULT_PAGINATION,
    updateTotalResult: null,
    updatePaginationTraceId: null,
    getAvailableFilterColumns: null,
    getAvailableSortColumns: null,
    datasource: null,
    selectedRows: [],
    addons: bbConfig.addons,
    addon: null,
  });

  const {
    updateCellValue,
    getFilterValueData,
    getDimensionOptions,
    getSuggestBiddingPrice,
    getLandingPageOptions,
    getGenders,
    getAges,
    getAudienceLocations,
    getInterestCategories,
    deleteDimension,
  } = apiRequest;

  const init = () => {
    initPropertyOptions();
    resetColumns();
  };

  const initPropertyOptions = (autoSelectAll = false) => {
    const columnOrder = get(bbConfig.configuration, 'columnOrder', []);
    const mapping = get(bbConfig.configuration, 'mapping', {});
    const hiddenColumns: any[] = get(config, 'hiddenColumns', []);
    const opt = {};
    const selectedViewId = get(config, ['view', 'id']);
    const view = config.views.find((i) => i.id === selectedViewId) || config.views[0];
    const excludeColumns = flatten(Object.values(get(view, 'excludeProperty', {}))).concat(hiddenColumns);

    for (const [colId, colVal] of Object.entries(mapping)) {
      const colValue: any = colVal;
      if (colValue.propertyType && !excludeColumns.includes(colId as any)) {
        opt[colValue.propertyType] = (opt[colValue.propertyType] || []).concat({
          id: colId,
          name: colValue.title,
          filterField: colValue.filterField,
          sortField: colValue.sortField || colValue.filterField,
          dataType: colValue.dataType || 'string',
          disable: false,
        });
      }
    }

    for (const [key, val] of Object.entries(opt)) {
      opt[key] = sortItemsByField(val, columnOrder, 'id');
    }

    updateConfig({ view });
    setOptions((options) => ({
      ...options,
      ...opt,
    }));

    if (config.dimension.length === 0) {
      const selectedColumns = config.settingType.reduce((acc, val) => {
        const cols = get(opt, val.type, []).map((c) => c.id);
        return {
          ...acc,
          [val.type]: cols,
        };
      }, {});
      updateConfig({
        ...config,
        ...selectedColumns,
      });
    }

    // FIXME: Temporary work around for switch view "campaign detail" to auto select all columns
    if (autoSelectAll) {
      const selectedCols = ['dimension', 'attribute', 'metric'].reduce((acc, colType) => {
        return {
          ...acc,
          [colType]: (opt[colType] || []).map((i) => i.id),
        };
      }, {});
      updateConfig(selectedCols);
    }
  };

  const getSortFilterFieldsByType = (type: string) => {
    const filterFields = getFilterFields(config.filter);
    const sortFields = config.sort.map((i) => i.field);
    const addFields = [...filterFields, ...sortFields];
    return Object.entries(config.mapping).reduce((acc, [k, v]) => {
      if (addFields.includes(k) && v['propertyType'] === type) {
        return [...acc, k];
      }
      return acc;
    }, []);
  };

  const getColumnFields = (type) => {
    const hiddenFields = get(config, ['requestHiddenField', type], []);
    const ignoreFields = get(config, ['requestIgnoreField', type], []);
    let fields = [...hiddenFields];
    let propertyIds = [];

    switch (type) {
      case 'dimension': {
        propertyIds = get(config, 'dimension', []);
        break;
      }
      case 'attribute': {
        fields = [...fields, ...get(config, 'primaryKeys', [])];
        propertyIds = [
          ...get(config, 'dimension', []),
          ...get(config, 'attribute', []),
          ...getSortFilterFieldsByType('attribute'),
        ];
        break;
      }
      case 'metric': {
        propertyIds = [...get(config, 'metric', []), ...getSortFilterFieldsByType('metric')];
        break;
      }
    }

    propertyIds
      // FIXME: more flexible way to ignore columns eg. column_name.valueGetter.will_ignore_field
      .forEach((id) => {
        let getterFields = Object.values(get(config, ['mapping', id, 'valueGetter'], {}));
        if (type === 'dimension') {
          getterFields = getterFields.map((i: string) => head(i.split('.')));
        }
        fields = fields.concat(getterFields);
      });
    return uniq(fields.filter((i) => !ignoreFields.includes(i)));
  };

  const loadTableData = (route = []) => {
    if (tableRef.current.gridApi) {
      tableRef.current.gridApi.refreshServerSideStore({ route });
    }
  };

  let loadPagination;
  if (ff.separate_table_data_loading) {
    loadPagination = async () => {
      const params = {
        previousTraceId: tableRef.current.pagination.traceId,
        pagination: {
          page: tableRef.current.pagination.page,
          limit: tableRef.current.pagination.limit,
        },
      };

      return new Promise(async (resolve, reject) => {
        const res = await apiRequest.getPaginationInfo(params);
        if (res.success) {
          const total = get(res, 'pagination.total', 0);
          tableRef.current.updateTotalResult(total);
        }

        resolve({
          success: true,
          data: res,
        });
      });
    };
  }

  const editorDataLoading = {
    StatusEditor: getDimensionOptions,
    BiddingPriceEditor: getSuggestBiddingPrice,
    LandingPageEditor: getLandingPageOptions,
    GenderEditor: getGenders,
    AgeEditor: getAges,
    LocationEditor: getAudienceLocations,
    InterestCategoryEditor: getInterestCategories,
  };

  const [reloadHandler, setReloadHandler] = React.useState({
    table: loadTableData,
    ...(ff.separate_table_data_loading ? { pagination: loadPagination } : {}),
  });

  const handleChangePagination = (value: { page: number; limit: number }) => {
    const newPagination = {
      ...pagination,
      ...value,
    };
    log('handlechangepagination', newPagination);
    if (pagination.limit !== newPagination.limit) {
      const newPage = Math.floor((pagination.page * pagination.limit) / newPagination.limit);
      newPagination.page = newPage;
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.limit));
    } else if (pagination.page != newPagination.page) {
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.page * newPagination.limit));
    }
    setPagination(newPagination);
  };

  const handleChangeVisibility = (name: string, isVisible: boolean) => {
    log('Change visibility', { name, isVisible });
    setVisibility({
      ...visibility,
      [name]: isVisible,
    });
  };

  const handleChangeStatus = (name: string, value: StatusType) => {
    if (ff.separate_table_data_loading) {
      setStatus((status) => ({
        ...status,
        [name]: value,
      }));
    } else {
      setStatus({
        ...status,
        [name]: value,
      });
    }
  };

  const updateConfig = (value: any) => {
    log('update config', value);
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries(value)) {
          draft[key] = val;
        }
      });
      changeConfiguration(newConfig);

      // FIXME: tableref.current.config is potentially out of date, some config will trigger query data. some would not
      // tableRef.current.config = newConfig;
      fastRef.current.config = newConfig;
      // console.log('UpdateConfig 1', newConfig);
      return newConfig;
    });
  };

  const updateSystemConfig = (value: any) => {
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries(value)) {
          set(draft, ['system', key], val);
        }
      });
      changeConfiguration(newConfig);
      tableRef.current.config = newConfig;
      // console.log('UpdateConfig 2', newConfig);
      return newConfig;
    });
  };

  const handleChangeOptionOrder = (name: string, ids: Array<number | string>) => {
    const newArr = sortItemsByField(get(options, name, []), ids, 'id');
    const newOptions = {
      ...options,
      [name]: newArr,
    };
    setOptions(newOptions);

    const colOrders = config.settingType.reduce((acc, val) => {
      const cols = get(newOptions, val.type, []).map((c) => c.id);
      return acc.concat(cols);
    }, []);
    updateConfig({ columnOrder: colOrders });

    if (tableRef.current.columnApi.moveColumns) {
      tableRef.current.columnApi.moveColumns(colOrders, 0);
    }
  };

  const handleChangeColumnOrder = (columnOrders: string[]) => {
    const newOptions = produce(options, (draft) => {
      config.settingType.forEach((i) => {
        draft[i.type] = sortItemsByField(get(options, [i.type], []), columnOrders, 'id');
      });
    });
    setOptions(newOptions);
    updateConfig({ columnOrder: columnOrders });
  };

  React.useEffect(() => {
    const newViewId = get(config, ['view', 'id']);
    const oldViewId = get(tableRef.current.config, ['view', 'id']);
    if (newViewId !== oldViewId) {
      initPropertyOptions(true);
      resetColumns();
    }
  }, [config.view]);

  React.useEffect(() => {
    resetColumns();
    if (tableRef.current.config.tableType === '' && tableRef.current.gridApi) {
      // tableRef.current.gridApi.getSelectedNodes().forEach(n => n.setSelected(false));
      tableRef.current.gridApi.deselectAll();
      tableRef.current.selectedRows = [];
    }
  }, [
    config.sort,
    config.filter,
    config.dimension,
    config.metric,
    config.attribute,
    config.groupBy,
    config.columnOrder,
    options.dimension,
    options.metric,
    options.attribute,
  ]);

  React.useEffect(() => {
    if (tableRef.current.gridApi && !isEqual(tableRef.current.config, config)) {
      console.info('table api model', tableRef.current.gridApi.getModel().getType());
      if (process.env.NODE_ENV === 'development') {
        log('diff', compare(tableRef.current.config, config));
      }
      log('table reload');
      if (tableRef.current.gridApi.getModel().getType() === 'serverSide') {
        tableRef.current.gridApi.paginationSetPageSize(tableRef.current.pagination.limit);
        tableRef.current.gridApi.refreshServerSideStore({ purge: true });
      } else if (tableRef.current.gridApi.getModel().getType() === 'clientSide') {
        tableRef.current.gridApi.refreshClientSideRowModel({ purge: true });
      }
    }
  }, [config.sort, config.filter, config.currency, config.search, config.dateRange, config.metric, config.groupBy]);

  const getFilterFields = (filter: TableBackbone.filter[]) => {
    if (filter.length === 0) return [];
    return filter.reduce((acc, i) => {
      if (i.subFilters) {
        return [...acc, ...getFilterFields(i.subFilters)];
      }
      return [...acc, i.field];
    }, []);
  };

  const getAvailableColumns = () => {
    const properties = ['dimension', 'attribute', 'metric'];
    return properties.reduce((acc, property) => {
      const cols = get(options, property, []).map((c) => ({ ...c, propertyType: property }));
      return [...acc, ...cols];
    }, []);
  };

  const getAvailableFilterColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.filterField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.filterField,
            dataType: c.dataType || 'string',
            propertyType: c.propertyType,
          },
        ];
      }
      return acc;
    }, []);
  };

  const getAvailableSortColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.sortField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.sortField,
            dataType: c.dataType || 'string',
            propertyType: c.propertyType,
          },
        ];
      }
      return acc;
    }, []);
  };

  const sortItemsByField = (items, orderedValues, field) => {
    const newItems = [...items];
    newItems.sort((a, b) => {
      const indexA = orderedValues.indexOf(a[field]);
      const indexB = orderedValues.indexOf(b[field]);
      if (indexA < indexB) {
        return -1;
      }
      if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
    return newItems;
  };

  const resetColumns = () => {
    const groupByColumns = get(config, 'groupBy.columns', []);
    const columns = config.settingType.reduce((acc, val) => {
      if (options[val.type]) {
        const selectedCols = get(config, [val.type], []);
        const cols = options[val.type]
          .filter((i) => selectedCols.includes(i.id))
          .map((c) => ({
            name: c.name,
            field: c.id,
            dataType: c.dataType,
            sortable: false,
            filterField: get(config, ['mapping', c.id, 'filterField'], ''),
            filterGetter: get(config, ['mapping', c.id, 'filterGetter']),
            cell: {
              format: getCellFormat(c.id),
              valueGetter: get(config, ['mapping', c.id, 'valueGetter']),
              staticValue: get(config, ['mapping', c.id, 'staticValue']),
              action: getCellActions(c.id),
            },
            action: groupByColumns.includes(c.id) ? getHeaderActions('groupBy') : getHeaderActions(c.id, val.type),
            aggFunc: get(config, ['groupBy', 'aggregations', c.id, 'func']),
          }));
        return acc.concat(cols);
      }
      return acc;
    }, []);
    log('reset columns', { groupByColumns, config, columns });
    setHeaders(columns);
  };

  const getHeaderActions = (columnField: string, columnType?: string) => {
    if (ref.current.headerAction[columnField]) {
      return ref.current.headerAction[columnField];
    } else {
      if (ref.current.headerAction[columnType]) {
        return ref.current.headerAction[columnType];
      } else {
        return ref.current.headerAction['default'];
      }
    }
  };

  const handleUpdateCell = async (params: any, idField: string) => {
    try {
      const session = uawl.currentSession();
      if (session) {
        session.addContext({ uaQueryParams: JSON.stringify(params), uaContext: JSON.stringify({ idField }) });
        session.ack('be:request');
        params._uaSessionId = session.id();
      }

      const res: any = await updateCellValue(params);

      const getUpdatedValues = (dimensionId) => {
        const newRowData = {};
        const updateFields = Object.keys(params.data[0]).filter((i) => i !== 'id');
        const useResponseValue = updateFields.includes('status');
        let data;
        if (useResponseValue) {
          const exist = res.data.find((i) => i.data.id == dimensionId);
          if (exist) {
            data = exist.data;
          }
        } else {
          data = params.data.find((i) => i.id == dimensionId);
        }
        if (data) {
          const dimensionName = idField.substring(0, idField.lastIndexOf('.'));
          updateFields.forEach((field) => {
            if (data[field] !== undefined) {
              newRowData[`${dimensionName}.${field}`] = data[field];
            }
          });
        }
        return newRowData;
      };

      const successIds = [];
      const errorItems = [];
      res.data.forEach((item, index) => {
        if (item.success) {
          successIds.push(item.data.id);
        } else {
          errorItems.push({
            id: params.data[index].id,
            messages: Object.values(item.error),
          });
        }
      });

      if (successIds.length > 0) {
        if (
          ['ADS_CAMPAIGN', 'ADS_OBJECT'].includes(params.dimension) &&
          Object.keys(params.data[0]).includes('status')
        ) {
          tableRef.current.gridApi.refreshServerSideStore();
        } else {
          tableRef.current.gridApi.forEachNode((rowNode) => {
            if (rowNode.data) {
              const dimensionId = (rowNode.data[idField] || '').toString();
              if (successIds.includes(dimensionId)) {
                rowNode.setData({ ...rowNode.data, ...getUpdatedValues(dimensionId) });
              }
            }
          });
        }
        notice(`Update ids ${successIds.join(', ')} successfully!`, [], true);
      }

      if (errorItems.length > 0) {
        errorItems.forEach((i) => {
          notice(`Update id "${i.id}" failed with the following errors`, i.messages, false);
        });
      }

      return res;
    } catch (err) {
      console.log('[UpdateCell] System error', err);
      return {
        success: false,
        message: err.message,
      };
    }
  };

  const updateSingleValue = async ({ value, row }: { value: any; row: any }, updateFieldMapping: any) => {
    const dataForUpdate = {};
    let dimension = '';
    for (const [key, field] of Object.entries<string>(updateFieldMapping)) {
      const newValue = value[key];
      const parts = field.split('.');
      const attributeField: string = last(parts);
      dataForUpdate[attributeField] = newValue;
      dimension = head(parts);
    }

    if (dimension) {
      const primaryKeys = get(config, 'primaryKeys', []);
      let idField = '';
      if (dimension === 'ADS_CALENDAR') {
        const field = updateFieldMapping['date_from'].substring(0, updateFieldMapping['date_from'].lastIndexOf('.'));
        idField = primaryKeys.find((k) => k.includes(field));
      } else {
        idField = primaryKeys.find((k) => k.includes(dimension));
      }

      const selectedRows = tableRef.current.gridApi.getSelectedRows();
      const ids = uniq(selectedRows.concat(row).map((r) => r[idField]));

      if (idField) {
        if (dimension === 'ADS_CALENDAR') {
          const prevTimelineFrom = moment(row[updateFieldMapping['date_from']], EIP_CONSTANT.DATE_FORMAT).format(
            EIP_CONSTANT.DATE_FORMAT,
          );
          if (prevTimelineFrom === dataForUpdate['timeline_from']) dataForUpdate['timeline_from'] = undefined;
          if (dataForUpdate['timeline_to']) {
            dataForUpdate['timeline_to'] = moment(dataForUpdate['timeline_to'], EIP_CONSTANT.DATE_FORMAT)
              .set({ hour: 23, minute: 59, second: 59 })
              .format(EIP_CONSTANT.DATETIME_FORMAT);
          }
        }
        const param = {
          data: ids.map((id) => ({ id, ...dataForUpdate })),
          dimension,
          marketplaceCode: row['MARKETPLACE.marketplace_code'],
        };
        log('onsubmit single value', { value, dataForUpdate, row, idField });
        const response = await handleUpdateCell(param, idField);
        return response;
      }
    }
  };

  const updateMultiValue = async ({ value, row }: { value: any; row: any }) => {
    const parts = get(value, ['value', 'field'], '').split('.');
    const dimension = head(parts);
    const updateField = last(parts);
    const primaryKeys = get(config, 'primaryKeys', []);
    const idField = primaryKeys.find((k) => k.includes(dimension));
    const data = get(value, ['value', 'data'], []).map((i) => ({
      id: i.id,
      [updateField as string]: i.value,
    }));
    const param = {
      data,
      dimension,
      marketplaceCode: row['MARKETPLACE.marketplace_code'],
    };
    log('onsubmit multi value', { value, param, row, idField });
    const response = await handleUpdateCell(param, idField);
    return response;
  };

  const handleSubmit = async (data: { value: any; row: any }, updateFieldMapping: any) => {
    // Detect updated field and new value
    const isUpdateMultiValue = get(data, ['value', 'value', 'isMultiValue'], false);
    if (isUpdateMultiValue) {
      const response = await updateMultiValue(data);
      return response;
    } else {
      const response = await updateSingleValue(data, updateFieldMapping);
      return response;
    }
  };

  const handleLocalUpdate = ({ value, row }: { value: any; row: any }, valueGetter: any) => {
    const primaryKeys = get(config, 'primaryKeys', []);
    const selectedRows = tableRef.current.gridApi.getSelectedRows().concat(row);

    const dataForUpdate = {};
    for (const [key, field] of Object.entries<string>(valueGetter)) {
      const oldValue = row[field];
      const newValue = value[key];
      if (oldValue !== newValue) {
        dataForUpdate[field] = newValue;
      }
    }

    tableRef.current.gridApi.forEachNode((rowNode) => {
      if (rowNode.data) {
        const exist = selectedRows.find((r) => {
          return primaryKeys.every((key) => r[key] === rowNode.data[key]);
        });
        if (exist) {
          rowNode.setData({ ...rowNode.data, ...dataForUpdate });
        }
      }
    });

    if (tableRef.current.callback.onRowUpdate) {
      const rowData = [];
      tableRef.current.gridApi.forEachNode((rowNode) => rowData.push(rowNode.data));
      tableRef.current.callback.onRowUpdate(rowData);
    }
  };

  const notice = (title = '', messages = [], isSuccess: boolean) => {
    if (ref.current.notification) {
      if (isSuccess) {
        ref.current.notification.success(title, messages);
      } else {
        ref.current.notification.error(title, messages);
      }
    }
  };

  const handleDeleteDimension = async ({ row }: { value: any; row: any }, dimension: string) => {
    const primaryKeys = get(config, 'primaryKeys', []);
    const idField = primaryKeys.find((k) => k.includes(dimension));
    if (idField) {
      const selectedRows = tableRef.current.gridApi.getSelectedRows();
      const ids: string[] = uniq(selectedRows.concat(row).map((r) => r[idField]));
      const params = {
        id: ids,
        dimension,
        marketplaceCode: row['MARKETPLACE.marketplace_code'],
      };

      try {
        const res: any = await deleteDimension(params);
        const successIds = [];
        const errorItems = [];
        res.data.forEach((item, index) => {
          if (item.success) {
            successIds.push(ids[index]);
          } else {
            errorItems.push({
              id: ids[index],
              messages: [item.message],
            });
          }
        });

        if (successIds.length > 0) {
          tableRef.current.gridApi.refreshServerSideStore();
          notice(`Update ids ${successIds.join(', ')} successfully!`, [], true);
        }

        if (errorItems.length > 0) {
          errorItems.forEach((i) => {
            notice(`Update id "${i.id}" failed with the following errors`, i.messages, false);
          });
        }
      } catch (err) {
        console.log('[UpdateCell] System error', err);
      }
    }
  };

  const getCellActions = (columnField: string) => {
    const cellMenu = get(config, ['mapping', columnField, 'menu'], []);
    return cellMenu.map((m) => {
      let submitHandler = null;
      if (m.disabledSubmit) {
        submitHandler = (data) => {
          alert('submit disabled. Reason: ' + m.disabledSubmitReason);
        };
      } else {
        if (m['onClickItem']) {
          submitHandler = m['onClickItem'];
        } else {
          const actionType = get(m, 'actionType', 'edit');
          if (actionType === 'delete') {
            const dimension = get(m, ['payload', 'static', 'dimension'], '');
            submitHandler = (data) => handleDeleteDimension(data, dimension);
          } else {
            const updateFieldMapping = get(m, ['payload', 'field'], {});
            if (m.localUpdate) {
              submitHandler = (data) => handleLocalUpdate(data, updateFieldMapping);
            } else {
              submitHandler = (data) => handleSubmit(data, updateFieldMapping);
            }
          }
        }
      }
      return {
        ...m,
        component: get(ref.current.cellEditor, m.editor, null),
        onSubmit: submitHandler,
        onLoad: editorDataLoading[m.editor],
      };
    });
  };

  const getCellFormat = (columnField: string) => {
    const formatType = get(config, ['mapping', columnField, 'cellFormat'], 'text');
    return ref.current.cellFormat[formatType];
  };

  const handleGetSelectedRows = (nextRows?: { data: any; isSelected: boolean }[]) => {
    const selectedRows = tableRef.current.selectedRows;

    if (!nextRows) return selectedRows;

    const kvSelectedIndex = selectedRows.reduce((carry, i, index) => {
      return { ...carry, [getRowId(i)]: index };
    }, {});

    const nextSelectedRows = produce(selectedRows, (draft) => {
      nextRows.forEach(({ data, isSelected }) => {
        const rowId = getRowId(data);
        if (isSelected && kvSelectedIndex[rowId] === undefined) {
          draft.push(data);
        } else if (!isSelected && kvSelectedIndex[rowId] !== undefined) {
          draft.splice(kvSelectedIndex[rowId], 1);
        }
      });
    });

    tableRef.current.selectedRows = nextSelectedRows;
    return addon('getSelectedRows', (nextSelectedRows) => nextSelectedRows)(nextSelectedRows);
  };

  const handleUpdateSelectedRows = (rows: any[]) => {
    const primaryKeys = get(config, `primaryKeys`, []);
    tableRef.current.selectedRows = cloneDeep(rows);
    if (tableRef.current.gridApi && primaryKeys.length > 0) {
      restoreSelectedRows({ api: tableRef.current.gridApi });
    }
  };

  const getDataRows = () => {
    if (!tableRef.current.datasource) {
      tableRef.current.datasource = createDatasource(tableRef);
    }

    setStatus((status) => ({
      ...status,
      table: 'loading',
    }));

    return new Promise((resolve, reject) => {
      tableRef.current.datasource.getRows({
        request: { startRow: 0 },
        success: ({ rowData }) => {
          log('resolve getrows', rowData);
          setStatus((status) => ({
            ...status,
            table: 'success',
          }));
          resolve(rowData);
        },
        fail: (error) => {
          setStatus((status) => ({
            ...status,
            table: 'error',
          }));
          reject(error);
        },
      });
    });
  };

  const registerMainButtonComponent = (btnComponent: any) => {
    ref.current.mainButton = btnComponent;
  };

  const registerHeaderAction = (action: any) => {
    ref.current.headerAction = action;
  };

  const registerCellFormat = (format: any) => {
    ref.current.cellFormat = format;
  };

  const registerCellEditor = (editor: any) => {
    ref.current.cellEditor = editor;
  };

  const registerNotification = (notification: {
    success: (title: string, messages: string[]) => void;
    error: (title: string, messages: string[]) => void;
  }) => {
    ref.current.notification = notification;
  };

  const addon = React.useCallback((path, defaults = null) => {
    const fun = get(tableRef.current.addons, [path], defaults);
    return (...args) => fun(...args, ref.current.backboneInstance);
  }, []);

  const registerAddon = React.useCallback((path, handler) => {
    tableRef.current.addons = set(tableRef.current.addons || {}, [path], handler);
  }, []);

  const changeConfig = React.useCallback((name: string, value: any) => {
    addon(`config.${name}`, () => Promise.resolve({ value }))(
      { value, previousValue: get(tableRef.current.config, name) },
      tableRef.current.config,
    ).then(({ value }) => {
      updateConfig({ [name]: value });
      if (name.indexOf('system.') === 0) {
        const varName = name.replace('system.', '');
        updateSystemConfig({ [varName]: value });
      }
    });
  }, []);

  tableRef.current.apiRequest = apiRequest;
  tableRef.current.getColumnFields = getColumnFields;
  tableRef.current.updateTotalResult = (total) => {
    setPagination((pagination) => ({ ...pagination, total: total }));
  };

  if (ff.separate_table_data_loading) {
    tableRef.current.updatePaginationTraceId = (traceId) => {
      tableRef.current.pagination.traceId = traceId;
      setPagination((pagination) => ({ ...pagination, traceId }));
    };
  }

  tableRef.current.getAvailableFilterColumns = getAvailableFilterColumns;
  tableRef.current.getAvailableSortColumns = getAvailableSortColumns;
  tableRef.current.addon = addon;
  tableRef.current.datasource = React.useMemo(() => createDatasource(tableRef), []);

  function restoreSelectedRows({ api }) {
    if (tableRef.current.config.tableType !== 'compact') return;
    const primaryKeys = get(config, 'primaryKeys', []);
    api.forEachNode((rowNode) => {
      if (rowNode.data) {
        const exist = tableRef.current.selectedRows.find((r) => {
          return primaryKeys.every((key) => r[key] === rowNode.data[key]);
        });
        rowNode.setSelected(!!exist);
      }
    });
  }

  function getMainButtonComponent(name: string) {
    return get(ref.current.mainButton, name, null);
  }

  function getRowId(row, ignoreKeys = []) {
    const primaryKeys = get(config, 'primaryKeys', []);
    return ['_eipCustomId']
      .concat(primaryKeys)
      .filter((i) => ignoreKeys.indexOf(i) === -1)
      .map((i) => {
        return row[i];
      })
      .join('|');
  }

  let getFilterOptionValue;
  if (ff.custom_filter_value_addon) {
    getFilterOptionValue = (columnName: string, payload?: any, forceReload: boolean) => {
      const filterValueReq = get(tableRef.current.addons, `filterValue.${columnName}`);
      if (filterValueReq) {
        return filterValueReq(payload, forceReload);
      }

      switch (columnName) {
        case 'country':
          return dataRequest.getCountry({ forceReload });
        case 'marketplace':
          return dataRequest.getMarketplace({ forceReload });
        case 'storefront':
          return dataRequest.getStorefront();
        case 'ad_tool':
          return dataRequest.getAdTool();
        case 'ad_type':
          return dataRequest.getAdType({ forceReload });
        case 'campaign_status':
          return dataRequest.getStatus({ dimension: 'ADS_CAMPAIGN', marketplaceCode: 'SHOPEE' });
        case 'ad_object_status':
          return dataRequest.getStatus({ dimension: 'ADS_OBJECT', marketplaceCode: 'SHOPEE' });
        case 'keyword_status':
          return dataRequest.getStatus({ dimension: 'ADS_PLACEMENT', marketplaceCode: 'SHOPEE' });
        case 'script_status':
          return dataRequest.getStatus({ dimension: 'SCRIPT', marketplaceCode: 'SHOPEE' });
        case 'keyword_match_type':
          const matchTypes = [
            { id: 'BROAD_MATCH', text: 'Broad match' },
            { id: 'EXACT_MATCH', text: 'Exact match' },
          ];
          return Promise.resolve(matchTypes);
        default:
          return Promise.resolve([]);
      }
    };
  }

  ref.current.backboneInstance = {
    visibility,
    status,
    options,
    setConfig,
    updateConfig,
    config,
    getRowId: getRowId,
    setGridApi: ({ grid, column }) => {
      tableRef.current.gridApi = grid;
      tableRef.current.columnApi = column;
      if (grid.addEventListener) {
        grid.addEventListener('paginationChanged', (event) => {
          restoreSelectedRows(event);
        });
      }
      // tableRef.current.gridApi.refreshServerSideStore({ purge: true });
    },
    reloadData: (name: string, route) => {
      log('reloadDATA', name);
      reloadHandler[name] && reloadHandler[name](route);
    },
    init: init,
    isTableReady: () => !!tableRef.current.gridApi,
    getCellConfig: (columnField: string) => {
      return get(config, ['mapping', columnField]);
    },
    getConfig: (name: string, defaultValue: any = null) =>
      cloneDeep(get(fastRef.current.config, `${name}`, defaultValue)),
    // cloneDeep(get(config, `${name}`, defaultValue)),
    getCallback: (name: string) => get(tableRef.current.callback, `${name}`, () => undefined),
    // getConfig: useConfig,
    getStatus: (name: string) => get(status, `${name}`, ''),
    getHeaders: () => headers,
    getRows: getDataRows,
    getDatasource: () => tableRef.current.datasource,
    getPagination: () => pagination,
    getOptions: (name: string) => get(options, name, []),
    getFilterOptionValue: ff.custom_filter_value_addon ? getFilterOptionValue : getFilterValueData,
    getTempStorage: (name: string) => get(ref.current.tempStorage, name),
    // getOptions: useOption,
    getSubmitHandler: (type: string) => apiRequest[type],
    getVisibility: (name: string) => get(visibility, `${name}`, false),
    getSelectedRows: handleGetSelectedRows,
    updateSelectedRows: handleUpdateSelectedRows,
    updateTempStorage: (name: string, value: any) => {
      ref.current.tempStorage[name] = value;
    },
    changeVisibility: handleChangeVisibility,
    changeStatus: handleChangeStatus,
    changeConfig: changeConfig,
    changeOptionOrder: handleChangeOptionOrder,
    changePagination: handleChangePagination,
    changeColumnOrder: handleChangeColumnOrder,
    getMainButtonComponent,
    getAvailableColumns,
    getAvailableFilterColumns,
    getAvailableSortColumns,
    registerMainButtonComponent,
    registerHeaderAction,
    registerCellFormat,
    registerCellEditor,
    registerNotification,
    registerAddon: registerAddon,
    addon: addon,
    ...(ff.separate_table_data_loading ? { loadPagination } : {}),
  };

  return ref.current.backboneInstance;
};
