import * as React from 'react';

interface IGoogleFormProps {
  embed: JSX.Element;
}

const GoogleForm = ({ embed }: IGoogleFormProps) => {
  const ref = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   if (!ref.current) return;
  //   const form = ref.current.querySelector('form');
  //   console.log('form', form);
  // }, [ref.current]);

  // setInterval(() => {
  // if (!ref.current) return;
  // const iframe = ref.current.querySelector('iframe') as HTMLIFrameElement;

  // console.log('iframe', iframe.contentWindow.window.document);
  // }, 2000);
  return (
    <div style={{ width: '100%', height: '100%' }} ref={ref} id={'google_feature_request_form'}>
      {embed}
    </div>
  );
};

export default GoogleForm;
