import * as React from 'react';
import { get, uniq, groupBy, sortBy, last, debounce, omit } from 'lodash';
import { useSetAtom } from 'jotai';
import moment from 'moment';

import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import {
  ACCUMULATIVE_CONFIG,
  CHART_CONFIG,
  DEFAULT_DATETIME_FORMAT_FORMULA,
  PERSONALIZATION,
  X_AXIS,
  Y_AXIS,
} from '@ep/insight-ui/system/helper/constant';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';

function useEtableGridApi(
  backbone,
  callback = {
    beforeRequest: () => {},
    getRows: { success: async (result: { rowData: any[]; rowCount: number }) => {}, fail: async (error: Error) => {} },
  },
) {
  const api = React.useMemo(() => {
    const ds = backbone.getDatasource();
    function refreshServerSideStore() {
      callback.beforeRequest();
      ds.getRows({
        request: { startRow: 0, limit: -1, groupKeys: [], rowGroupCols: {} },
        success: callback.getRows.success,
        fail: callback.getRows.fail,
      });
    }

    return {
      deselectAll: () => {},
      refreshServerSideStore: debounce(refreshServerSideStore, 200),
      paginationSetPageSize: (...args) => {
        console.info('called with', args);
      },
    };
  }, []);

  return api;
}

export const useEvisualization = ({ backbone, config }: any) => {
  const chartRef = React.useRef({ isInit: false });

  const setLastUpdatedAt = useSetAtom(eTableAtom.lastUpdatedAt);

  const [loadingChart, setLoadingChart] = React.useState(false);
  const [rowData, setRowData] = React.useState([]);
  const [summary, setSummary] = React.useState({ title: '' });
  const [isRequestFailed, setIsRequestFailed] = React.useState(false);

  const currencyReq = get(config, 'requestFilter.currency', '');
  const currencyConfig = backbone.getConfig('currency', 'USD');
  const chartConfig = backbone.getConfig('chartConfig', {});
  const groupPeriod = backbone.getConfig('groupPeriod');
  const allowAccumulative = backbone.getConfig('system.allowAccumulative', 'no');

  const groupDimension = (backbone.getConfig('groupBy', { columns: [] }).columns || [])[0];
  const heatmapGroupDimension = (backbone.getConfig('groupBy', { columns: [] }).columns || [])[1];
  const mapping = backbone.getConfig('mapping', {});
  const view = backbone.getConfig('view', {});
  const datetimeFormat = get(chartConfig, ['config', 'datetimeFormat'], DEFAULT_DATETIME_FORMAT_FORMULA);
  const useDimensionXAxis = get(chartConfig, ['config', 'useDimensionXAxis'], 'no') === 'yes';
  const timelineCohorts = get(chartConfig, ['config', 'timelineCohorts'], false);

  const viewByDimenionValueGetter = get(mapping, [groupDimension, 'valueGetter']);
  const viewByDimenionField =
    get(viewByDimenionValueGetter, ['value'], '') || get(viewByDimenionValueGetter, ['id'], '');

  let valueDimensions = backbone.getConfig('groupBy', { columns: [] }).columns || [];
  valueDimensions = (valueDimensions || []).filter((i) => i !== EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE);

  const calculateAccumulative = (
    { dates, index, rows, currentRow, accumulativeConfig, accumulativeConfigValue, metric },
    isCohort = false,
  ) => {
    const sumAccumulative = dates.slice(0, index + 1).reduce(
      (result, date) => {
        const exists = rows.find((i) => i.datetime === date);
        const accArr = accumulativeConfigValue.match(/ACC.*?\'\)/g) || [];
        const accObj = accArr.reduce((carry, acc) => {
          return {
            ...carry,
            [acc]: (result[acc] || []).concat(get(exists, ['eData', metric, acc.replace(/(.*\(\'|\'\))/g, '')], '')),
          };
        }, {});
        const rs = accumulativeConfigValue.replace(/ACC.*?\'\)/g, (c) => {
          const arr = accObj[c];
          const sum = arr.reduce((a, b) => a + (b || 0), 0);
          if (c.startsWith('ACC_AVG')) {
            return sum / arr.length;
          }
          if (c.startsWith('ACC_MAX')) {
            return Math.max(...arr);
          }
          if (c.startsWith('ACC_MIN')) {
            return Math.min(...arr);
          }
          return sum;
        });
        const currentValue = exists ? (isCohort ? exists.value_cohort : exists.value) : null;
        const nextValue = rs
          ? toValue(rs, get(exists, ['eData', metric], {}))
          : currentValue === null
          ? result.value
          : result.value + currentValue;
        return {
          ...result,
          value: nextValue,
          ...accObj,
        };
      },
      { ...currentRow, value: null, ...(isCohort ? { datetime: currentRow.datetime_cohort } : {}) },
    );

    if (accumulativeConfig === 'sum') {
      return sumAccumulative;
    }
    if (accumulativeConfig === 'avg') {
      return {
        ...sumAccumulative,
        value: sumAccumulative.value == null ? null : sumAccumulative.value / (index + 1),
      };
    }
    return sumAccumulative;
  };

  const getAxisLabel = (axis, row, mapping) => {
    if (axis && axis.value && axis?.value !== EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE && axis?.value !== 'all') {
      const axisValue = axis.value;
      if (axis.type === 'time') {
        const format = String(datetimeFormat).startsWith('=')
          ? toValue(datetimeFormat, { period: axisValue })
          : datetimeFormat;
        return moment(row.datetime).format(format);
      }

      const valueField = get(
        mapping,
        [axisValue, 'valueGetter', 'value'],
        get(mapping, [axisValue, 'valueGetter', 'id'], ''),
      );
      return get(row, ['eData', axisValue, 'label'], row[valueField]);
    }
    return 'all';
  };

  const charts = React.useMemo(() => {
    const availableMetric = backbone.getConfig('metric');
    const orderMetric = backbone.getOptions('metric').filter((el) => availableMetric.includes(el.id));
    const valueGroupBy = get(chartConfig, 'config.groupBy', '');
    const valueDimension = groupDimension || get(chartConfig, 'config.valueDimension', '');
    const dimensionName = valueDimension
      ? uniq(
          rowData.map((r) => {
            return get(r, ['eData', valueDimension, 'value'], get(r, ['eData', valueDimension, 'id']));
          }),
        )
      : [];
    const valueDisplay = get(chartConfig, 'config.display', '');
    const isColumnStacked = get(chartConfig, 'config.isColumnStacked', false);
    const chartType = get(chartConfig, ['config', 'chartType'], '');

    let result = [];

    if (chartType === 'heatmap') {
      const heatmapXAxis = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG, X_AXIS], null);
      const heatmapYAxis = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG, Y_AXIS], null);
      const sortedRowData = sortBy(rowData, 'datetime');
      orderMetric.forEach((headerItem) => {
        const metricLabel = headerItem.name || headerItem.id;
        const metric = headerItem.id || headerItem.name;
        const colKey = headerItem.id;
        const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
        const formatLabel = get(valueGetter, ['label'], '');
        const labelField = get(valueGetter, ['label'], '');

        const accumulativeConfig = get(view, [ACCUMULATIVE_CONFIG, headerItem.id], 'sum');

        const concatSymbol = 'QWERTYUIOPASDFGHJKLZXCVBNM';

        const heatmapRowsObj = sortedRowData.reduce((carry, row) => {
          const xAxisLabel = getAxisLabel(heatmapXAxis, row, mapping);
          const yAxisLabel = getAxisLabel(heatmapYAxis, row, mapping);
          const key = `${xAxisLabel}${concatSymbol}${yAxisLabel}`;
          const rowValue = get(row, ['eData', headerItem.id, 'value'], get(row, ['eData', headerItem.id, 'id']));

          carry[key] = {
            ...row,
            value: (carry[key]?.value || []).concat(rowValue),
          };

          return carry;
        }, {});

        const heatmapRows = Object.entries(heatmapRowsObj).map(([key, data]) => {
          const [header, label] = key.split(concatSymbol);
          const sumValue = data.value.reduce((a, b) => a + (b == null ? 0 : b), 0);
          const finalValue = accumulativeConfig === 'avg' ? sumValue / data.value.length : sumValue;

          return {
            ...data,
            header: header === 'all' ? 'All' : header,
            label: label === 'all' ? 'All' : label,
            value: finalValue,
            formatLabel,
          };
        });

        let total = get(sortedRowData, ['0', 'total', valueGetter.value || valueGetter.id], null);

        if (labelField.startsWith('=')) {
          total = toValue(labelField, {
            ...get(rowData, [0, 'eData', headerItem.id], {}),
            value: total,
            'config.currency': currencyConfig,
          });
        } else {
          total = formatCurrencyNumber(total);
        }

        const currency = get(sortedRowData, ['0', 'currency', valueGetter.value || valueGetter.id], null);
        result.push({
          data: { rows: heatmapRows },
          total,
          cohortTotal: 0,
          metric,
          metricLabel,
          isSettingYAxis: true,
          currency,
          isColumnStacked,
          colKey,
        });
      });
      return result;
    }

    if (chartType === 'treemap') {
      orderMetric.forEach((headerItem) => {
        const chartMapping = [];
        const metric = headerItem.name || headerItem.id;
        const colKey = headerItem.id;
        const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
        const metricRows = rowData.map((r) => ({
          eData: r.eData,
          datetime: r.datetime,
          rawData: r,
          ...get(r, ['eData', headerItem.id], {}),
          value: get(r, ['eData', headerItem.id, 'value'], get(r, ['eData', headerItem.id, 'id'])),
          value_cohort: get(r, ['cohort', valueGetter.value || valueGetter.id], null),
          datetime_cohort: get(r, ['cohort', 'datetime'], ''),
        }));
        const accumulativeConfig = get(view, [ACCUMULATIVE_CONFIG, headerItem.id], '');
        const accumulativeConfigValue = get(mapping, [headerItem.id, 'valueGetter', accumulativeConfig], '');

        const labelField = get(mapping, [headerItem.id, 'valueGetter', 'label'], '');

        const totals = Object.entries(valueGetter).reduce((carry, [k, v]) => {
          if (/^value\d*$/.test(String(k))) {
            carry[k] = get(rowData, ['0', 'total', v], null);
          }
          return carry;
        }, {});
        let total = get(rowData, ['0', 'total', valueGetter.value || valueGetter.id], null);
        const rawTotal = total;
        const metricValueGetter = get(rowData, [0, 'eData', headerItem.id], {});
        if (labelField.startsWith('=')) {
          total = toValue(labelField, { ...metricValueGetter, ...totals, 'config.currency': currencyConfig });
        } else {
          total = formatCurrencyNumber(total);
        }
        const cohortTotals = Object.entries(valueGetter).reduce((carry, [k, v]) => {
          if (/^value\d*$/.test(String(k))) {
            carry[k] = get(rowData, ['0', 'total_cohort', v], null);
          }
          return carry;
        }, {});
        const currency = get(rowData, ['0', 'currency', valueGetter.value || valueGetter.id], null);
        let cohortTotal = get(rowData, ['0', 'total_cohort', valueGetter.value || valueGetter.id], null);
        const rawCohortTotal = cohortTotal;
        if (labelField.startsWith('=')) {
          cohortTotal = toValue(labelField, {
            ...metricValueGetter,
            ...cohortTotals,
            'config.currency': currencyConfig,
          });
        } else {
          cohortTotal = formatCurrencyNumber(cohortTotal);
        }

        if (Object.entries(groupBy(metricRows, (i) => get(i, ['eData', valueDimension, 'value']))).length === 0) {
          result.push({
            data: { rows: [], headers: [] },
            total: null,
            cohortTotal: 0,
            metric,
            colKey,
            isSettingYAxis: true,
            currency: '',
            isColumnStacked,
          });
        } else {
          const dates = uniq(rowData.map((i) => i.datetime)).sort();
          const groupedRows = groupBy(metricRows, (i) =>
            valueDimensions.map((valueDimension) => get(i, ['eData', valueDimension, 'value'])).join('////'),
          );
          for (const [k, v] of Object.entries(groupedRows)) {
            const treeData = valueDimensions.map((valueDimension) => ({
              label: get(v, [0, 'eData', valueDimension, 'label']),
              key: valueDimension,
            }));
            const groupedValue = uniq(valueDimensions).reduce(
              (carry, dimension) => {
                const data = get(v, [0, 'eData', dimension], {});
                return {
                  label: carry.label.concat(data.label || []),
                  id: carry.id.concat(data.id || []),
                  value: carry.value.concat(data.value || []),
                };
              },
              { label: [], id: [], value: [] },
            );
            const label = groupedValue.label.join(' - ');
            const value = groupedValue.value.join(' - ');
            const id = groupedValue.id.join(' - ');

            chartMapping.push({
              key: headerItem.id,
              data: dates.map((d, index) => {
                const existed = v.find((i) => i.datetime === d);
                if (allowAccumulative && accumulativeConfig) {
                  return calculateAccumulative({
                    dates,
                    index,
                    rows: v,
                    currentRow: existed,
                    accumulativeConfig,
                    accumulativeConfigValue,
                    metric: headerItem.id,
                  });
                }
                return existed ?? { value: null };
              }),
              formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
              formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
              treeData,
              currency,
              ...get(v, [0, 'eData', headerItem.id], {}),
              label: label || id || value || headerItem.name || headerItem.id || '',
            });

            const hasCohort = v.some((el) => el.datetime_cohort);

            if (hasCohort) {
              chartMapping.push({
                key: headerItem.id,
                data: dates.map((d, index) => {
                  const existed = v.find((i) => i.datetime === d);
                  if (allowAccumulative && accumulativeConfig) {
                    return calculateAccumulative(
                      {
                        dates,
                        index,
                        rows: v,
                        currentRow: existed,
                        accumulativeConfig,
                        accumulativeConfigValue,
                        metric: headerItem.id,
                      },
                      true,
                    );
                  }
                  return existed ? { value: existed.value_cohort, datetime: existed.datetime_cohort } : { value: null };
                }),
                formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                currency,
                isCohort: true,
                ...get(v, [0, 'eData', headerItem.id], {}),
                label: label || id || value || headerItem.name || headerItem.id || '',
              });
            }
          }

          result.push({
            data: { rows: chartMapping, headers: dates },
            total,
            rawTotal,
            cohortTotal: timelineCohorts ? cohortTotal : null,
            rawCohortTotal: timelineCohorts ? rawCohortTotal : null,
            metric,
            colKey,
            isSettingYAxis: true,
            currency,
            isColumnStacked,
          });
        }
      });

      return result;
    }
    switch (valueGroupBy) {
      case 'dimension': {
        if (valueDisplay === 'single' && orderMetric.length >= 2) {
          const dates = uniq(rowData.map((i) => i.datetime)).sort();

          dimensionName.forEach((i) => {
            if (Object.entries(groupBy(rowData, 'metric')).length === 0) {
              result.push({
                data: { rows: [], headers: [] },
                total: 0,
                metric: i,
                colKey: valueDimension,
                currency: '',
                isSettingYAxis: false,
                isColumnStacked,
              });
            } else {
              for (const [k, v] of Object.entries(groupBy(rowData, 'metric'))) {
                const chartMapping = [];
                chartMapping.push({
                  label: k,
                  key: k,
                  data: v.filter((el) => el[valueDimension] === i),
                  formatLabel: get(mapping, [i, 'valueGetter', 'label'], '=p("value")'),
                  formatTopLabel: get(mapping, [i, 'valueGetter', 'topLabel'], ''),
                });

                const currency = uniq(v.map((i) => i.unit));
                const total = v.reduce((a, b) => {
                  if (b[valueDimension] === i) {
                    a += b.value;
                  }
                  return a;
                }, 0);

                result.push({
                  data: { rows: chartMapping, headers: dates },
                  total: null,
                  metric: i,
                  colKey: valueDimension,
                  currency: currency[0],
                  isSettingYAxis: false,
                  isColumnStacked,
                });
              }
            }
          });
        } else {
          const sortedRowData = sortBy(rowData, 'datetime');
          const dates = uniq(sortedRowData.map((i) => i.datetime));
          dimensionName.forEach((i) => {
            const chartMapping = [];
            const dimensionRows = sortedRowData.filter((value) => {
              const dimensionValue = get(
                value,
                ['eData', valueDimension, 'id'],
                get(value, ['eData', valueDimension, 'value']),
              );
              return valueDimension === EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE || dimensionValue === i;
            });
            const metric = uniq(dimensionRows.map((i) => i.metric));

            if (dimensionRows.length === 0) {
              result.push({
                pie: [],
                data: { rows: [], headers: [] },
                total: null,
                metric: i,
                colKey: valueDimension,
                currency: '',
                isSettingYAxis: true,
                hideShowTotal: metric.length > 1,
                formatLabel: '',
                isColumnStacked,
              });
            } else {
              let total = null;
              for (const metric of orderMetric) {
                const valueGetter = get(mapping, [metric.id, 'valueGetter'], {});
                const metricAPIField = valueGetter.value || valueGetter.id;
                const metricLabelField = valueGetter.label || metricAPIField || '';
                const currency = get(dimensionRows, [0, 'currency', metricAPIField], '');
                const rawTotal = get(dimensionRows, [0, 'total', metricAPIField], null);
                if (metricLabelField.startsWith('=')) {
                  total = toValue(metricLabelField, {
                    ...get(rowData, [0, 'eData', metric.id], {}),
                    value: rawTotal,
                    'config.currency': currencyConfig,
                  });
                } else {
                  total = formatCurrencyNumber(rawTotal);
                }
                chartMapping.push({
                  key: metric.id,
                  data: dimensionRows.map((el) => {
                    const value = get(el, ['eData', metric.id, 'value'], get(el, ['eData', metric.id, 'id']));
                    return {
                      ...el,
                      value,
                    };
                  }),
                  currency,
                  formatLabel: get(mapping, [metric.id, 'valueGetter', 'label'], '=p("value")'),
                  formatTopLabel: get(mapping, [metric.id, 'valueGetter', 'topLabel'], ''),
                  ...get(rowData, [0, 'eData', metric.id], {}),
                  label: metric.name,
                  rawTotal,
                  total,
                });
              }

              result.push({
                pie: chartConfig.config.chartType === 'pie' ? chartMapping : [],
                data: { rows: chartMapping, headers: dates },
                total: total,
                metric: i || 'All',
                colKey: valueDimension,
                currency: currencyReq,
                isSettingYAxis: true,
                hideShowTotal: metric.length > 1,
                isColumnStacked,
              });
            }
          });
        }
        break;
      }

      case 'metric': {
        if (useDimensionXAxis && groupDimension) {
          const valueDimension = groupDimension;
          const headers = rowData.map((row) => {
            const eData = get(row, ['eData', valueDimension], {});
            return eData.label || eData.value;
          });
          if (valueDisplay === 'single') {
            result = orderMetric.map((headerItem) => {
              const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
              const valueMetricAPIField = get(valueGetter, ['value'], '');
              const additionalInformation = get(rowData, [0, 'eData', headerItem.id], {});
              const formatLabel = get(valueGetter, ['label'], '=p("value")');
              const totalValue = get(rowData, [0, 'total', valueMetricAPIField], 0);
              const total = toValue(formatLabel, {
                ...additionalInformation,
                value: totalValue,
                'config.currency': currencyConfig,
              });
              const metric = headerItem.name || headerItem.id;
              const colKey = headerItem.id;

              return {
                isColumnStacked,
                total,
                metric,
                colKey,
                isSettingYAxis: true,
                currency: '',
                cohortTotal: 0,
                data: {
                  headers,
                  rows: [
                    {
                      formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                      formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                      key: headerItem.id,
                      data: rowData.map((row) => {
                        return {
                          label: get(row, ['eData', headerItem.id, 'value']),
                          value: get(row, ['eData', headerItem.id, 'value']),
                          eData: get(row, ['eData'], {}),
                        };
                      }),
                      ...get(rowData, [0, 'eData', headerItem.id], {}),
                      label: headerItem.name || headerItem.id,
                    },
                  ],
                },
              };
            });
          } else {
            const rows = orderMetric.map((headerItem) => {
              return {
                formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                key: headerItem.id,
                data: rowData.map((row) => {
                  return {
                    label: get(row, ['eData', headerItem.id, 'value']),
                    value: get(row, ['eData', headerItem.id, 'value']),
                    eData: get(row, ['eData'], {}),
                  };
                }),
                ...get(rowData, [0, 'eData', headerItem.id], {}),
                label: headerItem.name || headerItem.id,
              };
            });
            const total = orderMetric
              .map((headerItem) => {
                const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
                const formatLabel = get(valueGetter, ['label'], '=p("value")');
                const value = get(valueGetter, ['value'], '');
                const totalValue = get(rowData, [0, 'total', value], 0);
                return toValue(formatLabel, {
                  ...get(rowData, [0, 'eData', headerItem.id], {}),
                  value: totalValue,
                  'config.currency': currencyConfig,
                });
              })
              .join(' / ');
            const colKey = orderMetric.map((headerItem) => headerItem?.id).join(', ') || 'Metric';
            result = [
              {
                isColumnStacked,
                isSettingYAxis: true,
                metric: orderMetric.map((headerItem) => headerItem.name || headerItem.id).join(' / ') || 'Metric',
                colKey,
                currency: '',
                total,
                data: {
                  headers,
                  rows,
                },
              },
            ];
          }
        } else if (valueDisplay === 'single') {
          orderMetric.forEach((headerItem) => {
            const chartMapping = [];
            const metric = headerItem.name || headerItem.id;
            const colKey = headerItem.id;
            const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
            const chartValues = Object.entries(valueGetter).reduce((a, [key, value]) => {
              if (/value(\d)*$/.test(key) && value) {
                return [...a, { key, value }];
              }
              return a;
            }, []);
            const metricRows = rowData.map((r) => {
              const data = {
                eData: r.eData,
                datetime: r.datetime,
                value: get(r, ['eData', headerItem.id, 'value'], get(r, ['eData', headerItem.id, 'id'])),
                value_cohort: get(r, ['cohort', valueGetter.value || valueGetter.id], null),
                datetime_cohort: get(r, ['cohort', 'datetime'], ''),
              };
              chartValues.forEach(({ key }) => {
                data[key] = get(r, ['eData', headerItem.id, key]);
              });
              return data;
            });
            const accumulativeConfig = get(view, [ACCUMULATIVE_CONFIG, headerItem.id], '');
            const accumulativeConfigValue = get(mapping, [headerItem.id, 'valueGetter', accumulativeConfig], '');
            const labelField = get(mapping, [headerItem.id, 'valueGetter', 'label'], '');

            const resourceMetric = get(rowData, ['0', 'resourceMetric'], []);
            let total = get(rowData, ['0', 'total', valueGetter.value || valueGetter.id], null);
            const rawTotal = total;
            const metricValueGetter = get(rowData, [0, 'eData', headerItem.id], {});
            if (labelField.startsWith('=')) {
              total = toValue(labelField, { ...metricValueGetter, value: total, 'config.currency': currencyConfig });
            } else {
              total = formatCurrencyNumber(total);
            }
            const currency = get(rowData, ['0', 'currency', valueGetter.value || valueGetter.id], null);
            let cohortTotal = get(rowData, ['0', 'total_cohort', valueGetter.value || valueGetter.id], null);
            const rawCohortTotal = cohortTotal;
            if (labelField.startsWith('=')) {
              cohortTotal = toValue(labelField, {
                ...metricValueGetter,
                value: cohortTotal,
                'config.currency': currencyConfig,
              });
            } else {
              cohortTotal = formatCurrencyNumber(cohortTotal);
            }

            if (Object.entries(groupBy(metricRows, (i) => get(i, ['eData', valueDimension, 'value']))).length === 0) {
              result.push({
                data: { rows: [], headers: [] },
                total: null,
                cohortTotal: 0,
                metric,
                colKey,
                isSettingYAxis: true,
                currency: '',
                isColumnStacked,
              });
            } else {
              const dates = uniq(rowData.map((i) => i.datetime)).sort();
              for (const [k, v] of Object.entries(
                groupBy(metricRows, (i) =>
                  get(i, ['eData', valueDimension, 'value'], get(i, ['eData', valueDimension, 'id'])),
                ),
              )) {
                const groupedValue = uniq(valueDimensions).reduce(
                  (carry, dimension) => {
                    const data = get(v, [0, 'eData', dimension], {});
                    return {
                      label: carry.label.concat(data.label || []),
                      id: carry.id.concat(data.id || []),
                      value: carry.value.concat(data.value || []),
                    };
                  },
                  { label: [], id: [], value: [] },
                );
                const label = groupedValue.label.join(' - ');
                const value = groupedValue.value.join(' - ');
                const id = groupedValue.id.join(' - ');

                chartValues.forEach(({ key, value: metricValue }) => {
                  const metricLabel = get(
                    mapping,
                    [headerItem.id, 'staticValue', key + '.customMetric'],
                    /value\d+$/.test(key) ? resourceMetric.find((i) => i.value == metricValue)?.label_raw || '' : '',
                  );
                  const additionalData = {
                    yAxisID: get(mapping, [headerItem.id, 'staticValue', key + '.yAxisID'], ''),
                    chartStyle: get(mapping, [headerItem.id, 'staticValue', key + '.chartStyle'], ''),
                    chartColor: get(mapping, [headerItem.id, 'staticValue', key + '.color'], ''),
                    lineDash: get(mapping, [headerItem.id, 'staticValue', key + '.lineDash'], null),
                    order: get(mapping, [headerItem.id, 'staticValue', key + '.order'], null),
                    lineTension: get(mapping, [headerItem.id, 'staticValue', key + '.lineTension'], null),
                    displayChart: get(mapping, [headerItem.id, 'staticValue', key + '.displayChart'], 'yes') != 'no',
                  };
                  chartMapping.push({
                    key: key === 'value' ? headerItem.id : key,
                    data: dates.map((d, index) => {
                      const existed = v.find((i) => i.datetime === d);
                      if (allowAccumulative && accumulativeConfig) {
                        return calculateAccumulative({
                          dates,
                          index,
                          rows: v,
                          currentRow: existed,
                          accumulativeConfig,
                          accumulativeConfigValue,
                          metric: headerItem.id,
                        });
                      }
                      return existed ? { ...existed, value: existed[key] || null } : { value: null };
                    }),
                    formatLabel: get(
                      mapping,
                      [headerItem.id, 'valueGetter', key + '.label'],
                      get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                    ),
                    formatTopLabel: get(
                      mapping,
                      [headerItem.id, 'valueGetter', key + '.topLabel'],
                      get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                    ),
                    currency,
                    ...get(v, [0, 'eData', headerItem.id], {}),
                    label: label || id || value || metricLabel || headerItem.name || headerItem.id || '',
                    ...additionalData,
                    config: {
                      ...omit(backbone.config, ['mapping']),
                    },
                  });
                });
                const hasCohort = v.some((el) => el.datetime_cohort);

                if (hasCohort) {
                  chartMapping.push({
                    key: 'cohort',
                    data: dates.map((d, index) => {
                      const existed = v.find((i) => i.datetime === d);
                      if (allowAccumulative && accumulativeConfig) {
                        return calculateAccumulative(
                          {
                            dates,
                            index,
                            rows: v,
                            currentRow: existed,
                            accumulativeConfig,
                            accumulativeConfigValue,
                            metric: headerItem.id,
                          },
                          true,
                        );
                      }
                      return existed
                        ? { value: existed.value_cohort, datetime: existed.datetime_cohort }
                        : { value: null };
                    }),
                    formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                    formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                    currency,
                    isCohort: true,
                    ...get(v, [0, 'eData', headerItem.id], {}),
                    label: label || id || value || headerItem.name || headerItem.id || '',
                    config: {
                      ...omit(backbone.config, ['mapping']),
                    },
                  });
                }
              }

              result.push({
                data: { rows: chartMapping, headers: dates },
                total,
                rawTotal,
                cohortTotal: timelineCohorts ? cohortTotal : null,
                rawCohortTotal: timelineCohorts ? rawCohortTotal : null,
                metric,
                colKey,
                isSettingYAxis: true,
                currency,
                isColumnStacked,
              });
            }
          });
        } else {
          const chartMapping = [];
          const sortedRowData = sortBy(rowData, 'datetime');
          const dates = uniq(sortedRowData.map((i) => i.datetime));

          orderMetric.forEach((headerItem) => {
            const valueGetter = get(mapping, [headerItem.id, 'valueGetter'], {});
            const metricRows = sortedRowData.map((r) => ({
              eData: r.eData,
              datetime: r.datetime,
              value: get(r, ['eData', headerItem.id, 'value'], get(r, ['eData', headerItem.id, 'id'])),
              label: get(r, ['eData', headerItem.id, 'label'], get(r, ['eData', headerItem.id, 'value'])),
              value_cohort: get(r, ['cohort', valueGetter.value || valueGetter.id], null),
              datetime_cohort: get(r, ['cohort', 'datetime'], ''),
            }));
            const accumulativeConfig = get(view, [ACCUMULATIVE_CONFIG, headerItem.id], '');
            const accumulativeConfigValue = get(mapping, [headerItem.id, 'valueGetter', accumulativeConfig], '');

            const groupedRows = groupBy(metricRows, (i) =>
              valueDimensions.map((valueDimension) => get(i, ['eData', valueDimension, 'value'])).join('////'),
            );

            const currency = get(sortedRowData, ['0', 'currency', valueGetter.value || valueGetter.id], null);

            if (Object.entries(groupedRows).length != 0) {
              for (const [k, v] of Object.entries(groupedRows)) {
                const treeData = valueDimensions.map((valueDimension) => ({
                  label: get(v, [0, 'eData', valueDimension, 'label']),
                  key: valueDimension,
                }));
                const groupedValue = uniq(valueDimensions).reduce(
                  (carry, dimension) => {
                    const data = get(v, [0, 'eData', dimension], {});
                    return {
                      label: carry.label.concat(data.label || []),
                      id: carry.id.concat(data.id || []),
                      value: carry.value.concat(data.value || []),
                    };
                  },
                  { label: [], id: [], value: [] },
                );
                const label = groupedValue.label.join(' - ');
                const value = groupedValue.value.join(' - ');
                const id = groupedValue.id.join(' - ');
                chartMapping.push({
                  // label: metric,
                  key: headerItem.id,
                  data: dates.map((d, index) => {
                    const existed = v.find((i) => i.datetime === d);
                    if (allowAccumulative && accumulativeConfig) {
                      return calculateAccumulative({
                        dates,
                        index,
                        rows: v,
                        currentRow: existed,
                        accumulativeConfig,
                        accumulativeConfigValue,
                        metric: headerItem.id,
                      });
                    }
                    return existed ?? { value: null };
                  }),
                  formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                  formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                  currency,
                  treeData,
                  ...get(v, [0, 'eData', headerItem.id], {}),
                  label: label || id || value || headerItem.name || headerItem.id || '',
                });

                const hasCohort = v.some((el) => el.datetime_cohort);

                if (hasCohort) {
                  chartMapping.push({
                    key: headerItem.id,
                    data: dates.map((d, index) => {
                      const existed = v.find((i) => i.datetime === d);
                      if (allowAccumulative && accumulativeConfig) {
                        return calculateAccumulative(
                          {
                            dates,
                            index,
                            rows: v,
                            currentRow: existed,
                            accumulativeConfig,
                            accumulativeConfigValue,
                            metric: headerItem.id,
                          },
                          true,
                        );
                      }
                      return existed
                        ? { value: existed.value_cohort, datetime: existed.datetime_cohort }
                        : { value: null };
                    }),
                    currency,
                    formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                    formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
                    isCohort: true,
                    treeData,
                    ...get(v, [0, 'eData', headerItem.id], {}),
                    label: label || id || value || headerItem.name || headerItem.id || '',
                  });
                }
              }
            }
          });

          const metric = orderMetric.length === 1 ? orderMetric[0].name || orderMetric[0].id : 'Metric';
          const colKey = orderMetric.length === 1 ? orderMetric[0].id : 'Metric';
          const valueGetter = get(mapping, [get(orderMetric, [0, 'id'], ''), 'valueGetter'], {});
          const labelField = get(valueGetter, ['label'], '');

          let total =
            orderMetric.length === 1 ? get(rowData, ['0', 'total', valueGetter.value || valueGetter.id], null) : 0;
          let cohortTotal =
            orderMetric.length === 1
              ? get(rowData, ['0', 'total_cohort', valueGetter.value || valueGetter.id], null)
              : 0;

          const metricValueGetter = orderMetric.length === 1 ? get(rowData, [0, 'eData', orderMetric[0].id], {}) : {};
          if (labelField.startsWith('=')) {
            total = toValue(labelField, {
              ...metricValueGetter,
              value: total,
              'config.currency': currencyConfig,
            });
            cohortTotal = toValue(labelField, {
              ...metricValueGetter,
              value: cohortTotal,
              'config.currency': currencyConfig,
            });
          } else {
            total = formatCurrencyNumber(total);
            cohortTotal = formatCurrencyNumber(cohortTotal);
          }

          result.push({
            data: { rows: chartMapping, headers: dates },
            total,
            cohortTotal: timelineCohorts ? cohortTotal : null,
            metric,
            colKey,
            isSettingYAxis: true,
            currency: '',
            isColumnStacked,
          });
        }
        break;
      }

      default: {
        orderMetric.forEach((headerItem) => {
          const chartMapping = [];
          const metric = headerItem.id;
          const metricRows = rowData.filter((i) => i.metric === headerItem.id);

          if (Object.entries(groupBy(metricRows, valueDimension)).length === 0) {
            result.push({
              data: { rows: [], headers: [] },
              total: null,
              metric,
              currency: '',
              formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
              formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
              isSettingYAxis: false,
              isColumnStacked,
            });
          } else {
            for (const [k, v] of Object.entries(groupBy(metricRows, valueDimension))) {
              chartMapping.push({
                label: k,
                key: k,
                data: v,
                formatLabel: get(mapping, [headerItem.id, 'valueGetter', 'label'], '=p("value")'),
                formatTopLabel: get(mapping, [headerItem.id, 'valueGetter', 'topLabel'], ''),
              });
            }

            const currency = uniq(metricRows.map((i) => i.unit));
            const total = rowData.reduce((a, b) => {
              if (b.metric === headerItem.id) {
                a += b.value;
              }
              return a;
            }, 0);

            result.push({
              data: { rows: chartMapping, headers: uniq(rowData.map((i) => i.datetime)).sort() },
              total: null,
              metric,
              currency: currency[0],
              isSettingYAxis: false,
              isColumnStacked,
            });
          }
        });
        break;
      }
    }

    return result;
  }, [rowData, backbone, chartConfig, groupPeriod]);

  const requestSuccess = React.useCallback(async ({ rowData, rowCount, summary }) => {
    console.info('etable[evisualization]', rowData, rowCount, summary);
    setLastUpdatedAt({ value: moment() });
    setSummary(summary);
    setRowData(rowData);
    setLoadingChart(false);
  }, []);

  const requestFail = React.useCallback(async () => {
    setLoadingChart(false);
    setIsRequestFailed(true);
  }, []);

  const reload = () => {
    setIsRequestFailed(false);
    gridApi.refreshServerSideStore();
  };

  const gridApi = useEtableGridApi(backbone, {
    beforeRequest: React.useCallback(() => {
      setLoadingChart(true);
    }, []),
    getRows: { success: requestSuccess, fail: requestFail },
  });

  React.useEffect(() => {
    backbone.setGridApi({
      grid: gridApi,
      column: {},
    });
    backbone.init();
    window.requestAnimationFrame(() => {
      chartRef.current.isInit = true;
      gridApi.refreshServerSideStore();
    });
  }, []);

  React.useEffect(() => {
    if (!groupDimension) {
      const valueDimension = backbone.getConfig('chartConfig', { config: {} }).config?.valueDimension;
      if (valueDimension) {
        backbone.changeConfig('groupBy', { columns: [valueDimension] });
      }
    }
  }, [groupDimension]);

  React.useEffect(() => {
    backbone.getCallback('onBackboneReady')(backbone);
  }, []);

  React.useEffect(() => {
    let tid = 0;
    if (get(config, 'refreshInterval', null)) {
      console.info('auto refresh');
      tid = window.setInterval(() => {
        gridApi.refreshServerSideStore();
      }, get(config, 'refreshInterval'));
    }
    return () => {
      window.clearInterval(tid);
    };
  }, [config]);

  return {
    charts,
    viewByDimenionField,
    chartRef,
    loadingChart,
    chartConfig,
    summary,
    isRequestFailed,
    reload,
  };
};
