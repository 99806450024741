import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Arrowdowncustom = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="4" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.15772 0.172117C7.27024 0.286826 7.33329 0.441252 7.33329 0.602117C7.33329 0.762982 7.27024 0.917408 7.15772 1.03212L4.42401 3.81878C4.36997 3.87566 4.30496 3.92095 4.23293 3.95189C4.1609 3.98283 4.08335 3.99878 4.00498 3.99878C3.92661 3.99878 3.84906 3.98283 3.77703 3.95189C3.705 3.92095 3.63999 3.87566 3.58594 3.81878L0.852241 1.03212C0.793509 0.974951 0.746819 0.906553 0.714937 0.830974C0.683054 0.755396 0.666626 0.674174 0.666626 0.592117C0.666626 0.510061 0.683054 0.428838 0.714937 0.353259C0.746819 0.277681 0.793509 0.209283 0.852241 0.152117C0.968181 0.0477597 1.1205 -0.00656777 1.27615 0.000920613C1.4318 0.00840899 1.57822 0.0771095 1.68366 0.192117L4.00498 2.53212L6.313 0.172117C6.42602 0.0617588 6.57757 0 6.73536 0C6.89314 0 7.04469 0.0617588 7.15772 0.172117Z"
      fill="#8D979E"
    />
  </SvgIcon>
);

export default Arrowdowncustom;
