import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Arrow = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.99312 11.8301L5.99312 4.25373L7.2818 5.54311C7.46991 5.73132 7.72505 5.83706 7.99107 5.83706C8.2571 5.83706 8.51224 5.73132 8.70035 5.54311C8.88846 5.3549 8.99414 5.09963 8.99414 4.83345C8.99414 4.56728 8.88846 4.31201 8.70035 4.1238L5.70341 1.12525C5.61055 1.03156 5.50006 0.957204 5.37832 0.906459C5.25659 0.855715 5.12602 0.82959 4.99414 0.82959C4.86226 0.82959 4.73169 0.855715 4.60996 0.906459C4.48822 0.957204 4.37773 1.03156 4.28487 1.12525L1.28793 4.1238C1.19479 4.21699 1.1209 4.32763 1.07049 4.44939C1.02008 4.57115 0.99414 4.70166 0.99414 4.83345C0.99414 4.96525 1.02008 5.09575 1.07049 5.21752C1.1209 5.33928 1.19479 5.44992 1.28793 5.54311C1.38107 5.6363 1.49165 5.71023 1.61335 5.76066C1.73505 5.8111 1.86548 5.83706 1.99721 5.83706C2.12893 5.83706 2.25936 5.8111 2.38106 5.76066C2.50276 5.71023 2.61334 5.6363 2.70648 5.54311L3.99516 4.25373L3.99516 11.8301C3.99516 12.0952 4.10041 12.3494 4.28776 12.5368C4.4751 12.7243 4.72919 12.8296 4.99414 12.8296C5.25909 12.8296 5.51318 12.7243 5.70052 12.5368C5.88787 12.3494 5.99312 12.0952 5.99312 11.8301Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Arrow;
