import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Checked = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.71402 2.30066C1.62206 2.20534 1.51205 2.12931 1.39042 2.07701C1.26879 2.0247 1.13797 1.99717 1.0056 1.99602C0.873227 1.99487 0.741951 2.02012 0.619431 2.0703C0.496911 2.12048 0.385602 2.19458 0.291996 2.28829C0.198391 2.38199 0.124366 2.49342 0.0742387 2.61607C0.0241118 2.73872 -0.00111264 2.87013 3.76407e-05 3.00265C0.00118792 3.13516 0.0286892 3.26612 0.0809377 3.38787C0.133186 3.50963 0.209136 3.61976 0.304353 3.71182L2.29823 5.7078C2.48518 5.8949 2.73871 6 3.00306 6C3.26741 6 3.52094 5.8949 3.7079 5.7078L7.69565 1.71584C7.79086 1.62377 7.86681 1.51365 7.91906 1.39189C7.97131 1.27013 7.99881 1.13918 7.99996 1.00666C8.00111 0.874151 7.97589 0.742736 7.92576 0.620087C7.87563 0.497437 7.80161 0.38601 7.708 0.292306C7.6144 0.198602 7.50309 0.124498 7.38057 0.0743177C7.25805 0.0241378 7.12677 -0.00111382 6.9944 3.76799e-05C6.86203 0.00118918 6.73121 0.0287201 6.60958 0.0810238C6.48795 0.133328 6.37794 0.209357 6.28598 0.304676L3.00306 3.59106L1.71402 2.30066Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Checked;
