import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Addfilter = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.00174 3H12.9983C13.5513 3 14 3.44843 14 4.00125C14 4.55407 13.5513 5.00251 12.9983 5.00251H3.00174C2.44865 5.00251 2 4.55407 2 4.00125C2 3.44843 2.44865 3 3.00174 3Z"
      fill={props.color}
    />
    <path
      d="M3.00171 6.99832L7.99621 6.99832C8.5493 6.99832 8.99795 7.44676 8.99795 7.99957C8.99795 8.55239 8.5493 9.00083 7.99621 9.00083H3.00171C2.44862 9.00083 1.99997 8.55239 1.99997 7.99957C1.99997 7.44592 2.44862 6.99832 3.00171 6.99832Z"
      fill={props.color}
    />
    <path
      d="M3.00174 11H4.99854C5.55163 11 6.00028 11.4484 6.00028 12.0013C6.00028 12.5541 5.55163 13.0025 4.99854 13.0025H3.00174C2.44865 13.0025 2 12.5541 2 12.0013C2.00084 11.4484 2.44865 11 3.00174 11Z"
      fill={props.color}
    />
    <path
      d="M10.0013 14.001C10.0013 14.5521 10.4513 15.0029 11.0013 15.0029C11.5513 15.0029 11.993 14.5521 12.0013 14.001V13.0077H12.9986C13.5494 13.0077 14 12.5575 14 12.0072C14 11.457 13.5494 11.0068 12.9986 11.0068H12.0013V10.0019C12.0013 9.45084 11.5513 9 11.0013 9C10.4513 9 10.0013 9.45084 10.0013 10.0019V11.0068H9.00139C8.45063 11.0068 8 11.457 8 12.0072C8 12.5575 8.44228 12.9994 8.99305 13.0077H10.0013V14.001Z"
      fill={props.color}
    />
  </SvgIcon>
);
export default Addfilter;
