import { useLog } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import { get } from 'lodash';

const log = useLog('lib:table-backbone:callback');

export function callbackGenerate(config: Record<string, any>) {
  const mainBtnConfig = get(config, 'mainButton', {
    text: '',
    type: 'link',
    url: '/page',
  }) || {
    text: '',
    type: 'link',
    url: '/page',
  };

  let cbClickMainButton = () => undefined;
  switch (mainBtnConfig.type) {
    case 'link': {
      cbClickMainButton = () => {
        window.location.href = getPageUrn(mainBtnConfig.url);
      };
      break;
    }
    case 'function': {
      cbClickMainButton = () => {
        log('todo');
      };
      break;
    }
    case 'dialog': {
      cbClickMainButton = () => {
        console.log('open dialog');
      };
      break;
    }
    case 'compactTable': {
      cbClickMainButton = () => {
        log('todo');
      };
      break;
    }
  }

  return {
    clickMainButton: cbClickMainButton,
  };
}
