import { Box, makeStyles } from '@material-ui/core';
import * as React from 'react';
import { DropdownCell } from '../dropdown-cell';
import { IPropsFormat } from './wrapper-format';
import Dropdown from '../../dropdown-menu/dropdown';
import { CAMPAIGN_RES } from '../mocks/getCampaignData-100';
import { IConfigTable, makeTable } from '../table-container';
import { CAMPAIGN_SETTING_CONFIG } from '../mocks/table-config/campaign-settings';
import * as _ from 'lodash';
const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  dropdown: {
    width: '100%',
    textAlign: 'right',
  },
}));

interface ICellAction {
  title: string;
  name: string;
  icon: string;
  component: React.ReactNode | unknown;
  onSubmit: (info) => void;
}
// export interface IAdditionFormat {
//   //   stamp: number;
// }
function AdditionFormat(props: IPropsFormat<any>) {
  console.log(props.value.config ? 'MakeTable' : '', 'AdditionFormat', props.value);

  const value = _.get(props, 'value', null);
  const node = _.get(props, 'node', null);
  const cellAction = _.get(props, 'cellAction', []);
  const classes = useStyles();
  // const [isHovering, setIsHovering] = React.useState(false);

  const table = React.useMemo(() => {
    if (props.value.config) {
      const config: IConfigTable = {
        apiRequest: {
          getTableData: (params) => {
            return Promise.resolve(CAMPAIGN_RES);
          },
        },
        configuration: props.value.config,
        tableType: 'compact',
        callback: {
          onRowSelect: (rows) => {
            console.log('onRowSelect 123 ', rows);
            // setRows(rows);
          },
        },
      };
      // {}
      // return <div>aaaa</div>;
      return <div>{makeTable({ config: config })}</div>;
    }
    return null;
  }, [props.value.config]);

  return (
    <div className={classes.container}>
      <Box width={'100%'} display={'flex'} justifyContent={'flex-end'} pr={'10px'}>
        <Dropdown disabledClickContentClose alignMenu="right" icon="add" label="">
          <Box height={'60vh'} width={'75vw'}>
            {table}
          </Box>
        </Dropdown>
      </Box>
    </div>
  );
}
export default AdditionFormat;
