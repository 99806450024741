import { eipRequest, useLog, EIP_CONSTANT } from '@eip/next/lib/main';
import { get } from 'lodash';
import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';
import * as dataSource from '../../ha-data-source/common';
import { createDataQueryFromConfig } from '../../table-backbone/table-datasource';
import moment from 'moment';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import { INPUT_SPECIAL_KEY_DISABLE } from '@ep/insight-ui/elements/textField/special-key';

const log = useLog('lib:workflow-backbone:programmatic-script');

const validation = {
  total_budget: `ev_budget_is_no_limit(#_.total_budget)
  OR ev_shop_budget_min(#_.shop_eid, "Budget should above {min_budget}");`,
  daily_budget: `ev_budget_is_no_limit(#_.daily_budget)
  OR ev_shop_budget_min(#_.shop_eid, "Budget should above {min_budget}");`,
  campaign_name: `ev_required(#_.campaign_name, "Campaign name is required.")
  AND ev_no_duplicate_campaign_name(#_.campaign_name, #_.shop_eid, "Campaign name is duplicated.");`,
  schedule_start_date: `ev_cond(#_.schedule_start_date > time("now"), "Start date is invalid");`,
};

const defaultDataRepo = dataSource;

function screenInfo(data: typeof dataSource, tableConfig) {
  const COMPACT_PROGRAMMATIC_KEYWORD_CONFIG = tableConfig.ADS_OPS_AD_PLACEMENT;
  const COMPACT_PROGRAMMATIC_OBJECT_CONFIG = tableConfig.ADS_OPS_AD_OBJECT;
  const screen = {
    screenId: 'screenInfo',
    inputOnDemand: {
      __init__: async () => {
        return Promise.all([
          data.default.getStorefront(),
          data.default.getAdType(),
          data.default.getAdTool({ storefront: null }),
          data.default.getScriptTarget(),
          data.default.getRepeating(),
        ])
          .then(([storefront, adType, adTool, scriptTarget, repeating]) => {
            const marketplaceCountryData = get(storefront, ['data'], []).reduce(
              (carry, storefrontData) => {
                const marketplace = get(storefrontData, ['channel'], '');
                const marketplaceCode = get(marketplace, ['code'], '');
                const country = get(storefrontData, ['country'], '');
                const countryCode = get(country, ['code'], '');
                const currency = get(storefrontData, ['currency'], '');
                const foundMarketplaceData = carry.marketplaces.some((el) => el.value === marketplaceCode);
                const foundCountryData = carry.countries.some((el) => el.value === countryCode);

                if (!foundMarketplaceData && marketplaceCode) {
                  carry.marketplaces.push({
                    label: marketplace.name,
                    value: marketplace.code,
                  });
                }
                if (!foundCountryData && countryCode) {
                  carry.countries.push({
                    label: country.name,
                    value: country.code,
                    payload: {
                      currency,
                    },
                  });
                }
                return carry;
              },
              {
                marketplaces: [],
                countries: [],
              },
            );
            return {
              marketplace: marketplaceCountryData.marketplaces,
              country: marketplaceCountryData.countries,
              storefront: storefront.data,
              adType: adType.data,
              adTool: adTool.data,
              scriptTarget: scriptTarget.data,
              programmaticObject: [],
              existingScript: [],
              repeating: repeating.data,
            };
          })
          .catch((err) => console.error(err));
      },
      getMarketplace: data.default.getMarketplace,
      getCountry: data.default.getCountry,
      getStorefront: data.default.getStorefront,
      getAdType: data.default.getAdType,
      getScriptTarget: data.default.getScriptTarget,
      getAdTool: data.default.getAdTool,
      ...(ff.script_interval ? { getInterval: data.getInterval } : {}),
      getRepeating: data.default.getRepeating,
      getCompactTableConfiguration: async ({ scriptTarget, storefront, marketplace }) => {
        log('compactableconfiguration', { scriptTarget, storefront });
        const isKeywordFocus = scriptTarget === 'AdsPlacement';
        return {
          programmaticObject: {
            displayField: isKeywordFocus ? 'ads_placements.name' : 'ads_objects.name', // 'ADS_OBJECT.name',
            idField: isKeywordFocus ? 'ads_placements.id' : 'ads_objects.id',
            config: isKeywordFocus ? COMPACT_PROGRAMMATIC_KEYWORD_CONFIG : COMPACT_PROGRAMMATIC_OBJECT_CONFIG,
          },
        };
      },
      getProgrammaticObject: async ({
        scriptTarget,
        objectIds,
        marketplace = 'SHOPEE',
      }: {
        scriptTarget: string;
        objectIds: string[];
        marketplace: string;
      }) => {
        const isAdsPlacement = scriptTarget != 'AdsObject';
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const payload = {
          dimensions: [isAdsPlacement ? 'ads_placements' : 'ads_objects'],
          attributes: isAdsPlacement
            ? ['ads_placements.id', 'ads_placements.name']
            : ['ads_objects.id', 'ads_objects.name'],
          metrics: [],
          filter: {
            combinator: 'AND',
            filters: [
              {
                field: isAdsPlacement ? 'ads_placements.id' : 'ads_objects.id',
                operator: 'IN',
                dataType: 'string',
                value: objectIds,
              },
            ],
          },
          from: date,
          to: date,
          hiddenFilter: {},
          isSummary: true,
          pagination: {
            page: 1,
            limit: objectIds.length,
          },
          sort: null,
        };

        return eipRequest
          .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=automation', payload)
          .then((res) => {
            const result = produceQueryResult({
              ...res,
              data: {
                ...res.data,
                masterData: res.masterData,
                masterDataPrimaryKey: res.masterDataPrimaryKey,
              },
            });

            return get(result, ['rows'], []);
          });
      },
      getStorefrontScripts: data.default.getStorefrontScripts,
    },
    validation: validation,
  };

  return screen as WorkflowRegisterScreen<(typeof screen)['inputOnDemand']>;
}
export type ScreenInfoRegister = ReturnType<typeof screenInfo>;

function screenScenario_0(data: typeof dataSource) {
  const screen = {
    screenId: 'screenScenario_0',
    inputOnDemand: {
      __init__: async (workflow?: Workflow) => {
        const { scriptTarget, adTool, storefront, marketplace } = workflow.data.get(['screenInfo'], {
          scriptTarget: null,
          adType: null,
          adTool: null,
          storefront: null,
        });
        const targetObject = await data.default.getScriptTarget();
        return {
          attribute: await data.conditionOptions.getAttributeOptions({
            adsUnit: scriptTarget,
            adTool: adTool,
            marketplace: marketplace,
          }),
          targetObject: targetObject.data,
          timeInclusive: await data.conditionOptions.getTimeInclusiveOptions(),
          timeQuery: await data.conditionOptions.getTimeQueryOptions({}),
          timeUnit: await data.conditionOptions.getTimeUnitOptions({}),
          conditionOperator: await data.conditionOptions.getConditionOperatorOptions({}),
          actionUnit: await data.conditionOptions.getActionUnit({}),
          actionObject: await data.conditionOptions.getActionObject({}),
          actionType: await data.conditionOptions.getActionType({}),
        };
      },
      getTargetObjectOptions: () => data.default.getScriptTarget(),
      ...data.conditionOptions,
      getAttributeOptions: data.conditionOptions.getAttributeOptions,
      getActionObject: data.conditionOptions.getActionObject,
    },

    validation: validation,
  };

  return screen as WorkflowRegisterScreen<(typeof screen)['inputOnDemand']>;
}

export type ScreenScenarioRegister = ReturnType<typeof screenScenario_0>;

export function createRegister(tableConfig, data = defaultDataRepo, workflowId = ''): WorkflowRegister {
  const _screenInfo = screenInfo(data, tableConfig);
  const _screenScenario = screenScenario_0(data);

  async function fromDraft(parentId, entityId, templatePageId) {
    let draftId = entityId;
    return {
      entityId: 'default',
      blockId: 'unknown',
    };
    if (draftId) {
      // load draft 1) all correct 2) incorrect blockId
      return { entityId, blockId: templatePageId };
    } else {
      draftId = 'draft' + nanoid();
      const blockId = uuid();
      // create new draft
      return data.createUserDraft({ parentId, entityId: draftId, blockId, templatePageId }).then(async () => {
        await dataSource.updateUserMenu({
          parentId: parentId,
          blockId: blockId,
        });
        return {
          entityId: draftId,
          blockId: blockId,
        };
      });
    }
  }

  const HyperAutomationDetailWorkflowRegister: WorkflowRegister = {
    input: {},
    onSubmit: async (data: WorkflowSubmitDataScript, options?: { operation: 'UPDATE' | 'CREATE' }) => {
      log('submit data', data);
      const screenInfo = get(data, ['screenInfo'], {});
      const screenScenario0 = get(data, ['screenScenario_0'], {});
      const screenScenarioConditions = get(screenScenario0, ['condition', 'filters'], []).map((condition) => {
        return {
          metricCode: get(condition, ['attribute'], ''),
          rangeCode: get(condition, ['timeQuery'], ''),
          timingCode: get(condition, ['timeUnit'], ''),
          customTimingCode: get(condition, ['timeInclusive'], ''),
          period: get(condition, ['timeQuantity'], ''),
          operatorCode: get(condition, ['conditionOperator'], ''),
          valueCompare: get(condition, ['value'], ''),
        };
      });
      const screenScenarioAction = get(screenScenario0, ['action'], {});
      const payload = {
        programmaticScript: {
          scriptName: get(screenInfo, ['scriptName'], ''),
          scriptStatus: get(screenInfo, ['scriptStatus'], 'ONGOING'),
        },
        objectIds: get(screenInfo, ['programmaticObject'], [])
          .map((el) => (el ? String(el) : ''))
          .filter((el) => el)
          .join(','),
        objectType: get(screenInfo, ['scriptTarget'], ''),
        storefrontId: get(screenInfo, ['storefront'], ''),
        adsTool: get(screenInfo, ['adTool'], ''),
        ...(options.operation === 'UPDATE' ? { scriptId: get(screenInfo, ['scriptId'], '') } : {}),
        scenarioCondition: [
          {
            programmaticScriptCondition: screenScenarioConditions,
            programmaticScriptScenario: {
              scenarioName: 'Scenario #1',
              timelineFrom: get(screenScenario0, ['timeline_from'], ''),
              timelineTo: get(screenScenario0, ['timeline_to'], '') || '9999-12-31 23:59:59',
              logicCondition: get(screenScenario0, ['condition', 'filters', 1, 'combinator'], 'and'),
              actionSelectionCode: get(screenScenarioAction, ['type'], ''),
              actionObjectCode: get(screenScenarioAction, ['object'], ''),
              stepValueCode: get(screenScenarioAction, ['unit'], ''),
              stepValue: String(get(screenScenarioAction, ['value'], '')).replace(INPUT_SPECIAL_KEY_DISABLE, ''),
              untilReaching: String(get(screenScenarioAction, ['maxValue'], '')).replace(INPUT_SPECIAL_KEY_DISABLE, ''),
              repeating: get(screenScenario0, ['repeating'], ''),
              repeatingOn: get(screenScenario0, ['repeatingOn'], ''),
              repeatingAt: get(screenScenario0, ['repeatingAt'], ''),
            },
          },
        ],
      };
      const result = await dataSource.createScript(payload);
      return {
        entityId: result.scriptId || result.data?.scriptId,
      };
    },
    init: async (input: { entityId?: string; parentId?: string; pageId?: string; context?: Record<string, any> }) => {
      if (input.entityId && input.entityId.indexOf('default') !== 0) {
        return dataSource.loadScript({ entityId: input.entityId }).then((script) => {
          return { ...script, title: script.screenInfo?.scriptName };
        });
      } else {
        const splitted = window.location.href.split('?');
        const queryParams: any = (splitted[splitted.length - 1] || '').split('&').reduce((a, b) => {
          const [key, value] = b.split('=');
          return {
            ...a,
            [key]: value,
          };
        }, {});
        const cloneId = get(input, 'context.cloneId', get(queryParams, 'cloneId', null));
        if (cloneId) {
          return dataSource.loadScript({ entityId: cloneId }, tableConfig).then((script) => {
            return {
              ...script,
              title: script.screenInfo.scriptName,
              screenInfo: {
                ...script.screenInfo,
                scriptId: null,
                scriptStatus: null,
              },
            };
          });
        }
        return { entityId: 'default' };
      }
    },
    screens: { screenInfo: _screenInfo, screenScenario_0: _screenScenario },
  };

  return HyperAutomationDetailWorkflowRegister;
}
