import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const RefreshRounded = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.4993 6.99993C12.775 6.99993 13.0006 6.77437 13.0006 6.49868V3.49952C13.0006 3.05674 12.4659 2.83118 12.1484 3.14864L11.1655 4.13161C10.2685 3.39815 9.11047 2.96805 7.85437 3.00662C5.19773 3.08181 3.03399 5.27061 3.00057 7.92725C2.9588 10.7176 5.21444 12.9983 7.99639 12.9983C10.3272 12.9983 12.2821 11.4026 12.8418 9.24722C13.0006 8.6123 12.5244 7.99409 11.8728 7.99409C11.4216 7.99409 11.0206 8.29484 10.9037 8.73761C10.5778 10.0409 9.3999 11.0016 7.99639 11.0016C6.26707 11.0016 4.88027 9.53962 5.00558 7.78523C5.10583 6.31489 6.30049 5.12024 7.77083 5.01163C8.50688 4.9554 9.18976 5.16813 9.7372 5.55989L9.14928 6.1478C8.83182 6.45691 9.05738 6.99993 9.50016 6.99993H12.4993Z"
      fill="#253746"
    />
  </SvgIcon>
);

export default RefreshRounded;
