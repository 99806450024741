import React from 'react';
import { useLog } from '../../log';

const log = useLog('context:NodeEdit');

export const NodeEditContext = React.createContext({
  onTextChange: (nodeData: NodeData, contentId: string, contentValue: string) => {
    console.info('onTextChange', { contentId, contentValue });
  },
  isEditMode: false,
  onUpdateCustomAttributes: (nodeData: NodeData, attributes: NodeData['customAttributes']) => {
    log('onUpdateCustomAttributes', nodeData, attributes);
  },
});

export const TableContext = React.createContext({
  customAttributes: {},
  builtinEntities: {},
  onExportData: (fileType: string) => {
    console.log('Export file ' + fileType);
  },
  onUpdateCustomAttributes: (attributes: NodeData['customAttributes']) => {
    console.log('Update table custom attributes', attributes);
  },
  onRemove: () => {
    console.log('Remove table');
  },
  onDuplicate: () => {
    console.log('Duplicate table');
  },
});
