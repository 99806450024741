import * as React from 'react';
import { UaMonit } from './uamonit';
import { uaType } from './watchlist';

const dumpUaMonit = new UaMonit('dump', (label, context) => {
  console.info('dumpit', context);
});

export const UaWatchlist = React.createContext<{
  start: (type: uaType, context?) => void;
  currentSession: () => UaMonit;
}>({
  start: (type, context) => {
    console.info('missing setup', type, context);
    return Promise.resolve();
  },
  currentSession: () => dumpUaMonit,
});

export function UaMonitProvider({ children }: { children: JSX.Element }) {
  const currentSession = React.useRef<UaMonit>(dumpUaMonit);
  const uawl = window.uaWatchlist;

  const getCurrentSession = React.useCallback<() => UaMonit>(() => {
    return currentSession.current;
  }, []);

  const start = React.useCallback((type, context?) => {
    if (!uawl) {
      currentSession.current = dumpUaMonit;
      return;
    }
    return uawl.start(type, context).then((instance) => {
      currentSession.current = instance;
    });
  }, []);

  return <UaWatchlist.Provider value={{ start, currentSession: getCurrentSession }}>{children}</UaWatchlist.Provider>;
}
