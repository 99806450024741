import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ThreeDotsVertical = (props: SvgIconProps) => (
  <SvgIcon {...props} width="2" height="10" viewBox="0 0 2 10" fill="none">
    <path d="M2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z" />
    <path d="M2 5C2 5.55228 1.55228 6 1 6C0.447715 6 0 5.55228 0 5C0 4.44772 0.447715 4 1 4C1.55228 4 2 4.44772 2 5Z" />
    <path d="M2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9Z" />
  </SvgIcon>
);

export default ThreeDotsVertical;
