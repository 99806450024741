import * as React from 'react';
import { createGlobalStyle } from 'styled-components';

const PrintStyle = createGlobalStyle`
@page {
  size: ${(props: { width: number; height: number }) =>
    `${Math.ceil(props.width * 0.26458333) + 40}mm ${
      Math.ceil(props.height * 0.26458333) + 40
    }mm`};
  margin: 0mm;
}
`;

export function PrintControl() {
  const [printSize, setPrintSize] = React.useState({
    width: 0,
    height: 0,
  });

  const setupLayout = React.useCallback(() => {
    const el: HTMLDivElement = document.querySelector(
      '.dashboard-container',
    );
    setPrintSize({
      width: el.offsetWidth,
      height: el.offsetHeight,
    });
  }, []);

  React.useEffect(() => {
    window.addEventListener('beforeprint', setupLayout);
    return () => {
      window.removeEventListener('beforeprint', setupLayout);
    };
  });

  return <PrintStyle {...printSize} />;
}
