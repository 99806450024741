import { Button, makeStyles } from '@material-ui/core';
import * as React from 'react';

const useStyles = makeStyles({
  button: {
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
  },
});

interface IUploadInputProps {
  disabled?: boolean;
  onChange?: (v: any) => void;
  onRef?: (ref: React.MutableRefObject<HTMLInputElement>) => void;
}

const UploadInput = ({ onChange = () => undefined, onRef = () => undefined, disabled }: IUploadInputProps) => {
  const classes = useStyles();
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (typeof onRef === 'function') onRef(inputRef);
  }, []);

  const handSelectImage = (e) => {
    const newImage = e.target?.files?.[0];
    if (newImage) {
      onChange(newImage);
    }
  };

  return (
    <label htmlFor="upload-photo">
      {disabled ? null : (
        <input
          style={{ display: 'none' }}
          id="upload-photo"
          name="upload-photo"
          accept="image/*"
          type="file"
          ref={inputRef}
          onChange={handSelectImage}
        />
      )}

      <Button color="secondary" variant="contained" component="span" className={classes.button} disabled={true}>
        Upload photo
      </Button>
    </label>
  );
};
export default UploadInput;
