import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Maintenance = (props: SvgIconProps) => (
  <SvgIcon width="283" height="155" viewBox="0 0 283 155" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_273)">
      <path
        d="M137.161 141.508C176.233 141.508 207.907 109.83 207.907 70.754C207.907 31.6777 176.233 0 137.161 0C98.0886 0 66.4144 31.6777 66.4144 70.754C66.4144 109.83 98.0886 141.508 137.161 141.508Z"
        fill="#CCE3F5"
      />
      <path
        d="M56.375 112.485C81.4557 112.485 101.788 92.1507 101.788 67.0673C101.788 41.9839 81.4557 21.6498 56.375 21.6498C31.2943 21.6498 10.9624 41.9839 10.9624 67.0673C10.9624 92.1507 31.2943 112.485 56.375 112.485Z"
        fill="#CCE3F5"
      />
      <path
        d="M241.579 121.201C259.314 103.465 259.314 74.7085 241.579 56.9721C223.845 39.2356 195.091 39.2356 177.357 56.9721C159.622 74.7085 159.622 103.465 177.357 121.201C195.091 138.938 223.845 138.938 241.579 121.201Z"
        fill="#CCE3F5"
      />
      <path
        d="M232.3 61.2627C239.187 61.2627 244.771 55.6787 244.771 48.7905C244.771 41.9023 239.187 36.3183 232.3 36.3183C225.412 36.3183 219.829 41.9023 219.829 48.7905C219.829 55.6787 225.412 61.2627 232.3 61.2627Z"
        fill="#EBF6FF"
      />
      <path
        d="M72.4537 20.4732C76.9587 20.4732 80.6107 16.8208 80.6107 12.3153C80.6107 7.80979 76.9587 4.15738 72.4537 4.15738C67.9487 4.15738 64.2967 7.80979 64.2967 12.3153C64.2967 16.8208 67.9487 20.4732 72.4537 20.4732Z"
        fill="#EBF6FF"
      />
      <path
        d="M44.061 143.469L42.9629 139.782L42.5708 138.449L36.7668 119.388L33.7079 109.112L29.0019 93.5804H28.9235H23.1194H22.8057L19.9037 103.778L17.6291 111.936L15.3546 120.015L13.2369 127.389L8.68779 143.469H4.60928V149.509H47.9042V143.469H44.061Z"
        fill="#253746"
      />
      <path d="M281.473 145.762H0.5V155H281.473V145.762Z" fill="#253746" />
      <path
        d="M273.006 144.489L271.908 140.802L271.516 139.469L265.712 120.329L262.575 110.132L257.947 94.6002H257.869H251.986H251.751L248.849 104.798L246.575 112.877L244.222 121.035L242.182 128.408L237.633 144.489H233.555V150.529H276.85V144.489H273.006Z"
        fill="#253746"
      />
      <path d="M25.0018 143.861L43.512 78.4413H51.2768L71.0419 143.783L25.0018 143.861Z" fill="#ED5C10" />
      <path d="M75.4342 141.822H20.3743V149.588H75.4342V141.822Z" fill="#ED5C10" />
      <path d="M57.2377 98.13L39.8256 91.3841L36.9236 101.66L61.1594 111.151L57.2377 98.13Z" fill="#F2F3F4" />
      <path d="M34.0216 112.014L31.0412 122.29L69.0027 137.194L65.081 124.173L34.0216 112.014Z" fill="#F2F3F4" />
      <path
        opacity="0.18"
        d="M69.1595 137.194L68.6105 135.468L61.2378 111.151L57.3162 98.1301L51.3553 78.4413H43.9041L48.8454 94.8355L49.865 98.1301L52.7671 107.857L53.7867 111.151L56.6887 120.8L60.6103 133.821L61.1594 135.468L61.63 137.194L63.0418 141.822H68.0615H70.5713L69.1595 137.194Z"
        fill="#8C98A4"
      />
      <path d="M195.593 62.5961L211.75 149.666H203.044L187.436 62.5961H195.593Z" fill="#3F4F5C" />
      <path d="M191.75 62.5961L207.986 149.666H199.28L183.671 62.5961H191.75Z" fill="#8C98A4" />
      <path d="M105.16 62.5961L121.396 149.666H112.69L97.0816 62.5961H105.16Z" fill="#253746" />
      <path d="M101.317 62.5961L117.553 149.666H108.847L93.2384 62.5961H101.317Z" fill="#8C98A4" />
      <path d="M84.2971 62.5961L68.1399 149.666H76.8459L92.3756 62.3608L84.2971 62.5961Z" fill="#3F4F5C" />
      <path d="M87.5913 106.837H98.4934L87.5913 93.5804V106.837Z" fill="white" />
      <path d="M124.768 62.5961L161.161 106.837H178.652V102.523L145.788 62.5961H124.768Z" fill="white" />
      <path d="M87.5913 62.5961V67.9301L119.592 106.837H140.063L103.67 62.5961H87.5913Z" fill="white" />
      <path d="M178.652 62.5961H166.887L178.652 76.8725V62.5961Z" fill="white" />
      <path d="M87.5913 93.5805L98.4934 106.837H119.592L87.5913 67.9301V93.5805Z" fill="#ED5C10" />
      <path d="M103.67 62.5961L140.063 106.837H161.161L124.768 62.5961H103.67Z" fill="#ED5C10" />
      <path d="M166.887 62.5961H145.788L178.652 102.523V76.8724L166.887 62.5961Z" fill="#ED5C10" />
      <path d="M181.554 109.661L178.652 106.837H87.5913L90.4933 109.739L181.554 109.661Z" fill="#8C98A4" />
      <path d="M182.652 62.5961L166.416 149.666H175.122L190.809 62.5961H182.652Z" fill="#3F4F5C" />
      <path d="M172.377 126.212H74.3361V133.429H172.377V126.212Z" fill="#253746" />
      <path d="M171.201 122.839H73.1596V130.056H171.201V122.839Z" fill="#C2C7CB" />
      <path d="M79.5127 62.5961L63.3555 149.666H72.0615L87.5913 62.3608L79.5127 62.5961Z" fill="#C2C7CB" />
      <path d="M178.652 62.5961L162.495 149.666H171.201L186.652 62.5961H178.652Z" fill="#C2C7CB" />
      <path d="M198.887 143.861L217.476 78.4413H225.241L245.006 143.783L198.887 143.861Z" fill="#ED5C10" />
      <path d="M249.626 141.656H194.15V150.894H249.626V141.656Z" fill="#ED5C10" />
      <path d="M231.202 98.13L213.79 91.3841L210.888 101.66L235.123 111.151L231.202 98.13Z" fill="#F2F3F4" />
      <path d="M207.986 112.014L205.005 122.29L242.967 137.194L239.045 124.173L207.986 112.014Z" fill="#F2F3F4" />
      <path
        opacity="0.18"
        d="M243.045 137.194L242.575 135.468L235.202 111.151L231.28 98.1301L225.319 78.4413H217.79L222.809 94.8355L223.829 98.1301L226.731 107.857L227.751 111.151L230.653 120.8L234.574 133.821L235.045 135.468L235.594 137.194L237.006 141.822H241.947H244.457L243.045 137.194Z"
        fill="#8C98A4"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_273">
        <rect width="282" height="155" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Maintenance;
