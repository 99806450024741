import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueSingleBudgetFormat } from '@ep/insight-ui/elements/table/format/budgets/single-budget-format';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        height: '32px',
      },
    },
    width: '100%',
  },
}));

const defaultOptions: OptionSelectType[] = [
  {
    label: 'USD',
    value: 'USD',
  },
  {
    label: 'IDR',
    value: 'IDR',
  },
  {
    label: 'PHP',
    value: 'PHP',
  },
  {
    label: 'VND',
    value: 'VND',
  },
  {
    label: 'MYR',
    value: 'MYR',
  },
  {
    label: 'SGD',
    value: 'SGD',
  },
  {
    label: 'THB',
    value: 'THB',
  },
  {
    label: 'TWD',
    value: 'TWD',
  },
];

export type ICurrencyForm = {
  title?: string;
  options?: OptionSelectType[];
  value?: string;
  onClose?: () => void;
  onSubmit?: (value: string) => void;
  submitLabel?: string;
};
export const CurrencyForm = ({
  title = 'Currency',
  options = defaultOptions,
  value = '',
  onClose = () => undefined,
  onSubmit = () => undefined,
  submitLabel = 'Apply now',
}: ICurrencyForm) => {
  const classes = useStyles();
  const [currentStatus, setCurrentStatus] = React.useState<string>(value);
  React.useEffect(() => {
    if (value != currentStatus) setCurrentStatus(value);
  }, [value]);
  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentStatus(e.target.value);
  };
  const handleSubmit = () => {
    onSubmit(currentStatus);
    onClose();
  };
  return (
    <div style={{ padding: '0 10px', minWidth: '320px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <SelectForm options={options} value={currentStatus} className={classes.select} onChange={handleChangeStatus} />
        <GroupButtonSubmit onCancel={onClose} onSubmit={handleSubmit} submitLabel={submitLabel} />
      </div>
    </div>
  );
};

type Props = {
  data: {
    value: IValueSingleBudgetFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => void;
  onLoad: (param: {
    type: string;
    dimension: string;
    marketplaceCode: string;
    toolCode?: string;
    countryCode?: string;
  }) => Promise<void>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const CurrencyEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, onLoad, anchorEl, setAnchorEl } = props;
  const defaultValue = React.useMemo(() => {
    if (data.value.currency) return data.value.currency;
    return '';
  }, [data.value, anchorEl]);

  const [options, setOptions] = React.useState<OptionSelectType[]>([]);

  const initOptions = async () => {
    // if (onLoad) {
    //   const marketplaceField = get(payload, ['rowPicker', 'marketplaceCode']);
    //   const res = await onLoad({
    //     type: 'status',
    //     ...payload.static,
    //     marketplaceCode: get(data.node, ['data', marketplaceField], null),
    //   });
    //   console.log('status options ==>', res);

    //   if (res.success) {
    //     // TODO: set initial options
    //     const arr: OptionSelectType[] = res.data.map((item) => ({
    //       label: item.text,
    //       value: item.id,
    //       icon: getIconStatus(item.id).iconType,
    //     }));
    //     setOptions(arr);
    //   }
    // }
    setOptions(defaultOptions);
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = (valueCurrency) => {
    const item: OptionSelectType = options.find((m) => m.value == valueCurrency);
    if (item) {
      const valueTmp = JSON.parse(JSON.stringify(data.value));
      valueTmp.currency = item.value;
      onSubmit({ value: valueTmp, row: data.node.data });
    }
  };

  return (
    <CurrencyForm title={title} options={options} value={defaultValue} onClose={handleClosed} onSubmit={handleSubmit} />
  );
};

export default CurrencyEditor;
