import * as React from 'react';
import { get } from 'lodash';

import {
  default as dataRequest,
  checkEpsiloTableEndpoint,
  EpsiloTableObject,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';

import { getCellFormat } from '@ep/insight-ui/system/block/etable/cell-format/metric';
import { getCellFormat as getAttributeCellFormat } from '@ep/insight-ui/system/block/etable/cell-format/attribute';

export const useMassCreate = ({ eTableEndpoint, gridApi, handleClose, isDimension }: any) => {
  const [options, setOptions] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [defaultSelectedItems, setDefaultSelectedItems] = React.useState([]);

  const optionsObj = React.useMemo(() => {
    return options.reduce(
      (a, b) => ({
        ...a,
        [b.value]: b,
      }),
      {},
    );
  }, [options]);

  // Set default selectedItems
  React.useEffect(() => {
    const selectedOptions = [];
    gridApi.forEachNode((node) => {
      const existedOption = options.find((option) => option.value === get(node, 'data.valueGetter.value', ''));
      if (existedOption && selectedOptions.every((option) => option.value !== existedOption.value)) {
        selectedOptions.push(existedOption);
      }
    });
    setSelectedItems(selectedOptions.map((option) => option.value));
    setDefaultSelectedItems(selectedOptions.map((option) => option.value));
  }, [gridApi, options]);

  const namespace = React.useMemo(() => {
    const queryParams = getQueryParams(eTableEndpoint.value);
    return queryParams?.namespace;
  }, [eTableEndpoint.value]);

  // Load options
  React.useEffect(() => {
    setLoading(true);
    let request;
    if (checkEpsiloTableEndpoint(eTableEndpoint.value, EpsiloTableObject.PERFORMANCE)) {
      request = dataRequest.getPerformanceMetrics;
    } else if (checkEpsiloTableEndpoint(eTableEndpoint.value, EpsiloTableObject.OPERATION)) {
      request = dataRequest.getOperationMetrics;
    } else if (namespace) {
      request = () => {
        if (isDimension) {
          return dataRequest.getOneDimensionOptions(namespace);
        }
        return dataRequest.getOneMetricOptions(namespace);
      };
    }
    if (request) {
      request()
        .then((res) => {
          const selectedOptions = [];
          gridApi.forEachNode((node) => {
            const existedOption = res.find((option) => option.value === get(node, 'data.valueGetter.value', ''));
            if (existedOption && selectedOptions.every((option) => option.value !== existedOption.value)) {
              selectedOptions.push(existedOption.value);
            }
          });
          const mappedResponse = res.map((el) => ({
            ...el,
            name: el.label_raw,
            id: el.value,
          }));

          // Sort the response by alphabet
          const sortedResponse = [...mappedResponse]
            .sort((a, b) => (a.label > b.label ? -1 : 1))
            .sort((a, b) => (selectedOptions.includes(b.value) ? 1 : -1));
          setOptions(sortedResponse);
          setLoading(false);
        })
        .catch((e) => setOptions([]));
    }
  }, [eTableEndpoint.value]);

  const handleSubmit = () => {
    if (gridApi) {
      const rowData = [];
      gridApi.forEachNode((node) => {
        rowData.push(node.data);
      });
      const valueGetterData = rowData.map((el) => {
        return get(el, 'valueGetter.value', '');
      });
      const addColumns = selectedItems
        .filter((itemValue) => !valueGetterData.includes(itemValue))
        .map((itemValue) => {
          const item = optionsObj[itemValue];
          if (namespace && isDimension) {
            return {
              ...getAttributeCellFormat('oneDimension').defaultConfig,
              title: item.name,
              columnKeys: item.name
                .toLowerCase()
                .match(/[a-z]+/gi)
                .join('_'),
              valueGetter: {
                value: item.value,
                label: item.value,
                id: item.value,
              },
              filterField: item.value,
              sortField: item.value,
              propertyType: 'dimension',
            };
          }
          return {
            ...getCellFormat(namespace ? 'oneMetric' : 'metricTraction').defaultConfig,
            title: item.name,
            columnKeys: item.name
              .toLowerCase()
              .match(/[a-z]+/gi)
              .join('_'),
            valueGetter: {
              value: item.value,
              showAs: '="number"',
              dividedBy: '=100',
            },
            ...(namespace
              ? {
                  filterField: item.value,
                  sortField: item.value,
                }
              : {}),
          };
        });
      const removeColumns = rowData.filter((el) => {
        const valueGetter = get(el, 'valueGetter.value', '');
        return (
          defaultSelectedItems.some((ele) => ele.value === valueGetter) &&
          selectedItems.every((ele) => ele.value !== valueGetter)
        );
      });
      gridApi.applyTransaction({
        add: addColumns,
        remove: removeColumns,
        addIndex: 0,
      });
    }
    handleClose();
  };

  return {
    selectedItems,
    setSelectedItems,
    options,
    loading,
    handleSubmit,
    setOptions,
    inputValue,
    setInputValue,
  };
};
