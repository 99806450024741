import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import CircleLoading from '../loading/circle-loading';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { Typography } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  section: {
    marginTop: '24px',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    ...(ff.integrate_storefront_ui_v2
      ? {
          borderBottom: '1px solid #C2C7CB',
        }
      : {
          borderRadius: '8px',
        }),
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  buttonCancel: {
    color: '#8C98A4',
  },
  mobileContent: {
    padding: ff.integrate_storefront_ui_v2 ? '20px 25px 0 25px' : '15px 0 0 0',
  },
  buttonIntegrate: {
    width: '95%',
    height: '40px',
    marginTop: ff.integrate_storefront_ui_v2 ? '15px' : '30px',
    marginLeft: '12px',
  },
  buttonBack: {
    width: '100%',
    height: '40px',
    marginTop: '15px',
    background: '#F6F7F8',
  },
});

const StorefrontIntegrateForm = ({
  loading = false,
  onBackStep,
  onClose,
  onNextStep,
  initialValues,
  messageErrorCallback = '',
}: any) => {
  const classes = useStyles();
  const { marketplace, country, username, password, otp } = initialValues;
  // const { items, setItems } = useStorefrontForm();
  const [items, setItems] = React.useState([]);
  const isIntegrateSuccess = items.some((item) => item.checked && !item.disabled);
  const onIntegrate = () => {
    if (!loading) {
      onNextStep({ storefronts: items.filter((item) => item.checked && !item.disabled) });
    }
    console.log({
      marketplace,
      country,
      username,
      password,
      otp,
      items,
    });
  };

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;

  React.useEffect(() => {
    const storefronts = initialValues.storeLists.map((i) => ({
      value: i.sid,
      label: i.name,
      checked: !!i.existed,
      disabled: !!i.existed,
    }));
    setItems(storefronts);
  }, [initialValues]);

  return !isMobile ? (
    <Grid container direction={'column'}>
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>Confirm storefront to integrate</span>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <ExpansionForm
          label={'Select storefront to integrate to Epsilo'}
          level={'2'}
          detailContentSize={'full'}
          defaultExpanded={true}
        >
          <Box>
            <CheckboxList items={items} onChange={setItems} />
          </Box>
        </ExpansionForm>
      </Grid>
      {messageErrorCallback && (
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <Icon type={'error'} style={{ width: '9px', height: '9px' }} />
            <Typography variant="body5" style={{ color: '#D4290D' }}>
              {messageErrorCallback}
            </Typography>
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} back`}
              onClick={onBackStep}
            >
              <span className={`${classes.text} textButton`}>Back</span>
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            {loading ? <CircleLoading /> : ''}
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} cancel`}
              onClick={onClose}
            >
              <span className={`${classes.text} textButton`}>Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={onIntegrate}
              disabled={loading || !isIntegrateSuccess}
            >
              Integrate
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Grid container direction={'column'}>
      <div className={classes.mobileHeader}>
        <h4 className={classes.mobileTitle}>Select your storefront</h4>
        <div className={classes.mobileButton}>
          <Button className={classes.buttonCancel} color="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
      </div>
      <Box className={classes.mobileContent}>
        <CheckboxList items={items} onChange={setItems} />
        {messageErrorCallback && (
          <Grid item xs={12}>
            <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
              <Icon type={'error'} style={{ width: '9px', height: '9px' }} />
              <Typography variant="body5" style={{ color: '#D4290D' }}>
                {messageErrorCallback}
              </Typography>
            </Box>
          </Grid>
        )}
        <Grid container direction="row">
          <Grid item xs={3}>
            <Button className={classes.buttonBack} onClick={onBackStep}>
              Back
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonIntegrate}
              onClick={onIntegrate}
              disabled={loading || !isIntegrateSuccess}
            >
              Integrate
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
};
export default StorefrontIntegrateForm;
