import { PageContainerResponsive } from '@eip/next/lib/main';
import React from 'react';
import { Redirect } from 'react-router';
import { LoadingUI } from './components/common/loading';
import ContainerInternalPage from './container/internal-page';
import { getPagePrefix1 } from './global';
import { useAuth } from './hooks/use-auth';
import { withSystemPage } from './system-page';

function PagePublic(LazyComponent: React.ComponentType) {
  function PageWithLazy() {
    const { token } = useAuth();
    if (token) {
      return <Redirect to={getPagePrefix1()} />;
    }
    return (
      <React.Suspense fallback={<LoadingUI loading />}>
        <PageContainerResponsive>
          <LazyComponent />
        </PageContainerResponsive>
      </React.Suspense>
    );
  }

  PageWithLazy.displayName = `PageWithLazy(${LazyComponent.displayName})`;

  return PageWithLazy;
}

function PageWithoutLogin(LazyComponent: React.ComponentType) {
  function PageWithLazy() {
    return (
      <React.Suspense fallback={<LoadingUI loading />}>
        <PageContainerResponsive>
          <LazyComponent />
        </PageContainerResponsive>
      </React.Suspense>
    );
  }

  PageWithLazy.displayName = `PageWithLazy(${LazyComponent.displayName})`;

  return PageWithLazy;
}

export function PageWithInternalMenu(LazyComponent: React.ComponentType) {
  function PageWithLazy() {
    return (
      <React.Suspense fallback={<LoadingUI loading />}>
        <ContainerInternalPage>
          <LazyComponent />
        </ContainerInternalPage>
      </React.Suspense>
    );
  }

  PageWithLazy.displayName = `Pwlz(${LazyComponent.displayName})`;

  return PageWithLazy;
}

export function PageWithoutMenu(LazyComponent: React.ComponentType) {
  function PageWithLazy() {
    return (
      <React.Suspense fallback={<LoadingUI loading />}>
        <LazyComponent />
      </React.Suspense>
    );
  }

  PageWithLazy.displayName = `Pwlz(${LazyComponent.displayName})`;

  return PageWithLazy;
}

export function internalMenu(
  canAccess: (permissionCode: string[]) => boolean,
  morePage: (canAccess: (permissionCode: string[]) => boolean) => any,
) {
  let legacySystemPage;
  if (ff.left_menu_system_page) {
    legacySystemPage = morePage(canAccess);
    legacySystemPage = [].concat(legacySystemPage).map((el) => ({
      ...el,
      page: PageWithInternalMenu(withSystemPage(el.page)),
    }));
  } else {
    legacySystemPage = morePage(canAccess);
    legacySystemPage = [].concat(legacySystemPage).map((el) => ({
      ...el,
      page: PageWithInternalMenu(el.page),
    }));
  }

  const allMenus = [].concat(
    {
      title: 'Feature request',
      url: '/feature-request',
      page: PageWithInternalMenu(React.lazy(() => import('@ep/insight-ui/eo2/page/feature-request'))),
    },
    {
      title: 'Download Page',
      url: '/download-files',
      page: PageWithoutMenu(React.lazy(() => import('@ep/insight-ui/eo2/page/download-page'))),
    },
    {
      title: 'Redirect Page',
      url: '/redirect-external',
      page: PageWithoutMenu(React.lazy(() => import('@ep/insight-ui/eo2/page/redirect-external'))),
    },
    legacySystemPage,
  );

  console.info({ allMenus });
  return allMenus;
}

export function publicMenu() {
  return [
    {
      title: 'Login',
      url: '/login',
      page: PagePublic(React.lazy(() => import('@ep/insight-ui/eo2/page/auth0-login'))),
    },
    {
      title: 'Logout next',
      url: '/logout-next',
      page: PageWithoutLogin(React.lazy(() => import('@ep/insight-ui/eo2/page/auth0-logout'))),
    },
    {
      title: 'Logout',
      url: '/logout',
      page: PageWithoutLogin(React.lazy(() => import('@ep/insight-ui/eo2/page/auth0-logout'))),
    },
  ];
}

export function Todo() {
  return <h1>Todo</h1>;
}
