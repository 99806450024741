import * as React from 'react';
import { get } from 'lodash';

import { Grid, Box, withStyles, Button } from '@material-ui/core';
import MuiListItem from '@material-ui/core/ListItem';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { CHART_CONFIG, PERSONALIZATION, X_AXIS, Y_AXIS } from '@ep/insight-ui/system/helper/constant';

const ListItem = withStyles(() => ({
  root: {
    borderRadius: '4px',
    paddingRight: 15,
    marginBottom: 8,
    paddingLeft: 15,
  },
  button: {
    // backgroundColor: 'red',
    borderRadius: '4px',
    fontStyle: ' normal',
    fontWeight: 400,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
}))(MuiListItem);

const HeatmapPeriodically = ({ enhancePeriodicallyOptions, classes, valuePeriod, handleClose }: any) => {
  const backboneContext = React.useContext(TableBackboneContext);
  const personalization = backboneContext.getConfig(PERSONALIZATION, {});
  const heatmapConfig = get(personalization, [CHART_CONFIG], {});
  const [axes, setAxes] = React.useState(() => {
    const xAxis = get(heatmapConfig, [X_AXIS], {});
    const yAxis = get(heatmapConfig, [Y_AXIS], {});

    return {
      [X_AXIS]: xAxis,
      [Y_AXIS]: yAxis,
    };
  });

  const axesOption = [
    {
      label: 'X-Axis',
      value: X_AXIS,
    },
    {
      label: 'Y-Axis',
      value: Y_AXIS,
    },
  ];

  const onSubmit = () => {
    const personalization = backboneContext.getConfig(PERSONALIZATION, {});
    backboneContext.changeConfig(PERSONALIZATION, {
      ...personalization,
      [CHART_CONFIG]: axes,
    });
    // packages/insight-ui/system/backbone/table-backbone/next-table-backbone.ts -> line 547 prevent auto reload table
    backboneContext.reloadData('table');
    handleClose();
  };

  return (
    <div className={classes.period}>
      <Grid container style={{ columnGap: '16px' }}>
        {axesOption.map(({ label, value }) => {
          return (
            <Grid
              key={value}
              item
              style={{ minWidth: '120px', width: enhancePeriodicallyOptions.length === 0 ? '100%' : null }}
            >
              <Box className={classes.viewByHeader}>
                <HeaderList title={label} />
              </Box>
              {enhancePeriodicallyOptions.map((el, index) => (
                <div key={index}>
                  <ListItem
                    button
                    className={get(axes, [value, 'value']) === el.value ? classes.activeButton : classes.defaultButton}
                    onClick={() => {
                      setAxes((axis) => ({
                        ...axis,
                        [value]: {
                          type: el.type || 'time',
                          value: el.value,
                        },
                      }));
                    }}
                  >
                    {el.label}
                  </ListItem>
                </div>
              ))}
            </Grid>
          );
        })}
      </Grid>
      <Box display={'flex'} alignItems={'center'} justifyContent={'flex-end'} sx={{ margin: '8px 0' }}>
        <Box display={'flex'} alignItems={'center'} sx={{ columnGap: '12px' }}>
          <Button onClick={handleClose}>Cancel</Button>
          <Button variant={'contained'} color={'primary'} onClick={onSubmit}>
            Apply
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default HeatmapPeriodically;
