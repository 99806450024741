import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddDuplicate = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 5C2.44772 5 2 5.44772 2 6V13C2 13.5523 2.44772 14 3 14H8C8.55228 14 9 13.5523 9 13C9 12.4477 8.55228 12 8 12L4 12V6C4 5.44772 3.55228 5 3 5Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 3C5 2.44772 5.44772 2 6 2H13C13.5523 2 14 2.44772 14 3V10C14 10.5523 13.5523 11 13 11H6C5.44772 11 5 10.5523 5 10V3ZM8.5 4.5C8.5 3.94772 8.94771 3.5 9.5 3.5C10.0523 3.5 10.5 3.94772 10.5 4.5V5.5H11.5C12.0523 5.5 12.5 5.94772 12.5 6.5C12.5 7.05229 12.0523 7.5 11.5 7.5H10.5V8.5C10.5 9.05228 10.0523 9.5 9.5 9.5C8.94771 9.5 8.5 9.05228 8.5 8.5V7.5H7.5C6.94771 7.5 6.5 7.05228 6.5 6.5C6.5 5.94772 6.94771 5.5 7.5 5.5H8.5V4.5Z"
    />
  </SvgIcon>
);

export default AddDuplicate;
