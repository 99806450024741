import { getActionMenu } from '@ep/insight-ui/system/block/etable/table-config/common-config';
import { EIP_CONSTANT } from '@eip/next/lib/main';

export const TOP_KEYWORD_ANALYSIS_CONFIG = {
  title: 'Keyword exploring',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getKeywordDiscoveryData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=SUGGESTED_KEYWORD',
  },
  useDataSource: false,
  primaryKeys: [
    'SUGGESTED_KEYWORD.id',
    'SUGGESTED_KEYWORD.marketplace_code',
    'SUGGESTED_KEYWORD.marketplace_name',
    'SUGGESTED_KEYWORD.country_code',
    'SUGGESTED_KEYWORD.country_name',
  ],
  tableType: 'keyword-analysis',
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  groupBy: {
    aggregations: {
      'SUGGESTED_KEYWORD.marketplace_code': {
        func: 'max',
      },
      'SUGGESTED_KEYWORD.marketplace_name': {
        func: 'max',
      },
      'SUGGESTED_KEYWORD.country_code': {
        func: 'max',
      },
      'SUGGESTED_KEYWORD.country_name': {
        func: 'max',
      },
      'SUGGESTED_KEYWORD.search_volume': {
        func: 'sum',
      },
      'SUGGESTED_KEYWORD.competing_products': {
        func: 'max',
      },
      'SUGGESTED_KEYWORD.id': {
        func: 'max',
      },
    },
  },
  // disabledComponents: {
  //   dimension: ['keyword', 'marketplace', 'country'],
  // },
  mapping: {
    // ============= DIMENSION =============
    keyword: {
      title: 'Keyword',
      cellFormat: 'keywordAnalysisFormat',
      propertyType: 'dimension',
      dataType: 'string',
      filterField: 'SUGGESTED_KEYWORD.name',
      valueGetter: {
        id: 'SUGGESTED_KEYWORD.name',
        value: 'SUGGESTED_KEYWORD.name',
      },
      menu: getActionMenu('keyword'),
    },
    marketplace: {
      title: 'Marketplace',
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      filterField: 'SUGGESTED_KEYWORD.marketplace_name',
      valueGetter: {
        id: 'SUGGESTED_KEYWORD.marketplace_code',
        value: 'SUGGESTED_KEYWORD.marketplace_name',
      },
    },
    country: {
      title: 'Country',
      cellFormat: 'text',
      propertyType: 'dimension',
      dataType: 'string',
      filterField: 'SUGGESTED_KEYWORD.country_name',
      valueGetter: {
        id: 'SUGGESTED_KEYWORD.country_code',
        value: 'SUGGESTED_KEYWORD.country_name',
      },
    },
    search_volume: {
      title: 'Search volume',
      cellFormat: 'currency',
      propertyType: 'dimension',
      dataType: 'integer',
      filterField: 'SUGGESTED_KEYWORD.search_volume',
      valueGetter: {
        id: 'SUGGESTED_KEYWORD.search_volume',
        value: 'SUGGESTED_KEYWORD.search_volume',
      },
    },
    // search_trend: {
    //   title: 'Search trend',
    //   cellFormat: 'elasticity',
    //   propertyType: 'dimension',
    //   dataType: 'string',
    //   filterField: 'search_trend',
    //   valueGetter: {},
    // },
    competing_products: {
      title: 'Competing products',
      cellFormat: 'currency',
      propertyType: 'dimension',
      dataType: 'integer',
      filterField: 'SUGGESTED_KEYWORD.competing_products',
      valueGetter: {
        id: 'SUGGESTED_KEYWORD.competing_products',
        value: 'SUGGESTED_KEYWORD.competing_products',
      },
    },

    // ============= ATTRIBUTE =============
    // ============= METRIC =============
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
      },
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
    },
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: true },
    { type: 'attribute', label: 'Attribute', allowConfig: true },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  search: '',
  requestIgnoreField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
};
