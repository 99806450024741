import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddFavorite = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5247 5.37003L12.9063 5.58746C13.9509 5.67944 14.3938 6.99233 13.5916 7.70314L11.703 9.44251C11.5526 9.58467 11.4857 9.80209 11.5275 10.0112L12.079 12.5115C12.2545 13.3226 11.6111 14 10.8924 14C10.6835 14 10.4579 13.9415 10.249 13.8077L8.35203 12.5868C8.13475 12.4446 7.85063 12.4446 7.63336 12.5868L5.73642 13.8077C5.52751 13.9415 5.31024 14 5.09297 14C4.36595 14 3.73085 13.331 3.90634 12.5115L4.45787 10.0112C4.50801 9.80209 4.44116 9.58467 4.28239 9.44251L2.3938 7.70314C1.61665 6.99233 2.05954 5.67944 3.11247 5.58746L5.49408 5.37003C5.71971 5.35331 5.91191 5.20279 5.99548 4.99373L6.88127 2.76098C7.08183 2.25087 7.54144 2 8.00105 2C8.46066 2 8.92863 2.25087 9.12918 2.76098L10.0233 4.99373C10.1069 5.20279 10.2991 5.35331 10.5247 5.37003Z" />
  </SvgIcon>
);

export default AddFavorite;
