import * as React from 'react';
import { debounce, get, last } from 'lodash';
import { DateObject } from 'react-multi-date-picker';
import moment from 'moment';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { DATETIME_PICKER, DATETIME_REGEX } from '@ep/insight-ui/sw/etable/constant';

export enum CalendarOption {
  TODAY = 'TODAY',
  YESTERDAY = 'YESTERDAY',
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_15_DAYS = 'LAST_15_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_60_DAYS = 'LAST_60_DAYS',
  THIS_MONTH = 'THIS_MONTH',
  THIS_YEAR = 'THIS_YEAR',
  YEAR_TO_DATE = 'YEAR_TO_DATE',
  PREVIOUS = 'PREVIOUS',
  LAST_MONTH = 'LAST_MONTH',
  LAST_YEAR = 'LAST_YEAR',
  CUSTOM = 'CUSTOM',
}

export const calendarDateLabel: { [key in CalendarOption]?: string } = {
  [CalendarOption.TODAY]: 'Today',
  [CalendarOption.YESTERDAY]: 'Yesterday',
  [CalendarOption.LAST_7_DAYS]: 'Last 7 days',
  [CalendarOption.LAST_15_DAYS]: 'Last 15 days',
  [CalendarOption.LAST_30_DAYS]: 'Last 30 days',
  [CalendarOption.LAST_60_DAYS]: 'Last 60 days',
  [CalendarOption.THIS_MONTH]: 'This month',
  [CalendarOption.YEAR_TO_DATE]: 'Year to date',
  [CalendarOption.THIS_YEAR]: 'This year',
  [CalendarOption.PREVIOUS]: 'The previous',
  [CalendarOption.LAST_MONTH]: 'Last month',
  [CalendarOption.LAST_YEAR]: 'Last year',
  [CalendarOption.CUSTOM]: 'Custom',
};

interface selectLimitProps {
  startDate?: string;
  endDate?: string;
}

export type DateValue = {
  dateFrom: string;
  dateTo: string;
};

type ITab = {
  label: string;
  onClick: () => void;
  option: CalendarOption;
};

const generateDateObject = (val: string, format = 'DD/MM/YYYY'): DateObject => {
  return new DateObject({ date: val, format: format });
};

export const getDateRangeFromOption = (option: CalendarOption, currentDateRange: DateValue): DateValue => {
  let dateFrom, dateTo;
  const dateTimeFormat = 'YYYY-MM-DD';
  switch (option) {
    case CalendarOption.TODAY:
      dateFrom = moment();
      dateTo = moment();
      break;
    case CalendarOption.YESTERDAY:
      dateFrom = moment().subtract(1, 'day');
      dateTo = moment().subtract(1, 'day');
      break;
    case CalendarOption.LAST_7_DAYS:
      dateFrom = moment().subtract(8, 'days');
      dateTo = moment().subtract(1, 'day');
      break;
    case CalendarOption.LAST_15_DAYS:
      dateFrom = moment().subtract(16, 'days');
      dateTo = moment().subtract(1, 'day');
      break;
    case CalendarOption.LAST_30_DAYS:
      dateFrom = moment().subtract(31, 'days');
      dateTo = moment().subtract(1, 'day');
      break;
    case CalendarOption.LAST_60_DAYS:
      dateFrom = moment().subtract(61, 'days');
      dateTo = moment().subtract(1, 'days');
    case CalendarOption.THIS_MONTH:
      dateFrom = moment().startOf('month');
      dateTo = moment();
      break;
    case CalendarOption.THIS_YEAR:
      dateFrom = moment().startOf('years');
      dateTo = moment().endOf('years');
      break;
    case CalendarOption.YEAR_TO_DATE:
      dateFrom = moment().startOf('years');
      dateTo = moment();
      break;
    default:
      break;
  }
  if (!!dateFrom && !!dateTo) {
    return {
      dateFrom: dateFrom.format(dateTimeFormat),
      dateTo: dateTo.format(dateTimeFormat),
    };
  }
  return currentDateRange;
};

export const getCohortDateRangeFromOption = (
  option: CalendarOption,
  currentDateRange: DateValue,
  dateRange: DateValue,
): DateValue => {
  if (!option) return null;
  const calStartDate = moment(dateRange.dateFrom, 'YYYY-MM-DD');
  const calEndDate = moment(dateRange.dateTo, 'YYYY-MM-DD');
  const diffDays = calEndDate.diff(calStartDate, 'days');
  const dateTimeFormat = 'YYYY-MM-DD';
  let dateFrom, dateTo;
  switch (option) {
    case CalendarOption.PREVIOUS:
      dateFrom = moment(calStartDate).subtract(diffDays + 1, 'days');
      dateTo = moment(calEndDate).subtract(diffDays + 1, 'days');
      break;
    case CalendarOption.LAST_MONTH:
      dateFrom = moment(calStartDate).subtract(1, 'months');
      dateTo = moment(calEndDate).subtract(1, 'months');
      break;
    case CalendarOption.LAST_YEAR:
      dateFrom = moment(calStartDate).subtract(1, 'years');
      dateTo = moment(calEndDate).subtract(1, 'years');
      break;
    default:
      break;
  }
  if (!!dateFrom && !!dateTo) {
    return {
      dateFrom: dateFrom.format(dateTimeFormat),
      dateTo: dateTo.format(dateTimeFormat),
    };
  }
  return currentDateRange;
};

interface IProps {
  value: DateValue;
  defaultFormatReturn?: string;
  formatDateView?: string;
  clickOnSubmit?: boolean;
  onChange: (newValue: DateValue, option?: CalendarOption) => void;
}

export const useCalendarInput = ({ value, defaultFormatReturn, formatDateView, clickOnSubmit, onChange }: IProps) => {
  // Get config value
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const calendarCohort = backbone.getVisibility('calendar');
  const cohortDateRange = backbone.getConfig('cohortDateRange');
  const dateOptionConfig: CalendarOption = backbone.getConfig('calendarOption');
  const dateCohortOptionConfig: CalendarOption = backbone.getConfig('calendarCohort');
  const tableType = backbone.getConfig('tableType');
  const lastUpdatedAt = backbone.getConfig('lastUpdatedAt');
  const dateRange = backbone.getConfig('dateRange');
  const chartConfig = backbone.getConfig('chartConfig');
  const calendarConfig = backbone.getConfig('calendarConfig', {});
  const calendarTypePicker = get(calendarConfig, 'typePicker', 'default');
  const isDateRangeSelect = get(calendarConfig, 'dateRangeSelect', 'yes') === 'yes';
  const hiddenCalendarOptions = get(calendarConfig, ['hiddenCalendarOptions'], []) || [];
  const maxDaysSelected = get(calendarConfig, ['maxDaysSelected'], 999) || 999;

  const dateFromConfig = get(dateRange, 'dateFrom', '');
  const dateToConfig = get(dateRange, 'dateTo', '');
  const groupPeriod = get(chartConfig, 'config.groupPeriod', '');
  const chartType = get(chartConfig, 'config.chartType', '');

  const [currentValue, setCurrentValue] = React.useState<any>();
  const [currentTab, setCurrentTab] = React.useState<CalendarOption>(dateOptionConfig);
  const [open, setOpen] = React.useState<boolean>(false);
  const [shouldShowLabel, setShouldShowLabel] = React.useState(true);

  const calendarLabelRef = React.useRef(null);

  const isMonthPicker = groupPeriod === 'monthly' || calendarTypePicker === 'month';

  const defaultDate = React.useMemo(() => {
    const tempValues = [];
    let start = new DateObject();
    let end = new DateObject();
    const dateFrom = get(value, 'dateFrom', '');
    if (dateFrom) {
      start = generateDateObject(dateFrom, defaultFormatReturn);
    }
    const dateTo = get(value, 'dateTo', '');
    if (dateTo) {
      end = generateDateObject(dateTo, defaultFormatReturn);
    }
    // setCurrentValue(start);
    tempValues.unshift(start);
    tempValues.push(end);
    return tempValues;
  }, [value]);

  const isDatetimePicker = calendarTypePicker === DATETIME_PICKER;

  const [values, setValues] = React.useState<DateObject[]>(defaultDate);
  const [checkedHour, setCheckedHour] = React.useState(() => {
    return isDatetimePicker && DATETIME_REGEX.test(value?.dateFrom);
  });
  const [time, setTime] = React.useState(() => {
    const { dateFrom, dateTo } = value || {};
    const timeStart = dateFrom?.match(/\d{2}:\d{2}/)?.[0] || '00:00';
    const timeEnd = dateTo?.match(/\d{2}:\d{2}/)?.[0] || '23:00';

    return {
      timeStart,
      timeEnd,
    };
  });

  const datetimeChecked = React.useMemo(() => {
    return isDatetimePicker && checkedHour;
  }, [isDatetimePicker, checkedHour]);

  React.useEffect(() => {
    if (datetimeChecked) {
      const newValues = getNewDatesWithTime(values, time);

      handleChangeDateDebounce(newValues);
    }
  }, [time, datetimeChecked]);

  const handleSelectShortcut = (values) => {
    setCheckedHour(false);
    setValues(values);
  };

  const diffDays = moment(dateToConfig, 'YYYY-MM-DD').diff(moment(dateFromConfig, 'YYYY-MM-DD'), 'days');
  const tabsCohort = [
    {
      label: 'The previous',
      option: CalendarOption.PREVIOUS,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateTo = new DateObject(dateFromConfig).subtract(1, 'days');
        const dateFrom = new DateObject(dateTo).subtract(diffDays, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(dateTo);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Last month',
      option: CalendarOption.LAST_MONTH,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject(dateFromConfig).subtract(1, 'months');
        const dateTo = new DateObject(dateToConfig).subtract(1, 'months');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(dateTo);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Last year',
      option: CalendarOption.LAST_YEAR,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject(dateFromConfig).subtract(1, 'years');
        const dateTo = new DateObject(dateToConfig).subtract(1, 'years');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(dateTo);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Custom',
      option: CalendarOption.CUSTOM,
      onClick: () => {
        setCurrentValue([]);
      },
    },
  ];

  React.useEffect(() => {
    if (calendarLabelRef.current) {
      const ro = new ResizeObserver((entries, ...args) => {
        setShouldShowLabel(entries[0].contentRect?.height <= 14 && entries[0].contentRect?.height > 0);
      });
      if (calendarLabelRef.current) {
        ro.observe(calendarLabelRef.current);
      }
      return () => {
        ro.disconnect();
      };
    }
  }, [calendarLabelRef.current]);

  const checkMatchCalendar = () => {
    const dateFrom = get(value, 'dateFrom', '');
    const dateTo = get(value, 'dateTo', '');
    let start = new DateObject();
    let end = new DateObject();
    if (dateFrom) {
      start = generateDateObject(dateFrom, defaultFormatReturn);
    }
    if (dateTo) {
      end = generateDateObject(dateTo, defaultFormatReturn);
    }

    if (isDateRangeSelect || calendarTypePicker === 'week') {
      setCurrentValue([start, end]);
    } else {
      setCurrentValue(dateFrom);
    }
    !clickOnSubmit && setValues([start, end]);
  };

  const getDateLabel = (arr) => {
    if (lastUpdatedAt) {
      return `Updated at ${lastUpdatedAt}`;
    }
    if (values.length >= 1) {
      const hasHours = DATETIME_REGEX.test(value?.dateFrom) && isDatetimePicker;
      const isSameDay = moment(value?.dateFrom).isSame(moment(value?.dateTo), 'day');
      const isSameDatetime = isSameDay && moment(value?.dateFrom).isSame(moment(value?.dateTo), 'hour');
      const format = hasHours ? formatDateView.concat(' HH:mm') : formatDateView;
      const start = moment(value?.dateFrom).format(format);
      const end = moment(value?.dateTo).format(hasHours && isSameDay ? 'HH:mm' : format);
      let label = start === end || isSameDatetime ? `${start}` : `${start} - ${end}`;
      if (dateOptionConfig !== CalendarOption.CUSTOM && dateOptionConfig) {
        label = calendarDateLabel[dateOptionConfig] + `, ${label}`;
      }
      return label;
    }
    return '';
  };

  React.useEffect(() => {
    const valueBackbone = calendarCohort ? cohortDateRange : dateRange;
    const tempValues = [];
    let start = new DateObject();
    let end = new DateObject();
    const dateFrom = get(valueBackbone, 'dateFrom', '');
    if (dateFrom) {
      start = generateDateObject(dateFrom, defaultFormatReturn);
    }
    const dateTo = get(valueBackbone, 'dateTo', '');
    if (dateTo) {
      end = generateDateObject(dateTo, defaultFormatReturn);
    }
    // setCurrentValue(start);
    tempValues.unshift(start);
    tempValues.push(end);
    setCurrentValue(valueBackbone);
    setValues(tempValues);
    setCurrentTab(calendarCohort ? dateCohortOptionConfig : dateOptionConfig);
  }, [calendarCohort]);

  React.useEffect(() => {
    if (values.length > 0 && clickOnSubmit && !calendarCohort) {
      const start = values[0].format(defaultFormatReturn);
      const end = last(values).format(defaultFormatReturn);
      if (!value || start != value.dateFrom || end != value.dateTo) {
        onChange({ dateFrom: start, dateTo: end });
      }
    }
  }, [values, calendarCohort]);

  React.useEffect(() => {
    setOpen(calendarCohort);
  }, [calendarCohort]);

  //   data tabs
  const tabs: Array<ITab> = [
    {
      label: calendarDateLabel[CalendarOption.TODAY],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const todayValue = new DateObject();
        if (tempValues.length > 1) {
          tempValues[0] = todayValue;
          tempValues[tempValues.length - 1] = todayValue;
        } else {
          tempValues.unshift(todayValue);
          tempValues.push(todayValue);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.TODAY,
    },
    {
      label: calendarDateLabel[CalendarOption.YESTERDAY],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const yesterdayValue = new DateObject().subtract(1, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = yesterdayValue;
          tempValues[tempValues.length - 1] = yesterdayValue;
        } else {
          tempValues.unshift(yesterdayValue);
          tempValues.push(yesterdayValue);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.YESTERDAY,
    },
    {
      label: calendarDateLabel[CalendarOption.LAST_7_DAYS],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject().subtract(8, 'days');
        const dateTo = new DateObject().subtract(1, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.LAST_7_DAYS,
    },
    {
      label: calendarDateLabel[CalendarOption.LAST_15_DAYS],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject().subtract(16, 'days');
        const dateEnd = new DateObject().subtract(1, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateEnd;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateEnd);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.LAST_15_DAYS,
    },
    {
      label: calendarDateLabel[CalendarOption.LAST_30_DAYS],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject().subtract(31, 'days');
        const dateTo = new DateObject().subtract(1, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.LAST_30_DAYS,
    },
    {
      label: calendarDateLabel[CalendarOption.LAST_60_DAYS],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject().subtract(61, 'days');
        const dateTo = new DateObject().subtract(1, 'days');
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.LAST_60_DAYS,
    },
    {
      label: calendarDateLabel[CalendarOption.THIS_MONTH],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject(moment().startOf('month').format('YYYY-MM-DD'));
        const dateTo = new DateObject(moment().format('YYYY-MM-DD'));
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.THIS_MONTH,
    },
    {
      label: calendarDateLabel[CalendarOption.YEAR_TO_DATE],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject(moment().startOf('year').format('YYYY-MM-DD'));
        const dateTo = new DateObject(moment().format('YYYY-MM-DD'));
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.YEAR_TO_DATE,
    },
    {
      label: calendarDateLabel[CalendarOption.THIS_YEAR],
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = new DateObject(moment().startOf('year').format('YYYY-MM-DD'));
        const dateTo = new DateObject(moment().endOf('year').format('YYYY-MM-DD'));
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
      option: CalendarOption.THIS_YEAR,
    },
    {
      label: calendarDateLabel[CalendarOption.CUSTOM],
      onClick: () => {
        setCurrentValue(defaultDate);
        setValues(defaultDate);
      },
      option: CalendarOption.CUSTOM,
    },
  ];

  const tabsPeriodMonth = [
    {
      label: 'This year',
      option: CalendarOption.THIS_YEAR,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD');
        const lasteDateOfYear = moment().endOf('year').format('YYYY-MM-DD');
        const dateTo = new DateObject(lasteDateOfYear);
        const dateFrom = new DateObject(firstDateOfYear);
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Last month',
      option: CalendarOption.LAST_MONTH,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
        const dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
        const dateFromObject = new DateObject(dateFrom);
        const dateToObject = new DateObject(dateTo);
        if (tempValues.length > 1) {
          tempValues[0] = dateFromObject;
          tempValues[tempValues.length - 1] = dateToObject;
        } else {
          tempValues.unshift(dateFromObject);
          tempValues.push(dateToObject);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Year to date',
      option: CalendarOption.YEAR_TO_DATE,
      onClick: () => {
        const tempValues: Array<DateObject> = [...values];
        const firstDateOfYear = moment().startOf('year').format('YYYY-MM-DD');
        const lasteDateOfMonth = moment().endOf('month').format('YYYY-MM-DD');
        const dateFrom = new DateObject(firstDateOfYear);
        const dateTo = new DateObject(lasteDateOfMonth);
        if (tempValues.length > 1) {
          tempValues[0] = dateFrom;
          tempValues[tempValues.length - 1] = dateTo;
        } else {
          tempValues.unshift(dateFrom);
          tempValues.push(dateTo);
        }
        setCurrentValue(tempValues);
        handleSelectShortcut(tempValues);
      },
    },
    {
      label: 'Custom',
      option: CalendarOption.CUSTOM,
      onClick: () => {
        setCurrentValue([]);
      },
    },
  ];

  const tabsSingle = React.useMemo(() => {
    if (isMonthPicker) {
      return [
        {
          label: calendarDateLabel[CalendarOption.THIS_MONTH],
          onClick: () => {
            const tempValues: Array<DateObject> = [...values];
            const dateFrom = new DateObject(moment().startOf('month').format('YYYY-MM-DD'));
            const dateTo = new DateObject(moment().format('YYYY-MM-DD'));
            if (tempValues.length > 1) {
              tempValues[0] = dateFrom;
              tempValues[tempValues.length - 1] = dateTo;
            } else {
              tempValues.unshift(dateFrom);
              tempValues.push(dateTo);
            }
            setCurrentValue(tempValues);
            handleSelectShortcut(tempValues);
          },
          option: CalendarOption.THIS_MONTH,
        },
        {
          label: calendarDateLabel[CalendarOption.LAST_MONTH],
          onClick: () => {
            const tempValues: Array<DateObject> = [...values];
            const dateFrom = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD');
            const dateTo = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD');
            const dateFromObject = new DateObject(dateFrom);
            const dateToObject = new DateObject(dateTo);
            if (tempValues.length > 1) {
              tempValues[0] = dateFromObject;
              tempValues[tempValues.length - 1] = dateToObject;
            } else {
              tempValues.unshift(dateFromObject);
              tempValues.push(dateToObject);
            }
            setCurrentValue(tempValues);
            handleSelectShortcut(tempValues);
          },
          option: CalendarOption.LAST_MONTH,
        },
        {
          label: calendarDateLabel[CalendarOption.CUSTOM],
          onClick: () => {
            setCurrentValue(defaultDate);
            setValues(defaultDate);
          },
          option: CalendarOption.CUSTOM,
        },
      ];
    }
    return [
      {
        label: calendarDateLabel[CalendarOption.TODAY],
        onClick: () => {
          const tempValues: Array<DateObject> = [...values];
          const todayValue = new DateObject();
          if (tempValues.length > 1) {
            tempValues[0] = todayValue;
            tempValues[tempValues.length - 1] = todayValue;
          } else {
            tempValues.unshift(todayValue);
            tempValues.push(todayValue);
          }
          setCurrentValue(todayValue);
          handleSelectShortcut(tempValues);
        },
        option: CalendarOption.TODAY,
      },
      {
        label: calendarDateLabel[CalendarOption.YESTERDAY],
        onClick: () => {
          const tempValues: Array<DateObject> = [...values];
          const yesterdayValue = new DateObject().subtract(1, 'days');
          if (tempValues.length > 1) {
            tempValues[0] = yesterdayValue;
            tempValues[tempValues.length - 1] = yesterdayValue;
          } else {
            tempValues.unshift(yesterdayValue);
            tempValues.push(yesterdayValue);
          }
          setCurrentValue(yesterdayValue);
          handleSelectShortcut(tempValues);
        },
        option: CalendarOption.YESTERDAY,
      },
      {
        label: calendarDateLabel[CalendarOption.CUSTOM],
        onClick: () => {
          setCurrentValue(Array.isArray(defaultDate) ? defaultDate[0] : defaultDate);
          setValues(Array.isArray(defaultDate) ? defaultDate : [defaultDate]);
        },
        option: CalendarOption.CUSTOM,
      },
    ];
  }, [isDateRangeSelect]);

  //   handle click tab
  const handleClickTab = (option: CalendarOption, defaultOnclick: () => void) => () => {
    setCurrentTab(option);
    defaultOnclick();
  };

  const mapDays = ({
    date,
    isSameDate,
    selectLimit,
  }: {
    date: DateObject;
    isSameDate(arg1: DateObject, arg2: DateObject): boolean;
    selectLimit?: selectLimitProps;
  }): React.HTMLAttributes<HTMLDivElement> => {
    const props: React.HTMLAttributes<HTMLDivElement> = {};
    const start = values[0];

    const isSameStart = isSameDate(date, start);
    const isSameEnd = isSameDate(date, last(values));
    if (values.length > 1) {
      props.className = `${isSameStart && 'selected-one start'} ${isSameEnd && 'selected-one end'}`;

      const startDate = moment(start.format());
      const endDate = moment(last(values).format());
      const isBetween = moment(date.format()).isBetween(startDate, endDate);
      if (isBetween && !isSameEnd) {
        props.className = ' rmdp-range-child';
      }
    } else {
      if (isSameStart) {
        props.className = ' selected-one';
      } else {
        props.className = '';
      }
    }

    const min =
      selectLimit && selectLimit.startDate ? moment(selectLimit.startDate).format(defaultFormatReturn) : new Date(0);
    const max =
      selectLimit && selectLimit.endDate
        ? moment(selectLimit.endDate).format(defaultFormatReturn)
        : moment().format(defaultFormatReturn);

    try {
      const selectedDate = new DateObject(values[0]).format(defaultFormatReturn);
      const maximumDate = moment(selectedDate)
        .add(maxDaysSelected - 1, 'days')
        .format(defaultFormatReturn);
      const minimumDate = moment(selectedDate)
        .subtract(maxDaysSelected - 1, 'days')
        .format(defaultFormatReturn);
      const dateFormatted = moment(date.format(defaultFormatReturn));
      if (
        isDateRangeSelect &&
        values.length === 1 &&
        (dateFormatted.isBefore(minimumDate) || dateFormatted.isAfter(maximumDate))
      ) {
        props.disabled = true;
      }
    } catch {}

    if (moment(date.format()).isBefore(min)) {
      props.disabled = true;
    }
    if (max && moment(date.format()).isAfter(max)) {
      props.disabled = true;
    }

    return props;
  };

  const titleTable = backbone.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();

  const handleChangeDate = (selectedDates: DateObject | DateObject[]) => {
    if (datetimeChecked) {
      const newValues = getNewDatesWithTime(
        isDateRangeSelect || Array.isArray(selectedDates) ? selectedDates : [selectedDates],
        time,
      );
      setValues(newValues);
    } else if (calendarTypePicker === 'week') {
      setValues(selectedDates.map((date) => date.setDay(date.day + 1)));
    } else {
      setValues(isDateRangeSelect ? selectedDates : [selectedDates]);
    }
    setCurrentTab(CalendarOption.CUSTOM);
  };

  const getNewDatesWithTime = (dates, time) => {
    let [datetimeStart, datetimeEnd]: string | DateObject[] = dates;
    if (!datetimeEnd) {
      datetimeEnd = datetimeStart;
    }
    const format = 'YYYY-MM-DD';
    datetimeStart = datetimeStart.format(format).concat(' ' + time.timeStart);
    datetimeEnd = datetimeEnd.format(format).concat(' ' + time.timeEnd);

    const newValues = [datetimeStart, datetimeEnd].map(
      (time) => new DateObject({ date: time, format: 'YYYY-MM-DD HH:mm' }),
    );
    return newValues;
  };

  const handleChangeDateDebounce = React.useCallback(debounce(handleChangeDate, 100), [time, datetimeChecked]);

  const onSubmit = () => {
    if (values.length > 0) {
      let periodEnd;
      if (isMonthPicker) {
        const valueEnd = last(values).format(defaultFormatReturn);
        periodEnd = moment(valueEnd).endOf('month').format(defaultFormatReturn);
      }
      const format = datetimeChecked ? 'YYYY-MM-DD HH:mm' : defaultFormatReturn;
      const start = values[0].format(format) + (datetimeChecked ? ':00.000' : '');
      const end = last(values).format(format) + (datetimeChecked ? ':00.000' : '');
      if (!datetimeChecked) {
        setTime({
          timeStart: '00:00',
          timeEnd: '23:00',
        });
      }
      if (calendarCohort) {
        const startCohort = values[0].format('YYYY-MM-DD');
        const endCohort = last(values).format('YYYY-MM-DD');
        backbone.changeConfig('calendarCohort', currentTab);
        backbone.changeConfig('cohortDateRange', { dateFrom: startCohort, dateTo: endCohort });
        // remove group by when changing cohort daterange
        if (backbone.getConfig('visualizationType') === 'chart' && chartType != 'treemap') {
          backbone.changeConfig('groupBy', {});
        }
      } else {
        if (!value || start != value.dateFrom || end != value.dateTo) {
          onChange({ dateFrom: start, dateTo: periodEnd || end }, currentTab);
          setShouldShowLabel(true);
        }
      }
    }
    setOpen(false);
    backbone.changeVisibility('calendar', false);
  };

  const onClose = () => {
    setOpen(false);
    backbone.changeVisibility('calendar', false);
  };

  const handleCloseCalendarCohort = () => {
    backbone.changeVisibility('calendar', false);
  };

  const getTabs = () => {
    let calendarTabs = [];
    if (!isDateRangeSelect) calendarTabs = tabsSingle;
    else if (calendarCohort) calendarTabs = tabsCohort;
    else if (isMonthPicker) calendarTabs = tabsPeriodMonth;
    else calendarTabs = tabs;
    return calendarTabs.filter((tab) => !hiddenCalendarOptions.includes(tab?.option));
  };

  return {
    calendarCohort,
    currentValue,
    currentTab,
    open,
    setOpen,
    defaultDate,
    values,
    checkMatchCalendar,
    getDateLabel,
    handleClickTab,
    mapDays,
    titleTableClass,
    handleChangeDate,
    onSubmit,
    onClose,
    handleCloseCalendarCohort,
    calendarTypePicker,
    isDateRangeSelect,
    tabsSingle,
    getTabs,
    shouldShowLabel,
    calendarLabelRef,
    checkedHour,
    setCheckedHour,
    time,
    setTime,
    isDatetimePicker,
  };
};
