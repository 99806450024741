import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Clock = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.99999 0C5.4477 0 4.99999 0.447715 4.99999 1V6.3812L0.866012 8.76795C0.387719 9.04409 0.223844 9.65568 0.499986 10.134C0.776129 10.6123 1.38772 10.7761 1.86601 10.5L6.25129 7.96816C6.50983 7.90124 6.72779 7.7335 6.86071 7.50939C7.03514 7.26102 7.08998 6.94135 6.99999 6.64456V1C6.99999 0.447715 6.55227 0 5.99999 0Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Clock;
