import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { debounce, get } from 'lodash';
import * as React from 'react';
import CalendarInput from '../../form-control/calendar/calendar-input';

import {
  CalendarOption,
  getCohortDateRangeFromOption,
  DateValue,
} from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';

const SYNC_CALENDAR_EVENT = 'sync_calendar';

const ButtonSelectDate = () => {
  const backbone = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const cohortCalendarOption: CalendarOption = backbone.getConfig('calendarCohort');
  const cohortCalendarDateRange: DateValue = backbone.getConfig('cohortDateRange');
  const chartConfig = backbone.getConfig('chartConfig', {});
  const groupPeriodConfig = get(chartConfig, 'config.groupPeriod', '');

  // This linkToGlobalFilterOldChart variable is only for old chart config
  const linkToGlobalFilterOldChart = get(backbone.getConfig('chartConfig'), ['config', 'linkToGlobalFilter'], null);

  const isLinkedToGlobalFilter = (linkToGlobalFilterOldChart || backbone.getConfig('linkToGlobalFilter')) === 'yes';

  React.useEffect(() => {
    const handleEvent = (e) => {
      if (isLinkedToGlobalFilter) {
        handleChangeCalendarInput(e.detail.data.value, e.detail.data.option, false);
      }
    };
    window.addEventListener(SYNC_CALENDAR_EVENT, handleEvent);

    return () => {
      window.removeEventListener(SYNC_CALENDAR_EVENT, handleEvent);
    };
  }, []);

  const handleChangeCalendarInput = debounce((value, option, sendToGlobal = true) => {
    const eventDetails = {
      detail: {
        data: { value, option },
      },
    };
    if (sendToGlobal && isLinkedToGlobalFilter) {
      window.dispatchEvent(new CustomEvent(SYNC_CALENDAR_EVENT, eventDetails));
    } else {
      backbone.changeConfig('dateRange', value);
      if (ff.keep_block_refreshing) {
        const lastUpdatedAt = backbone.getConfig('lastUpdatedAt');
        if (lastUpdatedAt) {
          backbone.changeConfig('lastUpdatedAt', null);
        }
      }
      if (option) {
        backbone.changeConfig('calendarOption', option);
        if (option !== CalendarOption.CUSTOM) {
          backbone.changeConfig(
            'cohortDateRange',
            getCohortDateRangeFromOption(cohortCalendarOption, cohortCalendarDateRange, value),
          );
        }
      }
    }
  }, 1000);
  const hidden = backbone.getConfig('system.hiddenComponents', []);

  return (
    <div>
      {!hidden.includes('timeline') ? (
        <CalendarInput
          value={backbone.getConfig('dateRange')}
          defaultFormatReturn={'YYYY-MM-DD'}
          formatDateView={groupPeriodConfig === 'monthly' ? 'MMM, YYYY' : 'MMM DD, YYYY'}
          onChange={handleChangeCalendarInput}
          groupPeriodConfig={groupPeriodConfig}
        />
      ) : null}
    </div>
  );
};
export default ButtonSelectDate;
