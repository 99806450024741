import * as React from 'react';
import clsx from 'clsx';

import { Box, makeStyles, Checkbox } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';

/* Type */
type PropsStyles = {
  searchAble?: boolean;
  disabledCheckbox?: boolean;
  checked?: boolean;
  disabled?: boolean;
};

const useStylesCheckedBox = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '2px solid',
    borderRadius: '2px',
    borderColor: '#C2C7CB !important',
    width: '12px',
    height: '12px',
    '& .eip1-MuiSvgIcon-root': {
      width: 'auto',
    },
  },
  rootChecked: {
    backgroundColor: '#0369C7',
    borderColor: '#0369C7  !important',
    '&.icon_disabled': {
      backgroundColor: '#E4E7E9',
      borderColor: '#E4E7E9 !important',
      pointerEvents: 'none',
    },
  },
  icon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
      pointerEvents: 'none',
    },
  },
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
    '& .eip1-MuiButtonBase-root.eip1-MuiIconButton-root': {
      display: (props: PropsStyles) => (props.disabledCheckbox ? 'none' : 'flex'),
    },
  },
}));

type ICheckedBox = { icon: 'indeterminate' | 'checked' | 'default'; disabled?: boolean };
const CheckedBoxCustom = ({ icon, disabled = false }: ICheckedBox) => {
  const classes = useStylesCheckedBox();
  return (
    <Box className={clsx(classes.root, icon !== 'default' && classes.rootChecked, disabled ? 'icon_disabled' : '')}>
      {icon !== 'default' && <Icon type={icon} className={`${classes.icon} ${disabled ? 'icon_disabled' : null}`} />}
    </Box>
  );
};

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    '& .eip1-MuiCheckbox-colorSecondary.Mui-checked': {
      color: '#0080ff',
    },
    '& .eip1-MuiButtonBase-root': {
      marginRight: '0',
    },
  },
});

const CheckboxFormat = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Checkbox
        icon={<CheckedBoxCustom icon={'default'} />}
        checkedIcon={<CheckedBoxCustom icon={'checked'} />}
        defaultChecked={props.value}
        onChange={(e) => props.setValue(e.target.checked)}
      />
    </Box>
  );
};

export default CheckboxFormat;
