import { EIP_CONSTANT } from '@eip/next/lib/main';
import { CAMPAIGN_DETAIL_COMMON_MAPPING, getActionMenu } from '../../table-config/common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign details',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'ADS_PLACEMENT.id',
    'PRODUCT.id',
    // 'SUGGESTED_KEYWORD.id',
  ],
  views: [
    {
      id: 'ad_object',
      name: 'Ad object',
      excludeProperty: {
        dimension: ['negative_keyword', 'keyword', 'ad_object_master'],
        attribute: ['keyword_bidding_price', 'keyword_status', 'keyword_match_type', 'keyword_method'],
        metric: [],
      },
    },
    {
      id: 'keyword',
      name: 'Keyword',
      excludeProperty: {
        dimension: ['negative_keyword', 'ad_object'],
        attribute: [],
        metric: [],
      },
    },
    {
      id: 'negative_keyword',
      name: 'Negative keyword',
      excludeProperty: {
        dimension: ['keyword', 'ad_object'],
        attribute: ['keyword_bidding_price', 'keyword_match_type'],
        metric: [],
      },
    },
  ],
  view: {
    id: 'ad_object',
    name: 'Ad object',
  },
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  disabledComponents: {
    dimension: ['marketplace', 'country', 'storefront', 'ad_tool', 'ad_object'],
  },
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'marketplace',
          'country',
          'storefront',
          'ad_tool',
          'ad_object',
          'ad_object_master',
          'keyword',
          'negative_keyword',
          'campaign_tag',
          'ad_object_tag',
          'keyword_tag',
          'campaign_status',
          'campaign_advertising_cost',
          'ad_object_budget',
          'ad_object_status',
          'keyword_bidding_price',
          'keyword_status',
          'keyword_match_type',
          'keyword_method',
        ],
      }
    : {}),
  mapping: {
    // ---------------------------- Dimension ----------------------------------//
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        campaignName: 'ADS_CAMPAIGN.name',
        campaignCode: 'ADS_CAMPAIGN.campaign_code',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
      },
    },

    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },

    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },

    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
        ...(ff.groupby_display_location
          ? {
              id: 'ADTOOL.ads_tool',
              label: 'ADTOOL.ads_tool_name',
              type: 'ADTYPE.ads_type',
              createdAt: 'ADS_PLACEMENT.created_at',
              updatedAt: 'ADS_PLACEMENT.updated_at',
            }
          : {}),
      },
      tableConfiguration: null,
    },

    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT',
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        productIds: 'PRODUCT.product_sid',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        type: 'ADS_PLACEMENT.type',
        masterObjectType: 'ADS_OBJECT.master_object_type',
        subtext: 'PRODUCT.product_sid',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },
    ad_object_master: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        type: 'ADS_PLACEMENT.type',
        masterObjectType: 'ADS_OBJECT.master_object_type',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
      staticValue: {
        disabled: true,
      },
    },
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'compact',
      groupByColumn: 'ADS_PLACEMENT.name',
      valueGetter: {
        groupByColumn: 'ADS_PLACEMENT.name',
        data: 'keyword',
        adPlacementId: 'ADS_PLACEMENT.id',
        label: 'ADS_PLACEMENT.name',
        createdAt: 'ADS_PLACEMENT.created_at',
        updatedAt: 'ADS_PLACEMENT.updated_at',
        isAutomatedAds: 'ADS_PLACEMENT.automated_ads',
        marketplace: 'MARKETPLACE.marketplace_name',
        type: 'ADS_PLACEMENT.type',
        ...(ff.groupby_display_location
          ? {
              id: 'ADS_PLACEMENT.id',
              objectId: 'ADS_OBJECT.id',
            }
          : {}),
      },
      ...(ff.compact_dependency_data
        ? {
            staticValue: {
              dependencyData: ['ADS_OBJECT.id'],
            },
          }
        : {}),
      tableConfiguration: null,
    },

    negative_keyword: {
      title: 'Negative keyword',
      propertyType: 'dimension',
      cellFormat: 'compact',
      valueGetter: {
        data: 'negativeKeyword',
        displayField: 'ADS_PLACEMENT.name',
        type: 'ADS_PLACEMENT.type',
        ...(ff.groupby_display_location
          ? {
              id: 'ADS_PLACEMENT.id',
              objectId: 'ADS_OBJECT.id',
            }
          : {}),
      },
      ...(ff.compact_dependency_data
        ? {
            staticValue: {
              dependencyData: ['ADS_OBJECT.id'],
            },
          }
        : {}),
      tableConfiguration: null,
    },
    // ---------------------------- Attribute ----------------------------------//
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.general_tag',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.delete_ad_campaign_object
      ? {
          campaign_status: {
            title: 'Campaign status',
            propertyType: 'attribute',
            cellFormat: 'status',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.status',
            valueGetter: {
              label: 'ADS_CAMPAIGN.status',
              status: 'ADS_CAMPAIGN.status',
              tooltips: 'ADS_CAMPAIGN.tooltips',
            },
            menu: [
              {
                name: 'Publish now',
                icon: 'upload',
                condition: [
                  {
                    field: 'ADS_CAMPAIGN.status',
                    operator: 'CONTAIN',
                    values: ['DRAFT'],
                  },
                ],
                payload: {
                  field: {
                    status: 'ADS_CAMPAIGN.status',
                  },
                  static: {
                    status: 'PUBLISHING',
                  },
                },
              },
              {
                name: 'Pause it',
                icon: 'pausedRounded',
                condition: [
                  {
                    field: 'ADS_CAMPAIGN.status',
                    operator: 'CONTAIN',
                    values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
                  },
                ],
                payload: {
                  field: {
                    status: 'ADS_CAMPAIGN.status',
                  },
                  static: {
                    status: 'SUSPENDED',
                  },
                },
              },
              {
                name: 'Resume it',
                icon: 'refreshRounded',
                condition: [
                  {
                    field: 'ADS_CAMPAIGN.status',
                    operator: 'CONTAIN',
                    values: ['SUSPENDED'],
                  },
                ],
                payload: {
                  field: {
                    status: 'ADS_CAMPAIGN.status',
                  },
                  static: {
                    status: 'RECOVERING',
                  },
                },
              },
            ],
          },
        }
      : {}),
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    campaign_advertising_cost: {
      title: 'Campaign advertising cost',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.max_bidding_price',
      valueGetter: {
        value: 'ADS_CAMPAIGN.max_bidding_price',
        currency: 'STOREFRONT.currency',
      },
      menu: [
        {
          title: 'Campaign advertising cost',
          name: 'Change campaign advertising cost',
          icon: 'pencil',
          editor: 'AdvertisingCostEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.max_bidding_price',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
          disable: true,
        },
      ],
    },

    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },

    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED', 'INELIGIBLE'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
      ],
    },

    keyword_bidding_price: {
      title: 'Keyword bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_PLACEMENT.bidding_price',
      valueGetter: {
        value: 'ADS_PLACEMENT.bidding_price',
        id: 'ADS_PLACEMENT.id',
        currency: 'STOREFRONT.currency',
        isAutomatedAds: 'ADS_PLACEMENT.automated_ads',
        marketplace: 'MARKETPLACE.marketplace_name',
      },
      menu: [
        {
          title: 'Keyword bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_PLACEMENT.name',
            },
            static: {
              primaryKey: 'ADS_PLACEMENT.id',
            },
          },
        },
      ],
    },

    keyword_status: {
      title: 'Keyword status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.status',
      valueGetter: {
        label: 'ADS_PLACEMENT.status',
        status: 'ADS_PLACEMENT.status',
        id: 'ADS_PLACEMENT.id',
        tooltips: 'ADS_PLACEMENT.tooltips',
      },
      menu: [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_PLACEMENT',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
      ],
    },

    keyword_match_type: {
      title: 'Keyword match type',
      propertyType: 'attribute',
      cellFormat: 'textMatchType',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.match_type',
      valueGetter: {
        value: 'ADS_PLACEMENT.match_type',
        id: 'ADS_PLACEMENT.id',
      },
      menu: getActionMenu('keyword_match_type'),
    },
    keyword_method: {
      title: 'Keyword method',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      valueGetter: {
        value: 'ADS_PLACEMENT.automated_ads',
        label: 'ADS_PLACEMENT.automated_ads',
      },
      staticValue: {
        displayType: 'bidding_method',
      },
      tableConfiguration: null,
      menu: [
        {
          title: 'Turn all to manual keyword',
          name: 'Turn all to manual keyword',
          icon: 'refresh',
          condition: [
            {
              field: 'ADS_PLACEMENT.automated_ads',
              operator: 'CONTAIN',
              values: [1],
            },
          ],
          payload: {
            field: {
              value: 'ADS_PLACEMENT.automated_ads',
            },
            static: {
              value: 0,
            },
          },
        },
        {
          title: 'Turn all to Tokopedia automation',
          name: 'Turn all to Tokopedia automation',
          icon: 'refresh',
          condition: [
            {
              field: 'ADS_PLACEMENT.automated_ads',
              operator: 'CONTAIN',
              values: [0],
            },
          ],
          payload: {
            field: {
              value: 'ADS_PLACEMENT.automated_ads',
            },
            static: {
              value: 1,
            },
          },
        },
        {
          title: 'Add Botep automation',
          name: 'Add Botep automation',
          icon: 'epsiloDisable',
          disable: true,
        },
      ],
    },
    // ---------------------------- metric ----------------------------------//
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.position_updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
    ...CAMPAIGN_DETAIL_COMMON_MAPPING,
  },
};
