import { EIP_CONSTANT } from '@eip/next/lib/main';
import { EpsiloLiveDashboard } from './live-dashboard';

/**
 * ff.mass_operation_management:start
 */
import { MASS_OPERATION_DASHBOARD_CONFIG } from './chart-config/mass-operation-dashboard';
/**
 * ff.mass_operation_management:end
 */

export default {
  blockType: 'liveDashboard',
  label: 'Live Dashboard',
  blockComponent: EpsiloLiveDashboard,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 12,
  },
  packagedConfigs: {},
};
