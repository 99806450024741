import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const ProgressIndicatorDot = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="8" viewBox="0 0 9 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.942383 4C0.942383 1.79086 2.73324 0 4.94238 0C7.15152 0 8.94238 1.79086 8.94238 4C8.94238 6.20914 7.15152 8 4.94238 8C2.73324 8 0.942383 6.20914 0.942383 4Z"
      fill={props.color}
    />
  </SvgIcon>
);
export default ProgressIndicatorDot;
