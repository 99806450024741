import { atom } from 'jotai';

const narrativePopup = atom({
  isDisplay: false,
  content: '',
  submit: (content: string) => {},
  ref: null,
  backboneConfig: null,
  availableVars: [],
});

export { narrativePopup };
