/* eslint-disable react/prop-types */
import CalendarRange from '@ep/insight-ui/elements/form-control/calendar/calendar-range';
import { DateValue } from '@ep/insight-ui/elements/form-control/calendar/calendar-range/calendar-range';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { IValueDateRange } from '@ep/insight-ui/elements/table/format/date-range-format';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const useStyles = makeStyles(() => ({
  header: {
    paddingLeft: '0px',
  },
}));

type Props = {
  data: {
    value: IValueDateRange;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const TimeLineEditor = ({ data, title = '', payload, onSubmit, setAnchorEl }: Props) => {
  const classes = useStyles();
  const defaultFormatReturn = _.get(data, 'value.defaultFormatReturn', 'YYYY-MM-DD');
  const minDate = _.get(data, 'value.minDate', '');
  const maxDate = _.get(data, 'value.maxDate', '');
  const timelineId = _.get(data, 'value.id', null);
  const restrict = _.get(payload, 'restrict', []);
  const defaultValue = React.useMemo(() => {
    return {
      dateFrom: data.value.date_from,
      dateTo: data.value.date_to,
    };
  }, [data.value]);
  const [value, setValue] = React.useState<DateValue>(defaultValue);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const checkDisableComponent = (name: string) => {
    if (restrict.length > 0) {
      return restrict.some((i) => {
        if (i.disableComponent.includes(name)) {
          const operator = _.get(i, 'condition.operator');
          if (operator)
            switch (operator) {
              case 'CONTAIN':
                return i.condition.values.includes(data.node.data[i.condition.field]);
              case 'NOT_CONTAIN':
                return !i.condition.values.includes(data.node.data[i.condition.field]);
              default:
                return false;
            }
          else return true;
        }
        return false;
      });
    }
    return _.get(data, 'value.disabledChooseStartDate', false);
  };

  const disabledChooseStartDate = checkDisableComponent('startDate'); // _.get(data, 'value.disabledChooseStartDate', false);

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit({
      value: { ...data.value, id: timelineId, date_from: value.dateFrom, date_to: value.dateTo },
      row: data.node.data,
    });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '8px', maxWidth: '524px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      <CalendarRange
        value={defaultValue}
        onChange={setValue}
        defaultFormatReturn={defaultFormatReturn}
        disabledChooseStartDate={disabledChooseStartDate}
        minDate={minDate}
        maxDate={maxDate}
        clickOnSubmit
      />
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};
export default TimeLineEditor;
