import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import moment from 'moment';
import * as React from 'react';
import Condition from '../../form-controls/condition';
import ConditionRow from '../../form-controls/condition/condition-row';
import NumberFormControl from '../../form-controls/form-control-number';
import OperatorValue from '../../form-controls/operator-value';
import GroupSelectionChild from '../../form-controls/option-operator';
import TimeRange from '../../form-controls/time-range';
import TimeZone from '../../form-controls/time-zone';
import RepeatingOn from '../../form-controls/repeating-on';
import RepeatingAt from '../../form-controls/repeating-at';
import Selection from '../../form-controls/selection';

type Props = {
  label?: string;
};

type ScriptScenarioPropType = {
  label: string;
  value?: {
    timeline_from?: string;
    timeline_to?: string;
    timeZone?: string;
    timeRange: {
      dateFrom: string;
      dateTo: string;
    };
    condition: {
      filters: {
        combinator: 'and' | 'or';
        targetObject: string;
        attribute: string;
        timeQuery: string;
        timeUnit?: string;
        timeQuantity?: number;
        conditionOperator: string;
        value: number;
      }[];
    };
    action?: {
      type: string;
      object: string;
      unit: string;
      value?: number;
      maxValue?: number;
    };
    repeating: any;
    repeatingOn: any;
    repeatingAt: any;
  };
  conditionOptions: React.ComponentProps<typeof ConditionRow>['options'];
  actionOptions: {
    actionObject: OptionSelectType[];
    actionType: OptionSelectType[];
    actionUnit: OptionSelectType[];
    capCurrency: string;
    valueCurrency: string;
  };
  onChange: (arg: Record<string, any>) => Promise<any>;
  // dataMenu: DropdownMenuDataType[][];
  menuFuncs?: {
    addFunc: () => void;
    duplicateFunc: () => void;
    removeFunc: () => void;
  };
  disableEdit?: boolean;
  repeating: OptionSelectType[];
  requestOptions?: any;
  validUpdatingScenario?: boolean;
};

const useStyle = makeStyles({
  numberForm: {
    '& > div > .eip1-MuiGrid-root': {
      alignItems: 'flex-start',
      '& > div.eip1-MuiGrid-item:first-child': {
        minHeight: 32,
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
});

const ScenarioKeepForm = (
  props: ScriptScenarioPropType = {
    label: 'Scenario:',
    conditionOptions: null,
    actionOptions: null,
    onChange: () => undefined,
    value: {
      timeRange: {
        dateFrom: moment().format('YYYY-MM-DD'),
        dateTo: moment().add(7, 'day').format('YYYY-MM-DD'),
      },
      timeZone: '+07:00-Asia/Ho_Chi_Minh',
      action: {
        object: '',
        type: '',
        value: 0,
        unit: '',
        maxValue: 0,
      },
      condition: { filters: [] },
      repeating: 'DAILY',
      repeatingAt: null,
      repeatingOn: null,
    },
    // dataMenu: [],
    menuFuncs: {
      addFunc: () => undefined,
      duplicateFunc: () => undefined,
      removeFunc: () => undefined,
    },
    disableEdit: false,
    validUpdatingScenario: true,
  },
) => {
  const classes = useStyle();
  const dataMenu: DropdownMenuDataType[][] = [
    [
      {
        title: 'Add new scenario',
        icon: 'plus',
        onClick: props.menuFuncs.addFunc,
      },
      {
        title: 'Duplicate',
        icon: 'duplicate',
        iconSize: '16px',
        onClick: props.menuFuncs.duplicateFunc,
      },
      {
        title: 'Remove',
        icon: 'remove',
        onClick: props.menuFuncs.removeFunc,
      },
    ],
  ];

  const isDisabled = props.disableEdit || !props.validUpdatingScenario;
  return (
    <ExpansionForm level="1" label={props.label} detailContentSize={'full'} defaultExpanded={true}>
      <Formik
        initialValues={props.value}
        validateOnChange={true}
        enableReinitialize
        validate={(data) => {
          return props.onChange(data).then(() => {
            return {};
          });
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, handleChange, setFieldValue }) => (
          <Form>
            <ExpansionForm label={'Generic Information'} detailContentSize={'half'} defaultExpanded={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TimeRange
                    label={'Timeline'}
                    value={values.timeRange}
                    dateFormat={'YYYY-MM-DD'}
                    onChange={(value) => setFieldValue('timeRange', value)}
                    disabled={isDisabled}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TimeZone
                    disabled
                    label={'Time zone'}
                    name="timezone"
                    value={''}
                    onChange={handleChange}
                    placeholder={'Storefront timezone'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Selection
                    options={props.repeating}
                    onChange={handleChange('repeating')}
                    label={'Repeat'}
                    value={values.repeating}
                    placeholder="Choose repeat"
                    disabled={isDisabled}
                    requestOption={props.requestOptions.getRepeating}
                  />
                </Grid>
                <RepeatingOn
                  repeatingValue={values.repeating}
                  value={values.repeatingOn}
                  onChange={(value) => setFieldValue('repeatingOn', value)}
                  disabled={isDisabled}
                />
                <RepeatingAt
                  repeatingValue={values.repeating}
                  value={values.repeatingAt}
                  onChange={(value) => setFieldValue('repeatingAt', value)}
                  disabled={isDisabled}
                />
              </Grid>
            </ExpansionForm>
            <ExpansionForm label={'Condition'} detailContentSize={'full'} defaultExpanded={true}>
              <Condition
                condition={values.condition}
                options={props.conditionOptions}
                name={'condition'}
                onChange={(condition) => {
                  setFieldValue('condition', condition);
                }}
                disabled={isDisabled}
              />
            </ExpansionForm>
            <ExpansionForm label={'Action'} detailContentSize={'half'} defaultExpanded={true}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {props.actionOptions && (
                    <GroupSelectionChild
                      label={'Action'}
                      operatorOptions={props.actionOptions.actionType}
                      objectOptions={props.actionOptions.actionObject}
                      optionProps={{
                        value: values.action.object,
                        onChange: (e) => {
                          setFieldValue('action', {
                            object: e.target.value,
                            type: '',
                            value: 0,
                            unit: '',
                            maxValue: 0,
                          });
                        },
                      }}
                      operatorProps={{
                        value: values.action.type,
                        onChange: handleChange('action.type'),
                      }}
                      disabled={isDisabled}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  {props.actionOptions && (
                    <OperatorValue
                      label={'Value'}
                      operators={props.actionOptions.actionUnit}
                      operatorProps={{
                        value: values.action.unit,
                        onChange: handleChange('action.unit'),
                      }}
                      valueProps={{
                        value: values.action.value,
                        onChangeNumber: handleChange('action.value'),
                        placeholder: '',
                      }}
                      currency={
                        props.actionOptions[values.action.unit === 'STEP_PERCENTAGE' ? 'valueCurrency' : 'capCurrency']
                      }
                      disabled={isDisabled}
                    />
                  )}
                </Grid>
                <Grid item xs={12} className={classes.numberForm}>
                  <NumberFormControl
                    label={
                      values.action?.object === 'BIDDING_PRICE' && values.action?.type === 'DECREASE'
                        ? 'Minimum value'
                        : 'Maximum value'
                    }
                    value={values.action.maxValue}
                    onChange={handleChange('action.maxValue')}
                    placeholder={'Bidding price'}
                    currency={props.actionOptions.capCurrency}
                    helperText={''}
                    disabled={isDisabled}
                  />
                </Grid>
              </Grid>
            </ExpansionForm>
          </Form>
        )}
      </Formik>
    </ExpansionForm>
  );
};

export default ScenarioKeepForm;
