import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { noSelectStyled, responsiveChart } from '@ep/insight-ui/lib/epsilo-theme';
import { getComponent } from '@ep/insight-ui/system/backbone/atom/app';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeStyles } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import { useAtomValue } from 'jotai';
import * as _ from 'lodash';
import color from 'color';
import { set, uniq } from 'lodash';
import * as React from 'react';
import ChartState, { IStateChartValue } from '../../chart-loading-state/chart-loading-state';
import ChartSize, { breakPointSize, TSize } from '../../chart-size';
import { IData, IOption } from '../../type';
import * as LineHook from './hooks';
import * as LineHookV2 from './hooks-v2';
import { Lined } from './line-template';
import { colorsChartLegend, convertColor } from '@ep/insight-ui/chartlib/helper';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import ChartDataLabels from 'chartjs-plugin-datalabels';

const useStyles = makeStyles({
  ratio_wrap: responsiveChart.ratio_wrap as CreateCSSProperties,
  ratio_item: responsiveChart.ratio_item as CreateCSSProperties,
  wrapper: {
    width: '100%',
    height: '100%',
  },
  wrapperMobile: {
    width: '100%',
    height: '100%',
  },
  wrapperResponsive: {
    width: '80%',
    height: '90%',
  },
  wrapperPopup: {
    width: '260px',
    height: '400px',
    '& .eip1-MuiTypography-h2': {
      fontSize: '18px',
    },
  },
  chart: {
    width: '100%',
    height: '100%',
    flex: '1 0 0',
  },
  wrapperRes: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  wrapperLegendLineSingle: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '& ul.legendDiv': {
      display: 'flex',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      width: 'auto',
      height: '30px',
      '& li.legendItem': {
        margin: 0,
        marginRight: 0,
        fontSize: '12px',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
    position: 'relative',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
  crisper: {
    position: 'absolute',
  },
  darkmode: {
    height: 25,
  },
});

type ILineChart = {
  keyChart?: string;
  title?: string;
  value?: string;
  cohortValue?: number;
  percent?: number;
  currency?: string;
  dataList: IData;
  optionList: IOption;
  stateChart: IStateChartValue;
  isBorder?: boolean;
  forwardMeaning?: boolean;
  keepHeadContent?: boolean;
  disabledPaddingChart?: boolean;
  type: 'normal' | 'special';
  isSettingYAxis?: boolean;
  isSingleChart?: boolean;
  isHideTotal?: boolean;
  isHidePercent?: boolean;
  setIsDarkMode;
  showLegend?: boolean;
  colorOption?: string[];
  redraw?: boolean;
  rawTotal?: number;
  rawCohortTotal?: number;
  valueDisplay?: string;
  showLabelOnTop?: boolean;
  colKey?: string;
  subTitle?: string;
};

const LineChart = ({
  keyChart = '1',
  title = '',
  value = '',
  cohortValue = null,
  percent = 0,
  currency = '',
  dataList,
  optionList,
  stateChart,
  isBorder,
  forwardMeaning,
  keepHeadContent = false,
  disabledPaddingChart = false,
  type = 'normal',
  isSettingYAxis = false,
  isSingleChart = false,
  isHideTotal = false,
  isHidePercent = false,
  showLegend = false,
  colorOption = [],
  redraw = true,
  rawTotal,
  rawCohortTotal,
  valueDisplay,
  showLabelOnTop = false,
  colKey = '',
  subTitle = '',
}: ILineChart) => {
  const classes = useStyles({ isSingleChart });
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const darkmode = React.useContext(DarkModeContext);
  const divRef = React.useRef<HTMLDivElement>(null);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const [width, setWidth] = React.useState(window.innerWidth);
  const [chartSize, setchartSize] = React.useState<TSize>('large');
  const ref = React.useRef(null);
  const idLegend = React.useMemo(() => `lineChartLegend_${keyChart}`, []);
  const isMobile = containerClass === 'eres--small';
  const [enhanceOptionList, setEnhanceOptionList] = React.useState(optionList);
  const configs = {
    percent: '15%',
    width,
    legendDivID: idLegend,
    // ref: divRef,
  };
  const { htmlLegendPlugin } = type === 'normal' ? LineHook.funcConfigs(configs) : LineHookV2.funcConfigs(configs);

  const metric = backbone.getConfig('metric');
  const columnOrder = backbone.getConfig('columnOrder');
  const mapping = backbone.getConfig('mapping');
  const showLegendConfig = backbone.getConfig('chartConfig.config.showLegend', 'no') === 'yes';
  const showXAxisConfig = backbone.getConfig('chartConfig.config.showXAxis', 'no') === 'yes';
  const yAxisConfig = backbone.getConfig('chartConfig.config.yAxisConfig', '');
  const zAxisConfig = backbone.getConfig('chartConfig.config.zAxisConfig', '');

  React.useEffect(() => {
    if (ref.current) {
      const metricOrder = columnOrder.filter((el) => metric.includes(el));
      const datasets = _.get(ref.current, ['data', 'datasets'], []);
      const isEqualOrder = datasets.every((data) => data.order === metricOrder.indexOf(data.key));
      if (metricOrder.length === datasets.length && !isEqualOrder && valueDisplay != 'single') {
        let colorIndex = 0;
        const colors = [...ref.current.data.datasets]
          .sort((a, b) => metricOrder.indexOf(a.key) - metricOrder.indexOf(b.key))
          .reduce((carry, item, index) => {
            const chartColorConfig = _.get(item, ['chartColorConfig'], null);
            let color, hoverBorderColor;

            if (item.isCohort) {
              color = '#D4DDED';
              hoverBorderColor = convertColor('#D4DDED');
            } else if (chartColorConfig) {
              color = chartColorConfig;
              hoverBorderColor = convertColor(chartColorConfig);
            } else if (colorOption?.[colorIndex]) {
              color = colorOption[colorIndex];
              hoverBorderColor = convertColor(colorOption[colorIndex]);
              colorIndex++;
            } else {
              color = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].stroke;
              hoverBorderColor = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].pointBorderColor;
              colorIndex++;
            }

            return {
              ...carry,
              [`${item.key}_${index}`]: {
                color,
                hoverBorderColor,
              },
            };
          }, {});

        // Update datasets for formula colors
        const formulaValues = ref.current.data.datasets.reduce((a, b) => {
          return {
            ...a,
            [b.key]: b.data,
          };
        }, {});

        ref.current.data.datasets = ref.current.data.datasets.map((el) => {
          const order = Math.max(metricOrder.indexOf(el.key), 0);
          const keyColor = `${el.key}_${order}`;
          const chartColor = _.get(colors, [keyColor, 'color'], '');
          const hoverBorderColor = _.get(colors, [keyColor, 'hoverBorderColor'], '');
          const chartStyle = el.chartStyle;
          const formulaColor = _.get(mapping, [el.key, 'valueGetter', 'chartColor'], '');
          let backgroundColor, hoverBackgroundColor;
          if (formulaColor && formulaColor.startsWith('=') && chartStyle === 'bar') {
            backgroundColor = el.data.map((dt, i) => {
              const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                return {
                  ...carry,
                  [key]: value[i],
                };
              }, {});

              return (
                toValue(formulaColor, {
                  value: dt,
                  legend: el.label,
                  ...formulaValue,
                }) || colors[keyColor].color
              );
            });
            hoverBackgroundColor = el.data.map((dt, i) => {
              const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                return {
                  ...carry,
                  [key]: value[i],
                };
              }, {});
              return (
                convertColor(
                  toValue(formulaColor, {
                    value: dt,
                    legend: el.label,
                    ...formulaValue,
                  }),
                ) || colors[keyColor].hoverBorderColor
              );
            });
          }

          return {
            ...el,
            borderColor: backgroundColor ? uniq(backgroundColor) : chartColor,
            pointHoverBackgroundColor: backgroundColor || chartColor,
            pointHoverBorderColor: hoverBackgroundColor || hoverBorderColor,
            ...(chartStyle === 'area'
              ? { fill: 'start', backgroundColor: color(chartColor).lightness(90).fade(0.2).rgb().string() }
              : {}),
            ...(chartStyle === 'bar' ? { backgroundColor: backgroundColor || chartColor } : {}),
            order,
          };
        });
        ref.current.update('reOrder');
      }
    }
  }, [metric, columnOrder, ref.current]);

  const isDarkMode = darkmode.isDarkMode;

  const newDataList = React.useMemo(() => {
    if (dataList) {
      if (!dataList?.datasets) return {};

      const tmpDataList = JSON.parse(JSON.stringify(dataList)) as IData;
      if (chartSize === 'small') {
        tmpDataList.datasets.forEach((data) => {
          data.pointRadius = type === 'normal' ? 6 : 3;
          data.pointBorderWidth = type === 'normal' ? 6 : 3;
          data.pointHoverRadius = type === 'normal' ? 3 : 3;
          data.pointHoverBorderWidth = type === 'normal' ? 6 : 3;
        });
        return tmpDataList;
      }
      if (chartSize === 'medium') {
        tmpDataList.datasets.forEach((data) => {
          data.pointRadius = type === 'normal' ? 12 : 6;
          data.pointBorderWidth = type === 'normal' ? 12 : 6;
          data.pointHoverRadius = type === 'normal' ? 6 : 6;
          data.pointHoverBorderWidth = type === 'normal' ? 12 : 6;
        });
        return tmpDataList;
      }
      tmpDataList.datasets.forEach((data) => {
        data.pointRadius = type === 'normal' ? 16 : 9;
        data.pointBorderWidth = type === 'normal' ? 16 : 9;
        data.pointHoverRadius = type === 'normal' ? 8 : 9;
        data.pointHoverBorderWidth = type === 'normal' ? 16 : 9;
      });
      return tmpDataList;
    }
  }, [chartSize, dataList]);

  const optionListRef = React.useRef(optionList);

  const onUpdateSize = () => {
    if (optionListRef.current) {
      set(optionListRef.current, `scales.yAxes.display`, false);
      const scaleList = Object.keys(_.get(optionListRef.current, ['scales'], {}));
      const [y, yFormula, yLabel, grid] = yAxisConfig.split('\n').map((el) => (el ? el.trim() : ''));
      const [z, zFormula, zLabel] = zAxisConfig.split('\n').map((el) => (el ? el.trim() : ''));
      const matchedY = scaleList.find(
        (i) => toValue(y, { value: i }, true) == true || toValue(y, { value: i }, true) == i,
      );
      if (matchedY) {
        set(optionListRef.current, `scales.${matchedY}.display`, true);
        set(optionListRef.current, `scales.${matchedY}.position`, 'left');
        set(optionListRef.current, `scales.${matchedY}.grid.display`, grid == 'true');
        const dt = []
          .concat(newDataList?.datasets)
          .find((el) => el?.yAxisID == matchedY || (matchedY == 'yAxes' && !el?.yAxisID));
        if (dt?.formatLabel || yFormula) {
          set(optionListRef.current, `scales.${matchedY}.ticks.callback`, (value) => {
            return toValue(yFormula || dt?.formatLabel, { value, currency: dt?.currency });
          });
        }
        if (yLabel) {
          set(optionListRef.current, `scales.${matchedY}.title.display`, true);
          set(
            optionListRef.current,
            `scales.${matchedY}.title.text`,
            toValue(yLabel, { ...(dt || {}), value: matchedY }),
          );
        }
      }
      const matchedZ = scaleList.find(
        (i) => toValue(z, { value: i }, true) == true || toValue(z, { value: i }, true) == i,
      );
      if (matchedZ) {
        set(optionListRef.current, `scales.${matchedZ}.display`, true);
        set(optionListRef.current, `scales.${matchedZ}.position`, 'right');
        const dt = []
          .concat(newDataList?.datasets)
          .find((el) => el?.yAxisID == matchedZ || (matchedZ == 'yAxes' && !el?.yAxisID));
        if (dt?.formatLabel || zFormula) {
          set(optionListRef.current, `scales.${matchedZ}.ticks.callback`, (value) => {
            return toValue(zFormula || dt?.formatLabel, { value, currency: dt?.currency });
          });
        }
        if (zLabel) {
          set(optionListRef.current, `scales.${matchedZ}.title.display`, true);
          set(
            optionListRef.current,
            `scales.${matchedZ}.title.text`,
            toValue(zLabel, { ...(dt || {}), value: matchedZ }),
          );
        }
      }

      if (isSettingYAxis && ff.reuse_our_chart) {
        if (showXAxisConfig) {
          if (isDarkMode) {
            set(optionListRef.current, 'scales.x.ticks.color', '#FFF');
          } else {
            set(optionListRef.current, 'scales.x.ticks.color', '#666');
          }
          set(optionListRef.current, 'scales.x.grid.display', false);
          set(optionListRef.current, 'scales.x.display', true);
        } else {
          set(optionListRef.current, 'scales.x.display', false);
          set(optionListRef.current, 'scales.xAxes.display', false);
        }
      } else if (optionListRef.current.scales) {
        set(optionListRef.current, 'scales.x.display', false);
      }

      if (
        divRef.current.offsetWidth < breakPointSize.width.medium ||
        divRef.current.offsetHeight < breakPointSize.height.medium
      ) {
        setEnhanceOptionList({ ...optionListRef.current });
        setchartSize('small');
        return;
      }
      if (
        divRef.current.offsetWidth < breakPointSize.width.large ||
        divRef.current.offsetHeight < breakPointSize.height.large
      ) {
        setEnhanceOptionList({ ...optionListRef.current });
        setchartSize('medium');
        return;
      }

      if (isSettingYAxis && ff.reuse_our_chart) {
        showXAxisConfig ?? set(optionListRef.current, 'scales.x.display', true);
      } else {
        set(optionListRef.current, 'scales.x.display', false);
      }
      setEnhanceOptionList({ ...optionListRef.current });
      setchartSize('large');
    }
  };

  // React.useEffect(() => {
  //   const ro = new ResizeObserver(onUpdateSize);
  //   if (divRef.current) {
  //     ro.observe(divRef.current);
  //   }
  //   return () => {
  //     ro.disconnect();
  //   };
  // }, [ref, divRef]);

  React.useEffect(() => {
    optionListRef.current = _.cloneDeep(optionList);
    onUpdateSize();
  }, [optionList, isDarkMode]);

  const ComponentAtom = React.useRef(getComponent(ChartState)).current;

  const ChartStateCom = useAtomValue(ComponentAtom);

  return (
    <div
      ref={divRef}
      id={`divRef_${idLegend}`}
      className={clsx({
        [classes.wrapper]: !isMobile,
        [classes.wrapperMobile]: isMobile,
      })}
      style={{ display: 'flex', flexDirection: 'column', backgroundColor: isDarkMode ? '#253645' : 'inherit' }}
    >
      <ChartSize size={chartSize} isBorder={isBorder}>
        <ChartStateCom
          keepHeadContent={keepHeadContent}
          stateChart={stateChart}
          title={title}
          value={value}
          cohortValue={cohortValue}
          currency={currency}
          percent={percent}
          isHideTotal={isHideTotal}
          isHidePercent={isHidePercent}
          rawTotal={rawTotal}
          rawCohortTotal={rawCohortTotal}
          colKey={colKey}
          subTitle={subTitle}
        >
          <div className={classes.wrapperRes} style={{ margin: disabledPaddingChart ? '0 -10px' : '0px' }}>
            <div className={classes.chart}>
              <Lined
                data={newDataList}
                options={enhanceOptionList}
                plugins={[htmlLegendPlugin, ...(showLabelOnTop && enhanceOptionList ? [ChartDataLabels] : [])]}
                refElement={ref}
                redraw={redraw}
              />
            </div>
            <div
              className={showLegendConfig ? classes.wrapperLegendLineSingle : classes.wrapperLegendLine}
              style={{
                display: chartSize === 'large' ? 'flex' : showLegend && showLegendConfig ? 'block' : 'none',
              }}
            >
              <div className={classes.legendDiv} id={idLegend}></div>
            </div>
          </div>
        </ChartStateCom>
      </ChartSize>
    </div>
  );
};

export default LineChart;
