import * as React from 'react';
import { eipRequest as request, EIP_CONSTANT } from '@eip/next/lib/main';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

const API_PUT_USER = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/root-management/modify-workspace-user';

export const usePutUser = ({ dataInput, setDataInput, setOpen }: any) => {
  const { onToastMultiple } = useToast();
  const putUser = async () => {
    const userArray = dataInput.split('\n');
    const res = await request.put(API_PUT_USER, {
      action: 'ADD', // ADD or REMOVE
      arrayUserEmail: userArray,
      workspaceCode: 'WP1001',
    });
    if (res.serviceResponse) {
      if (res.serviceResponse.code === 1000) {
        onToastMultiple({
          title: 'Invite new users failed',
          variant: 'error',
          messages: [res.serviceResponse.message],
        });
      }
      if (res.serviceResponse.code === 0) {
        onToastMultiple({
          title: 'Successfully invited',
          variant: 'success',
          messages: [res.serviceResponse.message],
        });
      }
    }
    setOpen(false);
    setDataInput('');
    return res;
  };
  return { putUser };
};
