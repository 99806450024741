import * as React from 'react';
import * as _ from 'lodash';
// import { IRowEDashboard, IDateValue } from '.././';
import { ITreeMapDataChart } from '@ep/insight-ui/chartlib/type';
import TreeMapChartResponsive from '@ep/insight-ui/chartlib/treemap-chart/treemap-chart';
import { IRowEDashboard } from '../mocks/treemap';
import { funcConfigs } from '@ep/insight-ui/chartlib/treemap-chart/hooks';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { IStatusChartBB } from '../dashboard-container';
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { TreeChartOptions, LegendPositions } from '@carbon/charts/interfaces';
import ChartTableDetail from '@ep/insight-ui/chartlib/chart-table-detail/chart-table-detail';

type ITreeMapChart = {
  data: IRowEDashboard;
  statusChart: IStatusChartBB;
};

const TreeMapChartEDashboard = ({ data, statusChart }: ITreeMapChart) => {
  const { defaultOptions, customHTML } = funcConfigs();

  const [dataList, setDataList] = React.useState<ITreeMapDataChart[]>([]);
  const [optionList, setOptionList] = React.useState<TreeChartOptions>(defaultOptions);
  const [percentList, setPercentList] = React.useState<{ [key: string]: string }>({});

  const stateChart = React.useMemo((): IStateChartValue => {
    switch (statusChart) {
      case 'success': {
        if (dataList.length == 0) return 'empty';
        return 'success';
      }
      case 'error':
        return 'error';
      default:
        return 'loading';
    }
  }, [statusChart, dataList.length]);

  React.useEffect(() => {
    if (data) {
      const hashColors = {};
      data.rows.forEach((item, index) => {
        hashColors[item.name] = colors.charts[index];
      });

      const TOTAL_VALUE = _.sum(data.rows.map((tree) => _.sumBy(tree.children, 'value')));
      const PERCENT_LIST = {};
      data.rows.forEach((tree) => {
        tree.children.forEach((leaf) => {
          PERCENT_LIST[leaf.name] = ((leaf.value * 100) / TOTAL_VALUE).toFixed(2);
        });
      });
      setPercentList(PERCENT_LIST);

      setDataList(data.rows);

      _.set(optionList, 'title', '');
      _.set(optionList, 'getFillColor', (group) => {
        return hashColors[group];
      });
      _.set(optionList, 'tooltip.customHTML', customHTML({ percent: PERCENT_LIST, currency: '' }));
      _.set(optionList, 'legend.position', LegendPositions.BOTTOM);
      setOptionList(optionList);
    }
    //Display option chart
  }, [data]);
  return (
    <ChartTableDetail title={'#SKINCARE'} dataMenu={[[]]}>
      <TreeMapChartResponsive
        keyChart={'Edash_tree'}
        stateChart={stateChart}
        dataList={dataList}
        optionList={optionList}
        percentList={percentList}
        currency={''}
      />
    </ChartTableDetail>
  );
};

export default TreeMapChartEDashboard;
