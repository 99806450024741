import * as React from 'react';

import { makeStyles } from '@material-ui/core';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import GroupButtonSubmit from '@ep/insight-ui/elements/inline-edit/cell/group-button-submit';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  inputText: {
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      height: '32px',
    },
    width: '100%',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  TextField: {
    width: '100%',
  },
  InputPaper: {
    '& input': {
      width: '100%',
    },
  },
}));

const SimpleForm = ({ title, defaultValue, handleClose, handleSubmit }) => {
  const classes = useStyles();

  const [value, setValue] = React.useState(defaultValue);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [error, setError] = React.useState({
    visible: false,
    textError: '',
  });

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      await handleSubmit(value);
      handleClose();
    } catch (e) {
      setError({
        visible: true,
        textError: e.message,
      });
    }
    setIsSubmitting(false);
  };

  React.useEffect(() => {}, []);

  return (
    <div style={{ padding: '0 10px', minWidth: '320px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <TextFieldCustom
          defaultValue={value}
          onChangeText={setValue}
          error={error.visible}
          helperText={error.textError}
          className={classes.TextField}
          classNamePaperInput={classes.InputPaper}
          autoFocus
        />
      </div>
      <GroupButtonSubmit onCancel={handleClose} onSubmit={onSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default SimpleForm;
