import moment from 'moment';
import { get } from 'lodash';

import { CELL_FORMAT_STATIC_KEYS } from '../cell-format-object-keys';
import AdoptionRate from '@ep/insight-ui/elements/table/format/adoption-rate-format';
import CurrencyFormat from '@ep/insight-ui/elements/table/format/currency-format';
import CalculateFormat from '@ep/insight-ui/elements/table/format/calculate-format';
import { groupableCell } from '@ep/insight-ui/elements/table/function-helper';
import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { checkEpsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';
import { generateFillDates } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';
import LineChartFormat from '@ep/insight-ui/elements/table/format/line-chart-format';

export const hasCohortProperties = (backbone) => {
  const mappings = get(backbone, 'config.mapping', null) || backbone.getConfig('mapping');
  const visualizationType = get(backbone, ['config', 'visualizationType'], 'table');
  const chartType = get(backbone, ['config', 'chartConfig', 'config', 'chartType'], '');
  const allowCohort = get(backbone, ['config', 'chartConfig', 'config', 'timelineCohorts'], false);
  if (visualizationType === 'chart' && ['lines', 'treemap'].includes(chartType) && allowCohort) {
    return true;
  }
  return Object.values(mappings).some((mapping) => {
    return get(mapping, 'staticValue.allowCohort', false);
  });
};

export const hasStorefrontMetricTraction = (backbone) => {
  const mappings = get(backbone, 'config.mapping', null) || backbone.getConfig('mapping');
  const columnTractionKey = Object.keys(mappings).find((el) => mappings[el]?.cellFormat === 'storefrontMetricTraction');
  const isMetricVisible = get(backbone, 'config.metric', []).includes(columnTractionKey);

  return isMetricVisible;
};

export function columnEnhance(columnDefs, columns, backbone, tableDOM?) {
  const mappings = backbone.getConfig('mapping');

  const modifiedColumnDefs = modifyOriginalColDefs(columnDefs, backbone, mappings);

  const tractionFormat = Object.values(mappings).find((mapping) => mapping.cellFormat === 'traction');
  if (tractionFormat) {
    return enhanceColumnTraction(modifiedColumnDefs, columns, backbone, tableDOM);
  }

  const metricPivot = Object.values(mappings).find((mapping) => mapping.cellFormat === 'storefrontMetricTraction');
  const columnTractionKey = Object.keys(mappings).find((el) => mappings[el]?.cellFormat === 'storefrontMetricTraction');
  const isMetricVisible = get(backbone, 'config.metric', []).includes(columnTractionKey);

  if (metricPivot && isMetricVisible) {
    return enhanceColumnPivot(metricPivot, columnTractionKey, {
      columnDefs: modifiedColumnDefs,
      columns,
      backbone,
      tableDOM,
    });
  }

  if (ff.metric_cohort_column && hasCohortProperties(backbone)) {
    return enhanceCohortColumn(mappings, { columnDefs: modifiedColumnDefs, columns, backbone, tableDOM });
  }

  return modifiedColumnDefs;
}

function enhanceColumnTraction(columnDefs, columns, backbone, tableDOM) {
  const additionalColumns = [];
  const mappings = backbone.getConfig('mapping');
  const tractionFormat = Object.values(mappings).find((mapping) => mapping.cellFormat === 'traction');
  const {
    period = 'week',
    timeRange = 8,
    formatTime = '[CW]W - YYYY',
  } = tractionFormat.staticValue || CELL_FORMAT_STATIC_KEYS.traction;

  additionalColumns.push(
    ...Array.from({ length: timeRange }, (_, index) => {
      return {
        headerName: moment().subtract(index, period).format(formatTime),
        field: `traction_${period}_${index + 1}`,
        colId: `traction_${period}_${index + 1}`,
        resizable: true,
        suppressMovable: true,
        cellStyle: {
          borderRightColor: 'whitesmoke',
        },
        headerComponentParams: {
          type: 'number',
        },
        cellRendererParams: {
          showTooltip: true,
        },
        cellRenderer: groupableCell(AdoptionRate),
        cellRendererSelector(params) {
          if (params.node.isRowPinned()) {
            return {
              frameworkComponent: CalculateFormat,
              params: {
                tableDOM,
              },
            };
          }

          return undefined;
        },
      };
    }),
  );

  return [...columnDefs, ...additionalColumns];
}

function enhanceColumnPivot(mapping, columnField, { columnDefs, columns, tableDOM, backbone }) {
  const showTrend = backbone.getConfig('showTrend', true);

  const currentDateRange = backbone.getConfig('dateRange');
  const dateFrom = moment(currentDateRange.dateFrom, 'YYYY-MM-DD');
  const dateTo = moment(currentDateRange.dateTo, 'YYYY-MM-DD');
  const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
  const isSparkline = get(mapping, 'staticValue.allowSparkline', false);
  const columnName = get(mapping, 'staticValue.sparklineColumnName', 'Trend');

  let period;
  if (checkEpsiloTableEndpoint(endpoint)) {
    period = backbone.getConfig('groupPeriod') || 'daily';
  } else {
    period = get(mapping, 'staticValue.period', 'daily');
  }

  const pivotDateTimeColumn = generateFillDates(dateFrom, dateTo, period);
  const colWidth = backbone.getConfig('columnWidth').find((ele) => ele.columnField === columnField);

  const sparklineColumn = {
    headerName: columnName,
    field: 'sparkline',
    colId: 'sparkline',
    resizable: true,
    suppressMovable: true,
    hide: !isSparkline,
    cellStyle: {
      borderRightColor: 'whitesmoke',
    },
    headerComponentParams: {
      type: 'number',
    },
    cellRendererParams: {
      showTooltip: true,
    },
    width: colWidth || 100,
    cellRenderer: LineChartFormat,
    valueGetter: (row) => {
      return {
        data: [...pivotDateTimeColumn].sort().map((dt) => row.data[dt]),
        labels: [...pivotDateTimeColumn].sort(),
        height: '32px',
      };
    },
  };
  if (!showTrend) return [...columnDefs, sparklineColumn];

  const additionalColumns = pivotDateTimeColumn.map((dt) => {
    return {
      headerName: dt,
      field: dt,
      colId: dt,
      resizable: true,
      suppressMovable: true,
      cellStyle: {
        borderRightColor: 'whitesmoke',
      },
      headerComponentParams: {
        type: 'number',
      },
      cellRendererParams: {
        showTooltip: true,
      },
      width: 100,
      cellRenderer: groupableCell(CurrencyFormat),
      valueGetter: (row) => {
        return {
          value: row.data[dt],
          currency: row.data['currency'] || row.data['STOREFRONT.currency'],
          isEmpty: get(backbone.getConfig('groupBy'), 'columns', []).length === 0,
        };
      },
      cellRendererSelector(params) {
        if (params.node.isRowPinned()) {
          return {
            frameworkComponent: CalculateFormat,
            params: {
              tableDOM,
            },
          };
        }

        return undefined;
      },
    };
  });

  return [...columnDefs, sparklineColumn, ...additionalColumns];
}

const enhanceCohortColumn = (mappings, { columnDefs, columns, backbone, tableDOM }) => {
  const metrics = backbone.getConfig('metric').filter((i) => {
    return get(mappings, [i, 'staticValue', 'allowCohort'], false);
  });

  const cohortConfig = backbone.getConfig('calendarCohort');

  if (cohortConfig && metrics.length > 0) {
    const cloneColDefs = [...columnDefs];

    metrics.forEach((el: any) => {
      const newCols = Array.from({ length: 2 }).map((_, index) => {
        const colId = index === 0 ? `${el}_cohort` : `${el}_delta`;
        const columnWidth = backbone.getConfig('columnWidth').find((ele) => ele.columnField === colId);
        const newCol = {
          headerName: `${mappings[el].title} ${index === 0 ? 'Cohort' : 'Delta'}`,
          field: colId,
          colId: colId,
          resizable: true,
          suppressMovable: true,
          width: columnWidth?.width,
          cellStyle: {
            borderRightColor: 'whitesmoke',
          },
          headerComponentParams: {
            type: 'number',
            dataType: mappings[el].dataType,
          },
          cellRendererParams: {
            showTooltip: true,
            dataType: mappings[el].dataType,
          },
          cellRenderer: groupableCell(
            cellFormat[mappings[el].cellFormat] ? cellFormat[mappings[el].cellFormat] : CurrencyFormat,
          ),
          valueGetter: (row) => {
            const dataValue = Number(row.data[`${mappings[el].valueGetter.value}`]);
            const dataCohort = Number(row.data[`${mappings[el].valueGetter.value}_cohort`]);
            const deltaValue =
              (Number.isNaN(dataValue) || Number.isNaN(dataCohort) || dataValue === 0
                ? 0
                : (dataValue - dataCohort) / dataCohort) * 100;
            return {
              value: index === 0 ? dataCohort : deltaValue,
              typeDisplay: index === 0 ? 'currency' : 'delta',
            };
          },
          cellRendererSelector(params) {
            if (params.node.isRowPinned()) {
              return {
                frameworkComponent: CalculateFormat,
                params: {
                  tableDOM,
                },
              };
            }

            return undefined;
          },
        };

        return newCol;
      });

      const metricIndex = cloneColDefs.findIndex((ele) => ele.colId === el);
      cloneColDefs.splice(metricIndex + 1, 0, ...newCols);
    });

    return cloneColDefs;
  }

  return columnDefs;
};

const modifyOriginalColDefs = (columnDefs, backbone, mappings) => {
  const modifiedColumnDefs = columnDefs.map((el) => {
    let menuAction = [...get(el, ['headerComponentParams', 'menuAction'], [])];
    const staticValue = get(el, ['cellRendererParams', 'column', 'cell', 'staticValue'], {});
    const columnTractionKey = Object.keys(mappings).find(
      (el) => mappings[el]?.cellFormat === 'storefrontMetricTraction',
    );

    // Add hide column
    const disabledComponents = backbone.getConfig('disabledComponents', {});
    const columnDef = get(mappings, el.field, {});
    const type = columnDef.propertyType;
    const disabledOptions = get(disabledComponents, type, []);

    if (type && !disabledOptions.includes(el.field)) {
      menuAction = menuAction.concat({
        name: 'Hide this',
        icon: 'hideDetail',
        type: '',
        component: null,
        onSubmit: () => {
          const currentSelected = backbone.getConfig(type);
          const filteredSelected = currentSelected.filter((ele) => ele !== el.field);
          backbone.changeConfig(type, filteredSelected);
        },
      });
    }

    if (columnTractionKey === el.field) {
      const showTrend = backbone.getConfig('showTrend', true);
      menuAction = menuAction.concat({
        name: showTrend ? 'Hide trend' : 'Show trend',
        icon: showTrend ? 'eyeSlash' : 'eye',
        type: '',
        component: null,
        onSubmit: () => {
          backbone.changeConfig('showTrend', !showTrend);
        },
      });
    }

    if (staticValue.allowGroupby) {
      const pinColumnIndex = menuAction.findIndex((el) => el.icon === 'pinColumn') || menuAction.length;
      menuAction = [
        ...menuAction.slice(0, pinColumnIndex),
        {
          name: 'Group by',
          icon: 'groupBy',
          type: '',
          component: null,
          onSubmit: (data) => {
            backbone.changeConfig('groupBy', { columns: [data.field] });
          },
        },
        ...menuAction.slice(pinColumnIndex),
      ];
    }

    return {
      ...el,
      headerComponentParams: {
        ...el.headerComponentParams,
        menuAction,
      },
    };
  });

  return modifiedColumnDefs;
};
