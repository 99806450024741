import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import EpsiloTheme from '@ep/insight-ui/lib/epsilo-theme';
import { Box, makeStyles, ThemeProvider } from '@material-ui/core';
import React from 'react';
import { TOP_ADVERTISERS_RES, TOP_ADVERTISERS_RES_2, MAKETER_RES } from './mocks';
import { TOP_ADVERTISERS_CONFIG, TOP_ADVERTISERS_CONFIG_2, MAKETER_CONFIG } from './configs';
import { ITypeTable, makeAdvertise } from './advertiser/advertiser-container';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const useStyles = makeStyles({
  tableWrapper: {
    width: '100%',
    // border: '1px solid #d0d0d0',
  },
  container: {},
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#253746',
    '&.titlePage': {
      fontSize: '32px',
      lineHeight: '40px',
    },
    '&.title': {
      fontSize: '24px',
      lineHeight: '28px',
    },
  },
});

const AdvertiserCommunity = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <p className={`${classes.text} title`}>
        Manage and grow your e-commerce business with help from <br /> world-class marketers
      </p>
      <p className={classes.text}>
        Explore and team up with talents from a pool of more than <strong>14,567 marketers</strong> that cover{' '}
        <strong>26 timezones</strong> across
        <br />
        the globe to surge your marketplace performance to a new height.
      </p>
      <SectionOne />
      <SectionTwo />
      <Maketers />
    </div>
  );
};

const SectionOne = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(TOP_ADVERTISERS_RES);
      },
    },
    tableType: ITypeTable.TOP_ADVERTISERS,
    requestFilter: {
      currency: 'USD',
    },
    configuration: TOP_ADVERTISERS_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeAdvertise({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};

const SectionTwo = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(TOP_ADVERTISERS_RES_2);
      },
    },
    tableType: ITypeTable.TOP_ADVERTISERS,
    requestFilter: {
      currency: 'USD',
    },
    configuration: TOP_ADVERTISERS_CONFIG_2,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeAdvertise({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};
const Maketers = () => {
  const classes = useStyles();
  const config = {
    apiRequest: {
      getTableData: () => {
        return Promise.resolve(MAKETER_RES);
      },
    },
    tableType: ITypeTable.ALL_ADVERTISER,
    requestFilter: {
      currency: 'USD',
    },
    configuration: MAKETER_CONFIG,
  };

  const changeConfiguration = (config: unknown) => {
    console.log('Update configuration', config);
  };

  const tableContainer = React.useMemo(
    () =>
      makeAdvertise({
        config,
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <ThemeProvider theme={EpsiloTheme}>
      <DndProvider backend={HTML5Backend}>
        <Box className={classes.tableWrapper}>{tableContainer}</Box>
      </DndProvider>
    </ThemeProvider>
  );
};
export default AdvertiserCommunity;
