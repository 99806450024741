import { eipRequest as request, EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { createDataQueryFromConfig, getAPIRequest } from '@ep/insight-ui/system/backbone/table-backbone';
import { createWorkflowCampaignDetails } from '@ep/insight-ui/system/backbone/workflow-backbone';
import { enhanceDataRequest2 } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';
import produce from 'immer';
import { cloneDeep, difference, flatten, get, merge, set, uniq, uniqBy, uniqueId } from 'lodash';
import qs from 'qs';
import { getCampaignSettingInfo, publishDimensions } from '../legacy/api-request-campaign-details';
import { COMPACT_AD_OBJECT_CONFIG, COMPACT_AD_TOOL_CONFIG } from '../table-config';
import { CAMPAIGN_DETAIL_CONFIG, COMPACT_AUDIENCE_CONFIG, COMPACT_DISPLAY_LOCATION_CONFIG } from './table-config';
import { getSelectedAdObjects } from '../util';

const COMPACT_TABLE_CONFIG = {
  ad_tool: { ...COMPACT_AD_TOOL_CONFIG, tableId: 'ad_tool_compact' },
  ad_object: { ...COMPACT_AD_OBJECT_CONFIG, tableId: 'ad_object_compact' },
  display_location: { ...COMPACT_DISPLAY_LOCATION_CONFIG, tableId: 'display_location_compact' },
  audience: { ...COMPACT_AUDIENCE_CONFIG, tableId: 'audience_compact' },
};

const CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY = {
  ...CAMPAIGN_DETAIL_CONFIG,
  primaryKeys: [...CAMPAIGN_DETAIL_CONFIG.primaryKeys, 'eipCustomRowId'],
};

const log = useLog('campaign-details:shp_da');
const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

const endpoint = {
  GET_CAMPAIGN_SETTING: API_URL + '/mop-query/listing/getCampaignData',
  GET_CAMPAIGN_INSIDE_DATA: API_URL + '/mop-query/listing/getCampaignInsideData',
  CREATE_DIMENSION: API_URL + '/ads-operation/create-dimension',
  UPDATE_DIMENSION: API_URL + '/ads-operation/update-dimension',
  MASS_CREATE_DIMENSION: API_URL + '/ads-operation/mass-create-dimension',
  MASS_UPDATE_DIMENSION: API_URL + '/ads-operation/mass-update-dimension',
  MASS_DELETE_DIMENSION: API_URL + '/ads-operation/mass-delete-dimension',
  REQUEST_CUSTOM_KEYWORD: API_URL + '/ads-operation/filter-dimension',
};

const DEFAULT_VALUE = {
  MATCH_TYPE: 'EXACT_MATCH',
  BIDDING_PRICE: 450,
  BIDDING_PREMIUM: 0,
};

export function getWorkflow() {
  const wf = createWorkflowCampaignDetails(
    {},
    {
      async full_tableConfig(input: Record<string, any>, workflow) {
        const ref: any = {};
        const tableConfig: any = merge(cloneDeep(CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY), {
          mapping: {
            ad_tool: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_tool' }),
            },
            ad_object: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_object' }),
            },
            display_location: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', {
                column: 'display_location',
              }),
            },
            audience: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', {
                column: 'audience',
              }),
            },
          },
        });

        if (tableConfig.views.length > 0) {
          tableConfig.view = tableConfig.views[0];
        }

        console.log('table config', tableConfig);

        const config = {
          apiRequest: await workflow.request('eTableApiRequest', {
            campaignId: input.campaignId,
            schedule: input.schedule,
          }),
          id: uniqueId('ep_campaign_detail_'),
          configuration: tableConfig,
          callback: {
            onBackboneReady: (backbone) => {
              ref.backbone = backbone;
              workflow.set('mainTableBackbone', backbone);
              backbone.changeConfig('dateRange', input.schedule);
            },
            onRowRemove: async (type, rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              let selectedRows = [];
              let reloadTable = false;
              switch (type) {
                case 'ad_object':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['ADS_OBJECT.id'] !== rows['ADS_OBJECT.id']);

                  res = await workflow.applyUpdate('syncRemoveProduct', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                  } else {
                    error = res.error;
                  }
                  reloadTable = res.reloadTable;
                  break;
                case 'display_location':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['SELECTED_LOCATION.id'] !== rows['SELECTED_LOCATION.id']);

                  res = await workflow.applyUpdate('syncRemoveDisplayLocations', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                case 'audience':
                  selectedRows = backbone.getSelectedRows().filter((row) => row['AUDIENCE.id'] !== rows['AUDIENCE.id']);

                  res = await workflow.applyUpdate('syncRemoveAudiences', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                case 'status':
                  res = await workflow.request('deleteStatusRow', {
                    id: rowTarget['ADS_PLACEMENT.id'] || rowTarget['ADS_OBJECT.id'],
                    dimension: rowTarget['ADS_PLACEMENT.id'] ? 'ADS_PLACEMENT' : 'ADS_OBJECT',
                    marketplaceCode: rowTarget['MARKETPLACE.marketplace_code'],
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                default:
                  updatedRows = rows;
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, item.message], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (reloadTable) {
                // stateDispatch.current({ type: type, payload: { selectedItems: updatedRows, rowTarget } });
                // onChange(updatedRows, type);
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
          addons: {
            'compactTable.display_location.config': async ({ compactTableConfig }, rowTarget, backbone) => {
              let selectedRows = backbone.getSelectedRows();
              if (selectedRows.length === 0) {
                selectedRows = [rowTarget];
              }

              const masterObjectField = 'PRODUCT.id';
              return produce(compactTableConfig, (draft) => {
                const selectedProductId = selectedRows.map((i) => i[masterObjectField]);
                draft.config.id = 'compact_table_keyword_' + selectedProductId.join('_');
                draft.config.configuration.hiddenFilter = {
                  combinator: 'AND',
                  filters: [
                    {
                      field: masterObjectField,
                      operator: 'in',
                      dataType: 'integer',
                      value: ff.can_not_add_keywork ? selectedProductId.filter((el) => el !== null) : selectedProductId,
                    },
                  ],
                };
                draft.config.configuration.requestHiddenField = {
                  attribute: [masterObjectField],
                };
                draft.selectedRows = [];
              });
            },
            'compactTable.audience.config': async ({ compactTableConfig }, rowTarget, backbone) => {
              let selectedRows = backbone.getSelectedRows();
              if (selectedRows.length === 0) {
                selectedRows = [rowTarget];
              }

              const masterObjectField = 'PRODUCT.id';
              return produce(compactTableConfig, (draft) => {
                const selectedProductId = selectedRows.map((i) => i[masterObjectField]);
                draft.config.id = 'compact_table_keyword_' + selectedProductId.join('_');
                draft.config.configuration.hiddenFilter = {
                  combinator: 'AND',
                  filters: [
                    {
                      field: masterObjectField,
                      operator: 'in',
                      dataType: 'integer',
                      value: selectedProductId,
                    },
                  ],
                };
                draft.config.configuration.requestHiddenField = {
                  attribute: [masterObjectField],
                };
                draft.selectedRows = [];
              });
            },
            'datasource.getRowsParams': async ({ params }, config) => {
              const ignoreFields = ['ad_object', 'ad_tool', 'context', 'display_location', 'audience'];
              params.attributes = params.attributes.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              params.dimensions = params.dimensions.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              const aggregations = get(params, 'groupBy.aggregations', []);
              if (aggregations.length > 0) {
                params.groupBy.aggregations = aggregations.filter((i) => {
                  return ignoreFields.indexOf(i.field) === -1;
                });
              }
              return params;
            },
            'selection.filter': (field, originSelectionFilter) => {
              const additionalFilterField = get(tableConfig, ['system', 'additionalFilters'], []).find(
                ({ id }) => id === field,
              );

              const additionalOperators = get(additionalFilterField, ['operators'], []).map((el) => el.value);

              return additionalOperators.length ? additionalOperators : originSelectionFilter;
            },
            ...get(tableConfig, ['system', 'additionalFilters'], []).reduce((a, b) => {
              return {
                ...a,
                [`filterValue.${b.id}`]: () => {
                  return {
                    data: b.options,
                    success: true,
                    message: 'OK',
                  };
                },
              };
            }, {}),
            'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
              return enhanceDataRequest2(params, originRequest, backbone);
            },
          },
        };

        return config;
      },
      async compact_tableConfig({ column: type }, workflow) {
        const tableConfig = COMPACT_TABLE_CONFIG[type];
        const finalConfig: RecordValue = {
          tableCompactId: tableConfig.tableId,
          configuration: tableConfig,
          // apiRequest: {
          //   getTableData: () => Promise.resolve(COMPACT_TABLE_RES[type]),
          // },
          submitRowSelectionOnClosed: true,
          callback: {
            onRowSelect: async (rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              switch (type) {
                case 'ad_object': {
                  res = await workflow.applyUpdate('syncUpdateCampaignProducts', {
                    updateType: 'default',
                    target: rowTarget,
                    value: rows,
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                case 'display_location': {
                  const contextSelectedRows = backbone.getSelectedRows();
                  res = await workflow.applyUpdate('syncUpdateCampaignDisplayLocations', {
                    updateType: 'default',
                    value: rows,
                    target: contextSelectedRows.length > 0 ? contextSelectedRows : [rowTarget],
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                case 'audience': {
                  const contextSelectedRows = backbone.getSelectedRows();
                  res = await workflow.applyUpdate('syncUpdateCampaignAudiences', {
                    updateType: 'default',
                    value: rows,
                    target: contextSelectedRows.length > 0 ? contextSelectedRows : [rowTarget],
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                default:
                  updatedRows = rows;
              }

              if (updatedRows) {
                workflow.emit('onChange', { updatedRows, type });
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, ...Object.values(item.error)], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (updatedRows.length > 0) {
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
        };

        let searchField = '';
        switch (type) {
          case 'ad_object':
            searchField = 'PRODUCT';
            break;
          case 'display_location':
            searchField = 'LOCATION.name';
            break;
          case 'audience':
            searchField = 'SUGGESTED_AUDIENCE.name';
            break;
        }

        finalConfig.addons = {
          ...(ff.tooltips_toko_shop_ads ? COMPACT_TABLE_CONFIG[type]?.addons || {} : {}),
          'datasource.getRowsParams': async ({ params }, config) => {
            const context = get(config, 'system.context');
            const getStorefrontId = get(context, 'STOREFRONT.id');
            const storefrontIdFilter = {
              field: 'STOREFRONT.id',
              dataType: 'string',
              operator: 'is',
              value: getStorefrontId,
            };
            const shopeeFilter = {
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              operator: 'is',
              value: 'SHOPEE',
            };

            if (!params.filter) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter],
                  });
            } else if (
              !params.filter.filters.some((el) =>
                Object.keys(shopeeFilter).every((ele) => el[ele] === shopeeFilter[ele]),
              )
            ) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter],
                  });
            }

            if (config.search) {
              return produce(params, (draft) => {
                const searchFilter = {
                  combinator: 'OR',
                  filters: config.search
                    .split('\n')
                    .filter((i) => String(i).trim() !== '')
                    .map((i) => {
                      return {
                        field: searchField,
                        dataType: 'string',
                        operator: 'contains',
                        value: String(i).trim(),
                      };
                    }),
                };

                if (!params.filter) {
                  draft.filter = searchFilter;
                } else {
                  draft.filter.filters = [...draft.filter.filters, searchFilter];
                }
              });
            }

            return params;
          },
        };

        return finalConfig;
      },
    },
    {
      eTableApiRequest: async ({ campaignId, schedule }, workflow) => {
        let apiRequest;
        const endpoint = CAMPAIGN_DETAIL_CONFIG.endpoint;
        apiRequest = getAPIRequest(endpoint);
        const originGetTableData = apiRequest.getTableData;
        apiRequest = {
          ...apiRequest,
          getTableData: async (params) => {
            let hiddenFilter = params.hiddenFilter;
            if (!hiddenFilter) {
              hiddenFilter = {
                combinator: 'AND',
                filters: [],
              };
            }

            hiddenFilter = produce(hiddenFilter, (draft) => {
              draft.combinator = 'AND';
              draft.filters = (hiddenFilter.filters || []).concat({
                field: 'ADS_CAMPAIGN.id',
                dataType: 'integer',
                operator: '=',
                value: campaignId,
              });
            });

            params.sort = params.sort
              ? [].concat(params.sort)
              : [
                  { field: 'ADS_OBJECT.created_at', sort: 'DESC' },
                  { field: 'ADS_OBJECT.updated_at', sort: 'DESC' },
                ];

            const { mainTableBackbone } = await workflow.get('mainTableBackbone');
            const view = mainTableBackbone.getConfig('view');

            if (view.id === 'display_ads_audience') {
              params.attributes = params.attributes.filter((attribute) => !attribute.startsWith('ADS_PLACEMENT'));
              params.dimensions = params.dimensions.filter((dimension) => dimension !== 'ADS_PLACEMENT');
              params.metrics = params.metrics.filter((metric) => !metric.startsWith('ADS_PLACEMENT'));
            }

            return originGetTableData({ ...params, hiddenFilter }).then(async (res) => {
              const rows = res.data.rows;
              let result = null;

              if (rows.length === 0) {
                result = await workflow.request('initFirstRowForEmptyCampaign', {
                  campaignId,
                  marketplace: 'SHOPEE',
                  campaignDateFrom: schedule.dateFrom,
                  campaignDateTo: schedule.dateTo,
                  originResponse: res,
                });
              } else {
                result = {
                  ...res,
                  data: {
                    ...res.data,
                    rows: await workflow.request('transformCellCompactSelections', {
                      rows,
                      campaignDateFrom: schedule.dateFrom,
                      campaignDateTo: schedule.dateTo,
                    }),
                  },
                };
              }

              return result;
            });
          },
        };
        return apiRequest;
      },
      initFirstRowForEmptyCampaign: async (
        { marketplace, campaignId, campaignDateFrom, campaignDateTo, originResponse },
        workflow,
      ) => {
        const campaignInfo = await getCampaignSettingInfo({ campaignId, marketplaceCode: marketplace });
        const rows = await workflow.request('transformCellCompactSelections', {
          rows: [].concat(campaignInfo),
          campaignDateFrom,
          campaignDateTo,
        });

        const result = produce(originResponse, (draft) => {
          set(draft, 'data.pagination.total', 1);
          set(draft, 'data.rows', rows);
        });

        return result;
      },
      transformCellCompactSelections: async (
        { rows, campaignDateFrom: campaignFrom, campaignDateTo: campaignTo },
        workflow,
      ) => {
        const toolIdField = 'ADTOOL.ads_tool';
        const toolNameField = 'ADTOOL.ads_tool_name';
        const adObjectNameField = 'ADS_OBJECT.name';
        const productIdField = 'PRODUCT.id';
        const adPlacementColField = 'display_location';
        const adPlacementIdField = 'SELECTED_LOCATION.id';
        const adPlacementNameField = 'SELECTED_LOCATION.name';
        const adPlacementTypeField = 'SELECTED_LOCATION.type';
        const audienceColField = 'audience';
        const audienceIdField = 'AUDIENCE.id';
        const audienceNameField = 'AUDIENCE.name';
        const audienceTypeField = 'AUDIENCE.type';

        const selectedAdTools: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: toolIdField,
          keyTuple: [toolIdField, toolNameField],
        });

        const adTool = get(selectedAdTools, [0, toolIdField]);
        const adObjectIdField = adTool === 'SHP_SA' ? 'AD_OBJECT.id' : 'PRODUCT.id';

        let selectedAdObjects: any[] = rows;
        if (rows.length > 0) {
          const r = rows[0];
          selectedAdObjects = await getSelectedAdObjects({
            currentWorkflow: workflow,
            marketplace: 'SHOPEE',
            compactTableConfig: COMPACT_TABLE_CONFIG.ad_object,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom, // FIXME: asked querying calendar on api
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
            originalRows: rows,
            productIdField,
            adObjectNameField,
          });
        }

        // FIXME: Wrong logic because api "getCampaignInsideDate" can't return suggest adPlacement id,
        rows.forEach((r) => {
          r[adPlacementIdField] = r['ADS_PLACEMENT.id'];
          r[adPlacementNameField] = r['ADS_PLACEMENT.display_location_name'];
          r[adPlacementTypeField] = r['ADS_PLACEMENT.type'];
          r[audienceIdField] = r['AUDIENCE.id'];
          r[audienceNameField] = r['AUDIENCE.name'];
          r[audienceTypeField] = r['AUDIENCE.type'];
        });
        const selectedDisplayLocation: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: adPlacementIdField,
          keyTuple: [adPlacementIdField, adPlacementNameField, adPlacementTypeField],
        });

        // const selectedAdPlacements = filterSelectedItems(rows, adPlacementIdField, adPlacementNameField);
        const groupedAdPlacementsByAdObject = selectedAdObjects.reduce((acc, adObject) => {
          const adObjectId = adObject[adObjectIdField];
          const adPlacements = rows
            .filter((r) => r[adPlacementIdField] && r[adObjectIdField] === adObjectId)
            .map((r) => ({
              master_object_id: r[adObjectIdField],
              [adPlacementIdField]: r[adPlacementIdField],
              [adPlacementNameField]: r[adPlacementNameField],
              [adPlacementTypeField]: r[adPlacementTypeField],
            }));
          return {
            ...acc,
            [adObjectId]: adPlacements,
          };
        }, {});

        const groupedAudienceByAdObject = selectedAdObjects.reduce((acc, adObject) => {
          const adObjectId = adObject[adObjectIdField];
          const audiences = rows
            .filter((r) => r[audienceIdField] && r[adObjectIdField] === adObjectId)
            .map((r) => ({
              master_object_id: r[adObjectIdField],
              [audienceIdField]: r[audienceIdField],
              [audienceNameField]: r[audienceNameField],
              [audienceTypeField]: r[audienceTypeField],
            }));
          return {
            ...acc,
            [adObjectId]: audiences,
          };
        }, {});

        rows.forEach((r) => {
          const adObjectId = r[adObjectIdField];
          const selectedAdPlacements = groupedAdPlacementsByAdObject[adObjectId] || [];
          const selectedAudiences = groupedAudienceByAdObject[adObjectId] || [];
          r.eipCustomRowId = uniqueId('eip_custom_row_');
          r.ad_tool = {
            selectedItems: selectedAdTools,
            currentFocusItem: selectedAdTools.find((i) => i[toolIdField] === r[toolIdField]),
            displayField: toolNameField,
            singleSelection: true,
          };
          r.ad_object = {
            selectedItems: selectedAdObjects,
            currentFocusItem: selectedAdObjects.find((i) => i[adObjectIdField] === r[adObjectIdField]),
            displayField: adObjectNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom, // FIXME: asked querying calendar on api
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
          };
          r[adPlacementColField] = {
            selectedItems: selectedAdPlacements,
            currentFocusItem: ff.groupby_display_location
              ? selectedDisplayLocation.find((i) => i[adPlacementIdField] === r[adPlacementIdField])
              : selectedAdPlacements.find((i) => i[adPlacementIdField] === r[adPlacementIdField]),
            displayField: adPlacementNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: adObjectIdField,
                  operator: '=',
                  dataType: 'integer',
                  value: adObjectId,
                },
              ],
            },
          };
          r[audienceColField] = {
            selectedItems: selectedAudiences,
            currentFocusItem: selectedAudiences.find((i) => i[audienceIdField] === r[audienceIdField]),
            displayField: audienceNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: adObjectIdField,
                  operator: '=',
                  dataType: 'integer',
                  value: adObjectId,
                },
              ],
            },
          };
        });

        return rows;
      },
      async filterSelectedItems(
        { rows, idField, keyTuple }: { rows: any[]; idField: string; keyTuple: any[] },
        workflow,
      ) {
        return rows.reduce((acc, r) => {
          const exist = acc.find((i) => i[idField] === r[idField]);
          if (!exist && r[idField]) {
            return [
              ...acc,
              {
                ...keyTuple.reduce((carry, k) => {
                  return { ...r, ...carry, [k]: r[k] };
                }, {}),
              },
            ];
          }
          return acc;
        }, []);
      },

      createAdsObjects: (param: {
        data: {
          masterObjectId: string;
          adsCampaignId: string;
          dailyBudget: number;
          totalBudget: number;
        }[];
        marketplaceCode: string;
      }) => {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            adsOpsAdsCalendars: [],
            status: 'DRAFT',
          })),
          dimension: 'ADS_OBJECT',
        };
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsObjects(param: { id: string[]; marketplaceCode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_OBJECT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.deleteFetch(url);
      },
      createAdsPlacements(param: {
        data: {
          adsObjectId: string;
          name: string;
          matchType: string;
          biddingPrice: number;
        }[];
        marketplaceCode: string;
      }) {
        const reqParam = {
          ...param,
          data: param.data.map((i) => ({
            ...i,
            status: 'DRAFT',
          })),
          dimension: 'ADS_PLACEMENT',
        };
        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      createAudience(param: {
        data: {
          adsObjectId: string;
          name: string;
          matchType: string;
          biddingPrice: number;
        }[];
        marketplaceCode: string;
      }) {
        const reqParam = {
          ...param,
          dimension: 'ADS_AUDIENCE_BEHAVIOUR',
        };
        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsPlacements(param: { id: string[]; marketplacecode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_PLACEMENT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      deleteAudience(param: { id: string[]; marketplacecode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_AUDIENCE_BEHAVIOUR');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      deleteStatusRow(param: { id: string[]; marketplacecode: string; dimension: string }) {
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
          {
            ...param,
            dimension: param.dimension,
          },
          {
            arrayFormat: 'comma',
          },
        )}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      dataQueryFromMainTable: async ({ campaignId, schedule, excludedMetric = true }, workflow) => {
        const tableConfig = await workflow.getTableConfig('full', 'tableConfig', { campaignId, schedule });
        const config = tableConfig.configuration;
        if (excludedMetric) {
          config.mapping = Object.keys(config.mapping).reduce((carry, k) => {
            {
              if (config.mapping[k].propertyType !== 'metric') {
                return { ...carry, [k]: config.mapping[k] };
              }
              return carry;
            }
          }, {});
        }
        const ds = createDataQueryFromConfig({ config: config, addons: tableConfig.addons });
        return ds;
      },
      campaignStatus: async ({ campaignId, schedule }, workflow) => {
        return {
          haveDraft: true,
          draftItems: [],
        };
      },
      publishCampaign: async ({ campaignId, schedule, marketplace }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });
        const results = [];

        const draftProduct = await ds.query({
          filter: [],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_OBJECT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdObjectIds = draftProduct.rowData.map((i) => i['ADS_OBJECT.id']);
        if (draftAdObjectIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_OBJECT',
              id: draftAdObjectIds,
              marketplaceCode: marketplace,
            }),
          );
        }

        return results;
      },
    },
    {
      async syncRemoveProduct({ target: rowTarget, updateType, value: products }, workflow) {
        const adObjectIdField = 'ADS_OBJECT.id';
        const marketplaceCode = get(rowTarget, ['MARKETPLACE.marketplace_code'], null);
        const selectingItemIds = products.map((i) => i[adObjectIdField]);

        const removeProductIds = selectingItemIds;

        let removePromise = null;
        if (removeProductIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            id: selectingItemIds,
          };
          removePromise = workflow.request('deleteAdsObjects', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = result.data;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },

      async syncRemoveDisplayLocations({ target: rowTarget, updateType, value: locations }, workflow) {
        const locationIdField = 'SELECTED_LOCATION.id';
        const locationNameField = 'SELECTED_LOCATION.name';
        const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);
        const selectedItems = get(rowTarget, ['display_location', 'selectedItems'], []);
        const selectingItemIds = locations.map((i) => ({
          masterObjectId: i[locationIdField],
          name: i[locationNameField],
        }));

        // Determine which locations to add/remove
        const removeKeywordIds = selectingItemIds;

        let removePromise = null;
        if (removeKeywordIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            ...(ff.select_all_add_keyword
              ? {
                  id: removeKeywordIds.map((i) => i.masterObjectId).filter((el) => el !== null),
                }
              : {
                  id: removeKeywordIds.map((i) => i.masterObjectId),
                }),
          };
          removePromise = workflow.request('deleteAdsPlacements', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            if (result) {
              if (result.success) {
                rows = selectedItems.filter((item) => {
                  const exist = removeKeywordIds.some(
                    (i) => i.masterObjectId === item[locationIdField] && i.name === item[locationNameField],
                  );
                  return !exist;
                });
                success = true;
              } else {
                console.log(result.message);
              }
            }

            return {
              success,
              data: rows,
              error: [],
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },

      async syncRemoveAudiences({ target: rowTarget, updateType, value: audiences }, workflow) {
        const audienceIdField = 'AUDIENCE.id';
        const audienceNameField = 'AUDIENCE.name';
        const marketplaceCode = get(rowTarget, 'MARKETPLACE.marketplace_code', null);
        const selectedItems = get(rowTarget, ['audience', 'selectedItems'], []);
        const selectingItemIds = audiences.map((i) => ({
          masterObjectId: i[audienceIdField],
          name: i[audienceNameField],
        }));

        // Determine which audiences to add/remove
        const removeKeywordIds = selectingItemIds;

        let removePromise = null;
        if (removeKeywordIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            id: removeKeywordIds.map((i) => i.masterObjectId),
          };
          removePromise = workflow.request('deleteAudience', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            if (result) {
              if (result.success) {
                rows = selectedItems.filter((item) => {
                  const exist = removeKeywordIds.some(
                    (i) => i.masterObjectId === item[audienceIdField] && i.name === item[audienceNameField],
                  );
                  return !exist;
                });
                success = true;
              } else {
                console.log(result.message);
              }
            }

            return {
              success,
              data: rows,
              error: [],
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },

      async syncUpdateCampaignProducts({ target, updateType, value }, workflow) {
        const productIdField = 'PRODUCT.id';
        const adObjectIdField = 'ADS_OBJECT.id';
        const campaignId = get(target, 'ADS_CAMPAIGN.id', null);
        const marketplaceCode = get(target, ['MARKETPLACE.marketplace_code'], null);
        const storefrontId = get(target, ['STOREFRONT.id'], null);
        const selectedItems = get(target, ['ad_object', 'selectedItems'], []);
        const countryCode = get(target, ['COUNTRY.country_code'], null);
        const toolCode = get(target, ['ADTOOL.ads_tool'], null);
        const adType = get(target, ['ADTYPE.ads_type'], null);

        const selectedItemIds = selectedItems.map((i) => i[productIdField]);
        const selectingItemIds = value.map((i) => i[productIdField]);

        // Determine which products to add/remove
        const removeProductIds = difference(selectedItemIds, selectingItemIds);
        const addProductIds = difference(selectingItemIds, selectedItemIds);

        let removePromise = null;
        let addPromise = null;
        removePromise = Promise.resolve(null);

        if (addProductIds.length > 0) {
          const addAdsObjectParam = {
            marketplaceCode,
            data: addProductIds.map((id) => ({
              masterObjectId: id, // FIXME: incorrect
              masterObjectType: undefined, // no need according to @Nam.Huynh https://epsilo.slack.com/archives/D01TB0HSUPN/p1635135520001600
              countryCode,
              storefrontId,
              toolCode,
              type: adType,
              adsCampaignId: campaignId,
              totalBudget: 0,
              dailyBudget: 0,
              adsOpsAdsCalendars: [],
              baseBiddingPrice: 0,
            })),
          };
          addPromise = workflow.request('createAdsObjects', addAdsObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];
            if (values[0]) {
              if (values[0].success) {
                rows = selectedItems.filter((i) => !removeProductIds.includes(i[productIdField]));
                success = true;
              } else {
                error = error.concat(values[0].data);
                console.log(values[0].message);
              }
            }

            if (values[1]) {
              if (values[1].success) {
                const addedItems = value
                  .filter((i) => addProductIds.includes(i[productIdField]))
                  .map((i) => {
                    const adObject = values[1].data.find((v) => v.data.masterObjectId == i[productIdField]);
                    return {
                      ...i,
                      status: get(adObject, ['data', 'status'], ''),
                      adsObjectId: parseInt(get(adObject, ['data', 'adsObjectId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.log(values[1].message);
                console.error(values[1]);
              }
            }

            return {
              success,
              data: rows,
              error: error,
            };
          })
          .catch((err) => {
            console.log('Error', err);
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },

      async syncUpdateCampaignDisplayLocations({ target: rowTargets, updateType, value: locations }, workflow) {
        const locationIdField = 'LOCATION.id';
        const locationNameField = 'LOCATION.name';

        const adObjectIds = uniq(
          rowTargets.map((rowTarget) => get(rowTarget, ['ad_object', 'currentFocusItem', 'ADS_OBJECT.id'], null)),
        );

        const marketplaceCode = get(rowTargets[0], 'MARKETPLACE.marketplace_code', null);
        const selectedItemIds = flatten(
          rowTargets.map((rowTarget) => get(rowTarget, ['display_location', 'selectedItems'], [])),
        );

        const selectingItemIds = uniqBy(
          locations.map((i: any) => ({
            masterObjectId: i[locationIdField],
            name: i[locationNameField],
            type: i['LOCATION.type'],
          })),
          (i: any) => [i.masterObjectId, i.name].join(','),
        ).filter((i: any) => i.masterObjectId);

        // Determine which locations to add/remove
        // const removeLocationIds = differenceWith(selectedItemIds, selectingItemIds, isEqual);
        /**
         * FIXME: temporary commented the removed locations, the logic here is not correct with provided context
         * when there are multiple ad object selected and suggested selected list does not return the existing location
         */
        const addLocationIds = selectingItemIds.filter((selectingItemId) => {
          return !selectedItemIds.some(
            (selectedItemId) => selectedItemId['SELECTED_LOCATION.type'] === selectingItemId['type'],
          );
        });

        // Temporarily comment remove uncheck row when closing compact table
        // const removeLocationIds = selectedItemIds
        //   .filter((selectedItemId) => {
        //     return selectingItemIds.every(
        //       (selectingItemId) => selectedItemId['LOCATION.type'] !== selectingItemId['type'],
        //     );
        //   })
        //   .map((el) => ({ ...el, masterObjectId: el['LOCATION.id'] }));

        let addPromise = null;
        let removePromise = null;

        if (addLocationIds.length > 0) {
          const addLocationObjectParam = {
            marketplaceCode,
            data: flatten(
              addLocationIds.map((i) => {
                return adObjectIds.map((adObjectId) => {
                  return {
                    adsObjectId: adObjectId,
                    name: i.name,
                    type: i.type,
                    biddingPremium: DEFAULT_VALUE.BIDDING_PREMIUM,
                  };
                });
              }),
            ),
          };
          addPromise = workflow.request('createAdsPlacements', addLocationObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        // Temporarily comment remove uncheck row when closing compact table
        // if (removeLocationIds.length > 0) {
        //   const removeLocationObjectParam = {
        //     marketplaceCode,
        //     id: removeLocationIds.map((i) => i.masterObjectId),
        //   };
        //   removePromise = workflow.request('deleteAdsPlacements', removeLocationObjectParam);
        // } else {
        //   removePromise = Promise.resolve(null);
        // }
        removePromise = Promise.resolve(null);

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];
            if (values[1]) {
              if (values[1].success) {
                const addedItems = locations
                  .filter((item) => {
                    const exist = addLocationIds.some(
                      (i) => i.masterObjectId === item[locationIdField] && i.name === item[locationNameField],
                    );
                    return exist;
                  })
                  .map((i) => {
                    const adPlacement = values[1].data.find((v) => v.data.name === i[locationNameField]);
                    return {
                      ...i,
                      status: get(adPlacement, ['data', 'status'], ''),
                      adsPlacementId: parseInt(get(adPlacement, ['data', 'adsPlacementId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.error(JSON.stringify(values[1]));
                console.log(values[1].message);
              }
            }

            return {
              success,
              data: rows,
              error,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncUpdateCampaignAudiences({ target: rowTargets, updateType, value: audiences }, workflow) {
        const audienceIdField = 'SUGGESTED_AUDIENCE.id';
        const audienceFieldName = 'SUGGESTED_AUDIENCE.name';
        const audienceFieldType = 'SUGGESTED_AUDIENCE.type';

        const adObjectIds = uniq(
          rowTargets.map((rowTarget) => get(rowTarget, ['ad_object', 'currentFocusItem', 'ADS_OBJECT.id'], null)),
        );

        const marketplaceCode = get(rowTargets[0], 'MARKETPLACE.marketplace_code', null);
        const selectedItemIds = flatten(
          rowTargets.map((rowTarget) => get(rowTarget, ['audience', 'selectedItems'], [])),
        );
        const selectingItemIds = uniqBy(
          audiences.map((i) => ({
            masterObjectId: i[audienceIdField],
            name: i[audienceFieldName],
            type: i[audienceFieldType],
          })),
          (i: any) => [i.masterObjectId, i.name].join(','),
        ).filter((i: any) => i.masterObjectId);

        /**
         * FIXME: temporary commented the removed audiences, the logic here is not correct with provided context
         * when there are multiple ad object selected and suggested selected list does not return the existing keyword
         */
        const addAudienceIds = selectingItemIds.filter((selectingItemId) => {
          return !selectedItemIds.some((selectedItemId) => selectedItemId['AUDIENCE.type'] === selectingItemId['type']);
        });

        // Temporarily comment remove uncheck row when closing compact table
        // const removeAudienceIds = selectedItemIds
        //   .filter((selectedItemId) => {
        //     return selectingItemIds.every(
        //       (selectingItemId) => selectedItemId['AUDIENCE.type'] !== selectingItemId['type'],
        //     );
        //   })
        //   .map((el) => ({ ...el, masterObjectId: el['AUDIENCE.id'] }));

        let addPromise = null;
        let removePromise = null;

        if (addAudienceIds.length > 0) {
          const addAudienceParam = {
            marketplaceCode,
            data: flatten(
              addAudienceIds.map((i) => {
                return adObjectIds.map((adObjectId) => {
                  return {
                    ads_object_id: adObjectId,
                    name: i.name,
                    type: i.type,
                    status: 'DRAFT',
                  };
                });
              }),
            ),
          };
          addPromise = workflow.request('createAudience', addAudienceParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        // Temporarily comment remove uncheck row when closing compact table
        // if (removeAudienceIds.length > 0) {
        //   const removeAudienceObjectParam = {
        //     marketplaceCode,
        //     id: removeAudienceIds.map((i) => i.masterObjectId),
        //   };
        //   removePromise = workflow.request('deleteAudience', removeAudienceObjectParam);
        // } else {
        //   removePromise = Promise.resolve(null);
        // }
        removePromise = Promise.resolve(null);

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];

            if (values[1]) {
              if (values[1].success) {
                const addedItems = audiences
                  .filter((item) => {
                    const exist = addAudienceIds.some(
                      (i) => i.masterObjectId === item[audienceIdField] && i.name === item[audienceFieldName],
                    );
                    return exist;
                  })
                  .map((i) => {
                    const adPlacement = values[1].data.find((v) => v.data.name === i[audienceFieldName]);
                    return {
                      ...i,
                      status: get(adPlacement, ['data', 'status'], ''),
                      adsPlacementId: parseInt(get(adPlacement, ['data', 'adsPlacementId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.error(JSON.stringify(values[1]));
                console.log(values[1].message);
              }
            }

            return {
              success,
              data: rows,
              error,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
    },
  );
  return wf;
}
