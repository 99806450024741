import * as React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import moment from 'moment';
import { cloneDeep, uniqBy } from 'lodash';
import clsx from 'clsx';

import { Box, Button, Checkbox, Dialog, FormControlLabel, makeStyles, Tab, Tabs } from '@material-ui/core';

import { MonacoEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/monaco-editor';
import { aim, eipRequest as request } from '@eip/next/lib/main';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { MonacoCompareEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/monaco-compare-editor';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';

import RayConfig from './config';
import { rayRequest, RELOAD_LIST_EVENT } from './util';
import Icon from '@ep/insight-ui/icons/Icon';
import ButtonWithIcon from './button-with-icon';

const useStyles = makeStyles(() => ({
  container: {
    width: ({ toggleLog }) => (toggleLog ? '60%' : '100%'),
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    '& .eip1-MuiFormControlLabel-root': {
      marginLeft: '0',
      height: '26px',
    },
    '& .eip1-MuiTabs-root': {
      padding: '0 12px',
    },
    '& .eip1-MuiTab-wrapper': {
      whiteSpace: 'nowrap',
    },
    '& .eip1-MuiTab-root': {
      maxWidth: '100%',
      minWidth: 'unset',
      opacity: 0.3,
      '&.Mui-selected': {
        opacity: 1,
      },
    },
    '& .eip1-MuiTabScrollButton-root': {
      display: 'none',
    },
    background: ({ theme }) => (theme === 'vs-dark' ? '#1E1E1E' : '#fff'),
    color: ({ theme }) => (theme === 'vs-dark' ? '#fff' : '#000000DE'),
  },
  codeEditor: {
    height: '100%',
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  actionContainer: {
    padding: '4px 8px',
  },
  historyActionContainer: {
    flex: '0 0 300px',
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 8px',
    marginRight: '12px',
  },
  historyList: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '12px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      border: '4px solid #F8FAFD',
      borderRadius: '16px',
      display: 'none',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      display: 'block',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a0a0a5',
      border: '4px solid #F8FAFD',
    },
    overflowX: 'hidden !important',
    overflowY: 'scroll',
  },
  historyActions: {
    borderTop: '1px solid #ccc',
    padding: '8px 12px',
    '& button': {
      padding: '12px 16px',
    },
  },
  updatedAt: {
    color: '#8c98a4',
    whiteSpace: 'nowrap',
    marginRight: '8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 24px',
  },
  name: {
    marginTop: '0.3em',
    marginBottom: '0.3em',
    '&:focus-visible': {
      outline: 'none',
    },
  },
  scriptExpand: {
    '& .eip1-MuiAccordionSummary-content': {
      marginTop: 0,
    },
    '& .eip1-MuiAccordionSummary-root': {
      minHeight: '28px',
      padding: 0,
    },
    marginBottom: '16px',
  },
  configContainer: {
    flex: '1',
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100vw',
    height: '100vh',
  },
  historyDialogContainer: {
    display: 'flex',
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
  },
  historyItem: {
    display: 'flex',
    flexDirection: 'column',
    padding: '4px 12px',
    cursor: 'pointer',
    borderRadius: '4px',
    '&.active': {
      background: '#19191919',
    },
  },
  mergeCodeContainer: {
    flex: 1,
  },
  topRightSection: {
    display: 'flex',
    alignItems: 'center',
  },
  dropdownButton: {
    '.vs-dark &': {
      '& svg': {
        fill: '#fff',
        '&:hover': {
          fill: 'currentColor',
        },
      },
      '&:hover svg': {
        fill: 'currentColor',
      },
    },
  },
}));

const ALERT_SEPARATOR = '<========##=========ALERT========##=========>';

const MINUTE = 60 * 1000;

const defaultSuggestions = [
  {
    label: { label: 'out.println', detail: ` - global` },
    insertText: 'out.println(${1:})',
    insertTextRules: 'InsertAsSnippet',
    kind: 'Variable',
  },
  {
    label: { label: 'class', detail: ` - global` },
    insertText: 'class ',
    kind: 'Variable',
  },
  {
    label: { label: 'import', detail: ` - global` },
    insertText: 'import ',
    kind: 'Variable',
  },
  {
    label: { label: 'def', detail: ` - global` },
    insertText: 'def ',
    kind: 'Variable',
  },
  {
    label: { label: 'db', detail: ` - global` },
    insertText: 'db',
    kind: 'Variable',
  },
  {
    label: { label: 'alert', detail: ` - global` },
    insertText: 'alert',
    kind: 'Variable',
  },
  {
    label: { label: 'query', detail: ' - global' },
    insertText: 'query(${1:})',
    insertTextRules: 'InsertAsSnippet',
  },
  {
    label: { label: 'newSlackMessage', detail: ' - global' },
    insertText: 'newSlackMessage(${1:})',
    insertTextRules: 'InsertAsSnippet',
  },
  {
    label: { label: 'send', detail: ' - global' },
    insertText: 'send(${1:})',
    insertTextRules: 'InsertAsSnippet',
  },
  {
    label: { label: 'newEmailMessage', detail: ' - global' },
    insertText: 'newEmailMessage(${1:subject}, ${2:email})',
    insertTextRules: 'InsertAsSnippet',
  },
];

const suggestions = cloneDeep(defaultSuggestions);

enum TAB {
  SCRIPT = 'script',
  CONFIG = 'config',
}

const defaultData = {
  id: '',
  name: 'name',
  updateInterval: 5,
  updatePeriod: 0,
  mode: 1,
  filter:
    '{\n' +
    '  "combinator": "AND",\n' +
    '  "filters": [\n' +
    '    {"field": "service","operator": "=","value": "example_service"}\n' +
    '  ]\n' +
    '}',
  filterRoot: '{}',
  filterDetail: '{}',
  etlScript: 'root = trace.getRootOrOperation("")\n' + 'for span in trace:\n' + '\tout.println(span.operationName)\n',
  type: '',
  enabled: false,
  updatedAt: null,
};

type RayMainCodeProps = {
  setAlerts: React.Dispatch<React.SetStateAction<{ emailMessages: string[]; slackMessages: string[] }>>;
  defaultAlerts: { emailMessages: string[]; slackMessages: string[] };
  setLog: React.Dispatch<React.SetStateAction<string>>;
  setToggleLog: React.Dispatch<React.SetStateAction<boolean>>;
  toggleLog: boolean;
  favoriteFunctions: {
    checkIsFavorite: (id) => boolean;
    handleToggleFavorite: (id) => void;
  };
  theme: 'vs' | 'vs-dark';
  setTheme: React.Dispatch<React.SetStateAction<'vs' | 'vs-dark'>>;
  detail: any;
  setDetail: any;
};

const RayMainCode = ({
  setAlerts,
  setLog,
  toggleLog,
  setToggleLog,
  defaultAlerts,
  favoriteFunctions,
  theme,
  setTheme,
  detail,
  setDetail,
}: RayMainCodeProps) => {
  const classes = useStyles({ toggleLog, theme });
  const { id, serviceId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const refId = queryParams?.ref;
  const [enableRealAlert, setEnableRealAlert] = React.useState(false);
  const [localhost, setLocalhost] = React.useState('localhost:8080');
  const [traceId, setTraceId] = React.useState('');
  const [disabledButtons, setDisabledButtons] = React.useState({});
  const [importedData, setImportedData] = React.useState([]);
  const [mergeCodeOpen, setMergeCodeOpen] = React.useState(false);
  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [historyScript, setHistoryScript] = React.useState([]);
  const [selectedScript, setSelectedScript] = React.useState(null);
  const [updatedAt, setUpdatedAt] = React.useState(detail?.updatedAt);
  const importedSuggestionsRef = React.useRef<{
    variables: string[];
    functions: string[];
  }>({
    variables: [],
    functions: [],
  });
  const [tab, setTab] = React.useState<TAB>(id == 0 ? TAB.CONFIG : TAB.SCRIPT);

  const editor = React.useRef(null);
  const editorCompareRef = React.useRef(null);
  const editorHistoryRef = React.useRef(null);
  const editorContent = React.useRef('');
  const [compareValue, setCompareValue] = React.useState('');
  const [openMenu, setOpenMenu] = React.useState(false);

  const isFavorite = favoriteFunctions.checkIsFavorite(id);

  const getSuggestionsFromScript = (script, id) => {
    let variables = script.match(/\(?\,?\n(\t)*(\s)*\w+\s*(?=\=)/gi);
    if (variables) {
      variables = uniqBy(
        variables
          .filter((variable) => !variable.startsWith('(') && !variable.startsWith(','))
          .map((variable) => {
            return {
              data: variable.replace(/\n(\t)*(\s)*/gi, '').trim(),
              id: String(id),
            };
          }),
        'data',
      );
    }
    let functions = script.match(/\n(\t)*(\s)*def\s+\w+\s*\(.*\)/gi);
    if (functions) {
      functions = uniqBy(
        functions.map((fn) => {
          return {
            data: fn.replace(/\n(\t)*(\s)*def\s+/gi, '').trim(),
            id: String(id),
          };
        }),
        'data',
      );
    }

    return {
      variables: variables || [],
      functions: functions || [],
    };
  };

  const getRequestData = () => {
    return {
      ...detail,
      interval: detail?.updateInterval,
      etlScript: encodeURIComponent(editorContent.current),
      realAlertTest: enableRealAlert,
      updatePeriod: Number(detail?.updatePeriod ?? 0),
      filter: detail?.filter || '{}',
      filterRoot: detail?.filterRoot || '{}',
      filterDetail: detail?.filterDetail || '{}',
    };
  };

  const requestDetail = (id, refId = null) => {
    if (id != 0 || (id == 0 && refId)) {
      const requestId = id != 0 ? id : refId;
      const body = {
        action: 'get',
        id: requestId,
      };
      rayRequest(body).then((res) => {
        setDetail({
          ...res,
          updateInterval: res.updateInterval / MINUTE,
          updatePeriod: res.updatePeriod / MINUTE,
          id: id != 0 ? id : '',
        });
        editorContent.current = res.etlScript;
        rayRequest({
          action: 'get_history',
          id: requestId,
          from: 0,
          limit: 20,
        }).then((res) => {
          setHistoryScript(res);
        });
      });
    } else if (id == 0) {
      setDetail(defaultData);
      editorContent.current = defaultData.etlScript;
    }
  };

  React.useEffect(() => {
    requestDetail(id, refId);
    setTab(id == 0 ? TAB.CONFIG : TAB.SCRIPT);
  }, [id, refId]);

  React.useEffect(() => {
    const handlePostRequest = async (detail) => {
      const ref = detail?.ref;

      if (ref) {
        const requestItemRefs = String(ref)
          .split(',')
          .filter((el) => Boolean(el))
          .map((el) => {
            const subBody = {
              action: 'get',
              id: el.trim(),
            };
            return subBody;
          });

        try {
          const refContents = [];
          for (const subBody of requestItemRefs) {
            try {
              const response = await rayRequest(subBody);
              refContents.push(response);
            } catch {}
          }

          const importedSuggestions = refContents.reduce(
            (carry, data) => {
              if (!data?.id) return carry;
              const { variables, functions } = getSuggestionsFromScript(data.etlScript, data.id);
              carry.variables.push(...variables);
              carry.functions.push(...functions);
              return carry;
            },
            {
              variables: [],
              functions: [],
            },
          );
          setImportedData(refContents);
          importedSuggestionsRef.current = importedSuggestions;
        } catch {}
      } else {
        setImportedData([]);
        importedSuggestionsRef.current = {
          variables: [],
          functions: [],
        };
      }

      updateSuggestion(editorContent.current);
    };
    if (detail) {
      handlePostRequest(detail);
    }
  }, [detail?.ref]);

  React.useEffect(() => {
    if (detail?.updatedAt) setUpdatedAt(detail.updatedAt);
  }, [detail?.updatedAt]);

  React.useEffect(() => {
    if (updatedAt) {
      const minute = 1000;

      const handleUpdatedAt = setTimeout(() => {
        setUpdatedAt((prevValue) => prevValue + minute);
      }, minute);

      return () => {
        clearTimeout(handleUpdatedAt);
      };
    }
  }, [updatedAt]);

  const parseJsonArray = (str) => {
    try {
      const obj = JSON.parse(str.trim());
      if (Array.isArray(obj)) {
        return obj;
      }
    } catch (ignored) {}
    return [];
  };

  const showOutput = (data) => {
    if (typeof data === 'object') {
      setLog(JSON.stringify(data, null, 2));
      return;
    }
    const [log, alerts] = String(data).split(ALERT_SEPARATOR);
    setLog(log);
    if (!alerts) return;
    const messages = alerts.trim().split('\n');
    const slackMessages = parseJsonArray(messages[0])
      .filter((msg) => msg?.length > 0)
      .map((msg) => `https://app.slack.com/block-kit-builder/#${encodeURIComponent(msg)}`);
    const emailMessages = parseJsonArray(messages[1])
      .filter((msg) => msg?.length > 0)
      .map((msg) => spawnDocument(encodeURI(msg)));

    setAlerts({
      slackMessages: slackMessages,
      emailMessages: emailMessages,
    });
  };

  const test = () => {
    setDisabledButtons((prevValue) => {
      return {
        ...prevValue,
        test: true,
      };
    });
    const body = {
      ...getRequestData(),
      action: 'test',
      version: 2,
      enabled: true,
    };
    return rayRequest(body)
      .then(showOutput)
      .catch((e) => {
        setLog(e.error || e.message);
        setAlerts(defaultAlerts);
      })
      .finally(() => {
        setDisabledButtons((prevValue) => {
          return {
            ...prevValue,
            test: false,
          };
        });
      });
  };

  const testTrace = () => {
    setDisabledButtons((prevValue) => {
      return {
        ...prevValue,
        testTrace: true,
      };
    });
    const body = {
      ...getRequestData(),
      trace: traceId,
      action: 'testTrace',
      version: 2,
    };
    return rayRequest(body)
      .then(showOutput)
      .catch((e) => {
        setLog(e.error || e.message);
        setAlerts({
          slackMessages: [],
          emailMessages: [],
        });
      })
      .finally(() => {
        setDisabledButtons((prevValue) => {
          return {
            ...prevValue,
            testTrace: false,
          };
        });
      });
  };

  const testLocal = () => {
    setDisabledButtons((prevValue) => {
      return {
        ...prevValue,
        testLocal: true,
      };
    });

    const body = {
      def: { ...getRequestData(), etlScript: editorContent.current },
      action: 'test',
    };

    request
      .post('http://' + localhost + '/kb.jsp', body)
      .then(showOutput)
      .catch((e) => {
        setLog(e.error || e.message);
        setAlerts({
          slackMessages: [],
          emailMessages: [],
        });
      })
      .finally(() => {
        setDisabledButtons((prevValue) => {
          return {
            ...prevValue,
            testLocal: false,
          };
        });
      });
  };

  const save = (merged) => {
    setDisabledButtons((prevValue) => {
      return {
        ...prevValue,
        save: true,
      };
    });

    const body = {
      ...getRequestData(),
      action: 'saveReturnUpdated',
      scriptChanged: merged || detail.etlScript !== editorContent.current,
      ...(merged ? { updatedAt: new Date().getTime() } : {}),
      ...(id == 0 ? { service: serviceId, updatedAt: new Date().getTime() } : {}),
    };

    return rayRequest(body)
      .then((res) => {
        if (res.success && res.id) {
          requestDetail(res.id);
          window.dispatchEvent(new CustomEvent(RELOAD_LIST_EVENT));
          if (id == 0) {
            history.push(`/ray/${serviceId}/${res.id}`);
          }
        } else if (res.success == false && res.currentScript) {
          setCompareValue(res.currentScript);
          setMergeCodeOpen(true);
        }
        return res;
      })
      .catch((e) => {
        setLog(e.error || e.message || e);
        setAlerts({
          slackMessages: [],
          emailMessages: [],
        });
        return e;
      })
      .finally(() => {
        setDisabledButtons((prevValue) => {
          return {
            ...prevValue,
            save: false,
          };
        });
      });
  };

  const spawnDocument = (msg) => {
    let decodedMsg = msg;
    try {
      decodedMsg = decodeURI(msg);
    } catch {}
    const child = window.open('', '_blank', '');
    child.document.write(decodedMsg);
    child.document.close();
    return decodedMsg;
  };

  const handleCodeChange = (content) => {
    updateSuggestion(content);
    editorContent.current = content;
  };

  const handleToggleTheme = () => {
    editor.current._themeService.setTheme(theme === 'vs' ? 'vs-dark' : 'vs');
    setTheme(theme === 'vs' ? 'vs-dark' : 'vs');
  };

  const updateSuggestion = (content) => {
    const { variables, functions } = getSuggestionsFromScript(content, 'self');
    variables.push(...importedSuggestionsRef.current.variables);
    functions.push(...importedSuggestionsRef.current.functions);
    suggestions.splice(0, suggestions.length);
    defaultSuggestions.forEach((suggestion) => suggestions.push(suggestion));
    variables.forEach(({ data, id }) => {
      const newSuggestion = {
        label: { label: data, detail: ` - ${id}` },
        insertText: data,
        kind: 'Variable',
      };
      suggestions.push(newSuggestion);
    });
    functions.forEach(({ data, id }) => {
      const functionName = data.split('(')[0];
      const newSuggestion = {
        label: { label: functionName, detail: ` - ${id}` },
        insertText: data.replace(/\(.*\)/gi, (matched) =>
          matched === '()'
            ? matched
            : `(${matched
                .replace(/[\(\)]/g, '')
                .split(',')
                .map((el, index) => `\${${index + 1}:${el.split('=')[0]?.trim() || ''}}`)
                .join(', ')})`,
        ),
        insertTextRules: 'InsertAsSnippet',
      };
      suggestions.push(newSuggestion);
      const newSuggestionVariable = {
        label: { label: functionName, detail: ` - ${id}` },
        insertText: functionName,
        kind: 'Variable',
      };
      suggestions.push(newSuggestionVariable);
    });
  };

  React.useEffect(() => {
    const handleKeyUp = (e) => {
      if (e.ctrlKey) {
        if (e.key === 's') {
          e.stopPropagation();
          e.preventDefault();
          save(false);
        } else if (e.key === 'r') {
          e.stopPropagation();
          e.preventDefault();
          test();
        } else if (e.key === 'Enter') {
          e.stopPropagation();
          e.preventDefault();
          testTrace();
        }
      }
    };

    const handleKeyDown = (e) => {
      if ((e.ctrlKey || e.metaKey) && e.shiftKey) {
        if (e.key.toLowerCase() === 'l') {
          e.stopPropagation();
          e.preventDefault();
          handleToggleTheme();
        }
      }
    };

    document.addEventListener('keyup', handleKeyUp);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keyup', handleKeyUp);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [detail, theme]);

  const handleCloseMergeCode = () => {
    setMergeCodeOpen(false);
  };

  const handleCloseHistory = () => {
    setHistoryOpen(false);
    setSelectedScript(null);
  };

  const viewHistory = () => {
    setHistoryOpen(true);
    if (historyScript?.length) {
      setSelectedScript(0);
    }
  };

  function dateToFromNowDaily(myDate) {
    const momentDate = moment(myDate);
    const now = moment();

    const diffDays = now.date() - momentDate.date();
    const diffWeeks = now.week() - momentDate.week();

    let timeLabel = '';
    if (diffDays === 0 && diffWeeks === 0) timeLabel = 'Today';
    else if (diffDays === 1 && diffWeeks === 0) timeLabel = 'Yesterday';
    else if (diffWeeks === 0) timeLabel = momentDate.format('dddd');
    else if (diffWeeks === 1) timeLabel = momentDate.format('[Last] dddd');
    if (timeLabel) {
      return momentDate.format(`[${timeLabel} at] h:mm A`);
    }
    return momentDate.format('MMMM DD, YYYY [at] h:mm A');
  }

  const historyScriptMemo = React.useMemo(() => {
    return [
      {
        ...detail,
        etlScript: editorContent.current,
        updatedByEmail: aim.getUserSettings().profile?.userEmail,
        historyId: new Date().getTime(),
        updatedAt: new Date().getTime(),
      },
    ].concat(historyScript);
  }, [detail, historyScript, editorContent.current]);

  const handleSelectHistory = (index) => {
    setSelectedScript(index);
    const modifiedEditor = editorHistoryRef.current._editors?.modified;
    const originalEditor = editorHistoryRef.current._editors?.original;
    modifiedEditor.setValue('');
    originalEditor.setValue('');
    setTimeout(() => {
      modifiedEditor.setValue(historyScriptMemo[index]?.etlScript);
      originalEditor.setValue(historyScriptMemo[index + 1]?.etlScript || '');
    }, 500);
  };

  const historyScriptRenderMemo = React.useMemo(() => {
    return historyScriptMemo.map((item, index) => {
      return (
        <div
          className={clsx(classes.historyItem, index == selectedScript ? 'active' : '')}
          key={item.historyId}
          onClick={() => handleSelectHistory(index)}
        >
          <span>{dateToFromNowDaily(item.updatedAt)}</span>
          <span>{item.updatedByEmail}</span>
        </div>
      );
    });
  }, [historyScriptMemo, selectedScript]);

  const historyScriptContent = React.useMemo(() => {
    if (historyScriptMemo?.length == 0 || selectedScript == null) {
      return {
        value: '',
        compareValue: '',
      };
    }

    return {
      value: historyScriptMemo[selectedScript]?.etlScript,
      compareValue: historyScriptMemo[selectedScript + 1]?.etlScript || '',
    };
  }, [historyScriptMemo, selectedScript]);

  const referenceScript = React.useMemo(() => {
    const foundScript = importedData.find((data) => `script-${data.id}` === tab);
    if (!foundScript) {
      if ([TAB.SCRIPT, TAB.CONFIG].includes(tab)) return null;
      return (
        <div className={classes.codeEditor} style={{ paddingLeft: '24px' }}>
          <h1>Not found</h1>
        </div>
      );
    }
    return (
      <div className={classes.codeEditor}>
        <MonacoEditor
          ref={editor}
          language={'python'}
          onCodeChange={handleCodeChange}
          value={foundScript.etlScript}
          automaticLayout={true}
          theme={theme}
          options={{
            minimap: {
              enabled: true,
            },
          }}
          minimap={{ enabled: true }}
          scrollBeyondLastLine={false}
          wordWrap={true}
          readOnly={true}
        />
      </div>
    );
  }, [tab, importedData]);

  const actions = [
    {
      id: 'duplicate',
      title: 'Duplicate',
      icon: 'ic/heroicons:document-duplicate-20-solid/#0000008a',
      onClick: () => {
        window.open(`/ray/${serviceId}/0?ref=${id}`, '_blank');
      },
      disable: false,
    },
    {
      id: 'toggle-theme',
      title: theme === 'vs' ? 'Dark theme' : 'Light theme',
      icon: theme === 'vs' ? 'ic/material-symbols:dark-mode/#0000008a' : 'ic/material-symbols:light-mode/#0000008a',
      onClick: handleToggleTheme,
      disable: false,
    },
    {
      id: 'toggle-log',
      title: 'Toggle log',
      icon: 'ic/ic:baseline-insert-drive-file/#0000008a',
      onClick: () => {
        setToggleLog((prevValue) => !prevValue);
      },
      disable: false,
    },
  ];

  if (!id || !detail) return null;

  return (
    <div className={classes.container}>
      <ErrorBoundary>
        <div className={classes.header}>
          <h1
            className={classes.name}
            contentEditable
            onBlur={(e) => {
              setDetail((prevValue) => {
                return {
                  ...prevValue,
                  name: e.target?.innerText || prevValue?.name,
                };
              });
              if (!e.target?.innerText) {
                e.target.innerText = detail.name;
              }
            }}
            suppressContentEditableWarning
          >
            {detail.name}
          </h1>
          <div className={classes.topRightSection}>
            <div className={classes.updatedAt}>
              {id != 0 ? (
                <span>
                  Edited {updatedAt ? moment(updatedAt).fromNow() : 'long time ago'} by{' '}
                  {detail.updatedByEmail || 'unknown'}
                </span>
              ) : null}
            </div>
            <Button onClick={viewHistory}>
              <Icon
                type={`ic/fluent:history-32-filled/#${theme === 'vs-dark' ? 'ffffff' : '0000008a'}`}
                size={24}
                aria-label={'view-history'}
              />
            </Button>
            <Button onClick={() => favoriteFunctions.handleToggleFavorite(id)}>
              <Icon
                type={
                  isFavorite
                    ? 'ic/ic:outline-star/yellow'
                    : `ic/ic:outline-star-border/#${theme === 'vs-dark' ? 'ffffff' : '0000008a'}`
                }
                size={24}
                aria-label={isFavorite ? 'remove-from-favorites' : 'add-to-favorites'}
              />
            </Button>
            <Dropdown
              className={classes.dropdownButton}
              disabledToggleStyle
              alignMenu="right"
              label=""
              icon={'threeDotsVertical'}
              sizeIcon={'12px'}
              defaultOpen={openMenu}
              handleOnClosed={() => setOpenMenu(false)}
              disabledClickContentClose={false}
            >
              {actions.map((action) => {
                return (
                  <ButtonWithIcon onClick={action.onClick} title={action.title} icon={action.icon} key={action.id} />
                );
              })}
            </Dropdown>
          </div>
        </div>
        <Tabs value={tab} onChange={(event, value) => setTab(value)} variant="scrollable">
          <Tab label={'Config'} value={TAB.CONFIG} />
          <Tab label={`${detail.id || 'new'}-${detail.name}.py`} value={TAB.SCRIPT} />
          {importedData.map((data) => {
            const label = (
              <div style={{ display: 'flex', columnGap: '4px', alignItems: 'center' }}>
                <span>
                  {data.id}-{data.name}.py
                </span>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  onClick={() => window.open(`/ray/${serviceId}/${data.id}`, '_blank')}
                >
                  <Icon type={'duplicate'} />
                </div>
              </div>
            );
            return <Tab key={data.id} label={label} value={`script-${data.id}`} />;
          })}
        </Tabs>
        <div className={classes.codeEditor} style={{ display: tab !== TAB.SCRIPT ? 'none' : null }}>
          <MonacoEditor
            ref={editor}
            language={'python'}
            onCodeChange={handleCodeChange}
            value={detail.etlScript}
            automaticLayout={true}
            suggestions={suggestions}
            theme={theme}
            options={{
              minimap: {
                enabled: true,
              },
            }}
            minimap={{ enabled: true }}
            scrollBeyondLastLine={false}
            wordWrap={true}
          />
        </div>
        <div className={classes.configContainer} style={{ display: tab !== TAB.CONFIG ? 'none' : null }}>
          <RayConfig detail={detail} setDetail={setDetail} />
        </div>
        {referenceScript}
        <div className={classes.actionContainer}>
          <Box display={'flex'} flexDirection={'column'} style={{ rowGap: '4px' }}>
            <Box display={'flex'} alignItems={'center'} style={{ columnGap: '4px' }}>
              <FormControlLabel
                label={'Enabled'}
                control={
                  <Checkbox
                    checked={detail.enabled}
                    onChange={() =>
                      setDetail((prevValue) => ({
                        ...prevValue,
                        enabled: !prevValue.enabled,
                      }))
                    }
                    color={'primary'}
                  />
                }
              />
              {tab === TAB.SCRIPT ? (
                <>
                  {/* <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      editor.current.formatCode();
                    }}
                  >
                    Format code
                  </Button> */}
                </>
              ) : null}
            </Box>
            <Box display={'flex'} alignItems={'center'} style={{ columnGap: '4px' }}>
              <Button
                variant="contained"
                color="primary"
                disabled={disabledButtons['save']}
                onClick={() => {
                  save(false);
                }}
              >
                Save
              </Button>
              <Button
                variant="contained"
                color="secondary"
                disabled={disabledButtons['test']}
                onClick={() => {
                  test();
                }}
              >
                Test
              </Button>
              {serviceId === 'x-ray-monitor' ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={disabledButtons['testTrace']}
                    onClick={() => {
                      testTrace();
                    }}
                  >
                    Test trace
                  </Button>
                  <div style={{ flex: '0 0 50%' }}>
                    <TextFieldCustom
                      onChangeText={(value) => {
                        setTraceId(value);
                      }}
                      defaultValue={traceId}
                      suffix={' '}
                    />
                  </div>
                </>
              ) : serviceId === 'hyper-integration' ? (
                <>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={disabledButtons['testLocal']}
                    onClick={() => {
                      testLocal();
                    }}
                  >
                    Test local
                  </Button>
                  <div style={{ flex: '0 0 50%' }}>
                    <TextFieldCustom
                      onChangeText={(value) => {
                        setLocalhost(value);
                      }}
                      defaultValue={localhost}
                      suffix={' '}
                    />
                  </div>
                </>
              ) : null}
              <FormControlLabel
                label={'Real Alert'}
                control={
                  <Checkbox
                    checked={enableRealAlert}
                    onChange={(event) => setEnableRealAlert(event.target.checked)}
                    color={'primary'}
                  />
                }
              />
            </Box>
          </Box>
        </div>
        <Dialog open={mergeCodeOpen} onClose={handleCloseMergeCode} fullScreen>
          <div className={classes.dialogContainer}>
            <div className={classes.mergeCodeContainer}>
              <MonacoCompareEditor
                ref={editorCompareRef}
                language={'python'}
                onCodeChange={handleCodeChange}
                value={editorContent.current}
                suggestions={suggestions}
                options={{
                  automaticLayout: true,
                  theme: theme,
                  minimap: {
                    enabled: false,
                  },
                  scrollBeyondLastLine: false,
                  wordWrap: true,
                  renderSideBySide: false,
                }}
                compareValue={compareValue}
              />
            </div>
            <div className={classes.actionContainer}>
              <Box display={'flex'} alignItems={'center'} style={{ columnGap: '4px' }}>
                <Button
                  variant="contained"
                  color="primary"
                  disabled={disabledButtons['save']}
                  onClick={() => {
                    save(true)
                      .then((res) => {
                        if (res.success) {
                          handleCloseMergeCode();
                        } else {
                          alert(res.message);
                        }
                      })
                      .catch((e) => {
                        setLog(e.error || e.message);
                      });
                  }}
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  disabled={disabledButtons['test']}
                  onClick={() => {
                    handleCloseMergeCode();
                  }}
                >
                  Cancel
                </Button>
              </Box>
            </div>
          </div>
        </Dialog>
        <Dialog open={historyOpen} onClose={handleCloseHistory} fullScreen>
          <div className={classes.historyDialogContainer}>
            <div className={classes.mergeCodeContainer}>
              <MonacoCompareEditor
                ref={editorHistoryRef}
                language={'python'}
                onCodeChange={() => undefined}
                value={historyScriptContent.value}
                suggestions={suggestions}
                options={{
                  automaticLayout: true,
                  theme: theme,
                  minimap: {
                    enabled: false,
                  },
                  scrollBeyondLastLine: false,
                  wordWrap: true,
                  renderSideBySide: false,
                  readOnly: true,
                }}
                compareValue={historyScriptContent.compareValue}
              />
            </div>
            <div className={classes.historyActionContainer}>
              <div className={classes.historyList}>{historyScriptRenderMemo}</div>
              <div className={classes.historyActions}>
                <Box display={'flex'} alignItems={'center'} style={{ columnGap: '4px' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={selectedScript == 0}
                    onClick={() => {
                      const etlScript = historyScriptMemo[selectedScript]?.etlScript;
                      if (etlScript != null) {
                        editor.current.setValue(etlScript);
                        handleCloseHistory();
                      }
                    }}
                  >
                    Restore version
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={disabledButtons['test']}
                    onClick={handleCloseHistory}
                  >
                    Cancel
                  </Button>
                </Box>
              </div>
            </div>
          </div>
        </Dialog>
      </ErrorBoundary>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default RayMainCode;
