import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const AddGroup = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.00516 2.89776C2.00175 2.93137 2 2.96548 2 3V5C2 5.55228 2.44772 6 3 6C3.55228 6 4 5.55228 4 5V4H6C6.55228 4 7 3.55229 7 3C7 2.44772 6.55228 2 6 2H3C2.48223 2 2.05637 2.3935 2.00516 2.89776ZM8 4C7.44772 4 7 4.44772 7 5V7H5C4.44772 7 4 7.44771 4 8C4 8.55228 4.44772 9 5 9H7V11C7 11.5523 7.44772 12 8 12C8.55228 12 9 11.5523 9 11V9H11C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7H9V5C9 4.44772 8.55228 4 8 4ZM13 10C12.4477 10 12 10.4477 12 11V12H10C9.44772 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H13C13.5523 14 14 13.5523 14 13V11C14 10.4477 13.5523 10 13 10ZM2 11C2 10.4477 2.44772 10 3 10C3.55228 10 4 10.4477 4 11V12H6C6.55228 12 7 12.4477 7 13C7 13.5523 6.55228 14 6 14H3C2.44772 14 2 13.5523 2 13V11ZM13 2C13.5178 2 13.9436 2.3935 13.9948 2.89776C13.9983 2.93137 14 2.96548 14 3V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5V4H10C9.44771 4 9 3.55228 9 3C9 2.44772 9.44772 2 10 2H13Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default AddGroup;
