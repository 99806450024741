import * as React from 'react';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import GroupButtonSubmit from '../group-button-submit';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        width: '208px',
        height: '32px',
      },
    },
    width: '100%',
  },
}));
const options: OptionSelectType[] = [
  {
    label: 'Broad match',
    value: 'BROAD_MATCH',
  },
  {
    label: 'Exact match',
    value: 'EXACT_MATCH',
  },
];

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const MatchTypeEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const [matchType, setMatchType] = React.useState('');
  const classes = useStyles();
  React.useEffect(() => {
    if (data.value.value !== matchType) {
      setMatchType(data.value.value);
    }
  }, [data.value.value, anchorEl]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const item: OptionSelectType = options.find((m) => m.value == matchType);
    if (item) {
      const valueTmp: IValueTextFormat = {
        label: item.label,
        value: item.value,
      };
      setIsSubmitting(true);
      const response: any = await onSubmit({ value: valueTmp, row: data.node.data });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    } else {
      handleClosed();
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <SelectForm
          options={options}
          value={matchType}
          className={classes.select}
          placeholder="Placeholder"
          onChange={(e) => setMatchType(e.target.value)}
        />
      </div>
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default MatchTypeEditor;
