import { Button, Chip, Grid, makeStyles, Popover } from '@material-ui/core';
import * as React from 'react';
import _ from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import CreateNoteEditor from '../../inline-edit/cell/createnote-editor';

const useStyles = makeStyles(() => ({
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#253746',
  },
  menu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  typography: {
    paddingLeft: '10px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
}));

export interface INote {
  value: string;
  label: string;
}
export interface INoteFormat {
  value: Array<INote>;
}

const NoteFormat = (props: IPropsFormat<INoteFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, ['cellAction'], []);
  const updateHandler = _.get(props, 'updateHandler');
  const node = _.get(props, ['node'], null);
  const value = _.get(props, ['value'], null);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const [isCreateNoteForm, setIsCreateNoteForm] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const propsComponent = {
    onSubmit: updateHandler,
    data: {
      value,
      node,
    },
    setAnchorEl: (val: any) => {
      setAnchorEl(val);
    },
    anchorEl: anchorEl,
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderNote = () => {
    if (value.label) {
      return (
        <Grid container alignItems={'center'} spacing={1}>
          <Grid item xs={12}>
            <span className={classes.label}>{value.label}</span>
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Grid container alignItems={'center'} spacing={1}>
          <div className={classes.menu}>
            <Button
              size="small"
              onClick={(event) => {
                setIsCreateNoteForm(true);
                setAnchorEl(event.currentTarget);
              }}
              style={{ padding: 8 }}
            >
              <Icon type="plus" />
            </Button>
            {isCreateNoteForm && (
              <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <CreateNoteEditor {...propsComponent} title="Note" />
              </Popover>
            )}
          </div>
        </Grid>
      );
    }
  };
  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={value.label ? cellAction : []}
      node={node}
      value={value}
      isGrouped={isGrouped}
    >
      {renderNote()}
    </WrapperFormat>
  );
};
export default NoteFormat;
