import * as React from 'react';
import App from '@ep/insight-ui/eo2/app';
import { LoadingUI } from '@ep/insight-ui/eo2/components/common/loading';
import ContainerInternalPage from '@ep/insight-ui/eo2/container/internal-page';
import { Watchlist } from '@ep/insight-ui/system/util/uamonit/watchlist';
import { getPagePrefix1 } from '@ep/insight-ui/eo2/global';

window.uaWatchlist = window.uaWatchlist ? window.uaWatchlist : new Watchlist();

export default function OneApp() {
  return (
    <App
      morePage={(canAccess) => {
        return next(canAccess);
      }}
    />
  );
}

export const next = (canAccess) => [
  {
    title: 'Intelligence',
    url: getPagePrefix1(),
    page: React.lazy(() => import('@eip/next/src/page/main')),
    exact: false,
    hidden: false,
    children: [],
  },
  {
    title: 'Workspace',
    url: `/:workspaceId${getPagePrefix1()}`,
    page: React.lazy(() => import('@eip/next/src/page/main')),
    exact: false,
    hidden: false,
    children: [],
  },
  {
    title: 'Workspace',
    url: `/:workspaceId`,
    page: React.lazy(() => import('@eip/next/src/page/main')),
    exact: false,
    hidden: false,
    children: [],
  },
];

export function withPageReverseControl(
  LazyComponent: React.ComponentType<{
    pageContainer: React.ComponentType;
  }>,
) {
  function PageWithLazy() {
    return (
      <React.Suspense fallback={<LoadingUI loading />}>
        <LazyComponent pageContainer={ContainerInternalPage} />
      </React.Suspense>
    );
  }

  PageWithLazy.displayName = `LazyPageReverse(${LazyComponent.displayName})`;

  return PageWithLazy;
}
