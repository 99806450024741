import { eipRequest as request } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';

const convertBodyToString = (json) => {
  return Object.entries(json)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');
};

export const rayRequest = (body) => {
  return request.post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb.jsp', convertBodyToString(body), {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
  });
};

export const RELOAD_LIST_EVENT = 'reload_list_event';
