export const WATERFALL_CONFIG = {
  title: 'Waterfall',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_METRIC_DATA: '/https://staging-datacenter.epsilo.io/metrics.jsp',
    GET_CHART_DATA: 'https://staging-datacenter.epsilo.io/charts.jsp',
  },
  useDataSource: false,
  primaryKeys: [],
  tableType: 'waterfall',
  hiddenFilter: {
    currency: 'USD',
  },
  mapping: {
    // ============= DIMENSION =============

    // ============= ATTRIBUTE =============

    // ============= METRIC =============
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
    },
    direct_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
      },
    },
    direct_ads_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
      },
    },
    items_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    direct_order: {
      title: 'Direct order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_order',
      valueGetter: {
        value: 'direct_order',
      },
    },
    total_order: {
      title: 'Total order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'total_order',
      valueGetter: {
        value: 'total_order',
      },
    },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  settingType: [
    {
      type: 'metric',
      label: 'Metric',
    },
  ],
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
};
