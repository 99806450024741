import * as React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList, { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import ConditionRow from './condition-row';
import { get, last, set } from 'lodash';
import { useField, useFormikContext } from 'formik';

/**
 * ff.improve_script_detail_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.improve_script_detail_mobile:end
 */

const useStyles = makeStyles({
  dropdownMenu: {
    display: 'flex',
    alignItems: ' center',

    '& p': {
      marginRight: '8px',
      marginLeft: '4px',
    },
  },
  container: {
    marginBottom: 10,
  },
  textStyleCondition: {
    height: '24px',
    minHeight: '24px',
    '& .eip1-MuiTypography-body1': {
      fontWeight: '500',
    },
    ...(ff.improve_script_detail_mobile
      ? {
          '.eres--small &': {
            padding: 0,
          },
        }
      : {}),
  },
});

type ConditionType = {
  filters: Array<{
    combinator: 'and' | 'or';
    targetObject?: string;
    attribute?: string;
    timeQuery?: string;
    timeUnit?: string;
    timeQuantity?: number;
    timeInclusive?: string;
    conditionOperator?: string;
    value?: number;
  }>;
};

const Condition = ({
  condition,
  options,
  name,
  onChange,
  disabled = false,
}: {
  condition: ConditionType;
  options: React.ComponentProps<typeof ConditionRow>['options'];
  name: string;
  onChange: (condition: ConditionType) => void;
  disabled?: boolean;
}) => {
  const classes = useStyles();

  /**
   * ff.improve_script_detail_mobile:start
   */
  let containerClass;
  if (ff.improve_script_detail_mobile) {
    containerClass = React.useContext(ContainerResponsiveContext).containerClass;
  }
  /**
   * ff.improve_script_detail_mobile:end
   */

  const conditions = React.useMemo(() => {
    if (condition.filters) return condition.filters;
    return [];
  }, [condition.filters]);
  // const [conditions, setConditions] = React.useState(condition.filters || []);
  const { handleChange, values, setValues } = useFormikContext();

  const handleAddCondition = () => {
    const tempConditions = [...conditions];
    tempConditions.push({
      combinator: get(tempConditions, [1, 'combinator'], 'and'),
      targetObject: get(options, 'targetObject[0].value', ''),
      attribute: get(options, 'attribute[0].value', ''),
      timeQuery: get(options, 'timeQuery[0].value', ''),
      timeUnit: get(options, 'timeUnit[0].value', ''),
      timeQuantity: get(options, 'timeQuantity', 0),
      timeInclusive: get(options, ['0', 'value'], ''),
      conditionOperator: get(options, 'conditionOperator[0].value', ''),
      value: get(options, 'value', 0),
    });
    const newCondition = { ...condition };
    newCondition.filters = tempConditions;

    onChange(newCondition);
  };
  const handleDelete = (index: number) => {
    const tempConditions = [...conditions];
    if (tempConditions.length > 0) {
      tempConditions.splice(index, 1);

      const newCondition = { ...condition };
      newCondition.filters = tempConditions;

      onChange(newCondition);
    }
  };
  const handleDuplicate = (index: number) => {
    const tempConditions = [...conditions];
    tempConditions.splice(index, 0, tempConditions[index]);

    const newCondition = { ...condition };
    newCondition.filters = tempConditions;

    onChange(newCondition);
  };
  const dataMenu: Array<Array<DropdownMenuDataType>> = [
    [
      {
        title: 'Add more condition',
        icon: 'plus',
        onClick: handleAddCondition,
      },
    ],
  ];
  return (
    <Grid
      container
      className={ff.improve_script_detail_mobile ? `${classes.container} ${containerClass}` : classes.container}
    >
      <Grid item xs={12}>
        {conditions.map((condition, index) => {
          return (
            <ConditionRow
              index={index}
              key={index}
              options={options}
              disabledOperatorCell={index == 0}
              handleDelete={handleDelete}
              handleDuplicate={handleDuplicate}
              name={`${name}.filters[${index}]`}
              disabled={disabled}
            />
          );
        })}
      </Grid>
      {!disabled && (
        <Grid item>
          <MenuList listMenu={dataMenu} classNameCondition={classes.textStyleCondition} />
        </Grid>
      )}
    </Grid>
  );
};

export default Condition;
