import * as React from 'react';
import { get } from 'lodash';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import ButtonAction from './button-action';

const ReloadTableBtn = () => {
  const backbone = React.useContext(TableBackboneContext);
  const groupBy = backbone.getConfig('groupBy');

  const gridApi = backbone.getGridApi();

  const handleReloadTable = () => {
    if (gridApi) {
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.length > 0) {
        const expanded = [];
        gridApi.forEachNode((node) => {
          if (node.expanded) {
            expanded.push(node);
          }
        });
        expanded.forEach((el) => {
          gridApi.refreshServerSideStore({ route: [el.key] });
        });
      } else {
        gridApi.refreshServerSideStore();
      }
    }
  };

  return (
    <>
      <ButtonAction
        icon="reload"
        label={'Reload'}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={handleReloadTable}
      />
    </>
  );
};

export default ReloadTableBtn;
