import { EIP_CONSTANT } from '@eip/next/lib/main';

export const COMPACT_KEYWORD_CONFIG = {
  tableType: 'compact',
  title: 'Keyword',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getSuggestedAdsPlacementData',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  searchHint: 'Looking for keywords',
  system: {
    hiddenComponents: ['timeline'],
  },
  primaryKeys: ['SUGGESTED_KEYWORD.id', 'PRODUCT.id'],
  sort: [{ field: 'search_volume', sort: 'desc' }],
  mapping: {
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SUGGESTED_KEYWORD.name',
      valueGetter: {
        label: 'SUGGESTED_KEYWORD.name',
        id: 'SUGGESTED_KEYWORD.id',
      },
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'SUGGESTED_KEYWORD.product_name',
      valueGetter: {
        label: 'SUGGESTED_KEYWORD.product_name',
        subtext: 'SUGGESTED_KEYWORD.master_object_id',
        id: 'SUGGESTED_KEYWORD.product_name',
      },
    },

    // ============= ATTRIBUTE =============

    // ============= METRIC =============
    // impression: {
    //   title: 'Impression',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'impression',
    //   valueGetter: {
    //     value: 'impression',
    //   },
    // },
    // click: {
    //   title: 'Click',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'click',
    //   valueGetter: {
    //     value: 'click',
    //   },
    // },
    // cost: {
    //   title: 'Cost',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'cost',
    //   valueGetter: {
    //     value: 'cost',
    //     currency: 'STOREFRONT.currency',
    //   },
    // },
    // ads_item_sold: {
    //   title: 'Ads item sold',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'ads_item_sold',
    //   valueGetter: {
    //     value: 'ads_item_sold',
    //   },
    // },
    // direct_item_sold: {
    //   title: 'Direct ads item sold',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'direct_item_sold',
    //   valueGetter: {
    //     value: 'direct_item_sold',
    //   },
    // },
    // ads_gmv: {
    //   title: 'Ads GMV',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ads_gmv',
    //   valueGetter: {
    //     value: 'ads_gmv',
    //     currency: 'STOREFRONT.currency',
    //   },
    // },
    // direct_gmv: {
    //   title: 'Direct ads GMV',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'direct_gmv',
    //   valueGetter: {
    //     value: 'direct_gmv',
    //     currency: 'STOREFRONT.currency',
    //   },
    // },
    // ads_order: {
    //   title: 'Ads order',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'ads_order',
    //   valueGetter: {
    //     value: 'ads_order',
    //   },
    // },
    // direct_ads_order: {
    //   title: 'Direct ads order',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'direct_order',
    //   valueGetter: {
    //     value: 'direct_order',
    //   },
    // },
    // roas: {
    //   title: 'ROAS',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'roas',
    //   valueGetter: {
    //     value: 'roas',
    //   },
    // },
    // cir: {
    //   title: 'CIR',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'cir',
    //   valueGetter: {
    //     value: 'cir',
    //   },
    // },
    // cpc: {
    //   title: 'CPC',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'cpc',
    //   valueGetter: {
    //     value: 'cpc',
    //     currency: 'STOREFRONT.currency',
    //   },
    // },
    // ctr: {
    //   title: 'CTR',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ctr',
    //   valueGetter: {
    //     value: 'ctr',
    //   },
    // },
    // cr: {
    //   title: 'CR',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'cr',
    //   valueGetter: {
    //     value: 'cr',
    //   },
    // },
    search_volume: {
      title: 'Search volume',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'SUGGESTED_KEYWORD.search_volume',
      valueGetter: {
        value: 'SUGGESTED_KEYWORD.search_volume',
      },
    },
    keyword_suggested_bidding_price: {
      title: 'Suggest bidding price',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'SUGGESTED_KEYWORD.bidding_price',
      valueGetter: {
        value: 'SUGGESTED_KEYWORD.bidding_price',
        currency: 'STOREFRONT.currency',
      },
    },
  },
};
