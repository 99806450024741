import * as React from 'react';
import clsx from 'clsx';

import { Box, Button, Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { SwitchIcon } from '@ep/insight-ui/elements/modal/switch';
import { useHistory } from 'react-router';
import ButtonWithIcon from './button-with-icon';

const useStyles = makeStyles(() => ({
  container: {},
  styleButton: {
    backgroundColor: 'transparent !important',
    minWidth: '20px',
    height: '20px',
    color: '#253746 !important',
  },
  footerDropdown: {
    paddingTop: '8px',
  },
  topGrid: {
    flexWrap: 'nowrap',
    padding: '15px 8px 12px 8px ',
  },
}));

const bgSidebar = '#FAFCFF';

const RayFooter = () => {
  const classes = useStyles();

  const history = useHistory();

  const handleQuitRay = () => {
    history.push('/');
  };

  return (
    <div className={classes.container}>
      <div style={{ position: 'sticky', bottom: 0, backgroundColor: bgSidebar }}>
        <Divider />
        <Grid container justifyContent={'space-between'} alignItems={'center'} className={` ${classes.topGrid}`}>
          <Box display={'flex'} alignItems={'center'}>
            <Icon type={'logo'} fontSizeAdjust={16} style={{ width: 72, height: 26.6 }} />
          </Box>
          <Dropdown
            classNameFooter={classes.footerDropdown}
            className={classes.styleButton}
            disabledToggleStyle
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            sizeIcon={'12px'}
          >
            <ButtonWithIcon title={'Quit Ray'} icon={'ic/solar:exit-bold-duotone/xanadu'} onClick={handleQuitRay} />
          </Dropdown>
        </Grid>
      </div>
    </div>
  );
};

export default RayFooter;
