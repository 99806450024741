import * as React from 'react';
import clsx from 'clsx';

import { makeStyles, List, ListItem, ListItemText, Tooltip, Button, Grid, Box } from '@material-ui/core';

import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';

import { useCalculate } from '../hooks/use-calculate';
import ConditionalWrap from '../../util/conditional-wrap';
import Icon from '@ep/insight-ui/icons/Icon';

export const PINNED_ROW_HEIGHT = 32;

const useStyles = makeStyles(() => ({
  calculate: {
    '.ag-react-container &': {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      width: '100%',
    },
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  calculateBtn: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '10px',
    cursor: 'pointer',
  },
  cellDropdown: {
    maxWidth: 250,
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  disable: {
    pointerEvents: 'none',
    color: '#C2C7CB',
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  reloadButton: {
    '&.eip1-MuiButton-text': {
      padding: '6px 0',
      minWidth: '26px',
    },
    '& .eip1-MuiTouchRipple-root': {
      display: 'none',
    },
  },
}));

const CalculateFormat = (params) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = React.useState(false);

  const {
    open,
    setOpen,
    disabled,
    dropdownMenu,
    handleClickMenuItem,
    loading,
    currentData,
    selectedMenu,
    errorFunc,
    requestCalculate,
  } = useCalculate(params);

  const renderMenuItem = dropdownMenu.map((menu: any, index: number) => {
    return (
      <div key={index}>
        <ListItem
          className={clsx({
            [classes.itemDropdown]: true,
            [classes.disable]: menu.disable,
          })}
          disabled={menu.disable}
          role={'menuitem'}
          button
          onClick={() => handleClickMenuItem(menu)}
        >
          <ListItemText primary={menu.selectLabel} className={classes.textStyle} />
        </ListItem>
      </div>
    );
  });

  const calculateLabel = React.useMemo(() => {
    return (
      <LoadingComponent loading={loading && currentData.label}>
        {currentData.value != null || errorFunc ? (
          <div>
            <span style={{ color: '#8C98A4' }}>{currentData.label} </span>
            <ConditionalWrap
              condition={['LIST'].includes(selectedMenu?.id) && !errorFunc}
              wrap={(children) => {
                const items = String(currentData.value).split(',');
                return (
                  <Tooltip
                    title={
                      <div>
                        <div style={{ marginBottom: '0.3em' }}>{items.length} item(s)</div>
                        {items.map((i, index) => {
                          return (
                            <React.Fragment key={index}>
                              {i}
                              <br />
                            </React.Fragment>
                          );
                        })}
                      </div>
                    }
                    classes={{ tooltip: classes.tooltip }}
                    placement="top-start"
                    PopperProps={{
                      popperOptions: {
                        modifiers: {
                          flip: { enabled: false },
                        },
                      },
                    }}
                  >
                    {children}
                  </Tooltip>
                );
              }}
            >
              <span>{!errorFunc ? currentData.value : '-'}</span>
            </ConditionalWrap>
          </div>
        ) : (
          <>Calculate</>
        )}
      </LoadingComponent>
    );
  }, [currentData, loading, errorFunc]);

  return (
    <div
      className={`${classes.calculate} ag-cell-calculate`}
      style={{ opacity: currentData.value != null || loading || errorFunc ? 1 : null }}
      onMouseEnter={() => {
        params.tableDOM?.current.querySelector('.ag-floating-bottom').classList.remove('hover');
        setIsHovering(true);
      }}
      onMouseLeave={() => {
        setIsHovering(false);
      }}
    >
      <Dropdown
        defaultOpen={open}
        onOpen={() => {
          setOpen(true);
          if (params.tableDOM && params.tableDOM.current) {
            params.tableDOM.current.querySelector('.ag-floating-bottom').classList.add('hover');
          }
        }}
        label={calculateLabel}
        icon={currentData.value != null || loading ? null : 'chevron'}
        disabled={disabled}
        dataMenuPage={[dropdownMenu]}
        handleOnClosed={() => {
          params.tableDOM?.current.querySelector('.ag-floating-bottom').classList.remove('hover');
        }}
      >
        <List component="nav" aria-label="mailbox folders" className={classes.cellDropdown}>
          {renderMenuItem}
        </List>
      </Dropdown>
      {errorFunc ? (
        <Button variant="text" onClick={() => requestCalculate(errorFunc)} className={classes.reloadButton}>
          <Tooltip
            title={'Reload'}
            classes={{ tooltip: classes.tooltip }}
            placement="top-start"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                },
              },
            }}
          >
            <Box>
              <Icon type={'reload'} />
            </Box>
          </Tooltip>
        </Button>
      ) : null}
      {isHovering && currentData.value != null ? (
        <Button
          variant="text"
          onClick={() => {
            const cb = navigator.clipboard;
            cb.writeText(String(currentData.value)).then(() => setIsHovering(false));
          }}
          className={classes.reloadButton}
        >
          <Tooltip
            title={'Copy'}
            classes={{ tooltip: classes.tooltip }}
            placement="top-start"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                },
              },
            }}
          >
            <Box>
              <Icon type={'copy'} />
            </Box>
          </Tooltip>
        </Button>
      ) : null}
    </div>
  );
};

export default CalculateFormat;
