import * as React from 'react';
import { get } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { TextareaAutosize } from '@material-ui/core';
import GroupButtonSubmit from '../group-button-submit';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { IValueStatusTextFormat } from '@ep/insight-ui/elements/table/format/status-text-format';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  inputText: {
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      height: '32px',
    },
    width: '100%',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  TextField: {
    width: '100%',
  },
  InputPaper: {
    '& input': {
      width: '100%',
    },
  },
}));

type Props = {
  data: {
    value: IValueStatusTextFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  isTextarea?: boolean;
};
const TextEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const classes = useStyles();

  const ref = React.useRef(null);

  const [value, setValue] = React.useState<string>(get(data, ['value', 'value'], ''));
  const [error, setError] = React.useState<{ visible: boolean; textError: string }>({
    visible: false,
    textError: '',
  });

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  const { onToast } = useToast();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    if (!value) {
      setError({
        visible: true,
        textError: 'This field is required',
      });
    } else {
      setIsSubmitting(true);
      const response: any = await onSubmit({
        value,
        row: data.node.data,
        backbone: backboneContext,
        rowNode: data.node,
      });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        onToast({
          title: 'Success',
          messages: response.message,
          variant: 'success',
        });
        handleClosed();
      } else {
        setError({
          visible: true,
          textError: response.message,
        });
      }
    }
  };

  React.useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref.current]);

  React.useEffect(() => {
    setValue(get(data, ['value', 'value'], ''));
  }, [data]);

  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <TextareaAutosize
          ref={ref}
          style={{ width: '100%', borderColor: error.visible && '#D4290D', borderRadius: error.visible && '2px' }}
          minRows={5}
          maxRows={5}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            setError({
              visible: false,
              textError: '',
            });
          }}
        />
        {error && error.visible ? <div className={classes.textError}>{error.textError}</div> : null}
      </div>
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default TextEditor;
