import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const LinesChart = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="3 3 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.28803 6.28809C5.89675 6.67937 5.89675 7.3126 6.28803 7.70388L8.27614 9.69199C8.66742 10.0833 9.30065 10.0833 9.69193 9.69199C10.0832 9.30071 10.0832 8.66748 9.69193 8.2762L7.70382 6.28809C7.31254 5.89681 6.67848 5.89681 6.28803 6.28809Z" />
    <path d="M7.69966 6.292C8.09094 6.68328 8.09094 7.3165 7.69966 7.70779L3.70925 11.6982C3.31797 12.0895 2.68474 12.0895 2.29346 11.6982C1.90218 11.3069 1.90218 10.6737 2.29346 10.2824L6.28387 6.292C6.67431 5.90071 7.30837 5.90071 7.69966 6.292Z" />
    <path d="M13.6798 4.29918C14.0711 4.69046 14.0711 5.32369 13.6798 5.71497L9.70028 9.69453C9.309 10.0858 8.67577 10.0858 8.28449 9.69453C7.89321 9.30325 7.89321 8.67002 8.28449 8.27874L12.264 4.29918C12.6553 3.90874 13.2886 3.90874 13.6798 4.29918Z" />
  </SvgIcon>
);

export default LinesChart;
