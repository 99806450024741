import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Delete = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.71 1.29C9.61656 1.19732 9.50574 1.12399 9.38391 1.07423C9.26207 1.02446 9.13161 0.999239 9 1H7C7 0.734784 6.89464 0.48043 6.70711 0.292893C6.51957 0.105357 6.26522 0 6 0H4C3.73478 0 3.48043 0.105357 3.29289 0.292893C3.10536 0.48043 3 0.734784 3 1H1C0.734784 1 0.48043 1.10536 0.292893 1.29289C0.105357 1.48043 0 1.73478 0 2C0 2.26522 0.105357 2.51957 0.292893 2.70711C0.48043 2.89464 0.734784 3 1 3H9C9.26522 3 9.51957 2.89464 9.70711 2.70711C9.89464 2.51957 10 2.26522 10 2C10.0008 1.86839 9.97554 1.73793 9.92577 1.61609C9.87601 1.49426 9.80268 1.38344 9.71 1.29Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.98515 4H1.99786C1.85515 3.99879 1.71384 4.02823 1.58347 4.08633C1.45309 4.14444 1.33669 4.22985 1.24211 4.33682C1.14753 4.44379 1.07697 4.56982 1.0352 4.70641C0.993434 4.84299 0.981424 4.98695 0.999982 5.12858L1.74839 11.121C1.77781 11.3646 1.89579 11.5889 2.07984 11.751C2.26389 11.9131 2.50112 12.0017 2.74628 11.9999H7.23674C7.4819 12.0017 7.71913 11.9131 7.90318 11.751C8.08723 11.5889 8.20521 11.3646 8.23462 11.121L8.98304 5.12858C9.00159 4.98695 8.98958 4.84299 8.94781 4.70641C8.90605 4.56982 8.83549 4.44379 8.74091 4.33682C8.64633 4.22985 8.52993 4.14444 8.39955 4.08633C8.26918 4.02823 8.12786 3.99879 7.98515 4Z"
    />
  </SvgIcon>
);

export default Delete;
