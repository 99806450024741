import * as React from 'react';
import * as _ from 'lodash';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Dialog,
  Divider,
  IconButton,
  MenuItemProps,
  Popover,
  Tooltip as MuiTooltip,
  Popper,
} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import Tooltip from '@ep/insight-ui/elements/tooltip';
import HeaderList from '../header-list/header-list';
import Typography from '../../text-style/Typography';
import clsx from 'clsx';
/**
 * ff.add_css_identifier_to_new_release_components:start
 */
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { get } from 'lodash';
import { ACTION_CATEGORY_SEPARATOR } from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import Dropdown from '../../dropdown-menu/dropdown';
import Menu, { MenuProps } from '@material-ui/core/Menu';

/**
 * ff.add_css_identifier_to_new_release_components:end
 */

/** Style */
const useStyles = makeStyles(() => ({
  groupMenu: {
    position: 'relative',
    '&::after': {
      left: '50%',
      width: '100%',
      bottom: '0px',
      height: '1px',
      content: '""',
      transform: 'translateX(-50%)',
      position: 'absolute',
      backgroundColor: colors.border.divider,
    },
  },
  iconNote: {
    display: 'flex',
    alignItems: 'center',
    background: 'transparent',
    marginRight: '5px',
  },
  header: {
    height: '32px',
    minHeight: '32px',
    marginBottom: '6px',
    paddingLeft: '16px',
  },
  // list: {
  //   '& div:last-child li:last-child': {
  //     marginBottom: '0',
  //   },
  //   '& div:last-child li:first-child': {
  //     marginTop: '4px',
  //   },
  // },
  secondary: {
    flexWrap: 'wrap',
    gap: '0 !important',
  },
  listItemIconRoot: {
    minWidth: 'auto',
    marginTop: '-2px',
    marginLeft: '4px',
  },
  disable: {
    pointerEvents: 'none',
  },
  textColor: {
    '.Mui-disabled &': {
      color: '#C2C7CB !important',
    },
    '& span': {
      marginRight: '16px',
    },
  },
  paperDialog: {
    width: '100%',
    padding: '1em',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  tooltip: {
    background: '#253746',
    fontSize: '11px',
    lineHeight: '14px',
    maxWidth: '350px',
    zIndex: 9999999,
  },
}));

const useMenuItemStyles = makeStyles(() => ({
  subDropdownMenu: {
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    background: '#fff',
    borderRadius: '4px',
    padding: '8px',
  },
}));

interface StyledMenuItemProps extends MenuItemProps {
  gap: string;
  notIsItem?: boolean;
  secondary?: boolean;
  disable?: boolean;
  active?: boolean;
}

export const StyledMenuItem = withStyles(() => ({
  root: {
    height: (props: StyledMenuItemProps) => (props.secondary ? 'auto' : '32px'),
    minHeight: '32px',
    borderRadius: '4px',
    padding: '6px 8px',
    gap: (props: StyledMenuItemProps) => props.gap,
    '&:hover': {
      backgroundColor: (props: StyledMenuItemProps) =>
        props.notIsItem ? 'transparent !important' : props.active ? '#EBF6FF' : '#E4E7E9',
    },
    paddingLeft: (props: StyledMenuItemProps) => (props.notIsItem ? '0px' : ''),
    marginBottom: '0px',
    color: (props: StyledMenuItemProps) => (props.disable ? colors.text.disabled : colors.text.default),
    '&.Mui-disabled': {
      opacity: 1,
    },
    '& .eip1-MuiTypography-body1': {
      fontWeight: '400',
    },
    background: (props: StyledMenuItemProps) => (props.active ? '#EBF6FF' : null),
  },
}))(({ notIsItem, ...rest }: StyledMenuItemProps) => <MenuItem {...rest} />);

const EnhancedStyleMenuItem = (props) => {
  const classes = useMenuItemStyles();
  const [isHovering, setIsHovering] = React.useState(false);

  const subActions = get(props, ['item', 'subActions'], []) || [];
  const subMenu = get(props, ['item', 'subMenu'], []) || [];
  const [subMenuAnchorEl, setSubMenuAnchorEl] = React.useState(null);
  const anchorEl = React.useRef(null);
  const timeoutCloseEl = React.useRef(null);

  const popper = React.useMemo(() => {
    return subMenuAnchorEl && subMenu[0]?.length > 0 ? (
      <Popper
        open={Boolean(subMenuAnchorEl)}
        anchorEl={subMenuAnchorEl}
        placement={'left'}
        style={{ zIndex: 1500 }}
        transition
        modifiers={{
          offset: {
            enabled: true,
            offset: 8 + (subMenu[0]?.length - 1) * 16 + ', 28',
          },
        }}
        onMouseEnter={() => {
          clearTimeout(timeoutCloseEl.current);
        }}
      >
        <MonitorContainer mId="sub-dropdown-menu" mLabel="sub-dropdown-menu" className={classes.subDropdownMenu}>
          <MenuList listMenu={subMenu} />
        </MonitorContainer>
      </Popper>
    ) : null;
  }, [subMenuAnchorEl, subMenu]);

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseOver={() => {
        if (!isHovering) setIsHovering(true);
        clearTimeout(timeoutCloseEl.current);
        setSubMenuAnchorEl(anchorEl.current);
      }}
      onMouseLeave={() => {
        if (isHovering) setIsHovering(false);
        timeoutCloseEl.current = setTimeout(() => {
          setSubMenuAnchorEl(null);
        }, 250);
      }}
      ref={anchorEl}
    >
      <StyledMenuItem {...props}>{props.children}</StyledMenuItem>
      {isHovering && subActions.length > 0 ? (
        <Box
          sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 0 }}
          onClick={(e) => e.stopPropagation()}
        >
          <Dropdown
            disabledClickContentClose={false}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            sizeButton={`32px`}
            sizeIcon={'14px'}
            handleOnClosed={() => setIsHovering(false)}
          >
            <MenuList listMenu={[subActions]} onClose={() => setIsHovering(false)} closeAfterClick />
          </Dropdown>
        </Box>
      ) : null}
      {popper}
    </Box>
  );
};

interface DropdownProps {
  listMenu: DropdownMenuDataType[][];
  className?: string;
  title?: string;
  iconSize?: string;
  closeMenuCallBack?: () => void;
  secondary?: boolean;
  gap?: string;
  children?: React.ReactNode;
  classNameCondition?: string;
  blockEid?: string;
  customArg?: any;
  onClose?: any;
  closeAfterClick?: boolean;
}

type PopupProps = {
  maxHeight?: string;
  maxWidth?: string;
  overflow?: string;
};

export type DropdownMenuDataType = {
  title: string;
  icon?: IconType;
  onClick: () => void;
  note?: string;
  editable?: boolean;
  colorTitle?: string;
  iconSize?: string;
  colorStartIcon?: string;
  select?: boolean;
  disable?: boolean;
  name?: string;
  isPopupMenu?: boolean;
  popupComponent?: any;
  popupProps?: PopupProps;
  maxWidthPopup?: string;
  subText?: string;
  className?: string;
  tooltip?: string;
};

const MenuList: React.FC<DropdownProps> = ({
  listMenu,
  title,
  className,
  iconSize = '14px',
  closeMenuCallBack,
  secondary,
  gap = '10px',
  children,
  classNameCondition,
  blockEid,
  customArg,
  onClose,
  closeAfterClick,
}: DropdownProps) => {
  const classes = useStyles();
  const hashGapIcon = {
    hashtag: '6px',
    filter: '8px',
    duplicatePlus: '8px',
  };
  const hashMarginIcon = {
    hashtag: '-2px 0 0 1px',
    filter: '0 0 0 2px',
  };

  const origin = React.useRef<HTMLDivElement>();
  const [indexRender, setIndexRender] = React.useState<number[]>([null, null]);
  const [open, setOpen] = React.useState(null);
  const [popupProps, setPopupProps] = React.useState<PopupProps>({});
  const [maxWidthPopup, setMaxWidthPopup] = React.useState('');
  const [overflowPopup, setOverflowPopup] = React.useState('');
  const Component = React.useMemo(
    () => _.get(listMenu, [indexRender[0], indexRender[1], 'popupComponent'], null),
    [indexRender, listMenu],
  );

  const handleClickMenuItem = (menu, index: number[]) => {
    if (menu.popupComponent) {
      setIndexRender(index);
      setOpen(true);

      if (menu.popupProps) setPopupProps(menu.popupProps);
      // menu.popupProps.maxWidth ? setMaxWidthPopup(menu.popupProps.maxWidth) : setMaxWidthPopup('');
      // menu.popupProps.overflow ? setOverflowPopup(menu.popupProps.overflow) : setOverflowPopup('');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  let backboneContext, titleTable, titleTableClass;
  if (ff.add_css_identifier_to_new_release_components) {
    backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
    titleTable = backboneContext.getConfig('title');
    titleTableClass = titleTable.length ? titleTable.replace(/\s/g, '_').toLowerCase() : '';
  }

  return (
    <MonitorContainer component="div" mId="menu-list" mLabel="menu-list" ref={origin} className={`${className}`}>
      {title && <HeaderList title={title} />}
      {children}
      <div className={classes.list}>
        {listMenu &&
          listMenu.map((group, indexGroup) => (
            <div key={indexGroup} className={indexGroup !== listMenu.length - 1 ? classes.groupMenu : ''}>
              {group.map((menu, index) =>
                get(menu, ['customProps', 'category']) === ACTION_CATEGORY_SEPARATOR ? (
                  <Box key={index}>
                    {index > 0 && <Divider style={{ margin: '4px -8px 4px -8px' }} />}
                    <Typography variant="h6">{menu.title} </Typography>
                  </Box>
                ) : (
                  <Box sx={{ position: 'relative' }} key={index}>
                    <EnhancedStyleMenuItem
                      gap={hashGapIcon[menu.icon] ? hashGapIcon[menu.icon] : gap}
                      secondary={secondary}
                      disabled={menu.disable}
                      className={`${
                        ff.add_css_identifier_to_new_release_components
                          ? clsx({
                              [classes.secondary]: secondary,
                              [classes.disable]: menu.disable,
                              [`eip_elevation_table_${titleTableClass}`]: titleTableClass,
                            })
                          : clsx({
                              [classes.secondary]: secondary,
                              [classes.disable]: menu.disable,
                            })
                      } ${ff.function_crud_etable_view ? menu?.className || '' : ''}`}
                      classes={{
                        root: classNameCondition,
                      }}
                      onClick={(event) => {
                        if (menu.onClickWithEvent) {
                          menu.onClickWithEvent(event);
                          if (menu.closeAfterClick) {
                            onClose();
                          }

                          return;
                        }
                        menu.isPopupMenu && handleClickMenuItem(menu, [indexGroup, index]);
                        if (ff.dnd_sidebar_menu && customArg) {
                          menu.onClick(customArg);
                        } else if (ff.clone_page) {
                          menu.onClick(blockEid);
                        } else {
                          menu.onClick();
                        }
                        if (ff.dnd_sidebar_menu) {
                          if (closeAfterClick) onClose();
                        }
                        if (closeMenuCallBack) closeMenuCallBack();
                      }}
                      active={menu.active}
                      item={menu}
                    >
                      {menu.icon && (
                        <ListItemIcon
                          className={clsx(classes.listItemIconRoot)}
                          style={{ margin: hashMarginIcon[menu.icon] ? hashMarginIcon[menu.icon] : '' }}
                        >
                          <Icon
                            type={menu.icon}
                            size={menu.iconSize || iconSize}
                            colorIcon={
                              menu.disable
                                ? colors.text.disabled
                                : ff.function_crud_etable_view
                                ? menu.colorStartIcon
                                : 'colors.text.default'
                            }
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        className={classes.textColor}
                        primary={menu.title}
                        style={{ color: ff.function_crud_etable_view ? menu?.colorTitle || '#253746' : '#253746' }}
                      />

                      {menu.note && !secondary ? (
                        <Tooltip mode="long" content={menu.note} title="" width="140px">
                          <div className={classes.iconNote}>
                            <Icon type="note" size="12px" aria-label="delete" colorIcon={colors.icon.highlight} />
                          </div>
                        </Tooltip>
                      ) : menu.note && secondary ? (
                        <Typography variant="body5" style={{ width: '100%' }}>
                          {menu.note}
                        </Typography>
                      ) : (
                        false
                      )}
                    </EnhancedStyleMenuItem>
                    {menu.tooltip ? (
                      <Box
                        sx={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: 2 }}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <MuiTooltip
                          title={menu.tooltip}
                          classes={{ tooltip: classes.tooltip }}
                          placement={'bottom-end'}
                        >
                          <div className={classes.iconNote}>
                            <Icon
                              type="note"
                              size="12px"
                              aria-label="delete"
                              colorIcon={menu.disable ? '#C2C7CB' : colors.icon.highlight}
                            />
                          </div>
                        </MuiTooltip>
                      </Box>
                    ) : null}
                  </Box>
                ),
              )}
            </div>
          ))}
      </div>
      {Component && (
        <Dialog
          PaperProps={{
            className: clsx(classes.paperDialog, 'dialog'),
            style: { maxWidth: popupProps.maxWidth, overflow: popupProps.overflow, maxHeight: popupProps.maxHeight },
          }}
          open={open}
          onClose={handleClose}
        >
          {/* <Box display={'flex'} justifyContent={'flex-end'}>
            <IconButton style={{ padding: '10px' }}>
              <Icon type="close" />
            </IconButton>
          </Box> */}
          <Component onClose={handleClose} />
        </Dialog>
      )}
    </MonitorContainer>
  );
};

export default MenuList;
