import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueSingleBudgetFormat } from '@ep/insight-ui/elements/table/format/budgets/single-budget-format';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../../group-button-submit';
import BudgetRowForm from '../budget-row-form';
import { TypeValueBudget } from '../budget-row-form/budget-row-form';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  formGroup: {
    marginTop: '16px',
  },
}));

export const options: OptionSelectType[] = [
  {
    label: 'Total budget',
    value: 'total',
  },
  {
    label: 'Daily budget',
    value: 'daily',
  },
];

const operators: OptionSelectType[] = [
  {
    label: 'Exact value',
    value: 'exactValue',
  },
  {
    label: 'No limit',
    value: 'noLimit',
  },
];
type Props = {
  data: {
    value: IValueSingleBudgetFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const BudgetSingleEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl, ...rest }: Props) => {
  const classes = useStyles();
  const disabledModelNoLimit = _.get(data, ['value', 'disabledModelNoLimit'], false);
  const defaultValue: TypeValueBudget = React.useMemo(() => {
    const label = _.get(data, ['value', 'label'], '');
    const value = _.get(data, ['value', 'value'], '');
    const currency = _.get(data, ['value', 'currency'], '');

    return {
      field: label,
      operator: value ? operators[0].value : operators[1].value,
      budget: String(value),
      currency: currency,
    };
  }, [data.value, anchorEl]);
  const [budget, setBudget] = React.useState<TypeValueBudget>(defaultValue);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    setBudget(defaultValue);
  }, [anchorEl]);

  const handleClosed = () => setAnchorEl(null);

  const handleSubmit = async () => {
    const valueSubmit: IValueSingleBudgetFormat = {
      label: budget.field,
      currency: data.value.currency ?? '',
      value: +budget.budget,
    };
    setIsSubmitting(true);
    const response: any = await onSubmit({ value: valueSubmit, row: data.node.data });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      {budget && (
        <BudgetRowForm
          disabledListBox
          options={options}
          operators={operators}
          value={defaultValue}
          onChange={setBudget}
          disabledModelNoLimit={disabledModelNoLimit}
        />
      )}

      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};
export default BudgetSingleEditor;
