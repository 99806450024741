export const InformationMetric = [
  {
    title: 'Impressions',
    definition: 'The number of times your ad is shown on a channel’s ads results page to logged-in users.',
  },
  {
    title: 'Impression',
    definition: 'The number of times your ad is shown.',
  },
  {
    title: 'Clicks',
    definition: 'The number of times your ad is clicked into from a channel’s ads results page by logged-in users.',
  },
  {
    title: 'Click',
    definition: 'The number of times your ad is clicked.',
  },
  {
    title: 'Unique visitor',
    definition: 'The number of unique visitors to all the product pages and shop pages.',
  },
  {
    title: 'Visitor',
    definition: 'Customers who are aware of your brands, stores, and items',
  },
  {
    title: 'ATC',
    definition: 'The total number of units added to the cart over the selected time period.',
  },
  {
    title: 'Wishlist',
    definition:
      'Number of times the Product is added to a wishlist during the selected time period, regardless of SKU variation or if it is removed from the wishlist after that.',
  },
  {
    title: 'ATC visitor',
    definition:
      'Number of Visitors that have added at least one product to a shopping cart. If the same visitor adds several units, it reflects one only.',
  },
  {
    title: 'Product view',
    definition: 'The number of visits to the product page.',
  },
  {
    title: 'Unique buyer',
    definition: 'The number of unique buyers from orders placed',
  },
  {
    title: 'Current stock',
    definition: 'The amount of stock available',
  },
  {
    title: 'Visitor value',
    definition: 'GMV divided by unique visitor',
  },
  {
    title: 'Shop page view',
    definition: 'The number of visits to the shop page.',
  },
  {
    title: 'Conversion rate',
    definition: 'Order divided by product view',
  },
  {
    title: 'Percent in stock',
    definition: 'Number of purchasable SKUs over the number of active SKUs',
  },
  {
    title: 'Wishlist visitor',
    definition:
      'Number of Visitors that have added at least one of your Products to their wishlist, regardless of the SKU variation or if it is removed from the wishlist after that. If the same visitor adds several units to wishlists, it reflects one only.',
  },
  {
    title: 'Revenue per buyer',
    definition: 'GMV divided by unique buyer.',
  },
  {
    title: 'Average basket size',
    definition: 'GMV divided by orders.',
  },
  {
    title: 'Percent selling SKU',
    definition: 'Number of selling SKUs over the number of active SKUs',
  },
  {
    title: 'Average selling price',
    definition: 'GMV divided by units sold.',
  },
  {
    title: 'Percent in stock (weighted)',
    definition:
      'Page views of the active SKU multiplied by the stock of active SKU, over the total number of page views of active SKUs',
  },
  {
    title: 'Unit sold',
    definition: 'The number of units associated with the placed orders.',
  },
  {
    title: 'Ads GMV',
    definition:
      'The revenue generated by buyers from the advertised product as well as other products from your shop. All sales are attributed to the last clicked ad.',
  },
  {
    title: 'GMV',
    definition: 'The value of orders over the selected time period.',
  },
  {
    title: 'GMV target',
    definition: 'The target value of orders over the selected time period.',
  },
  {
    title: 'Direct Ads GMV',
    definition: 'The revenue generated by buyers from it’s advertised product.',
  },
  {
    title: 'Total GMV',
    definition: 'The total revenue of a store including ads and organic revenue.',
  },
  {
    title: 'Ads Items Sold',
    definition:
      'The total number of units sold generated by buyers from the advertised product as well as other products from your shop. All sales are attributed to the last clicked ad.',
  },
  {
    title: 'Direct Ads Items Sold',
    definition: 'The total number of units sold generated by buyers from it’s advertised product.',
  },
  {
    title: 'Total Items Sold',
    definition: 'The total number of units sold of a store including ad and organic items sold.',
  },
  {
    title: 'Cost',
    definition: 'The total amount spent on ads in channel’s ads results page, during the time when ads are running.',
  },
  {
    title: 'ROAS',
    definition:
      'Return on Ads Spend (ROAS) measures the revenue generated by the ad from the sales relative to the amount spent on your ad. A higher ROAS is better.ROAS = Ads GMV ÷ Cost',
  },
  {
    title: 'Direct ROAS',
    definition:
      'Direct Return on Ads Spend (Direct ROAS) measures the direct revenue generated by the ad from the sales relative to the amount spent on your ad. Direct ROAS = Direct Ads GMV ÷ Cost ',
  },
  {
    title: 'CTR',
    definition:
      'Clickthrough rate (CTR) measures how often users who see your ad end up clicking it. CTR = Clicks ÷ Impressions.',
  },
  {
    title: 'CR',
    definition:
      'Conversion rate (CR) measures how often users who click your ad end up purchasing it as well as other products from your shop. CR = Ads Items sold ÷ Clicks',
  },
  {
    title: 'Direct CR',
    definition:
      'Direct Conversion rate (Direct CR) measures how often users who click your ad end up purchasing it. Direct CR = Direct Ads Items sold ÷ Clicks',
  },
  {
    title: 'CPC',
    definition: 'Cost-per-click (CPC) is the actual price you pay for each click on your ads. CPC = Cost ÷ Clicks',
  },
  {
    title: 'CPM',
    definition:
      'CPM (i.e. cost per mile) is a payment for one thousand advertisement impressions, CPM = (Ads spend *1000)/Impression',
  },
  {
    title: 'CPI',
    definition:
      'Cost-per-item (CPI) is the actual price you pay for each unit sold when users bought your promoted product as well as other products from your shop. CPI = Cost ÷ Ads Items sold',
  },
  {
    title: 'Direct CPI',
    definition:
      'Direct Cost-per-item (Direct CPI)  is the actual price you pay for each unit sold when users bought your promoted product. Direct CPI = Cost ÷ Direct Ads Items sold',
  },
  {
    title: 'CIR',
    definition:
      'Cost-in-revenue (CIR) measures the cost of advertising relative to the revenue generated by the ad from the sales of your shop’s products. A lower CIR is better. CIR = Cost ÷ Ads GMV',
  },
  {
    title: 'Direct CIR',
    definition:
      'Direct Cost-in-revenue (Direct CIR) measures the cost of advertising relative to the direct revenue generated by the ad from the sales of your shop’s products. A lower CIR is better. Direct CIR = Cost ÷ Direct Ads GMV',
  },
  {
    title: '% Ads GMV Contribution',
    definition:
      '% Ads GMV Contribution measure the contribution of the revenue generated by the ad to the total revenue of a store. % Ads GMV Contribution = Ads GMV ÷ Total GMV',
  },
  {
    title: '% Direct Ads GMV Contribution',
    definition:
      '% Direct Ads GMV Contribution measure the contribution of the direct revenue generated by the ad to the total revenue of a store. % Direct Ads GMV Contribution = Direct Ads GMV ÷ Total GMV',
  },
  {
    title: '% Ads Items Sold Contribution',
    definition:
      '% Ads Items Sold Contribution measure the contribution of the units sold generated by the ad to the total units sold of a store. % Ads Items Sold Contribution = Ads Items Sold ÷ Total Items Sold',
  },
  {
    title: '% Direct Ads Items Sold Contribution',
    definition:
      '% Direct Items Sold GMV Contribution measure the contribution of the direct units sold generated by the ad to the total units sold of a store. % Direct Ads Items Sold Contribution = Direct Ads Items Sold ÷ Total Items Sold',
  },
  {
    title: 'Ads Order',
    definition:
      'The total number of orders generated by buyers from the advertised product as well as other products from your shop. All sales are attributed to the last clicked ad.',
  },
  {
    title: 'Order',
    definition: 'The number of orders placed, including unpaid orders.',
  },
  {
    title: 'Direct Ads Order',
    definition: 'The total number of orders generated by buyers from it’s advertised product.',
  },
  {
    title: 'Total Order',
    definition: 'The total number of orders of a store including ads and organic orders.',
  },
  {
    title: '% Ads Orders Contribution',
    definition:
      '% Ads Orders Contribution measure the contribution of the orders generated by the ad to the total orders of a store. % Ads Orders Contribution = Ads Orders ÷ Total Orders',
  },
  {
    title: '% Direct Ads Orders Contribution',
    definition:
      '% Direct Orders GMV Contribution measure the contribution of the direct orders generated by the ad to the total orders of a store. % Direct Ads Orders Contribution = Direct Ads Orders ÷ Total Orders',
  },
  {
    title: 'Direct Ads Conversions',
    definition: 'The total number of unique products generated by buyers from it’s advertised product.',
  },
  {
    title: 'Ads Conversions',
    definition:
      'The total number of unique products generated by buyers from the advertised product as well as other products from your shop. All sales are attributed to the last clicked ad.',
  },
];
