/* eslint-disable react/prop-types */
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from '../wrapper-format';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { enhanceCellAction } from '../../table-helper';
import Icon from '@ep/insight-ui/icons/Icon';
import moment from 'moment';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'flex-end',
    lineHeight: '24px',
    textAlign: 'right',
    width: '100%',
  },
  value: {
    lineHeight: '20px',
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
    marginLeft: '-10px',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  height100: {
    height: '100%',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  subtextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));
export interface IValueBudgetFormat {
  currency: string;
  totalBudget: number | null | undefined;
  dailyBudget: number | null | undefined;
}

const SelectionBudgetFormat = (props: IPropsFormat<IValueBudgetFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const totalBudget = _.get(props, ['value', 'totalBudget'], '');
  const dailyBudget = _.get(props, ['value', 'dailyBudget'], '');
  const currency = _.get(props, ['value', 'currency'], '');
  const typeTable = _.get(props, 'typeTable', '');
  const edot = _.get(props, ['value', 'edot'], []);
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const subtextValue = _.get(props, ['value', 'subtext'], '');
  const getValue = () => {
    if (totalBudget && parseFloat(totalBudget) > 0) {
      return `${formatCurrencyNumber(totalBudget)} ${currency} • Total`;
    }
    if (dailyBudget && parseFloat(dailyBudget) > 0) {
      return `${formatCurrencyNumber(dailyBudget)} ${currency} • Daily`;
    }

    return 'no limit';
  };

  const backbone = React.useContext(TableBackboneContext);

  const dailyBudgetField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'dailyBudget'], '');
  const valueSyncStatus = _.get(props, ['data', 'data_status', dailyBudgetField, 'status'], null);
  const valueSyncUpdatedAt = _.get(props, ['data', 'data_status', dailyBudgetField, 'updated_at'], null);
  const valueSyncErrorMessage = _.get(props, ['data', 'data_status', dailyBudgetField, 'error_message'], '');

  const subtext = React.useMemo(() => {
    if (valueSyncStatus == 2) {
      return (
        <Box className={classes.subtextContainer}>
          <Tooltip title={valueSyncErrorMessage} classes={{ tooltip: classes.tooltip }} placement="bottom">
            <Box className={classes.syncStatusText}>
              <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
              <span>
                Sync failed at:{' '}
                {moment(valueSyncUpdatedAt).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Box>
          </Tooltip>
          {subtextValue ? (
            <>
              <span>|</span>
              <span>{subtextValue}</span>
            </>
          ) : null}
        </Box>
      );
    }

    return <span>{subtextValue}</span>;
  }, [props, valueSyncStatus, valueSyncUpdatedAt]);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(cellAction, edot, props.field, backbone)}
      node={node}
      value={value}
    >
      <Grid container alignItems={'center'} className={clsx(classes.format, classes.height100)}>
        <Grid
          container
          alignItems={'flex-end'}
          direction={'column'}
          wrap={'nowrap'}
          className={clsx(classes.styleText, classes.height100)}
        >
          <Grid item xs={12} className={classes.height100}>
            <span className={classes.hashtag}>{hashtag}</span>
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            {getValue()}
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            <Box className={classes.subtext}>{subtext}</Box>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default SelectionBudgetFormat;
