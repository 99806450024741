import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { formatCurrency } from '@ep/insight-ui/lib/number';
// import { IRowEDashboard, IDateValue } from '.././';
import { dataType, IData, IOption } from '@ep/insight-ui/chartlib/type';
import LineChartResponsive from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize/line-chart';
import { IRowEDashboard } from '../mocks/line-new';
import { funcConfigs } from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize/hooks';
import ChartState from '@ep/insight-ui/chartlib/chart-loading-state';
import { IStatusChartBB } from '../dashboard-container';
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { colorsChartLegend } from '@ep/insight-ui/chartlib/helper';

type IStackedChart = {
  data: IRowEDashboard;
  statusChart: IStatusChartBB;
};

const StackedChartEDashboard = ({ data, statusChart }: IStackedChart) => {
  const [dataList, setDataList] = React.useState<IData>();
  const [optionList, setOptionList] = React.useState<any>();
  const { externalTooltipHandler } = funcConfigs({});

  let thisMonthSerries: Array<{ date: string; value: number }>;
  let lastMonthSerries: Array<{ date: string; value: number }>;
  let rows;
  if (ff.revive_all_charts) {
    rows = _.get(data, 'rows', []);
    thisMonthSerries = [];
    lastMonthSerries = [];
    rows.forEach((item) => {
      thisMonthSerries = item.this_time_value_serries;
      lastMonthSerries = item.last_time_value_serries;
    });
  } else {
    thisMonthSerries = _.get(data, 'this_time_value_serries', []);
    lastMonthSerries = _.get(data, 'last_time_value_serries', []);
  }

  const stateChart = React.useMemo((): IStateChartValue => {
    switch (statusChart) {
      case 'success': {
        if (thisMonthSerries.length === 0 && lastMonthSerries.length === 0) return 'empty';
        return 'success';
      }
      case 'error':
        return 'error';
      default:
        return 'loading';
    }
  }, [statusChart, thisMonthSerries.length, lastMonthSerries.length]);

  React.useEffect(() => {
    const thisMonthData = [];
    const lastMonthData = lastMonthSerries.map((item) => item.value);
    const labels = thisMonthSerries.map((item) => {
      return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(item.date));
    });
    const tempDashLine = [];
    thisMonthSerries.forEach((item) => {
      if (moment(item.date).isBefore(moment().format())) {
        thisMonthData.push(item.value);
      } else {
        tempDashLine.push(item.value);
      }
    });
    setDataList({
      labels: labels,
      datasets: [
        {
          label: 'Storefront fee',
          data: thisMonthData,
          borderColor: colorsChartLegend[0].stroke,
          pointBackgroundColor: colorsChartLegend[0].stroke,
          pointBorderColor: colorsChartLegend[0].pointBorderColor,
          fill: false,
          pointRadius: 0,
          lineTension: 0.4,
          active: 'active',
        },
        {
          label: 'User fee',
          data: thisMonthData.map((data, index) => data + lastMonthData[index]),
          borderColor: colorsChartLegend[1].stroke,
          pointBackgroundColor: colorsChartLegend[1].stroke,
          pointBorderColor: colorsChartLegend[1].pointBorderColor,
          fill: false,
          pointRadius: 0,
          lineTension: 0.4,
          active: 'active',
        },
        {
          label: 'Take rate',
          data: lastMonthData,
          borderColor: colorsChartLegend[2].stroke,
          pointBackgroundColor: colorsChartLegend[2].stroke,
          pointBorderColor: colorsChartLegend[2].pointBorderColor,
          fill: false,
          pointRadius: 0,
          lineTension: 0.4,
          active: 'active',
        },
      ],
    });

    //Display option chart
    setOptionList({
      responsive: true,
      maintainAspectRatio: false,
      datasets: {
        bar: {
          barThickness: 47,
        },
      },
      scales: {
        yAxes: {
          stacked: true,
          display: true,
          suggestedMin: 0,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
            callback: (value) => {
              return value;
            },
          },
        },
        xAxes: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            labels: labels,
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          enabled: false,
          mode: 'index',
          callbacks: {
            //Custom show currency chart
            label: (context) => {
              const label = _.get(context, ['dataset', 'label'], '');
              const textKey = _.get(context, ['dataset', 'textKey'], '');
              const colorTooltip = _.get(context, ['dataset', 'colorTooltip'], '');
              const raw = _.get(context, ['raw'], 0);
              return {
                label,
                textKey,
                value: context.parsed.y,
                percent: `(${100 / 10}%)`,
                colorTooltip,
                specialKey: raw !== 0,
              };
            },
          },
          external: externalTooltipHandler,
        },
      },
      elements: {
        point: {
          pointStyle: 'circle',
          pointRadius: 0,
          hoverBorderWidth: 16,
          radius: 100,
          pointHoverRadius: 8,
        },
      },
    });
  }, [data]);
  return (
    <ChartState stateChart={stateChart}>
      <LineChartResponsive stateChart={stateChart} keyChart={'billing'} dataList={dataList} optionList={optionList} />
    </ChartState>
  );
};

export default StackedChartEDashboard;
