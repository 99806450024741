import { LegendPositions, TreeChartOptions } from '@carbon/charts/interfaces';
import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import * as _ from 'lodash';
import * as React from 'react';
import { legendLine, PopLegend, tableTooltip } from '../helper';

const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: 999,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();

  const customHTML =
    ({ percent = {}, currency = '', labelList = {}, headers = [] }) =>
    (data, htmlString) => {
      const tableRoot = document.createElement('table');
      const tableHead = document.createElement('thead');
      const tableBody = document.createElement('tbody');

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';

        const header = headers.find((h) => h.label === data[0].label);

        let headerText = (data[0].label || data[1].value.label || '').split('\n')[0];
        headerText += header && header.valueLabel ? ' (' + header?.valueLabel + ')' : '';

        const text = document.createTextNode(headerText);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      })();

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        const tdHTML = `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${data[0].color};"></span>
        <div class="content">
          <div class="label">${_.startCase(_.lowerCase((data[1].value.label || '').split('\n')[0]))}</div>
          <div class="value">${
            labelList[data[1].value?.value] || `${formatCurrencyNumber(data[1].value?.value, currency)} ${currency}`
          }</div>
        </div>
      </td>
      `;
        tr.innerHTML = tdHTML;
        tableBody.appendChild(tr);
      })();

      tableRoot.className = `${classes.tableTooltip}`;
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      return tableRoot.outerHTML;
    };

  const defaultOptions: TreeChartOptions = {
    resizable: true,
    legend: {
      position: LegendPositions.BOTTOM,
      enabled: true,
    },
    tooltip: {
      customHTML: customHTML({}),
      truncation: {
        numCharacter: 256,
      },
    },
    // Hide the toolbar for new version of library
    toolbar: {
      enabled: false,
    },
  };

  return {
    customHTML,
    defaultOptions,
  };
};

export const useTableVisible = (ref) => {
  const [isTableShow, setIsTableShow] = React.useState(false);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        ref.current &&
        !ref.current.contains(event.target) &&
        !event.target.classList.contains('eip1-MuiTypography-root') &&
        !event.target.classList.contains('eip1-MuiPaper-root') &&
        !event.target.classList.contains('eip1-MuiMenu-paper') &&
        !event.target.classList.contains('eip1-MuiButton-endIcon') &&
        !event.target.classList.contains('eip1-MuiButtonBase-root') &&
        !event.target.classList.contains('eip1-MuiSvgIcon-root') &&
        (!event.target.hasAttribute('aria-hidden') ||
          (event.target.hasAttribute('aria-hidden') && event.target.getAttribute('aria-hidden') === true))
      ) {
        setIsTableShow(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  return {
    ref,
    isTableShow,
    setIsTableShow,
  };
};
