import * as React from 'react';

import { Grid, Box, makeStyles, Button } from '@material-ui/core';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import HeaderList from '@ep/insight-ui/elements/table/header-list';
import ButtonAction from '@ep/insight-ui/elements/button/button-action';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';

import FormatItem from './format-item';
import { useConditionFormat } from './hooks/use-condition-format';

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    minWidth: '568px',
    padding: '0 8px',
  },
  header: {
    paddingLeft: 0,
  },
  buttonAddBottomWrap: {
    marginTop: '8px',
  },
  buttonAddBottom: {
    display: 'flex',
    alignItems: ' center',
    padding: '2px 8px',
    marginLeft: '-5px',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
      lineHeight: '20px',
      fontSize: '14px',
    },
    '& .eip1-MuiButton-startIcon': {
      marginTop: '-3px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  formGroupSubmit: {
    marginTop: '16px',
    '& .eip1-MuiButton-text': {
      padding: '6px 8px',
    },
  },
}));

const ConditionFormat = (props) => {
  const classes = useStyles();

  const backbone = React.useContext(TableBackboneContext);

  const {
    title,
    formatItems,
    setFormatItems,
    handleAddFormat,
    handleClose,
    handleSubmit,
    handleUpdateFormatItem,
    handleDeleteFormatItem,
    handleDuplicateFormatItem,
    previewText,
    previewWidth,
  } = useConditionFormat({
    props,
    backbone,
  });

  return (
    <Box className={classes.container}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      {formatItems.map((item, index) => {
        return (
          <FormatItem
            key={item.fakeId}
            handleUpdateFormatItem={handleUpdateFormatItem}
            handleDeleteFormatItem={handleDeleteFormatItem}
            handleDuplicateFormatItem={handleDuplicateFormatItem}
            item={item}
            index={index}
            items={formatItems}
            setItems={setFormatItems}
            previewText={previewText}
            previewWidth={previewWidth}
          />
        );
      })}
      <Grid item className={classes.buttonAddBottomWrap}>
        <ButtonAction
          icon="plus"
          label="Add more formats"
          size={'medium'}
          className={classes.buttonAddBottom}
          variant={'text'}
          onClick={handleAddFormat}
        />
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
        <Grid item>
          <Button onClick={handleClose}>Cancel</Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConditionFormat;
