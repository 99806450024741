import { get } from 'lodash';
import * as React from 'react';
import { enhancedETableConfig2 } from '../../../../migration';
import { NodeEditContext } from '@eip/next/lib/main';
import { DEFAULT_CONFIG, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';
import { Provider } from 'jotai';
import { IFilterItem } from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog/filter-form-group';
import FilterForm from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog/filter-form-group';
import { Typography } from '@material-ui/core';
import { filterOperators, getSelectedOperators } from '@ep/insight-ui/elements/table/table-helper';
import { FieldSelectedValue } from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
const fakeFunction = () => undefined;

interface FilterInputProps {
  initialValue?: string;
  onChange: (data: any) => void;
  configuration?: any;
}

const FilterInput = ({ onChange, initialValue, configuration, ...rest }: FilterInputProps) => {
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const [filterModel, setFilterModel] = React.useState(() => {
    if (!initialValue) return [];

    return safeJsonParse(initialValue, []);
  });

  const fieldConfiguration = get(configuration, ['field_configuration'], []).reduce((carry, item) => {
    return {
      ...carry,
      [item.key]: item.value,
    };
  }, {});

  const { eTable_config: eTableConfig, eTable_selected: eTableSelected } = fieldConfiguration;

  React.useEffect(() => {
    if (onChange) {
      onChange({
        target: {
          value: JSON.stringify(filterModel),
        },
      });
    }
  }, [filterModel]);

  const { config, linkedObjects } =
    eTableSelected && eTableConfig
      ? enhancedETableConfig2({
          customConfig: eTableConfig,
          systemConfig: {},
          nodeEditContext,
          blockEid: new Date().getTime(),
          setOpenCodeEditor: fakeFunction,
          lastUpdated: new Date().getTime(),
          getCustomCellActions: fakeFunction,
          onToastMultiple: fakeFunction,
        })
      : { config: { configuration: DEFAULT_CONFIG } };

  const backbone = useTableBackbone(config, fakeFunction);

  const getOptionFilterAvailable = async (field: string, filterData?: IFilterItem[]) => {
    return backbone.getFilterOptionValue(field, filterData);
  };

  const filterOptions: Array<FieldSelectedValue> = backbone.getAvailableFilterColumns().map((item) => {
    return {
      label: item.name,
      value: item.id,
      dataType: item.dataType ?? 'string',
      selectedOperators: backbone.addon('selection.filter', () => getSelectedOperators(item.id))(
        item.id,
        getSelectedOperators(item.id),
      ),
    };
  });

  React.useEffect(() => {
    backbone.init();
  }, []);

  if (!eTableSelected || !eTableConfig) return <span>ETable is not selected</span>;

  if (filterOptions.length == 0) return <LoadingIcon />;
  return (
    <Provider initialValues={[[eTableAtom.linkedObjects, linkedObjects]]}>
      <FilterForm
        addFilterButtonLabel="Add condition"
        headerText={''}
        helperText={<Typography variant="body2">Add your first condition</Typography>}
        options={filterOptions}
        operators={filterOperators}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        enabledSearchOptions={false}
        enableFillerGroup
        disabledIconNoFilter
        getOptionAvailable={getOptionFilterAvailable}
        hideButtons
        updateAfterChange
      />
    </Provider>
  );
};

export default FilterInput;
