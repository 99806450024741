import * as React from 'react';

const NotFound = () => {
  return (
    <div style={{ marginLeft: 80 }}>
      <h2>Not found.</h2>
    </div>
  );
};

export default NotFound;
