import qs from 'qs';
import * as eff from 'redux-saga/effects';

type enhancedAction<T extends any, A extends T[], R> = {
  (...args: A): { type: string; payload: R } | any;
  type: () => string;
  fetchType: () => string;
};

export const makeAction = <T extends any, A extends T[], R>(act: {
  actname: string;
  fun: (...args: A) => R;
}): enhancedAction<T, A, R> => {
  function action(...args: A) {
    return { type: act.actname, payload: act.fun(...args) };
  }
  action.type = () => act.actname;
  action.fetchType = () => act.actname + '_FETCH';

  return action;
};

type enSagaAction<T extends any, A extends T[], R> = enhancedAction<T, A, R> & { saga: any };

export function actionWithRequest<T extends any, A extends T[], R extends any>(act: {
  actname: string;
  fun: (...args: A) => R;
}): enSagaAction<T, A, R> {
  const action = makeAction({
    actname: act.actname,
    fun: (...args: A): A => {
      return args;
    },
  });

  function fn(...a: A) {
    return action(...a);
  }

  function* request(actRequest: { type: string; payload: A }) {
    yield eff.put({
      type: 'REQUEST_ACTION_LOADING',
      payload: {
        loading: {
          section: action.type(),
          status: true,
        },
      },
    });

    yield eff.delay(10);

    try {
      const result = yield eff.call(act.fun as any, ...(actRequest.payload ?? []));

      yield eff.delay(10);

      yield eff.put({
        type: action.fetchType(),
        payload: result,
      });

      yield eff.delay(10);

      yield eff.put({
        type: 'REQUEST_ACTION_LOADING',
        payload: {
          loading: {
            section: action.type(),
            status: false,
          },
        },
      });
    } catch (error) {
      console.error(error);
      yield eff.put({
        type: 'REQUEST_ACTION_LOADING',
        payload: {
          loading: {
            section: action.type(),
            status: false,
            error,
          },
        },
      });
    }
  }

  function* saga() {
    yield eff.takeLatest(action.type(), request);
  }

  fn.type = action.type;
  fn.fetchType = action.fetchType;
  fn.saga = saga;

  return fn;
}

export { makeAction as actionNative };
export { actionWithRequest as actionAsync };

export function makeReducer<T>(initState: T): any {
  const map: { [key: string]: (...args: any) => any } = {};

  function add(action: string, stateUpdate: (state: T, action: { type: string; payload?: any }) => T) {
    map[action] = stateUpdate;
  }

  function process(state: T = initState, action: { type: string; payload?: any }) {
    if (map[action.type]) {
      return map[action.type](state, action);
    } else {
      return state;
    }
  }

  return {
    process,
    add,
  };
}
