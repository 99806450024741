import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Properties = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="10" viewBox="0 0 12 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1Z" />
    <path d="M1 6C1.55228 6 2 5.55228 2 5C2 4.44772 1.55228 4 1 4C0.447715 4 0 4.44772 0 5C0 5.55228 0.447715 6 1 6Z" />
    <path d="M2 9C2 9.55229 1.55228 10 1 10C0.447715 10 0 9.55229 0 9C0 8.44771 0.447715 8 1 8C1.55228 8 2 8.44771 2 9Z" />
    <path d="M4 2C3.44772 2 3 1.55228 3 1C3 0.447715 3.44772 0 4 0H11C11.5523 0 12 0.447715 12 1C12 1.55228 11.5523 2 11 2H4Z" />
    <path d="M3 5C3 5.55228 3.44772 6 4 6H11C11.5523 6 12 5.55228 12 5C12 4.44772 11.5523 4 11 4H4C3.44772 4 3 4.44772 3 5Z" />
    <path d="M4 10C3.44772 10 3 9.55229 3 9C3 8.44771 3.44772 8 4 8H11C11.5523 8 12 8.44771 12 9C12 9.55229 11.5523 10 11 10H4Z" />
  </SvgIcon>
);

export default Properties;
