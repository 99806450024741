import { EventEmitter } from 'events';
import React from 'react';
import { aim, EIP_CONSTANT } from '@eip/next/lib/main';
import { authentication } from '@ep/insight-ui/lib/authentication';
import UserProfileInfo from '@ep/insight-ui/elements/input-form/form-templates/form-groups/profile-form/profile-form';

class FormUserProfileChartlib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <BlockUserProfileInfo />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return null;
}

function BlockUserProfileInfo() {
  const disabled = {
    uploadPhoto: true,
    fulltName: true,
    email: true,
    region: true,
    privacy: true,
    password: false,
  };
  const onChangePassword = async (values, callback) => {
    try {
      const { flowId, crsfToken } = await authentication.initProfileSettingFlow();
      if (flowId) {
        await authentication.updateUserPassword(flowId, {
          password: values.password,
          csrfToken: crsfToken,
        });
        callback();
      }
    } catch (error) {
      alert(error?.message ? error.message : 'Something is wrong. Please try again later');
    }
  };

  return (
    <UserProfileInfo
      disabled={disabled}
      userProfile={aim.getUserSettings().profile}
      onChangePassword={onChangePassword}
    />
  );
}

export default {
  blockType: 'formUserProfile',
  label: 'User setting',
  blockComponent: FormUserProfileChartlib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
    h: 4,
  },
};
