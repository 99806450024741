import produce from 'immer';
import { atom } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { get } from 'lodash';

type EtableView = {
  id: string;
  name: string;
  combinator: {
    filter: FilterType[];
    sort: SortType[];
    properties: {
      dimension: string[];
      attribute: string[];
      metric: string[];
    };
    groupBy: any;
    pinnedColumn: {
      field: string;
      isLeftPin: boolean;
      isRightPin: boolean;
    }[];
  };
  systemView: boolean;
};

export const defaultView: EtableView = {
  id: '',
  name: '',
  combinator: {
    filter: [],
    sort: [],
    properties: {
      dimension: [],
      attribute: [],
      metric: [],
    },
    groupBy: {},
    pinnedColumn: [],
  },
  systemView: false,
};

const atomViews = atom<EtableView[]>([]);
const atomCurrentView = atom<EtableView>({} as EtableView);

const log = console.info.bind(console, '[atom:view]');

const rwViews = atom(
  (get) => get(atomViews),
  (get, set, views) => {
    log('set views', views);
    set(atomViews, views);
  },
);

const rwCurrentView = atom(
  (get) => get(atomCurrentView),
  (get, set, viewId: string) => {
    const currentView = get(atomViews).find((i) => i.id == viewId);
    log('set current view', viewId, currentView, get(atomViews));
    set(
      atomCurrentView,
      get(atomViews).find((i) => i.id == viewId),
    );
  },
);

const updateView = atom(null, (get, set, view: EtableView) => {
  const id = view.id;
  const allViews = get(atomViews);
  set(
    atomViews,
    produce(allViews, (draft) => {
      const fIndex = allViews.findIndex((v) => v.id === id);
      draft[fIndex] = view;
    }),
  );
});

const pinnedColumn = selectAtom(atomCurrentView, (v) => {
  log('pinned column', v);
  return get(v, 'combinator.pinnedColumn', []);
});

const columnProps = selectAtom(atomCurrentView, (v) => {
  return get(v, 'combinator.properties', {
    dimension: [],
    attribute: [],
    metric: [],
  });
});

const toggleVerticalLines = selectAtom(atomCurrentView, (v) => {
  return get(v, 'combinator.showVerticalLines', 'yes');
});

export { rwViews as views, rwCurrentView as currentView, updateView, pinnedColumn, columnProps, toggleVerticalLines };
