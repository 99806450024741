/**
 * @jest-environment node
 */

import * as F from '@formulajs/formulajs';
import numeral from 'numeral';
import moment from 'moment';
import { isNumber } from 'lodash';

const cached = {};

type ExcelType = Omit<typeof F, 'TEXT'>;

function enhanceF() {
  const ef: Partial<ExcelType> & { TEXT: (value: any, format: string) => string } = {
    ...F,
  };

  ef.TEXT = (value, format, complementFormat) => {
    if (value === null || value === undefined) {
      return '-';
    }
    if (isNumber(value)) {
      return numeral(value).format(format);
    }

    const possibleDatetime = moment.utc(value);
    if (possibleDatetime.isValid()) {
      if (format === 'relative') {
        return possibleDatetime.fromNow();
      } else if (format === 'local') {
        if(complementFormat) {
          return possibleDatetime.local().format(complementFormat);
        } else {
          return possibleDatetime.local().toISOString();
        }
      }
      return possibleDatetime.format(format);
    } else {
      return `#INVALID!${value}`;
    }
  };

  return ef;
}

const eF = enhanceF();
export function toValue(formulaStr, context: Record<string, any>) {
  const isFormula = typeof formulaStr === 'string' && formulaStr.indexOf('=') === 0;
  if (!isFormula) return formulaStr;

  const formula = formulaStr.replace('=', '');

  if (!cached[formula]) {
    try {
      cached[formula] = new Function(
        'F',
        'p',
        `
    with(F) { 
      try {
        return String(${formula}); 
      }
      catch(err){
        console.warn(err);
        return '#ERR!';
      }
    }
   `,
      );
    } catch (e) {
      cached[formula] = () => '#SYNTAX!';
    }
  }

  return cached[formula](eF, (k: string) => context[k]);
}

export const isFormulaField = (f) => String(f).indexOf('=') === 0;

if (require.main === module) {
  console.info(toValue(`=CONCATENATE('hello ', p('hello'))`, { hello: 'world' }));
}
