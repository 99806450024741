import * as React from 'react';
import moment from 'moment';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, Grid, IconButton, List, ListItem, Tooltip } from '@material-ui/core';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { get, isEmpty } from 'lodash';
import Typography from '../text-style/Typography';
import TreeItem from '@material-ui/lab/TreeItem';
import { StyleListItem } from './list-item-style';
import Dropdown from '../dropdown-menu/dropdown';
import MenuList from '../list-control/menu-list';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import { colors, noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import clsx from 'clsx';
import * as _ from 'lodash';
import { ContainerResponsiveContext, aim, eipRequest as request, EIP_CONSTANT, usePage } from '@eip/next/lib/main';
import { LIST_EXPANDED_DEFAULT } from './tab-list-menu';

/**
 * ff.dnd_sidebar_menu:start
 */
import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';
import DraggableMenu from './draggable-menu';
/**
 * ff.dnd_sidebar_menu:end
 */

import TabListPopover, { TabListType } from './tab-list-popover';
import { usePassport } from './hooks/use-passport';
import { useToast } from '../notifications/hook';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import StyledTooltipWithMessages from '../tooltip/styled-tooltip';
import { DropdownCell } from '../etable2/dropdown-cell';
import { getCustomCellActions } from '@ep/insight-ui/system/helper/function';
import LoadingComponent from '../loading/loading-component';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import { useHistory } from 'react-router';

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    fontSize: '14px',
    lineHeight: '16px',
    fontStyle: 'normal',
    color: '#253746',
    fontWeight: 400,
    marginLeft: '0px',
    width: '100%',
    whiteSpace: 'nowrap',
    height: '100%',
    padding: '8px 0',
    ...(ff.next_left_menu
      ? {
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }
      : {}),
    ...noSelectStyled,
  },
  icon: {
    width: 15,
    height: 15,
    margin: 5,
    marginTop: 3,
    marginLeft: 0,
    marginRight: '6px',
    color: colors.icon.default,
  },
  iconExpand: {
    width: 10,
    height: 10,
    marginLeft: 1,
  },
  iconRight: {
    marginLeft: 20,
  },
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },
  content: {},
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  selected: {},
  parentTreeView: {},
  treeItem: {
    // height: '40px',
    borderRadius: '4px !important',
    padding: 0,
    justifyContent: 'space-between',
    '& .eip1-MuiTreeItem-group': {
      marginLeft: '0px',
      '& .eip1-MuiListItem-gutters': {
        paddingLeft: '24px',
      },
    },
    '& .eip1-MuiTreeItem-iconContainer': {
      width: 2,
    },
    '& .eip1-MuiTreeItem-label': {
      padding: 0,
    },
    '& .eip1-MuiTreeItem-content': {
      paddingRight: 8,
    },

    '&:not(.disabled) .eip1-MuiTreeItem-label:hover': {
      backgroundColor: `#E4E7E9`,
      borderRadius: ' 4px',
      '& p, & svg': {
        // color: '#0369C7 !important',
      },
    },
    '&:not(.disabled).eip1-MuiTreeItem-root.Mui-selected': {
      '&> .eip1-MuiTreeItem-content .eip1-MuiTreeItem-label': {
        borderRadius: ' 4px',
        backgroundColor: '#EBF6FF',
        '& p,& svg, & $textSecondary, & iconify-icon': {
          color: '#0369C7 !important',
        },
      },
    },
    '&.disabled .eip1-MuiTreeItem-label:hover': {
      backgroundColor: `transparent`,
    },
    // '&.no-children': {
    //   '& p, & svg': {
    //     color: '#9ca5ae',
    //   },
    // },
    '&.disabled.eip1-MuiTreeItem-root.Mui-selected': {
      '&> .eip1-MuiTreeItem-content .eip1-MuiTreeItem-label': {
        borderRadius: ' 4px',
        backgroundColor: `transparent`,
        '& p, & svg,& $textSecondary': {
          color: '#253746 !important',
        },
      },
    },
  },
  endIcon: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    pointerEvents: 'all',
  },
  noPointEvents: {
    pointerEvents: 'none',
  },
  iconLightBulbStar: {
    width: '20px',
    height: '18px',
    marginRight: '0px',
    marginLeft: '1px',
    marginTop: '-1px',
    marginBottom: '0px',
  },
  notification: {
    width: '15px',
    height: '12px',
  },
  bill: {
    width: '15px',
    height: '13px',
  },
  customActions: {},
  flex: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

export type TypeSimpleTab = {
  label: string;
  type?: string;
  nodeId?: string;
  icon?: IconType;
  dataMenu?: Array<DropdownMenuDataType[]>;
  disable?: boolean;
  locked?: boolean;
  tabs?: TypeSimpleTab[] | undefined;
  listExpanded?: string[];
  selected?: boolean;
  parentNodeId?: string;
  indeterminate?: boolean;
  setListExpanded?: React.Dispatch<React.SetStateAction<string[]>>;
  blockEid?: string;
  useDnd?: boolean;
  index?: number;
  parentNodeIds?: string[];
  updateSidebar?: any;
  appMenuListRef?: any;
  level?: number;
  nodeDetails?: any;
  ignoreChangePermission?: boolean;
  setAppMenuList?: any;
  homepageId?: string;
  setHomepageId?: React.Dispatch<React.SetStateAction<string>>;
  parentIds?: string[];
  favorites?: { nodeId: string }[];
  updateFavorites?: (newFavorites: { nodeId: string }[]) => void;
  additionalBlockInfo?: any;
  customActions?: any[];
  pageId?: string;
  sectionId?: string;
  endIcon?: string;
  sectionsExpanded?: string[];
  setSectionsExpanded?: React.Dispatch<React.SetStateAction<string[]>>;
};
export type IOnClickItem = (e: React.MouseEvent<HTMLSpanElement, MouseEvent>, tab: TypeSimpleTab) => void;
const noop: IOnClickItem = (e, tab) => undefined;
export const SimpleTab = ({
  nodeId,
  label,
  icon,
  disable = false,
  locked = false,
  dataMenu,
  tabs,
  onItemClick = noop,
  type,
  listExpanded = [],
  blockEid,
  setListExpanded = () => undefined,
  useDnd,
  index,
  parentNodeIds,
  updateSidebar,
  appMenuListRef,
  level,
  nodeDetails,
  selected,
  ignoreChangePermission,
  setAppMenuList,
  homepageId,
  setHomepageId,
  parentIds,
  favorites,
  updateFavorites,
  additionalBlockInfo,
  customActions,
  pageId,
  sectionId,
  endIcon,
  sectionsExpanded = [],
  setSectionsExpanded = () => undefined,
}: TypeSimpleTab & { onItemClick: IOnClickItem }) => {
  const classes = useStyles();
  const ref = React.useRef(null);
  const [expand, setExpand] = React.useState<boolean>(false);
  const [isHovering, setIsHovering] = React.useState(false);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  const [loadingSection, setLoadingSection] = React.useState(false);
  const [pageSections, setPageSections] = React.useState([]);
  const [loadPageSectionsError, setLoadPageSectionsError] = React.useState(false);
  const [timeReload, setTimeReload] = React.useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popoverType, setPopoverType] = React.useState<TabListType>(null);
  const dropdownRef = React.useRef(null);
  const { onToast, onToastMultiple } = useToast();
  const { currentPageBlockViews, loadPageBlockViews, getPageSections } = usePage();

  const history = useHistory();
  const location = history.location;

  const { setHomepage } = usePassport();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isPlusIcon =
    [
      'afdd53e6-49f9-4170-9212-bdeada713c0d',
      'ec22dd25-a14f-40d8-9199-a5b0517e835e',
      'b9f2a55f-32fc-4d70-9044-27ce0ec42848',
      '751811a2-3b77-41e2-83dc-7588690d579e',
    ].indexOf(nodeId) > -1;
  const enhanceDataMenu = React.useMemo(() => {
    const pageActions = [
      {
        title: (favorites || []).some((fv) => fv.nodeId === nodeId) ? 'Remove from favorites' : 'Add to favorites',
        onClick(blockEid: string) {
          const newFavorites = favorites.some((fv) => fv.nodeId === blockEid)
            ? favorites.filter((fv) => fv.nodeId !== blockEid)
            : favorites.concat({
                nodeId: blockEid,
              });
          updateFavorites(newFavorites);
        },
        icon: (favorites || []).some((fv) => fv.nodeId === nodeId)
          ? 'ic/fluent:star-off-12-filled/#0000008a'
          : 'ic/fluent:star-12-filled/#0000008a',
        disable: false,
      },
      ...((blockEid || '').split('/').length === 1
        ? [
            {
              title: homepageId === blockEid ? 'Unset homepage' : 'Set homepage',
              onClick(blockEid: string) {
                const newHomepageId = homepageId === blockEid ? null : blockEid;
                setHomepage(newHomepageId)
                  .then(() => {
                    onToast({
                      title: 'Successfully save homepage',
                      messages: '',
                      variant: 'success',
                    });
                    setHomepageId(newHomepageId);
                  })
                  .catch((e) => {
                    onToast({
                      title: e.message || 'Something went wrong!',
                      messages: '',
                      variant: 'error',
                    });
                  });
              },
              icon: homepageId === blockEid ? 'ic/bi:house-dash-fill/#0000008a' : 'ic/bi:house-fill/#0000008a',
              disable: false,
            },
          ]
        : []),
    ];
    if (aim.isSuperAdmin() && !ignoreChangePermission) {
      return [
        [
          {
            title: 'Rename',
            onClick() {
              setPopoverType(TabListType.CHANGE_NAME);
              setAnchorEl(dropdownRef.current);
            },
            icon: 'addedit',
            disable: !aim.isSuperAdmin(),
          },
          ...pageActions,
          ...dataMenu[0].slice(0, -1),
          {
            title: 'Change permission',
            onClick(blockEid: string) {
              setPopoverType(TabListType.CHANGE_PERMISSION);
              setAnchorEl(dropdownRef.current);
            },
            icon: 'user',
            disable: false,
          },
          ...dataMenu[0].slice(-1),
        ],
      ];
    } else {
      return [[...pageActions, ...dataMenu[0]]];
    }
  }, [dataMenu, homepageId, favorites]);

  const sectionDataMenu = React.useMemo(() => {
    const url =
      type == 'page_section'
        ? getPageUrn('/page/' + pageId + '?q=' + nodeId)
        : getPageUrn('/page/' + pageId + '?q=' + sectionId + '&v=' + nodeId.split('|')?.[1]);
    const sectionActions = [
      {
        title: 'Copy link',
        onClick(blockEid: string) {
          const cb = navigator.clipboard;
          cb.writeText(window.location.origin + url);
        },
        icon: 'copyLinkView',
      },
      {
        title: 'Open new tab',
        onClick(blockEid: string) {
          window.open(url, '_blank');
        },
        icon: 'addlink',
        disable: false,
      },
    ];
    return [sectionActions];
  }, []);

  const isExpend = React.useMemo(() => {
    return listExpanded.concat(sectionsExpanded).includes(nodeId);
  }, [listExpanded, sectionsExpanded]);

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (!['page_section_view', 'page_section'].includes(type)) {
      const listTemp = [...listExpanded];
      const index = listTemp.findIndex((item) => item == nodeId);

      if (index !== -1) {
        listTemp.splice(index, 1);
      } else {
        listTemp.push(nodeId);
      }
      localStorage.setItem(LIST_EXPANDED_DEFAULT, JSON.stringify(listTemp));
      setListExpanded(listTemp);
    } else {
      const listTemp = [...sectionsExpanded];
      const index = listTemp.findIndex((item) => item == nodeId);

      if (index !== -1) {
        listTemp.splice(index, 1);
      } else {
        listTemp.push(nodeId);
      }
      setSectionsExpanded(listTemp);
    }
  };

  React.useEffect(() => {
    if (isExpend && !isPlusIcon && type == 'page') {
      setLoadingSection(true);
      setLoadPageSectionsError(false);
      if (getPageSections) {
        getPageSections(nodeId, timeReload > 0)
          .then((res) => {
            setPageSections(res);
          })
          .catch(() => {
            setLoadPageSectionsError(true);
          })
          .finally(() => setLoadingSection(false));
      }
    }
  }, [isExpend, isPlusIcon, timeReload]);

  React.useEffect(() => {
    if (isExpend && !isPlusIcon && type == 'page_section') {
      setLoadingSection(true);
      setLoadPageSectionsError(false);
      const matched = location.pathname.match(/page\/.+/)?.[0];
      const selectedPageId = matched ? matched.split('/')[1] : '';

      if (get(currentPageBlockViews, [pageId, nodeId], null)) {
        const sections = get(currentPageBlockViews, [pageId, nodeId], []).map((view) => {
          return {
            title: view.name,
            label: view.name,
            nodeId: nodeId + '|' + view.id,
            icon: 'ic/ph:dot-outline-fill/#3F4F5C',
            tabs: [],
            type: 'page_section_view',
            pageId: pageId,
            sectionId: nodeId,
            endIcon: view.isLocked ? 'rmx/lock-line-icon/#0000008a' : null,
          };
        });
        setPageSections(sections);
        setLoadingSection(false);
      } else if (pageId != selectedPageId) {
        loadPageBlockViews(pageId, timeReload > 0)
          .catch((e) => {
            setLoadPageSectionsError(true);
          })
          .finally(() => setLoadingSection(false));
      }
    }
  }, [isExpend, isPlusIcon, currentPageBlockViews, timeReload]);
  return (
    <MonitorContainer mId={`menu-sidebar-${type}-${nodeId}`} mLabel={`Menu sidebar ${type} ${label}`}>
      <ConditionalWrap
        condition={useDnd}
        wrap={(children) => (
          <DraggableMenu
            nodeId={nodeId}
            parentNodeIds={parentNodeIds}
            showTopLine={index === 0}
            listExpanded={listExpanded}
            updateSidebar={updateSidebar}
            appMenuListRef={appMenuListRef}
          >
            {children}
          </DraggableMenu>
        )}
      >
        <TreeItem
          nodeId={nodeId}
          label={
            <List ref={ref}>
              <div
                style={{ width: '100%', position: 'relative', zIndex: 1 }}
                onMouseOver={() => !disable && setIsHovering(true)}
                onMouseLeave={() => !disable && setIsHovering(false)}
                ref={dropdownRef}
              >
                <TabListPopover
                  anchorEl={anchorEl}
                  handleClose={handleClose}
                  type={popoverType}
                  parentNodeIds={parentNodeIds}
                  nodeId={nodeId}
                  updateSidebar={updateSidebar}
                  appMenuListRef={appMenuListRef}
                  setAppMenuList={setAppMenuList}
                  label={label}
                  menuType={'feature'}
                  {...(ff.next_left_menu ? { parentIds } : {})}
                />
                <StyleListItem
                  className={'TreeClick'}
                  disabled={disable || locked}
                  style={{ padding: 0, paddingLeft: `${level * 12}px`, position: 'relative' }}
                >
                  <div
                    onClick={(e) => !(disable || locked) && handleClick(e)}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '20px',
                    }}
                    trace-type={type}
                    trace-action-type={isExpend ? 'close' : 'open'}
                    trace-node-id={nodeId}
                    trace-label={label}
                  >
                    {icon ? (
                      <Icon
                        type={icon}
                        className={clsx(
                          classes.icon,
                          classes.noPointEvents,
                          classes.iconExpand,
                          classes[icon],
                          icon === 'lightBulbStar' && classes.iconLightBulbStar,
                        )}
                        color={'inherit'}
                        trace-type={type}
                        trace-action-type={isExpend ? 'close' : 'open'}
                        trace-node-id={nodeId}
                        trace-label={label}
                      />
                    ) : (
                      <Icon
                        type={isPlusIcon ? 'plus' : isExpend ? 'triangularArrowDown' : 'triangularArrowRight'}
                        color={'inherit'}
                        className={clsx(`${classes.icon} ${classes.iconExpand} ${classes.noPointEvents}`)}
                        trace-type={type}
                        trace-action-type={isExpend ? 'close' : 'open'}
                        trace-node-id={nodeId}
                        trace-label={label}
                      />
                    )}
                  </div>

                  <div
                    className={clsx(classes.textSecondary, classes.flex)}
                    style={{ width: '100%' }}
                    onClick={(evt) => {
                      console.info('nodeDetails', nodeDetails);
                      if (!locked && !disable)
                        onItemClick(evt, { pageId, nodeId, label, type, nodeDetails, sectionId });
                    }}
                  >
                    <span className={classes.label}>{isEmpty(label) ? 'Untitled' : label}</span>
                    {endIcon ? (
                      <Icon
                        type={endIcon}
                        className={clsx(
                          classes.icon,
                          classes.noPointEvents,
                          classes.iconExpand,
                          classes[icon],
                          icon === 'lightBulbStar' && classes.iconLightBulbStar,
                        )}
                        color={'inherit'}
                      />
                    ) : null}
                  </div>
                  {get(additionalBlockInfo, [blockEid, 'length'], 0) > 0 ? (
                    <StyledTooltipWithMessages messages={additionalBlockInfo[blockEid]}>
                      <div
                        style={{
                          position: 'absolute',
                          top: '0',
                          left: `${level * 12 - 8}px`,
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '-1px',
                          height: '100%',
                        }}
                      >
                        <Icon
                          type={getConst('SIDEBAR_VIEW_AT_ICON', 'ic/ic:round-circle/azureishWhite')}
                          size={'8px'}
                        />
                      </div>
                    </StyledTooltipWithMessages>
                  ) : null}
                </StyleListItem>
                <div className={classes.endIcon} style={{ zIndex: 99 }}>
                  {locked ? (
                    <IconButton size="small" disabled style={{ marginBottom: 2 }}>
                      <Icon type="lock" />
                    </IconButton>
                  ) : (
                    (isHovering || isMobileView) && (
                      <MonitorContainer mId={blockEid} mLabel={blockEid}>
                        <Dropdown
                          disabled={disable || locked}
                          alignMenu="right"
                          icon="threeDotsVertical"
                          disabledToggleStyle
                          label=""
                          sizeIcon={'12px'}
                          dataMenuPage={enhanceDataMenu}
                        >
                          {(customActions || []).length > 0 && !['page_section', 'page_section_view'].includes(type) ? (
                            <div className={classes.customActions}>
                              <DropdownCell
                                cellAction={getCustomCellActions(
                                  { actions: customActions, field: '', backbone: null, isPageAction: true },
                                  [],
                                  onToastMultiple,
                                )}
                                props={{ value: { pageId: blockEid }, node: { data: { pageId: blockEid } } }}
                                ignoreDropdown
                              />
                            </div>
                          ) : null}
                          {['page_section', 'page_section_view'].includes(type) ? (
                            <MenuList
                              closeAfterClick
                              listMenu={sectionDataMenu}
                              {...(ff.clone_page
                                ? {
                                    blockEid: blockEid,
                                  }
                                : {})}
                            />
                          ) : !isEmpty(enhanceDataMenu) ? (
                            <MenuList
                              closeAfterClick
                              listMenu={enhanceDataMenu}
                              {...(ff.clone_page
                                ? {
                                    blockEid: blockEid,
                                  }
                                : {})}
                            />
                          ) : null}
                        </Dropdown>
                      </MonitorContainer>
                    )
                  )}
                </div>
              </div>
            </List>
          }
          className={clsx(
            `${classes.treeItem} ${(disable || locked) && 'disabled'}`,
            (!tabs || tabs.length === 0) && 'no-children',
          )}
          classes={{
            root: classes.root,
            content: 'TreeItemContent',
          }}
        >
          {
            <div
              style={{
                ...(loadingSection || loadPageSectionsError
                  ? { paddingTop: '8px', paddingBottom: '8px', paddingLeft: `${(level + 1) * 12}px` }
                  : {}),
              }}
            >
              <LoadingComponent loading={loadingSection}>
                {loadPageSectionsError ? (
                  <Button variant="text" size="small" onClick={() => setTimeReload((prevValue) => prevValue + 1)}>
                    <Grid container>
                      <Box style={{ marginRight: '4px' }}>
                        <Icon type={'reload'} />
                      </Box>
                      <span>Reload</span>
                    </Grid>
                  </Button>
                ) : (
                  pageSections.map((tab) => {
                    return (
                      <SimpleTab
                        listExpanded={[]}
                        setListExpanded={() => undefined}
                        key={tab.nodeId ? `${tab.nodeId}` : `${tab.label}`}
                        nodeId={tab.nodeId ? `${tab.nodeId}` : `${tab.label}`}
                        {...tab}
                        onItemClick={onItemClick}
                        dataMenu={dataMenu}
                        useDnd={false}
                        index={index}
                        parentNodeIds={[...parentNodeIds, nodeId]}
                        appMenuListRef={appMenuListRef}
                        updateSidebar={updateSidebar}
                        setAppMenuList={setAppMenuList}
                        level={level + 1}
                        homepageId={homepageId}
                        setHomepageId={setHomepageId}
                        favorites={favorites}
                        updateFavorites={updateFavorites}
                        additionalBlockInfo={additionalBlockInfo}
                        customActions={customActions}
                        sectionsExpanded={sectionsExpanded}
                        setSectionsExpanded={setSectionsExpanded}
                      />
                    );
                  })
                )}
              </LoadingComponent>
            </div>
          }
          {!disable && !locked && tabs
            ? tabs.map((tab, index) => (
                <SimpleTab
                  listExpanded={listExpanded}
                  setListExpanded={setListExpanded}
                  key={tab.nodeId ? `${tab.nodeId}` : `${tab.label}`}
                  nodeId={tab.nodeId ? `${tab.nodeId}` : `${tab.label}`}
                  {...tab}
                  onItemClick={onItemClick}
                  dataMenu={dataMenu}
                  useDnd={useDnd}
                  index={index}
                  parentNodeIds={[...parentNodeIds, nodeId]}
                  appMenuListRef={appMenuListRef}
                  updateSidebar={updateSidebar}
                  setAppMenuList={setAppMenuList}
                  level={level + 1}
                  homepageId={homepageId}
                  setHomepageId={setHomepageId}
                  favorites={favorites}
                  updateFavorites={updateFavorites}
                  additionalBlockInfo={additionalBlockInfo}
                  customActions={customActions}
                  sectionsExpanded={sectionsExpanded}
                  setSectionsExpanded={setSectionsExpanded}
                />
              ))
            : null}
        </TreeItem>
      </ConditionalWrap>
    </MonitorContainer>
  );
};
