import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { E } from '@ep/insight-ui/elements/e-element/e-element';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon from '@ep/insight-ui/icons/Icon';
import Arrow from '@ep/insight-ui/icons/svg/Arrow';
import { colors, contentStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrencyNumber, handlePercentAbs } from '@ep/insight-ui/lib/number';
import { Box, Button, Grid, IconButton, Tooltip, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { InformationMetric } from '../information-metric';
interface IProps {
  size: 'small' | 'medium';
  isSpacingNumber: boolean;
}

const titleResponsive = {
  small: {
    fontSize: '12px',
    lineHeight: '16px',
  },
  medium: {
    fontSize: '18px',
    lineHeight: '20px',
  },
};

const useStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: '#FFFFFF',
    color: '#253746',
    boxShadow: 'rgb(140 152 164 / 25%) 0px 6px 12px',
    borderRadius: '12px',

    width: '288px',
    height: '192px',

    padding: '12px',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '10px',
    '& .eip1-chart-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '18px',
      marginTop: '2px',
    },
    '& .eip1-chart-header .title': {
      cursor: 'pointer',
    },
    '.eres--small &': {
      flexDirection: 'row',
    },
  },

  title: {
    color: '#253746',
    fontWeight: 500,
    fontSize: (props: IProps) => titleResponsive[props.size].fontSize,
    lineHeight: (props: IProps) => titleResponsive[props.size].lineHeight,
  },

  number: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& .trending': {
      '&.trendingSmall': {
        fontSize: '14px',
      },
    },
    '.eres--medium &, .eres--large &': {
      '& .parameter + .trending': {
        marginLeft: (props: IProps) => (props.isSpacingNumber ? 'auto' : '8px'),
      },
    },
    '& .parameterChartSmall': {
      fontSize: '20px',
    },
    '&.between': {
      justifyContent: 'space-between',
    },
    '.eres--small &': {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },

  percent: {
    ...(contentStyle.percent as CreateCSSProperties),
    '& .parameter + .trending': {
      marginLeft: '0',
    },
    display: 'flex',
    alignItems: 'center',
  },

  state: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    '& .eip1-MuiTypography-root': {
      fontWeight: 400,
    },
  },

  stateAble: {
    display: 'flex',
    alignItems: 'center',
  },
  stateAbleIcon: {
    marginTop: '-2px',
    marginRight: '5px',
  },

  stateErrorText: {
    color: colors.text.critical,
  },

  '@keyframes rightTransform': {
    '0%': {
      transform: 'translateX(0)',
    },
    '25%': {
      transform: 'translateX(11px)',
    },
    '50%': {
      transform: 'translateX(11px)',
    },
    '75%': {
      transform: 'translateX(11px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  '@keyframes leftTransform': {
    '0%': {
      transform: 'translateX(0)',
    },
    '25%': {
      transform: 'translateX(-11px)',
    },
    '50%': {
      transform: 'translateX(-11px)',
    },
    '75%': {
      transform: 'translateX(-11px)',
    },
    '100%': {
      transform: 'translateX(0)',
    },
  },
  stateLoading: {
    display: 'flex',
    alignItems: 'center',
    '& .right': {
      // backgroundColor: 'blue',
      // transform: 'translateX(11px)',
      animation: `$rightTransform 1000ms ${theme.transitions.easing.easeIn} infinite`,
    },
    '& .left': {
      // backgroundColor: 'yellow',
      // transform: 'translateX(-11px)',
      animation: `$leftTransform 1000ms ${theme.transitions.easing.easeIn} infinite`,
    },
  },

  stateEmpty: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '8px',
  },

  wrapperResponsive: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '220px',
  },
  cohortValue: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
  },
}));

const BackgroundTooltip = withStyles({
  tooltip: {
    backgroundColor: '#253746',
  },
})(Tooltip);

const formatTooltip = (items) => {
  return items.map(({ value, currency, label }) => {
    return (
      <Typography key={value} variant="body1" style={{ fontSize: '12px' }}>{`${label}: ${formatCurrencyNumber(
        value,
        currency,
      )} ${currency ? currency : ''}`}</Typography>
    );
  });
};

export type IStateChartValue = 'success' | 'loading' | 'error' | 'empty' | 'no-data-config' | 'hide';
interface IChartState {
  stateChart?: IStateChartValue;
  children?: React.ReactNode;
  title?: string;
  value?: string | number;
  cohortValue?: number;
  currency?: string;
  percent?: number;
  isSpacingNumber?: boolean;
  forwardMeaning?: boolean;
  headerType?: string;
  parentRef?: any;
  handleClickTitle?: any;
  keepHeadContent?: boolean;
  titleBetween?: boolean;
  isHideTotal?: boolean;
  isHidePercent?: boolean;
}
const ChartState = ({
  stateChart,
  title = '',
  value = '',
  cohortValue = null,
  currency = '',
  percent = 0,
  isSpacingNumber = false,
  children = null,
  forwardMeaning = true,
  headerType = '',
  parentRef = { current: undefined },
  handleClickTitle,
  keepHeadContent = false,
  titleBetween = false,
  isHideTotal = false,
  isHidePercent = false,
}: IChartState) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState<'small' | 'medium'>('medium');
  const [arrow, setArrow] = React.useState<string>('up');
  const [isHovering, setIsHovering] = React.useState(false);
  const classes = useStyle({ size, isSpacingNumber });
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';
  const [isHoverTitle, setIsHoverTitle] = React.useState(false);
  const getDefinition = () => {
    const definition = InformationMetric.find((val) => val.title === title);
    if (definition === undefined) return '';
    return definition.definition;
  };
  const chartWithState = () => {
    switch (stateChart) {
      case 'success': {
        return <>{children}</>;
      }
      case 'empty': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateAble)}>
              <Icon className={clsx(classes.stateAbleIcon)} type="note" size="14px" />
              <Typography variant="body1">No data available.</Typography>
            </Box>
          </Box>
        );
      }
      case 'no-data-config': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateEmpty)}>
              <Icon type="tool" colorIcon={colors.icon.default} size="24px" />
              <Typography variant="body1">No data added to be monitored</Typography>
              <Button variant="contained" color="secondary">
                Add data
              </Button>
            </Box>
          </Box>
        );
      }
      case 'error': {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateAble)}>
              <Icon className={clsx(classes.stateAbleIcon)} type="error" colorIcon={colors.icon.critical} size="14px" />
              <Typography variant="body1">
                <span className={clsx(classes.stateErrorText)}>Could not load data.</span> Retry
              </Typography>
            </Box>
          </Box>
        );
      }
      case 'hide': {
        return null;
      }
      default: {
        return (
          <Box className={clsx(classes.state)}>
            <Box className={clsx(classes.stateLoading)}>
              <Box
                className={clsx('left')}
                width="10px"
                height="10px"
                borderRadius="100%"
                bgcolor={colors.icon.warning}
              />
              <Box
                className={clsx('right')}
                width="10px"
                height="10px"
                borderRadius="100%"
                bgcolor={colors.icon.warning}
              />
            </Box>
          </Box>
        );
      }
    }
  };

  const onResize = () => {
    const width = _.get(ref.current, 'offsetWidth');

    if (width < 768) {
      setSize('small');
      return;
    }
    setSize('medium');
  };

  React.useEffect(() => {
    const re = new ResizeObserver(onResize);
    re.observe(ref.current);
  }, []);
  const [isNumberChartSmall, setIsNumberChartSmall] = React.useState(false);
  const refNumber = React.useRef<HTMLDivElement>(null);
  const restyleNumberChart = (numberChartDOM) => {
    if (numberChartDOM.current.offsetHeight < 30) {
      if (numberChartDOM.current.classList.contains('numberGroupChartSmall')) {
        setIsNumberChartSmall(false);
      } else {
        return;
      }
    }
    if (numberChartDOM.current.offsetHeight > 30) {
      if (numberChartDOM.current.classList.contains('numberGroupChartSmall')) {
        return;
      } else {
        setIsNumberChartSmall(true);
      }
    }
  };
  React.useEffect(() => {
    if (refNumber.current) {
      const numberMutation = new ResizeObserver(() => {
        restyleNumberChart(refNumber);
      });
      numberMutation.observe(refNumber.current);
      return () => {
        numberMutation.disconnect();
      };
    }
  }, []);

  React.useEffect(() => {
    percent >= 0 ? setArrow('up') : setArrow('down');
  }, [percent]);

  const [isCopied, setIsCopied] = React.useState(false);

  const handleCopyMetricNumber = () => {
    const cb = navigator.clipboard;
    cb.writeText(value).then(() => setIsCopied(true));
  };

  const cohortRender = React.useMemo(() => {
    if (cohortValue == 0) return null;
    const percent = value / cohortValue;
    const items = [
      {
        label: title,
        value,
        currency,
      },
      {
        label: `${title} cohort`,
        value: cohortValue,
        currency,
      },
    ];
    return (
      <Box>
        <Typography
          variant={'h4'}
          className={clsx('parameter', classes.cohortValue)}
          style={{ color: percent < 1 ? '#D4290D' : '#0BA373' }}
        >
          <BackgroundTooltip title={formatTooltip(items)} placement="top">
            <div>{(percent * 100).toFixed(2)}%</div>
          </BackgroundTooltip>
        </Typography>
      </Box>
    );
  }, [value, cohortValue]);

  return (
    <div
      className={clsx(classes.wrapper, 'chartState')}
      ref={ref}
      onMouseOver={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {headerType !== 'analysis' ? (
        (keepHeadContent || stateChart === 'success') && (
          <Box mb={size === 'small' ? '8px' : '16px'} style={{ width: isMobile ? '25%' : 'auto' }}>
            <Grid container direction="row">
              {title && (
                <E pattern={title}>
                  {(val) => (
                    <Grid
                      item
                      xs={12}
                      onMouseOver={() => setIsHoverTitle(true)}
                      onMouseLeave={() => setIsHoverTitle(false)}
                      style={{ display: 'flex' }}
                    >
                      <Typography variant="h5" color="textSecondary" className={clsx(classes.title, 'title')}>
                        {val}
                      </Typography>
                      {isHoverTitle && (
                        <BackgroundTooltip title={getDefinition()} placement="top">
                          <IconButton style={{ padding: '0px', marginLeft: '6px' }}>
                            <Icon type={'addinformation'} colorIcon={'#253746'} />
                          </IconButton>
                        </BackgroundTooltip>
                      )}
                    </Grid>
                  )}
                </E>
              )}
            </Grid>
            {(keepHeadContent || stateChart === 'success') && (
              <div
                ref={refNumber}
                className={clsx('numberGroupChart', isNumberChartSmall ? 'numberGroupChartSmall' : '')}
              >
                <Box
                  className={clsx(
                    classes.number,
                    'number',
                    size === 'small' && titleBetween ? 'between' : '',
                    isMobile ? classes.wrapperResponsive : null,
                  )}
                >
                  <Box display={'flex'} position={'relative'} width={'100%'}>
                    {!isHideTotal ? (
                      isNumberChartSmall ? (
                        <Typography
                          variant={size === 'small' ? 'h2' : 'h1'}
                          className={clsx('parameter', isNumberChartSmall ? 'parameterChartSmall' : '')}
                        >
                          {value === null
                            ? 'N/A'
                            : `${formatCurrencyNumber(value, currency)} ${currency ? currency : ''}`}
                        </Typography>
                      ) : (
                        <Typography variant={size === 'small' ? 'h2' : 'h1'} className={'parameter'}>
                          {value === null
                            ? 'N/A'
                            : `${formatCurrencyNumber(value, currency)} ${currency ? currency : ''}`}
                        </Typography>
                      )
                    ) : null}
                    {isHovering && !isHideTotal && (
                      <BackgroundTooltip
                        title={
                          isCopied
                            ? 'Number copied'
                            : formatCurrencyNumber(value, currency) + ` ${currency ? currency : ''}`
                        }
                        onClick={handleCopyMetricNumber}
                      >
                        <IconButton
                          style={{ padding: '4px', marginLeft: '4px' }}
                          onMouseLeave={() => setIsCopied(false)}
                        >
                          <Icon type={'copy'} colorIcon={'#253746'} onClick={() => undefined} />
                        </IconButton>
                      </BackgroundTooltip>
                    )}
                    {cohortValue !== null && cohortRender}
                  </Box>
                  <Typography
                    variant="h4"
                    className={clsx(
                      classes.percent,
                      arrow === 'down' && 'down',
                      arrow == 'down' ? 'warning' : 'normal',
                      'trending',
                      !forwardMeaning && 'forwardMeaning',
                      isNumberChartSmall ? 'trendingSmall' : '',
                    )}
                  >
                    {(ff.reuse_our_chart && isHidePercent) || percent == null ? (
                      <></>
                    ) : (
                      <>
                        {+percent !== 0 && <Arrow className={clsx(arrow === 'down' && 'down')} />}
                        {handlePercentAbs(percent) || 0}%
                      </>
                    )}
                  </Typography>
                </Box>
              </div>
            )}
          </Box>
        )
      ) : (
        <Box
          mb={title && stateChart === 'success' && value && '16px'}
          className={`eip1-chart-header`}
          style={{ width: isMobile ? '25%' : 'auto' }}
        >
          <E pattern={title}>
            {(val) => (
              <Typography
                variant="h5"
                color="textSecondary"
                className={clsx(classes.title, 'title')}
                onClick={handleClickTitle}
              >
                {val}
              </Typography>
            )}
          </E>{' '}
        </Box>
      )}

      {chartWithState()}
    </div>
  );
};

export default ChartState;
