import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronMediumUp = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.263364 5.74C0.0945818 5.56794 0 5.3363 0 5.095C0 4.8537 0.0945818 4.62206 0.263364 4.45L4.36392 0.27C4.44499 0.184682 4.5425 0.116756 4.65055 0.070344C4.75859 0.0239319 4.87492 0 4.99247 0C5.11002 0 5.22635 0.0239319 5.33439 0.070344C5.44244 0.116756 5.53995 0.184682 5.62102 0.27L9.72158 4.45C9.80967 4.53575 9.87971 4.63835 9.92753 4.75171C9.97536 4.86508 10 4.98691 10 5.11C10 5.23308 9.97536 5.35492 9.92753 5.46829C9.87971 5.58165 9.80967 5.68425 9.72158 5.77C9.54767 5.92654 9.31919 6.00803 9.08572 5.99679C8.85224 5.98556 8.6326 5.88251 8.47445 5.71L4.99247 2.2L1.53044 5.74C1.36091 5.90554 1.13359 5.99818 0.896904 5.99818C0.660222 5.99818 0.432897 5.90554 0.263364 5.74Z" />
  </SvgIcon>
);

export default ChevronMediumUp;
