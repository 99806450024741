import { TreeChartOptions } from '@carbon/charts/interfaces';
import { AlignType } from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { getComponent } from '@ep/insight-ui/system/backbone/atom/app';
import { MenuProps } from '@material-ui/core';
import { useAtomValue } from 'jotai';
import * as React from 'react';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../chart-size';
import { ITreeMapDataChart } from '../type';
import { TreeMap } from './treemap-template';

interface MenuStyledProps extends MenuProps {
  alignMenu?: AlignType;
}

type ITreeMapChart = {
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: ITreeMapDataChart[];
  optionList: TreeChartOptions;
  percentList: { [key: string]: string };
  keyChart?: string;
  stateChart: IStateChartValue;
  colKey?: string;
  subTitle?: string;
};
const TreeMapChart = ({
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  percentList,
  stateChart,
  colKey,
  subTitle,
}: ITreeMapChart) => {
  const divRef = React.useRef<HTMLDivElement>();
  const legendRef = React.useRef<HTMLDivElement>(null);
  const [chartSize, setchartSize] = React.useState<TSize>('large');
  const ref = React.createRef<any>();

  const [menu, setMenu] = React.useState<any[]>([]);

  const ChartStateDerived = useAtomValue(React.useRef(getComponent(ChartState)).current);

  return (
    <div ref={divRef} style={{ height: '100%' }}>
      <ChartSize size={chartSize}>
        <ChartStateDerived
          stateChart={stateChart}
          title={title}
          value={value}
          currency={currency}
          percent={percent}
          handleClickTitle={() => {}}
          headerType={'analysis'}
          parentRef={divRef}
          colKey={colKey}
          subTitle={subTitle}
        >
          <>
            <TreeMap data={dataList} options={optionList} refElement={ref} colKey={colKey} />
            {menu}
          </>
        </ChartStateDerived>
      </ChartSize>
    </div>
  );
};

export default TreeMapChart;
