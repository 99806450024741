/* eslint-disable react/prop-types */
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import * as _ from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  audienceValue: {
    color: '#0369C7',
  },
}));

export type IValueAudienceFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueAudienceFormat>;
};
const AudienceFormat = (props: IPropsFormat<IValueAudienceFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const audienceStoreVisitors = _.get(props, 'value.audience_store_visitors', null);
  const audienceSimilarProducts = _.get(props, 'value.audience_similar_products', null);
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems={'center'} className={classes.format}>
        <Grid container className={classes.styleText}>
          <Grid item xs={12}>
            <span className={classes.audienceValue}>
              <Icon type={'ascending'} color={'#0369C7'} />
              {` ${audienceStoreVisitors || 0}%`}
            </span>
            <span> • store visitors in the past 15 days</span>
          </Grid>
          <Grid item xs={12}>
            <span className={classes.audienceValue}>
              <Icon type={'ascending'} color={'#0369C7'} />
              {` ${audienceSimilarProducts || 0}%`}
            </span>
            <span> • in-market audience for similar product</span>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default AudienceFormat;
