import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CloseRounded = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2C6.81331 2 5.65328 2.35189 4.66658 3.01118C3.67989 3.67047 2.91085 4.60754 2.45673 5.7039C2.0026 6.80026 1.88378 8.00666 2.11529 9.17054C2.3468 10.3344 2.91825 11.4035 3.75736 12.2426C4.59648 13.0818 5.66558 13.6532 6.82946 13.8847C7.99335 14.1162 9.19975 13.9974 10.2961 13.5433C11.3925 13.0892 12.3295 12.3201 12.9888 11.3334C13.6481 10.3467 14 9.18669 14 8C14 6.4087 13.3679 4.88258 12.2426 3.75736C11.1174 2.63214 9.5913 2 8 2ZM10.79 9.41C10.9783 9.5983 11.0841 9.8537 11.0841 10.12C11.0841 10.3863 10.9783 10.6417 10.79 10.83C10.6017 11.0183 10.3463 11.1241 10.08 11.1241C9.8137 11.1241 9.55831 11.0183 9.37 10.83L8 9.41L6.54 10.83C6.35264 11.0163 6.09919 11.1208 5.835 11.1208C5.57082 11.1208 5.31737 11.0163 5.13 10.83C5.03627 10.737 4.96188 10.6264 4.91111 10.5046C4.86034 10.3827 4.8342 10.252 4.8342 10.12C4.8342 9.98799 4.86034 9.85728 4.91111 9.73542C4.96188 9.61356 5.03627 9.50296 5.13 9.41L6.54 8L5.13 6.59C5.03627 6.49704 4.96188 6.38644 4.91111 6.26458C4.86034 6.14272 4.8342 6.01201 4.8342 5.88C4.8342 5.74799 4.86034 5.61728 4.91111 5.49542C4.96188 5.37356 5.03627 5.26296 5.13 5.17C5.31737 4.98375 5.57082 4.87921 5.835 4.87921C6.09919 4.87921 6.35264 4.98375 6.54 5.17L8 6.59L9.37 5.17C9.55831 4.9817 9.8137 4.87591 10.08 4.87591C10.3463 4.87591 10.6017 4.9817 10.79 5.17C10.9783 5.3583 11.0841 5.6137 11.0841 5.88C11.0841 6.1463 10.9783 6.4017 10.79 6.59L9.37 8L10.79 9.41Z"
      fill="#253746"
    />
  </SvgIcon>
);

export default CloseRounded;
