import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MediumScreen = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 13H10.6667L12 15V16H6V15L7.33333 13H2C0.895 13 0 12.105 0 11L0 2C0 0.895 0.895 0 2 0H16C17.105 0 18 0.895 18 2V11C18 12.105 17.105 13 16 13ZM3 2C2.4475 2 2 2.4475 2 3V8C2 8.55229 2.44772 9 3 9H15C15.5523 9 16 8.55229 16 8V3C16 2.96231 15.9979 2.92511 15.9939 2.88851C15.9422 2.42207 15.57 2.05262 15.1023 2.00516C15.0687 2.00175 15.0345 2 15 2H3Z"
    />
  </SvgIcon>
);

export default MediumScreen;
