import * as React from 'react';
import FormControlTemplate from '../form-control-template';
import TimeZoneInput from './time-zone-input';
import { SelectFormProps } from '@ep/insight-ui/elements/form-control/select-form/select-form';

interface TimeZoneProps extends SelectFormProps {
  label: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
const TimeZone = ({ label, value, onChange, ...rest }: TimeZoneProps) => {
  return (
    <div>
      <FormControlTemplate label={label}>
        <TimeZoneInput value={value} onChange={onChange} {...rest} />
      </FormControlTemplate>
    </div>
  );
};

export default TimeZone;
