import { ScriptDetails } from '@ep/insight-ui/chartlib/script-info';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { EventEmitter } from 'events';
import React from 'react';
export default class FormCampaignInfoChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return (
      <MonitorContainer component="div" mId={data.blockEid} mLabel={'Script details'}>
        <ScriptDetails />
      </MonitorContainer>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return null;
}
