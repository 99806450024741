import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const MoveTo = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.7064 4.2862L11.5717 2.14788C11.2562 1.83189 10.7171 2.05592 10.7171 2.50231V3.98443H3.99896C3.44732 3.98443 3 4.43249 3 4.98504L3.00001 4.99047L3 4.99591V11.9994C3 12.5519 3.44732 13 3.99896 13C4.5506 13 4.99791 12.5519 4.99791 11.9994V5.98566H10.7171V7.48867C10.7171 7.9359 11.2562 8.15909 11.5717 7.84311L13.7064 5.70478C14.0979 5.31273 14.0979 4.67825 13.7064 4.2862Z" />
  </SvgIcon>
);

export default MoveTo;
