import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import PieChartResponsive from './pie-chart';
import { funcConfigs } from './hooks';
import { colorsChartLegend, defaultOptions, tool } from '../helper';
import { IDataChart } from '../type';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { TSize } from '../chart-size';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { defaultPieLabel } from '@ep/insight-ui/elements/chart-editor/chart-editor';
type Props = {
  data: any;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  isHidePercent?: boolean;
  isHideTotal?: boolean;
  colKey?: string;
  subTitle?: string;
};
const DotChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
  isHidePercent = false,
  isHideTotal = false,
  colKey = '',
  subTitle = '',
}: Props) => {
  const [dataChart, setDataChart] = React.useState<{ dataList: any; optionList: any }>({
    dataList: null,
    optionList: null,
  });
  const { externalTooltipHandler } = funcConfigs();
  const backbone = React.useContext(TableBackboneContext);
  const pieLabel = _.get(backbone.getConfig('chartConfig'), ['config', 'pieLabel'], defaultPieLabel);
  React.useEffect(() => {
    if (!data) return;
    //Display option chart
    const { PIE: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    const datalabels = {
      formatter: (value, ctx) => {
        const dataArr = ctx.dataset.data;
        const sum = dataArr.reduce((a, b) => a + b, 0);
        const percent = value / sum;
        return toValue('=' + pieLabel, {
          value,
          sum,
          percent,
          label: ctx.dataset.label,
        });
      },
      font: {
        weight: '500',
        size: 18,
        lineHeight: '20px',
      },
      color: '#FFFFFF',
    };
    _.set(optionList, 'plugins.datalabels', datalabels);
    _.set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    if (maxValues) {
      _.set(optionList, 'scales.y.suggestedMax', maxValues);
    }
    data?.datasets.forEach((el) => {
      _.set(optionList, 'plugins.tooltip.callbacks.label', tool.pieCallbacks.label({ currency: el.currency }));
    });
    setDataChart({ dataList: data, optionList });
  }, [data]);

  return (
    <PieChartResponsive
      stateChart={stateChart}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
      keyChart={keyChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      isHidePercent={isHidePercent}
      isHideTotal={isHideTotal}
      colKey={colKey}
      subTitle={subTitle}
    />
  );
};

export default DotChart;
