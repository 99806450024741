import { eipRequest as request, EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { createDataQueryFromConfig, getAPIRequest } from '@ep/insight-ui/system/backbone/table-backbone';
import { createWorkflowCampaignDetails } from '@ep/insight-ui/system/backbone/workflow-backbone';
import { enhanceDataRequest2 } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';
import produce from 'immer';
import { cloneDeep, difference, get, merge, omit, set, uniq, uniqueId } from 'lodash';
import qs from 'qs';
import { getCampaignSettingInfo, publishDimensions } from '../legacy/api-request-campaign-details';
import { COMPACT_AD_OBJECT_CONFIG, COMPACT_AD_TOOL_CONFIG } from '../table-config';
import { CAMPAIGN_DETAIL_CONFIG } from './table-config';
import { getSelectedAdObjects } from '../util';
import { getActionMenu } from '../table-config/common';

const COMPACT_TABLE_CONFIG = {
  ad_tool: { ...COMPACT_AD_TOOL_CONFIG, tableId: 'ad_tool_compact' },
  ad_object: { ...COMPACT_AD_OBJECT_CONFIG, tableId: 'ad_object_compact' },
};

const CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY = {
  ...CAMPAIGN_DETAIL_CONFIG,
  primaryKeys: [...CAMPAIGN_DETAIL_CONFIG.primaryKeys, 'eipCustomRowId'],
};

const log = useLog('chartlib:table-campaign-detail');
const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;

const endpoint = {
  GET_CAMPAIGN_SETTING: API_URL + '/mop-query/listing/getCampaignData',
  GET_CAMPAIGN_INSIDE_DATA: API_URL + '/mop-query/listing/getCampaignInsideData',
  CREATE_DIMENSION: API_URL + '/ads-operation/create-dimension',
  UPDATE_DIMENSION: API_URL + '/ads-operation/update-dimension',
  MASS_CREATE_DIMENSION: API_URL + '/ads-operation/mass-create-ads-unit',
  MASS_UPDATE_DIMENSION: API_URL + '/ads-operation/mass-update-dimension',
  MASS_DELETE_DIMENSION: API_URL + '/ads-operation/mass-delete-dimension',
  MASS_UPSERT_DIMENSION: API_URL + '/ads-operation/mass-upsert-dimension',
};

export function getShopeeBAWorkflow(campaignType: 'SHP_BA_7_DAYS' | 'SHP_BA_14_DAYS' | 'SHP_BA_CUS') {
  const wf = createWorkflowCampaignDetails(
    {},
    {
      async full_tableConfig(input: Record<string, any>, workflow) {
        const ref: any = {};
        const campaignDetailConfig = { ...CAMPAIGN_DETAIL_CONFIG_CUSTOM_KEY };
        if (campaignType === 'SHP_BA_CUS') {
          campaignDetailConfig.mapping.ad_object_budget = {
            title: 'Ad object budget',
            propertyType: 'attribute',
            cellFormat: 'dailyBudget',
            dataType: 'float',
            filterField: 'ADS_OBJECT.daily_budget',
            valueGetter: {
              dailyBudget: 'ADS_OBJECT.daily_budget',
              value: 'ADS_OBJECT.daily_budget',
              currency: 'STOREFRONT.currency',
            },
            staticValue: {
              label: 'daily',
            },
            menu: getActionMenu('ad_object_daily_budget'),
          };
        } else {
          campaignDetailConfig.mapping.ad_object_budget = {
            title: 'Ad object budget',
            propertyType: 'attribute',
            cellFormat: 'totalBudget',
            dataType: 'float',
            filterField: 'ADS_OBJECT.total_budget',
            valueGetter: {
              totalBudget: 'ADS_OBJECT.total_budget',
              value: 'ADS_OBJECT.total_budget',
              currency: 'STOREFRONT.currency',
            },
            staticValue: {
              label: 'total',
            },
            menu: getActionMenu('ad_object_total_budget'),
          };
        }
        const tableConfig: Record<string, any> = merge(cloneDeep(campaignDetailConfig), {
          mapping: {
            ad_tool: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_tool' }),
            },
            ad_object: {
              tableConfiguration: await workflow.getTableConfig('compact', 'tableConfig', { column: 'ad_object' }),
            },
          },
        });

        const excludeColumns = ['storefront_ad_object', 'tagline', 'landing_page'];
        tableConfig.mapping = omit(tableConfig.mapping, excludeColumns);

        const config = {
          apiRequest: await workflow.request('eTableApiRequest', {
            campaignId: input.campaignId,
            schedule: input.schedule,
          }),
          id: uniqueId('ep_campaign_detail_'),
          configuration: tableConfig,
          callback: {
            onBackboneReady: (backbone) => {
              ref.backbone = backbone;
              workflow.set('mainTableBackbone', backbone);
              backbone.changeConfig('dateRange', input.schedule);
            },
            onRowRemove: async (type, rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              let selectedRows = [];
              let reloadTable = false;
              switch (type) {
                case 'ad_object':
                  selectedRows = backbone
                    .getSelectedRows()
                    .filter((row) => row['ADS_OBJECT.id'] !== rows['ADS_OBJECT.id']);

                  res = await workflow.applyUpdate('syncRemoveProduct', {
                    target: rowTarget,
                    updateType: 'default',
                    value: [].concat(rows).concat(selectedRows),
                  });
                  if (res.success) {
                    updatedRows = res.data;
                  } else {
                    error = res.error;
                  }
                  reloadTable = res.reloadTable;
                  break;
                case 'status':
                  res = await workflow.request('deleteStatusRow', {
                    id: rowTarget['ADS_PLACEMENT.id'] || rowTarget['ADS_OBJECT.id'],
                    dimension: rowTarget['ADS_PLACEMENT.id'] ? 'ADS_PLACEMENT' : 'ADS_OBJECT',
                    marketplaceCode: rowTarget['MARKETPLACE.marketplace_code'],
                  });
                  if (res.success) {
                    updatedRows = res.data;
                    reloadTable = true;
                  } else {
                    error = res.error;
                  }
                  break;
                default:
                  updatedRows = rows;
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, item.message], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (reloadTable) {
                // stateDispatch.current({ type: type, payload: { selectedItems: updatedRows, rowTarget } });
                // onChange(updatedRows, type);
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
          addons: {
            'datasource.getRowsParams': async ({ params }, config) => {
              const ignoreFields = ['ad_object', 'ad_tool', 'context'];
              params.attributes = params.attributes.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              params.dimensions = params.dimensions.filter((i) => {
                return ignoreFields.indexOf(i) === -1;
              });
              const aggregations = get(params, 'groupBy.aggregations', []);
              if (aggregations.length > 0) {
                params.groupBy.aggregations = aggregations.filter((i) => {
                  return ignoreFields.indexOf(i.field) === -1;
                });
              }
              return params;
            },
            'selection.filter': (field, originSelectionFilter) => {
              const additionalFilterField = get(tableConfig, ['system', 'additionalFilters'], []).find(
                ({ id }) => id === field,
              );

              const additionalOperators = get(additionalFilterField, ['operators'], []).map((el) => el.value);

              return additionalOperators.length ? additionalOperators : originSelectionFilter;
            },
            ...get(tableConfig, ['system', 'additionalFilters'], []).reduce((a, b) => {
              return {
                ...a,
                [`filterValue.${b.id}`]: () => {
                  return {
                    data: b.options,
                    success: true,
                    message: 'OK',
                  };
                },
              };
            }, {}),
            'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
              return enhanceDataRequest2(params, originRequest, backbone);
            },
          },
        };
        return config;
      },
      async compact_tableConfig({ column: type }, workflow) {
        const tableConfig = COMPACT_TABLE_CONFIG[type];
        const finalConfig: RecordValue = {
          tableCompactId: tableConfig.tableId,
          configuration: tableConfig,
          // apiRequest: {
          //   getTableData: () => Promise.resolve(COMPACT_TABLE_RES[type]),
          // },
          submitRowSelectionOnClosed: true,
          callback: {
            onRowSelect: async (rows, rowTarget, backbone) => {
              let res = null;
              let updatedRows = null;
              let error = null;
              switch (type) {
                case 'ad_object': {
                  res = await workflow.applyUpdate('syncUpdateCampaignProducts', {
                    updateType: 'default',
                    target: rowTarget,
                    value: rows,
                  });
                  if (!res.success) {
                    error = res.error;
                    updatedRows = rows;
                  } else {
                    updatedRows = res.data;
                  }
                  break;
                }
                default:
                  updatedRows = rows;
              }

              if (updatedRows) {
                workflow.emit('onChange', { updatedRows, type });
              }
              if (error && error.length > 0) {
                const errorMsgs = error
                  .filter((i) => i.success === false)
                  .reduce((acc, item) => [...acc, ...Object.values(item.error)], []);
                if (errorMsgs.length > 0) {
                  workflow.emit('onToastMultiple', {
                    title: 'Error',
                    variant: 'error',
                    messages: uniq(errorMsgs),
                  });
                }
              }
              if (updatedRows.length > 0) {
                backbone.updateSelectedRows([]);
                backbone.reloadData('table', rowTarget._route);
              }
            },
          },
        };
        log('Compact table config', { type, finalConfig });

        let searchField = '';
        switch (type) {
          case 'ad_object':
            searchField = 'PRODUCT';
            break;
        }

        finalConfig.addons = {
          ...(COMPACT_TABLE_CONFIG[type]?.addons || {}),
          'datasource.getRowsParams': async ({ params }, config) => {
            const context = get(config, 'system.context');
            const getStorefrontId = get(context, 'STOREFRONT.id');
            const storefrontIdFilter = {
              field: 'STOREFRONT.id',
              dataType: 'string',
              operator: 'is',
              value: getStorefrontId,
            };
            const shopeeFilter = {
              field: 'MARKETPLACE.marketplace_code',
              dataType: 'string',
              operator: 'is',
              value: 'SHOPEE',
            };

            if (!params.filter) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter],
                  });
            } else if (
              !params.filter.filters.some((el) =>
                Object.keys(shopeeFilter).every((ele) => el[ele] === shopeeFilter[ele]),
              )
            ) {
              type === 'ad_tool'
                ? (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter, storefrontIdFilter],
                  })
                : (params.filter = {
                    combinator: 'AND',
                    filters: [shopeeFilter, params.filter],
                  });
            }

            if (config.search) {
              return produce(params, (draft) => {
                const searchFilter = {
                  combinator: 'OR',
                  filters: config.search
                    .split('\n')
                    .filter((i) => String(i).trim() !== '')
                    .map((i) => {
                      return {
                        field: searchField,
                        dataType: 'string',
                        operator: 'contains',
                        value: String(i).trim(),
                      };
                    }),
                };

                if (!params.filter) {
                  draft.filter = searchFilter;
                } else {
                  draft.filter.filters = [...draft.filter.filters, searchFilter];
                }
              });
            }

            return params;
          },
        };

        return finalConfig;
      },
    },
    {
      eTableApiRequest: async ({ campaignId, schedule }, workflow) => {
        let apiRequest;
        const endpoint = CAMPAIGN_DETAIL_CONFIG.endpoint;
        apiRequest = getAPIRequest(endpoint);
        const originGetTableData = apiRequest.getTableData;
        apiRequest = {
          ...apiRequest,
          getTableData: (params) => {
            let hiddenFilter = params.hiddenFilter;
            if (!hiddenFilter) {
              hiddenFilter = {
                combinator: 'AND',
                filters: [],
              };
            }

            hiddenFilter = produce(hiddenFilter, (draft) => {
              draft.combinator = 'AND';
              draft.filters = (hiddenFilter.filters || []).concat({
                field: 'ADS_CAMPAIGN.id',
                dataType: 'integer',
                operator: '=',
                value: campaignId,
              });
            });

            params.sort = params.sort
              ? [].concat(params.sort)
              : [
                  { field: 'ADS_PLACEMENT.created_at', sort: 'DESC' },
                  { field: 'ADS_OBJECT.created_at', sort: 'DESC' },
                  { field: 'ADS_PLACEMENT.updated_at', sort: 'DESC' },
                  { field: 'ADS_OBJECT.updated_at', sort: 'DESC' },
                ];

            return originGetTableData({ ...params, hiddenFilter }).then(async (res) => {
              const rows = res.data.rows;
              let result = null;

              if (rows.length === 0) {
                // rows = stateSelector.current;
                result = await workflow.request('initFirstRowForEmptyCampaign', {
                  campaignId,
                  marketplace: 'SHOPEE',
                  campaignDateFrom: schedule.dateFrom,
                  campaignDateTo: schedule.dateTo,
                  originResponse: res,
                });
              } else {
                result = {
                  ...res,
                  data: {
                    ...res.data,
                    rows: await workflow.request('transformCellCompactSelections', {
                      rows,
                      campaignDateFrom: schedule.dateFrom,
                      campaignDateTo: schedule.dateTo,
                    }),
                  },
                };
              }

              return result;
            });
          },
        };
        return apiRequest;
      },
      initFirstRowForEmptyCampaign: async (
        { marketplace, campaignId, campaignDateFrom, campaignDateTo, originResponse },
        workflow,
      ) => {
        const campaignInfo = await getCampaignSettingInfo({ campaignId, marketplaceCode: marketplace });
        const rows = await workflow.request('transformCellCompactSelections', {
          rows: [].concat(campaignInfo),
          campaignDateFrom,
          campaignDateTo,
        });

        const result = produce(originResponse, (draft) => {
          set(draft, 'data.pagination.total', 1);
          set(draft, 'data.rows', rows);
        });

        return result;
      },
      transformCellCompactSelections: async (
        { rows, campaignDateFrom: campaignFrom, campaignDateTo: campaignTo },
        workflow,
      ) => {
        const toolIdField = 'ADTOOL.ads_tool';
        const toolNameField = 'ADTOOL.ads_tool_name';
        const adObjectNameField = 'ADS_OBJECT.name';
        const productIdField = 'PRODUCT.id';

        const selectedAdTools: any[] = await workflow.request('filterSelectedItems', {
          rows,
          idField: toolIdField,
          keyTuple: [toolIdField, toolNameField],
        });

        let selectedAdObjects: any[] = rows;
        if (rows.length > 0) {
          const r = rows[0];
          selectedAdObjects = await getSelectedAdObjects({
            currentWorkflow: workflow,
            marketplace: 'SHOPEE',
            compactTableConfig: COMPACT_TABLE_CONFIG.ad_object,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom, // FIXME: asked querying calendar on api
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
            originalRows: rows,
            productIdField,
            adObjectNameField,
          });
        }

        rows.forEach((r) => {
          const productId = r[productIdField];
          r.eipCustomRowId = uniqueId('eip_row_');
          r.ad_tool = {
            selectedItems: selectedAdTools,
            currentFocusItem: selectedAdTools.find((i) => i[toolIdField] === r[toolIdField]),
            displayField: toolNameField,
            singleSelection: true,
          };
          r.ad_object = {
            selectedItems: selectedAdObjects,
            currentFocusItem: selectedAdObjects.find((i) => i[productIdField] === r[productIdField]),
            displayField: adObjectNameField,
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: 'STOREFRONT.id',
                  dataType: 'integer',
                  operator: '=',
                  value: r['STOREFRONT.id'],
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
                  dataType: 'date',
                  operator: 'is_on_or_before',
                  value: campaignTo,
                },
                {
                  field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
                  dataType: 'date',
                  operator: 'is_on_or_after',
                  value: campaignFrom,
                },
                {
                  field: 'ADTOOL.ads_tool',
                  dataType: 'string',
                  operator: 'is',
                  value: r[toolIdField],
                },
              ],
            },
          };
        });

        return rows;
      },
      async filterSelectedItems(
        { rows, idField, keyTuple }: { rows: any[]; idField: string; keyTuple: any[] },
        workflow,
      ) {
        return rows.reduce((acc, r) => {
          const exist = acc.find((i) => i[idField] === r[idField]);
          if (!exist && r[idField]) {
            return [
              ...acc,
              {
                ...keyTuple.reduce((carry, k) => {
                  return { ...r, ...carry, [k]: r[k] };
                }, {}),
              },
            ];
          }
          return acc;
        }, []);
      },

      createAdsObjects: (param: {
        data: {
          masterObjectId: string;
          adsCampaignId: string;
          dailyBudget: number;
          totalBudget: number;
        }[];
        marketplaceCode: string;
      }) => {
        const fields = [
          'daily_budget',
          'total_budget',
          'tool_code',
          'marketplace_code',
          'storefront_id',
          'country_code',
          'name',
          'status',
          'master_object_id',
          'ads_campaign_id',
        ];
        const reqParam = {
          data: param.data.map((i) => ({
            ads_unit_type: 'AdsObject',
            attributes: fields.map((field) => {
              return {
                field_name: field,
                value: i[field],
              };
            }),
          })),
        };
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
      },
      deleteAdsObjects(param: { id: string[]; marketplaceCode: string }) {
        const queryParams = Object.keys(param).reduce((a, b) => {
          if (b === 'marketplaceCode') return `${a}&${b}=${param[b]}`;
          if (b === 'id') return `${a}&${param[b].map((el) => `${b}=${el}`).join('&')}`;
          return a;
        }, 'dimension=ADS_OBJECT');
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${queryParams}`;
        // return Promise.resolve(CREATE_ADS_OBJECT_RES);
        return request.deleteFetch(url);
      },
      deleteStatusRow(param: { id: string[]; marketplacecode: string; dimension: string }) {
        const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
          {
            ...param,
            dimension: param.dimension,
          },
          {
            arrayFormat: 'comma',
          },
        )}`;

        // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
        return request.deleteFetch(url);
      },
      dataQueryFromMainTable: async ({ campaignId, schedule, excludedMetric = true }, workflow) => {
        const tableConfig = await workflow.getTableConfig('full', 'tableConfig', { campaignId, schedule });
        const config = tableConfig.configuration;
        if (excludedMetric) {
          config.mapping = Object.keys(config.mapping).reduce((carry, k) => {
            {
              if (config.mapping[k].propertyType !== 'metric') {
                return { ...carry, [k]: config.mapping[k] };
              }
              return carry;
            }
          }, {});
        }
        const ds = createDataQueryFromConfig({ config: config, addons: tableConfig.addons });
        return ds;
      },
      campaignStatus: async ({ campaignId, schedule }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });

        return {
          haveDraft: true,
          draftItems: [],
        };
      },
      publishCampaign: async ({ campaignId, schedule, marketplace }, workflow) => {
        const ds: ReturnType<typeof createDataQueryFromConfig> = await workflow.request('dataQueryFromMainTable', {
          campaignId,
          schedule,
        });
        const results = [];

        const draftProduct = await ds.query({
          filter: [],
          dateRange: {
            dateFrom: schedule.dateFrom,
            dateTo: schedule.dateTo,
          },
          hiddenFilter: {
            combinator: 'AND',
            filters: [
              { field: 'ADS_CAMPAIGN.id', dataType: 'integer', operator: '=', value: campaignId },
              { field: 'ADS_OBJECT.status', dataType: 'string', operator: '=', value: 'DRAFT' },
            ],
          },
          pageSize: 1000,
        });

        const draftAdObjectIds = draftProduct.rowData.map((i) => i['ADS_OBJECT.id']);
        if (draftAdObjectIds.length > 0) {
          results.push(
            await publishDimensions({
              dimension: 'ADS_OBJECT',
              id: draftAdObjectIds,
              marketplaceCode: marketplace,
            }),
          );
        }
        return results;
      },
    },
    {
      async syncRemoveProduct({ target: rowTarget, updateType, value: products }, workflow) {
        const adObjectIdField = 'ADS_OBJECT.id';
        const marketplaceCode = get(rowTarget, ['MARKETPLACE.marketplace_code'], null);
        const selectingItemIds = products.map((i) => i[adObjectIdField]);

        const removeProductIds = selectingItemIds;

        let removePromise = null;
        if (removeProductIds.length > 0) {
          const removeAdsObjectParam = {
            marketplaceCode,
            id: selectingItemIds,
          };
          removePromise = workflow.request('deleteAdsObjects', removeAdsObjectParam);
        } else {
          removePromise = Promise.resolve(null);
        }

        return removePromise
          .then((result) => {
            let rows = [];
            let success = false;
            let reloadTable = false;
            if (result) {
              if (result.success) {
                rows = result.data;
                success = true;
              } else {
                console.log(result.message);
              }
              if (Number(result.successfulElement) > 0) {
                reloadTable = true;
              }
            }

            return {
              success,
              data: rows,
              error: result.data,
              reloadTable,
            };
          })
          .catch((err) => {
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
      async syncUpdateCampaignProducts({ target, updateType, value }, workflow) {
        const productIdField = 'PRODUCT.id';
        const productNameField = 'PRODUCT.name';
        const campaignId = get(target, 'ADS_CAMPAIGN.id', null);
        const marketplaceCode = get(target, ['MARKETPLACE.marketplace_code'], null);
        const storefrontId = get(target, ['STOREFRONT.id'], null);
        const selectedItems = get(target, ['ad_object', 'selectedItems'], []);
        const countryCode = get(target, ['COUNTRY.country_code'], null);
        const toolCode = get(target, ['ADTOOL.ads_tool'], null);

        const selectedItemIds = selectedItems.map((i) => i[productIdField]);
        const selectingItemIds = value.map((i) => i[productIdField]);

        // Determine which products to add/remove
        const removeProductIds = difference(selectedItemIds, selectingItemIds);
        const addProductIds = difference(selectingItemIds, selectedItemIds);
        const addProductName = addProductIds.reduce((carry, id) => {
          return {
            ...carry,
            [id]: value.find((el) => el[productIdField] == id),
          };
        }, {});

        let removePromise = null;
        let addPromise = null;
        removePromise = Promise.resolve(null);

        if (addProductIds.length > 0) {
          const addAdsObjectParam = {
            data: addProductIds.map((id) => ({
              master_object_id: String(id),
              country_code: countryCode,
              storefront_id: String(storefrontId),
              tool_code: toolCode,
              status: 'DRAFT',
              ads_campaign_id: String(campaignId),
              total_budget: '0',
              daily_budget: '0',
              marketplace_code: marketplaceCode,
              name: addProductName[id]?.[productNameField],
            })),
          };
          addPromise = workflow.request('createAdsObjects', addAdsObjectParam);
        } else {
          addPromise = Promise.resolve(null);
        }

        return Promise.all([removePromise, addPromise])
          .then((values) => {
            let rows = [];
            let success = false;
            let error = [];
            if (values[0]) {
              if (values[0].success) {
                rows = selectedItems.filter((i) => !removeProductIds.includes(i[productIdField]));
                success = true;
              } else {
                error = error.concat(values[0].data);
                console.log(values[0].message);
              }
            }

            if (values[1]) {
              if (values[1].success) {
                const addedItems = value
                  .filter((i) => addProductIds.includes(i[productIdField]))
                  .map((i) => {
                    const adObject = values[1].data.find((v) => v.data.masterObjectId == i[productIdField]);
                    return {
                      ...i,
                      status: get(adObject, ['data', 'status'], ''),
                      adsObjectId: parseInt(get(adObject, ['data', 'adsObjectId'], 0)),
                    };
                  });
                rows = [...rows, ...addedItems];
                success = true;
              } else {
                error = error.concat(values[1].data);
                console.log(values[1].message);
                console.error(values[1]);
              }
            }

            return {
              success,
              data: rows,
              error: error,
            };
          })
          .catch((err) => {
            console.log('Error', err);
            return {
              success: false,
              data: [],
              error: err.data,
            };
          });
      },
    },
  );
  return wf;
}
