import { SortValue } from '@ep/insight-ui/elements/chart-type/data-demo';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { groupBy as _groupBy, get, set, uniqBy, uniq, cloneDeep } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { colorsChartLegend, convertColor, defaultOptions, HIDDEN_LABEL_TEXT, tool } from '../helper';
import { IDataChart } from '../type';
import ColumnChartResponsive from './column-chart';
import { funcConfigs } from './hooks';

type Props = {
  data: IDataChart;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  colorOption?: Array<string>;
  isHideTotal?: boolean;
  isHidePercent?: boolean;
  isColumnStacked?: boolean;
  sortBy?: string;
  isSingle?: boolean;
  groupPeriod?: string;
  valueDisplay?: 'single' | 'multiple';
  isSettingYAxis?: boolean;
  groupBy?: 'dimension' | 'metric';
  chartType?: 'column' | 'waterfall';
  colKey?: string;
  subTitle?: string;
};
const ColumnChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
  colorOption = [],
  isHideTotal = false,
  isHidePercent = false,
  isColumnStacked = false,
  sortBy = SortValue.ALPHABET,
  isSingle = false,
  groupPeriod,
  valueDisplay,
  isSettingYAxis = false,
  groupBy = 'dimension',
  chartType = 'column',
  colKey,
  subTitle,
}: Props) => {
  const [dataChart, setDataChart] = React.useState<{ dataList: any; optionList: any }>({
    dataList: null,
    optionList: null,
  });
  const { externalTooltipHandler } = funcConfigs({});
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const mapping = backboneContext.getConfig('mapping');
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const periodBackbone = backboneContext.getConfig('groupPeriod', '');
  const useDimensionXAxis =
    get(backboneContext.getConfig('chartConfig', {}), ['config', 'useDimensionXAxis'], 'no') === 'yes';
  const showLabelOnTop =
    get(backboneContext.getConfig('chartConfig', {}), ['config', 'showLabelOnTop'], 'no') === 'yes';

  const isoWeekday = get(backboneContext.getConfig('chartConfig', {}), ['config', 'isoWeekday'], 'Friday');

  const groupDimension = (backboneContext.getConfig('groupBy', { columns: [] }).columns || [])[0];

  const formatHeaders = () => {
    const headers = data.headers;
    let formatTime;
    if (!periodBackbone || (useDimensionXAxis && groupDimension)) return headers;
    switch (periodBackbone) {
      case 'hourly':
        formatTime = 'HH:mm';
        break;
      case 'daily':
        formatTime = 'MMM DD';
        break;
      case 'weekly':
        formatTime = '[W]W YYYY';
        break;
      case 'monthly':
        formatTime = 'MMM YYYY';
        break;
      case 'quarterly':
        formatTime = '[Q]Q YYYY';
        break;
      case 'yearly':
        formatTime = '[Y]YYYY';
        break;

      default:
        formatTime = 'MMM DD';
        break;
    }
    if (periodBackbone === 'weekly') {
      return headers.map((header) => moment(header).isoWeekday(isoWeekday).format('[W]W, MMM DD YYYY'));
    }
    return headers.map((header) => moment(header).format(formatTime));
  };

  React.useEffect(() => {
    const datasets = [];
    const alphabetOrder = Object.entries(_groupBy(data.rows, 'key'))
      .sort((a, b) => (String(a[0]).toLowerCase() > String(b[0]).toLowerCase() ? 1 : -1))
      .reduce((a, b) => {
        return [
          ...a,
          ...[...b[1]].sort((c, d) => (String(c.label).toLowerCase() > String(d.label).toLowerCase() ? 1 : -1)),
        ];
      }, []);
    const dataOrder = sortBy === SortValue.ALPHABET && periodBackbone != 'all' ? alphabetOrder : data.rows;
    const isKeyTheSame = uniq(dataOrder.map((el) => el.key)).length === 1;

    const headers = formatHeaders();
    let colorIndex = 0;
    const colors = dataOrder.reduce((carry, item, index) => {
      const chartColorConfig = get(mapping, [item.key, 'staticValue', 'color'], null);
      let color, hoverBorderColor;

      if (chartColorConfig) {
        color = chartColorConfig;
        hoverBorderColor = convertColor(chartColorConfig);
      } else if (colorOption?.[colorIndex]) {
        color = colorOption[colorIndex];
        hoverBorderColor = convertColor(colorOption[colorIndex]);
        colorIndex++;
      } else {
        color = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].stroke;
        hoverBorderColor = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].pointBorderColor;
        colorIndex++;
      }

      return {
        ...carry,
        [`${item.key}_${index}`]: {
          color,
          hoverBorderColor,
        },
      };
    }, {});

    dataOrder.forEach((row, index) => {
      let thisSeries = [];
      const futureSeries = [];
      const rowDataEmpty = [];
      row.data.forEach((item) => {
        // if (moment(item.datetime, 'YYYY-MM-DD').isAfter(now)) {
        //   futureSeries.push(item.value);
        // } else {
        thisSeries.push(item.value);
        // }
        rowDataEmpty.push(0);
      });

      if (chartType === 'waterfall') {
        const total = thisSeries.reduce((a, b) => a + (b || 0), 0);
        thisSeries = thisSeries.map((el, index, arr) => {
          const previousValue = arr.slice(0, index).reduce((a, b) => a + (b || 0), 0);
          return [previousValue, previousValue + (el || 0)];
        });
        thisSeries.push([0, total]);
      }

      const colorIndex = Number(index) >= Object.keys(colorsChartLegend).length ? 0 : index;
      const chartColorConfig = get(mapping, [row.key, 'staticValue', 'color'], null);

      let chartYAxis = get(mapping, [row.key, 'valueGetter', 'chart.yAxis'], '');
      if (!chartYAxis || !chartYAxis.startsWith('=')) {
        chartYAxis = `y_${row.key}`;
      } else {
        try {
          chartYAxis = `y_${toValue(chartYAxis, {})}`;
        } catch (e) {
          chartYAxis = `y_${row.key}`;
        }
      }

      const order = isKeyTheSame ? index : dataOrder.findIndex((el) => el.key === row.key);
      const key = `${row.key}_${index}`;

      const dataSetCurrent = {
        currency: row.currency,
        formatLabel: row.formatLabel,
        formatTopLabel: row.formatTopLabel,
        axisData: data.headers,
        label: row.label,
        backgroundColor: colors[key].color,
        borderColor: colors[key].color,
        pointBackgroundColor: colors[key].color,
        hoverBackgroundColor: colors[key].hoverBorderColor,
        data: thisSeries,
        textKey: row.label,
        key: row.key,
        active: 'active',
        order: order,
        ...(!isColumnStacked &&
        isSettingYAxis &&
        ff.reuse_our_chart &&
        ((valueDisplay === 'single' && groupBy === 'dimension') || valueDisplay === 'multiple')
          ? {
              yAxisID: chartYAxis,
            }
          : {}),
        chartColorConfig,
      };
      if (order == data.rows.length - 1 || !isColumnStacked) {
        set(dataSetCurrent, 'borderRadius', {
          topLeft: 4,
          topRight: 4,
        });
      } else {
        set(dataSetCurrent, 'borderRadius', {
          topLeft: 0,
          topRight: 0,
        });
      }
      datasets.push(dataSetCurrent);
      if (chartType === 'waterfall') {
        Array.from({ length: thisSeries.length - 1 }).map((_, index) => {
          const lineDataset = {
            ...dataSetCurrent,
            type: 'line',
            data: thisSeries.map((el, i) =>
              i - index <= 1 && i - index >= 0 ? el[i - index === 0 || i === thisSeries.length - 1 ? 1 : 0] : undefined,
            ),
            borderDash: [10, 5],
            borderWidth: 1,
            pointRadius: 0,
            pointHoverRadius: 0,
          };
          datasets.push(lineDataset);
        });
      }
      if (futureSeries.length > 0) {
        const arr = thisSeries.map((item, index) => 0).concat(futureSeries);
        const dataSet = {
          label: HIDDEN_LABEL_TEXT + index,
          colorTooltip: colorsChartLegend[colorIndex].stroke,
          textKey: row.label,
          backgroundColor: colorsChartLegend[colorIndex].segment,
          borderColor: colorsChartLegend[colorIndex].segment,
          pointBackgroundColor: colorsChartLegend[colorIndex].stroke,
          hoverBackgroundColor: colorsChartLegend[colorIndex].hover,
          data: arr,
          stack: 1,
          active: 'active',
        };
        if (index == data.rows.length - 1) {
          set(dataSet, 'borderRadius', {
            topLeft: 4,
            topRight: 4,
          });
        }
        datasets.push(dataSet);
      }
    });

    // Update datasets for formula colors
    const formulaValues = datasets.reduce((a, b) => {
      return {
        ...a,
        [b.key]: b.data,
      };
    }, {});
    datasets.forEach((item, index) => {
      const keyColor = `${item.key}_${index}`;
      const formulaColor = get(mapping, [item.key, 'valueGetter', 'chartColor'], '');
      let backgroundColor, hoverBackgroundColor;
      if (formulaColor && formulaColor.startsWith('=')) {
        backgroundColor = item.data.map((dt, i) => {
          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
            return {
              ...carry,
              [key]: value[i],
            };
          }, {});

          return (
            toValue(formulaColor, {
              value: dt,
              legend: item.label,
              ...formulaValue,
            }) ||
            colors[keyColor]?.color ||
            '#ccc'
          );
        });
        hoverBackgroundColor = item.data.map((dt, i) => {
          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
            return {
              ...carry,
              [key]: value[i],
            };
          }, {});
          return (
            convertColor(
              toValue(formulaColor, {
                value: dt,
                legend: item.label,
                ...formulaValue,
              }),
            ) ||
            colors[keyColor]?.hoverBorderColor ||
            '#ccc'
          );
        });
      }

      if (backgroundColor) {
        item.pointHoverBackgroundColor = backgroundColor;
        item.backgroundColor = backgroundColor;
        item.borderColor = uniq(backgroundColor);
      }
      if (hoverBackgroundColor) {
        item.pointHoverBorderColor = hoverBackgroundColor;
        item.hoverBackgroundColor = hoverBackgroundColor;
      }
    });

    const uniqYAxisId = uniqBy(datasets, 'yAxisID');

    const displayYAxisList = uniqBy(datasets, 'key').reduce((a, b) => {
      const isDisplay = get(mapping, [b.key, 'valueGetter', 'chart.displayYAxis'], '').toLowerCase() === '=true';
      if (!isDisplay || a.includes(b.yAxisID)) return a;
      return [...a, b.yAxisID];
    }, []);

    const rangeYAxisList = uniqBy(datasets, 'key').reduce((a, b) => {
      let maxYAxis, minYAxis;
      try {
        maxYAxis = toValue(get(mapping, [b.key, 'valueGetter', 'chart.rangeMax']), {});
        minYAxis = toValue(get(mapping, [b.key, 'valueGetter', 'chart.rangeMin']), {});
      } catch {}

      return {
        ...a,
        [b.yAxisID]: {
          maxYAxis:
            Math.max(a[b.yAxisID]?.maxYAxis || -999999999999999, maxYAxis) || a[b.yAxisID]?.maxYAxis || maxYAxis,
          minYAxis: Math.min(a[b.yAxisID]?.minYAxis || 999999999999999, minYAxis) || a[b.yAxisID]?.minYAxis || minYAxis,
        },
      };
    }, {});

    const dataList = {
      labels: headers.concat(chartType === 'waterfall' ? 'Total' : []),
      datasets,
    };
    //Display option chart
    const { STACKED_COLUMN: optionList } = cloneDeep(defaultOptions);
    set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    set(optionList, 'plugins.tooltip.callbacks.label', tool.columeCallbacks.label({ chartType }));
    set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    set(optionList, 'scales.yAxes.display', false);
    set(optionList, 'period', periodBackbone);
    if (maxValues) {
      set(optionList, 'scales.y.suggestedMax', maxValues);
    }
    set(optionList, 'scales.yAxes.stacked', isColumnStacked);
    set(optionList, 'scales.xAxes.stacked', isColumnStacked);
    if (isSingle && isDarkMode) {
      set(optionList, 'scales.xAxes.ticks.color', '#FFFFFF');
    }

    if (chartType === 'waterfall' || showLabelOnTop) {
      set(optionList, 'plugins.datalabels', {
        display: (props) => {
          return get(props, ['dataset', 'type'], '') !== 'line' ? 'auto' : false;
        },
        color: 'black',
        formatter: (rawValue, props) => {
          const format = props.dataset.formatTopLabel || props.dataset.formatLabel;
          if (chartType === 'waterfall') {
            const value = Array.isArray(rawValue) ? rawValue[1] - rawValue[0] : rawValue;
            if (value == null) return null;
            const label = format ? toValue(format, { value, currency: props.dataset.currency }) : value;
            return label;
          }

          const key = get(props, ['dataset', 'key'], '');
          const order = get(props, ['dataset', 'order'], '');
          let value;
          const dts = datasets.filter((dt) => dt.key == key);
          const datasetsHasValue = dts.filter((dt) => get(dt, ['data', props.dataIndex], null) != null);
          const mappedDataset = dts.map((dt) => get(dt, ['data', props.dataIndex], null)).filter((dt) => dt != null);
          const total = mappedDataset.reduce((a, b) => a + b, 0);
          const max = Math.max(...mappedDataset);
          const min = Math.min(...mappedDataset);
          if (isColumnStacked) {
            value =
              order == (datasetsHasValue.length > 0 ? datasetsHasValue[datasetsHasValue.length - 1]?.order : 0)
                ? dts.reduce((carry, dt) => {
                    const dtValue = get(dt, ['data', props.dataIndex], null);
                    if (carry == null && dtValue == null) return null;
                    return (carry || 0) + (dtValue || 0);
                  }, null)
                : null;
          } else {
            value = Array.isArray(rawValue) ? rawValue[1] - rawValue[0] : rawValue;
          }
          if (value == null) return null;
          const label = format
            ? toValue(format, { value, currency: props.dataset.currency, total, max, min, list: mappedDataset })
            : value;
          return label;
        },
        anchor: 'end',
        offset: -20,
        align: 'start',
      });
      set(optionList, 'layout.padding.top', 20);
    }

    datasets.forEach((item) => {
      // Only show yAxis if datasets has 1 yAxisID value, valueDisplay is multiple and chart.displayYAxis is =true
      const shoudShowYAxis =
        uniqYAxisId.length === 1 && valueDisplay === 'multiple' && displayYAxisList.includes(item.yAxisID);
      if (!shoudShowYAxis) {
        set(optionList, `scales.${item.yAxisID}`, cloneDeep(defaultOptions).STACKED_COLUMN.scales.yAxes);
        set(optionList, `scales.${item.yAxisID}.display`, false);
        set(optionList, `scales.${item.yAxisID}.ticks.callback`, tool.callbacks.callback);
        const maxYAxis = toValue(get(mapping, [item.key, 'valueGetter', 'chart.rangeMax']), {});
        const minYAxis = toValue(get(mapping, [item.key, 'valueGetter', 'chart.rangeMin']), {});
        if (maxYAxis) {
          set(optionList, `scales.yAxes.max`, maxYAxis);
          set(optionList, `scales.${item.yAxisID}.max`, maxYAxis);
        }
        if (minYAxis) {
          set(optionList, `scales.yAxes.min`, minYAxis);
          set(optionList, `scales.${item.yAxisID}.min`, minYAxis);
        }
      } else {
        const maxYAxis = get(rangeYAxisList, [item.yAxisID, 'maxYAxis']);
        const minYAxis = get(rangeYAxisList, [item.yAxisID, 'minYAxis']);
        if (maxYAxis) {
          set(optionList, `scales.${item.yAxisID}.max`, maxYAxis);
        }
        if (minYAxis) {
          set(optionList, `scales.${item.yAxisID}.min`, minYAxis);
        }
      }
    });

    setDataChart({ dataList, optionList });
  }, [periodBackbone, isColumnStacked, sortBy, isDarkMode]);

  return (
    <ColumnChartResponsive
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      subTitle={subTitle}
      currency={currency}
      percent={percent}
      isHideTotal={isHideTotal}
      isHidePercent={isHidePercent}
      isColumnStacked={isColumnStacked}
      isSingle={isSingle}
      colorOption={colorOption}
      valueDisplay={valueDisplay}
      chartType={chartType}
      colKey={colKey}
      showLabelOnTop={showLabelOnTop}
    />
  );
};

export default ColumnChart;
