import { EIP_CONSTANT, NodeEditContext, useLog, eipRequest } from '@eip/next/lib/main';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
// import { actions } from '@eip/next/src/components/dashboard/dashboard-redux';
import { makeDashboard, TypeEDashBoard } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { Box, Button, Divider } from '@material-ui/core';
import { EventEmitter } from 'events';
import produce from 'immer';
import loadjs from 'loadjs';
import { debounce, get, groupBy, uniq, assign, omit, set, isEmpty } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { METRIC_CONFIG } from './chart-config/metric-campaign-config';

/**
 * ff.reuse_our_chart: start
 */
import { Dialog } from '@material-ui/core';
import ChartType from '@ep/insight-ui/elements/chart-type';
import {
  optionsType,
  optionsHeading,
  optionsColor,
  optionsColumnColor,
  optionsCohorts,
  optionsGroupBy,
  optionsDimension,
  sectionDisplay,
  columnStacked,
  optionsHiddenButton,
  sortOptions,
  optionsDisplayAllBlock,
} from '@ep/insight-ui/elements/chart-type/data-demo';

const optionsChart = [
  {
    label: 'Column',
    labelType: 'STACKING',
    value: 'col',
    icon: 'columnChart',
  },
  {
    label: 'Pie',
    labelType: 'Pie Types',
    value: 'pie',
    icon: 'pieChart',
  },
  {
    label: 'Lines',
    labelType: 'Lines Types',
    value: 'lines',
    icon: 'linesChart',
  },
  {
    label: 'Treemap',
    labelType: 'Treemap Types',
    value: 'treemap',
    icon: 'dashboard',
  },
  {
    label: 'Comparison',
    labelType: 'Comparison Types',
    value: 'comparison',
    icon: 'turnIntoGroup',
  },
];

/**
 * ff.reuse_our_chart: end
 */

/**
 * ff.keep_block_refreshing: start
 */
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import moment from 'moment';
/**
 * ff.keep_block_refreshing: end
 */

/**
 * ff.generate_etable_config:start
 */
import ETableConfig from '@ep/insight-ui/system/block/etable/etable-config';
import { nanoid } from 'nanoid';
import {
  exportInsightTable,
  customExportTable,
  handleDataRequest,
  getNamespaceStorefrontList,
  getNamespaceCountryList,
  getNamespaceMarketplaceList,
  getNamespaceList,
} from '../etable/addons/enhance-data-request';
/**
 * ff.generate_etable_config:end
 */

import {
  default as dataRequest,
  EpsiloTableObject,
  checkEpsiloTableEndpoint,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';
import { getInsightStorefrontList } from '@ep/insight-ui/system/block/etable/addons/enhance-data-request';

import EpsiloTemplateConfig from '../epsilo-template-config';

import {
  CalendarOption,
  getDateRangeFromOption,
  getCohortDateRangeFromOption,
  DateValue,
} from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';

import { isFormulaField } from '@ep/insight-ui/system/util/excel-formula';
import {
  getAvailableAggFunc as nextGetAvailableAggFunc,
  getDefaultAggFunc as nextGetDefaultAggFunc,
} from '@ep/insight-ui/system/util/aggregation';
import { getDefaultAggFunc } from '@eip/next/lib/main';

const log = useLog('chartlib:rich-tamle');
const ETABLE_PERSONALIZED_FIELDS = EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS;
export class EDashboardChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    const chartNodeData = Object.assign({ customAttributes: {} }, data);
    return <ChartShape nodeData={chartNodeData} container={dom}></ChartShape>;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <RichConfigForm data={data} onSubmit={handleSubmit} key={nodeId} nodeId={nodeId}></RichConfigForm>;
  }
}

const defaultConfiguration = METRIC_CONFIG;

const optionsGroupPeriod = [
  { label: 'Day', value: 'daily' },
  { label: 'Hourly', value: 'hourly' },
  { label: 'Week', value: 'weekly' },
  { label: 'Month', value: 'monthly' },
  { label: 'Quarter', value: 'quarterly' },
  { label: 'Year', value: 'yearly' },
  { label: 'All', value: 'all' },
];

const RichConfigForm = ({
  data,
  onSubmit,
  nodeId,
}: {
  data: NodeData;
  onSubmit: (...args: any[]) => void;
  nodeId: string;
}) => {
  const [openConfig, setOpenConfig] = React.useState(false);
  const [config, setConfig] = React.useState(data);
  const [loadingChart, setLoadingChart] = React.useState(false);

  const onValueChange = React.useCallback(
    debounce((config) => {
      const finConfig = produce(config, (draft) => {
        draft._editorId = `updated_${Date.now()}`;
        set(draft, 'view.combinator.filter', config.filter);
        set(draft, 'view.combinator.groupBy', config.groupBy);

        get(config, 'views').forEach((v, index) => {
          set(draft, ['views', index, 'combinator', 'groupBy'], config.groupBy);
          set(draft, ['views', index, 'combinator', 'filter'], config.filter);
        });

        const hiddenButton = get(config, 'chartConfig.config.hiddenButton', []);
        if (hiddenButton.length > 0) {
          set(draft, 'excludedUserCustomization', hiddenButton.concat('groupBy'));
        }
      });
      setConfig(finConfig as NodeData['customAttributes']);
      onSubmit(finConfig);
      setOpenConfig(false);
    }, 700),
    [],
  );

  const handleClickConfiguration = () => {
    // dispatch(actions.setEditorMode({ mode: 'advance_json_edit' }));
    setOpenConfig(true);
  };

  const handleClose = () => {
    // dispatch(actions.setEditorMode({ mode: 'select' }));
    setOpenConfig(false);
  };
  const editorBtn = React.useRef();

  const handleChartConfig = (cConfig) => {
    const chartConfig = {
      // chart: {
      //   headers: chartRef.current.dateTimes,
      //   rows: chartRef.current.newChartData,
      // },
      config: cConfig,
    };

    const finConfig = produce(config, (draft) => {
      draft._editorId = `updated_${Date.now()}`;
      draft.chartConfig = chartConfig;
      set(
        draft,
        'system.hiddenComponents',
        data.system.hiddenComponents
          .filter((el) => optionsHiddenButton.every((ele) => ele.value !== el))
          .concat(chartConfig.config.hiddenButton),
      );
      const hiddenButton = get(chartConfig, 'config.hiddenButton', []);
      if (hiddenButton.length > 0) {
        set(draft, 'excludedUserCustomization', hiddenButton.concat('groupBy'));
      }
      const externalFilters = get(chartConfig, ['config', 'externalFilters'], []);
      if (ff.expose_property_etable) {
        const externalProperties = get(chartConfig, ['config', 'externalProperties'], []);
        set(draft, ['system', 'externalProperties'], externalProperties);
      }
      if (externalFilters.length > 0) {
        set(draft, ['system', 'externalFilters'], externalFilters);
        const specialFilters = { ...config.specialFilters };
        Object.keys(specialFilters).forEach((key) => {
          if (!externalFilters.includes(key)) delete specialFilters[key];
        });
        set(draft, 'specialFilters', specialFilters);
      }
    });

    setConfig(finConfig);
    onSubmit(finConfig);
  };

  const defaultOptionsHiddenButton = React.useMemo(() => {
    return data.system.hiddenComponents.filter((el) => optionsHiddenButton.some((ele) => ele.value == el));
  }, []);

  const dimensionOptions = React.useMemo(() => {
    const dimensions = Object.values(config.mapping).filter((i) => i.propertyType === 'dimension');
    return uniq(
      dimensions.reduce((carry, dim) => {
        return carry.concat(Object.values(dim.valueGetter));
      }, []),
    )
      .map((i) => {
        return {
          label: i,
          value: i,
        };
      })
      .concat({
        label: 'none',
        value: '_all',
      });
  }, [config]);

  return (
    <React.Fragment>
      <EpsiloTemplateConfig nodeId={nodeId} config={config} chartId={'eChartv2'} />
      <Box p={1}>
        <Button variant="contained" color="secondary" onClick={handleClickConfiguration}>
          Advanced configuration
        </Button>
      </Box>
      <ETableConfig open={openConfig} onClose={handleClose} config={config} onSubmit={onValueChange} />
      <Box p={1}>
        {loadingChart ? (
          <Box>
            <LoadingIcon color={'#253746'} />
          </Box>
        ) : ff.reuse_our_chart ? (
          <ChartType
            optionsGroupPeriod={optionsGroupPeriod}
            optionsChart={optionsChart}
            optionsType={optionsType}
            optionsHeading={optionsHeading}
            optionsColor={optionsColor}
            optionsColumnColor={optionsColumnColor}
            optionsCohorts={optionsCohorts}
            optionsGroupBy={optionsGroupBy}
            optionsDimension={dimensionOptions}
            sectionDisplay={sectionDisplay}
            optionsColumnStacked={columnStacked}
            optionsHiddenButton={optionsHiddenButton}
            sortOptions={sortOptions}
            optionsDisplayAllBlock={optionsDisplayAllBlock}
            defaultOptionsHiddenButton={defaultOptionsHiddenButton}
            onSubmit={handleChartConfig}
            nodeData={config}
          />
        ) : (
          <Button variant="contained" color="secondary" className="trigger-highchart-editor" ref={editorBtn}>
            Chart setup
          </Button>
        )}
      </Box>
    </React.Fragment>
  );
};

const ChartShape = ({ nodeData, container }: { nodeData: NodeData; container: any }) => {
  const nodeEditContext = React.useContext(NodeEditContext);
  const chartConfig = get(nodeData.customAttributes, 'chartConfig', {});

  let lastUpdated;
  if (ff.keep_block_refreshing) {
    lastUpdated = React.useRef(moment().valueOf());
  }

  const handleChangeConfig = React.useCallback(
    (config: any) => {
      nodeEditContext.onUpdateCustomAttributes(nodeData, config);
    },
    [nodeData],
  );

  const config = getConfig(nodeData);

  config.addons = {
    ...config.addons,
    'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
      const specialFilters = backbone.getConfig('specialFilters') || {};

      const formattedSpecialFilters = Object.values(specialFilters).map((filter) => ({
        dataType: filter.dataType,
        field: filter.queryField,
        operator: filter.queryType,
        value: filter.queryValue,
      }));

      if (formattedSpecialFilters.length > 0) {
        return {
          ...params,
          filter: {
            ...params.filter,
            combinator: params.filter?.combinator || 'and',
            filters: [...(params.filter?.filters || []), ...formattedSpecialFilters],
          },
        };
      }

      return produce(params, (draft) => {
        draft.metrics = params.metrics.map((i) => {
          if (String(i).includes('@today')) {
            return String(i).replace('@today', '@' + moment().format('YYYY-MM-DD'));
          }
          return i;
        });
      });

      return params;
    },
  };

  if (
    checkEpsiloTableEndpoint(get(config, 'configuration.endpoint.GET_TABLE_DATA', ''), EpsiloTableObject.PERFORMANCE)
  ) {
    const mappings = get(config, 'configuration.mapping', {});
    Object.keys(mappings)
      .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
      .forEach((colKey) => {
        let apiFilterValue;
        if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
        else if (get(mappings, [colKey, 'valueGetter', 'id'], ''))
          apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
        else
          apiFilterValue = get(
            mappings, //
            [colKey, 'valueGetter', 'value'],
            colKey,
          );
        config.addons[`filterValue.${colKey}`] = async () => {
          try {
            const result = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_listing.jsp', {
              attributes: [apiFilterValue],
            });

            const options = get(result, 'data.rows', []);

            return Promise.resolve({
              data: options
                .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
              success: true,
              message: 'OK',
            });
          } catch (e) {
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        };
      });
    config.addons = {
      ...config.addons,
      'filterValue.country': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

          return Promise.resolve({
            data: availableCountryList.map((c) => ({
              label: c,
              value: c,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableMarketplaceList = [
            ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
          ];

          return Promise.resolve({
            data: availableMarketplaceList.map((m) => ({
              label: m,
              value: m,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const data = Object.values(storefrontList).map((el: any) => {
            return {
              label: [el.marketplace_code, el.country_code, el.storefront_name].join(' / '),
              value: el.id,
              payload: {
                sid: el.storefront_sid,
                country: {
                  code: el.country_code,
                },
                channel: {
                  id: el.marketplace_code,
                },
              },
            };
          });

          return Promise.resolve({
            data,
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'system.export': (params, backbone) => {
        return exportInsightTable(params, backbone);
      },
    };
  }

  if (checkEpsiloTableEndpoint(get(config, 'configuration.endpoint.GET_TABLE_DATA', ''))) {
    config.addons = {
      ...config.addons,
      'externalFilter.getFields': (backbone) => {
        const specialFilterFields = ['country', 'marketplace', 'storefront', 'brand', 'category_1'];

        const externalFilters = backbone.getConfig('system.externalFilters', []);
        return backbone
          .getAvailableColumns()
          .reduce((a, b) => {
            if (externalFilters.includes(b.id)) {
              return [
                ...a,
                {
                  name: b.name,
                  id: b.id,
                  field: b.filterField,
                  dataType: b.dataType || 'string',
                  propertyType: b.propertyType,
                },
              ];
            }
            return a;
          }, [])
          .sort((a, b) => {
            if (specialFilterFields.findIndex((el) => el === a.id) === -1) return 1;
            if (specialFilterFields.findIndex((el) => el === b.id) === -1) return -1;
            return (
              specialFilterFields.findIndex((el) => el === a.id) - specialFilterFields.findIndex((el) => el === b.id)
            );
          });
      },
    };
  }

  if (checkEpsiloTableEndpoint(get(config, 'configuration.endpoint.GET_TABLE_DATA', ''))) {
    config.addons = {
      ...config.addons,
      'post.getTableData.callback': (params, response, backbone) => {
        backbone.changeConfig('tableParams', params);
      },
      'system.export': (params, backbone) => {
        return customExportTable(params, backbone);
      },
    };
  }

  const queryParams = getQueryParams(get(config, ['configuration', 'endpoint', 'GET_TABLE_DATA'], ''));
  if (queryParams.namespace) {
    const mappings = get(config, ['configuration', 'mapping'], {});
    const endpoint = get(config, ['configuration', 'endpoint', 'GET_TABLE_DATA'], '');
    const isUniversalPrefix = endpoint.includes('universalPrefix');
    Object.keys(mappings)
      .filter(
        (colKey) =>
          get(mappings, [colKey, 'propertyType'], '') === 'dimension' ||
          get(mappings, [colKey, 'selectionFilter'], false),
      )
      .forEach((colKey) => {
        const addonKey = `filterValue.${colKey}`;

        config.addons[addonKey] = async (_, __, ___, getColumnFields) => {
          const result = await getNamespaceList({
            isUniversalPrefix,
            namespace: queryParams.namespace,
            field: colKey,
            config,
            getColumnFields,
          });
          return result;
        };
      });

    config.addons = {
      ...config.addons,
      'filterValue.storefront': async () => {
        const mapping = get(config, ['configuration', 'mapping'], {});
        const result = await getNamespaceStorefrontList(queryParams.namespace, mapping);
        return result;
      },
    };
  }

  if (config) {
    return makeDashboard({
      config,
      changeConfiguration: handleChangeConfig,
      tableBackboneHook: useTableBackbone,
      tableContext: TableBackboneContext,
      chartConfig,
    });
  }

  return <h1>chartv2</h1>;
};

const mappingData = (result) => {
  let rows = result.data.rows;
  const masterDataHeaders = get(result, 'data.masterData.storefront.headers', []);
  const masterData = get(result, 'data.masterData.storefront.rows', []).reduce((carry, i: any[]) => {
    const id = i[0];
    return {
      ...carry,
      [id]: i.reduce((obj, v, index) => {
        return { ...obj, [masterDataHeaders[index]]: v };
      }, {}),
    };
  }, {});

  rows = rows.map((r) => {
    const sfId = r['storefront.id'];
    return {
      ...masterData[sfId],
      ...r,
    };
  });

  return { rows, masterDataHeaders };
};

function checkIfTrendingApi(endpoint) {
  return /live-dashboard\/trending/i.test(endpoint);
}

const getAggFunc = (key) => {
  const aggFuncList = [
    'NONE',
    'UNIQUE',
    'SUM',
    'AVG',
    'MIN',
    'MAX',
    'COUNT_ALL',
    'COUNT_VALUES',
    'COUNT_UNIQUE',
    'COUNT_EMPTY',
    'COUNT_NOT_EMPTY',
    'PERCENT_EMPTY',
    'PERCENT_NOT_EMPTY',
    'RANGE',
  ].map((el) => {
    let fieldId = el;
    if (el === 'NONE') fieldId = 'NULL';
    if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
    return {
      id: el.toLowerCase(),
      requestQuery: fieldId,
    };
  });

  const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce((carry, i) => ({ ...carry, [i.id]: i }), {});

  return aggFuncByKey[key]
    ? aggFuncByKey[key]
    : {
        id: key,
        requestQuery: key.toUpperCase(),
      };
};

const getConfig = (nodeData: NodeData) => {
  const customConfig = get(nodeData, 'customAttributes', {});
  let finConfig = produceValidConfig(customConfig);
  finConfig = popuplateConfigWithCurrentView(customConfig);

  const isCohort = get(customConfig, 'chartConfig.config.timelineCohorts', false);
  const calendarCohort = get(customConfig, 'calendarCohort', '');
  const dateRange = get(customConfig, 'dateRange', {});
  const calStartDate = moment(dateRange?.dateFrom, 'YYYY-MM-DD');
  const calEndDate = moment(dateRange?.dateTo, 'YYYY-MM-DD');
  const diffDays = calEndDate.diff(calStartDate, 'day');
  let calStartDateCohort, calEndDateCohort;

  const queryParams = getQueryParams(finConfig.endpoint?.GET_TABLE_DATA);

  return {
    apiRequest: {},
    tableType: TypeEDashBoard.METRIC,
    requestFilter: {
      currency: 'USD',
    },
    configuration: finConfig,
    addons: {
      'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
        const endpoint = backbone.config.endpoint.GET_TABLE_DATA;
        if (checkEpsiloTableEndpoint(endpoint) || checkIfTrendingApi(endpoint)) {
          const columnOrder = get(backbone, ['config', 'columnOrder'], []);
          const mapping = get(backbone, ['config', 'mapping'], {});
          const config = get(backbone, 'config', {});

          const { namespace } = getQueryParams(endpoint);

          if (namespace) {
            const defaultAggField = columnOrder.reduce((carry, cid: any) => {
              const c = mapping[cid];
              if (c && c.defaultCalculated) {
                const qFields = Object.values(c.valueGetter).filter((f) => !isFormulaField(f));
                qFields.forEach((f: string) => {
                  if (!carry[f]) carry[f] = c.defaultCalculated;
                });
              }
              return carry;
            }, {});

            const configAggregations = Object.keys(mapping).reduce((carry, key) => {
              const keyValueGetter = get(mapping, [key, 'valueGetter', 'value'], '');
              const func = get(mapping, [key, 'defaultCalculated'], null);
              if (func) {
                return {
                  ...carry,
                  [keyValueGetter]: func,
                };
              }
              return carry;
            }, {});

            const aggregations = [].concat(
              params.attributes
                .map((i) => {
                  if (isFormulaField(i)) return null;
                  const defaultAggFunc = defaultAggField[i] || nextGetDefaultAggFunc('attribute').id;
                  const aggFunc: AggFuncType = getAggFunc(
                    configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc),
                  );
                  return {
                    field: i,
                    func: String(aggFunc.requestQuery).replace(/_f_/g, i),
                  };
                })
                .filter((i) => !isEmpty(i)),
              params.metrics
                .map((i) => {
                  if (isFormulaField(i)) return null;
                  const defaultAggFunc = defaultAggField[i] || getDefaultAggFunc('metric').id;
                  const aggFunc: AggFuncType = getAggFunc(
                    configAggregations[i] || get(config, ['groupBy', 'aggregations', i, 'func'], defaultAggFunc),
                  );

                  return {
                    field: i,
                    func: String(aggFunc.requestQuery).replace(/_f_/g, i),
                  };
                })
                .filter((i) => !isEmpty(i)),
            );

            params.groupBy = {
              column: get(params, 'groupBy.dimensions'),
              dimensions: get(params, 'groupBy.dimensions'),
              aggregations,
            };
          }

          const isPieChart = get(backbone, 'config.chartConfig.config.chartType') === 'pie';
          const groupPeriodConfig = get(backbone, 'config.chartConfig.config.groupPeriod', undefined);
          const groupPeriod = get(backbone, 'config.groupPeriod', undefined);
          const displayAllBlock = get(backbone, 'config.chartConfig.config.displayAllBlock', undefined);
          const result = await handleDataRequest(
            {
              ...params,
              isSummary: isPieChart ? true : undefined,
              groupPeriod: groupPeriod ? (displayAllBlock ? 'all' : groupPeriod) : groupPeriodConfig,
              groupAll: displayAllBlock ? true : undefined,
              pagination: {
                limit: moment(params.to).diff(params.from, 'day') + 1,
                page: 1,
              },
            },
            originRequest,
            backbone,
          );

          const today = moment().format('YYYY-MM-DD');

          const rowsMetricsAsColumns = result.data.rows;

          const metricList = params.metrics;

          let rowMetricsAsValue = [];
          const mapApiFieldMetric = Object.entries(backbone.config.mapping).reduce(
            (carry, [k, v]: [k: string, v: any]) => {
              if (v.propertyType === 'metric') {
                let apiField: string = get(v, 'valueGetter.value', '');
                if (apiField.includes('@today')) {
                  apiField = apiField.replace('@today', '@' + today);
                }
                return { ...carry, [apiField]: k };
              }
              return carry;
            },
            {},
          );
          const currencyReq = get(params, 'hiddenFilter.currency', '');

          metricList.forEach((m) => {
            rowMetricsAsValue = rowMetricsAsValue.concat(
              rowsMetricsAsColumns.map((row) => {
                const rs = get(row, 'resourceMetric', []).find((ele) => ele.value === m);
                const symbol = get(rs, 'prefix_value', '');
                const rowWithNoMetric: any = omit(row, metricList.concat(metricList.map((el) => `${el}_cohort`)));
                rowWithNoMetric.metric = mapApiFieldMetric[m];
                rowWithNoMetric.value = row[m];
                rowWithNoMetric.value_cohort = row[`${m}_cohort`];
                rowWithNoMetric.datetime_cohort = row['datetime_cohort'];
                rowWithNoMetric.currency = symbol[0] === '$' ? currencyReq : symbol[0];
                rowWithNoMetric.total = get(row, `total["${m}"]`, 0);
                rowWithNoMetric.cohort_total = get(row, `cohort_total["${m}"]`, 0);
                return rowWithNoMetric;
              }),
            );
          });

          set(result, 'data.rows', rowMetricsAsValue);

          return result;
        }

        const mapping = backbone.config.mapping;
        const metricList = Object.keys(mapping)
          .filter((k) => mapping[k].propertyType === 'metric')
          .map((k) => mapping[k]);
        const tsMetricList = metricList.filter((i) => i.cellFormat === 'metricTraction');
        if (tsMetricList.length === 0) {
          return originRequest(params);
        } else {
          if (String(backbone.config.endpoint.GET_TABLE_DATA).includes(EIP_CONSTANT.API_HOST.API_DATA_CENTER)) {
            if (isCohort && calendarCohort) {
              if (typeof calendarCohort == 'string') {
                switch (calendarCohort) {
                  case 'previous':
                    calStartDateCohort = moment(calStartDate).subtract(diffDays + 1, 'days');
                    calEndDateCohort = moment(calEndDate).subtract(diffDays + 1, 'days');
                    break;
                  case 'last_month':
                    calStartDateCohort = moment(calStartDate).subtract(1, 'months');
                    calEndDateCohort = moment(calEndDate).subtract(1, 'months');
                    break;
                  case 'last_year':
                    calStartDateCohort = moment(calStartDate).subtract(1, 'years');
                    calEndDateCohort = moment(calEndDate).subtract(1, 'years');
                    break;
                  default:
                    break;
                }
              } else {
                calStartDateCohort = moment(calendarCohort.dateFrom, 'YYYY-MM-DD');
                calEndDateCohort = moment(calendarCohort.dateTo, 'YYYY-MM-DD');
              }
            }

            const nuParams = {
              ...params,
              filter: {
                ...get(params, 'filter', { combinator: 'AND', filters: [] }),
                filters: [
                  ...get(params, 'filter.filters', []),
                  { field: 'created_datetime', operator: '>=', value: params.from },
                  { field: 'created_datetime', operator: '<=', value: params.to },
                ],
              },
            };

            const nuParamsCohorts = {
              ...params,
              filter: {
                ...get(params, 'filter', { combinator: 'AND', filters: [] }),
                filters: [
                  ...get(params, 'filter.filters', []),
                  { field: 'created_datetime', operator: '>=', value: moment(calStartDateCohort).format('YYYY-MM-DD') },
                  { field: 'created_datetime', operator: '<=', value: moment(calEndDateCohort).format('YYYY-MM-DD') },
                ],
              },
            };

            if (isCohort && calendarCohort) {
              const [result, resultCohorts] = await Promise.all([
                originRequest(nuParams),
                originRequest(nuParamsCohorts),
              ]);
              const { rows: rowsCohort } = mappingData(resultCohorts);
              const { rows: rowsCurrent, masterDataHeaders } = mappingData(result);
              const finResponse = {
                success: true,
                data: {
                  headers: [...masterDataHeaders],
                  rows: [{ rowsCohort }, { rowsCurrent }],
                },
              };
              return finResponse;
            }

            console.info({ nuParams });
            const result = await originRequest(nuParams);
            console.info({ result });

            const headers = ['metric', 'datetime'];
            const { rows, masterDataHeaders } = mappingData(result);

            const finResponse = {
              success: true,
              data: {
                ...result.data,
                headers: [...masterDataHeaders, ...headers],
                rows,
              },
            };

            // console.info(JSON.stringify({ finResponse }, null, 2));

            return finResponse;
          } else {
            const dateStart = moment(params.from, 'YYYY-MM-DD');
            const dateEnd = moment(params.to, 'YYYY-MM-DD');

            const timePoints = new Array(dateEnd.diff(dateStart, 'day') + 1)
              .fill(0)
              .map((i, index) => moment(dateStart).add(index, 'day'));

            const tsResult = {
              headers: [],
              rows: [],
            };
            for (const tp of timePoints) {
              const requestParams = {
                ...params,
                from: tp.format('YYYY-MM-DD'),
                to: tp.format('YYYY-MM-DD'),
              };
              const result = await originRequest(requestParams);
              tsResult.headers = result.data.headers.concat('datetime');
              tsResult.rows = tsResult.rows.concat(
                result.data.rows.map((r) => {
                  return result.data.headers.reduce(
                    (acc, h) => {
                      return { ...acc, [h]: r[h] };
                    },
                    { datetime: tp.format('YYYY-MM-DD') },
                  );
                }),
              );
            }

            console.info(tsResult);

            return {
              success: true,
              data: tsResult,
            };
          }
        }
      },
      'datasource.getRowsParams': async ({ params }, config) => {
        return produce(params, (draft) => {
          draft.hiddenFilter.currency = config.currency || params.hiddenFilter.currency;
          draft.groupPeriod = config.groupPeriod;
        });
      },
      ...(checkEpsiloTableEndpoint(finConfig.endpoint?.GET_TABLE_DATA, EpsiloTableObject.PERFORMANCE)
        ? {
            'filterValue.country': async (payload?: any, forceReload?: boolean) => {
              try {
                const [{ data: countryList }, storefrontList] = await Promise.all([
                  dataRequest.getCountry(),
                  getInsightStorefrontList(),
                ]);

                const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

                return Promise.resolve({
                  data: countryList.filter((country) => availableCountryList.includes(country.value)),
                  success: true,
                  message: 'OK',
                });
              } catch (e) {
                return Promise.resolve({
                  data: [],
                  success: false,
                  message: '',
                });
              }
            },
            'filterValue.marketplace': async (payload?: any, forceReload?: boolean) => {
              try {
                const [{ data: marketplaceList }, storefrontList] = await Promise.all([
                  dataRequest.getMarketplace(),
                  getInsightStorefrontList(),
                ]);

                const availableMarketplaceList = [
                  ...new Set(Object.values(storefrontList).map((el) => el.marketplace_code)),
                ];

                return Promise.resolve({
                  data: marketplaceList.filter((marketplace) => availableMarketplaceList.includes(marketplace.value)),
                  success: true,
                  message: 'OK',
                });
              } catch (e) {
                return Promise.resolve({
                  data: [],
                  success: false,
                  message: '',
                });
              }
            },
            'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
              try {
                const [{ data: countryList }, { data: marketplaceList }, storefrontList] = await Promise.all([
                  dataRequest.getCountry(),
                  dataRequest.getMarketplace(),
                  getInsightStorefrontList(),
                ]);

                const data = Object.values(storefrontList).map((el) => {
                  const country = countryList.find((ele) => ele.value === el.country_code);
                  const marketplace = marketplaceList.find((ele) => ele.value === el.marketplace_code);
                  return {
                    label: [marketplace.label, country.label, el.storefront_name].join(' / '),
                    value: el.id,
                    payload: {
                      channel: { id: marketplace.value, name: marketplace.label },
                      country: { code: country.value, name: country.label },
                      sid: el.storefront_sid,
                    },
                  };
                });

                return Promise.resolve({
                  data,
                  success: true,
                  message: 'OK',
                });
              } catch (e) {
                return Promise.resolve({
                  data: [],
                  success: false,
                  message: '',
                });
              }
            },
          }
        : {}),
      ...(queryParams.namespace
        ? {
            'filterValue.storefront': async () => {
              const result = await getNamespaceStorefrontList(queryParams.namespace);
              return result;
            },
            'filterValue.country': async () => {
              const result = await getNamespaceCountryList(queryParams.namespace);
              return result;
            },
            'filterValue.marketplace': async () => {
              const result = await getNamespaceMarketplaceList(queryParams.namespace);
              return result;
            },
          }
        : {}),
    },
  };
};

function produceValidConfig(customConfig) {
  const dimensions = Object.entries(customConfig.mapping).reduce((carry, [k, c]: [any, any]) => {
    if (c.propertyType === 'dimension') {
      return carry.concat(k);
    }
    return carry;
  }, []);
  const groupPeriodConfig = get(customConfig.chartConfig, 'config.groupPeriod', undefined);

  return produce(customConfig, (draft) => {
    draft.dimension = dimensions;
    draft.groupPeriod = groupPeriodConfig;
    set(draft, 'view.combinator.properties.dimension', dimensions);
    const isHideFilter = get(customConfig, 'excludedUserCustomization', []).includes('filter');
    if (isHideFilter) {
      set(draft, 'view.combinator.filter', customConfig.filter);
    }
    get(customConfig, 'views', []).forEach((v, index) => {
      set(draft, ['views', index, 'combinator', 'properties', 'dimension'], dimensions);
      if (isHideFilter) {
        set(draft, ['views', index, 'combinator', 'filter'], customConfig.filter);
      }
    });
    if (!!get(customConfig, 'endpoint.GET_TABLE_DATA')) {
      const endpoint = get(customConfig, 'endpoint.GET_TABLE_DATA', '').replace(
        '{ep.datacenter}',
        EIP_CONSTANT.API_HOST.API_DATA_CENTER,
      );
      set(draft, 'endpoint.GET_TABLE_DATA', endpoint);
      if (checkEpsiloTableEndpoint(endpoint) && !get(customConfig, ['system', 'externalFilters'], null)) {
        set(draft, ['system', 'externalFilters'], ['country', 'marketplace', 'storefront']);
      }
      if (ff.expose_property_etable) {
        if (!get(customConfig, ['system', 'externalProperties'], null)) {
          set(draft, ['system', 'externalProperties'], ['metric']);
        }
      } else {
        set(draft, ['system', 'externalProperties'], undefined);
      }
    }

    // Set dateRange, cohortDateRange based on calendarOption, e.g: TODAY, YESTERDAY, ...
    const calendarOption: CalendarOption = get(customConfig, 'calendarOption', null);
    const calendarDateRange: DateValue = get(customConfig, 'dateRange', null);
    if (checkIfTrendingApi(get(customConfig, 'endpoint.GET_TABLE_DATA'))) {
      draft.refreshInterval = 5 * 60 * 1000;
      draft.system.hiddenComponents = get(customConfig, 'system.hiddenComponents', []).concat(['timeline']);
    }
    if (calendarOption && calendarDateRange) {
      const newDateRange = getDateRangeFromOption(calendarOption, calendarDateRange);
      set(draft, ['dateRange'], getDateRangeFromOption(calendarOption, calendarDateRange));

      const calendarCohortOption: CalendarOption = get(customConfig, 'calendarCohort', '');
      const calendarCohortDateRange: DateValue = get(customConfig, 'cohortDateRange', null);
      set(
        draft,
        ['cohortDateRange'],
        getCohortDateRangeFromOption(calendarCohortOption, calendarCohortDateRange, newDateRange),
      );
    }
  });
}

function popuplateConfigWithCurrentView(config, view = null) {
  // apply view to top level
  let viewInfo = view;
  if (!view) viewInfo = get(config, 'view', {});

  const validConfig = produce(config, (draft) => {
    Object.keys(get(config, 'view.combinator', {})).forEach((key) => {
      if (key === 'properties') {
        Object.keys(config.view.combinator[key]).forEach((propKey) => {
          draft[propKey] = config.view.combinator[key][propKey];
        });
      } else if (key !== 'runtime') {
        draft[key] = config.view.combinator[key];
      }
    });
  });

  return validConfig;
}

export { getConfig };
