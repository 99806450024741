import * as React from 'react';
import { Modal, makeStyles, Grid, Box, Button, Dialog } from '@material-ui/core';

import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import FormControlInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-input';

import { useVerifyOtp } from './hooks/use-verify-otp';

import Success from './images/Success';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  modalContainer: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: '796px',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
    '&.last': {
      width: '440px',
      color: '#253746',
      padding: '32px',
      textAlign: 'center',
      rowGap: '8px',
    },
    '& .eip1-MuiSvgIcon-root': {
      width: 'unset',
    },
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  formPassword: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkWord: {
    color: '#0369C7',
    cursor: 'pointer',
  },
  credentialDescription: {
    color: '#8C98A4',
    paddingBottom: '16px',
  },
  selectStorefrontLabel: {
    color: '#8C98A4',
    marginBottom: '8px',
  },
  section: {
    marginTop: '24px',
  },
  expanded: {
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      marginBottom: '10px',
    },
  },
  error: {
    '& .eip1-MuiFormHelperText-contained p, & .eip1-MuiFormHelperText-contained svg': {
      color: '#D4290D',
    },
  },
  helper: {
    '& > p.eip1-MuiFormHelperText-contained': {
      display: 'none',
    },
  },
  dialog: {
    width: '400px',
    height: '287px',
  },
  resendOtp: {
    fontSize: '10px',
    lineHeight: '12px',
  },
  textN100Color: {
    color: '#253746',
  },
  textN80Color: {
    color: '#8C98A4',
  },
  fontWeight500: {
    fontWeight: 500,
  },
  resendOtpAction: {
    color: '#8C98A4',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '1px solid #8C98A4',
    },
  },
  disableEvent: {
    pointerEvents: 'none',
  },
  textError: {
    color: '#D4290D',
  },
  textRetry: {
    color: '#0B4AA8',
    fontWeight: 500,
    cursor: 'pointer',
    '&:hover': {
      borderBottom: '1px solid #0B4AA8',
    },
  },
});

const VerifyOTPFOrm = ({ open, onClose, storefrontId, backbone, initialStep }: any) => {
  const [step, setStep] = React.useState(initialStep);

  React.useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  const { handleClose } = useVerifyOtp({ onClose, step, setStep, backbone });

  return (
    <Modal open={open} onClose={handleClose}>
      <>
        {step === 1 && <VerifyOTPStep onClose={handleClose} setStep={setStep} storefrontId={storefrontId} />}
        {step === 2 && <SuccessStep onClose={handleClose} backbone={backbone} />}
      </>
    </Modal>
  );
};

const ModalStepTemplate = ({
  onClose,
  children,
  title,
  expansionLabel,
  actionLabel = 'Finish',
  action,
  disableActionBtn = false,
  requesting = false,
}: any) => {
  const classes = useStyles();

  return (
    <Grid container direction={'column'} className={classes.modalContainer}>
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>{title}</span>
      </Grid>
      <Grid item xs={12} className={classes.section}>
        <ExpansionForm
          label={expansionLabel}
          level={'2'}
          detailContentSize={'full'}
          defaultExpanded={true}
          className={classes.expanded}
        >
          {children}
        </ExpansionForm>
      </Grid>
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} back`}
              onClick={onClose}
            >
              <span className={`${classes.text} textButton`}>Back</span>
            </Button>
          </Box>
          <Box>
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} cancel`}
              onClick={onClose}
            >
              <span className={`${classes.text} textButton`}>Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
              onClick={action}
              disabled={requesting || disableActionBtn}
            >
              {actionLabel}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

const VerifyOTPStep = ({ onClose, setStep, storefrontId }: any) => {
  const classes = useStyles();

  const {
    otp,
    setOtp,
    requesting,
    errorMessage,
    setErrorMessage,
    timeToResend,
    handleResendOtp,
    handleRequestCookie,
    defaultTimeToResend,
  } = useVerifyOtp({ storefrontId, setStep });

  return (
    <ModalStepTemplate
      title={'Verify OTP code to re-connect to Seller Center'}
      expansionLabel={'Why you need to do this?'}
      actionLabel={'Reconnect'}
      action={() => handleRequestCookie(otp)}
      onClose={onClose}
      disableActionBtn={!otp}
      requesting={requesting}
    >
      <Grid container xs={12}>
        <Grid item xs={12}>
          <FormControlInput
            label={'OTP code'}
            classNameContainer={`${classes.formControl} input`}
            placeholder={'Please enter your OTP code'}
            defaultValue={otp}
            onChangeText={(value) => {
              setOtp(value);
            }}
          />
          <Grid container xs={12}>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              {errorMessage ? (
                <div>
                  <span className={classes.textError}>{errorMessage} </span>
                  <span
                    className={classes.textRetry}
                    onClick={() => {
                      setOtp('');
                      setErrorMessage('');
                    }}
                  >
                    Retry
                  </span>
                </div>
              ) : (
                <div className={classes.resendOtp}>
                  <span className={classes.textN100Color}>Didn’t receive a code? </span>
                  <span
                    className={`${classes.resendOtpAction} ${timeToResend && classes.disableEvent}`}
                    onClick={handleResendOtp}
                  >
                    Resend OTP ({timeToResend === 0 ? defaultTimeToResend : timeToResend}s)
                  </span>
                </div>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.credentialDescription}>
            <p>
              Please enter the OTP that send to your mobile phone/ email address to complete verify your account. The
              OTP code will be expired within <span className={classes.textN100Color}>15 minutes</span>, so make sure to
              enter it soon.
            </p>
            <p>This OTP will be used to access your seller center account.</p>
            <p>
              Visit <span className={classes.linkWord}>Privacy Policy</span> for more information on how we protect your
              data.
            </p>
          </div>
        </Grid>
      </Grid>
    </ModalStepTemplate>
  );
};

const SuccessStep = ({ onClose, backbone }: any) => {
  const classes = useStyles();

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      backbone.reloadData('table');
      onClose();
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  return (
    <Grid container direction={'column'} alignItems={'center'} className={`${classes.modalContainer} last`}>
      <Success />
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>Your store is reconnected</span>
      </Grid>
      <Grid item xs={12}>
        <span className={`${classes.text}`}>
          We need a little time to update your lastest data from the Seller Center.{' '}
        </span>
      </Grid>
    </Grid>
  );
};

export default VerifyOTPFOrm;
