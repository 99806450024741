import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { DashboardIntraday } from '@ep/insight-ui/elements/eDashboard/eDashboard.stories';

class EChart implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <div style={{ padding: '12px' }}>
        <DashboardIntraday />
      </div>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'eChart',
  label: 'Chart',
  blockComponent: EChart,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 15,
  },
};
