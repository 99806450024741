import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Star = (props: SvgIconProps) => (
  <SvgIcon {...props} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.52473 3.37003L10.9063 3.58746C11.9509 3.67944 12.3938 4.99233 11.5916 5.70314L9.703 7.44251C9.55258 7.58467 9.48573 7.80209 9.52751 8.01115L10.079 10.5115C10.2545 11.3226 9.61108 12 8.89242 12C8.6835 12 8.45788 11.9415 8.24896 11.8077L6.35203 10.5868C6.13475 10.4446 5.85063 10.4446 5.63336 10.5868L3.73642 11.8077C3.52751 11.9415 3.31024 12 3.09297 12C2.36595 12 1.73085 11.331 1.90634 10.5115L2.45787 8.01115C2.50801 7.80209 2.44116 7.58467 2.28239 7.44251L0.393805 5.70314C-0.383354 4.99233 0.0595429 3.67944 1.11247 3.58746L3.49408 3.37003C3.71971 3.35331 3.91191 3.20279 3.99548 2.99373L4.88127 0.760976C5.08183 0.250871 5.54144 0 6.00105 0C6.46066 0 6.92863 0.250871 7.12918 0.760976L8.02334 2.99373C8.1069 3.20279 8.2991 3.35331 8.52473 3.37003Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default Star;
