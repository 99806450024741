import moment from 'moment';
import React from 'react';
import * as _ from 'lodash';
import { ITreeMapDataChart } from '../type';
import BulletChartResponse from './bullet-chart';
import { funcConfigs } from './hooks';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { colorsChartLegend, defaultOptions, tool } from '../helper';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { ChartTabularData, AxisChartOptions, BulletChartOptions } from '@carbon/charts/interfaces';
type Props = {
  data: ChartTabularData;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
};
function TreeMapChart({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
}: Props) {
  const { defaultOptions, customHTML } = funcConfigs();

  const [dataChart, setDataChart] = React.useState<{ dataList: ChartTabularData; optionList: BulletChartOptions }>({
    dataList: [],
    optionList: defaultOptions,
  });

  React.useEffect(() => {
    const hashColors = {};
    const hashHeader = {};
    data.forEach((item, index) => {
      hashColors[item.group] = colors.charts[index];
      hashHeader[item.group] = item.title;
    });

    //Display option chart
    const { optionList } = dataChart;
    _.set(optionList, 'getFillColor', (group, label, data, defaultFillColor) => hashColors[group]);
    _.set(optionList, 'tooltip.customHTML', customHTML(hashHeader, hashColors));

    setDataChart({ dataList: data, optionList });
  }, [data]);
  return (
    <BulletChartResponse
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
    />
  );
}

export default TreeMapChart;
