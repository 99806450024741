import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, FormControl, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FieldSelectedValue, FilterOperatorType } from '.';
import Dropdown, { ChildrenDropdownProps } from '../../../dropdown-menu/dropdown';
import CalendarSingle from '../../../form-control/calendar/calendar-single';
import SelectForm from '../../../form-control/select-form';
import SelectFormGroup from '../../../form-control/select-form/select-form-group';
import SelectFormSearch, { SelectFormProp } from '../../../form-control/select-form/select-form-search-inside';
import TextForm from '../../../form-control/text-form';
import MenuList from '../../../list-control/menu-list';
import { DropdownMenuDataType } from '../../../list-control/menu-list/menu-list';
import { OptionSelectType } from '../../../list-control/type';
import Typography from '../../../text-style/Typography';
import { IFilterItem, TypeFilter, TypeGroupFilter } from './filter-form-group';

/**
 * ff.ag_grid_27:start
 */
import { ContainerResponsiveContext, NodeEditContext } from '@eip/next/lib/main';
/**
 * ff.ag_grid_27:end
 */

/**
 * ff.correct_filter_display:start
 */
import { Tooltip, withStyles } from '@material-ui/core';
import FilterSelection from '@ep/insight-ui/elements/table/table-actions/filter-selection';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { getQueryParams } from '@ep/insight-ui/system/backbone/data-source/common';
import { v4 as uuid } from 'uuid';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';

interface MenuActionProps extends ChildrenDropdownProps {
  index: number;
  isStaticGroup?: boolean;
}

/** Style */
const useStyles = makeStyles(() => ({
  groupRule: {
    gap: '8px',
    display: 'flex',
  },
  iconMore: {
    '& svg': {
      width: '3px',
    },
  },
  groupSub: {
    flexGrow: 3,
    padding: '5px 10px',
    background: '#F8FAFD',
    border: '1px solid #C2C7CB',
    boxSizing: 'border-box',
    borderRadius: ' 4px',
  },
  formGroup: {
    marginTop: '8px',
    gap: '8px',
    flexWrap: 'inherit',
    '& > div:nth-child(2)': {
      flexGrow: 1,
    },
  },
  selectField: {
    '& .eip1-MuiAutocomplete-inputRoot': {
      '& .eip1-MuiAutocomplete-input': {
        paddingLeft: '0px !important',
      },
      padding: '5px 24px 5px 8px',
    },
    '&.queryField .eip1-MuiSelect-outlined': {
      paddingRight: '15%',
    },
    '& .select_value': {
      fontWeight: 400,
    },
    '& .eip1-MuiSelect-selectMenu': {
      // width: '150px',
      paddingRight: '30px !important',
      overflow: 'unset',
      textOverflow: 'unset,',
    },
  },
  operator: {
    display: 'flex',
    width: '64px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#253746',
  },
  listAction: {
    padding: '8px',
  },
  inputValue: {
    flex: 1,
  },
  buttonAddBottom: {
    marginTop: '8px',
  },
  dropdownMenu: {
    display: 'flex',
    alignItems: ' center',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& p': {
      marginRight: '12px',
      marginLeft: '8px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  paper: {
    padding: '8px',
  },
  selectSearchPaper: {
    '& .eip1-MuiFormControl-root': {
      width: '100%',
      '& .eip1-MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  buttonFilter: {
    flex: 1,
    '& button': {
      width: '100%',
      maxWidth: '100%',
    },
    background: '#fff',
  },
  selectionContainerClass: {
    padding: 0,
  },
  lockIcon: {
    display: 'flex',
    justifyContent: 'center',
    width: '20px',
  },
}));

const booleanOperators: OptionSelectType[][] = [
  [
    {
      label: 'is',
      value: '=',
    },
    {
      label: 'is not',
      value: '!=',
    },
  ],
];

const groupOperator: OptionSelectType[] = [
  {
    label: 'And',
    value: 'and',
    note: 'All filter must match.',
  },
  {
    label: 'Or',
    value: 'or',
    note: 'At least one filter must match.',
  },
];

type FilterGroupProps = {
  options: Array<FieldSelectedValue>;
  getDefaultRule: () => IFilterItem;
  getFieldOperators: (dataType: string | number, field?: any) => OptionSelectType[][];
  operators?: FilterOperatorType;
  tmpFilterModel: IFilterItem[];
  setTmpFilterModel: (tmpFilterModel: IFilterItem[]) => void;
  enableFillerGroup?: boolean;
  level: number;
  isSubForm?: boolean;
  getOptionAvailable?: (field: string) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  isStaticGroup?: boolean;
  isLockedGroup?: boolean;
  isHiddenGroup?: boolean;
  filterData: IFilterItem[];
  disabled?: boolean;
  checkingValidField?: boolean;
  setCheckingValidField?: any;
  requiredFilterFields?: string[];
  isSystem?: boolean;
};
const FilterGroup = ({
  enableFillerGroup = false,
  isSubForm = false,
  getDefaultRule,
  getFieldOperators,
  setTmpFilterModel,
  tmpFilterModel,
  options,
  level,
  operators,
  getOptionAvailable = () => undefined,
  isStaticGroup = false,
  isLockedGroup = false,
  isHiddenGroup = false,
  filterData,
  disabled,
  checkingValidField,
  setCheckingValidField,
  requiredFilterFields,
  isSystem = false,
}: FilterGroupProps) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const { isEditMode } = React.useContext(NodeEditContext);
  const isMobileView = containerClass === 'eres--small';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mGroupOperator = React.useMemo(() => {
    if (level > 0) {
      return groupOperator;
    } else {
      return groupOperator.map((i) => ({ ...i, disabled: i.value === 'or' }));
    }
  }, [level]);

  const handleChangeRule = (
    field: 'field' | 'queryType' | 'queryValue',
    value: string | OptionSelectType,
    tmpId: string,
  ) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);

    if (index !== -1) {
      if (field === 'queryValue' && typeof value == 'string') {
        if (ff.filter_metric_empty) {
          if (tmpModel[index][field] === null) {
            tmpModel[index][field] = { value: '' };
          }
        }
        tmpModel[index][field].value = value;
      } else if (field === 'field') {
        tmpModel[index][field] = value;
        const queryType = value.isAdditionalFilter
          ? _.get(field, ['operators', 0, 0], null)
          : _.get(getFieldOperators((value as OptionSelectType).dataType), [0, 0], null);
        if (queryType) {
          tmpModel[index]['queryType'] = queryType;
        }

        if (typeof value !== 'string' && value.value === 'campaign_timeline') {
          tmpModel[index]['queryValue'].value = moment(new Date()).format('YYYY/MM/DD');
        } else {
          const queryQuery = _.get(tmpModel, [index, 'queryValue']) || {};
          tmpModel[index]['queryValue'] = { ...queryQuery, value: null };
        }
      } else {
        tmpModel[index][field] = value;
        _.set(tmpModel[index], ['queryValue', 'value'], '');
        // const selectedOperator:string[] =_.get(tmpModel,[index,'field','selectedOperators'],[]);
        // const queryType:string = _.get(tmpModel,[index,'queryType','value'],'');
        // if(selectedOperator.includes(queryType))
      }
      setTmpFilterModel(tmpModel);
    }
  };
  const handleChangeLogicalOperator = (value: string) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const logicalOperatorValue = value.toLocaleLowerCase() == 'and' ? 'and' : 'or';
    tmpModel.map((item, index) => {
      if (index !== 0) {
        item.logicalOperator = logicalOperatorValue;
      }
    });
    setTmpFilterModel(tmpModel);
  };
  const handleDeleteRule = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      tmpModel.splice(index, 1);
    }
    setTmpFilterModel(tmpModel);
  };

  const formatDuplicateRule = (rule) => {
    const subFilters = _.get(rule, ['subFilters'], []).filter((filter) => !filter.isHidden);
    return {
      ...rule,
      isLocked: false,
      static: isSystem,
      isDisabled: false,
      isHidden: false,
      tmpId: uuid(),
      ...(subFilters.length > 0 ? { subFilters: subFilters.map((r) => formatDuplicateRule(r)) } : {}),
    };
  };

  const handleDuplicateRule = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    const lastStaticIndex = isSystem ? index : tmpModel.filter((md) => md.static).length - 1;

    if (index !== -1 && tmpModel[index]) {
      tmpModel.splice(Math.max(index, lastStaticIndex) + 1, 0, {
        ...formatDuplicateRule(tmpModel[index]),
        logicalOperator: tmpModel[index]?.logicalOperator || 'and',
      });
    }
    setTmpFilterModel(tmpModel);
  };

  const handleTurnIntoGroup = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      let logicalOperator = '';
      if (tmpModel.length > 1) {
        logicalOperator = tmpModel[1].logicalOperator;
      }
      const itemCloned: IFilterItem = {
        ...tmpModel[index],
        static: false,
        isLocked: false,
        isDisabled: false,
        isHidden: false,
      };
      const groupDefault: IFilterItem = {
        type: 'groupFilter',
        logicalOperator: logicalOperator,
        subFilters: [itemCloned],
        tmpId: uuid(),
        static: !!tmpModel[index]?.static,
        isLocked: !!tmpModel[index]?.isLocked,
        isDisabled: !!tmpModel[index]?.isDisabled,
        isHidden: !!tmpModel[index]?.isHidden,
      };
      tmpModel.splice(index, 1);
      tmpModel.splice(index, 0, groupDefault);
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeStatus = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      tmpModel[index].isDisabled = !tmpModel[index].isDisabled;
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeStatic = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      tmpModel[index].static = !tmpModel[index].static;
      tmpModel[index].isLocked = false;
      tmpModel[index].isHidden = false;
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeLocked = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      tmpModel[index].isLocked = !tmpModel[index].isLocked;
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeHidden = (tmpId: string) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (index !== -1 && tmpModel[index]) {
      tmpModel[index].isHidden = !tmpModel[index].isHidden;
      setTmpFilterModel(tmpModel);
    }
  };

  const MenuAction: React.FC<MenuActionProps> = ({ index, isStaticGroup = false, ...rest }: MenuActionProps) => {
    if (isStaticGroup && !isEditMode)
      return (
        <MenuList
          listMenu={[
            [
              {
                title: 'Duplicate',
                icon: 'duplicatePlus',
                iconSize: '16px',
                onClick: handleDuplicateRule(rest.tmpId),
              },
            ],
          ]}
          closeMenuCallBack={rest.onClose}
        />
      );
    return (
      <MenuList
        listMenu={[
          [
            ...(rest.isLocked && !isEditMode
              ? []
              : [
                  {
                    title: 'Remove',
                    icon: 'remove',
                    onClick: handleDeleteRule(rest.tmpId),
                  },
                ]),
            {
              title: 'Duplicate',
              icon: 'duplicatePlus',
              iconSize: '16px',
              onClick: handleDuplicateRule(rest.tmpId),
            },
            ...(level <= 2 && !rest.subFilters
              ? [
                  {
                    title: 'Turn into group',
                    icon: 'turnIntoGroup',
                    onClick: handleTurnIntoGroup(rest.tmpId),
                  },
                ]
              : []),

            {
              title: rest.isDisabled ? 'Enable' : 'Disable',
              icon: 'editStatus',
              onClick: handleChangeStatus(rest.tmpId),
            },
            ...(isEditMode && (isStaticGroup || rest.isStatic)
              ? [
                  {
                    title: `${rest.isLocked ? 'Unlock' : 'Lock'} filter`,
                    icon: rest.isLocked ? 'rmx/lock-unlock-line-icon/#0000008a' : 'rmx/lock-line-icon/#0000008a',
                    onClick: handleChangeLocked(rest.tmpId),
                  },
                  {
                    title: `Mark filter as ${rest.isHidden ? 'public' : 'hidden'}`,
                    icon: rest.isHidden ? 'rmx/eye-fill-icon/#0000008a' : 'rmx/eye-off-fill-icon/#0000008a',
                    onClick: handleChangeHidden(rest.tmpId),
                  },
                ]
              : []),
          ],
        ]}
        closeMenuCallBack={rest.onClose}
      />
    );
  };

  const handleSetGroup = (tmpId: string, childFilters: IFilterItem[]) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const index = tmpModel.findIndex((md) => md.tmpId === tmpId);
    if (tmpModel[index] && tmpModel[index].type == 'groupFilter') {
      if (childFilters.length > 0) {
        (tmpModel[index] as TypeGroupFilter).subFilters = childFilters;
      } else {
        tmpModel.splice(index, 1);
      }
      setTmpFilterModel(tmpModel);
    }
  };
  const getFilterItem = (arr: IFilterItem[]) => {
    const isStaticGroup = arr[0].isStaticGroup;
    if (arr.length > 0) {
      const simpleFilter = arr.filter((item) => item.type == 'filter' && !item.static);
      if (simpleFilter.length > 0) {
        const last = _.last(simpleFilter);
        return { ...last };
      }
    }
    return getDefaultRule(isStaticGroup);
  };

  const handleAddGroup = () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const isStaticGroup = tmpModel[0].isStaticGroup;
    const defaultFilterItem = getFilterItem(tmpModel);
    let logicalOperator = 'and';
    if (tmpModel.length > 1) {
      logicalOperator = tmpModel[1].logicalOperator;
    }
    const groupDefault: IFilterItem = {
      type: 'groupFilter',
      logicalOperator: logicalOperator,
      subFilters: [defaultFilterItem].map((el) => ({
        ...el,
        isDisabled: false,
        isLocked: false,
        isHidden: false,
        tmpId: uuid(),
        static: isSystem,
      })),
      isStaticGroup: isStaticGroup ? isStaticGroup : false,
      tmpId: uuid(),
    };
    if (isSystem) {
      groupDefault.static = true;
      groupDefault.isStaticGroup = true;
    }
    tmpModel.push(groupDefault);
    setTmpFilterModel(tmpModel);
  };

  const handleAddRule = () => {
    const tmpModel: Array<IFilterItem> = JSON.parse(JSON.stringify(tmpFilterModel));
    const defaultFilterItem = getFilterItem(tmpModel);
    const isStaticGroup = tmpModel[0].isStaticGroup;
    if (tmpModel.length > 1) {
      defaultFilterItem.logicalOperator = tmpModel[1].logicalOperator;
    }
    tmpModel.push({
      ...defaultFilterItem,
      static: isSystem,
      isStaticGroup: isStaticGroup ? isStaticGroup : false,
      isDisabled: false,
      isLocked: false,
      isHidden: false,
      tmpId: uuid(),
    });
    setTmpFilterModel(tmpModel);
  };
  const dataMenu: Array<Array<DropdownMenuDataType>> = [
    level <= 2
      ? [
          {
            title: 'Add a filter',
            icon: 'plus',
            onClick: handleAddRule,
          },
          {
            title: 'Add a filter group',
            icon: 'addGroup',
            iconSize: '16px',
            onClick: handleAddGroup,
          },
        ]
      : [
          {
            title: 'Add a filter',
            icon: 'plus',
            onClick: handleAddRule,
          },
        ],
  ];
  const getValueContentWithRule = (rule: IFilterItem, indexLevel: number) => {
    const queryValue = _.get(rule, 'queryValue.value', '');
    // is_empty is_not_empty
    const operatorValue = _.get(rule, 'queryType.value', '');
    const isEmptyOperator = operatorValue == 'is_empty' || operatorValue == 'is_not_empty';

    const fieldValue = _.get(rule, 'field.value', '');
    const dataType = _.get(rule, 'field.dataType', '');
    if (dataType == 'date' || dataType == 'datetime')
      return (
        <CalendarSingle
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked}
          placeholder={'Date'}
          style={{
            width: '224px',
          }}
          value={queryValue}
          onChangeValue={(val) => handleChangeRule('queryValue', val, rule.tmpId)}
        />
      );

    const useSelected = (_.get(rule, ['field', 'selectedOperators'], []) as Array<string>).includes(
      _.get(rule, ['queryType', 'value'], ''),
    );
    if (useSelected)
      return (
        <SelectedValue
          style={{
            minWidth: '224px',
            width: '100%',
            backgroundColor: '#fff',
          }}
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked || isLockedGroup}
          defaultValue={queryValue}
          placeholderInput={'Search for a property'}
          onChangeValue={(val) => {
            handleChangeRule('queryValue', val.value, rule.tmpId);
            setCheckingValidField(false);
          }}
          field={fieldValue}
          getOptionAvailable={getOptionAvailable}
          className={classes.inputValue}
          placeholder={'Select an option'}
          filterData={filterData}
          checkingValidField={checkingValidField}
          requiredFilterFields={requiredFilterFields}
        />
      );
    const queryUnit = _.get(rule, 'queryValue.unit', '');

    return (
      <FormControl
        style={{ flex: 1, minWidth: '224px', width: '100%' }}
        variant="outlined"
        size="small"
        className={classes.inputValue}
        error={checkingValidField && requiredFilterFields.includes(fieldValue) && !queryValue}
      >
        <TextForm
          noShadow={true}
          fullWidthInput
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked || isLockedGroup}
          style={{ backgroundColor: '#fff' }}
          placeholder={'Content'}
          value={ff.filter_metric_empty ? (queryValue === null ? '' : queryValue) : queryValue}
          setValue={(value) => {
            handleChangeRule('queryValue', value, rule.tmpId);
            setCheckingValidField(false);
          }}
          tmpFilterModel={tmpFilterModel}
          positionUnit="start"
          unit={queryUnit}
          endAdornment={
            <IconButton
              style={{ padding: 0, opacity: queryValue && !rule.isLocked ? 1 : 0 }}
              onClick={() => handleChangeRule('queryValue', '', rule.tmpId)}
            >
              <Icon type={'closeCircle'} />
            </IconButton>
          }
        />
      </FormControl>
    );
  };

  const shouldHideFilter = (filter) => {
    return (
      filter.isHidden ||
      filter.isHiddenGroup ||
      filter.subFilters?.every((ft) =>
        ft.type === 'groupFilter' && ft.subFilters?.length ? shouldHideFilter(ft) : ft.isHidden || ft.isHiddenGroup,
      )
    );
  };

  return (
    <React.Fragment>
      {tmpFilterModel
        .filter((rule: TypeFilter) => {
          return (!isEditMode && !shouldHideFilter(rule)) || isEditMode;
        })
        .map((rule: TypeFilter | TypeGroupFilter, idxLevel1: number) => {
          const isStatic = _.get(rule, 'static', false);
          const isLocked = _.get(rule, 'isLocked', false);
          const isHidden = _.get(rule, 'isHidden', false);
          if (isStatic) {
            options = options.filter((val) => (val.value !== 'marketplace' && !isEditMode) || isEditMode);
          }
          return (
            <div key={idxLevel1}>
              {
                <Grid
                  container
                  direction="row"
                  alignItems={rule.type == 'groupFilter' ? 'flex-start' : 'center'}
                  style={{ display: 'flex', flexWrap: isMobileView ? 'wrap' : 'nowrap' }}
                  className={classes.formGroup}
                >
                  {/* logical Operator */}
                  <Grid
                    item
                    className={classes.operator}
                    style={{ width: '100%', maxWidth: isSubForm ? '60px' : '64px' }}
                  >
                    {idxLevel1 === 0 ? (
                      <Typography variant="body1" style={{ textAlign: isSubForm ? 'right' : 'left', fontWeight: 400 }}>
                        Where
                      </Typography>
                    ) : idxLevel1 === 1 && !isStatic ? (
                      rule.isStaticGroup || (isStaticGroup && !isEditMode) ? (
                        <Typography
                          variant="body1"
                          style={{ textAlign: isSubForm ? 'right' : 'left', fontWeight: 400 }}
                        >
                          And
                        </Typography>
                      ) : (
                        <SelectForm
                          options={mGroupOperator}
                          style={{
                            width: '100%',
                            maxWidth: isSubForm ? '60px' : '64px',
                            backgroundColor: '#fff',
                          }}
                          alignMenu="left"
                          value={rule.logicalOperator}
                          placeholder="And"
                          onChange={(e) => handleChangeLogicalOperator(e.target.value)}
                          className={classes.selectField}
                          renderOption={(item) => (
                            <div>
                              <Typography variant="body1">{item.label}</Typography>
                              <Typography variant="body5">{item.note}</Typography>
                            </div>
                          )}
                        />
                      )
                    ) : (
                      <Typography
                        variant="body2"
                        align={'right'}
                        style={{ textTransform: 'capitalize', fontWeight: 400 }}
                      >
                        {rule.logicalOperator}
                      </Typography>
                    )}
                  </Grid>

                  {/*rule  */}
                  {rule.type == 'filter' && (
                    <Grid
                      item
                      className={classes.groupRule}
                      style={{
                        flexGrow: 3,
                        flexWrap: isMobileView ? 'wrap' : 'nowrap',
                        ...(rule.isHidden ? { opacity: 0.3 } : {}),
                      }}
                    >
                      <SelectFormSearch
                        noShadow={true}
                        options={options}
                        style={{
                          flexGrow: 0.5,
                          backgroundColor: '#fff',
                          width: '100%',
                        }}
                        inputWidth={isSubForm ? '35%' : '40%'}
                        value={rule.field}
                        disabled={
                          (isStatic && !isEditMode) ||
                          rule.isDisabled ||
                          disabled ||
                          isLocked ||
                          (isStaticGroup && !isEditMode) ||
                          isLockedGroup
                        }
                        placeholder={'Add name'}
                        placeholderInput={'Search'}
                        onChangeValue={(val) => handleChangeRule('field', val, rule.tmpId)}
                        className={`${classes.selectField} queryField`}
                        classNamePaper={`${classes.selectSearchPaper}`}
                        autoFocus={true}
                      />

                      <SelectFormGroup
                        noShadow={true}
                        style={{ flexGrow: 1, backgroundColor: '#fff' }}
                        options={getFieldOperators(rule.field.dataType, rule.field)}
                        inputWidth={isSubForm ? '30%' : '25%'}
                        disabled={
                          (isStatic && !isEditMode) ||
                          rule.isDisabled ||
                          disabled ||
                          isLocked ||
                          (isStaticGroup && !isEditMode) ||
                          isLockedGroup
                        }
                        value={rule.queryType}
                        onChangeValue={(val) => handleChangeRule('queryType', val, rule.tmpId)}
                        className={`${classes.selectField} queryField `}
                      />
                      {getValueContentWithRule(rule, idxLevel1)}
                    </Grid>
                  )}
                  {rule.type == 'groupFilter' && rule.subFilters.length > 0 && (
                    <Grid className={classes.groupSub} style={{ ...(rule.isHidden ? { opacity: 0.3 } : {}) }}>
                      <FilterGroup
                        level={level + 1}
                        tmpFilterModel={rule.subFilters}
                        getDefaultRule={getDefaultRule}
                        getFieldOperators={getFieldOperators}
                        setTmpFilterModel={(val: IFilterItem[]) => {
                          handleSetGroup(rule.tmpId, val);
                        }}
                        options={options}
                        isSubForm={true}
                        operators={operators}
                        enableFillerGroup={enableFillerGroup}
                        getOptionAvailable={getOptionAvailable}
                        isStaticGroup={rule.static || isStaticGroup}
                        isLockedGroup={rule.isLocked || isLockedGroup}
                        isHiddenGroup={rule.isHidden || isHiddenGroup}
                        filterData={filterData}
                        disabled={rule.isDisabled}
                        checkingValidField={checkingValidField}
                        setCheckingValidField={setCheckingValidField}
                        requiredFilterFields={requiredFilterFields}
                        isSystem={isSystem}
                      />
                    </Grid>
                  )}
                  {((!isStaticGroup && !isLocked && !isLockedGroup) || isEditMode) && (
                    <MonitorContainer
                      mId={'filter-item-edot'}
                      mLabel={'Filter item edot'}
                      component={Grid}
                      item
                      className={`${classes.groupRule} ${classes.iconMore}`}
                    >
                      <Dropdown
                        disabledClickContentClose={false}
                        classNamePaper={classes.listAction}
                        alignMenu="right"
                        icon="threeDotsVertical"
                        label=""
                        isMobileFilter={true}
                        isButtonActive={filterData.length > 0}
                      >
                        <MenuAction
                          index={idxLevel1}
                          isStaticGroup={isStatic || isStaticGroup}
                          isDisabled={rule.isDisabled}
                          tmpId={rule.tmpId}
                          subFilters={rule.subFilters}
                          {...(isEditMode ? { isStatic, isLocked, isHidden } : {})}
                        />
                      </Dropdown>
                    </MonitorContainer>
                  )}
                  {(isLocked || isLockedGroup) && !isStatic && !isEditMode ? (
                    <Box className={classes.lockIcon}>
                      <Icon type={'rmx/lock-fill-icon/#0000008a'} size={'16px'} />
                    </Box>
                  ) : null}
                </Grid>
              }
            </div>
          );
        })}

      {(!isStaticGroup || isEditMode) &&
      tmpFilterModel.filter((rule: TypeFilter) => (!rule.isHidden && !isEditMode) || isEditMode).length > 0 &&
      enableFillerGroup ? (
        <Grid item className={classes.buttonAddBottom}>
          <Dropdown
            disabledClickContentClose={false}
            label={'Add more filters'}
            iconStart={'plus'}
            alignMenu={'left'}
            className={classes.dropdownMenu}
            classNamePaper={`${classes.paper}`}
            icon={'chevron'}
            sizeButton={'24px'}
            sizeIcon={'8px'}
            isMobileFilter={true}
            isButtonActive={filterData.length > 0}
          >
            <MenuList listMenu={dataMenu} />
          </Dropdown>
        </Grid>
      ) : (
        <Box style={{ height: '8px' }}></Box>
      )}
    </React.Fragment>
  );
};

interface ISelectedValueProps extends Omit<SelectFormProp, 'value'> {
  disabled?: boolean;
  value?: OptionSelectType;
  defaultValue: string;
  field: string;
  getOptionAvailable: (
    field: string,
    filterData?: IFilterItem[],
  ) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  filterData?: any;
}

const SelectedValue = ({
  disabled = false,
  field = '',
  defaultValue,
  getOptionAvailable,
  checkingValidField,
  requiredFilterFields,
  ...rest
}: ISelectedValueProps) => {
  const classes = useStyles();
  const backbone = React.useContext(TableBackboneContext);
  const endpoint = backbone.getConfig('endpoint', { GET_TABLE_DATA: '' }).GET_TABLE_DATA;
  const queryParams = getQueryParams(endpoint);
  if (ff.new_filter_selection && queryParams.namespace) {
    return (
      <Box className={classes.buttonFilter}>
        <FilterSelection
          filter={{ id: field, placeholder: rest.placeholder, placeholderInput: rest.placeholderInput }}
          onSubmit={({ payload }) => {
            rest.onChangeValue({
              ...payload,
              value: String(payload.value),
            });
          }}
          getFilterOptionValue={getOptionAvailable}
          defaultValues={{ [field]: { queryValue: defaultValue } }}
          selectionContainerClass={classes.selectionContainerClass}
          marginBottom={5}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          disabled={disabled}
          isSingle
          isAdvancedFilter
        />
      </Box>
    );
  }
  const [options, setOptions] = React.useState<OptionSelectType[]>([]);
  const [loadingObj, setLoadingObj] = React.useState({ isLoading: false, message: '', status: '' });
  const value = React.useMemo(() => {
    const va = options.find((item) => item.value == defaultValue);
    if (va) {
      return va;
    }
    return { label: '', value: '' };
  }, [options, defaultValue]);

  const handleGetOptions = async (forceReload = false) => {
    setLoadingObj({ ...loadingObj, isLoading: true, status: 'loading' });
    const res = await getOptionAvailable(field, rest.filterData, forceReload);

    if (res.success) {
      if (_.get(res.data, 'length', 0) > 0) {
        setLoadingObj({ ...loadingObj, isLoading: false, message: res.message, status: 'success' });
        setOptions(res.data);
      } else {
        setOptions([]);
        setLoadingObj({
          ...loadingObj,
          isLoading: true,
          message: res.message ? res.message : 'Please try again.',
          status: 'fail',
        });
      }
    } else {
      setLoadingObj({
        ...loadingObj,
        isLoading: true,
        message: res.message ? res.message : 'Please try again.',
        status: 'fail',
      });
      setOptions([]);
    }
  };

  React.useEffect(() => {
    handleGetOptions();
  }, [field, rest.filterData]);
  return (
    <SelectFormSearch
      disabled={disabled}
      value={value}
      noShadow={true}
      options={options}
      classNamePaper={`${classes.selectSearchPaper}`}
      loading={loadingObj}
      handleRefeshLoading={handleGetOptions}
      isError={!value.value && checkingValidField && requiredFilterFields.includes(field)}
      {...rest}
    />
  );
};
export default FilterGroup;
