import Eventemitter from 'eventemitter2';
import { nanoid } from 'nanoid';

// TODO: different transport: mixpanel, fullstory, elastic apm
type SessionContext = Record<string, any>;

type Session = {
  label: string;
  context?: SessionContext;
  spans?: any[];
};

type TrackRecord = (label, context?: SessionContext) => void;
type LogTransport = {
  init: (label, context?: SessionContext) => void;
  end: () => void;
  mark: (label, context?: SessionContext) => void;
};

export class UaMonit {
  type: string;
  context: SessionContext;
  currentTid: number;
  record: LogTransport;
  _isEnded: boolean;
  _id: string;
  _path: string[];
  _timeline: number[];
  _startTs: number;
  _prevTs: number;

  constructor(type: string, record: TrackRecord | LogTransport) {
    this.type = type;
    this.context = {};
    this.currentTid = 0;
    this._isEnded = false;
    this._id = nanoid();
    this._startTs = 0;
    this._prevTs = 0;

    if (typeof record === 'function') {
      this.record = {
        init: () => null,
        end: () => null,
        mark: (label, context) => record(label, context),
      };
    } else {
      this.record = record;
    }
  }

  public addContext(kv: Record<string, number | string>) {
    if (!kv) return;
    Object.keys(kv).forEach((k) => {
      this.context[k] = kv[k];
    });
    return this;
  }

  public mark(label) {
    if (!this._isEnded) {
      const ts = Date.now();
      const timespan = ts - this._startTs;
      const delta = ts - this._prevTs;
      this.record.mark(label, { ...this.context, uaTTL: timespan, uaTTLDelta: delta });
      this._prevTs = ts;
    } else {
      console.error('record after ended', `${label}`);
    }

    return this;
  }

  public requestAck(label, timerMs = 1000) {
    this.mark('start_ack:' + label);
    this.currentTid = window.setTimeout(() => {
      this.fail(`${label}:timer:${timerMs}`);
      this.end();
    }, timerMs);
  }

  public start(label: string, timerMs = 1000) {
    this._startTs = Date.now();
    this._prevTs = Date.now();
    this.mark('start:' + label);
    this.record.init('start:' + label);
    return this;
  }

  public ack(label: string) {
    window.clearTimeout(this.currentTid);
    this.currentTid = 0;
    this.success('ack:' + label);
    return this;
  }

  public success(msg: string) {
    this.mark('success:' + msg);
    return this;
  }

  public fail(msg: string) {
    this.mark('fail:' + msg);
    return this;
  }

  public end() {
    this.mark('end');
    this.record.end();
    this._isEnded = true;
    if (this.currentTid) {
      window.clearTimeout(this.currentTid);
    }
    return this;
  }

  public id() {
    return this._id;
  }
  public isEnded() {
    return this._isEnded;
  }
}

const sessions: Record<string, Session> = {};

function init(label) {
  const sid = label + ':' + nanoid();
  sessions[sid] = {
    label,
  };
  return sid;
}
