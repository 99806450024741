import * as React from 'react';
import clsx from 'clsx';

import { Box, makeStyles, Button, Popover, Grid, PopoverOrigin } from '@material-ui/core';

import { useFilterSelection } from './hooks/use-filter-selection';

import Icon from '@ep/insight-ui/icons/Icon';
import { DarkModeContext } from '../../epsilo-chart/chart-container';
import MultipleSelectComponent from '../../multiple-select-component';
import SelectComponent from '../../select-component';

type FilterValueType = {
  dataType: string;
  field: string;
  logicalOperator: string;
  queryType: string;
  queryField: string;
  queryValue: string;
  queryValueLabel: string;
  type: string;
};

interface IPropsFilterSelection {
  getFilterOptionValue: any;
  filter: {
    dataType?: string;
    field?: string;
    id: string;
    name?: string;
    propertyType?: string;
    placeholder?: string;
    placeholderInput?: string;
  };
  onSubmit: (filter: any, payload: any) => void;
  defaultValues: {
    country?: FilterValueType;
    marketplace?: FilterValueType;
    storefront?: FilterValueType;
  };
  containerClass?: string;
  isMultiSelect?: boolean;
  selectionContainerClass?: string;
  marginBottom?: number;
  anchorOrigin?: PopoverOrigin;
  disabled?: boolean;
  isSingle?: boolean;
  isAdvancedFilter?: boolean;
}

const useStyles = makeStyles(() => ({
  groupBtn: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  removeFilter: {
    cursor: 'pointer',
  },
  buttonProperty: (props) => ({
    maxWidth: '250px',
    padding: '6px 0 7px 8px',
    '& .eip1-MuiButton-label': {
      lineHeight: '17px',
      fontWeight: 400,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left',
      display: 'block',
      position: 'relative',
      paddingRight: '24px',
      '& .eip1-MuiButton-endIcon': {
        position: 'absolute',
        top: '50%',
        right: '12px',
        transform: 'translateY(-50%)',
      },
    },
    ...(props.isDarkMode
      ? {
          backgroundColor: '#253645',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFFFFF',
          },
        }
      : {}),
  }),
  container: {},
  popoverContainer: {
    '& .eip1-MuiPopover-paper': {
      overflowY: 'hidden',
    },
  },
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 16px',
  },
  button: {
    height: '100%',
  },
  groupCheckList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    maxHeight: '90vh',
    overflowY: 'auto',
    // maxWidth: 640,
  },
  checkListItem: {
    minWidth: 210,
    // maxWidth: 210,
    // margin: '0px 4px',
    padding: '8px 8px',
  },
  disabled: {
    background: '#F6F7F8',
  },
}));

const FilterSelection = ({
  filter,
  onSubmit,
  getFilterOptionValue,
  defaultValues,
  containerClass,
  selectionContainerClass,
  marginBottom = 20,
  isMultiSelect = false,
  anchorOrigin,
  disabled = false,
  isSingle = false,
  isAdvancedFilter = false,
}: IPropsFilterSelection) => {
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const classes = useStyles({ isDarkMode });

  const {
    value,
    filteredOptions,
    handleClose,
    handleSubmit,
    anchorEl,
    handleButtonClick,
    setOptions,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    loadingObj,
    onSingleClick,
    originalLabel,
    handleRetry,
    compactBackbone,
    handleChangeCompactSort,
    hasSortButton,
    lastUpdatedLabel,
  } = useFilterSelection({
    defaultValues,
    filter,
    getFilterOptionValue,
    onSubmit,
    isSingle,
    isAdvancedFilter,
  });

  const error = loadingObj.status === 'fail' ? <ErrorRetry onClick={handleRetry} label={'Refresh'} /> : null;

  return (
    <Box className={clsx(containerClass, classes.groupBtn, disabled ? classes.disabled : '')}>
      <Button
        className={clsx(containerClass, classes.buttonProperty)}
        endIcon={<Icon type="chevron" size="8px" colorIcon="#3F4F5C" />}
        onClick={handleButtonClick}
        variant="outlined"
        style={disabled ? { color: '#C2C7CB' } : value.label ? { color: isDarkMode ? '#FFFFFF' : '#253746' } : {}}
        disabled={disabled}
      >
        {originalLabel || value.label || filter.placeholder || filter.name}
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverContainer}
        anchorOrigin={anchorOrigin}
      >
        <div className={classes.container}>
          {!isSingle ? (
            <>
              <MultipleSelectComponent
                title={filter.name}
                options={filteredOptions}
                setOptions={setOptions}
                selectedItems={selectedItems}
                setSelectedItems={setSelectedItems}
                hasSearch
                isSelectAllShow
                loadingObj={loadingObj}
                loading={loadingObj.status === 'loading'}
                onInputChange={setSearchTerm}
                useParentFilter
                placeholder={filter.placeholderInput}
                error={error}
                isMultiSelect={isMultiSelect}
                showSelectedIndicator
                compactBackbone={compactBackbone}
                handleChangeCompactSort={handleChangeCompactSort}
                hasSortButton={hasSortButton}
                handleRetry={handleRetry}
                lastUpdatedLabel={lastUpdatedLabel}
              />
              <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
                <Grid item>
                  <Button className={classes.button} onClick={handleClose}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    disabled={!isMultiSelect && selectedItems.length != 1}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <SelectComponent
              title={filter.name}
              options={filteredOptions}
              hasSearch
              error={error}
              loading={loadingObj.status === 'loading'}
              onSelectItem={onSingleClick}
              onInputChange={setSearchTerm}
              useParentFilter
              placeholder={filter.placeholderInput}
              containerClass={selectionContainerClass}
              marginBottom={marginBottom}
              compactBackbone={compactBackbone}
              handleChangeCompactSort={handleChangeCompactSort}
              hasSortButton={hasSortButton}
            />
          )}
        </div>
      </Popover>
    </Box>
  );
};

export default FilterSelection;

const useStyleRetry = makeStyles({
  container: {
    marginLeft: '8px',
  },
  label: {
    display: 'flex',
    columnGap: '4px',
    alignItems: 'center',
    color: '#626266',
    whiteSpace: 'nowrap',
  },
  icon: {
    cursor: 'pointer',
  },
});

const ErrorRetry = ({ onClick, label = 'Retry' }) => {
  const classes = useStyleRetry();

  return (
    <div className={classes.container}>
      <div className={classes.label}>
        <span className={classes.icon} onClick={onClick}>
          <Icon type={'reload'} colorIcon={'#626266'} />
        </span>
        {label}
      </div>
    </div>
  );
};
