export const CAMPAIGN_SETTING_CONFIG = {
  title: 'Campaign overview setting',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {},
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.storefront_id',
    'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id',
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.storefront_id',
      },
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      valueGetter: {
        config: 'AD_OBJECT.config',
        subtext: 'PRODUCT.product_sid',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'ADTOOL.ads_tool',
      },
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.ads_ops_shp_ads_campaign_id',
      },
    },

    // ============= ATTRIBUTE =============
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
    },
    campaign_timeline: {
      title: 'Campaign timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      valueGetter: {
        date_from: 'ADS_CALENDAR.timeline_from',
        date_to: 'ADS_CALENDAR.timeline_to',
        adsCalendarId: 'ADS_CALENDAR.ads_ops_ads_calendar_id',
      },
    },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'singleBudget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        // currency: 'STOREFRONT.currency',
      },
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'singleBudget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        // currency: 'STOREFRONT.currency',
      },
    },

    // ============= METRIC =============
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'roas',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'cost',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'ads_gmv',
      },
    },
    direct_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'direct_gmv',
      },
    },
    items_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'cpc',
      },
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      valueGetter: {
        value: 'click',
      },
    },
  },
};
