import * as API_HOST from '@ep/one/global';

const EIP_CONSTANT = {
  DATE_FORMAT: 'YYYY-MM-DD',
  DATETIME_FORMAT: 'YYYY-MM-DD HH:mm:ss',
  ELEMENT_DISABLED_KEY_VALUE: '__disabled__',
  RELOAD_TIME: 60000,
  SCREEN_SIZE: {
    lg: 1200,
    md: 960,
    sm: 768,
    xs: 480,
    xxs: 0,
  },

  SCREEN_COLUMNS: {
    lg: {
      min: 10,
      max: 72,
      default: 36,
    },
    md: {
      min: 10,
      max: 36,
      default: 36,
    },
    sm: {
      min: 10,
      max: 36,
      default: 36,
    },
    xs: {
      min: 10,
      max: 36,
      default: 36,
    },
    xxs: {
      min: 10,
      max: 36,
      default: 36,
    },
  },
  GRID_MARGIN: 12,
  GRID_BORDER_RADIUS: 5,
  ETABLE_PERSONALIZED_FIELDS: ff.function_crud_etable_view
    ? [
        'dimension',
        'metric',
        'attribute',
        'filter',
        'sort',
        'columnOrder',
        'columnWidth',
        'pinnedColumn',
        'groupBy',
        'personalization',
        'view',
        'views',
        'calendarOption',
        'dateRange',
        'calendarCohort',
        'cohortDateRange',
      ]
    : [
        'dimension',
        'metric',
        'attribute',
        'filter',
        'sort',
        'columnOrder',
        'columnWidth',
        'pinnedColumn',
        'groupBy',
        'personalization',
      ],
  EIP_SYSTEM_PAGE_ID: {
    myProfile: '0cc4d7ff-0bb2-43f5-8638-eef9fa9d4126',
    campaignManagement: '34c31cc3-0570-4aa9-bf69-d799a1e7f065',
    companyManagement: '58d5913c-2799-41d7-ad73-ac4f349aa88a',
    hyperAutomation: '0c170463-2364-4b69-8460-6d19be07082d',
    campaignDetail: 'afdd53e6-49f9-4170-9212-bdeada713c0d',
    scriptDetail: 'ec22dd25-a14f-40d8-9199-a5b0517e835e',
    intraday: '66af3842-c6d1-4c32-8402-fd02c69c4f0d',
    marketerCommunity: 'dc96ffed-a769-4e4c-ba64-65e9b5a95787',
    gtmRevenue: 'f70231e9-ee6b-4558-9e1a-515fcdb38848',
    gtmSale: 'ae56d222-f800-4a34-b59b-a0f58eefd1f3',
    gtmSolution: 'b638d47b-7002-4a94-964e-1c51b94dbd58',
    gtmManagedService: '4792a93a-ec0f-4a6e-b43f-2332c86ff879',
    gtmInvestorMIS: '310a1eed-0535-4e43-ae0a-af65def8978f',
  },
  EIP_SYSTEM_PAGE_GROUP_ID: {
    botep: 'botep',
  },
  API_HOST,
  CONTAINER_RESPONSIVE: {
    breakpoints: [
      ['eres--small', 480],
      ['eres--medium', 1008],
      ['eres--large', Number.POSITIVE_INFINITY],
    ],
  },
  BLOCK_TYPE: {
    userPersona: 'user_persona',
  },
};

export { EIP_CONSTANT };
