import * as React from 'react';
import * as _ from 'lodash';
import { Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Icon from '@ep/insight-ui/icons/Icon';
import Dropdown from '../../dropdown-menu/dropdown';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import StyledTooltip from '../../tooltip/styled';
const useStyle = makeStyles({
  header: {
    fontWeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    ...(ff.ag_grid_27 ? { width: '100%' } : {}),
  },
  label: {
    // width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    //
    // textAlign: 'right',
    '&.align-right': {
      textAlign: 'right',
    },
    '&.align-left': {
      textAlign: 'left',
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  sortType: {
    '&.d-show': {
      opacity: 1,
    },
    '&.d-none': {
      opacity: 0,
    },
    marginRight: '8px',
  },
  button: {
    minWidth: '24px',
    height: '24px',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  tooltip: {
    '& p': {
      margin: 0,
      padding: '2px 4px',
    },
  },
  tooltipHeader: {
    fontWeight: 500,
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
});
const HeaderGroup = (props: any) => {
  const classes = useStyle();
  const displayName = _.get(props, 'displayName', '');
  const menuAction = _.get(props, 'menuAction', []);
  const field = _.get(props, 'field', '');
  const sort = _.get(props, 'sort', []);
  const sortType = _.get(
    sort.filter((s) => s.field === field),
    [0, 'sort'],
    null,
  );
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const mapping = backboneContext.getConfig('mapping');
  const dataType = _.get(props, 'dataType', 'text');
  const [isHovering, setIsHovering] = React.useState(false);
  const columnDescription = _.get(mapping, [props.field, 'columnDescription'], '');
  const handleMouseOverCell = () => {
    setIsHovering(true);
  };
  const handleMouseOutCell = () => {
    setIsHovering(false);
  };
  return (
    <Grid
      className={clsx(displayName + 'header', classes.header)}
      onMouseOver={handleMouseOverCell}
      onMouseLeave={handleMouseOutCell}
      data-etl="column-group"
    >
      <StyledTooltip
        title={
          <div className={classes.tooltip}>
            <p className={classes.tooltipHeader}>{props?.displayName}</p>
            {columnDescription ? <p className={classes.tooltipContent}>{columnDescription}</p> : null}
          </div>
        }
        placement={'right'}
      >
        <Grid container alignItems={'center'} wrap={'nowrap'} spacing={3}>
          <Grid
            item
            className={`${classes.label} ${
              dataType == 'integer' || dataType == 'float' ? 'align-right' : 'align-left'
            } ${displayName}`}
          >
            {displayName}
          </Grid>
          {columnDescription && (
            <Grid xs={2} className={`${classes.sortType} d-show`} container alignItems={'center'}>
              <Icon type={'ic/mi:circle-information/#737373'} />
            </Grid>
          )}
        </Grid>
      </StyledTooltip>
      <div className={`${classes.menu}`}>
        {isHovering && menuAction?.length > 0 && (
          <Dropdown
            className={classes.button}
            disabledClickContentClose={false}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
          >
            <List component="nav" aria-label="mailbox folders">
              {menuAction.map((menu, index) => (
                <div key={index}>
                  {/* {index === menuAction?.length - 1 && <Divider />} */}
                  <ListItem button role="menuitem" className={classes.itemDropdown}>
                    <ListItemIcon>
                      <Icon type={menu.icon} colorIcon={'#253746'} width="16px" height="16px" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.textStyle}
                      primary={menu.name}
                      onClick={() => {
                        const checkExist = sort.filter((object) => object.field === field);
                        if (checkExist.length === 0) {
                          sort.push({ field, sort: menu.type });
                        } else {
                          checkExist[0].sort = menu.type;
                        }
                        menu.onSubmit({ field, menuType: { name: menu.name, type: menu.type } });
                      }}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Dropdown>
        )}
      </div>
    </Grid>
  );
};
export default HeaderGroup;
