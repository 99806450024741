import * as React from 'react';
import {
  createStyles,
  debounce,
  InputAdornment,
  InputBase,
  InputProps,
  makeStyles,
  OutlinedInput,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import withStyles, { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import clsx from 'clsx';
import Error from '@ep/insight-ui/icons/svg/Error';

const useStyles = makeStyles(() => ({
  textStyle: textFieldStyle as CreateCSSProperties,
  isCorrect: textFieldStyle.isCorrect as CreateCSSProperties,
  inputCustom: {
    height: '32px',
    marginLeft: '5px',
    padding: '0px',
    width: '200px',
    overflowY: 'hidden',
    '& input': {
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
      },
    },
  },
  arrow: {},
  textUnit: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: ' 14px',
    margin: '0 7px',
  },
  arrowIcon: {
    height: '100%',
    width: 7,
    paddingLeft: 5,
    paddingRight: 5,
    minWidth: ' 25px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  arrowUp: {
    marginBottom: '2px',
    cursor: 'pointer',
    width: '100%',
  },
  arrowDown: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  borderIcon: {
    borderBottom: 'solid 1px rgba(0, 0, 0, 0.23)',
  },
  formGroup: {
    flexWrap: 'nowrap',
    width: 'fit-content',
    borderLeft: '1px solid rgba(0, 0, 0, 0.23)',
  },
  formControl: {
    width: '100%',
  },
  formInput: {
    // overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '&:hover': {
      borderColor: '#006EC6 !important',
    },

    '&. Mui-focused': {
      background: 'red',
    },
    '&.Mui-disabled': {
      color: '#C2C7CB',
      background: '#F6F7F8',
    },
    '& .Mui-disabled': {
      color: '#C2C7CB',
      background: '#F6F7F8',
    },
    '& input': {
      width: '100%',
      boxShadow: '0 0 10px 10px transparent !important',
      paddingTop: 0,
      paddingBottom: 0,
      paddingLeft: '10px',
      minHeight: '32px',
    },
    border: '1px solid #C2C7CB',
    borderRadius: '4px',
    '& fieldset': {
      border: 'none',
    },
  },
  formHelper: {
    marginRight: 0,
    marginLeft: 0,
    fontSize: '10px',
    color: '#D4290D',
    lineHeight: '12px',
    '& svg': {
      width: '9px',
      height: '9px',
      marginRight: '5px',
    },
  },
  formHelperNoError: {
    marginRight: 0,
    marginLeft: 0,
    fontSize: '10px',
    lineHeight: '12px',
    color: colors.text.subdued,
  },
}));

export interface TextFormProps extends InputProps {
  value: string;
  onChangeNumber: (newValue: string) => void;
  maxValue?: number;
  minValue?: number;
  unit?: string;
  disabledIncreaseDecrease?: boolean;
  helperText?: string;
  timeDebounce?: number;
}
const TextFieldNumber = ({
  unit = '',
  value,
  disabledIncreaseDecrease = false,
  onChangeNumber,
  maxValue,
  minValue = 0,
  helperText = '',
  error,
  timeDebounce = 1000,
  ...res
}: TextFormProps) => {
  const classes = useStyles();
  const [number, setNumber] = React.useState<string>(value);
  React.useEffect(() => {
    if (number !== value) {
      setNumber(value);
    }
  }, [value]);
  const Increase = () => {
    if (maxValue) {
      if (+number < maxValue) {
        setNumber(String(+number + 1));
      }
    } else {
      setNumber(String(+number + 1));
    }
  };
  const Decrease = () => {
    if (+number > minValue) {
      setNumber(String(+number - 1));
    }
  };
  const handleSetNumber = React.useCallback(debounce(onChangeNumber, timeDebounce), []);

  React.useEffect(() => {
    if (number !== null && number !== undefined) {
      handleSetNumber(number);
    }
  }, [number]);
  const onChange = (e) => {
    const re = /^(\d*\.?\d*)$/;
    if (re.test(e.target.value)) {
      setNumber(e.target.value);
      res.onChange && res.onChange(e);
    }
  };

  return (
    <React.Fragment>
      <OutlinedInput
        {...res}
        className={clsx(classes.textStyle, classes.inputCustom, classes.formInput, res.className)}
        value={number}
        onChange={onChange}
        // onBlur={onBlur}
        endAdornment={
          <InputAdornment position="end" disablePointerEvents={res.disabled}>
            {unit ? (
              <Grid item>
                <div className={classes.textUnit}>{unit}</div>
              </Grid>
            ) : null}

            {!disabledIncreaseDecrease && (
              <Grid container alignItems={'center'}>
                {/* button actions number */}
                <Grid item className={classes.arrow}>
                  <Grid container direction={'column'} className={classes.formGroup}>
                    <Grid item xs={6}>
                      <div onClick={Increase} className={`${classes.arrowIcon} ${classes.borderIcon}`}>
                        <div style={{ width: 7, height: '100%' }}>
                          <Icon
                            className={`${classes.arrowUp}`}
                            type={'arrowup'}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div onClick={Decrease} className={classes.arrowIcon}>
                        <div style={{ width: 7, height: '100%' }}>
                          <Icon
                            className={`${classes.arrowDown}`}
                            type={'arrowdowncustom'}
                            style={{ width: '100%', height: '100%' }}
                          />
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </InputAdornment>
        }
      />
      {error ? (
        <FormHelperText className={`${classes.formHelper}`}>
          <Error />
          {helperText}
        </FormHelperText>
      ) : (
        helperText && <FormHelperText className={`${classes.formHelperNoError}`}>{helperText}</FormHelperText>
      )}
    </React.Fragment>
  );
};

export default TextFieldNumber;
