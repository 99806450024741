import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { get } from 'lodash';
import { useAtomValue } from 'jotai';

import { DropdownCell } from '@ep/insight-ui/elements/etable2/dropdown-cell';
import { enhanceCellAction as enhanceCellAction1 } from '../table-helper';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { checkEpsiloTableEndpoint, EpsiloTableObject } from '@ep/insight-ui/system/backbone/data-source/common';

import { useWrapperFormat } from '@ep/insight-ui/elements/table/format/hooks/use-wrapper-format';
import { useKeyboardInteraction } from '../hooks/use-keyboard-interaction';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    padding: '0 17px',
    width: '100%',
    height: '100%',
    '.ag-cell-wrapper.ag-row-group-leaf-indent &.hover': {
      backgroundColor: '#FAFCFF',
    },
    '&.hover': {
      '& .dropdown_menu': {
        background: '#FAFCFF',
      },
    },
    '&.eip1-selectable': {
      userSelect: 'text',
      MozUserSelect: 'text',
      WebkitUserSelect: 'text',
      msUserSelect: 'text',
    },
  },
  menu: {
    position: 'absolute',
    right: 8,
    ...(ff.mobile_interaction_zone
      ? {
          '.eres--small &': {
            display: 'none',
          },
        }
      : {}),
  },
  tagItem: {
    background: ' #EDF2F9',
    borderRadius: '4px',
    '& .eip1-MuiChip-deleteIcon': {
      width: '10px',
      height: '10px',
      color: '#3F4F5C',
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    lineHeight: '20px',
    textAlign: 'right',
    '&.type': {
      textTransform: 'capitalize',
      fontSize: '14px',
      color: '#8C98A4',
    },
    '&.value': {
      fontSize: '14px',
      color: '#253746',
    },
  },
  menuClasses: {
    '& .eip1-MuiListItem-gutters': {
      padding: 8,
      '& .eip1-MuiListItemIcon-root': {
        minWidth: 12,
      },
    },
  },
}));
export interface ICellAction {
  title?: string;
  name?: string;
  icon?: string;
  iconSize?: string;
  component?: React.ReactNode | unknown;
  condition?: { field: string; operator: string; values: any[] }[];
  onSubmit?: (info) => void;
  onClickItem?: () => void;
  checkDisable?: (row) => boolean;
}
export interface IPropsFormat<V> {
  value: V;
  cellAction: Array<ICellAction>;
  node: any;
}
type Props = {
  children: JSX.Element;
  cellAction: Array<ICellAction>;
  value: any;
  node: any;
  disabledMenu?: boolean;
  disabledPadding?: boolean;
  isGrouped?: boolean;
  gridApi?: any;
  typeTable?: string;
  field?: string;
  eGridCell?: HTMLElement;
};

const WRAPPER_FORMAT_HOVER_EVENT = 'WRAPPER_FORMAT_HOVER_EVENT';

const WrapperFormat = ({
  children,
  value,
  node,
  disabledMenu = false,
  isGrouped = false,
  gridApi,
  typeTable,
  field,
  cellAction,
  eGridCell,
  ...props
}: Props) => {
  const classes = useStyles();
  const [isHovering, setIsHovering] = React.useState(false);
  const backbone = React.useContext(TableBackboneContext);
  const [oneAction, setOneAction] = React.useState(null);

  const { showThreeDots, setShowThreeDots } = useKeyboardInteraction({ backbone, gridApi, node, eGridCell });

  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);
  const tableId = useAtomValue(eTableAtom.tableId);

  React.useEffect(() => {
    const getOneAction = async () => {
      const actions = get(linkedObjects, ['actions', field], []);
      const oneAction = await backbone.addon('cell.customActions2', () => [])(
        { field, actions },
        {
          data: {
            selectedRows: backbone?.getSelectedRows() || [],
            ...props.data,
            ...get(props, ['data', 'eData', field], {}),
          },
          isOne: true,
        },
      );

      if (oneAction.length > 0) {
        setOneAction(
          oneAction.slice(0, 1).map((el) => ({
            ...el,
            name: '',
            title: el.name,
          })),
        );
      }
    };
    if (!value?.value && !value?.label) {
      getOneAction();
    }
  }, [value]);

  React.useEffect(() => {
    const handleHoverEvent = (e) => {
      const { level, rowIndex, isHovering, tableId: nodeTableId } = e.detail;
      if (node && node.level == level && node.rowIndex == rowIndex && tableId == nodeTableId) {
        setIsHovering(isHovering);
      }
    };

    window.addEventListener(WRAPPER_FORMAT_HOVER_EVENT, handleHoverEvent);

    return () => {
      window.removeEventListener(WRAPPER_FORMAT_HOVER_EVENT, handleHoverEvent);
    };
  }, [node, tableId]);

  const handleOnMouseOver = (event) => {
    if (!disabledMenu) {
      window.dispatchEvent(
        new CustomEvent(WRAPPER_FORMAT_HOVER_EVENT, {
          detail: {
            level: node?.level,
            rowIndex: node?.rowIndex,
            isHovering: true,
            tableId,
          },
        }),
      );
    }
  };
  function handleOnMouseLeave(event) {
    const e = event.toElement || event.relatedTarget;
    if (e.parentNode == this || e == this) {
      return;
    }

    if (!disabledMenu) {
      window.dispatchEvent(
        new CustomEvent(WRAPPER_FORMAT_HOVER_EVENT, {
          detail: {
            level: node?.level,
            rowIndex: node?.rowIndex,
            isHovering: false,
            tableId,
          },
        }),
      );
    }
  }

  const myRef = React.useRef(null);
  let renderEdot = null;
  if (!disabledMenu && (isHovering || showThreeDots) && !oneAction) {
    renderEdot = (
      <EdotFunction
        cellAction={cellAction}
        targetRef={myRef}
        isGrouped={isGrouped}
        field={field}
        value={value}
        node={node}
        gridApi={gridApi}
        showThreeDots={showThreeDots}
        setIsHovering={setIsHovering}
        setShowThreeDots={setShowThreeDots}
        linkedObjects={linkedObjects}
        {...props}
      />
    );
  }

  return (
    <div
      className={`${classes.container} cellTable eip1-selectable ${isHovering ? 'hover' : ''}`}
      role={'cell'}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
      style={{
        padding: isGrouped
          ? typeTable === 'compact'
            ? '2.5px 8px 2.5px 24px'
            : '0.5px 8px 0.5px 24px'
          : typeTable === 'compact'
          ? '2.5px 8px'
          : '0.5px 8px',
      }}
      ref={myRef}
    >
      {children}
      {renderEdot}
      {oneAction ? (
        <MonitorContainer
          mId="one-action"
          mLabel={oneAction[0]?.['title'] || 'One action'}
          component={'div'}
          className={clsx(classes.menu, 'dropdown_menu')}
          role={'menubar'}
        >
          <DropdownCell
            cellAction={oneAction}
            props={{ value, node, gridApi }}
            onOpened={() => undefined}
            onClosed={() => undefined}
            showThreeDots={showThreeDots}
            menuClasses={classes.menuClasses}
            setShowThreeDots={setShowThreeDots}
            ignoreDropdown
          />
        </MonitorContainer>
      ) : null}
    </div>
  );
};
export default WrapperFormat;

function EdotFunction({
  targetRef,
  isGrouped,
  cellAction,
  value,
  node,
  gridApi,
  field,
  showThreeDots,
  setIsHovering,
  setShowThreeDots,
  linkedObjects,
  ...props
}) {
  const [isPauseHover, setIsPauseHover] = React.useState(false);
  let backbone, additionalActions;
  backbone = React.useContext(TableBackboneContext);

  const [actions, setActions] = React.useState([]);
  const sourceColumn = get(props, ['data', 'eData', field, 'pivot', 'sourceColumn'], '');
  React.useEffect(() => {
    const getActions = async () => {
      const sourceColumnActions = get(linkedObjects, ['actions', sourceColumn], []);
      const atcs = await backbone.addon('cell.customActions2', () => [])(
        { field: sourceColumn, actions: sourceColumnActions },
        {
          pivotColumn: field,
        },
      );
      const actions = get(linkedObjects, ['actions', field], []);
      const customAtcs = await backbone.addon('cell.customActions2', () => [])(
        { field, actions },
        {
          data: {
            selectedRows: backbone?.getSelectedRows() || [],
            ...props.data,
            ...get(props, ['data', 'eData', field], {}),
          },
        },
      );
      setActions(atcs.concat(customAtcs));
    };
    const tid = setTimeout(getActions, 0);
    return () => {
      clearTimeout(tid);
    };
  }, [sourceColumn, field]);

  const classes = useStyles();

  additionalActions = React.useMemo(() => {
    const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
    const edotActions = [];
    if (backbone.getGridApi) {
      const gridApi = backbone.getGridApi();

      if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
        if (isGrouped && node.group) {
          edotActions.push({
            name: 'Reload',
            icon: 'reload',
            onSubmit() {
              if (gridApi) {
                gridApi.refreshServerSideStore({ route: [node.key] });
              }
            },
          });
        }
      }
    }

    return edotActions.concat(actions);

    // return edotActions;
  }, [backbone, node, actions]);

  // const cellAction1 = enhanceCellAction1(cellAction, get(value, 'edot', []), field, backbone);

  const { enhanceCellAction } = useWrapperFormat({ cellAction: cellAction, myRef: targetRef, value, node, backbone });

  const filteredActions = React.useMemo(() => {
    if (enhanceCellAction) {
      return enhanceCellAction
        .concat(additionalActions)
        .filter((a) => {
          if (a.condition) {
            return a.condition.every((c) => {
              switch (c.operator) {
                case 'CONTAIN':
                  return c.values.includes(node.data[c.field]);
                case 'NOT_CONTAIN':
                  return !c.values.includes(node.data[c.field]);
                default:
                  return true;
              }
            });
          }
          return true;
        })
        .map((a) => {
          if (a.checkDisable) {
            return {
              ...a,
              disable: a.checkDisable(node.data),
            };
          }
          return a;
        });
    }
    return [].concat(additionalActions);
  }, [enhanceCellAction, node, backbone, additionalActions]);

  //{!disabledMenu && isHovering && (
  const onOpened = () => setIsPauseHover(true);
  const onClosed = () => {
    setIsPauseHover(false);
    setTimeout(() => {
      setIsHovering(false);
    }, 0);
  };

  return (
    <MonitorContainer
      mId="cell-edot"
      mLabel="Cell edot"
      component={'div'}
      className={clsx(classes.menu, 'dropdown_menu')}
      role={'menubar'}
    >
      {filteredActions.length > 0 && (
        <DropdownCell
          cellAction={filteredActions}
          props={{ value, node, gridApi }}
          onOpened={onOpened}
          onClosed={onClosed}
          showThreeDots={showThreeDots}
          setShowThreeDots={setShowThreeDots}
        />
      )}
    </MonitorContainer>
  );
}
