import { Box, Button, CircularProgress } from '@material-ui/core';
import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT, useWorkflow } from '@eip/next/lib/main';

class SubmitControlChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <WorkflowSubmitControl />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return <div></div>;
}

function WorkflowSubmitControl() {
  return null;
  // const workflow = useWorkflow();

  // const [isLoading, setIsLoading] = React.useState(false);

  // return (
  //   <Box height={'100%'} display="flex" flexDirection={'row-reverse'} alignItems={'center'} padding={[0, 4]}>
  //     {isLoading && <CircularProgress color={'primary'} size={'1em'} />}
  //     <Button
  //       variant="contained"
  //       color="primary"
  //       disabled={isLoading}
  //       onClick={() => {
  //         if (workflow) {
  //           setIsLoading(true);
  //           workflow.submit().then(() => {
  //             setIsLoading(false);
  //           });
  //         }
  //       }}
  //     >
  //       <span data-dbf-text-editable="submit_text">Submit</span>
  //     </Button>
  //     <Box width={12}></Box>
  //     <Button variant="contained" color="secondary" data-dbf-text-editable="cancel_text">
  //       Cancel1
  //     </Button>
  //   </Box>
  // );
}

export default {
  blockType: 'fromSubmitControl',
  label: 'Submit control',
  blockComponent: SubmitControlChartLib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
    h: 1,
  },
};
