import * as React from 'react';
import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltipView: {
    background: '#253746',
    fontSize: '11px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    padding: '0 16px',
  },
  tooltipListItem: {
    '& div': {
      display: 'flex',
      flexDirection: 'column',
      rowGap: '4px',
    },
  },
}));

interface StyledTooltipProps {
  messages: string[];
  children: any;
}

const StyledTooltipWithMessages = ({ messages, children }: StyledTooltipProps) => {
  const classes = useStyles();

  return (
    <Tooltip
      title={
        <ul className={classes.tooltipList}>
          {messages.map((message, index) => {
            return <li key={index}>{message}</li>;
          })}
        </ul>
      }
      classes={{ tooltip: classes.tooltipView }}
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltipWithMessages;
