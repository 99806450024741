import numeral from 'numeral';

const formatNum = (val: string) => {
  const num = parseFloat(val);
  if (isNaN(num)) return 0;
  if (num > 999999) return numeral(num).format('0.00a').toUpperCase();
  return numeral(num).format('0,0.00');
};

export const formatCurrency = (number, currency) => {
  if (currency === 'VND' || currency === 'IDR') {
    return numeral(number).format('0[.]00a').toUpperCase();
  }
  return formatNum(number);
  // return numeral(number).format('0,0[.]00');
};

export const formatNumber = (number) => {
  return numeral(number).format('0[,]0[.]00a').toUpperCase();
};

export const parseObjectToParam = (obj: any) => {
  let str = '';
  for (const key in obj) {
    if (str != '') {
      str += '&';
    }
    if (obj[key] !== undefined) {
      str += key + '=' + encodeURIComponent(obj[key]);
    }
  }
  return str;
};

export const handlePercentAbs = (value) => {
  return Math.abs(value);
};

export const formatCurrencyNumber = (number, currency = '') => {
  const value = currency === '%' ? (number * 100).toFixed(2) : number;
  const result = numeral(value);

  const decimalNum = number - Math.floor(number);
  if (decimalNum == 0) {
    return result.format('0,0');
  }
  if (Math.round(decimalNum * 100) % 10 == 0) {
    return result.format('0,0.0');
  }
  return result.format('0,0.00');
};

export const formatCurrencyResponsive = (number, currency) => {
  if (currency === 'VND' || currency === 'IDR') {
    return numeral(number).format('0[.]00a').toUpperCase();
  }
  return formatNumResponsive(number);
};

const formatNumResponsive = (val: string) => {
  const number = parseFloat(val);
  const lookup = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'K' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return number >= item.value;
    });
  return item ? (number / item.value).toFixed(0).replace(rx, '$1') + item.symbol : '0';
};

export const formatPercentNumber = (number) => {
  const result = numeral(number);
  const decimalNum = number - Math.floor(number);
  if (decimalNum == 0) {
    return result.format('0');
  }
  return result.format('0,0.00');
};
