import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Close = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.57676 5.94986L11.4749 2.05176C11.684 1.84266 11.8014 1.55908 11.8014 1.26338C11.8014 0.967676 11.684 0.684087 11.4749 0.474995C11.2658 0.265904 10.9822 0.148438 10.6865 0.148438C10.3908 0.148438 10.1072 0.265904 9.8981 0.474995L6 4.3731L2.1019 0.474995C1.8928 0.265904 1.60922 0.148438 1.31352 0.148438C1.01782 0.148438 0.734227 0.265904 0.525136 0.474995C0.316044 0.684087 0.198578 0.967676 0.198578 1.26338C0.198578 1.55908 0.316045 1.84266 0.525136 2.05176L4.42324 5.94986L0.525137 9.84796C0.316046 10.0571 0.198578 10.3406 0.198578 10.6363C0.198578 10.932 0.316044 11.2156 0.525136 11.4247C0.734227 11.6338 1.01782 11.7513 1.31352 11.7513C1.60922 11.7513 1.89281 11.6338 2.1019 11.4247L6 7.52662L9.8981 11.4247C10.1072 11.6338 10.3908 11.7513 10.6865 11.7513C10.9822 11.7513 11.2658 11.6338 11.4749 11.4247C11.684 11.2156 11.8014 10.932 11.8014 10.6363C11.8014 10.3406 11.684 10.0571 11.4749 9.84796L7.57676 5.94986Z" />
  </SvgIcon>
);

export default Close;
