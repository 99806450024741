import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Move = (props: SvgIconProps) => (
  <SvgIcon {...props} width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.7064 2.2862L8.57167 0.147875C8.25621 -0.168109 7.71709 0.0559224 7.71709 0.502312V1.98443H0.998957C0.447319 1.98443 0 2.43249 0 2.98504L1.44039e-05 2.99047L0 2.99591V9.99938C0 10.5519 0.447319 11 0.998957 11C1.5506 11 1.99791 10.5519 1.99791 9.99938V3.98566H7.71709V5.48867C7.71709 5.9359 8.25621 6.15909 8.57167 5.84311L10.7064 3.70478C11.0979 3.31273 11.0979 2.67825 10.7064 2.2862Z" />
  </SvgIcon>
);

export default Move;
