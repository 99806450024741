import React from 'react';
import { debounce } from 'lodash';
import { nanoid } from 'nanoid';
import { useLog } from '@eip/next/lib/log';

const log = useLog('dbf:history-local');

function stackReducer(state: { stack: any[]; step: number }, action: { type: string; payload?: any }) {
  switch (action.type) {
    case 'PUSH': {
      let { stack, step } = state;
      const serializedItem = JSON.stringify({
        historyId: nanoid(),
        dashboard: action.payload,
      });
      if (serializedItem === stack[step]) break;

      const nextStep = step + 1;
      log('push', { nextStep, item: action.payload });
      state = {
        step: nextStep,
        stack: stack.slice(0, nextStep).concat(serializedItem),
      };
      break;
    }
    case 'UNDO': {
      const { step } = state;
      if (step > 0) {
        state = { ...state, step: step - 1 };
      }
      break;
    }
    case 'REDO': {
      const { step, stack } = state;
      if (step < stack.length - 1) {
        state = { ...state, step: step + 1 };
      }
      break;
    }
  }

  log({ action: action.type, state });

  return state;
}

export function useDashboardHistory(initDashboard?: Dashboard) {
  const [state, dispatch] = React.useReducer(
    stackReducer,
    initDashboard
      ? {
          stack: [].concat(
            JSON.stringify({
              historyId: nanoid(),
              dashboard: initDashboard,
            }),
          ),
          step: 0,
        }
      : { stack: [], step: -1 },
  );

  const { stack, step } = state;

  const undo = React.useCallback(() => {
    dispatch({ type: 'UNDO' });
  }, []);
  const redo = React.useCallback(() => {
    dispatch({ type: 'REDO' });
  }, []);

  const push = React.useCallback(
    debounce(
      (item) => {
        dispatch({ type: 'PUSH', payload: item });
      },
      1000,
      { trailing: true },
    ),
    [],
  );

  return {
    current: step > -1 ? stack[step] : null,
    undo,
    redo,
    push,
  };
}
