import * as React from 'react';
import { useSetAtom } from 'jotai';

import { TextareaAutosize, IconButton, Box, makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { formulaPopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-formula';
import { narrativePopup } from '@ep/insight-ui/system/block/etable/etable-config/atom/editor-narrative';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';

import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    '& textarea': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
  iconsContainer: {
    padding: 0,
    position: 'absolute',
    top: 8,
    right: 8,
    display: 'flex',
    rowGap: '8px',
    alignItems: 'center',
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
  },
});

const TextareaWithPlugins = ({
  value,
  onChange,
  placeholder,
  isSupportFormula,
  isSupportNarrative = false,
  isSupportJSON = false,
  initialFocus,
  ...rest
}: any) => {
  const classes = useStyles();
  const [showEditor, setShowEditor] = React.useState(false);

  const displayFormula = useSetAtom(formulaPopup);
  const displayNarrative = useSetAtom(narrativePopup);

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (ref.current && initialFocus) {
      ref.current.focus();
    }
  }, [initialFocus]);

  return (
    <Box className={classes.container}>
      <TextareaAutosize
        ref={ref}
        minRows={5}
        style={{ width: '100%' }}
        maxRows={5}
        value={typeof value === 'object' ? JSON.stringify(value) : value}
        onChange={(e) => onChange(e.target.value, e)}
        placeholder={placeholder}
        {...rest}
      />
      <Box className={classes.iconsContainer}>
        {isSupportNarrative && (
          <IconButton
            onClick={(event) => {
              const availVars = [];
              event.stopPropagation();
              displayNarrative({
                isDisplay: true,
                content: value,
                ref: event.currentTarget,
                backboneConfig: { mapping: {} },
                availableVars: availVars,
                submit: (content) => {
                  onChange(content);
                },
              });
            }}
          >
            <Icon type={'ri/MdEditNote/blue5'} />
          </IconButton>
        )}
        {isSupportFormula && (
          <IconButton
            onClick={(event) => {
              const availVars = [];
              event.stopPropagation();
              displayFormula({
                isDisplay: true,
                content: value,
                ref: event.currentTarget,
                backboneConfig: { mapping: {} },
                availableVars: availVars,
                submit: (content) => {
                  onChange(content);
                },
              });
            }}
          >
            <Icon type={'rmx/functions-icon/blue6'} />
          </IconButton>
        )}
        {isSupportJSON && (
          <>
            <IconButton
              onClick={(event) => {
                setShowEditor(true);
              }}
            >
              <Icon type={'ic/bi:filetype-json/blue5'} />
            </IconButton>
            {showEditor && (
              <PopupJsonEditor
                title={'Editor'}
                open={showEditor}
                onClose={() => setShowEditor(false)}
                value={value ? safeJsonParse(value, value) : {}}
                onValueChange={(result) => {
                  onChange(JSON.stringify(result));
                  setShowEditor(false);
                }}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default TextareaWithPlugins;
