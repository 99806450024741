import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Arrowdown = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.98983 1.68972e-05H1.99854C1.80021 -0.00113686 1.60602 0.0568177 1.44074 0.166491C1.27546 0.276165 1.14658 0.432588 1.07052 0.615819C0.994464 0.79905 0.974681 1.00079 1.01369 1.19531C1.05271 1.38983 1.14875 1.56832 1.28957 1.70803L4.28522 4.70455C4.37804 4.79816 4.48849 4.87247 4.61017 4.92318C4.73185 4.97389 4.86237 5 4.99419 5C5.12601 5 5.25652 4.97389 5.3782 4.92318C5.49989 4.87247 5.61033 4.79816 5.70316 4.70455L8.6988 1.70803C8.83962 1.56832 8.93567 1.38983 8.97468 1.19531C9.01369 1.00079 8.99391 0.79905 8.91785 0.615819C8.8418 0.432588 8.71291 0.276165 8.54763 0.166491C8.38235 0.0568177 8.18817 -0.00113686 7.98983 1.68972e-05Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Arrowdown;
