/* eslint-disable react/prop-types */
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { createPortal } from 'react-dom';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
const useStyles = makeStyles(() => ({
  label: {
    color: colors.text.subdued,
    marginLeft: '48px',
  },
}));

export type IValueEmptyRows = {
  tableDOM: React.MutableRefObject<any>;
  label?: string;
  rowHeight?: 40 | 56;
};
const EmptyRows = ({ tableDOM, label = 'Empty', rowHeight = 56 }: IValueEmptyRows) => {
  const classes = useStyles();

  const container = React.useMemo(() => {
    if (!tableDOM.current) return null;
    return tableDOM.current.querySelector('.ag-full-width-container') as HTMLDivElement;
  }, [tableDOM.current]);

  const bodyViewport = React.useMemo(() => {
    if (!tableDOM.current) return null;
    return tableDOM.current.querySelector('.ag-body-viewport.ag-layout-normal.ag-row-no-animation') as HTMLDivElement;
  }, [tableDOM.current]);

  if (!bodyViewport) return null;
  React.useLayoutEffect(() => {
    bodyViewport.setAttribute('style', `height: ${rowHeight}px`);
    return () => {
      bodyViewport.setAttribute('style', `height: auto`);
    };
  }, [bodyViewport]);

  return (
    container &&
    createPortal(
      <Box
        className={
          'ag-row-even ag-row ag-row-level-0 ag-row-loading ag-full-width-row ag-row-position-absolute ag-row-first ag-row-last ag-after-created ag-full-width-focus ag-row-focus'
        }
        style={{ height: rowHeight }}
      >
        <Typography variant="body2" className={classes.label}>
          {label}
        </Typography>
      </Box>,
      container,
    )
  );
};
export default EmptyRows;
