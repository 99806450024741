import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const BasicArrowRight = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.99952 9.00307L10.5759 9.00307L9.28648 10.2918C9.09827 10.4799 8.99253 10.735 8.99253 11.001C8.99253 11.2671 9.09827 11.5222 9.28648 11.7103C9.47469 11.8984 9.72996 12.0041 9.99614 12.0041C10.2623 12.0041 10.5176 11.8984 10.7058 11.7103L13.7043 8.71337C13.798 8.6205 13.8724 8.51001 13.9231 8.38828C13.9739 8.26654 14 8.13597 14 8.00409C14 7.87222 13.9739 7.74164 13.9231 7.61991C13.8724 7.49817 13.798 7.38769 13.7043 7.29482L10.7058 4.29788C10.6126 4.20474 10.502 4.13085 10.3802 4.08044C10.2584 4.03004 10.1279 4.00409 9.99614 4.00409C9.86434 4.00409 9.73384 4.03004 9.61207 4.08044C9.49031 4.13085 9.37967 4.20474 9.28648 4.29788C9.19329 4.39103 9.11936 4.5016 9.06893 4.6233C9.01849 4.745 8.99253 4.87543 8.99253 5.00716C8.99253 5.13888 9.01849 5.26932 9.06893 5.39101C9.11936 5.51271 9.19329 5.62329 9.28648 5.71643L10.5759 7.00511L2.99952 7.00511C2.73443 7.00511 2.4802 7.11036 2.29275 7.29771C2.10531 7.48505 2 7.73915 2 8.00409C2 8.26904 2.10531 8.52313 2.29275 8.71047C2.4802 8.89782 2.73443 9.00307 2.99952 9.00307Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default BasicArrowRight;
