import * as React from 'react';
import { makeStyles } from '@material-ui/core';

interface FlexBoxProps {
  flexDirection?: 'row' | 'column';
  justifyContent?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  alignItems?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  children?: React.ReactNode;
  className?: string;
  wrap?: 'wrap' | 'nowrap';
}

interface FlexBoxItemProps {
  flex?: string;
  justifySeft?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  alignSeft?: 'center' | 'flex-start' | 'flex-end' | 'space-between';
  children?: React.ReactNode;
  className?: string;
  width?: string;
}

const useStyle = makeStyles<FlexBoxItemProps | FlexBoxProps>(() => ({
  root: {
    display: 'flex',
    flexDirection: (props: FlexBoxProps) => props.flexDirection,
    justifyContent: (props: FlexBoxProps) => props.justifyContent,
    alignItems: (props: FlexBoxProps) => props.alignItems,
    flexWrap: (props: FlexBoxProps) => props.wrap,
  },
  item: {
    alignSelf: (props: FlexBoxItemProps) => props.alignSeft,
    justifySelf: (props: FlexBoxItemProps) => props.justifySeft,
    flex: (props: FlexBoxItemProps) => props.flex,
    width: (props: FlexBoxItemProps) => props.width,
  },
}));

const FlexBox: React.FC<FlexBoxProps> = ({
  children,
  flexDirection,
  alignItems,
  justifyContent,
  className = '',
  wrap,
}: FlexBoxProps) => {
  const classes = useStyle({ flexDirection, alignItems, justifyContent, wrap });
  return <div className={`${classes.root} ${className}`}>{children}</div>;
};

export const FlexBoxItem: React.FC<FlexBoxItemProps> = ({
  children,
  flex,
  alignSeft,
  justifySeft,
  className,
  width,
}: FlexBoxItemProps) => {
  const classes = useStyle({ alignSeft, justifySeft, flex, width });
  return <div className={`${classes.item} ${className}`}>{children}</div>;
};
export default FlexBox;
