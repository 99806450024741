import { createTheme as createMuiTheme } from '@material-ui/core/styles';
import { FiraCodettf } from './fonts/FiraCode-VariableFont_wght.ttf';

export const seedClassName = 'eip1';

export const colors = {
  red: [' #FCEEEC', ' #F8D5CF', ' #F6C4BC', ' #ED9081', ' #E86F5B', ' #E4553E', ' #E03C21', ' #D4290D', ' #B9240C'],
  orange: [' #FEF2EC', ' #FBDECF', ' #FAD2BC', ' #F6AA82', ' #F3905D', ' #F17D40', ' #EE6923', ' #ED5C10', ' #E55000'],
  green: [' #ECF8F4', ' #CEEEE4', ' #BBE7D9', ' #80D3B9', ' #59C5A3', ' #3CBB93', ' #1EB183', ' #0BA373', ' #098F65'],
  blue: [' #EBF4FB', ' #CCE3F5', ' #B8D8F1', ' #7AB5E5', ' #529FDE', ' #338ED8', ' #147DD2', ' #006EC6', ' #0060AD'],
  charts: ['#204D77', '#F17D40', '#338ED8', '#3CBB93', '#E4553E', '#F0AD4E', '#5BC0DE', '#3CBB93', '#D4DDED'],
  action: {
    primaryDefault: '#006EC6',
    secondaryDefault: '#EDF2F9',
    subtleDefault: '',
    primaryHovered: '#338ED8',
    secondaryHovered: '#E4E7E9',
    subtleHovered: '#E4E7E9',
    primaryPressed: '#204D77',
    secondaryPressed: '#204D77',
    subtlePressed: '#204D77',
    primaryActive: '#EBF4FB',
    secondaryActive: '#EBF4FB',
    subtleActive: '',
    primaryDisabled: '#F6F7F8',
    secondaryDisabled: '#F6F7F8',
    subtleDisabled: '',
  },
  text: {
    default: '#253746',
    critical: '#D4290D',
    subdued: '#8C98A4',
    warning: '#ED5C10',
    disabled: '#C2C7CB',
    success: '#0BA373',
    iconOn: '#FFFFFF',
    highLight: '#006EC6',
  },
  border: {
    default: '#C2C7CB',
    critical: '#D4290D',
    subdued: '#E4E7E9',
    divider: '#E5E5E5',
    warning: '#ED5C10',
    success: '#0BA373',
    highlight: '#006EC6',
  },
  surface: {
    default: '#FFFFFF',
    highlight: '#F8FAFD',
    success: '#ECF8F4',
    disable: '#F6F7F8',
    highlightHovered: '#EBF4FB',
    transparent: 'transparent',
    darkDefault: '#253746',
  },
  icon: {
    default: '#3F4F5C',
    critical: '#D4290D',
    subdued: '#8D979E',
    warning: '#ED5C10',
    hovered: '#253746',
    success: '#0BA373',
    disabled: '#C2C7CB',
    highlight: '#006EC6',
    iconOn: '#FFFFFF',
  },
  shadow: {
    default: '0px 6px 12px rgba(140, 152, 164, 0.08)',
    hover: '0px 6px 12px rgba(140, 152, 164, 0.25)',
  },
};

const firaCode = {
  fontFamily: 'Fira-Code',
  fontStyle: 'semi-bold',
  fontDisplay: 'swap',
  fontWeight: '600',
  src: `
   local('TitillumWeb'),
   local('TitillumWeb-SemiBold'),
   url(${FiraCodettf}) format('ttf')
 `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

export default createMuiTheme({
  breakpoints: {
    keys: ['xs', 'sm', 'md', 'lg', 'xl'],
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
  spacing: 4,
  direction: 'ltr',
  mixins: {
    toolbar: {
      minHeight: 68,
      '@media (min-width:0px) and (orientation: landscape)': {
        minHeight: 60,
      },
      '@media (min-width:600px)': {
        minHeight: 76,
      },
    },
  },
  overrides: {
    MuiExpansionPanelSummary: {
      root: {
        minHeight: '40px',
        padding: '0px 15px',
      },
      content: {
        margin: '8px 0',
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        padding: '4px 12px 8px',
      },
    },
    MuiInputBase: {
      root: {
        fontWeight: 400,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: '10px',
      },
    },
    MuiSvgIcon: {
      root: {
        height: 'auto',
      },
    },
    MuiCheckbox: {
      root: {
        width: '16px',
        padding: '0',
        backgroundColor: 'transparent',
        marginRight: '10px',
        '& .eip1-MuiSvgIcon-root': {
          width: '16px',
        },
      },
    },
    MuiRadio: {
      root: {
        width: '16px',
        padding: '0',
        backgroundColor: 'transparent',
        marginRight: '10px',
        '& .eip1-MuiSvgIcon-root': {
          width: '14px',
        },
      },
    },
    MuiTooltip: {
      tooltip: {
        padding: '4px',
        boxSizing: 'border-box',
      },
    },
    MuiToggleButton: {
      root: {
        padding: '6px 16px',
        '&$selected': {
          color: colors.icon.highlight,
          backgroundColor: colors.surface.default,
        },
      },
    },
    MuiIconButton: {
      root: {
        borderRadius: '4px',
        backgroundColor: colors.surface.default,
        '&:hover': {
          backgroundColor: colors.action.subtleHovered,
        },
        '&.active': {
          color: colors.icon.highlight,
          backgroundColor: colors.surface.default,
        },
        '&:active': {
          color: '#FFFFFF',
          backgroundColor: colors.action.primaryDefault,
        },
        '&:disabled': {
          color: colors.icon.disabled,
        },
      },
      sizeSmall: {
        width: '32px',
        height: '32px',
      },
    },
    MuiAutocomplete: {
      '& .eip1-MuiAutocomplete-hasPopupIcon .eip1-MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"]': {
        paddingRight: '0px',
      },
      listbox: {
        padding: '0px',
      },
      inputRoot: {
        '&&[class*="MuiOutlinedInput-root"] $input': {
          padding: '3px',
        },
      },
      endAdornment: {
        top: 'calc( 50% - 2px )',
        transform: 'translateY(-50%)',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: '26px',
      },
    },
    MuiList: {
      padding: {
        paddingTop: '0',
        paddingBottom: '0',
      },
    },
    MuiInputAdornment: {
      positionEnd: {
        color: colors.text.subdued,
        '& p': {
          color: 'inherit',
          fontWeight: 'normal',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        '& $notchedOutline': {
          borderWidth: '1px',
        },
        '&:hover $notchedOutline': {
          borderWidth: '1px',
        },
        '&$focused $notchedOutline': {
          borderWidth: '1px',
          borderColor: colors.border.highlight,
        },
        '& .eip1-MuiSelect-outlined': {
          paddingRight: '0px',
        },
      },
      adornedStart: {
        paddingLeft: '0px',
      },
      inputMarginDense: {
        paddingTop: '8px',
        paddingBottom: '8px',
      },
      notchedOutline: {
        borderWidth: '1px',
      },
    },
    MuiButton: {
      root: {
        color: colors.text.default,
        borderRadius: '4px',
        padding: '6px 8px',
        minWidth: '32px',
      },
      containedSizeSmall: {
        minWidth: '48px',
        padding: '1px 8px',
      },
      label: {
        fontSize: '14px',
        lineHeight: '14px',
        padding: '0px 0px',
        fontWeight: 500,
        letterSpacing: '0.0125em',
      },
      containedPrimary: {
        color: '#FFFFFF',
        boxShadow: 'none',
        backgroundColor: colors.action.primaryDefault,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.action.primaryHovered,
        },
        '&.active': {
          color: colors.text.highLight,
          backgroundColor: colors.action.primaryActive,
        },
        '&:active': {
          color: '#FFFFFF',
          backgroundColor: colors.action.primaryDefault,
        },
        '&:disabled': {
          color: colors.text.disabled,
          backgroundColor: colors.action.primaryDisabled,
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        color: colors.text.default,
        backgroundColor: colors.action.secondaryDefault,
        '&:hover': {
          boxShadow: 'none',
          backgroundColor: colors.action.secondaryHovered,
        },
        '&.active': {
          color: colors.text.highLight,
          backgroundColor: colors.action.secondaryActive,
        },
        '&:active': {
          color: '#FFFFFF',
          backgroundColor: colors.action.primaryDefault,
        },

        '&:disabled': {
          color: colors.text.disabled,
          backgroundColor: colors.action.secondaryDisabled,
        },
      },
      text: {
        padding: '6px 8px',
        '&:hover': {
          backgroundColor: colors.action.subtleHovered,
        },
        '&.active': {
          color: colors.text.highLight,
        },
        '&:active': {
          color: '#FFFFFF',
          backgroundColor: colors.action.primaryDefault,
        },
        '&:disabled': {
          color: colors.text.disabled,
          backgroundColor: 'transparent',
        },
      },
      outlined: {
        minWidth: 'auto',
        padding: '0',
        color: colors.icon.subdued,
        borderColor: colors.border.default,
        boxShadow: colors.shadow.default,
        '&:hover': {
          color: colors.icon.default,
          boxShadow: colors.shadow.hover,
          backgroundColor: 'transparent',
          borderColor: colors.border.highlight,
        },
        '&:active': {
          color: '#FFFFFF',
          backgroundColor: colors.action.primaryDefault,
        },
        '&:disabled': {
          color: colors.icon.disabled,
          borderColor: colors.border.default,
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#fff',
      },
    },
    MuiLink: {
      button: {
        '& span:hover': {
          color: 'black',
        },
      },
    },
    MuiBreadcrumbs: {
      root: {
        '& .eip1-MuiBreadcrumbs-li:hover': {
          '& .eip1-MuiLink-underlineHover': {
            textDecoration: 'none',
          },
          '& p.eip1-MuiTypography-root': {
            backgroundColor: '#E4E7E9',
            color: colors.text.default,
          },
        },
        '&:last-of-type': {
          cursor: 'default',
        },
      },
    },
    MuiPaper: {
      rounded: {
        borderRadius: '4px',
        padding: '8px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '@font-face': [firaCode],
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: colors.border.subdued,
      },
    },
  },
  palette: {
    common: {
      black: '#000',
      white: '#fff',
    },
    type: 'light',
    primary: {
      main: '#338ED8',
      light: 'rgb(91, 164, 223)',
      dark: 'rgb(35, 99, 151)',
      contrastText: '#fff',
    },
    secondary: {
      main: '#F6F7F8',
      light: 'rgb(247, 248, 249)',
      dark: 'rgb(172, 172, 173)',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    warning: {
      light: '#ffb74d',
      main: '#ff9800',
      dark: '#f57c00',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    info: {
      light: '#64b5f6',
      main: '#2196f3',
      dark: '#1976d2',
      contrastText: '#fff',
    },
    success: {
      light: '#81c784',
      main: '#4caf50',
      dark: '#388e3c',
      contrastText: 'rgba(0, 0, 0, 0.87)',
    },
    grey: {
      '50': '#fafafa',
      '100': '#f5f5f5',
      '200': '#eeeeee',
      '300': '#e0e0e0',
      '400': '#bdbdbd',
      '500': '#9e9e9e',
      '600': '#757575',
      '700': '#616161',
      '800': '#424242',
      '900': '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      default: colors.text.default,
      critical: colors.text.critical,
      subdued: colors.text.subdued,
      warning: colors.text.warning,
      disabled: colors.text.disabled,
      success: colors.text.success,
      iconOn: colors.text.iconOn,
      highLight: colors.text.highLight,
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    background: {
      paper: '#fff',
      default: '#fff',
    },
    action: {
      primaryDefault: colors.action.primaryDefault,
      secondaryDefault: colors.action.secondaryDefault,
      subtleDefault: colors.action.subtleDefault,
      primaryHovered: colors.action.primaryHovered,
      secondaryHovered: colors.action.secondaryHovered,
      subtleHovered: colors.action.subtleHovered,
      primaryPressed: colors.action.primaryPressed,
      secondaryPressed: colors.action.secondaryPressed,
      subtlePressed: colors.action.subtlePressed,
      primaryActive: colors.action.primaryActive,
      secondaryActive: colors.action.secondaryActive,
      subtleActive: colors.action.subtleActive,
      primaryDisabled: colors.action.primaryDisabled,
      secondaryDisabled: colors.action.secondaryDisabled,
      subtleDisabled: colors.action.subtleDisabled,
    },
    surface: {
      default: colors.surface.default,
      highlight: colors.surface.highlight,
      success: colors.surface.success,
      disable: colors.surface.disable,
      highlightHovered: colors.surface.highlightHovered,
      transparent: colors.surface.transparent,
      darkDefault: colors.surface.darkDefault,
    },
    chart: {
      charts01: '#204D77',
      charts05: '#E4553E',
      charts01Disable: '#8FA6BB',
      charts02: '#F17D40',
      charts06: '#F0AD4E',
      charts02Disable: '#F8BE9F',
      charts03: '#338ED8',
      charts07: '#5BC0DE',
      charts03Disable: '#99C7EC',
      charts04: '#3CBB93',
      charts08: '#D4DDED',
      charts04Disable: '#9DDDC9',
    },
    tag: {
      tag01: '#FFE4CC',
      tag02: '#C2DDF3',
      tag03: '#C4EFD0',
      tag04: '#D4D7DA',
      tag05: '#FFCDCD',
      tag06: '#DEF2F8',
      tag07: '#F5D6EC',
      tag08: '#CEFDF7',
      tag09: '#FFEFB5',
      tag10: '#DFD6F7',
    },
    icon: {
      default: colors.icon.default,
      critical: colors.icon.critical,
      subdued: colors.icon.subdued,
      warning: colors.icon.warning,
      hovered: colors.icon.hovered,
      success: colors.icon.success,
      disabled: colors.icon.disabled,
      highlight: colors.icon.highlight,
      iconOn: colors.icon.iconOn,
    },
    border: {
      default: colors.border.default,
      critical: colors.border.critical,
      subdued: colors.border.subdued,
      warning: colors.border.warning,
      success: colors.border.success,
      highlight: colors.border.highlight,
    },
  },
  props: {},
  shadows: [
    'none',
    colors.shadow.default,
    colors.shadow.hover,
    '0px 3px 3px -2px rgba(0,0,0,0.2),0px 3px 4px 0px rgba(0,0,0,0.14),0px 1px 8px 0px rgba(0,0,0,0.12)',
    '0px 2px 4px -1px rgba(0,0,0,0.2),0px 4px 5px 0px rgba(0,0,0,0.14),0px 1px 10px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 5px 8px 0px rgba(0,0,0,0.14),0px 1px 14px 0px rgba(0,0,0,0.12)',
    '0px 3px 5px -1px rgba(0,0,0,0.2),0px 6px 10px 0px rgba(0,0,0,0.14),0px 1px 18px 0px rgba(0,0,0,0.12)',
    '0px 4px 5px -2px rgba(0,0,0,0.2),0px 7px 10px 1px rgba(0,0,0,0.14),0px 2px 16px 1px rgba(0,0,0,0.12)',
    '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
    '0px 5px 6px -3px rgba(0,0,0,0.2),0px 9px 12px 1px rgba(0,0,0,0.14),0px 3px 16px 2px rgba(0,0,0,0.12)',
    '0px 6px 6px -3px rgba(0,0,0,0.2),0px 10px 14px 1px rgba(0,0,0,0.14),0px 4px 18px 3px rgba(0,0,0,0.12)',
    '0px 6px 7px -4px rgba(0,0,0,0.2),0px 11px 15px 1px rgba(0,0,0,0.14),0px 4px 20px 3px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 12px 17px 2px rgba(0,0,0,0.14),0px 5px 22px 4px rgba(0,0,0,0.12)',
    '0px 7px 8px -4px rgba(0,0,0,0.2),0px 13px 19px 2px rgba(0,0,0,0.14),0px 5px 24px 4px rgba(0,0,0,0.12)',
    '0px 7px 9px -4px rgba(0,0,0,0.2),0px 14px 21px 2px rgba(0,0,0,0.14),0px 5px 26px 4px rgba(0,0,0,0.12)',
    '0px 8px 9px -5px rgba(0,0,0,0.2),0px 15px 22px 2px rgba(0,0,0,0.14),0px 6px 28px 5px rgba(0,0,0,0.12)',
    '0px 8px 10px -5px rgba(0,0,0,0.2),0px 16px 24px 2px rgba(0,0,0,0.14),0px 6px 30px 5px rgba(0,0,0,0.12)',
    '0px 8px 11px -5px rgba(0,0,0,0.2),0px 17px 26px 2px rgba(0,0,0,0.14),0px 6px 32px 5px rgba(0,0,0,0.12)',
    '0px 9px 11px -5px rgba(0,0,0,0.2),0px 18px 28px 2px rgba(0,0,0,0.14),0px 7px 34px 6px rgba(0,0,0,0.12)',
    '0px 9px 12px -6px rgba(0,0,0,0.2),0px 19px 29px 2px rgba(0,0,0,0.14),0px 7px 36px 6px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 20px 31px 3px rgba(0,0,0,0.14),0px 8px 38px 7px rgba(0,0,0,0.12)',
    '0px 10px 13px -6px rgba(0,0,0,0.2),0px 21px 33px 3px rgba(0,0,0,0.14),0px 8px 40px 7px rgba(0,0,0,0.12)',
    '0px 10px 14px -6px rgba(0,0,0,0.2),0px 22px 35px 3px rgba(0,0,0,0.14),0px 8px 42px 7px rgba(0,0,0,0.12)',
    '0px 11px 14px -7px rgba(0,0,0,0.2),0px 23px 36px 3px rgba(0,0,0,0.14),0px 9px 44px 8px rgba(0,0,0,0.12)',
    '0px 11px 15px -7px rgba(0,0,0,0.2),0px 24px 38px 3px rgba(0,0,0,0.14),0px 9px 46px 8px rgba(0,0,0,0.12)',
  ],
  typography: {
    htmlFontSize: 16,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.25,
      letterSpacing: '-0.01562em',
    },
    h2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1.5rem',
      lineHeight: 1.167,
      letterSpacing: '-0.00833em',
    },
    h3: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '3rem',
      lineHeight: 1.167,
      letterSpacing: '0em',
    },
    h4: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '1rem',
      lineHeight: 1.25,
      letterSpacing: '0.00735em',
    },
    h5: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: 1.333,
      letterSpacing: '0em',
    },
    h6: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.75rem',
      lineHeight: 1.333,
      color: '#8C98A4',
      textTransform: 'uppercase',
      letterSpacing: '0.0075em',
    },
    subtitle1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: 1.75,
      letterSpacing: '0.00938em',
    },
    subtitle2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.57,
      letterSpacing: '0.00714em',
    },
    body1: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.00938em',
    },
    body2: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    button: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.875rem',
      lineHeight: 1.75,
      letterSpacing: '0.02857em',
      textTransform: 'none',
    },
    caption: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 1.66,
      letterSpacing: '0.03333em',
    },
    overline: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.75rem',
      lineHeight: 2.66,
      letterSpacing: '0.08333em',
      textTransform: 'uppercase',
    },
  },
  shape: {
    borderRadius: 4,
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
      easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
      easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
      sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
    },
    duration: {
      shortest: 150,
      shorter: 200,
      short: 250,
      standard: 300,
      complex: 375,
      enteringScreen: 225,
      leavingScreen: 195,
    },
  },
  zIndex: {
    mobileStepper: 1000,
    speedDial: 1050,
    appBar: 1100,
    drawer: 1200,
    modal: 1300,
    snackbar: 1400,
    tooltip: 1500,
  },
  nprogress: {
    color: '#000',
  },
  colors: colors,
});

export const variantMapping = {
  body3: {
    element: 'p',
    styles: {
      fontFamily: '"Fira Code", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  body4: {
    element: 'p',
    styles: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '0.625rem',
      lineHeight: 1.2,
      letterSpacing: '0.01071em',
    },
  },
  body5: {
    element: 'p',
    styles: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 400,
      fontSize: '0.625rem',
      lineHeight: 1.2,
      color: '#8C98A4',
      letterSpacing: '0.01071em',
    },
  },
};

export const TooltipMapping = {
  position: 'relative',
  loot: {
    position: 'absolute',
    marginTop: 80,
    width: '100% !important',
    '@media (max-width:767px)': {
      marginTop: 150,
    },
  },

  root: {
    width: '180px',
    position: 'fixed',
    transform: 'translate(10%, -60%)',
    transition: 'all .1s ease',
    pointerEvents: 'none',
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    '& table': {
      background: '#fff',
      boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
      borderRadius: '5px',
      width: '100%',
      borderSpacing: 0,
      '& thead': {
        textAlign: 'left',
        background: '#F8FAFD',
        fontSize: '14px',
        '& th': {
          padding: '10px',
          textTransform: 'uppercase',
          fontWeight: 500,
          fontSize: 12,
          '& .subTitle': {
            fontSize: 10,
            color: '#8C98A4',
            marginBottom: 5,
            textTransform: 'capitalize',
          },
          '& .titleTool': {
            fontSize: 12,
            width: '99%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
          },
        },
      },
    },

    '& .wraperContent': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingRight: '14px',
      paddingLeft: '14px',
      paddingTop: '2px',
      lineHeight: 2.3,
      '& .icon': {
        display: 'inline-block',
        borderWidth: '2px',
        marginRight: '5px',
        height: '10px',
        width: '10px',
        borderRadius: '50%',
      },
      '& .rightContent': {
        fontWeight: 500,
        '& .lastContent': {
          fontWeight: 400,
        },
      },
    },
  },
  legendPieChart: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: '0',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    lineHeight: 2.5,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    maxWidth: 100,
    '@media (max-width:767px)': {
      display: 'block',
      right: 25,
      '& li': {
        width: '100% !important',
      },
    },
    // width char <450
    '&.sm450 ': {
      position: 'unset',
      width: '100%',
      maxWidth: 'unset',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      '& li': {
        width: 'auto',
      },
    },
    '& li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      width: '100%',
      '& span': {
        display: 'inline-block',
        width: '12px',
        height: '12px',
        marginRight: '10px',
        borderRadius: '50%',
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    zIndex: '0',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
    lineHeight: 2.5,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    '@media (max-width:767px)': {
      transform: 'translate(0, 0)',
      '& li': {
        width: '35% !important',
      },
    },
    '& li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      flex: '0 0 auto',
      '& .legendItem__checkbox': {
        width: '12px',
        height: '12px',
        borderRadius: '2px',
        backgroundColor: 'transparent',
        border: '2px solid #C2C7CB',
        marginRight: '8px',
        '&.active': {
          border: 'none',
          position: 'relative',
          '& .checked': {
            width: '100%',
            height: '100%',
            position: 'absolute',
            '&.after': {
              backgroundColor: 'white',
              borderRadius: '5px',
              width: '4px',
              top: '50%',
              left: '50%',
              height: '1.75px',
              transform: 'translate(calc(-50% - 1.25px), calc(-50% + 0.75px)) rotate(45deg)',
            },
            '&.before': {
              borderColor: 'white',
              backgroundColor: 'white',
              borderRadius: '5px',
              width: '6.5px',
              top: '50%',
              left: '50%',
              height: '1.75px',
              transform: 'translate(calc(-50% + 0.8px), calc(-50% + 0px)) rotate(-50deg)',
            },
          },
        },
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
    '& li + li': {
      marginLeft: '30px',
    },
  },
  totalValue: {
    position: 'absolute',
    cursor: 'default',
    top: 0,
    left: '2rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '32px',
    color: '#253746',
    margin: 0,
    '& span': {
      fontSize: '16px',
      marginLeft: '15px',
      color: '#0BA373',
      fontWeight: 500,
    },
    '& .currency': {
      fontSize: '32px',
      color: '#253746',
      marginLeft: 0,
      marginRight: '5px',
      textDecoration: 'underline',
    },
    '& svg': {
      marginRight: 4,
    },
    '@media (max-width:767px)': {
      marginTop: 100,
      left: 0,
    },
  },
  totalValueLine: {
    position: 'absolute',
    cursor: 'default',
    top: 0,
    left: '15px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '32px',
    color: '#253746',
    margin: 0,
    '& span': {
      fontSize: '16px',
      marginLeft: '15px',
      color: '#0BA373',
      fontWeight: 500,
    },
    '& .currency': {
      fontSize: '32px',
      color: '#253746',
      marginLeft: 0,
      marginRight: '5px',
      textDecoration: 'underline',
    },
    '& svg': {
      marginRight: 4,
    },
    '@media (max-width:767px)': {
      marginTop: 0,
      left: 0,
    },
  },
  legendLine: {
    display: 'flex',
    flexDirection: 'row',
    position: 'absolute',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    lineHeight: 2.2,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    '@media (max-width:767px)': {
      top: 0,
    },
    '& li': {
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      padding: '5px',
      transition: 'all .3s ease',
      '& span': {
        display: 'inline-block',
        width: '12px',
        height: '12px',
        marginRight: '10px',
        borderRadius: '50%',
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
    '& li:hover': {
      background: '#F8FAFD',
      borderRadius: '5px',
      textAlign: 'center',
    },
  },
  popLegend: {
    display: 'none',
    position: 'absolute',
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    pointerEvents: 'none',
    transition: 'all .1s ease',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    borderRadius: '4px',
    width: '150px',
    padding: '15px',
    margin: 0,
    transform: 'translate(-100%, 40%)',
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      right: '5rem',
    },
    '& .contentWrapp': {
      display: 'flex',
      '& .leftContent': {
        fontWeight: '500',
        marginRight: '5px',
      },
    },
    '& .label': {
      color: '#8C98A4',
    },
  },

  popLegendSticked: {
    display: 'none',
    background: '#fff',
    position: 'absolute',
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '12px',
    pointerEvents: 'none',
    transition: 'all .1s ease',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    borderRadius: '4px',
    width: '150px',
    padding: '15px',
    margin: 0,
    transform: 'translate(-100%, 40%)',
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      right: '5rem',
    },
    '& .contentWrapp': {
      display: 'flex',
      '& .leftContent': {
        fontWeight: '500',
        marginRight: '5px',
      },
    },
    '& .label': {
      color: '#8C98A4',
    },
  },
};

export const labelStyles = {
  default: {
    root: {
      fontWeight: 'normal',
      fontSize: '14px',
      color: '#253746',
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& .eip1-MuiIconButton-label input:disabled ~ span': {
        '&:before': {
          backgroundImage: 'radial-gradient(#c2c7cb, #c2c7cb 28%,transparent 32%) !important',
        },
      },
    },
    errorIcon: {
      borderRadius: '50%',
      fontWeight: 'normal',
      width: 14,
      height: 14,
      boxShadow: 'inset 0 0 0 2px #db4c35, inset 0 -1px 0 #db4c35',
      backgroundColor: '#FCEEEC',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        boxShadow: 'inset 0 0 0 2px #db4c35, inset 0 -1px 0 #db4c35',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#E4E7E9',
      },
    },
    checkedIconError: {
      fontWeight: 'normal',
      fontSize: '14px',
      width: 14,
      height: 14,
      color: '#db4c35',
      backgroundColor: '#db4c35',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      boxShadow: 'inset 0 0 0 2px #db4c35, inset 0 -1px 0 #db4c35',
      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#db4c35',
        boxShadow: 'inset 0 0 0 2px #db4c35, inset 0 -1px 0 #db4c35',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#E4E7E9',
      },
    },
    icon: {
      fontSize: '14px',
      color: '#253746',
      borderRadius: '50%',
      width: 14,
      height: 14,
      boxShadow: 'inset 0 0 0 2px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#fff',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        boxShadow: 'inset 0 0 0 2px #006EC6, inset 0 -1px 0 #006EC6',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#E4E7E9',
      },
    },
    checkedIcon: {
      fontWeight: 'normal',
      fontSize: '14px',
      width: 14,
      height: 14,
      color: '#253746',
      backgroundColor: '#006EC6',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      boxShadow: 'inset 0 0 0 2px #006EC6, inset 0 -1px 0 #006EC6',
      '&:before': {
        display: 'block',
        width: 14,
        height: 14,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#338ED8',
        boxShadow: 'inset 0 0 0 2px #338ED8, inset 0 -1px 0 #338ED8',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: '#E4E7E9',
      },
    },
  },
};

export const tabsStyle = {
  root: {
    boderBottom: 'none',
    transition: 'all .1s ease',
    '& .customLabel': {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '16px',
      textTransform: 'capitalize',
      color: '#8C98A4',
      '& .customIcon': {
        background: '#E4E7E9',
        color: '#253746',
        fontSize: '12px',
        fontWeight: 500,
        padding: '0px 6px',
        marginLeft: '5px',
        borderRadius: '10px',
        width: '14px',
        height: '16px',
      },
    },
    '& :hover': {
      '& .customLabel': {
        color: '#253746',
      },
      '& .Mui-selected': {
        '& .customLabel': {
          color: '#006EC6',
        },
      },
    },
    '& .Mui-selected': {
      '& .customLabel': {
        color: '#006EC6',
        '& .customIcon': {
          background: '#006EC6',
          color: '#fff',
        },
      },
    },
    '& .eip1-MuiTab-textColorInherit': {
      opacity: 1,
      padding: 0,
      '& .eip1-MuiTab-wrapper': {
        flexDirection: 'unset',
        justifyContent: 'end',
      },
    },
  },
};

export const tableStyles = {
  table: {
    '& td': {
      borderBottom: '0',
      '& .titleName': {
        color: colors.border.highlight,
        fontSize: '16px',
      },
      verticalAlign: 'baseline',
    },
    '& th': {
      borderBottom: `1px solid ${colors.border.highlight}`,
      color: colors.border.highlight,
      fontSize: '16px',
    },
    '& td:first-child, & th:first-child': {
      borderRight: `1px solid ${colors.border.highlight}`,
    },
  },
};

export const boxStyle = {
  box: {
    borderRadius: 12,
    border: '0.5px solid #E4E7E9',
    '& :hover': {
      boxShadow: 'rgb(140 152 164 / 25%) 0px 6px 12px',
      borderRadius: 12,
    },
  },
};

export const contentStyle = {
  content: {
    height: '100%',
    padding: 10,
    cursor: 'default',
    '& :hover': {
      boxShadow: 'unset',
    },
    '& .value': {
      marginLeft: 5,
    },
    '& .downClass': {
      color: '#D4290D',
      '& .arrowDown': {
        transform: 'rotate(180deg)',
        '& path': {
          fill: '#D4290D',
        },
      },
    },
  },
  title: {
    marginBottom: 10,
    '& svg': {
      height: '10px',
    },
    '& button': {
      padding: 0,
      color: '#3F4F5C',
    },
    '& button:hover': {
      backgroundColor: 'transparent',
    },
    '& button:focus': {
      backgroundColor: 'transparent',
      color: '#3F4F5C',
    },
    '& h4': {
      color: '#8C98A4',
      textTransform: 'uppercase',
    },
    '& h6': {
      width: '90%',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
  },
  percent: {
    color: '#0BA373',
    '& svg': {
      fontSize: '0.8rem',
      height: '11px',
    },
    '&.warning': {
      color: '#D4290D',
      '& svg': {
        color: '#D4290D',
      },
    },
    '&.normal': {
      color: '#0BA373',
      '& svg': {
        color: '#0BA373',
      },
    },
    '&.forwardMeaning': {
      '&.warning': {
        color: '#0BA373',
        '& svg': {
          color: '#0BA373',
        },
      },
      '&.normal': {
        color: '#D4290D',
        '& svg': {
          color: '#D4290D',
        },
      },
    },
    '&.down': {
      '& svg': {
        transform: 'rotateZ(-180deg)',
      },
    },
  },
  selected: {
    border: '1px solid #006EC6',
  },
  chart: {
    '& svg': {
      width: '100%',
      height: '100%',
      color: 'transparent',
    },
    '& canvas': {
      height: '100% !important',
    },
  },
  skeleton: {
    borderRadius: '6px !important',
    alignSelf: 'flex-end',
  },
  wrapElement: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  wrapElementBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
    width: '100%',
  },
  wrapElementBoxBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexFlow: 'wrap',
    width: '100%',
    '& h4': {
      width: '100%',
    },
  },
};

export const searchStyle = {
  width: '208px',
  '& svg': {
    width: '18px',
    height: '18px',
    color: '#3F4F5C',
    marginLeft: 11,
  },
  '& ::placeholder': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    textTransform: 'capitalize',
    color: '#8C98A4',
  },
  '& > :before': {
    content: 'unset',
  },
  '& > :after': {
    content: 'unset',
  },
  '& .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#006EC6',
  },
  '& .Mui-focused .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#006EC6',
    borderRadius: '4px',
    borderWidth: '1px',
  },
  '& input': {
    padding: 0,
    height: '40px',
    width: '208px',
    caretColor: '#006EC6',
  },
  '& button': {
    opacity: 0,
    padding: 0,
    '& svg': {
      height: '11.6px',
      width: '11.6px',
    },
  },
  '& button:hover': {
    background: 'transparent',
  },
  '& .Mui-focused button': {
    opacity: 1,
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent',
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
    '& svg': {
      color: '#C2C7CB',
    },
    '& ::placeholder': {
      color: '#C2C7CB',
    },
  },
};

export const textFieldStyle = {
  width: '208px',
  '& svg': {
    width: '18px',
    height: '18px',
    color: '#3F4F5C',
  },
  '& ::placeholder': {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    color: '#8C98A4',
  },
  '& > :before': {
    content: 'unset',
  },
  '& > :after': {
    content: 'unset',
  },
  '& .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#C2C7CB',
    borderRadius: '4px',
  },
  '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#006EC6',
  },
  '& .Mui-focused .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#006EC6',
    borderRadius: '4px',
    borderWidth: '1px',
  },
  '& input': {
    padding: '0 10px',
    height: '32px',
    width: '208px',
    caretColor: '#006EC6',
  },
  '&.eip1-MuiOutlinedInput-multiline': {
    padding: '10px',
    minHeight: '22px',
    width: '208px',
    caretColor: '#006EC6',
  },
  '& button': {
    opacity: 0,
    padding: 0,
    '& svg': {
      height: '12px',
      width: '12px',
    },
  },
  '& button:hover': {
    background: 'transparent',
  },
  '& .Mui-focused button': {
    opacity: 1,
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#E4E7E9',
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#E4E7E9',
  },
  '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
    background: '#F6F7F8',
    '& svg': {
      color: '#C2C7CB',
    },
    '& input': {
      padding: '0 10px',
      height: '32px',
    },
    '& ::placeholder': {
      color: '#C2C7CB',
    },
  },
  '& .Mui-error.Mui-focused .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#E4553E',
  },
  '& .Mui-error:hover .eip1-MuiOutlinedInput-notchedOutline': {
    borderColor: '#E4553E',
  },
  '& .Mui-error.eip1-MuiFormHelperText-contained': {
    fontSize: '10px',
    color: '#D4290D',
    margin: '5px 0 0 0',
    '& svg': {
      height: '9px',
      width: '9px',
      color: '#D4290D',
      marginRight: '5px',
    },
  },
  isCorrect: {
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#0BA373',
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#0BA373',
    },
    '& .Mui-focused .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#0BA373',
    },
    '& .eip1-MuiFormHelperText-contained': {
      fontSize: '10px',
      color: '#0BA373',
      margin: '5px 0 0 0',
      '& svg': {
        height: '9px',
        width: '9px',
        color: '#0BA373',
        marginRight: '5px',
      },
    },
  },
};

export const metricGroup = {
  metricCardStyle: {
    marginBottom: 15,
  },
  title: {
    alignSelf: 'center',
  },
  borderIconButton: {
    padding: '6px',
  },
  borderAdd: {
    width: '100%',
    height: '73px',
    padding: '32px 0',
    border: '1px dashed #C2C7CB',
    borderRadius: 12,
    '& svg': {
      marginRight: 5,
      alignSelf: 'baseline',
    },
  },
};

export const modalStyle = {
  export: {
    box: {
      color: '#253746',
      backgroundColor: '#FFFFFF',
      padding: '14px 12px 12px 12px',
      boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
      borderRadius: '4px',
      border: 0,
      '& h4': {
        borderBottom: '1px solid #E4E7E9',
        paddingBottom: 10,
      },
      '& svg': {
        position: 'absolute',
        right: '10px',
        display: 'inline-block',
        color: '#3F4F5C',
        pointerEvents: 'none',
      },
    },
    select: {
      color: '#253746',
      fontSize: 14,
      width: '100%',
      margin: '16px 0',
      border: '1px solid #C2C7CB',
      borderRadius: '4px',
      '& .eip1-MuiSelect-select': {
        padding: '10px 0px 10px 8px',
      },
      '& .eip1-MuiOutlinedInput-notchedOutline': {
        border: 0,
      },
    },
    field: {
      width: '100%',

      '& :before': {
        borderBottom: 0,
      },
      '& :after': {
        borderBottom: 0,
      },
      '& .eip1-MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottom: 0,
      },
      '& input': {
        padding: '10px 0px 10px 8px',
        border: '1px solid #C2C7CB',
        borderRadius: '4px',
      },
      '& p': {
        fontSize: 10,
        marginTop: 6,
        marginBottom: 12,
        color: '#515F6B',
      },
      '& ::placeholder': {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: 400,
        fontSize: '14px',
        textTransform: 'capitalize',
        color: '#8C98A4',
      },
    },
    button: {
      marginTop: 24,
      textAlign: 'right',
      '& .cancel': {
        marginRight: 10,
      },
    },
  },
  dialog: {
    color: '#253746',
    backgroundColor: '#FFFFFF',
    padding: '24px',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    borderRadius: '4px',
    border: 0,
    '& h2': {
      marginBottom: 24,
    },
    button: {
      marginTop: 24,
      textAlign: 'right',
      '& .cancel': {
        marginRight: 10,
      },
    },
  },
};

export const historyVersion = {
  transition: 'all .1s ease',
  borderRadius: '4px 0px 0px 4px',
  '& .eip1-MuiGrid-container': {
    paddingLeft: 10,
    '& .eip1-MuiGrid-item': {
      lineHeight: 0,
    },
  },
  '& .wrappTitle': {
    paddingLeft: 5,
  },
  selected: {
    background: '#F8FAFD',
    '& button': {
      background: '#F8FAFD',
    },
    '& p': {
      color: '#006EC6',
    },
    '& input': {
      color: '#006EC6',
    },
    '& circle.circle': {
      fill: '#fff',
      stroke: '#006EC6',
      strokeWidth: 2,
    },
    '& .autoSave': {
      '& svg': {
        transform: 'rotate(0deg)',
      },
    },
  },
  text: {
    marginTop: 5,
    width: '100%',
    '& input': {
      padding: 0,
      fontSize: 14,
      fontWeight: 500,
      color: '#253746',
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '& .eip1-MuiOutlinedInput-notchedOutline': {
      borderWidth: 0,
    },
    '& .Mui-focused input': {
      padding: '0 10px',
      color: '#006EC6',
    },
    '& :before': {
      borderBottom: 0,
    },
    '& :after': {
      borderBottom: 0,
    },
    '& .eip1-MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 0,
    },
  },
  svg: {
    lineHeight: 0,
    width: '40px',
  },
  '& p': {
    cursor: 'default',
    fontSize: 10,
    color: '#8C98A4',
    marginLeft: 16,
  },
  '& .subTitle': {
    margin: '0 0 4px 0',
  },
  '& .avatar': {
    width: 12,
    height: 12,
    marginRight: 4,
    verticalAlign: 'text-top',
  },
  '& :hover': {
    background: '#E4E7E9',
    borderRadius: '4px 0px 0px 4px',
    '& button': {
      background: '#E4E7E9',
    },
  },
  '& .firstStyle': {
    paddingLeft: 0,
    '& .dot': {
      textAlign: 'right',
      '& button': {
        transition: 'unset',
        padding: '12px 0',
        marginRight: 15,
      },
      '& svg': {
        height: '10px',
      },
    },
  },
  '& .autoSave': {
    cursor: 'default',
    alignSelf: 'center',
    '& svg': {
      marginLeft: 10,
      transform: 'rotate(-90deg)',
    },
  },
};

export const scrollbarStyles = {
  '& ul': {
    width: '100% !important',
  },
  '&::-webkit-scrollbar': {
    backgroundColor: 'transparent',
    width: '8px',
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-track:hover': {
    backgroundColor: 'transparent',
  },
  '&:hover::-webkit-scrollbar-thumb': {
    backgroundColor: '#babac0',
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'transparent',
    borderRadius: '16px',
    border: '1px solid #F8FAFD',
  },
  '&::-webkit-scrollbar-thumb:hover': {
    backgroundColor: '#a0a0a5',
    border: '0px solid #f4f4f4',
  },
  '&::-webkit-scrollbar-button ': { display: 'none' },
};

const textFontStyle: React.CSSProperties = {
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '1.43',
  letterSpacing: '0.01071em',
};
export const getCustomPlaceHolder = (color = '#CFD4D9', styleTextPlaceHolder = textFontStyle) => ({
  '&::placeholder': {
    /* Firefox */
    ...styleTextPlaceHolder,
    opacity: 1,
    color: color ?? '#253746',
  },

  '&:-ms-input-placeholder': {
    /* Internet Explorer 10-11 */
    ...styleTextPlaceHolder,
    color: color ?? '#253746',
  },

  '&::-ms-input-placeholder': {
    /* Microsoft Edge */
    ...styleTextPlaceHolder,
    color: color ?? '#253746',
  },
});

export const calendarRangeStyle = {
  boxShadow: 'none !important',
  width: '100% !important',
  '& .rmdp-calendar': {
    margin: 0,
    width: '100%',
  },
  '& .rmdp-header-values': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: ' 14px',
    lineHeight: '20px',
  },
  '& .rmdp-day-picker': {
    justifyContent: 'space-between',
  },
  '& .rmdp-week-day': {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '12px',
    lineHeight: ' 16px',
    color: '#8C98A4',
    textTransform: 'uppercase',
  },
  '& .rmdp-arrow-container': {
    '& .rmdp-arrow': {
      border: 'solid black',
      borderWidth: ' 0 3px 3px 0',
      borderRadius: '1px',
      height: '10px',
      width: '10px',
    },
  },
  '& .rmdp-arrow-container:hover': {
    background: 'transparent',
    boxShadow: 'none',
    '& .rmdp-arrow': {
      border: 'solid black',
      borderWidth: ' 0 3px 3px 0',
      borderRadius: '1px',
      height: '10px',
      width: '10px',
    },
  },
  '& .rmdp-day': {
    '& span': {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: ' 14px',
      lineHeight: '20px',
    },
    '&:not(.rmdp-disabled):not(.rmdp-day-hidden)': {
      '&:hover': {
        backgroundColor: ' #EBF4FB',
      },
      '&: span:hover': { backgroundColor: 'transparent', color: '#000' },
      '&:not(.start):not(.end) span:hover': { backgroundColor: 'transparent', color: '#000' },
      '&:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover': {
        backgroundColor: 'transparent',
      },
    },
    '&.rmdp-today': {
      '& span': {
        background: '#ED5C10',
        color: '#fff',
      },
      '&:hover span': {
        background: '#ED5C10 !important',
        color: '#fff !important',
      },
      '&.start,&.end': {
        '& span': {
          color: '#fff',
        },
      },
    },
  },
  '& .rmdp-range': {
    background: '#EBF4FB',
    '&.div:nth-child(1)': {
      color: '#fff',
      background: '#006EC6',
      borderRadius: 4,
    },
    color: '#000',
    '&:not(.start)::not(.end)': {
      borderRadius: 0,
    },
    boxShadow: 'unset',
    '&.start, &.end': {
      borderRadius: 4,
      background: '#006EC6 !important',
      color: '#fff',
      '&:hover': {
        '& span': {
          color: '#fff',
        },
      },
      '& span:hover': { color: '#fff', background: 'transparent' },
    },
  },
};

export const responsiveTextField = {
  inputSelect: {
    '& .eip1-MuiSelect-select': {
      minWidth: '50px',
      borderRadius: '4px',
    },
    '&.md .eip1-MuiSelect-select': {
      minWidth: '120px',
    },
    '&.xs .eip1-MuiSelect-select': {
      minWidth: '30px',
    },
    '&.xs2 .eip1-MuiSelect-select': {
      minWidth: '40px',
    },
    '&.sm .eip1-MuiSelect-select': {
      minWidth: '25px',
    },
  },
  inputNumber: {
    '&.xs': {
      minWidth: '64px',
    },
    '&.md': {
      minWidth: '120px',
    },
  },
};
export const noSelectStyled = {
  '-webkit-touch-callout': 'none',
  '-webkit-user-select': 'none',
  '-khtml-user-select': 'none',
  '-moz-user-select': 'none',
  '-ms-user-select': 'none',
  'user-select': 'none',
};

export const responsiveChart = {
  ratio_wrap: {
    maxHeight: '100%',
    position: 'relative',
    aspectRatio: '16 / 9',
  },
  ratio_item: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};
