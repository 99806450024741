import { EventEmitter } from 'events';
import React from 'react';
import SubscriptionPlan from '@ep/insight-ui/elements/plan-tables';

export default class SubscriptionPlanChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <SubscriptionPlan />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return null;
}
