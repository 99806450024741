import * as React from 'react';
import { AUTH0_LOGOUT_URI, getAuth0 } from '@ep/insight-ui/eo2/global';
import { aim } from '@eip/next/lib/main';

export default function Auth0Login() {
  React.useEffect(() => {
    return getAuth0().then(async (auth0) => {
      aim.clearUserSettings();
      aim.setLoginToken(null);
      const result = await auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
    });
  }, []);

  return (
    <div>
      <h1>Logging out...</h1>
    </div>
  );
}
