import * as React from 'react';

import { Grid, Button, makeStyles } from '@material-ui/core';

import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';

const useStyles = makeStyles({
  container: {
    marginTop: '15px',
    position: 'sticky',
    bottom: 0,
    background: '#fff',
  },
  button: {
    height: '100%',
  },
  loadingIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});

const ButtonActions = ({
  isSubmitting,
  onCancel,
  onSubmit,
  disablePrimary,
  primaryLabel = 'Submit',
  secondaryLabel = 'Cancel',
}: any) => {
  const classes = useStyles();

  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2} className={classes.container}>
      {isSubmitting && (
        <Grid item className={classes.loadingIcon}>
          <CircleLoading size={'18px'} />
        </Grid>
      )}
      {onCancel && (
        <Grid item>
          <Button className={classes.button} onClick={onCancel}>
            {secondaryLabel}
          </Button>
        </Grid>
      )}
      {onSubmit && (
        <Grid item>
          <Button
            disabled={isSubmitting || disablePrimary}
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            onClick={onSubmit}
          >
            {primaryLabel}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default ButtonActions;
