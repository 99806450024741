import { PageContext, pageRedux, usePage, useLog, EIP_CONSTANT, ContainerResponsiveContext } from '@eip/next/lib/main';
import type { ContainerResponiveType } from '@eip/next/lib/main';
import { makeStore, request } from '@ep/one/src/utils';
import * as React from 'react';
import MainPage from '../src/page/main';
import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { debounce, get } from 'lodash';

const { reducer, rootSaga } = pageRedux;
const log = useLog('system-page');

function PageContainer({ children }) {
  window.localStorage.setItem(
    'epusersettings',
    JSON.stringify({
      profile: {
        userId: '42ea833c-5ecf-40b7-8657-5a8b55e385c9',
        userEmail: 'phuc.phan@epsilo.io',
        userName: 'Phuc Phan',
        oldUserId: 998,
      },
      permissions: {
        byShopId: {},
        byCode: {},
      },
      lastUpdated: 1633876531768,
    }),
  );
  const store = React.useMemo(
    () =>
      makeStore({
        storeName: 'eip-workspace/system-page-' + new Date().toISOString(),
        reducer,
        rootSaga: rootSaga,
      }),
    [],
  );
  return (
    <Provider store={store} context={PageContext}>
      <PageWrapper>{children}</PageWrapper>
    </Provider>
  );
}

const saveSystemPageDesign = debounce((page: Dashboard, diff, changeset) => {
  request.post('/design-system-page', {
    page: {
      block_eid: page.blockEid,
      block_type: 'page',
      table: 'eip_system_block',
      content: page.nodes.map((n) => n.id),
      payload: {
        format: {
          layouts: page.layouts,
          layoutColumns: page.layoutColumns,
        },
        properties: {
          title: get(page, 'properties.title', ''),
          groupId: get(page, 'properties.groupId', ''),
          workflowId: get(page, 'properties.workflowId', ''),
          version: get(page, 'properties.version', Date.now()),
        },
      },
    },
    nodes: page.nodes.reduce((carry, node) => {
      const block = {
        block_eid: node.id,
        block_type: node.chartLibId,
        content: null,
        parent_id: page.blockEid,
        table: 'eip_system_block',
        payload: {
          properties: {
            customAttributes: node.customAttributes,
            mainAttributes: node.mainAttributes,
          },
        },
      };

      return { ...carry, [block.block_eid]: block };
    }, {}),
  });
  console.info('on page update', page, diff, changeset);
}, 200);

export function PageWrapper({ children }) {
  const { onPageEvent } = usePage();

  React.useEffect(() => {
    const unregister = onPageEvent('update', saveSystemPageDesign);
    return unregister;
  }, []);

  return children;
}

export function pageView({
  pageId,
  entityId = 'default',
  queryStr,
}: {
  pageId: string;
  entityId?: string;
  queryStr?: string;
}) {
  const history = createMemoryHistory();
  history.push(`/page/syspage-${pageId}/${entityId}/persona-syspage-${pageId}` + (queryStr ? `?${queryStr}` : ''));
  history.listen((location) => {
    log('hc', location);
  });

  return (
    <Router history={history}>
      <Route path="/page/:presetId" strict={false}>
        <PageContainer>
          <MainPage pageContainer={PageContainer}></MainPage>
        </PageContainer>
      </Route>
    </Router>
  );
}

export function pageEdit({ pageId }) {
  const history = createMemoryHistory();
  history.push(`/page/syspage-${pageId}/edit`);

  return (
    <Router history={history}>
      <Route path="/page/:presetId" strict={false}>
        <div style={{ marginLeft: 240, marginRight: 240, marginTop: 60 }}>
          <PageContainer>
            <MainPage pageContainer={PageContainer}></MainPage>
          </PageContainer>
        </div>
      </Route>
    </Router>
  );
}

export function pageWithUrn({ urn, queryStr }: { urn: string; queryStr?: string }) {
  const history = createMemoryHistory();
  history.push(`${urn}` + (queryStr ? `?${queryStr}` : ''));

  return (
    <Router history={history}>
      <Route path="/page/:presetId" strict={false}>
        <PageContainer>
          <MainPage pageContainer={PageContainer}></MainPage>
        </PageContainer>
      </Route>
    </Router>
  );
}

export function PageContainerResponsive({ children }) {
  const [containerKlass, setContainerKlass] = React.useState<string>('eres--small');

  React.useEffect(() => {
    const updateContainerKlass = () => {
      const vw = Math.min(window.screen.availWidth || 0, window.innerWidth || 0);
      const containerKlass = EIP_CONSTANT.CONTAINER_RESPONSIVE.breakpoints.find((bp) => bp[1] > vw)[0];
      setContainerKlass(containerKlass as string);
    };

    updateContainerKlass();
    window.addEventListener('resize', updateContainerKlass);

    return () => {
      window.removeEventListener('resize', updateContainerKlass);
    };
  }, []);

  return (
    <ContainerResponsiveContext.Provider value={{ containerClass: containerKlass as ContainerResponiveType }}>
      {children}
    </ContainerResponsiveContext.Provider>
  );
}

export { MainPage as SystemMainPage };
