import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Setting = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2107 4.88091L10.5776 4.65639C10.3943 4.59123 10.2472 4.45139 10.1729 4.2716C10.0985 4.09182 10.1039 3.88893 10.1876 3.71332L10.4756 3.10677C10.6909 2.65291 10.5975 2.1127 10.2423 1.7575C9.88712 1.40229 9.34693 1.30887 8.89309 1.52414L8.28657 1.81267C8.11087 1.89602 7.90808 1.90107 7.72845 1.82654C7.54881 1.75202 7.40915 1.6049 7.34404 1.42164L7.11904 0.789085C6.95034 0.315926 6.50231 0 6 0C5.49769 0 5.04966 0.315926 4.88096 0.789085L4.65596 1.42214C4.59075 1.60531 4.45104 1.75231 4.27142 1.82673C4.0918 1.90116 3.88907 1.89605 3.71343 1.81267L3.10691 1.52414C2.65307 1.30887 2.11288 1.40229 1.7577 1.7575C1.40252 2.1127 1.30909 2.65291 1.52436 3.10677L1.81237 3.71332C1.89594 3.88895 1.90121 4.09177 1.82688 4.27151C1.75254 4.45125 1.60556 4.59109 1.42236 4.65639L0.789336 4.88141C0.316078 5.04986 0 5.49788 0 6.00025C0 6.50262 0.316078 6.95064 0.789336 7.11909L1.42236 7.34411C1.60553 7.40929 1.75251 7.54904 1.82685 7.72871C1.90119 7.90837 1.89593 8.11112 1.81237 8.28668L1.52436 8.89323C1.30909 9.34709 1.40252 9.8873 1.7577 10.2425C2.11288 10.5977 2.65307 10.6911 3.10691 10.4759L3.71343 10.1878C3.88903 10.1041 4.09189 10.0988 4.27161 10.1733C4.45132 10.2478 4.59101 10.395 4.65596 10.5784L4.88096 11.2109C5.04966 11.6841 5.49769 12 6 12C6.50231 12 6.95034 11.6841 7.11904 11.2109L7.34404 10.5779C7.40922 10.3947 7.54897 10.2477 7.72862 10.1733C7.90828 10.099 8.11102 10.1043 8.28657 10.1878L8.89309 10.4759C9.34693 10.6911 9.88712 10.5977 10.2423 10.2425C10.5975 9.8873 10.6909 9.34709 10.4756 8.89323L10.1876 8.28668C10.1041 8.11112 10.0988 7.90837 10.1731 7.72871C10.2475 7.54904 10.3945 7.40929 10.5776 7.34411L11.2107 7.11859C11.6839 6.95014 12 6.50212 12 5.99975C12 5.49739 11.6839 5.04936 11.2107 4.88091ZM6 8C7.10457 8 8 7.10457 8 6C8 4.89543 7.10457 4 6 4C4.89543 4 4 4.89543 4 6C4 7.10457 4.89543 8 6 8Z"
    />
  </SvgIcon>
);

export default Setting;
