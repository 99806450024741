export const aggFuncList: AggFuncType[] = [
  {
    id: 'max',
    requestQuery: 'max',
  },
  {
    id: 'min',
    requestQuery: 'min',
  },
  {
    id: 'avg',
    requestQuery: 'avg',
  },
  {
    id: 'unique',
    requestQuery: 'count(distinct(${_f_}))',
  },
  {
    id: 'sum',
    requestQuery: 'sum',
  },
  {
    id: 'count',
    requestQuery: 'count',
  },
  {
    id: 'median',
    requestQuery: 'null as ${_f_}',
  },
  {
    id: 'percent_not_empty',
    requestQuery: 'sum(if(${_f_} is null OR ${_f_} = 0, 0, 1)) / count(${_f_}) as ${_f_}',
  },
  {
    id: 'percent_empty',
    requestQuery: 'sum(if(${_f_} is null OR ${_f_} = 0, 1, 0)) / count(${_f_}) as ${_f_}',
  },
  {
    id: 'count_not_empty',
    requestQuery: 'sum(if(${_f_} is null OR ${_f_} = 0, 0, 1)) as ${_f_}',
  },
  {
    id: 'count_empty',
    requestQuery: 'sum(if(${_f_} is null OR ${_f_} = 0, 1, 0)) as ${_f_}',
  },
  {
    id: 'range',
    requestQuery: 'concat("[\\"", min(${_f_}), "\\",\\"", max(${_f_}), "\\"]")',
  },
  {
    id: 'none',
    requestQuery: 'null as ${_f_}',
  },
  {
    id: 'earliest_date',
    requestQuery: 'min(${_f_})',
  },
  {
    id: 'latest_date',
    requestQuery: 'max(${_f_})',
  },
];

export const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce(
  (carry, i) => ({ ...carry, [i.id]: i }),
  {},
);

export function getAvailableAggFunc(key) {
  if (!aggFuncByKey[key]) {
    return {
      id: key,
      requestQuery: key,
    };
  }
  return aggFuncByKey[key];
}

export function getDefaultAggFunc(type: 'groupTarget' | 'dimension' | 'attribute' | 'metric'): AggFuncType {
  switch (type) {
    case 'groupTarget':
      return aggFuncByKey.max;
    case 'dimension':
      return aggFuncByKey.unique;
    case 'attribute':
      return aggFuncByKey.count;
    case 'metric':
      return aggFuncByKey.avg;
  }
}

export const supportedFieldKey = (k) => 'value' === k || 'id' === k;
