import React from 'react';
import { Bar } from 'react-chartjs-2';

const MixedChartFormat = () => {
  const chartLength = Array.from({ length: 15 });
  const generateBarData = () => chartLength.map(() => Math.round(Math.random() * 5) + 5);
  const generateLineData = () => chartLength.map(() => Math.round(Math.random() * 9) + 1);

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
        max: 15,
      },
      x: {
        grid: {
          display: false,
          drawBorder: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
    },
    hover: {
      mode: null,
    },
  };
  const initialData = {
    labels: chartLength.map(() => ''),
    datasets: [
      {
        type: 'line',
        label: 'Dataset 1',
        borderColor: '#e4553e',
        borderWidth: 3,
        fill: false,
        data: generateLineData(),
        lineTension: 0.4,
        pointRadius: 0,
      },
      {
        type: 'bar',
        label: 'Dataset 2',
        backgroundColor: '#0b4aa8',
        data: generateBarData(),
        borderColor: 'white',
        borderWidth: 1,
        borderRadius: 20,
      },
    ],
  };

  return (
    <>
      <Bar data={initialData} options={options} height={1} width={3} />
    </>
  );
};

export default MixedChartFormat;
