import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { boldMatchKeyword } from '@ep/insight-ui/lib/search';
import {
  Box,
  BoxProps,
  Checkbox,
  debounce,
  FormControlLabel,
  FormControlLabelProps,
  FormGroup,
  Typography,
} from '@material-ui/core';
import { CheckboxProps } from '@material-ui/core/Checkbox';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import clsx from 'clsx';
import * as React from 'react';
import ButtonAction from '../../button/button-action';
import HeaderList from '../header-list/header-list';
import { RadioComponent } from '../radio-list/radio-custom';
import SearchInput from './../search-input/search-input';
import { OptionType } from './../type';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

/* Type */
type PropsStyles = {
  searchAble?: boolean;
  disabledCheckbox?: boolean;
  checked?: boolean;
  disabled?: boolean;
};
interface CheckboxListProps {
  items: OptionType[];
  onChange: (v: OptionType[]) => void;
  onReorder?: (v: OptionType[]) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
  color?: CheckboxProps['color'];
  row?: boolean;
  draggable?: boolean;
  disabledAddOnIcon?: boolean;
  addAble?: boolean;
  searchAble?: boolean;
  allowCheckAll?: boolean;
  disabledCheckbox?: boolean;
  disabledReorderDebounce?: boolean;
  selectLimit?: number;
  singleSelectElements?: string[];
}
type CheckboxRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
  moveCard?: (dragIndex: string, hoverIndex: string) => void;
  disabled?: boolean;
  field?: string;
};

/** Style */
const useStyles = makeStyles(() => ({
  title: {
    paddingLeft: '7px',
  },
  header: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    marginBottom: '6px',
    paddingLeft: '8px',
  },
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
    '& .eip1-MuiButtonBase-root.eip1-MuiIconButton-root': {
      display: (props: PropsStyles) => (props.disabledCheckbox ? 'none' : 'flex'),
    },
  },
  searchInput: (props: PropsStyles) => ({
    display: props.searchAble ? 'block' : 'none',
    position: 'sticky',
    top: '0px',
    zIndex: 1,
    backgroundColor: '#fff',
    paddingTop: '16px',
    paddingRight: '10px',
  }),
  searchInputMobile: {
    margin: '0 -8px',
    borderRadius: '10px 10px 0 0',
    paddingTop: '0px',
  },
  srollBar: {
    overflow: 'auto',
    maxHeight: 'calc(100% - 48px)',
    height: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  srollBarMobile: {
    margin: '0 -8px',
    borderRadius: '0px 0px 10px 10px',
    background: '#fff',
  },
}));
const useStylesForm = makeStyles(() => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    height: '32px',
    minHeight: '32px',
    paddingLeft: '8px',
    paddingRight: '8px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: (props: PropsStyles) =>
        props.disabled
          ? 'transparent'
          : props.disabledCheckbox && props.checked
          ? '#EBF6FF'
          : colors.action.subtleHovered,
    },
    backgroundColor: (props: PropsStyles) => (props.checked && props.disabledCheckbox ? '#EBF6FF' : 'transparent'),
    color: (props: PropsStyles) => (props.checked && props.disabledCheckbox ? '#0369C7' : colors.text.default),
  },
  formMobile: {
    height: '40px',
  },
  endIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
const useStylesCheckedBox = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '2px solid',
    borderRadius: '2px',
    borderColor: '#C2C7CB !important',
    width: '12px',
    height: '12px',
    '& .eip1-MuiSvgIcon-root': {
      width: 'auto',
    },
  },
  rootChecked: {
    backgroundColor: '#0369C7',
    borderColor: '#0369C7  !important',
    '&.icon_disabled': {
      backgroundColor: '#E4E7E9',
      borderColor: '#E4E7E9 !important',
      pointerEvents: 'none',
    },
  },
  icon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
      pointerEvents: 'none',
    },
  },
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
    '& .eip1-MuiButtonBase-root.eip1-MuiIconButton-root': {
      display: (props: PropsStyles) => (props.disabledCheckbox ? 'none' : 'flex'),
    },
  },
}));
interface ICustomFormControlLabelProps extends FormControlLabelProps {
  checked: boolean;
  disabledCheckbox?: boolean;
  field?: string;
}
const StyledFormControlLabel = withStyles(() => ({
  root: {
    width: '100%',
    margin: '0',
    '& .eip1-MuiFormControlLabel-label.Mui-disabled': {
      color: 'rgb(37, 55, 70)',
    },
  },
}))((props: ICustomFormControlLabelProps) => {
  const classes = useStylesForm({
    checked: props.checked,
    disabledCheckbox: props.disabledCheckbox,
    disabled: props.disabled,
  });

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = false;

  return (
    <div
      className={clsx(classes.form, {
        [classes.formMobile]: isMobileView,
        tagproperty: props.field == 'campaign_tag' || props.field == 'ad_object_tag' || props.field == 'keyword_tag',
        ...(ff.badge_new_does_not_display_in_note
          ? {
              noteproperty:
                props.field == 'campaign_note' || props.field == 'ad_object_note' || props.field == 'ad_note',
            }
          : {}),
      })}
    >
      {props.draggable && (
        <Box mr={'10px'} width={'18px'} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Icon type={'draggable'} />
        </Box>
      )}
      <FormControlLabel {...props} />
      {props.item?.endIcon ? (
        <div
          onClick={(evt) => props.item.onEndIconClick(evt, props.item)}
          className={clsx(props.item.endIconClasses, classes.endIcon)}
          style={{ ...(props.item?.onEndIconClick ? { cursor: 'pointer' } : {}) }}
        >
          <Icon type={props.item.endIcon} />
        </div>
      ) : null}
    </div>
  );
});
type ICheckedBox = { icon: 'indeterminate' | 'checked' | 'defaut'; disabled?: boolean };
const CheckedBox = ({ icon, disabled = false }: ICheckedBox) => {
  const classes = useStylesCheckedBox();
  return (
    <Box className={clsx(classes.root, icon !== 'defaut' && classes.rootChecked, disabled ? 'icon_disabled' : '')}>
      {icon !== 'defaut' && <Icon type={icon} className={`${classes.icon} ${disabled ? 'icon_disabled' : null}`} />}
    </Box>
  );
};

const CheckboxRender = ({ draggable, index, children, moveCard, disabled, field }: CheckboxRenderProps) => {
  const classes = useStylesCheckedBox();

  return draggable && !disabled ? (
    <div className={clsx(classes.box, `eip_properties_name_${field} property_type`)}>
      <DragCard key={index} index={index} moveCard={moveCard}>
        {children}
      </DragCard>
    </div>
  ) : (
    <div className={classes.box}>{children}</div>
  );
};

const CheckboxList: React.FC<CheckboxListProps> = ({
  items,
  onChange,
  onReorder,
  disabled = false,
  label,
  color = 'primary',
  row,
  className = '',
  draggable = false,
  disabledAddOnIcon = false,
  disabledCheckbox = false,
  addAble,
  searchAble,
  allowCheckAll,
  disabledReorderDebounce = false,
  selectLimit,
  singleSelectElements = [],
}: CheckboxListProps) => {
  const classes = useStyles({ searchAble, disabledCheckbox });
  const [inputValue, setInputValue] = React.useState('');
  const defaultOptions = React.useMemo(() => items, [items]);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = false;

  const [options, setOptions] = React.useState<OptionType[]>(defaultOptions);
  React.useEffect(() => {
    setOptions(defaultOptions);
  }, [items]);
  const { getRootProps, getInputProps, groupedOptions } = useAutocomplete({
    open: true,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option.label,
    blurOnSelect: false,
    clearOnBlur: false,
    disableCloseOnSelect: true,
    inputValue: inputValue,
  });

  const itemHoverIndex = React.useRef(null);
  const timeout = React.useRef(null);

  const handler = React.useCallback(debounce(onReorder, 1000), []);
  const moveCard = (dragIndex, hoverIndex) => {
    // improve performance, check timeout before reordering options
    if (itemHoverIndex.current !== hoverIndex) {
      itemHoverIndex.current = hoverIndex;
      timeout.current = setTimeout(() => {
        timeout.current = null;
      }, 50);
    } else if (!timeout.current) {
      const tmpItems: OptionType[] = [...options];
      const dragCard = tmpItems[dragIndex];
      tmpItems.splice(dragIndex, 1);
      tmpItems.splice(hoverIndex, 0, dragCard);
      setOptions(tmpItems);
      if (disabledReorderDebounce) {
        onReorder(tmpItems);
      } else {
        handler(tmpItems);
      }
    }
    return timeout.current;
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tmpItems = [...options];

    if (disabledCheckbox) {
      tmpItems.forEach((item) => {
        if (item.value === e.target.value) {
          item.checked = true;
          return;
        }
        item.checked = false;
      });
    } else {
      switch (e.target.value) {
        case '_all': {
          let isCheckRadio = !!tmpItems.filter((item) => singleSelectElements.includes(item.value) && item.checked)
            .length;
          tmpItems.forEach((item) => {
            if (!item.disabled) {
              if (e.target.checked) {
                if (!singleSelectElements.includes(item.value)) {
                  item.checked = true;
                } else {
                  if (!isCheckRadio) {
                    item.checked = true;
                    isCheckRadio = true;
                  }
                }
              } else {
                item.checked = false;
              }
            }
          });
          break;
        }
        case 'limitSelectAll': {
          let count = tmpItems.filter((item) => item.checked).length;
          if (e.target.checked) {
            tmpItems.forEach((item) => {
              if (!item.disabled && count !== selectLimit) {
                item.checked = e.target.checked;
                count++;
              }
            });
          } else {
            tmpItems.forEach((item) => (item.checked = false));
          }
          break;
        }
        default: {
          tmpItems.forEach((item) => {
            if (item.value == e.target.value) {
              item.checked = e.target.checked;
            }
          });
          if (e.target.checked && singleSelectElements.includes(e.target.value))
            tmpItems.forEach((item) => {
              if (item.value !== e.target.value && singleSelectElements.includes(item.value)) {
                item.checked = false;
              }
            });
          break;
        }
      }
    }

    setOptions(tmpItems);
    onChange(tmpItems);
  };

  const selectedList = options.filter((item) => item.checked);

  // eslint-disable-next-line react/display-name
  const mappingCheckbox = (type: 'search' | 'list-show') => (item: OptionType, index: number) => {
    const disabledValid = item.disabled || (selectLimit ? selectLimit == selectedList.length && !item.checked : false);
    // eslint-disable-next-line react/display-name
    const icon = !singleSelectElements.includes(item.value) ? (
      <CheckedBox icon={'defaut'} disabled={disabledValid} />
    ) : (
      <RadioComponent icon="radioBlank" disabled={disabledValid} />
    );
    // eslint-disable-next-line react/display-name
    const checkedIcon = !singleSelectElements.includes(item.value) ? (
      <CheckedBox icon={'checked'} disabled={disabledValid} />
    ) : (
      <RadioComponent icon="radio" disabled={disabledValid} />
    );
    const label =
      type == 'search' ? (
        <Typography
          variant="body2"
          dangerouslySetInnerHTML={{ __html: boldMatchKeyword(item.label, inputValue) }}
        ></Typography>
      ) : (
        <Typography variant="body2">{item.label}</Typography>
      );

    return (
      <CheckboxRender
        key={index}
        index={index}
        draggable={draggable}
        moveCard={moveCard}
        disabled={disabled}
        field={item.value}
      >
        <StyledFormControlLabel
          disabledCheckbox={disabledCheckbox}
          disabled={disabledValid}
          draggable={draggable}
          key={index}
          checked={item.checked}
          field={item.value}
          control={
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              checked={item.checked}
              value={item.value}
              color={color}
              disabled={disabled || disabledValid}
              onChange={!(disabled || disabledValid) ? handleChange : () => undefined}
              inputProps={{ 'aria-label': `${color} checkbox` }}
            />
          }
          label={label}
          item={item}
        />
      </CheckboxRender>
    );
  };

  const statusCheckAll = React.useMemo(() => {
    let checkedNotRadio = true;
    let checkedRadio = singleSelectElements.length > 0 ? false : true;
    options.forEach((item) => {
      if (!singleSelectElements.includes(item.value)) {
        checkedNotRadio = checkedNotRadio && item.checked;
      } else {
        checkedRadio = checkedRadio || item.checked;
      }
    });
    return checkedRadio && checkedNotRadio;
  }, [options, singleSelectElements]);

  const IconCheckAll = React.useMemo(() => {
    // All disabled
    if (options.every((item) => item.disabled)) {
      return <CheckedBox icon={'defaut'} disabled={true} />;
    }
    // All checked
    if (statusCheckAll) {
      return <CheckedBox icon={'checked'} />;
    }
    // Some checked
    if (options.some((item) => item.checked)) {
      return <CheckedBox icon={'indeterminate'} />;
    }
    // No checked
    return <CheckedBox icon={'defaut'} />;
  }, [options, statusCheckAll]);
  const IconLimitSelectAll = React.useMemo(() => {
    if (options.every((item) => item.disabled)) {
      return <CheckedBox icon={'defaut'} disabled={true} />;
    }
    const length = options.filter((item) => item.checked).length;
    if (length > 0) {
      if (length == selectLimit) return <CheckedBox icon={'checked'} />;
      if (length < selectLimit) return <CheckedBox icon={'indeterminate'} />;
    }
    return <CheckedBox icon={'defaut'} />;
  }, [options, selectLimit]);

  const IconAll = () => {
    let checked = statusCheckAll;
    let icon = IconCheckAll;
    let value = '_all';
    let label = 'All';
    if (selectLimit) {
      checked = selectedList.length == selectLimit;
      icon = IconLimitSelectAll;
      value = 'limitSelectAll';
      label = `Maximum ${selectLimit} select(s)`;
    }
    return (
      <div className={classes.box}>
        <StyledFormControlLabel
          disabledCheckbox={disabledCheckbox}
          disabled={options.every((item) => item.disabled)}
          control={
            <Checkbox
              icon={icon}
              checkedIcon={<CheckedBox disabled={options.every((item) => item.disabled)} icon={'checked'} />}
              checked={checked}
              value={value}
              color={color}
              disabled={disabled}
              onChange={handleChange}
              inputProps={{ 'aria-label': `${color} checkbox` }}
            />
          }
          label={<Typography variant="body1">{label}</Typography>}
          checked={checked}
        />
      </div>
    );
  };
  return (
    <FormGroup row={row} className={`${className}`} style={{ height: '100%' }}>
      {label && <HeaderList title={label} addOn={!disabledAddOnIcon} />}
      <div
        {...getRootProps()}
        className={clsx(classes.searchInput, {
          [classes.searchInputMobile]: isMobileView,
        })}
      >
        <SearchInput
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          inputValue={inputValue}
          setInputValue={setInputValue}
          inputProps={{ ...getInputProps() }}
          fullWidth={true}
        />
      </div>

      <div
        className={clsx(classes.srollBar, {
          [classes.srollBarMobile]: isMobileView,
        })}
      >
        {inputValue !== '' && groupedOptions.map(mappingCheckbox('search'))}
        {inputValue === '' && allowCheckAll && IconAll()}
        {inputValue === '' && options.map(mappingCheckbox('list-show'))}
        {addAble && <ButtonAction disabled={disabled} icon="plus" label="Create new option" fullWidth={true} />}
      </div>
    </FormGroup>
  );
};
export default CheckboxList;
