import { default as dataRequest } from '@ep/insight-ui/system/backbone/data-source/common';

export const filterValueAddon = {
  'filterValue.ad_tool': async (payload?: any, forceReload?: boolean) => {
    const res = await dataRequest.getAdTool({ forceReload });
    res.data = res.data.map((item) => ({
      ...item,
      label: `${item.payload.marketplaceId[0]}${item.payload.marketplaceId.slice(1).toLowerCase()} / ${item.label}`,
    }));
    return Promise.resolve(res);
  },
  'filterValue.storefront': async (payload?: any, forceReload?: boolean) => {
    const res = await dataRequest.getStorefront({ forceReload });

    let selectedMarketplace;
    if (payload && payload.length > 0) {
      const staticFilter = payload.find((item) => item.static);

      if (staticFilter) {
        const staticSubMarketplaceFilter =
          staticFilter?.field?.value === 'marketplace'
            ? staticFilter
            : staticFilter?.subFilters?.find((item) => item.static && item.field?.value === 'marketplace');

        if (staticSubMarketplaceFilter) {
          selectedMarketplace = staticSubMarketplaceFilter.queryValue.value;
        }
      }
    } else {
      selectedMarketplace = null;
    }

    res.data = res.data.filter((item) => !selectedMarketplace || item.payload.channel.id === selectedMarketplace);

    return Promise.resolve(res);
  },
};
