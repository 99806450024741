import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core';

import { aim, eipRequest, EIP_CONSTANT } from '@eip/next/lib/main';
import { getAuth0, AUTH0_LOGOUT_URI, getPageUrn } from '@ep/insight-ui/eo2/global';
import InformationPage from '@ep/insight-ui/eo2/components/information-page';

const useStyles = makeStyles({
  redirectLabel: {
    color: '#0369C7',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default function Auth0Login() {
  const classes = useStyles();

  const [loginState, setLoginState] = React.useState({
    success: false,
    error: null,
    authResult: null,
    errorDescription: '',
  });
  const [auth0, setAuth0] = React.useState(null);
  const history = useHistory();
  const location = history.location;

  React.useEffect(() => {
    eipRequest.get('/api/v1/reload-cache?q=all');
  }, []);

  React.useEffect(() => {
    aim.clearUserSettings();
    aim.setLoginToken(null);
    getAuth0().then(async (auth0) => {
      try {
        setAuth0(auth0);

        const token = await auth0.getTokenSilently();
        console.info({ token });

        const user = await auth0.getUser();

        if (user.email_verified) {
          eipRequest.axios.interceptors.request.use((config) => {
            config.headers['Authorization'] = `Bearer ${token}`;
            return config;
          });

          eipRequest.get(EIP_CONSTANT.API_HOST.AUTH_WS + '/ws/who').then((result) => {
            const info = result.data;
            document.cookie =
              info.ab_testing === 'enable'
                ? `use_workspace_version=1; expires=${new Date(new Date().getTime() + 1000 * 60 * 60 * 24 * 90)};`
                : 'use_workspace_version=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
            aim.setLoginToken(token);
            aim.setUserSettings({
              isAuth0: true,
              permissions: { byCode: {}, byShopId: {} },
              profile: {
                ...info,
                userEmail: info.email,
                userName: info.email,
                userId: info.user_id,
              },
              lastUpdated: Date.now(),
            });
            const queryParams = new Proxy(new URLSearchParams(String(location.search).replace(/.+(?=\?)/, '')), {
              get: (searchParams, prop: string) => searchParams.get(prop),
            });
            setTimeout(() => {
              const event = new CustomEvent('RECEIVED_TOKEN', {
                detail: {
                  token,
                },
              });
              document.dispatchEvent(event);

              if (queryParams.redirect_url && !String(queryParams.redirect_url).includes('login')) {
                window.location.href = queryParams.redirect_url;
              } else {
                window.location.href = '/page';
              }
            }, 500);
          });

          setLoginState({ success: true, error: null, authResult: token, errorDescription: '' });
        } else {
          setLoginState({
            success: false,
            error: 'Email is not verified',
            authResult: null,
            errorDescription: 'Please verify your email before logging in.',
          });
        }
      } catch (error) {
        if (error.error === 'login_required') {
          auth0.loginWithRedirect();
        } else if (error.error === 'unauthorized') {
          const errorDetails = {
            success: false,
            error: 'Account is not authorized',
            authResult: null,
            errorDescription: error.error_description,
          };
          let errorObject = null;
          try {
            errorObject = JSON.parse(error.error_description);
            errorDetails.error = errorObject.header;
            errorDetails.errorDescription = errorObject.message;
          } catch (ex) {}

          setLoginState(errorDetails);
        } else {
          setLoginState({
            success: false,
            error: 'Something went wrong!',
            authResult: null,
            errorDescription: 'Please try again.',
          });
        }
        console.error(error);
      }
    });
  }, []);

  const title = React.useMemo(() => {
    if (loginState.success) return 'Logged in';
    return loginState.error ? loginState.error : 'Logging you in...';
  }, [loginState.success, loginState.error]);

  const redirectToLogin = () => {
    auth0.logout({ returnTo: AUTH0_LOGOUT_URI });
  };

  const content = React.useMemo(() => {
    if (loginState.error)
      return (
        <div>
          <div dangerouslySetInnerHTML={{ __html: loginState.errorDescription }}></div>
          <div>
            <span className={classes.redirectLabel} onClick={redirectToLogin}>
              Click here
            </span>{' '}
            to retry.
          </div>
        </div>
      );
    return (
      <span>
        Your redirect should start automatically. If not, please{' '}
        <span className={classes.redirectLabel} onClick={() => auth0.loginWithRedirect()}>
          click here
        </span>
        .
      </span>
    );
  }, [loginState.error, auth0]);

  return <InformationPage title={title}>{content}</InformationPage>;
}
