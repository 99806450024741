import * as React from 'react';
import qs from 'qs';

import { makeStyles } from '@material-ui/core';

import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { getPageUrn } from '@ep/insight-ui/eo2/global';

const useStyles = makeStyles({
  container: {
    position: 'absolute',
    top: '-62px',
    right: '20px',
    zIndex: 1000,
  },
});

const EdotScript = ({ entityId, marketplace, type }: { entityId: string; marketplace: string; type: string }) => {
  const classes = useStyles();

  const dataMenu = React.useMemo(() => {
    return [
      [
        ...(entityId != 'default'
          ? [
              {
                title: 'Duplicate',
                icon: 'duplicate',
                onClick: () => {
                  const queryStr = qs.stringify({
                    cloneId: entityId,
                  });

                  window.open(getPageUrn('/page/751811a2-3b77-41e2-83dc-7588690d579e/default?' + queryStr));
                },
              },
            ]
          : []),
      ],
    ];
  }, []);

  return (
    <div className={classes.container}>
      <Dropdown alignMenu="right" icon="threeDotsVertical" label="">
        <MenuList listMenu={dataMenu} />
      </Dropdown>
    </div>
  );
};

export default EdotScript;
