import * as Yup from 'yup';

export const listPasswordErrors = [
  'At least 8 characters',
  'A mixture of uppercase and lowercase',
  'A mixture of letters, numbers and special characters',
  'Cannot contains part of your email',
];

export const listEmailErrors = [
  'Email is required.',
  'Invalid email address. The correct address might be: abc@xyz.com',
];

const password = Yup.string()
  .required('At least 8 characters')
  .min(8, 'At least 8 characters')
  .matches(/((?=.*\d)(?=.*\W))/, 'A mixture of letters, numbers and special characters')
  .matches(/((?=.*[A-Z])(?=.*[a-z]))/, 'A mixture of uppercase and lowercase');

export const email = Yup.string()
  .required('Email is required.')
  .email('Invalid email address. The correct address might be: abc@xyz.com');

export const signUpSchema = Yup.object().shape({
  firstName: Yup.string().required('First name is required.'),
  lastName: Yup.string().required('Last name is required.'),
  email: email,
  password: password,
  company: Yup.string()
    .required('Company is required.')
    .matches(/^\S/, 'Not allow whitespace first.')
    .max(100, 'Maximum 100 characters.'),
});

export const loginSchema = Yup.object().shape({
  email: email,
  password: Yup.string().required('Password is required.'),
});

export const passwordSchema = Yup.object().shape({
  password: password,
});

export const validatorForSchema = (schema) => (values) =>
  schema
    .validate(values, {
      abortEarly: false,
      strict: false,
    })
    .then(() => ({}))
    .catch(({ inner }) =>
      inner.reduce(
        (memo, { path, message }) => ({
          ...memo,
          [path]: (memo[path] || []).concat(message),
        }),
        {},
      ),
    );
