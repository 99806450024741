import * as React from 'react';
import { FixedSizeList } from 'react-window';
import clsx from 'clsx'
import produce from 'immer';
import { debounce } from 'lodash';

import { Box, makeStyles, List, ListItem, FormControl, Select, MenuItem } from '@material-ui/core';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import SearchInput from '@ep/insight-ui/elements/list-control/search-input/search-input';

interface Menu {
  label: string;
  value: any;
  id?: string;
  selections: {
    label: string;
    value: any;
  }[]
}

interface IPropsMenuPropertiesSelection {
  title: string;
  onInputChange?: (value) => void;
  menu: Menu[],
  onMenuChange?: (menu: Menu[]) => void;
  marginBottom?: number;
}

const useStyles = makeStyles(() => ({
  container: {},
  listItem: {
    borderRadius: '4px',
    '&.hasSelectAll': {
      paddingLeft: '20px',
    },
    '& svg, & img': {
      width: '24px',
      height: '24px',
    },
    padding: '8px'
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  formControl: {
    minWidth: '100px'
  }
}));

const Row = ({ data, index, style }: any) => {
  const classes = useStyles();

  const listItem = data.options[index];
  const label = data.options[index].label;
  const value = data.options[index].value;

  const handleChange = (e) => {
    const newData = produce(data.options, (draft) => {
      draft[index].value = e.target.value
    })
    data.onMenuChange(newData)
  }
  
  return (
    <div style={style}>
      <ListItem
        className={clsx(classes.listItem)}
      >
        <Box className={classes.item}>
          <span>{label}</span>
          <FormControl className={classes.formControl} size="small">
            <Select
              value={value}
              onChange={handleChange}
              variant={'outlined'}
            >
              {listItem.selections.map(selection => {
                return <MenuItem value={selection.value} key={selection.value}>
                  {selection.value ? selection.label : <em>{'None'}</em>}
              </MenuItem>
              })}
            </Select>
          </FormControl>
        </Box>
      </ListItem>
    </div>
  );
};

const MenuPropertiesSelection = ({ title, onInputChange = () => undefined, menu, onMenuChange, marginBottom = 20, }: IPropsMenuPropertiesSelection) => {
  const classes = useStyles();
  const [tmpMenu, setTmpMenu] = React.useState<Menu[]>(menu)
  const [inputValue, setInputValue] = React.useState<string>('')

  const handleInputChange = React.useCallback(debounce(onInputChange, 100), []);
  React.useEffect(() => {
    handleInputChange(inputValue);
  }, [inputValue]);

  React.useEffect(() => {
    setTmpMenu(menu)
  }, [menu])

  const windowHeight = window.innerHeight;
  const listHeightPercent = 0.7;
  const menuLength = tmpMenu.length

  const widthList = React.useMemo(() => {
    const mapped = tmpMenu.map((el) => String(el.label).length * 10 + 100);
    return Math.min(600, Math.max(...mapped, 250));
  }, [tmpMenu]);

  return (
    <Box className={classes.container}>
      <HeaderList title={title} />
      <Box sx={{ marginTop: '8px', padding: '0 8px' }}>
        <SearchInput
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          inputValue={inputValue}
          setInputValue={setInputValue}
          fullWidth={true}
        />
      </Box>
      <List>
        <FixedSizeList
          height={
            menuLength * 35 + marginBottom > windowHeight * listHeightPercent
              ? windowHeight * listHeightPercent
              : menuLength * 35 + marginBottom
          }
          itemData={{ options: tmpMenu, onMenuChange }}
          itemCount={menuLength}
          itemSize={35}
          width={widthList}
        >
          {Row}
        </FixedSizeList>
      </List>
    </Box>
  );
};

export default MenuPropertiesSelection;
