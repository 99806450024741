import * as React from 'react';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { Button, Grid, TextField, TextareaAutosize, makeStyles } from '@material-ui/core';
import ButtonActions from '@ep/insight-ui/system/block/etable/etable-config/action-components/inline-edit/button-actions';
import CircleLoading from '../../loading/circle-loading';
import { textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { EIP_CONSTANT, eipRequest } from '@eip/next/lib/main';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { useToast } from '../../notifications/hook';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { useGuideMe } from './hooks/use-guide-me';
import { cloneDeep } from 'lodash';
import AccessBy from '@ep/insight-ui/next-elements/access-by';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

interface PropsStyle {
  inputNoShadow: boolean;
  line: number;
  type: string;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 10px',
    minWidth: '320px',
  },
  header: {
    paddingLeft: 0,
    marginBottom: '10px',
  },
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    marginBottom: '16px',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
  },
  button: {
    height: '100%',
  },
  loadingIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  formField: {
    display: 'flex',
    rowGap: '4px',
    flexDirection: 'column',
  },
  textStyle: (props: PropsStyle) => ({
    ...(textFieldStyle as CreateCSSProperties),
    width: '100%',
    '& input': {
      padding: '0 10px',
      height: '32px',
      caretColor: '#006EC6',
      width: '100%',
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
        margin: 0,
      },
      '&[type=number]': {
        ' -moz-appearance': 'textfield',
      },
    },
    '& .eip1-MuiOutlinedInput-multiline': {
      padding: '10px 10px',
      minHeight: '32px',
      caretColor: '#006EC6',
      width: '100%',
      alignItems: 'flex-start',
      '& textarea': {
        lineHeight: '20px',
        height: props.line > 0 ? `${props.line * 20}px !important` : '20px !important',
      },
    },
    '& .eip1-MuiButtonBase-root': {
      width: 'auto',
      opacity: (props: PropsStyle) => (props.type === 'password' ? 1 : 0),
    },
    '& .eip1-MuiFormHelperText-contained,  & .Mui-error.eip1-MuiFormHelperText-contained': {
      fontSize: '10px',
      margin: '4px 0 0 0',
      '& svg': {
        height: '9px',
        width: '9px',
        marginRight: '5px',
      },
    },
    '& input:-webkit-autofill,& input:-webkit-autofill:hover,& input:-webkit-autofill:focus,& input:-webkit-autofill:active':
      {
        '-webkit-text-fill-color': ' black !important',
        '-webkit-background-clip': 'text',
      },
  }),
  formFieldLabel: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
  },
  errorMessage: {
    color: '#d4290d',
    fontSize: '12px',
    marginTop: '4px',
  },
}));

const defaultUserOptions = [
  {
    label: '*@epsilo.io',
    value: '*@epsilo.io',
    firstname: 'Epsilo',
    lastname: 'Member',
    email: '*@epsilo.io',
    avatarUrl: '',
  },
];

const GuideMe = ({
  onClose,
  fetchWatchMeLink,
  guideMeList,
}: {
  onClose: () => void;
  fetchWatchMeLink: (tableId: string) => void;
  guideMeList: any[];
}) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [useCaseName, setUseCaseName] = React.useState('Watch me');
  const [useCaseDescription, setUseCaseDescription] = React.useState('');
  const [icon, setIcon] = React.useState('');
  const [watchMeLink, setWatchMeLink] = React.useState('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const tableId = useAtomValue(eTableAtom.tableId);
  const [selectedGuideMe, setSelectedGuideMe] = React.useState(null);
  const [status, setStatus] = React.useState('1');
  const [parent, setParent] = React.useState(() => {
    if (guideMeList.length) return guideMeList[0]?.id || tableId;
    return tableId;
  });
  const [accessBy, setAccessBy] = React.useState([]);

  const parentLevel = React.useMemo(() => {
    return (guideMeList || []).find((i) => i.id == parent)?.level;
  }, [parent, guideMeList]);

  const { upsertGuideline } = useGuideMe();

  const backbone = React.useContext(TableBackboneContext);
  const options = React.useMemo(() => {
    const firstLevelOption = [
      {
        label: backbone.getConfig('title'),
        value: tableId,
      },
    ];
    const anotherLevelOptions = cloneDeep(guideMeList || []).map((i) => {
      return {
        label: `Level ${i.level} - ${i.use_case_name}`,
        value: i.id,
      };
    });

    return selectedGuideMe
      ? [].concat(firstLevelOption).concat(anotherLevelOptions)
      : guideMeList.length
      ? anotherLevelOptions
      : firstLevelOption;
  }, [guideMeList, selectedGuideMe]);

  const selectedOptions = React.useMemo(() => {
    const firstLevelOption = [
      {
        label: 'New',
        value: null,
      },
    ];
    const anotherLevelOptions = cloneDeep(guideMeList || []).map((i) => {
      return {
        label: `Level ${i.level} - ${i.use_case_name}`,
        value: i.id,
      };
    });

    return [].concat(firstLevelOption).concat(anotherLevelOptions);
  }, [guideMeList]);

  const statusOptions = [
    {
      label: 'Enabled',
      value: '1',
    },
    {
      label: 'Disabled',
      value: '0',
    },
  ];

  React.useMemo(() => {
    const selectedInfo = guideMeList.find((i) => i.id == selectedGuideMe);
    if (selectedInfo) {
      const { icon, parent_id, use_case_name, use_case_description, demo_link, disabled, access_by } = selectedInfo;
      setIcon(icon);
      setParent(parent_id);
      setUseCaseDescription(use_case_description);
      setUseCaseName(use_case_name);
      setWatchMeLink(demo_link);
      setStatus(disabled || '1');
      setAccessBy(access_by ? safeJsonParse(access_by, []) : []);
    } else if (guideMeList.length) {
      setParent(guideMeList[0]?.id || tableId);
    }
  }, [selectedGuideMe]);

  const { onToastMultiple } = useToast();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage('');
    upsertGuideline(
      {
        section_id: tableId,
        parent_id: parent,
        use_case_name: useCaseName,
        use_case_description: useCaseDescription,
        demo_link: watchMeLink,
        level: parent == tableId ? 0 : Number(parentLevel) + 1,
        icon: icon,
        disabled: status,
        access_by: JSON.stringify(accessBy),
      },
      selectedGuideMe,
    )
      .then((res) => {
        if (res.success || res.code == 200) {
          onToastMultiple({
            title: 'Setup demo successfully',
            messages: [],
            variant: 'success',
          });
          fetchWatchMeLink(tableId);
          onClose();
        } else {
          setErrorMessage(res.message);
        }
      })
      .catch((e) => {
        if (e.statusCode == 404) {
          setErrorMessage('API is not available yet');
        } else {
          setErrorMessage(e.message);
        }
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };
  return (
    <form onSubmit={handleSubmit}>
      <HeaderList title={'Setup demo'} variant="h4" className={classes.header} />
      <div className={classes.formFields}>
        <div className={classes.formField}>
          <div>
            <SelectForm
              options={selectedOptions}
              value={selectedGuideMe}
              onChange={(e) => setSelectedGuideMe(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Status</span>
          </div>
          <div>
            <SelectForm options={statusOptions} value={status} onChange={(e) => setStatus(e.target.value)} />
          </div>
        </div>
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Access by</span>
          </div>
          <div>
            <AccessBy userOptions={defaultUserOptions} values={accessBy} setValues={setAccessBy} />
          </div>
        </div>
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Parent</span>
          </div>
          <div>
            <SelectForm
              options={options}
              disabled={selectedGuideMe}
              value={parent}
              onChange={(e) => setParent(e.target.value)}
            />
          </div>
        </div>
        {/* <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Icon</span>
          </div>
          <div>
            <TextField
              className={classes.textStyle}
              variant={'outlined'}
              value={icon}
              onChange={(e) => setIcon(e.target.value)}
            />
          </div>
        </div> */}
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Use case name*</span>
          </div>
          <div>
            <TextField
              className={classes.textStyle}
              variant={'outlined'}
              value={useCaseName}
              onChange={(e) => setUseCaseName(e.target.value)}
            />
          </div>
        </div>
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Use case description</span>
          </div>
          <div>
            <TextareaAutosize
              minRows={5}
              maxRows={5}
              value={useCaseDescription}
              onChange={(e) => setUseCaseDescription(e.target.value)}
              style={{ width: '100%', borderColor: '#c2c7cb', borderRadius: '4px', font: 'inherit' }}
            />
          </div>
        </div>
        <div className={classes.formField}>
          <div>
            <span className={classes.formFieldLabel}>Demo link*</span>
          </div>
          <div>
            <TextField
              className={classes.textStyle}
              variant={'outlined'}
              value={watchMeLink}
              onChange={(e) => setWatchMeLink(e.target.value)}
            />
          </div>
        </div>
      </div>
      {errorMessage ? (
        <div className={classes.errorMessage}>
          <span>{errorMessage}</span>
        </div>
      ) : null}
      <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{ marginBottom: '4px' }}>
        {isSubmitting && (
          <Grid item className={classes.loadingIcon}>
            <CircleLoading size={'18px'} />
          </Grid>
        )}
        <Grid item>
          <Button className={classes.button} onClick={onClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            disabled={isSubmitting || !useCaseName || !watchMeLink}
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
          >
            {selectedGuideMe ? 'Update' : 'Create'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default GuideMe;
