import * as React from 'react';
import { cloneDeep } from 'lodash';
import { usePassport } from '../../hooks/use-passport';

const fakeUsers = [
  {
    label: 'textone@example.com',
    value: 'textone@example.com',
    firstname: 'Text',
    lastname: 'One',
    email: 'textone@example.com',
    avatarUrl: '',
  },
  {
    label: 'customertwo@example.com',
    value: 'customertwo@example.com',
    firstname: 'Customer',
    lastname: 'Two',
    email: 'customertwo@example.com',
    avatarUrl: '',
  },
];

const cached = {
  users: null,
};

const fetchUsers = () => {
  if (cached.users) {
    return Promise.resolve(cached.users);
  }
  return new Promise((resolve) => {
    if (ff.fetch_fake_users) {
      setTimeout(() => {
        cached.users = fakeUsers;
        resolve(fakeUsers);
      }, 1000);
    } else {
      resolve([]);
    }
  });
};

const defaultUserOptions = [
  {
    label: '*@epsilo.io',
    value: '*@epsilo.io',
    firstname: 'Epsilo',
    lastname: 'Member',
    email: '*@epsilo.io',
    avatarUrl: '',
  },
];

export const useChangePermission = ({ handleClose, parentNodeIds, nodeId, updateSidebar, appMenuListRef }) => {
  const [users, setUsers] = React.useState([]);
  const [selectedUsers, setSelectedUsers] = React.useState([]);

  let updateFeatureMetadata, updateProductMetadata;
  if (ff.next_left_menu) {
    const passportHook = usePassport();
    updateFeatureMetadata = passportHook.updateFeatureMetadata;
    updateProductMetadata = passportHook.updateProductMetadata;
  }

  const defaultUsers = React.useMemo(() => {
    const cloneAppMenuList = cloneDeep(appMenuListRef.current);
    const parentNode = parentNodeIds
      ? parentNodeIds.reduce((a, b, i) => {
          if (!a) return a;
          if (i === 0) return a.find((el) => el.nodeId === b);
          return a.tabs.find((el) => el.nodeId === b);
        }, cloneAppMenuList)
      : null;

    const node = parentNode
      ? parentNode.tabs.find((el) => el.nodeId === nodeId)
      : cloneAppMenuList.find((el) => el.nodeId === nodeId);
    if (node) {
      return node.accessBy
        ? node.accessBy.map((el) => ({
            label: el,
            value: el,
          }))
        : [];
    }
    return [];
  }, [appMenuListRef, parentNodeIds, nodeId]);

  const handleChange = (data) => {
    setSelectedUsers(data);
  };

  const handleApply = () => {
    const cloneAppMenuList = cloneDeep(appMenuListRef.current);
    const parentNode = parentNodeIds
      ? parentNodeIds.reduce((a, b, i) => {
          if (!a) return a;
          if (i === 0) return a.find((el) => el.nodeId === b);
          return a.tabs.find((el) => el.nodeId === b);
        }, cloneAppMenuList)
      : null;

    const node = parentNode
      ? parentNode.tabs.find((el) => el.nodeId === nodeId)
      : cloneAppMenuList.find((el) => el.nodeId === nodeId);
    if (node) {
      node.accessBy = (selectedUsers || []).map((el) => el.value);
      updateSidebar(cloneAppMenuList);
      if (ff.next_left_menu) {
        const passportArgs = [
          node.nodeId,
          JSON.stringify({
            accessBy: node.accessBy,
          }),
        ];
        if (parentNode) {
          updateFeatureMetadata(...passportArgs);
        } else {
          updateProductMetadata(...passportArgs);
        }
      }
      handleClose();
    }
  };

  React.useEffect(() => {
    fetchUsers().then((res) => {
      setUsers(defaultUserOptions.concat(res));
    });
  }, [appMenuListRef, parentNodeIds, nodeId]);

  return {
    users,
    defaultUsers,
    handleChange,
    handleApply,
  };
};
