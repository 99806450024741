import * as React from 'react';

import { SystemPage, Block } from '../system-page';
import { ETable } from '@ep/insight-ui/system/block';
import { EO2_SYSTEM_PAGE_ID } from '../constant';

const AdoptionRatePage = () => {
  return (
    <SystemPage
      title={'Adoption Rate'}
      blockId={EO2_SYSTEM_PAGE_ID.productHealth.adoptionRate}
      version={2}
      token={'tbd'}
      pageCategory={'hidden'}
    >
      <Block
        blockType={ETable.blockType}
        blockId={'b77fd316-2204-40a7-9da6-29d30f62e999'}
        config={ETable.packagedConfigs.ADOPTION_RATE_BY_ACTIONS_CONFIG}
        layout={{ w: 36, h: 8, x: 0, y: 0 }}
      />
      <Block
        blockType={ETable.blockType}
        blockId={'a76efc54-3c97-4ff5-97f8-886cf98cecbf'}
        config={ETable.packagedConfigs.ADOPTION_RATE_BY_USERS_CONFIG}
        layout={{ w: 36, h: 8, x: 0, y: 8 }}
      />
      <Block
        blockType={ETable.blockType}
        blockId={'fd3eac7a-09c1-48cf-9353-c8e57106c618'}
        config={ETable.packagedConfigs.USER_COHORT_CONFIG}
        layout={{ w: 36, h: 8, x: 0, y: 16 }}
      />
    </SystemPage>
  );
};

export default AdoptionRatePage;
