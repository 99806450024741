import { EIP_CONSTANT } from '@eip/next/lib/main';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';

const PASSPORT_URL = EIP_CONSTANT.API_HOST.API_PASSPORT_URL;
const UPDATE_SIDEBAR_URL = PASSPORT_URL + '/api/auth/common/update-sidebar';
const FEATURE_CREATE_URL = PASSPORT_URL + '/api/auth/feature/create';
const FEATURE_UPDATE_URL = PASSPORT_URL + '/api/auth/feature/update';
const PRODUCT_UPDATE_URL = PASSPORT_URL + '/api/auth/product/update';
const FEATURE_UPDATE_METADATA_URL = PASSPORT_URL + '/api/auth/feature/update-metadata';
const PRODUCT_UPDATE_METADATA_URL = PASSPORT_URL + '/api/auth/product/update-metadata';
const LEFT_MENU_URL = PASSPORT_URL + '/api/auth/user/left-menu';
const SET_HOMEPAGE_URL = PASSPORT_URL + '/api/auth/user/set-homepage';
const SORT_WORKSPACE_URL = PASSPORT_URL + '/api/auth/workspace-user/sort-workspace-accessible';
const SET_USER_WORKSPACE_URL = PASSPORT_URL + '/api/auth/user/workspace-set';
const UPDATE_USER_FAVORITE = PASSPORT_URL + '/api/auth/workspace-user/update-favorite';

export const usePassport = () => {
  const getLeftMenu = () => {
    return request.get(LEFT_MENU_URL, null, true).finally(() => {
      setTimeout(() => {
        request.get('/api/v1/reload-cache?q=left-menu&update-ws=1').then(() => {
          setTimeout(() => {
            request.get(LEFT_MENU_URL);
          }, 1000);
        });
      }, DELAY_REQUEST_ADDITIONAL_DATA);
    });
  };

  const reloadCacheWorkspaceAccessible = (res) => {
    if (res?.success) {
      request.get('/api/v1/reload-cache?q=workspace-accessible');
    }
    return res;
  };

  const reloadCacheWorkspaceLeftMenu = (res) => {
    if (res?.success) {
      request.get('/api/v1/reload-cache?q=left-menu');
    }
    return res;
  };

  const updateLeftMenu = (sidebar) => {
    return request
      .post(UPDATE_SIDEBAR_URL, {
        newValues: {
          sidebar: sidebar,
        },
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const createNewFeature = (payload) => {
    return request
      .post(FEATURE_CREATE_URL, {
        newValues: payload,
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const updateFeature = (nodeId, payload) => {
    return request
      .post(FEATURE_UPDATE_URL, {
        newValues: {
          code: nodeId,
          ...payload,
        },
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const updateProduct = (nodeId, payload) => {
    return request
      .post(PRODUCT_UPDATE_URL, {
        newValues: {
          id: nodeId,
          ...payload,
        },
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const updateFeatureMetadata = (nodeId, payload) => {
    return request
      .post(FEATURE_UPDATE_METADATA_URL, {
        newValues: {
          code: nodeId,
          metadata: payload,
        },
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const updateProductMetadata = (nodeId, payload) => {
    return request
      .post(PRODUCT_UPDATE_METADATA_URL, {
        newValues: {
          productId: nodeId,
          metadata: payload,
        },
      })
      .then(reloadCacheWorkspaceLeftMenu);
  };

  const setHomepage = (url) => {
    return request
      .post(SET_HOMEPAGE_URL, {
        newValues: {
          homepage: url,
        },
      })
      .then(reloadCacheWorkspaceAccessible);
  };

  const sortWorkspace = (workspaceIds) => {
    return request
      .post(SORT_WORKSPACE_URL, {
        newValues: {
          workspacesOrder: workspaceIds,
        },
      })
      .then(reloadCacheWorkspaceAccessible);
  };

  const setUserWorkspace = (workspaceId) => {
    return request.post(SET_USER_WORKSPACE_URL, {
      newValues: {
        workspace_id: workspaceId,
      },
    });
  };

  const updateUserFavorites = (pages) => {
    return request
      .post(UPDATE_USER_FAVORITE, {
        newValues: {
          favorites: pages,
        },
      })
      .then(reloadCacheWorkspaceAccessible);
  };

  return {
    getLeftMenu,
    updateLeftMenu,
    updateFeature,
    updateProduct,
    createNewFeature,
    setHomepage,
    updateFeatureMetadata,
    updateProductMetadata,
    sortWorkspace,
    setUserWorkspace,
    updateUserFavorites,
  };
};
