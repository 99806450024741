import * as React from 'react';

import { Button, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    columnGap: '8px',
    marginRight: '26px',
  },
}));

const CampaignActions = ({ campaignInfo, onPublish }: any) => {
  const classes = useStyles();
  const { status } = campaignInfo || {};
  const [isPublishing, setIsPublishing] = React.useState(false);
  const handlePublish = () => {
    if (onPublish) {
      setIsPublishing(true);
      onPublish().finally(() => {
        setIsPublishing(false);
      });
    } else {
      setIsPublishing(false);
    }
  };
  campaignInfo;

  return (
    <div className={classes.container}>
      {status == 'DRAFT' && (
        <Button onClick={handlePublish} variant={'contained'} color={'primary'} disabled={isPublishing}>
          {isPublishing ? 'Publishing...' : 'Publish'}
        </Button>
      )}
    </div>
  );
};

export default CampaignActions;
