export const CELL_FORMAT_OBJECT_KEYS = {
  text: {
    label: '',
    subTabs: '',
    status: '',
    subtext: '',
    hashtag: '',
    displayType: '',
    disabled: '',
    subtext2: '',
    valid: '',
    jsonTooltips: '',
  },
  textMatchType: {
    value: '',
  },
  status: {
    subtext: '',
    hashtag: '',
    label: '',
    status: '',
    tooltips: '',
  },
  budget: {
    totalBudget: '',
    dailyBudget: '',
    currency: '',
  },
  selectionBudget: {
    totalBudget: '',
    dailyBudget: '',
    currency: '',
  },
  currency: {
    value: '',
    typeDisplay: '',
    currency: '',
    formatDateDisplay: '',
    subtext: '',
    hashtag: '',
    isAuto: '',
    suggestedValue: '',
    suggestedMax: '',
    suggestedMin: '',
    isAutomatedAds: '',
    isAutomatedCampaign: '',
    marketplace: '',
    marketplaceCode: '',
  },
  timeline: {
    date_from: '',
    defaultFormatReturn: '',
    formatDateView: '',
    date_to: '',
  },
  position: {
    position: '',
    updateDay: '',
    defaultFormat: '',
    displayNumber: '',
  },
  userInfo: {
    hashtag: '',
    subtext: '',
    image: '',
    name: '',
  },
  tag: {
    value: '',
  },
  stamp: {
    stamp: '',
  },
  progressBar: {
    value: '',
  },
  add: {
    config: '',
  },
  lineChart: {
    data: '',
  },
  simpleGroup: {
    label: '',
  },
  singleBudget: {
    value: '',
    currency: '',
    label: '',
  },
  totalBudget: {
    value: '',
    currency: '',
  },
  dailyBudget: {
    value: '',
    currency: '',
  },
  // eslint-disable-next-line react/display-name
  // eslint-disable-next-line react/display-name
  compact: {
    isAuto: '',
    subtext: '',
    hashtag: '',
    isAutomatedAds: '',
    isAutomatedCampaign: '',
    marketplace: '',
    marketplaceCode: '',
    subtext2: '',
  },
  elasticity: {},
  timeBasedCell: {},
  lineEDashboardChart: {},
  keywordFormat: {
    label: '',
    subTabs: '',
    status: '',
    subtext: '',
    hashtag: '',
  },
  age: {
    value: '',
    status: '',
    subtext: '',
    hashtag: '',
  },
  gender: {
    value: '',
    status: '',
    subtext: '',
    hashtag: '',
  },
  keywordMethod: {
    value: '',
    label: '',
    subTabs: '',
    status: '',
    subtext: '',
    hashtag: '',
    marketplace: '',
    isAutomatedCampaign: '',
    marketplaceCode: '',
  },
  enableDisableFormat: {},
  audienceFormat: {
    audience_store_visitors: '',
    audience_similar_products: '',
  },
  actionNeededFormat: {
    value: '',
    marketplaceName: '',
    cookieState: '',
  },
  connectionStatusFormat: {
    status: '',
  },
  ...(ff.new_aggregate_for_campaign_cell_format
    ? {
        aggregateAdObjectBudget: {
          currency: '',
          campaignId: '',
        },
        aggregateKeywordBiddingPrice: {
          currency: '',
          campaignId: '',
        },
      }
    : {}),
};

export const CELL_FORMAT_STATIC_KEYS = {
  traction: {
    period: 'week',
    timeRange: 8,
    formatTime: '[CW]W - YYYY',
  },
};
