export const STOREFRONT_INTEGRATION_CONFIG = {
  sort: [],
  view: {
    id: 'default',
    name: 'Default',
    combinator: {
      sort: [],
      filter: [],
      groupBy: {},
      properties: {
        metric: [],
        attribute: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
        dimension: [],
      },
      columnOrder: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
      columnWidth: [
        {
          width: 40,
          columnField: 'checkbox',
        },
        {
          width: 300,
          columnField: 'shop_name',
        },
        {
          width: 300,
          columnField: 'shop_url',
        },
        {
          width: 150,
          columnField: 'status',
        },
        {
          width: 150,
          columnField: 'updated_at_timestamp',
        },
      ],
      pinnedColumn: [],
      specialFilters: {},
    },
    systemView: true,
    initializedView: true,
  },
  title: 'Workspace Subscription',
  views: [
    {
      id: 'default',
      name: 'Default',
      combinator: {
        sort: [],
        filter: [],
        groupBy: {},
        properties: {
          metric: [],
          attribute: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
          dimension: [],
        },
        columnOrder: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
        columnWidth: [
          {
            width: 40,
            columnField: 'checkbox',
          },
          {
            width: 300,
            columnField: 'shop_name',
          },
          {
            width: 300,
            columnField: 'shop_url',
          },
          {
            width: 150,
            columnField: 'status',
          },
          {
            width: 150,
            columnField: 'updated_at_timestamp',
          },
        ],
        pinnedColumn: [],
        specialFilters: {},
      },
      systemView: true,
      initializedView: true,
    },
  ],
  filter: [],
  metric: [],
  search: '',
  system: {
    filter: [],
    allowPivot: 'no',
    datetimeField: 'created_datetime',
    numberOfPivot: 0,
    allowCalculate: 'yes',
    externalFilters: [],
    hiddenComponents: ['majorButton', 'search', 'settingMenu', 'actionButton', 'timeline'],
    externalProperties: ['metric'],
    defaultExpandedRows: 0,
    externalFiltersOptions: [],
    ignoreFooterRow: true,
  },
  groupBy: {},
  mapping: {
    shop_name: {
      menu: [],
      title: 'Shop name',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: '',
      cellFormat: 'oneDimension',
      filterField: '',
      staticValue: {},
      valueGetter: {
        id: 'storefront.shop_id',
        label: 'storefront.shop_name',
        value: 'storefront.shop_id',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    shop_url: {
      menu: [],
      title: 'Shop url',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      cellFormat: 'oneDimension',
      filterField: '',
      staticValue: {},
      valueGetter: {
        label: 'storefront.shop_url',
        value: 'storefront.shop_url',
        id: 'storefront.shop_url',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    status: {
      menu: [],
      title: 'Status',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: '',
      cellFormat: 'oneDimension',
      filterField: '',
      staticValue: {},
      valueGetter: {
        label: '=PROPER(p("value"))',
        value: 'storefront.status',
        id: 'storefront.status',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    updated_at_timestamp: {
      menu: [],
      title: 'Updated at',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: '',
      cellFormat: 'oneDimension',
      filterField: '',
      staticValue: {},
      valueGetter: {
        label: '=TEXT(p("value"), "local")',
        value: 'storefront.updated_at_timestamp',
        id: 'storefront.updated_at_timestamp',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
  },
  currency: 'USD',
  endpoint: {
    GET_TABLE_DATA: 'https://datacenter.epsilo.io/v2/query.jsp?namespace=passport',
  },
  _editorId: 'table_Q7POBMqWTUfPn0Cw7vYJe',
  attribute: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
  dateRange: {
    dateTo: '2023-04-25',
    dateFrom: '2023-04-18',
  },
  dimension: [],
  tableType: 'compact',
  columnOrder: ['shop_name', 'shop_url', 'status', 'updated_at_timestamp'],
  columnWidth: [
    {
      width: 40,
      columnField: 'checkbox',
    },
    {
      width: 300,
      columnField: 'shop_name',
    },
    {
      width: 300,
      columnField: 'shop_url',
    },
    {
      width: 150,
      columnField: 'status',
    },
    {
      width: 150,
      columnField: 'updated_at_timestamp',
    },
  ],
  primaryKeys: ['storefront.shop_id'],
  settingType: [
    {
      type: 'dimension',
      label: 'Dimension',
      allowConfig: true,
    },
    {
      type: 'attribute',
      label: 'Attribute',
      allowConfig: true,
    },
    {
      type: 'metric',
      label: 'Metric',
      allowConfig: true,
    },
  ],
  customAction: {
    actions: [],
  },
  hiddenFilter: {},
  pinnedColumn: [],
  viewByConfig: {
    timeline: ['regularly', 'hourly', 'daily', 'weekly', 'monthly', 'quarterly', 'yearly', 'all'],
    dimension: ['_dimension_all'],
  },
  hiddenColumns: ['ad_type'],
  calendarConfig: {
    typePicker: 'default',
    dateRangeSelect: 'yes',
  },
  specialFilters: {},
  allowCustomView: true,
  shortcutActions: {
    sort: {
      name: 'sort',
      order: 1,
      shortcut: true,
      hideLabel: false,
    },
    filter: {
      name: 'filter',
      order: 0,
      shortcut: true,
      hideLabel: false,
    },
  },
  defaultPagination: {
    page: 1,
    limit: 100,
    total: 0,
    traceId: '',
  },
  visualizationType: 'table',
  disabledComponents: {
    dimension: [],
  },
  linkToGlobalFilter: 'no',
  requestHiddenField: {
    attribute: ['ADTYPE.ads_type'],
    dimension: ['ADTYPE'],
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  hiddenPropertyColumns: [],
  paginationIsExactTotal: false,
  isSupportGroupAggregation: true,
};
