import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Correct = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 0.5C2.525 0.5 0.5 2.525 0.5 5C0.5 7.475 2.525 9.5 5 9.5C7.475 9.5 9.5 7.475 9.5 5C9.5 2.525 7.475 0.5 5 0.5ZM7.475 4.4L4.85 7.025C4.625 7.175 4.475 7.25 4.25 7.25C4.025 7.25 3.875 7.175 3.725 7.025L2.6 5.975C2.3 5.675 2.3 5.225 2.6 4.925C2.9 4.625 3.35 4.625 3.65 4.925L4.25 5.45L6.35 3.35C6.65 3.05 7.1 3.05 7.4 3.35C7.7 3.575 7.7 4.1 7.475 4.4Z"
      fill="#0BA373"
    />
  </SvgIcon>
);

export default Correct;
