import { mopFilterToQueryParams } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import qs from 'qs';

const SHOPEE_SC_LINK = {
  VN: 'https://shopee.vn',
  PH: 'https://shopee.ph',
  MY: 'https://shopee.com.my',
  SG: 'https://shopee.sg',
  ID: 'https://shopee.co.id',
  TH: 'https://shopee.co.th',
};

const getAdObjectPDPLink = (data) => {
  const marketplace = data.row['MARKETPLACE.marketplace_code'];
  const countryCode = data.row['COUNTRY.country_code'];
  const productSId = data.row['PRODUCT.product_sid'];
  const shopSId = data.row['STOREFRONT.storefront_sid'];
  switch (marketplace) {
    case 'SHOPEE':
      if (SHOPEE_SC_LINK[countryCode]) {
        return `${SHOPEE_SC_LINK[countryCode]}/${productSId}-i.${shopSId}.${productSId}`;
      }
    default:
      return '';
  }
};

export const CAMPAIGN_DETAIL_COMMON_MAPPING = {
  // ---------------------------- Attribute ----------------------------------//

  // ---------------------------- Metric ----------------------------------//
  roas: {
    title: 'ROAS',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'roas',
    valueGetter: {
      value: 'roas',
    },
  },
  direct_gmv: {
    title: 'Direct ad GMV',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'direct_gmv',
    valueGetter: {
      value: 'direct_gmv',
      currency: 'STOREFRONT.currency',
    },
  },
  cost: {
    title: 'Cost',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'cost',
    valueGetter: {
      value: 'cost',
      currency: 'STOREFRONT.currency',
    },
  },
  ads_gmv: {
    title: 'Ads GMV',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'ads_gmv',
    valueGetter: {
      value: 'ads_gmv',
      currency: 'STOREFRONT.currency',
    },
  },
  items_sold: {
    title: 'Items sold',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'ads_item_sold',
    valueGetter: {
      value: 'ads_item_sold',
    },
  },
  direct_item_sold: {
    title: 'Direct item sold',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'direct_item_sold',
    valueGetter: {
      value: 'direct_item_sold',
    },
  },
  cpc: {
    title: 'CPC',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'cpc',
    valueGetter: {
      value: 'cpc',
      currency: 'STOREFRONT.currency',
    },
  },
  ads_order: {
    title: 'Order',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'ads_order',
    valueGetter: {
      value: 'ads_order',
    },
  },
  click: {
    title: 'Click',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'click',
    valueGetter: {
      value: 'click',
    },
  },
  impression: {
    title: 'Impression',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'impression',
    valueGetter: {
      value: 'impression',
    },
  },
  ctr: {
    title: 'CTR',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'ctr',
    valueGetter: {
      value: 'ctr',
    },
  },
  cr: {
    title: 'CR',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'cr',
    valueGetter: {
      value: 'cr',
    },
  },
  stock: {
    title: 'Stock',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'integer',
    filterField: 'stock',
    valueGetter: {
      value: 'stock',
    },
  },
  discount: {
    title: 'Discount',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'discount',
    valueGetter: {
      value: 'discount',
    },
  },
  direct_roas: {
    title: 'Direct ROAS',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'direct_roas',
    valueGetter: {
      value: 'direct_roas',
    },
  },
  cpi: {
    title: 'CPI',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'cpi',
    valueGetter: {
      value: 'cpi',
    },
  },
  direct_cr: {
    title: 'Direct CR',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'direct_cr',
    valueGetter: {
      value: 'direct_cr',
    },
  },
  direct_cpi: {
    title: 'Direct CPI',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'direct_cpi',
    valueGetter: {
      value: 'direct_cpi',
    },
  },
  direct_cir: {
    title: 'Direct CIR',
    propertyType: 'metric',
    cellFormat: 'currency',
    dataType: 'float',
    filterField: 'direct_cir',
    valueGetter: {
      value: 'direct_cir',
    },
  },
  // selling_price: {
  //   title: 'Selling price',
  //   propertyType: 'metric',
  //   cellFormat: 'currency',
  //   dataType: 'float',
  //   filterField: 'selling_price',
  //   valueGetter: {
  //     value: 'selling_price',
  //     currency: 'STOREFRONT.currency',
  //   },
  // },
  // ad_object_suggested_bidding_price: {
  //   title: 'Ad object suggested bidding price',
  //   propertyType: 'metric',
  //   cellFormat: 'currency',
  //   dataType: 'float',
  //   filterField: 'ad_object_suggested_bidding_price',
  //   valueGetter: {
  //     value: 'ad_object_suggested_bidding_price',
  //     // currency: 'ADS_OBJECT.currency',
  //   },
  // },
  // search_volume: {
  //   title: 'Search volume',
  //   propertyType: 'metric',
  //   cellFormat: 'currency',
  //   dataType: 'integer',
  //   filterField: 'SUGGESTED_KEYWORD.search_volume',
  //   valueGetter: {
  //     value: 'SUGGESTED_KEYWORD.search_volume',
  //   },
  // },
  // keyword_suggested_bidding_price: {
  //   title: 'Suggest bidding price',
  //   propertyType: 'metric',
  //   cellFormat: 'currency',
  //   dataType: 'float',
  //   filterField: 'SUGGESTED_KEYWORD.bidding_price',
  //   valueGetter: {
  //     value: 'SUGGESTED_KEYWORD.bidding_price',
  //     currency: 'STOREFRONT.currency',
  //   },
  // },
  // suggested_premium_rate: {
  //   title: 'Suggested premium rate',
  //   propertyType: 'metric',
  //   cellFormat: 'text',
  //   dataType: 'float',
  //   filterField: 'AUDIENCE.premium_rate',
  //   valueGetter: {
  //     label: 'AUDIENCE.premium_rate',
  //   },
  // },
};

export const getActionMenu = (column, context: any = {}) => {
  switch (column) {
    case 'marketplace':
    case 'country':
    case 'storefront':
    case 'ad_type':
    case 'ad_tool':
      return [
        // {
        //   name: 'Create campaign',
        //   icon: 'add',
        //   iconSize: '13px',
        // },
        {
          name: 'Tag',
          icon: 'tag',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'campaign':
      return [
        {
          name: 'Add ad object',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Duplicate campaign',
          icon: 'duplicatePlus',
          disable: true,
        },
        {
          title: 'Campaign name',
          name: 'Edit name',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_CAMPAIGN.name',
            },
          },
        },
        {
          name: 'Campaign detail',
          icon: 'showDetail',
          // editor: 'LinkTo',
          onClickItem(data: { row: any; value: any }) {
            console.info(data);
            window.open(
              `${getPageUrn('/page/afdd53e6-49f9-4170-9212-bdeada713c0d')}/${data.value.id}?marketplace=` +
                data.row['MARKETPLACE.marketplace_code'],
            );
          },
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'ad_object':
      return [
        {
          name: 'Add keyword',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add negative keyword',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add placement',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add audience',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Open product detail page',
          icon: 'openPdp',
          onClickItem: (data) => {
            const link = getAdObjectPDPLink(data);
            if (link) {
              window.open(link);
            }
          },
        },
        {
          name: 'Tag',
          icon: 'tag',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'keyword':
      return [
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'negative_keyword':
      return [
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'script':
      return [
        {
          name: 'View details',
          icon: 'setscript',
          onClickItem: (data) => {
            const value: any = data.value;
            const queryStr = qs.stringify({
              type: context.type,
              marketplace: data.row['MARKETPLACE.marketplace_code'],
            });

            window.open(getPageUrn('/page/ec22dd25-a14f-40d8-9199-a5b0517e835e/' + value.id) + '?' + queryStr);
          },
        },
        {
          name: 'View log',
          icon: 'history',
          onClickItem: (data) => {
            const value: any = data.value;
            const filters: MopFilterType[] = [
              {
                field: 'SCRIPT_LOG.script_id',
                dataType: 'integer',
                operator: '=',
                value: value.id,
              },
            ];

            window.open(
              getPageUrn('/page/3f38d2fa-4eb5-47a7-b937-146872685fb5') +
                '?' +
                qs.stringify({
                  mopq: mopFilterToQueryParams(filters),
                }),
            );
          },
        },
        {
          name: 'Replace',
          icon: 'refresh',
          disable: true,
        },
      ];
    case 'keyword_position': {
      return [
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Open search result page',
          icon: 'openPdp',
          onClickItem: (data) => {
            const countryCode = data.row['COUNTRY.country_code'];
            const keyword = data.row['ADS_PLACEMENT.name'];
            if (SHOPEE_SC_LINK[countryCode]) {
              window.open(`${SHOPEE_SC_LINK[countryCode]}/search?keyword=${keyword}`);
            }
          },
        },
        {
          name: 'Refresh',
          icon: 'refresh',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.position.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'position',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
    case 'campaign_daily_budget': {
      return [
        {
          title: 'Campaign daily budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.daily_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'daily_budget',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_CAMPAIGN.daily_budget.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'daily_budget',
              dimension: 'ADS_CAMPAIGN',
              id: 'ADS_CAMPAIGN.id',
            },
          },
        },
      ];
    }
    case 'campaign_total_budget': {
      return [
        {
          title: 'Campaign total budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.total_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'total_budget',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_CAMPAIGN.total_budget.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'total_budget',
              dimension: 'ADS_CAMPAIGN',
              id: 'ADS_CAMPAIGN.id',
            },
          },
        },
      ];
    }
    case 'ad_object_budget': {
      return [
        {
          title: 'Ad object budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SelectionBudgetEditor',
          payload: {
            field: {
              totalBudget: 'ADS_OBJECT.total_budget',
              dailyBudget: 'ADS_OBJECT.daily_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: ['total_budget', 'daily_budget'],
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.daily_budget.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'daily_budget',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'ad_object_daily_budget': {
      return [
        {
          title: 'Ad object budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              dailyBudget: 'ADS_OBJECT.daily_budget',
              value: 'ADS_OBJECT.daily_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: ['daily_budget'],
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.daily_budget.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'daily_budget',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'ad_object_total_budget': {
      return [
        {
          title: 'Ad object budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              totalBudget: 'ADS_OBJECT.total_budget',
              value: 'ADS_OBJECT.total_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: ['total_budget'],
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.total_budget.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'total_budget',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'keyword_match_type': {
      return [
        {
          title: 'Match type',
          name: 'Change match type',
          icon: 'pencil',
          editor: 'MatchTypeEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.match_type',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'match_type',
              dimension_type: 'ADS_PLACEMENT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.match_type.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'match_type',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
    case 'campaign_timeline': {
      return [
        {
          title: 'Campaign timeline',
          name: 'Change timeline',
          icon: 'pencil',
          editor: 'TimeLineEditor',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'NOT_CONTAIN',
              values: ff.ui_change_timeline_campaign ? [] : ['ENDED'],
            },
          ],
          payload: {
            field: {
              date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
              date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
            },
            restrict: [
              {
                condition: {
                  field: 'ADS_CAMPAIGN.status',
                  operator: 'NOT_CONTAIN',
                  values: ['DRAFT', 'SCHEDULED'],
                },
                disableComponent: ['startDate'],
              },
            ],
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              fields: [
                'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from.status',
                'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to.status',
              ],
              fieldsOperator: 'OR',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              dimension: 'ADS_CALENDAR',
              id: 'ADS_CALENDAR.ADS_CAMPAIGN.id',
              conditional_field_name: [
                {
                  condition: [
                    {
                      field: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from.status',
                      operator: 'CONTAIN',
                      values: [2],
                    },
                  ],
                  field_name: 'timeline_from',
                },
                {
                  condition: [
                    {
                      field: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to.status',
                      operator: 'CONTAIN',
                      values: [2],
                    },
                  ],
                  field_name: 'timeline_to',
                },
              ],
            },
          },
        },
      ];
    }
    case 'ad_object_timeline': {
      return [
        {
          title: 'Ad object timeline',
          name: 'Change timeline',
          icon: 'pencil',
          editor: 'TimeLineEditor',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'NOT_CONTAIN',
              values: ['ENDED'],
            },
          ],
          payload: {
            field: {
              date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
              date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
              id: 'ADS_CALENDAR.ADS_OBJECT.id',
            },
            restrict: [
              {
                condition: {
                  field: 'ADS_CAMPAIGN.status',
                  operator: 'NOT_CONTAIN',
                  values: ['DRAFT', 'SCHEDULED'],
                },
                disableComponent: ['startDate'],
              },
            ],
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              fields: ['ADS_CALENDAR.ADS_OBJECT.timeline_from.status', 'ADS_CALENDAR.ADS_OBJECT.timeline_to.status'],
              fieldsOperator: 'OR',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              dimension: 'ADS_CALENDAR',
              id: 'ADS_CALENDAR.ADS_OBJECT.id',
              conditional_field_name: [
                {
                  condition: [
                    {
                      field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from.status',
                      operator: 'CONTAIN',
                      values: [2],
                    },
                  ],
                  field_name: 'timeline_from',
                },
                {
                  condition: [
                    {
                      field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to.status',
                      operator: 'CONTAIN',
                      values: [2],
                    },
                  ],
                  field_name: 'timeline_to',
                },
              ],
            },
          },
        },
      ];
    }
    case '_metric': {
      return [
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Refresh',
          icon: 'refresh',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
      ];
    }
    case 'ad_object_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_OBJECT.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'campaign_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_CAMPAIGN.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'keyword_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_PLACEMENT.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'sub_item_tag': {
      return [
        {
          title: 'Edit tag',
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TaglineEditor',
        },
        {
          title: 'Delete Tag',
          name: 'Delete',
          icon: 'delete',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'campaign_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_CAMPAIGN.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.note',
            },
          },
        },
      ];
    }
    case 'ad_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_PLACEMENT.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.note',
            },
          },
        },
      ];
    }
    case 'ad_object_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_OBJECT.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_OBJECT.note',
            },
          },
        },
      ];
    }
    case 'campaign_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'status',
              dimension: 'ADS_CAMPAIGN',
              id: 'ADS_CAMPAIGN.id',
            },
          },
        },
      ];
    }
    case 'ad_object_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED', 'INELIGIBLE'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.status.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'status',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'keyword_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_PLACEMENT',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_PLACEMENT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.status.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'status',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
    case 'tagline': {
      return [
        {
          title: 'Creative tagline',
          name: 'Edit creative tagline',
          icon: 'pencil',
          editor: 'TaglineEditor',
          payload: {
            field: {
              label: 'ADS_OBJECT.tagline',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.tagline.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'tagline',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'keyword_bidding_price': {
      return [
        {
          title: 'Keyword bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_PLACEMENT.name',
            },
            static: {
              primaryKey: 'ADS_PLACEMENT.id',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'bidding_price',
              dimension_type: 'ADS_PLACEMENT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.bidding_price.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'bidding_price',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
    case 'age': {
      return [
        {
          title: 'Age',
          name: 'Change age',
          icon: 'pencil',
          editor: 'AgeEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.audience_age',
            },
            rowPicker: {
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.audience_age.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'audience_age',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'gender': {
      return [
        {
          title: 'Gender',
          name: 'Change gender',
          icon: 'pencil',
          editor: 'GenderEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.audience_gender',
            },
            rowPicker: {
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.audience_gender.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'audience_gender',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'audience_location': {
      return [
        {
          title: 'Location',
          name: 'Change location',
          icon: 'pencil',
          editor: 'LocationEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.audience_location',
            },
            rowPicker: {
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.audience_location.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'audience_location',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'interest_category': {
      return [
        {
          title: 'Interest category',
          name: 'Change interest category',
          icon: 'pencil',
          editor: 'InterestCategoryEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.audience_interest_category',
            },
            rowPicker: {
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.audience_interest_category.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'audience_interest_category',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'ad_object_bidding_price': {
      return [
        {
          title: 'Ad object bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          condition: [
            {
              field: 'ADS_OBJECT.automated_ads',
              operator: 'CONTAIN',
              values: [0],
            },
          ],
          payload: {
            field: {
              value: 'ADS_OBJECT.base_bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_OBJECT.name',
            },
            static: {
              primaryKey: 'ADS_OBJECT.id',
            },
            excludeOperator: [
              {
                operator: 'suggest-bid-price',
                field: 'ADTOOL.ads_tool',
                values: ff.enable_bidding_price ? [] : ['SHP_DA'],
              },
            ],
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'base_bidding_price',
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.base_bidding_price.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'base_bidding_price',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'premium_rate': {
      return [
        {
          title: 'Premium rate',
          name: 'Change premium rate',
          icon: 'pencil',
          editor: 'PremiumRateEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.bidding_premium',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_PLACEMENT.name',
            },
            static: {
              primaryKey: 'ADS_PLACEMENT.id',
            },
            excludeOperator: [
              {
                operator: 'suggest-bid-price',
                field: 'ADTOOL.ads_tool',
                values: ff.enable_bidding_price ? [] : ['SHP_DA'],
              },
            ],
          },
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'NOT_CONTAIN',
              values: ['ENDED', 'PAUSED', 'SUSPENDED'],
            },
            {
              field: 'ADS_OBJECT.status',
              operator: 'NOT_CONTAIN',
              values: ['ENDED', 'PAUSED', 'SUSPENDED'],
            },
          ],
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.bidding_premium.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'bidding_premium',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
    case 'ad_campaign_object': {
      return [
        {
          title: 'Ad campaign object',
          name: 'Change object',
          icon: 'editStatus',
          editor: 'DropdownEditor',
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.objective',
            },
          },
          dimension: 'ADS_CAMPAIGN',
          field: 'objective',
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_CAMPAIGN.objective.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'objective',
              dimension: 'ADS_CAMPAIGN',
              id: 'ADS_CAMPAIGN.id',
            },
          },
        },
      ];
    }
    case 'ad_object_creative': {
      return [
        {
          title: 'Ad object creative',
          name: 'Change creative',
          icon: 'editStatus',
          editor: 'DropdownEditor',
          payload: {
            field: {
              status: 'ADS_OBJECT.automated_creatives',
            },
          },
        },
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_OBJECT.automated_creatives.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'automated_creatives',
              dimension: 'ADS_OBJECT',
              id: 'ADS_OBJECT.id',
            },
          },
        },
      ];
    }
    case 'keyword_method': {
      return [
        {
          name: 'Retry',
          icon: 'reload',
          id: 'retry_sync_value',
          condition: [
            {
              field: 'ADS_PLACEMENT.automated_ads.status',
              operator: 'CONTAIN',
              values: [2],
            },
          ],
          payload: {
            field: {
              field_name: 'automated_ads',
              dimension: 'ADS_PLACEMENT',
              id: 'ADS_PLACEMENT.id',
            },
          },
        },
      ];
    }
  }
};

export const staticFilter = {
  campaignSettings: [
    {
      field: {
        label: 'Marketplace',
        value: 'marketplace',
        dataType: 'string',
        selectedOperators: ['is', 'is_not', 'contains', 'does_not_contain'],
      },
      logicalOperator: 'and',
      queryType: {
        label: 'Is',
        value: 'is',
      },
      queryValue: {
        value: 'SHOPEE',
      },
      type: 'filter',
      static: true,
    },
    {
      field: {
        label: 'Ads GMV',
        value: 'ads_gmv',
        dataType: 'float',
      },
      logicalOperator: 'and',
      queryType: {
        label: '>',
        value: '>',
      },
      queryValue: {
        value: '0',
      },
      type: 'filter',
      static: false,
    },
  ],
  default: [
    {
      field: {
        label: 'Marketplace',
        value: 'marketplace',
        dataType: 'string',
        selectedOperators: ['is', 'is_not', 'contains', 'does_not_contain'],
      },
      logicalOperator: 'and',
      queryType: {
        label: 'Is',
        value: 'is',
      },
      queryValue: {
        value: 'SHOPEE',
      },
      type: 'filter',
      static: true,
    },
  ],
};
