import { noSelectStyled, TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import * as _ from 'lodash';
import { colorsChartLegend, PopLegend, tableTooltip, legendLine } from '../helper';
import { BulletChartOptions, ScaleTypes, LegendPositions } from '@carbon/charts/interfaces';
const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legendLine: legendLine as CreateCSSProperties,
  pop: PopLegend as CreateCSSProperties,
  totalValueLine: TooltipMapping.totalValueLine as CreateCSSProperties,
  canvas: {
    height: '100%',
    width: '100%',
    '& canvas': {
      height: '100% !important',
      margin: 'auto',
      // marginTop: '10%',
    },
  },
  tooltip: {
    borderRadius: '3px',
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    transform: 'translate(-50%, 0)',
    transition: 'all .1s ease',
    zIndex: 999,
  },
  tableTooltip: tableTooltip as CreateCSSProperties,
}));

interface ILineStyleProps {
  percent?: string;
  width?: number;
  legendDivID?: string;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props?: ILineStyleProps) => {
  const classes = useStyles();

  const customHTML =
    (header = {}, color = {}) =>
    (data, htmlString) => {
      const tableRoot = document.createElement('table');
      const tableHead = document.createElement('thead');
      const tableBody = document.createElement('tbody');

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(header[data[0].value]);

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      })();

      (() => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        const tdHTML = `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${color[data[0].value]};"></span>
        <div class="content">
          <div class="label">${data[0].value}</div>
          <div class="value">${`${data[1].value}`}</div>
          <div class="percent">(${data[3].value})</div>
        </div>
      </td>
      `;
        tr.innerHTML = tdHTML;
        tableBody.appendChild(tr);
      })();

      tableRoot.className = `${classes.tableTooltip}`;
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);

      return tableRoot.outerHTML;
    };

  const defaultOptions: BulletChartOptions = {
    axes: {
      bottom: {
        mapsTo: 'value',
        extendLinearDomainBy: 'marker',
        ticks: {
          formatter: (value, index, arr) => {
            return value;
          },
        },
      },
      left: {
        scaleType: ScaleTypes.LABELS,
        mapsTo: 'title',
      },
      right: {
        visible: false,
        scaleType: ScaleTypes.LABELS_RATIO,
        mapsTo: 'title',
      },
    },
    resizable: true,
    legend: {
      enabled: false,
      position: LegendPositions.RIGHT,
    },
    toolbar: {
      enabled: false,
    },
    tooltip: {
      customHTML: customHTML({}),
    },
  };

  return {
    customHTML,
    defaultOptions,
  };
};
