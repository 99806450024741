import moment from 'moment';
import React from 'react';
import * as _ from 'lodash';
import { ITreeMapDataChart } from '../type';
import TreeMapChartResponse from './treemap-chart';
import NewTreeMapChartResponse from './treemap-chart_integrate_api_sos';
import { funcConfigs } from './new-hooks';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { colorsChartLegend, defaultOptions, tool } from '../helper';
import { IWaterfallDataChart } from '../type';
import { IStateChartValue } from '../chart-loading-state/chart-loading-state';
type Props = {
  data: ITreeMapDataChart[];
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  showTable?: any;
  isSimplified?: boolean;
  handleReload?: any;
};
function TreeMapChart({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
  showTable,
  isSimplified = false,
  handleReload = () => undefined,
}: Props) {
  const { defaultOptions, customHTML } = funcConfigs();

  const [dataChart, setDataChart] = React.useState<{ dataList: ITreeMapDataChart[]; optionList: any }>({
    dataList: [],
    optionList: JSON.parse(JSON.stringify(defaultOptions)),
  });

  const TOTAL_VALUE = React.useMemo(() => _.sum(data.map((tree) => _.sumBy(tree.children, 'value'))), [data]);

  const PERCENT_LIST = React.useMemo(() => {
    const result = {};
    data.forEach((tree) => {
      tree.children.forEach((leaf) => {
        result[leaf.name] = leaf.value.toFixed(2);
      });
    });
    return result;
  }, [data, TOTAL_VALUE]);

  React.useEffect(() => {
    const hashColors = {};
    [...data]
      .sort((a, b) => b.children[0].value - a.children[0].value)
      .forEach((item, index) => {
        hashColors[(item.name || '').split('\n')[0]] = colors.charts[index];
      });

    //Display option chart
    // _.set(dataChart.optionList, 'title', 'TreeMap Chart');
    _.set(dataChart.optionList, 'getFillColor', (group, label, data, defaultFillColor) => {
      return hashColors[group];
    });
    _.set(dataChart.optionList, 'tooltip.customHTML', customHTML);
    _.set(dataChart.optionList, 'tooltip.valueFormatter', (value, label) => {
      return {
        label,
        value,
      };
    });
    if (ff.replace_keyword_compact) {
      _.set(dataChart.optionList, 'legend.position', 'bottom');
      if (isSimplified) {
        _.set(dataChart.optionList, 'legend.clickable', false);
      }
    }
    setDataChart({ dataList: data, optionList: dataChart.optionList });
  }, [data]);
  return (
    <NewTreeMapChartResponse
      percentList={PERCENT_LIST}
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      currency={currency}
      percent={percent}
      showTable={showTable}
      {...(ff.replace_keyword_compact ? { isSimplified, handleReload } : {})}
    />
  );
}

export default TreeMapChart;
