import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Done = (props: SvgIconProps) => (
  <SvgIcon {...props} width="13" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.6861 1.32973C11.587 1.22497 11.4676 1.14152 11.3351 1.08449C11.2026 1.02746 11.0599 0.998047 10.9157 0.998047C10.7715 0.998047 10.6288 1.02746 10.4963 1.08449C10.3639 1.14152 10.2444 1.22497 10.1453 1.32973L3.29171 8.3334L1.87097 6.91266C1.77041 6.81041 1.65049 6.7292 1.51823 6.67377C1.38596 6.61833 1.24398 6.58978 1.10056 6.58978C0.957149 6.58978 0.81517 6.61833 0.682901 6.67377C0.550633 6.7292 0.430722 6.81041 0.33016 6.91266C0.124236 7.12221 0.0088501 7.40426 0.0088501 7.69807C0.0088501 7.99187 0.124236 8.27392 0.33016 8.48348L2.47128 10.6446C2.5778 10.7563 2.70587 10.8452 2.84776 10.906C2.98964 10.9667 3.14237 10.998 3.29672 10.998C3.45106 10.998 3.60379 10.9667 3.74567 10.906C3.88756 10.8452 4.01563 10.7563 4.12215 10.6446L11.6961 2.90055C11.8983 2.68847 12.0103 2.40616 12.0085 2.11312C12.0066 1.82008 11.891 1.53921 11.6861 1.32973Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Done;
