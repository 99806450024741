import { EIP_CONSTANT } from '@eip/next/lib/main';

import { exportMassUpload } from '@ep/insight-ui/system/backbone/data-source/common';

export const MASS_OPERATION_MANAGEMENT = {
  title: 'Request management',
  tableType: 'massOperationTable',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getRequestData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_OBJECT',
  },
  primaryKeys: [],
  system: {
    hiddenComponents: ['search'],
  },
  mainButton: {
    text: 'Import',
    type: 'component',
    componentName: 'ImportMassOperationBtn',
  },
  views: [
    {
      id: 'overview',
      name: 'Overview',
    },
  ],
  view: {
    id: 'overview',
    name: 'Overview',
  },
  mapping: {
    request: {
      title: 'Request',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'REQUEST.name',
      valueGetter: {
        label: 'REQUEST.name',
        id: 'REQUEST.id',
      },
      menu: [
        {
          name: 'Export result',
          icon: 'export',
          onClickItem: (data) => {
            exportMassUpload(data.value.id).then((res) => {
              if (res.success) {
                window.location.href = res.data.url;
              }
            });
          },
        },
      ],
    },
    status: {
      title: 'Status',
      propertyType: 'attribute',
      cellFormat: 'statusRequest',
      dataType: 'string',
      filterField: 'REQUEST.status_name',
      valueGetter: {
        label: 'REQUEST.status_name',
        status: 'REQUEST.status_name',
        id: 'REQUEST.status_id',
      },
    },
    result: {
      title: 'Result',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      valueGetter: {
        label: 'REQUEST.result',
      },
    },
    uploaded_at: {
      title: 'Upload at',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'date',
      filterField: 'REQUEST.uploaded_at',
      valueGetter: {
        label: 'REQUEST.uploaded_at',
      },
    },
    owner: {
      title: 'Owner',
      propertyType: 'attribute',
      cellFormat: 'userInfo',
      dataType: 'string',
      filterField: 'REQUEST.owner_name',
      valueGetter: {
        name: 'REQUEST.owner_name',
        id: 'REQUEST.owner_id',
      },
    },
  },
};
