import { Box, CircularProgress } from '@material-ui/core';
import React from 'react';

function CircleLoading({ size = '14px', color = '#232323' }: { size?: string; color?: string }) {
  return (
    <Box component={'span'} display={'flex'} mr={'5px'}>
      <CircularProgress size={size} style={{ color: color }} />
    </Box>
  );
}

export default CircleLoading;
