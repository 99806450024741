import * as React from 'react';

interface IPinnedColumn {
  id: string;
  left: number;
  width: number;
  initialIndex: number;
  stickyWidth: number;
  breakLine: number;
  isLeftPin: boolean;
}

export const usePinThisColumn = (pinnedColumns, rowsGroup) => {
  // Store all columns are sticky
  const [selectedPinnedColumns, setSelectedPinnedColumns] = React.useState<IPinnedColumn[]>([]);
  // Ag grid column API
  const [columnApi, setColumnApi] = React.useState(null);
  // Get table DOM
  const tableDOM = React.useRef(null);

  // Add scroll event for table
  React.useEffect(() => {
    if (tableDOM && columnApi) {
      // Optimize header pinned column when scroll
      const mutationObserver = new MutationObserver(() => {
        restyleHeaderStickyColumn(tableDOM);
      });
      mutationObserver.observe(tableDOM.current.querySelector('.ag-header-container'), {
        attributes: true,
        attributeFilter: ['style'],
      });

      // Trigger event to set style for sticky column when table view change
      window.requestAnimationFrame(() => {
        restyleStickyColumn(tableDOM);
      });

      const scrollEvent = function () {
        const { scrollLeft } = this;
        // Filter pin this columns
        const filteredSelectedPinnedColumns = selectedPinnedColumns.filter(({ isLeftPin }) => !isLeftPin);
        filteredSelectedPinnedColumns.forEach(({ id, stickyWidth, width, breakLine, initialIndex, left }, colIndex) => {
          const column = columnApi.getColumn(id);
          if (scrollLeft > column.left + width - stickyWidth) {
            if (colIndex === 0) {
              const index = columnApi.getAllGridColumns().findIndex((el) => el.left > scrollLeft);
              const columns = filteredSelectedPinnedColumns.filter((selectedPinnedColumn) => {
                const column = columnApi.getColumn(selectedPinnedColumn.id);
                return scrollLeft > column.left + selectedPinnedColumn.width - selectedPinnedColumn.stickyWidth;
              });
              const columnIds = columns.map((column) => column.id);
              /**
               * Use ag-grid move columns for preventing columns being remove from DOM
               */
              columnApi.moveColumns(columnIds, index - 1);
              columns.forEach((selectedPinnedColumn) => {
                setStickyColumn(selectedPinnedColumn.id, selectedPinnedColumn.stickyWidth);
              });
            }
          } else if (scrollLeft > breakLine && scrollLeft <= breakLine + width) {
            const columnIndex = columnApi.getAllGridColumns().findIndex((el) => el.colId === column.colId);
            if (columnIndex > initialIndex) {
              /**
               * Use ag-grid move columns for preventing columns being remove from DOM
               */
              columnApi.moveColumn(id, initialIndex + rowsGroup.length);
            }
            setStickyColumn(id, stickyWidth);
          } else if (scrollLeft > breakLine && scrollLeft <= column.left - stickyWidth) {
            if (colIndex === 0) {
              const columns = filteredSelectedPinnedColumns.filter((selectedPinnedColumn) => {
                const column = columnApi.getColumn(selectedPinnedColumn.id);
                return (
                  scrollLeft > selectedPinnedColumn.breakLine + selectedPinnedColumn.width &&
                  scrollLeft <= column.left - selectedPinnedColumn.stickyWidth
                );
              });
              const columnIds = columns.map((column) => column.id);
              const index = columnApi.getAllGridColumns().findIndex((el) => el.left > scrollLeft);
              /**
               * Use ag-grid move columns for preventing columns being remove from DOM
               */
              columnApi.moveColumns(columnIds, index - 1);
              columns.forEach((selectedPinnedColumn) => {
                setStickyColumn(selectedPinnedColumn.id, selectedPinnedColumn.stickyWidth);
              });
            }
          } else if (scrollLeft <= breakLine) {
            const columnIndex = columnApi.getAllGridColumns().findIndex((el) => el.colId === column.colId);
            if (columnIndex >= initialIndex) {
              columnApi.moveColumn(id, initialIndex + rowsGroup.length);
              unsetStickyColumn(id, left);
              unsetStickyColumnHeader(id, left);
            }
          }
          if (scrollLeft > breakLine) {
            setStickyColumnHeader(id, stickyWidth + scrollLeft);
          }
        });
      };
      tableDOM.current.querySelector('.ag-body-horizontal-scroll-viewport').addEventListener('scroll', scrollEvent);
      return () => {
        tableDOM.current
          .querySelector('.ag-body-horizontal-scroll-viewport')
          .removeEventListener('scroll', scrollEvent);
        mutationObserver.disconnect();
      };
    }
  }, [selectedPinnedColumns, rowsGroup.length, columnApi]);

  React.useEffect(() => {
    if (columnApi) {
      // remove sticky column when changing pinnedColumns
      const removedStickyColumns = selectedPinnedColumns.filter(
        ({ id }) => !pinnedColumns.some(({ field }) => field !== id),
      );

      removedStickyColumns.forEach(({ id, initialIndex, left }) => {
        columnApi?.moveColumn(id, initialIndex);
        unsetStickyColumn(id, left);
        unsetStickyColumnHeader(id, left);
      });

      const columnChange =
        pinnedColumns.find(({ field }) => selectedPinnedColumns.every(({ id }) => id !== field)) ||
        selectedPinnedColumns.find((selectedPinnedColumn) => {
          const isColumnChange = pinnedColumns.some(
            ({ field, isLeftPin }) => selectedPinnedColumn.id === field && selectedPinnedColumn.isLeftPin !== isLeftPin,
          );
          const isRemoved = pinnedColumns.every(({ field }) => selectedPinnedColumn.id !== field);
          return isColumnChange || isRemoved;
        });

      const columnChangeLeft = !columnChange
        ? 0
        : columnChange.id
        ? columnChange.left
        : columnApi.getColumn(columnChange.field)
        ? columnApi.getColumn(columnChange.field).left
        : 0;

      const columnChangeField = !columnChange ? null : columnChange.id || columnChange.field;

      setSelectedPinnedColumns(
        pinnedColumns
          .filter(({ field }) => columnApi.getColumn(field))
          .map((pinnedColumn, index, array) => {
            const { left, actualWidth, instanceId } = columnApi.getColumn(pinnedColumn.field);
            const stickyWidth = array.reduce((a, b) => {
              const column = columnApi.getColumn(b.field);
              return b.isLeftPin || instanceId <= column.instanceId ? a : a + column.actualWidth;
            }, 0);

            const selectedPinnedColumn = selectedPinnedColumns.find(({ id }) => id === pinnedColumn.field);
            if (selectedPinnedColumn) {
              let plusWidth = 0;
              if (
                columnChange &&
                columnChangeLeft <= selectedPinnedColumn.left &&
                columnChangeField !== selectedPinnedColumn.id
              ) {
                if (
                  (!!columnChange.id &&
                    !columnChange.isLeftPin &&
                    array.some(({ field }) => field === columnChange.id)) ||
                  (!columnChange.id && !!columnChange.isLeftPin)
                ) {
                  plusWidth -= 200;
                } else if (!!columnChange.id && !!columnChange.isLeftPin) {
                  plusWidth += 200;
                }
              }
              return {
                ...selectedPinnedColumn,
                left: selectedPinnedColumn.left + plusWidth,
                isLeftPin: pinnedColumn.isLeftPin,
                stickyWidth,
                breakLine: selectedPinnedColumn.left + plusWidth - stickyWidth,
              };
            }

            return {
              id: pinnedColumn.field,
              left: left,
              width: actualWidth,
              initialIndex:
                columnApi.getAllGridColumns().findIndex((column) => column.instanceId === instanceId) -
                rowsGroup.length,
              stickyWidth,
              breakLine: left - stickyWidth,
              isLeftPin: pinnedColumn.isLeftPin,
            };
          })
          .sort((a, b) => a.left - b.left),
      );
    }
  }, [pinnedColumns, rowsGroup.length, columnApi]);

  // Set sticky for ag-header-cell
  const setStickyColumnHeader = (pinnedColumn, stickyWidth) => {
    if (tableDOM) {
      const headerElement: any = tableDOM.current.querySelector(`.ag-header-cell[col-id="${pinnedColumn}"]`);
      if (headerElement) {
        headerElement.style.left = `${stickyWidth}px`;
        headerElement.style.zIndex = '1';
        headerElement.style.borderRight = '1px solid #ccc';
        headerElement.style.backgroundColor = '#fff';
        headerElement.style.transition = 'none';
      }
    }
  };

  // Set sticky for ag-cell
  const setStickyColumn = (pinnedColumn, stickyWidth) => {
    if (tableDOM) {
      tableDOM.current.querySelectorAll(`.ag-cell[col-id="${pinnedColumn}"]`).forEach((el: any) => {
        if (el) {
          el.style.left = `${stickyWidth}px`;
          el.style.zIndex = '1';
          el.style.position = 'sticky';
          el.style.borderRight = '1px solid #ccc';
          el.style.backgroundColor = '#fff';
          el.style.transition = 'none';
          el.style.display = 'inline-block';
        }
      });
    }
  };

  // Unset sticky for ag-cell
  const unsetStickyColumn = (pinnedColumn, left) => {
    if (tableDOM) {
      tableDOM.current.querySelectorAll(`.ag-cell[col-id="${pinnedColumn}"]`).forEach((el: any) => {
        if (el) {
          el.style.left = `${left}px`;
          el.style.zIndex = null;
          el.style.position = null;
          el.style.borderRight = null;
          el.style.backgroundColor = null;
          el.style.display = null;
          window.requestAnimationFrame(() => {
            el.style.transition = null;
          });
        }
      });
    }
  };

  // Unset sticky for ag-header-cell
  const unsetStickyColumnHeader = (pinnedColumnHeader, left) => {
    if (tableDOM) {
      const headerElement: any = tableDOM.current.querySelector(`.ag-header-cell[col-id="${pinnedColumnHeader}"]`);
      if (headerElement) {
        headerElement.style.left = `${left}px`;
        headerElement.style.borderRight = null;
        headerElement.style.zIndex = null;
        headerElement.style.backgroundColor = null;
        window.requestAnimationFrame(() => {
          headerElement.style.transition = null;
        });
      }
    }
  };

  // Handle table scroll
  const handleBodyScroll = ({ left, direction }) => {
    if (direction === 'vertical') {
      selectedPinnedColumns.forEach(({ id, breakLine, stickyWidth, isLeftPin }) => {
        if (left > breakLine && ((ff.integrate_api_sos && !isLeftPin) || !ff.integrate_api_sos))
          setStickyColumn(id, stickyWidth);
      });
    }
  };

  // Set style for sticky column when table view change
  const restyleStickyColumn = (tableDOM) => {
    if (tableDOM.current) {
      const { scrollLeft } = tableDOM.current.querySelector('.ag-body-horizontal-scroll-viewport');
      const filteredSelectedPinnedColumns = selectedPinnedColumns.filter(({ isLeftPin }) => !isLeftPin);
      filteredSelectedPinnedColumns.forEach(({ id, stickyWidth, width, breakLine, left }, colIndex) => {
        if (scrollLeft > left + width - stickyWidth) {
          if (colIndex === 0) {
            const index = columnApi.getAllGridColumns().findIndex((el) => el.left > scrollLeft);
            const columns = filteredSelectedPinnedColumns.filter((selectedPinnedColumn) => {
              return (
                scrollLeft > selectedPinnedColumn.left + selectedPinnedColumn.width - selectedPinnedColumn.stickyWidth
              );
            });
            const columnIds = columns.map((column) => column.id);
            columnApi.moveColumns(columnIds, index - 1);
            columns.forEach((selectedPinnedColumn) => {
              setStickyColumn(selectedPinnedColumn.id, selectedPinnedColumn.stickyWidth);
            });
          }
        } else if (scrollLeft > breakLine) {
          setStickyColumn(id, stickyWidth);
        }
        setTimeout(() => {
          if (scrollLeft > breakLine) {
            setStickyColumnHeader(id, stickyWidth + scrollLeft);
          }
        }, 0);
      });
      // Style for Pin left column
      const leftSelectedPinnedColumns = selectedPinnedColumns.filter(({ isLeftPin }) => isLeftPin);
      leftSelectedPinnedColumns.forEach(({ id }) => {
        // Remove style for pin left column
        const pinnedColumnLeft = columnApi.getColumn(id).left;
        unsetStickyColumn(id, pinnedColumnLeft);
        unsetStickyColumnHeader(id, pinnedColumnLeft);
      });
    }
  };

  // Set style for header sticky column when column move
  const restyleHeaderStickyColumn = (tableDOM) => {
    const { scrollLeft } = tableDOM.current.querySelector('.ag-body-horizontal-scroll-viewport');
    const filteredSelectedPinnedColumns = selectedPinnedColumns.filter(({ isLeftPin }) => !isLeftPin);
    filteredSelectedPinnedColumns.forEach(({ id, stickyWidth, breakLine }) => {
      if (scrollLeft > breakLine) {
        setStickyColumnHeader(id, stickyWidth + scrollLeft);
      }
    });
  };

  const handleColumnResized = (params) => {
    if (params.finished) {
      const { column } = params;
      setSelectedPinnedColumns(
        selectedPinnedColumns.map((item) => {
          const { left } = columnApi.getColumn(item.id);
          if (item.left > column.left) {
            return {
              ...item,
              left,
            };
          }
          return item;
        }),
      );
    }
  };

  return {
    handleBodyScroll,
    setColumnApi,
    tableDOM,
    restyleStickyColumn,
    restyleHeaderStickyColumn,
    handleColumnResized,
  };
};
