import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Italic = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="3 3 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="10" y="11" width="2" height="6" rx="1" transform="rotate(90 10 11)" />
    <rect x="12" y="3" width="2" height="6" rx="1" transform="rotate(90 12 3)" />
    <path d="M8 11L6 11L8 5L10 5L8 11Z" />
  </SvgIcon>
);

export default Italic;
