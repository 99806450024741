import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, FormHelperText } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Error from '@ep/insight-ui/icons/svg/Error';
import clsx from 'clsx';

type PropsStyle = {
  inputWidth: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  container: {
    '& .eip1-MuiAutocomplete-listbox': {
      padding: '0px !important',
    },
    width: (props: PropsStyle) => props.inputWidth || 'auto',
    '& .eip1-MuiAutocomplete-popupIndicator': {
      marginRight: '5px',
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        color: colors.icon.default,
      },
    },
    '& .eip1-MuiInputBase-root.Mui-disabled': {
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.highlight,
    },
    '& .eip1-MuiInputBase-root.Mui-disabled .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.subdued,
    },
  },
  paper: {
    boxShadow: colors.shadow.hover,
  },
  formHelper: {
    marginRight: 0,
    marginLeft: 0,
    fontSize: '10px',
    color: '#D4290D',
    '& svg': {
      width: '9px',
      height: '9px',
      marginRight: '5px',
    },
  },
  error: {
    '& .eip1-MuiOutlinedInput-root .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.critical,
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.critical,
    },
    '& .eip1-MuiInputBase-root.Mui-disabled .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.subdued,
    },
  },
  input: {
    '&:hover': {
      backgroundColor: '#EBF6FF',
      color: '#0369C7',
    },
  },
  option: {
    backgroundColor: 'transparent',
    borderRadius: '4px',
    '&[aria-selected="true"]': {
      backgroundColor: '#EBF6FF',
      color: '#0369C7',
    },
    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
  },
}));

interface SelectFormProps {
  options: OptionSelectType[];
  value: string;
  disabled?: boolean;
  onChange?: (v: string) => void;
  inputWidth?: string;
  placeholder?: string;
  className?: string;
  helperText?: string;
  error?: boolean;
}

const SelectSearchForm = ({
  options,
  value,
  onChange,
  disabled,
  inputWidth,
  placeholder,
  className,
  helperText,
  error,
}: SelectFormProps) => {
  const classes = useStyles({ inputWidth });
  return (
    <FormControl>
      <Autocomplete
        multiple={false}
        disableClearable
        // disableCloseOnSelect
        disabled={disabled}
        classes={{
          paper: classes.paper,
          option: classes.option,
        }}
        options={options}
        autoHighlight
        value={options.find((item) => item.value === value)}
        onChange={(event: any, newValue: OptionSelectType | null) => {
          onChange(newValue.value);
        }}
        className={clsx(classes.container, className, error && classes.error)}
        getOptionLabel={(option) => option.label}
        renderOption={(option) => <React.Fragment>{option.label}</React.Fragment>}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            variant="outlined"
            inputProps={{
              ...params.inputProps,
            }}
            InputProps={{
              ...params.InputProps,
            }}
          />
        )}
        popupIcon={<Icon type="chevronMedium" size="10px" />}
      />
      {error && !disabled && (
        <FormHelperText className={`${classes.formHelper}`}>
          <Error />
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default SelectSearchForm;
