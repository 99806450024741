import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const NoResult = (props: SvgIconProps) => (
  <SvgIcon {...props} width="104" height="104" viewBox="0 0 104 104" fill="none">
    <g clipPath="url(#clip0)">
      <path
        d="M71.6301 43.8735C71.4189 43.8735 71.2077 43.8146 71.0083 43.7085C70.4217 43.3667 70.2223 42.6006 70.5625 42.0113L76.147 32.4054C76.3699 32.0282 76.7688 31.7925 77.2146 31.7925H81.5203C82.2008 31.7925 82.7522 32.3464 82.7522 33.0301C82.7522 33.7137 82.2008 34.2676 81.5203 34.2676H77.9185L72.686 43.2606C72.4631 43.6496 72.0525 43.8735 71.6301 43.8735Z"
        fill="#C2C7CB"
      />
      <path
        d="M25.124 43.8735C24.7017 43.8735 24.2911 43.6496 24.0564 43.2606L18.8239 34.2676H15.2221C14.5416 34.2676 13.9902 33.7137 13.9902 33.0301C13.9902 32.3464 14.5416 31.7925 15.2221 31.7925H19.5278C19.9619 31.7925 20.3725 32.0282 20.5954 32.4054L26.1799 42.0113C26.5202 42.6006 26.3207 43.3549 25.7341 43.7085C25.5464 43.8146 25.3352 43.8735 25.124 43.8735Z"
        fill="#C2C7CB"
      />
      <path
        d="M22.5195 66.621H5.10905C5.01519 67.1396 4.96826 67.67 4.96826 68.2122C4.96826 73.0799 8.89853 77.0401 13.7439 77.0401H29.9225V66.4678L22.5195 66.621Z"
        fill="#D3D7DA"
      />
      <path
        d="M72.6744 8.39611L72.0878 68.212C72.0878 73.0797 68.1575 77.04 63.3004 77.04H15.2222C20.0675 77.04 24.0095 73.0915 24.0095 68.212L24.5961 8.39611C24.5961 6.82852 25.8515 5.56738 27.4119 5.56738H69.8587C71.4191 5.56738 72.6744 6.82852 72.6744 8.39611Z"
        fill="#F6F7F8"
      />
      <path
        d="M88.7827 80.8119C88.0084 80.1755 87.0698 79.7747 86.0843 79.6451C86.0491 79.6451 86.0022 79.6333 85.967 79.6333C85.3804 80.6234 84.7116 81.578 83.9725 82.4738C83.2216 83.3931 82.4121 84.23 81.5557 85.0079C81.2389 84.1003 80.6758 83.2989 79.9366 82.686L79.397 82.2381L79.9366 82.686C80.711 83.3224 81.2858 84.171 81.5909 85.1257C81.9077 86.0804 82.4825 86.9173 83.2451 87.5655L95.5052 97.69C97.3589 99.2222 100.104 98.9511 101.629 97.0889C103.155 95.2266 102.885 92.4686 101.031 90.9364L88.7827 80.8119Z"
        fill="#E4E7E9"
      />
      <path
        d="M80.9104 80.5643C80.4294 81.1536 79.9249 81.7075 79.397 82.2379L79.9366 82.6858C80.6758 83.2987 81.2272 84.1002 81.5557 85.0077C82.4239 84.2298 83.2217 83.393 83.9725 82.4737C84.7116 81.5779 85.3804 80.6232 85.967 79.6331V79.6213C85.0167 79.4799 84.125 79.091 83.3859 78.4781L82.7524 77.9595C82.2127 78.8552 81.5909 79.7274 80.9104 80.5643Z"
        fill="#C2C7CB"
      />
      <path
        d="M61.447 42.9894C48.7645 44.0149 39.3202 55.1883 40.3526 67.9176C41.3733 80.6587 52.4954 90.1467 65.1661 89.1095C70.8444 88.6499 75.8658 86.1511 79.5966 82.4031C80.8754 81.1066 82.0134 79.6686 82.9637 78.1128C85.4157 74.0819 86.6711 69.2613 86.2605 64.1931C85.2398 51.4521 74.1294 41.964 61.447 42.9894ZM77.1915 77.9125C74.0356 81.637 69.636 83.9117 64.7789 84.3007C59.9218 84.6896 55.2172 83.1692 51.5099 79.9987C47.8025 76.8281 45.5382 72.4082 45.151 67.5287C44.7639 62.6491 46.2773 57.9228 49.4333 54.1983C52.5892 50.4738 56.9888 48.199 61.8459 47.8101C66.703 47.4211 71.4076 48.9416 75.1149 52.1121C78.8223 55.2826 81.0866 59.7025 81.4737 64.5821C81.8609 69.4616 80.3357 74.188 77.1915 77.9125Z"
        fill="#D3D7DA"
      />
      <path
        d="M81.4739 64.5822C81.0867 59.7026 78.8224 55.2827 75.1151 52.1122C71.4077 48.9417 66.6914 47.4212 61.846 47.8102C57.0007 48.2109 52.5894 50.4739 49.4334 54.1984C46.2775 57.9229 44.7641 62.661 45.1512 67.5406C45.5384 72.4083 47.8027 76.84 51.51 80.0105C55.2174 83.1811 59.9337 84.7015 64.7791 84.3126C69.6362 83.9118 74.0357 81.6488 77.1917 77.9244C80.3359 74.1881 81.8611 69.4617 81.4739 64.5822ZM69.8122 71.6186C69.4015 72.1019 68.8384 72.373 68.2635 72.4201C67.6886 72.4673 67.0786 72.2905 66.6093 71.8779L63.5707 69.2849L60.9896 72.3376C60.1801 73.3041 58.737 73.422 57.775 72.5969C56.813 71.7719 56.6956 70.3339 57.5169 69.3674L60.098 66.3148L57.0593 63.7218C56.0973 62.8967 55.98 61.4588 56.8012 60.4923C57.6225 59.5258 59.0538 59.408 60.0158 60.233L63.0545 62.826L65.6355 59.7733C66.445 58.8069 67.8881 58.689 68.8501 59.514C69.3311 59.9266 69.601 60.4923 69.6479 61.0698C69.6948 61.6474 69.5188 62.2603 69.12 62.7317L66.5389 65.7844L69.5775 68.3774C70.5161 69.2142 70.6334 70.6522 69.8122 71.6186Z"
        fill="white"
      />
      <path
        d="M69.8119 71.6186C69.4013 72.1018 68.8381 72.3729 68.2633 72.4201C67.6884 72.4672 67.0783 72.2904 66.609 71.8779L63.5704 69.2849L60.9893 72.3375C60.1798 73.304 58.7368 73.4219 57.7747 72.5969C56.8127 71.7718 56.6954 70.3339 57.5166 69.3674L60.0977 66.3147L57.0591 63.7217C56.0971 62.8967 55.9797 61.4587 56.801 60.4922C57.6222 59.5257 59.0535 59.4079 60.0156 60.2329L63.0542 62.8259L65.6353 59.7733C66.4448 58.8068 67.8878 58.6889 68.8499 59.514C69.3309 59.9265 69.6007 60.4922 69.6476 61.0698C69.6946 61.6473 69.5186 62.2602 69.1197 62.7316L66.5386 65.7843L69.5773 68.3773C70.5158 69.2142 70.6331 70.6521 69.8119 71.6186Z"
        fill="white"
      />
      <path
        d="M69.2958 72.1844C68.85 72.6323 68.2634 72.8445 67.6768 72.8445C67.0902 72.8445 66.5153 72.6087 66.0695 72.1608L63.2773 69.2968L60.4381 72.1019C59.5464 72.9859 58.1034 72.9741 57.2117 72.0783C56.3318 71.1708 56.3436 69.7211 57.2352 68.8371L60.0744 66.0319L57.2821 63.1796C56.4022 62.2839 56.414 60.8224 57.3056 59.9384C58.209 59.0544 59.652 59.0662 60.5319 59.962L63.3242 62.8143L66.1634 60.0091C67.0667 59.1251 68.5098 59.1369 69.3897 60.0327C69.8355 60.4806 70.0467 61.0699 70.0467 61.6592C70.0467 62.2485 69.8121 62.8261 69.3662 63.2739L66.5271 66.0909L69.3193 68.9432C70.1992 69.8507 70.1875 71.3004 69.2958 72.1844Z"
        fill="#D3D7DA"
      />
      <path
        d="M31.6586 26.2293C31.4709 26.2293 31.2949 26.2175 31.1072 26.2057C30.591 26.1703 30.2038 25.7107 30.239 25.1921C30.2742 24.6735 30.7318 24.2845 31.248 24.3199C35.3425 24.6381 37.0085 19.9825 37.0202 19.9353C37.1962 19.4403 37.7241 19.181 38.2169 19.3578C38.7096 19.5346 38.9677 20.065 38.7918 20.56C38.7683 20.6189 38.2521 22.0805 37.0789 23.4948C35.6123 25.2981 33.7469 26.2293 31.6586 26.2293Z"
        fill="#C2C7CB"
      />
      <path
        d="M66.6557 26.2294C64.5674 26.2294 62.702 25.2983 61.2238 23.5068C60.0505 22.0924 59.5343 20.6309 59.5109 20.572C59.3349 20.0769 59.593 19.5466 60.0857 19.3698C60.5785 19.2047 61.1064 19.4523 61.2824 19.9473C61.3528 20.1359 62.9836 24.6383 67.0546 24.3318C67.5708 24.2965 68.0284 24.6854 68.0636 25.204C68.0988 25.7226 67.7116 26.1823 67.1954 26.2176C67.0194 26.2176 66.8317 26.2294 66.6557 26.2294Z"
        fill="#C2C7CB"
      />
      <path
        d="M37.9357 30.6843C38.9595 30.6843 39.7894 29.4759 39.7894 27.9852C39.7894 26.4945 38.9595 25.2861 37.9357 25.2861C36.9119 25.2861 36.082 26.4945 36.082 27.9852C36.082 29.4759 36.9119 30.6843 37.9357 30.6843Z"
        fill="#C2C7CB"
      />
      <path
        d="M60.3791 30.6843C61.4028 30.6843 62.2327 29.4759 62.2327 27.9852C62.2327 26.4945 61.4028 25.2861 60.3791 25.2861C59.3553 25.2861 58.5254 26.4945 58.5254 27.9852C58.5254 29.4759 59.3553 30.6843 60.3791 30.6843Z"
        fill="#C2C7CB"
      />
      <path
        d="M71.3367 94.6372H23.1176C22.4372 94.6372 21.8857 94.0833 21.8857 93.3997C21.8857 92.7161 22.4372 92.1621 23.1176 92.1621H71.3367C72.0172 92.1621 72.5686 92.7161 72.5686 93.3997C72.5686 94.0833 72.0172 94.6372 71.3367 94.6372Z"
        fill="#C2C7CB"
      />
      <path
        d="M63.8866 100H30.5673C29.8869 100 29.3354 99.4461 29.3354 98.7625C29.3354 98.0789 29.8869 97.5249 30.5673 97.5249H63.8866C64.567 97.5249 65.1185 98.0789 65.1185 98.7625C65.1185 99.4461 64.567 100 63.8866 100Z"
        fill="#C2C7CB"
      />
      <path
        d="M88.1842 4.17681C90.12 4.68362 91.5866 6.12156 91.0586 8.1606C90.6832 9.61033 89.6625 10.0582 88.7122 10.4236C87.5507 10.8833 87.1166 11.0483 86.882 11.9676C86.8233 12.2151 86.5652 12.3566 86.3306 12.2976L85.216 12.003C84.9696 11.944 84.8288 11.6965 84.8875 11.449L84.911 11.3783C85.1573 10.1054 85.9903 9.46889 87.0931 9.06815C88.02 8.71456 88.6183 8.47884 88.8178 7.68915C89.0759 6.69909 88.4306 6.0862 87.6211 5.87405C86.8468 5.67368 86.2367 5.93298 85.873 6.46337C85.6266 6.82874 85.1691 6.99375 84.735 6.88768C84.0076 6.69909 83.7026 5.83869 84.1366 5.23758C85.0635 3.96466 86.7177 3.79965 88.1842 4.17681ZM85.5328 13.3348C86.4361 13.5705 86.882 14.2424 86.6825 15.0203C86.4831 15.7746 85.7674 16.1518 84.864 15.9278C84.0076 15.7039 83.55 15.0203 83.7495 14.2659C83.9489 13.488 84.6646 13.1109 85.5328 13.3348Z"
        fill="#C2C7CB"
      />
      <path
        d="M3.77161 48.8237C5.36718 47.5979 7.39684 47.3386 8.66391 49.0123C9.56728 50.2027 9.28571 51.2871 8.99241 52.2654C8.64044 53.4676 8.49966 53.9154 9.07453 54.6698C9.22705 54.8701 9.19185 55.153 8.99241 55.3062L8.0773 56.0134C7.87785 56.1666 7.59628 56.1313 7.44377 55.9309L7.39684 55.872C6.54039 54.8937 6.56386 53.8447 6.92755 52.725C7.22085 51.7821 7.40857 51.1574 6.91582 50.5092C6.29402 49.6959 5.42584 49.8256 4.75711 50.3324C4.12357 50.8274 3.94759 51.4639 4.14704 52.0768C4.27609 52.5011 4.12357 52.9608 3.77161 53.2318C3.17327 53.6915 2.31682 53.3968 2.10564 52.6779C1.67155 51.1574 2.5632 49.7431 3.77161 48.8237ZM9.30917 56.5674C10.0483 56.0016 10.8578 56.0606 11.3388 56.7088C11.8081 57.3217 11.6673 58.135 10.9282 58.7007C10.2243 59.2429 9.40303 59.1722 8.93375 58.5593C8.45273 57.911 8.60525 57.1096 9.30917 56.5674Z"
        fill="#C2C7CB"
      />
    </g>
    <defs>
      <clipPath className="clip0">
        <rect width="100.627" height="96" fill="white" transform="translate(2 4)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default NoResult;
