import * as React from 'react';
import * as _ from 'lodash';
import { funcConfigs } from './hooks';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { IData, IOption } from '../type';
import { Pied } from './pie-chart-template';
import { makeStyles } from '@material-ui/core';
import { responsiveChart } from '@ep/insight-ui/lib/epsilo-theme';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../chart-size';
import { breakPointSize } from '../chart-size';
import ChartPluginDatalabels from 'chartjs-plugin-datalabels';
import { getComponent } from '@ep/insight-ui/system/backbone/atom/app';
import { useAtomValue } from 'jotai';

const useStyles = makeStyles({
  ratio_wrap: responsiveChart.ratio_wrap as CreateCSSProperties,
  ratio_item: responsiveChart.ratio_item as CreateCSSProperties,
  wrapper: {
    width: '100%',
    height: '100%',
  },
  chart: {
    width: '100%',
    height: '100%',
    flex: '1 0 0',
  },
  wrapperRes: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
    '& ul.legendDiv': {
      display: 'flex',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      overflowX: 'auto',
      whiteSpace: 'nowrap',
      width: 'auto',
      height: '30px',
      '& li.legendItem': {
        margin: 0,
        marginRight: 0,
        fontSize: '12px',
      },
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
    position: 'relative',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
  crisper: {
    position: 'absolute',
  },
});

type IPieChart = {
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: IData;
  optionList: IOption;
  stateChart: IStateChartValue;
  isHidePercent?: boolean;
  isHideTotal?: boolean;
  colKey?: string;
  subTitle?: string;
};
const PieChart = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  stateChart,
  isHidePercent = false,
  isHideTotal = false,
  colKey = '',
  subTitle = '',
}: IPieChart) => {
  const divRef = React.useRef<HTMLDivElement>();
  const [chartSize, setchartSize] = React.useState<TSize>('large');

  const [width, setWidth] = React.useState(window.innerWidth);
  const classes = useStyles();

  const ref = React.createRef();
  const idLegend = React.useMemo(() => `pieChartLegend_${keyChart}`, [keyChart]);
  const { htmlLegendPlugin } = funcConfigs({
    percent: '15%',
    width,
    legendDivID: idLegend,
  });

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (optionList) {
        if (
          divRef.current.offsetWidth < breakPointSize.width.medium ||
          divRef.current.offsetHeight < breakPointSize.height.medium
        )
          return setchartSize('small');
        if (
          divRef.current.offsetWidth < breakPointSize.width.large ||
          divRef.current.offsetHeight < breakPointSize.height.large
        )
          return setchartSize('medium');

        setchartSize('large');
      }
    });
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [ref]);

  const ChartStateDerived = useAtomValue(React.useRef(getComponent(ChartState)).current);

  return (
    <div ref={divRef} className={classes.wrapper} style={{ display: 'flex', flexDirection: 'column' }}>
      <ChartSize size={chartSize}>
        <ChartStateDerived
          stateChart={stateChart}
          title={title}
          value={value}
          currency={currency}
          percent={percent}
          titleBetween
          isHidePercent={isHidePercent}
          isHideTotal={isHideTotal}
          colKey={colKey}
          subTitle={subTitle}
        >
          <div className={classes.wrapperRes}>
            <div className={clsx(classes.chart, 'chart')}>
              {dataList && (
                <Pied
                  data={dataList}
                  options={optionList}
                  plugins={[htmlLegendPlugin, ChartPluginDatalabels]}
                  refElement={ref}
                />
              )}
            </div>
            <div
              className={classes.wrapperLegendLine}
              style={{
                display: chartSize === 'large' ? 'flex' : 'block',
              }}
            >
              <div className={classes.legendDiv} id={idLegend}></div>
            </div>
          </div>
        </ChartStateDerived>
      </ChartSize>
    </div>
  );
};

export default PieChart;
