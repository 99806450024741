import * as React from 'react';
import { cloneDeep, get, isString, set, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import color from 'color';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import LineChartResponsive from './line-chart';
import { funcConfigs } from './hooks';
import { colorsChartLegend, convertColor, defaultOptions, tool } from '../../helper';
import { IDataChart } from '../../type';
import ChartState, { IStateChartValue } from '../../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../../chart-size';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';

type Props = {
  data: IDataChart;
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  cohortValue?: number;
  percent?: number;
  currency?: string;
  colorOption?: Array<string>;
  isSettingYAxis?: boolean;
  isSingleChart?: boolean;
  isHideTotal?: boolean;
  isHidePercent?: boolean;
  groupPeriod?: string;
  showLegend?: boolean;
  lineTension?: number;
  valueDisplay?: 'multiple' | 'single';
  groupBy?: 'dimension' | 'metric';
  isMixed?: boolean;
  rawTotal?: number;
  rawCohortTotal?: number;
  isColumnStacked?: boolean;
  colKey?: string;
  subTitle?: string;
};
const LineChart = ({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  cohortValue = null,
  percent = 0,
  currency = '',
  colorOption = [],
  isSettingYAxis = false,
  isSingleChart = false,
  isHideTotal = false,
  isHidePercent = false,
  groupPeriod,
  showLegend = false,
  lineTension = 0,
  valueDisplay,
  groupBy = 'dimension',
  isMixed = false,
  rawTotal,
  rawCohortTotal,
  isColumnStacked = false,
  colKey = '',
  subTitle = '',
}: Props) => {
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const mapping = backboneContext.getConfig('mapping');
  const periodBackbone = backboneContext.getConfig('groupPeriod', '');
  const [dataChart, setDataChart] = React.useState<{ dataList: any; optionList: any }>({
    dataList: null,
    optionList: null,
  });
  const { externalTooltipHandler } = funcConfigs();
  const useDimensionXAxis =
    get(backboneContext.getConfig('chartConfig', {}), ['config', 'useDimensionXAxis'], 'no') === 'yes';

  const groupDimension = (backboneContext.getConfig('groupBy', { columns: [] }).columns || [])[0];
  const showLabelOnTop =
    get(backboneContext.getConfig('chartConfig', {}), ['config', 'showLabelOnTop'], 'no') === 'yes';
  const isoWeekday = get(backboneContext.getConfig('chartConfig', {}), ['config', 'isoWeekday'], 'Friday');

  const formatTime = React.useMemo(() => {
    switch (periodBackbone) {
      case 'hourly':
        return 'HH:mm';
      case 'daily':
        return 'MMM DD';
      case 'weekly':
        return '[W]W GGGG';
      case 'monthly':
        return 'MMM YYYY';
      case 'quarterly':
        return '[Q]Q YYYY';
      case 'yearly':
        return '[Y]YYYY';
      default:
        return 'MMM DD';
    }
  }, [periodBackbone]);

  const formatHeaders = () => {
    const headers = data.headers;
    if (!periodBackbone || (useDimensionXAxis && groupDimension)) return headers;
    if (periodBackbone === 'weekly') {
      return headers.map((header) => moment(header).isoWeekday(isoWeekday).format('[W]W, MMM DD YYYY'));
    }
    return headers.map((header) => moment(header).format(formatTime));
  };

  React.useEffect(() => {
    const isCohort = data.rows.some((el) => el.isCohort);
    const headers = formatHeaders();
    let colorIndex = 0;
    const colors = data.rows.reduce((carry, item, index) => {
      const chartColorConfig = get(mapping, [item.key, 'staticValue', 'color'], null);
      let color, hoverBorderColor;

      if (item.isCohort) {
        color = '#D4DDED';
        hoverBorderColor = convertColor('#D4DDED');
      } else if (chartColorConfig) {
        color = chartColorConfig;
        hoverBorderColor = convertColor(chartColorConfig);
      } else if (colorOption?.[colorIndex]) {
        color = colorOption[colorIndex];
        hoverBorderColor = convertColor(colorOption[colorIndex]);
        colorIndex++;
      } else {
        color = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].stroke;
        hoverBorderColor = colorsChartLegend[colorIndex % Object.keys(colorsChartLegend).length].pointBorderColor;
        colorIndex++;
      }

      return {
        ...carry,
        [`${item.key}_${index}`]: {
          color,
          hoverBorderColor,
        },
      };
    }, {});

    const lastBarIndex = Math.max(
      ...data.rows.map((item, index) => {
        const chartStyle = isMixed ? get(mapping, [item.key, 'staticValue', 'chartStyle'], null) : null;
        return chartStyle ? index : null;
      }),
    );

    const datasets = data.rows.map((item, index) => {
      const isDisplayChart = !item.hasOwnProperty('displayChart') || item.displayChart;
      const chartStyle = !isDisplayChart
        ? 'line'
        : item.chartStyle
        ? item.chartStyle
        : isMixed
        ? get(mapping, [item.key, 'staticValue', 'chartStyle'], null)
        : null;
      const chartColorConfig = get(mapping, [item.key, 'staticValue', 'color'], null);
      let borderDash = get(mapping, [item.key, 'staticValue', 'value.lineDash'], undefined);
      if (borderDash && borderDash != '' && isString(borderDash)) {
        borderDash = borderDash.split(',').map((i) => Number(i));
      } else {
        borderDash = undefined;
      }
      let chartYAxis = get(mapping, [item.key, 'valueGetter', 'chart.yAxis'], '');
      if (!chartYAxis || !chartYAxis.startsWith('=')) {
        chartYAxis = `y_${item.key}`;
      } else {
        try {
          chartYAxis = `y_${toValue(chartYAxis, {})}`;
        } catch (e) {
          chartYAxis = `y_${item.key}`;
        }
      }

      const keyColor = `${item.key}_${index}`;

      const chartColor = item.chartColor && !item.isCohort ? item.chartColor : get(colors, [keyColor, 'color'], '');
      const hoverBorderColor = get(colors, [keyColor, 'hoverBorderColor'], '');

      return {
        dateList: item.data.map((it) => it?.datetime),
        label: item.label,
        key: item.key,
        formatLabel: item.formatLabel,
        formatTopLabel: item.formatTopLabel,
        currency: item.currency,
        axisData: data.headers,
        data: item.data.map((it) => it?.value),
        dataLabel: item.data.map((it) => it?.label),
        borderDash: item.lineDash ? item.lineDash || borderDash : borderDash,
        borderColor: chartColor,
        pointRadius: 16,
        pointBorderWidth: 16,
        pointHoverRadius: isDisplayChart ? 8 : 0,
        pointHoverBorderWidth: 16,
        pointBackgroundColor: 'transparent',
        pointHoverBackgroundColor: chartColor,
        pointBorderColor: 'transparent',
        pointHoverBorderColor: hoverBorderColor,
        lineTension: item.lineTension ? item.lineTension || lineTension : lineTension,
        ...((!isColumnStacked || (isColumnStacked && chartStyle !== 'bar')) &&
        isSettingYAxis &&
        ff.reuse_our_chart &&
        ((valueDisplay === 'single' && groupBy === 'dimension') || valueDisplay === 'multiple' || item['yAxisID'])
          ? {
              yAxisID: item['yAxisID'] || chartYAxis,
            }
          : {}),
        ...(chartStyle ? { type: chartStyle === 'area' ? 'line' : chartStyle } : {}),
        ...(chartStyle === 'area'
          ? { fill: 'start', backgroundColor: color(chartColor).fade(0.3).rgb().string() }
          : {}),
        ...(chartStyle === 'bar' ? { backgroundColor: chartColor } : {}),
        order:
          valueDisplay == 'single' && groupBy == 'metric'
            ? item.isCohort
              ? 9999
              : item.order
              ? item.order
              : index
            : index,
        isCohort: item.isCohort,
        chartStyle,
        chartColorConfig,
        ...(!isColumnStacked || (chartStyle === 'bar' && index === lastBarIndex)
          ? { borderRadius: { topLeft: 4, topRight: 4 } }
          : {}),
        config: item.config,
        ...(!isDisplayChart ? { borderWidth: 0 } : {}),
      };
    });

    // Update datasets for formula colors
    const formulaValues = datasets.reduce((a, b) => {
      return {
        ...a,
        [b.key]: b.data,
      };
    }, {});
    datasets.forEach((item, index) => {
      const keyColor = `${item.key}_${index}`;
      const formulaColor = get(mapping, [item.key, 'valueGetter', 'chartColor'], '');
      let backgroundColor, hoverBackgroundColor;
      if (formulaColor && formulaColor.startsWith('=') && item.chartStyle === 'bar' && !item.isCohort) {
        backgroundColor = item.data.map((dt, i) => {
          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
            return {
              ...carry,
              [key]: value[i],
            };
          }, {});

          return (
            toValue(formulaColor, {
              value: dt,
              legend: item.label,
              ...formulaValue,
            }) || colors[keyColor].color
          );
        });
        hoverBackgroundColor = item.data.map((dt, i) => {
          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
            return {
              ...carry,
              [key]: value[i],
            };
          }, {});
          return (
            convertColor(
              toValue(formulaColor, {
                value: dt,
                legend: item.label,
                ...formulaValue,
              }),
            ) || colors[keyColor].hoverBorderColor
          );
        });
      }

      if (backgroundColor) {
        item.pointHoverBackgroundColor = backgroundColor;
        item.backgroundColor = backgroundColor;
        item.borderColor = uniq(backgroundColor);
      }
      if (hoverBackgroundColor) {
        item.pointHoverBorderColor = hoverBackgroundColor;
      }
    });

    const dataList = {
      labels: headers,
      datasets,
    };
    //Display option chart
    const { LINE: optionList } = cloneDeep(defaultOptions);
    set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    set(optionList, 'plugins.tooltip.callbacks.label', tool.lineCallbacks.label({ isCohort, formatTime }));
    set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    set(optionList, 'period', periodBackbone);

    if (maxValues) {
      set(optionList, 'scales.yAxes.suggestedMax', maxValues);
    }

    const uniqYAxisId = uniqBy(datasets, 'yAxisID');

    const displayYAxisList = uniqBy(datasets, 'key').reduce((a, b) => {
      const isDisplay = get(mapping, [b.key, 'valueGetter', 'chart.displayYAxis'], '').toLowerCase() === '=true';
      if (!isDisplay || a.includes(b.yAxisID)) return a;
      return [...a, b.yAxisID];
    }, []);

    const rangeYAxisList = uniqBy(datasets, 'key').reduce((a, b) => {
      let maxYAxis, minYAxis;
      try {
        maxYAxis = toValue(get(mapping, [b.key, 'valueGetter', 'chart.rangeMax']), {});
        minYAxis = toValue(get(mapping, [b.key, 'valueGetter', 'chart.rangeMin']), {});
      } catch {}

      return {
        ...a,
        [b.yAxisID]: {
          maxYAxis:
            Math.max(a[b.yAxisID]?.maxYAxis || -999999999999999, maxYAxis) || a[b.yAxisID]?.maxYAxis || maxYAxis,
          minYAxis: Math.min(a[b.yAxisID]?.minYAxis || 999999999999999, minYAxis) || a[b.yAxisID]?.minYAxis || minYAxis,
        },
      };
    }, {});

    if (showLabelOnTop) {
      set(optionList, 'plugins.datalabels', {
        display: (props) => {
          return 'auto';
        },
        color: 'black',
        formatter: (rawValue, props) => {
          const key = get(props, ['dataset', 'key'], '');
          const order = get(props, ['dataset', 'order'], '');
          const chartStyle = get(props, ['dataset', 'chartStyle'], '');

          let value;
          const dts = datasets.filter((dt) => dt.key == key);
          const datasetsHasValue = dts.filter((dt) => get(dt, ['data', props.dataIndex], null) != null);
          const mappedDataset = dts.map((dt) => get(dt, ['data', props.dataIndex], null)).filter((dt) => dt != null);
          const total = mappedDataset.reduce((a, b) => a + b, 0);
          const max = Math.max(...mappedDataset);
          const min = Math.min(...mappedDataset);
          if (isColumnStacked && chartStyle === 'bar') {
            value =
              order == (datasetsHasValue.length > 0 ? datasetsHasValue[datasetsHasValue.length - 1]?.order : 0)
                ? dts.reduce((carry, dt) => {
                    const dtValue = get(dt, ['data', props.dataIndex], null);
                    if (carry == null && dtValue == null) return null;
                    return (carry || 0) + (dtValue || 0);
                  }, null)
                : null;
          } else {
            value = Array.isArray(rawValue) ? rawValue[1] - rawValue[0] : rawValue;
          }
          if (value == null) return null;
          const format = props.dataset.formatTopLabel || props.dataset.formatLabel;
          const label = format
            ? toValue(format, {
                value,
                currency: props.dataset.currency,
                total,
                max,
                min,
                list: mappedDataset,
              })
            : value;
          return label;
        },
        anchor: 'end',
        offset: -20,
        align: 'start',
      });
      set(optionList, 'layout.padding.top', 20);
    }

    datasets.forEach((item) => {
      if (item.yAxisID && valueDisplay == 'single' && groupBy == 'metric') {
        set(optionList, `scales.${item.yAxisID}`, {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
          beginAtZero: true,
          axis: 'y',
        });

        return;
      }
      // Only show yAxis if datasets has 1 yAxisID value, valueDisplay is multiple and chart.displayYAxis is =true
      const shoudShowYAxis =
        uniqYAxisId.length === 1 && valueDisplay === 'multiple' && displayYAxisList.includes(item.yAxisID);
      if (!shoudShowYAxis) {
        set(optionList, `scales.${item.yAxisID}`, cloneDeep(defaultOptions).LINE.scales.yAxes);
        set(optionList, `scales.${item.yAxisID}.display`, false);
        set(optionList, `scales.${item.yAxisID}.ticks.callback`, tool.callbacks.callback);
        const maxYAxis = toValue(get(mapping, [item.key, 'valueGetter', 'chart.rangeMax']), {});
        const minYAxis = toValue(get(mapping, [item.key, 'valueGetter', 'chart.rangeMin']), {});
        if (maxYAxis) {
          set(optionList, `scales.yAxes.max`, maxYAxis);
          set(optionList, `scales.${item.yAxisID}.max`, maxYAxis);
        }
        if (minYAxis) {
          set(optionList, `scales.yAxes.min`, minYAxis);
          set(optionList, `scales.${item.yAxisID}.min`, minYAxis);
        }
      } else {
        const maxYAxis = get(rangeYAxisList, [item.yAxisID, 'maxYAxis']);
        const minYAxis = get(rangeYAxisList, [item.yAxisID, 'minYAxis']);
        if (maxYAxis) {
          set(optionList, `scales.${item.yAxisID}.max`, maxYAxis);
        }
        if (minYAxis) {
          set(optionList, `scales.${item.yAxisID}.min`, minYAxis);
        }
      }
      if (isColumnStacked && item.chartStyle === 'bar') {
        set(optionList, ['scales', item.yAxisID, 'stacked'], isColumnStacked);
      }
    });
    set(optionList, 'scales.x.stacked', isColumnStacked);
    set(optionList, 'scales.yAxes.stacked', isColumnStacked);
    // if (dataList && isSettingYAxis && isSingleChart && ff.reuse_our_chart) {
    //   _.set(optionList, 'scales', settingYAxis(datasets));
    // }
    // settingYAxis function always return undefined
    setDataChart({ dataList, optionList });
  }, [periodBackbone, isColumnStacked]);

  return (
    <LineChartResponsive
      keyChart={keyChart}
      stateChart={stateChart}
      dataList={dataChart.dataList}
      optionList={dataChart.optionList}
      title={title}
      value={value}
      cohortValue={cohortValue}
      currency={currency}
      percent={percent}
      type="normal"
      isSettingYAxis={isSettingYAxis}
      isSingleChart={isSingleChart}
      isHideTotal={isHideTotal}
      isHidePercent={isHidePercent}
      showLegend={showLegend}
      colorOption={colorOption}
      rawTotal={rawTotal}
      rawCohortTotal={rawCohortTotal}
      valueDisplay={valueDisplay}
      showLabelOnTop={showLabelOnTop}
      colKey={colKey}
      subTitle={subTitle}
    />
  );
};

export default LineChart;
