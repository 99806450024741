import { TreemapChart } from '@carbon/charts-react';
import * as d3 from 'd3';
import '@carbon/charts/styles.css';
import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import Chart from 'chart.js/auto';
import { debounce, get } from 'lodash';
import React from 'react';
import './style.css';
import clsx from 'clsx';
import { createPortal } from 'react-dom';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { DropdownCell } from '@ep/insight-ui/elements/etable2/dropdown-cell';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

const log = () => {};
// console.info.bind(console, '[treemap]');

interface ScatterStyleProps {
  data: Chart.ChartData;
  options: Chart.ChartOptions;
  refElement: any;
  colKey?: string;
}

const useStyles = makeStyles(() => ({
  loot: TooltipMapping.loot as CreateCSSProperties,
  wrapperChart: {
    // width: '436px',
    // height: '436px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '387px',
    overflow: 'hidden',
    "& div.bx--cc--legend[data-name='legend-items']": {
      flexWrap: 'nowrap',
      overflowX: 'auto',
      height: '100%',
      width: '100%',
      paddingBottom: '8px',
      '& .legend-item p': {
        whiteSpace: 'nowrap',
      },
      '&::-webkit-scrollbar': {
        height: '7px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dfdfe3df',
        borderRadius: '16px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
      },
    },
    '& .bx--cc--chart-wrapper text': {
      fontSize: '14px',
      fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
    },
  },
  menu: {
    '& button': {
      height: '32px',
      width: '32px',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TreeMap = ({ data, options, refElement, colKey }: ScatterStyleProps) => {
  const [init, setInit] = React.useState(false);
  const [hoverElement, setHoverElement] = React.useState(null);
  const [hoverData, setHoverData] = React.useState(null);
  const classes = useStyles();
  const markInit = React.useMemo(() => {
    return debounce(() => {
      setInit(true);
    }, 100);
  }, []);
  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      markInit();
    });

    if (!refElement.current) return;

    // refElement.current.chartRef.id = 'TreeMapChart';
  }, [refElement]);
  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);

  const backbone = React.useContext(TableBackboneContext);
  const [actions, setActions] = React.useState([]);

  React.useEffect(() => {
    const getActions = async () => {
      const actions = get(linkedObjects, ['actions', colKey], []);
      const customAtcs = await backbone.addon('cell.customActions2', () => [])(
        { field: colKey, actions },
        {
          data: { ...hoverData, ...get(hoverData, ['eData', colKey], {}) },
        },
      );
      setActions(customAtcs);
    };
    getActions();
  }, [colKey, hoverData, linkedObjects]);

  return (
    <Box className={clsx(classes.wrapperChart, 'TreeMapChart')}>
      {!init && <div>Loading...</div>}
      {init && (
        <CustomTreemapChart
          ref={refElement}
          data={data}
          options={options}
          setHoverElement={setHoverElement}
          hoverElement={hoverElement}
          setHoverData={setHoverData}
        />
      )}
      {hoverElement
        ? createPortal(
            <foreignObject
              x={hoverElement?.target?.getBoundingClientRect().width - 12 - 32}
              y="12"
              width="32"
              height="32"
            >
              <MonitorContainer
                mId="cell-edot"
                mLabel="Cell edot"
                component={'div'}
                className={clsx(classes.menu, 'dropdown_menu')}
                role={'menubar'}
              >
                {actions.length > 0 && (
                  <DropdownCell
                    cellAction={actions}
                    props={{ value: hoverData, node: { data: hoverData } }}
                    onOpened={() => undefined}
                    onClosed={() => undefined}
                    showThreeDots={true}
                    setShowThreeDots={() => undefined}
                  />
                )}
              </MonitorContainer>
            </foreignObject>,
            hoverElement.target.parentNode,
          )
        : null}
    </Box>
  );
};

class CustomTreemapChart extends TreemapChart {
  componentDidMount(): void {
    super.componentDidMount();
    try {
      if (this.chart) {
        const enterGraph = d3.select(this.chartRef.current).select('svg.cds--cc--treemap svg');
        const allLeafGroups = enterGraph.selectAll("g[data-name='leaf']");

        allLeafGroups.on('mouseover', (e, d) => {
          if (
            this.props.setHoverElement &&
            this.props.hoverElement?.target != e.target &&
            e.target?.tagName.toLowerCase() == 'rect'
          ) {
            this.props.setHoverElement(e);
            this.props.setHoverData(d?.data?.rawData);
          }
        });
        allLeafGroups
          .selectAll('foreignObject.description')
          .data((d) => {
            return d;
          })
          .join(
            (enter) => {
              enter
                .append('foreignObject')
                .html((d) => {
                  const data = get(d.data, 'data', {});
                  const label = get(d.data, 'label', d.value);
                  const subtextFields = Object.keys(data)
                    .filter((k) => /subtext(\d)*/.test(k))
                    .sort();
                  if (Object.keys(subtextFields).length > 0) {
                    return `<p class="subtext">${subtextFields.map((f) => data[f]).join('<br />')}</p>`;
                  }
                  return `<p style="color: white">${label}</p>`;
                })
                .attr('pointer-events', 'none') // by pass the event
                .attr('class', 'description')
                .attr('x', 7)
                .attr('y', 24)
                .attr('width', (d) => (d.x1 > 14 ? d.x1 - 14 : 0))
                .attr('height', (d) => (d.y1 > 36 ? d.y1 - 36 : 0));
            },
            (update) => update.text((d) => d.text).style('fill', 'white'),
            (exit) => exit.remove(),
          );
      }
    } catch {}
  }
}
