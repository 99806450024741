export const boldMatchKeyword = (str, keyword) => {
  const tmpStr = str.toLowerCase();
  const tmpKeyword = keyword.toLowerCase();
  let firstIndex = 0;
  let lastIndex = 0;
  firstIndex = tmpStr.indexOf(tmpKeyword);
  if (firstIndex === -1) {
    return str;
  }
  lastIndex = tmpStr.indexOf(tmpKeyword) + tmpKeyword.length;

  return (
    str.slice(0, firstIndex) + '<b>' + str.slice(firstIndex, lastIndex) + '</b>' + str.slice(lastIndex, str.length)
  );
};
