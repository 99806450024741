/* eslint-disable react/prop-types */
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { Avatar, Box, createStyles, Grid, Popover, Theme, Tooltip, withStyles } from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

// import { enhanceCellAction } from '../table-helper';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { FormatAvatar } from './format-avatar';
import TooltipWrapper from '../../tooltip-wrapper';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';

const LinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 4,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#eeeeee',
    },
  }),
)(MuiLinearProgress);
const useStyles = makeStyles(() => ({
  format: {
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
  },
  hashtag: {
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  alignItemsEnd: {
    alignItems: 'flex-end',
  },
  tooltip: {
    background: '#253746',
    fontSize: (props) => (props.customTooltipFontSize ? props.customTooltipFontSize : '10px'),
    lineHeight: '1.2em',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  statusContainer: {
    flexShrink: 0,
    flexGrow: 0,
  },
  statusCenter: {
    width: '100%',
  },
  cellContainer: {
    columnGap: '5px',
  },
  avatar: {
    width: '24px',
    height: '24px',
    fontSize: '10px',
    border: '1px solid #CCE3F5',
    background: '#EBF6FF',
    color: '#000',
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

export type IValueTextFormat = {
  label: string;
  value: string;
  status?: IconType;
  hashtag?: string;
  subtext?: string;
  subTabs?: Array<IValueTextFormat>;
};
const TextFormat = (props: IPropsFormat<IValueTextFormat>) => {
  const customTooltipFontSize = get(props, ['value', 'tooltip.fontSize'], '');
  const classes = useStyles({ customTooltipFontSize });
  const backbone = React.useContext(TableBackboneContext);
  const node = get(props, 'node', null);
  const value = get(props, 'value', null);

  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const isMinimalTable = typeTable == 'minimal';

  const label = get(props, ['value', 'label'], null);
  const status = get(props, ['value', 'status'], '');
  const subtext = get(props, ['value', 'subtext'], '');
  const hashtag = get(props, ['value', 'hashtag'], '');
  const iconSize = get(props, ['value', 'iconSize'], '12');
  const iconCenter = get(props, ['value', 'iconCenter'], '') == 1;
  const disabled = get(props, 'value.disabled', false);
  const avatar = get(props, ['value', 'avatar'], null);
  const avatarName = get(props, ['value', 'avatarName'], null);
  const customTooltip = get(props, ['value', 'tooltip'], '');
  const isBarProgress = get(props, ['value', 'showAs'], '') === 'bar';
  const dividedBy = Number(get(props, ['value', 'dividedBy'], '0.01'));
  let valueProgress = Number(get(props, ['value', 'value'], 0)) / dividedBy;
  if (valueProgress >= 0) {
    valueProgress = 1 + valueProgress;
  }
  const tableId = useAtomValue(eTableAtom.tableId);
  const statusTooltip = get(props, ['value', 'status.tooltip'], null);
  const labelTooltip = get(props, ['value', 'label.tooltip'], null);
  const subtextTooltip = get(props, ['value', 'subtext.tooltip'], null);
  const hashtagTooltip = get(props, ['value', 'hashtag.tooltip'], null);
  const barColor = get(props, ['value', 'barColor'], '#0369C7') || '#0369C7';
  const barSubtext = get(props, ['value', 'barSubtext'], Number(valueProgress * 100).toFixed(2) + '%');
  const alignStyle = {
    left: 'alignItemsStart',
    center: 'alignItemsCenter',
    right: 'alignItemsEnd',
  };
  const dataType = get(backbone.getConfig('mapping'), [props.field, 'dataType'], 'string');
  const align = ['float', 'integer'].includes(dataType) ? 'right' : 'left';

  const customStyle = React.useMemo(() => {
    const styles = Object.keys(value || {})
      .filter((el) => el.startsWith('style.'))
      .reduce((a, b) => {
        const styleName = b.replace('style.', '');
        let styleValue = value && value[b] ? toValue(value[b], value) : null;

        if (styleName === 'background' || styleName.includes('color')) {
          if (styleValue === 'auto') {
            styleValue = typeof label == 'string' ? stringToTagColour(label) : null;
          }
        }
        if (styleValue) {
          return {
            ...a,
            [styleName]: styleValue,
          };
        }
        return a;
      }, {});

    return styles;
  }, [value, label]);

  return (
    <WrapperFormat
      cellAction={props.cellAction}
      typeTable={typeTable}
      node={node}
      value={value}
      isGrouped={isGrouped}
      disabledMenu={disabled}
      eGridCell={props.eGridCell}
      gridApi={props.api}
      data={props.data}
      field={props.field}
    >
      <TooltipWrapper tooltipConfig={customTooltip} data={node?.data} tableId={tableId}>
        <Grid
          container
          alignItems={'center'}
          wrap={'nowrap'}
          className={classes.height100}
          style={{ overflow: 'hidden' }}
        >
          {/* text */}
          <Grid item style={{ width: '100%', height: '100%' }}>
            <Grid
              container
              alignItems="center"
              wrap={'nowrap'}
              className={clsx(classes.height100, classes.cellContainer, 'cell-format')}
            >
              {/* status icon */}
              {!isEmpty(status) ? (
                <TooltipWrapper tooltipConfig={statusTooltip} data={node?.data} tableId={tableId}>
                  <Grid item className={clsx(classes.statusContainer, iconCenter ? classes.statusCenter : '')}>
                    <span className={classes.wrapperIcon}>
                      <Icon type={String(status) as IconType} size={iconSize} className={`${classes.icon} status`} />
                    </span>
                  </Grid>
                </TooltipWrapper>
              ) : null}
              {avatar ? (
                <FormatAvatar avatar={avatar || ''} />
              ) : avatarName ? (
                <Avatar src={''} className={`${classes.avatar}`}>
                  {avatarName.length > 0
                    ? avatarName
                        .split(' ')
                        .map((el) => el[0])
                        .join('')
                    : avatarName[0]}
                </Avatar>
              ) : null}
              {hashtag || label !== null || subtext || isBarProgress ? ( // Do not render this DOM if none of hashtag, label, subtext has value
                <Grid item style={{ width: '100%' }} className={clsx(classes.height100)}>
                  <Grid
                    container
                    alignItems={'center'}
                    direction={'column'}
                    wrap={'nowrap'}
                    className={clsx(
                      classes.height100,
                      classes[get(alignStyle, [align], 'alignItemsStart')],
                      'cell-format-main',
                    )}
                  >
                    {/*hashtag*/}
                    {isMinimalTable ? null : (
                      <TooltipWrapper
                        tooltipConfig={hashtagTooltip}
                        data={node?.data}
                        tableId={tableId}
                        isShow={hashtag}
                      >
                        <Grid item xs={12} className={classes.height100}>
                          {String(hashtag)?.includes('</span>') ? (
                            <span
                              className={`${classes.hashtag} ${disabled && 'disabled'}`}
                              dangerouslySetInnerHTML={{ __html: hashtag }}
                            ></span>
                          ) : (
                            <span className={`${classes.hashtag} ${disabled && 'disabled'}`}>{hashtag}</span>
                          )}
                        </Grid>
                      </TooltipWrapper>
                    )}
                    {/* label */}
                    {isBarProgress ? (
                      <Grid
                        container
                        direction={'column'}
                        alignItems={'flex-start'}
                        wrap={'nowrap'}
                        justifyContent={'center'}
                      >
                        <Grid container alignItems={'center'} item style={{ width: '100%', height: '20px' }}>
                          <LinearProgress
                            style={{ width: '100%', backgroundColor: barColor }}
                            variant="determinate"
                            value={valueProgress * 100}
                          />
                        </Grid>
                      </Grid>
                    ) : (
                      <TooltipWrapper tooltipConfig={labelTooltip} data={node?.data} tableId={tableId} isShow={label}>
                        <Grid item xs={12} className={clsx(classes.height100, 'label')}>
                          {Object.keys(customStyle).length > 0 ? (
                            <Box
                              className={`${classes.label} ${disabled && 'disabled'}`}
                              style={{
                                padding: customStyle.background ? '0 6px' : undefined,
                                borderRadius: customStyle.background ? '4px' : undefined,
                                ...customStyle,
                              }}
                            >
                              {String(label)?.includes('</span>') ? (
                                <span dangerouslySetInnerHTML={{ __html: label }}></span>
                              ) : (
                                <span>{label}</span>
                              )}
                            </Box>
                          ) : (
                            <>
                              {String(label)?.includes('</span>') ? (
                                <span
                                  dangerouslySetInnerHTML={{ __html: label }}
                                  className={`${classes.label} ${disabled && 'disabled'}`}
                                ></span>
                              ) : (
                                <span className={`${classes.label} ${disabled && 'disabled'}`}>{label}</span>
                              )}
                            </>
                          )}
                        </Grid>
                      </TooltipWrapper>
                    )}

                    {/* subtext */}
                    {isMinimalTable ? null : (
                      <TooltipWrapper
                        tooltipConfig={subtextTooltip}
                        data={node?.data}
                        tableId={tableId}
                        isShow={subtext}
                      >
                        <Grid item xs={12} className={classes.height100}>
                          {String(subtext)?.includes('</span>') ? (
                            <span
                              className={`${classes.subtext} ${disabled && 'disabled'}`}
                              dangerouslySetInnerHTML={{ __html: subtext }}
                            ></span>
                          ) : (
                            <span className={`${classes.subtext} ${disabled && 'disabled'}`}>
                              {subtext ? subtext : ''}
                            </span>
                          )}
                        </Grid>
                      </TooltipWrapper>
                    )}
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </TooltipWrapper>
    </WrapperFormat>
  );
};

export default TextFormat;
