import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import Chart from 'chart.js/auto';
import * as React from 'react';
import { Line } from 'react-chartjs-2';

interface StackedStyleProps {
  data: Chart.ChartData;
  options: Chart.ChartOptions;
  plugins: Chart.PluginServiceRegistrationOptions[];
  refElement: any;
  redraw: boolean;
}

const useStyles = makeStyles(() => ({
  loot: TooltipMapping.loot as CreateCSSProperties,
  wrapperChart: {
    // width: '436px',
    // height: '436px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: '100%',
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const Lined = ({ data, options, plugins, refElement, redraw }: StackedStyleProps) => {
  const classes = useStyles();

  React.useEffect(() => {
    for (const id in Chart.instances) {
      Chart.instances[id].resize();
    }
  }, []);

  return (
    <div className={classes.wrapperChart}>
      {data && (
        <Line
          ref={refElement}
          data={data}
          options={options}
          plugins={plugins}
          className={classes.loot}
          redraw={redraw}
        />
      )}
    </div>
  );
};
