import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Icon from '@ep/insight-ui/icons/Icon';
import { TypeTabListItem } from '../../app-menu/tab-list-menu';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { TypeSimpleTab } from '../../app-menu/simple-tab';
import SearchInput from '../search-input/search-input';

const useViewStyles = makeStyles({
  root: {
    '& .eip1-MuiTreeItem-label': {
      backgroundColor: 'transparent !important',
    },
  },
});

const useStyles = makeStyles({
  treeView: {
    '& .eip1-MuiTreeItem-content': {
      pointerEvents: 'none',
      padding: '6px',
      '& > *': {
        pointerEvents: 'visible',
      },
      '& .eip1-MuiTreeItem-iconContainer': {
        pointerEvents: 'visible',
        marginTop: '-2px',
        '& *': {
          pointerEvents: 'none',
        },
      },
    },
  },
  srollBar: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
});
let useStyles_Flag;
if (ff.welcome_page_questionnaire) {
  useStyles_Flag = makeStyles({
    labelSelect: {
      fontSize: '18px',
      lineHeight: '20px',
      fontWeight: 500,
      color: '#253746',
    },
  });
}

const useStylesCheckedBox = makeStyles(() => ({
  root: {
    position: 'relative',
    border: '2px solid',
    borderRadius: '2px',
    borderColor: '#C2C7CB !important',
    width: '12px',
    height: '12px',
    marginTop: '-2px',
    '& .eip1-MuiSvgIcon-root': {
      width: 'auto',
    },
  },
  rootChecked: {
    backgroundColor: '#0369C7',
    borderColor: '#0369C7  !important',
    '&.icon_disabled': {
      backgroundColor: '#E4E7E9',
      borderColor: '#E4E7E9 !important',
    },
  },
  icon: {
    height: 'auto',
    color: 'white',
    position: 'absolute',
    top: '50%',
    left: '0',
    transform: 'translate(0, -50%)',
    '&.icon_disabled': {
      color: '#C2C7CB',
    },
  },
}));

interface ITreeListProps {
  dataListMenu: TypeTabListItem[];
  onSelected?: (value: TypeTabListItem[]) => void;
  onToggle?: () => void;
  searchAble?: boolean;
  disableCheckbox?: boolean;
}

const TreeList = ({
  dataListMenu,
  onSelected,
  onToggle,
  searchAble = true,
  disableCheckbox = false,
}: ITreeListProps) => {
  const classesView = useViewStyles();
  const classes = useStyles();

  const [expanded, setExpanded] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [inputValue, setInputValue] = React.useState('');
  const [searchDataListMenu, setsearchDataListMenu] = React.useState(dataListMenu);

  React.useEffect(() => {
    setsearchDataListMenu(dataListMenu);
  }, [dataListMenu]);

  const handleToggle = (event, nodeIds) => {
    const classList: DOMTokenList = event.target.classList;

    let isToggle;
    classList.forEach((strClas) => {
      if (strClas === 'eip1-MuiTreeItem-iconContainer') {
        isToggle = true;
      }
    });
    if (!isToggle) {
      return;
    }
    setExpanded(nodeIds);
    onToggle && onToggle();
  };

  const handleSearchTreeItems = (tree: TypeSimpleTab[]) => {
    const tmp = JSON.parse(JSON.stringify(tree)) as TypeSimpleTab[];

    if (tmp.length === 0) return [];

    tree.forEach((item, index) => {
      if (item.tabs) {
        const afterSearchItems = handleSearchTreeItems(item.tabs);
        if (afterSearchItems.length === 0) {
          if (!item.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
            tmp[index] = null;
          }
          return;
        }
        tmp[index].tabs = afterSearchItems;
        return;
      }
      if (!item.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase())) {
        tmp[index] = null;
      }
    });

    return tmp.filter((item) => item !== null);
  };

  React.useEffect(() => {
    const newDataList = handleSearchTreeItems(dataListMenu);
    setsearchDataListMenu(newDataList);
  }, [inputValue]);

  const handleSelected = (tree: TypeTabListItem[]) => {
    const tmp = JSON.parse(JSON.stringify(dataListMenu)) as TypeTabListItem[];
    const newDataList = tmp.map((item) => {
      const found = tree.find((treeItem) => treeItem.nodeId === item.nodeId);
      if (found) return found;
      return item;
    });

    onSelected(newDataList);
    setsearchDataListMenu(tree);
  };

  return (
    <div>
      {searchAble && (
        <div>
          <SearchInput
            inputValue={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
            }}
            setInputValue={setInputValue}
            fullWidth={true}
          />
        </div>
      )}
      <div className={clsx(classes.srollBar, 'srollArea')}>
        <TreeView
          classes={classesView}
          className={classes.treeView}
          defaultCollapseIcon={<Icon type={'triangularArrowDown'} color={'inherit'} />}
          defaultExpandIcon={<Icon type={'triangularArrowRight'} color={'inherit'} />}
          expanded={expanded}
          selected={selected}
          onNodeToggle={handleToggle}
          multiSelect
        >
          <TreeItems disableCheckbox={disableCheckbox} dataListMenu={searchDataListMenu} onSelected={handleSelected} />
        </TreeView>
      </div>
    </div>
  );
};

type ICheckedBox = {
  icon: 'indeterminate' | 'checked' | 'defaut';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLElement>;
};
const CheckedBox = ({ icon, disabled = false, onClick }: ICheckedBox) => {
  const classes = useStylesCheckedBox();
  return (
    <Box
      onClick={onClick}
      className={clsx(classes.root, icon !== 'defaut' && classes.rootChecked, disabled ? 'icon_disabled' : '')}
    >
      {icon !== 'defaut' && <Icon type={icon} className={`${classes.icon} ${disabled ? 'icon_disabled' : null}`} />}
    </Box>
  );
};

interface ITreeItemsProps {
  dataListMenu: TypeSimpleTab[];
  onSelected?: (value: TypeSimpleTab[]) => void;
  disableCheckbox?: boolean;
}
const TreeItems = ({ dataListMenu, onSelected, disableCheckbox = false }: ITreeItemsProps) => {
  const classes = useStyles();
  let classesFlag;
  if (ff.welcome_page_questionnaire) {
    classesFlag = useStyles_Flag();
  }

  const selectedAll = (tabs: TypeSimpleTab[], value: boolean) => {
    tabs.forEach((item) => {
      item.selected = value;
      if (item.tabs) {
        item.indeterminate = false;
        selectedAll(item.tabs, value);
      }
    });
  };

  const handleSelected = (index: number) => {
    const tmp = JSON.parse(JSON.stringify(dataListMenu)) as TypeSimpleTab[];
    tmp[index].selected = !tmp[index].selected;
    const isGroup = !!tmp[index].nodeId && !!tmp[index].tabs;
    if (isGroup) {
      tmp[index].indeterminate = false;
      selectedAll(tmp[index].tabs, tmp[index].selected);
    }

    onSelected(tmp);
  };

  const handldItemsSelected = (value: TypeSimpleTab[], index: number) => {
    const tmp = JSON.parse(JSON.stringify(dataListMenu)) as TypeSimpleTab[];

    const allSelected = value.every((item) => item.selected);
    const allNotSelected = value.every((item) => !item.selected);

    if (allSelected) {
      tmp[index].selected = true;
      tmp[index].indeterminate = false;
    } else if (allNotSelected) {
      tmp[index].selected = false;
      tmp[index].indeterminate = false;
    } else {
      tmp[index].selected = false;
      tmp[index].indeterminate = true;
    }

    tmp[index].tabs = value;
    onSelected(tmp);
  };

  const handleCheckBox = (treeItem: TypeSimpleTab) => {
    if (treeItem.selected) return 'checked';
    if (treeItem.indeterminate) return 'indeterminate';

    if (treeItem.tabs && treeItem.tabs.some((item) => item.indeterminate)) return 'indeterminate';

    return 'defaut';
  };
  return (
    <>
      {dataListMenu.map((treeItem, index) => (
        <TreeItem
          key={treeItem.nodeId}
          nodeId={treeItem.nodeId}
          label={
            <Box
              display={'flex'}
              alignItems={'center'}
              gridGap={'8px'}
              onClick={() => {
                if (!disableCheckbox) {
                  handleSelected(index);
                }
              }}
            >
              {!disableCheckbox && <CheckedBox icon={handleCheckBox(treeItem)} />}
              <Typography variant="body2">{treeItem.label}</Typography>
            </Box>
          }
        >
          {treeItem.tabs && (
            <TreeItems dataListMenu={treeItem.tabs} onSelected={(v) => handldItemsSelected(v, index)} />
          )}
        </TreeItem>
      ))}
    </>
  );
};

export default TreeList;
