import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import FormControlInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/form-control-input';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as React from 'react';
import CircleLoading from '../loading/circle-loading';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import Selection from '@ep/insight-ui/elements/input-form/form-templates/form-controls/selection';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  formPassword: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  linkWord: {
    color: '#0369C7',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  credentialDescription: {
    color: '#8C98A4',
    marginBottom: '8px',
  },
  selectStorefrontLabel: {
    color: '#8C98A4',
    marginBottom: '8px',
  },
  section: {
    marginTop: '24px',
  },
  expanded: {
    '& .eip1-MuiAccordionSummary-root.Mui-expanded': {
      marginBottom: '10px',
    },
  },
  error: {
    '& .eip1-MuiFormHelperText-contained p, & .eip1-MuiFormHelperText-contained svg': {
      color: '#D4290D',
    },
  },
  helper: {
    '& > p.eip1-MuiFormHelperText-contained': {
      display: 'none',
    },
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    ...(ff.integrate_storefront_ui_v2
      ? {
          borderBottom: '1px solid #C2C7CB',
        }
      : {
          borderRadius: '8px',
        }),
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  buttonCancel: {
    color: '#8C98A4',
  },
  mobileContent: {
    padding: ff.integrate_storefront_ui_v2 ? '20px 25px 0 25px' : '15px 0 0 0',
    height: '100vh',
  },
  buttonValidate: {
    width: '95%',
    height: '40px',
    marginTop: ff.integrate_storefront_ui_v2 ? '15px' : '30px',
    marginLeft: '12px',
  },
  buttonBack: {
    width: '100%',
    height: '40px',
    marginTop: '15px',
    background: '#F6F7F8',
  },
  descriptionMobile: {
    color: '#8C98A4',
    marginTop: '32px',
    lineHeight: '22px',
  },
  message: {
    marginBottom: '8px',
    paddingLeft: '4px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
});

const AccountInformationForm = ({
  loading = false,
  initialValues,
  onClose,
  onNextStep,
  onBackStep,
  messageError,
  subtext,
  otpProcess = false,
  extensionCode = '',
}: any) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const widthScreen = window.screen.width;
  const isMobile = containerClass === 'eres--small' && widthScreen < 420;
  return (
    <Grid container direction={'column'}>
      {!isMobile ? (
        <Grid item xs={12}>
          <span className={`${classes.text} title`}>Connect your store</span>
        </Grid>
      ) : (
        <div className={classes.mobileHeader}>
          <h4 className={classes.mobileTitle}>Account information</h4>
          <div className={classes.mobileButton}>
            <Button className={classes.buttonCancel} color="secondary" onClick={onClose}>
              Cancel
            </Button>
          </div>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          if (!loading) {
            onNextStep(values);
          }
        }}
        // validate={validatorForSchema(passwordSchema)}
      >
        {({ values, errors, setFieldValue }) =>
          !isMobile ? (
            <Form>
              <Grid item xs={12} className={classes.section}>
                <ExpansionForm
                  label={'Account information'}
                  level={'2'}
                  detailContentSize={'full'}
                  defaultExpanded={true}
                  className={classes.expanded}
                >
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Selection
                          options={[
                            {
                              label: 'Main account',
                              value: 'main_account',
                            },
                            {
                              label: 'Sub account',
                              value: 'sub_account',
                            },
                          ]}
                          placeholder={'Choose account type'}
                          classNameContainer={`${classes.formControl} select`}
                          onChange={(event) => {
                            setFieldValue('account_type', event.target.value);
                          }}
                          label={'Account type'}
                          value={values.account_type}
                          disabled={loading || otpProcess}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Selection
                          options={[
                            {
                              label: 'Auto',
                              value: '0',
                            },
                            {
                              label: 'Extension',
                              value: '1',
                            },
                          ]}
                          placeholder={'Choose method'}
                          classNameContainer={`${classes.formControl} select`}
                          onChange={(event) => {
                            setFieldValue('method', event.target.value);
                            if (event.target.value == '1') {
                              setFieldValue('username', '');
                              setFieldValue('password', '');
                            }
                          }}
                          label={'Method'}
                          value={values.method}
                        />
                      </Grid>
                      {values.method != '1' ? (
                        <>
                          <Grid item xs={12}>
                            <FormControlInput
                              label={'Username'}
                              classNameContainer={`${classes.formControl} input`}
                              placeholder={'Your username to login on Seller center'}
                              defaultValue={values.username}
                              onChangeText={(value) => setFieldValue('username', value)}
                              className={classes.helper}
                              autoComplete="new-password"
                              disabled={otpProcess || loading}
                            />
                          </Grid>
                          <Grid className={classes.formPassword} item xs={12}>
                            <FormControlInput
                              label={'Password'}
                              classNameContainer={`${classes.formControl} input`}
                              placeholder={'Your password to login on Seller center'}
                              type={'password'}
                              defaultValue={values.password}
                              onChangeText={(value) => setFieldValue('password', value)}
                              className={classes.helper}
                              autoComplete="new-password"
                              disabled={otpProcess || loading}
                            />
                          </Grid>
                        </>
                      ) : (
                        <Grid className={classes.formPassword} item xs={12}>
                          <FormControlInput
                            label={'Register code'}
                            classNameContainer={`${classes.formControl} input`}
                            placeholder={'Please enter register code here'}
                            defaultValue={values.reg_code}
                            onChangeText={(value) => setFieldValue('reg_code', value)}
                            className={classes.helper}
                            autoComplete="new-password"
                            disabled={loading}
                          />
                        </Grid>
                      )}
                      {otpProcess ? (
                        <Grid className={classes.formPassword} item xs={12}>
                          <FormControlInput
                            label={'OTP'}
                            classNameContainer={`${classes.formControl} input`}
                            placeholder={'Please enter the OTP to proceed with the process'}
                            defaultValue={values.otp}
                            onChangeText={(value) => setFieldValue('otp', value)}
                            className={classes.helper}
                            disabled={loading}
                          />
                        </Grid>
                      ) : null}
                      <Grid item xs={12}>
                        <div className={classes.credentialDescription}>
                          <p style={{ margin: 0 }}>
                            Your account will be encrypted and used for operating your storefront. No one can access
                            this information. Visit{' '}
                            <a
                              target="_blank"
                              href="https://epsilo.io/privacy-policy"
                              className={classes.linkWord}
                              rel="noreferrer"
                            >
                              Privacy Policy
                            </a>{' '}
                            for more information on how we protect your data.
                          </p>
                        </div>
                      </Grid>
                      {extensionCode ? (
                        <Grid item xs={12}>
                          <div className={classes.credentialDescription}>
                            <p style={{ margin: 0 }}>
                              Please use this code{' '}
                              <span>
                                <strong>{extensionCode}</strong>
                              </span>{' '}
                              to proceed with the plugin.
                            </p>
                          </div>
                        </Grid>
                      ) : null}
                      {messageError && (
                        <div className={classes.message}>
                          <span style={{ fontSize: '12px', color: '#d4290d' }}>
                            <Icon type={'error'} style={{ width: '9px', height: '9px' }} /> {messageError}
                          </span>
                        </div>
                      )}
                      {subtext && (
                        <div className={classes.message}>
                          <Icon type={'ic/mi:circle-information/#253746'} />{' '}
                          <span style={{ fontSize: '14px', color: '#253746' }}>{subtext}</span>
                        </div>
                      )}
                    </Grid>
                  </Box>
                </ExpansionForm>
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      className={`${classes.button} back`}
                      onClick={onBackStep}
                    >
                      <span className={`${classes.text} textButton`}>Back</span>
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    {loading ? <CircleLoading /> : ''}
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      className={`${classes.button} cancel`}
                      onClick={onClose}
                    >
                      <span className={`${classes.text} textButton`}>Cancel</span>
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      type="submit"
                      disabled={
                        loading ||
                        ((!values.username || !values.password) && values.method != '1') ||
                        (otpProcess && !values.otp)
                      }
                    >
                      Register account
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Form>
          ) : (
            <Form>
              <Box className={classes.mobileContent}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <FormControlInput
                      label={'Email/Phone/Username'}
                      classNameContainer={`${classes.formControl} input`}
                      placeholder={'Your username to login on Seller center'}
                      defaultValue={values.username}
                      onChangeText={(value) => setFieldValue('username', value)}
                      className={classes.helper}
                      autoComplete="new-password"
                    />
                  </Grid>
                  <Grid className={classes.formPassword} item xs={12}>
                    <FormControlInput
                      label={'Passwords'}
                      placeholder={'Your password to login on Seller center'}
                      type={'password'}
                      defaultValue={values.password}
                      errorMessage={errors.password}
                      helperText={messageError}
                      error={!!messageError}
                      onChangeText={(value) => setFieldValue('password', value)}
                      className={classes.error}
                      autoComplete="new-password"
                    />
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.descriptionMobile}>
                    <p style={{ margin: 0 }}>
                      Your account will be encrypted and used for operating your storefront. No one can access this
                      information. Visit{' '}
                      <a
                        target="_blank"
                        href="https://epsilo.io/privacy-policy"
                        className={classes.linkWord}
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>{' '}
                      for more information on how we protect your data.
                    </p>
                  </div>
                </Grid>
                <Grid container direction="row">
                  <Grid item xs={3}>
                    <Button className={classes.buttonBack} onClick={onBackStep}>
                      Back
                    </Button>
                  </Grid>
                  <Grid item xs={9}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.buttonValidate}
                      type="submit"
                      disabled={loading || !values.username || !values.password}
                    >
                      Validate account
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Form>
          )
        }
      </Formik>
    </Grid>
  );
};
export default AccountInformationForm;
