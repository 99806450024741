import { expose } from 'threads/worker';
import {
  buildNodeAsBlockDiff,
  buildPageChangeset,
  buildPageBlockChangeset,
  buildPageViewsChange,
} from './page-changeset';

import * as jsonpatch from 'fast-json-patch';
import { omit, keyBy } from 'lodash';

expose({
  hello(payload) {
    console.info('hello web worker...', payload);
  },
  diff(currentPage, nextPage) {
    console.info('worker...', { currentPage, nextPage });
    const pageDiff = jsonpatch.compare(omit(currentPage, 'nodes'), omit(nextPage, 'nodes'));
    const nodeDiff = buildNodeAsBlockDiff(keyBy(currentPage.nodes, 'id'), keyBy(nextPage.nodes, 'id'));

    return { currentPage, patches: pageDiff, nodeDiff, nextPage };
  },
  buildPageChangeset,
  buildPageBlockChangeset,
  buildPageViewsChange,
});
