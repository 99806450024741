import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Desktop = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M12 2H4.00523C2.89879 2 2.00262 2.895 2.00262 4V13C2.00262 14.105 2.89879 15 4.00523 15H0C0 16.105 0.896171 17 2.00262 17H17.9974C19.1038 17 20 16.105 20 15H15.9948C17.1012 15 17.9974 14.105 17.9974 13V7.27461L17.0092 6.64003L15.9948 7.2846V12C15.9948 12.5525 15.5467 13 14.9935 13H5.00654C4.45332 13 4.00523 12.5525 4.00523 12V5C4.00523 4.4475 4.45332 4 5.00654 4H13.683L12.3411 2.76018C12.1925 2.63004 12.0858 2.45879 12.0344 2.26803C12.0108 2.18018 11.9993 2.09001 12 2Z" />
    <path d="M19.4525 1.80614L18.253 1.69815C18.1989 1.69127 18.1477 1.66976 18.1049 1.63593C18.0621 1.6021 18.0293 1.55723 18.0101 1.50616L17.5663 0.387197C17.5231 0.273362 17.4464 0.175357 17.3462 0.106198C17.246 0.0370392 17.1272 0 17.0055 0C16.8838 0 16.765 0.0370392 16.6648 0.106198C16.5647 0.175357 16.4879 0.273362 16.4447 0.387197L16.0009 1.50616C15.9806 1.55792 15.9462 1.60301 15.9018 1.63639C15.8573 1.66976 15.8044 1.69011 15.749 1.69515L14.5585 1.80614C14.4403 1.81429 14.3272 1.85729 14.2334 1.9297C14.1395 2.00211 14.0693 2.1007 14.0314 2.21302C13.9936 2.32535 13.9898 2.44637 14.0207 2.56082C14.0515 2.67527 14.1155 2.77802 14.2047 2.85611L15.1463 3.72607C15.1849 3.76157 15.2135 3.80656 15.2293 3.85656C15.245 3.90657 15.2475 3.95984 15.2362 4.01106L14.9634 5.26202C14.9361 5.37758 14.9436 5.49864 14.9852 5.60988C15.0267 5.72113 15.1003 5.81754 15.1966 5.88693C15.2929 5.95631 15.4077 5.99553 15.5263 5.99964C15.6449 6.00375 15.7621 5.97255 15.863 5.90999L17.0055 5.18402L18.136 5.90999C18.2369 5.97255 18.3541 6.00375 18.4727 5.99964C18.5914 5.99553 18.7061 5.95631 18.8024 5.88693C18.8988 5.81754 18.9723 5.72113 19.0139 5.60988C19.0554 5.49864 19.063 5.37758 19.0357 5.26202L18.7628 4.01106C18.7516 3.95984 18.754 3.90657 18.7698 3.85656C18.7855 3.80656 18.8142 3.76157 18.8527 3.72607L19.7943 2.85611C19.883 2.77876 19.947 2.67703 19.9784 2.56357C20.0097 2.45011 20.007 2.32995 19.9705 2.21803C19.9341 2.10611 19.8655 2.00739 19.7734 1.93415C19.6813 1.86091 19.5697 1.8164 19.4525 1.80614Z" />
  </SvgIcon>
);

export default Desktop;
