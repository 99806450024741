import { Chip, Grid, makeStyles, Popover } from '@material-ui/core';
import * as React from 'react';
import { get } from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';
import { Button, Menu } from '@material-ui/core';
import WrapperFormat, { IPropsFormat, ICellAction } from './wrapper-format';
import TagEditor from '../../inline-edit/cell/tag-editor';
/**
 * ff.ui_ux_update_tag_item:start
 */
import CreateTagEditor from '../../inline-edit/cell/createtag-editor';
/**
 * ff.ui_ux_update_tag_item:end
 */

import { tagsData } from '@ep/insight-ui/elements/inline-edit/cell/tag-editor/mocks/tag-data';

/**
 * ff.add_css_identifier_to_new_release_components:start
 */
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
/**
 * ff.add_css_identifier_to_new_release_components:end
 */

const useStyles = makeStyles(() => ({
  tagItem: {
    background: ' #F2F3F4',
    height: '24px',
    borderRadius: '4px',
    '& .eip1-MuiChip-deleteIcon': {
      width: '10px',
      height: '10px',
      color: '#3F4F5C',
    },
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0px',
    textAlign: 'left',
    color: '#253746',
  },
  menu: {
    display: 'flex',
    width: '100%',
    justifyContent: 'end',
  },
  typography: {
    paddingLeft: '10px',
  },
}));

export interface ITag {
  label?: string;
  value?: string;
}
export interface ITagFormat {
  value: string;
}

const TagWithInlineEditor = (props: IPropsFormat<ITagFormat>) => {
  const classes = useStyles();
  const cellAction = get(props, ['cellAction'], []);
  const updateHandler = get(props, 'updateHandler');
  let actionSubItem, isCreateTagForm, setIsCreateTagForm;
  if (ff.ui_ux_update_tag_item) {
    actionSubItem = get(props, 'actionSubItem', []);
    [isCreateTagForm, setIsCreateTagForm] = React.useState(false);
  }
  const node = get(props, ['node'], null);
  const value = get(props, ['value', 'value'], null);
  const tagsGetter = React.useMemo(() => get(props, ['value', 'value'], '[]'), [props]);
  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const column = get(props, ['column', 'colDef'], null);
  const nodeGroup = get(props, ['node', 'group'], false);

  const tagsDemo = {
    campaign_tag: [
      { label: 'Normal day', value: 'normal_day' },
      { label: 'Brand day', value: 'brand_day' },
      { label: 'Super brand day', value: 'super_brand_day' },
      { label: 'Other', value: 'other' },
    ],
    ad_object_tag: [
      { label: 'High-performing', value: 'high_performing' },
      { label: 'Mid-performing', value: 'mid_performing' },
      { label: 'Low-performing', value: 'low_performing' },
      { label: 'Priority', value: 'priority' },
      { label: 'Non-priority', value: 'non_priority' },
      { label: 'New', value: 'new' },
      { label: 'Other', value: 'other' },
    ],
    keyword_tag: [
      { label: 'Generic', value: 'generic' },
      { label: 'Brand', value: 'brand' },
      { label: 'Competitor', value: 'competitor' },
      { label: 'Category', value: 'category' },
      { label: 'Relevant', value: 'relevant' },
      { label: 'Must win', value: 'must_win' },
      { label: 'New', value: 'new' },
      { label: 'Other', value: 'other' },
    ],
  };

  const tagsValue: Array<ITag> = tagsData[column.field] ? tagsData[column.field] : [];

  const [isEditTagForm, setIsEditTagForm] = React.useState(false);

  const currentValue = React.useMemo(
    () =>
      tagsValue.find((el) => {
        return el.value === value;
      }),
    [value],
  );

  const { firstTag, tags }: { firstTag: ITag; tags: Array<ITag> } = React.useMemo(() => {
    const first = get(tagsValue, 0);
    let tags = [];
    if (tagsValue && tagsValue.length > 1) {
      tags = tagsValue.slice(1);
    }
    return { firstTag: first, tags: tags };
  }, [tagsGetter]);

  const ref = React.useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const columnField = React.useMemo(() => column.field, [column]);

  const propsComponent = {
    onSubmit: updateHandler,
    data: {
      value,
      node,
      columnField,
    },
    ...(ff.ui_ux_update_tag_item
      ? {
          actionSubItem: actionSubItem,
          payload: {
            column: column.field,
          },
        }
      : {}),
    setAnchorEl: (val: any) => {
      setAnchorEl(val);
    },
    anchorEl: anchorEl,
  };
  let backboneContext, titleTable, titleTableClass, titleColumnClass;
  if (ff.add_css_identifier_to_new_release_components) {
    backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
    titleTable = backboneContext.getConfig('title');
    if (titleTable.length > 0) {
      titleTableClass = titleTable.replace(/\s/g, '_').toLowerCase();
      titleColumnClass = column.field.replace(/\s/g, '_').toLowerCase();
    }
  }
  let renderTags;
  if (ff.ui_ux_update_tag_item) {
    renderTags = () => {
      if (!isGrouped) {
        if (!currentValue)
          return (
            <Grid container alignItems={'center'} spacing={1}>
              <div className={classes.menu}>
                <Button
                  size="small"
                  onClick={(event) => {
                    setIsCreateTagForm(true);
                    setAnchorEl(event.currentTarget);
                  }}
                  style={{ padding: 8 }}
                  className={
                    ff.add_css_identifier_to_new_release_components
                      ? `eip_action_add_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                      : ''
                  }
                >
                  <Icon type="plus" />
                </Button>
                {isCreateTagForm && (
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <CreateTagEditor {...propsComponent} title="Tag name" />
                  </Popover>
                )}
              </div>
            </Grid>
          );
        return (
          <Grid container alignItems={'center'} spacing={1}>
            {tagsValue.length > 0 && (
              <Grid item>
                <Chip
                  className={
                    ff.add_css_identifier_to_new_release_components
                      ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                      : classes.tagItem
                  }
                  style={{ backgroundColor: currentValue.color }}
                  label={currentValue.label}
                  onClick={(event) => {
                    setIsEditTagForm(true);
                    setAnchorEl(ref.current);
                  }}
                />
                {isEditTagForm && (
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <TagEditor {...propsComponent} />
                  </Popover>
                )}
              </Grid>
            )}
          </Grid>
        );
      } else {
        if (nodeGroup) {
          if (!currentValue) {
            return (
              <Grid container alignItems={'center'} spacing={1}>
                <div className={classes.menu}></div>
              </Grid>
            );
          }
          return (
            <Grid container alignItems={'center'} spacing={1}>
              {tagsValue.length > 0 && (
                <Grid item>
                  <Chip
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : classes.tagItem
                    }
                    label={currentValue.label}
                    style={{ backgroundColor: currentValue.color }}
                    onClick={(event) => {
                      // setIsEditTagForm(true);
                      // setAnchorEl(ref.current);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        } else {
          if (!currentValue) {
            return (
              <Grid container alignItems={'center'} spacing={1}>
                <div className={classes.menu}>
                  <Button
                    size="small"
                    onClick={(event) => {
                      setIsCreateTagForm(true);
                      setAnchorEl(event.currentTarget);
                    }}
                    style={{ padding: 8 }}
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `eip_action_add_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : ''
                    }
                  >
                    <Icon type="plus" />
                  </Button>
                  {isCreateTagForm && (
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <CreateTagEditor {...propsComponent} title="Tag name" />
                    </Popover>
                  )}
                </div>
              </Grid>
            );
          }
          return (
            <Grid container alignItems={'center'} spacing={1}>
              {tagsValue.length > 0 && (
                <Grid item>
                  <Chip
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : classes.tagItem
                    }
                    style={{ backgroundColor: currentValue.color }}
                    label={currentValue.label}
                    onClick={(event) => {
                      setIsEditTagForm(true);
                      setAnchorEl(ref.current);
                    }}
                  />
                  {isEditTagForm && (
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <TagEditor {...propsComponent} />
                    </Popover>
                  )}
                </Grid>
              )}
            </Grid>
          );
        }
      }
    };
  } else {
    renderTags = () => {
      if (!isGrouped) {
        if (!currentValue)
          return (
            <Grid container alignItems={'center'} spacing={1}>
              <div className={classes.menu}>
                <Button
                  size="small"
                  onClick={(event) => {
                    setIsEditTagForm(true);
                    setAnchorEl(event.currentTarget);
                  }}
                  style={{ padding: 8 }}
                  className={
                    ff.add_css_identifier_to_new_release_components
                      ? `eip_action_add_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                      : ''
                  }
                >
                  <Icon type="plus" />
                </Button>
                {isEditTagForm && (
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <TagEditor {...propsComponent} />
                  </Popover>
                )}
              </div>
            </Grid>
          );
        return (
          <Grid container alignItems={'center'} spacing={1}>
            {tagsValue.length > 0 && (
              <Grid item>
                <Chip
                  className={
                    ff.add_css_identifier_to_new_release_components
                      ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                      : classes.tagItem
                  }
                  label={currentValue.label}
                  style={{ backgroundColor: currentValue.color }}
                  onClick={(event) => {
                    setIsEditTagForm(true);
                    setAnchorEl(ref.current);
                  }}
                />
                {isEditTagForm && (
                  <Popover
                    open={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <TagEditor {...propsComponent} />
                  </Popover>
                )}
              </Grid>
            )}
          </Grid>
        );
      } else {
        if (nodeGroup) {
          if (!currentValue) {
            return (
              <Grid container alignItems={'center'} spacing={1}>
                <div className={classes.menu}></div>
              </Grid>
            );
          }
          return (
            <Grid container alignItems={'center'} spacing={1}>
              {tagsValue.length > 0 && (
                <Grid item>
                  <Chip
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : classes.tagItem
                    }
                    label={currentValue.label}
                    style={{ backgroundColor: currentValue.color }}
                    onClick={(event) => {
                      // setIsEditTagForm(true);
                      // setAnchorEl(ref.current);
                    }}
                  />
                </Grid>
              )}
            </Grid>
          );
        } else {
          if (!currentValue) {
            return (
              <Grid container alignItems={'center'} spacing={1}>
                <div className={classes.menu}>
                  <Button
                    size="small"
                    onClick={(event) => {
                      setIsEditTagForm(true);
                      setAnchorEl(ref.current);
                    }}
                    style={{ padding: 8 }}
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `eip_action_add_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : ''
                    }
                  >
                    <Icon type="plus" />
                  </Button>
                  {isEditTagForm && (
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <TagEditor {...propsComponent} />
                    </Popover>
                  )}
                </div>
              </Grid>
            );
          }
          return (
            <Grid container alignItems={'center'} spacing={1}>
              {tagsValue.length > 0 && (
                <Grid item>
                  <Chip
                    className={
                      ff.add_css_identifier_to_new_release_components
                        ? `${classes.tagItem} eip_tag_selected_${titleColumnClass} eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass}`
                        : classes.tagItem
                    }
                    label={currentValue.label}
                    style={{ backgroundColor: currentValue.color }}
                    onClick={(event) => {
                      setIsEditTagForm(true);
                      setAnchorEl(ref.current);
                    }}
                  />
                  {isEditTagForm && (
                    <Popover
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <TagEditor {...propsComponent} />
                    </Popover>
                  )}
                </Grid>
              )}
            </Grid>
          );
        }
      }
    };
  }

  if (ff.ui_ux_update_tag_item) {
    return (
      <WrapperFormat
        typeTable={typeTable}
        node={node}
        value={value}
        isGrouped={isGrouped}
        cellAction={currentValue && !nodeGroup ? cellAction : []}
        actionSubItem={actionSubItem}
      >
        <div ref={ref} style={{ width: '100%' }}>
          {renderTags()}
        </div>
      </WrapperFormat>
    );
  }
  return (
    <WrapperFormat typeTable={typeTable} node={node} value={value} isGrouped={isGrouped} cellAction={cellAction}>
      <div ref={ref} style={{ width: '100%' }}>
        {renderTags()}
      </div>
    </WrapperFormat>
  );
};
export default TagWithInlineEditor;
