import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const PlayButton = (props: SvgIconProps) => (
  <SvgIcon {...props} width="101" height="101" viewBox="0 0 101 101" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse cx="50.4383" cy="50.4359" rx="50.4383" ry="50.4359" fill="#F16145" />
    <path
      d="M67.6159 47.4806L59.2239 42.5012C57.3514 41.3862 54.2888 39.5701 52.4125 38.4596L43.7128 33.48C41.8409 32.3651 40 33.2594 40 35.4681V63.5347C40 65.7358 41.8409 66.6339 43.7135 65.5234L52.2594 60.544C54.1357 59.4335 57.2772 57.6174 59.1496 56.5024L67.5813 51.523C69.4493 50.4079 69.4847 48.5911 67.6159 47.4806Z"
      fill="white"
    />
  </SvgIcon>
);

export default PlayButton;
