import SelectFormSearchInside from '@ep/insight-ui/elements/form-control/select-form/select-form-search-inside';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
}));

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onLoad?: (value: any) => void;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const landingPageOptions: OptionSelectType[] = [
  {
    label: 'Main shop page',
    value: 'main-shop-page',
  },
  {
    label: 'Shop category page',
    value: 'shop-category-page',
  },
];

const LandingPageEditor = (props: Props) => {
  const { data, title = '', payload, onLoad, onSubmit, anchorEl, setAnchorEl } = props;
  const classes = useStyles();

  const [options, setOptions] = React.useState<OptionSelectType[]>([]);

  const [value, setValue] = React.useState({});

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const initOptions = async () => {
    if (onLoad) {
      const marketplaceField = get(payload, ['rowPicker', 'marketplaceCode']);
      const storefrontId = get(payload, ['rowPicker', 'storefrontId']);
      const res: any = await onLoad({
        marketplaceCode: get(data.node, ['data', marketplaceField], null),
        storefrontIds: [].concat(get(data.node, ['data', storefrontId])),
      });

      if (res.success) {
        const arr: OptionSelectType[] = res.data.map((item) => ({
          label: item.name,
          value: item.id,
        }));
        const selectedItem = arr.find((i) => i.value == data.value.id);
        setOptions(arr);
        setValue(selectedItem || arr[0]);
      }
    }
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const handleSubmit = async () => {
    const valueTmp = JSON.parse(JSON.stringify(data.value));

    valueTmp.label = value.label;
    valueTmp.value = value.value;
    setIsSubmitting(true);
    const response: any = await onSubmit({ value: valueTmp, row: data.node.data });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '304px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <div className={classes.block}>
          <div className={clsx(classes.form)}>
            <SelectFormSearchInside
              style={{ width: '100%' }}
              inputWidth={'100%'}
              searchAble={false}
              value={value}
              options={options}
              onChangeValue={(e) => setValue(e)}
            />
          </div>
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default LandingPageEditor;
