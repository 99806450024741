import { EIP_CONSTANT } from '@eip/next/lib/main';
import { CAMPAIGN_DETAIL_COMMON_MAPPING, getActionMenu } from '../../table-config/common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign detail',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
    GET_MARKET_STATUS_DETAIL: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'PRODUCT.id',
    // 'SUGGESTED_KEYWORD.id',
  ],
  viewSetting: [
    {
      id: 'all',
      name: 'All',
      excludeProperty: {
        dimension: ['storefront_ad_object'],
        metric: [],
        attribute: [],
      },
    },
  ],
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
    additionalFilters: [
      {
        name: 'Data status',
        id: 'data_status',
        dataType: 'string',
        propertyType: 'dimension',
        fields: ['ADS_CAMPAIGN.data_status', 'ADS_OBJECT.data_status'],
        options: [
          {
            label: 'Succeeded',
            value: '1',
          },
          {
            label: 'Failed',
            value: '2',
          },
        ],
        rules: [
          {
            value: '1',
            combinator: 'AND',
          },
          {
            value: '2',
            combinator: 'OR',
          },
        ],
        operators: [
          {
            label: 'Is',
            value: 'is',
          },
        ],
        isAdditionalFilter: true,
      },
    ],
  },
  disabledComponents: {
    dimension: ['marketplace', 'country', 'storefront', 'ad_tool', 'storefront_ad_object', 'ad_object'],
  },
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'dataSyncStatus',
          'marketplace',
          'country',
          'storefront',
          'ad_tool',
          'ad_object',
          'keyword',
          'campaign_tag',
          'ad_object_tag',
          'keyword_tag',
          'campaign_status',
          'ad_object_status',
          'ad_object_budget',
          'keyword_status',
          'keyword_method',
          'keyword_bidding_price',
          'keyword_match_type',
        ],
      }
    : {}),
  mapping: {
    dataSyncStatus: {
      title: 'Sync status',
      propertyType: 'dimension',
      cellFormat: 'dataSyncStatusFormat',
      dataType: 'string',
      filterField: '',
      sortField: '',
      valueGetter: {
        data_status: 'ADS_CAMPAIGN.data_status',
      },
      pinned: 'left',
    },
    // ---------------------------- Dimension ----------------------------------//
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        campaignName: 'ADS_CAMPAIGN.name',
        campaignCode: 'ADS_CAMPAIGN.campaign_code',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
        campaignDataStatus: 'ADS_CAMPAIGN.data_status',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
        ...(ff.groupby_display_location
          ? {
              id: 'ADTOOL.ads_tool',
              value: 'ADTOOL.ads_tool_name',
            }
          : {}),
      },
      tableConfiguration: null,
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT',
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        productIds: 'PRODUCT.product_sid',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        subtext: 'PRODUCT.product_sid',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        data_status: 'ADS_OBJECT.data_status',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },
    // ---------------------------- Attribute ----------------------------------//
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.delete_ad_campaign_object
      ? {
          campaign_status: {
            title: 'Campaign status',
            propertyType: 'attribute',
            cellFormat: 'status',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.status',
            valueGetter: {
              label: 'ADS_CAMPAIGN.status',
              status: 'ADS_CAMPAIGN.status',
              tooltips: 'ADS_CAMPAIGN.tooltips',
            },
            menu: getActionMenu('campaign_status'),
          },
        }
      : {}),
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'nextString',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'nextString',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    // ---------------------------- metric ----------------------------------//
    ...CAMPAIGN_DETAIL_COMMON_MAPPING,
  },
};
