import * as React from 'react';
import { usePage, useLog } from '@eip/next/lib/main';

const log = useLog('eo2:system-page');

export function SystemPage(props: {
  title: string;
  blockId: string;
  version: number;
  token: string;
  pageCategory: string;
  children: JSX.Element[];
}) {
  const { currentPage, setPageServer, getPageServer, updatePagePromise: updatePage, resetPageServer } = usePage();

  React.useEffect(() => {
    log('currentpage', currentPage);
    const content = props.children.map((c) => c.props.blockId);
    const layouts = {
      md: props.children.map((c) => {
        const { layout, blockId } = c.props;
        log('children c.props', c.props);
        return {
          id: blockId,
          layout: layout,
        };
      }),
      lg: props.children.map((c) => {
        const { layout, blockId } = c.props;
        log('children c.props', c.props);
        return {
          id: blockId,
          layout: layout,
        };
      }),
    };
    const nodes = props.children.map((c) => {
      const { blockId, blockType } = c.props;
      return {
        id: blockId,
        chartLibId: blockType,
        customAttributes: c.props.config,
      };
    });
    if (!currentPage) {
      setPageServer(getPageServer().systemPageServer);
      updatePage({
        title: props.title,
        table: 'eip_system_block',
        version: 1,
        blockEid: props.blockId,
        blockType: 'page',
        content: content,
        layouts: {
          md: props.children.map((c) => {
            const { layout, blockId } = c.props;
            log('children c.props', c.props);
            return {
              id: blockId,
              layout: layout,
            };
          }),
          lg: props.children.map((c) => {
            const { layout, blockId } = c.props;
            log('children c.props', c.props);
            return {
              id: blockId,
              layout: layout,
            };
          }),
        },
        nodes: props.children.map((c) => {
          const { blockId, blockType } = c.props;
          return {
            id: blockId,
            chartLibId: blockType,
            customAttributes: c.props.config,
          };
        }),
        parentId: '__parent_id__',
      }).then(() => {
        resetPageServer();
        window.location.reload();
      });
    } else if (currentPage && currentPage.properties.version < props.version) {
      log('version update');
      setPageServer(getPageServer().systemPageServer);
      updatePage({
        parentId: '__parent_id__',
        blockEid: props.blockId,
        title: props.title,
        table: 'eip_system_block',
        blockType: 'page',
        properties: { version: props.version, title: props.title },
        content,
        layouts,
        nodes,
      }).then(() => {
        resetPageServer();
        window.location.reload();
      });
    }
  }, [currentPage]);

  return null;
}

export function Block(props: {
  blockType: string;
  blockId: string;
  config: any;
  layout: { w: number; h: number; x: number; y: number };
}) {
  return null;
}
