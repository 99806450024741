import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import ButtonWithIcon from './button-with-icon';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    flex: '0 0 32px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    width: '100%',
  },
  linkContainer: {
    flex: '0 0 100%',
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    cursor: 'pointer',
    paddingLeft: '12px',
    paddingRight: '12px',
    height: '32px',
    '&:hover': {
      backgroundColor: '#E4E7E9',
      borderRadius: '4px',
    },
    '&.active': {
      backgroundColor: '#EBF6FF',
      borderRadius: '4px',
      '& span': {
        color: '#0369C7',
      },
    },
    '& span': {
      color: '#253746',
    },
    textDecoration: 'none',
  },
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  dropdownContainer: {
    position: 'absolute',
    top: 0,
    right: 0,
    zIndex: 10,
  },
}));

type RayListItemProps = {
  id: string;
  name: string;
  idWidth: number;
  favoriteFunctions: any;
};

const RayListItem = ({ id, name, idWidth, favoriteFunctions }: RayListItemProps) => {
  const classes = useStyles();
  const { serviceId, id: itemId } = useParams();

  const [isHovering, setIsHovering] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);

  const isFavorite = favoriteFunctions.checkIsFavorite(id);

  const actions = [
    {
      id: 'open_new_tab',
      title: 'Open new tab',
      icon: 'duplicate',
      onClick: () => {
        window.open(`/ray/${serviceId}/${id}`, '_blank');
      },
      disable: false,
    },
    {
      id: 'add_to_favorites',
      title: isFavorite ? 'Remove from favorites' : 'Add to favorites',
      icon: isFavorite ? 'ic/fluent:star-off-12-filled/#0000008a' : 'ic/fluent:star-12-filled/#0000008a',
      onClick: () => {
        favoriteFunctions.handleToggleFavorite(id);
      },
      disable: false,
    },
    {
      id: 'duplicate',
      title: 'Duplicate',
      icon: 'ic/heroicons:document-duplicate-20-solid/#0000008a',
      onClick: () => {
        window.open(`/ray/${serviceId}/0?ref=${id}`, '_blank');
      },
      disable: false,
    },
  ];

  const handleClose = () => {
    setOpenMenu(false);
    setIsHovering(false);
  };

  return (
    <div
      className={classes.container}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Link className={clsx(classes.linkContainer, id == itemId ? 'active' : '')} to={`/ray/${serviceId}/${id}`}>
        <span style={{ flex: `0 0 ${idWidth * 8}px` }}>{id}</span>
        <span>-</span>
        <span className={classes.name}>{name}</span>
      </Link>
      {isHovering ? (
        <div className={classes.dropdownContainer}>
          <Dropdown
            disabledToggleStyle
            alignMenu="right"
            label=""
            icon={'threeDotsVertical'}
            sizeIcon={'12px'}
            defaultOpen={openMenu}
            handleOnClosed={handleClose}
            disabledClickContentClose={false}
          >
            {actions.map((action) => {
              return (
                <ButtonWithIcon onClick={action.onClick} title={action.title} icon={action.icon} key={action.id} />
              );
            })}
          </Dropdown>
        </div>
      ) : null}
    </div>
  );
};

export default RayListItem;
