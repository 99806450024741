import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE } from '@ep/insight-ui/system/helper/constant';
import { makeStyles } from '@material-ui/core';
import * as _ from 'lodash';
import { get, isEqual, set } from 'lodash';
import * as React from 'react';
import FilterDialog, { FieldSelectedValue } from '../../inline-dialog/filter/filter-group-dialog';
import { IFilterItem } from '../../inline-dialog/filter/filter-group-dialog/filter-form-group';
import { filterOperators, getSelectedOperators } from '../table-helper';
import { NodeEditContext } from '@eip/next/lib/main';
type IProps = {
  marginLeft?: number;
};

const useStyles = makeStyles((theme) => ({
  containerButton: {
    '& .eip1-MuiTypography-body2': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
    },
  },
  FilterDialog: {
    maxWidth: 'calc(100vw - 43%)',
    // width: 'fit-content',
    minWidth: '432px',
    maxHeight: 'calc(100vh - 39%)',
    '& ul': {
      width: '100% !important',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'none',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'none',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '5px solid #FFF',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '4px solid #FFF',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  active: {
    color: `${colors.text.highLight}`,
    '&:hover': {
      color: `${colors.text.highLight}`,
      backgroundColor: `#CCE3F5`,
    },
    '&.eip1-MuiButton-active': {
      color: `white`,
    },
  },
  popover: {
    marginLeft: (props: IProps) => `-${props.marginLeft}px`,
  },
}));

function ButtonFilter({ marginLeft = 0 }) {
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const isLockedView = get(backboneContext.getConfig('view'), ['isLock'], false);
  const { isEditMode } = React.useContext(NodeEditContext);

  const visibility = backboneContext.getVisibility('filter');
  const options: Array<FieldSelectedValue> = backboneContext.getAvailableFilterColumns().map((item) => {
    return {
      label: item.name,
      value: item.id,
      dataType: item.dataType,
      selectedOperators: backboneContext.addon('selection.filter', () => getSelectedOperators(item.id))(
        item.id,
        getSelectedOperators(item.id),
      ),
      propertyType: item.propertyType,
      isAdditionalFilter: item.isAdditionalFilter,
      operators: [item.operators],
    };
  });

  const handleFilterData = () => {
    const filterData = backboneContext.getConfig('filter');
    const filterTemp: { field: string; value: string } = get(
      backboneContext,
      'getTempStorage',
      () => null,
    )('filterModal');

    if (filterTemp) {
      const defaultFilterItem: IFilterItem = {
        type: 'filter',
        logicalOperator: 'and',
        field: {
          label: '',
          value: filterTemp.field,
          dataType: 'string',
        },
        queryType: {
          label: '',
          value: '',
          dataType: 'string',
        },
        queryValue: {
          value: filterTemp.value,
        },
      };
      const field = options.find((item) => item.value == filterTemp.field);
      if (field) defaultFilterItem.field = field;
      const queryType = _.get(filterOperators, [defaultFilterItem.field.dataType, 0, 0], null);
      if (queryType) defaultFilterItem.queryType = queryType;
      filterData.push(defaultFilterItem);

      (backboneContext as any).updateTempStorage('filterModal', null);
    }
    return filterData;
  };

  const mapping = backboneContext.getConfig('mapping');
  const requiredValueFilterFields = React.useMemo(() => {
    return Object.keys(mapping).filter((key) => {
      return get(mapping, [key, 'staticValue', 'requiredFilter'], false);
    });
  }, [mapping]);

  const filterData = handleFilterData();

  const invoke = React.useMemo(() => {
    const handleClose = () => {
      backboneContext.changeVisibility('filter', false);
    };
    const handleChangeFilter = (model: IFilterItem[]) => {
      console.time('startChangeFilter');
      if (isEditMode && isLockedView) {
        const version = new Date().getTime();
        const view = backboneContext.getConfig('view');
        set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
        set(view, ['combinator', 'filter'], model);

        const views = backboneContext.getConfig('views');
        for (const v of views) {
          if (v.id === view.id) {
            set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
            set(v, ['combinator', 'filter'], model);
          }
        }

        backboneContext.updateConfig({
          filter: model,
          view,
          views,
        });
      } else {
        backboneContext.changeConfig('filter', model);
      }
    };

    return {
      changeConfig: backboneContext.changeConfig,
      getFilterOption: backboneContext.getFilterOptionValue,
      handleChangeFilter,
      handleFilterData,
      handleClose,
    };
  }, []);

  return (
    <ButtonFilterContent
      invoke={invoke}
      marginLeft={marginLeft}
      visibility={visibility}
      filterData={filterData}
      options={options}
      requiredValueFilterFields={requiredValueFilterFields}
    />
  );
}

const ButtonFilterContent = React.memo(
  function ButtonFilterContent({
    marginLeft,
    filterData,
    options,
    visibility,
    requiredValueFilterFields,
    invoke,
  }: any) {
    const classes = useStyles({ marginLeft });

    const { isEditMode } = React.useContext(NodeEditContext);

    const isVisibleFilter = visibility;

    const handleClose = invoke.handleClose;
    const handleChangeFilter = invoke.handleChangeFilter;
    const getOptionAvailable = invoke.getFilterOption;

    const filterDataLength = (filterData || []).filter((data) => (!data.isHidden && !isEditMode) || isEditMode).length;

    return (
      <div className={classes.containerButton}>
        <FilterDialog
          options={options}
          defaultOpen={isVisibleFilter}
          onClosed={handleClose}
          operators={filterOperators}
          filterModel={filterData}
          classNameButton={`${filterDataLength > 0 ? classes.active : ''}`}
          isButtonActive={filterDataLength > 0}
          className={`${classes.FilterDialog} eip_action_filter`}
          setFilterModel={handleChangeFilter}
          getOptionAvailable={getOptionAvailable}
          alignMenu="right"
          disabledClickContentClose
          enableFillerGroup
          disabledIconNoFilter
          requiredValueFilterFields={requiredValueFilterFields}
        />
      </div>
    );
  },
  (prevProps, nextProps) => {
    return [
      () => prevProps.marginLeft === nextProps.marginLeft,
      () => prevProps.visibility === nextProps.visibility,
      () => isEqual(prevProps.filterData, nextProps.filterData),
      () => isEqual(prevProps.options, nextProps.options),
      () => prevProps.requireValueFilterFields !== nextProps.requiredValueFilterFields,
    ].every((validate) => validate());
  },
);

export default ButtonFilter;
