import * as React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';

const useStyles = makeStyles({
  button: {
    height: '100%',
  },
  loadingIcon: {
    display: 'flex',
    alignItems: 'center',
  },
});
type GroupButtonSubmitProps = {
  onCancel: () => void;
  onSubmit: () => void;
  submitLabel?: string;
  disabled?: {
    cancel?: boolean;
    submit?: boolean;
  };
  title?: string;
  isSubmitting?: boolean;
  optionValue?: string;
};
const GroupButtonSubmit = ({
  onCancel,
  onSubmit,
  submitLabel = 'Apply now',
  disabled = { cancel: false, submit: false },
  title,
  isSubmitting,
  optionValue,
}: GroupButtonSubmitProps) => {
  const classes = useStyles();

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');
  return (
    <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{ marginTop: '15px' }}>
      {isSubmitting && (
        <Grid item className={classes.loadingIcon}>
          <CircleLoading size={'18px'} />
        </Grid>
      )}
      <Grid item>
        <Button disabled={disabled.cancel} className={classes.button} onClick={onCancel}>
          Cancel
        </Button>
      </Grid>
      <Grid item>
        <Button
          disabled={disabled.submit || isSubmitting}
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          suggest={optionValue == 'suggest-bid-price' ? 'true' : 'false'}
          onClick={() => {
            if (title == 'Ad object bidding price' || title == 'Keyword bidding price') {
              return uawl
                .start('canMassUpdateBiddingPrice', { title: titleTable, uaTarget: title, timerMs: 3000 })
                .then(() => onSubmit());
            }
            onSubmit();
          }}
        >
          {submitLabel}
        </Button>
      </Grid>
    </Grid>
  );
};
export default GroupButtonSubmit;
