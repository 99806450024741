import * as React from 'react';
import { Avatar, Box, Button, Dialog, Grid, makeStyles } from '@material-ui/core';
import UploadInput from '../../form-controls/upload-input';
import FormControlLabel from '../../form-controls/form-control-label';
import TextForm from '@ep/insight-ui/elements/form-control/text-form';
import AvatarProfile from '@ep/insight-ui/icons/svg/img/AvatarProfile.png';
import TimeZoneInput from '../../form-controls/time-zone/time-zone-input';
import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import clsx from 'clsx';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import HeaderList from '@ep/insight-ui/elements/table/header-list';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { Form, Formik } from 'formik';
import { listPasswordErrors, passwordSchema, validatorForSchema } from '../../../helper';
import GroupButtonSubmit from '@ep/insight-ui/elements/inline-edit/cell/group-button-submit';
import * as _ from 'lodash';
/**
 * ff.ui_my_profile_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { Modal } from '@material-ui/core';
/**
 * ff.ui_my_profile_mobile:end
 */

const useStylesFormGroup = makeStyles({
  properties: {
    marginBottom: '16px',
    '&:last-of-type': {
      marginBottom: 0,
    },
    '& .block:last-of-type': {
      marginBottom: 0,
    },
  },
  label: {
    fontStyle: ' normal',
    fontWeight: 400,
    fontSize: ' 14px',
    lineHeight: '20px',
    color: colors.text.default,
    ...(ff.ui_my_profile_mobile
      ? {
          '&.labelMobile': {
            fontSize: '16px',
            fontWeight: 500,
          },
        }
      : {}),
  },
  wrapLabel: {
    display: 'flex',
    height: '100%',
    ...(ff.ui_my_profile_mobile
      ? {
          '&.wrapLabelMobile': {
            width: '100%',
            height: 'auto',
          },
        }
      : {}),
  },
});
type FormGroupLabelProps = {
  label: string;
  children: JSX.Element;
  className?: string;
  wrapLabelPos?: 'flex-start' | 'center' | 'flex-end';
  labelPos?: 'flex-start' | 'center' | 'flex-end';
};
type ProfileFormProps = {
  userProfile: {
    userName: string;
    userEmail: string;
  };
  disabled?: {
    uploadPhoto: boolean;
    fulltName: boolean;
    firstName: boolean;
    lastName: boolean;
    email: boolean;
    region: boolean;
    privacy: boolean;
    password: boolean;
  };
  onChangePassword?: any;
};
const FormGroupLabel = ({ label, className = '', children, wrapLabelPos, labelPos }: FormGroupLabelProps) => {
  const classes = useStylesFormGroup();
  let isMobileView;
  if (ff.ui_my_profile_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }
  if (ff.ui_my_profile_mobile) {
    return (
      <Grid
        container
        className={`${classes.properties} ${className}`}
        alignItems={'flex-start'}
        justifyContent={'space-between'}
      >
        <Grid
          item
          alignItems={wrapLabelPos}
          className={clsx(classes.wrapLabel, {
            wrapLabelMobile: isMobileView,
          })}
        >
          <Box height="32px" display="flex" alignItems={labelPos}>
            <span
              className={clsx(classes.label, {
                labelMobile: isMobileView,
              })}
            >
              {label}
            </span>
          </Box>
        </Grid>
        <Grid item xs={isMobileView ? 12 : 9}>
          <div>{children}</div>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      className={`${classes.properties} ${className}`}
      alignItems={'flex-start'}
      justifyContent={'space-between'}
    >
      <Grid item alignItems={wrapLabelPos} className={clsx(classes.wrapLabel)}>
        <Box height="32px" display="flex" alignItems={labelPos}>
          <span className={classes.label}>{label}</span>
        </Box>
      </Grid>
      <Grid item xs={9}>
        <div>{children}</div>
      </Grid>
    </Grid>
  );
};

const useStyles = makeStyles({
  avatar: {
    height: '72px',
    width: '72px',
    marginRight: '8px',
    backgroundColor: '#ebf6ff',
    border: '1px solid #cce3f5',
  },
  inputText: {
    height: '32px',
  },
  ...(ff.ui_my_profile_mobile
    ? {
        textNote: {
          fontStyle: ' normal',
          fontWeight: 'normal',
          fontSize: ' 14px',
          lineHeight: '20px',
          color: '#979797',
          '&.textNoteMobile': {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: '#8C98A4',
          },
        },
      }
    : {
        textNote: {
          fontStyle: ' normal',
          fontWweight: 'normal',
          fontSize: ' 14px',
          lineHeight: '20px',
          color: '#979797',
        },
      }),
  block: {
    marginBottom: 8,
    gap: 8,
    flexWrap: 'nowrap',
    '&:last-type': {
      marginBottom: 0,
    },
  },
  button: {
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    ...(ff.ui_my_profile_mobile
      ? {
          '&.buttonMobile': {
            backgroundColor: '#F2F3F4',
            color: '#253746',
            boxShadow: 'none',
          },
        }
      : {}),
  },
  dialog: {
    marginTop: '18px',
  },
  header: {
    padding: 0,
    margin: 0,
  },
  ...(ff.ui_my_profile_mobile
    ? {
        modalContent: {
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: '#FFFFFF',
          boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
          padding: '0px',
          outline: 'none',
          overflowY: 'auto',
        },
        modalHeader: {
          position: 'relative',
          textAlign: 'center',
          padding: '12px',
          background: '#FFF',
          boxShadow: 'inset 0px -1px 0px #E5E5E5',
        },
        modalTitle: {
          fontSize: '16px',
          fontWeight: 500,
          lineHeight: '20px',
          margin: 0,
          color: '#253746',
        },
        modalButtonCancel: {
          position: 'absolute',
          top: '50%',
          left: '10px',
          transform: 'translate(0, -50%)',
          color: '#8C98A4',
        },
        contentMobile: {
          padding: '24px 32px 32px 32px',
        },
        buttonGroupMobile: {
          width: '100%',
        },
        buttonApplyMobile: {
          width: '100%',
          padding: '13px',
        },
      }
    : {}),
});
const defaultUserInfo = {
  userName: '',
  userEmail: '',
};
const defaultDisabled = {
  uploadPhoto: true,
  fulltName: true,
  email: true,
  region: true,
  privacy: true,
  password: true,
  ...(ff.ui_my_profile_mobile
    ? {
        firstName: true,
        lastName: true,
      }
    : {}),
};
const ProfileForm = ({
  userProfile = defaultUserInfo,
  disabled = defaultDisabled,
  onChangePassword,
}: ProfileFormProps) => {
  const [open, setOpen] = React.useState(false);
  let isMobileView, firstName, lastName;
  if (ff.ui_my_profile_mobile) {
    const nameGroup = userProfile.userName.split(' ');
    firstName = nameGroup[0];
    lastName = nameGroup[nameGroup.length - 1];
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }

  const handleClosed = () => {
    setOpen(false);
  };

  const classes = useStyles();
  if (ff.ui_my_profile_mobile) {
    return (
      <div>
        {/* ProfileForm */}
        <ExpansionForm
          label={'My account'}
          level={'1'}
          defaultExpanded={true}
          detailContentSize={'normal'}
          dataMenu={[[]]}
        >
          <Grid container>
            <FormGroupLabel label={'Account info'} labelPos={'center'}>
              <Grid container>
                {/* avatar upload */}
                <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                  <Grid item xs={isMobileView ? 12 : 6} container alignItems={'center'}>
                    <Box>
                      <Avatar className={classes.avatar}> </Avatar>
                    </Box>
                    <Box>
                      <UploadInput disabled={disabled.uploadPhoto} />
                    </Box>
                  </Grid>
                </Grid>
                {/* Full name */}
                {!isMobileView && (
                  <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                    <Grid item xs={isMobileView ? 12 : 6}>
                      <FormControlLabel label={'Full name'}>
                        <TextForm
                          fullWidthInput
                          disabled={disabled.fulltName}
                          value={userProfile.userName}
                          className={classes.inputText}
                          setValue={(value) => {
                            console.log('value', value);
                          }}
                        />
                      </FormControlLabel>
                    </Grid>
                    {/* Label */}
                    {/* <Grid item xs={6}>
                      <FormControlLabel label={'Last name'}>
                        <TextForm
                          fullWidthInput
                          disabled={disabled.lastName}
                          value=""
                          className={classes.inputText}
                          setValue={(value) => {
                            console.log('value', value);
                          }}
                        />
                      </FormControlLabel>
                    </Grid> */}
                  </Grid>
                )}

                {/* First name */}
                <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                  <Grid item xs={isMobileView ? 12 : 6}>
                    <FormControlLabel label={'First name'}>
                      <TextForm
                        fullWidthInput
                        disabled={disabled.firstName}
                        value={firstName}
                        className={classes.inputText}
                        setValue={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid>
                </Grid>
                {/* Last name */}
                <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                  <Grid item xs={isMobileView ? 12 : 6}>
                    <FormControlLabel label={'Last name'}>
                      <TextForm
                        fullWidthInput
                        disabled={disabled.lastName}
                        value={lastName}
                        className={classes.inputText}
                        setValue={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid>
                </Grid>

                <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                  {/* Email */}
                  <Grid item xs={isMobileView ? 12 : 6}>
                    <FormControlLabel label={'Email'}>
                      <TextForm
                        fullWidthInput
                        disabled={disabled.email}
                        value={userProfile.userEmail}
                        className={classes.inputText}
                        setValue={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid>
                  {/* username */}
                  {/* <Grid item xs={6}>
                    <FormControlLabel label={'username'}>
                      <TextForm
                        fullWidthInput
                        disabled={disabled.userName}
                        value={disabled.userName ? '' : 'Karen29641320'}
                        className={classes.inputText}
                        setValue={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid> */}
                </Grid>
              </Grid>
            </FormGroupLabel>

            {/* <FormGroupLabel label={'Region & Time'} wrapLabelPos={'flex-end'} labelPos={'flex-start'}>
              <Grid container>
                <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                  <Grid item xs={6}>
                    <FormControlLabel label={'Region'}>
                      <TextForm
                        fullWidthInput
                        disabled={disabled.region}
                        value={disabled.userName ? '' : 'Karen'}
                        className={classes.inputText}
                        setValue={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControlLabel label={'Timezone'}>
                      <TimeZoneInput
                        value={'+07:00-Asia/Ho_Chi_Minh'}
                        onChange={(value) => {
                          console.log('value', value);
                        }}
                      />
                    </FormControlLabel>
                  </Grid>
                </Grid>
              </Grid>
            </FormGroupLabel> */}

            <FormGroupLabel label={'Password'} wrapLabelPos={'center'} labelPos={'center'}>
              <Grid container className={clsx(classes.block, 'block')}>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    component="span"
                    className={clsx(classes.button, { buttonMobile: isMobileView })}
                    disabled={disabled.password}
                    onClick={() => setOpen(true)}
                  >
                    Change Password
                  </Button>
                  {!isMobileView ? (
                    <Dialog
                      // fullWidth
                      open={open}
                      onClose={() => setOpen(false)}
                      PaperProps={{
                        style: { width: '480px', margin: '0', padding: 16 },
                      }}
                    >
                      <HeaderList title={'Change password'} variant="h2" className={classes.header}></HeaderList>
                      <div className={classes.dialog}>
                        <Formik
                          initialValues={{ password: '' }}
                          onSubmit={async (values) => {
                            onChangePassword(values, () => setOpen(false));
                          }}
                          validate={validatorForSchema(passwordSchema)}
                        >
                          {({ values, handleChange, errors, touched, isValid, setFieldTouched }) => {
                            const isErrors = touched.password && (!values.password || !_.isEmpty(errors.password));

                            return (
                              <Form>
                                <Grid container wrap={'nowrap'}>
                                  <Grid item xs={12}>
                                    <TextFieldCustom
                                      disabled={disabled.password}
                                      placeholder={`Choose a strong password`}
                                      type={'password'}
                                      defaultValue={values.password}
                                      onChangeText={(v) => {
                                        if (v !== values.password && !touched['password']) setFieldTouched('password');
                                        handleChange('password')(v);
                                      }}
                                      helperText={listPasswordErrors}
                                      errorMessage={errors.password}
                                      alwaysShowHeperText
                                      multiHelperText
                                    />
                                  </Grid>
                                </Grid>
                                <GroupButtonSubmit
                                  onCancel={handleClosed}
                                  disabled={{ submit: !isValid }}
                                  onSubmit={() => undefined}
                                />
                              </Form>
                            );
                          }}
                        </Formik>
                      </div>
                    </Dialog>
                  ) : (
                    <Modal
                      open={open}
                      onClose={() => setOpen(false)}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box className={classes.modalContent}>
                        <div className={classes.modalHeader}>
                          <div className={classes.modalButtonCancel}>
                            <Button color="inherit" onClick={() => setOpen(false)}>
                              Cancel
                            </Button>
                          </div>
                          <h4 className={classes.modalTitle}>Change Password</h4>
                        </div>
                        <Grid className={classes.contentMobile}>
                          <div>
                            <Formik
                              initialValues={{ password: '' }}
                              onSubmit={async (values) => {
                                onChangePassword(values, () => setOpen(false));
                              }}
                              validate={validatorForSchema(passwordSchema)}
                            >
                              {({ values, handleChange, errors, touched, isValid, setFieldTouched }) => {
                                const isErrors = touched.password && (!values.password || !_.isEmpty(errors.password));

                                return (
                                  <Form>
                                    <Grid container wrap={'nowrap'}>
                                      <Grid item xs={12}>
                                        <TextFieldCustom
                                          disabled={disabled.password}
                                          placeholder={`Choose a strong password`}
                                          type={'password'}
                                          defaultValue={values.password}
                                          onChangeText={(v) => {
                                            if (v !== values.password && !touched['password'])
                                              setFieldTouched('password');
                                            handleChange('password')(v);
                                          }}
                                          helperText={listPasswordErrors}
                                          errorMessage={errors.password}
                                          alwaysShowHeperText
                                          multiHelperText
                                        />
                                      </Grid>
                                    </Grid>
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="flex-end"
                                      style={{ marginTop: '16px' }}
                                    >
                                      <Grid item className={classes.buttonGroupMobile}>
                                        <Button
                                          disabled={!isValid}
                                          className={classes.buttonApplyMobile}
                                          variant="contained"
                                          color="primary"
                                          type="submit"
                                          onClick={() => undefined}
                                        >
                                          Apply
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Form>
                                );
                              }}
                            </Formik>
                          </div>
                        </Grid>
                      </Box>
                    </Modal>
                  )}
                </Grid>
              </Grid>
            </FormGroupLabel>
            <FormGroupLabel label={'Privacy'} wrapLabelPos={'flex-start'} labelPos={'center'}>
              <>
                <Grid container className={clsx(classes.block, 'block')}>
                  <Grid item xs={12}>
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      disabled={disabled.privacy}
                      className={clsx(classes.button, { buttonMobile: isMobileView })}
                    >
                      Logout of all devices
                    </Button>
                  </Grid>
                </Grid>
                <Grid container className={clsx(classes.block, 'block')}>
                  <Grid item xs={12}>
                    <span
                      className={clsx(classes.textNote, {
                        textNoteMobile: isMobileView,
                      })}
                    >
                      You will be logged out of all other active sessions besides this one and will have to log back in.
                    </span>
                  </Grid>
                </Grid>
              </>
            </FormGroupLabel>
          </Grid>
        </ExpansionForm>
      </div>
    );
  }
  return (
    <div>
      {/* ProfileForm */}
      <ExpansionForm
        label={'My account'}
        level={'1'}
        defaultExpanded={true}
        detailContentSize={'normal'}
        dataMenu={[[]]}
      >
        <Grid container>
          <FormGroupLabel label={'Account info'} labelPos={'center'}>
            <Grid container>
              {/* avatar upload */}
              <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                <Grid item xs={6} container alignItems={'center'}>
                  <Box>
                    <Avatar className={classes.avatar}> </Avatar>
                  </Box>
                  <Box>
                    <UploadInput disabled={disabled.uploadPhoto} />
                  </Box>
                </Grid>
              </Grid>
              {/* First name */}
              <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                <Grid item xs={6}>
                  <FormControlLabel label={'Full name'}>
                    <TextForm
                      fullWidthInput
                      disabled={disabled.fulltName}
                      value={userProfile.userName}
                      className={classes.inputText}
                      setValue={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid>
                {/* Label */}
                {/* <Grid item xs={6}>
                  <FormControlLabel label={'Last name'}>
                    <TextForm
                      fullWidthInput
                      disabled={disabled.lastName}
                      value=""
                      className={classes.inputText}
                      setValue={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid> */}
              </Grid>

              <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                {/* Email */}
                <Grid item xs={6}>
                  <FormControlLabel label={'Email'}>
                    <TextForm
                      fullWidthInput
                      disabled={disabled.email}
                      value={userProfile.userEmail}
                      className={classes.inputText}
                      setValue={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid>
                {/* username */}
                {/* <Grid item xs={6}>
                  <FormControlLabel label={'username'}>
                    <TextForm
                      fullWidthInput
                      disabled={disabled.userName}
                      value={disabled.userName ? '' : 'Karen29641320'}
                      className={classes.inputText}
                      setValue={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid> */}
              </Grid>
            </Grid>
          </FormGroupLabel>

          {/* <FormGroupLabel label={'Region & Time'} wrapLabelPos={'flex-end'} labelPos={'flex-start'}>
            <Grid container>
              <Grid item xs={12} container className={clsx(classes.block, 'block')}>
                <Grid item xs={6}>
                  <FormControlLabel label={'Region'}>
                    <TextForm
                      fullWidthInput
                      disabled={disabled.region}
                      value={disabled.userName ? '' : 'Karen'}
                      className={classes.inputText}
                      setValue={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel label={'Timezone'}>
                    <TimeZoneInput
                      value={'+07:00-Asia/Ho_Chi_Minh'}
                      onChange={(value) => {
                        console.log('value', value);
                      }}
                    />
                  </FormControlLabel>
                </Grid>
              </Grid>
            </Grid>
          </FormGroupLabel> */}

          <FormGroupLabel label={'Password'} wrapLabelPos={'center'} labelPos={'center'}>
            <Grid container className={clsx(classes.block, 'block')}>
              <Grid item xs={12}>
                <Button
                  color="secondary"
                  variant="contained"
                  component="span"
                  className={classes.button}
                  disabled={disabled.password}
                  onClick={() => setOpen(true)}
                >
                  Change Password
                </Button>
                <Dialog
                  // fullWidth
                  open={open}
                  onClose={() => setOpen(false)}
                  PaperProps={{
                    style: { width: '480px', margin: '0', padding: 16 },
                  }}
                >
                  <HeaderList title={'Change password'} variant="h2" className={classes.header}></HeaderList>
                  <div className={classes.dialog}>
                    <Formik
                      initialValues={{ password: '' }}
                      onSubmit={async (values) => {
                        onChangePassword(values, () => setOpen(false));
                      }}
                      validate={validatorForSchema(passwordSchema)}
                    >
                      {({ values, handleChange, errors, touched, isValid, setFieldTouched }) => {
                        const isErrors = touched.password && (!values.password || !_.isEmpty(errors.password));

                        return (
                          <Form>
                            <Grid container wrap={'nowrap'}>
                              <Grid item xs={12}>
                                <TextFieldCustom
                                  disabled={disabled.password}
                                  placeholder={`Choose a strong password`}
                                  type={'password'}
                                  defaultValue={values.password}
                                  onChangeText={(v) => {
                                    if (v !== values.password && !touched['password']) setFieldTouched('password');
                                    handleChange('password')(v);
                                  }}
                                  helperText={listPasswordErrors}
                                  errorMessage={errors.password}
                                  alwaysShowHeperText
                                  multiHelperText
                                />
                              </Grid>
                            </Grid>
                            <GroupButtonSubmit
                              onCancel={handleClosed}
                              disabled={{ submit: !isValid }}
                              onSubmit={() => undefined}
                            />
                          </Form>
                        );
                      }}
                    </Formik>
                  </div>
                </Dialog>
              </Grid>
            </Grid>
          </FormGroupLabel>
          <FormGroupLabel label={'Privacy'} wrapLabelPos={'flex-start'} labelPos={'center'}>
            <>
              <Grid container className={clsx(classes.block, 'block')}>
                <Grid item xs={12}>
                  <Button
                    color="secondary"
                    variant="contained"
                    component="span"
                    disabled={disabled.privacy}
                    className={classes.button}
                  >
                    Logout of all devices
                  </Button>
                </Grid>
              </Grid>
              <Grid container className={clsx(classes.block, 'block')}>
                <Grid item xs={12}>
                  <span className={classes.textNote}>
                    You will be logged out of all other active sessions besides this one and will have to log back in.
                  </span>
                </Grid>
              </Grid>
            </>
          </FormGroupLabel>
        </Grid>
      </ExpansionForm>
    </div>
  );
};
export default ProfileForm;
