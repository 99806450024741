import * as React from 'react';

import { Grid, Box, makeStyles } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';

import Marketplace from '@ep/insight-ui/eo2/page/images/Marketplace';
import Companies from '@ep/insight-ui/eo2/page/images/companies.png';

const useExploreEpsiloStyles = makeStyles(() => ({
  container: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',
    padding: '70px 20% 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '100vh',
    backgroundColor: '#1A2731',
    color: '#FFF',
    '& h1': {
      fontSize: '32px',
    },
    '& h4': {
      fontSize: '16px',
    },
    '& h3': {
      fontSize: '18px',
    },
    '& *': {
      margin: 0,
      padding: 0,
    },
  },
  descriptionSection: {
    border: '1px solid #FFFFFF',
    padding: '27px 36px',
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    rowGap: '20px',
  },
  descriptionItem: {
    flex: '0 0 50%',
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '70%',
  },
  topCompanies: {
    marginTop: '8px',
  },
  svgControl: {
    '& svg': {
      width: '100%',
    },
  },
}));

const descriptionItems = [
  'Full Feature Free Trial',
  'Advertising optimisation',
  'Real-time reports',
  'Team collaboration',
];

const ExploreEpsilo = () => {
  const classes = useExploreEpsiloStyles();

  const descriptionItemRender = descriptionItems.map((item, index) => {
    return (
      <Box key={index} className={classes.descriptionItem}>
        <Icon type={'done'} colorIcon={'#FFF'} />
        <span>{item}</span>
      </Box>
    );
  });

  return (
    <Grid container className={classes.container}>
      <h4>Looking to learn more about Epsilo?</h4>
      <h1>Grow your ecommerce business with Epsilo</h1>
      <Box className={classes.svgControl}>
        <Marketplace />
      </Box>
      <Box className={classes.descriptionSection}>{descriptionItemRender}</Box>
      <Box className={classes.footer}>
        <h3>Top companies in the world trust Epsilo</h3>
        <Box className={classes.topCompanies}>
          <img src={Companies} />
        </Box>
      </Box>
    </Grid>
  );
};

export default ExploreEpsilo;
