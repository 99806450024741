import { withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import * as React from 'react';

export const SwitchIcon = withStyles((theme) => ({
  root: {
    width: 30,
    height: '100%',
    padding: 0,
    margin: '0 8px',
    '& .eip1-MuiSwitch-switchBase': {
      transform: 'translateX(2px)',
      top: 1,
    },
    '& .eip1-MuiIconButton-root:hover': {
      background: '#fff',
    },
  },
  switchBase: {
    borderRadius: '50%',
    padding: 1,
    '&$checked': {
      top: 1,
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#52d869',
        opacity: 1,
        border: 'none',
      },
    },
  },

  switchBaseCustom: {
    borderRadius: '50%',
    padding: 1,
    '&$checked': {
      top: 1,
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#006EC6',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
  },
  track: {
    top: 1,
    width: 30,
    height: 16,
    borderRadius: 26 / 2,
    border: 'none',
    backgroundColor: '#C2C7CB',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: props.isCustom ? classes.switchBaseCustom : classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
