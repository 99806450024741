import * as React from 'react';
import { makeStyles, Button } from '@material-ui/core';

import { useEditor, EditorContent, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Placeholder from '@tiptap/extension-placeholder';
import Mention from '@tiptap/extension-mention';
import suggestion from './suggestion';

import Icon from '@ep/insight-ui/icons/Icon';

export default {
  title: 'Elements / Text Editor',
};

const useStyles = makeStyles({
  textEditor: {
    width: '100%',
    '& .ProseMirror': {
      outline: 'none',
      padding: '3px 8px',
    },
    '& .ProseMirror p.is-editor-empty:first-child::before': {
      color: '#adb5bd',
      content: 'attr(data-placeholder)',
      float: 'left',
      height: 0,
      pointerEvents: 'none',
    },
    '& .ProseMirror p:first-child': {
      marginTop: 0,
    },
    '& .ProseMirror ul': {
      listStyle: 'disc',
      marginBlockStart: '1em',
      marginBlockEnd: '1em',
      paddingInlineStart: '40px',
    },
  },
  textAction: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      background: 'none',
      outline: 'none',
      border: 'none',
      cursor: 'pointer',
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '32px',
      width: '32px',
      marginRight: '1px',
      borderRadius: '2px',
    },
    '& button:hover': {
      background: 'rgba(var(--sk_foreground_low,29,28,29),.13)',
    },
    '& button.is-active': {
      color: 'rgba(var(--sk_primary_foreground,29,28,29),1)',
      background: 'rgba(var(--sk_primary_foreground,29,28,29),.2)',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  formButton: {
    height: '32px',
  },
});

const MenuBar = ({ editor }: { editor: Editor }) => {
  if (!editor) {
    return null;
  }
  const classes = useStyles();

  return (
    <div className={classes.textAction}>
      <button
        onClick={() => editor.chain().focus().toggleUnderline().run()}
        className={editor.isActive('underline') ? 'is-active' : ''}
      >
        <Icon type={'underline'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={editor.isActive('italic') ? 'is-active' : ''}
      >
        <Icon type={'italic'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={editor.isActive('bold') ? 'is-active' : ''}
      >
        <Icon type={'bold'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleStrike().run()}
        className={editor.isActive('strike') ? 'is-active' : ''}
      >
        <Icon type={'strikethrough'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={editor.isActive('bulletList') ? 'is-active' : ''}
      >
        <Icon type={'properties'} colorIcon={'#253746'} />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleCode().run()}
        className={editor.isActive('code') ? 'is-active' : ''}
      >
        <Icon type={'code'} colorIcon={'#253746'} />
      </button>
    </div>
  );
};

interface IPropTextEditor {
  placeholder?: string;
  label?: string;
  btnAction?: any;
}

export const TextEditorStories = ({
  placeholder = 'Write something...',
  label = 'Submit',
  btnAction,
}: IPropTextEditor) => {
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Placeholder.configure({
        placeholder: placeholder,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
        renderLabel({ node }) {
          return `${node.attrs.label ?? node.attrs.id}`;
        },
      }),
    ],
    content: ``,
  });
  const classes = useStyles();

  return (
    <div className={classes.textEditor}>
      <EditorContent editor={editor} />
      <div className={classes.actions}>
        <MenuBar editor={editor} />
        <Button
          variant="contained"
          color="primary"
          className={classes.formButton}
          onClick={() => {
            btnAction(editor.getHTML());
          }}
        >
          {label}
        </Button>
      </div>
    </div>
  );
};
