import { requestEtableConfig } from '@ep/insight-ui/system/backbone/data-source/common';

import CompactFormat from '../cell-format/compact-format';
import CalculateCellFormat from '../cell-format/calculate-cell-format';
import { CellEditorInputFormat, CellEditorViewFormat } from '../cell-format/input-format';
import ResyncConfigFormat from '../cell-format/resync-config-format';
import { isFormulaField } from '@ep/insight-ui/sw/util/excel-formula';

import {
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_ETABLE_SELECTED,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_METRICS,
  INPUT_TYPE_VIEW,
  DEFAULT_CONFIG_ETABLE_SELECTION,
  INHERIT_FROM_COMPACT,
  CELL_FORMAT,
  COMPACT_VALUE_GETTER,
  COMPACT_STATIC_VALUE,
  MAX_WIDTH,
  INPUT_TYPE_FIELD_VALUE,
  INPUT_TYPE_FIELD_FILTER,
  COMPACT_PAGINATION_LIMIT,
} from './constant';
import SelectFormat from '../cell-format/select-format';
import { get, uniq } from 'lodash';
import { withWrapperFormat } from '../cell-format/wrapper-format';

const getApiMappingOptions = (etableConfigContext) => {
  return uniq(
    Object.values<any>(get(etableConfigContext, 'mapping', {})).reduce(
      (a, b) => {
        return [...a, ...(Object.values(b.valueGetter || {}).filter((el) => !!el && !isFormulaField(el)) || [])];
      },
      ['currency'],
    ),
  );
};

export const getAdvancedFilterColDef = (etableConfigContext, etableList, cellFormatOptions) => {
  const selectFromETableOption = [
    {
      label: 'eTable select',
      value: INPUT_TYPE_ETABLE_SELECTED,
      description: 'display field to select available eTable',
    },
    {
      label: 'eTable config',
      value: INPUT_TYPE_ETABLE_CONFIG,
      description: 'display config to select available eTable',
    },
    {
      label: 'View',
      value: INPUT_TYPE_VIEW,
      description: 'display config to select available eTable',
    },
    {
      label: 'Label',
      value: INPUT_TYPE_FIELD_LABEL,
    },
    {
      label: 'Value',
      value: INPUT_TYPE_FIELD_VALUE,
      description: 'display config to select available eTable',
    },
    {
      label: 'Filter Field',
      value: INPUT_TYPE_FIELD_FILTER,
      description: 'display config to select available eTable',
    },
    {
      label: 'Pagination limit',
      value: COMPACT_PAGINATION_LIMIT,
      description: 'display config to select available eTable',
    },
    {
      label: 'Metrics',
      value: INPUT_TYPE_METRICS,
      description: 'display config to select available eTable',
    },
  ];

  const etableFieldSelection = [
    INPUT_TYPE_ETABLE_CONFIG,
    INPUT_TYPE_VIEW,
    INPUT_TYPE_FIELD_LABEL,
    INPUT_TYPE_FIELD_VALUE,
    INPUT_TYPE_FIELD_FILTER,
  ];

  const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map((i) => ({
    value: i,
    label: i,
  }));

  return {
    field: 'advancedFilter',
    headerName: 'Advanced filter',
    editable: true,
    cellRendererSelector(params) {
      if (params.node.rowPinned) {
        return {
          frameworkComponent: CalculateCellFormat,
        };
      }
      return {
        component: withWrapperFormat(CompactFormat),
        params: {
          isCustom: true,
          config: {
            colDefs: [
              {
                field: 'key',
                headerName: 'Key',
                width: 100,
                cellRenderer: SelectFormat,
                cellRendererParams: {
                  options: selectFromETableOption,
                  hasSearch: true,
                  field: 'key',
                  allowCustomOption: true,
                },
                maxWidth: 150,
                editable: true,
              },
              {
                field: 'value',
                headerName: 'Value',
                width: 100,
                cellRendererSelector(valueParams) {
                  if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                    return {
                      component: SelectFormat,
                      params: {
                        options: etableList,
                        hasSearch: true,
                        field: 'value',
                        allowCustomOption: true,
                        allowedRemove: true,
                      },
                    };
                  }
                  const etableConfig = (
                    valueParams.node.parent.allLeafChildren.find((el) => el.data?.key === INPUT_TYPE_ETABLE_CONFIG) || {
                      data: { value: {} },
                    }
                  ).data?.value;

                  const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce((a, b) => {
                    const valueGetter = Object.values(get(b, 'valueGetter', {}));
                    return [...a, ...valueGetter];
                  }, []);
                  mapping.sort();
                  const uniqMapping = uniq(mapping);
                  const filteredMapping = uniqMapping.filter((el) => !!el);

                  if (etableFieldSelection.includes(valueParams.data.key)) {
                    if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                      return {
                        component: ResyncConfigFormat,
                      };
                    }

                    if (valueParams.data.key === INPUT_TYPE_VIEW) {
                      const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                        label: name,
                        value: id,
                      }));

                      return {
                        component: SelectFormat,
                        params: {
                          options: viewsOptions,
                          hasSearch: true,
                          field: 'value',
                          allowCustomOption: true,
                          allowedRemove: true,
                        },
                      };
                    }
                    return {
                      component: SelectFormat,
                      params: {
                        options: filteredMapping,
                        hasSearch: true,
                        field: 'value',
                        allowCustomOption: true,
                        allowedRemove: true,
                      },
                    };
                  }
                  if (valueParams.data.key === INPUT_TYPE_METRICS) {
                    return {
                      component: CompactFormat,
                      params: {
                        isCustom: true,
                        allowDragRow: true,
                        config: {
                          colDefs: [
                            {
                              field: CELL_FORMAT,
                              headerName: 'Cell Format',
                              width: 100,
                              cellRenderer: SelectFormat,
                              cellRendererParams: {
                                options: [{ value: INHERIT_FROM_COMPACT, label: 'Inherit from compact' }].concat(
                                  cellFormatOptions,
                                ),
                                hasSearch: true,
                                field: CELL_FORMAT,
                                allowCustomOption: true,
                              },
                              onCellValueChanged(params) {
                                params.api.redrawRows({
                                  rowNodes: params.node.parent.allLeafChildren,
                                });
                              },
                              maxWidth: 150,
                              editable: true,
                            },
                            {
                              field: COMPACT_VALUE_GETTER,
                              headerName: 'Data point mapping',
                              width: 100,
                              cellRendererSelector(params) {
                                if (params.data[CELL_FORMAT] === INHERIT_FROM_COMPACT) {
                                  const compactColumns = Object.entries(get(etableConfig, ['mapping'], {})).map(
                                    ([key, { title }]) => ({
                                      label: title,
                                      value: key,
                                    }),
                                  );
                                  return {
                                    component: SelectFormat,
                                    params: {
                                      options: compactColumns,
                                      hasSearch: true,
                                      field: COMPACT_VALUE_GETTER,
                                    },
                                  };
                                }

                                return {
                                  component: CompactFormat,
                                  params: {
                                    isCustom: true,
                                    config: {
                                      colDefs: [
                                        {
                                          field: 'key',
                                          maxWidth: 179,
                                          onCellValueChanged(params) {
                                            params.api.redrawRows({
                                              rowNodes: [params.node],
                                            });
                                          },
                                          editable: true,
                                          cellRenderer: CellEditorViewFormat,
                                          cellEditor: CellEditorInputFormat,
                                        },
                                        {
                                          field: 'value',
                                          headerName: 'Value',
                                          width: 100,
                                          cellRenderer: SelectFormat,
                                          cellRendererParams: {
                                            options: filteredMapping,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                          },
                                          editable: true,
                                        },
                                      ],
                                    },
                                  },
                                };
                              },
                              editable: true,
                            },
                            {
                              field: COMPACT_STATIC_VALUE,
                              headerName: 'Column settings',
                              width: 100,
                              cellRenderer: CompactFormat,
                              cellRendererParams: {
                                isCustom: true,
                                config: {
                                  colDefs: [
                                    {
                                      field: 'key',
                                      maxWidth: 179,
                                      onCellValueChanged(params) {
                                        params.api.redrawRows({
                                          rowNodes: [params.node],
                                        });
                                      },
                                      editable: true,
                                      cellRenderer: CellEditorViewFormat,
                                      cellEditor: CellEditorInputFormat,
                                    },
                                    {
                                      field: 'value',
                                      headerName: 'Value',
                                      width: 100,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: filteredMapping,
                                        hasSearch: true,
                                        field: 'value',
                                        allowCustomOption: true,
                                      },
                                      editable: true,
                                    },
                                  ],
                                },
                              },
                              editable: true,
                            },
                            {
                              field: MAX_WIDTH,
                              headerName: 'Max Width',
                              width: 100,
                              cellRenderer: CellEditorViewFormat,
                              cellEditor: CellEditorInputFormat,
                            },
                          ],
                          getDefaultRowData() {
                            return {
                              [CELL_FORMAT]: INHERIT_FROM_COMPACT,
                              [COMPACT_VALUE_GETTER]: '',
                              [COMPACT_STATIC_VALUE]: '',
                              [MAX_WIDTH]: '100px',
                            };
                          },
                          addNewFromIndex: 9999999,
                        },
                      },
                    };
                  }
                  if (valueParams.data.key === COMPACT_PAGINATION_LIMIT) {
                    return {
                      component: CellEditorViewFormat,
                    };
                  }
                  return {
                    component: SelectFormat,
                    params: {
                      options: sourceFieldViewOptions,
                      hasSearch: true,
                      field: 'value',
                      allowCustomOption: true,
                      allowedRemove: true,
                    },
                  };
                },
                onCellValueChanged(params) {
                  if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                    const blockEid = params.data.value;
                    requestEtableConfig(blockEid).then((res) => {
                      const eTableConfigNode = params.node.parent.allLeafChildren.find(
                        (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                      );
                      if (eTableConfigNode) {
                        if (res.success) {
                          eTableConfigNode.setDataValue('value', res.data);
                        } else if (!eTableConfigNode.data?.blockEid) {
                          eTableConfigNode.setDataValue('value', {
                            ...DEFAULT_CONFIG_ETABLE_SELECTION,
                            blockEid,
                          });
                        }
                      }
                      const eTableConfigView = params.node.parent.allLeafChildren.find(
                        (el) => el.data.key === INPUT_TYPE_VIEW,
                      );
                      if (eTableConfigView) {
                        if (res.success) {
                          eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                        }
                      }
                    });
                  }
                  const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                    return params.data.key === INPUT_TYPE_ETABLE_SELECTED || data?.key != INPUT_TYPE_ETABLE_CONFIG;
                  });
                  params.api.redrawRows({
                    rowNodes: redrawRows,
                  });
                },
                editable: true,
              },
            ],
            getDefaultRowData() {
              return {
                key: '',
                value: '',
              };
            },
            getDefaultData() {
              return [
                {
                  key: INPUT_TYPE_ETABLE_SELECTED,
                  value: '',
                },
                {
                  key: INPUT_TYPE_ETABLE_CONFIG,
                  value: '',
                },
                {
                  key: INPUT_TYPE_VIEW,
                  value: '',
                },
                {
                  key: INPUT_TYPE_FIELD_LABEL,
                  value: '',
                },
                {
                  key: INPUT_TYPE_FIELD_VALUE,
                  value: '',
                },
                {
                  key: INPUT_TYPE_FIELD_FILTER,
                  value: '',
                },
                {
                  key: COMPACT_PAGINATION_LIMIT,
                  value: '999',
                },
                {
                  key: INPUT_TYPE_METRICS,
                  value: '',
                },
              ];
            },
            addNewFromIndex: 9999999,
            hideDefaultActions: true,
          },
        },
      };
    },
    minWidth: 150,
  };
};
