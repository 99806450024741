import * as React from 'react';
import GlobalNavigation from './global-navigation';
import { makeStyles, Typography, Modal, Box, Button } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import SideBar from './side-bar';
import { TypeTabListItem, TabList } from './tab-list-menu';
import { TypeBreadcrumbs } from './bread-crumbs';
import { TypeUserAvatar } from './group-avatar';
import clsx from 'clsx';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import { IOnClickItem } from './simple-tab';
import { AuthContext, ContainerResponsiveContext, eipRequest as request } from '@eip/next/lib/main';
/**
 * ff.sync_correct_pagename_breadcrumb:start
 */
import { BreadcrumbsComponent } from './bread-crumbs';
/**
 * ff.sync_correct_pagename_breadcrumb:end
 */

/**
 *
 */
import { useAppMenu } from './hooks/use-app-menu';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { aim } from '@eip/next/lib/main';
import { isSandboxMode } from '@ep/insight-ui/eo2/global';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';
/**
 *
 */

type StyleProp = {
  drawerWidth: number;
};
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  mobileRootContent: {
    '& > .eip1-MuiBox-root': {
      marginLeft: '0',
      padding: '0 16px',
      width: '100%',
    },
    '& .app-controls .page-title': {
      minWidth: '0',
    },
    '& .eip1-MuiBox-root.body-container': {
      width: 'calc(100vw - 41px)',
    },
    // Todo: For demo, need to change in lib
    '& .dashboard-grid-layout, & .ep-dashboard-item': {
      width: '100% !important',
    },
  },
  mobileHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
  },
  mobileHeaderScroll: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px',
    width: '100%',
    position: 'fixed',
    background: '#fff',
    zIndex: '100',
  },
  headerRight: {
    display: 'flex',
    padding: '10px',
  },
  headerIcon: {
    marginLeft: '24px',
  },
  headerLeft: {
    display: 'flex',
  },
  headerTitle: {
    '& span': {
      color: '#253746',
    },
    marginLeft: '12px',
  },
  content: ({ drawerWidth }: StyleProp) => ({
    flexGrow: 1,
    // padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
  }),
  contentShift: ({ drawerWidth }: StyleProp) => ({
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
  }),
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  wrapper: {
    paddingLeft: '27px',
  },
  wrapperClose: {
    paddingLeft: '37px',
  },
  sandboxBar: {
    background: '#8C98A4',
    position: 'fixed',
    width: '100%',
    bottom: '0',
    left: '0',
    height: '24px',
    fontSize: '0.8em',
    paddingLeft: '30px!important',
    textAlign: 'center',
    lineHeight: '24px',
    color: '#fff',
  },
}));

const useStylesModal = makeStyles(() => ({
  modalContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
  },
  modalHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  modalGroupItems: {
    marginTop: '16px',
  },
  groupItem: {
    background: '#FFF',
    '&:first-child': {
      borderTopLeftRadius: '8px',
      borderTopRightRadius: '8px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '8px',
      borderBottomRightRadius: '8px',
    },
    '& button': {
      justifyContent: 'flex-start',
      width: '100%',
      fontWeight: 400,
      padding: '16px 18px',
      '&.eip1-MuiButton-text:hover': {
        backgroundColor: 'transparent',
      },
      '&.eip1-MuiButton-text:active': {
        backgroundColor: '#006EC6',
      },
    },
    '& button .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      padding: 0,
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .disabled': {
      color: '#C2C7CB',
    },
  },
  groupItemTitle: {
    fontWeight: 400,
  },
  modalTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  modalButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  modalButtonCancel: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
    color: '#8C98A4',
  },
  buttonIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '12px',
  },
  leftItem: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    '& svg': {
      width: 13,
      height: 13,
    },
  },
  subText: {
    fontSize: '12px',
    lineHeight: '16px',
    color: '#8C98A4',
    fontWeight: 400,
  },
}));
const drawerWidth_const = 250;
type AppBarMenuProps = {
  userEmail: string;
  children?: React.ReactNode;
  dataSideBarHead?: Array<TypeTabListItem>;
  dataListMenu?: Array<TypeTabListItem>;
  dataListUser?: Array<TypeUserAvatar>;
  dataBreadcrumbs?: Array<TypeBreadcrumbs>;
  dataMenuTopBar?: Array<DropdownMenuDataType[]>;
  dataMenuFooter?: Array<DropdownMenuDataType[]>;
  dataMenuPage?: Array<DropdownMenuDataType[]>;
  headerLimitUsers?: number;
  onMenuItemClick: IOnClickItem;
  enabledPaddingChild?: boolean;
  isFullPage: boolean;
  aboutData?: { version: string; updateTime: string };
};

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const MenuItemComponent = ({ disable, icon, title, onClick, subText, ...rest }: DropdownMenuDataType) => {
  const classes = useStylesModal();
  return (
    <div className={classes.groupItem}>
      <Button disabled={disable} onClick={onClick} className={disable ? 'disabled' : ''}>
        <div className={classes.leftItem}>
          <div className={classes.buttonIcon}>
            <Icon type={icon} width={'12px'} height={'12px'} />
          </div>
          <span className={classes.groupItemTitle}>{title === '' || title === undefined ? rest.name : title}</span>
        </div>
        <div>
          <span className={classes.subText}>{subText}</span>
        </div>
      </Button>
    </div>
  );
};

export const MobilePropertiesModal = ({ dataMenuPage, setIsPropertiesModalOpen, title = 'Actions' }: any) => {
  const classes = useStylesModal();
  let editorComponent, setEditorComponent, Component;
  if (ff.mobile_interaction_zone) {
    [editorComponent, setEditorComponent] = React.useState(null);

    Component = React.useMemo(() => {
      if (editorComponent) return editorComponent.component;
      return () => undefined;
    }, [editorComponent]);
  }

  const handleDone = () => {
    setIsPropertiesModalOpen(false);
  };

  const handleCancel = () => {
    setIsPropertiesModalOpen(false);
  };

  const firstGroup =
    dataMenuPage &&
    dataMenuPage[0] &&
    dataMenuPage[0]
      .filter((el) => !['Delete', 'Export'].includes(el.title))
      .map((el, index) => (
        <MenuItemComponent
          key={index}
          {...{
            ...el,
            onClick() {
              if (ff.mobile_interaction_zone) {
                if (el.component) {
                  setEditorComponent(el);
                } else {
                  el.onClick();
                  setIsPropertiesModalOpen(false);
                }
              } else {
                el.onClick();
                setIsPropertiesModalOpen(false);
              }
            },
          }}
        />
      ));

  const secondGroup =
    dataMenuPage &&
    dataMenuPage[0] &&
    dataMenuPage[0]
      .filter((el) => ['Delete', 'Export'].includes(el.title))
      .map((el, index) => (
        <MenuItemComponent
          key={index}
          {...{
            ...el,
            onClick() {
              if (ff.mobile_interaction_zone) {
                if (el.component) {
                  setEditorComponent(el);
                } else {
                  el.onClick();
                  setIsPropertiesModalOpen(false);
                }
              } else {
                el.onClick();
                setIsPropertiesModalOpen(false);
              }
            },
          }}
        />
      ));

  const otherGroups =
    dataMenuPage && dataMenuPage.length > 1
      ? dataMenuPage.slice(1).map((el, index) => {
          const groupComponent = el.map((ele, index) => (
            <MenuItemComponent
              key={index}
              {...{
                ...ele,
                onClick() {
                  if (ff.mobile_interaction_zone) {
                    if (ele.component) {
                      setEditorComponent(ele);
                    } else {
                      ele.onClick();
                      setIsPropertiesModalOpen(false);
                    }
                  } else {
                    ele.onClick();
                    setIsPropertiesModalOpen(false);
                  }
                },
              }}
            />
          ));
          return (
            <div key={index} className={classes.modalGroupItems}>
              {groupComponent}
            </div>
          );
        })
      : null;

  return (
    <Box className={classes.modalContent}>
      <div className={classes.modalHeader}>
        <div className={classes.modalButtonCancel}>
          <Button color="inherit" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
        <h4 className={classes.modalTitle}>{ff.mobile_interaction_zone ? title : 'Actions'}</h4>
        <div className={classes.modalButton}>
          <Button color="primary" onClick={handleDone}>
            Done
          </Button>
        </div>
      </div>
      {ff.mobile_interaction_zone ? (
        editorComponent && Component ? (
          <Component {...editorComponent} />
        ) : (
          <>
            <div className={classes.modalGroupItems}>{firstGroup}</div>
            <div className={classes.modalGroupItems}>{secondGroup}</div>
            {otherGroups}
          </>
        )
      ) : (
        <>
          <div className={classes.modalGroupItems}>{firstGroup}</div>
          <div className={classes.modalGroupItems}>{secondGroup}</div>
          {otherGroups}
        </>
      )}
    </Box>
  );
};
export const OPEN_SIDEBAR = 'opensidebar';

const API_GET_SYSTEM_PREFERENCES = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/programmatic/get-system-preferences';

const AppBarMenu = ({
  userEmail,
  dataBreadcrumbs,
  dataSideBarHead = [],
  dataListMenu = [],
  dataListUser = [],
  dataMenuTopBar = [],
  dataMenuFooter = [],
  dataMenuPage = [],
  headerLimitUsers = 5,
  enabledPaddingChild = false,
  children,
  onMenuItemClick,
  aboutData = { updateTime: '', version: '' },
  isFullPage = false,
}: AppBarMenuProps) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const [systemMode, setSystemMode] = React.useState('bau_mode');
  const defaultOpenSideBar = localStorage.getItem(OPEN_SIDEBAR);
  const isDefaultOpenSideBar = JSON.parse(defaultOpenSideBar);
  const [openSideBar, setOpenSideBar] = React.useState<boolean>(defaultOpenSideBar ? isDefaultOpenSideBar : true);
  const classes = useStyles({ drawerWidth: drawerWidth_const });
  const [openSidebarModile, setOpenSidebarModile] = React.useState(false);
  const [isPropertiesModalOpen, setIsPropertiesModalOpen] = React.useState(false);

  const {
    appMenuList,
    updateSidebar,
    appMenuListRef,
    addNewSidebarSection,
    deleteSidebarSection,
    setAppMenuList,
    cloneFeature,
    flatAppMenuList,
    favorites,
    favoritesRef,
    updateFavorites,
    favoritesMenuList,
    additionalBlockInfo,
    sandboxPageInformation,
    customActions,
  } = useAppMenu(dataListMenu);

  const { currentWorkspaceDetail } = React.useContext(AuthContext);
  const [homepageId, setHomepageId] = React.useState(() => {
    return String(get(currentWorkspaceDetail, ['homepage'], ''));
  });
  const enhancedDataSideBarHead = React.useMemo(() => {
    const flattedMenuList = flatAppMenuList(appMenuList);
    const homepage = flattedMenuList.find((menu) => menu.nodeId === homepageId);
    return dataSideBarHead.map((el, index) => {
      if (index === 0)
        return {
          ...el,
          tabs: el.tabs.concat(
            homepage
              ? {
                  ...homepage,
                  icon: 'ic/bi:house-fill/#0000008a',
                  tabs: [],
                }
              : [],
          ),
        };
      return el;
    });
  }, [dataSideBarHead, appMenuList, homepageId]);

  let titleBreadcrumbs, setTitleBreadcrumbs;
  if (ff.sync_correct_pagename_breadcrumb) {
    [titleBreadcrumbs, setTitleBreadcrumbs] = React.useState('Company management');
  }

  const handleMenuClick = (evt, tab) => {
    setOpenSidebarModile(false);
    onMenuItemClick(evt, tab);
    if (ff.sync_correct_pagename_breadcrumb) {
      setTitleBreadcrumbs(tab.label);
    }
  };

  const [scrollMobile, setscrollMobile] = React.useState(false);

  React.useEffect(() => {
    if (String(userEmail).endsWith('@epsilo.io')) {
      const timeout = setTimeout(() => {
        request.get(API_GET_SYSTEM_PREFERENCES).then((res) => {
          if (res?.success) {
            setSystemMode(res.mode);
          }
        });
      }, DELAY_REQUEST_ADDITIONAL_DATA);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [userEmail]);

  React.useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;

      currentScrollY > 90 ? setscrollMobile(true) : setscrollMobile(false);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollMobile]);

  if (isFullPage) return <>{children}</>;

  return (
    <div className={!isMobileView && classes.root}>
      <ConditionalWrap
        condition={isMobileView}
        wrap={(children) => (
          <header className={scrollMobile ? classes.mobileHeaderScroll : classes.mobileHeader}>
            <div className={classes.headerLeft}>
              <Icon type={'alignLeft'} colorIcon="#253746" onClick={() => setOpenSidebarModile(true)} />
              <Modal
                open={openSidebarModile}
                onClose={() => setOpenSidebarModile(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                {children}
              </Modal>
              <div className={classes.headerTitle}>
                <span>Campaign management</span>
              </div>
            </div>
            <div className={classes.headerRight}>
              <div className={classes.headerIcon}>
                <Icon type={'star'} colorIcon="#8C98A4" />
              </div>
              <div className={classes.headerIcon}>
                <Icon onClick={() => setIsPropertiesModalOpen(true)} type={'threeDotsVertical'} size={'14px'} />
                <Modal
                  open={isPropertiesModalOpen}
                  onClose={() => setIsPropertiesModalOpen(false)}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <MobilePropertiesModal
                    dataMenuPage={dataMenuPage}
                    setIsPropertiesModalOpen={setIsPropertiesModalOpen}
                  />
                </Modal>
              </div>
            </div>
          </header>
        )}
      >
        <DndProvider context={window} backend={HTML5Backend}>
          <SideBar
            userEmail={userEmail}
            drawerWidth={drawerWidth_const}
            openSideBar={Boolean(openSideBar)}
            setOpenSideBar={setOpenSideBar}
            dataMenuTopBar={dataMenuTopBar}
            dataMenuFooter={dataMenuFooter}
            header={
              <React.Fragment>
                <TabList
                  data={enhancedDataSideBarHead}
                  onItemClick={handleMenuClick}
                  appMenuListRef={appMenuListRef}
                  setAppMenuList={setAppMenuList}
                  cloneFeature={cloneFeature}
                  updateSidebar={updateSidebar}
                  homepageId={homepageId}
                  setHomepageId={setHomepageId}
                  favorites={favorites}
                  updateFavorites={updateFavorites}
                  additionalBlockInfo={additionalBlockInfo}
                  customActions={customActions}
                />
              </React.Fragment>
            }
            aboutData={aboutData}
            setOpenSidebarModile={setOpenSidebarModile}
            systemMode={systemMode}
            setSystemMode={setSystemMode}
          >
            <TabList
              data={favoritesMenuList}
              onItemClick={handleMenuClick}
              updateSidebar={updateFavorites}
              appMenuListRef={favoritesRef}
              useDnd={true}
              addNewSidebarSection={addNewSidebarSection}
              deleteSidebarSection={deleteSidebarSection}
              setAppMenuList={setAppMenuList}
              cloneFeature={cloneFeature}
              homepageId={homepageId}
              setHomepageId={setHomepageId}
              favorites={favorites}
              updateFavorites={updateFavorites}
              additionalBlockInfo={additionalBlockInfo}
              customActions={customActions}
            />
            <TabList
              data={appMenuList}
              onItemClick={handleMenuClick}
              updateSidebar={updateSidebar}
              appMenuListRef={appMenuListRef}
              useDnd={aim.isSuperAdmin()}
              addNewSidebarSection={addNewSidebarSection}
              deleteSidebarSection={deleteSidebarSection}
              setAppMenuList={setAppMenuList}
              cloneFeature={cloneFeature}
              homepageId={homepageId}
              setHomepageId={setHomepageId}
              favorites={favorites}
              updateFavorites={updateFavorites}
              additionalBlockInfo={additionalBlockInfo}
              customActions={customActions}
            />
          </SideBar>
        </DndProvider>
      </ConditionalWrap>
      {!isMobileView && (
        <GlobalNavigation
          openSideBar={Boolean(openSideBar)}
          dataListUser={dataListUser}
          dataBreadcrumbs={dataBreadcrumbs}
          dotMenu={dataMenuPage}
          drawerWidth={drawerWidth_const}
          headerLimitUsers={headerLimitUsers}
          favorites={favorites}
          updateFavorites={updateFavorites}
          {...(ff.next_left_menu ? { sandboxPageInformation } : {})}
        />
      )}
      <main
        className={
          !isMobileView &&
          clsx(classes.content, {
            [classes.contentShift]: Boolean(openSideBar),
          })
        }
      >
        {!isMobileView && <div className={classes.drawerHeader} />}
        <div
          className={
            isMobileView
              ? classes.mobileRootContent
              : clsx(enabledPaddingChild && (!openSideBar ? classes.wrapperClose : classes.wrapper))
          }
        >
          {children}
        </div>
        <SandboxBar />
      </main>
    </div>
  );
};
export default AppBarMenu;

function SandboxBar() {
  const history = useHistory();
  const isSandbox = React.useMemo(() => {
    return isSandboxMode();
  }, []);
  if (!isSandbox) {
    return null;
  }

  return (
    <div
      style={{
        background: '#8C98A4',
        position: 'fixed',
        width: '100%',
        bottom: '0',
        left: '0',
        height: '24px',
        fontSize: '0.8em',
        paddingLeft: '30px!important',
        textAlign: 'center',
        lineHeight: '24px',
        color: '#fff',
      }}
    >
      Sandbox{' '}
      <div
        onClick={() => {
          const searchParams = new URLSearchParams(location.search);
          searchParams.delete('isSandbox');
          const href = history.createHref({
            pathname: location.pathname.replace(/page\/([^\/]*)\/.*/, 'page/$1'),
            search: searchParams.toString(),
          });

          console.info('new href', href);
          window.location.href = '/';
          // window.setTimeout(() => {
          //   window.location.reload();
          // }, 100);
        }}
        style={{ display: 'inline-block', cursor: 'pointer' }}
      >
        (Switch off)
      </div>
    </div>
  );
}
