import * as React from 'react';
import moment from 'moment';
import TimeBasedCell from '../format/time-based-cell-format';

export const useTimeBasedCell = (period: number, hasMixedChart: boolean, backbone: any) => {
  const [time, setTime] = React.useState(
    moment(new Date())
      .subtract(period === 1 ? new Date().getMinutes() : new Date().getMinutes() % 30, 'm')
      .toDate(),
  );

  const [timeBasedColumns, setTimeBasedColumns] = React.useState({});

  React.useEffect(() => {
    if (hasMixedChart) {
      const timeBasedColumnsLength =
        period === 1 && time.getMinutes() >= 30 ? time.getHours() / period + 2 : time.getHours() / period + 1;

      const timeBasedColumnsArr = Array.from({ length: timeBasedColumnsLength })
        .map((_, index) => {
          return moment(time)
            .subtract(index * period, 'h')
            .toDate();
        })
        .filter((el, index) => {
          const currentTime = moment(el).format('HH:mm');
          return !timeBasedColumns[currentTime] || (currentTime === '00:00' && index === 0);
        });

      const newTimeBasedColumns = timeBasedColumnsArr.reduce((a, b) => {
        const currentTime = moment(b).format('HH:mm');
        ['roas', 'ad_gmv'].forEach((el) => {
          a[`${currentTime}_${el}`] = {
            headerName: currentTime,
            field: currentTime,
            colId: currentTime,
            resizable: false,
            suppressMovable: true,
            cellStyle: {
              borderRightColor: 'whitesmoke',
            },
            headerComponentParams: {
              type: 'number',
            },
            width: 200,
            cellRendererParams: {
              backbone,
              metricId: el,
            },
            cellRendererFramework: TimeBasedCell,
          };
        });
        return a;
      }, {});

      if (newTimeBasedColumns['00:00']) {
        setTimeBasedColumns(newTimeBasedColumns);
      } else {
        setTimeBasedColumns({ ...newTimeBasedColumns, ...timeBasedColumns });
      }
    }
  }, [time, hasMixedChart, period]);

  React.useEffect(() => {
    if (hasMixedChart) {
      const interval = setInterval(() => {
        setTime(moment(time).add(period, 'h').toDate());
      }, 24 * 60 * 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [time, hasMixedChart, period]);

  return {
    timeBasedColumns,
  };
};
