import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { colors, getCustomPlaceHolder, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import MaskedTextInput from 'react-text-mask';
import { IconButton, OutlinedInput, OutlinedInputProps, debounce } from '@material-ui/core';
import clsx from 'clsx';
import _ from 'lodash';
import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';

const useStyles = makeStyles(() => ({
  textStyle: textFieldStyle as CreateCSSProperties,
  textFormat: {
    width: '100%',
    '& input': {
      width: '100%',
      ...getCustomPlaceHolder('#000', {
        fontWeight: 400,
      }),
    },
    '&:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: colors.border.highlight,
    },
    '&.Mui-disabled .eip1-MuiOutlinedInput-notchedOutline ': {
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
      pointerEvent: 'none',
    },
    '&.Mui-disabled .eip1-MuiInputBase-input.Mui-disabled ': {
      background: colors.surface.disable,
    },
    '&.Mui-disabled.eip1-MuiOutlinedInput-adornedStart': {
      background: colors.surface.disable,
    },
  },
}));

interface TextMaskCustomProps extends OutlinedInputProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskCustom = (props: TextMaskCustomProps) => {
  const { inputRef, ...other } = props;
  return (
    <MaskedTextInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      type="text"
      mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
    />
  );
};

interface Props extends OutlinedInputProps {
  defaultValue?: string;
  onChangeText?: (value: string) => void;
  mask?: (string | RegExp)[];
  disabledIconClear?: boolean;
}
const TextFormatCustom = ({ defaultValue = '', onChangeText, mask = [], disabledIconClear = false, ...res }: Props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState<string>(defaultValue);
  React.useEffect(() => {
    if (defaultValue !== value) setValue(defaultValue);
  }, [defaultValue]);

  const isValidDate = (value) => {
    const covert = _.split(value, '/', 3);
    const dateString = `${covert[2]}-${covert[1]}-${covert[0]}`;

    if (!dateString.match(/^\d{4}-\d{2}-\d{2}$/)) return false;
    const d = new Date(dateString);

    // check exists time
    const dNum = d.getTime();
    if ((!dNum && dNum !== 0) || dNum <= new Date().getTime()) return false;

    // compare initialDate vs date create by Class Date
    return d.toISOString().slice(0, 10) === dateString;
  };

  const handleSetText = (value: string) => {
    if (value !== '') {
      if (isValidDate(value)) {
        onChangeText(value);
      }
    } else {
      onChangeText(value);
    }
  };
  React.useEffect(() => {
    handleSetText(value);
  }, [value]);

  return (
    <OutlinedInput
      {...res}
      className={clsx(classes.textStyle, classes.textFormat, res.className)}
      value={value}
      placeholder={'no limit'}
      onChange={(e) => setValue(e.target.value)}
      inputComponent={TextMaskCustom as any}
      endAdornment={
        !disabledIconClear &&
        value &&
        !res.disabled && (
          <IconButton aria-label="toggle password visibility" onClick={() => setValue('')} style={{ opacity: 1 }}>
            <CloseFiled />
          </IconButton>
        )
      }
    />
  );
};

export default TextFormatCustom;
