import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const SuccessConnectStore = (props: SvgIconProps) => (
  <SvgIcon {...props} width="192" height="166" viewBox="0 0 192 166" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.9999 33.882C12.7741 33.882 13.4321 33.2229 13.4321 32.4475V26.1664C13.4321 25.3909 12.7741 24.7318 11.9999 24.7318C11.2257 24.7318 10.5676 25.3909 10.5676 26.1664V32.4475C10.5676 33.2617 11.187 33.882 11.9999 33.882Z"
      fill="#1EB183"
    />
    <path
      d="M11.9999 48.7319C12.7741 48.7319 13.4321 48.0728 13.4321 47.2973V41.0162C13.4321 40.2408 12.7741 39.5817 11.9999 39.5817C11.2257 39.5817 10.5676 40.2408 10.5676 41.0162V47.2973C10.5676 48.1115 11.187 48.7319 11.9999 48.7319Z"
      fill="#1EB183"
    />
    <path
      d="M1.43226 38.1858H7.70325C8.47744 38.1858 9.13551 37.5266 9.13551 36.7512C9.13551 35.9758 8.47744 35.3167 7.70325 35.3167H1.43226C0.658065 35.3167 0 35.9758 0 36.7512C0.0387098 37.5266 0.619355 38.1858 1.43226 38.1858Z"
      fill="#1EB183"
    />
    <path
      d="M16.2968 38.1858H22.5677C23.3419 38.1858 24 37.5266 24 36.7512C24 35.9758 23.3419 35.3167 22.5677 35.3167H16.2968C15.5226 35.3167 14.8645 35.9758 14.8645 36.7512C14.8645 37.5266 15.4839 38.1858 16.2968 38.1858Z"
      fill="#1EB183"
    />
    <path
      d="M180 127.482C180.774 127.482 181.432 126.823 181.432 126.047V119.766C181.432 118.991 180.774 118.332 180 118.332C179.226 118.332 178.568 118.991 178.568 119.766V126.047C178.568 126.862 179.187 127.482 180 127.482Z"
      fill="#1EB183"
    />
    <path
      d="M180 142.332C180.774 142.332 181.432 141.673 181.432 140.897V134.616C181.432 133.841 180.774 133.182 180 133.182C179.226 133.182 178.568 133.841 178.568 134.616V140.897C178.568 141.711 179.187 142.332 180 142.332Z"
      fill="#1EB183"
    />
    <path
      d="M169.432 131.786H175.703C176.477 131.786 177.136 131.127 177.136 130.351C177.136 129.576 176.477 128.917 175.703 128.917H169.432C168.658 128.917 168 129.576 168 130.351C168.039 131.127 168.619 131.786 169.432 131.786Z"
      fill="#1EB183"
    />
    <path
      d="M184.297 131.786H190.568C191.342 131.786 192 131.127 192 130.351C192 129.576 191.342 128.917 190.568 128.917H184.297C183.523 128.917 182.865 129.576 182.865 130.351C182.865 131.127 183.484 131.786 184.297 131.786Z"
      fill="#1EB183"
    />
    <path
      d="M94.1316 165.3C137.137 165.3 172 130.437 172 87.4319C172 44.4263 137.137 9.56348 94.1316 9.56348C51.1261 9.56348 16.2632 44.4263 16.2632 87.4319C16.2632 130.437 51.1261 165.3 94.1316 165.3Z"
      fill="#3CBB93"
    />
    <path
      d="M98.5527 157.772C109.408 126.519 138.494 106.669 169.79 105.991C169.921 105.466 170.052 104.94 170.162 104.393C138.078 104.918 108.182 125.206 97.0645 157.247C96.1453 159.895 95.3793 162.587 94.7446 165.278C95.2918 165.278 95.8389 165.257 96.3641 165.257C96.9551 162.74 97.6773 160.245 98.5527 157.772Z"
      fill="#80D3B9"
    />
    <path
      d="M130.002 71.1712C136.064 53.7066 135.823 35.1039 129.455 18.0114C128.754 17.6612 128.076 17.3111 127.376 16.9828C134.204 34.1629 134.664 53.0062 128.536 70.6459C114.551 110.806 70.5611 132.122 30.4232 118.181C27.1623 117.043 24.0326 115.708 21.0125 114.198C21.2751 114.92 21.5596 115.642 21.8441 116.365C24.4485 117.59 27.1185 118.706 29.8979 119.669C38.4114 122.624 47.0999 124.025 55.6352 124.025C88.2008 124.025 118.731 103.627 130.002 71.1712Z"
      fill="#80D3B9"
    />
    <path
      d="M113.391 40.8816C99.5812 80.6912 56.1604 101.986 16.2852 88.7232C16.2852 89.2922 16.307 89.8393 16.3289 90.4083C24.339 93.0127 32.4804 94.2383 40.5123 94.2383C73.0779 94.2383 103.608 73.8411 114.879 41.3849C118.052 32.2149 119.453 22.848 119.212 13.678C118.687 13.5029 118.14 13.3278 117.615 13.1527C117.921 22.3446 116.586 31.7116 113.391 40.8816Z"
      fill="#80D3B9"
    />
    <path
      d="M81.8756 10.5264C81.1534 10.6358 80.4312 10.7671 79.7308 10.8984C72.246 21.8849 61.9817 30.7704 49.704 36.7233C43.3791 39.7872 36.7916 41.932 30.0728 43.1795C29.6569 43.7704 29.2411 44.3832 28.8472 44.996C36.2226 43.7704 43.4666 41.4724 50.3824 38.1239C63.3824 31.8428 74.1719 22.3226 81.8756 10.5264Z"
      fill="#80D3B9"
    />
    <path
      d="M124.887 86.9802C126.227 88.32 156.986 100.936 172.198 107.077C163.49 136.552 146.798 150.061 139.541 153.132C128.516 147.829 105.712 136.971 102.697 135.966C78.4139 137.222 70.0403 128.011 62.9227 124.243C55.8051 120.475 39.0579 114.194 39.0579 107.914C39.0579 102.89 42.9656 100.238 44.9194 99.5406C34.8711 95.7725 34.8711 90.7483 34.8711 89.0736C35.2897 80.2813 41.5699 79.8626 43.6633 79.8626C32.7776 74.8384 33.615 68.5582 33.615 66.4648C34.871 58.5099 44.082 58.9285 47.0128 58.9285C41.1512 54.3231 41.9886 49.2989 42.4073 47.2055C45.4218 39.1668 58.1776 41.9022 64.1787 44.2747C69.2029 45.9494 80.0885 49.4664 83.438 50.1362C71.7149 33.389 75.0644 12.8736 75.4831 9.10544C75.9017 5.33725 77.9951 0.731812 83.8567 0.731812C89.7182 0.731812 91.8117 5.33735 91.8117 8.68679C90.9743 25.434 101.441 34.2263 103.535 37.1571C109.815 44.6934 118.607 58.5099 119.445 70.2329C120.282 81.956 123.213 85.3055 124.887 86.9802Z"
      fill="#FEF2EC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M60.4111 43.0188C61.5445 43.3503 62.5832 43.7102 63.4732 44.062C67.9279 45.5469 76.9906 48.4801 81.3226 49.5929C82.054 51.1457 81.8103 52.2427 81.4995 53.641C81.2934 54.5683 81.0579 55.6281 81.0579 57.0411C80.1941 52.4339 68.5411 46.4651 60.4111 43.0188ZM81.3342 133.801C74.5554 131.871 70.0245 128.935 66.3218 126.536C64.8528 125.584 63.5141 124.717 62.2172 124.03C61.6935 123.753 61.1177 123.462 60.5002 123.159C65.2957 122.687 68.1422 120.951 70.5909 115.238C73.103 109.376 68.9161 105.608 63.892 102.259C78.5458 99.3279 71.8469 88.8609 69.7535 86.7675C79.132 81.0734 74.4986 73.2301 71.0095 70.0202C72.6843 70.0202 76.7036 69.4341 79.3832 67.0895C78.9645 70.8576 79.8019 83.418 88.5942 93.8851C82.3139 95.1411 76.4524 104.771 79.3832 115.238C77.3989 118.986 79.1299 122.734 80.6632 126.054C82.0417 129.039 83.2604 131.678 81.4766 133.66C81.4318 133.71 81.3843 133.757 81.3342 133.801Z"
      fill="#FFDBC9"
    />
    <path
      d="M16.3077 151.809C15.724 151.809 15.1403 151.585 14.7233 151.167L11.3877 147.82C10.526 146.956 10.526 145.533 11.3877 144.669C12.2494 143.804 13.6671 143.804 14.5288 144.669L16.3077 146.454L22.6732 140.067C23.5349 139.202 24.9525 139.202 25.8142 140.067C26.6759 140.931 26.6759 142.354 25.8142 143.218L17.8643 151.195C17.4752 151.558 16.9193 151.809 16.3077 151.809Z"
      fill="white"
    />
    <path
      d="M141.549 51.927C141.848 51.0057 143.152 51.0057 143.451 51.9271L145.082 56.9463C145.216 57.3583 145.6 57.6373 146.033 57.6373H151.311C152.279 57.6373 152.682 58.8769 151.898 59.4463L147.629 62.5484C147.278 62.803 147.132 63.2544 147.265 63.6664L148.896 68.6857C149.196 69.607 148.141 70.3731 147.357 69.8037L143.088 66.7016C142.737 66.447 142.263 66.447 141.912 66.7016L137.643 69.8037C136.859 70.3731 135.804 69.607 136.104 68.6857L137.735 63.6664C137.868 63.2544 137.722 62.803 137.371 62.5484L133.102 59.4463C132.318 58.8769 132.721 57.6373 133.689 57.6373H138.967C139.4 57.6373 139.784 57.3583 139.918 56.9463L141.549 51.927Z"
      fill="#FFD964"
    />
    <path
      d="M126.524 39.4635C126.674 39.0029 127.326 39.0029 127.476 39.4635L128.459 42.4914C128.526 42.6974 128.718 42.8369 128.935 42.8369H132.119C132.603 42.8369 132.804 43.4567 132.412 43.7414L129.837 45.6127C129.662 45.74 129.588 45.9657 129.655 46.1717L130.639 49.1996C130.789 49.6602 130.261 50.0433 129.87 49.7586L127.294 47.8873C127.119 47.76 126.881 47.76 126.706 47.8873L124.13 49.7586C123.739 50.0433 123.211 49.6602 123.361 49.1996L124.345 46.1717C124.412 45.9657 124.338 45.74 124.163 45.6127L121.588 43.7414C121.196 43.4567 121.397 42.8369 121.881 42.8369H125.065C125.282 42.8369 125.474 42.6974 125.541 42.4914L126.524 39.4635Z"
      fill="#F9C937"
    />
  </SvgIcon>
);
export default SuccessConnectStore;
