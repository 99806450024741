import Icon from '@ep/insight-ui/icons/Icon';
import { Button, colors, makeStyles, Slide } from '@material-ui/core';
import { OptionsObject, ProviderContext, SnackbarKey, SnackbarMessage, SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getPageUrn, isSandboxMode } from '../../eo2/global';
const useStyles = makeStyles({
  container: {
    padding: '5px',
  },
  rootSnackbar: {
    pointerEvents: 'all',
  },
  root: {
    padding: '10px',
    display: 'flex',
    flexDirection: 'row',
    // height: '116px',
    alignItems: 'flex-start',
    width: '364px',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    borderRadius: '4px',
    color: '#fff',
    '&.error': {
      backgroundColor: '#D4290D',
      border: '1px solid #E4553E',
    },
    '&.success': {
      background: '#0BA373',
      border: '1px solid #3CBB93',
    },
    '&.warning': {
      background: '#F7BB05',
      border: '1px solid #F7BB05',
    },
  },
  divIcon: {
    // width: '20%',
    height: '100%',
    padding: '2px 10px',
  },
  icon: {
    '&.error': {
      width: '20px',
      height: '20px',
      '& svg': {},
    },
  },
  content: {
    flex: 1,
    flexShrink: 0,
    fontStyle: 'normal',
    '& .title': {
      fontWeight: 500,
      fontSize: '16px',
      color: '#fff',
      marginBottom: '5px',
    },
    '& .messages': {
      fontWeight: 'normal',
      fontSize: '14px',
    },
  },
  closeBtn: {
    '&:hover': {
      background: 'none',
    },
  },
  link: {
    fontWeight: 'bold',
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
type onToastProps = {
  title?: string;
  messages?: string;
  variant: 'error' | 'success' | 'warning';
  disabledIcon?: boolean;
};
interface onToastMultipleProps extends Omit<onToastProps, 'messages'> {
  messages: string[];
}
export type IOnToast = (props: onToastProps) => SnackbarKey;
export type IOnToastMultiple = (props: onToastMultipleProps) => SnackbarKey;

export type IOnToastCustomize = ({
  title,
  variant,
  content,
  disableIcon,
}: {
  title: string;
  variant: 'error' | 'success';
  content: React.ReactNode;
  disableIcon?: boolean;
}) => void;
export const useToast = (globalToast?: ProviderContext) => {
  let sanboxMode;
  sanboxMode = isSandboxMode();
  const { enqueueSnackbar, closeSnackbar } = globalToast ?? useSnackbar();
  const classes = useStyles();
  let handleClickLink;
  handleClickLink = () => {
    window.open('/page/' + EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.companyManagement, '_blank');
  };
  const content = (key, { messages, title, variant, disabledIcon = false }: onToastProps, messagesArray?: string[]) => (
    <div className={classes.container}>
      <div className={`${classes.root} ${variant}`} style={{ color: variant == 'warning' ? '#000' : '#fff' }}>
        {/* icon */}
        {!disabledIcon && (
          <div className={`${classes.divIcon}`}>
            <Icon
              type={variant == 'error' || 'warning' ? 'customError' : 'customSuccess'}
              colorIcon={variant == 'warning' ? '#000' : '#fff'}
              style={{ height: '20px', width: '20px' }}
            />
          </div>
        )}
        {/* content */}
        <div className={`${classes.content}`}>
          {/* title */}
          <div className={'title'} style={{ color: variant == 'warning' ? '#000' : '#fff' }}>
            {title}
          </div>
          {messagesArray && messagesArray.length > 0 ? (
            <div>
              {messagesArray.map((mes, index) => (
                <div key={index.toString()}>
                  <span>・{mes}</span>
                </div>
              ))}
              {sanboxMode ? (
                <div>
                  <span>
                    This is for demo only, please integrate storefront to have full experience.{' '}
                    <span onClick={handleClickLink} className={classes.link}>
                      Integrate now
                    </span>
                  </span>
                </div>
              ) : null}
            </div>
          ) : (
            <div className={'messages'}>
              {messages}
              {sanboxMode ? (
                <div>
                  <span>
                    This is for demo only, please integrate storefront to have full experience.{' '}
                    <span onClick={handleClickLink} className={classes.link}>
                      Integrate now
                    </span>
                  </span>
                </div>
              ) : null}
            </div>
          )}
        </div>
        <div className={`${classes.divIcon}`}>
          <Button className={classes.closeBtn} variant={'text'} onClick={() => closeSnackbar(key)}>
            <Icon type={'close'} colorIcon={'#fff'} style={{ height: '10px', width: '10px' }} />
          </Button>
        </div>
      </div>
    </div>
  );
  const onToastCustomize: IOnToastCustomize = ({
    title,
    variant,
    content,
    disableIcon = false,
  }: {
    title: string;
    variant: 'error' | 'success';
    content: React.ReactNode;
    disableIcon?: boolean;
  }) => {
    enqueueSnackbar('', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      // eslint-disable-next-line react/display-name
      TransitionComponent: Slide,
      disableWindowBlurListener: true,
      // eslint-disable-next-line react/display-name
      content: (key) => (
        <div className={classes.container}>
          <div className={`${classes.root} ${variant}`}>
            {/* icon */}
            {!disableIcon && (
              <div className={`${classes.divIcon}`}>
                <Icon type={variant == 'error' ? 'error' : 'success'} style={{ height: '20px', width: '20px' }} />
              </div>
            )}
            {/* content */}

            <div className={`${classes.content}`}>
              <div className={'title'}>{title}</div>
              <div>{content}</div>
              (sanboxMode ? (
              <div>
                <span>
                  This is for demo only, please integrate storefront to have full experience.{' '}
                  <span onClick={handleClickLink} className={classes.link}>
                    Integrate now
                  </span>
                  ?
                </span>
              </div>
              ) : null)
            </div>
            <div className={`${classes.divIcon}`}>
              <Button variant={'text'} onClick={() => closeSnackbar(key)}>
                <Icon type={'close'} style={{ height: '10px', width: '10px' }} />
              </Button>
            </div>
          </div>
        </div>
      ),
    });
  };
  const onToast: IOnToast = (props: onToastProps) =>
    enqueueSnackbar('', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      // eslint-disable-next-line react/display-name
      TransitionComponent: Slide,
      // eslint-disable-next-line react/display-name
      content: (key) => content(key, props),
    });

  const onToastMultiple: IOnToastMultiple = (props: onToastMultipleProps) =>
    enqueueSnackbar('', {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      // eslint-disable-next-line react/display-name
      TransitionComponent: Slide,
      // eslint-disable-next-line react/display-name
      content: (key) =>
        content(key, { variant: props.variant, disabledIcon: props.disabledIcon, title: props.title }, props.messages),
    });
  return { onToast, onToastCustomize, onToastMultiple };
};

export type INotificationContext = {
  onToast: IOnToast;
  onToastCustomize: IOnToastCustomize;
};

export const NotificationContext = React.createContext<INotificationContext>(null);
export const useNotificationGlobal = () => React.useContext(NotificationContext);

export type IGlobalToast = {
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey;
  closeSnackbar: (key?: SnackbarKey) => void;
};
