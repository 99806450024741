import * as React from 'react';

import { Modal, Box, makeStyles, createStyles, Theme, withStyles, Button } from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';

import Icon from '@ep/insight-ui/icons/Icon';
import { importMassUpload } from '@ep/insight-ui/system/backbone/data-source/common';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';

import ButtonAction from './button-action';

const useStyles = makeStyles({
  excelContainer: {
    background: '#FFF',
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    width: '368px',
    padding: '20px 16px',
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    borderRadius: '8px',
  },
  label: {
    fontSize: '24px',
    lineHeight: '32px',
    fontWeight: 500,
    color: '#2B3245',
  },
  item: {
    display: 'flex',
    border: '1px solid #E4E7E9',
    borderRadius: '4px',
    padding: '10px',
    columnGap: '10px',
  },
  fileContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
  itemHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '12px',
    color: '#2B3245',
    height: '20px',
  },
  itemName: {
    fontWeight: 500,
  },
  itemDetail: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    rowGap: '2px',
  },
  cancelBtn: {
    '& .eip1-MuiButton-label': {
      columnGap: '4px',
    },
  },
  fileDetail: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
  },
  fileSize: {
    color: '#8C98A4',
    lineHeight: '12px',
    fontSize: '10px',
  },
  filePercent: {
    color: '#0BA373',
    lineHeight: '12px',
    fontSize: '10px',
  },
  fileLoading: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
    alignItems: 'center',
    padding: '0 42px',
  },
  loadingContent: {
    textAlign: 'center',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#253746',
  },
  uploadFailed: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#D4290D',
  },
});

const LinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 4,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    barColorSecondary: {
      borderRadius: 5,
      backgroundColor: '#D4290D',
    },
    barColorPrimary: {
      borderRadius: 5,
      backgroundColor: '#0BA373',
    },
  }),
)(MuiLinearProgress);

const ImportMassOperationBtn = () => {
  const classes = useStyles();

  const inputRef = React.useRef(null);
  const [open, setOpen] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const handleOnChange = async (e) => {
    setOpen(true);
    setLoading(true);
    if (e.target.files.length > 0) {
      const tmpFiles = [];
      for (const file of [...e.target.files]) {
        try {
          await importMassUpload(file);
          tmpFiles.push({
            file: file,
            success: true,
          });
        } catch (e) {
          tmpFiles.push({
            file: file,
            success: false,
          });
        }
      }
      setLoading(false);
      setFiles(tmpFiles);
    } else {
      alert('Something went wrong!');
    }
  };

  React.useEffect(() => {
    if (files.length === 0) setOpen(false);
  }, [files]);

  const handleCancelItem = (file) => {
    setFiles(files.filter((item) => item.name !== file.name || item.size !== file.size));
  };

  const handleTryAgain = async (file, index) => {
    setLoading(true);
    try {
      await importMassUpload(file);
      setFiles(
        files.map((el, i) =>
          i === index
            ? {
                file: file,
                success: true,
              }
            : el,
        ),
      );
    } catch (e) {}
    setLoading(false);
  };

  const handleClose = () => {
    setFiles([]);
    inputRef.current.value = '';
  };

  const filesRender = files.map(({ file, success }, index) => {
    const sizeInMb = (file.size / Math.pow(10, 6)).toFixed(2);
    return (
      <Box className={classes.item} key={file.size + file.name}>
        <Box style={{ height: '20px' }}>
          <Icon type={'excel'} />
        </Box>
        <Box className={classes.itemDetail}>
          <Box className={classes.itemHeader}>
            <span className={classes.itemName}>{file.name}</span>
            {!success && (
              <Button className={classes.cancelBtn} onClick={() => handleTryAgain(file, index)}>
                <Icon type={'reload'} color={'#2B3245'} />
                <span>Try again</span>
              </Button>
            )}
          </Box>
          <LinearProgress
            style={{ width: '100%', backgroundColor: success ? '#0BA373' : '#D4290D' }}
            color={success ? 'primary' : 'secondary'}
            variant="determinate"
            value={100}
          />
          <Box className={classes.fileDetail}>
            {success ? (
              <>
                <span className={classes.fileSize}>{`${sizeInMb} Mb of ${sizeInMb} Mb`}</span>
                <Icon type={'separate'} />
                <span className={classes.filePercent}>100% done</span>
              </>
            ) : (
              <span className={classes.uploadFailed}>Upload failed</span>
            )}
          </Box>
        </Box>
      </Box>
    );
  });

  return (
    <>
      <input
        type="file"
        style={{ display: 'none' }}
        onChange={handleOnChange}
        ref={inputRef}
        accept={'.xlsx'}
        multiple
      />
      <ButtonAction
        icon="import"
        label={'Import'}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={() => inputRef.current.click()}
      />
      <Modal open={open} onClose={handleClose}>
        <Box className={classes.excelContainer}>
          <span className={classes.label}>Import</span>
          {loading ? (
            <Box className={classes.fileLoading}>
              <LoadingIcon color={'#ED5C10'} />
              <span className={classes.loadingContent}>
                The file is importing to our database. Do not close until the importation has finished.
              </span>
            </Box>
          ) : (
            <Box className={classes.fileContainer}>{filesRender}</Box>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ImportMassOperationBtn;
