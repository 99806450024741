export const MASS_OPERATION_TABLE_DATA = [
  {
    'REQUEST.name': 'Face serum - Flash sales -2021 Jul',
    'REQUEST.id': 1,
    'REQUEST.status_name': 'Validation in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '0/900',
    'REQUEST.uploaded_at': 'Oct 16, 2021 11:59PM',
    'REQUEST.owner_name': 'Martina Staback',
    'REQUEST.owner_id': 1,
  },
  {
    'REQUEST.name': 'Cosmetic - Clearance -2021 August ',
    'REQUEST.id': 2,
    'REQUEST.status_name': 'Execution in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '0/1000',
    'REQUEST.uploaded_at': 'Oct 16, 2021 11:59PM',
    'REQUEST.owner_name': 'Timothy Mulqueen',
    'REQUEST.owner_id': 2,
  },
  {
    'REQUEST.name': 'Lipstick - Brand day - 2021 - July',
    'REQUEST.id': 3,
    'REQUEST.status_name': 'Success',
    'REQUEST.status_id': 2,
    'REQUEST.result': '368/368',
    'REQUEST.uploaded_at': 'Oct 16, 2021 11:59PM',
    'REQUEST.owner_name': 'Yvonne Tjepkema',
    'REQUEST.owner_id': 3,
  },
  {
    'REQUEST.name': 'Cosmetic - Clearance -2021 August ',
    'REQUEST.id': 4,
    'REQUEST.status_name': 'Failed',
    'REQUEST.status_id': 3,
    'REQUEST.result': '0/629',
    'REQUEST.uploaded_at': 'Oct 16, 2021 11:59PM',
    'REQUEST.owner_name': 'Izetta Funnell',
    'REQUEST.owner_id': 4,
  },
  {
    'REQUEST.name': 'Lipstick - Brand day - 2021 - July',
    'REQUEST.id': 5,
    'REQUEST.status_name': 'Execution in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '863/863',
    'REQUEST.uploaded_at': 'Oct 16, 2021 11:59PM',
    'REQUEST.owner_name': 'Lisha Centini',
    'REQUEST.owner_id': 5,
  },
  {
    'REQUEST.name': 'Cosmetic - Clearance -2021 August ',
    'REQUEST.id': 6,
    'REQUEST.status_name': 'Execution in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '900/900',
    'REQUEST.uploaded_at': 'Oct 10, 2021 9:00AM',
    'REQUEST.owner_name': 'Mariann Bilden',
    'REQUEST.owner_id': 6,
  },
  {
    'REQUEST.name': 'Face serum - Flash sales -2021 Jul',
    'REQUEST.id': 7,
    'REQUEST.status_name': 'Success',
    'REQUEST.status_id': 2,
    'REQUEST.result': '135/135',
    'REQUEST.uploaded_at': 'Oct 10, 2021 9:00AM',
    'REQUEST.owner_name': 'Chantell Maynerich',
    'REQUEST.owner_id': 7,
  },
  {
    'REQUEST.name': 'Face serum - Flash sales -2021 Jul',
    'REQUEST.id': 8,
    'REQUEST.status_name': 'Failed',
    'REQUEST.status_id': 3,
    'REQUEST.result': '0/450',
    'REQUEST.uploaded_at': 'Oct 10, 2021 9:00AM',
    'REQUEST.owner_name': 'Glory Kulzer',
    'REQUEST.owner_id': 8,
  },
  {
    'REQUEST.name': 'Cosmetic - Clearance -2021 August ',
    'REQUEST.id': 9,
    'REQUEST.status_name': 'Execution in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '478/860',
    'REQUEST.uploaded_at': 'Oct 10, 2021 9:00AM',
    'REQUEST.owner_name': 'Celeste Korando',
    'REQUEST.owner_id': 9,
  },
  {
    'REQUEST.name': 'Cosmetic - Clearance -2021 August',
    'REQUEST.id': 10,
    'REQUEST.status_name': 'Execution in progress',
    'REQUEST.status_id': 1,
    'REQUEST.result': '550/550',
    'REQUEST.uploaded_at': 'Oct 10, 2021 9:00AM',
    'REQUEST.owner_name': 'Karl Klonowski',
    'REQUEST.owner_id': 10,
  },
];
