/* eslint-disable react/prop-types */
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { Box, Button, Grid, Theme, Tooltip, createStyles, withStyles } from '@material-ui/core';
import MuiLinearProgress from '@material-ui/core/LinearProgress';
import { makeStyles } from '@material-ui/core/styles';
import { isEmpty as _isEmpty, get, isArray, max, min } from 'lodash';
import * as React from 'react';

import WrapperFormat, { IPropsFormat } from './wrapper-format';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { getQueryParams } from '@ep/insight-ui/sw/etable/data/common';
import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';
import clsx from 'clsx';
import LoadingComponent from '../../loading/loading-component';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { useSetAtom } from 'jotai';

const useStyles = makeStyles(() => ({
  format: {
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#253746',
  },
  subtext: {
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    color: '#8C98A4',
  },
  autoSubtext: () => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
    textAlign: 'left',
  }),
  hashtag: {
    fontSize: '10px',
    lineHeight: '12px',
    color: '#006EC6',
    '&.progress-bar': {
      marginLeft: 0,
      justifyContent: 'flex-start',
    },
  },
  numberAlign: {
    display: 'flex',
    width: '100%',
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
    justifyContent: 'flex-start',
  },
  alignRight: {
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  rowGroup: {
    height: '100%',
    width: '100%',
  },
  tooltip: {
    background: '#253746',
    fontSize: (props) => (props.customTooltipFontSize ? props.customTooltipFontSize : '10px'),
    lineHeight: '1.2em',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  height100: {
    height: '100%',
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  statusContainer: {
    flexShrink: 0,
    flexGrow: 0,
  },
  statusCenter: {
    width: '100%',
  },
}));

export interface IValuePivotFormat {
  value: number | string;
  suggestedValue?: number;
  suggestedMax?: number;
  suggestedMin?: number;
  currency: string;
  defaultCurrency: string;
  typeDisplay?: 'currency' | 'datetime' | 'text-number';
  formatDateDisplay?: string;
  hashtag?: string;
  subtext?: string;
}

const LinearProgress = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 4,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 0,
      backgroundColor: '#eeeeee',
    },
  }),
)(MuiLinearProgress);

const PivotFormat = (props: IPropsFormat<IValuePivotFormat>) => {
  const backbone = React.useContext(TableBackboneContext);
  const customTooltipFontSize = get(props, ['value', 'tooltip.fontSize'], '');
  const cellAction = get(props, ['cellAction'], []);
  const node = get(props, ['node'], []);
  const value = get(props, ['value'], []);
  const currency = get(props, ['value', 'currency'], '');
  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const classes = useStyles({ customTooltipFontSize });
  const isEmpty = get(props, ['value', 'isEmpty'], false);
  const cellValue = get(props, ['data', 'eData', props.field], {});
  const metric = cellValue.colField;
  const labelFormula = get(backbone, ['config', 'mapping', metric, 'valueGetter', 'label'], '');
  const metricValue = cellValue.value;
  const loaded = cellValue.loaded;
  const success = cellValue.success;
  const token = cellValue.token;
  const label = String(labelFormula).startsWith('=')
    ? toValue(labelFormula, { ...props.data, value: Number(metricValue), 'config.currency': backbone.config?.currency })
    : metricValue;
  const isBarProgress = get(props, ['value', 'showAs'], '') === 'bar';
  const dividedBy = Number(get(props, ['value', 'dividedBy'], '0.01'));
  let valueProgress = Number(get(props, ['value', 'value'], 0)) / dividedBy;
  if (valueProgress >= 0) {
    valueProgress = 1 + valueProgress;
  }
  const setHeaderRetry = useSetAtom(eTableAtom.updateHeaderRetry);

  const barSubtext = get(props, ['value', 'barSubtext'], Number(valueProgress * 100).toFixed(2) + '%');
  const iconSize = get(props, ['value', 'iconSize'], '12');
  const iconCenter = get(props, ['value', 'iconCenter'], '') == 1;

  const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
  const queryParams = getQueryParams(endpoint);
  const barColor = get(props, ['value', 'barColor'], '#0369C7') || '#0369C7';

  const dataType = get(backbone.getConfig('mapping'), [props.field, 'dataType'], 'float');
  const align = ['float', 'integer'].includes(dataType) ? 'right' : 'left';
  const alignStyle = {
    left: 'alignLeft',
    right: 'alignRight',
  };
  const alignClass = classes[get(alignStyle, [align], 'alignRight')];

  let valueNumber;
  if (!!label) {
    valueNumber = label;
  } else {
    valueNumber = props.value.value;
  }
  const subtext = get(props, ['value', 'subtext'], '');
  const hashtag = get(props, ['value', 'hashtag'], '');
  const customTooltip = get(props, ['value', 'tooltip'], '');
  const status = get(props, ['value', 'status'], '');

  const finalCurrency = React.useMemo(() => {
    if (endpoint.includes('query.jsp') || queryParams.namespace) return '';
    if (currency) return currency;
  }, []);

  const customStyle = React.useMemo(() => {
    const styles = Object.keys(value || {})
      .filter((el) => el.startsWith('style.'))
      .reduce((a, b) => {
        const styleName = b.replace('style.', '').replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
        let styleValue = value && value[b] ? toValue(value[b], value) : null;

        if (styleName === 'background' || styleName.includes('color')) {
          if (styleValue === 'auto') {
            styleValue = stringToTagColour(String(value));
          }
        }
        if (styleValue) {
          return {
            ...a,
            [styleName]: styleValue,
          };
        }
        return a;
      }, {});

    return styles;
  }, [value, label]);

  const getSuggestedValue = () => {
    if (isArray(valueNumber) && valueNumber.length > 0) {
      if (valueNumber.length == 1) {
        return formatCurrencyNumber(get(valueNumber, [0], 0));
      }
      return `${min(valueNumber)} ➞ ${max(valueNumber)}`;
    }

    return '';
  };

  const handleReload = () => {
    setHeaderRetry({ token });
  };

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={cellAction}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={false}
      eGridCell={props.eGridCell}
    >
      <ConditionalWrap
        condition={customTooltip}
        wrap={(children) => (
          <Tooltip
            title={String(customTooltip)
              .split('\n')
              .map((i, index) => {
                return (
                  <React.Fragment key={index}>
                    {i}
                    <br />
                  </React.Fragment>
                );
              })}
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        {!isEmpty && (
          <Grid container alignItems={'flex-start'} className={classes.format} wrap={'nowrap'}>
            {!_isEmpty(status) ? (
              <Grid item className={clsx(classes.statusContainer, iconCenter ? classes.statusCenter : '')}>
                <span className={classes.wrapperIcon}>
                  <Icon type={String(status) as IconType} size={iconSize} className={`${classes.icon} status`} />
                </span>
              </Grid>
            ) : null}
            <Grid item style={{ flexGrow: 1 }} className={classes.height100}>
              <Grid container direction="column" wrap={'nowrap'} className={classes.height100}>
                <Grid item xs={12} className={clsx(classes.numberAlign, alignClass)} alignItems={'flex-end'}>
                  <span className={classes.hashtag}>{hashtag}</span>
                </Grid>
                {isBarProgress ? (
                  <Grid
                    container
                    direction={'column'}
                    alignItems={'flex-start'}
                    wrap={'nowrap'}
                    justifyContent={'center'}
                  >
                    <Grid container alignItems={'center'} item style={{ width: '100%', height: '20px' }}>
                      <LinearProgress
                        style={{ width: '100%', backgroundColor: barColor }}
                        variant="determinate"
                        value={valueProgress * 100}
                      />
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item xs={12} className={clsx(classes.numberAlign, alignClass)} style={{ alignSelf: 'center' }}>
                    <LoadingComponent loading={loaded != true}>
                      {success ? (
                        Object.keys(customStyle).length > 0 ? (
                          <Box
                            style={{ padding: '0 6px', borderRadius: '4px', display: 'inline-flex', ...customStyle }}
                          >
                            {!isArray(valueNumber) ? valueNumber : getSuggestedValue()} {finalCurrency}
                          </Box>
                        ) : (
                          <div>
                            {!isArray(valueNumber) ? valueNumber : getSuggestedValue()} {finalCurrency}
                          </div>
                        )
                      ) : (
                        <Button variant="text" size="small" onClick={handleReload}>
                          <Grid container>
                            <Box style={{ marginRight: '4px' }}>
                              <Icon type={'reload'} />
                            </Box>
                            <span>Reload</span>
                          </Grid>
                        </Button>
                      )}
                      {}
                    </LoadingComponent>
                  </Grid>
                )}

                <Grid item xs={12} className={clsx(classes.numberAlign, alignClass)}>
                  <span className={`${classes.subtext}`}>{subtext}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ConditionalWrap>
    </WrapperFormat>
  );
};

// function PivotFormat1(){
//   return <span>Currency</span>
// }
// export default PivotFormat1;

export default PivotFormat;
