import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { withStyles } from '@material-ui/core/styles';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { useRef, useEffect, useState } from 'react';
import ButtonDropdownMenu from '@ep/insight-ui/elements/button/button-dropdown';
import clsx from 'clsx';
import { PopoverActions, Modal, ButtonProps, Tooltip } from '@material-ui/core';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { MobilePropertiesModal } from '../app-menu/app-menu';
import ConditionalWrap from '../util/conditional-wrap';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';

// Solve issue lose focusing input
// Notion link: https://www.notion.so/epsilion/Bugfix-4191-unilever_beautyhotpro-TH-User-cannot-type-the-F-in-the-filter-search-box-3252aa1b7fa2475d8af09782b4c11382
const DoNotRemovedForMisCaptureUserTyping = () => {
  return <div style={{ opacity: 0, marginLeft: '-0.5rem', fontSize: '0' }}>&hearts;</div>;
};

/** Style */
const StyledMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    borderRadius: '4px',
    marginTop: '12px',
    boxShadow: `${colors.shadow.hover}`,
    minWidth: '208px',
    boxSizing: 'border-box',
    ...(ff.etable_add_loadmore_menu_mobile
      ? {
          '&.paginationClassNameMobile': {
            width: '100%',
          },
        }
      : {}),
  },
})(({ alignMenu, keyboardNavigation, ...rest }: MenuStyledProps) =>
  rest.open ? (
    <MonitorContainer
      component={Menu}
      mId="dropdown-menu"
      mLabel="dropdown-menu"
      className="el-dropdown-menu"
      elevation={0}
      getContentAnchorEl={null}
      disableRestoreFocus={!keyboardNavigation}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: alignMenu,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: alignMenu,
      }}
      {...rest}
    />
  ) : null,
);

export const StyledMenuItem = withStyles(() => ({
  root: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    paddingRight: '0px',
    '&:hover': {
      backgroundColor: colors.action.subtleHovered,
    },
    marginBottom: '6px',
  },
}))(MenuItem);

/* Interface */
export type AlignType = 'left' | 'right' | 'center';
interface MenuStyledProps extends MenuProps {
  alignMenu?: AlignType;
  keyboardNavigation?: boolean;
}
export interface DropdownProps {
  label: string | JSX.Element;
  dataMenu?: DropdownMenuDataType[][];
  onChange?: (v: DropdownMenuDataType[][]) => void;
  handleOnClosed?: () => void;
  disabled?: boolean;
  className?: string;
  defaultOpen?: boolean;
  icon?: IconType;
  alignMenu?: AlignType;
  children?: React.ReactNode;
  disabledToggleStyle?: boolean;
  disabledClickContentClose?: boolean;
  iconStart?: IconType;
  iconExpand?: IconType;
  sizeIcon?: string;
  sizeIconStart?: string;
  classNamePaper?: string;
  classNameFooter?: string;
  colorStartIcon?: string;
  maxHeight?: string;
  dataColorActive?: string;
  sizeButton?: string;
  onClick?: () => void;
  onOpen?: () => void;
  classNamePopover?: string;
  action?: React.Ref<PopoverActions>;
  refFuncs?: (funcs: { open: () => void; close: () => void }) => void;
  dataMenuPage: Array<DropdownMenuDataType[]>;
  isMobileFilter?: boolean;
  isDropdownMobileShow?: boolean;
  setIsDropdownMobileShow?: React.Dispatch<React.SetStateAction<boolean>>;
  title?: string;
  typeDropdown: string;
  enableFocusMenuItem?: boolean;
  onChangeSubItem?: () => void;
  viewNameAction?: string;
  closeCalendarCohort?: () => void;
  isButtonActive?: boolean;
  buttonProps?: ButtonProps;
  keyboardNavigation?: boolean;
  tooltip?: string | React.ReactNode;
  parentRef?: React.MutableRefObject<HTMLDivElement>;
}

export type ChildrenDropdownProps = {
  onClose?: () => void;
  wrapperrefmenu?: React.MutableRefObject<HTMLInputElement>;
  openmenu?: boolean;
};

type closeMenuCallback = (hide: boolean) => void;

type PopupProps = {
  maxHeight?: string;
  maxWidth?: string;
  overflow?: string;
};

type DropdownMenuDataType = {
  title: string;
  icon?: IconType;
  onClick: () => void;
  note?: string;
  editable?: boolean;
  colorTitle?: string;
  iconSize?: string;
  colorStartIcon?: string;
  select?: boolean;
  disable?: boolean;
  name?: string;
  isPopupMenu?: boolean;
  popupComponent?: any;
  popupProps?: PopupProps;
  maxWidthPopup?: string;
};
type IDropDownContext = {
  open: boolean;
  setOpen: (value: boolean) => void;
};
const DropDownContext = React.createContext<IDropDownContext>({ open: null, setOpen: (value) => undefined });
export const useDropdownContext = () => React.useContext(DropDownContext);

const Dropdown: React.FC<DropdownProps> = ({
  label,
  disabled,
  className = '',
  defaultOpen = false,
  disabledToggleStyle,
  disabledClickContentClose = true,
  handleOnClosed = () => undefined,
  icon,
  alignMenu = 'left',
  iconStart,
  iconExpand,
  sizeIcon,
  sizeIconStart,
  classNamePaper,
  classNameFooter,
  colorStartIcon,
  children,
  maxHeight,
  dataColorActive = colors.text.highLight,
  sizeButton,
  onClick,
  onOpen = () => undefined,
  classNamePopover,
  action,
  refFuncs = () => undefined,
  dataMenuPage = [],
  isMobileFilter = false,
  isDropdownMobileShow,
  setIsDropdownMobileShow,
  title,
  typeDropdown,
  enableFocusMenuItem = false,
  onChangeSubItem = () => undefined,
  viewNameAction = '',
  closeCalendarCohort,
  isButtonActive = false,
  buttonProps = {},
  keyboardNavigation = false,
  tooltip = null,
  parentRef,
}: DropdownProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const childEl = React.useRef({ el: null, isInit: false });
  const [open, setOpen] = useState(defaultOpen);

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  // const isMobileView = containerClass === 'eres--small';
  const isMobileView = false;

  const handleClick = () => {
    setOpen(true);
    onOpen();
    onClick && onClick();
  };
  const ref = useRef(null);
  const wrapperrefmenu = useRef<HTMLDivElement>(null);

  if (ff.mobile_interaction_zone) {
    useEffect(() => {
      if (isDropdownMobileShow) handleClick();
    }, [isDropdownMobileShow]);
  }

  useEffect(() => {
    setOpen(defaultOpen);
  }, [defaultOpen]);

  useEffect(() => {
    if (!open) {
      if (ff.calendar_timeline_cohort) {
        if (closeCalendarCohort) {
          closeCalendarCohort();
        }
      }
      if (ff.mobile_interaction_zone) {
        if (setIsDropdownMobileShow) {
          setIsDropdownMobileShow(false);
        }
      }
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    handleOnClosed();
    if (ff.function_crud_etable_view) {
      onChangeSubItem && onChangeSubItem();
    }
    if (ff.calendar_timeline_cohort) {
      if (closeCalendarCohort) {
        closeCalendarCohort();
      }
    }
  };

  useEffect(() => {
    if (ref) {
      setAnchorEl(ref.current);
    }
  }, [ref, tooltip]);

  useEffect(() => {
    if (refFuncs) refFuncs({ open: () => setOpen(true), close: () => setOpen(false) });
  }, [refFuncs]);

  if (open || childEl.current.isInit) {
    childEl.current.el = React.Children.map(children, (child: any) => {
      if (child)
        return React.cloneElement(child, {
          onClose: handleClose,
          wrapperrefmenu: wrapperrefmenu,
          openmenu: open.toString(),
        });
      return null;
    });
    // childEl.current.el = children;
    childEl.current.isInit = true;
  }

  const getIcon = () => {
    if (!icon) return null;
    if (iconExpand && open) return <Icon type={iconExpand} size={sizeIcon} />;
    return <Icon type={icon} size={sizeIcon} />;
  };

  let mobileDropdownTitle;
  if (ff.mobile_interaction_zone) {
    mobileDropdownTitle = React.useMemo(() => {
      if (title) {
        const formattedTitle = title.split('_').join(' ');
        return formattedTitle[0].toUpperCase() + formattedTitle.slice(1);
      }

      return '';
    }, [title]);
  }

  return (
    <DropDownContext.Provider value={{ open, setOpen }}>
      <MonitorContainer
        component="div"
        className={'dropdown'}
        mId={icon === 'threeDotsVertical' ? 'edot' : 'dropdown'}
        mLabel={label}
      >
        <ConditionalWrap
          condition={tooltip}
          wrap={(children) => {
            return <Tooltip title={tooltip}>{children}</Tooltip>;
          }}
        >
          <ButtonDropdownMenu
            endIcon={getIcon()}
            startIcon={iconStart && <Icon type={iconStart} size={sizeIconStart} colorIcon={colorStartIcon} />}
            sizeIcon={sizeIcon}
            disabledToggleStyle={disabledToggleStyle}
            className={className}
            label={label}
            aria-controls="simple-menu"
            aria-haspopup="true"
            disabled={disabled}
            open={open}
            ref={ref}
            onClick={handleClick}
            dataColorActive={dataColorActive}
            sizeButton={sizeButton}
            isButtonActive={isButtonActive}
            {...buttonProps}
          ></ButtonDropdownMenu>
        </ConditionalWrap>
        {keyboardNavigation && defaultOpen ? (
          <>{childEl.current.el}</>
        ) : (
          <StyledMenu
            onClick={() => {
              if (!disabledClickContentClose) {
                setOpen(false);
              }
            }}
            alignMenu={alignMenu}
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            classes={{
              paper: clsx(classNamePaper, classNameFooter, {
                paginationClassNameMobile: isMobileView && typeDropdown == 'pagination',
              }),
            }}
            PaperProps={{
              style: {
                maxHeight: maxHeight,
                overflowY: maxHeight && 'auto',
                width: parentRef?.current ? parentRef.current.offsetWidth : null,
              },
              value: viewNameAction,
            }}
            PopoverClasses={{
              root: classNamePopover,
            }}
            action={action}
            keyboardNavigation={keyboardNavigation}
          >
            {keyboardNavigation ? (
              childEl.current.el
            ) : (
              <div style={{ height: '100%' }} ref={wrapperrefmenu}>
                {!enableFocusMenuItem && <DoNotRemovedForMisCaptureUserTyping />}
                {childEl.current.el}
              </div>
            )}
          </StyledMenu>
        )}
      </MonitorContainer>
    </DropDownContext.Provider>
  );
};
export default Dropdown;
