import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { GridSortModel } from '@material-ui/data-grid';

import Dropdown, { AlignType } from '../../../dropdown-menu/dropdown';
import SortForm, { ISortItem } from './sort-form';
import { OptionSelectType } from '../../../list-control/type';
import { SortType } from '@ep/insight-ui/elements/table/table';

/* Type */
export interface SortDialogProps {
  options: Array<OptionSelectType>;
  sortModel: ISortItem[];
  defaultOpen?: boolean;
  onClosed?: () => void;
  disabled?: boolean;
  alignMenu?: AlignType;
  className?: string;
  classNameButton?: string;
  classNamePopover?: string;
  setSortModel: (model: SortType[]) => void;
  disabledClickContentClose?: boolean;
  disabledIconNoFilter?: boolean;
  isDialogSort?: boolean;
  setIsDialogSort?: any;
}

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    minWidth: '352px',
    paddingBottom: '6px',
    '& .eip1-MuiMenu-paper': {
      backgroundColor: 'red',
    },
  },
  groupRule: {
    '& .eip1-MuiFormControl-root:not(:first-child)': {
      marginLeft: '8px',
    },
  },
  formGroup: {
    marginTop: '16px',
  },
  closeBtn: {
    height: '24px',
    width: '24px',
  },
  header: {
    paddingLeft: '0px',
  },
  selectField: {
    '& .eip1-MuiAutocomplete-inputRoot': {
      '& .eip1-MuiAutocomplete-input': {
        paddingLeft: '0px !important',
      },
      padding: '5px 24px 5px 8px',
    },
  },
  paper: {
    padding: '12px 16px 6px 16px',
  },
}));

const SortDialog = ({
  options,
  sortModel,
  onClosed = () => undefined,
  setSortModel,
  disabled,
  defaultOpen = false,
  alignMenu,
  className = '',
  classNameButton = '',
  classNamePopover = '',
  disabledClickContentClose = false,
  disabledIconNoFilter = false,
}: SortDialogProps) => {
  const classes = useStyles();

  return (
    <Dropdown
      alignMenu={alignMenu}
      disabled={disabled}
      defaultOpen={defaultOpen}
      handleOnClosed={onClosed}
      label="Sort"
      iconStart="sort"
      className={classNameButton}
      classNamePaper={`${classes.paper} ${className}`}
      classNamePopover={classNamePopover}
      disabledClickContentClose={disabledClickContentClose}
    >
      <SortForm
        options={options}
        sortModel={sortModel}
        setSortModel={setSortModel}
        disabledIconNoFilter={disabledIconNoFilter}
      />
    </Dropdown>
  );
};

export default SortDialog;
