import * as React from 'react';
import { makeStyles, withStyles, createStyles } from '@material-ui/core/styles';
import { Box, debounce, FormControl, MenuItem, Select, SelectProps, Modal } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { OptionSelectType } from '../../list-control/type';
import Icon from '@ep/insight-ui/icons/Icon';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import _ from 'lodash';
import clsx from 'clsx';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import { useAutocomplete } from '@material-ui/lab';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { MobilePropertiesModal } from '../../app-menu/app-menu';

type PropsStyle = {
  inputWidth: string;
};
/** Style */
const useStyles = makeStyles(() => ({
  select: (props: PropsStyle) => ({
    width: props.inputWidth || '100%',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '20px',
    },
    '& .eip1-MuiSelect-select[aria-expanded="true"]': {
      backgroundColor: `#006EC6`,
      color: 'white',

      '& ~ svg': {
        color: 'white',
      },
    },
  }),
  box: {
    marginBottom: '0px',
  },
  popper: {
    marginTop: '4px',
    boxSizing: 'border-box',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    position: 'absolute',
    right: '10px',
    pointerEvents: 'none',
  },
  value: {
    maxWidth: '95%',
    textOverflow: 'ellipsis',
    width: '100%',
    overflow: ' hidden',
    whiteSpace: 'nowrap',
    display: 'block',
    lineHeight: '20px',
  },
  wrapperIcon: {
    display: 'flex',
    margin: '0 8px 0 5px',
    width: '16px',
    height: '16px',
  },
  iconSelectBox: {
    width: '16px',
    height: '16px',
  },
  iconDrag: {
    width: '6px',
    height: '100%',
    marginRight: '8px',
  },
}));

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      height: '17px',
      backgroundColor: '#EDF2F9',
      border: 'none !important',
      paddingLeft: '8px',
      cursor: 'pointer',
      //   '&:focus': {
      //     backgroundColor: colors.surface.default,
      //     borderColor: colors.border.highlight,
      //     borderRadius: '4px',
      //   },
      //   '&[aria-expanded=true]': {
      //     borderColor: colors.border.highlight,
      //   },
      //   '&[aria-disabled=true]': {
      //     background: colors.surface.disable,
      //     color: colors.icon.disabled,
      //   },
    },
  }),
)(InputBase);
export type AlignType = 'left' | 'right';

interface SelectFormProps extends SelectProps {
  options?: OptionSelectType[];
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  onReorder?: (v: OptionSelectType) => void;
  onChangeObject?: (value: OptionSelectType) => void;
  inputWidth?: string;
  alignMenu?: AlignType;
  placeholder?: string;
  classNameForm?: string;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  draggable?: boolean;
  dataMenuPage: Array<DropdownMenuDataType[]>;
}

export const StyledMenuItem = withStyles(() => ({
  root: {
    minHeight: '32px',
    minWidth: '192px',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.action.subtleHovered,
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    fontWeight: 400,
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
    '&.active': {
      backgroundColor: '#EBF6FF',
      color: '#006EC6',
    },
  },
}))(MenuItem);

const usePlaceholderStyles = makeStyles(() => ({
  placeholder: {
    color: colors.text.subdued,
  },
}));

const Placeholder = ({ text }: { text: string }) => {
  const classes = usePlaceholderStyles();
  return (
    <Typography variant="body2" className={classes.placeholder}>
      {text}
    </Typography>
  );
};
type CheckboxRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
};
const SelectFormDrag: React.FC<SelectFormProps> = ({
  options,
  value,
  onChange,
  onReorder,
  onChangeObject,
  alignMenu = 'left',
  inputWidth,
  placeholder,
  classNameForm,
  renderOption,
  draggable = false,
  disabled = false,
  dataMenuPage = [],

  ...rest
}: SelectFormProps) => {
  const classes = useStyles({ inputWidth });
  const [open, setOpen] = React.useState(false);
  const defaultOptions = React.useMemo(() => options, [options]);
  const [values, setValues] = React.useState<OptionSelectType[]>(defaultOptions);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const renderValue = () => {
    const index = _.findIndex(options, ['value', value]);
    if (value !== '' && index !== -1) {
      return (
        <Box display={'flex'} alignItems={'center'} height={'100%'}>
          <span className={classes.wrapperIcon}>
            <Icon type={'addproperties'} className={classes.iconSelectBox} />
          </span>
          <div className={classes.value}>
            <span>{options[index].label}</span>
          </div>
        </Box>
      );
    }
    return placeholder ? <Placeholder text={placeholder} /> : null;
  };

  const handler = React.useCallback(debounce(onReorder, 1000), []);
  const moveCard = (dragIndex, hoverIndex) => {
    const tmpItems: OptionSelectType[] = [...options];
    const dragCard = tmpItems[dragIndex];
    tmpItems.splice(dragIndex, 1);
    tmpItems.splice(hoverIndex, 0, dragCard);
    setValues(tmpItems);
    // handler(valueUpdate);
  };

  if (ff.function_crud_etable_view) {
    React.useEffect(() => {
      setValues(options);
    }, [defaultOptions]);
  }

  const handleOnChange = (e) => {
    onChange(e);
    if (onChangeObject) {
      const index = _.findIndex(options, ['value', value]);
      if (index) {
        onChangeObject(options[index]);
      }
    }
  };
  const handleSelected = (val: OptionSelectType) => () => {
    if (onChangeObject && val.value !== value) onChangeObject(val);
    setOpen(false);
  };
  const SelectBoxRender = ({ draggable, index, children }: CheckboxRenderProps) =>
    draggable && !disabled ? (
      <div className={classes.box}>
        <DragCard key={index} index={index} moveCard={moveCard}>
          {children}
        </DragCard>
      </div>
    ) : (
      <div className={classes.box}>{children}</div>
    );

  return (
    <FormControl className={classes.select}>
      <Select
        disabled={disabled}
        autoFocus={false}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: alignMenu,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: alignMenu,
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.popper,
          },
        }}
        variant="outlined"
        value={value}
        onChange={handleOnChange}
        displayEmpty
        IconComponent={() => <Icon type="chevron" className={classes.icon} />}
        renderValue={renderValue}
        input={<BootstrapInput />}
        open={open}
        onOpen={(e) => setOpen(true)}
        onClose={(e) => setOpen(false)}
        {...rest}
      >
        {!isMobileView &&
          values.map((item: OptionSelectType, index: number) => (
            <SelectBoxRender key={index} draggable={draggable} index={index}>
              <StyledMenuItem
                value={item.value}
                className={clsx(value === item.value ? 'active' : '')}
                onClick={handleSelected(item)}
              >
                {draggable && (
                  <div className={`${classes.iconDrag} draggable`}>
                    <Icon type={'draggable'} style={{ width: '100%', height: '100%' }} />
                  </div>
                )}
                {item.icon && (
                  <Box mx={2} display={'flex'} height={'100%'} alignItems={'center'} width={12}>
                    <Icon type={item.icon} style={{ width: '100%', height: '100%' }} />
                  </Box>
                )}
                {renderOption ? renderOption(item) : item.label}
              </StyledMenuItem>
            </SelectBoxRender>
          ))}
        {isMobileView && (
          // <div>123</div>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <MobilePropertiesModal dataMenuPage={dataMenuPage} setIsPropertiesModalOpen={setOpen} />
          </Modal>
        )}
      </Select>
    </FormControl>
  );
};

export default SelectFormDrag;
