import * as React from 'react';
import clsx from 'clsx';
import { FixedSizeList } from 'react-window';
import { debounce, get } from 'lodash';

import { makeStyles, Box, IconButton, InputAdornment, List, ListItem, TextField } from '@material-ui/core';

import Search from '@ep/insight-ui/icons/svg/Search';
import Icon from '@ep/insight-ui/icons/Icon';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import { cellFormat } from '@ep/insight-ui/elements/etable2/table-helper';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from '@ep/insight-ui/system/block/etable/cell-format-options';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import ButtonSort from '../table/table-actions/button-sort';

const nextCellFormat = {
  ...CELL_FORMAT_OPTIONS_WITH_COMPONENT,
  ...cellFormat,
  ...['qualityMetric', 'oneMetric', 'operationMetric'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.currencyFormatFormula };
  }, {}),
  ...['oneDimension'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.textFormatFormula };
  }, {}),
};
const useStyles = makeStyles(() => ({
  container: {
    padding: '16px',
    paddingBottom: 0,
  },
  textStyle: {
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: 0,
      paddingRight: '8px',
    },
    '& .eip1-MuiIconButton-root': {
      padding: 0,
    },
  },
  inputIcon: {
    marginLeft: '12px',
  },
  listItem: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#0000000a',
      '& .additional-container > div': {
        backgroundColor: 'transparent',
      },
    },
    borderRadius: '4px',
    '&.hasSelectAll': {
      paddingLeft: '20px',
    },
    '& svg, & img': {
      width: '24px',
      height: '24px',
    },
    position: 'relative',
  },
  title: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    marginBottom: '8px',
    textTransform: 'uppercase',
  },
  label: {
    whiteSpace: 'nowrap',
  },
  dragIcon: {
    cursor: 'grab',
    marginRight: '10px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '8px',
  },
  cellFormatContainer: {
    '& > div:hover': {
      backgroundColor: 'transparent',
    },
    background: '#fff',
    height: '36px',
    marginLeft: '8px',
  },
  additionalContainer: {
    position: 'absolute',
    height: '36px',
    top: 0,
    right: 0,
    zIndex: 999,
    display: 'flex',
    alignItems: 'center',
    '& .cellTable': {
      // Do not want to override current cell style
      padding: '0 !important',
      whiteSpace: 'nowrap',
      '& .cell-format-main': {
        '& > div': {
          minHeight: '12px',
        },
        '& .label span': {
          lineHeight: '12px',
        },
      },
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
  },
}));

const Row = ({ data, index, style }: any) => {
  const classes = useStyles();

  const listItem = data.options[index];
  const label = data.options[index].label;
  return (
    <div style={style}>
      <ListItem
        className={clsx(classes.listItem)}
        onClick={() => {
          data.onSelectItem(listItem.value);
        }}
      >
        <span className={classes.label}>
          {String(label)?.includes('</span>') ? (
            <span dangerouslySetInnerHTML={{ __html: label }}></span>
          ) : (
            <span>{label ? label : 'None'}</span>
          )}
        </span>
        <Box className={clsx(classes.additionalContainer, 'additional-container')}>
          {(get(data, ['options', index, 'additionalInfomrations'], []) || []).map((metric, index) => {
            const Component = nextCellFormat[metric.cellFormat];

            if (Component) {
              return (
                <Box className={classes.cellFormatContainer} key={index} style={{ maxWidth: metric.maxColumnWidth }}>
                  <Component value={metric.value} cellAction={[]} />
                </Box>
              );
            }

            return '';
          })}
        </Box>
      </ListItem>
    </div>
  );
};

interface ISelectComponent {
  onSelectItem: any;
  options?: any[];
  title?: string;
  hasSearch?: boolean;
  loading?: boolean;
  defaultInputValue?: string;
  allowCustomOption?: boolean;
  onInputChange?: (value) => void;
  useParentFilter?: boolean;
  placeholder?: string;
  containerClass?: any;
  marginBottom?: number;
  error?: JSX.Element;
  compactBackbone?: any;
  handleChangeCompactSort?: (model?: SortType[]) => void;
  hasSortButton?: boolean;
}

const SelectComponent = ({
  onSelectItem,
  options,
  title = '',
  hasSearch = true,
  loading = false,
  error = null,
  defaultInputValue = '',
  allowCustomOption = false,
  onInputChange = (value) => undefined,
  useParentFilter = false,
  placeholder = '',
  containerClass,
  marginBottom = 20,
  compactBackbone,
  handleChangeCompactSort,
  hasSortButton = false,
}: ISelectComponent) => {
  const classes = useStyles();

  const [inputValue, setInputValue] = React.useState(defaultInputValue);

  const handleSetText = React.useCallback(debounce(onInputChange, 300), []);

  React.useEffect(() => {
    handleSetText(inputValue);
  }, [inputValue]);

  const filteredOptions = React.useMemo(() => {
    if (useParentFilter) return options || [];

    const finalOptions = options.filter(
      (option) => option.label.toLowerCase().includes(inputValue.toLowerCase()) || !hasSearch,
    );

    return finalOptions.length || !allowCustomOption
      ? finalOptions
      : [
          {
            label: `Create "${inputValue}"`,
            value: inputValue,
          },
        ];
  }, [options, hasSearch, inputValue]);

  const filteredOptionsLength = filteredOptions.length;

  const windowHeight = window.innerHeight;
  const listHeightPercent = 0.7;

  const widthList = React.useMemo(() => {
    let hasAdditionalWidth = false;
    const mapped = options
      .filter((el) => el.label)
      .map((el) => {
        const additionalWidth = el.additionalInfomrations
          ? el.additionalInfomrations.reduce((a, b) => a + parseInt(b.maxColumnWidth) || 100, 0)
          : 0;
        if (additionalWidth > 0) hasAdditionalWidth = true;
        return el.label.length * 10 + 40 + additionalWidth;
      });
    return Math.min(hasAdditionalWidth ? 1024 : 450, Math.max(...mapped, 250));
  }, [options]);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (inputRef.current) inputRef.current.focus();
  }, [inputRef.current]);

  return (
    <Box className={clsx(classes.container, containerClass)}>
      {title && (
        <Box className={classes.title}>
          <span>{title}</span>
        </Box>
      )}
      <Box className={classes.header}>
        {hasSearch && (
          <TextField
            className={clsx(classes.textStyle, classes.textSearch)}
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={classes.inputIcon}>
                  <Search style={{ width: 12, height: 14 }} />
                </InputAdornment>
              ),
              endAdornment: (
                <IconButton onClick={() => setInputValue('')} style={{ opacity: inputValue ? 1 : 0 }}>
                  <Icon type={'closeCircle'} />
                </IconButton>
              ),
              classes: { input: classes['input'] },
              fullWidth: true,
            }}
            inputRef={inputRef}
            placeholder={placeholder}
          />
        )}
        {hasSortButton ? (
          <TableBackboneContext.Provider value={compactBackbone}>
            <ButtonSort callback={handleChangeCompactSort} />
          </TableBackboneContext.Provider>
        ) : null}
      </Box>
      {error && <Box className={classes.error}>{error}</Box>}
      {loading ? (
        <Box className={classes.loading}>
          <LoadingIcon color={'#253746'} />
        </Box>
      ) : (
        <React.Fragment>
          {!error && filteredOptions.length === 0 ? (
            <span>0 results</span>
          ) : (
            <List>
              <FixedSizeList
                height={
                  filteredOptionsLength * 35 + marginBottom > windowHeight * listHeightPercent
                    ? windowHeight * listHeightPercent
                    : filteredOptionsLength * 35 + marginBottom
                }
                itemData={{ options: filteredOptions, onSelectItem }}
                itemCount={filteredOptionsLength}
                itemSize={35}
                width={widthList}
              >
                {Row}
              </FixedSizeList>
            </List>
          )}
        </React.Fragment>
      )}
    </Box>
  );
};

export default SelectComponent;
