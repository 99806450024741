import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';
import ReactDOM from 'react-dom';
import { GRID_BORDER_RADIUS, GRID_MARGIN, SCREEN_COLUMNS } from '../../constant';
import type { GridAdapterContextType } from './type';

// Helper for generating column width
export function calcGridColWidth(positionParams): number {
  const { margin, containerPadding, containerWidth, cols } = positionParams;
  return (containerWidth - margin[0] * (cols - 1) - containerPadding[0] * 2) / cols;
}

// Similar to _.clamp
export function clamp(num: number, lowerBound: number, upperBound: number) {
  return Math.max(Math.min(num, upperBound), lowerBound);
}

/**
 * Translate x and y coordinates from pixels to grid units.
 * @param  {PositionParams} positionParams  Parameters of grid needed for coordinates calculations.
 * @param  {Number} top                     Top position (relative to parent) in pixels.
 * @param  {Number} left                    Left position (relative to parent) in pixels.
 * @param  {Number} w                       W coordinate in grid units.
 * @param  {Number} h                       H coordinate in grid units.
 * @return {Object}                         x and y in grid units.
 */
export function calcXY(positionParams, top: number, left: number, w: number, h: number): { x: number; y: number } {
  const { margin, cols, rowHeight, maxRows } = positionParams;
  const colWidth = calcGridColWidth(positionParams);

  let x = Math.round((left - margin[0]) / (colWidth + margin[0]));
  let y = Math.round((top - margin[1]) / (rowHeight + margin[1]));

  // Capping
  x = clamp(x, 0, cols - w);
  y = clamp(y, 0, maxRows - h);
  return { x, y };
}

// eslint-disable-next-line no-unused-vars
type WPProps = {
  measureBeforeMount?: boolean;
  breakpoints: {
    [key: string]: number;
  };
  unitWidth: number;
  className?: string;
  style?: React.CSSProperties;
  margin?: number;
  // TODO auto generate the background
  cols?: any;
  onAddNewNode: (pos: { x: number; y: number }) => void;
  onBreakpointChange?: (bp) => void;
  onBlockWidthUnitChange?: (size: number) => void;
};

type WPState = {
  width: number;
  unitWidth: number;
  gridBg: string;
  cols: number;
  breakpoints: {
    [key: string]: number;
  };
  bp: string;
};

export const GridAdapterContext = React.createContext<GridAdapterContextType>({
  cols: 0,
  squareWidth: 0,
  margin: 0,
});

const getSvgGridBg = (unitWidth, marginWidth) => {
  const totalWidth = unitWidth + marginWidth;
  const borderRadius = Math.max(Math.round(GRID_BORDER_RADIUS * (marginWidth / GRID_MARGIN)), 1);
  const svg = `
    <svg width="${totalWidth}" height="${totalWidth}" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="${unitWidth}" height="${unitWidth}" fill="#f6f7f8" rx="${borderRadius}" />
    </svg>
  `;

  return `data:image/svg+xml;utf8,${encodeURIComponent(svg)}`;
};

/*
 * A simple HOC that provides facility for listening to container resizes.
 *
 * The Flow type is pretty janky here. I can't just spread `WPProps` into this returned object - I wish I could - but it triggers
 * a flow bug of some sort that causes it to stop typechecking.
 */
export default function GridProvider<P>(ComposedComponent: React.ComponentType<P>) {
  return class WidthProvider extends React.Component<P & WPProps, WPState> {
    static defaultProps: WPProps = {
      measureBeforeMount: false,
      unitWidth: 39,
      cols: {},
      onAddNewNode: () => undefined,
      onBlockWidthUnitChange: () => undefined,
      onBreakpointChange: () => undefined,
      breakpoints: {},
    };

    static propTypes = {
      // If true, will not render children until mounted. Useful for getting the exact width before
      // rendering, to prevent any unsightly resizing.
      measureBeforeMount: PropTypes.bool,
      unitWidth: PropTypes.number,
      cols: PropTypes.number,
    };

    state = {
      width: null,
      unitWidth: null,
      cols: SCREEN_COLUMNS.lg.default,
      gridBg: '',
      breakpoints: {},
      bp: 'lg',
    };

    mounted = false;
    resizeObserver: ResizeObserver = null;
    domRef: React.RefObject<unknown>;

    constructor(props) {
      super(props);

      this.state = { ...this.state, ...props };
    }

    componentDidMount() {
      // window.addEventListener('resize', this.onWindowResize);
      // Call to properly set the breakpoint and resize the elements.
      // Note that if you're doing a full-width element, this can get a little wonky if a scrollbar
      // appears because of the grid. In that case, fire your own resize event, or set `overflow: scroll` on your body.
      this.mounted = true;
      this.setInitialBreakpoint();
      this.onWindowResize();
      this.domRef = React.createRef();
      this.resizeObserver = new ResizeObserver((entries) => {
        this.onWindowResize();
      });
      this.resizeObserver.observe(document.getElementById('eip-page-container-wrapper'));
    }

    componentWillUnmount() {
      this.mounted = false;
      window.removeEventListener('resize', this.onWindowResize);
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }

    static getDerivedStateFromProps(nextProps, prevState) {
      const cols = nextProps.cols[prevState.bp];
      if (cols !== prevState.cols) {
        const marginWidth = nextProps.margin ? nextProps.margin : 0;
        const marginAllGutters = marginWidth * (cols - 1);
        const unitWidth = parseFloat(((prevState.width - marginAllGutters) / cols).toFixed(6));
        return {
          cols: cols,
          unitWidth: unitWidth,
          gridBg: `${getSvgGridBg(unitWidth, marginWidth)}`,
        };
      }

      return null;
    }

    deprecated_svgGridDefault = () => {
      return `
      data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDAlIj48ZGVmcz48cGF0dGVybiBpZD0icGF0dGVybiIgd2lkdGg9IjU1IiBoZWlnaHQ9IjU1IiB2aWV3Qm94PSIwIDAgNDAsNDAiIHBhdHRlcm5Vbml0cz0idXNlclNwYWNlT25Vc2UiIHBhdHRlcm5UcmFuc2Zvcm09InJvdGF0ZSgxMzUpICI+PHJlY3QgaWQ9InBhdHRlcm4tYmFja2dyb3VuZCIgd2lkdGg9IjQwMCUiIGhlaWdodD0iNDAwJSIgZmlsbD0icmdiYSgyNTUsIDI1NSwgMjU1LDEpIj48L3JlY3Q+IDxwYXRoIGZpbGw9InJnYmEoMzcsIDU1LCA3MCwwLjA1KSIgZD0iTSAtMTAgMzAgaCA2MCB2MSBoLTYweiBNLTEwIC0xMCBoNjAgdjEgaC02MCI+PC9wYXRoPjxwYXRoIGZpbGw9InJnYmEoMjU1LCAyNTUsIDI1NSwxKSIgZD0ibSAtMTAgMTAgaCA2MCB2MSBoLTYweiBNLTEwIC0zMCBoNjAgdjEgaC02MHoiPjwvcGF0aD48L3BhdHRlcm4+ICA8L2RlZnM+IDxyZWN0IGZpbGw9InVybCgjcGF0dGVybikiIGhlaWdodD0iMTAwJSIgd2lkdGg9IjEwMCUiPjwvcmVjdD48L3N2Zz4= 
      `;
    };

    getBreakpoint = (width) => {
      return Object.keys(this.props.breakpoints)
        .sort((i1, i2) => this.props.breakpoints[i2] - this.props.breakpoints[i1])
        .find((bp) => this.props.breakpoints[bp] < width);
    };

    setInitialBreakpoint = () => {
      const node = ReactDOM.findDOMNode(this);
      if (node instanceof HTMLElement) {
        const bp = this.getBreakpoint(node.offsetWidth);
        this.props.onBreakpointChange(bp);
      }
    };

    onWindowResize = debounce(() => {
      const marginWidth = this.props.margin ? this.props.margin : 0;

      if (!this.mounted) return;
      const node = ReactDOM.findDOMNode(this); // Flow casts this to Text | Element
      if (node instanceof HTMLElement) {
        const cWidth = node.offsetWidth;
        const bp = this.getBreakpoint(cWidth);
        const col = this.props.cols[bp];
        const widthAllGutters = marginWidth * (col - 1);
        const widthAllUnits = cWidth - widthAllGutters;
        const widthSingleUnit = Math.floor(widthAllUnits / col);
        const width = widthSingleUnit * col + widthAllGutters;

        console.info({ cWidth, width, bp, col, widthSingleUnit });
        console.info(getSvgGridBg(widthSingleUnit, marginWidth));

        this.setState({
          cols: col,
          width: width,
          breakpoints: { ...this.props.breakpoints, [bp]: width - 1 },
          unitWidth: widthSingleUnit,
          bp,
          gridBg: `${getSvgGridBg(widthSingleUnit, marginWidth)}`,
        });
        this.props.onBlockWidthUnitChange(widthSingleUnit);
      }
    }, 500);

    getPositionParams(props) {
      return {
        cols: props.cols,
        containerPadding: props.containerPadding,
        containerWidth: props.containerWidth,
        margin: props.margin,
        maxRows: props.maxRows,
        rowHeight: props.rowHeight,
      };
    }

    render() {
      const { measureBeforeMount, ...rest } = this.props;

      if (!this.mounted || this.state.width === null) {
        return <div className={this.props.className} style={this.props.style} />;
      }

      return (
        <div style={{ position: 'relative' }}>
          <GridAdapterContext.Provider
            value={{
              cols: this.state.cols,
              margin: rest.margin,
              squareWidth: this.state.unitWidth,
            }}
          >
            <ComposedComponent
              {...(rest as P)}
              innerRef={this.domRef}
              breakpoints={this.state.breakpoints}
              width={this.state.width}
              margin={[rest.margin, rest.margin]}
              rowHeight={this.state.unitWidth}
              style={{
                background: this.props.isDragging ? `url(${this.state.gridBg})` : `none`,
                width: this.state.width,
              }}
            />
          </GridAdapterContext.Provider>
        </div>
      );
    }
  };
}
