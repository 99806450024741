import { EIP_CONSTANT } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import * as React from 'react';
import ButtonAction from '../../button/button-action';
import { ContainerResponsiveContext } from '@eip/next/lib/main';

const useStyles = makeStyles((theme) => ({
  containerButton: {
    '& .eip1-MuiTypography-h5': {
      fontSize: '14px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      whiteSpace: 'nowrap',
    },
    height: '32px',
  },
}));

const ButtonCreateCampaign = (props) => {
  const classes = useStyles();
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const mainButton = backboneContext.getConfig('mainButton');
  const buttonType = get(mainButton, 'type');
  const text = get(mainButton, 'text', 'Blue button');
  // const [open, setOpen] = React.useState(false);
  // const handleClose = () => {
  //   setOpen(false);
  // };
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  const handleClick = () => {
    const clickHandler = backboneContext.getCallback('clickMainButton');
    console.log('Click create button', backboneContext, clickHandler);
    // if (mainButton.option) {
    //   setOpen(true);
    // } else if (clickHandler) {
    //   clickHandler();
    // }
    if (clickHandler) {
      clickHandler();
    }
  };

  if (text === EIP_CONSTANT.ELEMENT_DISABLED_KEY_VALUE) {
    return null;
  }

  const buttonLabel = isMobileView && text ? text.split(' ')[0] : text;

  let button = null;
  if (buttonType === 'component') {
    const componentName = get(mainButton, 'componentName');
    const ButtonComponent = backboneContext.getMainButtonComponent(componentName);
    button = ButtonComponent ? <ButtonComponent buttonText={buttonLabel} /> : null;
  } else {
    button = (
      <ButtonAction
        icon="plus"
        label={buttonLabel}
        variant={'contained'}
        color={'primary'}
        style={{ paddingTop: 5, paddingBottom: 5, height: '100%' }}
        onClick={handleClick}
      />
    );
  }

  return (
    <div className={classes.containerButton}>
      {/* button CreateCampaign */}
      {button}
    </div>
  );
};
export default ButtonCreateCampaign;
