import * as React from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { TextFieldCustom } from '../textField/textField';
import GroupButtonSubmit from '../inline-edit/cell/group-button-submit';
import { aim, eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { get, set } from 'lodash';
import moment from 'moment';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
  },
  inputText: {
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      height: '32px',
    },
    width: '100%',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  TextField: {
    width: '100%',
  },
  InputPaper: {
    '& input': {
      width: '100%',
    },
  },
  formControl: {
    marginBottom: 0,
    '&.select': {},
  },
  checkbox: {
    '& .MuiButtonBase-root.MuiCheckbox-root': {
      padding: '0 4px 0 9px',
    },
    '& .MuiTypography-root': {
      fontSize: '12px',
      color: '#253746',
    },
    marginBottom: '8px',
  },
}));

const optionsPermission = [
  {
    label: 'Viewer',
    value: 'viewer',
  },
  {
    label: 'Full access',
    value: 'full_access',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
];

async function findUserByEmail(email: string) {
  const dateFrom = moment().format('YYYY-MM-DD');
  const dateTo = moment().format('YYYY-MM-DD');
  const res = await eipRequest.post(
    EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=passport&mainColumn=user.uid',
    {
      dimensions: ['user'],
      attributes: ['user.email', 'user.uid'],
      metrics: [],
      pagination: { page: 1, limit: 100 },
      from: dateFrom,
      to: dateTo,
      sort: [{ field: 'user.created_at', sort: 'DESC' }],
      filter: {
        combinator: 'and',
        filters: [
          {
            field: 'user.type',
            operator: 'is',
            value: 'main',
            dataType: 'string',
          },
          {
            field: 'user.status',
            operator: 'is',
            value: 'active',
            dataType: 'string',
          },
          {
            field: 'user.email',
            operator: 'is',
            value: email,
            dataType: 'string',
          },
        ],
      },
      hiddenFilter: { currency: 'USD' },
      currency: 'USD',
      isSummary: true,
    },
  );

  return get(res, 'data.rows.0', null);
}

export const ViewAsUser = ({ data, title = '', onClose, onSubmit, anchorEl, setAnchorEl }: any) => {
  const classes = useStyles();
  const [viewAsEmail, setViewAsEmail] = React.useState('');
  const [viewAsAnonymous, setViewAsAnonymous] = React.useState(false);
  const [error, setError] = React.useState(null);
  const handleClosed = () => {
    onClose();
  };
  const isViewAsUserMode = React.useMemo(() => {
    const settings = aim.getUserSettings();
    return settings.adminMode?.includes(EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);
  }, []);

  const handleSubmit = async () => {
    const settings = aim.getUserSettings();
    if (viewAsAnonymous) {
      if (!isViewAsUserMode) {
        settings.adminMode = (settings.adminMode || []).concat([EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER]);
        settings.adminConfig = {
          origin: {
            userEmail: settings.profile.userEmail,
            userId: settings.profile.userId,
          },
          isAnonymous: true,
        };
      } else {
        settings.adminConfig.isAnonymous = true;
      }
      settings.profile.userEmail = settings.adminConfig?.origin?.userEmail;
      aim.setUserSettings(settings);
      window.location.reload();
      return;
    }
    const switchEmail = String(viewAsEmail);
    // check if email is valid
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(switchEmail)) {
      setError('Invalid email address.');
      return;
    }

    const existingUser = await findUserByEmail(switchEmail);
    if (existingUser === null) {
      setError('User email is not found.');
      return;
    }

    if (switchEmail === String(settings.profile.userEmail)) {
      setError('You are switching to the same user.');
      return;
    }

    if (!isViewAsUserMode) {
      settings.adminMode = (settings.adminMode || []).concat([EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER]);
      settings.adminConfig = {
        origin: {
          userEmail: settings.profile.userEmail,
          userId: settings.profile.userId,
        },
        override: {
          userId: existingUser[1],
          userEmail: switchEmail,
        },
        isAnonymous: false,
      };
    }
    settings.profile.userEmail = switchEmail;
    settings.adminConfig.override = {
      userId: existingUser[1],
      userEmail: switchEmail,
    };
    settings.adminConfig.isAnonymous = false;

    aim.setUserSettings(settings);

    window.location.reload();
    return;
  };

  const handleResetView = () => {
    const settings = aim.getUserSettings();
    if (!isViewAsUserMode) {
      return;
    }
    aim.resetAdminMode(EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);

    window.location.reload();
  };

  return (
    <>
      <div style={{ padding: '0 10px', minWidth: '320px' }}>
        <HeaderList title={'View as user'} variant="h4" className={classes.header} />
        <div className={classes.dialog}>
          <TextFieldCustom
            placeholder="user@example.com"
            error={error}
            errorMessage={[error]}
            onChangeText={(userEmail) => {
              setViewAsEmail(userEmail);
            }}
            disabled={viewAsAnonymous}
          />
        </div>
        <div className={classes.checkbox}>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={viewAsAnonymous} onChange={(e) => setViewAsAnonymous(e.target.checked)} />}
              label="View as new user"
            />
          </FormGroup>
        </div>
        <Grid container direction="row" justifyContent="flex-end" spacing={2}>
          <Grid item>
            <Button disabled={!isViewAsUserMode} variant="contained" color="secondary" onClick={handleResetView}>
              Reset view
            </Button>
          </Grid>
          <Grid item style={{ flexGrow: 1 }}></Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={handleClosed}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
