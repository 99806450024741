import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const ErrorStatus = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <path
      d="M3.56699 0.75C3.75944 0.416667 4.24056 0.416667 4.43301 0.75L7.89711 6.75C8.08956 7.08333 7.849 7.5 7.4641 7.5H0.535899C0.150999 7.5 -0.0895643 7.08333 0.102886 6.75L3.56699 0.75Z"
      fill={'#D4290D'}
    />
  </SvgIcon>
);
export default ErrorStatus;
