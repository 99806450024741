import * as React from 'react';

import {
  Grid,
  Divider,
  TextField,
  ListItemIcon,
  Button,
  RadioGroup,
  Box,
  FormControlLabel,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { OptionSelectType } from '../list-control/type';
import Typography from '../text-style/Typography';
import SelectSearchFormIcon from '../form-control/select-form/select-search-form-icon';
import Dropdown from '../dropdown-menu/dropdown';
import MenuList, { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import Icon from '@ep/insight-ui/icons/Icon';

import RadioItem from './control-group';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';

import FlexBox, { FlexBoxItem } from '../share-with-collaborators/Flexbox';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { get } from 'lodash';
import SelectMultipleForm from '../form-control/select-form/select-multiple-form';
import { SortValue } from './data-demo';
import moment from 'moment';

const useStyle = makeStyles(() => ({
  root: {
    ...(ff.reuse_our_chart ? {} : { width: '240px' }),
    padding: '5px',
    boxSizing: 'border-box',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '240px',
    padding: '12px',
  },
  group: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '12px',
    '& h6': {
      lineHeight: '1.35',
    },
  },
  titltFormat: {
    marginBottom: '10px',
  },
  'mb-4': {
    marginBottom: '4px',
  },
  'mb-16': {
    marginBottom: '16px',
  },
  'mb-14': {
    marginBottom: '14px',
  },
  'mb-6': {
    marginBottom: '6px',
  },
  'mb-0': {
    marginBottom: '0px !important',
  },
  'mr-2': {
    marginRight: '2px',
  },
  'ml-2': {
    marginLeft: '2px',
  },
  'mx-2': {
    marginLeft: '2px',
    marginRight: '2px',
  },
  button: {
    justifyContent: 'flex-start',
    width: '100%',
    height: 'auto',
    padding: '2px 4px',
    '& span.eip1-MuiButton-endIcon': {
      marginLeft: 'auto',
    },
    '& span.eip1-MuiButton-startIcon': {
      marginRight: '4px',
    },
  },
  icon: {
    borderRadius: '50%',
    width: 16,
    height: 12,
    border: '2px solid #C2C7CB',
    backgroundColor: 'white',
  },
  checkedIcon: {
    borderRadius: '50%',
    width: 16,
    height: 16,
    backgroundColor: '#006EC6',
    borderColor: '#006EC6',
    '&:before': {
      display: 'block',
      width: 8,
      height: 8,
      backgroundColor: 'white',
      content: '""',
      transform: 'translate(50%, 50%)',
      borderRadius: '50%',
    },
    'input:hover ~ &': {
      backgroundColor: '#106ba3',
    },
  },
  select: {
    '& .eip1-MuiInputBase-root': {
      padding: '6px 8px',
      '& input': {
        padding: '0px !important',
        height: '20px',
        color: colors.text.default,
      },
      '& .eip1-MuiAutocomplete-endAdornment': {
        top: '50%',
        color: colors.icon.default,
      },
      '& .eip1-MuiButtonBase-root': {
        marginRight: '-8px',
        padding: '8px',
        backgroundColor: 'transparent',
        color: colors.icon.default,
        '& .eip1-MuiIconButton-label': {
          width: '16px',
          height: '16px',
          '& .eip1-MuiSvgIcon-root': {
            fontSize: '8px !important',
          },
        },
        '& .eip1-MuiTouchRipple-root': {
          display: 'none',
        },
        '&:active': {
          backgroundColor: 'transparent',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-root:hover .eip1-MuiOutlinedInput-notchedOutline': {
      borderColor: '#006EC6',
    },
  },
  selectWithIcon: {
    '& .eip1-MuiListItemIcon-root': {
      minWidth: 'unset',
      marginRight: '4px',
      marginBottom: '0px',
      marginLeft: '0px',
    },
  },
  classNameIcon: {
    borderRadius: '4px',
  },
  buttonFormat: {
    minWidth: 'unset',
    padding: '6px',
    backgroundColor: 'transparent',
  },
  buttonColor: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: 'transparent',
  },
  buttonColorActive: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: '#E4E7E9',
  },
  box12: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
  buttonBgIconWhite: {
    '&.eip1-MuiButton-active .eip1-MuiButton-startIcon': {
      borderRadius: '4px',
      backgroundColor: 'white',
    },
    '&:active .eip1-MuiButton-startIcon': {
      borderRadius: '4px',
      backgroundColor: 'white',
    },
  },
  switchCompare: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .eip1-MuiIconButton-root': {
      padding: '8px',
    },
  },
  rangeMonth: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export type OptionSelectChart = OptionSelectType & { labelType: string };

interface ChartTypeProps {
  optionsChart: OptionSelectChart[];
  optionsType: OptionSelectType[];
  optionsHeading: DropdownMenuDataType[][];
  optionsColor: DropdownMenuDataType[][];
  optionsColumnColor: string[];
  optionsGroupPeriod: OptionSelectType[];
  optionsCohorts: any;
  optionsGroupBy: any;
  optionsDimension: any;
  sectionDisplay: any;
  optionsColumnStacked: any;
  optionsHiddenButton: any;
  sortOptions: OptionSelectType[];
  optionsDisplayAllBlock: any;
  defaultOptionsHiddenButton?: any;
  onSubmit?: any;
  nodeData?: any;
}

const ChartType: React.FC<ChartTypeProps> = ({
  optionsChart,
  optionsType,
  optionsGroupPeriod,
  optionsHeading,
  optionsColor,
  optionsColumnColor,
  optionsCohorts,
  optionsGroupBy,
  optionsDimension,
  sectionDisplay,
  optionsColumnStacked,
  optionsHiddenButton,
  sortOptions,
  optionsDisplayAllBlock,
  defaultOptionsHiddenButton = [],
  onSubmit,
  nodeData,
}: ChartTypeProps) => {
  const chartConfig = get(nodeData, 'chartConfig.config', {});
  const classes = useStyle();
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const [valueChartType, setValueChartType] = React.useState('col');
  const [valueStacking, setValueStacking] = React.useState('def');
  const [isTotalQuery, setIsTotalQuery] = React.useState(false);
  const [valueTitle, setValueTitle] = React.useState('Title');
  const [valueColor, setValueColor] = React.useState([]);
  const [valueTimelineCohorts, setValueTimelineCohorts] = React.useState(false);
  const [valueGroupBy, setValueGroupBy] = React.useState('metric');
  const [valueDimension, setValueDimension] = React.useState(
    chartConfig && chartConfig.valueDimension ? chartConfig.valueDimension : null,
  );
  const [height, setHeight] = React.useState(400);
  const [valueDisplay, setValueDiplay] = React.useState('single');
  const [valueColumnStacked, setValueColumnStacked] = React.useState(false);
  const [valueColumnSort, setValueColumnSort] = React.useState(SortValue.ALPHABET);
  const [valueGroupPeriod, setValueGroupPeriod] = React.useState('daily');
  const [valueColorFormat, setValueColorFormat] = React.useState('order');
  const [valueAllBlock, setValueAllBlock] = React.useState(false);
  const [valueHiddenButtons, setValueHiddenButtons] = React.useState(defaultOptionsHiddenButton);
  const [linkToGlobalFilter, setLinkToGlobalFilter] = React.useState('no');

  const submitChartConfig = () => {
    const chartConfig = {
      isTotalQuery,
      chartType: valueChartType,
      title: valueTitle,
      color: valueColor,
      timelineCohorts: valueTimelineCohorts,
      groupBy: valueGroupBy,
      groupPeriod: valueGroupPeriod,
      valueDimension: valueDimension,
      display: valueDisplay,
      isColumnStacked: valueColumnStacked,
      sortBy: valueColumnSort,
      hiddenButton: valueHiddenButtons,
      externalFilters: valueExternalFilters,
      ...(ff.expose_property_etable ? { externalProperties: valueExternalProperties } : {}),
      height,
      displayAllBlock: valueChartType === 'comparison' ? valueAllBlock : undefined,
      linkToGlobalFilter,
      // comparasionMetrics: [comparasionMetricOne, comparasionMetricTwo]
      //   .filter((el) => !!el)
      //   .map((el) => metricOptions.find((ele) => ele.value === el)),
    };
    backboneContext.changeConfig('chartEditors', chartConfig);
    onSubmit(chartConfig);
  };

  const handleSelectColor = (color) => {
    if (valueColor.includes(color)) {
      setValueColor(valueColor.filter((el) => el != color));
    } else {
      setValueColor([...valueColor, color]);
    }
  };

  const externalFiltersOptions = React.useMemo(() => {
    return Object.keys(nodeData.mapping)
      .filter((key) => {
        return nodeData.mapping[key].propertyType === 'dimension';
      })
      .map((key) => ({
        value: key,
        label: nodeData.mapping[key].title,
      }));
  }, [nodeData]);

  const [valueExternalFilters, setValueExternalFilters] = React.useState(
    get(nodeData, ['system', 'externalFilters'], []),
  );

  let externalPropertiesOptions, valueExternalProperties, setValueExternalProperties;
  if (ff.expose_property_etable) {
    externalPropertiesOptions = [
      {
        label: 'Metrics',
        value: 'metric',
      },
    ];

    [valueExternalProperties, setValueExternalProperties] = React.useState(
      get(nodeData, ['system', 'externalProperties'], []),
    );
  }

  React.useEffect(() => {
    if (chartConfig.chartType) {
      setValueChartType(chartConfig.chartType);
      setValueTitle(chartConfig.title);
      setValueColor(chartConfig.color);
      setValueTimelineCohorts(chartConfig.timelineCohorts);
      setValueGroupBy(chartConfig.groupBy);
      setValueDimension(chartConfig.valueDimension);
      setValueDiplay(chartConfig.display);
      setIsTotalQuery(chartConfig.isTotalQuery);
      setHeight(chartConfig.height);
      setValueColumnStacked(chartConfig.isColumnStacked);
      setValueGroupPeriod(chartConfig.groupPeriod || 'daily');
      setValueHiddenButtons(chartConfig.hiddenButton);
      setValueAllBlock(chartConfig.displayAllBlock || false);
      setLinkToGlobalFilter(chartConfig.linkToGlobalFilter || 'no');
      if (chartConfig.sortBy) {
        setValueColumnSort(chartConfig.sortBy);
      }
    }
  }, []);

  return (
    <Grid container>
      {ff.reuse_our_chart ? <></> : <Grid item xs={4}></Grid>}
      <Grid item {...(ff.reuse_our_chart ? {} : { xs: 4 })} style={{ width: '100%' }}>
        <FlexBox flexDirection="column" className={classes.root}>
          <div className={classes.group} style={{ marginLeft: '5px' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isTotalQuery}
                  onChange={(event) => {
                    setIsTotalQuery(event.target.checked);
                  }}
                  name="totalQuery"
                  color="secondary"
                />
              }
              label="Is total query?"
            />
          </div>
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              External Filters
            </Typography>
            <div style={{ width: '100%' }}>
              <SelectMultipleForm
                options={externalFiltersOptions}
                value={valueExternalFilters}
                onChange={setValueExternalFilters}
                multipleSelect={true}
                searchAble={false}
                isConfigChart={true}
              />
            </div>
          </div>
          {ff.expose_property_etable && (
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                External Metric
              </Typography>
              <div style={{ width: '100%' }}>
                <SelectMultipleForm
                  options={externalPropertiesOptions}
                  value={valueExternalProperties}
                  onChange={setValueExternalProperties}
                  multipleSelect={true}
                  searchAble={false}
                  isConfigChart={true}
                />
              </div>
            </div>
          )}
          <div className={classes.group}>
            <Typography variant="h6">Link to page filter</Typography>
            <div style={{ width: '100%' }}>
              <SelectMultipleForm
                options={[
                  {
                    label: 'Yes',
                    value: 'yes',
                  },
                  {
                    label: 'No',
                    value: 'no',
                  },
                ]}
                value={linkToGlobalFilter}
                onChange={([value]) => setLinkToGlobalFilter(value)}
                multipleSelect={false}
                searchAble={false}
                isConfigChart
              />
            </div>
          </div>
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              Hide top buttons
            </Typography>
            <div style={{ width: '100%' }}>
              <SelectMultipleForm
                options={optionsHiddenButton}
                value={valueHiddenButtons}
                onChange={setValueHiddenButtons}
                multipleSelect={true}
                searchAble={false}
                isConfigChart={true}
              />
            </div>
          </div>
          <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              Chart Types
            </Typography>
            <SelectSearchFormIcon
              className={`${classes.selectWithIcon} ${classes.select}`}
              icon={true}
              options={optionsChart}
              inputWidth="100%"
              value={valueChartType}
              placeholder="Placeholder"
              onChange={(e) => setValueChartType(e)}
            />
          </div>
          <>
            {valueChartType === 'comparison' ? (
              <div className={classes.group}>
                <Typography variant="h6" className={classes['mb-4']}>
                  Display All Block
                </Typography>
                <SelectSearchFormIcon
                  className={`${classes.selectWithIcon} ${classes.select}`}
                  options={optionsDisplayAllBlock}
                  inputWidth="100%"
                  value={valueAllBlock}
                  placeholder="Placeholder"
                  onChange={(e) => setValueAllBlock(e)}
                />
              </div>
            ) : null}
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Period
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                icon={false}
                options={optionsGroupPeriod}
                inputWidth="100%"
                value={valueGroupPeriod}
                placeholder="Placeholder"
                onChange={(e) => setValueGroupPeriod(e)}
              />
            </div>
            {valueChartType === 'col' ? (
              <>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Stacked
                  </Typography>
                  <SelectSearchFormIcon
                    className={`${classes.selectWithIcon} ${classes.select}`}
                    options={optionsColumnStacked}
                    inputWidth="100%"
                    value={valueColumnStacked}
                    placeholder="Placeholder"
                    onChange={(e) => setValueColumnStacked(e)}
                  />
                </div>
                <div className={classes.group}>
                  <Typography variant="h6" className={classes['mb-4']}>
                    Sort by
                  </Typography>
                  <SelectSearchFormIcon
                    className={`${classes.selectWithIcon} ${classes.select}`}
                    options={sortOptions}
                    inputWidth="100%"
                    value={valueColumnSort}
                    placeholder="Placeholder"
                    onChange={(e) => setValueColumnSort(e)}
                  />
                </div>
              </>
            ) : null}
            {/* <div className={classes.group}>
            <Typography variant="h6" className={classes['mb-4']}>
              {optionsChart.find((option) => option.value === valueChartType).labelType}
            </Typography>
            <SelectSearchFormIcon
              className={classes.select}
              options={optionsType}
              inputWidth="100%"
              value={valueStacking}
              placeholder="Placeholder"
              onChange={(e) => setValueStacking(e)}
            />
          </div> */}
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Timeline Cohorts
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={optionsCohorts}
                inputWidth="100%"
                value={valueTimelineCohorts}
                placeholder="Placeholder"
                onChange={(e) => setValueTimelineCohorts(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Group By
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={optionsGroupBy}
                inputWidth="100%"
                value={valueGroupBy}
                placeholder="Placeholder"
                onChange={(e) => setValueGroupBy(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Value Dimension
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={optionsDimension}
                inputWidth="100%"
                value={valueDimension}
                placeholder="Placeholder"
                onChange={(e) => setValueDimension(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                Section Display As
              </Typography>
              <SelectSearchFormIcon
                className={`${classes.selectWithIcon} ${classes.select}`}
                options={sectionDisplay}
                inputWidth="100%"
                value={valueDisplay}
                placeholder="Placeholder"
                onChange={(e) => setValueDiplay(e)}
              />
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                HEIGHT
              </Typography>
              <FlexBox flexDirection="row" className={classes.select}>
                <TextField
                  placeholder="Placehoder"
                  onChange={(e) => setHeight(e.target.value)}
                  variant="outlined"
                  value={height}
                  type={'number'}
                />
              </FlexBox>
            </div>
            <Divider style={{ marginBottom: '12px' }} />
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                TITLE TEXT
              </Typography>
              <FlexBox flexDirection="column" className={classes.select}>
                <TextField
                  placeholder="Placehoder"
                  onChange={(e) => setValueTitle(e.target.value)}
                  variant="outlined"
                  value={valueTitle}
                />
              </FlexBox>
            </div>
            <div className={classes.group}>
              <Typography variant="h6" className={classes['mb-4']}>
                TITLE FORMAT
              </Typography>
              <FlexBox flexDirection="column">
                <FlexBox justifyContent="space-between" className={classes['mb-16']}>
                  {/* Heading */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <Dropdown
                      alignMenu="right"
                      icon="chevron"
                      label="Heading 1"
                      className={`${classes.button} ${classes['mr-2']}`}
                    >
                      <MenuList listMenu={optionsHeading} />
                    </Dropdown>
                  </FlexBoxItem>
                  {/* Color */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['ml-2']}`}>
                    <Dropdown
                      sizeIconStart="15px"
                      alignMenu="right"
                      icon="chevron"
                      label="Color"
                      className={`${classes.button} ${classes.buttonBgIconWhite} ${classes['ml-2']}`}
                      iconStart="colorText"
                      colorStartIcon={colors.icon.default}
                    >
                      <MenuList listMenu={optionsColor} title="Color" iconSize="18px" />
                    </Dropdown>
                  </FlexBoxItem>
                </FlexBox>
                <FlexBox justifyContent="space-between" className={classes['mb-16']}>
                  {/* Style */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="bold" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="italic" size="12px"></Icon>
                      </Button>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="underline" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                  {/* Format */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['ml-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="format" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="code" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
                <FlexBox justifyContent="flex-start">
                  {/* Align */}
                  <FlexBoxItem width="calc(50% - 6px)" className={`${classes['mr-2']}`}>
                    <FlexBox>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="alignLeft" size="12px"></Icon>
                      </Button>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={`${classes.buttonFormat} ${classes['mx-2']}`}
                      >
                        <Icon type="alignCenter" size="12px"></Icon>
                      </Button>
                      <Button variant="contained" color="secondary" className={classes.buttonFormat}>
                        <Icon type="alignRight" size="12px"></Icon>
                      </Button>
                    </FlexBox>
                  </FlexBoxItem>
                </FlexBox>
              </FlexBox>
            </div>
            <Divider style={{ marginBottom: '12px' }} />
            <div className={`${classes.group} ${classes['mb-0']}`}>
              <Typography variant="h6" className={classes['mb-6']}>
                COLOR FORMAT
              </Typography>
              <RadioGroup onChange={(e) => setValueColorFormat(e.target.value)}>
                <RadioItem label="Column order" value={valueColorFormat} radioValue="order">
                  <ListItemIcon style={{ paddingLeft: '30px', ...(ff.reuse_our_chart ? { flexWrap: 'wrap' } : {}) }}>
                    {optionsColumnColor.map((color) => (
                      <Button
                        key={color}
                        variant="contained"
                        color="secondary"
                        className={valueColor.includes(color) ? classes.buttonColorActive : classes.buttonColor}
                        onClick={() => handleSelectColor(color)}
                      >
                        <Box bgcolor={color} className={classes.box12}></Box>
                      </Button>
                    ))}
                  </ListItemIcon>
                </RadioItem>
                <RadioItem
                  label="Dimension value color"
                  value={valueColorFormat}
                  radioValue="dimension"
                  className={classes['mb-0']}
                >
                  <Typography variant="body5" style={{ marginLeft: '38px' }}>
                    Manage dimension value color
                  </Typography>
                </RadioItem>
              </RadioGroup>
            </div>
          </>
          {ff.reuse_our_chart ? (
            <Button variant="contained" color="primary" style={{ marginTop: '15px' }} onClick={submitChartConfig}>
              Submit
            </Button>
          ) : (
            <></>
          )}
        </FlexBox>
      </Grid>
    </Grid>
  );
};

export default ChartType;
