export interface IRowEDashboard {
  headers: string[];
  rows: IRowMetric[];
}

export interface IRowMetric {
  metric: string;
  title: string;
  unit: string;
  value: number;
  this_time_value: number;
  last_time_value: number;
  this_time_value_serries: IDateValue[];
  last_time_value_serries: IDateValue[];
}

export interface IDateValue {
  date: string;
  value: number;
}

export const INTRADAY_RES = {
  data: {
    rows: [
      {
        headers: [
          'metric',
          'title',
          'unit',
          'value',
          'this_time_value',
          'last_time_value',
          'this_time_value_serries',
          'last_time_value_serries',
        ],
        rows: [
          {
            metric: 'roas',
            title: 'ROAS',
            value: 5.77775728212337,
            forwardMeaning: true,
            this_time_value: 142.3569288920457,
            last_time_value: 62.3546696464901,
            this_time_value_serries: [null, null],
            last_time_value_serries: [null, null],
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 12.540540764447535,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 7.35915718092391,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 7.475003143845189,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 5.688966024941288,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 6.6430431160244705,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 6.2710007752110295,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 4.773184191916434,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 6.969098133899213,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 5.218565344555621,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 5.217586228707731,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 5.122591041029591,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 5.172999387845024,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 4.808783468298789,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 5.861565290817069,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 6.161714339218294,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 5.62621724245018,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 5.5127005186868505,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 5.068346546564619,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 3.3986973233551168,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 3.727658792404264,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 4.464538528635655,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 8.508209236179848,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 5.280956369261435,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 5.485805902826522,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 2.703829766107494,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 2.786793746432361,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 1.9691339048025027,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 1.5476764263599971,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 2.3909981244536698,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 2.183572867382382,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 2.381709504954747,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 2.315035586232239,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 2.43315313021042,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 2.4773192754037723,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 2.9148973775615166,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 3.451883063562578,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 2.925424679205797,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 2.3237160091904645,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 2.7807733365855594,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 2.7274552927946507,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 2.5842780534938368,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 2.575867902407852,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 2.4843447934621525,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 2.6814745842609287,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 3.3819412311296064,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 2.7636920103346783,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 2.804128640400704,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 2.765570339760189,
              },
            ],
          },
          {
            metric: 'direct_gmv',
            title: 'Direct Ads GMV',
            unit: 'USD',
            value: 31640.53938294161,
            forwardMeaning: true,
            this_time_value: 31640.5393829416,
            last_time_value: 11727.469544224012,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 2032.8280882801873,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 746.3341139002662,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 423.34458038422093,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 251.957307328535,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 296.3421833875391,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 588.5172571942377,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 971.3577633259056,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 1468.8472553578008,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 1252.471616779064,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 1342.6666546094523,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 1504.1722502230014,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 1328.66258407724,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 1538.26069785118,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 1617.3979840131658,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 1271.1783612197582,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 1215.4847058346288,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 1033.1515449458452,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 1127.0085762125202,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 1342.3557637703266,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 1515.207679500495,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 1655.4191561972048,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 4266.828506572555,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 1819.5873646547707,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 1031.1573873217073,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 382.7990805066866,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 268.2810441676569,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 42.942455728537404,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 81.1408000452503,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 96.51620798392312,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 122.93831336946391,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 280.9308906050121,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 308.75054815826553,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 422.4926010820921,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 580.400652340488,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 652.4405784167795,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 958.8596622981742,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 816.6545610005415,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 517.1871633868632,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 776.5070304626881,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 678.6093008912351,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 580.8771750589763,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 731.7274715872809,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 472.33126091729935,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 517.497734974382,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 887.2416342425173,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 592.1615051189818,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 531.7589363679286,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 426.4229355129879,
              },
            ],
          },
          {
            metric: 'cost',
            title: 'Cost',
            unit: 'USD',
            value: 14237.022662629364,
            forwardMeaning: false,
            this_time_value: 14237.022662629362,
            last_time_value: 10106.736113506264,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 593.9122853568399,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 299.58240140949067,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 163.42912015141886,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 120.35347748396227,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 161.69034235144713,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 287.7553184486347,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 458.73338176896453,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 633.8451777657635,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 626.7279000709841,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 640.5009719245588,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 704.3055843710665,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 711.1764836590265,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 732.8120301154149,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 653.4135518269275,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 589.8696213199856,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 548.5577260227985,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 487.4373381539199,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 583.1382637866344,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 837.998317954365,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 850.5559867906917,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 959.3748379613028,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 1077.9669695722462,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 886.4302733318444,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 627.455301031076,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 311.71061989446355,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 207.89128258548521,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 124.20994762116432,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 87.61864351039345,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 102.08613192988638,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 200.63259722480927,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 290.3727388051368,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 369.9608819639393,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 457.0496367881292,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 509.96127320386813,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 529.8602108751757,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 588.1279018027597,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 577.5641323135294,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 592.7740203351104,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 576.7395171854614,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 521.1041726677319,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 482.77733610885656,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 537.8895572367373,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 520.1493275492289,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 502.8151625621649,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 572.8416022264089,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 575.471424788687,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 486.08864921195055,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 381.0393451151858,
              },
            ],
          },
          {
            metric: 'gmv',
            title: 'Ads GMV',
            unit: 'USD',
            value: 82258.06136476227,
            forwardMeaning: true,
            this_time_value: 82258.06136476224,
            last_time_value: 27293.03655257541,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 7447.981225023647,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 2204.6739806110827,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 1221.6331869277092,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 684.6868443897976,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 1074.1159156854208,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 1804.5138250624848,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 2189.6189261639884,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 4417.329245548398,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 3270.6204997765562,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 3341.869050587495,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 3607.869476646336,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 3678.915514617921,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 3523.9343757894817,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 3830.026195938218,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 3634.6081039566207,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 3086.304936628731,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 2687.096066868452,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 2955.5468054326766,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 2848.1026402075904,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 3170.582502592407,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 4283.165927481825,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 9171.568526811387,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 4681.199597857959,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 3442.097994156069,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 842.8124524824693,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 579.350126247033,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 244.58601917457761,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 135.6053090706763,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 244.08774997708824,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 438.0958956125514,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 691.5835119919365,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 856.4726072603844,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 1112.071754412572,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 1263.3368918173917,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 1544.4881391542417,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 2030.148743441541,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 1689.6203664940813,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 1377.4384808848902,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 1603.78187154456,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 1421.288333839983,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 1247.6308744303356,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 1385.532445526483,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 1292.2302737197667,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 1348.2860789914723,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 1937.3166334758375,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 1590.4257788644081,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 1363.0551030289216,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 1053.7911111322044,
              },
            ],
          },
          {
            metric: 'total_item_sold',
            title: 'Total Items Sold',
            value: 29936,
            forwardMeaning: true,
            this_time_value: 29936,
            last_time_value: 7522,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 3749,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 1355,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 818,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 537,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 552,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 908,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 1013,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 3663,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 1942,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 1751,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 1468,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 1254,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 1388,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 1554,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 2428,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 1424,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 802,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 778,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 752,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 747,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 1053,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 0,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 0,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 0,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 317,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 178,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 99,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 50,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 84,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 172,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 324,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 334,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 400,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 486,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 528,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 564,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 551,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 528,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 523,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 455,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 426,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 459,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 505,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 538,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 0,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 0,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 1,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 0,
              },
            ],
          },
          {
            metric: 'direct_item_sold',
            title: 'Direct Ads Items Sold',
            value: 5580,
            forwardMeaning: true,
            this_time_value: 5580,
            last_time_value: 1715,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 351,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 124,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 66,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 48,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 49,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 115,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 172,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 322,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 212,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 219,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 214,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 231,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 269,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 285,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 226,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 204,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 163,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 191,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 236,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 254,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 289,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 845,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 320,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 175,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 46,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 39,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 6,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 10,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 13,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 17,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 42,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 40,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 61,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 79,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 92,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 129,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 115,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 85,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 115,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 99,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 94,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 113,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 73,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 81,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 126,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 89,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 88,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 63,
              },
            ],
          },
          {
            metric: 'direct_ads_order',
            title: 'Direct Ads Order',
            value: 5442,
            forwardMeaning: true,
            this_time_value: 5442,
            last_time_value: 1651,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 322,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 122,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 66,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 48,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 49,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 114,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 168,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 292,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 205,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 204,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 207,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 228,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 261,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 284,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 225,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 201,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 161,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 187,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 233,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 249,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 286,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 839,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 317,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 174,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 45,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 38,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 6,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 10,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 13,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 17,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 42,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 40,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 61,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 75,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 85,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 121,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 114,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 80,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 112,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 97,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 86,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 109,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 71,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 79,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 120,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 86,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 84,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 60,
              },
            ],
          },
          {
            metric: 'order',
            title: 'Total Order',
            value: 39734,
            forwardMeaning: true,
            this_time_value: 39734,
            last_time_value: 8263,
            this_time_value_serries: [
              {
                date: '2021-08-25 00:00:00',
                value: 3857,
              },
              {
                date: '2021-08-25 01:00:00',
                value: 1280,
              },
              {
                date: '2021-08-25 02:00:00',
                value: 528,
              },
              {
                date: '2021-08-25 03:00:00',
                value: 342,
              },
              {
                date: '2021-08-25 04:00:00',
                value: 392,
              },
              {
                date: '2021-08-25 05:00:00',
                value: 739,
              },
              {
                date: '2021-08-25 06:00:00',
                value: 917,
              },
              {
                date: '2021-08-25 07:00:00',
                value: 3213,
              },
              {
                date: '2021-08-25 08:00:00',
                value: 1687,
              },
              {
                date: '2021-08-25 09:00:00',
                value: 1578,
              },
              {
                date: '2021-08-25 10:00:00',
                value: 1378,
              },
              {
                date: '2021-08-25 11:00:00',
                value: 1601,
              },
              {
                date: '2021-08-25 12:00:00',
                value: 1531,
              },
              {
                date: '2021-08-25 13:00:00',
                value: 1995,
              },
              {
                date: '2021-08-25 14:00:00',
                value: 2019,
              },
              {
                date: '2021-08-25 15:00:00',
                value: 1356,
              },
              {
                date: '2021-08-25 16:00:00',
                value: 1254,
              },
              {
                date: '2021-08-25 17:00:00',
                value: 1170,
              },
              {
                date: '2021-08-25 18:00:00',
                value: 1245,
              },
              {
                date: '2021-08-25 19:00:00',
                value: 1325,
              },
              {
                date: '2021-08-25 20:00:00',
                value: 1631,
              },
              {
                date: '2021-08-25 21:00:00',
                value: 5096,
              },
              {
                date: '2021-08-25 22:00:00',
                value: 2065,
              },
              {
                date: '2021-08-25 23:00:00',
                value: 1535,
              },
            ],
            last_time_value_serries: [
              {
                date: '2021-08-24 00:00:00',
                value: 276,
              },
              {
                date: '2021-08-24 01:00:00',
                value: 158,
              },
              {
                date: '2021-08-24 02:00:00',
                value: 71,
              },
              {
                date: '2021-08-24 03:00:00',
                value: 39,
              },
              {
                date: '2021-08-24 04:00:00',
                value: 71,
              },
              {
                date: '2021-08-24 05:00:00',
                value: 125,
              },
              {
                date: '2021-08-24 06:00:00',
                value: 224,
              },
              {
                date: '2021-08-24 07:00:00',
                value: 270,
              },
              {
                date: '2021-08-24 08:00:00',
                value: 305,
              },
              {
                date: '2021-08-24 09:00:00',
                value: 415,
              },
              {
                date: '2021-08-24 10:00:00',
                value: 446,
              },
              {
                date: '2021-08-24 11:00:00',
                value: 485,
              },
              {
                date: '2021-08-24 12:00:00',
                value: 456,
              },
              {
                date: '2021-08-24 13:00:00',
                value: 416,
              },
              {
                date: '2021-08-24 14:00:00',
                value: 430,
              },
              {
                date: '2021-08-24 15:00:00',
                value: 403,
              },
              {
                date: '2021-08-24 16:00:00',
                value: 361,
              },
              {
                date: '2021-08-24 17:00:00',
                value: 408,
              },
              {
                date: '2021-08-24 18:00:00',
                value: 433,
              },
              {
                date: '2021-08-24 19:00:00',
                value: 452,
              },
              {
                date: '2021-08-24 20:00:00',
                value: 573,
              },
              {
                date: '2021-08-24 21:00:00',
                value: 597,
              },
              {
                date: '2021-08-24 22:00:00',
                value: 478,
              },
              {
                date: '2021-08-24 23:00:00',
                value: 371,
              },
            ],
          },
        ],
      },
    ],
  },
  message: 'OK',
  code: 200,
};
