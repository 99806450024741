import * as React from 'react';

import { Box } from '@material-ui/core';

import { useSpecialButtonFilter } from './hooks/use-special-button-filter';
import FilterSelection from './filter-selection';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { Provider, useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';

const SpecialButtonFilters = ({
  containerClass,
  isHidden = false,
}: {
  containerClass?: string;
  isHidden?: boolean;
}) => {
  const { backbone, specialFilterFields, defaultValues, onSubmit } = useSpecialButtonFilter();
  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);

  const specialFilterFieldsRender = specialFilterFields.map((el) => {
    return (
      <MonitorContainer component={Box} key={el.id} mId={el.id} mLabel={el.id}>
        <FilterSelection
          filter={el}
          getFilterOptionValue={backbone.getFilterOptionValue}
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          containerClass={containerClass}
          isMultiSelect={el.isMultiple == undefined ? true : el.isMultiple}
        />
      </MonitorContainer>
    );
  });

  if (isHidden) return null;

  return (
    <Provider
      initialValues={[
        [eTableAtom.tableId, ''],
        [eTableAtom.linkedObjects, linkedObjects],
      ]}
    >
      {specialFilterFieldsRender}
    </Provider>
  );
};

export default SpecialButtonFilters;
