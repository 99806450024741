import { NodeEditContext, useLog, usePage } from '@eip/next/lib/main';
import { useWorkflow as usePageContext, useWorkflowScreen } from '@ep/insight-ui/system/backbone/workflow-backbone';
import FormCampaignInfo, {
  IDisableArr,
} from '@ep/insight-ui/elements/input-form/form-templates/form-groups/campaign-details/campaign-setting-form';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';
import { CampaignTableFactory } from '@ep/insight-ui/system/workflow/campaign-details/campaign-table-factory';
import { TableCampaignDetail as TableInit } from '@ep/insight-ui/system/workflow/campaign-details/campaign-table-init';
import {
  createAdsObjects,
  createCampaign,
  getCampaignSettingInfo,
  transformCellCompactSelections,
  mappingDimensionOptions,
  getDimensionDefinition,
  updateAdObjectMethod,
  updateCampaign,
  updateCampaignTimeline,
} from '@ep/insight-ui/system/workflow/campaign-details/legacy/api-request-campaign-details';
import { Box, makeStyles } from '@material-ui/core';
import { debounce, get, isEqual, uniqueId, startCase, lowerCase } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import * as request from '../../backbone/data-source/common';
import { HelmetMetaData } from '@ep/insight-ui/elements/seo-settings/helmet-data';
import { Provider } from 'jotai';
import { PersonalizationAtom } from '../../backbone/atom/campaign-detail';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { MonitorContainer } from '../../util/monitor/container';

type Options = Omit<React.ComponentProps<typeof FormCampaignInfo>, 'value'>;

const log = useLog('chartlib:campaign-creator');
const mapOptionItem = (i: { value: any; label: string; payload?: any }) => {
  return {
    label: i.label,
    value: i.value,
    payload: i.payload,
  };
};

function mapOptionItemNext(i: { id: any; text: string; payload?: any }) {
  return {
    label: i.text,
    value: i.id,
    payload: i.payload,
  };
}

class CampaignCreatorChartlib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData) {
    const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);

    const onMainTableUpdateConfig = React.useCallback((config) => {
      const personalization = get(data, 'customAttributes.personalization', {});
      nodeEditContext.onUpdateCustomAttributes(data, { personalization: { ...personalization, ...config } });
    }, []);

    return (
      <NotificationGlobalProvider>
        <MonitorContainer component="div" mId={data.blockEid} mLabel={'Campaign details'}>
          <Provider
            initialValues={[[PersonalizationAtom, get(data, `customAttributes.${EIP_CONSTANT.PERSONALIZATION}`, {})]]}
          >
            <CampaignCreator onMainTableUpdateConfig={onMainTableUpdateConfig}></CampaignCreator>
          </Provider>
        </MonitorContainer>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <div></div>;
  }
}

export default {
  blockType: 'tempCampaignCreator',
  label: 'Campaign Creator',
  blockComponent: CampaignCreatorChartlib,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg,
    h: 12,
  },
};
const CONSTANT_VALUES = {
  adObjectMethod: {
    MANUAL: 'MANUAL_OPERATION',
    Botep: 'EPSILO_OPERATION',
    MARKETPLACE: 'COMBINED_OPERATION',
  },
  objective: {
    sale: 'SALE',
    traffic: 'TRAFFIC',
  },
  marketplaceCode: {
    SHOPEE: 'SHOPEE',
    LAZADA: 'LAZADA',
    TOKOPEDIA: 'TOKOPEDIA',
  },
  status: {
    DRAFT: 'DRAFT',
    PUBLISHING: 'PUBLISHING',
    SCHEDULED: 'SCHEDULED',
    ONGOING: 'ONGOING',
    PAUSED: 'PAUSED',
    SUSPENDED: 'SUSPENDED',
    RECOVERING: 'RECOVERING',
    ENDED: 'ENDED',
  },
};
const rangeAdObjectMethod = [
  CONSTANT_VALUES.adObjectMethod.MANUAL,
  CONSTANT_VALUES.adObjectMethod.Botep,
  CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
];
const disabledAdObjectMethod = {
  [CONSTANT_VALUES.marketplaceCode.SHOPEE]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
  [CONSTANT_VALUES.marketplaceCode.LAZADA]: [CONSTANT_VALUES.adObjectMethod.Botep],
  [CONSTANT_VALUES.marketplaceCode.TOKOPEDIA]: [
    CONSTANT_VALUES.adObjectMethod.Botep,
    CONSTANT_VALUES.adObjectMethod.MARKETPLACE,
  ],
};
export const CampaignCreator = ({ onMainTableUpdateConfig }) => {
  const [initialRows, setInitialRows] = React.useState([]);
  const [adsCampaignId, setAdsCampaignId] = React.useState<string | number>(null);
  const [campaignInfo, setCampaignInfo] = React.useState({
    storefrontIds: [],
    campaignId: 0,
    marketplaceIds: [],
    schedule: { dateFrom: null, dateTo: null },
    totalBudget: 0,
    dailyBudget: 0,
    bidLimit: 0,
    objective: 'SALE',
    toolCode: null,
    status: 'DRAFT',
    adObjectMethod: CONSTANT_VALUES.adObjectMethod.MANUAL,
    campaignName: '',
  });
  const [initialSetting, setInitialSetting] = React.useState(null);
  const [disableSubmit, setDisableSubmit] = React.useState(true);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(-1);
  const [currentStatus, setCurrentStatus] = React.useState('DRAFT');
  const [firstLoaded, setFirstLoaded] = React.useState(false);

  const [loadingStatus, setLoadingStatus] = React.useState<{ loading: boolean; error: boolean }>({
    loading: true,
    error: false,
  });

  const requestOptions = {
    getCountry: (forceReload) => request.default.getCountry({ forceReload }),
    getMarketplace: (forceReload) => request.default.getMarketplace({ forceReload }),
    getStorefront: (forceReload) => request.default.getStorefront({ forceReload }),
  };
  // Disable to edit campaign name in Campaign detail page for campaign status is not Draft
  React.useEffect(() => {
    if (
      campaignInfo &&
      campaignInfo.marketplaceIds.includes('SHOPEE') &&
      campaignInfo.toolCode === 'SHP_SA' &&
      campaignInfo.status !== 'DRAFT'
    ) {
      document.querySelector('.app-controls').style.pointerEvents = 'none';
    } else {
      document.querySelector('.app-controls').style.pointerEvents = null;
    }
  }, [campaignInfo]);

  const { title, updateTitle } = usePage();
  const screen = useWorkflowScreen('campaign_info');
  const pageContext = usePageContext();
  const { onToastMultiple } = useToast();

  const fastRef = React.useRef({
    marketplaceCode: null,
    isScreenReady: false,
    campaignInfo: campaignInfo,
    title: null,
    campaignIdWithEmptyData: false,
    adsCalendarId: null,
    previousCampaignInfo: campaignInfo,
  });

  // log('currentPage', currentPage);

  const [options, setOptions] = React.useState<Options>({
    marketplace: [],
    adType: [],
    country: [],
    storefront: [],
    status: [],
    objective: [],
    adObjectMethod: [],
    disabledArr: [],
  });

  React.useEffect(() => {
    fastRef.current.title = title;
  });

  const debounceUpdateCampaign = React.useRef(
    debounce(
      ({ id, marketplaceCode, data }) =>
        updateCampaign({ id, marketplaceCode, data })
          // .then(() => {
          //   alert('Update campaign name successfully!');
          // })
          .catch(() => {
            alert('Update campaign name failed');
          }),
      1000,
    ),
  );

  React.useEffect(() => {
    if (campaignInfo.campaignId && title && title !== campaignInfo.campaignName) {
      debounceUpdateCampaign.current({
        id: campaignInfo.campaignId.toString(),
        marketplaceCode: get(campaignInfo, ['marketplaceIds', 0], null),
        data: {
          name: title,
        },
      });
      // .then(() => {
      //   alert('Update campaign name successfully!');
      // })
      // .catch(() => {
      //   alert('Update campaign name failed');
      // });
    }
  }, [title]);

  React.useEffect(() => {
    if (screen) {
      screen
        .init()
        .then(({ marketplace, adType, country, storefront, adsCampaignId, status, marketplaceCode }) => {
          setOptions({
            marketplace: marketplace.map(mapOptionItem),
            adType: adType.map(mapOptionItem),
            country: country.map(mapOptionItem),
            storefront: storefront.map(mapOptionItem),
            status: status.map(mapOptionItem),
            adObjectMethod: [],
          });
          fastRef.current.marketplaceCode = marketplaceCode;

          if (!adsCampaignId || adsCampaignId.indexOf('default') === 0) {
            setInitialSetting(campaignInfo);
            fastRef.current.isScreenReady = true;
          } else {
            setAdsCampaignId(adsCampaignId);
          }
          setLoadingStatus({
            ...loadingStatus,
            loading: false,
          });
        })
        .catch(() => {
          setLoadingStatus({ ...loadingStatus, loading: false, error: true });
        });
    } else {
      console.info('no workflow screen found');
      setLoadingStatus({ ...loadingStatus, loading: false });
    }
  }, []);

  React.useEffect(() => {
    const haveOptions = Object.values(options).some((v) => v.length > 0);
    if (haveOptions && adsCampaignId) {
      loadCampaignDetail(adsCampaignId, fastRef.current.marketplaceCode);
    }
  }, [options, adsCampaignId]);

  // FIXME: separating the campaign details submit
  React.useEffect(() => {
    if (!adsCampaignId) return;
  }, [lastUpdatedAt, adsCampaignId]);

  const loadCampaignDetail = async (campaignId, marketplaceCode = 'SHOPEE') => {
    const setting: any = await getCampaignSettingInfo({ campaignId, marketplaceCode });
    const totalBudget = get(setting, 'ADS_CAMPAIGN.total_budget', '') || '';
    const dailyBudget = get(setting, 'ADS_CAMPAIGN.daily_budget', '') || '';
    if (setting) {
      const info = {
        campaignId,
        marketplaceIds: [].concat(get(setting, 'MARKETPLACE.marketplace_code', [])),
        countryIds: [].concat(get(setting, 'COUNTRY.country_code', [])),
        storefrontIds: [].concat(get(setting, 'STOREFRONT.id', 0).toString()),
        adTypeIds: [].concat(get(setting, 'ADTYPE.ads_type', '').toLowerCase()),
        adObjectMethod:
          get(setting, 'ADS_CAMPAIGN.automated_ads_select_product', 0) === 1
            ? CONSTANT_VALUES.adObjectMethod.MARKETPLACE
            : CONSTANT_VALUES.adObjectMethod.MANUAL,
        objective: get(setting, 'ADS_CAMPAIGN.objective', ''),
        schedule: {
          dateFrom: get(setting, 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from', moment().format('YYYY-MM-DD')),
          dateTo: get(setting, 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to', ''),
        },
        totalBudget: String(totalBudget),
        dailyBudget: String(dailyBudget),
        status: get(setting, 'ADS_CAMPAIGN.status', ''),
        bidLimit: 0,
        toolCode: get(setting, 'ADTOOL.ads_tool', null),
        campaignName: get(setting, 'ADS_CAMPAIGN.name', ''),
        firstSearchSlot: get(setting, 'ADS_CAMPAIGN.first_search_slot'),
      };
      const pageTitle = get(setting, 'ADS_CAMPAIGN.name', '');
      updateTitle(pageTitle);
      setCampaignInfo(info);
      setInitialSetting(info);
      setCurrentStatus(info.status);
      fastRef.current.isScreenReady = true;
      fastRef.current.campaignInfo = info;
      fastRef.current.previousCampaignInfo = info;
      fastRef.current.adsCalendarId = get(setting, 'ADS_CALENDAR.id', '');
      const row = { ...setting, campaignId: campaignId };
      const transformedRows = transformCellCompactSelections([row], info.schedule.dateFrom, info.schedule.dateTo);
      setInitialRows(transformedRows);
    } else {
      // Add more error case
      setLoadingStatus({
        loading: false,
        error: true,
      });
    }
  };

  const checkToRecreateCampaign = (obj1: any, obj2: any) => {
    const mainFields = ['marketplaceIds', 'countryIds', 'storefrontIds' /* 'adTypeIds' */];
    const mainObj1 = {};
    const mainObj2 = {};
    mainFields.forEach((field) => {
      mainObj1[field] = obj1[field];
      mainObj2[field] = obj2[field];
    });
    return !isEqual(mainObj1, mainObj2);
  };

  const handleChangeCampaignProperties = React.useCallback(
    debounce(async (setting: any) => {
      // FIXME: not work, campaignid would be 0
      if (!firstLoaded) setFirstLoaded(true);
      log('Change setting', setting);
      const adToolCode = get(initialRows, [0, 'ad_tool', 'currentFocusItem', 'ADTOOL.ads_tool']);
      const newSetting = {
        ...campaignInfo,
        ...setting,
        campaignId: campaignInfo.campaignId,
        toolCode: campaignInfo.toolCode,
      };

      if (!isEqual(newSetting.storefrontIds, fastRef.current.campaignInfo.storefrontIds)) {
        const storefront = options.storefront.find((i) => i.value === newSetting.storefrontIds[0]);
        newSetting.marketplaceIds = [storefront.payload.channel.id];
        newSetting.countryIds = [storefront.payload.country.code];
      }

      const isRecreateCampaign = checkToRecreateCampaign(newSetting, campaignInfo);
      const marketplaceCode = get(newSetting, ['marketplaceIds', 0], '');
      fastRef.current.marketplaceCode = marketplaceCode;

      if (
        marketplaceCode == CONSTANT_VALUES.marketplaceCode.LAZADA &&
        setting.adObjectMethod !== campaignInfo.adObjectMethod &&
        !!campaignInfo.campaignId
      ) {
        const res = await updateAdObjectMethod(newSetting);
        if (!res.success) {
          onToastMultiple({
            title: 'Error',
            variant: 'error',
            messages: Object.values(res.data[0].error),
          });
        } else {
          await loadCampaignDetail(campaignInfo.campaignId, marketplaceCode);
        }
      }

      if (newSetting && !isEqual(newSetting.marketplaceIds, initialSetting ? initialSetting.marketplaceIds : [])) {
        const dimension = 'ADS_CAMPAIGN';

        let allValues = {};
        try {
          const res_Dimension = await getDimensionDefinition({ dimension, marketplaceCode });
          if (res_Dimension.success) {
            allValues = get(res_Dimension, 'data.data.value', {});
          }
        } catch (error) {
          allValues = {};
        }

        // here handle default data with marketplaceCode
        let disabledArr: IDisableArr[] = [];
        newSetting.adObjectMethod = CONSTANT_VALUES.adObjectMethod.MANUAL;
        newSetting.objective = '';
        //edit schedule
        if (
          ff.user_cannot_extend_campaign_timeline
            ? [CONSTANT_VALUES.status.PUBLISHING, CONSTANT_VALUES.status.RECOVERING].includes(campaignInfo.status)
            : [
                CONSTANT_VALUES.status.ENDED,
                CONSTANT_VALUES.status.PUBLISHING,
                CONSTANT_VALUES.status.RECOVERING,
              ].includes(campaignInfo.status)
        ) {
          disabledArr = ['schedule'];
        }

        // edit budget
        if (campaignInfo.status == CONSTANT_VALUES.status.ENDED) {
          disabledArr.push('total-budget');
          disabledArr.push('daily-budget');
        }

        //edit ad-object-method
        if (campaignInfo.status !== 'DRAFT') {
          disabledArr.push('ad-object-method');
        }

        if (marketplaceCode == CONSTANT_VALUES.marketplaceCode.LAZADA) {
          newSetting.objective = CONSTANT_VALUES.objective.sale;
        } else {
          disabledArr.push('objective');
        }

        // here handle fetch data options from server
        const adObjectMethods = mappingDimensionOptions({
          values: allValues['operation_method'],
          keyPropertyDimension: 'operation_method',
        });
        const objectives = mappingDimensionOptions({
          values: allValues['objective'],
          keyPropertyDimension: 'objective',
        });

        const adObjectOps = [];
        rangeAdObjectMethod.map((id) => {
          const el = adObjectMethods.find((i) => i.id == id);
          if (el) {
            adObjectOps.push({
              label: el.text.includes('MARKETPLACE')
                ? el.text.replace('MARKETPLACE', startCase(lowerCase(marketplaceCode)))
                : el.text,
              value: el.id,
              disabled: get(disabledAdObjectMethod, [marketplaceCode], []).includes(el.id),
            });
          }
        });

        setOptions({
          ...options,
          objective: objectives.map(mapOptionItemNext),
          adObjectMethod: adObjectOps,
          disabledArr,
        });
      }
      setInitialSetting(newSetting);
      setCampaignInfo(newSetting);
      const prevSetting = fastRef.current.previousCampaignInfo;
      const isChange = !isEqual(prevSetting, newSetting);
      const isChangeTimeline = !isEqual(prevSetting.schedule, newSetting.schedule);
      if (isChange) {
        fastRef.current.campaignInfo = newSetting;
      }
      const row = getBasisRow(newSetting);
      const isValid = Object.values(row).every((p) => p !== null);
      if (isValid) {
        if (isRecreateCampaign) {
          if (adToolCode) {
            createDraftCampaign({ ...setting, toolCode: adToolCode }, initialRows);
          } else {
            setInitialRows([row]);
          }
          setDisableSubmit(true);
        } else {
          if (campaignInfo.campaignId) {
            if (isChange)
              if (isChangeTimeline) handleUpdateCampaignTimeline(newSetting);
              else handleUpdateCampaign(newSetting);
          } else {
            if (adToolCode) {
              createDraftCampaign({ ...setting, toolCode: adToolCode }, initialRows);
              setDisableSubmit(true);
            }
          }
        }
      }
    }, 300),
    [campaignInfo, options],
  );

  let isRefresheTable, setIsRefresheTable;
  if (ff.campaign_inside_data_is_not_refresh) {
    [isRefresheTable, setIsRefresheTable] = React.useState(false);
  }

  const handleUpdateCampaignTimeline = async (setting: any) => {
    try {
      const adsCalendarId = fastRef.current.adsCalendarId;
      if (!adsCalendarId) {
        alert('Update campaign failed');
        return;
      }

      const param = {
        id: adsCalendarId,
        marketplaceCode: get(setting, ['marketplaceIds', 0], null),
        data: {},
      };
      const prevSchedule = fastRef.current.previousCampaignInfo.schedule;
      if (prevSchedule.dateFrom !== get(setting, ['schedule', 'dateFrom'])) {
        param.data['timelineFrom'] = get(setting, ['schedule', 'dateFrom'], moment().format('YYYY/MM/DD'));
      }
      let timelineTo = get(setting, ['schedule', 'dateTo'], null);
      if (timelineTo) {
        timelineTo = moment(timelineTo, EIP_CONSTANT.DATE_FORMAT)
          .set({ hour: 23, minute: 59, second: 59 })
          .format(EIP_CONSTANT.DATETIME_FORMAT);
      }
      if (prevSchedule.dateTo !== timelineTo) {
        param.data['timelineTo'] = timelineTo;
      }

      const res = await updateCampaignTimeline(param);
      if (res.success) {
        alert('Update campaign successfully!');
        if (ff.campaign_inside_data_is_not_refresh) {
          setIsRefresheTable(true);
        }
        fastRef.current.previousCampaignInfo = setting;
      } else {
        const message = Object.values(res.error).join('\n');
        const previousCampaignInfo = fastRef.current.previousCampaignInfo;
        handleChangeCampaignProperties(previousCampaignInfo);
        alert('Update campaign failed!\n' + message);
      }
    } catch (error) {
      log('handleUpdateCampaign fail', error);
    }
  };
  const handleUpdateCampaign = async (setting: any) => {
    if (firstLoaded) {
      try {
        const param = {
          id: setting.campaignId,
          marketplaceCode: get(setting, ['marketplaceIds', 0], null),
          data: {
            total_budget: get(setting, 'totalBudget') || 0,
            daily_budget: get(setting, 'dailyBudget') || 0,
            objective: get(setting, 'objective'),
          },
        };
        const status = get(setting, 'status');
        if (status !== currentStatus) {
          param.data['status'] = status;
        }
        if (get(setting, ['marketplaceIds', 0], null) === 'LAZADA') {
          param.data['firstSearchSlot'] = get(setting, ['firstSearchSlot'])
            ? Number(get(setting, ['firstSearchSlot']))
            : null;
        }
        const res = await updateCampaign(param);
        if (res.success) {
          alert('Update campaign successfully!');
          fastRef.current.previousCampaignInfo = setting;
        } else {
          const message = Object.values(res.error).join('\n');
          const previousCampaignInfo = fastRef.current.previousCampaignInfo;
          handleChangeCampaignProperties(previousCampaignInfo);
          alert('Update campaign failed!\n' + message);
        }
      } catch (error) {
        log('handleUpdateCampaign fail', error);
      }
    }
  };

  const getBasisRow = (setting) => {
    const marketplaceCode = get(setting, ['marketplaceIds', 0], null);
    const countryCode = get(setting, ['countryIds', 0], null);
    const storefrontId = get(setting, ['storefrontIds', 0], null);
    const adTypeId = get(setting, ['adTypeIds', 0], null);
    const marketplace = options.marketplace.find((i) => i.value === marketplaceCode);
    const country = options.country.find((i) => i.value === countryCode);
    const storefront = options.storefront.find((i) => i.value === storefrontId);
    const adType = options.adType.find((i) => i.value === adTypeId);
    const row = {
      'MARKETPLACE.marketplace_code': marketplaceCode,
      'MARKETPLACE.marketplace_name': marketplace ? marketplace.label : '',
      'COUNTRY.country_code': countryCode,
      'COUNTRY.country_name': country ? country.label : '',
      'STOREFRONT.id': storefrontId,
      'STOREFRONT.name': storefront ? storefront.label : '',
      // 'ADTYPE.ads_type': adTypeId,
      // 'ADTYPE.ads_type_name': adType ? adType.label : '',
      'ADS_CALENDAR.timeline_to': get(setting, ['schedule', 'dateTo'], null),
      'ADS_CALENDAR.timeline_from': get(setting, ['schedule', 'dateFrom'], ''),
      'ADS_CAMPAIGN.daily_budget': get(setting, 'dailyBudget') || 0,
      'ADS_CAMPAIGN.total_budget': get(setting, 'totalBudget') || 0,
      'STOREFRONT.currency': get(storefront, ['payload', 'country', 'currency'], ''),
      adsOpsShpAdsCampaignId: 0,
      eipCustomRowId: uniqueId('eip_custom_row_'),
      ad_tool: {
        selectedItems: [],
        displayField: 'ADTOOL.ads_tool_name',
        singleSelection: true,
        filter: [
          {
            logicalOperator: 'AND',
            field: 'marketplace',
            dataType: 'string',
            queryType: 'is',
            queryValue: marketplaceCode,
          },
        ],
        hiddenFilter: {
          combinator: 'AND',
          filters: [
            // { field: 'MARKETPLACE.marketplace_code', dataType: 'string', operator: 'equal', value: marketplaceCode },
            { field: 'STOREFRONT.id', dataType: 'integer', operator: '=', value: storefrontId },
          ],
        },
      },
      ad_object: {
        selectedItems: [],
        displayField: 'PRODUCT.name',
        hiddenFilter: {
          combinator: 'AND',
          filters: [
            {
              field: 'ADS_CALENDAR.timeline_from',
              dataType: 'date',
              operator: 'less_or_equal',
              value: get(setting, ['schedule', 'dateTo'], null),
            },
            {
              field: 'ADS_CALENDAR.timeline_to',
              dataType: 'date',
              operator: 'greater_or_equal',
              value: get(setting, ['schedule', 'dateFrom'], null),
            },
          ],
        },
      },
      keyword: {
        selectedItems: [],
        displayField: 'SUGGESTED_KEYWORD.name',
      },
    };
    return row;
  };

  const createDraftCampaign = async (setting: any, rows: any[]) => {
    log('createdraftcampaign', setting);
    const toolCode = get(setting, 'toolCode', null);
    let timelineTo = get(setting, ['schedule', 'dateTo'], null);
    if (timelineTo) {
      timelineTo = moment(timelineTo, EIP_CONSTANT.DATE_FORMAT)
        .set({ hour: 23, minute: 59, second: 59 })
        .format(EIP_CONSTANT.DATETIME_FORMAT);
    }
    const param: any = {
      data: {
        name: fastRef.current.title,
        storefrontId: get(setting, ['storefrontIds', 0], null),
        toolCode: toolCode,
        totalBudget: get(setting, 'totalBudget') || 0,
        dailyBudget: get(setting, 'dailyBudget') || 0,
        countryCode: get(setting, ['countryIds', 0]),
        adsCalendars: [
          {
            timelineFrom: get(setting, ['schedule', 'dateFrom'], moment().format('YYYY/MM/DD')),
            timelineTo,
          },
        ],
      },
      marketplaceCode: get(setting, ['marketplaceIds', 0], null),
    };

    if (get(setting, ['marketplaceIds', 0], null) === 'LAZADA') {
      param.data.objective = get(setting, ['objective'], 'SALE');
      // param.data.automated_ads = get(setting, ['adObjectMethod'], 'MANUAL_OPERATION') === 'MANUAL_OPERATION' ? 0 : 1;
      param.data.automated_ads_select_product =
        get(setting, ['adObjectMethod'], 'MANUAL_OPERATION') === 'MANUAL_OPERATION' ? 0 : 1;
      param.data.firstSearchSlot = get(setting, ['firstSearchSlot']) ? Number(get(setting, ['firstSearchSlot'])) : null;
    }

    try {
      const response = await createCampaign(param);
      if (response.success) {
        const campaignId = get(response, ['data', 'id'], 0);
        const mkplace = get(setting, ['marketplaceIds', 0], null);
        pageContext.data.set('entityQuery', { marketplace: mkplace });
        pageContext.data.set('entityId', campaignId);
        setAdsCampaignId(campaignId);
        if (toolCode === 'SHP_SA') {
          await createShopAdObject(campaignId, setting);
        }
        await loadCampaignDetail(campaignId, mkplace);
      } else {
        const message = Object.values(response.error).join('\n');
        throw Error('Create draft campaign failed!\n' + message);
      }
    } catch (error) {
      log('[Error] createCampaign', error);
      alert(error.message);
      setInitialRows((rows) => {
        const rollbackRows = rows.map((i) => ({ ...i, ad_tool: { ...i.ad_tool, selectedItems: [] } }));
        return rollbackRows;
      });
    }
  };

  const createShopAdObject = async (campaignId: string, setting: any) => {
    const addAdsObjectParam = {
      marketplaceCode: get(setting, ['marketplaceIds', 0], null),
      data: [
        {
          masterObjectId: get(setting, ['storefrontIds', 0], null),
          masterObjectType: 'STOREFRONT',
          countryCode: get(setting, ['countryIds', 0]),
          storefrontId: get(setting, ['storefrontIds', 0], null),
          toolCode: get(setting, ['toolCode', 0], null),
          type: get(setting, 'ADTYPE.ads_type', ''),
          tagline: 'Shop',
          adsCampaignId: campaignId,
          totalBudget: 0,
          dailyBudget: 0,
          adsOpsAdsCalendars: [],
        },
      ],
    };

    const res = await createAdsObjects(addAdsObjectParam);
    if (!res.success) {
      onToastMultiple({
        title: 'Error',
        variant: 'error',
        messages: Object.values(res.data[0].error),
      });
    }
  };

  const handleChangeCampaignTable = (items, type) => {
    console.trace();
    log('handleChange', items);

    const adToolCode = get(items, [0, 'ADTOOL.ads_tool']);
    if (type === 'ad_tool' && adToolCode !== fastRef.current.campaignInfo.toolCode && adToolCode) {
      createDraftCampaign({ ...fastRef.current.campaignInfo, toolCode: adToolCode }, items);
    } else {
      setLastUpdatedAt(Date.now());
    }
  };
  return (
    <LoadingComponent
      loading={(loadingStatus.loading || !initialSetting) && !loadingStatus.error}
      error={loadingStatus.error}
    >
      <Box>
        {campaignInfo.marketplaceIds[0] && (
          <HelmetMetaData
            title={`Campaign details/${campaignInfo.marketplaceIds[0]}/${title}`}
            description="Epsilo is an industry-leading platform for e-commerce teams to grow revenue with automation, research, and collaboration"
          ></HelmetMetaData>
        )}
        <FormCampaignInfo
          value={initialSetting}
          onChange={handleChangeCampaignProperties}
          disabledChooseStartDate={
            ff.user_cannot_extend_campaign_timeline
              ? [
                  CONSTANT_VALUES.status.ONGOING,
                  CONSTANT_VALUES.status.PAUSED,
                  CONSTANT_VALUES.status.SUSPENDED,
                  CONSTANT_VALUES.status.ENDED,
                ].includes(currentStatus)
              : [
                  CONSTANT_VALUES.status.ONGOING,
                  CONSTANT_VALUES.status.PAUSED,
                  CONSTANT_VALUES.status.SUSPENDED,
                ].includes(currentStatus)
          }
          requestOptions={requestOptions}
          {...options}
        />
        {!adsCampaignId && <TableInit initialRows={initialRows} onChange={handleChangeCampaignTable} />}
        {adsCampaignId && (
          <CampaignTableFactory
            key={`${adsCampaignId}-${campaignInfo.adObjectMethod}`}
            marketplace={campaignInfo.marketplaceIds[0]}
            storefrontId={campaignInfo.storefrontIds[0]}
            adTool={campaignInfo.toolCode}
            campaignId={adsCampaignId as number}
            schedule={campaignInfo.schedule}
            campaignStatus={campaignInfo.status}
            totalBudget={campaignInfo.totalBudget}
            dailyBudget={campaignInfo.dailyBudget}
            objective={campaignInfo.objective}
            currentStatus={currentStatus}
            isRefresheTable={isRefresheTable}
            onMainTableUpdateConfig={onMainTableUpdateConfig}
          />
        )}
      </Box>
    </LoadingComponent>
  );
};
