import * as React from 'react';

export const useMultipleSelect = ({ params, gridApi, handleClose }) => {
  const [loading, setLoading] = React.useState(params?.requestOptions ? true : false);
  const [options, setOptions] = React.useState(params?.options || []);
  const [selectedItems, setSelectedItems] = React.useState([]);

  React.useEffect(() => {
    if (params?.requestOptions) {
      params
        ?.requestOptions()
        .then((res) => {
          setOptions(res);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  }, []);

  const handleSubmit = () => {
    if (params?.handleSubmit) {
      params?.handleSubmit(selectedItems, gridApi, handleClose);
    } else {
      handleClose();
    }
  };

  return {
    loading,
    options,
    setOptions,
    selectedItems,
    setSelectedItems,
    handleSubmit,
  };
};
