import { TypeTabListItem } from '@ep/insight-ui/elements/app-menu/tab-list-menu';
import SelectTree from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form/select-tree';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { produce } from 'immer';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    '&.ageStart, &.ageEnd': {
      maxWidth: 64,
    },
  },
}));

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  onLoad: (param: { toolCodeIds: string[]; storefrontIds: string[] }) => Promise<void>;
};

const dataListMenuInit: Array<TypeTabListItem> = [
  {
    label: 'Greater Manila Area',
    nodeId: 'greater-manila-area',
    selected: false,
    tabs: [
      {
        label: 'North Luzon',
        selected: false,
        nodeId: 'north-luzon',
        tabs: [
          { label: 'North Luzon 1', selected: false, nodeId: 'north-luzon-1' },
          { label: 'South Luzon 2', selected: false, nodeId: 'south-luzon-2' },
        ],
      },
      { label: 'South Luzon', selected: false, nodeId: 'south-luzon' },
    ],
  },
  {
    label: 'Metro Manila',
    nodeId: 'metro-manila',
    selected: false,
    tabs: [
      { label: 'Metro Manila 1', selected: false, nodeId: 'metro-Manila-1' },
      { label: 'Metro Manila 2', selected: false, nodeId: 'metro-Manila-2' },
    ],
  },
  {
    label: 'Metro ManilaVisayas',
    nodeId: 'metro-manila-visayas',
    selected: false,
    tabs: [
      { label: 'Metro ManilaVisayas 1', selected: false, nodeId: 'metro-ManilaVisayas-1' },
      { label: 'Metro ManilaVisayas 2', selected: false, nodeId: 'metro-ManilaVisayas-2' },
    ],
  },
];

const checkSelectedTreeItem = (tree: TypeTabListItem[], value: string) => {
  for (let index = 0; index < tree.length; index++) {
    const element = tree[index];
    if (element.nodeId === value) {
      element.selected = true;
      return;
    }
    if (element.tabs && !element.selected) {
      checkSelectedTreeItem(element.tabs, value);

      const allSelected = element.tabs.every((item) => item.selected);
      const allNotSelected = element.tabs.every((item) => !item.selected);

      if (allSelected) {
        element.selected = true;
        element.indeterminate = false;
      } else if (allNotSelected) {
        element.selected = false;
        element.indeterminate = false;
      } else {
        element.selected = false;
        element.indeterminate = true;
      }
    }
  }
};

const LocationEditor = (props: Props) => {
  const { data, title = '', payload, onLoad, onSubmit, anchorEl, setAnchorEl } = props;
  const classes = useStyles();

  const defaultValue = React.useMemo(() => {
    if (data.value.subtext) {
      return data.value.subtext.split(', ');
    }
    return [''];
  }, [data.value, anchorEl]);

  const [value, setValue] = React.useState([]);
  const [options, setOptions] = React.useState([]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const initOptions = async () => {
    if (onLoad) {
      const toolCode = get(payload, ['rowPicker', 'toolCode']);
      const storefrontId = get(payload, ['rowPicker', 'storefrontId']);
      const res: any = await onLoad({
        toolCodeIds: [].concat(get(data.node, ['data', toolCode])),
        storefrontIds: [].concat(get(data.node, ['data', storefrontId])),
      });
      if (res.success) {
        const arr = res.data.map((item) => ({
          label: item.text,
          nodeId: item.id,
        }));
        setOptions(arr);
        return;
      } else {
        setOptions([]);
        return;
      }
    } else {
      setOptions([]);
      return;
    }
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  React.useEffect(() => {
    const valueProp: Array<{ id: string; text: string }> = get(data, ['value', 'value'], []);
    if (valueProp && valueProp.length > 0) {
      const arrayValue = valueProp.map((item) => {
        return { label: item.text, nodeId: item.id };
      });
      setValue(arrayValue);
    }
  }, [data.value, options]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit({
      value: produce(data.value, (draft) => {
        const arr = value.map((item) => ({ id: item.nodeId, text: item.label }));
        draft.value = JSON.stringify(arr);
      }),
      row: data.node.data,
    });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '466px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <div className={classes.block}>
          <div className={clsx(classes.form, 'age')}>
            <SelectTree chip value={value} options={options} onChange={setValue} />
          </div>
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default LocationEditor;
