import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const StarGrey = (props: SvgIconProps) => (
  <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.7871 8.05505L19.3595 8.38118C20.9264 8.51916 21.5907 10.4885 20.3874 11.5547L17.5545 14.1638C17.3289 14.377 17.2286 14.7031 17.2913 15.0167L18.1186 18.7672C18.3818 19.984 17.4166 21 16.3386 21C16.0253 21 15.6868 20.9122 15.3734 20.7115L12.528 18.8801C12.2021 18.6669 11.7759 18.6669 11.45 18.8801L8.60464 20.7115C8.29127 20.9122 7.96536 21 7.63946 21C6.54893 21 5.59628 19.9965 5.85951 18.7672L6.68681 15.0167C6.76202 14.7031 6.66174 14.377 6.42358 14.1638L3.59071 11.5547C2.42497 10.4885 3.08931 8.51916 4.6687 8.38118L8.24113 8.05505C8.57957 8.02997 8.86787 7.80418 8.99322 7.49059L10.3219 4.14146C10.6227 3.37631 11.3122 3 12.0016 3C12.691 3 13.3929 3.37631 13.6938 4.14146L15.035 7.49059C15.1604 7.80418 15.4487 8.02997 15.7871 8.05505Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);

export default StarGrey;
