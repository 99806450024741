import * as React from 'react';
import InlineEdit from '../action-components/inline-edit';
import ButtonActions from '../action-components/inline-edit/button-actions';
import { Box } from '@material-ui/core';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { eipRequest } from '@eip/next/lib/main';
import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';
import Icon from '@ep/insight-ui/icons/Icon';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

export function ScriptExecution(props) {
  const form = React.useRef(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [status, setStatus] = React.useState('');
  const [consoleLog, setConsoleLog] = React.useState('');
  const { onToast, onToastMultiple } = useToast();
  const actionConfiguration = React.useMemo(() => {
    console.info('inlineFormPayload', props.payload);
    return {
      form: props.payload.find((i) => i.field_key === 'form'),
    };
  }, [props.payload]);

  const onSubmit = React.useCallback(() => {
    const scriptIdField = props.payload.find((field) => field.field_key === 'scriptId');
    if (!scriptIdField) {
      setStatus('error_loading');
      return;
    }
    const scriptId = scriptIdField.init_value_value;
    setIsSubmitting(true);

    (async function () {
      setStatus('loading');
      const scriptDetail = await eipRequest
        .post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb_api.jsp', {
          action: 'get',
          id: scriptId,
        })
        .then((res) => {
          return res.data;
        })
        .catch(() => {
          console.error('get script content error');
          setStatus('error_loading');
        });

      let content = scriptDetail.etlScript;
      const data = form.current.getValues();
      const template = data._params;

      content = content.replaceAll('###__inject_parameters__', `
_params = ${JSON.stringify(template, null, 2)}
      `)

      await eipRequest
        .post(EIP_CONSTANT.API_HOST.RAY_URL + '/kb_api.jsp', {
          action: 'test',
          id: scriptDetail.id,
          name: scriptDetail.name,
          interval: scriptDetail.updateInterval / 60_000,
          updatePeriod: scriptDetail.updatePeriod,
          mode: scriptDetail.mode,
          filter: scriptDetail.filter,
          filterRoot: scriptDetail.filterRoot,
          filterDetail: scriptDetail.filterDetail,
          etl: content,
          realAlertTest: false,
          enabled: true,
        })
        .then((res) => {
          setStatus('success');
          setIsSubmitting(false);
          // setConsoleLog(res.output);
          onToast({
            title: 'Log',
            messages: res.output,
            variant: 'success',
          });
        })
        .catch(() => {
          setIsSubmitting(false);
          console.error('get script content error');
          setStatus('error_loading');
        });
    })();

  }, [props.payload]);

  return (
    <Box>
      <InlineEdit ref={form} {...props} />
      <Box display="flex" alignItems={'center'}>
        <StatusIndicator status={status} />
        <ButtonActions
          isSubmitting={isSubmitting}
          onCancel={() => {
            props.setAnchorEl(null)
          }}
          onSubmit={onSubmit}
          configuration={actionConfiguration}
        />
      </Box>
    </Box>
  );
}

function StatusIndicator({ status }) {
  switch (status) {
    case 'loading':
      return <CircleLoading size={'12px'} />;
    case 'error_loading':
      return <Icon type="error" />;
    default:
      return null;
  }
}
