import * as React from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Button } from '@material-ui/core';

import ETableConfig from '@ep/insight-ui/system/block/etable/etable-config';

const CompactConfigFormat = (props: ICellRendererParams) => {
  const [open, setOpen] = React.useState(false);

  const handleSubmit = (data) => {
    props.node.setDataValue(props.column.getColId(), data);
    setOpen(false);
  };

  return (
    <>
      <Button variant="contained" color="secondary" onClick={() => setOpen(true)}>
        Advanced configuration
      </Button>
      <ETableConfig
        open={open}
        onClose={() => setOpen(false)}
        config={props.compactConfig}
        onSubmit={handleSubmit}
        isCompactConfig={true}
      />
    </>
  );
};

export default CompactConfigFormat;
