/* eslint-disable react/prop-types */
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { Grid, Tooltip, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get, isArray, min, max, isEmpty as _isEmpty } from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';

import { getQueryParams, ResourceMetricInterface } from '@ep/insight-ui/system/backbone/data-source/common';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import { toValue } from '@ep/insight-ui/system/util/excel-formula';
import { enhanceCellAction } from '../table-helper';
import ConditionalWrap from '@ep/insight-ui/elements/util/conditional-wrap';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import clsx from 'clsx';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';

type StyleProps = {
  isAuto: boolean;
};
const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    lineHeight: '20px',
    flexDirection: 'row',
    height: '100%',
  },
  display: {
    display: 'none',
  },
  styleText: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: isAuto ? '#C2C7CB' : '#253746',
  }),
  subtext: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#8C98A4',
  }),
  autoSubtext: () => ({
    justifyContent: 'flex-start',
    color: '#C2C7CB',
    textAlign: 'left',
  }),
  hashtag: ({ isAuto }: StyleProps) => ({
    display: 'flex',
    fontSize: '10px',
    lineHeight: '12px',
    marginLeft: '18px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    justifyContent: 'flex-end',
    color: isAuto ? '#C2C7CB' : '#006EC6',
  }),
  numberAlign: {
    width: '100%',
    textAlign: 'right',
    justifyContent: 'flex-end',
  },
  rowGroup: {
    height: '100%',
    width: '100%',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  height100: {
    height: '100%',
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
}));

export interface IValueCurrencyFormat {
  value: number | string;
  suggestedValue?: number;
  suggestedMax?: number;
  suggestedMin?: number;
  currency: string;
  defaultCurrency: string;
  typeDisplay?: 'currency' | 'datetime' | 'text-number';
  formatDateDisplay?: string;
  hashtag?: string;
  subtext?: string;
}

const CurrencyFormat = (props: IPropsFormat<IValueCurrencyFormat>) => {
  const cellAction = get(props, ['cellAction'], []);
  const node = get(props, ['node'], []);
  const value = get(props, ['value'], []);
  const currency = get(props, ['value', 'currency'], '');
  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const classes = useStyles({ isAuto: false });
  const isEmpty = get(props, ['value', 'isEmpty'], false);
  const label = get(props, ['value', 'label'], null);

  let valueNumber;
  if (!!label) {
    valueNumber = toValue(get(props, ['value', 'label'], ''), props.value);
  } else {
    valueNumber = toValue(get(props, ['value', 'value'], ''), props.value);
  }
  const subtext = toValue(get(props, ['value', 'subtext'], ''), props.value);
  const hashtag = toValue(get(props, ['value', 'hashtag'], ''), props.value);
  const customTooltip = toValue(get(props, ['value', 'tooltip'], ''), props.value);
  const status = toValue(get(props, ['value', 'status'], ''), props.value);

  const edot = get(props, ['value', 'edot'], []);

  const resourceMetric: ResourceMetricInterface[] = get(props, ['data', 'resourceMetric'], []);

  const backbone = React.useContext(TableBackboneContext);
  const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
  const queryParams = getQueryParams(endpoint);

  const finalCurrency = React.useMemo(() => {
    if (endpoint.includes('query.jsp') || queryParams.namespace) return '';
    if (currency) return currency;
    const mappings = backbone.getConfig('mapping');
    const colId = get(props, 'colDef.colId', '');
    const valueGetter = get(mappings, `['${colId.replace(/\_cohort$/, '')}'].valueGetter.value`, '');
    const metric = resourceMetric.find((el) => el.value === valueGetter);
    const metricCurrency = get(metric, 'prefix_value', '');
    return metricCurrency === '$' ? get(node.data, ['currency']) : metricCurrency;
  }, []);

  const customStyle = React.useMemo(() => {
    const styles = Object.keys(value || {})
      .filter((el) => el.startsWith('style.'))
      .reduce((a, b) => {
        const styleName = b.replace('style.', '').replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
        let styleValue = value && value[b] ? toValue(value[b], value) : null;

        if (styleName === 'background' || styleName.includes('color')) {
          if (styleValue === 'auto') {
            styleValue = stringToTagColour(String(value));
          }
        }
        if (styleValue) {
          return {
            ...a,
            [styleName]: styleValue,
          };
        }
        return a;
      }, {});

    return styles;
  }, [value, label]);

  const getSuggestedValue = () => {
    if (isArray(valueNumber) && valueNumber.length > 0) {
      if (valueNumber.length == 1) {
        return formatCurrencyNumber(get(valueNumber, [0], 0));
      }
      return `${min(valueNumber)} ➞ ${max(valueNumber)}`;
    }

    return '';
  };

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(cellAction, edot, props.field)}
      node={node}
      value={value}
      isGrouped={isGrouped}
      gridApi={props.api}
      disabledMenu={false}
    >
      <ConditionalWrap
        condition={customTooltip}
        wrap={(children) => (
          <Tooltip
            title={String(customTooltip).split('\n').map((i, index) => {
              return (
                <React.Fragment key={index}>
                  {i}
                  <br />
                </React.Fragment>
              );
            })}
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            PopperProps={{
              popperOptions: {
                modifiers: {
                  flip: { enabled: false },
                },
              },
            }}
          >
            {children}
          </Tooltip>
        )}
      >
        {!isEmpty && (
          <Grid container alignItems={'center'} className={classes.format} wrap={'nowrap'}>
            {!_isEmpty(status) ? (
              <Grid item style={{ marginRight: '5px', flexShrink: 0, flexGrow: 0, flexBasis: '16px' }}>
                <span className={classes.wrapperIcon}>
                  <Icon type={String(status) as IconType} size={16} className={`${classes.icon} status`} />
                </span>
              </Grid>
            ) : null}
            <Grid item style={{ flexGrow: 1 }} className={classes.height100}>
              <Grid container direction="row" className={classes.height100}>
                <Grid item xs={12} className={classes.numberAlign} style={{ alignSelf: 'flex-start' }}>
                  <span className={classes.hashtag}>{hashtag}</span>
                </Grid>
                <Grid item xs={12} className={classes.numberAlign} style={{ alignSelf: 'center' }}>
                  {Object.keys(customStyle).length > 0 ? (
                    <Box
                      className={classes.styleText}
                      style={{ padding: '0 6px', borderRadius: '4px', display: 'inline-flex', ...customStyle }}
                    >
                      {!isArray(valueNumber) ? valueNumber : getSuggestedValue()} {finalCurrency}
                    </Box>
                  ) : (
                    <div className={classes.styleText}>
                      {!isArray(valueNumber) ? valueNumber : getSuggestedValue()} {finalCurrency}
                    </div>
                  )}
                </Grid>
                <Grid item xs={12} className={classes.numberAlign} style={{ alignSelf: 'flex-start' }}>
                  <span className={`${classes.subtext}`}>{subtext}</span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
      </ConditionalWrap>
    </WrapperFormat>
  );
};
export default CurrencyFormat;
