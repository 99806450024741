import { useLog, eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { produce } from 'immer';
import { cloneDeep, get, isEqual, merge, omit, set, sortBy, uniq, uniqBy } from 'lodash';
import moment from 'moment';
import * as tableConfig from './table-config';

/**
 * ff.migration_user_etable_settings_sandbox:end
 */
import { isSandboxMode } from '@ep/insight-ui/eo2/global';
/**
 * ff.migration_user_etable_settings_sandbox:end
 */

import {
  CalendarOption,
  getDateRangeFromOption,
  getCohortDateRangeFromOption,
  DateValue,
} from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';

import {
  checkEpsiloTableEndpoint,
  EpsiloTableObject,
  getQueryParams,
} from '@ep/insight-ui/system/backbone/data-source/common';
import {
  COMPACT_PAGINATION_LIMIT,
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_ETABLE_SELECTED,
  INPUT_TYPE_FIELD_FILTER,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_FIELD_VALUE,
  INPUT_TYPE_METRICS,
  INPUT_TYPE_VIEW,
  viewByTimelineOptions,
} from './etable-config/utils/constant';
import {
  LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE,
  LOCKED_VIEW_CONFIG_PROPERTY,
  PERSONALIZATION,
  POST_RELOAD_TABLE_EVENT,
  SHORTCUT_ACTIONS,
} from '../../helper/constant';
import { DATETIME_PICKER } from '@ep/insight-ui/sw/etable/constant';
import { CELL_FORMAT_OPTIONS_WITH_COMPONENT } from './cell-format-options';
import { cellFormat } from '@ep/insight-ui/elements/etable2/table-helper';
import { hasCohortProperties } from './addons/column-enhance';

import {
  customExportTable,
  enhanceDataRequest2,
  getInsightStorefrontList,
  getMetricDefinition,
  getNamespaceList,
} from './addons/enhance-data-request';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { handleUpdateVisible } from '../../helper/functions';
import { isFormulaField, toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { stripUniversalPrefix } from '@ep/insight-ui/sw/util/column';

const nextCellFormat = {
  ...CELL_FORMAT_OPTIONS_WITH_COMPONENT,
  ...cellFormat,
  ...['qualityMetric', 'oneMetric', 'operationMetric'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.currencyFormatFormula };
  }, {}),
  ...['oneDimension'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.textFormatFormula };
  }, {}),
  ...['onePivot'].reduce((carry, k) => {
    return { ...carry, [k]: cellFormat.pivotFormatFormula };
  }, {}),
};

const log = useLog('block:etable:migration');

const ETABLE_PERSONALIZED_FIELDS = EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS;
/**
 * IMPORTANT: whenever tableback configuration structure is updated, the migration should be updated
 * Guideline to update
 * * Look at the userConfig as DATA, thinking how you would migrate user data to the new one.
 */
const migrations = [
  // force applying system config
  (userConfig, systemConfig) =>
    produce((draft) => {
      const filter = [];
      (userConfig.filter || []).forEach((i, index) => {
        if (i.static !== true) {
          filter.push(userConfig.filter[index]);
        }
      });
      [...get(systemConfig, 'system.filter', [])].reverse().forEach((f) => {
        filter.unshift(f);
      });
      draft.filter = filter.filter((i) => i);
    })(userConfig),
  // simplify user filter
  (userConfig, systemConfig) =>
    produce((draft) => {
      let filter = [];
      function migrateSingleFilter(item: any): TableBackbone.filter {
        let nuFilter: TableBackbone.filter = {} as TableBackbone.filter;
        if (item.type === 'groupFilter') {
          nuFilter.type = 'groupFilter';
          nuFilter.logicalOperator = item.logicalOperator;
          (nuFilter as TableBackbone.groupFilter).subFilters = item.subFilters.map((f) => migrateSingleFilter(f));
          if (item.static) nuFilter.static = item.static;
        } else {
          nuFilter = {
            field: item.field.value,
            type: item.type,
            logicalOperator: item.logicalOperator,
            dataType: item.field.dataType,
            queryType: item.queryType.value,
            queryValue: item.queryValue.value,
          };
          if (item.static || nuFilter.field === 'marketplace') nuFilter.static = true;
        }

        return nuFilter;
      }
      (userConfig.filter || []).forEach((i) => {
        if (typeof i.field === 'object') {
          filter.push(migrateSingleFilter(i));
        } else {
          filter.push(i);
        }
      });
      filter = get(systemConfig, 'system.filter', []).concat(filter.filter((i) => !i.static));
      draft.filter = filter;
    })(userConfig),
  // make sure all the filters is not empty
  (userConfig, systemConfig) =>
    produce(userConfig, (draft) => {
      const filters: TableBackbone.filter[] = userConfig.filter || [];
      const finalFilters = [];
      filters.forEach((filter) => {
        if (filter.type === 'groupFilter' && filter.subFilters.length === 0) {
          return;
        } else if (filter.type === 'groupFilter' && !!(filter as any).field) {
          finalFilters.push({
            type: 'groupFilter',
            static: filter.static,
            subFilters: filter.subFilters,
            logicalOperator: filter.logicalOperator,
          });
        } else {
          finalFilters.push(filter);
        }
      });
      draft.filter = finalFilters;
      return draft;
    }),
  (userConfig, systemConfig) => {
    return produce(userConfig, (draft) => {
      if (userConfig.filter && userConfig.filter[0] && userConfig.filter[0].type !== 'groupFilter') {
        draft.filter = systemConfig.system.filter;
      } else if (userConfig.filter && userConfig.filter[0] && userConfig.filter[0].type === 'groupFilter') {
        const filter = userConfig.filter[0];
        if (!isEqual(filter, systemConfig.system.filter[0])) {
          draft.filter = systemConfig.system.filter;
        }
      }
    });
  },
  // re apply the system configuration
  (userConfig, systemConfig) =>
    produce((draft) => {
      const filter = [];
      (userConfig.filter || []).forEach((i, index) => {
        if (i.static !== true) {
          filter.push(userConfig.filter[index]);
        }
      });
      draft.filter = get(systemConfig, 'system.filter', []);
    })(userConfig),
  // migrate user config with new system config
  (userConfig, systemConfig) =>
    produce(userConfig, (draft) => {
      const filter = get(userConfig, ['filter', 0]);
      if (filter && filter.type === 'groupFilter' && filter.static) {
        const migrateFilters = get(systemConfig, ['system', 'filter', 0, 'subFilters'], []).reduce((acc, item) => {
          const exist = filter.subFilters.some((i) => item.field === i.field);
          return exist ? acc : [...acc, item];
        }, filter.subFilters);
        draft.filter[0].subFilters = sortBy(migrateFilters, (i) => (i.field === 'marketplace' ? 0 : 1));
      }
    }),
  (userConfig, systemConfig) =>
    produce(userConfig, (draft) => {
      const dimension = get(userConfig, 'dimension', []);
      if (dimension.length > 0) {
        if (systemConfig.mapping['campaign_tag']) {
          draft.dimension.push('campaign_tag');
        }
        if (systemConfig.mapping['keyword_tag']) {
          draft.dimension.push('keyword_tag');
        }
        if (systemConfig.mapping['ad_object_tag']) {
          draft.dimension.push('ad_object_tag');
        }
      }
    }),
];

function produceValidConfig(customConfig) {
  const configKey = get(customConfig, '_configKey');
  let validConfig = customConfig;
  if (configKey && tableConfig[configKey]) {
    const systemConfig = { ...tableConfig[configKey], _version: migrations.length };
    validConfig = produce(validConfig, (draft) => {
      uniq(Object.keys(validConfig).concat(Object.keys(systemConfig))).forEach((key) => {
        if (ETABLE_PERSONALIZED_FIELDS.indexOf(key) === -1) {
          draft[key] = systemConfig[key];
        }
        if (draft[key] === undefined && systemConfig[key]) {
          draft[key] = systemConfig[key];
        }

        if (draft[key] === undefined) {
          delete draft[key];
        }
      });
      draft._configKey = configKey;
      draft._version = validConfig._version;
    });
    validConfig = migrateConfiguration(validConfig, systemConfig);
  } else {
    log('missing configuration key', configKey);
  }
  validConfig = produce(validConfig, (draft) => {
    if (!!get(validConfig, 'endpoint.GET_TABLE_DATA')) {
      const endpoint = get(validConfig, 'endpoint.GET_TABLE_DATA', '').replace(
        '{ep.datacenter}',
        EIP_CONSTANT.API_HOST.API_DATA_CENTER,
      );
      set(draft, 'endpoint.GET_TABLE_DATA', endpoint);
      if (checkEpsiloTableEndpoint(endpoint)) {
        if (!get(validConfig, ['system', 'externalFilters'], null)) {
          set(draft, ['system', 'externalFilters'], ['country', 'marketplace', 'storefront']);
        }
        const queryParams = getQueryParams(endpoint);
        set(draft, ['isSupportGroupAggregation'], !!queryParams.namespace);
        set(draft, ['paginationIsExactTotal'], false);
        if (ff.expose_property_etable) {
          if (!get(validConfig, ['system', 'externalProperties'], null)) {
            set(draft, ['system', 'externalProperties'], ['metric']);
          }
        } else {
          set(draft, ['system', 'externalProperties'], undefined);
        }

        if (!get(validConfig, ['selectedMetricTraction'], null)) {
          const isStorefrontMetricTraction = Object.values(get(validConfig, 'mapping', {})).find(
            (el) => el.cellFormat === 'storefrontMetricTraction',
          );
          if (isStorefrontMetricTraction) {
            set(draft, ['selectedMetricTraction'], get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []));
          }
        }
        const settingType = get(validConfig, ['settingType'], []);
        if (
          get(validConfig, ['system', 'allowPivotMetric'], 'no') == 'yes' &&
          get(validConfig, ['visualizationType'], 'table') == 'table'
        ) {
          set(
            draft,
            ['settingType'],
            uniqBy(settingType.concat({ type: 'pivot_metric', label: 'Pivot metric', allowConfig: true }), 'type'),
          );
        }

        // Set column order for view
        get(validConfig, ['views'], []).forEach((_, viewIndex) => {
          if (!get(validConfig, ['views', viewIndex, 'combinator', 'columnOrder'], null)) {
            set(draft, ['views', viewIndex, 'combinator', 'columnOrder'], validConfig.columnOrder);
          }
        });

        if (!get(validConfig, ['system', 'allowCalculate'], null)) {
          set(draft, ['system', 'allowCalculate'], 'yes');
        }
      } else {
        set(draft, ['paginationIsExactTotal'], true);
      }
      if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.QUALITY)) {
        set(draft, ['isSupportGroupAggregation'], true);
        if (ff.reload_btn_quality) {
          set(draft, ['system', 'hiddenComponents'], ['search']);
          set(draft, ['mainButton'], {
            text: 'Reload',
            type: 'component',
            componentName: 'ReloadTableBtn',
          });
        } else {
          set(draft, ['system', 'hiddenComponents'], ['search', 'majorButton']);
          delete draft.mainButton;
        }
      }
      if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.OPERATION)) {
        set(draft, ['isSupportGroupAggregation'], true);
      }

      if (
        get(validConfig, ['visualizationType'], 'table') === 'chart' &&
        !get(validConfig, ['groupBy', 'columns'], []).length
      ) {
        draft.groupBy = {};
        // set(draft, ['groupBy', 'columns'], valueDimension ? [valueDimension] : []);
      }

      if (!get(validConfig, ['viewByConfig', 'timeline'], null)) {
        set(
          draft,
          ['viewByConfig', 'timeline'],
          viewByTimelineOptions.map(({ value }) => value),
        );
      }

      if (!get(validConfig, ['viewByConfig', 'dimension'], null)) {
        const dimension = get(validConfig, 'dimension', []);
        const mapping = get(validConfig, 'mapping', {});
        const viewByDimensionOptions = Object.keys(mapping)
          .filter((el) => dimension.includes(el))
          .concat(EIP_CONSTANT.VIEW_BY.GROUP_BY_NONE);
        set(draft, ['viewByConfig', 'dimension'], viewByDimensionOptions);
      }
    }

    // Set dateRange, cohortDateRange based on calendarOption, e.g: TODAY, YESTERDAY, ...
    const calendarOption: CalendarOption = get(validConfig, 'calendarOption', null);
    const calendarDateRange: DateValue = get(validConfig, 'dateRange', null);
    if (calendarOption && calendarDateRange) {
      const newDateRange = getDateRangeFromOption(calendarOption, calendarDateRange);
      set(draft, ['dateRange'], newDateRange);
      set(draft, ['_runtime', 'dateRange'], newDateRange);

      const calendarCohortOption: CalendarOption = get(validConfig, 'calendarCohort', '');
      const calendarCohortDateRange: DateValue = get(validConfig, 'cohortDateRange', null);
      const newCohortDateRange = getCohortDateRangeFromOption(
        calendarCohortOption,
        calendarCohortDateRange,
        newDateRange,
      );
      set(draft, ['cohortDateRange'], newCohortDateRange);
      set(draft, ['_runtime', 'cohortDateRange'], newCohortDateRange);
    }
  });
  log('valid config', validConfig);
  return validConfig;
}

export { produceValidConfig };

function migrateConfiguration(userConfig, systemConfig) {
  const userVersion = userConfig._version || 0;

  if (userVersion == migrations.length) return userConfig;

  const validMigrations = migrations.slice(userVersion);

  let finalConfig = userConfig;
  validMigrations.forEach((migrate) => {
    finalConfig = migrate(finalConfig, systemConfig);
  });

  finalConfig = produce(finalConfig, (draft) => {
    draft._version = migrations.length;
  });

  return finalConfig;
}

export function popuplateConfigWithCurrentView(config, view = null) {
  // apply view to top level
  let viewInfo = view;
  if (!view) viewInfo = get(config, 'view', {});

  const validConfig = produce(config, (draft) => {
    Object.keys(get(config, 'view.combinator', {})).forEach((key) => {
      if (key === 'properties') {
        Object.keys(config.view.combinator[key]).forEach((propKey) => {
          draft[propKey] = config.view.combinator[key][propKey];
        });
      } else if (key !== 'runtime') {
        draft[key] = config.view.combinator[key];
      }
    });
  });

  return validConfig;
}

export function migrateSingleSpecialFilters(config, systemConfig) {
  const singleSpecialFilters = get(systemConfig, ['system', 'externalFiltersOptions'], [])
    .filter(({ isMultiple }) => isMultiple === false)
    .map(({ value }) => value);
  if (!systemConfig && !singleSpecialFilters.length) return config;

  const validConfig = produce(config, (draft) => {
    singleSpecialFilters.forEach((key) => {
      const filterPath = ['specialFilters', key];
      const specialFilter = get(config, filterPath, {});
      const queryValuePath = ['queryValue'];
      const queryValueLabelPath = ['queryValueLabel'];
      const queryValue = get(specialFilter, queryValuePath, []).filter((i) => !!i);
      const queryValueLabel = String(get(specialFilter, queryValueLabelPath, ''));
      const systemViews = get(systemConfig, ['views'], []);
      const userViews = get(config, ['views'], []);
      const diffViews = userViews.filter(({ id }) => !systemViews.some((v) => v.id == id));
      let newSpecialFilter;
      // If current user's personalization config for single special filter is empty, use system config
      if (singleSpecialFilters.includes(key) && queryValue.length === 0) {
        newSpecialFilter = get(systemConfig, filterPath, {});
      }
      // If current user's personalization config for single special filter is more than 1 selected, keep the first one
      if (singleSpecialFilters.includes(key) && queryValue.length > 1) {
        newSpecialFilter = {
          ...get(config, filterPath, {}),
          queryValue: queryValue.slice(0, 1),
          queryValueLabel: queryValueLabel
            .split(',')
            .filter((i) => !!i)
            .slice(0, 1)
            .join(''),
        };
      }

      if (newSpecialFilter) {
        // Update specialFilters for current view
        set(draft, filterPath, newSpecialFilter);
        set(draft, ['view', 'combinator'].concat(filterPath), newSpecialFilter);

        // Update specialFilters for user view only
        diffViews.forEach((dv) => {
          const viewIndex = userViews.findIndex(({ id }) => id == dv.id);
          if (viewIndex > -1) {
            set(draft, ['views', viewIndex, 'combinator'].concat(filterPath), newSpecialFilter);
          }
        });
      }

      // Update specialFilters for userSystemView
      systemViews.forEach((sv) => {
        let newViewFilter;
        const uViewIndex = userViews.findIndex(({ id }) => id == sv.id);
        if (uViewIndex > -1) {
          const uSpecialFilter = get(config, ['views', uViewIndex, 'combinator'].concat(filterPath), {});
          const uQueryValue = get(uSpecialFilter, ['queryValue'], []);
          const uQueryValueLabel = String(get(uSpecialFilter, ['queryValueLabel'], ''));
          // If current user's personalization config for single special filter is empty, use system config
          if (singleSpecialFilters.includes(key) && uQueryValue.length === 0) {
            newViewFilter = get(sv, ['combinator'].concat(filterPath), []);
          }
          // If current user's personalization config for single special filter is more than 1 selected, keep the first one
          if (singleSpecialFilters.includes(key) && uQueryValue.length > 1) {
            newViewFilter = {
              ...uSpecialFilter,
              queryValue: uQueryValue.slice(0, 1),
              queryValueLabel: uQueryValueLabel.split(',').slice(0, 1).join(''),
            };
          }

          if (newViewFilter) {
            set(draft, ['views', uViewIndex, 'combinator'].concat(filterPath), newViewFilter);
          }
        }
      });
    });
  });

  return validConfig;
}

export function migrateRemovedColumns(config) {
  const validConfig = produce(config, (draft) => {
    const validColumnKeys = Object.keys(get(config, ['mapping'], {}));

    // Remove unused special filters
    const specialFilters = {};
    Object.keys(get(config, ['specialFilters'], {})).forEach((key) => {
      if (validColumnKeys.includes(key) && get(config, ['specialFilters', key, 'queryValue'], null)) {
        specialFilters[key] = get(config, ['specialFilters', key], {});
      }
    });
    set(draft, ['specialFilters'], specialFilters);
    set(draft, ['view', 'combinator', 'specialFilters'], specialFilters);
    get(config, ['views'], []).forEach((view, index) => {
      const viewSpecialFilters = {};
      Object.keys(get(view, ['combinator', 'specialFilters'], {})).forEach((key) => {
        if (validColumnKeys.includes(key) && get(view, ['combinator', 'specialFilters', key, 'queryValue'], null)) {
          viewSpecialFilters[key] = get(view, ['combinator', 'specialFilters', key], {});
        }
        set(draft, ['views', index, 'combinator', 'specialFilters'], viewSpecialFilters);
      });
    });

    const systemSpecialFilters = get(config, ['system', 'externalFilters'], []).filter((field) =>
      validColumnKeys.includes(field),
    );
    set(draft, ['system', 'externalFilters'], systemSpecialFilters);

    const systemSpecialFiltersOptions = get(config, ['system', 'externalFiltersOptions'], []).filter(({ value }) =>
      validColumnKeys.includes(value),
    );

    set(draft, ['system', 'externalFiltersOptions'], systemSpecialFiltersOptions);

    // Remove duplicate columns
    const availableProperties = Object.entries(config.mapping).reduce(
      (carry, [key, { propertyType }]) => {
        if (!propertyType) return carry;
        return {
          ...carry,
          [propertyType]: carry[propertyType].concat(key),
        };
      },
      {
        dimension: [],
        attribute: [],
        metric: [],
      },
    );
    Object.entries(availableProperties).forEach(([propertyType, values]) => {
      set(
        draft,
        [propertyType],
        (config[propertyType] || []).filter((el) => values.includes(el)),
      );
      set(
        draft,
        ['view', 'combinator', 'properties', propertyType],
        (get(config, ['view', 'combinator', 'properties', propertyType], []) || []).filter((el) => values.includes(el)),
      );
      config.views.forEach((_, viewIndex) => {
        set(
          draft,
          ['views', viewIndex, 'combinator', 'properties', propertyType],
          (get(config, ['views', viewIndex, 'combinator', 'properties', propertyType], []) || []).filter((el) =>
            values.includes(el),
          ),
        );
      });
    });
    // TODO: Remove another config...
  });

  return validConfig;
}

export function migrateSpecialFilters(config) {
  return produce(config, (draft) => {
    const specialFilters = Object.keys(config.specialFilters || {}).forEach((key) => {
      if (config.view.specialFilters && config.view.specialFilters[key]) {
        set(draft, ['view', 'specialFilters', key, 'queryField'], undefined);
        set(draft, ['view', 'combinator', 'specialFilters', key, 'queryField'], undefined);
      } else {
        // TODO: trace to remove this function
      }
    }, {});

    draft.specialFilters = specialFilters;

    // Handle selection type of special filters
    const externalFiltersOptions = get(config, ['system', 'externalFiltersOptions'], []).map((el) => ({
      isMultiple: true,
      ...el,
    }));
    set(draft, ['system', 'externalFiltersOptions'], externalFiltersOptions);
  });
}

export function lockedViewAdjustment(config, systemConfig) {
  const validConfig = produce(config, (draft) => {
    get(systemConfig, 'customAttributes.views', []).forEach((view) => {
      const systemViewIndex = get(config, 'views', []).findIndex((el) => el.id === view.id);
      if (systemViewIndex === -1) {
        draft.views.push(view);
        return;
      }

      const isCurrentView = get(config, 'view.id', '') === view.id;
      if (!view.isLock) {
        set(draft, ['views', systemViewIndex, 'isLock'], false);
      }

      if (isCurrentView && !view.isLock) {
        set(draft, ['view', 'isLock'], false);
      }

      if (!view.isLock) return;

      // const _keepUserConfigPaths = [
      //   ['views', systemViewIndex, 'combinator', 'filter'],
      //   ['views', systemViewIndex, 'combinator', 'specialFilters'],
      //   ['views', systemViewIndex, 'combinator', 'personalization'],
      // ];
      const keepUserConfigPaths = [
        ['view', ...LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE],
        ['view', ...LOCKED_VIEW_CONFIG_PROPERTY],
        ['views', systemViewIndex, ...LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE],
        ['views', systemViewIndex, ...LOCKED_VIEW_CONFIG_PROPERTY],
        ['view', 'combinator', 'sort'],
        ['views', systemViewIndex, 'combinator', 'sort'],
        ['view', 'combinator', 'filter'],
        ['views', systemViewIndex, 'combinator', 'filter'],
        ['view', 'combinator', 'specialFilters'],
        ['views', systemViewIndex, 'combinator', 'specialFilters'],
        ['view', 'combinator', 'properties'],
        ['views', systemViewIndex, 'combinator', 'properties'],
        ['view', 'combinator', 'groupPeriod'],
        ['views', systemViewIndex, 'combinator', 'groupPeriod'],
        ['view', 'combinator', 'personalization'],
        ['views', systemViewIndex, 'combinator', 'personalization'],
      ];

      const userView = produce(view, (draft) => {
        for (let i = 0; i < keepUserConfigPaths.length; i++) {
          const configPath = keepUserConfigPaths[i];
          const userConfig = get(config, configPath);
          set(draft, configPath.slice(2), userConfig);
        }
      });
      set(draft, ['views', systemViewIndex], userView);
      if (isCurrentView) {
        set(draft, ['view'], userView);
        set(draft, ['personalization'], get(config, ['personalization']));
      }
      const lastSystemUpdate = get(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE);
      const lastUserSystemUpdate = get(config, ['views', systemViewIndex, ...LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE]);
      if (!lastUserSystemUpdate || lastUserSystemUpdate < lastSystemUpdate) {
        const overrideConfigPaths = [
          ...(isCurrentView
            ? [
                ['view', ...LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE],
                ['view', ...LOCKED_VIEW_CONFIG_PROPERTY],
              ]
            : []),
          ['views', systemViewIndex, ...LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE],
          ['views', systemViewIndex, ...LOCKED_VIEW_CONFIG_PROPERTY],
        ];

        for (const configPath of overrideConfigPaths) {
          set(draft, configPath, get(view, configPath.slice(configPath[0] === 'view' ? 1 : 2)));
        }

        const lockedProperties = get(view, LOCKED_VIEW_CONFIG_PROPERTY, []);
        const newProperties = {};
        for (const type of ['dimension', 'attribute', 'metric']) {
          const systemProperties = get(view, ['combinator', 'properties', type], []).filter((el) => {
            return lockedProperties.includes(el);
          });
          const userProperties = get(config, ['views', systemViewIndex, 'combinator', 'properties', type], []).filter(
            (el) => {
              return !lockedProperties.includes(el);
            },
          );
          newProperties[type] = systemProperties.concat(userProperties);
        }

        if (isCurrentView) {
          set(draft, ['view', 'combinator', 'properties'], newProperties);
        }
        set(draft, ['views', systemViewIndex, 'combinator', 'properties'], newProperties);

        const lockedSort = get(view, ['combinator', 'sort'], []).filter((el) => el.locked);
        const userSort = get(config, ['views', systemViewIndex, 'combinator', 'sort'], []).filter((el) => !el.locked);
        const newSort = lockedSort.concat(userSort);

        if (isCurrentView) {
          set(draft, ['view', 'combinator', 'sort'], newSort);
        }
        set(draft, ['views', systemViewIndex, 'combinator', 'sort'], newSort);

        const lockedFilter = get(view, ['combinator', 'filter'], []).filter(
          (el) => el.static || el.isLocked || el.isHidden,
        );
        const userFilter = get(config, ['views', systemViewIndex, 'combinator', 'filter'], []).filter(
          (el) => !el.static && !el.isLocked && !el.isHidden,
        );

        const newFilter = lockedFilter.concat(userFilter);

        if (isCurrentView) {
          set(draft, ['view', 'combinator', 'filter'], newFilter);
        }
        set(draft, ['views', systemViewIndex, 'combinator', 'filter'], newFilter);
      }
    });
  });

  return validConfig;
}

export function migrateAdvancedFilter(config) {
  const advancedFilterOptions = [
    {
      key: INPUT_TYPE_ETABLE_SELECTED,
      value: '',
    },
    {
      key: INPUT_TYPE_ETABLE_CONFIG,
      value: null,
    },
    {
      key: INPUT_TYPE_VIEW,
      value: '',
    },
    {
      key: INPUT_TYPE_FIELD_LABEL,
      value: '',
    },
    {
      key: INPUT_TYPE_FIELD_VALUE,
      value: '',
    },
    {
      key: INPUT_TYPE_FIELD_FILTER,
      value: '',
    },
    {
      key: COMPACT_PAGINATION_LIMIT,
      value: '999',
    },
    {
      key: INPUT_TYPE_METRICS,
      value: '',
    },
  ];

  return produce(config, (draft) => {
    Object.keys(config.mapping).forEach((key) => {
      const currentAdvancedFilter = get(config, ['mapping', key, 'advancedFilter'], []) || [];
      const advancedFilter = advancedFilterOptions.map((opt) => {
        const currentValue = currentAdvancedFilter.find((el) => el.key === opt.key);
        return {
          ...opt,
          value: currentValue ? currentValue.value : opt.value,
        };
      });

      set(draft, ['mapping', key, 'advancedFilter'], advancedFilter);
    });
  });
}

export function datetimeSelectionAdjustment(customConfig) {
  const tractionPath = ['combinator', PERSONALIZATION, 'tractionPeriod'];
  const viewTractionPath = ['view'].concat(tractionPath);
  return produce(customConfig, (draft) => {
    if (get(customConfig, ['calendarConfig', 'typePicker'], '') !== DATETIME_PICKER) {
      const datetimeFormat = 'YYYY-MM-DD';
      set(draft, ['dateRange'], {
        dateTo: moment(get(customConfig, ['dateRange', 'dateTo'])).format(datetimeFormat),
        dateFrom: moment(get(customConfig, ['dateRange', 'dateFrom'])).format(datetimeFormat),
      });
      const viewTractionConfig = get(customConfig, viewTractionPath, {}) || {};
      Object.entries(viewTractionConfig).forEach(([key, value]) => {
        if (value?.value === 'custom') {
          set(draft, viewTractionPath.concat(key, 'customValue'), {
            dateTo: moment(get(customConfig, viewTractionPath.concat(key, 'customValue', 'dateTo'))).format(
              datetimeFormat,
            ),
            dateFrom: moment(get(customConfig, viewTractionPath.concat(key, 'customValue', 'dateFrom'))).format(
              datetimeFormat,
            ),
          });
        }
      });
      customConfig.views.forEach((view, viewIndex) => {
        const viewTractionPath = ['views', viewIndex].concat(tractionPath);
        const viewTractionConfig = get(customConfig, viewTractionPath, {}) || {};
        Object.entries(viewTractionConfig).forEach(([key, value]) => {
          if (value?.value === 'custom') {
            set(draft, viewTractionPath.concat(key, 'customValue'), {
              dateTo: moment(get(customConfig, viewTractionPath.concat(key, 'customValue', 'dateTo'))).format(
                datetimeFormat,
              ),
              dateFrom: moment(get(customConfig, viewTractionPath.concat(key, 'customValue', 'dateFrom'))).format(
                datetimeFormat,
              ),
            });
          }
        });
      });
    }
  });
}

export function migrateShortcutActions(config) {
  return produce(config, (draft) => {
    if (!get(config, [SHORTCUT_ACTIONS], null)) {
      set(draft, [SHORTCUT_ACTIONS], {
        filter: {
          shortcut: true,
          hideLabel: false,
          order: 0,
          name: 'filter',
        },
        sort: {
          shortcut: true,
          hideLabel: false,
          order: 1,
          name: 'sort',
        },
      });
    }
  });
}

export function migrateColumnsSettings(config) {
  return produce(config, (draft) => {
    const mapping = get(config, ['mapping'], {});
    const systemSettings = Object.entries(mapping).reduce((carry, [key, value]) => {
      return {
        ...carry,
        [key]: value.systemSettings || {},
      };
    }, {});

    const { hiddenColumns } = Object.entries(systemSettings).reduce(
      (carry, [k, v]) => {
        const isHidden = toValue(get(v, ['properties.state'], ''), config) == 'hidden';
        const isDisabled = toValue(get(v, ['properties.status'], ''), config) == 'disabled';

        if (isHidden) carry['hiddenColumns'].push(k);
        if (isDisabled) carry['disabledColumns'].push(k);
        return carry;
      },
      {
        disabledColumns: [],
        hiddenColumns: [],
      },
    );

    set(
      draft,
      ['dimension'],
      config.dimension.filter((i) => !hiddenColumns.includes(i)),
    );
    set(
      draft,
      ['attribute'],
      config.attribute.filter((i) => !hiddenColumns.includes(i)),
    );
    set(
      draft,
      ['metric'],
      config.metric.filter((i) => !hiddenColumns.includes(i)),
    );
  });
}

export function updateViewByQuery(config, queryView) {
  return produce(config, (draft) => {
    const selectedView = config.views?.find((view) => view.id == queryView);
    if (selectedView) {
      set(draft, ['view'], selectedView);
    }
  });
}

export function reduceConfigSize(config, systemConfig) {
  const linkedObjects = {
    actions: {},
    advancedFilter: {},
  };
  const newConfig = produce(config, (draft) => {
    Object.keys(config.mapping).forEach((key) => {
      set(
        linkedObjects,
        ['actions', key],
        get(systemConfig, ['customAttributes', 'mapping', key, 'actions'], get(config, ['mapping', key, 'actions'])),
      );
      set(
        linkedObjects,
        ['advancedFilter', key],
        get(
          systemConfig,
          ['customAttributes', 'mapping', key, 'advancedFilter'],
          get(config, ['mapping', key, 'advancedFilter']),
        ),
      );
      set(draft, ['mapping', key, 'actions'], []);
      set(draft, ['mapping', key, 'advancedFilter'], []);
    });
  });

  return [newConfig, linkedObjects];
}

const defaultConfiguration = tableConfig.DEFAULT_CONFIG;

const isValidIndexData = (config) => {
  return checkEpsiloTableEndpoint(get(config, 'endpoint.GET_TABLE_DATA', ''), [
    EpsiloTableObject.PERFORMANCE,
    EpsiloTableObject.QUALITY,
    EpsiloTableObject.OPERATION,
  ]);
};

export function enhancedETableConfig({
  customConfig,
  systemConfig,
  nodeEditContext,
  lastUpdated,
  blockEid,
  setOpenCodeEditor,
  getCustomCellActions,
  onToastMultiple,
}) {
  let validConfig = produceValidConfig(customConfig);
  validConfig = lockedViewAdjustment(validConfig, systemConfig);
  validConfig = migrateSpecialFilters(validConfig);
  validConfig = datetimeSelectionAdjustment(validConfig);
  validConfig = popuplateConfigWithCurrentView(validConfig);
  validConfig = migrateSingleSpecialFilters(validConfig, systemConfig?.customAttributes);
  validConfig = migrateRemovedColumns(validConfig);
  validConfig = migrateAdvancedFilter(validConfig);
  validConfig = migrateShortcutActions(validConfig);
  validConfig = migrateColumnsSettings(validConfig);

  const endpoint = get(validConfig, 'endpoint.GET_TABLE_DATA', '');
  const isLockedView = get(validConfig, ['view', 'isLock'], false);
  const defaultLimit = get(validConfig, ['system', 'defaultLimit']);

  const config: {
    apiRequest: Record<string, any>;
    configuration: Record<string, any>;
    addons?: Record<string, (...args: any[]) => any>;
    callback?: Record<string, any>;
    cellFormat?: Record<string, any>;
  } = {
    apiRequest: {},
    configuration: {
      ...defaultConfiguration,
      ...validConfig,
      defaultPagination: {
        limit: defaultLimit ? defaultLimit : isValidIndexData(validConfig) ? 1000 : 100,
        page: 1,
        total: 0,
        ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
      },
    },
    callback: {
      onBackboneReady: (backbone, config) => {
        const addons = get(config, 'addons', {});

        Object.keys(addons).forEach((key) => {
          if (key.startsWith('initial.request.')) {
            backbone
              .addon(key, () => Promise.resolve({ data: null }))()
              .then((res) => {
                backbone.addInitialDataRequest(key.replace('initial.request.', ''), res.data);
              });
          }
        });

        const enpointTable = get(backbone, 'config.endpoint.GET_TABLE_DATA');
        // Add Metric Options for button Properties
        const mappingConfig = get(backbone, 'config.mapping', {});
        const isStorefrontMetricTraction = Object.values(mappingConfig).find(
          (ele) => ele?.cellFormat === 'storefrontMetricTraction',
        );
        const metric = get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []);
        const metricTractionProperties = backbone.getConfig('metricTractionProperties', []);

        const isDiff =
          metric.length !== metricTractionProperties.length ||
          (metric.length === metricTractionProperties.length &&
            metricTractionProperties.some(({ id }) => !metric.includes(id)));

        if (isStorefrontMetricTraction && isDiff) {
          getMetricDefinition(enpointTable).then((res) => {
            if (res) {
              const metricProperties = metric.map((el) => {
                const metricDef = res.filter((mt) => mt.value === el)[0];
                return {
                  id: el,
                  name: get(metricDef, 'label_raw', el),
                  disable: false,
                };
              });
              backbone.changeConfig('metricTractionProperties', metricProperties);
            }
          });
        }
      },
    },
    cellFormat: nextCellFormat,
  };
  const queryParams = getQueryParams(endpoint);

  // Will remove when API is available
  const showTooltip = false;

  // Add additionalColumns for table
  config.addons = {
    ...config.addons,
    ...(ff.metric_cohort_column
      ? {
          'dataMenu.properties': (arr, handleUpdateVisibleWithId, backbone) => {
            const newProperties = [...arr];
            const allowChangeCurrency = backbone.getConfig('system.changeCurrency', 'no') == 'yes';
            if (hasCohortProperties(backbone)) {
              newProperties.unshift({
                title: 'Cohort',
                name: 'cohort',
                icon: getConst('SHORTCUT_CALENDAR_ICON', 'calendar'),
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('calendar', true),
              });
            }
            if (checkEpsiloTableEndpoint(endpoint)) {
              if (backbone.getConfig('system.allowAccumulative', 'no') === 'yes') {
                newProperties.unshift({
                  title: 'Accumulative',
                  name: 'accumulative',
                  icon: getConst('SHORTCUT_ACCUMULATIVE_ICON', 'ic/bi:bar-chart-fill/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('accumulative', true),
                });
              }
              const allowedViewByTimelineType = backbone.getConfig('viewByConfig.allowedViewByTimelineType', [
                'timeline',
                'dimension1',
                'dimension2',
              ]);
              if ((allowedViewByTimelineType || []).length) {
                newProperties.unshift({
                  title: 'View by',
                  name: 'periodically',
                  icon: getConst('SHORTCUT_VIEWBY_ICON', 'ic/uis:layer-group/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('periodically', true),
                });
              }
              if (backbone.getConfig('system.allowPivotMetric', 'no') === 'yes') {
                newProperties.unshift({
                  title: 'Pivot',
                  name: 'pivot',
                  icon: getConst('SHORTCUT_PIVOT_ICON', 'ic/icon-park-solid:pivot-table/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('pivot', true),
                });
              }
              if (allowChangeCurrency) {
                newProperties.unshift({
                  title: 'Change currency',
                  icon: getConst('SHORTCUT_CHANGE_CURRENCY_ICON', 'changeCurrency'),
                  colorStartIcon: '#253746',
                  name: 'currencySwitch',
                  onClick: () => handleUpdateVisibleWithId('currencySwitch', true),
                });
              }
              if (!isLockedView) {
                newProperties.push({
                  title: 'Reset all columns',
                  name: 'reset_all_columns',
                  icon: getConst('SHORTCUT_RESET_ALL_COLUMNS_ICON', 'rmx/restart-line-icon/black'),
                  colorStartIcon: '#253746',
                  onClick: () => {
                    let view = cloneDeep(backbone.getConfig('view'));
                    const systemViews = get(systemConfig, ['customAttributes', 'views'], []);
                    const defaultSystemViews = systemViews.find(({ id }) => id === 'default');

                    if (defaultSystemViews) {
                      const clonedSystemView = cloneDeep(defaultSystemViews);
                      set(
                        clonedSystemView,
                        ['combinator', 'columnWidth'],
                        get(defaultSystemViews, ['combinator', 'columnWidth'], []).map((el) => {
                          return {
                            ...el,
                            width: get(mappings, [el.columnField, 'staticValue', 'defaultWidth'], el.width),
                          };
                        }),
                      );

                      const views = backbone.getConfig('views', []).map((view) => {
                        if (view.id === 'default') return cloneDeep(clonedSystemView);
                        return view;
                      });

                      if (view.id === 'default') {
                        view = clonedSystemView;
                      }

                      backbone.updateConfig({
                        views,
                        view,

                        ...get(view, 'combinator.properties', {}),
                        ...omit(view.combintor, 'properties'),
                      });

                      backbone.reloadData('table', [], true);
                    }
                  },
                });
              }
            }
            const enhancedNewProperties = newProperties.map((el) => {
              if (!nodeEditContext.isEditMode) return el;
              const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
              const shortcut = shortcutActions[el.name]?.shortcut;
              return {
                ...el,
                subActions: [
                  {
                    title: shortcut ? 'Remove from shortcuts' : 'Add to shortcuts',
                    name: 'shortcut',
                    icon: shortcut ? 'ic/mdi:bookmark-off/#253746' : 'ic/mdi:bookmark/#253746',
                    colorStartIcon: '#253746',
                    onClick: () => {
                      const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
                      let updateShortcutAction = get(shortcutActions, [el.name], null);
                      const shortcut = shortcutActions[el.name]?.shortcut;
                      if (!updateShortcutAction) {
                        updateShortcutAction = {
                          name: el.name,
                          hideLabel: false,
                        };
                      }
                      const updateShortcutActions = Object.entries(
                        merge(shortcutActions, { [el.name]: updateShortcutAction }),
                      ).reduce((carry, [key, act]) => {
                        if (act.order > shortcutActions[el.name]?.order && shortcut) {
                          act.order--;
                        }
                        if (key === el.name) {
                          act.shortcut = !shortcut;
                          act.order = shortcut
                            ? null
                            : Object.values(shortcutActions).filter((act) => act.shortcut).length - 1;
                        }
                        return {
                          ...carry,
                          [key]: act,
                        };
                      }, {});
                      backbone.changeConfig(SHORTCUT_ACTIONS, updateShortcutActions);
                    },
                  },
                  {
                    title: shortcutActions[el.name]?.hideLabel ? 'Show shortcut label' : 'Hide shortcut label',
                    name: 'resize_all_columns',
                    icon: shortcutActions[el.name]?.hideLabel
                      ? 'ic/radix-icons:text/#253746'
                      : 'ic/radix-icons:text-none/#253746',
                    colorStartIcon: '#253746',
                    onClick: () => {
                      const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
                      let updateShortcutAction = get(shortcutActions, [el.name], null);
                      const hideLabel = shortcutActions[el.name]?.hideLabel;
                      if (!updateShortcutAction) {
                        updateShortcutAction = {
                          name: el.name,
                          hideLabel: !hideLabel,
                        };
                      }
                      const updateShortcutActions = Object.entries(
                        merge(shortcutActions, { [el.name]: updateShortcutAction }),
                      ).reduce((carry, [key, act]) => {
                        if (key === el.name) {
                          act.hideLabel = !hideLabel;
                        }
                        return {
                          ...carry,
                          [key]: act,
                        };
                      }, {});
                      backbone.changeConfig(SHORTCUT_ACTIONS, updateShortcutActions);
                    },
                  },
                ],
              };
            });
            return [enhancedNewProperties];
          },
        }
      : {}),
    'system.reset.view': (view, backbone) => {
      const systemViews = get(systemConfig, ['customAttributes', 'views'], []);
      const systemView = systemViews.find(({ id }) => id === view.id);

      if (systemView) {
        const clonedSystemView = cloneDeep(systemView);
        const views = backbone.getConfig('views', []).map((el) => {
          if (el.id === view.id) return cloneDeep(clonedSystemView);
          return el;
        });

        if (view.id === view.id) {
          view = clonedSystemView;
        }

        backbone.updateConfig({
          views,
          view,
          ...get(view, 'combinator.properties', {}),
          ...omit(view.combintor, 'properties'),
        });

        backbone.changeConfigMultiple({
          filter: systemView.combinator.filter,
          sort: systemView.combinator.sort,
          groupBy: systemView.combinator.groupBy,
          pinnedColumn: systemView.combinator.pinnedColumn,
          //
          attribute: systemView.combinator.properties.attribute,
          metric: systemView.combinator.properties.metric,
          dimension: systemView.combinator.properties.dimension,
          personalization: systemView.combinator.properties.personalization,
          specialFilters: systemView.combinator.specialFilters,
        });

        backbone.reloadData('table', [], true);
      }
    },
    'get.system.config': () => {
      return get(systemConfig, ['customAttributes'], {});
    },
  };

  if (ff.keep_block_refreshing) {
    const { timeToReloadData } = config.configuration;
    if (timeToReloadData) {
      config.addons = {
        ...config.addons,
        'calendar.last_updated': (rows, currentConfig, backbone) => {
          setTimeout(() => {
            const currentTime = moment().valueOf();
            if (currentTime - lastUpdated.current > timeToReloadData) {
              backbone.changeConfig('lastUpdatedAt', moment(new Date()).format('MMM/YY hh:mm'));
              backbone.reloadData('table');
              lastUpdated.current = currentTime;
            }
          }, timeToReloadData);
          return rows.map((row) => ({
            ...row,
            lastUpdatedAt: moment(new Date()).format('MMM/YY hh:mm'),
          }));
        },
      };
    }
  }

  if (ff.metric_traction) {
    config.addons = {
      ...config.addons,
      'externalFilter.getFields': (backbone) => {
        const externalFilters = backbone.getConfig('system.externalFilters', []);
        const externalFiltersOptions = backbone.getConfig('system.externalFiltersOptions', []);
        const availableColumns = backbone.getAvailableColumns();
        return externalFilters.reduce((carry, item) => {
          const column = availableColumns.find((el) => el.id == item);
          if (!column) return carry;
          const filterOption = externalFiltersOptions.find((option) => option.value === item);
          return [
            ...carry,
            {
              name: column.name,
              id: column.id,
              field: column.filterField,
              dataType: column.dataType || 'string',
              propertyType: column.propertyType,
              isMultiple: get(filterOption, ['isMultiple'], true),
            },
          ];
        }, []);
      },
      'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
        if (checkEpsiloTableEndpoint(endpoint)) {
          return eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, {
            ...params,
            '@eTableConfig': { ...backbone.config, tableId: blockEid },
          });
        } else {
          return enhanceDataRequest2(params, originRequest, backbone);
        }
      },
    };
  }
  const mappings = get(validConfig, 'mapping', {});

  const prevAddon = get(config, ['addons', 'datasource.getRowsParams'], null);
  config.addons = {
    ...config.addons,
    'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
      const prevParams = prevAddon ? prevAddon({ params }, currentConfig) : params;
      const specialFilters = backbone.getConfig('specialFilters') || {};
      const mapping = backbone.getConfig('mapping', {});
      const ignoreCurrency = backbone.getConfig('ignoreCurrency', false) || false;
      const currency = ignoreCurrency ? null : backbone.getConfig('currency', '') || null;

      if (checkEpsiloTableEndpoint(endpoint)) {
        prevParams.hiddenFilter = {
          ...prevParams.hiddenFilter,
          currency: currency,
        };
        prevParams.currency = get(prevParams, 'hiddenFilter.currency', currency);
      }

      const formattedSpecialFilters = Object.entries(specialFilters)
        .filter(([field]) => get(backbone, ['config', 'system', 'externalFilters'], []).includes(field))
        .map(([k, filter]) => {
          const tableField = mapping[k];
          if (!tableField) return null;
          return {
            dataType: filter.dataType,
            field: tableField.filterField,
            operator: filter.queryType,
            value: filter.queryValue,
          };
        })
        .filter((el) => el);

      if (formattedSpecialFilters.length > 0) {
        return {
          ...prevParams,
          filter: {
            ...prevParams.filter,
            combinator: prevParams.filter?.combinator || 'and',
            filters: [...(prevParams.filter?.filters || []), ...formattedSpecialFilters],
          },
        };
      }

      return { ...prevParams };
    },
  };
  if (checkEpsiloTableEndpoint(endpoint)) {
    config.addons = {
      ...config.addons,
      'post.getTableData.callback': (params, response, backbone) => {
        backbone.changeConfig('tableParams', params);
        window.dispatchEvent(
          new CustomEvent(POST_RELOAD_TABLE_EVENT, {
            detail: {
              params,
              backbone,
              tableId: blockEid,
              response,
            },
          }),
        );
      },
      'system.export': (params, backbone) => {
        return customExportTable(params, backbone);
      },
      'system.groupby.aggFunc': (key) => {
        const aggFuncList = [
          'NONE',
          'UNIQUE',
          'SUM',
          'AVG',
          'MIN',
          'MAX',
          'COUNT_ALL',
          'COUNT_VALUES',
          'COUNT_UNIQUE',
          'COUNT_EMPTY',
          'COUNT_NOT_EMPTY',
          'PERCENT_EMPTY',
          'PERCENT_NOT_EMPTY',
          'RANGE',
        ].map((el) => {
          let fieldId = el;
          if (el === 'NONE') fieldId = 'NULL';
          if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
          return {
            id: el.toLowerCase(),
            requestQuery: fieldId,
          };
        });

        const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce(
          (carry, i) => ({ ...carry, [i.id]: i }),
          {},
        );

        return aggFuncByKey[key]
          ? aggFuncByKey[key]
          : {
              id: key,
              requestQuery: key.toUpperCase(),
            };
      },
      'column.actions': (type, params) => {
        switch (type) {
          case 'hideColumn': {
            return {
              onSubmit(data, backbone) {
                const currentSelected = backbone.getConfig(type);
                const filteredSelected = currentSelected.filter((ele) => ele !== data.field);
                backbone.changeConfig(type, filteredSelected);
              },
            };
          }
          case 'showTrend': {
            return {
              onSubmit(backbone) {
                backbone.changeConfig('showTrend', get(params, 'payload.showTrend', true));
              },
            };
          }
          case 'groupBy': {
            return {
              onSubmit: (data, backbone) => {
                backbone.changeConfig('groupBy', { columns: [data.field] });
              },
            };
          }
        }
      },
    };
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
    const mappings = get(validConfig, 'mapping', {});
    const cachedFilterOption = {};
    Object.keys(mappings)
      .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
      .forEach((colKey) => {
        let apiFilterValue;
        if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
        else if (get(mappings, [colKey, 'valueGetter', 'id'], '')) {
          apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
        } else {
          apiFilterValue = get(
            mappings, //
            [colKey, 'valueGetter', 'value'],
            colKey,
          );
        }
        const addonKey = `filterValue.${colKey}`;
        config.addons[addonKey] = async () => {
          if (cachedFilterOption[addonKey]) return cachedFilterOption[addonKey];
          try {
            const result = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_listing.jsp', {
              attributes: [apiFilterValue],
            });

            const options = get(result, 'data.rows', []);

            cachedFilterOption[addonKey] = {
              data: options
                .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
              success: true,
              message: 'OK',
            };
            return cachedFilterOption[addonKey];
          } catch (e) {
            cachedFilterOption[addonKey] = null;
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        };
      });
    config.addons = {
      ...config.addons,
      'filterValue.country': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

          return Promise.resolve({
            data: availableCountryList.map((c) => ({
              label: c,
              value: c,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.marketplace': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableMarketplaceList = [
            ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
          ];

          return Promise.resolve({
            data: availableMarketplaceList.map((m) => ({
              label: m,
              value: m,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.storefront': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const data = Object.values(storefrontList).map((el: any) => {
            return {
              label: [el.marketplace_code, el.country_code, el.storefront_name].join(' / '),
              value: el.id,
              payload: {
                sid: el.storefront_sid,
                country: {
                  code: el.country_code,
                },
                channel: {
                  id: el.marketplace_code,
                },
              },
            };
          });

          return Promise.resolve({
            data,
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
    };
  }
  if (queryParams.namespace) {
    const isUniversalPrefix = endpoint.includes('universalPrefix');
    Object.keys(mappings)
      .filter(
        (colKey) =>
          get(mappings, [colKey, 'propertyType'], '') === 'dimension' ||
          get(mappings, [colKey, 'selectionFilter'], false),
      )
      .forEach((colKey) => {
        const addonKey = `filterValue.${colKey}`;

        config.addons[addonKey] = async (payload, forceReload, ___, getColumnFields, selectedValues = []) => {
          const result = await getNamespaceList({
            isUniversalPrefix,
            namespace: queryParams.namespace,
            field: colKey,
            config,
            getColumnFields,
            payload,
            selectedValues,
            forceReload,
          });
          return result;
        };
      });

    config.addons = {
      ...config.addons,
      'selection.filter': (field, originSelectionFilter) => {
        if (originSelectionFilter.length === 0 && get(mappings, [field, 'selectionFilter'], false)) {
          return ['is', 'is_not'];
        }
        return originSelectionFilter;
      },

      'cell.customActions': (field, additionalData, backbone) => {
        const mappings = backbone.getConfig('mapping');

        const colDef = mappings[field];

        const customCellActions = getCustomCellActions({
          actions: get(colDef, ['actions'], []),
          field,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });

        return customCellActions;
      },

      'cell.customActions2': ({ field, actions }, additionalData, backbone) => {
        const customCellActions = getCustomCellActions({
          actions,
          field,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });

        return customCellActions;
      },
      'main.button.actions': (actions, additionalData, backbone) => {
        return getCustomCellActions({
          actions,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });
      },

      'system.getPaginationInfo': async (payload, backbone) => {
        const requestParams = {
          ...(payload ? payload : get(backbone, ['config', 'tableParams'], {})),
          aggregateAll: get(backbone, ['config', 'primaryKeys'], [])
            .slice(0, 1)
            .map((field) => ({
              field: field,
              func: 'COUNT_ALL',
            })),
        };

        const result = await eipRequest.post(
          get(backbone, ['config', 'endpoint', 'GET_TABLE_DATA'], ''),
          requestParams,
        );

        if (get(result, ['code'], '') === 200) {
          return {
            pagination: {
              total: result.aggregateAll[0].value,
            },
            serviceResponse: {
              success: true,
              message: 'success',
            },
            code: 200,
          };
        }

        return result;
      },

      'get.system.config.detail': (args, defaultValue = null) =>
        get(systemConfig, ['customAttributes', ...[].concat(args)], defaultValue),
    };
  }

  return config;
}

export function enhancedETableConfig2({
  customConfig,
  systemConfig,
  nodeEditContext,
  lastUpdated,
  blockEid,
  setOpenCodeEditor,
  getCustomCellActions,
  onToastMultiple,
  addons = {},
  queryView = '',
}) {
  let linkedObjects = {
    actions: {},
    advancedFilter: {},
  };
  let validConfig = produceValidConfig(customConfig);
  if (!nodeEditContext.isEditMode) {
    [validConfig, linkedObjects] = reduceConfigSize(validConfig, systemConfig);
  } else {
    linkedObjects = reduceConfigSize(validConfig, systemConfig)[1];
  }
  if (queryView) {
    validConfig = updateViewByQuery(validConfig, queryView);
  }
  validConfig = lockedViewAdjustment(validConfig, systemConfig);
  validConfig = migrateSpecialFilters(validConfig);
  validConfig = datetimeSelectionAdjustment(validConfig);
  validConfig = popuplateConfigWithCurrentView(validConfig);
  validConfig = migrateSingleSpecialFilters(validConfig, systemConfig?.customAttributes);
  validConfig = migrateRemovedColumns(validConfig);
  validConfig = migrateAdvancedFilter(validConfig);
  validConfig = migrateShortcutActions(validConfig);
  validConfig = migrateColumnsSettings(validConfig);

  const endpoint = get(validConfig, 'endpoint.GET_TABLE_DATA', '');
  const isLockedView = get(validConfig, ['view', 'isLock'], false);
  const defaultLimit = get(validConfig, ['system', 'defaultLimit']);

  const config: {
    apiRequest: Record<string, any>;
    configuration: Record<string, any>;
    addons?: Record<string, (...args: any[]) => any>;
    callback?: Record<string, any>;
    cellFormat?: Record<string, any>;
  } = {
    apiRequest: {},
    configuration: {
      ...defaultConfiguration,
      ...validConfig,
      defaultPagination: {
        limit: defaultLimit ? defaultLimit : isValidIndexData(validConfig) ? 1000 : 100,
        page: 1,
        total: 0,
        ...(ff.separate_table_data_loading ? { traceId: '' } : {}),
      },
    },
    callback: {
      onBackboneReady: (backbone, config) => {
        const addons = get(config, 'addons', {});

        Object.keys(addons).forEach((key) => {
          if (key.startsWith('initial.request.')) {
            backbone
              .addon(key, () => Promise.resolve({ data: null }))()
              .then((res) => {
                backbone.addInitialDataRequest(key.replace('initial.request.', ''), res.data);
              });
          }
        });

        const enpointTable = get(backbone, 'config.endpoint.GET_TABLE_DATA');
        // Add Metric Options for button Properties
        const mappingConfig = get(backbone, 'config.mapping', {});
        const isStorefrontMetricTraction = Object.values(mappingConfig).find(
          (ele) => ele?.cellFormat === 'storefrontMetricTraction',
        );
        const metric = get(isStorefrontMetricTraction, 'staticValue.selectedMetrics', []);
        const metricTractionProperties = backbone.getConfig('metricTractionProperties', []);

        const isDiff =
          metric.length !== metricTractionProperties.length ||
          (metric.length === metricTractionProperties.length &&
            metricTractionProperties.some(({ id }) => !metric.includes(id)));

        if (isStorefrontMetricTraction && isDiff) {
          getMetricDefinition(enpointTable).then((res) => {
            if (res) {
              const metricProperties = metric.map((el) => {
                const metricDef = res.filter((mt) => mt.value === el)[0];
                return {
                  id: el,
                  name: get(metricDef, 'label_raw', el),
                  disable: false,
                };
              });
              backbone.changeConfig('metricTractionProperties', metricProperties);
            }
          });
        }
      },
    },
    cellFormat: nextCellFormat,
  };
  const queryParams = getQueryParams(endpoint);

  // Will remove when API is available
  const showTooltip = false;

  // Add additionalColumns for table
  config.addons = {
    ...config.addons,
    ...(ff.metric_cohort_column
      ? {
          'dataMenu.properties': (arr, handleUpdateVisibleWithId, backbone) => {
            const newProperties = [...arr];
            const allowChangeCurrency = backbone.getConfig('system.changeCurrency', 'no') == 'yes';
            if (hasCohortProperties(backbone)) {
              newProperties.unshift({
                title: 'Cohort',
                name: 'cohort',
                icon: getConst('SHORTCUT_CALENDAR_ICON', 'calendar'),
                colorStartIcon: '#253746',
                onClick: () => backbone.changeVisibility('calendar', true),
              });
            }
            if (checkEpsiloTableEndpoint(endpoint)) {
              if (backbone.getConfig('system.allowAccumulative', 'no') === 'yes') {
                newProperties.unshift({
                  title: 'Accumulative',
                  name: 'accumulative',
                  icon: getConst('SHORTCUT_ACCUMULATIVE_ICON', 'ic/bi:bar-chart-fill/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('accumulative', true),
                });
              }
              const allowedViewByTimelineType = backbone.getConfig('viewByConfig.allowedViewByTimelineType', [
                'timeline',
                'dimension1',
                'dimension2',
              ]);
              if ((allowedViewByTimelineType || []).length) {
                newProperties.unshift({
                  title: 'View by',
                  name: 'periodically',
                  icon: getConst('SHORTCUT_VIEWBY_ICON', 'ic/uis:layer-group/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('periodically', true),
                });
              }
              if (backbone.getConfig('system.allowPivotMetric', 'no') === 'yes') {
                newProperties.unshift({
                  title: 'Pivot',
                  name: 'pivot',
                  icon: getConst('SHORTCUT_PIVOT_ICON', 'ic/icon-park-solid:pivot-table/black'),
                  colorStartIcon: '#253746',
                  onClick: () => handleUpdateVisibleWithId('pivot', true),
                });
              }
              if (allowChangeCurrency) {
                newProperties.unshift({
                  title: 'Change currency',
                  icon: getConst('SHORTCUT_CHANGE_CURRENCY_ICON', 'changeCurrency'),
                  colorStartIcon: '#253746',
                  name: 'currencySwitch',
                  onClick: () => handleUpdateVisibleWithId('currencySwitch', true),
                });
              }
              if (!isLockedView) {
                newProperties.push({
                  title: 'Reset all columns',
                  name: 'reset_all_columns',
                  icon: getConst('SHORTCUT_RESET_ALL_COLUMNS_ICON', 'rmx/restart-line-icon/black'),
                  colorStartIcon: '#253746',
                  onClick: () => {
                    let view = cloneDeep(backbone.getConfig('view'));
                    const systemViews = get(systemConfig, ['customAttributes', 'views'], []);
                    const defaultSystemViews = systemViews.find(({ id }) => id === 'default');

                    if (defaultSystemViews) {
                      const clonedSystemView = cloneDeep(defaultSystemViews);
                      set(
                        clonedSystemView,
                        ['combinator', 'columnWidth'],
                        get(defaultSystemViews, ['combinator', 'columnWidth'], []).map((el) => {
                          return {
                            ...el,
                            width: get(mappings, [el.columnField, 'staticValue', 'defaultWidth'], el.width),
                          };
                        }),
                      );

                      const views = backbone.getConfig('views', []).map((view) => {
                        if (view.id === 'default') return cloneDeep(clonedSystemView);
                        return view;
                      });

                      if (view.id === 'default') {
                        view = clonedSystemView;
                      }

                      backbone.updateConfig({
                        views,
                        view,

                        ...get(view, 'combinator.properties', {}),
                        ...omit(view.combintor, 'properties'),
                      });

                      backbone.reloadData('table', [], true);
                    }
                  },
                });
              }
            }
            const enhancedNewProperties = newProperties.map((el) => {
              if (!nodeEditContext.isEditMode) return el;
              const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
              const shortcut = shortcutActions[el.name]?.shortcut;
              return {
                ...el,
                subActions: [
                  {
                    title: shortcut ? 'Remove from shortcuts' : 'Add to shortcuts',
                    name: 'shortcut',
                    icon: shortcut ? 'ic/mdi:bookmark-off/#253746' : 'ic/mdi:bookmark/#253746',
                    colorStartIcon: '#253746',
                    onClick: () => {
                      const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
                      let updateShortcutAction = get(shortcutActions, [el.name], null);
                      const shortcut = shortcutActions[el.name]?.shortcut;
                      if (!updateShortcutAction) {
                        updateShortcutAction = {
                          name: el.name,
                          hideLabel: false,
                        };
                      }
                      const updateShortcutActions = Object.entries(
                        merge(shortcutActions, { [el.name]: updateShortcutAction }),
                      ).reduce((carry, [key, act]) => {
                        if (act.order > shortcutActions[el.name]?.order && shortcut) {
                          act.order--;
                        }
                        if (key === el.name) {
                          act.shortcut = !shortcut;
                          act.order = shortcut
                            ? null
                            : Object.values(shortcutActions).filter((act) => act.shortcut).length - 1;
                        }
                        return {
                          ...carry,
                          [key]: act,
                        };
                      }, {});
                      backbone.changeConfig(SHORTCUT_ACTIONS, updateShortcutActions);
                    },
                  },
                  {
                    title: shortcutActions[el.name]?.hideLabel ? 'Show shortcut label' : 'Hide shortcut label',
                    name: 'resize_all_columns',
                    icon: shortcutActions[el.name]?.hideLabel
                      ? 'ic/radix-icons:text/#253746'
                      : 'ic/radix-icons:text-none/#253746',
                    colorStartIcon: '#253746',
                    onClick: () => {
                      const shortcutActions = backbone.getConfig(SHORTCUT_ACTIONS, {});
                      let updateShortcutAction = get(shortcutActions, [el.name], null);
                      const hideLabel = shortcutActions[el.name]?.hideLabel;
                      if (!updateShortcutAction) {
                        updateShortcutAction = {
                          name: el.name,
                          hideLabel: !hideLabel,
                        };
                      }
                      const updateShortcutActions = Object.entries(
                        merge(shortcutActions, { [el.name]: updateShortcutAction }),
                      ).reduce((carry, [key, act]) => {
                        if (key === el.name) {
                          act.hideLabel = !hideLabel;
                        }
                        return {
                          ...carry,
                          [key]: act,
                        };
                      }, {});
                      backbone.changeConfig(SHORTCUT_ACTIONS, updateShortcutActions);
                    },
                  },
                ],
              };
            });
            return [enhancedNewProperties];
          },
        }
      : {}),
    'system.reset.view': (view, backbone) => {
      const systemViews = get(systemConfig, ['customAttributes', 'views'], []);
      const systemView = systemViews.find(({ id }) => id === view.id);

      if (systemView) {
        const clonedSystemView = cloneDeep(systemView);
        const views = backbone.getConfig('views', []).map((el) => {
          if (el.id === view.id) return clonedSystemView;
          return el;
        });

        if (view.id === clonedSystemView.id) {
          view = clonedSystemView;
        }

        const newProperties = produce({}, (draft) => {
          Object.keys(get(view, 'combinator', {})).forEach((key) => {
            if (key === 'properties') {
              Object.keys(view.combinator[key]).forEach((propKey) => {
                draft[propKey] = view.combinator[key][propKey];
              });
            } else if (key !== 'runtime') {
              draft[key] = view.combinator[key];
            }
          });
        });

        backbone.updateConfig({
          views,
          view,
          ...newProperties,
        });

        backbone.reloadData('table', [], true);
      }
    },
    'get.system.config': () => {
      return get(systemConfig, ['customAttributes'], {});
    },
  };

  if (ff.keep_block_refreshing) {
    const { timeToReloadData } = config.configuration;
    if (timeToReloadData) {
      config.addons = {
        ...config.addons,
        'calendar.last_updated': (rows, currentConfig, backbone) => {
          setTimeout(() => {
            const currentTime = moment().valueOf();
            if (currentTime - lastUpdated.current > timeToReloadData) {
              backbone.changeConfig('lastUpdatedAt', moment(new Date()).format('MMM/YY hh:mm'));
              backbone.reloadData('table');
              lastUpdated.current = currentTime;
            }
          }, timeToReloadData);
          return rows.map((row) => ({
            ...row,
            lastUpdatedAt: moment(new Date()).format('MMM/YY hh:mm'),
          }));
        },
      };
    }
  }

  if (ff.metric_traction) {
    config.addons = {
      ...config.addons,
      'externalFilter.getFields': (backbone) => {
        const externalFilters = backbone.getConfig('system.externalFilters', []);
        const externalFiltersOptions = backbone.getConfig('system.externalFiltersOptions', []);
        const availableColumns = backbone.getAvailableColumns();
        return externalFilters.reduce((carry, item) => {
          const column = availableColumns.find((el) => el.id == item);
          if (!column) return carry;
          const filterOption = externalFiltersOptions.find((option) => option.value === item);
          return [
            ...carry,
            {
              name: column.name,
              id: column.id,
              field: column.filterField,
              dataType: column.dataType || 'string',
              propertyType: column.propertyType,
              isMultiple: get(filterOption, ['isMultiple'], true),
            },
          ];
        }, []);
      },
      'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
        if (checkEpsiloTableEndpoint(endpoint)) {
          const defaultResponse = {
            rows: [],
            queryParams: params,
            headers: [],
            eTableContext: {
              columns: [],
              groupBy: {},
              pinnedColumn: [],
            },
            data: {
              headers: [],
              rows: [],
              masterData: {},
              masterDataPrimaryKey: {},
              originalHeaders: [],
              resourceMetric: [],
              summary: {},
            },
          };

          const system = get(backbone, ['config', 'system'], {});
          const specialFilters = get(backbone, ['config', 'specialFilters'], {});
          const externalFiltersOptions = get(system, ['externalFiltersOptions'], []);
          const externalFilters = get(system, ['externalFilters'], []);
          const singleExternalFilters = externalFilters.filter((i) => {
            const detail = externalFiltersOptions.find((opt) => opt.value == i);
            return detail && detail.isMultiple === false;
          });

          const emptySingleExternalFilters = singleExternalFilters.filter((i) => {
            return !Object.keys(specialFilters).includes(i);
          });

          if (emptySingleExternalFilters?.length && backbone.config?.tableType != 'compact') {
            const attributes = uniq(
              emptySingleExternalFilters.reduce((carry, key) => {
                const valueGetter = get(backbone, ['config', 'mapping', key, 'valueGetter'], {});
                const rawFilter = get(backbone, ['config', 'mapping', key, 'filterField'], '');
                let filterField = rawFilter;
                filterField = filterField?.split(',')?.[0] || filterField;
                const { id, value, label } = valueGetter;
                const labelField = String(label || value || id || filterField);
                const valueField = String(filterField || value || id || label);

                return carry.concat(...[valueField, labelField]);
              }, []),
            );

            const queryParamsString = String(endpoint)?.split('?')[1] || '';

            const response = await eipRequest.post(
              EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/listing.jsp?' + queryParamsString,
              {
                ...params,
                pagination: {
                  page: 1,
                  limit: 1,
                },
                dimensions: uniq(attributes.map((i) => String(i).split('.')[0])),
                attributes,
                metrics: [],
                sort: null,
                filter: {
                  combinator: 'and',
                  ...params.filter,
                  filters: [
                    ...(params.filter?.filters || []),
                    ...attributes.map((attribute) => {
                      return {
                        field: attribute,
                        operator: 'is_not_empty',
                        value: '',
                      };
                    }),
                  ],
                },
                groupBy: null,
                '@eTableConfig': undefined,
              },
            );
            if (response.data.rows.length) {
              const row = response.data.rows[0].reduce((carry, el, index) => {
                return {
                  ...carry,
                  [stripUniversalPrefix(response.data.headers[index])]: el,
                };
              }, {});
              const singleExternalData = emptySingleExternalFilters.reduce((carry, key) => {
                const valueGetter = get(backbone, ['config', 'mapping', key, 'valueGetter'], {});
                const rawFilter = get(backbone, ['config', 'mapping', key, 'filterField'], '');
                let filterField = rawFilter;
                filterField = filterField?.split(',')?.[0] || filterField;
                const { id, value, label } = valueGetter;
                const labelField = stripUniversalPrefix(String(label || value || id || filterField));
                const valueField = stripUniversalPrefix(String(filterField || value || id || label));

                return {
                  ...carry,
                  [key]: {
                    dataType: 'string',
                    field: key,
                    logicalOperator: 'and',
                    queryType: 'IN',
                    queryValue: [row[valueField] || row[labelField]].filter((i) => !!i),
                    queryValueLabel: isFormulaField(labelField)
                      ? toValue(labelField, { ...row, value: row[valueField] }) || ''
                      : row[labelField] || '',
                    type: 'filter',
                  },
                };
              }, {});

              backbone.changeConfig('specialFilters', {
                ...get(backbone, ['config', 'specialFilters'], {}),
                ...singleExternalData,
              });
            }
            return defaultResponse;
          }

          return eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, {
            ...params,
            '@eTableConfig': { ...backbone.config, tableId: blockEid },
          });
        } else {
          return enhanceDataRequest2(params, originRequest, backbone);
        }
      },
      'datasource.removeSecondaryMetricWithGroupBy': (params, backbone) => {
        const isChart = backbone.getConfig('visualizationType') == 'chart';
        const isNotGroupByRequest =
          !get(params, ['groupBy', 'dimensions']) ||
          get(params, ['groupBy', 'drillDowns', 'length']) == get(params, ['groupBy', 'dimensions', 'length']);
        if (isChart || isNotGroupByRequest) {
          return params;
        }
        const mapping = backbone.getConfig('mapping');
        const secondaryMetrics = Object.keys(mapping).filter((i) => {
          const isMetric = get(mapping, [i, 'propertyType']) == 'metric';
          const isSecondaryMetric = get(mapping, [i, 'staticValue', 'isSecondaryMetric']) == 'yes';
          const metricValue = get(mapping, [i, 'valueGetter', 'value'], '');
          const metricAggFunc = get(params, ['groupBy', 'aggregations'], []).find((i) => i.field == metricValue);

          return isMetric && isSecondaryMetric && String(metricAggFunc?.func).toLowerCase() == 'none';
        });
        const excludeSecondaryValue = secondaryMetrics.map((i) => get(mapping, [i, 'valueGetter', 'value']));

        const cloneParams = cloneDeep(params);
        cloneParams.metrics = cloneParams.metrics.filter(
          (i) => excludeSecondaryValue.length == 0 || !excludeSecondaryValue.includes(i),
        );
        return cloneParams;
      },
    };
  }
  const mappings = get(validConfig, 'mapping', {});

  const prevAddon = get(config, ['addons', 'datasource.getRowsParams'], null);
  config.addons = {
    ...config.addons,
    'datasource.getRowsParams': ({ params }, currentConfig, backbone) => {
      const prevParams = prevAddon ? prevAddon({ params }, currentConfig) : params;
      const specialFilters = backbone.getConfig('specialFilters') || {};
      const mapping = backbone.getConfig('mapping', {});
      const ignoreCurrency = backbone.getConfig('ignoreCurrency', false) || false;
      const currency = ignoreCurrency ? null : backbone.getConfig('currency', '') || null;

      if (checkEpsiloTableEndpoint(endpoint)) {
        prevParams.hiddenFilter = {
          ...prevParams.hiddenFilter,
          currency: currency,
        };
        prevParams.currency = get(prevParams, 'hiddenFilter.currency', currency);
      }

      const formattedSpecialFilters = Object.entries(specialFilters)
        .filter(([field]) => get(backbone, ['config', 'system', 'externalFilters'], []).includes(field))
        .map(([k, filter]) => {
          const tableField = mapping[k];
          if (!tableField) return null;
          const filterField = tableField.filterField;
          return {
            dataType: filter.dataType,
            field: filterField?.split(',')?.[0] || filterField,
            operator: filter.queryType,
            value: filter.queryValue,
          };
        })
        .filter((el) => el);

      if (formattedSpecialFilters.length > 0) {
        return {
          ...prevParams,
          filter: {
            ...prevParams.filter,
            combinator: prevParams.filter?.combinator || 'and',
            filters: [...(prevParams.filter?.filters || []), ...formattedSpecialFilters],
          },
        };
      }

      return { ...prevParams };
    },
  };
  if (checkEpsiloTableEndpoint(endpoint)) {
    config.addons = {
      ...config.addons,
      'post.getTableData.callback': (params, response, backbone) => {
        backbone.changeConfig('tableParams', params);
        window.dispatchEvent(
          new CustomEvent(POST_RELOAD_TABLE_EVENT, {
            detail: {
              params,
              backbone,
              tableId: blockEid,
              response,
            },
          }),
        );
      },
      'system.export': (params, backbone) => {
        return customExportTable(params, backbone);
      },
      'system.groupby.aggFunc': (key) => {
        const aggFuncList = [
          'NONE',
          'UNIQUE',
          'SUM',
          'AVG',
          'MIN',
          'MAX',
          'COUNT_ALL',
          'COUNT_VALUES',
          'COUNT_UNIQUE',
          'COUNT_EMPTY',
          'COUNT_NOT_EMPTY',
          'PERCENT_EMPTY',
          'PERCENT_NOT_EMPTY',
          'RANGE',
        ].map((el) => {
          let fieldId = el;
          if (el === 'NONE') fieldId = 'NULL';
          if (el === 'UNIQUE') fieldId = 'COUNT_UNIQUE';
          return {
            id: el.toLowerCase(),
            requestQuery: fieldId,
          };
        });

        const aggFuncByKey: Record<string, AggFuncType> = aggFuncList.reduce(
          (carry, i) => ({ ...carry, [i.id]: i }),
          {},
        );

        return aggFuncByKey[key]
          ? aggFuncByKey[key]
          : {
              id: key,
              requestQuery: key.toUpperCase(),
            };
      },
      'column.actions': (type, params) => {
        switch (type) {
          case 'hideColumn': {
            return {
              onSubmit(data, backbone) {
                const currentSelected = backbone.getConfig(type);
                const filteredSelected = currentSelected.filter((ele) => ele !== data.field);
                backbone.changeConfig(type, filteredSelected);
              },
            };
          }
          case 'showTrend': {
            return {
              onSubmit(backbone) {
                backbone.changeConfig('showTrend', get(params, 'payload.showTrend', true));
              },
            };
          }
          case 'groupBy': {
            return {
              onSubmit: (data, backbone) => {
                backbone.changeConfig('groupBy', { columns: [data.field] });
              },
            };
          }
        }
      },
    };
  }
  if (checkEpsiloTableEndpoint(endpoint, EpsiloTableObject.PERFORMANCE)) {
    const mappings = get(validConfig, 'mapping', {});
    const cachedFilterOption = {};
    Object.keys(mappings)
      .filter((colKey) => get(mappings[colKey], 'propertyType', '') === 'dimension')
      .forEach((colKey) => {
        let apiFilterValue;
        if (get(mappings, [colKey, 'filterField'], '')) apiFilterValue = get(mappings, [colKey, 'filterField'], '');
        else if (get(mappings, [colKey, 'valueGetter', 'id'], '')) {
          apiFilterValue = get(mappings, [colKey, 'valueGetter', 'id'], '');
        } else {
          apiFilterValue = get(
            mappings, //
            [colKey, 'valueGetter', 'value'],
            colKey,
          );
        }
        const addonKey = `filterValue.${colKey}`;
        config.addons[addonKey] = async () => {
          if (cachedFilterOption[addonKey]) return cachedFilterOption[addonKey];
          try {
            const result = await eipRequest.post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/insight_listing.jsp', {
              attributes: [apiFilterValue],
            });

            const options = get(result, 'data.rows', []);

            cachedFilterOption[addonKey] = {
              data: options
                .filter((i) => (Array.isArray(i) ? i[0] : i)) // Remove empty value
                .map((i) => ({ label: Array.isArray(i) ? i[0] : i, value: Array.isArray(i) ? i[0] : i })),
              success: true,
              message: 'OK',
            };
            return cachedFilterOption[addonKey];
          } catch (e) {
            cachedFilterOption[addonKey] = null;
            return Promise.resolve({
              data: [],
              success: false,
              message: '',
            });
          }
        };
      });
    config.addons = {
      ...config.addons,
      'filterValue.country': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableCountryList = [...new Set(Object.values(storefrontList).map((el) => el.country_code))];

          return Promise.resolve({
            data: availableCountryList.map((c) => ({
              label: c,
              value: c,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.marketplace': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const availableMarketplaceList = [
            ...new Set<string>(Object.values(storefrontList).map((el) => el.marketplace_code)),
          ];

          return Promise.resolve({
            data: availableMarketplaceList.map((m) => ({
              label: m,
              value: m,
            })),
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
      'filterValue.storefront': async () => {
        try {
          const [storefrontList] = await Promise.all([getInsightStorefrontList()]);

          const data = Object.values(storefrontList).map((el: any) => {
            return {
              label: [el.marketplace_code, el.country_code, el.storefront_name].join(' / '),
              value: el.id,
              payload: {
                sid: el.storefront_sid,
                country: {
                  code: el.country_code,
                },
                channel: {
                  id: el.marketplace_code,
                },
              },
            };
          });

          return Promise.resolve({
            data,
            success: true,
            message: 'OK',
          });
        } catch (e) {
          return Promise.resolve({
            data: [],
            success: false,
            message: '',
          });
        }
      },
    };
  }
  if (queryParams.namespace) {
    const isUniversalPrefix = endpoint.includes('universalPrefix');
    Object.keys(mappings)
      .filter(
        (colKey) =>
          get(mappings, [colKey, 'propertyType'], '') === 'dimension' ||
          get(mappings, [colKey, 'selectionFilter'], false),
      )
      .forEach((colKey) => {
        const addonKey = `filterValue.${colKey}`;

        config.addons[addonKey] = async (payload, forceReload, ___, getColumnFields, selectedValues = []) => {
          const result = await getNamespaceList({
            isUniversalPrefix,
            namespace: queryParams.namespace,
            field: colKey,
            config,
            getColumnFields,
            payload,
            selectedValues,
            forceReload,
          });
          return result;
        };
      });

    config.addons = {
      ...config.addons,
      'selection.filter': (field, originSelectionFilter) => {
        if (originSelectionFilter.length === 0 && get(mappings, [field, 'selectionFilter'], false)) {
          return ['is', 'is_not'];
        }
        return originSelectionFilter;
      },

      'cell.customActions': (field, additionalData, backbone) => {
        const mappings = backbone.getConfig('mapping');

        const colDef = mappings[field];

        const customCellActions = getCustomCellActions({
          actions: get(colDef, ['actions'], []),
          field,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });

        return customCellActions;
      },

      'cell.customActions2': ({ field, actions }, additionalData, backbone) => {
        const customCellActions = getCustomCellActions({
          actions,
          field,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });

        return customCellActions;
      },

      'main.button.actions': (actions, additionalData, backbone) => {
        return getCustomCellActions({
          actions,
          backbone,
          additionalData,
          setOpenCodeEditor: setOpenCodeEditor,
          onToastMultiple,
          blockEid: blockEid,
        });
      },

      'system.getPaginationInfo': async (payload, backbone) => {
        const requestParams = {
          ...(payload ? payload : get(backbone, ['config', 'tableParams'], {})),
          aggregateAll: get(backbone, ['config', 'primaryKeys'], [])
            .slice(0, 1)
            .map((field) => ({
              field: field,
              func: 'COUNT_ALL',
            })),
        };

        const result = await eipRequest.post(
          get(backbone, ['config', 'endpoint', 'GET_TABLE_DATA'], ''),
          requestParams,
        );

        if (get(result, ['code'], '') === 200) {
          return {
            pagination: {
              total: result.aggregateAll[0].value,
            },
            serviceResponse: {
              success: true,
              message: 'success',
            },
            code: 200,
          };
        }

        return result;
      },

      'get.system.config.detail': (args, defaultValue = null) =>
        get(systemConfig, ['customAttributes', ...[].concat(args)], defaultValue),
    };
  }

  if (addons) {
    config.addons = {
      ...config.addons,
      ...addons,
    };
  }

  return { config, linkedObjects };
}
