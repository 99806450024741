import * as React from 'react';
import { generalLabels, generateRandomScatterData } from '../../../chartlib/helper';
import { IScatterDataChart } from '../../../chartlib/type';
import ScatterChartResponsive from '../../../chartlib/scatter';

const data: IScatterDataChart = {
  headers: generalLabels({ month: '09', year: '2021', DateFormatView: 'D MMM' }),
  rows: [
    {
      label: 'Vietnam',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 12000,
        minValue: 5000,
      }),
    },
    {
      label: 'Thailand',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10000,
        minValue: 4000,
      }),
    },
    {
      label: 'Singapore',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10000,
        minValue: 3000,
      }),
    },
    {
      label: 'Philipines',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10000,
        minValue: 1000,
      }),
    },
    {
      label: 'Malaysia',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10000,
        minValue: 2000,
      }),
    },
    {
      label: 'Indonesia',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10400,
        minValue: 3000,
      }),
    },
    {
      label: 'USA',
      data: generateRandomScatterData({
        month: '09',
        year: '2021',
        DateFormatView: 'YYYY/MM/DD hh:mm:ss',
        maxValue: 10000,
        minValue: 4000,
      }),
    },
  ],
};
const ScatterChart = () => {
  return (
    <>
      <div style={{ marginBottom: '100px', height: '100vh' }}>
        <ScatterChartResponsive
          keyChart="small"
          title="Title"
          value="600"
          currency="PHP"
          percent={15}
          data={data}
          stateChart="success"
        />
      </div>
    </>
  );
};

export default ScatterChart;
