import { ColumnApi } from '@ag-grid-community/core';
import { EIP_CONSTANT, useLog } from '@eip/next/lib/main';
import { compare } from 'fast-json-patch';
import produce, { current } from 'immer';
import { useSetAtom } from 'jotai';
import { cloneDeep, flatten, get, head, isEqual, last, set, uniq, isArray, sortBy, omit } from 'lodash';
import moment from 'moment';
import { nanoid } from 'nanoid';
import React from 'react';
import { v4 as uuid } from 'uuid';
import { getAPIRequest } from './next-api-request';
import { currentView, updateView, views } from './atom/view';
import { callbackGenerate } from './callback-generator';
import { DEFAULT_OPTIONS } from './mockup';
import { createDatasource } from './next-table-datasource';
import { StatusType } from './workflow-type';
import { viewByTimelineOptions } from '../../block/etable/etable-config/utils/constant';
import { PERSONALIZATION, SHORTCUT_ACTIONS } from '../../helper/constant';
import { isFormulaField, toValue } from '@ep/insight-ui/sw/util/excel-formula';
const log = useLog('lib:table-backbone');

/**
 * Table backbone controls:
 * System function:
 *  # columns mapping
 *    # edot
 *    # edot actions
 *  # api request: filtering, sorting, grouping, aggregation, searching
 *  # pagination
 *  # inline edit
 *    # muliple single edit
 *    # mass editing
 *    # predefined option and validation
 *  # filter
 *  # sort
 *  # group by
 *    # aggregation
 *  # configurable components
 *  # view selection
 *  # default time range: period, custom date range
 *  # user personalization regions
 *  # localization
 *
 * Personalization:
 *  # hide/show columns
 *  # filter
 *  # sort
 *  # column orderings
 *  # group by
 *    # aggregation
 *  # view managing
 *  # time range
 *
 * Experience:
 *  # Pinthis
 *
 * ### TODO
 * * Let start creating test for:
 * * grouping and aggregation
 * * Edot actions for open new link with URL component from row
 * * Thinking about mocking the backbone
 */

const DEFAULT_PAGINATION = {
  limit: 10,
  page: 1,
  total: 0,
  traceId: '',
};

export const DEFAULT_CONFIG = {
  title: 'Title',
  view: {
    id: 'default',
    name: 'Default',
    combinator: {
      filter: [],
      sort: [],
      properties: {
        dimension: [],
        attribute: [],
        metric: [],
      },
      groupBy: {},
      pinnedColumn: [],
    },
    systemView: true,
    initializedView: false,
  },
  views: [
    {
      id: 'default',
      name: 'Default',
      combinator: {
        filter: [],
        sort: [],
        properties: {
          dimension: [],
          attribute: [],
          metric: [],
        },
        groupBy: {},
        pinnedColumn: [],
      },
      systemView: true,
      initializedView: false,
    },
  ],
  allowCustomView: true,
  tableType: '',
  dateRange: {
    dateFrom: moment().subtract(7, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().format(EIP_CONSTANT.DATE_FORMAT),
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: true },
    { type: 'attribute', label: 'Attribute', allowConfig: true },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  currency: 'USD',
  dimension: [],
  metric: [],
  attribute: [],
  pinnedColumn: [],
  columnWidth: [],
  columnOrder: [],
  sort: [],
  filter: [],
  search: '',
  endpoint: {},
  mapping: {},
  groupBy: {},
  system: {},
  hiddenFilter: {},
  hiddenColumns: ['ad_type'],
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
  },
  disabledComponents: {},
  defaultPagination: DEFAULT_PAGINATION,
};

export const SPECIAL_FILTERS = 'specialFilters';

const removeHiddenColumnFromFilter = (hiddenColumns, filters) => {
  return filters.reduce((carry, filter) => {
    if (hiddenColumns.includes(filter.field)) return carry;
    if (filter.type == 'groupFilter') {
      const subFilters = removeHiddenColumnFromFilter(hiddenColumns, filter.subFilters) || [];
      if (subFilters.length == 0) return carry;
      carry.push({
        ...filter,
        subFilters,
      });
    } else {
      carry.push(filter);
    }

    return carry;
  }, []);
};

export const useTableBackbone = (
  bbConfig = {
    apiRequest: {},
    configuration: {} as Record<string, any>,
    callback: {},
    addons: {},
  },
  changeConfiguration = () => undefined,
) => {
  const [config, setConfig] = React.useState(() => ({ ...DEFAULT_CONFIG, ...bbConfig.configuration }));
  const [options, setOptions] = React.useState(DEFAULT_OPTIONS);
  const [groupedCols, setGroupedCols] = React.useState(() => {
    return get(config, ['groupBy'], {});
  });
  const [status, setStatus] = React.useState({
    table: '',
    pagination: '',
    dimension: '',
    metric: '',
    attribute: '',
  });
  const [visibility, setVisibility] = React.useState({
    tableSetting: false,
    filter: false,
    sort: false,
  });
  const [headers, setHeaders] = React.useState([]);
  const initPagination = cloneDeep(bbConfig.configuration.defaultPagination || DEFAULT_PAGINATION);
  const hasPivotMetric = get(bbConfig, ['configuration', 'system', 'allowPivotMetric'], 'no') == 'yes';
  const [pagination, setPagination] = React.useState(() => {
    const totalPivotMetric = get(bbConfig, ['configuration', 'pivot_metric'], []).length;
    const usePivotMultipleHeader = get(bbConfig, ['configuration', 'usePivotMultipleHeader'], 'yes') != 'no';
    return {
      ...initPagination,
      limit:
        hasPivotMetric && totalPivotMetric && !usePivotMultipleHeader
          ? initPagination.limit * totalPivotMetric
          : initPagination.limit,
      totalPivot: hasPivotMetric && totalPivotMetric && !usePivotMultipleHeader ? totalPivotMetric : 1,
    };
  });
  const [createdTime, setCreatedTime] = React.useState(null);

  React.useEffect(() => {
    if (createdTime == null) setCreatedTime(new Date().getTime());
  }, [createdTime]);

  const [initialDataRequest, setInitialDataRequest] = React.useState({});
  const setViews = useSetAtom(views);
  const setCurrentView = useSetAtom(currentView);
  const updateView1 = useSetAtom(updateView);

  const initialDataRequestRef = React.useRef({});

  const getInitialDataRequest = (key) => {
    if (key) return initialDataRequest[key];
    return initialDataRequest;
  };

  const addInitialDataRequest = (key, value) => {
    initialDataRequestRef.current[key] = value;
    setInitialDataRequest({
      ...initialDataRequestRef.current,
    });
  };

  const ref = React.useRef({
    mainButton: {},
    headerAction: {},
    cellFormat: {},
    cellEditor: {},
    notification: null,
    tempStorage: {},
    backboneInstance: null,
  });

  const apiRequest = React.useMemo(() => {
    const defaultRequest = getAPIRequest(config.endpoint);
    return { ...defaultRequest, ...bbConfig.apiRequest };
  }, []);

  const fastRef = React.useRef<Record<string, any>>({ config });

  const tableRef = React.useRef<{
    getColumnFields;
    apiRequest;
    config;
    callback: {
      clickMainButton?: (...args: any[]) => void;
      onRowUpdate?: (...args: any) => void;
    };
    gridApi;
    columnApi;
    pagination;
    updateTotalResult;
    updatePaginationTraceId;
    getAvailableGroupByColumns;
    getAvailableFilterColumns;
    getAvailableSortColumns;
    datasource;
    selectedRows: any[];
    addons: Record<string, any>;
    addon: (string: any, defaultFun: (...args: any[]) => any) => typeof defaultFun;
    queryToken: string;
    setStatus: Record<string, any>;
    changeConfig: (key: string, value: any) => void;
  }>({
    queryToken: null,
    getColumnFields: null,
    apiRequest: null,
    config: { ...DEFAULT_CONFIG, ...bbConfig.configuration },
    callback: { ...bbConfig.callback, ...callbackGenerate(config) },
    gridApi: null,
    columnApi: null,
    pagination: cloneDeep(bbConfig.configuration.defaultPagination || DEFAULT_PAGINATION),
    updateTotalResult: null,
    updatePaginationTraceId: null,
    getAvailableGroupByColumns: null,
    getAvailableFilterColumns: null,
    getAvailableSortColumns: null,
    datasource: null,
    selectedRows: [],
    addons: bbConfig.addons,
    addon: null,
    setStatus,
    changeConfig: (key, value) => undefined,
  });

  React.useEffect(() => {
    tableRef.current.pagination = pagination;
  }, [pagination, tableRef.current]);

  const init = () => {
    return new Promise((resolve) => {
      initPropertyOptions();
      resetColumns();
      initViews();
      resolve(true);
    });
  };

  /**
   *
   * When switching the view, the etable configs of filters, sorts, etc... will be reset to the saved view configuration
   */
  const initPropertyOptions = (isViewChanged = false) => {
    const mapping = get(bbConfig.configuration, 'mapping', {});
    const configHiddenColumns: any[] = get(config, 'hiddenColumns', []);
    const opt = {
      dimension: [],
      metric: [],
      attribute: [],
    };
    const isPivotMetric =
      get(bbConfig, ['configuration', 'system', 'allowPivotMetric'], 'no') == 'yes' &&
      get(bbConfig.configuration, 'visualizationType', 'table') == 'table';
    if (isPivotMetric) {
      opt['pivot_metric'] = [];
    }
    const selectedViewId = get(config, ['view', 'id']);
    const view = config.views.find((i) => i.id === selectedViewId) || config.views[0];
    const columnOrder = get(view, ['combinator', 'columnOrder'], config.columnOrder);

    // When switching view, apply its configuration for current table's configuration
    const newConfig = {
      view: view,
      filter: get(view, ['combinator', 'filter'], config.filter),
      sort: get(view, ['combinator', 'sort'], config.sort),
      dimension: get(view, ['combinator', 'properties', 'dimension']),
      attribute: get(view, ['combinator', 'properties', 'attribute'], []),
      metric: get(view, ['combinator', 'properties', 'metric'], []),
      groupBy: get(view, ['combinator', 'groupBy'], config.groupBy),
      pinnedColumn: get(view, ['combinator', 'pinnedColumn'], config.pinnedColumn),
      columnOrder,
      [SPECIAL_FILTERS]: get(view, ['combinator', SPECIAL_FILTERS], config[SPECIAL_FILTERS]),
      groupPeriod: get(view, ['combinator', 'groupPeriod'], config.groupPeriod),
      columnWidth: get(view, ['combinator', 'columnWidth'], config.columnWidth),
    };

    const systemSettings = Object.entries(mapping).reduce((carry, [key, value]) => {
      return {
        ...carry,
        [key]: value.systemSettings || {},
      };
    }, {});

    const { hiddenColumns, disabledColumns, singleId } = Object.entries(systemSettings).reduce(
      (carry, [k, v]) => {
        const isHidden =
          toValue(get(v, ['properties.state'], ''), { ...bbConfig.configuration, ...newConfig }) == 'hidden';
        const isDisabled =
          toValue(get(v, ['properties.status'], ''), { ...bbConfig.configuration, ...newConfig }) == 'disabled';

        const singleId =
          toValue(get(v, ['properties.singleId'], ''), { ...bbConfig.configuration, ...newConfig }) || '';

        if (isHidden) carry['hiddenColumns'].push(k);
        if (isDisabled) carry['disabledColumns'].push(k);
        if (singleId) {
          carry['singleId'][k] = singleId;
        }
        return carry;
      },
      {
        disabledColumns: [],
        hiddenColumns: [],
        singleId: {},
      },
    );

    const excludeColumns = flatten(Object.values(get(view, 'excludeProperty', {}))).concat(
      configHiddenColumns,
      hiddenColumns,
    );

    if (hiddenColumns.length) {
      newConfig.dimension = newConfig.dimension.filter((i) => !hiddenColumns.includes(i));
      newConfig.attribute = newConfig.attribute.filter((i) => !hiddenColumns.includes(i));
      newConfig.metric = newConfig.metric.filter((i) => !hiddenColumns.includes(i));
      const newSpecialFilters = cloneDeep(newConfig[SPECIAL_FILTERS]);
      hiddenColumns.forEach((k) => {
        if (newSpecialFilters[k]) {
          delete newSpecialFilters[k];
        }
      });
      set(newConfig, [SPECIAL_FILTERS], newSpecialFilters);
      newConfig.filter = removeHiddenColumnFromFilter(hiddenColumns, newConfig.filter || []);
    }

    for (const [colId, colVal] of Object.entries(mapping)) {
      const colValue: any = colVal;
      if (colValue.propertyType && !excludeColumns.includes(colId as any)) {
        opt[colValue.propertyType] = (opt[colValue.propertyType] || []).concat({
          id: colId,
          name: colValue.title,
          filterField: colValue.filterField,
          sortField: colValue.sortField || colValue.filterField,
          dataType: colValue.dataType || 'string',
          disable: false,
          disabled: disabledColumns.includes(colId),
          singleId: singleId?.[colId] || '',
        });
        if (colValue.propertyType == 'metric' && isPivotMetric) {
          opt['pivot_metric'] = opt[colValue.propertyType];
        }
      }
    }

    if (isPivotMetric) {
      opt['pivot_metric'] = opt['metric'].map((i) => {
        return {
          ...i,
          id: i.id + '_pivot',
        };
      });
    }

    for (const [key, val] of Object.entries(opt)) {
      opt[key] = sortItemsByField(val, columnOrder, 'id');
    }

    if (isViewChanged) {
      updateConfig(newConfig);
    }

    setViews(config.views);
    setCurrentView(view.id);

    setOptions((options) => ({
      ...options,
      ...opt,
    }));
  };

  const initViews = () => {
    const listViews = config.views;
    if (listViews.length === 0) {
      const views = [
        {
          id: 'default',
          name: 'Default',
          combinator: {
            filter: get(config, ['filter'], []),
            sort: get(config, ['sort'], []),
            properties: {
              dimension: get(config, ['dimension'], []),
              attribute: get(config, ['attribute'], []),
              metric: get(config, ['metric'], []),
            },
            groupBy: get(config, ['groupBy'], {}),
            pinnedColumn: get(config, ['pinnedColumn'], []),
          },
          systemView: true,
          initializedView: true,
        },
      ];
      updateConfig({ views: views, view: views[0] });
    } else {
      const index = listViews.findIndex((item) => item.id === 'default' || item.id === 'all');

      if (index !== -1 && !get(listViews[index], ['initializedView'], false)) {
        const defaultCombinator = {
          dimension:
            get(config, ['dimension'], []).length === 0
              ? get(tableRef, 'current.config.dimension', [])
              : get(config, ['dimension']),
          attribute:
            get(config, ['attribute'], []).length === 0
              ? get(tableRef, 'current.config.attribute', [])
              : get(config, ['attribute']),
          metric:
            get(config, ['metric'], []).length === 0
              ? get(tableRef, 'current.config.metric', [])
              : get(config, ['metric']),
        };
        const newListViews = [
          ...listViews.slice(0, index),
          {
            id: 'default',
            name: 'Default',
            combinator: {
              filter: get(config, ['filter'], []),
              sort: get(config, ['sort'], []),
              properties: {
                dimension: defaultCombinator.dimension,
                attribute: defaultCombinator.attribute,
                metric: defaultCombinator.metric,
              },
              groupBy: get(config, ['groupBy'], {}),
              pinnedColumn: get(config, ['pinnedColumn'], []),
            },
            systemView: true,
            initializedView: true,
          },
          ...listViews.slice(index + 1),
        ];

        updateConfig({ views: newListViews, view: newListViews[index] });
      }
    }
  };

  const getSortFilterFieldsByType = (type: string) => {
    const filterFields = getFilterFields(config.filter);
    const sortFields = config.sort.map((i) => i.field);
    const addFields = [...filterFields, ...sortFields];
    return Object.entries(config.mapping).reduce((acc, [k, v]) => {
      if (addFields.includes(k) && v['propertyType'] === type) {
        return [...acc, k];
      }
      return acc;
    }, []);
  };

  const getColumnFields = (type) => {
    const hiddenFields = get(config, ['requestHiddenField', type], []);
    const ignoreFields = get(config, ['requestIgnoreField', type], []);
    let fields = [...hiddenFields];
    let propertyIds = [];

    switch (type) {
      case 'dimension': {
        propertyIds = get(config, 'dimension', []);
        break;
      }
      case 'attribute': {
        fields = [...fields];
        propertyIds = [
          ...get(config, 'dimension', []),
          ...get(config, 'attribute', []),
          ...getSortFilterFieldsByType('attribute'),
        ];
        break;
      }
      case 'metric': {
        propertyIds = [...get(config, 'metric', []), ...getSortFilterFieldsByType('metric')];
        break;
      }
    }

    propertyIds
      // FIXME: more flexible way to ignore columns eg. column_name.valueGetter.will_ignore_field
      .forEach((id) => {
        let getterFields = Object.values(get(config, ['mapping', id, 'valueGetter'], {}));
        getterFields = getterFields.filter((f) => !isFormulaField(f));
        if (type === 'dimension') {
          getterFields = getterFields.map((i: string) => head(i.split('.')));
        }
        fields = fields.concat(getterFields);
      });
    return uniq(fields.filter((i) => !ignoreFields.includes(i)));
  };

  const loadTableData = (route = [], purge) => {
    if (tableRef.current.gridApi) {
      tableRef.current.gridApi.refreshServerSideStore({ route, purge });
    }
  };

  let loadPagination;
  loadPagination = async (traceId) => {
    const params = {
      previousTraceId: traceId,
      pagination: {
        page: tableRef.current.pagination.page,
        limit: tableRef.current.pagination.limit,
      },
    };

    return new Promise(async (resolve) => {
      try {
        const res = await apiRequest.getPaginationInfo(params);
        if (res.success) {
          const total = get(res, 'pagination.total', 0);
          tableRef.current.updateTotalResult(total);
        }

        resolve({
          success: true,
          data: res,
        });
      } catch (e) {
        // Handle request when losing connection
        resolve({
          success: false,
          data: {},
        });
      }
    });
  };

  const [reloadHandler, setReloadHandler] = React.useState({
    table: loadTableData,
    pagination: loadPagination,
  });

  const handleChangePagination = (value: { page: number; limit: number }) => {
    const newPagination = {
      ...pagination,
      ...value,
    };
    log('handlechangepagination', newPagination);
    if (pagination.limit !== newPagination.limit) {
      const newPage = Math.floor((pagination.page * pagination.limit) / newPagination.limit);
      newPagination.page = newPage;
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.limit));
    } else if (pagination.page != newPagination.page) {
      tableRef.current.gridApi.paginationSetPageSize(Number(newPagination.page * newPagination.limit));
    }
    setPagination(newPagination);
  };

  const handleChangeVisibility = (name: string, isVisible: boolean) => {
    log('Change visibility', { name, isVisible });
    setVisibility({
      ...visibility,
      [name]: isVisible,
    });
  };

  const handleChangeStatus = (name: string, value: StatusType) => {
    setStatus((status) => ({
      ...status,
      [name]: value,
    }));
  };

  const updateConfig = (value: any) => {
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries<any>(value)) {
          draft[key] = val;
          if (key === 'view' && !isEqual(config[PERSONALIZATION], val.combinator[PERSONALIZATION])) {
            draft[PERSONALIZATION] = val.combinator[PERSONALIZATION];
          }
          if (key === 'groupBy') {
            setGroupedCols(val);
          }
        }
      });
      changeConfiguration(newConfig);

      Object.keys(value).forEach((k) => {
        switch (k) {
          case 'columnOrder': {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft.columnOrder = value[k];
            });
            break;
          }
          case 'pinnedColumn': {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft.pinnedColumn = value[k];
            });
            break;
          }
          case 'calculation': {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft.calculation = value[k];
            });
            break;
          }
          case 'columnWidth': {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft.columnWidth = value[k];
            });
            break;
          }
          case PERSONALIZATION: {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft[PERSONALIZATION] = value[k];
            });
            break;
          }
          // Prevent reload when updating label and additionalInformations
          case SPECIAL_FILTERS: {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              if (Object.keys(value?.[k] || {}).length) {
                draft[SPECIAL_FILTERS] = Object.entries(tableRef.current.config?.[SPECIAL_FILTERS] || {}).reduce(
                  (carry, [key, filter]) => {
                    return {
                      ...carry,
                      [key]: {
                        ...(filter || {}),
                        ...get(value, [k, key], {}),
                        queryValue: get(tableRef, ['current', 'config', SPECIAL_FILTERS, key, 'queryValue']),
                      },
                    };
                  },
                  {},
                );
              }
            });
            break;
          }
          case SHORTCUT_ACTIONS: {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft[SHORTCUT_ACTIONS] = value[k];
            });
            break;
          }
          case 'tableParams': {
            tableRef.current.config = produce(tableRef.current.config, (draft) => {
              draft['tableParams'] = value[k];
            });
            break;
          }
        }
      });

      // FIXME: tableref.current.config is potentially out of date, some config will trigger query data. some would not
      // tableRef.current.config = newConfig;
      fastRef.current.config = newConfig;
      // console.log('UpdateConfig 1', newConfig);
      return newConfig;
    });
  };

  const updateSystemConfig = (value: any) => {
    setConfig((config) => {
      const newConfig = produce(config, (draft) => {
        for (const [key, val] of Object.entries(value)) {
          set(draft, ['system', key], val);
        }
      });
      changeConfiguration(newConfig);
      tableRef.current.config = newConfig;
      // console.log('UpdateConfig 2', newConfig);
      return newConfig;
    });
  };

  const handleChangeOptionOrder = (name: string, ids: Array<number | string>) => {
    const newArr = sortItemsByField(get(options, name, []), ids, 'id');
    const newOptions = {
      ...options,
      [name]: newArr,
    };
    setOptions(newOptions);

    const colOrders = config.settingType.reduce((acc, val) => {
      const cols = get(newOptions, val.type, []).map((c) => c.id);
      return acc.concat(cols);
    }, []);
    changeConfig('columnOrder', colOrders);

    if (tableRef.current.columnApi.moveColumns) {
      const colApi: ColumnApi = tableRef.current.columnApi;
      const groupedCols = colApi.getRowGroupColumns().map((c) => c.getColId());
      const effCols = colOrders.filter((cid) => colApi.getColumn(cid) && !groupedCols.includes(cid));
      const pivotOrders = colOrders.filter((i) => String(i).endsWith('_pivot')).map((i) => i.replace('_pivot', ''));
      const mappedGridColumns = tableRef.current.columnApi.getAllGridColumns().map((i) => {
        if (i.colId?.startsWith('pivot_header')) {
          return {
            colId: i.colId,
            rawMetric: i.rawMetric,
            position: i.instanceId,
            parentIndex: i.parentIndex,
          };
        }
        return null;
      });
      mappedGridColumns.sort((a, b) => {
        if (a == null || b == null) return 1;
        if (a.parentIndex != b.parentIndex) return a.parentIndex - b.parentIndex;
        return pivotOrders.indexOf(a.rawMetric) - pivotOrders.indexOf(b.rawMetric);
      });
      colApi.moveColumn(effCols[0], 1 + groupedCols.length);
      colApi.moveColumns(effCols.slice(1), 2 + groupedCols.length);
      mappedGridColumns.forEach((col, index) => {
        if (col) {
          colApi.moveColumn(col.colId, index);
        }
      });
    }
  };

  const handleChangeColumnOrder = (columnOrders: string[]) => {
    const newOptions = produce(options, (draft) => {
      config.settingType.forEach((i) => {
        draft[i.type] = sortItemsByField(get(options, [i.type], []), columnOrders, 'id');
      });
    });
    setOptions(newOptions);
    changeConfig('columnOrder', columnOrders);
  };

  React.useEffect(() => {
    const isViewChanged = tableRef.current?.config?.view?.id
      ? tableRef.current?.config?.view?.id != config.view?.id
      : false;
    initPropertyOptions(isViewChanged);
    resetColumns();
  }, [config.view]);

  React.useEffect(() => {
    resetColumns();
    if (tableRef.current.config.tableType === '' && tableRef.current.gridApi) {
      // tableRef.current.gridApi.getSelectedNodes().forEach(n => n.setSelected(false));
      tableRef.current.gridApi.deselectAll();
      tableRef.current.selectedRows = [];
    }
  }, [
    config.dimension,
    config.metric,
    config.attribute,
    config.columnOrder,
    options.dimension,
    options.metric,
    options.attribute,
    config.groupBy,
    config.groupPeriod,
    config[SPECIAL_FILTERS],
  ]);

  React.useEffect(() => {
    if (tableRef.current.config.tableType === '' && tableRef.current.gridApi) {
      // tableRef.current.gridApi.getSelectedNodes().forEach(n => n.setSelected(false));
      tableRef.current.gridApi.deselectAll();
      tableRef.current.selectedRows = [];
    }
  }, [config.sort, config.filter, config.dateRange.dateFrom, config.dateRange.dateTo, config.groupBy, config.view]);

  React.useEffect(() => {
    // Compare config more deeper
    let isConfigTheSame = true;
    Object.keys(config).forEach((key) => {
      if (!isEqual(get(tableRef, ['current', 'config', key]), config[key])) {
        isConfigTheSame = false;
      }
    });
    const isGroupedColumnsTheSame =
      config?.visualizationType == 'table'
        ? isEqual(
            get(config, ['view', 'combinator', 'groupBy', 'columns'], []),
            get(tableRef, ['current', 'config', 'view', 'combinator', 'groupBy', 'columns'], []),
          )
        : true;
    if (tableRef.current.gridApi && !isConfigTheSame && isGroupedColumnsTheSame) {
      if (process.env.NODE_ENV === 'development') {
        log('diff', compare(tableRef.current.config, config));
      }
      log('table reload');
      const usePivotMultipleHeader = config.usePivotMultipleHeader;
      const shouldUpdatePagination =
        hasPivotMetric &&
        (tableRef.current.config?.usePivotMultipleHeader != usePivotMultipleHeader ||
          (!isEqual(get(config, ['pivot_metric'], null), get(tableRef, ['current', 'config', 'pivot_metric'], null)) &&
            !(usePivotMultipleHeader != 'no')));

      const limit = shouldUpdatePagination ? config.pivot_metric?.length * initPagination.limit : initPagination.limit;
      tableRef.current.config = cloneDeep(config);
      tableRef.current.queryToken = nanoid();
      tableRef.current.pagination = {
        page: 1,
        limit: limit,
        totalPivot: shouldUpdatePagination ? config.pivot_metric?.length : 1,
      };

      setPagination((prev) => {
        return {
          ...prev,
          ...tableRef.current.pagination,
        };
      });
      tableRef.current.gridApi?.paginationSetPageSize(limit);
      tableRef.current.gridApi?.gridOptionsWrapper?.setProperty('cacheBlockSize', limit);

      tableRef.current.gridApi?.refreshServerSideStore({ purge: true });
      if (shouldUpdatePagination) {
        setCreatedTime(null);
      }
    } else if (!isGroupedColumnsTheSame) {
      tableRef.current.config = cloneDeep(config);
    }
  }, [
    config.sort,
    config.filter,
    config.currency,
    config.search,
    config.fieldOperator,
    config.dateRange,
    config.groupBy,
    config.metric,
    config.dimension,
    config.attribute,
    config.pivot?.columnValue,
    config.tableMode,
    config.keyword,
    config.hiddenFilter,
    config.cohortDateRange,
    config[SPECIAL_FILTERS],
    config.groupPeriod,
    config.selectedMetricTraction,
    config.metricTractionProperties,
    config[PERSONALIZATION],
    config.pivot_metric,
    config.groupDimension,
    config.usePivotMultipleHeader,
  ]);

  const getFilterFields = (filter: TableBackbone.filter[]) => {
    if (filter.length === 0) return [];
    return filter.reduce((acc, i) => {
      if (i.subFilters) {
        return [...acc, ...getFilterFields(i.subFilters)];
      }
      return [...acc, i.field];
    }, []);
  };

  const getAvailableColumns = () => {
    const properties = ['dimension', 'attribute', 'metric'];
    return properties.reduce((acc, property) => {
      const cols = get(options, property, []).map((c) => ({ ...c, propertyType: property }));
      return [...acc, ...cols];
    }, []);
  };

  const getAvailableGroupByColumns = () => {
    return get(config, 'dimension', []);
  };

  const getAvailableFilterColumns = () => {
    return getAvailableColumns().reduce((acc, c) => {
      if (c.filterField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.filterField,
            dataType: c.dataType || 'string',
            propertyType: c.propertyType,
          },
        ];
      }
      return acc;
    }, []);
  };

  const getAvailableSortColumns = () => {
    const columns = getAvailableColumns().reduce((acc, c) => {
      if (c.sortField) {
        return [
          ...acc,
          {
            name: c.name,
            id: c.id,
            field: c.sortField,
            dataType: c.dataType || 'string',
            propertyType: c.propertyType,
          },
        ];
      }
      return acc;
    }, []);
    return addon('system.get.sort.available.column', () => columns)(columns);
  };

  const getTimePeriodicallyOptions = () => {
    return viewByTimelineOptions.map((el) => ({
      ...el,
      checked: false,
    }));
  };

  const sortItemsByField = (items, orderedValues, field) => {
    const newItems = [...items];
    newItems.sort((a, b) => {
      const indexA = orderedValues.indexOf(a[field]);
      const indexB = orderedValues.indexOf(b[field]);
      if (indexA < indexB) {
        return -1;
      }
      if (indexA > indexB) {
        return 1;
      }
      return 0;
    });
    return newItems;
  };

  const resetColumns = () => {
    const groupByColumns = get(config, 'groupBy.columns', []);
    const columns = config.settingType.reduce((acc, val) => {
      if (options[val.type]) {
        const selectedCols = get(config, [val.type], []);
        const cols = options[val.type]
          .filter((i) => selectedCols.includes(i.id))
          .map((c) => ({
            name: c.name,
            field: c.id,
            dataType: c.dataType,
            sortable: false,
            filterField: get(config, ['mapping', c.id, 'filterField'], ''),
            filterGetter: get(config, ['mapping', c.id, 'filterGetter']),
            cell: {
              format: getCellFormat(c.id),
              valueGetter: get(config, ['mapping', c.id, 'valueGetter']),
              staticValue: get(config, ['mapping', c.id, 'staticValue']),
            },
            action: groupByColumns.includes(c.id) ? getHeaderActions('groupBy') : getHeaderActions(c.id, val.type),
            aggFunc: get(config, ['groupBy', 'aggregations', c.id, 'func']),
          }));
        return acc.concat(cols);
      }
      return acc;
    }, []);
    log('reset columns', { groupByColumns, config, columns });
    addon('system.reset.columns', () => setHeaders(columns))(columns, setHeaders);
  };

  const getHeaderActions = (columnField: string, columnType?: string) => {
    if (ref.current.headerAction[columnField]) {
      return ref.current.headerAction[columnField];
    } else {
      if (ref.current.headerAction[columnType]) {
        return ref.current.headerAction[columnType];
      } else {
        return ref.current.headerAction['default'];
      }
    }
  };

  const getCellFormat = (columnField: string) => {
    const formatType = get(config, ['mapping', columnField, 'cellFormat'], 'text');
    return ref.current.cellFormat[formatType];
  };

  const handleGetSelectedRows = (nextRows?: { data: any; isSelected: boolean }[]) => {
    const selectedRows = tableRef.current.selectedRows;

    if (!nextRows) return selectedRows;

    const kvSelected = selectedRows.reduce((carry, i) => {
      return { ...carry, [getRowId(i)]: true };
    }, {});

    const nextSelectedRows = produce(selectedRows, (draft) => {
      nextRows.forEach(({ data, isSelected }) => {
        const rowId = getRowId(data);
        if (isSelected && kvSelected[rowId] === undefined) {
          draft.push(data);
        } else if (!isSelected && kvSelected[rowId] !== undefined) {
          const index = current(draft).findIndex((dt) => getRowId(dt) == rowId);
          if (index > -1) {
            draft.splice(index, 1);
          }
        }
      });
    });

    tableRef.current.selectedRows = nextSelectedRows;
    return addon('getSelectedRows', (nextSelectedRows) => nextSelectedRows)(nextSelectedRows);
  };

  const handleUpdateSelectedRows = (rows: any[]) => {
    const primaryKeys = get(config, `primaryKeys`, []);
    tableRef.current.selectedRows = cloneDeep(rows);
    if (tableRef.current.gridApi && primaryKeys.length > 0) {
      restoreSelectedRows({ api: tableRef.current.gridApi });
    }
  };

  const getDataRows = () => {
    if (!tableRef.current.datasource) {
      tableRef.current.datasource = createDatasource(tableRef);
    }

    setStatus((status) => ({
      ...status,
      table: 'loading',
    }));

    return new Promise((resolve, reject) => {
      tableRef.current.datasource.getRows({
        request: { startRow: 0 },
        success: ({ rowData }) => {
          log('resolve getrows', rowData);
          setStatus((status) => ({
            ...status,
            table: 'success',
          }));
          resolve(rowData);
        },
        fail: (error) => {
          setStatus((status) => ({
            ...status,
            table: 'error',
          }));
          reject(error);
        },
      });
    });
  };

  const registerMainButtonComponent = (btnComponent: any) => {
    ref.current.mainButton = btnComponent;
  };

  const registerHeaderAction = (action: any) => {
    ref.current.headerAction = action;
  };

  const registerCellFormat = (format: any) => {
    ref.current.cellFormat = format;
  };

  const registerCellEditor = (editor: any) => {
    ref.current.cellEditor = editor;
  };

  const registerNotification = (notification: {
    success: (title: string, messages: string[]) => void;
    error: (title: string, messages: string[]) => void;
  }) => {
    ref.current.notification = notification;
  };

  const addon = React.useCallback((path, defaults = null) => {
    const fun = get(tableRef.current.addons, [path], defaults);
    if (!fun) return null;
    return (...args) => fun(...args, ref.current.backboneInstance);
  }, []);

  const registerAddon = React.useCallback((path, handler) => {
    tableRef.current.addons = set(tableRef.current.addons || {}, [path], handler);
  }, []);

  const trackingUpdateView = (name: string, value: any) => {
    if (
      [
        'filter',
        'sort',
        'dimension',
        'metric',
        'attribute',
        'pivot_metric',
        'groupDimension',
        'usePivotMultipleHeader',
        'groupBy',
        'pinnedColumn',
        SPECIAL_FILTERS,
        'groupPeriod',
        'columnWidth',
        'columnOrder',
        PERSONALIZATION,
        'currency',
        'calculation',
        'showVerticalLines',
      ].includes(name)
    ) {
      const listViews = tableRef.current.config.views;
      const selectedViews = tableRef.current.config.view;
      const index = listViews.findIndex((item) => item.id === selectedViews.id);
      if (index !== -1) {
        const newSelected = {
          ...selectedViews,
          combinator: {
            ...get(selectedViews, ['combinator'], {}),
            filter: name === 'filter' ? value : tableRef.current.config.filter,
            sort: name === 'sort' ? value : tableRef.current.config.sort,
            properties: ['dimension', 'metric', 'attribute']
              .concat(name == 'pivot_metric' ? 'pivot_metric' : [])
              .includes(name)
              ? {
                  dimension: tableRef.current.config.dimension,
                  attribute: tableRef.current.config.attribute,
                  metric: tableRef.current.config.metric,
                  [name]: value,
                }
              : {
                  ...selectedViews.combinator.properties,
                },
            groupDimension: name === 'groupDimension' ? value : tableRef.current.config.groupDimension || [],
            usePivotMultipleHeader:
              name === 'usePivotMultipleHeader' ? value : tableRef.current.config.usePivotMultipleHeader || 'yes',
            groupBy: name === 'groupBy' ? value : tableRef.current.config.groupBy,
            pinnedColumn: name === 'pinnedColumn' ? value : tableRef.current.config.pinnedColumn,
            [SPECIAL_FILTERS]: name === SPECIAL_FILTERS ? value : tableRef.current.config[SPECIAL_FILTERS],
            groupPeriod: name === 'groupPeriod' ? value : tableRef.current.config.groupPeriod,
            columnWidth: name === 'columnWidth' ? value : tableRef.current.config.columnWidth,
            columnOrder: name === 'columnOrder' ? value : tableRef.current.config.columnOrder,
            [PERSONALIZATION]: name === PERSONALIZATION ? value : tableRef.current.config[PERSONALIZATION],
            currency: name === 'currency' ? value : tableRef.current.config.currency,
            calculation: name === 'calculation' ? value : tableRef.current.config.calculation,
            ...(name == 'showVerticalLines'
              ? { showVerticalLines: value || tableRef.current.config.showVerticalLines || 'yes' }
              : {}),
          },
          ...(name === PERSONALIZATION ? { [PERSONALIZATION]: value } : {}),
        };
        const newListViews = [...listViews.slice(0, index), newSelected, ...listViews.slice(index + 1)];

        tableRef.current.config = produce(tableRef.current.config, (draft) => {
          draft.views = newListViews;
          draft.view = newSelected;
        });
        updateView1(newSelected);
        setCurrentView(newSelected.id);
        return { views: newListViews, view: newSelected, [name]: value };
      }
    }
    return { [name]: value };
  };

  const changeConfig = React.useCallback((name: string, value: any) => {
    let nextValue = value;
    if (name === SPECIAL_FILTERS) {
      nextValue = Object.keys(value || {}).reduce((acc, key) => {
        return { ...acc, [key]: omit(value[key], ['queryField']) };
      }, {});
    }

    addon(`config.${name}`, () => Promise.resolve({ value: nextValue }))(
      { value: nextValue, previousValue: get(tableRef.current.config, name) },
      tableRef.current.config,
    ).then(({ value }) => {
      const trackedUpdateView = trackingUpdateView(name, value);
      updateConfig(trackedUpdateView);
      if (name.indexOf('system.') === 0) {
        const varName = name.replace('system.', '');
        updateSystemConfig({ [varName]: value });
      }
    });
  }, []);

  const createUserView = (name: string, id?: string) => {
    if ('allowCustomView' in config && config.allowCustomView) {
      const newSelect = {
        id: id || uuid(),
        name: name.trim(),
        combinator: {
          filter: tableRef.current.config.filter,
          sort: tableRef.current.config.sort,
          properties: {
            dimension: tableRef.current.config.dimension,
            attribute: tableRef.current.config.attribute,
            metric: tableRef.current.config.metric,
          },
          groupBy: tableRef.current.config.groupBy,
          pinnedColumn: tableRef.current.config.pinnedColumn,
        },
        systemView: false,
      };
      const views = [...config.views, newSelect];
      updateConfig({ views: views });

      return newSelect;
    }
    return false;
  };

  const changeConfigMultiple = React.useCallback((config: Record<string, any>) => {
    const arr = [];
    for (const [name, value] of Object.entries(config)) {
      const temp = new Promise((resolve) => {
        addon(`config.${name}`, () => Promise.resolve({ value }))(
          { value, previousValue: get(tableRef.current.config, name) },
          tableRef.current.config,
        ).then(({ value }) => {
          resolve({ [name]: value });
        });
      });
      arr.push(temp);
    }
    return Promise.all(arr).then((arr) => {
      const fConfig = arr.reduce((carry, obj) => {
        return { ...carry, ...obj };
      }, {});
      updateConfig(fConfig);
    });
  }, []);

  const getTableRows = () => {
    const result = [];
    if (tableRef.current?.gridApi?.forEachNode) {
      tableRef.current.gridApi.forEachNode((node) => {
        result.push(node);
      });
    }

    return result;
  };

  const selectView = async (id: string) => {
    const listViews = config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1) {
      const newViewSelect: any = listViews[index];
      if ('combinator' in newViewSelect && 'allowCustomView' in config && config.allowCustomView) {
        // update config switch custom view
        await changeConfigMultiple({
          filter: newViewSelect.combinator.filter,
          sort: newViewSelect.combinator.sort,
          groupBy: newViewSelect.combinator.groupBy,
          pinnedColumn: newViewSelect.combinator.pinnedColumn,
          //
          attribute: newViewSelect.combinator.properties.attribute,
          metric: newViewSelect.combinator.properties.metric,
          dimension: newViewSelect.combinator.properties.dimension,
        });
        // changeConfig('view', newViewSelect);
      }
      changeConfig('view', newViewSelect);
      setCurrentView(id);
    }
  };

  const updateUserView = (
    id: string,
    name: string,
    filter: any[] = null,
    sort: any[] = null,
    properties: any = null,
    groupBy: any = null,
    pinnedColumn: any = null,
  ) => {
    const listViews: any = tableRef.current.config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1 && 'allowCustomView' in config && config.allowCustomView) {
      const newListViews = [
        ...listViews.slice(0, index),
        {
          ...listViews[index],
          name: !listViews[index].systemView ? name : listViews[index].name,
          combinator: {
            filter: filter ? filter : tableRef.current.config.filter,
            sort: sort ? sort : tableRef.current.config.sort,
            properties: properties
              ? {
                  ...listViews[index].combinator.properties,
                  ...properties,
                }
              : {
                  dimension: tableRef.current.config.dimension,
                  attribute: tableRef.current.config.attribute,
                  metric: tableRef.current.config.metric,
                },
            groupBy: groupBy ? groupBy : tableRef.current.config.groupBy,
            pinnedColumn: pinnedColumn ? pinnedColumn : tableRef.current.config.pinnedColumn,
          },
        },
        ...listViews.slice(index + 1),
      ];
      updateConfig({ views: newListViews });
      tableRef.current.config.views = newListViews;
      if (tableRef.current.config.view.id === id) {
        updateConfig({ view: newListViews[index] });
        tableRef.current.config.view = newListViews[index];
      }
      updateView1(newListViews[index]);
      return true;
    }
    return false;
  };

  const deleteUserView = (id: string) => {
    const listViews: any = config.views;
    const index = listViews.findIndex((item) => item.id === id);
    if (index !== -1 && !listViews[index].systemView && 'allowCustomView' in config && config.allowCustomView) {
      const newListViews = listViews.filter((v) => v.id !== id);
      updateConfig({ views: newListViews });
      tableRef.current.config.views = newListViews;
      if (tableRef.current.config.view.id === id) {
        const newViewSelect = newListViews[index === newListViews.length ? newListViews.length - 1 : index];
        selectView(newViewSelect.id);
      }
      return true;
    }
    return false;
  };

  tableRef.current.apiRequest = apiRequest;
  tableRef.current.getColumnFields = getColumnFields;
  tableRef.current.updateTotalResult = (total) => {
    setPagination((pagination) => ({ ...pagination, total: total }));
  };

  tableRef.current.updatePaginationTraceId = (traceId) => {
    setPagination((pagination) => {
      console.info('setPagination', traceId);
      return { ...pagination, traceId };
    });
    tableRef.current.pagination.traceId = traceId;
  };

  tableRef.current.getAvailableGroupByColumns = getAvailableGroupByColumns;
  tableRef.current.getAvailableFilterColumns = getAvailableFilterColumns;
  tableRef.current.getAvailableSortColumns = getAvailableSortColumns;
  tableRef.current.changeConfig = changeConfig;
  tableRef.current.addon = addon;
  tableRef.current.datasource = React.useMemo(() => createDatasource(tableRef), []);

  function restoreSelectedRows({ api }) {
    if (tableRef.current.config.tableType !== 'compact') return;
    const primaryKeys = get(config, 'primaryKeys', []);
    if (api.forEachNode) {
      api.forEachNode((rowNode) => {
        if (rowNode.data) {
          const exist = tableRef.current.selectedRows.find((r) => {
            return primaryKeys.every((key) => r[key] === rowNode.data[key]);
          });
          rowNode.setSelected(!!exist);
        }
      });
    }
  }

  function getMainButtonComponent(name: string) {
    return get(ref.current.mainButton, name, null);
  }

  function getRowId(row, ignoreKeys = []) {
    if (!row) return null;
    const primaryKeys = get(config, 'primaryKeys', []);

    const addonGetRowId = addon('getRowId', undefined);
    if (typeof addonGetRowId === 'function') {
      return addonGetRowId(row, primaryKeys, ignoreKeys);
    }

    return ['_eipCustomId']
      .concat(primaryKeys)
      .filter((i) => ignoreKeys.indexOf(i) === -1)
      .map((i) => {
        return row[i];
      })
      .join('|');
  }

  const getFilterOptionValue = (
    columnName: string,
    payload?: any,
    forceReload: boolean,
    selectedValues: string[] | number[] = [],
  ) => {
    const filterValueReq = get(tableRef.current.addons, `filterValue.${columnName}`);
    if (filterValueReq) {
      return filterValueReq(
        payload,
        forceReload,
        getInitialDataRequest,
        tableRef.current.getColumnFields,
        selectedValues,
      );
    }
    return null;
  };

  const handleExportEtable = async () => {
    if (hasAddon('system.export')) {
      return addon('system.export')(get(ref, ['current', 'backboneInstance', 'config', 'tableParams'], {}));
    }
    let getFilter, filter;
    getFilter = get(tableRef.current, ['config', 'filter']);
    filter = tableRef.current.datasource.getParamFilter(getFilter);
    const titleFail = 'Time range is too long';
    const messagesFail = 'For now, you can only export data for 31 days. Try adjusting your time range.';
    const dateFrom = config.dateRange.dateFrom;
    const dateTo = config.dateRange.dateTo;
    const durationDate = moment.duration(moment(dateTo).diff(moment(dateFrom))).asDays();
    const endpointApi = get(config.endpoint, 'GET_TABLE_DATA', '').match(/\/mop(.*)*/);
    const params = {
      dimensions: getColumnFields('dimension'),
      attributes: getColumnFields('attribute'),
      metrics: getColumnFields('metric'),
      from: dateFrom,
      to: dateTo,
      sort: null,
      filter: { ...filter },
      hiddenFilter: get(config, 'hiddenFilter', {}),
      api: endpointApi[0],
    };

    if (durationDate + 1 > 31) {
      return { title: titleFail, messages: messagesFail, variant: 'warning' };
    }

    return new Promise(async (resolve) => {
      const response = await apiRequest.requestExportEtable(params);
      resolve({
        title: response.title,
        messages: response.messages,
        variant: response.variant,
      });
    });
  };

  const hasAddon = (addon) => {
    return get(tableRef.current.addons, addon, null);
  };

  ref.current.backboneInstance = {
    visibility,
    status,
    options,
    setConfig: (newConfig) => {
      tableRef.current.config = { ...DEFAULT_CONFIG, ...newConfig };
      setConfig(newConfig);
    },
    updateConfig,
    config,
    getRowId: getRowId,
    setGridApi: ({ grid, column }) => {
      tableRef.current.gridApi = grid;
      tableRef.current.columnApi = column;
      if (grid.addEventListener) {
        grid.addEventListener('paginationChanged', (event) => {
          restoreSelectedRows(event);
        });
      }
      // tableRef.current.gridApi.refreshServerSideStore({ purge: true });
    },
    getGridApi: () => tableRef.current.gridApi,
    getColumnApi: () => tableRef.current.columnApi,
    reloadData: (name: string, route, purge) => {
      log('reloadDATA', name);
      reloadHandler[name] && reloadHandler[name](route, purge);
    },
    init: init,
    isTableReady: () => !!tableRef.current.gridApi,
    getCellConfig: (columnField: string) => {
      return get(config, ['mapping', columnField]);
    },
    getConfig: (name: string, defaultValue: any = null) =>
      cloneDeep(get(fastRef.current.config, `${name}`, defaultValue)),
    getCallback: (name: string) => get(tableRef.current.callback, `${name}`, () => undefined),
    createUserView: createUserView,
    updateUserView: updateUserView,
    deleteUserView: deleteUserView,
    selectView: selectView,
    getStatus: (name: string) => get(status, `${name}`, ''),
    getHeaders: () => headers,
    getRows: getDataRows,
    getDatasource: () => tableRef.current.datasource,
    getPagination: () => {
      log('getPagination', tableRef.current.pagination, pagination);
      // console.info('getPagination', tableRef.current.pagination, pagination);
      return pagination;
    },
    getOptions: (name: string) => get(options, name, []),
    getFilterOptionValue: getFilterOptionValue,
    getTempStorage: (name: string) => get(ref.current.tempStorage, name),
    // getOptions: useOption,
    getSubmitHandler: (type: string) => apiRequest[type],
    getVisibility: (name: string) => get(visibility, `${name}`, false),
    getSelectedRows: handleGetSelectedRows,
    updateSelectedRows: handleUpdateSelectedRows,
    updateTempStorage: (name: string, value: any) => {
      ref.current.tempStorage[name] = value;
    },
    changeVisibility: handleChangeVisibility,
    changeStatus: handleChangeStatus,
    changeConfig: changeConfig,
    changeOptionOrder: handleChangeOptionOrder,
    changePagination: handleChangePagination,
    changeColumnOrder: handleChangeColumnOrder,
    getMainButtonComponent,
    getAvailableColumns,
    getAvailableFilterColumns,
    getAvailableSortColumns,
    registerMainButtonComponent,
    registerHeaderAction,
    registerCellFormat,
    registerCellEditor,
    registerNotification,
    registerAddon: registerAddon,
    addon: addon,
    hasAddon,
    loadPagination,
    exportEtable: handleExportEtable,
    getTimePeriodicallyOptions,
    addInitialDataRequest,
    getInitialDataRequest,
    autoResizeColumn: (field) => {
      tableRef.current.columnApi.autoSizeColumn(field, false);
    },
    changeConfigMultiple,
    getTableRows,
    getGroupedColumns: () => groupedCols,
    getCreatedTime: () => createdTime,
  };

  return ref.current.backboneInstance;
};
