export const USER_CONFIG = {
  title: 'User allocation and permission',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {},
  primaryKeys: ['id'],
  system: {
    hiddenComponents: ['search'],
  },
  mainButton: {
    text: 'Allocate New user',
    type: 'component',
    componentName: 'AllocateUser',
  },
  mapping: {
    // ============= Dimension =============
    first_name: {
      title: 'First name',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        value: 'first_name',
        label: 'first_name',
      },
    },

    // ============= ATTRIBUTE =============
    last_name: {
      title: 'Last name',
      propertyType: 'attribute',
      cellFormat: 'text',
      valueGetter: {
        value: 'last_name',
      },
    },
    email: {
      title: 'Email',
      propertyType: 'attribute',
      cellFormat: 'text',
      valueGetter: {
        value: 'email',
      },
    },
    company: {
      title: 'Comapny',
      propertyType: 'attribute',
      cellFormat: 'text',
      valueGetter: {
        value: 'company',
      },
    },
    user_status: {
      title: 'Permission',
      propertyType: 'attribute',
      cellFormat: 'text',
      valueGetter: {
        value: 'permission',
      },
      menu: [
        {
          title: 'Status',
          name: 'Edit permission',
          icon: 'pencil',
          editor: 'UserPermission',
        },
        {
          name: 'Remove access',
          icon: 'delete',
        },
      ],
    },
    assigned_date: {
      title: 'Assigned date',
      propertyType: 'attribute',
      cellFormat: 'text',
      valueGetter: {
        value: 'assigned_date',
      },
    },
  },
};
