import React from 'react';
import rbLogo from './abbott.png';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

import { TimerUI } from '../timer';

import imageLogo from '../../../images/epsilo-logo.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#6A5B76',
      color: '#fff',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    toolbar: {
      height: 56,
      minHeight: 'auto',
      backgroundColor: '#6A5B76',
    },
    logo: {
      height: 24,
    },
    brandxEp: {
      display: 'flex',
      fontSize: 16,
      fontWeight: 500,
      color: '#fff',
      alignItems: 'center',
      '& span': {
        margin: '0 10px',
        color: 'rgba(255, 255, 255, 0.5)',
        '&.client': {
          fontSize: 16,
        },
      },
    },
  }),
);

export const HeaderUI = () => {
  const classes = useStyles();

  return (
    <AppBar className={classes.root}>
      <Toolbar className={classes.toolbar}>
        <Container>
          <Grid container justify="space-between">
            <Grid item xs={12}>
              <div className={classes.brandxEp}>
                <img src={rbLogo} className={classes.logo} />
                <span style={{ marginLeft: 12, paddingBottom: 3 }}>x</span>
                <img src={imageLogo} className={classes.logo} />
              </div>
              <div>
                <TimerUI></TimerUI>
              </div>
            </Grid>
            {/* <Grid item>
            </Grid> */}
          </Grid>
        </Container>
      </Toolbar>
    </AppBar>
  );
};
