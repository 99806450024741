import * as React from 'react';
import { Grid, GridProps, makeStyles } from '@material-ui/core';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/** Style */
const useStyles = makeStyles(() => ({
  formControl: {
    // margin: '20px 0px',
    // marginBottom: '20px',
    '.eres--small &': {
      flexDirection: 'column',
      gap: '4px',
      '& > div': {
        width: '100%',
      },
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    '.eres--small &': {
      color: '#8C98A4',
      fontSize: '12px',
      lineHeight: '16px',
      textTransform: 'uppercase',
      fontWeight: '500',
    },
    color: '#8C98A4',
  },
}));
interface Props extends GridProps {
  label: string;
  children: JSX.Element;
}
const FormControlTemplate = ({ label, children, ...containerProps }: Props) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';

  let labelNameClass;
  labelNameClass = label.replace(/\s/g, '').toLowerCase();
  return (
    <Grid
      container
      alignItems={isMobile ? 'flex-start' : 'center'}
      justifyContent={'space-between'}
      {...containerProps}
      className={`${classes.formControl} ${containerProps.className}`}
    >
      {label && (
        <Grid item xs={isMobile ? 12 : 4}>
          <span className={classes.label}>{label}</span>
        </Grid>
      )}
      <Grid item xs={isMobile || !label ? 12 : 8} className={`eip_tid_formControl_${labelNameClass}`}>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormControlTemplate;
