import * as React from 'react';
import { v4 as uuid } from 'uuid';
import { get } from 'lodash';

import { FieldSelectedValue } from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog';
import { filterOperators, getSelectedOperators } from '@ep/insight-ui/elements/table/table-helper';
import { IFilterItem } from '@ep/insight-ui/elements/inline-dialog/filter/filter-group-dialog/filter-form-group';

const colorPickerStyles = [
  {
    icon: 'ri/MdFormatColorText',
    key: 'label',
  },
  {
    icon: 'ri/MdFormatColorFill',
    key: 'cell_highlight',
  },
];

const labelStyles = [
  {
    icon: 'ri/MdFormatBold',
    key: 'bold',
    value: 'bold',
  },
  {
    icon: 'ri/MdFormatItalic',
    key: 'italic',
    value: 'italic',
  },
  {
    icon: 'ri/MdFormatUnderlined',
    key: 'underline',
    value: 'underline',
  },
];

const appliedStyles = {
  label: 'color',
  cell_highlight: 'background',
  bold: 'fontWeight',
  italic: 'fontStyle',
  underline: 'textDecoration',
};

export const useFormatItem = ({ backbone, props }) => {
  const [styleItems, setStyleItems] = React.useState(get(props, ['item', 'styles'], []));
  const [filterModel, setFilterModel] = React.useState(get(props, ['item', 'filters'], []));
  const [expanded, setExpanded] = React.useState(get(props, ['item', 'expanded'], true));
  const [hideDetail, setHideDetail] = React.useState(!get(props, ['item', 'expanded'], true));
  const [title, setTitle] = React.useState(get(props, ['item', 'title'], ''));
  const [isEditing, setIsEditing] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectedColorStyle, setSelectedColorStyle] = React.useState(null);
  const [colorValue, setColorValue] = React.useState('');

  const styles = styleItems.reduce((a, b) => {
    if (b.value) {
      return { ...a, [appliedStyles[b.key]]: b.value };
    }

    return a;
  }, {});

  const titleRef = React.useRef(get(props, ['item', 'title'], ''));

  const actions = [
    [
      {
        title: 'Duplicate',
        icon: 'duplicate',
        onClick: () => {
          props.handleDuplicateFormatItem(props.item);
        },
      },
      {
        title: 'Remove',
        icon: 'remove',
        iconSize: '16px',
        onClick: () => {
          props.handleDeleteFormatItem(props.item);
        },
      },
    ],
  ];

  React.useEffect(() => {
    if (expanded) {
      setHideDetail(false);
    } else {
      setTimeout(() => {
        setHideDetail(true);
      }, 325);
    }
  }, [expanded]);

  React.useEffect(() => {
    setTitle(titleRef.current);
  }, [titleRef.current]);

  const inputRef = React.useRef(null);

  React.useEffect(() => {
    if (isEditing) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  React.useEffect(() => {
    props.handleUpdateFormatItem({
      ...props.item,
      styles: styleItems,
      filters: filterModel,
      expanded,
      title,
    });
  }, [styleItems, filterModel, expanded, title]);

  React.useEffect(() => {
    const colorStyle = styleItems.find((item) => item.key === selectedColorStyle);
    setColorValue(colorStyle?.value || null);
  }, [selectedColorStyle]);

  const filterOptions: Array<FieldSelectedValue> = backbone.getAvailableFilterColumns().map((item) => {
    return {
      label: item.name,
      value: item.id,
      dataType: item.dataType ?? 'string',
      selectedOperators: backbone.addon('selection.filter', () => getSelectedOperators(item.id))(
        item.id,
        getSelectedOperators(item.id),
      ),
    };
  });

  const handleClosePopover = (color?) => {
    if (selectedColorStyle) {
      handleClickLabelStyle({ key: selectedColorStyle, value: color || colorValue });
    }
    setAnchorEl(null);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>, style) => {
    setAnchorEl(event.currentTarget);
    setSelectedColorStyle(style);
  };

  const getOptionFilterAvailable = async (field: string, filterData?: IFilterItem[]) => {
    return backbone.getFilterOptionValue(field, filterData);
  };

  const handleClickLabelStyle = (item) => {
    const isExisted = styleItems.some(({ key }) => key === item.key);
    if (isExisted) {
      setStyleItems(
        styleItems.map((style) => {
          const isLabelStyle = labelStyles.some(({ key }) => key === item.key);
          const newValue = isLabelStyle ? (style.value ? null : item.value) : item.value;
          return style.key === item.key ? { ...style, value: newValue } : style;
        }),
      );
    } else {
      setStyleItems([...styleItems, { key: item.key, value: item.value, fakeId: uuid() }]);
    }
  };

  return {
    expanded,
    setExpanded,
    hideDetail,
    titleRef,
    title,
    setTitle,
    isEditing,
    setIsEditing,
    styleItems,
    filterOptions,
    filterOperators,
    filterModel,
    setFilterModel,
    getOptionFilterAvailable,
    inputRef,
    actions,
    labelStyles,
    handleClickLabelStyle,
    anchorEl,
    handleClosePopover,
    handleOpenPopover,
    colorPickerStyles,
    setColorValue,
    colorValue,
    styles,
  };
};
