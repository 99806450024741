import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import ETreemapChart from './treemap-chart';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';

class TreemapChart implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <NotificationGlobalProvider>
        <div style={{ padding: '12px' }}>
          <ETreemapChart />
        </div>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'eTreemapChart',
  label: 'Treemap',
  blockComponent: TreemapChart,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 18,
  },
};
