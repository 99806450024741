import { EIP_CONSTANT } from '@eip/next/lib/main';

export const USER_COHORT_CONFIG = {
  tableType: 'userCohort',
  title: 'User cohort',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getUserCohortData',
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  primaryKeys: [],
  mapping: {
    action: {
      title: 'Cohort',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COHORT.name',
      valueGetter: {
        label: 'COHORT.name',
        id: 'COHORT.id',
      },
    },
    line: {
      title: 'Last 8 weeks',
      propertyType: 'metric',
      cellFormat: 'traction',
      dataType: 'string',
      valueGetter: {
        data: 'traction',
      },
    },
  },
};
