export * from './template';
export * from './template-insight';
export * from './template-insight-country';
export * from './template-insight-marketplace';
export * from './template-quality-index';
export * from './template-operation-index';
export * from './ad-object-optimization';
export * from './ad-object-programmatic-setting';
export * from './ad-optimization';
export * from './campaing-settings';
export * from './compact-keyword-optimization';
export * from './compact-programmatic-object';
export * from './log';
export * from './programmatic-setting';
export * from './storefront';
export * from './template-one-index';

export * from './new-campaign-settings';
export * from './new-ad-object-optimization';
export * from './new-ad-optimization';

/**
 * ff.adoption_rate_table:start
 */
export * from './adoption-rate-by-actions';
export * from './adoption-rate-by-users';
export * from './user-cohort';
/**
 * ff.adoption_rate_table:end
 */

/**
 * ff.ui_page_keyword_analysis:start
 */
export * from './keywork-analysis';
/**
 * ff.ui_page_keyword_analysis:end
 */
export * from './sos-config';

/**
 * ff.mass_operation_management:start
 */
export * from './mass-operation-management';
/**
 * ff.mass_operation_management:end
 */

export * from './ads-ops-ad-object';
export * from './ads-ops-ad-placement';

export const DEFAULT_CONFIG = {
  title: 'Table title',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {},
  mapping: {},
};
