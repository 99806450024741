import * as React from 'react';
import clsx from 'clsx';
import { get, isEqual, cloneDeep } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import {
  makeStyles,
  Box,
  Button,
  Popover,
  FormControl,
  TextField,
  InputAdornment,
  IconButton,
  List,
  Divider,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import { searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import Tooltip from '@ep/insight-ui/elements/tooltip';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';

import MenuItem from './menu-item';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { checkEpsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';
import { NodeEditContext } from '@eip/next/lib/main';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { useLocation, useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  container: {},
  popover: {
    minWidth: '247px',
  },
  dropdownBtn: (props) => ({
    background: props.isDarkMode ? '#253645' : '#F6F7F8',
    borderRadius: '4px',
    height: '32px',
    paddingRight: '6px',
    '& .eip1-MuiButton-label': {
      fontWeight: 'bold',
      display: 'flex',
      alignItems: 'center',
      columnGap: '6px',
    },
    ...(props.isDarkMode
      ? {
          '& .iconDropDown': {
            color: '#FFFFFF',
          },
          '& .viewLabel': {
            color: '#FFFFFF',
          },
          '&:hover': {
            '& .iconDropDown': {
              color: '#253746',
            },
            '& .viewLabel': {
              color: '#253746',
            },
          },
        }
      : {}),
  }),
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
    '& input::placeholder': {
      textTransform: 'unset',
    },
  },
  textSearch: {
    '& input': {
      height: 30,
      width: '100%',
      paddingRight: '22px',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  noResults: {
    display: 'flex',
    justifyContent: 'center',
    color: '#8C98A4',
    padding: '8px 0',
  },
  divider: {
    margin: '8px 0',
  },
  newViewBtn: {
    width: '100%',
    height: '32px',
    '& .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
      fontWeight: 'normal',
      justifyContent: 'flex-start',
    },
  },
  listViews: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    maxHeight: '232px',
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  tooltip: {
    maxWidth: '245px',
    marginLeft: '15px',
    padding: '0px',
    '& p': {
      fontSize: '11px',
      marginBottom: '0px',
      lineHeight: '15px',
      padding: '8px',
    },
  },
  threeDots: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  searchField: {
    position: 'relative',
  },
  closeBtn: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translateY(-50%)',
  },
  viewLabel: {
    whiteSpace: 'nowrap',
  },
}));

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const emptySystemConfig = {};

const DropdownView = () => {
  const backboneContext = React.useContext(TableBackboneContext);
  const darkmode = React.useContext(DarkModeContext);
  const allowCustomView = backboneContext.getConfig('allowCustomView', true);
  const isDarkMode = darkmode.isDarkMode;
  const classes = useStyles({ isDarkMode });
  const { isEditMode } = React.useContext(NodeEditContext);
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q, v } = queryParams;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setViews(backboneContext.getConfig('views'));
    setSelectedView(backboneContext.getConfig('view'));
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Update backbone config
  React.useEffect(() => {
    if (
      !anchorEl &&
      (!isEqual(views, backboneContext.getConfig('views')) || !isEqual(selectedView, backboneContext.getConfig('view')))
    ) {
      backboneContext.updateConfig({
        views,
        view: selectedView,
      });
    }
  }, [anchorEl]);

  const systemConfig = backboneContext.addon('get.system.config', () => {
    return emptySystemConfig;
  })();

  const systemViews = React.useMemo(() => get(systemConfig, ['views'], []), [systemConfig]);

  const [searchValue, setSearchValue] = React.useState<string>('');

  const inputRef = React.useRef(null);

  const [views, setViews] = React.useState(backboneContext.getConfig('views'));
  const [selectedView, setSelectedView] = React.useState(backboneContext.getConfig('view'));

  const history = useHistory();

  React.useEffect(() => {
    if (v != selectedView.id) {
      const foundView = views.find((view) => view.id == v);
      if (foundView) {
        selectView(foundView);
        backboneContext.updateConfig({
          view: foundView,
        });
      }
    }
  }, [v, selectedView, views]);

  React.useEffect(() => {
    const bbViews = backboneContext.getConfig('views').map((el) => {
      return {
        ...el,
        name: el.id === selectedView.id ? selectedView.name : el.name,
        ...(Object.keys(el).includes('isHidden')
          ? { isHidden: el.id === selectedView.id ? selectedView.isHidden : !!el.isHidden }
          : {}),
        ...(Object.keys(el).includes('isLock')
          ? { isLock: el.id === selectedView.id ? selectedView.isLock : !!el.isLock }
          : {}),
      };
    });
    setViews(bbViews);
  }, [selectedView]);

  const addNewView = (value?: string) => {
    const newView = backboneContext.createUserView(value || '');
    if (newView) {
      setViews([...views, { ...newView, isNew: true, systemView: isEditMode ? true : false }]);
    }
  };

  const handleDuplicateView = (view) => {
    const newView = backboneContext.createUserView('');
    if (newView) {
      setViews([
        ...views,
        {
          ...view,
          name: '',
          id: newView.id,
          isNew: true,
          systemView: isEditMode ? true : false,
          isHidden: isEditMode ? view.isHidden : false,
          isLock: isEditMode ? view.isLock : false,
        },
      ]);
    }
  };

  const selectView = (view) => {
    if (v && v != view?.id) {
      history.replace({
        search: `?q=${q}&v=${view.id}`,
      });
    }
    setSelectedView(view);
    backboneContext.addon('config.view', () => undefined)(view);
    handleClose();
  };

  React.useEffect(() => {
    if (q && q?.split(',').length == 1 && !v) {
      history.replace({
        search: `?q=${q}&v=${selectedView.id}`,
      });
    }
  }, [selectedView, q, v]);

  const deleteView = (deletedView) => {
    setViews(views.filter((view) => view.id !== deletedView.id));
    if (selectedView.id === deletedView.id) {
      setSelectedView(views[0]);
    }
  };

  const updateView = (newView) => {
    setViews(
      views.map((view) => {
        if (view.id === newView.id) return newView;
        return view;
      }),
    );
    if (selectedView.id === newView.id) {
      setSelectedView(newView);
    }
  };

  const resetView = (view) => {
    backboneContext.addon('system.reset.view', () => undefined)(view);
    handleClose();
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const viewsClone = cloneDeep(views);
    [viewsClone[dragIndex], viewsClone[hoverIndex]] = [viewsClone[hoverIndex], viewsClone[dragIndex]];
    setViews(viewsClone);
  };

  const viewsRender = React.useMemo(() => {
    return views
      .filter(
        (view) =>
          (isEditMode || !view.isHidden) &&
          (!inputRef ||
            String(view.name || '')
              .toLowerCase()
              .includes(searchValue.toLowerCase())),
      )
      .map((view, index) => {
        return (
          // Do not allow user to move item when searchValue is not empty
          <ConditionalWrap
            key={view.id}
            condition={!searchValue}
            wrap={(children) => (
              <DragCard index={index} moveCard={moveCard}>
                {children}
              </DragCard>
            )}
          >
            <MenuItem
              view={view}
              allowCustomView={allowCustomView}
              selectView={selectView}
              selectedView={selectedView}
              handleDuplicateView={handleDuplicateView}
              updateView={updateView}
              deleteView={deleteView}
              resetView={resetView}
              systemViews={systemViews}
              isEditMode={isEditMode}
            />
          </ConditionalWrap>
        );
      });
  }, [views, searchValue, selectedView]);

  return (
    <MonitorContainer mId={'custom-view'} mLabel={'custom view'} component={Box} className={classes.container}>
      <Button className={classes.dropdownBtn} onClick={handleClick}>
        <Icon className={isDarkMode ? 'iconDropDown' : ''} type={'addproperties'} />
        <span className={`${classes.viewLabel} ${isDarkMode ? 'viewLabel' : ''}`}>
          {selectedView.name || 'Untitled'}
        </span>
        <Icon className={isDarkMode ? 'iconDropDown' : ''} type={'chevron'} />
      </Button>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popover}>
          <FormControl fullWidth className={classes.searchField}>
            <TextField
              style={{
                position: 'sticky',
                top: '0px',
                zIndex: 1,
                backgroundColor: '#fff',
                paddingTop: '8px',
                paddingBottom: '8px',
              }}
              className={`${clsx(classes.textSearch, classes.textStyle, 'custom_view search_box')}`}
              placeholder={'Find a view'}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              variant={'outlined'}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search style={{ width: 12, height: 14 }} />
                  </InputAdornment>
                ),
                endAdornment: searchValue && (
                  <IconButton onClick={() => setSearchValue('')} className={classes.closeBtn}>
                    <Icon type={'closeCircle'} />
                  </IconButton>
                ),
                classes: { input: classes['input'] },
                fullWidth: true,
              }}
              inputRef={inputRef}
            />
          </FormControl>
          {viewsRender.length > 0 ? (
            <DndProvider context={window} backend={HTML5Backend}>
              <List className={classes.listViews}>{viewsRender}</List>
            </DndProvider>
          ) : (
            <Box className={classes.noResults}>
              <span>No results</span>
            </Box>
          )}
          {allowCustomView && (
            <>
              <Divider className={classes.divider} />
              <Tooltip
                style={{ width: '100%' }}
                mode="long"
                content={'Create a new table view that you can use to configure your table and sort/filter your data'}
                placement="right"
                className={classes.tooltip}
              >
                <Button className={classes.newViewBtn} onClick={() => addNewView('')}>
                  <span>
                    <Icon type="plus" style={{ width: '9px', height: '9px' }} />
                  </span>
                  <span>New view</span>
                </Button>
              </Tooltip>
            </>
          )}
        </Box>
      </Popover>
    </MonitorContainer>
  );
};

export default DropdownView;
