import { get } from 'lodash';
import * as React from 'react';

import { makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import { useETableOptions } from './hooks/use-etable-options';
import { INPUT_TYPE_CUSTOM_TAG } from '../../../utils/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';

const useStyles = makeStyles(() => ({
  containerLoading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  tagItemContainer: {
    borderRadius: '4px',
    width: '100%',
    cursor: 'pointer',
  },
  tagItem: {
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    padding: '6px 10px',
    '&:hover': {
      background: '#0000000a',
    },
  },
  tagIcon: {
    margin: '0 8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '6px',
  },
}));

const TagSelection = ({ configuration, field, rowData, value, onChange, handleSubmit, ...rest }: any) => {
  const classes = useStyles();
  const [options, setOptions] = React.useState([]);

  const backbone = React.useContext(TableBackboneContext);

  const { eTableOptions, isSelectionFromTag, loading } = useETableOptions({ ...rest, configuration });
  const mapping = backbone.getConfig('mapping');
  const color = get(mapping, [field, 'valueGetter', 'style.background'], '');

  React.useEffect(() => {
    if (isSelectionFromTag) {
      setOptions(eTableOptions);
    } else {
      const customTags = get(configuration, ['field_configuration'], []).find((el) => el.key === INPUT_TYPE_CUSTOM_TAG);
      if (Array.isArray(customTags?.value)) {
        setOptions(customTags.value);
      }
    }
  }, [eTableOptions, isSelectionFromTag]);

  const handleClick = (value) => {
    onChange({
      target: {
        value,
      },
    });
    handleSubmit();
  };

  const tagRender = React.useMemo(() => {
    return options.map((option) => {
      const bgColor = toValue(color, { rowData, value: option.value });
      return (
        <div
          className={classes.tagItemContainer}
          style={bgColor ? { background: bgColor } : {}}
          key={option.value}
          onClick={() => handleClick(option.value)}
        >
          <div className={classes.tagItem}>
            <div className={classes.tagIcon}>
              <Icon type={'draggable'} />
            </div>
            <span>{option.label}</span>
          </div>
        </div>
      );
    });
  }, [options]);

  if (loading && isSelectionFromTag)
    return (
      <div className={classes.containerLoading}>
        <LoadingIcon />
      </div>
    );

  return <div className={classes.container}>{tagRender}</div>;
};

export default TagSelection;
