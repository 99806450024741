import { EIP_CONSTANT } from '@eip/next/lib/main';

export const ETABLE_TEMPLATE = {
  title: 'Etable starter',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getStorefrontData',
  },
  primaryKeys: ['STOREFRONT.id', 'MARKETPLACE.marketplace_code', 'COUNTRY.country_code', 'WORKSPACE.id', 'COMPANY.id'],
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  hiddenColumns: [],
  requestHiddenField: {},
  requestIgnoreField: {
    metric: [],
  },
  mapping: {
    company: {
      title: 'Company',
      propertyType: 'dimension',
      cellFormat: 'companyFormat',
      dataType: 'string',
      filterField: 'COMPANY.name',
      valueGetter: {
        label: 'COMPANY.name',
        id: 'COMPANY.id',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'countryFormat',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'marketplaceFormat',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'storefrontFormat',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        complementId: 'STOREFRONT.id',
        subtext: 'STOREFRONT.id',
        id: 'STOREFRONT.id',
      },
    },
    workspace: {
      title: 'Workspace',
      propertyType: 'dimension',
      cellFormat: 'workspaceFormat',
      dataType: 'string',
      filterField: 'WORKSPACE.name',
      valueGetter: {
        label: 'WORKSPACE.name',
        id: 'WORKSPACE.id',
      },
    },
  },
};
