import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ThreeDots = (props: SvgIconProps) => (
  <SvgIcon {...props} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.584961 1.72266C0.584961 1.32617 0.710286 1.00033 0.960938 0.745117C1.21615 0.489909 1.55339 0.362305 1.97266 0.362305C2.38737 0.362305 2.72461 0.48763 2.98438 0.738281C3.24414 0.984375 3.37402 1.31934 3.37402 1.74316V1.99609C3.37402 2.39258 3.2487 2.71615 2.99805 2.9668C2.7474 3.21745 2.40788 3.34277 1.97949 3.34277C1.55566 3.34277 1.21615 3.21745 0.960938 2.9668C0.710286 2.71159 0.584961 2.38346 0.584961 1.98242V1.72266Z"
      fill="#253746"
    />
  </SvgIcon>
);
export default ThreeDots;
