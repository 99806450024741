import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as _ from 'lodash';
import { useTableVisible } from './hooks';
import { colors, noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { ITreeMapDataChart } from '../type';
import { TreeMap } from './treemap-template';
import { makeStyles, Menu, MenuProps, withStyles, Box } from '@material-ui/core';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state_integrate_api_sos';
import ChartSize, { TSize } from '../chart-size';
import { breakPointSize } from '../chart-size';
import { TreeChartOptions } from '@carbon/charts/interfaces';
import { AlignType } from '@ep/insight-ui/elements/dropdown-menu/dropdown';

/**
 *
 */
import { Button } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
/**
 *
 */

const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  titleChart: {
    color: '#8C98A4',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.25,
    letterSpacing: '0.00735em',
    marginBottom: '10px',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
  wrapperRes: {
    height: '100%',
  },
  totalValueLine: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '1rem',
    color: '#253746',
    margin: 0,
    '& span': {
      fontSize: '1rem',
      marginLeft: '15px',
      color: '#0BA373',
      fontWeight: 500,
    },
    '& .currency': {
      fontSize: '1rem',
      color: '#253746',
      marginLeft: '1px',
      marginRight: '5px',
    },
    '& svg': {
      marginRight: 4,
    },
    '@media (max-width:767px)': {
      marginTop: 0,
      left: 0,
    },
    '& .percent': {
      display: 'block',
      '& span': {
        margin: 0,
      },
    },
  },
  legendLine: {
    display: 'flex',
    flexDirection: 'row',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    lineHeight: 2.2,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    '@media (max-width:767px)': {
      top: 0,
    },
    '& .legendItem': {
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      padding: '5px',
      transition: 'all .3s ease',
      '& span': {
        width: '12px',
        height: '12px',
        marginRight: '8px',
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
    '& .legendItem:hover': {
      background: '#F8FAFD',
      borderRadius: '5px',
      textAlign: 'center',
    },
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
  ...(ff.replace_keyword_compact
    ? {
        buttonError: {
          display: 'flex',
          columnGap: '8px',
        },
      }
    : {}),
});

interface MenuStyledProps extends MenuProps {
  alignMenu?: AlignType;
}
const StyledMenu = withStyles({
  paper: {
    background: '#FFFFFF',
    borderRadius: '4px',
    boxShadow: `${colors.shadow.hover}`,
    minWidth: '208px',
    boxSizing: 'border-box',
    paddingTop: '0px',
  },
})(({ alignMenu, ...rest }: MenuStyledProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    disableRestoreFocus={true}
    anchorOrigin={{
      vertical: 'top',
      horizontal: alignMenu,
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: alignMenu,
    }}
    {...rest}
  />
));

type ITreeMapChart = {
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: ITreeMapDataChart[];
  optionList: TreeChartOptions;
  percentList: { [key: string]: string };
  keyChart?: string;
  stateChart: IStateChartValue;
  defaultKeyword?: string;
  keywordData?: any[];
  onKeywordChange?: any;
  showTable?: any;
  isSimplified?: boolean;
  handleReload?: any;
};
const TreeMapChart = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  percentList,
  stateChart,
  showTable,
  isSimplified = false,
  handleReload = () => undefined,
}: ITreeMapChart) => {
  const classes = useStyles();
  const divRef = React.useRef<HTMLDivElement>();
  const legendRef = React.useRef<HTMLDivElement>(null);
  const [chartSize, setchartSize] = React.useState<TSize>('large');
  const ref = React.createRef<any>();

  const [menu, setMenu] = React.useState<any[]>([]);
  const openList = React.useRef<boolean[]>([]);
  const paramsTable = React.useRef<any>();
  const update = React.useState()[1].bind(null, {});

  const resize = (isZoom = true) => {
    // Handle layout bug when hovering during chart load time
    let chart;
    if (divRef.current) {
      chart = divRef.current.querySelector('.chart-holder');
      if (chart) {
        chart.style.pointerEvents = 'none';
      }
    }

    setTimeout(() => {
      if (chart) {
        chart.style.pointerEvents = null;
      }
    }, 500);

    if (_.isEmpty(percentList)) return;

    setTimeout(() => {
      const leafs = (ff.replace_keyword_compact ? divRef.current : document).querySelectorAll(
        'g[data-name="leaf"]',
      ) as NodeListOf<SVGGElement>;
      leafs.forEach((leaf, index) => {
        const text = leaf.querySelector('text');

        if (!text) return;

        // cache default label into <g> element to get label below
        if (!leaf.dataset.value) {
          leaf.dataset.value = _.unescape(text.innerHTML);
        }

        // Add label and percent to text
        const innerHTML = `
          <tspan x="0" dy="0">${leaf.dataset.value.split('\n')[0]}</tspan>
          <tspan x="0" dy="20px">${leaf.dataset.value.split('\n')[1]}%</tspan>
        `;
        text.innerHTML = innerHTML;
        text.style.transform = `translate(10px)`;

        leafs.forEach((leaf, index) => {
          leaf.onclick = () => {
            if (!ff.replace_keyword_compact || !isSimplified) {
              showTable();
            }
            // Pass param here
            paramsTable.current = {
              title: leaf.dataset.value,
              percent: percentList[leaf.dataset.value],
            };

            update();
          };
        });
      });
    }, 500);

    if (optionList && divRef?.current) {
      if (!legendRef.current) {
        legendRef.current = divRef.current.querySelector('.layout-child.legend') as HTMLDivElement;
      }
      if (divRef.current.offsetWidth < breakPointSize.width.medium) {
        legendRef.current.style.display = 'none';
        setchartSize('small');
        return;
      }
      if (divRef.current.offsetWidth < breakPointSize.width.large) {
        legendRef.current.style.display = 'none';
        setchartSize('medium');
        return;
      }
      legendRef.current.style.display = 'flex';
      return setchartSize('large');
    }
  };

  React.useEffect(() => {
    new ResizeObserver(() => {
      resize();
    }).observe(divRef.current);

    const legendItems = document.querySelectorAll('.legend-item') as NodeListOf<HTMLElement>;
    legendItems.forEach((checkbox) => {
      checkbox.onclick = () => {
        const leafs = document.querySelectorAll('g[data-name="leaf"]') as NodeListOf<SVGGElement>;
        leafs.forEach((leaf) => {
          const rect = leaf.querySelector('rect');
          const text = leaf.querySelector('text');

          rect.style.height = ``;
          rect.style.width = ``;
          text.setAttribute('opacity', '0');
        });
        resize(false);
      };
    });
  }, [dataList]);

  React.useEffect(() => {
    if (!ref?.current) return;
    const leafs = ref.current.chartRef.querySelectorAll('g[data-name="leaf"]') as NodeListOf<SVGGElement>;

    if (!_.isEmpty(openList.current)) return;
    openList.current = Array(leafs.length).fill(false);
  }, [ref]);

  return (
    <div ref={divRef} style={{ height: '100%' }}>
      <ChartSize size={chartSize}>
        <ChartState
          stateChart={'success'}
          title={title}
          value={value}
          currency={currency}
          percent={percent}
          headerType={'analysis'}
          parentRef={divRef}
          {...(ff.replace_keyword_compact ? { isSimplified } : {})}
        >
          <>
            {stateChart === 'success' && (!isSimplified || (isSimplified && dataList && dataList.length > 0)) ? (
              <TreeMap data={dataList} options={optionList} refElement={ref} />
            ) : stateChart === 'empty' ? (
              <span>No data available.</span>
            ) : stateChart === 'error' ? (
              <Box display={'flex'} alignItems={'center'}>
                <Button variant="text" size="small" onClick={handleReload}>
                  <span className={classes.buttonError}>
                    <Icon type={'reload'} />
                    <span>Reload</span>
                  </span>
                </Button>
              </Box>
            ) : stateChart === 'loading' ? (
              <Box display={'flex'} alignItems={'center'}>
                <LoadingIcon />
                <span>
                  <strong>One moment please ...</strong>
                </span>
              </Box>
            ) : null}
            {menu}
          </>
        </ChartState>
      </ChartSize>
    </div>
  );
};

export default TreeMapChart;
