import * as React from 'react';
import { get } from 'lodash';

import { Box, makeStyles, Button, Popover, ButtonGroup } from '@material-ui/core';

import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import { useButtonAction } from './hooks/use-button-action';

const useStyles = makeStyles((theme) => ({
  buttonAction: {
    '& .eip1-MuiTypography-body2': {
      fontWeight: 500,
      whiteSpace: 'nowrap',
    },
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  singleButton: {
    height: '32px',
    whiteSpace: 'nowrap',
  },
}));

const ButtonAction = ({ backbone }: any) => {
  const classes = useStyles();

  const { ref, anchorEl, handleClose, actions, Component, propsComponent } = useButtonAction({ backbone });

  if (!actions.length) return null;

  return (
    <Box mr={2} ref={ref}>
      <ButtonGroup variant="contained" color="primary">
        <Button
          variant="contained"
          color="primary"
          onClick={get(actions, ['0', 'onClick'], () => undefined)}
          className={classes.singleButton}
        >
          {get(actions, ['0', 'title'], '')}
        </Button>
        {actions.length > 1 ? (
          <Dropdown
            alignMenu="right"
            disabledToggleStyle
            icon={'chevron'}
            sizeIcon={'12px'}
            className={classes.buttonAction}
            buttonProps={{ color: 'primary', variant: 'contained' }}
          >
            <MenuList closeAfterClick listMenu={[actions.slice(1)]} />
          </Dropdown>
        ) : null}
      </ButtonGroup>
      {Component ? (
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Component {...propsComponent} />
        </Popover>
      ) : null}
    </Box>
  );
};

export default ButtonAction;

/**
 * eip1-MuiButtonBase-root eip1-MuiButton-root eip1-jss2667 eip1-jss5247 eip1-jss2668 eip1-MuiButtonGroup-grouped eip1-MuiButtonGroup-groupedHorizontal eip1-MuiButtonGroup-groupedContained eip1-MuiButtonGroup-groupedContainedHorizontal eip1-MuiButtonGroup-groupedContainedPrimary eip1-jss5140 eip1-MuiButton-text eip1-MuiButtonGroup-grouped eip1-MuiButtonGroup-groupedHorizontal eip1-MuiButtonGroup-groupedContained eip1-MuiButtonGroup-groupedContainedHorizontal eip1-MuiButtonGroup-groupedContainedPrimary eip1-jss5140
 */
