import { useLog } from '@eip/next/lib/log';
import { FilterType } from '@eip/next/src/components/dashboard/context';
import { NodeData } from '@eip/next/src/components/dashboard/type';
import {
  buildRequestParams,
  dataQuery,
} from '@eip/next/src/util/data-query';
import { getBuiltinEntities } from '@eip/next/src/util/data-request';
import { get } from 'lodash';
import moment from 'moment';
import numeral from 'numeral';

const log = useLog('dbf:data-query:table-query');

function formatValue(value, dataType) {
  switch (dataType) {
    case 'integer':
      return parseInt(numeral(value).format('0,0'));
    case 'number':
    case 'float':
      return parseFloat(numeral(value).format('0,0[.]00'));
    case 'currency':
      return numeral(value).format('$0,0[.]00');
    case 'percent':
      return numeral(value).format('0[.]00') + '%';
    default:
      return value;
  }
}

export async function requestTableData(
  nodeData: NodeData,
  filters: FilterType,
  resolveFn,
) {
  const dataFrame = [
    {
      status: '',
      data: {
        headers: [],
        rows: [],
      },
    },
    {
      status: '',
      data: {
        headers: ['pagination'],
        rows: [
          {
            page: 1,
            itemCount: 0,
            limit: 10,
          },
        ],
      },
    },
    {
      status: '',
      data: {
        headers: ['builtinEntities'],
        rows: [],
      },
    },
  ];

  const reqParams = buildRequestParams(nodeData, filters);

  const dimension = get(nodeData.customAttributes, 'dimensionId');
  if (dimension) {
    const dimensionId = get(nodeData.customAttributes, 'dimensionId');
    const relatedDimensions = get(
      nodeData.customAttributes,
      `selection[${dimension}].relatedDimensions`,
      [],
    );
    const dimensions = [dimensionId, ...relatedDimensions];
    const metrics = get(
      nodeData.customAttributes,
      `selection[${dimension}].metrics`,
      [],
    );
    const pagination = get(nodeData.customAttributes, 'pagination', {
      page: 1,
      limit: 10,
    });
    const orders = get(nodeData.customAttributes, 'sorting', []).map(
      (i) => [i.field, i.sort],
    );

    const builtinEntities = await getBuiltinEntities();

    log('request', {
      metrics,
      dimensions: [...reqParams.dimensions, ...dimensions],
      dateRange: {
        from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      filters: reqParams.filters,
      // orders,
      pagination,
    });

    dataQuery({
      metrics,
      dimensions: [...reqParams.dimensions, ...dimensions],
      dateRange: {
        from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      },
      filters: reqParams.filters,
      // orders,
      pagination,
    }).then((res) => {
      log('response', res);
      const headers = res.data.headers
        .filter((h) => !h.includes('tool_id'))
        .map((h) => {
          const entity = builtinEntities.metrics.find(
            (m) => m.metric === h,
          );
          return entity
            ? {
                field: entity.metric,
                label: entity.label,
                dataType: entity.data_type,
              }
            : { field: h, label: h, dataType: '' };
        });

      const rows = res.data.rows.map((r) => {
        const row = {};
        headers.forEach((h, i) => {
          row[h.field] = formatValue(r[i], h.dataType);
        });
        return row;
      });
      dataFrame[0].data.headers = headers;
      dataFrame[0].data.rows = rows;
      dataFrame[1].data.rows = [
        {
          page: res.pagination.page,
          itemCount: res.pagination.item_count,
          limit: res.pagination.limit,
        },
      ];
      dataFrame[2].data.rows = [builtinEntities];

      log('resolvefn', resolveFn, dataFrame);
      resolveFn({
        dataFrame: dataFrame,
        _isAllCompleted: true,
      });
    });
  }

  return dataFrame;
}
