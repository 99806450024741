import { eipRequest } from '@eip/next/lib/main';
import { get, set, zipObject, escape } from 'lodash';

import CurrencyFormat from '@ep/insight-ui/elements/table/format/currency-format';
import { isFormulaField, toValue } from '@ep/insight-ui/system/util/excel-formula';

export function produceQueryResult(rawResult, mapping, skipRowMapping = false) {
  let foundCurrencyField;
  for (const column of Object.values(mapping)) {
    const foundItem = Object.entries(get(column, 'valueGetter', {})).find(([k, v]) => k === 'currency');
    if (foundItem) {
      foundCurrencyField = foundItem[1] !== '' ? foundItem[1] : null;
    }
  }

  let rows = get(rawResult, 'data.rows', []);
  if (!skipRowMapping) {
    rows = rows.map((row) => zipObject(get(rawResult, 'data.headers', []), row));
  }
  const mainHeaders = get(rawResult, 'data.headers', []);
  set(rawResult, 'data.originalHeaders', mainHeaders);
  const masterData = get(rawResult, 'masterData', {});

  const entityRelations = mainHeaders.filter((i) => i.indexOf('.id') > 0).map((i) => i.replace('.id', ''));
  const mapEntity = entityRelations.reduce((carry, entityType) => {
    const headers = masterData[entityType]?.headers.map((i) => `${entityType}.${i}`) || [];
    const rows = masterData[entityType]?.rows || [];
    return {
      ...carry,
      [entityType]: rows.reduce((carry, row, index) => {
        const obj = zipObject(headers, row);
        const id = obj[`${entityType}.id`];
        return { ...carry, [id]: obj };
      }, {}),
    };
  }, {});

  const enrichedRows = rows.map((i) => {
    let item: any = { ...i };
    entityRelations.forEach((entityType) => {
      item = { ...item, ...mapEntity[entityType][i[`${entityType}.id`]] };
    });

    return {
      ...item,
      currency: get(item, foundCurrencyField, 'USD'),
    };
  });

  set(rawResult, 'rows', enrichedRows);
  set(rawResult, 'headers', enrichedRows[0] ? Object.keys(enrichedRows[0]) : mainHeaders);

  return rawResult;
}

export function getTableContext(mapping) {
  const tableContext = { attributes: [], metrics: [] };
  Object.values(mapping).forEach((f: any) => {
    if (f.propertyType == 'dimension' || f.propertyType === 'attribute') {
      tableContext.attributes = tableContext.attributes.concat(
        Object.values(f.valueGetter).filter((i) => !isFormulaField(i)),
      );
    } else if (f.propertyType === 'metric') {
      tableContext.metrics = tableContext.metrics.concat(
        Object.values(f.valueGetter).filter((i) => !isFormulaField(i)),
      );
    }
  });
  return tableContext;
}

export const resetColumns = (columns, setHeaders, backbone) => {
  const mapping = backbone.getConfig('mapping', {});
  const groupPeriod = backbone.getConfig('groupPeriod', '');
  const endpoint = get(backbone.getConfig('endpoint'), 'GET_TABLE_DATA', '');
  const storefrontMetricCol = Object.keys(mapping).find(
    (el) => get(mapping, [el, 'cellFormat'], '') === 'storefrontMetricTraction',
  );
  const dateRange = backbone.getConfig('dateRange');

  if (groupPeriod && mapping[groupPeriod]) {
    const specialFilters = Object.values(backbone.getConfig('specialFilters', {})).map((el) => {
      return {
        field: el.queryField,
        operator: el.queryType,
        value: el.queryValue,
      };
    });

    const pivotFields = Object.values(get(mapping, [groupPeriod, 'valueGetter'], {})).filter((f) => !isFormulaField(f));
    const pivotGroupByField = get(
      mapping,
      [groupPeriod, 'valueGetter', 'id'],
      get(mapping, [groupPeriod, 'valueGetter', 'value']),
    );

    const params = {
      dimensions: ['storefront'],
      attributes: [].concat(pivotFields),
      table_context: getTableContext(mapping),
      metrics: [],
      pagination: {
        page: 1,
        limit: 20,
      },
      groupBy: {
        column: [].concat(pivotGroupByField),
        aggregations: pivotFields
          .filter((f) => f !== pivotGroupByField)
          .map((f) => {
            return { field: f, func: 'MAX' };
          }),
      },
      sort: null,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'created_datetime',
            operator: '>=',
            value: dateRange.dateFrom,
          },
          {
            field: 'created_datetime',
            operator: '<=',
            value: dateRange.dateTo,
          },
          ...specialFilters,
        ],
      },
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
      groupPeriod: 'all',
    };

    setHeaders(columns.filter((column) => column.field === storefrontMetricCol));
    eipRequest
      .post(endpoint, params)
      .then(async (res) => {
        const result = produceQueryResult(res, mapping);

        const idField = get(mapping, [groupPeriod, 'valueGetter', 'id']);
        const labelField = get(mapping, [groupPeriod, 'valueGetter', 'label']);

        let getLabel = (context) => context['label'];
        if (isFormulaField(labelField)) {
          getLabel = (context) => toValue(labelField, context);
        }

        const groupData = result.rows;

        const colHeaders = groupData.map((el) => {
          const context = Object.entries(get(mapping, [groupPeriod, 'valueGetter'])).reduce((carry, [k, f]) => {
            return { ...carry, [k]: el[f] };
          }, {});
          return {
            field: escape(String(el[idField])),
            name: String(getLabel(context)),
            dataType: 'string',
            action: [],
            cell: {
              format: CurrencyFormat,
              valueGetter: {
                value: escape(`value_${el[idField]}`),
              },
              staticValue: {},
              action: [],
            },
          };
        });
        await backbone.addon('pivot.set')({ values: colHeaders.map((h) => h.field), queryField: idField });
        setHeaders(columns.filter((column) => column.field === storefrontMetricCol).concat(colHeaders));
      })
      .catch((e) => {
        setHeaders(columns.filter((column) => column.field === storefrontMetricCol));
      });
  } else {
    setHeaders(columns);
  }
};
