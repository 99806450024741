import { useLog } from '@eip/next/lib/log';
import { makeStore } from '@ep/one/src/utils';
import { makeStyles } from '@material-ui/core';
import React from 'react';
// import { Responsive as ResponsiveLayoutGrid } from 'react-grid-layout';
import { getUserSettings, isSuperAdmin } from '@ep/one/aim';
import 'react-grid-layout/css/styles.css';
import { Provider, useSelector } from 'react-redux';
import 'react-resizable/css/styles.css';
import { SCREEN_COLUMNS } from '../../constant';
import { DashboardFrameEditMode } from './dashboard-grid-editor';
import { DashboardFrameViewMode as DashboardFrameViewModeNext } from './dashboard-grid-viewonly';
import { actions, initState, reducer as dashboardReducer } from './dashboard-redux';
import { DashboardBreakpoints, DashboardState, NodeData, NodeLayout } from './type';
import minimatch from 'minimatch';
import { get } from 'lodash';

const log = useLog('dbf:grid');

type DashboardScreenLayout = NodeLayout;

type DashboardResponsiveLayoutType = {
  [key in DashboardBreakpoints]: DashboardScreenLayout[];
};

type DashboardProps = {
  layouts: DashboardResponsiveLayoutType;
  nodes: NodeData[];
  layoutColumns: Dashboard['layoutColumns'];
  filterComponent?: React.Component;
};

function DashboardFrame({
  layouts,
  nodes,
  layoutColumns,
  filterComponent = null,
  isEditMode = false,
}: DashboardProps & { isEditMode: boolean }) {
  if (isEditMode) {
    return (
      <DashboardFrameEditMode
        layoutColumns={layoutColumns}
        layouts={layouts}
        nodes={nodes}
        filterComponent={filterComponent}
      />
    );
  } else {
    return <DashboardFrameViewModeNext layoutColumns={layoutColumns} layouts={layouts} nodes={nodes} />;
  }
}

export default function DashboardFull(
  props: Omit<React.ComponentProps<typeof DashboardFrame>, 'children'> & {
    historyId: string;
    isEditMode: boolean;
  },
): JSX.Element {
  const store = React.useMemo(() => {
    let nodes;
    if (props.isEditMode) {
      nodes = props.nodes;
    } else {
      const { userEmail, workspace_role_id, userId } = getUserSettings().profile;
      nodes = props.nodes.filter((i) => {
        if (isSuperAdmin()) return true;

        const isRoleValid =
          isSuperAdmin() || !workspace_role_id || (i.roles || [1, 2, 3]).includes(Number(workspace_role_id));
        if (!isRoleValid) return false;

        let allowAccess = false;
        let legacyAllowAccess = true;
        const permission = get(i, ['permission'], []);

        if (permission && permission.length > 0) {
          legacyAllowAccess = permission.includes(userEmail);
        }

        const nextPermission = get(i, ['next_permission'], []);

        const includedAccess = nextPermission.filter((i) => !String(i).startsWith('!'));
        const excludeAccess = nextPermission.filter((i) => String(i).startsWith('!'));

        allowAccess = allowAccess || includedAccess.length === 0;
        allowAccess = allowAccess || includedAccess.some((i) => minimatch(userId, i, { matchBase: true }));
        allowAccess =
          allowAccess || includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
        allowAccess =
          allowAccess &&
          (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userId, i, { matchBase: true })));

        allowAccess =
          allowAccess &&
          (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

        return legacyAllowAccess && allowAccess;
      });
    }

    return makeStore({
      storeName: 'dashboard-full',
      reducer: dashboardReducer,
      rootSaga: function* rootSaga() {
        console.info('dashboardt => no saga setup yet');
      },
      initState: {
        ...initState,
        layouts: props.layouts,
        nodes,
        // layoutColumns: props.layoutColumns,

        layoutColumns: {
          lg: SCREEN_COLUMNS.lg.default,
          md: SCREEN_COLUMNS.md.default,
          sm: SCREEN_COLUMNS.sm.default,
          xs: SCREEN_COLUMNS.xs.default,
          xxs: SCREEN_COLUMNS.xxs.default,
        },
      },
    });
  }, [props.isEditMode]);
  const currentRef = React.useRef({ isInit: false, historyId: null });

  React.useEffect(() => {
    if (currentRef.current.isInit === false || currentRef.current.historyId !== props.historyId) {
      store.dispatch(
        actions.set({
          layouts: props.layouts,
          nodes: props.nodes,
          layoutColumns: props.layoutColumns,
        }),
      );
      currentRef.current.isInit = true;
      currentRef.current.historyId = props.historyId;
    }
  }, [props.layouts, props.nodes, props.layoutColumns, props.historyId]);

  return (
    <Provider store={store}>
      <DashboardContainer {...props}></DashboardContainer>
    </Provider>
  );
}

function DashboardContainer(props) {
  const { layouts, nodes, layoutColumns } = useSelector((state: DashboardState) => {
    return {
      layouts: state.layouts,
      nodes: state.nodes,
      layoutColumns: state.layoutColumns,
    };
  });

  return <DashboardFrame {...props} layoutColumns={layoutColumns} layouts={layouts} nodes={nodes}></DashboardFrame>;
}

const useStyle = makeStyles(() => {
  return {
    dashboardBlank: {
      position: 'relative',
      minHeight: '100vh',
      '& > div': {
        position: 'relative',
        zIndex: 1,
      },
      '&:after': {
        top: 0,
        left: 0,
        zIndex: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        content: '""',
        fontSize: '5em',
        color: '#eee',
      },
    },
    banner: {
      position: 'fixed',
      top: 56,
      zIndex: 1300,
    },
  };
});
