import { EIP_CONSTANT } from '@eip/next/lib/main';

export const ADOPTION_RATE_BY_USERS_CONFIG = {
  tableType: 'adoptionRateByUsers',
  title: 'Adoption rate by users',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getAdoptionRateData',
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  primaryKeys: [],
  mapping: {
    feature: {
      title: 'Feature name',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'FEATURE.name',
      valueGetter: {
        label: 'FEATURE.name',
        id: 'FEATURE.id',
      },
    },
    action: {
      title: 'Action name',
      propertyType: 'dimension',
      cellFormat: 'textWithTooltip',
      dataType: 'string',
      filterField: 'ACTION.name',
      valueGetter: {
        label: 'ACTION.name',
        id: 'ACTION.id',
        tooltip: 'ACTION.tooltip',
      },
    },
    line: {
      title: 'Last 8 weeks',
      propertyType: 'metric',
      cellFormat: 'traction',
      dataType: 'string',
      valueGetter: {
        data: 'traction',
      },
    },
  },
};
