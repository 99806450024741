import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Format = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.58205 4L7.29536 6.28668C7.20177 6.37951 7.12748 6.48995 7.07679 6.61164C7.02609 6.73332 6.99999 6.86384 6.99999 6.99566C6.99999 7.12748 7.02609 7.25799 7.07679 7.37968C7.12748 7.50136 7.20177 7.6118 7.29536 7.70463C7.38819 7.79822 7.49863 7.87251 7.62031 7.9232C7.742 7.9739 7.87251 8 8.00433 8C8.13615 8 8.26667 7.9739 8.38835 7.9232C8.51004 7.87251 8.62048 7.79822 8.71331 7.70463L11.709 4.70897C11.8026 4.61614 11.8768 4.5057 11.9275 4.38402C11.9782 4.26234 12.0043 4.13182 12.0043 4C12.0043 3.86818 11.9782 3.73766 11.9275 3.61598C11.8768 3.4943 11.8026 3.38386 11.709 3.29103L8.71331 0.29537C8.62048 0.201777 8.51004 0.127491 8.38835 0.0767959C8.26667 0.0261007 8.13616 1.15242e-08 8.00433 0C7.87251 -1.15241e-08 7.742 0.0261006 7.62031 0.0767958C7.49863 0.127491 7.38819 0.201777 7.29536 0.29537C7.20177 0.388198 7.12748 0.498639 7.07679 0.620322C7.02609 0.742005 6.99999 0.872522 6.99999 1.00434C6.99999 1.13616 7.02609 1.26668 7.07679 1.38836C7.12748 1.51005 7.20177 1.62049 7.29536 1.71331L9.58205 4Z" />
    <path d="M2.42229 4L4.70897 1.71332C4.80257 1.62049 4.87685 1.51005 4.92755 1.38836C4.97824 1.26668 5.00434 1.13616 5.00434 1.00434C5.00434 0.872522 4.97824 0.742005 4.92755 0.620323C4.87685 0.49864 4.80257 0.388199 4.70897 0.29537C4.61614 0.201778 4.5057 0.127491 4.38402 0.0767961C4.26234 0.0261009 4.13182 2.52812e-07 4 2.52812e-07C3.86818 2.52812e-07 3.73766 0.0261009 3.61598 0.0767961C3.4943 0.127491 3.38386 0.201778 3.29103 0.29537L0.29537 3.29103C0.201777 3.38386 0.127491 3.4943 0.0767958 3.61598C0.0261006 3.73766 0 3.86818 0 4C0 4.13182 0.0261006 4.26234 0.0767958 4.38402C0.127491 4.5057 0.201777 4.61614 0.29537 4.70897L3.29103 7.70463C3.38386 7.79822 3.4943 7.87251 3.61598 7.9232C3.73766 7.9739 3.86818 8 4 8C4.13182 8 4.26234 7.9739 4.38402 7.9232C4.5057 7.87251 4.61614 7.79822 4.70897 7.70463C4.80257 7.6118 4.87685 7.50136 4.92755 7.37968C4.97824 7.258 5.00434 7.12748 5.00434 6.99566C5.00434 6.86384 4.97824 6.73332 4.92755 6.61164C4.87685 6.48995 4.80257 6.37951 4.70897 6.28669L2.42229 4Z" />
  </SvgIcon>
);

export default Format;
