import { API_URL } from '@ep/one/global';
import { once } from 'lodash';
import { request } from '@ep/one/src/utils';
import moment from 'moment';
import { ONE_EP } from '@ep/one/endpoint';

let cachedMetrics;

let queryAllMetrics = once((shopIds) => {
  return request
    .get(API_URL + '/api/v1/dashboard/metrics', {
      from: moment().subtract(7, 'day').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      shop_eids: shopIds,
      features: ['M_TOK_PA', 'M_LZD_SS', 'M_SHP_KB'],
      currency: 'USD',
    })
    .then((res) => {
      cachedMetrics = res.data.map((i) => ({
        id: i.code,
        label: i.title,
        unit: i.unit,
      }));
      return cachedMetrics;
    });
});

async function getAllMetrics(shopIds) {
  if (cachedMetrics) {
    return Promise.resolve(cachedMetrics);
  }

  return queryAllMetrics(shopIds);
}

let cachedAllShops = null;
const queryAllShops = once(() => {
  console.info('doing query shops');
  return request.get(ONE_EP.GET_SHOPS()).then((res) => {
    const allShops = res.data;
    cachedAllShops = allShops;
    return cachedAllShops;
  });
});

async function getAllShops() {
  if (cachedAllShops) return Promise.resolve(cachedAllShops);
  return queryAllShops();
}

export { getAllMetrics, getAllShops };
