import * as React from 'react';
import clsx from 'clsx';

import { Box, makeStyles, Popover, Button } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import PropertiesMenu from '@ep/insight-ui/elements/next-elements/visualization/dropdown-properties';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { DarkModeContext } from '../../epsilo-chart/chart-container';

const useStyles = makeStyles(() => ({
  popover: {
    '& .eip1-MuiPopover-paper': {
      background: ' #FFFFFF',
      boxShadow: ' 0px 6px 12px rgb(140 152 164 / 25%)',
      padding: 0,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#babac038',
        marginLeft: 20,
      },
      '&::-webkit-scrollbar-track:hover': {
        backgroundColor: '#babac038',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '5px solid #F8FAFD',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4',
      },
      '&::-webkit-scrollbar-button ': { display: 'none' },
    },
  },
  container: {},
  buttonProperty: (props) => ({
    padding: '6px 12px 7px 8px',
    '& .eip1-MuiButton-label': {
      lineHeight: '17px',
      fontWeight: 400,
    },
    ...(props.isDarkMode
      ? {
          backgroundColor: '#253645',
          color: '#FFFFFF',
          '&:hover': {
            backgroundColor: '#FFFFFF',
            color: '#253645',
          },
        }
      : {}),
  }),
}));

const externalPropertyLabel = {
  metric: 'Metrics',
  dimension: 'Dimensions',
  attribute: 'Attributes',
  pivot_metric: 'Pivot',
};

const SpecialButtonProperties = ({ containerClass }: { containerClass?: string }) => {
  const backbone = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const darkmode = React.useContext(DarkModeContext);
  const isDarkMode = darkmode.isDarkMode;
  const externalProperties = backbone.getConfig('system.externalProperties') || [];

  const specialPropertyFieldsRender = externalProperties.map((el) => {
    return (
      <Box key={el}>
        <ButtonProperty property={el} containerClass={containerClass} isDarkMode={isDarkMode} />
      </Box>
    );
  });
  return <>{specialPropertyFieldsRender}</>;
};

interface ButtonPropertyProps {
  property: string;
  containerClass?: string;
  isDarkMode?: boolean;
}

const ButtonProperty = ({ property, containerClass, isDarkMode = false }: ButtonPropertyProps) => {
  const classes = useStyles({ isDarkMode });
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box>
        <Button
          className={clsx(containerClass, classes.buttonProperty)}
          onClick={handleClick}
          endIcon={<Icon type="chevron" size="8px" colorIcon="#3F4F5C" />}
          variant="outlined"
        >
          {externalPropertyLabel[property]}
        </Button>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <PropertiesMenu handleClose={handleClose} properties={[property]} />
      </Popover>
    </Box>
  );
};

export default SpecialButtonProperties;
