import Icon from '@ep/insight-ui/icons/Icon';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, FormControl, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FieldSelectedValue, FilterOperatorType } from '.';
import Dropdown, { ChildrenDropdownProps } from '../../../dropdown-menu/dropdown';
import CalendarSingle from '../../../form-control/calendar/calendar-single';
import SelectForm from '../../../form-control/select-form';
import SelectFormGroup from '../../../form-control/select-form/select-form-group';
import SelectFormSearch, { SelectFormProp } from '../../../form-control/select-form/select-form-search-inside';
import TextForm from '../../../form-control/text-form';
import MenuList from '../../../list-control/menu-list';
import { DropdownMenuDataType } from '../../../list-control/menu-list/menu-list';
import { OptionSelectType } from '../../../list-control/type';
import Typography from '../../../text-style/Typography';
import { IFilterItem, TypeFilter, TypeGroupFilter } from './filter-form-group';

/**
 * ff.ag_grid_27:start
 */
import { ContainerResponsiveContext, NodeEditContext } from '@eip/next/lib/main';
/**
 * ff.ag_grid_27:end
 */

/**
 * ff.correct_filter_display:start
 */
import { Tooltip, withStyles } from '@material-ui/core';
import FilterSelection from '@ep/insight-ui/elements/table/table-actions/filter-selection';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { getQueryParams } from '@ep/insight-ui/system/backbone/data-source/common';

interface MenuActionProps extends ChildrenDropdownProps {
  index: number;
  isStaticGroup?: boolean;
}

/** Style */
const useStyles = makeStyles(() => ({
  groupRule: {
    gap: '8px',
    display: 'flex',
  },
  iconMore: {
    '& svg': {
      width: '3px',
    },
  },
  groupSub: {
    flexGrow: 3,
    padding: '5px 10px',
    background: '#F8FAFD',
    border: '1px solid #C2C7CB',
    boxSizing: 'border-box',
    borderRadius: ' 4px',
  },
  formGroup: {
    marginTop: '8px',
    gap: '8px',
    flexWrap: 'inherit',
    '& > div:nth-child(2)': {
      flexGrow: 1,
    },
  },
  selectField: {
    '& .eip1-MuiAutocomplete-inputRoot': {
      '& .eip1-MuiAutocomplete-input': {
        paddingLeft: '0px !important',
      },
      padding: '5px 24px 5px 8px',
    },
    '&.queryField .eip1-MuiSelect-outlined': {
      paddingRight: '15%',
    },
    '& .select_value': {
      fontWeight: 400,
    },
    '& .eip1-MuiSelect-selectMenu': {
      // width: '150px',
      paddingRight: '30px !important',
      overflow: 'unset',
      textOverflow: 'unset,',
    },
  },
  operator: {
    display: 'flex',
    width: '64px',
    height: '32px',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: '#253746',
  },
  listAction: {
    padding: '8px',
  },
  inputValue: {
    flex: 1,
  },
  buttonAddBottom: {
    marginTop: '8px',
  },
  dropdownMenu: {
    display: 'flex',
    alignItems: ' center',
    '& .eip1-MuiTypography-root': {
      fontWeight: 'bold',
    },
    '& p': {
      marginRight: '12px',
      marginLeft: '8px',
    },
    color: `${colors.text.default}`,
    backgroundColor: 'transparent',
    '&.eip1-MuiButton-active': {
      backgroundColor: `${colors.action.primaryDisabled}`,
      color: `${colors.text.default}`,
    },
  },
  paper: {
    padding: '8px',
  },
  selectSearchPaper: {
    '& .eip1-MuiFormControl-root': {
      width: '100%',
      '& .eip1-MuiFormControl-root': {
        width: '100%',
      },
    },
  },
  buttonFilter: {
    flex: 1,
    '& button': {
      width: '100%',
      maxWidth: '100%',
    },
  },
  selectionContainerClass: {
    padding: 0,
  },
  lockIcon: {
    display: 'flex',
    justifyContent: 'center',
    width: '32px',
  },
}));

const booleanOperators: OptionSelectType[][] = [
  [
    {
      label: 'is',
      value: '=',
    },
    {
      label: 'is not',
      value: '!=',
    },
  ],
];

const groupOperator: OptionSelectType[] = [
  {
    label: 'And',
    value: 'and',
    note: 'All filter must match.',
  },
  {
    label: 'Or',
    value: 'or',
    note: 'At least one filter must match.',
  },
];

type LegacyFilterGroupProps = {
  options: Array<FieldSelectedValue>;
  getDefaultRule: () => IFilterItem;
  getFieldOperators: (dataType: string | number, field?: any) => OptionSelectType[][];
  operators?: FilterOperatorType;
  tmpFilterModel: IFilterItem[];
  setTmpFilterModel: (tmpFilterModel: IFilterItem[]) => void;
  enableFillerGroup?: boolean;
  level: number;
  isSubForm?: boolean;
  getOptionAvailable?: (field: string) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  isStaticGroup?: boolean;
  filterData: IFilterItem[];
  disabled?: boolean;
  checkingValidField?: boolean;
  setCheckingValidField?: any;
  requiredFilterFields?: string[];
};
const LegacyFilterGroup = ({
  enableFillerGroup = false,
  isSubForm = false,
  getDefaultRule,
  getFieldOperators,
  setTmpFilterModel,
  tmpFilterModel,
  options,
  level,
  operators,
  getOptionAvailable = () => undefined,
  isStaticGroup = false,
  filterData,
  disabled,
  checkingValidField,
  setCheckingValidField,
  requiredFilterFields,
}: LegacyFilterGroupProps) => {
  const classes = useStyles();
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const { isEditMode } = React.useContext(NodeEditContext);
  const isMobileView = containerClass === 'eres--small';
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const mGroupOperator = React.useMemo(() => {
    if (level > 0) {
      return groupOperator;
    } else {
      return groupOperator.map((i) => ({ ...i, disabled: i.value === 'or' }));
    }
  }, [level]);

  const handleChangeRule = (
    field: 'field' | 'queryType' | 'queryValue',
    value: string | OptionSelectType,
    index: number,
  ) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));

    if (index !== -1) {
      if (field === 'queryValue' && typeof value == 'string') {
        if (ff.filter_metric_empty) {
          if (tmpModel[index][field] === null) {
            tmpModel[index][field] = { value: '' };
          }
        }
        tmpModel[index][field].value = value;
      } else if (field === 'field') {
        tmpModel[index][field] = value;
        const queryType = value.isAdditionalFilter
          ? _.get(field, ['operators', 0, 0], null)
          : _.get(getFieldOperators((value as OptionSelectType).dataType), [0, 0], null);
        if (queryType) {
          tmpModel[index]['queryType'] = queryType;
        }

        if (typeof value !== 'string' && value.value === 'campaign_timeline') {
          tmpModel[index]['queryValue'].value = moment(new Date()).format('YYYY/MM/DD');
        } else {
          const queryQuery = _.get(tmpModel, [index, 'queryValue']) || {};
          tmpModel[index]['queryValue'] = { ...queryQuery, value: null };
        }
      } else {
        tmpModel[index][field] = value;
        _.set(tmpModel[index], ['queryValue', 'value'], '');
        // const selectedOperator:string[] =_.get(tmpModel,[index,'field','selectedOperators'],[]);
        // const queryType:string = _.get(tmpModel,[index,'queryType','value'],'');
        // if(selectedOperator.includes(queryType))
      }
      setTmpFilterModel(tmpModel);
    }
  };
  const handleChangeLogicalOperator = (value: string) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const logicalOperatorValue = value.toLocaleLowerCase() == 'and' ? 'and' : 'or';
    tmpModel.map((item, index) => {
      if (index !== 0) {
        item.logicalOperator = logicalOperatorValue;
      }
    });
    setTmpFilterModel(tmpModel);
  };
  const handleDeleteRule = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      tmpModel.splice(index, 1);
    }
    setTmpFilterModel(tmpModel);
  };

  const handleDuplicateRule = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      tmpModel.splice(index, 0, {
        ...tmpModel[index],
        isLocked: false,
        static: false,
        isDisabled: false,
      });
    }
    setTmpFilterModel(tmpModel);
  };

  const handleTurnIntoGroup = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      let logicalOperator = '';
      if (tmpModel.length > 1) {
        logicalOperator = tmpModel[1].logicalOperator;
      }
      const itemCloned: IFilterItem = tmpModel[index];
      const groupDefault: IFilterItem = {
        type: 'groupFilter',
        logicalOperator: logicalOperator,
        subFilters: [itemCloned],
      };
      tmpModel.splice(index, 1);
      tmpModel.splice(index, 0, groupDefault);
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeStatus = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      tmpModel[index].isDisabled = !tmpModel[index].isDisabled;
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeStatic = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      tmpModel[index].static = !tmpModel[index].static;
      setTmpFilterModel(tmpModel);
    }
  };

  const handleChangeLocked = (index: number) => () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index]) {
      tmpModel[index].isLocked = !tmpModel[index].isLocked;
      setTmpFilterModel(tmpModel);
    }
  };

  const MenuAction: React.FC<MenuActionProps> = ({ index, isStaticGroup = false, ...rest }: MenuActionProps) => {
    if (isStaticGroup)
      return (
        <MenuList
          listMenu={[
            [
              {
                title: 'Duplicate',
                icon: 'duplicatePlus',
                iconSize: '16px',
                onClick: handleDuplicateRule(index),
              },
            ],
          ]}
          closeMenuCallBack={rest.onClose}
        />
      );
    return (
      <MenuList
        listMenu={[
          [
            ...(rest.isLocked && !isEditMode
              ? []
              : [
                  {
                    title: 'Remove',
                    icon: 'remove',
                    onClick: handleDeleteRule(index),
                  },
                ]),
            {
              title: 'Duplicate',
              icon: 'duplicatePlus',
              iconSize: '16px',
              onClick: handleDuplicateRule(index),
            },
            {
              title: 'Turn into group',
              icon: 'turnIntoGroup',
              onClick: handleTurnIntoGroup(index),
            },
            {
              title: rest.isDisabled ? 'Enable' : 'Disable',
              icon: 'editStatus',
              onClick: handleChangeStatus(index),
            },
            ...(isEditMode
              ? [
                  {
                    title: `Mark filter as ${rest.isStatic ? 'default' : 'static'}`,
                    icon: rest.isStatic ? 'rmx/lock-unlock-line-icon/#0000008a' : 'rmx/lock-line-icon/#0000008a',
                    onClick: handleChangeStatic(index),
                  },
                  {
                    title: `${rest.isLocked ? 'Unlock' : 'Lock'} filter`,
                    icon: rest.isLocked ? 'rmx/lock-unlock-line-icon/#0000008a' : 'rmx/lock-line-icon/#0000008a',
                    onClick: handleChangeLocked(index),
                  },
                ]
              : []),
          ],
        ]}
        closeMenuCallBack={rest.onClose}
      />
    );
  };

  const handleSetGroup = (index: number, childFilters: IFilterItem[]) => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    if (tmpModel[index] && tmpModel[index].type == 'groupFilter') {
      if (childFilters.length > 0) {
        (tmpModel[index] as TypeGroupFilter).subFilters = childFilters;
      } else {
        tmpModel.splice(index, 1);
      }
      setTmpFilterModel(tmpModel);
    }
  };
  const getFilterItem = (arr: IFilterItem[]) => {
    const isStaticGroup = arr[0].isStaticGroup;
    if (arr.length > 0) {
      const simpleFilter = arr.filter((item) => item.type == 'filter' && !item.static);
      if (simpleFilter.length > 0) {
        const last = _.last(simpleFilter);
        return { ...last };
      }
    }
    return getDefaultRule(isStaticGroup);
  };

  const handleAddGroup = () => {
    const tmpModel: IFilterItem[] = JSON.parse(JSON.stringify(tmpFilterModel));
    const isStaticGroup = tmpModel[0].isStaticGroup;
    const defaultFilterItem = getFilterItem(tmpModel);
    let logicalOperator = 'and';
    if (tmpModel.length > 1) {
      logicalOperator = tmpModel[1].logicalOperator;
    }
    const groupDefault: IFilterItem = {
      type: 'groupFilter',
      logicalOperator: logicalOperator,
      subFilters: [defaultFilterItem].map((el) => ({
        ...el,
        isDisabled: false,
        isLocked: false,
      })),
      isStaticGroup: isStaticGroup ? isStaticGroup : false,
    };
    tmpModel.push(groupDefault);
    setTmpFilterModel(tmpModel);
  };

  const handleAddRule = () => {
    const tmpModel: Array<IFilterItem> = JSON.parse(JSON.stringify(tmpFilterModel));
    const defaultFilterItem = getFilterItem(tmpModel);
    const isStaticGroup = tmpModel[0].isStaticGroup;
    if (tmpModel.length > 1) {
      defaultFilterItem.logicalOperator = tmpModel[1].logicalOperator;
    }
    tmpModel.push({
      ...defaultFilterItem,
      static: false,
      isStaticGroup: isStaticGroup ? isStaticGroup : false,
      isDisabled: false,
      isLocked: false,
    });
    setTmpFilterModel(tmpModel);
  };
  const dataMenu: Array<Array<DropdownMenuDataType>> = [
    level != 2
      ? [
          {
            title: 'Add a filter',
            icon: 'plus',
            onClick: handleAddRule,
          },
          {
            title: 'Add a filter group',
            icon: 'addGroup',
            iconSize: '16px',
            onClick: handleAddGroup,
          },
        ]
      : [
          {
            title: 'Add a filter',
            icon: 'plus',
            onClick: handleAddRule,
          },
        ],
  ];
  const getValueContentWithRule = (rule: IFilterItem, indexLevel: number) => {
    const queryValue = _.get(rule, 'queryValue.value', '');
    // is_empty is_not_empty
    const operatorValue = _.get(rule, 'queryType.value', '');
    const isEmptyOperator = operatorValue == 'is_empty' || operatorValue == 'is_not_empty';

    const fieldValue = _.get(rule, 'field.value', '');
    const dataType = _.get(rule, 'field.dataType', '');
    if (dataType == 'date' || dataType == 'datetime')
      return (
        <CalendarSingle
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked}
          placeholder={'Date'}
          style={{
            width: '224px',
          }}
          value={queryValue}
          onChangeValue={(val) => handleChangeRule('queryValue', val, indexLevel)}
        />
      );

    const useSelected = (_.get(rule, ['field', 'selectedOperators'], []) as Array<string>).includes(
      _.get(rule, ['queryType', 'value'], ''),
    );
    if (useSelected)
      return (
        <SelectedValue
          style={{
            minWidth: '224px',
            width: '100%',
            backgroundColor: '#fff',
          }}
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked}
          defaultValue={queryValue}
          placeholderInput={'Search for a property'}
          onChangeValue={(val) => {
            handleChangeRule('queryValue', val.value, indexLevel);
            setCheckingValidField(false);
          }}
          field={fieldValue}
          getOptionAvailable={getOptionAvailable}
          className={classes.inputValue}
          placeholder={'Select an option'}
          filterData={filterData}
          checkingValidField={checkingValidField}
          requiredFilterFields={requiredFilterFields}
        />
      );
    const queryUnit = _.get(rule, 'queryValue.unit', '');

    return (
      <FormControl
        style={{ flex: 1, minWidth: '224px', width: '100%' }}
        variant="outlined"
        size="small"
        className={classes.inputValue}
        error={checkingValidField && requiredFilterFields.includes(fieldValue) && !queryValue}
      >
        <TextForm
          noShadow={true}
          fullWidthInput
          disabled={isEmptyOperator || rule.isDisabled || disabled || rule.isLocked}
          style={{ backgroundColor: '#fff' }}
          placeholder={'Content'}
          value={ff.filter_metric_empty ? (queryValue === null ? '' : queryValue) : queryValue}
          setValue={(value) => {
            handleChangeRule('queryValue', value, indexLevel);
            setCheckingValidField(false);
          }}
          tmpFilterModel={tmpFilterModel}
          positionUnit="start"
          unit={queryUnit}
          endAdornment={
            ff.bugfix_0_number_is_displayed_in_any_field ? (
              queryValue ? (
                <IconButton
                  style={{ padding: 0, opacity: 1 }}
                  onClick={() => handleChangeRule('queryValue', '', indexLevel)}
                >
                  <Icon type={'closeCircle'} />
                </IconButton>
              ) : (
                <IconButton
                  style={{ padding: 0, opacity: 0 }}
                  onClick={() => handleChangeRule('queryValue', '', indexLevel)}
                >
                  <Icon type={'closeCircle'} />
                </IconButton>
              )
            ) : (
              queryValue && (
                <IconButton style={{ padding: 0 }} onClick={() => handleChangeRule('queryValue', '', indexLevel)}>
                  <Icon type={'closeCircle'} />
                </IconButton>
              )
            )
          }
        />
      </FormControl>
    );
  };

  return (
    <React.Fragment>
      {tmpFilterModel.map((rule: TypeFilter | TypeGroupFilter, idxLevel1: number) => {
        const isStatic = _.get(rule, 'static', false);
        const isLocked = _.get(rule, 'isLocked', false);
        if (isStatic) {
          options = options.filter((val) => val.value !== 'marketplace');
        }
        return (
          <div key={idxLevel1}>
            {
              <Grid
                container
                direction="row"
                alignItems={rule.type == 'groupFilter' ? 'flex-start' : 'center'}
                style={{ display: 'flex', flexWrap: isMobileView ? 'wrap' : 'nowrap' }}
                className={classes.formGroup}
              >
                {/* logical Operator */}
                <Grid
                  item
                  className={classes.operator}
                  style={{ width: '100%', maxWidth: isSubForm ? '60px' : '64px' }}
                >
                  {idxLevel1 === 0 ? (
                    <Typography variant="body1" style={{ textAlign: isSubForm ? 'right' : 'left', fontWeight: 400 }}>
                      Where
                    </Typography>
                  ) : idxLevel1 === 1 && !isStatic ? (
                    rule.isStaticGroup ? (
                      <Typography variant="body1" style={{ textAlign: isSubForm ? 'right' : 'left', fontWeight: 400 }}>
                        And
                      </Typography>
                    ) : (
                      <SelectForm
                        options={mGroupOperator}
                        style={{
                          width: '100%',
                          maxWidth: isSubForm ? '60px' : '64px',
                          backgroundColor: '#fff',
                        }}
                        alignMenu="left"
                        value={rule.logicalOperator}
                        placeholder="And"
                        onChange={(e) => handleChangeLogicalOperator(e.target.value)}
                        className={classes.selectField}
                        renderOption={(item) => (
                          <div>
                            <Typography variant="body1">{item.label}</Typography>
                            <Typography variant="body5">{item.note}</Typography>
                          </div>
                        )}
                      />
                    )
                  ) : (
                    <Typography
                      variant="body2"
                      align={'right'}
                      style={{ textTransform: 'capitalize', fontWeight: 400 }}
                    >
                      {rule.logicalOperator}
                    </Typography>
                  )}
                </Grid>

                {/*rule  */}
                {rule.type == 'filter' && (
                  <Grid
                    item
                    className={classes.groupRule}
                    style={{ flexGrow: 3, flexWrap: isMobileView ? 'wrap' : 'nowrap' }}
                  >
                    <SelectFormSearch
                      noShadow={true}
                      options={options}
                      style={{
                        flexGrow: 0.5,
                        backgroundColor: '#fff',
                        width: '100%',
                      }}
                      inputWidth={isSubForm ? '35%' : '40%'}
                      value={rule.field}
                      disabled={isStatic || rule.isDisabled || disabled || isLocked}
                      placeholder={'Add name'}
                      placeholderInput={'Search'}
                      onChangeValue={(val) => handleChangeRule('field', val, idxLevel1)}
                      className={`${classes.selectField} queryField`}
                      classNamePaper={`${classes.selectSearchPaper}`}
                      autoFocus={true}
                    />

                    <SelectFormGroup
                      noShadow={true}
                      style={{ flexGrow: 1, backgroundColor: '#fff' }}
                      options={getFieldOperators(rule.field.dataType, rule.field)}
                      inputWidth={isSubForm ? '30%' : '25%'}
                      disabled={isStatic || rule.isDisabled || disabled || isLocked}
                      value={rule.queryType}
                      onChangeValue={(val) => handleChangeRule('queryType', val, idxLevel1)}
                      className={`${classes.selectField} queryField `}
                    />
                    {getValueContentWithRule(rule, idxLevel1)}
                  </Grid>
                )}
                {rule.type == 'groupFilter' && rule.subFilters.length > 0 && (
                  <Grid className={classes.groupSub}>
                    <LegacyFilterGroup
                      level={level + 1}
                      tmpFilterModel={rule.subFilters}
                      getDefaultRule={getDefaultRule}
                      getFieldOperators={getFieldOperators}
                      setTmpFilterModel={(val: IFilterItem[]) => {
                        handleSetGroup(idxLevel1, val);
                      }}
                      options={options}
                      isSubForm={true}
                      operators={operators}
                      enableFillerGroup={enableFillerGroup}
                      getOptionAvailable={getOptionAvailable}
                      isStaticGroup={false}
                      filterData={filterData}
                      disabled={rule.isDisabled}
                      checkingValidField={checkingValidField}
                      setCheckingValidField={setCheckingValidField}
                      requiredFilterFields={requiredFilterFields}
                    />
                  </Grid>
                )}
                {((!isStatic && !isLocked) || isEditMode) && (
                  <Grid item className={`${classes.groupRule} ${classes.iconMore}`}>
                    <Dropdown
                      disabledClickContentClose={false}
                      classNamePaper={classes.listAction}
                      alignMenu="right"
                      icon="threeDotsVertical"
                      label=""
                      isMobileFilter={true}
                      isButtonActive={filterData.length > 0}
                    >
                      <MenuAction
                        index={idxLevel1}
                        isStaticGroup={isStatic && rule.type == 'groupFilter'}
                        isDisabled={rule.isDisabled}
                        {...(isEditMode ? { isStatic, isLocked } : {})}
                      />
                    </Dropdown>
                  </Grid>
                )}
                {isLocked && !isEditMode ? (
                  <Box className={classes.lockIcon}>
                    <Icon type={'rmx/lock2-fill-icon/#0000008a'} size={'16px'} />
                  </Box>
                ) : null}
              </Grid>
            }
          </div>
        );
      })}

      {!isStaticGroup && tmpFilterModel.length > 0 && enableFillerGroup && (
        <Grid item className={classes.buttonAddBottom}>
          <Dropdown
            disabledClickContentClose={false}
            label={'Add more filters'}
            iconStart={'plus'}
            alignMenu={'left'}
            className={classes.dropdownMenu}
            classNamePaper={`${classes.paper}`}
            icon={'chevron'}
            sizeButton={'24px'}
            sizeIcon={'8px'}
            isMobileFilter={true}
            isButtonActive={filterData.length > 0}
          >
            <MenuList listMenu={dataMenu} />
          </Dropdown>
        </Grid>
      )}
    </React.Fragment>
  );
};

interface ISelectedValueProps extends Omit<SelectFormProp, 'value'> {
  disabled?: boolean;
  value?: OptionSelectType;
  defaultValue: string;
  field: string;
  getOptionAvailable: (
    field: string,
    filterData?: IFilterItem[],
  ) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  filterData?: any;
}

const SelectedValue = ({
  disabled = false,
  field = '',
  defaultValue,
  getOptionAvailable,
  checkingValidField,
  requiredFilterFields,
  ...rest
}: ISelectedValueProps) => {
  const classes = useStyles();
  const backbone = React.useContext(TableBackboneContext);
  const endpoint = backbone.getConfig('endpoint', { GET_TABLE_DATA: '' }).GET_TABLE_DATA;
  const queryParams = getQueryParams(endpoint);
  if (ff.new_filter_selection && queryParams.namespace) {
    return (
      <Box className={classes.buttonFilter}>
        <FilterSelection
          filter={{ id: field, placeholder: rest.placeholder, placeholderInput: rest.placeholderInput }}
          onSubmit={({ payload }) => {
            rest.onChangeValue({
              ...payload,
              value: String(payload.value),
            });
          }}
          getFilterOptionValue={getOptionAvailable}
          defaultValues={{ [field]: { queryValue: defaultValue } }}
          selectionContainerClass={classes.selectionContainerClass}
          marginBottom={5}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          disabled={disabled}
          isSingle
        />
      </Box>
    );
  }
  const [options, setOptions] = React.useState<OptionSelectType[]>([]);
  const [loadingObj, setLoadingObj] = React.useState({ isLoading: false, message: '', status: '' });
  const value = React.useMemo(() => {
    const va = options.find((item) => item.value == defaultValue);
    if (va) {
      return va;
    }
    return { label: '', value: '' };
  }, [options, defaultValue]);

  const handleGetOptions = async (forceReload = false) => {
    setLoadingObj({ ...loadingObj, isLoading: true, status: 'loading' });
    const res = await getOptionAvailable(field, rest.filterData, forceReload);

    if (res.success) {
      if (_.get(res.data, 'length', 0) > 0) {
        setLoadingObj({ ...loadingObj, isLoading: false, message: res.message, status: 'success' });
        setOptions(res.data);
      } else {
        setOptions([]);
        setLoadingObj({
          ...loadingObj,
          isLoading: true,
          message: res.message ? res.message : 'Please try again.',
          status: 'fail',
        });
      }
    } else {
      setLoadingObj({
        ...loadingObj,
        isLoading: true,
        message: res.message ? res.message : 'Please try again.',
        status: 'fail',
      });
      setOptions([]);
    }
  };

  React.useEffect(() => {
    handleGetOptions();
  }, [field, rest.filterData]);
  return (
    <SelectFormSearch
      disabled={disabled}
      value={value}
      noShadow={true}
      options={options}
      classNamePaper={`${classes.selectSearchPaper}`}
      loading={loadingObj}
      handleRefeshLoading={handleGetOptions}
      isError={!value.value && checkingValidField && requiredFilterFields.includes(field)}
      {...rest}
    />
  );
};
export default LegacyFilterGroup;
