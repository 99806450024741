/**
 * TOBE removed:
 * http://localhost:9800/iframe.html?id=eo2-full-app--primary&args=&viewMode=story#/page/afdd53e6-49f9-4170-9212-bdeada713c0d/100039290562?marketplace=LAZADA
 */
// ** React Perfect Scrollbar
import { Box, makeStyles } from '@material-ui/core';
import React from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Route, Switch, useHistory, useParams, useRouteMatch, useLocation } from 'react-router';
import { get } from 'lodash';
import minimatch from 'minimatch';
import { BlockFactoryContext, EIP_CONSTANT, aim, useLog, usePage, AuthContext, socket } from '@eip/next/lib/main';
import { usePassport } from '@ep/insight-ui/elements/app-menu/hooks/use-passport';
import { getPagePrefix1, getPageUrn } from '@ep/insight-ui/eo2/global';
// ** Core styles
import { DashboardSinglePreset } from '../dashboard-app';
import { usePageControl } from './page-control';
import chartLibs from '../components/dashboard/node/chartlibs';
import NotFound from '../components/not-found';
import * as uuid from 'uuid';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';
import PriceBook from '@ep/insight-ui/elements/price-book';

const log = useLog('page:main');

function getBlockComponent(blockType: string) {
  return chartLibs.find(blockType);
}

export default function MainPage(): JSX.Element {
  return (
    <BlockFactoryContext.Provider value={{ find: getBlockComponent }}>
      <DndProvider context={window} backend={HTML5Backend}>
        <EipPageRouted />
      </DndProvider>
    </BlockFactoryContext.Provider>
  );
}

function RequiredPresetId({ pageId }) {
  return <DashboardSinglePreset presetId={pageId} />;
}

const useStyle = makeStyles((theme) => ({
  containerWrapper: {
    marginLeft: 52,
    width: `calc(100% - 80px)`,
  },
}));

function EipPageRouted() {
  usePageControl();
  // const match = useRouteMatch();
  const based = getPagePrefix1();

  React.useEffect(() => {
    socket.connect();

    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <Switch>
      <Route path={`${based}`} exact={true} component={RootPage}></Route>
      <Route path={`${based}/pricing`} exact={true} component={PricingPage}></Route>
      <Route path={`${based}/:systemPageId`} exact={true} component={SystemPage}></Route>
      <Route path={`${based}/:systemPageId/default`} exact={true} component={SystemPage}></Route>
      {aim.canAccess('page:edit') && (
        <Route path={`${based}/:systemPageId/edit`} exact={true} component={SystemPageEdit}></Route>
      )}
      <Route path={`${based}/:systemPageId/:entityId`} exact={true} component={SystemPageEntity}></Route>
      <Route path={`${based}/:systemPageId/default/:userPageId`} exact={true} component={PageContainer}></Route>
      <Route path={`${based}/:systemPageId/:entityId/:userPageId`} exact={true} component={PageEntityDetails}></Route>
      <Route path={`/:workspaceId`} exact={true} component={RootPage}></Route>
      <Route path={`/:workspaceId${based}/pricing`} exact={true} component={PricingPage}></Route>
      <Route path={`/:workspaceId${based}`} exact={true} component={RootPage}></Route>
      <Route path={`/:workspaceId${based}/:systemPageId`} exact={true} component={SystemPage}></Route>
      <Route path={`/:workspaceId${based}/:systemPageId/default`} exact={true} component={SystemPage}></Route>
      {aim.isSuperAdmin() && (
        <Route path={`/:workspaceId${based}/:systemPageId/edit`} exact={true} component={SystemPageEdit}></Route>
      )}
      <Route path={`/:workspaceId${based}/:systemPageId/:entityId`} exact={true} component={SystemPageEntity}></Route>
      <Route
        path={`/:workspaceId${based}/:systemPageId/default/:userPageId`}
        exact={true}
        component={PageContainer}
      ></Route>
      <Route
        path={`/:workspaceId${based}/:systemPageId/:entityId/:userPageId`}
        exact={true}
        component={PageEntityDetails}
      ></Route>
    </Switch>
  );
}

function Todo() {
  return <h1>Todo</h1>;
}

function RootPage() {
  const { isInit, loadPageList, pageList } = usePage();
  const { getLeftMenu } = usePassport();
  const history = useHistory();
  const { workspaceId } = useParams<{ workspaceId: string }>();
  const userProfile = aim.getUserSettings().profile;
  const userEmail = String(userProfile.userEmail).toLowerCase();
  const userWorkspaceDomain = userProfile?.workspace_domain;

  function filterMenuPermission(menuList) {
    const ml = menuList.filter((m) => {
      if (m.accessBy && m.accessBy.length > 0) {
        let allowAccess = false;

        if (aim.isSuperAdmin()) return true;

        const includedAccess = m.accessBy.filter((i) => !String(i).startsWith('!'));
        const excludeAccess = m.accessBy.filter((i) => String(i).startsWith('!'));

        allowAccess = allowAccess || includedAccess.length === 0;
        allowAccess = allowAccess || includedAccess.some((i) => minimatch(userProfile.userId, i, { matchBase: true }));
        allowAccess =
          allowAccess || includedAccess.some((i) => minimatch(userEmail, String(i).toLowerCase(), { matchBase: true }));
        allowAccess =
          allowAccess &&
          (excludeAccess.length === 0 ||
            excludeAccess.every((i) => minimatch(userProfile.userId, i, { matchBase: true })));

        allowAccess =
          allowAccess &&
          (excludeAccess.length === 0 || excludeAccess.every((i) => minimatch(userEmail, i, { matchBase: true })));

        return allowAccess;
      }
      return true;
    });

    ml.forEach((m) => {
      m.tabs = filterMenuPermission(m.tabs || [], m.nodeId);
    });

    return ml;
  }

  if (ff.next_left_menu) {
    React.useEffect(() => {
      getLeftMenu().then((res) => {
        const result = get(res, ['data'], []);
        const firstValidProduct = userWorkspaceDomain == 'sandbox' ? result : filterMenuPermission(result) || [];
        const firstPage = firstValidProduct?.find((el) => get(el, ['tabs', 'length'], 0) > 0);
        if (firstPage) {
          const wsId = workspaceId || userWorkspaceDomain;
          const workspaces = aim.getUserSettings().workspaces || [];
          const workspaceDetail = workspaces.find((ws) => ws.workspace_domain == wsId);
          const wsHomepage = workspaceDetail?.homepage;
          const favorites = workspaceDetail?.favorite;
          let pageId = '';
          if (wsHomepage) pageId = wsHomepage;
          else if (favorites) {
            try {
              pageId = get(JSON.parse(favorites), [0, 'nodeId'], '');
            } catch {}
          }
          if (!pageId) {
            pageId = firstPage.tabs?.[0]?.nodeId;
          }
          history.push(getPageUrn(`/${wsId}` + '/page/' + pageId));
        }
      });
    }, [isInit]);
  } else {
    React.useEffect(() => {
      if (isInit === false) {
        loadPageList();
      }
    }, [isInit]);
    React.useEffect(() => {
      if (Object.values(pageList).length > 0) {
        const first = Object.values(pageList)[0];
        const wsHomepage = aim.getUserSettings().profile?.workspace_homepage;
        const favorites = aim.getUserSettings().profile?.favorite;
        let pageId = '';
        if (wsHomepage) pageId = wsHomepage;
        else if (favorites) {
          try {
            pageId = get(JSON.parse(favorites), [0, 'nodeId'], '');
          } catch {}
        }
        if (!pageId) {
          pageId = first.blockEid;
        }
        history.push(getPageUrn('/page/' + pageId));
      }
    }, [pageList, isInit]);
  }

  return null;
}

function SystemPage() {
  const classes = useStyle();
  const { workspaces, setCurrentWorkspace, loadedWorkspaces } = React.useContext(AuthContext);
  const {
    loadSinglePage,
    currentPage,
    setCurrentPage,
    getLoadingStatus,
    title,
    loadFormulaTitle,
    pageHashtag,
    pageSubtext,
    refreshCaches,
    updatePageSocket,
    loadUserList,
  } = usePage();
  const { params } = useRouteMatch<{ systemPageId: string; workspaceId?: string }>();
  const history = useHistory();
  const [init, setInit] = React.useState(false);
  const memoRef = React.useRef({ systemPageId: null, userPersonaPageId: null, userWorkspaceId: null });
  const workspaceId = aim.getUserSettings().profile?.workspace_id;
  const userWorkspaceDomain = aim.getUserSettings().profile?.workspace_domain;
  const { search } = useLocation();
  const userEmail = aim.getUserSettings().profile?.userEmail;
  const name = aim.getUserSettings().profile?.auth0_name;
  const pageId = window.location.href.match(/page\/.*(?=\/)/)?.[0]?.replace('page/', '');

  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q } = queryParams;

  const handleEvents = (viewingPage) => {
    updatePageSocket({
      view: viewingPage,
    });
  };

  // const handleUpdateLastUpdatedEvents = () => {
  //   refreshCaches(params.systemPageId);
  // };
  React.useEffect(() => {
    if (aim.canAccess('internal')) {
      loadUserList();
    }
  }, []);

  React.useEffect(() => {
    socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
    socket.on('updateViewRoom', handleEvents);
    // socket.on('updateRoomLastUpdated', handleUpdateLastUpdatedEvents);

    const handleTabChange = (event) => {
      if (event.target.hidden === true) return;
      socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
    };

    document.addEventListener('visibilitychange', handleTabChange);

    return () => {
      updatePageSocket({
        view: {
          members: {},
        },
      });
      document.removeEventListener('visibilitychange', handleTabChange);
      socket.off('updateViewRoom', handleEvents);
      // socket.off('updateRoomLastUpdated', handleUpdateLastUpdatedEvents);
      socket.emit('leaveViewRoom', { pageId, email: userEmail, q });
    };
  }, [pageId, q]);

  React.useEffect(() => {
    const handleConnect = () => {
      if (!window.location.href.includes('/edit')) {
        socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
      }
    };
    socket.on('connect', handleConnect);

    return () => {
      socket.off('connect', handleConnect);
    };
  }, [q]);

  React.useEffect(() => {
    loadFormulaTitle(title, pageHashtag, pageSubtext);
  }, [title, pageHashtag, pageSubtext]);

  React.useEffect(() => {
    log('systempage - load single page', params.systemPageId);
    if (ff.next_left_menu) {
      setCurrentWorkspace(params.workspaceId);
      if (workspaces.length == 0) return;
      // Redirect to latest workspace if current workspace is invalid
      if (workspaces.every((ws) => ws.workspace_domain != params.workspaceId)) {
        let wsId = userWorkspaceDomain;
        if (!wsId || (wsId && workspaces.every((ws) => ws.workspace_id != workspaceId))) {
          wsId = workspaces[0]?.workspace_domain;
        }
        const newPageUrn = `/page/${params.systemPageId}/default` + search;
        history.push(`/${wsId}` + newPageUrn);
        return;
      }
    }
    if (
      memoRef.current.systemPageId !== params.systemPageId ||
      (memoRef.current.userWorkspaceId !== params.workspaceId && ff.next_left_menu)
    ) {
      memoRef.current.systemPageId = params.systemPageId;
      if (ff.next_left_menu) {
        memoRef.current.userWorkspaceId = params.workspaceId;
      }
      setTimeout(() => {
        loadSinglePage(params.systemPageId);
      }, 100);
      setTimeout(() => {
        refreshCaches(params.systemPageId);
      }, 9000);
      setInit(true);
    }
  }, [params.systemPageId, ...(ff.next_left_menu ? [params.workspaceId, workspaces] : []), currentPage]);

  React.useLayoutEffect(() => {
    log('current page', currentPage);
    if (!currentPage || !init) return;
    else if (
      memoRef.current.userPersonaPageId !== currentPage.blockEid &&
      currentPage.table == 'eip_block' &&
      currentPage.blockType === 'user_persona'
    ) {
      memoRef.current.userPersonaPageId = currentPage.blockEid;
      setCurrentPage(currentPage.blockEid);
      let newPageUrn = `/page/${currentPage.content[0]}/default`;
      if (ff.next_left_menu && (params?.workspaceId || workspaceId)) {
        newPageUrn = `/${params?.workspaceId || workspaceId}` + newPageUrn;
      }
      newPageUrn = getPageUrn(newPageUrn);
      const [finUrn, _] = newPageUrn.split('?');
      history.replace({
        pathname: finUrn,
        search: history.location.search,
      });
    }
    // } else if (
    //   currentPage.table == 'eip_block' &&
    //   currentPage.blockType === 'user_system_link' &&
    //   params.systemPageId === currentPage.blockEid
    // ) {
    //   history.replace({
    //     pathname: getPageUrn(`/page/${currentPage.content[0]}/${currentPage.entityId}/${currentPage.blockEid}`),
    //     search: history.location.search,
    //   });
    // }
  }, [currentPage]);

  if ((loadedWorkspaces && workspaces.length === 0) || (!getLoadingStatus('LOAD_SINGLE_PAGE')?.status && !currentPage))
    return <NotFound />;

  if (currentPage && currentPage.blockType === EIP_CONSTANT.BLOCK_TYPE.userPersona) {
    return (
      <Box className={classes.containerWrapper} id="eip-page-container-wrapper">
        <RequiredPresetId pageId={currentPage.blockEid} />
      </Box>
    );
  }

  return null;
}

function SystemPageEntity() {
  const classes = useStyle();
  const { workspaces, setCurrentWorkspace, loadedWorkspaces } = React.useContext(AuthContext);
  const {
    loadSinglePage,
    currentPage,
    setCurrentPage,
    getLoadingStatus,
    loadFormulaTitle,
    title,
    pageHashtag,
    pageSubtext,
    refreshCaches,
  } = usePage();
  const { params } = useRouteMatch<{ systemPageId: string; entityId: string; workspaceId?: string }>();
  const memoRef = React.useRef({ systemPageId: null, userPersonaPageId: null });
  const history = useHistory();
  const { search } = useLocation();
  const workspaceId = aim.getUserSettings().profile?.workspace_id;
  const userWorkspaceDomain = aim.getUserSettings().profile?.workspace_domain;

  log('systempageentity', history.location.pathname);

  React.useEffect(
    () => {
      log('systempageentity - load single page', params.systemPageId);
      loadSinglePage(params.systemPageId, { entityId: params.entityId });
    },
    ff.next_left_menu ? [params.entityId] : [],
  );

  React.useEffect(() => {
    loadFormulaTitle(title, pageHashtag, pageSubtext);
  }, [title, pageHashtag, pageSubtext]);

  React.useEffect(() => {
    log('current page', currentPage);
    if (ff.next_left_menu) {
      setCurrentWorkspace(params.workspaceId);
      if (workspaces.length == 0) return;
      // Redirect to latest workspace if current workspace is invalid
      if (workspaces.every((ws) => ws.workspace_domain != params.workspaceId)) {
        let wsId = userWorkspaceDomain;
        if (!wsId || (wsId && workspaces.every((ws) => ws.workspace_id != workspaceId))) {
          wsId = workspaces[0]?.workspace_domain;
        }
        const newPageUrn = `/page/${params.systemPageId}/${params.entityId}` + search;
        history.push(`/${wsId}` + newPageUrn);
        return;
      }
    }
    if (
      currentPage &&
      currentPage.table == 'eip_block' &&
      currentPage.blockType === 'user_persona' &&
      currentPage.blockEid !== memoRef.current.userPersonaPageId
    ) {
      memoRef.current.userPersonaPageId = currentPage.blockEid;
      setCurrentPage(currentPage.blockEid);
      setTimeout(() => {
        refreshCaches(params.systemPageId);
      }, 1000);
      // history.replace({
      //   pathname: getPageUrn(`/page/${currentPage.content[0]}/${params.entityId}/${currentPage.blockEid}`),
      //   search: history.location.search,
      // });
    }
  }, [currentPage]);

  if (
    (ff.next_left_menu && loadedWorkspaces && workspaces.length === 0) ||
    (!getLoadingStatus('LOAD_SINGLE_PAGE')?.status && !currentPage)
  )
    return <NotFound />;

  if (currentPage && currentPage.blockType === EIP_CONSTANT.BLOCK_TYPE.userPersona) {
    return (
      <Box className={classes.containerWrapper} id="eip-page-container-wrapper">
        <RequiredPresetId pageId={currentPage.blockEid} />
      </Box>
    );
  }

  return null;
}

function PageEntityDetails() {
  const { loadSinglePage } = usePage();
  const { params } = useRouteMatch<{ systemPageId: string; entityId: string; userPageId: string }>();
  const classes = useStyle();

  log('pageentitydetails');

  React.useEffect(() => {
    log('pageentitydetails - load single page', params.userPageId);
    loadSinglePage(params.userPageId, { entityId: params.entityId, systemPageId: params.systemPageId });
  }, []);

  return (
    <Box className={classes.containerWrapper} id="eip-page-container-wrapper">
      <RequiredPresetId pageId={params.userPageId} />
    </Box>
  );
}

function PageContainer() {
  const classes = useStyle();
  const { loadSinglePage, currentPage } = usePage();
  const params = useParams<{ systemPageId: string; userPageId: string }>();

  React.useEffect(() => {
    if (!currentPage || currentPage.blockEid !== params.userPageId) {
      loadSinglePage(params.userPageId);
    }
  }, [params.userPageId, params.systemPageId, currentPage]);

  return (
    <Box className={classes.containerWrapper} id="eip-page-container-wrapper">
      <RequiredPresetId pageId={params.userPageId} />
    </Box>
  );
}

function SystemPageEdit() {
  const classes = useStyle();
  const params = useParams<{ systemPageId: string }>();
  const { loadSingleSystemPage, currentPage, loadFormulaTitle, title, pageHashtag, pageSubtext, updatePageSocket } =
    usePage();

  const userEmail = aim.getUserSettings().profile?.userEmail;
  const name = aim.getUserSettings().profile?.auth0_name;
  const pageId = window.location.href.match(/page\/.*(?=\/)/)?.[0]?.replace('page/', '');

  React.useEffect(() => {
    loadFormulaTitle(title, pageHashtag, pageSubtext);
  }, [title, pageHashtag, pageSubtext]);

  log('systempageedit');
  React.useEffect(() => {
    if (!currentPage || currentPage.blockEid !== params.systemPageId) {
      loadSingleSystemPage(params.systemPageId);
    }
  }, [params.systemPageId, currentPage]);

  const handleUpdateMembersEvents = (editingPage) => {
    updatePageSocket({
      edit: editingPage,
    });
  };

  const handleUpdateLastUpdatedEvents = (lastUpdated) => {
    updatePageSocket({
      lastUpdated,
    });
    if (lastUpdated?.by && lastUpdated?.id != socket?.id) {
      const message = "This page's settings was overridden by " + lastUpdated.by;
      if (window.Notyf) {
        const notyf = new window.Notyf({ duration: 0, position: { x: 'center', y: 'top' }, dismissible: true });
        notyf.success(message);
      } else {
        alert(message);
      }
    }
  };

  React.useEffect(() => {
    socket.emit('joinEditRoom', { pageId, email: userEmail, name });
    socket.on('updateEditRoom', handleUpdateMembersEvents);
    socket.on('updateRoomLastUpdated', handleUpdateLastUpdatedEvents);

    return () => {
      updatePageSocket({
        edit: {
          members: {},
        },
      });
      socket.off('updateEditRoom', handleUpdateMembersEvents);
      socket.off('updateRoomLastUpdated', handleUpdateLastUpdatedEvents);
    };
  }, []);

  React.useEffect(() => {
    socket.on('connect', () => {
      if (window.location.href.includes('/edit')) {
        socket.emit('joinEditRoom', { pageId, email: userEmail, name });
      }
    });
  }, []);

  React.useEffect(() => {
    const handleEvent = (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault();
      // Chrome requires returnValue to be set.
      event.returnValue = '';
    };
    window.addEventListener('beforeunload', handleEvent);

    return () => {
      window.removeEventListener('beforeunload', handleEvent);
    };
  }, []);
  return (
    <Box className={classes.containerWrapper} id="eip-page-container-wrapper">
      <DashboardSinglePreset presetId={params.systemPageId} mode={'edit'} />
    </Box>
  );
}

function PricingPage() {
  const classes = useStyle();
  const { workspaces, setCurrentWorkspace, loadedWorkspaces } = React.useContext(AuthContext);
  const { updatePageSocket, loadUserList } = usePage();
  const { params } = useRouteMatch<{ systemPageId: string; workspaceId?: string }>();
  const history = useHistory();
  const workspaceId = aim.getUserSettings().profile?.workspace_id;
  const userWorkspaceDomain = aim.getUserSettings().profile?.workspace_domain;
  const { search } = useLocation();
  const userEmail = aim.getUserSettings().profile?.userEmail;
  const name = aim.getUserSettings().profile?.auth0_name;
  const pageId = 'pricing';

  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q } = queryParams;

  const handleEvents = (viewingPage) => {
    updatePageSocket({
      view: viewingPage,
    });
  };

  React.useEffect(() => {
    if (aim.canAccess('internal')) {
      loadUserList();
    }
  }, []);

  React.useEffect(() => {
    socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
    socket.on('updateViewRoom', handleEvents);

    const handleTabChange = (event) => {
      if (event.target.hidden === true) return;
      socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
    };

    document.addEventListener('visibilitychange', handleTabChange);

    return () => {
      updatePageSocket({
        view: {
          members: {},
        },
      });
      document.removeEventListener('visibilitychange', handleTabChange);
      socket.off('updateViewRoom', handleEvents);
      socket.emit('leaveViewRoom', { pageId, email: userEmail, q });
    };
  }, [pageId, q]);

  React.useEffect(() => {
    const handleConnect = () => {
      if (!window.location.href.includes('/edit')) {
        socket.emit('joinViewRoom', { pageId, email: userEmail, name, q });
      }
    };
    socket.on('connect', handleConnect);

    return () => {
      socket.off('connect', handleConnect);
    };
  }, [q]);

  React.useEffect(() => {
    log('systempage - load single page', params.systemPageId);
    if (ff.next_left_menu) {
      setCurrentWorkspace(params.workspaceId);
      if (workspaces.length == 0) return;
      // Redirect to latest workspace if current workspace is invalid
      if (workspaces.every((ws) => ws.workspace_domain != params.workspaceId)) {
        let wsId = userWorkspaceDomain;
        if (!wsId || (wsId && workspaces.every((ws) => ws.workspace_id != workspaceId))) {
          wsId = workspaces[0]?.workspace_domain;
        }
        const newPageUrn = `/page/pricing`;
        history.push(`/${wsId}` + newPageUrn);
        return;
      }
    }
  }, []);

  if (loadedWorkspaces && workspaces.length === 0) return <NotFound />;

  return (
    <Box id="eip-page-container-wrapper">
      <PriceBook />
    </Box>
  );
}
