import * as React from 'react';

import { makeStyles } from '@material-ui/core';

import { SwitchIcon } from '@ep/insight-ui/elements/modal/switch';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  label: {
    color: '#8C98A4',
    fontSize: '12px',
    fontWeight: 500,
    lineHeight: '16px',
    '.horizontal &': {
      width: '30%',
    },
  },
  tooltip: {
    '& p': {
      margin: 0,
      padding: '2px 4px',
    },
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

const Toggle = ({ placeholder, initialValue, onChange, tooltip }) => {
  const classes = useStyles();

  const handleChange = (e) => {
    onChange({
      target: {
        value: e.target.checked,
      },
    });
  };

  React.useEffect(() => {
    setTimeout(() => {
      onChange({
        target: {
          value: initialValue,
        },
      });
    }, 0);
  }, [initialValue, onChange]);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {placeholder ? <span className={classes.label}>{placeholder}</span> : null}
        {tooltip ? (
          <>
            <StyledTooltip
              title={
                <div className={classes.tooltip}>
                  {tooltip ? <p className={classes.tooltipContent}>{tooltip}</p> : null}
                </div>
              }
              placement={'right'}
            >
              <div className={classes.title}>
                <Icon type={'ic/mi:circle-information/#737373'} />
              </div>
            </StyledTooltip>
          </>
        ) : null}
      </div>
      <SwitchIcon defaultChecked={initialValue} onChange={handleChange} isCustom />
    </div>
  );
};

export default Toggle;
