import React from 'react';
import LineChartResponsive from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize/line-chart';
import { dataType, IData, IOption } from '@ep/insight-ui/chartlib/type';
import { IStatusChartBB } from '../../../elements/eDashboard/dashboard-container';
import { defaultOptions, tool } from '@ep/insight-ui/chartlib/helper';
import * as _ from 'lodash';
import * as LineChartHook from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize/hooks-v2';
import { IDateValue, IRowMetric } from '@ep/insight-ui/elements/eDashboard/mocks/intraday';
import moment from 'moment';
import { IStateChartValue } from '../../chart-loading-state/chart-loading-state';
import { Dialog } from '@material-ui/core';
export enum LineCase {
  TODAY = 'today',
  YESTERDAY = 'yesterday',
  OTHER = 'other',
}
export type ILineChartList = {
  dataList: IData;
  title: string;
  value: number;
  percent: number;
  unit?: string;
  forwardMeaning?: boolean;
  stateChart?: IStatusChartBB;
  lineCase?: LineCase;
};
type Props = {
  index: number;
  metricData: IRowMetric;
  selected?: number;
  onClose?: () => void;
  isChartHide?: boolean;
};

const isSameDate = (currenDate: moment.Moment, dateCompare: moment.Moment) => {
  if (!currenDate.isValid() || !dateCompare.isValid()) return false;
  return currenDate.isSame(dateCompare, 'days');
};

const checkCase = (thisDayValueSerries: IDateValue[], LastDayValueSerries: IDateValue[]) => {
  const startThisDate = moment(_.get(thisDayValueSerries, [0, 'date'], ''));
  const endThisDate = moment(_.get(thisDayValueSerries, [thisDayValueSerries.length - 1, 'date'], ''));
  const startLastDate = moment(_.get(LastDayValueSerries, [0, 'date'], ''));
  const endLastDate = moment(_.get(LastDayValueSerries, [LastDayValueSerries.length - 1, 'date'], ''));

  // Case Today or Yesterday
  if (isSameDate(startThisDate, endThisDate) && isSameDate(startLastDate, endLastDate)) {
    const today = moment();
    const yesterday = moment().subtract(1, 'days');
    /**
     *  # 1
     * if this value is today and last time is yesterday
     * return LineCase.TODAY
     */
    if (isSameDate(startThisDate, today) && isSameDate(startLastDate, yesterday)) return LineCase.TODAY;

    /**
     *  # 2
     *  if this value is yesterday and last time is the previous day
     *  return LineCase.YESTERDAY
     */

    if (isSameDate(startThisDate, yesterday) && isSameDate(startLastDate, moment().subtract(2, 'days')))
      return LineCase.YESTERDAY;
  }

  /**
   * #3
   *  others case always return  LineCase.OTHER
   *
   */
  return LineCase.OTHER;
};

const getlabelTodayCase = (array: IDateValue[], index: number) => {
  return array.map((data) => (index === 0 ? 'Today' : 'Yesterday'));
};

const getLabelOtherCase = (array: IDateValue[]) => {
  return array.map((item) => item.date);
};

const getLabel = (lineCase: LineCase, array: IDateValue[], index: number) => {
  if (lineCase === LineCase.TODAY) {
    return getlabelTodayCase(array, index);
  }
  // if (lineCase === LineCase.YESTERDAY) {
  //   return getlabelYesterdayCase(array, index);
  // }
  return getLabelOtherCase(array);
};

const getPrecent = (row: IRowMetric) => {
  const this_time_value: number = _.get(row, 'value', _.get(row, 'this_time_value', 0));
  const last_time_value: number = _.get(row, 'lastValue', _.get(row, 'last_time_value', 0));
  let value = 0;
  if (last_time_value !== 0) {
    value = (this_time_value - last_time_value) / last_time_value;
  }
  return _.round(value * 100, 2).toFixed(2);
};

export const getTimeOfDay = (m: moment.Moment) => {
  return m.minutes() + m.hours() * 60;
};
const getStateChart = (thisData: Array<any>, lastData: Array<any>, isChartHide: boolean): IStateChartValue => {
  if (isChartHide) return 'hide';
  const isEmptyThisData = _.isEmpty(thisData);
  const isEmptyLastData = _.isEmpty(lastData);
  if (isEmptyThisData && isEmptyLastData) {
    return 'empty';
  }
  return 'success';
};
const getDataLineChart = (row, isChartHide) => {
  const thisDayValueSerries: Array<IDateValue> = _.get(row, ['this_time_value_serries'], []).filter((item) => !!item);
  const LastDayValueSerries: Array<IDateValue> = _.get(row, ['last_time_value_serries'], []).filter((item) => !!item);
  const forwardMeaning: boolean = _.get(row, 'forwardMeaning', true);
  const title: string = _.get(row, ['title'], '');
  const value: number = _.get(row, ['value'], 0);
  const unit: string = _.get(row, ['unit'], '');
  const toDay = moment();
  const percent = getPrecent(row);
  const thisDayDataSerries = [];
  const dashLine = [];
  const lineCase = checkCase(thisDayValueSerries, LastDayValueSerries);
  console.log(lineCase);

  thisDayValueSerries.forEach((item, index) => {
    const date = moment(item.date);
    if (isSameDate(date, toDay) && getTimeOfDay(date) > getTimeOfDay(toDay)) {
      dashLine.push(item.value);
    } else if (date.isAfter(toDay)) {
      dashLine.push(item.value);
    } else {
      thisDayDataSerries.push(item.value);
    }
  });

  const labels: string[] =
    thisDayValueSerries.length > 0
      ? thisDayValueSerries.map((item) => (lineCase !== LineCase.OTHER ? moment(item.date).format('HH:mm') : item.date))
      : LastDayValueSerries.map((item) =>
          lineCase !== LineCase.OTHER ? moment(item.date).format('HH:mm') : item.date,
        );
  const datasets: dataType[] = [
    {
      // label: `${title && title + ' - '}Today`,
      label: `Current`,
      data: thisDayDataSerries,
      dateList: getLabel(lineCase, thisDayValueSerries, 0),
      backgroundColor: '#204D77',
      borderColor: '#204D77',
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 0,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#204D77',
      pointHoverBorderColor: '#204D77',
      pointStyle: 'circle',
      lineTension: 0.4,
    },
    {
      label: LineChartHook.HIDDEN_LABEL_TEXT,
      data: thisDayDataSerries.map((item, index) => null).concat(dashLine),
      dateList: getLabel(lineCase, thisDayValueSerries, 0),
      backgroundColor: '#204D77',
      borderColor: '#204D77',
      borderDash: [10, 8],
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 0,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#204D77',
      pointHoverBorderColor: '#204D77',
      pointStyle: 'circle',
      lineTension: 0.4,
    },
    {
      // label: `${title && title + ' - '}Yesterday`,
      label: `Previous`,
      data: LastDayValueSerries.map((item) => item.value),
      dateList: getLabel(lineCase, LastDayValueSerries, 1),
      backgroundColor: '#D4DDED',
      borderColor: '#D4DDED',
      fill: false,
      pointRadius: 3,
      pointHoverRadius: 3,
      pointBorderWidth: 0,
      pointHitRadius: 15,
      pointBackgroundColor: 'transparent',
      pointBorderColor: 'transparent',
      pointHoverBackgroundColor: '#D4DDED',
      pointHoverBorderColor: '#D4DDED',
      pointStyle: 'circle',
      lineTension: 0.4,
    },
  ];
  const stateChart = getStateChart(thisDayValueSerries, LastDayValueSerries, isChartHide);
  return {
    title,
    value,
    percent,
    dataList: {
      labels,
      datasets,
    },
    unit,
    forwardMeaning,
    stateChart,
    lineCase,
  };
};
const LineChartIntraday = ({ index = 0, metricData, selected, onClose = () => undefined, isChartHide }: Props) => {
  const { externalTooltipHandler } = LineChartHook.funcConfigs();
  const lineChart = getDataLineChart(metricData, isChartHide);
  const getOptionList = (currency?: string, lineCase?: LineCase) => {
    const { LINE: optionList } = JSON.parse(JSON.stringify(defaultOptions));
    _.set(optionList, 'plugins.tooltip.external', externalTooltipHandler);
    _.set(optionList, 'plugins.tooltip.callbacks.label', tool.lineCallbacksV2.label({ currency, lineCase }));
    _.set(optionList, 'scales.yAxes.ticks.callback', tool.callbacks.callback);
    _.set(optionList, 'isCrisper', false);
    // _.set(optionList, 'isFixedToolip', true);
    return optionList;
  };
  return (
    <>
      <LineChartResponsive
        keyChart={'edash_line' + index.toString()}
        dataList={lineChart.dataList}
        optionList={getOptionList(lineChart.unit, lineChart.lineCase)}
        stateChart={lineChart.stateChart}
        title={lineChart.title}
        value={lineChart.value}
        currency={lineChart.unit}
        percent={lineChart.percent}
        forwardMeaning={lineChart.forwardMeaning}
        keepHeadContent
        isBorder
        // disabledPaddingChart
        type="special"
        redraw={false}
      />
    </>
  );
};

export default LineChartIntraday;
