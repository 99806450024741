import { demoData } from './static-data';

const mockData = {
  string: 'Mock data',
  nextString: 'Mock data',
  integer: 0,
  datetime: '1970-01-01 00:00:00',
  date: '1970-01-01',
  float: 0,
};

export function getColumnData(columnName: string, dataType: string) {
  const data = {};
  if (demoData['headers'].includes(columnName)) {
    const index = demoData['headers'].indexOf(columnName);
    data[columnName] = demoData['rows'][0][index] ? demoData['rows'][0][index] : mockData[dataType];
  } else {
    data[columnName] = mockData[dataType];
  }
  return data;
}

export function getTableData(tableBackbone) {
  const dimension = tableBackbone.getOptions('dimension');
  const attribute = tableBackbone.getOptions('attribute');
  const metric = tableBackbone.getOptions('metric');
  const columns = dimension.concat(attribute, metric);

  let data = {};
  columns.forEach((value) => {
    data = {
      ...data,
      ...getColumnData(value.filterField, value.dataType),
    };
  });
  return data;
}
