import { EventEmitter } from 'events';
import React from 'react';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles(() => ({
  container: {
    '.eres--small &': {
      'background-color': 'grey',
    },
    '.eres--medium &': {
      'background-color': 'green',
    },
    '.eres--large &': {
      'background-color': 'yellow',
    },
  },
}));

class ResponsiveDemo implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <ResponsiveDemoBlock />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <BlockConfigForm data={data} onSubmit={handleSubmit} />;
  }
}

function BlockConfigForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Block configuration goes here.</h2>;
}

function ResponsiveDemoBlock() {
  const { containerClass } = React.useContext(ContainerResponsiveContext);

  const classname = useStyle();

  return (
    <div style={{ padding: '12px' }} className={classname.container}>
      <h2>
        My parent container <strong>{containerClass}</strong>
      </h2>
    </div>
  );
}

export default {
  blockType: 'responsive-demo',
  label: 'Responsive demo',
  blockComponent: ResponsiveDemo,
  systemConfig: {},
  layout: {
    w: 2,
    h: 2,
  },
};
