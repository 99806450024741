import { groupBy as _groupBy, first, get, omit, set, sortBy, zipObject } from 'lodash';
import moment from 'moment';

import produce from 'immer';
import { getQueryDatetimeValue } from '../../util/calendar';
import { getDateRangeFromOption } from '../../util/date-range-calculate';
import { toValue } from '../../util/excel-formula';
import { getMetricDefinition } from '../data/common';
import { ETableDataUpdate, PushCellUpdate } from '../push-cell-update';
import { convertColor } from '../../util/helper';
import { DATETIME_PICKER, DATETIME_REGEX } from '../constant';

const LIMIT_COUNT = 9999;
const DEFAULT_MAX_TRACTION_ROW = 10;

const getFilterParams = (rows = [], primaryKeys = [], maximumTractionRowsPerRequest = DEFAULT_MAX_TRACTION_ROW) => {
  if (primaryKeys.length === 1) {
    const key = primaryKeys[0];
    const filterParams = rows.map((row) => row[key]);
    const result = Array.from({ length: Math.ceil(filterParams.length / maximumTractionRowsPerRequest) }).map(
      (_, index) => {
        const value = filterParams.slice(
          index * maximumTractionRowsPerRequest,
          (index + 1) * maximumTractionRowsPerRequest,
        );
        return {
          field: key,
          operator: 'IN',
          value,
        };
      },
    );

    return result;
  }
  const filterParams = rows.map((row) => {
    return primaryKeys.reduce(
      (carry, key) => {
        return {
          ...carry,
          filters: carry.filters.concat(
            Object.keys(row).includes(key)
              ? {
                  field: key,
                  operator: 'is',
                  value: row[key],
                }
              : [],
          ),
        };
      },
      {
        combinator: 'and',
        filters: [],
      },
    );
  });
  const result = Array.from({ length: Math.ceil(filterParams.length / maximumTractionRowsPerRequest) }).map(
    (_, index) =>
      filterParams.slice(index * maximumTractionRowsPerRequest, (index + 1) * maximumTractionRowsPerRequest).reduce(
        (carry, data) => {
          return {
            ...carry,
            filters: carry.filters.concat(data),
          };
        },
        {
          combinator: 'OR',
          filters: [],
        },
      ),
  );
  return result;
};

export function makeTractionElasticityColumn(cellFormatType) {
  return async function (
    originData,
    {
      config,
      contextQuery,
      cellUpdate,
      pageRequests,
    }: { config: any; contextQuery: any; cellUpdate: PushCellUpdate; pageRequests: any },
    next,
  ) {
    const eTablePrimaryKeys = get(config, 'primaryKeys', []);

    const mappings = get(config, 'mapping', {});
    const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
    const visualizationType = get(config, ['visualizationType'], 'table');
    const datetimeFieldRequest = get(config, ['system', 'datetimeField'], 'created_datetime');
    const dateRangeChart = contextQuery.dateRangeChart || get(config, 'dateRange', {});
    const dateRangeCohort = contextQuery.dateRangeCohort || get(config, 'dateRange', {});
    const groupPeriod = get(config, ['groupPeriod'], '');
    const calendarConfig = get(config, ['calendarConfig'], {});
    const calendarTypePicker = get(calendarConfig, 'typePicker', 'default');
    const maximumTractionRowsPerRequest =
      get(config, ['system', 'maximumTractionRowsPerRequest'], DEFAULT_MAX_TRACTION_ROW) || DEFAULT_MAX_TRACTION_ROW;

    const isDatetimePicker = calendarTypePicker === DATETIME_PICKER;

    const {
      columnTractionElasticityKey,
      elasticityMetrics,
      willDisplayCharts,
      fillSlot,
      extra: extraInfo,
    } = Object.keys(mappings).reduce(
      (carry, el) => {
        const isSkipElasticity = get(mappings, [el, 'staticValue', 'isSkipElasticity'], false);
        const isSelected = []
          .concat(get(config, ['metric'], []), get(config, ['dimension'], []), get(config, ['attribute'], []))
          .some((mt) => mt == el);
        if (mappings[el]?.cellFormat === cellFormatType && !isSkipElasticity && isSelected) {
          carry.columnTractionElasticityKey.push(el);
          let elasticityMetrics = Object.entries(get(mappings, [el, 'valueGetter'], {})).reduce((a, [key, value]) => {
            if (/value(\d)*$/.test(key) && value) {
              return [...a, { key, value }];
            }
            return a;
          }, []);

          elasticityMetrics = sortBy(elasticityMetrics, (el) => el.key).reverse();

          carry.elasticityMetrics[el] = elasticityMetrics;
          carry.fillSlot[el] = get(mappings, [el, 'staticValue', 'fillSlot'], 'yes');
          carry.extra[el] = {
            chartTooltipFormat: get(
              mappings,
              [el, 'valueGetter', 'chartTooltipFormat'],
              get(mappings, [el, 'valueGetter', 'label'], '=p("value")'),
            ),
            serieFormat: carry.elasticityMetrics[el].reduce((a, b) => {
              const labelFormat = get(
                mappings,
                [el, 'valueGetter', b.key + '.label'],
                get(mappings, [el, 'valueGetter', 'label'], '=p("value")'),
              );
              return {
                ...a,
                [b.key]: {
                  label: labelFormat,
                  chartTooltipFormat: get(mappings, [el, 'valueGetter', b.key + '.chartTooltipFormat'], labelFormat),
                  chartStyle: get(
                    mappings,
                    [el, 'staticValue', b.key + '.chartStyle'],
                    get(mappings, [el, 'staticValue', 'chartStyle'], 'line'),
                  ),
                  color: get(mappings, [el, 'staticValue', b.key + '.color'], '#204D77'),
                  lineDash: get(mappings, [el, 'staticValue', b.key + '.lineDash'], null),
                  order: get(mappings, [el, 'staticValue', b.key + '.order'], null),
                  yAxisID: get(mappings, [el, 'staticValue', b.key + '.yAxisID'], null),
                  lineTension: get(mappings, [el, 'staticValue', b.key + '.lineTension'], null),
                  displayChart: get(mappings, [el, 'staticValue', b.key + '.displayChart'], 1),
                  customMetric: get(mappings, [el, 'staticValue', b.key + '.customMetric'], null),
                },
              };
            }, {}),
          };

          if (get(mappings, [el, 'staticValue', 'displayChart'], 0)) {
            carry.willDisplayCharts.push(el);
          }
        }

        return carry;
      },
      {
        columnTractionElasticityKey: [],
        elasticityMetrics: {},
        willDisplayCharts: [],
        fillSlot: {},
        extra: {},
      },
    );

    const groupBy = get(contextQuery, ['groupBy', 'columns'], get(contextQuery, ['groupBy', 'dimensions'], []));
    const drillDowns = get(contextQuery, ['groupBy', 'drillDowns'], []);
    const groupDimension =
      get(config, ['groupDimension'], null) && (!groupPeriod || groupPeriod == 'all')
        ? [].concat(get(config, ['groupDimension'], null))
        : [];
    const groupDimensionField = groupDimension.map((i) => get(mappings, [i, 'valueGetter', 'value'], null));

    if (!columnTractionElasticityKey.length || (groupBy.length && !drillDowns.length))
      return next(originData, { config, contextQuery, cellUpdate });

    const isSameDay = moment(dateRangeChart.dateFrom).isSame(moment(dateRangeChart.dateTo), 'day');

    const filterParams = getFilterParams(
      get(originData, ['rows'], []),
      eTablePrimaryKeys,
      maximumTractionRowsPerRequest,
    );

    // Request for traction data
    const requestTractionData = async function () {
      const isRequesting = Object.entries(pageRequests || {})
        .filter(([key]) => key != config.tableId)
        .some(([_, value]) => {
          return new Date().getTime() - value.createdAt < 30000 && value.status != 'DONE';
        });
      if (isRequesting) {
        setTimeout(() => {
          requestTractionData();
        }, 100);
      } else {
        for (const index in filterParams) {
          const groupedTractionElasticity = columnTractionElasticityKey.reduce((a, b) => {
            const isHourlyIn1Day = get(mappings, [b, 'staticValue', 'hourlyIn1Day'], 1) == 1;
            const period =
              isHourlyIn1Day && isSameDay
                ? 'hourly'
                : groupPeriod || get(mappings, [b, 'staticValue', 'period'], 'daily');
            const metrics = elasticityMetrics[b].map((el) => el.value);

            if (!willDisplayCharts.includes(b)) return a;

            if (!a[period]) {
              a[period] = [];
            }

            return {
              ...a,
              [period]: [...a[period], ...metrics].filter((el) => !!el),
            };
          }, {});

          const groupedTractionElasticityChartsParams = Object.keys(groupedTractionElasticity).map((period) => {
            const keys = eTablePrimaryKeys.concat(groupDimensionField);

            const params = {
              ...contextQuery,
              metrics: groupedTractionElasticity[period],
              pagination: {
                page: 1,
                limit: LIMIT_COUNT,
              },
              ...(groupDimension.length
                ? {
                    dimensions: eTablePrimaryKeys
                      .map((i) => String(i).split('.')?.[0])
                      .concat(groupDimensionField.map((i) => String(i).split('.')?.[0])),
                    attributes: keys,
                    groupPeriod: 'all',
                    groupBy: {
                      column: keys,
                      dimensions: keys,
                      aggregations: groupDimensionField
                        .map((i) => {
                          return {
                            field: i,
                            func: 'MAX',
                          };
                        })
                        .concat(
                          eTablePrimaryKeys.map((i) => {
                            return {
                              field: i,
                              func: 'MAX',
                            };
                          }),
                        ),
                    },
                  }
                : {
                    groupPeriod: period,
                    groupBy: undefined,
                  }),
              filter: {
                combinator: 'AND',
                filters: [
                  filterParams[index],
                  ...get(contextQuery, ['filter', 'filters'], []),
                  {
                    field: datetimeFieldRequest,
                    operator: '>=',
                    value: getQueryDatetimeValue(datetimeFieldRequest, dateRangeChart.dateFrom, 'start'),
                  },
                  {
                    field: datetimeFieldRequest,
                    operator: '<=',
                    value: getQueryDatetimeValue(datetimeFieldRequest, dateRangeChart.dateTo, 'end'),
                  },
                ],
              },
              _eip: {
                src: 'traction.period',
                from: Number(index) * maximumTractionRowsPerRequest,
              },
            };

            if (drillDowns.length) {
              params.filter = {
                combinator: 'AND',
                filters: [
                  ...get(params, ['filter', 'filters'], []),
                  ...drillDowns.map((drillDown) => ({
                    ...drillDown,
                    operator: '=',
                  })),
                ],
              };
            }

            return params;
          });

          groupedTractionElasticityChartsParams.forEach((params) => {
            const process = async (
              context: { columnTractionElasticityKey; elasticityMetrics; groupPeriod; fillSlot; extra },
              el,
            ) => {
              const { columnTractionElasticityKey, elasticityMetrics, fillSlot, extra } = context;
              let groupByField;

              groupByField = eTablePrimaryKeys;

              // if (Object.keys(masterDataPrimaryKey).length === 0) {
              //   groupByField = eTablePrimaryKeys;
              // } else if (masterDataPrimaryKey) {
              //   groupByField = Object.keys(masterDataPrimaryKey).reduce((a, b) => {
              //     return [...a, ...masterDataPrimaryKey[b].map((ele) => `${b}.${ele}`)];
              //   }, []);
              // }

              const { rows } = el.data;

              const separator = '|';

              const groupedRows = _groupBy(rows, (r) => {
                return groupByField.map((f) => r[f]).join(separator);
              });

              const updateData = Object.keys(groupedRows).reduce((carry, rowKey) => {
                const ids = String(rowKey).split(separator);
                const keys = groupByField.reduce((carry, f, index) => {
                  return { ...carry, [f]: ids[index] };
                }, {});

                const rows = groupedRows[rowKey];

                let updateCellByCol = columnTractionElasticityKey.reduce((carry, k) => {
                  return { ...carry, [k]: {} };
                }, {});

                for (const row of rows) {
                  updateCellByCol = columnTractionElasticityKey.reduce((carry, colKey, index) => {
                    const dimension = groupDimension.length
                      ? groupDimensionField.map((i) => row[i]).join(separator)
                      : row.datetime;
                    carry[colKey][dimension] = elasticityMetrics[colKey].reduce((a, b) => {
                      return {
                        ...a,
                        [b.value]: row[b.value],
                      };
                    }, {});
                    return carry;
                  }, updateCellByCol);
                }

                const { formatTime, periodUnit }: { formatTime: string; periodUnit: any } = (() => {
                  switch (context.groupPeriod) {
                    case 'hourly':
                      return { formatTime: 'MMM DD HH:mm', periodUnit: 'hour' };
                    case 'daily':
                      return { formatTime: 'MMM DD, YYYY', periodUnit: 'day' };
                    case 'weekly':
                      return { formatTime: '[W]W, MMM DD YYYY', periodUnit: 'week' };
                    case 'monthly':
                      return { formatTime: 'MMM YYYY', periodUnit: 'month' };
                    case 'quarterly':
                      return { formatTime: '[Q]Q YYYY', periodUnit: 'quarter' };
                    case 'yearly':
                      return { formatTime: '[Y]YYYY', periodUnit: 'year' };
                    default:
                      return { formatTime: 'MMM DD, YYYY', periodUnit: 'day' };
                  }
                })();

                columnTractionElasticityKey.forEach((colKey) => {
                  const labels = Object.keys(updateCellByCol[colKey]).sort();
                  let formatLabels = labels.map((label) =>
                    groupDimension.length
                      ? label
                      : context.groupPeriod === 'weekly'
                      ? moment(label).isoWeekday('Friday').format(formatTime)
                      : moment(label).format(formatTime),
                  );

                  let filledLabels = labels;
                  let dataValues = updateCellByCol[colKey];

                  if (
                    !groupDimension.length &&
                    (fillSlot[colKey] === 'yes' ||
                      (dateRangeChart.dateTo == dateRangeChart.dateFrom && periodUnit === 'hour'))
                  ) {
                    const hourlyFormat = 'YYYY-MM-DD HH[:00:00]';
                    const strtoMoment = periodUnit === 'hour' ? hourlyFormat : 'YYYY-MM-DD';
                    const isDatetime = isDatetimePicker && DATETIME_REGEX.test(dateRangeChart.dateFrom);
                    const startMoment = isDatetime
                      ? moment(dateRangeChart.dateFrom)
                      : periodUnit == 'week'
                      ? moment(dateRangeChart.dateFrom, strtoMoment).startOf(periodUnit).weekday(1).startOf('day')
                      : moment(dateRangeChart.dateFrom, strtoMoment).startOf(periodUnit).startOf('day');
                    const endMoment = isDatetime
                      ? moment(dateRangeChart.dateTo)
                      : periodUnit == 'week'
                      ? moment(dateRangeChart.dateTo, strtoMoment).startOf(periodUnit).weekday(1).endOf('day')
                      : moment(dateRangeChart.dateTo, strtoMoment).startOf(periodUnit).endOf('day');

                    const filledData = {};
                    const kvData = Object.entries(dataValues);
                    filledLabels = new Array(endMoment.diff(startMoment, periodUnit) + 1).fill(1).map((_, index) => {
                      const l = moment(startMoment)
                        .add(index, periodUnit)
                        .format(periodUnit === 'hour' ? hourlyFormat : 'YYYY-MM-DD');
                      filledData[l] = null;
                      return l;
                    });

                    // if (kvData.length === 1) {
                    //   // the data is the latest in day only
                    //   filledData[now] = kvData[0][1];
                    // } else if (isUTCBased(extra.queryDatetimeField)) {
                    kvData.forEach(([k, v]) => {
                      const kUTC = moment(k).format(strtoMoment);
                      filledData[kUTC] = v;
                    });
                    // } else {
                    // }

                    // const allIsEmpty = Object.keys(filledData).every((k) => !filledData[k]);
                    // if (!allIsEmpty) {
                    //   Object.keys(filledData).forEach((k) => {
                    //     if (filledData[k] === null) {
                    //       filledData[k] = 0.0039;
                    //     }
                    //   });
                    // }

                    dataValues = filledData;
                    formatLabels = filledLabels.map((l) => moment(l).format(formatTime));
                  }

                  const data = {
                    labels: formatLabels,
                    datasets: elasticityMetrics[colKey].map((field, index) => {
                      const isDisplayChart = get(extra, [colKey, 'serieFormat', field.key, 'displayChart'], 1) == 1;
                      const chartStyle = get(extra, [colKey, 'serieFormat', field.key, 'chartStyle'], 'line');
                      const chartMinConfig = get(mappings, [colKey, 'valueGetter', 'tractionRangeMin'], '');
                      const chartMaxConfig = get(mappings, [colKey, 'valueGetter', 'tractionRangeMax'], '');

                      const seriesValues = filledLabels
                        .map((label) => {
                          return get(dataValues, [label, field.value], null);
                        })
                        .filter((value) => value != undefined && value != null);

                      const minValue = seriesValues.length ? Math.min(...seriesValues) : null;
                      const maxValue = seriesValues.length ? Math.max(...seriesValues) : null;
                      const avgValue = seriesValues.length
                        ? seriesValues.reduce((a, b) => a + b, 0) / seriesValues.length
                        : null;

                      const rangeValue = {
                        ...rows?.[0],
                        min: minValue,
                        max: maxValue,
                        avg: avgValue,
                        ...(typeof field === 'object' ? field : {}),
                      };

                      let defaultMinValue = minValue;
                      if (minValue != null && minValue >= 0) defaultMinValue = 0;
                      const min = String(chartMinConfig).startsWith('=')
                        ? toValue(chartMinConfig, rangeValue)
                        : defaultMinValue;

                      const defaultMaxValue = maxValue ? maxValue * 1.05 : null;
                      const max = String(chartMaxConfig).startsWith('=')
                        ? toValue(chartMaxConfig, rangeValue)
                        : defaultMaxValue;

                      const serieData = filledLabels.map((label, index) => {
                        const yValue = get(dataValues, [label, field.value], null);

                        return {
                          x: formatLabels[index],
                          y: yValue,
                          tooltipLabel: toValue(get(extra, [colKey, 'serieFormat', field.key, 'chartTooltipFormat']), {
                            ...rows?.[0],
                            value: yValue,
                          }),
                          metric: field.value,
                          chartStyle,
                        };
                      });

                      const borderDash = get(extra, [colKey, 'serieFormat', field.key, 'lineDash'], '');

                      return {
                        label: field.key,
                        key: field.key,
                        data: serieData,
                        min,
                        max,
                        maxRaw: max,
                        order: get(extra, [colKey, 'serieFormat', field.key, 'order'], null) || 999 + index,
                        // data: filledLabels.map((label) => dataValues[label]),
                        backgroundColor: get(extra, [colKey, 'serieFormat', field.key, 'color'], '#204D77'),
                        borderColor: get(extra, [colKey, 'serieFormat', field.key, 'color'], '#204D77'),
                        borderDash: borderDash ? String(borderDash).split(',') : undefined,
                        borderWidth: isDisplayChart ? (chartStyle === 'bar' ? 0 : 2) : 0,
                        borderRadius: 3,
                        fill: false,
                        pointRadius: 0,
                        pointHoverRadius: isDisplayChart ? 3 : 0,
                        pointHitRadius: 15,
                        pointBorderWidth: 2,
                        pointStyle: 'rectRounded',
                        lineTension: get(extra, [colKey, 'serieFormat', field.key, 'lineTension'], null) || 0.39,
                        maxBarThickness: 20,
                        barPercentage: 1,
                        categoryPercentage: 0.8,
                        spanGaps: false,
                        parsing: {
                          xAxisKey: 'x',
                          yAxisKey: 'y',
                        },
                        type: chartStyle === 'area' ? 'line' : chartStyle,
                        chartStyle: isDisplayChart ? chartStyle : 'line',
                        yAxisID: get(extra, [colKey, 'serieFormat', field.key, 'yAxisID'], null) || field.key,
                        customMetric: get(extra, [colKey, 'serieFormat', field.key, 'customMetric'], null),
                      };
                    }),
                  };
                  const formulaColor = get(mappings, [colKey, 'valueGetter', 'chartColor'], '');

                  if (formulaColor?.startsWith('=')) {
                    const formulaValues = data.datasets?.reduce((a, b) => {
                      return {
                        ...a,
                        [b.key]: b.data.map(({ y }) => y),
                      };
                    }, {});
                    data.datasets.forEach((dataset) => {
                      if (dataset.chartStyle === 'bar') {
                        const backgroundColor = dataset.data.map((dt, i) => {
                          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                            return {
                              ...carry,
                              [key]: value[i],
                            };
                          }, {});

                          return toValue(formulaColor, {
                            value: dt.y,
                            ...formulaValue,
                          });
                        });
                        dataset.backgroundColor = backgroundColor;
                        dataset.borderColor = backgroundColor;
                        dataset.pointHoverBackgroundColor = backgroundColor;

                        const hoverBackgroundColor = dataset.data.map((dt, i) => {
                          const formulaValue = Object.entries(formulaValues).reduce((carry, [key, value]) => {
                            return {
                              ...carry,
                              [key]: value[i],
                            };
                          }, {});
                          return convertColor(
                            toValue(formulaColor, {
                              value: dt.y,
                              ...formulaValue,
                            }),
                          );
                        });
                        dataset.hoverBackgroundColor = hoverBackgroundColor;
                      }
                    });
                  }

                  carry.push({
                    keyId: rowKey,
                    keys,
                    updatePath: [colKey],
                    data: {
                      dataValues: data,
                      formatLabels: formatLabels,
                      chartPeriod: periodUnit,
                    },
                  });
                });

                return carry;
              }, []);

              return updateData as ETableDataUpdate[];
            };

            cellUpdate.addQuery({
              endpoint,
              params: omit(params, ['from', 'to']),
              columnEffects: columnTractionElasticityKey,
              process: process.bind(null, {
                columnTractionElasticityKey: columnTractionElasticityKey,
                elasticityMetrics,
                groupPeriod: params.groupPeriod,
                fillSlot: fillSlot,
                extra: { ...extraInfo, queryDatetimeField: datetimeFieldRequest },
              }),
            });
          });

          const customFields = [];

          if (visualizationType == 'table') {
            const groupedTractionElasticityCohort = columnTractionElasticityKey.reduce((a, b) => {
              const period = get(config, ['personalization', 'tractionPeriod', b, 'value'], 'last_1_days');
              const metrics = elasticityMetrics[b].map((el) => el.value);

              if (!a[period]) {
                a[period] = [];
              }

              if (period == 'custom') {
                customFields.push(b);
              }

              return {
                ...a,
                [period]: [...a[period], ...metrics].filter((el) => !!el),
              };
            }, {});

            const groupedTractionElasticityCohortParams = Object.keys(groupedTractionElasticityCohort).map((period) => {
              const customValue = get(config, ['personalization', 'tractionPeriod', customFields[0]], null);
              const format =
                DATETIME_REGEX.test(dateRangeCohort.dateFrom) &&
                (!period || 'the_previous' === period || period?.startsWith('last')) &&
                isDatetimePicker
                  ? 'YYYY-MM-DD HH:mm'
                  : 'YYYY-MM-DD';
              const { dateFrom, dateTo } = customValue?.customValue
                ? customValue.customValue
                : getDateRangeFromOption(
                    period,
                    {
                      dateFrom: dateRangeCohort.dateFrom,
                      dateTo: dateRangeCohort.dateTo,
                    },
                    format,
                  );

              const params = {
                ...contextQuery,
                metrics: groupedTractionElasticityCohort[period],
                pagination: {
                  page: 1,
                  limit: LIMIT_COUNT,
                },
                groupBy: undefined,
                isSummary: true,
                filter: {
                  combinator: 'AND',
                  filters: [
                    filterParams[index],
                    ...get(contextQuery, ['filter', 'filters'], []),
                    {
                      field: datetimeFieldRequest,
                      operator: '>=',
                      value: getQueryDatetimeValue(datetimeFieldRequest, dateFrom, 'start'),
                    },
                    {
                      field: datetimeFieldRequest,
                      operator: '<=',
                      value: getQueryDatetimeValue(datetimeFieldRequest, dateTo, 'end'),
                    },
                  ],
                },
                from: dateFrom,
                to: dateTo,
                _eip: {
                  src: 'traction.total',
                  from: Number(index) * maximumTractionRowsPerRequest,
                },
              };

              if (drillDowns.length) {
                params.filter = {
                  combinator: 'AND',
                  filters: [
                    ...get(params, ['filter', 'filters'], []),
                    ...drillDowns.map((drillDown) => ({
                      ...drillDown,
                      operator: '=',
                    })),
                  ],
                };
              }

              return { ...params, period };
            });

            groupedTractionElasticityCohortParams.forEach((params) => {
              const process = async (
                context: { columnTractionElasticityKey; elasticityMetrics; extra; dateRange },
                el,
              ) => {
                const { columnTractionElasticityKey, elasticityMetrics, extra, dateRange } = context;
                const groupByField = eTablePrimaryKeys;
                // const masterDataPrimaryKey = get(el.data, ['masterDataPrimaryKey'], null);
                // if (masterDataPrimaryKey) {
                //   groupByField = Object.keys(masterDataPrimaryKey).reduce((a, b) => {
                //     return [...a, ...masterDataPrimaryKey[b].map((ele) => `${b}.${ele}`)];
                //   }, []);
                // }

                const { rows } = el.data;

                const separator = '|';

                const groupedRows = _groupBy(rows, (r) => {
                  return groupByField.map((f) => r[f]).join(separator);
                });

                const updateData = Object.keys(groupedRows).reduce((carry, rowKey) => {
                  const ids = String(rowKey).split(separator);
                  const keys = groupByField.reduce((carry1, f, index) => {
                    return { ...carry1, [f]: ids[index] };
                  }, {});

                  const row = first(groupedRows[rowKey]);

                  let updateCellByCol = zipObject(
                    columnTractionElasticityKey,
                    Array(columnTractionElasticityKey.length).fill({}),
                  );

                  if (row) {
                    updateCellByCol = columnTractionElasticityKey.reduce((carry, colKey) => {
                      carry[colKey] = elasticityMetrics[colKey].reduce((a, b) => {
                        return {
                          ...a,
                          [b.value]: row[b.value],
                        };
                      }, {});
                      return carry;
                    }, updateCellByCol);
                  }

                  columnTractionElasticityKey.forEach((colKey) => {
                    carry.push({
                      keyId: rowKey,
                      keys,
                      updatePath: [colKey],
                      data: {
                        cohortValues: elasticityMetrics[colKey]
                          .map((field) => {
                            return {
                              cohortValue: get(updateCellByCol, [colKey, field.value], null),
                              cohortTooltipLabel: toValue(
                                get(
                                  extra,
                                  [colKey, 'serieFormat', field.key, 'chartTooltipFormat'],
                                  extra[colKey].chartTooltipFormat,
                                ),
                                {
                                  value: updateCellByCol[colKey]?.[field.value],
                                },
                              ),
                              cohortDateRange: dateRange,
                              metric: field.value,
                              color: get(extra, [colKey, 'serieFormat', field.key, 'color'], '#204D77'),
                              customMetric: get(extra, [colKey, 'serieFormat', field.key, 'customMetric'], null),
                            };
                          })
                          .reverse(),
                      },
                    });
                  });

                  return carry;
                }, []);

                return updateData as ETableDataUpdate[];
              };

              cellUpdate.addQuery({
                endpoint,
                params: omit(params, ['period', 'from', 'to']), // invalid query params
                columnEffects: columnTractionElasticityKey,
                process: process.bind(null, {
                  columnTractionElasticityKey,
                  elasticityMetrics,
                  extra: extraInfo,
                  dateRange: dateRangeCohort,
                }),
              });
            });
          }

          cellUpdate.complete();

          await new Promise((resolve) => {
            setTimeout(
              () => {
                resolve(true);
              },
              Number(index) === 0 ? 2000 : 500,
            );
          });
        }
      }
    };

    requestTractionData();

    const { columns, data } = await next(originData, { config, contextQuery, cellUpdate, pageRequests });
    const resourceMetric = await getMetricDefinition(endpoint);

    const nuColumns = columns.map((c) => {
      if (columnTractionElasticityKey.indexOf(c.field) === -1) {
        return c;
      }
      const metrics = elasticityMetrics[c.field];

      return produce(c, (draft) => {
        set(draft, 'cell.valueGetter.metricDetail', () => {
          return metrics.reduce((carry, metric) => {
            const foundMetricDef = resourceMetric.find((m) => metric.value == m.value);

            return {
              ...carry,
              [metric.value]: foundMetricDef,
            };
          }, {});
        });
      });
    });

    return { columns: nuColumns, data };
  };
}

/**
 * TODO:
 * * conduct loading traction metric based on masterDataPrimaryKey
 * * lazyload, push note from service worker to traction cell
 * * fail tolerance, support reloading on failure cell
 */
const tractionElasticityTable1 = makeTractionElasticityColumn('tractionElasticity');

export { tractionElasticityTable1 };
