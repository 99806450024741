import { useLog } from '@eip/next/lib/main';
import { get } from 'lodash';
import { nanoid } from 'nanoid';
import { v4 as uuid } from 'uuid';
import * as dataSource from '../../data-source/programmatic-script';
import { createDataQueryFromConfig } from '../../table-backbone/table-datasource';

const log = useLog('lib:workflow-backbone:programmatic-script');

const validation = {
  total_budget: `ev_budget_is_no_limit(#_.total_budget)
  OR ev_shop_budget_min(#_.shop_eid, "Budget should above {min_budget}");`,
  daily_budget: `ev_budget_is_no_limit(#_.daily_budget)
  OR ev_shop_budget_min(#_.shop_eid, "Budget should above {min_budget}");`,
  campaign_name: `ev_required(#_.campaign_name, "Campaign name is required.")
  AND ev_no_duplicate_campaign_name(#_.campaign_name, #_.shop_eid, "Campaign name is duplicated.");`,
  schedule_start_date: `ev_cond(#_.schedule_start_date > time("now"), "Start date is invalid");`,
};

function screenInfo(data: typeof dataSource, tableConfig) {
  const COMPACT_PROGRAMMATIC_KEYWORD_CONFIG = tableConfig.COMPACT_KEYWORD_OPTIMIZATION_CONFIG;
  const COMPACT_PROGRAMMATIC_OBJECT_CONFIG = tableConfig.COMPACT_PROGRAMMATIC_OBJECT_CONFIG;
  const screen = {
    screenId: 'screenInfo',
    inputOnDemand: {
      __init__: async () => {
        const marketplace = await data.getMarketplace();
        const country = await data.getCountry();
        const storefront = await data.getStorefront();
        const adType = await data.getAdType();
        const adTool = await data.scriptGetAdTool();
        const scriptTarget = await data.getScriptTarget();
        let interval;
        if (ff.script_interval) {
          interval = await data.getInterval();
        }
        return {
          marketplace: marketplace.data,
          country: country.data,
          storefront: storefront.data,
          adType: adType.data,
          adTool: adTool.data,
          scriptTarget: scriptTarget.data,
          programmaticObject: [],
          existingScript: [],
          ...(ff.script_interval ? { interval: interval?.data } : {}),
        };
      },
      getMarketplace: data.getMarketplace,
      getCountry: data.getCountry,
      getStorefront: data.getStorefront,
      getAdType: data.getAdType,
      getScriptTarget: data.getScriptTarget,
      getAdTool: data.scriptGetAdTool,
      ...(ff.script_interval ? { getInterval: data.getInterval } : {}),
      getCompactTableConfiguration: async ({ scriptTarget, storefront, marketplace }) => {
        log('compactableconfiguration', { scriptTarget, storefront });
        const isKeywordFocus = scriptTarget === 'ADS_PLACEMENT';
        return {
          programmaticObject: {
            displayField: isKeywordFocus ? 'ADS_PLACEMENT.name' : 'ADS_OBJECT.name', // 'ADS_OBJECT.name',
            idField: isKeywordFocus ? 'ADS_PLACEMENT.id' : 'ADS_OBJECT.id',
            config: {
              id: 'compact_table_' + storefront,
              configuration: {
                ...(isKeywordFocus ? COMPACT_PROGRAMMATIC_KEYWORD_CONFIG : COMPACT_PROGRAMMATIC_OBJECT_CONFIG),
                filter: [
                  {
                    type: 'filter',
                    logicalOperator: 'and',
                    field: 'marketplace',
                    dataType: 'string',
                    static: true,
                    queryValue: marketplace || 'SHOPEE',
                    queryType: 'is',
                  },
                ],
                hiddenFilter: {
                  combinator: 'AND',
                  filters: [
                    ...(ff.add_script_compact_table_properties
                      ? []
                      : [
                          {
                            field: isKeywordFocus ? 'ADS_PLACEMENT.status' : 'ADS_OBJECT.status',
                            dataType: 'string',
                            operator: 'is_not',
                            value: 'ENDED',
                          },
                        ]),
                    {
                      field: 'STOREFRONT.id',
                      dataType: 'integer',
                      operator: '=',
                      value: storefront,
                    },
                    // {
                    //   field: 'MARKETPLACE.marketplace_code',
                    //   dataType: 'string',
                    //   operator: 'is',
                    //   value: marketplace || 'SHOPEE',
                    // },
                  ],
                },
              },
              tableType: 'compact',
            },
          },
        };
      },
      getProgrammaticObject: async ({
        scriptTarget,
        objectIds,
        marketplace = 'SHOPEE',
      }: {
        scriptTarget: string;
        objectIds: string[];
        marketplace: string;
      }) => {
        const isKeywordFocus = scriptTarget === 'ADS_PLACEMENT';

        let config: any = isKeywordFocus ? COMPACT_PROGRAMMATIC_KEYWORD_CONFIG : COMPACT_PROGRAMMATIC_OBJECT_CONFIG;
        config = {
          ...config,
          defaultPagination: {
            limit: -1,
          },
          requestIgnoreField: {
            metric: ['STOREFRONT.currency'],
          },
        };
        return createDataQueryFromConfig({
          config: config,
        })
          .query({
            filter: [
              {
                type: 'filter',
                logicalOperator: 'and',
                field: 'MARKETPLACE.marketplace_code',
                dataType: 'string',
                static: true,
                queryValue: marketplace || 'SHOPEE',
                queryType: 'is',
              },
            ],
            hiddenFilter: {
              combinator: 'AND',
              filters: [
                {
                  field: isKeywordFocus ? 'ADS_PLACEMENT.id' : 'ADS_OBJECT.id',
                  dataType: 'integer',
                  operator: 'in',
                  value: objectIds.map((i) => Number(i)),
                },
                // {
                //   field: 'MARKETPLACE.marketplace_code',
                //   dataType: 'string',
                //   operator: 'is',
                //   value: marketplace,
                // },
              ],
            },
          })
          .then((r: any) => {
            console.info('programmatic script details', r.rowData);
            return r.rowData;
          })
          .catch((error) => {
            throw error;
          });
      },
      getStorefrontScripts: data.getStorefrontScripts,
    },
    validation: validation,
  };

  return screen as WorkflowRegisterScreen<(typeof screen)['inputOnDemand']>;
}
export type ScreenInfoRegister = ReturnType<typeof screenInfo>;

function screenScenario_0(data: typeof dataSource) {
  const screen = {
    screenId: 'screenScenario_0',
    inputOnDemand: {
      __init__: async (workflow?: Workflow) => {
        const { scriptTarget, adType, adTool, storefront } = workflow.data.get(['screenInfo'], {
          scriptTarget: null,
          adType: null,
          adTool: null,
          storefront: null,
        });
        const targetObject = await data.getScriptTarget();
        return {
          attribute: await data.conditionOptions.getAttributeOptions({
            scriptTargetId: scriptTarget,
            adTypeId: adType,
            adToolId: adTool,
            storefrontId: storefront,
          }),
          targetObject: targetObject.data,
          timeInclusive: await data.conditionOptions.getTimeInclusiveOptions(),
          timeQuery: await data.conditionOptions.getTimeQueryOptions({}),
          timeUnit: await data.conditionOptions.getTimeUnitOptions({}),
          conditionOperator: await data.conditionOptions.getConditionOperatorOptions({}),
          actionUnit: await data.conditionOptions.getActionUnit({}),
          actionObject: await data.conditionOptions.getActionObject({}),
          actionType: await data.conditionOptions.getActionType({}),
        };
      },
      getTargetObjectOptions: () => data.getScriptTarget(),
      ...data.conditionOptions,
      getAttributeOptions: ({ scriptTargetId, storefrontId, adTypeId, adToolId }) => {
        return data.conditionOptions.getAttributeOptions({
          scriptTargetId: scriptTargetId,
          adTypeId: adTypeId,
          adToolId: adToolId,
          storefrontId: storefrontId,
        });
      },
    },

    validation: validation,
  };

  return screen as WorkflowRegisterScreen<(typeof screen)['inputOnDemand']>;
}

export type ScreenScenarioRegister = ReturnType<typeof screenScenario_0>;

export function createRegister(tableConfig, data = dataSource, workflowId = ''): WorkflowRegister {
  const _screenInfo = screenInfo(data, tableConfig);
  const _screenScenario = screenScenario_0(data);

  async function fromDraft(parentId, entityId, templatePageId) {
    let draftId = entityId;
    return {
      entityId: 'default',
      blockId: 'unknown',
    };
    if (draftId) {
      // load draft 1) all correct 2) incorrect blockId
      return { entityId, blockId: templatePageId };
    } else {
      draftId = 'draft' + nanoid();
      const blockId = uuid();
      // create new draft
      return data.createUserDraft({ parentId, entityId: draftId, blockId, templatePageId }).then(async () => {
        await dataSource.updateUserMenu({
          parentId: parentId,
          blockId: blockId,
        });
        return {
          entityId: draftId,
          blockId: blockId,
        };
      });
    }
  }

  const RuleDetailsWorkflowRegister: WorkflowRegister = {
    input: {},
    onSubmit: async (data: WorkflowSubmitDataScript, options?: { operation: 'UPDATE' | 'CREATE' }) => {
      log('submit data', data);
      const result = await dataSource.createScript(data, options.operation);
      return {
        entityId: result.scriptId,
        marketplace: result.marketplace,
        type: get(data, ['screenInfo', 'scriptTarget']),
      };
    },
    init: async (input: { entityId?: string; parentId?: string; pageId?: string; context?: Record<string, any> }) => {
      if (input.entityId && input.entityId.indexOf('default') !== 0) {
        const scriptType = get(input, 'context.type');
        const marketplace = get(input, 'context.marketplace', 'SHOPEE');
        return dataSource
          .loadScript({ entityId: input.entityId, objectTypeId: scriptType, marketplace }, tableConfig)
          .then((script) => {
            return { ...script, title: script.screenInfo.scriptName };
          });
      } else {
        const splitted = window.location.href.split('?');
        const queryParams: any = (splitted[splitted.length - 1] || '').split('&').reduce((a, b) => {
          const [key, value] = b.split('=');
          return {
            ...a,
            [key]: value,
          };
        }, {});
        const cloneId = get(input, 'context.cloneId', get(queryParams, 'cloneId', null));
        const marketplace = get(input, 'context.marketplace', get(queryParams, 'marketplace', null));
        const type = get(input, 'context.type', get(queryParams, 'type', null));
        if (cloneId && marketplace) {
          return dataSource
            .loadScript({ entityId: cloneId, marketplace, objectTypeId: type }, tableConfig)
            .then((script) => {
              return {
                ...script,
                title: script.screenInfo.scriptName,
                screenInfo: {
                  ...script.screenInfo,
                  scriptId: null,
                  scriptStatus: null,
                },
              };
            });
        }
        return { entityId: 'default' };
      }
    },
    screens: { screenInfo: _screenInfo, screenScenario_0: _screenScenario },
  };

  return RuleDetailsWorkflowRegister;
}
