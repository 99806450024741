import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Reload = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.49932 3.99993C9.77501 3.99993 10.0006 3.77437 10.0006 3.49868V0.499517C10.0006 0.0567432 9.46591 -0.168821 9.14845 0.14864L8.16548 1.13161C7.26848 0.398154 6.11047 -0.0319464 4.85437 0.00661806C2.19773 0.081806 0.0339878 2.27061 0.000570944 4.92725C-0.0412002 7.71756 2.21444 9.99826 4.99639 9.99826C7.32722 9.99826 9.28211 8.40261 9.84184 6.24722C10.0006 5.6123 9.52438 4.99409 8.87275 4.99409C8.42163 4.99409 8.02062 5.29484 7.90366 5.73761C7.57785 7.04087 6.3999 8.00161 4.99639 8.00161C3.26707 8.00161 1.88027 6.53962 2.00558 4.78523C2.10583 3.31489 3.30049 2.12024 4.77083 2.01163C5.50688 1.9554 6.18976 2.16813 6.7372 2.55989L6.14928 3.1478C5.83182 3.45691 6.05738 3.99993 6.50016 3.99993H9.49932Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Reload;
