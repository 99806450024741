import * as React from 'react';
import { get } from 'lodash';

import { Button, makeStyles } from '@material-ui/core';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

import Icon from '@ep/insight-ui/icons/Icon';
import VerfiyOTPForm from '@ep/insight-ui/elements/new-storefront/verify-otp-form';
import { useRequestOtp, useVerifyOtp } from '@ep/insight-ui/elements/new-storefront/hooks/use-verify-otp';

import { IPropsFormat } from './wrapper-format';

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0.5px 8px',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  stamp: {
    background: ' #EDF2F9',
    alignItems: 'center',
    justifyContent: 'center',
    '& .eip1-MuiChip-label': {
      //   padding: '',
      fontStyle: ' normal',
      fontWeight: 500,
      fontSize: ' 14px',
      lineHeight: ' 20px',
    },
  },
  height100: {
    height: '100%',
  },
  btnAction: {
    '& .eip1-MuiButton-label': {
      display: 'flex',
      alignItems: 'center',
      columnGap: '8px',
    },
  },
}));

export type IValueActionFormat = {
  value: string;
};

const ActionNeededFormat = (props: IPropsFormat<IValueActionFormat>) => {
  const classes = useStyles();

  const value = get(props, ['value', 'value'], null);
  const marketplaceName = get(props, ['value', 'marketplaceName'], '');
  const needRefreshCookie = get(props, ['value', 'cookieState'], 2);
  const backbone = React.useContext(TableBackboneContext);

  const { needOtp, requestOtp, open, setOpen } = useRequestOtp({
    storefrontId: value,
  });

  if (needRefreshCookie === 2) return null;

  return (
    <div className={classes.container}>
      <Button className={classes.btnAction} onClick={requestOtp}>
        <span>
          <Icon type={'reload'} />
        </span>
        <span>{'Reconnect'}</span>
      </Button>
      <VerfiyOTPForm
        open={open}
        onClose={() => setOpen(false)}
        storefrontId={value}
        marketplaceName={marketplaceName}
        backbone={backbone}
        initialStep={needOtp ? 1 : 2}
      />
    </div>
  );
};

export default ActionNeededFormat;
