import { API_URL } from '@ep/one/global';

export const ONE_EP = {
  CHECK_EXIST_SUBCRIPTION_CODE: () =>
    `${API_URL}/api/v1/user/verify-subscription-code`,
  GET_SHOPS: () => `${API_URL}/api/v1/user/shops`,
  GET_COUNTRIES: () => `${API_URL}/api/v1/common/channels-countries`,
  LINK_SHOP: () => `${API_URL}/api/v1/user/link-shop`,
  GET_SHOP_DETAILS: () => `${API_URL}/api/v1/user/shop-detail`,
  GET_AVAIL_COMPANIES: () => `${API_URL}/api/v1/user/company-list`,
  GET_USERS_FOR_ALLOCATE: () =>
    `${API_URL}/api/v1/user/users-for-allocate`,
  GET_SHOP_FEATURES: () => `${API_URL}/api/v1/common/features`,
  GET_SHOP_FEATURES_PERMISSONS: () => `${API_URL}/api/v1/user/acl`,
  GET_SHOP_PERMISSIONS: () =>
    `${API_URL}/api/v1/common/permission-list-for-shop`,
  GET_USER_PERMISSIONS_ALL_SHOP: () =>
    `${API_URL}/api/v1/user/acl-for-all-shops`,
  POST_USER_PERMISSION: () => `${API_URL}/api/v1/user/acl`,
  ALLOCATE_SHOP_MEMBER: () =>
    `${API_URL}/api/v1/user/allocate-user-shop`,
  GET_USER_PROFILE: () => `${API_URL}/api/v1/user/profile`,
  USER_LOGOUT: () => `${API_URL}/api/v1/logout`,
  USER_REMOVE_ALLOCATE: () =>
    `${API_URL}/api/v1/user/remove-alloc-user-shop`,
  GET_REQUEST_DASHBOARD_DOWNLOAD: () =>
    `${API_URL}/api/v1/dashboard/download`,
  GET_CHECK_VALID_DASHBOARD_FILE: () =>
    `${API_URL}/api/v1/dashboard/check-file`,
  POST_DOWNLOAD_DASHBOARD_FILE: () =>
    `${API_URL}/api/v1/dashboard/get-file`,
};
