import moment from 'moment';
import { addonMiddleware, PLACE_HOLDER } from '@ep/insight-ui/system/util/addon-middleware';
import produce from 'immer';
import { cloneDeep, first, flatten, get, groupBy, isFunction, omit, omitBy, range, set, uniq } from 'lodash';
import { nanoid } from 'nanoid';
import { getChannelCellUpdate, getChannelUpstreamCalculate } from '../channel';
import { EIP_CONSTANT } from '../constant';
import { useLog } from '../log';
import { stripUniversalPrefix } from '../util/column';
import { isFormulaField, toValue } from '../util/excel-formula';
import { pivotTable } from './addon/pivot';
import { pivotTableNext, pivotBuild } from './addon/pivot-next';
import { tractionTable } from './addon/traction-multi-columns';
import { makeTractionElasticityColumn, tractionElasticityTable1 } from './addon/traction-elasticity1';
import * as swRequest from '@ep/insight-ui/sw/util/request';
import { enhanceChartDataRequest } from './data/chart-enhancer';
import { enhanceDataRequest2 } from './data/enhancer';
import { FormulaUpstream } from './formula-upstream';
import { makeOriginRequest } from './origin-request';
import { PushCellUpdate } from './push-cell-update';
import { calculateConditionFormat } from './row-enhance';
import { CONDITION_FORMAT_PATH } from '@ep/insight-ui/system/helper/constant';
import { cohortColumn } from './addon/cohort-column';
import { pivotMetric } from './addon/pivot-metric';
import { calculateSecondaryMetricGroupBy } from './addon/secondary-metric-calculator';
import { enhanceMarketIndexMetric } from './addon/enhance-market-index-metric';

const console = useLog('eTable');
type fetchFun = (request: RequestInfo) => Promise<Response>;

type eTableRequest = {
  eTableConfig: Record<string, any>;
  url: string;
  method: string;
  headers: Headers;
  body: Record<string, any>;
};

const VISUALIZATION_TYPE = {
  chart: 'chart',
  table: 'table',
};

function isChart(config) {
  return config.visualizationType === VISUALIZATION_TYPE.chart;
}

const retryBadges: Record<string, { payload: any[]; createdAt: number }> = {};

// this retry does not support multiple different retryQuery for different columns groupped.
async function handleRetry({ token, tableId }) {
  if (!retryBadges[token]) return;

  const pushCellUpdate = new PushCellUpdate((err: Error, results, retryQuery) => {
    delete retryBadges[token];
    const channel = getChannelCellUpdate(tableId);
    const columnEffects = uniq(flatten(retryQuery.map((i) => i.columnEffects)));
    const rowEffects = uniq(flatten(retryQuery.map((i) => i.rowEffects)));

    if (!err) {
      channel.postMessage({
        type: 'pushCellUpdate',
        update: results,
        error: null,
        extra: { columnEffects, rowEffects },
      });
    } else {
      console.info('fail with...', retryQuery);
      const retryToken = nanoid();
      retryBadges[retryToken] = { payload: retryQuery, createdAt: Date.now() };

      channel.postMessage({
        update: null,
        error: err.message,
        extra: { token: retryToken, columnEffects, rowEffects },
      });
    }
  });

  get(retryBadges, [token, 'payload'], []).forEach((item) => {
    pushCellUpdate.addQuery({
      ...item,
      endpoint: 'https://datacenter-auth0.epsilo.io/v2/query.jsp?namespace=universal_market_index',
    });
  });

  pushCellUpdate.complete();
}

const pageRequests = {};

export async function handle(fetch: fetchFun, request: eTableRequest): Promise<Response> {
  const pageId = request.headers.get('x-user-page');
  const tableId = request.eTableConfig.tableId;
  try {
    const { url, body } = request;
    if (url.includes('/_eip_retry/etable')) {
      await handleRetry(body as { token; tableId });
      return new Response(JSON.stringify({ success: 1 }), { status: 200, statusText: 'OK' });
    }
    const response = await handleRaw(fetch, request);

    pageRequests[pageId][tableId] = {
      status: 'DONE',
      createdAt: new Date().getTime(),
    };

    return new Response(JSON.stringify(response));
  } catch (error) {
    const config = request.eTableConfig;
    const columns = produceColumns(config);
    const rows = await calculateValueGetter({
      rows: [],
      columns,
      drillDowns: get(request.body, 'drillDowns', []),
      resourceMetric: [],
      groupedFields: [],
      formulaUpstream: null,
      config,
    });
    console.error(error);
    pageRequests[pageId][tableId] = {
      status: 'DONE',
      createdAt: new Date().getTime(),
    };
    return new Response(
      JSON.stringify({
        data: {
          rows: rows,
          headers: [],
        },
        error: {
          message: error.message,
          name: error.name,
          code: error.code,
        },
        eTableContext: {
          columns: columns,
          pinnedColumn: config.pinnedColumn,
          groupBy: config.groupBy,
        },
      }),
      {
        status: 509,
        statusText: 'SW_ERROR',
      },
    );
  }
}

async function handleRaw(fetch: fetchFun, request: eTableRequest) {
  const _config = request.eTableConfig;
  const traceparent = request.headers.get('traceparent');
  const tableEndpoint = get(_config, ['endpoint', 'GET_TABLE_DATA'], '');
  const config = set(
    cloneDeep(_config),
    ['endpoint', 'GET_TABLE_DATA'],
    tableEndpoint + (String(tableEndpoint).includes('?') ? '&' : '?') + '_eiptraceparent=' + traceparent,
  );
  const tableId = config.tableId;
  console.info('@eTable / config', config);

  let newUrl = request.url;
  newUrl =
    newUrl +
    (newUrl.includes('?') ? '&' : '?') +
    '_eipTitle=' +
    config?.title +
    '&_eiptraceparent=' +
    request.headers.get('traceparent');
  const body = omit(request.body, ['@eTableConfig']);

  let pData = { data: { rows: [], headers: [] }, queryParams: null as any };
  let eTableSummary = {};

  const originRequest = makeOriginRequest(newUrl).getTableData;

  if (newUrl.includes('export.jsp')) {
    const response = await swRequest.post(newUrl, body);
    return response;
  }

  const pageId = request.headers.get('x-user-page');
  if (!pageRequests[pageId]) {
    pageRequests[pageId] = {
      [tableId]: {
        status: 'LOADING',
        createdAt: new Date().getTime(),
      },
    };
  } else {
    pageRequests[pageId][tableId] = {
      status: 'LOADING',
      createdAt: new Date().getTime(),
    };
  }
  let transformedParams = cloneDeep(body);

  if (isChart(config)) {
    const chartConfig = produce(config, (draft) => {
      Object.entries(config.mapping).forEach(([key, value]: [string, any]) => {
        draft.mapping[key].valueGetter = omitBy(value.valueGetter, (value, k) => k.startsWith('aggregate.'));
      });
    });
    pData = await enhanceChartDataRequest(
      cloneDeep(
        produce(body, (draft) => {
          draft.pagination = undefined;
        }),
      ),
      originRequest,
      { config: chartConfig },
    );
    eTableSummary = {
      title: get(config, 'chartConfig.config.title', ''),
      title2: get(config, 'chartConfig.config.title2', ''),
      title3: get(config, 'chartConfig.config.title3', ''),
    };
    handleChartTotal(pData, config, request);
  } else {
    const result = await enhanceDataRequest2(body, originRequest, { config });
    pData = result.data;
    transformedParams = result.params;
  }

  const pushCellUpdates = range(0, 2).map((i) => {
    return new PushCellUpdate((err: Error, results, retryQuery) => {
      const channel = getChannelCellUpdate(tableId);
      if (!err) {
        channel.postMessage({
          update: results,
          error: null,
        });
      } else {
        console.info('fail with...', retryQuery);
        const retryToken = nanoid();
        retryBadges[retryToken] = { payload: retryQuery, createdAt: Date.now() };

        channel.postMessage({
          update: null,
          error: err.message,
          extra: {
            token: retryToken,
            columnEffects: uniq(flatten(retryQuery.map((i) => i.columnEffects))),
            rowEffects: uniq(flatten(retryQuery.map((i) => i.rowEffects))),
          },
        });
      }
    });
  });

  const { data, columns } = await enhanceTable(pData, {
    config,
    contextQuery: body,
    cellUpdate: pushCellUpdates,
    requestHeaders: request.headers,
    pageRequests: pageRequests[pageId],
    transformedParams: transformedParams,
  });

  pushCellUpdates.forEach((i) => i.complete());

  let rows = get(data, 'data.rows', []);

  const tableCalculationUpstream = new FormulaUpstream(body, originRequest, (results) => {
    console.info('table ucal...', results);

    const groupedByRow = results.reduce((carry, i) => {
      const key = JSON.stringify(get(i, 'context._eTable.rowIds', null));
      const updateItem = {
        keys: get(i, 'context._eTable.rowIds'),
        updatePath: [i.context._eTable.field, i.context._eTable.valueGetterField],
        data: i.result,
      };
      if (!carry[key]) {
        carry[key] = [].concat(updateItem);
      } else {
        carry[key].push(updateItem);
      }

      return carry;
    }, {});

    const channel = getChannelCellUpdate(tableId);
    channel.postMessage({
      update: groupedByRow,
    });
  });

  rows = await calculateValueGetter({
    rows,
    columns,
    groupedFields: get(body, 'groupBy.dimensions', []),
    drillDowns: get(body, 'groupBy.drillDowns', []),
    resourceMetric: get(data, 'data.resourceMetric', []),
    formulaUpstream: tableCalculationUpstream,
    config,
  });

  rows = calculateConditionFormat({
    rows,
    conditionFormat: get(config, CONDITION_FORMAT_PATH, {}),
    columns,
  });

  const summaryFormulaUpstream = new FormulaUpstream(body, originRequest, (results) => {
    console.info('upstream summary', results);
    const channel = getChannelUpstreamCalculate();
    setTimeout(() => {
      for (const { id, result } of results) {
        console.info('upstream pointer', id, result);
        channel.postMessage({
          id,
          result,
        });
      }
    }, 539);
  });
  const summary = await calculateSummary(rows, eTableSummary, summaryFormulaUpstream);

  set(data, 'data.rows', rows);
  set(data, 'data.summary', summary);

  console.info('@eTable / columns', columns);
  console.info('@eTable / rows', rows);
  console.info('@eTable / rows', config.pinnedColumn);

  const context = {
    columns: columns.map((i1) => {
      return produce(i1, (i) => {
        Object.keys(i1.cell.valueGetter).forEach((k) => {
          if (typeof i.cell.valueGetter[k] === 'function') {
            i.cell.valueGetter[k] = 'serviceCalculated';
          }
        });
      });
    }),
    pinnedColumn: config.pinnedColumn,
    groupBy: {
      ...config.groupBy,
      aggregations: get(body, ['groupBy', 'aggregations'], []).reduce((a, b) => {
        return {
          ...a,
          [b.field]: {
            func: b.func,
          },
        };
      }, {}),
    },
  };

  summaryFormulaUpstream.complete();
  tableCalculationUpstream.complete();

  console.info('@eTable / context', context);

  // generate type for the the following function
  const { data: finalData, context: finalContext } = await pivotBuild({ data, context, config });

  // pageRequests[pageId][tableId] = {
  //   status: 'DONE',
  //   createdAt: new Date().getTime(),
  // };

  return {
    ...finalData,
    eTableContext: finalContext,
  };
}

async function enhanceTable(
  originData,
  { config, contextQuery, cellUpdate, requestHeaders, pageRequests, transformedParams },
): Promise<{ columns: any[]; data: any[] }> {
  if (isChart(config)) {
    return {
      data: originData,
      columns: produceColumns(config),
    };
  }

  const tractionElasticityColumn1 = makeTractionElasticityColumn('tractionElasticity1');

  const result = await addonMiddleware(
    async (data, { config, contextQuery }) => {
      const columns = produceColumns(config);
      return {
        data,
        columns: columns,
      };
    },
    [
      tractionElasticityColumn1,
      [
        originData,
        {
          config,
          contextQuery: {
            ...contextQuery,
            dateRangeChart: {
              dateFrom: moment(contextQuery.from, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              dateTo: moment(contextQuery.to, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            },
            dateRangeCohort: {
              dateFrom: contextQuery.from,
              dateTo: contextQuery.to,
            },
          },
          cellUpdate: cellUpdate[1],
          pageRequests,
        },
      ],
    ],
    tractionElasticityTable1,
    calculateSecondaryMetricGroupBy,
    tractionTable,
    cohortColumn(handleRaw, { headers: requestHeaders }),
    pivotTableNext(handleRaw, { headers: requestHeaders }),
    pivotTable,
    pivotMetric,
    enhanceMarketIndexMetric,
  )(originData, { config, contextQuery, cellUpdate: cellUpdate[0], pageRequests, transformedParams });
  return result;
}

export async function calculateValueGetter({
  rows,
  columns,
  groupedFields = [],
  drillDowns = [],
  resourceMetric = [],
  formulaUpstream,
  config,
}: {
  rows: any[];
  columns: any[];
  groupedFields: any[];
  drillDowns: any[];
  resourceMetric: any[];
  formulaUpstream: FormulaUpstream;
  config: any;
}) {
  const fieldColCalculate = columns.reduce((carry, col) => {
    const valueGetter = col.cell.valueGetter;
    const mapOrder = [];
    for (const [key, field] of Object.entries<string>(valueGetter)) {
      if (isFunction(field)) {
        mapOrder.push([key, field]);
      } else if (isFormulaField(field) && /UCAL/.test(field)) {
        mapOrder.push([
          key,
          (row) => {
            formulaUpstream &&
              formulaUpstream.addFormula(field, {
                _eTable: {
                  field: col.field,
                  valueGetterField: key,
                  rowIds: Object.keys(row)
                    .filter((k) => k.indexOf('.id') > -1)
                    .reduce((carry, k) => {
                      return { ...carry, [k]: row[k] };
                    }, {}),
                  row,
                },
              });
            return '...';
          },
        ]);
      } else if (
        isFormulaField(field) &&
        !(
          groupedFields.length > 0 &&
          drillDowns.length === 0 &&
          ['LIST', 'RANGE'].indexOf(col.aggFunc || col.defaultAggFunc) > -1
        )
      ) {
        mapOrder.push([key, (row) => toValue(field, row)]);
      } else {
        mapOrder.unshift([
          key,
          (row) => {
            return row[stripUniversalPrefix(field)];
          },
        ]);
      }
    }

    const foundMetricDef = resourceMetric.find(
      (m) =>
        valueGetter.value &&
        !isFunction(valueGetter.value) &&
        [valueGetter.value, valueGetter.value.replace(/\.(m|a)_/, '.')].indexOf(m.value) > -1,
    );

    if (foundMetricDef && !valueGetter.currency) {
      mapOrder.unshift([
        'currency',
        (row) => {
          return foundMetricDef.prefix_value === '$' ? row['currency'] : foundMetricDef.prefix_value;
        },
      ]);
    }
    mapOrder.unshift(['aggregate.function', () => col.aggFunc || col.defaultAggFunc]);

    return {
      ...carry,
      [col.field]: mapOrder,
    };
  }, {});

  return rows.map((row) => {
    const data = {};
    for (const [key, mapOrder] of Object.entries(fieldColCalculate)) {
      const cData = (mapOrder as any[]).reduce((carry, [vKey, mapFun]) => {
        const cohort = Object.entries(get(row, ['cohort'], {})).reduce((carry, [k, v]) => {
          return {
            ...carry,
            [`cohort.${k}`]: v,
          };
        }, {});
        const cohortTotal = Object.entries(get(row, ['total_cohort'], {})).reduce((carry, [k, v]) => {
          return {
            ...carry,
            [`total_cohort.${k}`]: v,
          };
        }, {});
        const total = Object.entries(get(row, ['total'], {})).reduce((carry, [k, v]) => {
          return {
            ...carry,
            [`total.${k}`]: v,
          };
        }, {});
        let cohortPeriod = get(config, ['calendarCohort'], '');
        if (String(cohortPeriod).split('_').length > 1) {
          cohortPeriod = cohortPeriod.split('_').map((i) => i[0]);
        } else if (cohortPeriod === 'PREVIOUS') {
          cohortPeriod = 'previous';
        } else if (cohortPeriod === 'CUSTOM') {
          const dateFrom = moment(get(config, ['cohortDateRange', 'dateFrom'], '')).format('MMM DD');
          const dateTo = moment(get(config, ['cohortDateRange', 'dateTo'], '')).format('MMM DD');
          cohortPeriod = `${dateFrom} - ${dateTo}`;
        }
        return {
          ...carry,
          [vKey]: mapFun({
            ...row,
            ...carry,
            ...cohort,
            ...cohortTotal,
            'cohort.period': cohortPeriod,
            ...total,
            'config.currency': config?.currency,
          }),
        };
      }, {});
      data[key] = cData;
    }

    return { ...row, eData: data };
  });
}

export function produceColumns(config) {
  const groupByColumns = get(config, 'groupBy.columns', []);
  const columnOrder = get(config, 'columnOrder');

  const columns = config.settingType.reduce((acc, val) => {
    if (config[val.type]) {
      let cols = config[val.type]
        .filter(
          (i) =>
            get(config, ['mapping', i], false) && get(config, ['mapping', i, 'cellFormat']) !== 'filterColumnFormat',
        )
        .sort((a, b) => {
          const indexA = columnOrder.indexOf(a);
          const indexB = columnOrder.indexOf(b);
          const groupedIndexA = groupByColumns.indexOf(a);
          const groupedIndexB = groupByColumns.indexOf(b);

          if (groupedIndexA > -1 && groupedIndexB > -1) {
            return groupedIndexA > groupedIndexB ? 1 : groupedIndexA < groupedIndexB ? -1 : 0;
          }

          return indexA > indexB ? 1 : indexA < indexB ? -1 : 0;
        });
      cols = cols.map((cid) => {
        const f = get(config, ['view', 'combinator', 'columnWidth'], get(config, ['columnWidth'], [])).find(
          (i) => i.columnField === cid,
        );
        let width = EIP_CONSTANT.ETABLE.COLUMN_WIDTH;
        if (f) width = f.width;

        return {
          name: get(config, ['mapping', cid, 'title'], ''),
          propertyType: val.type,
          field: cid,
          dataType: get(config, ['mapping', cid, 'dataType'], 'string'),
          sortable: true,
          isGrouped: groupByColumns.includes(cid),
          filterField: get(config, ['mapping', cid, 'filterField'], ''),
          filterGetter: get(config, ['mapping', cid, 'filterGetter']),
          width: width,
          actions: [],
          cell: {
            format: get(config, ['mapping', cid, 'cellFormat'], 'text'),
            valueGetter: get(config, ['mapping', cid, 'valueGetter']),
            staticValue: get(config, ['mapping', cid, 'staticValue']),
            actions: [], // get(config, ['mapping', cid, 'menu']),
            updateHandler: null,
          },
          aggFunc: get(config, ['groupBy', 'aggregations', cid, 'func']),
          defaultAggFunc: get(config, ['mapping', cid, 'defaultCalculated']),
          columnDescription: get(config, ['mapping', cid, 'columnDescription'], ''),
        };
      });
      return acc.concat(cols);
    }
    return acc;
  }, []);

  return columns;
}

async function calculateSummary(rows, summary: Record<string, any>, formulaUpstream: FormulaUpstream) {
  const calculated = Object.entries(summary).reduce((carry, [k, val]) => {
    let summaryVal;
    if (/UCAL/.test(val)) {
      summaryVal = formulaUpstream.addFormula(val, {});
    } else {
      summaryVal = val;
    }

    return { ...carry, [k]: summaryVal };
  }, {});

  return calculated;
}

async function handleChartTotal(
  pData: { data: { headers: any[]; rows: any[] }; queryParams: any },
  config: any,
  request: eTableRequest,
) {
  const metrics = Object.entries(config.mapping)
    .filter(([k, m]: [string, any]) => {
      return m.propertyType === 'metric' && m.cellFormat !== 'filterColumnFormat';
    })
    .map(([k, m]: [string, any]) => {
      return m.valueGetter.value || m.valueGetter.id;
    });

  const results = await handleRaw(null, {
    body: produce(pData.queryParams, (draft) => {
      draft.groupPeriod = 'all';
      draft.metrics = pData.queryParams.metrics.concat(metrics);
      draft._eip = { src: 'chart-total' };
    }),
    eTableConfig: produce(config, (draft) => {
      draft.visualizationType = VISUALIZATION_TYPE.table;
    }),
    headers: request.headers,
    method: request.method,
    url: request.url,
  });

  const groupField: string = first(get(results, 'eTableContext.groupBy.columns').filter((i) => !!i));

  const chartGroupDimension = get(config, 'chartConfig.config.groupBy');
  if (chartGroupDimension === 'metric') {
    Object.entries(config.mapping)
      .filter(([_, m]: [string, any]) => m.propertyType === 'metric')
      .forEach(([k, m]: [string, any]) => {
        const label = m.title;
        const groupedKeys = Object.keys(m.valueGetter);

        const groupedColumnData = get(results, 'data.rows', [])
          .map((r) => {
            return r.eData[k];
          })
          .filter((r) => !!r);

        const columData = groupedKeys.reduce((carry, gk) => {
          carry[gk] = groupedColumnData.map((r) => r[gk]);
          return carry;
        }, {});

        groupedKeys.forEach((gk) => {
          const formulaStr = m.valueGetter[gk];
          getChannelUpstreamCalculate().postMessage({
            id: `_upstream_func/${label}/${gk}`,
            result: toValue(formulaStr, columData),
          });
        });
      });
  } else {
    const groupedColumnData = get(results, 'data.rows', []).map((r) => {
      return r.eData[groupField];
    });
    groupedColumnData.forEach((r) => {
      const label = r.value;
      Object.entries(r).forEach(([k, v]) => {
        getChannelUpstreamCalculate().postMessage({
          id: `_upstream_func/${label}/${k}`,
          result: v,
        });
      });
    });
  }

  return results;
}
