import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const EditStatus = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="5.5" cy="10.5" r="4.5" fill={`${props.color}`} />
    <path
      d="M4.31868 8.57439L4.31868 8.5744L9.2467 3.64623L9.60025 3.29266L9.95381 3.64623L12.3536 6.04604L12.7071 6.39958L12.3536 6.75313L7.42555 11.6813L7.42411 11.6827C7.26527 11.8403 7.0769 11.9649 6.86979 12.0495C6.66318 12.1339 6.44198 12.1768 6.21881 12.1757M4.31868 8.57439L6.22008 11.6757M4.31868 8.57439L4.31725 8.57584C4.1597 8.73468 4.03506 8.92305 3.95047 9.13017C3.86607 9.33679 3.82319 9.55801 3.82428 9.7812M4.31868 8.57439L4.32428 9.77987M6.21881 12.1757C6.21827 12.1757 6.21773 12.1757 6.21719 12.1757L6.22008 11.6757M6.21881 12.1757H6.22008V11.6757M6.21881 12.1757H4.92422C4.63249 12.1757 4.35272 12.0598 4.14644 11.8535C3.94016 11.6473 3.82428 11.3675 3.82428 11.0758V9.7812M6.22008 11.6757H4.92422C4.7651 11.6757 4.61251 11.6125 4.5 11.5C4.38749 11.3875 4.32428 11.2349 4.32428 11.0758V9.77987M3.82428 9.7812C3.82428 9.78172 3.82428 9.78224 3.82429 9.78277L4.32428 9.77987M3.82428 9.7812V9.77987H4.32428M9.9538 4.35334L9.60138 4.0009L9.95381 4.35333L9.9538 4.35334ZM9.60026 3.99978L9.24671 4.35334L9.2467 4.35333L9.60026 3.99978ZM14.0053 2.70426L14.0044 2.70332L13.2964 1.99537L13.2964 1.99537L13.2954 1.99432C12.9769 1.67771 12.5461 1.5 12.097 1.5C11.6479 1.5 11.217 1.67771 10.8985 1.99432L10.8985 1.99432L10.8975 1.99537L10.0456 2.8473L9.69204 3.20085L10.0456 3.5544L12.4453 5.95421L12.7989 6.30777L13.1525 5.95421L13.9763 5.13036C14.1381 4.97527 14.2677 4.78971 14.3575 4.58432C14.4483 4.37683 14.4967 4.15329 14.4998 3.92684C14.503 3.70039 14.4609 3.47559 14.376 3.26564C14.291 3.05569 14.165 2.86482 14.0053 2.70426Z"
      fill={`${props.color}`}
      stroke="#fff"
    />
  </SvgIcon>
);

export default EditStatus;
