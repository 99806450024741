import * as React from 'react';
import { get, set } from 'lodash';
import moment from 'moment';

import CalendarRange from '@ep/insight-ui/elements/form-control/calendar/calendar-range';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const Calendar = ({ configuration, onChange = () => undefined, rowData = {}, getValues, fieldName }: any) => {
  const defaultFormatReturn = React.useMemo(() => {
    const returnFormatField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'return_format',
    ) || { value: 'YYYY-MM-DD' };
    return returnFormatField.value;
  }, [configuration]);

  let minDate = React.useMemo(() => {
    const minDateField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'min_date',
    ) || { value: '' };
    return minDateField.value;
  }, [configuration]);

  minDate = toValue(minDate, rowData);

  let maxDate = React.useMemo(() => {
    const maxDateField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'max_date',
    ) || { value: '' };
    return maxDateField.value === 'now' ? moment().format(defaultFormatReturn) : maxDateField.value;
  }, [configuration, defaultFormatReturn]);

  maxDate = toValue(maxDate, rowData);

  let maxDaysSelected = React.useMemo(() => {
    const maxDaysSelectedField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'maxDaysSelected',
    ) || { value: '' };
    return maxDaysSelectedField.value;
  }, [configuration]);

  maxDaysSelected = toValue(maxDaysSelected, rowData);

  const defaultDateFrom = React.useMemo(() => {
    const defaultDateFromField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'default_date_from',
    ) || { value: '' };
    return moment(rowData[defaultDateFromField.value]).format('YYYY-MM-DD');
  }, [configuration]);

  const defaultDateTo = React.useMemo(() => {
    const defaultDateToField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'default_date_to',
    ) || { value: '' };
    return moment(rowData[defaultDateToField.value]).format('YYYY-MM-DD');
  }, [configuration]);

  let dateFromKey = React.useMemo(() => {
    const dateFromKeyField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'date_from_field',
    ) || { value: '' };
    return dateFromKeyField.value;
  }, [configuration]);

  dateFromKey = toValue(dateFromKey, rowData);

  let dateToKey = React.useMemo(() => {
    const dateToKeyField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'date_to_field',
    ) || { value: '' };
    return dateToKeyField.value;
  }, [configuration]);

  dateToKey = toValue(dateToKey, rowData);

  let disableStartDateField = React.useMemo(() => {
    const disableStartDateFieldField = (get(configuration, ['field_configuration'], []) || []).find(
      (el) => el.key === 'disable_start_date',
    ) || { value: '' };
    return disableStartDateFieldField.value;
  }, [configuration]);

  disableStartDateField = toValue(disableStartDateField, rowData);

  const defaultValue = {
    dateFrom: defaultDateFrom,
    dateTo: defaultDateTo,
  };

  const convertValue = (value) => {
    const newObj = (getValues && getValues(fieldName)) || {};
    set(newObj, dateFromKey.split('.'), moment(value.dateFrom).startOf('day').format(defaultFormatReturn));
    set(
      newObj,
      dateToKey.split('.'),
      moment(value.dateTo || '9999-12-31')
        .endOf('day')
        .format(defaultFormatReturn),
    );

    return newObj;
  };

  const isDisabledStartedDate = React.useMemo(() => {
    if (typeof disableStartDateField === 'boolean') return disableStartDateField;
    if (!disableStartDateField) return false;
    return toValue(String(disableStartDateField), rowData) == 'disabled';
  }, [disableStartDateField]);

  React.useEffect(() => {
    onChange({
      target: {
        value: convertValue(defaultValue),
      },
    });
  }, []);

  return (
    <div style={{ width: '480px' }}>
      <CalendarRange
        value={defaultValue}
        onChange={(e) => {
          onChange({
            target: {
              value: convertValue(e),
            },
          });
        }}
        defaultFormatReturn={defaultFormatReturn}
        disabledChooseStartDate={isDisabledStartedDate}
        minDate={minDate}
        maxDate={maxDate}
        maxDaysSelected={maxDaysSelected}
        clickOnSubmit
      />
    </div>
  );
};

export default Calendar;
