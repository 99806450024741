import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Filter = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.00174 3H12.9983C13.5513 3 14 3.44843 14 4.00125C14 4.55407 13.5513 5.00251 12.9983 5.00251H3.00174C2.44865 5.00251 2 4.55407 2 4.00125C2 3.44843 2.44865 3 3.00174 3Z" />
    <path d="M5.00186 6.99854H10.9981C11.5512 6.99854 11.9998 7.44697 11.9998 7.99979C11.9998 8.55261 11.5512 9.00104 10.9981 9.00104H5.00186C4.44877 9.00104 4.00012 8.55261 4.00012 7.99979C4.00012 7.44613 4.44877 6.99854 5.00186 6.99854Z" />
    <path d="M7.00113 10.9976H8.99793C9.55102 10.9976 9.99967 11.446 9.99967 11.9988C9.99967 12.5516 9.55102 13.0001 8.99793 13.0001H7.00113C6.44804 13.0001 5.99939 12.5516 5.99939 11.9988C6.00023 11.446 6.44804 10.9976 7.00113 10.9976Z" />
  </SvgIcon>
);

export default Filter;
