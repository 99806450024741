import { EIP_CONSTANT, NodeEditContext, useLog } from '@eip/next/lib/main';
import { WorkflowLightContext } from '@ep/insight-ui/system/backbone/workflow-backbone/context';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { makeTable } from '@ep/insight-ui/elements/table/table-container2';
import { Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { get, reduce } from 'lodash';
import * as React from 'react';
import { publishDimensions, updateCampaign } from './legacy/api-request-campaign-details';
import { getWorkflow } from './shopee-product-ads/shopee-product-ads';

import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import { ETABLE_PERSONALIZED_FIELDS } from '@eip/next/src/constant';
import { Provider, useAtomValue } from 'jotai';
import { P13nUpdateAtom, PersonalizationAtom } from '../../backbone/atom/campaign-detail';

const log = useLog('workflow:campaign-details:table-factory');

function getWorkflowForAdTool(adTool: string) {
  switch (adTool) {
    case 'SHP_PA':
      return Promise.resolve(getWorkflow());
    case 'SHP_DA':
      return import('./shopee-discovery-ads/shopee-discovery-ads').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'SHP_SA':
      return import('./shopee-shop-ads/shopee-shop-ads').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'TOKO_PA':
      return import('./tokopedia-product-ads/tokopedia-product-ads').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'TOKO_SA':
      return import('./tokopedia-shop-ads/tokopedia-shop-ads').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'LZD_SS':
      return import('./lazada-sponsored-search/lazada-sponsored-search').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'LZD_SP':
      return import('./lazada-sponsored-product/lazada-sponsored-product').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'LZD_SD':
      return import('./lazada-sponsored-discovery/lazada-sponsored-discovery').then(({ getWorkflow }) => {
        return getWorkflow();
      });
    case 'SHP_BA_7_DAYS':
    case 'SHP_BA_14_DAYS':
    case 'SHP_BA_CUS':
      return import('./shopee-boost-ads/shopee-boost-ads').then(({ getShopeeBAWorkflow }) => {
        return getShopeeBAWorkflow(adTool);
      });
  }
}

export function CampaignTableFactory({
  campaignId,
  adTool,
  schedule,
  marketplace,
  campaignStatus,
  totalBudget,
  dailyBudget,
  objective,
  currentStatus,
  isRefresheTable = false,
  storefrontId,
  onMainTableUpdateConfig,
}: {
  marketplace: string;
  campaignId: number;
  adTool: string;
  storefrontId: number;
  schedule: { dateFrom: string; dateTo: string };
  campaignStatus?: string;
  totalBudget?: number;
  dailyBudget?: number;
  objective?: string;
  currentStatus?: string;
  isRefresheTable?: boolean;
  onMainTableUpdateConfig?: (config: any) => void;
}) {
  const [workflow, setWorkflow] = React.useState<WorkflowCampaignTable<any, any>>();
  const [error, setError] = React.useState<boolean>(false);
  const personalization = useAtomValue(PersonalizationAtom);
  const p13nRef = React.useRef(personalization);

  React.useEffect(() => {
    if (adTool)
      getWorkflowForAdTool(adTool)
        .then((workflow) => setWorkflow(workflow))
        .catch(() => {
          setError(true);
        });
  }, [adTool]);

  React.useEffect(() => {
    if (ff.campaign_inside_data_is_not_refresh) {
      if (isRefresheTable) {
        workflow
          .get('mainTableBackbone')
          .then(({ mainTableBackbone }: { mainTableBackbone: TableBackbone.TableBackboneContextType }) =>
            mainTableBackbone.reloadData('table'),
          );
      }
    }
  }, [schedule, isRefresheTable]);

  const compactTableConfigUpdate = React.useCallback(
    (updatedConfig) => {
      console.info('compact table update', updatedConfig);
      const compactKey = `${marketplace}:${storefrontId}:${adTool}:${updatedConfig.tableId}`;
      const validConfig = ETABLE_PERSONALIZED_FIELDS.reduce((acc, field) => {
        if (updatedConfig[field]) {
          acc[field] = updatedConfig[field];
        }
        return acc;
      }, {});
      p13nRef.current = {
        ...p13nRef.current,
        [compactKey]: validConfig,
      };
      onMainTableUpdateConfig({
        [compactKey]: validConfig,
      });
    },
    [marketplace, storefrontId, adTool, onMainTableUpdateConfig],
  );

  return (
    <LoadingComponent loading={!workflow && !error} error={error}>
      <WorkflowLightContext.Provider value={workflow}>
        <Provider
          initialValues={[
            [PersonalizationAtom, personalization],
            [
              P13nUpdateAtom,
              {
                update: compactTableConfigUpdate,
                get: (id) => p13nRef.current[`${marketplace}:${storefrontId}:${adTool}:${id}`],
              },
            ],
          ]}
        >
          <TableCampaignDetail
            campaignId={campaignId}
            adTool={adTool}
            marketplace={marketplace}
            schedule={schedule}
            storefrontId={storefrontId}
            onMainTableUpdateConfig={onMainTableUpdateConfig}
          />
        </Provider>
        <PublishControl
          campaignId={campaignId}
          schedule={schedule}
          marketplace={marketplace}
          campaignStatus={campaignStatus}
          totalBudget={totalBudget}
          dailyBudget={dailyBudget}
          objective={objective}
          currentStatus={currentStatus}
        />
      </WorkflowLightContext.Provider>
    </LoadingComponent>
  );
}

const TableCampaignDetail = ({
  campaignId,
  schedule,
  adTool,
  marketplace,
  storefrontId,
  onMainTableUpdateConfig,
}: {
  campaignId: number;
  storefrontId: number;
  schedule: { dateFrom: string; dateTo: string };
  marketplace?: string;
  adTool?: string;
  onMainTableUpdateConfig?: (config: any) => void;
}) => {
  const [tableConfig, setTableConfig] = React.useState(null);
  const personalization = useAtomValue(PersonalizationAtom);
  const { onToastMultiple } = useToast();

  const workflow: WorkflowCampaignTable<any, any> = React.useContext(WorkflowLightContext);

  React.useEffect(() => {
    console.info({ adTool, marketplace, storefrontId, personalization });
    workflow.getTableConfig('full', 'tableConfig', { campaignId, schedule }).then((config) => {
      const p13n = get(personalization, `${marketplace}:${storefrontId}:${adTool}`, {});
      const finConfig = { ...config.configuration, ...p13n };
      setTableConfig({ ...config, configuration: finConfig });
    });
    workflow.on('onToastMultiple', (errors) => {
      onToastMultiple({
        ...errors,
        title: 'Please review the following errors',
      });
    });
  }, [adTool, marketplace, storefrontId, personalization]);

  const tableContainer = React.useMemo(() => {
    if (tableConfig) {
      log('change makeTable', tableConfig);
      return makeTable({
        config: tableConfig,
        changeConfiguration: (updatedConfig) => {
          onMainTableUpdateConfig({
            [`${marketplace}:${storefrontId}:${adTool}`]: ETABLE_PERSONALIZED_FIELDS.reduce((acc, field) => {
              if (updatedConfig[field]) {
                acc[field] = updatedConfig[field];
              }
              return acc;
            }, {}),
          });
        },
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      });
    }
    return null;
  }, [tableConfig, adTool, marketplace, storefrontId]);

  return tableContainer;
};

function PublishControl({
  campaignId,
  schedule,
  marketplace: marketplaceCode,
  campaignStatus,
  totalBudget,
  dailyBudget,
  objective,
  currentStatus,
}: {
  campaignId: number | string;
  schedule: { dateFrom: string; dateTo: string };
  marketplace: string;
  campaignStatus: string;
  totalBudget?: number;
  dailyBudget?: number;
  objective?: string;
  currentStatus?: string;
}) {
  const classes = useStyles();
  const [loading, setLoading] = React.useState<{ status: 'done' | 'inprogress' | 'error'; success: boolean }>({
    status: 'done',
    success: true,
  });
  const { onToastMultiple, onToast } = useToast();
  const workflow = React.useContext(WorkflowLightContext);

  const publishDraftDimensions = async (dimension, ids, marketplaceCode) => {
    try {
      const param = {
        id: ids,
        dimension,
        marketplaceCode,
      };
      log('[Param] publishDraftDimension', param);
      const submitRes = await publishDimensions(param);
      if (submitRes.success) {
        onToast({ title: `Submit ${dimension} successfully`, variant: 'success' });
        if (dimension === 'ADS_CAMPAIGN') {
          setTimeout(() => {
            const marketplaceQuery = marketplaceCode !== 'SHOPEE' ? `?marketplace=${marketplaceCode}` : '';
            window.location.href = getPageUrn(
              `/page/${EIP_CONSTANT.EIP_SYSTEM_PAGE_ID.campaignDetail}/${ids[0]}${marketplaceQuery}`,
            );
          }, 2000);
        }
      } else {
        const errors = get(submitRes, 'data[0].error', {});
        if (Object.keys(errors).length > 0) {
          Object.values(errors).map((el) => onToast({ title: el, variant: 'error' }));
        } else {
          onToast({ title: `Submit ${dimension} failed`, variant: 'error' });
        }
      }
    } catch (error) {
      log('handleSubmit fail', error);
    }
  };

  const publishDraftCampaign = React.useCallback(async () => {
    const campaignIds = [].concat(campaignId);
    await publishDraftDimensions('ADS_CAMPAIGN', campaignIds, marketplaceCode);
  }, [campaignId, marketplaceCode]);

  async function handleSubmit() {
    setLoading({ status: 'inprogress', success: true });
    try {
      if (campaignStatus === 'DRAFT') {
        await publishDraftCampaign();
      } else {
        await workflow.request('publishCampaign', { campaignId, schedule, marketplace: marketplaceCode });
        workflow
          .get('mainTableBackbone')
          .then(({ mainTableBackbone }: { mainTableBackbone: TableBackbone.TableBackboneContextType }) =>
            mainTableBackbone.reloadData('table'),
          );
      }
      setLoading({ status: 'done', success: true });
    } catch (error) {
      setLoading({ status: 'error', success: false });
    }
  }

  return (
    <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          disabled={false}
          onClick={() => {
            if (loading.status === 'inprogress') return;
            handleSubmit();
          }}
        >
          Publish
          {loading.status === 'inprogress' && (
            <React.Fragment>
              ... <CircularProgress color={'inherit'} size={12} />
            </React.Fragment>
          )}
        </Button>
      </Grid>
    </Grid>
  );
}

const useStyles = makeStyles({
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 8px',
  },
});
