import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Arrow = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.98978 1.68972e-05H1.9985C1.80016 -0.00113686 1.60597 0.0568177 1.44069 0.166491C1.27541 0.276165 1.14653 0.432588 1.07047 0.615819C0.994418 0.79905 0.974636 1.00079 1.01365 1.19531C1.05266 1.38983 1.1487 1.56832 1.28953 1.70803L4.28517 4.70455C4.378 4.79816 4.48844 4.87247 4.61012 4.92318C4.7318 4.97389 4.86232 5 4.99414 5C5.12596 5 5.25648 4.97389 5.37816 4.92318C5.49984 4.87247 5.61028 4.79816 5.70311 4.70455L8.69875 1.70803C8.83958 1.56832 8.93562 1.38983 8.97463 1.19531C9.01365 1.00079 8.99386 0.79905 8.91781 0.615819C8.84175 0.432588 8.71287 0.276165 8.54759 0.166491C8.38231 0.0568177 8.18812 -0.00113686 7.98978 1.68972e-05Z"
      fill={`${props.color ? props.color : '#3F4F5C'}`}
    />
  </SvgIcon>
);

export default Arrow;
