import * as React from 'react';
import { get } from 'lodash';

import { makeStyles, Box } from '@material-ui/core';

import { tagsData } from '@ep/insight-ui/elements/inline-edit/cell/tag-editor/mocks/tag-data';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';

import { INPUT_TYPE_TAG_TYPE } from '../../../utils/constant';

const useStyles = makeStyles({
  select: {
    width: '100%',
  },
  container: {
    padding: 0,
    alignItems: 'center',
    marginBottom: '8px',
  },
});

const useTagItemStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '36px',
    padding: '8px',
  },
});

const TagItem = ({ item }: { item: { color: string; label: string } }) => {
  const classes = useTagItemStyles();
  return (
    <Box style={{ background: item.color }} className={classes.container}>
      {item.label}
    </Box>
  );
};

const TagEditor = ({ configuration, value, setValue, onChange, placeholder }: any) => {
  const classes = useStyles();

  const tagType = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const tagTypeObject = fieldConfiguration.find((el) => el.key === INPUT_TYPE_TAG_TYPE) || { value: '' };
    return tagTypeObject.value;
  }, [configuration]);

  const options = React.useMemo(() => {
    return tagsData[tagType] || [];
  }, [tagType]);

  return (
    <SelectForm
      options={options}
      value={value}
      placeholder={placeholder}
      onChange={onChange}
      className={classes.select}
      renderOption={(item) => <TagItem item={item} />}
      styledMenuItemClass={classes.container}
    />
  );
};

export default TagEditor;
