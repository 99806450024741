import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import { getActionMenu, staticFilter } from './common-config';

export const NEW_CAMPAIGN_SETTING_CONFIG = {
  title: 'Campaign overview setting',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_CAMPAIGN',
  },
  mainButton: {
    url: getPageUrn('/page/afdd53e6-49f9-4170-9212-bdeada713c0d'),
    text: 'Create campaign',
    type: 'link',
  },
  system: {
    hiddenComponents: ['search'],
    filter: staticFilter.campaignSettings,
  },
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_CALENDAR.ADS_CAMPAIGN.id',
  ],
  disabledComponents: {
    dimension: ['country', 'marketplace', 'storefront', 'ad_type', 'ad_tool', 'campaign'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'country',
          'marketplace',
          'storefront',
          'ad_type',
          'ad_tool',
          'campaign',
          'campaign_tag',
          'campaign_status',
          'campaign_timeline',
          'campaign_daily_budget',
          'campaign_total_budget',
          'impression',
        ],
      }
    : {}),
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
      menu: getActionMenu('country'),
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
      menu: getActionMenu('marketplace'),
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
      menu: getActionMenu('storefront'),
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
      menu: getActionMenu('ad_type'),
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool_name',
        id: 'ADTOOL.ads_tool',
      },
      menu: getActionMenu('ad_tool'),
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      sortField: 'ADS_CAMPAIGN.name',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        id: 'ADS_CAMPAIGN.id',
        subtext: 'ADS_CAMPAIGN.campaign_code',
      },
      menu: getActionMenu('campaign'),
    },

    // ============= ATTRIBUTE =============
    // campaign_brief: {
    //   title: 'Campaign brief',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.campaign_brief',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.campaign_brief',
    //   },
    // },
    ads_campaign_transform_status: {
      title: 'Campaign sync status',
      propertyType: 'dimension',
      cellFormat: 'transformStatus',
      filterField: 'ADS_CAMPAIGN.transform_status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.transform_status',
        id: 'ADS_CAMPAIGN.transform_status',
        value: 'ADS_CAMPAIGN.transform_status',
        updatedAt: 'ADS_CAMPAIGN.transform_status_updated_at',
      },
      menu: [
        {
          title: 'Data sync',
          name: 'Sync data',
          icon: 'pencil',
          editor: 'TransformStatusEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.transform_status',
            },
          },
        },
      ],
    },
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'nextString',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
        }
      : {}),
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    // campaign_objective: {
    //   title: 'Campaign objective',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.campaign_object',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.campaign_object',
    //   },
    // },
    // tag: {
    //   title: 'Tag',
    //   propertyType: 'attribute',
    //   cellFormat: 'tag',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.tags',
    //   valueGetter: {
    //     tags: 'ADS_CAMPAIGN.tags',
    //   },
    // },
    // owner: {
    //   title: 'Owner',
    //   propertyType: 'attribute',
    //   cellFormat: 'userInfo',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.owner',
    //   valueGetter: {
    //     name: 'ADS_CAMPAIGN.owner',
    //   },
    // },
    // planner: {
    //   title: 'Planner',
    //   propertyType: 'attribute',
    //   cellFormat: 'userInfo',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.planner',
    //   valueGetter: {
    //     name: 'ADS_CAMPAIGN.planner',
    //   },
    // },
    campaign_timeline: {
      title: 'Campaign timeline',
      propertyType: 'attribute',
      cellFormat: 'timeline',
      dataType: 'date',
      filterField: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
      valueGetter: {
        date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
        date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
      },
      menu: getActionMenu('campaign_timeline'),
    },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'dailyBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.daily_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_daily_budget'),
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'totalBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.total_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_total_budget'),
    },
    // ad_objective: {
    //   title: 'Ad objective',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.ad_objective',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.ad_objective',
    //   },
    // },

    // ============= METRIC =============
    impression: {
      title: 'Impression',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'impression',
      valueGetter: {
        value: 'impression',
      },
      menu: getActionMenu('_metric'),
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
      menu: getActionMenu('_metric'),
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    items_sold: {
      title: 'Ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    direct_item_sold: {
      title: 'Direct ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    ads_order: {
      title: 'Ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_order',
      valueGetter: {
        value: 'ads_order',
      },
      menu: getActionMenu('_metric'),
    },
    direct_ads_order: {
      title: 'Direct ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_order',
      valueGetter: {
        value: 'direct_order',
      },
      menu: getActionMenu('_metric'),
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    direct_gmv: {
      title: 'Direct ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
      menu: getActionMenu('_metric'),
    },
    cir: {
      title: 'CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cir',
      valueGetter: {
        value: 'cir',
      },
      menu: getActionMenu('_metric'),
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
      menu: getActionMenu('_metric'),
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
      menu: getActionMenu('_metric'),
    },
    direct_roas: {
      title: 'Direct ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_roas',
      valueGetter: {
        value: 'direct_roas',
      },
      menu: getActionMenu('_metric'),
    },
    cpi: {
      title: 'CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpi',
      valueGetter: {
        value: 'cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cr: {
      title: 'Direct CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cr',
      valueGetter: {
        value: 'direct_cr',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cpi: {
      title: 'Direct CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cpi',
      valueGetter: {
        value: 'direct_cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cir: {
      title: 'Direct CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cir',
      valueGetter: {
        value: 'direct_cir',
      },
      menu: getActionMenu('_metric'),
    },
  },
};
