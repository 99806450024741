import Icon from '@ep/insight-ui/icons/Icon';
import { Divider, Grid, List, ListItem, ListItemIcon, ListItemText, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import * as React from 'react';
import { useState } from 'react';
import Dropdown from '../dropdown-menu/dropdown';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';

const useStyle = makeStyles({
  header: {
    fontWeight: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    ...(ff.ag_grid_27 ? { width: '100%' } : {}),
  },
  label: {
    width: '100%',
    //
    // textAlign: 'right',
    '&.align-right': {
      textAlign: 'right',
    },
    '&.align-left': {
      textAlign: 'left',
    },
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  sortType: {
    '&.d-show': {
      opacity: 1,
    },
    '&.d-none': {
      opacity: 0,
    },
  },
  button: {
    minWidth: '24px',
    height: '24px',
    backgroundColor: 'white',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
});

export const HeaderCell = (props: any) => {
  const classes = useStyle();
  const { menuAction = [], field, sort, dataType = '' } = props;
  const sortType = props.sort?.filter((s) => s.field === field)[0]?.sort;
  const newAction = menuAction.map((el) => {
    return {
      ...el,
      onClick() {
        const checkExist = sort.filter((object) => object.field === field);
        if (checkExist.length === 0) {
          sort.push({ field, sort: el.type });
        } else {
          checkExist[0].sort = el.type;
        }
        el.onSubmit({ field, menuType: { name: el.name, type: el.type } });
      },
    };
  });
  const [isHovering, setIsHovering] = useState(false);
  const handleMouseOverCell = () => {
    setIsHovering(true);
  };
  const handleMouseOutCell = () => {
    setIsHovering(false);
  };

  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const uawl = React.useContext(UaWatchlist);
  const titleTable = backboneContext.getConfig('title');

  return (
    <Grid
      className={clsx(props?.displayName + 'header', classes.header)}
      onMouseOver={handleMouseOverCell}
      onMouseLeave={handleMouseOutCell}
      role="cell"
    >
      <Grid container justifyContent={'space-between'} alignItems={'center'} wrap={'nowrap'} spacing={3}>
        <Grid
          item
          className={`${classes.label} ${
            dataType === 'integer' || dataType == 'float' ? 'align-right' : 'align-left'
          } ${props?.displayName}`}
        >
          {props?.displayName}
        </Grid>
        {sortType && (
          <Grid
            item
            xs={2}
            className={`${classes.sortType} ${!isHovering ? 'd-show' : 'd-none'}`}
            container
            alignItems={'center'}
          >
            {sortType === 'asc' && <Icon type={'ascending'} />}
            {sortType === 'desc' && <Icon type={'descensing'} />}
          </Grid>
        )}
      </Grid>

      <div className={`${classes.menu}`} role="menubar">
        {isHovering && menuAction?.length > 0 && (
          <Dropdown
            className={`${classes.button} eip_header_act_${props.field}`}
            disabledClickContentClose={false}
            alignMenu="right"
            icon="threeDotsVertical"
            label=""
            dataMenuPage={[newAction]}
          >
            <List component="nav" aria-label="mailbox folders" role="menu">
              {menuAction.map((menu, index) => (
                <div key={index}>
                  {/* {index === menuAction?.length - 1 && <Divider />} */}
                  <ListItem button role="menuitem" className={classes.itemDropdown}>
                    <ListItemIcon>
                      <Icon type={menu.icon} colorIcon={'#253746'} width="16px" height="16px" />
                    </ListItemIcon>
                    <ListItemText
                      className={classes.textStyle}
                      primary={menu.name}
                      onClick={() => {
                        if (menu.name == 'Group by') {
                          uawl.start('canGroupItemEtable', { title: titleTable, timerMs: 3000 });
                        }
                        const checkExist = sort.filter((object) => object.field === field);
                        if (checkExist.length === 0) {
                          sort.push({ field, sort: menu.type });
                        } else {
                          checkExist[0].sort = menu.type;
                        }
                        menu.onSubmit({ field, menuType: { name: menu.name, type: menu.type } });
                      }}
                    />
                  </ListItem>
                </div>
              ))}
            </List>
          </Dropdown>
        )}
      </div>
    </Grid>
  );
};
export default HeaderCell;
