import { get, set, uniq } from 'lodash';
import { uQuery } from '../origin-request';
import { M } from '@ep/insight-ui/lib/stories/native-bbad66b5';

const filterOutMetric = (i) => !String(i).startsWith('metric.');

export const enhanceMarketIndexMetric = async (
  originData,
  { config, contextQuery, cellUpdate, pageRequests, transformedParams },
  next,
) => {
  try {
    const rows = get(originData, ['rows'], []);
    const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
    const metricsParam = get(contextQuery, ['metrics'], []).filter(filterOutMetric);
    const attributes = get(contextQuery, ['attributes'], []);
    const eTablePrimaryKeys = get(config, 'primaryKeys', [])
      .filter(filterOutMetric)
      .filter((i) => attributes.includes(i));
    const calculateSecondaryMetric = get(config, ['system', 'calculateSecondaryMetric'], 'no') == 'yes';
    const groupBy = get(contextQuery, ['groupBy', 'columns'], get(contextQuery, ['groupBy', 'dimensions'], []));
    const drillDowns = get(contextQuery, ['groupBy', 'drillDowns'], []);
    const _CONT_ = '_cont_';
    if (!calculateSecondaryMetric || (groupBy.length && !drillDowns.length)) {
      return next(originData, { config, contextQuery, cellUpdate, pageRequests });
    }
    const metricsToFetch = uniq(
      rows.reduce((carry, row) => {
        const metricTypeData = row?.['metric.type_data'];
        const metricCode = row?.['metric.metric_code'];
        const metricExpression = row?.['metric.expression'];
        if (metricTypeData == 'secondary_data' && String(metricCode).includes(_CONT_)) {
          return carry.concat(row['metric.metric_code'].split(_CONT_)[0]);
        }
        if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
          return carry;
        }
        const primaryMetrics = String(metricExpression).match(/\w+/g);
        return carry.concat(primaryMetrics || []);
      }, []),
    );

    if (metricsToFetch.length > 0) {
      const requestPendingPrimaryMetricsParams = {
        ...transformedParams,
        filter: {
          ...transformedParams.filter,
          filters: (transformedParams.filter?.filters || [])
            .filter((i) => !String(i.field).startsWith('metric.'))
            .concat({
              field: 'metric.metric_code',
              operator: 'IN',
              value: metricsToFetch,
            }),
        },
        pagination: {
          page: 1,
          limit: 9999,
        },
      };

      const pendingPrimaryMetricsResults = await uQuery(endpoint, requestPendingPrimaryMetricsParams);
      const pendingPrimaryMetricsData = get(pendingPrimaryMetricsResults, ['data', 'rows'], []);
      const newRows = rows.map((row) => {
        const metricExpression = row?.['metric.expression'];
        const metricTypeData = row?.['metric.type_data'];
        const metricCode = row?.['metric.metric_code'];
        if (metricTypeData == 'secondary_data' && metricCode.includes(_CONT_)) {
          const primaryMetricsData = pendingPrimaryMetricsData
            .filter((item) => {
              const matchedMetricCode = metricCode.split(_CONT_)[0];
              return (
                (row['metric_category.name'] == item['metric_category.name'] ||
                  item['metric_category.name'] == 'OVERALL') &&
                matchedMetricCode == item['metric.metric_code'] &&
                eTablePrimaryKeys
                  .filter((i) => !['metric_category.name', 'metric_category.id'].includes(i))
                  .every((i) => {
                    return row[i] == item[i];
                  })
              );
            })
            .reduce((carry, item) => {
              if (item['metric_category.name'] == row['metric_category.name']) {
                carry['current'] = item;
              }
              if (item['metric_category.name'] == 'OVERALL') {
                carry['total'] = item;
              }

              return carry;
            }, {});
          if (!Object.keys(primaryMetricsData).length) return row;

          metricsParam.forEach((p) => {
            const newValue = get(primaryMetricsData, ['current', p]) / get(primaryMetricsData, ['total', p]);
            set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
          });
          return row;
        }
        if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
          return row;
        }
        const primaryMetricsData = pendingPrimaryMetricsData
          .filter((item) => {
            const primaryMetrics = String(metricExpression).match(/\w+/g);
            return (
              primaryMetrics.includes(item['metric.metric_code']) &&
              eTablePrimaryKeys.every((i) => {
                return row[i] == item[i];
              })
            );
          })
          .reduce((carry, item) => {
            return {
              ...carry,
              [item['metric.metric_code']]: item,
            };
          }, {});
        if (!Object.keys(primaryMetricsData).length) return row;

        metricsParam.forEach((p) => {
          const newValue = eval(
            metricExpression.replace(/\{\w+\}/g, (c) => {
              return get(primaryMetricsData, [c.replace('{', '').replace('}', ''), p]);
            }),
          );
          set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
        });
        return row;
      });

      set(originData, ['rows'], newRows);
      set(originData, ['data', 'rows'], newRows);
    }
    // const existedPrimaryMetrics = rows
    return next(originData, { config, contextQuery, cellUpdate, pageRequests, transformedParams });
  } catch (e) {
    return next(originData, { config, contextQuery, cellUpdate, pageRequests, transformedParams });
  }
};
