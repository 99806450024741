import * as React from 'react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { InputAdornment, TextField } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
/**


 * ff.welcome_page_questionnaire:start


 */

import { ContainerResponsiveContext } from '@eip/next/lib/main';

/**


    * ff.welcome_page_questionnaire:end


    */

/**
 * ff.welcome_page_questionnaire:start
 */
import clsx from 'clsx';
/**
 * ff.welcome_page_questionnaire:start
 */

/** Style */
const useStyles = makeStyles(() => ({
  textInput: {
    height: '30px',
    '& input': {
      width: '100%',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    '& input::placeholder': {
      color: colors.text.subdued,
    },
  },
  textInputMobile: {
    '& .eip1-MuiOutlinedInput-input': {
      padding: '10px 0px',
    },
  },
  hovered: {
    borderColor: 'red',
  },
}));

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },

    '& .eip1-MuiOutlinedInput-root': {
      paddingLeft: '10px',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.border.highlight,
        borderWidth: '1px',
      },
    },
  },
})(TextField);

type ClearAdornmentProps = {
  show: string;
};
const ClearAdornment = withStyles({
  root: {
    '&:hover': {
      cursor: 'pointer',
    },
    visibility: (props: ClearAdornmentProps) => (props.show === 'true' ? 'visible' : 'hidden'),
  },
})(InputAdornment);

type TextFieldCustomProps = React.ComponentProps<typeof TextField> & {
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
};

const SearchInput: React.FC<TextFieldCustomProps> = ({ inputValue, setInputValue, ...rest }: TextFieldCustomProps) => {
  const classes = useStyles();

  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';

  return (
    <CssTextField
      InputLabelProps={{ shrink: false }}
      placeholder="Search"
      variant="outlined"
      color="secondary"
      value={inputValue}
      onChange={(e) => setInputValue(e.target.value)}
      {...rest}
      InputProps={{
        className: clsx(classes.textInput, {
          [classes.textInputMobile]: isMobileView,
        }),
        startAdornment: (
          <InputAdornment position="start">
            <Icon type={'search'} size="12px" />
          </InputAdornment>
        ),
        endAdornment: (
          <ClearAdornment position="end" show={String(inputValue !== '')}>
            <Icon type="close" size="12px" onClick={() => setInputValue('')} />
          </ClearAdornment>
        ),
      }}
    />
  );
};

export default SearchInput;
