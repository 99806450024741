import * as React from 'react';

import CompactSelection from '@ep/insight-ui/elements/input-form/form-templates/form-controls/compact-selection2';

import { useETableOptions } from './hooks/use-etable-options';
import { get } from 'lodash';

const Compact = ({
  configuration,
  onChange,
  placeholder,
  initialValue,
  customProps,
  fieldKey,
  errors,
  ...rest
}: any) => {
  const errorMessage = get(errors, String(fieldKey).split('.').concat('message'), '');
  const { compactFinalConfig, labelField, selectedRows, handleOnChange } = useETableOptions({
    ...rest,
    configuration,
    onChange,
    initialValue,
    customProps,
  });

  return (
    <>
      <CompactSelection
        label={''}
        displayField={labelField}
        selectedRows={selectedRows}
        setRows={handleOnChange}
        useSubmitOnClose
        config={compactFinalConfig}
        placeHolder={placeholder}
      />
      {errorMessage ? <span style={{ color: '#d4290d', fontSize: '12px' }}>{errorMessage}</span> : null}
    </>
  );
};

export default Compact;
