import { Chip, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
import * as _ from 'lodash';
const useStyles = makeStyles(() => ({
  stamp: {
    background: ' #EDF2F9',
    alignItems: 'center',
    justifyContent: 'center',
    '& .eip1-MuiChip-label': {
      //   padding: '',
      fontStyle: ' normal',
      fontWeight: 500,
      fontSize: ' 14px',
      lineHeight: ' 20px',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  },
}));

export interface IStampFormat {
  stamp: number;
}
const StampFormat = (props: IPropsFormat<IStampFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const stamp = _.get(value, 'stamp', '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={props.value} isGrouped={isGrouped}>
      <Grid container alignItems={'center'} justifyContent={'center'}>
        <Chip className={classes.stamp} label={stamp} />
      </Grid>
    </WrapperFormat>
  );
};

export default StampFormat;
