import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { debounce } from 'lodash';
import * as React from 'react';
import TextArea from '../../textField/text-area';
import { Button, Box, Tooltip, makeStyles } from '@material-ui/core';
import SelectFormGroup from '@ep/insight-ui/elements/form-control/select-form/select-form-group';
import { checkEpsiloTableEndpoint } from '@ep/insight-ui/sw/etable/data/common';

const useStyles = makeStyles(() => ({
  operatorSection: {
    '&.queryField > div': {
      paddingRight: '24px',
    },
  },
}));
const ButtonSearchArea = (props) => {
  const classes = useStyles();
  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const searchText = backboneContext.getConfig('search');
  const allowMultiSearch = backboneContext.getConfig('allowMultiSearch', true);
  const searchHint = backboneContext.getConfig('searchHint', 'Looking for more keywords...');
  const text = React.useRef<string>(searchText);
  const handleOnChangeText = (searchStr) => {
    text.current = searchStr;
  };
  const endpoint = backboneContext.getConfig('endpoint.GET_TABLE_DATA', '');
  const tableId = backboneContext.getConfig('tableId', '');
  const [fieldOperatorValue, setFieldOperatorValue] = React.useState('contains');

  const FIELD_OPERATOR = 'fieldOperator';

  const fieldsOperators = [
    [
      { label: 'Contain', value: 'contains' },
      { label: 'Does not contain', value: 'does_not_contain' },
      { label: 'Is', value: 'is' },
      { label: 'Is not', value: 'is_not' },
      { label: 'Starts with', value: 'begins_with' },
      { label: 'Does not start with', value: 'does_not_start_with' },
      { label: 'Ends with', value: 'ends_with' },
      { label: 'Does not end with', value: 'does_not_end_with' },
    ],
  ];

  const fieldOperator = React.useMemo(() => {
    return fieldsOperators[0].find((i) => i.value == fieldOperatorValue);
  }, [fieldOperatorValue]);

  return (
    <Box display="flex" alignItems={'flex-start'}>
      {checkEpsiloTableEndpoint(endpoint) && tableId ? (
        <SelectFormGroup
          noShadow={true}
          style={{ flexGrow: 1, backgroundColor: '#fff', marginRight: '4px' }}
          options={fieldsOperators}
          inputWidth={'30%'}
          value={fieldOperator}
          onChangeValue={(val) => {
            setFieldOperatorValue(val.value);
          }}
          className={`${classes.operatorSection} queryField`}
        />
      ) : null}
      <Box flexGrow={1} marginRight={1}>
        <TextArea
          style={{ maxHeight: '104px' }}
          placeholder={searchHint}
          onKeyDown={(e) => {
            // when press control enter, trigger search
            if (e.key === 'Enter' && (e.ctrlKey || e.metaKey)) {
              backboneContext.changeConfig('search', text.current);
            }
            return true;
          }}
          searchIcon={false}
          onChangeText={handleOnChangeText}
          defaultValue={searchText}
          multiline={allowMultiSearch}
          limit={100}
        />
      </Box>
      <Tooltip title={'Ctrl + ↵'}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            backboneContext.changeConfig('search', text.current);
            backboneContext.changeConfig(FIELD_OPERATOR, fieldOperatorValue);
          }}
          style={{ flexGrow: 0, alignSelf: 'start', paddingTop: 9, paddingBottom: 9 }}
        >
          Search
        </Button>
      </Tooltip>
    </Box>
  );
};

export default ButtonSearchArea;
