import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { CAMPAIGN_RES } from '@ep/insight-ui/elements/table/mocks/getCampaignData-100';
import { CAMPAIGN_SETTING_CONFIG } from '@ep/insight-ui/elements/table/mocks/table-config/campaign-settings';
import { Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import { get } from 'lodash';
import * as React from 'react';
import CompactSelection from '../form-controls/compact-selection';
import Selection from '../form-controls/selection';

type SelectItem = {
  label: string;
  value: string;
  payload?: any;
};

type ScriptGeneralInformationPropType = {
  value: {
    marketplace: string;
    country: string;
    storefront: string;
    adType: string;
    adTool: string;
    scriptTarget: string;
    programmaticObject: string[];
    existingSetting: string;
    interval: string;
  };
  marketplace: SelectItem[];
  country: SelectItem[];
  storefront: SelectItem[];
  adType: SelectItem[];
  adTool: SelectItem[];
  scriptTarget: SelectItem[];
  programmaticObject: SelectItem[];
  existingSetting: SelectItem[];
  configuration?: any;
  onChange?: (arg: Record<string, any>) => Promise<any>;
  setCountryCode?: any;
  disableEdit?: boolean;
  requestOptions?: any;
  isLoading?: boolean;
  interval?: SelectItem[];
};

const GeneralInformationForm = (props: ScriptGeneralInformationPropType) => {
  return (
    <ExpansionForm level="1" label={'General information'} defaultExpanded={true}>
      <Formik
        initialValues={props.value}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
        enableReinitialize
        validateOnChange={true}
        validate={(data) => {
          console.info('validate', data);
          return props.onChange(data).then(() => {
            return {};
          });
        }}
      >
        {({ values, handleChange, handleSubmit, isSubmitting, setFieldValue }) => {
          const DEFAULT_CONFIG = {
            apiRequest: {
              getTableData: (params) => {
                return Promise.resolve(CAMPAIGN_RES);
              },
            },
            configuration: CAMPAIGN_SETTING_CONFIG,
            tableType: 'compact',
          };
          const programObjConfig = {
            ...get(props, 'configuration.programmaticObject.config', DEFAULT_CONFIG),
            callback: {
              onRowSelect: (rows) => {
                setFieldValue('programmaticObject', rows, true);
              },
            },
            ...(ff.missing_search_params
              ? {
                  addons: {
                    'datasource.getRowsParams': async ({ params }, config) => {
                      const search = config.search
                        .split('\n')
                        .map((el) => el.trim())
                        .filter((el) => el);
                      const searchField = params.dimensions.includes('ADS_PLACEMENT')
                        ? 'ADS_PLACEMENT.name'
                        : 'ADS_OBJECT.name';
                      if (search.length > 0) {
                        params.filter?.filters.push({
                          combinator: 'or',
                          filters: search.map((el) => ({
                            dataType: 'string',
                            field: searchField,
                            operator: 'contains',
                            value: el,
                          })),
                        });
                      }
                      return params;
                    },
                  },
                }
              : {}),
          };
          const programObjField = get(props, 'configuration.programmaticObject.displayField', 'STOREFRONT.name');
          return (
            <Form>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Selection
                    options={props.marketplace}
                    onChange={handleChange('marketplace')}
                    label={'Marketplace'}
                    value={values.marketplace}
                    placeholder="Choose marketplace"
                    disabled={props.disableEdit}
                    requestOption={props.requestOptions.getMarketplace}
                    isLoading={props.isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Selection
                    options={props.country}
                    onChange={(e) => {
                      handleChange('country')(e);
                      props.setCountryCode(e.target.value);
                    }}
                    label={'Country'}
                    value={values.country}
                    placeholder="Choose country"
                    disabled={props.disableEdit}
                    requestOption={props.requestOptions.getCountry}
                    isLoading={props.isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Selection
                    options={props.storefront}
                    onChange={handleChange('storefront')}
                    label={'Storefront'}
                    value={values.storefront}
                    placeholder="Choose storefront"
                    disabled={props.disableEdit}
                    requestOption={props.requestOptions.getStorefront}
                    isLoading={props.isLoading}
                  />
                </Grid>

                {/* <Grid item xs={12}>
                  <Selection
                    options={props.adType}
                    onChange={handleChange('adType')}
                    label={'Ad type'}
                    value={values.adType}
                  />
                </Grid> */}
                <Grid item xs={12}>
                  <Selection
                    options={props.adTool}
                    onChange={handleChange('adTool')}
                    label={'Ad tool'}
                    value={values.adTool}
                    placeholder="Choose ad tool"
                    disabled={props.disableEdit}
                    requestOption={props.requestOptions.getAdTool}
                    isLoading={props.isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Selection
                    options={props.scriptTarget}
                    onChange={handleChange('scriptTarget')}
                    label={'Script target'}
                    value={values.scriptTarget}
                    placeholder="Choose script target"
                    disabled={props.disableEdit}
                    requestOption={props.requestOptions.getScriptTarget}
                    isLoading={props.isLoading}
                  />
                </Grid>

                <Grid item xs={12}>
                  {/* <MultipleSelection
                  label={'Programmatic object'}
                  defaultValue={values.programmaticObject}
                  onChangeValue={(value) => setFieldValue('programmaticObject', value)}
                  limitTags={3}
                  options={props.programmaticObject}
                /> */}
                  <CompactSelection
                    label={'Programmatic object'}
                    displayField={programObjField}
                    selectedRows={values.programmaticObject}
                    setRows={(value: any[]) => {
                      setFieldValue('programmaticObject', value);
                    }}
                    useSubmitOnClose
                    config={programObjConfig}
                    placeHolder="Choose programmatic object"
                    disabled={props.disableEdit || !values.storefront}
                  />
                </Grid>

                <Grid item xs={12}>
                  {' '}
                  <Selection
                    options={props.existingSetting}
                    onChange={handleChange('existingSetting')}
                    label={'Apply existing setting'}
                    value={values.existingSetting}
                    placeholder="Choose existing script"
                    disabled={props.disableEdit}
                    isLoading={props.isLoading}
                    showIcon={true}
                  />
                </Grid>

                {ff.script_interval ? (
                  <Grid item xs={12}>
                    <Selection
                      options={props.interval}
                      onChange={handleChange('interval')}
                      label={'Schedule'}
                      value={values.interval}
                      placeholder="Choose schedule"
                      disabled={props.disableEdit}
                      requestOption={props.requestOptions.getInterval}
                      isLoading={props.isLoading}
                    />
                  </Grid>
                ) : null}
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ExpansionForm>
  );
};

export default GeneralInformationForm;
