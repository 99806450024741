import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiAccordion, { AccordionProps } from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { Box, Grid, Tooltip } from '@material-ui/core';
import { ContainerResponsiveContext, aim } from '@eip/next/lib/main';
import { DarkModeContext } from '@ep/insight-ui/elements/epsilo-chart/chart-container';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import clsx from 'clsx';
import ConditionalWrap from '../util/conditional-wrap';
import StyledTooltip from '../tooltip/styled';
import { get } from 'lodash';
import MonitorSection from './monitor-section';

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    background: 'transparent !important',
    backgroundColor: 'transparent !important',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    margin: '12px 0 0 0',
    overflow: 'hidden',
    '&$expanded': {
      margin: '12px 0 0 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
    display: 'block',
  },
}))(MuiAccordionDetails);

/** Style */
const useStyles = makeStyles(() => ({
  iconToggle: {
    width: '12px',
    height: '100%',
    marginRight: '10px',
  },
  label: {
    fontStyle: ' normal',
    fontWeight: 500,
    fontSize: ' 24px',
    lineHeight: '28px',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '.eres--small &': {
      fontSize: '18px',
      lineHeight: '20px',
    },
  },
  headerRight: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  spacer: {
    marginBottom: '10px',
    '&.non-spacer': {
      marginBottom: 0,
    },
  },
  wapperRight: {
    justifyContent: 'flex-end',
  },
  headerLeft: {
    flexShrink: 0,
    maxWidth: 'calc(100% - 32px)',
    display: 'flex',
    alignItems: 'center',
  },
  tooltip: {
    margin: 0,
    padding: '2px 4px',
    whiteSpace: 'pre-line',
  },
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
}));

type StyleProps = {
  expand?: boolean;
};

interface AccordionCustomProps extends AccordionProps {
  label?: string | JSX.Element;
  headerRight?: React.ReactNode;
  headerAction?: React.ReactNode;
  contentMarginTop?: string | number;
  noSpacer?: boolean;
}

const ExpansionPanel: React.FC<AccordionCustomProps> = ({
  label = '',
  headerRight = null,
  headerAction = null,
  children,
  contentMarginTop = 0,
  noSpacer = false,
  ...rest
}: AccordionCustomProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [needTooltip, setNeedTooltip] = React.useState<boolean>(false);
  React.useEffect(() => {
    setExpanded(rest.defaultExpanded);
  }, [rest.defaultExpanded]);

  const classes = useStyles();
  const handleToggle = () => {
    setExpanded(!expanded);
  };
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const darkmode = React.useContext(DarkModeContext);
  const isMobileView = containerClass === 'eres--small';
  const isDarkMode = darkmode.isDarkMode;
  const labelRef = React.useRef(null);

  const backboneContext = React.useContext(TableBackboneContext);
  const hidden = backboneContext.getConfig('system.hiddenComponents', []);
  const tableDescription = get(backboneContext, ['config', 'description'], '');
  const userEmail = aim.getUserSettings().profile?.userEmail;

  React.useEffect(() => {
    if (labelRef.current) {
      setNeedTooltip(labelRef.current.offsetWidth < labelRef.current.scrollWidth);
    }
  }, [labelRef.current]);

  if (hidden.includes('expansion')) {
    return (
      <Grid>
        <Grid
          item
          xs={12}
          container
          justifyContent={'space-between'}
          alignItems={'center'}
          style={{ marginRight: '10px', marginBottom: '8px' }}
        >
          <Grid
            item
            xs={isMobileView ? 'auto' : 12}
            className={`${!isMobileView && classes.spacer} ${noSpacer ? 'non-spacer' : ''} ${classes.wapperRight}`}
          >
            <Grid className={classes.headerRight}>{headerRight}</Grid>
          </Grid>
        </Grid>
        <Grid>
          <div style={{ marginLeft: '17px', marginRight: '17px', marginTop: contentMarginTop }}>{children}</div>
        </Grid>
      </Grid>
    );
  }

  return (
    <Accordion {...rest} square expanded={expanded} style={{ backgroundColor: isDarkMode ? '#253645' : '#FFFFFF' }}>
      <AccordionSummary>
        <Grid container>
          <Grid
            justifyContent={'space-between'}
            alignItems={'center'}
            style={{ marginRight: '10px', display: 'flex', flexWrap: 'nowrap', width: '100%' }}
          >
            <Grid className={clsx(!isMobileView && classes.spacer, classes.headerLeft, noSpacer ? 'non-spacer' : '')}>
              <Box display={'flex'} alignItems={'center'} onClick={handleToggle}>
                <span className={classes.iconToggle}>
                  <Icon
                    colorIcon={isDarkMode ? '#FFFFFF' : '#000000'}
                    type={expanded ? 'arrowdown' : 'arrowright'}
                    style={{ width: '12px', height: '15px' }}
                  />
                </span>
                {/* <ConditionalWrap
                  condition={needTooltip}
                  wrap={(children) => {
                    return <Tooltip title={label}>{children}</Tooltip>;
                  }}
                > */}
                <span
                  style={{ color: isDarkMode ? '#FFFFFF' : '#000000' }}
                  className={classes.label}
                  data-dbf-text-editable={'etable-title'}
                  ref={labelRef}
                >
                  {label}
                </span>
                {/* </ConditionalWrap> */}
                {tableDescription && (
                  <StyledTooltip title={<div className={classes.tooltip}>{tableDescription}</div>} placement={'right'}>
                    <div className={classes.tooltipContainer}>
                      <Icon type={'ic/mi:circle-information/#737373'} />
                    </div>
                  </StyledTooltip>
                )}
              </Box>
              {userEmail.endsWith('@epsilo.io') ? <MonitorSection /> : null}
            </Grid>
            <Grid
              className={`${!isMobileView && classes.spacer} ${noSpacer ? 'non-spacer' : ''} ${classes.wapperRight}`}
            >
              <Grid className={classes.headerRight}>{headerRight}</Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} container style={{ marginLeft: '17px', marginRight: '6px' }}>
            {headerAction}
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails style={{ padding: noSpacer ? '2px 8px 8px' : '4px 8px 8px' }}>
        <div style={{ marginLeft: '17px', marginRight: '17px', marginTop: contentMarginTop }}>{children}</div>
      </AccordionDetails>
    </Accordion>
  );
};

export default ExpansionPanel;
