import * as React from 'react';
import { cloneDeep, get, isEqual, set, sortBy } from 'lodash';

import { Tooltip, makeStyles } from '@material-ui/core';

import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import {
  DELAY_CLOSE_POPUP,
  LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE,
  LOCKED_VIEW_CONFIG_PROPERTY,
} from '@ep/insight-ui/system/helper/constant';
import { OptionsModel, SelectionModal, SettingTypesItem } from '@ep/insight-ui/elements/table/dropdown-properties/type';
import { NodeEditContext } from '@eip/next/lib/main';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import MultipleSelectComponent from '../../multiple-select';
import ButtonActions from '../../button-actions';
import StyledTooltipWithMessages from '@ep/insight-ui/elements/tooltip/styled-tooltip';
import Icon from '@ep/insight-ui/icons/Icon';

const useStyles = makeStyles(() => ({
  container: {
    padding: '4px',
    display: 'flex',
    flexDirection: 'column',
  },
  lockIcon: {
    cursor: 'pointer',
  },
  propertiesContainer: {
    display: 'flex',
    '& > div:not(:first-child)': {
      borderLeft: '1px solid #E4E7E9',
    },
  },
}));

const usePropertyStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    padding: 0,
    '& > div': {
      padding: '12px',
    },
  },
  toggle: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const usePivotMetricStyles = makeStyles(() => ({
  tooltipContainer: {
    background: '#253746',
  },
  container: {
    cursor: 'pointer',
  },
}));

const DropdownProperties = ({ handleClose, properties }: { handleClose: () => void; properties: string[] }) => {
  const classes = useStyles();

  const [property, setProperty] = React.useState<{
    options: OptionsModel;
    settingTypes: Array<SettingTypesItem>;
    selectedItems: OptionsModel;
    disabledOptions: { [key: string]: string[] };
    singleSelectColumns: { [key: string]: string[] };
  } | null>({ options: {}, selectedItems: {}, settingTypes: [], disabledOptions: {}, singleSelectColumns: {} });
  const [selectionState, setSelectionState] = React.useState({});
  const [orderState, setOrderState] = React.useState({});

  const backboneContext = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const [usePivotMultipleHeader, setUsePivotMultipleHeader] = React.useState(() => {
    return backboneContext.getConfig('usePivotMultipleHeader') || 'yes';
  });

  const lockedViewConfigProperty = get(backboneContext.getConfig('view'), LOCKED_VIEW_CONFIG_PROPERTY, []);
  const [lockState, setLockState] = React.useState(lockedViewConfigProperty);

  const lockStateRef = React.useRef(lockedViewConfigProperty);

  const hiddenPropertyColumns = backboneContext.getConfig('hiddenPropertyColumns', []);

  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const metricTractionProperties = backboneContext.getConfig('metricTractionProperties', []);

  const isLockedView = get(backboneContext.getConfig('view'), ['isLock'], false);
  const { isEditMode } = React.useContext(NodeEditContext);

  const fetchData = () => {
    const settingTypes: Array<SettingTypesItem> = backboneContext
      .getConfig('settingType', [])
      .filter((i) => i.allowConfig !== false);
    if (metricTractionProperties && metricTractionProperties.length > 0) {
      settingTypes.push({ type: 'traction', label: 'Traction' });
    }
    if (settingTypes.length > 0) {
      settingTypes.forEach((setting) => {
        const opts = backboneContext.getOptions(setting.type);
        const selectedOptIds = backboneContext.getConfig(setting.type) || [];
        property.options[setting.type] = opts
          .filter((opt) => !hiddenPropertyColumns.includes(opt.id))
          .map((option) => {
            if (!isLockedView) return option;
            if (!isEditMode)
              return {
                ...option,
                disabled: lockState.includes(option.id),
              };
            return {
              ...option,
              endIcon: lockState.includes(option.id)
                ? 'rmx/lock-line-icon/#0000008a'
                : 'rmx/lock-unlock-line-icon/#0000008a',
              onEndIconClick: (_, item) => {
                if (lockStateRef.current.includes(option.id)) {
                  lockStateRef.current = lockStateRef.current.filter((el) => el != option.id);
                } else {
                  lockStateRef.current = lockStateRef.current.concat(option.id);
                }
                setLockState(lockStateRef.current);
                item.disabled = !item.disabled;
                item.endIcon = item.disabled ? 'rmx/lock-line-icon/#0000008a' : 'rmx/lock-unlock-line-icon/#0000008a';
              },
              endIconClasses: classes.lockIcon,
              disabled: lockState.includes(option.id),
            };
          });
        property.selectedItems[setting.type] = opts.filter((i) => selectedOptIds.includes(i.id));
        property.singleSelectColumns[setting.type] = get(setting, 'singleSelectColumns', []);
        if (metricTractionProperties && property.options['traction'] && property.selectedItems['traction']) {
          const selectedMetricTraction = backboneContext.getConfig('selectedMetricTraction', []);
          property.options[setting.type] = metricTractionProperties.filter(
            (metricTractionPropertie) => !hiddenPropertyColumns.includes(metricTractionPropertie.id),
          );
          property.selectedItems[setting.type] =
            selectedMetricTraction.length > 0
              ? metricTractionProperties.filter((i) => selectedMetricTraction.includes(i.id))
              : [];
        }
      });
      const disabledOptions = Object.entries(property.options).reduce(
        (carry, [k, v]) => {
          return {
            ...carry,
            [k]: v.filter((i) => i.disabled).map((i) => i.id),
          };
        },
        {
          dimension: [],
          attribute: [],
          metric: [],
        },
      );

      setProperty({
        settingTypes,
        options: property.options,
        selectedItems: property.selectedItems,
        disabledOptions: disabledOptions,
        // disabledOptions: {
        //   attribute: [
        //     'storefront.created_by',
        //     'storefront.created_at',
        //     'storefront.updated_by',
        //     'storefront.updated_at',
        //   ],
        // },
        singleSelectColumns: property.singleSelectColumns,
      });
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const handleUpdateSelection = (type: string, selectedArr: SelectionModal) => {
    if (selectedArr) {
      setSelectionState((prevValue) => {
        const val = cloneDeep(prevValue);
        set(val, type, selectedArr);
        return val;
      });
      return;
    }
    // if (selectedArr) backboneContext.changeConfig(type, selectedArr);
  };

  const handleReorder = (type: string, optionIds: string[]) => {
    if (optionIds) {
      setOrderState((prevValue) => {
        const val = cloneDeep(prevValue);
        set(val, type, optionIds);
        return val;
      });
      return;
    }

    // if (optionIds) backboneContext.changeOptionOrder(type, optionIds);
  };

  const handleSubmit = () => {
    handleClose();

    setTimeout(() => {
      let shouldPivotReload = false;

      // Do not allow user change order of columns in locked view
      const columnOrderProperties = {};
      const columnSelectionProperties = {};
      if (!isLockedView || isEditMode) {
        Object.keys(orderState).forEach((type) => {
          const initialCurrentOption = _.get(property, ['options', type], []).map((item) => item.id);
          if (metricTractionProperties && type == 'traction') {
            const initialCurrentTractionOption = metricTractionProperties.map((item) => item.id);
            if (!isEqual(initialCurrentTractionOption, orderState[type])) {
              const clone = [...metricTractionProperties];
              clone.sort((a, b) => orderState[type].indexOf(a.id) - orderState[type].indexOf(b.id));
              backboneContext.changeConfig('metricTractionProperties', clone);
            }
          } else if (!isEqual(initialCurrentOption, orderState[type])) {
            shouldPivotReload = true;
            backboneContext.changeOptionOrder(type, orderState[type]);
            columnOrderProperties[type] = orderState[type];
          }
        });
      }

      Object.keys(selectionState).forEach((type) => {
        const initialSelected = _.get(property, ['selectedItems', type], []).map((item) => item.id);
        const currentSelected = selectionState[type].map((item) => item.id);

        if (!isEqual(sortBy(initialSelected), sortBy(currentSelected))) {
          shouldPivotReload = false;
          if (metricTractionProperties && type == 'traction') {
            backboneContext.changeConfig('selectedMetricTraction', currentSelected);
          } else {
            backboneContext.changeConfig(type, currentSelected);
            columnSelectionProperties[type] = currentSelected;
          }
        }
      });

      if (usePivotMultipleHeader != backboneContext.getConfig('usePivotMultipleHeader')) {
        backboneContext.changeConfig('usePivotMultipleHeader', usePivotMultipleHeader);
      }

      if (backboneContext.getConfig('tableMode', 'default') == 'pivot' && shouldPivotReload) {
        backboneContext.reloadData('table');
      }

      // Only allow admin to update lock properties
      if (isEditMode && isLockedView) {
        const version = new Date().getTime();
        const view = backboneContext.getConfig('view');
        set(view, LOCKED_VIEW_CONFIG_PROPERTY, lockState);
        set(view, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);

        const propertiesPath = ['combinator', 'properties'];
        const columnOrderPath = ['combinator', 'columnOrder'];
        const settingType = backboneContext.getConfig('settingType', []);
        const columnProperties = get(view, ['combinator', 'properties'], {});
        const newColumnProperties = Object.entries(columnProperties).reduce((carry, [key, value]) => {
          return {
            ...carry,
            [key]: columnSelectionProperties[key] ? columnSelectionProperties[key] : value,
          };
        }, {});
        const columnOrder = settingType.reduce((carry, { type }) => {
          const newOption = columnOrderProperties[type] ? columnOrderProperties[type] : columnProperties[type];
          return carry.concat(newOption);
        }, []);
        set(view, propertiesPath, newColumnProperties);
        set(view, columnOrderPath, columnOrder);

        const views = backboneContext.getConfig('views');
        for (const v of views) {
          if (v.id === view.id) {
            set(v, LOCKED_VIEW_CONFIG_PROPERTY, lockState);
            set(v, LOCKED_VIEW_CONFIG_LAST_SYSTEM_UPDATE, version);
            set(v, propertiesPath, newColumnProperties);
            set(v, columnOrderPath, columnOrder);
          }
        }

        backboneContext.updateConfig({
          view,
          views,
        });
      }
    }, getConst(DELAY_CLOSE_POPUP, 100));
  };

  const renderProperties = React.useMemo(() => {
    return get(property, ['settingTypes'], [])
      .filter(
        ({ type }) =>
          get(property, ['options', type], []).length > 0 &&
          get(property, ['selectedItems', type], null) &&
          (!properties || [].concat(properties).includes(type)),
      )
      .map(({ label, type, componentType, selectLimit }) => {
        const options = get(property, ['options', type], []).map((i) => ({
          ...i,
          label: i.name,
          value: i.id,
        }));
        const selectedItems = get(property, ['selectedItems', type], []).map((i) => i.id);
        return (
          <Property
            key={type}
            defaultOptions={options}
            defaultSelectedItems={selectedItems}
            label={label}
            handleUpdateSelection={handleUpdateSelection}
            handleReorder={handleReorder}
            type={type}
            disabledOptions={get(property, ['disabledOptions', type], [])}
            usePivotMultipleHeader={usePivotMultipleHeader}
            setUsePivotMultipleHeader={setUsePivotMultipleHeader}
          />
        );
      });
  }, [property]);

  return (
    <div className={classes.container}>
      <div className={classes.propertiesContainer}>{renderProperties}</div>
      <div style={{ paddingRight: '8px', paddingBottom: '8px' }}>
        <ButtonActions onCancel={handleClose} onSubmit={handleSubmit} primaryLabel={'Apply'} />
      </div>
    </div>
  );
};

const PivotMetricAction = ({ usePivotMultipleHeader, setUsePivotMultipleHeader }) => {
  const classes = usePivotMetricStyles();
  const [value, setValue] = React.useState(usePivotMultipleHeader);

  React.useEffect(() => {
    setUsePivotMultipleHeader(value);
  }, [value]);

  return (
    <div className={classes.container} onClick={() => setValue((value) => (value != 'no' ? 'no' : 'yes'))}>
      <Tooltip
        title={value != 'no' ? 'Horizontal pivot' : 'Vertical pivot'}
        placement={'bottom'}
        classes={{ tooltip: classes.tooltipContainer }}
      >
        <span>
          <Icon
            type={
              value != 'no'
                ? 'ic/fluent:align-space-evenly-horizontal-20-filled/charcoal'
                : 'ic/fluent:align-space-evenly-vertical-20-filled/charcoal'
            }
          />
        </span>
      </Tooltip>
    </div>
  );
};

const Property = ({
  defaultOptions,
  defaultSelectedItems,
  label,
  handleUpdateSelection,
  handleReorder,
  type,
  disabledOptions,
  usePivotMultipleHeader,
  setUsePivotMultipleHeader,
}: any) => {
  const classes = usePropertyStyles();
  const [options, setOptions] = React.useState(defaultOptions);
  const [selectedItems, setSelectedItems] = React.useState(defaultSelectedItems);

  React.useEffect(() => {
    const optionsIds = options.map(({ id }) => id);
    handleReorder(type, optionsIds);
  }, [options]);

  React.useEffect(() => {
    const selectedItemsObject = selectedItems.map((i) => {
      return {
        id: i,
      };
    });
    handleUpdateSelection(type, selectedItemsObject);
  }, [selectedItems]);
  return (
    <div className={classes.container}>
      <MultipleSelectComponent
        options={options}
        setOptions={setOptions}
        selectedItems={selectedItems}
        setSelectedItems={setSelectedItems}
        title={label}
        loading={false}
        disabledOptions={disabledOptions}
      />
      {type == 'pivot_metric' ? (
        <div className={classes.toggle}>
          <PivotMetricAction
            usePivotMultipleHeader={usePivotMultipleHeader}
            setUsePivotMultipleHeader={setUsePivotMultipleHeader}
          />
        </div>
      ) : null}
    </div>
  );
};

export default DropdownProperties;
