import UploadInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/upload-input';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { IImageFormat } from '@ep/insight-ui/elements/table/format/image-format';
import Icon from '@ep/insight-ui/icons/Icon';
import { Avatar, Box, Grid, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  wrapAvatar: {
    '&:hover': {
      '& .avatar': {
        borderColor: '#E4E7E9',
      },
      '& .icon': {
        backgroundColor: '#E4E7E9',
      },
    },
  },
  avatar: {
    border: '2px solid',
    borderColor: 'transparent',
    height: '105px',
    width: '105px',
    transition: '0.15s all',
  },
  icon: {
    position: 'absolute',
    bottom: 0,
    right: 8,
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    borderRadius: '50%',
    height: '32px',
    width: '32px',
  },
}));

type Props = {
  data: {
    value: IImageFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const PhotoEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, anchorEl, setAnchorEl } = props;
  const inputRef = React.useRef<HTMLDivElement>(null);
  const classes = useStyles();
  const defaultValue = React.useMemo(() => {
    if (data.value.image) return data.value.image;
    return '';
  }, [data.value, anchorEl]);

  const [avatar, setAvatar] = React.useState(defaultValue);

  const handleClosed = () => {
    setAnchorEl(null);
  };

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleClickAvatar = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleSubmit = async () => {
    const valueTmp = JSON.parse(JSON.stringify(data.value));
    valueTmp.image = avatar;
    setIsSubmitting(true);
    const response: any = await onSubmit({ value: valueTmp, row: data.node.data });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  const handleChangeImage = (file) => {
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (e) => {
      const base64Str = `data:${file.type};base64,${btoa(e.target.result)}`;
      setAvatar(base64Str);
    };
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '198px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <Grid item xs={12} container wrap="nowrap" alignItems="center" justifyContent={'center'}>
          <Box position={'relative'} className={classes.wrapAvatar}>
            <Avatar src={avatar} className={clsx(classes.avatar, 'avatar')} />
            <IconButton className={clsx(classes.icon, 'icon')} onClick={handleClickAvatar}>
              <Icon type={'edit'} />
            </IconButton>
          </Box>
          <Box ref={inputRef} display={'none'}>
            <UploadInput onChange={handleChangeImage} onRef={(ref) => (inputRef.current = ref.current)} />
          </Box>
        </Grid>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default PhotoEditor;
