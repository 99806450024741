import * as React from 'react';
import AreaChartResponsive from '../../../chartlib/area-chart';
import { generalLabels, generateRandomData } from '../../../chartlib/helper';
import { IDataChart } from '../../../chartlib/type';
import { IStateChartValue } from '../../../chartlib/chart-loading-state/chart-loading-state';
import { IConfigTable, makeTable } from '@ep/insight-ui/elements/table/table-container';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { CHART_CONFIG, FORMAT_CHART } from '@ep/insight-ui/chartlib/area-chart/chart-config';
import moment from 'moment';

const data: IDataChart = {
  headers: generalLabels({ month: '10', year: '2021', DateFormatView: 'DD/MM/YYYY' }),
  rows: [
    {
      label: 'Vietnam',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 12000,
        minValue: 5000,
      }),
    },
    {
      label: 'Thailand',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 4000,
      }),
    },
    {
      label: 'Singapore',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 3000,
      }),
    },
    {
      label: 'Philipines',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 1000,
      }),
    },
    {
      label: 'Malaysia',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 2000,
      }),
    },
    {
      label: 'Indonesia',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10400,
        minValue: 3000,
      }),
    },
    {
      label: 'USA',
      data: generateRandomData({
        month: '10',
        year: '2021',
        DateFormatView: 'DD/MM/YYYY',
        maxValue: 10000,
        minValue: 4000,
      }),
    },
  ],
};

const { period, formatTime } = FORMAT_CHART.traction;

const valueDate = data.rows.reduce((a, b) => {
  b.data.forEach((el) => {
    const [time, value] = Object.values(el);
    if (!a[b.label])
      a[b.label] = {
        [moment(time).format(formatTime)]: value,
      };
    else a[b.label][moment(time).format(formatTime)] = value;
  });
  return a;
}, {});

const AreaChart = () => {
  const stateChart: IStateChartValue = 'success';
  const config = {
    apiRequest: {},
    configuration: CHART_CONFIG,
  };

  const changeConfiguration = (config: IConfigTable) => {
    console.log('Update configuration', config);
  };
  const headers = ['country', ...data.headers.map((el) => `${el}`)];

  const RESPONSE_CHART = {
    data: {
      headers,
      pagination: { limit: 100, page: 1, total: -1 },
      primaryKeys: [],
      rows: data.rows.map((row) => ({
        country: row.label,
        ...valueDate[row.label],
      })),
    },
    message: 'OK',
    code: 200,
  };

  const tableContainer = React.useMemo(
    () =>
      makeTable({
        config: {
          ...config,
          addons: {
            'table.columns.enhance': (columnDefs, columns, backbone) => {
              const additionalColumns = data.headers.map((el) => {
                return {
                  headerName: moment(el).format(formatTime),
                  field: moment(el).format(formatTime),
                  colId: moment(el).format(formatTime),
                  resizable: false,
                  suppressMovable: true,
                  cellStyle: {
                    borderRightColor: 'whitesmoke',
                  },
                  headerComponentParams: {
                    type: 'number',
                  },
                  width: 200,
                  cellRendererParams: {},
                  cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    paddingRight: '10px',
                  },
                };
              });
              return [...columnDefs, ...additionalColumns];
            },
            'datasource.apiRequest.getTableData': async (params, originRequest) => {
              return Promise.resolve(RESPONSE_CHART);
            },
          },
        },
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [],
  );

  return (
    <>
      <div style={{ marginBottom: '100px', height: '100vh' }}>
        <AreaChartResponsive
          keyChart="are_demo"
          stateChart={stateChart}
          title={'Hihi'}
          value={'600'}
          currency={'IDR'}
          percent={15}
          data={data}
        />
        {tableContainer}
      </div>
    </>
  );
};

export default AreaChart;
