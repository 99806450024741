import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Lock = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.99996 5.46707V4.20041C10.0072 3.1403 9.59754 2.11981 8.85922 1.35905C8.1209 0.598282 7.11314 0.158207 6.05329 0.13374C5.52353 0.126676 4.99765 0.224923 4.50618 0.422777C4.0147 0.620631 3.56743 0.914149 3.19033 1.28629C2.81322 1.65843 2.51381 2.10178 2.30946 2.59059C2.10512 3.0794 1.99991 3.60394 1.99996 4.13374V5.46707C1.64634 5.46707 1.3072 5.60755 1.05715 5.8576C0.807102 6.10765 0.666626 6.44678 0.666626 6.80041V12.1337C0.666626 12.4874 0.807102 12.8265 1.05715 13.0765C1.3072 13.3266 1.64634 13.4671 1.99996 13.4671H9.99996C10.3536 13.4671 10.6927 13.3266 10.9428 13.0765C11.1928 12.8265 11.3333 12.4874 11.3333 12.1337V6.80041C11.3333 6.44678 11.1928 6.10765 10.9428 5.8576C10.6927 5.60755 10.3536 5.46707 9.99996 5.46707ZM5.99996 10.8004C5.73625 10.8004 5.47846 10.7222 5.2592 10.5757C5.03993 10.4292 4.86904 10.221 4.76812 9.97732C4.6672 9.73368 4.6408 9.46559 4.69225 9.20695C4.74369 8.94831 4.87068 8.71074 5.05715 8.52427C5.24362 8.3378 5.4812 8.21081 5.73984 8.15936C5.99848 8.10791 6.26657 8.13432 6.5102 8.23523C6.75384 8.33615 6.96208 8.50705 7.10858 8.72631C7.25509 8.94558 7.33329 9.20337 7.33329 9.46707C7.33329 9.8207 7.19282 10.1598 6.94277 10.4099C6.69272 10.6599 6.35358 10.8004 5.99996 10.8004ZM8.66663 5.46707H3.33329V4.13374C3.32508 3.7813 3.38845 3.43086 3.51955 3.10362C3.65066 2.77637 3.84678 2.47912 4.09606 2.22984C4.34534 1.98056 4.64259 1.78444 4.96984 1.65333C5.29708 1.52223 5.64752 1.45886 5.99996 1.46707C6.3524 1.45886 6.70284 1.52223 7.03008 1.65333C7.35733 1.78444 7.65458 1.98056 7.90386 2.22984C8.15314 2.47912 8.34926 2.77637 8.48037 3.10362C8.61147 3.43086 8.67484 3.7813 8.66663 4.13374V5.46707Z"
      fill={props.color ? props.color : '#3F4F5C'}
    />
  </SvgIcon>
);

export default Lock;
