import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Logout = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.7648 8.54809C13.8392 8.47953 13.8984 8.39764 13.9388 8.30724C13.9792 8.21683 14 8.11973 14 8.02163C14 7.92354 13.9792 7.82644 13.9388 7.73603C13.8984 7.64562 13.8392 7.56373 13.7648 7.49517L11.401 5.22162C11.3283 5.15147 11.2413 5.09572 11.1454 5.05766C11.0494 5.01961 10.9464 5 10.8422 5C10.7381 5 10.6351 5.01961 10.5391 5.05766C10.4431 5.09572 10.3562 5.15147 10.2834 5.22162C10.1338 5.36268 10.0486 5.5533 10.0461 5.75274L10.0461 7.08519L5.98901 7.08229C5.72671 7.08229 5.47515 7.18046 5.28967 7.3552C5.1042 7.52995 5 7.76695 5 8.01407C5 8.2612 5.1042 8.4982 5.28967 8.67294C5.47515 8.84769 5.72671 8.94586 5.98901 8.94586L10.0461 8.94876L10.0461 10.2533C10.0461 10.451 10.1294 10.6406 10.2778 10.7804C10.4262 10.9201 10.6275 10.9987 10.8373 10.9987C10.9397 11.0045 11.0423 10.991 11.1391 10.959C11.2359 10.927 11.3249 10.8771 11.401 10.8123L13.7648 8.54809Z"
      fill={props.color}
    />
    <rect x="2" y="2" width="2" height="12" rx="1" fill={props.color} />
    <rect x="8" y="2" width="2" height="6" rx="1" transform="rotate(90 8 2)" fill={props.color} />
    <rect x="8" y="12" width="2" height="6" rx="1" transform="rotate(90 8 12)" fill={props.color} />
  </SvgIcon>
);

export default Logout;
