import { cellFormat } from '@ep/insight-ui/elements/table/table-helper';
import { getActionMenu } from '../table-config/common-config';
import { DATA_TYPE, generateColumnKeys, PROPERTY_TYPE } from './common';

import metricDefs from './resource-metric';

export const options = [
  'accumulationFormat',
  'impressionFormat',
  'storefrontMetricTraction',
  'metricTraction',
  'epsiloMetric',
  'operationMetric',
  'qualityMetric',
  'dateTimeMetric',
  // ...Object.keys(accumulationCellFormat),
  'oneMetric',
  'tractionElasticity',
  'tractionElasticity1',
];

import { CellFormatType } from './cell-format-type';

export const storefrontMetricTractionOptions = metricDefs.map((i) => ({
  label: i.metric_name,
  value: i.metric_code,
  prefix: i.prefix_value,
}));

// getStorefrontMetricTraction();
export const getCellFormat = (name) => {
  switch (name) {
    case 'storefrontMetricTraction':
      return {
        component: cellFormat?.text,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'metric',
          },
          staticValue: {
            selectedMetrics: [],
            period: 'daily',
          },
        },
        label: 'Storefront metric traction',
        eTableEditor: {
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
            // Temporarily comment this, may use it later
            // selectedMetrics: {
            //   type: CellFormatType.COMPACT_SELECT_TABLE,
            //   isMultiple: true,
            //   dataType: CellFormatDataType.ARRAY,
            //   rowData: storefrontMetricTractionOptions,
            //   colDefs: [{ field: 'label', headerName: 'Field', editable: false }],
            // },
            selectedMetrics: {
              type: CellFormatType.MULTI_SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
        },
      };
    case 'metricTraction':
      return {
        component: cellFormat?.currency,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'Metric traction',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'epsiloMetric':
      return {
        component: cellFormat?.currencyFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'Epsilo metric',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'operationMetric':
      return {
        component: cellFormat?.currencyFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'Operation Metric (multiple column)',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'qualityMetric':
      return {
        component: cellFormat?.currencyFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'Quality Metric (multiple column)',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'dateTimeMetric':
      return {
        component: cellFormat?.dateTimeCompare,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'storefront.updated_at',
          },
          staticValue: {
            dateTime: 'absolute',
          },
        },
        label: 'Date Time',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            dateTime: {
              type: CellFormatType.SELECT,
              options: [
                { label: 'Absolute', value: 'absolute' },
                { label: 'Relative', value: 'relative' },
              ],
            },
          },
        },
      };
    case 'onePivot':
      return {
        component: cellFormat?.pivotFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
            showAs: '="number"',
            dividedBy: '=100',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'One pivot',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'oneMetric':
      return {
        component: cellFormat?.currencyFormatFormula,
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: 'gmv',
            showAs: '="number"',
            dividedBy: '=100',
          },
          staticValue: {
            period: 'daily',
          },
        },
        label: 'One metric',
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
          staticValue: {
            period: {
              type: CellFormatType.SELECT,
              options: [
                {
                  label: 'daily',
                  value: 'daily',
                },
                {
                  label: 'weekly',
                  value: 'weekly',
                },
              ],
            },
          },
        },
      };
    case 'tractionElasticity':
      return {
        component: cellFormat?.tractionElasticityFormat,
        label: 'Traction Elasticity',
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: '',
            value2: '',
          },
          staticValue: {
            period: 'daily',
            displayChart: 'yes',
            'value.color': '#204D77',
            'value.lineDash': '10,5',
            chartStyle: 'line',
            fillSlot: 'yes',
            'value2.color': '#e4553e',
            'value2.chartStyle': 'line',
            'value2.lineDash': '10,5',
          },
        },
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
        },
      };
    case 'tractionElasticity1':
      return {
        component: cellFormat?.tractionElasticityFormat,
        label: 'Traction Elasticity1',
        defaultConfig: {
          columnKeys: generateColumnKeys(name),
          propertyType: PROPERTY_TYPE.METRIC,
          cellFormat: name,
          dataType: DATA_TYPE.STRING,
          filterField: '',
          sortField: '',
          valueGetter: {
            value: '',
          },
          staticValue: {
            period: 'hourly',
          },
        },
        eTableEditor: {
          valueGetter: {
            value: {
              type: CellFormatType.SELECT,
              options: storefrontMetricTractionOptions,
            },
          },
        },
      };
    default:
      return null;
  }
};
