import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Resize = (props: SvgIconProps) => (
  <SvgIcon {...props} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.679 0H8.84332C8.77787 0.000917514 8.71412 0.0209842 8.65999 0.0577119C8.60587 0.0944397 8.56373 0.146213 8.53881 0.206615C8.51388 0.267016 8.50727 0.333389 8.51979 0.397504C8.53231 0.46162 8.56341 0.520658 8.60924 0.567301L9.55223 1.50835L6.87708 4.178C6.81472 4.24023 6.76526 4.31411 6.73151 4.39541C6.69776 4.47672 6.6804 4.56386 6.6804 4.65186C6.6804 4.73987 6.69776 4.82701 6.73151 4.90832C6.76526 4.98962 6.81472 5.0635 6.87708 5.12573C7.00301 5.2514 7.17382 5.32201 7.35192 5.32201C7.4401 5.32201 7.52743 5.30467 7.6089 5.271C7.69037 5.23732 7.7644 5.18796 7.82676 5.12573L10.5019 2.45608L11.4449 3.39713C11.4916 3.44286 11.5508 3.4739 11.615 3.48639C11.6793 3.49889 11.7458 3.49229 11.8063 3.46742C11.8669 3.44255 11.9187 3.4005 11.9555 3.34648C11.9923 3.29246 12.0124 3.22885 12.0134 3.16353V0.333707C12.0134 0.245202 11.9781 0.160322 11.9154 0.0977402C11.8527 0.0351581 11.7677 0 11.679 0Z" />
    <path d="M5.16972 6.85425C5.10754 6.7917 5.03358 6.74205 4.95208 6.70816C4.87058 6.67428 4.78317 6.65683 4.69488 6.65683C4.60659 6.65683 4.51918 6.67428 4.43768 6.70816C4.35618 6.74205 4.28221 6.7917 4.22004 6.85425L1.54489 9.5239L0.581836 8.60287C0.535098 8.55714 0.475937 8.52611 0.41169 8.51361C0.347442 8.50112 0.280933 8.50771 0.220407 8.53259C0.159881 8.55746 0.108001 8.59951 0.0711975 8.65353C0.0343941 8.70754 0.0142861 8.77115 0.0133667 8.83647V11.6663C0.0133667 11.7548 0.0485973 11.8397 0.111308 11.9023C0.174019 11.9648 0.259073 12 0.34776 12H3.18342C3.24887 11.9991 3.31261 11.979 3.36674 11.9423C3.42087 11.9056 3.46301 11.8538 3.48793 11.7934C3.51285 11.733 3.51946 11.6666 3.50694 11.6025C3.49442 11.5384 3.46332 11.4793 3.41749 11.4327L2.4745 10.4917L5.14965 7.822C5.21673 7.76102 5.27067 7.68706 5.30819 7.60461C5.34572 7.52216 5.36603 7.43296 5.36791 7.34243C5.36979 7.25189 5.35319 7.16193 5.31911 7.078C5.28504 6.99408 5.23421 6.91795 5.16972 6.85425Z" />
  </SvgIcon>
);

export default Resize;
