import * as React from 'react';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box } from '@material-ui/core';
import { get } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import MixedChartFormat from './format/mixed-chart-format';
import { useTimeBasedCell } from './hooks/time-based-cell';
import { makeTable } from './table-container';
import { INTRADAY_CONFIG } from '@ep/insight-ui/system/block/etable/table-config/intraday-config';

const data = [
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Face serum - Flash sales -2021 Jul',
    'campaign.sub': 'CAMPSP60B5FBA133C6D',
    storefront: 'XYZ Beauty',
    ad_tool: 'Tokopedia, Product Ad',
    ad_object: 'Sakura White Pinkish Radiance Serum Milk UV Body Lotion Skin Care - 400 ml (Twin Pack)',
    keyword: 'mascara',
    'storefront_sos.main': '22%',
    'storefront_sos.sub': 'Oct/25 9:32:02 by Botep',
    'keyword_bidding_price.main': '535 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:32:02 by Botep',
    'suggested_bidding_price.main': '586 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:11:02 by Botep',
    'daily_budget.main': '601,822 IDR',
    'daily_budget.sub': 'Approved by Scarlet N',
    'script_activation.main': 'Attribution delay check',
    'script_activation.sub': '48 times triggered by Botep',
    'ad_spend_intrday.main': '3,702,805 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:18:02',
    'ad_spend_rr.main': '9,679,357 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '67,293,588 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:11:02',
    typeDisplay: 'text-number',
    progress: 68,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Cosmetic - Clearance -2021 August ',
    'campaign.sub': 'CAMPSP60B5FBAD39D58',
    storefront: 'Chee An Vat',
    ad_tool: 'Shopee, Keyword Ad',
    ad_object: 'Excellence Fashion - Matt Ash 7.17 x2pcs [Pewarna Rambut Permanen]',
    keyword: 'lipstick',
    'storefront_sos.main': '68%',
    'storefront_sos.sub': 'Oct/25 9:11:02 by Botep',
    'keyword_bidding_price.main': '1,120 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:40:02 by Botep',
    'suggested_bidding_price.main': '4,754 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:37:02 by Botep',
    'daily_budget.main': '7,957,240 IDR',
    'daily_budget.sub': 'Approved by Angeline G',
    'script_activation.main': 'Penalize low-performance ads',
    'script_activation.sub': '27 times triggered by Botep',
    'ad_spend_intrday.main': '8,629,391 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:22:02',
    'ad_spend_rr.main': '21,415,680 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '9,608,518 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:18:02',
    typeDisplay: 'text-number',
    progress: 39,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Lipstick - Brand day - 2021 - July',
    'campaign.sub': 'CAMPSP60B5FBB14B3CC',
    storefront: 'XYZ Beauty',
    ad_tool: 'Tokopedia, Product Ad',
    ad_object: 'Sakura White Hyaluron Skin Care Starter Kit X Chelsea (Untuk Kulit Glowing & Cerah Merona)',
    keyword: 'fondation',
    'storefront_sos.main': '45%',
    'storefront_sos.sub': 'Oct/25 9:22:02 by Botep',
    'keyword_bidding_price.main': '4,448 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:22:02 by Botep',
    'suggested_bidding_price.main': '645 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:48:02 by Botep',
    'daily_budget.main': '8,629,391 IDR',
    'daily_budget.sub': 'Approved by Angeline G',
    'script_activation.main': 'Maximize daily ads profit',
    'script_activation.sub': '25 times triggered by Botep',
    'ad_spend_intrday.main': '83,426,635 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:11:02',
    'ad_spend_rr.main': '601,822 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '601,822 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:32:02',
    typeDisplay: 'text-number',
    progress: 1112,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Cosmetic - Clearance -2021 August ',
    'campaign.sub': 'CAMPSP60DEC172763D3',
    storefront: 'Chee An Vat',
    ad_tool: 'Shopee, Keyword Ad',
    ad_object: 'Sakura White Body Lotion & Hand Cream (Perawatan Untuk Kulit Cerah Merona)',
    keyword: 'hypercurl',
    'storefront_sos.main': '32%',
    'storefront_sos.sub': 'Oct/25 9:18:02 by Botep',
    'keyword_bidding_price.main': '535 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:37:02 by Botep',
    'suggested_bidding_price.main': '574 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:22:02 by Botep',
    'daily_budget.main': '9,608,518 IDR',
    'daily_budget.sub': 'Approved by Amir H',
    'script_activation.main': 'Leverage high ROAS search ads',
    'script_activation.sub': '5 times triggered by Botep',
    'ad_spend_intrday.main': '21,415,680 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:37:02',
    'ad_spend_rr.main': '51,542,141 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '83,426,635 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:22:02',
    typeDisplay: 'text-number',
    progress: 45,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Lipstick - Brand day - 2021 - July',
    'campaign.sub': 'CAMPSP60E471804DD0F',
    storefront: 'XYZ Beauty',
    ad_tool: 'Tokopedia, Product Ad',
    ad_object: 'Brilliant Signature Liquid Lipstick 327 & Unbelievabrow Brow Gel 113 - Warm Brown',
    keyword: 'eyeliner',
    'storefront_sos.main': '0%',
    'storefront_sos.sub': 'Oct/25 9:48:02 by Botep',
    'keyword_bidding_price.main': '181 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:18:02 by Botep',
    'suggested_bidding_price.main': '735 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:12:02 by Botep',
    'daily_budget.main': '51,542,141 IDR',
    'daily_budget.sub': 'Approved by Abhishek',
    'script_activation.main': 'Leverage high CTR product ads',
    'script_activation.sub': '72 times triggered by Botep',
    'ad_spend_intrday.main': '601,822 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:32:02',
    'ad_spend_rr.main': '4,476,538 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '51,542,141 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:37:02',
    typeDisplay: 'text-number',
    progress: 345,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Cosmetic - Clearance -2021 August ',
    'campaign.sub': 'CAMPSP60B5FB9893723',
    storefront: 'Olispe Official Store',
    ad_tool: 'Tokopedia, Product Ad',
    ad_object: 'Bright Complete White Speed Serum Day Cream Extra SPF 36/PA+++ Skin Care - 50 ml',
    keyword: 'lipstick ',
    'storefront_sos.main': '18%',
    'storefront_sos.sub': 'Oct/25 9:37:02 by Botep',
    'keyword_bidding_price.main': '155 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:11:02 by Botep',
    'suggested_bidding_price.main': '564 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:40:02 by Botep',
    'daily_budget.main': '67,293,588 IDR',
    'daily_budget.sub': 'Approved by Angeline G',
    'script_activation.main': 'Ride the daily campaign momentum',
    'script_activation.sub': '0 times triggered by Botep',
    'ad_spend_intrday.main': '34,066,989 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:37:02',
    'ad_spend_rr.main': '627,032 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '7,957,240 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:37:02',
    typeDisplay: 'text-number',
    progress: 27,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Face serum - Flash sales -2021 Jul',
    'campaign.sub': 'CAMPSP60B5FBAA5E7EF',
    storefront: 'Chee An Vat',
    ad_tool: 'Shopee, Keyword Ad',
    ad_object: 'Superstay Matte Ink Liquid Matte Lipstick Initiator & Delicate Make Up',
    keyword: 'kosmetik',
    'storefront_sos.main': '42%',
    'storefront_sos.sub': 'Oct/25 9:37:02 by Botep',
    'keyword_bidding_price.main': '612 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:37:02 by Botep',
    'suggested_bidding_price.main': '612 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:37:02 by Botep',
    'daily_budget.main': '4,476,538 IDR',
    'daily_budget.sub': 'Approved by Scarlet N',
    'script_activation.main': 'Product ads - avoid spending on underperforming ads',
    'script_activation.sub': '6 times triggered by Botep',
    'ad_spend_intrday.main': '67,293,588 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:40:02',
    'ad_spend_rr.main': '7,957,240 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '4,476,538 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:12:02',
    typeDisplay: 'text-number',
    progress: 107,
  },
  {
    cohort: 'High budget; high performance',
    'campaign.main': 'Face serum - Flash sales -2021 Jul',
    'campaign.sub': 'CAMPSP60B5FBA8CC36C',
    storefront: 'XYZ Beauty',
    ad_tool: 'Shopee, Keyword Ad',
    ad_object: 'Sakura White Hyaluron 30x Booster Serum Skin Care - 30 ml [Untuk Kulit Glowing Dalam 7 Hari]',
    keyword: 'foundation',
    'storefront_sos.main': '27%',
    'storefront_sos.sub': 'Oct/25 9:49:02 by Botep',
    'keyword_bidding_price.main': '655 IDR',
    'keyword_bidding_price.sub': 'Oct/25 9:48:02 by Botep',
    'suggested_bidding_price.main': '562 IDR',
    'suggested_bidding_price.sub': 'Oct/25 9:18:02 by Botep',
    'daily_budget.main': '83,426,635 IDR',
    'daily_budget.sub': 'Approved by Amir H',
    'script_activation.main': 'Pause losing search ad permanently',
    'script_activation.sub': '11 times triggered by Botep',
    'ad_spend_intrday.main': '9,608,518 IDR',
    'ad_spend_intrday.sub': 'Oct/25 9:37:02',
    'ad_spend_rr.main': '9,608,518 IDR',
    'ad_spend_rr.sub': 'by Botep',
    'ad_gmv_intraday.main': '135,395,416 IDR',
    'ad_gmv_intraday.sub': 'Updated at Oct/25 9:37:02',
    typeDisplay: 'text-number',
    progress: 872,
  },
];

export const Table = ({ configuration = INTRADAY_CONFIG, period = 1 }: { configuration: any; period?: number }) => {
  const config = {
    tableType: '',
    apiRequest: {},
    configuration,
  };

  const changeConfiguration = (config: any) => {
    console.log('Update configuration', config);
  };

  const loadedData = {};

  const tableContainer = React.useMemo(
    () =>
      makeTable({
        config: {
          ...config,
          callback: {
            onBackboneReady: (backbone) => {
              console.info('backbone ready', backbone);
            },
          },
          addons: {
            getTimeValue: async (
              time,
              metricId, // example: roas | ad_gmv
              rowIndex,
            ): Promise<{
              value?: string | number;
              unit?: string;
              isPercentage?: boolean;
              label?: string;
              error: boolean;
            }> => {
              /**
               * Please provide your mock data here.
               * 1. Based on the configuration the time-series columns will be generated, if the metricGroup is 2 item, then every period passed there are 2 column append
               * 2. When querying new value for new period, display a loading icon when data is not avaible, eg. Promise is loading.
               * 3. To access addon, under the component use the following `backbone.addon('getTimeValue', yourDefaultFunction)
               */
              return new Promise((resolve, reject) => {
                if (!loadedData[time]) {
                  loadedData[time] = {};
                }
                if (loadedData[time] && loadedData[time][rowIndex] && loadedData[time][rowIndex][metricId]) {
                  resolve(loadedData[time][rowIndex][metricId]);
                } else {
                  setTimeout(() => {
                    const randomNumber = Math.round(Math.random() * 9);
                    // 20% error
                    // if (randomNumber > 7) {
                    //   const data = {
                    //     error: true,
                    //   };
                    //   reject(data);
                    // } else {
                    const data = {
                      value: (Math.random() * 10).toFixed(2),
                      unit: metricId === 'ad_gmv' ? '%' : 'x',
                      isPercentage: metricId === 'ad_gmv',
                      label: metricId === 'ad_gmv' ? '% Ad GMV' : 'ROAS',
                      error: false,
                    };
                    if (!loadedData[time][rowIndex]) loadedData[time][rowIndex] = {};
                    loadedData[time][rowIndex][metricId] = data;
                    resolve(data);
                    // }
                  }, 2000);
                }
              });
            },
            'table.columns.enhance': (columnDefs, columns, backbone) => {
              const hasMixedChart = columns.some((column) => column.cell?.format === MixedChartFormat);
              const { timeBasedColumns } = useTimeBasedCell(period, hasMixedChart, backbone);
              return [...columnDefs, ...Object.values(timeBasedColumns)];
            },
            'datasource.apiRequest.getTableData': async (params, originRequest) => {
              if (get(params, 'groupBy.drillDowns', []).length == 0) {
                const response = {
                  data: {
                    headers: Object.keys(data[0]),
                    primaryKeys: ['ad_object', 'campaign.sub', 'keyword'],
                    rows: [
                      {
                        cohort: 'High budget; high performance',
                        'campaign.main': '28',
                        'campaign.sub': 'CAMPSP60B5FBA133C6D',
                        storefront: '9',
                        ad_tool: '2',
                        ad_object: '50',
                        keyword: '225',
                        'storefront_sos.main': '24%',
                        'storefront_sos.sub': 'Oct/25 9:32:02 by Botep',
                        'keyword_bidding_price.main': '235 IDR',
                        'keyword_bidding_price.sub': 'Oct/25 9:32:02 by Botep',
                        'suggested_bidding_price.main': '906 IDR',
                        'suggested_bidding_price.sub': 'Oct/25 9:11:02 by Botep',
                        'daily_budget.main': '804,302 IDR',
                        'daily_budget.sub': 'Approved by Scarlet N',
                        'script_activation.main': 'Attribution delay check',
                        'script_activation.sub': '14541 times triggered by Botep',
                        'ad_spend_intrday.main': '3,908,805 IDR',
                        'ad_spend_intrday.sub': 'Oct/25 9:18:02',
                        'ad_spend_rr.main': '10,579,957 IDR',
                        'ad_spend_rr.sub': 'by Botep',
                        'ad_gmv_intraday.main': '4,293,588 IDR',
                        'ad_gmv_intraday.sub': 'Updated at Oct/25 9:11:02',
                        typeDisplay: 'text-number',
                        progress: 68,
                      },
                      {
                        cohort: 'Low budget; high performance',
                        'campaign.main': '17',
                        'campaign.sub': 'CAMPSP60B5FBA133C6D',
                        storefront: '12',
                        ad_tool: '3',
                        ad_object: '1308',
                        keyword: '529',
                        'storefront_sos.main': '15%',
                        'storefront_sos.sub': 'Oct/25 9:32:02 by Botep',
                        'keyword_bidding_price.main': '535 IDR',
                        'keyword_bidding_price.sub': 'Oct/25 9:32:02 by Botep',
                        'suggested_bidding_price.main': '986 IDR',
                        'suggested_bidding_price.sub': 'Oct/25 9:11:02 by Botep',
                        'daily_budget.main': '704,302 IDR',
                        'daily_budget.sub': 'Approved by Scarlet N',
                        'script_activation.main': 'Attribution delay check',
                        'script_activation.sub': '49000 times triggered by Botep',
                        'ad_spend_intrday.main': '7,908,805 IDR',
                        'ad_spend_intrday.sub': 'Oct/25 9:18:02',
                        'ad_spend_rr.main': '2,579,957 IDR',
                        'ad_spend_rr.sub': 'by Botep',
                        'ad_gmv_intraday.main': '1,293,588 IDR',
                        'ad_gmv_intraday.sub': 'Updated at Oct/25 9:11:02',
                        typeDisplay: 'text-number',
                        progress: 68,
                      },
                      {
                        cohort: 'No performance',
                        'campaign.main': '5',
                        'campaign.sub': 'CAMPSP60B5FBA133C6D',
                        storefront: '10',
                        ad_tool: '3',
                        ad_object: '738',
                        keyword: '59',
                        'storefront_sos.main': '10%',
                        'storefront_sos.sub': 'Oct/25 9:32:02 by Botep',
                        'keyword_bidding_price.main': '535 IDR',
                        'keyword_bidding_price.sub': 'Oct/25 9:32:02 by Botep',
                        'suggested_bidding_price.main': '586 IDR',
                        'suggested_bidding_price.sub': 'Oct/25 9:11:02 by Botep',
                        'daily_budget.main': '701,302 IDR',
                        'daily_budget.sub': 'Approved by Scarlet N',
                        'script_activation.main': 'Attribution delay check',
                        'script_activation.sub': '48 times triggered by Botep',
                        'ad_spend_intrday.main': '1,908,805 IDR',
                        'ad_spend_intrday.sub': 'Oct/25 9:18:02',
                        'ad_spend_rr.main': '2,579,957 IDR',
                        'ad_spend_rr.sub': 'by Botep',
                        'ad_gmv_intraday.main': '10,293,588 IDR',
                        'ad_gmv_intraday.sub': 'Updated at Oct/25 9:11:02',
                        typeDisplay: 'text-number',
                        progress: 68,
                      },
                    ],
                    pagination: { limit: 100, page: 1, total: 3 },
                  },
                };
                return Promise.resolve(response);
              }
              console.info('datasource.apiRequest.getTableData', params);
              const response = {
                data: {
                  headers: Object.keys(data[0]),
                  pagination: { limit: 100, page: 1, total: data.length },
                  primaryKeys: ['product'],
                  rows: data,
                },
                message: 'OK',
                code: 200,
              };

              return Promise.resolve(response);
            },
          },
        },
        changeConfiguration,
        tableBackboneHook: useTableBackbone,
        tableContext: TableBackboneContext,
      }),
    [period],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Box>{tableContainer}</Box>
    </DndProvider>
  );
};
