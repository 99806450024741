import RemixHeadOne from './RemixHeadOne';
import RemixHeadTwo from './RemixHeadTwo';
import RemixParagraph from './RemixParagraph';
import RemixOrderedList from './RemixOrderedList';
import RemixBulletList from './RemixBulletList';
import RemixRedo from './RemixRedo';
import RemixUndo from './RemixUndo';
import RemixBlockQuote from './RemixBlockQuote';
import RemixCode from './RemixCode';
import RemixStrokeThrough from './RemixStrokeThrough';
import RemixUnderline from './RemixUnderline';
import RemixCodeBlock from './RemixCodeBlock';
import RemixHighlight from './RemixHighlight';
import RemixItalic from './RemixItalic';
import RemixBold from './RemixBold';
import RemixTaskList from './RemixTaskList';
import RemixLink from './RemixLink';
import RemixSeparator from './RemixSeparator';
import RemixTextWrap from './RemixTextWrap';
import RemixFormatClear from './RemixFormatClear';

export default {
  remixHeadOne: RemixHeadOne,
  remixHeadTwo: RemixHeadTwo,
  remixParagraph: RemixParagraph,
  remixOrderedList: RemixOrderedList,
  remixBulletList: RemixBulletList,
  remixRedo: RemixRedo,
  remixUndo: RemixUndo,
  remixBlockQuote: RemixBlockQuote,
  remixCode: RemixCode,
  remixStrokeThrough: RemixStrokeThrough,
  remixUnderline: RemixUnderline,
  remixCodeBlock: RemixCodeBlock,
  remixHighlight: RemixHighlight,
  remixItalic: RemixItalic,
  remixBold: RemixBold,
  remixTaskList: RemixTaskList,
  remixSeparator: RemixSeparator,
  remixTextWrap: RemixTextWrap,
  remixFormatClear: RemixFormatClear,
  remixLink: RemixLink,
};
