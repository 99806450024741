import * as React from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  calculateContainer: {
    '.ag-react-container &': {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '4px',
    },
    width: '100%',
    '.ag-cell-value &': {
      display: 'flex',
      justifyContent: 'flex-end',
      columnGap: '4px',
      alignItems: 'center',
    },
  },
  countLabel: {
    color: 'rgba(55, 53, 47, 0.65)',
    fontSize: '10px',
  },
});

const CalculateCellFormat = (props: ICellRendererParams) => {
  const classes = useStyles();
  if (props.value) {
    return (
      <Box className={classes.calculateContainer}>
        <span className={classes.countLabel}>COUNT</span>
        <span>{props.value}</span>
      </Box>
    );
  }
  return null;
};

export default CalculateCellFormat;
