import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddOS = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.5 12V3.5H6.6875V4.23438C6.6875 4.45011 6.87778 4.625 7.1125 4.625H8.3875C8.62222 4.625 8.8125 4.45011 8.8125 4.23438V3.5H10V12H5.5ZM8.3875 1.5H10.3C11.2389 1.5 12 2.19956 12 3.0625V12.4375C12 13.3004 11.2389 14 10.3 14H5.2C4.26112 14 3.5 13.3004 3.5 12.4375V3.0625C3.5 2.19956 4.26112 1.5 5.2 1.5H7.1125H8.3875Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);

export default AddOS;
