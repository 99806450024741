import Icon from '@ep/insight-ui/icons/Icon';
import Search from '@ep/insight-ui/icons/svg/Search';
import { colors, searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import {
  createStyles,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputBase,
  makeStyles,
  Menu,
  MenuItem,
  MenuProps,
  Select,
  SelectProps,
  TextField,
  withStyles,
  Tooltip,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { AlignType } from '../../dropdown-menu/dropdown';
import HeaderList from '../../list-control/header-list/header-list';
import { OptionSelectType } from '../../list-control/type';
import CircleLoading from '../../loading/circle-loading';
type PropsStyle = {
  inputWidth: string;
  inputHeight: string;
  inputNoShadow: boolean;
};
const useStyles = makeStyles(() => ({
  select: (props: PropsStyle) => ({
    overflow: 'unset',
    width: 'auto',
    '& .eip1-MuiSelect-outlined': {
      paddingRight: '20%',
    },
    '& .eip1-MuiOutlinedInput-input': {
      paddingTop: 0,
      paddingBottom: 0,
    },
    '& .eip1-MuiSelect-selectMenu': {
      paddingRight: '30px !important',
      overflow: 'unset',
      textOverflow: 'unset,',
    },
    // height: props.inputHeight || '17px',
  }),
  selectInput: {
    cursor: 'pointer',
    color: colors.text.default,
    '&.placeholder': {
      color: '#8C98A4',
    },
    '&.error': {
      borderColor: '#f44336',
    },
  },
  popper: {
    marginTop: '4px',
    boxSizing: 'border-box',
  },
  placeholder: {
    color: '#aaa',
  },
  icon: {
    cursor: 'pointer',
    position: 'absolute',
    right: '10px',
    color: colors.icon.default,
  },
  container: {
    '& .eip1-MuiAutocomplete-listbox': {
      padding: '0px !important',
    },
    width: (props: PropsStyle) => props.inputWidth || 'auto',
    '& .eip1-MuiAutocomplete-popupIndicator': {
      marginRight: '5px',
      '&:hover': {
        background: 'transparent',
      },
      '&:active': {
        color: colors.icon.default,
      },
    },
    '& .eip1-MuiInputBase-root.Mui-disabled': {
      backgroundColor: colors.surface.disable,
      color: colors.icon.disabled,
      borderColor: colors.border.subdued,
    },
  },
  input: {
    '&::placeholder': {
      textOverflow: 'ellipsis !important',
      fontSize: 13,
    },
    width: '100%',
  },
  paper: {
    // border: '1px solid #d3d4d5',
    marginTop: 5,
    boxShadow: colors.shadow.hover,
    paddingTop: 0,
    paddingRight: 0,
    width: '244px',
    '& ul': {
      padding: '0 !important',
      height: '100%',
    },
    height: 'auto',
    overflow: 'hidden',
  },
}));

const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      height: '17px',
      border: `1px solid ${colors.border.default}`,
      paddingLeft: '8px',
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&:hover': {
        borderColor: colors.border.highlight,
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
        borderColor: 'unset',
      },
    },
  }),
)(InputBase);
interface IStyledMenu extends MenuProps {
  height: number;
}
// eslint-disable-next-line react/display-name
const StyledMenu = React.forwardRef<IStyledMenu, any>((props, ref) => {
  return (
    <Menu
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      ref={ref}
      style={{ padding: 0 }}
      {...props}
    />
  );
});
const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontWeight: 400,
    borderRadius: '4px',
    '&:focus': {
      backgroundColor: colors.action.subtleHovered,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      borderColor: colors.border.highlight,
      backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
  },
}))(MenuItem);

const BackgroundTooltip = withStyles({
  tooltip: {
    backgroundColor: '#253746',
  },
})(Tooltip);

const useFormControlStyles = makeStyles((theme) => ({
  textStyle: {
    ...(searchStyle as CreateCSSProperties),
    width: '100%',
  },
  textSearch: {
    '& input': {
      height: 30,
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
  },
  srollBar: {
    overflow: 'auto',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  listOptions: {
    borderBottom: '1px solid #E4E7E9',
    padding: '8px 0',
    '&:last-child': {
      borderBottom: 'unset',
    },
  },
}));
type FormControlSearchProps = {
  currentValue: OptionSelectType;
  placeholder?: string;
  options?: OptionSelectType[];
  onChange?: (value: OptionSelectType) => void;
  onChangeKeyWord?: () => void;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  searchAble?: boolean;
  setKeyword?: any;
  field?: string;
};
const FormControlSearch = ({
  currentValue,
  placeholder = '',
  options,
  onChange,
  onChangeKeyWord,
  renderOption,
  searchAble = true,
  setKeyword,
  field,
}: FormControlSearchProps) => {
  const [keyWork, setKeyWork] = React.useState<string>('');
  let optionsPropertyType, settingTypes;

  options.map((val) => {
    if (val.propertyType) {
      settingTypes = Object.keys(options).map((k) => options[k]);
      optionsPropertyType = settingTypes.reduce(function (r, a) {
        r[a.propertyType] = r[a.propertyType] || [];
        r[a.propertyType].push(a);
        return r;
      }, {});
      return optionsPropertyType;
    }
  });

  const results: OptionSelectType[] = React.useMemo(() => {
    return options.filter((option) =>
      keyWork.trim() ? option.label.toLocaleLowerCase().includes(keyWork.toLocaleLowerCase()) : true,
    );
  }, [options, keyWork]);

  React.useEffect(() => {
    onChangeKeyWord && onChangeKeyWord();
  }, [keyWork]);

  const classes = useFormControlStyles();

  const inputRef = React.useRef();
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {searchAble && (
        <FormControl fullWidth>
          <TextField
            style={{
              position: 'sticky',
              top: '0px',
              zIndex: 1,
              backgroundColor: '#fff',
              paddingTop: '8px',
              paddingRight: '8px',
              paddingBottom: '8px',
            }}
            className={clsx(classes.textStyle, classes.textSearch)}
            placeholder={placeholder}
            onChange={(e) => {
              setKeyWork(e.target.value);
            }}
            value={keyWork}
            variant={'outlined'}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ width: 12, height: 14 }} />
                </InputAdornment>
              ),
              endAdornment: _.get(currentValue, 'value', '') && (
                <IconButton onClick={() => setKeyWork('')}>
                  <Icon type={'closeCircle'} />
                </IconButton>
              ),
              classes: { input: classes['input'] },
              fullWidth: true,
            }}
            inputRef={inputRef}
          />
        </FormControl>
      )}

      <div className={clsx(classes.srollBar, 'srollArea')}>
        {results.length > 0 ? (
          !settingTypes ? (
            results.map((item: OptionSelectType, index: number) => (
              <StyledMenuItem
                key={index}
                value={item.value}
                onClick={() => {
                  onChange(item);
                }}
                style={{
                  color: item.value === currentValue.value && '#0369C7',
                  backgroundColor: item.value === currentValue.value && '#EBF6FF',
                }}
              >
                {renderOption ? renderOption(item) : item.label}
              </StyledMenuItem>
            ))
          ) : (
            Object.keys(optionsPropertyType).map((propertyOption, index: number) => {
              const optionsType = results.filter((el) => el.propertyType == propertyOption);
              return (
                <Grid key={index} className={classes.listOptions}>
                  <HeaderList title={propertyOption} />
                  {optionsType &&
                    optionsType.map((item, index) => {
                      return (
                        <>
                          <StyledMenuItem
                            key={index}
                            value={item.value}
                            onClick={() => {
                              onChange(item);
                            }}
                            style={{
                              color: item.value === currentValue.value && '#0369C7',
                              backgroundColor: item.value === currentValue.value && '#EBF6FF',
                            }}
                          >
                            {item.label}
                          </StyledMenuItem>
                        </>
                      );
                    })}
                </Grid>
              );
            })
          )
        ) : (
          <StyledMenuItem disabled style={{ justifyContent: 'center', width: '100%' }}>
            No options
          </StyledMenuItem>
        )}
      </div>
    </>
  );
};

interface SelectFormProp extends SelectProps {
  value: OptionSelectType;
  options?: OptionSelectType[];
  onChangeValue?: (value: OptionSelectType) => void;
  inputWidth?: string;
  inputHeight?: string;
  alignMenu?: AlignType;
  placeholder?: string;
  placeholderInput?: string;
  renderOption?: (option: OptionSelectType) => React.ReactNode;
  disabled?: boolean;
  className?: string;
  classNamePaper?: string;
  style?: React.CSSProperties;
  noShadow?: boolean;
  searchAble?: boolean;
  loading?: any;
  handleRefeshLoading?: any;
  setKeyword?: any;
  field?: string;
  handleRemoveFilter?: () => void;
  isError?: boolean;
}
const SelectFormSearchInside = ({
  alignMenu = 'left',
  value,
  disabled,
  inputWidth,
  inputHeight,
  onChangeValue,
  options,
  placeholder = '',
  placeholderInput,
  renderOption,
  className,
  classNamePaper,
  style,
  noShadow = false,
  searchAble = true,
  loading = {
    isLoading: false,
    message: '',
    status: '',
  },
  handleRefeshLoading = (forceReload = false) => undefined,
  setKeyword,
  field,
  handleRemoveFilter,
  isError,
  ...rest
}: SelectFormProp) => {
  const classes = useStyles({ inputWidth, inputHeight, inputNoShadow: noShadow });
  const [anchorEl, setAnchorEl] = React.useState(null);
  const refPaper = React.useRef<HTMLDivElement>(null);
  const refMenu = React.useRef<HTMLDivElement>(null);

  // Call this to re-render
  const update = React.useState()[1].bind(null, {});

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenu = React.useCallback(() => {
    if (!refMenu.current || !refPaper.current) return;
    const ERROR_PX = 1;
    const PADDING = 8;
    const SEARCH_INPUT_HEIGHT = searchAble ? 38 : 0;
    const paperEl = refPaper.current.querySelector('.eip1-MuiPaper-root') as HTMLDivElement;
    const srollAreaEL = refMenu.current.querySelector('.srollArea') as HTMLDivElement;

    srollAreaEL.style.maxHeight = 'unset';
    const paperHeight = Math.floor(refMenu.current.clientHeight) + PADDING;
    paperEl.style.height = `${paperHeight}px`;

    const maxHeight = Math.floor(paperEl.clientHeight) - SEARCH_INPUT_HEIGHT - PADDING;
    srollAreaEL.style.maxHeight = `${maxHeight}px`;

    if (paperHeight > paperEl.clientHeight) {
      srollAreaEL.style.paddingRight = '0px';
    } else {
      srollAreaEL.style.paddingRight = '8px';
    }
    update();
  }, []);

  React.useEffect(() => {
    handleMenu();
  }, [anchorEl]);

  React.useEffect(() => {
    window.addEventListener('resize', handleMenu);
    return () => window.removeEventListener('resize', handleMenu);
  }, []);

  if (ff.integrate_api_sos) {
    return (
      <div className={clsx(classes.select, className)}>
        {rest.children ? (
          <div onClick={handleClick}>{rest.children}</div>
        ) : (
          <Select
            readOnly
            color={'primary'}
            onClick={handleClick}
            style={{ ...style, color: value.value ? '#000' : '#8C98A4' }}
            value={value.value ? value.value : placeholder}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: alignMenu,
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: alignMenu,
              },
              getContentAnchorEl: null,
              PopoverClasses: {
                paper: classes.popper,
              },
            }}
            inputProps={{
              className: `${classes.selectInput} ${value.value ? '' : 'placeholder'} ${isError ? 'error' : ''}`,
              style: {
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '100%',
                overflowX: 'hidden',
              },
            }}
            variant="outlined"
            IconComponent={() => {
              if (loading.isLoading) {
                if (loading.status === 'loading') {
                  return (
                    <Grid style={{ position: 'absolute', right: '10px' }}>
                      <CircleLoading size="15px" />
                    </Grid>
                  );
                } else {
                  return (
                    <BackgroundTooltip title={loading.message} placement="top">
                      <IconButton
                        onClick={() => handleRefeshLoading(true)}
                        style={{ padding: '0px', marginLeft: '6px', position: 'absolute', right: '10px' }}
                      >
                        <Icon type={'reload'} colorIcon={'#253746'} />
                      </IconButton>
                    </BackgroundTooltip>
                  );
                }
              } else {
                if (handleRemoveFilter && value && value.value) {
                  return <Icon type="close" className={classes.icon} onClick={handleRemoveFilter} />;
                } else {
                  return <Icon type="chevron" className={classes.icon} />;
                }
              }
            }}
            disabled={disabled}
            input={<BootstrapInput />}
            {...rest}
            // className={!value.value ? classes.noValue}
          >
            <option value={placeholder} disabled>
              {placeholder}
            </option>
            <option value={_.get(value, 'value', '')}>{value ? value.label : ''}</option>
          </Select>
        )}
        <StyledMenu
          classes={{ paper: clsx(classNamePaper, classes.paper) }}
          anchorEl={anchorEl}
          keepMounted
          open={!disabled && !loading.isLoading && Boolean(anchorEl)}
          onClose={handleClose}
          ref={refPaper}
        >
          {Boolean(anchorEl) && (
            <div ref={refMenu} className="wrap" style={{ position: 'relative' }}>
              <FormControlSearch
                options={options}
                currentValue={value}
                onChange={(val: OptionSelectType) => {
                  onChangeValue(val);
                  handleClose();
                }}
                placeholder={placeholderInput}
                renderOption={renderOption}
                onChangeKeyWord={handleMenu}
                searchAble={searchAble}
                {...(ff.storefront_sos_option_list
                  ? {
                      setKeyword: setKeyword,
                      field: field,
                    }
                  : {})}
              />
            </div>
          )}
        </StyledMenu>
      </div>
    );
  }

  return (
    <div className={clsx(classes.select, className)}>
      <Select
        readOnly
        color={'primary'}
        onClick={handleClick}
        style={{ ...style, color: value.value ? '#000' : '#8C98A4' }}
        value={value.value ? value.value : placeholder}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: alignMenu,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: alignMenu,
          },
          getContentAnchorEl: null,
          PopoverClasses: {
            paper: classes.popper,
          },
        }}
        inputProps={{
          className: `${classes.selectInput} ${value.value ? '' : 'placeholder'}`,
          style: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            width: '100%',
            overflowX: 'hidden',
          },
        }}
        variant="outlined"
        IconComponent={() => {
          if (loading.isLoading) {
            if (loading.status === 'loading') {
              return (
                <Grid style={{ position: 'absolute', right: '10px' }}>
                  <CircleLoading size="15px" />
                </Grid>
              );
            } else {
              return (
                <BackgroundTooltip title={loading.message} placement="top">
                  <IconButton
                    onClick={() => handleRefeshLoading(true)}
                    style={{ padding: '0px', marginLeft: '6px', position: 'absolute', right: '10px' }}
                  >
                    <Icon type={'reload'} colorIcon={'#253746'} />
                  </IconButton>
                </BackgroundTooltip>
              );
            }
          } else {
            if (handleRemoveFilter && value && value.value) {
              return <Icon type="close" className={classes.icon} />;
            } else {
              return <Icon type="chevron" className={classes.icon} />;
            }
          }
        }}
        disabled={disabled}
        input={<BootstrapInput />}
        {...rest}
        // className={!value.value ? classes.noValue}
      >
        <option value={placeholder} disabled>
          {placeholder}
        </option>
        <option value={_.get(value, 'value', '')}>{value ? value.label : ''}</option>
      </Select>
      <StyledMenu
        classes={{ paper: clsx(classNamePaper, classes.paper) }}
        anchorEl={anchorEl}
        keepMounted
        open={!disabled && !loading.isLoading && Boolean(anchorEl)}
        onClose={handleClose}
        ref={refPaper}
      >
        {Boolean(anchorEl) && (
          <div ref={refMenu} className="wrap" style={{ position: 'relative' }}>
            <FormControlSearch
              options={options}
              currentValue={value}
              onChange={(val: OptionSelectType) => {
                onChangeValue(val);
                handleClose();
              }}
              placeholder={placeholderInput}
              renderOption={renderOption}
              onChangeKeyWord={handleMenu}
              searchAble={searchAble}
              {...(ff.storefront_sos_option_list
                ? {
                    setKeyword: setKeyword,
                    field: field,
                  }
                : {})}
            />
          </div>
        )}
      </StyledMenu>
    </div>
  );
};

export default SelectFormSearchInside;
