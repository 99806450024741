import { EIP_CONSTANT } from '@eip/next/lib/main';

export const COMPACT_PROGRAMMATIC_OBJECT_CONFIG = {
  tableType: 'compact',
  title: 'Ad object optimization',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getAdsObjectData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_OBJECT',
  },
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADS_CAMPAIGN.id',
    'ADS_OBJECT.id',
    'ADS_CALENDAR.id',
    'PRODUCT.id',
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool',
      },
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      sortField: 'ADS_CAMPAIGN.name',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.id',
      },
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT',
      filterGetter: {
        default: 'ADS_OBJECT.name',
      },
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        subtext: 'PRODUCT.product_sid',
        id: 'ADS_OBJECT.id',
        ...(ff.add_script_compact_table_properties ? { scriptId: 'SCRIPT.id' } : {}),
        productId: 'PRODUCT.id',
      },
    },

    // ============= ATTRIBUTE =============
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
    },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'dailyBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.daily_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        currency: 'STOREFRONT.currency',
      },
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'totalBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.total_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        currency: 'STOREFRONT.currency',
      },
    },
    // ad_objective: {
    //   title: 'Ad objective',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.ad_objective',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.ad_objective',
    //   },
    // },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'budget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
    },
    // ad_object_bidding_price: {
    //   title: 'Ad object bidding price',
    //   propertyType: 'attribute',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ADS_OBJECT.bidding_price',
    //   valueGetter: {
    //     value: 'ADS_OBJECT.bidding_price',
    //     // currency: 'ADS_OBJECT.currency',
    //   },
    // },
    // ad_object_creative: {
    //   title: 'Ad object creative',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.ad_object_creative',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.ad_object_creative',
    //   },
    // },
    // creative_tagline: {
    //   title: 'Creative tagline',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_tagline',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_tagline',
    //   },
    // },
    // creative_image: {
    //   title: 'Creative image',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_image',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_image',
    //   },
    // },
    // creative_landing_page: {
    //   title: 'Creative landing page',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative_landing_page',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative_landing_page',
    //   },
    // },

    // ============= METRIC =============
    stock: {
      title: 'In stock',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'stock',
      valueGetter: {
        value: 'stock',
      },
    },
    discount: {
      title: 'Discount',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'discount',
      valueGetter: {
        value: 'discount',
      },
    },
    ad_item_sold: {
      title: 'Ad item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
    },
    direct_gmv: {
      title: 'Direct ad GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
    },
    items_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
    },
    ...(ff.add_script_compact_table_properties
      ? {
          script_amount: {
            title: 'Script amount',
            propertyType: 'metric',
            cellFormat: 'currency',
            dataType: 'integer',
            filterField: 'script_amount',
            valueGetter: {
              value: 'script_amount',
            },
          },
        }
      : {}),
  },
};
