import * as React from 'react';
import { get } from 'lodash';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Box, Button, makeStyles, Popover, ListItemIcon, OutlinedInput } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { optionsColumnColor } from '@ep/insight-ui/elements/chart-type/data-demo';

const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  main: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  edit: {
    cursor: 'pointer',
  },
  removeIcon: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  },
  popoverContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      '&:hover': {
        '& input': {
          borderColor: '#006EC6',
        },
        '& .eip1-MuiOutlinedInput-notchedOutline': {
          borderColor: '#006EC6',
        },
      },
    },
    '& .eip1-MuiOutlinedInput-input': {
      padding: '0px 8px',
      height: 32,
    },
    '& .eip1-MuiOutlinedInput-adornedStart': {
      paddingLeft: 8,
    },
  },
});

const useListColorStyles = makeStyles({
  list: {
    flexWrap: 'wrap',
    gap: '8px',
    width: '200px',
  },
  buttonColorActive: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: '#E4E7E9',
  },
  buttonColor: {
    minWidth: `calc(100% / 8)`,
    padding: '6px',
    backgroundColor: 'transparent',
  },
  box12: {
    width: '12px',
    height: '12px',
    borderRadius: '2px',
  },
});

const ListColor = ({ selectedColor, handleClose, handleChangeColor }: any) => {
  const classes = useListColorStyles();

  const handleSelectColor = (color) => {
    handleChangeColor(color);
    handleClose(color);
  };

  return (
    <ListItemIcon className={classes.list}>
      {optionsColumnColor.map((color) => (
        <Button
          key={color}
          variant="contained"
          color="secondary"
          className={selectedColor === color ? classes.buttonColorActive : classes.buttonColor}
          onClick={() => handleSelectColor(color)}
        >
          <Box bgcolor={color} className={classes.box12}></Box>
        </Button>
      ))}
    </ListItemIcon>
  );
};

const ColorPickerFormat = (props: ICellRendererParams) => {
  const classes = useStyles();

  const selectedColor = get(props.data, props.field, null);
  const [colorValue, setColorValue] = React.useState('');
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (color?) => {
    props.node.setDataValue(props.field, color || colorValue);
    setAnchorEl(null);
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.main}>
        <Box style={{ color: selectedColor }}>{selectedColor}</Box>
        <Box className={classes.edit} onClick={handleClick}>
          <Icon type={'edit'} />
        </Box>
      </Box>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={() => handleClose()}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popoverContainer}>
          {props.allowCustom ? (
            <OutlinedInput
              onChange={(e) => setColorValue(e.target.value)}
              value={colorValue}
              endAdornment={
                colorValue ? (
                  <Box className={classes.removeIcon} onClick={() => setColorValue('')}>
                    <Icon type={'closeCircle'} size={12} />
                  </Box>
                ) : null
              }
            />
          ) : null}
          <ListColor selectedColor={selectedColor} handleClose={handleClose} handleChangeColor={setColorValue} />
        </Box>
      </Popover>
    </Box>
  );
};

export default ColorPickerFormat;
