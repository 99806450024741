import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';
import numeral from 'numeral';
import moment from 'moment';
import * as _ from 'lodash';
import { HIDDEN_LABEL_TEXT, tableTooltip } from '../../helper';
const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legend: TooltipMapping.legend as CreateCSSProperties,
  totalValue: TooltipMapping.totalValue as CreateCSSProperties,
  tableTooltip: tableTooltip as CreateCSSProperties,
}));

interface IStackedStyleProps {
  percent?: string;
  icon?: string;
  width?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props: IStackedStyleProps) => {
  const classes = useStyles();
  //Show date to chart
  const handleDate = () => {
    const arrDays = [];
    if (props.width <= 767) {
      for (let newDay = 1; newDay < 5; newDay++) {
        arrDays.push('W' + newDay);
      }
      return arrDays;
    }

    let daysInMonth = moment().daysInMonth();
    while (daysInMonth) {
      const current = moment().date(daysInMonth);
      arrDays.push(current.format('DD MMMM'));
      daysInMonth--;
    }

    return arrDays.reverse();
  };

  //Show random data chart
  const handleRandomdata = () => {
    const newArr = [];
    const small = props.width <= 767 ? 5 : 32;

    for (let i = 1; i < small; i++) {
      const random = Math.floor(Math.random() * 800) + 1;
      newArr.push(random);
    }
    return newArr;
  };

  //Custom tooltip
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = classes.root;
      const table = document.createElement('table');
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    // Create tooltip element
    const { chart, tooltip } = context;

    const tooltipEl = getOrCreateTooltip(chart);

    //Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    //Set text into tooltip
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      let total = 0;
      bodyLines.forEach((arr) => {
        total += _.get(arr, [0, 'value'], 0);
      });
      const tableHead = document.createElement('thead');

      titleLines.forEach((title) => {
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.style.borderWidth = '0';
        tr.className = 'headerRow';

        const th: HTMLTableCellElement = document.createElement('th');
        th.style.borderWidth = '0';
        const text = document.createTextNode(moment(title).format('ddd, DD MMM YYYY'));

        th.appendChild(text);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const specialKey: string = _.get(body, [0, 'specialKey'], false);
        const label: string = _.get(body, [0, 'label'], '');
        const value: number = _.get(body, [0, 'value'], 0);
        const tr: HTMLTableRowElement = document.createElement('tr');
        tr.className = 'legendRow';
        const colors = tooltip.labelColors[i];
        const tdHTML = `
            <td class="legendLine">
              <span class="legendIcon" style="background: ${colors.backgroundColor}; border-color: ${
          colors.borderColor
        };"></span>
              <div class="content">
              <div class="label">${_.startCase(_.lowerCase(label))}</div>
              <div class="value">${`đ${formatCurrency(value, 'VND')}`}</div>
              <div class="percent">(${numeral((value / total) * 100).format('0.00')}%)</div>
              </div>
            </td>
          `;
        tr.innerHTML = tdHTML;
        if (!label.includes(HIDDEN_LABEL_TEXT)) {
          tableBody.appendChild(tr);
        } else if (specialKey) {
          const textKey: string = _.get(body, [0, 'textKey'], '');
          const colorTooltip: string = _.get(body, [0, 'colorTooltip'], '');
          tr.querySelector('.label').textContent = textKey;
          const leng: HTMLSpanElement = tr.querySelector('.legendIcon');
          leng.style.background = colorTooltip;
          tableBody.appendChild(tr);
        }
      });

      const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

      // Remove old children
      while (tableRoot.firstChild) {
        tableRoot.firstChild.remove();
      }
      tableRoot.className = `${classes.tableTooltip}`;
      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    //Display position tooltip after chart
    tooltipEl.style.opacity = '1';
    if (tooltip.caretX > 832) {
      tooltipEl.style.transform = 'translate(-100%,-50%)';
    } else if (tooltip.caretX > 63) {
      tooltipEl.style.transform = 'translate(0,-100%)';
    }
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    if (!legendContainer) return {};
    const listContainer = legendContainer.querySelector('ul');
    const totalValue = legendContainer.querySelector(`.${classes.totalValue}`);

    if (!listContainer) {
      const listContainer = document.createElement('ul');
      listContainer.className = classes.legend;
      legendContainer.appendChild(listContainer);
    }

    //Create total value chart
    if (!totalValue) {
      const totalValue = document.createElement('p');
      totalValue.className = classes.totalValue;
      legendContainer.appendChild(totalValue);
    }
    return { listContainer, totalValue };
  };

  const getTotalChart = (maxValue: number, percent: string) => {
    const currency = 'đ';
    const value = `${maxValue}`;
    const per = `${percent}`;
    return { value, per, currency };
  };

  //Custom legend
  const htmlLegendPlugin = {
    afterUpdate(chart) {
      const id = chart.canvas.parentNode.id;
      const ul = getOrCreateLegendList(chart, id).listContainer;
      const checkEl = document.getElementById(id);
      const { cacheColorsBackground } = cacheChartColors;
      const datas: Array<any> = chart.data.datasets;
      const chartColorsBackground = cacheColorsBackground(datas.map((data) => data.backgroundColor));
      chart.canvas.parentNode.className = classes.wrapp;

      // Remove all children
      // while (checkEl.querySelector('ul').firstChild) {
      //   checkEl.querySelector('ul').firstChild.remove();
      // }
      if (ul) {
        ul.innerHTML = '';
      }

      // Reuse the built-in legendItems generator
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      const onToggle = () => {
        datas.forEach((data, index) => {
          if (data.active === 'active') {
            data.backgroundColor = chartColorsBackground[index];
            chart.setDatasetVisibility(index, true);
          } else {
            data.backgroundColor = 'transparent';
            chart.setDatasetVisibility(index, false);
          }
        });
      };

      items.forEach((item) => {
        const li = document.createElement('li');

        li.onclick = () => {
          const allActive = datas.every((data) => data.active === 'active');

          if (datas[item.datasetIndex].active === 'active') {
            if (allActive) {
              datas.forEach((data) => (data.active = ''));
              datas[item.datasetIndex].active = 'active';

              onToggle();
              chart.update();
              return;
            }

            if (!allActive) {
              datas[item.datasetIndex].active = '';
              const allNotActive = datas.every((data) => data.active === '');
              if (allNotActive) {
                datas.forEach((data) => (data.active = 'active'));
              }

              onToggle();
              chart.update();
              return;
            }
          }

          datas[item.datasetIndex].active = 'active';
          onToggle();
          chart.update();
        };
        li.onmouseover = (e) => {
          // datas.forEach((data, index) => {
          //   const arrbackgroundColor = data.backgroundColor.split(',');
          //   if (item.datasetIndex === index) {
          //     data.backgroundColor = chartColorsBackground[index];
          //   } else {
          //     arrbackgroundColor.splice(arrbackgroundColor.length - 1, 1, ' .1)');
          //     data.backgroundColor = arrbackgroundColor.join(',');
          //   }
          // });
          // setTimeout(() => {
          //   chart.update();
          // }, 50);
        };
        li.onmouseout = () => {
          // datas.forEach((data, index) => {
          //   data.backgroundColor = chartColorsBackground[index];
          // });
          // chart.update();
        };
        if (ul) {
          const allActive = datas.every((data) => data.active === 'active');
          //Create checkbox
          const checkbox = document.createElement('span');
          checkbox.className = `legendItem__checkbox ${datas[item.datasetIndex].active === 'active' ? 'active' : ''}`;
          checkbox.style.backgroundColor = `${
            datas[item.datasetIndex].active === 'active' ? chartColorsBackground[item.datasetIndex] : 'transparent'
          }`;
          //Create checked
          if (!allActive) {
            const after = document.createElement('span');
            const before = document.createElement('span');
            after.className = 'checked after';
            before.className = 'checked before';
            checkbox.appendChild(after);
            checkbox.appendChild(before);
          }

          //Binding data text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.className = 'textContainer';
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.className = `legendItem`;
          li.appendChild(checkbox);
          li.appendChild(textContainer);
          ul.classList.add('legendDiv');
          ul.appendChild(li);
        }
      });
    },
  };

  const cacheChartColors = (() => {
    let colorsBackground: string[] = [];
    return {
      cacheColorsBackground: (chartColor: string[]) => {
        if (colorsBackground.length === 0) {
          colorsBackground = chartColor;
        }
        return colorsBackground;
      },
    };
  })();

  return {
    handleDate: handleDate,
    handleRandomdata: handleRandomdata,
    externalTooltipHandler: externalTooltipHandler,
    htmlLegendPlugin: htmlLegendPlugin,
  };
};
