import moment from 'moment';
const randomInt = (min: number, max: number) => {
  return Math.round(min + Math.random() * (max - min));
};
const getDemoData = ({
  type = 'TODAY',
  label = '',
}: {
  type: 'TODAY' | 'YESTERDAY' | 'LAST-7-DAYS';
  label: string;
}) => {
  const getTimeOfDays = (day: moment.Moment, minValue: number, maxValue: number) => {
    // console.log('day', day.format());

    const items = [];
    new Array(24).fill().forEach((acc, index) =>
      items.push({
        date: day.hour(index).minute(0).format('YYYY-MM-DD HH:mm'),
        value: randomInt(minValue, maxValue),
      }),
    );
    return items;
  };

  if (type == 'TODAY') {
    return {
      metric: label,
      title: 'TODAY',
      unit: 'USD',
      value: 31640.53938294161,
      forwardMeaning: true,
      this_time_value: 31640.5393829416,
      last_time_value: 11727.469544224012,
      this_time_value_serries: getTimeOfDays(moment(), 11727, 21640),
      last_time_value_serries: getTimeOfDays(moment().subtract(1, 'days'), 1727, 11727),
    };
  }
  if (type == 'YESTERDAY') {
    return {
      metric: label,
      title: 'YESTERDAY',
      unit: 'USD',
      value: 14237.022662629364,
      forwardMeaning: false,
      this_time_value: 14237.022662629362,
      last_time_value: 10106.736113506264,
      this_time_value_serries: getTimeOfDays(moment().subtract(1, 'days'), 593, 14237),
      last_time_value_serries: getTimeOfDays(moment().subtract(2, 'days'), 299, 10106),
    };
  }
};
getDemoData({ label: '', type: 'TODAY' });
export const METRIC_DATA_MOCK = [
  {
    metric: 'roas',
    title: 'EMPTY DATA',
    value: 5.77775728212337,
    forwardMeaning: true,
    this_time_value: 142.3569288920457,
    last_time_value: 62.3546696464901,
    this_time_value_serries: [null, null],
    last_time_value_serries: [null, null],
  },
  getDemoData({ label: 'TODAY', type: 'TODAY' }),
  getDemoData({ label: 'YESTERDAY', type: 'YESTERDAY' }),
  {
    metric: 'gmv',
    title: 'Multiple Day',
    unit: 'USD',
    value: 82258.06136476227,
    forwardMeaning: true,
    this_time_value: 82258.06136476224,
    last_time_value: 27293.03655257541,
    this_time_value_serries: [
      {
        date: '2021-12-09 00:00:00',
        value: 7447.981225023647,
      },
      {
        date: '2021-12-10 01:00:00',
        value: 2204.6739806110827,
      },
      {
        date: '2021-12-11 02:00:00',
        value: 1221.6331869277092,
      },
      {
        date: '2021-12-12 03:00:00',
        value: 684.6868443897976,
      },
      {
        date: '2021-12-13 04:00:00',
        value: 1074.1159156854208,
      },
      {
        date: '2021-12-14 05:00:00',
        value: 1804.5138250624848,
      },
      {
        date: '2021-12-15 06:00:00',
        value: 2189.6189261639884,
      },
      {
        date: '2021-12-16 07:00:00',
        value: 4417.329245548398,
      },
    ],
    last_time_value_serries: [
      {
        date: '2021-12-02 00:00:00',
        value: 842.8124524824693,
      },
      {
        date: '2021-12-03 01:00:00',
        value: 579.350126247033,
      },
      {
        date: '2021-12-04 02:00:00',
        value: 244.58601917457761,
      },
      {
        date: '2021-12-05 03:00:00',
        value: 135.6053090706763,
      },
      {
        date: '2021-12-06 04:00:00',
        value: 244.08774997708824,
      },
      {
        date: '2021-12-07 05:00:00',
        value: 438.0958956125514,
      },
      {
        date: '2021-12-08 06:00:00',
        value: 691.5835119919365,
      },
      {
        date: '2021-12-09 07:00:00',
        value: 856.4726072603844,
      },
    ],
  },
];
