import * as React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import ItemTypes from './ItemTypes';
import { ConnectDragSource, ConnectDropTarget } from 'react-dnd';

/* Interface */
interface PropRef {
  isDragging: boolean;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  children: React.ReactNode;
}
interface CardProps {
  id: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => number;
  findIndex?: (id) => number;
  onDrop?: () => void;
}

// eslint-disable-next-line react/display-name
const Card = React.forwardRef(({ isDragging, connectDragSource, connectDropTarget, children }: PropRef, ref) => {
  const elementRef = React.useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0 : 1;
  React.useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current,
  }));

  return (
    <div ref={elementRef} style={{ opacity }}>
      {children}
    </div>
  );
});
export default DropTarget(
  ItemTypes.CARD,
  {
    hover(props: CardProps, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = props.findIndex(monitor.getItem().id);
      const hoverIndex = props.findIndex(props.id);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex || monitor.getItem().id == props.id) {
        return;
      }
      const dragWidth = monitor.getItem().width;
      const hoverBoundingRect = node.getBoundingClientRect();
      const clientOffset = monitor.getClientOffset();
      const moveRightCondition =
        hoverIndex > dragIndex && hoverBoundingRect.x + hoverBoundingRect.width - clientOffset.x < dragWidth;
      const moveLeftCondition = hoverIndex < dragIndex && clientOffset.x - hoverBoundingRect.x < dragWidth;
      if (moveRightCondition || moveLeftCondition) {
        props.moveCard(hoverIndex, dragIndex);
        return;
      }
      if (hoverIndex - dragIndex > 1) {
        props.moveCard(hoverIndex - 1, dragIndex);
        return;
      }
      if (dragIndex - hoverIndex > 1) {
        props.moveCard(hoverIndex + 1, dragIndex);
        return;
      }
    },
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget(),
  }),
)(
  DragSource(
    ItemTypes.CARD,
    {
      beginDrag: (props: CardProps, _, component) => {
        const result = {
          id: props.id,
        };
        if (!component) return result;

        const node = component.getNode();

        if (!node) return result;

        const dragBoundingRect = node.getBoundingClientRect();

        return {
          id: props.id,
          width: dragBoundingRect.width,
        };
      },
      endDrag(props) {
        if (props.onDrop) props.onDrop();
      },
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging() && !monitor.didDrop(),
    }),
  )(Card),
);
