import * as React from 'react';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { getTreemapData } from '@ep/insight-ui/system/backbone/data-source/common';

/**
 * ff.replace_keyword_compact:start
 */
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { TreemapDataResponseInterface } from '@ep/insight-ui/system/backbone/data-source/type';
/**
 * ff.replace_keyword_compact:end
 */

export const defaultTreemapData: TreemapDataResponseInterface = {
  data: [],
  success: true,
  message: '',
};

export const useTreemap = ({ keyword, dateRange, backbone }: any) => {
  const [treemapData, setTreemapData] = React.useState<TreemapDataResponseInterface>(
    ff.replace_keyword_compact ? defaultTreemapData : [],
  );

  let reload, setReload, isLoading, setIsLoading, open, setOpen, stateChart: IStateChartValue, handleReload;
  if (ff.replace_keyword_compact) {
    [open, setOpen] = React.useState(false);
    [reload, setReload] = React.useState(0);
    [isLoading, setIsLoading] = React.useState(true);
    stateChart = React.useMemo(() => {
      if (isLoading) {
        return 'loading';
      }
      if (treemapData.success) {
        if (treemapData.data.length > 0) return 'success';
        return 'empty';
      }
      return 'error';
    }, [treemapData, isLoading]);

    handleReload = () => {
      setReload((prev) => prev + 1);
    };
  }

  React.useEffect(() => {
    if (ff.replace_keyword_compact) {
      setIsLoading(true);
    }
    getTreemapData(keyword, dateRange).then((res) => {
      if (ff.replace_keyword_compact) {
        setIsLoading(false);
        setTreemapData(res);
      } else {
        const indexStorefrontName = res.data.headers.findIndex((header) => header === 'STOREFRONT.name');
        const indexSoS = res.data.headers.findIndex((header) => header === 'sos');
        setTreemapData(
          res.data.rows
            .sort((a, b) => b[indexSoS] - a[indexSoS])
            .map((row) => {
              return {
                name: row[indexStorefrontName],
                children: [
                  {
                    name: row[indexStorefrontName],
                    value: row[indexSoS],
                    showLabel: true,
                  },
                ],
              };
            }),
        );
      }
    });
  }, [keyword, dateRange.dateFrom, dateRange.dateTo, ...(ff.replace_keyword_compact ? [reload] : [])]);

  const showTable = () => {
    backbone.changeConfig('isTreemapShow', false);
  };

  const showHeatmap = () => {
    backbone.changeConfig('isTreemapShow', true);
  };

  return {
    showTable,
    showHeatmap,
    treemapData,
    ...(ff.replace_keyword_compact ? { stateChart, handleReload, open, setOpen } : {}),
  };
};
