import { ContainerResponsiveContext, AuthContext } from '@eip/next/lib/main';
import Icon from '@ep/insight-ui/icons/Icon';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, Divider, Drawer, Grid, Tooltip } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import Dropdown from '../dropdown-menu/dropdown';
import FeatureFlagsPopup from '../feature-flags/feature-flags-popup';
import MenuList from '../list-control/menu-list';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import Typography from '../text-style/Typography';
import { OPEN_SIDEBAR } from './app-menu';
import { SwitchIcon } from '../modal/switch';
import { isSandboxMode } from '../../eo2/global';
/**
 * ff.call_switch_sandbox_api:start
 */
import { aim, eipRequest as request } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { useLog } from '@ep/insight-ui/sw/log';
import WorkspaceSection from './workspace-section';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import ModalConfirm from '../table/dropdown-views/modal-confirm';
import { useToast } from '../notifications/hook';
import { usePassport } from './hooks/use-passport';
import { get } from 'lodash';
import { DropdownCell } from '../etable2/dropdown-cell';
import { getCustomCellActions } from '@ep/insight-ui/system/helper/function';
import ActionConfiguration from '@ep/insight-ui/system/panel/page-configuration/action-configuration';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';
/**
 * ff.call_switch_sandbox_api:end
 */

type PropsStyle = {
  drawerWidth: number;
};

/** Style */
const useStyles = makeStyles((theme) => ({
  container: {
    position: 'fixed',
    display: 'inline-block',
    minHeight: '67px',
    // top: '40px',
    // bottom: 0,
    zIndex: theme.zIndex.drawer,
    '&.isOpen': {
      '& $abButton': {
        display: 'none',
      },
      '&:hover': {
        '& $abButton': {
          display: 'flex',
        },
      },
    },
  },
  drawer: ({ drawerWidth }: PropsStyle) => ({
    width: theme.spacing(3) + 1,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  }),
  drawerOpen: ({ drawerWidth }: PropsStyle) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    height: '100%',
    '.eres--small &': {
      width: '80vw',
      height: '100vh',
    },
  }),
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    height: '100%',
    overflowX: 'hidden',
    width: theme.spacing(3) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(3) + 1,
    },
  },
  containerBox: {
    backgroundColor: '#F8FAFD',
    height: '100%',
    width: '100%',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '16px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
      border: '5px solid #F8FAFD',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '4px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },
  },
  containerContent: {
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track:hover': {
      backgroundColor: 'transparent',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
      borderRadius: '16px',
      border: '1px solid #F8FAFD',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '&::-webkit-scrollbar-button ': { display: 'none' },

    '&.isScrollAble .TreeItemContent': {
      paddingRight: 0,
    },
  },
  topGrid: {
    flexWrap: 'nowrap',
    padding: '15px 8px 12px 8px ',
  },
  textCapitalize: {
    textTransform: 'capitalize',
    color: '#8C98A4',
    fontSize: '14px',
  },
  label: {
    fontSize: '12px',
    lineHeight: '16px',
    fontStyle: 'normal',
    fontWeight: 500,
    color: '#8C98A4',
    marginLeft: '0px',
    whiteSpace: 'nowrap',

    //paddingBottom: '5px',
  },
  textSecondary: {
    fontSize: '14px',
    lineHeight: '16px',
    fontStyle: 'normal',
    color: '#000',
    fontWeight: 400,
    marginLeft: '0px',
    width: '100%',
    whiteSpace: 'nowrap',
  },
  textDisable: {
    color: '#8C98A4',
  },
  widthAuto: {
    // width: 'auto',
  },
  icon: {
    width: 12,
    height: 12,
    margin: 5,
    marginLeft: 0,
    marginRight: 12,
  },
  iconExpand: {
    width: 8,
    height: 8,
    marginLeft: 1,
  },
  iconRight: {
    marginLeft: 20,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: `#EBF4FB`,
    },
  },
  borderIconButton: {
    padding: '5px',
    display: 'flex',
    top: '50%',
    transform: 'translate(50%, -50%)',
  },
  abButton: {
    right: 0,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  buttonExpand: {
    borderRadius: '50%',
    border: 'none',
    height: 24,
    width: 24,
    backgroundColor: '#fff !important',
  },
  iconExp: {
    width: '6px',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  lorealLogo: {
    background: '#FFFFFF',
    border: '1px solid #E4E7E9',
    borderRadius: 5,
    marginRight: '4px',
    marginLeft: '3px',
    display: 'flex',
    padding: '2px',
    alignItems: ' center',
    minWidth: '24px',
    width: '24px',
    height: '24px',
    overflow: 'hidden',
    '& .logo': {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      justifyContent: 'center',
      backgroundColor: '#fff',
      width: '100%',
      height: '100%',
      margin: 'auto',
    },
  },
  selectBrand: {
    '& .eip1-MuiSelect-selectMenu': {
      paddingTop: '0',
      paddingBottom: '0',
      paddingLeft: '0',
      paddingRight: ' 27px',
    },
    '& .icon_select': {
      color: '#3F4F5C',
    },
  },
  text: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    ...noSelectStyled,
    '&.logo': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#253746',
    },
    '&.subText': {
      fontSize: '10px',
      lineHeight: '12px',
      color: '#8C98A4',
    },
  },
  divide: {
    display: 'none',
  },
  styleButton: {
    backgroundColor: 'transparent !important',
    minWidth: '20px',
    height: '20px',
    color: '#253746 !important',
  },
  textHead: {
    '&.title': {
      color: '#253746 !important',
    },
    '&.subtitle': {
      color: '#8C98A4 !important',
    },
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  menuDropdown: {
    paddingTop: '8px',
  },
  footerDropdown: {
    paddingTop: '8px',
  },
  workspace: {
    cursor: 'pointer',
    padding: '4px 12px',
    borderRadius: '4px',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
  bottomMenu: {
    borderTop: '1px solid #E5E5E5',
    color: '#8C98A4',
    padding: '6px 8px',
    fontSize: '12px',
    marginTop: '4px',
    position: 'relative',
  },
  abButtonMobile: {
    display: 'flex',
    right: 0,
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 1,
  },
  sandbox: {
    position: 'relative',
    padding: '6px 0 6px 10px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    '&::before': {
      left: '50%',
      width: '100%',
      top: '0px',
      height: '1px',
      content: '""',
      transform: 'translateX(-50%)',
      position: 'absolute',
      backgroundColor: '#E5E5E5',
    },
  },
  confirmButton: {
    backgroundColor: '#006EC6',
    '&:hover': {
      backgroundColor: '#338ED8',
    },
  },
  systemConfigActions: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
  customActions: {
    '& hr': {
      padding: '0 8px',
    },
  },
  ray: {
    position: 'relative',
    padding: '6px 0 6px 10px',
    height: '32px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    width: '100%',
    justifyContent: 'flex-start',
  },
}));
const bgSidebar = '#FAFCFF';

let API_CALL_SWITCH_SANDBOX, API_UPDATE_SYSTEM_PREFERENCES;
if (ff.call_switch_sandbox_api) {
  API_CALL_SWITCH_SANDBOX = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/switch-sandbox-environment';
  API_UPDATE_SYSTEM_PREFERENCES = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/programmatic/update-system-preferences';
}

type SideBarProps = {
  drawerWidth: number;
  header?: React.ReactNode;
  children?: React.ReactNode;
  openSideBar: boolean;
  setOpenSideBar: (val: boolean) => void;
  dataMenuTopBar?: Array<DropdownMenuDataType[]>;
  dataMenuFooter?: Array<DropdownMenuDataType[]>;
  aboutData?: { version: string; updateTime: string };
  userEmail: string;
  setOpenSidebarModile?: any;
  systemMode: string;
  setSystemMode: React.Dispatch<React.SetStateAction<string>>;
};
const SideBar = ({
  openSideBar,
  setOpenSideBar,
  drawerWidth,
  header,
  userEmail = 'youremail@email.com',
  dataMenuTopBar = [],
  dataMenuFooter = [],
  children,
  aboutData = { updateTime: '', version: '' },
  setOpenSidebarModile,
  systemMode,
  setSystemMode,
}: SideBarProps) => {
  const classes = useStyles({ drawerWidth: drawerWidth });
  const wrapContentRef = React.useRef<HTMLDivElement>();
  const divideRef = React.useRef<HTMLDivElement>();
  const contentRef = React.useRef<HTMLDivElement>();
  const location = useLocation();
  const history = useHistory();
  const offsetScrollY = _.get(wrapContentRef, ['current', 'offsetTop'], 0);

  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobileView = containerClass === 'eres--small';
  const { onToastMultiple } = useToast();

  const { currentWorkspaceDetail, workspaces } = React.useContext(AuthContext);
  const isSandbox = currentWorkspaceDetail?.workspace_domain === 'sandbox';
  const [customActions, setCustomActions] = React.useState([]);

  React.useEffect(() => {
    if (!wrapContentRef.current) return;
    if (!contentRef.current) return;

    const parentHeight = wrapContentRef.current.offsetHeight;
    const childrenHeight = contentRef.current.offsetHeight;

    if (childrenHeight > parentHeight) {
      wrapContentRef.current.classList.add('isScrollAble');
    }

    // TreeItemContent
  }, [wrapContentRef, contentRef]);
  let handleSandbox, sandboxMode, setSandboxMode;
  [sandboxMode, setSandboxMode] = React.useState(isSandboxMode());

  const [isSwitchingMode, setIsSwitchingMode] = React.useState(false);

  let userId;
  if (ff.call_switch_sandbox_api) {
    userId = aim.getUserSettings() ? aim.getUserSettings().profile.userId : null;
  }

  const isViewAUserMode = React.useMemo(() => {
    const settings = aim.getUserSettings();
    return settings?.adminMode && settings?.adminMode.includes(EIP_CONSTANT.ADMIN_MODE.VIEW_AS_USER);
  }, []);

  const { setUserWorkspace } = usePassport();

  const workspaceChannelRef = React.useRef(null);

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/system-actions').then((res) => {
        const customActions = get(res, 'data.v', []) || [];
        setCustomActions(customActions);
      });
    }, DELAY_REQUEST_ADDITIONAL_DATA);
    workspaceChannelRef.current = new BroadcastChannel('workspace');

    return () => {
      workspaceChannelRef.current.close();
      clearTimeout(timeout);
    };
  }, []);

  handleSandbox = async () => {
    const userProfile = aim.getUserSettings();

    const ws = isSandbox ? workspaces[0] : workspaces.find((ws) => ws.workspace_domain === 'sandbox');
    if (ws) {
      aim.setUserSettings({
        ...userProfile,
        profile: {
          ...userProfile?.profile,
          workspace_id: ws.workspace_id,
          workspace_domain: ws.workspace_domain,
          favorite: ws.favorite,
        },
      });
      workspaceChannelRef.current.postMessage(ws);
      setUserWorkspace(ws.workspace_id);
      const newUrl = `/${ws.workspace_domain}/`;
      history.push(newUrl);
    } else {
      alert('You are not allowed to access this workspace');
    }
  };

  const handleToggleSafeMode = () => {
    setIsSwitchingMode(true);
    request
      .post(API_UPDATE_SYSTEM_PREFERENCES, {
        mode: systemMode === 'dd_mode' ? 'bau_mode' : 'dd_mode',
      })
      .then((res) => {
        if (res?.success) {
          setSystemMode(res.mode);
        }
      })
      .catch((e) => {
        onToastMultiple({
          title: 'Error',
          messages: [e.error?.message || e.message],
          variant: 'error',
        });
      })
      .finally(() => {
        setIsSwitchingMode(false);
      });
  };

  const customCellActions = getCustomCellActions(
    { actions: customActions, field: '', backbone: null, isPageAction: true },
    [],
    onToastMultiple,
  );

  return (
    <Box className={`${classes.container} ${openSideBar ? ' isOpen' : ''} ${containerClass}`}>
      <Drawer
        anchor="left"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: openSideBar,
          [classes.drawerClose]: !openSideBar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: openSideBar,
            [classes.drawerClose]: !openSideBar,
          }),
        }}
      >
        <Box
          boxShadow={1}
          className={`${classes.containerBox}`}
          style={{
            overflowY: 'hidden',
            overflowX: 'hidden',
            backgroundColor: bgSidebar,
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            height={'100%'}
            style={{ opacity: openSideBar ? 1 : 0, backgroundColor: bgSidebar }}
          >
            {/* header */}
            <div style={{ zIndex: 1, top: 0, backgroundColor: bgSidebar }}>
              <Grid
                container
                alignItems={'center'}
                wrap={'nowrap'}
                className={`${classes.topGrid}`}
                style={{ marginBottom: 5 }}
                onClick={() => {
                  if (openMenu) return;
                  setOpenMenu(true);
                }}
              >
                <Grid container className={classes.workspace}>
                  <Box>
                    <Box display={'flex'} alignItems={'center'}>
                      <Typography variant="h4" className={`${classes.textHead} title`}>
                        {ff.next_left_menu ? currentWorkspaceDetail?.workspace_name || 'My workspace' : 'My workspace'}
                      </Typography>
                      <Dropdown
                        classNamePaper={classes.menuDropdown}
                        className={classes.styleButton}
                        disabledToggleStyle
                        alignMenu="right"
                        disabled
                        icon={ff.next_left_menu ? 'ic/charm:chevrons-up-down/#253746' : 'chevronMedium'}
                        label=""
                        sizeIcon={ff.next_left_menu ? '12px' : '8px'}
                        defaultOpen={openMenu}
                        handleOnClosed={() => setOpenMenu(false)}
                        {...(isMobileView ? { dataMenuPage: dataMenuTopBar } : {})}
                      >
                        {ff.next_left_menu ? <WorkspaceSection setOpenMenu={setOpenMenu} /> : null}
                        <MenuList listMenu={dataMenuTopBar} />
                      </Dropdown>
                    </Box>
                    <Typography variant="body5" className={`${classes.textHead} subtitle`}>
                      {userEmail} {isViewAUserMode ? <Icon type="ic/fluent:circle-12-filled/yellow" size={8} /> : null}
                    </Typography>
                  </Box>
                </Grid>
                {/* <Grid item className={classes.lorealLogo}>
                  <div
                    className={'logo'}
                    style={{
                      backgroundImage: `url(${lorealLogo})`,
                    }}
                  ></div>
                </Grid>
                <Grid item style={{ width: '100%' }}>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextSelectForm
                        className={classes.selectBrand}
                        classNameText={`${classes.text} logo`}
                        value={'l-oreal'}
                        options={[{ label: 'L’Oreal', value: 'l-oreal' }]}
                        variant={'filled'}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div className={`${classes.text} subText`}>enterprise</div>
                    </Grid>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid>
                <Grid item xs={12}>
                  <div>{header}</div>
                </Grid>
              </Grid>
            </div>
            {/* content */}
            <div className={classes.divide} ref={divideRef}>
              <Divider />
            </div>
            <div
              ref={wrapContentRef}
              onScroll={(e) => {
                const scrollTop = _.get(wrapContentRef, ['current', 'scrollTop'], 0);
                if (scrollTop == 0) {
                  if (divideRef) {
                    divideRef.current.style.display = 'none';
                  }
                } else {
                  divideRef.current.style.display = 'block';
                }
              }}
              className={classes.containerContent}
              style={{ overflowY: 'auto', overflowX: 'hidden', height: '100%' }}
            >
              <Grid container innerRef={contentRef}>
                {/* group tabs */}
                {children}
              </Grid>
            </div>
            {/* footer */}
            <div style={{ position: 'sticky', bottom: 0, backgroundColor: bgSidebar }}>
              <Divider />
              <Grid container justifyContent={'space-between'} alignItems={'center'} className={` ${classes.topGrid}`}>
                <Box display={'flex'} alignItems={'center'}>
                  <Icon type={'logo'} fontSizeAdjust={16} style={{ width: 72, height: 26.6 }} />
                  {(systemMode !== 'bau_mode' || isSwitchingMode) && String(userEmail).endsWith('@epsilo.io') ? (
                    <Tooltip title={isSwitchingMode ? 'Switching' : 'Safe mode'}>
                      <Box style={{ marginLeft: '8px', height: 26.6, display: 'flex', alignItems: 'center' }}>
                        <Icon
                          type={isSwitchingMode ? 'ic/ic:baseline-shield/blue6' : 'ic/mdi:shield-check/blue6'}
                          size={'24px'}
                        />
                      </Box>
                    </Tooltip>
                  ) : null}
                </Box>
                {/* <Icon type={'chevronmediumright'} size={'12px'} style={{ width: 6, height: 10, marginRight: 1 }} /> */}
                <Dropdown
                  classNameFooter={classes.footerDropdown}
                  className={classes.styleButton}
                  disabledToggleStyle
                  alignMenu="right"
                  icon="threeDotsVertical"
                  label=""
                  sizeIcon={'12px'}
                >
                  <MenuList listMenu={dataMenuFooter} />
                  {/* <button
                    onClick={() =>
                      (window.location.href = history.createHref({
                        pathname: '/sandbox' + location.pathname,
                        search: location.search,
                      }))
                    }
                  >
                    toggle sandbox
                  </button> */}
                  {aim.canAccess('section_botep') &&
                  (workspaces || []).some((ws) => ws.workspace_domain === 'sandbox') ? (
                    <Grid
                      container
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      className={clsx(classes.sandbox, 'nfSandbox')}
                    >
                      <div style={{ display: 'flex', alignItems: 'center', columnGap: '12px' }}>
                        <div style={{ width: '14px', display: 'flex', alignItems: 'center' }}>
                          <Icon type={'ic/cib:codesandbox/charcoal'} />
                        </div>
                        <Typography variant="subtitle1" style={{ fontSize: '14px' }} className={'nfSandboxText'}>
                          Sandbox
                        </Typography>
                      </div>
                      {ff.add_css_identifier_to_new_release_components ? (
                        <SwitchIcon
                          checked={isSandbox}
                          onChange={handleSandbox}
                          isCustom={true}
                          toggle={isSandbox ? 'on' : 'off'}
                          className={'sandbox-toggle'}
                        />
                      ) : (
                        <SwitchIcon checked={sandboxMode} onChange={handleSandbox} isCustom={true} />
                      )}
                    </Grid>
                  ) : null}
                  {aim.canAccess('safeMode:toggle') ? (
                    <>
                      <Grid
                        container
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        className={clsx(classes.sandbox, 'nfSandbox')}
                      >
                        <div style={{ display: 'flex', alignItems: 'center', columnGap: '12px' }}>
                          <div style={{ width: '14px', display: 'flex', alignItems: 'center' }}>
                            <Icon type={'ic/ic:baseline-shield/charcoal'} />
                          </div>
                          <Typography variant="subtitle1" style={{ fontSize: '14px' }} className={'nfSandboxText'}>
                            Safe mode
                          </Typography>
                        </div>
                        <MonitorContainer component="div" mId="toggle-safe-mode" mLabel="Toggle safe mode">
                          <SwitchIcon
                            checked={systemMode === 'dd_mode'}
                            onChange={() => setOpenConfirm(true)}
                            isCustom={true}
                            className={'safeMode-toggle'}
                            disabled={isSwitchingMode}
                          />
                        </MonitorContainer>
                      </Grid>
                      <ModalConfirm
                        title={`Are you sure to switch to ${systemMode === 'bau_mode' ? 'safe mode' : 'normal mode'}`}
                        message={``}
                        openModal={openConfirm}
                        onClose={() => setOpenConfirm(false)}
                        action={handleToggleSafeMode}
                        btnLabel={'Apply'}
                        btnClass={classes.confirmButton}
                      />
                    </>
                  ) : null}
                  {aim.canAccess('ray') ? (
                    <Button className={clsx(classes.ray, 'nfSandbox')} onClick={() => window.open('/ray', '_blank')}>
                      <div style={{ display: 'flex', alignItems: 'center', columnGap: '12px' }}>
                        <div style={{ width: '14px', display: 'flex', alignItems: 'center' }}>
                          <Icon type={'ic/material-symbols:edit-document/xanadu'} />
                        </div>
                        <Typography variant="subtitle1" style={{ fontSize: '14px' }} className={'nfSandboxText'}>
                          Access Ray
                        </Typography>
                      </div>
                    </Button>
                  ) : null}
                  <div
                    className={classes.customActions}
                    style={{ borderTop: customCellActions?.length > 0 ? '1px solid #E5E5E5' : null }}
                  >
                    <DropdownCell cellAction={customCellActions} props={{}} ignoreDropdown />
                  </div>
                  <div className={classes.bottomMenu}>
                    Epsilo {aboutData.version} <br /> Updated {aboutData.updateTime}
                    {aim.canAccess('page:edit') ? (
                      <div className={classes.systemConfigActions}>
                        <ActionConfiguration
                          label={<Icon type={'threeDotsVertical'} />}
                          source={'system-actions'}
                          handleSubmit={setCustomActions}
                          variant={'text'}
                          color={'default'}
                        />
                      </div>
                    ) : null}
                  </div>
                </Dropdown>
              </Grid>
            </div>
          </Box>
        </Box>
      </Drawer>
      {!isMobileView ? (
        <Box className={`${classes.borderIconButton} ${classes.abButton}`}>
          <Button
            onClick={() => {
              setOpenSideBar(!openSideBar);
              localStorage.setItem(OPEN_SIDEBAR, JSON.stringify(!openSideBar));
            }}
            variant="outlined"
            className={classes.buttonExpand}
          >
            <div className={classes.iconExp}>
              <Icon
                type={openSideBar ? 'arrowleftcustom' : 'chevronmediumright'}
                style={{ height: '100%', width: '100%', marginLeft: openSideBar ? 0 : 1 }}
              />
            </div>
          </Button>
        </Box>
      ) : (
        <Box className={`${classes.borderIconButton} ${classes.abButtonMobile}`}>
          <Button onClick={() => setOpenSidebarModile(false)} variant="outlined" className={classes.buttonExpand}>
            <div className={classes.iconExp}>
              <Icon
                type={'arrowleftcustom'}
                style={{ height: '100%', width: '100%', marginLeft: openSideBar ? 0 : 1 }}
              />
            </div>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SideBar;

function FeatureFlagPanel() {
  const [open, setOpen] = React.useState(false);
  const ff = window.ff;
  const ffFull = window.ffFull;

  return (
    <React.Fragment>
      <Typography
        onClick={() => setOpen((open) => !open)}
        variant="subtitle1"
        style={{ fontSize: '14px', cursor: 'pointer' }}
      >
        Feature toggle
      </Typography>
      <FeatureFlagsPopup featureFlags={ffFull} ffEnabled={ff} open={open} setOpen={setOpen}></FeatureFlagsPopup>
    </React.Fragment>
  );
}
