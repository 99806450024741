export const INPUT_TYPE_OPTIONS_KEY = 'input_type.options';
export const INPUT_TYPE_SELECTION_OPTIONS_KEY = 'input_type.selectionOptions';
export const INPUT_TYPE_PLACEHOLDER = 'place_holder';
export const INPUT_TYPE_HELPER_TEXT = 'help_text';
export const INPUT_TYPE_TOOLTIP = 'tooltip';
export const INPUT_TYPE_REGEX = 'regex';
export const INPUT_TYPE_DATA_TYPE = 'data_type';
export const INPUT_TYPE_REQUEST_OPTIONS = 'input_type.request_options';
export const INPUT_TYPE_TAG_TYPE = 'input_type.tag_type';
export const INPUT_TYPE_ETABLE_SELECTED = 'eTable_selected';
export const INPUT_TYPE_CUSTOM_TAG = 'custom_tag';
export const INPUT_TYPE_SEARCH_HINT = 'search_hint';
export const INPUT_TYPE_ETABLE_CONFIG = 'eTable_config';
export const INPUT_TYPE_STYLES = 'styles';
export const INPUT_TYPE_SUBMIT_AFTER_CLICK = 'submit_after_click';
export const INPUT_TYPE_RELOAD_AFTER_SUBMIT = 'reload_after_submit';
export const INPUT_TYPE_SUBMIT_METHOD = 'method';
export const INPUT_TYPE_USE_QUERY_PAYLOAD = 'use_query_payload';
export const INPUT_TYPE_FIELD_ID = 'field_id';
export const INPUT_TYPE_FIELD_LABEL = 'field_label';
export const INPUT_TYPE_FIELD_VALUE = 'field_value';
export const INPUT_TYPE_FIELD_FILTER = 'field_filter';
export const INPUT_TYPE_METRICS = 'metrics';
export const INPUT_TYPE_FILTER_FIELD = 'filter_field';
export const INPUT_TYPE_MULTIPLE_SELECT = 'multiple_select';
export const INPUT_TYPE_MAX_ITEM_SELECTED = 'max_item_selected';
export const INPUT_TYPE_MAX_LINES = 'max_lines';
export const INPUT_TYPE_COMPACT_SPLIT_DATA = 'compact_split_data';
export const INPUT_TYPE_DISPLAY_AS = 'display_as';
export const INPUT_TYPE_DISPLAY_AS_SELECTION = 'display_as_selection';
export const INPUT_TYPE_DISPLAY_AS_COMPACT = 'display_as_compact';
export const INPUT_TYPE_VIEW = 'view';
export const INPUT_TYPE_SYSTEM_FILTERS = 'compact_system_filters';
export const INPUT_TYPE_ADDITIONAL_DATA = 'compact_additional_data';
export const INPUT_TYPE_LINK_GROUPBY_FILTER = 'link_groupby_filter';
export const INPUT_TYPE_COMPACT_LINKED_FIELD = 'compact_linked_field';
export const INPUT_TYPE_COMPACT_SOURCE_FIELD = 'compact_source_field';
export const INPUT_TYPE_COMPACT_TARGET_FIELD = 'compact_target_field';
export const INPUT_TYPE_CUSTOM_FILTERS = 'custom_filters';
export const INPUT_TYPE_COMPACT_OPERATOR = 'compact_operator';
export const INPUT_TYPE_COMPACT_IGNORE_CALENDAR = 'compact_ignore_calendar';
export const INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER = 'compact_source_inherit_filter_field';
export const INPUT_TYPE_SUPPORT_FORMULA = 'support_formula';
export const INPUT_TYPE_SUPPORT_NARRATIVE = 'support_narrative';
export const INPUT_TYPE_SUPPORT_JSON = 'support_json';
export const INPUT_TYPE_FORMAT_INPUT = 'format_input';
export const INHERIT_FROM_COMPACT = 'inherit_from_compact';
export const CELL_FORMAT = 'cell_format';
export const COMPACT_VALUE_GETTER = 'compact_value_getter';
export const COMPACT_STATIC_VALUE = 'compact_static_value';
export const COMPACT_PAGINATION_LIMIT = 'compact_pagination_limit';
export const MAX_WIDTH = 'max_width';
export const INPUT_TYPE_HIDDEN_FIELD = 'hidden';

export const INPUT_TYPE_CONFIG_TYPE_CONSTANT = 'constant';
export const INPUT_TYPE_CONFIG_TYPE_API_MAPPING = 'api_mapping';
export const INPUT_TYPE_CONFIG_TYPE_API_MAPPING_WITH_BULK = 'api_mapping_with_bulk';
export const INPUT_TYPE_CONFIG_TYPE_FORMULA = 'formula';

export const CUSTOM_TAG = 'customTag';
export const SYSTEM_FIELD_DEFINED = 'system_field_defined';
export const SELECT_COMPONENT_NAME = 'select';
export const MULTIPLE_SELECT_COMPONENT_NAME = 'multipleSelect';
export const LABEL_SELECT_COMPONENT_NAME = 'labelSelect';
export const TAG_EDITOR_COMPONENT_NAME = 'tagEditor';
export const TEXT_INPUT_COMPONENT_NAME = 'textInput';
export const TEXTAREA_COMPONENT_NAME = 'textarea';
export const FILTER_COMPONENT_NAME = 'filter';
export const CALENDAR_COMPONENT_NAME = 'calendar';
export const BUDGET_EDITOR_COMPONENT_NAME = 'budgetEditor';
export const BIDDING_EDITOR_COMPONENT_NAME = 'biddingEditor';
export const TOGGLE_COMPONENT_NAME = 'toggle';

export const INPUT_TYPE_DATA_OBJECT = 'Data object';

export const INPUT_TYPE_SELECT_FROM_ETABLE = 'selectFromETable';
export const INPUT_TYPE_SELECT_FROM_TAG = 'selectFromTag';

export const DEFAULT_CONFIG_ETABLE_SELECTION = {
  endpoint: {
    GET_TABLE_DATA: '',
  },
  title: '',
  primaryKeys: [],
  mapping: {},
  view: {
    id: 'all',
    name: 'All',
  },
  views: [
    {
      id: 'all',
      name: 'All',
    },
  ],
};
export const INIT_VALUE_TYPE_FROM_ETABLE = 'init_value_type_based_etable';
export const INIT_VALUE_TYPE_STATIC = 'init_value_type_STATIC';
export const INIT_VALUE_TYPE_CLIPBOARD = 'init_value_type_based_clipboard';
export const INIT_VALUE_TYPE_FROM_COMPACT_ETABLE = 'init_value_type_compact_etable';

export const ACTION_CATEGORY_FILTER = 'filter';
export const ACTION_CATEGORY_STATUS = 'status';
export const ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS = 'internal_link_with_params';
export const ACTION_CATEGORY_INLINE_FORM = 'inline_form';
export const ACTION_CATEGORY_ETABLE_FORM = 'eTable_form';
export const ACTION_CATEGORY_JSON_FORM = 'json_form';
export const ACTION_CATEGORY_CONDITON_FORMAT = 'condition_format';
export const ACTION_CATEGORY_SEPARATOR = 'separator';
export const ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE = 'switch_placement_type';
export const ACTION_CATEGORY_INTEGRATE_STOREFRONT = 'integrate_storefront';
export const ACTION_CATEGORY_IMPACT_TABLE = 'impact_table';
export const ACTION_CATEGORY_TAG = 'tag';
export const ACTION_CATEGORY_PERIOD = 'period';
export const ACTION_CATEGORY_SCHEDULE_DAILY = 'schedule_daily';
export const ACTION_CATEGORY_COMPACT_TABLE_VIEW = 'compact_table_view';
export const ACTION_CATEGORY_COPY_PASTE = 'copy_paste';
export const ACTION_CATEGORY_CODE_IDE = 'code_ide';
export const ACTION_CATEGORY_SCRIPT_ACTION = 'script_action';
export const ACTION_CATEGORY_CODE_EXECUTION = 'code_execution';

export const viewByTimelineOptions = [
  { label: 'Regularly', value: 'regularly' },
  { label: 'Hourly', value: 'hourly' },
  { label: 'Daily', value: 'daily' },
  { label: 'Date', value: 'date' },
  { label: 'Weekly', value: 'weekly' },
  { label: 'Monthly', value: 'monthly' },
  { label: 'Quarterly', value: 'quarterly' },
  { label: 'Yearly', value: 'yearly' },
  { label: 'None', value: 'all' },
];
