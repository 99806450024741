import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AlignCenter = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="3 3 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="13" y="3" width="2" height="10" rx="1" transform="rotate(90 13 3)" />
    <rect x="11" y="7" width="2" height="6" rx="1" transform="rotate(90 11 7)" />
    <rect x="13" y="11" width="2" height="10" rx="1" transform="rotate(90 13 11)" />
  </SvgIcon>
);

export default AlignCenter;
