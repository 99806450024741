import { EIP_CONSTANT } from '@eip/next/lib/main';
import { getActionMenu, staticFilter } from './common-config';

export const NEW_AD_OPTIMIZATION_CONFIG = {
  title: 'Ad optimization',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getAdsPlacementData',
    GET_PROPERTIES: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getDimensions?table=ADS_PLACEMENT',
  },
  primaryKeys: [
    'COUNTRY.country_code',
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_PLACEMENT.id',
    'ADS_OBJECT.id',
    'ADS_CALENDAR.id',
    'PRODUCT.id',
  ],
  disabledComponents: {
    dimension: ['country', 'marketplace', 'storefront', 'ad_type', 'ad_tool', 'campaign', 'ad_object'],
  },
  settingType: [
    {
      type: 'dimension',
      label: 'Dimension',
      singleSelectColumns: ['keyword', 'negative_keyword', 'audience', 'placement', 'display_location'],
    },
    { type: 'attribute', label: 'Attribute' },
    { type: 'metric', label: 'Metric' },
  ],
  system: {
    hiddenComponents: ['majorButton', 'search'],
    filter: staticFilter.adOptimization,
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'country',
          'marketplace',
          'storefront',
          'ad_type',
          'ad_tool',
          'campaign',
          'ad_object',
          'keyword',
          'display_location',
          'keyword_tag',
          'campaign_status',
          'campaign_daily_budget',
          'campaign_total_budget',
          'ad_object_status',
          'ad_object_budget',
          'keyword_status',
          'keyword_bidding_price',
          'keyword_match_type',
        ],
      }
    : {}),
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
      menu: getActionMenu('country'),
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
      menu: getActionMenu('marketplace'),
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
      menu: getActionMenu('storefront'),
    },
    ad_type: {
      title: 'Ad type',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTYPE.ads_type',
      valueGetter: {
        label: 'ADTYPE.ads_type_name',
        id: 'ADTYPE.ads_type',
      },
      menu: getActionMenu('ad_type'),
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADTOOL.ads_tool',
      valueGetter: {
        label: 'ADTOOL.ads_tool_name',
        id: 'ADTOOL.ads_tool',
      },
      menu: getActionMenu('ad_tool'),
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      sortField: 'ADS_CAMPAIGN.name',
      filterField: 'ADS_CAMPAIGN',
      filterGetter: {
        default: 'ADS_CAMPAIGN.name',
      },
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        subtext: 'ADS_CAMPAIGN.campaign_code',
        id: 'ADS_CAMPAIGN.id',
      },
      menu: getActionMenu('campaign'),
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT',
      filterGetter: {
        default: 'ADS_OBJECT.name',
      },
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        id: 'ADS_OBJECT.id',
        masterObjectId: 'PRODUCT.id',
      },
      menu: getActionMenu('ad_object'),
    },
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.name',
      groupByColumn: 'ADS_PLACEMENT.name',
      valueGetter: {
        label: 'ADS_PLACEMENT.name',
        id: 'ADS_PLACEMENT.id',
        groupByColumn: 'ADS_PLACEMENT.name',
      },
      menu: getActionMenu('keyword'),
    },
    ads_campaign_transform_status: {
      title: 'Campaign sync status',
      propertyType: 'dimension',
      cellFormat: 'transformStatus',
      filterField: 'ADS_CAMPAIGN.transform_status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.transform_status',
        id: 'ADS_CAMPAIGN.transform_status',
        value: 'ADS_CAMPAIGN.transform_status',
        updatedAt: 'ADS_CAMPAIGN.transform_status_updated_at',
      },
      menu: [
        {
          title: 'Data sync',
          name: 'Sync data',
          icon: 'pencil',
          editor: 'TransformStatusEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.transform_status',
            },
          },
        },
      ],
    },
    ads_object_transform_status: {
      title: 'Ad object sync status',
      propertyType: 'dimension',
      cellFormat: 'transformStatus',
      filterField: 'ADS_OBJECT.transform_status',
      valueGetter: {
        label: 'ADS_OBJECT.transform_status',
        id: 'ADS_OBJECT.transform_status',
        value: 'ADS_OBJECT.transform_status',
        updatedAt: 'ADS_OBJECT.transform_status_updated_at',
      },
      menu: [
        {
          title: 'Data sync',
          name: 'Sync data',
          icon: 'pencil',
          editor: 'TransformStatusEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.transform_status',
            },
          },
        },
      ],
    },
    ads_placement_transform_status: {
      title: 'Ad placement sync status',
      propertyType: 'dimension',
      cellFormat: 'transformStatus',
      filterField: 'ADS_PLACEMENT.transform_status',
      valueGetter: {
        label: 'ADS_PLACEMENT.transform_status',
        id: 'ADS_PLACEMENT.transform_status',
        value: 'ADS_PLACEMENT.transform_status',
        updatedAt: 'ADS_PLACEMENT.transform_status_updated_at',
      },
      menu: [
        {
          title: 'Data sync',
          name: 'Sync data',
          icon: 'pencil',
          editor: 'TransformStatusEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.transform_status',
            },
          },
        },
      ],
    },
    display_location: {
      title: 'Display location',
      propertyType: 'dimension',
      cellFormat: 'text',
      filterField: 'ADS_PLACEMENT.display_location_name',
      valueGetter: {
        ...(ff.fix_group_by_display_location ? { id: 'ADS_PLACEMENT.display_location_name' } : {}),
        adPlacementId: 'ADS_PLACEMENT.id',
        label: 'ADS_PLACEMENT.display_location_name',
      },
    },
    // negative_keyword: {
    //   title: 'Negative keyword',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    // audience: {
    //   title: 'Audience',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    // placement: {
    //   title: 'Campaign',
    //   propertyType: 'dimension',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },

    // ============= ATTRIBUTE =============
    // campaign_brief: {
    //   title: 'Campaign brief',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_CAMPAIGN.campaign_brief',
    //   valueGetter: {
    //     label: 'ADS_CAMPAIGN.campaign_brief',
    //   },
    // },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      filterField: 'ADS_PLACEMENT.general_tag',
      dataType: 'string',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.add_note_column_including_inline_edit
      ? {
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'nextString',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
        }
      : {}),
    campaign_status: {
      title: 'Campaign status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.status',
      valueGetter: {
        label: 'ADS_CAMPAIGN.status',
        status: 'ADS_CAMPAIGN.status',
        tooltips: 'ADS_CAMPAIGN.tooltips',
      },
      menu: getActionMenu('campaign_status'),
    },
    // disabled due to lack of api support
    // campaign_timeline: {
    //   title: 'Campaign timeline',
    //   propertyType: 'attribute',
    //   cellFormat: 'timeline',
    //   dataType: 'date',
    //   filterField: 'ADS_CAMPAIGN.timeline_from',
    //   valueGetter: {
    //     date_from: 'ADS_CAMPAIGN.timeline_from',
    //     date_to: 'ADS_CAMPAIGN.timeline_to',
    //   },
    //   menu: [
    //     {
    //       title: 'Campaign timeline',
    //       name: 'Change campaign timeline',
    //       icon: 'pencil',
    //       editor: 'TimeLineEditor',
    //       payload: {
    //         field: {
    //           date_from: 'ADS_CALENDAR.timeline_from',
    //           date_to: 'ADS_CALENDAR.timeline_to',
    //         },
    //       },
    //     },
    //   ],
    // },
    campaign_daily_budget: {
      title: 'Campaign daily budget',
      propertyType: 'attribute',
      cellFormat: 'dailyBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.daily_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_daily_budget'),
    },
    campaign_total_budget: {
      title: 'Campaign total budget',
      propertyType: 'attribute',
      cellFormat: 'totalBudget',
      dataType: 'float',
      filterField: 'ADS_CAMPAIGN.total_budget',
      valueGetter: {
        value: 'ADS_CAMPAIGN.total_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('campaign_total_budget'),
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    // ad_object_bidding_price: {
    //   title: 'Ad object bidding price',
    //   propertyType: 'attribute',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ADS_OBJECT.bidding_price',
    //   valueGetter: {
    //     value: 'ADS_OBJECT.bidding_price',
    //   },
    // },
    // ad_object_creative: {
    //   title: 'Ad object creative',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'ADS_OBJECT.creative',
    //   valueGetter: {
    //     label: 'ADS_OBJECT.creative',
    //   },
    // },
    keyword_status: {
      title: 'Keyword status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.status',
      valueGetter: {
        label: 'ADS_PLACEMENT.status',
        status: 'ADS_PLACEMENT.status',
        id: 'ADS_PLACEMENT.id',
        tooltips: 'ADS_PLACEMENT.tooltips',
      },
      menu: getActionMenu('keyword_status'),
    },
    keyword_bidding_price: {
      title: 'Keyword bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_PLACEMENT.bidding_price',
      valueGetter: {
        value: 'ADS_PLACEMENT.bidding_price',
        currency: 'STOREFRONT.currency',
      },
      menu: [
        {
          title: 'Keyword bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_PLACEMENT.name',
            },
            static: {
              primaryKey: 'ADS_PLACEMENT.id',
            },
            excludeOperator: [
              {
                operator: 'suggest-bid-price',
                field: 'ADTOOL.ads_tool',
                values: ff.enable_bidding_price ? [] : ['SHP_DA'],
              },
            ],
          },
          // disabledSubmit: true,
          // disabledSubmitReason: 'not supported yet (code: mop)',
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
      ],
    },
    keyword_match_type: {
      title: 'Keyword match type',
      propertyType: 'attribute',
      cellFormat: 'textMatchType',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.match_type',
      valueGetter: {
        value: 'ADS_PLACEMENT.match_type',
        id: 'ADS_PLACEMENT.id',
      },
      menu: getActionMenu('keyword_match_type'),
    },
    // premium_rate: {
    //   title: 'Premium rate',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.premium_rate',
    //   valueGetter: {
    //     label: 'AUDIENCE.premium_rate',
    //   },
    // },
    // age: {
    //   title: 'Age',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.age',
    //   valueGetter: {
    //     label: 'AUDIENCE.age',
    //   },
    // },
    // gender: {
    //   title: 'Gender',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.gender',
    //   valueGetter: {
    //     label: 'AUDIENCE.gender',
    //   },
    // },
    // location: {
    //   title: 'Location',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.location',
    //   valueGetter: {
    //     label: 'AUDIENCE.location',
    //   },
    // },
    // interest_category: {
    //   title: 'Interest category',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: 'AUDIENCE.interest_category',
    //   valueGetter: {
    //     label: 'AUDIENCE.interest_category',
    //   },
    // },

    // ============= METRIC =============
    impression: {
      title: 'Impression',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'impression',
      valueGetter: {
        value: 'impression',
      },
      menu: getActionMenu('_metric'),
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'click',
      valueGetter: {
        value: 'click',
      },
      menu: getActionMenu('_metric'),
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cost',
      valueGetter: {
        value: 'cost',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ads_item_sold: {
      title: 'Ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_item_sold',
      valueGetter: {
        value: 'ads_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    direct_item_sold: {
      title: 'Direct ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_item_sold',
      valueGetter: {
        value: 'direct_item_sold',
      },
      menu: getActionMenu('_metric'),
    },
    ads_gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ads_gmv',
      valueGetter: {
        value: 'ads_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    direct_gmv: {
      title: 'Direct ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_gmv',
      valueGetter: {
        value: 'direct_gmv',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ads_order: {
      title: 'Ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ads_order',
      valueGetter: {
        value: 'ads_order',
      },
      menu: getActionMenu('_metric'),
    },
    direct_ads_order: {
      title: 'Direct ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'direct_order',
      valueGetter: {
        value: 'direct_order',
      },
      menu: getActionMenu('_metric'),
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'roas',
      valueGetter: {
        value: 'roas',
      },
      menu: getActionMenu('_metric'),
    },
    cir: {
      title: 'CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cir',
      valueGetter: {
        value: 'cir',
      },
      menu: getActionMenu('_metric'),
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpc',
      valueGetter: {
        value: 'cpc',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('_metric'),
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ctr',
      valueGetter: {
        value: 'ctr',
      },
      menu: getActionMenu('_metric'),
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cr',
      valueGetter: {
        value: 'cr',
      },
      menu: getActionMenu('_metric'),
    },
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
    direct_roas: {
      title: 'Direct ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_roas',
      valueGetter: {
        value: 'direct_roas',
      },
      menu: getActionMenu('_metric'),
    },
    cpi: {
      title: 'CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'cpi',
      valueGetter: {
        value: 'cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cr: {
      title: 'Direct CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cr',
      valueGetter: {
        value: 'direct_cr',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cpi: {
      title: 'Direct CPI',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cpi',
      valueGetter: {
        value: 'direct_cpi',
      },
      menu: getActionMenu('_metric'),
    },
    direct_cir: {
      title: 'Direct CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'direct_cir',
      valueGetter: {
        value: 'direct_cir',
      },
      menu: getActionMenu('_metric'),
    },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  search: '',
};
