import * as React from 'react';
import { AuthContext, eipRequest, EIP_CONSTANT } from '@eip/next/lib/main';
import { get } from 'lodash';
import { DELAY_REQUEST_ADDITIONAL_DATA } from '@ep/insight-ui/system/helper/constant';

const EP_VERSION = EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42';
let cachedRes = null;

export function useVersion({ value = '', dateTime = '' }) {
  const [version, setVersion] = React.useState({ value, dateTime });

  const { currentWorkspaceDetail } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (cachedRes) {
      Promise.resolve(cachedRes).then((res) => {
        setVersion({
          value: get(res, 'data.v.version'),
          dateTime: get(res, 'data.v.date'),
        });
      });
    } else if (currentWorkspaceDetail) {
      const timeout = setTimeout(() => {
        cachedRes = eipRequest.get(EP_VERSION + '/kv/eip_version');
        cachedRes.then((res) => {
          cachedRes = res;
          setVersion({
            value: get(res, 'data.v.version'),
            dateTime: get(res, 'data.v.date'),
          });
          console.info('version', res);
        });
      }, DELAY_REQUEST_ADDITIONAL_DATA);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [currentWorkspaceDetail]);

  return version;
}
