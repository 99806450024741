let isInit = false;
export function init() {}

export function setUserContext({ id, username, email }) {}

const transactions: { [key: string]: any } = {};
export function getTransaction(type, name = 'default') {}

/**
 *
 * @param labels pair key:value; *searchable* in elastic apm
 */
export function addLabels(labels: { [k: string]: any }) {}

export function endTransaction(type, name = 'default') {}

export function error(error: Error | string) {}

/**
 *
 * @param labels pair key:value; *none* searchable in elastic apm
 */
export function addCustomData(labels: { [k: string]: any }) {}
