import { aim, eipRequest } from '@eip/next/lib/main';
import { EIP_CONSTANT } from '@ep/insight-ui/sw/constant';
import { atom } from 'jotai';

export const PageAtoms = {
  fn: atom({
    syncPage: (target: string, blockEid: string) => {
      if (target === 'staging') {
        return eipRequest.post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/page/chunk',
          { "id": blockEid, "user_id": aim.getUserSettings().profile.userId },
        ).then(res => {
          return eipRequest
            .post(
              'https://staging-eip-manager.epsilo.io/public/s2s/promote-to-botep',
              {
                user: {
                  id: aim.getUserSettings().profile.userId,
                  email: aim.getUserSettings().profile.userEmail,
                },
                ...res,
                target,
              },
              { 'x-token': 'bzpwcdihujxo' }
            )
        })
      } else {
        return eipRequest
          .post(
            EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/dev/production/promote-to-botep',
            {
              pageId: blockEid,
              user: {
                id: aim.getUserSettings().profile.userId,
                email: aim.getUserSettings().profile.userEmail,
              },
            },
          )
      }
    }
  }),
}