import { usePage } from '@eip/next/lib/main';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { debounce } from 'lodash';
import React from 'react';
import { TitleEditable } from './title-editable';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import Icon from '@ep/insight-ui/icons/Icon';
import { makeStyles } from '@material-ui/core';
import RichPrompt from '@ep/insight-ui/elements/rich-prompt';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

export function PageTitle() {
  const classes = useStyles();
  const { title, updateEditorTitle, description, formulaTitle, nextPageTitle, formulaPageHashtag, formulaPageSubtext } =
    usePage();
  const [updatedTitle, setUpdatedTitle] = React.useState<{ text: string; ts: number }>({
    text: formulaTitle,
    ts: Date.now(),
  });

  const syncPageTitle = React.useCallback(
    debounce((title) => {
      updateEditorTitle(title);
    }, 500),
    [],
  );

  // FIXME: the title updating is cumbersome
  const handleChangeTitle = React.useCallback((value) => {
    syncPageTitle(value.text);
  }, []);

  React.useEffect(() => {
    if (formulaTitle !== updatedTitle.text) {
      setUpdatedTitle({
        text:
          String(title).startsWith('=') && String(title).includes('uQuery') && !formulaTitle
            ? ''
            : formulaTitle || title,
        ts: Date.now(),
      });
    }
  }, [formulaTitle, title]);

  const isReadOnly =
    String(nextPageTitle || title).startsWith('=') && String(nextPageTitle || title).includes('uQuery');

  return (
    // <input
    //   type="text"
    //   placeholder="Untitled"
    //   defaultValue={title}
    //   onChange={(evt) => {
    //     handleChangeTitle(evt.target.value);
    //   }}
    // />
    <div className={classes.container}>
      <RichPrompt text={formulaPageHashtag} />
      <Typography variant="h1" className={'page-title'} placeholder="Untitled">
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <TitleEditable
            placeholder={'Untitled'}
            value={updatedTitle}
            onChange={handleChangeTitle}
            readOnly={
              String(nextPageTitle || title).startsWith('=') &&
              (String(nextPageTitle || title).includes('uQuery') ||
                String(nextPageTitle || title).includes('chartQuery'))
            }
            isEditor
          />
          {description ? (
            <StyledTooltip
              title={<div style={{ whiteSpace: 'pre-line', padding: '2px 4px' }}>{description}</div>}
              placement={'right'}
            >
              <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                <Icon type={'ic/mi:circle-information/#737373'} size={'20px'} width={'20px'} />
              </div>
            </StyledTooltip>
          ) : null}
        </div>
      </Typography>
      <RichPrompt text={formulaPageSubtext} />
    </div>
  );
}
