import { useLog, EIP_CONSTANT, eipRequest as request } from '@eip/next/lib/main';
import { get, uniqueId, startCase, lowerCase } from 'lodash';
import moment from 'moment';
import qs from 'qs';

const API_URL = EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY;
const endpoint = {
  GET_CAMPAIGN_SETTING: API_URL + '/mop-query/listing/getCampaignData',
  GET_CAMPAIGN_INSIDE_DATA: API_URL + '/mop-query/listing/getCampaignInsideData',
  GET_DIMENSION_DEFINITION: API_URL + '/ads-operation/get-dimension-definition',
  CREATE_DIMENSION: API_URL + '/ads-operation/create-dimension',
  CREATE_ADS_UNIT: API_URL + '/ads-operation/create-ads-unit',
  UPDATE_DIMENSION: API_URL + '/ads-operation/update-dimension',
  MASS_CREATE_DIMENSION: API_URL + '/ads-operation/mass-create-dimension',
  MASS_UPDATE_DIMENSION: API_URL + '/ads-operation/mass-update-dimension',
  MASS_DELETE_DIMENSION: API_URL + '/ads-operation/mass-delete-dimension',
  REQUEST_CUSTOM_KEYWORD: API_URL + '/ads-operation/filter-dimension',
};

const log = useLog('lib:campaign-detail');

const filterSelectedItems = (rows: any[], idField, fieldNames: string[]) => {
  return rows.reduce((acc, r) => {
    const exist = acc.find((i) => i[idField] === r[idField]);
    if (!exist && r[idField]) {
      return [
        ...acc,
        {
          ...fieldNames.reduce((carry, k) => {
            return { ...carry, [k]: r[k] };
          }, {}),
        },
      ];
    }
    return acc;
  }, []);
};

const transformDataRows = (data) => {
  if (data) {
    return data.rows.map((r) => {
      return data.headers.reduce((acc, h, i) => {
        return {
          ...acc,
          [h]: r[i],
        };
      }, {});
    });
  }
  return [];
};

export const getCampaignSettingInfo = ({
  campaignId = null,
  marketplaceCode = 'SHOPEE',
}: {
  campaignId: string;
  marketplaceCode: string;
}) => {
  const param = {
    dimensions: ['COUNTRY', 'MARKETPLACE', 'STOREFRONT', 'ADTYPE', 'ADTOOL', 'ADS_CALENDAR', 'ADS_CAMPAIGN'],
    attributes: [
      'COUNTRY.country_code',
      'COUNTRY.country_name',
      'MARKETPLACE.marketplace_code',
      'MARKETPLACE.marketplace_name',
      'STOREFRONT.id',
      'STOREFRONT.name',
      'STOREFRONT.currency',
      'ADTYPE.ads_type',
      'ADTYPE.ads_type_name',
      'ADTOOL.ads_tool',
      'ADTOOL.ads_tool_name',
      'ADS_CALENDAR.id',
      'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
      'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
      'ADS_CAMPAIGN.id',
      'ADS_CAMPAIGN.name',
      'ADS_CAMPAIGN.campaign_code',
      'ADS_CAMPAIGN.status',
      'ADS_CAMPAIGN.daily_budget',
      'ADS_CAMPAIGN.total_budget',
    ],
    metrics: [],
    pagination: { page: 1, limit: 10 },
    from: moment().add(-1, 'year').format('YYYY-MM-DD'),
    to: moment().add(1, 'year').format('YYYY-MM-DD'),
    filter: {
      combinator: 'and',
      filters: [
        {
          field: 'ADS_CAMPAIGN.id',
          operator: '=',
          value: campaignId,
          dataType: 'integer',
        },
        {
          field: 'MARKETPLACE.marketplace_code',
          operator: 'is',
          value: marketplaceCode,
          dataType: 'string',
        },
      ],
    },
  };

  if (marketplaceCode === 'LAZADA') {
    param.attributes.push(
      'ADS_CAMPAIGN.objective',
      'ADS_CAMPAIGN.automated_ads',
      'ADS_CAMPAIGN.automated_ads_select_product',
      'ADS_CAMPAIGN.first_search_slot',
    );
  }
  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.post(endpoint.GET_CAMPAIGN_SETTING, param);
      const rows = transformDataRows(res.data).map((r) => {
        return {
          ...r,
          'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from': r['ADS_CALENDAR.ADS_CAMPAIGN.timeline_from'].split(' ')[0],
          'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to': r['ADS_CALENDAR.ADS_CAMPAIGN.timeline_to'].split(' ')[0],
        };
      });
      if (rows.length > 0) {
        resolve(rows[0]);
      } else {
        resolve(null);
      }
    } catch (err) {
      reject(err);
    }
  });
};

export const getCampaignInsideData = (campaignId: string | number, campaignFrom?: string, campaignTo?: string) => {
  const reqParam = {
    from: campaignFrom ? campaignFrom : moment().format('YYYY-MM-DD'),
    to: campaignTo ? campaignTo : moment().format('YYYY-MM-DD'),
    attributes: [
      'MARKETPLACE.marketplace_name',
      'MARKETPLACE.marketplace_code',
      'ADS_CAMPAIGN.id',
      'ADTOOL.ads_tool',
      'ADS_OBJECT.id',
      'ADS_PLACEMENT.id',
      'ADS_CALENDAR.id',
      'COUNTRY.country_code',
      'COUNTRY.country_name',
      'STOREFRONT.name',
      'STOREFRONT.id',
      'ADTYPE.ads_type',
      'ADTYPE.ads_type_name',
      'ADS_OBJECT.total_budget',
      'ADS_OBJECT.daily_budget',
      'STOREFRONT.currency',
      'ADS_PLACEMENT.bidding_price',
      'ADS_PLACEMENT.match_type',
      'ADTOOL.ads_tool_name',
      'PRODUCT.name',
      'PRODUCT.id',
      'PRODUCT.product_sid',
      'ADS_PLACEMENT.name',
    ],
    dimensions: [
      'MARKETPLACE',
      'ADS_CAMPAIGN',
      'ADTOOL',
      'ADS_OBJECT',
      'ADS_PLACEMENT',
      'ADS_CALENDAR',
      'COUNTRY',
      'STOREFRONT',
    ],
    metrics: [
      'roas',
      'direct_gmv',
      'cost',
      'ads_gmv',
      'ads_item_sold',
      'direct_item_sold',
      'cpc',
      'click',
      'ctr',
      'cr',
      'stock',
      'discount',
    ],
    hiddenFilter: {
      combinator: 'AND',
      filters: [
        {
          field: 'ADS_CAMPAIGN.id',
          dataType: 'integer',
          operator: '=',
          value: campaignId,
        },
      ],
    },
    pagination: {
      limit: 1000,
    },
  };

  return new Promise(async (resolve, reject) => {
    try {
      const res = await request.post(endpoint.GET_CAMPAIGN_INSIDE_DATA, reqParam);
      // Restore campaign detail rows
      const rows = transformCellCompactSelections(transformDataRows(res.data), campaignFrom, campaignTo);

      resolve({
        data: {
          rows: rows,
          pagination: { page: 1, limit: 100, total: rows.length },
        },
      });
    } catch (err) {
      reject(err);
    }
  });
};

enum Objective {
  SALE = 'SALE',
  TRAFFIC = 'TRAFFIC',
}

export const createCampaign = (param: {
  data: {
    name: string;
    storefrontId: string;
    toolCode: string;
    totalBudget: number;
    dailyBudget: number;
    adsCalendars: {
      timelineFrom: string;
      timelineTo: string;
    }[];
    objective?: Objective;
    countryCode?: string;
  };
  marketplaceCode: string;
}) => {
  const reqParam = {
    ...param,
    dimension: 'ADS_CAMPAIGN',
  };
  const toolCode = get(param, ['data', 'toolCode'], '');
  const useCreateAdsUnit = toolCode.startsWith('SHP_BA');
  const adsUnitAttribute = {
    daily_budget: String(get(param, ['data', 'dailyBudget'], '0')),
    total_budget: String(get(param, ['data', 'totalBudget'], '0')),
    tool_code: get(param, ['data', 'toolCode']),
    marketplace_code: get(param, ['marketplaceCode']),
    storefront_id: get(param, ['data', 'storefrontId']),
    country_code: get(param, ['data', 'countryCode']),
    name: get(param, ['data', 'name']),
    status: 'DRAFT',
  };
  const adsUnitFields = [
    'daily_budget',
    'total_budget',
    'tool_code',
    'marketplace_code',
    'storefront_id',
    'country_code',
    'name',
    'status',
  ];
  const createAdsUnitParams = {
    data: {
      attributes: adsUnitFields.map((field) => {
        return {
          field_name: field,
          value: adsUnitAttribute[field],
        };
      }),
      ads_unit_type: 'AdsCampaign',
      ads_calendars: get(param, ['data', 'adsCalendars'], []).map((calendar) => {
        return {
          timeline_from: calendar.timelineFrom,
          timeline_to: calendar.timelineTo,
        };
      }),
    },
  };
  if (useCreateAdsUnit) {
    return request.post(endpoint.CREATE_ADS_UNIT, createAdsUnitParams);
  }
  // return Promise.resolve(CREATE_CAMPAIGN_RES);
  return request.post(endpoint.CREATE_DIMENSION, reqParam);
};

export const updateCampaign = (param: {
  data: {
    name?: string;
    total_budget?: number;
    daily_budget?: number;
    status?: string;
  };
  id: string;
  marketplaceCode: string;
}) => {
  const reqParam = {
    ...param,
    dimension: 'ADS_CAMPAIGN',
  };
  // return Promise.resolve(UPDATE_CAMPAIGN_RES);
  return request.put(endpoint.UPDATE_DIMENSION, reqParam);
};

export const updateCampaignTimeline = (param: {
  data: {
    timelineFrom?: number;
    timelineTo?: number;
  };
  id: string;
  marketplaceCode: string;
}) => {
  const reqParam = {
    ...param,
    dimension: 'ADS_CALENDAR',
  };
  // return Promise.resolve(UPDATE_CAMPAIGN_RES);
  return request.put(endpoint.UPDATE_DIMENSION, reqParam);
};

export const createAdsObjects = (param: {
  data: {
    masterObjectId: string;
    adsCampaignId: string;
    dailyBudget: number;
    totalBudget: number;
  }[];
  marketplaceCode: string;
}) => {
  const reqParam = {
    ...param,
    data: param.data.map((i) => ({
      ...i,
      adsOpsAdsCalendars: [],
      status: 'DRAFT',
    })),
    dimension: 'ADS_OBJECT',
  };
  // return Promise.resolve(CREATE_ADS_OBJECT_RES);
  return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
};

export const deleteAdsObjects = (param: { id: string[]; marketplaceCode: string }) => {
  const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
    {
      ...param,
      dimension: 'ADS_OBJECT',
    },
    {
      arrayFormat: 'comma',
    },
  )}`;
  // return Promise.resolve(CREATE_ADS_OBJECT_RES);
  return request.deleteFetch(url);
};

export const createAdsPlacements = (param: {
  data: {
    adsObjectId: string;
    name: string;
    matchType: string;
    biddingPrice: number;
  }[];
  marketplaceCode: string;
}) => {
  const reqParam = {
    ...param,
    data: param.data.map((i) => ({
      ...i,
      type: 'SEARCH_RESULT_PLACEMENT',
      status: 'DRAFT',
    })),
    dimension: 'ADS_PLACEMENT',
  };
  // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
  return request.post(endpoint.MASS_CREATE_DIMENSION, reqParam);
};

export const deleteAdsPlacements = (param: { id: string[]; marketplaceCode: string }) => {
  const url = `${endpoint.MASS_DELETE_DIMENSION}?${qs.stringify(
    {
      ...param,
      dimension: 'ADS_PLACEMENT',
    },
    {
      arrayFormat: 'comma',
    },
  )}`;

  // return Promise.resolve(CREATE_ADS_PLACEMENT_RES);
  return request.deleteFetch(url);
};

export const publishDimensions = (param: { dimension: string; id: string[]; marketplaceCode: string }) => {
  const reqParam = {
    ...param,
    data: {
      status: 'PUBLISHING',
    },
  };
  return request.put(endpoint.MASS_UPDATE_DIMENSION, reqParam);
};

export const updatePublishedCampaign = (param) => {
  const reqParam: any = {
    dimension: 'ADS_CAMPAIGN',
    id: [].concat(param.campaignId),
    marketplaceCode: param.marketplaceCode,
    data: {
      total_budget: param.totalBudget,
      daily_budget: param.dailyBudget,
      objective: param.objective,
    },
  };
  if (param.currentStatus !== param.campaignStatus) {
    reqParam.data.status = param.campaignStatus;
  }
  return request.put(endpoint.MASS_UPDATE_DIMENSION, reqParam);
};

export function transformCellCompactSelections(rows, campaignFrom, campaignTo) {
  const toolIdField = 'ADTOOL.ads_tool';
  const toolNameField = 'ADTOOL.ads_tool_name';
  const productNameField = 'PRODUCT.name';
  const keywordIdField = 'SUGGESTED_KEYWORD.id';
  const keywordNameField = 'SUGGESTED_KEYWORD.name';

  // FIXME: Wrong logic because api "getCampaignInsideDate" can't return suggest keyword id,
  rows.forEach((r) => {
    r[keywordIdField] = r['ADS_PLACEMENT.id'];
    r[keywordNameField] = r['ADS_PLACEMENT.name'];
  });

  const selectedAdTools = filterSelectedItems(rows, toolIdField, [toolIdField, toolNameField]);
  const productIdField = get(selectedAdTools, [0, toolIdField]) === 'SHP_SA' ? 'STOREFRONT.id' : 'PRODUCT.id';
  const selectedAdObjects = filterSelectedItems(rows, productIdField, [
    productIdField,
    productNameField,
    'ADS_OBJECT.id',
  ]);
  // const selectedKeywords = filterSelectedItems(rows, keywordIdField, keywordNameField);

  const groupedKeywordsByProduct = selectedAdObjects.reduce((acc, adObject) => {
    const productId = adObject[productIdField];
    const keywords = rows
      .filter((r) => r[keywordIdField] && r[productIdField] === productId)
      .map((r) => ({
        master_object_id: r[productIdField],
        [keywordIdField]: r[keywordIdField],
        [keywordNameField]: r[keywordNameField],
      }));
    return {
      ...acc,
      [productId]: keywords,
    };
  }, {});

  rows.forEach((r) => {
    const productId = r[productIdField];
    const selectedKeywords = groupedKeywordsByProduct[productId] || [];
    r.eipCustomRowId = uniqueId('eip_custom_row_');
    r.ad_tool = {
      selectedItems: selectedAdTools,
      currentFocusItem: selectedAdTools.find((i) => i[toolIdField] === r[toolIdField]),
      displayField: toolNameField,
      singleSelection: true,
    };
    r.ad_object = {
      selectedItems: selectedAdObjects,
      currentFocusItem: selectedAdObjects.find((i) => i[productIdField] === r[productIdField]),
      displayField: productNameField,
      hiddenFilter: {
        combinator: 'AND',
        filters: [
          {
            field: 'STOREFRONT.id',
            dataType: 'integer',
            operator: '=',
            value: r['STOREFRONT.id'],
          },
          {
            field: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
            dataType: 'date',
            operator: 'is_on_or_after',
            value: campaignFrom, // FIXME: asked querying calendar on api
          },
          {
            field: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
            dataType: 'date',
            operator: 'is_on_or_before',
            value: campaignTo,
          },
        ],
      },
    };
    r.keyword = {
      selectedItems: selectedKeywords,
      currentFocusItem: selectedKeywords.find((i) => i[keywordIdField] === r[keywordIdField]),
      displayField: keywordNameField,
      hiddenFilter: {
        combinator: 'AND',
        filters: [
          {
            field: productIdField,
            operator: '=',
            dataType: 'integer',
            value: productId,
          },
        ],
      },
    };
  });

  return rows;
}

export async function queryCustomKeyword({
  marketcode,
  toolCode,
  keywords,
  storefrontId,
  productId,
}: {
  toolCode: string;
  marketcode: string;
  keywords: string[];
  storefrontId: string | number;
  productId: string | number;
}) {
  return request.post(endpoint.REQUEST_CUSTOM_KEYWORD, {
    dimension: 'SUGGEST_KEYWORD',
    marketplace_code: marketcode,
    search_by: {
      tool_code: [toolCode],
      storefront_id: [storefrontId],
      master_object_id: [productId],
      name: keywords,
    },
  });
}

const COMMON_TEXT = {
  operation_method: {
    EPSILO_OPERATION: 'Automated by Botep',
    MANUAL_OPERATION: 'Manual',
    COMBINED_OPERATION: 'Automated by MARKETPLACE',
  },
};

export const getDimensionDefinition = (param: {
  dimension: string;
  marketplaceCode: string;
  toolCode?: string;
  countryCode?: string;
}) => {
  return request.get(endpoint.GET_DIMENSION_DEFINITION, param);
};
export const mappingDimensionOptions = ({
  values,
  keyPropertyDimension,
}: {
  values: string;
  keyPropertyDimension: string;
}) =>
  values
    ? (values.split(':')[1] || '').split(',').map((i) => ({
        id: i,
        text: get(COMMON_TEXT, [keyPropertyDimension, i]) || startCase(lowerCase(i)),
      }))
    : [];

export const getDimensionOptions = (param: {
  type: string;
  dimension: string;
  marketplaceCode: string;
  toolCode?: string;
  countryCode?: string;
}) => {
  return new Promise<{ success: any; data: { id: string; text: string; payload?: any }[] }>(async (resolve, reject) => {
    try {
      let opts = [];
      const res = await getDimensionDefinition(param);
      if (res.success) {
        const values = get(res.data, ['data', 'value', param.type]);
        if (values) {
          opts = mappingDimensionOptions({ values, keyPropertyDimension: param.type });
        }
      }
      resolve({
        success: res.success,
        data: opts,
      });
    } catch (err) {
      resolve({
        success: false,
        data: [],
      });
    }
  });
};

export const updateAdObjectMethod = async (param) => {
  const reqParam = {
    marketplaceCode: param.marketplaceIds[0],
    dimension: 'ADS_CAMPAIGN',
    data: {
      automated_ads_select_product: param.adObjectMethod === 'MANUAL_OPERATION' ? 0 : 1,
      automated_ads: param.adObjectMethod === 'MANUAL_OPERATION' ? 0 : 1,
    },
    id: [param.campaignId],
  };
  return request.put(endpoint.MASS_UPDATE_DIMENSION, reqParam);
};
