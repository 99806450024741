import { EventEmitter } from 'events';
import React from 'react';
import UserBilling from '@ep/insight-ui/elements/input-form/form-templates/form-groups/billing-form/billing-form';

export default class FormUserBillingChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return <UserBilling />;
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }) {
  return null;
}
