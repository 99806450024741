import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Chevronmediumright = (props: SvgIconProps) => (
  <SvgIcon {...props} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.58205 4L0.295361 6.28669C0.201768 6.37951 0.127482 6.48996 0.0767864 6.61164C0.0260915 6.73332 -9.15923e-06 6.86384 -9.14771e-06 6.99566C-9.13618e-06 7.12748 0.0260915 7.258 0.0767865 7.37968C0.127482 7.50136 0.201768 7.6118 0.295361 7.70463C0.388189 7.79822 0.498631 7.87251 0.620313 7.9232C0.741996 7.9739 0.872512 8 1.00433 8C1.13615 8 1.26667 7.9739 1.38835 7.9232C1.51004 7.87251 1.62048 7.79822 1.71331 7.70463L4.70896 4.70897C4.80256 4.61614 4.87684 4.5057 4.92754 4.38402C4.97823 4.26234 5.00433 4.13182 5.00433 4C5.00433 3.86818 4.97823 3.73766 4.92754 3.61598C4.87684 3.4943 4.80256 3.38386 4.70896 3.29103L1.71331 0.29537C1.62048 0.201778 1.51004 0.127491 1.38835 0.0767964C1.26667 0.026101 1.13615 3.38167e-07 1.00433 3.49691e-07C0.872512 3.61215e-07 0.741995 0.026101 0.620312 0.0767964C0.49863 0.127491 0.388189 0.201778 0.29536 0.295371C0.201768 0.388199 0.127481 0.49864 0.0767859 0.620323C0.0260909 0.742006 -9.68301e-06 0.872522 -9.67149e-06 1.00434C-9.65996e-06 1.13616 0.026091 1.26668 0.0767859 1.38836C0.127481 1.51005 0.201768 1.62049 0.29536 1.71332L2.58205 4Z"
      fill={props.color}
    />
  </SvgIcon>
);
export default Chevronmediumright;
