import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Arrowleft = (props: SvgIconProps) => (
  <SvgIcon {...props} width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.42229 4L4.70897 1.71331C4.80257 1.62049 4.87685 1.51005 4.92755 1.38836C4.97824 1.26668 5.00434 1.13616 5.00434 1.00434C5.00434 0.872522 4.97824 0.742005 4.92755 0.620322C4.87685 0.498639 4.80257 0.388199 4.70897 0.29537C4.61614 0.201777 4.5057 0.127491 4.38402 0.0767958C4.26234 0.0261006 4.13182 0 4 0C3.86818 0 3.73766 0.0261006 3.61598 0.0767958C3.4943 0.127491 3.38386 0.201777 3.29103 0.29537L0.29537 3.29103C0.201777 3.38386 0.127491 3.4943 0.0767958 3.61598C0.0261006 3.73766 0 3.86818 0 4C0 4.13182 0.0261006 4.26234 0.0767958 4.38402C0.127491 4.5057 0.201777 4.61614 0.29537 4.70897L3.29103 7.70463C3.38386 7.79822 3.4943 7.87251 3.61598 7.9232C3.73766 7.9739 3.86818 8 4 8C4.13182 8 4.26234 7.9739 4.38402 7.9232C4.5057 7.87251 4.61614 7.79822 4.70897 7.70463C4.80257 7.6118 4.87685 7.50136 4.92755 7.37968C4.97824 7.25799 5.00434 7.12748 5.00434 6.99566C5.00434 6.86384 4.97824 6.73332 4.92755 6.61164C4.87685 6.48995 4.80257 6.37951 4.70897 6.28668L2.42229 4Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);
export default Arrowleft;
