import * as React from 'react';

import { Tooltip, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltipView: {
    background: '#253746',
    fontSize: (props) => props.fontSize,
    padding: '8px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
}));

const TooltipDark = ({ title, children, fontSize = '11px', placement = 'bottom-start' }: any) => {
  const classes = useStyles({ fontSize });

  return (
    <Tooltip title={title} classes={{ tooltip: classes.tooltipView }} placement={placement}>
      {children}
    </Tooltip>
  );
};

export default TooltipDark;
