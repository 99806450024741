import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Descensing = (props: SvgIconProps) => (
  <SvgIcon {...props} width="9" height="13" viewBox="0 0 9 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.99403 1.00242L3.99403 8.57876L2.70535 7.28938C2.51724 7.10117 2.26211 6.99543 1.99608 6.99543C1.73005 6.99543 1.47491 7.10117 1.2868 7.28938C1.09869 7.47759 0.993011 7.73286 0.993011 7.99904C0.993011 8.26521 1.09869 8.52048 1.2868 8.70869L4.28374 11.7072C4.37661 11.8009 4.48709 11.8753 4.60883 11.926C4.73056 11.9768 4.86113 12.0029 4.99301 12.0029C5.12489 12.0029 5.25546 11.9768 5.3772 11.926C5.49893 11.8753 5.60942 11.8009 5.70229 11.7072L8.69922 8.70869C8.79236 8.6155 8.86625 8.50486 8.91666 8.3831C8.96707 8.26134 8.99301 8.13083 8.99301 7.99904C8.99301 7.86724 8.96707 7.73673 8.91666 7.61497C8.86625 7.49321 8.79236 7.38257 8.69922 7.28938C8.60608 7.19618 8.4955 7.12226 8.3738 7.07182C8.25211 7.02139 8.12167 6.99543 7.98995 6.99543C7.85822 6.99543 7.72779 7.02139 7.60609 7.07182C7.48439 7.12226 7.37381 7.19618 7.28067 7.28938L5.99199 8.57876L5.99199 1.00242C5.99199 0.737327 5.88674 0.483097 5.6994 0.295651C5.51205 0.108206 5.25796 0.00289898 4.99301 0.002899C4.72807 0.00289901 4.47397 0.108206 4.28663 0.295651C4.09928 0.483097 3.99403 0.737327 3.99403 1.00242Z"
      fill={props.color ? props.color : '#3F4F5C'}
    />
  </SvgIcon>
);
export default Descensing;
