import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const DeleteChip = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.723 8.29533L6.4286 5.00928L9.70298 1.72323C9.79683 1.63009 9.87133 1.51929 9.92216 1.3972C9.973 1.27512 9.99917 1.14417 9.99917 1.01192C9.99917 0.879661 9.973 0.748715 9.92216 0.62663C9.87133 0.504546 9.79683 0.393741 9.70298 0.300607C9.51536 0.114012 9.26157 0.00927734 8.99703 0.00927734C8.73249 0.00927734 8.4787 0.114012 8.29109 0.300607L5.0067 3.58666L1.7223 0.310625C1.62921 0.216724 1.51846 0.142192 1.39644 0.0913298C1.27442 0.0404674 1.14354 0.014281 1.01135 0.014281C0.879161 0.014281 0.748281 0.0404674 0.626258 0.0913298C0.504236 0.142192 0.393486 0.216724 0.300399 0.310625C0.113898 0.498333 0.00921631 0.752253 0.00921631 1.01693C0.00921631 1.2816 0.113898 1.53552 0.300399 1.72323L3.58479 5.00928L0.310412 8.29533C0.216558 8.38846 0.142064 8.49927 0.0912273 8.62135C0.0403907 8.74344 0.0142174 8.87438 0.0142174 9.00664C0.0142174 9.1389 0.0403907 9.26984 0.0912273 9.39193C0.142064 9.51401 0.216558 9.62481 0.310412 9.71795C0.498025 9.90454 0.751817 10.0093 1.01636 10.0093C1.2809 10.0093 1.53469 9.90454 1.7223 9.71795L5.0067 6.4319L8.29109 9.70793C8.38418 9.80183 8.49493 9.87636 8.61695 9.92723C8.73897 9.97809 8.86985 10.0043 9.00204 10.0043C9.13423 10.0043 9.26511 9.97809 9.38713 9.92723C9.50916 9.87636 9.6199 9.80183 9.71299 9.70793C9.90083 9.52155 10.0073 9.26837 10.0092 9.0037C10.0111 8.73902 9.90818 8.48436 9.723 8.29533Z" />
  </SvgIcon>
);

export default DeleteChip;
