import * as React from 'react';
import clsx from 'clsx';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';

import CategoryItem from './item';
import { useCategoryVisualization } from './hook/use-category-visualization';
import LoadingIcon from '../list-control/spinners/icon-spinner';
import { get } from 'lodash';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    width: '100%',
  },
  containerContent: {
    display: 'grid',
    gap: '16px',
    width: '100%',
    [theme.breakpoints.up('xs', 'sm')]: {
      gridTemplateColumns: ({ xs }) => `repeat(${xs}, 1fr)`,
    },
    [theme.breakpoints.up('sm', 'md')]: {
      gridTemplateColumns: ({ sm }) => `repeat(${sm}, 1fr)`,
    },
    [theme.breakpoints.up('md', 'lg')]: {
      gridTemplateColumns: ({ md }) => `repeat(${md}, 1fr)`,
    },
    [theme.breakpoints.up('lg', 'xl')]: {
      gridTemplateColumns: ({ lg }) => `repeat(${lg}, 1fr)`,
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: ({ xl }) => `repeat(${xl}, 1fr)`,
    },
  },
  textLoad: {
    fontSize: '14px',
    color: '#8C98A4',
    lineHeight: '20px',
  },
  paginationItem: {
    '& > div': {
      cursor: 'pointer',
      display: 'inline-flex',
      alignItems: 'center',
      padding: '8px',
      pointerEvents: 'all',
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: '#F2F3F4',
      },
    },
  },
  textResult: {
    marginLeft: '4px',
    marginRight: '4px',
    color: '#253746',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  text: {
    fontWeight: 500,
  },
  loadingText: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
    height: '36px',
  },
  textAll: {
    fontSize: '14px',
    color: '#8C98A4',
  },
  results: {
    padding: '6px 12px',
  },
}));

const CategoryVisualization = ({ backbone, config }: any) => {
  const { maximumChartsPerRow } = get(config, ['configuration', 'system'], {});
  const [xl, lg, md, sm, xs] = String(maximumChartsPerRow || '')
    .split(',')
    .concat('', '', '', '', '')
    .map((el) => String(el).trim())
    .reduce(
      (carry, value, index, arr) => {
        if (!value && index === 0) return carry;
        carry[index] = Number(value || carry[index - 1]) || 5;
        return carry;
      },
      [5, 5, 3, 3, 2],
    );

  const classes = useStyles({ xl, lg, md, sm, xs });
  const { isRequestFailed, loadingChart, rowData, reload, limit, onLoadMore, status } = useCategoryVisualization({
    backbone,
    config,
  });
  const tableId = useAtomValue(eTableAtom.tableId);

  const categoryRender = React.useMemo(() => {
    return rowData.map((item, index) => {
      return <CategoryItem key={index} item={item} backbone={backbone} tableId={tableId} />;
    });
  }, [rowData]);

  return (
    <Grid container spacing={3} style={{ height: '100%', marginTop: '16px', marginBottom: '16px' }}>
      {loadingChart ? (
        <Box>
          <LoadingIcon color={'#253746'} />
        </Box>
      ) : isRequestFailed ? (
        <Button variant="text" size="small" onClick={reload}>
          <Grid container>
            <Box style={{ marginRight: '4px' }}>
              <Icon type={'reload'} />
            </Box>
            <span>Reload</span>
          </Grid>
        </Button>
      ) : rowData.length === 0 ? (
        <div>
          <span>No data available.</span>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.containerContent}>{categoryRender}</div>
          {status === 'loading' ? (
            <div className={classes.loadingText}>
              <LoadingIcon /> <span className={`${classes.text} label`}>{'One moment please ...'}</span>
            </div>
          ) : null}
          {!loadingChart && status === 'success' ? (
            <div className={classes.paginationItem} onClick={onLoadMore}>
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  width={'16px'}
                  height={'16px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginRight={'4px'}
                >
                  <Icon type={'downarrow'} size={'12px'} />
                </Box>
                <span className={classes.textLoad}>Load</span>
                <span className={classes.textResult}>{limit} more</span>
              </Box>
            </div>
          ) : null}
          {!loadingChart && status === 'total' ? (
            <div className={clsx(classes.results)}>
              <span className={classes.textAll}>All:</span>
              <span className={classes.textResult}>{rowData.length} results</span>
            </div>
          ) : null}
        </div>
      )}
    </Grid>
  );
};

export default CategoryVisualization;
