import { first, get, groupBy, isEmpty, last } from 'lodash';
import React from 'react';
import clsx from 'clsx';
import moment from 'moment';
import * as uuid from 'uuid';

import { Box, Grid, makeStyles, Tooltip, withStyles } from '@material-ui/core';

import ColumnChartResponsive from '@ep/insight-ui/chartlib/column-chart';
import LineChartResponsive from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize';
import PieChart from '@ep/insight-ui/chartlib/pie-chart-v2';
import TreeMapChartResponsive from '@ep/insight-ui/chartlib/treemap-chart';
import HeatmapChartResponsive from '@ep/insight-ui/chartlib/heatmap-chart';
import { formatCurrency, formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { isFormulaField, toValue } from '@ep/insight-ui/sw/util/excel-formula';

const useStyle = makeStyles({
  container: {
    background: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  chartStyle: {
    height: (props) => (props.size ? (props.size > 100 ? `${props.size}px` : '100px') : '400px'),
    width: '100%',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  single: {
    height: (props) => (props.size ? (props.size > 100 ? `${props.size}px` : '100px') : '400px'),
    width: '100%',
    bgcolor: '#fff',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  title: {
    color: '#253746',
    fontWeight: 500,
  },
  chartBox: {
    padding: '6px',
  },
  chartBoxSingle: {
    width: '100%',
  },
  heatmapChart: {
    width: '100%',
    marginBottom: '20px',
    height: '100%',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  heatmapContainer: {
    columnGap: '20px',
    flexWrap: 'wrap',
  },
});

const BackgroundTooltip = withStyles({
  tooltip: {
    backgroundColor: '#253746',
  },
})(Tooltip);

const getTitleValue = (title, chart, currency) => {
  return title === undefined || title === null
    ? ''
    : String(title).startsWith('=')
    ? toValue(title, { ...chart, metricId: chart?.colKey, currency })
    : title?.replace(`{metric}`, chart?.metric).replace(`{total}`, chart?.total);
};

const ChartETable = ({ chartConfig, backbone, title = '', title2 = '', title3 = '' }: any) => {
  // FIXME: need to load series from real data
  const { chart: charts, config, viewByDimenionField } = chartConfig;
  const classes = useStyle({ size: config?.height });
  const divRef = React.useRef<HTMLDivElement>(null);
  const maximumChartsPerRow = config?.maximumChartsPerRow || 4;
  const [itemPerRow, setItemPerRow] = React.useState(Math.max(maximumChartsPerRow, 1));

  const finalItemPerRow = Math.min(charts.filter((chart) => !isEmpty(chart)).length, itemPerRow);

  const valueDisplay = get(config, 'display', '');

  const onUpdateSize = () => {
    if (!divRef || !divRef.current) return;
    if (!divRef.current.offsetWidth) return;
    if (divRef.current.offsetWidth < 768) {
      setItemPerRow(Math.max(Math.min(maximumChartsPerRow, 2), 1));
      return;
    }
    if (divRef.current.offsetWidth < 1100) {
      setItemPerRow(Math.max(Math.min(maximumChartsPerRow, 3), 1));
      return;
    }
    setItemPerRow(Math.max(maximumChartsPerRow, 1));
  };
  React.useLayoutEffect(() => {
    const ro = new ResizeObserver(onUpdateSize);
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [divRef.current]);

  const renderChart = () => {
    if (config && config.chartType) {
      switch (config.chartType) {
        case 'col':
        case 'waterfall':
          return (
            <Grid ref={divRef} className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const metrics = get(chart, ['data', 'rows'], []).map((i) => i['label']);
                const totals = get(chart, ['data', 'rows'], []).map((i) => i['total']);
                const chartValue = { ...chart, metrics, totals };

                const chartTitle = getTitleValue(title, chartValue, chart?.currency);
                const chartTitle2 = getTitleValue(title2, chartValue, chart?.currency) || chart?.total;
                const chartTitle3 = getTitleValue(title3, chartValue, chart?.currency);
                return (
                  <Grid
                    {...(charts.length > 1
                      ? {
                          xs: 12 / finalItemPerRow,
                          item: true,
                        }
                      : {})}
                    className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                    key={chart?.metric}
                  >
                    <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                      <ColumnChartResponsive
                        keyChart={`column_${uuid.v4()}`}
                        title={chartTitle}
                        value={chartTitle2}
                        subTitle={chartTitle3}
                        currency={chart?.currency}
                        percent={15}
                        data={chart?.data}
                        stateChart="success"
                        colorOption={config?.color}
                        isHideTotal={!chartTitle2 && !chart?.total}
                        isHidePercent={true}
                        isColumnStacked={config?.isColumnStacked}
                        sortBy={config?.sortBy}
                        isSingle={charts.length <= 1}
                        isSettingYAxis={chart?.isSettingYAxis}
                        groupPeriod={config?.groupPeriod}
                        valueDisplay={valueDisplay}
                        groupBy={config?.groupBy}
                        chartType={config.chartType}
                        colKey={chart?.colKey}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        case 'lines':
        case 'mixed':
          return (
            <Grid ref={divRef} className={classes.container} container spacing={3}>
              {charts
                .filter((chart) => !isEmpty(chart))
                .map((chart) => {
                  const metrics = get(chart, ['data', 'rows'], []).map((i) => i['label']);
                  const totals = get(chart, ['data', 'rows'], []).map((i) => i['total']);
                  const chartValue = { ...chart, metrics, totals };
                  const chartTitle = getTitleValue(title, chartValue, chart?.currency);
                  const chartTitle2 = getTitleValue(title2, chartValue, chart?.currency) || chart?.total;
                  const chartTitle3 = getTitleValue(title3, chartValue, chart?.currency);

                  return (
                    <Grid
                      {...(charts.length > 1
                        ? {
                            xs: 12 / finalItemPerRow,
                            item: true,
                          }
                        : {})}
                      className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                      key={chart?.metric}
                    >
                      <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                        <LineChartResponsive
                          keyChart={`line_${uuid.v4()}`}
                          data={chart?.data}
                          stateChart={'success'}
                          title={chartTitle}
                          value={chartTitle2}
                          subTitle={chartTitle3}
                          rawTotal={chart?.rawTotal}
                          cohortValue={chart?.cohortTotal}
                          rawCohortTotal={chart?.rawCohortTotal}
                          currency={chart?.currency}
                          percent={null}
                          colorOption={config?.color}
                          isSettingYAxis={chart?.isSettingYAxis}
                          isSingleChart={charts.length <= 1}
                          isHideTotal={isFormulaField(config.title) && !title2}
                          // isHidePercent={isFormulaField(config.title)}
                          groupPeriod={config?.groupPeriod}
                          lineTension={config?.lineTension}
                          showLegend={true}
                          valueDisplay={valueDisplay}
                          groupBy={config?.groupBy}
                          isMixed={config.chartType === 'mixed'}
                          isColumnStacked={config?.isColumnStacked}
                          colKey={chart?.colKey}
                        />
                      </Box>
                    </Grid>
                  );
                })}
            </Grid>
          );
        case 'pie':
          return (
            <Grid ref={divRef} className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const metrics = get(chart, ['data', 'rows'], []).map((i) => i['label']);
                const totals = get(chart, ['data', 'rows'], []).map((i) => i['total']);
                const chartValue = { ...chart, metrics, totals };

                const chartTitle = getTitleValue(title, chartValue, chart?.currency);
                const chartTitle2 = getTitleValue(title2, chartValue, chart?.currency) || chart?.total;
                const chartTitle3 = getTitleValue(title3, chartValue, chart?.currency);
                const labels = chart.data.rows.map((i) => i.label);
                const datasets = [
                  {
                    label: 'Labels',
                    data: chart.data.rows.map((i) => get(i, 'data.0.value')),
                    currency: '',
                    actives: labels.map((i) => true),
                    backgroundColor: config?.color.map((i) => i),
                    borderWidth: 0,
                    hoverOffset: 4,
                    formatLabel: get(chart, ['data', 'rows', '0', 'formatLabel'], ''),
                  },
                ];

                return (
                  <Grid
                    {...(charts.length > 1
                      ? {
                          xs: 12 / finalItemPerRow,
                          item: true,
                        }
                      : {})}
                    className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                    key={chart?.metric}
                  >
                    <Grid className={charts.length > 1 ? classes.chartStyle : classes.single} key={chart?.metric}>
                      <PieChart
                        keyChart={`pie_${uuid.v4()}`}
                        data={{ labels, datasets }}
                        stateChart="success"
                        title={chartTitle}
                        value={chartTitle2}
                        subTitle={chartTitle3}
                        currency={chart?.currency}
                        percent={15}
                        isHidePercent={true}
                        isHideTotal={!chartTitle2 && !chart?.total}
                        colKey={chart?.colKey}
                      />
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          );
        case 'treemap':
          return (
            <Grid className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const dataRows = get(chart.data, 'rows', []);
                const currency = get(dataRows, '[0].currency', '');
                const metrics = get(chart, ['data', 'rows'], []).map((i) => i['label']);
                const totals = get(chart, ['data', 'rows'], []).map((i) => i['total']);
                const chartValue = { ...chart, metrics, totals };

                const chartTitle = getTitleValue(title, chartValue, currency);
                const chartTitle2 = getTitleValue(title2, chartValue, currency) || chart?.total;
                const chartTitle3 = getTitleValue(title3, chartValue, currency);
                const groupedData = groupBy(dataRows, 'treeData[0].label');

                const dataTreemap = Object.entries(groupedData).map(([label, children]) => {
                  let total = 0;
                  const firstChild = first(children);
                  const aGroup = {
                    name: label || '',
                    children: children.reduce((acc, ele: any) => {
                      const childValueField = last(ele.treeData)?.key;
                      return acc.concat(
                        ele.data.map((item) => {
                          total += +item.value;
                          const data = get(item, ['eData', childValueField], {});
                          const dataMetric = get(item, ['eData', get(chart, ['colKey'], '')], {});
                          const name = data.label || data.id || data.value || '';
                          return {
                            ...item,
                            name: viewByDimenionField ? data.label || name : name,
                            value: item.value,
                            label: item.label,
                            data: { ...dataMetric, ...data },
                            showLabel: true,
                          };
                        }),
                      );
                    }, []),
                    totalValue: 0,
                    totalLabel: '',
                  };

                  aGroup.totalValue = total;
                  aGroup.totalLabel = toValue(firstChild.formatLabel, { value: total });

                  return aGroup;
                });
                return (
                  !isEmpty(chart) && (
                    <Grid
                      item
                      xs={12}
                      className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                      key={chart?.metric}
                    >
                      <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                        <TreeMapChartResponsive
                          keyChart={`treemap_${uuid.v4()}`}
                          data={dataTreemap}
                          stateChart="success"
                          currency={currency}
                          title={chartTitle}
                          value={chartTitle2}
                          subTitle={chartTitle3}
                          percent={null}
                          colKey={chart?.colKey}
                        />
                      </Box>
                    </Grid>
                  )
                );
              })}
            </Grid>
          );
        case 'heatmap':
          return (
            <Grid className={clsx(classes.container, classes.heatmapContainer)} container spacing={3}>
              {charts.map((chart) => {
                const dataRows = get(chart.data, 'rows', []);
                const currency = get(dataRows, '[0].currency', '');
                const metrics = get(chart, ['data', 'rows'], []).map((i) => i['label']);
                const totals = get(chart, ['data', 'rows'], []).map((i) => i['total']);
                const chartValue = { ...chart, metrics, totals };
                const chartTitle = getTitleValue(title, chartValue, currency);
                const chartTitle2 = getTitleValue(title2, chartValue, currency) || chart?.total;
                const chartTitle3 = getTitleValue(title3, chartValue, currency);
                const groupPeriod = backbone.getConfig('groupPeriod');
                let datetimeFormat = get(config, ['datetimeFormat'], '');
                if (String(datetimeFormat).startsWith('=')) {
                  datetimeFormat = toValue(datetimeFormat, {
                    period: groupPeriod,
                  });
                }
                const groupByLabel = groupBy(dataRows, 'label');
                const isLabelLong = dataRows.some((el) => get(el, ['header'], '').length > 16);
                const height = (isLabelLong ? 80 : 0) + Math.max(Object.keys(groupByLabel).length * 40, 150);
                return (
                  !isEmpty(chart) && (
                    <Grid className={classes.heatmapChart} key={chart.metric}>
                      <Box>
                        <HeatmapChartResponsive
                          keyChart={`heatmap_${uuid.v4()}`}
                          data={dataRows}
                          stateChart="success"
                          currency={currency}
                          title={chartTitle}
                          value={chartTitle2}
                          subTitle={chartTitle3}
                          percent={null}
                          height={height}
                          chartConfig={chartConfig}
                          backbone={backbone}
                          chart={chart}
                          colKey={chart?.colKey}
                        />
                      </Box>
                    </Grid>
                  )
                );
              })}
            </Grid>
          );
        default:
          return <></>;
      }
    }
  };

  return <>{renderChart()}</>;
};

export default ChartETable;
