/**
 * with every formula on the eTable request
 * - pick the list of formula required upstream query
 * - do the upstream query
 * - populate the result to the formula, calculate the formula
 * - split the result into the list of result
 * - push the result to the client
 */

import { first, flatten, get, groupBy, omit, uniqBy } from 'lodash';
import { nanoid } from 'nanoid';
import { buffer, delay, map, mergeMap, Observable, share, Subject } from 'rxjs';
import { eFDefault, execute } from '../util/excel-formula';

function getProxyFn(uCal) {
  const ProxyExcelFunction = new Proxy(
    {
      ...eFDefault,
      UCAL: uCal,
      UCALS: uCal,
    },
    {
      get(target, p, receiver) {
        if (!/^UCAL/.test(p.toString())) {
          return () => '1';
        }
        return Reflect.get(...arguments);
      },
    },
  );

  return ProxyExcelFunction;
}

type FormulaItem = { id: string; formula: string; context?: Record<string, any> };

export class FormulaUpstream {
  formulaRequest: Subject<FormulaItem>;
  formulaList: FormulaItem[] = [];
  params: Record<string, any>;
  originRequest: (...args: any[]) => Promise<any>;
  requestPipe: Observable<any>;
  addDone$: Subject<boolean>;
  callback: (arg: { id: string; result: any; context?: Record<string, any> }[]) => void;

  constructor(params, originRequest, callback) {
    this.formulaRequest = new Subject();
    this.params = params;
    this.originRequest = originRequest;
    this.addDone$ = new Subject();
    this.callback = callback;

    this.requestPipe = this.formulaRequest.pipe(
      map((fx) => {
        const aggs: { field: string; aggFunc: string; isGlobal: boolean }[] = [];
        execute(
          getProxyFn((isGlobal = true, field1, aggFunc1) => {
            aggs.push({ field: field1, aggFunc: aggFunc1, isGlobal });
            return '1';
          }),
          fx.formula,
          fx.context || {},
        );
        return aggs;
      }),
      buffer(this.addDone$),
      map((aggFuncs) => {
        if (aggFuncs.length === 0) return this.formulaRequest.complete();

        const flatAggFuncs1 = uniqBy(flatten(aggFuncs), (i) => [i.field, i.aggFunc, i.isGlobal].join(''));

        if (flatAggFuncs1.length === 0) return this.formulaRequest.complete();

        const flatAggFuncs = groupBy(flatAggFuncs1, (i) => (i.isGlobal == true ? 'global' : 'local'));

        return Object.entries(flatAggFuncs).reduce((carry, [key, aggFuncs]) => {
          const params = aggFuncs.reduce(
            (acc, i) => {
              acc.groupBy.aggregations.push({ field: i.field, func: i.aggFunc });
              return acc;
            },
            {
              attributes: this.params.attributes || [],
              metrics: this.params.metrics || [],
              groupAll: true,
              isSummary: true,
              groupBy: {
                dimensions: null,
                aggregations: [] as any[],
              },
              pagination: {
                page: 1,
                limit: 100,
              },
            },
          );

          return { ...carry, [key]: params };
        }, {});
      }),
      mergeMap(async (groupedParams) => {
        if (!groupedParams) return this.formulaRequest.complete();

        const resultByKey = {};
        async function doRequest(key, params, contextParams, attempt = 1, count = 1) {
          try {
            const result = await originRequest({
              ...contextParams,
              ...(params as any),
              _eip: {
                src: 'formula-up',
                retry: count + '_' + attempt,
              },
            });
            resultByKey[key] = result;
          } catch (error) {
            console.error(error);
            // retry once
            resultByKey[key] = { data: { rows: [] }, success: false };
            const nextAttempt = attempt - 1;
            if (nextAttempt > 0) {
              await doRequest(key, params, contextParams, nextAttempt, count + 1);
            }
          }
        }

        for (const [key, params] of Object.entries(groupedParams)) {
          const contextParams: any = key === 'global' ? omit(this.params, ['filter']) : this.params;
          await doRequest(key, params, contextParams, 2);
        }

        return Promise.resolve(resultByKey);
      }),
      share(),
    );

    this.requestPipe.subscribe((result: Record<string, any>) => {
      if (!result) {
        this.formulaRequest.complete();
        return;
      }
      const dResult: Record<string, any> = result;
      const context: any = Object.entries(dResult).reduce((carry, [key, result]) => {
        carry[key] = { value: first(result.data.rows), status: result.success === false ? 'fail' : 'success' };
        return carry;
      }, {});

      const failList = [];
      const fnResults = this.formulaList.map((fx) => {
        return {
          id: fx.id,
          result: execute(
            {
              ...eFDefault,
              UCAL(isGlobal = true, input: string, cal: string, r?: (k: string, isGlobal: boolean) => any) {
                let r1 = r;
                if (typeof isGlobal === 'function') {
                  r1 = isGlobal;
                }
                return r1(input, isGlobal);
              },
            },
            fx.formula,
            {
              ...get(fx, ['context', '_eTable', 'row']),
              r: (k, isGlobal = true) => {
                const contextKey = isGlobal ? 'global' : 'local';
                let { status, value } = get(context, [contextKey], { status: 'success', value: {} });
                if (status === 'fail') {
                  failList.push(fx.formula);
                  return null;
                }
                return value[k];
              },
            },
          ),
          context: fx.context,
        };
      });

      this.callback(fnResults);
      this.formulaRequest.pipe(delay(1000)).subscribe(() => {
        this.formulaRequest.complete();
      });
    });
  }

  addFormula(formula: string, context: Record<string, any> = {}) {
    const fx = { id: `_upstream_func/${nanoid()}`, formula: formula, context };
    this.formulaList.push(fx);
    this.formulaRequest.next(fx);
    return fx.id;
  }

  complete() {
    this.addDone$.next(true);
  }
}
