import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { ITag, ITagFormat } from '@ep/insight-ui/elements/table/format/tag-format';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { produce } from 'immer';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    '&.ageStart, &.ageEnd': {
      maxWidth: 64,
    },
  },
}));

type Props = {
  data: {
    value: ITagFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  onLoad: (param: { toolCodeIds: string[]; storefrontIds: string[] }) => Promise<void>;
};
const InterestCategoryEditor = (props: Props) => {
  const { data, title = '', payload, onLoad, onSubmit, anchorEl, setAnchorEl } = props;
  const classes = useStyles();

  const [values, setValues] = React.useState<string[]>([]);
  const [options, setOptions] = React.useState<OptionSelectType[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const initOptions = async () => {
    if (onLoad) {
      setLoading(true);
      const toolCode = get(payload, ['rowPicker', 'toolCode']);
      const storefrontId = get(payload, ['rowPicker', 'storefrontId']);
      const res: any = await onLoad({
        toolCodeIds: [].concat(get(data.node, ['data', toolCode])),
        storefrontIds: [].concat(get(data.node, ['data', storefrontId])),
      });
      if (res.success) {
        const arr: OptionSelectType[] = res.data.map((item) => ({
          label: item.text,
          value: item.id,
        }));
        setOptions(arr);
      } else {
        setOptions([]);
      }
      setLoading(false);
    } else {
      setOptions([]);
      // setOptions(
      //   [
      //     { id: 'Tag#1', text: 'Tag 1' },
      //     { id: 'Tag#2', text: 'Tag 2' },
      //     { id: 'Tag#3', text: 'Tag 3' },
      //     { id: 'Tag#4', text: 'Tag 4' },
      //     { id: 'Tag#5', text: 'Tag 5' },
      //   ].map((item) => ({ value: item.id, label: item.text })),
      // );
    }
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  React.useEffect(() => {
    const valueDefault: ITag[] = get(data, 'value.value', []);
    const valTemp: string[] = [];
    if (valueDefault && valueDefault.length > 0 && options.length > 0) {
      // setValues
      valueDefault.map((val) => {
        const item = options.find((item) => item.value == val.id);
        if (item) valTemp.push(item.value);
      });
    }
    setValues(valTemp);
  }, [data.value, options]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const response: any = await onSubmit({
      value: produce(data.value, (draft) => {
        // const item: OptionSelectType = options.find((m) => m.value == gender);
        const valTemp: ITag[] = [];
        if (values.length > 0 && options.length > 0) {
          values.map((val) => {
            const item = options.find((item) => item.value == val);
            if (item) valTemp.push({ id: item.value, text: item.label });
          });
        }
        (draft as any).value = JSON.stringify(valTemp);
      }),
      row: data.node.data,
    });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '466px' }}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <HeaderList
            title={title}
            variant="h4"
            className={classes.header}
            iconHead={
              loading ? (
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
      <div className={classes.dialog}>
        <div className={classes.block}>
          <div className={clsx(classes.form, 'age')}>
            <SelectMultipleForm options={options} multipleSelect searchAble chip value={values} onChange={setValues} />
          </div>
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default InterestCategoryEditor;
