import * as React from 'react';

export type ContainerResponiveType = 'eres--small' | 'eres--medium' | 'eres--large';

export const ContainerResponsiveContext = React.createContext<{
  containerClass: ContainerResponiveType;
  containerWidth?: number;
}>({
  containerClass: null,
  containerWidth: 0,
});
