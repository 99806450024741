import Text from './text';
import PageMarketerCommunity from './page-marketer-community';
import Treemap from './treemap';
import EChart from './chart';
import EChatSystem from './chat-system';
import EIntradayTable from './intraday-table';
import PageNewStorefront from './page-new-storefront';
import CampaignDetails from './campaign-details';
import Camp0Detail from './camp0-detail';
import FormUserProfile from './form/user-profile';
import FormSubmitControl from './form/submit';
import ResponsiveDemo from './responsive-demo';
import InsightChart from './insight-chart';
// import WelcomeQuestionnaire from './welcome';
// import WelcomeVideo from './welcome-video';
import EDashboard from './metric-dashboard';
import ETable from './etable';
import AreaChart from './area-chart';
import StackedAreaChart from './stacked-area-chart';
import Bullet from './bullet-chart';
import StackedColumnChart from './stacked-column-chart';
import ColumnChart from './column-chart';
import GroupColumnChart from './group-column-chart';
import DiscreteChart from './discrete-chart';
import DotChart from './dot-chart';
// import GaugeChart from './gauge-chart';
import LineChart from './line-chart';
import MixedChart from './mixed-chart';
import PieChart from './pie-chart';
import Scatter from './scatter-chart';
import Annotation from './annotation-chart';
import Legend from './legend-chart';
// import Waterfall from './waterfall-chart';
import EChartV2 from './epsilo-chart';
import EpsiloLiveDashboard from './epsilo-live-dashboard';
import HyperAutomationDetail from './hyper-automation-detail';

const blockComponents = {
  [Text.blockType]: Text,
  [Treemap.blockType]: Treemap,
  [EChart.blockType]: EChart,
  [EChatSystem.blockType]: EChatSystem,
  [EIntradayTable.blockType]: EIntradayTable,
  [PageMarketerCommunity.blockType]: PageMarketerCommunity,
  [PageNewStorefront.blockType]: PageNewStorefront,
  [CampaignDetails.blockType]: CampaignDetails,
  [FormUserProfile.blockType]: FormUserProfile,
  [ResponsiveDemo.blockType]: ResponsiveDemo,
  [InsightChart.blockType]: InsightChart,
  // [WelcomeQuestionnaire.blockType]: WelcomeQuestionnaire,
  // [WelcomeVideo.blockType]: WelcomeVideo,
  [FormSubmitControl.blockType]: FormSubmitControl,
  [EDashboard.blockType]: EDashboard,
  [ETable.blockType]: ETable,
  [AreaChart.blockType]: AreaChart,
  [StackedAreaChart.blockType]: StackedAreaChart,
  [Bullet.blockType]: Bullet,
  [StackedColumnChart.blockType]: StackedColumnChart,
  [ColumnChart.blockType]: ColumnChart,
  [GroupColumnChart.blockType]: GroupColumnChart,
  [DiscreteChart.blockType]: DiscreteChart,
  [DotChart.blockType]: DotChart,
  // [GaugeChart.blockType]: GaugeChart,
  [LineChart.blockType]: LineChart,
  [MixedChart.blockType]: MixedChart,
  [PieChart.blockType]: PieChart,
  [Scatter.blockType]: Scatter,
  [Annotation.blockType]: Annotation,
  [Legend.blockType]: Legend,
  // [Waterfall.blockType]: Waterfall,
  [EChartV2.blockType]: EChartV2,
  [EpsiloLiveDashboard.blockType]: EpsiloLiveDashboard,
  [Camp0Detail.blockType]: Camp0Detail,
  [HyperAutomationDetail.blockType]: HyperAutomationDetail,
};

export default blockComponents;
export {
  Text,
  Treemap,
  EChart,
  EChatSystem,
  EIntradayTable,
  PageMarketerCommunity,
  PageNewStorefront,
  CampaignDetails,
  ResponsiveDemo,
  InsightChart,
  // WelcomeQuestionnaire,
  // WelcomeVideo,
  FormSubmitControl,
  EDashboard,
  ETable,
  AreaChart,
  StackedAreaChart,
  Bullet,
  StackedColumnChart,
  ColumnChart,
  GroupColumnChart,
  DiscreteChart,
  DotChart,
  // GaugeChart,
  LineChart,
  MixedChart,
  PieChart,
  Scatter,
  Annotation,
  Legend,
  // Waterfall,
  EChartV2,
  EpsiloLiveDashboard,
  Camp0Detail,
  HyperAutomationDetail,
};
