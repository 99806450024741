import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CopyLinkView = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.25 5H5C3.34315 5 2 6.34315 2 8C2 9.65685 3.34315 11 5 11H6.25C6.66421 11 7 10.6642 7 10.25C7 9.83579 6.66421 9.5 6.25 9.5H6H5C4.17157 9.5 3.5 8.82843 3.5 8C3.5 7.17157 4.17157 6.5 5 6.5H6H6.25C6.66421 6.5 7 6.16421 7 5.75C7 5.33579 6.66421 5 6.25 5ZM9.75 5H11C12.6569 5 14 6.34315 14 8C14 9.65685 12.6569 11 11 11H9.75C9.33579 11 9 10.6642 9 10.25C9 9.83579 9.33579 9.5 9.75 9.5H10H11C11.8284 9.5 12.5 8.82843 12.5 8C12.5 7.17157 11.8284 6.5 11 6.5H10H9.75C9.33579 6.5 9 6.16421 9 5.75C9 5.33579 9.33579 5 9.75 5ZM5.75 7.25C5.33579 7.25 5 7.58579 5 8C5 8.41421 5.33579 8.75 5.75 8.75H10.25C10.6642 8.75 11 8.41421 11 8C11 7.58579 10.6642 7.25 10.25 7.25H5.75Z"
      fill="#C2C7CB"
    />
  </SvgIcon>
);

export default CopyLinkView;
