import Chart from 'chart.js/auto';
import { debounce, get } from 'lodash';
import React from 'react';
import clsx from 'clsx';
import Gradient from 'javascript-color-gradient';

import { HeatmapChart } from '@carbon/charts-react';
import '@carbon/charts/styles.css';

import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateCSSProperties } from '@material-ui/styles';

import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import Icon from '@ep/insight-ui/icons/Icon';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { CHART_CONFIG, PERSONALIZATION } from '@ep/insight-ui/system/helper/constant';
import { createPortal } from 'react-dom';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { DropdownCell } from '@ep/insight-ui/elements/etable2/dropdown-cell';

const log = () => {};
// console.info.bind(console, '[treemap]');

interface ScatterStyleProps {
  data: Chart.ChartData;
  options: Chart.ChartOptions;
  refElement: any;
  metric: string;
  colKey?: string;
}

const useStyles = makeStyles(() => ({
  loot: TooltipMapping.loot as CreateCSSProperties,
  wrapperChart: {
    // width: '436px',
    // height: '436px',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    "& div.bx--cc--legend[data-name='legend-items']": {
      flexWrap: 'nowrap',
      overflowX: 'auto',
      height: '100%',
      width: '100%',
      paddingBottom: '8px',
      '& .legend-item p': {
        whiteSpace: 'nowrap',
      },
      '&::-webkit-scrollbar': {
        height: '7px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#dfdfe3df',
        borderRadius: '16px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
      },
    },
    '& .bx--cc--chart-wrapper text': {
      fontSize: '14px',
      fontFamily: "Roboto, 'Helvetica Neue', Arial, sans-serif",
    },
  },
  menu: {
    '& button': {
      height: '32px',
      width: '32px',
    },
  },
}));

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const HeatmapTemplate = ({ data, options, refElement, metric, colKey }: ScatterStyleProps) => {
  const [init, setInit] = React.useState(false);
  const tryTimes = React.useRef(0);
  const chartContainerRef = React.useRef(null);
  const isModifiedRef = React.useRef(false);
  const [hoverElement, setHoverElement] = React.useState(null);
  const [focusedElement, setFocusedElement] = React.useState(null);
  const [hoverData, setHoverData] = React.useState(null);
  const hoverElementRef = React.useRef(null);

  const boundingClientRectHoverElement = React.useMemo(() => {
    return hoverElement?.target?.getBoundingClientRect();
  }, [hoverElement]);

  const backbone = React.useContext(TableBackboneContext);

  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);

  const [actions, setActions] = React.useState([]);

  React.useEffect(() => {
    hoverElementRef.current = hoverElement;
  }, [hoverElement]);

  React.useEffect(() => {
    const getActions = async () => {
      const actions = get(linkedObjects, ['actions', colKey], []);
      const customAtcs = await backbone.addon('cell.customActions2', () => [])(
        { field: colKey, actions },
        {
          data: { ...hoverData, ...get(hoverData, ['eData', colKey], {}) },
        },
      );
      setActions(customAtcs);
    };
    getActions();
  }, [colKey, hoverData, linkedObjects]);

  const classes = useStyles();
  const markInit = React.useMemo(() => {
    return debounce(() => {
      setInit(true);
    }, 100);
  }, []);

  const timesToStop = 60;
  const gradientColors = get(options, ['color', 'gradient', 'colors'], []);
  const nullColor = new Gradient()
    .setColorGradient('#ffffff', ...gradientColors.slice(0, 1))
    .setMidpoint(5)
    .getColors()[0];

  const tryToHackNullState = (chartRef) => {
    if (!chartRef.current || tryTimes.current > timesToStop || isModifiedRef.current) return;

    const heatCells = chartRef.current.querySelectorAll('rect.heat');
    if (heatCells.length === 0) {
      tryTimes.current++;
      return setTimeout(() => {
        tryToHackNullState(chartRef);
      }, getConst(['heatmap', 'waitTime'], 500));
    }

    heatCells.forEach((node) => {
      const classList = [];
      node.classList.forEach((className) => {
        classList.push(className);
      });

      const dataIndex = (classList.find((el) => el.startsWith('heat-')) || '').replace('heat-', '');
      if (dataIndex == -1) {
        node.classList.remove('null-state');
        node.style.fill = nullColor;
        return;
      }

      const valueArray = (data || []).map((el) => el.value).filter((el) => el != null);
      const min = Math.min(...valueArray);
      const max = Math.max(...valueArray);
      const gap = (max - min) / (gradientColors.length - 1);
      const { value, formatLabel } = get(data, [dataIndex], {});
      const colorIndex = Math.floor(value / gap);
      const label = toValue(formatLabel, { value });
      const { xAxis, yAxis } = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG], { xAxis: null, yAxis: null });
      const labelDisplayCondition = toValue(
        get(backbone, ['config', 'chartConfig', 'config', 'labelDisplayCondition'], '1'),
        {
          value,
          xAxis: xAxis?.value,
          yAxis: yAxis?.value,
          metric,
          colorIndex,
        },
      );
      if (labelDisplayCondition != '1') return;
      const labelColor = toValue(get(backbone, ['config', 'chartConfig', 'config', 'labelColor'], '#000000de'), {
        value,
        xAxis: xAxis?.value,
        yAxis: yAxis?.value,
        metric,
        colorIndex,
      });

      node.addEventListener('mouseover', (e) => {
        if (
          setHoverElement &&
          hoverElementRef.current?.target != e.target &&
          e.target?.tagName.toLowerCase() == 'rect'
        ) {
          setHoverElement(e);
          setHoverData(get(data, [dataIndex], {}));
        }
      });

      const text = document.createElementNS('http://www.w3.org/2000/svg', 'text');
      const { width, height } = node.getBoundingClientRect();
      text.setAttribute('x', String(width / 2));
      text.setAttribute('y', String(height / 2));
      text.style.fill = labelColor;
      text.style.pointerEvents = 'none';
      text.style.fontFamily = '"Roboto", "Helvetica", "Arial", sans-serif';
      text.style.fontWeight = '500';
      text.setAttribute('text-anchor', 'middle');
      text.setAttribute('alignment-baseline', 'central');
      text.innerHTML = label;
      node.parentNode.appendChild(text);
      if (text.getBoundingClientRect().width > width) {
        text.style.display = 'none';
      }
    });
    isModifiedRef.current = true;
  };

  React.useEffect(() => {
    window.requestAnimationFrame(() => {
      markInit();
    });

    if (!refElement.current) return;
  }, [refElement]);

  React.useEffect(() => {
    // Hack: override null color and allow user to show tooltip when hovering null piece
    tryToHackNullState(chartContainerRef);
  }, [chartContainerRef.current]);

  const memo = React.useMemo(() => {
    return <HeatmapChart ref={refElement} data={data} options={options} />;
  }, []);

  return (
    <Box className={clsx(classes.wrapperChart, 'HeatmapChart')} ref={chartContainerRef}>
      {!init ? (
        <div>Loading...</div>
      ) : !data.length ? (
        <Box display={'flex'} alignItems={'center'} sx={{ columnGap: '4px' }}>
          <Icon type={'note'} size="14px" />
          <span>No data available.</span>
        </Box>
      ) : (
        <>
          {memo}
          {focusedElement || hoverElement
            ? createPortal(
                <foreignObject x={boundingClientRectHoverElement?.width - 6 - 32} y="6" width="32" height="32">
                  <MonitorContainer
                    mId="cell-edot"
                    mLabel="Cell edot"
                    component={'div'}
                    className={clsx(classes.menu, 'dropdown_menu')}
                    role={'menubar'}
                  >
                    <DropdownCell
                      cellAction={actions}
                      props={{ value: hoverData, node: { data: hoverData } }}
                      onOpened={(e) => {
                        setFocusedElement(hoverElementRef.current);
                      }}
                      onClosed={(e) => {
                        setFocusedElement(null);
                      }}
                      showThreeDots={true}
                      setShowThreeDots={() => undefined}
                    />
                  </MonitorContainer>
                </foreignObject>,
                focusedElement?.target?.parentNode || hoverElement?.target?.parentNode,
              )
            : null}
        </>
      )}
    </Box>
  );
};
