import { SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';

const Calculate = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.6667 2H3.33333C2.6 2 2 2.6 2 3.33333V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33333C14 2.6 13.4 2 12.6667 2ZM4.66666 5.14676H7C7.27615 5.14676 7.50001 5.37062 7.50001 5.64676C7.50001 5.92291 7.27615 6.14676 7 6.14676H4.66666C4.39052 6.14676 4.16666 5.92291 4.16666 5.64676C4.16666 5.37062 4.39052 5.14676 4.66666 5.14676ZM11.4998 10.5H9.16646C8.89032 10.5 8.66646 10.7239 8.66646 11C8.66646 11.2762 8.89032 11.5 9.16647 11.5H11.4998C11.776 11.5 11.9998 11.2762 11.9998 11C11.9998 10.7239 11.776 10.5 11.4998 10.5ZM9.16646 8.83345H11.4998C11.776 8.83345 11.9998 9.05731 11.9998 9.33345C11.9998 9.60959 11.776 9.83345 11.4998 9.83345H9.16647C8.89032 9.83345 8.66646 9.60959 8.66646 9.33345C8.66646 9.05731 8.89032 8.83345 9.16646 8.83345ZM5.33334 11.5001C5.33334 11.7763 5.5572 12.0002 5.83334 12.0002C6.10948 12.0002 6.33334 11.7763 6.33334 11.5001V10.6668H7.16668C7.44282 10.6668 7.66668 10.443 7.66668 10.1668C7.66668 9.89067 7.44282 9.66681 7.16668 9.66681H6.33334V8.83347C6.33334 8.55733 6.10948 8.33347 5.83334 8.33347C5.5572 8.33347 5.33334 8.55733 5.33334 8.83347V9.66681H4.5C4.22386 9.66681 4 9.89067 4 10.1668C4 10.443 4.22386 10.6668 4.5 10.6668H5.33334V11.5001ZM9.74727 6.94605C9.55179 7.14154 9.23484 7.14154 9.03936 6.94605C8.84436 6.75106 8.8438 6.43507 9.03811 6.23939L9.62665 5.64667L9.03998 5.06C8.84484 4.86486 8.84484 4.54848 9.03998 4.35333C9.23512 4.15819 9.55151 4.15819 9.74665 4.35333L10.3333 4.94L10.92 4.35333C11.1151 4.15819 11.4315 4.15819 11.6267 4.35333C11.8218 4.54848 11.8218 4.86486 11.6267 5.06L11.04 5.64667L11.6285 6.23939C11.8228 6.43507 11.8223 6.75106 11.6273 6.94605C11.4318 7.14154 11.1148 7.14154 10.9194 6.94605L10.3333 6.36001L9.74727 6.94605Z"
      fill="#253746"
    />
  </SvgIcon>
);

export default Calculate;
