import { AD_OPTIMIZATION_CONFIG } from './ad-optimization';

const mapping = {
  cohort: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Cohort 1',
    filterField: 'cohort',
    valueGetter: {
      value: 'cohort',
    },
  },
  country: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Country',
    valueGetter: {},
  },
  marketplace: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Marketplace',
    valueGetter: {},
  },
  campaign: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Campaign',
    filterField: 'campaign.main',
    valueGetter: {
      value: 'campaign.main',
      subtext: 'campaign.sub',
    },
  },
  storefront: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Storefront',
    filterField: 'storefront',
    valueGetter: {
      value: 'storefront',
    },
  },
  adtype: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Ad type',
    valueGetter: {},
  },
  ad_tool: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Ad tool',
    filterField: 'ad_tool',
    valueGetter: {
      value: 'ad_tool',
    },
  },
  ad_object: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Ad object',
    filterField: 'ad_object',
    valueGetter: {
      value: 'ad_object',
    },
  },
  keyword: {
    cellFormat: 'text',
    propertyType: 'dimension',
    title: 'Keyword',
    filterField: 'keyword',
    valueGetter: {
      value: 'keyword',
    },
  },
  daily_budget: {
    cellFormat: 'currency',
    propertyType: 'attribute',
    title: 'Daily budget',
    filterField: 'daily_budget.main',
    valueGetter: {
      value: 'daily_budget.main',
      subtext: 'daily_budget.sub',
      typeDisplay: 'typeDisplay',
    },
    menu: [
      {
        title: 'Daily budget',
        name: 'Edit budget',
        icon: 'pencil',
        editor: 'SingleBudgetEditor',
        // payload: {
        //   field: {
        //     value: 'daily_budget.main',
        //   },
        // },
        // localUpdate: true,
      },
      {
        name: 'Set script',
        icon: 'setscript',
      },
      {
        name: 'Filter by',
        icon: 'filter',
      },
      {
        name: 'Calculate',
        icon: 'calculate',
        iconSize: '18px',
      },
    ],
  },
  keyword_bidding_price: {
    cellFormat: 'currency',
    propertyType: 'attribute',
    title: 'Keyword bidding price',
    filterField: 'keyword_bidding_price.main',
    valueGetter: {
      value: 'keyword_bidding_price.main',
      subtext: 'keyword_bidding_price.sub',
      typeDisplay: 'typeDisplay',
    },
    menu: [
      {
        title: 'Keyword bidding price',
        name: 'Change bidding price',
        icon: 'pencil',
        editor: 'BiddingPriceEditor',
      },
      {
        name: 'Set script',
        icon: 'setscript',
      },
      {
        name: 'Filter by',
        icon: 'filter',
      },
      {
        name: 'Calculate',
        icon: 'calculate',
        iconSize: '18px',
      },
    ],
  },
  keyword_status: {
    cellFormat: 'text',
    propertyType: 'attribute',
    title: 'Keyword status',
    valueGetter: {},
  },
  keyword_match_type: {
    cellFormat: 'text',
    propertyType: 'attribute',
    title: 'Keyword match type',
    valueGetter: {},
  },
  script_activation: {
    cellFormat: 'text',
    propertyType: 'attribute',
    title: 'Script activation',
    filterField: 'script_activation.main',
    valueGetter: {
      value: 'script_activation.main',
      subtext: 'script_activation.sub',
    },
  },
  storefront_sos: {
    cellFormat: 'currency',
    propertyType: 'metric',
    title: 'Storefront SoS',
    filterField: 'storefront_sos.main',
    valueGetter: {
      value: 'storefront_sos.main',
      subtext: 'storefront_sos.sub',
      typeDisplay: 'typeDisplay',
    },
  },
  suggested_bidding_price: {
    cellFormat: 'currency',
    propertyType: 'metric',
    title: 'Suggested bidding price',
    filterField: 'suggested_bidding_price.main',
    valueGetter: {
      value: 'suggested_bidding_price.main',
      subtext: 'suggested_bidding_price.sub',
      typeDisplay: 'typeDisplay',
    },
  },
  ad_spend_intrday: {
    cellFormat: 'currency',
    propertyType: 'metric',
    title: 'Ad spend(Intraday)',
    filterField: 'ad_spend_intraday.main',
    valueGetter: {
      value: 'ad_spend_intrday.main',
      subtext: 'ad_spend_intrday.sub',
      typeDisplay: 'typeDisplay',
    },
  },
  ad_spend_rr: {
    cellFormat: 'currency',
    propertyType: 'metric',
    title: 'Ad spend(RR)',
    filterField: 'ad_spend_rr.main',
    valueGetter: {
      value: 'ad_spend_rr.main',
      subtext: 'ad_spend_rr.sub',
      typeDisplay: 'typeDisplay',
    },
  },
  progress: {
    cellFormat: 'progressBar',
    propertyType: 'metric',
    title: '% Progress',
    filterField: 'progress',
    valueGetter: {
      value: 'progress',
    },
  },
  roas: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'ROAS',
    valueGetter: {},
  },
  direct_ad_gmv: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Direct ad GMV',
    valueGetter: {},
  },
  ad_gmv: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Ad GMV',
    valueGetter: {},
  },
  ad_gmv_contribution: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: '% Ad GMV contribution',
    valueGetter: {},
  },
  cost: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Cost',
    valueGetter: {},
  },
  item_sold: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Item sold',
    valueGetter: {},
  },
  direct_item_sold: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Direct item sold',
    valueGetter: {},
  },
  cpc: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'CPC',
    valueGetter: {},
  },
  click: {
    cellFormat: 'text',
    propertyType: 'traction',
    title: 'Click',
    valueGetter: {},
  },
};

export const INTRADAY_CONFIG = {
  ...AD_OPTIMIZATION_CONFIG,
  title: 'Advertising operation',
  tableType: 'intraday',
  primaryKeys: ['ad_object', 'cohort'],
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: true },
    { type: 'attribute', label: 'Attribute', allowConfig: true },
    { type: 'metric', label: 'Metric', allowConfig: true },
    { type: 'traction', label: 'Traction', allowConfig: true, selectLimit: 2 },
  ],
  groupBy: {
    columns: ['cohort'],
    aggregations: {
      cohort: {
        func: 'unique',
        column: 'cohort',
      },
    },
  },
  mapping: {
    // TODO: add cohort columns
    ...mapping,
    elasticity: {
      title: 'Elasticity',
      propertyType: 'metric',
      dataType: 'string',
      immovable: true,
      cellFormat: 'elasticity',
      valueGetter: {
        metric: 'roas',
        counterMetric: 'ads_gmv',
      },
    },
  },
  filter: [],
  disabledComponents: {
    dimension: ['keyword', 'adtype', 'country', 'marketplace', 'ad_tool', 'campaign', 'ad_object', 'storefront'],
  },
  hiddenColumn: [
    'country',
    'marketplace',
    'adtype',
    'keyword_match_type',
    'keyword_status',
    'roas',
    'direct_ad_gmv',
    'cost',
    'item_sold',
    'direct_item_sold',
    'cpc',
    'click',
    'ad_gmv_contribution',
    'ad_gmv',
  ],
  dimension: [
    'cohort',
    'country',
    'marketplace',
    'campaign',
    'storefront',
    'adtype',
    'ad_tool',
    'ad_object',
    'keyword',
  ],
  attribute: ['daily_budget', 'keyword_bidding_price', 'keyword_status', 'keyword_match_type', 'script_activation'],
  metric: ['storefront_sos', 'suggested_bidding_price', 'ad_spend_intrday', 'ad_spend_rr', 'progress', 'elasticity'],
  traction: ['roas', 'ad_gmv_contribution'],
};
