import { EIP_CONSTANT } from '@eip/next/lib/main';
import ExpansionPanel from '@ep/insight-ui/elements/expansion-panels/expansion-panel';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, Drawer, Grid, makeStyles, Tab, Tabs } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import ReactDOM from 'react-dom';
import {
  Text,
  Treemap,
  EChart,
  EChatSystem,
  EIntradayTable,
  PageMarketerCommunity,
  PageNewStorefront,
  ResponsiveDemo,
  // WelcomeQuestionnaire,
  // WelcomeVideo,
  InsightChart,
  ETable,
  EDashboard,
  AreaChart,
  StackedAreaChart,
  Bullet,
  StackedColumnChart,
  ColumnChart,
  GroupColumnChart,
  DiscreteChart,
  DotChart,
  // GaugeChart,
  LineChart,
  MixedChart,
  PieChart,
  Scatter,
  Annotation,
  Legend,
  // Waterfall,
  EChartV2,
  EpsiloLiveDashboard,
} from '../block';
import SuperBigMetric from '../images/template/super-big-metric.svg';
import Table from '../images/template/table.svg';
import Textbase from '../images/template/textbase.svg';
import {
  AreaChartImg,
  StackedAreaChartImg,
  BulletChartImg,
  StackedColumnChartImg,
  ColumnChartImg,
  GroupColumnChartImg,
  DiscreteChartImg,
  DotChartImg,
  GaugeChartImg,
  LineChartImg,
  MixedChartImg,
  PieChartImg,
  ScatterChartImg,
  AnnotationChartImg,
  WaterfallChartImg,
} from '../images/chart';

import EpsiloTemplates from './epsilo-templates';
import PageConfiguration from './page-configuration';

const SCREEN_COLUMNS = EIP_CONSTANT.SCREEN_COLUMNS;

type ChartTemplatePanelProps = {
  isExpanded: boolean;
  chartSingleConfig: any;
  onDragItemStart: (props: { chartId: string; layout?: { w: number; h: number }; advancedProperties?: any }) => void;
  onDragItemEnd: () => void;
  chartGroupConfig: (chartTypeId: string) => Record<
    string,
    {
      title?: string;
      layout?: { w: number; h: number };
      advancedProperties?: any;
    }
  >;
};

const useStyle = makeStyles((theme) => {
  const panelWidth = 262;
  return {
    bodyExpanded: {
      width: `calc(100vw + ${panelWidth}px)`,
      marginRight: `${panelWidth}px !important`,
    },
    panelPaper: {
      fontSize: `12px`,
      width: `${panelWidth}px`,
      marginTop: 58,
      zIndex: theme.zIndex.drawer + 5,
      height: `calc(100% - 58px)`,
    },
    panelModal: {
      zIndex: 21,
    },
    expansionPanel: {
      flexDirection: 'column',
    },
    editorPanel: {
      '& .card-body': {
        padding: '0 12px 12px 12px !important',
      },
      '& .configuration-form': {
        marginTop: '1rem',
      },
    },
    cardLarge: {
      '& .MuiPaper-root': {
        height: '198px',
      },
    },
    card: {
      position: 'relative',
      border: `1px solid ${colors.border.subdued}`,
      borderRadius: '12px',
      backgroundColor: 'white',
      width: '100%',
      height: 93,
      display: 'flex',
      textAlign: 'center',
      verticalAlign: 'middle',
      '& .MuiPaper-root': {
        height: '84px',
      },
      '&:hover': {
        boxShadow: 'rgb(140 152 164 / 25%) 0px 6px 12px',
      },
      '& .chart-name': {
        position: 'absolute',
        bottom: 8,
        width: '100%',
        textAlign: 'center',
        visibility: 'visible',
      },
    },
    image: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    defaultItem: {
      width: '100%',
      height: '100%',
    },
    tabRoot: {
      minWidth: 'auto',
      padding: '6px 12px !important',
    },
    tabText: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: 500,
      fontSize: '12px !important',
      textTransform: 'capitalize',
      color: '#8C98A4',
    },
  };
});

export const ChartTemplatePanel = ({
  isExpanded = true,
  onDragItemStart,
  onDragItemEnd,
  chartSingleConfig,
  chartGroupConfig,
}: ChartTemplatePanelProps) => {
  const classes = useStyle();
  const [activeTab, setActiveTab] = React.useState<'chartlib' | 'template' | 'page_configuration'>('chartlib');

  return ReactDOM.createPortal(
    <Drawer
      open={isExpanded}
      anchor={'left'}
      variant={'persistent'}
      classes={{ paper: classes.panelPaper }}
      className={clsx(classes.editorPanel)}
    >
      <div className={clsx('card card-lg sidebar-card sidebar-footer-fixed')}>
        <div className="card-body sidebar-body sidebar-scrollbar">
          <Tabs
            value={activeTab}
            onChange={(evt, tabValue) => {
              setActiveTab(tabValue);
            }}
          >
            <Tab
              label={'eWorkflow'}
              value={'chartlib'}
              classes={{
                root: classes.tabRoot,
                wrapped: classes.tabText,
              }}
            ></Tab>
            <Tab
              label={'Page Configuration'}
              value={'page_configuration'}
              classes={{
                root: classes.tabRoot,
                wrapped: classes.tabText,
              }}
            ></Tab>
          </Tabs>

          {/* Chart lib */}
          {activeTab === 'chartlib' && (
            <Box position="relative">
              <ExpansionPanel
                label="Text"
                className={classes.expansionPanel}
                classes={{
                  contentDetailsRoot: classes.expansionPanel,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: Text.blockType,
                          layout: { w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default, h: 4 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={Textbase}></img>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: ResponsiveDemo.blockType,
                          layout: { w: 2, h: 2 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      Responsive demo
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: WelcomeQuestionnaire.blockType,
                          layout: { w: 18, h: 5 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      {WelcomeQuestionnaire.label}
                    </Box>
                  </Grid> */}
                  {/* <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: WelcomeVideo.blockType,
                          layout: { w: 120, h: 20 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      {WelcomeVideo.label}
                    </Box>
                  </Grid> */}
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: InsightChart.blockType,
                          layout: { w: 120, h: 20 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      {InsightChart.label}
                    </Box>
                  </Grid>
                </Grid>
              </ExpansionPanel>
              <ExpansionPanel
                label="eDashboard"
                className={classes.expansionPanel}
                classes={{
                  contentDetailsRoot: classes.expansionPanel,
                }}
              >
                <Grid container spacing={3}>
                  {Object.entries(EDashboard.packagedConfigs).map(([configName, c]) => {
                    return (
                      <Grid item xs={6} key={configName}>
                        <Box
                          className={classes.card}
                          draggable={true}
                          onDragStart={(evt: React.DragEvent) => {
                            onDragItemStart({
                              chartId: 'eDashboard',
                              layout: EDashboard.layout,
                              advancedProperties: c,
                            });
                          }}
                          onDragEnd={onDragItemEnd}
                        >
                          <img className={classes.image} src={SuperBigMetric}></img>
                          <Typography className="chart-name" variant="body4">
                            {c.title}
                          </Typography>
                        </Box>
                      </Grid>
                    );
                  })}
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: Treemap.blockType,
                          layout: Treemap.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {Treemap.label}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EChart.blockType,
                          layout: EChart.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {EChart.label}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EChartV2.blockType,
                          layout: EChartV2.layout,
                          advancedProperties: EChartV2.packagedConfigs.default,
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {EChartV2.label}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EChartV2.blockType,
                          layout: EChartV2.layout,
                          advancedProperties: EChartV2.packagedConfigs.INSIGHT_METRIC_STOREFRONT_CONFIG,
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {EChartV2.label} - insight
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EChartV2.blockType,
                          layout: EChartV2.layout,
                          advancedProperties: EChartV2.packagedConfigs.OPERATION_INDEX_DASHBOARD_CONFIG,
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {EChartV2.label} - operation index
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EpsiloLiveDashboard.blockType,
                          layout: EpsiloLiveDashboard.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={SuperBigMetric}></img>
                      <Typography className="chart-name" variant="body4">
                        {EpsiloLiveDashboard.label}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ExpansionPanel>
              <ExpansionPanel
                label="eDatagrid"
                className={classes.expansionPanel}
                classes={{
                  contentDetailsRoot: classes.expansionPanel,
                }}
              >
                <Grid container spacing={3}>
                  {Object.entries(ETable.packagedConfigs)
                    .filter(([k, v]) => k !== 'DEFAULT_CONFIG')
                    .map(([configName, c]) => {
                      return (
                        <Grid item xs={6} key={configName}>
                          <Box
                            className={classes.card}
                            draggable={true}
                            onDragStart={(evt: React.DragEvent) => {
                              onDragItemStart({
                                chartId: 'richTable',
                                layout: ff.generate_etable_config ? ETable.layout : c.layout,
                                advancedProperties: ff.generate_etable_config ? c : c.advancedProperties,
                              });
                            }}
                            onDragEnd={onDragItemEnd}
                          >
                            <img className={classes.image} src={Table}></img>
                            <Typography className="chart-name" variant="body4">
                              {c.title}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EIntradayTable.blockType,
                          layout: EIntradayTable.layout,
                          advancedProperties: ff.generate_etable_config ? EIntradayTable.advancedProperties : {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={Table}></img>
                      <Typography className="chart-name" variant="body4">
                        {EIntradayTable.label}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ExpansionPanel>
              <ExpansionPanel
                label="eCollaboration"
                className={classes.expansionPanel}
                classes={{
                  contentDetailsRoot: classes.expansionPanel,
                }}
              >
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: EChatSystem.blockType,
                          layout: EChatSystem.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={Textbase}></img>
                      <Typography className="chart-name" variant="body4">
                        {EChatSystem.label}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: PageMarketerCommunity.blockType,
                          layout: PageMarketerCommunity.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={Textbase}></img>
                      <Typography className="chart-name" variant="body4">
                        {PageMarketerCommunity.label}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={(evt: React.DragEvent) => {
                        onDragItemStart({
                          chartId: PageNewStorefront.blockType,
                          layout: PageNewStorefront.layout,
                          advancedProperties: {},
                        });
                      }}
                      onDragEnd={onDragItemEnd}
                    >
                      <img className={classes.image} src={Textbase}></img>
                      <Typography className="chart-name" variant="body4">
                        {PageNewStorefront.label}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </ExpansionPanel>
              <ExpansionPanel label="eProperties" classes={{}}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.HYPER_AUTOMATION_DETAIL })}
                      onDragEnd={onDragItemEnd}
                    >
                      Hyper automation detail
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.CAMPAIGN_ZERO_DETAIL })}
                      onDragEnd={onDragItemEnd}
                    >
                      Campaign 0 detail
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.FORM_CAMPAIGN_INFO })}
                      onDragEnd={onDragItemEnd}
                    >
                      Campaign Properties
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.FORM_SCRIPT })}
                      onDragEnd={onDragItemEnd}
                    >
                      Script properties
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.FORM_USER_PROFILE })}
                      onDragEnd={onDragItemEnd}
                    >
                      User profile properties
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.FORM_USER_BILLING })}
                      onDragEnd={onDragItemEnd}
                    >
                      User billing properties
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() => onDragItemStart({ chartId: chartSingleConfig.SUBSCRIPTION_PLAN })}
                      onDragEnd={onDragItemEnd}
                    >
                      Subscription plan
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: chartSingleConfig.CAMPAIGN_CREATOR,
                          layout: { w: SCREEN_COLUMNS.lg.default, h: 12 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      Campaign Creator
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box
                      className={classes.card}
                      draggable={true}
                      onDragStart={() =>
                        onDragItemStart({
                          chartId: chartSingleConfig.FORM_SUBMIT_CONTROL,
                          layout: { w: SCREEN_COLUMNS.lg.default, h: 1 },
                        })
                      }
                      onDragEnd={onDragItemEnd}
                    >
                      Submit control
                    </Box>
                  </Grid>
                </Grid>
              </ExpansionPanel>
              {ff.make_all_chart && (
                <ExpansionPanel
                  label="Chartlib"
                  className={classes.expansionPanel}
                  classes={{
                    contentDetailsRoot: classes.expansionPanel,
                  }}
                >
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: AreaChart.blockType,
                            layout: AreaChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={AreaChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {AreaChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: StackedAreaChart.blockType,
                            layout: StackedAreaChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={StackedAreaChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {StackedAreaChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: Bullet.blockType,
                            layout: Bullet.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={BulletChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {Bullet.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: StackedColumnChart.blockType,
                            layout: StackedColumnChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={StackedColumnChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {StackedColumnChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: ColumnChart.blockType,
                            layout: ColumnChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={ColumnChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {ColumnChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: GroupColumnChart.blockType,
                            layout: GroupColumnChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={GroupColumnChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {GroupColumnChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: DiscreteChart.blockType,
                            layout: DiscreteChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={DiscreteChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {DiscreteChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: DotChart.blockType,
                            layout: DotChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={DotChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {DotChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: GaugeChart.blockType,
                            layout: GaugeChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={GaugeChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {GaugeChart.label}
                        </Typography>
                      </Box>
                    </Grid> */}
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: LineChart.blockType,
                            layout: LineChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={LineChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {LineChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: MixedChart.blockType,
                            layout: MixedChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={MixedChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {MixedChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: PieChart.blockType,
                            layout: PieChart.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={PieChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {PieChart.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: Scatter.blockType,
                            layout: Scatter.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={ScatterChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {Scatter.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: Annotation.blockType,
                            layout: Annotation.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={AnnotationChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {Annotation.label}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: Legend.blockType,
                            layout: Legend.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={AnnotationChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {Legend.label}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={6}>
                      <Box
                        className={classes.card}
                        draggable={true}
                        onDragStart={(evt: React.DragEvent) => {
                          onDragItemStart({
                            chartId: Waterfall.blockType,
                            layout: Waterfall.layout,
                            advancedProperties: {},
                          });
                        }}
                        onDragEnd={onDragItemEnd}
                      >
                        <img className={classes.image} src={WaterfallChartImg}></img>
                        <Typography className="chart-name" variant="body4">
                          {Waterfall.label}
                        </Typography>
                      </Box>
                    </Grid> */}
                  </Grid>
                </ExpansionPanel>
              )}
              <EpsiloTemplates classes={classes} onDragItemStart={onDragItemStart} onDragItemEnd={onDragItemEnd} />
            </Box>
          )}
          {activeTab === 'page_configuration' ? <PageConfiguration /> : null}
        </div>
      </div>
      {/* End Footer */}
    </Drawer>,
    document.getElementById('root'),
  );
};
