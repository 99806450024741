const headers = ['FEATURE.name', 'FEATURE.id', 'ACTION.name', 'ACTION.id', 'ACTION.tooltip', 'traction'];

export const ADOPTION_RATE_ACTIONS_RES = {
  data: {
    headers,
    pagination: { limit: 100, page: 1, total: -1 },
    primaryKeys: [],
    rows: [
      ['Campaign management', 1, 'Create campaign', 11, null, null],
      ['Campaign management', 1, 'Change match type', 2, null, null],
      ['Campaign management', 1, 'Open Search result, product detail page', 3, null, null],
      ['Filter', 2, 'Filter, on Summary dashboard', 4, null, null],
      ['Filter', 2, 'Filter, on any eTable', 5, null, null],
      ['Sort', 3, 'Sort, by eDot headers', 6, null, null],
      ['Sort', 3, 'Sort, on any eTable', 7, null, null],
      ['Group', 4, 'Calculate now', 8, null, null],
      ['Group', 4, 'GroupBy, on any eTable', 9, null, null],
      ['Pin', 5, 'Pin, on any eTable', 10, null, null],
    ].map((row) => {
      return row.reduce((a, b, index) => {
        a[headers[index]] = b;
        return a;
      }, {});
    }),
  },
  message: 'OK',
  code: 200,
};

export const ADOPTION_RATE_USERS_RES = {
  data: {
    headers,
    pagination: { limit: 100, page: 1, total: -1 },
    primaryKeys: [],
    rows: [
      [
        'Campaign management',
        1,
        'Active users',
        1,
        'Filtered by one of these: “Filter Button”, “Add Filter”, "Filter menu item”, “Filter By menu item”',
        null,
      ],
      ['Campaign management', 1, 'Change bidding price', 2, 'Clicked on “Create script” or “Add a script”', null],
      ['Campaign management', 1, 'Suggest bid price', 3, 'Clicked on “Apply” the filter on Summary Dashboard', null],
      ['Campaign management', 1, 'Apply suggest bid price', 4, 'Clicked on “Apply” the suggest bid price', null],
    ].map((row) => {
      return row.reduce((a, b, index) => {
        a[headers[index]] = b;
        return a;
      }, {});
    }),
  },
  message: 'OK',
  code: 200,
};

const userCohortHeader = ['COHORT.name', 'COHORT.id', 'traction'];

export const USER_COHORT_RES = {
  data: {
    headers: userCohortHeader,
    pagination: { limit: 100, page: 1, total: -1 },
    primaryKeys: [],
    rows: [
      ['Active users', 1, null],
      ['Registered users', 2, null],
      ['Visited Campaign detail users', 3, null],
      ['Hardcore users', 4, null],
      ['Visited Hyper Automation users', 5, null],
      ['Visited Script detail users', 6, null],
      ['Visited register page users', 7, null],
      ['Sign up successfully users', 8, null],
      ['Mobile active users', 9, null],
    ].map((row) => {
      return row.reduce((a, b, index) => {
        a[userCohortHeader[index]] = b;
        return a;
      }, {});
    }),
  },
  message: 'OK',
  code: 200,
};
