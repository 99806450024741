import * as React from 'react';
import { cloneDeep, get, isEqual } from 'lodash';
import moment from 'moment';

import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { useGetTableData } from '@ep/insight-ui/system/util/hooks/use-get-table-data';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import {
  CELL_FORMAT,
  COMPACT_PAGINATION_LIMIT,
  COMPACT_VALUE_GETTER,
  INHERIT_FROM_COMPACT,
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_FIELD_FILTER,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_FIELD_VALUE,
  INPUT_TYPE_METRICS,
  INPUT_TYPE_VIEW,
  MAX_WIDTH,
} from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import produce from 'immer';

export type LoadingObjType = {
  isLoading: boolean;
  message: string;
  status: 'fail' | 'success' | 'loading' | 'reloading';
  cached?: boolean;
};

// Generate me the unittest for this hook

export const useFilterSelection = ({
  defaultValues,
  filter,
  onSubmit,
  getFilterOptionValue,
  isSingle,
  isAdvancedFilter,
}: any) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const anchorElRef = React.useRef(null);
  const defaultOptions = React.useMemo(() => {
    const queryValue = get(defaultValues, [filter.id, 'queryValue'], []);
    if (!Array.isArray(queryValue)) return [];
    const queryValueLabel = String(get(defaultValues, [filter.id, 'queryValueLabel'], ''));

    return queryValue
      .filter((i) => !!i)
      .map((value, index) => ({
        value,
        label: queryValueLabel.split(',').filter((i) => !!i)[index],
        additionalInfomrations: get(defaultValues, [filter.id, 'additionalInfomrations', index], []) || [],
      }));
  }, [defaultValues]);
  const [options, setOptions] = React.useState<OptionSelectType[]>(defaultOptions);
  const [loadingObj, setLoadingObj] = React.useState<LoadingObjType>({
    isLoading: false,
    message: '',
    status: 'loading',
    cached: false,
  });
  const [selectedItems, setSelectedItems] = React.useState(() => {
    return get(defaultValues, [filter.id, 'queryValue'], []) || [];
  });
  const [searchTerm, setSearchTerm] = React.useState('');
  const [originalLabel, setOriginalLabel] = React.useState('');
  const legacySelectedItemsRef = React.useRef(get(defaultValues, [filter.id, 'queryValue'], []) || []);

  const selectedOptionsRef = React.useRef([]);
  const [selectedOptions, setSelectedOptions] = React.useState([]);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);
  const [lastUpdatedLabel, setLastUpdatedLabel] = React.useState(null);
  const filterIdRef = React.useRef(filter.id);

  const backbone = React.useContext(TableBackboneContext);
  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);

  const advancedFilter = get(linkedObjects, ['advancedFilter', filter?.id], []);

  const etableConfig = (advancedFilter.find(({ key }) => key === INPUT_TYPE_ETABLE_CONFIG) || { value: null }).value;
  const etableConfigBlockEid = get(etableConfig, ['blockEid'], '');
  const selectedMetrics = (advancedFilter.find(({ key }) => key === INPUT_TYPE_METRICS) || { value: null }).value;
  const etableConfigMapping = get(etableConfig, ['mapping'], {});
  const labelField = (advancedFilter.find(({ key }) => key === INPUT_TYPE_FIELD_LABEL) || { value: '' }).value;
  const valueField = (advancedFilter.find(({ key }) => key === INPUT_TYPE_FIELD_VALUE) || { value: '' }).value;
  const filterField = (advancedFilter.find(({ key }) => key === INPUT_TYPE_FIELD_FILTER) || { value: '' }).value;
  const paginationLimit = (advancedFilter.find(({ key }) => key === COMPACT_PAGINATION_LIMIT) || { value: '' }).value;
  const viewField = (advancedFilter.find(({ key }) => key === INPUT_TYPE_VIEW) || { value: '' }).value;

  let defaultETableConfig = {
    ...etableConfig,
    view: get(etableConfig, ['views'], []).find((i) => i?.id == viewField) || etableConfig?.view,
  };

  defaultETableConfig = produce(defaultETableConfig, (draft) => {
    Object.keys(get(defaultETableConfig, 'view.combinator', {})).forEach((key) => {
      if (key === 'properties') {
        Object.keys(defaultETableConfig.view.combinator[key]).forEach((propKey) => {
          draft[propKey] = defaultETableConfig.view.combinator[key][propKey];
        });
      } else if (key !== 'runtime') {
        draft[key] = defaultETableConfig.view.combinator[key];
      }
    });
  });

  defaultETableConfig = {
    ...defaultETableConfig,
    searchField: filterField || labelField || valueField,
    searchValueField: valueField || filterField || labelField,
    pagination: {
      limit: paginationLimit,
      page: 1,
    },
    // Only allow sort by selected metrics
    availableSortColumnKeys: (selectedMetrics || []).map((metric) => metric[COMPACT_VALUE_GETTER]),
    searchInput: searchTerm,
  };

  const hasSortButton = etableConfig?.blockEid && selectedMetrics?.length;

  const handleSetSelectedOptions = (data) => {
    setSelectedOptions(data);
    selectedOptionsRef.current = data;
  };

  React.useEffect(() => {
    if (lastUpdatedAt) {
      setLastUpdatedLabel(moment(lastUpdatedAt).fromNow());
      const interval = setInterval(() => {
        setLastUpdatedLabel(moment(lastUpdatedAt).fromNow());
      }, 1000 * 60);

      return () => {
        clearInterval(interval);
      };
    }
  }, [lastUpdatedAt]);

  React.useEffect(() => {
    if (!Array.isArray(selectedItems)) return;
    const newSelectedOptions = selectedItems.map((selectedItem) => {
      const option =
        selectedOptions.find(({ value }) => value === selectedItem.value) ||
        options.find(({ value }) => value === selectedItem);
      return (
        option || {
          value: selectedItem,
          label: selectedItem,
        }
      );
    });

    handleSetSelectedOptions(newSelectedOptions);
  }, [selectedItems]);

  const optionsObj = React.useMemo(() => {
    return options.reduce(
      (a, b) => ({
        ...a,
        [b.value]: b,
      }),
      {},
    );
  }, [options]);

  const defaultValue = React.useMemo(() => {
    return defaultValues[filter.id];
  }, [defaultValues]);

  const filteredOptions = React.useMemo(() => {
    return options.map((option, index) => ({
      ...option,
      additionalInfomrations:
        get(defaultValues, [filter.id, 'additionalInfomrations', index], null) || option?.additionalInfomrations || [],
    }));
  }, [options, filter, defaultValues]);

  const {
    getTableData,
    backbone: compactBackbone,
    searchInputRef,
    searchFieldIdRef,
    selectedItemsRef,
  } = useGetTableData({
    etableConfig: defaultETableConfig,
  });

  React.useEffect(() => {
    if (etableConfigBlockEid) {
      selectedItemsRef.current = selectedItems;
    } else {
      legacySelectedItemsRef.current = selectedItems;
    }
  }, [etableConfigBlockEid, selectedItems]);

  const handleChangeCompactSort = (model) => {
    compactBackbone.setConfig({
      ...compactBackbone.config,
      sort: model,
    });
    handleGetOptions(true);
  };

  const handleGetOptions = (forceReload = false) => {
    if (!anchorElRef.current && !isAdvancedFilter) return;
    setLoadingObj({ ...loadingObj, isLoading: true, status: forceReload ? 'reloading' : 'loading', cached: false });

    if (etableConfigBlockEid) {
      return getTableData(forceReload)
        .then((res) => {
          // Prevent messy response
          if (res.searchInput === searchInputRef.current && res.searchField === filterIdRef.current) {
            if (res.success) {
              setLoadingObj({
                ...loadingObj,
                isLoading: false,
                message: res.message,
                status: 'success',
                cached: Boolean(res.isCached),
              });
              setLastUpdatedAt(res.lastUpdated);
              const result = res.data.map((el) => {
                const label = String(labelField).startsWith('=') ? toValue(labelField, el) : el[labelField];
                const selectedCompactColumns = selectedMetrics
                  ? selectedMetrics.filter((metric) => metric[CELL_FORMAT] === INHERIT_FROM_COMPACT)
                  : [];

                const additionalInfomrations = selectedCompactColumns
                  .filter((metric) => etableConfigMapping[metric[COMPACT_VALUE_GETTER]])
                  .map((metric) => {
                    const columnKey = metric[COMPACT_VALUE_GETTER];
                    const maxColumnWidth = metric[MAX_WIDTH];
                    const valueGetter = get(el, ['eData', columnKey], {});
                    const staticValue = get(etableConfigMapping, [columnKey, 'staticValue'], {});
                    return {
                      cellFormat: get(etableConfigMapping, [columnKey, 'cellFormat']),
                      value: {
                        ...staticValue,
                        ...valueGetter,
                      },
                      maxColumnWidth,
                    };
                  });

                return {
                  label: label,
                  value: el[valueField],
                  additionalInfomrations,
                };
              });

              const updatedSelectedOptions = selectedOptionsRef.current.map((option) => {
                const updatedData = result.find(({ value }) => value == option.value);
                return updatedData ? updatedData : option;
              });
              const newOptions = result.filter(
                (el) => !selectedOptionsRef.current.some((option) => option.value == el.value),
              );
              // Prevent update selected information if user does not click reload because we never query selected item without forceReload
              if (!isEqual(updatedSelectedOptions, selectedOptionsRef.current) && forceReload) {
                // Prevent infinite loop
                handleSetSelectedOptions(updatedSelectedOptions);
                // Update label or metrics if change detected
                onSubmit({ filter, payload: updatedSelectedOptions });
              }
              setOptions(updatedSelectedOptions.concat(newOptions));
            } else {
              setLoadingObj({ ...loadingObj, isLoading: false, message: res?.message || '', status: 'fail' });
              setOptions([]);
              setLastUpdatedAt(new Date().getTime());
            }
          } else {
            setLoadingObj({
              ...loadingObj,
              isLoading: false,
              message: '',
              status: 'fail',
            });
          }
        })
        .catch((e) => {
          setLoadingObj({
            ...loadingObj,
            isLoading: false,
            message: e?.message,
            status: 'fail',
          });
          setOptions([]);
          setLastUpdatedAt(new Date().getTime());
        });
    }

    const $promise = getFilterOptionValue(
      filter.id,
      { search: searchTerm },
      forceReload,
      legacySelectedItemsRef.current,
    );

    if (!$promise) return;

    getFilterOptionValue(filter.id, { search: searchTerm }, forceReload, isSingle ? [] : legacySelectedItemsRef.current)
      .then((res) => {
        if (res.field === filterIdRef.current) {
          if (res.success) {
            setLoadingObj({ ...loadingObj, isLoading: false, message: res.message, status: 'success' });
            setOptions(selectedOptionsRef.current.concat(res.data));
          } else {
            setLoadingObj({
              ...loadingObj,
              isLoading: false,
              message: res.message ? res.message : 'Please try again.',
              status: 'fail',
            });
            setOptions([]);
          }
          setLastUpdatedAt(res.lastUpdatedAt);
        } else if (res.field == undefined) {
          if (res.success) {
            setLoadingObj({ ...loadingObj, isLoading: false, message: res.message, status: 'success' });
            setOptions(res.data);
          } else {
            setLoadingObj({
              ...loadingObj,
              isLoading: false,
              message: res.message ? res.message : 'Please try again.',
              status: 'fail',
            });
            setOptions([]);
          }
        } else {
          setLoadingObj({
            ...loadingObj,
            isLoading: false,
            message: '',
            status: 'fail',
          });
        }
      })
      .catch((e) => {
        setLoadingObj({
          ...loadingObj,
          isLoading: false,
          message: e.message,
          status: 'fail',
        });
        setOptions([]);
        setLastUpdatedAt(new Date().getTime());
      });
  };

  const handleRetry = () => {
    handleGetOptions(true);
  };

  React.useEffect(() => {
    if (etableConfigBlockEid) {
      compactBackbone.setConfig(defaultETableConfig);
    }
  }, [filter?.id, etableConfigBlockEid]);

  React.useEffect(() => {
    // Only request listing after table / chart has requested
    if (etableConfigBlockEid) {
      searchInputRef.current = searchTerm;
      searchFieldIdRef.current = filter.id;
    }
    filterIdRef.current = filter.id;
    handleGetOptions();
  }, [filter, defaultValues, searchTerm, etableConfigBlockEid]);

  const value = React.useMemo(() => {
    let label = defaultValue?.queryValueLabel;
    if (!label) {
      const option = options.find(({ value }) => value == defaultValue?.queryValue);
      if (option) {
        label = option.label;
        setOriginalLabel(label);
      } else {
        label = []
          .concat(defaultValue?.queryValue)
          .filter((i) => !!i)
          .join(',');
      }
    }
    return {
      label,
      value: defaultValue?.queryValue,
    };
  }, [defaultValue, options]);

  const handleClose = () => {
    setAnchorEl(null);
    anchorElRef.current = null;
    setSearchTerm('');
    setSelectedItems(get(defaultValues, [filter.id, 'queryValue'], []) || []);
    setLastUpdatedAt(null);
  };

  const handleSubmit = () => {
    const checkedOptions = selectedItems.map((el) => optionsObj[el]);
    const hasChanged =
      checkedOptions.length !== (value.value || []).length ||
      checkedOptions.some((option, index) => option.value !== (value.value || [])[index]);

    if (hasChanged) {
      onSubmit({ filter, payload: checkedOptions });
    }
    setSearchTerm('');
    setAnchorEl(null);
    anchorElRef.current = null;
    setLastUpdatedAt(null);
  };

  const onSingleClick = (value) => {
    const option = options.find((el) => el.value === value);
    if (option) {
      onSubmit({ filter, payload: option });
    }
    setAnchorEl(null);
  };

  React.useEffect(() => {
    setOriginalLabel('');
  }, [filter]);

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
    anchorElRef.current = event.currentTarget;
    const newSelectedItems = get(defaultValues, [filter.id, 'queryValue'], []) || [];
    legacySelectedItemsRef.current = cloneDeep(newSelectedItems);
    setSelectedItems(newSelectedItems);
    if (!Array.isArray(newSelectedItems)) {
      if (isSingle) {
        handleGetOptions();
      }
      return;
    }
    const newSelectedOptions = newSelectedItems.map((selectedItem) => {
      const option =
        selectedOptions.find(({ value }) => value === selectedItem.value) ||
        options.find(({ value }) => value === selectedItem);
      return (
        option || {
          value: selectedItem,
          label: selectedItem,
        }
      );
    });

    handleSetSelectedOptions(newSelectedOptions);
    handleGetOptions();
  };

  return {
    anchorEl,
    setAnchorEl,
    value,
    loadingObj,
    filteredOptions,
    handleGetOptions,
    handleClose,
    handleSubmit,
    setOptions,
    selectedItems,
    setSelectedItems,
    setSearchTerm,
    onSingleClick,
    originalLabel,
    handleButtonClick,
    handleRetry,
    compactBackbone,
    handleChangeCompactSort,
    hasSortButton,
    lastUpdatedLabel,
  };
};
