import {
  buildRequestParams,
  dataQuery,
} from '@eip/next/src/util/data-query';
import { produce } from 'immer';
import moment from 'moment';
import { FilterType } from '../../context';
import { NodeData } from '../../type';

const getDaysOfMonth = (dateFormat = 'DD/MM/YYYY') => {
  const days = [];
  const firstDay = moment().startOf('month');
  const daysInMonth = moment().daysInMonth();
  for (let i = 0; i < daysInMonth; i++) {
    days.push(firstDay.clone().add(i, 'days').format(dateFormat));
  }
  return days;
};

export async function requestLineChartData(
  nodeData: NodeData,
  filters: FilterType,
  requestResolveFn,
) {
  const resolveFn = (dataFrame, isChainCompleted = false) => {
    return requestResolveFn({
      dataFrame: dataFrame,
      _isAllCompleted: isChainCompleted,
    });
  };

  let dataFrame = [
    {
      status: 'loading',
      data: {
        headers: ['currency', 'amount'],
        rows: [],
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['percent'],
        rows: [],
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['chart_labels'],
        rows: getDaysOfMonth(),
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['last_month'],
        rows: [],
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['current_month'],
        rows: [],
      },
    },
    {
      status: 'loading',
      data: {
        headers: ['predict_current_month'],
        rows: [],
      },
    },
  ];

  const reqParams = buildRequestParams(nodeData, filters);
  if (reqParams.metrics.length === 0) {
    reqParams.metrics = ['gmv'];
  }

  let $chains = dataQuery({
    ...reqParams,
    dateRange: {
      from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
      to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
    },
  }).then((data) => {
    dataFrame[0].status = 'success';
    dataFrame[0].data = {
      headers: ['currency', 'amount', '__tool_id'],
      rows: [].concat(data.data.rows).map((r) => ['USD', ...r]),
    };
    resolveFn(dataFrame);
  });

  $chains = $chains.then(() => {
    return dataQuery({
      ...reqParams,
      dateRange: {
        from: moment().subtract(60, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(31, 'day').format('YYYY-MM-DD'),
      },
    }).then((data) => {
      dataFrame = produce(dataFrame, (draft) => {
        const currentVal = dataFrame[0].data.rows[0][1];
        const previousVal = data.data.rows[0][0];

        draft[1].status = 'success';
        draft[1].data = {
          headers: ['percent'],
          rows: [100 * (currentVal / previousVal - 1)],
        };
      });
      resolveFn(dataFrame);
    });
  });

  $chains = $chains.then(() => {
    return dataQuery({
      ...reqParams,
      dimensions: [...reqParams.dimensions, 'date'],
      dateRange: {
        from: moment().subtract(30, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      },
    }).then((data) => {
      dataFrame = produce(dataFrame, (draft) => {
        draft[4].status = 'success';
        draft[4].data = {
          headers: ['date', 'amount'],
          rows: data.data.rows.map((i) => ({
            date: i[0],
            value: i[1],
          })),
        };
      });
      resolveFn(dataFrame);
    });
  });

  $chains = $chains.then(() => {
    return dataQuery({
      ...reqParams,
      dimensions: [...reqParams.dimensions, 'date'],
      dateRange: {
        from: moment().subtract(60, 'day').format('YYYY-MM-DD'),
        to: moment().subtract(31, 'day').format('YYYY-MM-DD'),
      },
    }).then((data) => {
      dataFrame = produce(dataFrame, (draft) => {
        draft[2].status = 'success';
        draft[3].status = 'success';
        draft[3].data = {
          headers: ['date', 'amount'],
          rows: data.data.rows.map((i) => ({
            date: i[0],
            value: i[1],
          })),
        };
      });
      resolveFn(dataFrame, true);
    });
  });

  return dataFrame;
}
