import { eipRequest as _request, EIP_CONSTANT, useLog, aim } from '@eip/next/lib/main';
import hash from 'object-hash';
import common, { updateUserMenu } from './common';
import moment from 'moment';
import { nanoid } from 'nanoid';
import { get } from 'lodash';
import { createDataQueryFromConfig } from '@ep/insight-ui/system/backbone/table-backbone';

const { API_EIP_MANAGER_URL, API_GRPC_GATEWAY } = EIP_CONSTANT.API_HOST;
const { ServiceError } = _request;
const { getUserSettings } = aim;
// import {
//   AD_OBJECT_PROGRAMMATIC_SETTING_CONFIG,
//   PROGRAMMATIC_SETTING_CONFIG,
// } from '@ep/insight-ui/system/block/etable/table-config';

const log = useLog('data-source:programmatic-script');

const request = {
  memo: {},
  get: (url, body?: any) => {
    const cacheKey = hash(
      { url, body },
      {
        unorderedArrays: true,
      },
    );
    if (request.memo[cacheKey]) {
      return Promise.resolve(request.memo[cacheKey]);
    } else {
      request.memo[cacheKey] = _request
        .get(url, body)
        .then((res) => {
          request.memo[cacheKey] = res;
          return res;
        })
        .catch((err) => {
          request.memo[cacheKey] = null;
        });

      return request.memo[cacheKey];
    }
  },
  post: _request.post,
};

const API = {
  CREATE_SCRIPT: () => API_GRPC_GATEWAY + '/programmatic/create-script',
  UPDATE_SCRIPT: () => API_GRPC_GATEWAY + '/programmatic/update-script',
  GET_SCRIPT_TARGET_ATTRIBUTE: () => API_GRPC_GATEWAY + '/programmatic/get-list-metric',
  LOAD_SCRIPT: () => API_GRPC_GATEWAY + '/programmatic/get-list-script',
  LOAD_INTERVALS: () => API_GRPC_GATEWAY + '/programmatic/get-intervals',
  SAVE_DRAFT: () => API_EIP_MANAGER_URL + '/user/save_changeset',
};

export const getAdTool: typeof common.getAdTool = (args) =>
  common.getAdTool(args).then((items) => {
    return items.filter((i) => i.payload.marketplaceId === 'SHOPEE');
  });
export const scriptGetAdTool = common.scriptGetAdTool;
export const getAdType = common.getAdType;
export const getCountry = common.getCountry;
export const getMarketplace = common.getMarketplace;
export const getStorefront = common.getStorefront;
export const getInterval = common.getInterval;
export { updateUserMenu };

export async function getScriptTarget() {
  return new Promise((resolve, reject) => {
    resolve({
      data: [
        {
          label: 'Ad object',
          value: 'ADS_OBJECT',
        },
        {
          label: 'Ad object - Keyword',
          value: 'ADS_PLACEMENT',
        },
      ],
    });
  });
}

export const conditionOptions = {
  getAttributeOptions: async ({ storefrontId, scriptTargetId, adTypeId, adToolId }) => {
    log('getAttributeOptions', { storefrontId, scriptTargetId, adTypeId, adToolId });
    if (!storefrontId || !scriptTargetId || !adToolId) return [];

    const result = await request.get(API.GET_SCRIPT_TARGET_ATTRIBUTE(), {
      storefrontId,
      objectType: String(scriptTargetId).toUpperCase(),
      // adsType: String(adTypeId).toUpperCase(),
      adsTool: String(adToolId).toUpperCase(),
    });

    return (result.data || []).map((item) => {
      return {
        id: item.metricCode,
        text: item.metricName,
      };
    });
  },

  getTimeQueryOptions: async ({ scriptTargetId, attributeId }: RecordValue) => {
    return [
      {
        value: 'LAST',
        label: 'Last',
      },
      {
        value: 'CURRENT',
        label: 'Current',
      },
      {
        value: 'TODAY',
        label: 'Today',
      },
    ];
  },
  getTimeInclusiveOptions: async () => {
    return [
      {
        label: 'Include Today',
        value: 'INCLUDE_TODAY',
      },
      {
        label: 'Exclude today',
        value: 'EXCLUDE_TODAY',
      },
    ];
  },
  getTimeUnitOptions: async ({ scriptTargetId, attributeId }: RecordValue) => {
    return [
      {
        value: 'DAYS',
        label: 'Days',
      },
      {
        value: 'WEEKS',
        label: 'Weeks',
      },
      {
        value: 'MONTHS',
        label: 'Months',
      },
    ];
  },
  getConditionOperatorOptions: async ({ scriptTargetId, attributeId, timeQueryId, timeUnitId }: RecordValue) => {
    return [
      {
        value: 'EQUALS',
        label: '=',
      },
      {
        value: 'GREATER_THAN',
        label: '>',
      },
      {
        value: 'LESS_THAN',
        label: '<',
      },
      {
        value: 'GREATER_THAN_OR_EQUAL_TO',
        label: '>=',
      },
      {
        value: 'LESS_THAN_OR_EQUAL_TO',
        label: '<=',
      },
    ];
  },
  getActionType: async ({ actionUnit, actionObject }: RecordValue) => {
    const allOptions = [
      {
        value: 'INCREASE',
        label: 'Increase',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'DECREASE',
        label: 'Decrease',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'EDIT',
        label: 'Edit',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['ONGOING', 'PAUSED'],
      },
    ];

    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionUnit) {
        valid = i.actionUnit.indexOf(actionUnit) > -1;
      }
      if (valid && actionObject) {
        valid = i.actionObject.indexOf(actionObject) > -1;
      }
      return valid;
    });
  },
  getActionObject: async ({ actionType, actionUnit }: RecordValue) => {
    const allOptions = [
      {
        value: 'BIDDING_PRICE',
        label: 'Bidding price',
        actionType: ['INCREASE', 'DECREASE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'STATUS',
        label: 'Status',
        actionType: ['EDIT'],
        actionUnit: ['ONGOING', 'PAUSED'],
      },
    ];
    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionType) {
        valid = i.actionType.indexOf(actionType) > -1;
      }
      if (valid && actionUnit) {
        valid = i.actionUnit.indexOf(actionUnit) > -1;
      }
      return valid;
    });
  },
  getActionUnit: async ({ actionType, actionObject }: RecordValue) => {
    const allOptions = [
      {
        value: 'STEP_PERCENTAGE',
        label: 'Percentage',
        actionObject: ['BIDDING_PRICE'],
        actionType: ['INCREASE', 'DECREASE'],
      },
      {
        value: 'STEP_EXACT_VALUE',
        label: 'Exact value',
        actionObject: ['BIDDING_PRICE'],
        actionType: ['INCREASE', 'DECREASE'],
      },
      {
        value: 'STEP_ONGOING',
        label: 'Ongoing',
        actionObject: ['STATUS'],
        actionType: ['EDIT'],
      },
      {
        value: 'STEP_PAUSED',
        label: 'Paused',
        actionObject: ['STATUS'],
        actionType: ['EDIT'],
      },
    ];
    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionType) {
        valid = i.actionType.indexOf(actionType) > -1;
      }
      if (valid && actionObject) {
        valid = i.actionObject.indexOf(actionObject) > -1;
      }
      return valid;
    });
  },
};

export async function createScript(data: WorkflowSubmitDataScript, operation: 'CREATE' | 'UPDATE' = 'CREATE') {
  const post: {
    storefrontId: number;
    adsType: string;
    adsTool: string;
    objectType: string;
    objectIds: string;
    programmaticScript: any;
    scenarioCondition: any[];
  } = {
    programmaticScript: null,
    scenarioCondition: [],
    objectIds: null,
    objectType: null,
    storefrontId: null,
    adsType: null,
    adsTool: null,
  };

  function formalizedValue(val) {
    if (val === EIP_CONSTANT.ELEMENT_DISABLED_KEY_VALUE) {
      return undefined;
    }
    return val === 0 ? '0' : val;
  }

  const scenarioKey = 'screenScenario_0';
  const marketplace = data.screenInfo.marketplace;
  post.storefrontId = data.screenInfo.storefront;
  post.objectIds = data.screenInfo.programmaticObject.join(',');
  post.objectType = String(data.screenInfo.scriptTarget).toUpperCase();
  // post.adsType = String(data.screenInfo.adType).toUpperCase();
  post.adsType = undefined;
  post.adsTool = String(data.screenInfo.adTool).toUpperCase();
  post.programmaticScript = {
    scriptName: data.screenInfo.scriptName,
    scriptStatus: data.screenInfo.scriptStatus,
    scriptId: data.screenInfo.scriptId,
  };
  if (ff.script_interval) {
    post.programmaticScript.interval = data.screenInfo.interval;
  }

  post.scenarioCondition = [];
  const sceneKeys = [scenarioKey];
  for (const [index, sceneKey] of Object.entries(sceneKeys)) {
    const sceneData = data[sceneKey];
    const scenario = {
      programmaticScriptCondition: [],
      programmaticScriptScenario: {},
    };
    const condition = sceneData.condition;
    for (const cond of condition.filters) {
      scenario.programmaticScriptCondition.push({
        metricCode: formalizedValue(cond.attribute),
        rangeCode: formalizedValue(cond.timeQuery),
        timingCode: formalizedValue(cond.timeUnit),
        // logicCondition: 'LOGIC_' + String(data[scenarioKey].condition.combinator).toUpperCase(),
        customTimingCode: formalizedValue(cond.timeInclusive)
          ? formalizedValue(cond.timeInclusive).toUpperCase()
          : undefined,
        period: formalizedValue(!cond.timeQuantity ? undefined : cond.timeQuantity),
        operatorCode: formalizedValue(cond.conditionOperator),
        valueCompare: formalizedValue(cond.value),
      });
    }
    const action = sceneData.action;
    const timelineFrom = sceneData.timeline_from;
    const timelineTo = sceneData.timeline_to;

    scenario.programmaticScriptScenario = {
      scenarioName: 'Scenario #' + (Number(index) + 1),
      timelineFrom,
      timelineTo,
      logicCondition:
        'LOGIC_' + String(get(data, [scenarioKey, 'condition', 'filters', 1, 'combinator'], 'AND')).toUpperCase(),
      actionSelectionCode: String(action.type).toUpperCase(),
      actionObjectCode: String(action.object).toUpperCase(),
      stepValueCode: `${String(action.unit).toUpperCase()}`,
      stepValue: formalizedValue(action.value),
      untilReaching: formalizedValue(action.maxValue),
    };
    post.scenarioCondition.push(scenario);
  }

  log('submit post', post);

  return request.post(operation === 'UPDATE' ? API.UPDATE_SCRIPT() : API.CREATE_SCRIPT(), post).then((result) => {
    if (result.serviceResponse.code !== 200) {
      throw new ServiceError(result.serviceResponse);
    }
    return { ...result, marketplace: marketplace };
  });
}

function mapMopScriptCondition(script) {
  const sceneData = get(script, 'scenarioCondition', []);
  const scenarios: Record<string, ScriptScenarioType> = {};
  for (const [index, scene] of Object.entries(sceneData)) {
    const scenario: ScriptScenarioType = {
      timeline_from: get(scene, 'programmaticScriptScenario.timelineFrom'),
      timeline_to: get(scene, 'programmaticScriptScenario.timelineTo'),
      timezone: null,
      condition: {
        combinator: get(scene, 'programmaticScriptScenario.logicCondition', 'AND').replace('LOGIC_', '').toLowerCase(),
        filters: [],
      },
      action: {
        type: get(scene, 'programmaticScriptScenario.actionSelectionCode'),
        object: get(scene, 'programmaticScriptScenario.actionObjectCode'),
        unit: get(scene, 'programmaticScriptScenario.stepValueCode'),
        value: get(scene, 'programmaticScriptScenario.stepValue'),
        maxValue: get(scene, 'programmaticScriptScenario.untilReaching'),
      },
    };
    const serverConditions = (scene as any).programmaticScriptCondition;
    for (const cond of serverConditions) {
      scenario.condition.filters.push({
        targetObject: get(script, 'programmaticScript.objectType'),
        attribute: cond.metricCode,
        timeQuery: cond.rangeCode,
        timeUnit: cond.timingCode,
        timeInclusive: cond.customTimingCode,
        conditionOperator: String(cond.operatorCode),
        timeQuantity: cond.period,
        value: cond.valueCompare,
      });
    }
    const sKey = `screenScenario_${index}`;
    scenarios[sKey] = scenario;
  }

  return scenarios;
}

export async function getStorefrontScripts(req: { storefrontId: number | string; objectTypeId: string }) {
  const scripts = await request
    .get(API.LOAD_SCRIPT(), { storefrontId: req.storefrontId, objectType: req.objectTypeId })
    .then((res) => {
      return res.data;
    });

  const listScript = scripts.map((i) => ({
    text: get(i, 'programmaticScript.scriptName'),
    id: get(i, 'programmaticScript.scriptId'),
    payload: {
      scenarios: mapMopScriptCondition(i),
    },
  }));

  return listScript;
}

export async function loadScript(
  req: {
    entityId: string;
    objectTypeId?: string;
    storefrontId?: number | string;
    marketplace?: string;
  },
  tableConfig,
) {
  const objectTypeId = req.objectTypeId || 'ADS_OBJECT';
  const marketplace = req.marketplace || 'SHOPEE';

  let config = tableConfig.AD_OBJECT_PROGRAMMATIC_SETTING_CONFIG;

  switch (objectTypeId) {
    case 'ADS_OBJECT':
      config = tableConfig.AD_OBJECT_PROGRAMMATIC_SETTING_CONFIG;
      break;
    case 'ADS_PLACEMENT':
      config = tableConfig.PROGRAMMATIC_SETTING_CONFIG;
      break;
  }

  if (ff.load_wrong_campaign_detail) {
    config.mapping = {
      ...config.mapping,
      script_id: {
        title: 'Script id',
        propertyType: 'dimension',
        cellFormat: 'text',
        dataType: 'string',
        filterField: 'SCRIPT.id',
        valueGetter: {
          id: 'SCRIPT.id',
        },
      },
    };
    config.defaultPagination = {
      limit: -1,
    };
  }

  const { rowData, rowCount } = await createDataQueryFromConfig({
    config: {
      ...config,
      requestIgnoreField: {
        metric: ['STOREFRONT.currency'],
      },
    },
  }).query({
    filter: [
      {
        type: 'filter',
        logicalOperator: 'AND',
        field: 'SCRIPT.id',
        queryType: '=',
        queryValue: req.entityId,
        dataType: 'integer',
      },
      {
        type: 'filter',
        logicalOperator: 'AND',
        field: 'MARKETPLACE.marketplace_code',
        queryType: 'is',
        queryValue: marketplace,
        dataType: 'string',
      },
    ],
    dateRange: {
      dateFrom: moment().subtract(1, 'year').format(EIP_CONSTANT.DATE_FORMAT),
      dateTo: moment().subtract(1, 'day').format(EIP_CONSTANT.DATE_FORMAT),
    },
    pageSize: 1000,
  });

  const scriptObjectIds = rowData.map((r) =>
    objectTypeId === 'ADS_OBJECT' ? r['ADS_OBJECT.id'] : r['ADS_PLACEMENT.id'],
  );
  let scriptInterval = 30;
  const firstRow = rowData[0];
  const storefrontId = String(get(firstRow, 'STOREFRONT.id') || '');

  if (storefrontId) {
    try {
      const queryParamsObject = {
        storefrontId,
        scriptId: req.entityId,
      };
      const res = await request.get(
        API_GRPC_GATEWAY + '/programmatic/get-list-script' + '?' + new URLSearchParams(queryParamsObject).toString(),
      );
      scriptInterval = get(res, ['data', 0, 'programmaticScript', 'interval'], 30);
    } catch (e) {}
  }
  const screenInfo: any = {
    scriptName: get(firstRow, 'SCRIPT.script_name'),
    marketplace: get(firstRow, 'MARKETPLACE.marketplace_code'),
    programmaticObject: scriptObjectIds.map((i) => String(i)),
    country: get(firstRow, 'COUNTRY.country_code'),
    storefront: String(get(firstRow, 'STOREFRONT.id')),
    adType: String(get(firstRow, 'ADTYPE.ads_type')),
    adTool: String(get(firstRow, 'ADTOOL.ads_tool')),
    scriptTarget: objectTypeId,
    scriptStatus: get(firstRow, ['SCRIPT.script_status'], ''),
    scriptId: get(firstRow, ['SCRIPT.id'], ''),
    interval: scriptInterval,
  };

  const scriptPayload = await request
    .get(API.LOAD_SCRIPT(), { scriptId: req.entityId, storefrontId: screenInfo.storefront, objectType: objectTypeId })
    .then((res) => {
      const [script] = res.data;
      let scriptPayload: WorkflowSubmitDataScript = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        screenInfo: screenInfo,
      };

      const scenarios = mapMopScriptCondition(script);

      scriptPayload = {
        ...scriptPayload,
        ...scenarios,
      };

      return scriptPayload;
    });

  log('exisiting script payload', scriptPayload);

  return scriptPayload;
}

export async function createUserDraft({ parentId, entityId, blockId, templatePageId }) {
  const userId = getUserSettings().profile.userId;
  const pointer = { id: blockId, table: 'user_block' };

  return request.post(API.SAVE_DRAFT(), {
    request_id: nanoid(),
    changeset: [
      {
        pointer: pointer,
        op: 'add',
        path: [],
        value: {
          content: [templatePageId],
          type: 'user_system_link',
          id: blockId,
          version: 1,
          parent_id: parentId,
          parent_table: 'eip_system_block',
          owner_id: userId,
          updated_by: userId,
          created_at: moment().format('YYYY-MM-DD HH:mm:ss'),
          updated_at: moment().format('YYYY-MM-DD HH:mm:ss'),
          table: 'eip_block',
          payload_schema_version: 1,
          workspace_id: 'epsilo',
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['properties'],
        value: {
          title: '',
          workflowId: 'ruleDetailWorkflow',
          systemTemplatePageId: templatePageId,
        },
      },
      {
        pointer: pointer,
        op: 'add',
        path: ['entity_id'],
        value: {
          entity_id: entityId,
        },
      },
    ],
  });
}
