import { useLog } from '@eip/next/lib/log';
import { API_EIP_MANAGER_URL } from '@ep/one/global';
import { EventEmitter2 } from 'eventemitter2';
import { Drafted } from 'immer/dist/internal';
import { debounce, get } from 'lodash';
import * as React from 'react';
import { createDispatchHook, createSelectorHook } from 'react-redux';
import { actions, PageReduxStateType, persistRepo, uQueryRegex } from './page/reducers';
import { usePageServer } from './page/server-system-page';
import { useUserPageServer } from './page/server-user-page';
import { v4 } from 'uuid';
import { EIP_CONSTANT, aim, eipRequest } from '../epsilo-lib';
import { IOnToastMultiple, useToast } from '@ep/insight-ui/elements/notifications/hook';

const log = useLog('page:use-page');

export const PageContext = React.createContext(null);
const useSelector = createSelectorHook(PageContext);
const useDispatch = createDispatchHook(PageContext);

const systemPageServer = usePageServer(API_EIP_MANAGER_URL);
const userPageServer = useUserPageServer(API_EIP_MANAGER_URL);

let globalPageServer = userPageServer;

const pageEvent = new EventEmitter2();

type immuPageUpdater = (draft: Drafted<Dashboard>) => void;

const f = (field, operator, value) => {
  return {
    field,
    operator,
    value,
  };
};

const convertToFilters = (combinator = 'and') => {
  return (...args) => {
    return {
      combinator: combinator,
      filters: args,
    };
  };
};

export function usePage() {
  const {
    stateTitle,
    isInit,
    pageList,
    currentPage,
    loading,
    currentDirectory,
    systemPageId,
    pageNavigation,
    titleEditable,
    pageDescription,
    pageSocket,
    formulaTitle,
    nextPageTitle,
    pageSubtext,
    formulaPageSubtext,
    pageHashtag,
    formulaPageHashtag,
    currentPageBlockViews,
    userList,
  } = useSelector((state: PageReduxStateType) => {
    const systemPageId = get(state.currentPageBlock, 'content[0]', null);

    return {
      stateTitle: state.pageTitle,
      pageDescription: state.pageDescription?.replace(/\\n/g, '\n'),
      systemPageId: systemPageId,
      isInit: state.isInit,
      pageList: state.systemPageList,
      currentPage: state.currentPageBlock,
      loading: state.loading,
      currentDirectory: state.currentDirectory,
      pageNavigation: state.navigation,
      titleEditable: state.titleEditable,
      pageSocket: state.pageSocket,
      formulaTitle: state.formulaTitle,
      nextPageTitle: state.nextPageTitle,
      pageSubtext: state.pageSubtext,
      formulaPageSubtext: state.formulaSubtext,
      pageHashtag: state.pageHashtag,
      formulaPageHashtag: state.formulaHashtag,
      currentPageBlockViews: state.currentPageBlockViews,
      userList: state.userList,
    };
  });

  const dispatch = useDispatch();

  const updatePage = React.useCallback(
    debounce(async (page: Dashboard | immuPageUpdater, onToastMultiple?: IOnToastMultiple) => {
      return new Promise((resolve) => {
        const serverCallback = ({ page, diff, changeset, resultUpdate }) => {
          if (onToastMultiple) {
            if (resultUpdate?.isSent) {
              onToastMultiple({
                title: resultUpdate.message,
                variant: resultUpdate.variant,
                messages: [],
              });
            } else if (!diff) {
              onToastMultiple({
                title: 'Nothing different.',
                variant: 'error',
                messages: [],
              });
            } else if (diff) {
              onToastMultiple({
                title: 'Successfully published new version.',
                variant: 'success',
                messages: [],
              });
            }
          }
          resolve({ page, diff, changeset });
        };
        if (typeof page === 'function') {
          if (globalPageServer === userPageServer) {
            dispatch(
              actions.updatePersonalize({
                page: page as immuPageUpdater,
                callback: resolve,
              }),
            );
          } else if (globalPageServer === systemPageServer) {
            dispatch(
              actions.update({
                page: page as immuPageUpdater,
                callback: serverCallback,
              }),
            );
          }
        } else {
          if (globalPageServer === userPageServer) {
            dispatch(
              actions.updatePersonalize({
                page: page as Dashboard,
                callback: resolve,
              }),
            );
          } else if (globalPageServer === systemPageServer) {
            dispatch(
              actions.update({
                page: page as Dashboard,
                callback: serverCallback,
              }),
            );
          }
        }
      }).then(({ page, diff, changeset }) => {
        pageEvent.emit('update', page, diff, changeset);
        return page;
      });
    }, 700),
    [],
  );

  const updatePagePromise = React.useMemo(() => {
    let $debounced = null;
    let $defer = null;
    let tid = 0;

    return (page: Dashboard | immuPageUpdater) => {
      if (tid) window.clearTimeout(tid);
      $debounced = new Promise((resolve) => {
        $defer = resolve;
        tid = window.setTimeout(() => {
          $defer = null;
          if (typeof page === 'function') {
            dispatch(
              actions.update({
                page: page as immuPageUpdater,
                callback: resolve,
              }),
            );
          } else {
            dispatch(
              actions.update({
                page: page as Dashboard,
                callback: resolve,
              }),
            );
          }
        }, 700);
      });
      return $debounced.then(({ page, diff, changeset }) => {
        if ($defer) $defer({ page, diff, changeset });
        return { page, diff, changeset };
      });
    };
  }, []);
  const funRef = React.useRef({
    toggleTitleEditable: (editable) => {
      dispatch(actions.togglePageTitleEditable({ editable }));
    },
    updateTitle: (newTitle, ts?) => {
      dispatch(actions.updateTitle({ title: newTitle }));
    },
    updateHashtag: (newHashtag, ts?) => {
      dispatch(actions.updateHashtag({ hashtag: newHashtag }));
    },
    updateSubtext: (newSubtext, ts?) => {
      dispatch(actions.updateSubtext({ subtext: newSubtext }));
    },
    updateEditorTitle: (newTitle, ts?) => {
      dispatch(actions.updateEditorTitle({ title: newTitle }));
    },
    getPageServer: () => {
      return { systemPageServer, userPageServer, globalPageServer };
    },
    resetPageServer: () => {
      globalPageServer = userPageServer;
    },
    setPageServer: (pageServer) => {
      globalPageServer = pageServer;
    },
    setCurrentPage: (pageId) => {
      log('setcurrentpage', pageId);
      dispatch(actions.setCurrentPage(pageId));
    },
    loadPageList: () => dispatch(actions.getList()),
    loadMenuList: (pageIds) => dispatch(actions.getMenuList({ pageIds })),
    loadUserList: () => dispatch(actions.getUserList()),
    updatePage: updatePage,
    updatePagePromise: updatePagePromise,
    loadSinglePage: (pageId, context?: any) => dispatch(actions.loadSinglePage({ pageId, context })),
    loadSinglePagePromise: (pageId) => {
      return new Promise((resolve) => {
        dispatch(
          actions.loadSinglePage({
            pageId,
            callback: ({ pageId, result }) => {
              resolve({ pageId, result });
            },
          }),
        );
      });
    },
    addStaticPage: (pageId, pageContent) => dispatch(actions.addStaticPage({ pageId, pageContent })),
    loadSingleSystemPage: (pageId, context?: any) => dispatch(actions.loadSingleSystemPage({ pageId })),
    getLoadingStatus: (key): { status: boolean; message?: string } => {
      return get(loading, key, { status: true });
    },
    loadBuiltinEntities: () => {
      dispatch(actions.loadBuiltinEntities());
    },
    loadPageNavigation: (pageId) => dispatch(actions.loadPageNavigation({ pageId })),
    updatePageNavigation: ({ pageId, parentId, nextParentId, listBeforeId, listAfterId }) => {
      window.setTimeout(
        dispatch(
          actions.updatePageNavigation({
            pageId,
            parentId,
            nextParentId,
            listBeforeId,
            listAfterId,
          }),
        ),
        100,
      );
    },

    onPageEvent: (eventName: string, callback: (...args: any[]) => void) => {
      pageEvent.addListener(eventName, callback);
      return () => {
        pageEvent.removeListener(eventName, callback);
      };
    },
    isDraft: (entityId: string | number) => String(entityId).includes('draft') || String(entityId).includes('default'),
    createSystemPage(title, groupId, callback?) {
      const blockId = v4();
      const { profile } = aim.getUserSettings();
      return systemPageServer
        .update([
          {
            op: 'add',
            pointer: {
              id: blockId,
              table: 'eip_system_block',
            },
            path: [],
            value: {
              title: title,
              table: 'eip_system_block',
              version: 1,
              id: blockId,
              type: 'page',
              content: [],
              layouts: {
                md: [],
                lg: [],
              },
              nodes: [],
              parent_id: '__parent_id__',
              parent_table: 'space',
              owner_id: profile.userId,
              properties: {
                title: title,
                groupId,
              },
            },
          },
          {
            op: 'add',
            pointer: {
              id: blockId,
              table: 'eip_system_block',
            },
            path: ['properties'],
            value: {
              title: title,
              groupId,
              accessBy: ['*@epsilo.io'],
            },
          },
        ])
        .then((res) => {
          if (callback) {
            callback(blockId);
          }
          return res;
        })
        .then(() => {
          window.location.href = `/page/${blockId}/edit`;
        });
    },
    clonePage(pageId, cloneId, groupId = 'botep') {
      return systemPageServer.clone(pageId, cloneId, groupId);
    },
    removePage(pageId) {
      return systemPageServer.remove(pageId);
    },
    updatePageSocket(pageSocket) {
      return dispatch(actions.updatePageSocket(pageSocket));
    },
    loadFormulaTitle(title, pageHashtag, pageSubtext) {
      return dispatch(actions.loadFormulaTitle({ title, pageHashtag, pageSubtext }));
    },
    refreshCaches(pageId) {
      return dispatch(actions.refreshCaches({ pageId }));
    },
    async loadPageBlockViews(pageId, forceReload) {
      const response = await globalPageServer.getPageBlockViews(pageId, forceReload);
      dispatch(actions.loadPageBlockViews({ result: { payload: response }, pageId }));

      return response;
    },
  });

  const getLoadingStatus = React.useCallback(
    (key): { status: boolean; message?: string } => {
      return get(loading, key, { status: true });
    },
    [loading],
  );

  const getPageSections = (pageId, forceReload) => {
    return globalPageServer.getPageSections(pageId, forceReload);
  };

  return {
    isInit,
    pageList,
    currentPage,
    actions,
    globalPageServer,
    title: stateTitle,
    description: pageDescription,
    currentDirectory,
    systemPageId,
    pageNavigation,
    titleEditable,
    //
    ...funRef.current,
    getLoadingStatus,
    pageSocket,
    formulaTitle,
    nextPageTitle,
    pageSubtext,
    formulaPageSubtext,
    pageHashtag,
    formulaPageHashtag,
    currentPageBlockViews,
    getPageSections,
    userList,
  };
}

persistRepo.registerRepo({
  request: async ({ type, payload }) => {
    switch (type) {
      case 'update': {
        log('globalpageserver', globalPageServer);
        return globalPageServer.update(payload.changeset, payload.pageId).then((rs) => ({ payload: rs }));
        break;
      }
      case 'getSinglePage': {
        return globalPageServer.getSingle(payload.pageId, payload.isEditMode).then((rs) => {
          return { payload: rs };
        });
        break;
      }
      case 'getPageNavigation': {
        return globalPageServer.getPageNavigation(payload.pageId).then((rs) => {
          return { payload: rs };
        });
      }
      case 'refreshCaches': {
        if (globalPageServer.refreshCaches) {
          return globalPageServer.refreshCaches(payload.pageId);
        }
        return undefined;
      }
      default: {
        log(new Error('missing action ' + type));
      }
    }
  },
});
