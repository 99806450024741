import React, { Component } from 'react';
import { Provider, useAtomValue } from 'jotai';
import { BlockMonitor, BlockMonitorTrace } from '../../backbone/atom/monitor';
import { useLog } from '@ep/insight-ui/sw/log';

type Props<C extends React.ElementType> = React.ComponentPropsWithRef<C> & {
  mId?: string;
  mLabel?: string;
  children?: JSX.Element | JSX.Element[];
  component: C;
};

const log = useLog('MonitorContainer');

export const scope = Symbol('MontitorContainer');

export const MonitorContainer = React.forwardRef(function MonitorContainer<C extends React.ElementType = 'div'>(
  { mId: id, mLabel: label, children, component: TheComponent = 'div', ...props }: Props<C>,
  ref,
) {
  const blockMonitor = useAtomValue(BlockMonitor, scope);
  const [containerDom, setContainerDom] = React.useState<HTMLElement>(null);

  const track = React.useCallback(
    (targetLabel, payload: Record<string, string | number>, el) => {
      log.info('track', { sourceLabel: targetLabel, payload, blockMonitor });
      let sectionLabel = label;
      if (typeof label !== 'string') {
        sectionLabel = '(element)';
      } else if (label == '') {
        sectionLabel = '(no label)';
      }

      const idPath = [].concat(blockMonitor.id, id);
      const labelPath = [].concat(blockMonitor.label, sectionLabel);

      const context = {
        sectionId: JSON.stringify([].concat(blockMonitor.id, id)),
        sectionLabel: JSON.stringify([].concat(blockMonitor.label, sectionLabel)),
        sectionIdRaw: idPath,
        sectionLabelRaw: labelPath,
        targetDepth: blockMonitor.id.length + 1,
        targetLabel: targetLabel == '' ? '(no label)' : targetLabel,
      };

      const finPayload = window._epProduceTracePayload ? window._epProduceTracePayload(payload, context, el) : payload;
      log.info('span payload', {
        payload,
        context,
        el,
      });

      if (finPayload.isIgnore === true) {
        log.info('ignore span', { finPayload, payload, context, el });
        return;
      }

      const span = window.epGetTracer().startSpan(`click_section ${sectionLabel}`, {
        attributes: {
          ...finPayload,
          sectionId: JSON.stringify([].concat(blockMonitor.id, id)),
          sectionLabel: JSON.stringify([].concat(blockMonitor.label, sectionLabel)),
          targetDepth: blockMonitor.id.length + 1,
          targetLabel: targetLabel == '' ? '(no label)' : targetLabel,
        },
      });

      span.end();
    },
    [id, label, blockMonitor],
  );

  React.useEffect(() => {
    if (!containerDom) {
      return;
    }

    function trackClickEvent(event: MouseEvent) {
      const el = event.target as HTMLElement;
      const elMonitorId = el.getAttribute('data-mid');
      const handledTs = el.getAttribute('data-ep-monitor');

      const trackLabel = elMonitorId ? elMonitorId : el.innerText || el.getAttribute('aria-label');
      el.setAttribute('data-ep-monitor', String(event.timeStamp));

      const payload = {
        httpUrl: window.location.href,
        httpUserAgent: window.navigator.userAgent,
        action: 'click',
        tagName: el.tagName,
        targetType: el.getAttribute('type'),
        eventTs: String(event.timeStamp),
      };

      Object.values(el.attributes).forEach(({ name }) => {
        if (String(name).startsWith('trace-')) {
          payload[
            name
              .split('-')
              .map((el, index) => (index == 0 ? el : el[0].toUpperCase() + el.slice(1)))
              .join('')
          ] = el.getAttribute(name);
        }
      });

      track(trackLabel, payload, el);
    }

    containerDom.addEventListener('click', trackClickEvent);

    return () => {
      containerDom.removeEventListener('click', trackClickEvent);
    };
  }, [track, containerDom]);

  return (
    <Provider
      initialValues={[
        [
          BlockMonitor,
          {
            id: [].concat(blockMonitor.id, id),
            label: [].concat(
              blockMonitor.label,
              typeof label !== 'string' ? '(element)' : label == '' ? '(no label)' : label,
            ),
          },
        ],
        [BlockMonitorTrace, { track }],
      ]}
      scope={scope}
    >
      <TheComponent
        {...props}
        ref={(domRef) => {
          if (ref) {
            ref.current = domRef;
          }
          setContainerDom(domRef);
        }}
      >
        {children}
      </TheComponent>
    </Provider>
  );
});
