import * as React from 'react';
import { makeStyles, Link, Breadcrumbs } from '@material-ui/core';
import Typography from '../text-style/Typography';
import RichPrompt from '../rich-prompt';

const useStyles = makeStyles((theme) => ({
  textSecondary: {
    fontSize: '14px',
    lineHeight: '20px',
    fontStyle: 'normal',
    color: '#253746',
    fontWeight: 400,
    marginLeft: '0px',
    width: '100%',
    whiteSpace: 'nowrap',
    letterSpacing: '0.006em',
    backgroundColor: 'transparent',
    padding: '2px 4px;',
    borderRadius: '4px',
  },
  preRoute: {
    '& p.eip1-MuiTypography-root': {
      color: '#8C98A4',
    },
    '& .eip1-MuiBreadcrumbs-separator': {
      marginLeft: '6px',
      marginRight: '6px',
    },
    cursor: 'pointer',
  },
}));

const chartRegex = new RegExp(`chartQuery\\(([\\'\\"]).*?\\1\\s*\\,\\s*([\\'\\"]).*?\\2\\s*\\)`, 'g');
const uQueryRegex = new RegExp(`uQuery\\(([\\'\\"]).*?\\1\\s*\\,\\s*([\\'\\"]).*?\\2\\s*\\)`, 'g');

export type TypeBreadcrumbs = {
  label: string;
  path: string;
  dissable?: boolean;
};
type Props = {
  routes: Array<TypeBreadcrumbs>;
};
export const BreadcrumbsComponent = ({ routes }: Props) => {
  const classes = useStyles();
  return (
    <Breadcrumbs style={{ marginTop: 1 }}>
      {routes &&
        routes.length > 0 &&
        routes.map((route: TypeBreadcrumbs, index: number) =>
          index !== routes.length - 1 ? (
            <Link key={`Breadcrumbs_${index}`} color={'inherit'} href={route.path} className={`${classes.preRoute}`}>
              <Typography variant={'body4'} className={`${classes.textSecondary}`}>
                {route.label}
              </Typography>
            </Link>
          ) : (
            <Typography
              key={`Breadcrumbs_${index}`}
              variant={'body4'}
              className={`${classes.textSecondary} t_page_name`}
            >
              {route.label?.startsWith('=') && (uQueryRegex.test(route.label) || chartRegex.test(route.label)) ? (
                <RichPrompt text={route.label.replace(chartRegex, '""')} />
              ) : (
                route.label
              )}
            </Typography>
          ),
        )}
    </Breadcrumbs>
  );
};
