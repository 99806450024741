import { aim, EIP_CONSTANT } from '@eip/next/lib/main';
import { request } from '../data-source/common';
import { get } from 'lodash';
import { useLog } from '@eip/next/lib/main';
import { safeJsonParse } from '../../util/safe-json-parse';

const log = useLog('lib:workflow-backbone:hyper-automation');

const API = {
  GET_MARKETPLACE_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-marketplace-allocation',
  GET_COUNTRY_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-country-allocation',
  GET_STOREFRONT_URL: EIP_CONSTANT.API_HOST.API_PASSPORT_URL + '/api/auth/user/collect-storefront-allocation',
  GET_SCRIPT_TARGET_ATTRIBUTE: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/ads-unit/collect-attribute',
  GET_SCRIPT_TARGET_METRIC: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/ads-unit/collect-metric',
  GET_EXISTING_SCRIPTS: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/storefront/collect-existed-script',
  GET_AD_TOOL: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/storefront/collect-ad-tool',
  GET_REPEATING: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/programmatic-script/collect-repeating',
  SAVE_SCRIPT: EIP_CONSTANT.API_HOST.API_HYPER_AUTOMATION + '/api/auth/programmatic-script/save',
};

const mappingFields = {
  code: 'value',
  name: 'label',
  storefront_name: 'label',
  storefront_id: 'value',
};

const transformData = (data) => {
  return data.map((el) => {
    const mappedData = Object.entries(mappingFields).reduce((carry, [key, mappedKey]) => {
      return {
        ...carry,
        [mappedKey]: el[key],
      };
    }, {});

    return {
      ...el,
      ...mappedData,
    };
  });
};

const getMarketplace = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.get(API.GET_MARKETPLACE_URL);
      resolve({
        data: transformData(response.data || []),
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getCountry = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.get(API.GET_COUNTRY_URL);
      resolve({
        data: transformData(response.data || []),
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getStorefront = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_STOREFRONT_URL, { newValues: {} });
      const transformedData = transformData(response.data || []).map((dt) => {
        const payload = {
          country: get(dt, ['country'], {}),
          channel: {
            ...get(dt, ['channel'], {}),
            id: get(dt, ['channel', 'code'], {}),
          },
        };
        return {
          ...dt,
          value: String(dt.value),
          label: `${payload.channel?.name} / ${payload.country?.name} / ${String(dt.label)}`,
          payload,
        };
      });
      resolve({
        data: transformedData,
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getAdType = async () => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

const getAdTool = async ({ storefront }) => {
  if (!storefront) {
    return Promise.resolve({
      data: [],
      message: 'success',
      success: true,
    });
  }

  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_AD_TOOL, {
        newValues: {
          storefront_id: storefront,
        },
      });
      const transformedData = transformData(response.data || []).map((dt) => {
        return {
          ...dt,
          value: String(dt.tool_code),
          label: String(dt.tool_name),
        };
      });
      resolve({
        data: transformedData,
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

const getStatus = async (arg) => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

const getInterval = () => {
  return new Promise((resolve) => {
    resolve({
      data: [],
      message: 'success',
      success: true,
    });
  });
};

const getRepeating = async () => {
  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_REPEATING, {});
      const data = response.data.map((i) => {
        return {
          label: i.name,
          value: i.code,
          payload: i,
        };
      });
      resolve({
        data,
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
};

export async function loadScript({ entityId }) {
  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_EXISTING_SCRIPTS, {
        newValues: {
          scriptId: entityId,
        },
      });

      const scriptDetail = get(response, ['data', 0], {}) || {};
      const scenario = get(scriptDetail, ['scenario', 0], {});
      const condition = get(scriptDetail, ['condition'], []);

      const mappedScriptDetail = {
        screenInfo: {
          adTool: scriptDetail.tool_code,
          adType: scriptDetail.object_type,
          country: scriptDetail.country_code,
          interval: scriptDetail.interval,
          marketplace: scriptDetail.marketplace_code,
          programmaticObject: get(scriptDetail, ['object'], []).map((i) => i.object_id),
          scriptId: scriptDetail.script_id,
          scriptName: scriptDetail.script_name,
          scriptStatus: scriptDetail.script_status,
          scriptTarget: scriptDetail.ads_unit,
          storefront: scriptDetail.storefront_id,
        },
        screenScenario_0: {
          repeatingAt: scenario.repeating_at ? safeJsonParse(scenario.repeating_at) : null,
          repeating: scenario.repeating_code,
          repeatingOn: scenario.repeating_on ? safeJsonParse(scenario.repeating_on) : null,
          timeRange: {
            dateFrom: scenario.timeline_from,
            dateTo: scenario.timeline_to,
          },
          action: {
            maxValue: scenario.until_reaching,
            object: scenario.action_object_code,
            type: scenario.action_selection_code,
            unit: scenario.step_value_code,
            value: scenario.step_value,
          },
          condition: {
            combinator: 'and',
            filters: condition.map((i) => {
              return {
                attribute: i.metric_code,
                conditionOperator: i.operator_key,
                timeInclusive: i.custom_timing_code,
                timeQuantity: i.period,
                timeQuery: i.range_code,
                timeUnit: i.timing_code,
                value: JSON.parse(i.data_value)?.value_compare,
                targetObject: scriptDetail.ads_unit,
              };
            }),
          },
          timeline_from: scenario.timeline_from,
          timeline_to: scenario.timeline_to,
        },
      };

      resolve(mappedScriptDetail);
    } catch (e) {
      resolve({
        data: {},
        message: e.message,
        success: false,
      });
    }
  });
}
export const conditionOptions = {
  getAttributeOptions: async ({ adsUnit, marketplace, adTool }) => {
    log('getAttributeOptions', { adsUnit, marketplace, adTool });
    if (!adsUnit || !marketplace || !adTool) return [];

    return new Promise(async (resolve) => {
      try {
        const response = await request.post(API.GET_SCRIPT_TARGET_METRIC, {
          newValues: {
            ads_unit: adsUnit,
            marketplace_code: marketplace,
            tool_code: adTool,
          },
        });

        const result = (response.data || []).map((item) => {
          return {
            id: String(item.field_name).toUpperCase(),
            text: item.label,
          };
        });

        resolve(result);
      } catch (e) {
        resolve([]);
      }
    });
  },

  getTimeQueryOptions: async ({ scriptTargetId, attributeId }: RecordValue) => {
    return [
      {
        value: 'LAST',
        label: 'Last',
      },
      {
        value: 'CURRENT',
        label: 'Current',
      },
      {
        value: 'TODAY',
        label: 'Today',
      },
    ];
  },
  getTimeInclusiveOptions: async () => {
    return [
      {
        label: 'Include Today',
        value: 'INCLUDE_TODAY',
      },
      {
        label: 'Exclude today',
        value: 'EXCLUDE_TODAY',
      },
    ];
  },
  getTimeUnitOptions: async ({ scriptTargetId, attributeId }: RecordValue) => {
    return [
      {
        value: 'DAYS',
        label: 'Days',
      },
      {
        value: 'WEEKS',
        label: 'Weeks',
      },
      {
        value: 'MONTHS',
        label: 'Months',
      },
    ];
  },
  getConditionOperatorOptions: async ({ scriptTargetId, attributeId, timeQueryId, timeUnitId }: RecordValue) => {
    return [
      {
        value: 'EQUALS',
        label: '=',
      },
      {
        value: 'GREATER_THAN',
        label: '>',
      },
      {
        value: 'LESS_THAN',
        label: '<',
      },
      {
        value: 'GREATER_THAN_OR_EQUAL_TO',
        label: '>=',
      },
      {
        value: 'LESS_THAN_OR_EQUAL_TO',
        label: '<=',
      },
    ];
  },
  getActionType: async ({ actionUnit, actionObject }: RecordValue) => {
    const allOptions = [
      {
        value: 'INCREASE',
        label: 'Increase',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'DECREASE',
        label: 'Decrease',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'EDIT',
        label: 'Edit',
        actionObject: ['BIDDING_PRICE'],
        actionUnit: ['ONGOING', 'PAUSED'],
      },
    ];

    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionUnit) {
        valid = i.actionUnit.indexOf(actionUnit) > -1;
      }
      if (valid && actionObject) {
        valid = i.actionObject.indexOf(actionObject) > -1;
      }
      return valid;
    });
  },
  getActionObject: ({ adTool, marketplace, adsUnit, actionType, actionUnit }: RecordValue) => {
    const allOptions = [
      {
        value: 'BIDDING_PRICE',
        label: 'Bidding price',
        actionType: ['INCREASE', 'DECREASE'],
        actionUnit: ['PERCENTAGE', 'EXACT_VALUE'],
      },
      {
        value: 'STATUS',
        label: 'Status',
        actionType: ['EDIT'],
        actionUnit: ['ONGOING', 'PAUSED'],
      },
    ];
    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionType) {
        valid = i.actionType.indexOf(actionType) > -1;
      }
      if (valid && actionUnit) {
        valid = i.actionUnit.indexOf(actionUnit) > -1;
      }
      return valid;
    });
    return new Promise(async (resolve) => {
      if (!adTool || !marketplace || !adsUnit) {
        return resolve({
          data: [],
          message: '',
          success: false,
        });
      }
      try {
        const response = await request.post(API.GET_SCRIPT_TARGET_ATTRIBUTE, {
          newValues: {
            tool_code: adTool,
            marketplace_code: marketplace,
            ads_unit: adsUnit,
          },
        });
        const transformedData = transformData(response.data || []).map((dt) => {
          return {
            ...dt,
            value: String(dt.field_name),
            label: String(dt.label),
          };
        });
        resolve({
          data: transformedData,
          message: response.message || [],
          success: response.success,
        });
      } catch (e) {
        resolve({
          data: [],
          message: e.message,
          success: false,
        });
      }
    });
  },
  getActionUnit: async ({ actionType, actionObject }: RecordValue) => {
    const allOptions = [
      {
        value: 'STEP_PERCENTAGE',
        label: 'Percentage',
        actionObject: ['BIDDING_PRICE'],
        actionType: ['INCREASE', 'DECREASE'],
      },
      {
        value: 'STEP_EXACT_VALUE',
        label: 'Exact value',
        actionObject: ['BIDDING_PRICE'],
        actionType: ['INCREASE', 'DECREASE'],
      },
      {
        value: 'ONGOING',
        label: 'Ongoing',
        actionObject: ['STATUS'],
        actionType: ['EDIT'],
      },
      {
        value: 'SUSPENDED',
        label: 'Paused',
        actionObject: ['STATUS'],
        actionType: ['EDIT'],
      },
    ];
    return allOptions.filter((i) => {
      let valid = true;
      if (valid && actionType) {
        valid = i.actionType.indexOf(actionType) > -1;
      }
      if (valid && actionObject) {
        valid = i.actionObject.indexOf(actionObject) > -1;
      }
      return valid;
    });
  },
};

export async function getScriptTarget() {
  return new Promise((resolve, reject) => {
    resolve({
      data: [
        {
          label: 'Ad object',
          value: 'AdsObject',
        },
        {
          label: 'Ad object - Keyword',
          value: 'AdsPlacement',
        },
      ],
    });
  });
}

export async function getStorefrontScripts(req: { storefront: number | string }) {
  if (!req?.storefront) {
    return Promise.resolve({
      data: [],
      message: 'success',
      success: true,
    });
  }

  return new Promise(async (resolve) => {
    try {
      const response = await request.post(API.GET_EXISTING_SCRIPTS, {
        newValues: {
          storefront_id: req?.storefront,
        },
      });
      const transformedData = transformData(response.data || []).map((dt) => {
        return {
          ...dt,
          value: String(dt.script_id),
          label: String(dt.script_name),
        };
      });
      resolve({
        data: transformedData,
        message: response.message || [],
        success: response.success,
      });
    } catch (e) {
      resolve({
        data: [],
        message: e.message,
        success: false,
      });
    }
  });
}

export async function createScript(payload) {
  return request.post(API.SAVE_SCRIPT, {
    newValues: payload,
  });
}

export default {
  getMarketplace,
  getCountry,
  getStorefront,
  getAdType,
  getAdTool,
  getStatus,
  getScriptTarget,
  getInterval,
  getStorefrontScripts,
  getRepeating,
  loadScript,
};
