import * as React from 'react';
import { get } from 'lodash';
import moment from 'moment';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Button, Box, makeStyles, Tooltip } from '@material-ui/core';

import { requestEtableConfig } from '@ep/insight-ui/system/backbone/data-source/common';
import CircleLoading from '@ep/insight-ui/elements/loading/circle-loading';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
    '& a': {
      textDecoration: 'none',
      color: '#253746',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  updatedAt: {
    fontSize: '10px',
  },
  tooltip: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const convertUTCToLocalTime = (utc) => {
  return moment(utc).format('YYYY-MM-DD HH:mm:ss');
};

const ResyncConfigFormat = (props: ICellRendererParams) => {
  const classes = useStyles();

  const { onToast } = useToast();

  const blockEid = get(props, ['data', 'value', 'blockEid'], '');
  const [parentId, setParentId] = React.useState(get(props, ['data', 'value', '_actionInfo', 'parentId'], ''));
  const [isRequesting, setIsRequesting] = React.useState(false);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);

  const lastSyncedAt = get(props, ['data', 'value', '_actionInfo', 'lastSyncedAt'], '');

  const convertedLastSyncedAt = convertUTCToLocalTime(lastSyncedAt);
  const convertedLastUpdatedAt = convertUTCToLocalTime(lastUpdatedAt);

  const handleSubmit = () => {
    if (blockEid) {
      setIsRequesting(true);
      requestEtableConfig(blockEid)
        .then((res) => {
          if (res.success) {
            props.node.setDataValue('value', res.data);
            const updatedAt = get(res, ['data', '_actionInfo', 'lastUpdatedAt']);
            setLastUpdatedAt(updatedAt);
            onToast({
              messages: 'The table configuration has been successfully re-synced.',
              variant: 'success',
            });
          }
        })
        .finally(() => {
          setIsRequesting(false);
        });
    }
  };

  React.useEffect(() => {
    requestEtableConfig(blockEid).then((res) => {
      if (res.success) {
        if (!parentId) {
          setParentId(get(res, ['data', '_actionInfo', 'parentId'], ''));
        }
        const updatedAt = convertUTCToLocalTime(get(res, ['data', '_actionInfo', 'lastUpdatedAt']));
        setLastUpdatedAt(updatedAt);
      }
    });
  }, [blockEid]);

  const tooltip = React.useMemo(() => {
    return (
      <Box className={classes.tooltip}>
        <span>Last updated at: {convertedLastUpdatedAt}</span>
        <span>Last synced at: {convertedLastSyncedAt}</span>
      </Box>
    );
  }, [convertedLastSyncedAt, convertedLastUpdatedAt]);

  return (
    <Box className={classes.container}>
      <Button variant="contained" color="secondary" onClick={handleSubmit} disabled={isRequesting}>
        Resync configuration
      </Button>
      {isRequesting ? <CircleLoading /> : null}
      {lastUpdatedAt ? (
        <Tooltip title={tooltip}>
          <span className={classes.updatedAt} style={{ color: lastSyncedAt < lastUpdatedAt ? '#204d77' : null }}>
            Last updated at: {moment(lastUpdatedAt).fromNow()}
          </span>
        </Tooltip>
      ) : null}
      {parentId ? (
        <a target={'_blank'} href={`/page/${parentId}${blockEid ? `?q=${blockEid}` : ''}`} rel="noreferrer">
          View table
        </a>
      ) : null}
    </Box>
  );
};

export default ResyncConfigFormat;
