import clsx from 'clsx';
import { get, isEmpty } from 'lodash';
import React from 'react';

import { Box, Grid, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';

import ColumnChartResponsive from '@ep/insight-ui/chartlib/column-chart';
import LineChartResponsive from '@ep/insight-ui/chartlib/line-chart/line-chart-fullsize';
import PieChart from '@ep/insight-ui/chartlib/pie-chart-v2';
import TreeMapChartResponsive from '@ep/insight-ui/chartlib/treemap-chart';
import { formatCurrency, formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import * as uuid from 'uuid';

const useStyle = makeStyles({
  container: {
    background: '#fff',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
  },
  comparisonContainer: {
    marginTop: '0',
  },
  chartStyle: {
    height: (props) => (props.size ? (props.size > 100 ? `${props.size}px` : '100px') : '400px'),
    width: '100%',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  single: {
    height: (props) => (props.size ? (props.size > 100 ? `${props.size}px` : '100px') : '400px'),
    width: '100%',
    bgcolor: '#fff',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  chartComparisonStyle: {
    padding: 12,
    backgroundColor: '#fff',
    border: '1px solid #C2C7CB',
    borderRadius: '12px',
  },
  chartComparisonMetrics: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    color: '#253746',
    fontWeight: 500,
  },
  chartBox: {
    padding: '6px',
  },
  chartBoxSingle: {
    width: '100%',
  },
});

const BackgroundTooltip = withStyles({
  tooltip: {
    backgroundColor: '#253746',
  },
})(Tooltip);

const ChartETable = ({ chartConfig, backbone }: any) => {
  // FIXME: need to load series from real data
  const { chart: charts, config } = chartConfig;
  const classes = useStyle({ size: config?.height });
  const [isHover, setIsHover] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);
  const [itemPerRow, setItemPerRow] = React.useState(4);

  const onUpdateSize = () => {
    if (!divRef || !divRef.current) return;
    if (!divRef.current.offsetWidth) return;
    if (divRef.current.offsetWidth < 768) {
      setItemPerRow(2);
      return;
    }
    if (divRef.current.offsetWidth < 1100) {
      setItemPerRow(3);
      return;
    }
    setItemPerRow(4);
  };
  React.useLayoutEffect(() => {
    const ro = new ResizeObserver(onUpdateSize);
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [divRef.current]);

  console.info({ charts });

  const renderChart = () => {
    if (config && config.chartType) {
      switch (config.chartType) {
        case 'col':
          return (
            <Grid className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const chartTitle = String(get(config, 'title', '')).replace(`{metric}`, chart?.metric);
                return (
                  <Grid
                    {...(charts.length > 1
                      ? {
                          xs: 3,
                          item: true,
                        }
                      : {})}
                    className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                    key={chart?.metric}
                  >
                    <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                      <ColumnChartResponsive
                        keyChart={`column_${uuid.v4()}`}
                        title={chartTitle}
                        value={chart?.total}
                        currency={chart?.currency}
                        percent={15}
                        data={chart?.data}
                        stateChart="success"
                        colorOption={config?.color}
                        isHideTotal={chart?.hideShowTotal}
                        isHidePercent={true}
                        isColumnStacked={config?.isColumnStacked}
                        sortBy={config?.sortBy}
                        isSingle={charts.length <= 1}
                        groupPeriod={config?.groupPeriod}
                      />
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        case 'lines':
          return (
            <Grid ref={divRef} className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const chartTitle = String(get(config, 'title', '')).replace(`{metric}`, chart?.metric);
                return (
                  !isEmpty(chart) && (
                    <Grid
                      {...(charts.length > 1
                        ? {
                            xs: 12 / itemPerRow,
                            item: true,
                          }
                        : {})}
                      className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                      key={chart?.metric}
                    >
                      <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                        <LineChartResponsive
                          keyChart={`line_${uuid.v4()}`}
                          data={chart?.data}
                          stateChart={'success'}
                          title={chartTitle}
                          value={chart?.total}
                          cohortValue={chart?.cohortTotal}
                          currency={chart?.currency}
                          percent={15}
                          colorOption={config?.color}
                          isSettingYAxis={chart?.isSettingYAxis}
                          isSingleChart={charts.length <= 1}
                          isHideTotal={chart?.hideShowTotal}
                          isHidePercent={true}
                          groupPeriod={config?.groupPeriod}
                          showLegend={true}
                        />
                      </Box>
                    </Grid>
                  )
                );
              })}
            </Grid>
          );
        case 'pie':
          return (
            <Grid className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const chartTitle = String(get(config, 'title', '')).replace(`{metric}`, chart?.metric);
                const labels = chart.data.rows.map((i) => i.label);
                const datasets = [
                  {
                    label: 'Labels',
                    data: chart.data.rows.map((i) => get(i, 'data.0.value')),
                    currency: get(chart, 'data.rows.[0].currency', ''),
                    actives: labels.map((i) => true),
                    backgroundColor: config?.color.map((i) => i),
                    borderWidth: 0,
                    hoverOffset: 4,
                  },
                ];

                return (
                  <Grid
                    {...(charts.length > 1
                      ? {
                          xs: 3,
                          item: true,
                        }
                      : {})}
                    className={charts.length > 1 ? classes.chartStyle : classes.single}
                    key={chart?.metric}
                  >
                    <PieChart
                      keyChart={`pie_${uuid.v4()}`}
                      data={{ labels, datasets }}
                      stateChart="success"
                      title={chartTitle}
                      value={chart?.total}
                      currency={chart?.currency}
                      percent={15}
                      isHidePercent={true}
                      isHideTotal={true}
                    />
                  </Grid>
                );
              })}
            </Grid>
          );
        case 'treemap':
          return (
            <Grid className={classes.container} container spacing={3}>
              {charts.map((chart) => {
                const chartTitle = String(get(config, 'title', '')).replace(`{metric}`, chart?.metric);
                const dataRows = get(chart.data, 'rows', []);
                const currency = get(dataRows, '[0].currency', '');
                const dataTreemap = dataRows.map((el) => {
                  return {
                    name: el.label,
                    children: el.data.map((ele) => {
                      return {
                        name: ele[config.valueDimension],
                        value: ele.value,
                        showLabel: true,
                      };
                    }),
                  };
                });
                return (
                  !isEmpty(chart) && (
                    <Grid
                      {...(charts.length > 1
                        ? {
                            xs: 3,
                            item: true,
                          }
                        : {})}
                      className={charts.length > 1 ? classes.chartBox : classes.chartBoxSingle}
                      key={chart?.metric}
                    >
                      <Box className={charts.length > 1 ? classes.chartStyle : classes.single}>
                        <TreeMapChartResponsive
                          keyChart={`treemap_${uuid.v4()}`}
                          data={dataTreemap}
                          stateChart="success"
                          currency={currency}
                          title={chartTitle}
                        />
                      </Box>
                    </Grid>
                  )
                );
              })}
            </Grid>
          );
        case 'comparison':
          const formatTooltip = (items) => {
            return (
              <>
                {items.map((item) => {
                  const value = get(item, 'data.0.value', 0);
                  const currency = get(item, 'data.0.currency', '');
                  const label = get(item, 'label', '');
                  return (
                    <Typography
                      key={item.label}
                      variant="body1"
                      style={{ fontSize: '12px' }}
                    >{`${label}: ${formatCurrencyNumber(value, currency)} ${currency}`}</Typography>
                  );
                })}
                <Typography variant="body1" style={{ fontSize: '12px' }}>
                  {`The number represents actual divided by "${get(
                    items,
                    '1.label',
                    'GMV target',
                  )}", if >=100% green, <100% red`}
                </Typography>
              </>
            );
          };
          return (
            <Grid className={clsx(classes.container, classes.comparisonContainer)} container spacing={5}>
              {charts.map((chart) => {
                const chartTitle = String(get(config, 'title', '')).replace(`{metric}`, chart?.metric);
                const metrics = backbone.getOptions('metric');
                const items = [...get(chart, 'data.rows', [])].sort((a, b) => {
                  return metrics.findIndex((el) => el.id === a.key) - metrics.findIndex((el) => el.id === b.key);
                });

                return (
                  <Grid key={chart?.metric} xs={3} item={true} className={clsx(classes.chartBox)}>
                    <Box className={clsx(classes.chartComparisonStyle)}>
                      <Typography variant="h4" color="textSecondary" className={clsx(classes.title, 'title')}>
                        {chartTitle}
                      </Typography>
                      <Grid
                        className={clsx(classes.chartComparisonMetrics)}
                        style={{ paddingTop: 21, paddingBottom: 39 }}
                      >
                        {items.map((i, index) => {
                          const value = get(i, 'data.0.value', 0);
                          const currency = get(i, 'data.0.currency', '');
                          if (index > 0) {
                            if (value === 0) return 0;
                            const percent = get(items, '0.data.0.value', 0) / value;
                            return (
                              <Box key={i.metric}>
                                <Typography
                                  variant={'h4'}
                                  className={clsx('parameter')}
                                  style={{ color: percent < 1 ? '#D4290D' : '#0BA373' }}
                                >
                                  <BackgroundTooltip title={formatTooltip(items)} placement="top">
                                    <div>{(percent * 100).toFixed(2)}%</div>
                                  </BackgroundTooltip>
                                </Typography>
                              </Box>
                            );
                          }
                          return (
                            <Box key={i.metric}>
                              <Typography variant={'h2'} className={clsx('parameter')}>
                                {formatCurrency(value, currency)} {currency}
                              </Typography>
                            </Box>
                          );
                        })}
                      </Grid>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          );
        default:
          return <></>;
      }
    }
  };

  return <>{renderChart()}</>;
};

export default ChartETable;
