/* eslint-disable react/prop-types */
import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import WrapperFormat, { IPropsFormat } from '../wrapper-format';
import * as _ from 'lodash';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexFlow: 'wrap',
    lineHeight: '24px',
    alignItems: 'center',
    textAlign: 'right',
    width: '100%',
  },
}));
export interface IValueBudget {
  value: number | string | null;
  currency: string;
  label: string;
}

export interface IValueMultipleBudgetFormat {
  budgets: Array<IValueBudget>;
  disabledModelNoLimit?: boolean;
}

const MultiBudgetFormat = (props: IPropsFormat<IValueMultipleBudgetFormat>) => {
  const classes = useStyles();
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const budgets = _.get(props, ['value', 'budgets'], []);
  const typeTable = _.get(props, 'typeTable', '');
  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value}>
      <Grid container alignItems={'center'} className={classes.format}>
        <Grid container className={classes.styleText}>
          {budgets.length > 0 &&
            budgets.map((val, index) => {
              return (
                <Grid item xs={12} key={index}>
                  {val.value ? `${formatCurrencyNumber(val.value)} ${val.currency} • ${val.label}` : 'no limit'}
                </Grid>
              );
            })}
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default MultiBudgetFormat;
