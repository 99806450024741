import * as React from 'react';

import { Tooltip, withStyles } from '@material-ui/core';

const StyledTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#181d1f',
    fontSize: 13,
    border: '1px solid #eee',
    fontWeight: 400,
    borderRadius: 0,
    padding: '0.5em',
    marginTop: 0,
  },
}))(Tooltip);

const LightTooltip = ({ children, ...rest }: { children: JSX.Element; rest: any }) => {
  return <StyledTooltip {...rest}>{children}</StyledTooltip>;
};

export default LightTooltip;
