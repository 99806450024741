import * as React from 'react';
import { EIP_CONSTANT } from './constant';
import { ContainerResponsiveContext } from './context/container-responsive';
import type { ContainerResponiveType } from './context/container-responsive';

export function PageContainerResponsive({ children }) {
  const [containerKlass, setContainerKlass] = React.useState<string>('eres--small');

  React.useEffect(() => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const updateContainerKlass = () => {
      const vw = isIOS
        ? Math.max(window.screen.availWidth || 0, window.innerWidth || 0)
        : Math.min(window.screen.availWidth || 0, window.innerWidth || 0);
      const containerKlass = EIP_CONSTANT.CONTAINER_RESPONSIVE.breakpoints.find((bp) => bp[1] > vw)[0];
      setContainerKlass(containerKlass as string);
    };

    updateContainerKlass();
    window.addEventListener('resize', updateContainerKlass);

    return () => {
      window.removeEventListener('resize', updateContainerKlass);
    };
  }, []);

  return (
    <ContainerResponsiveContext.Provider value={{ containerClass: containerKlass as ContainerResponiveType }}>
      {children}
    </ContainerResponsiveContext.Provider>
  );
}
