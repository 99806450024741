import * as React from 'react';
import moment from 'moment';
import { get, isEmpty, isEqual, isNumber } from 'lodash';
import { Line, Bar, Chart } from 'react-chartjs-2';
import memoizeOne from 'memoize-one';

import { Grid, makeStyles, Box, Tooltip } from '@material-ui/core';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { tableTooltip } from '@ep/insight-ui/chartlib/helper';

import WrapperFormat, { IPropsFormat } from '../../etable2/format/wrapper-format';
import { getDateRangeFromOption } from '@ep/insight-ui/sw/util/date-range-calculate';
import clsx from 'clsx';
import { stringToTagColour } from '@ep/insight-ui/system/util/table-cell';
import color from 'color';
import produce from 'immer';
import { DATETIME_REGEX } from '@ep/insight-ui/sw/etable/constant';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import ConditionalWrap from '../../util/conditional-wrap';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import TooltipWrapper from '../../tooltip-wrapper';
import { CALCULATE_TRACTION_WIDTH } from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerChart: {
    padding: '0 12px',
    marginBottom: '5px',
    width: '100%',
    minWidth: '120px',
  },
  // subtext: {
  //   fontSize: '13px',
  // },
  tableTooltip: tableTooltip,
  infoSection: {},
  format: {
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
  },
  hashtag: {
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    '&.disabled': {
      color: '#8C98A4',
      pointerEvents: 'none',
    },
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  lineHeight20: {
    lineHeight: '20px',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  tooltip: {
    background: '#253746',
    fontSize: (props) => (props.customTooltipFontSize ? props.customTooltipFontSize : '10px'),
    lineHeight: '1.2em',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  tooltipItem: {
    padding: '4px',
  },
  avatar: {
    width: '32px',
    height: '32px',
    fontSize: '10px',
    border: '1px solid #CCE3F5',
    background: '#EBF6FF',
    color: '#000',
    marginRight: '4px',
    borderRadius: '4px',
  },
}));

const useTooltipStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    top: 0,
    left: 0,
    minWidth: '200px',
    background: '#fff',
    borderRadius: '3px',
    color: '#253746',
    boxShadow: '0px 6px 12px rgb(140 152 164 / 25%)',
    padding: '6px 8px 8px',
  },
  tableTooltip: tableTooltip,
  icon: {
    width: '8px',
    height: '8px',
    borderRadius: '2px',
  },
  date: {
    color: '#253746',
    fontSize: '12px',
    lineHeight: '16px',
    fontWeight: 500,
    background: '#FAFCFF',
    padding: '4px 2px',
    whiteSpace: 'nowrap',
  },
  body: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: '#8C98A4',
    fontSize: '10px',
    lineHeight: '12px',
    columnGap: '8px',
    marginLeft: '8px',
    fontWeight: 400,
    marginRight: '8px',
  },
  value: {},
}));

export type ITractionElasticityFormat = {
  label: string;
  value: string;
  hashtag?: string;
  subtext?: string;
};

const _renderChart = memoizeOne(function renderChart(data, options, tableType, classes) {
  if (!data.datasets || data.datasets.length === 0) return <Box width={'100%'}></Box>;

  const cData = produce(data, (draft) => {
    data.datasets.forEach((dataset, index) => {
      // draft.datasets[index].normalized = true;
      draft.datasets[index].segment = {
        borderDash: (ctx) => {
          return ctx.p0.skip || ctx.p1.skip ? [2, 2] : undefined;
        },
      };
      if (dataset.chartStyle === 'area') {
        draft.datasets[index].fill = 'start';
        draft.datasets[index].backgroundColor = color(dataset.borderColor).lightness(90).fade(0.2).rgb().string(); // 'rgba(215, 230, 244, 0.8)'
      }
    });
  });

  return (
    <Grid item className={classes.containerChart}>
      <Errorboundary>
        <Line height={tableType === 'compact' ? '33px' : '53px'} width={'100%'} data={cData} options={options} />
      </Errorboundary>
    </Grid>
  );
}, isEqual);

const getOrCreateTooltip = (chart) => {
  const id = 'cell-elasticity-chart-tooltip';
  let tooltipEl = document.getElementById(id);

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = id;
    tooltipEl.style.background = '#fff';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = '#204d77';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.zIndex = '10001';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.boxShadow = '0px 6px 12px rgb(140 152 164 / 25%)';
    tooltipEl.style.padding = '6px 8px';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.width = '200px';

    tooltipEl.appendChild(table);
    document.body.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, options) => {
  // Tooltip Element

  const { chart, tooltip } = context;
  const { period, classes } = options;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    let total = 0;
    bodyLines.forEach((arr) => {
      total += get(arr, [0, 'value'], 0);
    });
    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr: HTMLTableRowElement = document.createElement('tr');
      tr.style.borderWidth = '0';
      tr.className = 'headerRow';

      const th: HTMLTableCellElement = document.createElement('th');
      th.style.borderWidth = '0';
      // const date = moment(title);
      const text = document.createTextNode(title);
      // if (date.isValid()) {
      //   if (period === 'hourly') {
      //     text = document.createTextNode(tit);
      //   } else {
      //     text = document.createTextNode(moment(title).format('ddd, DD MMM YYYY'));
      //   }
      // }

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const tr: HTMLTableRowElement = document.createElement('tr');
      tr.className = 'legendRow';
      const label: string = get(body, [0, 'metric'], '');
      const value: number = get(body, [0, 'value', 'tooltipLabel'], 0);
      const backgroundColor: string = get(tooltip, ['labelColors', i, 'borderColor'], '');
      const currency: string = get(body, [0, 'currency'], '') || '';

      const tdHTML = `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${backgroundColor}; border-color: ${backgroundColor};"></span>
        <div class="content">
        <div class="label">${label}</div>
        <div class="value">${value}</div>
        </div>
      </td>
      `;
      //<div class="value">${`${formatCurrencyNumber(value, currency)} ${currency}`}</div>
      tr.innerHTML = tdHTML;
      tableBody.appendChild(tr);
    });

    const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot.className = `${classes.tableTooltip}`;
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const position = context.chart.canvas.getBoundingClientRect();

  const { offsetLeft: positionX, offsetTop: positionY, offsetHeight: positionY1 } = chart.canvas;
  const tooltipWidth = tooltipEl.getBoundingClientRect().width;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  const left = position.left + 110 + tooltip.caretX;
  const constantNumber = 12;
  tooltipEl.style.left =
    (left + tooltipWidth / 2 + constantNumber > document.body.clientWidth
      ? left - tooltipWidth - constantNumber
      : left) + 'px';
  tooltipEl.style.top = position.top + positionY1 + 'px';
  // tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '0';
};

const MIN_WIDTH_TO_SHOW_CHART = 88;

const TractionElasticityFormat = (props: IPropsFormat<ITractionElasticityFormat>) => {
  const customTooltipFontSize = get(props, ['value', 'tooltip.fontSize'], '');
  const classes = useStyles({ customTooltipFontSize });

  const [isChartShow, setIsChartShow] = React.useState(true);
  const backbone = React.useContext(TableBackboneContext);

  const ref = React.useRef(null);

  const cellAction = get(props, 'cellAction', []);
  const node = get(props, 'node', null);
  const value = +get(props, ['value', 'value'], null);
  const isGrouped = get(props, 'isGrouped', false);
  const typeTable = get(props, 'typeTable', '');
  const isMinimalTable = typeTable == 'minimal';
  const currency = get(props, ['value', 'currency'], '');
  const field = get(props, 'field', '');
  const eData = get(props, ['value', 'eData'], get(props, ['data', 'eData', field], {}));
  const label = get(props, ['value', 'label'], `${isNumber(value) ? value.toFixed(2) : '-'} ${currency}`);
  const hashtag = get(props, ['value', 'hashtag'], '');
  const displayChart = get(props, ['value', 'displayChart']);
  const status = get(props, ['value', 'status'], '');
  const customTooltip = get(props, ['value', 'tooltip'], '');
  const tableId = useAtomValue(eTableAtom.tableId);
  const statusTooltip = get(props, ['value', 'status.tooltip'], null);
  const labelTooltip = get(props, ['value', 'label.tooltip'], null);
  const hashtagTooltip = get(props, ['value', 'hashtag.tooltip'], null);
  const [valueWidth, setValueWidth] = React.useState(null);

  const customStyle = React.useMemo(() => {
    const styles = Object.keys(props.value || {})
      .filter((el) => el.startsWith('style.'))
      .reduce((a, b) => {
        const styleName = b.replace('style.', '').replace(/[A-Z]/g, (c) => `-${c.toLowerCase()}`);
        let styleValue = props.value[b];

        if (styleName === 'background' || styleName.includes('color')) {
          if (styleValue === 'auto') {
            styleValue = typeof label == 'string' ? stringToTagColour(label) : null;
          }
        }
        if (styleValue) {
          return {
            ...a,
            [styleName]: styleValue,
          };
        }
        return a;
      }, {});

    return styles;
  }, [label]);

  const data = get(eData, ['dataValues'], {});
  const yAxisOptions = get(data, ['datasets'], []).reduce((carry, dataset) => {
    const isDisplayChart =
      get(backbone, ['config', 'mapping', props.field, 'staticValue', `${dataset.key}.displayChart`], 1) == 1;
    if (!isDisplayChart) return carry;
    if (!carry[dataset.yAxisID]) {
      carry[dataset.yAxisID] = {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        min: dataset.min,
        max: dataset.max,
      };
    } else {
      carry[dataset.yAxisID].min = Math.min(dataset.min, carry[dataset.yAxisID].min);
      carry[dataset.yAxisID].max = Math.max(dataset.max, carry[dataset.yAxisID].max);
    }
    return carry;
  }, {});

  // const formatLabels = get(eData, ['labels'], []);
  const metricDetail = get(eData, ['metricDetail'], {});
  const cohortValues = get(eData, ['cohortValues'], []);
  const cohortValue = +get(cohortValues, [0, 'cohortValue'], null);
  const lim = get(eData, ['lim'], 'no');

  const tableType = backbone.getConfig('tableType');

  const chartPeriod = get(eData, ['chartPeriod'], '');
  const dateRange = backbone.getConfig('dateRange');

  const period = React.useMemo(() => {
    const personalization = get(
      backbone.getConfig('view', {}),
      ['combinator', 'personalization'],
      backbone.getConfig('personalization', {}),
    );
    const tractionPeriod = get(personalization, ['tractionPeriod'], {});
    return get(tractionPeriod, field, {});
  }, [backbone]);

  const periodShortcut = React.useMemo(() => {
    const { dateTo, dateFrom } = get(period, ['customValue'], {});

    if (period.value === 'custom' && dateFrom) {
      const dateFormat = 'MMM DD';
      const defaultFormat = 'YYYY-MM-DD';
      if (moment(dateTo).format(defaultFormat) === moment(dateFrom).format(defaultFormat))
        return moment(dateFrom).format(dateFormat);
      return `${moment(dateFrom).format(dateFormat)} - ${moment(dateTo).format(dateFormat)}`;
    }

    return period.shortcut || 'previous';
  }, [period]);

  const percent = React.useMemo(
    () =>
      !isNumber(value) || !isNumber(cohortValue)
        ? null
        : cohortValue
        ? Math.ceil((value / cohortValue - 1) * 100)
        : null,
    [cohortValue, value],
  );
  const percentLim = lim === 'yes' ? -1 * percent : percent;

  const options = React.useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        ...yAxisOptions,
        xAxes: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 2,
          bottom: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) =>
            chartPeriod ? externalTooltipHandler(context, { period: chartPeriod, classes }) : null, // this cause the issue with unmounting cellformat that could access cleared `periodBackbone`
          callbacks: {
            label(data) {
              return {
                metric: data.dataset?.customMetric
                  ? data.dataset?.customMetric
                  : metricDetail?.[data.raw?.metric]?.label_raw,
                value: data.raw,
                currency: currency,
              };
            },
          },
        },
      },
    }),
    [chartPeriod],
  );

  // Handle overlap
  React.useEffect(() => {
    const ro = new ResizeObserver(([entry]) => {
      setIsChartShow(entry?.target?.offsetWidth > MIN_WIDTH_TO_SHOW_CHART);
    });

    if (ref.current) {
      const onMouseEnter = () => {
        ref.current.parentNode.parentNode.parentNode.style.zIndex = 9999;
      };
      const onMouseLeave = () => {
        ref.current.parentNode.parentNode.parentNode.style.zIndex = null;
      };
      ref.current.parentNode.parentNode.addEventListener('mouseenter', onMouseEnter);
      ref.current.parentNode.parentNode.addEventListener('mouseleave', onMouseLeave);

      ro.observe(ref.current);

      return () => {
        ref.current.parentNode.parentNode.removeEventListener('mouseenter', onMouseEnter);
        ref.current.parentNode.parentNode.removeEventListener('mouseleave', onMouseLeave);
        ro.disconnect();
      };
    }
  }, [ref]);

  React.useEffect(() => {
    const handleEvent = (e) => {
      if (e.detail?.data?.tableId == tableId && (!e.detail?.data?.colId || e.detail?.data?.colId == field)) {
        let maxWidth = 0;
        document
          .querySelector('#block-' + tableId)
          .querySelectorAll('.eip_col_' + field)
          .forEach((node) => {
            const width = node.querySelector('.display-chart')?.clientWidth;
            if (width > maxWidth) {
              maxWidth = width;
            }
          });
        setValueWidth((prev) => {
          if (maxWidth > prev) return maxWidth;
          return prev;
        });
      }
    };

    window.addEventListener(CALCULATE_TRACTION_WIDTH, handleEvent);

    return () => {
      window.removeEventListener(CALCULATE_TRACTION_WIDTH, handleEvent);
    };
  }, []);
  let renderChart = <Box width={'100%'}></Box>;
  if (displayChart && isChartShow && get(data, 'datasets', []).length > 0) {
    renderChart = _renderChart(data, options, tableType, classes);
    // data.datasets[0].segment = {
    //   borderDash: (ctx) => {
    //     return ctx.p0.skip || ctx.p1.skip ? [2, 2] : undefined;
    //   },
    // };
    // data.datasets[0].normalized = true;
    // renderChart = (
    //   <Grid item className={classes.containerChart}>
    //     <Errorboundary>
    //       {chartStyle === 'bar' ? (
    //         <Bar height={tableType === 'compact' ? '33px' : '53px'} width={'100%'} data={data} options={options} />
    //       ) : (
    //         <Line height={tableType === 'compact' ? '33px' : '53px'} width={'100%'} data={data} options={options} />
    //       )}
    //     </Errorboundary>
    //   </Grid>
    // );
  }

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={cellAction}
      node={node}
      value={value}
      gridApi={props.api}
      isGrouped={isGrouped}
      data={props.data}
      field={props.field}
      eGridCell={props.eGridCell}
    >
      <Grid className={clsx(classes.container, classes.height100)} ref={ref}>
        {/* status icon */}
        {!isEmpty(status) ? (
          <TooltipWrapper tooltipConfig={statusTooltip} data={node?.data} tableId={tableId} isShow={status}>
            <Grid
              item
              style={{ marginRight: !isEmpty(status) ? '5px' : '', flexShrink: 0, flexGrow: 0, flexBasis: '12px' }}
            >
              <span className={classes.wrapperIcon}>
                <Icon type={String(status) as IconType} size={12} className={`${classes.icon} status`} />
              </span>
            </Grid>
          </TooltipWrapper>
        ) : null}
        {/*hashtag*/}
        <Grid
          item
          className={clsx(classes.infoSection, classes.height100, displayChart && 'display-chart')}
          style={valueWidth ? { flex: `0 0 ${valueWidth}px` } : {}}
        >
          <Grid container className={classes.height100} alignItems={'flex-start'} wrap={'nowrap'} direction="column">
            {isMinimalTable ? null : (
              <TooltipWrapper tooltipConfig={hashtagTooltip} data={node?.data} tableId={tableId} isShow={hashtag}>
                <Grid item xs={12} className={classes.height100}>
                  {String(hashtag)?.includes('</span>') ? (
                    <span className={`${classes.hashtag}`} dangerouslySetInnerHTML={{ __html: hashtag }}></span>
                  ) : (
                    <span className={`${classes.hashtag}`}>{hashtag}</span>
                  )}
                </Grid>
              </TooltipWrapper>
            )}

            {/* label */}
            <TooltipWrapper
              tooltipConfig={customTooltip || labelTooltip}
              data={node?.data}
              tableId={tableId}
              isShow={label}
            >
              <Grid item xs={12} className={clsx(classes.height100, classes.lineHeight20)}>
                {Object.keys(customStyle).length > 0 ? (
                  <Box
                    className={`${classes.label}`}
                    style={{
                      padding: customStyle.background ? '0 6px' : undefined,
                      borderRadius: customStyle.background ? '4px' : undefined,
                      ...customStyle,
                    }}
                  >
                    {String(label)?.includes('</span>') ? (
                      <span dangerouslySetInnerHTML={{ __html: label }}></span>
                    ) : (
                      <span>{label}</span>
                    )}
                  </Box>
                ) : String(label)?.includes('</span>') ? (
                  <span dangerouslySetInnerHTML={{ __html: label }}></span>
                ) : (
                  <span>{label}</span>
                )}
              </Grid>
            </TooltipWrapper>
            {/* subtext */}
            {isMinimalTable ? null : (
              <Grid item xs={12} className={classes.height100}>
                <div className={classes.subtext}>
                  {percent && (
                    <span style={{ color: percentLim > 0 ? '#0BA373' : percentLim < 0 ? '#D4290D' : undefined }}>
                      {percent > 0 && '+'}
                      {percent.toFixed(2)}%
                    </span>
                  )}
                  <span> </span>
                  <Tooltip
                    title={
                      <TooltipCohort
                        values={cohortValues}
                        period={period.value}
                        dateRange={dateRange}
                        periodCustom={period.customValue}
                        metricDetail={metricDetail}
                      />
                    }
                    placement="bottom"
                  >
                    <span style={{ color: '#8C98A4' }}>&nbsp;vs {periodShortcut}</span>
                  </Tooltip>
                </div>
              </Grid>
            )}
          </Grid>
        </Grid>
        {renderChart}
      </Grid>
    </WrapperFormat>
  );
};

class Errorboundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span>Something went wrong.</span>;
    }

    return this.props.children;
  }
}

const TooltipCohort = ({ values, period, dateRange, periodCustom, metricDetail }: any) => {
  const classes = useTooltipStyles();
  const format =
    DATETIME_REGEX.test(dateRange.dateFrom) && (!period || 'the_previous' === period || period?.startsWith('last'))
      ? 'YYYY-MM-DD HH:mm'
      : 'YYYY-MM-DD';

  let { dateFrom, dateTo } = getDateRangeFromOption(
    period || 'last_1_days',
    {
      dateFrom: moment(dateRange.dateFrom).format(format),
      dateTo: moment(dateRange.dateTo).format(format),
    },
    format,
  );

  if (period === 'custom') {
    const format = DATETIME_REGEX.test(periodCustom?.dateFrom) ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD';
    dateFrom = moment(periodCustom?.dateFrom).format(format);
    dateTo = moment(periodCustom?.dateTo).format(format);
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.date}>
        {dateFrom} - {dateTo}
      </Box>
      {values.map((value) => {
        return (
          <Box className={classes.body} key={value.metric}>
            <Box className={classes.label}>
              <Box
                className={classes.icon}
                style={{ background: value.color || '#204D77', borderColor: value.color || '#204D77' }}
              ></Box>
              <span style={{ whiteSpace: 'nowrap' }}>
                {value.customMetric || metricDetail[value.metric]?.label_raw}
              </span>
            </Box>
            <Box className={classes.value}>
              <span>{value.cohortTooltipLabel || value.cohortValue}</span>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

// function TractionElasticityFormat1(){
//   return <span>Elasticity</span>;
// }
// export default TractionElasticityFormat1;

export default TractionElasticityFormat;
