import Icon from '@ep/insight-ui/icons/Icon';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import {
  Box,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  makeStyles,
  Divider,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { get, isArray } from 'lodash';
import * as React from 'react';
import { useState } from 'react';
import Dropdown from '../dropdown-menu/dropdown';
/**
 * ff.ui_ux_update_tag_item:start
 */
import { Dialog } from '@material-ui/core';
/**
 * ff.ui_ux_update_tag_item:end
 */

import { NodeEditContext } from '@eip/next/lib/main';
import Wrapper from './wrapper';
import { ACTION_CATEGORY_SEPARATOR } from '@ep/insight-ui/system/block/etable/etable-config/utils/constant';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import ConditionalWrap from '../util/conditional-wrap';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import { ACTION_INTERACTION, PERSONALIZATION } from '@ep/insight-ui/system/helper/constant';
import { Provider, useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import MinimalEtable from '@ep/insight-ui/elements/minimal-etable';
import TooltipWrapper from '@ep/insight-ui/elements/tooltip-wrapper';

const useStyles = makeStyles((theme) => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '3px',
  },
  formatcell: {
    display: 'flex',
    flexDirection: 'column',
  },

  statusNone: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    textAlign: 'end',
  },
  text: {
    paddingLeft: '5px',

    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
  texttotal: {
    textAlign: 'end',
    paddingLeft: '5px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '30px',
    color: '#253746',
  },
  textdaily: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    lineHeight: '0',
  },
  helpText: {
    fontSize: '10px',
    color: '#8C98A4',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    display: 'block',
    lineHeight: '0',
    marginTop: '-10px',
  },
  textCurrent: {
    textAlign: 'end',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
  },
  textPosition: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    color: '#253746',
    display: 'flex',
    justifyContent: 'space-between',
  },
  forcell: {
    marginLeft: '-10px',
  },
  cellDropdown: {
    maxWidth: 250,
  },
  display: {
    display: 'none',
  },
  edotContainer: {
    // position: 'absolute',
    right: 0,
    background: 'transparent',
  },
  button: {
    minWidth: '24px',
    height: '24px',
  },
  menuDropdown: {
    paddingTop: '8px',
  },
  itemDropdown: {
    color: '#253746',
    paddingTop: '2px',
    paddingBottom: '2px',
    '&:hover': {
      borderRadius: '4px',
      backgroundColor: '#E4E7E9',
    },
  },
  textStyle: {
    '& > span': {
      fontWeight: 400,
    },
  },
  disable: {
    pointerEvents: 'none',
  },
  itemDropdownContainer: {
    position: 'relative',
    '&:hover': {
      '& .editAction': {
        display: 'block',
      },
    },
    '& .editAction': {
      position: 'absolute',
      background: 'transparent',
      top: '50%',
      right: '4px',
      transform: 'translateY(-50%)',
      padding: '0 4px',
      display: 'none',
      cursor: 'pointer',
      borderRadius: '4px',
      '&:hover': {
        background: '#E4E7E9',
      },
    },
    '&.eip1-MuiListItem-gutters': {
      padding: 0,
      borderRadius: '4px',
    },
  },
  iconContainer: {
    '& svg': {
      maxWidth: '16px',
    },
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  actionTooltipContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  actionTooltipContainer: {
    background: '#253746',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 400,
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
    maxWidth: 'unset',
  },
}));

export const DropdownCell = (props: any) => {
  const classes = useStyles();
  const origin = React.useRef<HTMLDivElement>();
  const [indexRender, setIndexRender] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [subActions, setSubActions] = React.useState(null);
  const onOpened = get(props, 'onOpened', () => undefined);
  const onClosed = get(props, 'onClosed', () => undefined);
  const ignoreDropdown = get(props, ['ignoreDropdown'], false);
  const [isThreedotsShown, setIsThreedotsShown] = React.useState(false);
  const [dropdownFuncs, setDropdownFuncs] = useState<{ open: () => void; close: () => void }>({
    open: () => undefined,
    close: () => undefined,
  });
  const handleClose = () => {
    setAnchorEl(null);
    onClosed();
  };

  const tableId = useAtomValue(eTableAtom.tableId);

  let dropdownOnclose = null;
  dropdownOnclose = () => {
    setSubActions(null);
    onClosed();
    setIsThreedotsShown(false);
  };
  const handleOnOpened = (...args) => {
    if (onOpened) {
      onOpened(...args);
      setIsThreedotsShown(true);
    }
  };

  const backbone = React.useContext(TableBackboneContext);

  const { isEditMode } = React.useContext(NodeEditContext);

  const cellAction = get(props, ['cellAction', indexRender], {});

  const propsComponent = {
    onSubmit: cellAction.onSubmit,
    onLoad: cellAction.onLoad,
    title: cellAction.title,
    payload: cellAction.payload,
    customProps: cellAction.customProps,
    dimension: cellAction.dimension,
    field: cellAction.field,
    data: props.props,
    setAnchorEl: (val: any) => {
      setAnchorEl(val);
      if (!val) onClosed();
    },
    anchorEl: anchorEl,
    actionSubItem: props.props.actionSubItem,
    isPageAction: cellAction.isPageAction,
    setShowThreeDots: props.setShowThreeDots,
  };
  const typeComponent = React.useMemo(() => cellAction.type, [cellAction]);
  const Component = React.useMemo(
    () => get(props, ['cellAction', indexRender, 'component'], null),
    [indexRender, props],
  );

  const editAction = (e, menu) => {
    e.stopPropagation();
    try {
      document.querySelector('#edit-config-btn').click();
      setTimeout(() => {
        document.querySelector(`#compact-${menu.shortcut.key}-actions`).click();
        setTimeout(() => {
          document.querySelectorAll('.advanced-configuration-btn').forEach((node, i) => {
            if (i === menu.shortcut.index) {
              node.click();
            }
          });
        }, 100);
      }, 100);
      onClosed();
      return;
    } catch (e) {
      // Do nothing
    }
  };

  const handleClickMenuItem = (menu, index) => {
    if (!menu.disable) {
      if (menu.component) {
        setIndexRender(index);
        setAnchorEl(origin.current);
      } else {
        const staticValue = get(menu, ['payload', 'static']);
        const cellValue = get(props, 'props.value');
        const onSubmit = get(menu, ['onSubmit']);
        const menu_subActions = get(menu, ['subActions'], []);
        if (!(menu_subActions.length > 0)) {
          if (onSubmit) {
            onSubmit({
              value: staticValue || cellValue,
              row: get(props, 'props.node.data'),
            });
          }
          dropdownFuncs.close();
          onClosed();
        } else {
          setSubActions(menu_subActions);
        }
      }
    }
  };

  React.useEffect(() => {
    const handleEvent = (e) => {
      if (props.showThreeDots) {
        handleClickMenuItem(props.cellAction[0], 0);
      }
    };
    window.addEventListener('executeFunction', handleEvent);

    return () => {
      window.removeEventListener('executeFunction', handleEvent);
    };
  }, [props]);

  const handleClickDescription = (e, menu) => {};

  const enhanceToValue = (formula) => {
    return toValue(formula, get(props, ['props', 'node', 'data'], {}));
  };

  const mappingListItem = (menu, index) => {
    const isTracking = get(menu, ['customProps', 'tracking'], false);

    if (get(menu, ['customProps', 'category']) == ACTION_CATEGORY_SEPARATOR) {
      return (
        <Box key={index}>
          {index > 0 && <Divider style={{ margin: '4px -8px 4px -8px' }} />}
          {menu.name ? (
            <Typography
              variant="h6"
              style={{ color: '#000', lineHeight: '16px', marginTop: index > 0 ? '8px' : '0px' }}
            >
              {menu.name}
            </Typography>
          ) : null}
        </Box>
      );
    }

    let { icon, tooltip, condition } = get(menu, ['customProps', 'description'], []).reduce((carry, item) => {
      return {
        ...carry,
        [item.key]: item.value,
      };
    }, {});
    if (tooltip) {
      tooltip = enhanceToValue(tooltip);
    }
    if (icon) {
      icon = enhanceToValue(icon);
    }
    const actionTooltipConfig = get(menu, ['customProps', 'tooltip'], []) || [];
    return (
      <TooltipWrapper
        tooltipConfig={actionTooltipConfig}
        data={get(props, ['props', 'node', 'data'], {})}
        isShow={isThreedotsShown}
        tableId={tableId}
      >
        <div style={{ pointerEvents: 'all' }}>
          <MenuItem
            className={clsx(classes.itemDropdownContainer, props.menuClasses)}
            onClick={() => {
              handleClickMenuItem(menu, index);
            }}
            data-mtracking={isTracking ? 'yes' : 'no'}
            disabled={menu.disable}
          >
            {/* {index === props.cellAction?.length - 1 && <Divider />} */}
            <ListItem
              className={clsx({
                [classes.itemDropdown]: true,
                [classes.disable]: menu.disable,
              })}
              disabled={menu.disable}
              role={'menuitem'}
              button
            >
              {menu.icon && (
                <ListItemIcon className={classes.iconContainer}>
                  <Icon
                    style={menu.iconSize && { height: menu.iconSize, width: menu.iconSize }}
                    type={menu.icon}
                    colorIcon={menu.disable ? '#C2C7CB' : '#253746'}
                  />
                </ListItemIcon>
              )}
              <Box display={'flex'} alignItems={'center'}>
                <ListItemText primary={menu.name} className={classes.textStyle} />
                {icon ? (
                  <ConditionalWrap
                    condition={tooltip}
                    wrap={(children) => {
                      return (
                        <StyledTooltip
                          title={<div className={classes.tooltipContent}>{tooltip}</div>}
                          placement={'right'}
                        >
                          {children}
                        </StyledTooltip>
                      );
                    }}
                  >
                    <Box
                      display={'flex'}
                      alignItems={'center'}
                      sx={{ marginLeft: '4px', pointerEvents: 'auto' }}
                      onClick={(e) => handleClickDescription(e, menu)}
                    >
                      <Icon type={icon} />
                    </Box>
                  </ConditionalWrap>
                ) : null}
              </Box>
            </ListItem>
            {isEditMode && menu.shortcut && !get(menu, ['customProps', 'isOne'], false) ? (
              <Box className={'editAction'} onClick={(e) => editAction(e, menu)}>
                <Icon type={'edit'} />
              </Box>
            ) : null}
          </MenuItem>
        </div>
      </TooltipWrapper>
    );
  };

  const renderActionMenu =
    !Boolean(anchorEl) || typeComponent == 'deleteEditor'
      ? !subActions
        ? props.cellAction && props.cellAction.map(mappingListItem)
        : isArray(subActions) && (subActions as Array<any>).map(mappingListItem)
      : null;

  const newAction = props.cellAction.map((menu, index) => {
    return {
      ...menu,
      onClick() {
        handleClickMenuItem(menu, index);
      },
      data: props.props,
    };
  });

  return (
    <Provider
      initialValues={[
        [eTableAtom.tableId, ''],
        [eTableAtom.linkedObjects, {}],
      ]}
    >
      <div ref={origin} className={classes.edotContainer}>
        <ConditionalWrap
          condition={!ignoreDropdown}
          wrap={(children) => {
            return (
              <Dropdown
                classNamePaper={classes.menuDropdown}
                className={`${classes.button} eip_cell_act_${props?.classField} ${props.classes ? props.classes : ''}`}
                alignMenu="right"
                icon={props.icon ? props.icon : 'threeDotsVertical'}
                label=""
                defaultOpen={Boolean(anchorEl)}
                onOpen={handleOnOpened}
                handleOnClosed={dropdownOnclose}
                refFuncs={setDropdownFuncs}
                dataMenuPage={[newAction]}
                {...(props.sizeIcon ? { sizeIcon: props.sizeIcon } : {})}
                {...(ff.mobile_interaction_zone
                  ? {
                      isDropdownMobileShow: props.isDropdownMobileShow,
                      setIsDropdownMobileShow: props.setIsDropdownMobileShow,
                      title: props.classField,
                    }
                  : {})}
                keyboardNavigation
              >
                {children}
              </Dropdown>
            );
          }}
        >
          {renderActionMenu}
        </ConditionalWrap>
        {Component &&
          (typeComponent === 'compact' ? (
            <Component {...propsComponent} />
          ) : typeComponent == 'deleteEditor' ? (
            <Dialog
              open={Boolean(anchorEl)}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <MonitorContainer
                mId={'inline-form/' + propsComponent.title}
                mLabel={`Inline form: ${propsComponent.title}`}
                component={Component}
                {...propsComponent}
              />
            </Dialog>
          ) : (
            <Popover
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MonitorContainer
                mId={'inline-form/' + propsComponent.title}
                mLabel={`Inline form: ${propsComponent.title}`}
                component={Wrapper}
              >
                <Component {...propsComponent} />
              </MonitorContainer>
            </Popover>
          ))}
      </div>
    </Provider>
  );
};
