import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { cloneDeep, get } from 'lodash';

import { aim, AuthContext } from '@eip/next/lib/main';
import { usePassport } from '@ep/insight-ui/elements/app-menu/hooks/use-passport';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

export const useWorkspaceMenu = () => {
  const history = useHistory();
  const { currentWorkspaceDetail } = React.useContext(AuthContext);
  const [workspaceOptions, setWorkspaceOptions] = React.useState(aim.getUserSettings()?.workspaces || []);

  const { sortWorkspace, setUserWorkspace } = usePassport();

  const workspaceChannelRef = React.useRef(null);

  React.useEffect(() => {
    workspaceChannelRef.current = new BroadcastChannel('workspace');

    return () => {
      workspaceChannelRef.current.close();
    };
  }, []);

  const workspacesTopBar = React.useMemo(() => {
    return workspaceOptions.map((ws) => ({
      title: ws.workspace_name,
      icon: 'ic/bi:person-workspace/xanadu',
      onClick: (event) => {
        onItemClick(event, ws);
      },
      closeAfterClick: true,
      active: ws.workspace_id == currentWorkspaceDetail?.workspace_id,
      colorTitle: ws.workspace_id == currentWorkspaceDetail?.workspace_id ? '#0369C7' : null,
      id: ws.workspace_id,
      logo: ws.workspace_logo,
    }));
  }, [workspaceOptions]);

  const onItemClick = (event, ws) => {
    event.stopPropagation();
    if (ws.workspace_id == currentWorkspaceDetail?.workspace_id) return;
    const { metaKey, ctrlKey } = event;
    const currentUserSettings = aim.getUserSettings();
    const redirectPage = ws?.homepage ? ws?.homepage : get(safeJsonParse(ws?.favorite || '[]'), [0, 'nodeId'], '');
    const newUrl = `/${ws.workspace_domain}/page${redirectPage ? `/${redirectPage}` : ''}`;
    if (metaKey || ctrlKey || event.button == 1) {
      window.open(newUrl);
    } else {
      aim.setUserSettings({
        ...currentUserSettings,
        profile: {
          ...currentUserSettings?.profile,
          workspace_id: ws.workspace_id,
          workspace_domain: ws.workspace_domain,
          favorite: ws.favorite,
        },
      });
      workspaceChannelRef.current.postMessage(ws);
      setUserWorkspace(ws.workspace_id);
      history.push(newUrl);
    }
  };

  const moveCard = (dragIndex, hoverIndex) => {
    const clone = cloneDeep(workspaceOptions);
    [clone[dragIndex], clone[hoverIndex]] = [clone[hoverIndex], clone[dragIndex]];
    setWorkspaceOptions(clone);
    const userSettings = aim.getUserSettings();
    aim.setUserSettings({
      ...userSettings,
      workspaces: clone,
    });
  };

  const onDrop = () => {
    if (workspaceOptions && workspaceOptions.length > 0) {
      const workspaceIds = workspaceOptions.map((ws) => ws.workspace_id);
      sortWorkspace(workspaceIds);
    }
  };

  return {
    workspacesTopBar,
    moveCard,
    onDrop,
  };
};
