import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import Dropdown, { AlignType } from '../../../dropdown-menu/dropdown';
import { OptionSelectType } from '../../../list-control/type';
import FilterForm, { IFilterItem } from './filter-form-group';
import { useEffect } from 'react';
import clsx from 'clsx';

export type FilterOperatorType = {
  [operatorKey: string]: OptionSelectType[][];
};
export interface FieldSelectedValue extends Omit<OptionSelectType, 'dataType'> {
  dataType: string;
  selectedOperators?: string[];
}
export interface FilterDialogProps {
  options: Array<FieldSelectedValue>;
  operators?: FilterOperatorType;
  filterModel: IFilterItem[];
  defaultOpen?: boolean;
  helperText?: any;
  headerText?: string;
  onClosed?: () => void;
  disabled?: boolean;
  alignMenu?: AlignType;
  className?: string;
  classNameButton?: string;
  classNamePopover?: string;
  enableFillerGroup?: boolean;
  enabledSearchOptions?: boolean;
  disabledClickContentClose?: boolean;
  disabledIconNoFilter?: boolean;
  setFilterModel: (model: IFilterItem[]) => void;
  getOptionAvailable?: (field: string) => Promise<
    {
      label: string;
      value: string;
    }[]
  >;
  isDialogFilter?: boolean;
  setIsDialogFilter?: any;
  setIsScrollDisabled: any;
  isButtonActive?: boolean;
  requiredValueFilterFields: string[];
  hideButtons?: boolean;
  addFilterButtonLabel: string;
}

/** Style */
const useStyles = makeStyles(() => ({
  paper: {
    padding: '4px 16px',
  },
  isScrollDisabled: {
    bottom: '10px',
  },
}));

const FilterDialog = ({
  options,
  operators,
  filterModel,
  setFilterModel,
  disabled,
  alignMenu,
  className = '',
  classNameButton = '',
  classNamePopover = '',
  defaultOpen = false,
  onClosed = () => undefined,
  enableFillerGroup = false,
  enabledSearchOptions = false,
  disabledClickContentClose = false,
  disabledIconNoFilter = false,
  getOptionAvailable = () => undefined,
  isButtonActive = false,
  requiredValueFilterFields = [],
}: FilterDialogProps) => {
  const classes = useStyles();
  const [isScrollDisabled, setIsScrollDisabled] = React.useState(false);
  useEffect(() => {
    setIsScrollDisabled(false);
  }, [onClosed, defaultOpen]);

  return (
    <Dropdown
      alignMenu={alignMenu}
      disabled={disabled}
      defaultOpen={defaultOpen}
      handleOnClosed={onClosed}
      label="Filter"
      iconStart="filter"
      className={classNameButton}
      classNamePaper={clsx(`${classes.paper} ${className}`, {
        [classes.isScrollDisabled]: isScrollDisabled,
      })}
      disabledClickContentClose={disabledClickContentClose}
      classNamePopover={classNamePopover}
      isButtonActive={isButtonActive}
    >
      <FilterForm
        options={options}
        operators={operators}
        filterModel={filterModel}
        setFilterModel={setFilterModel}
        enabledSearchOptions={enabledSearchOptions}
        enableFillerGroup={enableFillerGroup}
        disabledIconNoFilter={disabledIconNoFilter}
        getOptionAvailable={getOptionAvailable}
        setIsScrollDisabled={setIsScrollDisabled}
        requiredValueFilterFields={requiredValueFilterFields}
      />
    </Dropdown>
  );
};

export default FilterDialog;
