/* eslint-disable react/jsx-key */
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import * as React from 'react';

import ChartETable from '@ep/insight-ui/elements/epsilo-chart/edashboard-charts/chart-etable2';
import LoadingIcon from '@ep/insight-ui/elements/list-control/spinners/icon-spinner';
import { Box, Grid, Button } from '@material-ui/core';

import { useEvisualization } from './hooks/use-evisualization';

export type OptionSelectType = {
  width?: number;
  name: string;
  field: string;
  sortable?: boolean;
  filterable?: boolean;
  cell: {
    format: any;
    action?: {
      name: string;
      icon?: IconType;
      component?: React.ReactNode;
      onSubmit: (info) => void;
    };
  };
  align?: string;
  action?: {
    name: string;
    icon?: IconType;
    component?: React.ReactNode;
    onSubmit: (info) => void;
    type?: string;
  };
};

export type SortType = {
  field: string;
  sort: 'asc' | 'desc';
};

export interface IColumnWidth {
  columnField: string;
  width: number;
}

export interface IPagination {
  page: number;
  limit: number;
}

interface IPinnedColumn {
  field: string;
  isLeftPin: boolean;
  isRightPin: boolean;
  left?: number;
}

interface IPropTable {
  backbone: any;
  openSortModal?: (params: { field: string; menuType: { name: string; type: 'desc' | 'asc' | '' } }) => void;
  filterText?: string;
  columns: OptionSelectType[];
  columnsActions?: [{ name: 'Add filter'; onClick: () => void }];
  columnOrder?: string[];
  columnWidth?: Array<IColumnWidth>;
  hiddenColumns?: string[];
  pinnedColumns?: IPinnedColumn[];
  rowsGroup?: string[];
  filter?: [{ field: string; operator: string; value: string }];
  sort?: SortType[];
  allowSelect?: boolean;
  selectedRows?: any[];
  pageOptions?: Array<OptionType>;
  pagination: { page: number; limit: number; total: number };
  onChangePagination: ({ page, limit }: IPagination) => void;
  onColumnResize?: (columnWidth: Array<IColumnWidth>) => void;
  onRowSelect?: () => void;
  onColumnReorder?: (columnOrder: string[]) => void;
  typeTable?: string;
  aggregations?: { [key: string]: any };
  cellFormatRegistry: Record<string, any>;
  cellActionRegistry: Record<string, any>;
  headerActionRegistry: Record<string, any>;
}

const EVisualization = ({ backbone, config }: IPropTable & { config; chartConfig? }) => {
  const { charts, viewByDimenionField, chartConfig, loadingChart, summary, isRequestFailed, reload } =
    useEvisualization({
      backbone,
      config,
    });

  return (
    <Grid container spacing={3} style={{ height: '100%', marginTop: '16px', marginBottom: '16px' }}>
      {loadingChart ? (
        <Box>
          <LoadingIcon color={'#253746'} />
        </Box>
      ) : isRequestFailed ? (
        <Button variant="text" size="small" onClick={reload}>
          <Grid container>
            <Box style={{ marginRight: '4px' }}>
              <Icon type={'reload'} />
            </Box>
            <span>Reload</span>
          </Grid>
        </Button>
      ) : (
        chartConfig && (
          <ChartETable
            chartConfig={{
              ...chartConfig,
              chart: charts,
              viewByDimenionField,
            }}
            backbone={backbone}
            title={summary?.title}
            title2={summary?.title2}
            title3={summary?.title3}
          ></ChartETable>
        )
      )}
    </Grid>
  );
};

export default EVisualization;
