import * as React from 'react';
import { get } from 'lodash';

import SelectMultipleForm from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form';

import { useETableOptions } from './hooks/use-etable-options';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

const MultipleSelect = ({ configuration, onChange, placeholder, initialValue, fieldKey, errors, ...rest }: any) => {
  const [selected, setSelected] = React.useState([]);

  const { eTableOptions, isSelectionFromETable } = useETableOptions({ ...rest, configuration });

  React.useEffect(() => {
    setTimeout(() => {
      const value = initialValue ? safeJsonParse(initialValue, initialValue) : [];
      setSelected(value);
      onChange({
        target: {
          value: value,
        },
      });
    }, 0);
  }, [initialValue, onChange]);

  const handleChange = (e) => {
    setSelected(e);
    onChange(e);
  };

  const formFieldOptions = React.useMemo(() => {
    const optionObject = get(configuration, ['field_configuration'], []) || [];
    let migratedOptionObject;
    if (ff.migrate_action_config) {
      migratedOptionObject = (optionObject || []).reduce((a, b) => {
        if (b.key === 'input_type.options') {
          return [...a, ...b.value];
        }
        return [...a, b];
      }, []);
    }
    const options = (ff.migrate_action_config ? migratedOptionObject : optionObject).map((el) => {
      let value = el.value;
      if (el.jsonParse) {
        try {
          value = JSON.parse(el.value);
        } catch (e) {}
      }
      return {
        ...el,
        value,
      };
    });
    return options;
  }, [configuration]);

  const options = React.useMemo(() => {
    if (isSelectionFromETable) return eTableOptions;
    return formFieldOptions;
  }, [formFieldOptions, eTableOptions, isSelectionFromETable]);

  const errorMessage = get(errors, String(fieldKey).split('.').concat('message'), '');

  return (
    <>
      <SelectMultipleForm
        options={options}
        value={selected}
        onChange={handleChange}
        multipleSelect
        searchAble={false}
        isConfigChart={false}
      />
      {errorMessage ? <span style={{ color: '#d4290d', fontSize: '12px' }}>{errorMessage}</span> : null}
    </>
  );
};

export default MultipleSelect;
