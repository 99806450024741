import * as React from 'react';
import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';
import Autocomplete, {
  AutocompleteGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
} from '@material-ui/lab/Autocomplete';
import { Checkbox, makeStyles, TextField, Box, TextFieldProps, OutlinedTextFieldProps } from '@material-ui/core';
import MuiChip from '@material-ui/core/Chip';
import MuiPaper from '@material-ui/core/Paper';
import withStyles, { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import { colors, textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import clsx from 'clsx';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Icon from '@ep/insight-ui/icons/Icon';
import * as _ from 'lodash';
import { useRef } from 'react';
import { LocalGasStationSharp } from '@material-ui/icons';
import { Grid, IconButton } from '@material-ui/core';
import CircleLoading from '../../loading/circle-loading';
const useStyles = makeStyles(() => ({
  textStyle: {
    ...(textFieldStyle as CreateCSSProperties),
    width: '100%',
    '& .Mui-disabled .eip1-MuiChip-label': {
      color: colors.text.subdued,
      '& .popupIcon svg': {
        color: colors.text.subdued,
      },
    },
  },
  complete: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inputAutocomplete: {
    width: '100% ',
    position: 'relative',
    '& input': {
      padding: '0px !important',
      paddingLeft: '9px !important',
      height: '32px',
    },
    '& .eip1-MuiInputBase-root': {
      padding: '0px',
      flexWrap: ' nowrap',
      // maxWidth: '90%',
    },
  },
  renderInput: {
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    marginTop: '8px',
  },
  renderOption: {
    background: 'transparent',
  },
  // chip: {

  // },
  position: {
    '&.iconClose': {
      right: '17px',
      marginTop: '2px',
      position: 'absolute',
      transform: 'translateY(-50%)',
      top: '50%',
    },
    '&.popupIcon': {
      position: 'absolute',
      right: '10px',
      transform: 'translateY(-50%)',
      top: '50%',
    },
  },
  icon: {
    '&.close': {
      width: '12px',
      height: '12px',
      display: 'flex',
      alignItems: 'center',
    },
    '&.deleteIcon': {
      width: '10px',
      height: '10px',
      display: 'flex',
      alignItems: 'center',
    },
    '&.popupIcon': {
      width: '8px',
      height: '8px',
      display: 'flex',
      alignItems: 'center',
      color: '#3F4F5C',
    },
    '&.disabled svg': {
      color: colors.text.subdued,
    },
  },
  text: {
    '&.labelOption': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#253746',
    },
    '&.selected': {
      color: '#0369C7',
    },
  },
  groupTags: {
    display: 'block',
    maxWidth: '200px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: ' ellipsis ellipsis',
  },
  disabled: {
    pointerEvents: 'none',
  },
  singleChip: {
    backgroundColor: 'transparent',
  },
  option: {
    backgroundColor: 'transparent',
    borderRadius: '4px',
    '&[aria-selected="true"]': {
      backgroundColor: '#EBF6FF',
      color: '#0369C7',
    },
    '&[data-focus="true"]&[aria-selected="false"]': {
      backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
  },
}));
const Chip = withStyles(() => ({
  root: {
    height: '24px',
    left: '0px',
    top: '0px',
    background: '#F6F7F8',
    borderRadius: '4px',
    alignItems: 'center',
    border: 0,
    margin: '0 2px',
    maxWidth: '100%',
  },
  deleteIcon: {
    '& svg': {
      color: '#253746',
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
  },
}))(MuiChip);

const Paper = withStyles(() => ({
  root: {
    // background: 'yellow',
    padding: 0,
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    '& ul': {
      // background: 'blue',
      // padding: '10px 0',
      '& li': {
        margin: '5px 5px',
      },
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '8px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'transparent',
      },
      '&::-webkit-scrollbar-track:hover': {
        backgroundColor: 'transparent',
      },
      '&:hover::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'transparent',
        borderRadius: '16px',
        border: '1px solid #F8FAFD',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '0px solid #f4f4f4',
      },
      '&::-webkit-scrollbar-button ': { display: 'none' },
    },
  },
}))(MuiPaper);

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon style={{ color: '#338ED8' }} fontSize="small" />;
type OptionType = {
  label: string;
  value: string;
  checked?: boolean;
};
export interface AutocompleteProps extends Omit<OutlinedTextFieldProps, 'variant'> {
  options: OptionType[];
  defaultValue?: string[];
  placeholder?: string;
  limitTags?: number;
  onChangeValue?: (arr: string[]) => void;
  variant?: 'filled' | 'outlined' | 'standard';
  single?: boolean;
}
const AutoCompleteInput = ({
  options,
  defaultValue = [],
  placeholder,
  // limitTags = 2,
  onChangeValue = (v: string[]) => undefined,
  variant = 'outlined',
  single = false,
  ...rest
}: AutocompleteProps) => {
  const classes = useStyles();
  const myInput = React.useRef();
  const [limitTags, setLimitTags] = React.useState<number>(100);
  const initialValues = React.useMemo(() => {
    if (defaultValue.length > 0) {
      if (single) {
        return options.find((item) => item.value === defaultValue[0]);
      }
      setLimitTags(100);
      return options.filter((item) => defaultValue.includes(item.value));
    }
    return [];
  }, [defaultValue]);
  const [countValue, setCountValue] = React.useState<number>(!initialValues?.length ? 0 : initialValues ? 1 : 0);

  const handleChangeSingle = (e, value: OptionType) => {
    if (!value) {
      onChangeValue([]);
      setCountValue(0);
      return;
    }
    onChangeValue([value.value]);
    setCountValue(1);
  };
  const handleChange = (e, value: OptionType[]) => {
    onChangeValue(value.map((item) => item.value));
    setCountValue(value.length);
  };
  const renderTags = (tags: any[], getTagProps: AutocompleteGetTagProps) => {
    const lengthInput = myInput.current ? myInput.current.offsetWidth : 0;
    let totalLengthChip = 0;
    let lengthChipCount = 50;
    return (
      <div style={{ maxWidth: '80%', display: 'flex', flexWrap: 'nowrap' }}>
        {tags.map((option, index) => {
          return index < limitTags ? (
            <React.Fragment key={index.toString()}>
              <Chip
                className={classes.singleChip}
                {...getTagProps({ index })}
                ref={(ref) => {
                  const length = ref ? ref.offsetWidth : 0;
                  totalLengthChip += length;
                  if (totalLengthChip < lengthInput * 0.9 - lengthChipCount) {
                    setLimitTags(index + 1);
                  }
                }}
                variant="outlined"
                deleteIcon={
                  <span className={`${classes.icon} deleteIcon`} style={{ display: single ? 'none' : 'block' }}>
                    <Icon type={'deleteChip'} style={{ height: '10px', width: '10px' }} />
                  </span>
                }
                label={option.label}
              />
            </React.Fragment>
          ) : null;
        })}
        {limitTags && tags.length > limitTags && (
          <Chip
            className={classes.singleChip}
            ref={(ref) => {
              lengthChipCount = ref ? ref.offsetWidth : 0;
            }}
            variant="outlined"
            label={`+ ${tags.length - limitTags}`}
          />
        )}
      </div>
    );
  };

  const renderOptions = (option: OptionType, { selected }) => {
    if (single) {
      return (
        <div className={classes.renderOption}>
          <span className={`${classes.text} ${selected ? 'selected' : ''} labelOption`}>{option.label}</span>
        </div>
      );
    }
    return (
      <div className={classes.renderOption}>
        <Checkbox style={{ marginRight: 8 }} checked={selected} icon={icon} checkedIcon={checkedIcon} />
        <span className={`${classes.text} labelOption`}>{option.label}</span>
      </div>
    );
  };

  const renderInput = (params: AutocompleteRenderInputParams) => {
    return <TextField {...params} placeholder={placeholder} variant={variant} {...rest} />;
  };

  const [refreshLoading, setRefreshLoading] = React.useState(false);
  const reloadData = async () => {
    if (rest.requestOption) {
      setRefreshLoading(true);
      const res = await rest.requestOption(true);
      setRefreshLoading(false);
    }
  };

  return (
    <div>
      <Box
        position={'relative'}
        display={'inline-flex'}
        width={'100%'}
        className={clsx(rest.disabled && classes.disabled)}
      >
        <Autocomplete
          classes={{
            option: classes.option,
          }}
          ref={myInput}
          multiple={!single}
          value={initialValues}
          options={options}
          onChange={single ? handleChangeSingle : handleChange}
          className={clsx(classes.textStyle, classes.inputAutocomplete)}
          includeInputInList={true}
          closeIcon={
            <span className={`${classes.icon} close ${classes.position} iconClose`}>
              <CloseFiled style={{ height: '100%', width: '100%' }} />
            </span>
          }
          forcePopupIcon={false}
          renderTags={renderTags}
          PaperComponent={Paper}
          disableCloseOnSelect={!single}
          getOptionSelected={(option, value) => _.isEqual(option, value)}
          getOptionLabel={(option) => option.label || ''}
          renderOption={renderOptions}
          style={{ width: '100%' }}
          selectOnFocus={true}
          renderInput={renderInput}
        />
        {refreshLoading ? (
          <Grid style={{ position: 'absolute', right: '0px', top: '8px' }}>
            <CircleLoading size="15px" />
          </Grid>
        ) : options.length > 0 ? (
          <span className={`${classes.icon} popupIcon ${classes.position} ${rest.disabled ? 'disabled' : ''}`}>
            <Icon type={'chevron'} style={{ height: '100%', width: '100%' }} />
          </span>
        ) : (
          <IconButton
            onClick={reloadData}
            style={{ padding: '0px', marginLeft: '6px', position: 'absolute', right: '8px', top: '10px' }}
          >
            <Icon type={'reload'} colorIcon={'#253746'} />
          </IconButton>
        )}
      </Box>
    </div>
  );
};
export default AutoCompleteInput;
