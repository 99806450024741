import * as React from 'react';
import { cloneDeep, get, omit, set } from 'lodash';
import * as uuid from 'uuid';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  Divider,
  makeStyles,
  TextareaAutosize,
} from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import ButtonEtableConfig from '../etable/etable-config/button-etable-config';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';
import ETableConfig from '../etable/etable-config';
import { RichConfigForm } from '../etable/etable-next-configuration-form';
import { EIP_CONSTANT, eipRequest as request } from '@eip/next/lib/main';

import * as jsonpatch from 'fast-json-patch';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
const useStyles = makeStyles(() => ({
  addConfigBtn: {
    marginTop: '8px',
  },
  addConfigBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  configList: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
}));

const useAdToolConfigStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    '& .eip1-MuiAccordionSummary-root': {
      minHeight: 'unset',
      background: 'transparent !important',
    },
    '& .eip1-MuiPaper-rounded': {
      padding: 0,
      boxShadow: 'none',
    },
    '& .eip1-MuiAccordionSummary-content': {
      margin: 0,
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
  },
  acLabel: {
    whiteSpace: 'nowrap',
  },
  dropdown: {
    background: '#EDF2F9',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
  btnGroup: {
    marginBottom: '8px',
  },
  configBtn: {
    width: '100%',
  },
  editableContent: {
    padding: '0 4px',
    fontWeight: 'bold',
    fontSize: '14px',
    width: '100%',
  },
  headerIcon: {
    '& svg': {
      height: '15px',
      width: '12px',
    },
  },
}));

const Camp0DetailConfiguration = ({ data, onSubmit, nodeId }: any) => {
  const classes = useStyles();

  const defaultView = {
    id: 'default',
    name: 'Default',
    combinator: {},
  };
  const [configs, setConfigs] = React.useState(get(data, ['adsToolConfig'], []));
  const [syncing, setSyncing] = React.useState(false);

  const { onToastMultiple } = useToast();

  React.useEffect(() => {
    setConfigs(get(data, ['adsToolConfig'], []));
  }, [data]);

  const handleAddConfig = () => {
    setConfigs((prev) => {
      const defaultConfig = {
        id: uuid.v4(),
        code: 'AD_TOOL_' + (prev.length + 1),
        config: {
          endpoint: {},
          mapping: {},
          view: defaultView,
          views: [defaultView],
        },
      };
      return prev.concat(defaultConfig);
    });
  };

  const handleSubmit = (configs) => {
    onSubmit({
      adsToolConfig: configs,
    });
  };

  const handleUpdateAdToolConfig = ({ id, config, code }) => {
    setConfigs((prevValue) => {
      const newConfigs = prevValue.map((cfg) => {
        if (cfg.id == id) {
          return {
            ...cfg,
            code: code,
            config,
          };
        }
        return cfg;
      });
      return newConfigs;
    });
  };

  const duplicateConfig = ({ config }) => {
    return setConfigs((prevValue) => {
      const newConfig = {
        id: uuid.v4(),
        code: 'AD_TOOL_' + (prevValue.length + 1),
        config: config,
      };
      return prevValue.concat(newConfig);
    });
  };

  const removeConfig = ({ id }) => {
    return setConfigs((prevValue) => {
      const newConfigs = prevValue.filter((cfg) => cfg.id != id);
      return newConfigs;
    });
  };

  const syncFromUC = () => {
    setSyncing(true);
    request
      .post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/page/chunk', {
        id: '3edd4b9c-fd8c-40c2-8cd3-c6d997ed8426',
      })
      .then((res) => {
        const adPlacementOptimization = get(
          res,
          ['block', 'kEFPhuDPZYVuYrzk8GDmX', 'payload', 'properties', 'customAttributes'],
          null,
        );
        const adObjectOptimization = get(
          res,
          ['block', '5TeSVwsvgelEJRbKnmMeW', 'payload', 'properties', 'customAttributes'],
          null,
        );

        if (!adPlacementOptimization || !adObjectOptimization) {
          onToastMultiple({
            messages: []
              .concat(!adObjectOptimization ? ['Ad object optimization settings does not exist'] : [])
              .concat(!adPlacementOptimization ? ['Ad placement optimization settings does not exist'] : []),
            title: 'Sync failed',
            variant: 'error',
          });
          return;
        }
        const cloneConfigs = cloneDeep(configs);
        const mergedConfigs = cloneConfigs.map((cfg) => {
          if (cfg.code == 'init') return cfg;
          const hasKeywordColumn = get(cfg, ['config', 'mapping'], {}).hasOwnProperty('keyword');
          const ignoreMergeFields = [
            'actions',
            'advancedFilter',
            'filterField',
            'sortField',
            'valueGetter',
            'systemSettings',
          ];
          const mergedFields = ['calendarOption', 'dateRange'];
          const mergedViewFields = ['columnWidth'];

          if (!hasKeywordColumn) {
            Object.keys(cfg.config.mapping).forEach((key) => {
              if (adObjectOptimization.mapping.hasOwnProperty(key)) {
                const isMetric = get(cfg, ['config', 'mapping', key, 'propertyType']) == 'metric';
                if (isMetric) {
                  set(cfg, ['config', 'mapping', key], get(adObjectOptimization, ['mapping', key]));
                } else {
                  Object.keys(omit(adObjectOptimization.mapping[key], ignoreMergeFields)).forEach((k) => {
                    set(cfg, ['config', 'mapping', key, k], get(adObjectOptimization, ['mapping', key, k]));
                  });
                  const cfgValueGetter = get(cfg, ['config', 'mapping', key, 'valueGetter']);
                  const targetValueGetter = get(adObjectOptimization, ['mapping', key, 'valueGetter']);
                  set(cfg, ['config', 'mapping', key, 'valueGetter'], {
                    ...cfgValueGetter,
                    ...targetValueGetter,
                    id: cfgValueGetter.id,
                    label: cfgValueGetter.label,
                    value: cfgValueGetter.value,
                  });
                }
              }
            });
            const views = get(cfg, ['config', 'views'], []);
            if (views.length == 1) {
              mergedViewFields.forEach((field) => {
                set(cfg, ['config', field], get(adObjectOptimization, [field]));
                set(
                  cfg,
                  ['config', 'view', 'combinator', field],
                  get(adObjectOptimization, ['view', 'combinator', field]),
                );
                set(
                  cfg,
                  ['config', 'views', 0, 'combinator', field],
                  get(adObjectOptimization, ['views', 0, 'combinator', field]),
                );
              });
            }
            mergedFields.forEach((field) => {
              set(cfg, ['config', field], get(adObjectOptimization, [field]));
            });
          } else {
            Object.keys(cfg.config.mapping).forEach((key) => {
              if (adPlacementOptimization.mapping.hasOwnProperty(key)) {
                const isMetric = get(cfg, ['config', 'mapping', key, 'propertyType']) == 'metric';
                if (isMetric) {
                  set(cfg, ['config', 'mapping', key], get(adPlacementOptimization, ['mapping', key]));
                } else {
                  Object.keys(omit(adPlacementOptimization.mapping[key], ignoreMergeFields)).forEach((k) => {
                    set(cfg, ['config', 'mapping', key, k], get(adPlacementOptimization, ['mapping', key, k]));
                  });
                  const cfgValueGetter = get(cfg, ['config', 'mapping', key, 'valueGetter']);
                  const targetValueGetter = get(adPlacementOptimization, ['mapping', key, 'valueGetter']);
                  set(cfg, ['config', 'mapping', key, 'valueGetter'], {
                    ...cfgValueGetter,
                    ...targetValueGetter,
                    id: cfgValueGetter.id,
                    label: cfgValueGetter.label,
                    value: cfgValueGetter.value,
                  });
                }
              }
            });
            const views = get(cfg, ['config', 'views'], []);
            if (views.length == 1) {
              mergedViewFields.forEach((field) => {
                set(cfg, ['config', field], get(adPlacementOptimization, [field]));
                set(
                  cfg,
                  ['config', 'view', 'combinator', field],
                  get(adPlacementOptimization, ['view', 'combinator', field]),
                );
                set(
                  cfg,
                  ['config', 'views', 0, 'combinator', field],
                  get(adPlacementOptimization, ['views', 0, 'combinator', field]),
                );
              });
            }
            mergedFields.forEach((field) => {
              set(cfg, ['config', field], get(adPlacementOptimization, [field]));
            });
          }
          return cfg;
        });
        handleSubmit(mergedConfigs);
        onToastMultiple({
          messages: [],
          title: 'Sync successfully',
          variant: 'success',
        });
      })
      .catch((e) => {
        onToastMultiple({
          messages: [e.message],
          title: 'Sync failed, please try again',
          variant: 'error',
        });
      })
      .finally(() => setSyncing(false));
  };

  const configsRender = React.useMemo(() => {
    return configs.map((config) => {
      return (
        <AdToolConfig
          key={config.id}
          {...config}
          handleUpdateAdToolConfig={handleUpdateAdToolConfig}
          duplicateConfig={duplicateConfig}
          removeConfig={removeConfig}
        />
      );
    });
  }, [configs]);

  return (
    <div>
      <div style={{ marginBottom: '8px' }}>
        <Button
          variant={'contained'}
          color={'primary'}
          onClick={syncFromUC}
          className={classes.addConfigBtn}
          disabled={syncing}
          fullWidth
        >
          <span className={classes.addConfigBtnContainer}>
            <Icon type={syncing ? 'ic/material-symbols:sync/#cccccc' : 'ic/material-symbols:sync/white'} />
            <span>{syncing ? 'Syncing' : 'Sync Unified campaign'}</span>
          </span>
        </Button>
      </div>
      <div className={classes.configList}>{configsRender}</div>
      <Button variant={'text'} onClick={handleAddConfig} className={classes.addConfigBtn}>
        <span className={classes.addConfigBtnContainer}>
          <Icon type={'ic/material-symbols:add-circle/#253746'} />
          <span>Add config</span>
        </span>
      </Button>
      <Button
        variant={'contained'}
        color={'primary'}
        onClick={() => handleSubmit(configs)}
        className={classes.addConfigBtn}
        fullWidth
      >
        <span className={classes.addConfigBtnContainer}>
          <span>Submit</span>
        </span>
      </Button>
    </div>
  );
};

const AdToolConfig = ({ id, code, config, handleUpdateAdToolConfig, duplicateConfig, removeConfig }) => {
  const classes = useAdToolConfigStyles();
  const [sConfig, setSConfig] = React.useState(config);
  const [expanded, setExpanded] = React.useState(false);
  const codeRef = React.useRef(code);

  React.useEffect(() => {
    setSConfig(config);
  }, [config]);

  const adToolActions = [
    [
      {
        title: 'duplicate',
        onClick() {
          duplicateConfig({ id, config: sConfig });
        },
        icon: 'duplicate',
      },
      ...(['init', 'default'].includes(code)
        ? []
        : [
            {
              title: 'remove',
              onClick() {
                removeConfig({ id });
              },
              icon: 'remove',
            },
          ]),
    ],
  ];

  const onSubmit1 = (config) => {
    setSConfig(config);
    handleUpdateAdToolConfig({ id, config, code: codeRef.current });
  };

  const nodeId = 'abc';

  return (
    <div className={classes.container}>
      <Accordion expanded={expanded} onChange={(e, value) => setExpanded(value)}>
        <AccordionSummary>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <div className={classes.headerIcon} style={expanded ? { transform: 'translateY(2px)' } : {}}>
                <Icon type={expanded ? 'arrowdown' : 'arrowright'} />
              </div>
              {['default', 'init'].includes(code) ? (
                <div className={classes.editableContent}>{code}</div>
              ) : (
                <TextareaAutosize
                  className={classes.editableContent}
                  onChange={(e) => (codeRef.current = e.target.value)}
                  onBlur={() => {
                    onSubmit1(sConfig);
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  defaultValue={code}
                ></TextareaAutosize>
              )}
            </div>
            <div
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <Dropdown alignMenu={'right'} icon={'threeDotsVertical'}>
                <MenuList closeAfterClick listMenu={adToolActions} />
              </Dropdown>
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <RichConfigForm ignoreTemplate onSubmit={onSubmit1} data={sConfig} nodeId={nodeId} />
        </AccordionDetails>
      </Accordion>
      <Divider />
    </div>
  );
};

export default Camp0DetailConfiguration;
