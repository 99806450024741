import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { get } from 'lodash';
import React from 'react';
import { useAtomValue } from 'jotai';
import Gradient from 'javascript-color-gradient';

import { getComponent } from '@ep/insight-ui/system/backbone/atom/app';

import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import { ITreeMapDataChart } from '../type';
import { funcConfigs } from './hooks';
import { HeatmapTemplate } from './heatmap-template';
import ChartSize from '../chart-size';
import {
  CHART_CONFIG,
  DEFAULT_GRADIENT_MID_POINT,
  EPSILO_BLUE_COLOR,
  PERSONALIZATION,
} from '@ep/insight-ui/system/helper/constant';

import './style.css';

type Props = {
  data: ITreeMapDataChart[];
  maxValues?: number;
  stateChart: IStateChartValue;
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  height?: number;
  chartConfig?: any;
  backbone?: any;
  chart?: any;
  colKey?: string;
  subTitle?: string;
};

function Heatmap({
  data,
  maxValues,
  stateChart,
  keyChart,
  title = '',
  value = '',
  percent = 0,
  currency = '',
  height,
  chartConfig,
  backbone,
  chart,
  colKey = '',
  subTitle = '',
}: Props) {
  const ChartStateDerived = useAtomValue(React.useRef(getComponent(ChartState)).current);

  const { customHTML } = funcConfigs();

  const divRef = React.useRef(null);
  const ref = React.createRef();

  const configColors = get(chartConfig, ['config', 'color'], []);
  const gradientMidpoint = get(chartConfig, ['config', 'gradientMidpoint'], DEFAULT_GRADIENT_MID_POINT);

  const colors = new Gradient()
    .setColorGradient('#ffffff', ...(configColors.length ? configColors : [EPSILO_BLUE_COLOR]))
    .setMidpoint(Math.max(0, Number(gradientMidpoint)))
    .getColors();

  const formatLabel = get(data, [0, 'formatLabel'], '');
  const options = {
    axes: {
      left: {
        title: '',
        mapsTo: 'label',
        scaleType: 'labels',
      },
      bottom: {
        title: '',
        mapsTo: 'header',
        scaleType: 'labels',
      },
    },
    heatmap: {
      colorLegend: {
        title: '',
      },
    },
    experimental: true,
    height: `${height}px`,
    toolbar: {
      enabled: false,
    },
    color: {
      gradient: {
        enabled: true,
        colors: colors,
      },
    },
    tooltip: {
      customHTML: customHTML(formatLabel),
      truncation: {
        threshold: 100,
      },
      valueFormatter: (value, key) => {
        return value;
      },
    },
    legend: {
      enabled: false,
    },
  };

  const selectedColumns = get(backbone, ['config', PERSONALIZATION, CHART_CONFIG, 'selectedColumns'], []).map(
    (el) => el.label,
  );

  const filteredData = data.filter((el) => selectedColumns.length == 0 || selectedColumns.includes(el.header));

  return (
    <div ref={divRef} style={{ height: '100%' }}>
      <ChartSize size={'large'}>
        <ChartStateDerived
          stateChart={stateChart}
          title={title}
          value={value}
          currency={currency}
          percent={percent}
          handleClickTitle={() => undefined}
          headerType={'analysis'}
          parentRef={divRef}
          colKey={colKey}
          subTitle={subTitle}
        >
          <div style={{ marginTop: '8px' }}>
            <HeatmapTemplate
              data={filteredData}
              options={options}
              refElement={ref}
              metric={chart.metric}
              colKey={colKey}
            />
          </div>
        </ChartStateDerived>
      </ChartSize>
    </div>
  );
}

export default Heatmap;
