import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ChevronMedium = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.73664 0.258176C9.90542 0.430239 10 0.661878 10 0.903175C10 1.14447 9.90542 1.37611 9.73664 1.54818L5.63608 5.72818C5.55501 5.81349 5.4575 5.88142 5.34945 5.92783C5.2414 5.97424 5.12508 5.99818 5.00753 5.99818C4.88998 5.99818 4.77365 5.97424 4.66561 5.92783C4.55756 5.88142 4.46005 5.81349 4.37898 5.72818L0.278422 1.54818C0.190325 1.46243 0.12029 1.35983 0.0724659 1.24646C0.0246416 1.13309 0 1.01126 0 0.888176C0 0.765091 0.0246416 0.643257 0.0724659 0.529889C0.12029 0.416521 0.190325 0.313925 0.278422 0.228175C0.452332 0.0716396 0.680809 -0.00985165 0.914283 0.00138092C1.14776 0.0126135 1.3674 0.115664 1.52555 0.288176L5.00753 3.79818L8.46955 0.258176C8.63909 0.0926383 8.86641 0 9.10309 0C9.33978 0 9.5671 0.0926383 9.73664 0.258176Z" />
  </SvgIcon>
);

export default ChevronMedium;
