import * as React from 'react';
import { get } from 'lodash';
import { Controller } from 'react-hook-form';

import { Box, Modal, makeStyles } from '@material-ui/core';

import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import ButtonActions from './button-actions';
import { useInlineEdit } from './hooks/use-inline-edit';
import FormField from './form-field';
import { ACTION_CATEGORY_TAG, CUSTOM_TAG, INPUT_TYPE_STYLES } from '../../utils/constant';
import CustomTag from './custom-tag';
import { FormulaEditor } from '../../formula-editor';
import { NarrativeEditor } from '../../narrative-editor/narrative-editor';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import clsx from 'clsx';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

const useStyles = makeStyles({
  container: {
    padding: '0 10px',
    minWidth: '320px',
  },
  header: {
    paddingLeft: 0,
    marginBottom: '10px',
  },
  popupModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    background: '#FFFFFF',
    borderRadius: '8px',
    padding: '20px 16px 16px 16px',
  },
  formFields: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '16px',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
  },
});

const ConditionalWrap = ({ condition, wrap, children }) => {
  return condition ? wrap(children) : children;
};

const InlineEdit = React.forwardRef((props, ref) => {
  const classes = useStyles();

  const {
    getValues,
    isSubmitting,
    handleClosed,
    handleSubmit,
    configuration,
    isModal,
    control,
    errorMessage,
    headerTitle,
    rowData,
    initialFocusIndex,
    isEmbeded,
    subtext,
    errors,
  } = useInlineEdit(props);

  const formStyles = React.useMemo(() => {
    const styles = get(configuration, ['defaults', 'form', 'field_configuration'], [])
      .filter((el) => String(el.key).startsWith('styles.'))
      .reduce((carry, { key: k, value: v }) => {
        const style = k.replace('styles.', '');
        carry[style] = v;

        return carry;
      }, {});

    return styles;
  }, [configuration]);

  const tracking = get(props, ['customProps', 'tracking'], '');

  const category = get(props, ['customProps', 'category'], '');

  const styles = React.useMemo(() => {
    const value = (
      get(configuration, ['defaults', 'form', 'field_configuration'], []).find(
        (el) => el.key === INPUT_TYPE_STYLES,
      ) || {
        value: '{}',
      }
    ).value;

    return safeJsonParse(value, value);
  }, [configuration]);

  React.useImperativeHandle(ref, () => ({
    getValues,
  }));

  if (category === ACTION_CATEGORY_TAG) {
    return (
      <Box className={classes.container}>
        <Controller
          name={
            ((configuration.fields || []).find((field) => field.input_type === CUSTOM_TAG) || { field_key: '' })
              .field_key
          }
          control={control}
          render={({ field }) => {
            return (
              <CustomTag
                {...field}
                {...props}
                configuration={(configuration.fields || []).find((field) => field.input_type === CUSTOM_TAG)}
                rowData={rowData}
                errorMessage={errorMessage}
                handleSubmit={handleSubmit}
              />
            );
          }}
        ></Controller>
      </Box>
    );
  }

  return (
    <ConditionalWrap
      condition={isModal}
      wrap={(children) => {
        return (
          <Modal open={props.anchorEl}>
            <Box className={classes.popupModal}>{children}</Box>
          </Modal>
        );
      }}
    >
      <MonitorContainer
        disableTracking={tracking !== 'yes'}
        mId={'inline-form-panel/' + headerTitle}
        mLabel={'Inline form panel: ' + headerTitle}
        component={Box}
        className={clsx(classes.container, props.horizontal && 'horizontal')}
        {...(typeof styles === 'object' ? { style: styles } : {})}
      >
        <form onSubmit={handleSubmit}>
          {headerTitle ? <HeaderList title={headerTitle} variant="h4" className={classes.header} /> : null}
          <div className={classes.formFields} style={{ ...formStyles }}>
            {configuration.fields.map((field, index) => {
              return (
                <FormField
                  key={field.field_key}
                  {...props}
                  configuration={field}
                  control={control}
                  rowData={rowData}
                  initialFocus={initialFocusIndex === index}
                  getValues={getValues}
                  handleSubmit={handleSubmit}
                  errors={errors}
                ></FormField>
              );
            })}
            <FormulaEditor />
            <NarrativeEditor />
          </div>
          {subtext && <span className={classes.subtext}>{subtext}</span>}
          {errorMessage && <span className={classes.textError}>{errorMessage}</span>}
          {!isEmbeded && (
            <ButtonActions
              configuration={configuration.defaults}
              onCancel={handleClosed}
              onSubmit={handleSubmit}
              isSubmitting={isSubmitting}
            />
          )}
        </form>
      </MonitorContainer>
    </ConditionalWrap>
  );
});

export default InlineEdit;
