import { ContainerResponsiveContext, NodeEditContext, useLog } from '@eip/next/lib/main';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';

import { getDefaultAggFunc } from '@ep/insight-ui/system/util/aggregation';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { Box, Button, makeStyles } from '@material-ui/core';
import { produce } from 'immer';
import { get } from 'lodash';
import * as React from 'react';
import { useToast } from '../notifications/hook';
import { NotificationGlobalProvider } from '../notifications/notifications';
import { CompactFactoryProvider } from './compact-factory';
import ExpansionTable from '@ep/insight-ui/elements/table/expansion-table';
import HeaderRight from '@ep/insight-ui/elements/table/table-actions/header-right';
import TableActions from '@ep/insight-ui/elements/table/table-actions/table-actions-2';
import { aggregationAddon } from '@ep/insight-ui/elements/table/table-addons/aggregation';
import { filterValueAddon } from '@ep/insight-ui/elements/table/table-addons/filter-value';
import HeaderActions from '@ep/insight-ui/elements/table/table-actions/header-actions';

import { ComponentList } from '@ep/insight-ui/system/backbone/atom/app';
import ChartLoadingState from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import ChartLoadingState2 from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state2';

import { cellEditor, cellFormat, initHeaderAction, mainButtonComponent } from './table-helper';
import Table from './tablev27';

import { useEditorEnhancement } from '@ep/insight-ui/elements/table/hooks/use-editor-enhancement';

import { Provider } from 'jotai';
import EVisualization from '../evisualization/evisualization';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { useAtomValue, useSetAtom } from 'jotai';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import CategoryVisualization from '../category';

import DropdownViewCompact from '@ep/insight-ui/elements/table/table-actions/dropdown-view-compact';

const log = useLog('lib:table-backbone:table-container');
export interface IConfigTable {
  id?: string;
  configuration: any;
  apiRequest?: any;
  tableType?: string;
  submitRowSelectionOnClosed?: boolean;
  addons?: Record<string, any>;
  callback?: any;
  isCalendarHide?: boolean;
  cellFormat: Record<string, React.Component>;
}
interface TableContainerProps {
  config: IConfigTable;
  changeConfiguration: (value: any) => void;
  selectedRows?: any[];
  blockEid?: string;
  backboneRef?: any;
}
const useStyles = makeStyles(() => ({
  toggle: {},
  wrapperTable: {
    display: 'flex',
    flexDirection: 'column',
  },
  wrapperCompactChart: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    width: 904,
    height: '100%',
  },
  wrapperCompactGallery: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0 12px',
    width: '75vw',
    height: '100%',
  },
}));
const TableContainer: React.FunctionComponent<TableContainerProps> = ({
  config,
  changeConfiguration,
  selectedRows,
  blockEid,
  backboneRef = null,
}: TableContainerProps) => {
  const classes = useStyles();
  // const { enqueueSnackbar } = useSnackbar();
  const { onToastMultiple } = useToast();
  const tableId = useAtomValue(eTableAtom.tableId);

  let isMobileView;
  if (ff.ecompacttable_layout_mobile) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobileView = containerClass === 'eres--small';
  }
  const { isEditMode } = React.useContext(NodeEditContext);

  const uawl = React.useContext(UaWatchlist);
  const enhanceConfig = React.useMemo(() => {
    return produce(config, (draft) => {
      draft.addons = {
        ...filterValueAddon,
        ...config.addons,
        ...aggregationAddon,
        ...(!config.addons || !config.addons['datasource.apiRequest.getTableData']
          ? {
              'datasource.apiRequest.getTableData': (params, originalRequest, backboneInstance) => {
                const session = uawl.currentSession();
                session.addContext({ queryParams: params });
                if (backboneInstance) {
                  // const currentFilter = backboneInstance.getConfig('filter');
                  session.addContext({ currentFilter: backboneInstance.config.filter });
                }
                session.ack('be:request');
                params._uaSessionId = session.id();
                return originalRequest(params);
              },
            }
          : {}),
      };
      if (config.tableType === 'compact') {
        let changeLog = [];
        let initSelectedRow = [];
        draft.addons['rowSelect.init'] = (selectedRows) => {
          initSelectedRow = selectedRows;
        };
        draft.addons['rowSelect.changeLog.add'] = (item: { data; isSelected }[], backbone) => {
          changeLog = changeLog.concat([].concat(item));
        };

        draft.addons['onGetSelectedRow'] = (nativeSelectedRow, backbone) => {
          changeLog;
          nativeSelectedRow;
          initSelectedRow;
          backbone;
          const currentSelectedIds = initSelectedRow.map((r) =>
            backbone.getRowId(r, ['_eipCustomId', 'eipCustomRowId']),
          );
          const currentSelectedRows = [].concat(initSelectedRow);
          changeLog.forEach((l) => {
            const lId = backbone.getRowId(l.data, ['_eipCustomId', 'eipCustomRowId']);
            const isSelected = l.isSelected;
            if (isSelected && currentSelectedIds.indexOf(lId) === -1) {
              currentSelectedRows.push(l.data);
              currentSelectedIds.push(lId);
            } else if (!isSelected && currentSelectedIds.indexOf(lId) > -1) {
              const i = currentSelectedIds.indexOf(lId);
              currentSelectedRows.splice(i, 1);
              currentSelectedIds.splice(i, 1);
            }
          });

          return currentSelectedRows;
        };

        draft.addons['datasource.apiRequest.getTableData'] = (params, originalRequest) => {
          return originalRequest(params).then((res) => {
            if (params.pagination.page === 1) {
              changeLog = [];
            }
            return res;
          });
        };
      }
    });
  }, []);

  const backbone = useTableBackbone(enhanceConfig, changeConfiguration);
  const handleChangePin = (field) => {
    // const headers = backbone.getHeaders();
  };
  const cellFormatRegistry = React.useMemo(() => get(config, 'cellFormat', cellFormat), []);
  const cellActionRegistry = React.useMemo(() => get(config, 'cellAction', {}), []);

  const headerAction = initHeaderAction(backbone, handleChangePin, isEditMode, blockEid);

  React.useEffect(() => {
    backbone.registerMainButtonComponent(mainButtonComponent);
    backbone.registerHeaderAction(headerAction);
    // backbone.registerCellFormat(get(config, 'cellFormat', cellFormat));
    backbone.registerCellEditor(cellEditor);
    backbone.registerNotification({
      // success: (msg: string) => enqueueSnackbar(msg, { variant: 'success' }),
      success: (title: string, msg: string[]) => onToastMultiple({ title, messages: msg, variant: 'success' }),
      error: (title: string, msg: string[]) => onToastMultiple({ title, messages: msg, variant: 'error' }),
    });
    backbone.init();
  }, []);

  const headers = backbone.getHeaders();
  const pagination = backbone.getPagination();
  const sort = backbone.getConfig('sort');
  const pinnedColumns = backbone.getConfig('pinnedColumn');
  const columnWidth = backbone.getConfig('columnWidth');
  const titleTable = backbone.getConfig('title');
  const createdTime = backbone.getCreatedTime ? backbone.getCreatedTime() : new Date().getTime();

  const rowsGroup = backbone.getConfig('groupBy.columns', []);

  React.useEffect(() => {
    backbone.updateSelectedRows(selectedRows);
    backbone.addon('rowSelect.init', (i) => i)(selectedRows);
  }, [selectedRows.length]);

  React.useEffect(() => {
    backbone.getCallback('onBackboneReady')(backbone, config);
    if (config.addons) {
      Object.keys(config.addons).forEach((k) => {
        backbone.registerAddon(k, config.addons[k]);
      });
    }
  }, []);

  React.useEffect(() => {
    if (backboneRef) {
      backboneRef.current = backbone;
    }
  }, [backbone]);

  useEditorEnhancement(config, backbone);

  log('table headers >>>>', headers);

  const generateTable = (typeTable: string) => {
    const aggregations = () => {
      const configAggregation = backbone.getConfig('groupBy.aggregations', {});
      const obj = {};
      headers.forEach((col) => {
        const propertyType = get(backbone.getCellConfig(col.field), 'propertyType', '');
        const func = get(getDefaultAggFunc(propertyType), 'id', '');
        obj[col.field] = { func };
      });
      return { ...obj, ...configAggregation };
    };

    const visualizationType = backbone.getConfig('visualizationType');

    if (visualizationType === 'category') {
      if (typeTable === 'compact') {
        return (
          <CompactFactoryProvider>
            <MonitorContainer
              mId={`compact-chart/${tableId}`}
              mLabel={`Compact chart: ${config.configuration.title}`}
              component={Box}
              component={'div'}
              className={classes.wrapperCompactGallery}
            >
              <HeaderActions />
              <CategoryVisualization backbone={backbone} config={config} />
            </MonitorContainer>
          </CompactFactoryProvider>
        );
      }
      return (
        <CompactFactoryProvider>
          <ExpansionTable
            defaultExpanded={true}
            label={titleTable}
            className={classes.toggle}
            noSpacer
            headerRight={config.isCalendarHide ? null : <HeaderRight />}
          >
            <Box pb={1} className={classes.wrapperTable}>
              <TableActions
                hasDimensionViewBy={!get(config, ['configuration', 'chartConfig', 'config', 'timelineCohorts'], false)}
              />
              <CategoryVisualization backbone={backbone} config={config} />
            </Box>
          </ExpansionTable>
        </CompactFactoryProvider>
      );
    }

    if (visualizationType === 'chart') {
      if (typeTable === 'compact') {
        return (
          <CompactFactoryProvider>
            <MonitorContainer
              mId={`compact-chart/${tableId}`}
              mLabel={`Compact chart: ${config.configuration.title}`}
              component={Box}
              component={'div'}
              className={classes.wrapperCompactChart}
            >
              <HeaderActions />
              <EVisualization
                backbone={backbone}
                config={config}
                chartConfig={get(config, 'chartConfig', {})}
                columns={headers}
                sort={sort}
                pinnedColumns={pinnedColumns}
                columnWidth={columnWidth}
                rowsGroup={rowsGroup}
                pagination={pagination}
                allowSelect={true}
                selectedRows={selectedRows}
                cellFormatRegistry={cellFormatRegistry}
                cellActionRegistry={cellActionRegistry}
                headerActionRegistry={headerAction}
                onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                onColumnReorder={backbone.changeColumnOrder}
                onChangePagination={backbone.changePagination}
                hiddenColumns={backbone.getConfig('hiddenColumn')}
                aggregations={aggregations()}
              />
            </MonitorContainer>
          </CompactFactoryProvider>
        );
      }
      const timelineCohorts = get(config, ['configuration', 'chartConfig', 'config', 'timelineCohorts'], false);
      const chartType = get(config, ['configuration', 'chartConfig', 'config', 'chartType'], 'column');
      return (
        <CompactFactoryProvider>
          <ExpansionTable
            defaultExpanded={true}
            label={titleTable}
            className={classes.toggle}
            noSpacer
            headerRight={config.isCalendarHide ? null : <HeaderRight />}
          >
            <Box pb={1} className={classes.wrapperTable}>
              <TableActions hasDimensionViewBy={!timelineCohorts || chartType == 'treemap'} />
              <EVisualization
                backbone={backbone}
                config={config}
                chartConfig={get(config, 'chartConfig', {})}
                columns={headers}
                sort={sort}
                pinnedColumns={pinnedColumns}
                columnWidth={columnWidth}
                rowsGroup={rowsGroup}
                pagination={pagination}
                allowSelect={true}
                selectedRows={selectedRows}
                cellFormatRegistry={cellFormatRegistry}
                cellActionRegistry={cellActionRegistry}
                headerActionRegistry={headerAction}
                onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                onColumnReorder={backbone.changeColumnOrder}
                onChangePagination={backbone.changePagination}
                hiddenColumns={backbone.getConfig('hiddenColumn')}
                aggregations={aggregations()}
              />
            </Box>
          </ExpansionTable>
        </CompactFactoryProvider>
      );
    }

    // if (
    //   ['Competitiveness details', 'Category performance ', 'Keyword performance'].includes(backbone.getConfig('title'))
    // ) {
    //   return <h1>Table</h1>;
    // }
    switch (typeTable) {
      case 'minimal':
        return (
          <CompactFactoryProvider>
            <MonitorContainer
              mId={`compact-table/${tableId}`}
              mLabel={`Compact table: ${config.configuration.title}`}
              component={Box}
              width={'100%'}
              height={'100%'}
              className={classes.wrapperTable}
            >
              <Table
                typeTable={'minimal'}
                backbone={backbone}
                columns={headers}
                sort={sort}
                pinnedColumns={pinnedColumns}
                rowsGroup={rowsGroup}
                pagination={pagination}
                allowSelect={true}
                selectedRows={selectedRows}
                cellFormatRegistry={cellFormatRegistry}
                cellActionRegistry={cellActionRegistry}
                headerActionRegistry={headerAction}
                onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                onColumnReorder={backbone.changeColumnOrder}
                onChangePagination={backbone.changePagination}
                aggregations={aggregations()}
              />
            </MonitorContainer>
          </CompactFactoryProvider>
        );
      case 'compact':
        return (
          <CompactFactoryProvider>
            <MonitorContainer
              mId={`compact-table/${tableId}`}
              mLabel={`Compact table: ${config.configuration.title}`}
              component={Box}
              width={'904px'}
              height={'100%'}
              className={classes.wrapperTable}
            >
              {config.configuration.allowSelectedView ? (
                <Box mb={'0.5em'} position={'static'}>
                  <DropdownViewCompact />
                </Box>
              ) : null}

              <HeaderActions />
              <Table
                typeTable={'compact'}
                backbone={backbone}
                columns={headers}
                sort={sort}
                pinnedColumns={pinnedColumns}
                rowsGroup={rowsGroup}
                pagination={pagination}
                allowSelect={true}
                selectedRows={selectedRows}
                cellFormatRegistry={cellFormatRegistry}
                cellActionRegistry={cellActionRegistry}
                headerActionRegistry={headerAction}
                onColumnResize={(colWidth) => backbone.changeConfig('columnWidth', colWidth)}
                onColumnReorder={backbone.changeColumnOrder}
                onChangePagination={backbone.changePagination}
                aggregations={aggregations()}
              />
            </MonitorContainer>
          </CompactFactoryProvider>
        );
      default:
        return (
          <CompactFactoryProvider>
            <ExpansionTable
              defaultExpanded={true}
              label={titleTable}
              className={classes.toggle}
              noSpacer
              headerRight={config.isCalendarHide ? null : <HeaderRight />}
            >
              <Box pb={1} maxHeight={810} className={classes.wrapperTable}>
                <TableActions hasDimensionViewBy={true} />
                <Table
                  backbone={backbone}
                  columns={headers}
                  sort={sort}
                  pinnedColumns={pinnedColumns}
                  rowsGroup={rowsGroup}
                  pagination={pagination}
                  allowSelect={true}
                  selectedRows={selectedRows}
                  cellFormatRegistry={cellFormatRegistry}
                  cellActionRegistry={cellActionRegistry}
                  headerActionRegistry={headerAction}
                  onColumnResize={(colWidth) => {
                    backbone.changeConfig('columnWidth', colWidth);
                  }}
                  onColumnReorder={backbone.changeColumnOrder}
                  onChangePagination={backbone.changePagination}
                  hiddenColumns={backbone.getConfig('hiddenColumn')}
                  aggregations={aggregations()}
                />
              </Box>
            </ExpansionTable>
          </CompactFactoryProvider>
        );
    }
  };

  return (
    <TableBackboneContext.Provider value={backbone}>
      {createdTime ? generateTable(backbone.getConfig('tableType', '')) : null}
    </TableBackboneContext.Provider>
  );
};

export const makeTable = ({
  blockEid,
  config = {
    apiRequest: {},
    configuration: {},
    tableType: '',
    callback: {},
    addons: {},
  },
  changeConfiguration = (config: any) => undefined,
  selectedRows = [],
  linkedObjects = {
    actions: {},
    advancedFilter: {},
  },
  backboneRef = null,
}: {
  blockEid: string;
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
  linkedObjects?: {
    actions: any;
    advancedFilter: any;
  };
  backboneRef?: any;
}) => {
  return (
    <Provider
      initialValues={[
        [eTableAtom.tableId, blockEid],
        [
          ComponentList,
          [
            {
              src: ChartLoadingState,
              custom: ChartLoadingState2,
            },
          ],
        ],
        [eTableAtom.linkedObjects, linkedObjects],
      ]}
    >
      <NotificationGlobalProvider>
        <EditableTableContainer
          key={config.id}
          config={config}
          changeConfiguration={changeConfiguration}
          selectedRows={selectedRows}
          blockEid={blockEid}
          backboneRef={backboneRef}
        />
      </NotificationGlobalProvider>
    </Provider>
  );
};

function EditableTableContainer({ config, changeConfiguration: changeConfig, selectedRows, blockEid, backboneRef }) {
  const { isEditMode } = React.useContext(NodeEditContext);

  if (isEditMode) {
    return (
      <TableContainer
        key={config.configuration._editorId}
        config={config}
        changeConfiguration={changeConfig}
        selectedRows={selectedRows}
        blockEid={blockEid}
        backboneRef={backboneRef}
      />
    );
  } else {
    return (
      <TableContainer
        key={config.id}
        config={config}
        changeConfiguration={changeConfig}
        selectedRows={selectedRows}
        blockEid={blockEid}
        backboneRef={backboneRef}
      />
    );
  }
}
