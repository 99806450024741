import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';
import { Box, Button, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import * as React from 'react';
import CircleLoading from '../loading/circle-loading';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { Typography } from '@material-ui/core';
import Icon from '@ep/insight-ui/icons/Icon';
import { ETableBlock } from '@ep/insight-ui/system/block/etable/etable-next';
import { produceColumns } from '@ep/insight-ui/sw/etable/service';
import { calculateValueGetter } from '@ep/insight-ui/sw/etable/util';
import { get } from 'lodash';

const useStyles = makeStyles({
  formControl: {
    marginBottom: 0,
    '&.select': {},
    '&.input': {},
  },
  text: {
    fontStyle: 'normal',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '&.cancel': {
      background: 'transparent',
      marginRight: '8px',
    },
  },
  section: {
    marginTop: '24px',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    ...(ff.integrate_storefront_ui_v2
      ? {
          borderBottom: '1px solid #C2C7CB',
        }
      : {
          borderRadius: '8px',
        }),
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translate(0, -50%)',
  },
  buttonCancel: {
    color: '#8C98A4',
  },
  mobileContent: {
    padding: ff.integrate_storefront_ui_v2 ? '20px 25px 0 25px' : '15px 0 0 0',
  },
  buttonIntegrate: {
    width: '95%',
    height: '40px',
    marginTop: ff.integrate_storefront_ui_v2 ? '15px' : '30px',
    marginLeft: '12px',
  },
  buttonBack: {
    width: '100%',
    height: '40px',
    marginTop: '15px',
    background: '#F6F7F8',
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '8px',
    color: '#253746',
    marginTop: '16px',
  },
  tableContainer: {
    '& > div': {
      width: '100%',
    },
  },
  message: {
    marginBottom: '8px',
    paddingLeft: '4px',
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
  link: {
    color: '#007aff',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const StorefrontIntegrateForm = ({
  loading = false,
  onBackStep,
  onClose,
  onNextStep,
  initialValues,
  messageErrorCallback = '',
  fetchStorefrontInformation,
  storefrontIntegrationConfig,
  handleIntegrateStorefront,
  subtext,
  messageError,
}: any) => {
  const classes = useStyles();
  const backboneRef = React.useRef(null);
  const [redirectLink, setRedirectLink] = React.useState('');
  const source = React.useRef(null);
  const [rowSelected, setRowSelected] = React.useState(null);

  const reloadTable = () => {
    backboneRef.current?.reloadData('table');
  };

  const receiveMessage = (evt) => {
    if (evt.source === source.current && evt.data.success == 'success') {
      reloadTable();
      source.current.close();
      source.current = null;
    }
  };

  const openLink = (link) => {
    if (source.current == null || source.current.closed) {
      source.current = window.open(link);
    } else {
      source.current.focus();
    }
  };

  React.useEffect(() => {
    window.addEventListener('message', receiveMessage);

    return () => {
      window.removeEventListener('message', receiveMessage);
    };
  }, [source.current]);

  const nodeData = {
    customAttributes: storefrontIntegrationConfig,
  };

  const integratedStore = React.useMemo(() => {
    return ['SUCCESSFUL', 'INTEGRATED'].includes(rowSelected?.['storefront.status']);
  }, [rowSelected]);

  const addons = {
    'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
      const columns = produceColumns(backbone.config);
      const result = await fetchStorefrontInformation(initialValues.sessionId);

      return new Promise(async (resolve) => {
        const headers = result?.length > 0 ? Object.keys(result[0]).map((i) => `storefront.${i}`) : [];
        let rows = Array.isArray(result)
          ? result.map((row) => {
              return Object.entries(row).reduce((carry, [k, v]) => {
                return {
                  ...carry,
                  [`storefront.${k}`]: v,
                };
              }, {});
            })
          : [];

        rows = await calculateValueGetter({
          rows,
          columns,
          drillDowns: [],
          resourceMetric: [],
          groupedFields: [],
          formulaUpstream: null,
          config: backbone.config,
        });
        resolve({
          data: {
            rows,
            headers,
          },
          eTableContext: {
            columns,
            groupBy: {},
            pinnedColumn: [],
          },
        });
      });
    },
    'rowSelect.changeLog.add': ([{ data, isSelected }]) => {
      setRowSelected(isSelected ? data : null);
    },
  };

  const integrateStorefront = () => {
    setRedirectLink('');
    if (handleIntegrateStorefront && !integratedStore) {
      const selectedRows = backboneRef.current.getSelectedRows();
      const storefrontId = get(selectedRows, [0, 'storefront.shop_id'], '');
      if (storefrontId) {
        handleIntegrateStorefront(storefrontId).then((res) => {
          if (res.success) {
            backboneRef.current.reloadData('table');
          }
          if (res?.data?.link_open_api) {
            setRedirectLink(res?.data?.link_open_api || '');
          }
        });
      }
    } else if (integratedStore) {
      window.open('/page/3edd4b9c-fd8c-40c2-8cd3-c6d997ed8426/default');
    }
  };

  return (
    <Grid container direction={'column'}>
      <Grid item xs={12}>
        <span className={`${classes.text} title`}>Confirm storefront to integrate</span>
      </Grid>
      <div className={classes.tableContainer}>
        <ETableBlock nodeData={nodeData} systemConfig={nodeData} addons={addons} backboneRef={backboneRef} />
      </div>
      {messageErrorCallback && (
        <Grid item xs={12}>
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <Icon type={'error'} style={{ width: '9px', height: '9px' }} />
            <Typography variant="body5" style={{ color: '#D4290D' }}>
              {messageErrorCallback}
            </Typography>
          </Box>
        </Grid>
      )}
      {messageError && (
        <div className={classes.message}>
          <span style={{ fontSize: '12px', color: '#d4290d' }}>
            <Icon type={'error'} style={{ width: '9px', height: '9px' }} /> {messageError}
          </span>
        </div>
      )}
      {subtext && (
        <div className={classes.message}>
          <Icon type={'ic/mi:circle-information/#253746'} />{' '}
          <span style={{ fontSize: '14px', color: '#253746' }}>{subtext}</span>
        </div>
      )}
      {redirectLink ? (
        <div className={classes.message}>
          <span>
            The shop can integrate OPEN-API. Please click{' '}
            <span
              onClick={() => {
                setRedirectLink('');
                openLink(redirectLink);
              }}
              className={classes.link}
            >
              link
            </span>{' '}
            to validate agreement to add OPEN-API.
          </span>
        </div>
      ) : null}
      <Grid item xs={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} back`}
              onClick={onBackStep}
            >
              <span className={`${classes.text} textButton`}>Back</span>
            </Button>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ columnGap: '4px' }}>
            {loading ? <CircleLoading /> : ''}
            <Button
              color="secondary"
              variant="contained"
              component="span"
              className={`${classes.button} cancel`}
              onClick={onClose}
            >
              <span className={`${classes.text} textButton`}>Cancel</span>
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              onClick={() => backboneRef.current?.reloadData('table')}
              disabled={loading || !backboneRef.current}
            >
              Reload storefront
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={integrateStorefront}
              disabled={loading || !backboneRef.current}
            >
              {integratedStore ? 'Go to Unified campaign' : 'Integrate'}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
export default StorefrontIntegrateForm;
