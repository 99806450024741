/* eslint-disable react/prop-types */
import Icon from '@ep/insight-ui/icons/Icon';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import * as _ from 'lodash';
import * as React from 'react';
import WrapperFormat, { IPropsFormat } from './wrapper-format';
const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    paddingTop: '3px',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  menu: {
    position: 'absolute',
    right: 0,
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  status: {
    display: 'flex',
    height: '10px',
    alignItems: 'flex-start',
  },
  statusCompact: {
    height: '22px',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  statusContainer: {
    width: 'unset',
    columnGap: '1px',
  },
}));

export interface IValueConnectionStatusFormat {
  label: string;
  status: string;
  hashtag?: string;
  subtext?: string;
}
const ConnectionStatusFormat = (props: IPropsFormat<IValueConnectionStatusFormat>) => {
  const classes = useStyles();
  // const { hashtag = '', subtext = '' } = props.value;
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const value = _.get(props, 'value', null);
  const status = _.get(props, ['value', 'status'], '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');

  return (
    <WrapperFormat typeTable={typeTable} cellAction={cellAction} node={node} value={value} isGrouped={isGrouped}>
      <Grid container alignItems="center" wrap={'nowrap'} className={`${classes.statusContainer} ${classes.height100}`}>
        <Grid
          item
          className={clsx(classes.status, typeTable === 'compact' && classes.statusCompact)}
          style={{ marginRight: !_.isEmpty(status) ? '2px' : '' }}
        >
          <span style={{ display: 'flex' }}>
            <Icon type={status === 1 ? 'errorStatus' : 'goingStatus'} />
          </span>
        </Grid>
        <Grid item style={{ width: '100%' }} direction={'column'} className={classes.height100}>
          <Grid
            container
            alignItems={'center'}
            direction={'column'}
            wrap={'nowrap'}
            className={clsx(classes.height100, classes.alignItemsStart)}
          >
            {/*hashtag*/}
            <Grid item xs={12} className={classes.height100}>
              <span className={classes.hashtag}></span>
            </Grid>
            {/* label */}
            <Grid item xs={12} className={classes.height100}>
              <span className={classes.label}>{status == 1 ? 'Dis-connect' : 'Connect'}</span>
            </Grid>
            {/* subtext */}
            <Grid item xs={12} className={classes.height100}>
              <span className={classes.subtext}></span>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default ConnectionStatusFormat;
