const createDebug = require('debug');

export function useLog(namespace: string) {
  const logInfo = createDebug(namespace);
  const logError = createDebug(namespace+':error');

  return {
    info: logInfo,
    log: logInfo,
    error: logError,
  };
}
