import * as React from 'react';
import FormControlTemplate from './form-control-template';
import AutoCompleteInput, {
  AutocompleteProps,
} from '@ep/insight-ui/elements/form-control/multiple-controls-select/auto-complete-input';
interface IProps extends AutocompleteProps {
  label: string;
  classNameContainer?: string;
}
const MultipleSelection = ({ label = '', classNameContainer, ...rest }: IProps) => {
  return (
    <FormControlTemplate label={label} className={`${classNameContainer}`}>
      <AutoCompleteInput {...rest} />
    </FormControlTemplate>
  );
};

export default MultipleSelection;
