import { atom, Atom } from 'jotai';
import { atomFamily, selectAtom } from 'jotai/utils';

type ComponentMap = { src: React.ComponentType; custom: React.ComponentType };

export const ComponentList = atom<ComponentMap[]>([]);

export const components = atom(
  (get, set, args: any) => {
    const coms = get(ComponentList);

    const fIndex = coms.findIndex((c) => c.src === args.src);
    if (fIndex > -1) {
      coms[fIndex] = args.custom;
    } else {
      coms.push({ src: args.src, custom: args.custom });
    }

    set(ComponentList, coms);
  },
);

const readonlyComponent = atom((get) => get(ComponentList));

export const getComponent = (src: React.ComponentType) => {
  return selectAtom(readonlyComponent, (s) => {
    const foundOne = s.find((i) => i.src === src);
    if (foundOne) return foundOne.custom;
    else return src;
  });
};
