import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';

export const dataMenu: DropdownMenuDataType[][] = [
  [
    {
      title: 'Edit',
      icon: 'pencil',
      onClick: () => undefined,
    },
    {
      title: 'Duplicate',
      icon: 'duplicate',
      iconSize: '16px',
      onClick: () => undefined,
    },
    {
      title: 'Remove',
      icon: 'delete',
      onClick: () => undefined,
    },
  ],
  [
    {
      title: 'Properties',
      icon: 'properties',
      onClick: () => undefined,
    },
    ,
    {
      title: 'Customize',
      icon: 'setting',
      onClick: () => undefined,
    },
    {
      title: 'Sort',
      icon: 'sort',
      onClick: () => undefined,
    },
    {
      title: 'Filter',
      icon: 'filter',
      onClick: () => undefined,
    },
  ],
  [
    {
      title: 'Export',
      icon: 'export',
      onClick: () => undefined,
    },
  ],
];

export const dataMenuCheckbox = JSON.stringify([
  {
    label: 'All',
    value: 'all',
    checked: false,
  },
  {
    label: 'Option 1',
    value: 'option1',
    checked: false,
  },
  {
    label: 'Option 2',
    value: 'option2',
    checked: false,
  },
  {
    label: 'Option 3',
    value: 'option3',
    checked: false,
  },
  {
    label: 'Option 4',
    value: 'option4',
    checked: false,
  },
]);

export const dataMenuRadio = JSON.stringify([
  {
    label: 'Radio 1',
    value: 'radio1',
    checked: false,
  },
  {
    label: 'Radio 2',
    value: 'radio2',
    checked: false,
  },
  {
    label: 'Radio 3',
    value: 'radio3',
    checked: false,
  },
  {
    label: 'Radio 4',
    value: 'radio4',
    checked: false,
  },
]);

export const dataMenuSizeScreen: Array<Array<DropdownMenuDataType>> = [
  [
    {
      title: '1440 px',
      icon: 'screen',
      onClick: () => undefined,
    },
    {
      title: '1920 px',
      icon: 'screen',
      onClick: () => undefined,
    },
  ],
];

export const pageOption = JSON.stringify([
  {
    label: '10 pages',
    value: '10',
    checked: true,
  },
  {
    label: '20 pages',
    value: '20',
    checked: false,
  },
  {
    label: '50 pages',
    value: '50',
    checked: false,
  },
  {
    label: '100 pages',
    value: '100',
    checked: false,
  },
]);
