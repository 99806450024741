import * as React from 'react';

import type { ICellRendererParams } from '@ag-grid-community/core';

import { Box } from '@material-ui/core';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import LoadingComponent from '@ep/insight-ui/elements/loading/loading-component';

import { ButtonLoadmore } from '@ep/insight-ui/elements/table/pagination';

export function InlineLoadmore(props: ICellRendererParams) {
  const backbone = React.useContext(TableBackboneContext);
  const [isLoading, setIsLoading] = React.useState(false);

  const pagination = backbone.getPagination();
  const { _havingMore: haveMore } = props.data;
  const changePagination = (limit) => {
    setIsLoading(true);
    const { _route: route, _groupColumn: groupColumn, _lastRow: lastRow } = props.data;
    backbone.changeConfig('system.groupByPagination', [{ group: groupColumn, limit: Number(lastRow) + Number(limit) }]);

    props.api.refreshServerSideStore({
      route: route,
    });
  };

  // React.useEffect(() => {
  //   const needRequest = props.data._needRequest;
  //   const hasGetPaginationAddon = backbone.hasAddon('system.getPaginationInfo');

  //   const fetchPagination = async () => {
  //     setIsLoading(true);
  //     const params = get(backbone, ['config', 'tableParams'], {});
  //     const cloneParams = cloneDeep(params);
  //     const drillDown = get(params, ['groupBy', 'drillDowns'], [])[0];
  //     const additionalFilter = {
  //       ...drillDown,
  //       dataType: 'string',
  //       operator: 'is',
  //     };
  //     cloneParams.filter = {
  //       combinator: 'and',
  //       ...cloneParams.filter,
  //       filters: [...(cloneParams.filter?.filters || []), additionalFilter],
  //     };
  //     delete cloneParams.groupBy;

  //     const cacheName = btoa(unescape(encodeURIComponent(`${props.tableId}:drilldown:${drillDown?.['field']}:${drillDown?.['value']}`)));
  //     if (!cachedPagination[cacheName]) {
  //       cachedPagination[cacheName] = await backbone.addon('system.getPaginationInfo', () => Promise.resolve(null))(
  //         cloneParams,
  //         backbone,
  //       );
  //     }
  //     const res = cachedPagination[cacheName];
  //     const total = get(res, ['pagination', 'total'], 0);
  //     const limit = get(cloneParams, ['pagination', 'limit'], 0);
  //     if (total > limit) {
  //       setIsLoading(false);
  //     } else {
  //       const { _route: route } = props.data;
  //       props.api.applyServerSideTransaction({
  //         route: route,
  //         remove: [props.node.data],
  //       });
  //     }
  //     setIsRequested(true);
  //   };

  //   if (needRequest && hasGetPaginationAddon && !isRequested) {
  //     fetchPagination();
  //   }
  // }, [props, backbone, isRequested]);

  React.useEffect(() => {
    if (haveMore === false) {
      const { _route: route } = props.data;
      window.setTimeout(() => {
        props.api.applyServerSideTransaction({
          route: route,
          remove: [props.node.data],
        });
      }, 0);
    }
  }, [haveMore, props.data]);

  return (
    <>
      {haveMore !== false ? (
        <Box sx={{ height: '100%', width: '100%', marginLeft: '40px', display: 'flex', alignItems: 'center' }}>
          <LoadingComponent loading={isLoading}>
            <ButtonLoadmore
              limit={pagination.limit}
              onClick={() => changePagination(pagination.limit)}
            ></ButtonLoadmore>
          </LoadingComponent>
        </Box>
      ) : null}
    </>
  );
}
