import * as lastUpdatedAt from './last-updated-at';
import * as view from './view';
import * as groupBy from './groupby';
import * as tableId from './table-id';
import * as headerUpdates from './header-update';
import * as linkedObjects from './linked-objects';

export const eTableAtom = {
  ...lastUpdatedAt,
  view,
  groupBy,
  ...tableId,
  ...headerUpdates,
  ...linkedObjects,
};
