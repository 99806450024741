import CloseFiled from '@ep/insight-ui/icons/svg/CloseField';

import Eyes from '@ep/insight-ui/icons/svg/Eyes';
import Error from '@ep/insight-ui/icons/svg/Error';
import Correct from '@ep/insight-ui/icons/svg/Correct';
import { textFieldStyle } from '@ep/insight-ui/lib/epsilo-theme';
import { Box, debounce, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField, { BaseTextFieldProps } from '@material-ui/core/TextField';
import clsx from 'clsx';
import * as React from 'react';
import * as _ from 'lodash';
import { CreateCSSProperties } from '@material-ui/styles';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { FormikErrors } from 'formik';

interface PropsStyle {
  inputNoShadow: boolean;
  line: number;
  type: string;
}

const useStyles = makeStyles(() => ({
  textStyle: (props: PropsStyle) => ({
    ...(textFieldStyle as CreateCSSProperties),
    width: '100%',
    '& button': {
      opacity: 1,
      padding: 0,
      '& svg': {
        height: '12px',
        width: '12px',
      },
    },
    '& input': {
      padding: '0 10px',
      height: '32px',
      caretColor: '#006EC6',
      width: '100%',
    },
    '& .eip1-MuiOutlinedInput-multiline': {
      padding: '10px 10px',
      minHeight: '32px',
      caretColor: '#006EC6',
      width: '100%',
      alignItems: 'flex-start',
      '& textarea': {
        lineHeight: '20px',
        height: props.line > 0 ? `${props.line * 20}px !important` : '20px !important',
      },
    },
    '& .eip1-MuiButtonBase-root': {
      width: 'auto',
    },
    '& .eip1-MuiFormHelperText-contained,  & .Mui-error.eip1-MuiFormHelperText-contained': {
      fontSize: '10px',
      margin: '8px 0 0 0',
      '& svg': {
        height: '9px',
        width: '9px',
        marginRight: '5px',
      },
    },
    '& input:-webkit-autofill,& input:-webkit-autofill:hover,& input:-webkit-autofill:focus,& input:-webkit-autofill:active':
      {
        '-webkit-text-fill-color': ' black !important',
        '-webkit-background-clip': 'text',
      },
  }),
  isCorrect: textFieldStyle.isCorrect as CreateCSSProperties,
  fakeDisplay: {
    padding: '8px 50px 8px 8px',
    zIndex: 9,
    top: '0px',
    left: '0px',
    borderRadius: '4px',
    position: 'absolute',
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background: 'white',
    borderColor: '#C2C7CB',
    cursor: 'pointer',
    border: '1px solid',
    '&:hover': {
      borderColor: 'rgb(0, 110, 198)',
    },
  },
  clearBtn: {
    zIndex: 10,
    display: 'flex',
    '& button': {
      width: '100%',
      backgroundColor: 'transparent',
      '&:hover, &:active': {
        backgroundColor: 'transparent',
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
  },
  errorBackground: {
    background: '#FFF9F9',
  },
}));

export interface ITextField extends BaseTextFieldProps {
  placeholder?: string;
  defaultValue?: string;
  disabled?: boolean;
  error?: boolean;
  correct?: boolean;
  helperText?: string | string[];
  errorMessage?: string | FormikErrors<any> | FormikErrors<any>[] | string[];
  className?: string;
  classNamePaperInput?: string;
  onChangeText?: (v: string) => void;
  onClear?: () => void;
  line?: number;
  noShadow?: boolean;
  alwaysShowHeperText?: boolean;
  multiHelperText?: boolean;
  suffix?: string | JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const TextFieldCustom = ({
  placeholder,
  defaultValue = '',
  disabled,
  error,
  correct,
  helperText,
  className,
  classNamePaperInput = '',
  onChangeText = () => undefined,
  onClear = () => undefined,
  noShadow = false,
  line = -1,
  alwaysShowHeperText = false,
  errorMessage,
  multiHelperText = false,
  ...res
}: ITextField) => {
  const defaultV = React.useMemo(() => defaultValue, [defaultValue]);
  const [value, setValue] = React.useState(defaultV);
  const [isPasswordShow, setIsPasswordShow] = React.useState(res.type === 'password' ? false : true);
  React.useEffect(() => {
    if (defaultV !== value) {
      setValue(defaultV);
    }
  }, [defaultValue]);
  const [file, setFile] = React.useState<Blob>(null);
  const classes = useStyles({ inputNoShadow: noShadow, line, type: res.type });
  //Handle error and correct text
  const handleText = (error, correct, helperText) => {
    if (_.isArray(helperText)) {
      if (!_.isEmpty(errorMessage) && !multiHelperText) {
        return (
          <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
            <Icon type={'closeCircle'} />
            <Typography variant="body5">{errorMessage[0]}</Typography>
          </Box>
        );
      }
      return helperText.map((text, index) => {
        if (alwaysShowHeperText) {
          let icon: IconType = 'success';
          if (Array.isArray(errorMessage) && errorMessage.includes(text)) {
            icon = 'closeCircle';
          }
          return (
            <Box key={String(index)} display={'flex'} alignItems={'center'} mt={index === 0 ? '0px' : '4px'}>
              <Icon type={icon} />
              <Typography variant="body5">{text}</Typography>
            </Box>
          );
        }
        if (Array.isArray(errorMessage) && errorMessage.includes(text)) {
          return (
            <Box
              key={String(index)}
              display={'flex'}
              alignItems={'center'}
              gridGap={'5px'}
              mt={index === 0 ? '0px' : '2px'}
            >
              <Icon type={'closeCircle'} color={'inherit'} style={{ color: '#8C98A4' }} />
              <Typography variant="body5">{text}</Typography>
            </Box>
          );
        }
      });
    }
    if (error && !_.isEmpty(errorMessage)) {
      return (
        <Box display={'flex'} alignItems={'center'} gridGap={'5px'}>
          <Icon type={'error'} style={{ width: '9px', height: '9px' }} />
          <Typography variant="body5" style={{ color: '#D4290D' }}>
            {errorMessage[0]}
          </Typography>
        </Box>
      );
    }

    return (
      <React.Fragment>
        {error ? <Error /> : correct ? <Correct /> : null}
        {helperText}
      </React.Fragment>
    );
  };
  const handleSetText = React.useCallback(debounce(onChangeText, 100), []);
  React.useEffect(() => {
    handleSetText(value);
  }, [value]);
  React.useEffect(() => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        handleSetText(String(reader.result));
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }, [file]);

  const handleChange = (e) => {
    setValue(e.target.value);
  };
  const handleChangeFile = (e) => {
    setFile(e.target.files[0]);
  };
  const handleClick = () => {
    inputRef.current.children[0].children[0].click();
  };

  const inputRef = React.useRef<HTMLDivElement>();

  return (
    <Box position="relative" style={{ width: '100%' }}>
      <TextField
        ref={inputRef}
        className={clsx(classes.textStyle, correct && classes.isCorrect, className)}
        placeholder={placeholder}
        variant="outlined"
        onChange={res.type === 'file' ? handleChangeFile : handleChange}
        value={res.type === 'file' ? '' : value}
        disabled={disabled}
        error={error}
        helperText={handleText(error, correct, helperText)}
        autoComplete={res.type === 'password' ? 'new-password' : 'off'}
        InputProps={{
          endAdornment: res.suffix ? (
            res.suffix
          ) : res.type === 'password' ? (
            <IconButton
              aria-label="toggle password visibility"
              tabIndex={-1}
              onClick={() => {
                setIsPasswordShow(!isPasswordShow);
              }}
            >
              {isPasswordShow ? <Icon type={'hidePassword'} /> : <Icon type={'eyes'} />}
            </IconButton>
          ) : res.type !== 'password' && value ? (
            <IconButton
              aria-label="toggle password visibility"
              tabIndex={-1}
              onClick={() => {
                setValue('');
                onClear();
              }}
            >
              <CloseFiled />
            </IconButton>
          ) : null,
          className: `${classNamePaperInput} ${error && !_.isArray(helperText) && classes.errorBackground}`,
        }}
        {...res}
        type={res.type === 'password' && isPasswordShow ? 'text' : res.type}
      />
      {res.type === 'file' && (
        <Box className={clsx(classes.fakeDisplay)} position="absolute" top="0" left="0" onClick={handleClick}>
          {file && <Box>{file.name}</Box>}
        </Box>
      )}
      {res.type === 'file' && (
        <Box position="absolute" top="0" right="0" width={32} height={32} className={clsx(classes.clearBtn)}>
          <IconButton
            aria-label="toggle password visibility"
            onClick={() => {
              setFile(null);
              onClear();
            }}
          >
            {file && <Icon type="close" size={'12px'} />}
            {!file && <Icon type="import" size={'12px'} onClick={handleClick} />}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};
