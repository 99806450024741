import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import EDiscreteChart from './discrete-chart';
import { NotificationGlobalProvider } from '@ep/insight-ui/elements/notifications/notifications';

class DiscreteChart implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    return (
      <NotificationGlobalProvider>
        <div style={{ padding: '12px' }}>
          <EDiscreteChart />
        </div>
      </NotificationGlobalProvider>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  return <h2>Chart configuration goes here.</h2>;
}

export default {
  blockType: 'eDiscreteChart',
  label: 'DiscreteChart',
  blockComponent: DiscreteChart,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 18,
  },
};
