import { EIP_CONSTANT } from '@eip/next/lib/main';
import moment from 'moment';

export const INTRADAY_DASHBOARD_CONFIG = {
  title: 'Intraday',
  dimension: ['country', 'marketplace', 'storefront', 'ad_tool'],
  metric: [
    'cost',
    'roas',
    'ads_gmv',
    'direct_gmv',
    'total_item_sold',
    'total_order',
    'direct_percent_gmv_over_total',
    'direct_percent_item_sold_over_total',
  ],
  attribute: [],
  dateRange: {
    dateFrom: moment().format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().format(EIP_CONSTANT.DATE_FORMAT),
  },
  endpoint: {
    GET_METRIC_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/metrics.jsp',
    GET_CHART_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/charts.jsp',
  },
  useDataSource: false,
  primaryKeys: [],
  tableType: 'intraday',
  hiddenFilter: {
    currency: 'USD',
    timing: 'hourly',
  },
  mapping: {
    // ============= DIMENSION =============
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'country_code',
      valueGetter: {
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'marketplace_code',
      valueGetter: {
        id: 'tool_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront_id',
      valueGetter: {
        id: 'storefront_id',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'tool_code',
      valueGetter: {
        id: 'tool_code',
      },
    },

    // ============= ATTRIBUTE =============

    // ============= METRIC =============
    impression: {
      title: 'Impression',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'impression',
      },
    },
    click: {
      title: 'Click',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'click',
      },
    },
    cost: {
      title: 'Cost',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'cost',
      },
    },
    item_sold: {
      title: 'Ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'item_sold',
      },
    },
    direct_item_sold: {
      title: 'Direct ads item sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'direct_item_sold',
      },
    },
    gmv: {
      title: 'Ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'gmv',
      },
    },
    direct_gmv: {
      title: 'Direct ads GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'direct_gmv',
      },
    },
    ads_order: {
      title: 'Ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'ads_order',
      },
    },
    direct_ads_order: {
      title: 'Direct ads order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'direct_ads_order',
      },
    },
    roas: {
      title: 'ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'roas',
      },
    },
    direct_roas: {
      title: 'Direct ROAS',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'direct_roas',
      },
    },
    cir: {
      title: 'CIR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'cir',
      },
    },
    cpc: {
      title: 'CPC',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'cpc',
      },
    },
    ctr: {
      title: 'CTR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'ctr',
      },
    },
    cr: {
      title: 'CR',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'float',
      valueGetter: {
        value: 'cr',
      },
    },
    total_gmv: {
      title: 'Total GMV',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'total_gmv',
      },
    },
    total_item_sold: {
      title: 'Items sold',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'total_item_sold',
      },
    },
    total_order: {
      title: 'Total order',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'total_order',
      },
    },
    percent_gmv_over_total: {
      title: '%Ads GMV contribution',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'percent_gmv_over_total',
      },
    },
    direct_percent_gmv_over_total: {
      title: '%Direct ads GMV contribution',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'direct_percent_gmv_over_total',
      },
    },
    percent_item_sold_over_total: {
      title: '%Ads item sold contribution',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'percent_item_sold_over_total',
      },
    },
    direct_percent_item_sold_over_total: {
      title: '%Direct ads item sold contribution',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'direct_percent_item_sold_over_total',
      },
    },
  },
  view: {
    id: 'all',
    name: 'All',
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
  system: {
    hiddenComponents: ['sort', 'search', 'majorButton'],
  },
};
