import * as React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { get } from 'lodash';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import clsx from 'clsx';
import { DropdownCell } from '../etable2/dropdown-cell';
import Icon from '@ep/insight-ui/icons/Icon';
import ItemAction from './item-action';
import StyledTooltip from '../tooltip/styled';
import { useAtomValue } from 'jotai';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import ConditionalWrap from '../next-elements/conditional-wrap';
import { getChannelCellUpdate } from '@ep/insight-ui/sw/channel';
import { tableTooltip } from '@ep/insight-ui/chartlib/helper';
import MiniChart from '../mini-chart';

const useStyles = makeStyles(() => ({
  container: {
    borderRadius: '8px',
    boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;',
    padding: '12px',
    display: 'grid',
    gridTemplateColumns: 'repeat(10, 1fr)',
    position: 'relative',
  },
  menu: {
    position: 'absolute',
    right: 8,
    top: 8,
    '& button': {
      height: '32px',
      width: '32px',
    },
  },
  content: {
    display: 'flex',
    columnGap: '4px',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  descriptionIcon: {
    width: '16px',
    maxWidth: '16px',
  },
  tooltip: {
    '& p': {
      margin: 0,
      padding: '2px 4px',
    },
  },
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  tableTooltip: tableTooltip,
}));

class Errorboundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span>Something went wrong.</span>;
    }

    return this.props.children;
  }
}

const CONTAINER = 'container';
const ACTION = 'action';

const excludeKeys = [ACTION, CONTAINER];

const ChartRender = ({ item, tableId, value, colId }) => {
  const [chartData, setChartData] = React.useState({
    labels: [],
    datasets: [],
    chartPeriod: '',
  });
  const [chartPeriod, setChartPeriod] = React.useState('');
  const metricDetail = get(value, ['metricDetail'], {});
  const currency = get(item, ['currency'], '');

  React.useEffect(() => {
    const updateData = (event) => {
      try {
        const cellUpdateByRowId = event.data.update;
        Object.values(cellUpdateByRowId).forEach((el) => {
          el.forEach(({ keys, updatePath, keyId, data }) => {
            if (colId == updatePath[0]) {
              const isMatched = Object.entries(keys).every(([k, v]) => item[k] == v);
              if (isMatched) {
                if (data.dataValues) {
                  setChartData(data.dataValues);
                }
                if (data.chartPeriod) {
                  setChartPeriod(data.chartPeriod);
                }
              }
            }
          });
        });
      } catch {}
    };
    getChannelCellUpdate(tableId).addEventListener('message', updateData);

    return () => {
      getChannelCellUpdate(tableId).removeEventListener('message', updateData);
    };
  }, []);

  return <MiniChart data={chartData} chartPeriod={chartPeriod} metricDetail={metricDetail} currency={currency} />;
};

const CategoryItem = ({ backbone, item, tableId }: any) => {
  const classes = useStyles();
  const mapping = backbone.getConfig('mapping', {});
  const columnOrder = backbone.getConfig('columnOrder');
  const [isHovering, setIsHovering] = React.useState(false);
  const [showThreeDots, setShowThreeDots] = React.useState(false);

  const linkedObjects = useAtomValue(eTableAtom.linkedObjects);

  const [actions, setActions] = React.useState([]);
  const containerStyles = React.useMemo(() => {
    const type = get(mapping, [CONTAINER, 'propertyType'], 'dimension');
    if (!backbone.getConfig(type, []).includes(CONTAINER)) return {};
    const containerEData = get(item, ['eData', CONTAINER], {});
    const containerStaticValue = get(mapping, [CONTAINER, 'staticValue'], {});
    const containerValue = {
      ...containerStaticValue,
      ...containerEData,
    };
    return Object.entries(containerValue)
      .filter(([key]) => String(key).startsWith('styles.'))
      .reduce((carry, [k, v]) => {
        const style = k.replace('styles.', '');
        carry[style] = v;

        return carry;
      }, {});
  }, []);

  const getActions = () => {
    const type = get(mapping, [ACTION, 'propertyType'], 'dimension');
    const acts = get(linkedObjects, ['actions', ACTION], []);
    if (!backbone.getConfig(type, []).includes(ACTION)) return;
    const actions = backbone.addon('cell.customActions2', () => [])(
      { field: ACTION, actions: acts },
      {
        data: { ...item, ...get(item, ['eData', ACTION], {}) },
      },
    );

    setActions(actions);
  };

  React.useEffect(() => {
    getActions();
  }, []);

  const contentRender = React.useMemo(() => {
    const dimension = backbone.getConfig('dimension');
    const attribute = backbone.getConfig('attribute');
    const metric = backbone.getConfig('metric');
    const columns = []
      .concat(dimension, attribute, metric)
      .sort((a, b) =>
        !columnOrder.includes(b) || !columnOrder.includes(a) ? -1 : columnOrder.indexOf(a) - columnOrder.indexOf(b),
      );

    return columns.reduce((carry, i) => {
      const type = get(mapping, [i, 'propertyType'], 'dimension');
      if (excludeKeys.includes(i) || !backbone.getConfig(type, []).includes(i)) return carry;
      const eData = get(item, ['eData', i], {});
      const staticValue = get(mapping, [i, 'staticValue'], {});
      const value = {
        ...staticValue,
        ...eData,
      };
      const columnDescription = toValue(get(mapping, [i, 'columnDescription'], ''), { ...value, ...item });
      const styles = Object.entries(value)
        .filter(([key]) => String(key).startsWith('styles.'))
        .reduce(
          (carry, [k, v]) => {
            const style = k.replace('styles.', '');
            carry[style] = v;

            return carry;
          },
          {
            gridColumn: '1 / 11',
          },
        );

      const cellFormat = get(mapping, [i, 'cellFormat'], '');
      const acts = get(linkedObjects, ['actions', i], []);
      const actions = backbone.addon('cell.customActions2', () => [])(
        { field: i, actions: acts },
        {
          data: { ...item, ...get(item, ['eData', i], {}) },
        },
      );
      if (['oneDimension', 'oneMetric'].includes(cellFormat)) {
        const label = get(eData, ['label'], get(eData, ['value'], ''));
        const tooltip = get(eData, ['tooltip'], '');
        const startIcon = get(eData, ['startIcon'], '');
        const endIcon = get(eData, ['endIcon'], '');
        const labelElement = String(label).includes('</span>') ? (
          <div dangerouslySetInnerHTML={{ __html: label }} className={classes.label}></div>
        ) : (
          <div className={classes.label}>{label}</div>
        );
        const startIconSize = get(eData, ['startIconSize'], '14px');
        const endIconSize = get(eData, ['endIconSize'], '14px');
        const element = (
          <div className={classes.content} style={styles}>
            <ConditionalWrap
              condition={tooltip}
              wrap={(children) => {
                return (
                  <StyledTooltip
                    title={
                      <div className={classes.tooltip}>
                        {tooltip ? <p className={classes.tooltipContent}>{tooltip}</p> : null}
                      </div>
                    }
                    placement={'right'}
                  >
                    {children}
                  </StyledTooltip>
                );
              }}
            >
              <div className={classes.content}>
                {startIcon ? (
                  <span className={classes.icon}>
                    <Icon type={startIcon} size={startIconSize} />
                  </span>
                ) : null}
                {labelElement}
                {columnDescription && (
                  <StyledTooltip
                    title={
                      <div className={classes.tooltip}>
                        {columnDescription ? <p className={classes.tooltipContent}>{columnDescription}</p> : null}
                      </div>
                    }
                    placement={'right'}
                  >
                    <Grid xs={2} className={classes.descriptionIcon} container alignItems={'center'}>
                      <Icon type={'ic/mi:circle-information/#737373'} />
                    </Grid>
                  </StyledTooltip>
                )}
                {endIcon ? (
                  <span className={classes.icon}>
                    <Icon type={endIcon} size={endIconSize} />
                  </span>
                ) : null}
              </div>
            </ConditionalWrap>
          </div>
        );
        if (actions.length > 0) {
          return carry.concat(
            <div style={styles}>
              <ItemAction backbone={backbone} actions={actions} data={item} />
            </div>,
          );
        }
        return carry.concat(element);
      }
      if (['tractionElasticity', 'tractionElasticity1'].includes(cellFormat)) {
        const element = (
          <div className={classes.content} style={styles}>
            <ChartRender item={item} tableId={tableId} value={value} colId={i} />
          </div>
        );

        return carry.concat(element);
      }
      return carry;
    }, []);
  }, [columnOrder, item]);

  return (
    <div
      className={classes.container}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      style={containerStyles}
    >
      <Errorboundary>{contentRender}</Errorboundary>
      {isHovering || showThreeDots ? (
        <MonitorContainer
          mId="cell-edot"
          mLabel="Cell edot"
          component={'div'}
          className={clsx(classes.menu, 'dropdown_menu')}
          role={'menubar'}
        >
          {actions.length > 0 && (
            <DropdownCell
              cellAction={actions}
              props={{ value: get(item, ['eData', ACTION, 'value'], ''), node: { data: item } }}
              showThreeDots={isHovering || showThreeDots}
              setShowThreeDots={() => setShowThreeDots(false)}
              onClosed={() => setShowThreeDots(false)}
              onOpened={() => setShowThreeDots(true)}
            />
          )}
        </MonitorContainer>
      ) : null}
    </div>
  );
};

export default CategoryItem;
