import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const CopyLink = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.00813 7.99979C5.90146 7.99979 5.00407 8.89527 5.00407 9.99958C5.00407 11.1039 5.90062 11.9985 7.00813 11.9985V13.9992C4.79395 13.9992 3 12.2082 3 9.99958C3 7.79012 4.79395 6 7.00813 6H8.99294C11.2071 6 13.0011 7.79012 13.0019 9.99958V10.0155C13.0019 10.5593 12.5608 10.9995 12.0158 10.9995H11.9999C11.4491 10.9995 11.0045 10.5492 11.0138 9.99958C11.0138 8.89527 10.1164 7.99979 9.00969 7.99979H7.00813Z"
      fill={`${props.color ? props.color : '#253746'}`}
    />
    <path
      d="M12.9918 7.99979V6C15.2052 6 17 7.79012 17.0008 10.0004C17.0008 12.2099 15.206 14 12.9927 14H11.007C8.79285 14 6.9989 12.209 6.9989 10.0004V9.99206C6.9989 9.44492 7.44341 9.00052 7.99256 9.00052C8.54423 9.00052 8.99041 9.44826 8.98623 9.99875C8.98623 11.1031 9.88362 11.9994 10.9903 11.9994H12.9918C14.0985 11.9994 14.9959 11.1039 14.9959 9.99958C14.9959 8.89527 14.0985 7.99979 12.9918 7.99979Z"
      fill={`${props.color ? props.color : '#253746'}`}
    />
  </SvgIcon>
);

export default CopyLink;
