import * as React from 'react';
import { cloneDeep, get, isEmpty, set, uniq } from 'lodash';
import moment from 'moment';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { TableCompactFactoryContext } from '@ep/insight-ui/elements/etable2/compact-factory';
import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { getDateRangeFromOption } from '@ep/insight-ui/elements/form-control/calendar/calendar-input/hooks/use-calendar-input';
import { NodeEditContext } from '@eip/next/lib/main';

import {
  INPUT_TYPE_ADDITIONAL_DATA,
  INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
  INPUT_TYPE_COMPACT_OPERATOR,
  INPUT_TYPE_COMPACT_SOURCE_FIELD,
  INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER,
  INPUT_TYPE_COMPACT_TARGET_FIELD,
  INPUT_TYPE_CUSTOM_FILTERS,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_ETABLE_CONFIG,
  INPUT_TYPE_FILTER_FIELD,
  INPUT_TYPE_LINK_GROUPBY_FILTER,
  INPUT_TYPE_SEARCH_HINT,
  INPUT_TYPE_SYSTEM_FILTERS,
  INPUT_TYPE_VIEW,
} from '../../utils/constant';
import { enhancedETableConfig2 } from '../../../migration';
import { useSetAtom } from 'jotai';
import { editorScript } from '../../atom/editor-script';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { splitComma } from '@ep/insight-ui/system/helper/functions';
import { calculateValueGetter } from '@ep/insight-ui/sw/etable/service';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { safeJsonParse } from '@ep/insight-ui/system/util/safe-json-parse';

const CompactTable = (props) => {
  const { display: displayTable } = React.useContext(TableCompactFactoryContext);
  const backboneContext = React.useContext(TableBackboneContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const lastUpdated = React.useRef(moment().valueOf());
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const { onToastMultiple } = useToast();

  React.useEffect(() => {
    if (props.anchorEl) {
      const etableConfig = get(props, ['payload', INPUT_TYPE_ETABLE_CONFIG], {});
      const sourceFields = get(props, ['payload', INPUT_TYPE_COMPACT_SOURCE_FIELD], []);
      const targetFields = get(props, ['payload', INPUT_TYPE_COMPACT_TARGET_FIELD], []);
      const filterField = get(props, ['payload', INPUT_TYPE_FILTER_FIELD], {});
      const searchHint = get(props, ['payload', INPUT_TYPE_SEARCH_HINT], '');
      const additionalData = get(props, ['payload', INPUT_TYPE_ADDITIONAL_DATA], []);
      const ignoreCalendar = get(props, ['payload', INPUT_TYPE_COMPACT_IGNORE_CALENDAR], false);
      const linkGroupByFilter = get(props, ['payload', INPUT_TYPE_LINK_GROUPBY_FILTER], false) == true;
      const displayAs = get(props, ['payload', INPUT_TYPE_DISPLAY_AS], 'etable');
      const view = get(props, ['payload', INPUT_TYPE_VIEW], '');
      const isPageAction = get(props, ['payload', 'isPageAction'], false);
      const getCustomCellActions = get(props, ['customProps', 'getCustomCellActions'], () => undefined);
      const callback = get(props, ['customProps', 'callback'], () => undefined);

      const inheritSourceFilter = get(props, ['payload', INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER], 'no');

      const dateRange = isPageAction ? get(etableConfig, ['dateRange'], {}) : backboneContext.getConfig('dateRange');
      const calendarOption = isPageAction
        ? get(etableConfig, ['calendarOption'], '')
        : backboneContext.getConfig('calendarOption');

      const viewInfo = get(etableConfig, 'views', []).find(({ id }) => view === id) || get(etableConfig, ['view'], {});
      const linkedBlockEid = get(etableConfig, 'blockEid', '');
      const customConfig = { ...etableConfig, view: viewInfo };

      const { config, linkedObjects } = enhancedETableConfig2({
        customConfig: customConfig,
        systemConfig: {},
        nodeEditContext,
        blockEid: linkedBlockEid,
        setOpenCodeEditor,
        lastUpdated,
        getCustomCellActions,
        onToastMultiple,
      });

      const clone = cloneDeep(config);

      clone.configuration.dateRange = isPageAction ? getDateRangeFromOption(calendarOption, dateRange) : dateRange;
      clone.configuration.calendarOption = calendarOption;
      clone.configuration.tableType = 'compact';
      if (clone.configuration.system?.hiddenComponents) {
        clone.configuration.system.hiddenComponents = clone.configuration.system?.hiddenComponents.filter(
          (el) => el !== 'search',
        );
      }
      clone.configuration.searchHint = searchHint || 'Search...';

      // const mappings = get(etableConfig, ['mapping'], {});
      // const dimensionField = uniq(
      //   Object.keys(mappings)
      //     .filter((key) => mappings[key]?.propertyType === 'dimension')
      //     .map((key) => {
      //       const valueGetter = get(mappings, [key, 'valueGetter'], {});
      //       return valueGetter.id || valueGetter.value;
      //     })
      //     .filter((el) => !!el),
      // );
      const dimensionFieldValue = typeof sourceFields === 'string' ? sourceFields.split(',') : sourceFields;
      const dimensionFieldKey = typeof targetFields === 'string' ? targetFields.split(',') : targetFields;

      const prevAddon = get(clone, ['addons', 'datasource.getRowsParams'], null);
      const prevGetTableAddon = get(clone, ['addons', 'datasource.apiRequest.getTableData'], () => null);

      const rowData = get(props, ['data', 'node', 'data'], {});
      const customFiltersConfig = get(props, ['payload', INPUT_TYPE_CUSTOM_FILTERS], null);

      let customFilters = customFiltersConfig
        ? String(customFiltersConfig).startsWith('=')
          ? toValue(customFiltersConfig, rowData)
          : rowData[customFiltersConfig]
        : null;

      if (typeof customFilters == 'string' && customFilters) {
        customFilters = safeJsonParse(customFilters, []).map((i) => {
          return {
            ...i,
            static: true,
            isHidden: true,
            isLocked: true,
          };
        });
      }
      if (customFilters && Array.isArray(customFilters) && customFilters.length) {
        clone.configuration.filter = [].concat(clone.configuration.filter || []).concat(customFilters);
      }

      clone.addons = {
        ...clone.addons,
        'datasource.getRowsParams': async ({ params }, currentConfig, backbone) => {
          try {
            const prevParams = prevAddon ? prevAddon({ params }, currentConfig, backbone) : params;
            const selectedRows = !isPageAction ? backboneContext.getSelectedRows() : [];
            const rowData = get(props, ['data', 'node', 'data'], {});
            const bulkRows = uniq(selectedRows.concat(rowData));
            let inheritFilters = [];
            if (inheritSourceFilter === 'yes' && !isPageAction) {
              const specialFilters = backboneContext.getConfig('specialFilters') || {};
              const mapping = backbone.getConfig('mapping');
              inheritFilters = Object.entries(specialFilters)
                .map(([k, filter]) => {
                  const tableField = mapping[k];
                  if (!tableField) return null;
                  return {
                    dataType: filter.dataType,
                    field: tableField.filterField,
                    operator: filter.queryType,
                    value: filter.queryValue,
                  };
                })
                .filter((el) => el);
            }

            const fieldOperator = backbone.getConfig('fieldOperator') || 'contains';

            const systemFiltersConfig = get(props, ['payload', INPUT_TYPE_SYSTEM_FILTERS], []);

            const systemFilters = bulkRows.map((row) => {
              return systemFiltersConfig.reduce(
                (carry, systemFilter) => {
                  const field = systemFilter[INPUT_TYPE_COMPACT_SOURCE_FIELD];
                  const operator = systemFilter[INPUT_TYPE_COMPACT_OPERATOR];
                  const isFormula = String(field).startsWith('=');
                  const value = isFormula ? toValue(field, row, true) : row[field];
                  if (value !== undefined && value !== null) {
                    carry.filters.push({
                      field: systemFilter[INPUT_TYPE_COMPACT_TARGET_FIELD],
                      operator: operator || 'is',
                      value: value,
                      dataType: typeof value,
                    });
                  }
                  return carry;
                },
                {
                  combinator: 'AND',
                  filters: [],
                },
              );
            });
            console.log('12345systemFiltersConfig', { systemFiltersConfig, systemFilters });

            const cloneParams = cloneDeep({
              ...prevParams,
              filter: {
                combinator: 'AND',
                ...prevParams.filter,
                filters: [
                  ...(inheritFilters || []),
                  ...(prevParams.filter?.filters || []),
                  ...(filterField && !isEmpty(backbone.getConfig('search'))
                    ? [
                        {
                          combinator: fieldOperator.includes('not') ? 'and' : 'or',
                          filters: String(backbone.getConfig('search'))
                            .split('\n')
                            .reduce((carry, search) => {
                              if (splitComma(filterField).length > 1) {
                                filterField.split(',').forEach((i) => {
                                  carry.push({
                                    field: i,
                                    operator: fieldOperator,
                                    value: search,
                                    dataType: 'string',
                                  });
                                });
                              } else {
                                carry.push({
                                  field: filterField,
                                  operator: fieldOperator,
                                  value: search,
                                  dataType: 'string',
                                });
                              }
                              return carry;
                            }, []),
                        },
                      ]
                    : []),
                  ...dimensionFieldValue.reduce((accum, field, index) => {
                    const rowData = get(props, ['data', 'node', 'data', field], null);
                    const bulkRows = uniq(selectedRows.map((el) => el[field]).concat(rowData)).filter(
                      (i) => i !== null,
                    );
                    if (!bulkRows.length) return accum;
                    return accum.concat({
                      field: dimensionFieldKey[index],
                      operator: 'IN',
                      value: bulkRows,
                      dataType: typeof bulkRows[0],
                    });
                  }, []),
                  ...(systemFiltersConfig?.length > 0
                    ? [
                        {
                          combinator: 'OR',
                          filters: systemFilters,
                        },
                      ]
                    : []),
                  ...(linkGroupByFilter
                    ? get(backboneContext, ['config', 'groupBy', 'columns'], [])
                        .map((i) => {
                          const valueGetter = get(backboneContext, ['config', 'mapping', i, 'valueGetter'], {});
                          const field = valueGetter?.id || valueGetter?.value;
                          return {
                            field: field,
                            operator: 'is',
                            value: get(rowData, [field], null),
                            dataType: 'string',
                          };
                        })
                        .filter((i) => i.value)
                    : []),
                ],
              },
              sort: prevParams.sort?.length
                ? params.sort
                : backbone.getConfig('search')
                ? [{ field: `LENGTH({${filterField.split(',')?.[0]}})`, sort: 'ASC' }]
                : [],
            });

            if (ignoreCalendar) {
              delete cloneParams.from;
              delete cloneParams.to;
            }

            return cloneParams;
          } catch (e) {
            return params;
          }
        },
        'datasource.apiRequest.getTableData': async (params, originRequest, backbone) => {
          const prevResult = await prevGetTableAddon(params, originRequest, backbone);
          const fieldOperator = backbone.config['fieldOperator'] || 'contains';
          const search = backbone.config['search'] || '';
          const keyAdditionalData = (additionalData || []).find((i) => i.key == '_key' && i.value);

          let isAdded = false;
          search
            .split('\n')
            .map((i) => (i ? String(i).trim() : ''))
            .filter((i) => !!i)
            .forEach((searchInput) => {
              const isMatched = (prevResult?.rows || []).every((row) => row[keyAdditionalData?.value] != searchInput);

              if (
                (additionalData || []).length > 1 &&
                isMatched &&
                searchInput &&
                !fieldOperator?.includes('not') &&
                params?.pagination?.page == 1
              ) {
                isAdded = true;
                const headers = params.attributes.concat(params.metrics);
                const newRow = headers.reduce((carry, header) => {
                  const overrideField = additionalData.find((i) => i.key == header);
                  if (!overrideField) carry[header] = null;
                  else carry[header] = toValue(overrideField.value, { ...rowData, _search: searchInput });
                  return carry;
                }, {});
                prevResult.rows.unshift(newRow);
              }
            });

          if (isAdded) {
            const calculatedValueGetter = await calculateValueGetter({
              rows: prevResult.rows,
              columns: prevResult?.eTableContext?.columns,
              groupedFields: get(params, 'groupBy.dimensions', []),
              drillDowns: get(params, 'groupBy.drillDowns', []),
              resourceMetric: [],
              formulaUpstream: null,
              config: backbone.config,
            });
            set(prevResult, ['data', 'rows'], calculatedValueGetter);
          }

          return prevResult;
        },
      };
      clone.configuration.visualizationType = displayAs;
      clone.configuration.tableId = clone.configuration.blockEid;

      const hiddenComponents = get(clone, ['configuration', 'system', 'hiddenComponents'], []);
      if (displayAs === 'chart') {
        set(clone, ['configuration', 'system', 'hiddenComponents'], uniq([...hiddenComponents, 'search', 'expansion']));
      }

      if (ignoreCalendar) {
        set(clone, ['configuration', 'system', 'hiddenComponents'], uniq([...hiddenComponents, 'timeline']));
      }

      callback([
        'compact',
        {
          config: clone,
          open: true,
        },
      ]);

      const tableRender = makeTable({ blockEid: linkedBlockEid, config: clone, linkedObjects });
      displayTable({
        anchorEl: props.anchorEl,
        tableConfig: { config: clone },
        tableEl: tableRender,
        visible: true,
        onClosed() {
          props.setAnchorEl(null);
          callback([
            'compact',
            {
              config: clone,
              open: false,
            },
          ]);
        },
      });
    }
  }, [props.setAnchorEl]);
  return null;
};

export default CompactTable;
