import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Eyes = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.5 6.37545e-05C5.21225 -0.020326 3.94873 0.351342 2.87712 1.06575C1.80551 1.78016 0.976469 2.80353 0.5 4.00006C0.976469 5.1966 1.80551 6.21997 2.87712 6.93438C3.94873 7.64879 5.21225 8.02045 6.5 8.00006C7.78775 8.02045 9.05127 7.64879 10.1229 6.93438C11.1945 6.21997 12.0235 5.1966 12.5 4.00006C12.0235 2.80353 11.1945 1.78016 10.1229 1.06575C9.05127 0.351342 7.78775 -0.020326 6.5 6.37545e-05ZM6.5 6.00006C6.10444 6.00006 5.71776 5.88277 5.38886 5.663C5.05996 5.44324 4.80362 5.13088 4.65224 4.76543C4.50087 4.39998 4.46126 3.99785 4.53843 3.60988C4.6156 3.22192 4.80608 2.86556 5.08579 2.58585C5.36549 2.30614 5.72186 2.11566 6.10982 2.03849C6.49778 1.96132 6.89991 2.00093 7.26537 2.1523C7.63082 2.30368 7.94318 2.56003 8.16294 2.88892C8.3827 3.21782 8.5 3.6045 8.5 4.00006C8.5 4.5305 8.28929 5.0392 7.91421 5.41428C7.53914 5.78935 7.03043 6.00006 6.5 6.00006Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Eyes;
