import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const AddLink = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="2" width="7" height="9" rx="1" />
    <path d="M4 5C3.44772 5 3 5.44772 3 6V13C3 13.5523 3.44772 14 4 14H9C9.55228 14 10 13.5523 10 13C10 12.4477 9.55228 12 9 12L5 12V6C5 5.44772 4.55228 5 4 5Z" />
  </SvgIcon>
);

export default AddLink;
