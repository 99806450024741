import { EIP_CONSTANT } from '@eip/next/lib/main';

export const STOREFRONT_CONFIG = {
  title: 'Storefront management',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getStorefrontData',
  },
  primaryKeys: ['STOREFRONT.id', 'MARKETPLACE.marketplace_code', 'COUNTRY.country_code', 'WORKSPACE.id', 'COMPANY.id'],
  system: {
    hiddenComponents: ['search', 'timeline'],
  },
  mainButton: {
    text: 'New storefront',
    type: 'component',
    componentName: 'StorefrontIntegration',
  },
  requestHiddenField: {},
  mapping: {
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        complementId: 'STOREFRONT.id',
        subtext: 'STOREFRONT.id',
        id: 'STOREFRONT.id',
      },
    },
    connectStatus: {
      title: 'Connection Status',
      propertyType: 'attribute',
      cellFormat: 'connectionStatusFormat',
      valueGetter: {
        status: 'STOREFRONT.cookie_state',
      },
    },
    actionNeeded: {
      title: 'Action needed',
      propertyType: 'attribute',
      cellFormat: 'actionNeededFormat',
      valueGetter: {
        value: 'STOREFRONT.id',
        cookieState: 'STOREFRONT.cookie_state',
        marketplaceName: 'MARKETPLACE.marketplace_name',
      },
    },
    createdBy: {
      title: 'Created By',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.created_by_user_name',
      valueGetter: {
        label: 'STOREFRONT.created_by_user_name',
      },
    },
    createdAt: {
      title: 'Created At',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'date',
      filterField: 'STOREFRONT.created_at',
      valueGetter: {
        label: 'STOREFRONT.created_at',
      },
    },
    updatedBy: {
      title: 'Updated By',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.updated_by_user_name',
      valueGetter: {
        label: 'STOREFRONT.updated_by_user_name',
      },
    },
    updatedAt: {
      title: 'Updated At',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'date',
      filterField: 'STOREFRONT.updated_at',
      valueGetter: {
        label: 'STOREFRONT.updated_at',
      },
    },
    company: {
      title: 'Company',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COMPANY.name',
      valueGetter: {
        label: 'COMPANY.name',
        id: 'COMPANY.id',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    workspace: {
      title: 'Workspace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'WORKSPACE.name',
      valueGetter: {
        label: 'WORKSPACE.name',
        id: 'WORKSPACE.id',
      },
    },
  },
};
