import { createDataComparePlanTable, ICompareItem, ICompareRow } from './subscription-plan-table/compare-item';
import { createDataSubscriptionTable, ISubscriptionRow } from './subscription-plan-table/subscription-components';

export const subscription_Head: Array<ISubscriptionRow> = [
  createDataSubscriptionTable(
    'Base Price',
    {
      value: 0,
      type: 'currency',
      per: ['store'],
    },
    {
      value: 69,
      type: 'currency',
      per: ['store', 'month'],
    },
    {
      value: 'Contact sale for pricing',
    },
  ),
  createDataSubscriptionTable(
    'Additional User',
    null,
    {
      value: 9,
      type: 'currency',
      per: ['user', 'month'],
    },
    {
      value: 'Contact sale for pricing',
    },
  ),
  createDataSubscriptionTable(
    'Take-rate on Ad',
    null,
    {
      value: 8,
      type: 'percent',
    },
    {
      value: 'Contact sale for pricing',
    },
  ),
];
export const subscription_Body: Array<ICompareItem> = [
  {
    label: 'REPORTS & COMMUNICATION',
    rows: [
      createDataComparePlanTable({ label: 'Historical Data' }, '2 weeks', '6 months', '18 months'),
      createDataComparePlanTable({ label: 'Covered Metrics' }, '20+', '20+', '50+'),
      createDataComparePlanTable({ label: 'Custom Metrics' }, null, null, '#'),
      createDataComparePlanTable({ label: 'Customized Dashboard' }, null, null, '#'),
    ],
  },
  {
    label: 'MARKETPLACE ADVERTISING',
    rows: [
      createDataComparePlanTable({ label: 'Campaign Management' }, '#', '#', '#'),
      createDataComparePlanTable({ label: 'Control budget by campaign' }, null, '#', '#'),
    ],
  },
  {
    label: 'HYPER AUTOMATION',
    rows: [
      createDataComparePlanTable({ label: 'Programmatic Script' }, '#', '#', '#'),
      createDataComparePlanTable({ label: 'Script Suggestion' }, null, null, '#'),
    ],
  },
  {
    label: 'COLLABORATION',
    rows: [
      createDataComparePlanTable({ label: 'Real-time collaboration with live presences' }, '#', '#', '#'),
      createDataComparePlanTable({ label: 'Collaborative workspace' }, null, '#', '#'),
      createDataComparePlanTable({ label: 'Approval process' }, null, null, '#'),
    ],
  },
  {
    label: 'DATA AGGREGATION',
    rows: [
      createDataComparePlanTable(
        { label: 'Aggregation interval' },
        'Up to 30 minutes',
        'Up to 20 minutes',
        'Up to 10 minutes',
      ),
    ],
  },
  {
    label: 'SUPPORT',
    rows: [
      createDataComparePlanTable({ label: 'Priority support' }, null, '#', '#'),
      createDataComparePlanTable({ label: 'Dedicated success manager' }, null, null, '#'),
      createDataComparePlanTable({ label: 'Custom contract & invoicing' }, null, null, '#'),
      createDataComparePlanTable({ label: 'Access new features early' }, null, null, '#'),
    ],
  },
];
