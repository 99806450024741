import * as React from 'react';

import { Box, List, ListItem, makeStyles, Popover, Button, Grid } from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';

import { Calendar } from 'react-multi-date-picker';

import { usePeriodUpdate } from './hooks/use-period-update';
import { calendarStyle } from '@ep/insight-ui/elements/form-control/calendar/helper';
import CalendarTimeInput from '@ep/insight-ui/elements/form-control/calendar/calendar-input/calendar-time-input';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { CALENDAR_WEEKDAYS } from '@ep/insight-ui/system/helper/constant';

const useStyles = makeStyles({
  container: {
    minWidth: '200px',
  },
  activeButton: {},
  defaultButton: {},
  groupButton: {
    marginTop: '16px',
    '& .eip1-MuiButton-label': {
      lineHeight: '20px',
    },
    '& button + button': {
      marginLeft: '8px',
    },
  },
  calendar: () => ({
    ...(calendarStyle as CreateCSSProperties),
    '&.rmdp-shadow': {
      boxShadow: 'none',
    },
  }),
  paper: {
    width: '500px',
    padding: '16px',
  },
});

const PeriodUpdate = (props: any) => {
  const classes = useStyles();

  const {
    options,
    currentOption,
    anchorEl,
    open,
    handleCloseCalendar,
    mapDays,
    handleSubmitCalendar,
    calendarValue,
    isDatetimePicker,
    checkedHour,
    setCheckedHour,
    time,
    setTime,
    handleChangeDate,
    currentValue,
  } = usePeriodUpdate(props);

  return (
    <Box className={classes.container}>
      <List>
        {options.map((option) => {
          return (
            <ListItem
              key={option.value}
              button
              onClick={option.onClick}
              className={`${option.value === currentOption ? classes.activeButton : classes.defaultButton}`}
            >
              {option.label}
            </ListItem>
          );
        })}
      </List>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseCalendar}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        <Calendar
          multiple={false}
          range
          disableMonthPicker
          disableYearPicker
          value={currentValue}
          onChange={handleChangeDate}
          numberOfMonths={2}
          className={classes.calendar}
          weekDays={CALENDAR_WEEKDAYS}
          weekStartDayIndex={1}
          showOtherDays
          mapDays={mapDays}
        />
        {isDatetimePicker ? (
          <Box style={{ marginTop: '8px' }}>
            <CalendarTimeInput
              checkedHour={checkedHour}
              setCheckedHour={setCheckedHour}
              time={time}
              setTime={setTime}
              values={calendarValue}
            />
          </Box>
        ) : null}
        <Grid container justifyContent="flex-end" item xs={12} className={classes.groupButton}>
          <Button variant="text" onClick={handleCloseCalendar}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmitCalendar}>
            Apply
          </Button>
        </Grid>
      </Popover>
    </Box>
  );
};

export default PeriodUpdate;
