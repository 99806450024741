import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Success = () => (
  <SvgIcon width="147" height="146" viewBox="0 0 147 146" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_155_23011)">
      <path
        d="M9.625 33.3687C10.2137 33.3687 10.7141 32.8675 10.7141 32.2778V27.5016C10.7141 26.9119 10.2137 26.4107 9.625 26.4107C9.03629 26.4107 8.53589 26.9119 8.53589 27.5016V32.2778C8.53589 32.897 9.00686 33.3687 9.625 33.3687Z"
        fill="#1EB183"
      />
      <path
        d="M9.625 44.6607C10.2137 44.6607 10.7141 44.1595 10.7141 43.5699V38.7936C10.7141 38.204 10.2137 37.7028 9.625 37.7028C9.03629 37.7028 8.53589 38.204 8.53589 38.7936V43.5699C8.53589 44.189 9.00686 44.6607 9.625 44.6607Z"
        fill="#1EB183"
      />
      <path
        d="M1.58911 36.6413H6.35768C6.94639 36.6413 7.44679 36.1401 7.44679 35.5505C7.44679 34.9608 6.94639 34.4596 6.35768 34.4596H1.58911C1.0004 34.4596 0.5 34.9608 0.5 35.5505C0.529436 36.1401 0.970968 36.6413 1.58911 36.6413Z"
        fill="#1EB183"
      />
      <path
        d="M12.8923 36.6413H17.6609C18.2496 36.6413 18.75 36.1401 18.75 35.5505C18.75 34.9608 18.2496 34.4596 17.6609 34.4596H12.8923C12.3036 34.4596 11.8032 34.9608 11.8032 35.5505C11.8032 36.1401 12.2742 36.6413 12.8923 36.6413Z"
        fill="#1EB183"
      />
      <path
        d="M137.375 104.544C137.964 104.544 138.464 104.042 138.464 103.453V98.6765C138.464 98.0868 137.964 97.5856 137.375 97.5856C136.786 97.5856 136.286 98.0868 136.286 98.6765V103.453C136.286 104.072 136.757 104.544 137.375 104.544Z"
        fill="#1EB183"
      />
      <path
        d="M137.375 115.836C137.964 115.836 138.464 115.334 138.464 114.745V109.968C138.464 109.379 137.964 108.878 137.375 108.878C136.786 108.878 136.286 109.379 136.286 109.968V114.745C136.286 115.364 136.757 115.836 137.375 115.836Z"
        fill="#1EB183"
      />
      <path
        d="M129.339 107.816H134.108C134.696 107.816 135.197 107.315 135.197 106.725C135.197 106.136 134.696 105.635 134.108 105.635H129.339C128.75 105.635 128.25 106.136 128.25 106.725C128.279 107.315 128.721 107.816 129.339 107.816Z"
        fill="#1EB183"
      />
      <path
        d="M140.642 107.816H145.411C146 107.816 146.5 107.315 146.5 106.725C146.5 106.136 146 105.635 145.411 105.635H140.642C140.054 105.635 139.553 106.136 139.553 106.725C139.553 107.315 140.024 107.816 140.642 107.816Z"
        fill="#1EB183"
      />
      <path
        d="M72.0793 133.301C104.781 133.301 131.292 106.791 131.292 74.0888C131.292 41.3867 104.781 14.8764 72.0793 14.8764C39.3771 14.8764 12.8668 41.3867 12.8668 74.0888C12.8668 106.791 39.3771 133.301 72.0793 133.301Z"
        fill="#3CBB93"
      />
      <path
        d="M75.4409 127.576C83.6953 103.812 105.813 88.7172 129.611 88.2013C129.711 87.8019 129.81 87.4025 129.894 86.9864C105.496 87.3858 82.7634 102.813 74.3092 127.177C73.6103 129.191 73.0278 131.238 72.5452 133.285C72.9612 133.285 73.3773 133.268 73.7767 133.268C74.226 131.354 74.7752 129.457 75.4409 127.576Z"
        fill="#80D3B9"
      />
      <path
        d="M99.3555 61.7238C103.965 48.4435 103.782 34.2977 98.9394 21.3003C98.4069 21.034 97.891 20.7677 97.3584 20.5181C102.551 33.5821 102.9 47.9109 98.2404 61.3244C87.6062 91.8626 54.1556 108.072 23.6341 97.471C21.1544 96.6056 18.7746 95.5904 16.478 94.4421C16.6777 94.9913 16.8941 95.5405 17.1104 96.0897C19.0908 97.0216 21.1212 97.8704 23.2347 98.6026C29.7085 100.849 36.3154 101.914 42.8058 101.914C67.5691 101.914 90.7848 86.404 99.3555 61.7238Z"
        fill="#80D3B9"
      />
      <path
        d="M86.7242 38.6912C76.2231 68.9631 43.2052 85.1559 12.8834 75.0708C12.8834 75.5035 12.9001 75.9195 12.9167 76.3522C19.0077 78.3326 25.1985 79.2646 31.3062 79.2646C56.0696 79.2646 79.2852 63.7542 87.8559 39.074C90.269 32.101 91.3341 24.9782 91.151 18.0052C90.7516 17.872 90.3355 17.7389 89.9361 17.6057C90.1691 24.5954 89.154 31.7182 86.7242 38.6912Z"
        fill="#80D3B9"
      />
      <path
        d="M62.7596 15.6086C62.2104 15.6918 61.6612 15.7916 61.1287 15.8915C55.4371 24.2458 47.632 31.0025 38.2958 35.5291C33.4862 37.859 28.477 39.4899 23.3679 40.4385C23.0517 40.8879 22.7355 41.3539 22.4359 41.8198C28.0443 40.8879 33.5528 39.1405 38.8117 36.5942C48.6971 31.8179 56.9016 24.5787 62.7596 15.6086Z"
        fill="#80D3B9"
      />
      <path
        d="M95.4664 73.7454C96.4852 74.7642 119.875 84.3578 131.442 89.0273C124.82 111.441 112.128 121.713 106.609 124.048C98.2256 120.015 80.885 111.759 78.5927 110.995C60.1271 111.95 53.7596 104.946 48.3473 102.081C42.935 99.2152 30.2001 94.4396 30.2001 89.664C30.2001 85.8435 33.1716 83.8272 34.6573 83.2966C27.0164 80.4312 27.0164 76.6108 27.0164 75.3373C27.3348 68.6515 32.1103 68.3331 33.7022 68.3331C25.4245 64.5126 26.0612 59.737 26.0612 58.1452C27.0164 52.0961 34.0205 52.4145 36.2491 52.4145C31.7919 48.9124 32.4287 45.0919 32.7471 43.5C35.0393 37.3873 44.7391 39.4673 49.3024 41.2714C53.1229 42.5449 61.4005 45.2193 63.9475 45.7287C55.0331 32.9938 57.58 17.3935 57.8984 14.5281C58.2168 11.6628 59.8087 8.16071 64.2659 8.16071C68.7231 8.16071 70.315 11.6628 70.315 14.2098C69.6782 26.9447 77.6375 33.6305 79.2294 35.8591C84.005 41.5898 90.6908 52.0961 91.3275 61.0105C91.9643 69.9249 94.1929 72.4719 95.4664 73.7454Z"
        fill="#FEF2EC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.4375 40.3164C47.2994 40.5685 48.0892 40.8421 48.766 41.1097C52.1534 42.2389 59.0448 44.4693 62.3389 45.3155C62.8951 46.4962 62.7097 47.3304 62.4735 48.3937C62.3168 49.0988 62.1377 49.9047 62.1377 50.9792C61.4808 47.4758 52.6197 42.9371 46.4375 40.3164ZM62.3477 109.349C57.193 107.881 53.7477 105.648 50.9321 103.824C49.815 103.1 48.7971 102.441 47.8109 101.919C47.4127 101.708 46.9748 101.487 46.5053 101.256C50.1518 100.897 52.3163 99.5777 54.1783 95.2329C56.0886 90.7757 52.9049 87.9104 49.0844 85.3634C60.2274 83.1348 55.1335 75.1755 53.5416 73.5836C60.6731 69.2538 57.1498 63.2896 54.4967 60.8487C55.7702 60.8487 58.8266 60.403 60.8642 58.6201C60.5458 61.4855 61.1825 71.0367 67.8683 78.996C63.0928 79.9511 58.6356 87.2736 60.8642 95.2329C59.3552 98.0831 60.6715 100.933 61.8375 103.458C62.8857 105.728 63.8125 107.734 62.456 109.241C62.4219 109.279 62.3858 109.315 62.3477 109.349Z"
        fill="#FFDBC9"
      />
      <path
        d="M12.9008 123.042C12.4569 123.042 12.013 122.872 11.696 122.554L9.15953 120.009C8.50428 119.352 8.50428 118.27 9.15953 117.613C9.81477 116.955 10.8928 116.955 11.548 117.613L12.9008 118.97L17.7412 114.113C18.3964 113.456 19.4744 113.456 20.1296 114.113C20.7849 114.771 20.7849 115.852 20.1296 116.51L14.0845 122.575C13.7885 122.851 13.3658 123.042 12.9008 123.042Z"
        fill="white"
      />
      <path
        d="M108.136 47.0904C108.364 46.3898 109.355 46.3898 109.583 47.0904L110.823 50.9071C110.924 51.2204 111.216 51.4325 111.546 51.4325H115.559C116.296 51.4325 116.602 52.3752 116.006 52.8081L112.759 55.167C112.493 55.3606 112.381 55.7039 112.483 56.0172L113.723 59.8339C113.951 60.5345 113.149 61.1171 112.553 60.6841L109.306 58.3252C109.04 58.1316 108.679 58.1316 108.412 58.3252L105.166 60.6841C104.57 61.1171 103.768 60.5345 103.996 59.8339L105.236 56.0172C105.337 55.7039 105.226 55.3606 104.959 55.167L101.713 52.8081C101.117 52.3752 101.423 51.4325 102.16 51.4325H106.173C106.502 51.4325 106.794 51.2204 106.896 50.9071L108.136 47.0904Z"
        fill="#FFD964"
      />
      <path
        d="M96.7113 37.6129C96.8251 37.2626 97.3207 37.2626 97.4345 37.6129L98.1826 39.9153C98.2335 40.072 98.3795 40.178 98.5442 40.178H100.965C101.333 40.178 101.487 40.6494 101.189 40.8658L99.2301 42.2888C99.0968 42.3857 99.041 42.5573 99.0919 42.7139L99.84 45.0164C99.9539 45.3666 99.5529 45.6579 99.255 45.4414L97.2964 44.0185C97.1631 43.9216 96.9827 43.9216 96.8494 44.0185L94.8909 45.4414C94.5929 45.6579 94.192 45.3666 94.3058 45.0164L95.0539 42.7139C95.1048 42.5573 95.049 42.3857 94.9158 42.2888L92.9572 40.8658C92.6592 40.6494 92.8124 40.178 93.1807 40.178H95.6016C95.7663 40.178 95.9123 40.072 95.9632 39.9153L96.7113 37.6129Z"
        fill="#F9C937"
      />
    </g>
    <defs>
      <clipPath id="clip0_155_23011">
        <rect width="146" height="146" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Success;
