import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Store = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.0333 2.74L14 4.66667V5.33333C13.9514 5.69674 13.7726 6.03017 13.4967 6.27164C13.2208 6.51312 12.8666 6.64622 12.5 6.64622C12.1334 6.64622 11.7792 6.51312 11.5033 6.27164C11.2274 6.03017 11.0486 5.69674 11 5.33333C10.9514 5.69674 10.7726 6.03017 10.4967 6.27164C10.2208 6.51312 9.86664 6.64622 9.5 6.64622C9.13336 6.64622 8.77919 6.51312 8.5033 6.27164C8.22741 6.03017 8.04856 5.69674 8 5.33333C7.95144 5.69674 7.77259 6.03017 7.4967 6.27164C7.22081 6.51312 6.86664 6.64622 6.5 6.64622C6.13336 6.64622 5.77919 6.51312 5.5033 6.27164C5.22741 6.03017 5.04856 5.69674 5 5.33333C4.95144 5.69674 4.77259 6.03017 4.4967 6.27164C4.22081 6.51312 3.86664 6.64622 3.5 6.64622C3.13336 6.64622 2.77919 6.51312 2.5033 6.27164C2.22741 6.03017 2.04856 5.69674 2 5.33333V4.66667L2.96667 2.74C3.07705 2.51786 3.24722 2.33091 3.45803 2.20019C3.66884 2.06946 3.91194 2.00013 4.16 2H11.84C12.0881 2.00013 12.3312 2.06946 12.542 2.20019C12.7528 2.33091 12.9229 2.51786 13.0333 2.74Z"
      fill={`${props.color}`}
    />
    <path
      d="M12.1953 7.5286C12.3203 7.40357 12.4899 7.33333 12.6667 7.33333C12.8435 7.33333 13.013 7.40357 13.1381 7.5286C13.2631 7.65362 13.3333 7.82319 13.3333 8V12.6667C13.3333 13.0203 13.1929 13.3594 12.9428 13.6095C12.6928 13.8595 12.3536 14 12 14H4C3.64638 14 3.30724 13.8595 3.05719 13.6095C2.80714 13.3594 2.66667 13.0203 2.66667 12.6667V8C2.66667 7.82319 2.7369 7.65362 2.86193 7.5286C2.98695 7.40357 3.15652 7.33333 3.33333 7.33333C3.51014 7.33333 3.67971 7.40357 3.80474 7.5286C3.92976 7.65362 4 7.82319 4 8V10.6667H12V8C12 7.82319 12.0702 7.65362 12.1953 7.5286Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);
export default Store;
