import * as React from 'react';
import { get, isNumber, uniq } from 'lodash';
import { useAtomValue } from 'jotai';

import { eipRequest } from '@eip/next/lib/main';
import { POST_RELOAD_TABLE_EVENT } from '@ep/insight-ui/system/helper/constant';
import { eTableAtom } from '@ep/insight-ui/system/backbone/table-backbone/atom';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { stripUniversalPrefix } from '@ep/insight-ui/sw/util/column';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';
import { formatCurrencyNumber } from '@ep/insight-ui/lib/number';

const DEFAULT_FOOTER_CALCULATION_FORMAT_LABEL = get(
  window,
  '_eipConstant.DEFAULT_FOOTER_CALCULATION_FORMAT_LABEL',
  `=SWITCH(true, 
  COUNTIF(LIST("LIST", "RANGE"), p("aggregate.function")) > 0, TEXTJOIN(', ', true, MAP(LIST(p("value")), "TEXT(x, \\"#.##%\\")")),
  COUNTIF(LIST("PERCENT_EMPTY", "PERCENT_NOT_EMPTY"), p("aggregate.function")) > 0, TEXT(p("value"), "#.##%"), 
  TEXT(p("value"), "#,###.##"))`,
);

export const useCalculate = (params: any) => {
  const [open, setOpen] = React.useState(null);
  const [values, setValues] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [errorFunc, setErrorFunc] = React.useState(null);
  const [selectedMenu, setSelectedMenu] = React.useState(null);

  const backbone = React.useContext(TableBackboneContext);
  const mapping = backbone.getConfig('mapping');
  const field = params.colDef.field;
  const selectedBackboneMenu = get(backbone.getConfig('calculation'), [field], null);
  const labelFormula = get(backbone.getConfig('mapping'), [field, 'valueGetter', 'label'], '');
  const aggByType = useAtomValue(eTableAtom.groupBy.aggregationByType);
  const tableId = useAtomValue(eTableAtom.tableId);
  const aggByFunc = useAtomValue(eTableAtom.groupBy.aggregationByFunc);

  const disabled = false;

  const valueField = get(mapping, [field, 'valueGetter', 'value'], get(mapping, [field, 'valueGetter', 'id'], ''));
  const fieldPropertyType = get(mapping, [field, 'propertyType']);

  const currentData = {
    label: get(selectedMenu, ['label'], ''),
    value: values?.[get(selectedMenu, ['requestQuery'], null)],
  };

  React.useEffect(() => {
    if (tableId) {
      const handleEvent = (e) => {
        const isDrilldown = get(e, ['detail', 'params', 'groupBy', 'drillDowns'], []).length;
        if (tableId === get(e, ['detail', 'tableId']) && selectedMenu && !isDrilldown) {
          fetchData(get(e, ['detail', 'params'], backbone.getConfig('tableParams', {})), true);
        }
      };
      window.addEventListener(POST_RELOAD_TABLE_EVENT, handleEvent);

      return () => {
        window.removeEventListener(POST_RELOAD_TABLE_EVENT, handleEvent);
      };
    }
  }, [tableId, selectedMenu]);

  React.useEffect(() => {
    if (selectedBackboneMenu && get(selectedBackboneMenu, ['requestQuery'], '') != 'NULL') {
      setSelectedMenu(selectedBackboneMenu);
      fetchData();
    }
  }, []);

  const getValue = (val, funcValue, labelFormula) => {
    if (val === null) return '';
    let valNumber;
    if (isNumber(val)) {
      valNumber = Number(val);
    }

    switch (String(funcValue).toUpperCase()) {
      case aggByFunc.PERCENT_EMPTY.id:
      case aggByFunc.PERCENT_NOT_EMPTY.id: {
        return `${formatCurrencyNumber(valNumber, '%')}%`;
      }
      case aggByFunc.SUM.id:
      case aggByFunc.AVG.id:
      case aggByFunc.MEDIAN.id:
      case aggByFunc.MAX.id:
      case aggByFunc.MIN.id: {
        return String(labelFormula).startsWith('=')
          ? toValue(labelFormula, {
              value: val,
              currency: '',
              'storefront.currency': '',
            }).trim()
          : `${formatCurrencyNumber(valNumber)}`;
      }
      case aggByFunc.RANGE.id:
      case aggByFunc.LIST.id: {
        try {
          return valNumber
            ? toValue(labelFormula, {
                currency: '',
                'storefront.currency': '',
                value: valNumber,
              }).trim()
            : JSON.parse(val.replaceAll('\n', '\\n').replaceAll('\t', '\\t'))
                .map((v) => {
                  return String(labelFormula).startsWith('=')
                    ? toValue(labelFormula, {
                        currency: '',
                        'storefront.currency': '',
                        value: v,
                      }).trim()
                    : v;
                })
                .join(', ');
        } catch (e) {
          return val;
        }
      }
      default:
        return valNumber;
    }
  };

  const requestCalculate = async (func, params?) => {
    setErrorFunc(null);
    const queryFields = {};
    if (['dimension', 'attribute'].includes(fieldPropertyType) || /\.a_.*$/.test(valueField)) {
      queryFields['attributes'] = uniq(get(params, 'attributes', []).concat([valueField]));
      queryFields['metrics'] = [];
    } else {
      queryFields['metrics'] = uniq(get(params, 'metrics', []).concat([valueField]));
    }

    const requestParams = {
      ...(params || backbone.getConfig('tableParams', {})),
      ...queryFields,
      groupAll: true,
      groupPeriod: 'all',
      groupBy: {
        columns: null,
        aggregations: [
          {
            field: valueField,
            func: func,
          },
        ],
      },
      pagination: {
        page: 1,
        limit: 100,
      },
      sort: null,
    };
    setLoading(true);
    let endpoint = backbone.config.endpoint.GET_TABLE_DATA;
    if (endpoint.includes('?')) {
      endpoint += '&_eip.src=footer-calculator';
    } else {
      endpoint += '?_eip.src=footer-calculator';
    }
    try {
      const result = await eipRequest.post(endpoint, requestParams);
      const headers = get(result, ['data', 'headers'], []);
      const fieldIndex = headers.findIndex((el) => stripUniversalPrefix(el) == stripUniversalPrefix(valueField));

      let value = get(result, ['data', 'rows', 0, fieldIndex]);
      value = getValue(value, func, labelFormula);
      setValues({
        ...values,
        [func]: value,
      });
      setLoading(false);
    } catch (e) {
      setErrorFunc(func);
      setLoading(false);
      setValues({
        ...values,
        [func]: null,
      });
    }
  };

  const handleClickMenuItem = async (menu) => {
    if (menu.onClick) {
      menu.onClick();
    }
    if (params.tableDOM && params.tableDOM.current) {
      params.tableDOM.current.querySelector('.ag-floating-bottom').classList.remove('hover');
    }
    setSelectedMenu(menu);

    setOpen(false);
    backbone.changeConfig('calculation', {
      ...(backbone.getConfig('calculation', {}) || {}),
      [field]: menu,
    });

    if ((!values || values[menu.id] == null) && menu.id !== 'NONE') {
      requestCalculate(menu.id);
    }
  };

  const dropdownMenu = React.useMemo(() => {
    return get(aggByType, [params.dataType], []);
  }, [params.dataType]);

  const fetchData = async (params?, forceReload?) => {
    if (valueField && (!values || forceReload)) {
      const func = get(selectedBackboneMenu, ['id'], null);
      requestCalculate(func, params);
    }
  };

  return {
    open,
    setOpen,
    disabled,
    handleClickMenuItem,
    dropdownMenu,
    fetchData,
    loading,
    currentData,
    selectedMenu,
    errorFunc,
    requestCalculate,
  };
};
