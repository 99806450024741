import { TypeTabListItem } from '@ep/insight-ui/elements/app-menu/tab-list-menu';
import SelectFormSearchInside from '@ep/insight-ui/elements/form-control/select-form/select-form-search-inside';
import SelecTree from '@ep/insight-ui/elements/form-control/select-form/select-multiple-form/select-tree';
import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueTextFormat } from '@ep/insight-ui/elements/table/format/text-format';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';
import { produce } from 'immer';
/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
  },
}));

type Props = {
  data: {
    value: IValueTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  customProps?: {
    perValue?: number;
    perLabel?: string;
  };
};

const AdvertisingCostEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, anchorEl, setAnchorEl, customProps } = props;
  const perValue = get(customProps, 'perValue', -1);
  const perLabel = get(customProps, 'perLabel', '');
  const currency = get(data, 'value.currency', '');
  const classes = useStyles();

  const [value, setValue] = React.useState('');

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (anchorEl) {
      const valueInput = get(data, 'value.value', '');
      if (valueInput != value) {
        setValue(String(valueInput));
      }
    }
  }, [data.value, anchorEl]);

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const valueInput = get(data, 'value.value', '');
    if (valueInput != value) {
      setIsSubmitting(true);
      const response: any = await onSubmit({
        value: produce(data.value, (draft) => {
          draft.value = value;
        }),
        row: data.node.data,
      });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    } else {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', width: '304px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <div className={classes.block}>
          <TextBudgetInput
            style={{ width: perValue > 0 ? '156px' : '100%' }}
            defaultValue={value}
            currency={currency}
            onChangeText={setValue}
            disabledDebounce
            placeholder={'0'}
            noSelectBox
            autoFocus
          />
          {perValue > 0 && (
            <Typography variant="body2">
              / {perValue} {perLabel}
            </Typography>
          )}
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default AdvertisingCostEditor;
