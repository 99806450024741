import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Indeterminate = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 8 2" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7 0H1C0.447715 0 0 0.447715 0 1C0 1.55228 0.447715 2 1 2H7C7.55228 2 8 1.55228 8 1C8 0.447715 7.55228 0 7 0Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Indeterminate;
