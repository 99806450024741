import { EventEmitter } from 'events';
import React from 'react';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { Table } from '@ep/insight-ui/elements/table/table-intraday';
/**
 * ff.generate_etable_config:start
 */
import { INTRADAY_CONFIG } from '@ep/insight-ui/system/block/etable/table-config/intraday-config';
import { RichConfigForm } from '../etable/etable';
/**
 * ff.generate_etable_config:end
 */
import './styles.css';

class EIntradayTable implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    // dom.innerHTML = '<div style="height: 1000px; width: 100px; background: tomato;"><div style="height: 100px; background: black"></div></div>';

    return (
      <div style={{ padding: '12px' }}>
        <Table
          {...(ff.generate_etable_config ? { configuration: data.customAttributes.configuration } : {})}
          period={data.customAttributes.period || 1}
        />
      </div>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit) {
    return <ChartForm data={data} onSubmit={handleSubmit} />;
  }
}

function ChartForm({ data, onSubmit }: { data: any; onSubmit: any }) {
  const periodOptions = [
    {
      label: '10 mins',
      value: 1 / 6,
    },
    {
      label: '30 mins',
      value: 0.5,
    },
    {
      label: '60 mins',
      value: 1,
    },
  ];

  const periodRender = periodOptions.map((option) => {
    return (
      <li
        key={option.value}
        onClick={() => {
          if (ff.generate_etable_config) {
            const newData = { ...data, period: option.value };
            onSubmit(newData);
          } else {
            onSubmit({ period: option.value });
          }
        }}
        className={`${data.period === option.value ? 'active' : ''}`}
      >
        {option.label}
      </li>
    );
  });

  let configurationSubmit;
  if (ff.generate_etable_config) {
    configurationSubmit = (config) => {
      const newData = {
        ...data,
        configuration: config,
      };
      onSubmit(newData);
    };
  }

  return (
    <div>
      <div>
        <h3>Period</h3>
        <ul className="period-section">{periodRender}</ul>
      </div>
      {ff.generate_etable_config && <RichConfigForm data={data.configuration} onSubmit={configurationSubmit} />}
    </div>
  );
}

export default {
  blockType: 'eIntradayTable',
  label: 'Intraday Table',
  blockComponent: EIntradayTable,
  systemConfig: {},
  layout: {
    w: EIP_CONSTANT.SCREEN_COLUMNS.lg.default,
    h: 20,
  },
  ...(ff.generate_etable_config
    ? {
        advancedProperties: {
          configuration: INTRADAY_CONFIG,
        },
      }
    : {}),
};
