import React from 'react';
import Dashboard from './components/pages/main/index-dark';

import './app-dark.css';
import { MuiThemeProvider, createGenerateClassName, StylesProvider } from '@material-ui/core';
import { themeDark } from './theme-dark';

const prefixClass = createGenerateClassName({ seed: 'eld' });

export default function App({isDisableTarget}: {isDisableTarget: boolean}) {
  console.info('app dark version ===>');
  return (
    <StylesProvider generateClassName={prefixClass}>
      <MuiThemeProvider theme={themeDark}>
        <Dashboard isDisableTarget={isDisableTarget}></Dashboard>
      </MuiThemeProvider>
    </StylesProvider>
  );
}
