import { EIP_CONSTANT } from '@eip/next/lib/main';

export const CAMPAIGN_HISTORY = {
  title: 'Campaign History',
  dimension: [],
  metric: [],
  attribute: [],
  tableType: 'compact',
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=changelog',
  },
  searchHint: 'Looking for history',
  system: {
    hiddenComponents: ['timeline'],
  },
  requestIgnoreField: {
    dimension: ['ADTYPE'],
    attribute: [
      'ADTYPE.ads_type',
      'ADS_CAMPAIGN.campaign_code',
      'ADS_CAMPAIGN.name',
      'ADS_CAMPAIGN.id',
      'ADS_OBJECT.id',
      'ADS_OBJECT.name',
      'ADS_PLACEMENT.id',
      'ADS_PLACEMENT.name',
      'PRODUCT.product_sid',
      'ADS_OBJECT.sc_identify_code',
      'PRODUCT.id',
      'changelog_ads_ops_dimension.field_name',
      'changelog_ads_ops_dimension.created_at',
      'changelog_ads_ops_dimension.old_value',
      'changelog_ads_ops_dimension.new_value',
    ],
  },
  sort: [
    {
      field: 'createdAt',
      sort: 'desc',
    },
  ],
  columnOrder: [
    'campaign',
    'adObject',
    'keyword',
    'fieldName',
    'newValue',
    'createdAt',
    'userName',
    'oldValue',
    'country',
    'storefront',
    'marketplace',
  ],
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.country_name',
      valueGetter: {
        label: 'storefront.country_name',
        id: 'storefront.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.marketplace_name',
      valueGetter: {
        label: 'storefront.marketplace_name',
        id: 'storefront.marketplace_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront.id',
      valueGetter: {
        label: 'storefront.name',
        id: 'storefront.id',
        sid: 'storefront.storefront_sid',
        subtext: 'storefront.id',
      },
    },
    campaign: {
      title: 'Campaign',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.name',
      valueGetter: {
        label: 'ADS_CAMPAIGN.name',
        id: 'ADS_CAMPAIGN.id',
        subtext: 'ADS_CAMPAIGN.campaign_code',
      },
    },
    adObject: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT.name',
      valueGetter: {
        label: 'ADS_OBJECT.name',
        id: 'ADS_OBJECT.id',
        subtext: 'PRODUCT.product_sid',
        subtext2: 'ADS_OBJECT.sc_identify_code',
        masterObjectId: 'PRODUCT.id',
      },
    },
    keyword: {
      title: 'Keyword',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.name',
      valueGetter: {
        label: 'ADS_PLACEMENT.name',
        id: 'ADS_PLACEMENT.id',
      },
    },
    fieldName: {
      title: 'Field Name',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'changelog_ads_ops_dimension.field_name',
      valueGetter: {
        label: 'changelog_ads_ops_dimension.field_name',
        id: 'changelog_ads_ops_dimension.field_name',
      },
    },
    createdAt: {
      title: 'Created At',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'changelog_ads_ops_dimension.created_at',
      valueGetter: {
        label: 'changelog_ads_ops_dimension.created_at',
        id: 'changelog_ads_ops_dimension.created_at',
      },
    },
    oldValue: {
      title: 'Old Value',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'changelog_ads_ops_dimension.old_value',
      valueGetter: {
        label: 'changelog_ads_ops_dimension.old_value',
        id: 'changelog_ads_ops_dimension.old_value',
      },
    },
    newValue: {
      title: 'New Value',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'changelog_ads_ops_dimension.new_value',
      valueGetter: {
        label: 'changelog_ads_ops_dimension.new_value',
        id: 'changelog_ads_ops_dimension.new_value',
      },
    },
    userName: {
      title: 'User Name',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'user.name',
      valueGetter: {
        label: 'user.name',
        subtext: 'user.email',
        id: 'user.email',
      },
    },
  },
};
