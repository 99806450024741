import { eipRequest as request } from '@eip/next/lib/main';
import { get, lowerCase, startCase } from 'lodash';
import qs from 'qs';
import dataRequest from '../data-source/common';
import * as DEFAULT_URL from './endpoint';
import { getMockDataRows } from './mockup';

type SortType = {
  field: string;
  sort: 'asc' | 'desc';
};

type FilterType = {
  combination: 'and' | 'or';
  filters: {
    field: string;
    operator: string;
    value: string;
  }[];
};

type ResponseType = {
  data: any;
  message: string;
  code: number;
};

export type SubmitResponseType = {
  message: string;
  error?: any[];
  code: number;
};

export const getAPIRequest = (endpoint) => {
  const ROWS = getMockDataRows();
  const APIRequest = {
    ...DEFAULT_URL,
    ...endpoint,
  };

  let getFilterValueData;
  if (ff.custom_filter_value_addon) {
  } else {
    getFilterValueData = (columnName: string, payload?: any) => {
      switch (columnName) {
        case 'country':
          return dataRequest.getCountry();
        case 'marketplace':
          return dataRequest.getMarketplace();
        case 'storefront':
          return dataRequest.getStorefront();
        case 'ad_tool':
          return dataRequest.getAdTool();
        case 'ad_type':
          return dataRequest.getAdType();
        case 'campaign_status':
          return dataRequest.getStatus({ dimension: 'ADS_CAMPAIGN', marketplaceCode: 'SHOPEE' });
        case 'ad_object_status':
          return dataRequest.getStatus({ dimension: 'ADS_OBJECT', marketplaceCode: 'SHOPEE' });
        case 'keyword_status':
          return dataRequest.getStatus({ dimension: 'ADS_PLACEMENT', marketplaceCode: 'SHOPEE' });
        case 'script_status':
          return dataRequest.getStatus({ dimension: 'SCRIPT', marketplaceCode: 'SHOPEE' });
        case 'keyword_match_type':
          const matchTypes = [
            { id: 'BROAD_MATCH', text: 'Broad match' },

            { id: 'EXACT_MATCH', text: 'Exact match' },
          ];
          return Promise.resolve(matchTypes);
        default:
          return Promise.resolve([]);
      }
    };
  }

  const getTableData = async (param: {
    dimensions: string[];
    attributes: string[];
    metrics: string[];
    group_by?: string[];
    sort?: SortType[];
    filter?: FilterType[];
    pagination: {
      page: number;
      limit: number;
    };
    from: string;
    to: string;
  }): Promise<ResponseType> => {
    try {
      const res = await request.post(APIRequest.GET_TABLE_DATA, param);
      let rows;
      try {
        rows = res.data.rows;
      } catch (error) {
        console.error(error);
      }

      console.info('rows', rows);

      return Promise.resolve({
        data: {
          ...res.data,
          rows,
          pagination: res.pagination,
          ...(res.masterData ? { masterData: res.masterData } : {}),
        },
        ...(ff.separate_table_data_loading ? { traceId: get(res, 'serviceResponse.traceId') } : {}),
        eTableContext: get(res, 'eTableContext', null),
        message: get(res, 'serviceResponse.message'),
        code: get(res, 'serviceResponse.code'),
        success: get(res, 'serviceResponse.success'),
      });
    } catch (err) {
      return Promise.reject({
        data: [],
        error: err,
        message: 'Load fail',
        code: 1,
        success: false,
      });
    }
  };

  const getPaginationInfo = async (
    params: {
      previousTraceId: string;
      pagination: {
        page: number;
        limit: number;
      };
    },
    backbone,
    payload,
  ) => {
    try {
      let res;
      if (backbone) {
        res = await backbone.addon('system.getPaginationInfo', () =>
          request.post(APIRequest.GET_PAGINATION_INFO, params),
        )(payload);
      } else {
        res = await request.post(APIRequest.GET_PAGINATION_INFO, params);
      }

      return Promise.resolve({
        pagination: res.pagination,
        success: res.serviceResponse.success,
        message: res.serviceResponse.message,
        code: res.serviceResponse.code,
      });
    } catch (err) {
      return Promise.reject({
        pagination: {
          page: 0,
          limit: 0,
          total: 0,
        },
        success: false,
        message: 'Load pagination fail',
        code: 1,
        error: err,
      });
    }
  };

  const requestExportEtable = async (params: {
    dimensions: string[];
    attributes: string[];
    metrics: string[];
    sort?: SortType[];
    filter?: FilterType[];
    hiddenFilter: any;
    from: string;
    to: string;
  }) => {
    const titleSuccess = 'Data export is in progress';
    const messageSuccess = "We'll email you once it’s done.";
    return new Promise(async (resolve, reject) => {
      try {
        const response = await request.post(APIRequest.API_EXPORT_ETABLE, params);
        if (response.code == 13 || !response.serviceResponse.success) {
          resolve({
            title: 'Sorry. Your requested data couldn’t be exported',
            messages: 'Please try again later',
            variant: 'error',
          });
        }
        if (response.serviceResponse.success) {
          resolve({ title: titleSuccess, messages: messageSuccess, variant: 'success' });
        }
      } catch (error) {
        resolve({
          title: 'Sorry. Your requested data couldn’t be exported',
          messages: 'Please try again later',
          variant: 'error',
        });
      }
    });
  };

  const updateCellValue = (params: { data: any; dimension: string; marketplaceCode: string }) => {
    if (params.dimension === 'SCRIPT') {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await request.put(APIRequest.MASS_UPDATE_SCRIPT, params);
          const serviceRes = res['serviceResponse'] || {};
          resolve({ ...res, ...serviceRes });
        } catch (err) {
          reject(err);
        }
      });
    }
    return request.post(APIRequest.MASS_UPDATE_CELL_VALUE, params);
  };

  const deleteDimension = (param: { id: string[]; dimension: string; marketplaceCode: string }) => {
    const url = `${APIRequest.MASS_DELETE_DIMENSION}?${qs.stringify(param, {
      arrayFormat: 'repeat',
    })}`;
    return request.deleteFetch(url);
  };

  const getProperties = async (): Promise<ResponseType> => {
    try {
      const res = await request.get(APIRequest.GET_PROPERTIES);

      const dimensions = [];
      let metrics = [];
      let attributes = [];

      res.data.forEach((p) => {
        const _metrics = p.metrics.map((m) => ({
          id: m.id,
          name: m.name,
          dataType: m.dataType,
          dimension: p.id,
        }));
        const _attributes = p.attributes.map((a) => ({
          id: a.id,
          name: a.name,
          dataType: a.dataType,
          dimension: p.id,
        }));
        dimensions.push({
          id: p.id,
          name: p.name,
          dataType: p.dataType,
        });
        metrics = metrics.concat(_metrics);
        attributes = attributes.concat(_attributes);
      });

      return Promise.resolve({
        data: {
          dimensions,
          metrics,
          attributes,
        },
        message: res.serviceResponse.message,
        code: res.serviceResponse.code,
      });
    } catch (err) {
      return Promise.reject({
        data: [],
        message: 'Load property fail',
        code: 1,
      });
    }
  };
  const getDimensions = (): Promise<ResponseType> => {
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        data: [],
        message: 'Load fail',
        code: 1,
      });
    }

    const res = {
      data: [
        { name: 'Campaign', id: 'campaign_eid' },
        { name: 'SKU', id: 'product_eid' },
        { name: 'Keyword', id: 'keyword_eid' },
        { name: 'Rule', id: 'ruleId' },
      ],
      message: 'Load successfully',
      code: 0,
    };

    return Promise.resolve(res);
  };

  const getRelatedMetrics = (param: { dimensions: string[] }): Promise<ResponseType> => {
    console.log('getRelatedMetrics param:', param);

    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        data: [],
        message: 'Load fail',
        code: 1,
      });
    }

    const res = {
      data: [
        {
          id: 'gmv',
          name: 'GMV',
          dataType: 'number',
        },
        {
          id: 'roas',
          name: 'ROAS',
          dataType: 'number',
        },
        {
          id: 'cpc',
          name: 'CPC',
          dataType: 'number',
        },
        {
          id: 'cost',
          name: 'Cost',
          dataType: 'number',
        },
        {
          id: 'ads_gmv',
          name: 'Ads GMV',
          dataType: 'number',
        },
        {
          id: 'item_sold',
          name: 'Item Sold',
          dataType: 'number',
        },
        {
          id: 'direct_item_sold',
          name: 'Direct Item Sold',
          dataType: 'number',
        },
      ],
      message: 'Load successfully',
      code: 0,
    };

    return Promise.resolve(res);
  };

  const getRelatedAttributes = (param: { dimensions: string[] }): Promise<ResponseType> => {
    console.log('getRelatedAttributes param:', param);

    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        data: [],
        message: 'Load fail',
        code: 1,
      });
    }

    const res = {
      data: [
        {
          dimension_id: 'campaign_eid',
          attributes: [
            {
              id: 'campaign_name',
              name: 'Campaign name',
              dataType: 'string',
            },
            {
              id: 'timeline',
              name: 'Time line',
              dataType: 'timestamp',
            },
            {
              id: 'campaign_budget',
              name: 'Budget',
              dataType: 'number',
            },
          ],
        },
        {
          dimension_id: 'product_eid',
          attributes: [
            {
              id: 'product_name',
              name: 'Product name',
              dataType: 'string',
            },
            { id: 'price', name: 'Price', dataType: 'number' },
          ],
        },
        {
          dimension_id: 'keyword_eid',
          attributes: [
            {
              id: 'keyword_name',
              name: 'Keyword name',
              dataType: 'string',
            },
            { id: 'budget', name: 'Budget', dataType: 'number' },
          ],
        },
      ],
      message: 'Load successfully',
      code: 0,
    };

    return Promise.resolve(res);
  };

  const getRelatedActions = (param: { dimensions: string[] }): Promise<ResponseType> => {
    console.log('getRelatedActions param:', param);

    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        data: [],
        message: 'Load fail',
        code: 1,
      });
    }

    const res = {
      data: [
        {
          id: 'modify_budget',
          name: 'Modify Budget',
        },
        {
          id: 'add_rule',
          name: 'Add Rule',
        },
        {
          id: 'delete_rule',
          name: 'Delete Rule',
        },
        {
          id: 'add_keyword',
          name: 'Add Keyword',
        },
        {
          id: 'replace_text',
          name: 'Replace Text',
        },
      ],
      message: 'Load successfully',
      code: 0,
    };

    return Promise.resolve(res);
  };

  const checkExistCampaign = (value: string) => {
    if (value.includes('exist')) {
      return Promise.resolve(true);
    }

    return Promise.resolve(false);
  };

  const updateKeywordBidPrice = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update keyword bidding price', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Keyword bidding price update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['keyword_bidding_price'] = value;
    }

    return Promise.resolve({
      message: 'Keyword bidding price update successful',
      errors: [],
      code: 0,
    });
  };

  // ================================

  const updateCampaignName = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update campaign name', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Campaign name update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['campaign'] = value;
    }

    return Promise.resolve({
      message: 'Campaign name update successful',
      errors: [],
      code: 0,
    });
  };

  const updateCampaignBudget = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update campaign budget', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Campaign budget update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['campaign_budget'] = value;
    }

    return Promise.resolve({
      message: 'Campaign budget update successful',
      errors: [],
      code: 0,
    });
  };

  const updateCampaignTimeline = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update campaign timeline', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Campaign timeline update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['campaign_timeline'] = value;
    }

    return Promise.resolve({
      message: 'Campaign timeline update successful',
      errors: [],
      code: 0,
    });
  };

  const updateKeywordStatus = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update keyword status', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Keyword status update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['keyword'].status = value;
    }

    return Promise.resolve({
      message: 'Keyword status update successful',
      errors: [],
      code: 0,
    });
  };

  const updateKeywordName = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update keyword name', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Keyword name update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['keyword'].label = value;
    }

    return Promise.resolve({
      message: 'Keyword name update successful',
      errors: [],
      code: 0,
    });
  };

  const updateAdsObjectBudget = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update ads object budget', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Ads object budget update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['ads_object_budget'] = value;
    }

    return Promise.resolve({
      message: 'Ads object budget update successful',
      errors: [],
      code: 0,
    });
  };

  const updateMatchType = ({ id, value }: { id: string; value: any }): Promise<SubmitResponseType> => {
    console.log('[request] update match type', { id, value });
    const isFail = Math.random() < 0.1;
    if (isFail) {
      return Promise.reject({
        message: 'Match type update failed',
        errors: [],
        code: 1,
      });
    }

    // For demo update database
    const index = ROWS.findIndex((r) => r.rowId === parseInt(id));
    if (index > -1) {
      ROWS[index]['match_type'] = value;
    }

    return Promise.resolve({
      message: 'Match type update successful',
      errors: [],
      code: 0,
    });
  };

  const getDimensionDefinition = (param: {
    dimension: string;
    marketplaceCode: string;
    toolCode?: string;
    countryCode?: string;
  }) => {
    return request.get(DEFAULT_URL.GET_DIMENSION_DEFINITION, param);
  };

  const getDimensionOptions = (param: {
    type: string;
    dimension: string;
    marketplaceCode: string;
    toolCode?: string;
    countryCode?: string;
  }) => {
    return new Promise(async (resolve, reject) => {
      try {
        let opts = [];
        const res = await getDimensionDefinition(param);
        if (res.success) {
          const values = get(res.data, ['data', 'value', param.type]);
          if (values) {
            opts = (values.split(':')[1] || '').split(',').map((i) => ({
              id: i,
              text: startCase(lowerCase(i)),
            }));
          }
        }
        resolve({
          success: res.success,
          data: opts,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const getSuggestBiddingPrice = ({
    marketplaceCode,
    toolCodeIds,
    storefrontIds,
    masterObjectIds,
    keywords,
  }: {
    marketplaceCode: string;
    toolCodeIds: string[];
    storefrontIds: string[];
    masterObjectIds: string[];
    keywords: string[];
  }) => {
    const reqParams = {
      dimension: 'SUGGEST_KEYWORD',
      marketplace_code: marketplaceCode,
      search_by: {
        tool_code: toolCodeIds,
        storefront_id: storefrontIds,
        master_object_id: masterObjectIds,
        name: keywords,
      },
      paginate: {
        limit: keywords.length,
      },
    };

    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post(DEFAULT_URL.FILTER_DIMENSION, reqParams);
        console.log('getSuggestBiddingPrice', { reqParams, res });
        let keywords = [];
        if (res.success) {
          keywords = res.data.map((i) => ({
            name: i.name,
            biddingPrice: i.biddingPrice,
          }));
        }
        resolve({
          success: res.success,
          data: keywords,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const getLandingPageOptions = ({
    marketplaceCode,
    storefrontIds,
  }: {
    marketplaceCode: string;
    toolCodeIds: string[];
    storefrontIds: string[];
    masterObjectIds: string[];
    keywords: string[];
  }) => {
    const reqParams = {
      dimension: 'STOREFRONT_CATEGORY',
      marketplace_code: marketplaceCode,
      exact_match: true,
      search_by: {
        storefront_id: storefrontIds,
      },
    };

    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post(DEFAULT_URL.FILTER_DIMENSION, reqParams);
        console.log('getSuggestBiddingPrice', { reqParams, res });
        let options = [];
        if (res.success) {
          options = res.data.map((i) => ({
            id: i.id,
            name: i.name,
          }));
        }
        resolve({
          success: res.success,
          data: options,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const getAttributesDefinition = ({
    dimensionCode,
    attributeName,
    toolCodeIds,
    storefrontIds,
  }: {
    dimensionCode: string;
    attributeName: string;
    toolCodeIds: string[];
    storefrontIds: string[];
  }) => {
    const reqParams = {
      dimension: 'DEFINITION',
      exact_match: true,
      search_by: {
        definition_method: ['ATTRIBUTE_VALUE'],
        dimension_code: [dimensionCode],
        attribute_name: attributeName,
        storefront_id: storefrontIds,
        tool_code: toolCodeIds,
      },
    };

    return new Promise(async (resolve, reject) => {
      try {
        const res = await request.post(DEFAULT_URL.FILTER_DIMENSION, reqParams);
        let options = [];
        if (res.success) {
          options = res.data.map((i) => ({
            id: i.key,
            text: i.value,
          }));
        }
        console.log('Request attribute definition', { reqParams, options });
        resolve({
          success: res.success,
          data: options,
        });
      } catch (err) {
        reject(err);
      }
    });
  };

  const getGenders = (params: { toolCodeIds: string[]; storefrontIds: string[] }) => {
    return getAttributesDefinition({
      ...params,
      dimensionCode: 'ADS_OBJECT',
      attributeName: 'audience_gender',
    });
  };

  const getAges = (params: { toolCodeIds: string[]; storefrontIds: string[] }) => {
    return getAttributesDefinition({
      ...params,
      dimensionCode: 'ADS_OBJECT',
      attributeName: 'audience_age',
    });
  };

  const getAudienceLocations = (params: { toolCodeIds: string[]; storefrontIds: string[] }) => {
    return getAttributesDefinition({
      ...params,
      dimensionCode: 'ADS_OBJECT',
      attributeName: 'audience_location',
    });
  };

  const getInterestCategories = (params: { toolCodeIds: string[]; storefrontIds: string[] }) => {
    return getAttributesDefinition({
      ...params,
      dimensionCode: 'ADS_OBJECT',
      attributeName: 'audience_interest_category',
    });
  };

  return {
    requestExportEtable,
    ...(ff.custom_filter_value_addon ? {} : { getFilterValueData }),
    getTableData,
    ...(ff.separate_table_data_loading ? { getPaginationInfo } : {}),
    updateCellValue,
    updateMatchType,
    deleteDimension,
    getProperties,
    getDimensions,
    getRelatedMetrics,
    getRelatedAttributes,
    getRelatedActions,
    getDimensionDefinition,
    getDimensionOptions,
    getSuggestBiddingPrice,
    getLandingPageOptions,
    getGenders,
    getAges,
    getAudienceLocations,
    getInterestCategories,
    checkExistCampaign,
    updateKeywordBidPrice,
    updateCampaignName,
    updateCampaignBudget,
    updateCampaignTimeline,
    updateKeywordStatus,
    updateKeywordName,
    updateAdsObjectBudget,
  };
};
