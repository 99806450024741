import * as React from 'react';
import { get, uniqBy } from 'lodash';

import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import TextBudgetInput from '@ep/insight-ui/elements/input-form/form-templates/form-controls/text-budget/text-budget-input';
import { options } from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-editor/budget-editor';
import { options as BudgetOptions } from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-selection-editor/budget-selection-editor';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  row: {
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        width: '128px',
        height: '32px',
      },
    },
  },
  listAction: {
    padding: '8px',
  },
  showMenu: {
    // display:'inline-flex',
    opacity: 1,
  },
  hideMenu: {
    opacity: 0,
  },
}));

const Budget = ({ configuration, onChange = () => undefined, rowData, data }: any) => {
  const classes = useStyles();
  const [field, setField] = React.useState<string>('');
  const [value, setValue] = React.useState('0');

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;

  const disabledModelNoLimit = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const disabledModelNoLimitObject = fieldConfiguration.find((el) => el.key === 'disabled_model_no_limit') || {
      value: false,
    };
    return disabledModelNoLimitObject.value;
  }, [configuration]);

  const disabledListBox = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const disabledListBoxObject = fieldConfiguration.find((el) => el.key === 'disabled_list_box') || { value: false };
    return disabledListBoxObject.value;
  }, [configuration]);

  const currency = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const currencyObject = fieldConfiguration.find((el) => el.key === 'currency') || { value: '' };
    return currencyObject.value;
  }, [configuration]);

  const defaultBudgetType = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const defaultBudgetTypeObject = fieldConfiguration.find((el) => el.key === 'default_budget_type') || { value: '' };
    return defaultBudgetTypeObject.value;
  }, [configuration]);

  const primaryKey = React.useMemo(() => {
    const fieldConfiguration = get(configuration, ['field_configuration'], []) || [];
    const primaryKeyObject = fieldConfiguration.find((el) => el.key === 'primary_key') || { value: '' };
    return primaryKeyObject.value;
  }, [configuration]);

  React.useEffect(() => {
    setField(defaultBudgetType);
  }, [defaultBudgetType]);

  React.useEffect(() => {
    if (disabledListBox) {
      const dataValue = get(data, ['value', 'value'], 0);
      setField(defaultBudgetType || BudgetOptions[0].value);
      setValue(dataValue);
    } else {
      const dailyValue = get(data, ['value', 'dailyBudget'], 0);
      const totalValue = get(data, ['value', 'totalBudget'], 0);

      if (!dailyValue && !totalValue) {
        setField(defaultBudgetType || BudgetOptions[0].value);
      } else if (dailyValue > 0) {
        setField('daily');
        setValue(dailyValue);
      } else if (totalValue > 0) {
        setField('total');
        setValue(totalValue);
      }
    }
  }, []);

  React.useEffect(() => {
    const timeoutOnChange = setTimeout(() => {
      const selectedRows = backboneContext?.getSelectedRows
        ? [...backboneContext.getSelectedRows(), rowData]
        : [rowData];

      const valueRequest: { id: string; value: number }[] = selectedRows.map((row) => {
        return {
          id: row?.[primaryKey],
          value: Number(value),
        };
      });

      const data = uniqBy(valueRequest, 'id').map((el) => ({
        ...(!disabledListBox ? { total_budget: 0, daily_budget: 0 } : {}),
        [`${field}_budget`]: el.value,
        [primaryKey.split('.')[1]]: el.id,
      }));

      onChange({
        target: {
          value: data,
        },
      });
    }, 100);

    return () => {
      clearTimeout(timeoutOnChange);
    };
  }, [value, field]);

  return (
    <Grid
      container
      wrap={'nowrap'}
      alignItems={'center'}
      justifyContent={'space-between'}
      spacing={2}
      className={classes.row}
    >
      <Grid item style={{ width: '80%' }}>
        <SelectForm
          options={options}
          value={field}
          disabled={disabledListBox}
          className={classes.select}
          style={{ width: '100%' }}
          onChange={(e) => setField(e.target.value)}
        />
      </Grid>
      <Grid item style={{ width: '100%' }}>
        <TextBudgetInput
          defaultValue={value == '0' ? '' : String(value)}
          currency={rowData?.[currency] || currency || '$'}
          onChangeText={(newValue) => setValue(newValue)}
          disabledDebounce
          placeholder={disabledModelNoLimit ? '0' : 'no limit'}
          noSelectBox
        />
      </Grid>
    </Grid>
  );
};
export default Budget;
