import { mopFilterToQueryParams } from '@eip/next/lib/main';
import { getPageUrn } from '@ep/insight-ui/eo2/global';
import qs from 'qs';

const SHOPEE_SC_LINK = {
  VN: 'https://shopee.vn',
  PH: 'https://shopee.ph',
  MY: 'https://shopee.com.my',
  SG: 'https://shopee.sg',
  ID: 'https://shopee.co.id',
  TH: 'https://shopee.co.th',
};

const getAdObjectPDPLink = (data) => {
  const marketplace = data.row['MARKETPLACE.marketplace_code'];
  const countryCode = data.row['COUNTRY.country_code'];
  const productSId = data.row['PRODUCT.product_sid'];
  const shopSId = data.row['STOREFRONT.storefront_sid'];
  switch (marketplace) {
    case 'SHOPEE':
      if (SHOPEE_SC_LINK[countryCode]) {
        return `${SHOPEE_SC_LINK[countryCode]}/${productSId}-i.${shopSId}.${productSId}`;
      }
    default:
      return '';
  }
};

export const getActionMenu = (column, context: any = {}) => {
  switch (column) {
    case 'marketplace':
    case 'country':
    case 'storefront':
    case 'ad_type':
    case 'ad_tool':
      return [
        // {
        //   name: 'Create campaign',
        //   icon: 'add',
        //   iconSize: '13px',
        // },
        {
          name: 'Tag',
          icon: 'tag',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'campaign':
      return [
        {
          name: 'Add ad object',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Duplicate campaign',
          icon: 'duplicatePlus',
          disable: true,
        },
        {
          title: 'Campaign name',
          name: 'Edit name',
          icon: 'pencil',
          editor: 'NameEditor',
          condition: [
            {
              field: 'ADTOOL.ads_tool',
              operator: 'NOT_CONTAIN',
              values: ['SHP_SA'],
            },
          ],
          payload: {
            field: {
              label: 'ADS_CAMPAIGN.name',
            },
          },
        },
        {
          name: 'Campaign detail',
          icon: 'showDetail',
          // editor: 'LinkTo',
          onClickItem(data: { row: any; value: any }) {
            console.info(data);
            window.open(
              getPageUrn(
                `/page/afdd53e6-49f9-4170-9212-bdeada713c0d/${data.value.id}?marketplace=` +
                  data.row['MARKETPLACE.marketplace_code'],
              ),
            );
          },
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'ad_object':
      return [
        {
          name: 'Add keyword',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add negative keyword',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add placement',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Add audience',
          icon: 'add',
          iconSize: '13px',
          disable: true,
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Open product detail page',
          icon: 'openPdp',
          onClickItem: (data) => {
            const link = getAdObjectPDPLink(data);
            if (link) {
              window.open(link);
            }
          },
        },
        {
          name: 'Tag',
          icon: 'tag',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'keyword':
      return [
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'negative_keyword':
      return [
        {
          name: 'Mass upload',
          icon: 'upload',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    case 'script':
      return [
        {
          name: 'View details',
          icon: 'setscript',
          onClickItem: (data) => {
            const value: any = data.value;
            const queryStr = qs.stringify({
              type: context.type,
              marketplace: data.row['MARKETPLACE.marketplace_code'],
            });

            window.open(getPageUrn('/page/ec22dd25-a14f-40d8-9199-a5b0517e835e/' + value.id + '?' + queryStr));
          },
        },
        {
          name: 'Duplicate',
          icon: 'duplicate',
          onClickItem: (data) => {
            const value: any = data.value;
            const queryStr = qs.stringify({
              cloneId: value.id,
              marketplace: data.row['MARKETPLACE.marketplace_code'],
              type: context.type,
            });

            window.open(getPageUrn('/page/ec22dd25-a14f-40d8-9199-a5b0517e835e/default?' + queryStr));
          },
        },
        {
          name: 'View log',
          icon: 'history',
          onClickItem: (data) => {
            const value: any = data.value;
            const filters: MopFilterType[] = [
              {
                field: 'SCRIPT_LOG.script_id',
                dataType: 'integer',
                operator: '=',
                value: value.id,
              },
            ];

            window.open(
              getPageUrn(
                '/page/3f38d2fa-4eb5-47a7-b937-146872685fb5' +
                  '?' +
                  qs.stringify({
                    mopq: mopFilterToQueryParams(filters),
                  }),
              ),
            );
          },
        },
        {
          name: 'Replace',
          icon: 'refresh',
          disable: true,
        },
      ];
    case 'keyword_position': {
      return [
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Open search result page',
          icon: 'openPdp',
          onClickItem: (data) => {
            const countryCode = data.row['COUNTRY.country_code'];
            const keyword = data.row['ADS_PLACEMENT.name'];
            if (SHOPEE_SC_LINK[countryCode]) {
              window.open(`${SHOPEE_SC_LINK[countryCode]}/search?keyword=${keyword}`);
            }
          },
        },
        {
          name: 'Refresh',
          icon: 'refresh',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'View history',
          icon: 'history',
          disable: true,
        },
      ];
    }
    case 'campaign_daily_budget': {
      return [
        {
          title: 'Campaign daily budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.daily_budget',
            },
          },
        },
        {
          title: 'Schedule Daily Budget',
          name: 'Schedule budget',
          icon: 'pencil',
          editor: 'ScheduleDailyEditor',
          payload: {
            field: {
              adsType: 'ADS_CAMPAIGN',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'daily_budget',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
      ];
    }
    case 'campaign_total_budget': {
      return [
        {
          title: 'Campaign total budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SingleBudgetEditor',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.total_budget',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'total_budget',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
      ];
    }
    case 'ad_object_budget': {
      return [
        {
          title: 'Ad object budget',
          name: 'Edit budget',
          icon: 'pencil',
          editor: 'SelectionBudgetEditor',
          payload: {
            field: {
              totalBudget: 'ADS_OBJECT.total_budget',
              dailyBudget: 'ADS_OBJECT.daily_budget',
            },
          },
        },
        // {
        //   title: 'Schedule Daily Budget',
        //   name: 'Schedule budget',
        //   icon: 'pencil',
        //   editor: 'ScheduleDailyEditor',
        //   payload: {
        //     field: {
        //       adsType: 'ADS_OBJECT',
        //     },
        //   },
        // },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: ['total_budget', 'daily_budget'],
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
      ];
    }
    case 'keyword_match_type': {
      return [
        {
          title: 'Match type',
          name: 'Change match type',
          icon: 'pencil',
          editor: 'MatchTypeEditor',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.match_type',
            },
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'match_type',
              dimension_type: 'ADS_PLACEMENT',
            },
          },
        },
      ];
    }
    case 'campaign_timeline': {
      return [
        {
          title: 'Campaign timeline',
          name: 'Change timeline',
          icon: 'pencil',
          editor: 'TimeLineEditor',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'NOT_CONTAIN',
              values: ff.ui_change_timeline_campaign ? [] : ['ENDED'],
            },
          ],
          payload: {
            field: {
              date_from: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_from',
              date_to: 'ADS_CALENDAR.ADS_CAMPAIGN.timeline_to',
            },
            restrict: [
              {
                condition: {
                  field: 'ADS_CAMPAIGN.status',
                  operator: 'NOT_CONTAIN',
                  values: ['DRAFT', 'SCHEDULED'],
                },
                disableComponent: ['startDate'],
              },
            ],
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
      ];
    }
    case 'ad_object_timeline': {
      return [
        {
          title: 'Ad object timeline',
          name: 'Change timeline',
          icon: 'pencil',
          editor: 'TimeLineEditor',
          checkDisable: (row) => !!row['ADS_CAMPAIGN.id'],
          payload: {
            field: {
              date_from: 'ADS_CALENDAR.ADS_OBJECT.timeline_from',
              date_to: 'ADS_CALENDAR.ADS_OBJECT.timeline_to',
            },
            restrict: [
              {
                disableComponent: ['startDate'],
              },
            ],
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
      ];
    }
    case 'ad_object_bidding_price': {
      return [
        {
          title: 'Ad object bidding price',
          name: 'Change bidding price',
          icon: 'pencil',
          editor: 'BiddingPriceEditor',
          payload: {
            field: {
              value: 'ADS_OBJECT.base_bidding_price',
            },
            rowPicker: {
              marketplaceCode: 'MARKETPLACE.marketplace_code',
              toolCode: 'ADTOOL.ads_tool',
              storefrontId: 'STOREFRONT.id',
              masterObjectId: 'PRODUCT.id',
              name: 'ADS_OBJECT.name',
            },
            static: {
              primaryKey: 'ADS_OBJECT.id',
            },
            excludeOperator: [
              {
                operator: 'suggest-bid-price',
                field: 'ADTOOL.ads_tool',
                values: ff.enable_bidding_price ? [] : ['SHP_DA'],
              },
            ],
          },
        },
        {
          name: 'Set script',
          icon: 'setscript',
          disable: true,
        },
        {
          name: 'Filter by',
          icon: 'filter',
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'base_bidding_price',
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
      ];
    }
    case 'campaign_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_CAMPAIGN.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_CAMPAIGN.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_CAMPAIGN',
            },
          },
        },
        {
          title: 'Schedule Daily Status',
          name: 'Schedule status',
          icon: 'pencil',
          editor: 'ScheduleDailyEditor',
          payload: {
            field: {
              adsType: 'ADS_CAMPAIGN',
              type: 'status',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_CAMPAIGN',
            },
          },
        },
      ];
    }
    case 'ad_object_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED', 'INELIGIBLE'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_OBJECT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'Delete it',
          icon: 'delete',
          condition: [
            {
              field: 'ADS_OBJECT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          actionType: 'delete',
          payload: {
            static: {
              dimension: 'ADS_OBJECT',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_OBJECT',
            },
          },
        },
      ];
    }
    case 'keyword_status': {
      return [
        {
          name: 'Publish now',
          icon: 'upload',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SCHEDULED', 'ONGOING', 'PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'SUSPENDED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'ADS_PLACEMENT.status',
              operator: 'CONTAIN',
              values: ['SUSPENDED'],
            },
          ],
          payload: {
            field: {
              status: 'ADS_PLACEMENT.status',
            },
            static: {
              status: 'RECOVERING',
            },
          },
        },
        {
          name: 'History',
          icon: 'history',
          id: 'history',
          payload: {
            field: {
              field_name: 'status',
              dimension_type: 'ADS_PLACEMENT',
            },
          },
        },
      ];
    }
    case 'script_status': {
      return [
        {
          name: 'Pause it',
          icon: 'pausedRounded',
          condition: [
            {
              field: 'SCRIPT.script_status',
              operator: 'CONTAIN',
              values: ['ONGOING', 'SCHEDULED'],
            },
          ],
          payload: {
            field: {
              status: 'SCRIPT.script_status',
            },
            static: {
              status: 'PAUSED',
            },
          },
        },
        {
          name: 'Resume it',
          icon: 'refreshRounded',
          condition: [
            {
              field: 'SCRIPT.script_status',
              operator: 'CONTAIN',
              values: ['PAUSED'],
            },
          ],
          payload: {
            field: {
              status: 'SCRIPT.script_status',
            },
            static: {
              status: 'ONGOING',
            },
          },
        },
        {
          name: 'Draft it',
          icon: 'edit',
          condition: [
            {
              field: 'SCRIPT.script_status',
              operator: 'CONTAIN',
              values: ['SCHEDULED'],
            },
          ],
          payload: {
            field: {
              status: 'SCRIPT.script_status',
            },
            static: {
              status: 'DRAFT',
            },
          },
        },
        {
          name: 'Publish it',
          icon: 'upload',
          condition: [
            {
              field: 'SCRIPT.script_status',
              operator: 'CONTAIN',
              values: ['DRAFT'],
            },
          ],
          payload: {
            field: {
              status: 'SCRIPT.script_status',
            },
            static: {
              status: 'PUBLISHING',
            },
          },
        },
      ];
    }
    // case '_metric': {
    //   return [
    //     {
    //       name: 'Set script',
    //       icon: 'setscript',
    //       disable: true,
    //     },
    //     {
    //       name: 'Refresh',
    //       icon: 'refresh',
    //       disable: true,
    //     },
    //     {
    //       name: 'Filter by',
    //       icon: 'filter',
    //     },
    //   ];
    // }
    case 'ad_object_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_OBJECT.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'campaign_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_CAMPAIGN.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'keyword_tag': {
      return [
        {
          title: 'Tag name',
          name: 'Add new tag',
          icon: 'plus',
          editor: 'CreateTagEditor',
        },
        {
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TagEditor',
          payload: {
            column: column,
            field: {
              value: 'ADS_PLACEMENT.general_tag',
            },
          },
        },
        {
          title: 'Remove tag',
          name: 'Remove tag',
          icon: 'remove',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'sub_item_tag': {
      return [
        {
          title: 'Edit tag',
          name: 'Edit tag',
          icon: 'pencil',
          editor: 'TaglineEditor',
        },
        {
          title: 'Delete Tag',
          name: 'Delete',
          icon: 'delete',
          editor: 'DeleteEditor',
          type: 'deleteEditor',
        },
      ];
    }
    case 'campaign_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_CAMPAIGN.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_CAMPAIGN.note',
            },
          },
        },
      ];
    }
    case 'ad_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_PLACEMENT.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_PLACEMENT.note',
            },
          },
        },
      ];
    }
    case 'ad_object_note': {
      return [
        {
          title: 'Note',
          name: 'Edit note',
          icon: 'pencil',
          editor: 'NameEditor',
          payload: {
            field: {
              label: 'ADS_OBJECT.note',
            },
          },
        },
        {
          name: 'Remove note',
          icon: 'remove',
          actionType: 'removeEmpty',
          payload: {
            field: {
              value: 'ADS_OBJECT.note',
            },
          },
        },
      ];
    }
    case 'first_search_slot': {
      return [];
    }
    case 'max_bidding_price': {
      return [];
    }
  }
};

export const staticFilter = {
  campaignSettings: [
    {
      type: 'groupFilter',
      static: true,
      logicalOperator: 'and',
      subFilters: [
        {
          field: 'marketplace',
          logicalOperator: 'and',
          static: true,
          queryType: 'is',
          queryValue: 'LAZADA',
          queryValueLabel: 'Lazada',
          dataType: 'string',
        },
        {
          field: 'ads_gmv',
          dataType: 'float',
          logicalOperator: 'and',
          static: false,
          queryType: '>',
          queryValue: '0',
        },
        {
          field: 'campaign_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
      ],
    },
  ],
  adObjectOptimization: [
    {
      type: 'groupFilter',
      static: true,
      logicalOperator: 'and',
      subFilters: [
        {
          field: 'marketplace',
          logicalOperator: 'and',
          static: true,
          queryType: 'is',
          queryValue: 'LAZADA',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Lazada' } : {}),
          dataType: 'string',
        },
        {
          field: 'campaign_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
        {
          field: 'ad_object_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'SUSPENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Paused' } : {}),
        },
      ],
    },
  ],
  adOptimization: [
    {
      type: 'groupFilter',
      static: true,
      logicalOperator: 'and',
      subFilters: [
        {
          field: 'marketplace',
          logicalOperator: 'and',
          static: true,
          queryType: 'is',
          queryValue: 'LAZADA',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Lazada' } : {}),
          dataType: 'string',
        },
        {
          field: 'campaign_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
        {
          field: 'ad_object_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'SUSPENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Paused' } : {}),
        },
        {
          field: 'keyword_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
      ],
    },
  ],
  programmaticSettings: [
    {
      type: 'groupFilter',
      static: true,
      logicalOperator: 'and',
      subFilters: [
        {
          field: 'marketplace',
          logicalOperator: 'and',
          static: true,
          queryType: 'is',
          queryValue: 'LAZADA',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Lazada' } : {}),
          dataType: 'string',
        },
        {
          field: 'campaign_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
        {
          field: 'script_status',
          dataType: 'string',
          logicalOperator: 'and',
          queryType: 'is_not',
          queryValue: 'ENDED',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Ended' } : {}),
        },
      ],
    },
  ],
  default: [
    {
      type: 'groupFilter',
      static: true,
      logicalOperator: 'and',
      subFilters: [
        {
          field: 'marketplace',
          logicalOperator: 'and',
          static: true,
          queryType: 'is',
          queryValue: 'LAZADA',
          ...(ff.filter_mobile_menu ? { queryValueLabel: 'Lazada' } : {}),
          dataType: 'string',
        },
      ],
    },
  ],
};
