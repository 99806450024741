export const CHART_CONFIG = {
  title: 'Area Chart',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {},
  primaryKeys: [],
  system: { hiddenComponents: ['search', 'timeline', 'majorButton'] },
  mainButton: {},
  requestHiddenField: {},
  mapping: {
    country: {
      title: 'Country',
      propertyType: 'dimension',
      dataType: 'string',
      valueGetter: {
        value: 'country',
      },
    },
  },
  settingType: [
    { type: 'dimension', label: 'Dimension' },
    { type: 'attribute', label: 'Attribute' },
    { type: 'metric', label: 'Metric' },
    { type: 'traction', label: 'Traction' },
  ],
};

export const FORMAT_CHART = {
  traction: {
    period: 'daily',
    formatTime: 'YYYY/MM/DD',
  },
};
