import { aim, usePage } from '@eip/next/lib/main';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { AppBar, Box, Grid, GridProps, LinearProgress, makeStyles, Toolbar, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import { Tooltip } from '@material-ui/core';
import { BreadcrumbsComponent, TypeBreadcrumbs } from './bread-crumbs';
import DynamicActionDot from './dynamic-action-dot';
import { TypeUserAvatar } from './group-avatar';
import Icon from '@ep/insight-ui/icons/Icon';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import StyledTooltipWithMessages from '../tooltip/styled-tooltip';
import FeedbackActions from './feedback-actions';
import PageView from './page-view';
import { useLocation } from 'react-router';

type PropsStyle = {
  drawerWidth: number;
};
const useStyles = makeStyles((theme) => ({
  container: {
    justifyContent: 'space-between',
    backgroundColor: '#fff',
    padding: '0 18px',
    '&.ml-open': {
      paddingLeft: 25,
    },
    '&.ml-close': {
      paddingLeft: 35,
    },
  },
  label: {
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#253746',
    '&.highline': {
      fontWeight: 500,
      fontSize: '14px',
    },
  },
  icon: {
    width: '32px',
    height: '32px',
    color: '#8C98A4',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: colors.action.secondaryHovered,
    },
    '&.disable': {
      pointerEvents: 'none',
      '& svg': {
        color: '#E4E7E9',
      },
    },
  },
  textSecondary: {
    fontSize: '14px',
    lineHeight: '16px',
    fontStyle: 'normal',
    color: '#000',
    fontWeight: 400,
    marginLeft: '0px',
    width: '100%',
    whiteSpace: 'nowrap',
    textTransform: 'capitalize',
  },
  avatarUser: {
    borderRadius: '100%',
    border: '1px solid rgba(37, 55, 70, 0.08)',
    height: 32,
    width: 32,
  },
  appBarShift: ({ drawerWidth }: PropsStyle) => ({
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  gridUser: {
    padding: '0 10px',
  },
  iconAction: {
    '& div': {
      display: 'flex',
      marginLeft: '8px',
      alignItems: 'center',
      cursor: 'pointer',
      '&:hover': {
        background: '#E4E7E9',
      },
      padding: '4px',
      borderRadius: '4px',
    },
  },
  progress: {
    display: 'flex',
    alignItems: 'center',
    width: '200px',
    '& .eip1-MuiLinearProgress-barColorPrimary': { backgroundColor: '#1f496f' },
    '& span': { color: '#1f496f' },
  },
  tooltipContainer: {
    background: '#253746',
  },
}));

type favorite = {
  isDetailPage: boolean;
  nodeId: string;
  label: string;
};

interface TypeGlobalNavigation extends GridProps {
  drawerWidth: number;
  openSideBar: boolean;
  dotMenu: DropdownMenuDataType[][];
  dataBreadcrumbs: Array<TypeBreadcrumbs>;
  dataListUser: Array<TypeUserAvatar>;
  headerLimitUsers?: number;
  favorites?: favorite[];
  updateFavorites?: (favorites: favorite[]) => void;
  sandboxPageInformation?: any;
}
const Globalavigation = ({
  drawerWidth,
  openSideBar,
  dotMenu,
  dataBreadcrumbs,
  dataListUser,
  headerLimitUsers = 5,
  ...rest
}: TypeGlobalNavigation) => {
  const classes = useStyles({ drawerWidth: drawerWidth });
  const { title } = usePage();
  const pageHref = (location.href.match(/page.*/)?.[0] || '').replace('page/', '');
  const pageId = pageHref.split('/')[0] || '';
  const entityId = pageHref.split('/')[1] || '';
  const isFavorite =
    (entityId === 'default' && rest.favorites.some((fv) => fv.nodeId === pageId)) ||
    (entityId != 'default' && rest.favorites.some((fv) => pageHref.startsWith(fv.nodeId)));

  const [favorited, setFavorited] = React.useState(false);
  const pageSandboxInformation = get(rest, ['sandboxPageInformation', pageId], null);
  const { status, createdBy, createdAt, process } = pageSandboxInformation || {};
  const userEmail = aim.getUserSettings().profile?.userEmail;
  const pageSandboxInformationMessages = React.useMemo(() => {
    if (!pageSandboxInformation) return [];
    const messages = [
      `${['init', 'processing'].includes(status) ? `Generating` : `Generated`} sandbox data - created ${moment(
        createdAt,
      ).fromNow()} by ${createdBy}`,
    ];

    return messages;
  }, [pageSandboxInformation]);
  return (
    <AppBar
      position="fixed"
      style={{ boxShadow: 'none', height: '67px' }}
      className={clsx(classes.appBar, {
        [classes.appBarShift]: openSideBar,
      })}
    >
      <Toolbar style={{ background: 'transparent', padding: 0, margin: 0 }}>
        <Grid
          container
          className={`${classes.container} ${openSideBar ? 'ml-open' : 'ml-close'}`}
          alignItems="center"
          justifyContent={'space-between'}
          spacing={2}
          {...rest}
        >
          {/* button arrow & breakbrumbs  */}
          <Grid item style={{ flex: '1', overflow: 'hidden' }}>
            <Grid container alignContent={'center'} spacing={6}>
              {/* breakbrumbs */}
              <Grid item style={{ display: 'flex', columnGap: '4px', alignItems: 'center' }}>
                {status === 'finish' ? (
                  <StyledTooltipWithMessages messages={pageSandboxInformationMessages}>
                    <Box sx={{ minWidth: 14, cursor: 'default', display: 'flex', alignItems: 'center' }}>
                      <Icon type={'ic/material-symbols:check-circle-rounded/blue6'} />
                    </Box>
                  </StyledTooltipWithMessages>
                ) : null}
                <BreadcrumbsComponent routes={dataBreadcrumbs} />
                {['init', 'processing'].includes(status) ? (
                  <Box className={classes.progress}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                      <LinearProgress variant="determinate" value={process} />
                    </Box>
                    <StyledTooltipWithMessages messages={pageSandboxInformationMessages}>
                      <Box sx={{ minWidth: 35, cursor: 'default' }}>
                        <span variant="body2">{`${Math.round(process)}%`}</span>
                      </Box>
                    </StyledTooltipWithMessages>
                  </Box>
                ) : status === 'finish' ? (
                  <Box display={'flex'} alignItems={'center'}>
                    <FeedbackActions pageId={pageId} />
                  </Box>
                ) : null}
              </Grid>
            </Grid>
          </Grid>

          {/* list users & menu action  */}
          <Grid item style={{ whiteSpace: 'nowrap' }}>
            <Grid container alignItems={'center'} justifyContent={'flex-end'}>
              {/* list user */}
              <div style={{ color: '#8C98A4', marginRight: '0.2em' }}>
                <PageLastEditedOn />
              </div>
              <div className="page-observability" style={{ color: '#000000de' }}></div>
              {String(userEmail).endsWith('@epsilo.io') ? <PageView></PageView> : null}
              <MonitorContainer mId={'page-shortcut'} mLabel={'Page shortcut'}>
                {pageId && (
                  <Tooltip
                    title={isFavorite ? 'Remove from your favorites' : 'Add to your favorites'}
                    placement={'bottom'}
                    classes={{ tooltip: classes.tooltipContainer }}
                  >
                    <Grid
                      item
                      className={classes.iconAction}
                      onClick={() => {
                        const newFavorites = isFavorite
                          ? rest.favorites.filter((fv) =>
                              entityId != 'default' ? fv.nodeId !== pageHref : fv.nodeId !== pageId,
                            )
                          : rest.favorites.concat({
                              nodeId: entityId != 'default' ? pageHref : pageId,
                              isDetailPage: entityId != 'default',
                              label: title || 'Untitled',
                            });
                        rest.updateFavorites(newFavorites);
                      }}
                    >
                      <MonitorContainer
                        mId={isFavorite ? 'remove-from-favorites' : 'add-to-favorites'}
                        mLabel={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                        aria-label={isFavorite ? 'remove-from-favorites' : 'add-to-favorites'}
                      >
                        <Icon
                          type={isFavorite ? 'ic/ic:outline-star/yellow' : 'ic/ic:outline-star-border/#0000008a'}
                          size={24}
                          aria-label={isFavorite ? 'remove-from-favorites' : 'add-to-favorites'}
                        />
                      </MonitorContainer>
                    </Grid>
                  </Tooltip>
                )}
              </MonitorContainer>
              <Grid item>
                <DynamicActionDot dotMenu={dotMenu} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Globalavigation;

function PageLastEditedOn() {
  const { currentPage } = usePage();
  const pageVersion = get(currentPage, 'properties.systemVersion', null);
  const pageUpdatedBy = get(currentPage, 'updatedBy', null);
  const { search } = useLocation();
  const queryParams = new Proxy(new URLSearchParams(String(search).replace(/.+(?=\?)/, '')), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  });
  const { q } = queryParams;

  const [lastEditedOn, setLastEditedOn] = React.useState(null);
  const [lastEditedBy, setLastEditedBy] = React.useState(null);
  const [interval, setInterval] = React.useState(0);

  React.useEffect(() => {
    if (!!pageVersion && aim.canAccess('internal')) {
      if (!q) {
        setLastEditedOn(pageVersion);
        setLastEditedBy(pageUpdatedBy);
      } else {
        const maxLastUpdated = String(q)
          .split(',')
          .reduce((carry, blockId) => {
            const blockDetail = currentPage.nodes.find((node) => node.blockEid == blockId);
            if (!blockDetail) return carry;
            if (!carry.updatedAt || carry.updatedAt < blockDetail.updatedAt) {
              return {
                updatedAt: blockDetail.updatedAt,
                updatedBy: blockDetail.updatedBy,
              };
            }
            return carry;
          }, {});

        if (maxLastUpdated.updatedAt) {
          setLastEditedOn(maxLastUpdated.updatedAt);
          setLastEditedBy(maxLastUpdated.updatedBy);
        } else {
          setLastEditedOn(pageVersion);
          setLastEditedBy(pageUpdatedBy);
        }
      }
    }
  }, [pageVersion, currentPage, pageUpdatedBy, q]);

  React.useEffect(() => {
    if (lastEditedOn) {
      const timeout = 1000 * 30;
      const timeoutEvent = setTimeout(() => {
        setInterval((value) => value + timeout);
      }, timeout);

      return () => {
        clearTimeout(timeoutEvent);
      };
    }
  }, [lastEditedOn, interval]);

  return (
    <div>
      {lastEditedOn ? 'Updated ' + moment(lastEditedOn).fromNow() : ''} <PageUpdatedBy userId={lastEditedBy} />{' '}
      {lastEditedOn ? '•' : ''}
    </div>
  );
}

const _cachedPageUpdatedBy = null;
function PageUpdatedBy({ userId }) {
  if (typeof userId !== 'string' || !userId.includes('@epsilo.io')) return null;
  return <span style={{ textDecoration: 'none' }}>by {userId}</span>;
}
