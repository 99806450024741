import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const DeleteView = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.71 3.29C12.6166 3.19732 12.5057 3.12399 12.3839 3.07423C12.2621 3.02446 12.1316 2.99924 12 3H10C10 2.73478 9.89464 2.48043 9.70711 2.29289C9.51957 2.10536 9.26522 2 9 2H7C6.73478 2 6.48043 2.10536 6.29289 2.29289C6.10536 2.48043 6 2.73478 6 3H4C3.73478 3 3.48043 3.10536 3.29289 3.29289C3.10536 3.48043 3 3.73478 3 4C3 4.26522 3.10536 4.51957 3.29289 4.70711C3.48043 4.89464 3.73478 5 4 5H12C12.2652 5 12.5196 4.89464 12.7071 4.70711C12.8946 4.51957 13 4.26522 13 4C13.0008 3.86839 12.9755 3.73793 12.9258 3.61609C12.876 3.49426 12.8027 3.38344 12.71 3.29Z"
      fill={props.color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9852 6H4.99786C4.85515 5.99879 4.71384 6.02823 4.58347 6.08633C4.45309 6.14444 4.33669 6.22985 4.24211 6.33682C4.14753 6.44379 4.07697 6.56982 4.0352 6.70641C3.99343 6.84299 3.98142 6.98695 3.99998 7.12858L4.74839 13.121C4.77781 13.3646 4.89579 13.5889 5.07984 13.751C5.26389 13.9131 5.50112 14.0017 5.74628 13.9999H10.2367C10.4819 14.0017 10.7191 13.9131 10.9032 13.751C11.0872 13.5889 11.2052 13.3646 11.2346 13.121L11.983 7.12858C12.0016 6.98695 11.9896 6.84299 11.9478 6.70641C11.906 6.56982 11.8355 6.44379 11.7409 6.33682C11.6463 6.22985 11.5299 6.14444 11.3996 6.08633C11.2692 6.02823 11.1279 5.99879 10.9852 6Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default DeleteView;
