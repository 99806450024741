import * as React from 'react';
import { get } from 'lodash';

import { makeStyles, Box } from '@material-ui/core';

import { IValueSingleBudgetFormat } from '@ep/insight-ui/elements/table/format/budgets/single-budget-format';
import HeaderList from '@ep/insight-ui/elements/table/header-list';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import ScheduleDaily from '../schedule-daily';

import { useScheduleDaily } from '../schedule-daily/hooks/use-schedule-daily';
import GroupButtonSubmit from '../../group-button-submit';
import ViewHistory from '../schedule-daily/view-history';

/** Style */
const useStyles = makeStyles(() => ({
  header: {
    paddingLeft: '0px',
  },
}));

type Props = {
  data: {
    value: IValueSingleBudgetFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  popoverRef: {
    current: {
      updatePosition: () => void;
    } | null;
  };
};

const ScheduleDailyEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl, popoverRef, ...rest }: Props) => {
  const classes = useStyles();

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;

  const currency = get(data, ['value', 'currency'], '');
  const adsType = get(rest, ['payload', 'field', 'adsType'], '');
  const adsUnit = get(rest, ['payload', 'field', 'adsUnit'], '');
  const objectIdField = get(rest, ['payload', 'field', 'objectIdField'], '');
  const namespace = get(rest, ['payload', 'field', 'namespace'], '');
  const type = get(rest, ['payload', 'field', 'type'], 'daily_budget');
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  const handleClosed = () => setAnchorEl(null);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    await handleDynamicSubmit();
    setIsSubmitting(false);
  };

  const rowData = get(data, ['node', 'data'], {});
  const fieldId = objectIdField || `${adsType}.id`;
  const adsId = get(rowData, [fieldId], '');

  const {
    items,
    setItems,
    handleAdd,
    handleDelete,
    handleSubmit: handleDynamicSubmit,
    handleUpdate,
    isLoading,
    dateFormat,
  } = useScheduleDaily({
    data,
    title,
    onSubmit,
    anchorEl,
    setAnchorEl,
    adsType,
    adsUnit,
    namespace,
    handleClosed,
    type,
    rowData,
    fieldId,
    adsId,
    popoverRef,
  });

  return (
    <div
      style={{ padding: '0 10px', minWidth: '320px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <HeaderList title={title} variant="h4" className={classes.header} />
        <ViewHistory
          {...{
            data,
            adsType,
            type,
            rowData,
            fieldId,
            adsId,
            currency,
            adsUnit,
            objectIdField,
            onViewHistoryOpen: rest.onViewHistoryOpen,
          }}
        />
      </Box>
      <ScheduleDaily
        {...{
          items,
          setItems,
          handleAdd,
          handleDelete,
          handleUpdate,
          isLoading,
          currency: currency,
          nodeData: get(data, ['node', 'data'], {}),
          dateFormat,
          type,
        }}
      />
      <GroupButtonSubmit
        onCancel={handleClosed}
        onSubmit={handleSubmit}
        isSubmitting={isSubmitting}
        disabled={{ submit: items.length === 0 }}
      />
    </div>
  );
};

export default ScheduleDailyEditor;
