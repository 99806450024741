import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const ColorText = (props: SvgIconProps) => (
  <SvgIcon
    {...props}
    width="12"
    height="12"
    viewBox="-2.5 -2.5 15.5 15.5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.39587 0.5536L0.0273597 9.4611C0.000941273 9.52247 -0.00648604 9.58789 0.00569173 9.65196C0.0178695 9.71603 0.0493028 9.77691 0.0973941 9.82956C0.145485 9.88221 0.208854 9.92513 0.282265 9.95477C0.355675 9.9844 0.437019 9.99991 0.519577 10H1.5935C1.76052 10 1.92312 9.95796 2.05743 9.88007C2.19173 9.80217 2.29064 9.69252 2.33963 9.56724L3 8H7L7.57607 9.56724C7.62506 9.69252 7.72398 9.80217 7.85828 9.88007C7.99259 9.95796 8.15519 10 8.32221 10H9.48042C9.56298 9.99991 9.64433 9.9844 9.71774 9.95477C9.79115 9.92513 9.85451 9.88221 9.90261 9.82956C9.9507 9.77691 9.98213 9.71603 9.99431 9.65196C10.0065 9.58789 9.99906 9.52247 9.97264 9.4611L6.59788 0.552783C6.52771 0.39146 6.39496 0.251413 6.21823 0.15228C6.0415 0.0531464 5.82966 -0.000102295 5.61241 1.47535e-07H4.38135C4.16397 -4.42526e-05 3.95204 0.0533232 3.77529 0.152611C3.59855 0.2519 3.46587 0.392124 3.39587 0.5536ZM4.93184 2.27263H5.0625L6.5 6H3.5L4.93184 2.27263Z"
    />
  </SvgIcon>
);

export default ColorText;
