import { EIP_CONSTANT } from '@eip/next/lib/main';
import moment from 'moment';

export const MASS_OPERATION_DASHBOARD_CONFIG = {
  title: 'Performance statistics',
  dimension: [],
  metric: ['processing_request', 'processing_item', 'failed_request', 'failed_item'],
  attribute: [],
  dateRange: {
    dateFrom: moment().format(EIP_CONSTANT.DATE_FORMAT),
    dateTo: moment().format(EIP_CONSTANT.DATE_FORMAT),
  },
  endpoint: {
    GET_METRIC_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/metrics.jsp',
    GET_CHART_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/charts.jsp',
  },
  useDataSource: false,
  primaryKeys: [],
  tableType: 'mass-operation',
  hiddenFilter: {
    currency: 'USD',
    timing: 'hourly',
  },
  mapping: {
    // ============= DIMENSION =============
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'country_code',
      valueGetter: {
        id: 'COUNTRY.country_code',
      },
    },
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'marketplace_code',
      valueGetter: {
        id: 'tool_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'storefront_id',
      valueGetter: {
        id: 'storefront_id',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'tool_code',
      valueGetter: {
        id: 'tool_code',
      },
    },

    // ============= METRIC =============
    processing_request: {
      title: 'PROCESSING REQUEST',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'processing_request',
      },
    },
    processing_item: {
      title: 'PROCESSING ITEM',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'processing_item',
      },
    },
    failed_request: {
      title: 'FAILED REQUEST',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'failed_request',
      },
    },
    failed_item: {
      title: 'FAILED ITEM',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      valueGetter: {
        value: 'failed_item',
      },
    },
  },
  views: [
    {
      id: 'view_name',
      name: 'View name',
    },
  ],
  view: {
    id: 'view_name',
    name: 'View name',
  },
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  pinnedColumn: [],
  columnWidth: [],
  sort: [],
  filter: [],
  search: '',
  system: {
    hiddenComponents: ['sort', 'search', 'majorButton'],
  },
};
