import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Search = (props: SvgIconProps) => (
  <SvgIcon {...props} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 3.1375 3.1375 0 7 0C10.8625 0 14 3.1375 14 7C14 8.60666 13.4571 10.0879 12.545 11.27L17.7375 16.4625C18.0875 16.8125 18.0875 17.3875 17.7375 17.7375C17.3875 18.0875 16.8125 18.0875 16.4625 17.7375L11.27 12.545C10.0879 13.4571 8.60667 14 7 14C3.1375 14 0 10.8625 0 7ZM2 7C2 9.7625 4.2375 12 7 12C9.7625 12 12 9.7625 12 7C12 4.2375 9.7625 2 7 2C4.2375 2 2 4.2375 2 7Z"
    />
  </SvgIcon>
);

export default Search;
