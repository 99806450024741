import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { INPUT_SPECIAL_KEY_DISABLE } from '@ep/insight-ui/elements/textField/special-key';
import TextFieldNumber from '@ep/insight-ui/elements/textField/textField-number';
import { responsiveTextField } from '@ep/insight-ui/lib/epsilo-theme';
import { Grid, InputProps, makeStyles, SelectProps } from '@material-ui/core';
import * as React from 'react';
import FormControlTemplate from './form-control-template';
/**
 * ff.script_detail_add_warning_text:start
 */
import Typography from '@ep/insight-ui/elements/text-style/Typography';
/**
 * ff.script_detail_add_warning_text:end
 */
const useStyles = makeStyles({
  ...responsiveTextField,
  inputNumberValue: {
    margin: 0,
  },
  ...(ff.script_detail_add_warning_text
    ? {
        typography: {
          marginTop: '7px',
        },
      }
    : {}),
});
interface SelectItem extends SelectProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}
interface ValueProps extends InputProps {
  value: string;
  onChangeNumber: (e: any) => void;
}
type Props = {
  label: string;
  operators: OptionSelectType[];
  operatorProps: SelectItem;
  valueProps: ValueProps;
  currency?: string;
  disabled?: boolean;
};
const OperatorValue = ({ label, operators, operatorProps, valueProps, currency = '', disabled }: Props) => {
  const classes = useStyles();
  // const defaultOperator = React.useMemo(() => {
  //   return operatorProps.value;
  // }, [operatorProps.value]);
  const [operator, setOperator] = React.useState(operatorProps.value);
  React.useEffect(() => {
    if (operator !== operatorProps.value) setOperator(operatorProps.value);
  }, [operatorProps.value]);
  const handleChangeOperator = (e) => {
    if (e.target.value) {
      setOperator(e.target.value);
      operatorProps.onChange(e);
    }
  };
  if (ff.script_detail_add_warning_text) {
    return (
      <div>
        <FormControlTemplate label={label}>
          <Grid container spacing={3}>
            {operator !== INPUT_SPECIAL_KEY_DISABLE && (
              <Grid item xs={valueProps.value == INPUT_SPECIAL_KEY_DISABLE ? 12 : 4}>
                {/* operator */}
                <SelectForm
                  {...operatorProps}
                  className={`${classes.inputSelect} xs`}
                  options={operators}
                  value={operator}
                  onChange={handleChangeOperator}
                  placeholder={'Choose'}
                  disabled={disabled}
                />
              </Grid>
            )}
            {valueProps.value !== INPUT_SPECIAL_KEY_DISABLE && (
              <Grid item xs={8}>
                <TextFieldNumber
                  {...valueProps}
                  value={valueProps.value}
                  style={{ width: '100%' }}
                  placeholder={'Your value'}
                  className={`${classes.inputNumber} md ${classes.inputNumberValue}`}
                  unit={currency}
                  disabledIncreaseDecrease
                  disabled={disabled}
                />
                <Typography className={classes.typography} variant="body5">
                  Epsilo might round up/down to follow marketplace policy
                </Typography>
              </Grid>
            )}
          </Grid>
        </FormControlTemplate>
      </div>
    );
  }
  return (
    <div>
      <FormControlTemplate label={label}>
        <Grid container spacing={3}>
          {operator !== INPUT_SPECIAL_KEY_DISABLE && (
            <Grid item xs={valueProps.value == INPUT_SPECIAL_KEY_DISABLE ? 12 : 4}>
              {/* operator */}
              <SelectForm
                {...operatorProps}
                className={`${classes.inputSelect} xs`}
                options={operators}
                value={operator}
                onChange={handleChangeOperator}
                placeholder={'Choose'}
                disabled={disabled}
              />
            </Grid>
          )}
          {valueProps.value !== INPUT_SPECIAL_KEY_DISABLE && (
            <Grid item xs={8}>
              <TextFieldNumber
                {...valueProps}
                value={valueProps.value}
                style={{ width: '100%' }}
                placeholder={'Your value'}
                className={`${classes.inputNumber} md ${classes.inputNumberValue}`}
                unit={currency}
                disabledIncreaseDecrease
                disabled={disabled}
              />
            </Grid>
          )}
        </Grid>
      </FormControlTemplate>
    </div>
  );
};

export default OperatorValue;
