import * as React from 'react';
import BulletChartResponsive from '../../../chartlib/bullet-chart';
import { IStateChartValue } from '../../../chartlib/chart-loading-state/chart-loading-state';
import { ChartTabularData } from '@carbon/charts/interfaces';

const data: ChartTabularData = [
  {
    title: 'Item G',
    group: 'TEST 7',
    ranges: [350, 650, 980],
    marker: 1575,
    value: 400,
  },
  {
    title: 'Item F',
    group: 'TEST 6',
    ranges: [750, 1200, null],
    marker: 1725,
    value: 2100,
  },
  {
    title: 'Item E',
    group: 'TEST 5',
    ranges: [350, 500, 1005],
    marker: 1340,
    value: 550,
  },
  {
    title: 'Item D',
    group: 'TEST 4',
    ranges: [300, 895, 1600],
    marker: 1455,
    value: 1000,
  },
  {
    title: 'Item C',
    group: 'TEST 3',
    ranges: [800, 1000, 1400],
    marker: 1275,
    value: 250,
  },
  {
    title: 'Item B',
    group: 'TEST 2',
    ranges: [300, 895, 1600],
    marker: 1455,
    value: 1000,
  },
  {
    title: 'Item A',
    group: 'TEST 1',
    ranges: [800, 1000, 1400],
    marker: 1275,
    value: 250,
  },
];
const BulletChart = () => {
  const stateChart: IStateChartValue = 'success';
  return (
    <div style={{ marginBottom: '200px', height: '50vh' }}>
      <BulletChartResponsive
        keyChart={'bullet_demo'}
        data={data}
        stateChart={stateChart}
        title={'ABC'}
        value={'600'}
        currency={'IDR'}
        percent={15}
      />
    </div>
  );
};

export default BulletChart;
