import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import { formatCurrency } from '@ep/insight-ui/lib/number';
// import { IRowEDashboard, IDateValue } from '.././';
import { dataType, IData, IOption } from '@ep/insight-ui/chartlib/type';
import StackedChartResponsive from '@ep/insight-ui/chartlib/stacked-chart/stacked-chart-responsive';
import { IRowEDashboard } from '../mocks/billing';
import { funcConfigs } from '@ep/insight-ui/chartlib/stacked-chart/annotation/annotation';
import ChartState from '@ep/insight-ui/chartlib/chart-loading-state';
import { IStatusChartBB } from '../dashboard-container';
import { IStateChartValue } from '@ep/insight-ui/chartlib/chart-loading-state/chart-loading-state';
import { colorsChartLegend } from '@ep/insight-ui/chartlib/helper';

type IStackedChart = {
  data: IRowEDashboard;
  statusChart: IStatusChartBB;
};

const StackedChartEDashboard = ({ data, statusChart }: IStackedChart) => {
  const [dataList, setDataList] = React.useState<IData>();
  const [optionList, setOptionList] = React.useState<any>();

  let thisMonthSerries: Array<{ date: string; value: number }> = _.get(data, 'this_time_value_serries', []);
  let lastMonthSerries: Array<{ date: string; value: number }> = _.get(data, 'last_time_value_serries', []);
  let rows;
  if (ff.revive_all_charts) {
    rows = _.get(data, 'rows', []);
    rows.forEach((item) => {
      thisMonthSerries = item.this_time_value_serries;
      lastMonthSerries = item.last_time_value_serries;
    });
  }
  const stateChart = React.useMemo((): IStateChartValue => {
    switch (statusChart) {
      case 'success': {
        if (thisMonthSerries.length == 0 && lastMonthSerries.length == 0) return 'empty';
        return 'success';
      }
      case 'error':
        return 'error';
      default:
        return 'loading';
    }
  }, [statusChart, thisMonthSerries.length, lastMonthSerries.length]);

  const { externalTooltipHandler } = funcConfigs({});
  React.useEffect(() => {
    let thisMonthSerries: Array<{ date: string; value: number }> = _.get(data, 'this_time_value_serries', []);
    let lastMonthSerries: Array<{ date: string; value: number }> = _.get(data, 'last_time_value_serries', []);
    if (ff.revive_all_charts) {
      rows = _.get(data, 'rows', []);
      rows.forEach((item) => {
        thisMonthSerries = item.this_time_value_serries;
        lastMonthSerries = item.last_time_value_serries;
      });
    }
    const thisMonthData = [];
    const lastMonthData = lastMonthSerries.map((item) => item.value);
    const labels = thisMonthSerries.map((item) => {
      return new Intl.DateTimeFormat('en-US', { month: 'short', year: 'numeric' }).format(new Date(item.date));
    });
    const tempDashLine = [];
    thisMonthSerries.forEach((item) => {
      if (moment(item.date).isBefore(moment().format())) {
        thisMonthData.push(item.value);
      } else {
        tempDashLine.push(item.value);
      }
    });
    setDataList({
      labels: labels,
      datasets: [
        {
          label: 'Storefront fee',
          data: thisMonthData,
          backgroundColor: colorsChartLegend[0].stroke,
          borderColor: colorsChartLegend[0].stroke,
          hoverBackgroundColor: colorsChartLegend[0].hover,
          stack: '1',
          active: 'active',
        },
        {
          label: 'User fee',
          data: thisMonthData
            .map((item, index) => (index == thisMonthData.length - 1 ? item : null))
            .concat(tempDashLine),
          backgroundColor: colorsChartLegend[1].stroke,
          borderColor: colorsChartLegend[1].stroke,
          hoverBackgroundColor: colorsChartLegend[1].hover,
          borderDash: [5, 5],
          stack: '1',
          active: 'active',
        },
        {
          label: 'Take rate',
          data: lastMonthData,
          backgroundColor: colorsChartLegend[2].stroke,
          borderColor: colorsChartLegend[2].stroke,
          hoverBackgroundColor: colorsChartLegend[2].hover,
          stack: '1',
          active: 'active',
        },
      ],
    });

    //Display option chart
    setOptionList({
      responsive: true,
      maintainAspectRatio: true,
      datasets: {
        bar: {
          // barThickness: 47,
        },
      },
      scales: {
        yAxes: {
          stacked: true,
          display: true,
          suggestedMin: 0,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
            callback: (value) => {
              return value;
            },
          },
        },
        xAxes: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            labels: labels,
            fontSize: 14,
            fontWeight: 400,
            color: '#253746',
          },
        },
      },

      plugins: {
        legend: {
          display: false,
        },
        filler: {
          propagate: false,
        },
        tooltip: {
          enabled: false,
          mode: 'nearest',
          callbacks: {
            //Custom show currency chart
            label: (context) => {
              const label = _.get(context, ['dataset', 'label'], '');
              const textKey = _.get(context, ['dataset', 'textKey'], '');
              const colorTooltip = _.get(context, ['dataset', 'colorTooltip'], '');
              const raw = _.get(context, ['raw'], 0);
              return {
                label,
                textKey,
                value: context.parsed.y,
                percent: `(${100 / 10}%)`,
                colorTooltip,
                specialKey: raw !== 0,
              };
            },
          },
          external: externalTooltipHandler,
        },
      },
    });
  }, [data]);
  return (
    <ChartState stateChart={stateChart}>
      <StackedChartResponsive key={'billing'} dataList={dataList} optionsList={optionList} />
    </ChartState>
  );
};

export default StackedChartEDashboard;
