import { get } from 'lodash';
import * as React from 'react';
import { generatePath } from 'react-router';

type Props = {
  data: {
    value: any;
    node: any;
  };
  payload: Record<string, string>;
  title: string;
  onSubmit: (value: any) => void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};

const LinkTo = ({ data, payload, title = '', onSubmit, anchorEl, setAnchorEl }: Props) => {
  const urlPatt: string = get(payload, 'url');

  if (!urlPatt) return <span>opening link...</span>;

  const linkUrl = generatePath(urlPatt, data.value);

  window.location.href = linkUrl;

  return <span>opening link...</span>;
};

export default LinkTo;
