import { EIP_CONSTANT, useLog, NodeEditContext } from '@eip/next/lib/main';
import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
// import { actions } from '@eip/next/src/components/dashboard/dashboard-redux';
import { makeDashboard, TypeEDashBoard } from '@ep/insight-ui/elements/eDashboard/dashboard-container';
import PopupJsonEditor from '@ep/insight-ui/elements/popup-jsoneditor';
import { Button } from '@material-ui/core';
import { EventEmitter } from 'events';
import produce from 'immer';
import { debounce, get, merge, uniq } from 'lodash';
import React from 'react';
import { useDispatch } from 'react-redux';
import { getIntradayData, getMetricData } from './api-request';
import { INTRADAY_DASHBOARD_CONFIG } from './dashboard-config/intraday-dashboard';
import { METRIC_DASHBOARD_CONFIG } from './dashboard-config/metric-dashboard';
import ETableConfig from '@ep/insight-ui/system/block/etable/etable-config';
import { nanoid } from 'nanoid';

/**
 * ff.mass_operation_management:start
 */
import { getMassOperationData } from './api-request';
/**
 * ff.mass_operation_management:end
 */

/**
 * ff.keep_block_refreshing: start
 */
import moment from 'moment';
import { MonitorContainer } from '../../util/monitor/container';
/**
 * ff.keep_block_refreshing: end
 */

const log = useLog('chartlib:rich-table');
const ETABLE_PERSONALIZED_FIELDS = EIP_CONSTANT.ETABLE_PERSONALIZED_FIELDS;
export class EDashboardChartLib implements ChartLibComponent {
  render(dom: HTMLDivElement, data: NodeData, eventBus: EventEmitter) {
    const chartNodeData = Object.assign({ customAttributes: {} }, data);
    return (
      <MonitorContainer component="div" mId={data.blockEid} mLabel={'Summary'}>
        <ChartShape nodeData={chartNodeData} container={dom}></ChartShape>
      </MonitorContainer>
    );
  }

  renderConfigurationForm(dom: HTMLDivElement, data: NodeData['customAttributes'], handleSubmit, nodeId) {
    return <RichConfigForm data={data} onSubmit={handleSubmit} key={nodeId}></RichConfigForm>;
  }
}

const defaultConfiguration = METRIC_DASHBOARD_CONFIG;

const RichConfigForm = ({ data, onSubmit }: { data: NodeData; onSubmit: (...args: any[]) => void }) => {
  const [openConfig, setOpenConfig] = React.useState(false);
  const onValueChange = React.useCallback(
    debounce((config) => {
      onSubmit(config);
    }, 700),
    [],
  );
  const [sConfig, setStateConfig] = React.useState(data);

  const onSubmit1 = React.useCallback((config) => {
    console.info('onsubmit1', config);
    const fConfig = { ...config, _editorId: 'table_' + nanoid() };
    onSubmit(fConfig);
    setStateConfig(fConfig);
    setOpenConfig(false);
  }, []);

  const dispatch = useDispatch();
  const handleClickConfiguration = () => {
    // dispatch(actions.setEditorMode({ mode: 'advance_json_edit' }));
    setOpenConfig(true);
  };

  const handleClose = () => {
    // dispatch(actions.setEditorMode({ mode: 'select' }));
    setOpenConfig(false);
  };

  const config = merge({}, defaultConfiguration, data);
  return (
    <React.Fragment>
      <Button variant="contained" color="secondary" onClick={handleClickConfiguration}>
        Advanced configuration
      </Button>
      {ff.generate_etable_config ? (
        <ETableConfig
          open={openConfig}
          onClose={handleClose}
          config={sConfig}
          onSubmit={onSubmit1}
          isDashboardConfig={true}
        />
      ) : (
        <PopupJsonEditor
          onValueChange={onValueChange}
          open={openConfig}
          title={'Edit etable setting'}
          value={config}
          onClose={handleClose}
        />
      )}
    </React.Fragment>
  );
};

const ChartShape = ({ nodeData, container }: { nodeData: NodeData; container: any }) => {
  const nodeEditContext = React.useContext(NodeEditContext);

  let lastUpdated;
  if (ff.keep_block_refreshing) {
    lastUpdated = React.useRef(moment().valueOf());
  }

  const handleChangeConfig = React.useCallback(
    (config: any) => {
      nodeEditContext.onUpdateCustomAttributes(nodeData, config);
    },
    [nodeData],
  );

  // React.useEffect(() => {
  //   const ro = new ResizeObserver((entries) => {
  //     for (const entry of entries) {
  //       window.requestAnimationFrame(() => {
  //         const titleHeight = container.querySelector('.header-container').offsetHeight;
  //         const footerHeight = container.querySelector('.footer-container').offsetHeight;
  //         const newHeight = entry.contentRect.height - (titleHeight + footerHeight);
  //         container.querySelector('.body-container').style.height = newHeight + 'px';
  //       });
  //     }
  //   });

  //   ro.observe(container);

  //   return () => {
  //     ro.disconnect();
  //   };
  // }, []);

  const config = getConfig(nodeData);

  if (ff.keep_block_refreshing) {
    const { timeToReloadData } = config.configuration;
    if (timeToReloadData) {
      config.addons = {
        ...config.addons,
        'calendar.last_updated': (rows, currentConfig, backbone) => {
          setTimeout(() => {
            const currentTime = moment().valueOf();
            if (currentTime - lastUpdated.current > timeToReloadData) {
              backbone.changeConfig('lastUpdatedAt', moment(new Date()).format('MMM/YY hh:mm'));
              backbone.reloadData('table');
              lastUpdated.current = currentTime;
            }
          }, timeToReloadData);
          return rows.map((row) => ({
            ...row,
            lastUpdatedAt: moment(new Date()).format('MMM/YY hh:mm'),
          }));
        },
      };
    }
  }

  if (config) {
    return makeDashboard({
      config,
      changeConfiguration: handleChangeConfig,
      tableBackboneHook: useTableBackbone,
      tableContext: TableBackboneContext,
    });
  }

  return null;
};

const getConfig = (nodeData: NodeData) => {
  const customConfig = get(nodeData, 'customAttributes', {});

  switch (customConfig.tableType) {
    case 'intraday': {
      const finConfig = produceValidConfig({ ...INTRADAY_DASHBOARD_CONFIG, ...customConfig });
      return {
        apiRequest: {
          getTableData: (params) => getIntradayData(params, finConfig.endpoint),
        },
        configuration: finConfig,
      };
    }
    case 'metric': {
      const finConfig = produceValidConfig({ ...METRIC_DASHBOARD_CONFIG, ...customConfig });
      return {
        apiRequest: {
          getTableData: (params) => getMetricData(params, finConfig.endpoint),
        },
        tableType: TypeEDashBoard.METRIC,
        requestFilter: {
          currency: 'USD',
        },
        configuration: finConfig,
        addons: {
          'datasource.getRowsParams': async ({ params }, config, backbone) => {
            return produce(params, (draft) => {
              draft.hiddenFilter.currency = config.currency || params.hiddenFilter.currency;
              if (ff.calendar_timeline_cohort) {
                draft.cohortDateRange = backbone.getConfig('cohortDateRange');
              }
            });
          },
        },
      };
    }
  }

  if (ff.mass_operation_management) {
    if (customConfig.tableType === 'mass-operation') {
      const finConfig = produceValidConfig({ ...METRIC_DASHBOARD_CONFIG, ...customConfig });
      return {
        apiRequest: {
          getTableData: (params) => getMassOperationData(params, finConfig.endpoint),
        },
        tableType: TypeEDashBoard.METRIC,
        requestFilter: {
          currency: 'USD',
        },
        configuration: finConfig,
        addons: {
          'datasource.getRowsParams': async ({ params }, config) => {
            return produce(params, (draft) => {
              draft.hiddenFilter.currency = config.currency || params.hiddenFilter.currency;
            });
          },
        },
      };
    }
  }

  return null;
};

function produceValidConfig(customConfig) {
  const configKey = get(customConfig, '_configKey');
  let validConfig = customConfig;
  if (configKey) {
    let systemConfig = {};
    switch (configKey) {
      case 'METRIC_DASHBOARD_CONFIG':
        systemConfig = METRIC_DASHBOARD_CONFIG;
        break;
      case 'INTRADAY_DASHBOARD_CONFIG':
        systemConfig = INTRADAY_DASHBOARD_CONFIG;
        break;
    }

    validConfig = produce(validConfig, (draft) => {
      uniq(Object.keys(validConfig).concat(Object.keys(systemConfig))).forEach((key) => {
        if (ETABLE_PERSONALIZED_FIELDS.indexOf(key) === -1) {
          draft[key] = systemConfig[key];
        }
        if (draft[key] === undefined && systemConfig[key]) {
          draft[key] = systemConfig[key];
        }

        if (draft[key] === undefined) {
          delete draft[key];
        }
      });
      draft._configKey = configKey;
      draft._version = validConfig._version;
    });
    validConfig = migrateConfiguration(validConfig, systemConfig);
  } else {
    log('missing configuration key', configKey);
  }
  return validConfig;
}

const migrations = [
  (userConfig, systemConfig) =>
    produce((draft) => {
      const filter = [];
      (userConfig.filter || []).forEach((i, index) => {
        if (i.static !== true) {
          filter.push(userConfig.filter[index]);
        }
      });
      draft.filter = filter.filter((i) => i && (i.type === 'groupFilter' || i.field));
    })(userConfig),
];
function migrateConfiguration(userConfig, systemConfig) {
  const userVersion = userConfig._version || 0;

  if (userVersion == migrations.length) return userConfig;

  const validMigrations = migrations.slice(userVersion);

  let finalConfig = userConfig;
  validMigrations.forEach((migrate) => {
    finalConfig = migrate(finalConfig, systemConfig);
  });

  finalConfig = produce(finalConfig, (draft) => {
    draft._version = migrations.length;
  });

  return finalConfig;
}
