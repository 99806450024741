import { calendarRangeStyle, colors, scrollbarStyles, searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import {
  Box,
  createStyles,
  Grid,
  InputBase,
  makeStyles,
  Menu,
  MenuProps,
  Select,
  SelectProps,
  withStyles,
  Modal,
} from '@material-ui/core';
import { CreateCSSProperties } from '@material-ui/styles';
import clsx from 'clsx';
import * as React from 'react';
import FormControlTemplate from './form-control-template';
import { Calendar, DateObject } from 'react-multi-date-picker';
import _ from 'lodash';
import Icon from '@ep/insight-ui/icons/Icon';
import CalendarRange from '@ep/insight-ui/elements/form-control/calendar/calendar-range';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { PopupCalendar } from '../../../form-control/calendar/calendar-input/calendar-popup';
const BootstrapInput = withStyles(() =>
  createStyles({
    root: {},
    input: {
      height: '17px',
      border: `1px solid ${colors.border.default}`,
      paddingLeft: '8px',
      '&:focus': {
        backgroundColor: colors.surface.default,
        borderColor: colors.border.highlight,
        borderRadius: '4px',
      },
      '&:hover': {
        borderColor: '#006EC6 !important',
      },
      '&[aria-expanded=true]': {
        borderColor: colors.border.highlight,
      },
      '&[aria-disabled=true]': {
        background: colors.surface.disable,
        color: colors.icon.disabled,
      },
      ...(ff.improve_script_detail_mobile
        ? {
            '.eres--small &': {
              height: 'unset',
            },
          }
        : {}),
    },
  }),
)(InputBase);

const StyledMenu = withStyles({
  paper: {
    // border: '1px solid #d3d4d5',
    marginTop: 5,
    boxShadow: colors.shadow.hover,
    '& ul': {
      padding: '0 !important',
    },
    maxHeight: 'calc(100% - 34%)',
    minWidth: '510px',
    padding: 16,
    // overflowY: 'scroll',
    ...scrollbarStyles,
  },
})((props: MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'left',
    }}
    style={{ padding: 0 }}
    {...props}
  />
));

const generateDateObject = (val: string, format = 'DD/MM/YYYY'): DateObject => {
  return new DateObject({ date: val, format: format });
};
const useStylesInput = makeStyles({
  textStyle: searchStyle as CreateCSSProperties,
  textSearch: {
    width: '100%',
    '& input': {
      height: 30,
    },
  },
  calendar: {
    ...calendarRangeStyle,
  },
  value: {
    maxWidth: '95%',
    textOverflow: 'ellipsis',
    width: '100%',
    // overflow: ' hidden',
    whiteSpace: 'nowrap',
    display: 'block',
  },
  wrapperIcon: {
    display: 'flex',
    margin: '0 10px 0 5px',
    width: '25px',
    height: '20px',
  },
  iconSelectBox: {
    width: '16px',
    height: '20px',
  },
  alignCenter: {
    alignItems: 'center',
  },
  iconSpan: {
    textAlign: 'center',
    width: 'fit-content',
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  iconInput: {
    margin: 0,
    '&.calendar': {
      height: '11px',
      width: '12px',
    },
    '.eres--small &.calendar': {
      marginLeft: 0,
    },
  },
  container: {
    '.eres--small &': {
      flexWrap: 'nowrap',
      margin: 0,
      gap: '6px',
      '& > .eip1-MuiGrid-item': {
        padding: 0,
      },
    },
  },
  disabled: {
    pointerEvents: 'none',
    color: '#C2C7CB',
    '& .eip1-MuiSelect-outlined.eip1-MuiSelect-outlined': {
      background: '#F6F7F8',
      borderColor: '#E4E7E9',
    },
  },
  ...(ff.improve_script_detail_mobile
    ? {
        calendarMobileLabel: {
          maxWidth: '100%',
        },
      }
    : {}),
});
export type IValueInputTimeRange = {
  dateFrom: string;
  dateTo: string;
};
interface TimeRangeProps extends SelectProps {
  label: string;
  value: IValueInputTimeRange;
  onChange: (v: any) => void;
  dateFormat?: string;
  displayDateFormat?: string;
  disableMinDate?: boolean;
  disabledChooseStartDate?: boolean;
}
const TimeRange = ({
  label,
  value,
  onChange,
  dateFormat = 'DD/MM/YYYY',
  displayDateFormat = 'MMMM DD, YYYY',
  disableMinDate = false,
  disabled = false,
  disabledChooseStartDate = false,
  ...rest
}: TimeRangeProps) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const isMobile = containerClass === 'eres--small';

  const classes = useStylesInput();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const defaultValueDate: IValueInputTimeRange = React.useMemo(() => {
    return value;
  }, [value]);
  const [valueDate, setValueDate] = React.useState<IValueInputTimeRange>(value);

  React.useEffect(() => {
    if (!value) return;
    if (value.dateFrom !== valueDate.dateFrom || value.dateTo !== valueDate.dateTo) {
      setValueDate(value);
    }
  }, [value]);

  const labelInput = React.useMemo(() => {
    return `${valueDate.dateFrom}${valueDate.dateTo}`;
  }, [valueDate]);
  const getValueFormat = (value: IValueInputTimeRange, format: string = dateFormat) => {
    const dateFrom = generateDateObject(value.dateFrom, dateFormat).format(format);
    const dateTo = value.dateTo ? generateDateObject(value.dateTo, dateFormat).format(format) : '';
    return { dateFrom, dateTo };
  };
  const handleClick = (event) => {
    if (isMobile) {
      setOpenModal(true);
    } else {
      if (!disabled) setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    if (!disabled) setAnchorEl(null);
  };

  React.useEffect(() => {
    const value = getValueFormat(valueDate, dateFormat);
    onChange(value);
  }, [valueDate]);

  const [openModal, setOpenModal] = React.useState<boolean>(false);

  const handleClosePopup = () => {
    setOpenModal(false);
  };

  const renderValueInput = () => {
    const { dateTo, dateFrom } = getValueFormat(valueDate, isMobile ? 'MMM DD, YYYY' : displayDateFormat);

    let calendarMobileLabel;
    if (ff.improve_script_detail_mobile) {
      if (isMobile) {
        calendarMobileLabel = `${classes.alignCenter} ${classes.calendarMobileLabel}`;
      } else {
        calendarMobileLabel = classes.alignCenter;
      }
    }

    return (
      <Grid container alignItems={'center'} spacing={6} className={classes.container}>
        <Grid item className={classes.alignCenter}>
          <span className={classes.iconSpan}>
            <Icon
              type={'calendar'}
              className={`${classes.iconInput} calendar`}
              color={disabled ? '#8c98a4' : '#3F4F5C'}
            />
          </span>
        </Grid>
        <Grid item xs={10} className={classes.alignCenter} container>
          <>
            <Grid item xs={5} className={ff.improve_script_detail_mobile ? calendarMobileLabel : classes.alignCenter}>
              <div className={classes.value}>
                <span>{dateFrom}</span>
              </div>
            </Grid>

            <Grid item xs={2} className={ff.improve_script_detail_mobile ? calendarMobileLabel : classes.alignCenter}>
              <span className={classes.iconSpan}>
                <Icon type={'basicArrowRight'} className={classes.iconInput} color={disabled ? '#8c98a4' : '#3F4F5C'} />
              </span>
            </Grid>

            <Grid item xs={5} className={ff.improve_script_detail_mobile ? calendarMobileLabel : classes.alignCenter}>
              <div className={classes.value}>
                <span>{dateTo && !String(valueDate?.dateTo).startsWith('9999-12-31') ? dateTo : 'no limit'}</span>
              </div>
            </Grid>
          </>
        </Grid>
      </Grid>
    );
  };

  return (
    <FormControlTemplate label={label}>
      <div>
        <Select
          className={clsx(classes.textStyle, classes.textSearch, disabled && classes.disabled)}
          readOnly
          color={'primary'}
          onClick={handleClick}
          value={labelInput ? labelInput : rest.placeholder}
          inputProps={{
            style: {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%',
              overflowX: 'hidden',
            },
          }}
          variant="outlined"
          IconComponent={() => null}
          renderValue={renderValueInput}
          input={<BootstrapInput />}
          {...rest}
        >
          <option value={rest.placeholder} disabled>
            {rest.placeholder}
          </option>
          <option value={labelInput}>{labelInput}</option>
        </Select>
        <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {!isMobile ? (
            Boolean(anchorEl) && (
              <CalendarRange
                disabledChooseStartDate={disabledChooseStartDate}
                value={valueDate}
                defaultFormatReturn={dateFormat}
                onChange={(value) => setValueDate(value)}
                onClose={handleClose}
              />
            )
          ) : (
            <Modal open={openModal}>
              <PopupCalendar
                handleClose={handleClosePopup}
                onChange={(value) => setValueDate(value)}
                value={valueDate}
              />
            </Modal>
          )}
        </StyledMenu>
      </div>
    </FormControlTemplate>
  );
};
export default TimeRange;
