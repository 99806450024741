import * as React from 'react';
import { first, get, uniq, uniqBy } from 'lodash';
import clsx from 'clsx';

import type { ICellRendererParams } from '@ag-grid-community/core';
import { AgGridColumnProps, AgGridReact } from '@ag-grid-community/react';

import { Box, Button, makeStyles, Popover, Dialog, DialogContent } from '@material-ui/core';

import EmptyRows from '@ep/insight-ui/elements/table/format/no-rows-data-format';
import Icon from '@ep/insight-ui/icons/Icon';
import { EtableConfigFilter } from '@ep/insight-ui/system/block/etable/etable-config/filter';
import { CellFormatType } from '@ep/insight-ui/system/block/etable/cell-format/cell-format-type';

import ColorPickerFormat from './color-picker-format';
import { useCompactEtableConfig } from '../hooks/use-compact-etable-config';
import SelectFormat from './select-format';
import CompactConfigFormat from './compact-config-format';
import MultiSelectFormat from './multi-select-format';
import { SPECIAL_EDITOR, SYSTEM_KEY } from '../utils/special-editor';
import { EtableConfigContext } from '../context';
import PopoverComponent from '../popover-components';
import Wrapper from '@ep/insight-ui/elements/etable2/wrapper';
import { CellEditorInputFormat, CellEditorViewFormat } from './input-format';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import produce from 'immer';
import { getTooltipConfig } from '../utils/helpers';
const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  compactLabel: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  multiCompactLabel: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  editIcon: {
    cursor: 'pointer',
    padding: 8,
    '&:hover': {
      background: '#E4E7E9',
    },
  },
  tableContainer: {
    minWidth: '440px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 10,
    rowGap: '16px',
    '& .ag-cell-value': {
      alignItems: 'center',
      padding: '0 8px',
    },
    '& .ag-cell-value .ag-react-container': {
      display: 'inline-flex',
      alignItems: 'center',
    },
    '&.ag-theme-alpine .ag-ltr .ag-row-drag': {
      marginRight: 0,
    },
    '& .ag-cell-value.ag-cell-focus': {
      background: '#ebf6ff',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '8px',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      borderRadius: '16px',
      border: '1px solid #f8fafd',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: '#a0a0a5',
      border: '0px solid #f4f4f4',
    },
    '& .cell-checkbox .ag-cell-value': {
      padding: 0,
    },
    '& .header-checkbox .ag-header-select-all': {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)',
    },
    '&.ag-theme-alpine .ag-checkbox-input-wrapper.ag-indeterminate::before': {
      backgroundColor: '#FFF',
    },
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  actionGroup: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  compactSelectLabel: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  paper: {
    width: (props) => (props.paperWidth ? props.paperWidth : '800px'),
  },
});

const CompactFormat = (props: ICellRendererParams) => {
  const classes = useStyles({ paperWidth: props.popoverWidth });
  const { etableConfigContext, etableList } = props;
  const colId = props.column.getId();
  const etableContext = React.useContext(EtableConfigContext);
  const mappingConfig = get(etableContext, 'mapping', {});
  const sourceType = get(props, 'config.sourceType', 'compact-table');

  const onSubmit = (data, columnId) => {
    props.node.setDataValue(!columnId ? colId : columnId, data);
  };
  const {
    isEmpty,
    tableDOM,
    open,
    anchorEl,
    handleClick,
    handleClose,
    handleRowChange,
    actionButtons,
    handleSubmit,
    gridApi,
    setGridApi,
    compactConfig,
    isSelectType,
    specialCellFormat,
    specialCellFormatOptions,
    specialCompactOptions,
    columnsOption,
  } = useCompactEtableConfig({ onSubmit, props, mappingConfig });

  const actionButtonsRender = actionButtons.map((btn) => {
    if (btn.popoverComponent) {
      return (
        <PopoverComponent
          ChildComponent={btn.popoverComponent}
          title={btn.title}
          gridApi={gridApi}
          params={btn.params}
        />
      );
    }
    return (
      <Button key={btn.id} variant="contained" color="secondary" onClick={btn.onClick}>
        {btn.title}
      </Button>
    );
  });

  const columnDefs: AgGridColumnProps[] = React.useMemo(() => {
    return [
      {
        field: 'checkbox',
        pinned: 'left',
        maxWidth: 40,
        resizable: false,
        editable: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        cellClass: 'cell-checkbox',
        headerClass: 'header-checkbox',
        headerName: '',
      },
      ...(isSelectType || compactConfig.colDefs
        ? compactConfig.colDefs
        : [
            {
              field: 'key',
              maxWidth: 179,
              onCellValueChanged(params) {
                params.api.redrawRows({
                  rowNodes: [params.node],
                });
              },
              editable: true,
              cellEditor: CellEditorInputFormat,
              cellRendererSelector() {
                if (colId === 'staticValue') {
                  const options = Object.keys(SPECIAL_EDITOR).map((el) => ({
                    label: el,
                    value: el,
                  }));
                  return {
                    component: SelectFormat,
                    params: {
                      field: 'key',
                      options: options,
                      hasSearch: true,
                      allowCustomOption: true,
                    },
                  };
                }
                if (colId === 'systemSettings') {
                  const options = Object.keys(SYSTEM_KEY).map((el) => ({
                    label: el,
                    value: el,
                  }));
                  return {
                    component: SelectFormat,
                    params: {
                      field: 'key',
                      options: options,
                      hasSearch: true,
                      allowCustomOption: true,
                    },
                  };
                }
                if (colId === 'valueGetter') {
                  const options = [
                    'value',
                    'label',
                    'id',
                    'subtext',
                    'hashtag',
                    'tooltip',
                    'avatar',
                    'avatarName',
                    'showAs',
                    'dividedBy',
                    'barColor',
                  ].map((el) => ({
                    label: el,
                    value: el,
                  }));
                  const cssStyles = Object.keys(document.body.style)
                    .filter((el) => !/^\d/.test(String(el)))
                    .map((el) => ({
                      label: `styles.${el}`,
                      value: `styles.${el}`,
                    }));
                  return {
                    component: SelectFormat,
                    params: {
                      field: 'key',
                      options: options.concat(cssStyles),
                      hasSearch: true,
                      allowCustomOption: true,
                    },
                  };
                }
                return { component: CellEditorViewFormat };
              },
            },
            {
              field: 'value',
              cellRendererSelector: (params) => {
                if (get(props, ['colDef', 'field'], '') == 'systemSettings') {
                  return {
                    component: SelectFormat,
                    params: {
                      field: 'value',
                      options: [],
                      hasSearch: true,
                      allowCustomOption: true,
                      allowFormula: true,
                    },
                  };
                }
                const isDateTimeConfig = get(props, 'data.staticValue.dateTime', '');
                if (props.data.cellFormat === 'rgbStatusFormat' && colId === 'staticValue') {
                  return {
                    component: ColorPickerFormat,
                    params: {
                      field: 'value',
                    },
                  };
                }
                if (
                  get(props, 'colDef.field', '') === 'valueGetter' &&
                  (!SPECIAL_EDITOR[params.data.key] || String(params.data.key).startsWith('style.'))
                ) {
                  if (!!specialCellFormat) {
                    return {
                      component: SelectFormat,
                      params: {
                        field: 'value',
                        options: specialCellFormatOptions,
                        hasSearch: true,
                        allowCustomOption: true,
                        allowFormula: true,
                        mappingConfig: mappingConfig,
                      },
                    };
                  }
                }
                if (['staticValue', 'valueGetter'].includes(colId)) {
                  const editor = get(props.node.data, `eTableEditor.${colId}`, {});
                  console.info('cellEditorSelector', { params, editor });
                  const valueEditor = editor[params.data.key] || editor.value;
                  const confKey = params.data.key;
                  if (
                    ['label.tooltip', 'status.tooltip', 'subtext.tooltip', 'hashtag.tooltip'].includes(confKey) ||
                    ('staticValue' == colId && 'tooltip' == confKey)
                  ) {
                    return {
                      component: CompactFormat,
                      params: getTooltipConfig({ etableConfigContext, etableList }),
                    };
                  }
                  if (SPECIAL_EDITOR[confKey]) {
                    if (confKey === 'primaryKeys') {
                      return produce(SPECIAL_EDITOR[confKey], (draft) => {
                        draft.params.options = uniqBy(columnsOption, 'value');
                      });
                    }
                    return {
                      ...SPECIAL_EDITOR[params.data.key],
                      component:
                        SPECIAL_EDITOR[params.data.key].component === 'CompactFormat'
                          ? CompactFormat
                          : SPECIAL_EDITOR[params.data.key].component,
                    };
                  }
                  if (isDateTimeConfig && params.data.key === 'compareDisplayVs') {
                    return {
                      component: SelectFormat,
                      params: {
                        field: 'value',
                        options: columnsOption,
                        hasSearch: true,
                      },
                    };
                  }
                  if (params.data.key === 'valueCompare') {
                    return {
                      component: SelectFormat,
                      params: {
                        field: 'value',
                        options: specialCellFormatOptions,
                        hasSearch: true,
                      },
                    };
                  }
                  if (!valueEditor) {
                    return {
                      component: CellEditorViewFormat,
                    };
                  }

                  const specialCompactOption = get(
                    specialCompactOptions,
                    `${colId}.${get(props, 'data.cellFormat', '')}.${get(params, 'data.key', '')}`,
                    null,
                  );

                  switch (valueEditor.type) {
                    case CellFormatType.SELECT:
                      let options = [];
                      if (!!specialCompactOption) {
                        options = specialCompactOption;
                      } else if (params.data.key === 'accumulativeField') {
                        let accumulativeColumn = '';
                        params.api.forEachNode((node) => {
                          if (node.data.key === 'accumulativeColumn') {
                            accumulativeColumn = node.data.value;
                          }
                        });
                        options = valueEditor.getOptions(accumulativeColumn);
                      } else {
                        options = valueEditor.options;
                      }
                      return {
                        component: SelectFormat,
                        params: {
                          field: 'value',
                          options,
                          hasSearch: true,
                        },
                      };
                    case CellFormatType.ETABLE_FILTER:
                      return {
                        component: EtableConfigFilter,
                        params: {
                          tableConfig: get(props.node.data, 'staticValue.compactConfig'),
                        },
                      };
                    case CellFormatType.COMPACT_TABLE:
                      return {
                        component: CompactConfigFormat,
                        params: {
                          compactConfig:
                            props.value && props.value[params.data.key]
                              ? props.value[params.data.key]
                              : valueEditor.compactConfig,
                        },
                      };
                    case CellFormatType.COMPACT_SELECT_TABLE:
                      return {
                        component: CompactFormat,
                        params: {
                          config: valueEditor,
                        },
                      };
                    case CellFormatType.MULTI_SELECT:
                      const allowSelectAll =
                        get(props, ['data', 'cellFormat'], '') === 'storefrontMetricTraction' &&
                        get(params, ['data', 'key'], '') === 'selectedMetrics';

                      return {
                        component: MultiSelectFormat,
                        params: {
                          field: 'value',
                          options: specialCompactOption || valueEditor.options,
                          hasSearch: true,
                          allowSelectAll,
                        },
                      };
                    default:
                      return { component: CellEditorViewFormat };
                  }
                }
                return {
                  component: CellEditorViewFormat,
                };
              },
              // cellEditor: CellEditorInputFormat,
              cellEditorSelector: (params) => {
                const key = params.data.key;
                return {
                  component: CellEditorInputFormat,
                  params: SPECIAL_EDITOR[key] || {},
                };
              },
              editable: true,
              // editable: colId !== 'staticValue',
            },
          ]),
    ];
  }, [specialCellFormat, specialCellFormatOptions, specialCompactOptions]);

  const defaultColDef = React.useMemo(() => {
    return {
      flex: 1,
      editable: true,
      resizable: true,
      minWidth: 100,
      // suppressKeyboardEvent: ({ params }) => {
      //   return true;
      // },
    };
  }, []);

  const rowData = isSelectType
    ? compactConfig.rowData
    : compactConfig.colDefs
    ? get(props, ['data', colId]) || (compactConfig.getDefaultData ? compactConfig.getDefaultData() || [] : [])
    : Object.keys(props.data[colId] || {}).map((el) => ({
        key: el,
        value: props.data[colId][el],
      }));

  if (props.isConfig) {
    return (
      <Box>
        <Box className={classes.multiCompactLabel}>
          <Button
            className={clsx(classes.editIcon, 'advanced-configuration-btn')}
            onClick={handleClick}
            variant="contained"
            color="secondary"
          >
            Advanced configuration
          </Button>
        </Box>
        <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
          <DialogContent className={classes.container}>
            <Box className={`ag-theme-alpine body-container ${classes.tableContainer}`} ref={tableDOM}>
              <AgGridReact
                modules={[ClientSideRowModelModule, ClipboardModule]}
                columnDefs={columnDefs}
                rowData={rowData}
                defaultColDef={defaultColDef}
                suppressCellFocus={false}
                suppressNoRowsOverlay={true}
                onGridReady={(params) => {
                  setGridApi(params.api);
                  handleRowChange(params.api);
                }}
                onRowDataUpdated={(params) => {
                  handleRowChange(params.api);
                }}
                rowSelection={isSelectType && !compactConfig.isMultiple ? 'single' : 'multiple'}
                suppressRowClickSelection={true}
                rowDragManaged={props.allowDragRow}
                rowDragEntireRow={props.allowDragRow}
                suppressMoveWhenRowDragging={props.allowDragRow}
                processCellForClipboard={(params) => {
                  const colId = params.column.getColId();
                  if (colId === 'checkbox') {
                    return JSON.stringify({
                      // sourceId: nodeId,
                      data: {
                        sourceType: sourceType,
                      },
                    });
                  }
                  return JSON.stringify({
                    // sourceId: nodeId,
                    data: {
                      [colId]: params.value,
                    },
                  });
                }}
                processCellFromClipboard={(params) => {
                  try {
                    const value = JSON.parse(params.value);
                    const data = value.data;
                    const column = params.column.getColId();
                    if (!data[column]) throw new Error('invalid data');
                    return data[column];
                  } catch (error) {
                    return params.node?.data[params.column.getColId()];
                  }
                }}
                processDataFromClipboard={(params) => {
                  const selectedNodes = []; // params.api.getSelectedNodes();
                  let isValidPasteData = false;
                  let parsedRows: any[] = [];
                  try {
                    parsedRows = params.data.map((r) => r.map((c) => JSON.parse(c)));
                    const firstCell = first(parsedRows[0]);
                    isValidPasteData = firstCell.data.sourceType === sourceType;
                  } catch (error) {
                    // expect JSON parse error
                  }
                  if (!isValidPasteData) {
                    return params.data;
                  }
                  const transactions = {
                    update: [],
                    add: [],
                  };
                  if (selectedNodes.length > 0) {
                    selectedNodes.forEach((node, index) => {
                      let updateRow = parsedRows[index].reduce((acc, cell) => {
                        return { ...acc, ...cell.data };
                      }, {});
                      updateRow = { ...node.data, ...updateRow, columnKeys: node.data.columnKeys };
                      transactions.update.push(updateRow);
                    });
                  }
                  const newRows = parsedRows.slice(transactions.update.length).map((r) =>
                    r.reduce((acc, cell) => {
                      return { ...acc, ...cell.data };
                    }, {}),
                  );
                  transactions.add = transactions.add.concat(newRows);
                  params.api.applyTransaction(transactions);
                }}
              />
              {isEmpty && tableDOM.current && (
                <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={40} />
              )}
              <Box className={classes.actions}>
                <Box className={classes.actionGroup}>{!isSelectType && actionButtonsRender}</Box>
                <Box className={classes.actionGroup}>
                  <Button variant="contained" color="secondary" onClick={handleClose}>
                    Cancel
                  </Button>
                  <Button variant="contained" color="primary" onClick={handleSubmit}>
                    Submit
                  </Button>
                </Box>
              </Box>
            </Box>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.compactLabel}>
        {isSelectType ? (
          <span className={classes.compactSelectLabel}>
            {[]
              .concat(props.value)
              .map((el) => (compactConfig.rowData.find((ele) => ele.value === el) || { label: el }).label)
              .join(',')}
          </span>
        ) : (
          <span className={classes.compactSelectLabel}>
            {rowData.length > 0 && `${rowData.length} ${`propert${rowData.length === 1 ? 'y' : 'ies'}`}`}
          </span>
        )}
        <Button
          className={classes.editIcon}
          onClick={handleClick}
          {...(props.data.columnKeys ? { id: `compact-${props.data.columnKeys}-${props.colDef.field}` } : {})}
        >
          <Icon type={'plus'} />
        </Button>
      </Box>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        classes={{
          paper: classes.paper,
        }}
      >
        <Wrapper>
          <Box className={`ag-theme-alpine body-container ${classes.tableContainer}`} ref={tableDOM}>
            <AgGridReact
              modules={[ClientSideRowModelModule, ClipboardModule]}
              columnDefs={columnDefs}
              rowData={rowData}
              defaultColDef={defaultColDef}
              suppressCellFocus={false}
              suppressNoRowsOverlay={true}
              onGridReady={(params) => {
                setGridApi(params.api);
                handleRowChange(params.api);
              }}
              onRowDataUpdated={(params) => {
                handleRowChange(params.api);
              }}
              rowSelection={isSelectType && !compactConfig.isMultiple ? 'single' : 'multiple'}
              suppressRowClickSelection={true}
              suppressMoveWhenRowDragging={props.allowDragRow}
              rowDragManaged={props.allowDragRow}
              rowDragEntireRow={props.allowDragRow}
              processCellForClipboard={(params) => {
                const colId = params.column.getColId();
                if (colId === 'checkbox') {
                  return JSON.stringify({
                    // sourceId: nodeId,
                    data: {
                      sourceType: sourceType,
                    },
                  });
                }
                return JSON.stringify({
                  // sourceId: nodeId,
                  data: {
                    [colId]: params.value,
                  },
                });
              }}
              processCellFromClipboard={(params) => {
                try {
                  const value = JSON.parse(params.value);
                  const data = value.data;
                  const column = params.column.getColId();
                  if (!data[column]) throw new Error('invalid data');
                  return data[column];
                } catch (error) {
                  return params.node?.data[params.column.getColId()];
                }
              }}
              processDataFromClipboard={(params) => {
                // const selectedNodes = params.api.getSelectedNodes();
                const selectedNodes = [];
                let isValidPasteData = false;
                let parsedRows: any[] = [];
                try {
                  parsedRows = params.data.map((r) => r.map((c) => JSON.parse(c)));
                  const firstCell = first(parsedRows[0]);
                  isValidPasteData = firstCell.data.sourceType === sourceType;
                } catch (error) {
                  // expect JSON parse error
                }
                if (!isValidPasteData) {
                  return params.data;
                }
                const transactions = {
                  update: [],
                  add: [],
                };
                if (selectedNodes.length > 0) {
                  selectedNodes.forEach((node, index) => {
                    let updateRow = parsedRows[index].reduce((acc, cell) => {
                      return { ...acc, ...cell.data };
                    }, {});
                    updateRow = { ...node.data, ...updateRow, columnKeys: node.data.columnKeys };
                    transactions.update.push(updateRow);
                  });
                }
                const newRows = parsedRows.slice(transactions.update.length).map((r) =>
                  r.reduce((acc, cell) => {
                    return { ...acc, ...cell.data };
                  }, {}),
                );
                transactions.add = transactions.add.concat(newRows);
                params.api.applyTransaction(transactions);
              }}
            />
            {isEmpty && tableDOM.current && (
              <EmptyRows tableDOM={tableDOM} label={'This table is empty'} rowHeight={40} />
            )}
            <Box className={classes.actions}>
              <Box className={classes.actionGroup}>{!isSelectType && actionButtonsRender}</Box>
              <Box className={classes.actionGroup}>
                <Button variant="contained" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        </Wrapper>
      </Popover>
    </Box>
  );
};

export default CompactFormat;
