import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@ep/insight-ui/icons/Icon';
import Dropdown from '../dropdown-menu/dropdown';
import CheckboxList from '@ep/insight-ui/elements/list-control/checkbox-list';
import { OptionType } from '@ep/insight-ui/elements/list-control/type';
import { IPagination } from './table';
import { Box, Button, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import { get } from 'lodash';
import moment from 'moment';
/**
 * ff.etable_add_loadmore_menu_mobile:start
 */
import { MenuItemComponent } from '../app-menu/app-menu-mobile';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.etable_add_loadmore_menu_mobile:end
 */

/**
 * ff.separate_table_data_loading:start
 */
import LoadingIcon from '../list-control/spinners/icon-spinner';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { POST_RELOAD_TABLE_EVENT } from '@ep/insight-ui/system/helper/constant';
/**
 * ff.separate_table_data_loading:end
 */

const useStyles = makeStyles(() => ({
  '& .Shop': {
    color: 'red',
  },
  ...(ff.separate_table_data_loading
    ? {
        text: {
          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
          '&.label': {
            fontWeight: 500,
          },
        },
      }
    : {}),
  textAll: {
    fontSize: '14px',
    color: '#8C98A4',
  },
  textLoad: {
    fontSize: '14px',
    color: '#8C98A4',
    lineHeight: '20px',
  },
  textResult: {
    marginLeft: '4px',
    marginRight: '4px',
    color: '#253746',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
  },
  dropdown: {
    display: 'inline',
  },
  // pagination: {
  //   padding: '10px 12px',
  // },
  paginationItem: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    padding: '8px',

    pointerEvents: 'all',

    '&:hover': {
      backgroundColor: '#F2F3F4',
    },
  },
  paper: {
    paddingTop: '8px',
  },
  pageButton: {
    minWidth: '24px',
    height: '24px',
  },
  // pageItems: {
  //   '& .eip1-MuiTypography-root': {
  //     fontWeight: 500,
  //   },
  // },
  loadMore: {
    padding: '4px 8px',
    backgroundColor: 'transparent',
    '& .eip1-MuiButton-label > *:not(.dropdownSetting)': {
      pointerEvents: 'none',
    },
    '&:active .eip1-MuiButton-label > *': {
      color: 'white',
    },
    '&:active .eip1-MuiButton-label > svg > path': {
      fill: 'white',
    },
  },
  results: {
    padding: '6px 12px',
  },
  ...(ff.loading_config_search
    ? {
        buttonError: {
          columnGap: '8px',
          cursor: 'pointer',
        },
        error: {
          marginLeft: '40px',
        },
      }
    : {}),
  updatedAt: {
    color: '#626266',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
}));
interface PaginationProps {
  tableDOM?: React.MutableRefObject<any>;
  pageOptions?: Array<OptionType>;
  pagination?: {
    traceId?: string;
    page: number;
    limit: number;
    total: number;
  };
  onChangePagination?: ({ page, limit }: IPagination) => void;
  totalRowInTable?: number;
  isPromotedObjectTable?: boolean;
  rowSelected?: any;
  tableId?: string;
}

export const ROW_HEIGHT = 40;
const PAGE_HEIGHT = 40;
const MIN_ROW_TO_SHOW_PAGINATION = 10;

const Pagination = ({
  pageOptions,
  pagination,
  onChangePagination,
  tableDOM,
  totalRowInTable,
  isPromotedObjectTable,
  rowSelected,
  tableId,
}: PaginationProps) => {
  const classes = useStyles();
  const { page, limit } = pagination;
  const [hover, setHover] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const fullWidthContainerRef = React.useRef<HTMLDivElement>(null);
  const [lastUpdatedAt, setLastUpdatedAt] = React.useState(null);
  const [lastUpdatedLabel, setLastUpdatedLabel] = React.useState(null);

  React.useEffect(() => {
    if (lastUpdatedAt) {
      setLastUpdatedLabel(moment(lastUpdatedAt).fromNow());
      const interval = setInterval(() => {
        setLastUpdatedLabel(lastUpdatedAt.fromNow());
      }, 1000 * 60);

      return () => {
        clearInterval(interval);
      };
    }
  }, [lastUpdatedAt]);

  let status, setStatus;
  if (ff.separate_table_data_loading) {
    [status, setStatus] = React.useState('');
  }

  let backbone;
  if (ff.separate_table_data_loading) {
    backbone = React.useContext(TableBackboneContext) as TableBackboneContextType;

    React.useEffect(() => {
      if (pagination.traceId && pagination.traceId !== '') {
        loadPagination(pagination.traceId);
      }
    }, [pagination.traceId]);
  }

  const loadPagination = async (traceId, payload?) => {
    setStatus('loading');

    const res = await backbone.loadPagination(traceId, payload);
    setLastUpdatedAt(moment());
    if (res.success) {
      setStatus('success');
    } else {
      setStatus('error');
    }
  };

  const onLoadMore = () => {
    if (hover) return;
    onChangePagination({ page: page + 1, limit });
  };

  const onChange = (option: OptionType[]) => {
    const limit = Number(option.filter((value) => value.checked)[0].value);
    setOpen(false);
    onChangePagination({ page: page + 1, limit });
  };

  React.useEffect(() => {
    if (!tableDOM.current) return;
    if (ff.ag_grid_27) {
      window.requestAnimationFrame(() => {
        fullWidthContainerRef.current = tableDOM.current.querySelector('.ag-full-width-container');
        containerRef.current = tableDOM.current.querySelector(
          `.ag-body-viewport.ag-layout-normal.ag-row-no-animation`,
        ) as HTMLDivElement;
      });
    } else {
      // Cannot select like this in version 27.1.0
      containerRef.current = tableDOM.current.querySelector(
        `.ag-body-viewport.ag-layout-normal.ag-row-no-animation`,
      ) as HTMLDivElement;
      fullWidthContainerRef.current = tableDOM.current.querySelector('[ref="fullWidthContainer"]');
    }
  }, [tableDOM]);

  const paginationIsExactTotal = backbone.getConfig('paginationIsExactTotal');

  React.useEffect(() => {
    if (tableId) {
      const handleEvent = (e) => {
        const params = get(e, ['detail', 'params'], null);
        const page = get(params, ['pagination', 'page'], 0);
        const hasDrillDowns = get(params, ['groupBy', 'drillDowns'], []).length;
        if (
          tableId === get(e, ['detail', 'tableId']) &&
          paginationIsExactTotal === false &&
          pagination.total &&
          page === 1 &&
          !hasDrillDowns
        ) {
          loadPagination('', params);
        }
      };
      window.addEventListener(POST_RELOAD_TABLE_EVENT, handleEvent);

      return () => {
        window.removeEventListener(POST_RELOAD_TABLE_EVENT, handleEvent);
      };
    }
  }, [tableId, paginationIsExactTotal, pagination]);

  const isShowPage = React.useMemo(() => {
    return (
      containerRef.current &&
      (paginationIsExactTotal !== false
        ? totalRowInTable < pagination.total && totalRowInTable >= MIN_ROW_TO_SHOW_PAGINATION
        : totalRowInTable >= pagination.limit * pagination.page)
    );
  }, [containerRef.current, totalRowInTable, pagination, MIN_ROW_TO_SHOW_PAGINATION, paginationIsExactTotal]);

  React.useEffect(() => {
    if (!tableDOM) return;
    if (!containerRef.current) return;
    if (isShowPage) {
      const agBody = tableDOM.current.querySelector(
        '.ag-root.ag-unselectable.ag-layout-normal[ref="gridBody"]',
      ) as HTMLDivElement;
      const agCenter = tableDOM.current.querySelector('.ag-center-cols-clipper') as HTMLDivElement;

      if (!agBody) return;
      if (!agCenter) return;

      agBody.style.maxHeight = `${agBody.offsetHeight + PAGE_HEIGHT}px`;
      agCenter.style.height = `${agCenter.offsetHeight + PAGE_HEIGHT}px`;
    }
  }, [isShowPage, tableDOM, containerRef.current]);

  if (ff.separate_table_data_loading) {
    if (status === 'loading') {
      return <LoadingIcon />;
    }
    if (status === 'error') {
      if (ff.loading_config_search) {
        return (
          <div className={`${classes.text} label ${classes.error}`}>
            <Button variant="text" size="small" onClick={loadPagination}>
              <span className={classes.buttonError}>
                <Icon type={'reload'} />
                <span>Reload</span>
              </span>
            </Button>
          </div>
        );
      }
      return (
        <span>
          {'Error '}
          <Button variant="text" size="small" onClick={loadPagination}>
            Retry
          </Button>
        </span>
      );
    }
  }

  const getTotal = () => {
    if (paginationIsExactTotal === false && !pagination.total) {
      return totalRowInTable + (totalRowInTable >= pagination.limit * pagination.page ? '+' : '');
    }
    return pagination.total;
  };

  return (
    <>
      {isShowPage &&
        ReactDOM.createPortal(
          <Box
            position="absolute"
            height={fullWidthContainerRef.current.offsetHeight}
            width={'100%'}
            style={{ pointerEvents: 'none' }}
          >
            <div
              className={classes.paginationItem}
              onClick={onLoadMore}
              style={{
                transform: `translateY(${fullWidthContainerRef.current.offsetHeight}px)`,
                minHeight: `${PAGE_HEIGHT}px`,
              }}
            >
              <Box display={'flex'} alignItems={'center'}>
                <Box
                  width={'16px'}
                  height={'16px'}
                  display={'flex'}
                  justifyContent={'center'}
                  alignItems={'center'}
                  marginRight={'4px'}
                >
                  <Icon type={'downarrow'} size={'12px'} />
                </Box>
                <span className={classes.textLoad}>Load</span>
                {pageOptions && (
                  <>
                    <span className={classes.textResult}>{limit} more</span>
                    <div
                      className="dropdownSetting"
                      onMouseOver={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      <Dropdown
                        alignMenu="right"
                        icon="threeDotsVertical"
                        label=""
                        className={classes.pageButton}
                        classNamePaper={classes.paper}
                        defaultOpen={open}
                        onClick={() => setOpen(true)}
                      >
                        <CheckboxList
                          className={classes.pageItems}
                          items={pageOptions}
                          onChange={onChange}
                          draggable={false}
                          disabledCheckbox
                        />
                      </Dropdown>
                    </div>
                  </>
                )}
              </Box>
            </div>
          </Box>,
          containerRef.current,
        )}
      {isPromotedObjectTable ? (
        <div style={{ display: 'flex' }}>
          <div className={clsx(classes.results)}>
            <span className={classes.textAll}>Checked:</span>
            <span className={classes.textResult}>{rowSelected.length} / 10</span>
          </div>

          <div className={clsx(classes.results)}>
            <span className={classes.textAll}>All:</span>
            <span className={classes.textResult}>{pagination.total} results</span>
          </div>
        </div>
      ) : (
        <div className={clsx(classes.results)}>
          <span className={classes.textAll}>All:</span>
          <span className={classes.textResult}>{getTotal()} results</span>
          {/* {paginationIsExactTotal === false ? (
            <Box style={{ display: 'inline-flex', columnGap: '4px' }}>
              <span className={classes.buttonError} onClick={loadPagination}>
                <Icon type={'reload'} colorIcon={'#626266'} />
              </span>
              {lastUpdatedAt ? <span className={classes.updatedAt}>Updated {lastUpdatedLabel}</span> : null}
            </Box>
          ) : null} */}
        </div>
      )}
    </>
  );
};
export default Pagination;

export function ButtonLoadmore({ limit, onClick }: { limit; onClick }) {
  const classes = useStyles();
  return (
    <Box className={classes.paginationItem}>
      <Box display={'flex'} alignItems={'center'} onClick={onClick}>
        <Box
          width={'16px'}
          height={'16px'}
          display={'flex'}
          justifyContent={'center'}
          alignItems={'center'}
          marginRight={'4px'}
        >
          <Icon type={'downarrow'} size={'12px'} />
        </Box>
        <span className={classes.textLoad}>Load</span>
        <span className={classes.textResult}>{limit} more</span>
      </Box>
    </Box>
  );
}
