import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const AddInformation = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 2C4.68333 2 2 4.68333 2 8C2 11.3167 4.68333 14 8 14C11.3167 14 14 11.3167 14 8C14 4.68333 11.3167 2 8 2ZM9 11C9 11.55 8.55 12 8 12C7.45 12 7 11.55 7 11V8C7 7.45 7.45 7 8 7C8.55 7 9 7.45 9 8V11ZM8 6C7.45 6 7 5.55 7 5C7 4.45 7.45 4 8 4C8.55 4 9 4.45 9 5C9 5.55 8.55 6 8 6Z"
      fill="#253746"
    />
  </SvgIcon>
);
export default AddInformation;
