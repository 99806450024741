import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Group = (props: SvgIconProps) => (
  <SvgIcon {...props} width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <path
      d="M9.71002 1.71002L8.29002 0.290017C8.19658 0.197335 8.08576 0.12401 7.96392 0.0742455C7.84209 0.0244809 7.71162 -0.000744179 7.58002 1.67143e-05H2.42002C2.28841 -0.000744179 2.15795 0.0244809 2.03611 0.0742455C1.91427 0.12401 1.80346 0.197335 1.71002 0.290017L0.290017 1.71002C0.197335 1.80346 0.12401 1.91427 0.0742455 2.03611C0.0244809 2.15795 -0.000744179 2.28841 1.67143e-05 2.42002V9.00002C1.67143e-05 9.26523 0.105374 9.51959 0.29291 9.70712C0.480446 9.89466 0.7348 10 1.00002 10H9.00002C9.26523 10 9.51959 9.89466 9.70712 9.70712C9.89466 9.51959 10 9.26523 10 9.00002V2.42002C10.0008 2.28841 9.97555 2.15795 9.92579 2.03611C9.87602 1.91427 9.8027 1.80346 9.71002 1.71002ZM8.00002 5.33002C8.00002 5.418 7.98269 5.50513 7.94902 5.58641C7.91535 5.6677 7.86599 5.74156 7.80378 5.80378C7.74156 5.86599 7.6677 5.91535 7.58641 5.94902C7.50513 5.98269 7.418 6.00002 7.33002 6.00002H2.67002C2.49232 6.00002 2.3219 5.92943 2.19626 5.80378C2.07061 5.67813 2.00002 5.50771 2.00002 5.33002V4.67002C2.00002 4.49232 2.07061 4.3219 2.19626 4.19626C2.3219 4.07061 2.49232 4.00002 2.67002 4.00002H7.33002C7.418 4.00002 7.50513 4.01735 7.58641 4.05102C7.6677 4.08469 7.74156 4.13404 7.80378 4.19626C7.86599 4.25847 7.91535 4.33233 7.94902 4.41362C7.98269 4.49491 8.00002 4.58203 8.00002 4.67002V5.33002ZM1.00002 2.00002L2.00002 1.00002H8.00002L9.00002 2.00002H1.00002Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default Group;
