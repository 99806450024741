export const nodeDefault = {
  data: {
    country: {
      label: 'PH',
    },
    store_front: {
      status: 'active',
      label: 'P&G Home Care Official Store',
    },
    campaign: {
      label: 'Flash sale 15.7',
    },
    ads_object: {
      label: 'Joy Lemon Concentrate Dishwashing Liquid Bottle (790mL) Set of 2',
      status: 'active',
    },
    keyword: {
      label: 'downy sunrise fresh',
      status: 'active',
    },
    campaign_budget: [
      {
        value: 1317,
        currency: 'PHP',
        label: 'Total',
      },
      {
        value: 1360,
        currency: 'PHP',
        label: 'Daily',
      },
    ],
    campaign_bidding_limit: {
      value: 38,
      currency: 'PHP',
    },
    campaign_timeline: {
      date_from: '01/07/2021',
      date_to: -1,
    },
    ads_object_budget: [
      {
        value: 1313,
        currency: 'PHP',
        label: 'Total',
      },
    ],
    ads_object_timeline: {
      date_from: '03/07/2021',
      date_to: '21/07/2021',
    },
    match_type: {
      label: 'Broad match',
    },
    keyword_bidding_price: {
      value: 1,
      currency: 'PHP',
    },
    roas: {
      value: 934746,
      currency: 'VND',
    },
    cost: {
      value: 445068,
      currency: 'VND',
    },
    direct_ads_gmv: {
      value: 616581,
      currency: 'PHP',
    },
    ads_gmv: {
      value: 666351,
      currency: 'PHP',
    },
    item_sold: {
      value: 181332,
      currency: 'VND',
    },
    direct_item_sold: {
      value: 498848,
      currency: 'VND',
    },
    current_position: {
      position: 38,
      hour: '16:32',
      date: '03/06/2021',
    },
  },
};
