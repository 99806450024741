import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import CreditCard from '@ep/insight-ui/icons/svg/img/credit-card';
import { Box, BoxProps, Button, Grid, makeStyles, RadioGroup } from '@material-ui/core';
import * as React from 'react';
import FormControlLabel from '../../form-controls/form-control-label';
import FormControlRadio from '@material-ui/core/FormControlLabel';
import FormControlTemplate from '../../form-controls/form-control-template';
import StripeLogo from '@ep/insight-ui/icons/svg/img/StripeLogo.png';
import TextSelectForm from '@ep/insight-ui/elements/form-control/select-form/text-select-form';
import StyledRadio from '@ep/insight-ui/elements/list-control/radio-list/radio-custom';
import { Form, Formik } from 'formik';

const useStyles = makeStyles({
  container: {
    background: '#fff',
    padding: '5% 4% 3% 4%',
  },
  titleHeader: {
    marginBottom: '24px',
  },
  formControl: {
    '&.template': {
      marginBottom: 0,
    },
    '&.label': {},
  },
  size: {
    '&.w-100': {
      width: '100%',
    },
    '&.h-100': {
      height: '100%',
    },
    '&.mr-2': {
      marginRight: '12px',
    },
  },
  text: {
    fontStyle: 'normal',
    '&.title': {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: '28px',
      color: '#253746',
    },
    '&.text': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
    },
    '&.no-wrap': {
      whiteSpace: 'nowrap',
    },
    '&.currency': {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: '20px',
      color: '#253746',
    },
    '&.subText': {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#8C98A4',
    },
    '&.textButton': {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      color: '#253746',
      '&.primary': {
        color: '#FFFFFF',
      },
    },
    '&.text-sm': {
      fontSize: '12px',
      lineHeight: '12px',
    },
    '& .link': {
      color: '#006EC6',
      cursor: 'pointer',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  media: {
    '&.stripeLogo': {
      width: '72px',
      height: '30px',
      backgroundSize: 'cover',
    },
  },
  input: {
    width: '100%',
    '& input': {
      width: '100%',
    },
  },
  button: {
    height: '32px',
    '&.mr': {
      marginRight: '15px',
    },
  },
});

const PurchaseForm = ({ onClose, value }: any) => {
  const classes = useStyles();
  const [valueRadio, setValueRadio] = React.useState('');
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValueRadio((event.target as HTMLInputElement).value);
  };

  return (
    <Grid container className={`${classes.container}`}>
      <Grid item xs={12} spacing={3} className={`${classes.titleHeader}`}>
        <span className={`${classes.text} title`}>Purchase</span>
      </Grid>
      <Grid item xs={12}>
        <ExpansionForm
          label={'Order summary - professional plan'}
          labelSize={18}
          detailContentSize={'full'}
          defaultExpanded={true}
          dataMenu={[[]]}
          className={`${classes.text} currency`}
        >
          <Box>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormControlTemplate label={'Workspace'} className={`${classes.formControl} template`}>
                  <span className={`${classes.text} text`}>CPD</span>
                </FormControlTemplate>
              </Grid>
              <Grid item xs={12}>
                <FormControlTemplate label={'Base price'} className={`${classes.formControl} template`}>
                  <React.Fragment>
                    <span className={`${classes.text} currency`}>$299</span>
                    <span className={`${classes.text} subText`}>/ storefront / month</span>
                  </React.Fragment>
                </FormControlTemplate>
              </Grid>
              <Grid item xs={12}>
                <FormControlTemplate label={'Additional user'} className={`${classes.formControl} template`}>
                  <React.Fragment>
                    <span className={`${classes.text} currency`}>$19</span>
                    <span className={`${classes.text} subText`}>/ user / month</span>
                  </React.Fragment>
                </FormControlTemplate>
              </Grid>
              <Grid item xs={12}>
                <FormControlTemplate label={'Additional user'} className={`${classes.formControl} template`}>
                  <React.Fragment>
                    <span className={`${classes.text} currency`}>8%</span>{' '}
                    <span className={`${classes.text} subText`}>advertising spend/ month</span>
                  </React.Fragment>
                </FormControlTemplate>
              </Grid>
              <Grid item xs={12}>
                <FormControlTemplate label={'Billing interval'} className={`${classes.formControl} template`}>
                  <React.Fragment>
                    <TextSelectForm
                      value={'annually'}
                      options={[{ label: 'Annually', value: 'annually' }]}
                      variant={'filled'}
                    />
                  </React.Fragment>
                </FormControlTemplate>
              </Grid>
              <Grid item xs={12}>
                <Grid
                  container
                  wrap={'nowrap'}
                  alignItems={'center'}
                  justifyContent={'space-between'}
                  className={`${classes.size} h-100`}
                >
                  <Grid item className={`${classes.size} w-100 mr-2`}>
                    <TextFieldCustom
                      defaultValue={''}
                      placeholder={'Coupon code'}
                      onChangeText={(value) => console.log(value)}
                    />
                  </Grid>
                  <Grid item className={`${classes.size} h-100`}>
                    <Button color="secondary" variant="contained" component="div" className={classes.size}>
                      <span className={`${classes.text} textButton no-wrap`}>Apply coupon</span>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </ExpansionForm>
      </Grid>
      <Formik
        initialValues={value}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <Grid item xs={12}>
              <ExpansionForm
                label={'Purchase'}
                dataMenu={[[]]}
                detailContentSize={'full'}
                defaultExpanded={true}
                className={`${classes.text} currency`}
                rightElement={
                  <React.Fragment>
                    <Box display={'flex'} alignSelf={'center'}>
                      <div
                        style={{ backgroundImage: `url(${StripeLogo})` }}
                        className={`${classes.media} stripeLogo`}
                      />
                    </Box>
                  </React.Fragment>
                }
              >
                <Box>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormControlLabel label={'Payment method'} className={`${classes.formControl} label`}>
                        <Box display={'flex'} flexWrap={'nowrap'} justifyContent={'space-between'}>
                          <Box display={'flex'} alignItems={'center'}>
                            <RadioGroup
                              aria-label="gender"
                              name="controlled-radio-buttons-group"
                              value={valueRadio}
                              onChange={handleChange}
                            >
                              <FormControlRadio
                                value="credit"
                                control={<StyledRadio />}
                                label="Credit or debit card"
                                style={{ marginLeft: 0 }}
                              />
                            </RadioGroup>
                          </Box>
                          <Box display={'flex'} flexWrap={'nowrap'} justifyContent={'flex-end'}>
                            <CreditCard src={'visa'} height={'22px'} width={'35px'} />
                            <CreditCard src={'masterCard'} height={'22px'} width={'35px'} mx={2} />
                            <CreditCard src={'americanExpress'} height={'22px'} width={'35px'} />
                          </Box>
                        </Box>
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel label={'fullname'} className={`${classes.formControl} label`}>
                        <Grid container wrap={'nowrap'} spacing={3}>
                          <Grid item xs={7}>
                            <TextFieldCustom
                              defaultValue={values.fullname}
                              className={`${classes.input}`}
                              placeholder={'Name on card'}
                              onChangeText={(value) => setFieldValue('fullname', value)}
                            />
                          </Grid>
                        </Grid>
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel label={'Payment information'} className={`${classes.formControl} label `}>
                        <Grid container wrap={'nowrap'} spacing={3}>
                          <Grid item xs={7}>
                            <TextFieldCustom
                              defaultValue={values.card}
                              className={`${classes.input}`}
                              placeholder={'Name on card'}
                              onChangeText={(value) => setFieldValue('card', value)}
                            />
                          </Grid>
                          <Grid item xs={5}>
                            <Grid container wrap={'nowrap'} spacing={3}>
                              <Grid item>
                                <TextFieldCustom
                                  defaultValue={values.month_year}
                                  className={`${classes.input}`}
                                  placeholder={'MM/YY'}
                                  onChangeText={(value) => {
                                    let val = '';
                                    if (value.length === 3 && !value.includes('/')) {
                                      val = value.slice(0, 2) + '/' + value.slice(2);
                                    } else if (value.length === 2) {
                                      val = value + '/';
                                    } else {
                                      val = value;
                                    }
                                    setFieldValue('month_year', val);
                                  }}
                                />
                              </Grid>
                              <Grid item>
                                <TextFieldCustom
                                  defaultValue={values.cvc}
                                  className={`${classes.input}`}
                                  placeholder={'CVC'}
                                  onChangeText={(value) => setFieldValue('cvc', value)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </FormControlLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <span className={`${classes.text} subText text-sm`}>
                        Epsilo does not hold any credit card details as all card payments completed through
                        Stripe&lsquo;s payment gateway. You can view Stripe&lsquo;s privacy and data security policy
                        here: <span className={`link`}>https://stripe.com/gb/privacy</span>.
                      </span>
                    </Grid>
                  </Grid>
                </Box>
              </ExpansionForm>
            </Grid>
            <Grid item xs={12} style={{ flexWrap: 'nowrap' }}>
              <Box textAlign={'right'}>
                <Button
                  color="secondary"
                  variant="text"
                  component="span"
                  className={`${classes.button} mr`}
                  onClick={onClose}
                >
                  <span className={`${classes.text} textButton`}>Cancel</span>
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  className={`${classes.button}`}
                  disabled={!values.fullname || !values.card || !values.month_year || !values.cvc || !valueRadio}
                >
                  <span className={`${classes.text}`}>Subscribe</span>
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default PurchaseForm;
