import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Excel = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2 0H18C18.5304 0 19.0391 0.210714 19.4142 0.585786C19.7893 0.960859 20 1.46957 20 2V18C20 18.5304 19.7893 19.0391 19.4142 19.4142C19.0391 19.7893 18.5304 20 18 20H2C1.46957 20 0.960859 19.7893 0.585786 19.4142C0.210714 19.0391 0 18.5304 0 18V2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0Z"
      fill="#0BA373"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.4491 3.03428L3.95226 4.77865C3.66825 4.83892 3.41664 4.99145 3.24259 5.20885C3.06855 5.42626 2.98348 5.69429 3.00266 5.96481V14.1052C3.00124 14.364 3.09449 14.6155 3.26708 14.8184C3.43966 15.0213 3.68131 15.1635 3.95226 15.2216L11.4491 16.9659C11.6321 17.008 11.8229 17.0111 12.0073 16.9748C12.1917 16.9386 12.3649 16.864 12.514 16.7566C12.663 16.6492 12.784 16.5118 12.8679 16.3547C12.9518 16.1977 12.9964 16.0249 12.9985 15.8495V4.22045C13.0081 4.03921 12.9721 3.85839 12.8932 3.69244C12.8143 3.52649 12.6948 3.38002 12.5443 3.26475C12.3937 3.14947 12.2162 3.06859 12.0261 3.02857C11.8359 2.98855 11.6383 2.99051 11.4491 3.03428ZM15.0002 4.99996H15.832C16.1395 5.00593 16.4327 5.13072 16.6501 5.34816C16.8675 5.56559 16.9923 5.85878 16.9983 6.16622V13.8337C16.9923 14.1411 16.8675 14.4343 16.6501 14.6518C16.4327 14.8692 16.1395 14.994 15.832 15H15.0002V4.99996Z"
      fill="white"
    />
    <path
      d="M9.9801 8.01978H8.62687L8.00995 9.11977L7.37313 8.01978H6.0199L7.21393 9.99977L6 12.0198H7.29353L7.99005 10.9798H8.00995L8.70647 12.0198H10L8.78607 9.99977L9.9801 8.01978Z"
      fill="#0BA373"
    />
  </SvgIcon>
);
export default Excel;
