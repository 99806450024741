import * as React from 'react';
import ConditionalWrap from '../util/conditional-wrap';
import StyledTooltip from '@ep/insight-ui/elements/tooltip/styled';
import { makeStyles } from '@material-ui/core';
import MinimalEtable from '../minimal-etable';
import { toValue } from '../../sw/util/excel-formula';
import { get } from 'lodash';

const useStyles = makeStyles(() => ({
  tooltipContent: {
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  tooltipConfigContent: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    fontWeight: 400,
    whiteSpace: 'pre-line',
    padding: '1px 0',
  },
  tooltipConfigContainer: {
    background: '#253746',
    fontSize: '12px',
    lineHeight: '12px',
    fontWeight: 400,
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
    maxWidth: 'unset',
  },
  headerContent: {
    margin: 0,
    fontWeight: 500,
    fontSize: '12px',
    textTransform: 'uppercase',
  },
}));

const TooltipWrapper = ({ tooltipConfig = [], children, data, tableId, isShow = true }: any) => {
  const classes = useStyles();

  let tooltip = null;
  if (['string', 'number'].includes(typeof tooltipConfig)) {
    tooltip = tooltipConfig;
  } else if (
    Array.isArray(tooltipConfig) &&
    tooltipConfig.some((i) => i.key == 'status' && toValue(i.value, data) == 'Enabled') &&
    isShow
  ) {
    tooltip = tooltipConfig
      .filter((i) => ['header', 'span', 'table', 'block'].includes(i.key))
      .map((i, index) => {
        if (i.key == 'header') return <h3 className={classes.headerContent}>{toValue(i.value, data)}</h3>;
        if (i.key == 'span') return <span>{toValue(i.value, data)}</span>;
        if (i.key == 'block') return <div style={{ height: toValue(i.value, data) || '12px' }}></div>;
        if (i.key == 'table') {
          const id = 'table-action-tooltip-' + tableId + '-' + index;
          const childProps = {
            id: id,
            payload: (i.value || []).reduce((carry, i) => {
              return {
                ...carry,
                [i.key]: i.value,
              };
            }, {}),
            data: {
              node: {
                data,
              },
            },
          };
          return (
            <div id={id} key={id}>
              <MinimalEtable {...childProps} />
            </div>
          );
        }
        return null;
      });
  }
  return (
    <ConditionalWrap
      condition={tooltip && tooltip?.length}
      wrap={(children) => {
        return (
          <StyledTooltip
            title={
              <div className={classes.tooltipConfigContent} style={{ pointerEvents: 'all' }}>
                {tooltip}
              </div>
            }
            placement={'right'}
            interactive
            classes={{ tooltip: classes.tooltipConfigContainer }}
          >
            {children}
          </StyledTooltip>
        );
      }}
    >
      {children}
    </ConditionalWrap>
  );
};

export default TooltipWrapper;
