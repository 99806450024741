import * as React from 'react';
import { dataMenu as dataMenuDemo } from '@ep/insight-ui/elements/dropdown-menu/data-demo';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import MenuList from '@ep/insight-ui/elements/list-control/menu-list';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { IConfigTable, makeTable } from '@ep/insight-ui/elements/table/table-container';
import { CAMPAIGN_RES } from '@ep/insight-ui/elements/table/mocks/getCampaignData-100';
import { CAMPAIGN_SETTING_CONFIG } from '@ep/insight-ui/elements/table/mocks/table-config/campaign-settings';

interface IChartTableDetail {
  title?: string;
  dataMenu?: DropdownMenuDataType[][];
  children: React.ReactNode;
}
interface IProps {
  widthTable?: number;
}

const useStyles = makeStyles(() => ({
  container: {
    flexDirection: 'column',
    boxShadow: colors.shadow.default,
    border: `1px solid ${colors.border.subdued}`,
    borderRadius: '12px',
    padding: '16px',
    maxWidth: '100%',
    height: '100%',
    flexWrap: 'nowrap',
    '& .chartState': {
      padding: 0,
    },
  },
  heading: {
    marginBottom: '16px',
    display: 'flex',
    width: '100%',
  },
  headingText: {
    width: '100%',
    padding: 0,
    '&:hover': {
      color: colors.text.default,
      backgroundColor: 'transparent',
    },
    '&:avtive': {
      color: colors.text.default,
      backgroundColor: 'transparent',
    },
    '&.eip1-MuiButton-active': {
      color: colors.text.default,
      backgroundColor: 'transparent',
    },
    '& .eip1-MuiButton-label': {
      justifyContent: 'flex-start',
      '& .eip1-MuiTypography-root.eip1-MuiTypography-body2': {
        color: colors.text.default,
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '20px',
      },
    },
    '& .eip1-MuiTouchRipple-root': {
      display: 'none',
    },
  },
  body: {
    width: '100%',
    height: '100%',
  },
  dropdown: {
    width: '100%',
  },
  paper: {
    width: (props: IProps) => (props.widthTable ? `${props.widthTable}px` : 'auto'),
  },
}));

const ChartTableDetail = ({ children, title = '#', dataMenu = dataMenuDemo }: IChartTableDetail) => {
  const headingRef = React.useRef<HTMLDivElement>(null);
  const [widthTable, setWidthTable] = React.useState(null);
  const classes = useStyles({ widthTable });

  React.useEffect(() => {
    if (!headingRef.current) return;
    setWidthTable(headingRef.current.offsetWidth);
  }, [headingRef]);

  const getTableData = (params) => {
    return Promise.resolve(CAMPAIGN_RES);
  };

  const config: IConfigTable = {
    apiRequest: { getTableData },
    configuration: CAMPAIGN_SETTING_CONFIG,
    tableType: 'compact',
  };

  return (
    <Grid container className={`${classes.container}`} alignItems="center">
      <Grid item className={classes.heading} innerRef={headingRef}>
        <Box width={'100%'}>
          <Dropdown
            sizeButton={'24px'}
            className={classes.headingText}
            classNamePaper={classes.paper}
            disabledClickContentClose
            alignMenu="left"
            label={title}
          >
            <div>{makeTable({ config })}</div>
          </Dropdown>
        </Box>
        <Dropdown sizeButton={'24px'} alignMenu="right" icon="threeDotsVertical" label="">
          <MenuList listMenu={dataMenu} />
        </Dropdown>
      </Grid>
      <Grid item className={classes.body}>
        {children}
      </Grid>
    </Grid>
  );
};

export default ChartTableDetail;
