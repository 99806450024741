import { TooltipMapping } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import { formatCurrency } from '@ep/insight-ui/lib/number';
import moment from 'moment';
import { CreateCSSProperties } from '@material-ui/styles';
const useStyles = makeStyles(() => ({
  wrapp: TooltipMapping as CreateCSSProperties,
  loot: TooltipMapping.loot as CreateCSSProperties,
  root: TooltipMapping.root as CreateCSSProperties,
  legend: TooltipMapping.legend as CreateCSSProperties,
  popSticked: TooltipMapping.popLegendSticked as CreateCSSProperties,
  totalValue: TooltipMapping.totalValue as CreateCSSProperties,
}));

interface IStackedStyleProps {
  percent?: string;
  icon?: string;
  width?: number;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const funcConfigs = (props: IStackedStyleProps) => {
  const classes = useStyles();
  //Custom tooltip
  const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector(`.${classes.root}`);

    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.className = classes.root;
      const table = document.createElement('table');
      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
    return tooltipEl;
  };

  const externalTooltipHandler = (context) => {
    //Create tooltip element
    const { chart, tooltip } = context;
    const tooltipEl = getOrCreateTooltip(chart);
    const tableRoot = tooltipEl.querySelector('table');

    //Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }

    //Hide if no tooltips
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = '0';
      return;
    }

    // Set text into tooltip
    if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);
      const tableHead = document.createElement('thead');

      titleLines.forEach((title) => {
        const small = props.width <= 767 ? title : moment(title).format('dddd, DD MMMM YYYY');
        const tr = document.createElement('tr');
        const th = document.createElement('th');
        const titleTool = document.createElement('div');
        const text = document.createTextNode(small);
        titleTool.className = 'titleTool';
        titleTool.appendChild(text);
        th.appendChild(titleTool);
        tr.appendChild(th);
        tableHead.appendChild(tr);
      });

      const tableBody = document.createElement('tbody');
      bodyLines.forEach((body, i) => {
        const content = body[0].split(' ');
        const leftContent = content[0];
        const rightContent = content[1] + ' ' + content[2];
        const lastContent = ' ' + content[3];
        const colors = tooltip.labelColors[i];

        const divWrapper = document.createElement('div');
        const divLeft = document.createElement('div');
        const divRight = document.createElement('div');
        const span = document.createElement('span');
        const spanLeft = document.createElement('span');
        const spanRight = document.createElement('span');
        const tr = document.createElement('tr');
        const td = document.createElement('td');

        span.style.background = colors.backgroundColor;
        span.style.borderColor = colors.borderColor;

        const textLeft = document.createTextNode(leftContent);
        const textRight = document.createTextNode(rightContent);
        const textLast = document.createTextNode(lastContent);

        divWrapper.className = 'wraperContent';
        divLeft.className = 'wrapLeft';
        span.className = 'icon';
        spanLeft.className = 'leftContent';
        spanRight.className = 'lastContent';
        divRight.className = 'rightContent';

        spanLeft.appendChild(textLeft);
        spanRight.appendChild(textLast);
        divLeft.appendChild(span);
        divLeft.appendChild(spanLeft);
        divRight.appendChild(textRight);
        divRight.appendChild(spanRight);
        divWrapper.appendChild(divLeft);
        divWrapper.appendChild(divRight);
        td.appendChild(divWrapper);
        tr.appendChild(td);
        tableBody.appendChild(tr);
      });

      //Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
    }

    const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

    //Display position tooltip after chart
    tooltipEl.style.opacity = '1';
    if (tooltip.caretX > 832) {
      tooltipEl.style.transform = 'translate(-100%,-50%)';
    } else if (tooltip.caretX > 63) {
      tooltipEl.style.transform = 'translate(0,-100%)';
    }
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  };

  //Show random data chart
  const handleRandomdata = () => {
    const newArr = [];
    const small = props.width <= 767 ? 5 : 32;

    for (let i = 1; i < small; i++) {
      const random = Math.floor(Math.random() * 800) + 1;
      newArr.push(random);
    }
    return newArr;
  };

  //Show date to chart
  const handleDate = () => {
    const arrDays = [];

    if (props.width <= 767) {
      for (let newDay = 1; newDay < 5; newDay++) {
        arrDays.push('W' + newDay);
      }
      return arrDays;
    }

    let daysInMonth = moment().daysInMonth();

    while (daysInMonth) {
      const current = moment().date(daysInMonth);
      arrDays.push(current.format('DD MMMM'));
      daysInMonth--;
    }

    return arrDays.reverse();
  };

  const getOrCreateLegendList = (chart, id) => {
    const legendContainer = document.getElementById(id);
    if (!legendContainer) return {};
    const listContainer = legendContainer.querySelector('ul');
    const popLegend = legendContainer.querySelector(`.${classes.popSticked}`);
    const totalValue = legendContainer.querySelector(`.${classes.totalValue}`);

    //Create legend custom
    if (!listContainer) {
      const listContainer = document.createElement('ul');
      listContainer.className = classes.legend;
      legendContainer.appendChild(listContainer);
    }

    //Create pop-up when hover legend
    if (!popLegend) {
      const popLegend = document.createElement('p');
      popLegend.className = classes.popSticked;
      legendContainer.appendChild(popLegend);
    }

    //Create total value chart
    if (!totalValue) {
      const totalValue = document.createElement('p');
      totalValue.className = classes.totalValue;
      legendContainer.appendChild(totalValue);
    }

    return { listContainer, popLegend, totalValue };
  };

  //Get value price and percent value
  const getTotalValueAndPercent = (rawValue: number) => {
    const totalValue = `đ ${formatCurrency(rawValue, 'VND')}`;
    const totalPerCent = '(100%)';
    return { totalPerCent, totalValue };
  };

  const getTotalChart = (maxValue: number, percent: string) => {
    const currency = 'đ';
    const value = `${maxValue}`;
    const per = `${percent}`;
    return { value, per, currency };
  };

  //Custom legend
  const htmlLegendPlugin = {
    afterUpdate(chart) {
      const id = chart.canvas.parentNode.id;
      const ul = getOrCreateLegendList(chart, id).listContainer;
      const popLegend = getOrCreateLegendList(chart, id).popLegend;
      const totalValue = getOrCreateLegendList(chart, id).totalValue;
      const dataTotal = chart.data.datasets;
      const checkEl = document.getElementById(id);
      const items = chart.options.plugins.legend.labels.generateLabels(chart);
      const maxValue = getTotalChart({ ...chart.scales.yAxes }.end, props.percent);
      const newArr = [];
      const divLeft = document.createElement('div');
      const divRight = document.createElement('div');
      const divLabel = document.createElement('div');
      const divWrapp = document.createElement('div');
      const spanCurrency = document.createElement('span');
      const spanPercent = document.createElement('span');
      chart.canvas.parentNode.className = classes.wrapp;
      divLeft.className = 'leftContent';
      divRight.className = 'rightContent';
      divLabel.className = 'label';
      divWrapp.className = 'contentWrapp';
      spanCurrency.className = 'currency';
      spanCurrency.innerHTML = maxValue.currency;
      spanPercent.innerHTML = props.icon + maxValue.per;

      if (
        !checkEl?.querySelector(`.${classes.totalValue}`).firstChild &&
        maxValue.value !== 'undefined' &&
        maxValue.per !== 'undefined'
      ) {
        if (totalValue) {
          totalValue.appendChild(spanCurrency);
          totalValue.append(maxValue.value);
          totalValue.appendChild(spanPercent);
        }
      }

      //Remove old children
      // while (checkEl.querySelector('ul').firstChild) {
      //   checkEl.querySelector('ul').firstChild.remove();
      // }

      // while (checkEl.querySelector(`.${classes.popSticked}`).firstChild) {
      //   checkEl.querySelector(`.${classes.popSticked}`).firstChild.remove();
      // }
      if (ul) {
        ul.innerHTML = '';
      }
      //Get total data from chart
      dataTotal.forEach((item) => {
        item.data.forEach((data) => {
          newArr.push(data);
        });
      });

      //Get value and perCent
      const newValue = getTotalValueAndPercent(newArr.reduce((a, b) => a + b, 0));
      divLabel.append('Total');
      divLeft.append(newValue.totalValue);
      divRight.append(newValue.totalPerCent);
      divWrapp.appendChild(divLeft);
      divWrapp.appendChild(divRight);
      if (popLegend) {
        popLegend.appendChild(divLabel);
        popLegend.appendChild(divWrapp);
      }

      items.forEach((item) => {
        const li = document.createElement('li');

        //Click legend show pop-up
        li.onclick = (e) => {
          if (popLegend) {
            (popLegend as HTMLElement).style.display = 'flex';
            (popLegend as HTMLElement).style.top = e.pageY + 'px';
            (popLegend as HTMLElement).style.left = e.pageX + 'px';
          }
        };

        li.onmouseout = () => {
          if (popLegend) {
            (popLegend as HTMLElement).style.display = 'none';
          }
        };
        if (ul) {
          //Create color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = item.lineWidth + 'px';

          //Binding data text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);
          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        }
      });
    },
  };

  return {
    handleDate: handleDate,
    handleRandomdata: handleRandomdata,
    externalTooltipHandler: externalTooltipHandler,
    htmlLegendPlugin: htmlLegendPlugin,
  };
};
