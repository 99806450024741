import { get, groupBy as _groupBy, set, omit, zipObject, first, uniq } from 'lodash';
import { getQueryDatetimeValue } from '../../util/calendar';
import { toValue } from '../../util/excel-formula';
import { uQuery } from '../origin-request';
import moment from 'moment';
import { DATETIME_PICKER, DATETIME_REGEX } from '../constant';
import { ETableDataUpdate } from '../push-cell-update';

const DEFAULT_MAX_TRACTION_ROW = 10;
const LIMIT_COUNT = 9999;

const filterOutMetric = (i) => !String(i).startsWith('metric.');

const getFilterParams = (rows = [], primaryKeys = []) => {
  if (primaryKeys.length === 1) {
    const key = primaryKeys[0];
    const filterParams = rows.map((row) => row[key]);
    const result = Array.from({ length: Math.ceil(filterParams.length / DEFAULT_MAX_TRACTION_ROW) }).map((_, index) => {
      const value = filterParams.slice(index * DEFAULT_MAX_TRACTION_ROW, (index + 1) * DEFAULT_MAX_TRACTION_ROW);
      return {
        field: key,
        operator: 'IN',
        value,
      };
    });

    return result;
  }
  const filterParams = rows.map((row) => {
    return primaryKeys.reduce(
      (carry, key) => {
        return {
          ...carry,
          filters: carry.filters.concat(
            Object.keys(row).includes(key)
              ? {
                  field: key,
                  operator: 'is',
                  value: row[key],
                }
              : [],
          ),
        };
      },
      {
        combinator: 'and',
        filters: [],
      },
    );
  });
  const result = Array.from({ length: Math.ceil(filterParams.length / DEFAULT_MAX_TRACTION_ROW) }).map((_, index) =>
    filterParams.slice(index * DEFAULT_MAX_TRACTION_ROW, (index + 1) * DEFAULT_MAX_TRACTION_ROW).reduce(
      (carry, data) => {
        return {
          ...carry,
          filters: carry.filters.concat(data),
        };
      },
      {
        combinator: 'OR',
        filters: [],
      },
    ),
  );
  return result;
};

export async function pivotMetric(originData, { config, contextQuery, cellUpdate, pageRequests }, next) {
  const hasPivotMetric = get(config, ['system', 'allowPivotMetric'], 'no') == 'yes';
  const columnOrder = get(config, ['columnOrder'], []) || [];
  const pivotMetric = (get(config, ['pivot_metric'], []) || [])
    .sort((a, b) => {
      if (!columnOrder.includes(b)) return 1;
      return columnOrder.findIndex((el) => el == a) - columnOrder.findIndex((el) => el == b);
    })
    .map((i) => i.replace(/_pivot$/, ''));

  const calculateSecondaryMetric = get(config, ['system', 'calculateSecondaryMetric'], 'no') == 'yes';
  const metricsParamWithoutMetricPrefix = get(contextQuery, ['metrics'], []).filter(filterOutMetric);
  const eTablePrimaryKeys = get(config, 'primaryKeys', []);
  const groupBy = get(contextQuery, ['groupBy', 'columns'], get(contextQuery, ['groupBy', 'dimensions'], []));
  const drillDowns = get(contextQuery, ['groupBy', 'drillDowns'], []);
  const attributes = get(contextQuery, ['attributes'], []);
  const eTablePrimaryKeysWithoutMetricPrefix = get(config, 'primaryKeys', [])
    .filter(filterOutMetric)
    .filter((i) => attributes.includes(i));
  const filteredETablePrimaryKeys = get(config, ['primaryKeys'], []).filter((i) => attributes.includes(i));

  if (!hasPivotMetric || pivotMetric?.length == 0) {
    return next(originData, { config, contextQuery, cellUpdate, pageRequests });
  }

  const mappings = get(config, 'mapping', {});
  const groupPeriod = get(config, ['groupPeriod'], '');
  const datetimeFieldRequest = get(config, ['system', 'datetimeField'], 'created_datetime');
  const dateRange = contextQuery.dateRange || get(config, 'dateRange', {});
  const endpoint = get(config, ['endpoint', 'GET_TABLE_DATA'], '');
  const calendarConfig = get(config, ['calendarConfig'], {});
  const calendarTypePicker = get(calendarConfig, 'typePicker', 'default');
  const isDatetimePicker = calendarTypePicker === DATETIME_PICKER;
  const usePivotMultipleHeader = get(config, ['usePivotMultipleHeader'], 'yes') != 'no';
  const groupDimension =
    get(config, ['groupDimension'], null) && (!groupPeriod || groupPeriod == 'all')
      ? [].concat(get(config, ['groupDimension'], null))
      : [];
  const groupDimensionField = groupDimension.map((i) => get(mappings, [i, 'valueGetter', 'value'], null));
  const separator = '|';
  const _CONT_ = '_cont_';

  const metricAPIFields = pivotMetric.map((i) => get(mappings, [i, 'valueGetter', 'value'])).filter((i) => !!i);

  const createChildrenMetric = (parentField, parentIndex) => {
    return pivotMetric.map((i, index) => {
      return {
        field: parentField + '_' + i,
        name: get(mappings, [i, 'title'], i),
        rawMetric: i,
        dataType: 'float',
        width: 200,
        action: [],
        cell: {
          format: 'onePivot',
          valueGetter: {
            value: `pivot_metric_${i}`,
            label: `pivot_metric_${i}`,
          },
          staticValue: {},
          action: [],
        },
        suppressResizable: true,
        lockPosition: 'right',
        ...(index == 0 ? { additionalHeaderClass: 'eip_header_children_first_child' } : {}),
        parentIndex: parentIndex,
      };
    });
  };

  const filterParams = getFilterParams(get(originData, ['rows'], []), eTablePrimaryKeys);

  const { formatTime, periodUnit }: { formatTime: string; periodUnit: any } = (() => {
    switch (groupPeriod) {
      case 'hourly':
        return { formatTime: 'MMM DD HH:mm', periodUnit: 'hour' };
      case 'daily':
        return { formatTime: 'MMM DD, YYYY', periodUnit: 'day' };
      case 'weekly':
        return { formatTime: '[W]W, MMM DD YYYY', periodUnit: 'week' };
      case 'monthly':
        return { formatTime: 'MMM YYYY', periodUnit: 'month' };
      case 'quarterly':
        return { formatTime: '[Q]Q YYYY', periodUnit: 'quarter' };
      case 'yearly':
        return { formatTime: '[Y]YYYY', periodUnit: 'year' };
      default:
        return { formatTime: 'MMM DD, YYYY', periodUnit: 'day' };
    }
  })();

  const hourlyFormat = 'YYYY-MM-DD HH[:00:00]';
  const strtoMoment = periodUnit === 'hour' ? hourlyFormat : 'YYYY-MM-DD';
  const isDatetime = isDatetimePicker && DATETIME_REGEX.test(dateRange.dateFrom);
  const startMoment = isDatetime
    ? moment(dateRange.dateFrom)
    : periodUnit == 'week'
    ? moment(dateRange.dateFrom, strtoMoment).startOf(periodUnit).weekday(1).startOf('day')
    : moment(dateRange.dateFrom, strtoMoment).startOf(periodUnit).startOf('day');
  const endMoment = isDatetime
    ? moment(dateRange.dateTo)
    : periodUnit == 'week'
    ? moment(dateRange.dateTo, strtoMoment).startOf(periodUnit).weekday(1).endOf('day')
    : moment(dateRange.dateTo, strtoMoment).startOf(periodUnit).endOf('day');

  const PIVOT_HEADER = '_pivot_header_';

  const getPivotColumns = async () => {
    if (groupDimension.length) {
      const keys = filteredETablePrimaryKeys.concat(groupDimensionField);
      const params = {
        ...contextQuery,
        metrics: [],
        pagination: {
          page: 1,
          limit: LIMIT_COUNT,
        },
        dimensions: filteredETablePrimaryKeys
          .map((i) => String(i).split('.')?.[0])
          .concat(groupDimensionField.map((i) => String(i).split('.')?.[0])),
        attributes: keys,
        groupBy: {
          column: keys,
          dimensions: keys,
          aggregations: groupDimensionField
            .map((i) => {
              return {
                field: i,
                func: 'MAX',
              };
            })
            .concat(
              filteredETablePrimaryKeys.map((i) => {
                return {
                  field: i,
                  func: 'MAX',
                };
              }),
            ),
        },
        isSummary: true,
      };

      const result = await uQuery(endpoint, params);
      return Object.keys(
        _groupBy(get(result, ['data', 'rows'], []), (r) => {
          return groupDimensionField.map((f) => r[f]).join(separator);
        }),
      );
    }
    const result = new Array(endMoment.diff(startMoment, periodUnit) + 1).fill(1).map((_, index) => {
      const l = moment(startMoment).add(index, periodUnit).format(strtoMoment);
      return l;
    });
    return Promise.resolve(result);
  };

  const filledLabels = await getPivotColumns();

  // const pivotDataResponseObject = _groupBy(pivotDataRows, (r) => {
  //   return groupByField.map((f) => r[f]).join(separator);
  // });

  // Request for traction data
  const requestPivotData = async function () {
    const isRequesting = Object.entries(pageRequests || {})
      .filter(([key]) => key != config.tableId)
      .some(([_, value]) => {
        return new Date().getTime() - value.createdAt < 30000 && value.status != 'DONE';
      });
    if (isRequesting) {
      setTimeout(() => {
        requestPivotData();
      }, 100);
    } else {
      for (const index in filterParams) {
        const keys = filteredETablePrimaryKeys.concat(groupDimensionField);
        const params = {
          ...contextQuery,
          metrics: metricAPIFields,
          pagination: {
            page: 1,
            limit: LIMIT_COUNT,
          },
          ...(groupDimension.length
            ? {
                dimensions: filteredETablePrimaryKeys
                  .map((i) => String(i).split('.')?.[0])
                  .concat(groupDimensionField.map((i) => String(i).split('.')?.[0])),
                attributes: keys.concat(
                  !(!calculateSecondaryMetric || (groupBy.length && !drillDowns.length))
                    ? ['metric.type_data', 'metric.metric_code', 'metric.expression']
                    : [],
                ),
                groupPeriod: 'all',
                groupBy: {
                  column: keys,
                  dimensions: keys,
                  aggregations: groupDimensionField
                    .map((i) => {
                      return {
                        field: i,
                        func: 'MAX',
                      };
                    })
                    .concat(
                      filteredETablePrimaryKeys.map((i) => {
                        return {
                          field: i,
                          func: 'MAX',
                        };
                      }),
                    )
                    .concat(
                      !(!calculateSecondaryMetric || (groupBy.length && !drillDowns.length))
                        ? [
                            {
                              field: 'metric.type_data',
                              func: 'MAX',
                            },
                            {
                              field: 'metric.metric_code',
                              func: 'MAX',
                            },
                            {
                              field: 'metric.expression',
                              func: 'MAX',
                            },
                          ]
                        : [],
                    ),
                },
              }
            : {
                groupPeriod: groupPeriod || 'daily',
                groupBy: undefined,
              }),
          isSummary: undefined,
          filter: {
            combinator: 'AND',
            filters: [
              filterParams[index],
              ...get(contextQuery, ['filter', 'filters'], []),
              {
                field: datetimeFieldRequest,
                operator: '>=',
                value: getQueryDatetimeValue(datetimeFieldRequest, dateRange.dateFrom, 'start'),
              },
              {
                field: datetimeFieldRequest,
                operator: '<=',
                value: getQueryDatetimeValue(datetimeFieldRequest, dateRange.dateTo, 'end'),
              },
            ],
          },
          _eip: {
            src: usePivotMultipleHeader ? 'pivot.metric.usePivotMultipleHeader' : 'pivot.metric',
            from: Number(index) * DEFAULT_MAX_TRACTION_ROW,
          },
        };

        const process = async (context: { columnTractionElasticityKey; groupPeriod; transformedParams }, el) => {
          const { columnTractionElasticityKey, transformedParams } = context;
          const groupByField = filteredETablePrimaryKeys;

          // if (Object.keys(masterDataPrimaryKey).length === 0) {
          //   groupByField = eTablePrimaryKeys;
          // } else if (masterDataPrimaryKey) {
          //   groupByField = Object.keys(masterDataPrimaryKey).reduce((a, b) => {
          //     return [...a, ...masterDataPrimaryKey[b].map((ele) => `${b}.${ele}`)];
          //   }, []);
          // }

          const { rows } = el.data;

          if (!(!calculateSecondaryMetric || (groupBy.length && !drillDowns.length))) {
            const metricsToFetch = uniq(
              rows.reduce((carry, row) => {
                const metricTypeData = row?.['metric.type_data'];
                const metricCode = row?.['metric.metric_code'];
                const metricExpression = row?.['metric.expression'];
                if (metricTypeData == 'secondary_data' && String(metricCode).includes(_CONT_)) {
                  return carry.concat(row['metric.metric_code'].split(_CONT_)[0]);
                }
                if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
                  return carry;
                }
                if (row?.['metric.type_data'] != 'secondary_data' || row?.['metric.expression']?.length == 0) {
                  return carry;
                }
                const primaryMetrics = String(metricExpression).match(/\w+/g);
                return carry.concat(primaryMetrics || []);
              }, []),
            );
            if (metricsToFetch.length > 0) {
              const requestPendingPrimaryMetricsParams = {
                ...transformedParams,
                filter: {
                  ...transformedParams.filter,
                  filters: (transformedParams.filter?.filters || [])
                    .filter((i, index) => !String(i.field).startsWith('metric.') && index != 0)
                    .concat({
                      field: 'metric.metric_code',
                      operator: 'IN',
                      value: metricsToFetch,
                    }),
                },
                pagination: {
                  page: 1,
                  limit: 9999,
                },
              };

              const pendingPrimaryMetricsResults = await uQuery(endpoint, requestPendingPrimaryMetricsParams);
              const pendingPrimaryMetricsData = get(pendingPrimaryMetricsResults, ['data', 'rows'], []);
              rows.forEach((row) => {
                const metricExpression = row?.['metric.expression'];
                const metricTypeData = row?.['metric.type_data'];
                const metricCode = row?.['metric.metric_code'];
                const primaryKeys = groupDimension.length
                  ? eTablePrimaryKeysWithoutMetricPrefix.concat(groupDimensionField)
                  : eTablePrimaryKeysWithoutMetricPrefix;
                if (metricTypeData == 'secondary_data' && metricCode.includes(_CONT_)) {
                  const primaryMetricsData = pendingPrimaryMetricsData
                    .filter((item) => {
                      const matchedMetricCode = metricCode.split(_CONT_)[0];
                      return (
                        (row['metric_category.name'] == item['metric_category.name'] ||
                          item['metric_category.name'] == 'OVERALL') &&
                        matchedMetricCode == item['metric.metric_code'] &&
                        (primaryKeys.length == 0 ||
                          primaryKeys
                            .filter((i) => !['metric_category.name', 'metric_category.id'].includes(i))
                            .every((i) => {
                              return row[i] == item[i];
                            })) &&
                        (groupDimension.length ? true : row['datetime'] == item['datetime'])
                      );
                    })
                    .reduce((carry, item) => {
                      if (item['metric_category.name'] == row['metric_category.name']) {
                        carry['current'] = item;
                      }
                      if (item['metric_category.name'] == 'OVERALL') {
                        carry['total'] = item;
                      }

                      return carry;
                    }, {});
                  if (!Object.keys(primaryMetricsData).length) return row;

                  const requestMetrics = get(transformedParams, ['metrics'], []);
                  metricsParamWithoutMetricPrefix.concat(requestMetrics).forEach((p) => {
                    const newValue = get(primaryMetricsData, ['current', p]) / get(primaryMetricsData, ['total', p]);
                    set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
                  });
                  return row;
                }
                if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
                  return row;
                }
                const primaryMetricsData = pendingPrimaryMetricsData
                  .filter((item) => {
                    const primaryMetrics = String(metricExpression).match(/\w+/g);
                    return (
                      primaryMetrics.includes(item['metric.metric_code']) &&
                      (primaryKeys.length == 0 ||
                        primaryKeys.every((i) => {
                          return row[i] == item[i];
                        })) &&
                      (groupDimension.length ? true : row['datetime'] == item['datetime'])
                    );
                  })
                  .reduce((carry, item) => {
                    return {
                      ...carry,
                      [item['metric.metric_code']]: item,
                    };
                  }, {});
                if (!Object.keys(primaryMetricsData).length) return row;
                const requestMetrics = get(transformedParams, ['metrics'], []);
                metricsParamWithoutMetricPrefix.concat(requestMetrics).forEach((p) => {
                  const newValue = eval(
                    metricExpression.replace(/\{\w+\}/g, (c) => {
                      return get(primaryMetricsData, [c.replace('{', '').replace('}', ''), p]);
                    }),
                  );
                  set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
                });
              });
            }
          }

          const separator = '|';

          const rowsEffectData = get(originData, ['rows'], []).slice(
            +index * DEFAULT_MAX_TRACTION_ROW,
            (+index + 1) * DEFAULT_MAX_TRACTION_ROW,
          );
          const allGroupedRows = _groupBy(rowsEffectData, (r) => {
            return groupByField.map((f) => r[f]).join(separator);
          });

          const groupedRows = _groupBy(rows, (r) => {
            return groupByField.map((f) => r[f]).join(separator);
          });

          const updateData = Object.keys(allGroupedRows).reduce((carry, rowKey) => {
            const ids = String(rowKey).split(separator);
            const keys = groupByField.reduce((carry, f, index) => {
              return { ...carry, [f]: ids[index] };
            }, {});

            const rows = groupedRows[rowKey] || [];

            let updateCellByCol = columnTractionElasticityKey.reduce((carry, k) => {
              return { ...carry, [k]: {} };
            }, {});

            for (const row of rows) {
              for (const metric of pivotMetric) {
                const metricAPIField = get(mappings, [metric, 'valueGetter', 'value'], '');
                updateCellByCol = columnTractionElasticityKey.reduce((carry, colKey) => {
                  const dimension = groupDimension.length
                    ? groupDimensionField.map((i) => row[i]).join(separator)
                    : moment(row.datetime).format(strtoMoment);
                  set(carry, [colKey, metric, dimension], row[metricAPIField]);
                  return carry;
                }, updateCellByCol);
              }
            }

            columnTractionElasticityKey.forEach((colKey) => {
              pivotMetric.forEach((metric) => {
                const updatedCell = usePivotMultipleHeader
                  ? {
                      keyId: [rowKey].join(separator),
                      keys: {
                        ...keys,
                      },
                      updatePath: [colKey + '_' + metric],
                      data: {
                        value: get(updateCellByCol, [colKey, metric, colKey.replace(PIVOT_HEADER, '')], null),
                        colField: metric,
                        loaded: true,
                        success: true,
                      },
                    }
                  : {
                      keyId: [rowKey].concat(metric).join(separator),
                      keys: {
                        ...keys,
                        '_pivot.value': metric,
                      },
                      updatePath: [colKey],
                      data: {
                        value: get(updateCellByCol, [colKey, metric, colKey.replace(PIVOT_HEADER, '')], null),
                        colField: metric,
                        loaded: true,
                        success: true,
                      },
                    };

                carry.push(updatedCell);
              });
            });

            return carry;
          }, []);

          return updateData as ETableDataUpdate[];
        };

        const page = contextQuery.pagination.page || 1;
        const limit = contextQuery.pagination.limit || 10;
        const columnEffects = usePivotMultipleHeader
          ? filledLabels.reduce((carry, i) => {
              const subMetrics = pivotMetric.map((metric) => PIVOT_HEADER + i + '_' + metric);
              return carry.concat(subMetrics);
            }, [])
          : filledLabels.map((i) => PIVOT_HEADER + i);

        cellUpdate.addQuery({
          endpoint,
          params: omit(params, ['from', 'to']),
          columnEffects: columnEffects,
          rowEffects: [(page - 1) * limit, page * limit - 1],
          process: process.bind(null, {
            columnTractionElasticityKey: filledLabels.map((i) => PIVOT_HEADER + i),
            groupPeriod: params.groupPeriod,
            extra: { queryDatetimeField: datetimeFieldRequest },
            transformedParams: params,
          }),
        });

        if (!usePivotMultipleHeader) {
          const keys = filteredETablePrimaryKeys.concat(groupDimensionField);
          const params = {
            ...contextQuery,
            metrics: metricAPIFields,
            pagination: {
              page: 1,
              limit: LIMIT_COUNT,
            },
            ...(groupDimension.length
              ? {
                  dimensions: filteredETablePrimaryKeys
                    .map((i) => String(i).split('.')?.[0])
                    .concat(groupDimensionField.map((i) => String(i).split('.')?.[0])),
                  attributes: keys,
                  groupPeriod: 'all',
                  groupBy: {
                    column: keys,
                    dimensions: keys,
                    aggregations: groupDimensionField
                      .map((i) => {
                        return {
                          field: i,
                          func: 'MAX',
                        };
                      })
                      .concat(
                        filteredETablePrimaryKeys.map((i) => {
                          return {
                            field: i,
                            func: 'MAX',
                          };
                        }),
                      ),
                  },
                }
              : {
                  groupPeriod: 'all',
                  groupBy: undefined,
                }),
            isSummary: true,
            filter: {
              combinator: 'AND',
              filters: [
                filterParams[index],
                ...get(contextQuery, ['filter', 'filters'], []),
                {
                  field: datetimeFieldRequest,
                  operator: '>=',
                  value: getQueryDatetimeValue(datetimeFieldRequest, dateRange.dateFrom, 'start'),
                },
                {
                  field: datetimeFieldRequest,
                  operator: '<=',
                  value: getQueryDatetimeValue(datetimeFieldRequest, dateRange.dateTo, 'end'),
                },
              ],
            },
            _eip: {
              src: 'pivot.metric.total',
              from: Number(index) * DEFAULT_MAX_TRACTION_ROW,
            },
          };

          const process = async (context: { columnTractionElasticityKey; groupPeriod; transformedParams }, el) => {
            const { columnTractionElasticityKey, transformedParams } = context;
            const groupByField = filteredETablePrimaryKeys;

            // if (Object.keys(masterDataPrimaryKey).length === 0) {
            //   groupByField = eTablePrimaryKeys;
            // } else if (masterDataPrimaryKey) {
            //   groupByField = Object.keys(masterDataPrimaryKey).reduce((a, b) => {
            //     return [...a, ...masterDataPrimaryKey[b].map((ele) => `${b}.${ele}`)];
            //   }, []);
            // }

            const { rows } = el.data;

            if (!(!calculateSecondaryMetric || (groupBy.length && !drillDowns.length))) {
              const metricsToFetch = uniq(
                rows.reduce((carry, row) => {
                  const metricTypeData = row?.['metric.type_data'];
                  const metricCode = row?.['metric.metric_code'];
                  const metricExpression = row?.['metric.expression'];
                  if (metricTypeData == 'secondary_data' && String(metricCode).includes(_CONT_)) {
                    return carry.concat(row['metric.metric_code'].split(_CONT_)[0]);
                  }
                  if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
                    return carry;
                  }
                  if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
                    return carry;
                  }
                  const primaryMetrics = String(metricExpression).match(/\w+/g);
                  return carry.concat(primaryMetrics);
                }, []),
              );
              if (metricsToFetch.length > 0) {
                const requestPendingPrimaryMetricsParams = {
                  ...transformedParams,
                  filter: {
                    ...transformedParams.filter,
                    filters: (transformedParams.filter?.filters || [])
                      .filter((i, index) => !String(i.field).startsWith('metric.') && index != 0)
                      .concat({
                        field: 'metric.metric_code',
                        operator: 'IN',
                        value: metricsToFetch,
                      }),
                  },
                  pagination: {
                    page: 1,
                    limit: 9999,
                  },
                };

                const pendingPrimaryMetricsResults = await uQuery(endpoint, requestPendingPrimaryMetricsParams);
                const pendingPrimaryMetricsData = get(pendingPrimaryMetricsResults, ['data', 'rows'], []);
                rows.forEach((row) => {
                  const metricExpression = row?.['metric.expression'];
                  const metricTypeData = row?.['metric.type_data'];
                  const metricCode = row?.['metric.metric_code'];
                  const primaryKeys = groupDimension.length
                    ? eTablePrimaryKeysWithoutMetricPrefix.concat(groupDimensionField)
                    : eTablePrimaryKeysWithoutMetricPrefix;
                  if (metricTypeData == 'secondary_data' && metricCode.includes(_CONT_)) {
                    const primaryMetricsData = pendingPrimaryMetricsData
                      .filter((item) => {
                        const matchedMetricCode = metricCode.split(_CONT_)[0];
                        return (
                          (row['metric_category.name'] == item['metric_category.name'] ||
                            item['metric_category.name'] == 'OVERALL') &&
                          matchedMetricCode == item['metric.metric_code'] &&
                          (primaryKeys.length == 0 ||
                            primaryKeys
                              .filter((i) => !['metric_category.name', 'metric_category.id'].includes(i))
                              .every((i) => {
                                return row[i] == item[i];
                              }))
                        );
                      })
                      .reduce((carry, item) => {
                        if (item['metric_category.name'] == row['metric_category.name']) {
                          carry['current'] = item;
                        }
                        if (item['metric_category.name'] == 'OVERALL') {
                          carry['total'] = item;
                        }

                        return carry;
                      }, {});
                    if (!Object.keys(primaryMetricsData).length) return row;
                    const requestMetrics = get(transformedParams, ['metrics'], []);
                    metricsParamWithoutMetricPrefix.concat(requestMetrics).forEach((p) => {
                      const newValue = get(primaryMetricsData, ['current', p]) / get(primaryMetricsData, ['total', p]);
                      set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
                    });
                    return row;
                  }
                  if (metricTypeData != 'secondary_data' || metricExpression?.length == 0) {
                    return row;
                  }
                  const primaryMetricsData = pendingPrimaryMetricsData
                    .filter((item) => {
                      const primaryMetrics = String(metricExpression).match(/\w+/g);
                      return (
                        primaryMetrics.includes(item['metric.metric_code']) &&
                        (primaryKeys.length == 0 ||
                          primaryKeys.every((i) => {
                            return row[i] == item[i];
                          }))
                      );
                    })
                    .reduce((carry, item) => {
                      return {
                        ...carry,
                        [item['metric.metric_code']]: item,
                      };
                    }, {});
                  if (!Object.keys(primaryMetricsData).length) return row;
                  const requestMetrics = get(transformedParams, ['metrics'], []);
                  metricsParamWithoutMetricPrefix.concat(requestMetrics).forEach((p) => {
                    const newValue = eval(
                      metricExpression.replace(/\{\w+\}/g, (c) => {
                        return get(primaryMetricsData, [c.replace('{', '').replace('}', ''), p]);
                      }),
                    );
                    set(row, [p], newValue === Infinity || Number.isNaN(newValue) ? null : newValue);
                  });
                });
              }
            }

            const separator = '|';

            const rowsEffectData = get(originData, ['rows'], []).slice(
              +index * DEFAULT_MAX_TRACTION_ROW,
              (+index + 1) * DEFAULT_MAX_TRACTION_ROW,
            );
            const allGroupedRows = _groupBy(rowsEffectData, (r) => {
              return groupByField.map((f) => r[f]).join(separator);
            });

            const groupedRows = _groupBy(rows, (r) => {
              return groupByField.map((f) => r[f]).join(separator);
            });

            const updateData = Object.keys(allGroupedRows).reduce((carry, rowKey) => {
              const ids = String(rowKey).split(separator);
              const keys = groupByField.reduce((carry1, f, index) => {
                return { ...carry1, [f]: ids[index] };
              }, {});

              const rows = groupedRows[rowKey] || [];

              let updateCellByCol = columnTractionElasticityKey.reduce((carry, k) => {
                return { ...carry, [k]: {} };
              }, {});

              for (const row of rows) {
                for (const metric of pivotMetric) {
                  const metricAPIField = get(mappings, [metric, 'valueGetter', 'value'], '');
                  updateCellByCol = columnTractionElasticityKey.reduce((carry, colKey) => {
                    set(carry, [colKey, metric], row[metricAPIField]);
                    return carry;
                  }, updateCellByCol);
                }
              }

              columnTractionElasticityKey.forEach((colKey) => {
                pivotMetric.forEach((metric) => {
                  const updatedCell = {
                    keyId: [rowKey].concat(metric).join(separator),
                    keys: {
                      ...keys,
                      '_pivot.value': metric,
                    },
                    updatePath: [colKey],
                    data: {
                      value: get(updateCellByCol, [colKey, metric], null),
                      colField: metric,
                      loaded: true,
                      success: true,
                    },
                  };

                  carry.push(updatedCell);
                });
              });

              return carry;
            }, []);

            return updateData as ETableDataUpdate[];
          };

          const columnEffects = ['_pivot.column.total'];

          cellUpdate.addQuery({
            endpoint,
            params: omit(params, ['from', 'to']),
            columnEffects: columnEffects,
            rowEffects: [(page - 1) * limit, page * limit - 1],
            process: process.bind(null, {
              columnTractionElasticityKey: columnEffects,
              groupPeriod: params.groupPeriod,
              extra: { queryDatetimeField: datetimeFieldRequest },
              transformedParams: params,
            }),
          });
        }

        cellUpdate.complete();
      }
    }
  };

  if (!groupBy.length || drillDowns.length) {
    requestPivotData();
  }

  const pivotColumn = usePivotMultipleHeader
    ? filledLabels.map((i, index) => {
        const field = PIVOT_HEADER + i;
        return {
          field: field,
          name: moment(i).isValid() ? moment(i).format(formatTime) : i,
          dataType: 'float',
          action: [],
          cell: {
            format: 'onePivot',
            valueGetter: {
              value: `pivot_metric_${i}`,
              label: `pivot_metric_${i}`,
            },
            staticValue: {},
            action: [],
          },
          marryChildren: true,
          suppressResizable: true,
          lockPosition: 'right',
          children: createChildrenMetric(field, index),
        };
      })
    : [
        {
          field: '_pivot.column',
          name: 'Metric',
          dataType: 'string',
          action: [],
          cell: {
            format: 'oneDimension',
            valueGetter: {
              value: '_pivot.value',
              label: '_pivot.label',
            },
            staticValue: {
              status: 'ic/mingcute:hashtag-fill/#1f496f',
            },
            action: [],
          },
          suppressResizable: true,
          lockPosition: 'right',
          rawMetric: 'unknown',
        },
        {
          field: '_pivot.column.total',
          name: 'Total',
          dataType: 'float',
          action: [],
          cell: {
            format: 'onePivot',
            valueGetter: {
              value: '_pivot.value.total',
              label: '_pivot.label.total',
            },
            staticValue: {},
            action: [],
          },
          suppressResizable: true,
          lockPosition: 'right',
          rawMetric: 'unknown',
          pivotColumnWidth: 120,
        },
      ].concat(
        filledLabels.map((i) => {
          return {
            field: PIVOT_HEADER + i,
            name: moment(i).isValid() ? moment(i).format(formatTime) : i,
            dataType: 'float',
            action: [],
            cell: {
              format: 'onePivot',
              valueGetter: {
                value: `pivot_metric_${i}`,
                label: `pivot_metric_${i}`,
              },
              staticValue: {},
              action: [],
            },
            suppressResizable: true,
            lockPosition: 'right',
            rawMetric: 'unknown',
            pivotColumnWidth: 120,
          };
        }),
      );

  const { columns, data } = await next(originData, { config, contextQuery, cellUpdate, pageRequests });

  const pivotAdditionalData = pivotMetric.map((i) => {
    return {
      '_pivot.label': `<span style="font-weight: 500; color: #1f496f;">${get(mappings, [i, 'title'], '')}</span>`,
      '_pivot.value': i,
      status: 'ic/mingcute:hashtag-fill/#1f496f',
    };
  });
  if (!usePivotMultipleHeader) {
    const rows = get(data, 'data.rows', []);
    const pivotData = rows.reduce((carry, row) => {
      const pivotedData = pivotAdditionalData.map((dt) => {
        return {
          ...row,
          ...dt,
        };
      });

      return carry.concat(pivotedData);
    }, []);

    set(data, 'data.rows', pivotData);
  }

  return {
    columns: columns.concat(pivotColumn),
    data,
  };
}
