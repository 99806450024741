import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { TypeValueBudget } from '../budget-row-form/budget-row-form';
import BudgetRowForm from '../budget-row-form';
import { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import GroupButtonSubmit from '../../group-button-submit';
import * as _ from 'lodash';
import { IValueBudgetFormat } from '@ep/insight-ui/elements/table/format/budgets/budget-format';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  arrowUp: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  arrowDown: {
    marginBottom: '2px',
    cursor: 'pointer',
  },
  buttonAdd: {
    marginTop: 10,
  },
}));
export const options: OptionSelectType[] = [
  {
    label: 'Total budget',
    value: 'total',
  },
  {
    label: 'Daily budget',
    value: 'daily',
  },
];

const operators: OptionSelectType[] = [
  {
    label: 'Exact value',
    value: 'exactValue',
  },
  {
    label: 'No limit',
    value: 'noLimit',
  },
];
type Props = {
  data: {
    value: IValueBudgetFormat;
    node: any;
  };
  title: string;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const BudgetSelectionEditor = ({ data, title = '', onSubmit, anchorEl, setAnchorEl, ...rest }: Props) => {
  const classes = useStyles();
  const disabledModelNoLimit = _.get(data, ['value', 'disabledModelNoLimit'], false);
  const defaultBudgets = React.useMemo(() => {
    const currency = _.get(data, ['value', 'currency'], '');
    const totalBudget = _.get(data, ['value', 'totalBudget'], '');
    const dailyBudget = _.get(data, ['value', 'dailyBudget'], '');
    const arr: Array<TypeValueBudget> = [];
    if (totalBudget && parseFloat(totalBudget) > 0) {
      arr.push({
        field: 'total',
        operator: totalBudget ? operators[0].value : operators[1].value,
        budget: String(totalBudget),
        currency: currency,
      });
      return arr;
    }
    if (dailyBudget && parseFloat(dailyBudget) > 0) {
      arr.push({
        field: 'daily',
        operator: dailyBudget ? operators[0].value : operators[1].value,
        budget: String(dailyBudget),
        currency: currency,
      });
      return arr;
    }
    arr.push({
      field: 'total',
      operator: totalBudget ? operators[0].value : operators[1].value,
      budget: String(0),
      currency: currency,
    });
    return arr;
  }, [data.value, anchorEl]);
  const [budgets, setBudgets] = React.useState<Array<TypeValueBudget>>(defaultBudgets);
  React.useEffect(() => {
    setBudgets(defaultBudgets);
  }, [anchorEl]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const handleRemove = (index: number) => {
    const temp: Array<TypeValueBudget> = JSON.parse(JSON.stringify(budgets));
    if (temp[index]) {
      temp.splice(index, 1);
      setBudgets(temp);
    }
  };

  const handleOnChangeBudgetItem = (value: TypeValueBudget, index: number) => {
    if (budgets[index]) {
      budgets[index].budget = value.budget;
      budgets[index].field = value.field;
      budgets[index].operator = value.operator;
      setBudgets(budgets);
    }
  };
  const getDataMenu = (index: number): DropdownMenuDataType[][] => {
    return [[{ title: 'Remove', onClick: () => handleRemove(index) }]];
  };

  const handleClosed = () => setAnchorEl(null);
  const handleSubmit = () => {
    const run = async (val: IValueBudgetFormat) => {
      setIsSubmitting(true);
      const response: any = await onSubmit({ value: val, row: data.node.data });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    };
    const currency = _.get(data, ['value', 'currency'], '');
    let totalBudget = '0';
    let dailyBudget = '0';
    const valueSubmit: IValueBudgetFormat = {
      totalBudget: +totalBudget,
      dailyBudget: +dailyBudget,
      currency,
    };
    budgets.forEach((item) => {
      if (item.field == 'total') {
        totalBudget = item.budget;
      }
      if (item.field == 'daily') {
        dailyBudget = item.budget;
      }
    });
    if (totalBudget && parseFloat(totalBudget) > 0) {
      valueSubmit.totalBudget = +totalBudget;
      valueSubmit.dailyBudget = 0;
      return run(valueSubmit);
    }
    if (dailyBudget && parseFloat(dailyBudget) > 0) {
      valueSubmit.totalBudget = 0;
      valueSubmit.dailyBudget = +dailyBudget;
      return run(valueSubmit);
    }
    return run(valueSubmit);
  };
  const getOptions = (field: string) => {
    //   let idex = budgets.findIndex(item=>item.field==)
    if (budgets.length > 1) {
      return options.filter((item) => item.value == field);
    }
    return options;
  };
  const backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
  const titleTable = backboneContext.getConfig('title');
  const titleTableClass = titleTable.toString().replace(/\s/g, '_').toLowerCase();
  const titleActionClass = title.replace(/\s/g, '_').toLowerCase();

  return (
    <div
      style={{ padding: '0 10px', minWidth: '400px' }}
      className={`eip_elevation_table_${titleTableClass} eip_action_edit_${titleActionClass}`}
    >
      <HeaderList title={title} variant="h4" className={classes.header} />
      {budgets.map((budget, index) => (
        <BudgetRowForm
          key={index}
          options={getOptions(budget.field)}
          operators={operators}
          value={budget}
          enableStyleMenu={false}
          listMenu={index == 1 ? getDataMenu(index) : null}
          onChange={(value) => handleOnChangeBudgetItem(value, index)}
          disabledModelNoLimit={disabledModelNoLimit}
        />
      ))}
      <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
    </div>
  );
};

export default BudgetSelectionEditor;
