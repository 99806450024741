import * as React from 'react';
import GridLayout, { WidthProvider } from 'react-grid-layout';
import { useSelector } from 'react-redux';
import { DashboardItem } from '../..';
import { FilterContext, FilterType } from '../../context';
import { GridAdapterContext } from '../../grid-adapter';
import {
  ChartLibComponent,
  DashboardState,
  NodeData,
} from '../../type';

let ContainerGridLayout = WidthProvider(GridLayout);

type ContainerChartLibProps = {
  nodeData: NodeData;
};

export default class ContainerChartLib implements ChartLibComponent {
  render(dom, nodeData, eventBus) {
    return <NodeUI nodeData={nodeData} />;
  }
  renderConfigurationForm() {
    return <div></div>;
  }
}

function NodeUI({ nodeData }: ContainerChartLibProps) {
  const childNodes = useSelector((state: DashboardState) => {
    let nodeIds = nodeData.customAttributes.nodeIds || [];
    return {
      nodes: state.nodes.filter((n) => nodeIds.indexOf(n.id) > -1),
    };
  });

  const context = React.useContext(GridAdapterContext);
  const filterContext = React.useContext(FilterContext);

  const [containerFilter, setContainerFilter] =
    React.useState<FilterType>();

  React.useEffect(() => {
    setContainerFilter({
      ...filterContext.filter,
      ...containerFilter,
    });
  }, [filterContext.filter]);

  const handleUpdateContainerFilter = (filter: FilterType) => {
    setContainerFilter({
      ...containerFilter,
      ...filter,
    });
  };

  console.info('container 1', { context, childNodes });

  return (
    <FilterContext.Provider
      value={{
        filter: containerFilter,
        onUpdateFilter: handleUpdateContainerFilter,
      }}
    >
      <ContainerGridLayout
        className={'container-grid-layout'}
        cols={nodeData.customAttributes.w}
        rowHeight={context.squareWidth}
        margin={[context.margin, context.margin]}
        layout={nodeData.customAttributes.nodeLayouts.map((i) => ({
          ...i.layout,
          i: i.id,
        }))}
        containerPadding={[0, 0]}
        measureBeforeMount={true}
      >
        {childNodes.nodes.map((i) => (
          <div
            key={i.id}
            className={'ep-dashboard-item'}
            data-node-id={i.id}
          >
            <DashboardItem key={i.id} nodeData={i}></DashboardItem>
          </div>
        ))}
      </ContainerGridLayout>
    </FilterContext.Provider>
  );
}
