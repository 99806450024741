import * as React from 'react';
import { makeStyles } from '@material-ui/core';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import GroupButtonSubmit from '../group-button-submit';
import SelectSearchForm from '../../../form-control/select-form/select-search-form';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    marginTop: '10px',
    marginBottom: '15px',
  },
  inputText: {
    '& .eip1-MuiOutlinedInput-root': {
      width: '100%',
      height: '32px',
    },
    width: '100%',
  },
  textError: {
    color: '#D4290D',
    fontSize: '10px',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  TextField: {
    width: '100%',
  },
  InputPaper: {
    '& input': {
      width: '100%',
    },
  },
  formControl: {
    marginBottom: 0,
    '&.select': {},
  },
}));

const optionsPermission = [
  {
    label: 'Viewer',
    value: 'viewer',
  },
  {
    label: 'Full access',
    value: 'full_access',
  },
  {
    label: 'Editor',
    value: 'editor',
  },
];

export const UserPermission = ({ data, title = '', onSubmit, anchorEl, setAnchorEl }: any) => {
  const [value, setValue] = React.useState(data.value.value);

  const classes = useStyles();
  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = () => {
    return;
  };

  return (
    <>
      <div style={{ padding: '0 10px', minWidth: '320px' }}>
        <HeaderList title={'Permission'} variant="h4" className={classes.header} />
        <div className={classes.dialog}>
          <SelectSearchForm
            options={optionsPermission}
            inputWidth="300px"
            value={value.toLowerCase()}
            placeholder="Choose permission"
            onChange={(e) => setValue(e)}
          />
        </div>
        <GroupButtonSubmit submitLabel="Apply" onCancel={handleClosed} onSubmit={handleSubmit} />
      </div>
    </>
  );
};
