import { ITextField, TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';
import { makeStyles } from '@material-ui/core';
import * as React from 'react';
import FormControlTemplate from './form-control-template';

const useStyles = makeStyles({
  inputGroup: {
    width: '100%',
    '& .Mui-disabled.eip1-MuiOutlinedInput-root': {
      width: '100%',
    },
    '& input': {
      width: '100%',
    },
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield',
    },
  },
  input: {},
});

interface FormControlInputProps extends ITextField {
  label: string;
  classNameContainer?: string;
}
const FormControlInput = ({ label, classNameContainer = '', ...res }: FormControlInputProps) => {
  const classes = useStyles();
  return (
    <FormControlTemplate label={label} className={`${classNameContainer}`}>
      <TextFieldCustom className={classes.inputGroup} classNamePaperInput={classes.input} {...res} />
    </FormControlTemplate>
  );
};

export default FormControlInput;
