import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Share = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 2C11 3.10457 10.1046 4 9 4C8.67994 4 8.37743 3.92482 8.10919 3.79115L4.50774 5.87045L8.83914 8.37119C9.16645 8.13749 9.56717 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12C8.96359 12 8.1113 11.2117 8.01007 10.2019L3.32579 7.49745C2.97287 7.81015 2.50861 8 2 8C0.895431 8 0 7.10457 0 6C0 4.89543 0.895431 4 2 4C2.42169 4 2.81291 4.13051 3.13545 4.35334L7.00355 2.12009C7.00119 2.08036 7 2.04032 7 2C7 0.895431 7.89543 0 9 0C10.1046 0 11 0.895431 11 2Z" />
  </SvgIcon>
);

export default Share;
