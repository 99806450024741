import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, AccordionProps } from '@material-ui/core';
import Icon, { IconType } from '@ep/insight-ui/icons/Icon';
import { omit } from 'lodash';

/** Style */
const useStyles = makeStyles(() => ({
  container: (props: StyleProps) => ({
    width: props.width || 'auto',
    boxShadow: 'none',
    padding: '0px',
  }),
}));

type StyleProps = {
  width?: string;
};

interface AccordionCustomProps extends AccordionProps {
  icon?: IconType;
  label?: string;
  width?: string;
  sizeIcon?: string;
  classes: AccordionProps['classes'] & {
    contentDetailsRoot?: string;
  };
}

const ExpansionPanel: React.FC<AccordionCustomProps> = ({
  icon = 'chevronMedium',
  width,
  label,
  sizeIcon = '10px',
  children,
  ...rest
}: AccordionCustomProps) => {
  const classes = useStyles({ width });

  return (
    <Accordion
      {...rest}
      classes={omit(rest.classes, 'contentDetailsRoot')}
      className={`${classes.container} ${rest.className}`}
    >
      <AccordionSummary expandIcon={<Icon type={icon} size={sizeIcon} />}>{label}</AccordionSummary>
      <AccordionDetails classes={{ root: rest.classes?.contentDetailsRoot }}>{children}</AccordionDetails>
    </Accordion>
  );
};

export default ExpansionPanel;
