import SelectFormat from '../cell-format/select-format';
import InputFormat, { CellEditorInputFormat, CellEditorViewFormat } from '../cell-format/input-format';
import MultiSelectFormat from '../cell-format/multi-select-format';
import ColorPickerFormat from '../cell-format/color-picker-format';

const tractionConfig = {
  ['value.chartStyle']: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Line', value: 'line' },
        { label: 'Bar', value: 'bar' },
        { label: 'Area', value: 'area' },
      ],
    },
  },
  ['value.displayChart']: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  ['value.lineDash']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '10,5',
    },
  },
  ['value.lineTension']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '0.39',
    },
  },
  ['value.yAxisID']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '',
    },
  },
  ['value.order']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '',
    },
  },
  ['value.color']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '',
    },
  },
  ['value.customMetric']: {
    component: CellEditorInputFormat,
    params: {
      field: 'value',
      defaultValue: '',
    },
  },
};

export const SPECIAL_EDITOR = {
  isSecondaryMetric: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 'yes' },
        { label: 'no', value: 'no' },
      ],
    },
  },
  allowCohort: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  allowSparkline: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: true },
        { label: 'no', value: false },
      ],
    },
  },
  allowGroupby: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  requiredFilter: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  staticFilter: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  displayChart: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  chartStyle: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Line', value: 'line' },
        { label: 'Bar', value: 'bar' },
        { label: 'Area', value: 'area' },
      ],
    },
  },
  ['style.background']: {
    component: CellEditorViewFormat,
  },
  ['style.color']: {
    component: CellEditorViewFormat,
  },
  ['color']: {
    component: ColorPickerFormat,
    params: {
      field: 'value',
      allowCustom: true,
    },
  },
  period: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Hourly', value: 'hourly' },
        { label: 'Daily', value: 'daily' },
        { label: 'Weekly', value: 'weekly' },
        { label: 'Monthly', value: 'monthly' },
        { label: 'Quarterly', value: 'quarterly' },
        { label: 'Yearly', value: 'yearly' },
      ],
    },
  },
  edot: {
    component: MultiSelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Update cookie', value: 'update_cookie' },
        { label: 'Update comment', value: 'update_comment' },
        { label: 'Display history', value: 'display_history' },
        { label: 'On/off auto renew', value: 'auto_renew' },
        { label: 'On/off pull push', value: 'auto_pull_push' },
        { label: 'Change SC login credential', value: 'login_credential' },
      ],
    },
  },
  showAs: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Number', value: '="number"' },
        { label: 'Bar', value: '="bar"' },
      ],
    },
  },
  dividedBy: {
    component: CellEditorViewFormat,
  },
  width: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'Default', value: '="default"' },
        { label: 'Fit by content', value: '="fit"' },
      ],
    },
  },
  defaultWidth: {
    component: CellEditorViewFormat,
  },
  primaryKeys: {
    component: MultiSelectFormat,
    params: {
      field: 'value',
      options: [],
    },
  },
  hourlyIn1Day: {
    component: SelectFormat,
    params: {
      field: 'value',
      options: [
        { label: 'yes', value: 1 },
        { label: 'no', value: 0 },
      ],
    },
  },
  'label.tooltip': {},
  'status.tooltip': {},
  'subtext.tooltip': {},
  'hashtag.tooltip': {},
  ...tractionConfig,
};

export const SYSTEM_KEY = {
  'properties.status': {},
  'properties.state': {},
  'properties.singleId': {},
  'properties.lockWidth': {},
};
