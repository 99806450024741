import * as React from 'react';
import { get } from 'lodash';

import ScheduleDailyEditor from '@ep/insight-ui/elements/inline-edit/cell/budget/schedule-daily-editor';
import { toValue } from '@ep/insight-ui/sw/util/excel-formula';

const ScheduleDaily = (props) => {
  const rowData = get(props, ['data', 'node', 'data'], {});
  const popoverRef = React.useRef(null);

  let title = (get(props, ['payload'], []).find((i) => i['key'] == 'title') || { value: '' }).value || '';
  title = toValue(title, rowData, true);
  let type = (get(props, ['payload'], []).find((i) => i['key'] == 'field') || { value: '' }).value || '';
  type = toValue(type, rowData, true);
  let adsUnit = (get(props, ['payload'], []).find((i) => i['key'] == 'masterObjectType') || { value: '' }).value || '';
  adsUnit = toValue(adsUnit, rowData, true);
  let objectIdField =
    (get(props, ['payload'], []).find((i) => i['key'] == 'masterObjectId') || { value: '' }).value || '';
  objectIdField = toValue(objectIdField, rowData, true);

  const payload = {
    field: {
      adsUnit,
      type,
      objectIdField,
      namespace: 'campaign-management',
    },
  };

  return (
    <ScheduleDailyEditor
      popoverRef={popoverRef}
      title={title}
      payload={payload}
      data={get(props, ['data'], {})}
      anchorEl={props.anchorEl}
      setAnchorEl={(value) => {
        props.setAnchorEl(value);
        if (!value) {
          props.setShowThreeDots(false);
        }
      }}
      onViewHistoryOpen={() => props.setShowThreeDots(true)}
    />
  );
};

export default ScheduleDaily;
