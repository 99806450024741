import * as React from 'react';
import * as _ from 'lodash';
import { funcConfigs } from './hooks';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { IData, IOption } from '../type';
import { ScatterChart } from './scatter-template';
import { makeStyles } from '@material-ui/core';
import { responsiveChart } from '@ep/insight-ui/lib/epsilo-theme';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../chart-size';
import { breakPointSize } from '../chart-size';

interface IProps {
  width: number;
  height: number;
}

const useStyles = makeStyles({
  ratio_wrap: responsiveChart.ratio_wrap as CreateCSSProperties,
  ratio_item: responsiveChart.ratio_item as CreateCSSProperties,
  wrapper: {
    width: '100%',
    height: '100%',
  },
  chart: {
    width: '100%',
    height: '100%',
    flex: '1 0 0',
  },
  wrapperRes: {
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    '& .legendDiv': {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
});

type IScatterChart = {
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: IData;
  optionList: IOption;
  stateChart: IStateChartValue;
};
const ScatterChartResposive = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  stateChart,
}: IScatterChart) => {
  const divRef = React.useRef<HTMLDivElement>();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [chartSize, setchartSize] = React.useState<TSize>('large');

  const classes = useStyles();

  const ref = React.createRef();
  const idLegend = React.useMemo(() => `scatterChartLegend_${keyChart}`, [keyChart]);
  const { htmlLegendPlugin } = funcConfigs({
    percent: '15%',
    width,
    legendDivID: idLegend,
  });

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (optionList) {
        optionList.scales.xAxes.display = false;
        optionList.scales.yAxes.display = false;

        if (
          divRef.current.offsetWidth < breakPointSize.width.medium ||
          divRef.current.offsetHeight < breakPointSize.height.medium
        ) {
          optionList.elements.point.pointRadius = 4;
          optionList.elements.point.hoverBorderWidth = 8;
          optionList.elements.point.pointHoverRadius = 4;
          setchartSize('small');
          return;
        }
        if (
          divRef.current.offsetWidth < breakPointSize.width.large ||
          divRef.current.offsetHeight < breakPointSize.height.large
        ) {
          optionList.elements.point.pointRadius = 6;
          optionList.elements.point.hoverBorderWidth = 12;
          optionList.elements.point.pointHoverRadius = 6;
          setchartSize('medium');
          return;
        }

        optionList.scales.xAxes.display = true;
        optionList.scales.yAxes.display = true;
        optionList.elements.point.pointRadius = 8;
        optionList.elements.point.hoverBorderWidth = 16;
        optionList.elements.point.pointHoverRadius = 8;
        setchartSize('large');
      }
    });
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [ref]);

  return (
    <div ref={divRef} className={classes.wrapper} style={{ display: 'flex', flexDirection: 'column' }}>
      <ChartSize size={chartSize}>
        <ChartState stateChart={stateChart} title={title} value={value} currency={currency} percent={percent}>
          <div className={classes.wrapperRes}>
            <div className={classes.chart}>
              {dataList && (
                <ScatterChart data={dataList} options={optionList} plugins={[htmlLegendPlugin]} refElement={ref} />
              )}
            </div>
            <div className={classes.wrapperLegendLine} style={{ display: chartSize === 'large' ? 'flex' : 'none' }}>
              <div className={classes.legendDiv} id={idLegend}></div>
            </div>
          </div>
        </ChartState>
      </ChartSize>
    </div>
  );
};

export default ScatterChartResposive;
