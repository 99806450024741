import * as React from 'react';
import { nanoid } from 'nanoid';

import { Box, Button, TextField, Typography, makeStyles } from '@material-ui/core';

import { PageEditContext } from '@ep/insight-ui/system/page/context/page-edit';
import { aim } from '@eip/next/lib/main';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
    marginBottom: '36px',
  },
  input: {
    '& .eip1-MuiOutlinedInput-input': {
      padding: '6px 8px',
    },
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
  },
});

const EpsiloTemplateConfig = ({ nodeId, config, chartId }: { nodeId?: string; config?: any; chartId?: string }) => {
  const classes = useStyles();
  const [label, setLabel] = React.useState('');

  const pageEdit = React.useContext(PageEditContext);

  React.useEffect(() => {
    const template = pageEdit.getTemplate(nodeId);
    if (template) {
      setLabel(template.label);
    }
  }, []);

  if (!aim.isSuperAdmin()) return null;

  return (
    <Box className={classes.container}>
      <Box className={classes.input}>
        <Typography variant="h6">Template label</Typography>
        <TextField
          placeholder="Placehoder"
          onChange={(e) => setLabel(e.target.value)}
          variant="outlined"
          value={label}
        />
      </Box>
      <Button
        variant="contained"
        color="secondary"
        onClick={() =>
          pageEdit.saveTemplate({
            nodeId: nanoid(),
            rootNodeId: nodeId,
            label,
            config,
            chartId,
          })
        }
        disabled={!nodeId || !config || !chartId}
      >
        Save template
      </Button>
    </Box>
  );
};

export default EpsiloTemplateConfig;
