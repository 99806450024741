import { get, uniq, cloneDeep } from 'lodash';
import * as uuid from 'uuid';

import { request, requestEtableConfig } from '@ep/insight-ui/system/backbone/data-source/common';
import { EIP_CONSTANT, aim } from '@eip/next/lib/main';
import { options as BudgetOptions } from '@ep/insight-ui/elements/inline-edit/cell/budget/budget-selection-editor/budget-selection-editor';

import {
  INPUT_TYPE_OPTIONS_KEY,
  INPUT_TYPE_SELECTION_OPTIONS_KEY,
  SYSTEM_FIELD_DEFINED,
  INPUT_TYPE_CONFIG_TYPE_API_MAPPING,
  INPUT_TYPE_CONFIG_TYPE_API_MAPPING_WITH_BULK,
  INPUT_TYPE_CONFIG_TYPE_CONSTANT,
  INPUT_TYPE_CONFIG_TYPE_FORMULA,
  INPUT_TYPE_REQUEST_OPTIONS,
  INPUT_TYPE_SELECT_FROM_ETABLE,
  SELECT_COMPONENT_NAME,
  LABEL_SELECT_COMPONENT_NAME,
  TAG_EDITOR_COMPONENT_NAME,
  INPUT_TYPE_TAG_TYPE,
  INPUT_TYPE_PLACEHOLDER,
  INPUT_TYPE_HELPER_TEXT,
  INPUT_TYPE_ETABLE_SELECTED,
  INPUT_TYPE_FIELD_ID,
  INPUT_TYPE_FIELD_LABEL,
  INPUT_TYPE_DISPLAY_AS,
  INPUT_TYPE_DISPLAY_AS_SELECTION,
  INPUT_TYPE_DISPLAY_AS_COMPACT,
  INPUT_TYPE_FILTER_FIELD,
  INPUT_TYPE_ETABLE_CONFIG,
  DEFAULT_CONFIG_ETABLE_SELECTION,
  INIT_VALUE_TYPE_FROM_ETABLE,
  INIT_VALUE_TYPE_STATIC,
  CALENDAR_COMPONENT_NAME,
  ACTION_CATEGORY_FILTER,
  ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
  ACTION_CATEGORY_INLINE_FORM,
  ACTION_CATEGORY_IMPACT_TABLE,
  BUDGET_EDITOR_COMPONENT_NAME,
  BIDDING_EDITOR_COMPONENT_NAME,
  ACTION_CATEGORY_STATUS,
  ACTION_CATEGORY_TAG,
  CUSTOM_TAG,
  ACTION_CATEGORY_PERIOD,
  ACTION_CATEGORY_COMPACT_TABLE_VIEW,
  INPUT_TYPE_VIEW,
  INPUT_TYPE_COMPACT_LINKED_FIELD,
  INPUT_TYPE_COMPACT_SOURCE_FIELD,
  INPUT_TYPE_COMPACT_TARGET_FIELD,
  INPUT_TYPE_SUPPORT_FORMULA,
  TEXTAREA_COMPONENT_NAME,
  ACTION_CATEGORY_COPY_PASTE,
  INIT_VALUE_TYPE_CLIPBOARD,
  ACTION_CATEGORY_CONDITON_FORMAT,
  INPUT_TYPE_COMPACT_SOURCE_INHERIT_FILTER as INPUT_TYPE_COMPACT_TARGET_INHERIT_FILTER,
  INPUT_TYPE_DATA_TYPE,
  ACTION_CATEGORY_SEPARATOR,
  INPUT_TYPE_SUPPORT_JSON,
  TEXT_INPUT_COMPONENT_NAME,
  ACTION_CATEGORY_JSON_FORM,
  INPUT_TYPE_SYSTEM_FILTERS,
  ACTION_CATEGORY_CODE_IDE,
  ACTION_CATEGORY_CODE_EXECUTION,
  ACTION_CATEGORY_SCRIPT_ACTION,
  MULTIPLE_SELECT_COMPONENT_NAME,
  ACTION_CATEGORY_ETABLE_FORM,
  INPUT_TYPE_MULTIPLE_SELECT,
  INIT_VALUE_TYPE_FROM_COMPACT_ETABLE,
  INPUT_TYPE_COMPACT_SPLIT_DATA,
  INPUT_TYPE_FORMAT_INPUT,
  INPUT_TYPE_COMPACT_OPERATOR,
  INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
  INPUT_TYPE_CUSTOM_TAG,
  INPUT_TYPE_SELECT_FROM_TAG,
  INPUT_TYPE_SUBMIT_AFTER_CLICK,
  INPUT_TYPE_SUBMIT_METHOD,
  INPUT_TYPE_REGEX,
  INPUT_TYPE_TOOLTIP,
  ACTION_CATEGORY_SCHEDULE_DAILY,
  INPUT_TYPE_USE_QUERY_PAYLOAD,
  ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE,
  INPUT_TYPE_MAX_ITEM_SELECTED,
  INPUT_TYPE_MAX_LINES,
  INPUT_TYPE_SEARCH_HINT,
  INPUT_TYPE_ADDITIONAL_DATA,
  ACTION_CATEGORY_INTEGRATE_STOREFRONT,
  INPUT_TYPE_LINK_GROUPBY_FILTER,
  FILTER_COMPONENT_NAME,
  INPUT_TYPE_CUSTOM_FILTERS,
} from './constant';

import SelectFormat from '../cell-format/select-format';
import CalculateCellFormat from '../cell-format/calculate-cell-format';
import CompactFormat from '../cell-format/compact-format';
import { CellEditorInputFormat, CellEditorViewFormat } from '../cell-format/input-format';
import CheckboxFormat from '../cell-format/checkbox-format';
import { fields } from '../action-components/inline-edit/form-field';
import { defaultInlineEditData } from '../action-components/inline-edit/data/default-data';
import MultiSelectFormat from '../cell-format/multi-select-format';
import EmptyFormat from '../cell-format/empty-format';
import MultipleSelect from '../popover-components/multiple-select';
import ResyncConfigFormat from '../cell-format/resync-config-format';
import SelectWindowFormat from '../cell-format/select-window-format';
import { isFormulaField } from '@ep/insight-ui/sw/util/excel-formula';
import { advanceConfigurationForm } from './action-advance-configs/period';
import ScriptEditorFormat from '../cell-format/script-editor-format';
import { withWrapperFormat } from '../cell-format/wrapper-format';

const defaultPeriodConfig = [
  { label: 'Last year', value: 'last_year', shortcut: 'LY' },
  { label: 'Year to date', value: 'year_to_date', shortcut: 'YTD' },
  { label: 'The previous', value: 'the_previous', shortcut: 'previous' },
  { label: 'Custom', value: 'custom', shortcut: 'custom' },
];

const defaultRequestOptionsParams = [
  {
    key: 'payload',
    value: [
      {
        key: 'label',
        type: 'data_mapping',
        value: '',
      },
      {
        key: 'value',
        type: 'data_mapping',
        value: '',
      },
    ],
  },
  {
    key: 'endpoint',
    value: '',
  },
];

const HIDDEN_FIELD = 'hidden';

const operators = [
  { label: 'Is', value: 'is' },
  { label: 'Is not', value: 'is_not' },
  { label: 'Is empty', value: 'is_empty' },
  { label: 'Is not empty', value: 'is_not_empty' },
  { label: 'Contain', value: 'contains' },
  { label: 'Does not contain', value: 'does_not_contain' },
  { label: 'Starts with', value: 'begins_with' },
  { label: 'Ends with', value: 'ends_with' },
  { label: 'Does not start with', value: 'does_not_start_with' },
  { label: 'Does not end with', value: 'does_not_end_with' },
  { label: 'Is before', value: 'is_before' },
  { label: 'Is after', value: 'is_after' },
  { label: 'Is on or before', value: 'is_on_or_before' },
  { label: 'Is on or after', value: 'is_on_or_after' },
  { label: 'Is within', value: 'is_within' },
  { label: 'Any of', value: 'IN' },
  { label: 'Not in', value: 'NOT_IN' },
  { label: 'Match', value: 'match' },
  { label: '=', value: '=' },
  { label: '≠', value: '≠' },
  { label: '>', value: '>' },
  { label: '≥', value: '≥' },
  { label: '<', value: '<' },
  { label: '≤', value: '≤' },
];

const getActions = () => {
  return request.get(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv/action-template', {}, true);
};

const updateActions = (actions) => {
  return request.post(EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/xagolibo42/kv', {
    k: 'action-template',
    v: actions,
    version: Date.now(),
  });
};

const categoryOptions = [
  {
    label: 'Filter by',
    value: ACTION_CATEGORY_FILTER,
  },
  {
    label: 'Internal link with params',
    value: ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
  },
  {
    label: 'Inline form',
    value: ACTION_CATEGORY_INLINE_FORM,
  },
  {
    label: 'ETable form',
    value: ACTION_CATEGORY_ETABLE_FORM,
  },
  {
    label: 'JSON form',
    value: ACTION_CATEGORY_JSON_FORM,
  },
  {
    label: 'Impact table',
    value: ACTION_CATEGORY_IMPACT_TABLE,
  },
  {
    label: 'Status',
    value: ACTION_CATEGORY_STATUS,
  },
  {
    label: 'Tag',
    value: ACTION_CATEGORY_TAG,
  },
  {
    label: 'Period',
    value: ACTION_CATEGORY_PERIOD,
  },
  {
    label: 'Schedule daily',
    value: ACTION_CATEGORY_SCHEDULE_DAILY,
  },
  {
    label: 'Compact table view',
    value: ACTION_CATEGORY_COMPACT_TABLE_VIEW,
  },
  {
    label: 'Copy & paste',
    value: ACTION_CATEGORY_COPY_PASTE,
  },
  {
    label: 'Condition Format',
    value: ACTION_CATEGORY_CONDITON_FORMAT,
  },
  {
    label: 'Script action',
    value: ACTION_CATEGORY_SCRIPT_ACTION,
  },
  {
    label: 'Script editor',
    value: ACTION_CATEGORY_CODE_IDE,
  },
  {
    label: 'Script execution',
    value: ACTION_CATEGORY_CODE_EXECUTION,
  },
  {
    label: 'Separator',
    value: ACTION_CATEGORY_SEPARATOR,
  },
  {
    label: 'Switch placement type',
    value: ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE,
  },
  {
    label: 'Storefront integration',
    value: ACTION_CATEGORY_INTEGRATE_STOREFRONT,
  },
];

const actionButtons = [
  {
    title: 'Select action(s)',
    id: 'select_action',
    popoverComponent: MultipleSelect,
    params: {
      requestOptions() {
        return new Promise((resolve) => {
          getActions()
            .then((res) => {
              if (res.success) {
                const result = Object.keys(res.data.v).map((el) => {
                  return {
                    label: res.data.v[el].name,
                    value: el,
                  };
                });
                resolve(result);
              }
              resolve([]);
            })
            .catch(() => resolve([]));
        });
      },
      handleSubmit(selectedItems, gridApi, callback) {
        getActions().then((res) => {
          if (res.success) {
            const actions = { ...res.data.v };
            const rowData = [];
            selectedItems.forEach((el) => {
              rowData.push(actions[el]);
            });
            gridApi.applyTransaction({
              add: rowData,
            });
          }
          if (callback) {
            callback();
          }
        });
      },
      handleDelete(selectedItems, _, setOptions) {
        if (aim.isSuperAdmin()) {
          getActions().then((res) => {
            if (res.success) {
              const actions = { ...res.data.v };
              selectedItems.forEach((el) => {
                delete actions[el];
              });
              updateActions(actions).then(() => {
                const newOptions = Object.keys(actions).map((el) => {
                  return {
                    label: actions[el].name,
                    value: el,
                  };
                });
                setOptions(newOptions);
              });
            }
          });
        } else {
          alert('You do not have a permission to perform this action.');
        }
      },
    },
  },
  {
    title: 'Save action(s)',
    id: 'save_action',
    async onClick({ gridApi, event }) {
      if (aim.isSuperAdmin()) {
        const btnElement = event.currentTarget;
        if (!btnElement.hasAttribute('disabled')) {
          btnElement.setAttribute('disabled', '');

          const response = await getActions();

          if (response.success) {
            const actions = response.data.v || {};
            const selectedRows = gridApi.getSelectedRows();
            selectedRows.forEach((el) => {
              actions[uuid.v4()] = el;
            });

            await updateActions(actions);
          }
          btnElement.removeAttribute('disabled');
        }
      }
    },
  },
  {
    title: 'Clone action(s)',
    id: 'clone_action',
    onClick({ gridApi, event }) {
      gridApi.applyTransaction({
        add: gridApi.getSelectedRows().map((row) => cloneDeep(row)),
      });
    },
  },
];

const getApiMappingOptions = (etableConfigContext) => {
  return uniq(
    Object.values<any>(get(etableConfigContext, 'mapping', {})).reduce(
      (a, b) => {
        return [...a, ...(Object.values(b.valueGetter || {}).filter((el) => !!el && !isFormulaField(el)) || [])];
      },
      ['currency'],
    ),
  );
};

export const getActionColDef = (etableConfigContext, etableList, roles = []) => ({
  field: 'actions',
  headerName: 'Actions',
  editable: true,
  cellEditor: withWrapperFormat(CompactFormat),
  cellRenderer: withWrapperFormat(CompactFormat),
  cellRendererParams: {
    config: {
      sourceType: 'table-actions',
      colDefs: [
        {
          field: 'category',
          headerName: 'Category',
          maxWidth: 100,
          cellRenderer: SelectFormat,
          cellRendererParams: {
            options: categoryOptions,
            hasSearch: true,
            field: 'category',
          },
          onCellValueChanged(params) {
            let defaultConfig = [];
            if (params.newValue === ACTION_CATEGORY_IMPACT_TABLE) {
              defaultConfig = [
                {
                  key: 'dimension_type',
                  value: '',
                },
                {
                  key: 'field_name',
                  value: '',
                },
              ];
            }
            if ([ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS, ACTION_CATEGORY_JSON_FORM].includes(params.newValue)) {
              defaultConfig = [
                {
                  key: 'url',
                  value: '',
                },
              ];
            }
            if (ACTION_CATEGORY_SCHEDULE_DAILY == params.newValue) {
              defaultConfig = [
                {
                  key: 'title',
                  value: '',
                },
                {
                  key: 'field',
                  value: '',
                },
                {
                  key: 'masterObjectId',
                  value: '',
                },
                {
                  key: 'masterObjectType',
                  value: '',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_CONDITON_FORMAT) {
              defaultConfig = [
                {
                  key: 'title',
                  value: '',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_INLINE_FORM) {
              defaultConfig = [...defaultInlineEditData];
            }
            if (params.newValue === ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE) {
              defaultConfig = [
                {
                  key: 'parent_id',
                  value: '=p("ads_objects.id")',
                },
                {
                  key: 'storefront_id',
                  value: '=p("storefront.id")',
                },
                {
                  key: 'tool_code',
                  value: '=p("ads_tool.code")',
                },
                {
                  key: 'marketplace_code',
                  value: '=p("marketplace.code")',
                },
                {
                  key: 'country_code',
                  value: '=p("country.code")',
                },
                {
                  key: 'type',
                  value: '="Discovery"',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_INTEGRATE_STOREFRONT) {
              defaultConfig = [
                {
                  key: INPUT_TYPE_ETABLE_SELECTED,
                  value: '',
                },
                {
                  key: INPUT_TYPE_ETABLE_CONFIG,
                  value: '',
                },
                {
                  key: 'endpoint',
                  value: 'https://hyper-integration.epsilo.io/integration_new_store',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_ETABLE_FORM) {
              defaultConfig = [
                ...defaultInlineEditData,
                {
                  field_key: 'etableConfig',
                  label: 'ETable Configuration',
                  input_type: SYSTEM_FIELD_DEFINED,
                  init_value_value: '',
                  field_configuration: [
                    {
                      key: INPUT_TYPE_ETABLE_SELECTED,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_ETABLE_CONFIG,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_FILTER_FIELD,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_SYSTEM_FILTERS,
                      value: [],
                    },
                    {
                      key: INPUT_TYPE_VIEW,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_MULTIPLE_SELECT,
                      value: true,
                    },
                    {
                      key: INPUT_TYPE_COMPACT_SPLIT_DATA,
                      value: false,
                    },
                  ],
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_COPY_PASTE) {
              defaultConfig = [
                {
                  field_key: 'form',
                  init_value_type: SYSTEM_FIELD_DEFINED,
                  init_value_value: '',
                  field_configuration: [
                    {
                      key: 'submit_endpoint',
                      value: '',
                    },
                  ],
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_TAG) {
              defaultConfig = [
                ...defaultInlineEditData,
                {
                  field_key: 'tagConfig',
                  label: 'Tag Configuration',
                  input_type: SYSTEM_FIELD_DEFINED,
                  init_value_value: '',
                  field_configuration: [
                    {
                      key: INPUT_TYPE_CUSTOM_TAG,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_ETABLE_SELECTED,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_ETABLE_CONFIG,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_FILTER_FIELD,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_SYSTEM_FILTERS,
                      value: [],
                    },
                    {
                      key: INPUT_TYPE_VIEW,
                      value: '',
                    },
                    {
                      key: INPUT_TYPE_MULTIPLE_SELECT,
                      value: true,
                    },
                    {
                      key: INPUT_TYPE_COMPACT_SPLIT_DATA,
                      value: false,
                    },
                  ],
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_STATUS) {
              defaultConfig = [
                {
                  key: 'status_field',
                  value: '',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_PERIOD) {
              defaultConfig = [...defaultPeriodConfig];
            }
            if (params.newValue === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
              defaultConfig = [
                {
                  key: INPUT_TYPE_ETABLE_SELECTED,
                  value: '',
                },
                {
                  key: INPUT_TYPE_ETABLE_CONFIG,
                  value: '',
                },
                {
                  key: INPUT_TYPE_DISPLAY_AS,
                  value: 'etable',
                },
                {
                  key: INPUT_TYPE_FILTER_FIELD,
                  value: '',
                },
                {
                  key: INPUT_TYPE_SYSTEM_FILTERS,
                  value: [],
                },
                {
                  key: INPUT_TYPE_VIEW,
                  value: '',
                },
              ];
            }
            if (params.newValue === ACTION_CATEGORY_SCRIPT_ACTION) {
              defaultConfig = [
                {
                  key: 'script',
                  value: '',
                },
              ];
            }
            params.node.setData({
              ...params.data,
              prevConfiguration: {
                ...get(params, ['data', 'prevConfiguration'], {}),
                [params.oldValue]: params.data.configuration,
              },
              configuration: get(params, ['data', 'prevConfiguration', params.newValue], defaultConfig),
            });
            params.api.redrawRows({
              rowNodes: [params.node],
            });
          },
          editable: true,
        },
        {
          field: 'name',
          headerName: 'Name',
          width: 200,
          cellRendererSelector(params) {
            if (params.data.category === ACTION_CATEGORY_STATUS) {
              return {
                component: EmptyFormat,
              };
            }
            return {
              component: CellEditorViewFormat,
            };
          },
          cellEditor: CellEditorInputFormat,
          editable: true,
        },
        {
          field: 'icon',
          headerName: 'Icon',
          maxWidth: 100,
          cellRendererSelector(params) {
            if (params.data.category === ACTION_CATEGORY_STATUS) {
              return {
                component: EmptyFormat,
              };
            }
            return {
              component: SelectWindowFormat,
              params: {
                options: [],
                hasSearch: true,
                field: 'icon',
                allowCustomOption: true,
                allowedRemove: true,
                isIcon: true,
              },
            };
          },
          editable: true,
        },
        {
          field: 'disable',
          headerName: 'Disable',
          maxWidth: 150,
          cellRenderer: SelectFormat,
          cellRendererParams: {
            options: [{ label: 'Disabled', value: '1' }],
            hasSearch: true,
            allowCustomOption: true,
            field: 'disable',
          },
          editable: true,
        },
        {
          field: 'permission',
          headerName: 'Visible',
          maxWidth: 150,
          cellRenderer: SelectFormat,
          cellRendererParams: {
            options: [
              { label: 'Public', value: 'allow' },
              { label: 'Admin', value: 'admin' },
              { label: 'One', value: 'one' },
            ],
            hasSearch: true,
            allowCustomOption: true,
            field: 'permission',
          },
          editable: true,
        },
        {
          field: 'configuration',
          headerName: 'Configuration',
          cellRendererSelector(params) {
            if (params.data?.category === ACTION_CATEGORY_FILTER) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                },
              };
            }
            if (params.data?.category === ACTION_CATEGORY_IMPACT_TABLE) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(params) {
                          if (params.data?.key === 'field_name') {
                            const options = ['daily_budget', 'total_budget', 'match_type', 'bidding_price', 'status'];
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: options,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                              },
                            };
                          }
                          if (params.data?.key === 'dimension_type') {
                            const options = ['ADS_CAMPAIGN', 'ADS_OBJECT', 'ADS_PLACEMENT'];
                            return {
                              component: SelectFormat,
                              params: {
                                options: options,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          return {
                            component: CellEditorViewFormat,
                          };
                        },
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                    ],
                    hideDefaultActions: true,
                  },
                },
              };
            }
            if (ACTION_CATEGORY_INTEGRATE_STOREFRONT == params.data?.category) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 200,
                        cellRenderer: SelectFormat,
                        cellRendererParams: {
                          options: [
                            {
                              label: 'eTable select',
                              value: INPUT_TYPE_ETABLE_SELECTED,
                            },
                            {
                              label: 'eTable config',
                              value: INPUT_TYPE_ETABLE_CONFIG,
                            },
                            {
                              label: 'Endpoint',
                              value: 'endpoint',
                            },
                          ],
                          hasSearch: true,
                          field: 'key',
                          allowCustomOption: true,
                          allowedRemove: true,
                        },
                        editable: true,
                        onCellValueChanged(params) {
                          params.api.redrawRows({
                            rowNodes: [params.node],
                          });
                        },
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(valueParams) {
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: etableList,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                            return {
                              component: ResyncConfigFormat,
                            };
                          }
                          const etableConfig = (
                            params.value.find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
                          ).value;

                          if (valueParams.data.key === INPUT_TYPE_VIEW) {
                            const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }));

                            return {
                              component: SelectFormat,
                              params: {
                                options: viewsOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map((i) => ({
                            value: i,
                            label: i,
                          }));
                          if (valueParams.data.key === INPUT_TYPE_COMPACT_SOURCE_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: sourceFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const targetFieldViewOptions = getApiMappingOptions(etableConfig).map((i) => ({
                            value: i,
                            label: i,
                          }));
                          if (valueParams.data.key === INPUT_TYPE_COMPACT_TARGET_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: targetFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce((a, b) => {
                            const valueGetter = Object.values(get(b, 'valueGetter', {}));
                            return [...a, ...valueGetter];
                          }, []);
                          mapping.sort();
                          const uniqMapping = uniq(mapping);
                          const filteredMapping = uniqMapping.filter((el) => !!el);
                          return {
                            component: SelectFormat,
                            params: {
                              options: filteredMapping,
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                            },
                          };
                        },
                        onCellValueChanged(params) {
                          if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            const blockEid = params.data.value;
                            requestEtableConfig(blockEid).then((res) => {
                              const eTableConfigNode = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                              );
                              if (eTableConfigNode) {
                                if (res.success) {
                                  eTableConfigNode.setDataValue('value', res.data);
                                } else if (!eTableConfigNode.data?.blockEid) {
                                  eTableConfigNode.setDataValue('value', {
                                    ...DEFAULT_CONFIG_ETABLE_SELECTION,
                                    blockEid,
                                  });
                                }
                              }
                              const eTableConfigView = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_VIEW,
                              );
                              if (eTableConfigView) {
                                if (res.success) {
                                  eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                                }
                              }
                            });
                          }
                          const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                            return (
                              params.data.key === INPUT_TYPE_ETABLE_SELECTED || data?.key != INPUT_TYPE_ETABLE_CONFIG
                            );
                          });
                          params.api.redrawRows({
                            rowNodes: redrawRows,
                          });
                        },
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        key: '',
                        value: '',
                      };
                    },
                    addNewFromIndex: 9999999,
                  },
                },
              };
            }
            if (
              [
                ACTION_CATEGORY_INTERNAL_LINK_WITH_PARAMS,
                ACTION_CATEGORY_JSON_FORM,
                ACTION_CATEGORY_SWITCH_PLACEMENT_TYPE,
                ACTION_CATEGORY_INTEGRATE_STOREFRONT,
              ].includes(params.data?.category)
            ) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(params) {
                          if (params.data?.key === 'url') {
                            return {
                              component: CellEditorViewFormat,
                            };
                          }
                          return {
                            component: SelectFormat,
                            params: {
                              options: getApiMappingOptions(etableConfigContext),
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                            },
                          };
                        },
                        onCellValueChanged(params) {
                          if (params.data?.key === 'url') {
                            const dynamicParams = uniq(params.newValue.match(/\:\w+/g) || []);
                            const addKeys = dynamicParams.filter((el) => {
                              return params.node.parent.allLeafChildren.every((ele) => ele.data?.key !== el.slice(1));
                            });
                            const removeRows = params.node.parent.allLeafChildren
                              .filter((el) => {
                                return (
                                  dynamicParams.every((ele) => ele.slice(1) !== el.data?.key) && el.data?.key !== 'url'
                                );
                              })
                              .map((el) => el.data);

                            params.api.applyTransaction({
                              add: addKeys.map((el) => ({
                                key: el.slice(1),
                                value: '',
                              })),
                              remove: removeRows,
                            });
                          }
                        },
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        key: '',
                        value: '',
                      };
                    },
                    addNewFromIndex: 9999999,
                    hideDefaultActions: true,
                  },
                },
              };
            }
            if (ACTION_CATEGORY_SCHEDULE_DAILY == params.data?.category) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(params) {
                          return {
                            component: SelectFormat,
                            params: {
                              options: getApiMappingOptions(etableConfigContext),
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                            },
                          };
                        },
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        key: '',
                        value: '',
                      };
                    },
                    addNewFromIndex: 9999999,
                    hideDefaultActions: true,
                  },
                },
              };
            }
            if ([ACTION_CATEGORY_CONDITON_FORMAT, ACTION_CATEGORY_SCRIPT_ACTION].includes(params.data?.category)) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(params) {
                          return {
                            component: CellEditorViewFormat,
                          };
                        },
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        key: '',
                        value: '',
                      };
                    },
                    addNewFromIndex: 9999999,
                    hideDefaultActions: true,
                  },
                },
              };
            }
            if (params.data?.category === ACTION_CATEGORY_STATUS) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRenderer: SelectFormat,
                        cellRendererParams: {
                          options: getApiMappingOptions(etableConfigContext),
                          hasSearch: true,
                          field: 'value',
                          allowCustomOption: true,
                          allowedRemove: true,
                        },
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        key: '',
                        value: '',
                      };
                    },
                    hideDefaultActions: true,
                  },
                },
              };
            }
            if (params.data?.category === ACTION_CATEGORY_PERIOD) {
              return advanceConfigurationForm(params);
            }
            if (params.data?.category === ACTION_CATEGORY_COMPACT_TABLE_VIEW) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'key',
                        headerName: 'Config item',
                        width: 200,
                        cellRenderer: SelectFormat,
                        cellRendererParams: {
                          options: [
                            {
                              label: 'eTable select',
                              value: INPUT_TYPE_ETABLE_SELECTED,
                            },
                            {
                              label: 'eTable config',
                              value: INPUT_TYPE_ETABLE_CONFIG,
                            },
                            {
                              label: 'Display as',
                              value: INPUT_TYPE_DISPLAY_AS,
                            },
                            {
                              label: 'View',
                              value: INPUT_TYPE_VIEW,
                            },
                            {
                              label: 'System filters',
                              value: INPUT_TYPE_SYSTEM_FILTERS,
                            },
                            {
                              label: 'Ignore calendar',
                              value: INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                            },
                            {
                              label: 'Source fields',
                              value: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                            },
                            {
                              label: 'Target fields',
                              value: INPUT_TYPE_COMPACT_TARGET_FIELD,
                            },
                            {
                              label: 'Inherit filter from source',
                              value: INPUT_TYPE_COMPACT_TARGET_INHERIT_FILTER,
                              description:
                                'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                            },
                            {
                              label: 'Filter field',
                              value: INPUT_TYPE_FILTER_FIELD,
                              description:
                                'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                            },
                            {
                              label: 'Custom Tag',
                              value: INPUT_TYPE_CUSTOM_TAG,
                            },
                            {
                              label: 'Search hint',
                              value: INPUT_TYPE_SEARCH_HINT,
                            },
                            {
                              label: 'Additional data',
                              value: INPUT_TYPE_ADDITIONAL_DATA,
                            },
                            {
                              label: 'Link group by filter',
                              value: INPUT_TYPE_LINK_GROUPBY_FILTER,
                            },
                            {
                              label: 'Custom filters',
                              value: INPUT_TYPE_CUSTOM_FILTERS,
                            },
                          ],
                          hasSearch: true,
                          field: 'key',
                          allowCustomOption: true,
                          allowedRemove: true,
                        },
                        editable: true,
                        onCellValueChanged(params) {
                          params.api.redrawRows({
                            rowNodes: [params.node],
                          });
                        },
                      },
                      {
                        field: 'value',
                        headerName: 'Value',
                        width: 100,
                        cellRendererSelector(valueParams) {
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: etableList,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                            return {
                              component: ResyncConfigFormat,
                            };
                          }
                          const etableConfig = (
                            params.value.find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
                          ).value;

                          if (valueParams.data.key === INPUT_TYPE_VIEW) {
                            const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                              label: name,
                              value: id,
                            }));

                            return {
                              component: SelectFormat,
                              params: {
                                options: viewsOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_DISPLAY_AS) {
                            const chartConfig = get(etableConfig, ['chartConfig', 'config'], null);
                            const isGalleryVisualization = get(etableConfig, ['visualizationType'], null) == 'category';
                            const viewsOptions = [
                              {
                                label: 'Etable',
                                value: 'etable',
                              },
                              ...(!!chartConfig
                                ? [
                                    {
                                      label: 'Chart',
                                      value: 'chart',
                                    },
                                  ]
                                : []),
                              ...(isGalleryVisualization ? [{ label: 'Gallery', value: 'category' }] : []),
                            ];

                            return {
                              component: SelectFormat,
                              params: {
                                options: viewsOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map((i) => ({
                            value: i,
                            label: i,
                          }));

                          if (valueParams.data.key == INPUT_TYPE_CUSTOM_FILTERS) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: sourceFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_COMPACT_SOURCE_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: sourceFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          const targetFieldViewOptions = getApiMappingOptions(etableConfig).map((i) => ({
                            value: i,
                            label: i,
                          }));
                          if (valueParams.data.key === INPUT_TYPE_COMPACT_TARGET_FIELD) {
                            return {
                              component: MultiSelectFormat,
                              params: {
                                options: targetFieldViewOptions,
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_SYSTEM_FILTERS) {
                            return {
                              component: CompactFormat,
                              params: {
                                // isConfig: true,
                                isCustom: true,
                                config: {
                                  colDefs: [
                                    {
                                      field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                      headerName: 'Target field',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: targetFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                      headerName: 'Source field',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: sourceFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: INPUT_TYPE_COMPACT_OPERATOR,
                                      headerName: 'Operator',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: operators,
                                        hasSearch: true,
                                        allowCustomOption: false,
                                        field: INPUT_TYPE_COMPACT_OPERATOR,
                                      },
                                      editable: true,
                                    },
                                  ],
                                  getDefaultData() {
                                    return [];
                                  },
                                  getDefaultRowData() {
                                    return {
                                      [INPUT_TYPE_COMPACT_TARGET_FIELD]: '',
                                      [INPUT_TYPE_COMPACT_SOURCE_FIELD]: '',
                                      [INPUT_TYPE_COMPACT_OPERATOR]: 'is',
                                    };
                                  },
                                  addNewFromIndex: 9999999,
                                },
                                allowDragRow: true,
                                addRowLabel: 'Add input',
                              },
                            };
                          }

                          if (valueParams.data.key === INPUT_TYPE_ADDITIONAL_DATA) {
                            return {
                              component: CompactFormat,
                              params: {
                                // isConfig: true,
                                isCustom: true,
                                config: {
                                  colDefs: [
                                    {
                                      field: 'key',
                                      headerName: 'Key',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: targetFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: 'key',
                                      },
                                      editable: true,
                                    },
                                    {
                                      field: 'value',
                                      headerName: 'Value',
                                      width: 200,
                                      cellRenderer: SelectFormat,
                                      cellRendererParams: {
                                        options: sourceFieldViewOptions,
                                        hasSearch: true,
                                        allowCustomOption: true,
                                        field: 'value',
                                      },
                                      editable: true,
                                    },
                                  ],
                                  getDefaultData() {
                                    return [];
                                  },
                                  getDefaultRowData() {
                                    return {
                                      key: '',
                                      value: '',
                                    };
                                  },
                                  addNewFromIndex: 9999999,
                                },
                                allowDragRow: true,
                                addRowLabel: 'Add input',
                              },
                            };
                          }

                          const yesNoOptionsKeys = [INPUT_TYPE_LINK_GROUPBY_FILTER, INPUT_TYPE_COMPACT_IGNORE_CALENDAR];
                          if (yesNoOptionsKeys.includes(valueParams.data.key)) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: [
                                  {
                                    label: 'Yes',
                                    value: true,
                                  },
                                  {
                                    label: 'No',
                                    value: false,
                                  },
                                ],
                                hasSearch: true,
                                field: 'value',
                                allowCustomOption: true,
                                allowedRemove: true,
                              },
                            };
                          }
                          const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce((a, b) => {
                            const valueGetter = Object.values(get(b, 'valueGetter', {}));
                            return [...a, ...valueGetter];
                          }, []);
                          mapping.sort();
                          const uniqMapping = uniq(mapping);
                          const filteredMapping = uniqMapping.filter((el) => !!el);
                          return {
                            component: SelectFormat,
                            params: {
                              options: filteredMapping,
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                            },
                          };
                        },
                        onCellValueChanged(params) {
                          if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                            const blockEid = params.data.value;
                            requestEtableConfig(blockEid).then((res) => {
                              const eTableConfigNode = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                              );
                              if (eTableConfigNode) {
                                if (res.success) {
                                  eTableConfigNode.setDataValue('value', res.data);
                                } else if (!eTableConfigNode.data?.blockEid) {
                                  eTableConfigNode.setDataValue('value', {
                                    ...DEFAULT_CONFIG_ETABLE_SELECTION,
                                    blockEid,
                                  });
                                }
                              }
                              const eTableConfigView = params.node.parent.allLeafChildren.find(
                                (el) => el.data.key === INPUT_TYPE_VIEW,
                              );
                              if (eTableConfigView) {
                                if (res.success) {
                                  eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                                }
                              }
                            });
                          }
                          const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                            return (
                              params.data.key === INPUT_TYPE_ETABLE_SELECTED || data?.key != INPUT_TYPE_ETABLE_CONFIG
                            );
                          });
                          params.api.redrawRows({
                            rowNodes: redrawRows,
                          });
                        },
                        editable: true,
                      },
                    ],
                    getDefaultRowData() {
                      return {
                        label: '',
                        value: '',
                        shortcut: '',
                      };
                    },
                  },
                  addRowLabel: 'Add config',
                },
              };
            }
            if (params.data?.category === ACTION_CATEGORY_COPY_PASTE) {
              return {
                component: CompactFormat,
                params: {
                  isConfig: true,
                  isCustom: true,
                  config: {
                    colDefs: [
                      {
                        field: 'field_key',
                        headerName: 'Field Key',
                        width: 100,
                        cellRenderer: CellEditorViewFormat,
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'init_value_type',
                        headerName: 'Init value type',
                        width: 100,
                        cellRenderer: SelectFormat,
                        cellRendererParams: {
                          options: [
                            {
                              value: INIT_VALUE_TYPE_FROM_ETABLE,
                              label: 'From based eTable',
                              desc: 'Value is fed from the based eTable',
                            },
                            {
                              value: INIT_VALUE_TYPE_STATIC,
                              label: 'Static',
                              desc: 'It mostly be used with hidden field.',
                            },
                            {
                              value: INIT_VALUE_TYPE_CLIPBOARD,
                              label: 'From clipboard',
                              desc: 'Value is fed from the clipboard.',
                            },
                          ],
                          hasSearch: true,
                          useTooltipIcon: true,
                          allowCustomOption: true,
                          field: 'init_value_type',
                        },
                        onCellValueChanged(params) {
                          params.api.redrawRows({
                            rowNodes: [params.node],
                          });
                        },
                        editable: true,
                      },
                      {
                        field: 'init_value_value',
                        headerName: 'Init value',
                        cellRendererSelector: (params) => {
                          if ([INIT_VALUE_TYPE_FROM_ETABLE].includes(params.data.init_value_type)) {
                            return {
                              component: SelectFormat,
                              params: {
                                options: getApiMappingOptions(etableConfigContext),
                                hasSearch: true,
                                field: 'init_value_value',
                                allowCustomOption: true,
                                allowedRemove: true,
                                tooltipField: 'description',
                              },
                            };
                          } else {
                            return {
                              component: CellEditorViewFormat,
                            };
                          }
                        },
                        cellEditor: CellEditorInputFormat,
                        editable: true,
                      },
                      {
                        field: 'field_configuration',
                        headerName: 'Field Configuration',
                        width: 100,
                        cellRendererSelector() {
                          return {
                            component: CompactFormat,
                            params: {
                              config: {
                                colDefs: [
                                  {
                                    field: 'key',
                                    headerName: 'Config item',
                                    width: 100,
                                    cellRenderer: SelectFormat,
                                    cellRendererParams: {
                                      options: [
                                        {
                                          label: 'Submit Endpoint',
                                          value: 'submit_endpoint',
                                        },
                                      ],
                                      hasSearch: true,
                                      field: 'key',
                                      allowCustomOption: true,
                                    },
                                    onCellValueChanged(params) {
                                      if (params.newValue === INPUT_TYPE_REQUEST_OPTIONS) {
                                        params.node.setDataValue('value', defaultRequestOptionsParams);
                                      }

                                      params.api.redrawRows({
                                        rowNodes: [params.node],
                                      });
                                    },
                                    editable: true,
                                  },
                                  {
                                    field: 'value',
                                    headerName: 'Value',
                                    width: 100,
                                    cellRenderer: CellEditorViewFormat,
                                    cellEditor: CellEditorInputFormat,
                                    editable: true,
                                  },
                                ],
                              },
                              isCustom: true,
                            },
                          };
                        },
                        editable: true,
                      },
                    ],
                    getDefaultData() {
                      return defaultInlineEditData;
                    },
                    getDefaultRowData() {
                      return {
                        field_key: '',
                        init_value_value: '',
                        init_value_type: INIT_VALUE_TYPE_FROM_ETABLE,
                        field_configuration: null,
                      };
                    },
                  },
                  allowDragRow: true,
                  addRowLabel: 'Add input',
                },
              };
            }
            return {
              component: CompactFormat,
              params: {
                isConfig: true,
                isCustom: true,
                config: {
                  colDefs: [
                    {
                      field: 'field_key',
                      headerName: 'Field Key',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: 'label',
                      headerName: 'Label',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: INPUT_TYPE_TOOLTIP,
                      headerName: 'Tooltip',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: 'input_type',
                      headerName: 'Input type',
                      width: 100,
                      cellRenderer: SelectFormat,
                      cellRendererParams: {
                        options: Object.keys(fields),
                        hasSearch: true,
                        field: 'input_type',
                        useTooltipIcon: true,
                        allowCustomOption: true,
                      },
                      onCellValueChanged(params) {
                        const defaultConfiguration = [];

                        if (params.newValue === FILTER_COMPONENT_NAME) {
                          defaultConfiguration.push(
                            {
                              key: INPUT_TYPE_ETABLE_SELECTED,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_ETABLE_CONFIG,
                              value: '',
                            },
                          );
                        }
                        if (params.newValue === LABEL_SELECT_COMPONENT_NAME) {
                          defaultConfiguration.push({
                            key: INPUT_TYPE_SELECTION_OPTIONS_KEY,
                            value: [],
                          });
                        }
                        if (params.newValue === TAG_EDITOR_COMPONENT_NAME) {
                          defaultConfiguration.push({
                            key: INPUT_TYPE_TAG_TYPE,
                            value: '',
                          });
                        }
                        if ([INPUT_TYPE_SELECT_FROM_TAG, INPUT_TYPE_SELECT_FROM_ETABLE].includes(params.newValue)) {
                          if (params.newValue === INPUT_TYPE_SELECT_FROM_TAG) {
                            defaultConfiguration.push({
                              key: INPUT_TYPE_CUSTOM_TAG,
                              value: '',
                            });
                          }
                          defaultConfiguration.push(
                            {
                              key: INPUT_TYPE_ETABLE_SELECTED,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_ETABLE_CONFIG,
                              value: '',
                            },
                            ...(params.newValue === INPUT_TYPE_SELECT_FROM_TAG
                              ? []
                              : [
                                  {
                                    key: INPUT_TYPE_DISPLAY_AS,
                                    value: '',
                                  },
                                ]),
                            {
                              key: INPUT_TYPE_VIEW,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_FIELD_ID,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_FIELD_LABEL,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_FILTER_FIELD,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_SYSTEM_FILTERS,
                              value: [],
                            },
                          );
                        }
                        if (params.newValue === CUSTOM_TAG) {
                          defaultConfiguration.push(
                            {
                              key: INPUT_TYPE_ETABLE_SELECTED,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_ETABLE_CONFIG,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_VIEW,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_FIELD_ID,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_FIELD_LABEL,
                              value: '',
                            },
                          );
                        }
                        if (params.newValue === CALENDAR_COMPONENT_NAME) {
                          defaultConfiguration.push(
                            {
                              key: 'return_format',
                              value: 'YYYY-MM-DD',
                            },
                            {
                              key: 'min_date',
                              value: '',
                            },
                            {
                              key: 'max_date',
                              value: '',
                            },
                            {
                              key: 'date_from_field',
                              value: `${params.data?.field_key || ''}_from`,
                            },
                            {
                              key: 'date_to_field',
                              value: `${params.data?.field_key || ''}_to`,
                            },
                            {
                              key: 'default_date_from',
                              value: '',
                            },
                            {
                              key: 'default_date_to',
                              value: '',
                            },
                            {
                              key: 'disable_start_date',
                              value: false,
                            },
                          );
                        }
                        if (params.newValue === BUDGET_EDITOR_COMPONENT_NAME) {
                          defaultConfiguration.push(
                            {
                              key: 'currency',
                              value: '',
                            },
                            {
                              key: 'disabled_list_box',
                              value: '',
                            },
                            {
                              key: 'disabled_model_no_limit',
                              value: '',
                            },
                            {
                              key: 'default_budget_type',
                              value: '',
                            },
                            {
                              key: 'primary_key',
                              value: '',
                            },
                          );
                        }
                        if (params.newValue === BIDDING_EDITOR_COMPONENT_NAME) {
                          defaultConfiguration.push(
                            {
                              key: 'marketplace_code',
                              value: '',
                            },
                            {
                              key: 'primary_key',
                              value: '',
                            },
                            {
                              key: 'tool_code',
                              value: '',
                            },
                            {
                              key: 'storefront_id',
                              value: '',
                            },
                            {
                              key: 'master_object_id',
                              value: '',
                            },
                            {
                              key: 'name',
                              value: '',
                            },
                          );
                        }
                        if (params.newValue === TEXTAREA_COMPONENT_NAME) {
                          defaultConfiguration.push(
                            {
                              key: INPUT_TYPE_SUPPORT_FORMULA,
                              value: '',
                            },
                            {
                              key: INPUT_TYPE_SUPPORT_JSON,
                              value: '',
                            },
                          );
                        }
                        params.node.setDataValue('field_configuration', defaultConfiguration);
                        params.api.redrawRows({
                          rowNodes: [params.node],
                        });
                      },
                      editable: true,
                    },
                    {
                      field: 'init_value_type',
                      headerName: 'Init value type',
                      width: 100,
                      cellRenderer: SelectFormat,
                      cellRendererParams: {
                        options: [
                          {
                            value: INIT_VALUE_TYPE_FROM_ETABLE,
                            label: 'From based eTable',
                            desc: 'Value is fed from the based eTable',
                          },
                          {
                            value: INIT_VALUE_TYPE_STATIC,
                            label: 'Static',
                            desc: 'It mostly be used with hidden field.',
                          },
                          {
                            value: INIT_VALUE_TYPE_FROM_COMPACT_ETABLE,
                            label: 'From compact eTable',
                            desc: 'Value is fed from the compact eTable',
                          },
                        ],
                        hasSearch: true,
                        useTooltipIcon: true,
                        allowCustomOption: true,
                        field: 'init_value_type',
                      },
                      onCellValueChanged(params) {
                        params.api.redrawRows({
                          rowNodes: [params.node],
                        });
                      },
                      editable: true,
                    },
                    {
                      field: 'init_value_value',
                      headerName: 'Init value',
                      cellRendererSelector: (params) => {
                        if ([INIT_VALUE_TYPE_FROM_ETABLE].includes(params.data.init_value_type)) {
                          return {
                            component: SelectFormat,
                            params: {
                              options: getApiMappingOptions(etableConfigContext),
                              hasSearch: true,
                              field: 'init_value_value',
                              allowCustomOption: true,
                              allowedRemove: true,
                              tooltipField: 'description',
                            },
                          };
                        } else if ([INIT_VALUE_TYPE_FROM_COMPACT_ETABLE].includes(params.data.init_value_type)) {
                          const compactConfig = get(params, ['node', 'parent', 'allLeafChildren'], []).find(
                            (param) =>
                              param?.data?.field_key == 'etableConfig' &&
                              param?.data?.input_type === SYSTEM_FIELD_DEFINED,
                          );
                          if (compactConfig) {
                            const config = compactConfig.data.field_configuration.find(
                              (el) => el['key'] == INPUT_TYPE_ETABLE_CONFIG,
                            );
                            if (config) {
                              return {
                                component: SelectFormat,
                                params: {
                                  options: getApiMappingOptions(config.value),
                                  hasSearch: true,
                                  field: 'init_value_value',
                                  allowCustomOption: true,
                                  allowedRemove: true,
                                  tooltipField: 'description',
                                },
                              };
                            }
                          }
                          return {
                            component: CellEditorViewFormat,
                          };
                        } else {
                          return {
                            component: CellEditorViewFormat,
                          };
                        }
                      },
                      onCellValueChanged(params) {},
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: INPUT_TYPE_DATA_TYPE,
                      headerName: 'Data Type',
                      width: 100,
                      cellRenderer: SelectFormat,
                      cellRendererParams: {
                        options: ['string', 'int', 'float', 'boolean', 'json', 'encode', 'date', 'datetime'].map(
                          (type) => ({
                            label: type,
                            value: type,
                          }),
                        ),
                        field: INPUT_TYPE_DATA_TYPE,
                        allowedRemove: true,
                        hasSearch: true,
                        allowCustomOption: true,
                      },
                      editable: true,
                    },
                    {
                      field: 'field_configuration',
                      headerName: 'Field Configuration',
                      width: 100,
                      cellRendererSelector(params) {
                        const isHiddenField = get(params, ['data', 'input_type'], '') === HIDDEN_FIELD;
                        const isSystemField = get(params, ['data', 'input_type'], '') === SYSTEM_FIELD_DEFINED;
                        const isSelectFromETable = [INPUT_TYPE_SELECT_FROM_ETABLE, INPUT_TYPE_SELECT_FROM_TAG].includes(
                          get(params, ['data', 'input_type']),
                        );
                        const isCalendar = get(params, ['data', 'input_type']) === CALENDAR_COMPONENT_NAME;
                        const isSelect = [SELECT_COMPONENT_NAME, MULTIPLE_SELECT_COMPONENT_NAME].includes(
                          get(params, ['data', 'input_type']),
                        );
                        const isCustomTag = get(params, ['data', 'input_type']) === CUSTOM_TAG;
                        if (isSelect) {
                          return {
                            component: CompactFormat,
                            params: {
                              config: {
                                colDefs: [
                                  {
                                    field: 'label',
                                    headerName: 'Label',
                                    width: 100,
                                    cellRenderer: CellEditorViewFormat,
                                    cellEditor: CellEditorInputFormat,
                                    editable: true,
                                  },
                                  {
                                    field: 'value',
                                    headerName: 'Value',
                                    width: 100,
                                    cellRenderer: CellEditorViewFormat,
                                    cellEditor: CellEditorInputFormat,
                                    editable: true,
                                  },
                                  {
                                    field: 'jsonParse',
                                    headerName: 'Json Parse',
                                    maxWidth: 100,
                                    cellRenderer: CheckboxFormat,
                                    editable: true,
                                  },
                                ],
                                getDefaultRowData() {
                                  return {
                                    label: '',
                                    value: '',
                                  };
                                },
                              },
                              isCustom: true,
                              allowDragRow: true,
                            },
                          };
                        }

                        const typeOptions = [
                          {
                            label: 'Constant',
                            value: INPUT_TYPE_CONFIG_TYPE_CONSTANT,
                          },
                          {
                            label: 'API mapping',
                            value: INPUT_TYPE_CONFIG_TYPE_API_MAPPING,
                          },
                          {
                            label: 'Api mapping with bulk',
                            value: INPUT_TYPE_CONFIG_TYPE_API_MAPPING_WITH_BULK,
                          },
                          {
                            label: 'Formula',
                            value: INPUT_TYPE_CONFIG_TYPE_FORMULA,
                          },
                        ];
                        let keyOptions = [
                          {
                            label: 'Endpoint Url',
                            value: 'endpoint_url',
                          },
                          {
                            label: 'Request Options',
                            value: INPUT_TYPE_REQUEST_OPTIONS,
                          },
                          {
                            label: 'Tag Type',
                            value: INPUT_TYPE_TAG_TYPE,
                          },
                          {
                            label: 'Support Formula',
                            value: INPUT_TYPE_SUPPORT_FORMULA,
                          },
                          {
                            label: 'Support JSON',
                            value: INPUT_TYPE_SUPPORT_JSON,
                          },
                          {
                            label: 'Max lines',
                            value: INPUT_TYPE_MAX_LINES,
                          },
                          {
                            label: 'Format input',
                            value: INPUT_TYPE_FORMAT_INPUT,
                          },
                          {
                            label: 'Marketplace code field',
                            value: 'marketplace_code',
                          },
                          {
                            label: 'Tool code field',
                            value: 'tool_code',
                          },
                          {
                            label: 'Storefront id field',
                            value: 'storefront_id',
                          },
                          {
                            label: 'Master object id field',
                            value: 'master_object_id',
                          },
                          {
                            label: 'Name field',
                            value: 'name',
                          },
                          {
                            label: 'Exclude operators',
                            value: 'exclude_operators',
                          },
                          {
                            label: 'Primary key',
                            value: 'primary_key',
                          },
                          {
                            label: 'Text end adornment',
                            value: 'text_end_adornment',
                          },
                        ];

                        const selectFromETableOption = [
                          {
                            label: 'eTable select',
                            value: INPUT_TYPE_ETABLE_SELECTED,
                            description: 'display field to select available eTable',
                          },
                          {
                            label: 'eTable config',
                            value: INPUT_TYPE_ETABLE_CONFIG,
                            description: 'display config to select available eTable',
                          },
                          {
                            label: 'Display as',
                            value: INPUT_TYPE_DISPLAY_AS,
                            description: 'As compact table or a select box field',
                          },
                          {
                            label: 'View',
                            value: INPUT_TYPE_VIEW,
                          },
                          {
                            label: 'Id field',
                            value: INPUT_TYPE_FIELD_ID,
                            description: 'selection of fields in the chosen eTable',
                          },
                          {
                            label: 'Label field',
                            value: INPUT_TYPE_FIELD_LABEL,
                          },
                          {
                            label: 'Filter field',
                            value: INPUT_TYPE_FILTER_FIELD,
                          },
                          {
                            label: 'Ignore calendar',
                            value: INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                          },
                          {
                            label: 'Max item selected',
                            value: INPUT_TYPE_MAX_ITEM_SELECTED,
                            description: '-1 : unlimited, 1: single selected, some number (2-N): max number selected',
                          },
                          {
                            label: 'System filters',
                            value: INPUT_TYPE_SYSTEM_FILTERS,
                          },
                          {
                            label: 'Multiple select',
                            value: INPUT_TYPE_MULTIPLE_SELECT,
                          },
                          {
                            label: 'Split data',
                            value: INPUT_TYPE_COMPACT_SPLIT_DATA,
                          },
                          {
                            label: 'Styles',
                            value: 'styles',
                          },
                          {
                            label: 'Embedded',
                            value: 'embedded',
                          },
                          {
                            label: 'Search hint',
                            value: INPUT_TYPE_SEARCH_HINT,
                          },
                          {
                            label: 'Additional data',
                            value: INPUT_TYPE_ADDITIONAL_DATA,
                          },
                          {
                            label: 'Link group by filter',
                            value: INPUT_TYPE_LINK_GROUPBY_FILTER,
                          },
                          {
                            label: 'Custom filters',
                            value: INPUT_TYPE_CUSTOM_FILTERS,
                          },
                        ];

                        const systemOptions = [
                          {
                            label: 'Form Title',
                            value: 'form_title',
                          },
                          {
                            label: 'Submit Label',
                            value: 'submit_label',
                          },
                          {
                            label: 'Cancel Label',
                            value: 'cancel_label',
                          },
                          {
                            label: 'Submit Endpoint',
                            value: 'submit_endpoint',
                          },
                          {
                            label: 'Payload transform',
                            value: 'payload_transform',
                          },
                          {
                            label: 'Submit after click',
                            value: INPUT_TYPE_SUBMIT_AFTER_CLICK,
                          },
                          {
                            label: 'Request method',
                            value: INPUT_TYPE_SUBMIT_METHOD,
                          },
                          {
                            label: 'Use query payload',
                            value: INPUT_TYPE_USE_QUERY_PAYLOAD,
                          },
                        ];

                        const calendarOptions = [
                          {
                            label: 'Return Format',
                            value: 'return_format',
                          },
                          {
                            label: 'Min Date',
                            value: 'min_date',
                          },
                          {
                            label: 'Max Date',
                            value: 'max_date',
                          },
                          {
                            label: 'Date From Field',
                            value: 'date_from_field',
                          },
                          {
                            label: 'Date To Field',
                            value: 'date_to_field',
                          },
                          {
                            label: 'Default Date From',
                            value: 'default_date_from',
                          },
                          {
                            label: 'Default Date To',
                            value: 'default_date_to',
                          },
                          {
                            label: 'Disable Start Date',
                            value: 'disable_start_date',
                          },
                        ];

                        if (isSelectFromETable) {
                          keyOptions = selectFromETableOption;
                        }
                        if (isSystemField) {
                          keyOptions = systemOptions.concat(selectFromETableOption);
                        }
                        if (isCalendar) {
                          keyOptions = calendarOptions;
                        }
                        if (isCustomTag) {
                          keyOptions = selectFromETableOption.filter((el) => {
                            return [
                              INPUT_TYPE_ETABLE_SELECTED,
                              INPUT_TYPE_ETABLE_CONFIG,
                              INPUT_TYPE_FIELD_ID,
                              INPUT_TYPE_FIELD_LABEL,
                              INPUT_TYPE_VIEW,
                            ].includes(el.value);
                          });
                        }

                        const apiMappingOptions = getApiMappingOptions(etableConfigContext);

                        const hiddenCellRendererParams = {
                          config: {
                            colDefs: [
                              {
                                field: 'key',
                                headerName: 'Key',
                                width: 100,
                                cellRenderer: SelectFormat,
                                cellRendererParams: {
                                  options: keyOptions,
                                  hasSearch: true,
                                  field: 'key',
                                  allowCustomOption: true,
                                },
                                onCellValueChanged(params) {
                                  params.api.redrawRows({
                                    rowNodes: [params.node],
                                  });
                                },
                                editable: true,
                              },
                              {
                                field: 'type',
                                headerName: 'Type',
                                width: 100,
                                cellRenderer: SelectFormat,
                                cellRendererParams: {
                                  options: typeOptions,
                                  hasSearch: true,
                                  field: 'type',
                                  allowCustomOption: true,
                                },
                                onCellValueChanged(params) {
                                  params.api.redrawRows({
                                    rowNodes: [params.node],
                                  });
                                },
                                editable: true,
                              },
                              {
                                field: 'value',
                                headerName: 'Value',
                                width: 100,
                                cellRendererSelector(params) {
                                  if (
                                    [
                                      INPUT_TYPE_CONFIG_TYPE_API_MAPPING_WITH_BULK,
                                      INPUT_TYPE_CONFIG_TYPE_API_MAPPING,
                                    ].includes(params.data.type)
                                  ) {
                                    return {
                                      component: SelectFormat,
                                      params: {
                                        options: apiMappingOptions,
                                        hasSearch: true,
                                        field: 'value',
                                        allowCustomOption: true,
                                        allowedRemove: true,
                                      },
                                    };
                                  }

                                  return {
                                    component: CellEditorViewFormat,
                                  };
                                },
                                cellEditor: CellEditorInputFormat,
                                editable: true,
                              },
                            ],
                          },
                          isCustom: true,
                        };

                        if (isHiddenField) {
                          return {
                            component: CompactFormat,
                            params: hiddenCellRendererParams,
                          };
                        }

                        return {
                          component: CompactFormat,
                          params: {
                            config: {
                              colDefs: [
                                {
                                  field: 'key',
                                  headerName: 'Config item',
                                  width: 100,
                                  cellRenderer: SelectFormat,
                                  cellRendererParams: {
                                    options: keyOptions,
                                    hasSearch: true,
                                    field: 'key',
                                    allowCustomOption: true,
                                  },
                                  onCellValueChanged(params) {
                                    if (params.newValue === INPUT_TYPE_REQUEST_OPTIONS) {
                                      params.node.setDataValue('value', defaultRequestOptionsParams);
                                    }

                                    params.api.redrawRows({
                                      rowNodes: [params.node],
                                    });
                                  },
                                  editable: true,
                                },
                                {
                                  field: 'value',
                                  headerName: 'Value',
                                  width: 100,
                                  cellRendererSelector(valueParams) {
                                    if (valueParams.data.key === INPUT_TYPE_OPTIONS_KEY) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          config: {
                                            colDefs: [
                                              {
                                                field: 'label',
                                                headerName: 'Label',
                                                width: 100,
                                                cellRenderer: CellEditorViewFormat,
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'value',
                                                headerName: 'Value',
                                                width: 100,
                                                cellRenderer: CellEditorViewFormat,
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'jsonParse',
                                                headerName: 'Json Parse',
                                                maxWidth: 100,
                                                cellRenderer: CheckboxFormat,
                                                editable: true,
                                              },
                                            ],
                                            getDefaultRowData() {
                                              return {
                                                label: '',
                                                value: '',
                                              };
                                            },
                                          },
                                          isCustom: true,
                                          allowDragRow: true,
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === INPUT_TYPE_SELECTION_OPTIONS_KEY) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          config: {
                                            colDefs: [
                                              {
                                                field: 'key',
                                                headerName: 'Key',
                                                width: 100,
                                                cellEditor: CellEditorInputFormat,
                                                cellRenderer: CellEditorViewFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'label',
                                                headerName: 'Label',
                                                width: 100,
                                                cellEditor: CellEditorInputFormat,
                                                cellRenderer: CellEditorViewFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'options',
                                                headerName: 'Options',
                                                width: 100,
                                                cellEditor: CellEditorInputFormat,
                                                cellRenderer: CompactFormat,
                                                cellRendererParams: {
                                                  config: {
                                                    colDefs: [
                                                      {
                                                        field: 'label',
                                                        headerName: 'Label',
                                                        width: 100,
                                                        cellRenderer: CellEditorViewFormat,
                                                        cellEditor: CellEditorInputFormat,
                                                        editable: true,
                                                      },
                                                      {
                                                        field: 'value',
                                                        headerName: 'Value',
                                                        width: 100,
                                                        cellRenderer: CellEditorViewFormat,
                                                        cellEditor: CellEditorInputFormat,
                                                        editable: true,
                                                      },
                                                      {
                                                        field: 'jsonParse',
                                                        headerName: 'Json Parse',
                                                        maxWidth: 100,
                                                        cellRenderer: CheckboxFormat,
                                                        editable: true,
                                                      },
                                                    ],
                                                    getDefaultRowData() {
                                                      return {
                                                        label: '',
                                                        value: '',
                                                      };
                                                    },
                                                  },
                                                  allowDragRow: true,
                                                  isCustom: true,
                                                },
                                                editable: true,
                                              },
                                            ],
                                            getDefaultRowData() {
                                              return {
                                                label: '',
                                                value: '',
                                              };
                                            },
                                          },
                                          allowDragRow: true,
                                          isCustom: true,
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === INPUT_TYPE_TAG_TYPE) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: [
                                            {
                                              label: 'Campaign Tag',
                                              value: 'campaign_tag',
                                            },
                                            {
                                              label: 'Ads Object Tag',
                                              value: 'ad_object_tag',
                                            },
                                            {
                                              label: 'Keyword Tag',
                                              value: 'keyword_tag',
                                            },
                                          ],
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === INPUT_TYPE_REQUEST_OPTIONS) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          config: {
                                            colDefs: [
                                              {
                                                field: 'key',
                                                headerName: 'Key',
                                                width: 100,
                                                cellRenderer: CellEditorViewFormat,
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'value',
                                                headerName: 'Value',
                                                width: 100,
                                                cellRendererSelector(params) {
                                                  if (params.data.key === 'payload') {
                                                    return {
                                                      component: CompactFormat,
                                                      params: {
                                                        config: {
                                                          colDefs: [
                                                            {
                                                              field: 'key',
                                                              headerName: 'Key',
                                                              width: 100,
                                                              cellEditor: CellEditorInputFormat,
                                                              cellRenderer: CellEditorViewFormat,
                                                              editable: true,
                                                            },
                                                            {
                                                              field: 'type',
                                                              headerName: 'Type',
                                                              width: 100,
                                                              cellRenderer: SelectFormat,
                                                              cellRendererParams: {
                                                                options: [
                                                                  {
                                                                    label: 'Data Mapping',
                                                                    value: 'data_mapping',
                                                                  },
                                                                  {
                                                                    label: 'Multiple Select',
                                                                    value: 'multiple_select',
                                                                  },
                                                                  {
                                                                    label: 'Formula',
                                                                    value: 'formula',
                                                                  },
                                                                ],
                                                                hasSearch: true,
                                                                field: 'type',
                                                                allowCustomOption: true,
                                                                allowedRemove: true,
                                                              },
                                                              onCellValueChanged(params) {
                                                                params.api.redrawRows({
                                                                  rowNodes: [params.node],
                                                                });
                                                              },
                                                              editable: true,
                                                            },
                                                            {
                                                              field: 'value',
                                                              headerName: 'Value',
                                                              width: 100,
                                                              cellEditor: CellEditorInputFormat,
                                                              cellRendererSelector(params) {
                                                                if (params.data.type === 'formula') {
                                                                  return {
                                                                    component: CellEditorViewFormat,
                                                                  };
                                                                }
                                                                if (params.data.type === 'data_mapping') {
                                                                  return {
                                                                    component: SelectFormat,
                                                                    params: {
                                                                      options: apiMappingOptions,
                                                                      hasSearch: true,
                                                                      field: 'value',
                                                                      allowCustomOption: true,
                                                                      allowedRemove: true,
                                                                    },
                                                                  };
                                                                }
                                                                if (params.data.type === 'multiple_select') {
                                                                  return {
                                                                    component: MultiSelectFormat,
                                                                    params: {
                                                                      options: apiMappingOptions,
                                                                      hasSearch: true,
                                                                      field: 'value',
                                                                      allowCustomOption: true,
                                                                    },
                                                                  };
                                                                }
                                                              },
                                                              editable: true,
                                                            },
                                                          ],
                                                          getDefaultRowData() {
                                                            return {
                                                              key: '',
                                                              value: '',
                                                            };
                                                          },
                                                        },
                                                        allowDragRow: true,
                                                        isCustom: true,
                                                      },
                                                    };
                                                  }
                                                  return {
                                                    component: CellEditorViewFormat,
                                                  };
                                                },
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                            ],
                                            getDefaultRowData() {
                                              return {
                                                label: '',
                                                value: '',
                                              };
                                            },
                                          },
                                          isCustom: true,
                                          allowDragRow: true,
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: etableList,
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }
                                    const selection = ['payload_transform', 'exclude_operators'];
                                    if (selection.includes(valueParams.data.key)) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: [],
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }
                                    const apiMappingOptionsKeys = [
                                      'default_date_from',
                                      'default_date_to',
                                      'marketplace_code',
                                      'tool_code',
                                      'storefront_id',
                                      'master_object_id',
                                      'name',
                                      'primary_key',
                                      'text_end_adornment',
                                      INPUT_TYPE_MAX_ITEM_SELECTED,
                                      INPUT_TYPE_MAX_LINES,
                                    ];
                                    if (apiMappingOptionsKeys.includes(valueParams.data.key)) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: apiMappingOptions,
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                          hideTooltip: true,
                                        },
                                      };
                                    }
                                    const yesNoOptionsKeys = [
                                      'disabled_list_box',
                                      'disabled_model_no_limit',
                                      'disable_start_date',
                                      INPUT_TYPE_SUPPORT_FORMULA,
                                      INPUT_TYPE_SUPPORT_JSON,
                                      INPUT_TYPE_MULTIPLE_SELECT,
                                      INPUT_TYPE_COMPACT_SPLIT_DATA,
                                      INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                                      INPUT_TYPE_SUBMIT_AFTER_CLICK,
                                      INPUT_TYPE_USE_QUERY_PAYLOAD,
                                      INPUT_TYPE_LINK_GROUPBY_FILTER,
                                    ];
                                    if (yesNoOptionsKeys.includes(valueParams.data.key)) {
                                      const hasSearch = ['disable_start_date'];
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: [
                                            {
                                              label: 'Yes',
                                              value: true,
                                            },
                                            {
                                              label: 'No',
                                              value: false,
                                            },
                                          ],
                                          field: 'value',
                                          hasSearch: hasSearch.includes(valueParams.data.key),
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === INPUT_TYPE_SUBMIT_METHOD) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: [
                                            {
                                              label: 'GET',
                                              value: 'GET',
                                            },
                                            {
                                              label: 'POST',
                                              value: 'POST',
                                            },
                                            {
                                              label: 'PUT',
                                              value: 'PUT',
                                            },
                                            {
                                              label: 'PATCH',
                                              value: 'PATCH',
                                            },
                                            {
                                              label: 'DELETE',
                                              value: 'DELETE',
                                            },
                                          ],
                                          field: 'value',
                                        },
                                      };
                                    }
                                    if (valueParams.data.key === 'default_budget_type') {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: BudgetOptions,
                                          field: 'value',
                                        },
                                      };
                                    }

                                    const etableFieldSelection = [
                                      INPUT_TYPE_FIELD_ID,
                                      INPUT_TYPE_FIELD_LABEL,
                                      INPUT_TYPE_FILTER_FIELD,
                                      INPUT_TYPE_ETABLE_CONFIG,
                                      INPUT_TYPE_VIEW,
                                    ];
                                    if (etableFieldSelection.includes(valueParams.data.key)) {
                                      if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                                        return {
                                          component: ResyncConfigFormat,
                                        };
                                      }
                                      const etableConfig = (
                                        params.value.find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
                                      ).value;
                                      const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce(
                                        (a, b) => {
                                          const valueGetter = Object.values(get(b, 'valueGetter', {}));
                                          return [...a, ...valueGetter];
                                        },
                                        [],
                                      );

                                      if (valueParams.data.key === INPUT_TYPE_VIEW) {
                                        const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                                          label: name,
                                          value: id,
                                        }));

                                        return {
                                          component: SelectFormat,
                                          params: {
                                            options: viewsOptions,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }

                                      mapping.sort();
                                      const uniqMapping = uniq(mapping);
                                      const filteredMapping = uniqMapping.filter((el) => !!el);
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: filteredMapping,
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }
                                    // end
                                    // Display as of etable selection
                                    // start
                                    if (valueParams.data.key === INPUT_TYPE_DISPLAY_AS) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: [
                                            {
                                              label: 'Selection',
                                              value: INPUT_TYPE_DISPLAY_AS_SELECTION,
                                            },
                                            {
                                              label: 'Compact',
                                              value: INPUT_TYPE_DISPLAY_AS_COMPACT,
                                            },
                                          ],
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }
                                    // end

                                    const etableConfig = (
                                      params.value.find((el) => el.key === INPUT_TYPE_ETABLE_CONFIG) || { value: {} }
                                    ).value;

                                    const targetFieldViewOptions = getApiMappingOptions(etableConfig).map((i) => ({
                                      value: i,
                                      label: i,
                                    }));

                                    const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map(
                                      (i) => ({
                                        value: i,
                                        label: i,
                                      }),
                                    );

                                    if (valueParams.data.key == INPUT_TYPE_CUSTOM_FILTERS) {
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: sourceFieldViewOptions,
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    }

                                    if (valueParams.data.key === INPUT_TYPE_SYSTEM_FILTERS) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          // isConfig: true,
                                          isCustom: true,
                                          config: {
                                            colDefs: [
                                              {
                                                field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                                headerName: 'Target field',
                                                width: 200,
                                                cellRenderer: SelectFormat,
                                                cellRendererParams: {
                                                  options: targetFieldViewOptions,
                                                  hasSearch: true,
                                                  allowCustomOption: true,
                                                  field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                                },
                                                editable: true,
                                              },
                                              {
                                                field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                                headerName: 'Source field',
                                                width: 200,
                                                cellRenderer: SelectFormat,
                                                cellRendererParams: {
                                                  options: sourceFieldViewOptions,
                                                  hasSearch: true,
                                                  allowCustomOption: true,
                                                  field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                                },
                                                editable: true,
                                              },
                                              {
                                                field: INPUT_TYPE_COMPACT_OPERATOR,
                                                headerName: 'Operator',
                                                width: 200,
                                                cellRenderer: SelectFormat,
                                                cellRendererParams: {
                                                  options: operators,
                                                  hasSearch: true,
                                                  allowCustomOption: false,
                                                  field: INPUT_TYPE_COMPACT_OPERATOR,
                                                },
                                                editable: true,
                                              },
                                            ],
                                            getDefaultData() {
                                              return [];
                                            },
                                            getDefaultRowData() {
                                              return {
                                                [INPUT_TYPE_COMPACT_TARGET_FIELD]: '',
                                                [INPUT_TYPE_COMPACT_SOURCE_FIELD]: '',
                                                [INPUT_TYPE_COMPACT_OPERATOR]: 'is',
                                              };
                                            },
                                            addNewFromIndex: 9999999,
                                          },
                                          allowDragRow: true,
                                          addRowLabel: 'Add input',
                                        },
                                      };
                                    }

                                    if (valueParams.data.key === INPUT_TYPE_ADDITIONAL_DATA) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          // isConfig: true,
                                          isCustom: true,
                                          config: {
                                            colDefs: [
                                              {
                                                field: 'key',
                                                headerName: 'Key',
                                                width: 200,
                                                cellRenderer: SelectFormat,
                                                cellRendererParams: {
                                                  options: targetFieldViewOptions,
                                                  hasSearch: true,
                                                  allowCustomOption: true,
                                                  field: 'key',
                                                },
                                                editable: true,
                                              },
                                              {
                                                field: 'value',
                                                headerName: 'Value',
                                                width: 200,
                                                cellRenderer: SelectFormat,
                                                cellRendererParams: {
                                                  options: sourceFieldViewOptions,
                                                  hasSearch: true,
                                                  allowCustomOption: true,
                                                  field: 'value',
                                                },
                                                editable: true,
                                              },
                                            ],
                                            getDefaultData() {
                                              return [];
                                            },
                                            getDefaultRowData() {
                                              return {
                                                key: '',
                                                value: '',
                                              };
                                            },
                                            addNewFromIndex: 9999999,
                                          },
                                          allowDragRow: true,
                                          addRowLabel: 'Add input',
                                        },
                                      };
                                    }

                                    if (valueParams.data.key === INPUT_TYPE_CUSTOM_TAG) {
                                      return {
                                        component: CompactFormat,
                                        params: {
                                          config: {
                                            colDefs: [
                                              {
                                                field: 'label',
                                                headerName: 'Label',
                                                width: 100,
                                                cellRenderer: CellEditorViewFormat,
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                              {
                                                field: 'value',
                                                headerName: 'Value',
                                                width: 100,
                                                cellRenderer: CellEditorViewFormat,
                                                cellEditor: CellEditorInputFormat,
                                                editable: true,
                                              },
                                            ],
                                            getDefaultRowData() {
                                              return {
                                                label: '',
                                                value: '',
                                              };
                                            },
                                            addNewFromIndex: 99999,
                                          },
                                          isCustom: true,
                                          allowDragRow: true,
                                        },
                                      };
                                    }

                                    return {
                                      component: CellEditorViewFormat,
                                    };
                                  },
                                  onCellValueChanged(params) {
                                    if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                                      const blockEid = params.data.value;
                                      requestEtableConfig(blockEid).then((res) => {
                                        const eTableConfigNode = params.node.parent.allLeafChildren.find(
                                          (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                                        );
                                        if (eTableConfigNode) {
                                          if (res.success) {
                                            eTableConfigNode.setDataValue('value', res.data);
                                          } else if (!eTableConfigNode.data?.blockEid) {
                                            eTableConfigNode.setDataValue('value', {
                                              ...DEFAULT_CONFIG_ETABLE_SELECTION,
                                              blockEid,
                                            });
                                          }
                                        }
                                        const eTableConfigView = params.node.parent.allLeafChildren.find(
                                          (el) => el.data.key === INPUT_TYPE_VIEW,
                                        );
                                        if (eTableConfigView) {
                                          if (res.success) {
                                            eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                                          }
                                        }
                                      });
                                    }
                                    const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                                      return (
                                        params.data.key === INPUT_TYPE_ETABLE_SELECTED ||
                                        data?.key != INPUT_TYPE_ETABLE_CONFIG
                                      );
                                    });
                                    params.api.redrawRows({
                                      rowNodes: redrawRows,
                                    });
                                  },
                                  cellEditor: CellEditorInputFormat,
                                  editable: true,
                                },
                              ],
                            },
                            isCustom: true,
                          },
                        };
                      },
                      editable: true,
                    },
                    {
                      field: INPUT_TYPE_PLACEHOLDER,
                      headerName: 'Place holder (UI)',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: INPUT_TYPE_HELPER_TEXT,
                      headerName: 'Help text (UI)',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: INPUT_TYPE_REGEX,
                      headerName: 'Regex',
                      width: 100,
                      editable: true,
                      cellRenderer: SelectFormat,
                      cellRendererParams: {
                        options: [],
                        hasSearch: true,
                        field: INPUT_TYPE_REGEX,
                        useTooltipIcon: true,
                        allowCustomOption: true,
                      },
                    },
                  ],
                  getDefaultData() {
                    return defaultInlineEditData;
                  },
                  getDefaultRowData() {
                    return {
                      field_key: '',
                      label: '',
                      input_type: '',
                      field_configuration: null,
                    };
                  },
                },
                allowDragRow: true,
                addRowLabel: 'Add input',
              },
            };
          },
          editable: true,
        },
        {
          field: 'description',
          headerName: 'Description',
          maxWidth: 100,
          cellRendererSelector(params) {
            return {
              component: CompactFormat,
              params: {
                isConfig: true,
                isCustom: true,
                config: {
                  colDefs: [
                    {
                      field: 'key',
                      headerName: 'Key',
                      width: 100,
                      cellRenderer: CellEditorViewFormat,
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                    {
                      field: 'value',
                      headerName: 'Value',
                      width: 100,
                      cellRendererSelector(params) {
                        if (params.data?.key === 'icon') {
                          return {
                            component: SelectWindowFormat,
                            params: {
                              options: [],
                              hasSearch: true,
                              field: 'value',
                              allowCustomOption: true,
                              allowedRemove: true,
                              isIcon: true,
                            },
                          };
                        }
                        return {
                          component: SelectFormat,
                          params: {
                            options: [],
                            hasSearch: true,
                            field: 'value',
                            allowCustomOption: true,
                            allowedRemove: true,
                          },
                        };
                      },
                      onCellValueChanged(params) {
                        if (params.data?.key === 'url') {
                          const dynamicParams = uniq(params.newValue.match(/\:\w+/g) || []);
                          const addKeys = dynamicParams.filter((el) => {
                            return params.node.parent.allLeafChildren.every((ele) => ele.data?.key !== el.slice(1));
                          });
                          const removeRows = params.node.parent.allLeafChildren
                            .filter((el) => {
                              return (
                                dynamicParams.every((ele) => ele.slice(1) !== el.data?.key) && el.data?.key !== 'url'
                              );
                            })
                            .map((el) => el.data);

                          params.api.applyTransaction({
                            add: addKeys.map((el) => ({
                              key: el.slice(1),
                              value: '',
                            })),
                            remove: removeRows,
                          });
                        }
                      },
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                  ],
                  getDefaultData() {
                    return [
                      {
                        key: 'icon',
                        value: '',
                      },
                      {
                        key: 'tooltip',
                        value: '',
                      },
                      {
                        key: 'condition',
                        value: '',
                      },
                    ];
                  },
                  getDefaultRowData() {
                    return {
                      key: '',
                      value: '',
                    };
                  },
                  addNewFromIndex: 9999999,
                  hideDefaultActions: true,
                },
              },
            };
          },
          editable: true,
        },
        {
          field: 'tooltip',
          headerName: 'Tooltip',
          maxWidth: 100,
          cellRendererSelector(params) {
            return {
              component: CompactFormat,
              params: {
                isConfig: true,
                isCustom: true,
                config: {
                  colDefs: [
                    {
                      field: 'key',
                      headerName: 'Key',
                      width: 100,
                      cellRenderer: SelectFormat,
                      cellRendererParams: {
                        options: [
                          {
                            label: 'Header',
                            value: 'header',
                          },
                          {
                            label: 'Span',
                            value: 'span',
                          },
                          {
                            label: 'Table',
                            value: 'table',
                          },
                          {
                            label: 'Block',
                            value: 'block',
                          },
                          {
                            label: 'Status',
                            value: 'status',
                          },
                          {
                            label: 'Width',
                            value: 'width',
                          },
                        ],
                        field: 'key',
                      },
                      onCellValueChanged(params) {
                        params.api.redrawRows({
                          rowNodes: [params.node],
                        });
                      },
                      editable: true,
                    },
                    {
                      field: 'value',
                      headerName: 'Value',
                      width: 100,
                      cellRendererSelector(valueParams2) {
                        if (valueParams2.data?.key == 'table') {
                          return {
                            component: CompactFormat,
                            params: {
                              isConfig: true,
                              isCustom: true,
                              config: {
                                colDefs: [
                                  {
                                    field: 'key',
                                    headerName: 'Config item',
                                    width: 200,
                                    cellRenderer: SelectFormat,
                                    cellRendererParams: {
                                      options: [
                                        {
                                          label: 'eTable select',
                                          value: INPUT_TYPE_ETABLE_SELECTED,
                                        },
                                        {
                                          label: 'eTable config',
                                          value: INPUT_TYPE_ETABLE_CONFIG,
                                        },
                                        {
                                          label: 'Display as',
                                          value: INPUT_TYPE_DISPLAY_AS,
                                        },
                                        {
                                          label: 'View',
                                          value: INPUT_TYPE_VIEW,
                                        },
                                        {
                                          label: 'System filters',
                                          value: INPUT_TYPE_SYSTEM_FILTERS,
                                        },
                                        {
                                          label: 'Ignore calendar',
                                          value: INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                                        },
                                        {
                                          label: 'Source fields',
                                          value: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                        },
                                        {
                                          label: 'Target fields',
                                          value: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                        },
                                        {
                                          label: 'Inherit filter from source',
                                          value: INPUT_TYPE_COMPACT_TARGET_INHERIT_FILTER,
                                          description:
                                            'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                                        },
                                        {
                                          label: 'Filter field',
                                          value: INPUT_TYPE_FILTER_FIELD,
                                          description:
                                            'Used for setting search input on compact table used.\n It is used for pickup the value on the form input select etable dropdown.',
                                        },
                                        {
                                          label: 'Custom Tag',
                                          value: INPUT_TYPE_CUSTOM_TAG,
                                        },
                                        {
                                          label: 'Search hint',
                                          value: INPUT_TYPE_SEARCH_HINT,
                                        },
                                        {
                                          label: 'Additional data',
                                          value: INPUT_TYPE_ADDITIONAL_DATA,
                                        },
                                        {
                                          label: 'Link group by filter',
                                          value: INPUT_TYPE_LINK_GROUPBY_FILTER,
                                        },
                                      ],
                                      hasSearch: true,
                                      field: 'key',
                                      allowCustomOption: true,
                                      allowedRemove: true,
                                    },
                                    editable: true,
                                    onCellValueChanged(params) {
                                      params.api.redrawRows({
                                        rowNodes: [params.node],
                                      });
                                    },
                                  },
                                  {
                                    field: 'value',
                                    headerName: 'Value',
                                    width: 100,
                                    cellRendererSelector(valueParams) {
                                      if (valueParams.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                                        return {
                                          component: SelectFormat,
                                          params: {
                                            options: etableList,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }
                                      if (valueParams.data.key === INPUT_TYPE_ETABLE_CONFIG) {
                                        return {
                                          component: ResyncConfigFormat,
                                        };
                                      }
                                      const etableConfig = (
                                        valueParams.node.parent.allLeafChildren.find(
                                          (el) => el.data?.key === INPUT_TYPE_ETABLE_CONFIG,
                                        ) || {
                                          data: { value: {} },
                                        }
                                      ).data?.value;

                                      if (valueParams.data.key === INPUT_TYPE_VIEW) {
                                        const viewsOptions = get(etableConfig, ['views'], []).map(({ id, name }) => ({
                                          label: name,
                                          value: id,
                                        }));

                                        return {
                                          component: SelectFormat,
                                          params: {
                                            options: viewsOptions,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }

                                      if (valueParams.data.key === INPUT_TYPE_DISPLAY_AS) {
                                        const chartConfig = get(etableConfig, ['chartConfig', 'config'], null);
                                        const isGalleryVisualization =
                                          get(etableConfig, ['visualizationType'], null) == 'category';
                                        const viewsOptions = [
                                          {
                                            label: 'Etable',
                                            value: 'etable',
                                          },
                                          ...(!!chartConfig
                                            ? [
                                                {
                                                  label: 'Chart',
                                                  value: 'chart',
                                                },
                                              ]
                                            : []),
                                          ...(isGalleryVisualization ? [{ label: 'Gallery', value: 'category' }] : []),
                                        ];

                                        return {
                                          component: SelectFormat,
                                          params: {
                                            options: viewsOptions,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }

                                      const sourceFieldViewOptions = getApiMappingOptions(etableConfigContext).map(
                                        (i) => ({
                                          value: i,
                                          label: i,
                                        }),
                                      );
                                      if (valueParams.data.key === INPUT_TYPE_COMPACT_SOURCE_FIELD) {
                                        return {
                                          component: MultiSelectFormat,
                                          params: {
                                            options: sourceFieldViewOptions,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }

                                      const targetFieldViewOptions = getApiMappingOptions(etableConfig).map((i) => ({
                                        value: i,
                                        label: i,
                                      }));
                                      if (valueParams.data.key === INPUT_TYPE_COMPACT_TARGET_FIELD) {
                                        return {
                                          component: MultiSelectFormat,
                                          params: {
                                            options: targetFieldViewOptions,
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }

                                      if (valueParams.data.key === INPUT_TYPE_SYSTEM_FILTERS) {
                                        return {
                                          component: CompactFormat,
                                          params: {
                                            // isConfig: true,
                                            isCustom: true,
                                            config: {
                                              colDefs: [
                                                {
                                                  field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                                  headerName: 'Target field',
                                                  width: 200,
                                                  cellRenderer: SelectFormat,
                                                  cellRendererParams: {
                                                    options: targetFieldViewOptions,
                                                    hasSearch: true,
                                                    allowCustomOption: true,
                                                    field: INPUT_TYPE_COMPACT_TARGET_FIELD,
                                                  },
                                                  editable: true,
                                                },
                                                {
                                                  field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                                  headerName: 'Source field',
                                                  width: 200,
                                                  cellRenderer: SelectFormat,
                                                  cellRendererParams: {
                                                    options: sourceFieldViewOptions,
                                                    hasSearch: true,
                                                    allowCustomOption: true,
                                                    field: INPUT_TYPE_COMPACT_SOURCE_FIELD,
                                                  },
                                                  editable: true,
                                                },
                                                {
                                                  field: INPUT_TYPE_COMPACT_OPERATOR,
                                                  headerName: 'Operator',
                                                  width: 200,
                                                  cellRenderer: SelectFormat,
                                                  cellRendererParams: {
                                                    options: operators,
                                                    hasSearch: true,
                                                    allowCustomOption: false,
                                                    field: INPUT_TYPE_COMPACT_OPERATOR,
                                                  },
                                                  editable: true,
                                                },
                                              ],
                                              getDefaultData() {
                                                return [];
                                              },
                                              getDefaultRowData() {
                                                return {
                                                  [INPUT_TYPE_COMPACT_TARGET_FIELD]: '',
                                                  [INPUT_TYPE_COMPACT_SOURCE_FIELD]: '',
                                                  [INPUT_TYPE_COMPACT_OPERATOR]: 'is',
                                                };
                                              },
                                              addNewFromIndex: 9999999,
                                            },
                                            allowDragRow: true,
                                            addRowLabel: 'Add input',
                                          },
                                        };
                                      }

                                      if (valueParams.data.key === INPUT_TYPE_ADDITIONAL_DATA) {
                                        return {
                                          component: CompactFormat,
                                          params: {
                                            // isConfig: true,
                                            isCustom: true,
                                            config: {
                                              colDefs: [
                                                {
                                                  field: 'key',
                                                  headerName: 'Key',
                                                  width: 200,
                                                  cellRenderer: SelectFormat,
                                                  cellRendererParams: {
                                                    options: targetFieldViewOptions,
                                                    hasSearch: true,
                                                    allowCustomOption: true,
                                                    field: 'key',
                                                  },
                                                  editable: true,
                                                },
                                                {
                                                  field: 'value',
                                                  headerName: 'Value',
                                                  width: 200,
                                                  cellRenderer: SelectFormat,
                                                  cellRendererParams: {
                                                    options: sourceFieldViewOptions,
                                                    hasSearch: true,
                                                    allowCustomOption: true,
                                                    field: 'value',
                                                  },
                                                  editable: true,
                                                },
                                              ],
                                              getDefaultData() {
                                                return [];
                                              },
                                              getDefaultRowData() {
                                                return {
                                                  key: '',
                                                  value: '',
                                                };
                                              },
                                              addNewFromIndex: 9999999,
                                            },
                                            allowDragRow: true,
                                            addRowLabel: 'Add input',
                                          },
                                        };
                                      }

                                      const yesNoOptionsKeys = [
                                        INPUT_TYPE_LINK_GROUPBY_FILTER,
                                        INPUT_TYPE_COMPACT_IGNORE_CALENDAR,
                                      ];
                                      if (yesNoOptionsKeys.includes(valueParams.data.key)) {
                                        return {
                                          component: SelectFormat,
                                          params: {
                                            options: [
                                              {
                                                label: 'Yes',
                                                value: true,
                                              },
                                              {
                                                label: 'No',
                                                value: false,
                                              },
                                            ],
                                            hasSearch: true,
                                            field: 'value',
                                            allowCustomOption: true,
                                            allowedRemove: true,
                                          },
                                        };
                                      }
                                      const mapping = Object.values(get(etableConfig, ['mapping'], {})).reduce(
                                        (a, b) => {
                                          const valueGetter = Object.values(get(b, 'valueGetter', {}));
                                          return [...a, ...valueGetter];
                                        },
                                        [],
                                      );
                                      mapping.sort();
                                      const uniqMapping = uniq(mapping);
                                      const filteredMapping = uniqMapping.filter((el) => !!el);
                                      return {
                                        component: SelectFormat,
                                        params: {
                                          options: filteredMapping,
                                          hasSearch: true,
                                          field: 'value',
                                          allowCustomOption: true,
                                          allowedRemove: true,
                                        },
                                      };
                                    },
                                    onCellValueChanged(params) {
                                      if (params.data.key === INPUT_TYPE_ETABLE_SELECTED) {
                                        const blockEid = params.data.value;
                                        requestEtableConfig(blockEid).then((res) => {
                                          const eTableConfigNode = params.node.parent.allLeafChildren.find(
                                            (el) => el.data.key === INPUT_TYPE_ETABLE_CONFIG,
                                          );
                                          if (eTableConfigNode) {
                                            if (res.success) {
                                              eTableConfigNode.setDataValue('value', res.data);
                                            } else if (!eTableConfigNode.data?.blockEid) {
                                              eTableConfigNode.setDataValue('value', {
                                                ...DEFAULT_CONFIG_ETABLE_SELECTION,
                                                blockEid,
                                              });
                                            }
                                          }
                                          const eTableConfigView = params.node.parent.allLeafChildren.find(
                                            (el) => el.data.key === INPUT_TYPE_VIEW,
                                          );
                                          if (eTableConfigView) {
                                            if (res.success) {
                                              eTableConfigView.setDataValue('value', res.data?.view?.id || '');
                                            }
                                          }
                                        });
                                      }
                                      const redrawRows = params.node.parent.allLeafChildren.filter(({ data }) => {
                                        return (
                                          params.data.key === INPUT_TYPE_ETABLE_SELECTED ||
                                          data?.key != INPUT_TYPE_ETABLE_CONFIG
                                        );
                                      });
                                      params.api.redrawRows({
                                        rowNodes: redrawRows,
                                      });
                                    },
                                    editable: true,
                                  },
                                ],
                                getDefaultData() {
                                  return [
                                    {
                                      key: INPUT_TYPE_ETABLE_SELECTED,
                                      value: '',
                                    },
                                    {
                                      key: INPUT_TYPE_ETABLE_CONFIG,
                                      value: '',
                                    },
                                    {
                                      key: INPUT_TYPE_DISPLAY_AS,
                                      value: 'etable',
                                    },
                                    {
                                      key: INPUT_TYPE_SYSTEM_FILTERS,
                                      value: [],
                                    },
                                    {
                                      key: INPUT_TYPE_VIEW,
                                      value: '',
                                    },
                                  ];
                                },
                                getDefaultRowData() {
                                  return {
                                    label: '',
                                    value: '',
                                    shortcut: '',
                                  };
                                },
                              },
                              addRowLabel: 'Add config',
                            },
                          };
                        }
                        return {
                          component: SelectFormat,
                          params: {
                            options: [],
                            hasSearch: true,
                            field: 'value',
                            allowCustomOption: true,
                            allowedRemove: true,
                          },
                        };
                      },
                      cellEditor: CellEditorInputFormat,
                      editable: true,
                    },
                  ],
                  getDefaultData() {
                    return [
                      {
                        key: 'status',
                        value: 'Enabled',
                      },
                      {
                        key: 'width',
                        value: '300px',
                      },
                      {
                        key: 'span',
                        value: '',
                      },
                    ];
                  },
                  getDefaultRowData() {
                    return {
                      key: 'span',
                      value: '',
                    };
                  },
                  addNewFromIndex: 9999999,
                },
                allowDragRow: true,
              },
            };
          },
          editable: true,
        },
        ...(aim.isSuperAdmin()
          ? [
              {
                field: 'callback',
                headerName: 'Callback',
                maxWidth: 100,
                cellRenderer: ScriptEditorFormat,
                editable: true,
              },
            ]
          : []),
        {
          field: 'tracking',
          headerName: 'Tracking',
          maxWidth: 100,
          cellRenderer: SelectFormat,
          cellRendererParams: {
            options: [
              { label: 'Yes', value: 'yes' },
              { label: 'No', value: 'no' },
            ],
            hasSearch: true,
            allowCustomOption: true,
            field: 'tracking',
          },
          editable: true,
        },
        {
          field: 'role',
          headerName: 'User permission',
          maxWidth: 150,
          width: 100,
          cellRenderer: MultiSelectFormat,
          cellRendererParams: {
            options: roles,
            hasSearch: true,
            allowCustomOption: false,
            field: 'role',
            allowedRemove: true,
          },
          editable: true,
        },
      ],
      getDefaultRowData() {
        return {
          name: '',
          icon: '',
          category: ACTION_CATEGORY_INLINE_FORM,
          configuration: defaultInlineEditData,
          role: [1, 2, 3],
        };
      },
      addNewFromIndex: 999999,
    },
    allowDragRow: true,
    isCustom: true,
    addRowLabel: 'Add action',
    hasClone: true,
    cloneBtnLabel: 'Clone action',
    buttons: actionButtons,
    popoverWidth: '1024px',
  },
  cellRendererSelector(params) {
    if (params.node.rowPinned) {
      return {
        frameworkComponent: CalculateCellFormat,
      };
    }
    return undefined;
  },
  minWidth: 150,
});
