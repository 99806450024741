import SelectFormSearchInside from '@ep/insight-ui/elements/form-control/select-form/select-form-search-inside';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { IValueAgeFormat } from '@ep/insight-ui/elements/table/format/age-format';
import { Box, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { produce } from 'immer';
import { get, maxBy, minBy } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingTop: '2px',
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '18px',
  },
  avatar: {
    height: '80px',
    width: '80px',
    marginRight: '8px',
  },
  block: {
    display: 'flex',
    gap: 8,
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    '&.ageStart, &.ageEnd': {
      maxWidth: 64,
    },
  },
}));

type Props = {
  data: {
    value: IValueAgeFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any> | void;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
  onLoad: (value?: any) => void;
};
const AgeModel_CONST = {
  SET_AGE: 'set-age',
  ALL: 'all',
};
const ageOptions: OptionSelectType[] = [
  {
    label: 'All',
    value: AgeModel_CONST.ALL,
  },
  {
    label: 'Set age',
    value: AgeModel_CONST.SET_AGE,
  },
];

const AgeEditor = (props: Props) => {
  const { data, title = '', payload, onSubmit, onLoad, anchorEl, setAnchorEl } = props;
  const classes = useStyles();
  const [listAge, setListAge] = React.useState<OptionSelectType[]>([]);
  const value: { id: string | number; text: string }[] = get(props, 'data.value.value', []);

  const [ageModel, setAgeModel] = React.useState<OptionSelectType>(
    ageOptions.find((item) => item.value == AgeModel_CONST.ALL),
  );
  const [minAge, setMinAge] = React.useState<OptionSelectType>({ label: '', value: '' });
  const [maxAge, setMaxAge] = React.useState<OptionSelectType>({ label: '', value: '' });
  const [loading, setLoading] = React.useState(false);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const initOptions = async () => {
    if (onLoad) {
      setLoading(true);
      const toolCode = get(payload, ['rowPicker', 'toolCode']);
      const storefrontId = get(payload, ['rowPicker', 'storefrontId']);
      const res: any = await onLoad({
        toolCodeIds: [].concat(get(data.node, ['data', toolCode])),
        storefrontIds: [].concat(get(data.node, ['data', storefrontId])),
      });
      setLoading(false);
      if (res.success) {
        // TODO: set initial options
        const arr: OptionSelectType[] = res.data
          .sort((a, b) => (+a.text > +b.text ? 1 : -1))
          .map((item) => ({
            label: item.text,
            value: item.id,
          }));
        setListAge(arr);
        return;
      }
    }
    setListAge([]);
    // setListAge(demoAge.sort((a, b) => (+a.text > +b.text ? 1 : -1)).map((item) => ({ label: item.text, value: item.id })));
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  React.useEffect(() => {
    if (value && value.length > 0 && listAge.length > 0) {
      setAgeModel(ageOptions.find((item) => item.value == AgeModel_CONST.SET_AGE));
      const minText = minBy(value, (o) => o.text);
      const minValue = minText ? listAge.find((item) => item.label == minText.text) : null;
      const maxText = maxBy(value, (o) => o.text);
      const maxValue = maxText ? listAge.find((item) => item.label == maxText.text) : null;
      if (minValue) setMinAge(minValue);
      if (maxValue) setMaxAge(maxValue);
    } else {
      setAgeModel(ageOptions.find((item) => item.value == AgeModel_CONST.ALL));
    }
  }, [value, listAge]);

  const getMinAgeOptions = (value: string | number, type: 'min' | 'max') => {
    const idx = listAge.findIndex((item) => item.value == value);
    if (idx == -1) return [];
    return type == 'min' ? listAge.slice(0, idx) : listAge.slice(idx + 1, listAge.length - 1);
  };

  const handleOnChangeAgeModel = (val: OptionSelectType) => {
    if (val.value !== AgeModel_CONST.ALL && (!value || (value && value.length == 0))) {
      const minValue = minBy(listAge, (o) => +o.label);
      const maxValue = maxBy(listAge, (o) => +o.label);
      if (minValue) setMinAge(minValue);
      if (maxValue) setMaxAge(maxValue);
    }
    setAgeModel(val);
  };

  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    // const valueTmp = JSON.parse(JSON.stringify(data.value));
    // if (ageValue.value === 'all') {
    //   valueTmp.label = 'all';
    // } else {
    //   valueTmp.label = `${minAge.value} • ${maxAge.value}`;
    // }
    setIsSubmitting(true);
    const response: any = await onSubmit({
      value: produce(data.value, (draft) => {
        if (ageModel.value == AgeModel_CONST.ALL) {
          (draft as any).value = JSON.stringify([]);
        } else {
          const minIdx = listAge.findIndex((item) => item.value == minAge.value);
          const maxIdx = listAge.findIndex((item) => item.value == maxAge.value);
          const arr = listAge.slice(minIdx, maxIdx + 1).map((item) => ({ text: item.label, id: item.value }));
          (draft as any).value = JSON.stringify(arr);
        }
      }),
      row: data.node.data,
    });
    setIsSubmitting(false);
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0px 8px 8px 8px', minWidth: '304px' }}>
      <Box sx={{ display: 'flex' }}>
        <Box>
          <HeaderList
            title={title}
            variant="h4"
            className={classes.header}
            iconHead={
              loading ? (
                <Box ml={2} display={'inline-flex'}>
                  <CircularProgress size="1em" color="#232323" />
                </Box>
              ) : null
            }
          />
        </Box>
      </Box>
      <div className={classes.dialog}>
        <div className={classes.block}>
          <div className={clsx(classes.form, 'age')}>
            <SelectFormSearchInside
              style={{ width: '100%' }}
              inputWidth={'100%'}
              searchAble={false}
              value={ageModel}
              options={listAge.length > 0 ? ageOptions : ageOptions.filter((item) => item.value == AgeModel_CONST.ALL)}
              onChangeValue={handleOnChangeAgeModel}
            />
          </div>
          {ageModel.value !== AgeModel_CONST.ALL && (
            <>
              <div className={clsx(classes.form, 'ageStart')}>
                <SelectFormSearchInside
                  value={minAge}
                  style={{ width: '100%' }}
                  inputWidth={'100%'}
                  searchAble={false}
                  options={getMinAgeOptions(maxAge.value, 'min')}
                  onChangeValue={setMinAge}
                />
              </div>
              <div>•</div>
              <div className={clsx(classes.form, 'ageEnd')}>
                <SelectFormSearchInside
                  value={maxAge}
                  style={{ width: '100%' }}
                  inputWidth={'100%'}
                  searchAble={false}
                  options={getMinAgeOptions(minAge.value, 'max')}
                  onChangeValue={setMaxAge}
                />
              </div>
            </>
          )}
        </div>
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default AgeEditor;
