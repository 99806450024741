import { ColumnApi } from '@ag-grid-community/core';
import * as React from 'react';

interface IPinnedColumn {
  field: string;
  isLeftPin: boolean;
  isRightPin: boolean;
}

export const usePinColumn = ({ pinnedColumns, backbone, rowsGroup }) => {
  const [columnApi, setColumnApi] = React.useState<ColumnApi>(null);

  const pinThisColumns = React.useMemo(() => {
    if (columnApi) {
      return pinnedColumns.filter(
        (pinThisColumn) => pinThisColumn.isLeftPin === false && pinThisColumn.isRightPin === false,
      );
    }
    return [];
  }, [pinnedColumns, columnApi]);

  const pinLeftColumns = React.useMemo(() => {
    if (columnApi) {
      return pinnedColumns.filter(
        (pinThisColumn) => columnApi.getColumn(pinThisColumn.field) && pinThisColumn.isLeftPin,
      );
    }
    return [];
  }, [pinnedColumns]);

  const getPinColumnActions = (column, currentPinnedColumns) => {
    const pinnedColumn = currentPinnedColumns.find(({ field }) => field === column.field);
    return [
      {
        name: pinnedColumn && !pinnedColumn.isLeftPin && !pinnedColumn.isRightPin ? 'Unpin this' : 'Pin this',
        actionId: 'pinThis',
        icon: 'pinColumn',
        type: '',
        component: null,
        onSubmit: (data) => {
          const pinnedColumn = currentPinnedColumns.find(({ field }) => field === column.field);
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (pinnedColumn.isLeftPin || pinnedColumn.isRightPin) {
              newPinnedResult = currentPinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: false,
                  isRightPin: false,
                };
              });
            } else {
              newPinnedResult = currentPinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: false,
              isRightPin: false,
            };
            newPinnedResult = [...currentPinnedColumns, pinnedColumn];
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
      {
        name: pinnedColumn && pinnedColumn.isLeftPin ? 'Unpin left' : 'Pin left',
        icon: 'pinColumn',
        actionId: 'pinLeft',
        type: '',
        component: null,
        onSubmit: (data) => {
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (!pinnedColumn.isLeftPin) {
              newPinnedResult = currentPinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: true,
                  isRightPin: false,
                };
              });
            } else {
              newPinnedResult = currentPinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: true,
              isRightPin: false,
            };
            newPinnedResult = [...currentPinnedColumns, pinnedColumn];
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
      {
        name: pinnedColumn && pinnedColumn.isRightPin ? 'Unpin Right' : 'Pin right',
        actionId: 'pinRight',
        icon: 'pinColumn',
        type: '',
        component: null,
        onSubmit: (data) => {
          let newPinnedResult: IPinnedColumn[];
          if (pinnedColumn) {
            if (!pinnedColumn.isRightPin) {
              newPinnedResult = currentPinnedColumns.map((el) => {
                if (pinnedColumn.field !== el.field) return el;
                return {
                  ...el,
                  isLeftPin: false,
                  isRightPin: true,
                };
              });
            } else {
              newPinnedResult = currentPinnedColumns.filter((pinnedColumn) => pinnedColumn.field !== data.field);
            }
          } else {
            const pinnedColumn: IPinnedColumn = {
              field: data.field,
              isLeftPin: false,
              isRightPin: true,
            };
            newPinnedResult = [...currentPinnedColumns, pinnedColumn];
          }
          backbone.changeConfig('pinnedColumn', newPinnedResult);
        },
      },
    ];
  };

  React.useEffect(() => {
    // move left pinned columns to first
    const pinnedLeftColumns = pinLeftColumns.map(({ field }) => field);
    if (pinnedLeftColumns.length > 0) {
      columnApi.moveColumns(pinnedLeftColumns, rowsGroup.length > 0 ? 2 : 1);
    }
  }, [pinnedColumns]);

  const handleBodyScroll = ({ left, direction }) => {
    if (direction === 'horizontal' && pinThisColumns.length > 0) {
      // Check if columns are active
      const activePinnedColumns = pinThisColumns.filter((pinnedColumn) => {
        const column = columnApi.getColumn(pinnedColumn.field);
        if (!column) return false;
        if (column.isPinnedLeft() && column.isVisible()) {
          // Left value of the right column of current pinned column is also current left value of pinned column
          const currentIndex = columnApi.getAllGridColumns().findIndex((el) => el.getColId() === column.getColId());

          const columnRight = columnApi.getAllGridColumns().find((el, i) => {
            return i > currentIndex && !el.isPinnedLeft() && el.isVisible();
          });
          if (columnRight && left <= columnRight.getLeft()) {
            return false;
          }
          return true;
        }
        return left > column.getLeft();
      });
      activePinnedColumns.forEach((pCol) => {
        const col = columnApi.getColumn(pCol.field);
        if (!col.isPinnedLeft()) {
          columnApi.setColumnPinned(pCol.field, 'left');
        }
      });
      pinThisColumns.forEach((i) => {
        if (!activePinnedColumns.some((i1) => i1.field === i.field)) {
          columnApi.setColumnPinned(i.field, null);
        }
      });
    }
  };

  return {
    setColumnApi,
    handleBodyScroll,
    getPinColumnActions,
  };
};
