import { atom } from 'jotai';
import { Moment } from 'moment';

const lastUpdatedAtAtom = atom<{ value: Moment }>({ value: null });
export const lastUpdatedAt = atom(
  (get) => {
    return get(lastUpdatedAtAtom).value;
  },
  (get, set, value: { value: Moment }) => {
    set(lastUpdatedAtAtom, value);
  },
);
