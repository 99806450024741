import * as React from 'react';
import Dropdown from '@ep/insight-ui/elements/dropdown-menu/dropdown';
import { EIPContext } from '../../context';
import MenuList, { DropdownMenuDataType } from '@ep/insight-ui/elements/list-control/menu-list/menu-list';
import { Box } from '@material-ui/core';

export function BlockErrorDisplay({ blockData }) {
  const dbContext = React.useContext(EIPContext);
  const menuItems: DropdownMenuDataType[][] =
    dbContext.mode === 'edit'
      ? [
          [
            {
              title: 'Remove',
              icon: 'delete',
              onClick: () => {
                console.log('Remove node');
                dbContext.onRemoveNode(blockData.id);
              },
            },
          ],
        ]
      : null;

  const menu =
    dbContext.mode === 'edit' ? (
      <Dropdown alignMenu="right" icon="threeDotsVertical" label="">
        <MenuList listMenu={menuItems} />
      </Dropdown>
    ) : null;

  return <Box>Error {menu}</Box>;
}
