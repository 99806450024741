import { EventEmitter2 as EventEmitter } from 'eventemitter2';
import { get, set } from 'lodash';

export function createWorkflowLight(
  initData,
  requestRegistry,
  applyUpdateRegistry,
): WorkflowLight<typeof requestRegistry, typeof applyUpdateRegistry> {
  const em = new EventEmitter();
  let data = { ...initData };

  const workflow = {
    request<RequestResult>(fn, input: Record<string, any>): Promise<RequestResult> {
      if (!requestRegistry[fn]) {
        console.info('request `%s` is not registered', fn);
        return Promise.resolve(null);
      } else {
        return requestRegistry[fn](input, workflow);
      }
    },
    applyUpdate<UpdateResult>(
      fn,
      updates: {
        target: Record<string, any>[];
        updateType: string;
        value: any;
      },
    ): Promise<UpdateResult> {
      if (!applyUpdateRegistry[fn]) {
        console.info('applyUpdate `%s` is not registered', fn);
        return Promise.resolve(null);
      } else {
        return applyUpdateRegistry[fn](updates, workflow);
      }
    },
    getAllData(): Promise<Record<string, any>> {
      return Promise.resolve(data);
    },
    set(key, value: any): Promise<Record<string, typeof value>> {
      data = set(data, key, value);
      return Promise.resolve({ [key]: value });
    },
    get(key: string | string[], defaultValue?: any): Promise<typeof defaultValue> {
      const fData = {};
      for (const k of [].concat(key)) {
        fData[k] = get(data, k, get(defaultValue, k));
      }
      return Promise.resolve(fData);
    },
    init(input, defaultValue) {
      return Promise.resolve(defaultValue);
    },
    submit(data) {
      return Promise.resolve(data);
    },
    on(eventId: WorkflowEventType, fn: (...args: any[]) => void) {
      em.addListener(eventId, fn);
      return () => {
        em.removeListener(eventId, fn);
      };
    },
    emit(eventId, input) {
      em.emit(eventId, input);
    },
    removeListener(eventId: string, fn: (...args: any) => void) {
      em.removeListener(eventId, fn);
    },
  };

  return workflow;
}

export function createWorkflowCampaignDetails(
  initData,
  tableConfigRegistry: Record<
    `full_${string}` | `compact_${string}`,
    (input: Record<string, any>, workflow: ReturnType<typeof createWorkflowCampaignDetails>) => Promise<ETableConfig>
  >,
  requestRegistry,
  applyUpdateRegistry,
): WorkflowCampaignTable<typeof requestRegistry, typeof applyUpdateRegistry> {
  const em = new EventEmitter();
  let data = { ...initData };

  const workflow = {
    async getTableConfig(type, name, input) {
      return tableConfigRegistry[`${type}_${name}`](input, workflow);
    },
    request<RequestResult>(fn, input: Record<string, any>): Promise<RequestResult> {
      if (!requestRegistry[fn]) {
        console.info('request `%s` is not registered', fn);
        return Promise.resolve(null);
      } else {
        return requestRegistry[fn](input, workflow);
      }
    },
    applyUpdate<UpdateResult>(
      fn,
      updates: {
        target: Record<string, any>[];
        updateType: string;
        value: any;
      },
    ): Promise<UpdateResult> {
      if (!applyUpdateRegistry[fn]) {
        console.info('applyUpdate `%s` is not registered', fn);
        return Promise.resolve(null);
      } else {
        return applyUpdateRegistry[fn](updates, workflow);
      }
    },
    getAllData(): Promise<Record<string, any>> {
      return Promise.resolve(data);
    },
    set(key, value: any): Promise<Record<string, typeof value>> {
      data = set(data, key, value);
      return Promise.resolve({ [key]: value });
    },
    get(key: string | string[], defaultValue?: any): Promise<typeof defaultValue> {
      const fData = {};
      for (const k of [].concat(key)) {
        fData[k] = get(data, k, get(defaultValue, k));
      }
      return Promise.resolve(fData);
    },
    init(input, defaultValue) {
      return Promise.resolve(defaultValue);
    },
    submit(data) {
      return Promise.resolve(data);
    },
    on(eventId: WorkflowEventType, fn: (...args: any[]) => void) {
      em.addListener(eventId, fn);
      return () => {
        em.removeListener(eventId, fn);
      };
    },
    emit(eventId, input) {
      em.emit(eventId, input);
    },
    removeListener(eventId: string, fn: (...args: any) => void) {
      em.removeListener(eventId, fn);
    },
  };

  return workflow;
}
