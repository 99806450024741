import { EIP_CONSTANT } from '@eip/next/lib/main';

export const ADS_OPS_AD_OBJECT = {
  sort: [],
  view: {
    id: 'default',
    name: 'Default',
    isLock: false,
    combinator: {
      sort: [],
      filter: [],
      groupBy: {},
      properties: {
        metric: [],
        attribute: [
          'campaign_status',
          'campaign_daily_budget',
          'campaign_total_budget',
          'ad_object_status',
          'ad_object_total_budget',
        ],
        dimension: ['ad_tool', 'storefront', 'country', 'marketplace', 'campaign_name', 'object_name'],
      },
      columnOrder: [
        'object_name',
        'storefront',
        'country',
        'marketplace',
        'campaign_name',
        'ad_tool',
        'campaign_status',
        'campaign_daily_budget',
        'campaign_total_budget',
        'ad_object_status',
        'ad_object_total_budget',
      ],
      columnWidth: [
        {
          columnField: 'checkbox',
          width: 40,
        },
        {
          columnField: 'storefront',
          width: 100,
        },
        {
          columnField: 'country',
          width: 100,
        },
        {
          columnField: 'marketplace',
          width: 100,
        },
        {
          columnField: 'campaign_name',
          width: 218,
        },
        {
          columnField: 'ad_tool',
          width: 182,
        },
        {
          columnField: 'campaign_status',
          width: 137,
        },
        {
          columnField: 'campaign_daily_budget',
          width: 158,
        },
        {
          columnField: 'campaign_total_budget',
          width: 150,
        },
        {
          columnField: 'ad_object_status',
          width: 133,
        },
        {
          columnField: 'ad_object_total_budget',
          width: 149,
        },
        {
          columnField: 'object_name',
          width: 340,
        },
      ],
      pinnedColumn: [],
      specialFilters: {},
    },
    systemView: true,
    initializedView: true,
  },
  title: 'Ads ops ad object',
  views: [
    {
      id: 'default',
      name: 'Default',
      isLock: false,
      combinator: {
        sort: [],
        filter: [],
        groupBy: {},
        properties: {
          metric: [],
          attribute: [
            'campaign_status',
            'campaign_daily_budget',
            'campaign_total_budget',
            'ad_object_status',
            'ad_object_total_budget',
          ],
          dimension: ['ad_tool', 'storefront', 'country', 'marketplace', 'campaign_name', 'object_name'],
        },
        columnOrder: [
          'object_name',
          'storefront',
          'country',
          'marketplace',
          'campaign_name',
          'ad_tool',
          'campaign_status',
          'campaign_daily_budget',
          'campaign_total_budget',
          'ad_object_status',
          'ad_object_total_budget',
        ],
        columnWidth: [
          {
            columnField: 'checkbox',
            width: 40,
          },
          {
            columnField: 'storefront',
            width: 100,
          },
          {
            columnField: 'country',
            width: 100,
          },
          {
            columnField: 'marketplace',
            width: 100,
          },
          {
            columnField: 'campaign_name',
            width: 218,
          },
          {
            columnField: 'ad_tool',
            width: 182,
          },
          {
            columnField: 'campaign_status',
            width: 137,
          },
          {
            columnField: 'campaign_daily_budget',
            width: 158,
          },
          {
            columnField: 'campaign_total_budget',
            width: 150,
          },
          {
            columnField: 'ad_object_status',
            width: 133,
          },
          {
            columnField: 'ad_object_total_budget',
            width: 149,
          },
          {
            columnField: 'object_name',
            width: 340,
          },
        ],
        pinnedColumn: [],
        specialFilters: {},
      },
      systemView: true,
      initializedView: true,
    },
  ],
  filter: [],
  metric: [],
  search: '',
  system: {
    allowPivot: 'no',
    allowExport: 'yes',
    defaultLimit: '',
    datetimeField: 'created_datetime',
    numberOfPivot: 0,
    allowCalculate: 'yes',
    externalFilters: [],
    hiddenComponents: ['majorButton'],
    allowAccumulative: 'no',
    externalProperties: [],
    defaultExpandedRows: 0,
    externalFiltersOptions: [],
  },
  searchHint: 'Search ad object...',
  groupBy: {},
  mapping: {
    ad_tool: {
      menu: [],
      title: 'Ad tool',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_tool.code',
      cellFormat: 'oneDimension',
      filterField: 'ads_tool.code',
      staticValue: {},
      valueGetter: {
        id: 'ads_tool.code',
        label: 'ads_tool.name',
        value: 'ads_tool.code',
        subtext: 'ads_tool.code',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    country: {
      menu: [],
      title: 'Country',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'country.name',
      cellFormat: 'oneDimension',
      filterField: 'country.name',
      staticValue: {},
      valueGetter: {
        id: 'country.id',
        label: 'country.name',
        value: 'country.id',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    storefront: {
      menu: [],
      title: 'Storefront',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'storefront.name',
      cellFormat: 'oneDimension',
      filterField: 'storefront.name',
      staticValue: {},
      valueGetter: {
        id: 'storefront.id',
        label: 'storefront.name',
        value: 'storefront.id',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    marketplace: {
      menu: [],
      title: 'Marketplace',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'marketplace.name',
      cellFormat: 'oneDimension',
      filterField: 'marketplace.name',
      staticValue: {},
      valueGetter: {
        id: 'marketplace.id',
        label: 'marketplace.name',
        value: 'marketplace.id',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    object_name: {
      menu: [],
      title: 'Ad object',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_objects.name',
      cellFormat: 'oneDimension',
      filterField: 'ads_objects.name',
      staticValue: {},
      valueGetter: {
        id: 'ads_objects.id',
        label: 'ads_objects.name',
        value: 'ads_objects.id',
        subtext: 'ads_objects.id',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    campaign_name: {
      menu: [],
      title: 'Campaign',
      actions: [
        {
          icon: '',
          name: 'Campaign detail',
          role: [1, 2, 3],
          category: 'internal_link_with_params',
          shortcut: {
            key: 'campaign_name',
            index: 0,
          },
          configuration: [
            {
              key: 'url',
              value: 'https://to.epsilo.io/page/b9f2a55f-32fc-4d70-9044-27ce0ec42848/:id',
              field_configuration: [],
            },
            {
              key: 'id',
              value: 'ads_campaigns.id',
              field_configuration: [],
            },
          ],
          prevConfiguration: {
            inline_form: [
              {
                label: 'Form Configuration',
                field_key: 'form',
                input_type: 'system_field_defined',
                field_configuration: [
                  {
                    key: 'form_title',
                    value: 'Untitled',
                  },
                  {
                    key: 'submit_label',
                    value: 'Submit',
                  },
                  {
                    key: 'cancel_label',
                    value: 'Cancel',
                  },
                  {
                    key: 'submit_endpoint',
                    value: '',
                  },
                ],
              },
            ],
          },
        },
      ],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_campaigns.name',
      cellFormat: 'oneDimension',
      filterField: 'ads_campaigns.name',
      staticValue: {},
      valueGetter: {
        id: 'ads_campaigns.id',
        label: 'ads_campaigns.name',
        value: 'ads_campaigns.id',
      },
      propertyType: 'dimension',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    campaign_status: {
      menu: [],
      title: 'Campaign status',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_campaigns.status',
      cellFormat: 'oneDimension',
      filterField: 'ads_campaigns.status',
      staticValue: {},
      valueGetter: {
        id: 'ads_campaigns.status',
        label: '=PROPER(p("value"))',
        value: 'ads_campaigns.status',
        status:
          '=IF(p("value")=="ONGOING","ic/ic:baseline-circle/mint",IF(p("value")=="PAUSED","ic/pixelarticons:pause/gray",IF(p("value")=="DRAFT","ic/material-symbols:draft-orders/gray","ic/ic:baseline-circle/lightgray")))',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: true,
      columnDescription: '',
      defaultCalculated: null,
    },
    ad_object_status: {
      menu: [],
      title: 'Ad object status',
      actions: [],
      useMock: {},
      dataType: 'string',
      menuItem: [],
      sortField: 'ads_objects.status',
      cellFormat: 'oneDimension',
      filterField: 'ads_objects.status',
      staticValue: {},
      valueGetter: {
        id: 'ads_objects.status',
        label: '=PROPER(p("value"))',
        value: 'ads_objects.status',
        status:
          '=IF(p("value")=="ONGOING","ic/ic:baseline-circle/mint",IF(p("value")=="PAUSED","ic/pixelarticons:pause/gray",IF(p("value")=="DRAFT","ic/material-symbols:draft-orders/gray","ic/ic:baseline-circle/lightgray")))',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: true,
      columnDescription: '',
      defaultCalculated: null,
    },
    campaign_daily_budget: {
      menu: [],
      title: 'Campaign daily budget',
      actions: [],
      useMock: {},
      dataType: 'float',
      menuItem: [],
      sortField: 'ads_campaigns.daily_budget',
      cellFormat: 'oneDimension',
      filterField: 'ads_campaigns.daily_budget',
      staticValue: {},
      valueGetter: {
        id: 'ads_campaigns.daily_budget',
        label: '=IF(p("value"), CONCATENATE(TEXT(p("value"), "#,###"), " ", p("currency")), "No limit")',
        value: 'ads_campaigns.daily_budget',
        currency: 'storefront.currency',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    campaign_total_budget: {
      menu: [],
      title: 'Campaign total budget',
      actions: [],
      useMock: {},
      dataType: 'float',
      menuItem: [],
      sortField: 'ads_campaigns.total_budget',
      cellFormat: 'oneDimension',
      filterField: 'ads_campaigns.total_budget',
      staticValue: {},
      valueGetter: {
        id: 'ads_campaigns.total_budget',
        label: '=IF(p("value"), CONCATENATE(TEXT(p("value"), "#,###"), " ", p("currency")), "No limit")',
        value: 'ads_campaigns.total_budget',
        currency: 'storefront.currency',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
    ad_object_total_budget: {
      menu: [],
      title: 'Ad object total budget',
      actions: [],
      useMock: {},
      dataType: 'float',
      menuItem: [],
      sortField: 'ads_objects.total_budget',
      cellFormat: 'oneDimension',
      filterField: 'ads_objects.total_budget',
      staticValue: {},
      valueGetter: {
        id: 'ads_objects.total_budget',
        label: '=IF(p("value"), CONCATENATE(TEXT(p("value"), "#,###"), " ", p("currency")), "No limit")',
        value: 'ads_objects.total_budget',
        currency: 'storefront.currency',
      },
      propertyType: 'attribute',
      advancedFilter: [
        {
          key: 'eTable_selected',
          value: '',
        },
        {
          key: 'eTable_config',
          value: null,
        },
        {
          key: 'view',
          value: '',
        },
        {
          key: 'field_label',
          value: '',
        },
        {
          key: 'field_value',
          value: '',
        },
        {
          key: 'field_filter',
          value: '',
        },
        {
          key: 'compact_pagination_limit',
          value: '999',
        },
        {
          key: 'metrics',
          value: '',
        },
      ],
      defaultGroupBy: null,
      selectionFilter: null,
      columnDescription: '',
      defaultCalculated: null,
    },
  },
  _runtime: {
    dateRange: {
      dateFrom: '2024-02-14',
      dateTo: '2024-02-21',
    },
    cohortDateRange: null,
  },
  currency: 'USD',
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=automation',
  },
  _editorId: 'table_BFJ3JCYw5O4rLRGp-Fvoc',
  attribute: [
    'campaign_status',
    'campaign_daily_budget',
    'campaign_total_budget',
    'ad_object_status',
    'ad_object_total_budget',
  ],
  dateRange: {
    dateTo: '2024-02-21',
    dateFrom: '2024-02-14',
  },
  dimension: ['object_name', 'storefront', 'country', 'marketplace', 'campaign_name', 'ad_tool'],
  tableType: 'compact',
  mainButton: {
    text: 'Reload',
    type: 'component',
    componentName: 'ReloadTableBtn',
  },
  columnOrder: [
    'object_name',
    'storefront',
    'country',
    'marketplace',
    'campaign_name',
    'ad_tool',
    'campaign_status',
    'campaign_daily_budget',
    'campaign_total_budget',
    'ad_object_status',
    'ad_object_total_budget',
  ],
  columnWidth: [
    {
      columnField: 'checkbox',
      width: 40,
    },
    {
      columnField: 'storefront',
      width: 100,
    },
    {
      columnField: 'country',
      width: 100,
    },
    {
      columnField: 'marketplace',
      width: 100,
    },
    {
      columnField: 'campaign_name',
      width: 218,
    },
    {
      columnField: 'ad_tool',
      width: 182,
    },
    {
      columnField: 'campaign_status',
      width: 137,
    },
    {
      columnField: 'campaign_daily_budget',
      width: 158,
    },
    {
      columnField: 'campaign_total_budget',
      width: 150,
    },
    {
      columnField: 'ad_object_status',
      width: 133,
    },
    {
      columnField: 'ad_object_total_budget',
      width: 149,
    },
    {
      columnField: 'object_name',
      width: 340,
    },
  ],
  primaryKeys: ['ads_objects.id'],
  settingType: [
    {
      type: 'dimension',
      label: 'Dimension',
      allowConfig: true,
    },
    {
      type: 'attribute',
      label: 'Attribute',
      allowConfig: true,
    },
    {
      type: 'metric',
      label: 'Metric',
      allowConfig: true,
    },
  ],
  hiddenFilter: {
    currency: 'USD',
  },
  pinnedColumn: [],
  viewByConfig: {
    timeline: [],
    dimension: ['_dimension_all'],
  },
  hiddenColumns: [],
  calendarConfig: {
    typePicker: 'default',
    dateRangeSelect: 'yes',
  },
  calendarOption: 'LAST_7_DAYS',
  specialFilters: {},
  allowCustomView: true,
  cohortDateRange: null,
  shortcutActions: {
    sort: {
      name: 'sort',
      order: 1,
      shortcut: true,
      hideLabel: false,
    },
    filter: {
      name: 'filter',
      order: 0,
      shortcut: true,
      hideLabel: false,
    },
  },
  defaultPagination: {
    limit: 100,
    page: 1,
    total: 0,
    traceId: '',
  },
  visualizationType: 'table',
  disabledComponents: {},
  linkToGlobalFilter: 'no',
  requestHiddenField: {},
  requestIgnoreField: {
    metric: [],
  },
  hiddenPropertyColumns: [],
  paginationIsExactTotal: false,
  isSupportGroupAggregation: true,
};
