import * as React from 'react';
import produce from 'immer';
import { get } from 'lodash';
import color from 'color';
import { Line } from 'react-chartjs-2';

import { Box, Grid, makeStyles } from '@material-ui/core';

import { tableTooltip } from '@ep/insight-ui/chartlib/helper';

const useStyles = makeStyles(() => ({
  tableTooltip: tableTooltip,
}));

class Errorboundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <span>Something went wrong.</span>;
    }

    return this.props.children;
  }
}

const getOrCreateTooltip = (chart) => {
  const id = 'cell-elasticity-chart-tooltip';
  let tooltipEl = document.getElementById(id);

  if (!tooltipEl) {
    tooltipEl = document.createElement('div');
    tooltipEl.id = id;
    tooltipEl.style.background = '#fff';
    tooltipEl.style.borderRadius = '3px';
    tooltipEl.style.color = '#204d77';
    tooltipEl.style.opacity = '1';
    tooltipEl.style.pointerEvents = 'none';
    tooltipEl.style.position = 'fixed';
    tooltipEl.style.zIndex = '10001';
    tooltipEl.style.transform = 'translate(-50%, 0)';
    tooltipEl.style.transition = 'all .1s ease';
    tooltipEl.style.boxShadow = '0px 6px 12px rgb(140 152 164 / 25%)';
    tooltipEl.style.padding = '6px 8px';

    const table = document.createElement('table');
    table.style.margin = '0px';
    table.style.width = '200px';

    tooltipEl.appendChild(table);
    document.body.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context, options) => {
  // Tooltip Element

  const { chart, tooltip } = context;
  const { period, classes } = options;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);
    let total = 0;
    bodyLines.forEach((arr) => {
      total += get(arr, [0, 'value'], 0);
    });
    const tableHead = document.createElement('thead');

    titleLines.forEach((title) => {
      const tr: HTMLTableRowElement = document.createElement('tr');
      tr.style.borderWidth = '0';
      tr.className = 'headerRow';

      const th: HTMLTableCellElement = document.createElement('th');
      th.style.borderWidth = '0';
      const text = document.createTextNode(title);

      th.appendChild(text);
      tr.appendChild(th);
      tableHead.appendChild(tr);
    });

    const tableBody = document.createElement('tbody');
    bodyLines.forEach((body, i) => {
      const tr: HTMLTableRowElement = document.createElement('tr');
      tr.className = 'legendRow';
      const label: string = get(body, [0, 'metric'], '');
      const value: number = get(body, [0, 'value', 'tooltipLabel'], 0);
      const backgroundColor: string = get(tooltip, ['labelColors', i, 'borderColor'], '');

      const tdHTML = `
      <td class="legendLine">
        <span class="legendIcon" style="background: ${backgroundColor}; border-color: ${backgroundColor};"></span>
        <div class="content">
        <div class="label">${label}</div>
        <div class="value">${value}</div>
        </div>
      </td>
      `;
      tr.innerHTML = tdHTML;
      tableBody.appendChild(tr);
    });

    const tableRoot: HTMLTableElement = tooltipEl.querySelector('table');

    // Remove old children
    while (tableRoot.firstChild) {
      tableRoot.firstChild.remove();
    }
    tableRoot.className = `${classes.tableTooltip}`;
    // Add new children
    tableRoot.appendChild(tableHead);
    tableRoot.appendChild(tableBody);
  }

  const position = context.chart.canvas.getBoundingClientRect();

  const { offsetLeft: positionX, offsetTop: positionY, offsetHeight: positionY1 } = chart.canvas;
  const tooltipWidth = tooltipEl.getBoundingClientRect().width;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = '1';
  const left = position.left + 110 + tooltip.caretX;
  const constantNumber = 12;
  tooltipEl.style.left =
    (left + tooltipWidth / 2 + constantNumber > document.body.clientWidth
      ? left - tooltipWidth - constantNumber
      : left) + 'px';
  tooltipEl.style.top = position.top + positionY1 + 'px';
  // tooltipEl.style.top = positionY + tooltip.caretY + 'px';
  tooltipEl.style.font = tooltip.options.bodyFont.string;
  tooltipEl.style.padding = '0';
};

const MiniChart = ({ data, chartPeriod, metricDetail, currency, height }) => {
  const classes = useStyles();

  const yAxisOptions = get(data, ['datasets'], []).reduce((carry, dataset) => {
    if (!carry[dataset.yAxisID]) {
      carry[dataset.yAxisID] = {
        display: false,
        grid: {
          display: false,
          drawBorder: false,
        },
        min: dataset.min,
        max: dataset.max,
      };
    } else {
      carry[dataset.yAxisID].min = Math.min(dataset.min, carry[dataset.yAxisID].min);
      carry[dataset.yAxisID].max = Math.max(dataset.max, carry[dataset.yAxisID].max);
    }
    return carry;
  }, {});

  const options = React.useMemo(
    () => ({
      responsive: true,
      maintainAspectRatio: false,
      interaction: {
        mode: 'index',
        intersect: false,
      },
      scales: {
        ...yAxisOptions,
        xAxes: {
          display: false,
          grid: {
            display: false,
            drawBorder: false,
          },
        },
      },
      layout: {
        padding: {
          left: 0,
          right: 0,
          top: 2,
          bottom: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          position: 'nearest',
          external: (context) =>
            chartPeriod ? externalTooltipHandler(context, { period: chartPeriod, classes }) : null, // this cause the issue with unmounting cellformat that could access cleared `periodBackbone`
          callbacks: {
            label(data) {
              return {
                metric: data.dataset?.customMetric
                  ? data.dataset?.customMetric
                  : metricDetail?.[data.raw?.metric]?.label_raw,
                value: data.raw,
                currency: currency,
              };
            },
          },
        },
      },
    }),
    [chartPeriod],
  );

  const cData = produce(data, (draft) => {
    data.datasets.forEach((dataset, index) => {
      // draft.datasets[index].normalized = true;
      draft.datasets[index].segment = {
        borderDash: (ctx) => {
          return ctx.p0.skip || ctx.p1.skip ? [2, 2] : undefined;
        },
      };
      if (dataset.chartStyle === 'area') {
        draft.datasets[index].fill = 'start';
        draft.datasets[index].backgroundColor = color(dataset.borderColor).lightness(90).fade(0.2).rgb().string(); // 'rgba(215, 230, 244, 0.8)'
      }
    });
  });

  if (!data.datasets || data.datasets.length === 0) return <Box width={'100%'}></Box>;

  return (
    <Grid item>
      <Errorboundary>
        <Line {...(height ? { height } : {})} width={'100%'} data={cData} options={options} />
      </Errorboundary>
    </Grid>
  );
};

export default MiniChart;
