import { EIP_CONSTANT } from '@eip/next/lib/main';
import { CAMPAIGN_DETAIL_COMMON_MAPPING, getActionMenu } from '../../table-config/common';

export const CAMPAIGN_DETAIL_CONFIG = {
  title: 'Campaign detail',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getCampaignInsideData',
    UPDATE_CELL_VALUE: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/ads-operation/mass-update-dimension',
    GET_MARKET_STATUS_DETAIL: EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational',
  },
  primaryKeys: [
    'MARKETPLACE.marketplace_code',
    'STOREFRONT.id',
    'ADTYPE.ads_type',
    'ADTOOL.ads_tool',
    'ADS_CAMPAIGN.id',
    'ADS_CALENDAR.id',
    'ADS_OBJECT.id',
    'ADS_PLACEMENT.id',
    'PRODUCT.id',
    // 'SUGGESTED_KEYWORD.id',
  ],
  views: [
    {
      id: 'display_ads_display_location',
      name: 'Display location',
      excludeProperty: {
        dimension: ['audience'],
        attribute: ['age', 'gender', 'location', 'interest_category'],
        metric: [],
      },
      ...(ff.function_crud_etable_view ? { systemView: true } : {}),
    },
    {
      id: 'display_ads_audience',
      name: 'Audience',
      excludeProperty: {
        dimension: ['display_location'],
        attribute: ['premium_rate', 'display_location_status', 'keyword_position'],
        metric: [],
      },
      ...(ff.function_crud_etable_view ? { systemView: true } : {}),
    },
  ],
  view: {
    id: 'display_ads_display_location',
    name: 'Display location',
  },
  ...(ff.function_crud_etable_view ? { allowCustomView: false } : {}),
  mainButton: {
    text: 'Add ad tool',
  },
  requestIgnoreField: {
    metric: ['STOREFRONT.currency'],
    attribute: ['eipCustomRowId'],
  },
  system: {
    hiddenComponents: ['search', 'majorButton'],
    additionalFilters: [
      {
        name: 'Data status',
        id: 'data_status',
        dataType: 'string',
        propertyType: 'dimension',
        fields: ['ADS_CAMPAIGN.data_status', 'ADS_OBJECT.data_status', 'ADS_PLACEMENT.data_status'],
        options: [
          {
            label: 'Succeeded',
            value: '1',
          },
          {
            label: 'Failed',
            value: '2',
          },
        ],
        rules: [
          {
            value: '1',
            combinator: 'AND',
          },
          {
            value: '2',
            combinator: 'OR',
          },
        ],
        operators: [
          {
            label: 'Is',
            value: 'is',
          },
        ],
        isAdditionalFilter: true,
      },
    ],
  },
  disabledComponents: {
    dimension: ['marketplace', 'country', 'storefront', 'ad_tool', 'ad_object'],
  },
  requestHiddenField: {
    dimension: ['ADTYPE'],
    attribute: ['ADTYPE.ads_type'],
  },
  ...(ff.fix_incorrect_column_order
    ? {
        columnOrder: [
          'dataSyncStatus',
          'marketplace',
          'country',
          'storefront',
          'ad_tool',
          'ad_object',
          'display_location',
          'audience',
          'campaign_tag',
          'ad_object_tag',
          'keyword_tag',
          'campaign_status',
          'display_location_status',
          'ad_object_status',
          'ad_object_budget',
          'age',
          'gender',
          'audience_location',
          'interest_category',
          'ad_object_bidding_method',
          'ad_object_bidding_price',
          'premium_rate',
        ],
      }
    : {}),
  mapping: {
    dataSyncStatus: {
      title: 'Sync status',
      propertyType: 'dimension',
      cellFormat: 'dataSyncStatusFormat',
      dataType: 'string',
      filterField: '',
      sortField: '',
      valueGetter: {
        data_status: 'ADS_CAMPAIGN.data_status',
      },
      pinned: 'left',
    },
    // ---------------------------- Dimension ----------------------------------//
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'MARKETPLACE.marketplace_code',
      valueGetter: {
        label: 'MARKETPLACE.marketplace_name',
        id: 'MARKETPLACE.marketplace_code',
        campaignId: 'ADS_CAMPAIGN.id',
        campaignName: 'ADS_CAMPAIGN.name',
        campaignCode: 'ADS_CAMPAIGN.campaign_code',
        adToolId: 'ADTOOL.ads_tool',
        adToolDisplayName: 'ADTOOL.ads_tool_name',
        adObjectId: 'ADS_OBJECT.id',
        calendarId: 'ADS_CALENDAR.id',
        campaignDataStatus: 'ADS_CAMPAIGN.data_status',
      },
    },
    country: {
      title: 'Country',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'COUNTRY.country_code',
      valueGetter: {
        label: 'COUNTRY.country_name',
        id: 'COUNTRY.country_code',
      },
    },
    storefront: {
      title: 'Storefront',
      propertyType: 'dimension',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'STOREFRONT.id',
      valueGetter: {
        label: 'STOREFRONT.name',
        id: 'STOREFRONT.id',
        sid: 'STOREFRONT.storefront_sid',
        subtext: 'STOREFRONT.id',
      },
    },
    ad_tool: {
      title: 'Ad tool',
      propertyType: 'dimension',
      dataType: 'string',
      cellFormat: 'compact',
      valueGetter: {
        data: 'ad_tool',
        context: 'context',
        ...(ff.groupby_display_location
          ? {
              id: 'ADTOOL.ads_tool',
              label: 'ADTOOL.ads_tool_name',
              type: 'ADTYPE.ads_type',
            }
          : {}),
      },
      tableConfiguration: null,
    },
    ad_object: {
      title: 'Ad object',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_OBJECT',
      sortField: 'ADS_OBJECT.name',
      valueGetter: {
        data: 'ad_object',
        id: 'ADS_OBJECT.id',
        productId: 'PRODUCT.id',
        productIds: 'PRODUCT.product_sid',
        label: 'ADS_OBJECT.name',
        createdAt: 'ADS_OBJECT.created_at',
        updatedAt: 'ADS_OBJECT.updated_at',
        subtext: 'PRODUCT.product_sid',
        marketplaceCode: 'MARKETPLACE.marketplace_code',
        data_status: 'ADS_OBJECT.data_status',
      },
      groupByColumn: 'ADS_OBJECT.id',
      tableConfiguration: null,
    },
    display_location: {
      title: 'Display location',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'ADS_PLACEMENT.display_location_name',
      valueGetter: {
        data: 'display_location',
        adPlacementId: 'ADS_PLACEMENT.id',
        label: 'ADS_PLACEMENT.display_location_name',
        type: 'ADS_PLACEMENT.type',
        ...(ff.groupby_display_location
          ? {
              id: 'ADS_PLACEMENT.display_location_name',
            }
          : {}),
        data_status: 'ADS_PLACEMENT.data_status',
      },
      ...(ff.compact_dependency_data
        ? {
            staticValue: {
              dependencyData: ['PRODUCT.id'],
            },
          }
        : {}),
      tableConfiguration: null,
    },
    audience: {
      title: 'Audience',
      propertyType: 'dimension',
      cellFormat: 'compact',
      filterField: 'AUDIENCE.name',
      valueGetter: {
        data: 'audience',
        id: 'AUDIENCE.id',
        label: 'AUDIENCE.name',
        audienceType: 'AUDIENCE.type',
      },
      staticValue: {
        defaultDisplay: 'All',
        ...(ff.compact_dependency_data
          ? {
              dependencyData: ['PRODUCT.id'],
            }
          : {}),
      },
      tableConfiguration: null,
    },
    // ---------------------------- Attribute ----------------------------------//
    campaign_tag: {
      title: 'Campaign tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_CAMPAIGN.general_tag',
      valueGetter: {
        id: 'ADS_CAMPAIGN.general_tag',
        value: 'ADS_CAMPAIGN.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('campaign_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ad_object_tag: {
      title: 'Ad object tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_OBJECT.general_tag',
      valueGetter: {
        id: 'ADS_OBJECT.general_tag',
        value: 'ADS_OBJECT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('ad_object_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    keyword_tag: {
      title: 'Keyword tag',
      propertyType: 'dimension',
      cellFormat: 'tagWithInlineEditor',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.general_tag',
      valueGetter: {
        id: 'ADS_PLACEMENT.general_tag',
        value: 'ADS_PLACEMENT.general_tag',
      },
      ...(ff.ui_ux_update_tag_item
        ? {
            menu: getActionMenu('keyword_tag'),
            menuItem: getActionMenu('sub_item_tag'),
          }
        : {}),
    },
    ...(ff.delete_ad_campaign_object
      ? {
          campaign_status: {
            title: 'Campaign status',
            propertyType: 'attribute',
            cellFormat: 'status',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.status',
            valueGetter: {
              label: 'ADS_CAMPAIGN.status',
              status: 'ADS_CAMPAIGN.status',
              tooltips: 'ADS_CAMPAIGN.tooltips',
            },
            menu: getActionMenu('campaign_status'),
          },
        }
      : {}),
    ...(ff.add_note_column_including_inline_edit
      ? {
          campaign_note: {
            title: 'Campaign note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_CAMPAIGN.note',
            valueGetter: {
              id: 'ADS_CAMPAIGN.note',
              value: 'ADS_CAMPAIGN.note',
            },
            menu: getActionMenu('campaign_note'),
          },
          ad_note: {
            title: 'Ad note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_PLACEMENT.note',
            valueGetter: {
              id: 'ADS_PLACEMENT.note',
              value: 'ADS_PLACEMENT.note',
            },
            menu: getActionMenu('ad_note'),
          },
          ad_object_note: {
            title: 'Ad object note',
            propertyType: 'attribute',
            cellFormat: 'note',
            dataType: 'string',
            filterField: 'ADS_OBJECT.note',
            valueGetter: {
              id: 'ADS_OBJECT.note',
              value: 'ADS_OBJECT.note',
            },
            menu: getActionMenu('ad_object_note'),
          },
        }
      : {}),
    display_location_status: {
      title: 'Display location status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_PLACEMENT.status',
      valueGetter: {
        label: 'ADS_PLACEMENT.status',
        status: 'ADS_PLACEMENT.status',
        id: 'ADS_PLACEMENT.id',
      },
      menu: getActionMenu('keyword_status'),
    },
    ad_object_status: {
      title: 'Ad object status',
      propertyType: 'attribute',
      cellFormat: 'status',
      dataType: 'string',
      filterField: 'ADS_OBJECT.status',
      valueGetter: {
        label: 'ADS_OBJECT.status',
        status: 'ADS_OBJECT.status',
        tooltips: 'ADS_OBJECT.tooltips',
      },
      menu: getActionMenu('ad_object_status'),
    },
    ad_object_budget: {
      title: 'Ad object budget',
      propertyType: 'attribute',
      cellFormat: 'selectionBudget',
      dataType: 'float',
      filterField: 'ADS_OBJECT.total_budget',
      valueGetter: {
        totalBudget: 'ADS_OBJECT.total_budget',
        dailyBudget: 'ADS_OBJECT.daily_budget',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_budget'),
    },
    // audience_status: {
    //   title: 'Audience status',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    age: {
      title: 'Age',
      propertyType: 'attribute',
      cellFormat: 'age',
      dataType: 'string',
      filterField: 'ADS_OBJECT.audience_age',
      valueGetter: {
        value: 'ADS_OBJECT.audience_age',
      },
      menu: getActionMenu('age'),
    },
    gender: {
      title: 'Gender',
      propertyType: 'attribute',
      cellFormat: 'gender',
      dataType: 'string',
      filterField: 'ADS_OBJECT.audience_gender',
      valueGetter: {
        value: 'ADS_OBJECT.audience_gender',
      },
      menu: getActionMenu('gender'),
    },
    audience_location: {
      title: 'Location',
      propertyType: 'attribute',
      cellFormat: 'tag',
      dataType: 'string',
      filterField: 'ADS_OBJECT.audience_location',
      valueGetter: {
        value: 'ADS_OBJECT.audience_location',
      },
      menu: getActionMenu('audience_location'),
    },
    interest_category: {
      title: 'Interest category',
      propertyType: 'attribute',
      cellFormat: 'tag',
      dataType: 'string',
      filterField: 'ADS_OBJECT.audience_interest_category',
      valueGetter: {
        value: 'ADS_OBJECT.audience_interest_category',
      },
      menu: getActionMenu('interest_category'),
    },
    ad_object_bidding_method: {
      title: 'Ad object bidding method',
      propertyType: 'attribute',
      cellFormat: 'text',
      dataType: 'string',
      filterField: 'ADS_OBJECT.automated_ads',
      valueGetter: {
        label: 'ADS_OBJECT.automated_ads',
      },
      staticValue: {
        displayType: 'bidding_method',
      },
      menu: [
        {
          title: 'Turn to manual bidding',
          name: 'Turn to manual bidding',
          icon: 'refresh',
          condition: [
            {
              field: 'ADS_OBJECT.automated_ads',
              operator: 'CONTAIN',
              values: [1],
            },
          ],
          payload: {
            field: {
              value: 'ADS_OBJECT.automated_ads',
            },
            static: {
              value: 0,
            },
          },
        },
        {
          title: 'Turn to Shopee automation',
          name: 'Turn to Shopee automation',
          icon: 'refresh',
          condition: [
            {
              field: 'ADS_OBJECT.automated_ads',
              operator: 'CONTAIN',
              values: [0],
            },
          ],
          payload: {
            field: {
              value: 'ADS_OBJECT.automated_ads',
            },
            static: {
              value: 1,
            },
          },
        },
        {
          title: 'Add Botep automation',
          name: 'Add Botep automation',
          icon: 'epsiloDisable',
          disable: true,
        },
      ],
    },
    ad_object_bidding_price: {
      title: 'Ad object bidding price',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'float',
      filterField: 'ADS_OBJECT.base_bidding_price',
      valueGetter: {
        value: 'ADS_OBJECT.base_bidding_price',
        currency: 'STOREFRONT.currency',
      },
      menu: getActionMenu('ad_object_bidding_price'),
    },
    // placement_premium_rate: {
    //   title: 'Placement premium rate',
    //   propertyType: 'attribute',
    //   cellFormat: 'text',
    //   dataType: 'string',
    //   filterField: '',
    //   valueGetter: {
    //     label: '',
    //   },
    // },
    premium_rate: {
      title: 'Premium rate',
      propertyType: 'attribute',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.bidding_premium',
      valueGetter: {
        value: 'ADS_PLACEMENT.bidding_premium',
      },
      staticValue: {
        typeDisplay: 'text-number',
      },
      menu: getActionMenu('premium_rate'),
    },

    // ---------------------------- metric ----------------------------------//
    keyword_position: {
      title: 'Keyword position',
      propertyType: 'metric',
      cellFormat: 'currency',
      dataType: 'integer',
      filterField: 'ADS_PLACEMENT.position',
      valueGetter: {
        value: 'ADS_PLACEMENT.position',
        subtext: 'ADS_PLACEMENT.position_updated_at',
      },
      menu: getActionMenu('keyword_position'),
    },
    ...CAMPAIGN_DETAIL_COMMON_MAPPING,
  },
};
