import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Hashtag = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <rect x="13" y="9" width="2" height="11" rx="1" transform="rotate(90 13 9)" fill={props.color} />
    <rect x="14" y="5" width="2" height="11" rx="1" transform="rotate(90 14 5)" fill={props.color} />
    <path
      d="M9.84235 3.78827C9.93401 3.32993 10.3365 3 10.8039 3C11.4227 3 11.8868 3.56611 11.7654 4.17289L10.1577 12.2117C10.066 12.6701 9.66354 13 9.19612 13C8.57732 13 8.11322 12.4339 8.23458 11.8271L9.84235 3.78827Z"
      fill={props.color}
    />
    <path
      d="M5.84235 3.78827C5.93401 3.32993 6.33646 3 6.80388 3C7.42268 3 7.88678 3.56611 7.76542 4.17289L6.15765 12.2117C6.06599 12.6701 5.66354 13 5.19612 13C4.57732 13 4.11322 12.4339 4.23458 11.8271L5.84235 3.78827Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Hashtag;
