import * as React from 'react';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import FormControlTemplate from './form-control-template';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { Grid, makeStyles, Omit, SelectProps } from '@material-ui/core';
import { responsiveTextField } from '@ep/insight-ui/lib/epsilo-theme';

/**
 * ff.improve_script_detail_mobile:start
 */
import { ContainerResponsiveContext } from '@eip/next/lib/main';
/**
 * ff.improve_script_detail_mobile:end
 */

const useStyles = makeStyles({
  ...responsiveTextField,
});

export interface TypeOptionSelectChild extends Omit<OptionSelectType, 'dataType'> {
  dataType: string;
}
export interface TypeOperatorSelect {
  [dataType: string]: OptionSelectType[];
}
interface SelectItem extends SelectProps {
  value: string;
  onChange: (e: any) => void;
}
interface SelectionProps {
  label: string;
  operatorOptions: OptionSelectType[];
  objectOptions: OptionSelectType[];
  optionProps: SelectItem;
  operatorProps: SelectItem;
  disabled?: boolean;
}
const GroupSelectionChild = ({
  label,
  objectOptions,
  operatorOptions,
  optionProps,
  operatorProps,
  disabled = false,
}: SelectionProps) => {
  const classes = useStyles();

  /**
   * ff.improve_script_detail_mobile:start
   */
  let containerClass, isMobile;
  if (ff.improve_script_detail_mobile) {
    containerClass = React.useContext(ContainerResponsiveContext).containerClass;
    isMobile = containerClass === 'eres--small';
  }
  /**
   * ff.improve_script_detail_mobile:end
   */

  const handleOnChangeOption = (e) => {
    optionProps.onChange(e);
  };

  const handleChangeOperator = (e) => {
    operatorProps.onChange(e);
  };

  return (
    <div>
      <FormControlTemplate label={label}>
        <Grid container spacing={3}>
          <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 6) : 6}>
            {/* options */}
            <SelectForm
              {...optionProps}
              className={`${classes.inputSelect} md`}
              options={objectOptions}
              value={optionProps.value}
              onChange={handleOnChangeOption}
              placeholder={'Choose'}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={ff.improve_script_detail_mobile ? (isMobile ? 12 : 6) : 6}>
            {/* operator */}
            <SelectForm
              {...operatorProps}
              className={`${classes.inputSelect} md`}
              options={operatorOptions}
              value={operatorProps.value}
              onChange={handleChangeOperator}
              placeholder={'Choose'}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </FormControlTemplate>
    </div>
  );
};

export default GroupSelectionChild;
