import * as React from 'react';
import { useParams, useHistory } from 'react-router';

import { Divider, makeStyles } from '@material-ui/core';

import { SearchFieldCustom } from '@ep/insight-ui/elements/textField/searchField';

import RayListItem from './list-item';
import { rayRequest, RELOAD_LIST_EVENT } from './util';
import { TextFieldCustom } from '@ep/insight-ui/elements/textField/textField';

const defaultServiceId = 'x-ray-monitor';

const useStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    paddingLeft: '12px',
    paddingBottom: '8px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '12px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      border: '4px solid #F8FAFD',
      borderRadius: '16px',
      display: 'none',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      display: 'block',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a0a0a5',
      border: '4px solid #F8FAFD',
    },
    overflowX: 'hidden !important',
    overflowY: 'scroll',
  },
  favoriteLabel: {
    padding: '8px 0',
    color: '#8C98A4',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  favoriteListContainer: {
    display: 'flex',
    flexDirection: 'column',
    paddingRight: '12px',
  },
  searchTerm: {
    padding: '0 12px',
  },
}));

interface RayListProps {
  favoriteFunctions: any;
  favorites: string[];
}

const RayList = ({ favoriteFunctions, favorites = [] }: RayListProps) => {
  const classes = useStyles();
  const { serviceId } = useParams();
  const history = useHistory();

  const [list, setList] = React.useState([]);
  const [searchTerm, setSearchTerm] = React.useState('');

  const idWidth = React.useMemo(() => {
    return String(Math.max(...list.map((item) => item.id), 0)).length;
  }, [list]);

  React.useEffect(() => {
    const requestList = () => {
      const body = {
        action: 'list',
        service: serviceId,
      };
      rayRequest(body)
        .then((res) => {
          setList([{ id: '0', name: 'New' }].concat(res));
        })
        .catch((e) => {
          if (String(e).startsWith('<!DOCTYPE html>') || String(e).startsWith('<html>')) {
            document.write(e);
          }
        });
    };

    if (serviceId) {
      requestList();

      window.addEventListener(RELOAD_LIST_EVENT, requestList);

      return () => {
        window.removeEventListener(RELOAD_LIST_EVENT, requestList);
      };
    } else {
      history.push('/ray/' + defaultServiceId);
    }
  }, [serviceId]);

  const filteredFavorites = list.filter((item) => favorites?.some((fv) => fv == item.id));
  const favoritesRender = filteredFavorites.map((item) => {
    return <RayListItem {...item} key={item.id} idWidth={idWidth} favoriteFunctions={favoriteFunctions} />;
  });
  const listFiltered = React.useMemo(() => {
    return list.filter(
      ({ id, name }) =>
        String(id).includes(searchTerm) || String(name).toLocaleLowerCase().includes(searchTerm?.toLowerCase()),
    );
  }, [list, searchTerm]);

  return (
    <div className={classes.container}>
      <div className={classes.searchTerm}>
        <TextFieldCustom
          defaultValue={searchTerm}
          onChangeText={(v) => setSearchTerm(v)}
          placeholder={'Search script'}
        />
      </div>
      {filteredFavorites?.length > 0 ? (
        <>
          <div className={classes.favoriteLabel}>
            <span>Favorites</span>
          </div>
          <div className={classes.favoriteListContainer}>{favoritesRender}</div>
          <Divider />
        </>
      ) : null}
      <div className={classes.listContainer}>
        {listFiltered
          .filter((item) => !favorites || favorites.every((fv) => fv != item.id))
          .map((item) => (
            <RayListItem {...item} key={item.id} idWidth={idWidth} favoriteFunctions={favoriteFunctions} />
          ))}
      </div>
    </div>
  );
};

export default RayList;
