import * as React from 'react';
import { Dialog, DialogTitle, Modal, makeStyles } from '@material-ui/core';

import ExpansionForm from '@ep/insight-ui/elements/expansion-panels/expansion-form';
import Icon from '@ep/insight-ui/icons/Icon';
import { SwitchIcon } from '../modal/switch';

const useStyles = makeStyles({
  container: {},
  modalContainer: {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: '796px',
    // maxHeight: '80vh',
    // background: '#FFFFFF',
    // borderRadius: '8px',
    // padding: '20px 16px 16px 16px',
    // display: 'flex',
    // flexDirection: 'column',
    // rowGap: '12px',
    // overflowY: 'auto',
    // '&::-webkit-scrollbar-track': {
    //   borderRadius: '10px',
    //   backgroundColor: '#ebebeb',
    //   '-webkit-border-radius': '10px',
    // },
    // '&::-webkit-scrollbar': {
    //   width: '8px',
    // },
    // '&::-webkit-scrollbar-thumb': {
    //   borderRadius: '10px',
    //   backgroundColor: '#ccc',
    //   '-webkit-border-radius': '10px',
    // },
  },
  expanded: {
    color: '#777',
    marginBottom: 0,
    '&.eip1-MuiAccordion-root.Mui-expanded': {
      color: '#253746',
      marginBottom: 0,
      '& > .Mui-expanded': {
        marginBottom: 8,
      },
    },
  },
  featureItem: {
    color: '#253746',
    marginBottom: '.5em',
  },
});

const FeatureFlagsPopup = ({ open, featureFlags, ffEnabled, setOpen }: any) => {
  const classes = useStyles();

  const enabledFeatureItems = Object.keys(featureFlags)
    .filter((key) => featureFlags[key].enabled)
    .reduce((accum, key) => {
      const featureName = `${key}`;

      const featureNotionLink = featureFlags[key].notionLink.map((link, index) => {
        const label = String(link).replace('https://www.notion.so/epsilion/', '');
        return (
          <div key={link + index} className={classes.featureItem}>
            <SwitchIcon defaultChecked={ffEnabled[key]} disabled />
            <a href={link} target={'_blank'} rel="noreferrer nofollow">
              {label}
            </a>
            {' - '}
            {featureName}
          </div>
        );
      });

      return accum.concat(featureNotionLink);
    }, []);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth={true} maxWidth="xl">
      <DialogTitle>Feature flags ({enabledFeatureItems.length})</DialogTitle>
      <div className={classes.modalContainer}>{enabledFeatureItems}</div>
    </Dialog>
  );
};

export default FeatureFlagsPopup;
