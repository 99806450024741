import LRUCache from 'lru-cache';

export const UPSTREAM_CALCULATE = 'upstream-calculate';
export const ETABLE_CELL_UPDATE = 'etable-cell-update';
export const EVT_CHANNEL_CLOSE = '__channel-close';

const cache = new LRUCache<string, BroadcastChannel>({
  max: 500,
  dispose: (value, key, reason) => {
    // the channel instance will not receving the message send by itself
    // temporary solution is create a new instance and post the message
    const tempo = new BroadcastChannel(key);
    tempo.postMessage({ type: EVT_CHANNEL_CLOSE });
    tempo.close();
    setTimeout(() => {
      // the channel will have a delay to close, otherwise it would not receving the message above
      value.close();
    }, 39);
  },
  noDisposeOnSet: true,
  ttlAutopurge: true,
});

export function getChannelUpstreamCalculate(): BroadcastChannel {
  if (!cache.get(UPSTREAM_CALCULATE)) {
    cache.set(UPSTREAM_CALCULATE, new BroadcastChannel(UPSTREAM_CALCULATE));
  }

  return cache.get(UPSTREAM_CALCULATE);
}

export function getChannelCellUpdate(tableId = ''): BroadcastChannel {
  const key = `${ETABLE_CELL_UPDATE}/${tableId}`;
  if (!cache.get(key)) {
    cache.set(key, new BroadcastChannel(key));
  }

  return cache.get(key);
}

export function renewChannel(channelId) {
  console.info('...channel renew', channelId);
  if (!cache.get(channelId)) {
    cache.set(channelId, new BroadcastChannel(channelId));
  }
  return cache.get(channelId);
}

// setInterval(() => {
//   for (const [key, channel] of Object.entries(availableChannel)) {
//     channel.postMessage({ type: EVT_CHANNEL_CLOSE });
//     channel.close();
//   }
//   availableChannel = {};
// }, 30 * 60 * 1000);
