import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';
const Arrowright = (props: SvgIconProps) => (
  <SvgIcon {...props} width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.65914e-05 1.00436L1.68533e-05 6.99564C-0.00113689 7.19398 0.0568177 7.38817 0.166491 7.55345C0.276165 7.71873 0.432588 7.84761 0.615819 7.92367C0.79905 7.99972 1.00079 8.0195 1.19531 7.98049C1.38983 7.94148 1.56832 7.84544 1.70803 7.70461L4.70455 4.70897C4.79816 4.61614 4.87247 4.5057 4.92318 4.38402C4.97389 4.26234 5 4.13182 5 4C5 3.86818 4.97389 3.73766 4.92318 3.61598C4.87247 3.4943 4.79816 3.38386 4.70455 3.29103L1.70803 0.295387C1.56832 0.154562 1.38983 0.0585212 1.19531 0.0195083C1.00079 -0.0195046 0.79905 0.000277961 0.615819 0.0763344C0.432588 0.15239 0.276164 0.281275 0.166491 0.446554C0.0568174 0.611834 -0.00113717 0.80602 1.65914e-05 1.00436Z"
      fill={props.color}
    />
  </SvgIcon>
);

export default Arrowright;
