import { EIP_CONSTANT } from '@eip/next/lib/main';
import { get } from 'lodash';
export const generateNegativeKeyword = (params: any) => {
  const filters = get(params, ['filter', 'filters', 1, 'filters'], []);

  const negativeKeywords = filters.filter((item) => item.field == 'negative_keyword');

  const rows = negativeKeywords.map((item, index) => ({ id: index, name: item.value }));
  const NEGATIVE_KEYWORD_RES = {
    data: {
      headers: ['id', 'name'],
      primaryKeys: ['id'],
      rows,
      pagination: { page: 1, limit: 10, total: negativeKeywords.length },
    },
    message: 'OK',
    code: 200,
  };
  return Promise.resolve(NEGATIVE_KEYWORD_RES);
};
export const COMPACT_NEGATIVE_KEYWORD_CONFIG = {
  title: 'Ad object',
  tableType: 'compact',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA: EIP_CONSTANT.API_HOST.API_GRPC_GATEWAY + '/mop-query/listing/getProductData',
  },
  primaryKeys: ['id'],
  searchHint: 'Looking for products',
  settingType: [
    { type: 'dimension', label: 'Dimension', allowConfig: false },
    { type: 'attribute', label: 'Attribute', allowConfig: false },
    { type: 'metric', label: 'Metric', allowConfig: true },
  ],
  requestIgnoreField: {
    dimension: [],
  },
  system: {
    hiddenComponents: ['timeline', 'settingMenu'],
  },
  mapping: {
    negative_keyword: {
      title: 'Negative keyword',
      propertyType: 'dimension',
      cellFormat: 'text',
      valueGetter: {
        label: 'name',
        value: 'name',
      },
    },
    // ============= ATTRIBUTE =============

    // ============= METRIC =============
    // impression: {
    //   title: 'Impression',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'impression',
    //   valueGetter: {
    //     value: 'impression',
    //   },
    // },
    // click: {
    //   title: 'Click',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'click',
    //   valueGetter: {
    //     value: 'click',
    //   },
    // },
    // ads_item_sold: {
    //   title: 'Ads item sold',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'integer',
    //   filterField: 'ads_item_sold',
    //   valueGetter: {
    //     value: 'ads_item_sold',
    //   },
    // },
    // ads_gmv: {
    //   title: 'Ads GMV',
    //   propertyType: 'metric',
    //   cellFormat: 'currency',
    //   dataType: 'float',
    //   filterField: 'ads_gmv',
    //   valueGetter: {
    //     value: 'ads_gmv',
    //     currency: 'STOREFRONT.currency',
    //   },
    // },
  },
};
