import { apm, AuthContext } from '@eip/next/lib/main';
import NotFound from '@eip/next/src/components/not-found';
import Waitlist from './page/waitlist';
import { CssBaseline } from '@material-ui/core';
import React from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { NotificationGlobalProvider } from '../elements/notifications/notifications';
import { getPagePrefix1 } from './global';
import { useAuth } from './hooks/use-auth';
import { internalMenu, PageWithInternalMenu, publicMenu } from './menu-list';
import Root from './root';
import '../system/app.css';
/**
 * ff.maintenance_page:start
 */
import MaintenanceMobileBlock from './page/maintenance-page';
import { ContainerResponsiveContext, PageContainerResponsive } from '@eip/next/lib/main';
/**
 * ff.maintenance_page:end
 */
import { HelmetMetaData } from '../elements/seo-settings/helmet-data';
import { useWorkspace } from './hooks/use-workspace';
import Ray from './page/ray';

export default function OneApp({ morePage }: { morePage: (arg: any) => any }) {
  apm.init();
  const { publicRoutes: publicRoutes, platform } = React.useMemo(() => {
    let platform = 'mac';
    const isWindows = navigator.userAgent.indexOf('Win') !== -1;
    if (isWindows) {
      platform = 'win';
    }
    return {
      platform,
      publicRoutes: publicMenu(),
    };
  }, []);
  const { token } = useAuth();
  const { workspaces, currentWorkspaceDetail, setCurrentWorkspace, loadedWorkspaces } = useWorkspace(token);
  if (!loadedWorkspaces && token) return null;

  return (
    <PageContainerResponsive>
      <div className={'os-' + platform}>
        <Root>
          <HelmetMetaData title={'Epsilo | Execution hub for e-commerce teams'}></HelmetMetaData>
          <CssBaseline />
          <Switch>
            {workspaces.length === 0 && token ? (
              <>
                <Route path={'/waitlist'} component={Waitlist} />
                <Redirect to="/waitlist" />
              </>
            ) : null}
            <Route exact path="/" component={() => <Redirect to="/login" />} />
            {publicRoutes.map((i) => (
              <Route key={i.url} exact path={i.url} component={i.page} />
            ))}
            <Route path="*">
              <AuthenticatedRoutes
                morePage={morePage}
                workspaces={workspaces}
                currentWorkspaceDetail={currentWorkspaceDetail}
                setCurrentWorkspace={setCurrentWorkspace}
                loadedWorkspaces={loadedWorkspaces}
              />
            </Route>
          </Switch>
        </Root>
      </div>
    </PageContainerResponsive>
  );
}

function AuthenticatedRoutes({ morePage, ...props }: any) {
  const { token } = useAuth();
  const history = useHistory();
  if (token) {
    return <PageWithPermissions morePage={morePage} {...props} />;
  } else {
    window.location.href = history.createHref({
      pathname: '/login',
      search: `?redirect_url=${encodeURIComponent(window.location.href)}`,
    });
    return null;
  }
}

function flatternMenuHierarchy(navList: any[]) {
  let items: any[] = [];

  if (!navList || navList.length === 0) return items;

  for (const menu of navList) {
    items = items.concat(menu);
    items = items.concat(flatternMenuHierarchy(menu.children));
  }

  return items;
}
function PageWithPermissions({
  morePage,
  workspaces,
  currentWorkspaceDetail,
  setCurrentWorkspace,
  loadedWorkspaces,
}: {
  morePage: (arg: any) => any;
  workspaces: any[];
  currentWorkspaceDetail: any;
  setCurrentWorkspace: React.Dispatch<React.SetStateAction<any>>;
  loadedWorkspaces: boolean;
}) {
  let isMobile;
  if (ff.maintenance_page) {
    const { containerClass } = React.useContext(ContainerResponsiveContext);
    isMobile = containerClass === 'eres--small';
  }

  const { userSettings } = useAuth();

  const permByCode = userSettings.permissions?.byCode;

  const canInternalAccess = () => {
    return userSettings.profile.userEmail.indexOf('@epsilo.io') > -1;
  };

  const canAccess = (requiredPermCodes: string[]) => {
    const accessable = requiredPermCodes.every((code: string) =>
      code === '__INTERNAL__' ? canInternalAccess() : permByCode![code] && permByCode![code].length > 0,
    );
    return accessable;
  };

  const privateRoutes = React.useMemo(() => flatternMenuHierarchy(internalMenu(canAccess, morePage)), []);

  if (!permByCode) return <p style={{ marginLeft: '1em' }}>Loading your profile and settings...</p>;

  return (
    <AuthContext.Provider
      value={{
        canAccess,
        canInternalAccess,
        workspaces,
        currentWorkspaceDetail,
        setCurrentWorkspace,
        loadedWorkspaces,
      }}
    >
      <NotificationGlobalProvider>
        <Switch>
          <Route path="/mobile-underconstruction" component={MaintenanceMobileBlock} />
          <Route path="/ray/:serviceId/:id" component={Ray} />
          <Route path="/ray/:serviceId" component={Ray} />
          <Route path="/ray" component={Ray} />
          <Redirect from="/next/dashboard" to={getPagePrefix1()} />
          {privateRoutes
            .filter((i) => i.access !== false)
            .map((menuItem) => (
              <Route
                key={menuItem.url}
                exact={menuItem.exact === false ? false : true}
                path={menuItem.url}
                component={menuItem.page}
              />
            ))}
          <Route component={PageWithInternalMenu(NotFound)} />
        </Switch>
      </NotificationGlobalProvider>
    </AuthContext.Provider>
  );
}
