import { TableBackboneContext, useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';
import * as React from 'react';
import { DropdownMenuDataType } from '../list-control/menu-list/menu-list';
import ExpansionTable from '../table/expansion-table';
import HeaderRight from '../table/table-actions/header-right';
import TableActions from '../table/table-actions/table-actions';
import ArenaEDashboard from './edashboard-charts/area-chart';
import DotEDashboard from './edashboard-charts/dot-chart';
// import GaugeEDashboard from './edashboard-charts/gauge-chart';
import LineChartEDashboard from './edashboard-charts/line-chart';
import LineEDashboard from './edashboard-charts/line-new-chart';
import ScatterEDashboard from './edashboard-charts/scatter-chart';
import StackedChartEDashboard from './edashboard-charts/stacked-chart';
import TreeMapEDashboard from './edashboard-charts/treemap-chart';
// import WaterfallEDashboard from './edashboard-charts/waterfall-chart';
import { ContainerResponsiveContext } from '@eip/next/lib/main';
import { filterValueAddon } from '@ep/insight-ui/elements/table/table-addons/filter-value';
import { produce } from 'immer';
import { debounce } from 'lodash';
import LoadingIcon from '../list-control/spinners/icon-spinner';

export type IStatusChartBB = 'loading' | 'error' | 'success' | undefined;
export enum TypeEDashBoard {
  INTRADAY = 'intraday',
  METRIC = 'metric',
  BILLING = 'billing',
  AREA = 'area',
  LINE = 'line',
  DOT = 'dot',
  SCATTER = 'scatter',
  WATERFALL = 'waterfall',
  TREEMAP = 'treemap',
  GAUGE = 'gauge',
}
export interface IConfigTable {
  apiRequest: any;
  configuration: any;
  callback?: any;
}
interface DashboardContainerProps {
  config: IConfigTable;
  changeConfiguration: (value: any) => void;
  tableBackboneHook: any;
  tableContext: any;
  selectedRows?: any[];
}
const useStyles = makeStyles(() => ({
  toggle: {},
  headerMobile: {
    '& .dropdown': {
      display: 'none',
    },
  },
}));
const DashboardContainer: React.FunctionComponent<DashboardContainerProps> = ({
  config,
  changeConfiguration,
  tableBackboneHook,
  tableContext,
  selectedRows,
}: DashboardContainerProps) => {
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  // const isMobile = containerClass === 'eres--small';
  const isMobile = false;
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const enhanceConfig = React.useMemo(() => {
    return produce(config, (draft) => {
      draft.addons = {
        ...draft.addons,
        ...filterValueAddon,
      };
    });
  }, []);
  const backbone = tableBackboneHook(enhanceConfig, changeConfiguration);

  const [rows, setRows] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);

  const rowData = React.useMemo(() => {
    return rows;
    // return _.get(rows, [0, 'rows'], []);
  }, [rows]);
  React.useEffect(() => {
    backbone.registerNotification({
      success: (msg: string) => enqueueSnackbar(msg, { variant: 'success' }),
      error: (msg: string) => enqueueSnackbar(msg, { variant: 'error' }),
    });
    backbone.setGridApi({
      grid: {
        paginationSetPageSize: (limit) => undefined,
        refreshServerSideStore: refreshData,
      },
      column: {},
    });
    backbone.init();
    window.setTimeout(() => {
      window.requestAnimationFrame(() => refreshData());
    }, 100);
  }, []);

  const refreshData = React.useCallback(
    debounce(
      () => {
        setIsLoading(true);
        backbone
          .getRows()
          .then((value) => {
            setRows(value);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log('Get row fail', err);
          });
      },
      50,
      { trailing: true },
    ),
    [],
  );

  // const rows = SUMMARY_RES.data.rows;
  const titleTable = backbone.getConfig('title');
  const tableType = backbone.getConfig('tableType');

  const statusChart = backbone.getStatus('table');

  const generateTable = (typeTable: TypeEDashBoard | undefined) => {
    switch (typeTable) {
      case 'intraday':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <LineChartEDashboard data={rowData[0]} statusChart={'success'} />
              </Grid>
            </>
          </ExpansionTable>
        );

      case 'billing':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <StackedChartEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      case 'area':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <ArenaEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      case 'line':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <LineEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      case 'dot':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <DotEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      case 'scatter':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <ScatterEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      // case 'waterfall':
      //   return (
      //     <ExpansionTable
      //       label={titleTable}
      //       defaultExpanded={true}
      //       className={classes.toggle}
      //       headerRight={<HeaderRight />}
      //     >
      //       <>
      //         <TableActions />
      //         <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
      //           <Box height={'500px'} width={'100%'}>
      //             <WaterfallEDashboard data={rowData[0]} statusChart={statusChart} />
      //           </Box>
      //         </Grid>
      //       </>
      //     </ExpansionTable>
      //   );

      case 'treemap':
        return (
          <ExpansionTable
            label={titleTable}
            defaultExpanded={true}
            className={classes.toggle}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions />
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <Box height={'500px'} width={'100%'}>
                  <TreeMapEDashboard data={rowData[0]} statusChart={statusChart} />
                </Box>
              </Grid>
            </>
          </ExpansionTable>
        );

      // case 'gauge':
      //   return (
      //     <ExpansionTable
      //       label={titleTable}
      //       defaultExpanded={true}
      //       className={classes.toggle}
      //       headerRight={<HeaderRight />}
      //     >
      //       <>
      //         <TableActions />
      //         <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
      //           <Box height={'500px'} width={'100%'}>
      //             <GaugeEDashboard data={rowData[0]} statusChart={statusChart} />
      //           </Box>
      //         </Grid>
      //       </>
      //     </ExpansionTable>
      //   );

      default: {
        const orderMetric = backbone.getOptions('metric');
        const headers: Array<any> = backbone.getHeaders();
        const settingType = backbone.getConfig('settingType');
        const rows = [];

        headers.forEach((headerItem) => {
          const currentMetric = rowData.find((item) => item.metric == headerItem.field);
          if (currentMetric) rows.push(currentMetric);
        });
        const MenuOptions: DropdownMenuDataType[] = [
          ...(ff.calendar_timeline_cohort
            ? [
                {
                  title: 'Cohort',
                  name: 'cohort',
                  icon: 'calendar',
                  colorStartIcon: '#253746',
                  onClick: () => backbone.changeVisibility('calendar', true),
                },
              ]
            : []),
          {
            title: 'Properties',
            name: 'properties',
            icon: 'properties',
            colorStartIcon: '#253746',
            onClick: () => backbone.changeVisibility('property', true),
          },
          {
            title: 'Change chart type',
            name: 'change-chart-type',
            icon: 'task',
            // iconSize: '14px',
            colorStartIcon: '#253746',
            disable: true,
            onClick: () => undefined,
          },
          {
            title: 'Change currency',
            name: 'currencySwitch',
            icon: 'changeCurrency',
            // iconSize: '18px',
            colorStartIcon: '#253746',
            onClick: () => backbone.changeVisibility('currencySwitch', true),
          },
          {
            title: 'Export',
            name: 'export',
            icon: 'export',
            // iconSize: '18px',
            colorStartIcon: '#253746',
            onClick: () => undefined,
            disable: true,
          },
        ];
        return (
          <ExpansionTable
            label={<Box display={'flex'}>{titleTable}</Box>}
            defaultExpanded={true}
            className={`${classes.toggle} ${isMobile ? classes.headerMobile : null}`}
            headerRight={<HeaderRight />}
          >
            <>
              <TableActions dataMenu={MenuOptions} />{' '}
              {isLoading ? (
                <Box display={'flex'} mt={'1em'} mb={'1em'}>
                  <LoadingIcon /> <strong>One moment please...</strong>
                </Box>
              ) : null}
              <Grid container spacing={3} style={{ height: '100%', marginTop: '15px' }}>
                <LineChartEDashboard data={{ rows: rows }} statusChart={'success'} />
              </Grid>
            </>
          </ExpansionTable>
        );
      }
    }
  };
  return <tableContext.Provider value={backbone}>{generateTable(tableType)}</tableContext.Provider>;
};

export const makeDashboard = ({
  config = {
    apiRequest: {},
    configuration: {},
    callback: {},
  },
  changeConfiguration = (config: any) => undefined,
  tableBackboneHook = useTableBackbone,
  tableContext = TableBackboneContext,
  selectedRows = [],
}: {
  config: IConfigTable;
  changeConfiguration?: (config: any) => void;
  tableBackboneHook?: any;
  tableContext?: any;
  selectedRows?: any[];
}) => {
  return (
    <SnackbarProvider maxSnack={3} anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <DashboardContainer
        config={config}
        changeConfiguration={changeConfiguration}
        tableBackboneHook={tableBackboneHook}
        tableContext={tableContext}
        selectedRows={selectedRows}
      />
    </SnackbarProvider>
  );
};
