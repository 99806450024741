import * as React from 'react';

import { TreemapChart } from '@carbon/charts-react';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wrapperChart: {
    pointerEvents: 'none',
    cursor: 'pointer',
  },
}));

export interface TreemapDataInterface {
  name: string;
  children: {
    name: string;
    value: number;
    showLabel?: boolean;
  }[];
}

interface IPropsSimplifiedTreemap {
  data: TreemapDataInterface[];
  height: string;
}

const SimplifiedTreemap = ({ data, height }: IPropsSimplifiedTreemap) => {
  const classes = useStyles();

  const hashColors = {};
  [...data]
    .sort((a, b) => b.children[0].value - a.children[0].value)
    .forEach((item, index) => {
      hashColors[item.name] = colors.charts[index];
    });
  const options = {
    getFillColor(group) {
      return hashColors[group];
    },
    height,
    legend: {
      enabled: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  const treemapData = React.useMemo(() => {
    return data.map((el) => ({
      ...el,
      children: el.children.map((ele) => ({
        ...ele,
        showLabel: false,
      })),
    }));
  }, []);

  return (
    <Box className={classes.wrapperChart}>
      <TreemapChart data={treemapData} options={options} />
    </Box>
  );
};

export default SimplifiedTreemap;
