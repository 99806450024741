import { Backdrop, Box, createStyles, makeStyles, Theme, Modal } from '@material-ui/core';
import * as React from 'react';
import ContactInformationForm from '../input-form/form-templates/form-groups/getintouch-form/getintouch-form';
import PurchaseForm from '../input-form/form-templates/form-groups/purchase-form';
import { subscription_Head, subscription_Body } from './tables/mockup';
import SubscriptionPlanTable from './tables/subscription-plan-table';
import {
  createCellButton,
  createDataObjectButtons,
  createDataObjectInfoSubscription,
  IObjectButtons,
  IObjectInfoSubscription,
} from './tables/subscription-plan-table/subscription-components';
import { EnumSelectedColumn } from './tables/subscription-plan-table/subscription-plan-table';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    form: {
      position: 'relative',
      zIndex: theme.zIndex.drawer + 2,
    },
    wraperDialog: {
      position: 'fixed',
      zIndex: theme.zIndex.drawer + 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    popupModal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '480px',
      background: '#FFFFFF',
      borderRadius: '4px',
    },
  }),
);

const listInfo = () => {
  const companySize = [
    { id: '1', text: '1-100', label: '1-100', value: '1-100' },
    { id: '2', text: '100-500', label: '100-500', value: '100-500' },
  ];
  const questions = [
    {
      id: 'test',
      text: 'Test',
      label: 'Test',
      value: 'test',
    },
    {
      id: 'test_test',
      text: 'Test Test',
      label: 'Test Test',
      value: 'test_test',
    },
  ];

  return {
    companySize,
    questions,
  };
};

const DEFAULT_VALUES = {
  name: '',
  email: '',
  company_size: '',
  questions: '',
};

function PlanTables() {
  const classes = useStyles();

  const { companySize, questions } = listInfo();
  const [date, setDate] = React.useState<{ dateFrom: string; dateTo: string }>({
    dateFrom: '14/08/2021',
    dateTo: '19/08/2021',
  });
  const [switchPay, setSwitchPay] = React.useState<boolean>(false);
  const [pageLength, setPageLength] = React.useState<number>(50);

  const [dialog, setDialog] = React.useState<boolean>(false);
  const [openGetInTouch, setOpenGetInTouch] = React.useState<boolean>(false);

  const handleClose = () => {
    setDialog(false);
  };
  const handleToggle = () => {
    setDialog(!dialog);
  };

  const handleCloseGetInTouch = () => {
    setOpenGetInTouch(false);
  };
  const handleGetInTouch = () => {
    setOpenGetInTouch(!dialog);
  };

  const dateProps = {
    value: date,
    defaultFormatReturn: 'DD/MM/YYYY',
    formatDateView: 'MMMM DD, YYYY',
    onChange: setDate,
  };
  const payToggleProps = {
    checked: switchPay,
    onChange: (e) => setSwitchPay(e.target.checked),
  };
  const paginationProps = {
    pageLength: pageLength,
    onChange: setPageLength,
  };

  const ButtonsObject: IObjectButtons = createDataObjectButtons(
    createCellButton('Your plan', () => undefined, 'secondary'),
    createCellButton('Upgrade', handleToggle),
    createCellButton('Get in touch', handleGetInTouch),
  );

  const infosSubscription: IObjectInfoSubscription = createDataObjectInfoSubscription(
    'Start to explore ecommerce potential',
    'For teams who want to work together',
    'Stellar your e-commerce organization',
  );

  return (
    <div>
      <SubscriptionPlanTable
        label={'Pricing plans'}
        dataHead={subscription_Head}
        dataBody={subscription_Body}
        popularColumn={EnumSelectedColumn.Team}
        selectedColumn={EnumSelectedColumn.Starter}
        dateProps={dateProps}
        payToggleProps={payToggleProps}
        paginationProps={paginationProps}
        buttons={ButtonsObject}
        infos={infosSubscription}
      />
      <Modal open={dialog} onClose={handleClose}>
        <Box className={classes.popupModal}>
          <PurchaseForm value={{ fullname: '', card: '', month_year: '', cvc: '' }} onClose={handleClose} />
        </Box>
      </Modal>
      <Modal open={openGetInTouch} onClose={handleCloseGetInTouch}>
        <Box className={classes.popupModal}>
          <ContactInformationForm
            initialValues={DEFAULT_VALUES}
            companySizeList={companySize}
            questionsList={questions}
            onClose={handleCloseGetInTouch}
          />
        </Box>
      </Modal>
    </div>
  );
}

export default PlanTables;
