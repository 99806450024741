/**
 * Workflow provider
 * ===
 * When a page have workflow configuration, that workflow will be loaded
 * Workflow heavily relied on page url for constructing context.
 * One of the important context info is entityId.
 * entityId will have 2 form: draft entityid vs. system entityId
 *  * Draft entityId only exists in workflow page where user input is not totaly sync into MOP database.
 *    * Eg. when user create a script, but not click submit, the draft entityId will store the user input
 *  * System entityId will be a link into MOP database
 *    * an entity may require context variables for querying database. Eg marketplace code: SHOPEE, LAZADA
 */

import { useLog, usePage } from '@eip/next/lib/main';
import {
  workflowList as defaultWorkflowList,
  WorkflowContext,
  useWorkflow,
} from '@ep/insight-ui/system/backbone/workflow-backbone';
import produce from 'immer';
import { get, set } from 'lodash';
import qs from 'qs';
import * as React from 'react';
import { generatePath, useHistory, useLocation, useParams } from 'react-router';
import * as tableConfig from '@ep/insight-ui/system/block/etable/table-config';
import { getPageUrn } from '@ep/insight-ui/eo2/global';

const log = useLog('dbf:workflow-provider');

export function makeWorkflowProvider(name: keyof typeof workflowList, workflowList = defaultWorkflowList) {
  const workflow = workflowList[name] ? workflowList[name](tableConfig) : null;

  log('workflow', name);

  return function WorkflowProvider({ children }) {
    const { toggleTitleEditable } = usePage();
    React.useLayoutEffect(() => {
      if (workflow === null) {
        toggleTitleEditable(false);
      } else {
        toggleTitleEditable(true);
      }
    }, []);
    return (
      <WorkflowContext.Provider value={workflow}>
        {workflow ? <RouterWorkflow>{children}</RouterWorkflow> : children}
        {/* <GlobalRequestLoading /> */}
      </WorkflowContext.Provider>
    );
  };
}

function RouterWorkflow({ children }) {
  const history = useHistory();
  const location = useLocation();
  const workflow = useWorkflow();
  const params = useParams<{ systemPageId: string; entityId: string; userPageId }>();
  const { currentPage, updatePage, updateTitle, isDraft } = usePage();

  log('page params', params);
  React.useEffect(() => {
    const pageId = params.userPageId;
    const unregisters = [];
    if (!params.entityId) {
      const search = qs.parse(location.search.replace(/^\?/, ''));
      workflow
        .init({ entityId: null, pageId: params.systemPageId, parentId: currentPage.parentId, context: search })
        .then(({ entityId: nextEntityId }) => {
          if (isDraft(nextEntityId)) {
          } else if (nextEntityId) {
            history.replace(getPageUrn(`/page/${params.systemPageId}/${nextEntityId}`));
          }
        });
      unregisters.push(
        workflow.on('data_change:entityId', async (nextEntityId) => {
          if (!isDraft(nextEntityId) && nextEntityId !== params.entityId) {
            const data = await workflow.getAllData();
            const query: Record<string, any> = data.entityQuery || {};
            const uri = generatePath('/page/:parentId/:entityId', {
              parentId: params.systemPageId,
              entityId: nextEntityId,
            });
            history.replace(getPageUrn(uri) + (Object.keys(query).length > 0 ? '?' + qs.stringify(query) : ''));
          }
        }),
      );
    } else {
      const entityId = params.entityId;
      const search = qs.parse(location.search.replace(/^\?/, ''));
      workflow
        .init({
          entityId: entityId,
          pageId,
          parentId: currentPage.parentId,
          _draftData: get(currentPage, 'properties.draftData'),
          context: search,
        })
        .then(({ title }) => {
          // if (isDraft(entityId) && title) updateTitle(title);
          updateTitle(title || '');
        });
    }

    unregisters.push(
      workflow.on('submit', (data, error) => {
        if (error) {
          log(error);
          window.alert('Error! ' + error.message);
        } else {
          window.alert('Success');
          let redirectUrl = getPageUrn(
            generatePath('/page/:parentId/:entityId', {
              parentId:
                currentPage.blockType == 'user_persona'
                  ? get(currentPage, ['content', 0]) || currentPage.blockEid
                  : currentPage.blockEid,
              entityId: data.result.entityId,
            }),
          );
          if (data.result.marketplace) {
            redirectUrl += '?marketplace=' + data.result.marketplace;
          }
          // FIXME: support generic query
          if (data.result.type) redirectUrl += '&type=' + data.result.type;
          history.push(redirectUrl);
        }
      }),
    );

    return () => {
      unregisters.forEach((unregister) => unregister());
    };
  }, [currentPage.blockEid, params.entityId, params.systemPageId, params.userPageId]);

  // if (!params.entityId) return null;

  return children;
}
