export enum CellFormatType {
  SELECT = 'select',
  MULTI_SELECT = 'multiSelect',
  ETABLE_FILTER = 'eTableFilter',
  COMPACT_TABLE = 'compactTable',
  COMPACT_SELECT_TABLE = 'compactSelectTable',
  COLOR_PICKER = 'colorPicker',
}

export enum CellFormatDataType {
  ARRAY = 'array',
}
