export * from '@ep/insight-ui/elements/table/table-helper';
import { cellFormat } from '@ep/insight-ui/elements/table/table-helper-cell-format';
import TextFormatFormula from './format/formula-text-format';
import CurrencyFormatFormula from './format/formula-currency-format';
import PivotFormatFormula from './format/formula-pivot-format';

const overrideCellFormat = {
  ...cellFormat,
  textFormatFormula: TextFormatFormula,
  currencyFormatFormula: CurrencyFormatFormula,
  pivotFormatFormula: PivotFormatFormula,
};

export { overrideCellFormat as cellFormat };
