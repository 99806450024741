import * as React from 'react';
import { makeStyles, Popover, Box, withStyles, Grid, Button } from '@material-ui/core';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import SelectionComponent from '../dropdown-properties/selection-component';
import { TableBackboneContext, TableBackboneContextType } from '@ep/insight-ui/system/backbone/table-backbone';
import { get } from 'lodash';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '9px 18px',
    borderStyle: 'dashed',
    '&:active': {
      color: '#FFFFFF',
      backgroundColor: colors.action.subtlePressed,
    },
  },
  popover: {
    '& .eip1-MuiPopover-paper': {
      background: ' #FFFFFF',
      boxShadow: ' 0px 6px 12px rgb(140 152 164 / 25%)',
      padding: 0,
      '&::-webkit-scrollbar': {
        backgroundColor: 'transparent',
        width: '16px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: '#babac038',
        marginLeft: 20,
      },
      '&::-webkit-scrollbar-track:hover': {
        backgroundColor: '#babac038',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#babac0',
        borderRadius: '16px',
        border: '5px solid #F8FAFD',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        backgroundColor: '#a0a0a5',
        border: '4px solid #f4f4f4',
      },
      '&::-webkit-scrollbar-button ': { display: 'none' },
    },
  },
  sortDialog: {
    // maxWidth: 'unset',
    padding: '12px 16px 6px 16px',
    maxWidth: 'calc(100vw - 60%)',
    // width: 'fit-content',
    minWidth: '432px',
    maxHeight: '60vh',
  },
  currencyDialog: {
    padding: '12px 16px 8px 16px',
    maxWidth: 'calc(100vw - 60%)',
    minWidth: '333px',
    maxHeight: '60vh',
  },
  period: {
    padding: '12px 16px 8px 16px',
    maxWidth: 'calc(100vw - 60%)',
    minWidth: '250px',
    maxHeight: '60vh',
  },
  activeButton: {
    background: '#0369C7',
    color: '#fff !important',
    '&:hover': {
      backgroundColor: '#338ED8',
    },
  },
  defaultButton: {
    '&:hover': {
      backgroundColor: '#E4E7E9',
    },
  },
  viewByHeader: {
    padding: '8px 7px',
    marginBottom: '8px',
  },

  groupCheckList: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    maxHeight: '90vh',
    overflowY: 'auto',
    // maxWidth: 640,
  },
  formGroupSubmit: {
    marginTop: '8px',
    marginBottom: '8px',
    padding: '0px 16px',
  },
}));
export function ButtonOptionPivotDialog({ handleClose }) {
  const classes = useStyles();

  const backbone = React.useContext(TableBackboneContext) as TableBackboneContextType;
  const [isPivotMode, setIsPivotMode] = React.useState(() => backbone.getConfig('tableMode') === 'pivot');
  const [selectedItems, setSelectedItems] = React.useState(() => {
    const pivot = backbone.getConfig('pivot');
    const pivotColumnValue = get(pivot, 'columnValue', []);
    return pivotColumnValue.map((i) => ({
      id: i,
    }));
  });

  const [selectedMetricItems, setSelectedMetricItems] = React.useState(() => {
    const pivot = backbone.getConfig('pivot');
    const pivotColumnValue = get(pivot, 'metricColumnValue', []);
    return pivotColumnValue.map((i) => ({
      id: i,
    }));
  });

  const handleUpdateSelection = React.useCallback((type, newSelected) => {
    setSelectedItems(newSelected);
  }, []);

  const handleUpdateMetricSelection = React.useCallback((type, newSelected) => {
    setSelectedMetricItems(newSelected);
  }, []);

  const handleSubmit = React.useCallback(() => {
    backbone.changeConfig('tableMode', isPivotMode ? 'pivot' : 'default');
    backbone.changeConfig('pivot', {
      columnValue: selectedItems.map((i) => i.id),
      metricColumnValue: selectedMetricItems.map((i) => i.id),
    });
    handleClose();
  }, [isPivotMode, selectedItems, selectedMetricItems]);

  const dimensionOption = React.useMemo(() => {
    return backbone.getOptions('dimension');
  }, []);

  const metricOption = React.useMemo(() => {
    const currentMetrics = backbone.getConfig('metric');
    return backbone.getOptions('metric').filter((el) => {
      return currentMetrics.includes(el.id);
    });
  }, []);

  const handleTogglePivotMode = (event) => {
    setIsPivotMode(event.target.checked);
  };

  return (
    <div className={classes.period}>
      <Grid container style={{ columnGap: '16px', display: 'flex', flexWrap: 'nowrap' }}>
        {dimensionOption.length > 0 && (
          <Grid container className={clsx(classes.groupCheckList, `eip_action_pivot`)}>
            <SelectionComponent
              label={
                <Box>
                  Pivot
                  <SwitchIcon title="Enable pivot" checked={isPivotMode} onChange={handleTogglePivotMode} />
                </Box>
              }
              type={'pivot'}
              disabled={!isPivotMode}
              enableCenterStyles={false}
              items={dimensionOption}
              selectedItems={selectedItems}
              handleUpdateSelection={handleUpdateSelection}
              onReorder={undefined}
              selectLimit={1}
              componentType={''}
              disabledReorderDebounce={false}
              disabledOptions={[]}
              singleSelectColumns={[]} //please fill list id option u want radio here, be like ['campaign_status', 'campaign_daily_budget', 'campaign_total_budget']
            />
          </Grid>
        )}
        {metricOption.length > 0 && (
          <Grid container className={clsx(classes.groupCheckList, `eip_action_pivot`)}>
            <SelectionComponent
              label={<Box>Metric</Box>}
              type={'pivot'}
              disabled={!selectedItems.length || !isPivotMode}
              enableCenterStyles={false}
              items={metricOption}
              selectedItems={selectedMetricItems}
              handleUpdateSelection={handleUpdateMetricSelection}
              onReorder={undefined}
              selectLimit={1}
              componentType={''}
              disabledReorderDebounce={false}
              disabledOptions={[]}
              singleSelectColumns={[]} //please fill list id option u want radio here, be like ['campaign_status', 'campaign_daily_budget', 'campaign_total_budget']
            />
          </Grid>
        )}
      </Grid>
      <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
        <Grid item>
          <Button className={classes.button} onClick={handleClose}>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>
            Apply
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

export const SwitchIcon = withStyles((theme) => ({
  root: {
    width: 30,
    height: '100%',
    padding: 0,
    margin: '0 8px',
    '& .eip1-MuiSwitch-switchBase': {
      transform: 'translateX(2px)',
      top: 1,
    },
    '& .eip1-MuiIconButton-root:hover': {
      background: '#fff',
    },
  },
  switchBase: {
    borderRadius: '50%',
    padding: 1,
    '&$checked': {
      top: 1,
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#006EC6',
        opacity: 1,
        border: 'none',
      },
    },
  },

  switchBaseCustom: {
    borderRadius: '50%',
    padding: 1,
    '&$checked': {
      top: 1,
      transform: 'translateX(14px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#006EC6',
        opacity: 1,
        border: 'none',
      },
    },
  },
  thumb: {
    width: 12,
    height: 12,
  },
  track: {
    top: 1,
    width: 30,
    height: 16,
    borderRadius: 26 / 2,
    border: 'none',
    backgroundColor: '#C2C7CB',
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: props.isCustom ? classes.switchBaseCustom : classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
