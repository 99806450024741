import { eipRequest as request, useLog } from '@eip/next/lib/main';
import { cloneDeep } from 'lodash';
import moment from 'moment';
import { get } from 'lodash';

/**
 * ff.mass_operation_management:start
 */
// Will remove when API is available
import { MASS_OPERATION_DASHBOARD_DATA } from './demo-data/mass-operation-dashboard-data';
/**
 * ff.mass_operation_management:end
 */

const log = useLog('lib:eDashboard-apirequest');

const transformChartData = (response: any, metric: string) => {
  let sum = 0;
  const valueSeries = [];
  if (response.data[metric]) {
    response.data[metric].forEach((v, i) => {
      if (moment().isAfter(response.date[i], 'YYYY-MM-DD')) {
        sum += v;
      }
      valueSeries.push({
        date: response.date[i],
        value: v,
      });
    });
  }
  return { sum, valueSeries };
};

const getDashboardData = async (
  params: {
    metric: string[];
    filter: any;
    currency: string;
  },
  endpoint: {
    GET_METRIC_DATA: string;
    GET_CHART_DATA: string;
  },
  dateRange: {
    lastTimeStart: string;
    lastTimeEnd: string;
    thisTimeStart: string;
    thisTimeEnd: string;
  },
) => {
  return new Promise((resolve, reject) => {
    const lastTimeFilter = [
      {
        field: 'created_datetime',
        operator: '>=',
        value: dateRange.lastTimeStart,
      },
      {
        field: 'created_datetime',
        operator: '<=',
        value: dateRange.lastTimeEnd,
      },
    ];

    const thisTimeFilter = [
      {
        field: 'created_datetime',
        operator: '>=',
        value: dateRange.thisTimeStart,
      },
      {
        field: 'created_datetime',
        operator: '<=',
        value: dateRange.thisTimeEnd,
      },
    ];

    const lastTimeParams = cloneDeep(params);
    if (lastTimeParams.filter) {
      lastTimeParams.filter.filters = [...lastTimeParams.filter.filters, ...lastTimeFilter];
    } else {
      lastTimeParams.filter = {
        combinator: 'and',
        filters: lastTimeFilter,
      };
    }

    const thisTimeParams = cloneDeep(params);
    if (thisTimeParams.filter) {
      thisTimeParams.filter.filters = [...thisTimeParams.filter.filters, ...thisTimeFilter];
    } else {
      thisTimeParams.filter = {
        combinator: 'and',
        filters: thisTimeFilter,
      };
    }

    let thisTimeParamsOneDay;
    if (ff.request_daily_payload) {
      thisTimeParamsOneDay = cloneDeep(thisTimeParams);
      if (thisTimeParams.from == thisTimeParams.to) {
        delete thisTimeParamsOneDay.timing;
      }
    }
    let lastTimeParamsOneDay;
    if (ff.request_daily_payload) {
      lastTimeParamsOneDay = cloneDeep(lastTimeParams);
      if (lastTimeParams.from == lastTimeParams.to) {
        delete lastTimeParamsOneDay.timing;
      }
    }

    Promise.all([
      ...(ff.request_daily_payload
        ? [
            request.post(
              endpoint.GET_METRIC_DATA,
              thisTimeParams.from != thisTimeParams.to ? thisTimeParams : thisTimeParamsOneDay,
            ),
          ]
        : [request.post(endpoint.GET_METRIC_DATA, thisTimeParams)]),
      ...(ff.request_daily_payload
        ? [
            request.post(
              endpoint.GET_METRIC_DATA,
              lastTimeParams.from != lastTimeParams.to ? lastTimeParams : lastTimeParamsOneDay,
            ),
          ]
        : [request.post(endpoint.GET_METRIC_DATA, lastTimeParams)]),
      request.post(endpoint.GET_CHART_DATA, thisTimeParams),
      request.post(endpoint.GET_CHART_DATA, lastTimeParams),
    ])
      .then((responses) => {
        let data = [];
        if (responses[0].data) {
          data = responses[0].data.map((m, index) => {
            const thisTimeData = transformChartData(responses[2], m.code);
            const lastTimeData = transformChartData(responses[3], m.code);
            return {
              metric: m.code,
              title: m.title,
              unit: m.unit,
              value: m.value,
              lastValue: get(responses, [1, 'data', index, 'value'], lastTimeData.sum),
              forwardMeaning: m.forwardMeaning,
              this_time_value: thisTimeData.sum,
              last_time_value: lastTimeData.sum,
              this_time_value_serries: thisTimeData.valueSeries,
              last_time_value_serries: lastTimeData.valueSeries,
            };
          });
        }
        log('[Success] getDashboard', { rows: data });
        resolve({
          data: { rows: data },
        });
      })
      .catch((error) => {
        log('[Fail] getDashboard', error);
        reject(error);
      });
  });
};

const getHourlyParam = (dateFrom: string, dateTo: string, calendarCohort?: any) => {
  const calStartDate = moment(dateFrom, 'YYYY-MM-DD');
  const calEndDate = moment(dateTo, 'YYYY-MM-DD');
  return {
    lastTimeStart: (calendarCohort ? moment(calendarCohort.dateFrom) : moment(calStartDate).add(-1, 'day'))
      .startOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    lastTimeEnd: (calendarCohort ? moment(calendarCohort.dateTo) : moment(calEndDate).add(-1, 'day'))
      .endOf('day')
      .format('YYYY-MM-DD HH:mm:ss'),
    thisTimeStart: moment(calStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    thisTimeEnd: moment(calEndDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
  };
};

const getDateRangeParam = (dateFrom: string, dateTo: string, calendarCohort?: any) => {
  const calStartDate = moment(dateFrom, 'YYYY-MM-DD');
  const calEndDate = moment(dateTo, 'YYYY-MM-DD');
  const diffDays = calEndDate.diff(calStartDate, 'day');
  return {
    lastTimeStart: calendarCohort
      ? calendarCohort.dateFrom
      : moment(calStartDate)
          .subtract(diffDays + 1, 'day')
          .format('YYYY-MM-DD'),
    lastTimeEnd: calendarCohort
      ? calendarCohort.dateTo
      : moment(calEndDate)
          .subtract(diffDays + 1, 'day')
          .format('YYYY-MM-DD'),
    thisTimeStart: moment(calStartDate).format('YYYY-MM-DD'),
    thisTimeEnd: moment(calEndDate).format('YYYY-MM-DD'),
  };
};

export const getMetricData = async (
  params: {
    metric: string[];
    filter: any;
    currency: string;
    from: string;
    to: string;
    hiddenFilter?: any;
  },
  endpoint: {
    GET_METRIC_DATA: string;
    GET_CHART_DATA: string;
  },
) => {
  let dateRange;
  let calendarCohort;
  if (ff.calendar_timeline_cohort) {
    calendarCohort = get(params, 'cohortDateRange', null);
  }
  const reqParams = {
    ...params,
    ...params.hiddenFilter,
  };
  if (params.from === params.to) {
    if (ff.calendar_timeline_cohort) {
      dateRange = getHourlyParam(params.from, params.to, calendarCohort);
    } else {
      dateRange = getHourlyParam(params.from, params.to);
    }
    reqParams.timing = 'hourly';
  } else {
    if (ff.calendar_timeline_cohort) {
      dateRange = getDateRangeParam(params.from, params.to, calendarCohort);
    } else {
      dateRange = getDateRangeParam(params.from, params.to);
    }
  }
  if (ff.calendar_timeline_cohort && reqParams.calendarCohort) {
    delete reqParams.calendarCohort;
  }
  return getDashboardData(reqParams, endpoint, dateRange);
};

export const getIntradayData = async (
  params: {
    metric: string[];
    filter: any;
    currency: string;
    from: string;
    to: string;
    hiddenFilter?: any;
  },
  endpoint: {
    GET_METRIC_DATA: string;
    GET_CHART_DATA: string;
  },
) => {
  log('intraday', params);
  const reqParams = {
    ...params,
    ...params.hiddenFilter,
  };
  const dateRange = getHourlyParam(params.from, params.to);
  return getDashboardData(reqParams, endpoint, dateRange);
};

export const getMassOperationData = async (
  params: {
    metric: string[];
    filter: any;
    currency: string;
    from: string;
    to: string;
    hiddenFilter?: any;
  },
  endpoint: {
    GET_METRIC_DATA: string;
    GET_CHART_DATA: string;
  },
) => {
  log('intraday', params);
  const reqParams = {
    ...params,
    ...params.hiddenFilter,
  };
  return Promise.resolve({ data: { rows: MASS_OPERATION_DASHBOARD_DATA } });
};
