import React from 'react';

export const useAlignPopover = (container: HTMLDivElement) => {
  const [marginLeft, setMarginLeft] = React.useState(0);

  React.useEffect(() => {
    if (!container) return;
    const root = document.getElementById('root') as HTMLDivElement;
    const rootWidth = root.offsetWidth;
    // const scrollbarWidth= window.innerWidth-rootWidth;
    // const isVisibleScroll = root.scrollHeight > window.innerHeight;
    const containerWidth = container.offsetWidth;
    const containerOffsetLeft = container.getBoundingClientRect();

    const marginLeft = rootWidth - (containerWidth + containerOffsetLeft.x);
    setMarginLeft(marginLeft);
  }, [container]);

  return {
    marginLeft,
  };
};
