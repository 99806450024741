import * as React from 'react';
import * as _ from 'lodash';
import moment from 'moment';
import clsx from 'clsx';

import { Box, Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import Icon from '@ep/insight-ui/icons/Icon';

import WrapperFormat from './wrapper-format';
import { enhanceCellAction } from '../table-helper';

const useStyles = makeStyles(() => ({
  format: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 'normal',
    fontSize: '14px',
    display: 'flex',
    flexDirection: 'column',
  },
  display: {
    display: 'none',
  },
  styleText: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subtext: {
    fontSize: '10px',
    color: '#8C98A4',
    lineHeight: '12px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  hashtag: {
    display: 'flex',
    fontSize: '10px',
    color: '#006EC6',
    lineHeight: '12px',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  label: {
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    display: 'flex',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
  },
  wrapperIcon: {
    '&.expand': {
      display: 'flex',
      justifyContent: 'center',
      width: '8px',
      height: '100%',
      '& svg': {
        height: '10px',
      },
    },
  },
  icon: {
    width: '100%',
    height: '100%',

    '&.status': {
      borderRadius: '100%',
    },
    '&.arrowdown': {},
    '&.arrowright': {},
  },
  height100: {
    height: '100%',
  },
  alignItemsStart: {
    alignItems: 'flex-start',
  },
  syncStatusText: {
    display: 'flex',
    columnGap: '2px',
    alignItems: 'center',
  },
  tooltip: {
    background: '#253746',
    fontSize: '10px',
    lineHeight: '12px',
    top: '100px',
    left: '200px',
    '& .tooltip-icon': {
      marginRight: '4px',
    },
  },
  subtextContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '4px',
  },
}));

const EnableDisableFormat = (props: any) => {
  const classes = useStyles();
  const value = _.get(props, 'value', '');
  const isGrouped = _.get(props, 'isGrouped', false);
  const typeTable = _.get(props, 'typeTable', '');
  const cellAction = _.get(props, 'cellAction', []);
  const node = _.get(props, 'node', null);
  const hashtag = _.get(props, ['value', 'hashtag'], '');
  const edot = _.get(props, ['value', 'edot'], []);
  const subtextValue = _.get(props, ['value', 'subtext'], '');

  const backbone = React.useContext(TableBackboneContext);

  const valueField = _.get(backbone, ['config', 'mapping', props.field, 'valueGetter', 'value'], '');
  const valueSyncStatus = _.get(props, ['data', 'data_status', valueField, 'status'], null);
  const valueSyncUpdatedAt = _.get(props, ['data', 'data_status', valueField, 'updated_at'], null);
  const valueSyncErrorMessage = _.get(props, ['data', 'data_status', valueField, 'error_message'], '');

  const subtext = React.useMemo(() => {
    if (valueSyncStatus == 2) {
      return (
        <Box className={classes.subtextContainer}>
          <Tooltip title={valueSyncErrorMessage} classes={{ tooltip: classes.tooltip }} placement="bottom">
            <Box className={classes.syncStatusText}>
              <Icon type={'circleStatus'} colorIcon={'#D4290D'} />
              <span>
                Sync failed at:{' '}
                {moment(valueSyncUpdatedAt).add(moment().utcOffset(), 'minutes').format('YYYY-MM-DD HH:mm:ss')}
              </span>
            </Box>
          </Tooltip>
          {subtextValue ? (
            <>
              <span>|</span>
              <span>{subtextValue}</span>
            </>
          ) : null}
        </Box>
      );
    }

    return <span>{subtextValue}</span>;
  }, [props, valueSyncStatus, valueSyncUpdatedAt]);

  return (
    <WrapperFormat
      typeTable={typeTable}
      cellAction={enhanceCellAction(cellAction, edot, props.field, backbone)}
      node={node}
      value={value}
      isGrouped={isGrouped}
    >
      <Grid container alignItems={'center'} className={clsx(classes.format, classes.height100)}>
        <Grid
          container
          alignItems={'flex-start'}
          direction={'column'}
          wrap={'nowrap'}
          className={clsx(classes.styleText, classes.height100)}
        >
          <Grid item xs={12} className={classes.height100}>
            <span className={classes.hashtag}>{hashtag}</span>
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            {!!value.value ? 'Enable' : 'Disable'}
          </Grid>
          <Grid item xs={12} className={classes.height100}>
            <Box className={classes.subtext}>{subtext}</Box>
          </Grid>
        </Grid>
      </Grid>
    </WrapperFormat>
  );
};
export default EnableDisableFormat;
