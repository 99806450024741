import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const WatchMe = (props: SvgIconProps) => (
  <SvgIcon {...props} width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14 0H1.99999C1.26666 0 0.666656 0.6 0.666656 1.33333V9.33333C0.666656 10.0667 1.26666 10.6667 1.99999 10.6667H5.33332V12H10.6667V10.6667H14C14.7333 10.6667 15.3267 10.0667 15.3267 9.33333L15.3333 1.33333C15.3333 0.6 14.7333 0 14 0ZM14 9.33333H1.99999V1.33333H14V9.33333Z"
      fill={`${props.color}`}
    />
  </SvgIcon>
);

export default WatchMe;
