import * as React from 'react';
import moment from 'moment';
import { get, groupBy, uniq } from 'lodash';
import clsx from 'clsx';

import { Avatar, Box, Button, makeStyles, Popover } from '@material-ui/core';

import Icon from '@ep/insight-ui/icons/Icon';
import { EIP_CONSTANT } from '@eip/next/lib/main';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';
import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import TextareaWithPlugins from '../textField/textarea-with-plugins';
import StyledTooltip from '../tooltip/styled';

const useStyles = makeStyles((theme) => ({
  actionFeedback: {
    // width: '14px',
    // height: '14px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    columnGap: '2px',
    '& span': {
      color: '#344e73',
      fontSize: '11px',
    },
    '&:hover': {
      background: '#344e731a',
    },
    '&.disabled': {
      pointerEvent: 'none',
      '& span': {
        color: '#344e7380',
      },
      cursor: 'default',
    },
    borderRadius: '8px',
    padding: '2px 4px',
  },
  commentPopupContainer: {
    width: '625px',
    '& textarea': {
      resize: 'vertical',
    },
    '& h3': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  commentHistory: {
    display: 'flex',
    columnGap: '8px',
    margin: '8px 0',
  },
  commentDetail: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '4px',
    '& p': {
      margin: 0,
    },
  },
  historyContainer: {
    maxHeight: '50vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      backgroundColor: 'transparent',
      width: '12px',
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    /* Handle */
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#babac0',
      border: '4px solid #F8FAFD',
      borderRadius: '16px',
      display: 'none',
    },
    '&:hover::-webkit-scrollbar-thumb': {
      display: 'block',
    },
    /* Handle on hover */
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#a0a0a5',
      border: '4px solid #F8FAFD',
    },
  },
  popover: {},
  loadMore: {
    textAlign: 'center',
    cursor: 'pointer',
    marginBottom: '8px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '4px',
  },
  errorMessage: {
    color: '#d4290d',
    fontSize: '12px',
  },
}));

function stringToColor(string: string) {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

const CommentDetail = ({ data }) => {
  const classes = useStyles({ drawerWidth: null });
  const avatar = (data.user?.split('@')?.[0] || '')
    .split('.')
    .slice(0, 2)
    .map((el) => el[0])
    .join('')
    .toUpperCase();

  const [expand, setExpand] = React.useState(false);
  if (!data) return null;
  const { comment, createdAt, user } = data;

  return (
    <div className={classes.commentHistory}>
      <Avatar style={{ backgroundColor: stringToColor(avatar) }}>{avatar}</Avatar>
      <div className={classes.commentDetail}>
        <span>
          <span>{user}</span>
          <span style={{ color: '#8C98A4' }}> - {moment.utc(createdAt).local().fromNow()}</span>
        </span>
        <p>
          <span style={{ color: '#8C98A4', whiteSpace: 'pre-wrap' }}>{comment.slice(0, 100)}</span>
          {comment?.length > 100 && !expand ? (
            <span>
              ...{' '}
              <span style={{ cursor: 'pointer' }} onClick={() => setExpand(true)}>
                Show more
              </span>
            </span>
          ) : null}
          {expand ? (
            <>
              <span style={{ color: '#8C98A4', whiteSpace: 'pre-wrap' }}>{comment.slice(100)}</span>
              <span style={{ cursor: 'pointer' }} onClick={() => setExpand(false)}>
                {' '}
                Show less
              </span>
            </>
          ) : null}
        </p>
      </div>
    </div>
  );
};

const CommentPopup = ({ onClose, commentState, onSubmit, feedbacks }: any) => {
  const classes = useStyles({ drawerWidth: null });

  const [input, setInput] = React.useState('');
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const historyRef = React.useRef(null);

  const history = React.useMemo(() => {
    return get(feedbacks, [commentState], [])
      .filter((fb) => Boolean(fb['user_feedback.f_feedback']))
      .map((data) => {
        return {
          user: data['user_feedback.created_by'],
          comment: data['user_feedback.f_feedback'],
          createdAt: data['user_feedback.created_at'],
          id: data['user_feedback.id'],
        };
      });
  }, [commentState, feedbacks]);

  const handleSubmit = async () => {
    try {
      setErrorMessage('');
      if (!input) {
        setErrorMessage('Text is required.');
        return;
      }
      setIsSubmitting(true);
      const res = await onSubmit(commentState, input);
      if (!res?.success && res?.code != 200) {
        setErrorMessage(res?.message);
      }
    } catch (e) {
      setErrorMessage(e?.response?.message || e?.message);
    }
    setIsSubmitting(false);
  };

  React.useEffect(() => {
    if (historyRef.current) {
      historyRef.current.scrollTop = historyRef.current.scrollHeight;
    }
  }, [historyRef.current]);

  return (
    <div className={classes.commentPopupContainer}>
      <h3>Add a new comment</h3>
      <div className={classes.historyContainer} ref={historyRef}>
        {history.map((data) => {
          return <CommentDetail key={data.id} data={data} />;
        })}
      </div>
      <TextareaWithPlugins onChange={setInput} value={input} minRows={7} maxRows={7} />
      <span className={classes.errorMessage}>{errorMessage}</span>
      <Box display={'flex'} justifyContent={'flex-end'} pb={'0.5em'}>
        <Button onClick={onClose}>Cancel</Button>
        <div style={{ width: 12 }} />
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isSubmitting}>
          Submit
        </Button>
      </Box>
    </div>
  );
};

const FeedbackActions = ({ pageId }: { pageId: string }) => {
  const classes = useStyles({ drawerWidth: null });
  const [commentPopupAnchorEl, setCommentPopupAnchorEl] = React.useState(null);
  const [commentState, setCommentState] = React.useState<'like' | 'dislike' | 'discuss'>('discuss');
  const [feedbacks, setFeedbacks] = React.useState({
    like: [],
    dislike: [],
    discuss: [],
  });
  const [loading, setLoading] = React.useState(true);
  const timeoutOpenCommentPopup = React.useRef(null);

  const requestFeedbacks = () => {
    const payload = {
      dimensions: ['user_feedback', 'sandbox_page_configuration'],
      attributes: [
        'user_feedback.f_feedback',
        'user_feedback.id',
        'user_feedback.page_id',
        'sandbox_page_configuration.page_id',
        'user_feedback.section_title',
        'user_feedback.workspace_id',
        'user_feedback.created_at',
        'user_feedback.created_by',
        'user_feedback.updated_at',
        'user_feedback.updated_by',
        'user_feedback.f_like',
      ],
      metrics: [],
      pagination: {
        page: 1,
        limit: 999,
      },
      from: '2024-01-04',
      to: '2024-01-04',
      sort: [
        {
          field: 'user_feedback.created_at',
          sort: 'ASC',
        },
      ],
      hiddenFilter: {
        currency: 'USD',
      },
      currency: 'USD',
      isSummary: true,
      filter: {
        combinator: 'AND',
        filters: [
          {
            field: 'sandbox_page_configuration.page_id',
            operator: 'is',
            value: pageId,
          },
        ],
      },
    };

    setLoading(true);

    request
      .post(EIP_CONSTANT.API_HOST.API_DATA_CENTER + '/v2/query.jsp?namespace=operational', payload)
      .then((res) => {
        const result = produceQueryResult({
          ...res,
          data: {
            ...res.data,
            masterData: res.masterData,
            masterDataPrimaryKey: res.masterDataPrimaryKey,
          },
        });
        const groupByLike = groupBy(result.rows, 'user_feedback.f_like');

        setFeedbacks({
          like: get(groupByLike, [1], []),
          dislike: get(groupByLike, [0], []),
          discuss: get(groupByLike, ['null'], []).filter((fb) => Boolean(fb['user_feedback.f_feedback'])),
        });
      })
      .catch((e) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    requestFeedbacks();
  }, [pageId]);

  const handleCloseCommentPopup = () => {
    setCommentPopupAnchorEl(null);
  };
  const handleOnMouseDown = (event, state: 'like' | 'dislike' | 'discuss' = 'like') => {
    event.persist();
    setCommentState(state);
    timeoutOpenCommentPopup.current = setTimeout(() => {
      setCommentPopupAnchorEl(event.target);
    }, 500);
  };
  const handleOnMouseUp = () => {
    clearTimeout(timeoutOpenCommentPopup.current);
  };
  const handleClickDiscuss = (event) => {
    event.persist();
    setCommentPopupAnchorEl(event.target);
    setCommentState('discuss');
  };
  const handleSubmitFeedback = (state, comment = '') => {
    const payload = {
      action: 'user_feedback',
      like: state === 'like' ? 1 : state === 'dislike' ? 0 : null,
      feedback: comment,
      'section-title': '',
      'page-id': pageId,
    };
    return request.post(EIP_CONSTANT.API_HOST.API_SANDBOX_MANAGER + '/admin/apis.jsp', payload).then((res) => {
      if (res?.success || res?.code == 200) {
        handleCloseCommentPopup();
        requestFeedbacks();
      }
    });
  };

  const getStateCount = (state) => {
    return get(feedbacks, [state, 'length'], 0);
  };

  const getTooltip = (state) => {
    const list = uniq(get(feedbacks, [state], []).map((fb) => fb['user_feedback.created_by']));
    const suffix =
      state === 'like' ? 'liked this page' : state === 'dislike' ? 'disliked this page' : 'commented on this page';
    if (list.length === 0)
      return ['like', 'dislike'].includes(state)
        ? 'Press and hold to provide your feedback'
        : 'Click to provide your feedback';

    if (list.length === 1)
      return (
        <span>
          {list[0]} <span style={{ fontWeight: 'normal' }}>{suffix}</span>
        </span>
      );
    return (
      <span>
        {list.slice(0, -1).join(', ') + ' and ' + list[list.length - 1]}{' '}
        <span style={{ fontWeight: 'normal' }}>{suffix}</span>
      </span>
    );
  };

  return (
    <Box display={'flex'} alignItems={'center'} sx={{ columnGap: '4px', marginLeft: '4px' }}>
      <StyledTooltip title={loading ? 'Loading' : getTooltip('like')}>
        <Box
          className={clsx(classes.actionFeedback, loading ? 'disabled' : '')}
          onMouseDown={(e) => handleOnMouseDown(e, 'like')}
          onMouseUp={handleOnMouseUp}
          onClick={() => handleSubmitFeedback('like')}
        >
          <Icon type={`ic/carbon:thumbs-up-filled/${loading ? '#344e7380' : 'blue6'}`} />
          {getStateCount('like') > 0 ? <span>{getStateCount('like')}</span> : null}
        </Box>
      </StyledTooltip>
      <StyledTooltip title={loading ? 'Loading' : getTooltip('dislike')}>
        <Box
          className={clsx(classes.actionFeedback, loading ? 'disabled' : '')}
          onMouseDown={(e) => handleOnMouseDown(e, 'dislike')}
          onMouseUp={handleOnMouseUp}
          onClick={() => handleSubmitFeedback('dislike')}
        >
          <Icon type={`ic/carbon:thumbs-down-filled/${loading ? '#344e7380' : 'blue6'}`} />
          {getStateCount('dislike') > 0 ? <span>{getStateCount('dislike')}</span> : null}
        </Box>
      </StyledTooltip>
      <StyledTooltip title={loading ? 'Loading' : getTooltip('discuss')}>
        <Box className={clsx(classes.actionFeedback, loading ? 'disabled' : '')} onClick={handleClickDiscuss}>
          <Icon type={`ic/ri:discuss-fill/${loading ? '#344e7380' : 'blue6'}`} />
          {getStateCount('discuss') > 0 ? <span>{getStateCount('discuss')}</span> : null}
        </Box>
      </StyledTooltip>
      <Popover
        open={Boolean(commentPopupAnchorEl)}
        anchorEl={commentPopupAnchorEl}
        onClose={handleCloseCommentPopup}
        className={classes.popover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CommentPopup
          onClose={handleCloseCommentPopup}
          commentState={commentState}
          onSubmit={handleSubmitFeedback}
          feedbacks={feedbacks}
          requestFeedbacks={requestFeedbacks}
        />
      </Popover>
    </Box>
  );
};

export default FeedbackActions;
