import SelectForm from '@ep/insight-ui/elements/form-control/select-form/select-form';
import HeaderList from '@ep/insight-ui/elements/list-control/header-list/header-list';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { getIconStatus, IValueStatusTextFormat } from '@ep/insight-ui/elements/table/format/status-text-format';
import { IconType } from '@ep/insight-ui/icons/Icon';
import { Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import * as React from 'react';
import GroupButtonSubmit from '../group-button-submit';

/** Style */
const useStyles = makeStyles((theme) => ({
  header: {
    paddingLeft: '0px',
  },
  dialog: {
    width: '240',
    height: '150',
    marginTop: '10px',
  },
  select: {
    '& .eip1-MuiFormControl-root': {
      '& .eip1-MuiInputBase-root': {
        padding: '3px 6px',
        height: '32px',
      },
    },
    width: '100%',
  },
}));

// const options: OptionSelectType[] = [
//   {
//     label: 'ONGOING',
//     value: 'ONGOING',
//     icon: getIconStatus('ONGOING').iconType,
//   },
//   {
//     label: 'PAUSED',
//     value: 'PAUSED',
//     icon: getIconStatus('PAUSED').iconType,
//   },
//   {
//     label: 'PROCESSING',
//     value: 'PROCESSING',
//     icon: getIconStatus('PROCESSING').iconType,
//   },
//   {
//     label: 'ERROR',
//     value: 'ERROR',
//     icon: getIconStatus('ERROR').iconType,
//   },
//   {
//     label: 'DRAFT',
//     value: 'DRAFT',
//     icon: getIconStatus('DRAFT').iconType,
//   },
//   {
//     label: 'PUBLISHING',
//     value: 'PUBLISHING',
//     icon: getIconStatus('PUBLISHING').iconType,
//   },
//   {
//     label: 'SCHEDULED',
//     value: 'SCHEDULED',
//     icon: getIconStatus('SCHEDULED').iconType,
//   },
//   {
//     label: 'SUSPENDED',
//     value: 'SUSPENDED',
//     icon: getIconStatus('SUSPENDED').iconType,
//   },
//   {
//     label: 'RECOVERING',
//     value: 'RECOVERING',
//     icon: getIconStatus('RECOVERING').iconType,
//   },
//   {
//     label: 'ENDED',
//     value: 'ENDED',
//     icon: getIconStatus('ENDED').iconType,
//   },
// ];
type Props = {
  data: {
    value: IValueStatusTextFormat;
    node: any;
  };
  title: string;
  payload?: any;
  onSubmit: (value: any) => Promise<any>;
  onLoad: (param: {
    type: string;
    dimension: string;
    marketplaceCode: string;
    toolCode?: string;
    countryCode?: string;
  }) => Promise<void>;
  anchorEl: any;
  setAnchorEl: (value: any) => void;
};
const StatusEditor = (props: Props) => {
  console.log('Status props', props);
  const { data, title = '', payload, onSubmit, onLoad, anchorEl, setAnchorEl } = props;
  const classes = useStyles();
  const defaultValue = React.useMemo(() => {
    if (data.value.status) return data.value.status;
    return '';
  }, [data.value.status, anchorEl]);
  const [currentStatus, setCurrentStatus] = React.useState<IconType | string>(defaultValue);

  const [options, setOptions] = React.useState<OptionSelectType[]>([]);

  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const initOptions = async () => {
    if (onLoad) {
      const marketplaceField = get(payload, ['rowPicker', 'marketplaceCode']);
      const res = await onLoad({
        type: 'status',
        ...payload.static,
        marketplaceCode: get(data.node, ['data', marketplaceField], null),
      });
      console.log('status options ==>', res);

      if (res.success) {
        // TODO: set initial options
        const arr: OptionSelectType[] = res.data.map((item) => ({
          label: item.text,
          value: item.id,
          icon: getIconStatus(item.id).iconType,
        }));
        setOptions(arr);
      }
    }
  };

  React.useEffect(() => {
    initOptions();
  }, [onLoad]);

  const handleChangeStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCurrentStatus(e.target.value);
  };
  const handleClosed = () => {
    setAnchorEl(null);
  };
  const handleSubmit = async () => {
    const item: OptionSelectType = options.find((m) => m.value == currentStatus);
    if (item) {
      const valueTmp = JSON.parse(JSON.stringify(data.value));
      valueTmp.status = item.value;
      valueTmp.label = item.label;
      setIsSubmitting(true);
      const response: any = await onSubmit({ value: valueTmp, row: data.node.data });
      setIsSubmitting(false);
      if ((response && response.success) || !response) {
        handleClosed();
      }
    } else {
      handleClosed();
    }
  };

  return (
    <div style={{ padding: '0 10px', minWidth: '320px' }}>
      <HeaderList title={title} variant="h4" className={classes.header} />
      <div className={classes.dialog}>
        <SelectForm options={options} value={currentStatus} className={classes.select} onChange={handleChangeStatus} />
        <GroupButtonSubmit onCancel={handleClosed} onSubmit={handleSubmit} isSubmitting={isSubmitting} />
      </div>
    </div>
  );
};
export default StatusEditor;
