import * as React from 'react';

import { makeStyles } from '@material-ui/core';
import { MonacoEditor } from '@ep/insight-ui/system/block/etable/etable-config/script-editor/monaco-editor';

const useStyles = makeStyles(() => ({
  container: {
    width: '40%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    borderLeft: ({ theme }) => (theme === 'vs-dark' ? '1px solid #fff' : '1px solid #0000001f'),
    background: ({ theme }) => (theme === 'vs-dark' ? '#1E1E1E' : '#fff'),
    color: ({ theme }) => (theme === 'vs-dark' ? '#fff' : '#000000DE'),
    '& .monaco-scrollable-element.vs-dark': {
      borderBottom: '1px solid #fff',
    },
  },
  codeEditor: {
    height: '100%',
  },
  alerts: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    columnGap: '4px',
    rowGap: '4px',
    minHeight: '32px',
    marginLeft: '10px',
    borderTop: '1px solid #0000001f',
    '& a': {
      color: ({ theme }) => (theme === 'vs-dark' ? '#fff' : '#A21313'),
    },
  },
}));

type RayMainLogProps = {
  alerts: { emailMessages: string[]; slackMessages: string[] };
  log: string;
  theme: 'vs' | 'vs-dark';
};

const RayMainLog = ({ alerts, log, theme }: RayMainLogProps) => {
  const classes = useStyles({ theme });

  const editor = React.useRef(null);

  return (
    <div className={classes.container}>
      <ErrorBoundary>
        <div className={classes.codeEditor}>
          <MonacoEditor
            ref={editor}
            language={'text'}
            onCodeChange={(content) => {
              // autoSave(content, idRef.current);
            }}
            readOnly={true}
            value={log}
            automaticLayout={true}
            suggestions={[]}
            options={{
              minimap: {
                enabled: false,
              },
            }}
            minimap={{ enabled: false }}
            scrollBeyondLastLine={false}
            lineNumbers={'off'}
            glyphMargin={false}
            folding={false}
            wordWrap={true}
            theme={theme}
          />
        </div>
        <div className={classes.alerts}>
          {alerts.slackMessages.map((msg, index) => {
            return (
              <a href={msg} target="_blank" rel="noreferrer" key={index}>
                Alert {index + 1}
              </a>
            );
          })}
          {alerts.emailMessages.map((msg, index) => {
            return (
              <a href={msg} key={index}>
                Email {index + 1}
              </a>
            );
          })}
        </div>
      </ErrorBoundary>
    </div>
  );
};

class ErrorBoundary extends React.Component {
  state = { hasError: false };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

export default RayMainLog;
