import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import Typography from '@ep/insight-ui/elements/text-style/Typography';
import Icon from '@ep/insight-ui/icons/Icon';
import { Box, FormGroup, MenuItem, TextField, InputAdornment } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { get } from 'lodash';
import * as React from 'react';
import { colors, searchStyle } from '@ep/insight-ui/lib/epsilo-theme';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import { ITag } from '@ep/insight-ui/elements/table/format/tag-format';
/**
 * ff.add_css_identifier_to_new_release_components:start
 */
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
/**
 * ff.add_css_identifier_to_new_release_components:end
 */
import { tagsData } from '@ep/insight-ui/elements/inline-edit/cell/tag-editor/mocks/tag-data';
/**
 * ff.ui_ux_update_tag_item:start
 */
import { DropdownCellSubItem } from '@ep/insight-ui/elements/table/dropdown-cell-subitem';
/**
 * ff.ui_ux_update_tag_item:end
 */

/** Style */
const useStyles = makeStyles((theme) => ({
  tagSelected: {
    height: '24px',
    background: '#F2f3f4',
    borderRadius: '4px',
    padding: '2px 12px 2px 8px',
    whiteSpace: 'nowrap',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  chipIcon: {
    marginLeft: '8px',
    cursor: 'pointer',
  },
  formControlTag: {
    '& .eip1-MuiInputBase-formControl': {
      height: '32px',
      marginBottom: '8px',
    },
  },
  tagItem: {
    padding: '2px 8px',
    borderRadius: '4px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    paddingRight: '0px',
    '& .eip1-MuiMenuItem-gutters': {
      display: 'flex',
      paddingRight: '5px',
    },
  },
}));
type IValueTagEditorFormat = {
  value: string;
};
type Props = {
  data: {
    value: IValueTagEditorFormat;
    node: any;
  };
  payload: any;
  onSubmit: (value: any) => Promise<any>;
  setAnchorEl: (value: any) => void;
  actionSubItem: any;
};

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },

    '& .eip1-MuiOutlinedInput-root': {
      paddingLeft: '10px',
      '& fieldset': {
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: colors.border.highlight,
        borderWidth: '1px',
      },
    },
  },
})(TextField);

const TagEditor = (props: Props) => {
  const { data, onSubmit, actionSubItem = [], setAnchorEl } = props;
  const value = _.get(props, ['data', 'value'], null);
  let node, gridApi;
  if (ff.ui_ux_update_tag_item) {
    node = _.get(props, ['data', 'node'], null);
    gridApi = _.get(props, ['data', 'gridApi'], null);
  }
  let column;
  if (ff.ui_ux_update_tag_item) {
    column = _.get(props, ['payload', 'column'], null);
  } else {
    column = _.get(props, ['data', 'columnField'], null);
  }

  const tagsValue: Array<ITag> = column ? tagsData[column] : [];
  const classes = useStyles();
  const [options, setOptions] = React.useState<ITag[]>(tagsValue);
  const [inputValue, setInputValue] = React.useState('');
  const optionsRender: Itag[] = React.useMemo(() => {
    return options.filter((option) =>
      inputValue.trim() ? option.label.toLocaleLowerCase().includes(inputValue.toLocaleLowerCase()) : true,
    );
  }, [options, inputValue]);
  const currentValue = React.useMemo(() => tagsValue.find((el) => el.value == value), [value]);

  const handleClosed = () => setAnchorEl(null);

  const handleSelectedTag = async (item) => {
    const valueSelected = item.value;
    const response: any = await onSubmit({ value: { value: valueSelected }, row: data.node.data });
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };
  const handleRemovedTag = async () => {
    const response: any = await onSubmit({ value: { value: '' }, row: data.node.data });
    if ((response && response.success) || !response) {
      handleClosed();
    }
  };
  const itemHoverIndex = React.useRef(null);
  const timeout = React.useRef(null);
  const moveCard = (dragIndex, hoverIndex) => {
    if (itemHoverIndex.current !== hoverIndex) {
      itemHoverIndex.current = hoverIndex;
      timeout.current = setTimeout(() => {
        timeout.current = null;
      }, 50);
    } else if (!timeout.current) {
      const tmpItems = [...options];
      const dragCard = tmpItems[dragIndex];
      tmpItems.splice(dragIndex, 1);
      tmpItems.splice(hoverIndex, 0, dragCard);
      setOptions(tmpItems);
    }
    return timeout.current;
  };
  let backboneContext, titleTable, titleTableClass, titleColumnClass;
  if (ff.add_css_identifier_to_new_release_components) {
    backboneContext = React.useContext(TableBackboneContext) as TableBackbone.TableBackboneContextType;
    titleTable = backboneContext.getConfig('title');
    titleTableClass = titleTable.replace(/\s/g, '_').toLowerCase();
    titleColumnClass = column.replace(/\s/g, '_').toLowerCase();
  }
  return (
    <div style={{ width: '240px' }}>
      <FormGroup>
        <CssTextField
          InputLabelProps={{ shrink: false }}
          variant="outlined"
          color="secondary"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          InputProps={
            currentValue
              ? {
                  startAdornment: (
                    <span className={classes.tagSelected} style={{ backgroundColor: currentValue.color }}>
                      {currentValue.label}
                      <Icon
                        type="deleteChip"
                        size="10px"
                        colorIcon="icon.default"
                        className={`${classes['w-unset']} ${classes['h-unset']} ${classes.chipIcon}`}
                        onClick={() => {
                          handleRemovedTag();
                        }}
                      />
                    </span>
                  ),
                }
              : {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon type={'search'} size="12px" />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }
          }
          className={classes.formControlTag}
        />
        <div style={{ width: '100%' }}>
          {optionsRender &&
            optionsRender.map((item, index) => (
              <ListTagRender
                key={index}
                index={index}
                moveCard={moveCard}
                field={item.value}
                className={
                  ff.add_css_identifier_to_new_release_components
                    ? `eip_elevation_table_${titleTableClass} eip_column_${titleColumnClass} eip_option_${titleColumnClass}`
                    : ''
                }
              >
                <div
                  key={index}
                  className={classes.tagItem}
                  style={{
                    display: 'inline-block',
                    width: '100%',
                    paddingLeft: '0',
                    margin: '0.2em 0',
                    backgroundColor: item.color,
                  }}
                >
                  {ff.ui_ux_update_tag_item ? (
                    <StyledFormControlLabel
                      item={item}
                      key={index}
                      inputValue={inputValue}
                      handleSelectedTag={handleSelectedTag}
                    >
                      <div className={'dropdownSubItem'} role={'menubar'}>
                        {actionSubItem.length > 0 && (
                          <DropdownCellSubItem cellAction={actionSubItem} props={{ value, node, gridApi }} />
                        )}
                      </div>
                    </StyledFormControlLabel>
                  ) : (
                    <StyledFormControlLabel
                      item={item}
                      key={index}
                      inputValue={inputValue}
                      handleSelectedTag={handleSelectedTag}
                    />
                  )}
                </div>
              </ListTagRender>
            ))}
        </div>
      </FormGroup>
    </div>
  );
};
export default TagEditor;

const useStylesListTag = makeStyles(() => ({
  titleTag: {
    display: 'flex',
    ...(ff.ui_ux_update_tag_item
      ? {
          alignItem: 'center',
          width: '260px',
        }
      : {}),
  },
}));

interface ListTagLabel {
  item?: ITag;
  value?: any;
  handleSelectedTag?: void;
  inputValue?: string;
  items?: OptionSelectType[];
  index?: any;
  children;
}

const StyledMenuItem = withStyles((theme) => ({
  root: {
    fontWeight: 400,
    borderRadius: '4px',
    width: '100%',
    display: 'block',
    justifyContent: ' space-between',
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
    '&:hover': {
      // borderColor: colors.border.highlight,
      // backgroundColor: colors.action.secondaryHovered,
      color: colors.text.default,
    },
    '&:not:first-child': {
      marginBottom: '6px',
    },
    '& .eip1-MuiPaper-rounded': {
      borderRadius: '4px',
      marginTop: '15px',
    },
  },
}))(MenuItem);

const StyledFormControlLabel = withStyles(() => ({
  root: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    '&:hover': {},
    paddingLeft: '8px',
    width: '100%',
    margin: '0',
    boxSizing: 'border-box',
  },
}))((props: ListTagLabel) => {
  const { item, index, handleSelectedTag, inputValue, children } = props;
  const classes = useStylesListTag();
  const onChange = (value) => {
    handleSelectedTag(value);
  };
  if (ff.ui_ux_update_tag_item) {
    return (
      <StyledMenuItem key={index} value={item.value}>
        <div
          className={classes.titleTag}
          onClick={() => {
            onChange(item);
          }}
        >
          <Box mx={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
            <Icon type={'draggable'} />
          </Box>
          <Typography variant="body2">{item.label}</Typography>
        </div>
        {children}
      </StyledMenuItem>
    );
  }
  return (
    <StyledMenuItem
      key={index}
      value={item.value}
      onClick={() => {
        onChange(item);
      }}
      component={'div'}
    >
      <div className={classes.titleTag}>
        <Box mx={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Icon type={'draggable'} />
        </Box>
        <Typography variant="body2">{item.label}</Typography>
      </div>
    </StyledMenuItem>
  );
});

const useStylesCheckedBox = makeStyles(() => ({
  box: {
    marginBottom: '0px',
    overflow: 'hidden',
  },
}));
type ListTagRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
  moveCard?: (dragIndex: string, hoverIndex: string) => void;
  disabled?: boolean;
  field?: string;
  className?: string;
};
const ListTagRender = ({ draggable, index, children, moveCard, disabled, field, className }: ListTagRenderProps) => {
  const classes = useStylesCheckedBox();
  return (
    <div className={ff.add_css_identifier_to_new_release_components ? `${classes.box} ${className}` : classes.box}>
      <DragCard key={index} index={index} moveCard={moveCard}>
        {children}
      </DragCard>
    </div>
  );
};
