import * as React from 'react';
import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import FormControlTemplate from './form-control-template';
import { OptionSelectType } from '@ep/insight-ui/elements/list-control/type';
import { SelectFormProps } from '@ep/insight-ui/elements/form-control/select-form/select-form';
import TextForm from '@ep/insight-ui/elements/form-control/text-form';
import { TextFormProps } from '@ep/insight-ui/elements/form-control/text-form/text-form';

interface SelectionProps extends SelectFormProps {
  label: string;
  options: OptionSelectType[];
  value: string | string[];
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  classNameContainer?: string;
}

interface TextFormTemplateProps extends TextFormProps {
  label: string;
  value: string;
  placeHodler?: string;
  classNameContainer?: string;
  multiline?: boolean;
  line?: number;
}
const Selection = ({ label, onChange, options, value, classNameContainer = '', ...res }: SelectionProps) => {
  return (
    <div>
      <FormControlTemplate label={label} className={`${classNameContainer}`}>
        <SelectForm {...res} options={options} value={value} onChange={onChange} />
      </FormControlTemplate>
    </div>
  );
};
export const TextFormTemplate = ({
  label,
  value,
  classNameContainer = '',
  placeHodler = '',
  multiline = false,
  line,
  ...res
}: TextFormTemplateProps) => {
  return (
    <div>
      <FormControlTemplate label={label} className={`${classNameContainer}`}>
        <TextForm {...res} value={value} placeholder={placeHodler} fullWidthInput multiline={multiline} line={line} />
      </FormControlTemplate>
    </div>
  );
};

export default Selection;
