import { aim, ContainerResponsiveContext, NodeEditContext } from '@eip/next/lib/main';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import { UaWatchlist } from '@ep/insight-ui/system/util/uamonit/uamonit-hooks';
import { Box, Button, Grid, Modal, Divider, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { debounce, get, set } from 'lodash';
import * as React from 'react';
import Icon from '@ep/insight-ui/icons/Icon';
import { ChildrenDropdownProps } from '../../../dropdown-menu/dropdown';
import HeaderList from '../../../list-control/header-list/header-list';
import { OptionSelectType } from '../../../list-control/type';
import Typography from '../../../text-style/Typography';
import FilterGroup from './filter-group';
import LegacyFilterGroup from './legacy-filter-group';
import { FieldSelectedValue, FilterDialogProps } from './index';
import { v4 as uuid } from 'uuid';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { getQueryParams } from '@ep/insight-ui/system/backbone/data-source/common';
import { MonitorContainer } from '@ep/insight-ui/system/util/monitor/container';
import { getConst } from '@ep/insight-ui/sw/constant/common';
import { DELAY_CLOSE_POPUP } from '@ep/insight-ui/system/helper/constant';

/* Type */
interface FilterFormProps extends FilterDialogProps, ChildrenDropdownProps {}

/** Style */
const useStyles = makeStyles(() => ({
  container: {
    minWidth: '568px',
    paddingBottom: '6px',
    position: 'relative',
    '& .eip1-MuiMenu-paper': {
      backgroundColor: 'red',
    },
  },
  adminIcon: {
    position: 'absolute',
    top: '0',
    right: '0',
  },
  formGroupSubmit: {
    marginTop: '16px',
    '& .eip1-MuiButton-text': {
      padding: '6px 8px',
    },
  },
  header: {
    paddingLeft: '0px',
  },
  noFilter: {
    color: colors.icon.subdued,
    '& h4': {
      marginTop: '2px',
    },
    '& p': {
      textAlign: 'left',
    },
    textAlign: 'right',
  },
  buttonAdd: {
    padding: '6px 8px 6px 10px',

    marginTop: '16px',

    marginBottom: '14px',
  },
  buttonAddBottom: {
    marginTop: '18px',
  },
  iconNoFilter: {
    marginTop: '24px',
  },
  mobileContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: '#F6F9FF',
    boxShadow: '0px 6px 12px rgba(140, 152, 164, 0.25)',
    padding: '20px 16px',
    outline: 'none',
  },
  mobileHeader: {
    position: 'relative',
    textAlign: 'center',
    padding: '10px',
    background: '#FFF',
    borderRadius: '8px',
  },
  mobileTitle: {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '20px',
    margin: 0,
    color: '#253746',
  },
  mobileButton: {
    position: 'absolute',
    top: '50%',
    right: '10px',
    transform: 'translate(0, -50%)',
  },
  mobileContent: {
    marginTop: '20px',
    padding: '15px',
    height: '100vh',
    backgroundColor: '#FFFFFF',
  },
  error: {
    fontSize: '12px',
    color: '#f44336',
  },
}));

export interface TypeGroupFilter {
  type: 'groupFilter';
  logicalOperator: 'and' | 'or' | string;
  subFilters: Array<IFilterItem>;
  static?: boolean;
  isStaticGroup?: boolean;
  isDisabled?: boolean;
  isLocked?: boolean;
  isHidden?: boolean;
  tmpId?: string;
}
export interface TypeFilter {
  type: 'filter';
  logicalOperator: 'and' | 'or' | string;
  field: FieldSelectedValue;
  queryType: OptionSelectType;
  queryValue: {
    value: string;
    unit?: string;
  };
  static?: boolean;
  staticOperatorFor?: string[];
  isStaticGroup?: boolean;
  isDisabled?: boolean;
  isLocked?: boolean;
  isHidden?: boolean;
  tmpId?: string;
}
export type IFilterItem = TypeFilter | TypeGroupFilter;

export function migrateSingleFilter(item: any): TableBackbone.filter {
  let nuFilter: TableBackbone.filter = {} as TableBackbone.filter;
  if (item.type === 'groupFilter') {
    nuFilter.type = 'groupFilter';
    nuFilter.logicalOperator = item.logicalOperator;
    (nuFilter as TableBackbone.groupFilter).subFilters = item.subFilters.map((f) => migrateSingleFilter(f));
  } else {
    nuFilter = {
      field: item.field.value,
      type: item.type,
      logicalOperator: item.logicalOperator,
      dataType: item.field.dataType,
      queryType: item.queryType.value,
      queryValue: get(item, 'queryValue.value', ''),
      queryValueLabel: get(item, 'queryValue.label', ''),
    };
  }
  if (item.static) nuFilter.static = item.static;
  if (item.isDisabled) nuFilter.isDisabled = item.isDisabled;
  if (item.isLocked) nuFilter.isLocked = item.isLocked;
  if (item.isHidden) nuFilter.isHidden = item.isHidden;
  if (item.tmpId) nuFilter.id = item.tmpId;

  return nuFilter;
}

const FilterForm = ({
  options,
  operators,
  filterModel,
  setFilterModel,
  helperText = null,
  addFilterButtonLabel = 'Add a Filter',
  headerText = 'Filter',
  enableFillerGroup = false,
  enabledSearchOptions = false,
  disabledIconNoFilter = false,
  getOptionAvailable = () => undefined,
  setIsScrollDisabled = () => undefined,
  requiredValueFilterFields = [],
  hideButtons = false,
  ...rest
}: FilterFormProps) => {
  const classes = useStyles();
  const [isDefaultLoaded, setIsDefaultLoaded] = React.useState(false);
  const { containerClass } = React.useContext(ContainerResponsiveContext);
  const { isEditMode } = React.useContext(NodeEditContext);
  const isMobileView = containerClass === 'eres--small';

  const uawl = React.useContext(UaWatchlist);

  const requiredFilterFields = requiredValueFilterFields;

  const formatFilter = React.useCallback(
    (bbFilter) => {
      const transformFilter = (filter, isStaticGroup = false) => {
        return filter.reduce((acc, el) => {
          if (el.type === 'groupFilter') {
            return [...acc, { ...el, subFilters: transformFilter(el.subFilters, el.static), tmpId: uuid() }];
          }

          const filterColId = typeof el.field === 'object' ? el.field.value : el.field;
          const field = options.find((i) => i.value === filterColId);
          if (field) {
            const queryType =
              typeof el.queryType === 'object'
                ? el.queryType
                : operators[el.dataType]
                ? operators[el.dataType][0].find((operator: any) => operator.value === el.queryType)
                : '';
            const queryValue =
              typeof el.queryValue === 'object' ? el.queryValue : { value: el.queryValue, label: el.queryValueLabel };
            return [
              ...acc,
              {
                ...el,
                type: el.type ?? 'filter',
                static: el.static,
                field,
                queryType,
                queryValue,
                isStaticGroup,
                tmpId: el.id || uuid(),
              },
            ];
          }

          return acc;
        }, []);
      };

      return transformFilter(bbFilter);
    },
    [operators, options],
  );

  const backbone = React.useContext(TableBackboneContext);
  const endpoint = backbone.getConfig('endpoint', { GET_TABLE_DATA: '' }).GET_TABLE_DATA;
  const queryParams = getQueryParams(endpoint);
  const isNewVersion = queryParams.namespace;

  const [tmpFilterModel, setTmpFilterModel] = React.useState<Array<IFilterItem>>(
    () => {
      return formatFilter(filterModel);
    },
    // isDefaultLoaded || filterModel.length === 0 ? filterModel : [defaultFilterModel],
  );

  const filteredTmpFilterModel = React.useMemo(() => {
    return tmpFilterModel.filter((md) => (!md.isHidden && !isEditMode) || isEditMode);
  }, [tmpFilterModel]);

  const [checkingValidField, setCheckingValidField] = React.useState<boolean>(false);

  const wrapperRefModalFilter = React.useRef<HTMLDivElement>(null);
  const [heightWd, setHeightWd] = React.useState(window.innerHeight);

  React.useEffect(() => {
    const heightWrapper = wrapperRefModalFilter.current
      ? wrapperRefModalFilter.current.offsetParent.parentElement.offsetHeight
      : null;
    if (wrapperRefModalFilter.current) {
      if (heightWd - wrapperRefModalFilter.current.offsetParent.parentElement.offsetTop < heightWrapper) {
        setIsScrollDisabled(true);
      }
    }
  }, [tmpFilterModel]);

  // const getOptionsStatic = () => {
  //   const arr = [];
  //   tmpFilterModel.filter((item) => {
  //     if (get(item, 'static', false)) {
  //       arr.push(get(item, 'field.value', ''));
  //     }
  //   });
  //   return options.filter((item) => !arr.includes(item.value));
  // };
  // const optionsValue = getOptionsStatic();

  // React.useEffect(() => {
  //   if (!hideButtons) {
  //     // const tempArr = isDefaultLoaded || filterModel.length === 0 ? [...filterModel] : [defaultFilterModel];
  //     const tempArr = formatFilter(filterModel);
  //     const filterCache = JSON.parse(localStorage.getItem('filterModal'));
  //     if (filterCache) {
  //       const defaultFilterItem: IFilterItem = {
  //         type: 'filter',
  //         logicalOperator: 'and',
  //         field: {
  //           label: '',
  //           value: '',
  //           dataType: 'string',
  //         },
  //         queryType: {
  //           label: '',
  //           value: '',
  //           dataType: 'string',
  //         },
  //         queryValue: {
  //           value: '',
  //         },
  //       };
  //       const field = options.find((item) => item.value == filterCache.field);
  //       if (field) defaultFilterItem.field = field;

  //       const queryType = get(getFieldOperators(defaultFilterItem.field.dataType), [0, 0], null);
  //       if (queryType) defaultFilterItem.queryType = queryType;

  //       tempArr.push(defaultFilterItem);
  //       // FIXME: global leak, should use table backbone tempoStorage
  //       localStorage.removeItem('filterModal');
  //     }
  //     tempArr.map((item, index) => {
  //       if (!item.type) set(tempArr, [index, 'type'], 'filter');
  //     });
  //     setTmpFilterModel(tempArr);
  //   }
  // }, [filterModel, hideButtons]);

  const getFieldOperators = (dataType: keyof typeof operators, field): OptionSelectType[][] => {
    if (field?.isAdditionalFilter && field?.operators) return field.operators;
    return get(operators, [dataType], []);
  };

  const getDefaultRule = (isStaticGroup = false): IFilterItem => {
    const defaultFilterItem: IFilterItem = {
      type: 'filter',
      logicalOperator: 'and',
      field: {
        label: '',
        value: '',
        dataType: 'string',
      },
      queryType: {
        label: '',
        value: '',
        dataType: 'string',
      },
      queryValue: {
        value: '',
      },
      static: false,
      isStaticGroup: isStaticGroup ? true : false,
    };
    const field = get(options, [0], null);
    if (field) defaultFilterItem.field = field;

    const queryType = get(getFieldOperators(defaultFilterItem.field.dataType), [0, 0], null);
    if (queryType) defaultFilterItem.queryType = queryType;
    defaultFilterItem.static = false;
    return defaultFilterItem;
  };

  const handleAddRule = ({ isSystem = false }: { isSystem?: boolean }) => {
    const tmpModel: Array<IFilterItem> = JSON.parse(JSON.stringify(tmpFilterModel));
    const defaultModel = getDefaultRule();
    defaultModel.static = isSystem;
    tmpModel.push(defaultModel);
    setTmpFilterModel(tmpModel);
  };

  const setTmpFilterModelSystem = (value) => {
    const newValue = value.concat(tmpFilterModel.filter((el) => !el.static));
    setTmpFilterModel(newValue);
  };

  const setTmpFilterModelUser = (value) => {
    let newValue = value;
    if (isEditMode) {
      newValue = tmpFilterModel.filter((el) => el.static).concat(value);
    }
    setTmpFilterModel(newValue);
  };

  const handleCloseMenu = () => {
    setTmpFilterModel(formatFilter(filterModel));
    if (rest && rest.onClose) rest.onClose();
  };

  const handleSubmit = () => {
    setCheckingValidField(false);
    if (!isDefaultLoaded) {
      setIsDefaultLoaded(true);
    }
    const migratedFilter = [].concat(tmpFilterModel).map(migrateSingleFilter);

    const isValidFilter = requiredFilterFields.every((field) => {
      const fieldValues = migratedFilter.filter((el) => el.field === field);

      if (fieldValues.length === 0) return true;

      return fieldValues.every((el) => !!el.queryValue);
    });

    if (isValidFilter) {
      if (rest && rest.onClose) rest.onClose();
      setTimeout(() => {
        setFilterModel(migratedFilter);
      }, getConst(getConst(DELAY_CLOSE_POPUP, 100), 100));
    } else {
      setCheckingValidField(true);
    }
  };

  const handleDataChange = React.useCallback(debounce(handleSubmit, 300), [tmpFilterModel]);

  React.useEffect(() => {
    if (hideButtons) {
      handleDataChange();
    }
  }, [hideButtons]);

  React.useEffect(() => {
    if (rest.updateAfterChange) {
      handleDataChange();
    }
  }, [rest.updateAfterChange, tmpFilterModel]);

  const hasHiddenFilter = (filters) => {
    return filters.some((el) => {
      if (el.type === 'groupFilter') return hasHiddenFilter(el.subFilters);
      return el.isHidden;
    });
  };

  return (
    <MonitorContainer
      component="div"
      mId="advanced-filter-panel"
      mLabel={'Advanced filter panel'}
      className={`${classes.container}`}
      ref={wrapperRefModalFilter}
    >
      {isEditMode ? (
        <>
          <HeaderList
            title={'System filter'}
            addOn={!enableFillerGroup}
            variant="h4"
            onClickAddOn={() => handleAddRule({ isSystem: true })}
            className={classes.header}
          />
          <FilterGroup
            level={0}
            // tmpFilterModel={formattedTmpFilterModel}
            tmpFilterModel={tmpFilterModel.filter((el) => el.static)}
            getDefaultRule={getDefaultRule}
            getFieldOperators={getFieldOperators}
            setTmpFilterModel={setTmpFilterModelSystem}
            getOptionAvailable={getOptionAvailable}
            options={options}
            operators={operators}
            enableFillerGroup={enableFillerGroup}
            filterData={tmpFilterModel}
            checkingValidField={checkingValidField}
            setCheckingValidField={setCheckingValidField}
            requiredFilterFields={requiredFilterFields}
            isSystem
          />
          {filteredTmpFilterModel.filter((el) => el.static).length === 0 && (
            <Box className={classes.noFilter}>
              {!disabledIconNoFilter && (
                <div className={classes.iconNoFilter}>
                  <Icon type="noResult" />
                </div>
              )}

              {helperText ? (
                <React.Fragment>{helperText}</React.Fragment>
              ) : (
                <React.Fragment>
                  <Typography variant="body2">Use a filter to:</Typography>
                  <Typography variant="body2">Show ads by some certain name or ID.</Typography>
                  <Typography variant="body2">
                    Show only ads with certain advertising performance conditions.
                  </Typography>
                  <Typography variant="body2">Hide not running ads.</Typography>
                </React.Fragment>
              )}

              <Button
                variant="contained"
                color="primary"
                startIcon={<Icon type="plus" size="12px" />}
                className={classes.buttonAdd}
                onClick={() => handleAddRule({ isSystem: true })}
              >
                {addFilterButtonLabel}
              </Button>
            </Box>
          )}
          <Divider style={{ marginTop: '8px' }} />
        </>
      ) : null}
      {headerText ? (
        <HeaderList
          title={headerText}
          addOn={!enableFillerGroup}
          variant="h4"
          onClickAddOn={handleAddRule}
          className={classes.header}
        />
      ) : null}
      {isNewVersion ? (
        <FilterGroup
          level={0}
          // tmpFilterModel={formattedTmpFilterModel}
          tmpFilterModel={tmpFilterModel.filter((el) => (isEditMode && !el.static) || !isEditMode)}
          getDefaultRule={getDefaultRule}
          getFieldOperators={getFieldOperators}
          setTmpFilterModel={setTmpFilterModelUser}
          getOptionAvailable={getOptionAvailable}
          options={options}
          operators={operators}
          enableFillerGroup={enableFillerGroup}
          filterData={tmpFilterModel}
          checkingValidField={checkingValidField}
          setCheckingValidField={setCheckingValidField}
          requiredFilterFields={requiredFilterFields}
        />
      ) : (
        <LegacyFilterGroup
          level={0}
          // tmpFilterModel={formattedTmpFilterModel}
          tmpFilterModel={tmpFilterModel.filter((el) => (isEditMode && !el.static) || !isEditMode)}
          getDefaultRule={getDefaultRule}
          getFieldOperators={getFieldOperators}
          setTmpFilterModel={setTmpFilterModelUser}
          getOptionAvailable={getOptionAvailable}
          options={options}
          operators={operators}
          enableFillerGroup={enableFillerGroup}
          filterData={tmpFilterModel}
          checkingValidField={checkingValidField}
          setCheckingValidField={setCheckingValidField}
          requiredFilterFields={requiredFilterFields}
        />
      )}

      {/* Notify admin that there is a hidden filter */}
      {!isEditMode && hasHiddenFilter(tmpFilterModel) && aim.isSuperAdmin() ? (
        <div className={classes.adminIcon}>
          <Tooltip title={'There is a hidden filter'}>
            <span>
              <Icon type={'ic/ic:round-circle/yellow'} size={'8px'} />
            </span>
          </Tooltip>
        </div>
      ) : null}
      {filteredTmpFilterModel.filter((el) => (isEditMode && !el.static) || !isEditMode).length === 0 && (
        <Box className={classes.noFilter}>
          {!disabledIconNoFilter && (
            <div className={classes.iconNoFilter}>
              <Icon type="noResult" />
            </div>
          )}

          {helperText ? (
            <React.Fragment>{helperText}</React.Fragment>
          ) : (
            <React.Fragment>
              <Typography variant="body2">Use a filter to:</Typography>
              <Typography variant="body2">Show ads by some certain name or ID.</Typography>
              <Typography variant="body2">Show only ads with certain advertising performance conditions.</Typography>
              <Typography variant="body2">Hide not running ads.</Typography>
            </React.Fragment>
          )}

          <Button
            variant="contained"
            color="primary"
            startIcon={<Icon type="plus" size="12px" />}
            className={classes.buttonAdd}
            onClick={handleAddRule}
          >
            {addFilterButtonLabel}
          </Button>
        </Box>
      )}
      {checkingValidField && <span className={classes.error}>These marked field is required.</span>}
      {(filteredTmpFilterModel.length > 0 ||
        filterModel.filter((md) => (!md.isHidden && !isEditMode) || isEditMode).length > 0) &&
        !hideButtons && (
          <Grid container direction="row" justifyContent="flex-end" className={classes.formGroupSubmit} spacing={3}>
            <Grid item>
              <Button onClick={handleCloseMenu}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Apply
              </Button>
            </Grid>
          </Grid>
        )}
    </MonitorContainer>
  );
};

export default FilterForm;
