import { EIP_CONSTANT } from '@eip/next/lib/main';
import { epsiloTableEndpoint } from '@ep/insight-ui/system/backbone/data-source/common';

export const ETABLE_INSIGHT_TEMPLATE_MARKETPLACE = {
  title: 'Etable starter - Insight / Marketplace',
  dimension: [],
  metric: [],
  attribute: [],
  endpoint: {
    GET_TABLE_DATA:
      EIP_CONSTANT.API_HOST.API_DATA_CENTER +
      `/${epsiloTableEndpoint.PERFORMANCE.queryEpMatching}?_eipGroup=marketplace`,
  },
  primaryKeys: ['storefront.id'],
  system: {
    hiddenComponents: ['search', 'majorButton'],
  },
  hiddenColumns: [],
  requestHiddenField: {},
  requestIgnoreField: {
    metric: [],
  },
  hiddenFilter: {
    currency: 'USD',
  },
  mapping: {
    marketplace: {
      title: 'Marketplace',
      propertyType: 'dimension',
      cellFormat: 'marketplaceFormat',
      dataType: 'string',
      filterField: 'storefront.marketplace_code',
      valueGetter: {
        label: 'storefront.marketplace_code',
        id: 'storefront.marketplace_code',
      },
    },
    'storefront.gmv': {
      title: 'GMV',
      propertyType: 'metric',
      cellFormat: 'metricTraction',
      dataType: 'float',
      valueGetter: {
        value: 'storefront.gmv',
      },
    },
  },
};
