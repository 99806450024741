import * as React from 'react';
import { SvgIcon } from '@material-ui/core';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
const Not = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 16 16" fill="none">
    <rect x="7" y="3" width="2" height="8" rx="1" fill={props?.color} />
    <rect x="7" y="12" width="2" height="2" rx="1" fill={props?.color} />
  </SvgIcon>
);

export default Not;
