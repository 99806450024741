import * as React from 'react';
import CreditVisa from './credit-visa.png';
import CreditMasterCard from './credit-master-card.png';
import CreditAmericanExpress from './credit-american-express.png';
import { CardMedia, CardMediaTypeMap, CardMediaProps, BoxProps, Box } from '@material-ui/core';

const ImageSrc = {
  visa: CreditVisa,
  masterCard: CreditMasterCard,
  americanExpress: CreditAmericanExpress,
};
export type CreditType = keyof typeof ImageSrc;
interface Props extends Omit<BoxProps, 'src'> {
  src: CreditType;
}
const CreditCard = ({ src, ...props }: Props) => {
  return (
    <Box
      component={'div'}
      style={{ backgroundImage: `url(${ImageSrc[src]})`, backgroundSize: 'cover', ...props.style }}
      {...props}
    />
  );
};
export default CreditCard;
