import * as React from 'react';
import { cloneDeep, get, isEqual, omit, set } from 'lodash';
import moment from 'moment';

import { Button, TextField, Typography, makeStyles } from '@material-ui/core';

import SelectForm from '@ep/insight-ui/elements/form-control/select-form';
import { makeTable } from '@ep/insight-ui/elements/etable2/table-container';
import { useTableBackbone } from '@ep/insight-ui/system/backbone/table-backbone/next-table-backbone';
import { calculateValueGetter, produceColumns } from '@ep/insight-ui/sw/etable/service';
import { getCustomCellActions } from '../../block/etable/etable-next';
import { useSetAtom } from 'jotai';
import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { NodeEditContext, eipRequest } from '@eip/next/lib/main';
import { editorScript } from '../etable/etable-config/atom/editor-script';
import { enhancedETableConfig2 } from '../etable/migration';
import { getCampaignSettingInfo } from '../../workflow/camp0-detail/legacy/api-request-campaign-details';

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
  },
  configurationContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: '8px',
  },
  selection: {
    width: '500px',
  },
  formControl: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '8px',
  },
  inputControl: {
    width: '300px',
    '& .eip1-MuiOutlinedInput-input': {
      padding: '6px 0 7px 10px',
    },
    '& .eip1-MuiFormControl-root': {
      width: '100%',
    },
  },
}));

const EditorView = ({ nodeData }: any) => {
  const classes = useStyles();

  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);

  const [selectedConfig, setSelectedConfig] = React.useState('default');
  const options = get(nodeData, ['customAttributes', 'adsToolConfig'], []).map((el) => ({
    value: el.code,
    label: el.code,
  }));
  const [systemConfig, setSystemConfig] = React.useState(null);

  React.useEffect(() => {
    const clonedConfig = cloneDeep(get(nodeData, ['customAttributes', 'adsToolConfig'], []));
    const config = clonedConfig.find(({ code }) => code == selectedConfig);
    if (!config) {
      setSelectedConfig(null);
      return null;
    }
    const configAttributes = cloneDeep(get(config, ['config'], {}));
    const viewDefaultIndex = (configAttributes.views || []).findIndex((view) => view.id === 'default');
    const { dimension, attribute, metric, columnWidth } = Object.entries(configAttributes.mapping || {}).reduce(
      (carry, [key, col]) => {
        const propertyType = col['propertyType'];
        if (carry[propertyType]) {
          carry[propertyType].push(key);
        }
        const initColumnWidth = col.initColumnWidth;
        if (initColumnWidth) {
          let width = undefined;
          if (Number(initColumnWidth)) width = initColumnWidth;
          carry.columnWidth.push({
            width,
            columnField: key,
          });
        }
        return carry;
      },
      { dimension: [], attribute: [], metric: [], columnWidth: [] },
    );
    if (!configAttributes.dimension) {
      configAttributes.dimension = dimension;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'dimension'], dimension);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'dimension'], dimension);
    }
    if (!configAttributes.attribute) {
      configAttributes.attribute = attribute;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'attribute'], attribute);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'attribute'], attribute);
    }
    if (!configAttributes.metric) {
      configAttributes.metric = metric;
      if (configAttributes.view?.id == 'default') {
        set(configAttributes, ['view', 'combinator', 'properties', 'metric'], metric);
      }
      set(configAttributes, ['views', viewDefaultIndex, 'combinator', 'properties', 'metric'], metric);
    }
    if (!configAttributes.columnWidth) {
      configAttributes.columnWidth = columnWidth;
    }
    const nextSystemConfig = {
      customAttributes: configAttributes,
      id: config.id,
    };
    setSystemConfig((prev) => {
      return {
        ...prev,
        ...nextSystemConfig,
        updatedTimes: (prev?.updatedTimes || 0) + 1,
      };
    });
  }, [nodeData, selectedConfig]);

  const handleSaveConfig = () => {
    const clonePageConfig = cloneDeep(nodeData);
    const newAdsToolConfig = get(clonePageConfig, ['customAttributes', 'adsToolConfig'], []).map((config) => {
      if (config.id === systemConfig.id)
        return {
          ...config,
          config: systemConfig?.customAttributes,
        };
      return config;
    });
    nodeEditContext.onUpdateCustomAttributes(nodeData, { adsToolConfig: newAdsToolConfig });
  };

  React.useEffect(() => {
    const hasTool = get(nodeData, ['customAttributes', 'adsToolConfig'], []).some(({ code }) => code == selectedConfig);
    if (!hasTool) setSelectedConfig('default');
  }, [nodeData, selectedConfig]);

  return (
    <div className={classes.container}>
      <div className={classes.configurationContainer}>
        <div className={classes.selection}>
          <Typography variant={'h6'}>Select etable configuration</Typography>
          <div className={classes.formControl}>
            <div className={classes.inputControl}>
              <SelectForm
                options={options}
                value={selectedConfig}
                onChange={(e) => setSelectedConfig(e.target.value)}
              />
            </div>
            <Button variant={'contained'} color={'primary'} onClick={handleSaveConfig}>
              Save
            </Button>
          </div>
        </div>
      </div>
      {systemConfig ? (
        <ETableBlock
          nodeData={systemConfig}
          systemConfig={{}}
          pageConfig={nodeData}
          selectedConfig={selectedConfig}
          setSystemConfig={setSystemConfig}
        />
      ) : (
        <h2>This Ad tool has not been supported yet, please set it up.</h2>
      )}
    </div>
  );
};

const EXCLUDE_CONFIGURATION = ['tableParams'];

function ETableBlock({
  nodeData,
  systemConfig,
  pageConfig,
  selectedConfig,
  setSystemConfig,
}: {
  nodeData: NodeData;
  systemConfig: NodeData;
  pageConfig: any;
  selectedConfig: string;
  setSystemConfig: any;
}) {
  const nodeEditContext = React.useContext<NodeEditContext>(NodeEditContext);
  const setOpenCodeEditor = useSetAtom(editorScript);
  const { onToastMultiple } = useToast();

  const lastUpdated = React.useRef(moment().valueOf());
  const refBackbone = React.useRef(null);
  const initialRowsRef = React.useRef([]);
  const selectedConfigRef = React.useRef('');

  const handleChangeConfig = (config: Record<string, any>) => {
    const finConfig = omit(config, EXCLUDE_CONFIGURATION);
    EXCLUDE_CONFIGURATION.forEach((f) => {
      finConfig[f] = undefined;
    });
    setSystemConfig((prev) => {
      return {
        ...prev,
        customAttributes: finConfig,
      };
    });
  };

  const customConfig = get(nodeData, 'customAttributes', {});

  const { config, linkedObjects } = enhancedETableConfig2({
    customConfig,
    systemConfig,
    nodeEditContext,
    lastUpdated,
    blockEid: nodeData.blockEid,
    setOpenCodeEditor,
    getCustomCellActions: getCustomCellActions,
    onToastMultiple,
  });
  set(config, ['configuration', 'defaultPagination', 'limit'], 10);
  // Enhance etable config for campaign detail
  const prevGetRowsParams = config.addons['datasource.getRowsParams'];
  config.addons['datasource.getRowsParams'] = ({ params }, currentConfig, backbone) => {
    const prevParams = prevGetRowsParams ? prevGetRowsParams({ params }, currentConfig, backbone) : params;

    if (selectedConfigRef.current == 'default' || !selectedConfigRef.current) {
      return prevParams;
    }
    const campaignFilter = {
      dataType: 'string',
      field: 'ads_tool.code',
      operator: 'IN',
      value: selectedConfigRef.current?.split('\n').join(','),
    };
    if (!prevParams.filter) {
      prevParams.filter = {
        combinator: 'AND',
        filters: [campaignFilter],
      };
    } else {
      prevParams.filter.filters.push(campaignFilter);
    }
    return prevParams;
  };

  config.addons['datasource.apiRequest.getTableData'] = async (params, originRequest, backbone) => {
    if (selectedConfigRef.current) {
      const result = await eipRequest.post(backbone.config.endpoint.GET_TABLE_DATA, {
        ...params,
        '@eTableConfig': { ...backbone.config, tableId: nodeData.blockEid },
      });
      if (result?.rows?.length == 0) {
        const setting: any = await getCampaignSettingInfo({ campaignId: '' });
        const rows = [setting];
        const columns = produceColumns(backbone.config);
        const enhancedRows = await calculateValueGetter({
          rows,
          columns,
          groupedFields: [],
          drillDowns: [],
          resourceMetric: [],
          formulaUpstream: null,
          config: backbone.config,
        });
        set(result, ['rows'], enhancedRows);
        set(result, ['data', 'rows'], enhancedRows);
      }

      return result;
    }
    const columns = produceColumns(backbone.config);
    const enhancedRows = await calculateValueGetter({
      rows: initialRowsRef.current || [],
      columns,
      groupedFields: [],
      drillDowns: [],
      resourceMetric: [],
      formulaUpstream: null,
      config: backbone.config,
    });
    const result = {
      data: {
        summary: {},
        rows: enhancedRows,
      },
      eTableContext: {
        columns: columns,
        groupBy: {
          aggregations: {},
        },
        pinnedColumn: [],
      },
      rows: enhancedRows,
    };

    return result;
  };

  config.callback.onBackboneReady = (backbone) => {
    refBackbone.current = backbone;
  };

  React.useEffect(() => {
    selectedConfigRef.current = selectedConfig;
    if (refBackbone.current) {
      refBackbone.current.reloadData('table');
    }
  }, [selectedConfig]);

  const memoEtable = React.useMemo(() => {
    return makeTable({
      blockEid: nodeData.blockEid,
      config,
      changeConfiguration: handleChangeConfig,
      tableBackboneHook: useTableBackbone,
      linkedObjects,
    });
  }, [nodeData.id, pageConfig, nodeData.updatedTimes]);

  return <>{memoEtable}</>;
}

export default EditorView;
