import { produceQueryResult } from '@ep/insight-ui/sw/etable/data/common';
import * as request from '@ep/insight-ui/sw/util/request';
import { get, set } from 'lodash';
import { stringify } from 'qs';
import { stripUniversalPrefix, universalPrefixListField } from '../util/column';
import { useLog } from '../log';

const console = useLog('origin-request');

export function makeOriginRequest(endpoint) {
  const getTableData = async (param: {
    dimensions: string[];
    attributes: string[];
    metrics: string[];
    group_by?: string[];
    sort?: SortType[];
    filter?: FilterType[];
    pagination: {
      page: number;
      limit: number;
    };
    from: string;
    to: string;
    _eip: any;
  }): Promise<ETableResponseType> => {
    let enrichedEndpoint = endpoint;
    let _eip = param._eip;
    if (_eip) {
      const _eipStr = stringify({ _eip }, { allowDots: true });
      if (enrichedEndpoint.indexOf('?') === -1) {
        enrichedEndpoint += `?${_eipStr}`;
      } else {
        enrichedEndpoint += `&${_eipStr}`;
      }
    }

    try {
      const finalParams = {
        ...param,
        ...universalPrefixListField({
          attributes: param.attributes,
          metrics: param.metrics,
        }),
      };
      const res = await request.post(enrichedEndpoint, finalParams);
      let rows;
      try {
        rows = res.data.rows.map((row) => {
          return res.data.headers.reduce((acc, header, index) => {
            return {
              ...acc,
              [header]: row[index],
              [stripUniversalPrefix(header)]: row[index],
            };
          }, {});
        });
      } catch (error) {
        console.error(error);
      }

      return Promise.resolve({
        data: {
          ...res.data,
          rows,
          pagination: res.pagination,
          ...(res.masterData ? { masterData: res.masterData } : {}),
          ...(res.masterDataPrimaryKey ? { masterDataPrimaryKey: res.masterDataPrimaryKey } : {}),
        },
        ...{ traceId: get(res, 'serviceResponse.traceId') },
        eTableContext: get(res, 'eTableContext', null),
        message: get(res, 'serviceResponse.message'),
        code: get(res, 'serviceResponse.code'),
        success: get(res, 'serviceResponse.success'),
        queryParams: finalParams,
      });
    } catch (err) {
      return Promise.reject({
        data: [],
        error: err,
        message: 'Load fail',
        code: 1,
        success: false,
      });
    }
  };

  return {
    getTableData,
  };
}

export type UqueryParams = {
  dimensions: string[];
  attributes: string[];
  metrics: string[];
  groupBy?: { column?: string[]; aggregations?: { field: string; func: string }[] };
  groupPeriod?: 'all' | 'hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly';
  sort?: SortType[];
  filter?: FilterType[];
  pagination: {
    page: number;
    limit: number;
  };
  from: string;
  to: string;
  _eip?: any;
};

export async function uQuery(endpoint: string, param: UqueryParams): Promise<ETableResponseType> {
  let enrichedEndpoint = endpoint;
  let _eip = param._eip;
  if (_eip) {
    const _eipStr = stringify({ _eip }, { allowDots: true });
    if (enrichedEndpoint.indexOf('?') === -1) {
      enrichedEndpoint += `?${_eipStr}`;
    } else {
      enrichedEndpoint += `&${_eipStr}`;
    }
  }
  try {
    const res = await request.post(enrichedEndpoint, {
      ...param,
      ...universalPrefixListField({
        attributes: param.attributes,
        metrics: param.metrics,
      }),
    });
    let rows;
    try {
      set(res, 'data.masterData', res.masterData);
      set(res, 'data.masterDataPrimaryKey', res.masterDataPrimaryKey);
      const processedResult = produceQueryResult(res);
      rows = processedResult.rows;
    } catch (error) {
      console.error(error);
    }

    return Promise.resolve({
      data: {
        ...res.data,
        rows,
        pagination: res.pagination,
        ...(res.masterData ? { masterData: res.masterData } : {}),
        ...(res.masterDataPrimaryKey ? { masterDataPrimaryKey: res.masterDataPrimaryKey } : {}),
      },
      ...{ traceId: get(res, 'serviceResponse.traceId') },
      eTableContext: get(res, 'eTableContext', null),
      message: get(res, 'serviceResponse.message'),
      code: get(res, 'serviceResponse.code'),
      success: get(res, 'serviceResponse.success'),
    });
  } catch (err) {
    return Promise.reject({
      data: [],
      error: err,
      message: 'Load fail',
      code: 1,
      success: false,
    });
  }
}
