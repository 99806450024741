import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Radio = (props: SvgIconProps) => (
  <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="3" y="3" width="14" height="14" rx="7" fill="#0369C7" />
    <rect x="7" y="7" width="6" height="6" rx="3" fill="white" />
  </SvgIcon>
);

export default Radio;
