import React from 'react';
import { INIT_VALUE_TYPE_STATIC } from '../../../utils/constant';

export function HiddenInput({ configuration, ...props }) {
  const isConstant = configuration.init_value_type === INIT_VALUE_TYPE_STATIC;
  React.useEffect(() => {
    if (isConstant) props.onChange(configuration.init_value_value);
  }, []);

  return isConstant ? <input type="hidden" {...props} value={configuration.init_value_value} /> : null;
}
