import { get, uniq } from 'lodash';
import * as React from 'react';

import { useToast } from '@ep/insight-ui/elements/notifications/hook';
import { TableBackboneContext } from '@ep/insight-ui/system/backbone/table-backbone';
import { request } from '@ep/insight-ui/system/backbone/data-source/common';

export const useJsonEditor = (props) => {
  const [showEditor, setShowEditor] = React.useState(true);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState('');

  const { onToast } = useToast();

  const backbone = React.useContext(TableBackboneContext);
  const groupBy = backbone.getConfig('groupBy');
  const gridApi = backbone.getGridApi ? backbone.getGridApi() : null;
  const tracking = get(props, ['customProps', 'tracking'], '');
  const rowData = get(props, ['data', 'node', 'data'], {});

  const handleReloadTable = () => {
    if (gridApi) {
      const groupByCols = get(groupBy, 'columns', []);
      if (groupByCols.length > 0) {
        const expanded = [];
        gridApi.forEachNode((node) => {
          if (node.expanded) {
            expanded.push(node);
          }
        });
        expanded.forEach((el) => {
          gridApi.refreshServerSideStore({ route: [el.key] });
        });
      } else {
        gridApi.refreshServerSideStore();
      }
      gridApi.deselectAll();
    }
  };

  const handleSubmit = async (payload) => {
    setErrMsg('');

    const link = ((props.payload || []).find((el) => el.key === 'url') || { value: '' }).value;

    const url = link.replace(/\:\w+/g, (c) => {
      const { value } = props.payload.find((el) => el.key === c.slice(1)) || { value: '' };
      return get(rowData, [value], null);
    });

    let isSuccess = false;

    if (url) {
      try {
        setIsSubmitting(true);

        const response = await request.post(url, payload);

        setIsSubmitting(false);

        if (response.success || response.code == 200) {
          isSuccess = true;

          handleReloadTable();

          onToast({
            title: 'Success',
            messages: response.message,
            variant: 'success',
          });
          // TODO: query api for all row effected by the updates and reload the node.data
          setShowEditor(false);
        } else {
          setErrMsg(response.message || 'Something went wrong, please try again.');
        }
      } catch (e) {
        setErrMsg(e.message || 'Something went wrong, please try again.');
        setIsSubmitting(false);
      }

      if (tracking === 'yes') {
        try {
          let bulkRows = [];
          if (gridApi) {
            bulkRows = uniq(gridApi().getSelectedRows().concat(rowData));
          }
          freshpaint.track(`cell_action_submit`, {
            section: backbone.getConfig('title'),
            column: get(props, ['field'], ''),
            action_name: get(props, ['customProps', 'actionName'], ''),
            count_bulk_item: bulkRows.length,
            result: isSuccess,
          });
        } catch {}
      }
    } else {
      setErrMsg('Please enter the endpoint.');
    }
  };

  React.useEffect(() => {
    if (!showEditor) {
      props.setAnchorEl(null);
    }
  }, [showEditor]);

  return {
    showEditor,
    setShowEditor,
    handleSubmit,
    errMsg,
    isSubmitting,
  };
};
