import { EIP_CONSTANT } from '@eip/next/lib/main';
import React from 'react';

const ANALYTIC_URL = EIP_CONSTANT.API_HOST.API_EIP_MANAGER_URL + '/live-dashboard/user-view';

export function useCountUserView() {
  const [data, setData] = React.useState({ mobile: 0, desktop: 0 });
  React.useEffect(() => {
    queryData().then((result) => {
      if (result) {
        setData({
          mobile: result.data.mobile,
          desktop: result.data.desktop,
        });
      }
    });

    const tid = window.setInterval(() => {
      queryData().then((result) => {
        if (result) {
          setData({
            mobile: result.data.mobile,
            desktop: result.data.desktop,
          });
        }
      });
    }, 1 * 60 * 1000);

    return () => {
      window.clearInterval(tid);
    };
  }, []);

  return data;
}

function queryData() {
  return fetch(ANALYTIC_URL, {
    credentials: 'include',
  }).then((res) => res.json());
}
