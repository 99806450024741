import * as React from 'react';
import * as _ from 'lodash';
import { funcConfigs } from './hooks';
import { noSelectStyled } from '@ep/insight-ui/lib/epsilo-theme';
import { IData, IOption } from '../type';
import { Area } from './area-template';
import { makeStyles } from '@material-ui/core';
import ChartState, { IStateChartValue } from '../chart-loading-state/chart-loading-state';
import ChartSize, { TSize } from '../chart-size';
import { breakPointSize } from '../chart-size';
const useStyles = makeStyles({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  titleChart: {
    color: '#8C98A4',
    textTransform: 'uppercase',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    lineHeight: 1.25,
    letterSpacing: '0.00735em',
    marginBottom: '10px',
  },
  chart: {
    width: '100%',
    height: '100%',
  },
  wrapperRes: {
    height: '100%',
  },
  totalValueLine: {
    cursor: 'default',
    display: 'flex',
    justifyContent: 'space-between',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 500,
    fontSize: '1rem',
    color: '#253746',
    margin: 0,
    '& span': {
      fontSize: '1rem',
      marginLeft: '15px',
      color: '#0BA373',
      fontWeight: 500,
    },
    '& .currency': {
      fontSize: '1rem',
      color: '#253746',
      marginLeft: '1px',
      marginRight: '5px',
    },
    '& svg': {
      marginRight: 4,
    },
    '@media (max-width:767px)': {
      marginTop: 0,
      left: 0,
    },
    '& .percent': {
      display: 'block',
      '& span': {
        margin: 0,
      },
    },
  },
  legendLine: {
    display: 'flex',
    flexDirection: 'row',
    top: 0,
    right: 0,
    flexWrap: 'wrap',
    lineHeight: 2.2,
    margin: 0,
    padding: 0,
    color: '#253746',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    fontSize: '14px',
    '@media (max-width:767px)': {
      top: 0,
    },
    '& .legendItem': {
      marginRight: 10,
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'baseline',
      cursor: 'pointer',
      padding: '5px',
      transition: 'all .3s ease',
      '& span': {
        width: '12px',
        height: '12px',
        marginRight: '8px',
      },
      '& p': {
        padding: 0,
        margin: 0,
      },
    },
    '& .legendItem:hover': {
      background: '#F8FAFD',
      borderRadius: '5px',
      textAlign: 'center',
    },
  },
  wrapperLegendLine: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  legendDiv: {
    // display: 'flex',
    // // flex: 1,
    // // flexShrink: 0,
    // flexDirection:'column',
    // justifyContent: 'flex-end',
  },
  position: {
    '&.center': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
    },
  },
  noSelectText: {
    ...noSelectStyled,
  },
});

type IAreaChart = {
  keyChart?: string;
  title?: string;
  value?: string;
  percent?: number;
  currency?: string;
  dataList: IData;
  optionList: IOption;
  stateChart: IStateChartValue;
};
const AreaChart = ({
  keyChart = '1',
  title = '',
  value = '',
  percent = 0,
  currency = '',
  dataList,
  optionList,
  stateChart,
}: IAreaChart) => {
  const classes = useStyles();
  const divRef = React.useRef<HTMLDivElement>();
  const [width, setWidth] = React.useState(window.innerWidth);
  const [chartSize, setchartSize] = React.useState<TSize>('large');

  const ref = React.createRef();
  const idLegend = React.useMemo(() => `areaChartLegend_${keyChart}`, [keyChart]);

  const { htmlLegendPlugin } = funcConfigs({
    percent: '15%',
    width,
    legendDivID: idLegend,
  });

  React.useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      if (optionList) {
        optionList.scales.xAxes.display = false;
        optionList.scales.yAxes.display = false;

        if (
          divRef.current.offsetWidth < breakPointSize.width.medium ||
          divRef.current.offsetHeight < breakPointSize.height.medium
        )
          return setchartSize('small');
        if (
          divRef.current.offsetWidth < breakPointSize.width.large ||
          divRef.current.offsetHeight < breakPointSize.height.large
        )
          return setchartSize('medium');

        optionList.scales.xAxes.display = true;
        optionList.scales.yAxes.display = true;
        setchartSize('large');
      }
    });
    if (divRef.current) {
      ro.observe(divRef.current);
    }
    return () => {
      ro.disconnect();
    };
  }, [ref]);

  return (
    <div ref={divRef} className={classes.wrapper} style={{ display: 'flex', flexDirection: 'column' }}>
      <ChartSize size={chartSize}>
        <ChartState stateChart={stateChart} title={title} value={value} currency={currency} percent={percent}>
          <div
            className={classes.wrapperRes}
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div
              className={classes.chart}
              style={{
                flex: 1,
                flexShrink: 0,
                position: 'relative',
              }}
            >
              {dataList && <Area data={dataList} options={optionList} plugins={[htmlLegendPlugin]} refElement={ref} />}
            </div>
            <div className={classes.wrapperLegendLine} style={{ display: chartSize === 'large' ? 'flex' : 'none' }}>
              <div className={classes.legendDiv} id={idLegend}></div>
            </div>
          </div>
        </ChartState>
      </ChartSize>
    </div>
  );
};

export default AreaChart;
