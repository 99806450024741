import * as React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Button,
  Radio,
  FormControlLabel,
  FormGroup,
  Typography,
  debounce,
  FormControlLabelProps,
  Box,
} from '@material-ui/core';
import { RadioProps } from '@material-ui/core/radio';
import { colors } from '@ep/insight-ui/lib/epsilo-theme';
import DragCard from '@ep/insight-ui/elements/drag-n-drop';
import ButtonAction from '../../button/button-action';
import Icon from '@ep/insight-ui/icons/Icon';
import SearchInput from './../search-input/search-input';
import useAutocomplete from '@material-ui/lab/useAutocomplete';
import { boldMatchKeyword } from '@ep/insight-ui/lib/search';
import { OptionType } from './../type';
import HeaderList from '../header-list/header-list';
import GroupButtonSubmit from '../../inline-edit/cell/group-button-submit';

/* Type */
type PropsStyles = {
  searchAble?: boolean;
};
interface RadioListProps {
  items: OptionType[];
  onChange: (v: OptionType[]) => void;
  label?: string;
  disabled?: boolean;
  color?: RadioProps['color'];
  row?: boolean;
  draggable?: boolean;
  addAble?: boolean;
  searchAble?: boolean;
  onReorder?: (v: OptionType[]) => void;
  disabledAddOnIcon?: boolean;
  isShowButtonAction?: boolean;
  onClose?: any;
  onSubmit?: any;
}
type RadioRenderProps = {
  draggable?: boolean;
  index?: number;
  children: React.ReactNode;
};

/** Style */
const useStyles = makeStyles(() => ({
  addOnBtn: {
    padding: '5px',
    '&:focus': {
      backgroundColor: 'transparent',
      color: colors.icon.default,
    },
  },
  title: {
    paddingLeft: '7px',
  },
  header: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    marginBottom: '6px',
    paddingLeft: '8px',
  },
  box: {
    marginBottom: '6px',
  },
  searchInput: (props: PropsStyles) => ({
    display: props.searchAble ? 'block' : 'none',
  }),
}));

const useStylesForm = makeStyles(() => ({
  form: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: '4px',
    height: '32px',
    minHeight: '32px',
    paddingLeft: '8px',
    boxSizing: 'border-box',
    '&:hover': {
      backgroundColor: colors.action.subtleHovered,
    },
  },
}));
const StyledFormControlLabel = withStyles(() => ({
  root: {
    height: '32px',
    minHeight: '32px',
    borderRadius: '4px',
    '&:hover': {
      backgroundColor: colors.action.subtleHovered,
    },
    paddingLeft: '8px',
    width: '100%',
    margin: '0',
    boxSizing: 'border-box',
  },
}))((props: FormControlLabelProps) => {
  const classes = useStylesForm();
  return (
    <div className={classes.form}>
      {props.draggable && (
        <Box mx={2} display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Icon type={'draggable'} />
        </Box>
      )}
      <FormControlLabel {...props} />
    </div>
  );
});

const RadioList: React.FC<RadioListProps> = ({
  items,
  onChange,
  disabled = false,
  label,
  color = 'primary',
  row,
  draggable,
  addAble,
  searchAble,
  onReorder,
  disabledAddOnIcon = false,
  isShowButtonAction = false,
  onClose,
  onSubmit,
}: RadioListProps) => {
  const classes = useStyles({ searchAble });
  const [inputValue, setInputValue] = React.useState('');
  const defaultOptions = React.useMemo(() => items, [items]);
  const [options, setOptions] = React.useState<OptionType[]>(defaultOptions);
  React.useEffect(() => {
    setOptions(defaultOptions);
  }, [items]);

  const {
    getRootProps,
    getInputProps,

    groupedOptions,
  } = useAutocomplete({
    open: true,
    multiple: true,
    options: options,
    getOptionLabel: (option) => option.label,
    blurOnSelect: false,
    clearOnBlur: false,
    disableCloseOnSelect: true,
    inputValue: inputValue,
  });
  const handler = React.useCallback(debounce(onReorder, 1000), []);
  const moveCard = (dragIndex, hoverIndex) => {
    const tmpItems: OptionType[] = [...options];
    const dragCard = items[dragIndex];
    tmpItems.splice(dragIndex, 1);
    tmpItems.splice(hoverIndex, 0, dragCard);
    onChange(tmpItems);
    handler(tmpItems);
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const tmpItems = [...options];

    tmpItems.map((item) => {
      item.checked = item.value === e.target.value ? e.target.checked : false;
    });
    onChange(tmpItems);
    handler(tmpItems);
  };

  const RadioRender = ({ draggable, index, children }: RadioRenderProps) =>
    draggable && !disabled ? (
      <div className={classes.box}>
        <DragCard key={index} index={index} moveCard={moveCard}>
          {children}
        </DragCard>
      </div>
    ) : (
      <div className={classes.box}>{children}</div>
    );

  return (
    <FormGroup row={row}>
      {label && <HeaderList title={label} addOn={!disabledAddOnIcon} />}

      <div {...getRootProps()} className={classes.searchInput}>
        <SearchInput
          onChange={(e) => {
            setInputValue(e.target.value);
          }}
          inputValue={inputValue}
          setInputValue={setInputValue}
          inputProps={{ ...getInputProps() }}
          fullWidth={true}
        />
      </div>
      <div>
        {inputValue !== '' &&
          groupedOptions.map((item, index) => (
            <RadioRender key={index} draggable={draggable}>
              <StyledFormControlLabel
                draggable={draggable}
                control={
                  <Radio
                    checked={item.checked}
                    value={item.value}
                    color={color}
                    disabled={disabled}
                    onChange={handleChange}
                    inputProps={{ 'aria-label': `${color} radio` }}
                  />
                }
                label={
                  <Typography
                    variant="body2"
                    dangerouslySetInnerHTML={{ __html: boldMatchKeyword(item.label, inputValue) }}
                  ></Typography>
                }
              />
            </RadioRender>
          ))}
      </div>
      {inputValue === '' &&
        items.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <RadioRender index={index} draggable={draggable}>
                <StyledFormControlLabel
                  draggable={draggable}
                  key={index}
                  control={
                    <Radio
                      id={'huy'}
                      checked={item.checked}
                      value={item.value}
                      color={color}
                      disabled={disabled}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': `${color} radio` }}
                    />
                  }
                  label={
                    <label htmlFor="huy">
                      <Typography variant="body2">{item.label}</Typography>
                    </label>
                  }
                />
              </RadioRender>
            </React.Fragment>
          );
        })}
      {addAble && <ButtonAction disabled={disabled} icon="plus" label="Create new option" fullWidth={true} />}
      {ff.time_periodically && isShowButtonAction && (
        <Grid container direction="row" justifyContent="flex-end" spacing={2} style={{ marginTop: '15px' }}>
          <Button onClick={onClose}>Cancel</Button>
        </Grid>
      )}
    </FormGroup>
  );
};
export default RadioList;
