import * as React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

const Bold = (props: SvgIconProps) => (
  <SvgIcon {...props} width="12" height="12" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 1C0 0.965483 0.00174889 0.931373 0.00516289 0.897756C0.0563729 0.3935 0.482233 0 1 0H4C5.65685 0 7 1.34315 7 3C7 3.53465 6.86014 4.03664 6.61501 4.47137C7.44791 5.00444 8 5.93774 8 7C8 8.65685 6.65685 10 5 10H1C0.447715 10 0 9.55229 0 9V1ZM4 2C4.55228 2 5 2.44772 5 3C5 3.55228 4.55228 4 4 4H2L2 2L4 2ZM6 7C6 6.44772 5.55228 6 5 6H2L2 8H5C5.55228 8 6 7.55229 6 7Z"
    />
  </SvgIcon>
);

export default Bold;
